<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Client Element Assignment</span>
        </div>
        <div class="sub-header-buttons">
          <a
            routerLink="/home/select-elements"
            mat-raised-button
            title="Back"
            class=""
            >Back</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="clientElementForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-md-3">
              <label class="col-form-label"
                >Client Name<span class="star">*</span></label
              >
              <!-- <mat-select
                formControlName="client"
                placeholder="Select Client"
                class="form-control email-input assign-placeholder"
              >
                <mat-option [value]="null">--- Select Client ---</mat-option>
                <mat-option
                  *ngFor="let row of clientList; let i = index"
                  [value]="row?.clientId "
                >
                  {{ row?.clientCode }}
                </mat-option>
              </mat-select> -->
              <input
              type="text"
             
              placeholder="client Name"
              formControlName="client"
              autocomplete="off"
             readonly
             [disabled]="true"
             style="    background: #dddddd87 !important;"
              [value]="clientName"
              class="form-control email-input select-placeholder"
            />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.client?.invalid && validate?.client?.touched) ||
                  validate?.client?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.client?.errors?.required"
                  class="text-danger"
                  >Please select client</span
                >
                <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-3" id="loginform">
              <label class="col-form-label"
                >PF Max Salary Amount<span class="star">*</span></label
              >
              <input
                type="text"
                formControlName="PfMaxSalaryAmount"
                id="ratePerMonth"
                placeholder="Enter PF max salary amount"
                class="form-control email-input select-placeholder"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.PfMaxSalaryAmount?.invalid &&
                    validate?.PfMaxSalaryAmount?.touched) ||
                  validate?.PfMaxSalaryAmount?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.PfMaxSalaryAmount?.errors?.required"
                  class="text-danger"
                  >Please enter PF max salary amount</span
                >
                <span
                  *ngIf="validate?.PfMaxSalaryAmount?.errors?.pattern"
                  class="text-danger"
                  >Please enter valid PF max salary amount</span
                >
              </div>
            </div>
            <div class="form-group col-md-3" id="loginform">
              <label class="col-form-label"
                >Employee PF <span class="star">*</span></label
              >
              <input
                type="text"
                formControlName="EmployeePF"
                id="ratePerMonth"
                placeholder="Enter employee PF"
                class="form-control email-input select-placeholder"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.EmployeePF?.invalid &&
                    validate?.EmployeePF?.touched) ||
                  validate?.EmployeePF?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.EmployeePF?.errors?.required"
                  class="text-danger"
                  >Please enter employee PF</span
                >
                <span
                  *ngIf="validate?.EmployeePF?.errors?.pattern"
                  class="text-danger"
                  >Please enter valid employee PF</span
                >
              </div>
            </div>
            <div class="form-group col-md-3" id="loginform">
              <label class="col-form-label"
                >Employer PF <span class="star">*</span></label
              >
              <input
                type="text"
                formControlName="EmployerPF"
                id="ratePerMonth"
                placeholder="Enter employee PF "
                class="form-control email-input select-placeholder"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.EmployerPF?.invalid &&
                    validate?.EmployerPF?.touched) ||
                  validate?.EmployerPF?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.EmployerPF?.errors?.required"
                  class="text-danger"
                  >Please enter employer PF</span
                >
                <span
                  *ngIf="validate?.EmployerPF?.errors?.pattern"
                  class="text-danger"
                  >Please enter valid employer PF</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-3" id="loginform">
              <label class="col-form-label"
                >ESI Max Salary Amount<span class="star">*</span></label
              >
              <input
                type="text"
                formControlName="ESIMaxSalaryAmount"
                id="ratePerMonth"
                placeholder="Enter ESI max salary amount"
                class="form-control email-input select-placeholder"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.ESIMaxSalaryAmount?.invalid &&
                    validate?.ESIMaxSalaryAmount?.touched) ||
                  validate?.ESIMaxSalaryAmount?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.ESIMaxSalaryAmount?.errors?.required"
                  class="text-danger"
                  >Please enter ESI max salary amount</span
                >
                <span
                  *ngIf="validate?.ESIMaxSalaryAmount?.errors?.pattern"
                  class="text-danger"
                  >Please enter valid ESI max salary amount</span
                >
              </div>
            </div>
            <div class="form-group col-md-3" id="loginform">
              <label class="col-form-label"
                >Employee ESI <span class="star">*</span></label
              >
              <input
                type="text"
                formControlName="EmployeeESI"
                id="ratePerMonth"
                placeholder="Enter employee ESI"
                class="form-control email-input select-placeholder"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.EmployeeESI?.invalid &&
                    validate?.EmployeeESI?.touched) ||
                  validate?.EmployeeESI?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.EmployeeESI?.errors?.required"
                  class="text-danger"
                  >Please enter employee ESI</span
                >
                <span
                  *ngIf="validate?.EmployeeESI?.errors?.pattern"
                  class="text-danger"
                  >Please enter valid employee ESI</span
                >
              </div>
            </div>
            <div class="form-group col-md-3" id="loginform">
              <label class="col-form-label"
                >Employer ESI <span class="star">*</span></label
              >
              <input
                type="text"
                formControlName="EmployerESI"
                id="ratePerMonth"
                placeholder="Enter employee ESI "
                class="form-control email-input select-placeholder"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.EmployerESI?.invalid &&
                    validate?.EmployerESI?.touched) ||
                  validate?.EmployerESI?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.EmployerESI?.errors?.required"
                  class="text-danger"
                  >Please enter employer ESI</span
                >
                <span
                  *ngIf="validate?.EmployerESI?.errors?.pattern"
                  class="text-danger"
                  >Please enter valid employer ESI</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <input
                (click)="addelement()"
                style="margin-top: 45px"
                type="button"
                class="btn  btn-sm"
                value="Add"
                style="    margin-top: 42px;
                width: 63px;
                background: #1a83ff;color:#fff"
              />
              <!-- <div>
                                <span class="text-danger"  *ngIf="showctcerror">Please enter employee annual ctc</span>
                            </div> -->
            </div>
          </div>
          <div class="row"  *ngIf="clientElementForm?.controls">
            <div
              class="table-responsive scroll"
              *ngIf="getFormControls?.controls?.length != 0"
              style="max-height: 363px; overflow: auto; overflow-x: hidden"
            >
              <table class="table table-border">
                <thead class="text-nowrap">
                  <th>
                    Element Name<span class="text-danger"> *</span
                    ><span class="resizer"></span>
                  </th>
                  <th>
                    Element Percentage <span class="text-danger"> *</span
                    ><span class="resizer"></span>
                  </th>
                  <th>
                    Calculation Elements<span class="resizer1"></span>
                  </th>
                  <th>
                    Formula<span class="text-danger"> *</span
                    ><span class="resizer1"></span>
                  </th>
                  <th>Action</th>
                </thead>
                <tbody>
                  <ng-container
                    formArrayName="tableRows"
                    *ngFor="
                      let group of getFormControls?.controls;
                      let i = index
                    "
                  >
                    <tr
                      *ngIf="group?.get('isEditable')?.value"
                      [formGroupName]="i"
                    >
                      <td>
                        <div>
                          <!-- <select
                            formControlName="element"
                            class="form-select form-select-solid form-select-lg"
                            (change)="getelemenetpercentage(group, i, $event)"
                          >
                            <option value="null">- Select -</option>
                            <option
                              *ngFor="let row of elementlist; let i = index"
                              value="{{ row.elementId }}"
                            >
                              {{ row.elementName }}
                            </option>
                          </select> -->
                          <mat-select
                          formControlName="element"
                          class="form-control email-input assign-placeholder"
                          placeholder="Select element"
                          (change)="getelemenetpercentage(group, i, $event)"
                        >
                          <mat-option [value]="null">-Select element-</mat-option>
                          <mat-option
                            *ngFor="let row of elementlist; let i = index"
                            [value]="row?.elementId"
                          >
                            {{ row?.elementName }}
                          </mat-option>
                        </mat-select>
                          <div
                            *ngIf="
                              (submitted &&
                                group['controls'].element.invalid) ||
                              group['controls'].element.dirty
                            "
                          >
                            <small
                              *ngIf="group['controls'].element.errors?.required"
                              class="text-danger"
                              >Please select element</small
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <!-- <select
                            formControlName="Percentage"
                            class="form-select form-select-solid form-select-lg"
                          >
                            <option value="null">- Select -</option>
                            <option value="0">No Percentage</option>
                            <ng-container
                              *ngFor="
                                let dcrow of group.value.Percentagelist;
                                let di = index
                              "
                            >
                              <option value="{{dcrow.elementPercentageId}}">
                                {{ dcrow.elementPercentage }}
                              </option>
                            </ng-container>
                          </select> -->
                          <mat-select
                          formControlName="Percentage"
                          class="form-control email-input assign-placeholder"
                          placeholder="Select Percentage"
                        >
                          <mat-option [value]="null">-Select Percentage-</mat-option>
                          <mat-option [value]="0">No Percentage</mat-option>

                          <mat-option
                            *ngFor=" let dcrow of group.value.Percentagelist;
                                let di = index"
                            [value]="dcrow.elementPercentageId"
                          >
                            {{ dcrow.elementPercentage }}
                          </mat-option>
                        </mat-select>
                          <div
                            *ngIf="
                              (submitted &&
                                group['controls'].Percentage.invalid) ||
                              group['controls'].Percentage.dirty
                            "
                          >
                            <small
                              *ngIf="
                                group['controls'].Percentage.errors?.required
                              "
                              class="text-danger"
                              >Please select percentage</small
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <mat-select
                              formControlName="calcElement"
                              multiple
                              (selectionChange)="defineformula(group, i)"
                              matTooltip="{{
                                group.get('calcElementName').value
                              }}"
                              matTooltipPosition="above"
                              matTooltipClass="custom-tooltip"
                              [ngClass]="{
                                'is-invalid': group['controls'].errors
                              }"
                              style="border: 1px solid #dee2e6"
                              placeholder="Calculation element"
                              class="form-control email-input assign-placeholder"
                              autocomplete="off"
                            >
                              <!-- <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option> -->
                              <mat-option [value]="null">- Select -</mat-option>
                              <mat-option
                                *ngFor="
                                  let refelements of refreancelements;
                                  let i = index
                                "
                                [value]="refelements.elementId"
                                >{{ refelements.elementName }}</mat-option
                              >
                            </mat-select>
                          </div>
                          <!-- <input type="text" formControlName="calcElement" id="salaryPerMonth" [ngClass]="{ 'is-invalid': group['controls'].errors }" 
                                                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name"  autocomplete="off"> -->
                          <div
                            *ngIf="
                              (submitted &&
                                group['controls'].calcElement.invalid) ||
                              group['controls'].calcElement.dirty
                            "
                          >
                            <small
                              *ngIf="
                                group['controls'].calcElement.errors?.required
                              "
                              class="text-danger"
                              >Please select Elements</small
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <textarea
                            type="text"
                            formControlName="Formula"
                            (keyup)="validateformula(group, i)"
                            id="salaryPerAnnum"
                            style="border: 1px solid #dee2e6"
                            [ngClass]="{
                              'is-invalid': group['controls'].errors
                            }"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name"
                            autocomplete="off"
                          >
                          </textarea>
                          <!-- <div class="valid-msg" *ngIf=" group['controls'].salaryPerAnnum.errors">Please enter salary per annum -->
                          <!-- <span class="text-danger" *ngIf="group['controls'].salaryPerAnnum.errors?.required">Please enter Description</span>
                                                        <span class="text-danger" *ngIf="group['controls'].salaryPerAnnum.errors?.pattern">Please enter valid Description</span> -->
                          <!-- </div> -->
                          <div
                            *ngIf="
                              (submitted &&
                                group['controls'].Formula.invalid) ||
                              group['controls'].Formula.dirty
                            "
                          >
                            <small
                              *ngIf="group['controls'].Formula.errors?.required"
                              class="text-danger"
                              >Please enter formula</small
                            >
                            <small
                              *ngIf="
                                group['controls'].Formula.errors
                                  ?.invalidFormula ||
                                group['controls'].Formula.errors
                                  ?.elementsNotInFormula ||
                                group['controls'].Formula.errors?.noOperators
                              "
                              class="text-danger"
                              >Please enter valid formula</small
                            >
                          </div>
                        </div>
                      </td>
                      <td style="text-align: center">
                        <mat-icon style="    color: red;
                        position: relative;
                        top: 22px;"
                          *ngIf="i != 0 && i != 1"
                          class="material-symbols-outlined delete"
                          (click)="deleteRow(i, group)"
                          >delete</mat-icon
                        >
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!-- <div class="row">
                            <div class="col-md-4" *ngIf="selectedEmployeeData?.length > 0">
                                <div class="row">
                                    <div class="col-md-3 text-right">
                                        <p class="show-count">Show</p>
                                    </div>
                                    <div class="col-md-3 text-left pl-0">
                                        <select [(ngModel)]="pagePerItem" (change)="pageChages()"  class="form-control report-filter">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> -->
            <div class="row">
              <div class="col-md-12" style="float: right">
                <button type="submit" 
                mat-raised-button 
                [disabled]="btndisable"
                class="searchBtn ">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
    role="status"
  ></div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <!-- <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records </button>-->
</div>
