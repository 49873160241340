<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content pr-3">Users</span>
                </div>
                <div class="sub-header-buttons">
                    <button *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button color="primary" class="btn download-button">
                      Export
                    </button>
                    <a routerLink="/home/settings" mat-raised-button title="Back" class="ml-2">Back</a>
                    <!-- <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/add-user" routerLinkActive="active" color="primary" mat-raised-button title="Add User" class="ml-2">
                    Add User
                </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->
<div class="common-content">
    <div class="card-new">
        <div class="card" style="font-family: Mulish;">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-8">
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row mb-3 pl-0">
                                <div class="col-md-4">
                                    <input formControlName="keywords" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search...">
                                    <small class="text-muted">Note : Name</small>
                                </div>
                                <div class="ol-xl-1 col-lg-1 mb-2">
                                    <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn" style="font-family: Mulish;">Search</button>
                                </div>
                            </div>
                        </form>

                       <!--  <div class="row mb-3 pl-0">
                            <div class="col-md-4">
                                <input type="search" [(ngModel)]="tableSearch" autocomplete="off" class="form-control form-control-lg form-control-solid search-input" name="keywords" value="" placeholder="Search...">
                            </div>

                        </div> -->
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-9 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0">
                                <select [(ngModel)]="pagePerItem" class="form-select form-select-solid form-select-lg report-filter">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1500">1500</option>
                            </select>
                            </div>
                        </div>
                    </div>
                </div>   

                <div class="table-responsive-new1">
                    <table matSort (matSortChange)="sortData($event)" class="table --table-responsive table-bordered table-border-new">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center">Action</th>
                                <th mat-sort-header="employeeNumber">Employee Number</th>
                                <th mat-sort-header="Name">Name</th>
                                <th mat-sort-header="Email">Email</th>
                                
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData?.length > 0">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <!-- <td class="text-center">{{i+1}}</td> -->
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item routerLink="/home/edit-user/{{row.userId}}" routerLinkActive="active">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                         </button>
    
                                         <button mat-menu-item routerLink="/home/user-roles/{{row.userId}}" routerLinkActive="active">
                                            <mat-icon>supervised_user_circle</mat-icon>
                                            <span>Roles</span>
                                        </button>
    
                                        <!-- <button mat-menu-item routerLink="/home/view-employee" routerLinkActive="active">
                                        <mat-icon>visibility</mat-icon>
                                        <span>View</span>
                                        </button> -->
    
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 1" (click)="updateStatus(row.userId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>Inactive</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 2" (click)="updateStatus(row.userId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>
    
                                    </mat-menu>
                                </td>
    
                                <td *ngIf="row.employee != undefined">{{row.employee.employeeNumber}}</td>
                                <td *ngIf="row.employee == undefined">--</td>
                                <td *ngIf="row.employee != undefined">{{row.employee.firstName}}</td>
                                <td *ngIf="row.employee == undefined">--</td>
                                <td>{{row.emailId}}</td>
                                <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                    {{row.status.listTypeValueName}}
                                </span>
    
                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                    {{row.status.listTypeValueName}}
                                </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
    
                    <div *ngIf="nodatafound" class="no-data">
                        <img src="assets/uploads/nodata.png" width="10%" height="10%" style="margin-left: 50%;">
                        <!-- <span class="no-data-found"> No Data Found </span> -->
                    </div>

                </div>
              

                <div *ngIf="resultData?.length > 0 ">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>