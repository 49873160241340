import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PayrollService } from 'src/app/providers/payroll.service';
import { responseMsgTimeOut}from '../../app/providers/properties';
// import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormGroup, FormControl, AbstractControl,FormArray,Validators, FormBuilder }from '@angular/forms';
import { ImportClientComponent} from '../import-client/import-client.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AsyncPipe} from '@angular/common';
import {  MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { EmpassignmentdetailComponent} from '../empassignmentdetail/empassignmentdetail.component'
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { AppComponent } from 'src/app/app.component';
import {ExcelService } from '../services/excel.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { Router,ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-create-manage-recoveryadjustment',
  templateUrl: './create-manage-recoveryadjustment.component.html',
  styleUrls: ['./create-manage-recoveryadjustment.component.scss']
})
export class CreateManageRecoveryadjustmentComponent implements OnInit {
  length: number;
  dataArray: any=[];
  financialYear: any;
  period: string;
  recAdjustmentId: any;
  finEmpList: any;
  category: any;
  categoryId: number;
  eleNameList: any;
  categoryList: any;
  categoryTypeData: any;
  sub: boolean = false;
  pageSize: any;
  pageIndex: any;
  startIndex: number;
  empList:any=[];
  clientemployee: any;
  users(users: any) {
    throw new Error('Method not implemented.');
  }
  recoveryForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any=[];
  chooseFinId:any;
  finPeriodList: any=[];
  empCode: boolean=false;
  empRatings:any=[];
  selectedEmployeeData: any = [];
  financialPeriodList: any = [];
  empratingId: number;
  employeetData: any;
  userdata: any;
  reportData: any;
  firstname: any;
  lastname: any;
  maxstartdate: any;
  empnumber: any;
  currentPageOffset: number=0;
  data: any[]; // Your complete data array
  pagedData: any[];
  elementlist:any = [];
  collection: any = []; // Your paginated data array
  clientelements: any;
  maxpfamount: any;
  max: any;
  employeePfAmount: any;
  employerPfAmount: any;
  maxesiamount: any;
  employeeEsiAmount: any;
  employerEsiAmount: any;
  constructor(
    private fb:FormBuilder,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    private router:Router,
    private formBuilder: FormBuilder,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    @Inject(AppComponent) public AppData: any
  ) { }

  ngOnInit(): void {
    this.data = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`);
    this.updatePagedData();
    // this.collection = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`)
    this.recAdjustmentId=this.ActivatedRoute.snapshot.params.recoveryAdjustmentId;
    console.log(this.recAdjustmentId);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.recoveryForm = this.fb.group({
      category:[null,[Validators.required]],
      element:[null,[Validators.required]],
      client:[null,[Validators.required]],
      employee:[null,[Validators.required]],
      // employeecode:[null,[Validators.required]],
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      remarks:[null],
      Recovery: this.fb.array([],[Validators.required]),
    })
    
    this.payrollservice.getCategoryList().subscribe((result: any) => {
      this.recoveryForm.get('category').patchValue(result[0].listTypeValueId);
      this.categoryTypeData = result;
      this.categoryId=this.categoryTypeData[0].listTypeValueId;
      console.log('result',this.categoryId);
      this.payrollservice.getElementListValues(this.categoryId, this.orgId).subscribe((d: any) => {
        console.log('element', d)
        this.eleNameList = d;
      })
    })

    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    }) 
    // this.settingsService.getElementList(this.orgId).subscribe((Data: any) => {
    //   console.log(Data);
    //   this.finEmpList = Data;
    // });

    if(this.recAdjustmentId){
      console.log("if patch");
      // this.submitted=true;
      // this.selectedEmployeeData=[];
        this.payrollservice.getRecoveryAdjustmentById(this.recAdjustmentId).subscribe((userdata:any) =>
        {       
           console.log('userdata', userdata)
          this.userdata=userdata;
          // this.recoveryForm.get('category').patchValue(this.categoryTypeData[0].listTypeValueId);
          this.recoveryForm.patchValue({
            financialyear: this.userdata.financialPeriod.finanicalYear.financialYearId, // Replace with the desired value
            period: this.userdata.financialPeriod.financialPeriodId, // Replace with the desired value
            // employeecode:this.userdata.employee.employeeNumber,
            client: this.userdata.client.clientId,
            employee: this.userdata.employee.employeeNumber + "-"+this.userdata.employee.firstName+" "+this.userdata.employee.lastName ,
            category: this.userdata.financialPeriod.finanicalYear.financialYearId, // Replace with the desired value
            element: this.userdata.hrmspaySlipElement.elementId, // Replace with the desired value
            remarks:this.userdata.reMarks
            // employeeNumber: 'EMP001', // Replace with the desired value
          });
          console.log("this.postForm",this.recoveryForm)
          // Adding a row with leaveDays

          const updateData= {
            employeeId: this.userdata.employee.employeeId,
            employeeNumber:this.userdata.employee.employeeNumber,
            firstName:this.userdata.employee.firstName,
            lastName:this.userdata.employee.lastName,
            amount: this.userdata.amount,
            recoveryAdjustmentId:this.userdata.recoveryAdjustmentId
            // employeerattingid:this.userdata.employeerattingId
          }
          console.log("updateData",updateData);
          this.selectedEmployeeData.push(updateData);

          console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
          const rowsArray = this.recoveryForm.get('Recovery') as FormArray;
          this.addRow();
          console.log(rowsArray);
          rowsArray.at(0).patchValue({
            employeeId:this.userdata.employee.employeeId , 
            employeeNumber:this.userdata.employee.employeeNumber,
            firstName: this.userdata.employee.firstName,
            lastName: this.userdata.employee.lastName,
            amount: this.userdata.amount,
            recoveryAdjustmentId:this.userdata.recoveryAdjustmentId
          });
           
          // rowsArray.push(this.formBuilder.group({
          //   employeeId:this.userdata.employee.employeeId , 
          //   employeeNumber:this.userdata.employee.employeeNumber,
          //   firstName: this.userdata.employee.firstName,
          //   lastName: this.userdata.employee.lastName,
          //   amount: this.userdata.amount,
          //   recoveryAdjustmentId:this.userdata.recoveryAdjustmentId
          // }));
           
          this.employeeId = userdata.employee.employeeId;
          this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
            console.log(resultData);
            // resultData.forEach((fYear) => {
            //   if (fYear.financialYearStatus.listTypeValueId == 1) {
            //   // this.financialYearList = resultData;
            // }
            // });
          

            this.payrollservice.getCategoryList().subscribe((result: any) => {
              this.recoveryForm.get('category').patchValue(result[0].listTypeValueId);
              this.categoryTypeData = result;
              // this.categoryId=this.categoryTypeData[0].listTypeValueId;
              // console.log('result',this.categoryId);
              // this.payrollservice.getElementListValues(this.categoryId).subscribe((d: any) => {
              //   console.log('element', d)
              //   this.eleNameList = d;
              // })
            })

            this.settingsService.getFinancialYearId(this.recoveryForm.value.financialyear).subscribe((resultData1: any) => {
              console.log("resultData1", resultData1.finanicalPeriod)
        
              this.finPeriodList = resultData1.finanicalPeriod;
              console.log(this.userdata.financialPeriod.financialPeriodId);
              this.recoveryForm.get('period').patchValue(this.userdata.financialPeriod.financialPeriodId); 
              console.log("this.financialPeriodList", this.financialPeriodList);

            });
    
          });
          
        })
      // }
    }
  }

  get validate() { return this.recoveryForm.controls; }

   
  notZeroValidatorAsync(control: AbstractControl) {
    const value = control.value;
    const regex =/^(?!\.)\d{1,8}(\.\d{1,2})?$|^\d{1,8}$/; 
    return new Promise((resolve) => {
      if (!regex.test(value.toString()) ) {
        console.log("Invaild");
        resolve({ notZero: true }); // Return an object with a custom error key if value is zero
      } else {
        console.log("Zero");
        resolve(null); // Return null if the value is not zero, indicating the control is valid
      }
    });
    // const value = control.value;
    // return new Promise((resolve) => {
    //   if (value < 1 ) {
    //     resolve({ notZero: true }); // Return an object with a custom error key if value is zero
    //   } else {
    //     resolve(null); // Return null if the value is not zero, indicating the control is valid
    //   }
    // });
  }

  addRow() {
    const control = this.recoveryForm.get('Recovery') as FormArray;
    control.push(this.initRows());
    console.log(control);
  }
  
  
  initRows() {
    return this.fb.group({
      amount: ['', [Validators.required],this.notZeroValidatorAsync],
      employeeId: [''],
      employeeNumber:[''],
      recoveryAdjustmentId:[''], 
      firstName :[''],
      lastName :['']
    });
  }

  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  onKeyDown(event: KeyboardEvent): void 
{      
    if (event.keyCode === 13) {      
          event.preventDefault();  
        }      
} 
  
  onSubmit()
  {
    console.log("submit");
    this.submitted=true;
    const empRecoveryAdjustment = this.recoveryForm.get('Recovery') as FormArray;
    console.log(empRecoveryAdjustment);
    if (this.recoveryForm.invalid) 
     {
      console.log("invalid",this.recoveryForm);
      // if(this.recoveryForm.value.employeecode.length!=0&&empRecoveryAdjustment.length==0)
        // {
        //   this.error = true;
        //   this.error_msg = "Please Select Employee Code";
        //   setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
        // }
        if(this.recoveryForm.value.employee.length!=0&&empRecoveryAdjustment.length==0)
        {
          console.log("inside form:"+this.recoveryForm.value.employee);
          this.recoveryForm.patchValue({
            employee:null
          });
          this.error = true;
          this.error_msg = "Please Select Employee";
          setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
        }
        else
        {
          console.log("Employee Code Null")
        }
        return;
     }
   
    if(this.recAdjustmentId) //Update 
    {
      this.empRatings=[];
      console.log("update", this.empratingId);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      console.log("employeeLeaveControls", empRecoveryAdjustment)
      for (let i = 0; i < empRecoveryAdjustment.length; i++) {
        const employeeId = empRecoveryAdjustment.at(i).value.employeeId;
        console.log('employeeId', employeeId);
        console.log("Id", empRecoveryAdjustment.at(i).value.recoveryAdjustmentId);
        if(empRecoveryAdjustment.at(i).value.recoveryAdjustmentId==undefined)
        {
          console.log("Id not present", empRecoveryAdjustment.at(i).value.recoveryAdjustmentId);
          const updateRevAdjustment = 
          {
            // recoveryAdjustmentId:this.recAdjustmentId,
            amount: empRecoveryAdjustment.at(i).value.amount,
            reMarks:this.recoveryForm.value.remarks,
            financialPeriod: {
              financialPeriodId: this.recoveryForm.value.period
            },
            employee: {
              employeeId: empRecoveryAdjustment.at(i).value.employeeId
            },
            hrmspaySlipElement:{
                elementId:this.recoveryForm.value.element
            },
            status:{
              listTypeValueId:1
            },
            client:{
              clientId:this.recoveryForm.value.client
          }
        }
          this.empRatings.push(updateRevAdjustment);
        }
        else
        {
          console.log("id present", empRecoveryAdjustment.at(i).value.recoveryAdjustmentId);
          const updateRevAdjustment=
          {
            recoveryAdjustmentId:this.recAdjustmentId,
            amount: empRecoveryAdjustment.at(i).value.amount,
            reMarks:this.recoveryForm.value.remarks,
            financialPeriod: {
              financialPeriodId: this.recoveryForm.value.period
            },
            employee: {
              employeeId: empRecoveryAdjustment.at(i).value.employeeId
            },
            hrmspaySlipElement:{
                elementId:this.recoveryForm.value.element
            },
            client:{
              clientId:this.recoveryForm.value.client
          },
            status:{
              listTypeValueId:1
            }
          }
          this.empRatings.push(updateRevAdjustment);
        }
      }

      console.log('employeeLeaveArray', this.empRatings);          
        this. payrollservice.updateAdjustment(this.empRatings).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;
            // this.searchSubmit();
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            // this.clientupdateId = null;
            this.recoveryForm.reset();
            this.submitted = false;
            setTimeout(() => {
              this.router.navigate(["home/client-recovery-adjustment-list"])
            }, redirectMsgTimeOut)
          }
          else
          {
            // this.error = true;
            // this.error_msg = result.description;
            // this.errormessagejson = result;
            // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          console.log("err1",result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson',this.errormessagejson);
          this.importerror = true;
          }
        },
         err =>
            {
              // console.log("code",err);
              // console.log("code",err.statusCode);
              // if(err.error.statusCode == 409)
              // {
                console.log("code1",err.statusCode);
                setTimeout(() => {
                  this.error = true;
                  this.errorMsg = err.error.description;
                }, redirectMsgTimeOut) 
              // }
               
            })
    }

    else
    {
      this.empRatings=[];
      console.log("create");
      console.log(empRecoveryAdjustment.value);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      this.reportData=empRecoveryAdjustment.value;
      console.log("this.reportDate",this.reportData);
      for (let i = 0; i < this.reportData.length; i++) {
        const employeeId = this.reportData.at(i).employeeId;
        console.log('employeeId', employeeId);
        // const createEmployeeRating = {
        //   financialPeriod: 
        //            { 
        //              financialPeriodId: this.recoveryForm.value.period
        //            },
        //   employee: 
        //   { 
        //     employeeId: this.reportData.at(i).employeeId 
        //   },
        //   rating: this.reportData.at(i).emprating,
        //   status: { listTypeValueId: 1 }
        // };
       const createadjustment=
        {
          amount: this.reportData.at(i).amount,
          reMarks:this.recoveryForm.value.remarks,
          financialPeriod: {
            financialPeriodId: this.recoveryForm.value.period
          },
          employee: {
            employeeId: this.reportData.at(i).employeeId
          },
          hrmspaySlipElement:{
              elementId:this.recoveryForm.value.element
          },
           client:{
            clientId:this.recoveryForm.value.client
        }
        }
        this.empRatings.push(createadjustment);
        console.log(this.empRatings);
      }
          this.payrollservice.createAdjustment(this.empRatings).subscribe((result: any) =>
       {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          this.recoveryForm.reset();     
          this.submitted = false;
          // this.searchSubmit();
          setTimeout(() => {
            this.router.navigate(["home/client-recovery-adjustment-list"])
          }, redirectMsgTimeOut)
        }
        else
        {
          console.log("err1",result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson',this.errormessagejson);
          this.importerror = true;
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          // setTimeout(() => {
          // }, redirectMsgTimeOut)
        }

      }, err =>
      {
        console.log("code",err);
        console.log("code",err.statusCode);
        // if(err.error.statusCode == 409)
        // {
        //   console.log("code1",err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut) 
        // }
         
      })
    }

  }
  
  selectFinancialyear(event)
  {
    console.log("Select indidual Employee");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.recoveryForm.patchValue({
        period: null,
      });
    });
  }
  }

  
  // empSearch(event)
  // { 
  //   this.empList=[];
  //   this.empCode=false;
  //   console.log(event.target.value);
  //   this.userId=event.target.value;
  //   console.log(this.userId);
  //   this.payrollservice.getEmployeeNameList(this.userId,this.orgId).subscribe((result: any) => {
  //     if(result.statusCode==204)
  //           {
  //             console.log('NO Data');
  //             this.empList.length = 0;
  //             this.empCode=true;
  //           }
  //           else
  //           {
  //             this.empList = result;
  //             this.empCode=false;
  //             console.log(this.empList);
  //           }
  //   })
  // }

  empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      // this.payrollService.getEmployeeNameList(employeenumber, this.organizationId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }
      
      // })

      this.payrollservice.getEmployeeClientList( this.recoveryForm.value.employee , this.recoveryForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode)
        {
          
        }else{
          result.forEach(element => {
            if(!this.empList.some(e=>e.employeeId == element.employee.employeeId))
            {
              this.empList.push(element.employee);
            }
        
          });
        }
     
     })

    }
    
  }

  // empSelect(emp)
  //  {
  //   console.log(emp);
  //   this.employeeId = emp.employeeId;
  //   this.firstname=emp.firstName;
  //   this.lastname=emp.lastName;
  //   this.empnumber=emp.employeeNumber;
  //     console.log("select",this.selectedEmployeeData);
  //     let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
  //     if (!hasemployee) {
  //       this.selectedEmployeeData.push(emp);
  //       const rowsArray = this.recoveryForm.get('Recovery') as FormArray;
  //       console.log("control",rowsArray);
  //       rowsArray.push(this.formBuilder.group({
  //         employeeId: [this.employeeId], 
  //         employeeNumber:[this.empnumber],
  //         firstName:[ this.firstname],
  //         lastName:[this.lastname],
  //         amount: ['', [Validators.required],this.notZeroValidatorAsync],
  //       }));
  //     }
  // }
  get getFormControls() {
    this.dataArray=[];
    const control = this.recoveryForm.get('Recovery') as FormArray;
    // console.log(control);
    this.length=control.length;
    if(control != null)
    {
       control.controls.forEach((formGroup: FormGroup) => {
        const data = formGroup.value;
        this.dataArray.push(data);
      });
      // console.log(this.dataArray);
      return control;
    }    
  }
  
  empAllSelect()
 {
  console.log("all Employee");
  if(this.recoveryForm.value.employeecode == "All")
  {
    console.log("select",this.selectedEmployeeData);
    this.payrollservice
    .getAllEmployeeByOrganization(this.orgId).subscribe((result: any) => {
      console.log(result);
      if(result.length==0)
      {
        console.log("noData");
      }
      console.log("All",this.empList);
      result.forEach(element => {
        let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
        if (!hasemployee) {
          this.selectedEmployeeData.push(element);
          console.log('selectedEmployeeData', this.selectedEmployeeData);
          const rowsArray = this.recoveryForm.get('Recovery') as FormArray;
          rowsArray.push(this.formBuilder.group({
            employeeId: [element.employeeId], 
            employeeNumber:[element.employeeNumber],
            firstName:[element.firstName],
            lastName:[element.lastName],
            amount: ['', [Validators.required],this.notZeroValidatorAsync],
          }));
        }
      });
    })
  }
}

empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
  
      this.recoveryForm.patchValue({
        employee: this.employee 
     });
     this.payrollservice.getannexueversionbyemployee(this.employeeId).subscribe((result: any) => {
      if(result.statusCode)
      {
        
      }else{
        var date = new Date(result.effectiveToDate)

        // Add a day
        date.setDate(date.getDate() + 1)
        this.maxstartdate = date ; 
      }
      console.log(emp);
      this.employeeId = emp.employeeId;
      this.firstname=emp.firstName;
      this.lastname=emp.lastName;
      this.empnumber=emp.employeeNumber;
        console.log("select",this.selectedEmployeeData);
        let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
        if (!hasemployee) {
          this.selectedEmployeeData.push(emp);
          const rowsArray = this.recoveryForm.get('Recovery') as FormArray;
          console.log("control",rowsArray);
          rowsArray.push(this.formBuilder.group({
            employeeId: [this.employeeId], 
            employeeNumber:[this.empnumber],
            firstName:[ this.firstname],
            lastName:[this.lastname],
            amount: ['', [Validators.required],this.notZeroValidatorAsync],
          }));
        }
   })

   
   this.payrollservice.getEmployeeClientList(emp.employeeNumber , this.recoveryForm.value.client).subscribe((result: any) => {
    if(result.statusCode)
    {
      
    }else{
        this.clientemployee = result;
        console.log(  this.clientemployee)
        console.log( this.clientemployee[0].ratePerMonth)
           this.recoveryForm.patchValue({
            ctc : Number(this.clientemployee[0].ratePerMonth)
           })
    }
 
 })
     
   
  }
// onPageChange(event: any): void {
//   this.pageIndex = event.pageIndex;
//   this.pageSize = event.itemsPerPage;
//   console.log(`Page changed. PageIndex: ${this.pageIndex}, PageSize: ${this.pageSize}`);
// }

deleteemployee(index) {
  console.log(index + index);
  if (index != -1) {
    const rowsArray = this.recoveryForm.get('Recovery') as FormArray;
    console.log(rowsArray);
    // for (let i = 0; i < rowsArray.length; i++) {
      const emprattingId = rowsArray.at(index).value.employeeId;
      console.log('emprattingId', emprattingId);
      console.log("Id", rowsArray.at(index).value.recoveryAdjustmentId);
      if(rowsArray.at(index).value.recoveryAdjustmentId==undefined)
      {
        rowsArray.removeAt(index);
        this.selectedEmployeeData.splice(index, 1);
        console.log(this.selectedEmployeeData.length);
      }
   }
}

isRowValid(index: number): boolean {
  const rowsArray = this.recoveryForm.get('Recovery') as FormArray;
  // console.log(control);
  const row = rowsArray.at(index) as FormGroup;
  return row.valid || (row.touched && row.dirty);
}

// isRowValid(index: number): boolean {
//   const row = this.myForm.get('rows').controls[index];
//   return row.valid || (row.touched && row.invalid);
// }
close()
{
  console.log("close");
  this.router.navigateByUrl('/home/client-recovery-adjustment-list');
}
// pageChages()
//   {
//     console.log("page changes");
//     this.p=1;
//   }
onItemsPerPageChange(event: any) {
  this.pagePerItem = event.target.value;
  this.p = 1; // Reset the pagination to the first page when items per page is changed
}
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

  page(event: any): void {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.pageSize = event.itemsPerPage;
    console.log(`Page changed. PageIndex: ${this.pageIndex}, PageSize: ${this.pageSize}`);
  }

  onPageChange(event: any): void {
    this.p = event;
    this.updatePagedData();
  }

  updatePagedData(): void {
    const startIndex = (this.p - 1) * this.pagePerItem;
    const endIndex = startIndex + this.pagePerItem;
    console.log(startIndex);
    this.startIndex=startIndex;
    console.log(this.startIndex);
    console.log(endIndex);
  }

  get amountFormArray() : FormArray {
    return this.recoveryForm.controls["Recovery"] as FormArray
   }

   getclientEmployees()
  {
      this.empList.length = 0;
       console.log(this.recoveryForm.value.client)
      if(this.recoveryForm.value.client != null && this.recoveryForm.value.client != "null")
      {
        this.recoveryForm.patchValue({
          employee:null
         });
        this.payrollservice.getAllEmployeeClientList(this.recoveryForm.value.client).subscribe((ClientEmployees: any) => {
          if(ClientEmployees.length != undefined)
          {
            // ClientEmployees.forEach(element => {
            //   this.empList.push(element.employee);
            // });
          }
         
        });

        this.payrollservice.clientelements(this.recoveryForm.value.client).subscribe((result: any) => {
          if(result.statusCode)
          {
           this.elementlist.length = 0;
          }else{
            console.log("else")
             this.clientelements = result;
               this.maxpfamount = this.clientelements[0].pfMaxSalaryAmount;
               this.employeePfAmount = this.clientelements[0].employeePfAmount;
               this.employerPfAmount = this.clientelements[0].employerPfAmount;

               this.maxesiamount = this.clientelements[0].esiMaxSalaryAmount;
               this.employeeEsiAmount = this.clientelements[0].employeeEsiAmount;
               this.employerEsiAmount = this.clientelements[0].employerEsiAmount;

          //     result.forEach(cleintelemets => {
          //       cleintelemets.lines.forEach(lines => {
          //           this.elementlist.push(lines.element);
          //       });

          //     });
        
          // this.filterele =  this.elementlist.filter(ele=> ele.elementName.includes( "Basic" ) ||  ele.elementName.includes( "HRA" ) );
          // this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
          //        console.log(this.filterele);
  
          // const control = this.postForm.get('tableRows') as FormArray;
                 
          // console.log("this.filterele",this.filterele);
          // this.filterele.forEach((element ,index) => {
          //          this.addRow();
          //           if( element.elementName != "HRA")
          //           {
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,
          //               hrElementPercentage :  element.percentage ? element.percentage.elementPercentageId : 0,
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }else{
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,                  
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }
          //     });
        //  console.log(control); 
          }
       
       })
    }
    else
    {
      console.log("client Empty");
      this.empList =[];
      this.recoveryForm.patchValue({
        employee:null
       });
       console.log("empty patch emp"+this.recoveryForm);
    }
    
  }
  
}
