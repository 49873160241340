<mat-card-title style="font: 600 !important;" class="consolidate-timesheet form-control-label2">
    {{"Description"}}
  </mat-card-title>
  <mat-dialog-content class="mat-typography">
    <div class="input-width mb-2">
      <!-- <label class="form-control-label">Description<span class="text-danger">*</span></label> -->
      <textarea name="" 
      style="height: 38px;"
        matInput id="" 
        [(ngModel)]="data.description" 
        readonly
        placeholder="Enter description" 
        style="font-size: 15px; overflow-x: hidden; overflow-y: auto; min-height: 43px; border: none; padding: 8px;height: 38px;"
        class="form-control email-input select-placeholder"></textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button 
      [mat-dialog-close]="true" 

      class="searchBtn mb-2">Ok</button>
  </mat-dialog-actions>
