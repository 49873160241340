import { Component, Inject, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AddassignapproverComponent } from '../../../../app/project-management/project-management/addassignapprover/addassignapprover.component';
import { HeaderRowOutlet } from '@angular/cdk/table';
import { HeaderService } from '../../../../app/providers/header.service';
import { TimesheetService } from '../../../../app/providers/timesheet.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { error } from 'console';

@Component({
  selector: 'app-assignapprover',
  templateUrl:'./assignapprover.component.html',
  styleUrls: ['./assignapprover.component.scss']
})
export class AssignapproverComponent implements OnInit {
  eValue: any;
  editData: any;
  approverstoring: any;
approver: any=[];
project: any;
// isbuttondisable: any;
storeApproverId:any

// approver:any;

  tableShow:boolean =  false;
  isData: boolean;
  projectapproverobject: any = {};
  isButtonDisabled: boolean = false;

  user_type: string;
  search: any;
  approverList: any[] = [];
  userType: any;
  p: any;
  projectApproverForm: FormGroup;

  beforesearchpage: any;
  nodata=false;
  loader :boolean=false; 
  list_loader = true;

  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  orgId: any;
  projectList: any = [];
  submitted1=false;
  showError:Boolean=false
  ApproverList: any = [];

  constructor(private gs: ProjectManagementService, 
    private route:ActivatedRoute , private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,   private router: Router, public dialog: MatDialog,private headerservice:HeaderService,    private timesheet: TimesheetService) { }

  ngOnInit(): void {
    console.log("wtts inside the data::",this.data);
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.timesheet.setSuccessUpdateMessage('');
    this.route.params.subscribe(params => {
      // this.eValue =params['projectapproverId'];
      // this.editData = JSON.parse(params['approver'])     
      //  console.log("Value of 'approver' in add-assaignercompon:", this.eValue);
      // console.log("this.editData response::",this.editData);

      // this.approverstoring=this.editData.approver
      // .approverName
      
      // console.log("this.approverstoring",this.approverstoring);
      
      
  
      // Now you can use 'eValue' as needed in this component
    });
    // this.headerservice.setTitle('Assign Approvers');
    this.isData = false;
    const Data = JSON?.parse(localStorage.getItem('enoteUserData'))
    console.log("data local storage::",Data);
    this.orgId=Data.organization.organizationId

    console.log("org_ID::",this.orgId);
    
    this.projectApproverForm = this.fb.group({
      // organization: {
      //   organizationId:  this.organzationId
      // },
      project: ['', Validators.required],
      approver:['',Validators.required]

    })
    // this.gs.getResourceByIds(Data.organization.organizationId).subscribe(
    //   (d:any)=>{
        // this.userType = d.userType.userType;
        // this.user_type = d.userType.userType;
      //  this.gs.getProjectApprover(this.orgId).subscribe(
      //     (pa:any)=>{
      //       this.loader = false;
          
      //       this.approverList = pa;
      //       this.tableShow =  true;
      //       console.log(this.approverList);

      //             if(this.approverList.length == 0)
      //       {
      //         this.nodata = true;
      //         console.log("nodata")
      //       }
      //     },
      //     (error) => {
      //       this.nodata = true;
      //       this.loader = false;
      //       console.log("error")
      //       if(error.status == 404){
      //         this.nodata = true;
      //         console.log("404")
      //       }
      //     }
      //   );
    //   },
    //   (error) => {
    //     this.nodata = true;
    //     this.loader = false;
    //     console.log("error")
    //     if(error.status == 404){
    //       this.nodata = true;
    //       console.log("404")
    //     }
    //   }
    // )
    this.gs.getProjectList().subscribe(
      (d: any[]) => {
        // this.projectList=d
        console.log("project list::",d);
        console.log("this project list1::",this.projectList);
        // console.log(this.data.approvers);
        // console.log(this.data.projectId);
        
        
        
        
        this.projectList = d.sort((a, b) => a.projectName.localeCompare(b.projectName))
        console.log("this.projectList2::",this.projectList);
        
        // console.log("if condition checkk::",this.data.projectapproverId);
        
            //  if(this.eValue){
            //   console.log("if entered....!");
            //         //  this.projectreadonly = true;
            //                this.projectApproverForm.patchValue({
            //                 approver: this.editData.approvers.approverId,
            //                 // approver:this.editData.
            //                 // approverId,
            //                 approver:this.editData.
            //                 approver
            //                 .approverId,
            //                 project : this.editData.projectId,
            //                 // approver : this.data.project.projectId
        
            //       })
            //  }
      }
    )
    this.gs.getApprovers().subscribe(
      (d: any) => {
        console.log("approver response::",d);
        this.storeApproverId=d.approverId
        console.log(" this.storeApproverId::", this.storeApproverId);
        
        
        this.ApproverList = d.sort((a, b) => a.rulename.localeCompare(b.rulename))
      }
    )
    // this.gs.getapprover().subscribe(
    //   (approver:any)=>{
    //     this.approver=approver;
    //      console.log("this.approver",this.approver);
    //   }
    // )
  }
  projectapprovervalidate(){

console.log("this.projectApproverForm.value::",this.projectApproverForm.value);

    console.log("project id: ", this.projectApproverForm.value.project);
  
    // if(!this.data.projectApproverId)
    // {
    //     if(this.projectApproverForm?.value.project != 0)
    //     {
           
    //       this.gs.getprojectApprovers(this.projectApproverForm.value.project).subscribe(a=> {
    //           console.log(a);
    //           this.projectapproverobject = a;
    //             // if(this.projectapproverobject.isexist)
    //             // {
    //             //     console.log("role name isexist");
    //             //     // this.projectapproverexist = true;
    //             //     this.isButtonDisabled=true
    //             // }else{
    //             //   this.isButtonDisabled=false
    //             // }
    //       }
    //     )
    //     }else{
    //       this.isButtonDisabled=false
    //     }
    // }
   
   }
   get f() {
    return this.projectApproverForm?.controls;
  }
  
  editprojectapprover(approver){
    console.log("approver from assign component::",approver);
    
    console.log("approver Id::",approver.projectapproverId);

    // this.router.navigate(['home/add-assaign-approver', approver]);
    this.router.navigate(['/home/add-assaign-approver', { projectapproverId: approver.projectapproverId ,approver: JSON.stringify(approver) }]);
data:approver
  }
  SearchApproveProject() {
    this.search=''
    this.approverList=[]
    console.log("approver list::" ,this.approverList);
    
    console.log("form values::",this.projectApproverForm.value);
    
    console.log("Project::",this.projectApproverForm.value.project);
    // console.log("Approver Type::",this.projectApproverForm.value.approver.apporvers.approverId);
    
    

    if((this.projectApproverForm.value.project==undefined || this.projectApproverForm.value.project=='' ||  this.projectApproverForm.value.project==null) && (this.projectApproverForm.value.approver==undefined || this.projectApproverForm.value.approver=='' || this.projectApproverForm.value.approver==null)){
      alert("Please choose Any Field to Search!!")
      return
    }
this.tableShow=false
    if((this.projectApproverForm.value.project!==undefined || this.projectApproverForm.value.project!==''|| this.projectApproverForm.value.project!==null) && (this.projectApproverForm.value.approver==undefined || this.projectApproverForm.value.approver=='' || this.projectApproverForm.value.approver==null)){
      console.log("only project calling here!!!");
      this.isButtonDisabled = true;
      this.loader=true

      this.gs.onlyprojectApprovers(this.projectApproverForm.value.project).subscribe((response:any)=>{
        console.log(response);
        this.loader=false
        this.tableShow=true
        this.nodata=false
        this.approverList=response
        console.log("this,approverlist::",this.approverList);
        
        
        this.isButtonDisabled = true;
        if(response.message==='No Data'){
          this.approverList.length=0
          this.nodata=true
          this.tableShow=true

          this.isButtonDisabled=false
        }
        this.isButtonDisabled=false


        
      },
    (error)=>{
      this.nodata=true
      this.tableShow=true
      this.isButtonDisabled=false
    })



      
    }
    else if((this.projectApproverForm.value.project==undefined || this.projectApproverForm.value.project=='' || this.projectApproverForm.value.project==null) && (this.projectApproverForm.value.approver!==undefined  || this.projectApproverForm.value.project=='' || this.projectApproverForm.value.project==null) && (this.projectApproverForm.value.approver!==undefined || this.projectApproverForm.value.approver!==null ||  this.projectApproverForm.value.approver!=='')){
      console.log("Approver Type only calling here!!!");
      this.loader=true
      this.isButtonDisabled = true;

      this.gs.onlyApproverType(this.projectApproverForm.value.approver).subscribe((response:any)=>{
        console.log(response);
        this.loader=false
        this.tableShow=true

        this.nodata=false
        this.approverList=response
        // this.approverList.push(response)
        
        // this.approverList = Object.keys(response.approvers).map(key => response.approvers[key]);
        console.log(" this.approverList", this.approverList);
        
        this.isButtonDisabled = true;
        if(response.message==='No Data'){
          this.approverList.length=0
          this.nodata=true
          this.tableShow=true

          this.isButtonDisabled=false
        }
        this.isButtonDisabled=false


        
      },
      (error:any)=>{
        console.log("error::", error);
        if(error.message==='No Data'){
          this.nodata=true
          this.tableShow=true
          this.isButtonDisabled=false
        }
        
   
      }
    )
      
    }
    else{
      console.log("calling both project and Approver Type!!");
      this.isButtonDisabled = true;
      this.loader=true

      this.gs.projectapprovertypeAll(this.projectApproverForm.value.approver,this.projectApproverForm.value.project).subscribe((response:any)=>{
        console.log(response);
        this.loader=false
        this.tableShow=true
        this.nodata=false
        this.approverList=response
        console.log("this.approverlist::;", this.approverList);
        
        this.isButtonDisabled = true;
        if(response.message==='No Data'){
          this.approverList.length=0
          this.nodata=true
          this.tableShow=true

          this.isButtonDisabled=false
        }
        this.isButtonDisabled=false

      },
      (error)=>{

        console.log("error");
        if(error.errorCode==204){
          this.nodata=true
          this.loader=false
          this.tableShow=true

          this.approverList.length=0

          this.isButtonDisabled=false

        }
        
       
      }
    )
      
    }
    

    }
  changepage(){
    // if(this.search.length != 0)
    // {
    //   this.p = 1;
    // }else{
    //   this.p = this.beforesearchpage;
    // }
    console.log(this.approverList.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.approverList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.approverList.length);
      console.log("nodata");
    }
  }
  
  viewProject(e){
    // const dialogRef = this.dialog.open(AddassignapproverComponent, {
    //   width: '500px',
    //   height:'fit-content',
      data : e
    // })
    this.router.navigate(['/home/add-assaign-approver']);
  }
    changefirstpage() {
                    this.p = 1;
                    }
  sortData(sort: Sort) {
    const data = this.approverList.slice();
    if (!sort.active || sort.direction === '') {
      this.approverList = data;
      return;
    }
  
    this.approverList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'rulename':
          return this.compare(a.approvers.rulename, b.approvers.rulename, isAsc);     
          case 'projectName':
            return this.compare(a.project.projectName, b.project.projectName, isAsc);  
            case 'approver':
              return this.compare(a.approver.approverName, b.approver.approverName, isAsc);             
        default:
          return 0;
      }
    });
  }
  
   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  clearForm() {
    this.projectApproverForm.reset()
    this.approverList.length=0
    // this.ApproverList.length=0
    // this.nodata=false;
    this.tableShow=false;
    // this.projectList.length=0

  }

}
