Sub header start here
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content"> {{empannexueId? "Edit" : "Add"}} Employee Annexure</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/employee-annexue-list" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">      
            <div class="card-body">
                <form [formGroup]="postForm" (ngSubmit)="onSubmit()">                               
                    <div class="row" style="max-height: 110px">     
                    
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Payroll Run Level<span class="star">*</span></label>
                            <mat-select 
                              formControlName="PayrollRun" 
                              placeholder="- Select Payroll Run Level -" 
                              style="border-radius: 5px; outline:none; font-family: Mulish;" 
                              (selectionChange)="onCityChange($event)" 
                              class="form-control select-placeholder email-input">
                              
                              <mat-option [value]="null" selected="selected"> --- Payroll Run Level --- </mat-option>
                              
                              <mat-option [value]="1">Business Group</mat-option>
                              <mat-option [value]="2">Business Unit</mat-option>
                              <mat-option [value]="3">Organization</mat-option>
                            </mat-select>
                            
                            <div class="valid-msg" *ngIf="(validate.PayrollRun.invalid && validate.PayrollRun.touched) || validate.PayrollRun.dirty || submitted">
                              <span *ngIf="validate.PayrollRun.errors?.required" class="text-danger">Please select a Payroll Run Level</span>
                            </div>
                          </div>
                          

                          <div class="form-group col-md-3">
                            <label class="form-control-label">Payroll Run Level Name<span class="star">*</span></label>
                            <mat-select formControlName="PayrollLevel"   placeholder="- Payroll Run Level Name -" style="border-radius: 5px;outline:none;font-family: Mulish;"  class="form control
                            select-placeholder
                            email-input">
                                <mat-option [value]="null" selected="selected"> ---Payroll Run Level Name --- </mat-option>
                                <mat-option  *ngFor="let row of BusinessUnit; let i=index;" [value]="row.businessGroupId" (click)="passedId(row)" >{{row.businessGroupName}}</mat-option>

                                <mat-option  *ngFor="let row of BusinessName; let i=index;" [value]="row.businessUnitId"(click)="passedId(row)" >{{row.businessUnitName}}</mat-option>
                                <mat-option *ngFor="let row of OrganizationName; let i=index;"[value]="row.organizationId"(click)="passedId(row)" >{{row.organizationName}}</mat-option>

                            </mat-select>
                            <!-- <div class="valid-msg" *ngIf="(validate.livingCity.invalid && validate.livingCity.touched) || validate.livingCity.dirty || submitted">
                                <span *ngIf="validate.livingCity.errors?.required" class="text-danger">Please select living city</span>
                            </div> -->
                        </div>

                        <div class="form-group col-md-3">
                            <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                search
                            </mat-icon> -->
                            <label class="form-control-label">Employee<span class="star">*</span></label>
                            <input type="text"matInput  placeholder="Search Employee"   
                                        formControlName="employee"
                                        [matAutocomplete]="auto"
                                        (input)="validateInput($event)"
                                         class="form control
                                         select-placeholder

                                         email-input" style="border-radius: 5px;outline:none;font-family: Mulish;" (keyup)="empSearch($event)">
                                <mat-autocomplete #auto="matAutocomplete" >
                                    <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                        </mat-option> -->
                                    <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                    {{emp.employeeNumber}}- {{emp.firstName}}  
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:36px;right: 19px;">
                                    search
                                </mat-icon>
                            <div class="valid-msg" *ngIf="submitted">        
                                <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label">CTC<span class="star">*</span></label>
                            <input type="text" formControlName="ctc" id="ctc" maxlength="10" (keyup)="calcuatectc($event) " (keypress)=" onlyNumber($event)" class="form control
                            select-placeholder
                            email-input" style="border-radius: 5px;outline:none;font-family: Mulish;" placeholder="CTC" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.ctc.invalid && validate.ctc.touched) || validate.ctc.dirty || submitted">
                                <span *ngIf="validate.ctc.errors?.required" class="text-danger">Please enter ctc</span>
                                
                            </div>
                            <!-- <span *ngIf="calendarnameexist" class="text-danger">{{existMsg}}</span> -->
                        </div>         
                        
                    </div> 
                    <div class="row">


                        <div class="form-group col-md-3">
                            <label class="form-control-label">Effective From Date<span class="star">*</span></label>
                            <input formControlName="startDate" id="startDate" readonly  matInput [matDatepicker]="startDate" class="form control
                            select-placeholder
                            email-input"                     (dateChange)="startDates($event)"
                            style="border-radius: 5px;outline:none;font-family: Mulish;"
                                [min]="maxstartdate" placeholder="Select StartDate"  >
                            <mat-datepicker-toggle matSuffix [for]="startDate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #startDate  
                            panelClass="example-month-picker"></mat-datepicker>

                            <div class="valid-msg" *ngIf="(validate.startDate.invalid && validate.startDate.touched) || validate.startDate.dirty || submitted">
                                <span class="text-danger" *ngIf="validate.startDate.errors?.required">Please select start date</span>
                            </div>
                        </div>           

                        <div class="form-group col-md-3">
                            <label class="form-control-label">Effective To Date<span class="star">*</span></label>
                            <input formControlName="endDate" id="endDate"  readonly  matInput [matDatepicker]="endDate" class="form control
                            select-placeholder
                            email-input"                     (dateChange)="EndDate($event)"
                            style="border-radius: 5px;outline:none;font-family: Mulish;"
                                    [min]="postForm.value.startDate" placeholder="Select End Date" >
                            <mat-datepicker-toggle matSuffix [for]="endDate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>

                            <div class="valid-msg" *ngIf="(validate.endDate.invalid && validate.endDate.touched) || validate.endDate.dirty || submitted">
                                <span class="text-danger" *ngIf="validate.endDate.errors?.required">Please select end date</span>
                            </div>
                            <mat-error
                            *ngIf="show_error_msg"
                            style="font-family: Mulish"
                          >
                            Start date should not be  greater than End date.
                          </mat-error>
                            <!-- <span *ngIf="dateexist" class="text-danger">{{dateexistMsg}}</span> -->
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Living City<span class="star">*</span></label>
                            <mat-select formControlName="livingCity" placeholder="- Select living City -" style="border-radius: 5px;outline:none;font-family: Mulish;" (selectionChange)="gethrapercentage()" class="form control
                            select-placeholder
                            email-input">
                                <mat-option [value]="null" selected="selected"> ---Select Living City --- </mat-option>
                                <mat-option *ngFor="let row of livingcity; let i=index;" [value]="row.listTypeValueId">{{row.listTypeValueName}}</mat-option>
                            </mat-select>
                            <div class="valid-msg" *ngIf="(validate.livingCity.invalid && validate.livingCity.touched) || validate.livingCity.dirty || submitted">
                                <span *ngIf="validate.livingCity.errors?.required" class="text-danger">Please select living city</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Place<span class="star">*</span></label>
                            <input type="text" formControlName="place" id="place"  class="form control
                            select-placeholder
                            email-input" style="border-radius: 5px;outline:none;font-family: Mulish;" placeholder="Place" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.place.invalid && validate.place.touched) || validate.place.dirty || submitted">
                                <span *ngIf="validate.place.errors?.required" class="text-danger">Please enter place</span>
                                <span *ngIf="validate.place.errors?.pattern" class="text-danger">Please enter valid place</span>
                            </div>
                            <!-- <span *ngIf="calendarnameexist" class="text-danger">{{existMsg}}</span> -->
                        </div>         
                    
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <input (click)="addelement()" style=""  type="button"  class=" btn btn-primary btn-sm " value="Add" />
                            <div>
                                <span class="text-danger"  *ngIf="showctcerror">Please enter employee annual ctc</span>
                            </div>
                            
                        </div>

                    </div>
                    
                    <div class="col-md-12">
                        <div class="table-responsive scroll"   *ngIf=" getFormControls.controls.length != 0 " style="max-height: 363px;overflow: auto; overflow-x: hidden;">
                            <table class="table table-border">
                                <thead class="text-nowrap">
                                    <th>Element Name<span class="text-danger"> *</span><span class="resizer"></span></th>
                                    <th>Element Percentage <span class="text-danger"> *</span><span class="resizer"></span></th>
                                    <th>Per Month <span class="text-danger"> *</span><span class="resizer1"></span></th>
                                    <th>Per Annum<span class="text-danger"> *</span><span class="resizer1"></span></th>
                                    
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    <ng-container formArrayName="tableRows" 
                                    *ngFor="let group of getFormControls.controls ; let i=index">
                                    <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                                        <td>
                                            <div>
                                                <select   formControlName = "element"  class="form-select form-select-solid form-select-lg" (change)="getelemenetpercentage(group ,i,$event)">
                                                    <option value="null">- Select -</option>
                                                    <option *ngFor="let row of elementlist; let i=index;" value="{{row.elementId}}">{{row.elementName}}</option>
                                                </select>
                                                <div *ngIf="(submitted && group['controls'].element.invalid) || group['controls'].element.dirty">
                                                <small *ngIf="group['controls'].element.errors?.required" class="text-danger">Please select element</small>  
                                            </div> 
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <select   formControlName = "hrElementPercentage"  (change)="calcuatectcline(group , i)" class="form-select form-select-solid form-select-lg">
                                                    <option *ngIf="postForm.value.livingCity == null && group.value.elementName == 'HRA' " value="null" selected>- Select  living city -</option>
                                                    <ng-container 
                                                        *ngFor="let dcrow of group.value.hrElementPercentagelist; let di=index">
                                                        
                                                    <option  [disabled]=" group.value.elementName == 'HRA'"  [value]="dcrow.elementPercentage">{{dcrow.elementPercentage}}</option>
                                                    </ng-container>
                                                </select>
                                                <div *ngIf="(submitted && group['controls'].hrElementPercentage.invalid) || group['controls'].hrElementPercentage.dirty">
                                                <small *ngIf="group['controls'].hrElementPercentage.errors?.required" class="text-danger">Please select Document</small>  
                                            </div> 
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input type="text" readonly style="text-align: right;" formControlName="salaryPerMonth" id="salaryPerMonth" [ngClass]="{ 'is-invalid': group['controls'].errors }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name"  autocomplete="off">
                                                <div *ngIf="(submitted && group['controls'].salaryPerMonth.invalid) || group['controls'].salaryPerMonth.dirty">
                                                <small *ngIf="group['controls'].salaryPerMonth.errors?.required" class="text-danger">Please enter salary per month</small>  
                                            </div> 
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input type="text" readonly   style="text-align: right;" formControlName="salaryPerAnnum" id="salaryPerAnnum" [ngClass]="{ 'is-invalid': group['controls'].errors }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name"  autocomplete="off">
                                                <!-- <div class="valid-msg" *ngIf=" group['controls'].salaryPerAnnum.errors">Please enter salary per annum -->
                                                    <!-- <span class="text-danger" *ngIf="group['controls'].salaryPerAnnum.errors?.required">Please enter Description</span>
                                                    <span class="text-danger" *ngIf="group['controls'].salaryPerAnnum.errors?.pattern">Please enter valid Description</span> -->
                                                <!-- </div> -->
                                                <div *ngIf="(submitted && group['controls'].salaryPerAnnum.invalid) || group['controls'].salaryPerAnnum.dirty">
                                                    <small *ngIf="group['controls'].salaryPerAnnum.errors?.required" class="text-danger">Please enter salary per annum</small>  
                                                </div>
                                            </div>
                                            
                                        </td>
                                        
                                        <td style="text-align: center">  
                                            <mat-icon *ngIf=" group.value.elementName != 'Basic Salary' &&  group.value.elementName != 'HRA' " class="material-symbols-outlined delete" (click)="deleteRow(i , group)" style="color:red">delete</mat-icon>
                                        </td>
                                    </tr>
                                    <!-- <tr *ngIf="!group.get('isEditable').value" >
                                        <td>
                                            {{group.get('taskname').value}}
                                        </td>
                                        <td>
                                            {{group.get('taskdescription').value}}
                                        </td>
                                        <td>
                                            {{group.get('startdate').value}}
                                        </td>
                                        <td>
                                            {{group.get('enddate').value}}
                                        </td>
                                        <td>
                                            {{group.get('hours').value}}
                                        </td>
                                        <td>
                                            {{group.get('minutes').value}}
                                        </td>
                                        <td>
                                                <mat-icon class="edit" (click)="editRow(group)">edit</mat-icon> 
                                        </td>
                                    </tr> -->
                                    
                                    </ng-container>
                                    <tr>
                                        <td>
                                            <button *ngIf="this.postForm.value.ctc != this.totalannualincome" type="button" (click)="validateannexure()" class="submit action-button btn btn-primary btn-sm" style="margin-right: 20px;"
                                            >Validate Annexure</button>          
                                        </td>
                                        <td colspan="1" class="text-right">
                                            <b>Total :</b>
                                        </td>
                                        <td  class="text-right">
                                            {{(calucuatemontly())?.toFixed(2)}}
                                        </td>
                                        <td class="text-right">
                                            {{(calucuateyearly())?.toFixed(2)}}
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <small *ngIf="!checktotalamount || postForm.value.ctc != this.totalannualincome" class="text-danger"> CTC detail breakup is not matching with CTC.                                    !</small>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" *ngIf="selectedEmployeeData?.length > 0">
                            <div class="row">
                                <div class="col-md-3 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-3 text-left pl-0">
                                    <select [(ngModel)]="pagePerItem" (change)="pageChages()"  class="form-control report-filter">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                        <!-- <option value="1500">1500</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <!-- <button type="reset" class="submit action-button btn btn-sm mr-2" (click)="clear()"  style="margin-right: 20px;" mat-raised-button
                            >Clear</button>  -->
                            <!-- <div class="submit action-button btn btn-sm mr-2">
                                <a routerLink="/home/employee-annexue-list" mat-raised-button title="Back" class="">Cancel</a>
                            </div>  
                            
                            -->
                            <button type="button"   class="submit"      mat-raised-button   [routerLink]="['/home/employee-annexue-list']"   style="

 
                            background: none;
 
                            border: 1px solid #ddd;
                                font-size: 14px;
                            font-weight: 300;
                              height: 40px !important;
                                 "  
                               style="color:var(--Red-Color, #FF5D5D); height:35px; margin-right: 1%;"
                                >Cancel</button>
                            <!-- <button type="submit" mat-raised-button color="primary">Save</button> -->
                            <button type="submit" mat-raised-button
                            class="searchBtn " [disabled]="isProcessing ||postForm.invalid"
                            >
                            Save
                                                </button>
                        </div>
                    </div>
                </form>
            </div>
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <!-- <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records </button>-->
   
</div>


<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>