import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { map, Observable, Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { Router, ActivatedRoute, Routes, NavigationEnd } from '@angular/router';

import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { UserService } from '../providers/user.service';

/* import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs/lib/stomp.js';
 */

//import { Stomp } from '@stomp/stompjs';
import Swal from 'sweetalert2';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

//import { base_url,httpOptions,responseMsgTimeOut} from '../providers/properties';
import { NotificationResourceDetailComponent } from '../recruitment/notification-resource-detail/notification-resource-detail.component';
import { httprecruitmentOptions } from '../providers/properties';
import { GlobalConstants } from '../../common/global-constants';
import { FormGroup } from '@angular/forms';
import { SuperadminService } from '../providers/superadmin.service';
import { HeaderService } from '../providers/header.service';
// import { ProjectManagementAdminComponent } from '../project-management/project-management-admin/project-management-admin.component';
import { TimesheetService } from '../providers/timesheet.service';
import { environment } from 'src/environments/environment';
import { AppStateServiceService } from '../app-state-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class  HomeComponent implements OnInit {
  assignApproversActive: boolean;
  showAdditionalIcons: boolean = false;
  isSideNavClosed: boolean = false;
  appLogo = GlobalConstants.appLogo;
  appLogoIcon = GlobalConstants.appLogoIcon;
  hideMenus: Boolean = true;
  // sideNavContent = '228px';
 shouldShowSupplierMenu:any

  notification: boolean = true;
  JesperApps: boolean = false;
  hideModules: Boolean = true;
  expand: Boolean;
  expandModules: Boolean;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  moduleBackground = '';
  moduleColor = '';
  Swal: any;
  roleName:any
  isShow: boolean = false;
  isShowValue: any;
  active: boolean;
  isShowsettings: boolean = false;
  shrink: Boolean;
  right: Boolean;
  isshowRecruitmentArrow: boolean = false;
  usertype: any;
  employeeName: string;
  stompClient: any;
  notificationlist: any = [];
  notifylist: any;
  id1: any;
  id2: any;
  delete_notify: any = [];
  notificationcount: any = 0;
  isShow1: boolean = false;
  localStorageMenuId: any;

  first_segment: any;
  second_segment: any;
  currentRoute: string;
  isShowPayroll: boolean = false;
  isShowPms: boolean = false;
  expandPayroll: Boolean;
  expandApprovers: Boolean;
  client: Boolean;
  reports: Boolean;
  isShowClient: boolean = false;
  empAssign: Boolean;
  isShowEmpAssign: boolean = false;
  isShowShift: boolean = false;
  isShowApprover: boolean = false;
  isShowLoan: boolean = false;
  isShowAdvance: boolean = false;
  isShowProject: boolean = false;
  isShowAdvanceProject: boolean = false;
  isShowRequirement: boolean = false;
  expandAdvance: boolean;
  expandAdvanceProjects: boolean;
  expandLoan: boolean;
  isShowReport: boolean = false;
  isShowApprisal: boolean = false;
  SetupReports:boolean=false;
  menudata: any = [];
  moduledata: any = [];
  usertData: any;
  userTypeId: any;
  role: any;
  user: any;
  momlist: boolean;
  requireList: boolean;
  isShowmomlist: boolean = false;
  headerTitle$: Subscription;
  moduleName$: Subscription;
  expandRecruitment: boolean;
  resultData: any = {};
  resultDatarec: any;
  errorMsg: any;
  admindashboard: any;
  ProfileShared: number;
  Interviewed: any;
  isshowMom: boolean = false;
  Notinterviewed: number;
  recruiterSearchform: FormGroup;
  @Input() requiredError: boolean;
  reportfromdate: any;
  reporttodate: any;
  startmaxdate: Date = new Date();
  endmindate;
  recruiterlist: any;
  loader: any = false;
  backdrop: any = false;
  dataForExcel = [];
  true: any;
  interviewStatuslist: any;
  success: any = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  results: any = [];
  selectedMenuTitle: string = '';
  selectedModule: string = '';
  moduleName: string = '';
  moduleNameTitle: string = '';
  // localStorageMenuId: any;
  moduleId: any;
  menuName: string;

  showTimesheetTooltip: boolean = false;
  showDashboardTooltip: boolean = false;
  showEmployeeDashboardTooltip: boolean = false;
  assignApprovers: boolean = false;
  manageTimesheet: boolean = false;
  projects: boolean = false;
  manageSalaryApprovals: boolean = false;
  addTask: boolean = false;
  empProjectRoles: boolean = false;
  projectsAddEmployeeTask: boolean = false;
  TaskGroup: boolean = false;
  assignEmployeeProject: boolean = false;
  projectStage: boolean = false;
  assignEmployeeTask: boolean = false;
  dailyTimesheet: boolean = false;
  consolidateTimesheet: boolean = false;
  employeeTaskReport: boolean = false;
  salaryAdvance: boolean = false;
  salaryAdvanceManageSalaryApprovals: boolean = false;
  loan: boolean = false;
  manageApprover: boolean = false;
  employeeApprover: boolean = false;
  manageApproverToolTip: boolean = false;
  employeeApproverToolTip: boolean = false;
  selectedModuleName: any;
  clientWorkingDaysTooltips: boolean = false;
  clientsTooltips: boolean = false;
  clientElementsTooltips: boolean = false;
  clientTaxBreakUp: boolean = false;
  employeeClientAssignment: boolean = false;
  addClientEmployee:boolean=false
  momReports: boolean = false;
  recruitment: boolean = false;
  position: boolean = false;
  createMom: boolean = false;
  actionItemReport: boolean = false;
  settings: boolean = false;
  signOut: boolean = false;
  isSelected: boolean = false;
  routerLink: any;
  momPath: any;
  isShowSalary: boolean = false;
  selectModule: boolean = false;
  isShowLoanArrow: boolean = false;
  isShowApproverArrow: boolean = false;
  isShowClientArrow: boolean = false;
  appraisalList: boolean = false;
  appraisalIcon: boolean = false;
  isAppraisalReportsSelected: boolean = false;
  viewReports: boolean = false;
  currentUrl: string = '';

  //pms//
  addKra: boolean = false;
  assignKra: boolean = false;
  appraisal: boolean = false;
  assignmentReports: boolean = false;

  assignkratemplate: boolean = false;
  isShowPayrollArrow: boolean = false;

  recruitmentList: boolean = false;
  userType: any;
  payrollReports: boolean = false;

  //Leave //
  showleaveDashboardTooltip: boolean = false;
  showempleaveDashboardTooltip: boolean = false;
  showleavecalendarTooltip: boolean = false;
  requestList: boolean = false; //--requestList
  requestListArrowIcon: boolean = false;
  myLeaveListToolTip: boolean = false;
  myPermissionToolTip: boolean = false;
  mywfhlist: boolean = false;
  BusinessTravelList: boolean = false;
  Reports: boolean = false; //-Reports
  reportsListArrowIcon: boolean = false;
  LeaveDetail: boolean = false;
  PermissionDetail: boolean = false;
  WorkfromHome: boolean = false;
  businesstravel: boolean = false;
  Manage: boolean = false; //-manage
  ManageListArrowIcon: boolean = false;
  leaverequest: boolean = false;
  managepermission: boolean = false;
  managewfh: boolean = false;
  managebusinesstravel: boolean = false;
  managewithdrawalrequest: boolean = false;
  Setups: boolean = false; //-setups
  SetupsListArrowIcon: boolean = false;
  leavebalance: boolean = false;
  leaveadjustment: boolean = false;
  Settings: boolean = false; //-settings
  SettingsListArrowIcon: boolean = false;
  leavetype: boolean = false;
  emptimesettings: boolean = false;
  workingDaysToolTip: boolean = false;
  employeeRecoveryAdjustmentToolTip: boolean = false;
  employeeRevenueAdjustmentToolTip: boolean = false;
  managePayrollToolTip: boolean = false;
  managePaySlipToolTip: boolean = false;
  paySlip: boolean = false;
  manageAnnexure: boolean = false;
  manageElementsToolTip: boolean = false;
  employeeLeaveToolTip: boolean = false;
  employeeRating: boolean = false;
  assignEmployee: boolean = false;
  announcement:boolean = false;
  manageVariablePayToolTip: boolean = false;
  consolidatedReport: boolean = false;
  organizationcomplaice: boolean = false;
  elementFormula:boolean=false;

  pFReport: boolean = false;
  tDSReportToolTip: boolean = false;
  consolidatePayslipReport: boolean = false;
  esiReport: boolean = false;
  netpayreport:boolean=false;

  shiftSchedulingArrow:boolean = false;
  isRequirements: any;
  isAdmin: any;
  isEmployee:any;
  isUserAdmin:boolean = false;
  isTeamLead:boolean = false;
  
 
  

  //CHRMS
  showEmployeesTooltip:boolean =false;
  showNewsfeedTooltip:boolean = false;
  isHumanResource: any;
  isSetup:boolean;
  isSetupList:boolean;
  isShowArrowClient:boolean = false;
   isOpenOrganizationandClient:boolean = false;
   clientLogin: boolean = false;
   organizationLogin: boolean = false;
   entityAdminLogin: boolean = false;

   isShowAdmin:boolean =false;
   showApproverMenu:boolean = false;
   userdata:any
   organizationId:any;
   entityId:any;
   clientId:any
  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private UserService: UserService,
    private activatedRoute: ActivatedRoute,

    private superadminservice: SuperadminService,
    private headerservice: HeaderService,
    private route: ActivatedRoute,
    public router: Router,
    private timesheet: TimesheetService,
    private cdr: ChangeDetectorRef,
          private appStateService: AppStateServiceService
    

  ) {

    
   
    const moduleCode = localStorage.getItem('moduleCode');
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = this.userdata.organization.organizationId;
    console.log(this.organizationId )
    this.entityId = this.userdata.entity.entityId;
    console.log(this.entityId)
    this.clientId=this.userdata?.employee?.client?.clientId
    console.log(this.clientId)

    this.showApproverMenu = !!this.organizationId || (!!this.organizationId && !!this.entityId);

    if (!!this.entityId && !this.organizationId) {
      this.showApproverMenu = false;
    }
    if (!!this.clientId) {
      this.showApproverMenu = false;
    }

    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = this.userdata.organization.organizationId;
    console.log(this.organizationId )
    this.entityId = this.userdata.entity.entityId;
    console.log(this.entityId)
    this.clientId=this.userdata?.employee?.client?.clientId
    console.log(this.clientId)

    this.showApproverMenu = !!this.organizationId || (!!this.organizationId && !!this.entityId);

    if (!!this.entityId && !this.organizationId) {
      this.showApproverMenu = false;
    }
    if (!!this.clientId) {
      this.showApproverMenu = false;
    }


    if (moduleCode == 'CHRMS') {  
      // this.router.navigate(['/home/dashboard']);
    }

    console.log('moduleCode', moduleCode);

    this.getAllModules();
    

    // for (let i = 0; i < localStorage.length; i++) {
    //   const key = localStorage.key(i);
    //   if (key && key.startsWith('module_')) {
    //     const moduleData = localStorage.getItem(key);
    //     if (moduleData) {
    //       this.results.push(JSON.parse(moduleData));
    //     }
    //   }
    // }
    console.log('All modules:', this.results);

    const urlViewReports = this.route.snapshot.url.map(
      (segment) => segment.path
    );
    console.log('urlSegment', urlViewReports);

    const idIndexView = urlViewReports.indexOf('projects/emp-report') + 1;
    const idView = urlViewReports[idIndexView];
    const idIndexViewrepo = urlViewReports.indexOf('projects/emp-report') + 1;
    const idViewRepo = urlViewReports[idIndexViewrepo];

    console.log('routerurl', this.router.url);

    if (
      this.router.url == '/home/working-days' ||
      this.router.url == '/home/recovery-adjustment-list' ||
      this.router.url == '/home/manage-employee-revenue-adjustment' ||
      this.router.url == '/home/employee-payroll-list' ||
      this.router.url == '/home/manage-payslip' ||
      this.router.url == '/home/employee-payslip' ||
      this.router.url == '/home/employee-annexue-list' ||
      this.router.url == '/home/manage-employee-leave' ||
      this.router.url == '/home/employee-rating-list' ||
      this.router.url == '/home/manage-variablepay' ||
      this.router.url == '/home/recovery-adjustment' ||
      this.router.url == '/home/resource-list' ||
      this.router.url == '/home/add-resource' ||
      this.router.url == '/home/requirement-add' ||
      this.router.url == '/home/employee-annexue' ||
      this.router.url == '/home/employee-rating' ||
      this.router.url == '/home/add-employee-leave' ||
          this.router.url == '/home/select-manage-elements'
    ) {
      this.isShowPayroll = true;
      this.isShowPayrollArrow = true;
    }

    if (
      this.router.url == '/home/consolidatereport-list' ||
      this.router.url == '/home/pfreport-list' ||
      this.router.url == '/home/tdsreport-list' ||
      this.router.url == '/home/client-consolidatePayslip'
    ) {
      this.isShowReport = true;
      this.payrollReports = true;
    }
    

    if (
      this.router.url == '/home/organizationcomplaice' ||
       this.router.url == '/home/organizationcomplaice-list'
    
    ) {
      this.isSetup = true;
      this.isSetupList = true;
    }
    

    if (
      this.router.url == '/home/manage-elements' ||
      this.router.url == '/home/clients' ||
      this.router.url == '/home/manage-client-element-list' ||
      this.router.url == '/home/client-tax-breakup' ||
      this.router.url == '/home/empassign' ||
      this.router.url == '/home/select-workingdays' || 
      this.router.url == '/home/organization' ||
      this.router.url == '/home/select-elements' || 
      this.router.url == '/home/select-tax-breakup' ||
       this.router.url == '/home/select-emp-transfer'||
       this.router.url == '/home/assigns-cliemp-list'


    ) {
      this.isShowClient = true;
      this.isShowClientArrow = true;
    }

    if (
      this.router.url == '/home/settings' ||
      this.router.url == '/home/role' ||
      this.router.url == '/home/branch' ||
      this.router.url == '/home/add-branch' ||
      this.router.url == '/home/department' ||
      this.router.url == '/home/designation' ||
      this.router.url == '/home/qualification' ||
      this.router.url == '/home/financialyear' ||
      this.router.url == '/home/variablepayqualifier' ||
      this.router.url == '/home/element-percentage' ||
      this.router.url == '/home/manage-work-hours' ||
      this.router.url == '/home/application-settings' ||
      this.router.url == '/home/company-settings'
    ) {
      // this.isShowSettings = true;
      // this.isShowClientArrow = true;
    }

    if (
      this.router.url == '/home/appraisal' ||
      this.router.url == '/home/add-appraisal' ||
      this.router.url == '/home/appraisalview/' ||
      this.router.url == '/home/reports-appra'
    ) {
      this.appraisalList = true;
      this.appraisalIcon = true;
    }

    if (
      this.router.url == '/home/myleave-list' ||
      this.router.url == '/home/apply-leave' ||
      this.router.url == '/home/mypermission-list' ||
      this.router.url == '/home/apply-permission' ||
      this.router.url == '/home/mywfh-list' ||
      this.router.url == '/home/apply-wfh' ||
      this.router.url == '/home/mybusinesstravel-list' ||
      this.router.url == '/home/apply-businesstravel'
    ) {
      this.requestListArrowIcon = true;
      this.requestList = true;
    }
    if (
      this.router.url == '/home/manage-approver-list' ||
      this.router.url == '/home/manage-approver' ||
      this.router.url == '/home/manage-empl-approver' ||
      this.router.url == '/home/manage-emp-approver-list'
    ) {
      this.isShowApprover = true;
      this.isShowApproverArrow = true;
    }
    if (
      this.router.url == '/home/shift' ||
      this.router.url == '/home/shift-calendar' ||
      this.router.url == '/home/emp-shift-list' ||
      this.router.url == '/home/employee-shift'
    ) {
      this.isShowShift = true;
      this.shiftSchedulingArrow = true;
    }
    if (
      this.router.url == '/home/client-workingdays' ||
      this.router.url == '/home/clients' ||
      this.router.url == '/home/add-client' ||
      this.router.url == '/home/manage-client-element-list' ||
      this.router.url == '/home/clientElementassign' ||
      this.router.url == '/home/client-tax-breakup' ||
      this.router.url == '/home/empassign' ||  this.router.url == '/home/organization' 
      || this.router.url == '/home/add-organization'
      
    ) {
      this.isShowClient = true;
      this.isShowClientArrow = true;
    }
    if (
      this.router.url == '/home/leave-Detail' ||
      this.router.url == '/home/withdrawList' ||
      this.router.url == '/home/permission-Detail' ||
      this.router.url == '/home/work-from-home' ||
      this.router.url == '/home/business-travel'
    ) {
      this.reportsListArrowIcon = true;
      this.Reports = true;
    }
    if (
      this.router.url == '/home/manage-leave-request' ||
      this.router.url == '/home/manage-permission' ||
      this.router.url == '/home/manage-wfh' ||
      this.router.url == '/home/manage-business-travel' ||
      this.router.url == '/home/manage-withdrawal-request'
    ) {
      this.ManageListArrowIcon = true;
      this.Manage = true;
    }
    if (
      this.router.url == '/home/leave-balance' ||
      this.router.url == '/home/leave-adjustment-list' ||
      this.router.url == '/home/leave-adjustment'
    ) {
      this.SetupsListArrowIcon = true;
      this.Setups = true;
    }
    if (
      this.router.url == '/home/type-list' ||
      this.router.url == '/home/emptimesetting-list'
    ) {
      this.SettingsListArrowIcon = true;
      this.Settings = true;
    }

    if (this.router.url === `/home/projects/emp-report/${idViewRepo}`) {
      console.log('checkRoute', this.router.url);

      this.isShowmomlist = false;
      this.isshowMom = false;
    }
    if (
      this.router.url === '/home/project-management' ||
      '/home/time-employee-dashboard'
    ) {
      console.log('this.router.url', this.router.url);
      // this.isShowAdvanceProject = true;
      // this.viewReports = true;
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      // if (this.isShowAdvanceProject == true) {
      //   this.isShow = true;
      // } else {
      //   this.isShow = false;
      // }
    }


  
    if (
      this.router.url === '/home/projects' ||
      this.router.url === `/home/projects/emp-report/${idViewRepo}`
    ) {
      console.log('this.router.url', this.router.url);
      this.isShowAdvanceProject = true;
      // this.viewReports = true;
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      if (this.isShowAdvanceProject == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
    if (this.router.url === '/home/add-multiple-task') {
      console.log('this.router.url', this.router.url);

      this.isShowAdvanceProject = true;
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      if (this.isShowAdvanceProject == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      // this.isShow = true;
    }

    if (this.router.url === '/home/add-employee-task') {
      console.log('this.router.url', this.router.url);

      this.isShowAdvanceProject = true;
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      if (this.isShowAdvanceProject == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }

    if (this.router.url === '/home/task-group') {
      console.log('this.router.url', this.router.url);

      this.isShowAdvanceProject = true;
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      if (this.isShowAdvanceProject == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }

    if (this.router.url === '/home/assign-project') {
      console.log('this.router.url', this.router.url);

      this.isShowAdvanceProject = true;
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      if (this.isShowAdvanceProject == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }

    if (this.router.url === '/home/project-stage') {
      console.log('this.router.url', this.router.url);
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      this.isShowAdvanceProject = true;
      if (this.isShowAdvanceProject == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
    //...............Recruitment..........................................//
    if (this.router.url === '/home/positions') {
      console.log('this.router.url', this.router.url);
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      this.isShowRequirement = true;
      if (this.isShowRequirement == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
    if (this.router.url === '/home/requirement') {
      console.log('this.router.url', this.router.url);
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      this.isShowRequirement = true;
      if (this.isShowRequirement == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
    if (this.router.url === '/home/resource-list') {
      console.log('this.router.url', this.router.url);
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      this.isShowRequirement = true;
      if (this.isShowRequirement == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
    if (this.router.url === '/home/onboardings') {
      console.log('this.router.url', this.router.url);
      this.isShowmomlist = false;
      this.isShowAdvance = false;
      this.isShowRequirement = true;
      if (this.isShowRequirement == true) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
    //..............recruitment end up

    const urlSegments = this.route.snapshot.url.map((segment) => segment.path);
    console.log('urlSegment', urlSegments);

    const idIndex = urlSegments.indexOf('view-mom') + 1;
    const id = urlSegments[idIndex];

    const urlSegments1 = this.route.snapshot.url.map((segment) => segment.path);
    console.log('urlSegment', urlSegments1);

    const idIndex1 = urlSegments1.indexOf('update-mom') + 1;
    const id1 = urlSegments1[idIndex1];

    if (this.router.url === '/home/mom-reports' || `/home/update-mom/${id1}`) {
      console.log('this.router.url', this.router.url);

      this.isShowmomlist = true;

      if (this.isShowmomlist == true) {
        this.isshowMom = true;
      } else {
        this.isshowMom = false;
      }

      this.momPath = `update-mom/${id1}`;
      // this.momPath2 = '/home/mom-reports'
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.router.url;
        const urlSegments = this.router.url.split('/');

        // Find the index of 'status'
        const statusIndex = urlSegments.indexOf('status');

        // Get the first and second IDs if the index is valid
        const firstId =
          statusIndex !== -1 && statusIndex + 1 < urlSegments.length
            ? urlSegments[statusIndex + 1]
            : null;
        const secondId =
          statusIndex !== -1 && statusIndex + 2 < urlSegments.length
            ? urlSegments[statusIndex + 2]
            : null;

        console.log('`${firstId}`', firstId);
        console.log('`${secondId}`', secondId);

        console.log('checkViewRecords', this.router.url, url);
        if (
          firstId &&
          this.router.url === `/home/projects/emp-report/${firstId}`
        ) {
          this.isShowmomlist = false;
          this.isshowMom = false;
          this.isShowAdvanceProject = true;
        }

        if (
          firstId &&
          secondId &&
          this.router.url === `/home/projects/status/${firstId}/${secondId}`
        ) {
          this.isShowmomlist = false;
          this.isshowMom = false;
          this.isShowAdvanceProject = true;
        }
        if (url === `/home/projects/emp-report/${firstId}`) {
          this.isShowmomlist = false;
          this.isshowMom = false;
          this.isShowAdvanceProject = true;
        }
        if (url.startsWith('/home/projects/emp-report/')) {
          this.isShowmomlist = false;
          this.isshowMom = false;
          this.isShowAdvanceProject = true;
        }
        if (url.startsWith('/home/employee-roles')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }

        if (url.startsWith('/home/dashboard')) {
          this.selectModule = true;
        }

        if (url.startsWith('/home/assign-approver')) {
          this.assignApproversActive = true;
        }

        if (url === `/home/view-mom/${firstId}`) {
          this.isShowmomlist = true;
          this.isshowMom = true;
          this.isShowAdvanceProject = false;
        }
        if (url.startsWith('/home/positions')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }
        if (url.startsWith('/home/view-position')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }

        if (url.startsWith('/home/requirement')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }

        if (url.startsWith('/home/resource-list')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }
        if (url.startsWith('/home/view-resource')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }
        if (url.startsWith('/home/resource')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }
        if (url.startsWith('/home/resourcefeedback')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }

        if (url.startsWith('/home/onboardings')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }
        if (url.startsWith('/home/onboardingdetail')) {
          this.isshowRecruitmentArrow = true;
          this.recruitmentList = true;
        }
        if (
          url === '/home/project-management' ||
          url === '/home/time-employee-dashboard' ||
          url === '/home/another-dashboard' ||
          url === '/home/time-sheet-reports' ||
          url === '/home/assign-approver' ||
          url === '/home/manage-timesheet' ||
          url === '/home/emp-timesheet' ||
          url === '/home/consolidate-timesheet' ||
          url === '/home/employee-task-report' ||
          url === '/home/daily-timesheet' ||
          url === '/home/add-assaign-approver'
        ) {
          this.isShowAdvanceProject = false;
          this.selectedModuleName = 'PROJECTMANAGEMENT';
        }

        if (
          url === '/home/shift' ||
          url === '/home/shift-calendar' ||
          url === '/home/emp-shift-list' 
   
        ) {
          this.isShowShift = true;
          this.shiftSchedulingArrow = true;
        }

        if (
          url === '/home/project-management' ||
          url === '/home/time-employee-dashboard' ||
          url === '/home/another-dashboard' ||
          url === '/home/time-sheet-reports' ||
          url === '/home/assign-approver' ||
          url === '/home/manage-timesheet' ||
          url === '/home/projects' ||
          url === '/home/add-multiple-task' ||
          url === '/home/add-employee-task' ||
          url === '/home/task-group' ||
          url === '/home/assign-project' ||
          url === '/home/project-stage' ||
          url === '/home/emp-timesheet' ||
          url === '/home/consolidate-timesheet' ||
          url === '/home/employee-task-report' ||
          url === '/home/daily-timesheet' ||
          url === '/home/add-assaign-approver' ||
          url === `/home/projects/status/${firstId}/${secondId}`
        ) {
          console.log('this.router.url', url);
          console.log('check showmom', this.isShowmomlist);

          this.isShowmomlist = false;
          this.isshowMom = false;
          this.selectedModuleName = 'PROJECTMANAGEMENT';
        } else if (
          url === '/home/mom-reports' ||
          url.startsWith('/home/update-mom/') ||
          url === '/home/view-mom/' ||
          url.startsWith('/home/view-mom/') ||
          url === '/home/mom-creation' ||
          url === '/home/action-item-report'

        ) {
          this.selectedModuleName = 'PROJECTMANAGEMENT';
          this.isShowmomlist = true;
          // Set other flags if necessary for these routes
        } else {
          // Handle other routes if needed
          this.isShowmomlist = false;
          this.isshowMom = false;
        }
      });

    if (this.router.url === `/home/view-mom/${id1}`) {
      console.log('this.router.url', this.router.url);

      this.isShowmomlist = true;
      if (this.isShowmomlist == true) {
        this.isshowMom = true;
      } else {
        this.isshowMom = false;
      }
    }

    if (this.router.url === `/home/update-mom/${id1}`) {
      console.log('this.router.url', this.router.url);

      this.isShowmomlist = true;

      if (this.isShowmomlist == true) {
        this.isshowMom = true;
      } else {
        this.isshowMom = false;
      }
    }

    if (this.router.url === `/home/view-mom/${id1}`) {
      console.log('this.router.url', this.router.url);

      this.isShowmomlist = true;
      if (this.isShowmomlist == true) {
        this.isshowMom = true;
      } else {
        this.isshowMom = false;
      }
    }

    if (this.router.url === '/home/mom-creation') {
      console.log('this.router.url', this.router.url);

      this.isShowmomlist = true;
      if (this.isShowmomlist == true) {
        this.isshowMom = true;
      } else {
        this.isshowMom = false;
      }
    }

    if (this.router.url === '/home/action-item-report') {
      console.log('this.router.url', this.router.url);

      this.isShowmomlist = true;
      if (this.isShowmomlist == true) {
        this.isshowMom = true;
      } else {
        this.isshowMom = false;
      }
    }

    if (this.router.url === '/home/SalaryAdvance-list') {
      console.log('this.router.url', this.router.url);
      // this.salaryAdvanceOpenClose = false;
      this.isShowAdvance = true;
      if (this.isShowAdvance == true) {
        this.isShowSalary = true;
      } else {
        this.isShowSalary = false;
      }

      // this.isShowSalary = !this.isShowSalary;
    }

    if (this.router.url === '/home/salary-manage-approvals') {
      console.log('this.router.url', this.router.url);
      // this.salaryAdvanceOpenClose = false;
      // this.isShowAdvance =  true;
      this.isShowAdvance = true;
      if (this.isShowAdvance == true) {
        this.isShowSalary = true;
      } else {
        this.isShowSalary = false;
      }
    }

    if (this.router.url === '/home/loan-list') {
      console.log('this.router.url', this.router.url);

      // this.isShowAdvance =  !this.isShowAdvance;
      // this.isShowLoanArrow = !this.isShowLoanArrow;
      this.isShowLoan = true;
      if (this.isShowLoan == true) {
        this.isShowLoanArrow = true;
      } else {
        this.isShowLoanArrow = false;
      }
    }

    if (this.router.url === '/home/manage-approver-list') {
      console.log('this.router.url', this.router.url);

      // this.isShowAdvance =  !this.isShowAdvance;
      // this.isShowLoanArrow = !this.isShowLoanArrow;
      this.isShowApprover = true;
      if (this.isShowApprover == true) {
        this.isShowApproverArrow = true;
      } else {
        this.isShowApproverArrow = false;
      }
    }

    if (this.router.url === '/home/manage-emp-approver-list') {
      console.log('this.router.url', this.router.url);

      // this.isShowAdvance =  !this.isShowAdvance;
      // this.isShowLoanArrow = !this.isShowLoanArrow;
      this.isShowApprover = true;
      if (this.isShowApprover == true) {
        this.isShowApproverArrow = true;
      } else {
        this.isShowApproverArrow = false;
      }
    }

    if (this.router.url === '/home/manage-elements') {
      console.log('this.router.url', this.router.url);

      this.isShowClientArrow = true;
      this.isShowClient = true;
    }

    localStorage.setItem('localStorageMenuId', 'CHRMS');
    this.activatedRoute.url.subscribe((url) => {
      console.log('Activated route URL:', url);
    });

    // Alternatively, if you want to get the full URL string
    console.log('Activated route full URL:', this.router.url);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        if (currentRoute === '/home/dashboard') {
          localStorage.setItem('localStorageMenuId', 'CHRMS');
        } else {
          localStorage.removeItem('localStorageMenuId');
        }
      }
    });

    if (this.router.url === '/home/dashboard' || this.router.url === '/home/business-list') {
      this.localStorageMenuId = 'CHRMS';
      this.selectedMenuTitle = 'Dashboard';
      this.selectedModuleName = '';
    }

    if (this.router.url === '/home/assign-approver') {
      this.selectedMenuTitle = 'Assign Approvers';
    }

    if (this.router.url === '/home/time-sheet-reports') {
      this.selectedMenuTitle = 'Timesheet';
    }
    if (this.router.url === 'home/manage-timesheet') {
      this.selectedMenuTitle = 'Manage Timesheet';
    }
    if (this.router.url === 'home/projects') {
      this.selectedMenuTitle = 'Projects';
    }

    if (this.router.url === 'home/salary-manage-approvals') {
      this.selectedMenuTitle = 'Manage Salary Approvals';
    }

    if (this.router.url === 'home/add-multiple-task') {
      this.selectedMenuTitle = 'Add Task';
    }

    if (this.router.url === 'home/add-employee-task') {
      this.selectedMenuTitle = 'Assign Employee Task';
    }

    if (this.router.url === 'home/task-group') {
      this.selectedMenuTitle = 'Task Group';
    }

    if (this.router.url === 'home/assign-project') {
      this.selectedMenuTitle = 'Assign Employee Project';
    }

    if (this.router.url === 'home/project-stage') {
      this.selectedMenuTitle = 'Project Stage';
    }

    if (this.router.url === 'home/emp-timesheet') {
      this.selectedMenuTitle = 'Assign Employee Task';
    }

    if (activatedRoute.snapshot) {
      //this.first_segment = activatedRoute.snapshot.url[0].path;
      // this.first_segment = this.activatedRoute.snapshot.firstChild.url[0].path;

      // alert(this.first_segment);
      // this.second_segment = activatedRoute.snapshot.url[1].path;
    }
  }

  // console.log(this.router.url);

  // resultData : any = {};

  headerProfileImage: any;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  getName(data: any) {
    this.menuName = data;
  }

  getAllModules(): void {
    this.results = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('module_')) {
        const moduleData = localStorage.getItem(key);
        if (moduleData) {
          this.results.push(JSON.parse(moduleData));
        }
      }
    }

    // Sort the results by moduleId or any other property you want
    this.results.sort((a, b) => {
      return a.moduleId - b.moduleId; // Adjust the property if needed
    });

    console.log('All modules in order:', this.results);
  }

  moveModule() {
    this.router.navigate(['home', 'admin-home']);
    const currentModule = this.getCurrentModule(); // Call a function to get the current module
    if (currentModule) {
      // this.router.navigate(['/home/dashboard']);
      this.isSelected = true;
      this.selectedModuleName = 'Core HRMS'; // Set selected module name to Core HRMS
    }
  }

  getCurrentModule() {
    // You need to implement this function to get the current module
    // For now, I'll assume you are using selectedModuleName as the indicator of the current module
    return this.selectedModuleName;
  }

  moveToDashboard() {
    console.log('moveToDashboard triggered', this.user);
    
    if (

      this.user.employee?.employeeId == undefined 
      
    ) {
      this.router.navigate(['home','business-list']);
    } else {
      this.router.navigate(['home', 'newsfeed']);
    }
  }
  ngOnInit(): void {



    if(this.router.url === '/home/employee'){
      this.selectedMenuTitle = '';
    }
    this.headerTitle$ = this.headerservice.title.subscribe((title) => {
      setTimeout(() => {
        this.selectedMenuTitle = title;
      }, 0);
    });

    this.moduleName$ = this.headerservice.title.subscribe((module) => {
      setTimeout(() => {
        this.selectedModule = module;
      }, 0);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        let localStorageMenuId;

        if (currentRoute === '/home/dashboard') {
          localStorageMenuId = 'CHRMS';
        } else if (currentRoute === '/home/admin-dashboard') {
          localStorageMenuId = 'RQMNT';
        } else if (currentRoute === '/home/admin-dashboard') {
          localStorageMenuId = 'PMS';
        } else if (currentRoute === '/home/manage-elements') {
          localStorageMenuId = 'LEAVE';
        } else if (currentRoute === '/home/project-management') {
          localStorageMenuId = 'PROJECTMANAGEMENT';
        } else if (currentRoute === '/home/category') {
          localStorageMenuId = 'EXPENSEREIMBURSEMENT';
        } else {
          localStorageMenuId = null; // Or set to a default value
        }

        if (localStorageMenuId) {
          localStorage.setItem('localStorageMenuId', localStorageMenuId);
        } else {
          localStorage.removeItem('localStorageMenuId');
        }
      }
    });
    // this.setSelectedMenuTitle();

    this.route.data.subscribe((data) => {
      if (data && data.title) {
        this.selectedMenuTitle = data.title;
      }
    });

    this.getModulesList();
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.userType = [];
    

    // Populate userType array with roles
    this.usertData?.employee?.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    if(this.usertData.role!==undefined){
      this.usertData.role.forEach((role)=>{
        this.roleName=role.roleName;
        console.log("roleName::",this.roleName)

      })
      console.log("roleName::",this.roleName)

    }
    else{
      if(this.usertData.role.length==0){
        console.log("no role present in outside!!!")
      }
    }
  
    let org=this.usertData?.organization;
    console.log("org:",org)

    if (this.usertData?.organization?.organizationId) { 
      this.organizationLogin = true;
      this.clientLogin = false;

    } else if (this.usertData?.employee?.client?.clientId) {
      this.clientLogin = true;
      console.log("client login")
    } 
    else {
      this.entityAdminLogin = true;
      this.clientLogin =false;

    }
    console.log(" this.organizationLogin ", this.organizationLogin );
    console.log("this.entityAdminLogin",this.entityAdminLogin)
    console.log("this.clientLogin ",this.clientLogin )

   

    let OrganizationID = this.usertData ? this.usertData.organization.organizationId : 0;
    console.log(OrganizationID);
    if(this.usertData?.employee != undefined){
      if (this.usertData.employee.role != undefined) {
        // Populate userType array with roles
        this.usertData?.employee?.role?.forEach((role) => {
          this.userType.push(role?.roleName);
          console.log(this.userType.push(role?.roleName));
        });
        // Check if the user is an Admin or Recruiter

        this.isAdmin = this.userType.includes('Admin');
        this.isRequirements = this.userType.includes('Recruiter');
        this.isEmployee = this.userType.includes('Employee');
        console.log('emp', this.isEmployee )
        this.isHumanResource = this.userType.includes('Human Resource');
        this.isTeamLead = this.userType.includes('Team Lead');
        // this.isShowAdmin = this.userType.includes('Admin');
      }
    }
    else{
      if (this.usertData.role != undefined) {
        this.usertData?.role?.forEach((role) => {
          this.userType.push(role?.roleName);

        });
        this.isUserAdmin = true;

        console.log("else ,this.isUserAdmin ",this.isUserAdmin)
        console.log("  this.userType::",  this.userType)
        console.log("this.entityAdminLogin::",this.entityAdminLogin)
      }
    }

    
if( this.isAdmin==true && this.isEmployee==true){
  console.log("employe with admin!!!")
}

    console.log(this.userType);
    console.log( "this.isAdmin", this.isAdmin);
    console.log( "this.isHumanResource ", this.isHumanResource);
    console.log( "this.isRequirements ", this.isRequirements);
    console.log( "this.isEmployee" , this.isEmployee);

// Is check the org Emp / client Emp  or Entity Admin
 

    
    
    // this.userTypeId = this.usertData.userType.userTypeId;
    // console.log("UserId",this.userTypeId);
    // this.role=this.usertData.role[0].roleName
    // console.log("role"+this.role);
    this.expandPayroll = true;
    this.expandApprovers = true;
    this.client = true;
    this.empAssign = true;
    this.expandLoan = true;
    this.momlist = true;
    this.requireList = true;
    this.expandAdvance = true;
    this.expandAdvanceProjects = true;
    this.reports = true;
    this.expandRecruitment = true;
    /* let url =  this.route.parent.snapshot.url[2].path;
    alert(url); */
    if(this.entityAdminLogin){
      this.localStorageMenuId = localStorage.setItem('moduleCode','CHRMS');
    }
    // this.localStorageMenuId = localStorage.setItem('moduleCode','CHRMS');
    this.localStorageMenuId = localStorage.getItem('moduleCode');
    console.log("this.localStorageMenuId",this.localStorageMenuId);

    this.user = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(this.user);

    // this.userType=this.user.employee.role
    // console.log("check wtss::",this.userType);
    this.userType = [];
    this.user?.employee?.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });

    console.log('rolename', this.userType);

    this.shrink = false;
    this.expand = true;
    this.right = true;

    if (this.isHandset$) {
      this.expand = true;
    }

    if (this.isHandset$) {
      this.expand = true;
    }

    //notification start here
    let x = localStorage.getItem('notifications');

    if (x != null) {
      this.notificationlist = JSON.parse(x);
    }

    //console.log("ng inti notification" + JSON.stringify(this.notificationlist));

    if (this.notificationlist != null) {
      this.notificationcount = this.notificationlist.length;
    }

    /* this.usertype = JSON.parse(localStorage.getItem('user_hrms'));
    this.employeeName = this.usertype.employeeName; */

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    if (this.usertype.employee) {
      // console.log("emp");
      if (this.usertype.employee.role) {
        this.usertype.employee.role.forEach((element) => {
          if (element.menu) {
            element.menu.forEach((mennuelement) => {
              let hasmenu = this.menudata.some(
                (m) => m.menuId == mennuelement.menuId
              );
              if (!hasmenu) {
                this.menudata.push(mennuelement);
                //   console.log( this.menudata);
              }
              //  console.log( "module length" + mennuelement.module.length);
              mennuelement.module.forEach((moduleelement) => {
                let moduledata = this.moduledata.some(
                  (m) => m.moduleId == moduleelement.moduleId
                );
                if (!moduledata) {
                  this.moduledata.push(moduleelement);
                }
              });
              //  console.log( "moduledata" +this.moduledata);
            });
          }
        });
      }
    } else {
      if (this.usertype.role) {
        console.log('userrole');
        this.usertype.role.forEach((element) => {
          if (element.menu) {
            element.menu.forEach((mennuelement) => {
              let hasmenu = this.menudata.some(
                (m) => m.menuId == mennuelement.menuId
              );
              if (!hasmenu) {
                this.menudata.push(mennuelement);
                // console.log(this.menudata);
              }
              //  console.log( "module length" + mennuelement.module.length);
              mennuelement.module.forEach((moduleelement) => {
                let moduledata = this.moduledata.some(
                  (m) => m.moduleId == moduleelement.moduleId
                );
                if (!moduledata) {
                  // if(moduleelement.moduleId == 1){
                    this.moduledata.push(moduleelement);
                  // }
                }
              });
              //  console.log( "moduledata" +this.moduledata);
            });
          }
        });
      }
    }

    let employeeId = this.usertype.userId;
    // let organizationId = this.usertype.organization.organizationId;
    this.employeeName = this.usertype.firstName;

    //this.usertype = this.usertype.role.roleName;

    let nofitication = '/notification/';
    let ws = new SockJS(`${environment.rec_base_url}getresourceonboarding`, {
      headers: { Authorization: 'Basic ZW5vdGU6ZW5vdGUkcmVjdXJpdG1lbnQj' },
    });

    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect(
      {},
      function (frame: any) {
        _this.stompClient.subscribe(nofitication, function (sdkEvent: any) {
          _this.onMessageReceived(sdkEvent);
        });
        //_this.stompClient.reconnect_delay = 2000;
      },
      this.errorCallBack
    );
    //notification end here

    this.getUserData();
    // this.localStorageMenuId = 'CHRMS';
  
  
    console.log(" this.organizationLogin ", this.organizationLogin );
    console.log("this.entityAdminLogin",this.entityAdminLogin)
    console.log("this.clientLogin ",this.clientLogin )
  }

  ngOnDestroy(): void {
    if (this.headerTitle$) {
      this.headerTitle$.unsubscribe();
    }

    if (this.moduleName$) {
      this.headerTitle$.unsubscribe();
    }
  }

 
  toggle1() {
    this.isShowPms = !this.isShowPms;
  }
  toggle2() {
    this.isShowPayroll = !this.isShowPayroll;
    this.isShowPayrollArrow = !this.isShowPayrollArrow;
    this.isShowReport = false;
    this.payrollReports = false;
    this.isShowClient = false;
    this.isShowClientArrow = false;
    this.isShowClient = false;
    this.SetupReports=false;
  }
  toggle3() {
    this.isShowClient = !this.isShowClient;
  }
  toggle4() {
    this.isShowEmpAssign = !this.isShowEmpAssign;
  }
  toggle5() {
    this.isShowShift = !this.isShowShift;
    this.shiftSchedulingArrow =  !this.shiftSchedulingArrow;
    // this.isShowClientArrow = false;
    // this.isShowClient = false;
    // this.isShowApproverArrow = false;
    // this.isShowApprover = false;

  }
  toggle6() {
    this.isShowApprover = !this.isShowApprover;
  }
  toggle7() {
    this.isShowLoan = !this.isShowLoan;
    this.isShowLoanArrow = !this.isShowLoanArrow;
  }
  toggle8() {
    this.isShowAdvance = !this.isShowAdvance;
  }
  toggle9() {
    this.isShowReport = !this.isShowReport;
    this.payrollReports = !this.payrollReports;
    this.isShowPayroll = false;
    this.isShowPayrollArrow = false;
    this.isShowClient = false;
    this.isShowClientArrow = false;
    this.SetupReports=false;
  }
  toggle10() {
    this.isShowApprisal = !this.isShowApprisal;
  }

  togglepayroll()
  {
    this.SetupReports=!this.SetupReports;
    this.isShowReport = false;
    this.payrollReports = false;
    this.isShowClient = false;
    this.isShowClientArrow = false;
    this.isShowClient = false;
    this.isShowPayroll = false;
  }
  toggle12() {
    this.isShowmomlist = !this.isShowmomlist;
    this.isshowMom = !this.isshowMom;
    // this.isShowmomlist =  !this.isShowAdvanceProject
  }
  toggle11() {
    this.isShowAdvanceProject = !this.isShowAdvanceProject;
    this.isShow = !this.isShow;
  }

  toggleProjectsClose() {
    this.isShowAdvanceProject = false;
  }

  toggleSalaryAdvance() {
    this.isShowAdvance = !this.isShowAdvance;
    // this.isShowSalary = !this.isShowSalary;

    if (this.isShowAdvance == true) {
      this.isShowSalary = true;
    } else {
      this.isShowSalary = false;
    }
  }

  toggleLoan() {
    this.isShowLoan = false;
  }

  toggleApprover() {
    this.isShowApprover = false;
  }

  toggleClient() {
    this.isShowClient = false;
  }

  toggleMom() {
    this.isShowmomlist = false;
  }

  settingsCloseOtherMenus() {
    this.isShowClient = false;
    this.isShowClientArrow = false;
    this.isShowReport = false;
    this.payrollReports = false;
    this.isShowPayroll = false;
    this.isShowPayrollArrow = false;
    this.shiftSchedulingArrow = false;
    this.isShowShift = false;
  }
  getUserData() {
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let userId = this.usertype.userId;
    let organizationId = this.usertype.org;

    if (userId) {
      this.UserService.getUserById(userId).subscribe((resultData: any) => {
        this.resultData = resultData;
        console.log(this.resultData);
        this.menudata.length == 0;
        if (resultData.employee?.employeeAttachment) {
          this.UserService.getuserImage(
            resultData.employee.employeeAttachment.fileViewUrl
          ).subscribe(
            (blob) => {
              const a = document.createElement('a');
              const objectUrl: any = blob;
              this.headerProfileImage = blob;
            },
            (err) => {
              this.headerProfileImage = 'assets/uploads/no-image.png';
            }
          );
        } else {
          this.headerProfileImage = 'assets/uploads/no-image.png';
        }
      });
    }
  }

  checkmenudata(menuname) {
    // console.log(this.menudata);
    //console.log("menuname" + menuname);
    // console.log(this.menudata);

    const foundMenu = this.menudata.find((e) => e.menuname === menuname);
    // this.selectedMenuTitle = foundMenu.menuname;
    if (foundMenu) {
      this.menuName = foundMenu.menuname;
    }
    // console.log('this.menuName', foundMenu.menuname);
    // console.log("this.menudata",this.menudata);
    

    // return (this.menuName = this.menudata.some((e) => e.menuname == menuname));
    return this.menudata.some((e) => e.menuname == menuname);
  }

  checkmoduledata(modulecode) {
    this.selectedModuleName =
      this.results.find((result) => result.moduleCode === modulecode)
        ?.moduleName ?? '';
    // console.log('this.selectedmodule', this.selectedModuleName);

    let selectedModule = this.moduledata.some(
      (e) => e.moduleCode == modulecode
    );
    // console.log("selectedModuleName",this.selectedModuleName);

    if (
      this.selectedModuleName === 'Project Management' &&
      this.selectedMenuTitle.length == 0
    ) {
      // this.selectedMenuTitle ='Dashboard';
    }

    if (
      this.selectedModuleName === 'Core HRMS' &&
      this.selectedMenuTitle.length == 0
    ) {
      // this.selectedMenuTitle = 'Dashboard';
    }

    if (
      this.selectedModuleName === 'Leave' &&
      this.selectedMenuTitle.length == 0
    ) {
      // this.selectedMenuTitle = 'Dashboard';
    }

    if (
      this.selectedModuleName === 'Recruitment' &&
      this.selectedMenuTitle.length == 0
    ) {
      // this.selectedMenuTitle = 'Dashboard';
    }

    if (
      this.selectedModuleName === 'PMS' &&
      this.selectedMenuTitle.length == 0
    ) {
      // this.selectedMenuTitle ='Dashboard';
    }

    if (
      this.selectedModuleName === 'Payroll' &&
      this.selectedMenuTitle.length == 0
    ) {
      // this.selectedMenuTitle ='Dashboard';
    }
    // console.log("this.moduledata",this.moduledata)
    // console.log(this.moduledata.some((e) => e.moduleCode == modulecode));
    return this.moduledata.some((e) => e.moduleCode == modulecode);
  }

  errorCallBack(error: string) {
    // console.log("errorCallBack -> " + error)
    setTimeout(() => {}, 5000);
  }

  onMessageReceived(message: any) {
    // console.log("Message Recieved from Server :: " + message.body);
    let x = localStorage.getItem('notifications');
    //console.log(x);

    let y = JSON.parse(x);
    //console.log("notifications");
    //console.log(y);

    if (y != null) {
      if (y.length != 0) {
        //alert("x.lengtj " + y.length)
        this.notificationlist = y;
        //console.log(this.notificationlist);
      }
    }
    this.notifylist = JSON.parse(message.body);
    this.id1 = this.notifylist.resource.resourceId;
    //console.log(this.id1);
    //console.log("this.notificationlist" + JSON.stringify(this.notificationlist));
    let resourceidExist = false;

    if (this.notificationlist.length != 0) {
      for (let e of this.notificationlist) {
        this.id2 = e.resource.resourceId;
        //console.log(this.id2);
        if (this.id1 == this.id2) {
          resourceidExist = true;
        }
      }
    }

    //console.log("resourceidExist" + resourceidExist);
    if (resourceidExist == false) {
      this.notificationlist.push(JSON.parse(message.body));
      //console.log(this.notificationlist);
    }
    this.notificationcount = this.notificationlist.length;
    //console.log("this.notificationcount" + this.notificationcount);
    localStorage.setItem(
      'notifications',
      JSON.stringify(this.notificationlist)
    );
  }

  delete(i: number) {
    this.notificationlist.splice(i, 1);
    this.notificationcount = this.notificationlist.length;
    localStorage.setItem(
      'notifications',
      JSON.stringify(this.notificationlist)
    );
  }

  deleteAll(all: number) {
    this.notificationlist.splice(this.notificationlist);
    this.notificationcount = this.notificationlist.length;
    localStorage.setItem(
      'notifications',
      JSON.stringify(this.notificationlist)
    );
  }

  resourceDetails(resource) {
    //console.log("resource" +resource);
    var dialogbox = this.dialog.open(NotificationResourceDetailComponent, {
      width: '900px',
      height: '560px',
      data: resource,
    });
    // this.selectedvalue != null
    // this.isDetails = ! this.isDetails;
  }

  MatDrawControl() {
    // this.expand = !this.expand;
    this.hideMenus = !this.hideMenus;
    this.isSideNavClosed = !this.isSideNavClosed;
    this.hideModules = true;
  }

  MatDrawControl2() {
    this.hideModules = !this.hideModules;
    // this.expand = !this.expand;
    // Close the side nav when clicking on a home icon
    // this.isSideNavClosed = true; // Update side nav state
    this.isSideNavClosed = !this.isSideNavClosed;

    this.hideMenus = true;
  }

  MatDrawControl3() {
    // this.expand = !this.expand;
    // this.hideModules = !this.hideModules;
    // this.hideMenus = !this.hideMenus;
    this.isSideNavClosed = !this.isSideNavClosed;

    if (this.hideMenus === true || this.hideModules === false) {
      this.hideMenus = false;
      this.hideModules = false;
      this.hideModules = !this.hideModules;
      this.hideMenus = !this.hideMenus;
    } else {
      this.hideMenus = true;
      this.hideModules = true;
      this.hideModules = !this.hideModules;
      this.hideMenus = !this.hideMenus;
    }

    if (this.hideModules === true || this.hideMenus === false) {
      this.hideModules = false;
      this.hideMenus = false;
      this.hideModules = !this.hideModules;
      this.hideMenus = !this.hideMenus;
    } else {
      this.hideModules = true;
      this.hideMenus = true;
      this.hideModules = !this.hideModules;
      this.hideMenus = !this.hideMenus;
    }

    if (this.hideModules === true && this.hideMenus === true) {
      // this.hideModules = false;
      // this.hideMenus =  false;
      this.hideModules = !this.hideModules;
      this.hideMenus = !this.hideMenus;
    }
  }
  MatDrawControl4() {
    if (this.hideModules === true && this.hideMenus === true) {
      console.log('mo = T, Me = T');
      this.hideModules = false;
      this.hideMenus = false;
    } else {
      console.log('mo = f, Me = t');
      this.hideModules = true;
      this.hideMenus = true;
    }

    this.expand = true;
    this.isSideNavClosed = true;
    this.showAdditionalIcons = false;
  }


  manageShift(){
    this.isShowShift =  true;
  }
  ngAfterViewInit() {
    this.breakpointObserver.observe(['(max-width:800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
        //console.log(this.sidenav.mode)
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
        //console.log(this.sidenav.mode)
      }
    });
    this.cdr.detectChanges();
  }

  /* logout()
  {
	  //localStorage.clear();
	  localStorage.removeItem('enoteUserData');
	  this.router.navigate(['login']);
  } */

  logout() {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      //text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(10 123 235)',
      cancelButtonColor: 'rgb(233 49 49)',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        //localStorage.removeItem('enoteUserData');
        this.appStateService.notifyLogout(); // Notify AppComponent

        this.router.navigate(['login']);

        Swal.fire({
          icon: 'success',
          text: 'Done',
        });
        // window.location.reload();
      }
    });
  }

  toggle(val) {
    this.isShow = !this.isShow;
    this.isShowValue = val;
  }
  handleClicksetups() {
    this.Setups = !this.Setups;
    this.SetupsListArrowIcon = !this.SetupsListArrowIcon;
    this.Settings=false;
    this.Manage=false;
    this.requestList=false;
    this.SettingsListArrowIcon=false;
    this.ManageListArrowIcon=false;
    this.requestListArrowIcon=false;
    this.reportsListArrowIcon=false;


  }
  handleClickSettings() {
    this.Settings = !this.Settings;
    this.SettingsListArrowIcon = !this.SettingsListArrowIcon;
    this.Setups=false;
    this.Manage=false;
    this.requestList=false;
    this.SetupsListArrowIcon=false;
     this.ManageListArrowIcon=false;
     this.requestListArrowIcon=false;
     this.reportsListArrowIcon=false;

  }
  handleClickmanage() {
    this.Manage = !this.Manage;
    this.ManageListArrowIcon = !this.ManageListArrowIcon;
    this.Setups=false;
    this.Settings=false;
    this.SettingsListArrowIcon=false;
    this.SetupsListArrowIcon=false;
    this.requestList=false;
    this.requestListArrowIcon=false;
    this.reportsListArrowIcon=false;





  }
  handleClickRequest() {
    this.requestList = !this.requestList;
    this.requestListArrowIcon = !this.requestListArrowIcon;
    this.Setups=false;
    this.Settings=false;
    this.Manage=false;
    this.Reports=false;
    this.ManageListArrowIcon=false;
    this.SettingsListArrowIcon=false;
    this.SetupsListArrowIcon=false;
    this.reportsListArrowIcon=false;

    
  }
  handleClickReport() {
    this.Reports = !this.Reports;
    this.reportsListArrowIcon = !this.reportsListArrowIcon;
    this.Setups=false;
    this.Settings=false;
    this.Manage=false;
    this.requestList=false;
    this.ManageListArrowIcon=false;
    this.SettingsListArrowIcon=false;
    this.SetupsListArrowIcon=false;
    this.requestListArrowIcon=false;

  }

  show() {
    this.active = true;
  }

  showNotification(val) {
    this.isShow1 = !this.isShow1;
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  setHeaderTitle(menuName: string) {
    // console.log('menuName', menuName);

    // if (this.selectedMenuTitle.length >=0) {

    //   this.selectedMenuTitle= '';
    //   this.selectedMenuTitle.length == 0;
    // }
    // this.selectedMenuTitle = menuName;
    // this.headerService.setTitle(menuName);

    // this.selectedMenuTitle = menuName; // Assuming headerService has a method `setTitle` to set the title

    // console.log('menuNameTitle', this.selectedMenuTitle);

    this.selectedMenuTitle = menuName;
    this.headerservice?.setTitle(menuName);
    this.route.data.subscribe((data) => {
      if (data && data.title) {
        this.selectedMenuTitle = data.title;
      }
    });
  }

  

  setHeaderTitle2() {
    // console.log('menuName', menuName);

    // if (this.selectedMenuTitle.length >=0) {

    //   this.selectedMenuTitle= '';
    //   this.selectedMenuTitle.length == 0;
    // }
    // this.selectedMenuTitle = menuName;
    // this.headerService.setTitle(menuName);

    // this.selectedMenuTitle = menuName; // Assuming headerService has a method `setTitle` to set the title

    // console.log('menuNameTitle', this.selectedMenuTitle);

    console.log('checkingmenuroute', this.route);
    if (this.route.snapshot.data && this.route.snapshot.data.title) {
      this.selectedMenuTitle = this.route.snapshot.data.title;
      console.log('menuNameTitle', this.selectedMenuTitle);
    }
  }

  getModulesList() {
    /* Get Designation Data */
    // this.superadminservice.getActiveModule().subscribe(
    //   (result: any) => {
    //     result.forEach((element) => {
    //       // let hasmodule = this.moduledata.some(
    //       //   (mmd) => mmd.moduleId == element.moduleId
    //       // );
    //       // let hasremodules = this.results.some(
    //       //   (em) => em.moduleId == element.moduleId
    //       // );
    //       // if (hasmodule) {
    //       this.results.push(element);
    //       // }
    //     });
    //     /*  this.router.navigate(['/home/admin-home']).then(() => {
    //   window.location.reload();
    // }); */
    //   },
    //   (err) => {
    //     this.errorMsg = err.error.message;
    //     console.log(this.errorMsg);
    //   }
    // );
  }

  selectModulesTitle(moduleCode) {
    localStorage.setItem('moduleCode', moduleCode);
    this.localStorageMenuId = localStorage.getItem('moduleCode');

    if (moduleCode == 'CHRMS') {
      // Core HRMS
      this.moduleName = 'Core HRMS';
      // this.router
      //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      //   .then(() => {
      //     this.router.navigate(['/home/dashboard']);
      //   });
    } else if (moduleCode == 'RQMNT') {
      // Recruitment
      this.moduleName = 'Recruitment';
      // Other code...
    } else if (moduleCode == 'PMS') {
      // PMS
      this.moduleName = 'PMS';
      // Other code...
    } else if (moduleCode == 'PAYROLL') {
      // PAYROLL
      this.moduleName = 'Payroll';
      // Other code...
    } else if (moduleCode == 'LEAVE') {
      // Leave
      this.moduleName = 'Leave';
      // Other code...
    } else if (moduleCode == 'PROJECTMANAGEMENT') {
      // Project Management
      this.moduleName = 'Project Management';

      // Other code...
    }
  }

  selectModules(moduleCode) {
    console.log("moduleCode",moduleCode);
    localStorage.setItem('moduleCode', moduleCode);

    this.localStorageMenuId = localStorage.getItem('moduleCode');
    this.selectedModuleName =
      this.results.find((result) => result.moduleCode === moduleCode)
        ?.moduleName ?? '';

    // let chrms = this.router.navigate(['/home/dashboard']);
    // let chrms = this.router.navigate(['/home/business-list']);
    let org = this.usertData?.organization?.organizationId;
    if (this.usertData?.organization?.organizationId) { 
      this.organizationLogin = true;
      var chrms=this.router.navigate(['/home/dashboard'])
    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;
      var chrms=this.router.navigate(['/home/dashboard'])

    } else {
      this.entityAdminLogin = true;
      
      var chrms=this.router.navigate(['/home/business-list'])

    }

    let rqmt = this.router.navigate(['/home/admin-dashboard']);
    // let pms = this.router.navigate(['/home/kra-template']);
    if (moduleCode == 'CHRMS' && chrms) {
      //Core HRMS
      //this.router.navigate(['/home/dashboard']);
      this.moduleName = 'Core HRMS';

      this.moduleNameTitle = moduleCode;
      console.log('moduleName', this.moduleName);
      this.selectedModuleName = 'CHRMS';
      // this.router
      //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      //   .then(() => {
      // this.router.navigate(['/home/dashboard']);
      // this.router.navigate(['/home/business-list']);
      if (this.usertData?.organization?.organizationId) { 
        this.organizationLogin = true;
        var chrms=this.router.navigate(['/home/dashboard'])
      } else if (this.usertData?.employee?.client?.clientId) {
        this.clientLogin = true;
        var chrms=this.router.navigate(['/home/dashboard'])
  
      } else {
        this.entityAdminLogin = true;
        var chrms=this.router.navigate(['/home/business-list'])
  
      }
      // });
    } else if (moduleCode == 'RQMNT') {
      // this.router.navigate(['/home/admin-dashboard']);
      this.selectedModuleName = 'Recruitment';
      this.moduleName = 'Recruitment';
      console.log('moduleName', this.moduleName);
      //Recruitment
      if (this.isAdmin) {
        // this.router
        //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        //   .then(() => {
        this.router.navigate(['/home/admin-dashboard']);
        this.selectedModuleName = 'Recruitment';
        // });
      } else if (this.isRequirements)  {
        console.log("recruiter enters here!!");
        
        // this.router
        //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        //   .then(() => {
          this.selectedModuleName = 'Recruitment';
        this.router.navigate(['/home/recruiter-dashboard']);
        // });
      }
    } else if (moduleCode == 'PMS') {
      this.moduleName = 'PMS';
      console.log('moduleName', this.moduleName);
      
      //PMS
      if (this.isAdmin) {
      // this.router
      //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      //   .then(() => {
      this.router.navigate(['/home/pms-admin-dashboard']);
      this.selectedModuleName = 'PMS';
      // });
      }else if (this.isEmployee)  {
        console.log("Employee enters here!!");
        
        // this.router
        //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        //   .then(() => {
        this.router.navigate(['/home/appraisal']);
        // });
        this.selectedModuleName = 'PMS';
      }

    } else if (moduleCode == 'PAYROLL') {
      this.moduleName = 'Payroll';
      console.log('moduleName', this.moduleName);

      //PAYROLL
      // this.router
      //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      //   .then(() => {
        if (this.isAdmin) {
      this.router.navigate(['/home/working-days']);
        }else{
          this.router.navigate(['/home/employee-payslip']);
        }
      // });
    } else if (moduleCode == 'LEAVE') {
      this.moduleName = 'Leave';
      console.log('moduleName', this.moduleName);
      if (this.isAdmin) {
        // this.router
        //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        //   .then(() => {
          this.router.navigate(['/home/leave-dashboard']);
                  this.selectedModuleName = 'LEAVE';
        // });
      } else if (this.isEmployee)  {
        console.log("recruiter enters here!!");
        
        // this.router
        //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        //   .then(() => {
        this.router.navigate(['/home/emp-leave-dashboard']);
        // });
      }
      this.selectedModuleName = 'LEAVE';

      //Leave
      // this.router
      //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      //   .then(() => {
      
      // });
    } else if (moduleCode == 'PROJECTMANAGEMENT') {
      this.moduleName = 'Project Management';
      console.log('moduleName', this.moduleName);
      // this.router
      //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      //   .then(() => {
      // this.router.navigate(['/home/project-management']);
      if (this.isAdmin) {
        // this.router
        //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        //   .then(() => {
        this.router.navigate(['/home/project-management']);
        this.selectedModuleName = 'PROJECTMANAGEMENT';
        // });
      } else if (this.isEmployee)  {
        console.log("recruiter enters here!!");
        
        // this.router
        //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        //   .then(() => {
        this.router.navigate(['/home/time-employee-dashboard']);
        // });
      }

      this.selectedModuleName = 'PROJECTMANAGEMENT';
      // });
    } else if (moduleCode == 'EXPENSEREIMBURSEMENT') {
      this.moduleName = 'Expense Reimbursement';
      console.log('moduleName', this.moduleName);
      // this.router
      //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      //   .then(() => {
      this.router.navigate(['/home/category']);
      // });
    }



    // if (this.isAdmin) {
    //   // this.router
    //   //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
    //   //   .then(() => {
    //   this.router.navigate(['/home/kra-template']);
    //   this.selectedModuleName = 'PMS';
    //   // });
    // } else if (this.isEmployee)  {
    //   console.log("recruiter enters here!!");
      
    //   // this.router
    //   //   .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
    //   //   .then(() => {
    //   this.router.navigate(['/home/appraisal']);
    //   // });
    // }
    // this.selectedModuleName = 'PMS';
    console.log("localStorageMenuId", this.localStorageMenuId);
  }

  handleClick() {
    this.isShowSalary = false;
    this.isShowLoanArrow = false;
    this.isShowAdvance = false;
    this.isShowClientArrow = false;
    this.isShowmomlist = false;
    this.toggle11();
    this.isshowMom = false;
    // this.setHeaderTitle('Projects');
    this.isShowApproverArrow = false;
    // this.toggleSalaryAdvance();
    this.isShowAdvance = false;
    this.isShowLoan = false;
    // this.toggleLoan();
    this.toggleApprover();
    this.toggleClient();
    this.toggleMom();
  }

  handleClickSalaryAdvance() {
    // this.toggleSalaryAdvance();
    // this.toggleProjectsClose();
    // this.toggleLoan();
    // this.toggleApprover();
    // this.toggleClient();
    // this.toggleMom();
    this.toggle8();
    this.isShowApproverArrow = false;
    this.isShowClientArrow = false;
    this.toggleProjectsClose();
    this.isshowMom = false;
    this.isShowLoanArrow = false;
    this.isShow = false;
    // this.toggleLoan();
    this.isShowLoan = false;
    this.toggleApprover();
    this.toggleClient();
    this.toggleMom();
    this.toggleSalaryAdvance();
    // this.salaryAdvanceOpenClose = !this.salaryAdvanceOpenClose;
  }

  handleClickLoan() {
    this.toggleProjectsClose();
    // this.toggleSalaryAdvance();
    this.toggle7();
    this.isShowAdvance = false;
    this.isShowApproverArrow = false;
    this.isShowClientArrow = false;
    this.toggleApprover();
    this.toggleClient();
    this.toggleMom();
    this.isshowMom = false;
    this.isShow = false;
    this.isShowSalary = false;
    if (this.isShowLoan == true) {
      this.isShowLoanArrow = true;
    } else {
      this.isShowLoanArrow = false;
    }
  }

  handleClickApprover() {
    this.toggleProjectsClose();
    // this.toggleSalaryAdvance();
    // this.toggle7();
    this.isShowClientArrow = false;
    this.isShowAdvance = false;
    this.isShowLoanArrow = false;
    this.isShowLoan = false;
    this.isShowSalary = false;
    this.isshowMom = false;
    this.isShowLoanArrow = false;
    this.isShow = false;
    // this.toggleApprover();
    this.toggleClient();
    this.toggleMom();

    // if (this.isShowApprover == true) {
    //   this.isShowApproverArrow = true;
    // } else {
    //   this.isShowApproverArrow = false;

    // }

    this.shiftSchedulingArrow = false;
    this.isShowShift = false;

  }
  handleCHRMSClickApprover(){
    this.isShowApprover = !this.isShowApprover;
    this.isShowApproverArrow = !this.isShowApproverArrow;
  }
  handleCHRMSClickClient(){
    this.isShowClient = !this.isShowClient;
    this.isShowClientArrow = !this.isShowClientArrow;
  }
  handleClickClient() {
    this.toggleProjectsClose();
    // this.toggleSalaryAdvance();
    this.toggle3();
    this.isShowApproverArrow = false;
    this.isShowAdvance = false;
    this.isShowLoanArrow = false;
    this.isShowSalary = false;
    this.isShowLoan = false;
    this.isshowMom = false;
    this.isShow = false;
    this.toggleApprover();
    this.isShowApproverArrow = false;
    // this.toggleClient();
    this.toggleMom();
    // this.isShowApproverArrow = !this.isShowApproverArrow;
    this.isShowClientArrow = !this.isShowClientArrow;
    this.isShowReport = false;
    this.payrollReports = false;
    this.isShowPayroll = false;
    this.isShowPayrollArrow = false;
    this.shiftSchedulingArrow =  false;
    this.isShowShift = false;
  }
  handleClickMOM() {
    this.toggle12();
    this.isShowClientArrow = false;
    this.toggleProjectsClose();
    // this.toggleSalaryAdvance();
    this.isShowClientArrow = false;
    this.isShowApproverArrow = false;
    this.isShowAdvance = false;
    this.isShowSalary = false;
    // this.toggleLoan();
    this.isShowLoan = false;
    this.isShowLoanArrow = false;
    this.isShow = false;
    this.toggleApprover();
    this.toggleClient();
  }

  handleClickSetup(){
    this.isSetup = !this.isSetup;
    this.isSetupList = !this.isSetupList;
  }
  handleRequirement() {
    this.toggle12();
    this.isShowClientArrow = false;
    this.toggleProjectsClose();
    // this.toggleSalaryAdvance();
    this.isShowClientArrow = false;
    this.isShowApproverArrow = false;
    this.isShowAdvance = false;
    this.isShowSalary = false;
    // this.toggleLoan();
    this.isShowLoan = false;
    this.isShowLoanArrow = false;
    this.isShow = false;
    this.toggleApprover();
    this.toggleClient();
  }

  handleClickAppraisal() {
    this.appraisalList = !this.appraisalList;
    this.appraisalIcon = !this.appraisalIcon;
    this.isAppraisalReportsSelected = true;
  }
  handleClickRequirement() {
    this.appraisalList = !this.appraisalList;
    this.appraisalIcon = !this.appraisalIcon;
    this.isAppraisalReportsSelected = true;
  }
  handleClickManageTimesheet() {
    this.isShow = false;
    this.isshowMom = false;
    this.isShowSalary = false;
    this.isShowLoanArrow = false;
    this.isShowApproverArrow = false;
    this.isShowClientArrow = false;

    this.isShowLoan = false;
    this.isShowClient = false;
    this.isShowApprover = false;
    this.isShowmomlist = false;
    this.isShowAdvanceProject = false;
    this.isShowAdvance = false;
  }

  handleClickAssignApprover() {
    this.assignApproversActive = true;
    this.isShow = false;
    this.isshowMom = false;
    this.isShowSalary = false;
    this.isShowLoanArrow = false;
    this.isShowApproverArrow = false;
    this.isShowClientArrow = false;

    this.isShowLoan = false;
    this.isShowClient = false;
    this.isShowApprover = false;
    this.isShowmomlist = false;
    this.isShowAdvanceProject = false;
    this.isShowAdvance = false;
  }

  handleClickTimeSheet() {
    this.isShow = false;
    this.isshowMom = false;
    this.isShowSalary = false;
    this.isShowLoanArrow = false;
    this.isShowApproverArrow = false;
    this.isShowClientArrow = false;

    this.isShowLoan = false;
    this.isShowClient = false;
    this.isShowApprover = false;
    this.isShowmomlist = false;
    this.isShowAdvanceProject = false;
    this.isShowAdvance = false;
  }

  handleClickOrganizationClient(){
    this.isShowClient = !this.isShowClient;
    this.isShowClientArrow = !this.isShowClientArrow;
  }

  recuritmentListOpen() {
    this.recruitmentList = !this.recruitmentList;

    this.isshowRecruitmentArrow = !this.isshowRecruitmentArrow;
  }
  overflowDots(code: string): string {
    return code.length > 12 ? code.substring(0, 12) + '...' : code;
  }
  isActiveRoute(): boolean {
    return this.router.isActive('/projects', true);
  }
  isActiveRoutes(): boolean {
    return this.router.isActive('/positions', true);
  }

  isActiveRouteViewReports(): boolean {
    this.isShowmomlist = false;
    this.isShowAdvanceProject = true;
    this.viewReports = true;
    this.isShowAdvance = false;
    this.isShowmomlist = false;
    const urlViewReports = this.route.snapshot.url.map(
      (segment) => segment.path
    );
    // console.log('urlSegment', urlViewReports);

    const idIndexView = urlViewReports.indexOf('projects/emp-report') + 1;
    const idView = urlViewReports[idIndexView];

    return this.router.isActive(`/projects/emp-report/${idView}`, true);
  }

  isActiveRouteSalaryManage(): boolean {
    return this.router.isActive('/salary-manage-approvals', true);
  }

  isActiveRouteAddTask(): boolean {
    return this.router.isActive('/add-multiple-task', true);
  }

  isActiveRouteEmpProjectRoles(): boolean {
    return this.router.isActive('/employee-user-role', true);
  }


  isActiveRouteAssignEmployee(): boolean {
    return this.router.isActive('/add-employee-task', true);
  }

  isActiveRouteTaskGroup(): boolean {
    return this.router.isActive('/task-group', true);
  }

  isActiveRouteAssignEmployeeProject(): boolean {
    return this.router.isActive('/assign-project', true);
  }

  isActiveRouteProjectStage(): boolean {
    return this.router.isActive('/project-stage', true);
  }

  isActiveRouteMomReports(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('update-mom') ||
      currentUrl.includes('mom-reports') ||
      currentUrl.includes('view-mom')
    );
  }

  isActiveRouteLeaveList(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('myleave-list') || currentUrl.includes('apply-leave')
    );
  }

  ismypermissionList(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('mypermission-list') ||
      currentUrl.includes('apply-permission')
    );
  }

  ismywfhlist(): boolean {
    // return this.router.isActive('/mywfh-list', true);
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('mywfh-list') || currentUrl.includes('apply-wfh')
    );
  }

  isBusinessTravelList(): boolean {
    // return this.router.isActive('/mybusinesstravel-list', true);
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('mybusinesstravel-list') ||
      currentUrl.includes('apply-businesstravel')
    );
  }

  isActiveRouteRecruitmentPosition(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('add-position') ||
      currentUrl.includes('edit-position') ||
      currentUrl.includes('positions') ||
      currentUrl.includes('view-position') 

    );
  }

  isActiveRouteRequirement(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('requirement') ||
      currentUrl.includes('requirement-add') ||
      currentUrl.includes('requirement-edit')
    );
  }

  isActiveRouteSetup(): boolean {
    const currentUrl = this.router.url;
    return (
  
      currentUrl.includes('organizationcomplaice')  ||
      currentUrl.includes('organizationcomplaice-list') 
    
    );
  }


  isActivePaySlip(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('employee-payslip') ||
      currentUrl.includes('viewemppayslip')
    );
  }

  isActiveManagePaySlip(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('manage-payslip') ||
      currentUrl.includes('viewpayslip')
    );
  }

  isActivePayrollEmployeeRecovery(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('recovery-adjustment') ||
      currentUrl.includes('recovery-adjustment-list')
    );
  }

  isActivePayrollManageAnnexure(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('employee-annexue-list') ||
      currentUrl.includes('employee-annexue')
    );
  }

  isActiveEmployeeLeave(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('add-employee-leave') ||
      currentUrl.includes('manage-employee-leave')
    );
  }

  isActiveEmployeeRating(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('employee-rating-list') ||
      currentUrl.includes('employee-rating')
    );
  }

 

  isActiveConsolidatedReport(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('consolidatereport-list') ||
      currentUrl.includes('view-employee-payroll')
    );
  }

  isActiveorganizationcomplaice(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('organizationcomplaice')||
      currentUrl.includes('organizationcomplaice-list') 
    
    );
  }
  isActiveBusinessUnit(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('business-unit')||
      currentUrl.includes('add-business-unit') ||
      currentUrl.includes('edit-business-unit')
    
    );
  }
  isActivelegalEntity(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('legal-list')||
      currentUrl.includes('legal-entity') ||
      currentUrl.includes('edit-legal-entity')
    
    );
  }
  isActiveSupplier(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('supplier-list')||
      currentUrl.includes('add-supplier') ||
      currentUrl.includes('edit-supplier')
    
    );
  }
  isActiveClientEmp():boolean{
    const currentUrl = this.router.url;
    return(
      currentUrl.includes('assigns-cliemp-list') ||
      currentUrl.includes('empassign')


    )
  }
  isActiveClientSupplier(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('ClientSup-list')||
      currentUrl.includes('ClientSup-add') ||
      currentUrl.includes('ClientSup-edit')
    
    );
  }
  isActiveBusinessGroup(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('business-list')||
      currentUrl.includes('business-group') ||
      currentUrl.includes('edit-business-group')
    
    );
  }

  isActiveAssignEmployee(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('assignEmp')||
      currentUrl.includes('editassignEmp')||
      currentUrl.includes('assignlist')
    
    );
  }

  isActiveAnnouncement(): boolean {
    const currentUrl = this.router.url;
    return (
      
      currentUrl.includes('manage-announcement') ||
      currentUrl.includes('announcement')
    
    );
  }

  isActiveRouteCandidates(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('resourcefeedback') ||
      currentUrl.includes('resource/feedback') ||
      currentUrl.includes('view-resource') ||
      currentUrl.includes('resource-list') ||
      currentUrl.includes('add-resource')||
      currentUrl.includes('resource')
        );
  }
  isActiveRouteEmployeeRevenueAdjustment(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('add-employee-revenue-adjustment') ||
      currentUrl.includes('manage-employee-revenue-adjustment') ||
      currentUrl.includes('edit-employee-revenue-adjustment')
    );
  }

  isActiveRouteManagePayroll(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('employee-payroll-list') ||
      currentUrl.includes('employee-payroll')
    );
  }

  isActiveRouteOnboardings(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('add-onboarding') ||
      currentUrl.includes('edit-onboarding') ||
      currentUrl.includes('onboardings') ||
      currentUrl.includes('onboardingdetail') 


    );
  }
  navigateToMomReports() {
    this.router.navigate(['/home/mom-reports']);
  }

  navigateTosetUps() {
    this.router.navigate(['/home/organizationcomplaice-list']);
 

  }

  navigateToLeaveList() {
    this.router.navigate(['/home/myleave-list']);
  }
  navigateToRecruitmnet() {
    this.router.navigate(['/home/positions']);
  }

  navigateToRequirement() {
    this.router.navigate(['/home/requirement']);
  }

  navigateToManagePayslip() {
    this.router.navigate(['/home/manage-payslip']);
  }
  navigateToPayslip() {
    this.router.navigate(['/home/employee-payslip']);
  }

  navigateToCandidates() {
    this.router.navigate(['/home/resource-list']);
  }

  navigateToEmployeeRevenueAdjustment() {
    this.router.navigate(['/home/manage-employee-revenue-adjustment']);
  }

  navigateToManagePayroll() {
    this.router.navigate(['home/employee-payroll-list']);
  }

  navigateToManageAnnexure() {
    this.router.navigate(['/home/employee-annexue-list']);
  }

  navigateToEmployeeLeave() {
    this.router.navigate(['/home/manage-employee-leave']);
  }

  navigateToEmployeeRating() {
    this.router.navigate(['/home/employee-rating-list']);
  }

  navigateToAssignEmployee() {
    this.router.navigate(['/home/assignlist']);
  }

  navigateToAnnouncement() {
    this.router.navigate(['/home/manage-announcement']);
  }

  navigateToConsolidatedReports() {
    this.router.navigate(['/home/consolidatereport-list']);
  }

  navigateToorganizationcomplaice() {
    this.router.navigate(['/home/organizationcomplaice-list']);
  }
  navigateToEmployeeRecovery() {
    this.router.navigate(['/home/recovery-adjustment-list']);
  }
  navigateToOnboardings() {
    this.router.navigate(['/home/onboardings']);
  }
  navigateToAppraisal() {
    this.router.navigate(['/home/appraisal']);
  }
  isMomActiveCreation(): boolean {
    return this.router.isActive('/mom-creation', true);
  }
  navigateToleavebalance() {
    this.router.navigate(['/home/leave-balance']);
  }
  isleavebalance(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('leave-balance');
  }
  navigateToleaveadjustment() {
    this.router.navigate(['/home/leave-adjustment-list']);
  }
  isleaveadjustment(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('leave-adjustment-list') ||
      currentUrl.includes('leave-adjustment')
    );
  }
  navigateToleavetype() {
    this.router.navigate(['/home/type-list']);
  }
  isleavetype(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('type-list');
  }
  navigateToemptimesettings() {
    this.router.navigate(['/home/emptimesetting-list']);
  }
  isemptimesettings(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('emptimesetting-list');
  }
  navigateToleaverequest() {
    this.router.navigate(['/home/manage-leave-request']);
  }
  isleaverequest(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('manage-leave-request');
  }
  navigateTomanagepermission() {
    this.router.navigate(['/home/manage-permission']);
  }
  ismanagepermission(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('manage-permission');
  }
  navigateTomanagewfh() {
    this.router.navigate(['/home/manage-wfh']);
  }
  ismanagewfh(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('manage-wfh');
  }
  navigateTomanagebusinesstravel() {
    this.router.navigate(['/home/manage-business-travel']);
  }
  ismanagebusinesstravel(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('manage-business-travel');
  }
  navigateTomanagewithdrawalrequest() {
    this.router.navigate(['/home/manage-withdrawal-request']);
  }
  ismanagewithdrawalrequest(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('manage-withdrawal-request');
  }
  navigateTomyleavelist() {
    this.router.navigate(['/home/myleave-list']);
  }
  ismyleavelist(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('myleave-list') || currentUrl.includes('apply-leave')
    );
  }
  navigateTomypermissionlist() {
    this.router.navigate(['/home/mypermission-list']);
  }

  navigateTomywfhlist() {
    this.router.navigate(['/home/mywfh-list']);
  }

  navigateToBusinessTravelList() {
    this.router.navigate(['/home/mybusinesstravel-list']);
  }

  navigateToLeaveDetail() {
    this.router.navigate(['/home/leave-Detail']);
  }
  isLeaveDetail(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('leave-Detail') || currentUrl.includes('withdrawList')
    );
  }
  navigateToPermissionDetail() {
    this.router.navigate(['/home/permission-Detail']);
  }
  isPermissionDetail(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('permission-Detail');
  }
  navigateToWorkfromHome() {
    this.router.navigate(['/home/work-from-home']);
  }
  isWorkfromHome(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('work-from-home');
  }
  navigateTobusinesstravel() {
    this.router.navigate(['/home/business-travel']);
  }

  navigateToAppraisalReports() {
    this.router.navigate(['/home/report-of-appraisal']);
  }
  isbusinesstravel(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('business-travel');
  }

  // isAddAppraisal(): boolean {
  //   // return this.router.isActive('/appraisal-reports', true);
  //   return (
  //     this.currentUrl.includes('reports-appraisal')
  //   );
  // }

  // isActiveAppraisal(): boolean {
  //   return (
  //     this.currentUrl.includes('appraisal') ||
  //     this.currentUrl.includes('add-appraisal')
  //   );
  // }
  isActiveAppraisal(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('appraisal') || currentUrl.includes('add-appraisal')
    );
  }

  isAddAppraisal(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('report-of-appraisal');
  }

  isActiveAppraisal2(): boolean {
    return this.router.isActive('/home/appraisal', true);
    // const currentUrl = this.router.url;
    // return (
    //   currentUrl.includes('appraisal') ||
    //   currentUrl.includes('add-appraisal')
    // );
  }
  isActiveRequirement(): boolean {
    return this.router.isActive('/home/positions', true);
  }
  isActiveRequirement1(): boolean {
    return this.router.isActive('/home/add-position', true);
  }
  isRequirement(): boolean {
    return this.router.isActive('/home/requirement', true);
  }

  isActiveRequirementadd1(): boolean {
    return this.router.isActive('/home/requirement-add', true);
  }

  // isActiveAppraisal1(): boolean {
  //   return this.router.isActive('/home/add-appraisal', true);
  //   // const currentUrl = this.router.url;
  //   // return (
  //   //   currentUrl.includes('appraisal') ||
  //   //   currentUrl.includes('add-appraisal')
  //   // );
  // }

  isActiveAppraisal1(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('add-appraisal') || currentUrl.includes('appraisal')
    );
  }

  isActiveCreateApprovers(): boolean {
    return this.router.isActive('/home/appraisal-reports', true);
    // const currentUrl = this.router.url;
    // return (
    //   currentUrl.includes('appraisal') ||
    //   currentUrl.includes('add-appraisal')
    // );
  }
  // isActiveAddAssignApprovers():boolean{
  //   this.isShowAdvanceProject = false;
  //   return this.router.isActive('/home/add-assaign-approver', true);
  // }
  // isActiveAssignApprovers():boolean{
  //   this.isShowAdvanceProject = false;

  //   return this.currentUrl.includes('assign-approver');
  //   // return this.router.isActive('/home/assign-approver', true);
  // }

  isActiveAssignApprovers(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('assign-approver') ||
      currentUrl.includes('add-assaign-approver')
    );
  }

  isActiveEmployees(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('employee') ||
      currentUrl.includes('add-employee') ||
      currentUrl.includes('employee-roles') 
    );
  

    // return (
    //   currentUrl.startsWith('/home/employee') ||
    //   currentUrl.startsWith('/home/add-employee')
    // );
  }
  isActiveNewsfeed():boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('newsfeed')
    );
  }
  // isActiveOrganization(): boolean {
  //   const currentUrl = this.router.url;
  //   return (
  //     currentUrl.includes('organization') ||
  //     currentUrl.includes('add-organization') ||
  //     currentUrl.includes('edit-organization') ||
  //     currentUrl.includes('view-organization') 


  //   )
  // }
  isActiveOrg():boolean{
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('organization') ||
      currentUrl.includes('add-organization') ||
      currentUrl.includes('edit-organization') ||
      currentUrl.includes('view-organization') ||
      currentUrl.includes('add-client') ||
      currentUrl.includes('edit-clients')


    )
  }
  isActiveEmployeeGroups(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('groups-list-emp') ||
      currentUrl.includes('emp-groups')
    );
    // return (
    //   currentUrl === '/employee-groups-list' ||
    //   currentUrl === '/employee-groups'
    // );
  }
  // isActiveEmployeeGroups(): boolean {
  //   const currentUrl = this.router.url;
  //   return (
  //     currentUrl.startsWith('/home/groups-list-emp') ||
  //     currentUrl.startsWith('/home/emp-groups')
  //   );
  // }
  
  // isActiveEmployeeGroups(): boolean {
  //   const currentUrl = this.router.url;
  //   return (
  //     currentUrl === '/employee-groups-list' ||
  //     currentUrl === '/employee-groups'
  //   );
  // }

  navigateToAssignApprovers() {
    this.router.navigate(['/home/assign-approver']);
    this.isShow =  false;
  }
  navigateToEmployees() {
    this.router.navigate(['/home/employee']);
  }
  navigateToNewsfeed(){
    this.router.navigate(['/home/newsfeed']);
  }
  // navigateToOrganization() {
  //   this.router.navigate(['/home/organization']);
  // }
  navigateToOrg() {
    this.router.navigate(['/home/organization']);
  }
  navigateToBusinessUnit() {
    this.router.navigate(['/home/business-unit']);
  }
  navigateToBusinessGroup() {
    this.router.navigate(['/home/business-list']);
  }
  navigateToSpplier() {
    this.router.navigate(['/home/supplier-list']);
  }
  navigateToClientEmp(){
    this.router.navigate(['/home/assigns-cliemp-list']);

  }
  navigateToClientSpplier() {
    this.router.navigate(['/home/ClientSup-list']);
  }
  navigateToLegalentity() {
    this.router.navigate(['/home/legal-list']);
  }

  navigateToEmployeeGroups() {
    this.router.navigate(['/home/groups-list-emp']);
  }
 
  navigateTomanageApprover(){
    this.router.navigate(['/home/manage-approver-list']);
  }
  isActiveRoutemanageApprover(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('manage-approver-list') || currentUrl.includes('manage-approver')
    );
  }
  navigateToemployeeApprover(){
    this.router.navigate(['/home/manage-emp-approver-list']);
  }
  isActiveRouteemployeeApprover(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('manage-emp-approver-list') || currentUrl.includes('manage-empl-approver')
    );
  }
  navigateToclients(){
    this.router.navigate(['/home/clients']);
  }
  isActiveRouteclients(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('add-client') || currentUrl.includes('clients')
    );
  }
  navigateToclientElements(){
    this.router.navigate(['/home/select-elements']);
  }
  isActiveRouteclientElements(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('select-elements') || 
      currentUrl.includes('clientElementassign')||
      currentUrl.includes('organizatio-Assignemnt')


    );
  }
  navigateToclientWorkingDays(){
    this.router.navigate(['/home/select-workingdays']);
  }
  isActiveRouteclientWorkingDays(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('select-workingdays')
    );
  }
  navigateToEmployeeshift(){
    this.router.navigate(['/home/emp-shift-list']);
  }
  isActiveRouteEmployeeshift(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('emp-shift-list') || currentUrl.includes('employee-shift')
    );
  }
  navigateToSettings(){
    this.router.navigate(['/home/settings']);
  }
  isActiveRouteSettings(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('settings') || 
      currentUrl.includes('role') ||
      currentUrl.includes('branch') ||
      currentUrl.includes('add-branch') ||
      currentUrl.includes('department') ||
      currentUrl.includes('designation') ||
      currentUrl.includes('qualification') ||
      currentUrl.includes('financialyear') ||
      currentUrl.includes('variablepayqualifier') ||
      currentUrl.includes('element-percentage') ||
      currentUrl.includes('manage-work-hours') ||
      currentUrl.includes('application-settings') ||
      currentUrl.includes('company-settings') 

    );
  }
  
  isMomActionItemReport(): boolean {
    return this.router.isActive('/action-item-report', true);
  }

  isSalaryAdvance(): boolean {
    return this.router.isActive('/SalaryAdvance-list', true);
  }

  isManageSalaryApprovals(): boolean {
    return this.router.isActive('/salary-manage-approvals', true);
  }
  navigateExpenseReibursement() {
    this.router.navigate(['/home/expenseReimbursement']);
  }

  isActiveExpenseReibursement(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('expenseReimbursement') ||
      currentUrl.includes('expenseReimbursementCreate')
    );
  }

  checkActiveRoutes(): void {
    this.isActiveAppraisal();
    this.isAddAppraisal();
  }

  //pms//

  isActiveRouteAddkra(): boolean {
    // const currentUrl = this.router.url;
    // return (
    //   currentUrl.includes('add-kra-template') ||
    //   currentUrl.includes('kra-template') ||
    //   currentUrl.includes('edit-kra-template')
    // );
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('kra-template') &&
      !currentUrl.includes('assign-kra-template')
    );
  }

  isActiveRouteManageAppraisal(): boolean {
    // const currentUrl = this.router.url;
    // return (
    //   currentUrl.includes('add-kra-template') ||
    //   currentUrl.includes('kra-template') ||
    //   currentUrl.includes('edit-kra-template')
    // );
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('appraisal') || currentUrl.includes('add-appraisal')
    );
  }

  navigateToaddkra() {
    this.router.navigate(['/home/kra-template']);
  }

  navigateToManageAppraisal() {
    this.router.navigate(['/home/appraisal']);
  }

  // isActiveRouteAssignkra(): boolean {
  //   const currentUrl = this.router.url;
  //   return (
  //     currentUrl.includes('add-assign-kra-template') ||
  //     currentUrl.includes('assign-kra-template') ||
  //     currentUrl.includes('edit-assign-kra')
  //   );
  // }

  navigateToassignkra() {
    this.router.navigate(['/home/assign-kra-template']);
  }

  isadminAppraisal(): boolean {
    return this.router.isActive('/admin-reports', true);
  }

  isActiveRouteAssignkraTemplate(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('add-assign-kra-template') ||
      currentUrl.includes('assign-kra-template') ||
      currentUrl.includes('edit-assign-kra')
    );
    // const currentUrl = this.router.url;
    // return (
    //   currentUrl.includes('assign-kra-template')
    // );
  }

  navigateToAssignkraTemplate() {
    this.router.navigate(['/home/assign-kra-template']);
  }

  isActiveKraTemplate() {
    return this.router.isActive('/home/kra-template', true);
  }

  isActiveAddKraTemplate() {
    return this.router.isActive('/home/add-kra-template', true);
  }

  isActiveEditKraTemplate() {
    return this.router.isActive('/home/edit-assign-kra', true);
  }
  
  
}
