import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
import { AddDepartmentComponent } from '../add-department/add-department.component';
import { httpOptions,responseMsgTimeOut , redirectMsgTimeOut} from '../../../providers/properties';
import {Sort} from '@angular/material/sort';
@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit
{
  public searchFilter: any = '';
  organzationId: any;
  userdata: any;
search: any;
tableShow: boolean=false;
mySelect: any=5;
nodatafound: boolean=false;
  loader: boolean=true;
  clientId: any;

  constructor(
    private settingsservice: SettingsService,
    private dialog:MatDialog,
  )
  { }

  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  sortedData: any[];

  ngOnInit(): void
  {
    /* Get Department Data */
    /* this.settingsservice.getDepartmentList().subscribe((result: any) => {
      this.resultData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    }) */
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =  this.userdata.organization.organizationId;
    this.clientId = this.userdata?.employee?.client?.clientId;
    console.log("clientId:",this.clientId);
    
    this.departmentList();
  }

  departmentList()
  {
    this.p=1
    this.tableShow =  false;
    this.loader=true

    if(this.organzationId){
    this.settingsservice.getDepartmentList(this.organzationId).subscribe((result: any) => {
      this.tableShow =  true;
      this.loader=false
      this.resultData = result;
      // this.resultData = result.data || []; 


      if(result.statusCode == 204){
        this.nodatafound = true;
      }else{
        this.nodatafound = false;
      }
    if (this.resultData.length === 0) {
      this.nodatafound = true;
    }
    },err =>{
      this.nodatafound = true;

      //this.errorMsg = err.error.message;
    })
  }
    if(this.clientId){
    this.settingsservice.getDepartmentListByClient(this.clientId).subscribe((result: any) => {
      this.tableShow =  true;
      this.loader=false
      this.resultData = result;
      // this.resultData = result.data || []; 


      if(result.statusCode == 204){
        this.nodatafound = true;
      }else{
        this.nodatafound = false;
      }
    if (this.resultData.length === 0) {
      this.nodatafound = true;
    }
    },err =>{
      this.nodatafound = true;

      //this.errorMsg = err.error.message;
    })
  }
  }


  changefirstpage() {
    this.p = 1;
  }

  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AddDepartmentComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.departmentList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData)
  {
    var dialogRef = this.dialog.open(AddDepartmentComponent, {
      width: '500px',height:'fit-content',
      data:editData
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.departmentList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }

  updateStatus(departmentId,status)
	{
    this.settingsservice.updateDepartmentStatus(departmentId,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        // this.success = true;
				// this.sucess_msg = result.message;
        this.loader=true
        this.tableShow=false
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.departmentList();
      }
      else
      {
        // this.error = true;
        // this.error_msg = result.message;
        this.loader=false
        this.tableShow=true
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  ucFirst(string) 
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    console.log("data::",data);
    
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'departmentName':
          return compare(a.departmentName, b.departmentName, isAsc);

        case 'departmentCode':
          return compare(a.departmentCode, b.departmentCode, isAsc);
        

        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}