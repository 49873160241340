import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HrmsService } from '../../providers/hrms.service';
import { AdminService } from '../../providers/admin.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';
import { SuperadminService } from 'src/app/providers/superadmin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit
{

  registerForm: FormGroup;
  submitted = false;
  errorMsg : any;
  success:any=false;
  sucess_msg:any;

  password;
  show = false;
  error:any=false;
  error_msg:any;

  employeetData:any;
  userData: any;
  employeeId:any;
  //organizationId: any;
  dateFormatName : string = "dd-MMM-yyyy";
  dateFormat: string;
  organizationId: string | number;
  clientId:string | number;
  userId: string | number;
  globalResultData: any;
  isAdmin: any;
  isRequirements: any;
  isEmployee: any;
  isHumanResource: any;
  usertData: any;
  userType: any;

  constructor(
      private formBuilder: FormBuilder ,
      private router : Router,
      private hrmsservice: HrmsService,
      private AdminService: AdminService,
      private SuperadminService: SuperadminService,
  ) { }

  ngOnInit(): void
  {
      this.registerForm = this.formBuilder.group({
        email : ['' ,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        password : ['',[Validators.required ]], //,  Validators.min(4), Validators.max(10)
      });
      this.password = 'password';
  }

  // convenience getter for easy access to form fields
  get validate() { return this.registerForm.controls; }

  //Form Submit
  onSubmit()
  {
    this.submitted = true;

    if (this.registerForm.invalid){return;}

    let postValues =
    {
      emailId: this.registerForm.value.email,
      password : this.registerForm.value.password,
    }

    this.AdminService.adminLogin(postValues).subscribe((resultData: any) =>
    {
      let result = resultData;
      console.log("resposeData",result);
      if(result.statusCode >= 400 )
      {
        this.error = true;
        this.errorMsg = result.message;

        setTimeout(() => {
          this.router.navigate(['/login']);
        }, redirectMsgTimeOut)
      }
      else
      {
        this.userData =JSON.stringify(resultData);
        // console.log("this.userData",this.userData);
        localStorage.setItem('enoteUserData',this.userData);

        this.success = true;
        this.sucess_msg = resultData.message;

        this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
        console.log(this.employeetData)
        let employeeId = this.employeetData ?this.employeetData.employee?.employeeId : 0;
        let OrganizationID = this.employeetData ? this.employeetData.organization.organizationId : 0;
        console.log(OrganizationID);
        let clientId = this.employeetData ? this.employeetData?.employee?.client?.clientId : 0;
        console.log(clientId);
        
        console.log(employeeId);

        if(OrganizationID)
        {
          localStorage.setItem('OrganizationID',OrganizationID);
          localStorage.setItem('UserID',employeeId); 
          this.dateFormat = localStorage.getItem('dateFormatName') ? localStorage.getItem('dateFormatName') : this.dateFormatName;
          this.organizationId = localStorage.getItem('OrganizationID') ? localStorage.getItem('OrganizationID') : 0;
          this.userId = localStorage.getItem('UserID') ? localStorage.getItem('UserID') : 0;
           console.log(this.employeetData.role);
          this.SuperadminService.getOrganizationById(OrganizationID).subscribe((globalResultData:any) =>
          {
            this.globalResultData = globalResultData;
            this.dateFormatName = globalResultData.dateFormat ? globalResultData.dateFormat.dateFormatName : "dd-MMM-yyyy";
    
            let dateFormatName = this.dateFormatName;
            localStorage.setItem('dateFormatName',dateFormatName);
            // themployeetData
          });
          //.......
          localStorage.setItem('moduleCode','CHRMS');

        }


        if(clientId)
          {
            localStorage.setItem('clientId',clientId);
            localStorage.setItem('UserID',employeeId); 
            this.dateFormat = localStorage.getItem('dateFormatName') ? localStorage.getItem('dateFormatName') : this.dateFormatName;
            this.clientId = localStorage.getItem('clientId') ? localStorage.getItem('clientId') : 0;
            this.userId = localStorage.getItem('UserID') ? localStorage.getItem('UserID') : 0;
             console.log(this.employeetData.role);
            this.SuperadminService.getClientById(clientId).subscribe((globalResultData:any) =>
            {
              this.globalResultData = globalResultData;
              this.dateFormatName = globalResultData.dateFormat ? globalResultData.dateFormat.dateFormatName : "dd-MMM-yyyy";
      
              let dateFormatName = this.dateFormatName;
              localStorage.setItem('dateFormatName',dateFormatName);
              // themployeetData
            });
            //.......
            localStorage.setItem('moduleCode','CHRMS');
  
          }

        this.userType = [];
        if(this.employeetData.employee != undefined){
          if (this.employeetData.employee.role != undefined) {
          // Populate userType array with roles
          this.employeetData?.employee?.role?.forEach((role) => {
            this.userType.push(role?.roleName);
          });
          
          // Check if the user is an Admin or Recruiter
          this.isAdmin = this.userType.includes('Admin');
          this.isRequirements = this.userType.includes('Recruiter');
          this.isEmployee = this.userType.includes('Employee');
          this.isHumanResource = this.userType.includes('Human Resource');
          //....
          localStorage.setItem('moduleCode','CHRMS');
          if(this.isAdmin || this.isHumanResource){
            localStorage.setItem('moduleCode','CHRMS');
          }
          else if(this.isRequirements){
            localStorage.setItem('moduleCode','RQMNT');
          }
          else if(this.isEmployee){
            localStorage.setItem('moduleCode','PMS');
           if (this.employeetData?.employee?.client?.clientId) {
            localStorage.setItem('moduleCode','PAYROLL');
           }
          }
        }
        }
        // else{
        //   if (this.employeetData.role != undefined) {
        //   this.employeetData?.role?.forEach((role) => {
        //     this.userType.push(role?.roleName);
        //   });
          
        //   // Check if the user is an Admin or Recruiter
        //   this.isAdmin = this.userType.includes('Admin');
        //   this.isRequirements = this.userType.includes('Recruiter');
        //   this.isEmployee = this.userType.includes('Employee');
        //   this.isHumanResource = this.userType.includes('Human Resource');
        //   //....
        //   localStorage.setItem('moduleCode','CHRMS');
        //   if(this.isAdmin || this.isHumanResource){
        //     localStorage.setItem('moduleCode','CHRMS');
        //   }
        //   else if(this.isRequirements){
        //     localStorage.setItem('moduleCode','RQMNT');
        //   }
        //   else if(this.isEmployee){
        //     localStorage.setItem('moduleCode','PMS');
        //   }
        // }
        // }
        console.log("this.userType",this.userType);
        console.log( "this.isAdmin", this.isAdmin);
        console.log( "this.isHumanResource ", this.isHumanResource);
        console.log( "this.isRequirements ", this.isRequirements);
        console.log( "this.isEmployee" , this.isEmployee);


        //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.router.navigate(['/home']).then(() => {
         // window.location.reload();
        });

        //  setTimeout(() => {
        //   this.router.navigate(['/home/newsfeed']);
        // }) 
        setTimeout(() => {
          console.log(employeeId,this.employeetData)
          // if (employeeId == undefined   ) {
          //   this.router.navigate(['/home/business-list']);
          // } else {
          //   this.router.navigate(['/home/newsfeed']);
          //   // Optionally, you can set an error message for the user here.
          // }
          if (employeeId == undefined) {
            this.router.navigate(['/home/business-list']);
          } else {
            if (this.isAdmin || this.isHumanResource) {
              this.router.navigate(['/home/dashboard']); 
            } else {
              this.router.navigate(['/home/newsfeed']);
              // Optionally set an error or success message
            }
          }
          
        });
      }
    },err =>
    {
      this.errorMsg = err.error.message;
    })
  }

  showPassword()
  {
    if (this.password === 'password')
    {
      this.password = 'text';
      this.show = true;
    }
    else
    {
      this.password = 'password';
      this.show = false;
    }
  }

  /* onReset() {
      this.submitted = false;
      this.registerForm.reset();
  } */

}
