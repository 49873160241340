
<div class="row " style="padding: 14px;   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);" >
    <div class="col-md-1  col-1 pl-0">
        <img src="../../../assets/uploads/Prof Pic.png" alt="" width="50px" height="50px" style="padding:6px;"
        >
    </div>
    <div class="col-md-7 col-7">
        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor <br><span><small>Java Developer
                   </small></span></p>
    </div>
    <div class="col-md-4 col-4" style="margin-top: 5px;">
        <span class="material-icons c" style="color:red; float: right;"  mat-dialog-close>cancel</span>

    </div>
</div>

<div style="max-height: 360px; overflow-y: scroll; overflow-x: hidden; bottom: 0%;">
    <div class="row mt-3" style="width:70%; padding-left: 3%;">
        <div class="col-md-12 ">
            <p style="background-color: #E1E6FC; padding: 15px; border-radius: 15px;">Hi Lokesh, I did some additional testing this morning and everthing seems stable on my end as well.</p>
    
        </div>
    
    </div>
    <div class="row" style="width:70%; float: right; padding-right: 3%;">
        <div class="col-md-12 " >
            <p style="background-color:   #F3F2F2;padding: 15px; border-radius: 15px; ">Great. Did you to get a chance to look into the performance improvement we discussed last week?</p>
    
        </div>
    
    </div>
    <div class="row mt-3" style="width:70%; padding-left: 3%;">
        <div class="col-md-12 ">
            <p style="background-color: #E1E6FC; padding: 15px; border-radius: 15px;">Hi Lokesh, I did some additional testing this morning and everthing seems stable on my end as well.</p>
    
        </div>
    
    </div>
    <div class="row" style="width:70%; float: right; padding-right: 3%;">
        <div class="col-md-12 " >
            <p style="background-color:   #F3F2F2;padding: 15px; border-radius: 15px; ">Great. Did you to get a chance to look into the performance improvement we discussed last week?</p>
    
        </div>
    
    </div>
    <div class="row mt-3" style="width:70%; padding-left: 3%;">
        <div class="col-md-12 ">
            <p style="background-color: #E1E6FC; padding: 15px; border-radius: 15px;">Hi Lokesh, I did some additional testing this morning and everthing seems stable on my end as well.</p>
    
        </div>
    
    </div>
    <div class="row" style="width:70%; float: right; padding-right: 3%;">
        <div class="col-md-12 " >
            <p style="background-color:   #F3F2F2;padding: 15px; border-radius: 15px; ">Great. Did you to get a chance to look into the performance improvement we discussed last week?</p>
    
        </div>
    
    </div>
    <div class="row mt-3" style="width:70%; padding-left: 3%;">
        <div class="col-md-12 ">
            <p style="background-color: #E1E6FC; padding: 15px; border-radius: 15px;">Hi Lokesh, I did some additional testing this morning and everthing seems stable on my end as well.</p>
    
        </div>
    
    </div>
    <div class="row" style="width:70%; float: right; padding-right: 3%;">
        <div class="col-md-12 " >
            <p style="background-color:   #F3F2F2;padding: 15px; border-radius: 15px; ">Great. Did you to get a chance to look into the performance improvement we discussed last week?</p>
    
        </div>
    
    </div>  
    
</div>

<div class="row pl-3" style="padding: 3%;">
    <div class="col-md-10 col-xl-10 col-sm-10 col-9" >
        <div style="position: relative;">
            <!-- <input type="text" class="form-control" placeholder="Write message..."
                   style="border-radius: 20px;  outline: none;"> -->
                   <!-- <textarea type="text" placeholder="Write message..."
                                                rows="1"
                                                class="form-control form-control-solid form-control-lg"  style="border-radius: 20px;"
                                                ></textarea> -->
                                                <div class="textarea-container">
                                                    <textarea id="auto-resize-textarea" class="auto-resize-textarea" placeholder="Type here..."></textarea>
                                                  </div>
            <i class="fa fa-smile-o" style="position: absolute; top: 50%; right: 10px; background-color: #EBEBEB;  border-radius: 50%; transform: translateY(-50%);padding: 8px;"></i>
        </div>
    </div>
    <div class="col-md-2 col-xl-2 col-sm-2 col-3" >
        <!-- <i class="fa fa-paper-plane" style="padding: 15px; background-color: #1A83FF; color: white; border-radius: 15px;" aria-hidden="true"></i> -->
    <img src="../../assets/uploads/icons8-send-30.png" width="40px" height="40px" style="background-color: #1A83FF; padding: 10px; border-radius:10px;" alt="">
    </div>
</div>

