import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { httpOptions } from './properties';
import { environment } from 'src/environments/environment';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  getCategoryNameList(organizationId: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/organization/${organizationId}`, httpOptions).pipe(map(data => data));

  }
  usertype: any;
  organizationId: any;
  constructor(private http: HttpClient) {

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    if (this.usertype != undefined) {
      let userId = this.usertype.userId;
      this.organizationId = this.usertype.organization.organizationId;
    }


  }

  /**LOV List start **/
  getLovCountries(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}country/active_country`, httpOptions).pipe(map(data => data))
    return result;
  }

  getLovStates(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}state/active_state`, httpOptions).pipe(map(data => data))
    return result;
  }


  getLovDistricts(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}district/active_district`, httpOptions).pipe(map(data => data))
    return result;
  }

  getOrganizationListForDropdown(entityId: number): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}organization/entity/${entityId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  searchFinancialYearPeriodOrg(financialYear: number, financialPeriod: number, organizationId: number): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}organizationWorkingDays/financial-year/${financialYear}/financialPeriod/${financialPeriod}/organization/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  searchFinancialYearPeriod(financialYear:number,financialPeriod:number):Observable<any>{
      let result =  this.http.get<any>(`${environment.base_url}organizationWorkingDays/financial-year/${financialYear}/financialPeriod/${financialPeriod}`,httpOptions).pipe(map(data=>data))
      return result;
  }
  //http://localhost:6015/api/enote/hrms/organizationWorkingDays/financial-year/4/financialPeriod/61

  getLovCities(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}city/active_city`, httpOptions).pipe(map(data => data))
    return result;
  }
  /**LOV List end **/

  /*****Department start here*****/
  createDepartment(item): Observable<any> {
    let result = this.http.post(`${environment.base_url}department`, item, httpOptions).pipe(map(data => data))
    return result;
  }

  createCheckExistDepartmentCode(postData: any) {
    return this.http.post(`${environment.base_url}department/create/departmentcode`, postData, httpOptions).pipe(map(data => data));
  }

  createCheckExistDepartmentName(postData: any) {
    return this.http.post(`${environment.base_url}department/create/departmentname`, postData, httpOptions).pipe(map(data => data));
  }

  listworkingdaysBasedYearPeriodOrg(financialYear:string,financialPeriod:string,OrganizationId:number){
    let result = this.http.get(`${environment.base_url}organizationWorkingDays/financial-year/${financialYear}/financialPeriod/${financialPeriod}/organization/${OrganizationId}`, httpOptions).pipe(map(data => data));
    return result;
  }

  // http://localhost:6015/api/enote/hrms/organizationWorkingDays/financial-year/4/financialPeriod/61/organization/1
  getDepartmentById(id: any) {
    let result = this.http.get(`${environment.base_url}department/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  editDepartment(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}department/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  getDepartmentList(organizationId): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}department/organziation/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getDepartmentListByClient(clientId): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}department/client/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateDepartmentStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}department/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }
  /*****Department end here*****/


  /*****Branch start here*****/
  createBranch(item): Observable<any> {
    let result = this.http.post(`${environment.base_url}branch`, item, httpOptions).pipe(map(data => data))
    return result;
  }

  getBranchById(id: any) {
    let result = this.http.get(`${environment.base_url}branch/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  editBranch(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}branch/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  getBranchList(organizationId): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}branch/organization/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getBranchListByClient(clientId): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}branch/client/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateBranchStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}branch/deactivate/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }
  /*****Branch end here*****/


  /***** Desgination Start here *****/
  createDesignation(item): Observable<any> {
    let result = this.http.post(`${environment.base_url}designation`, item, httpOptions).pipe(map(data => data))
    return result;
  }
  createCheckExistDesignationName(postData: any) {
    return this.http.post(`${environment.base_url}designation/create/designationname`, postData, httpOptions).pipe(map(data => data));
  }
  updateCheckExistDesignationName(postData: any) {
    return this.http.post(`${environment.base_url}designation/update/designationname`, postData, httpOptions).pipe(map(data => data));
  }

  editDesignation(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}designation/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }


  getDesignationById(id: any) {
    let result = this.http.get(`${environment.base_url}designation/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  getDesignationList(organizationId): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}designation/organizationId/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getDesignationListbyClientId(clientId:number): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}designation/client/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  // http://localhost:6015/api/enote/hrms/designation/client/1
  getStreamListbyClient(clientId:number): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}stream/client/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  // http://localhost:6015/api/enote/hrms/stream/client/1 
  updateDeignationStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}designation/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }
  /***** Designation end here *****/


  /*****Qualification start here*****/
  getQualificationList(id): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}stream/organization/${id}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateQualificationStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}stream/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  createQualification(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}stream`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  

  // http://localhost:6015/api/enote/hrms/stream

  createCheckExistQualificationName(postData: any) {
    return this.http.post(`${environment.base_url}stream/create/streamName`, postData, httpOptions).pipe(map(data => data));
  }

  updateCheckExistQualificationName(postData: any) {
    return this.http.post(`${environment.base_url}stream/update/streamName`, postData, httpOptions).pipe(map(data => data));
  }

  getQuaificationById(id: any) {
    let result = this.http.get(`${environment.base_url}stream/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  editQualification(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}stream/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  /***** Qualification end here*****/

  /*****Country start here*****/
  getCountryList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}country`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateCountryStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}country/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }
  createCheckExistCountryName(postData: any) {
    return this.http.post(`${environment.base_url}country/create/countryname`, postData, httpOptions).pipe(map(data => data));
  }
  updateCheckExistCountryName(postData: any) {
    return this.http.post(`${environment.base_url}country/update/countryname`, postData, httpOptions).pipe(map(data => data));
  }
  //Country code exist Start
  createCheckExistCountryCode(postData: any) {
    return this.http.post(`${environment.base_url}country/create/countrycode`, postData, httpOptions).pipe(map(data => data));
  }
  updateCheckExistCountryCode(postData: any) {
    return this.http.post(`${environment.base_url}country/update/countrycode`, postData, httpOptions).pipe(map(data => data));
  }
  //Country code exist End
  createCountry(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}country`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  getCountryById(id: any) {
    let result = this.http.get(`${environment.base_url}country/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  editCountry(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}country/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }
  /*****Country end here*****/

  /*****state start here*****/
  getStateList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}state`, httpOptions).pipe(map(data => data))
    return result;
  }

  getStateFilterList(keyword: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}state/stateName/${keyword}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateStateStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}state/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  createState(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}state`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }
  createCheckExistStateName(postData: any) {
    return this.http.post(`${environment.base_url}state/create/statename`, postData, httpOptions).pipe(map(data => data));
  }
  updateCheckExistStateName(postData: any) {
    return this.http.post(`${environment.base_url}state/update/statename`, postData, httpOptions).pipe(map(data => data));
  }
  getStateById(id: any) {
    let result = this.http.get(`${environment.base_url}state/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  editState(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}state/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }
  /*****state end here*****/

  /*****district start here*****/
  getDistrictList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}district`, httpOptions).pipe(map(data => data))
    return result;
  }


  getDistrictFilterList(keyword: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}district/districtName/${keyword}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateDistrictStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}district/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }
  //District Name exist Start
  createCheckExistDistrictName(postData: any) {
    return this.http.post(`${environment.base_url}district/create/districtName`, postData, httpOptions).pipe(map(data => data));
  }
  updateCheckExistDistrictName(postData: any) {
    return this.http.post(`${environment.base_url}district/update/districtName`, postData, httpOptions).pipe(map(data => data));
  }
  //District Name exist End

  createDistrict(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}district`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  editDistrict(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}district/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  getDistrictById(id: any) {
    let result = this.http.get(`${environment.base_url}district/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  /*****district end here*****/


  /*****city start here*****/
  getCityList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}city`, httpOptions).pipe(map(data => data))
    return result;
  }

  getCityFilterList(keyword: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}city/cityName/${keyword}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateCityStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}city/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  //District Name exist Start
  createCheckExistCityName(postData: any) {
    return this.http.post(`${environment.base_url}city/create/cityname`, postData, httpOptions).pipe(map(data => data));
  }
  updateCheckExistCityName(postData: any) {
    return this.http.post(`${environment.base_url}city/update/cityname`, postData, httpOptions).pipe(map(data => data));
  }
  //District Name exist End

  createCity(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}city`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  editCity(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}city/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  getCityById(id: any) {
    let result = this.http.get(`${environment.base_url}city/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  /*****city end here*****/


  /*****role start here*****/
  getRoleList(organizationId): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}role/organziation/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateRoleStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}role/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }
  createCheckExistRoleName(postData: any) {
    return this.http.post(`${environment.base_url}role/create/rolename`, postData, httpOptions).pipe(map(data => data));
  }
  updateCheckExistRoleName(postData: any) {
    return this.http.post(`${environment.base_url}role/update/rolename`, postData, httpOptions).pipe(map(data => data));
  }
  createRole(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}role`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }
  clientCreateRole(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}client/role`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  editRole(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}role/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }
  editClientRole(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}client/role/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  getRoleById(id: any) {
    let result = this.http.get(`${environment.base_url}role/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  /*****role end here*****/

  /** List type start here **/
  getListTypeList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}list-types`, httpOptions).pipe(map(data => data))
    return result;
  }

  getListTypeValues(id: any) {
    let result = this.http.get(`${environment.base_url}list-type-values/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  getCountryAgainstStateList(id: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}state/countryId/${id}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getStateAgainstDistictList(id: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}district/stateId/${id}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getDistrictAgainstCityList(id: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}city/districtId/${id}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getActiveDateFormat(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}dateFormat/active_dateFormat`, httpOptions).pipe(map(data => data))
    return result;
  }

  getActivemenus(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}menu/active_menu`, httpOptions).pipe(map(data => data))
    return result;
  }

  getFinicalyearActiveList(organizationId: any) {
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/organzation/finanical_status/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }
  getFinicalyearActiveListbyClient(clientId: any) {
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/client/finanical_status/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }
  getFinicalyearActiveListClient(clientId: any) {
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/client/finanical_status/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }
  getClientFinancialYear(clientId:any){
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/client/finanical_status/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getFinicalyearList(organizationId) {
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/organzation/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }
  getFinicalyearListbyClient(clientId:any) {
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/client/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }


  getClientFinicalyearList(clientId) {
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/client/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getElementList(orgId: any) {
    let result = this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/organization/${orgId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getClientElementList(clientId: any) {
    let result = this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/client/${clientId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  createFinancialYear(postValues: any) {

    let result = this.http.post<any>(`${environment.base_url}finanicalyear`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  getFinancialYearId(financialyearId: any) {
    let result = this.http.get<any>(`${environment.base_url}finanicalyear/${financialyearId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateFinancialYear(financialyearId: any, finanicalyeardata: any) {
    let result = this.http.put<any>(`${environment.base_url}finanicalyear/${financialyearId}`, finanicalyeardata, httpOptions).pipe(map(data => data))
    return result;
  }

  validatecalendarname(finanicalyeardata: any) {
    let result = this.http.post<any>(`${environment.base_url}finanicalyear/create`, finanicalyeardata, httpOptions).pipe(map(data => data))
    return result;
  }
  validatecalendarnameupdate(finanicalyeardata) {
    let result = this.http.post<any>(`${environment.base_url}finanicalyear/update/`, finanicalyeardata, httpOptions).pipe(map(data => data))
    return result;
  }



  updateFinancialYearStatus(id: any, status: any) {
    let result = this.http.put<any>(`${environment.base_url}finanicalyear/status/${id}/${status}`, null, httpOptions).pipe(map(data => data))
    return result;
  }
  /** List type end here **/

  getvariablepayqualiferList(organizationId) {
    let result = this.http.get<any>(`${environment.base_url}variablepayqualifier/organzation/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }
  getvariblepayqualiferratinglikebyClient(tableSearch:any,clientId:any){
    let result=this.http.get<any>(`${environment.base_url}variablepayqualifier/rating/${tableSearch}/client/${clientId}`,httpOptions)
    return result;
  }

  getvariblepayqualiferratinglike(tableSearch: any, organizationId) {
    let result = this.http.get<any>(`${environment.base_url}variablepayqualifier/rating/${tableSearch}/${organizationId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updatequlafierStatus(id: any, status: any) {
    let result = this.http.put<any>(`${environment.base_url}variablepayqualifier/deactivate/${id}/${status}`, null, httpOptions).pipe(map(data => data))
    return result;
  }

  createvarpayqualifer(postValues: any) {
    let result = this.http.post<any>(`${environment.base_url}variablepayqualifier`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  updatevarpayqualifer(id: any, postValues: any) {
    let result = this.http.put<any>(`${environment.base_url}variablepayqualifier/${id}`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  getvariblepayqualiferById(qualiferId: any) {
    let result = this.http.get<any>(`${environment.base_url}variablepayqualifier/${qualiferId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getOrganizationdetails(organizationId: any) {
    return this.http.get(`${environment.base_url}organizationsetting/organization/${organizationId}`, httpOptions)
      .pipe(map(data => data));
  }

  getClientdetails(clientId: any) {
    return this.http.get(`${environment.base_url}organizationsetting/client/${clientId}`, httpOptions)
      .pipe(map(data => data));
  }



  getActiveClientList() {
    return this.http.get(`${environment.base_url}client/active_client`, httpOptions)
      .pipe(map(data => data));
  }
  getActiveSupplierList() {
    return this.http.get(`${environment.base_url}organizationsetting/organization`, httpOptions)
      .pipe(map(data => data));
  }
  getRoleListbyClient(clientId:any){
    return this.http.get(`${environment.base_url}role/client/${clientId}`, httpOptions)
    .pipe(map(data => data));
  }
}
