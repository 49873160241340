import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SuperadminService } from '../../providers/superadmin.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';
import { SettingsService } from '../../providers/settings.service';

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss']
})
export class ApplicationSettingsComponent implements OnInit
{
isbtnDisable: boolean=false;
  dateformateIdstore: any;
dateValidation: boolean=false;

  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private SuperadminService: SuperadminService,
    private ActivatedRoute:ActivatedRoute,
    private SettingsService:SettingsService,
 
  ) { }

  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any;
  

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeetData:any;
  employeeId:any;
  organizationId: any;

  activeDateFormatData :any;

  ngOnInit(): void 
  {
    this.postForm = this.formBuilder.group({
      //organizationName : ['',[Validators.required]],
      dateFormatId : [null,[Validators.required]], 
    });

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;

    if(organizationId)
    {
      this.SettingsService.getActiveDateFormat().subscribe((result: any) =>
        {
          this.activeDateFormatData = result;
          console.log( " this.activeDateFormatData",this.activeDateFormatData);
          this.SuperadminService.getOrganizationById(organizationId).subscribe((resultData:any) =>
            {
              this.resultData=resultData;
              console.log("this.resultData::",this.resultData);
              
              
              this.postForm.patchValue({
                dateFormatId: this.resultData.dateFormat.dateFormatId
              });
            });
        })
    
    }

    // this.getActiveDateFormat();
    this.onDateFormatChange(event)
  }

  get validate() { return this.postForm.controls; }
  selectedFun(){
    this.dateformateIdstore=this.postForm.controls['dateFormatId'].value
    this.dateformateIdstore=null
    this.isbtnDisable=false;
    console.log("entered null function!!");
    
    return
  }
  onDateFormatChange(event: any): void {
    const selectedValue = event.target.value;
    if (selectedValue) {
        this.dateValidation = false;
    }
  }

  onSubmit()
  {
    // this.isbtnDisable=false;
    console.log("form::",this.postForm);
    console.log(this.postForm.controls['dateFormatId'].value);
    
    

    if(this.postForm.invalid){
      console.log("if entered");
      // this.isbtnDisable=false;
      this.dateValidation=true

      // this.isbtnDisable=true;
      return
    }
    this.dateValidation=false

    if(this.postForm.valid){
      console.log("for entered!!!");
      
      // this.isbtnDisable=false;
      this.dateValidation=false

    
    // this.submitted = true;
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;

    console.log();
    
    if(organizationId)
    {
      let postValues = {
        organizationId : organizationId,
        dateFormat:
        {
          dateFormatId : this.postForm.value.dateFormatId,
        }
      };
      console.log("payload::",postValues);

      
      

      //postValues['organization'] = {organizationId: 1}
      this.isbtnDisable=true
      this.SuperadminService.updateOrganizationSettings(organizationId,postValues).subscribe((result: any) =>
      {
        // this.isbtnDisable=true;
        console.log("result::",result)
        this.isbtnDisable=true;

        if(result.statusCode == 200)
        {
          this.success = true;
          // this.sucess_msg = "App System Settings Updated SuccessfullyOrganization settings updated successfully!";
          // window.location.reload();
          this.sucess_msg=result.description
          this.isbtnDisable=true;

        setTimeout(() => {
          this.success = false;
          this.isbtnDisable=false;
          this.sucess_msg =''

            // this.router.navigate(['/home/branch']);
          }, 4000)
        }
        
        else
        {
          this.error = true;
          this.isbtnDisable=false
          this.error_msg = result.message;
          
          setTimeout(() => {
this.error=false;
this.isbtnDisable=false;

            this.router.navigate(['/home/branch']);
          }, 4000)

        }
        // this.isbtnDisable=false

      }, err =>{
        this.errorMsg = err.error.message;
      })
    }

  }
}

  // getActiveDateFormat()
  // {
  //   this.SettingsService.getActiveDateFormat().subscribe((result: any) =>
  //   {
  //     this.activeDateFormatData = result;
  //     console.log( " this.activeDateFormatData",this.activeDateFormatData);
      
  //   })
  // }
}
