<div>
  <div class="d-flex">
    <h2 style="width: 100%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" align="left">Upload Holiday/Exception</h2>
    <mat-icon class="material-icons float-right" style="color: red !important;"
      mat-dialog-close 
    >clear</mat-icon>
  </div>
  <form [formGroup]="fileuploadform" (ngSubmit)="onSubmit()" style="margin: 0 10px;">
    <div class="form-group">
      <div class="input-width" id="loginform" style="display: block;">
        <label class="form-control-label ">Calendar Name<span class="star"> *</span></label>
        <mat-select placeholder="Select Calender name" formControlName="calendar" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px; font-family:Mulish"
        >
          <mat-option [value]="null" selected>Select Calendar Name </mat-option>
          <mat-option *ngFor="let cal of calendarList" [value]="cal.calendarId">{{cal.calendarName}}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.calendar.hasError('required')  && (f.calendar.touched || f.calendar.dirty)">
          <span>Calendar is required</span>
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <div class="file-upload" style="">
        <button mat-mini-fab color="primary" class="upload-btn" type="button"
        (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
        {{fileName || "Choose File"}}
      </div>
      <input type="file" class="file-input" formControlName="attachment"
        accept=".xls,.xlsx" (change)="onFileChange($event)"  #fileUpload>
      <mat-error *ngIf="attachment">
        <span>Attachment is required</span>
      </mat-error>
      <!-- <div>
        <span style="margin-bottom: 7px" class="text-muted import-note"
          >Note : Upload format is .xls / xlsx.</span
        >
      </div> -->
      
     
    </div>
    <div class="row">
      <div class="col-md-12">
        <span class="text-muted">
          *** Please download the sample files and import the data in the specified format. ***<br>
        </span>
        <a
          class="download-sample text-danger "  style="font-size: small;"
          href="../../../../assets/backend/calendar_document/holiday_export_1721630665335.xlsx"
          download="holiday_export.xlsx"
        >
        <!-- <i class="fa fa-download" style="color: blue;" aria-hidden="true"></i>  -->
        Download  Holiday Sample
        </a><br>
        <a
          class="download-sample text-danger" style="font-size: small;"
          href="../../../../assets/backend/calendar_document/exception_export.xlsx"
          download="exception_export.xlsx"
        >
        <!-- <i class="fa fa-download"  style="color: blue;" aria-hidden="true"></i>  -->
          Download  Exception Sample
        </a>
      </div>  
    </div>
    
    <div class="form-group" align="right">
      <button mat-raised-button style="width: 20%; margin: 15px 0 0 8px; color:#ff0000a8;" type="button" 
      class="btn btnRes mr-2" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" style=" margin: 15px 0 0 8px" type="submit" 
      [disabled]="btnDisable"
      class="searchBtn">Upload</button>
    </div>
  </form>
 </div>
 <style>
     
 @import url("https://use.fontawesome.com/releases/v5.5.0/css/all.css");
 
 
 .button:focus{
   outline: 0px!important;
 }
 .file-input {
   display: none;
 }
 .filepath{
     -webkit-box-sizing: content-box;
     box-sizing: content-box;
     background-color: transparent;
     border: 0;
     border-bottom: 1px solid #ced4da;
     border-radius: 0;
     outline: 0;
     -webkit-box-shadow: none;
     box-shadow: none;
     height: 3rem;
 }
 .matTool{
   /* height:280px; */
   box-shadow: rgba(0,0,0,.25) 2px 2px 5px;
 }
 
 .dragAndUpload, .dragAndUpload * { -moz-box-sizing: border-box;
     -webkit-box-sizing: border-box;
     box-sizing: border-box;
     outline: 0;
 }
 
 .dragAndUploadManual {
     display: none;
 }
 
 .dragAndUpload {
     color: rgb(100,100,100);
     cursor: pointer;
     display: block;
     font: 0/1em "Helvetica Neue", "Segoe UI", sans-serif;
     font-weight: 400;
     height: 50px;
     line-height: 16px;
     margin: auto;
     position: relative;
     text-align: left;
     -moz-transition: all .2s;
     -webkit-transition: all .2s;
     transition: all .05s;
     perspective: 1000px;
     width: 200px;
 }
 
 .dragAndUploadText {
     background: rgb(255,255,255);
     border-radius: 9px;
     box-shadow: rgba(0,0,0,.25) 2px 2px 5px;
     display: inline-block;
     font-size: 16px;
     font-weight: bold;
     height: 65px;
     left: 45px;
     padding: 20px 15px;
     position: absolute;
     text-align: center;
     vertical-align: middle;
     width: 195px;
     z-index: 1;
 }
 
 .dragAndUploadTextSmall {
     display: block;
     font-size: 13px;
     font-weight: normal;
 }
 
 
 
 .dragAndUpload .dragAndUploadIcon {
     background: rgb(100,100,100);
     border-radius: 9px 0 0 9px;
     box-shadow: rgba(0,0,0,.25) 1px 1px 4px;
     color: rgb(255,255,255);
     display: inline-block;
     height: 65px;
     position: absolute;
     vertical-align: middle;
     width: 59px;
     z-index: 0;
 }
 
 .dragAndUpload.dragAndUploadFailure .dragAndUploadIcon {
     background: rgb(100,0,0);
 }
 
 .dragAndUpload .dragAndUploadIcon .fas {
     display: block;
     font-size: 22px;
     position: absolute;
     line-height: 65px;
     text-align: center;
     transition: .2s all;
     width: 49px;
 }
 
 .dragAndUpload.dragAndUploadActive .dragAndUploadIcon .fas {
     transform: rotate(180deg);
 }
 
 .dragAndUpload .dragAndUploadIcon .dragAndUploadIconUploading,
 .dragAndUpload .dragAndUploadIcon .dragAndUploadIconSuccess,
 .dragAndUpload .dragAndUploadIcon .dragAndUploadIconFailure {
     display: none;
 }
 
 .dragAndUpload.dragAndUploadUploading .dragAndUploadIcon .dragAndUploadIconNeutral {
     display: none;
 }
 
 .dragAndUpload.dragAndUploadUploading .dragAndUploadIcon .dragAndUploadIconUploading {
     display: block;
 }
 
 .dragAndUpload.dragAndUploadSuccess .dragAndUploadIcon .dragAndUploadIconSuccess {
     display: block;
 }
 
 .dragAndUpload.dragAndUploadFailure .dragAndUploadIcon .dragAndUploadIconFailure {
     display: block;
 }
 
 .dragAndUpload.dragAndUploadSuccess .dragAndUploadIcon .dragAndUploadIconNeutral,
 .dragAndUpload.dragAndUploadFailure .dragAndUploadIcon .dragAndUploadIconNeutral {
     display: none;
 }
 
 .dragAndUpload .dragAndUploadCounter {
     bottom: -20px;
     color: rgb(100,100,100);
     display: none;
     font-size: 12px;
     font-style: normal;
     font-weight: bold;
     line-height: 18px;
     left: 0;
     padding: 0 5px;
     position: absolute;
     text-align: center;
     z-index: 2;
     width: 100%;
 }
 
 .dragAndUpload.dragAndUploadUploading .dragAndUploadCounter,
 .dragAndUpload.dragAndUploadSuccess .dragAndUploadCounter,
 .dragAndUpload.dragAndUploadFailure .dragAndUploadCounter {
     display: block;
 }
 .form-control{
     line-height: 2.0;
     padding:7px 15px 7px 15px;
 }
 .form-control[type=file]:not(:disabled):not([readonly]) {
     cursor: pointer;
     padding-left: 12px;
     padding-bottom: 6px;
 }
 .email-input{
     transition: box-shadow .15s ease;
     border: 0;
     box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
     border-radius: 7px;
      /* color: #8898aa!important;  */
 }
 
 /****** IGNORE ******/
 body { 
   width: 400px; 
   margin: 100px auto; 
   background-color: #f5f5f5; 
 }
 
 .copyright {
   display:block;
   margin-top: 100px;
   text-align: center;
   font-family: 'Mulish',  sans-serif;
   font-size: 12px;
   font-weight: bold;
   text-transform: uppercase;
 }
 .copyright a{
   text-decoration: none;
   color: #EE4E44;
 }
 
 
 /****** CODE ******/
 
 .file-upload{display:block;text-align:center;font-family: Helvetica, Arial, sans-serif;font-size: 12px;}
 .file-upload .file-select{display:block;border: 2px solid #dce4ec;color: #34495e;cursor:pointer;height:40px;line-height:40px;text-align:left;background:#FFFFFF;overflow:hidden;position:relative;}
 .file-upload .file-select .file-select-button{background:#dce4ec;padding:0 10px;display:inline-block;height:40px;line-height:40px;}
 .file-upload .file-select .file-select-name{line-height:40px;display:inline-block;padding:0 10px;}
 .file-upload .file-select:hover{border-color:#34495e;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
 .file-upload .file-select:hover .file-select-button{background:#34495e;color:#FFFFFF;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
 .file-upload.active .file-select{border-color:#3fa46a;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
 .file-upload.active .file-select .file-select-button{background:#3fa46a;color:#FFFFFF;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
 .file-upload .file-select input[type=file]{z-index:100;cursor:pointer;position:absolute;height:100%;width:100%;top:0;left:0;opacity:0;filter:alpha(opacity=0);}
 .file-upload .file-select.file-select-disabled{opacity:0.65;}
 .file-upload .file-select.file-select-disabled:hover{cursor:default;display:block;border: 2px solid #dce4ec;color: #34495e;cursor:pointer;height:40px;line-height:40px;margin-top:5px;text-align:left;background:#FFFFFF;overflow:hidden;position:relative;}
 .file-upload .file-select.file-select-disabled:hover .file-select-button{background:#dce4ec;color:#666666;padding:0 10px;display:inline-block;height:40px;line-height:40px;}
 .file-upload .file-select.file-select-disabled:hover .file-select-name{line-height:40px;display:inline-block;padding:0 10px;}
 </style>
