import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ViewdescripeComponent } from '../viewdescripe/viewdescripe.component';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { HeaderService } from 'src/app/providers/header.service';
import { ReplaySubject, Subject, catchError, switchMap, take, takeUntil, throwError } from 'rxjs';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { MatSelect } from '@angular/material/select';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-consolidate-timesheet',
  templateUrl: './consolidate-timesheet.component.html',
  styleUrls: ['./consolidate-timesheet.component.scss'],
})
export class ConsolidateTimesheetComponent implements OnInit {
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
  public employeeProjects: any = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  selectedEmployeeSearch: any;
  selectedemployee: any;
  projectId: any = undefined;
  private unsubscribe$: Subject<void> = new Subject<void>();
  beforesearchpage: any;
  employeeNotFound: boolean = false;
  employeeSelected: boolean = false;
  projectList: any = [];
  taskList: any;
  projectStatus: any = '0';
  timesheetDate: any;
  p: any;
  user_type: string;
  search: any;
  userType: any;
  isData: boolean;
  taskId: number = 0;
  timesheetreport: any[] = [];
  isproject: boolean;
  nodata = true;
  loader = false;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  employeelist: any = [];
  employeeId: any;
  searchButtonDisable: boolean = false;
  projectlist: any;
  selectedemployeeId: any;
  startDate: any;
  endDate: any;
  estimatedSeconds: string = '00';
  disable_project: boolean = true;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  tasktotalTime: any;
  today: any;
  employee_required: boolean = false;
  empId: any;
  organizationId: any;
  organizationName:any;
  orgAddress:any;
  showEmptyData: boolean = false;
  project_required: boolean = false;
  // employeeProjects: any;
  // selectedemployee: any = undefined;
  employee: string;
  employeeNumber: any;
  endSelectedFirst: boolean;
  tableShow: boolean = false;
  checkEmployeeIds: any[] = [];

  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;


  constructor(
    private gs: ProjectManagementService,
    private router: Router,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
  ) { }



  ngAfterViewInit() {
    this.setInitialValue();


    // this.removePlaceholder();

  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.employeeProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  ngOnInit(): void {
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage('');
    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    // this.headerservice.setTitle('Consolidate Timesheet Report');
    this.today = new Date();
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');
    const data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = data.employee.employeeId;
    this.organizationId = data.organization.organizationId;
    console.log('organzationid' + this.organizationId);

    this.organizationName=  data.organization.organizationName;
    this.orgAddress=  data.organization.address;

    // this.gs.listProjectList(this.organizationId).subscribe((data: any) => {
    //   console.log('data', data);

    //   console.log('data', data[0].projectId);
    //   // this.projectList.push(data);
    //   this.projectList = data;
    //   this.projectlist = data;

    //   console.log('this.projectlist', this.projectlist[0].projectName);
    // });

    this.gs.getProjectsIfTasks(this.organizationId).subscribe((data: any) => {
      console.log('data', data);

      console.log('data', data[0].projectId);
      // this.projectList.push(data);
      this.projectList = data;
      this.projectlist = data;

      console.log('this.projectlist', this.projectlist[0].projectName);
    });

    // this.gs.getResource().subscribe(
    //   (d: any) => {
    //     // this.loader = false;
    //     this.employeelist = d;
    //   },
    //   (error) => {
    //     //  this.loader = false;
    //      this.nodata = false;
    //     console.log("error");
    //   }
    // );
  }

  // empSearch(event) {
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);
  //   // Employee List Api
  //   if (employeenumber) {
  //     // this.payrollService.getEmployeeNameList(employeenumber, this.organizationId).subscribe((result: any) => {
  //     //    if(result.statusCode)
  //     //    {
  //     //     this.empList.length = 0;
  //     //    }else{
  //     //      console.log("else")
  //     //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
  //     //    }

  //     // })

  //     this.gs.getEmployeesBynumber(employeenumber).subscribe((result: any) => {
  //       this.employeeProjects = result;

  //       console.log('this.employeeProjects', this.employeeProjects);
  //       if (result.statusCode) {
  //       } else {
  //         this.employeeProjects = result;

  //         console.log('this.employeeProjects', this.employeeProjects);
  //       }
  //     });
  //   }
  // }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }
  selectProject() {
    this.projectId = undefined;
    this.employeelist.length = 0;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.employeeNotFound = false;
  }
  getTotalEstimatedTime(tasks: any[]): string {
    if (!tasks) {
      return '00:00:00'; // or any default value you prefer
    }

    let totalHours = 0;
    let totalMinutes = 0;

    tasks.forEach(task => {
      totalHours += task.estimatedHours || 0;
      totalMinutes += task.estimatedMinutes || 0;
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}:00`;
  }

  handleBackspace(event): void {


    const inputValue = event.target.value;

    if (event.key === 'Backspace' && inputValue.length <= 0) {
      this.selectedemployeeId = undefined;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.employeeNotFound = false; // Set employeeNotFound to false
    }

    if (
      event.key === 'Backspace' &&
      !this.selectedemployee &&
      this.selectedemployeeId.trim().length === 0
    ) {
      this.selectedemployeeId = undefined;
      this.employeeNotFound = true;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.selectedemployeeId = undefined;
    }
  }
  noEmployeeFound() {
    this.employeeNotFound = false;
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.employeeProjects.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
  }
  onInputChange() {
    this.search = this.selectedemployee.trim();
  }

  onInputClick() {
    if (this.search === '') {
      this.selectedemployee = ''; // Clear the input value
    }
  }
  empSearch(event, event1: KeyboardEvent): void {


    if (event1.key === ' ') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      this.employeeProjects = [];
      console.log(event.target.value);
      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log(employeenumber);

      // Cancel the previous API request
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();
      console.log('checking Project Id', this.projectId);
      if (employeenumber.length == 0) {
        this.employeeNotFound = false
        console.log("enterd into the condition!!");
        // this.employeeId = undefined;
        // this.employee = undefined;

      }

      if (employeenumber && this.projectId > 0) {
        if (employeenumber.length >= 2) {
          this.gs
            .filterEmployeesByProjects(
              this.organizationId,
              this.projectId,
              employeenumber
            )
            .pipe(
              takeUntil(this.unsubscribe$),
              switchMap(() =>
                this.gs.filterEmployeesByProjects(
                  this.organizationId,
                  this.projectId,
                  employeenumber
                )
              ),
              catchError((err) => {
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.employeeNotFound = true;
                }
                this.employeeNotFound = true;
                console.error('Error filtering employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error filtering employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);
              if (result.errorCode === 204) {
                this.employeeNotFound = true;
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
              }
              this.employeeProjects.length = 0;

              result.forEach((res) => {
                console.log('employeeId', res.employee.employeeId);

                let employee = {
                  employee: {
                    employeeId: res.employee.employeeId,
                    firstName: res.employee.firstName,
                    employeeNumber: res.employee.employeeNumber,
                  },
                };

                if (Object.keys(employee).length > 0) {
                  this.employeeNotFound = false;
                } else {
                  this.employeeNotFound = true;
                }

                console.log('this.employeeProjects', this.employeeProjects);

                this.employeeProjects.push(employee);
              });

              // if (this.selectedemployee.length >= 1) {
              //   this.employeeSelected = true;
              // }

              if (result.errorCode === 204) {
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
                // alert('No Employee Found');
                this.employeeNotFound = true;
                this.employeeProjects.length = 0;
                // this.employeeProjects.push('No Employee Found');
                this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
              }
            });
        }
      } else {
        // Set selectedemployeeId to null if the input is empty
        this.selectedemployeeId = null;
      }
    }
  }

  empSelect(emp) {
    console.log('emp', emp);
    this.employeeId = emp.employee.employeeId;
    console.log(this.employeeId);
    this.employee = emp.employee.employeeNumber + '-' + emp.employee.firstName;
    this.employeeNumber = emp.employee.employeeNumber;
    this.selectedemployee = this.employee;
    this.selectedemployeeId = emp.employee.employeeId;
    // this.assignformbyemployee.patchValue({
    //   employeecode: this.employee,
    // });
    //  let  employeecode=this.selectedemployeeId;

    // console.log('this.employee', employeecode);
  }
  getTask() {
    // this.employeeNotFound = false;
    // this.employeeId = undefined;

    // this.employee = undefined;
    // this.employeeNumber = undefined;
    // this.selectedemployee = undefined;
    // this.selectedemployeeId = undefined;
    // this.employee_required = false;
    // this.disable_project = false;
    // this.employeeProjects = [];
    this.employeeNotFound = false;
    console.log(this.disable_project);
    console.log('employeeId' + this.selectedemployeeId);
    let matchFound = false;
    if (this.projectId == undefined) {
      this.employeelist.length = 0;
    } else {
      this.gs.getEmployeeProject(this.projectId).subscribe((data: any) => {
        console.log(data);
        this.employeelist = data;
        //   for (let index = 0; index < this.employeelist.length; index++) {
        //     this.checkEmployeeIds = [];
        //     if (this.employeelist[index]?.employeeId === this.employeeId) {
        //         this.checkEmployeeIds.push(this.employeelist[index]?.employeeId);
        //     }
        //     console.log('currentEmployee', this.checkEmployeeIds);
        //     console.log('array',this.employeelist);
        //     console.log('this.employeelist[index]', this.checkEmployeeIds)
        //     console.log('this.employeeId', this.employeeId);

        //     if ( this.checkEmployeeIds[index] === this.employeeId) {
        //         // if (this.selectedemployeeId === undefined) {
        //         //     this.selectedemployee = this.employee;
        //         // }
        //         matchFound = true;
        //         break; // Exit the loop as we found a match
        //     }

        //     console.log('this.selectedemployee',this.selectedemployee);

        //     if(this.selectedemployee  != undefined){

        //       if(this.checkEmployeeIds[index] != this.employeeId){

        //         if (this.employeeId != undefined && this.selectedemployee != undefined) {


        //           this.selectedemployee = undefined;
        //           this.employeeNotFound = false;
        //           this.employeeProjects.length = 0;
        //           this.employeeId = undefined;
        //           this.employee = undefined;
        //           this.employeeNumber = undefined;
        //           matchFound = false;
        //           break; 
        //         }

        //       }
        //     }


        // }
        this.employeeNotFound = false;
        if (this.selectedEmployeeSearch != undefined) {
          let hasemployee = this.employeelist.some(item => item.employeeId === this.employeeId);
          // console.log("hasemployee" + hasemployee);
          // console.log("index" + index);
          if (!hasemployee) {

            this.selectedemployee = undefined;
            this.selectedEmployeeSearch = undefined;
            this.employeeNotFound = false;
            this.selectedemployee = undefined;
            this.employeeNotFound = false;
            this.employeeProjects.length = 0;
            this.employeeId = undefined;
            this.employee = undefined;
            this.employeeNumber = undefined;
            matchFound = false;

          }
        }
        this.project_required = false;
      });
    }
  }
  dateChange(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const input = document.getElementById('endDate') as HTMLInputElement;
      // input.classList.remove('select-placeholder');
    } else {
      const input = document.getElementById('endDate') as HTMLInputElement;
      // input.classList.add('black-placeholder');
    }

    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  changefirstpage() {
    this.p = 1;
  }

  resetForms() {
    this.loader = false;
    this.employeeProjects = [];
    this.timesheetreport = [];
    this.selectedemployeeId = undefined;
    this.selectedemployee = undefined;
    this.projectId = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.timesheetreport.length = 0;
    this.showEmptyData = true;
    this.nodata = true;
    this.enddate_required = false;
    this.startdate_required = false;
    this.searchButtonDisable = false;
    this.tableShow = false;
  }

  showErrorMessage = false;

  @ViewChild('projectContainer') projectContainer: ElementRef;
  @ViewChild('projectSelect') projectSelect: ElementRef;

  onSelectionChange() {
    this.showErrorMessage = true;
    this.getTask();
  }

  // Detect clicks outside the select box
  // @HostListener('document:click', ['$event'])
  // onClickOutside(event: Event) {
  //   if (
  //     !this.projectContainer.nativeElement?.contains(event.target) &&
  //     !this.projectSelect.nativeElement?.contains(event.target)
  //   ) {
  //     if (this.projectId) {
  //       this.showErrorMessage = false;
  //     }
  //   }
  // }

  searchTimesheet() {
    // if (!this.selectedEmployeeSearch) {
    //   this.selectedemployee = '';
    // }
    this.changefirstpage();

    this.nodata = true;
    // this.nodata = false;
    this.timesheetreport.length = 0;

    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');
    console.log('projectId' + this.projectId);
    this.checkStartDate();

    if (this.startDate != undefined && this.endDate == undefined) {
      this.startdate_required = false;
      this.enddate_required = true;
      return;
    }

    if (this.startDate == undefined && this.endDate != undefined) {
      this.startdate_required = true;
      this.enddate_required = false;
      return;
    }


    // if(this.projectId == undefined || this.projectId == "undefined"){
    //   this.showErrorMessage =true;
    //   console.log(" this.showErrorMessage : " +  this.showErrorMessage)
    //   return
    // }

    // if (this.projectId == 0 || this.projectId == undefined) {
    //   // this.employee_required = true;
    //   this.project_required = true;
    //   return;
    // }else{
    //   this.project_required = false;
    // }

    // this.selectedemployeeId != undefined
    //   ? (this.startdate_required = true)
    //   : (this.startdate_required = false);
    // this.startDate != undefined
    //   ? (this.enddate_required = true )
    //   : (this.enddate_required = false);

    // if (this.endDate != undefined) {
    //   this.enddate_required = false;
    //   return;
    // }

    // if (this.startDate == undefined) {
    //   this.startdate_required = true;

    // } else if (this.endDate == undefined) {
    //   this.enddate_required = true;
    //   return;
    // }
    // if (this.startDate && this.endDate && this.startDate > this.endDate) {
    //   this.startDate = undefined;
    //   this.endDate = undefined;
    //   this.startdate_required = true;
    //   this.enddate_required = true;
    //   alert('Start date cannot be greater than end date.');
    // }

    if (
      (this.projectId == 0 || this.projectId == undefined) &&
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.selectedemployeeId == undefined
    ) {
      //     this.project_required =true;
      alert('Please Choose any of the Element to Search');
      return;
    }
    console.log("project::", this.projectId);
    console.log("employee::", this.selectedemployeeId);
    console.log("this.startDate::", this.startDate);
    console.log("this.endDate::", this.endDate);

    this.employee_required = false;
    if (this.selectedemployeeId == undefined && this.projectId != undefined) {
      if (this.startDate == undefined && this.endDate == undefined) {
        this.loader = true;
        this.searchButtonDisable = true;
        this.tableShow = false;
        if (!this.employee) {
          this.selectedemployee = undefined;
        }
        console.log('check emp', this.selectedemployee);

        if (this.selectedemployee == undefined) {
          this.selectedemployee = undefined;
          this.employeeProjects = [];
        }
        this.gs.getTaskDetailByProjectId(this.projectId).subscribe(
          (data: any) => {
            this.timesheetreport = [];
            this.searchButtonDisable = false;
            // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
            // if (data.errorCode === 204) {
            //   this.nodata = true;
            //   this.loader = false;
            //   this.searchButtonDisable=false;
            // }
            if (data.message === 'No Data') {
              console.log("nodata ");

              this.nodata = true;
              this.loader = false;
              this.searchButtonDisable = false;
            }
            if (data?.statusCode === 204) {
              this.nodata = true;
              this.searchButtonDisable = false;
              this.timesheetreport = [];
            }

            console.log('data', data);
            this.isproject = true;
            if (Array.isArray(data)) {
              this.timesheetreport = data;
            }


            this.tableShow = true;
            this.employeeNotFound = false;
            this.nodata = false;
            this.searchButtonDisable = false;
            this.loader = false;
            // console.log('this.timesheetreport',  this.timesheetreport);
            // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
            // this.nodata = false;
            this.tasktotalTime = this.timesheetreport[0]?.totalTime;
          },
          (err) => {
            console.log('err', err);

            this.showErrorMessage = true;

            this.tableShow = true;
            if (err.statusCode === 204) {
              this.nodata = true;
              this.searchButtonDisable = false;
            }
            if (err.error.errorCode === 204) {
              this.nodata = true;
              this.searchButtonDisable = false;
            }
            if (err.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.searchButtonDisable = false;
            }
            this.loader = false;
          }
        );
      } else {
        // if (this.startDate == undefined ) {
        //   if (this.startDate == undefined) {
        //     this.startdate_required = true;

        //   } else if ( this.startDate !== undefined && this.endDate == undefined) {
        //     this.enddate_required = true;
        //   // }
        //   return;
        // }




        console.log(this.startDate);
        this.enddate_required = false;
        this.startdate_required = false;
        this.loader = true;
        this.searchButtonDisable = true;
        this.tableShow = false;
        this.gs
          .getTaskDetailByProjectIdDate(
            this.projectId,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              this.timesheetreport = [];
              this.searchButtonDisable = false;
              this.nodata = false
              this.loader = false
              this.employeeNotFound = false;
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.message === 'No Data') {
                console.log("nodata ");

                this.nodata = true;
                this.loader = false;
                this.searchButtonDisable = false;
              }

              console.log('data', data);
              this.isproject = true;
              // this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.tableShow = true;
              this.searchButtonDisable = false;
              this.loader = false;
              // console.log('this.timesheetreport',  this.timesheetreport);
              // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
              // this.nodata = false;
              this.tasktotalTime = this.timesheetreport[0]?.totalTime;
            },
            (err) => {
              this.showErrorMessage = false;
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);
              // if (err.error.errorCode === 204) {
              //   this.nodata = true;
              //   this.loader = false;
              //   this.searchButtonDisable=false;
              // }


              // if (err.errorCode === 204) {
              //   this.nodata = true;
              //   this.loader = false;
              //   this.searchButtonDisable=false;
              // }
              this.loader = false;
            }
          );
      }
    } else if (
      this.selectedemployeeId == undefined &&
      this.projectId == undefined &&
      this.startDate != undefined &&
      this.endDate != undefined
    ) {
      this.loader = true;
      this.searchButtonDisable = true;
      this.tableShow = false;
      this.gs.getTaskDetailByDate(this.startDate, this.endDate).subscribe(
        (data: any) => {
          console.log('data', data);

          this.timesheetreport = [];
          this.searchButtonDisable = false;
          this.employeeNotFound = false;
          // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
          // if (data.errorCode === 204) {
          //   this.nodata = true;
          //   this.loader = false;
          //   this.searchButtonDisable=false;
          // }
          if (data.message === 'No Data') {
            console.log("nodata ");

            this.nodata = true;
            this.loader = false;
            this.searchButtonDisable = false;
          }

          console.log('data', data);
          this.isproject = true;
          // this.timesheetreport = data;
          this.timesheetreport = Array.isArray(data) ? data : [];
          this.tableShow = true;
          this.searchButtonDisable = false;
          this.loader = false;
          this.nodata = false;

          if (data.statusCode === 204) {

            this.nodata = true;
            this.loader = false;
            this.searchButtonDisable = false;
          }

          // console.log('this.timesheetreport',  this.timesheetreport);
          // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
          // this.nodata = false;
          this.tasktotalTime = this.timesheetreport[0]?.totalTime;
        },
        (err) => {
          this.showErrorMessage = true;
          this.tableShow = true;
          this.timesheetreport = [];
          if (err.error?.errorCode === 204 || err.errorCode === 204) {
            this.nodata = true;
          }
          this.loader = false;
          this.searchButtonDisable = false;
        }
      );
    } else if (
      this.selectedemployeeId != undefined &&
      this.projectId == undefined &&
      this.startDate != undefined &&
      this.endDate != undefined
    ) {
      // if (this.selectedemployeeId ) {
      //   this.selectedemployee = this.employee;
      // }
      this.loader = true;
      this.searchButtonDisable = true;
      this.tableShow = false;
      this.gs
        .getTimesheetReportByEmployeeIdAndStartDateAndEndDate(
          this.selectedemployeeId,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data: any) => {
            this.timesheetreport = [];
            this.searchButtonDisable = false;
            this.employeeNotFound = false;
            // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
            // if (data.errorCode === 204) {
            //   this.nodata = true;
            //   this.loader = false;
            //   this.searchButtonDisable=false;
            // }
            if (data.message === 'No Data') {
              console.log("nodata ");

              this.nodata = true;
              this.loader = false;
              this.searchButtonDisable = false;
            }

            console.log('data', data);
            this.isproject = true;
            // this.timesheetreport = data;
            this.timesheetreport = Array.isArray(data) ? data : [];
            this.tableShow = true;
            this.searchButtonDisable = false;
            this.loader = false;
            // console.log('this.timesheetreport',  this.timesheetreport);
            // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
            // this.nodata = false;
            this.tasktotalTime = this.timesheetreport[0]?.totalTime;
          },
          (err) => {
            this.tableShow = true;
            this.showErrorMessage = true;
            // this.timesheetreport.pop();
            // this.timesheetreport.splice(0, this.timesheetreport.length);
            if (err.error.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.searchButtonDisable = false;
            }
            if (err.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.searchButtonDisable = false;
            }
            this.loader = false;
          }
        );
    } else {
      if (
        this.startDate == undefined &&
        this.endDate == undefined &&
        this.selectedemployeeId != undefined &&
        this.projectId != undefined
      ) {
        // if (this.selectedemployeeId ) {
        //   this.selectedemployee = this.employee;
        // }
        this.loader = true;
        this.searchButtonDisable = true;
        this.tableShow = false;
        this.gs
          .getTimesheetReportByEmployeeIdAndProjectId(
            this.projectId,
            this.selectedemployeeId
          )
          .subscribe(
            (data: any) => {
              this.timesheetreport = [];
              this.searchButtonDisable = false;
              this.employeeNotFound = false;
              this.nodata = false;
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              // if (data.errorCode === 204) {
              //   this.nodata = true;
              //   this.loader = false;
              //   this.searchButtonDisable=false;
              // }

              if (data.message === 'No Data') {
                console.log("nodata ");

                this.nodata = true;
                this.loader = false;
                this.searchButtonDisable = false;
              }
              console.log('data', data);
              this.isproject = true;
              this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.tableShow = true;
              this.searchButtonDisable = false;
              this.loader = false;
              // console.log('this.timesheetreport',  this.timesheetreport);
              // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
              // this.nodata = false;
              this.tasktotalTime = this.timesheetreport[0]?.totalTime;
            },
            (err) => {
              this.showErrorMessage = true;
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);

              console.log('err', err);

              // if (err.errorCode === 204) {
              //   this.nodata = true;
              //   this.loader = false;
              //   this.searchButtonDisable=false;
              // }

              // if (err.error.errorCode === 204) {
              //   this.nodata = true;
              //   this.loader = false;
              // }

              this.loader = false;
            }
          );
      } else {
        if (this.startDate == undefined || this.endDate == undefined) {
          if (this.startDate == undefined) {
            this.startdate_required = true;
          } else if (this.endDate == undefined) {
            this.enddate_required = true;
          }
          return;
        }

        this.loader = true;
        this.enddate_required = false;
        this.startdate_required = false;
        this.searchButtonDisable = true;
        // if (this.selectedemployeeId ) {
        //   this.selectedemployee = this.employee;
        // }
        this.tableShow = false;
        this.gs
          .getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
            this.selectedemployeeId,
            this.projectId,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              this.timesheetreport = [];
              this.searchButtonDisable = false;
              this.employeeNotFound = false;
              this.nodata = false;
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.message === 'No Data') {
                console.log("nodata ");

                this.nodata = true;
                this.loader = false;
                this.searchButtonDisable = false;
              }
              console.log('data', data);
              this.isproject = true;
              this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.tableShow = true;
              this.searchButtonDisable = false;
              this.loader = false;
              // console.log('this.timesheetreport',  this.timesheetreport);
              // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
              // this.nodata = false;
              this.tasktotalTime = this.timesheetreport[0]?.totalTime;
            },
            (err) => {

              console.log();
              this.tableShow = true;
              this.timesheetreport.length = 0;
              this.showErrorMessage = true;
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);
              if (err.error.errorCode == 204) {
                this.nodata = true;
                this.loader = false;
                this.searchButtonDisable = false;
              }
              if (err.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.searchButtonDisable = false;
              }

              this.loader = false;
            }
          );
      }
    }
  }

  datechange(event: any) {
    console.log(event);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return;
    }
    if (i.task.taskStatusName == 'To Do') {
      return '#ffc107';
    }
    if (i.task.taskStatusName == 'Completed') {
      return '#28a745';
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#f44336';
    }
  }

  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == 'Pending') {
      return '#ffc107';
    }
    if (i.approvalStatusName == 'Approved') {
      return '#28a745';
    }
    if (i.approvalStatusName == 'Denied') {
      return '#f44336';
    }
  }

  description(e) {
    console.log(e.description);
    const dialogRef = this.dialog.open(ViewdescripeComponent, {
      width: '500px',
      data: e,
    });
  }

  sortData(sort: Sort) {
    const data = this.timesheetreport.slice();
    if (!sort.active || sort.direction === '') {
      this.timesheetreport = data;
      return;
    }

    this.timesheetreport = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employeeName':
          return this.compare(
            a.employee.firstName,
            b.employee.firstName,
            isAsc
          );
        case 'projectName':
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );
        case 'taskName':
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case 'taskCode':
          return this.compare(a.task.taskCode, b.task.taskCode, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'workedHours':
          return this.compare(a.workedHours, b.workedHours, isAsc);

        case 'estimatedHours':
          return this.compare(a.estimatedHours, b.estimatedHours, isAsc);
        case 'taskStatusName':
          return this.compare(
            a.task.taskStatusName,
            b.task.taskStatusName,
            isAsc
          );
        case 'approvalStatusName':
          return this.compare(
            a.approvalStatusName,
            b.approvalStatusName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const input = document.getElementById('startDate') as HTMLInputElement;
      // input.classList.remove('select-placeholder');
    } else {
      const input = document.getElementById('startDate') as HTMLInputElement;
      // input.classList.add('black-placeholder');
    }

    if (this.startDate != undefined) {
      this.startdate_required = false;
      // this.enddate_required = true;
      return;
    }

    if (this.startDate != undefined && this.endDate == undefined) {
      this.enddate_required = true;
      return;
    }

    // if (this.startDate != undefined ) {
    //   this.enddate_required = false;
    //   return;

    // }

    if (this.endSelectedFirst && this.startDate > this.endDate) {
      // If end date was selected first and start date is greater than end date
      // Set start date to be one day before end date
      this.startDate = new Date(this.endDate);
      this.startDate.setDate(this.endDate.getDate() - 1);
    }
  }

  checkStartDate(): void {
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      alert('Start date cannot be greater than end date.');
      this.startDate = '';
      this.endDate = '';
      this.startDate = undefined;
      this.endDate = undefined;
      this.startDate = '';
      this.endDate = '';
    }
  }

  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML);
    
  
    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    console.log(pdfTable.innerHTML);
    
  
    const documentDefinition = {
      content: html,
      pageMargins: [25, 40, 25, 40],
      info: {
        title: `MOM Report`
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);
  
        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download("MOM Report");
  }
}
