import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { PmsService } from 'src/app/providers/pms.service';
import { Router } from '@angular/router';
// import { ViewAppraisalComponent } from '../view-appraisal/view-appraisal.component';



@Component({
  selector: 'app-appraisalviewdialog',
  templateUrl: './appraisalviewdialog.component.html',
  styleUrls: ['./appraisalviewdialog.component.scss']
 
})
export class AppraisalviewdialogComponent implements OnInit {

appraisalDialogForm: FormGroup;
  usertData: any;
  orgId: any;
  localempId: any;
  position: any;
  department: any;
  crateDate: any;
  LastRevieDate: any;
  appList: any;
  appHeaderId: any;
  employeeComments: string ='';

  submitButtonDisable :boolean=false;
  loader: boolean=false;
  matDialog: any;
  submitted: boolean = false;
 

  // comments: any;
  // employeecomments:any[]=[];
  

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,
    private pmsService: PmsService,
    private router: Router,

    public dialogRef:MatDialogRef<AppraisalviewdialogComponent>,
    @Inject(MAT_DIALOG_DATA)public data:any
  
    // private viewcomponent : ViewAppraisalComponent
    
   
  ) { console.log('this.data',this.data);}

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;

    this.orgId = this.usertData.organization.organizationId;
    console.log("this.orgId:",this.orgId);
    
    this.localempId= this.usertData.employee.employeeId;
    console.log("empId:",this.localempId);
    
     this.position = this.usertData?.employee?.designation?.designationName;
    console.log("this.orgId:",this.position);
   
    this.department = this.usertData?.employee?.department?.departmentName;
    console.log("this.orgId:",this.department);
    // console.log(this.appList);

    this.appraisalDialogForm = this.formBuilder.group({
      submit: ['',Validators.required] ,

      acceptanceComments: [''],
      apprisalHeaderId: [''],
      reviewerTitle: [''],
      totalHoursWorking: [''],
      employeeId: [''],
      todayDate:[''],
      overAllRatting: [''],
      managementTotalRating: [''],
      leaveDays: [''],
      employeeAcceptanceDate: [''],
      employeeQuery: [''],
      employeeReviewRating: [''],
      managementRating: [''],
      managementComments: [''],
      managementReviseComments: [''],
      managementReviseRating: [''],
      ratingRequired: [''],
      closedDate: [''],
      organizationId:[''],
      createdBy: [''],
      lastUpdatedBy: [''],
      listTypeValueId: [''],
      listTypeValueName: [''],
      acceptance: [''],
      apprisalLineId:[''],
      criteriaDescription: [''],
      employeeRating:[''],
      weightage: [''],
      rating: [''],
      approverComments: [''],
      
      managementCalculateRating: [''],
      employeeComments: [''],
      kraTemplateHeaderLineId: [''],

      
      
    });
 }



submitForm() {

  console.log(this.appraisalDialogForm.value);
  this.submitted = true;


  

  if (this.appraisalDialogForm.invalid ) {
   
    this.appraisalDialogForm.markAllAsTouched();
    console.log("jkjkjk");
    return; 
  }

  // delete this.data.applist.employeeComments;
  // console.log("deleted",this.data.applist);
  
  const selectedValue = this.appraisalDialogForm.get('submit').value;
  // this.data.ecomment.forEach((element,index) => {
 
  const payload = {
    acceptanceComments: this.data.acceptanceComments,
    employeeId: this.data.employee.employeeId,
    apprisalHeader: {
      apprisalHeaderId: this.data.apprisalHeaderId,
      financialPeriodId:this.data.financialPeriodId,
      financialYearId:this.data.financialYearId,
      employeeId: this.data.employee.employeeId,
      reviewerTitle: this.data.reviewerTitle,
      todayDate: this.data.todayDate,
      overAllRatting: this.data.overAllRatting,
      managementTotalRating: this.data.managementTotalRating,
      totalHoursWorking: this.data.totalHoursWorking,
      leaveDays: this.data.leaveDays,
      employeeAcceptanceDate: selectedValue === 'yes' ? this.data.todayDate : null,
      employeeQuery: this.data.employeeQuery,
      employeeReviewRating: this.data.employeeReviewRating,
      managementRating: this.data.managementRating,
      managementComments: this.data.managementComments,
      managementReviseComments: this.data.managementReviseComments,
      managementReviseRating: this.data.managementReviseRating,
      ratingRequired: this.data.ratingRequired,
      closedDate: this.data.closedDate,
      organizationId: this.data.organizationId,
      createdBy: this.data.employee.employeeId,
      lastUpdatedBy: this.data.employee.employeeId,
      // employeeComments: element[index],
      apprisalLine: this.data.apprisalLine.map((apprisalLineItem , i)=> ({

      
        ...apprisalLineItem,
        apprisalLineId: apprisalLineItem.apprisalLineId,
        criteriaDescription: apprisalLineItem.criteriaDescription,
        employeeRating: apprisalLineItem.employeeRating,
        weightage: apprisalLineItem.weightage,
        rating: apprisalLineItem.rating,
        approverComments: apprisalLineItem.approverComments,
        managementRating: apprisalLineItem.managementRating,
        managementCalculateRating: apprisalLineItem.managementCalculateRating,
        employeeComments:apprisalLineItem.employeeComments ,
        kratemplateLine: {
          kraTemplateHeaderLineId: apprisalLineItem.kratemplateLine.kraTemplateHeaderLineId
        }
      })),
      status: {
        listTypeValueId: 1,
        listTypeValueName: 'Active'
      },
      apprisalStatus: {
        listTypeValueId: 8,
        listTypeValueName: 'Closed'
      }
    },
    acceptance: selectedValue === 'yes' ? true : false,
    createdBy: this.data.employee.employeeId,
    lastUpdatedBy: this.data.employee.employeeId,
  }
  console.log('payload', payload);
  
 
  
  this.loader=true;


  this.pmsService.createViewAppraisalDialog(payload)
    .subscribe(response => {
      console.log('API response:', response);
      // this.loader=false;
      this.submitButtonDisable= false;
      this.dialogRef.close();
      this.router.navigate(['/home/appraisal']);
    }, error => {
      console.error('API error:', error);
      this.submitButtonDisable= false;
      // this.loader=false;
    });
}

cancel(){

    this.dialogRef.close();
    console.log("cancel");
    
    
  }

// cancel() {
//     this.dialog.closeAll();
//         if( this.appraisalDialogForm.invalid){
//           this.submitted = false; 
//           this.appraisalDialogForm.reset();
//         }
//         this.appraisalDialogForm.reset();
//         this.appraisalDialogForm.get('file').reset();
    
//         this.submitted = false; // Reset the submitted flag to false
//         // this.postForm.reset(); // Optionally reset the form if needed
//         // this.dialogref.close(); // Close the dialog
//     }

  

  
}
