  import { Component, OnInit ,Inject,ElementRef,ViewChild} from '@angular/core';
  import { Sort } from '@angular/material/sort';
  import { AppComponent } from '../../app.component';
  import { Router } from '@angular/router';
  import { SettingsService } from '../../providers/settings.service';
  import { PmsService } from '../../providers/pms.service'
  import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
  import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
  import { UserService } from '../../providers/user.service';
import { EMPTY, Subject, catchError, switchMap, takeUntil, throwError } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;




  @Component({
    selector: 'app-admin-reports',
    templateUrl: './admin-reports.component.html',
    styleUrls: ['./admin-reports.component.scss']
  })
  export class AdminReportsComponent implements OnInit {
    nodatafound: boolean=true;
    usertData: any;
    orgId: any;
    userTypeId: any;
    searchForm!:FormGroup;
    kralistData: any;
    sortedData: any[];
    filterContent:any;
    p: number = 1;
    public searchFilter: any = '';
    query: any = '';
    beforepage :any;
    mySelect:any =5;
    list_loader = true;
    nodata = false;
    success = false;
    error:any=false;
    sucess_msg:any;
    error_msg:any;
    exportingdata:any;
    exported:any;
    loader:any= false;
    backdrop:any= false;
    resultData : any;
    pagePerItem:any=5;
    chooseFinId: any;
    tableSearch : any;
    autoCompleteSearch: number;
    employeetData: any;
    employeeData: any;
    empolyeereadonly :boolean = true;
    finPeriodList: any=[];
    submitted: any=false;
    finYearList: any=[];
    month: any;
    empList: any;
    employee: any;
    employeeId: any;

    errorMessage: string = '';
    unsubscribe$: any;
    employeeNotFound: boolean;

    employeeCode: string;
    employeeNumber: number;
    selectedemployeeId: any;
    employeeIds: any;
    employeeCodes: string;
    selectedemployee: any;
    employeeSelected: boolean;
    showTable: boolean=false;
    
    monthID:any;
    dataForExcel = [];
    headerForExcel = [
      { 'Employee Code': '', 'Employee Name': '',  'KRA Status': '', 'Appraisal Status': ''}
    ];

 @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
    constructor(
      private router:Router,
      private pmsService:PmsService,
      private dialog:MatDialog,
      private fb:FormBuilder,
      private userservice: UserService,
      private settingsService:SettingsService,
      // public ExportExcelService: ExportExcelService,
    ) { }

    ngOnInit(): void {
      this.nodatafound = false;
      this.resultData = [];
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      let userId = this.usertData.userId;
      this.orgId = this.usertData.organization.organizationId;
      // this.userTypeId = this.usertData.userType.userTypeId;
      // console.log(this.userTypeId);
      this.searchForm=this.fb.group({
        employeeCode:[null],
        month:[null],
        year:[null]
        // toyear:[null]
      })

      this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
        console.log(resultData);
        resultData.forEach((item: any) => {
          // console.log(item.financialYearStatus.listTypeValueId)
          // console.log(item.financialYearStatus.listTypeValueId == 1)
          if(item.financialYearStatus.listTypeValueId == 1)
          {
            console.log(item.financialYearStatus.listTypeValueId)
            this.finYearList.push(item);
            console.log(' this.finYearList', this.finYearList)
          }
        }); 
    });
    }

    isFormValid() {
      return this.searchForm.get('employeeCode').value !==null || this.searchForm.get('year').value || this.searchForm.get('month').value ;
    }
  
    

    selectFinancialyear(event: MatSelectChange)
    {
      console.log("Select");
      console.log(event.value);
      // console.log(this.filterForm.value.financialyear);
      this.chooseFinId=event.value;
      console.log(this.chooseFinId);
      this.searchForm.patchValue({
        
        month: undefined,
      });
      
      if(this.chooseFinId == null || this.chooseFinId == 'null')
      {
        console.log('if');
        this.finPeriodList = [];
        
        // this.submitted=true;
      }
      else
      {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData);
        this.submitted=false;
        this.finPeriodList = resultData.finanicalPeriod;
        console.log(this.finPeriodList);
        
        this.searchForm.patchValue({
          fromperiod: null,
          toperiod: null,
        });
      });
    }
    }

    
  


    searchSubmit() {
      this.p=1;
      this.employeeNotFound=false;
      this.resultData = [];
      this.nodatafound = false;
      this.loader = true; 
      this.showTable=false;
      console.log("Search", this.searchForm.value);
      
      let empCode = this.searchForm.value.employeeCode ? this.searchForm.value.employeeCode : null;
      console.log(empCode);
      let year = this.searchForm.value.year ? this.searchForm.value.year : null;
      console.log(year);
      let month = this.searchForm.value.month ? this.searchForm.value.month : null;
      console.log(month);

      if(!this.employeeId){
        this.searchForm.controls['employeeCode'].reset();
        this.employeeId=undefined;
        this.employeeNotFound=false;
        console.log("type")
        
      }
      
      if (month) {
        this.finPeriodList.forEach(element => {
          if (element.financialPeriodId == month) {
            this.month = element.financialperiodMonth + "-" + element.financialPeriodYear;
            console.log("month", this.month);
          }
        });
      }

      this.monthID = this.searchForm.value.month 
      console.log("month id",this.monthID );
      
      this.submitted = true;
      
      
      if (!this.searchForm.value.employeeCode && !this.searchForm.value.month && !this.searchForm.value.year) {
        alert('Please search valid Employee');
        this.loader = false; 
        this.showTable=false;
        this.employeeNotFound=false;
        if (this.unsubscribe$) {
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        }
        return;
      }
    
      if (this.searchForm.value.month && !this.searchForm.value.year) {
        alert('Please select a year along with the month and employee code.');
        this.loader = false;
        this.showTable=true;
        return; 
      }
    
      if (this.searchForm.value.employeeCode != null && this.searchForm.value.month != null && this.searchForm.value.year != null) {
        this.searchForm.patchValue({
          employeeCode: this.employeeCodes
      });
        console.log('All fields');
        this.pmsService.getReportList(this.employeeId, this.chooseFinId, this.monthID).subscribe((resultData: any) => {
          this.loader = false;
          this.showTable=true;
          this.submitted = false;
          if (resultData.statusCode == 204 || !resultData.length) {
            this.nodatafound = true;
            this.resultData = [];
          } else {
            this.nodatafound = false;
            this.resultData = resultData;
          }
          console.log(this.resultData);
        }, error => {
          this.loader = false;
          this.showTable=true;
          console.error('Error fetching data:', error);
        });
      }
    
      if (this.searchForm.value.employeeCode != null && this.searchForm.value.month == null && this.searchForm.value.year == null) {
        this.searchForm.patchValue({
          employeeCode: this.employeeCodes
      });
        console.log('Only employee');
        this.pmsService.getReportListEmp(this.employeeId).subscribe((resultData: any) => {
          this.loader = false; 
          this.showTable=true;
          this.submitted = false;
          if (resultData.statusCode == 204 || !resultData.length) {
            this.nodatafound = true;
            this.resultData = [];
          } else {
            this.nodatafound = false;
            this.resultData = resultData;
          }
          console.log(this.resultData);
        }, error => {
          this.loader = false; 
          this.showTable=true;
          console.error('Error fetching data:', error);
        });
      }
    
      if (this.searchForm.value.employeeCode != null && this.searchForm.value.month == null && this.searchForm.value.year != null) {
        this.searchForm.patchValue({
          employeeCode: this.employeeCodes
      });
        console.log('Employee and Year');
        this.pmsService.getReportListEmpAndYear(this.employeeId, this.chooseFinId).subscribe((resultData: any) => {
          this.loader = false;
          this.showTable=true;
          this.submitted = false;
          if (resultData.statusCode == 204 || !resultData.length) {
            this.nodatafound = true;
            this.resultData = [];
          } else {
            this.nodatafound = false;
            this.resultData = resultData;
          }
          console.log(this.resultData);
        }, error => {
          this.loader = false; 
          this.showTable=true;
          console.error('Error fetching data:', error);
        });
      }
    
      if (this.searchForm.value.employeeCode == null && this.searchForm.value.month != null && this.searchForm.value.year != null) {
      
        console.log('Year and Month');
        this.pmsService.getReportListYearAndMonth(this.chooseFinId, this.monthID).subscribe((resultData: any) => {
          this.loader = false; 
          this.showTable=true;
          this.submitted = false;
          if (resultData.statusCode == 204 || !resultData.length) {
            this.nodatafound = true;
            this.resultData = [];
          } else {
            this.nodatafound = false;
            this.resultData = resultData;
          }
          console.log(this.resultData);
        }, error => {
          this.loader = false; // Stop loader on error
          this.showTable=true;
          console.error('Error fetching data:', error);
        });
      }
    
      if (this.searchForm.value.employeeCode == null && this.searchForm.value.month == null && this.searchForm.value.year != null) {
        

        console.log('Only Year');
        this.pmsService.getReportListYear(this.chooseFinId).subscribe((resultData: any) => {
          this.loader = false; 
          this.showTable=true;
          this.submitted = false;
          if (resultData.statusCode == 204 || !resultData.length) {
            this.nodatafound = true;
            this.resultData = [];
            
          } else {
            this.nodatafound = false;
            this.resultData = resultData;
            
          }
          console.log(this.resultData);
        }, error => {
          this.loader = false; 
          this.showTable=true;
          console.error('Error fetching data:', error);
        });
      }
    }

    
    
    

   
    clear()
    {
      this.searchForm.reset();
      this.empList=[]
      this.resultData = [];
      this.nodatafound = true;
      console.log('clear');
      this.searchForm.clearValidators;
      // this.nodatafound = true;
      // this.getFilterFormData(this.orgId);
      // this.getAssignkraList(this.orgId);
      this.showTable=false;
      this.employeeNotFound=false;
      this.finPeriodList=[];
      this.month=[];
      this.monthID=[];
      this.employeeId=undefined;
   
      
    }

    getEmpCodeList(event)
    {
      console.log(event);
      let keywords = event.target.value;
      console.log(keywords);
      //let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
      if(keywords)
      {
        this.autoCompleteSearch = 0;
        this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
        let organizationId = this.employeetData.organization.organizationId;
        this.userservice.employeeSearch(organizationId,keywords).subscribe((result: any) =>
        {
          this.employeeData = result;
          console.log("employeeData",this.employeeData);
          if(this.employeeData.statusCode == 204)
          {
            this.autoCompleteSearch = 1; //No data Found
            this.searchForm.patchValue({
              //employeeName : firstName+" "+lastName,
              employeeId : ""
            });
          }
          else
          {
            this.autoCompleteSearch = 0;
          }
        })
      }
      else
      {
        this.autoCompleteSearch = 1;
        this.searchForm.patchValue({
          //employeeName : firstName+" "+lastName,
          employeeId : ""
        });
      }
    }


  
  

    empSearch(event, event1: KeyboardEvent): void {
      if (event1.key === ' ') {
        this.employeeNotFound = false;
        // Handle the space key press
        console.log('Space key pressed');
        // Optionally, prevent the default behavior
        event1.preventDefault();
      } else {
        if (this.empList?.length > 0) {
          this.nodatafound = false;
        }
    
        this.empList = [];
        console.log(event.target.value);
        let employeeNumber = event.target.value;
        employeeNumber = employeeNumber.replace(/-.*/, '');
        console.log(employeeNumber);
    
        // Cancel the previous API request
        if (this.unsubscribe$) {
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        }
        this.unsubscribe$ = new Subject<void>();
    
        if (
          this.employeeId === 0 ||
          this.employeeId === undefined ||
          this.employeeId === null
        ) {
          console.log('employee key', employeeNumber);
    
          if (employeeNumber && employeeNumber.length >= 2) {
            this.userservice
              .employeeSearch(this.orgId, employeeNumber)
              .pipe(
                takeUntil(this.unsubscribe$), // Cancel previous request
                switchMap(() => this.userservice.employeeSearch(this.orgId, employeeNumber)),
                catchError((err) => {
                  if (err.error.status === 500) {
                    // this.projectId = [];
                    this.empList = [];
                    this.employeeNotFound = true;
                  }
                  console.error('Error fetching employees:', err);
                  // Handle the error here, for example, showing an alert
                  // alert('Error fetching employees. Please try again later.');
                  return throwError(err); // Rethrow the error to propagate it downstream
                })
              )
              .subscribe((result: any) => {
                console.log('result', result);
    
                if (result && result.statusCode === 204) {
                  this.employeeNotFound = true;
                }
                this.empList.length = 0;
    
                if (result && Array.isArray(result)) {
                  result.forEach((element) => {
                    let employee = {
                      employee: {
                        employeeId: element.employeeId,
                        firstName: element.firstName,
                        employeeNumber: element.employeeNumber,
                      },
                    };
    
                    if (Object.keys(employee).length > 0) {
                      this.employeeNotFound = false;
                    } else {
                      this.employeeNotFound = true;
                    }
                    this.empList.push(employee);
                    console.log(this.empList);
                  });
    
                  if (this.selectedemployeeId?.length >= 1) {
                    this.employeeSelected = true;
                  }
                }
    
                if (result && result.errorCode === 204) {
                  // alert('No Employee Found');
                  this.employeeNotFound = true;
                  this.empList.length = 0;
                  this.empList.push('No Employee Found');
                  this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
                }
              });
          }
        } else {
          // Set selectedemployeeId to null if the input is empty
          this.selectedemployeeId = null;
        }
      }
    }

    handleBackspace(event): void {
      if (
        event.key === 'Backspace' &&
        !this.selectedemployee &&
        (this.selectedemployeeId === undefined || this.selectedemployeeId === null || this.selectedemployeeId.trim().length === 0)
      ) {
        this.selectedemployeeId = undefined;
        this.employeeId = undefined;
        this.empList.length = 0;
        this.employee = undefined;
        this.employeeNumber = undefined;
        this.selectedemployee = undefined;
        this.selectedemployeeId = undefined;
        this.employeeNotFound = false;
      }
    }

    noEmployeeFound() {
      this.selectedemployeeId = undefined;
      this.selectedemployeeId = undefined; // Set selectedemployeeId to null
      this.employeeId = undefined;
      this.empList.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.selectedemployeeId = undefined;
      this.employeeNotFound = false;
    }
    

   

    empSelect(emp) {
      console.log('emp',emp);
      this.employeeId= emp.employee.employeeId;
      this.employeeCodes = emp.employee.employeeNumber + '-' + emp.employee.firstName;
      // this.employeeCode = this.employeeStoring;
      console.log(this.employeeCodes);
      // console.log(this.employeeStoring);
      this.employeeNumber = emp.employee.employeeNumber;
      // this.selectedemployee = this.employee;
      this.selectedemployeeId=this.employee;
  
      this.searchForm.patchValue({
        employeeCode: this.employeeCodes
      });
  console.log("employee Id after selecting::",this.employeeId);
  
      console.log('this.employee::',this.selectedemployeeId);
      console.log("wtts id tell me?::",this.employeeId);
      // this.getTask()
  }

   

    ucFirst(string: string): string {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return ''; // Or handle null/undefined case as per your requirement
      }
    }
    
    
    sortData(sort:Sort) 
    {
      // const data = this.resultData.slice();
      const data = this.kralistData;
      console.log("Data",data);
      // const data = this.resultData;
      if (!sort.active || sort.direction === '') 
      {
        this.sortedData = data;
        return;
      }
      this.sortedData = data.sort((a, b) => 
      {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) 
        {
          case 'templateCode':
            return compare(a.templateCode, b.templateCode, isAsc);
          case 'templateName':
            return compare(a.templateName, b.templateName, isAsc);
          // case 'mobileNumber':
          //   return compare(a.mobileNumber, b.mobileNumber, isAsc);
          default:
            return 0;
        }
      });
    }

    get validate() 
    { 
      return this.searchForm.controls; 
    }

    onItemsPerPageChange(){
      this.p=1;
    }

 
    validateInput(event: Event) {
      const input = event.target as HTMLInputElement;
      let value = input.value;
    
      // Remove leading spaces
      if (value.startsWith(' ')) {
        value = value.trimStart();
      }
    
      // Remove spaces in between
      value = value.replace(/\s+/g, '');
    
      input.value = value;
    }


    preventSpaceBeforeInput(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
          event.preventDefault();
      }
    }

    printPdf() {
      const pdfTable = this.pdfTable.nativeElement;
      console.log(pdfTable.innerHTML);
      

      var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
      console.log(pdfTable.innerHTML);
      
  
      const documentDefinition = {
        content: html,
        pageMargins: [25, 40, 25, 40],
        info: {
          title: `Assignment Report`
        },
        background: function (currentPage, pageCount) {
          console.log('currentPage:', currentPage);
          console.log('pageCount:', pageCount);
  
          return {
            text: 'Page ' + currentPage.toString(),
            alignment: 'center',
            margin: [0, 8],
            fontSize: 10,
          };
        },
        pageSize: 'A4',
        pageOrientation: 'landscape'
      };
      console.log(pdfTable);
      pdfMake.createPdf(documentDefinition).download("Assignment Report");
    }
  }

  // const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // const EXCEL_EXTENSION = '.xlsx';






  function compare(a: number | string, b: number | string, isAsc: boolean) 
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

