import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecruitmentService } from '../providers/recruitment.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { Router } from '@angular/router';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';

@Component({
  selector: 'app-org-tax-breakup',
  templateUrl: './org-tax-breakup.component.html',
  styleUrls: ['./org-tax-breakup.component.scss']
})
export class OrgTaxBreakupComponent implements OnInit {

  orgtaxbreakup:FormGroup;
  filterForm:FormGroup;
  clientdata: any={};
  usertData:any;
  dateFormat: any;
  orgId:number;
  tableShow:boolean=false
  noRecordsFound: boolean =false;
  orgList:any[]=[];
  orgName:any
  error:any=false;
  resultData : any = [];
  submitted: boolean = false;
  errormessagejson: any;
  success = false;
  sucess_msg:any;
  error_msg:any;
  p: number = 1;
  tableSearch : any;
  errorMsg : any;
  finYearList: any=[];
  pagePerItem:any=5;
  chooseFinId:any;
  finPeriodList: any=[];
  importerror: boolean = false;
  taxbreakageId:number;
  filterData: any=[];
  chooseFinancialyearId: any;
  financialPeriodList: any[];
  perId: any;
  entityId:any
  staus: any;
  loader:boolean=false;
  storeBreakageStatus:any
  mySelect:number=5;
btndisable: any;
btndisables: any;
organizationLogin:boolean=false;
clientLogin:boolean=false;
clientId:any
clientNames:any
entityAdminLogin:boolean=false;
  constructor( private recruitmentservice :RecruitmentService,
    private fb:FormBuilder,
    private dialog:MatDialog,
    private router:Router,
    private settingsService: SettingsService,
    private payrollservice:PayrollService) { }

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.entityId=this.usertData.entity.entityId;
    this.noRecordsFound = true;
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;
      this.orgName= this.usertData.organization.organizationName;

      console.log('this.organizationLogin', this.organizationLogin);
    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;
     this.clientId = this.usertData?.employee?.client?.clientId;
     this.clientNames=this.usertData?.employee?.client?.clientName;


    } else {
      this.entityAdminLogin = true;

    }
    this.filterForm = this.fb.group({
      filterorganization:[null],
   
    })
    this.orgtaxbreakup= this.fb.group({
      organization:[null],
      salaryStartRange:[null,[Validators.required]],
      salaryEndRange:[null,[Validators.required]],
      taxAmount:[null,[Validators.required]]
    
    })
    if(this.organizationLogin == true){
      this.filterForm?.get('filterorganization')?.patchValue(this.orgId);
      console.log("filterorganization Patched Value::",this.filterForm?.get('filterorganization')?.value)
      this.filterForm?.get('filterorganization')?.disable();
    }
      // console.log('Filterorg Disabled:', this.filterForm);/
      if(this.organizationLogin == true){

      this.orgtaxbreakup?.get('organization')?.patchValue(this.orgId);
      this.orgtaxbreakup?.get('organization')?.disable();
      console.log("organization- Patched Value::",this.filterForm?.get('organization')?.value)

      // console.log('Filterorg Disabled:', this.filterForm);

    }

   

    // this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
    //   this.orgList =  result.filter(client =>client.status.listTypeValueId == 1);;
    //   console.log(this.orgList);
    // })    
    this.payrollservice.getOrganizationNameListBasedEntity( this.entityId).subscribe((result: any) => {
      // this.organizationList =  result.filter(organization =>organization?.status?.listTypeValueId == 1);;
      // console.log(this.organizationList);
      this.orgList = result;
      this.orgList = Array.isArray(result) ? result: [result];

    });  
    
   

  }
  changefirstpage(){
    this.p=1;
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  searchSubmit()
  {
       this.filterData=[];
    console.log("Submit",this.filterForm.value);
      if(this.filterForm.value.filterorganization === null)

    {
      this.btndisables=false

      alert("Please Select Organization to Search!!");
      // this.filterData=[];
      // this.noRecordsFound = true;
      return;
    }

      if(this.filterForm.value.filterorganization !== null)

      {
      this.btndisables=true
      this.loader=true;
      this.tableShow=false
      console.log("org only");
      this.payrollservice.gettaxbreakupbyOrg(this.orgId).subscribe((filterData:any) =>
      {
        this.filterData = Array.isArray(filterData) ? filterData: [];
        this.btndisables=false
        this.loader=false;
        this.tableShow=true
        this.noRecordsFound = false;
        // this.filterData = filterData;
        // console.log(this.filterData);
        if(filterData.statusCode==200){
          this.filterData = filterData;
          this.btndisables=false
          this.loader=false;
          this.tableShow=true
          console.log(this.filterData);
          this.noRecordsFound = false;

          // this.filterData = Array.isArray(filterData) ? filterData: [filterData];
        }
        // if(this.filterData.length == 0 || this.filterData.length == undefined)
        // {
        //   this.noRecordsFound = true;
        // }
       else if(filterData.statusCode==204){
          console.log("no data!");
          this.noRecordsFound = true;
          this.btndisables=false;
          this.tableShow=true;

          this.filterData=[];
        }
      })
    }
    
  }
 
   selectFilterFinancialyear(event)
  {
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinancialyearId=event.target.value;
    console.log(this.chooseFinancialyearId);
    if(this.chooseFinancialyearId == null || this.chooseFinancialyearId == 'null'){
      console.log('if');
      this.financialPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinancialyearId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.financialPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        filterperiod: null,
      });
    })
  }
  }
  //  onSubmit()
  //  {
  //   this.submitted=true;
  //   if(this.orgtaxbreakup.invalid)
  //   {
  //     console.log("form invaild");
  //     return;
  //   }
  //   console.log("submit",this.orgtaxbreakup.value);
  //   console.log(this.taxbreakageId);
  //   if(this.taxbreakageId)
  //   {
  //     console.log("Update"+this.taxbreakageId);
  //     let  updateclienttaxbreakage= this.orgtaxbreakup.value;

  //     const payload={
  //       organization:{
  //         organizationId:this.orgId
  //       },
  //       salaryStartRange:updateclienttaxbreakage.salaryStartRange,
  //       salaryEndRange:updateclienttaxbreakage.salaryEndRange,
  //       taxAmount:updateclienttaxbreakage.taxAmount,
  //       taxBreakageId:this.taxbreakageId,
  //       taxBreakageStatus:{
  //         listTypeValueId:this.storeBreakageStatus
  //       }

  //     }

  //     // updateclienttaxbreakage['taxBreakageId'] = this.taxbreakageId;
  //     // updateclienttaxbreakage['taxBreakageStatus'] = { listTypeValueId:this.staus } ;

     
  //     console.log(payload,"payload");
  //  //  return;
  //     this. payrollservice.updateOrgTaxBreakage(this.taxbreakageId,payload).subscribe((result: any) =>
  //       {
  //         this.btndisable=true;
  //         if(result.statusCode == 200)
  //         {
  //           this.success = true;
  //           this.btndisable=true;
  //           this.orgtaxbreakup.reset()

  //           // this.taxbreakageId=0;
  //           console.log("this.taxbreakageId"+this.taxbreakageId);
  //           this.sucess_msg = result.description;
  //           // if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
  //           //  ((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
  //           // {
  //           //   console.log("Filter Call Method",this.filterForm.value);
  //             this.searchSubmit();
  //           // }
  //           // if((this.filterForm.value.filterorganization==null || this.filterForm.value.filterorganization== 'null' ))
  //           // {
  //           //   console.log("Without",this.filterForm.value);
  //           //   // this.searchSubmit();
  //           // }
  //           // else{
  //           //   console.log("Filter Call Method",this.filterForm.value);
  //           //   // this.searchSubmit();
  //           // }
  //           setTimeout(() => {this.success  = false;},5000)
  //           // this.clientupdateId = null;
  //           this.orgtaxbreakup.reset();
  //           this.submitted = false;
  //           this.btndisable=false
  //           // setTimeout(() => {
  //           //   this.router.navigate(["home/employee-rating-list"])
  //           // }, redirectMsgTimeOut)
  //         }
  //         // else if(result.statusCode == 409)
  //         // {
  //         //   console.log("Already Exist");
  //         // this.importerror = true;
  //         // // this.error = true;
  //         // this.error_msg = result.description;
  //         // this.errormessagejson = result;
  //         // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
  //         // }
  //         else
  //         {
  //         console.log("err1",result.statusCode);
  //         this.error = true;
  //        // this.importerror = true;
  //       //  this.errormessagejson = result;
  //         // console.log('this.errormessagejson',this.errormessagejson);
  //           this.error_msg = result.description;
  //         setTimeout(() => {this.error = false,this.btndisable=false}, redirectMsgTimeOut)
  //         }
  //       },
  //        err =>
  //           {
  //               console.log("code1",err.statusCode);
  //               setTimeout(() => {
  //                 this.error = true;
  //                 this.errorMsg = err.error.description;
  //               }, redirectMsgTimeOut) 
  //           })
  //   }
  //   else
  //   {
  //     this.btndisable=true;
  //     console.log("create");


  //     let  createtaxbreakFormvalue= this.orgtaxbreakup.value;

  //     const payload={
  //       organization:{
  //         organizationId:this.orgId
  //       },
  //       salaryStartRange:createtaxbreakFormvalue.salaryStartRange,
  //       salaryEndRange:createtaxbreakFormvalue.salaryEndRange,
  //       taxAmount:createtaxbreakFormvalue.taxAmount

  //     }
      
      
       
    
  //   console.log("payload:",payload);
  // //  return
  //   this.payrollservice.createOrgTaxBreakup(payload).subscribe((result: any) =>
  //   {
  //     console.log("Create",result);
  //    if(result.statusCode == 200)
  //    {
  //      this.success = true;
  //      this.btndisable=true;
  //      this.orgtaxbreakup.reset();
  //      this.searchSubmit()

  //      this.sucess_msg = result.description; 
  //      this.orgtaxbreakup.controls['salaryStartRange']?.patchValue('');
  //     this.orgtaxbreakup.get('salaryEndRange')?.patchValue('')
  //     this.orgtaxbreakup.get('taxAmount')?.patchValue('')

  //     //  if((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)||(this.filterForm.value.filterfinancialyear=="null"||(this.filterForm.value.filterfinancialyear==null))||((this.filterForm.value.filterfinancialyear=="null")||(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null))||
  //     //  ((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null)))
  //     //  {
  //     //    console.log("Filter Call Method",this.filterForm.value);
  //     //   //  this.searchSubmit();
  //     //  }
  //     // if((this.filterForm.value.filterclient==null))
  //     // {
  //     //   console.log("Without",this.filterForm.value);
  //     //   // this.searchSubmit();
  //     // }
  //     // else{
  //     //   console.log("Filter Call Method",this.filterForm.value);
  //     //   // this.searchSubmit();
  //     // }
  //      setTimeout(() => {this.success  = false;},5000)
  //     //  this.orgtaxbreakup.reset();     
  //      this.submitted = false;
  //      this.btndisable=false;

  //    }
  //   //  else if(result.statusCode == 409)
  //   //       {
  //   //         console.log("Already Exist");
  //   //         // this.error = true;
  //   //       this.importerror = true;
  //   //       this.error_msg = result.description;
  //   //    this.errormessagejson = result;
  //   //    setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         
  //   //       }
  //    else
  //    {
  //      console.log("err1",result.statusCode);
  //     //  console.log("err1",result.clientCode);
  //      this.error = true;
  //      this.btndisable=false;

  //     //  this.importerror = true;
  //  //    this.errormessagejson = result;
  //     //  console.log('this.errormessagejson',this.errormessagejson);
  //     this.error_msg = result.description;
  //      setTimeout(() => {this.error = false}, 5000)
  //    }
  //  }, 
  //  err =>
  //  {
  //    console.log("code",err);
  //    console.log("code",err.statusCode);
  //      setTimeout(() => {
  //        this.error = true;
  //        this.btndisable=false;

  //        this.errorMsg = err.error.description;
  //      }, redirectMsgTimeOut) 
  //  })
      
  //   }
    
  //  }
  onSubmit() {
    this.btndisable = true;
  
    // Validate form
    if (this.orgtaxbreakup.invalid) {
        this.btndisable = false;
        console.log("Form invalid");
        return;
    }
  
    console.log("Submit:", this.orgtaxbreakup.value);
  
    if (this.taxbreakageId) {
        // Update flow
        console.log("Update:", this.taxbreakageId);
  
        const updatePayload = {
            ...this.orgtaxbreakup.value,
            taxBreakageId: this.taxbreakageId,
            organization: { organizationId: this.orgId },
            taxBreakageStatus: { listTypeValueId:this.storeBreakageStatus },
          
          };

  
        console.log("Update payload:", updatePayload);
  
        this. payrollservice.updateOrgTaxBreakage(this.taxbreakageId,updatePayload).subscribe((result: any) =>this.handleResponse(result, "update"),
            (err) => this.handleError(err)
        );
    } else {
        // Create flow
        console.log("Create");
  
        const createPayload = {
          organization: { organizationId: this.orgId },
            salaryStartRange: this.orgtaxbreakup.value.salaryStartRange,
            salaryEndRange: this.orgtaxbreakup.value.salaryEndRange,
            taxAmount: this.orgtaxbreakup.value.taxAmount,
        };
  
        console.log("Create payload:", createPayload);
        this.payrollservice.createOrgTaxBreakup(createPayload).subscribe((result: any) => this.handleResponse(result, "create"),
            (err) => this.handleError(err)
        );
    }
  }
  
  // Common Response Handler
  handleResponse(result: any, action: "create" | "update") {
    const isSuccess = result.statusCode === 200;
    console.log(`${action === "create" ? "Create" : "Update"} Response:`, result);
  
    if (isSuccess) {
        this.success = true;
        this.sucess_msg = result.description;
        this.orgtaxbreakup.reset();
        this.taxbreakageId = null; // Clear the ID after successful operation
        this.btndisable = false;
  
        if (this.filterForm.value.filterorganization != null) {
            this.searchSubmit();
        }
  
        setTimeout(() => {
            this.success = false;
        }, 5000);
    } else {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {
            this.error = false;
            this.btndisable = false;
        }, 5000);
    }
  }
  
  // Common Error Handler
  handleError(err: any) {
    console.error("Error:", err);
    this.error = true;
    this.error_msg = err.error?.description || "An error occurred";
    setTimeout(() => {
        this.error = false;
        this.btndisable = false;
    }, 5000);
  }
  
   updateform(row)
   {
    // this.clearForm();
    console.log("clear Form",row);
    this.taxbreakageId=row.taxBreakageId;
    console.log(this.taxbreakageId);
    this.loader=true;
    this.payrollservice.gettaxbreagebyId(this.taxbreakageId).subscribe((resultData:any) =>
    {
      console.log("Api1",resultData);
      this.resultData=resultData;
      this.loader=false;
      // this.staus=resultData.taxBreakageStatus?.listTypeValueId;
      console.log(this.staus);
      console.log(this.resultData);
      console.log(this.orgtaxbreakup);
      this.storeBreakageStatus=this.resultData.taxBreakageStatus?.listTypeValueId

             console.log("this.orgtaxbreakup 1", this.orgtaxbreakup.value);
             this.orgtaxbreakup.patchValue({
              organization : this.resultData.organization?.organizationId,
              salaryStartRange:this.resultData?.salaryStartRange,
              salaryEndRange:this.resultData?.salaryEndRange,
              taxAmount:this.resultData?.taxAmount
            });
    })
   }

clientWorkingDaysIdage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

selectFinancialyear(event)
{
  console.log("Select indidual Employee");
  console.log(event.target.value);
  // console.log(this.filterForm.value.financialyear);
   this.chooseFinId=event.target.value;
  console.log(this.chooseFinId);
  if(this.chooseFinId == null || this.chooseFinId == 'null'){
    console.log('if');
    this.finPeriodList = [];
  }
  else{
  this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => 
  {
    console.log("PeriodData", resultData)
    this.finPeriodList = resultData.finanicalPeriod;
    console.log("finPeriodList",  this.finPeriodList)
    this.orgtaxbreakup.patchValue({
      period: null,
    });
  })
  console.log("Inside selectFinancialyear func",this.orgtaxbreakup);
}
}

clearTable()
{
  // this.resultData = [];
  // this.noRecordsFound = false;

  this.filterData=[];
  this.tableShow=false;
  this.noRecordsFound = true;
  console.log('clear')
  this.filterForm.clearValidators;
  // this.filterForm.reset();
  const currentClientName =this.orgName

  this.filterForm.patchValue({
    filterorganization: currentClientName, // Preserve the current client name
            // Reset other fields
  });
  this.filterForm.get('filterorganization')?.disable();
  
}

clearForm() 
{
  console.log("clearform entered!");
  this.submitted=false;
  
  this.orgtaxbreakup.clearValidators();
  const currentClientName =this.orgName

  this.orgtaxbreakup.patchValue({
    organization: currentClientName,
    salaryStartRange:null,
    salaryEndRange:null,
    taxAmount:null

  });
  this.orgtaxbreakup.get('organization')?.disable();


  // this.orgtaxbreakup.reset(); // This resets all form controls to their initial values
}

pageChages()
{
    console.log("page changes");
    this.p=1;
}
get validate() 
{ 
  return this.orgtaxbreakup.controls; 
}
  
showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

 updateStatus(id,status)
	{
    this.payrollservice.deactiveWorkingDays(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

}
