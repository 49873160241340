import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import { PmsService } from '../../providers/pms.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { forEach, index } from 'mathjs';
import { DatePipe } from '@angular/common';
import {
  
  httpOptions,
  responseMsgTimeOut,
  redirectMsgTimeOut,
} from '../../providers/properties';
import { ViewAppraisalComponent } from '../view-appraisal/view-appraisal.component';
import { AppraisalviewdialogComponent } from '../appraisalviewdialog/appraisalviewdialog.component';
import { StatusServiceService } from 'src/app/status-service.service';

@Component({
  selector: 'app-apprisalview',
  templateUrl: './apprisalview.component.html',
  styleUrls: ['./apprisalview.component.scss'],
})
export class ApprisalviewComponent implements OnInit {
  viewform: FormGroup;
  submitted: boolean = false;
  usertData: any;
  orgId: any;
  empId: any;
  empData: any;
  approverData: any;
  kraTemplateData: any;
  empName: any;
  approverName: string;
  localempId: any;
  LastReviewDate: any;
  createDate: any;
  formattedDate: string;
  kraTempLine: any = [];
  currentYear: any;
  periodData: any;
  empLineList: any;
  per: any;
  sucess_msg: any;
  error_msg: any;
  LineDatas: any = [];
  LastRevieDate: any;
  crateDate: any;
  position: any;
  department: any;
  userId: any;
  approveRole: any;
  formattedTodayDate: string;
  formattedLastDate: any;
  Data: any;
  payload: any;
  fileByte: any;
  val: any;
  apprialform: any;
  kraValues: any;
  totalPer: any;
  appList: any;
  minHours: number = 0;
  minDays: number = 0;
  // apprisalHeaderId: any;
  appHeaderId: any;
  dowUrl: any;
  success: boolean;
  error: boolean;
  errorMsg: any;
  kraarray: any;
  period: any;
  show: boolean = false;
  manage: boolean = false;
  appheader: any;
  approverOneStatus: any;
  approverlevel: any;
  approvalStatus: any;
  apprisalStatus: any;
  manageRating: boolean = false;
  appRating: boolean = false;

  listTypeValueName: string;
  view: any;
  employeeComments: string = '';

  isdisabled: boolean = true;
  loader: boolean = false;
  invalidInput: boolean = false;

  constructor(
    private pmsService: PmsService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private ActivatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AppraisalviewdialogComponent>
  ) {}

  ngOnInit(): void {
    // console.log(this.viewform.controls['employeeComments'].value);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log('this.orgId:', this.orgId);
    this.localempId = this.usertData.employee.employeeId;
    console.log('empId:', this.localempId);
    // this.position="Java Developer";
    // console.log("position:",this.position);
    this.position = this.usertData.employee.designation.designationName;
    // this.position = "angular developer";
    console.log('this.orgId:', this.position);
    // this.department ="Java";
    // console.log("dept:",this.department);
    this.department = this.usertData.employee.department.departmentName;
    // this.department = "angular";
    console.log('this.orgId:', this.department);
    // this.userId = this.usertData.userId;
    // console.log("Employye ID"+this.userId);

    this.viewform = this.fb.group({
      period: [null, Validators.required],
      employeeName: [null],
      department: [null],
      empId: [null],
      reviewerName: [null],
      positionHold: [null],
      reviewerTitle: [null],
      LastReviewDate: [''],
      createDate: [''],
      // empoverallrating:[''],
      hoursWorked: [null, Validators.required],
      leaveDays: [null, Validators.required],
      empquery: [null],
      manquery: [null],
      // file:[null,Validators.required],
      file: [null],
      emprating: [],
      apprating: [],
      managementquery: [],
      finalrating: [],
      finalquery: [],
      employeeComments: ['', Validators.required],
      kra: this.fb.array([]),
    });

    this.appHeaderId = this.ActivatedRoute.snapshot.params.apprisalHeaderId;
    console.log(this.appHeaderId);

    this.loader = true;
    this.pmsService.getApprisalList(this.appHeaderId).subscribe((data: any) => {
      console.log(data);
      this.loader = false;
      this.isdisabled = true;
      console.log(this.isdisabled);
      // console.log("hellloo");

      if (data.statusCode == 204) {
        // this.nodatafound = true;
        console.log('appList Empty');
        this.appList = [];
      }
      this.appheader = [];
      this.appList = data;
      console.log('List', this.appList.reviewerTitle);
      console.log('Appr Raating', this.appList.managementRating);
      console.log('FInal Rating', this.appList.managementTotalRating);
      if (this.appList.approvals.approver.fyiObject != undefined) {
        if (
          this.appList.approvals.approver.fyiObject.employeeId ==
          this.localempId
        ) {
          console.log('FYI');
          // this.show=true;
          console.log(this.show);
        }
      }
      if (this.appList.approvals.approver.approverOne != undefined) {
        if (
          this.appList.approvals.approver.approverOne.employeeId ==
            this.localempId &&
          this.appList.approvals.approverOneStatus.listTypeValueId == 3
        ) {
          console.log('approver1');
          this.show = true;
          this.manage = false;
          console.log(this.show);
        }
      }
      if (this.appList.approvals.approver.approverTwoObject != undefined) {
        if (
          this.appList.approvals.approver.approverTwoObject.employeeId ==
            this.localempId &&
          this.appList.approvals.approverOneStatus.listTypeValueId == 5 &&
          this.appList.approvals.approverTwoStatus.listTypeValueId == 3
        ) {
          console.log('approver2 or Manager');
          this.manage = true;
          this.show = false;
          console.log(this.manage);
        }
      }
      if (this.appList.approvals.approver.approverThreeObject != undefined) {
        if (
          this.appList.approvals.approver.approverThreeObject.employeeId ==
            this.localempId &&
          this.appList.approvals.approverTwoStatus.listTypeValueId == 5 &&
          this.appList.approvals.approverThreeStatus.listTypeValueId == 3
        ) {
          console.log('approver3 or Manager');
          this.manage = true;
          this.show = false;
          console.log(this.manage);
        }
      }
      if (this.appList.approvals.approver.approverFourObject != undefined) {
        if (
          this.appList.approvals.approver.approverFourObject.employeeId ==
            this.localempId &&
          this.appList.approvals.approverThreeStatus.listTypeValueId == 5 &&
          this.appList.approvals.approverFourStatus.listTypeValueId == 3
        ) {
          console.log('approver4 or Manager');
          this.manage = true;
          this.show = false;
          console.log(this.manage);
        }
      }
      if (this.appList.approvals.approver.approverFiveObject != undefined) {
        if (
          this.appList.approvals.approver.approverFiveObject.employeeId ==
            this.localempId &&
          this.appList.approvals.approverFourStatus.listTypeValueId == 5 &&
          this.appList.approvals.approverFiveStatus.listTypeValueId == 3
        ) {
          console.log('approver5 or Manager');
          this.manage = true;
          this.show = false;
          console.log(this.manage);
        }
      }
      this.dowUrl = this.appList.timeSheetAttachment.fileDownloadUrl;
      console.log(this.dowUrl);
      this.appheader = data;
      console.log(this.appheader);
      console.log(this.appList.approvals.approver.approverOne);
      console.log(this.localempId);
      const kraLineFormArray = this.viewform.get('kra') as FormArray;
      console.log(kraLineFormArray);
      console.log(this.appList);

      this.pmsService
        .getPeriodBasedList(this.appList.financialPeriodId)
        .subscribe((Data: any) => {
          this.periodData = Data;
          console.log(this.periodData.financialperiodMonth.substring(0, 3));
          console.log(this.periodData.financialPeriodYear);
          this.period =
            this.periodData.financialperiodMonth.substring(0, 3) +
            '-' +
            this.periodData.financialPeriodYear;
          console.log(this.period);
          this.viewform.patchValue({
            period: this.period,
          });
        });

      console.log('Data', this.appList);

      this.viewform.patchValue({
        empId: this.appList.employeeId,
        employeeName:
          this.appList.employee.firstName +
          ' ' +
          this.appList.employee.lastName,
        LastReviewDate: this.appList.lastClosedDate.split('T')[0],
        createDate: this.appList.todayDate,
        // period:this.period,
        reviewerTitle: this.appList.reviewerTitle,

        reviewerName:
          this.appList.approvals.approver.approverOneObject.firstName +
          ' ' +
          this.appList.approvals.approver.approverOneObject.lastName,
        emprating: this.appList.employeeReviewRating,
        empquery: this.appList.employeeQuery,
        hoursWorked: this.appList.totalHoursWorking,
        apprating: this.appList.managementRating,
        leaveDays: this.appList.leaveDays,
        department: this.usertData.employee.department.departmentName,
        // department : this.department,

        positionHold: this.usertData.employee.designation.designationName,
        // positionHold: this.position,
      });

      console.log(this.appList.apprisalLine.length);
      if (
        this.appList.managementRating != undefined ||
        this.appList.managementRating != '  '
      ) {
        console.log('AppRating', this.appList.managementRating);
        // this.appRating=true;
        this.viewform.patchValue({
          apprating: this.appList.managementRating,
        });
      }
      if (
        this.appList.managementTotalRating != undefined ||
        this.appList.managementTotalRating != ' '
      ) {
        console.log('manageRating', this.appList.managementTotalRating);
        // this.manageRating=true;
        this.viewform.patchValue({
          finalrating: this.appList.managementTotalRating,
        });
      }
      if (
        this.appList.managementComments != undefined ||
        this.appList.managementComments != ' '
      ) {
        console.log('manageRating', this.appList.managementComments);
        // this.manageRating=true;
        this.viewform.patchValue({
          managementquery: this.appList.managementComments,
        });
      }

      this.appList.apprisalLine.forEach((element, index) => {
        console.log(element);
        // console.log(this.appList.apprisalLine.length);
        console.log(index);
        this.addRow(element);
        // let kraLine = this.appList.apprisalLine;
        // console.log(kraLine);
        // for(let i=0; i < kraLine.length;i++)
        //  {
        console.log('inside loop:', index);
        //  console.log(this.appList.apprisalLine[i].apprisalLineId);
        //  console.log(this.appList.apprisalLine[i].criteriaDescription);
        //  kraLineFormArray.at(i).patchValue(this.formBuilder.group({
        // console.log("Emp Rating",element.employeeRating);
        // console.log("Rating",element.rating);
        kraLineFormArray.at(index).patchValue({
          criteriaName: element.kratemplateLine.criteriaName,
          criteriaDescription: element.criteriaDescription,
          criteriaDetail: element.criteriaDetail,
          weightage: element.weightage,
          empRating: 4,
          // approverRating:['', Validators.required],
          rating: element.rating,
          // apprating :['0'],
          // approverComments:[''],
          employeeComments:element.employeeComments,
          // managementRating:[''],
          // managementCalculateRating:[''],
          // kraTemplateHeaderLineId:[data ? data.kraTemplateHeaderLineId : '']
        });
        // if(element.kratemplateLine.managementRating&&element.kratemplateLine.managementCalculateRating&&element.kratemplateLine.approverComments)
        console.log('managementRating', element.managementRating);
        if (
          element.managementRating != undefined ||
          (element.managementRating != ' ' &&
            element.kratemplateLine.managementCalculateRating != undefined &&
            element.kratemplateLine.managementCalculateRating != ' ' &&
            element.kratemplateLine.approverComments != undefined) ||
          element.kratemplateLine.approverComments != ' '
        ) {
          console.log('managementRating', element.managementRating);
          kraLineFormArray.at(index).patchValue({
            // approverRating:['', Validators.required],
            approverRating: element.managementRating,
            apprating: element.managementCalculateRating,
            approverComments: element.approverComments,
          });
        }

        if (
          element.managementTotalRating != undefined ||
          (element.managementTotalRating != ' ' &&
            element.managementComments != undefined &&
            element.managementComments != ' ')
        ) {
          this.loader = true;
          console.log('FianlRating', element.managementTotalRating);
          kraLineFormArray.at(index).patchValue({
            // approverRating:['', Validators.required],
            approverRating: element.managementRating,
            apprating: element.managementCalculateRating,
            approverComments: element.approverComments,
            finalrating: element.managementTotalRating,
            managementquery: element.managementComments,
          });
        }
        //  }

        const kraLin = this.viewform.get('kra') as FormArray;
        console.log(kraLin);
        console.log(this.viewform.value);
      });
    });

    this.viewform.get('employeeComments').valueChanges.subscribe((value) => {
      // Clear errors if field has a value
      if (value) {
        this.viewform.get('employeeComments').setErrors(null);
      }
    });
  }

  addRow(data?: any) {
    console.log(data);
    const control = this.viewform.get('kra') as FormArray;
    control.push(this.initRows(data)); // Pass data to initRows()
    console.log('control', control);
  }

  initRows(data: any = null) {
    console.log(this.show);
    console.log(this.manage);
    // if(this.show=true)
    // {
    console.log('Approver', this.show);
    const group = this.fb.group({
      criteriaName: [data ? data.criteriaName : ''],
      criteriaDescription: ['', Validators.required],
      criteriaDetail: ['', Validators.required],
      weightage: [data ? data.weightage : ''],
      empRating: ['', Validators.required],
      approverRating: [''],
      rating: ['', Validators.required],
      apprating: [''],
      approverComments: [''],
      // approverComments:[''],
      employeeComments: [''],
      finalrating: [''],
      managementquery: [''],
      kraTemplateHeaderLineId: [data ? data.kraTemplateHeaderLineId : ''],
    });
    return group;
    // }
    // else
    // {
    //   console.log("Manager",this.show);
    //   const group = this.fb.group({
    //     criteriaName: [data ? data.criteriaName : ''],
    //     criteriaDescription: ['', Validators.required],
    //     criteriaDetail: ['', Validators.required],
    //     weightage: [data ? data.weightage : ''],
    //     empRating: ['', Validators.required],
    //     approverRating:[''],
    //     rating: ['',Validators.required],
    //     apprating :[''],
    //     // approverComments:['',Validators.required],
    //     approverComments:[''],
    //     employeeComments:[''],
    //     finalrating:['',Validators.required],
    //     managementquery:['',Validators.required],
    //     kraTemplateHeaderLineId:[data ? data.kraTemplateHeaderLineId : '']
    //   });
    //   return group;
    // }
  }

  get validate() {
    return this.viewform.controls;
  }

  get KraLineFormArray(): FormArray {
    return this.viewform.get('kra') as FormArray;
  }

  kraLine() {
    return this.viewform.get('kra') as FormArray;
  }
  downloadfiles(dowUrl) {
    console.log('Download File' + dowUrl);
    const a = document.createElement('a');
    //  const objectUrl: any =  "data:" + filedata.fileType + filedata.fileEncoding + "," + filedata.fileByte;
    // const objectUrl =  `${base_url}download/${result}`
    let downloadurl = dowUrl;
    console.log('downloadurl' + downloadurl);
    const objectUrl = downloadurl;
    // const objectUrl =  `http://38.242.135.217:6018/api/enote/pms/download/TSDOC_6.jpg`
    a.href = objectUrl;
    a.download = objectUrl;
    a.click();
    URL.revokeObjectURL(objectUrl);
    console.log('Download File:' + a);
  }

  // openDialog() {

  //   console.log(this.viewform.value);
  //   this.viewform.value.kra.forEach((element , index) => {
  //       this.appList.apprisalLine[index]["employeeComments"] = element.employeeComments
  //   });

  //   const dialogRef = this.dialog.open(AppraisalviewdialogComponent, {
  //   width: '600px',
  //   height: '200px',
  //   data: this.appList,

  //   // data: {data1:this.appList,data2:this.viewform}

  //   });

  //   dialogRef.afterClosed().subscribe((result) => {

  //     // if (result.statusCode == 200) {
  //     //   this.success = true;
  //     //   this.sucess_msg = result.description;
  //     //   setTimeout(() => {
  //     //     this.success = false;
  //     //   }, responseMsgTimeOut);
  //     // } else {
  //     //   if (result.length != 0) {
  //     //     this.error = true;
  //     //     // this.error_msg = result.message;
  //     //     // this.importerror = true;
  //     //     // this.errormessagejson = result;
  //     //     // this.showerrormessage();
  //     //     // setTimeout(() => {this.error = false}, responseMsgTimeOut)
  //     //   } else {
  //     //     if (result.statusCode > 400) {
  //     //       this.error = true;

  //     //       // this.importerror = false;
  //     //     }
  //     //   }
  //     // }

  //     console.log("close");
  //   });
  // }

  openDialog() {
    console.log('openDialog() called');

    

   

    // Mark all controls in KraLineFormArray as touched to trigger validation
    this.KraLineFormArray.controls.forEach((control) => {
      control.get('employeeComments').markAsTouched();
    });

    if (this.KraLineFormArray.valid) {
      this.viewform.value.kra.forEach((element, index) => {
        this.appList.apprisalLine[index]['employeeComments'] =
          this.viewform.value.employeeComments;
      });

      const control = this.viewform.get('kra') as FormArray;


control.controls.forEach((row, index) => {
 
  const employeeCommentsValue = row.get('employeeComments').value;

  
  console.log('employeeCommentsValue', employeeCommentsValue);


 
  
  if (this.appList.apprisalLine && Array.isArray(this.appList.apprisalLine)) {
    
    if (this.appList.apprisalLine[index]) {
      
      this.appList.apprisalLine[index].employeeComments = employeeCommentsValue;
    } else {
    
      console.warn(`No apprisalLine item found at index ${index}`);
    }
  } else {
    
    console.error('apprisalLine is not properly initialized');
  }
});

      
      // Iterate through each form control in the FormArray
      control.controls.forEach((row, index) => {
        // Get the value of employeeComments from the current row
        const employeeCommentsValue = row.get('employeeComments').value;

        // Log the value for debugging
        console.log('employeeCommentsValue', employeeCommentsValue);

        // Ensure apprisalLine exists and is an array
        if (
          this.appList.apprisalLine &&
          Array.isArray(this.appList.apprisalLine)
        ) {
          // Ensure there is an apprisalLine item for this index
          if (this.appList.apprisalLine[index]) {
            // Update the employeeComments field with the new value
            this.appList.apprisalLine[index].employeeComments =
              employeeCommentsValue;
          } else {
            // Handle the case where there's no item at the given index
            console.warn(`No apprisalLine item found at index ${index}`);
          }
        } else {
          // Handle the case where apprisalLine is not properly initialized
          console.error('apprisalLine is not properly initialized');
        }
      });

      const dialogRef = this.dialog.open(AppraisalviewdialogComponent, {
        width: '600px',
        height: '230px',
        data: this.appList,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('Dialog closed');
      });
    } else {
      
    }
  }



  back() {
    this.router.navigate(['home/appraisal']);
  }

  

  handleKeyPress(event) {
    if (event.key === ' ' && event.target.selectionStart === 0) {
      event.preventDefault();
    }
  }

  validateInput(event) {
    const input = event.target;
    let value = input.value;

    // Trim leading spaces
    value = value.trimStart();

    // Replace multiple spaces with a single space
    value = value.replace(/\s+/g, ' ');

    // Set the modified value back to the input
    input.value = value;

    // Check if the input is invalid (only spaces or empty)
    this.invalidInput = value.trim().length === 0;
  }
}
