import { Component, OnInit } from '@angular/core';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { HeaderService } from 'src/app/providers/header.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';

@Component({
  selector: 'app-time-employee-dashboard',
  templateUrl: './time-employee-dashboard.component.html',
  styleUrls: ['./time-employee-dashboard.component.scss']
})
export class TimeEmployeeDashboardComponent implements OnInit {
taskpendingCount:any[]=[];
  projectCount:any = 0;
  projectList:any = [];
  taskCount:any = 0;
  taskPendingCount:any = 0;
  customerCount:any = 0;
  todayDate = new Date();
  employeeName:any;
  page:any
  loader = true;
  nodata =  false;
  beforesearchpage: any;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any = 5;
  p:any;
  usertData: any = '';
  userType: any;
  organizationId:any
  constructor(private gs:ProjectManagementService,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService,
    private positionservice: RecruitmentService) { }

  ngOnInit(): void {
    this.timesheet.setSuccessMessage('');
    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    this.assignemp.setSuccessUpdateMessage(
      ''
    );
    this.headerservice.setTitle('- Dashboard');
    this.loader = true;
    const localData = JSON.parse(localStorage.getItem('enoteUserData'))

    this.employeeName = localData.employeeName;
    const organizationId = localData.organization.organizationId;
    

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('local storage data::', this.usertData);

    this.userType = [];
    this.usertData.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });

    this.organizationId = this.usertData.organization.organizationId;

    this.positionservice
    .getclientlist(this.organizationId)
    .subscribe((resultData: any) => {
      console.log(' resultData', resultData);
      // this.clientListData = resultData;
      // this.Customer = resultData
      // this.clientCount = resultData.length;
       this.customerCount = resultData.length;
    });
    console.log('localData.employeeId',localData.employee.employeeId
    );
    console.log('localData.employeeId',localData.employee.employeeId
    );
    this.gs.getProjectByEmployeeId(localData.employee.employeeId
      ).subscribe(
      (d:any)=>{
        this.loader = false;

        if (d.length > 0) {
          
          this.projectCount = d.length;
        }
        this.projectList = d
        let client = new Array()
        console.log('d ',d);
        
        d.map(e=>{

          if(e.customer){
              console.log(e.customer);
            client.push(d)
          }
          // this.customerCount = client.length;
           console.log( "Customer length" + client.length);
        })
        if(this.projectList.length == 0)
        {
          this.loader = false;
          this.nodata = true;
          console.log("nodata");
        }
      },
      (error) => {
        this.nodata = true;
        this.loader = false;
        console.log("error")
        if(error.error.errorCode == 204){
          this.nodata = true;
          console.log("404")
        }

        if(error.errorCode == 204){
          this.nodata = true;
          console.log("404")
        }
      }

    )

   
    // this.gs.getPendingTaskCount(localData.employee.employeeId).subscribe(
    //   (d: any) => {});
    //   this.gs.getTaskCountByEmployee(localData.employee.employeeId).subscribe(
    //     (d: any) => {});
    
    // this.gs.getEmployeeTaskByEmpolyee(localData.employee.employeeId).subscribe(
    //   (d: any) => {
    //     this.loader = false;
    //     console.log('dat', d);
    
    //     // Filter tasks with taskStatus 31
    //     let pendingTasks = d.filter((t: any) => {
    //       return t.task.taskStatus.listTypeValueId == 31;
    //   });
      
    //   pendingTasks.forEach((t: any) => {
    //       this.taskpendingCount.push(t.task.taskStatus);
    //   });
    //     console.log('Pending Tasks:',   this.taskpendingCount); // Log pending tasks
    
    //     // Assign the length of the filtered array to taskPendingCount
    //     this.taskPendingCount = this.taskpendingCount.length;
    //   },
    //   (error) => {
    //     this.nodata = true;
    //     this.loader = false;
    //     console.log("error");
    //     if (error.status == 404) {
    //       this.nodata = true;
    //       console.log("404");
    //     }
    //   }
    // );

    this.gs.getTaskCountByEmployee(localData.employee.employeeId
    ).subscribe(
    (d:any)=>{
      this.loader = false;

      console.log('taskCount',d.taskCount);

      if (d.taskCount > 0) {
        
        this.taskCount = d.taskCount;
      }
      
      d.map(
        e=>{
          let task = new Array()
          if(e.task.taskStatus == 31){
            task.push(d)
          }
          this.taskPendingCount = task.length;
        }
      )
    },
    (error) => {
      this.nodata = true;
      this.loader = false;
      console.log("error")
      if(error.status == 404){
        this.nodata = true;
        console.log("404")
      }
    }
  )



  this.gs.getPendingTaskCount(localData.employee.employeeId
  ).subscribe(
  (d:any)=>{
    this.loader = false;

    console.log('taskCount',d.taskCount);
    
    if (d.taskCount > 0) {
      
      this.taskPendingCount = d.taskCount;
    }
    // d.map(
    //   e=>{
    //     let task = new Array()
    //     if(e.task.taskStatus == 31){
    //       task.push(d)
    //     }
    //     this.taskPendingCount = task.length;
    //   }
    // )
  },
  (error) => {
    this.nodata = true;
    this.loader = false;
    console.log("error")
    if(error.status == 404){
      this.nodata = true;
      console.log("404")
    }
  }
)
  }


  
    changefirstpage() {
      this.p = 1;
    }
  

  // sortData(sort: Sort) {
  //   const data = this.EmpList.slice();
  //   if (!sort.active || sort.direction === '') {
  //     this.EmpList = data;
  //     return;
  //   }

  //   this.EmpList = data.sort((a, b) => {
  //     const isAsc = sort.direction === 'asc';
  //     switch (sort.active) {
       
  //       case 'projectName':
  //         return this.compare(a.project.projectName, b.project.projectName, isAsc);
  //       case 'taskName':
  //         return this.compare(a.task.taskName, b.task.taskName, isAsc);
  //       case 'date':
  //         return this.compare(a.date, b.date, isAsc);
  //       case 'workedHours':
  //         return this.compare(a.workedHours, b.workedHours, isAsc);
  //       case 'taskStatusName':
  //           return this.compare(a.task.taskStatusName, b.task.taskStatusName, isAsc);
  //       case 'approvalStatusName':
  //         return this.compare(a.approval.approvalStatusName, b.approval.approvalStatusName, isAsc);
                  
  //       default:
  //         return 0;
  //     }
  //   });
  // }
}
