<!-- Sub header start here-->
<section>
    <div class="sub-header" style="top:64px">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <div class="">
                        <span class="h5 sub-content pr-3" style=" position: relative;
                        top: 6px;
                        color: #181c32;
                        font-weight: 800;
                        font-size: 22px;
                        font-family: 'Mulish', sans-serif;
                        right: 15px;">{{editId? "Edit" : "Add"}} KRA Template</span>
                    </div>
                    <!-- <span routerLink="/home/kra-template" class="h5 sub-content">Add Branch</span> -->
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card" style="font-family: Mulish;">
            <div class="card-body">
                <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label" style="font-weight: 600;">Template Code <span class="star">*</span></label>
                            <input type="text" placeholder="Template Code" formControlName="templateCode" autocomplete="off" (keyup)="templateCode($event)"   (paste)="onPaste($event)" 
                            (keypress)="noNumber($event)"
                
                               class="form-control email-input select-placeholder mb-3 mb-lg-0"
                            style="border-color: #f5f8fa;border: 1px solid #dee2e6;">
                            <!-- <div class="valid-msg" *ngIf="(validate.templateCode.invalid && validate.templateCode.touched) || validate.templateCode.dirty || submitted">
                                <span *ngIf="validate.templateCode.errors?.required" class="text-danger">Please Enter Template Code</span>
                                <span *ngIf="invalidInput &&(validate.templateCode.errors?.pattern)" class="text-danger">Please Enter Valid Template Code</span>
                                <span *ngIf="tempCodeExist" class="text-danger">Template Code Already Exists.</span>
                            </div> -->

                                 

                            <div class="valid-msg" *ngIf="(validate.templateName.invalid && validate.templateCode.touched) || validate.templateCode.dirty || submitted">
                                <span *ngIf="validate.templateCode.errors?.required" class="text-danger">Please Enter Template Code</span>
                                <span *ngIf="invalidInput &&(validate.templateCode.errors?.pattern)" class="text-danger">Please Enter Valid Template code</span>

                                <!-- <span *ngIf="validate.templateName.errors?.pattern" class="text-danger">Please Enter Valid Template Name</span> -->
                                <span *ngIf="tempCodeExist" class="text-danger">Template Code Already Exists.</span>

                            </div>

                    </div>

                            <div class="form-group col-md-3">
                                <label class="col-form-label" style="font-weight: 600;">Template Name <span class="star">*</span></label>
                                <input type="text" formControlName="templateName" autocomplete="off"   (keyup)="templateName($event)"  (paste)="onPaste($event)"  (input)="filterAndValidateInput($event)"  (keydown)="handleKeyPress($event)"


                                class="form-control email-input select-placeholder  mb-3 mb-lg-0" autocomplete="off" placeholder="Template name"
                                style="border-color: #f5f8fa;border: 1px solid #dee2e6;">
                                <div class="valid-msg" *ngIf="(validate.templateName.invalid && validate.templateName.touched) || validate.templateName.dirty || submitted">
                                    <span *ngIf="validate.templateName.errors?.required" class="text-danger">Please Enter Template Name</span>
                                    <!-- <span *ngIf="invalidInput &&(validate.templateName.errors?.pattern)" class="text-danger">Please Enter Valid Template Name</span> -->

                                    <span *ngIf="tempNameExist" class="text-danger">Template Name Already Exists.</span>

                                </div>
                            
                            </div>
                        
                        
                        

                       
                        

                        <div class="form-group col-md-3">
                            <label class="col-form-label" style="font-weight: 600;">Template Description </label>
                            <input type="text" formControlName="templateDescription" class="form-control email-input select-placeholder  mb-3 mb-lg-0" autocomplete="off" placeholder="Template Description"
                            style="border-color: #f5f8fa;border: 1px solid #dee2e6; " (input)="validateInput($event)" >
                            <!-- <div class="valid-msg" *ngIf="(validate.phoneNumber.invalid && validate.phoneNumber.touched) || validate.phoneNumber.dirty || submitted">
                                <span *ngIf="validate.phoneNumber.errors?.required" class="text-danger">Please enter Mobile Number</span>
                                <span *ngIf="validate.phoneNumber.errors?.pattern" class="text-danger">Please enter valid Mobile Number</span>
                            </div> -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-9 mb-3">
                            <label class="col-form-label" >
                                <h4 class="criteria-class mb-2" style="font-family: Mulish;">Add Criteria and Weightage (%) for the template</h4>
                                <h4 class="negative-rating mb-1" style="font-family: Mulish;">Select the checkbox for where you providing negative rating</h4>
                            </label>
                        </div>
                    </div>

                    <!-- Add and remove more start -->
                    
                    <div class="row">
                        <div class="col-md-3" style="font-weight: 600;">Criteria <span class="star">*</span></div>
                        <div class="col-md-3" style="font-weight: 600;">Weightage <span class="star">*</span></div>
                        <!-- <div class="col-md-2">Negative Rating</div> -->
                        <div class="col-md-2"></div>
                        
                        <div class="mt-2 form-group col-md-12" *ngFor=" let con of add_remove().controls; let i=index" formArrayName="add_remove_more">
                            <div class="row" [formGroupName]="i" *ngIf="i<10">
                                <div class="col-md-3">
                                    <input type="text" formControlName="criteriaName" class="form-control email-input select-placeholder  mb-3 mb-lg-0" autocomplete="off" placeholder="Criteria" (input)="validateInput($event)"  (paste)="onPaste($event)"  (keydown)="handleKeyPress($event)">
                                    <!-- <div class="valid-msg" *ngIf="(postForm.get('add_remove_more.' + i + '.criteriaName').invalid && postForm.get('add_remove_more.' + i + '.criteriaName').touched) || postForm.get('add_remove_more.' + i + '.criteriaName').dirty || submitted">
                                        <span *ngIf="postForm.get('add_remove_more.' + i + '.criteriaName').hasError('required')" class="text-danger">Please Enter Criteria</span>
                                    </div> -->
                                    <mat-error *ngIf="(submitted || KraLineFormArray.controls[i].get('criteriaName')?.touched || KraLineFormArray.controls[i].get('criteriaName')?.dirty) && KraLineFormArray.controls[i].get('criteriaName')?.invalid">
                                        <span *ngIf="KraLineFormArray.controls[i].get('criteriaName')?.hasError('required')">
                                            Please Enter Criteria.
                                        </span>
                                        <!-- <span
                                        *ngIf="KraLineFormArray.controls[i].get('criteriaName')?.hasError('notZero')">Please Enter valid Criteria</span> -->
                                    </mat-error>
                                    
                                </div>

                                <div class="col-md-3">
                                    <input type="text" formControlName="weightage"  (keyup)="updateTotalWeightage(i); validateWeightage(i)" class="form-control email-input select-placeholder  mb-3 mb-lg-0" autocomplete="off" placeholder="Weightage (%)" (input)="validateInput($event)" (paste)="onPaste($event)" (keydown)="handleKeyPress($event)" (keypress)="noNumber($event)">
                                    <!-- <div class="valid-msg" *ngIf="(postForm.get('add_remove_more.' + i + '.weightage').invalid && postForm.get('add_remove_more.' + i + '.weightage').touched) || postForm.get('add_remove_more.' + i + '.weightage').dirty || submitted">
                                        <span *ngIf="postForm.get('add_remove_more.' + i + '.weightage').hasError('required')" class="text-danger">Please Enter Weightage</span>
                                    </div> -->
                                    <mat-error *ngIf="(submitted || KraLineFormArray.controls[i].get('weightage')?.touched || KraLineFormArray.controls[i].get('weightage')?.dirty) && KraLineFormArray.controls[i].get('weightage')?.invalid">
                                        <span *ngIf="KraLineFormArray.controls[i].get('weightage')?.hasError('required')">
                                            Please Enter Weightage.
                                        </span>
                                        
                                        <!-- <span
                                        *ngIf="KraLineFormArray.controls[i].get('weightage')?.hasError('notZero')">Please Enter Vaild Weightage</span> -->
                                    </mat-error>
                                    
                                </div>

                                <!-- <div class="col-md-1">
                                    <div class="check_box check_boxView check__box1">
                                        <input type="checkbox" formControlName="negativeRatingCheck" class="check_box_btn" id="negative_rating_check" value="1">
                                    </div>
                                </div> -->
                               
                                <!-- add and remove btn -->
                                
                                <div *ngIf="i<1" class="col-md-2" style="margin-top:4px;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="addMore()"  [disabled]="isAddButtonDisabled"  style="font-family: Mulish; " >
                                        Add
                                    </button>
                                </div>

                                <div class="col-md-2 mt-2" *ngIf="i>0">
                                    <mat-icon (click)="removeMore(i)" class="material-icons-outlined" >delete</mat-icon>
                                </div>

                                <!-- <div class="col-md-2 mt-2" *ngIf="i > 0">
                                    <i class="fa fa-trash fa-2xl" (click)="removeMore(i)" style="color: red; cursor: pointer;"></i>
                                     <i class="fa-regular fa-trash fa-2xl"  (click)="removeMore(i)" style="color: red; cursor: pointer;"></i> -->
                                <!-- </div>  -->
                                
                                <!-- add and remove btn -->

                            </div>
                        </div>
                        <div class="form-group  col-md-3">
                        </div>
                    </div>
                    <!-- Add and remove more end -->

                    <div class="row">
                        <div class="col-md-12 text-right" >
                            <a routerLink="/home/kra-template" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2"  style="font-family: Mulish; color: red;">
                                Cancel
                            </a>
                            <!-- <button type="submit" mat-raised-button color="primary" title="Save" style="font-family: Mulish;" [disabled]="submitButtonDisable"
                            >Save</button> -->
                            <button type="submit" mat-raised-button title="Save" style="font-family: Mulish;"
  [disabled]="submitButtonDisable"
  [color]="submitButtonDisable ? 'warn' : 'primary'"
  [ngClass]="{'disabled-button': submitButtonDisable}">Save</button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>