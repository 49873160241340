<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content"
            >{{ empleaveId ? "Edit" : "Add" }} Employee Leave</span
          >
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="leaveForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Financial Year<span class="star"> *</span></label
              >
              <mat-select
                formControlName="financialyear"
                class="form-control email-input select-placeholder"
                (selectionChange)="selectFinancialyear()"
                placeholder="Select Financial Year"
              >
                <mat-option [value]="null">-- Select Financial Year ---</mat-option>
                <mat-option
                  *ngFor="let row of finYearList; let i = index"
                  [value]="row.financialYearId"
                >
                  {{ row.financialFromMonth }}-{{ row.financialFromYear }} To
                  {{ row.financialToMonth }}-{{ row.financialToYear }}
                </mat-option>
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.financialyear.invalid &&
                    validate.financialyear.touched) ||
                  validate.financialyear.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.financialyear.errors?.required"
                  class="text-danger"
                  >Please Select Financial Year</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Period<span class="star"> *</span></label
              >
              <mat-select
                formControlName="period"
                class="form-control email-input select-placeholder"
                placeholder="Select Period"
              >
                <mat-option [value]="null">-- Select Period ---</mat-option>
                <mat-option
                  *ngFor="let row of finPeriodList; let i = index"
                  [value]=" row.financialPeriodId "
                >
                  {{ row.financialperiodMonth }}-{{ row.financialPeriodYear }}
                </mat-option>
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.period.invalid && validate.period.touched) ||
                  validate.period.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.period.errors?.required"
                  class="text-danger"
                  >Please Select Period</span
                >
              </div>
            </div>
            <!-- <div class="form-group col-md-3">
              <label class="form-control-label"
                >Employee Code<span class="star"> *</span></label
              >
              <input
                type="text"
                placeholder="Search Employee Code"
                formControlName="employeecode"
                [matAutocomplete]="auto"
                class="form-control email-input select-placeholder"
                (keydown)="onKeyDown($event)"
                (keyup)="empSearch($event)"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option value="All" (click)="empAllSelect()"
                  >All</mat-option
                >
                <mat-option
                  *ngFor="let emp of empList"
                  [value]="emp.employeeNumber"
                  (click)="empSelect(emp)"
                >
                  {{ emp.employeeNumber }} - {{ emp.firstName }}
                </mat-option>
                <mat-option [value]="null" *ngIf="empCode && empList.length === 0" (click)="noEmployeeFound()">No
                  Employee Found</mat-option>
              
              </mat-autocomplete>
              <mat-icon
                class="material-symbols-outlined"
                style="position: absolute; top: 48px; right: 20px"
              >
                search
              </mat-icon>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.employeecode.invalid &&
                    validate.employeecode.touched) ||
                  validate.employeecode.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.employeecode.errors?.required"
                  class="text-danger"
                  >Please Select Employee Code</span
                >
              </div>
            </div> -->

            <div class="form-group col-md-3">
              <label class="form-control-label">Employee Code<span class="star"> *</span></label>
              <input
                type="text"
                placeholder="Search Employee Code"
                formControlName="employeecode"
                [matAutocomplete]="auto"
                class="form-control email-input select-placeholder"
                (keyup)="empSearch($event)"
                (keydown)="onKeyDown($event)"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <ng-container *ngIf="empList.length > 0">
                  <mat-option value="All" (click)="empAllSelect()">All</mat-option>
                  <mat-option
                    *ngFor="let emp of empList"
                    [value]="emp.employeeNumber"
                    (click)="empSelect(emp)"
                  >
                    {{ emp.employeeNumber }} - {{ emp.firstName }}
                  </mat-option>
                </ng-container>
                <mat-option [value]="null" *ngIf="empList.length === 0 && empCode" (click)="noEmployeeFound()">
                  No Employee Found
                </mat-option>
              </mat-autocomplete>
              <mat-icon
                class="material-symbols-outlined"
                style="position: absolute; top: 37px; right: 20px"
              >
                search
              </mat-icon>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.employeecode.invalid && validate.employeecode.touched) ||
                  validate.employeecode.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.employeecode.errors?.required"
                  class="text-danger"
                  >Please Select Employee Code</span
                >
              </div>
            </div>
            
          </div>
          <div
            class="row pb-3"
            *ngIf="
              selectedEmployeeData.length != 0 ||
              (empleaveId != null && !loader && resultData.length > 0)
            "
          >
            <!-- <div class="col-md-11 text-right" *ngIf="getFormControls.length >= 5">
              <p class="show-count">Show</p>
            </div>
            <div class="col-md-1 text-left pl-0" >
              <select
                [(ngModel)]="pagePerItem"
                [ngModelOptions]="{ standalone: true }"
                (change)="pageChages()"
                class="form-control report-filter"
              >
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div> -->
            <div class="text-right" *ngIf="getFormControls.length >5">
              <div class="select-pagination m-2" >
                <span>Show : </span>
                <select
                  class="select_list new-select_list listDesign"
                  [(ngModel)]="pagePerItem"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="pageChages()"
              
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>

          <div *ngIf="showtable">
            <table
              style="border-radius: 5px"
              class="mt-2 table --table-bordered table-border-new"
              *ngIf="
                selectedEmployeeData.length != 0 ||
                (empleaveId != null && !loader)
              "
            >
              <thead class="t-head">
                <tr>
                  <th class="col-md-2">Action</th>
                  <th class="col-md-3">Employee Code</th>
                  <th class="col-md-3">Employee Name</th>
                  <th style="text-align: left" class="col-md-4">Leave Days</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  formArrayName="Leaves"
                  *ngFor="
                    let group of getFormControls.controls
                      | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                      | searchFilter : tableSearch;
                    let i = index
                  "
                >
                  <ng-container *ngIf="p == 1; else nextpage">
                    <tr formGroupName="{{ i }}">
                      <td style="color: red">
                        <!-- <button mat-menu-item type="button" (click)="deleteemployee(i)"> -->
                        <mat-icon (click)="deleteemployee(i)">delete</mat-icon>
                        <!-- </button> -->
                      </td>
                      <td>{{ group.value.employeeNumber }}</td>
                      <td>
                        {{ group.value.firstName }} {{ group.value.lastName }}
                      </td>
                      <td class="text-center">
                        <input
                          type="text"
                          formControlName="empleave"
                          placeholder="Please Enter Leave Days"
                          (paste)="onPaste($event)"
                          class="example-input designchange form-control"
                          (keypress)="onlyNumber($event)"
                        /><br />
                        <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->
                        <mat-error
                          *ngIf="
                            submitted ||
                            ( leaveDaysFormArray.controls[i].get('empleave').touched &&
                              leaveDaysFormArray.controls[i].get('empleave')
                                ?.invalid)
                          "
                        >
                          <span
                            style="margin-right: 50%"
                            *ngIf="
                              leaveDaysFormArray.controls[i]
                                .get('empleave')
                                ?.hasError('required')
                            "
                          >
                            Please Enter Leave Days
                          </span>
                          <span
                            style="margin-right: 50%"
                            *ngIf="
                              leaveDaysFormArray.controls[i]
                                .get('empleave')
                                ?.hasError('notZero')
                            "
                          >
                            Please Enter Valid Leave Days
                          </span>
                          
                        </mat-error>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #nextpage>
                    <tr formGroupName="{{ i + startIndex }}">
                      <!-- <td class="text-center" style="position: relative; bottom: 5px;">
                                            <button mat-menu-item type="button" (click)="deleteemployee(i)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td> -->
                      <td style="color: red">
                        <!-- <button mat-menu-item type="button" (click)="deleteemployee(i)"> -->
                        <mat-icon (click)="deleteemployee(i)">delete</mat-icon>
                        <!-- </button> -->
                      </td>
                      <td>{{ group.value.employeeNumber }}</td>
                      <td>
                        {{ group.value.firstName }} {{ group.value.lastName }}
                      </td>
                      <td>
                        <input
                          type="text"
                          formControlName="empleave"
                          placeholder="Please Enter Leave Days"
                          class="example-input designchange form-control"
                          (keypress)="onlyNumber($event)"
                        /><br />
                        <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->

                        <mat-error
                          *ngIf="
                            submitted ||
                            (leaveDaysFormArray.controls[i + startIndex].get(
                              'empleave'
                            )?.touched &&
                              leaveDaysFormArray.controls[i + startIndex].get(
                                'empleave'
                              )?.invalid)
                          "
                        >
                          <span
                            style="margin-right: 50%"
                            *ngIf="
                              leaveDaysFormArray.controls[i + startIndex]
                                .get('empleave')
                                ?.hasError('required')
                            "
                            >Please Enter Leave Days.</span
                          >
                          <span
                          style="margin-right: 50%"
                          *ngIf="
                            leaveDaysFormArray.controls[i + startIndex]
                              .get('empleave')
                              ?.hasError('notZero') &&
                            leaveDaysFormArray.controls[i + startIndex]
                              .get('empleave')
                              ?.touched
                          "
                        >
                          Please Enter Valid Leave Days
                        </span>
                        
                          <span
                          style="margin-right: 50%"
                          *ngIf="
                            leaveDaysFormArray.controls[i + startIndex].get('empleave')?.hasError('max')
                          "
                        >Please Enter Leave Days less than or equal to 30.</span>
                        </mat-error>
                      </td>
                    </tr>
                  </ng-template>
                </ng-container>
              </tbody>

              <!-- <tbody>
                                    <tr>
                                        <td style="text-align: center;" colspan="5" *ngIf="noRecordsFound">No matching records found!</td>
                                    </tr>
                                </tbody>  -->
            </table>
          </div>
          <div *ngIf="selectedEmployeeData?.length > 0">
            <div class="row">
              <div class="col-md-4">
                <div class="showing-record">
                  {{ selectedEmployeeData.length }} rows
                </div>
              </div>

              <div class="col-md-8" *ngIf="!loader && selectedEmployeeData?.length > 5">
                <pagination-controls
                  style="float: right"
                  (pageChange)="onPageChange($event)"
                ></pagination-controls>
              </div>
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-md-12 text-right">
              <!-- <button type="button" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                [routerLink]="['home/employee-leave-list']" style="margin-right: 20px;"
                                (click)="close()">Cancel</button> -->

              <button
                type="button"
                class="submit"
                mat-raised-button
                (click)="close()"
                [routerLink]="['home/employee-leave-list']"
                style="
                  background: none;

                  border: 1px solid #ddd;
                  font-size: 14px;
                  font-weight: 300;
                  height: 40px !important;
                "
                style="
                  color: var(--Red-Color, #ff5d5d);
                  height: 35px;
                  margin-right: 1%;
                "
              >
                Cancel
              </button>
              <!-- <button type="submit" class="submit action-button btn btn-primary btn-sm">Save</button> -->
              <button
                mat-raised-button
                type="submit"
                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                [disabled]="isprocessing1 || leaveForm.invalid"
              >
                Save
              </button>
              <!-- [disabled]="this.leaveForm.invalid" -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
    role="status"
  ></div>
</div>
