<p>add-elements works!</p>
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">
                       {{elementPercentageId ? "Edit" : "Create"}} Element Percentage
                       
                    </span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/element-percentage" mat-raised-button title="Back" class="ml-2">
                        Back
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="elementform" (ngSubmit)="submitData()">

                    <div class="row">   
                        <div class="form-group col-md-3" >
                            <label class="col-form-label">Element Type <span class="star">*</span></label>
                            <mat-select class="form-select form-select-solid form-select-lg" (selectionChange)="listTypeValueid($event)" appearance="fill" formControlName="categoryType" placeholder="- Select Element -">
                                <mat-option *ngFor="let e of categoryTypeData" class="text-color" [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
                            </mat-select>
                            <div class="valid-msg" *ngIf="(categoryType.invalid && categoryType.touched) || categoryType.dirty || submitted">
                                <span *ngIf="categoryType.errors?.required" class="text-danger">Please select category type</span>
                            </div> 
                        </div>  
                        <div class="form-group col-md-3" >
                            <label class="col-form-label">Element Name <span class="star">*</span></label>
                            <mat-select class="form-select form-select-solid form-select-lg" (selectionChange)="listTypeValueidElementName($event)" appearance="fill" formControlName="elementName" placeholder="- Select Element Name -">
                                <mat-option *ngFor="let e of elementNameData" class="text-color" [value]="e.elementId">{{e.elementName}}</mat-option>
                            </mat-select>
                            <div class="valid-msg" *ngIf="(elementName.invalid && elementName.touched) || elementName.dirty || submitted">
                                <span *ngIf="elementName.errors?.required" class="text-danger">Please select category type</span>
                            </div> 
                        </div>  
                        <!-- *ngIf="showLivingCity" -->
                        <div class="form-group col-md-3" *ngIf="showLivingCity">
                            <label class="col-form-label">Living City </label>
                            <mat-select class="form-select form-select-solid form-select-lg" (selectionChange)="listTypeValueidLivingCity($event)" appearance="fill" formControlName="livingCity" placeholder="- Select Type -">
                                <mat-option *ngFor="let e of livingCityData" class="text-color" [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
                            </mat-select>
                        </div>  
                        <!-- <div class="form-group col-md-3">
                            <label class="col-form-label">Category Name <span class="star">*</span></label>
                            <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"  formControlName="elementName" id="elementName" placeholder="" required>
                            <div class="valid-msg" *ngIf="(elementName.invalid && elementName.touched) || elementName.dirty">
                                <span *ngIf="elementName.errors?.required" class="text-danger">Please enter category name</span>
                                <span *ngIf="elementName.errors?.pattern" class="text-danger">Please enter valid category name</span>
                            </div>
                        </div>  -->
                        
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Percentage <span class="star">*</span></label>
                            <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"  formControlName="percentage" placeholder="">  
                            <div class="valid-msg">
                                <!-- <span *ngIf="orderNumber.errors?.required" class="text-danger">Please enter order number</span> -->
                                <span *ngIf="percentage.errors?.pattern" class="text-danger">Please enter valid percentage</span>
                            </div>
                        </div>  
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a routerLink="/home/element-percentage" mat-raised-button title="Cancel" class="btn btn-light btn-sm mr-2">
                                Cancel
                            </a>
                            <button type="submit" class="submit action-button btn btn-primary btn-sm" [disabled]="elementform.invalid">Save</button><!-- [disabled]="resourceform.invalid || !maxExp" -->
                        </div>
                    </div>       
                </form>  
            </div>  
        </div>  
    </div>  
</div> 

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
