import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
// import { join } from 'path';
import { MatDialog } from '@angular/material/dialog';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { DenyreasonComponent } from '../denyreason/denyreason.component';
import { LeaveService } from 'src/app/providers/leave.service';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-manage-workfromhome',
  templateUrl: './manage-workfromhome.component.html',
  styleUrls: ['./manage-workfromhome.component.scss']
})
export class ManageWorkfromhomeComponent implements OnInit {

  isLoading: Boolean;
  list_count = 0;
  noData = false;
  data: any[] = [];
  query
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber', 'employeeName', 'organizationEmail', 'startDate', 'endDate', 'noOfDays', 'reason', 'workFromHomeStatus', 'star',];
  // dataSource: any;
  loader = true;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  mail: any;
  empId: any;
  approvallist: any = [];
  organizationId: any;
  success_msg: string;
  error_msg: any;
  // categoryId: any;
  success: boolean = false;
  error: boolean = false;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
list_counts=false;
paginators: boolean = true;
  beforesearchpage: any;

  constructor(
    private gs: GlobalserviceService,
    private router: Router,
    public dialog: MatDialog,
    public leaveservice: LeaveService,
    private headerservice: HeaderService
  ) {
  }
  ngOnInit() {
    // this.headerservice.setTitle('Work From Home')
    this.headerservice.setTitle('');

    let x = JSON.parse(localStorage.getItem("enoteUserData"));
    let mail = x.organizationEmail;
    this.mail = mail;
    console.log(this.mail);
    this.empId = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;
    // this.categoryId  = x.category.categoryId;

    // this.leaveservice.getWFHRequest(this.empId, 3).subscribe((d: any) => {
    //   this.loader=false;
    //   console.log(d);
    //   if(d.statusCode==204)
    //   {
    //     this.noData=true;
    //     console.log("NoData");
    //   }
    //   console.log(d)
    //   this.data = d;
    //   this.data.map(
    //     y => {
    //       if(y.approvals.approver.fyi == this.empId ){
    //         this.noData = false;
    //         y['fyistatus'] = true;
    //         this.approvallist.push(y);   
    //            console.log("fyi");
    //       }
    //       if (y.approvals.approver.approverOne == this.empId && y.approvals.approverOneStatus.listTypeValueId == 3
    //         && y.approvals.approver.approverOne) {
    //         this.noData = false;
    //         this.approvallist.push(y);  
    //         console.log("approverOne");   
    //       }
    //       if (y.approvals.approver.approverTwo == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 3) {
    //         this.noData = false;
    //         this.approvallist.push(y);
    //         console.log("approverOne");  
    //       }
    //       if (y.approvals.approver.approverThree == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 3) {
    //           this.noData = false;
    //         this.approvallist.push(y);
    //         console.log(this.approvallist);
    //         console.log("approverThree");  
    //       }
    //       if (y.approvals.approver.approverFour == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
    //         && y.approvals.approverFourStatus.listTypeValueId == 3) {
    //           this.noData = false;
    //         this.approvallist.push(y);
    //         console.log("approverFour");  
    //       }
    //       if (y.approvals.approver.approverFive == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
    //         && y.approvals.approverFourStatus.listTypeValueId == 5 && y.approvals.approverFiveStatus.listTypeValueId == 3) {
    //           this.noData = false;
    //         this.approvallist.push(y);
    //         console.log("approverFive");  
    //       }
    //       this.approvallist = this.approvallist.reduce((accumalator, current) => {
    //         console.log(accumalator);
    //         console.log(current);
    //         if (!accumalator.some(item => item === current)) {
    //           accumalator.push(current);
    //         }
    //         return accumalator;
    //       }, []);
    //       console.log("this.approvallist",this.approvallist);  
    //       if(this.approvallist.length == 0){
    //         this.noData = true;
    //         console.log("NO_DATA");
    //       }
    //     }
    //   )
    //   this.dataSource = new MatTableDataSource<any>(this.approvallist);
    //   this.dataSource.filterPredicate = (data, filter) => {
    //     const dataStr =data.employee.employeeNumber+ data.employee.firstName+data.employee.lastName + data.employee.organizationEmail 
    //     +data.startDate + data.endDate + data.noOfDays +data.reason+ data.workFromHomeStatusName;
    //     return dataStr.trim().toLowerCase().indexOf(filter) != -1;
    //   }
    //   this.dataSource.paginator = this.paginator;
    //   this.list_count = this.data.length;
    // },
    // (error) =>
    // {
    //   console.log(" Wfh emp request error");
    //   if(error.error.statusCode==204)
    //   {
    //     this.loader=false;
    //      this.noData=true;
    //      console.log("204");
    //   }
    // }
    // )
    this.fetchwfh();
  }
  fetchwfh() {
    this.loader = true;
   this.leaveservice.getWFHRequest(this.empId, 3).subscribe((d: any) => {
      this.loader = false;
      this.list_counts=true;

      this.approvallist = [];
      console.log(d);
      if (d.statusCode == 204) {
        this.noData = true;
        console.log("NoData");
      }
      console.log(d)
      this.data = d;
      this.data.map(
        y => {
          if (y.approvals.approver.fyi == this.empId) {
            this.noData = false;
            y['fyistatus'] = true;
            this.approvallist.push(y);
            console.log("fyi");
          }
          if (y.approvals.approver.approverOne == this.empId && y.approvals.approverOneStatus.listTypeValueId == 3
            && y.approvals.approver.approverOne) {
            this.noData = false;
            this.approvallist.push(y);
            console.log("approverOne");
          }
          if (y.approvals.approver.approverTwo == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            && y.approvals.approverTwoStatus.listTypeValueId == 3) {
            this.noData = false;
            this.approvallist.push(y);
            console.log("approverOne");
          }
          if (y.approvals.approver.approverThree == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 3) {
            this.noData = false;
            this.approvallist.push(y);
            console.log(this.approvallist);
            console.log("approverThree");
          }
          if (y.approvals.approver.approverFour == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
            && y.approvals.approverFourStatus.listTypeValueId == 3) {
            this.noData = false;
            this.approvallist.push(y);
            console.log("approverFour");
          }
          if (y.approvals.approver.approverFive == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
            && y.approvals.approverFourStatus.listTypeValueId == 5 && y.approvals.approverFiveStatus.listTypeValueId == 3) {
            this.noData = false;
            this.approvallist.push(y);
            console.log("approverFive");
          }
          this.approvallist = this.approvallist.reduce((accumalator, current) => {
            console.log(accumalator);
            console.log(current);
            if (!accumalator.some(item => item === current)) {
              accumalator.push(current);
            }
            return accumalator;
          }, []);
          console.log("this.approvallist", this.approvallist);
          if (this.approvallist.length == 0) {
            this.noData = true;
            console.log("NO_DATA");
          }
        }
      )
      this.dataSource = new MatTableDataSource<any>(this.approvallist);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeNumber + data.employee.firstName + data.employee.lastName + data.employee.organizationEmail
          + data.startDate + data.endDate + data.noOfDays + data.reason + data.workFromHomeStatusName;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.approvallist.length;
    },
      (error) => {
        console.log(" Wfh emp request error");
        if (error.error.statusCode == 204) {
          this.loader = false;
          this.noData = true;
          console.log("204");
        }
      }
    )
  }

  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if (this.list_count == 0) {
      this.noData = true;
    }
    else {
      this.noData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  edit(i) {
    console.log(i);
    this.router.navigate(['Home/type-edit', i.leaveTypeId]);
  }
  delete(i) {
    console.log(i)
    Swal.fire({
      title: 'Are you sure? Do you want to Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {

        this.leaveservice.deleteLeaveType(i.leaveTypeId).subscribe(
          d => {
            Swal.fire(
              'done!',
              'Your file has been deleted.',
              'success'
            )
            this.leaveservice.getLeaveType(this.organizationId).subscribe((d: any) => {
              console.log(d)
              this.data = d
              this.dataSource = new MatTableDataSource<any>(this.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              if (this.list_count < 1) {
                window.location.reload()
              }
            })
          },
          e => {
            Swal.fire(
              'Error!',
              'Somthing went wrong',
              'error'
            )
          },
          () => console.log('done')
        )
      }
      //
    })
  }
  Accept(i) {
    console.log(i);
    // this.loader = true;

   
    Swal.fire({
      title: 'Are you sure? Do you want to Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Approve!'
    }).then((result) => {
      if (result.value) {
        console.log(result.value,"approve")
        i['organization'] = {
          organizationId: this.organizationId
        }
        // i['category']={
        //   categoryId :this.categoryId
        // }
    
        // console.log(i.categoryId);
    
        let count = 0
        if (i.approvals.approver.approverOne) {
          count = count + 1
        }
        if (i.approvals.approver.approverTwo) {
          count = count + 1
        }
        if (i.approvals.approver.approverThree) {
          count = count + 1
        }
        if (i.approvals.approver.approverFour) {
          count = count + 1
        }
        if (i.approvals.approver.approverFive) {
          count = count + 1
        }
    
        if (i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3) {
          console.log(i, "22222222222222222222222222222222222222222222222222");
          i.approvals.approverOneStatus.listTypeValueId = 5
    
          i.approvals.approverlevel = 1
          i.approvals['employeeId'] = i.employee.employeeId;;
          console.log(i.approvals.approverOneStatus.listTypeValueId);
        }
        if (i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 3) {
          i.approvals.approverTwoStatus.listTypeValueId = 5
          i.approvals.approverlevel = 2
          i.approvals['employeeId'] = i.employee.employeeId;;
        }
        if (i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 3) {
          i.approvals.approverThreeStatus.listTypeValueId = 5
          i.approvals.approverlevel = 3
          i.approvals['employeeId'] = i.employee.employeeId;;
        }
        if (i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
          && i.approvals.approverFourStatus.listTypeValueId == 3) {
          i.approvals.approverFourStatus.listTypeValueId = 5
          i.approvals.approverlevel = 4
          i.approvals['employeeId'] = i.employee.employeeId;;
        }
        if (i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
          && i.approvals.approverFourStatus.listTypeValueId == 5 && i.approvals.approverFiveStatus.listTypeValueId == 3) {
          i.approvals.approverFiveStatus.listTypeValueId = 5
          i.approvals.approvalStatus.listTypeValueId = 5
          i.approvals.approverlevel = 5
          i.approvals['employeeId'] = i.employee.employeeId;;
          i.workFromHomeStatus = 5
        }
        if (count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5) {
          i.workFromHomeStatus = 5
          i.approvals.approvalStatus.listTypeValueId = 5
        }
        if (count == 2 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5) {
          i.workFromHomeStatus = 5
          i.approvals.approvalStatus.listTypeValueId = 5
        }
        if (count == 3 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
          && i.approvals.approverThreeStatus.listTypeValueId == 5) {
          i.workFromHomeStatus = 5
          i.approvals.approvalStatus.listTypeValueId = 5
        }
        if (count == 4 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
          && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5) {
          i.workFromHomeStatus = 5
          i.approvals.approvalStatus.listTypeValueId = 5
        }
        if (count == 5 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
          && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5 && i.approvals.approverFiveStatus.listTypeValueId == 5) {
          i.workFromHomeStatus = 5
          i.approvals.approvalStatus.listTypeValueId = 5
        }
    
        this.loader = true
        // i['workFromHomeStatus'] = 5
        this.leaveservice.Actionwfh(i.workFromHomeId, i).subscribe(d => {
          
          // this.isLoading = false
          // Swal.fire(
          //   "Done",
          //   'Approved!',
          // )
          this.success = true;
          this.success_msg = 'Work From Home Approved Successfully';
          this.loader = false;
          setTimeout(() => { this.success = false;
            // this.list_count=0;
            // this.approvallist = [];
          this.fetchwfh();
           }, 3000);
          
          // this.afterRefresh();
          //  window.location.reload()
        },
          err => {
            // this.isLoading = false
            // Swal.fire(
            //   'error!',
            //   err.error.message
            // )
            this.error = true;
            this.error_msg = err.error.message;
            this.loader = false
            setTimeout(() => { this.error = false; }, 3000);
          }

        );
      } else {
        this.loader = false;

      }
      //
    })
  }
  denied(i) {

    console.log(i);
    // this.loader = true;
 


    // leaveStatus
    Swal.fire({
      title: 'Are you sure? Do you want to Deny',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Deny'
    }).then((result) => {
      if (result.value) {
        console.log(result.value,"deny")

       
        console.log(i);

        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.loader = true
              i['workfromhomedenyReason'] = result.denyReason;

              i['organization'] = {
                organizationId: this.organizationId
              }
              // i['category']={
              //   categoryId :this.categoryId
              // }
          
              if (i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3) {
                i.approvals.approverOneStatus.listTypeValueId = 6
                console.log("approverone status");
                console.log(i.approvals.approverOneStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 1
                i.approvals['employeeId'] = i.employee.employeeId;;
              }
              if (i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5) {
                i.approvals.approverTwoStatus.listTypeValueId = 6
                console.log("approverTwoStatus");
                console.log(i.approvals.approverTwoStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 2
                i.approvals['employeeId'] = i.employee.employeeId;;
              }
              if (i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5) {
                i.approvals.approverThreeStatus.listTypeValueId = 6
                console.log("approverThreeStatus");
                console.log(i.approvals.approverThreeStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 3
                i.approvals['employeeId'] = i.employee.employeeId;;
              }
              if (i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
                && i.approvals.approverThreeStatus.listTypeValueId == 5) {
                i.approvals.approverFourStatus.listTypeValueId = 6
                console.log("approverFourStatus");
                console.log(i.approvals.approverFourStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 4
                i.approvals['employeeId'] = i.employee.employeeId;;
              }
              if (i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
                && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5) {
                i.approvals.approverFiveStatus.listTypeValueId = 6
                console.log("approverFiveStatus");
                console.log(i.approvals.approverFiveStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 5
                i.approvals['employeeId'] = i.employee.employeeId;;
              }
              i['workFromHomeStatus'] = 6

              this.leaveservice.Actionwfh(i.workFromHomeId, i).subscribe(d => {
                // this.isLoading = false;
                // Swal.fire({
                //   text:'Denied',
                //   icon:'success'
                // })
                this.success = true;
                this.success_msg = 'Work From Home Denied Successfully';
                this.loader = false
                setTimeout(() => { this.success = false;
                  // this.list_count=0;
                  // this.approvallist = [];
                  this.fetchwfh();
                 }, 3000);
               
                // window.location.reload();
                // this.afterRefresh();
              },
                err => {
                  // this.isLoading = false;
                  // Swal.fire({
                  //    text:err.error.message,
                  //    icon:'error'
                  // })
                  this.error = true;
                  this.error_msg = err.error.message;
                  this.loader = false;
                  setTimeout(() => { this.error = false; }, 3000);
                }
              );
            }
            else {
              this.loader = false;

            }
          });
        }
      }
    })
  }

  afterRefresh() {
    console.log("refersh");
    this.leaveservice.getWFHRequest(this.mail, 3).subscribe((d: any) => {
      this.loader = false;
      console.log(d);
      if (d.statusCode == 204) {
        this.noData = true;
        console.log("NoData");
      }
      console.log(d)
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.userType.userType
          + data.startDate + data.endDate + data.noOfDays + data.workFromHomeStatusName;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length;
    },
      (error) => {
        console.log(" Wfh emp request error");
        if (error.error.statusCode == 204) {
          this.loader = false;
          this.noData = true;
          console.log("204");
        }
      }
    )
  }
  Reason(i) {
    console.log(i);
    this.dialog.open(Reason, {
      width: '300px',
      data: i.reason
    });
  }

  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if (i == 'Cancelled') {
      return '#e40000'
    }
  }

  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}



