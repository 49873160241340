import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SuperadminService } from '../providers/superadmin.service';

@Component({
  selector: 'app-listentity',
  templateUrl: './listentity.component.html',
  styleUrls: ['./listentity.component.scss']
})
export class ListentityComponent implements OnInit {
  searchForm:FormGroup;
  entityNames: any;
nodatafound: any;
resultData: any=[];
tableShow: boolean=true;
pagePerItem: number=5;
p: string|number;
tableSearch: any;
  success: boolean;
  sucess_msg: any;
  error: boolean;
  error_msg: any;
  mySelect:number=5;
search: any;
  constructor( private router:Router,
               private formBuilder:FormBuilder,
               private SuperadminService:SuperadminService
  ) { }

  ngOnInit(): void {
    this.searchForm=this.formBuilder.group({
      entityName:['',Validators.required]


    })  
    this.entityAllList();
  }

  entityAllList(){

    this.SuperadminService.entityAll().subscribe((response:any)=>{
      this.resultData=response;
    })
  }
    updateStatus(id,status)
    {
      this.SuperadminService.updateEntityStatus(id,status).subscribe((result:any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
          const updatedRow = this.resultData.find((row) => row.entityId === id);
          if (updatedRow) {
            updatedRow.entityStatus = status; // Update the status in the current data
          }
          setTimeout(() => {this.success  = false;},5000)
  
          this.entityAllList();
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, 5000)
        }
      })
    }
    changefirstpage(){
      this.p=1;
    }
  searchSubmit(){
    console.log(this.searchForm.value);
    
    console.log("entity name",this.searchForm.controls['entityName'].value);

    this.entityNames=this.searchForm.controls['entityName'].value
    console.log(this.entityNames);
    

    if(this.entityNames=="" || this.entityNames==undefined || this.entityNames==null){
      alert("Please type the Entity Name to Search!")
      return;
    }

    if(this.entityNames!=="" && this.entityNames!==undefined){
      console.log("entity name Enter");
      this.SuperadminService.getSearchEntityNames(this.entityNames).subscribe((resultData: any) =>
        {
          console.log(resultData);
          
          if (resultData && resultData.length > 0) {
            this.tableShow = true;
            console.log("Search result:", resultData);
            this.resultData = resultData;  
            console.log(" this.resultData::", this.resultData);
            
    
            this.nodatafound = false;

            
          } 
          else {
            console.log("else came!!!");
            if(resultData.statusCode==204){
              console.log("no data!!");
              this.nodatafound=true;
              this.tableShow=true;
              this.resultData=[];
            }
          //  if(resultData.statusCode==204){
          //   console.log("no data!!");
            
          //   this.nodatafound=true;
          //   this.tableShow=true;
          //   this.resultData=[];
          // }
          }
        });
      }
          this.nodatafound=false;
}
clearTableData(){
  console.log("keydown fun came!!!");
  
  this.entityAllList();
  this.nodatafound=false;

}
  clearData(){
    this.searchForm.reset();
    this.entityAllList();
    this.nodatafound=false;


  }
  Add_client(){
    this.router.navigateByUrl("/super-admin-home/add-entity");

  }
}
