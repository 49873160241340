<!-- <section>
    <div class="sub-header" style="height: 68px;">
            <div class="row">
                <div class="col-md-2">
                    <span class="h5 sub-content headingDesing">Onboardings</span>
                </div>
                <div class="col-md-4" style="    position: relative;
    top: 7px;">
                   
                            <div class="sidebar-search">
                                <div class="input-group">
                                    <input type="input" class="form-control search-menu search-placeholder" placeholder="Search..." [(ngModel)]="search" (keyup)="changepage()" name="searchText"/>
                                </div>
                            </div>
                        </div>
                <div class="col-md-6 btnDesignset" >
                    <button *ngIf="resultData?.length > 0" (click)="exportToExcel()" type="button" mat-raised-button color="primary" class="download-button ml-2 btnDesigns">
                        Export
                    </button>
                    <a routerLink="/home/add-onboarding" routerLinkActive="active" *ngIf="isAdminReqruiter" mat-raised-button title="Add Position" class="ml-2 btnDesign">
                        Add Onboarding
                    </a>
                </div>

    
        </div>
    </div>
</section> -->

<!-- <nav class="navbar bg" style="margin-top: 67px">
      <div class="row w-100 ">
        <div class="col-md-3">
          <h4 class="heading-tag" style="    position: relative;
      left: 5px;">Onboardings</h4>
        </div>
  
        <div class="col-md-5">
          <div class="input-group">
            <input
              type="input"
              class="form-control search-menu search-placeholder"
              placeholder="Search..."
              [(ngModel)]="search"
              (keyup)="changepage()"
              name="searchText"
            />
          </div>
        </div>
  
        <div class="col-md-4  d-flex justify-content-end">
            <button *ngIf="resultData?.length > 0 && (resultData | mytask : search).length > 0" (click)="exportToExcel()" type="button" mat-raised-button color="primary" class="download-button ml-2 btnDesigns">
                Export
            </button>
            <a routerLink="/home/add-onboarding" routerLinkActive="active" *ngIf="isAdminReqruiter" mat-raised-button  class="ml-2 btnDesign">
                Add Onboarding
            </a>
        </div>
    </div>
  </nav> -->

  <nav class="navbar bg" style="margin-top: 67px;">
    <div class="container-fluid">
        <div class="row w-100">
            <div class="col-sm-4 col-md-3 d-flex align-items-center mb-2 mb-sm-0">
                <h4 class="heading-tag" style="position: relative; left: 5px;">
                    Onboardings
                </h4>
            </div>

            <div class=" col-sm-8 col-md-9 d-flex justify-content-end align-items-center" style="  position: relative;
            top: -4px;
            ">
                <div class="input-group me-3" style="max-width: 400px;">
                    <input type="text" [(ngModel)]="search"
                        autocomplete="off"
                        class="form-control form-control-lg form-control-solid search-input email-input select-placeholder"
                        name="keywords"
                        value=""
                        placeholder="Search...">
                </div>

                <div class="d-flex"  style="height: 38px;
                    padding-top: 5px !important;margin-top: -4px;">
                     <button *ngIf="resultData?.length > 0 && (resultData | mytask : search).length > 0" (click)="exportToExcel()" type="button" mat-raised-button color="primary" class="download-button ml-2 btnDesigns">
                        Export
                    </button>
                    <a routerLink="/home/add-onboarding" routerLinkActive="active" *ngIf="isAdminReqruiter" mat-raised-button  class="ml-2 btnDesign">
                        Add Onboarding
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>

<!-- Sub header end here-->


<mat-card
  style="
    border-radius: 15px;
    border: 2px solid #ddd !important;
    background: #fdfdfd;
    height: auto;
  "
>

<div class="container">
    <div class="row">
        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
            <div class="row mb-3 pl-0">
                <div class="col-md-4 mb-2">
                    <!-- [(ngModel)]="tableSearch"  -->
                    <label class="form-control-label">Work Location</label>

                    <input formControlName="keywords" type="text" autocomplete="off" (keydown)="preventSpaces($event)"  (input)="validateInput($event)" class="form-control email-input select-placeholder" value="" placeholder="Search...">
                    <small class="text-muted">Note : Work Location</small>
                    <!-- <div *ngIf="keywords.dirty">
                        <span *ngIf="keywords.errors?.pattern" class="text-danger">Please Enter Valid  Work Location</span>   
                    </div>  -->
                </div>

                <div class="col-md-4 mb-2">
                    <!-- <select formControlName="resourceId" (change)="selectResource($event);" class="form-select form-select-solid form-select-lg">
                        <option value="">- Select Resource -</option>
                        <option *ngFor="let resource of resourceData; let i=index;" value="{{resource.resourceId}}">
                            {{ucFirst(resource.resourceName)}} - {{resource.resourceNumber}}</option>
                    </select> -->
                    <label class="form-control-label">Resource</label>

                    <mat-select
                    id="framework"
                    class="form-control email-input select-placeholder"
                    formControlName="resourceId" 
                    placeholder="Select Resource" 
                     
                  >
                    <mat-option [value]=null  selected="selected">- Select Resource -</mat-option>
                    <!-- <mat-option [value]=0> In-House</mat-option> -->

                    
                    <mat-option
                    *ngFor="let resource of resourceData; let i=index;" value="{{resource.resourceId}}">
                    {{ucFirst(resource.resourceName)}} - {{resource.resourceNumber}}
                    </mat-option>
                  </mat-select>
                    <!-- <select formControlName="resourceId" placeholder="- Select Resource -" class="form-select form-select-solid form-select-lg select2-new">
                        <option [value]="null" selected="selected"> - Select Resource - </option>
                        <option *ngFor="let resource of resourceData; let i=index;" value="{{resource.resourceId}}">{{ucFirst(resource.resourceName)}} - {{resource.resourceNumber}}</option>
                    </select> -->
                </div>

                <!-- <div class="ol-xl-1 col-lg-1 mb-2">
                    <button type="submit" mat-raised-button  class="btn btn-primary search-btn">Search</button>
                </div> -->
                <div class="col-md-4 mb-3" style="    position: relative;
    top: 20px;">
                    <button type="submit" mat-raised-button   class=" search-btn searchBtn" [disabled]="btnDisables" style="border: 0px !important;background:#1a83ff;color:#ffff">Search</button>
                    <button style="margin-left: 10px;background: none; color: black;    border: 1px solid #ddd !important;" type="button" mat-raised-button
                    class="btn-clear search-btn mr-2" (click)="clearforms()"  [disabled]="btnDisables"
                     >Clear</button>
                </div>
            </div>
        </form>

    </div>
  </div>
  <div class="card-content">
    <!-- *ngIf="momList.length > 5" -->
    <div class="row p-0 m-0"    *ngIf="resultData?.length > 5 && (resultData | mytask : search)?.length > 0" >
      <div class="col-xl-12 col-lg-12" style="margin-top: -36px"  *ngIf="(resultData | mytask : search)?.length > 5">
        <div class="select-pagination">
          <span>Show : </span>
          <select
            class="select_list new-select_list"
            [(ngModel)]="mySelect"
            (change)="showChanges()"
          >
            <option class="form-control" value="5">5</option>
            <option class="form-control" value="10">10</option>
            <option class="form-control" value="15">15</option>
            <option class="form-control" value="20">20</option>
            <option class="form-control" value="25">25</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive" style="margin-top: 0px">
        <table matSort (matSortChange)="sortData($event)" class="table table-responsive" *ngIf="tableShow">
            <thead class="t-head">
                <tr>
                    <th class="table-head text-center"  style="border-radius: 15px 1px 0px 0">Action</th>
                    <th mat-sort-header="resourceName" class="table-head">Resource</th>
                    <th mat-sort-header="hiringFor" class="table-head date-width">Hiring For</th>
                    <th mat-sort-header="approvedCtc" class="table-head text-center">Approved CTC</th>
                    <th mat-sort-header="workLocation" class="table-head">Work Location</th>
                    <th mat-sort-header="clientName" class="table-head date-width">Client Name</th>
                    <th mat-sort-header="offerReleasedDate" class="table-head text-center date-width">Offer Released Date</th>
                    <th mat-sort-header="offerAcceptedDate" class="table-head text-center date-width">Offer Accepted Date</th>
                    <th mat-sort-header="waitingPeriod" class="table-head text-center">Waiting Period</th>
                    <th mat-sort-header="dateOfJoining" class="table-head text-center date-width">Expected Date Of Joining</th>
                    <th mat-sort-header="emailConfirmation" class="table-head text-center">Email Confirmation</th>
                    <th class="table-head text-center" style="border-radius: 0px 15px 0px 0px">Status</th>
                </tr>
            </thead>
            <tbody *ngIf="resultData?.length > 0 " style="    border-bottom: 1px solid #ddd !important;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;">
                <!--  <tr *ngFor="let e of onboardinglistdata | searchFilter: query | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                    -->
                <tr *ngFor="let e of resultData | mytask : search
  | paginate : { itemsPerPage: mySelect, currentPage: p }; let i=index;">
                    <td class="text-center">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        <mat-menu #menu="matMenu" class="controls-list">
                            <button mat-menu-item routerLink="/home/edit-onboarding/{{e.onboardingId}}" routerLinkActive="active" >
                                <mat-icon >edit</mat-icon>
                                <span>Edit</span>
                                </button>
                                <button mat-menu-item routerLink="/home/onboardingdetail/{{e.onboardingId}}" routerLinkActive="active">
                                    <mat-icon>visibility</mat-icon>
                                    <span>View</span>
                                    </button>



                            <!-- <button mat-menu-item *ngIf="e.status.listTypeValueId == 1" (click)="updateStatus(e.onboardingId,2)">
                                    <mat-icon>done</mat-icon>
                                    <span>Inactive</span>
                                </button>

                            <button mat-menu-item *ngIf="e.status.listTypeValueId ==2" (click)="updateStatus(e.onboardingId,1)">
                                    <mat-icon>done</mat-icon>
                                    <span>Active</span>
                                </button> -->
                                <button mat-menu-item *ngIf="  e.status.listTypeValueId == 1" (click)="updateStatus(e.onboardingId,2)" style="border: none;">
                                    <mat-icon>done</mat-icon>
                                    <span class="matIcondesign" style="color:red;">Inactive</span>
                                </button>

                                <button mat-menu-item *ngIf=" e.status.listTypeValueId == 2" (click)="updateStatus(e.onboardingId,1)" style="border: none;">
                                    <mat-icon>done</mat-icon>
                                    <span  class="matIcondesign" style="color:green;">Active</span>
                                </button>
                                <!-- <mat-icon _ngcontent-wwp-c277="" role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                                style="    position: relative;
                                top: 10px;
                                left: 10px;
                                color: rgba(0,0,0,.54);" aria-hidden="true" data-mat-icon-type="font">done</mat-icon>
                                <button mat-icon-button (click)="toggleStatus(e)" class="menu-button maticonHover"
                                [ngClass]="{ 'text-danger': e.status?.listTypeValueName === 'Active', 'text-success': e.status?.listTypeValueName === 'In-Active' }"
                                 style="padding-left:33px; box-sizing: border-box; font-size: 15px;" >
                                
                                    {{ e.status.listTypeValueName === 'Active' ? 'Inactive' : 'Active' }} 
                                    
                                </button> -->

                        </mat-menu>
                    </td>
                    <td class="table-data">{{ucFirst(e.resource.resourceName)}}</td>
                    <td class="table-data">{{e.hiring.listTypeValueName}}</td>
                    <td class="table-data text-center">{{e.approvedCtc}}</td>
                    <td class="table-data">{{ucFirst(e.workLocation)}}</td>
                    <!-- <td *ngIf="e.client ==undefined" class="table-data">In-House</td> -->
                    <td *ngIf="!e?.clientId || e?.clientId === 0" class="table-data">In-House</td>

<td *ngIf="e?.clientId && e?.clientId !== 0" class="table-data">
  {{ e?.clientId?.clientName ? ucFirst(e?.clientId?.clientName) : '-' }}
</td>

                    <td class="table-data text-center">
                        <!-- {{e.offerReleasedDate.slice(0,10)}}
                            {{e.offerReleasedDate | date: 'dd-MMM-yyyy'}} -->
                        {{e.offerReleasedDate ? (e.offerReleasedDate | date: dateFormat) :""}}
                    </td>
                    <td class="table-data text-center">
                        <!-- {{e.offerAcceptedDate.slice(0,10)}} -->
                        {{e.offerAcceptedDate ? (e.offerAcceptedDate | date: dateFormat) :""}}
                    </td>

                    <td class="table-data text-center">{{e.waitingPeriod}}</td>
                    <td class="table-data text-center">
                        <!-- {{e.dateOfJoining.slice(0,10)}} -->
                        {{e.dateOfJoining ? (e.dateOfJoining | date: dateFormat) :""}}
                    </td>
                    <td class="table-data text-center" *ngIf="e.emailConfirmation == 'true'">Yes</td>
                    <td class="table-data text-center" *ngIf="e.emailConfirmation == 'false'">No</td>
                    <!-- <td class="active-status text-center">
                        <span class=" btn-outline-success " *ngIf="e.status.listTypeValueId == 1">
                                {{e.status.listTypeValueName}}
                            </span>

                        <span class=" btn-outline-danger " *ngIf="e.status.listTypeValueId == 2">
                                {{e.status.listTypeValueName}}
                            </span>
                    </td> -->

                    <!-- <td *ngIf="e.status.listTypeValueName =='Active'" class=" table-data text-center text-success"  style="    padding: 15px 10px 4px 9px;">
                        <ul id="nav" >
                            <li class="text-center dropdown-list" style="list-style: none;">
                                <a class="text-success dropdown-list-status1 text-center" style="margin: 0px 30px 0px 20px;" *ngIf="e.status.listTypeValueName =='Active'">{{e.status.listTypeValueName}}</a>
                              
                                <ul  class="dropdown-item" (click)="statuschangeactive(e.onboardingId)" *ngIf="setInactiveNo">
                                    <li class="material-menu">
                                        <a class="text-danger dropdown-item-status2">In-Active</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </td> -->
                    <!-- <td *ngIf="e.status.listTypeValueName =='In-Active'" class="table-data text-center text-danger"  style="    padding: 15px 10px 4px 9px;">
                        <ul id="nav">
                            <li class="text-center dropdown-list" style="list-style: none;">
                                <a class="text-danger dropdown-list-status1 text-center"style="margin: 0px 30px 0px 20px;" *ngIf="e.status.listTypeValueName =='In-Active'">{{e.status.listTypeValueName}}</a>
                               
                                <ul class="dropdown-item" (click)="statuschangeinactive(e.onboardingId)"  *ngIf="setActiveNo">
                                    <li class="material-menu">
                                        <a class="text-success dropdownitem-status2">Active</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </td> -->

                    <td class="active-status text-center">
                        <span class=" btn-outline-success " *ngIf="e.status.listTypeValueId == 1">
                            {{e.status.listTypeValueName}}
                        </span>

                        <span class=" btn-outline-danger " *ngIf="e.status.listTypeValueId == 2">
                            {{e.status.listTypeValueName}}
                        </span>
                    </td>


                </tr>
            </tbody>
            <!-- <tbody>
                <tr>
                    <td style="text-align: center;" colspan="9" *ngIf="nodata">No Records Found!</td>
                </tr>
            </tbody> -->
            <tbody style="    border-left: 1px solid #ddd;
border-right: 1px solid #ddd;">
                <tr>
                    <td   style="
                    text-align: center;
                    font-size: larger;
                    font-weight: bold;
                  " colspan="9"  *ngIf="nodata || (resultData | mytask : search).length === 0 && search || resultData.length == 0 ">Onboardings Records Not Found!</td>
                </tr>
            </tbody>
        </table>

      <div
      class="pagination-content"
      *ngIf="resultData?.length > 5 && (resultData | mytask : search).length > 0"
      id="page"
    >
      <pagination-controls  *ngIf="(resultData | mytask : search).length > 5"
        style="position: relative; float: inline-end;top: 7px;"
        (pageChange)="p = $event"
        align="end"
      ></pagination-controls>
    </div>
    </div>
    

    </div>
</mat-card>


<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div  *ngIf="loader"  class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status" style="    height: 3rem;
    width: 3rem;
    top: 51%;"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
