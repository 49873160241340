import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../providers/admin.service';
import { PasswordValidators } from '../password-validators';
import { SuperadminService } from '../providers/superadmin.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-clientcreation',
  templateUrl: './clientcreation.component.html',
  styleUrls: ['./clientcreation.component.scss']
})
export class ClientcreationComponent implements OnInit {
  clientId: any;
  postForm: FormGroup;
  submitted1: boolean = false;
  activeCountryData: any;
  activeTimeZoneData: any;

  stateList: any;
  districtList: any;
  cityList: any;

  industryTypeLov: any;
  userType: any;

  editData: any;
  organizationNameExistStatus: any;
  contactNumberExistStatus: any;
  contactEmailExistStatus: any;
  password;
  show = false;
  dateFormatId: any;

  filename: any;
  fileByte: any;
  fileSize: any;
  fileType: any;
  profileImage: any;
  fileblob: any;
  fileId: any;
  allSelected: any;
  autoCompleteCountrySearch: any = 0; //Auto Complete Search
  countryData: any;

  autoCompleteStateSearch: any = 0; //Auto Complete Search
  stateData: any;

  autoCompleteDistrictSearch: any = 0; //Auto Complete Search
  districtData: any;

  autoCompleteCitySearch: any = 0; //Auto Complete Search
  cityData: any;
  businessUnitlist: any = [];
  supplierList: any = [];
  storeOrgId: any;
  createdId: any;
  success: boolean;
  sucess_msg: any;
  addClientButton: boolean = false;
  update_error_msg: any;
  error: any;
  clientIDs: any;
  countryIds: any;
  storeStaus: any;
  stateIds: any;
  districtIds: any;
  cityIds: any;
  countryId: any;
  stroeEntityId: any;
  submitted:boolean=false
  loader:boolean=false;
  constructor(
    private AdminService: AdminService,
    private superadmin: SuperadminService,
    private formBuilder: FormBuilder,
    private router: Router,
    private ActivatedRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {

    const userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("userdata",userdata);
    // this.storeOrgId=userdata.organization.organizationId;
    console.log("this.storeOrgId",this.storeOrgId);
    this.storeOrgId = userdata?.organization?.organizationId || null;

    this.createdId=userdata.userId;
    this.stroeEntityId=userdata.entity.entityId
    console.log("this.created id:",this.createdId);
    

    this.postForm = this.formBuilder.group({
      businessUnits: ['', [Validators.required]],
      // clientCode: ['', [Validators.required]],
      clientCode: ['', [Validators.required, Validators.pattern("^(?![ ])[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      clientName: ['', [Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      addressLine1: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      // addressLine2: [null],
      addressLine2: [
        null,
        [
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      // description: [null],
      description: [
        null,
        [
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],      postalCode: [null, [Validators.pattern('^[0-9.-]+$')]],

      countryName: ['', [Validators.required]],
      countryId: ['', [Validators.required]],

      stateName: ['', [Validators.required]],
      stateId: ['', [Validators.required]],

      districtName: ['', [Validators.required]],
      districtId: ['', [Validators.required]],

      cityName: ['', [Validators.required]],
      cityId: ['', [Validators.required]],
    });

    this.AdminService.getAllEntityBusinessUnit(this.stroeEntityId).subscribe((response: any) => {
      this.businessUnitlist = response;
    })



    this.clientIDs = this.ActivatedRoute.snapshot.params.clientId
    console.log("this.entityId::", this.clientIDs);
   
    if (this.clientIDs) {
      this.loader=true;
    this.addClientButton=true
      this.postForm = this.formBuilder.group({
        businessUnits: ['', [Validators.required]],
        clientCode: ['', [Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
        clientName: ['', [Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
        addressLine1: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
        // addressLine2: [null],
        addressLine2: [
          null,
          [
            Validators.pattern(
              "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
            ),
          ],
        ],
        // description: [null],
        description: [
          null,
          [
            Validators.pattern(
              "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
            ),
          ],
        ],
        postalCode: [null, [Validators.pattern('^[0-9.-]+$')]],

        countryName: ['', [Validators.required]],
        countryId: ['', [Validators.required]],

        stateName: ['', [Validators.required]],
        stateId: ['', [Validators.required]],

        districtName: ['', [Validators.required]],
        districtId: ['', [Validators.required]],

        cityName: ['', [Validators.required]],
        cityId: ['', [Validators.required]],
      });

      this.AdminService.getClientbyId(this.clientIDs).subscribe((response: any) => {
        this.loader=false;
        this.addClientButton=false
        console.log("edit response::", response);


        const editValue = response;
        this.countryId = editValue?.country?.countryId;
        this.stateIds = editValue?.state?.stateId;
        this.districtIds = editValue?.disrict?.disrictId;
        this.cityIds = editValue?.city?.cityId;
        this.storeStaus = editValue?.status?.listTypeValueId
        console.log("business name::", this.countryIds);


        this.postForm.patchValue({

          clientName: editValue.clientName,
          clientCode: editValue.clientCode,
          addressLine1: editValue.addressLine1,
          addressLine2: editValue.addressLine2,
          description: editValue.clientDescription,

          countryName: editValue.country.countryName,
          countryId: editValue.country.countryId,


          stateName: editValue.state.stateName,
          stateId: editValue.state.stateId,

          districtName: editValue.district.districtName,
          districtId: editValue.district.districtId,

          cityName: editValue.city.cityName,
          cityId: editValue.city.cityId,

          postalCode: editValue.postalCode,

          businessUnits: editValue.businessUnit.businessUnitId


        })

      })

    }




  }
  businessunitChange(event:any){
    if(event.target){
      this.submitted=false;
    }
    else{
      this.submitted=true;
    }
  }
  get validate(){
    return this.postForm.controls;
  }
  business() {
    this.postForm.controls['businessUnits'].setValue(null);

  }
  stateAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let countryId = this.postForm.value.countryId;

    if (!countryId) {
      alert('Please! First select country.');
      this.postForm.patchValue({
        stateName: '',
        stateId: '',
      });
      return;
    }

    if (keywords) {
      this.autoCompleteStateSearch = 0;

      this.AdminService.stateAutoCompleteSearch(countryId, keywords).subscribe(
        (result: any) => {
          this.stateData = result;

          if (this.stateData.statusCode == 204) {
            this.autoCompleteStateSearch = 1; //No data Found
            this.postForm.patchValue({
              //employeeName : firstName+" "+lastName,
              stateId: '',
            });
          } else {
            this.autoCompleteStateSearch = 0;
          }
        }
      );
    } else {
      this.autoCompleteStateSearch = 1;
      this.postForm.patchValue({
        stateId: '',

        districtName: '',
        districtId: '',

        cityName: '',
        cityId: '',
      });
    }
  }
  districtAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let stateId = this.postForm.value.stateId;

    if (!stateId) {
      alert('Please! First select state.');
      this.postForm.patchValue({
        districtName: '',
        districtId: '',
      });
      return;
    }

    if (keywords) {
      this.autoCompleteDistrictSearch = 0;

      this.AdminService.districtAutoCompleteSearch(stateId, keywords).subscribe(
        (result: any) => {
          this.districtData = result;

          if (this.districtData.statusCode == 204) {
            this.autoCompleteDistrictSearch = 1; //No data Found
            this.postForm.patchValue({
              districtId: '',
            });
          } else {
            this.autoCompleteDistrictSearch = 0;
          }
        }
      );
    } else {
      this.autoCompleteDistrictSearch = 1;
      this.postForm.patchValue({
        districtId: '',

        cityName: '',
        cityId: '',
      });
    }
  }
  cityAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let districtId = this.postForm.value.districtId;

    if (!districtId) {
      alert('Please! First select district.');
      this.postForm.patchValue({
        cityName: '',
        cityId: '',
      });
      return;
    }

    if (keywords) {
      this.autoCompleteCitySearch = 0;

      this.AdminService.cityAutoCompleteSearch(districtId, keywords).subscribe(
        (result: any) => {
          this.cityData = result;

          if (this.cityData.statusCode == 204) {
            this.autoCompleteCitySearch = 1; //No data Found
            this.postForm.patchValue({
              cityId: '',
            });
          } else {
            this.autoCompleteCitySearch = 0;
          }
        }
      );
    } else {
      this.autoCompleteCitySearch = 1;
      this.postForm.patchValue({
        cityId: '',
      });
    }
  }
  getFilterData(id, filterName, type) {
    if (type == 1) {
      //Country
      this.autoCompleteCountrySearch = 1;

      if (id) {
        this.postForm.patchValue({
          countryName: filterName,
          countryId: id,
        });

        this.postForm.patchValue({
          stateName: '',
          stateId: '',

          districtName: '',
          districtId: '',

          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 2) {
      //State
      this.autoCompleteStateSearch = 1;

      if (id) {
        this.postForm.patchValue({
          stateName: filterName,
          stateId: id,
        });

        this.postForm.patchValue({
          districtName: '',
          districtId: '',

          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 3) {
      //district
      this.autoCompleteDistrictSearch = 1;

      if (id) {
        this.postForm.patchValue({
          districtName: filterName,
          districtId: id,
        });

        this.postForm.patchValue({
          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 4) {
      //city
      this.autoCompleteCitySearch = 1;

      if (id) {
        this.postForm.patchValue({
          cityName: filterName,
          cityId: id,
        });
      }
    }
  }
  countrySelect(country) {
    console.log(country);
    this.countryId = country.countryId;
    this.postForm.patchValue({
      country: country.countryName
    });
    console.log("this.clientform", this.postForm);
  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  countryAutoCompleteSearch(event) {
    // let keywords = event.target.value;
    // console.log("keywords:",keywords);
    

    // if (keywords) {
    //   this.autoCompleteCountrySearch = 0;

    //   this.AdminService.countryAutoCompleteSearch(keywords).subscribe(
    //     (result: any) => {
    //       this.countryData = result;
    //       console.log("this.country data:",this.countryData);
          

    //       this.postForm.patchValue({
    //         countryId: '',
    //       });

    //       if (this.countryData.statusCode == 204) {
    //         this.autoCompleteCountrySearch = 1; //No data Found
    //         this.postForm.patchValue({
    //           countryId: '',
    //         });
    //       } else {
    //         this.autoCompleteCountrySearch = 0;
    //       }
    //     }
    //   );
    // } else {
    //   this.autoCompleteCountrySearch = 1;
    //   this.postForm.patchValue({
    //     countryId: '',

    //     stateName: '',
    //     stateId: '',

    //     districtName: '',
    //     districtId: '',

    //     cityName: '',
    //     cityId: '',
    //   });
    // }
    let keywords = event.target.value;
    const textarea = event.target.value;
  console.log("keywords::", textarea);

  // Check if the textarea is empty
  if (textarea.trim() === '') {
    this.autoCompleteCountrySearch = 1; // Hide the list if the input is empty
    this.postForm.patchValue({
      countryId: '',
      stateName: '',
        stateId: '',

        districtName: '',
        districtId: '',

        cityName: '',
        cityId: '',
    });
    return; // Exit the function early
  }
    this.AdminService.countryAutoCompleteSearch(textarea).subscribe(
      (result: any) => {
        this.countryData = result;
        console.log("this.country data:", this.countryData);
  
        this.postForm.patchValue({
          countryId: '',
        });
  
        if (this.countryData.statusCode == 204) {
          this.autoCompleteCountrySearch = 1; // No data Found
          this.postForm.patchValue({
            countryId: '',
          });
          this.postForm.controls['countryId'].reset();
          this.postForm.controls['countryName'].reset();
          return;
        } else {
          this.autoCompleteCountrySearch = 0;
        }
      }
    );
  }

  setSupplier() {
    this.postForm.controls['supplierName'].setValue(null);

  }
  submitClient() {

    this.submitted1 = true;
    this.submitted=true;
    console.log('client form::', this.postForm);
    // return;
    if (this.postForm.invalid) {
      
      return;
    }
    let postValues = this.postForm.value;


    if(this.clientIDs){

const Putpayload={
  clientId:Number(this.clientIDs),
      clientName:postValues.clientName,
      clientCode:postValues.clientCode,
      addressLine1:postValues.addressLine1,
      addressLine2:postValues.addressLine2,
      clientDescription:postValues.description,
      country:{
        countryId:postValues.countryId
      },
      state:{
        stateId:postValues.stateId
      },
      district:{
        districtId:postValues.districtId
      },
      city:{
        cityId:postValues.cityId
      },
      postalCode:postValues.postalCode,
      // organization:{
      //   organizationId:this.storeOrgId
      // },
      organization: this.storeOrgId ? { organizationId: this.storeOrgId } : null,

      createdBy:this.createdId,
      businessUnit:{
        businessUnitId:postValues.businessUnits
      },
      status:this.storeStaus

    }
    console.log("payload put::",Putpayload);
    this.addClientButton=true

    this.AdminService.updateClient(this.clientIDs,Putpayload).subscribe((response:any)=>{
      console.log(response);
      this.addClientButton=true

      if(response.statusCode==200){
        this.success = true;
        this.addClientButton=true
        this.sucess_msg = response.message;
        this.addClientButton = true;
        setTimeout(() => {
          this.success = true;
          this.addClientButton = true;
          this.router.navigate(['home/organization']);
        }, 5000)
      }
      if(response.statusCode==409){
        this.error=true;
        this.addClientButton = false;

        this.update_error_msg=response.message;

        setTimeout(()=>{
          this.error=false;
        },5000)

      }
      if(response.statusCode==404){
        this.error=true;
        this.addClientButton = false;

        this.update_error_msg=response.message;

        setTimeout(()=>{
          this.error=false;
        },5000)

      }
      
    },
    (err:any)=>{
      this.error=true;
      this.update_error_msg=err.error.message;
      this.addClientButton=false;

      setTimeout(()=>{
        this.error=false;
        this.addClientButton=false
      },5000)


    }
  )
    }
   
    else {


      const payload = {
        clientName: postValues.clientName,
        clientCode: postValues.clientCode,
        addressLine1: postValues.addressLine1,
        addressLine2: postValues.addressLine2,
        clientDescription: postValues.description,
        country: {
          countryId: postValues.countryId
        },
        state: {
          stateId: postValues.stateId
        },
        district: {
          districtId: postValues.districtId
        },
        city: {
          cityId: postValues.cityId
        },
        postalCode: postValues.postalCode,
        organization: this.storeOrgId ? { organizationId: this.storeOrgId } : null,

        createdBy: this.createdId,
        businessUnit: {
          businessUnitId: postValues.businessUnits
        },


      }




      // if (!this.storeOrgId) {
      //   // Option 1: Remove organization property entirely
      //   delete payload.organization;
      //   // Option 2: Set organization to null
      //   payload.organization = null;
      // }

      this.addClientButton=true

      console.log("payload creation::", payload);
      // return
      this.AdminService.createClient(payload).subscribe((response: any) => {
        console.log("response:", response);

        if (response.statusCode == 200) {
          this.success = true;
          this.sucess_msg = response.message;
          this.addClientButton = true;
          setTimeout(() => {
            this.success = true;
            this.addClientButton = true;
            this.router.navigate(['home/organization']);
          }, 5000)
        }
        if (response.statusCode == 409) {
          this.error = true;
          this.addClientButton = false;

          this.update_error_msg = response.message;

          setTimeout(() => {
            this.error = false;
          }, 5000)

        }


      },
        (err: any) => {
          this.error = true;
          this.update_error_msg = err.error.message;
          this.addClientButton = false;

          setTimeout(() => {
            this.error = false;
            this.addClientButton = false
          }, 5000)


        }
      )
    }
  }

}
