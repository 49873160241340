import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { LeaveService } from 'src/app/providers/leave.service';
import { MatOption } from '@angular/material/core';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-holiday',
  templateUrl: './add-holiday.component.html',
  styleUrls: ['./add-holiday.component.scss']
})
export class AddHolidayComponent implements OnInit {

  holidayFrom: FormGroup;
  myFilter: any
  holidayType: any = []
  exceptionType: any = []
  holiday: boolean =true;
  exception: boolean;
  dateDisable: boolean;
  submitted:boolean =false;
  btnDisable:boolean = false;
  storeHoliday: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private leaveservice: LeaveService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public datepipe:DatePipe,
    public dialogRef: MatDialogRef<AddHolidayComponent>
  ) { }

  ngOnInit() {
    console.log("this.data:",this.data);
    this.holidayFrom = this.fb.group({
      holidayName: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      holidayDate: this.data.start,
      holidayStatus: [1, Validators.required],
      holidaytype: ['', Validators.required],
      leaveType: ['', Validators.required],
      calender: {
        calendarId: this.data.groupId
      }
    })


    this.leaveservice.getListTypeById(5).subscribe(
      (d: any) => {

        this.holidayType = d;
        console.log("this.holidayType",this.holidayType);
       
  
      }
    )
   
    this.leaveservice.getListTypeById(6).subscribe(
      (d: any) => {
        console.log(d)
        this.exceptionType = d
      }
    )

    if (this.data.id) {
      this.dateDisable = true;
      this.leaveservice.getHolidayById(this.data.id).subscribe(
        (d: any) => {
          
          console.log(this.data,this.data.holidayType,d.holidayStatus,"11111111")
          this.holidayFrom.patchValue({
            holidayStatus: d.holidayStatus.toString()
          })
        }
      )
      this.holidayFrom.patchValue({
        holidayId: this.data.id,
        holidayName: this.data.title,
        holidayDate: this.data.start,
        holidaytype:Number(this.data.holidayType),
        calender: {
          calendarId: Number(this.data.groupId)
        },
        leaveType: 0
      })
    }
    else {
      this.holidayFrom.patchValue({
        holidayStatus: 1
      })
    }
  }

  get f() {
    return this.holidayFrom.controls
  }

   holiClick(e) {
    console.log("holiClick");
    console.log("e",e);
    if (e == 0) {
      this.holiday = true;
      this.exception = false;
    }
    if (e == 1) {
      this.holiday = false;
      this.exception = true;
    }
  }
  onLeaveTypeChange(event: Event) {
    console.log("onLeaveTypeChange");
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.holiClick(selectedValue);
  }
  delete(e) {
    this.leaveservice.deleteHoliday(e.id).subscribe(
      (data: any) => {
        Swal.fire({
          text: data.description,
          icon: 'success'
        })
        window.location.reload()
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'

        })
      }
    )
  }

  onSubmit() {

    this.submitted =true;
    let x = this.holidayFrom.value;
    console.log(x);
    // alert(x);
    if (this.holidayFrom.invalid) {
      return
    }
    this.btnDisable = true;
    console.log("DATE");
    var date = new Date(x.holidayDate);
    console.log(x.holidayDate);
    // var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
    // new code
    var newdate=this.datepipe.transform(x.holidayDate, 'MM-dd-yyyy');
    console.log(newdate);
    x['holidayDate'] = newdate;

    console.log(JSON.stringify(x))
    if (this.data.id) {
      x['holidayId'] = this.data.id
      x['holidayStatus'] = Number(x.holidayStatus)
      x['holidaytype'] = Number(x.holidaytype)
      this.leaveservice.UpdateHoliday(this.data.id, x).subscribe(
        (data: any) => {
          this.btnDisable = false;
          Swal.fire({
            text: data.description,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000 //

          })
          this.dialogRef.close( x)

          setTimeout(() => {
            //  window.location.reload()
          }, 1000)

        },
        err => {
          this.btnDisable = false;
          Swal.fire({
            text: err.err.message,
            icon: 'error'
          })
          setTimeout(() => {
            //  window.location.reload()
          }, 2000)
        }
      )
    }
    else {
      if (x.leaveType == 0) {
        console.log(x.leaveType);
        x['holidayStatus'] = 1
        x['holidaytype'] = Number(x.holidaytype)
        this.leaveservice.createHoliday(x).subscribe(
          (data: any) => {
            Swal.fire({
              text: data.description,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000

            })
            this.dialogRef.close(x)
            setTimeout(() => {
              //  window.location.reload()
            }, 1000)
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.dialogRef.close()
          }
        )
      }
      if (x.leaveType == 1) {
        console.log(x.leaveType);
        let y = this.holidayFrom.value;
        y['exceptionName'] = y.holidayName;
        var dato = new Date(y.holidayDate);
        // var newdato = (dato.getMonth() + 1) + '-' + dato.getDate() + '-' + dato.getFullYear();
        var newdato=this.datepipe.transform(x.holidayDate, 'MM-dd-yyyy');

        y['exceptionDate'] = newdato;
        y['exceptionStatus'] = 1;
        y['exceptionType'] = Number(y.holidaytype)
        y['calender'] = {
          calendarId: this.data.groupId
        }
        this.leaveservice.createException(y).subscribe(
          (data: any) => {
            Swal.fire({
              text: data.description,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000 
            })
            this.dialogRef.close(y)
            setTimeout(() => {
                // window.location.reload()
            }, 2000)
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.dialogRef.close()
          }
        )
      }
    }

  }

}
