import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from '../providers/payroll.service';
import { SettingsService } from '../providers/settings.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import { MatDialog } from '@angular/material/dialog';
import { LoanDetailsComponent } from '../loan-details/loan-details.component';
import { AdvanceApproverStatusComponent } from '../advance-approver-status/advance-approver-status.component';

@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styleUrls: ['./loan-list.component.scss']
})
export class LoanListComponent implements OnInit {
  p: number = 1;
  pagePerItem:any=10;
  organzationId: any;
  loanList: any =[];
  noRecordsFound: boolean =false;
  userdata: any;
  organizationSettings: any;

  constructor(
    public payrollService:PayrollService,
    public fb:FormBuilder,
    private settingsService: SettingsService,
    private gs:GlobalserviceService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =  this.userdata.organization.organizationId;
    console.log("organziationid " , this.organzationId);
    this.noRecordsFound = true;
    this.gs.getOrganizationdetails(this.organzationId).subscribe(
      (data:any)=>{
        this.organizationSettings=data;
        console.log("this.organizationSettings",this.organizationSettings);
      }
    )
    this.gs.getLoanlistByemployee(this.userdata.employee.employeeId).subscribe(
      (data: any) => {
        this.loanList = data;
        console.log(this.loanList);
        this.noRecordsFound = false;

        if(this.loanList.length == 0){
          this.noRecordsFound = true;
          console.log("nodata");
        }
        if(data.statusCode) {
          this.noRecordsFound = true;
          console.log("nodata");
        }
      },
      (error) => {
        this.noRecordsFound = true;
        console.log("error1")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
    );   
  }
  openDialog(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(LoanDetailsComponent,{
      width: '800px',
      height:'fit-content',
      data :approvaldata
    })
  }
  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(AdvanceApproverStatusComponent,{
      width: '500px',
      height:'fit-content',
      data :approvaldata
    })
  }

}
