<div style="margin-top: 70px" class="card card-content">
  <h4 class="heading-tag">Assign Employee Task</h4>
  <div class="row justify-content-between">
    <div class="col-md-4">
      <div id="loginform">
        <div class="col-md-12 col-xl-6 select">
          <label class="form-control-label"
            >Task&nbsp;Assign&nbsp;Type<span class="text-danger">*</span></label
          >
          <mat-select
            (selectionChange)="selected($event.value)"
            class="form-control email-input assign-placeholder"
            placeholder="Select task assign type "
          >
            <mat-option value="employee">Assign by Task</mat-option>
            <mat-option value="task">Assign by Employee</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-right">
      <!-- Adjusted the column size and added text-right class -->
      <button
        mat-raised-button
        type="submit"
        (click)="importEmployee()"
        class="searchBtn btn-res mr-3 button"
      >
        Import
      </button>
    </div>
  </div>

  <div *ngIf="task_by_employee" class="mt-3">
    <form [formGroup]="assignformbyemployee" class="task-Employee"  (ngSubmit)=" submitForm()">
      <div class="row">
        <div class="col-md-4">
          <div id="loginform">
            <label class="form-control-label"
              >Select Project<span class="text-danger">*</span></label
            >
            <mat-select
              class="form-control email-input select-placeholder"
              formControlName="projectname"
              placeholder="Select project"
            >
              <mat-option
                *ngFor="let project of projectList"
                [value]="project.projectId"
                (click)="projectData(project)"
              >
                {{ project.projectName }}
              </mat-option>
            </mat-select>
            <!-- <div *ngIf="submitted1 && projectname.invalid">
              <small *ngIf="projectname.errors?.required" class="text-danger"
                >Please select project</small
              >
            </div> -->
            <div
              *ngIf="(submitted1 && projectname.invalid) || projectname.dirty"
            >
              <small *ngIf="projectname.errors?.required" class="text-danger"
                >Please select project</small
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div id="loginform">
            <label class="form-control-label"
              >Select Task<span class="text-danger">*</span></label
            >
            <mat-select
              class="form-control email-input select-placeholder"
              formControlName="taskname"
              placeholder="Select task"
            >
              <mat-option
                *ngFor="let task of taskList"
                [value]="task.taskId"
                (click)="taskData(task)"
                >{{ task.taskName }}
              </mat-option>
            </mat-select>
            <div *ngIf="(submitted1 && taskname.invalid) || taskname.dirty">
              <small *ngIf="taskname.errors?.required" class="text-danger"
                >Please select task name</small
              >
            </div>
            <!--<span *ngIf="projecttaskexist" class="span-error"> Task already exist</span> -->
          </div>
        </div>
        <div class="col-md-4">
          <button
            mat-raised-button
            type="button"
            (click)="addRow()"
            class="searchBtn float-right m-2 "
          >
            Add Row
          </button>
        </div>
      </div>
      <div class="table-responsive" style="max-height: 275px; overflow: auto">
        <table class="table table-border table-responsive">
          <thead>
            <th>
              Employee<span class="text-danger">*</span
              ><span class="resizer"></span>
            </th>
            <th>
              Start Date<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>
              End Date<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>
              Hours<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>
              Minutes<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>Action</th>
          </thead>
          <tbody>
            <ng-container
              formArrayName="tableRows"
              *ngFor="let group of getFormControls.controls; let i = index"
            >
              <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                <td>
                  <input
                    type="text"
                    matInput
                    (input)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    (keyup)="validateInput($event)"
                    placeholder="Search Employee"
                    formControlName="employee"
                    [matAutocomplete]="auto"
                    class="form-control email-input select-placeholder"
                    (keyup)="empSearch($event,$event)"
                    (keydown)="handleBackspace($event)"
                  
                  />

                  <!-- [readonly]="
                    !assignformbyemployee.value.employee ||
                    assignformbyemployee.value.employee == 'null'
                  " -->
                  <mat-autocomplete #auto="matAutocomplete">
                
                    <mat-option
                      *ngFor="let emp of employeeProjects"
                      [value]="emp?.employee?.employeeId"
                      (click)="empSelect( emp,i)"
                    >
                      {{ emp?.employee?.employeeNumber }} -
                      {{ emp?.employee?.firstName }}
                    </mat-option>
                    <mat-option *ngIf="employeeNotFound"
                    (click)="noEmployeeFound()"
                      >No Employee Found</mat-option
                    >
                  </mat-autocomplete>
                  <mat-icon class="material-symbols-outlined search">
                    search
                  </mat-icon>
                  <!-- style="position: absolute; top: 305px; margin-left: 251px;" -->
                  <!-- <div class="valid-msg" *ngIf="submitted">        
                        <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                    </div> -->
                  <!-- </div> -->
                  <div
                    *ngIf="
                      group && group['controls'] && group['controls'].employee
                    "
                  >
                    <div
                      *ngIf="
                        (submitted1 && group['controls'].employee.invalid) ||
                        group['controls'].employee.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].employee.errors?.required"
                        class="text-danger2"
                      >
                        Please select employee
                      </small>
                    </div>
                  </div>
                </td>
                <td>
                  <div> 
                    <input
                      placeholder="Start Date"
                      matInput
                      readonly
                      [matDatepicker]="picker1"
                      class="form-control email-input date-input date-placeholder"
                      formControlName="startdate"
                      type="text"
                      [min]="min"
                      [max]="max"
                      [disabled]="!assignformbyemployee.get('taskname').valid"
                      (dateChange)="checkStartDate(); updateEndDateMin($event);updateEndDateMinbyEmployee($event)"
                      id="startDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker1"
                      [disabled]="!assignformbyemployee.get('taskname').valid || min == null"
                      style="
                        float: right;
                        position: relative;


                        margin-right: -4px;
                      "
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1      [disabled]="!assignformbyemployee.get('taskname').valid || !min"></mat-datepicker>
                    <div
                      class="validation"
                      *ngIf="
                        (submitted1 && group['controls'].startdate.invalid) ||
                        group['controls'].startdate.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].startdate.errors?.required || submitted1"
                        class="text-danger2"
                        >Please select start date</small
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      placeholder="End Date"
                      matInput
                      readonly
                      [matDatepicker]="picker2"
                      class="form-control email-input date-placeholder text-truncate date-input"
                      style="height: 38px"
                      formControlName="enddate"
                      type="text"
                      [min]="group.get('startdate').value"
                
                      [max]="this.max"
                      (dateChange)="checkStartDate(); updateEndDateMin($event);updateEndDateMinbyEmployee($event)"
                      id="endDate"
                      [disabled]=" !group.get('startdate').value"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                      class="date-picker"
                      style="float: right; position: relative"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <!-- <mat-datepicker-toggle matSuffix [for]="picker1"
                                        style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle> -->
                    <div
                      class="validation"
                      *ngIf="
                        (submitted1 && group['controls'].enddate.invalid) ||
                        group['controls'].enddate.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].enddate.errors?.required"
                        class="text-danger2"
                        >Please select End date</small
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="text"
                      formControlName="hours"
                      placeholder="Enter hours" 
                      (paste)="onPaste($event)"
                      (keypress)="noNumber($event)"
                      maxlength="3"
                      class="form-control email-input select-placeholder"
                    />
                    <!-- <div
                      *ngIf="
                        (submitted1 && group['controls'].hours.invalid) ||
                        group['controls'].hours.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].hours.errors?.required"
                        class="text-danger2"
                        >Please enter hours</small
                      >
                      <small *ngIf="group.get('hours').errors?.nonZeroHoursMinutes" class="text-danger2">
                        Hours and Minutes cannot be zero
                      </small>
                    </div> -->
                    <div *ngIf="(submitted2 && hours?.invalid) || hours?.dirty">
                      <small *ngIf="hours.errors?.required" class="text-danger2">
                        Please enter hours
                      </small>
                      <small *ngIf="hours.errors?.invalidHours" class="text-danger2">
                        Please enter a valid number between 0 and 999
                      </small>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="text"
                      formControlName="minutes"
                      placeholder="Enter minutes"
                      maxlength="2"
                      (paste)="onPaste($event)" 
                      (keypress)="noNumber($event)"
                      class="form-control email-input select-placeholder"
                    />
                    <div
                      *ngIf="
                        (submitted1 && group['controls'].minutes.invalid) ||
                        group['controls'].minutes.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].minutes.errors?.required"
                        class="text-danger2"
                        >Please enter minutes</small
                      >
                      <small *ngIf="group.get('minutes').errors?.nonZeroHoursMinutes" class="text-danger2">
                        Hours and Minutes cannot be zero
                      </small>
                    </div>
                  </div>
                </td>
                <td style="text-align: center" *ngIf="i != 0">
                  <!-- <mat-icon class="done" (click)="doneRow(group)">done</mat-icon> -->
                  <mat-icon style="color: red !important;" class="delete" (click)="deleteRow(i)"
                    >delete</mat-icon
                  >
                </td>
              </tr>
              <tr *ngIf="!group.get('isEditable').value">
                <td>
                  {{ group.get("employee").value }}
                </td>
                <td>
                  {{ group.get("startdate").value }}
                </td>
                <td>
                  {{ group.get("enddate").value }}
                </td>
                <td style="text-align: center">
                  <mat-icon
                    class="edit material-icons-outlined"
                    (click)="editRow(group)"
                    >edit</mat-icon
                  >
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="action-container">
        <button
          mat-raised-button
          class=" "
          style="
            float: right;
            color: white;
            margin-top: 15px;
            margin-left: 7px;
            background: none;
            color: black;
            height: 33px;
            border: 1px solid #ddd;
            font-size: 16px;
            font-weight: 610;
          "
          type="submit"
          (click)="resetFormData()"
        >
          Clear
        </button>

        <button
          mat-raised-button
          class="searchBtn"
          style="float: right; color: white; margin-top: 15px"
          type="submit"
          [disabled]="assignformbyemployee.invalid ||  
          !submitButtonEnable || !submitTask "
       
        >
          Submit
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="employee_by_task" class="mt-3">
    <form [formGroup]="assignformbytask" (ngSubmit)="submitForm1()">
      <div class="row">
        <div class="col-md-4">
          <div id="loginform">
            <label class="form-control-label"
              >Select Project<span class="text-danger">*</span></label
            >
            <mat-select
              class="form-control email-input select-placeholder"
              formControlName="projectname1"
              placeholder="Select project"
            >
              <mat-option
                *ngFor="let project of projectList"
                [value]="project.projectId"
                (click)="projectData(project)"
             
              >
                {{ project.projectName }}
              </mat-option>
            </mat-select>
            <div
              *ngIf="(submitted2 && projectname1.invalid) || projectname1.dirty"
            >
              <small *ngIf="projectname1.errors?.required" class="text-danger"
                >Please select project</small
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-control-label"
            >Search Employee<span class="text-danger">*</span></label
          >

          <input
            type="text"
            matInput
            placeholder="Search Employee"
            formControlName="employee1"
            (input)="validateInput($event)"
            (keydown)="validateInput($event)"
            (keyup)="validateInput($event)"
            [matAutocomplete]="auto"
            class="form-control email-input select-placeholder"
            (keyup)="empSearchEmployee($event,$event)"
            (keydown)="handleBackspace1($event)"
        
          />

         
          <mat-autocomplete #auto="matAutocomplete">
         
            <mat-option
              *ngFor="let emp of employeeProjectsTasks"
              [value]="emp?.employee?.employeeId"
              (click)="empSelectEmployee(emp)"
            >
              <div *ngIf="emp?.employee?.employeeId">
                {{ emp?.employee?.employeeNumber }} -
                {{ emp?.employee?.firstName }}
              </div>
            </mat-option>
     
            <mat-option *ngIf="employeeNotFoundByAssignEmployee"
            (click)="noEmployeeFound()"
              >No Employee Found</mat-option
            >
          </mat-autocomplete>

          <!-- [(ngModel)]="selectedEmployeeSearch" -->
          <!-- [ngModelOptions]="{standalone: true}" -->
          <!-- [formControl]="selectedEmployeeSearch"  -->
          <!-- <mat-select
            placeholder="Search Employee"
            class="form-control email-input select-placeholder text-truncate"
            formControlName="employee1"
            #singleSelect
            [disabled]="!assignformbytask.controls['projectname1'].value"
          >

            <mat-option>
              <ngx-mat-select-search
                (keyup)="empSearchEmployee($event, $event)"
                [(ngModel)]="selectedEmployeeSearch"
                [ngModelOptions]="{ standalone: true }"
       
                placeholderLabel="Search Employee"
              >
                <span ngxMatSelectNoEntriesFound> No Employee Found </span>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let emp of employeeProjectsTasks"
              [value]="emp?.employee?.employeeId"
              (click)="empSelectEmployee(emp)"
            >
              {{ emp?.employee?.employeeNumber }} -
              {{ emp?.employee?.firstName }}
            </mat-option>
          </mat-select> -->

          <div *ngIf="(submitted2 && employee1.invalid) || employee1.dirty">
            <small *ngIf="employee1.errors?.required" class="text-danger"
              >Please select employee</small
            >
          </div>
        </div>

        <div class="col-md-4">
          <button
            mat-raised-button
            type="button"
            (click)="addRow1()"
            class="searchBtn float-right m-2 btn-res"
          >
            Add Row
          </button>
        </div>
      </div>
      <div class="table-responsive" style="max-height: 275px; overflow: auto">
        <table class="table table-border table-responsive">
          <thead>
            <th>
              Task<span class="text-danger">*</span
              ><span class="resizer"></span>
            </th>
            <th>
              Start Date<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>
              End Date<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>
              Hours<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>
              Minutes<span class="text-danger">*</span
              ><span class="resizer1"></span>
            </th>
            <th>Action</th>
          </thead>
          <tbody>
            <ng-container
              formArrayName="tableRows1"
              *ngFor="let group of getFormControls1.controls; let i = index"
            >
              <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                <td id="date">
                  <mat-select
                    class="form-control email-input select-placeholder task-option"
                    formControlName="taskname1"
                    placeholder="Select task"
                    (onSelectionChange)="enableButton()"
                  >
                    <mat-option
                      *ngFor="let task of taskList"
                      [value]="task?.taskId"
                      (click)="taskDataBytask(task, group['controls'], i)"
                
                      
                      >{{ task?.taskName }}
                    </mat-option>
                  </mat-select>
                  <div
                    *ngIf="
                      (submitted2 && group['controls'].taskname1.invalid) ||
                      group['controls'].taskname1.dirty
                    "
                  >
                    <small
                      *ngIf="group['controls'].taskname1.errors?.required"
                      class="text-danger2"
                      >Please select taskname</small
                    >
                  </div>
                </td>
                <td id="date">
                  <div>
                    <!-- <input
                      formControlName="startdate1"
                      placeholder="Select start date"
                      (change)="mintaskendDate($event, i)"
                      [disabled]="startDate_disable"
                      [readonly]="startDate_disable"
                      [attr.id]="'dtPicker' + i"
                      type="date"
                      class="form-control email-input select-placeholder"
                    /> -->

                    <input
                      placeholder="Start Date"
                      matInput
                      readonly
                      [matDatepicker]="picker3"
                      class="form-control email-input date-input date-placeholder"
                      formControlName="startdate1"
                      [disabled]="!group['controls'].taskname1.value"
                      type="text"
                      [min]="minEmp"
                      [max]="maxEmp"
                      (dateChange)="updateEndDateMinbyEmployee($event)"
                      id="startDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker3"
                      style="
                        float: right;
                        position: relative;

                        margin-right: -4px;
                      "
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                    <div
                      class="validation"
                      *ngIf="
                        (submitted2 && group['controls'].startdate1.invalid) ||
                        group['controls'].startdate1.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].startdate1.errors?.required"
                        class="text-danger2"
                        >Please select start date</small
                      >
                    </div>
                  </div>
                </td>
                <td id="date">
                  <div>
                    <!-- <input
                      formControlName="enddate1"
                      placeholder="Select end date"
                      [disabled]="endDate_disable"
                      [readonly]="endDate_disable"
                      [attr.id]="'enddtPicker' + i"
                      type="date"
                      class="form-control email-input select-placeholder"
                    /> -->

                    <input
                      placeholder="End Date"
                      matInput
                      readonly
                      [matDatepicker]="picker4"
                      [disabled]="!group['controls'].startdate1.value"
                      class="form-control email-input date-placeholder text-truncate date-input"
                      style="height: 38px"
                      formControlName="enddate1"
                      type="text"
                      [min]="group.get('startdate1').value"
                      [max]="maxEmp"
                      (dateChange)="checkStartDate()"
                      id="endDate"
                      [disabled]="!group.get('startdate1').value"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker4"
                      class="date-picker"
                      style="float: right; position: relative"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                    <div
                      class="validation"
                      *ngIf="
                        (submitted2 && group['controls'].enddate1.invalid) ||
                        group['controls'].enddate1.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].enddate1.errors?.required"
                        class="text-danger2"
                        >Please select end date</small
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="text"
                      formControlName="hours1"
                      placeholder="Enter hours"
                      (paste)="onPaste($event)"
                      (keypress)="noNumber($event)"
                      maxlength="3"
                      class="form-control email-input select-placeholder"
                    />
                    <!-- <div
                      *ngIf="
                        (submitted2 && group['controls'].hours1.invalid) ||
                        group['controls'].hours1.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].hours1.errors?.required"
                        class="text-danger2"
                        >Please enter hours</small
                      >
                    </div> -->
                    <div
                      *ngIf="(submitted2 && hours1?.invalid) || hours1?.dirty"
                    >
                      <small
                        *ngIf="hours1?.errors?.required"
                        class="text-danger2"
                      >
                        Please enter hours
                      </small>
                      <small
                        *ngIf="hours1.errors?.invalidHours"
                        class="text-danger2"
                      >
                        Please enter a valid number between 0 and 999
                      </small>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="text"
                      formControlName="minutes1"
                      placeholder="Enter minutes"
                      (paste)="onPaste($event)"
                      maxlength="3"
                      (input)="  enableButton()"
                    
                      (keypress)="noNumber($event)"
                      class="form-control email-input select-placeholder"
                    />
                    <div
                      *ngIf="
                        (submitted2 && group['controls'].minutes1.invalid) ||
                        group['controls'].minutes1.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].minutes1.errors?.required"
                        class="text-danger2"
                        >Please enter minutes</small
                      >
                    </div>
                  </div>
                </td>
                <td style="text-align: center" *ngIf="i != 0">
                  <!-- <mat-icon class="done" (click)="doneRow1(group)">done</mat-icon> -->
                  <mat-icon
                    style="color: red !important"
                    class="delete"
                    (click)="deleteRow1(i)"
                    >delete</mat-icon
                  >
                </td>
              </tr>
              <tr *ngIf="!group.get('isEditable').value">
                <td>
                  {{ group.get("taskname1").value }}
                </td>
                <td>
                  {{ group.get("startdate1").value }}
                </td>
                <td>
                  {{ group.get("enddate1").value }}
                </td>
                <td style="text-align: center">
                  <mat-icon
                    class="edit material-icons-outlined"
                    (click)="editRow1(group)"
                    >edit</mat-icon
                  >
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="action-container">
        <button
          mat-raised-button
          style="
            float: right;
            color: white;
            margin-top: 15px;
            margin-left: 7px;
            background: none;
            color: black;
            height: 33px;
            border: 1px solid #ddd;
            font-size: 16px;
            font-weight: 610;
          "
          type="button"
          (click)="resetAssignByEmployee()"
        >
          Clear
        </button>

        <!-- <button
        mat-raised-button
        class="btn create-button"
        style="float: right; color: white; margin-top: 15px"
        type="submit"
        (click)="submitForm()"
        [disabled]="submitted || Taskform.invalid"
        [style.display]="Taskform.invalid ? 'none' : 'inline-block'"
      >
        Submit
      </button> -->
        <!-- !this.employee || -->
        <button
          mat-raised-button
          class="searchBtn"
          style="float: right; color: white; margin-top: 15px"
          type="submit"
          [disabled]="assignformbytask.invalid ||
          !submitButtonEnableEmployee  
          && !submitEmployee 
          || !this.employee 
          || !disableAssignEmployeeButton"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
  style="height: 527vh !important"
></div>
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div class="text-center" *ngIf="errorMessage">
  <div class="alert alert-danger alert-dismissible toast-msg" role="alert">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>
