import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-viewdescripe',
  templateUrl: './viewdescripe.component.html',
  styleUrls: ['./viewdescripe.component.scss']
})
export class ViewdescripeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ViewdescripeComponent>,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any, private gs: GlobalserviceService) {

      console.log('data',data);
      
     }
  ngOnInit(): void {
  }
}
