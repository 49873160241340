import { Component, Input, OnInit, ViewChild } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { AdminService } from 'src/app/providers/admin.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { UserService } from 'src/app/providers/user.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatboxmsgComponent } from 'src/app/chatboxmsg/chatboxmsg.component';
import { MatDialog } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';
import { AnnouncementService } from 'src/app/announcement.service';
import Swal from 'sweetalert2';
import Quill from 'quill';

// import { QuillConfig } from 'ngx-quill';
import MagicUrl from 'quill-magic-url';
import QuillBetterTable from 'quill-better-table';
import { log } from 'node:console';
Quill.register('modules/magicUrl', MagicUrl);
Quill.register({
  'modules/better-table': QuillBetterTable
}, true)
var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);


// import Table from 'quill/modules/table';
// Quill.register('modules/table', Table);


@Component({
  selector: 'app-newsandfeed',
  templateUrl: './newsandfeed.component.html',
  styleUrls: ['./newsandfeed.component.scss']
})
export class NewsandfeedComponent implements OnInit {
  emailcontent: any;

  public quill: Quill;
  quillConfig:any;

    // public Editor = ClassicEditor;
    // public editorConfig: any = {
    //   extraPlugins: [ this.base64UploadAdapterPlugin ],
    // };
  
    // base64UploadAdapterPlugin(editor) {
    //   editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    //     return new Base64UploadAdapter(loader);
    //   };
    // }
    // public editorContent: string = ' ';
    
    // onEditorChange(event: any) {
    //   console.log("Editor content changed:", this.editorContent);
    // }
    [x: string]: any;
  
    nodatafound1: boolean = false;
    nodatafounds: boolean = false;
  
    resultData: any = {};
    resultDatarec: any;
    errorMsg: any;
    admindashboard: any;
    ProfileShared: number;
    Interviewed: any;
    Notinterviewed: number;
    recruiterSearchform: FormGroup;
    @Input() requiredError: boolean;
    reportfromdate: any;
    reporttodate: any;
    startmaxdate: Date = new Date();
    endmindate;
    recruiterlist: any;
    loader=true;
    backdrop: any = false;
    dataForExcel = [];
    true: any;
    interviewStatuslist: any;
    success: any = false;
    error: any = false;
    sucess_msg: any;
    error_msg: any;
    results: any = [];
    localStorageMenuId: any;
    moduleId: any;
    first_segment: any;
    usertype: any;
    menudata: any = [];
    moduledata: any = [];
    showFeedSection: boolean = false;
    element: any;
    rows: any;
    posts: any[] = [];
    feedId1:any;
    showComment: boolean[] = [];

    selectedAnnouncementId: number | null = null;
  
    constructor(
      private dashboardservice: RecruitmentService,
      private adminservice: AdminService,
      private superadminservice: SuperadminService,
      private UserService: UserService,
      private datepipe: DatePipe,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      public dialog: MatDialog,
      private AnnouncementService: AnnouncementService,
      private fb: FormBuilder
  
  
  
    ) {
      //console.log("routes");
      //console.log(activatedRoute.snapshot.url); // array of states
      if (activatedRoute.snapshot) {
        this.first_segment = activatedRoute.snapshot.url[0].path;
      }
      this.posts.forEach(() => {
              this.showComments.push(false);
          });
    }
  
    ngOnInit(): void {
      console.log("2222222222222", this.emailcontent);
      this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
      this.organizationId = this.usertype.organization.organizationId;
      this.employeeId = this.usertype?.employee?.employeeId;
      this.clientId=this.usertype.employee?.client?.clientId;
  
      if (this.usertype.employee) {
        // console.log("emp");
        if (this.usertype.employee.role) {
          this.usertype.employee.role.forEach((element) => {
            if (element.menu) {
              element.menu.forEach((mennuelement) => {
                let hasmenu = this.menudata.some(
                  (m) => m.menuId == mennuelement.menuId
                );
                if (!hasmenu) {
                  this.menudata.push(mennuelement);
                  //   console.log( this.menudata);
                }
                //  console.log( "module length" + mennuelement.module.length);
                mennuelement.module.forEach((moduleelement) => {
                  let moduledata = this.moduledata.some(
                    (m) => m.moduleId == moduleelement.moduleId
                  );
                  if (!moduledata) {
                    this.moduledata.push(moduleelement);
                  }
                });
                //  console.log( "moduledata" +this.moduledata);
              });
            }
          });
        }
      } else {
        if (this.usertype.role) {
          console.log('userrole');
          this.usertype.role.forEach((element) => {
            if (element.menu) {
              element.menu.forEach((mennuelement) => {
                let hasmenu = this.menudata.some(
                  (m) => m.menuId == mennuelement.menuId
                );
                if (!hasmenu) {
                  this.menudata.push(mennuelement);
                  //   console.log( this.menudata);
                }
                //  console.log( "module length" + mennuelement.module.length);
                mennuelement.module.forEach((moduleelement) => {
                  let moduledata = this.moduledata.some(
                    (m) => m.moduleId == moduleelement.moduleId
                  );
                  if (!moduledata) {
                    this.moduledata.push(moduleelement);
                  }
                });
                //  console.log( "moduledata" +this.moduledata);
              });
            }
          });
        }
      }
console.log("this.id",this.id);
      this.getFeed(this.id);
      console.log("feed",this.getFeed);
      this.getAnnouncementByActive();
    //  this.getComment(this.id);
  
  
  
      this.FeedForm = this.fb.group({
        content: ['',Validators.required,],
        feedAttachment: [null]
      });  

      const fontFamilyArr = ["serif", "Arial", "Courier New", "Georgia", "Tahoma", "Times New Roman", "Trebuchet MS", "Verdana", "Calibri", "Roboto", "Noto Sans", "Comic Sans MS", "Helvetica"];
      let fonts = Quill.import("attributors/style/font");
      fonts.whitelist = fontFamilyArr;
      Quill.register(fonts, true);
  

      this.quillConfig = {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
  
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{
              'font': fontFamilyArr
            }],
            //  [{ 'font': [] }],
            [{ 'align': [] }],
  
            ['clean'],                                         // remove formatting button
  
            ['link'],
            ['image'],
            ['table'],
            // ['fileatttacment']
          ],
        },
        magicUrl: true,
        table: true,
        "better-table": {
          operationMenu: {
            items: {
              unmergeCells: {
                text: "Another unmerge cells name"
              }
            },
            color: {
              colors: ["#fff", "red", "rgb(0, 0, 0)"], // colors in operationMenu
              text: "Background Colors" // subtitle
            }
          }
        },
        keyboard: {
          bindings: QuillBetterTable.keyboardBindings
        }
      }
      console.log(QuillBetterTable.keyboardBindings); // Check if it is an object or null

    }


  //   public onEditorCreated(event: Quill): void {
  //     this.quill = event;
    
  // }

  public onEditorCreated(event: Quill): void {
    console.log(event);
    this.quill = event;
    console.log("Content",this.quill);
    
  }
  
    checkmoduledata(modulecode) {
      // console.log("modulecode" + modulecode);
      // console.log(this.moduledata);
      return this.moduledata.some((e) => e.moduleCode == modulecode);
    }
  
    ucFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  
    // getModulesList() {
    //   /* Get Designation Data */
    //   this.superadminservice.getActiveModule().subscribe(
    //     (result: any) => {
    //       result.forEach((element) => {
    //         let hasmodule = this.moduledata.some(
    //           (mmd) => mmd.moduleId == element.moduleId
    //         );
  
    //         let hasremodules = this.results.some(
    //           (em) => em.moduleId == element.moduleId
    //         );
    //         if (hasmodule) {
    //           this.results.push(element);
    //         }
    //       });
  
    //       /*  this.router.navigate(['/home/admin-home']).then(() => {
    //       window.location.reload();
    //     }); */
    //     },
    //     (err) => {
    //       this.errorMsg = err.error.message;
    //       console.log(this.errorMsg);
    //     }
    //   );
    // }
  
    // selectModules(moduleCode) {
    //   localStorage.setItem('moduleCode', moduleCode);
  
    //   this.localStorageMenuId = localStorage.getItem('moduleCode');
  
    //   if (moduleCode == 'CHRMS') {
    //     //Core HRMS
    //     //this.router.navigate(['/home/dashboard']);
  
    //     this.router
    //       .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
    //       .then(() => {
    //         this.router.navigate(['/home/dashboard']);
    //       });
    //   } else if (moduleCode == 'RQMNT') {
    //     //Recruitment
    //     if (this.usertype.role[0].roleName == 'Admin') {
    //       this.router
    //         .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
    //         .then(() => {
    //           this.router.navigate(['/home/admin-dashboard']);
    //         });
    //     } else {
    //       this.router
    //         .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
    //         .then(() => {
    //           this.router.navigate(['/home/recruiter-dashboard']);
    //         });
    //     }
  
    //   }
    //   else if(moduleCode == 'PMS') //PMS
    //   {
  
    //     this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
    //       this.router.navigate(['/home/kra-template']);
    //   });
    //   }
    //   else if(moduleCode == 'PAYROLL') //PAYROLL
    //   {
  
    //     this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
    //       this.router.navigate(['/home/employee-payslip']);
    //   });
    //   }
    //   else if(moduleCode == 'LEAVE') //Leave
    //   {
  
    //     this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
    //       this.router.navigate(['/home/leave-dashboard']);
    //   });
    //   } else if(moduleCode == 'LEAVE') //Leave
    //   {
  
    //     this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
    //       this.router.navigate(['/home/leave-dashboard']);
    //   });
    // }
    // else if (moduleCode == 'PROJECTMANAGEMENT') {
    //   this.router
    //     .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
    //     .then(() => {
    //       this.router.navigate(['/home/project-management']);
    //     });
    // }
    //   }


    showFeed(id: any, message: string, date: string, messageg1: String, time: string) {
  
      this.selectedAnnouncementId = id;
  
      console.log("Generating feed for announcement:", message, "Date:", date, id);
      this.AnnouncementService.getAnnouncementById(id).subscribe(
        (data: any) => {
          this.rows = data;
          console.log("Data received:", this.rows);

          // const ckeditorOutput = this.rows.announcementContent; // Assuming announcementContent holds the HTML string
          // const parser = new DOMParser();
          // const doc = parser.parseFromString(ckeditorOutput, 'text/html');
          // const oembedTag = doc.querySelector('figure oembed');
    
          // if (oembedTag) {
          //   const videoUrl = oembedTag.getAttribute('url');
          //   console.log("Extracted URL:", videoUrl);  // Output the extracted URL for verification
          //   this.rows.extractedUrl = videoUrl;        // Store it in a variable if needed
          // } else {
          //   console.log("No oembed tag found in figure tag");
          // }
  
  
          this.showFeedSection = true;
          console.log("showFeedSection:", this.showFeedSection);
          console.log(this.rows.announcementContent)
  
        },
        (error) => {
          console.error('Error fetching announcement data:', error);
        }
      );
    }

    
    hideFeed() {
      this.showFeedSection = false;
    }


    viewchatbox(chatboxdata: any) {
      console.log(chatboxdata);
      this.dialog.open(ChatboxmsgComponent, {
        width: '400px',
        height: 'fit-content',
        data: chatboxdata,
        position: { top: '90px', bottom: '10px', right: '50px' } // Adjust the positioning as needed
  
      })
    }
  
    getAnnouncementByActive() {
      // console.log("List Api data"+id);
      if(this.clientId !=undefined){
        this.AnnouncementService.getannouncementListClient(this.clientId).subscribe((data: any) => {
          console.log("data", data);
    
          this.employees = data;
          console.log(this.employees);
          this.loader = false;
          this.nodatafound1 = false;
          if (data.statusCode == 204) {
            console.log("nodata");
            this.nodatafound1 = true;
          }
          if (this.employees.length == 0) {
            this.employees = true;
          }
        })
      }else{
        this.AnnouncementService.getannouncementListOrganization(this.organizationId).subscribe((data: any) => {
          console.log("data", data);
    
          this.employees = data;
          console.log(this.employees);
          this.loader = false;
          this.nodatafound1 = false;
          if (data.statusCode == 204) {
            console.log("nodata");
            this.nodatafound1 = true;
          }
          if (this.employees.length == 0) {
            this.employees = true;
          }
        })
      }
      
    }
    onSubmit() {
      this.submitted = true;
      console.log(QuillBetterTable.keyboardBindings); // Check if it is an object or null

    //   if ( this.FeedForm.value.content.length < 2 ) {
    //     Swal.fire({
    //         text: 'Please fill content fields.',
    //         icon: 'error'
    //     });
    //     return; 
    // }
    
      let x = this.FeedForm.value;
      this.employeeId = this.usertype.employee.employeeId;
    

      x['employee'] = {
        employeeId: this.employeeId
    };
        x['status'] = {
        listTypeValueId: 1
    };
    
    if (this.payload) {
      x['feedAttachment'] = this.payload;
    }    console.log( "11111111111111",x);
  
  
      // Swal.fire({
      //   title: 'Are you sure? Do you want to Apply feed!',
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3f51b5',
      //   cancelButtonColor: '#f44336',
      //   confirmButtonText: 'Apply'
      // }).then((result) => {
        // if (result.value) {
          if (confirm('Are you sure? Do you want to Apply feed!')) {
  
          this.isLoading = true;
          this.AnnouncementService.applyFeed(x).subscribe(
            (result: any) => {
              this.success = true;
              this.sucess_msg = result.description;
              this.FeedForm.reset();
              this.submitted = false;
              this.payload = null; // Clear the payload after successful submission
              this.fileInputLabel = 'Attach File'; // Reset the file input label
  
              // this.searchSubmit();
              setTimeout(() => {
                this.success = false;
              }, responseMsgTimeOut)
            
            },
             err => {
              setTimeout(() => {
                  Swal.fire({
                      text: err.error.message,
                      icon: 'error'
                  });
                  this.isLoading = false; 
              }, redirectMsgTimeOut); 
            },
            
            () => {
              this.isLoading = false; 
              this.getFeed(this.id);
            } 
  
          )
        }
  
    }


    onSubmit1(post) {
      this.submitted = true;
  
      
      this.employeeId = this.usertype.employee.employeeId;

      post.liked = !post.liked;
  
      // Construct the payload object
      const y = {
          feed: {
              feedId: post.feedId
          },
            feedAttachment:{
              fileId :post.fileId,
              fileName :post.fileName,
              fileType :post.fileType,
              fileSize :post.fileSize,
              fileViewUrl :post.fileViewUrl,
              fileDownloadUrl :post.fileDownloadUrl,
  
              status: {
                listTypeValueId: 1
            }
            },
          employee: {
              employeeId: this.employeeId
          },
          status: {
              listTypeValueId: 1
          },
          
      };
      // console.log(this.post.feedAttachment.fileDownloadUrl)
      // this.dowUrl=this.post.feedAttachment.fileDownloadUrl;
      // console.log(this.dowUrl)
  
      console.log("Payload:", y);
  
      
      this.isLoading = true;
  
      this.AnnouncementService.applyLike(y).subscribe(
          (result: any) => {
              // this.success = true;
              // this.sucess_msg = result.description;
              this.FeedForm.reset();
              this.submitted = false;
  
              setTimeout(() => {
                  this.success = false;
              }, responseMsgTimeOut);
          },
          (err) => {
              console.error("Error:", err);
              this.isLoading = false;
          },
          () => {
              this.isLoading = false;
          }
      );
  }
  

  onSubmit2(post) {
    this.submitted = true;
  
    if (!this.content) {
      this.isLoading = false;
      return;
    }
    this.employeeId = this.usertype.employee.employeeId;
  
  
    
    const z = {
        feed: {
            feedId: post.feedId
        },
        content: this.content, 
              employee: {
            employeeId: this.employeeId
        },
        status: {
            listTypeValueId: 1
        }
        
    };
    // console.log(this.post.feedAttachment.fileDownloadUrl)
    // this.dowUrl=this.post.feedAttachment.fileDownloadUrl;
    // console.log(this.dowUrl)
  
    console.log("Payload:", z);
  
    
    this.isLoading = true;
  
    this.AnnouncementService.applyComments(z).subscribe(
        (result: any) => {
            this.success = true;
            this.sucess_msg = result.description;
            this.content = '';
                      this.submitted = false;
  
            setTimeout(() => {
                this.success = false;
            }, responseMsgTimeOut);
        },
        (err) => {
            console.error("Error:", err);
            this.isLoading = false;
        },
        () => {
            this.isLoading = false;
            console.log(this.id);
            this.getComment(this.id);
        }
    );
  }
  
    downloadfiles(dowUrl)
    {
      console.log("Download File"+dowUrl);
      const a = document.createElement('a')
                  //  const objectUrl: any =  "data:" + filedata.fileType + filedata.fileEncoding + "," + filedata.fileByte;
                  // const objectUrl =  `${base_url}download/${result}`
                  let downloadurl=dowUrl;
                  console.log("downloadurl"+downloadurl);
                  const objectUrl =  downloadurl
                  // const objectUrl =  `http://38.242.135.217:6018/api/enote/pms/download/TSDOC_6.jpg`
                  a.href = objectUrl;
                  a.download = objectUrl;
                  a.click();
                  URL.revokeObjectURL(objectUrl);
                  console.log("Download File:"+a);
    }
    onFileSelect(event: any) {
      console.log("file Read", event);
      const file: File = event.target.files[0];
      console.log("file", file);
    
      if (file) {
          const fileName = file.name;
          const fileType = file.type;
          const fileSize = file.size;
          let fileId = null; // Initialize fileId as null
    
          // Check if fileId is available in this.resultData.announcementAttachment
          if (this.resultData && this.resultData.announcementAttachment && this.resultData.announcementAttachment.fileId) {
              fileId = this.resultData.announcementAttachment.fileId;
             
          }
    
          // Use the obtained file details in your payload
          this.payload = {
              fileName: fileName,
              fileType: fileType,
              fileSize: fileSize,
              fileBytes: null,
              fileId: fileId, // Include fileId in the payload
              status: {
                  listTypeValueId: 1
              }
          };
    
          // this.announcementForm.patchValue({
          //     fileName: fileName
          // });
    
          console.log(this.payload);
      }
    
      let reader = new FileReader();
      reader.onload = () => {
          let x: any = reader.result;
          let y: any = x.split(',');
          let sourceByteArray = y[1];
          this.payload.fileBytes = sourceByteArray;
      };
      reader.readAsDataURL(file);
      this.fileInputLabel = file.name;
    }



      getFeed(id)
      {
        console.log("List Api data"+id);

        if(this.organizationId){
        this.AnnouncementService.getFeedListOrg(this.organizationId).subscribe((data:any) =>
          {
            
          console.log("data",data);
          
          this.posts=data;
          console.log(this.posts);
          this.loader=false;
          // this.nodatafound=false;
          if(data.statusCode==204)
          {
            console.log("nodata");
            this.nodatafounds=true;
          }
  
          if(this.posts.length==0)
          {
            this.nodatafounds=true;
          }
          })
        }
        if(this.clientId){
          this.AnnouncementService.getFeedListClient(this.clientId).subscribe((data:any) =>
            {
              
            console.log("data",data);
            
            this.posts=data;
            console.log(this.posts);
            this.loader=false;
            // this.nodatafound=false;
            if(data.statusCode==204)
            {
              console.log("nodata");
              this.nodatafounds=true;
            }
    
            if(this.posts.length==0)
            {
              this.nodatafounds=true;
            }
            })
          }
      }


      getComment(id)
      {
        console.log("List Api data"+id);
        console.log(this.feedId1)
        this.AnnouncementService.getComments(this.feedId1).subscribe((data:any) =>
          {
          console.log("data",data);
          
          this.com=data;
          console.log(this.com);
          this.loader=false;
          this.nodatafound=false;
          if(data.statusCode==204)
          {
            console.log("nodata");
            this.nodatafound=true;
          }
          if(this.com.length==0)
          {
            this.nodatafound=true;
          }
          })
      }
      
    //    extractFirstLine(message1) {
    //     // Split the message into lines
    //     const lines = message1.split('\n');
        
    //     // Find the first non-empty line
    //     let firstLine = '';
    //     for (let i = 0; i < lines.length; i++) {
    //         if (lines[i].trim() !== '') {
    //             firstLine = lines[i].trim();
    //             break;
    //         }
    //     }
    
    //     // Calculate the remaining length
    //     const remainingLength = message1.length - firstLine.length;
        
    //     // Create the dotted line
    //     const dottedLine = ' '.repeat(firstLine.length) + '...'.repeat(Math.max(0, Math.floor(remainingLength / 3)));
        
    //     // Return the first line with the dotted line
    //     return firstLine + dottedLine;
    // }
    
    
  
  // toggleComment() {
  //   this.showComment = !this.showComment;
  // }
  // toggleComment(feedId: any,i:any) {
  //   console.log( "with feedId:", feedId);
  //   console.log(i);
  //   this.feedId1=feedId;
  //   console.log(this.feedId1)
    
  
  //       this.showComment[feedId] = !this.showComment[feedId];
  //       this.AnnouncementService.getComments(this.feedId1).subscribe((data:any) =>
  //         {
  //         console.log("data",data);
  //         this.com = Array.isArray(data) ? data : [data];
  //         // this.com=data;
  //         console.log(this.com);
  //         this.loader=false;
  //         this.nodatafound=false;
  //         if(data.statusCode==204)
  //         {
  //           console.log("nodata");
  //           this.com=[];
  //           this.nodatafound=true;
  //         }
  //         if(this.com.length==0)
  //         {
  //           this.nodatafound=true;
  //         }
          
  //         })
    
  // }

  toggleComment(feedId: any,i:any) {
    console.log("with feedId:", feedId,i);
    
    // Check if the clicked feedId is the same as the previous one, if so, just toggle the visibility
    if (this.feedId1 !== feedId) {
        // Clear the previous comments and hide previous comment section
        this.com = []; 
        this.showComment[this.feedId1] = false; // Hide the previous comment if any
        this.nodatafound = false; // Reset no data found flag

        this.feedId1 = feedId; // Update the current feedId
    }
    
    console.log("Selected FeedId:", this.feedId1);
    this.showComment[feedId] = !this.showComment[feedId]; // Toggle visibility of the current feed

    this.AnnouncementService.getComments(this.feedId1).subscribe((data: any) => {
        console.log("data", data);
        this.com = data;
        console.log(this.com);
        this.loader = false;
        
        if (data.statusCode == 204 || this.com.length == 0) {
            console.log("No data");
            this.com=[];
            this.nodatafound = true; // Flag for no data found
        }
    });
}

toggled: boolean = false;
message: string = '';

handleSelection(event) {
  console.log(event.char);
  this.content = (this.content || '') + event.char; // Append the selected emoji to `content`
}


  



  

}
