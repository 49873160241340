import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  titles: string = '';

  public title = new BehaviorSubject('');
  public module = new BehaviorSubject('');
  
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const state = this.router.getCurrentNavigation()?.extras.state;
        if (state && state.titles) {
          this.titles = state.titles;
        }
      }
    });
  }
  
  setTitle(title: string) {    
    this.title.next(title);
  }

  setModule(module:string){
   this.module.next(module);
  }


  
  
}
