
<section>
    <div class="sub-header">
      <div class="col-md-12 mb-3">
        <div class="row">
          <div class="">
            <span
              routerLink="/super-admin-home/add-organization"
              class="h5 sub-content"
            >
              {{ clientIDs ? "Edit" : "Add" }} Client
            </span>
          </div>
          <div class="sub-header-buttons">
            <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
          </div>
        </div>
      </div>
    </div>
  </section>





    <div class="common-content">
        <div class="card-new">
          <div class="card">
            <div class="card-body">
    <form action="" [formGroup]="postForm">

    <div class="row">
      <!-- Client-specific fields -->
      <div class="form-group col-md-3">
        <label class="form-control-label"
          >Business Unit <span class="star">*</span></label
        >
        <mat-select
        formControlName="businessUnits"
        placeholder="Select Business Unit"
        class="form-control email-input select-placeholder"
        (selectionChange)="businessunitChange($event)"
      >
        <mat-option value="null" selected (click)="business()"
          >-Select Business Unit-</mat-option
        >
        <mat-option *ngFor="let row of businessUnitlist; let i=index;" [value]="row.businessUnitId">{{row.businessUnitName}} 
        </mat-option>
        <!-- <mat-option value="test">Service Based</mat-option> -->
      </mat-select>
        <div
          class="valid-msg"
          *ngIf="
            (validate.businessUnits?.invalid &&
              validate.businessUnits?.touched) ||
            validate.businessUnits?.dirty ||
            submitted
          "
        >
          <!-- <span
            *ngIf="validate?.businessUnits?.invalid || submitted1"
            class="text-danger"
            >Please select Business Unit</span
          >
          <span
          *ngIf="validate?.businessUnits?.touched"
          class="text-danger"
          >Please select Business Unit1</span
        > -->
        <span
        *ngIf="validate?.businessUnits?.errors?.required"
        class="text-danger"
        >Please select Business Unit</span
      >
          <span
            *ngIf="validate.businessUnits.errors?.pattern"
            class="text-danger"
            >Please select valid Business Unit</span
          >
        </div>
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label"
          >Client Code <span class="star">*</span></label
        >
        <input
          type="text"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="clientCode"
          placeholder="Client Code"
         
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
          placeholder="Client Code "
        />
        <span class="small smallDesign">EX:ABCDE12345</span>

        <div
          class="valid-msg"
          *ngIf="
            (validate.clientCode?.invalid &&
              validate.clientCode?.touched) ||
            validate.clientCode?.dirty ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.clientCode?.errors?.required"
            class="text-danger"
            >Please enter Client Code</span
          >
          <span
            *ngIf="validate.clientCode.errors?.pattern"
            class="text-danger"
            >Please enter valid Client Code</span
          >
        </div>
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label"
          >Client Name <span class="star">*</span></label
        >
        <input
          type="text"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="clientName"
          placeholder="Client Name"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />

        <div
          class="valid-msg"
          *ngIf="
            (validate.clientName?.invalid &&
              validate.clientName?.touched) ||
            validate.clientName?.dirty ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.clientName?.invalid"
            class="text-danger"
            >Please enter Client Name</span
          >
          <span
            *ngIf="validate.clientName.errors?.pattern"
            class="text-danger"
            >Please enter valid Client Name</span
          >
        </div>
      </div>
     
    </div>
    <div class="row">
      <div class="form-group col-md-3">
        <label class="form-control-label"
          >Country <span class="star">*</span></label
        >
        <input
          type="text"
          (keyup)="countryAutoCompleteSearch($event)"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"

          formControlName="countryName"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          placeholder="Country Name"
          autocomplete="off"
        />
        <input
          type="hidden"
          formControlName="countryId"
          id="countryId"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />

        <div
          class="valid-msg"
          *ngIf="
            (validate.countryId?.invalid &&
              validate.countryId?.touched &&
              autoCompleteCountrySearch == 1) ||
            (validate.countryName?.invalid &&
              validate.countryName?.touched) ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.countryId?.errors?.required"
            class="text-danger"
            >Please enter country name.</span
          >
        </div>

        <!-- AutoComplete Search here -->
        <span *ngIf="autoCompleteCountrySearch == 0">
          <ul
            class="auto-complete-country"
            *ngIf="countryData?.length > 0"
          >
            <li
              (click)="
                getFilterData(
                  searchData.countryId,
                  searchData.countryName,
                  1
                )
              "
              *ngFor="let searchData of countryData"
            >
              {{ searchData.countryName }}
            </li>
          </ul>
        </span>
        <!-- AutoComplete End here -->
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label">State<span class="star">*</span> </label>
        <input
          type="text"
          (keyup)="stateAutoCompleteSearch($event)"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="stateName"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          placeholder="State Name"
          autocomplete="off"
        />
        <input
          type="hidden"
          formControlName="stateId"
          id="stateId"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />

        <div
          class="valid-msg"
          *ngIf="
            (validate.stateId?.invalid &&
              validate.stateId?.touched &&
              autoCompleteStateSearch == 1) ||
            (validate.stateName?.invalid &&
              validate.stateName?.touched) ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.stateId?.errors?.required"
            class="text-danger"
            >Please enter state name.</span
          >
        </div>

        <!-- AutoComplete Search here -->
        <span *ngIf="autoCompleteStateSearch == 0">
          <ul
            class="auto-complete-country"
            *ngIf="stateData?.length > 0"
          >
            <li
              (click)="
                getFilterData(
                  searchData.stateId,
                  searchData.stateName,
                  2
                )
              "
              *ngFor="let searchData of stateData"
            >
              {{ searchData.stateName }}
            </li>
          </ul>
        </span>
        <!-- AutoComplete End here -->
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label">District <span class="star">*</span></label>
        <input
          type="text"
          (keyup)="districtAutoCompleteSearch($event)"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="districtName"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          placeholder="District Name"
          autocomplete="off"
        />
        <input
          type="hidden"
          formControlName="districtId"
          id="districtId"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />

        <div
          class="valid-msg"
          *ngIf="
            (validate.districtId?.invalid &&
              validate.districtId?.touched &&
              autoCompleteDistrictSearch == 1) ||
            (validate.districtName?.invalid &&
              validate.districtName?.touched) ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.districtId?.errors?.required"
            class="text-danger"
            >Please enter district name.</span
          >
        </div>

        <!-- AutoComplete Search here -->
        <span *ngIf="autoCompleteDistrictSearch == 0">
          <ul
            class="auto-complete-country"
            *ngIf="districtData?.length > 0"
          >
            <li
              (click)="
                getFilterData(
                  searchData.districtId,
                  searchData.districtName,
                  3
                )
              "
              *ngFor="let searchData of districtData"
            >
              {{ searchData.districtName }}
            </li>
          </ul>
        </span>
        <!-- AutoComplete End here -->
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label">City <span class="star">*</span></label>
        <input
          type="text"
          (keyup)="cityAutoCompleteSearch($event)"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="cityName"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          placeholder="City Name"
          autocomplete="off"
        />
        <input
          type="hidden"
          formControlName="cityId"
          id="cityId"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />

        <div
          class="valid-msg"
          *ngIf="
            (validate.cityId?.invalid &&
              validate.cityId?.touched &&
              autoCompleteCitySearch == 1) ||
            (validate.cityName?.invalid &&
              validate.cityName?.touched) ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.cityId?.errors?.required"
            class="text-danger"
            >Please enter city name.</span
          >
        </div>

        <!-- AutoComplete Search here -->
        <span *ngIf="autoCompleteCitySearch == 0">
          <ul
            class="auto-complete-country"
            *ngIf="cityData?.length > 0"
          >
            <li
              (click)="
                getFilterData(searchData.cityId, searchData.cityName, 4)
              "
              *ngFor="let searchData of cityData"
            >
              {{ searchData.cityName }}
            </li>
          </ul>
        </span>
        <!-- AutoComplete End here -->
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3">
        <label class="form-control-label">Postal Code </label>
        <input
          type="text"
          minlength="6"
          maxlength="6"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="postalCode"
          placeholder="Postal Code"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />
        <div
          class="valid-msg"
          *ngIf="
            (validate.postalCode?.invalid &&
              validate.postalCode?.touched) ||
            validate.postalCode?.dirty ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.postalCode?.errors?.required"
            class="text-danger"
            >Please enter Postal Code</span
          >
          <span
            *ngIf="validate.postalCode?.errors?.pattern"
            class="text-danger"
            >Please enter valid Postal Code</span
          >
        </div>
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label"
          >Address Line 1 <span class="star">*</span></label
        >
        <input
          type="text"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="addressLine1"
          placeholder="Address Line 1"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />
        <div
          class="valid-msg"
          *ngIf="
            (validate.addressLine1?.invalid &&
              validate.addressLine1?.touched) ||
            validate.addressLine1?.dirty ||
            submitted1
          "
        >
          <span
            *ngIf="validate?.addressLine1?.invalid"
            class="text-danger"
            >Please enter Address Line 1</span
          >
          <span
            *ngIf="validate?.addressLine1?.errors?.pattern"
            class="text-danger"
            >Please enter valid Addrss Line 1</span
          >
        </div>
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label">Address Line 2</label>
        <input
          type="text"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="addressLine2"
          placeholder="Address Line 2"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />
        <div
          class="valid-msg"
          *ngIf="
            (validate.addressLine2?.invalid &&
              validate.addressLine2?.touched) ||
            validate.addressLine2?.dirty ||
            submitted1
          "
        >
          <!-- <span *ngIf="validate.addressLine2.errors?.req/uired" class="text-danger">Please enter Address Line 2</span> -->
          <span
            *ngIf="validate?.addressLine2?.errors?.pattern"
            class="text-danger"
            >Please enter valid Addrss Line 2</span
          >
        </div>
      </div>
      <div class="form-group col-md-3">
        <label class="form-control-label">Description</label>
        <input
          type="text"
          (keyup)="validateInputSpace($event)"
          (keydown)="validateInputSpace($event)"
          formControlName="description"
          placeholder="Description"
          class="form-control email-input select-placeholder mb-3 mb-lg-0"
          autocomplete="off"
        />
        <div
          class="valid-msg"
          *ngIf="
         (validate?.description?.touched ||
      validate?.description?.dirty ||
      submitted1) &&
    validate?.description?.errors?.pattern
          "
        >
          <!-- <span *ngIf="validate.addressLine2.errors?.required" class="text-danger">Please enter Address Line 2</span> -->
          <span
           
            class="text-danger"
            >Please enter valid Description</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <a
          routerLink="/home/organization"
          routerLinkActive="active"
          mat-raised-button
          [disabled]="addClientButton"

          class="mr-2 canceldesign"
        >
          Cancel
        </a>
        <button
          type="submit"
          mat-raised-button
         class="btndesign"
         [disabled]="addClientButton"
          (click)="submitClient()"
        >
          Save
        </button>
      </div>
    </div>
</form>
</div>
</div>
</div>
</div>
<div *ngIf="loader" class="align-items-cEnter text-cEnter">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 55%;color: #1a83ff"
    role="status"
  ></div>
</div>
<div class="" *ngIf="success">
  <div class="alert alert-success" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
  <strong>Error!</strong><br />{{ update_error_msg }}
</div>
  <!-- </div> -->