import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,AbstractControl, Validators, FormBuilder }from '@angular/forms';
import { RecruitmentService } from '../providers/recruitment.service';
import { PayrollService } from 'src/app/providers/payroll.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { SettingsService } from 'src/app/providers/settings.service';
import { responseMsgTimeOut}from '../providers/properties'
import { Router,ActivatedRoute} from '@angular/router';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowErrorOfWorkingdaysComponent } from '../show-error-of-workingdays/show-error-of-workingdays.component';

@Component({
  selector: 'app-org-working-days',
  templateUrl: './org-working-days.component.html',
  styleUrls: ['./org-working-days.component.scss']
})
export class OrgWorkingDaysComponent implements OnInit {

  loader:any= false;
  isProcessing = false;
  isprocessing1=false;
  orgWorkingDaysForm:FormGroup;
  filterForm:FormGroup;
  clientdata: any={};
  usertData:any;
  dateFormat: any;
  orgId:number;
  noRecordsFound: boolean =false;
  clientList:any;
  error:any=false;
  resultData : any = [];
  submitted: boolean = false;
  errormessagejson: any;
  success = false;
  sucess_msg:any;
  error_msg:any;
  p: number = 1;
  tableSearch : any;
  errorMsg : any;
  finYearList: any=[];
  pagePerItem:number=5;
  chooseFinId:any;
  finPeriodList: any=[];
  importerror: boolean = false;
  orgWorkingDaysId:any;
  filterData: any=[];
  chooseFinancialyearId: any;
  financialPeriodList: any[];
  perId: any;
  staus: any;
  showtable:boolean=false;
  storeEntityId:any;
  organizationNameData:any[]=[];
   buttonDisabled:boolean =  false;
   clientLogin: boolean = false;
   organizationLogin: boolean = false;
   entityAdminLogin: boolean = false;
  employeetData: any;
orgName:any;
paginationSet:boolean=false;
  constructor(
    private recruitmentservice :RecruitmentService,
    private fb:FormBuilder,
    private dialog:MatDialog,
    private router:Router,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private ActivatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void 
  {
    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;
    let userId = this.usertData?.userId;
    this.orgId = userdata.organization?.organizationId;
    this.orgName= userdata.organization?.organizationName;
    console.log(this.orgId);

    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;

      console.log('this.organizationLogin', this.organizationLogin);
    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;

      console.log('this.clientLogin', this.clientLogin);
    } else {
      this.entityAdminLogin = true;

    }
    
    this.filterForm = this.fb.group({
      filterorg:[null],
      filterfinancialyear:[null],
      filterperiod:[null],
    })

    
    this.orgWorkingDaysForm = this.fb.group({
      organizationName:[null,[Validators.required]],
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      // workingdays:[null,[Validators.required,Validators.pattern(/^(0\.5|[1-9]\d{0,1}(\.\d)?)$/)]]
      workingdays:[null,[Validators.required,Validators.pattern(/^(0\.5|[1-9](\.0|\.5)?|[1-2][0-9](\.0|\.5)?|30(\.0)?)$/
)]]

    })

    
    
    // this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
    //   this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
    //   console.log(this.clientList);
    // })    

    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });

    this.settingsService.getOrganizationListForDropdown(this.storeEntityId).subscribe((response)=>{
    console.log('Entityresponse',response);
    this.organizationNameData =  response.filter(organization =>organization?.organizationStatus?.listTypeValueId == 1);;


    // this.organizationNameData = response;

    })
    console.log("organizationLogin",this.organizationLogin);
    if(this.organizationLogin == true){
      this.filterForm.get('filterorg')?.patchValue(this.orgName);
      this.filterForm.get('filterorg')?.disable();
      console.log('Filterorg Disabled:', this.filterForm.get('filterorg')?.disabled);
    }
    if(this.organizationLogin == true){
      this.orgWorkingDaysForm.get('organizationName')?.patchValue(this.orgName);
      this.orgWorkingDaysForm.get('organizationName')?.disable();
      console.log('organizationName Disabled:', this.orgWorkingDaysForm.get('organizationName')?.disabled);
    }
    console.log('Filterorg Disabled:', this.filterForm.get('filterorg')?.disabled);
    console.log('organizationName Disabled:', this.orgWorkingDaysForm.get('organizationName')?.disabled);

    this.ActivatedRoute.paramMap.subscribe(params => {


      console.log('params', params);
      this.orgWorkingDaysId = params.get('orgWorkingDaysId');
      console.log('this.legalentityId', this.orgWorkingDaysId);

    })

  }

  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  resetForm() {
    console.log("reset came!!!")
    const currentClientName =this.orgName; 
    console.log("currentClientName",currentClientName);
    
  
    this.orgWorkingDaysForm.patchValue({
      financialyear: null,
      organizationName:currentClientName,
      period:null,
      workingdays:null
    });
    this.orgWorkingDaysForm.get('filterclient')?.disable();

  }
  notZeroValidatorAsync(control: AbstractControl) 
  {
    const value = control.value;
    const regex =/^(?:\d\.\d{1,2}|\d{1})$/; 
    return new Promise((resolve) => {
      if (!regex.test(value.toString()) ) {
        console.log("Not Vaild");
        resolve({ notZero: true }); // Return an object with a custom error key if value is zero
      } else {
        console.log("Vaild");
        resolve(null); // Return null if the value is not zero, indicating the control is valid
      }
    });
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  getorworkingdayslist()
  {
    this.loader=true;
    this.payrollservice.getorgWorkingDaysbyorganList(this.orgId).subscribe((filterData:any) =>
    {
      this.noRecordsFound = false;
      this.filterData = filterData;
      console.log(this.filterData);
      if(this.filterData.length == 0 || this.filterData.length == undefined)
      {
        this.noRecordsFound = true;
      }
      if(this.filterData.statusCode == 204){
        this.noRecordsFound = true;
      }
      this.loader=false;
    })
  }
 
  exportToExcel()
  {
    console.log("Export");
  }
  
  searchSubmit()
  {
    this.p=1;
    const currentClientName = this.orgName;

    // Reset the entire form while preserving "filterclient"
    this.filterForm.patchValue({
      
      filterorg: currentClientName,
      
    });
    if (!this.filterForm.value.filterperiod) {
      this.filterForm.patchValue({ filterperiod: null });
    }
    console.log("period::",this.filterForm.value.filterperiod)
  
  
    // Re-disable the Client Name field
    this.filterForm.get('filterorg')?.disable();
    // this.filterData=[];
    // this.showtable=true;
    // this.loader=true;
    // this.isProcessing = true; 
    console.log("Submit",this.filterForm.value);
    if((this.filterForm.value.filterfinancialyear==="null"||this.filterForm.value.filterfinancialyear===null)&&!this.filterForm.value.filterperiod && (this.filterForm.value.filterorg==="null"||this.filterForm.value.filterorg===null))
    {

     alert("Please select any of the fields.");
      this.filterData=[];
      this.noRecordsFound = false;
      this.paginationSet=false;
      // this.getorworkingdayslist();
      this.loader=false;
      // this.isProcessing = false; 
      this.showtable=false;
     // return;
    }

    if((this.filterForm.value.filterfinancialyear!=="null" && this.filterForm.value.filterfinancialyear!==null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null) && (this.filterForm.value.filterorg!=="null"&&this.filterForm.value.filterorg!==null))
      {
       alert("Please select period as well.");
        this.filterData=[];
        this.noRecordsFound = false;
        this.paginationSet=false;

        // this.getorworkingdayslist();
        this.loader=false;
        // this.isProcessing = false; 
        this.showtable=false;
       // return;
      }

    if (
      (this.filterForm.value.filterfinancialyear !== "null" && this.filterForm.value.filterfinancialyear !== null) &&
      (this.filterForm.value.filterperiod === "null" || this.filterForm.value.filterperiod === null) &&
      (this.filterForm.value.filterorg === "null" || this.filterForm.value.filterorg === null)
    )
    {
      console.log("Finalcial Year only");
      this.isProcessing = true; 
      this.loader =  true;
      this.paginationSet=false;

      this.payrollservice.getworkingdaysYearList(this.filterForm.value.filterfinancialyear,this.orgId).subscribe((filterData:any) =>
      {
        this.showtable=true;
        this.isProcessing = false; 
        this.noRecordsFound = false;
        this.paginationSet=true;

        this.filterData = filterData;
        this.loader =  false;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;

        }
        this.loader=false;
        this.isProcessing = false; // Disable the button

      })
    }
    else if((this.filterForm.value.filterfinancialyear!=="null"||this.filterForm.value.filterfinancialyear!==null)&&(this.filterForm.value.filterperiod!=="null"&&this.filterForm.value.filterperiod!==null)&&
    (this.filterForm.value.filterorg==="null"||this.filterForm.value.filterorg===null))
    {
      this.isProcessing = true; 
      this.loader =  true;
      this.paginationSet=false;

      console.log("Finalcial and Period");
      this.payrollservice.getorgFinanicalandPeriodList(this.filterForm.value.filterperiod,this.orgId).subscribe((filterData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.filterData = filterData;
        this.loader =  false;
        this.paginationSet=true;

        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
          this.paginationSet=true;


        }
        this.loader=false;
        this.isProcessing = false; // Disable the button

      })
    } else if((this.filterForm.value.filterfinancialyear!=="null"&&this.filterForm.value.filterfinancialyear!==null)&&(this.filterForm.value.filterperiod!=="null" && this.filterForm.value.filterperiod!==null))
      {
        console.log("Finalcial and Period");
        this.isProcessing = true;
        this.loader =  true;
        this.paginationSet=false;

        this.settingsService.searchFinancialYearPeriodOrg(this.filterForm.value.filterfinancialyear,this.filterForm.value.filterperiod,this.orgId).subscribe((filterData:any) =>
        {
          this.showtable=true;
  
          this.noRecordsFound = false;
          this.filterData = filterData;
          this.loader =  false;
          this.paginationSet=true;

          console.log(this.filterData);
          if(this.filterData.length == 0 || this.filterData.length == undefined)
          {
            this.noRecordsFound = true;
          }
          if(this.filterData.statusCode == 204){
            this.noRecordsFound = true;
            this.paginationSet=true;

  
          }
          this.loader=false;
          this.isProcessing = false; // Disable the button
  
        })
      }else if((this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null" || !this.filterForm.value.filterperiod)&&(this.filterForm.value.filterorg!=="null" && this.filterForm.value.filterorg!==null))
        {
          console.log("Finalcial and Period");
          this.isProcessing = true;
          this.loader =  true;
          this.paginationSet=false;

          this.payrollservice.getWorkingDaysListOnlyByOrganization( this.orgId).subscribe((filterData:any) =>
          {
            this.showtable=true;
            this.noRecordsFound = false;
            this.filterData = filterData;
            this.loader =  false;
            this.paginationSet=true;

            console.log(this.filterData);
            if(this.filterData.length == 0 || this.filterData.length == undefined)
            {
              this.noRecordsFound = true;
            }
            if(this.filterData.statusCode == 204){
              this.noRecordsFound = true;
              this.paginationSet=true;

    
            }
            this.loader=false;
            this.isProcessing = false; // Disable the button
    
          })
        }else if((this.filterForm.value.filterfinancialyear=="null" && this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null" && this.filterForm.value.filterperiod==null)&&(this.filterForm.value.filterorg!=="null" && this.filterForm.value.filterorg!==null))
        {
          console.log("Finalcial and Period");
          this.isProcessing = true;
          this.loader =  true;
          this.paginationSet=false;

          this.settingsService.listworkingdaysBasedYearPeriodOrg(this.filterForm.value.filterfinancialyear,this.filterForm.value.filterperiod,this.filterForm.value.filterorg).subscribe((filterData:any) =>
          {
            this.showtable=true;
    
            this.noRecordsFound = false;
            this.filterData = filterData;
            this.loader =  false;
            this.paginationSet=true;

            console.log(this.filterData);
            if(this.filterData.length == 0 || this.filterData.length == undefined)
            {
              this.noRecordsFound = true;
            }
            if(this.filterData.statusCode == 204){
              this.noRecordsFound = true;
              this.paginationSet=true;

    
            }
            this.loader=false;
            this.isProcessing = false; // Disable the button
    
          })
        }
   
  }
 
  selectFilterFinancialyear() {
    console.log("Financial year selected");
    this.chooseFinancialyearId = this.filterForm.get('filterfinancialyear').value;
    console.log(this.chooseFinancialyearId);
  
    // Clear financial period when financial year is null
    if (this.chooseFinancialyearId == null || this.chooseFinancialyearId === 'null') {
      console.log('No financial year selected, clearing period list');
      this.financialPeriodList = [];
      this.filterForm.patchValue({ filterperiod: null }); // Clear period field
    } else {
      this.settingsService.getFinancialYearId(this.chooseFinancialyearId).subscribe((resultData: any) => {
        console.log("Period Data", resultData);
        this.financialPeriodList = resultData.finanicalPeriod;
        this.filterForm.patchValue({ filterperiod: null }); // Reset period field
      });
    }
  }
  
   onSubmit()
   {
 
  
  
    // Re-disable the Client Name field
    this.filterForm.get('filterorg')?.disable();
    this.isprocessing1 = true; // Disable the button
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    let clientId=this.employeetData?.employee?.client?.clientId
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;
    let clientDetailStore=clientId?{clientId:clientId}:null;
    // console.log('organizationId'+organizationId)
    // this.submitted=true;
    if(this.orgWorkingDaysForm.invalid)
    {
      this.isprocessing1 = false; // Enable the button if the form is invalid

      console.log("form invaild");
      return;
    }
    console.log("submit",this.orgWorkingDaysForm.value);
    console.log(this.orgWorkingDaysId);
    this.loader=true;

    if(this.orgWorkingDaysId)
    {
      this.submitted=false;

      console.log("Update"+this.orgWorkingDaysId);
      const updateWorkingDays= 
      {
        orgWorkingDaysId:this.orgWorkingDaysId,
        workingDays:this.orgWorkingDaysForm.value.workingdays,
        financialPeriod:
        {
        financialPeriodId:this.orgWorkingDaysForm.value.period
        },
        // organization:
        // {
        //  organizationId:this.orgWorkingDaysForm.value.organizationName
        // },
        organization:organizationIstore,
       status:{
          listTypeValueId:this.staus
        }
      }
      console.log(updateWorkingDays);
      // return
      this. payrollservice.updateorgWorkingDays(this.orgWorkingDaysId,updateWorkingDays).subscribe((result: any) =>
        {
          console.log("response::",result);
          if (Array.isArray(result)) {
            result.forEach((descript: any) => {
              if (descript.statusCode === 409) {
                this.error = true;
                this.error_msg = descript.description;
                this.loader = false;
                this.isprocessing1 = false;
                setTimeout(() => {
                  this.error = false;
                }, 5000);
              }
            });
          } else {
            // Handle result as an object
            if (result?.statusCode === 409) {
              this.error = true;
              this.error_msg = result.description;
              this.loader = false;
              this.isprocessing1 = false;
              setTimeout(() => {
                this.error = false;
              }, 5000);
            }
          }
          const currentClientName = this.orgName;

          //  this.orgWorkingDaysForm.patchValue({
                  
          //   financialyear: null,
          //   organizationName:currentClientName,
          //   period:null,
          //   workingdays:null              
          // });
          // this.orgWorkingDaysForm.markAsUntouched();
          this.orgWorkingDaysForm.reset();
    
          // Reapply values for specific fields (e.g., organizationName)
          this.orgWorkingDaysForm.patchValue({
            organizationName: currentClientName,
          });
        
          
          if(result.statusCode == 200)
          {
            this.success = true;
            this.orgWorkingDaysId=0;
            console.log("this.orgWorkingDaysId"+this.orgWorkingDaysId);
            this.sucess_msg = result.description;
            this.orgWorkingDaysForm.controls['period']?.patchValue('');
            this.orgWorkingDaysForm.get('financialyear')?.patchValue('')
            this.orgWorkingDaysForm.get('workingdays')?.patchValue('')

            // if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
            //  ((this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
            // {
            //   console.log("Filter Call Method",this.filterForm.value);
            //   this.searchSubmit();
            // }
            if((this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null))
            {
              console.log("Without",this.filterForm.value);
              this.searchSubmit();
            }
            else{
              console.log("Filter Call Method",this.filterForm.value);
              this.searchSubmit();
            }
            setTimeout(() => {this.success  = false;
             
            },redirectMsgTimeOut)

            // this.clientupdateId = null;
            // this.orgWorkingDaysForm.reset();
           
            this.submitted = false;
            this.showtable=false;
            // setTimeout(() => {
            //   this.router.navigate(["home/employee-rating-list"])
            // }, redirectMsgTimeOut)
          }
          
          else if(result.statusCode == 409)
          {
            console.log("Already Exist");
          // this.importerror = true;
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          setTimeout(() => {this.error = false}, 5000)
          }
          else
          {
          console.log("err1",result.statusCode);
          this.error = true;
          // this.importerror = true;
          this.errormessagejson = result;
          // console.log('this.errormessagejson',this.errormessagejson);
          //  this.error_msg = result.description;
          //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
          this.loader=false;
          this.isprocessing1 = false; // Disable the button

        },
         err =>
            {
                console.log("code1",err.statusCode);
                setTimeout(() => {
                  this.error = true;
                  this.errorMsg = err.error.description;
                }, redirectMsgTimeOut);
                this.loader=false;
                this.isprocessing1 = false; // Disable the button

            })
    }
    else
    {
      console.log("create");
      const createWorkingDays= 
      {
        workingDays:this.orgWorkingDaysForm.value.workingdays,
        financialPeriod:
        {
            financialPeriodId:this.orgWorkingDaysForm.value.period
        },
        // organization:
        // {
        //  organizationId:this.orgWorkingDaysForm.value.organizationName
        // },
        organization:organizationIstore,

    }
    console.log(createWorkingDays);
    // return
    this.payrollservice.createorgWorkingDays(createWorkingDays).subscribe((result: any) =>
    {
      console.log("Create",result);

      if (Array.isArray(result)) {
        result.forEach((descript: any) => {
          if (descript.statusCode === 409) {
            this.error = true;
            this.error_msg = descript.description;
            this.loader = false;
            this.isprocessing1 = false;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          }
        });
      } else {
        // Handle result as an object
        if (result?.statusCode === 409) {
          this.error = true;
          this.error_msg = result.description;
          this.loader = false;
          this.isprocessing1 = false;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
      }
      const currentClientName = this.orgName;

      //  this.orgWorkingDaysForm.patchValue({
              
      //   financialyear: null,
      //   organizationName:currentClientName,
      //   period:null,
      //   workingdays:null              
      // });
      // this.orgWorkingDaysForm.markAsUntouched();
      this.orgWorkingDaysForm.reset();

      // Reapply values for specific fields (e.g., organizationName)
      this.orgWorkingDaysForm.patchValue({
        organizationName: currentClientName,
      });
    

     if(result.statusCode ===200)
     {
       this.success = true;
       this.loader=false;
       this.sucess_msg = result.description; 
       
      // Update validity and mark as pristine to clear validation messages
      this.orgWorkingDaysForm.updateValueAndValidity();
      this.orgWorkingDaysForm.markAsPristine();
      this.orgWorkingDaysForm.markAsUntouched();
      //  if((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)||(this.filterForm.value.filterfinancialyear=="null"||(this.filterForm.value.filterfinancialyear==null))||((this.filterForm.value.filterfinancialyear=="null")||(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null))||
      //  ((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null)))
      //  {
      //    console.log("Filter Call Method",this.filterForm.value);
      //   //  this.searchSubmit();
      //  }
      // if((this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null))
      // {
      //   console.log("Without",this.filterForm.value);
      //   // this.searchSubmit();
      // }
      // else{
      //   console.log("Filter Call Method",this.filterForm.value);
      //   this.searchSubmit();
      // }
      if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
      ((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
      {
        this.searchSubmit();
      }
      if((this.filterForm.value.filterclient==null || this.filterForm.value.filterclient== 'null' )){
        console.log("Without",this.filterForm.value);

      }
       setTimeout(() => {this.success  = false;
        this.submitted=false;
      
        

        
       },redirectMsgTimeOut)
      //  this.orgWorkingDaysForm.reset(); 
      }
    //   else if(result.statusCode == 409)
    //     {
    //       console.log("Already Exist");
    //       this.error = true;
    //     // this.importerror = true;
    //     // this.error_msg = 'Organization Financial Year and Period and Working Days Already Exist';
    //  this.errormessagejson = result;
    //  setTimeout(() => {this.error = false}, 5000)
       
    //     }
    
     else
     {
       console.log("err1",result.statusCode);
      //  console.log("err1",result.clientCode);
       this.error = true;
        this.importerror = true;
       this.errormessagejson = result;
      //  console.log('this.errormessagejson',this.errormessagejson);
      //  this.error_msg = result.description;
      //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
     }
     this.loader=false;
     this.isprocessing1 = false; // Disable the button

   }, 
   err =>
   {
     console.log("code",err);
     console.log("code",err.statusCode);
       setTimeout(() => {
         this.error = true;
         this.errorMsg = err.error.description;
       }, redirectMsgTimeOut) ;
       this.loader=false;
       this.isprocessing1 = false; // Disable the button

   })
      
    }
    
   }

   updateform(row)
   {
    // this.clearForm();
    console.log("clear Form",row);
    this.orgWorkingDaysId=row.orgWorkingDaysId;
    console.log(this.orgWorkingDaysId);
    this.loader = true;
    this.payrollservice.getorgWorkingDaysList(this.orgWorkingDaysId).subscribe((resultData:any) =>
    {
      console.log("Api1",resultData);
      this.resultData=resultData;
      this.staus=resultData.status.listTypeValueId;
      console.log(this.staus);
      console.log(this.resultData);
      console.log(this.orgWorkingDaysForm);

             console.log("this.orgWorkingDaysForm 1", this.orgWorkingDaysForm.value);
             this.settingsService.getFinancialYearId(this.resultData.financialPeriod.finanicalYear.financialYearId).subscribe(
            (resultData1: any) => {

              console.log('resultData1',resultData1);
              
              console.log("resultData1", resultData1.finanicalPeriod);
              this.finPeriodList = resultData1.finanicalPeriod;
              console.log("resultData2", this.finPeriodList);
              this.perId=this.resultData.financialPeriod.financialPeriodId;
              console.log("perId", this.perId);
              // this.orgWorkingDaysForm.get('period').patchValue(this.resultData.financialPeriod.financialPeriodId); 
              console.log("patchvalue");
              setTimeout(() => {
                this.orgWorkingDaysForm.patchValue({
                  organizationName:this.orgName,
                  financialyear : this.resultData.financialPeriod.finanicalYear.financialYearId,
                  period : this.resultData.financialPeriod.financialPeriodId, 
                  workingdays : this.resultData.workingDays
                });
                this.loader = false;
              },50);
              console.log("this.orgWorkingDaysForm 2", this.orgWorkingDaysForm.value);
            });
    })
   }

orgWorkingDaysIdage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

selectFinancialyear()
{
  console.log("Select indidual Employee");
  // console.log(event.target.value);
  this.orgWorkingDaysForm.get('financialyear').value;
  // console.log(this.filterForm.value.financialyear);
   this.chooseFinId=this.orgWorkingDaysForm.get('financialyear').value;
  console.log(this.chooseFinId);
  if(this.chooseFinId == null || this.chooseFinId == 'null'){
    console.log('if');
    this.finPeriodList = [];
  }
  else{
  this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => 
  {
    console.log("PeriodData", resultData)
    this.finPeriodList = resultData.finanicalPeriod;
    console.log("finPeriodList",  this.finPeriodList)
    this.orgWorkingDaysForm.patchValue({
      period: null,
    });
  })
  console.log("Inside selectFinancialyear func",this.orgWorkingDaysForm);
}
}

clearTable()
{
  // this.isProcessing=true;
  // this.resultData = [];
  // this.noRecordsFound = false;
  const currentClientName = this.orgName;

  // Reset the entire form while preserving "filterclient"
  this.filterForm.patchValue({
    filterfinancialyear: null, 
    filterorg: currentClientName,
    filterperiod:null
  });


  // Re-disable the Client Name field
  this.filterForm.get('filterorg')?.disable();
  this.filterData=[];
  this.noRecordsFound = true;
  console.log('clear')
  // this.isProcessing=false;
this.showtable=false;
  this.filterForm.clearValidators;
  this.financialPeriodList=[];
}

clearForm() 
{
  this.orgWorkingDaysForm.reset(); // This resets all form controls to their initial values
}

pageChages()
{
    console.log("page changes");
    this.p=1;
}

get validate() 
{ 
  return this.orgWorkingDaysForm.controls; 
}
  
showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowErrorOfWorkingdaysComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

 updateStatus(id,status)
	{
    console.log(id,status);
    this.payrollservice.deactiveWorkingDays(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}


}
