<style>
  .full-width{
    width:100%;
  }
</style>
<div class="container">
  <div class="form-group">
    <form [formGroup]="exceptionForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
       
          <label class="col-form-label">Exception Name<span class="star">*</span></label>
          <input  formControlName="exceptionName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder="Exception Name" autocomplete="off" />
          <mat-error *ngIf="f.exceptionName.hasError('required')">
            Please enter exception name
          </mat-error>
      
      </div>

      <div class="form-group">    
        <label class="col-form-label">Date <span class="star">*</span></label>
        <input  formControlName="exceptionDate"  id="holidayDate" matInput [matDatepicker]="holidayDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
            placeholder="Select Date"  >
        <mat-datepicker-toggle matSuffix [for]="holidayDate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
        <mat-datepicker #holidayDate   
        panelClass="example-month-picker"></mat-datepicker>
        <mat-error *ngIf=" f.exceptionDate.hasError('required')">
          Please select <strong>Exception Date</strong>
        </mat-error>
    
      </div>


      <!-- <div class="form-group">
       
          <label class="col-form-label">Date<span class="star">*</span></label>
          <input class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
            
            (click)="picker.open()"
            formControlName="exceptionDate"
            [matDatepicker]="picker"
            placeholder="select date"
            [disabled] = "dateDisable"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi></mat-datepicker>
          <mat-error *ngIf=" f.exceptionDate.hasError('required')">
            Please select <strong>Exception Date</strong>
          </mat-error>
      
      </div> -->

      <div class="form-group" *ngIf="data.id">
        <label class="col-form-label">Exception Status<span class="star">*</span></label>
          <select formControlName="exceptionStatus" class="form-select form-select-solid form-select-lg select2-new">
            <option value="1">
              Active
            </option>
            <option value="2">
              In-Active
            </option>
          </select>
          <mat-error *ngIf="f.holidayStatus.hasError('required')">
            <span>Exception Status is required</span>
          </mat-error>
      
      </div>

    

      <div class="form-group">
        <button mat-stroked-button color="accent" style="margin: 0 0 0 5px" type="button"
        mat-dialog-close>
          Cancel
        </button>
        <button type="submit" mat-raised-button color="primary">Save</button>

      </div>

    </form>
  </div>
</div>
