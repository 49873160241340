import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { AppComponent } from 'src/app/app.component';
import {ExcelService } from '../services/excel.service';
import { SettingsService } from 'src/app/providers/settings.service';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import { EmployeeratingimportComponent } from '../employeeratingimport/employeeratingimport.component';
@Component({
  selector: 'app-employee-ratinglist',
  templateUrl: './employee-ratinglist.component.html',
  styleUrls: ['./employee-ratinglist.component.scss']
})
export class EmployeeRatinglistComponent implements OnInit {
  showtable:boolean=false;
  isProcessing = false;

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=5;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  
  
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any) { }

  ngOnInit(): void {
    this.noRecordsFound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.clientId=this.usertData?.employee?.client?.clientId;
    console.log(this.orgId);

    this.clientId = this.usertData.employee?.client?.clientId;
    console.log(this.clientId);

    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null],
      period:[null],
    })
    this.loader=true;


    if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
      this.loader=false;
    });
  }
      

  if(this.clientId){
    this.settingsService.getFinicalyearActiveListClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
      this.loader=false;

    });
  }
  }

  addRating()
  {
    console.log("create emp rating");
    this.router.navigateByUrl('/home/employee-rating');
  }
  onItemsPerPageChange(event: any) {
    this.pagePerItem = event.target.value;
    this.p = 1; // Reset the pagination to the first page when items per page is changed
  }
  searchSubmit()
  {
    this.showtable=true;
    this.isProcessing=true;
   this.p=1;
    console.log("submit");
    this.req=false;
    this.loader=true;

    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   // alert('Please enter employee number');
    //   return;
    // }
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null'))
    {
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = false;
      this.loader = false; // Stop loader after processing
      this.showtable=false;
      this.isProcessing=false;

      alert("Please Enter Employee Number or Financial Year");
      // this.req=true;
    }
    // if(((this.filterForm.value.employeecode!=''||this.filterForm.value.employeecode!=null)&&this.filterForm.value.financialyear==null)||(this.filterForm.value.employeecode==''||this.filterForm.value.employeecode==null)&&this.filterForm.value.financialyear!=null)
    // {
    //   this.req=false;
    // }
    console.log(this.filterForm.value);
    if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&this.filterForm.value.financialyear!=null&&this.filterForm.value.period!=null)
    {
      console.log("both");
      this.payrollservice.getemployeerating(this.filterForm.value.financialyear,this.filterForm.value.period,this.filterForm.value.employeecode).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader = false; // Stop loader after processing

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&this.filterForm.value.financialyear!=null&&(this.filterForm.value.period==null||this.filterForm.value.period=='null'))
    {
      console.log("Finanical year only");
      this.payrollservice.getyearbased(this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader = false; // Stop loader after processing

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&this.filterForm.value.financialyear!=null&&this.filterForm.value.period!=null)
    {
      console.log("finatical and period");
      this.payrollservice.getyearandperiod(this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader = false; // Stop loader after processing

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
   
    else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear==null||this.filterForm.value.financialyear=='null')&&this.filterForm.value.period==null)
    {
      console.log("Employeecode only");

      if(this.orgId){
      this.payrollservice.getemployeecodeOrg(this.filterForm.value.employeecode,this.orgId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;

        this.resultData = resultData;
        this.loader = false; // Stop loader after processing

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    if(this.clientId){
      this.payrollservice.getemployeecodeClient(this.filterForm.value.employeecode,this.clientId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;

        this.resultData = resultData;
        this.loader = false; // Stop loader after processing

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    }
  }
  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear()
  {
    console.log("Select");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    //  this.chooseFinId=event.target.value;
    this.chooseFinId = this.filterForm.controls['financialyear'].value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        period: null,
      });
    })
  }
  }

  importEmployeeRating()
  {
    console.log("import Employee");
    const dialogRef = this.dialog.open(EmployeeratingimportComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });
  
    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else 
      {
         if(result.length != 0)
         {
          console.log("Import Error");
          this.error = true;
          //this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;

          setTimeout(()=>{
            this.error = false;
            this.error_msg = '';
            this.importerror = false;
            this.errormessagejson =  '';
          },3000);
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         }
          else
          {
          if(result.statusCode > 400)
          {
            this.error = true;
            this.importerror = false;

            setTimeout(()=>{
              this.error = false;
              this.error_msg = '';
              this.importerror = false;
            },3000);
          }
          }
      }
    });
  }

  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.ratingId=row.employeerattingId;
    console.log(this.ratingId);
    // this.router.navigateByUrl('/home/edit-employee-rating/',row.employeerattingId);
    this.router.navigate(['/home/edit-employee-rating/', this.ratingId])
  }
  clearTable()
  {
    this.resultData = [];
    console.log('clear')
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
    this.finPeriodList=[];
    this.showtable=false;
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  validateInputs(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }
}
