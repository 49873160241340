import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private successMessageSubject = new BehaviorSubject<string>('');
  private successUpdateMessageSubject =  new BehaviorSubject<string>('');
private errorMessageSubject =  new BehaviorSubject<string>('');
  constructor() { }

  setSuccessMessage(message: string) {
    this.successMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getSuccessMessage() {
    return this.successMessageSubject.asObservable();
  }

  clearSuccessMessage() {
    this.successMessageSubject.next('');
  }

  setSuccessUpdateMessage(message: string) {
    this.successUpdateMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getSuccessUpdateMessage() {
    return this.successUpdateMessageSubject.asObservable();
  }

  clearSuccessUpdateMessage() {
    this.successUpdateMessageSubject.next('');
  }

  setErrorMessage(message: string) {
    this.errorMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getErrorMessage() {
    return this.errorMessageSubject.asObservable();
  }

  clearErrorMessage() {
    this.errorMessageSubject.next('');
  }
}
