import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router ,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ExcelService } from '../services/excel.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import {PayrolldataService} from '../services/payrolldata.service';

@Component({
  selector: 'app-clientemployee-payroll-list',
  templateUrl: './clientemployee-payroll-list.component.html',
  styleUrls: ['./clientemployee-payroll-list.component.scss']
})
export class ClientemployeePayrollListComponent implements OnInit {

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =true;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: any;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router, private payrolldataService :  PayrolldataService , 
    @Inject(AppComponent) public AppData: any) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null],
      period:[null],
      client:[null],
    })

    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });

    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      console.log("this.organizationSettings",this.organizationSettings);
      
    });

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
  }

  getclientEmployees()
  {
      this.empList.length = 0;
      console.log(this.filterForm.value.client);
      this.filterForm.value.employeecode = null;
      this.filterForm.patchValue({
        employeecode:null
      })
      if(this.filterForm.value.client != null && this.filterForm.value.client != "null")
      {
        this.payrollservice.getemployeeclient(this.filterForm.value.client).subscribe((ClientEmployees: any) => {
          if(ClientEmployees.length != undefined)
          {
           this.empList = ClientEmployees;
          }
         
        });
    }
  }

  empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      // this.payrollservice.getEmployeeNameList(employeenumber, this.orgId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }
      
      // })
      this.payrollservice.getClienEmployeeList( this.filterForm.value.employeecode , this.filterForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode)
        {
          
        }else{
          this.empList =  result;
        }
     
     })
    }
    
  }

  empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
  
      this.filterForm.patchValue({
        employeecode: this.employee 
     });
  }

  addpayroll(){
    this.router.navigateByUrl('/home/client-employee-payroll');
  }
  searchSubmit()
  {
    console.log("submit");
    this.req=false;
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   // alert('Please enter employee number');
    //   return;
    // }
    // if(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null){
    //   alert('Please select employee');
    //   return;
    // }
    if((this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')
    &&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null')){
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = true;
      alert("Please select Finicalyear and period");
      return;
    }
    if((!this.filterForm.value.employeecode || this.filterForm.value.employeecode == 'null') 
    && (!this.filterForm.value.client || this.filterForm.value.client == 'null')){
      alert('Please select client');
      return;
    }
    console.log(this.filterForm.value);
    if((this.filterForm.value.period === null || this.filterForm.value.period === 'null' )){
      if((this.filterForm.value.financialyear === null || this.filterForm.value.financialyear === 'null')){
        console.log("only client and employee");
        this.payrollservice.getemployeepayrollByEmployeeAndClient(this.employeeId,this.filterForm.value.client).subscribe(
          (resultData:any)=>{
            this.noRecordsFound = false;
            this.resultData = resultData;
            this.calucatetotal();
            console.log(this.resultData);
            if(this.resultData.length == 0 || this.resultData.length == undefined){
              this.noRecordsFound = true;
            }
            if(resultData.statusCode == 204){
              this.noRecordsFound = true;
            }
          }
        )
      }
      else{
        console.log("without period");
        this.payrollservice.getemployeepayrollByEmployeeAndClientFinancialyear(this.employeeId,this.filterForm.value.client,this.filterForm.value.financialyear).subscribe(
          (resultData:any) =>{
          this.noRecordsFound = false;
          this.resultData = resultData;
          this.calucatetotal();
          console.log(this.resultData);
          if(this.resultData.length == 0 || this.resultData.length == undefined){
            this.noRecordsFound = true;
          }
          if( resultData.statusCode == 204){
            this.noRecordsFound = true;
          }
        })
      }
    }
    else if (this.filterForm.value.client && this.filterForm.value.period && !this.employeeId){
      console.log("all");
      this.payrollservice.getemployeepayrollByClientAndFinancialperiod(this.filterForm.value.client,this.filterForm.value.period).subscribe(
        (resultData:any) =>{
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.payrolldataService.setpayrolldata(this.resultData);
        this.calucatetotal();
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined){
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    } else{
      console.log("all");
      this.payrollservice.getemployeepayrollByEmployeeAndClientAndFinancialperiod(this.employeeId,this.filterForm.value.client,this.filterForm.value.period).subscribe(
        (resultData:any) =>{
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.calucatetotal();
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined){
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    } 
    



  }


  calucatetotal()
  {
    this.totalctc =  0;
    this.totalamount =  0;
    this.totalEarningYtd =  0;
    this.totalDedutionYtd =  0;
    if(this.resultData.length == undefined){
      this.noRecordsFound = true;
    }
    else{
      this.resultData.forEach(element => {
        console.log("element.employeeAnnexure.ctc" + element.employeeAnnexure.ctc)
         let ctc =this.showmontlyctc(element,element.employeeAnnexure.ctc);
         console.log("ctc" + ctc)
        this.totalctc += Number(ctc);
         this.totalctc.toFixed(2);
        this.totalamount += element.totalAmount;
        this.totalEarningYtd +=   element.totalEarningYtd;
        this.totalDedutionYtd +=  element.totalDedutionYtd;
      });
    }
  }

  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear(event){
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      this.filterForm.patchValue({
        period: null
     });
    }
    else{
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
      });
    }
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.ratingId=row.employeerattingId;
    console.log(this.ratingId);
    // this.router.navigateByUrl('/home/edit-employee-rating/',row.employeerattingId);
    this.router.navigate(['/home/view-employee-payroll/', this.ratingId])
  }

  showmontlyctc(row , ctcamount){
      console.log(row);
    if(row.employeeAnnexure.annexureType.listTypeValueId == 59)
    {
     
    }else{
      ctcamount = ctcamount / 12 
    }
  
   return  Number(ctcamount).toFixed(2);
  }


}
