<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Pay Slip</span>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                    <!-- </div>
                    <div class="row"> -->
                        <div class="form-group col-xl-3 col-lg-3 col-md-4">
                            <div class="input-width" id="loginform">
                                <!-- <label class="col-control-label">Financial Year<span class="text-danger"> *</span></label> -->
                                <label class="form-control-label"> Financial year </label>
                                <mat-select formControlName="financialyear" class="form control
                                select-placeholder
                                email-input" placeholder=" -- Select Financial Year --" style="font-family:Mulish;border-radius: 5px;" (selectionChange)="selectFinancialyear()" >
                                    <mat-option [value]=null> -- Select Financial Year -- </mat-option>
                                    <mat-option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" style="color: black;">{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</mat-option> 
                                </mat-select>
                                <!-- <mat-error *ngIf="submitted && (filterForm.controls['financialyear'].errors?.required || financialyearError)">
                                    Financial year is required
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="form-group col-xl-3 col-lg-3 col-md-4">
                            <div class="input-width" id="loginform">
                                <!-- <label class="col-control-label">Period<span class="text-danger"> *</span></label> -->
                                <label class="form-control-label"> Period </label>
                                <mat-select formControlName="period" class="form control
                                select-placeholder
                                email-input" style="font-family:Mulish;border-radius: 5px;" placeholder=" -- Select Period --" >
                                    <mat-option [value]=null> -- Select Period -- </mat-option>
                                    <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}" style="color: black;">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option>
                                </mat-select>
                                <!-- <mat-error *ngIf="submitted && filterForm.controls['period'].errors?.required">
                                    Period is required
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="form-group col-md-2 col-xl-1 button-margin">
                            <!-- <button type="submit" mat-raised-button color="primary"
                                class="btn btn-primary search-btn">Search</button> -->

                                <button type="submit" mat-raised-button
                                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button" [disabled]="isProcessing"
                                >
                                Search
                            </button>

                            <button mat-stroked-button type="reset" class="apply-buttons" (click)="clearTable()" [disabled]="isProcessing"
                          
                            style="background:white;border: 1px solid #ddd;position: relative ;
                            top: -70%; margin-left:83px; font-family: Mulish; color: black; font-size: 16px; font-weight: 610;">Clear</button>
                        </div>
                    </div>
                </form>
                <div *ngIf="showtable && !loader && ManagePayslipdata.length >5" class="">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="select-pagination">
                                <span style="font-size: 12px;">Show : </span>
                                <select class="select_list new-select_list" [(ngModel)]="mySelect" (change)="showChanges()">
                                    <option class="form-control" value="5"> 5</option>
                                    <option class="form-control" value="10">10</option>
                                    <option class="form-control" value="15">15</option>
                                    <option class="form-control" value="20">20</option>
                                    <option class="form-control" value="25">25</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showtable && !loader" class="table-responsive">
                    <table class="table --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <th class="text-center">Action</th>
                                <th class="text-center">Financial Year</th>
                                <th class="text-center">Period</th>
                                <th>Employee Name</th>
                                <th class="text-center">Employee Code</th>
                                <th class="text-center">Location</th>
                                <th>Designation</th>
                                <th class="text-center">Created Date</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">CTC ( {{organizationSettings?.country?.currencyCode}} )</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">Amount ( {{organizationSettings?.country?.currencyCode}} )</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">Earning YTD ( {{organizationSettings?.country?.currencyCode}} )</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">Deduction YTD ( {{organizationSettings?.country?.currencyCode}} )</th>
                            </tr>
                        </thead>
                        <tbody class="t-body">
                            <tr *ngFor="let e of ManagePayslipdata | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                <td class="text-center tab-medium-width">
                                    <button class="menu-button"
                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon class="menu-icon">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="viewpayslip(e.payrollHeaderId)" class="mat-dropdowns">
                                            <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                            <span mat-button class="text">View</span>
                                        </button>
                                        <!-- <button mat-menu-item class="mat-dropdowns" (click)="downloadAsPDF(e.payrollHeaderId);">
                                            <mat-icon class="material-icons-outlined">file_download</mat-icon>
                                            <span mat-button class="text">Download PDF</span>
                                        </button> -->
                                    </mat-menu>
                                </td>
                                <td class="text-center tab-full-width">{{e.finanicalPeriod.finanicalYear.financialFromMonth}} - {{e.finanicalPeriod.finanicalYear.financialFromYear}} To {{e.finanicalPeriod.finanicalYear.financialToMonth}}-{{e.finanicalPeriod.finanicalYear.financialToYear}}</td>
                                <td class="text-center tab-medium-width">{{e.finanicalPeriod.financialperiodMonth}} - {{e.finanicalPeriod.financialPeriodYear}}</td>
                                <td class="tab-full-width">{{e.employee.firstName}} {{e.employee.lastName}}</td>
                                <td class="text-center tab-medium-width">{{e.employee.employeeNumber}}</td>
                                <td class="text-center tab-medium-width">{{e.employeeAnnexure.place}}</td>
                                <td class="tab-full-width">{{e.employee.designation?.designationName}}</td>
                                <td class="text-center tab-medium-width">{{e.createDateTime | date: dateFormat}}</td>
                                <td class="tab-medium-width text-right">{{e.ctc.toFixed(2)}}</td>
                                <td class="tab-medium-width text-right">{{e.amount.toFixed(2)}}</td>
                                <td class="tab-medium-width text-right">{{e.earningYtd.toFixed(2)}}</td>
                                <td class="tab-medium-width text-right">{{e.deductionYtd.toFixed(2)}}</td>
                            </tr>
                            <tr *ngIf="noRecordsFound == false">
                                <td class="text-right py-2" style="font-size: 13px;" colspan="8"><b>Total&nbsp;&nbsp;&nbsp;</b></td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{CTC.toFixed(2)}}</td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{Amount.toFixed(2)}}</td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{EarningYTD.toFixed(2)}}</td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{DeductionYTD.toFixed(2)}}</td>
                            </tr>
                        </tbody>
                        <tbody class="t-body">
                            <tr>
                                <td style="text-align: center; font-weight: 770;font-size: 21px;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" *ngIf="!loader && ManagePayslipdata.length>0">
                    <div class="col-md-4">
                        <div class="showing-record">
                            {{ManagePayslipdata.length}} rows
                        </div>
                    </div>
                    <div class="col-md-8 text-right mt-2" *ngIf="!loader && ManagePayslipdata.length> 5">
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>