import { Component, OnInit,Inject,ViewChild,ElementRef  } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { PmsService } from '../../providers/pms.service';
import { ShowapproverlistComponent }from '../showapproverlist/showapproverlist.component'
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { SettingsService } from '../../providers/settings.service';
import { T } from '@angular/cdk/keycodes';
import { HeaderService } from 'src/app/providers/header.service';
import { MatSelectChange } from '@angular/material/select';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-appraisal-reports',
  templateUrl: './appraisal-reports.component.html',
  styleUrls: ['./appraisal-reports.component.scss']
})
export class AppraisalReportsComponent implements OnInit {
  filterContent:any;
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  kralistData:any=[];
  list_loader = true;
  nodata = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  exportingdata:any;
  exported:any;
  loader:any= false;
  backdrop:any= false;

  searchForm!:FormGroup;
  sortedData: any[];

  resultData : any;
  pagePerItem:any=5;
  tableSearch : any;
  nodatafound: boolean = false;
  usertData: any;
  orgId: any;
  user: any;
  username:any;
  roles:any;
  empId
  chooseFinId: any;
  finPeriodList: any=[];
  finYearList: any=[];
  submitted: any=false;
  toperiod: boolean=false;
  fromperiod: boolean=false;
  isReadOnly: boolean = false;
search: any;
noRecorddatafound: boolean=false;
setSearchboolean: boolean=false;

showTable:boolean=false;

filteredFinPeriodList: any[] = [];

dataForExcel = [];
period: string = "";
@ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;



  constructor(
    private router:Router,
    private pmsService:PmsService,
    private dialog:MatDialog,
    private fb:FormBuilder,
    private settingsService:SettingsService,
    private headerservice : HeaderService
  ) { }

  ngOnInit(): void 
  {
    // this.noRecorddatafound = true;
    // this.headerservice.setTitle('Appraisal Reports')
    this.headerservice.setTitle('');
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.empId = this.usertData.employee.employeeId;
    console.log(this.empId);
    // this.user = this.usertData.userType.userTypeId;
    // console.log(this.user);
    this.roles = this.usertData.employee.role.map((r: any) => r.roleName);
    console.log(this.roles);
    this.searchForm=this.fb.group({
      financialyear:[null,Validators.required],
      fromperiod:[null],
      toperiod:[null],
      // toyear:[null]
    })

    // this.noRecorddatafound = true;


    this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
      console.log(resultData,"result");
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1)
        {
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      }); 

      this.searchForm.get('fromperiod')?.valueChanges.subscribe((fromPeriodId) => {
        if (fromPeriodId) { 
          console.log("from");
          this.searchForm.controls['toperiod'].reset();
          this.filteredFinPeriodList = this.finPeriodList.filter(
            period => period.financialPeriodId > fromPeriodId
          );
          console.log("value",this.searchForm.value);
          
          console.log("filteredFinPeriodList",this.filteredFinPeriodList);
          
        } else {
          console.log("to");
          this.filteredFinPeriodList = this.finPeriodList;
        }
      });
  });


  

  
}



  // selectFinancialyear(event)
  // {
  //   console.log("Select");
  //   console.log(event.target.value);
  //   // console.log(this.filterForm.value.financialyear);
  //    this.chooseFinId=event.target.value;
  //   console.log(this.chooseFinId);
  //   if(this.chooseFinId == null || this.chooseFinId == 'null')
  //   {
  //     // console.log('if');
  //     this.finPeriodList = [];
  //     // this.submitted=true;
  //   }
  //   else
  //   {
  //   this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
  //     console.log("PeriodData", resultData);
  //     this.submitted=false;
  //     this.finPeriodList = resultData.finanicalPeriod;
  //     this.searchForm.patchValue({
  //       fromperiod: null,
  //       toperiod: null,
  //     });
  //   });
  //   this.searchForm.get('financialyear').valueChanges.subscribe((value) => {
  //     if (value) {
  //       this.chooseFinId = value;
  //       this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
  //         this.finPeriodList = resultData.finanicalPeriod;
  //       });
  //     } else {
  //       this.finPeriodList = [];
  //     }
  //   });
  // }
  // }

  selectFinancialyear(event: MatSelectChange) {
    console.log("Select");
    console.log(event.value);
    
    this.chooseFinId = event.value;
    console.log(this.chooseFinId);
  
    if (this.chooseFinId == null || this.chooseFinId == 'null') {
      this.finPeriodList = [];
    } else {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData);
        this.submitted = false;
        this.finPeriodList = resultData.finanicalPeriod;
        this.searchForm.patchValue({
          fromperiod: null,
          toperiod: null,
        });
        this.filteredFinPeriodList = this.finPeriodList;
      });
    }
  }

  onFromPeriodChange(event:MatSelectChange) {
    const fromPeriod = event.value;
    const selectedFromPeriodIndex = this.finPeriodList.findIndex(period => period.financialPeriodId == fromPeriod);
    const filteredToPeriods = this.finPeriodList.slice(selectedFromPeriodIndex + 1);

    if (filteredToPeriods.length === 0) {
      console.log("1st alert");
      
      alert('No available "Period To" options for the selected "Period From".');
      this.searchForm.controls['fromperiod'].reset();
      this.searchForm.controls['toperiod'].reset();
    } else {
      console.log("2nd alert");
      this.searchForm.controls['toperiod'].enable();
    }

    
  }
  
  

  viweApprover(row)
  {
    console.log("row:",row);
      const dialogRef = this.dialog.open(
        ShowapproverlistComponent, {
        width: '500px',
        height:'500px',
        data : row
      })
      console.log("row",row);
  }

  // searchSubmit()
  // {
  //   this.noRecorddatafound=false;
  //   // this.loader = true;
  //   console.log("Search",this.searchForm.value);
  //   this.submitted=true;
  //   if(this.searchForm.invalid)
  //   {
  //     console.log("return");
  //     this.submitted=true;
  //     return;
  //   }

  //   this.loader = true;

  //   if (this.searchForm.value.financialyear && this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
  //     alert('Please select both Period From and Period To.');
  //     this.loader = false;
  //     return;
  //   }

  //   //  if((this.searchForm.value.financialyear==0||this.searchForm.value.financialyear==null)&&(this.searchForm.value.fromperiod==null||this.searchForm.value.fromperiod==0)&&(this.searchForm.value.toperiod==null||this.searchForm.value.toperiod==0))
  //    if(this.searchForm.value.financialyear==null && this.searchForm.value.fromperiod==null && this.searchForm.value.toperiod==null)
  //   {
  //     console.log("Both period invaild");
  //     this.submitted=true;
  //     this.loader = false;
  //     return;
  //   }
  //   // if(this.searchForm.value.financialyear!=0&&this.searchForm.value.fromperiod==null&&this.searchForm.value.toperiod==null)
  //   // {
  //   //   console.log("Both perid invaild");
  //   //   this.fromperiod=true;
  //   //   this.toperiod=true;
  //   // }
  //   // if(this.searchForm.value.financialyear!=0&&this.searchForm.value.fromperiod!=0&&this.searchForm.value.toperiod==null)
  //   // {
  //   //   console.log("Toperid invaild");
  //   //   this.toperiod=true;
  //   // }
  //   // else if(this.searchForm.value.financialyear!=0&&this.searchForm.value.fromperiod==null&&this.searchForm.value.toperiod!=0)
  //   // {
  //   //   console.log("Fromperid invaild");
  //   //   this.fromperiod=true;
  //   // }
  //   // if(this.searchForm.value.financialyear!=0||this.searchForm.value.financialyear!=null&&this.searchForm.value.fromperiod==0||this.searchForm.value.fromperiod==null&&this.searchForm.value.toperiod!=0||this.searchForm.value.toperiod==null)
  //   // {
  //   //  console.log("Financial Year Only");
  //   //  this.pmsService.getAppReportList(this.searchForm.value.financialyear).subscribe((resultData: any) => 
  //   //  {
  //   //    this.loader=false;
  //   //    // this.backdrop=false;
  //   //    this.nodatafound = false;
  //   //    this.resultData = resultData;

  //   //    if(this.resultData.length == 0 || this.resultData.length == undefined)
  //   //    {
  //   //      this.nodatafound = true;
  //   //      this.resultData=[];
  //   //    }
  //   //  })
  //   // }
  //   console.log(this.searchForm.value.fromperiod);
  //   console.log(this.searchForm.value.toperiod);
  //   //  if((this.searchForm.value.financialyear!=0||this.searchForm.value.financialyear!=null)&&(this.searchForm.value.fromperiod!=0||this.searchForm.value.fromperiod!=null)&&(this.searchForm.value.toperiod!=0||this.searchForm.value.toperiod!=null))
  //     if(this.searchForm.value.financialyear!=null && this.searchForm.value.fromperiod!=null && this.searchForm.value.toperiod!=null)

  //   {
  //     console.log("Financial Year and Period");
  //     this.pmsService.getAppsialReportPeriodList(this.searchForm.value.fromperiod,this.searchForm.value.toperiod).subscribe((resultData: any) => 
  //     {
  //       console.log(resultData);
        
  //       this.submitted=false;
  //       this.setSearchboolean=false;
  //       this.loader=false;
  //       this.nodatafound = false;
  //       // this. noRecorddatafound = false
  //       this.resultData = resultData;
  //       console.log("Data",this.resultData);


  //       if(this.resultData.statusCode==204)
  //       {
  //         // this.nodatafound = true;
  //         // this.noRecorddatafound = true;
  //         this.setSearchboolean=true
  //         this.resultData=[];
  //       }
  //       // if(this.resultData.length == 0 || this.resultData.length == undefined)
  //       // {
  //       //   this.nodatafound = true;
  //       //   this.resultData=[];
  //       // }
  //       // this.searchForm.reset();
  //     })
  //   }
  //   // if((this.searchForm.value.financialyear!=0||this.searchForm.value.financialyear!=null)&&(this.searchForm.value.fromperiod==0||this.searchForm.value.fromperiod==null)&&(this.searchForm.value.toperiod==0||this.searchForm.value.toperiod==null))
  //     if(this.searchForm.value.financialyear!=null && this.searchForm.value.fromperiod==null && this.searchForm.value.toperiod==null)

  //     {
  //       console.log("Financial Year Only ");
  //       this.pmsService.getAppsialReportPeriodListYear(this.chooseFinId).subscribe((resultData: any) => 
  //       {
  //         console.log(resultData);
  //         this.loader=false;
  //         this.submitted=false;
  //         this.setSearchboolean=false;
  //         this.nodatafound = false;
  //         this.resultData = resultData;
  //         console.log("Data",this.resultData);
  //         if(this.resultData.statusCode==204)
  //         {
  //           // this.nodatafound = true;
  //           // this.noRecorddatafound = true;
  //           this.setSearchboolean=true;

  //           this.resultData=[];
  //         }
  //         if(this.resultData.length == 0 || this.resultData.length == undefined)
  //         {
  //           this.nodatafound = true;
  //           this.resultData=[];
  //         }
  //         // this.searchForm.reset();
  //       })
  //     }
  //      }

  searchSubmit() {
    this.resultData=[];
    this.nodatafound = false;
    console.log("Search", this.searchForm.value);
    this.submitted = true;
  
    // Check if the form is invalid
    if (this.searchForm.invalid) {
      console.log("Form is invalid");
      this.submitted = true;
      return;
    }
  
    // Check if financial year is selected with only one of the periods
    if (this.searchForm.value.financialyear && this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
      alert('Please select both Period From and Period To.');
      this.loader = false;
      this.showTable=false;
      
      this.searchForm.controls['fromperiod'].setValue(null);
      return;
    }

    if (this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && this.searchForm.value.toperiod) {
      alert('Please select both Period From and Period To.');
      this.loader = false;
      this.showTable=false;
     
      this.searchForm.controls['toperiod'].setValue(null);
      
      return;
    }
  
  
    this.loader = true;
    this.showTable=false;
  
    // Case: No financial year and no periods
    if (!this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
      console.log("Both period invalid");
      this.submitted = true;
      this.loader = false;
      this.showTable=true;
      return;
    }
  
    console.log(this.searchForm.value.fromperiod);
    console.log(this.searchForm.value.toperiod);

  // if (!this.roles.includes('Employee')) {
  //   if (this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
  //     // Case: Financial year only
  //     console.log("Financial Year Only ");
  //     this.pmsService.getAppsialReportPeriodListYear(this.searchForm.value.financialyear).subscribe((resultData: any) => {
  //       console.log(resultData);
  //       this.loader = false;
  //       this.showTable=true;
  //       this.submitted = false;
  //       this.setSearchboolean = false;
  //       this.resultData = resultData;
  //       console.log("Data", this.resultData);
  //       if (this.resultData.statusCode == 204) {
  //         this.setSearchboolean = true;
  //         this.resultData = [];
  //         this.nodatafound = true;
  //       } else if (this.resultData.length === 0 || this.resultData.length === undefined) {
  //         this.nodatafound = true;
  //         this.resultData = [];
  //       } else {
  //         this.nodatafound = false;
  //       }
  //     });
  //   } else if (this.searchForm.value.financialyear && this.searchForm.value.fromperiod && this.searchForm.value.toperiod) {
  //     // Case: Financial year and periods
  //     console.log("Financial Year and Period");
  //     console.log("from period",this.searchForm.value.fromperiod);


  //     this.pmsService.getAppsialReportPeriodList(this.searchForm.value.fromperiod, this.searchForm.value.toperiod).subscribe((resultData: any) => {
  //       console.log(resultData);
  //       this.loader = false;
  //       this.showTable=true;
  //       this.submitted = false;
  //       this.setSearchboolean = false;
  //       this.resultData = resultData;
  //       console.log("Data", this.resultData);
  //       if (this.resultData.statusCode == 204) {
  //         this.setSearchboolean = true;
  //         this.resultData = [];
  //         this.nodatafound = true;
  //       } else if (this.resultData.length === 0 || this.resultData.length === undefined) {
  //         this.nodatafound = true;
  //         this.resultData = [];
  //       } else {
  //         this.nodatafound = false;
  //       }
  //     });
  //   }
  //   }
  // else{
  //   if (this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
  //     // Case: Financial year only
  //     console.log("Financial Year Only ");
  //     this.pmsService.getAppsialReportPeriodListYear(this.searchForm.value.financialyear).subscribe((resultData: any) => {
  //       console.log(resultData);
  //       this.loader = false;
  //       this.showTable=true;
  //       this.submitted = false;
  //       this.setSearchboolean = false;
  //       this.resultData = resultData;
  //       console.log("Data", this.resultData);
  //       if (this.resultData.statusCode == 204) {
  //         this.setSearchboolean = true;
  //         this.resultData = [];
  //         this.nodatafound = true;
  //       } else if (this.resultData.length === 0 || this.resultData.length === undefined) {
  //         this.nodatafound = true;
  //         this.resultData = [];
  //       } else {
  //         this.nodatafound = false;
  //       }
  //     });
  //   }

  // }



// if (this.roles.length === 1 && this.roles.includes('Employee')) {
//   // Logic when the user has only 'Employee' role
//   if (this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
//     // Case: Financial year only
//     console.log("Financial Year Only (Employee)");
//     this.pmsService.getAppsialReportPeriodListYearbyEmployee(this.searchForm.value.financialyear, this.empId).subscribe((resultData: any) => {
//       console.log(resultData);
//       this.loader = false;
//       this.showTable = true;
//       this.submitted = false;
//       this.setSearchboolean = false;
//       this.resultData = resultData;
//       console.log("Data", this.resultData);
//       if (this.resultData.statusCode == 204) {
//         this.setSearchboolean = true;
//         this.resultData = [];
//         this.nodatafound = true;
//       } else if (!this.resultData || this.resultData.length === 0) {
//         this.nodatafound = true;
//         this.resultData = [];
//       } else {
//         this.nodatafound = false;
//       }
//     });
//   } else if (this.searchForm.value.financialyear && this.searchForm.value.fromperiod && this.searchForm.value.toperiod) {
//     // Case: Financial year and periods
//     console.log("Financial Year and Period (Employee)");
//     this.pmsService.getAppsialReportPeriodListbyEmployee(this.searchForm.value.fromperiod, this.searchForm.value.toperiod, this.empId).subscribe((resultData: any) => {
//       console.log(resultData);
//       this.loader = false;
//       this.showTable = true;
//       this.submitted = false;
//       this.setSearchboolean = false;
//       this.resultData = resultData;
//       console.log("Data", this.resultData);
//       if (this.resultData.statusCode == 204) {
//         this.setSearchboolean = true;
//         this.resultData = [];
//         this.nodatafound = true;
//       } else if (!this.resultData || this.resultData.length === 0) {
//         this.nodatafound = true;
//         this.resultData = [];
//       } else {
//         this.nodatafound = false;
//       }
//     });
//   }
// } else if (this.roles.includes('Employee')) {
//   // Logic when 'Employee' is combined with other roles
//   if (this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
//     // Case: Financial year only
//     console.log("Financial Year Only (Other Roles)");
//     this.pmsService.getAppsialReportPeriodListYear(this.searchForm.value.financialyear).subscribe((resultData: any) => {
//       console.log(resultData);
//       this.loader = false;
//       this.showTable = true;
//       this.submitted = false;
//       this.setSearchboolean = false;
//       this.resultData = resultData;
//       console.log("Data", this.resultData);
//       if (this.resultData.statusCode == 204) {
//         this.setSearchboolean = true;
//         this.resultData = [];
//         this.nodatafound = true;
//       } else if (!this.resultData || this.resultData.length === 0) {
//         this.nodatafound = true;
//         this.resultData = [];
//       } else {
//         this.nodatafound = false;
//       }
//     });
//   } else if (this.searchForm.value.financialyear && this.searchForm.value.fromperiod && this.searchForm.value.toperiod) {
//     // Case: Financial year and periods
//     console.log("Financial Year and Period (Other Roles)");
//     this.pmsService.getAppsialReportPeriodList(this.searchForm.value.fromperiod, this.searchForm.value.toperiod).subscribe((resultData: any) => {
//       console.log(resultData);
//       this.loader = false;
//       this.showTable = true;
//       this.submitted = false;
//       this.setSearchboolean = false;
//       this.resultData = resultData;
//       console.log("Data", this.resultData);
//       if (this.resultData.statusCode == 204) {
//         this.setSearchboolean = true;
//         this.resultData = [];
//         this.nodatafound = true;
//       } else if (!this.resultData || this.resultData.length === 0) {
//         this.nodatafound = true;
//         this.resultData = [];
//       } else {
//         this.nodatafound = false;
//       }
//     });
//   }
// }




if (this.roles.includes('Admin')) {
  // Logic when 'Admin' role is present
  if (this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
    // Case: Financial year only
    console.log("Financial Year Only (Admin or combined with Admin)");
    this.pmsService.getAppsialReportPeriodListYear(this.searchForm.value.financialyear).subscribe((resultData: any) => {
      console.log(resultData);
      this.loader = false;
      this.showTable = true;
      this.submitted = false;
      this.setSearchboolean = false;
      this.resultData = resultData;
      console.log("Data", this.resultData);
      if (this.resultData?.statusCode === 204) {
        this.setSearchboolean = true;
        this.resultData = [];
        this.nodatafound = true;
      } else if (!this.resultData || this.resultData.length === 0) {
        this.nodatafound = true;
        this.resultData = [];
      } else {
        this.nodatafound = false;
      }
    });
  } else if (this.searchForm.value.financialyear && this.searchForm.value.fromperiod && this.searchForm.value.toperiod) {
    // Case: Financial year and periods
    console.log("Financial Year and Period (Admin or combined with Admin)");
    this.pmsService.getAppsialReportPeriodList(this.searchForm.value.fromperiod, this.searchForm.value.toperiod).subscribe((resultData: any) => {
      console.log(resultData);
      this.loader = false;
      this.showTable = true;
      this.submitted = false;
      this.setSearchboolean = false;
      this.resultData = resultData;
      console.log("Data", this.resultData);
      if (this.resultData?.statusCode === 204) {
        this.setSearchboolean = true;
        this.resultData = [];
        this.nodatafound = true;
      } else if (!this.resultData || this.resultData.length === 0) {
        this.nodatafound = true;
        this.resultData = [];
      } else {
        this.nodatafound = false;
      }
    });
  }
} else if (this.roles.includes('Employee')) {
  // Logic when 'Employee' role is present without Admin
  if (this.searchForm.value.financialyear && !this.searchForm.value.fromperiod && !this.searchForm.value.toperiod) {
    // Case: Financial year only
    console.log("Financial Year Only (Employee)");
    this.pmsService.getAppsialReportPeriodListYearbyEmployee(this.searchForm.value.financialyear, this.empId).subscribe((resultData: any) => {
      console.log(resultData);
      this.loader = false;
      this.showTable = true;
      this.submitted = false;
      this.setSearchboolean = false;
      this.resultData = resultData;
      console.log("Data", this.resultData);
      if (this.resultData?.statusCode === 204) {
        this.setSearchboolean = true;
        this.resultData = [];
        this.nodatafound = true;
      } else if (!this.resultData || this.resultData.length === 0) {
        this.nodatafound = true;
        this.resultData = [];
      } else {
        this.nodatafound = false;
      }
    });
  } else if (this.searchForm.value.financialyear && this.searchForm.value.fromperiod && this.searchForm.value.toperiod) {
    // Case: Financial year and periods
    console.log("Financial Year and Period (Employee)");
    this.pmsService.getAppsialReportPeriodListbyEmployee(this.searchForm.value.fromperiod, this.searchForm.value.toperiod, this.empId).subscribe((resultData: any) => {
      console.log(resultData);
      this.loader = false;
      this.showTable = true;
      this.submitted = false;
      this.setSearchboolean = false;
      this.resultData = resultData;
      console.log("Data", this.resultData);
      if (this.resultData?.statusCode === 204) {
        this.setSearchboolean = true;
        this.resultData = [];
        this.nodatafound = true;
      } else if (!this.resultData || this.resultData.length === 0) {
        this.nodatafound = true;
        this.resultData = [];
      } else {
        this.nodatafound = false;
      }
    });
  }
}


  }
  
  

  

  ucFirst(string)
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '')
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) =>
    {
      const isAsc = sort.direction === 'asc';

      switch (sort.active)
      {
        case 'roleName':
          return compare(a.roleName, b.roleName, isAsc);

        case 'employeeNumber':
          return compare(a.employeeNumber, b.employeeNumber, isAsc);

        case 'firstName':
          return compare(a.firstName, b.firstName, isAsc);

        case 'mobileNumber':
          return compare(a.mobileNumber, b.mobileNumber, isAsc);

        case 'organizationEmail':
          return compare(a.organizationEmail, b.organizationEmail, isAsc);

        case 'dateOfBirth':
          return compare(a.dateOfBirth, b.dateOfBirth, isAsc);

        case 'createDateTime':
            return compare(a.createDateTime, b.createDateTime, isAsc);

        default:
          return 0;
      }
    });
  }

  clear()
  {
    this.searchForm.reset();
    // this.kralistData=[]; 
    this.submitted=false;
    
    this.nodatafound = false;
    
      this.setSearchboolean=false;
      
    // this.resultData.length=0;
    this.setSearchboolean = false;
    this.finPeriodList = [];

    console.log('clear');
    this.resultData=[];
    this.searchForm.clearValidators;
    this.showTable=false;
    this.filteredFinPeriodList = [];
    
  }

  get validate()
    {
      return this.searchForm.controls;
    }

  onItemsPerPageChange(){
      this.p = 1;
    }


    printPdf() {
      const pdfTable = this.pdfTable.nativeElement;
      console.log(pdfTable.innerHTML);
      

      var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
      console.log(pdfTable.innerHTML);
      
  
      const documentDefinition = {
        content: html,
        pageMargins: [25, 40, 25, 40],
        info: {
          title: `Appraisal Report`
        },
        background: function (currentPage, pageCount) {
          console.log('currentPage:', currentPage);
          console.log('pageCount:', pageCount);
  
          return {
            text: 'Page ' + currentPage.toString(),
            alignment: 'center',
            margin: [0, 8],
            fontSize: 10,
          };
        },
        pageSize: 'A4',
        pageOrientation: 'landscape'
      };
      console.log(pdfTable);
      pdfMake.createPdf(documentDefinition).download("Appraisal Report");
    }

    
    public downloadAsExcel() {
    
      this.dataForExcel.length = 0;
      for (let e of this.resultData) {
        let status = '';
  switch (e.apprisalStatus.listTypeValueId) {
    case 8:
      status = e.apprisalStatus.listTypeValueName; 
      break;
    case 3:
      status = e.apprisalStatus.listTypeValueName; 
      break;
    case 9:
      status = e.apprisalStatus.listTypeValueName; 
      break;
    case 7:
      status = e.apprisalStatus.listTypeValueName; 
      break;
    default:
      status = 'Unknown'; 
  }
        let data = {
          // EmployeeCode: e.employee.employeeNumber,
          EmployeeName: e.employee.firstName + ' ' + (e.employee.lastName ? e.employee.lastName : ""),
          ReviewerName: e.approvals.approver.approverOneObject.firstName + ' ' + (e.approvals.approver.approverOneObject.lastName ? e.approvals.approver.approverOneObject.lastName : " "),
          EmployeeRating: e.employeeReviewRating,
          ReviewerRating:  e.managementRating ? e.managementRating  : '-',
          FinalRating: e.managementTotalRating ? e.managementTotalRating : '-',
          Status: status
          // KRAStatus: e.assigned ? 'Assigned' : 'Not Assigned',
          // AppraisalStatus: e.submitted ? 'Submitted' :'Not Submitted'
          
        }
        this.dataForExcel.push(data);
        console.log(this.dataForExcel);
      }
      let totaldata = {
        // EmployeeCode: "",
        EmployeeName: "",
        ReviewerName: "",
        EmployeeRating: "",
        ReviewerRating: "",
        FinalRating: "",
        Status: ""

        // KRAStatus: "",
        // AppraisalStatus:"",
      
        
       
      }
      this.dataForExcel.push(totaldata)
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataForExcel);
      const workbook: XLSX.WorkBook = {
        Sheets: { 'Assignment Report': worksheet },
        SheetNames: ['Assignment Report']
      }
      // console.log(reportDatabyrecruiter);
      // this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, 'Assignment Report');
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
      saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

