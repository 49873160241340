import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../providers/settings.service';
import { AddBranchComponent } from '../add-branch/add-branch.component';
import {  httpOptions, responseMsgTimeOut, redirectMsgTimeOut } from '../../../providers/properties';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})

export class BranchComponent implements OnInit {
  public searchFilter: any = '';
  organzationId: any;
  clientId:any;
  userdata: any;
  search: any;
  mySelect: any = 5;
  nodatafound: boolean = false;
  tableShow: boolean = false;

  constructor(private settingsservice: SettingsService) { }

  resultData: any = [];
  p: number = 1;
  collection: any[];
  pagePerItem: any = 10;
  success = false;
  error: any = false;
  tableSearch: any;

  errorMsg: any;
  sucess_msg: any;
  error_msg: any;
  loader: boolean = true;

  sortedData: any[];

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId = this.userdata.organization.organizationId;
    this.clientId = this.userdata?.employee?.client?.clientId; 
    this.getBranchList();
  }

  // getBranchList() {
  //   this.p = 1;
  //   this.tableShow = false;
  //   this.loader = true

  //   this.settingsservice.getBranchList(this.organzationId).subscribe((result: any) => {
  //     console.log('result', result);

  //     this.tableShow = true;
  //     this.loader = false;
  //     this.resultData = result;
  //     // this.resultData = result.data || []; 

  //     if (result.statusCode == 204) {
  //       this.nodatafound = true;
  //     }
  //     if (this.resultData.length === 0) {
  //       this.nodatafound = true;
  //     }

  //   },
  //     err => {
  //       this.nodatafound = true;

  //       this.errorMsg = err.error.message;
  //       console.log(this.errorMsg);
  //     })
  // }

  getBranchList() {
    this.p = 1;
    this.tableShow = false;
    this.loader = true;
  
    if (this.organzationId) {
      // API call with organizationId
      this.settingsservice.getBranchList(this.organzationId).subscribe(
        (result: any) => {
          console.log('result', result);
  
          this.tableShow = true;
          this.loader = false;
          this.resultData = result;
          // this.resultData = result.data || [];
  
          if (result.statusCode === 204 || this.resultData.length === 0) {
            this.nodatafound = true;
          }
        },
        (err) => {
          this.nodatafound = true;
          this.loader = false;
          this.errorMsg = err.error?.message;
          console.log(this.errorMsg);
        }
      );
    } else if (this.clientId) {
      // API call with clientId
      this.settingsservice.getBranchListByClient(this.clientId).subscribe(
        (result: any) => {
          console.log('result', result);
  
          this.tableShow = true;
          this.loader = false;
          this.resultData = result;
          // this.resultData = result.data || [];
  
          if (result.statusCode === 204 || this.resultData.length === 0) {
            this.nodatafound = true;
          }
        },
        (err) => {
          this.nodatafound = true;
          this.loader = false;
          this.errorMsg = err.error?.message;
          console.log(this.errorMsg);
        }
      );
    } else {
      console.error('Either organizationId or clientId must be provided.');
      this.loader = false;
      this.nodatafound = true;
    }
  }
  

  updateStatus(branchId, status) {
    this.settingsservice.updateBranchStatus(branchId, status).subscribe((result: any) => {
      if (result.statusCode == 200) {
        this.loader = true
        this.tableShow = false;
        // this.success = true;
        // this.sucess_msg = result.message;
        setTimeout(() => { this.success = false; }, redirectMsgTimeOut)

        this.getBranchList();
      }
      else {
        this.loader = false
        this.tableShow = true
        // this.error = true;
        // this.error_msg = result.message;
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }
    })
  }
  checkNoData() {
    this.nodatafound = this.resultData.length === 0;
  }

  changefirstpage() {
    this.p = 1;
  }
  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort) {
    //const data = this.resultData.slice();
    //   const data = this.resultData;
    //   if (!sort.active || sort.direction === '') 
    //   {
    //     this.sortedData = data;
    //     return;
    //   }

    //   this.sortedData = data.sort((a, b) => 
    //   {
    //     const isAsc = sort.direction === 'asc';

    //     switch (sort.active) 
    //     {
    //       case 'branchCode':
    //         return compare(a.branchCode, b.branchCode, isAsc);

    //       case 'branchName':
    //         return compare(a.branchName, b.branchName, isAsc);

    //       case 'mobileNumber':
    //         return compare(a.mobileNumber, b.mobileNumber, isAsc);

    //       default:
    //         return 0;
    //     }
    //   });
    // }
    // this.p=1;
    console.log('this.positionlistdata::', this.resultData);

    const data = this.resultData.slice();
    console.log('check this codition::', !sort.active || sort.direction === '');

    if (!sort.active || sort.direction === '') {
      this.resultData = data;
      this.checkNoData();
      return;
    }

    this.resultData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'branchCode':
          return compare(a.branchCode, b.branchCode, isAsc);

        case 'branchName':
          return compare(a.branchName, b.branchName, isAsc);

        case 'mobileNumber':
          return compare(a.mobileNumber, b.mobileNumber, isAsc);

        default:
          return 0;
      }
    });

    this.checkNoData();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
