import { Component, Inject, OnInit } from '@angular/core';
import { ProjectManagementService } from '../providers/project-management.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-expense-approval',
  templateUrl: './expense-approval.component.html',
  styleUrls: ['./expense-approval.component.scss']
})
export class ExpenseApprovalComponent implements OnInit {
nodata: boolean=false;
  stroreApprovelist: any;
   loader :any =true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private advanceservice :ProjectManagementService,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
      this.loader = true;
console.log(this.data.expenseReimbursementId);

    this.advanceservice.expenseApprovalList(this.data.expenseReimbursementId).subscribe((datas:any)=>{
      console.log(datas);
      this.stroreApprovelist=datas
       this.loader = false;
      // this.data=datas
      console.log(" this.data", this.stroreApprovelist);
      
      this.nodata=false
      // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);
if(this.stroreApprovelist.errorCode==204){
  this.nodata=true
console.log("if entered here!!");

}

    },
    (error:any)=>{
      this.nodata=true
      console.log("error came!!s");
      
    }
  )
    
  }

  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }

  approvalBackground(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#fff0c2'
    }
    if (approvalStatusName == 'Approved') {
      return '#c8e6c9'
    }
    if (approvalStatusName == 'Denied') {
      return '#ffcdd2'
    }
  }


}
