import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ValidationErrors,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import Swal from 'sweetalert2';
import { EMPTY } from 'rxjs';
import { isEmpty } from 'rxjs-compat/operator/isEmpty';
import {
 
  httpOptions,
  redirectMsgTimeOut,
  responseMsgTimeOut,
} from '../../providers/properties';
import { Fruit } from '../requirement/requirement-add/requirement-add/requirement-add.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { DatePipe } from '@angular/common';
import { HeaderService } from 'src/app/providers/header.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss'],
})
export class PositionComponent implements OnInit {
  resourceform: FormGroup;
  positioncraetedata: any;
  positionusertype: any;
  jobtypeId: any;
  mydata: any = [];
  myFile: any = [];
  isShow: boolean;
  extension: any;
  usertype: any;
  employeeId: string;
  update_data_error_msg: any;
  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  backdrop: any = false;
  success: any = false;
  error: any = false;
  positionId: any;
  userdata: any = [];
  showclientname: boolean = false;
  jobtype: any;
  noticeperiod: any;
  clientname: any;
  positionlistdata: any = [];
  maxExp: any = true;
  today: any = new Date();
  minexpmaxlength: any = 2;
  maxexpmaxlength: any = 2;
  noticeP: any;
  noticeParray: any = [];
  listTypeValue: any;
  noticePatcharray: any = [];
  submitted: boolean = false;
  skillsarray: Fruit[] = [];
  fruitsdata: any;
  skillsdata: any = [];
  removable = true;
  addOnBlur = true;
  visible = true;
  selectable = true;
  upDate: string;
  buttonDisabled: boolean = false;
  createDate: string;
  storeEntityId:any;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fileName: any;
  storeCreatedbyId: any;
  showFileInput: boolean=false;
  fileInputLabel: string;
newFileSelected: boolean=false;
orgId:any
  constructor(
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    private positionservice: RecruitmentService,
    private fb: FormBuilder,
    private headerservice: HeaderService,
    private datepipe: DatePipe,
    private SuperadminService:SuperadminService
  ) {}

  ngOnInit(): void {
    this.headerservice.setTitle(' Posistions ');

    this.today = new Date();
    ///localhost stroage data
    //this.usertype = JSON.parse(localStorage.getItem('user_hrms'));
    //this.employeeId = this.usertype.employeeId;

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    console.log('this.usertype::', this.usertype);

    this.storeCreatedbyId = this.usertype.employee.employeeId;
    this.storeEntityId= this.usertype?.entity?.entityId
    console.log('this.storeCreatedbyId::', this.storeCreatedbyId);

    let employeeId = this.usertype.userId;
    let organizationId = this.usertype.organization.organizationId;
    this.orgId=this.usertype.organization.organizationId
    console.log(  this.orgId);
    
    //this.usertype = this.usertype.role.roleName;

    //hiringfor
    this.positionservice.hiringfor().subscribe((data: any) => {
      this.positionusertype = data;
    });

    //clientname
    // this.positionservice.client().subscribe((client:any) =>
    // {
    //   this.positionlistdata = client;
    //   this.patchresource();
    // })

    this.patchresource();

    // this.positionservice
    //   .getclientlist(organizationId)
    //   .subscribe((resultData: any) => {
    //     console.log(' resultData', resultData);
    //     resultData.forEach((item: any) => {
    //       if (item.status.listTypeValueId == 1) {
    //         console.log(item.status.listTypeValueId);
    //         this.positionlistdata.push(item);
    //         console.log(' this.positionlistdata', this.positionlistdata);
    //         // this.patchresource();
    //       }
    //     });
    //   });

    //noticeperiod
    this.positionservice.noticeperiod().subscribe((data: any) => {
      this.noticeperiod = data;
    });

    this.positionservice.jobtypebyid().subscribe((data: any) => {
      this.jobtype = data;
    });

    if (this.ActivatedRoute.snapshot.params.positionId) {
      this.resourceform = this.fb.group({
        // positionName: new FormControl('', [
        //   Validators.required,
        //   Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"),
        // ]),
        // positionName:new FormControl('',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")
        // ]),
        positionName:new FormControl('',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")
        ]),

        // maxExperience: new FormControl(this.resourceform, [
        //   Validators.required,
        //   Validators.pattern(/^[0-9]\d{0,10}$/),
        // ]),
        maxExperience: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/), 
      
         this.noSpecialCharactersValidator()
        ]),
        // minExperience: new FormControl(this.resourceform, [
        //   Validators.required,
        //   Validators.pattern(/^[0-9]\d{0,10}$/),
        // ]),
        minExperience: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/), // Adjust pattern to require exactly 10 digits
        
         this.noSpecialCharactersValidator()
        ]),
        client: new FormControl(this.resourceform),
        location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        ctc: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[1-9]\d{0,10}$/),this.noSpecialCharactersValidator()

        ]),
        jobType: new FormControl(this.resourceform, [Validators.required]),
        hiring: new FormControl(this.resourceform, [Validators.required]),
        noticePeriod: new FormControl(this.resourceform, [Validators.required]),
        // noticePeriod: this.fb.array([]),
        jobDescriptionAttachment: new FormControl(this.resourceform),
        expiryDate: new FormControl(this.resourceform, [Validators.required]),
        skills: new FormControl(this.resourceform),
        jobDescription: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z-]+(([. ][a-zA-Z- ])?[a-zA-Z-]*)*$/),
        ]),
      });
    } else {
      this.resourceform = new FormGroup({
        // positionName: new FormControl(this.resourceform, [
        //   Validators.required,
        //   Validators.pattern(
        //     /^[a-zA-Z0-9-]+(([. ][a-zA-Z0-9- ])?[a-zA-Z0-9-]*)*$/
        //   ),
        // ]),
        positionName:new FormControl('',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")
]),
        maxExperience: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/), // Adjust pattern to require exactly 10 digits
      
         this.noSpecialCharactersValidator()
        ]),
        minExperience: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/), // Adjust pattern to require exactly 10 digits
         
         this.noSpecialCharactersValidator()
        ]),
        client: new FormControl(this.resourceform),
        location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        ctc: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[1-9]\d{0,10}$/),
          this.noSpecialCharactersValidator()

        ]),
        jobType: new FormControl(this.resourceform, [Validators.required]),
        hiring: new FormControl(this.resourceform, [Validators.required]),
        noticePeriod: new FormControl(this.resourceform, [Validators.required]),
        // noticePeriod: this.fb.array([]),
        jobDescriptionAttachment: new FormControl(this.resourceform),
        expiryDate: new FormControl(this.resourceform, [Validators.required]),
        skills: new FormControl(this.resourceform),
        jobDescription: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z-]+(([. ][a-zA-Z- ])?[a-zA-Z-]*)*$/),
        ]),
      });
    }

    this.getAllClientList()
  }
  noSpecialCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = /[!@#$%^&*(),.?":{}|<>]/.test(control.value);
      return forbidden ? { specialCharacters: true } : null;
    };
  }


  getAllClientList(){
    // this.loader=true;
    // this.tableShow=false
    this.SuperadminService.getAllClientlist(this.storeEntityId).subscribe((resultData:any)=>{
      // this.positionlistdata=response;
      // this.tableShow=true;
      // this.loader=false;
      // console.log("client list::",this.clientList);
      // this.nodatafound=false;
      // if (this.clientList.statusCode==204){
      //   console.log("no data entered!!");
        
      //   this.nodatafound=true;
      //   this.clientList=[];
      //   this.tableShow=true;

      // }
      console.log(' client list', resultData);
          resultData.forEach((item: any) => {
            if (item.status.listTypeValueId == 1) {
              console.log(item.status.listTypeValueId);
              this.positionlistdata.push(item);
              console.log('active client list::', this.positionlistdata);
              // this.patchresource();
            }
      
    })
  })
}

  keyUpmaxexp(event) {
    let minExperience = +this.resourceform.value.minExperience;
    let maxExperience = +this.resourceform.value.maxExperience;
    console.log('MIN: ' + minExperience);
    console.log('MAX: ' + maxExperience);

    if (
      this.resourceform.controls.minExperience.invalid ||
      this.resourceform.controls.maxExperience.invalid
    ) {
      this.maxExp = true;
    } else {
      if (maxExperience >= minExperience) {
        console.log('MAX 1: ');
        this.maxExp = true;
      } else {
        console.log('MAX 2: ');
        this.maxExp = false;
      }
    }
  }

  noNumber(event: any) {
    // const inputValue = event.target.value;

    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  checkdecimal() {
    if (this.resourceform.value.minExperience.includes('.')) {
      this.minexpmaxlength = 3;
    } else {
      this.minexpmaxlength = 2;
    }
  }

  checkdecimalmax() {
    if (this.resourceform.value.maxExperience.includes('.')) {
      this.maxexpmaxlength = 3;
    } else {
      this.maxexpmaxlength = 2;
    }
  }

  patchresource() {
    if (this.ActivatedRoute.snapshot.params.positionId) {
   
      this.positionId = this.ActivatedRoute.snapshot.params.positionId;
this.loader=true;
      this.positionservice
        .positionbyid(this.ActivatedRoute.snapshot.params.positionId,this.orgId)
        .subscribe((userdata: any) => {
          this.userdata = userdata;
          this.loader=false
          console.log('this.userdata', this.userdata);
          console.log(this.userdata.positionStatus);

          for (let i = 0; i < this.userdata.noticePeriod.length; i++) {
            this.noticePatcharray.push(
              this.userdata.noticePeriod[i].listTypeValueId
            );
          }
          this.today = new Date(this.userdata.expiryDate);

          if (this.userdata.skills != undefined) {
            this.skillsdata = this.userdata.skills.split(',');
            // console.log(this.skillsdata);
            let text;
            this.skillsdata.forEach((element) => {
              text = element;
              // console.log(text);
              this.skillsarray.push({ name: text });
              console.log(this.skillsarray);
            });
          }
          this.resourceform.patchValue({
            positionName: this.userdata.positionName,
            maxExperience: this.userdata.maxExperience,
            minExperience: this.userdata.minExperience,
            // client :this.userdata.client.clientId,
            location: this.userdata.location,
            ctc: this.userdata.ctc,
            hiring: this.userdata.hiring.listTypeValueId,
            jobType: this.userdata.jobType.listTypeValueId,
            noticePeriod: this.noticePatcharray,
            // expiryDate :  this.userdata.expiryDate.substring(0,10),
            expiryDate: this.userdata.expiryDate,
            // skills:this.skillsarray,
            jobDescription: this.userdata.jobDescription,
            // jobDescriptionAttachment :
            // {
            //   sourceFileName:this.mydata.sourceFileName,
            //   sourceFileSize:this.mydata.sourceFileSize,
            //   sourceFileType:this.mydata.sourceFileType,
            //   sourceByteArray:this.mydata.sourceByteArray,
            //   fileDownloadUrl:this.mydata.fileDownloadUrl,
            //   fileViewUrl:this.mydata.fileViewUrl
            // }
            // this.userdata.jobDescriptionAttachment
          });
          this.newFileSelected =  false;

          if (this.userdata.hiring.listTypeValueId == 32) {
            this.showclientname = true;
            this.resourceform
              .get('client')
              .setValidators([Validators.required]);
          } else {
            this.resourceform.controls['client'].setErrors(null);
            this.showclientname = false;
          }

          if (this.userdata.client != undefined) {
            this.resourceform.patchValue({
              // client :this.userdata.client.clientId,
              client: this.userdata.client,
            });
          }

          if (this.resourceform.invalid) {
            const controls = this.resourceform.controls;
            for (const name in controls) {
              if (controls[name].invalid) {
                console.log(name);
              }
            }
          }
          this.resourceform.patchValue({
            jobDescriptionAttachment: this.userdata.jobDescriptionAttachment?.sourceFileName
        });
        });
    }
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  listTypeValueid(event) {
    if (event.value == 32) {
      this.showclientname = true;
      this.resourceform.get('client').setValidators([Validators.required]);
    } else {
      this.resourceform.controls['client'].setErrors(null);
      this.showclientname = false;
    }
  }

  submitData() {
    this.buttonDisabled = true;
    this.submitted = true;
    console.log('this.resourceform', this.resourceform);
    if (this.resourceform.invalid) {
      this.buttonDisabled = false;
      return;
    }

    this.error = false;
    this.success = false;

    //this.backdrop=true;
    //this.loader=true;

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let employeeId = this.usertype.userId;
    let organizationId = this.usertype.organization.organizationId;
    console.log('organizationId', organizationId);
    this.fruitConcat();
    if (this.positionId) {
      this.upDate = this.datepipe.transform(
        this.resourceform.value.expiryDate,
        'YYYY-MM-dd'
      );
      var positionupdate;
      if (
        this.userdata.jobDescriptionAttachment != undefined &&
        this.mydata.length == 0
      ) {
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }

        positionupdate = {
          positionId: this.positionId,
          organizationId: organizationId,
          positionStatus: this.userdata.positionStatus,
          positionName: this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          // client: this.resourceform.value.client,
          location: this.resourceform.value.location,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            jobDescriptionId:
              this.userdata.jobDescriptionAttachment.jobDescriptionId,
            sourceFileName:
              this.userdata.jobDescriptionAttachment.sourceFileName,
            sourceFileSize:
              this.userdata.jobDescriptionAttachment.sourceFileSize,
            sourceFileType:
              this.userdata.jobDescriptionAttachment.sourceFileType,
            sourceByteArray:
              this.userdata.jobDescriptionAttachment.sourceByteArray,
            fileViewUrl: this.userdata.jobDescriptionAttachment.fileViewUrl,
            fileDownloadUrl:
              this.userdata.jobDescriptionAttachment.fileDownloadUrl,
          },
          lastUpdatedBy: this.storeCreatedbyId,
          status: this.userdata.status,
          expiryDate: this.upDate,
        };

      } else {
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }
        positionupdate = {
          positionId: this.positionId,
          organizationId: organizationId,
          positionStatus: this.userdata.positionStatus,
          positionName: this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          // client: this.resourceform.value.client,
          location: this.resourceform.value.location,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            // jobDescriptionId : this.userdata.jobDescriptionAttachment.jobDescriptionId,
            sourceFileName: this.mydata.sourceFileName,
            sourceFileSize: this.mydata.sourceFileSize,
            sourceFileType: this.mydata.sourceFileType,
            sourceByteArray: this.mydata.sourceByteArray,
            // fileViewUrl :   this.userdata.jobDescriptionAttachment.fileViewUrl,
            // fileDownloadUrl : this.userdata.jobDescriptionAttachment.fileDownloadUrl
          },
          lastUpdatedBy: this.storeCreatedbyId,
          status: this.userdata.status,
          expiryDate: this.upDate,
        };
      }

      if(this.resourceform.value.client !=null && this.resourceform.value.hiring == 32){
        console.log("client conditio !!");
        
        positionupdate['client'] = this.resourceform.value.client
      }

      this.positionservice
        .positionupdate(positionupdate, this.positionId)
        .subscribe(
          (data: any) => {
            console.log('update');
            if (data.statusCode == 200) {
              console.log('success');
              this.success = true;
              this.sucess_msg = data.description;

              setTimeout(() => {
                this.success = false;
                this.router.navigate(['/home/positions']);
              }, 4000);
            } else {
              console.log('error');
              this.error = true;
              this.buttonDisabled = false;
              this.error_msg = data.description;
              setTimeout(() => {
                this.error = false;
              }, 4000);
            }
          },
          (err) => {
            this.backdrop = false;
            this.loader = false;
            this.error = true;
            this.buttonDisabled = false;

            this.update_data_error_msg = err.error.message;
            setTimeout(() => {
              this.error = false;
            }, 4000);
          }
        );
    } //Create
    else {
      this.createDate = this.datepipe.transform(
        this.resourceform.value.expiryDate,
        'YYYY-MM-dd'
      );
      var positiondata;
      if (this.mydata.length != 0) {
        this.noticeP = this.resourceform.value.noticePeriod;

        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }

        positiondata = {
          organizationId: organizationId,
          positionStatus: 1,
          positionName: this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          client: this.resourceform.value.client,
          location: this.resourceform.value.location,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            sourceFileName: this.mydata.sourceFileName,
            sourceFileSize: this.mydata.sourceFileSize,
            sourceFileType: this.mydata.sourceFileType,
            sourceByteArray: this.mydata.sourceByteArray,
          },
          expiryDate: this.createDate,
          createdBy: this.storeCreatedbyId,
        };
        console.log(
          'this.resourceform.value.client0',
          this.resourceform.value.client
        );
        if(this.resourceform.value.client !=null)
        {
          // positiondata['client'] = {clientId:this.resourceform.value.client}
          positiondata['client'] = this.resourceform.value.client
        }
      } //Create
      else {
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }

        positiondata = {
          organizationId: organizationId,
          positionStatus: 1,
          positionName: this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          client: this.resourceform.value.client,
          location: this.resourceform.value.location,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            sourceFileName: this.mydata.sourceFileName,
            sourceFileSize: this.mydata.sourceFileSize,
            sourceFileType: this.mydata.sourceFileType,
            sourceByteArray: this.mydata.sourceByteArray,
          },
          expiryDate: this.createDate,
          createdBy: this.storeCreatedbyId,
        };
        console.log(
          'this.resourceform.value.client',
          this.resourceform.value.client
        );
        if(this.resourceform.value.client !=null){
          // positiondata['client'] = {clientId:this.resourceform.value.client}
          positiondata['client'] = this.resourceform.value.client
        }
        console.log('positiondata', positiondata);
      }

      this.backdrop = true;
      // this.loader = true;
      this.positionservice.positioncreate(positiondata).subscribe(
        (data: any) => {
          console.log('create');
          /* this.positioncraetedata= data;
        
        this.backdrop = false;
        this.loader = false;
        this.success = true;
        this.sucess_msg = this.positioncraetedata.description;
        
        setTimeout(() => 
        {
          this.router.navigate(['home/position']); 
        }, 3000) */

          if (data.statusCode == 200) {
            console.log('success');
            this.success = true;
            this.sucess_msg = data.description;
            setTimeout(() => {
              this.success = false;
              this.router.navigate(['/home/positions']);
            }, 4000);
          } else {
            console.log('error');
            this.error = true;
            this.error_msg = data.description;
            setTimeout(() => {
              this.error = false;
            }, 4000);
          }
        },
        (err) => {
          this.backdrop = false;
          // this.loader = false;
          this.error = true;
          this.buttonDisabled = false;

          this.error_msg = err.error.message;

          setTimeout(() => {
            this.error = false;
          }, 4000);
        }
      );
    }
  }

  formcancel() {
    this.router.navigate(['home/position']);
  }

  // getFileDetails(event) {
  //   console.log('att', event);
  //   const files = event.target.files;
  //   console.log('files', files);
  //   if (files) {
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       const ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();

  //       // Check if the file is an image (jpeg, png, gif)
  //       if (['jpeg', 'jpg', 'png', 'gif'].includes(ext)) {
  //         alert('Images (jpeg, png, gif) are not allowed.');
  //         return;
  //       }

  //       if (files.length != 0) {
  //         this.isShow = true;
  //       }

  //       const image = {
  //         sourceFileName: '',
  //         sourceFileType: '',
  //         sourceFileSize: '',
  //         sourceByteArray: '',
  //         fileUrlName: '',
  //         size: '',
  //         filePath: '',
  //         url: '',
  //       };

  //       this.fileName = file.name;
  //       console.log('this.fileName', this.fileName);
  //       console.log(image);
  //       this.myFile.push(file);

  //       image.sourceFileName = file.name;
  //       console.log('sourceFileName' + image.sourceFileName);

  //       image.sourceFileType = file.type;
  //       console.log('sourceFileType' + image.sourceFileType);

  //       image.sourceFileSize = file.size;
  //       console.log('sourceFileSize' + image.sourceFileSize);

  //       image.size = image.size;
  //       console.log('size' + image.size);

  //       var totalBytes = file.size;
  //       console.log('totalBytes' + totalBytes);

  //       if (totalBytes < 1000000) {
  //         image.size = Math.floor(totalBytes / 1000) + 'KB';
  //       } else {
  //         image.size = Math.floor(totalBytes / 1000000) + 'MB';
  //       }

  //       console.log('image.sourceFileType' + image.sourceFileType);

  //       if (
  //         image.sourceFileType.length == 0 &&
  //         (ext == 'docx' || ext == 'doc')
  //       ) {
  //         image.sourceFileType =
  //           'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  //       }

  //       const reader = new FileReader();

  //       reader.onload = () => {
  //         image.filePath = reader.result as string;
  //         this.extension = image.sourceFileName.substring(
  //           image.sourceFileName.lastIndexOf('.') + 1
  //         );

  //         let x = reader.result as string;
  //         let y = x.split(',');
  //         image.sourceByteArray = y[1];
  //         this.mydata = image;
  //         console.log(this.mydata);
  //       };

  //       reader.readAsDataURL(file);
  //     }
  //   }
  // }
  getFileDetails(event: any) {

    this.newFileSelected = true;

    const fileInput = event.target as HTMLInputElement;
    const filePath = fileInput?.value;
    const allowedExtensions = /(\.(?!jpg|jpeg|png|gif|bmp|webp|tiff)[^\.]+)$/i;
    
    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = '';
      alert('Invalid File Type!');
      this.newFileSelected = true;
  
      event.preventDefault();
      return
    } else {
      if (event.target.files.length > 0) {
        console.log("file if 1st!!");
        this.newFileSelected = true;
  
        const file = event.target.files[0];
        this.fileInputLabel = file.name;
        this.resourceform.get('jobDescriptionAttachment')?.setValue(file);
       
        // event.preventDefault();
  
  
      }
    }
    console.log('att', event);
    const files = event.target.files;
    console.log('files', files);
    if (files) {
      for (let i = 0; i < files.length; i++) {
  
        if (files.length != 0) {
          this.isShow = true;
          this.newFileSelected=true
  
        }
        const image = {
          sourceFileName: '',
          sourceFileType: '',
          sourceFileSize: '',
          sourceByteArray: '',
          fileUrlName: '',
          size: '',
          filePath: '',
          url: '',
        };
  
        const file = files[i];
        const fileType = file.type;
        const fileName = file.name;
  
        // Check if the file is an image
        // if (fileType.startsWith('image/')) {
        //   alert('Image files are not allowed.');
        //   this.newFileSelected=true
        //   return; // Skip processing this file
        // }
  
        this.fileName = fileName;
        console.log('this.fileName', this.fileName);
        console.log(image);
        this.myFile.push(file);
        image.sourceFileName = fileName;
        console.log('sourceFileName' + image.sourceFileName);
        image.sourceFileType = fileType;
        console.log('sourceFileType' + image.sourceFileType);
        image.sourceFileSize = file.size;
        console.log('sourceFileSize' + image.sourceFileSize);
        image.size = image.size;
        console.log('size' + image.size);
        var totalBytes = file.size;
        console.log('totalBytes' + totalBytes);
  
        if (totalBytes < 1000000) {
          image.size = Math.floor(totalBytes / 1000) + 'KB';
        } else {
          image.size = Math.floor(totalBytes / 1000000) + 'MB';
        }
        var ext = image.sourceFileName.substring(
          image.sourceFileName.lastIndexOf('.') + 1
        );
        console.log('image.sourceFileType' + image.sourceFileType);
  
        if (
          image.sourceFileType.length == 0 &&
          (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc')
        ) {
          image.sourceFileType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        const reader = new FileReader();
  
        reader.onload = () => {

          this.newFileSelected = true;

          image.filePath = reader.result + '';
          this.extension = image.sourceFileName.substring(
            image.sourceFileName.lastIndexOf('.') + 1
          );
          var ext = image.sourceFileName.substring(
            image.sourceFileName.lastIndexOf('.') + 1
          );
  
          let x: any = reader.result;
          let y: any = x.split(',');
          image.sourceByteArray = y[1];
          // this.mydata.push(image)
          this.mydata = image;
          console.log(this.mydata);
        };
        reader.readAsDataURL(file);
      }
    }
  }
  
  toggleFileInput() {
    this.showFileInput = !this.showFileInput;
  }
  //get values from form
  get positionName() {
    return this.resourceform.get('positionName');
  }
  get maxExperience() {
    return this.resourceform.get('maxExperience');
  }
  get minExperience() {
    return this.resourceform.get('minExperience');
  }
  get client() {
    return this.resourceform.get('client');
  }
  get location() {
    return this.resourceform.get('location');
  }
  get ctc() {
    return this.resourceform.get('ctc');
  }
  get jobType() {
    return this.resourceform.get('jobType');
  }
  get hiring() {
    return this.resourceform.get('hiring');
  }
  get noticePeriod() {
    return this.resourceform.get('noticePeriod');
  }
  get jobDescriptionAttachment() {
    return this.resourceform.get('jobDescriptionAttachment');
  }
  get expiryDate() {
    return this.resourceform.get('expiryDate');
  }
  get skills() {
    return this.resourceform.get('skills');
  }
  get jobDescription() {
    return this.resourceform.get('jobDescription');
  }

  fruitConcat() {
    let result;
    let text;
    this.skillsarray.forEach((element) => {
      text = element.name;
      if (result == undefined) {
        result = text;
        console.log(result);
        this.fruitsdata = result;
      } else {
        result = result + ',' + text;
        console.log(result);
        this.fruitsdata = result;
      }
    });
  }
  remove(fruit: Fruit): void {
    const index = this.skillsarray.indexOf(fruit);

    if (index >= 0) {
      this.skillsarray.splice(index, 1);
      console.log(this.skillsarray);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.skillsarray.push({ name: value.trim() });
      console.log(this.skillsarray);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
}
