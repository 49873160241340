
<div class="resource-background" style="background: none!important;">
    <div class="row">
        <div class="resource-title col-md-6 col-sm">
            <h1>RESOURCE</h1>
        </div>
        <div class="col-md-6 col-sm cancel-icon-m">
            <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example cancel icon" class="material-icons-round cancel-icon">cancel</mat-icon>
        </div>
    </div>
     
    <div class="card resource-card">
        <form>
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Resource Name : </label>
                    <label class="form-control-label-class">  {{resourceobj.resourceName}}  </label>
                </div> 
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Resource Number: </label>
                    <label class="form-control-label-class" *ngIf="resourceobj.resourceNumber != undefined"> {{resourceobj.resourceNumber}} </label>
                    <label class="form-control-label-class" *ngIf="resourceobj.resourceNumber == undefined" > -- </label> 
                </div>                                                   
              
            </div>  
            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Position : </label>
                    <label class="form-control-label-class"> {{resourceobj.position.positionName}} - {{resourceobj.position.positionNumber}}</label>
                </div> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Interview date : </label>
                    <label class="form-control-label-class">{{resourceobj.interviewDate.slice(0,10)}} </label>
                </div>
               
            </div>
            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Total Experience : </label>
                    <label class="form-control-label-class">{{resourceobj.totalExperience}} Years</label>
                </div>  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Relevent Experience : </label>
                    <label class="form-control-label-class">{{resourceobj.relevantExperience}} Years</label>
                </div>                                                       
            </div>
            <div class="row">   
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Notice Period : </label>
                    <label class="form-control-label-class">{{resourceobj.noticePeriod.listTypeValueName}}</label>
                </div>  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Current CTC : </label>
                    <label class="form-control-label-class">{{resourceobj.currentCtc}}</label>
                </div>                                                  
            </div>  
            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Expected CTC : </label>
                    <label class="form-control-label-class">{{resourceobj.expectedCtc}}</label>
                </div>    
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Job Location : </label>
                    <label class="form-control-label-class">{{resourceobj.jobLocation}}</label>
                </div>                                                   
            </div> 
            <div class="row" >  
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Resource Availability : </label>
                   <div *ngFor="let resourceobj of resourceobj.resourceAvailability" style="position: relative;left: 42%;bottom:28px">  
                    <label class="form-control-label-class">Date : {{resourceobj.resourceAvailabilityDate.slice(0,10)}} Time : {{resourceobj.resourceAvailabilityDate.substring(11,16)}}</label>
                    <!-- <label class="form-control-label-class">{{resourceobj.resourceAvailability}}</label> -->
                   </div> 
                </div>
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Interviewed : </label>
                    <label class="form-control-label-class">{{resourceobj.interviewed ? 'Yes' : 'No'}}</label>
                </div>                                                      
            </div> 
            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Interview Status : </label>
                    <label class="form-control-label-class" *ngIf="resourceobj.interviewStatus != undefined" >{{resourceobj.interviewStatus.listTypeValueName}}</label>
                    <label class="form-control-label-class" *ngIf="resourceobj.interviewStatus == undefined" > -- </label>
                </div>  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Comments : </label>
                    <label class="form-control-label-class">{{resourceobj.comments}} </label>
                </div>                                                 
            </div> 
            <div class="row">
                <div class="col-md-6" id="loginform" *ngIf="resourceobj.resourcecvattachment != undefined">
                    <div class="col-md-6" id="loginform" style="position: relative;bottom: 5px;">
                        <a class="file-download badge badge-secondary form-control-label" href="{{resourceobj.resourcecvattachment.fileDownloadUrl}}">
                            <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>Download</a>
                    </div>  
                </div> 
            </div>
        </form>  
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
