<mat-card-title style="    font-size: 20px; margin-left: -10px;
font-family: 'Mulish', sans-serif;
font-weight: 600;">
    <!-- {{"Description"}} -->
  </mat-card-title>
  <mat-dialog-content class="mat-typography">
    <div class="input-width mb-2 m-2">
      <label class="form-control-label">Description<span class="text-danger">*</span></label>
      <textarea  name="" 

        [(ngModel)]="data.description" 
        readonly
        placeholder="Enter description" 
        class="form-control email-input select-placeholder textarea"></textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button 
      [mat-dialog-close]="true" 
  
      class="btn " >Ok</button>
  </mat-dialog-actions>
