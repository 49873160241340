import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.scss']
})
export class ResourceDetailComponent implements OnInit {
  resourceId: any;
  loader:any= true;
  resourceobj   : any;
  constructor(private resourceservice :RecruitmentService,private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {

    this.resourceId = this.activatedRoute.snapshot.params.resourceId;
        this.resourceservice
        .findbyResourceId(this.activatedRoute.snapshot.params.resourceId)
        .subscribe((resource:any) =>
        {
          this.resourceobj = resource;
          this.loader = false;
          console.log(resource);

        })
  }
  
}





