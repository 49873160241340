import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import Swal from 'sweetalert2';
// import { add } from 'timelite/time';
import { add, str } from 'timelite/time';
// import { Options } from 'ngx-slider-v2';
import { Options } from '@angular-slider/ngx-slider';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { HeaderService } from 'src/app/providers/header.service';
@Component({
  selector: 'app-add-timelogs',
  templateUrl: './add-timelogs.component.html',
  styleUrls: ['./add-timelogs.component.scss'],
})
export class AddTimelogsComponent implements OnInit {
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value >= 1000) {
      return Math.round(value / 100) + '%';
    }

    return value;
  }
  submitClicked: boolean = false;
  showValidationMessage: boolean = true;
  success = false;
  error: any = false;
  disableDate: boolean = false;
  sucess_msg: any;
  errormessagejson: any;
  error_msg: any;
  enterTaskDetailStatus: boolean;
  taskForm: FormGroup;
  min: any;
  max: any;
  projectList: any = [];
  empId: any;
  taskList: any[] = [];
  myData: any;
  dateDiable: boolean = false;
  submitted: boolean = false;
  _taskName: any;
  approverId: any;
  assignHour: any;
  assignMinute: any;
  EmpList: any = [];
  taskId: any;
  hasapprover: boolean = false;
  projectAprrovers: any = [];
  approverpolicy: any = {};
  empapprovers: any;
  getdata: any;
  maxWorkHours: any;
  organziation: any;
  taskDetailStatusValue: number;
  taskDetailStatusValue1:number

  taskdetailbytask: any = [];
  // getdata:any;
  // maxWorkHours:any;
  today: Date;
  progressValue = 0;
  disabledButton: boolean = false;
  lastTaskStatusPercentage:any;
  loader:boolean =  false;

  constructor(
    public dialogRef: MatDialogRef<AddTimelogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs: ProjectManagementService,
    private timesheet: TimesheetService,
    private cdr: ChangeDetectorRef,
    private headerservice: HeaderService
  ) {
    this.taskForm = this.fb.group({
      project: ['', Validators.required],
      task: ['', Validators.required],
      date: ['', Validators.required],
      workedHours: ['', Validators.required],
      minutes: ['', Validators.required],
      description: ['', Validators.required],
      taskDetailStatus: ['0', Validators.required],
    });

    this.taskForm.patchValue({
      project: this.data?.project?.projectId,
    });
    this.patchFormValues();

    this.today = new Date();
  }

  isFormValid(): boolean {
    if (this.taskForm.valid) {
      this.submitClicked = true;
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.loader = true;
    }
    console.log('this.data', this.data);

    // this.headerservice.setTitle('Timesheet');
    this.gs.Getmaxworkhours().subscribe((x: any) => {
      console.log(x);
      this.getdata = x;
      this.maxWorkHours = this.getdata.maxWorkHours;
      console.log('maxWorkHours' + this.maxWorkHours);
    });

    this.dateDiable = true;
    let localData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('organizationId',localData);
    
    this.empId = localData.employee.employeeId;
    this.organziation = localData.organization.organizationId;
    console.log('    this.organziation',    this.organziation);
    


    this.gs.getProjectByEmployeeId( this.empId).subscribe((response: any) => {
      // Assuming response is an object with project IDs as keys
      this.projectList = Object.keys(response).map((key) => response[key]);
      // this.projectList = response;
      console.log('this.projectList', this.projectList);

      this.gs
        .getTaskByEmployee(this.empId, this.data.project.projectId)
        .subscribe((x: any) => {
          // this.taskList = x;
          console.log('taskDetailStatus : ' + this.data.taskDetailStatus);
          let workedHours = this.data.workedHours.substring(0, 2);

          this.gs
            .getTaskByEmployee(this.empId, this.data.project.projectId)
            .subscribe((x: any) => {
              this.taskList = x;
              // this.taskForm.controls['taskDetailStatus'].setValue(
              //   this.taskList[0].taskCompletionPercentage
              // );
              if (this.data) {
                this.loader = true;
                this.lastTaskStatusPercentage = this.data.taskDetailCompletionPercentage
                console.log('this.dataChecking',this.data);
                
                // Patch common values
                this.taskForm.patchValue({
                  project: this.data?.project?.projectId,
                  task: this.data?.task?.taskId,
                  date: this.data?.date,
                  workedHours: this.data?.workedHours?.substring(1, 2),
                  minutes: this.data?.workedHours?.substring(3, 5),
                  description: this.data?.description,
                  taskDetailStatus: this.data.taskDetailCompletionPercentage,
                });
                this.loader = false;
              }
              console.log(
                'taskList',
                this.taskList[0].taskCompletionPercentage
              );
            });
          // this.gs
          //   .getTaskByTaskId(this.data?.task?.taskId)
          //   .subscribe((x: any) => {
          //     this.getTask(x);
          //   });
        });
    });

    this.gs.GetEmployeTimeEntry(this.empId).subscribe((data: any) => {
      this.EmpList = data;

      console.log('this.EmpList', this.EmpList);
    });


    this.taskForm = this.fb.group({
      project: ['', Validators.required],
      task: ['', Validators.required],
      date: ['', Validators.required],
      workedHours: ['', Validators.required],
      minutes: ['', Validators.required],
      description: ['', Validators.required],
      taskDetailStatus: ['', Validators.required],
    });
    this.taskForm.valueChanges.subscribe(() => {
      const hours = this.taskForm.get('workedHours').value;
      const minutes = this.taskForm.get('minutes').value;

      if (parseInt(hours) === 0 && parseInt(minutes) === 0) {
        alert('Both hours and minutes cannot be zero');
        this.taskForm.get('workedHours').setValue('');
        this.taskForm.get('minutes').setValue('');
      }
    });

    console.log('this.data', this.data);
    //--------------InProgress------------------

    // if (this.data?.task.taskStatus?.listTypeValueId === 29) {
    //   this.taskDetailStatusValue = 50;

    //   // this.taskDetailStatusValue = 85;
    //   this.taskForm.patchValue({
    //     taskDetailStatus: this.taskDetailStatusValue,
    //   });
    // }

    // -----------------completed -------------------------------
    // if (this.data?.task.taskStatus?.listTypeValueId === 30) {
    //   console.log('taskDetailStatus333');

    //   this.taskDetailStatusValue = 85;
    //   this.taskForm.patchValue({
    //     taskDetailStatus: this.taskDetailStatusValue,
    //   });

    //   // this.taskDetailStatusValue = 85;
    // }

    // ------------------------  TODo -----------------------------
    // if (this.data?.task.taskStatus?.listTypeValueId === 31) {
    //   this.taskDetailStatusValue = 0;

    //   // this.taskDetailStatusValue = 85;
    //   this.taskForm.patchValue({
    //     taskDetailStatus: this.taskDetailStatusValue,
    //   });
    // }
    //  -------------------------Pending--------------------------
    // if (this.data?.task.taskStatus?.listTypeValueId === 3) {
    //   this.taskDetailStatusValue = 45;

    //   // this.taskDetailStatusValue = 85;
    //   this.taskForm.patchValue({
    //     taskDetailStatus: this.taskDetailStatusValue,
    //   });
    // }

    // else {
    //   this.taskDetailStatusValue = 45;
    // }

    if (this.data) {
      // this.taskForm.patchValue({
      //   project: this.data?.project?.projectId,
      //   task: this.data?.task?.taskId,
      //   date: this.data?.date,
      //   workedHours: this.data?.workedHours.substring(1, 2),

      //   minutes: this.data?.workedHours.substring(3, 5),
      //   description: this.data?.description,
      //   // taskDetailStatus: this.taskDetailStatusValue
      // });
      this.dateDiable = false;

      this.gs
        .getprojectApproversByProject(this.data?.project?.projectId)
        .subscribe((x: any) => {
        
          this.projectAprrovers = x;
          if (this.projectAprrovers.length != 0) {
            this.approverId = this.projectAprrovers[0].approvers.approverId;
            this.hasapprover = true;
          } else {
            console.log('project approver no employee approvers');
            this.hasapprover = false;
          }
        });
    }
  }

  private patchFormValues() {
    if (this.data) {
      // Patch common values
      // this.taskForm.patchValue({
      //   project: this.data?.project?.projectId,
      //   task: this.data?.task?.taskId,
      //   date: this.data?.date,
      //   workedHours: this.data?.workedHours?.substring(1, 2),
      //   minutes: this.data?.workedHours?.substring(3, 5),
      //   description: this.data?.description,
      //   taskDetailStatus: this.taskDetailStatusValue,
      // });
    }
  }

  get f() {
    return this.taskForm.controls;
  }
  get date() {
    return this.taskForm.get('date');
  }
  get workedHours() {
    return this.taskForm.get('workedHours');
  }
  get minutes() {
    return this.taskForm.get('minutes');
  }
  get taskName() {
    return this.taskForm.get('taskName');
  }
  get taskDetailStatus() {
    return this.taskForm.get('taskDetailStatus').value;
  }
  get description() {
    return this.taskForm.get('description');
  }
  get project() {
    return this.taskForm.get('project');
  }

  get task() {
    return this.taskForm.get('task');
  }
  // get taskDetailStatusValue() {
  //   return this.taskForm.get('taskDetailStatus').value;
  // }
  // keys

  hideValidationMessage() {
    this.showValidationMessage = false;
  }

  
  noNumber(event: any) {
    const pattern = /[0-9]/; // Removed the `\.` from the pattern
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  hourData(e) {
    console.log(e.target.value);
    console.log(this.getdata.maxWorkHours);
    var maxWorkHours = Number(this.getdata.maxWorkHours);

    if (e.target.value > maxWorkHours) {
      Swal.fire({
        text: 'More than ' + this.getdata.maxWorkHours + ' hours not accepted',
      });
      this.taskForm.get('workedHours').reset();

    }
    if (e.target.value >= maxWorkHours) {
      this.taskForm.patchValue({
        minutes: 0,
      });
    }
  }

  minuteData(e) {
    let x = this.taskForm.value;
    var maxWorkHours = Number(this.getdata?.maxWorkHours);
  
    if (x.workedHours >= maxWorkHours) {
      this.taskForm.patchValue({
        minutes: 0,
      });
    } else if (e.target.value > 59) {
      Swal.fire({
        text: 'More than 59 minutes not accepted',
      });
  
      this.taskForm.patchValue({
        minutes: null, // Use null instead of undefined
      });
  
      // Also clear the input field manually
      e.target.value = '';
    }
  }
  

  projectData(pro) {
    this.taskForm.patchValue({
      projectId: undefined, // Patching projectId here
      // Other patches
    });
    this.taskForm.patchValue({
      projectId: pro.projectId, // Patching projectId here
      // Other patches
    });

    this.taskForm.patchValue({
      taskDetailStatus: 1, // Patching taskId here
      // Other patches
    });

    this.lastTaskStatusPercentage = undefined;

    console.log('projectList', pro);
    //   this.min = pro.projectStartDate.substring(0,10);
    //   var currentDate1 = new Date(this.min);
    // currentDate1.setDate(currentDate1.getDate() + 1); // Decrementing the date by 1 day
    // this.min = currentDate1.toISOString().substring(0, 10);

    //   this.max = pro.projectEndDate.substring(0,10);
    //   var currentDate = new Date(this.max);
    // currentDate.setDate(currentDate.getDate() + 1); // Incrementing the date by 1 day
    // this.max = currentDate.toISOString().substring(0, 10);
    this.taskList = [];
    this.gs.getprojectByID(pro.projectId).subscribe((d: any) => {
      this.myData = d;
      console.log('this.myData', this.myData);
      this.dateDiable = false;
      this.approverId = '';

      this.gs
        .getprojectApproversByProject(pro.projectId)
        .subscribe((x: any) => {
          this.projectAprrovers = x;

          console.log('getprojectApproversByProject', x);
          if (x.errorCode == 204) {
            this.hasapprover = false;
          }

          if (this.projectAprrovers.length != 0) {
            this.approverId = this.projectAprrovers[0]?.approvers?.approverId;

            console.log( 'approver ID ',  this.approverId);
            
            this.hasapprover = true;
          } else {
            console.log('project approver no employee approvers');
            this.hasapprover = false;
          }
        });
    });

    console.log('emp & id', this.empId, pro.projectId);
    this.taskList.length = 0;
    this.taskForm.patchValue({
      task: null,
    });
    this.gs.getTaskByEmployee(this.empId, pro.projectId).subscribe((x: any) => {
      console.log('x', x);

      if (x.errorCode != 204) {
        this.taskList.length = 0;
        this.taskList = x;
        if (Array.isArray(x)) {
          // this.taskList.length = 0;
          this.taskList = x;
        } else if (typeof x === 'object') {
          // Transform the object into an array if possible
          //  this.taskList.length = 0;
          this.taskList = Object.values(x);
        } else {
          this.taskList.length = 0;
          console.error('Unexpected data type:', x);
          return;
        }
      }
    

   

      console.log('taskList', this.taskList);
    });
  }

  getTask(name) {
    console.log('nametask', name);

    this.taskForm.patchValue({
      task: name.taskId, // Patching taskId here
      // Other patches
    });
    this.taskId = name.taskId;
    this._taskName = name.taskName;
    this.min = name.employeeTaskStartDate;
    this.max = name.employeeTaskendDate;

    this.min && this.max
      ? (this.disableDate = true)
      : (this.disableDate = false);
    // Check if name.empolyeeTasks is defined and not null before iterating
    if (name.empolyeeTasks && name.empolyeeTasks.length > 0) {
      name.empolyeeTasks.forEach((e) => {
        if (e?.employee?.employeeId == this.empId) {
          this.gs.getEmployeeTaskById(e.emptaskId).subscribe((d: any) => {
            this.assignHour = ('0' + d.assignedhours).slice(-2);
            this.assignMinute = ('0' + d.assignedminutes).slice(-2);

            // this.min = e.startdate;
            // this.max = e.enddate;
          });
        }
      });
    }

    
    this.gs.lastUpdatedTimesheet(this.taskId, this.empId).subscribe(
      (data: any) => {
        console.log('checkingTaskdetail', data);
        if (data.taskDetailCompletionPercentage !== undefined) {
          this.taskForm.patchValue({
            taskDetailStatus: data.taskDetailCompletionPercentage,
            // Other patches
          });
          this.lastTaskStatusPercentage = data.taskDetailCompletionPercentage;
        }
      },
      (err) => {

        if(err.error.errorCode === 404){
    
            this.taskForm.patchValue({
              taskDetailStatus:1,
              // Other patches
            });
            // this.lastTaskStatusPercentage = data.taskDetailCompletionPercentage;
          }
        
          console.error('Error fetching last updated timesheet:', err);
        
        // Handle error as per your application's requirements (e.g., show error message)
      }
    );
  }


  // taskStatusValueChange(){
  // console.log('lastTaskStatusPercentage',this.lastTaskStatusPercentage);

  // if (this.lastTaskStatusPercentage && 
  //   this.lastTaskStatusPercentage > 0 && 
  //   this.lastTaskStatusPercentage.length > 0) {
    
  //     this.taskForm.patchValue({
  //       taskDetailStatus: this.lastTaskStatusPercentage, // Patching taskId here
  //       // Other patches
  //     });

  // }
  
  // }

  taskStatusValueChange(event: any): void {
    const newValue = event.value;
    console.log('lastTaskStatusPercentage', this.lastTaskStatusPercentage);

    if (this.lastTaskStatusPercentage && 
        this.lastTaskStatusPercentage > 0 && 
        newValue < this.lastTaskStatusPercentage) {
      // Reset the slider to the minimum allowed value
      this.taskForm.patchValue({
        taskDetailStatus: this.lastTaskStatusPercentage
      });
      console.log(`Cannot slide below ${this.lastTaskStatusPercentage}%`);
    }
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.submitted);
    if (this.taskForm.invalid) {
      alert('Please enter the valid data');
      this.enterTaskDetailStatus = true;
      return;
    } else {
      console.log(
        'this.taskForm.get(taskDetailStatus).value',
        this.taskForm.get('taskDetailStatus').value
      );
     console.log('this.approverId',this.approverId);

      if (this.approverId == undefined ||    !this.hasapprover) {
        Swal.fire({
          text: 'Please assign the approvers to Employee',
          icon: 'error',
        });
        return;
      } 

    else {
        
      
        console.log('approverID' + this.approverId);
        //return
        let user = JSON.parse(localStorage.getItem('enoteUserData'));
        let x = this.taskForm.value;

        console.log('first', x);

        // Delete the "projectId" property
        // delete x.project;
        x.description;

        x['approvalStatus'] = 3;
        let a = x.date;
        let b = new Date(a);
        b.setMinutes(b.getMinutes() + 480);
        let s = b.toISOString();
        x['date'] = s;
        x['employeeId'] = this.empId;

        x['taskName'] = this._taskName;
        // x['task'] = {
        //   taskId: x.task,
        //   taskStatus: {
        //     listTypeValueId: this.data.task.taskStatus.listTypeValueId,
        //   },
        // };

        // x['taskDetailStatus'] = this.data.taskDetailStatus;

        // "taskDetailStatus":{"listTypeValueId":30},
        // x['status'] = {
        //   listTypeValueId: this.data.status.listTypeValueId,
        // };
        x['approvers'] = {
          approverId: this.approverId,
        };

        // x['organizationId'] = this.organziation;

        console.log('2nd x', x);

        let c = this.f.workedHours.value.toString();
        var maxWorkHours = Number(this.getdata?.maxWorkHours);

        if (c > maxWorkHours) {
          // Swal.fire({
          //   text:"More than  "+maxWorkHours+" hours not accepted"
          // })
          return;
        }
        if (c >= maxWorkHours) {
          this.taskForm.patchValue({
            minutes: 0,
          });
        }
        let d = this.f.minutes.value.toString();
        if (d > 59) {
          // Swal.fire({
          //   text:"More than 59 minutes not accepted"
          // })
          return;
        }

        if (this.f.workedHours.value < 10) {
          c = `${0}${this.f.workedHours.value}`;
        }
        if (this.f.minutes.value < 10) {
          d = `${0}${this.f.minutes.value}`;
        }

        if (c == 0 && d == 0) {
          alert('Log Hours Should be greater than 0');
          return;
        }

        x['workedHours'] = `${c}:${d}:00`;
        if (x.description == '') {
          alert('Description is required');
          return;
        }
        // let taskDetailStatus: number = +x.taskDetailStatus;
        // x['taskDetailStatus'] = x.taskProgress;

        console.log('x.taskDetailCompletionPercentage', x.taskProgress);

        let hour;
        let minute;

        let arrayData = new Array();
        let TH: any = `${this.assignHour}:${this.assignMinute}:${'00'}`;
        let totalWorkedHours: any;
        let totalAssignedHours: any;

        if (Array.isArray(this.taskdetailbytask.length != 0)) {
          if (this.data) {
            this.taskdetailbytask.map((e) => {
              console.log(this.data.taskDetailId);
              if (
                e.task.taskId == this.taskId &&
                e.taskDetailId != this.data.taskDetailId
              ) {
                arrayData.push(e);
              }
            });
          } else {
            this.taskdetailbytask?.map((e) => {
              arrayData.push(e);
            });
          }

          console.log('arrayData');
          console.log(arrayData);
          if (arrayData.length >= 1) {
            hour = arrayData
              .map((a) => {
                let v = a.workedHours.split(':');
                return v[0];
              })
              .reduce(function (i, j) {
                return (
                  Number(('0' + i).slice(-2)) + Number(('0' + j).slice(-2))
                );
              });

            minute = arrayData
              .map((a) => {
                let v = a.workedHours.split(':');
                return v[1];
              })
              .reduce(function (i, j) {
                return (
                  Number(('0' + i).slice(-2)) + Number(('0' + j).slice(-2))
                );
              });
            if (minute >= 60) {
              hour += Math.floor(minute / 60);
              minute = minute % 60;
            }
            console.log('hour' + hour);
            console.log('minute' + minute);
          } else {
            // if(x.workedHours > TH){
            //    console.log("TH One");
            //   Swal.fire({
            //     text:'You have exceeded the maximum number of assigned hours.Please contact your manager',
            //     icon:'error'
            //   })
            //   return
            // }
          }

          let total = `${hour}:${minute}:${'00'}`;
          console.log('total' + total);
          console.log('workedHours' + x.workedHours);
          totalWorkedHours = add([total, x.workedHours]);
          totalAssignedHours = add([
            `${this.assignHour}:${this.assignMinute}:${'00'}`,
          ]);
          let x1 = ('0' + totalWorkedHours[0]).slice(-2);
          let x2 = ('0' + totalWorkedHours[1]).slice(-2);
          let x3 = ('0' + totalWorkedHours[2]).slice(-2);
          totalWorkedHours = `${x1}:${x2}:${x3}`;

          let y1 = ('0' + totalAssignedHours[0]).slice(-2);
          let y2 = ('0' + totalAssignedHours[1]).slice(-2);
          let y3 = ('0' + totalAssignedHours[2]).slice(-2);
          totalAssignedHours = `${y1}:${y2}:${y3}`;
        }
        // else{
        //   if(x.workedHours > TH){
        //     console.log("TH Two");
        //     Swal.fire({
        //       text:'You have exceeded the maximum number of assigned hours.Please contact your manager',
        //       icon:'error'
        //     })
        //     return
        //   }
        // }

        console.log('totalWorkedHours' + totalWorkedHours);
        console.log('totalAssignedHours' + totalAssignedHours);

        // if(totalWorkedHours > totalAssignedHours){
        //   console.log("totalAssignedHours");
        //   Swal.fire({
        //     text:'You have exceeded the maximum number of assigned hours.Please contact your manager',
        //     icon:'error'
        //   })

        //   return
        // }
        x['description'] = this.capitalizeWordsDescription(x.description);
        console.log(x);

        if (this.data) {
          x['taskDetailId'] = this.data.taskDetailId;
          console.log(JSON.stringify(x));
          delete x['project'];
          console.log('xx', x);
          x['projectId'] = this.data.project.projectId;

          //--------------InProgress------------------

          if (
            this.taskForm.get('taskDetailStatus').value <= 19 &&
            this.taskForm.get('taskDetailStatus').value >= 1
          ) {
            this.taskDetailStatusValue = 31;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }

          if (
            this.taskForm.get('taskDetailStatus').value <= 50 &&
            this.taskForm.get('taskDetailStatus').value >= 20
          ) {
            this.taskDetailStatusValue = 3;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }

          if (
            this.taskForm.get('taskDetailStatus').value <= 99 &&
            this.taskForm.get('taskDetailStatus').value >= 50
          ) {
            this.taskDetailStatusValue = 29;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }

          if (this.taskForm.get('taskDetailStatus').value == 100) {
            this.taskDetailStatusValue = 30;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }

          if (this.taskForm.get('taskDetailStatus').value == 0) {
            this.taskDetailStatusValue = 0;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }
          let body = {
            taskDetailId: this.data.taskDetailId,
            description: this.capitalizeWordsDescription(x.description),
            projectId: this.data.project.projectId,
            date: x.date,
            workedHours: `${c}:${d}:00`,
            taskProgress: this.taskDetailStatusValue,
            approvalStatus: this.data.approvalStatus,
            taskDetailCompletionPercentage:
              this.taskForm.get('taskDetailStatus').value,

            taskDetailStatus: {
              listTypeValueId: this.taskDetailStatusValue,
            },
            status: {
              listTypeValueId: this.data.status.listTypeValueId,
            },
            employeeId: this.empId,

            task: {
              taskId: x.task,
              taskStatus: {
                listTypeValueId: this.taskDetailStatusValue,
              },
              taskCompletionPercentage:
                this.taskForm.get('taskDetailStatus').value,
            },
            organizationId: this.organziation,
          };

          console.log('updatabody', body);

          this.gs.updatetimeEntry(body).subscribe((a: any) => {
            setTimeout(() => {
              // window.location.reload();
              // this.dialog.closeAll();
              this.clearSuccessMessage();
              this.success = false;
            }, 300);

            if (a.statusCode === 200) {
              this.success = true;
              this.sucess_msg = 'Timesheet Updated Successfully';

              this.sucess_msg = 'Timesheet Updated Successfully';
              this.timesheet.setSuccessUpdateMessage(
                'Timesheet Updated Successfully'
              );
              // setTimeout(() => {
              //   window.location.reload();
              // }, 2000);
            }

            if (a.errorCode === 409) {
              this.dialogRef.close();
              this.sucess_msg = 'Working Hours exceeded For the Day';
              this.timesheet.setWarningUpdateMessage(
                'Working Hours exceeded For the Day'
              ); // Call method to set success message
            }

            // setTimeout(() => {
            //   window.location.reload();
            // }, 6700);
          });
        } else {

    this.timesheet.setAfterSubmitTimesheet('timesheet');
          
          if (
            this.taskForm.get('taskDetailStatus').value <= 19 &&
            this.taskForm.get('taskDetailStatus').value >= 1
          ) {

            console.log('taskDetailStatus', this.taskForm.get('taskDetailStatus').value);
            
            this.taskDetailStatusValue1 = 31;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }
          //--------------InProgress------------------
          if (
            this.taskForm.get('taskDetailStatus').value >= 20 && 
            this.taskForm.get('taskDetailStatus').value <= 59
          ) {
            this.taskDetailStatusValue1 = 3;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }

          if (
            this.taskForm.get('taskDetailStatus').value >= 60 &&
            this.taskForm.get('taskDetailStatus').value <= 99
          ) {
            this.taskDetailStatusValue1 = 29;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }

          if (this.taskForm.get('taskDetailStatus').value == 100) {
            this.taskDetailStatusValue1 = 30;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }

          if (this.taskForm.get('taskDetailStatus').value == 0) {
            this.taskDetailStatusValue1 = 0;

            // this.taskDetailStatusValue = 85;
            // this.taskForm.patchValue({
            //   taskDetailStatus: this.taskDetailStatusValue,
            // });
          }
          // let body = {
          //   description: x.description,
          //   projectId: x.project,
          //   date: x.date,
          //   workhours: this.taskForm.get('workedHours').value,
          //   workminutes: this.taskForm.get('minutes').value,
          //   workedHours: x.workedHours,
          //   taskProgress: this.taskDetailStatusValue,
          //   taskDetailCompletionPercentage:  this.taskForm.get('taskDetailStatus').value,
          //   taskCompletionPercentage:this.taskForm.get('taskDetailStatus').value,
          //   approvalStatus: 3,
          //   taskDetailStatus: { listTypeValueId: this.taskDetailStatusValue },
          //   status: {
          //     listTypeValueId: 1,
          //   },
          //   employeeId: this.empId,
          //   task: {
          //     taskId: x.task,
          //     taskStatus: {
          //       listTypeValueId: this.taskDetailStatusValue,
          //     },
          //     taskCompletionPercentage:this.taskForm.get('taskDetailStatus').value
          //   },

          //   organizationId: this.organziation,
          // };

          //   let body2 = {
          //     "description":  x.description,
          //     "projectId":x.project,
          //     "date": x.date,
          //     "workedHours": x.workedHours,
          //     "taskProgress": this.taskDetailStatusValue,
          //     "taskDetailCompletionPercentage": this.taskForm.get('taskDetailStatus').value,
          //     "approvalStatus": 3,
          //     "taskDetailStatus": {
          //         "listTypeValueId": this.taskDetailStatusValue
          //     },
          //     "status": {
          //         "listTypeValueId": 1
          //     },
          //     "employeeId":this.empId,
          //     "task": {
          //         "taskId": x.task
          //     },
          //     "organizationId":this.organziation
          // }
          let createBody = {
            description: x.description,
            projectId: x.project,
            date: x.date,
            workedHours: x.workedHours,
            taskProgress: this.taskDetailStatusValue1,
            taskDetailCompletionPercentage:
              this.taskForm.get('taskDetailStatus').value,
            approvalStatus: 3,
            taskDetailStatus: {
              listTypeValueId: this.taskDetailStatusValue1,
            },
            status: {
              listTypeValueId: 1,
            },
            employeeId: this.empId,
            task: {
              taskId: x.task,
            },
            organizationId:    this.organziation,
          };

          console.log('body...', createBody);
          console.log(
            'checktype::',
            typeof createBody.taskDetailCompletionPercentage
          );
          this.gs.timeEntry(createBody).subscribe((a: any) => {
            // Swal.fire({
            //   icon: 'success',
            //   text: a.description,
            // });
            this.dialogRef.close();
            if (a.statusCode === 200) {
              this.sucess_msg = 'Timesheet Created Successfully';
              this.timesheet.setSuccessMessage(
                'Timesheet Created Successfully'
              ); // Call method to set success message

              // setTimeout(() => {
              //   window.location.reload();
              // }, 400);
            }

            if (a.errorCode === 204) {
              this.dialogRef.close();
              this.sucess_msg = 'Timesheet Created Successfully';
              this.timesheet.setSuccessMessage(
                'Timesheet Created Successfully'
              ); // Call method to set success message
              // setTimeout(() => {
              //   window.location.reload();
              // }, 400);
            }

            //  window.location.reload();
            // if (a.description === 'Task Created Successfully') {
            //   setTimeout(() => {
            //     this.success = true;
            //     this.sucess_msg = a.description;
            //   }, 300);
            // }

            if (a.errorCode === 409) {
              this.dialogRef.close();
              this.sucess_msg = 'Working Hours exceeded For the Day';
              this.timesheet.setWarningMessage(
                'Working Hours exceeded For the Day'
              ); // Call method to set success message
            }
            // setTimeout(() => {
            //   this.success = false;
            //   window.location.reload();
            //   // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            //   // const currentUrl = this.router.url;
            // }, 300);

            // setTimeout(()=>{
            //   // this.dialog.closeAll();
            // window.location.reload();
            // },4500);
          });
        }

        if (this.taskForm.valid) {
          // this.dialogRef.close(this.taskForm.value);

          // setTimeout(() => {
          //   this.success = true;
          //   this.sucess_msg = e.message;
          // }, 300);
          this.dialogRef.close(this.taskForm.value);
          // setTimeout(() => {
          //   // this.dialog.closeAll();

          //   // window.location.reload();
          // }, 4500);
        }
      }
    }
  }

  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  closeSuccessMessage() {
    this.success = false;
    // this.successMessage = '';
  }
  clearSuccessMessage() {
    this.timesheet.clearSuccessMessage();
  }

  // validateInput(event) {
  //   const textarea = event.target;
  //   let value = textarea.value;

  //   if (value.startsWith(' ')) {
  //     value = value.trimStart();
  //     textarea.value = value;
  //   }
  // }


  // validateInput(event) {
  //   const textarea = event.target;
  //   let value = textarea.value;
  
  //   // Prevent multiple spaces, Enter keys, comma, and backticks
  //   if (event.key === ' ' || event.key === '.' || event.key === 'Enter' || event.key === ',' || event.key === '`') {
  //     event.preventDefault();
  //   }
  
  //   // Trim spaces, commas, and backticks at the start
  //   while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
  //     value = value.trimStart().replace(/^,|^`/, '');
  //     textarea.value = value;
  //   }
  
  //   // Remove extra spaces between words
  //   value = value.replace(/\s{2,}/g, ' ');
  //   textarea.value = value;
  
  //   // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
  //   // This example checks for common date formats; you can expand it to cover other formats if necessary.
  //   const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
  //   const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
  //   if (datePattern1.test(value) || datePattern2.test(value)) {
  //     textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
  //   }
  // }
  
  // validateInput(event) {
  //   const textarea = event.target;
  //   let value = textarea.value;

  //   if (value.startsWith(' ')) {
  //     value = value.trimStart();
  //     textarea.value = value;
  //   }
  // }

  // validateInput(event) {
  //   const textarea = event.target;
  //   let value = textarea.value;
  
  //   // Allow space only if it's not the first character and the previous character is not a space
  //   if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
  //     event.preventDefault();
  //     return;
  //   }
  
  //   // Prevent Enter key, period, comma, and backticks
  //   if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
  //     event.preventDefault();
  //   }
  
  //   // Trim spaces, commas, and backticks at the start
  //   while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
  //     value = value.trimStart().replace(/^,|^`/, '');
  //     textarea.value = value;
  //   }
  
  //   // Remove extra spaces between words
  //   value = value.replace(/\s{2,}/g, ' ');
  //   textarea.value = value;
  
  //   // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
  //   const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
  //   const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
  //   if (datePattern1.test(value) || datePattern2.test(value)) {
  //     textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
  //   }
  // }
  
  
  
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }
  
  pasteCount: number = 0;
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }
}
