<nav class="navbar  bg" style="margin-top: 67px;">
    <a class="navbar-brand navDesign" style="font-size: 22px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;">Apply Leave</a>


</nav>
<div class="common-content">
    <div class="card-new colDesign">
        <!-- <div class="card"> -->
        <div class="card-body ">
            <div class="row mb-2">
                <div class="col-md-12">
                    <div class="--col-sm-12">
                        <mat-card>
                            <div *ngIf="isLoading"
                                class="d-flex align-items-center justify-content-center loader-container"
                                style="margin-top: 10%;">
                                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <!-- <ng-container *ngIf="isLoading; else elseTemplate">
                                    <div class="loading">
                                    <h4 class="text-muted">loading....</h4>
                                    <br />
                                    <div class="spinner-grow text-secondary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-success" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-danger" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-info" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-dark" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    </div>
                                </ng-container> -->
                            <!-- <ng-template #elseTemplate> -->
                            <div>
                                <form (ngSubmit)="onSubmit(leaveForm.value)" [formGroup]="leaveForm">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- <h3 class="title">*Enter details to apply Leave*</h3> -->
                                        </div>
                                    </div>

                                    <!-- employeeName -->
                                    <div class="form-group">
                                        <div class="input-width" id="loginform">
                                            <label class="form-control-label" style="font-family: Mulish;">Employee
                                                Name<span class="star">
                                                    *</span></label>
                                            <input type="text" formControlName="employeeName"
                                                class="form control
                                                select-placeholder
                                                email-input " style="outline:none;border-radius: 5px;"
                                                [ngClass]="{ 'is-invalid': submitted && f.employeeName.errors }"
                                                readonly />
                                            <div *ngIf="submitted && f.employeeName.errors " class="invalid-feedback">
                                                <div *ngIf="f.employeeName.errors.required">
                                                    employee_Name is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- employeeEmail -->
                                    <div class="form-group">
                                        <div class="input-width" id="loginform">
                                            <label class="form-control-label" style="font-family: Mulish;">Email-id<span class="star">
                                                *</span></label>
                                            <input type="text" formControlName="email" readonly
                                            class="form control
                                            select-placeholder
                                            email-input " style="outline:none;border-radius: 5px;"/>
                                        </div>
                                    </div>

                                    <!-- leaveType -->
                                    <div class="form-group">
                                        <div class="input-width" id="loginform">
                                            <label class="form-control-label">Leave Type<span class="star"> *</span></label>
                                            <mat-select placeholder="Select Leave Type" formControlName="leaveType"
                                            class="form control
                                            select-placeholder
                                            email-input "  style="background-image:none;outline:none;border-radius: 5px; font-family: Mulish;">
                                                <mat-option [value]="null"> ---Select Leave Type --- </mat-option>
                                                <mat-option 
                                                (click)="onSelectionChange(type,i)"
                                                *ngFor="let t of type; let i = index"
                                                    value="{{ t.leaveType.leaveTypeId }}" >
                                                    {{ t.leaveType.leaveName }} <em> * </em>bal: {{ t.noOfDays }}
                                                </mat-option>
                                            </mat-select>
                                            <div *ngIf="(f.leaveType.invalid && submitted) || f.leaveType.dirty">
                                                <mat-error *ngIf="f.leaveType.errors?.required">Please select Leave
                                                    Type</mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <!-- startDate -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">From Date<span class="star">
                                                            *</span></label>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"
                                                        style="float: right;position: relative;top: 35px;"></mat-datepicker-toggle>
                                                    <input (click)="picker.open()" [matDatepickerFilter]="myFilter"
                                                        (dateInput)="nofoDays($event)"
                                                        (dateChange)="someMethodName($event)"
                                                        formControlName="startDate" [matDatepicker]="picker"
                                                        placeholder="Select From Date" readonly
                                                        class="form control
                                                        select-placeholder
                                                        email-input " style="outline:none;border-radius: 5px; margin-top:-1%"/>
                                                    <mat-datepicker #picker ></mat-datepicker>
                                                    <div
                                                        *ngIf="(f.startDate.invalid && submitted) || f.startDate.dirty">
                                                        <mat-error *ngIf="f.startDate.errors?.required">Please select
                                                            From Date</mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- endDate -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">To Date<span class="star">
                                                            *</span></label>
                                                    <mat-datepicker-toggle matSuffix [for]="a"
                                                        style="float: right;position: relative;top: 35px;"></mat-datepicker-toggle>
                                                    <input (click)="a.open()" [matDatepickerFilter]="myFilter"
                                                        (dateInput)="nofoDays($event)" [min]="min"
                                                        formControlName="endDate" [matDatepicker]="a"
                                                        placeholder="Select To Date" [disabled]="endDate_disable"
                                                        readonly
                                                        class="form control
                                                        select-placeholder
                                                        email-input " style="outline:none;border-radius: 5px;margin-top: -1%;" />
                                                    <mat-datepicker #a ></mat-datepicker>
                                                    <div *ngIf="(f.endDate.invalid && submitted) || f.endDate.dirty">
                                                        <mat-error *ngIf="f.endDate.errors?.required">Please select To
                                                            Date</mat-error>
                                                    </div>
                                                    <mat-error *ngIf="show_error_msg">
                                                        To date should be greater than from date.
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <ng-container *ngIf="halfDay;else weekDay">
                                                <!-- Half Day -->
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label" style="font-family: Mulish;">No.of
                                                        Days<span class="star"> *</span></label>
                                                    <select formControlName="noOfDays"
                                                    class="form control
                                                    select-placeholder
                                                    email-input " style="outline:none;border-radius: 5px;font-family: Mulish;">
                                                        <option value="0.5">0.5</option>
                                                        <option value="1">1</option>
                                                    </select>
                                                    <div *ngIf="(f.noOfDays.invalid && submitted) || f.noOfDays.dirty">
                                                        <mat-error *ngIf="f.noOfDays.errors?.required">Please enter
                                                            Number Of Days</mat-error>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-template #weekDay>
                                                <!-- nofoDays -->
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">No.of Days<span class="star">
                                                            *</span></label>
                                                    <input type="text" [value]="noOFDays" readonly
                                                    class="form control
                                                    select-placeholder
                                                    email-input " style="outline:none;border-radius: 5px;" />
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="input-width" id="loginform">
                                            <label class="form-control-label mt-3">Reason for Leave<span class="star">
                                                    *</span></label>
                                            <textarea type="text" formControlName="leaveReason"
                                                placeholder="Enter leave reason!!!!" rows="2"
                                                class="form control
                                                textarea
                                                 email-input " style="outline:none;border-radius: 5px;"
                                                (input)="validateInput($event)"></textarea>
                                            <div *ngIf="(f.leaveReason.invalid && submitted) || f.leaveReason.dirty">
                                                <mat-error *ngIf="f.leaveReason.errors?.required">Please enter leave
                                                    reason</mat-error>
                                                    <mat-error *ngIf="(f.leaveReason.errors?.pattern )">
                                                        Please enter a valid leave reason
                                                    </mat-error>
                                            </div>
                                             
                                        </div>
                                    </div>

                                    <!-- button -->
                                    <div class="form-group">
                                        <div class="d-flex">
                                            <div style="width: 45%;margin: 0 auto;">
                                                <!-- <button mat-stroked-button class="cancel-button"
                                                    style="font-family: Mulish;" color="accent" type="button"
                                                    (click)="Cancel()">
                                                    Cancel
                                                </button> -->
                                                
 <button type="reset"   class=""      mat-raised-button (click)="Cancel()"  style="

 
 margin-left: 7px;
   background: none;

   border: 1px solid #ddd;
   font-size: 14px;
   font-weight: 300;
   height: 40px !important;
 "  
  style="color:var(--Red-Color, #FF5D5D); height:35px; margin-left:95% "
 >Cancel</button>
                                            </div>
                                            <div style="width: 45%;margin: 0 auto;">
                                                <!-- <button mat-stroked-button class="apply-button"         [disabled]="processing"
                                                        color="primary" type="submit" style="font-family: Mulish;">       
                                                        &nbsp;Apply&nbsp;
                                                        </button> -->
                                                <!-- <button type="button" mat-raised-button type="submit"
                                                    class="searchBtn mat-raised-button --mat-button-base mr-2 custom-button"
                                                    [disabled]="!processing || leaveForm.invalid">
                                                    Apply
                                                </button> -->
                                                <button type="submit"
                                                        class="searchBtn mr-2 custom-button"
                                                        [disabled]=" leaveForm.invalid || !processing ">
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- </ng-template> -->
                        </mat-card>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{success_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>