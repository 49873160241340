<style>
    .full-width{
      width:100%;
    }
  </style>
  <div class="container">
    <div class="-form-group">
      <form [formGroup]="exceptionForm" (ngSubmit)="onSubmit()">
        <div class="-form-group">
          <div class="input-width" id="loginform">
            <label class="form-control-label mt-3 ">Exception Name<span class="star"> *</span></label>
            <input formControlName="exceptionName" placeholder="Enter Exception Name" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
            />
            <mat-error *ngIf="f.exceptionName.hasError('required') && (f.exceptionName.touched || f.exceptionName.dirty)">
              Please enter <strong>Exception Name</strong>
            </mat-error>
            <mat-error *ngIf="(f.exceptionName.errors?.pattern )">
              Please enter a valid Exception Name
          </mat-error> 
          </div>
        </div>
  
        <div class="-form-group">
          <div class="input-width" id="loginform">
            <label class="form-control-label mt-3 ">Date<span class="star"> *</span></label>
            <mat-datepicker-toggle matSuffix [for]="picker" style="float: right;position: relative;top: 38px;"></mat-datepicker-toggle>
            <input
              (click)="picker.open()"
              formControlName="exceptionDate"
              [matDatepicker]="picker"
              [disabled] = "dateDisable"
              class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"

              placeholder="Choose Date"/>
            <mat-datepicker #picker touchUi></mat-datepicker>
            <mat-error *ngIf=" f.exceptionDate.hasError('required') && (f.exceptionDate.touched || f.exceptionDate.dirty)">
              Please select <strong>Exception Date</strong>
            </mat-error>
          </div>
        </div>
  
        <div class="-form-group" *ngIf="data.id">
          <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Exception Status<span class="star"> *</span></label>
            <mat-select formControlName="exceptionStatus" placeholder="Select Exception Status" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
            >
              <mat-option value="1" selected> Active </mat-option>
              <mat-option value="2">InActive</mat-option>
            </mat-select>
            <mat-error *ngIf="f.exceptionStatus.hasError('required') && (f.exceptionStatus.touched || f.exceptionStatus.dirty)">
              <span>Exception Status is required</span>
            </mat-error>
          </div>
        </div>
  
        <div class="-form-group float-right">
          <button mat-raised-button style="margin: 10px 0 0 5px; color: #ff0000a8;" type="button" mat-dialog-close class="btn btnRes mr-2">
            Cancel
          </button>
          <button mat-raised-button color="primary" style="margin: 10px 0 0 5px" type="submit"
          class="searchBtn"
          [disabled]="btnDisable">
            {{data.id ? 'Update' :'Create'}}
          </button>
        </div>
      </form>
    </div>
  </div>
  