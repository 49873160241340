<!-- <section>
  <div class="sub-header">
      <div class="col-md-12 mb-5">
          <div class="row">
            
              <div class="sub-header-buttons">
                <a routerLink="/home/mywfh-list" mat-raised-button title="Back" class="">Back</a>
            </div>
          </div>
      </div>
  </div>
</section> -->

<nav class="navbar  bg" style="margin-top: 67px;">
  <a class="navbar-brand navDesign" style="font-size: 22px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;">Apply Work From Home</a>


</nav>
<div class="common-content">
  <div class="card-new colDesign" style="font-family: Mulish;">
    <!-- <div class="card"> -->
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-md-12">
          <div class="col-sm-12">
            <mat-card>
              <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center" style="">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;top:50%;left: 48%;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <!-- <ng-container *ngIf="isLoading; else elseLoading">
                  <div class="loading">
                    <h4 class="text-muted">loading....</h4>
                    <br>
                    <div class="spinner-grow text-secondary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-info" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-dark" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </ng-container> -->
              <!-- <ng-template #elseLoading> -->
              <div>
                <form action="" [formGroup]="workfromHomeFrom" (ngSubmit)="applyWFH()">
                  <div class="row">
                    <div class="col-md-12">
                      <!-- <h3 class="title">*Enter details to apply Work From Home*</h3> -->
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <!-- startDate -->
                      <div class="--form-group">
                        <div class="input-width" id="loginform">
                          <label class="form-control-label" style="font-family: Mulish;">From Date<span class="star">
                              *</span></label>
                          <!-- [matDatepickerFilter]="myFilter" -->
                          <mat-datepicker-toggle matSuffix [for]="picker"
                            style="float: right;position: relative;top: 33px;"></mat-datepicker-toggle>
                          <input (click)="picker.open()" [matDatepickerFilter]="myFilter"
                            (dateChange)="someMethodName($event)" formControlName="startDate" [matDatepicker]="picker"
                            placeholder="Select From Date" (dateInput)="nofoDays($event)" readonly class="form control
select-placeholder
email-input " style="outline:none;border-radius: 5px; margin-top: -2%;font-family: Mulish">
                          <mat-datepicker #picker ></mat-datepicker>
                          <!-- <mat-error *ngIf=" f.startDate.hasError('required') && (f.startDate.touched || f.startDate.dirty)">
                                Please select <strong>From Date</strong>
                              </mat-error> -->
                          <div style="font-family: Mulish;"
                            *ngIf="(f.startDate.invalid && submitted) || f.startDate.dirty">
                            <mat-error *ngIf="f.startDate.errors?.required">Please select From Date</mat-error>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <!-- endDate -->
                      <div class="--form-group">
                        <div class="input-width" id="loginform">
                          <label class="form-control-label" style="font-family: Mulish;">To Date<span class="star">
                              *</span></label>
                          <mat-datepicker-toggle matSuffix [for]="a"
                            style="float: right;position: relative;top: 33px;"></mat-datepicker-toggle>
                          <input (click)="a.open()" [matDatepickerFilter]="myFilter" (dateInput)="nofoDays($event)"
                            [min]="min" formControlName="endDate" [matDatepicker]="a" placeholder="Select To Date"
                            [disabled]="endDate_disable" readonly class="form control
select-placeholder
email-input " style="outline:none;border-radius: 5px;margin-top: -2%;font-family: Mulish">
                          <mat-datepicker #a ></mat-datepicker>
                          <div style="font-family: Mulish;" *ngIf="(f.endDate.invalid && submitted) || f.endDate.dirty">
                            <mat-error *ngIf="f.endDate.errors?.required">Please select To Date</mat-error>
                          </div>
                          <mat-error style="font-family: Mulish;" *ngIf="show_error_msg">
                            To date should be greater than from date.
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- nofoDays -->
                  <div class="input-width" id="loginform">
                    <label class="form-control-label mt-3" style="font-family: Mulish;">No.of Days<span class="star">
                        *</span></label>
                    <input type="text" [value]="noOFDays" readonly class="form control
select-placeholder
email-input " style="outline:none;border-radius: 5px;font-family:Mulish">
                  </div>
                  <!-- reason -->
                  <div class="input-width form-group" id="loginform">
                    <label class="form-control-label mt-3">Reason<span class="star"> *</span></label>
                    <textarea type="text" formControlName="reason" placeholder="Enter reason!!!!" rows="2" class="form control
                    textarea
                    email-input " style="outline:none;border-radius: 5px;" (input)="validateInput($event)"></textarea>
                    <div *ngIf="(f.reason.invalid && submitted) || f.reason.dirty">
                      <mat-error *ngIf="f.reason.errors?.required">Please enter reason</mat-error>
                      <mat-error *ngIf="(f.reason.errors?.pattern )">
                        Please enter a valid reason
                      </mat-error>
                    </div>

                  </div>

                  <!-- buttons -->
                  <div class="form-group">
                    <div class="d-flex">
                      <div style="width: 45%;margin: 0 auto;">
                        <!-- <button mat-stroked-button class="cancel-button" style="font-family: Mulish;" color="accent" type="button" (click)="Cancel()">
                              Cancel
                            </button> -->
                        <button type="reset" class="" mat-raised-button (click)="Cancel()" style="
    float: right; 
    
    margin-left: 7px;
      background: none;
   
      border: 1px solid #ddd;
      font-size: 14px;
      font-weight: 300;
      height: 40px !important;
    " style="color:var(--Red-Color, #FF5D5D); height:35px; margin-left:91% ">Cancel</button>
                      </div>
                      <div style="width: 45%;margin: 0 auto;">
                        <!-- <button mat-stroked-button class="apply-button" style="font-family: Mulish;" color="primary" type="submit">
                              &nbsp;Apply&nbsp;
                            </button> -->
                        <!-- <button type="submit" mat-raised-button
                          class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                          [disabled]="!searchButton || workfromHomeFrom.invalid">
                          Apply
                        </button> -->
                        <button type="submit"
                        class="searchBtn mr-2 custom-button"
                        [disabled]="!searchButton || workfromHomeFrom.invalid">
                            Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!-- </ng-template> -->
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br>{{success_msg}}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br>{{error_msg}}
</div>