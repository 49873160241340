<!-- Sub header start here-->

<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <div class=""> 
                        <span class="h5 sub-content pr-3" style="    position: relative;
                        top: 6px;
                        color: #181c32;
                        font-weight: 800;
                        font-size: 22px;
                        font-family: 'Mulish', sans-serif;
                        right: 15px;"   >Add Appraisal</span>
                    </div>
                    <!-- <span routerLink="/home/kra-template" class="h5 sub-content">Add Branch</span> -->
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
              <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<form [formGroup]="apprialform" (ngSubmit)="searchSubmit()">
<div class="common-content">
    <div class="card-new">
        <div class="card" style="font-family:Mulish;">
            <div class="card-body">
                <div class="row">
                <div class="col-md-3 float-left mb-3 px-0">
                    <label class="col-form-label" style="font-weight: 600; margin-left: 12px;">Period To  <span class="star">*</span></label>
                    <select  formControlName="period" id="period" class="form-select form-select-solid form-select-lg" style="margin-left:12px"
                    (change)="onPeriodChange($event)" >
                    <!-- <option value="" >- Select -</option> -->
                    <!-- <option *ngFor="let year of periodData" value="{{year.financialPeriodId}}"
>
                        {{year.financialperiodMonth}}-{{year.financialPeriodYear}}</option> -->
                    <!-- <option *ngFor="let year of periodData" [value]="year.financialPeriodId + '-' + year.finanicalYear.financialYearId"
                    [selected]="year.financialPeriodYear == currentYear && year.financialperiodMonth == currentMonth">
                    

                        {{ year.financialperiodMonth }}-{{ year.financialPeriodYear }}
                    </option> -->
                    <option *ngFor="let year of periodData"  [value]="year.financialPeriodId"
                    >
                    <!-- <p>{{patchPeriodId}}</p> -->
                    
                    {{ year.financialperiodMonth }}-{{ year.financialPeriodYear }}
                    </option>   
                    <!-- <option *ngFor="let year of periodData" [value]="getPeriodValue(year)">
                        {{ year.financialperiodMonth }}-{{ year.financialPeriodYear }}-{{ year.finanicalYear.financialYearId }}
                    </option> -->
                    </select>
                    
                 <div class="valid-msg" *ngIf="(validate.period.invalid && validate.period.touched) || validate.period.dirty || submitted">
                    
                    <!-- <span *ngIf="validate.period.errors?.required" class="text-danger">Please select period.</span>  -->
                    <!-- <span *ngIf="validate.kraTemplateHeaderId.errors?.pattern" class="text-danger">Please enter valid template name</span> -->
                 </div>
                 
                </div>
              

              <div class=" col-md-9" >
                <button  type="reset" mat-raised-button  
                class="btn search-btn" title="Back" style="font-family: Mulish; float:inline-end "
                 (click)="back()">Back</button>
            </div>
        </div>

                <table class="table items appraisa-form table-bordered table-condensed table-hover product_table  ">
                    <thead class="appraisalfontbold">
                        <tr>
                            <td class="text-center" style="height:70px; font-size:larger; font-weight: 600;" colspan="12">
                                APPRAISAL
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width" colspan="1" style="font-weight: 600;">
                                EMPLOYEE NAME
                            </td>
                            <td class="tab-medium-width ">
                                <input type="text" formControlName="employeeName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="" readonly>
                                <!-- <div class="valid-msg">
                                    <span class="text-danger d-none">Please select KRA Template</span>
                                </div> -->
                            </td>

                            <td class="tab-medium-width" colspan="" style="font-weight: 600;">
                                DEPARTMENT
                            </td>
                            <td class="tab-medium-width" colspan="3">
                                <input type="text" formControlName="department" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="" readonly>
                                <!-- <div class="valid-msg">
                                    <span class="text-danger d-none">Please select KRA Template</span>
                                </div> -->
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width" style="font-weight: 600;">
                                EMPLOYEE ID
                            </td>
                            <td class="tab-medium-width">
                                <input type="text" formControlName="empId" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="" readonly>
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select KRA Template</span>
                                </div>
                            </td>

                            <td class="tab-medium-width" colspan="" style="font-weight: 600;">
                                REVIEWER NAME
                            </td>
                            <td class="tab-medium-width" colspan="4">
                                <input type="text" formControlName="reviewerName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="" readonly>
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select KRA Template</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width " style="font-weight: 600;">
                                POSITION HELD
                            </td>
                            <td class="tab-medium-width">
                                <input type="text" formControlName="positionHold" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="" readonly>
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select KRA Template</span>
                                </div>
                            </td>

                            <td class="tab-medium-width" colspan="" style="font-weight: 600;">
                                REVIEWER TITLE
                            </td>
                            <td class="tab-medium-width" colspan="4">
                                <input type="text" formControlName="reviewrTitle" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="" readonly>
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select KRA Template</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width " style="font-weight: 600;">
                                LAST REVIEW DATE
                            </td>
                            <td class="tab-medium-width">
                                <mat-datepicker-toggle matSuffix [for]="periodFrom" class="calender-icon-new" style="position: absolute;"  disabled ></mat-datepicker-toggle>
                                <mat-datepicker #periodFrom ></mat-datepicker>
                                <input type="text" readonly formControlName="LastReviewDate" id="LastReviewDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" matInput [matDatepicker]="periodFrom"  placeholder="Select Period From" style="padding-left: 35px;">
                            <!-- <mat-datepicker-toggle matSuffix [for]="periodFrom"></mat-datepicker-toggle> -->
                            
                            <!-- <div class="valid-msg" *ngIf="(validate.LastReviewDate.invalid && validate.LastReviewDate.touched) || validate.LastReviewDate.dirty || submitted">
                                <span *ngIf="validate.LastReviewDate.errors?.required" class="text-danger">Please select period from</span>
                            </div> -->
                            </td>
                            
                            <td class="tab-medium-width" colspan="" style="font-weight: 600;">
                                CREATED DATE
                            </td>
                            <td class="tab-medium-width" colspan="">
                                <mat-datepicker-toggle matSuffix [for]="toFrom" class="calender-icon-new" style="position: absolute !important;" disabled></mat-datepicker-toggle>
                                <mat-datepicker #toFrom></mat-datepicker>

                                <input type="text" readonly formControlName="createDate" [value]="formattedTodayDate" id="createDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" matInput [matDatepicker]="toFrom"  placeholder="Select Period From" style="padding-left: 35px;"   >
                                <!-- <mat-datepicker-toggle matSuffix [for]="toFrom" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle> -->
                                <!-- <mat-datepicker-toggle matSuffix [for]="toFrom"></mat-datepicker-toggle> -->
                                <!-- <mat-datepicker-toggle matSuffix [for]="toFrom" style="position: absolute;left: 1160px;"></mat-datepicker-toggle>
                                <input type="text" formControlName="createDate" id="createDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" matInput [matDatepicker]="toFrom"  placeholder="Select Period From">
                                <mat-datepicker #toFrom></mat-datepicker> -->
                            </td>
                        </tr>
                        </thead>
                        </table>


                        <table class="table items table-responsive appraisa-form table-bordered table-condensed table-hover product_table">
                        <thead class="appraisalfontbold">
                        <tr>
                            <td class="text-center" style="height:60px; font-size:larger; font-weight: 600;" colspan="12">
                                CHARACTERISTICS
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center" style="padding-bottom:25px; font-weight:600;" rowspan="2">KRA</th>
                            <th class="text-center"  style="padding-bottom:25px; font-weight:600;"rowspan="2">Description<span class="text-danger">*</span></th>
                            <th class="text-center" style="padding-bottom:25px; font-weight:600;" rowspan="2">Detail<span class="text-danger">*</span></th>
                            <th class="text-center" style="padding-bottom:25px; font-weight:600;" rowspan="2">Weightage(%)</th>
                            <th class="text-center" colspan="2" style="background: #d2d2e5; font-weight:600;">Employee Rating</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="background: #d2d2e5; font-weight:600;">Rating(Out Of 5)</th>
                            <th class="text-center" style="background: #d2d2e5; font-weight:600;">Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let kraline of kraLine().controls; let i = index" formArrayName="kra">
                            <tr   [formGroupName]="i">
                                <!-- <ng-container *ngFor="let kraline of kraLine.controls; let i = index"> -->
                                   
                                <!-- <div *ngFor="let kraLineData of kraTempLine">
                                    <tr  *ngFor="let kraline of kraLine.controls; let i = index" formArrayName="kra">   -->
                                        <td class="tab-maxfull-width " style="font-weight: 600;">
                                            {{ kraline.get('criteriaName').value }}
                                            <!-- {{kraline.get('criteriaName').value}} -->
                                             <!-- {{kraline.value.criteriaName}} -->
                                            <!-- <input type="number" formControlName="criteriaName" readonly=""   class="form-control text-center"> -->
                                        </td>
                                         <td class="tab-maxfull-width">
                                             <textarea class="form-control" formControlName="criteriaDescription" required=""  rows="2"   (input)="validateInput($event)"  (keydown)="handleKeyPress($event)" ></textarea>
                                             <mat-error 
                                              *ngIf="submitted || KraLineFormArray.controls[i].get('criteriaDescription')?.touched && KraLineFormArray.controls[i].get('criteriaDescription')?.invalid">
                                             <span
                                                 *ngIf="KraLineFormArray.controls[i].get('criteriaDescription')?.hasError('required')">Please Enter Criteria Description.
                                             </span>
                                            </mat-error>
                                         </td>
             
                                         <td class="tab-maxfull-width">
                                             <textarea class="form-control" formControlName="criteriaDetail"  required=""  rows="2" (input)="validateInput($event)"  (keydown)="handleKeyPress($event)"></textarea>
                                             <mat-error 
                                             *ngIf="submitted || KraLineFormArray.controls[i].get('criteriaDetail')?.touched && KraLineFormArray.controls[i].get('criteriaDetail')?.invalid">
                                            <span
                                                *ngIf="KraLineFormArray.controls[i].get('criteriaDetail')?.hasError('required')">Please Enter Criteria Detail.
                                            </span>
                                           </mat-error>
                                         </td>
             
                                         <td class="tab-medium-width"> 
                                             <input type="number" readonly="" formControlName="weightage"  class="form-control text-center" value="{{kraline.get('weightage').value}}">
                                         </td>
             
                                         <td class="tab-medium-width" style="background: #d2d2e5;">
                                             <select  formControlName="empRating" required=""  (change)="onInputChange($event,i)" class="form-control -searchDropdown">
                                                <option value="">- Rating -</option>
                                                <option value="5">5 - Excellent</option>
                                                <option value="4">4 - Very Good</option>
                                                <option value="3">3 - Good</option>
                                                <option value="2">2 - Weak</option>
                                                <option value="1">1 - Unacceptable</option>
                                            </select>
                                            <mat-error
                                                *ngIf="submitted || KraLineFormArray.controls[i].get('empRating')?.touched && KraLineFormArray.controls[i].get('empRating')?.invalid">
                                                <span
                                                    *ngIf="KraLineFormArray.controls[i].get('empRating')?.hasError('required')">Please Enter Employee Rating.
                                                </span>
                                            </mat-error>
                                         </td>
             
                                         <td class="tab-medium-width" style="background: #d2d2e5;">
                                             <input type="number" formControlName="rating" readonly=""  class="form-control text-center">
                                         </td>
                                        <!-- </ng-container> -->
                                </tr>
                        </ng-container>
                        <!-- <tr  *ngFor="let kraLineData of kraTempLine" formArrayName="kra"> -->
                        

                        
                        
                    </tbody>
                    
                </table>
                <table class="table items table-responsive appraisa-form table-bordered table-condensed table-hover">
                    <tbody>
                        <!-- OVER ALL RATING -->
                        <tr>
                            <td class="tab-maxfull-width appraisalfontbold" colspan="" style="font-weight: 600;">
                                EMPLOYEE RATING
                            </td>
                            <td class="tab-maxfull-width text-center">
                                <input type="text" id="over_all_rating" formControlName="empoverallrating" name="over_all_rating" readonly="" class="form-control text-center" value="">
                            </td>
                        </tr>
                        <!-- OVER ALL RATING -->
                        <!-- <tr>
                            <td class="tab-medium-width appraisalfontbold" style="font-weight: 600;">
                                Total Hours Worked 
                            </td>

                            

                            <td class="tab-medium-width" colspan="" style="font-weight: 600;">
                                <input 
                                    type="text" 
                                    name="total_hours_worked" 
                                    formControlName="hoursWorked" 
                                    
                                    readonly 
                                    id="total_hours_worked" 
                                    class="form-control text-center" 
                                    (input)="validateHours($event)" 
                                    maxlength="8"
                                    pattern="\d{2}:\d{2}:\d{2}"
                                    (paste)="preventPaste($event)"

                                    >
                                    
                                <div class="valid-msg" *ngIf="(validate.hoursWorked.invalid && validate.hoursWorked.touched) || validate.hoursWorked.dirty || submitted">
                                    <span *ngIf="validate.hoursWorked.errors?.required" class="text-danger">Please Enter Total Hours Worked</span>
                                    <span *ngIf="validate.hoursWorked.errors?.maxlength" class="text-danger">Total Hours Worked cannot exceed 3 digits</span>
                                </div>
                            </td>
                            
                            
                        </tr> -->
                        <tr>
                            <td class="tab-medium-width appraisalfontbold" style="font-weight: 600;">
                                Total Hours Worked <span class="text-danger">*</span>
                            </td>

                            <td class="tab-medium-width" colspan="" style="font-weight: 600;">
                                <input  type="number" name="total_hours_worked" formControlName="hoursWorked" required="" id="total_hours_worked" class="form-control text-center" value="" (input)="validateHours($event)"  [maxlength]="3" (paste)="preventPaste($event)">
                                <div class="valid-msg" *ngIf="(validate.hoursWorked.invalid && validate.hoursWorked.touched) || validate.hoursWorked.dirty || submitted">
                                    <span *ngIf="validate.hoursWorked.errors?.required" class="text-danger">Please Enter Total Hours worked</span>
                                    <span *ngIf="validate.hoursWorked.errors?.maxlength" class="text-danger">Total Hours worked cannot exceed 3 digits</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width appraisalfontbold" colspan="" style="font-weight: 600;">
                                Leave Days 
                            </td>
                            <td class="tab-medium-width" >
                                <input  type="number" id="leave_days" required="" formControlName="leaveDays" name="leave_days" class="form-control text-center" value=""  [min]="minDays" (input)="validateLeave($event)"  [maxlength]="2" (paste)="preventPaste($event)" >
                                <div class="valid-msg" *ngIf="(validate.leaveDays.invalid && validate.leaveDays.touched) || validate.leaveDays.dirty || submitted">
                                    <span *ngIf="validate.leaveDays.errors?.required" class="text-danger">Please Enter Leave Days</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width" style="font-weight: 600;">Employee Comment / Query:</td>
                            <td class="tab-medium-width text-center" colspan="">
                                <textarea name="employee_query" id="employee_query"  formControlName="query" class="form-control" rows="2"></textarea>
                            </td>
                        </tr>

                        <tr>
                            <td class="tab-medium-width" style="font-weight: 600;">Upload Timesheet Document <span class="text-danger">*</span></td>
                            <!-- <td class="tab-medium-width" colspan="">
                                <input type="file" name="file" id="file"  formControlName="file"  class="form-control" (change)="onFileSelected($event)" accept=".pdf,.doc,.docx,.xlsx" style="font-weight: 600; line-height: 1.5;">
                                <div class="valid-msg" *ngIf="(validate.file.invalid && validate.file.dirty) || validate.file.dirty || submitted">
                                    <span *ngIf="validate.file.errors?.required" class="text-danger">Please Upload Timesheet</span>
                                </div>
                            </td> -->

                            <td class="tab-medium-width" colspan="">
                                <input type="file" name="file" id="file" formControlName="file" class="form-control" (change)="onFileSelected($event)" accept=".pdf,.doc,.docx,.xlsx" style="font-weight: 600; line-height: 1.5;">
                                <div class="valid-msg" *ngIf="(apprialform.get('file').invalid && apprialform.get('file').dirty) || submitted">
                                    <span *ngIf="apprialform.get('file').errors?.required" class="text-danger">Please Upload Timesheet</span>
                                </div>
                            </td>
                            
                            
                        </tr>
                    </tbody>
                </table>

                <div class="row">
                    <div class="col-md-12 text-right">
                        <a routerLink="/home/appraisal" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2" style="font-family: Mulish; color: red;">
                            Cancel
                        </a>
                        <!-- <button type="submit" mat-raised-button color="primary"style="font-family: Mulish;" [disabled]="submitButtonDisabled">Submit</button> -->
                        <button type="submit" mat-raised-button title="Submit" style="font-family: Mulish;"
                        [disabled]="submitButtonDisabled"
                        [color]="submitButtonDisabled? 'warn' : 'primary'"
                        [ngClass]="{'disabled-button': submitButtonDisabled}">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
</form>