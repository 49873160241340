import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowErrorComponent } from '../../project-management/show-error/show-error.component';
import {
  redirectMsgTimeOut,
  responseMsgTimeOut,
} from '../../../../../src/app/providers/properties';
import { HeaderService } from '../../../../app/providers/header.service';

@Component({
  selector: 'app-addassignapprover',
  templateUrl: './addassignapprover.component.html',
  styleUrls: ['./addassignapprover.component.scss'],
})
export class AddassignapproverComponent implements OnInit {
  projectApproverForm: FormGroup;
  success: boolean = false;
  error: any = false;
  error_msg: any;
  sucess_msg: any;
  isButtonDisabled: boolean = true;
  saveButton:boolean = true;
  showError1: boolean = false;
  importerror: boolean = false;
  errormessagejson: any;
  errorMsg: any;
  projectList: any = [];
  ApproverList: any = [];
  empId: any;
  projectreadonly: boolean;
  projectapproverobject: any = {};
  projectapproverexist: boolean;
  ruleNameexist: boolean;
  organzationId: any;
  submitted1 = false;
  eValue: any;
  editData: any;
  updadValteues: any;
  approvertype: any = [];
  showError: Boolean = false;
  projectapproverupdate: any;
  approvertypestoring: any;
  error_Msg: any;
  hideButton: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<AddassignapproverComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs: ProjectManagementService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private zone: NgZone,
    private headerservice: HeaderService
  ) {}

  ngOnInit(): void {
    // this.headerservice.setTitle('Add Assign Approvers');

    console.log('wtts inside the data::', this.data);

    this.route.params.subscribe((params) => {
      this.eValue = params['projectapproverId'];
      this.editData = JSON.parse(params['approver']);
      console.log("Value of 'approver' in add-assaignercompon:", this.eValue);
      console.log('this.editData response::', this.editData);

      this.approvertypestoring = this.editData.approverType.approverTypeName;

      console.log('this.approvertypestoring', this.approvertypestoring);

      // Now you can use 'eValue' as needed in this component
    });

    const Data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId = Data.organization.organizationId;
    console.log('organziationid ' + this.organzationId);

    this.projectApproverForm = this.fb.group({
      // organization: {
      //   organizationId:  this.organzationId
      // },
      project: ['', Validators.required],
      approver: ['', Validators.required],
      approverType: ['', Validators.required],
    });
    console.log('data::', this.data);

    console.log('create project apporver id : ' + this.eValue);

    this.empId = Data.employeeId;

    // if(this.eValue){

    if (this.eValue) {
      console.log('if entered....!');
      this.projectreadonly = true;
      // this.projectApproverForm.patchValue({
      //   approver: this.editData.approvers.approverId,

      //   approverType: this.editData.approvers.approverTypeName,
      //   project: this.editData.projectId,
      //   // approver : this.data.project.projectId
      // });
      console.log(
        'this.editData.approvers.approveid::',
        this.editData.approverId
      );

      if (this.projectApproverForm.invalid) {
        this.projectapproverexist = false;
        this.isButtonDisabled =  false;

      }
    }

    this.gs.getApproverType().subscribe((approvertype: any) => {
      this.approvertype = approvertype;
      console.log('this.approvertype', this.approvertype);


      this.gs.getApprovers().subscribe((d: any[]) => {
        console.log('approver response::', d);

        this.ApproverList = d.sort((a, b) =>
          a.rulename.localeCompare(b.rulename)
        );
        this.gs.getProjectList().subscribe((d: any[]) => {
          console.log('project list::', d);
          console.log('this project list::', this.projectList);
          console.log(this.data.approvers);
          console.log(this.data.projectId);
    
          this.projectList = d.sort((a, b) =>
            a.projectName.localeCompare(b.projectName)
          );
          console.log('this.projectList::', this.projectList);
    
          console.log('if condition checkk::', this.data.projectapproverId);
    
          if (this.eValue) {
            console.log('if entered....!');
            //  this.projectreadonly = true;
            this.projectApproverForm.patchValue({
              approver: this.editData.approvers.approverId,
              // approver:this.editData.
              // approverId,
              approverType: this.editData.approverType.approverTypeId,
              project: this.editData.projectId,
              // approver : this.data.project.projectId
            });
          }
    
       
         
        });
      });
    });
  

    // this.gs.getApprovers().subscribe(
    //   (d: any[]) => {
    //     console.log("approver response::",d);

    //     this.ApproverList = d.sort((a, b) => a.rulename.localeCompare(b.rulename))
    //   }
    // )
    // this.gs.getApproverType().subscribe(
    //   (approvertype:any)=>{
    //     this.approvertype=approvertype;
    //      console.log("this.approvertype",this.approvertype);
    //   }
    // )
  }

  showerrormessage() {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }
  //control values of form values
  get f() {
    return this.projectApproverForm.controls;
  }

  //  resetForms() {
  //   this.projectApproverForm = undefined;
  //   this.showError1=false

  //     // this.timesheetreport.length = 0;

  // }

  Addprojectapprover() {
    // this.showError=false
    this.isButtonDisabled = false;
    this.saveButton = false;
    this.submitted1 = true;
    this.showError = true;
    // this.showError1=false
    if (this.projectApproverForm.valid && this.projectapproverexist == true) {
    }
    if (this.projectApproverForm.invalid) {
      return;
    } //  this.showError=false

    // console.log("form sumbitted");
    // return
    let proappform = this.projectApproverForm.value;

    console.log(proappform);

    if (this.eValue) {
      console.log('this.eValue in side update::', this.eValue);
      console.log('update form ');
      console.log('proappform form values::', proappform);

      this.projectapproverupdate = {
        projectapproverId: this.editData.projectapproverId,
        projectApproverStatus: {
          listTypeValueId: this.editData.projectApproverStatus.listTypeValueId,
        },
        // projectId: this.editData.project.projectid,
        projectId: proappform.project,
        approverType: {
          approverTypeId: proappform.approverType,
        },
        approvers: {
          approverId: proappform.approver,
        },

        // approvers:this.editData.approvers
      };
      this.isButtonDisabled = false;

      console.log('projectapproverupdate::', this.projectapproverupdate);

      console.log('check project exists', this.projectapproverexist);

      if (
        this.projectapproverexist == undefined ||
        this.projectapproverexist == false
      ) {
        this.isButtonDisabled = false;

        // return
        this.gs
          .updateProjectApprover(this.eValue, this.projectapproverupdate)
          .subscribe(
            (a: any) => {
              // Swal.fire({
              //   icon: 'success',
              //   text: "Project Approver updated Successfully"
              // })
              if (a.statusCode == 200) {
                this.success = true;
                this.sucess_msg = a.description;
                this.isButtonDisabled = true;

                setTimeout(() => {
                  this.success = false;
                  this.sucess_msg = a.description;
                  this.zone.run(() => {
                    this.isButtonDisabled = false;

                    this.router.navigate(['/home/assign-approver']);
                  });
                }, 2000);
                // this.router.navigate(['/home/assign-approver']);
              }
            },

            (err) => {
              // Swal.fire({
              //   icon: 'error',
              //   text: err.error.description
              // })
              console.log('code', err);

              this.error = true;
              this.error_Msg = err.error.message;

              console.log('code', err.statusCode);
              setTimeout(() => {
                this.error = false;
              }, 5000);
            }
          );
      }
    } else {
      // return
      if (
        this.projectapproverexist == undefined ||
        this.projectapproverexist == false
      ) {
        this.isButtonDisabled = true;

        var projectapprover = {
          projectId: proappform.project,
          approvers: {
            approverId: proappform.approver,
          },
          projectApproverStatus: {
            listTypeValueId: 1,
            listTypeValueName: 'Active',
          },
          approverType: {
            approverTypeId: proappform.approverType,
          },
        };

        console.log('projectapprover payload::', projectapprover);
        // this.gs.createProjectApprover(projectapprover).subscribe(a => {
        //   Swal.fire({
        //     icon: 'success',
        //     text: "Project Approver Created Successfully"
        //   })
        //   // console.log("craete status code ::",a.statusCode);

        //   this.router.navigate(['/home/assign-approver']);
        //   // window.location.reload()
        //     // this.dialog.closeAll()
        // } ,
        // err =>{
        //   Swal.fire({
        //     icon: 'error',
        //     text: err.error.message
        //   })
        // }
        // )
        // return
        this.gs.createProjectApprover(projectapprover).subscribe(
          (a: any) => {
            console.log('statuscode::', a.statusCode);

            if (a.statusCode == 200) {
              this.success = true;
              this.sucess_msg = a.message;
              this.isButtonDisabled = true;

              setTimeout(() => {
                this.success = false;
                this.sucess_msg = a.message;
                this.zone.run(() => {
                  this.isButtonDisabled = false;

                  this.router.navigate(['/home/assign-approver']);
                });
              }, 2000);
            }
          },
          (err) => {
            // this.error=true

            console.log('code', err);
            console.log('code', err.statusCode);
            setTimeout(() => {
              this.error = false;
              this.errorMsg = err.error.description;
            }, redirectMsgTimeOut);
          }
        );
      }
    }
    this.isButtonDisabled = false;
  }
  clearForm() {
    this.showError = false;
    this.projectapproverexist = false;
  }

  projectapprovervalidate() {
    console.log('project : ' + this.projectApproverForm.value.project);

    if (!this.data.projectApproverId) {
      if (this.projectApproverForm.value.project != 0) {
        this.gs
          .getprojectApprovers(this.projectApproverForm.value.project)
          .subscribe((a) => {
            console.log(a);
            this.projectapproverobject = a;
            if (this.projectapproverobject.isexist) {
              console.log('role name isexist');
              this.projectapproverexist = true;
              this.isButtonDisabled = true;
            } else {
              this.isButtonDisabled = false;
              this.projectapproverexist = false;
            }
          });
      } else {
        this.isButtonDisabled = false;
        this.projectapproverexist = false;
      }
    }
  }
}
