Sub header start here
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Shift Calendar</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row mb-4">
                    <div class="col-md-12">
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row pl-0">
                                <div class="col-md-3 form-group">               
                                    <label class="col-form-label">Client<span class="star">*</span></label>                           
                                    <select class="form-select form-select-solid form-select-lg"
                                        appearance="fill" formControlName="clientserach"                          
                                        placeholder="- Select Client -">
                                        <option  class="text-color"
                                        [value]="null">- Select Client -</option>
                                        <option *ngFor="let e of clientList" class="text-color"
                                            [value]="e.clientId">{{e.clientName}}</option>
                                    </select>
                                    <!-- (selectionChange)="listTypeValueid($event)" -->
                                </div>
                                <div class="col-md-3 form-group" style="height:80px">
                                    <label class="col-form-label">Start Date</label>         
                                    <input formControlName="startDateSearch" id="startDateSearch" matInput [matDatepicker]="startDateSearch" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                        placeholder="Select Start Date">
                                    <mat-datepicker-toggle matSuffix [for]="startDateSearch" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                    <mat-datepicker #startDateSearch   
                                    
                                    panelClass="example-month-picker"></mat-datepicker>
                                
    
                                    <div class="valid-msg" *ngIf="(serachvalidate.startDateSearch.invalid && serachvalidate.startDateSearch.touched) || serachvalidate.startDateSearch.dirty">
                                        <span class="text-danger" *ngIf="serachvalidate.startDateSearch.errors?.required">Please Start Date</span>
                                    </div>
                                </div>
                                <div class="col-md-3 form-group" style="height:80px">       
                                    <label class="col-form-label">End Date</label>                                            
                                    <input formControlName="endDateSearch" id="endDateSearch"  matInput [matDatepicker]="endDateSearch" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input"
                                        [min]="searchForm.value.startDateSearch" placeholder="Select End Date">
                                    <mat-datepicker-toggle matSuffix [for]="endDateSearch" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                    <mat-datepicker #endDateSearch></mat-datepicker>
    
                                    <div class="valid-msg" *ngIf="(serachvalidate.endDateSearch.invalid && serachvalidate.endDateSearch.touched) || serachvalidate.endDateSearch.dirty">
                                        <span class="text-danger" *ngIf="serachvalidate.endDateSearch.errors?.required">Please select End Date</span>
                                    </div>
                                </div>
                                <div class="col-md-3" style="margin-top: 34px;" >
                                    <button type="submit" mat-raised-button color="primary"
                                        class="btn btn-primary search-btn">Search</button>
                                    <button style="margin-left: 20px;" type="reset" mat-raised-button
                                        class="btn mr-2 search-btn"
                                        (click)="clearTable()">Clear</button>
                                        <!-- (click)="clearTable()" -->
                                </div>
                            </div>
                        </form>
                    <!--  <div class="col-md-3 pl-0">
                        <button class="search-btn" mat-raised-button color="primary">Search</button>
                    </div> -->
                </div>
                   
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left pl-0">
                                <select [(ngModel)]="pagePerItem"  (change)="pageChages()" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <!-- <option value="1500">1500</option> -->
                                </select>
                            </div>
                        </div>
                    </div>
                <div class="card-body">
                    <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Client<span class="star">*</span></label>
                                <select formControlName="client" placeholder="- Select client -" class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select client --- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option>
                                </select>
                                <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                    <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift Calendar Name<span class="star">*</span></label>
                                <input type="text" formControlName="calendarName" id="calendarName" (keyup)="validatecalendarname($event)" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder=" Shift Calendar Name" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.calendarName.invalid && validate.calendarName.touched) || validate.calendarName.dirty || submitted">
                                    <span *ngIf="validate.calendarName.errors?.required" class="text-danger">Please enter calendar name</span>
                                   
                                </div>
                                <span *ngIf="calendarnameexist" class="text-danger">{{existMsg}}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift Calendar Description<span class="star">*</span></label>
                                <input type="text" formControlName="calendarDescription" id="calendarDescription"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder=" Shift Calendar Description" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.calendarDescription.invalid && validate.calendarDescription.touched) || validate.calendarDescription.dirty || submitted">
                                    <span *ngIf="validate.calendarDescription.errors?.required" class="text-danger">Please Enter Description</span>                                  
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift Calendar StartDate <span class="star">*</span></label>
                                <input formControlName="startDate" id="startDate" readonly  matInput [matDatepicker]="startDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                    [max]="" placeholder="Select Shift Calendar StartDate"  (dateChange)="validatedate($event)">
                                <mat-datepicker-toggle matSuffix [for]="startDate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                <mat-datepicker #startDate   
                                panelClass="example-month-picker"></mat-datepicker>

                                <div class="valid-msg" *ngIf="(validate.startDate.invalid && validate.startDate.touched) || validate.startDate.dirty || submitted">
                                    <span class="text-danger" *ngIf="validate.startDate.errors?.required">Please select start date</span>
                                </div>
                            </div>                       
                        </div> 
                        <div class="row">                          
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift Calendar EndDate<span class="star">*</span></label>
                                <input formControlName="endDate" id="endDate" readonly  matInput [matDatepicker]="endDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input"
                                     [min]="postForm.value.startDate" placeholder="Select Shift Calendar EndDate"  (dateChange)="validatedate($event)">
                                <mat-datepicker-toggle matSuffix [for]="endDate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>

                                <div class="valid-msg" *ngIf="(validate.endDate.invalid && validate.endDate.touched) || validate.endDate.dirty || submitted">
                                    <span class="text-danger" *ngIf="validate.endDate.errors?.required">Please select end date</span>
                                </div>
                                <span *ngIf="dateexist" class="text-danger">{{dateexistMsg}}</span>
                            </div>
                            <div class="form-group col-md-2">
                                <label class="col-form-label">Work Day Start<span class="star">*</span></label>
                                <select formControlName="workDayStart" placeholder="- Select -" class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select--- </option>
                                    <option *ngFor="let row of days; let i=index;" value="{{row.number}}">{{row.name}}</option>
                                </select>
                                <div class="valid-msg" *ngIf="(validate.workDayStart.invalid && validate.workDayStart.touched) || validate.workDayStart.dirty || submitted">
                                    <span *ngIf="validate.workDayStart.errors?.required" class="text-danger">Please select work day start</span>
                                </div>
                            </div>
                           
                            <div class="form-group col-md-2">
                                <label class="col-form-label">working Days<span class="star">*</span></label>
                                <input type="text" formControlName="workingDays" id="workingDays" maxlength="1"  (keypress)="onlyNumber($event)"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder="Working Days" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.workingDays.invalid && validate.workingDays.touched) || validate.workingDays.dirty || submitted">
                                    <span *ngIf="validate.workingDays.errors?.required" class="text-danger">Please enter working days</span>                                  
                                </div>
                            </div>                          
                            <div class="form-group col-md-2">
                                <label class="col-form-label">Leave Days<span class="star">*</span></label>
                                <input type="text" formControlName="leaveDays" maxlength="1" (keypress)="onlyNumber($event)"  id="leaveDays"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder="Leave Days" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.leaveDays.invalid && validate.leaveDays.touched) || validate.leaveDays.dirty || submitted">
                                    <span *ngIf="validate.leaveDays.errors?.required" class="text-danger">Please enter leave days</span>
                                   
                                </div>
                              
                            </div>
                            <div class="form-group col-md-2">
                                <label class="col-form-label" style="padding-right: 10px;">Default</label>
                                <input type="checkbox" formControlName="default" id="checkbox" style="margin-top: 53px;">
                               
                                <div class="valid-msg" *ngIf="(validate.default.invalid && validate.default.touched) || validate.default.dirty || submitted">
                                    <!-- <span *ngIf="validate.default.errors?.required" class="text-danger">Please enter leave days</span> -->
                                   
                                </div>
                              
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="reset" class="submit action-button btn mr-2 btn-sm"  mat-raised-button  style="margin-right: 20px;"
                                >Clear</button>                              
                                <button type="submit" mat-raised-button color="primary">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
                 <div class="table-responsive" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                    <p><b>MANAGE SHIFT CALENDAR</b></p>
                    <table class="table  --table-bordered table-border-new"  style="width: 110%; border-top-left-radius: 10px;border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center">Action</th>
                                <th>Client</th>
                                <th>Calendar Name</th>
                                <th>Calendar Description</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Workday Start</th>
                                <th class="text-center">Working Days</th>
                                <th class="text-center">Leave Days</th>
                                <th class="text-center">Default</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <!-- <td class="text-center">{{i+1}}</td> -->
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="updateform(row)"  >
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                        <button *ngIf="!row.shiftCalendadefault && row.shiftCalendarStatus.listTypeValueId == 1" mat-menu-item (click)="updatedeafult(row)"  >
                                            <mat-icon>toggle_on</mat-icon>
                                            <span>Mark as Default</span>
                                        </button>
                                        <button  mat-menu-item (click)="getcalendarview(row)"  >
                                            <mat-icon>event</mat-icon>
                                            <span>View Holiday/Exceptions</span>
                                        </button>
                                        <button mat-menu-item *ngIf="row.shiftCalendarStatus.listTypeValueId == 1" (click)="updateStatus(row.shiftCalendarId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>In-Active</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.shiftCalendarStatus.listTypeValueId == 2" (click)="updateStatus(row.shiftCalendarId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>
                                    </mat-menu>
                                </td>
    
                                <td>{{row.client.clientName}}</td>
                                <td>{{row.shiftCalendarName}}</td>
                                <td>{{row.shiftCalendarDescription}}</td>
                                <td>{{row.shiftCalendarStartDate | date :dateFormat}}</td>
                                <td>{{row.shiftCalendarEndDate | date :dateFormat }}</td>
                                <td>{{getDayName(row.shiftCalendarWorkdayStart)}}</td>
                                <td class="text-center" >{{row.shiftCalendarWorkingdays}}</td>
                                <td class="text-center" >{{row.shiftCalendarLeavedays}}</td>
                                <td>{{row.shiftCalendadefault ? 'Yes' : 'No'}}</td>
                                <!-- capitalizeWordsName -->
                                <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.shiftCalendarStatus.listTypeValueId == 1">
                                        {{row.shiftCalendarStatus.listTypeValueName}}
                                    </span>
    
                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.shiftCalendarStatus.listTypeValueId == 2">
                                        {{row.shiftCalendarStatus.listTypeValueName}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No matching records found!</td>
                            </tr>
                        </tbody>
                    </table>
                 </div>
             

               
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="showing-record">
                        <!-- <ng-template pTemplate="paginatorleft" let-state>
                            Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                        </ng-template> -->
                        {{resultData.length}} rows
                    </div>
                </div>
                <div class="col-md-8 text-right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>