
<mat-card class="matcardDesign">

<!-- <div class="container"> -->
    <div class="resource-title1"  >
         <h2>{{paymentId ? 'Update' : 'Create'}} Payment Advice</h2>
    </div>  
    <div class="card resource-card" style="border: none;">
        <form [formGroup]="paymentadviceform" (ngSubmit)="submitData()">
            <div class="row">    
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Name<span class="text-danger">*</span></label>
                    <input type="text"                   

                    readonly class="form-control email-input select-placeholder no-border-on-focus " formControlName="name" 
                    placeholder="Enter Name" >
                    <div *ngIf="(name.invalid && name.touched) || name.dirty">
                        <small *ngIf="name.errors?.required" class="text-danger">Please Enter Employee Name</small> 
                        <!-- <small *ngIf="name.errors?.pattern" class="text-danger">Please enter valid EmployeeName</small>     -->
                    </div> 
                    <!-- <div *ngIf=" name.touched || name.dirty||(submmited && paymentadviceform.controls['name'].invalid)">
                        <small *ngIf="name.errors?.required" class="text-danger">Please select Employee name</small>
                    </div> -->
                </div>                                                   
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Advice No<span class="text-danger"> *</span></label>
                    <input type="text"
                    (cut)="disableAction($event)"
                    (copy)="disableAction($event)"
                    (paste)="disableAction($event)"
                    (keypress)="restrictInput($event)"

                     class="form-control email-input select-placeholder no-border-on-focus" formControlName="adviceNo"
                     placeholder="Enter Advice No">
                    <div *ngIf="(adviceNo.invalid && adviceNo.touched) || adviceNo.dirty">
                      <small *ngIf="adviceNo.errors?.required" class="text-danger">Please Enter Advice No</small> 
                        <small *ngIf="adviceNo.errors?.pattern" class="text-danger">Please Enter Valid Advice No</small>   
                    </div> 
                </div>
            </div>  
            <div class="row">  
                <div class="col-md-6" id="loginform-date" style="margin-top: 10px;">
                    <label class="form-control-label">Advice Date<span class="text-danger"> *</span></label>
                    <!-- <input type="date" class="form-control email-input select-placeholder no-border-on-focus" max="today" formControlName="dateOfBirth" value="2022-02-15"> -->
                    <input matInput [matDatepicker]="picker1" [min]="formatteddate" [max]="today" formControlName="adviceDate" 
                    readonly
                    class="form-control email-input select-placeholder no-border-on-focus borderStyle"  placeholder="Choose Advice Date">
                    <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <div *ngIf="(adviceDate.invalid && adviceDate.touched) || adviceDate.dirty">
                        <small *ngIf="adviceDate.errors?.required" class="text-danger">Please Choose Advice Date</small>
                        <!-- <small *ngIf="dob.errors?.pattern" class="text-danger">Please enter valid interview date</small>    -->
                     </div>
                </div>
                <div class="col-md-6" id="loginform-date" style="margin-top: 10px;">
                    <label class="form-control-label">Pay Period<span class="text-danger"> *</span></label>
                    <!-- <input type="date" class="form-control email-input select-placeholder no-border-on-focus" max="today" formControlName="dateOfBirth" value="2022-02-15"> -->
                    <input matInput [matDatepicker]="picker2"  [min]="this.paymentData?.paymentAdvice?.payPeriodStartDate" [max]="today" formControlName="paymentDate" readonly
                    class="form-control email-input select-placeholder no-border-on-focus borderStyle"  placeholder="Choose Pay Period">
                    <mat-datepicker-toggle matSuffix [for]="picker2" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <div *ngIf="(paymentDate.invalid && paymentDate.touched) || paymentDate.dirty"> 
                         <small *ngIf="paymentDate.errors?.required" class="text-danger">Please Choose Payment Date</small>
                        <!-- <small *ngIf="dob.errors?.pattern" class="text-danger">Please enter valid interview date</small>    -->
                    </div>
                </div>
            </div>
            <div class="row">   
                <div class="col-md-6" id="loginform" style="    margin-top: -17px;">
                    <label class="form-control-label">Payment Amount<span class="text-danger"> *</span></label>
                    <input type="text"  
                    readonly class="form-control email-input select-placeholder no-border-on-focus" maxlength="10" formControlName="paymentamount" 
                    placeholder="Enter Payment Amount">
                    <!-- <div *ngIf="(paymentamount.invalid && psaymentamount.touched) || mobileNumber.dirty">
                        <small *ngIf="paymentamount.errors?.required" class="text-danger">Please enter payment amount</small>
                        <small *ngIf="paymentamount.errors?.pattern" class="text-danger">Please enter valid phone number</small>   
                    </div>  -->
                </div>                                                  
                <div class="col-md-6" id="loginform" style="    margin-top: -20px;">
                    <label class="form-control-label">Payment Mode<span class="text-danger"> *</span></label>
                    <mat-select 
                    class="form-control email-input select-placeholder no-border-on-focus" 
                    appearance="fill" formControlName="paymentMode" placeholder="Select Payment Mode">
                        <mat-option *ngFor="let e of paymentModeType" class="text-color" [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
                        <!-- <mat-option class="text-color" [value]="1">Check</mat-option> -->
                    </mat-select>
                    <div *ngIf="(paymentMode.invalid && paymentMode.touched) || paymentMode.dirty">
                        <small *ngIf="paymentMode.errors?.required" class="text-danger">Please Select Payment Mode</small>
                    </div> 
                </div>  
            </div>
            <div class="row">  
                <div class="col-md-6" id="loginform-date" style="margin-top: 10px;">
                    <label class="form-control-label">Pay Period Begin Date<span class="text-danger"> *</span></label>
                    <!-- <input type="date" class="form-control email-input select-placeholder no-border-on-focus" max="today" formControlName="dateOfBirth" value="2022-02-15"> -->
                    <input matInput 
                    [matDatepicker]="picker3"  max="today"  formControlName="payPeriodBeginDate" 
                        class="form-control email-input select-placeholder no-border-on-focus" readonly="readonly" placeholder="Choose Pay Period Begin Date">
                        <mat-datepicker-toggle matSuffix  style="float: right;position: relative;bottom: 42px;" 
                             [disabled]="paymentadviceform.controls['payPeriodBeginDate'].disabled">
                        </mat-datepicker-toggle>
                    <!-- <mat-datepicker-toggle matSuffix [for]="picker3" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle> -->
                            <mat-datepicker #picker3></mat-datepicker>
                    <!-- <div *ngIf="(payPeriodBeginDate.invalid && payPeriodBeginDate.touched) || payPeriodBeginDate.dirty">
                        <small *ngIf="payPeriodBeginDate.errors?.required" class="text-danger">Please choose payperiodbegindate</small>
                         <small *ngIf="dob.errors?.pattern" class="text-danger">Please enter valid interview date</small> 
                    </div>  -->
                </div>
                <div class="col-md-6" id="loginform-date" style="margin-top: 10px;">
                    <label class="form-control-label">Pay Period End Date<span class="text-danger"> *</span></label>
                    <!-- <input type="date" class="form-control email-input select-placeholder no-border-on-focus" max="today" formControlName="dateOfBirth" value="2022-02-15"> -->
                    <input matInput readonly [matDatepicker]="picker4" max="today"  formControlName="payPeriodEndDate" 
                    class="form-control email-input select-placeholder no-border-on-focus" readonly="readonly" placeholder="Choose Pay Period End Date">
                    <!-- <mat-datepicker-toggle matSuffix [for]="picker4" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle> -->
                    <mat-datepicker-toggle matSuffix   style="float: right;position: relative;bottom: 42px;" 
                    [disabled]="paymentadviceform.controls['payPeriodEndDate'].disabled">
               </mat-datepicker-toggle>

                    <mat-datepicker #picker4></mat-datepicker>
                    <!-- <div *ngIf="(payPeriodEndDate.invalid && payPeriodEndDate.touched) || payPeriodEndDate.dirty"> -->
                        <!-- <small *ngIf="payPeriodEndDate.errors?.required" class="text-danger">Please choose payperiodenddate</small> -->
                        <!-- <small *ngIf="dob.errors?.pattern" class="text-danger">Please enter valid interview date</small>    -->
                    <!-- </div>  -->
                </div>
            </div> 
            <div class="row">  
               <div class="col-md-6" id="loginform" style="width: 57%;margin-top: -17px;">
                    <label class="form-control-label">Description</label>
                    <textarea type="text" class="form-control email-input select-placeholder no-border-on-focus" formControlName="description"
                     placeholder="Description" style="    font-size: 15px;
                    max-width: 87%;
                    overflow-x: hidden;
                    overflow-y: auto;
                    min-height: 38px;
                    border: none;
              
                   border: 2px solid var(--Card-Border-Color,#dee2e6);"></textarea>
                    <!-- <div *ngIf="(description.invalid && description.touched) || description.dirty">
                        <small *ngIf="description.errors?.required" class="text-danger">Please enter category description</small>
                    </div>  -->
                </div> 
            </div>
            <div class="row" style="float: right;">   
                <div class="col-md-12">
                    <div class="buttons">
                        <button type="cancel"   style="color:var(--Red-Color, #FF5D5D); margin-right: 10px;background:none"
                        class=" cancel-button"(click)="formcancel()">Cancel</button>
                        <!-- <Button type="submit" mat-raised-button [disabled]="paymentadviceform.invalid || btnDisable" class=" createbtn">{{paymentId ? 'Update' : 'Create'}}</Button> -->
                        <!-- <Button type="submit" class="btn create-button">{{usersId ? 'Update' : 'Create'}}</Button> -->
                        <button
                        mat-raised-button
                        class="searchBtn"
                        type="submit"
                        [disabled]="paymentadviceform.invalid || btnDisable"
                      >
                      {{paymentId ? 'Update' : 'Create'}}
                      </button>
                    </div>
                </div>  
            </div>
        </form>  
    </div>  
<!-- </div> -->
</mat-card>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;margin-top: -150px;" role="status"></div>
</div>
<div>
<div class="text-center" *ngIf="success" style="width: 350px;
margin-top: -500px;
position: relative;
left: 902px;
/* float: none; */"> 
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
<div  class="alert alert-danger alert-dismissible" *ngIf="error" style="width: 350px;
margin-top: -500px;
position: relative;
left: 902px;">
    <strong>Error!</strong><br>{{update_data_error_msg}}
</div>
</div>


