import { ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { RecruitmentService } from '../../providers/recruitment.service';
import { Router , ActivatedRoute } from '@angular/router';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-position-detail-new',
  templateUrl: './position-detail-new.component.html',
  styleUrls: ['./position-detail-new.component.scss']
})
export class PositionDetailNewComponent implements OnInit {

  loader:any= true;
  positionobj : any = {};
  positionId:any;
  clientListData:any = [];
  usertData: any = '';
  clientName:any;
  clientName1: any;
  combinedClients: any;
  userType: any;

  constructor(private positionservice :RecruitmentService,
  private activatedRoute : ActivatedRoute,
  private cdr: ChangeDetectorRef,
  private headerservice: HeaderService,

  // @Inject(MAT_DIALOG_DATA) public data: MatDialog
  )
  { }

  ngOnInit(): void 
  {
    this.headerservice.setTitle('Positions ')


    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));

    console.log("this.userData::",this.usertData);

    this.userType = [];
    this.usertData.employee.role.forEach(role => {
        this.userType.push(role?.roleName);
    });
    
    console.log("rolename",this.userType);
    
    let userId = this.usertData.userId;
    let organizationId = this.usertData.organization.organizationId;
    this.positionservice.getclientlist(organizationId).subscribe((resultData:any) =>
        {console.log(' resultData', resultData)
             this.clientListData = resultData;
             this.loader=false
         });
    this.positionId = this.activatedRoute.snapshot.params.positionId;
    
    this.positionservice.positionbyid(this.activatedRoute.snapshot.params.positionId).subscribe((data:any) =>
    {
      this.positionobj = data;

        const client = this.clientListData.find(aItem => aItem.clientId === this.positionobj.client);
        console.log('client',client)
        this.clientName1 = client.clientName;
        console.log('this.clientName1',this.clientName1)
            this.positionobj.clientName = this.clientName1;
            console.log('this.positionobj',this.positionobj)
      this.loader = false;

      this.cdr.detectChanges();
    })
  }


}
