import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from '../providers/settings.service';
import { SuperadminService } from '../providers/superadmin.service';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ExcelService} from '../../../src/app/services/excel.service';
import { AdminService } from '../providers/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewclientComponent } from '../viewclient/viewclient.component';

@Component({
  selector: 'app-organizationclientlisting',
  templateUrl: './organizationclientlisting.component.html',
  styleUrls: ['./organizationclientlisting.component.scss']
})
export class OrganizationclientlistingComponent implements OnInit {
    searchFormOrg
Org:FormGroup;
select_by_org: boolean=false;
industryTypeLov: any;
  tableShow: boolean=false;
mySelect: number=5;
resultData: any;
  p: number;
select_by_Client: any;
clientFormgroup:FormGroup;
loader:boolean=false;
success:any;
error_msg:any;
error:any;
nodatafound: any;
pagePerItem: any=5;
tableSearch: any;
pageViews: any=1;
  sortedData: any;
  sucess_msg: any;
  backdrop: boolean;
search: any;
  taskList: any[];
  selectedType: string = '';
  exported: any;
  industryType: any;
  dataExport=[];
  exportingdata: any;
  clientList: any=[];
  beforesearchpage:any;
btnDisabled: any;
submitted: boolean=false;
  showValidation: boolean=false;
  storeEntityId: any;
  constructor(
    private SettingsService:SettingsService,
    private formBuilder:FormBuilder,
    private SuperadminService:SuperadminService,
    private router:Router,
    public ExcelService: ExcelService,
    private adminservice:AdminService,
    public dialog: MatDialog,


  ) { }

  ngOnInit(): void {

    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);

    this.clientFormgroup = this.formBuilder.group({
      clientKeywords:  ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      businessUnitnames: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
    })



    // this.getIndustryTypeLov(7);
    this.getFilterFormData();
    this.getClient();
    // this.getAllClientList()
  }
  get clientKeywordset() {
    return this.clientFormgroup.get('clientKeywords');
  }
  get businessUnitname(){
    return this.clientFormgroup.get('businessUnitnames');
  }
//   resetTable(event:any){
//     console.log(event);
    
//     this.nodatafound=false;
//     const inputValue=event.target.value;
//     console.log("inputValue:",inputValue);
//     // this.tableShow=false;
//     console.log((inputValue.length==1 && (this.clientList.length==0||this.clientList.length>0)));
//     this.tableShow=true;


//     if(inputValue.length==0 && (this.clientList.length==0||this.clientList.length>0)){
// // this.loader=true;
// this.tableShow=false;
//     this.SuperadminService.getAllClientlist(1).subscribe((response:any)=>{
//       this.clientList=response;
//       this.tableShow=true;
//       this.loader=false;
//       console.log("client list::",this.clientList);
      
//     })
//   }
//   }
  getAllClientList(){
    // this.loader=true;
    this.tableShow=false
    this.SuperadminService.getAllClientlist(this.storeEntityId).subscribe((response:any)=>{
      this.clientList=response;
      this.tableShow=true;
      this.loader=false;
      console.log("client list::",this.clientList);
      this.nodatafound=false;
      if (this.clientList.statusCode==204){
        console.log("no data entered!!");
        
        this.nodatafound=true;
        this.clientList=[];
        this.tableShow=true;
      }
      
    })
  }

  handleBackspaceset(event:any){
    console.log("Key pressed:", event.key);

    // Get the current value in the input field
    const inputValue = event.target.value;
    this.tableShow=false
    console.log("Current input value:", inputValue);
    console.log("Current input value:", inputValue.length);
  
    // if ( inputValue.length == 1 && this.clientList.length  == 0) {
    //   this.clientList = [];
    //   this.loader = true;
    //   this.tableShow = false;
    //   this.showValidation = false;
    //   this.SuperadminService.getAllClientlist(this.storeEntityId).subscribe((response:any)=>{
    //     this.clientList=response;
    //     this.tableShow=true;
    //     this.loader=false;
    //     console.log("client list::",this.clientList);
    //     this.nodatafound=false;
    //     if (this.clientList.statusCode==204){
    //       console.log("no data entered!!");
          
    //       this.nodatafound=true;
    //       this.clientList=[];
    //       this.tableShow=true;
    //     }
        
    //   })
    // }
    // Your logic here (like calling a service or performing other actions)
    this.nodatafound = false;
  }
  handleBackspace(event:any) {

    // Get the key that was pressed
  console.log("Key pressed:", event.key);

  // Get the current value in the input field
        this.nodatafound=false;
        this.tableShow=false

  const inputValue = event.target.value;
  console.log("Current input value:", inputValue);
  console.log("Current input value:", inputValue.length);
  // if(inputValue==1 && (this.clientList.length==0 || this.clientList.length>=0)){
  //   // this.clientList = [];
  //   // this.loader = true;
  //   // this.tableShow = false;
  //   // this.showValidation = false;
  //   this.SuperadminService.getAllClientlist(this.storeEntityId).subscribe((response:any)=>{
  //     this.clientList=response;
  //     this.tableShow=true;
  //     this.loader=false;
  //     console.log("client list::",this.clientList);
  //     // this.nodatafound=false;
     
      
  //   })
  // }
  this.nodatafound = false;

    
  }
  getFilterFormData()
  {
    this.searchFormOrg = this.formBuilder.group({
      keywords: [''],
      industryType: [''],
      businessUnitname:['']
    });
  }
  getClient(){
    this.clientFormgroup=this.formBuilder.group({
      clientKeywords: [''],
      supplierName: [''],
      businessUnitnames:['']
      
    })
  }
  isObject(row: any):boolean {
    
    return row && row.client && typeof row.client === 'object';


  }
  openDialog(rowData: any): void {
    console.log("rowData:",rowData);
    
    this.dialog.open(ViewclientComponent, {
      width: '400px', 
      data: rowData   
    });
  }
  changefirstpage() {
    this.p = 1;
    }

    ucFirst(string) 
    {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
   
   
    updateStatusClient(id,status)
    {
      this.SuperadminService.updateClientStatus(id,status).subscribe((result:any) =>
      {
        console.log("result:",result);
        
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          // this.getAllClientList();
          this.clientSearch()
          // const updatedRow = this.clientList?.find((row) => row.clientId === id);
          // if (updatedRow) {
          //   updatedRow.status.listTypeValueName = status; // Update the status in the current data
          // }
          setTimeout(() => {this.success  = false;},5000)  
        }
        else
        {
          console.log("error came");
          
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, 5000)
        }
      })
    }
  clearData(){
    this.searchFormOrg.reset();
    this.nodatafound=false;
    this.showValidation=false;
    this.tableShow=false;
    // this.tableShow=false;
    this.loader=false;

    // this.SuperadminService.getAllClientlist(this.storeEntityId).subscribe((response:any)=>{
    //   this.clientList=response;
    //   this.tableShow=true;
    //   this.loader=false;
    //   console.log("client list::",this.clientList);
      
    // })  
  }
 
  selected(event: any) {
    this.selectedType = event.value; 

    
  }
  clearAllForm(){
    this.selectedType = ''; 
  

  }
  Add_org(){
    this.router.navigateByUrl("/home/add-organization");

  }
  Add_clients(){
    this.router.navigateByUrl("/home/add-client");

  }
  clientSearch(){

    var keywords = this.clientFormgroup.controls['clientKeywords'].value.trim();
    var businessname = this.clientFormgroup.controls['businessUnitnames'].value.trim();
    
    console.log("client:", keywords);
    console.log("business name:", businessname);
    
    if ((!keywords && !businessname) || (businessname && businessname.trim() === '')) {
      this.loader = false;
      alert("Please Type Keywords in Client Name field to search!");
      this.btnDisabled = false;
      return;
    }
    
    if (!keywords || keywords === '') {
      this.loader = false;
      alert("Please Type Keywords along with Client Name field to Search!");
      this.btnDisabled = false;
      return;
    }

   
      
    if ((keywords && keywords.trim() !== '') && (!businessname || businessname.trim() === '')) {
      console.log("client only !!");
    
      this.btnDisabled = true;
      this.loader = true;
      this.tableShow = false;
    
      this.SuperadminService.getClientDataonly(keywords,this.storeEntityId).subscribe((response: any) => {
        console.log(response);
        this.btnDisabled = false;
        this.loader = false;
    
        if (response && response.length > 0) {
          this.tableShow = true;
          console.log("Search result:", response);
          this.clientList = response;
          this.nodatafound = false;
        } else {
          console.log("else came!!!");
          if (response.statusCode == 209) {
            console.log("no data!!");
            this.nodatafound = true;
            this.tableShow = true;
            this.clientList = [];
          }
        }
      });
    }
    
    // else if((keywords==''||keywords==null)&&(businessname!==''||businessname!==null)){ 
    
    // console.log("business unit only!!");
    // this.btnDisabled=true;
    // this.loader=true;
    // this.tableShow = false;


    // this.SuperadminService.getBusinessUnitOnlyy(businessname,1).subscribe((response:any)=>{
    //   console.log(response);
    //   this.btnDisabled=false;
    //   this.loader=false;


    //   if (response && response.length > 0) {
    //     this.tableShow = true;
    //     console.log("Search result:", response);
    //     this.clientList = response;  
    //     this.btnDisabled=false;
    //     this.loader=false;


    //     console.log(" this.clientList::", this.clientList);
    //     this.btnDisabled=false

    //     this.nodatafound = false;

        
    //   } 
    //   else {
    //     console.log("else came!!!");
    //     if(response.statusCode==204){
    //       console.log("no data!!");
    //       this.nodatafound=true;
    //       this.tableShow=true;
    //       this.loader=false;

    //       this.clientList=[];
    //     }
     
    //   }
    // })

    // }
    
     else if((keywords!||keywords!=='')&&(businessname!==''||!businessname)){
        console.log("both calling!");
        this.btnDisabled=true;
        this.loader=true;
        this.tableShow = false;

        this.SuperadminService.getBusinessUnitClientOnlyy(keywords,businessname,this.storeEntityId).subscribe((response:any)=>{
          console.log(response);
            
          if (response && response.length > 0) {
            this.tableShow = true;
            this.loader=false;

            console.log("Search result:", response);
            this.clientList = response; 
            this.btnDisabled=false
 
            console.log(" this.clientList::", this.clientList);
            this.btnDisabled=false

    
            this.nodatafound = false;
    
            
          } 
          else {
            console.log("else came!!!");
            if(response.statusCode==204){
              console.log("no data!!");
              this.nodatafound=true;
              this.tableShow=true;
              this.loader=false;
              this.btnDisabled=false

              this.clientList=[];
            }
         
          }
        })
      
  
  }
    

  }
  
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  
}

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}