<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">{{label | titlecase}}</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/type-list" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
                <div class="row mb-2">
                    <div class="col-sm-9">
                            <div class="container">
                                <form action="" [formGroup]="leaveTypeForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3 class="title">*{{label | titlecase}}*</h3>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="input-width" id="loginform">
                                            <label class="col-form-label">Leave Name<span class="star"> *</span></label>
                                            <input type="text" placeholder="Enter leave Name!!" formControlName="leaveName"
                                                [ngClass]="{ 'is-invalid': submitted && f.leaveName.errors }"
                                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                            <!-- <mat-error *ngIf="f.leaveName.hasError('required') && (f.leaveName.touched || f.leaveName.dirty)">
                                                Please Enter <strong>Leave Name</strong>
                                            </mat-error> -->
                                            <div *ngIf="(f.leaveName.invalid && submitted) || f.leaveName.dirty">
                                                <mat-error *ngIf="f.leaveName.errors?.required"> Please select Leave Name</mat-error>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="id">
                                        <div class="input-width" id="loginform">
                                            <label class="col-form-label">Leave Status<span class="star"> *</span></label>
                                            <mat-select formControlName="status" class="form-select form-select-solid form-select-lg select2-new">
                                                <mat-option value="1">Active</mat-option>
                                                <mat-option value="2">InActive</mat-option>
                                            </mat-select>
                                            <!-- <mat-error *ngIf="f.status.hasError('required') && (f.status.touched || f.status.dirty)">
                                                Please select <strong>Leave Status</strong>
                                            </mat-error> -->
                                            <div *ngIf="(f.status.invalid && submitted) || f.status.dirty">
                                                <mat-error *ngIf="f.status.errors?.required"> Please select Leave Status</mat-error>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="form-group ">
                                        <div class="input-width" id="loginform">
                                            <label class="col-form-label">Select number of days<span class="star"> *</span></label>
                                            <mat-select style="color: red" placeholder="Enter Number Of Days" formControlName="noOfDays"
                                            class="form-select form-select-solid form-select-lg select2-new">
                                                <mat-option value="1">1</mat-option>
                                                <mat-option value="2">2</mat-option>
                                                <mat-option value="3">3</mat-option>
                                                <mat-option value="4">4</mat-option>
                                                <mat-option value="5">5</mat-option>
                                                <mat-option value="6">6</mat-option>
                                                <mat-option value="7">7</mat-option>
                                                <mat-option value="8">8</mat-option>
                                                <mat-option value="9">9</mat-option>
                                                <mat-option value="10">10</mat-option>
                                                <mat-option value="11">11</mat-option>
                                                <mat-option value="12">12</mat-option>
                                                <mat-option value="13">13</mat-option>
                                                <mat-option value="14">14</mat-option>
                                                <mat-option value="15">15</mat-option>
                                                <mat-option value="16">16</mat-option>
                                                <mat-option value="17">17</mat-option>
                                                <mat-option value="18">18</mat-option>
                                                <mat-option value="19">19</mat-option>
                                                <mat-option value="20">20</mat-option>
                                                <mat-option value="21">21</mat-option>
                                            </mat-select>
                                            <!-- <mat-error *ngIf="f.noOfDays.hasError('required') && (f.noOfDays.touched || f.noOfDays.dirty)">
                                                Please Select <strong>Number Of Days</strong>
                                            </mat-error> -->
                                            <div *ngIf="(f.noOfDays.invalid && submitted) || f.noOfDays.dirty">
                                                <mat-error *ngIf="f.noOfDays.errors?.required"> Please select Number Of Days</mat-error>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="d-flex">
                                            <div style="width: 45%;margin: 10px auto;">
                                                <button mat-stroked-button class="cancel-button" type="button"
                                                [routerLink]="['/home/type-list']">Cancel</button>
                                            </div>
                                            <div style="width: 45%;margin: 10px auto;">
                                                <button mat-stroked-button class="apply-button" color="primary" type="submit">
                                                &nbsp;{{button_label | titlecase}}&nbsp;
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
      
  
