import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ExcelService } from 'src/app/services/excel.service';
import { ExportExcelService} from 'src/app/services/export-excel.service';
import { Subject, Observable, EMPTY } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake  from "html-to-pdfmake";
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { HttpClient } from '@angular/common/http';
import { EmployeeService } from 'src/app/providers/employee.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-hdfcbankreport',
  templateUrl: './hdfcbankreport.component.html',
  styleUrls: ['./hdfcbankreport.component.scss']
})
export class HdfcbankreportComponent implements OnInit {

  isProcessing = false;


  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  userData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  totalamountbeforededuction: number= 0;
  totalnetpay: number = 0;
  totalDedution: number = 0;
  period: string = "";
  employeeName: any = "";
  firstname: any;
  lastname: any;
  empnumber: any;
  empCode: boolean;
  clientaddressobj: any = {};
  dataForExcel = []; 
  base64data: string | ArrayBuffer | null = null;
  excelData: any;
  headerForExcel = [
    {'S NO':'',   'MONTH':'', 'NAME':'','NETPAY':'', 'BANK':'','ACCNO':'',    
   }
  ];
  fileblob: string;
  @ViewChild('pdfTable')pdfTable!: ElementRef;

  private unsubscribe$: Subject<void> = new Subject<void>();
  inputValue: any;
  employeelistsub: any;
tableShow: boolean=false;

  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private EmployeeService : EmployeeService,
    private http: HttpClient,
    private router:Router,private superAminservice :SuperadminService ,
    @Inject(AppComponent) public AppData: any , private ExportExcelService  : ExportExcelService) { }

  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.userData.userId;
    this.orgId = this.userData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.userData?.employee?.client?.clientId;
    console.log(this.clientId);


    
    if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
    }

    if(this.clientId){
      this.settingsService.getFinicalyearActiveListbyClient(this.clientId).subscribe((resultData: any) => {
        console.log(resultData);
        this.finYearList = resultData;
      });
      }

    
   
    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null,],
      period:[null,]
    })
   

    
    if(this.orgId){
    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }

  if(this.clientId){
    this.settingsService.getClientdetails(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }







  if(this.orgId){
    this.superAminservice.getOrganizationById(this.orgId).subscribe(
      (resultData:any) =>{
      this.resultData = resultData;
      console.log(this.resultData);
      if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
        console.log(this.resultData.organizationAttachment.fileViewUrl);
        this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
         (blob) => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          },
          err =>{
            this.fileblob= "assets/uploads/no-image.png"
            console.log("else",this.fileblob);
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          }
        );
      }
      else{
        this.fileblob= "assets/uploads/no-image.png";
        console.log("else",this.fileblob);
        this.http.get(this.fileblob, { responseType: 'blob' })
        .subscribe(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64data = reader.result;                
              // console.log(this.base64data);
          }
          reader.readAsDataURL(res); 
          // console.log(res);
        });
      }
    }
    )
  }
  if(this.clientId){
    this.superAminservice.getClientById(this.clientId).subscribe(
      (resultData:any) =>{
      this.resultData = resultData;
      console.log(this.resultData);
      if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
        console.log(this.resultData.organizationAttachment.fileViewUrl);
        this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
         (blob) => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          },
          err =>{
            this.fileblob= "assets/uploads/no-image.png"
            console.log("else",this.fileblob);
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          }
        );
      }
      else{
        this.fileblob= "assets/uploads/no-image.png";
        console.log("else",this.fileblob);
        this.http.get(this.fileblob, { responseType: 'blob' })
        .subscribe(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64data = reader.result;                
              // console.log(this.base64data);
          }
          reader.readAsDataURL(res); 
          // console.log(res);
        });
      }
    }
    )
  }
  }
  printPdf(){
    console.log("PrintPDF");
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { 
      content: html ,
      // image: this.fileblob, // Use the dataURL here
      // width: 200,
      // height: 100,
      pageMargins: [ 20, 20, 20, 30 ],
      info: {
        title:`Net Pay Report`
        },
        pageSize: 'A4',
        pageOrientation: 'portrait',
        background: function (currentPage, pageCount) {
          console.log('currentPage:', currentPage);
          console.log('pageCount:', pageCount);
      
          return {
              text: 'Page ' + currentPage.toString(),
              alignment: 'center',
              margin: [0, 8],
              fontSize: 10,
          };
         },
      };
    //  pdfMake.createPdf(documentDefinition).open(); 
     pdfMake.createPdf(documentDefinition).download("Net Pay Report"); 
  }

  calucatetotal()
  {
    this.totalamountbeforededuction =  0;
    this.totalnetpay = 0;
    this.totalDedution = 0;
     if(this.resultData.length != undefined )
     {
      this.resultData.forEach(element => {
        this.totalnetpay +=  this.Roundof(element.totalAmount);
      });
      console.log("this.totalnetpay",this.totalnetpay);
     } 
  }
  Roundof(amount){

    return Math.round(amount);
  }


  searchSubmit()
  {
        this.isProcessing=true;
        this.noRecordsFound = false;

        if((this.filterForm.value.employeecode !== undefined && this.filterForm.value.employeecode !== null && this.filterForm.value.employeecode !== '') ){
          if((this.employee!= this.filterForm.value.employeecode ) || (this.employeeId == null)){
            window.alert('please select valid employee from dropdown.');
            return;
          }
        }

    console.log("submit");
    this.req=false;
    this.p = 1;
    console.log(this.filterForm.value);
    console.log("this.filterForm.value.employeecode" + this.filterForm.value.employeecode);
    console.log("this.filterForm.value.financialyear" + this.filterForm.value.financialyear);
    console.log("this.filterForm.value.period" + this.filterForm.value.period);
    console.log(this.totalnetpay)
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null ) && (this.filterForm.value.client=== 'null' || this.filterForm.value.client === null ) &&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period === 'null')){
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = false;
      this.isProcessing=false;

      alert("Please select  Requried fields");
      // this.req=true;
      return
    }

    if((this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null'))
      {
        console.log("Required Field");
        this.resultData =[];
        this.noRecordsFound = false;
        alert("Please Enter Requried Fields");
        this.isProcessing=false;
        this.loader=false
  
        return;
      }
  
      if((this.filterForm.value.period===null|| this.filterForm.value.period ==='null'))
        {
          console.log("Required Field");
          this.resultData =[];
          this.noRecordsFound = false;
          alert("Please Enter Requried Fields");
          this.isProcessing=false;
          this.loader=false
    
          return;
        }
   else if((this.filterForm.value.employeecode) && ( this.filterForm.value.financialyear) && (this.filterForm.value.period ))
    {
          
      this.loader = true;
      this.tableShow=false;


      console.log("both");

      if(this.orgId){
      this.payrollservice.getNetPayCallanByEmployeeIdOrg(this.employeeId,this.filterForm.value.period,this.filterForm.value.financialyear,this.orgId).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.isProcessing=false;
        this.tableShow=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
          this.tableShow=true;

        }else{
          this.resultData =  resultData.filter(netpay =>netpay.totalAmount != 0);
          this.calucatetotal();

          console.log(this.resultData)
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
          this.roundofdata();

        }
        
        if( resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          this.tableShow=true;

        }
      })
    }

    if(this.clientId){
      this.payrollservice.getNetPayCallanByEmployeeIdClient(this.employeeId,this.filterForm.value.period,this.filterForm.value.financialyear,this.clientId).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.isProcessing=false;
        this.tableShow=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
          this.tableShow=true;

        }else{
          this.resultData =  resultData.filter(netpay =>netpay.totalAmount != 0);
          this.calucatetotal();

          console.log(this.resultData)
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
          this.roundofdata();

        }
        
        if( resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          this.tableShow=true;

        }
      })
    }
    }
    
    else if( (this.filterForm.value.financialyear) && (this.filterForm.value.period  ) )
    {
      console.log("finactical and period");
      this.loader = true;
      if(this.orgId){
        this.loader = true;
        this.tableShow=false;

      this.payrollservice.getNetPayCallanReportPeriodYear(this.orgId , this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.isProcessing=false;
        this.tableShow=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
          this.loader = false;
          this.tableShow=true;
        }
        else{
          this.loader = false;
          let lastindex = this.resultData.length -1;
          this.resultData =  resultData.filter(netpay =>netpay.totalAmount != 0);
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
          this.roundofdata();

        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.resultData =[]
          this.loader = false;
          this.tableShow=true;

        }
      })
    }

    if(this.clientId){
      this.payrollservice.getNetPayCallanReportPeriodClientYear(this.clientId ,this.filterForm.value.financialyear, this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.isProcessing=false;

        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else{
          let lastindex = this.resultData.length -1;
          this.resultData =  resultData.filter(netpay =>netpay.totalAmount != 0);
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
          this.roundofdata();

        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    }
    
   
  }

  clearTable()
  {
    this.resultData.length = 0;
    this.noRecordsFound=false;
    this.empList =[];
  }

  // empSearch(event)
  // { 
  //   this.empList=[];
  //   this.empCode=false;
  //   console.log(event.target.value);
  //   this.userId=event.target.value;
  //   console.log(this.userId);
  //   // Employee List Api
  //     // this.empList.length == 0;
  //     if(event.target.value.length != 0)
  //     {
  //       this.payrollservice.getClienEmployeeList(this.userId,this.filterForm.value.client).subscribe((result: any) => {
  //         if(result.statusCode==204)
  //               {
  //                 console.log('NO Data');
  //                 this.empList.length = 0;
  //                 this.empCode=true;
  //               }
  //               else
  //               {
  //                 this.empList = result;
  //                 this.empCode=false;
  //                 console.log(this.empList);
  //               }
  //       })
  //     }else{
  //       this.filterForm.patchValue({
  //         employeecode: null
  //      });
  //     }
  
  // }

  // empSearch(event): void {
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);
    
  //   // Cancel the previous API request
  //   this.unsubscribe$.next();
  //   this.unsubscribe$.complete();
  //   this.unsubscribe$ = new Subject<void>();
  
  //   // Employee List Api
  //   if (employeenumber) {
  //     if(employeenumber.length >= 2)
  //     {
  //       this.payrollservice.getEmployeeNameList(employeenumber,this.orgId)
  //       .pipe(
  //         takeUntil(this.unsubscribe$), // Cancel previous request
  //         switchMap(() => this.payrollservice.getEmployeeNameList(employeenumber,this.orgId))
  //       )
  //       .subscribe((result: any) => {
  //         this.empList.length = 0;
  
  //         if (result.statusCode) {
  //           console.log(result.statusCode);
  //           this.empList.length = 0;
  //         } else {
  //           this.empList = result;
  //         }
  //       });
  //     }
      
  //   }
  // }

  empSearch(event){
    console.log(event.target.value)
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement) // Cast the event target as an input element
  this.inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
   console.log(this.inputValue)
    // Clear the employee list and reset flags if input is empty
    if (this.inputValue === '') {
      this.empList = [];
      this.empCode = false;
      console.log('Input is empty, employee list cleared');
      return;
    }
  
    // Check if input length is sufficient for the search
    if (this.inputValue.length >= 2) {
      // Unsubscribe from the previous API request if necessary
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
  
      // Reset and create a new Subject for cancellation
      this.unsubscribe$ = new Subject<void>();

      if(this.orgId){
      this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }
      if(this.clientId){
        this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true; // Set the flag to indicate an error state
              }
              console.error('An error occurred:', err);
              return EMPTY; // Return EMPTY to propagate completion downstream
            })
          )
          .subscribe(
            (result: any) => {
              if (result.statusCode === 204) {
                console.log('No employees found (status code 204)');
                this.empList = [];
                this.empCode = true; // Indicate no results found
              } else if (result.statusCode) {
                console.log('Other status code:', result.statusCode);
                this.empList = [];
                this.empCode = true;
              } else {
                // Handle the case where result is an array or an object
                this.empList = Array.isArray(result) ? result : Object.values(result);
                this.empCode = false;
              }
            },
            (err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true;
              }
              console.error('An error occurred:', err);
            }
          );
        }
    }
  } 
  
  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log(emp);
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;

  
   
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;

    console.log(this.employee);
    
      this.filterForm.patchValue({
        employeecode: this.employee
     });
  
    // }
  
  }

  roundofdata()
  {
    this.resultData.map(  (e) => {
      e.totalAmount  = Math.round(e.totalAmount);
      // e.payrollLine.map((payelement)=> {
      //   payelement.amount = Math.round(payelement.amount);
      // }) 
   })

  }

  
  
  pageChages(){
    console.log("page changes");
    this.p=1;
  }
  selectFinancialyear(){
    console.log("Select");
    // console.log(event.target.value);
    this.filterForm.get('financialyear').value;
    // console.log(this.FilterForm.value.financialyear);
     this.chooseFinId=this.filterForm.get('financialyear').value
    console.log(this.chooseFinId);
    
    if(this.chooseFinId == 'null' || this.chooseFinId == null )
    {
      this.filterForm.patchValue({
      
        financialyear:null,
      
      })
    }else{
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
    });
  }
  }
  selectFinancialfromPeriod(event)
  {
    if(event.target.value == 'null')
    {
      this.filterForm.patchValue({
      
        fromperiod:null,
      
      })
  }
}
selectFinancialtoPeriod(event)
{
  if(event.target.value == 'null')
  {
    this.filterForm.patchValue({
    
      toperiod:null,
    
    })
}
}

calucatenetpay(earnings,deductions)
{
   let netpay = earnings - deductions;

  return  netpay?.toFixed(2);
}
 
  public downloadAsExcel(){
      let clientcode = "" ;
    this.dataForExcel.length = 0;
    let totaldaysofwages = 0;
    let totalOtHours = 0;
    this.excelData=this.resultData;
    console.log(this.excelData);
    let i = 1;
    for(let e of this.excelData ){
      let data= {
         
          SNo :  i++,
          period : e.finanicalPeriod.financialperiodMonth + "-"  +e.finanicalPeriod.financialPeriodYear,    
          // clientCode : e.client.clientCode,                
          // employeeNumber :   e.employee.employeeNumber,
          firstName :  e.employee.firstName,
          netPay : this.Roundof(e.totalAmount?.toFixed(2)),
          bankName :  e.employee.employeeInfo?.bankName ?e.employee.employeeInfo?.bankName  : "" ,
          // bankBranchName: e.employee.employeeInfo.bankBranchName ?e.employee.employeeInfo.bankBranchName  : "" ,
          bankAccountNo:   e.employee.employeeInfo?.bankAccountNo ?e.employee.employeeInfo?.bankAccountNo  : "" ,
          // ifscCode :  e.employee.employeeInfo.ifscCode ?e.employee.employeeInfo.ifscCode  : ""   ,               
          // uanNumber:  e.employee.uanNumber?e.employee.uanNumber : "",       
          // esiNumber :  e.employee.esiNumber ?e.employee.esiNumber : ""
        }
    
      //  let netpay = Math.round(e.totalEarnings) -  Math.round(e.totalDeduction) ;
      //   data["Dedecutions"]  =  Math.round(e.totalDeduction) ;
     
      console.log("table data",data);
      this.dataForExcel.push(Object.values(data));
      console.log(this.dataForExcel);
    }
    let data= {
      SNo :  "",
      period :"",    
      firstName : "Total",
      netPay : this.Roundof(this.totalnetpay).toFixed(2),
      bankName :  "",
      bankAccountNo: "",
      ifscCode : ""  ,               
      // uanNumber: "",       
      // esiNumber : ""
   }
  this.dataForExcel.push(Object.values(data));
    let reportDatabyrecruiter = {
      title: 'Net Pay Challan',
       exceldate: "Period : " +  this.period,
      data: this.dataForExcel,
      employeeName :this.employeeName,
      headers: Object.keys(this.headerForExcel[0]),
    }
    console.log(reportDatabyrecruiter);
    this.ExportExcelService.exportExcel(reportDatabyrecruiter);
  }


  noEmployeeFound() {
    // this.selectedemployeeId = undefined;
    // this.selectedemployeeId = undefined; 
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    // this.selectedemployee = undefined;
    // this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 

  }

}
