import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Sort } from '@angular/material/sort';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ViewtimesheetreportComponent } from '../../../../app/project-management/project-management/viewtimesheetreport/viewtimesheetreport.component';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { PayrollService } from '../../../../app/providers/payroll.service';
import { EmployeeService } from '../../../../app/providers/employee.service';
import { HeaderService } from '../../../../app/providers/header.service';
import { EMPTY, ReplaySubject, Subject, catchError, switchMap, take, takeUntil, throwError } from 'rxjs';
import { TimesheetService } from '../../../../app/providers/timesheet.service';
import { AssignEmpService } from '../../../../app/providers/assign-emp.service';
import { MatSelect } from '@angular/material/select';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector:'app-dailytimesheet',
  templateUrl:'./dailytimesheet.component.html',
  styleUrls: ['./dailytimesheet.component.scss']
})
export class DailytimesheetComponent implements OnInit {
  @ViewChild('dateInput') dateInput: NgForm; // ViewChild to access the NgForm instance
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
  public employeeProjects: any = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  selectedEmployeeSearch:any;
  selectedemployee:any;
myform: any;

tableShow:boolean = false;
// today: Date = new Date();
// timesheetDate: any;
date_required: boolean = false;
private unsubscribe$: Subject<void> = new Subject<void>();

  resultData: any = {};
  storeEmpId: any;
  storeOrgId: any;
isproject: boolean;
timesheetDate: string='';
isbuttondisable:boolean=false;
// date_required: boolean = false;
proj_required:boolean=false;
timesheetReport:any=[]
disable_project: boolean = true;
user_type: string;
projectId: any=0;
projectlist: any;
selectedemployeeId: any;
employeelist: any;
today: any;
timesheetreport: any=[];
mySelect: any=5;
search: any;
p: any;
nodata: boolean=false;
loader: boolean=true;
  empId: any;
  organizationId: any;
  organizationName:any;
  orgAddress:any;
  projectList: any=[];
  isData: boolean;
  userType: any;
  employee_required: boolean=false;
// filterForm: FormGroup;
empList: any=[];
  userId: number;
  orgId: number;
  employeeId: number;
  employee: string;
  // selectedemployee: any =  undefined;
  // clientForm: FormGroup;
  Data: any;
  backdrop: boolean=false;
  nodatafound: boolean = true;
  sortedData: any[];
  emp_Id: any;
// filterForm: FormGroup;
  employeeNumber: number;
tableSearch: any;
pagePerItem: string|number;
pageViews: any;
  dataFormvalues: any;
// employeeProjects: any;
submitted1: any;
group: any;
  assignformbyemployee: any;
submitted: boolean=false;
  employeeIds: any;
  showEmptyData:boolean=false;
  selectedProjectId: number;
  employeeTimesheet: any;
  employeeNotFound: boolean = false;
tablehide: boolean=true;
projectIdList: any[] = [];
  stroreOrgId: any;
  myFormattedDate: string;
  employeeSelected: boolean = false;
// filterForm: FormGroup<any>;

@ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
  constructor(
    private gs: ProjectManagementService,
    private router: Router,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private payrollservice:PayrollService,
    private employee_service:EmployeeService,
    public formBuilder: FormBuilder,
    private headerservice:HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService

  ) { }


  ngAfterViewInit() {
    this.setInitialValue();
    

    // this.removePlaceholder();
  
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.employeeProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  ngOnInit(): void {
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage(
      ''
    );
    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    this.timesheet.setWarningUpdateMessage(
      ''
    );
    // this.headerservice.setTitle('Daily Timesheet Report');
    const data = JSON.parse(localStorage.getItem("enoteUserData"));
    console.log("data::",data);
    
    this.empId = data.employee.employeeId;
    this.organizationId = data.organization.organizationId;
    console.log("organzationid" + this.organizationId);

    this.organizationName=  data.organization.organizationName;
    this.orgAddress=  data.organization.address;


    this.tableShow = false;
    // this.gs
    //   .getProjectByOrganization(this.organizationId)
    //   .subscribe((data: any) => {
    //     console.log("Project data List", data);

    //     console.log("data", data[0].projectId);
    //     this.projectList.push(data);
    //     this.projectList = data;
    //     this.projectlist = data;
   
    //     console.log("this.projectlist::", this.projectlist[0].projectName);
    //   });
    this.isData = false;
    this.today = new Date();
    this.today = this.datepipe.transform(this.today, "YYYY-MM-dd");
     
    const Data = JSON.parse(localStorage.getItem("enoteUserData"));
    console.log("local data list::",Data);
    this.stroreOrgId=Data.organization.organizationId
    console.log(this.stroreOrgId);
    
    
    // this.gs.getResourceByIds(Data.organization.organizationId).subscribe((d:any)=>{
    //   // this.userType = d.userType.userType;
    //   // this.user_type = d.userType.userType;
    //   this.gs.getProjectList().subscribe((pa: any) => {
    //     this.projectList = pa;
    //     console.log(this.projectList);
    //   });
    // })
    this.gs.getProjectsIfTasks(this.stroreOrgId).subscribe((data: any) => {
      console.log('data', data);

      // console.log('data', data[0].projectId);
      this.projectIdList.push(data);
      this.projectIdList = data;
      this.projectlist = data;

      console.log('this.projectlist', this.projectlist[0].projectName);
    });

  console.log("this.employeeProjects::",this.employeeProjects);
  
    this.getEmployeeProjectReport();

   
  }
  getEmployeeProjectReport(){
    this.tableShow = false;
    var currentdate = new Date();
    console.log("current date::" , currentdate);

    const now = Date.now();
    this.myFormattedDate = this.datepipe.transform(now, "YYYY-MM-dd");
    this.loader = true;
    console.log("myFormattedDate::" , this.myFormattedDate);
    this.gs.getTaskReportByEmpolyee(this.myFormattedDate).subscribe(
      (tp: any) => {
        this.loader = false;
        this.timesheetreport = tp;
        this.tableShow = true;
        console.log("time sheet report emp_list::",this.timesheetreport);
        this.timesheetreport.forEach((employee:any) => {
          // console.log("Employee ID:", employee.employee.employeeId);
          console.log("Timesheet:", employee.employee.timesheet);
        
          // Now, if you want to further iterate over the timesheet object
      
        });

                
        this.timesheetReport.forEach((project:any)=>{
          this.employeeIds=project.employeeId;
          console.log("employeeids::",this.employeeIds);
          
        })
        if (this.timesheetreport.length == 0) {
          this.nodata = true;
          this.tableShow = true;
          console.log("nodata");
        }
      },
      (error) => {
        // this.nodata = true;

        // this.tableShow = true;
        console.log("error");
        if (error.errorCode == 404) {
          // this.nodata = true;
          this.tableShow = true;
          this.loader = false;

          console.log("404");
        }
      }
    );
  }
  // handleBackspace(event): void {
  //   if (
  //     event.key === 'Backspace' &&
  //     !this.selectedemployee &&
  //     this.selectedemployeeId.trim().length === 0
  //   ) {
  //     this.selectedemployeeId = undefined;

  //     this.employeeId = undefined;
  //     this.employeeProjects.length = 0;
  //     this.employee = undefined;
  //     this.employeeNumber = undefined;
  //     this.selectedemployee = undefined;
  //     this.selectedemployeeId = undefined;
  //   }
  // }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.employeeProjects.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.employeeNotFound=false
  }

  // empSearch(event)
  // { 
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);

  //   this.unsubscribe$.next();
  //   this.unsubscribe$.complete();
  //   this.unsubscribe$ = new Subject<void>();
  //   // Employee List Api
  //   if(employeenumber)
  //   {
  //     if(employeenumber.length >= 2){
  //       this.employeeProjects.length=0

  //       this.gs
  //       .filterEmployeesByProjects(
  //         this.organizationId,
  //         this.selectedProjectId,
  //         employeenumber
  //       )
  //       .pipe(
  //         takeUntil(this.unsubscribe$), // Cancel previous request
  //         switchMap(() =>
  //           this.gs.filterEmployeesByProjects(
  //             this.organizationId,
  //             this.selectedProjectId,
  //             employeenumber
  //           )
  //         )
  //       )
  //       .subscribe((result: any) => {

  //     // this.gs.getEmployeesBynumber( employeenumber ).subscribe((result: any) => {
  //       // this.employeeProjects = result;
  //       this.employeeProjects.length=0

  //       console.log('this.employeeProjects',this.employeeProjects);
  //       if(result.statusCode)
  //       {
  //         this.employeeProjects.length=0

  //       }else{
  //         this.employeeProjects =   result;
  //         console.log('this.employeeProjects',this.employeeProjects);
  //       }
  //    },
  //    (error)=>{
  //     this.employeeProjects.length=0
  //     // this.selectedemployeeId=undefined
  //   }
  //    );
  //   } 
  //   else{
  //     // this.employeeProjects.length=0

  //   }
  // }
  // else{
  //   // this.employeeProjects.length=0

  // }
  // this.getTask()

  // }

  onInputChange() {
    this.search = this.selectedemployee?.trim();
  }

  onInputClick() {
    if (this.search === '') {
      this.selectedemployee = ''; // Clear the input value
    }
  }
  handleBackspace(event): void {
   
//     const inputValue = event.target.value;
  
//     if (event.key === 'Backspace' && inputValue.length <= 1) {
//         this.selectedemployeeId = undefined;
//         this.employeeId = undefined;
//         this.employeeProjects.length = 0;
//         this.employee = undefined;
//         this.employeeNumber = undefined;
//         this.selectedemployee = undefined;
//         this.employeeNotFound = false; 
//     }
const inputValue = event.target.value;

if (this.timesheetreport.length > 0) {
  this.nodata = false;
}
if (event.key === 'Backspace' && inputValue.length <= 0) {
  this.selectedemployeeId = undefined;
  this.employeeId = undefined;
  this.employeeProjects.length = 0;
  this.employee = undefined;
  this.employeeNumber = undefined;
  this.selectedemployee = undefined;
  this.employeeNotFound = false; // Set employeeNotFound to false
}
  }

  // empSearch(event,event1: KeyboardEvent): void {
  //   // if (event.target.value) {
  //   //   const input = event.target as HTMLInputElement;
  //   //   input.classList.remove('black-placeholder');
  //   // } else {
  //   //   const input = event.target as HTMLInputElement;
  //   //   input.classList.add('black-placeholder');
  //   // }

  //   if (event1.key === ' ') {
  //     this.employeeNotFound = false;
  //     // Handle the space key press
  //     console.log('Space key pressed');

  //     // Optionally, prevent the default behavior
  //     event1.preventDefault();
  //   } else {
  //   this.employeeProjects = [];
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);

  //   // Cancel the previous API request
  //   this.unsubscribe$.next();
  //   this.unsubscribe$.complete();
  //   this.unsubscribe$ = new Subject<void>();
  //   // console.log('checking Project Id', this.projectId);

  //   if(employeenumber.length==0){
  //     this.employeeNotFound=false
  //     console.log("enterd into the condition!!");
      
  //   }

  //   if (
  //     this.projectId === 0 ||
  //     this.projectId === undefined ||
  //     this.projectId === null
  //   ) {
  //     console.log('employee key', employeenumber);
  //     if (employeenumber.length >= 2) {
  //       this.gs
  //         .getEmployeesBynumber(employeenumber)
  //         .pipe(
  //           takeUntil(this.unsubscribe$), // Cancel previous request
  //           switchMap(() => this.gs.getEmployeesBynumber(employeenumber)),
  //           catchError((err) => {
  //             if (err.error.status === 500) {
  //               // this.projectId = [];
  //               this.employeeNotFound = true;
  //             }
  //             console.error('Error fetching employees:', err);
  //             // Handle the error here, for example, showing an alert
  //             // alert('Error fetching employees. Please try again later.');
  //             return throwError(err); // Rethrow the error to propagate it downstream
  //           })
  //         )
  //         .subscribe((result: any) => {
  //           console.log('result', result);

  //           if (result.statusCode === 204) {
  //             this.employeeNotFound = true;
  //           }
  //           this.employeeProjects.length = 0;

  //           result.forEach((element) => {
  //             let employee = {
  //               employee: {
  //                 employeeId: element.employeeId,
  //                 firstName: element.firstName,
  //                 employeeNumber: element.employeeNumber,
  //               },
  //             };
  //             console.log('result:', result);
  //             console.log('Is result an array?', Array.isArray(result));
              

  //             if (Object.keys(employee).length > 0) {
  //               this.employeeNotFound = false;
  //             } else {
  //               this.employeeNotFound = true;
  //             }
  //             this.employeeProjects.push(employee);
  //             console.log('this.employeeProjects', this.employeeProjects);
  //           });

  //           // if (this.selectedemployee.length >= 1) {
  //           //   this.employeeSelected = true;
  //           // }

  //           if (result.errorCode === 204) {
  //             // alert('No Employee Found');
  //             this.employeeNotFound = true;
  //             this.employeeProjects.length = 0;
  //             this.employeeProjects.push('No Employee Found');
  //             this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
  //           }
  //         });
  //     }
  //   }

  //   // Employee List Api
  //   if (employeenumber && this.projectId > 0) {
  //     if (employeenumber.length >= 2) {
  //       this.gs
  //         .filterEmployeesByProjects(
  //           this.organizationId,
  //           this.projectId,
  //           employeenumber
  //         )
  //         .pipe(
  //           takeUntil(this.unsubscribe$),
  //           switchMap(() =>
  //             this.gs.filterEmployeesByProjects(
  //               this.organizationId,
  //               this.projectId,
  //               employeenumber
  //             )
  //           ),
  //           catchError((err) => {
  //             console.log('emp', err);

  //             if (err.errorCode === 204) {
  //               // this.projectId = [];
  //               this.employeeNotFound = true;
  //             }
  //             if (err.error.status === 500) {
  //               // this.projectId = [];
  //               this.employeeNotFound = true;
  //             }
  //             this.employeeNotFound = true;
  //             console.error('Error filtering employees:', err);
  //             // Handle the error here, for example, showing an alert
  //             // alert('Error filtering employees. Please try again later.');
  //             return throwError(err); // Rethrow the error to propagate it downstream
  //           })
  //         )
  //         .subscribe((result: any) => {
  //           console.log('result', result);
  //           if (result.errorCode === 204) {
  //             // this.projectId = [];
  //             this.employeeNotFound = true;
  //           }
  //           this.employeeProjects.length = 0;

  //           result.forEach((res) => {
  //             console.log('employeeId', res.employee.employeeId);

  //             let employee = {
  //               employee: {
  //                 employeeId: res.employee.employeeId,
  //                 firstName: res.employee.firstName,
  //                 employeeNumber: res.employee.employeeNumber,
  //               },
  //             };

  //             if (Object.keys(employee).length > 0) {
  //               this.employeeNotFound = false;
  //             } else {
  //               this.employeeNotFound = true;
  //             }

  //             console.log('this.employeeProjects', this.employeeProjects);

  //             this.employeeProjects.push(employee);
  //           });

  //           // if (this.selectedemployee.length >= 1) {
  //           //   this.employeeSelected = true;
  //           // }

  //           if (result.errorCode === 204) {
  //             // alert('No Employee Found');
  //             this.employeeNotFound = true;
  //             this.employeeProjects.length = 0;
  //             // this.employeeProjects.push('No Employee Found');
  //             this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
  //           }
  //         });
  //     }
  //   } else {
  //     // Set selectedemployeeId to null if the input is empty
  //     this.selectedemployeeId = null;
  //   }
  // }
  //   // this.noEmployeeFound()
  // }
  empSearch(event, event1: KeyboardEvent): void {
    // this.selectedemployee = event.target.value;
    // this.employee = '';
    if (event1.key === ' ') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      if (this.timesheetreport.length > 0) {
        this.nodata = false;
      }

      this.employeeProjects = [];
      console.log(event.target.value);
      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log(employeenumber);

      // Cancel the previous API request
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();

      if (employeenumber.length == 0) {
        this.employeeNotFound = false;
        console.log('enterd into the condition!!');
        // this.employeeId = undefined;
        // this.employee = undefined;
      }
      console.log('checking Project Id', this.projectId);

      if (
        this.projectId === 0 ||
        this.projectId === undefined ||
        this.projectId === null
      ) {
        console.log('employee key', employeenumber);
        if (employeenumber.length >= 2) {
          this.gs
            .getEmployeesBynumber(employeenumber)
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() => this.gs.getEmployeesBynumber(employeenumber)),
              catchError((err) => {
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.employeeNotFound = true;
                }
                console.error('Error fetching employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error fetching employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);

              if (result.statusCode === 204) {
                this.employeeNotFound = true;
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
                this.employeeId = undefined;
              }
              this.employeeProjects.length = 0;

              (result as any)?.forEach((element) => {
                let employee = {
                  employee: {
                    employeeId: element.employeeId,
                    firstName: element.firstName,
                    employeeNumber: element.employeeNumber,
                  },
                };

                if (Object.keys(employee).length > 0) {
                  this.employeeNotFound = false;
                } else {
                  this.employeeNotFound = true;
                }
                this.employeeProjects.push(employee);
                console.log('this.employeeProjects', this.employeeProjects);
              });

              // if (this.selectedemployee.length >= 1) {
              //   this.employeeSelected = true;
              // }

              if (result.errorCode === 204) {
                // alert('No Employee Found');
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
                this.employeeId = undefined;
                this.employeeNotFound = true;
                this.employeeProjects.length = 0;
                this.employeeProjects.push('No Employee Found');
                this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
              }
            });
        }
      }

      // Employee List Api
      if (employeenumber && this.projectId > 0) {
        if (employeenumber.length >= 2) {
          this.gs
            .filterEmployeesByProjects(
              this.organizationId,
              this.projectId,
              employeenumber
            )
            .pipe(
              takeUntil(this.unsubscribe$),
              switchMap(() =>
                this.gs.filterEmployeesByProjects(
                  this.organizationId,
                  this.projectId,
                  employeenumber
                )
              ),
              catchError((err) => {
                console.log('emp', err);

                if (err.errorCode === 204) {
                  // this.projectId = [];
                  this.employeeNotFound = true;
                  this.selectedemployee = undefined;
                  this.selectedemployeeId = undefined;
                  this.employeeId = undefined;
                }
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.employeeNotFound = true;
                }
                this.employeeNotFound = true;
                console.error('Error filtering employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error filtering employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);
              if (result.errorCode === 204) {
                // this.projectId = [];
                this.employeeNotFound = true;
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
                this.employeeId = undefined;
              }
              this.employeeProjects.length = 0;

              (result as any).forEach((res) => {
                console.log('employeeId', res.employee.employeeId);

                let employee = {
                  employee: {
                    employeeId: res.employee.employeeId,
                    firstName: res.employee.firstName,
                    employeeNumber: res.employee.employeeNumber,
                  },
                };

                if (Object.keys(employee).length > 0) {
                  this.employeeNotFound = false;
                } else {
                  this.employeeNotFound = true;
                }

                console.log('this.employeeProjects', this.employeeProjects);

                this.employeeProjects.push(employee);
              });

              // if (this.selectedemployee.length >= 1) {
              //   this.employeeSelected = true;
              // }

              if (result.errorCode === 204) {
                // alert('No Employee Found');
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
                this.employeeId = undefined;
                this.employeeNotFound = true;
                this.employeeProjects.length = 0;
                // this.employeeProjects.push('No Employee Found');
                this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
              }
            });
        }
      } else {
        // Set selectedemployeeId to null if the input is empty
        // this.selectedemployeeId = null;
      }
    }
  }
  selectProjectEmpty(){
    this.selectedemployee=''
    this.employee=undefined
    this.selectedemployeeId=undefined
    this.projectId=undefined
    this.employeeId=undefined
  }
  projectData(pro){
    // this.employeeProjects = [];
    // this.selectedemployee =  '';
    // this.employee=undefined;
    
    // this.selectedemployeeId=undefined
    //  this.employeeNotFound =  false;
    console.log("pro",pro);
    this.employeeId=0
    this.gs.getEmployeeProject(this.projectId).subscribe(
      (data: any) => {
        console.log('data', data);

        // this.projectlist = data;
      }
      )
    // this.selectedProjectId=pro.projectId;
    this.getTask()

  }
 
  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }
  viewtimesheet(data: any) {

    console.log("data::wtts check::",data);
    

    console.log("timesheetreport length" , this.timesheetreport.length);
    var searchdata = {
      timesheetDate: this.timesheetDate,
      projectId: this.projectId,
      employeeId: data,
    };
    var dialogbox = this.dialog.open(ViewtimesheetreportComponent, {
      width: "900px",
      height: "515px",
      data: searchdata,
    
    });
              }
              getTask() {
                // this.employee_required = false;
                // this.disable_project = false;
                // // console.log(this.disable_project);
                // console.log("employeeId::" , this.selectedemployeeId);
                // if (this.projectId == undefined || this.selectedemployeeId === 0) {
                //   this.employeelist= 0;
                //   console.log("this.projectid::",this.projectId);
                  
                // } else {
                //   this.gs.getEmployeeProject(this.projectId).subscribe((data: any) => {
                //     console.log("data::",data);
                //     this.employeelist = data;
            
                //     console.log("this.employelistt::",this.employeelist);
            
                //     const empl_Ids:number[]=[]
                //     this.employeelist.forEach((item:any)=>{
                //       if(item.employee&& item.employee.employeeId){
                //         empl_Ids.push(item.employee.employeeId)
                //       }
                //     })
                //     console.log("emp_ids::",empl_Ids);
                    
                    
                //   });
                // }
        //         if(this.selectedProjectId)
        //         {
        //           this.gs.getEmployeeProject(this.selectedProjectId).subscribe(
        //             (data: any) => {
        //               console.log("data", data);
        //               this.employeeProjects = data;
        //               if (this.employeeId != undefined) {
        // let hasemployee =  this.employeeProjects.some(item => item.employeeId === this.employeeId);
        // // console.log("hasemployee" + hasemployee);
        // // console.log("index" + index);
        //   if(!hasemployee)
        //    {
            

            
        //       this.selectedemployee = undefined;
        //       this.employeeNotFound = false;
        //       this.employeeProjects.length = 0;
        //       this.employeeId = undefined;
        //       this.employee = undefined;
        //       this.employeeNumber = undefined;
          
        
        //    }
        //             }          // this.employeelist.push({data})
        //             },
        //             (error) => {
        //               console.error("Error fetching employee data:", error);
        //               // Handle the error (e.g., show an error message to the user)
        //             }
        //           );
        //         }else{
        //           // this.employeelist.length = 0;
        //         }
        // console.log(this.disable_project);
        // console.log('employeeId' + this.selectedemployeeId);
        let matchFound = false;
        if (this.projectId == undefined) {
          this.employeelist.length = 0;
        } else {
          this.gs.getEmployeeProject(this.projectId).subscribe((data: any) => {
            console.log(data);
            this.employeelist = data;
         
    
          if (this.employeeId != undefined) {
          let hasemployee =  this.employeelist.some(item => item.employeeId === this.employeeId);
        
            if(!hasemployee)
             {
              
    
              
                this.selectedemployee = undefined;
                this.employeeNotFound = false;
                this.employeeProjects.length = 0;
                this.employeeId = undefined;
                this.employee = undefined;
                this.employeeNumber = undefined;
                matchFound = false;
          
             }
            }
            // this.project_required = false;
          });
        }

                }
                dateChange(event) {
                  console.log(event.value);
                  this.date_required = false;
                  }
                  validateDate() {
                    // Check if the input value is a valid date
                //     if (isNaN(Date.parse(this.timesheetDate.toString()))) {
                //       this.dateInput.control.setErrors({ 'incorrect': true });
                //   } else {
                //       this.dateInput.control.setErrors(null);
                //   }
                //   if (!this.timesheetDate) {
                //     this.date_required = true;
                // } else {
                //     this.date_required = false;
                // }
                if (this.timesheetDate) {
                  // Check if the input value is a valid date
                  if (isNaN(Date.parse(this.timesheetDate.toString()))) {
                    this.dateInput.control.setErrors({ 'incorrect': true });
                  } else {
                    this.dateInput.control.setErrors(null);
                  }
                } else {
                  this.dateInput.control.setErrors({ 'required': true });
                }
                this.date_required = !this.timesheetDate;
                }
            
                  changefirstpage() {
                    this.p = 1;
                    }


                    searchTimesheet() {
                      if (!this.employee) {
                        this.selectedemployee = '';
                      }
                  
                      this.isbuttondisable = false;
                      this.employeeNotFound = false;
                      this.tablehide = false;
                  
                      this.nodata = false;
                      this.changefirstpage();
                      this.timesheetDate = this.datepipe.transform(
                        this.timesheetDate,
                        'YYYY-MM-dd'
                      );
                      if (this.timesheetDate == undefined) {
                        console.log('retun');
                        this.date_required = true;
                        this.proj_required = true;
                        return;
                      }
                      //  this.timesheetreport.length = 0;
                  
                      console.log('this.timesheetDate', this.timesheetDate);
                      console.log('this.projectId', this.projectId);
                      console.log('this.employeeId', this.employeeId);
                      // console.log(this.projectId !== undefined || this.projectId!==0);
                      // console.log(this.projectId !== undefined && this.projectId!==0);
                      // console.log(this.selectedemployeeId == undefined||this.selectedemployeeId.length==0);
                      console.log('check true or false::', this.employeeId === 0);
                      console.log(
                        'check wt full condition::',
                        this.projectId == undefined || this.projectId == 0
                      );
                  
                      if (
                        (this.projectId == undefined || this.projectId == 0) &&
                        (this.employeeId == undefined || this.employeeId == 0)
                      ) {
                        if (this.employeeId == 0 || this.employeeId == undefined) {
                          this.selectedemployee = '';
                          this.selectedemployeeId = undefined;
                          this.employee = undefined;
                          this.employeeProjects = [];
                          this.employeeProjects.length = 0;
                          this.employeeNumber = undefined;
                        }
                        if (this.projectId == undefined) {
                          this.projectId = undefined;
                        }
                  
                        this.loader = true;
                        // this.selectedemployeeId =  this.employee;
                        // this.selectedemployee =  this.employee;
                        this.isbuttondisable = true;
                        this.loader = true;
                        this.tablehide = true;
                        this.timesheetreport = [];
                        console.log('date only');
                  
                        this.tableShow = false;
                        this.gs.getTaskReportByEmpolyee(this.timesheetDate).subscribe(
                          (data: any) => {
                            console.log(data);
                            this.loader = false;
                            this.tablehide = false;
                  
                            // this.timesheetreport = data;
                            this.timesheetreport = Array.isArray(data) ? data : [];
                            this.tableShow = true;
                            this.isbuttondisable = true;
                  
                            this.nodata = false;
                            if (this.timesheetreport.message == 'No Data') {
                              this.timesheetreport.length = 0;
                              this.nodata = true;
                              this.isbuttondisable = false;
                  
                              // this.timesheetReport=[]
                              console.log('data not found!!!');
                            }
                            this.isbuttondisable = false;
                          },
                          (error) => {
                            // this.timesheetreport.length=0
                            // this.nodata = true;
                            // if(error.errorCode==404){
                            this.nodata = true;
                            this.tableShow = true;
                            this.tablehide = false;
                            this.isbuttondisable = false;
                  
                            console.log('No Data');
                            // }
                          }
                        );
                      } else if (
                        this.projectId != 0 &&
                        (this.employeeId == undefined || this.employeeId == 0)
                      ) {
                        if (this.employeeId == 0 || this.employeeId == undefined) {
                          this.selectedemployee = '';
                          this.selectedemployeeId = undefined;
                          this.employee = undefined;
                        }
                  
                        this.timesheetreport = [];
                  
                        this.isbuttondisable = true;
                        this.loader = true;
                        console.log('date and project');
                        this.tableShow = false;
                        this.gs
                          .getTaskDetailReportByProject(this.timesheetDate, this.projectId)
                          .subscribe(
                            (data: any) => {
                              this.loader = false;
                              console.log(data);
                              // this.timesheetreport = data;
                              this.timesheetreport = Array.isArray(data) ? data : [];
                              this.tableShow = true;
                              this.nodata = false;
                              this.isbuttondisable = true;
                  
                              if (this.timesheetreport.message == 'No Data') {
                                this.timesheetreport.length = 0;
                                this.isbuttondisable = false;
                  
                                console.log('data not found!!!');
                                this.nodata = true;
                              }
                              this.isbuttondisable = false;
                            },
                            (error) => {
                              this.nodata = true;
                            }
                          );
                      } else if (
                        this.projectId == 0 &&
                        (this.employeeId !== undefined || this.employeeId !== 0)
                      ) {
                        if (this.employeeId == 0 || this.employeeId == undefined) {
                          this.selectedemployee = '';
                          this.selectedemployeeId = undefined;
                          this.employee = undefined;
                          this.employeeProjects = [];
                          this.employeeProjects.length = 0;
                          this.employeeNumber = undefined;
                        }
                  
                        this.tableShow = false;
                        // this.employee=this.selectedemployeeId;
                        // this.selectedemployee = this.employee;
                        this.timesheetreport = [];
                        this.isbuttondisable = true;
                        this.loader = true;
                        console.log('date and employee');
                        this.gs
                          .getSubmittedTimesheetByEmployeeId(this.timesheetDate, this.employeeId)
                          .subscribe(
                            (data: any) => {
                              console.log(data);
                              this.loader = false;
                              // this.timesheetreport = data;
                              this.timesheetreport = Array.isArray(data) ? data : [];
                              this.tableShow = true;
                              this.nodata = false;
                              this.isbuttondisable = true;
                  
                              if (this.timesheetreport.message == 'No Data') {
                                this.timesheetreport.length = 0;
                                this.isbuttondisable = false;
                  
                                console.log('data not found!!!');
                                this.nodata = true;
                              }
                              this.isbuttondisable = false;
                            },
                            (error) => {
                              this.nodata = true;
                            }
                          );
                      } else {
                        if (this.employeeId == 0 || this.employee == undefined) {
                          this.selectedemployee = '';
                          this.employee = undefined;
                          this.selectedemployeeId = undefined;
                        }
                  
                        // this.selectedemployeeId =  this.employee;
                        // this.employee=this.selectedemployeeId
                        // this.selectedemployee = this.employee;
                        this.isbuttondisable = true;
                  
                        console.log('all');
                  
                        this.timesheetreport = [];
                        this.loader = true;
                        this.tableShow = false;
                        this.gs
                          .getSubmittedTimesheetByEmployeeIdAngProjectId(
                            this.timesheetDate,
                            this.employeeId,
                            this.projectId
                          )
                          .subscribe(
                            (data: any) => {
                              console.log(data);
                              this.loader = false;
                              // this.timesheetreport = data;
                              this.timesheetreport = Array.isArray(data) ? data : [];
                              this.tableShow = true;
                              this.isbuttondisable = true;
                              this.nodata = false;
                  
                              if (this.timesheetreport.message == 'No Employee Found') {
                                this.timesheetreport.length = 0;
                                this.isbuttondisable = false;
                  
                                console.log('data not found!!!');
                                this.nodata = true;
                              }
                              this.isbuttondisable = false;
                            },
                            (error) => {
                              this.nodata = true;
                            }
                          );
                      }
                  
                      //      if(this.timesheetDate != undefined && (this.projectId !== undefined || this.projectId!==0)  && this.selectedemployeeId != undefined)
                      //     {
                      //        console.log("all")
                      //      this.gs
                      //      .getSubmittedTimesheetByEmployeeIdAngProjectId(
                      //        this.timesheetDate,
                      //        this.employeeId,
                      //        this.projectId
                      //      )
                      //      .subscribe(
                      //        (data: any) => {
                      //          console.log(data);
                      //          this.timesheetreport = data;
                      //          if (this.timesheetreport.length == 0) {
                      //            this.nodata = true;
                      //          } else {
                      //            console.log(this.timesheetreport.employeeName);
                      //            this.nodata = false;
                      //          }
                      //        },
                      //        (error) => {
                      //          this.nodata = true;
                      //        }
                      //      );
                      //     }
                      //  else   if (this.timesheetDate != undefined && (this.projectId !== undefined && this.projectId!==0) &&  this.selectedemployeeId==undefined) {
                      //       this.proj_required=false
                      //        console.log("date and project");
                      //        this.gs
                      //          .getTaskDetailReportByProject(this.timesheetDate, this.projectId)
                      //          .subscribe(
                      //            (data: any) => {
                      //              console.log(data);
                      //              this.timesheetreport = data;
                      //              this.nodata = false;
                      //            },
                      //            (error) => {
                      //              this.nodata = true;
                      //            }
                      //          );
                      //           }
                  
                      //  else   if (this.projectId == 0 || this.projectId == undefined || this.timesheetDate!=undefined) {
                      //       if (
                      //         this.selectedemployeeId == 0 ||
                      //         this.selectedemployeeId == undefined
                      //       )
                  
                      //        {
                      //         console.log("only date here!....");
                  
                      //         this.gs.getTaskReportByEmpolyee(this.timesheetDate).subscribe(
                      //           (data: any) => {
                      //             console.log(data);
                      //             this.timesheetreport = data;
                      //             this.nodata = false;
                      //             if(this.timesheetreport.message=='No data  available'){
                      //               console.log("data not found!!!");
                  
                      //             }
                      //           },
                      //           (error) => {
                      //             this.nodata = true;
                      //           }
                      //         );
                      //       }
                  
                      // else {
                      //   console.log("else only date calling here.....");
                  
                      //   this.gs
                      //     .getSubmittedTimesheetByEmployeeIdAngProjectId(
                      //       this.timesheetDate,
                      //       this.selectedemployeeId,
                      //       this.projectId
                  
                      //     )
                      //     .subscribe(
                      //       (data: any) => {
                      //         console.log(data);
                      //         this.timesheetreport = data;
                      //         if (this.timesheetreport.length == 0) {
                      //           this.nodata = true;
                      //         } else {
                      //           console.log(this.timesheetreport.employeeName);
                      //           this.nodata = false;
                      //         }
                      //       },
                      //       (error) => {
                      //         this.nodata = true;
                      //       }
                      //     );
                      // }
                      // }
                      // else if(this.timesheetDate!=undefined && (this.projectId == undefined || this.projectId==0) && this.selectedemployeeId){
                  
                      //   console.log("only date is calling!....");
                  
                      //           this.gs.getTaskReportByEmpolyee(this.timesheetDate).subscribe(
                      //             (data: any) => {
                      //               console.log(data);
                      //               this.timesheetreport = data;
                      //               this.nodata = false;
                      //               if(this.timesheetreport.message=='No data  available'){
                      //                 console.log("data not found!!!");
                      //               }
                      //             },
                      //             (error) => {
                      //               this.nodata = true;
                      //             }
                      //           );
                      // }
                      // else if(this.timesheetDate != undefined && (this.projectId == undefined || this.projectId==0) && this.selectedemployeeId != undefined)
                      // {
                      //   console.log("date and employee ID!..");
                  
                      //  this.gs
                      //  .getSubmittedTimesheetByEmployeeIdAngProjectId(
                      //    this.timesheetDate,
                      //    this.employeeId,
                      //    this.projectId
                      //  )
                      //  .subscribe(
                      //    (data: any) => {
                      //      console.log(data);
                      //      this.timesheetreport = data;
                      //      if (this.timesheetreport.length == 0) {
                      //        this.nodata = true;
                      //      } else {
                      //        console.log(this.timesheetreport.employeeName);
                      //        this.nodata = false;
                      //      }
                      //    },
                      //    (error) => {
                      //      this.nodata = true;
                      //    }
                      //  );
                      // }
                  
                      //     if ((this.projectId == 0 || this.projectId == undefined) && this.selectedemployeeId!==undefined) {
                      //       // if (
                      //       //   this.selectedemployeeId!== 0 ||
                      //       //   this.selectedemployeeId!== undefined
                      //       // ) {
                      //         console.log("if is calling here....");
                  
                      //         this.gs.getSubmittedTimesheetByEmployeeId(this.timesheetDate,this.employeeId).subscribe(
                      //           (data: any) => {
                      //             console.log(data);
                      //             this.timesheetreport = data;
                      //             this.nodata = false;
                      //           },
                      //           (error) => {
                      //             this.nodata = true;
                      //           }
                      //         );
                      //       // }
                      //     }
                      //      else if (
                      //        this.projectId !== undefined &&
                      //        this.employeeId!== undefined &&
                      //        this.timesheetDate
                      //      ) {
                      //        this.gs
                      //          .getSubmittedTimesheetByEmployeeIdAngProjectId(
                      //            this.timesheetDate,
                      //            this.employeeId,
                      //            this.projectId
                      //          )
                      //          .subscribe(
                      //            (data: any) => {
                      //              console.log(data);
                      //              this.timesheetreport = data;
                      //              if (this.timesheetreport.length == 0) {
                      //                this.nodata = true;
                      //              } else {
                      //                console.log(this.timesheetreport.employeeName);
                      //                this.nodata = false;
                      //              }
                      //            },
                      //            (error) => {
                      //              this.nodata = true;
                      //            }
                      //          );
                      //      }
                    }
                      resetForms() {
                        // this.employeeNotFound =  false;
                        // this.isbuttondisable=false
                        // this.employee=undefined

              // this.employeeProjects=[]
              // this.timesheetreport = [];
                        // this.employeeNotFound = false;
                        // this.employeeProjects =  [];
                        // this.selectedemployeeId = undefined;
                          // this.projectId= 0;
                          // this.selectedemployee =  '';
                          // this.timesheetDate  = undefined;
                          // this.showEmptyData = true;
                   
                          // this.getEmployeeProjectReport()
                          // this.dateInput.reset();
                          // this.dateInput.control.setErrors(null);
                          // this.timesheetDate = null;
                          this.date_required=false
                          // this.nodata=false
                          // this.employeeProjects.length=0
                          // this.tablehide=false
                          // this.employeeId=null

                          console.log("Project:" , this.projectId )
                          console.log("employe",this.employee) 
                          console.log("date",this.timesheetDate);
              
                          


                          if((this.projectId==undefined || this.projectId==0) && (this.employee==undefined || this.employee==null) && (this.timesheetDate==undefined || this.timesheetDate=='')){
                            this.loader=false
                            console.log("if came!!!!");
                            return
                            

                          }
                          else{
                            console.log("else came!!");
                            if (
                              this.projectId !== 0 ||
                              this.projectId !== undefined ||
                              this.employee !== undefined ||
                              this.employee !== null ||
                              this.timesheetDate !== undefined ||
                              this.timesheetDate !== null 
                            
                            ) {
                              console.log("first condition");
                            if (
                              this.projectId != 0||
                              this.employee != undefined ||
                              this.timesheetDate != undefined 
                      
                            ) {
                              console.log("second condition");
                      
                              if (
                                this.projectId != undefined ||
                                this.employee != undefined ||
                                this.timesheetDate != undefined 
                      
                              ) {
                                console.log("third condition");
                      
                                console.log('Form is valid');
                       
                            this.loader = true;
                            this.nodata = false;
                            this.tableShow = false;
                            this.employeeNotFound =  false;
                            this.isbuttondisable=false
                            this.employee=undefined
    
                  this.employeeProjects=[]
                  this.timesheetreport = [];
                            this.employeeNotFound = false;
                            this.employeeProjects =  [];
                            this.selectedemployeeId = undefined;
                              this.projectId= 0;
                              this.selectedemployee =  '';
                              this.timesheetDate  = undefined;
                              this.showEmptyData = true;
                       
                              this.dateInput.reset();
                              // this.dateInput.control.setErrors(null);
                              // this.timesheetDate = null;
                              this.date_required=false
                              this.nodata=false
                              this.employeeProjects.length=0
                              this.tablehide=false
                              this.employeeId=null
    


                          this.gs.getTaskReportByEmpolyee(this.myFormattedDate).subscribe(
                            (tp: any) => {
                              this.loader = false;
                              this.timesheetreport = tp;
                              this.tableShow = true;
                              console.log("time sheet report emp_list::",this.timesheetreport);
                              this.timesheetreport.forEach((employee:any) => {
                                // console.log("Employee ID:", employee.employee.employeeId);
                                console.log("Timesheet:", employee.employee.timesheet);
                              
                                // Now, if you want to further iterate over the timesheet object
                            
                              });
                      
                                      
                              this.timesheetReport.forEach((project:any)=>{
                                this.employeeIds=project.employeeId;
                                console.log("employeeids::",this.employeeIds);
                                
                              })
                              if (this.timesheetreport.length == 0) {
                                this.nodata = true;
                                this.tableShow = true;
                                console.log("nodata");
                              }
                            },
                            (error) => {
                              this.nodata = true;
                              this.tableShow = true;
                              this.loader = false;
                              console.log("error");
                              if (error.status == 404) {
                                this.nodata = true;
                                console.log("404");
                              }
                            }
                          );
                        }
                      }
                    }
                  }
               }
                

                     sortData(sort: Sort) {
                      const data = this.timesheetreport.slice();
                      if (!sort.active || sort.direction === "") {
                        this.timesheetreport = data;
                        return;
                      }
                  
                      this.timesheetreport = data.sort((a, b) => {
                        const isAsc = sort.direction === "asc";
                        switch (sort.active) {
                          case "employeeName":
                            return this.compare(a.employeeName, b.employeeName, isAsc);
                          case "organizationEmail":
                            return this.compare(a.organizationEmail, b.organizationEmail, isAsc);
                          // case 'timesheet':
                          //   return this.compare(a.date, b.date, isAsc);
                          default:
                            return 0;
                        }
                      });
                    }   
                    compare(a: number | string, b: number | string, isAsc: boolean) {
                      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
                    }      

//   empSelect(emp) {
//     console.log('emp',emp);
//     this.employeeId = emp.employeeId;
//     this.employee = emp.employee.employeeNumber + '-' + emp.employee.firstName;
//     this.employeeNumber = emp.employeeNumber;

//     this.selectedemployeeId=this.employee

//     // this.assignformbyemployee.patchValue({
//     //   // employeecode: this.employee,
//     // });
// console.log("employee Id after selecting::",this.employeeId);

//     console.log('this.employee::',this.selectedemployeeId);
//     console.log("wtts id tell me?::",this.employeeId);
//     this.getTask()
    
//   }
// noEmployeeFound() {
//   this.selectedemployeeId = undefined;
//   this.selectedemployeeId = undefined; // Set selectedemployeeId to null
//   this.employeeId = undefined;
//   this.employeeProjects.length = 0;
//   this.employee = undefined;
//   this.employeeNumber = undefined;
//   this.selectedemployee = undefined;
//   this.selectedemployeeId = undefined;
// }
      
empSelect(emp) {
  console.log('emp', emp);
  // this.employeeProjects = [];
  // this.selectedemployeeId = undefined; // Set selectedemployeeId to null
  // this.employeeId = undefined;
  // this.employeeProjects.length = 0;
  // this.employee = undefined;
  // this.employeeNumber = undefined;
  // this.selectedemployee = undefined;
  // this.selectedemployeeId = undefined;
  // if (
  //   this.projectId === 0 ||
  //   this.projectId === undefined ||
  //   this.projectId === null
  // ) {
  //   this.employeeId = emp.employeeId;
  //   this.employee = emp.employeeNumber + '-' + emp.firstName;
  //   this.employeeNumber = emp.employeeNumber;
  //   this.selectedemployee = this.employee;
  //   this.selectedemployeeId = this.employeeId;
  // }

  // if (this.projectId > 0) {
  this.employeeId = emp.employee.employeeId;
  this.employee = emp.employee.employeeNumber + '-' + emp.employee.firstName;
  this.employeeNumber = emp.employeeNumber;
  this.selectedemployee =  emp.employee.employeeNumber + '-' + emp.employee.firstName;
  // this.selectedemployeeId =  emp.employee.employeeNumber + '-' + emp.employee.firstName;
  
  this.selectedemployeeId = emp.employee.employeeId;
  
  // }

  // this.selectedemployeeId =  this.employee;
  // this.selectedemployee =  this.employee;
  // this.assignformbyemployee.patchValue({
  //   employeecode: this.employee,
  // });
  //  let  employeecode=this.selectedemployeeId;

  // console.log('this.employee', employeecode);
}

printPdf() {
  const pdfTable = this.pdfTable.nativeElement;
  console.log(pdfTable.innerHTML);
  

  var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
  console.log(pdfTable.innerHTML);
  

  const documentDefinition = {
    content: html,
    pageMargins: [25, 40, 25, 40],
    info: {
      title: `Daiy Timesheet Report`
    },
    background: function (currentPage, pageCount) {
      console.log('currentPage:', currentPage);
      console.log('pageCount:', pageCount);

      return {
        text: 'Page ' + currentPage.toString(),
        alignment: 'center',
        margin: [0, 8],
        fontSize: 10,
      };
    },
    pageSize: 'A4',
    pageOrientation: 'landscape'
  };
  console.log(pdfTable);
  pdfMake.createPdf(documentDefinition).download("Daiy Timesheet Report");
}
    

}
