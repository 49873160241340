import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuperadminService } from '../../../providers/superadmin.service';

@Component({
  selector: 'app-add-date-format',
  templateUrl: './add-date-format.component.html',
  styleUrls: ['./add-date-format.component.scss']
})
export class AddDateFormatComponent implements OnInit 
{
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  dateFormatNameExistStatus :any;
  updatedateFormatNameExistStatus :any;
  modulesLov :any;
  dateFormatId :any;

  constructor(
    private superadminservice: SuperadminService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddDateFormatComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void 
  {
    this.postForm = this.formBuilder.group({
      dateFormatName : ['',[Validators.required]],
      dateFormatDescription : [''],
    });

    if(this.edit_data)
    {
      this.dateFormatId = this.edit_data.dateFormatId;


      this.superadminservice.getDateformatById(this.edit_data.dateFormatId).subscribe((resultData:any) =>
      {
        console.log(resultData);
        this.resultData=resultData;
        
        this.postForm.patchValue({
          dateFormatName: this.resultData.dateFormatName,
          dateFormatDescription: this.resultData.dateFormatDescription,
        });
      });
    }
  }

  get validate() { return this.postForm.controls; }

  onSubmit() //form Submit Function
  {
    this.submitted = true;

    if(this.edit_data) //Edit
    {
      if (this.postForm.invalid || this.dateFormatNameExistStatus == true)
      {
        return;
      }

      //let postValues = this.postForm.value;

      var postValues = {
        dateFormatName:this.postForm.value.dateFormatName,
        dateFormatDescription:this.postForm.value.dateFormatDescription,
      }

      postValues['dateFormatId'] = this.edit_data.dateFormatId;
      postValues['status'] = this.edit_data.status;

      this.backdrop = true;
      this.loader=true;

      /* if(this.postForm.valid && this.updateroleNameExistStatus == true)
      {
         return
      } */
      

      this.superadminservice.editDateFormat(this.edit_data.dateFormatId,postValues).subscribe((resultData: any) =>
      {
        //this.router.navigate([ '/home/designation' ]);
        //window.location.reload();
        console.log(resultData);
        this.backdrop = false;
        this.loader = false;
        this.success = true;
        this.sucess_msg = resultData.description;

        this.dialogref.close(resultData);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    else //Create
    {
      if (this.postForm.invalid || this.dateFormatNameExistStatus == true)
      {
        return;
      }

      var postValues = {
        dateFormatName:this.postForm.value.dateFormatName,
        dateFormatDescription:this.postForm.value.dateFormatDescription,
      }
    
      this.superadminservice.createDateFormat(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>
      {
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
  }

  checkExistDateFormatName(val)
  {
    let editData = 0;
   
    if(this.postForm.value.dateFormatName.length != 0)
    {
      if(this.edit_data)
      {
        var postValues = {
          dateFormatName:this.postForm.value.dateFormatName,
          dateFormatDescription:this.postForm.value.dateFormatDescription,
        }
  
        postValues['dateFormatId'] = this.edit_data.dateFormatId;
        postValues['dateFormatStatus'] = this.edit_data.dateFormatStatus;
        
        
        this.superadminservice.updateCheckExistDateFormat(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.dateFormatNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.dateFormatNameExistStatus = true;
          }
        })
      }
      else
      {
        var postValues = {
          dateFormatName:this.postForm.value.dateFormatName,
          dateFormatDescription:this.postForm.value.dateFormatDescription,
        }
        
        this.superadminservice.createCheckExistDateFormatName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.dateFormatNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.dateFormatNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.dateFormatNameExistStatus = false;
    }
  }
}
