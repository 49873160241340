import { Component, Inject, OnInit } from '@angular/core';
import { ShiftemployeeComponent } from '../shift/shiftemployee/shiftemployee.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-show-error-of-workingdays',
  templateUrl: './show-error-of-workingdays.component.html',
  styleUrls: ['./show-error-of-workingdays.component.scss']
})
export class ShowErrorOfWorkingdaysComponent implements OnInit {

  p:number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=5;
  data: any;
 search: any;

  constructor(@Inject(MAT_DIALOG_DATA) public resultData : any , private dialogref : MatDialogRef<ShiftemployeeComponent> ) { }

  ngOnInit(): void {
    // let data=this.resultData;
    this.resultData ;
    console.log('resultData',this.resultData);
     console.log(this.data);
    //   this.resultData = [
    //     {
    //         "statusCode": 409,
    //         "description": "EmployeeClient Alredy Assing StartDate 2023-06-26 EndDate 2023-06-27",
    //         "employeeNumber": "1041",
    //         "firstLogin": false,
    //         "createdBy": 0,
    //         "lastUpdatedBy": 0
    //     }
    // ]
  }

  closedialog()
  {
     this.dialogref.close();
  }
}
