<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class=""><span class="h5 sub-content">Settings</span></div>
                <!-- <div class="sub-header-buttons">
                    <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                    <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                        Add Employee
                    </a>
                </div>-->
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="settings-section">
                                    <div class="settings-list1">
                                        <span class="to-do-header">System Settings</span>
                                    </div>

                                    <!-- <div class="settings-list" routerLink="/home/list-types" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/10.png" alt="">
                                        <span class="settings-title">List of Values</span>
                                        <span class="settings-sub-title">Manage all list of values.</span>
                                    </div> -->

                                    <div class="settings-list" routerLink="/home/user-role" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/2.png" alt="">
                                        <span class="settings-title">User Roles</span>
                                        <span class="settings-sub-title">Manage all user roles.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/branch" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/21.png" alt="">
                                        <span class="settings-title">Branch</span>
                                        <span class="settings-sub-title">Manage all Branchs.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/department" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/1.png" alt="">
                                        <span class="settings-title">Departments</span>
                                        <span class="settings-sub-title">Manage all departments.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/designation" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/6.png" alt="">
                                        <span class="settings-title">Designations</span>
                                        <span class="settings-sub-title">Manage all designations.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/qualification" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/11.png" alt="">
                                        <span class="settings-title">Stream</span>
                                        <span class="settings-sub-title">Manage all Qualification Stream.</span>
                                    </div>
                                    <div class="settings-list" routerLink="/home/financialyear" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/103.png" alt="">
                                        <span class="settings-title"> Financial Calendar</span>
                                        <span class="settings-sub-title">Manage all financial Year and month .</span>
                                    </div>
                                    <div class="settings-list" routerLink="/home/variablepayqualifier" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/101.png" alt="">
                                        <span class="settings-title"> Variable Pay Qualifier</span>
                                        <span class="settings-sub-title">Manage all  variable pay qualifiers .</span>
                                    <div class="settings-list" routerLink="/home/element-percentage" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/104.png" alt="">
                                        <span class="settings-title"> Element Percentage</span>
                                        <span class="settings-sub-title">Manage all percentages of elements</span>
                                    </div>
                                    <div class="settings-list" routerLink="/home/manage-work-hours" routerLinkActive="active" *ngIf="setOrgLogin">
                                        <img class="settings-icon" src="assets/uploads/icons/105.png" alt="">
                                        <span class="settings-title"> Manage Work Hours</span>
                                        <span class="settings-sub-title">Manage Work Hours</span>
                                    </div>
                                    <!-- <div class="settings-list">
                                        <img class="settings-icon" src="assets/uploads/icons/91.png" alt="">
                                        <span class="settings-title">Positions</span>
                                        <span class="settings-sub-title">Manage all positions.</span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <!-- <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="settings-section">
                            <div class="settings-list1">
                                <span class="to-do-header">Application Settings</span>
                            </div> -->

                            <!-- <div class="settings-list">
                                <img class="settings-icon" src="assets/uploads/icons/country.png" alt="">
                                <span class="settings-title">Company Settings</span>
                                <span class="settings-sub-title">A user profile is a visual display of personal data associated with a specific user, or a customized desktop environment.</span>
                            </div>

                            <div class="settings-list">
                                <img class="settings-icon" src="assets/uploads/icons/state.png" alt="">
                                <span class="settings-title">Email & Contact Settings</span>
                                <span class="settings-sub-title">A user profile is a visual display of personal data associated with a specific user, or a customized desktop environment.</span>
                            </div>

                            <div class="settings-list">
                                <img class="settings-icon" src="assets/uploads/icons/100.png" alt="">
                                <span class="settings-title">Mailer Settings</span>
                                <span class="settings-sub-title"> A user profile is a visual display of personal data associated with a specific user, or a customized desktop environment.</span>
                            </div>

                            <div class="settings-list">
                                <img class="settings-icon" src="assets/uploads/icons/31.png" alt="">
                                <span class="settings-title">Image Settings</span>
                                <span class="settings-sub-title">A user profile is a visual display of personal data associated with a specific user, or a customized desktop environment.</span>
                            </div>

                            <div class="settings-list">
                                <img class="settings-icon" src="assets/uploads/icons/20.png" alt="">
                                <span class="settings-title">Currency Settings</span>
                                <span class="settings-sub-title">A user profile is a visual display of personal data associated with a specific user, or a customized desktop environment.</span>
                            </div> -->

                            <!-- <div class="settings-list" routerLink="/home/application-settings" routerLinkActive="active" >
                                <img class="settings-icon" src="assets/uploads/icons/90.png" alt="">
                                <span class="settings-title">App System Settings</span>
                                <span class="settings-sub-title">Manage application settings</span>
                            </div>

                            <div class="settings-list" routerLink="/home/company-settings" routerLinkActive="active" >
                                <img class="settings-icon" src="assets/uploads/icons/90.png" alt="">
                                <span class="settings-title">Company Settings</span>
                                <span class="settings-sub-title">A user profile is a visual display of personal data associated with a specific user, or a customized desktop environment.</span>
                            </div>

                        </div>
                    </div>
                </div> -->

                <!-- <div class="card mt-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="settings-section">
                                    <div class="settings-list1">
                                        <span class="to-do-header">Locations</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/country" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/country.png" alt="">
                                        <span class="settings-title">Countries</span>
                                        <span class="settings-sub-title">Manage all countries.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/state" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/state.png" alt="">
                                        <span class="settings-title">States</span>
                                        <span class="settings-sub-title">Manage all states.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/district" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/city.png" alt="">
                                        <span class="settings-title">District</span>
                                        <span class="settings-sub-title">Manage all districts.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/home/city" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/94.png" alt="">
                                        <span class="settings-title">Cities</span>
                                        <span class="settings-sub-title">Manage all cities.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

            <!-- </div> -->

            
        </div>
    </div>
</div>