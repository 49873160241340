<div
  style="margin-top: 80px; margin-left: 10px; margin-right: 10px"
  class="card card-content"
  [ngClass]="{'min-height': selectedType === 'organization' || selectedType === 'client'}"
>
  <h4 class="heading-tag">Manage Elements</h4>
  <div class="row justify-content-between">
    <div class="col-md-4">
      <div id="loginform">
        <div class="col-md-12 col-xl-6 select">
          <label class="form-control-label">
            Select&nbsp;Organization&nbsp;/&nbsp;Client
          </label>
          <mat-select
            (selectionChange)="selected($event)"
            [(value)]="selectedType"
            [disabled]="organizationLogin || clientLogin"
            class="form-control email-input assign-placeholder"
            placeholder="Select Organization/Client"
          >
            <mat-option
            *ngIf="!organizationLogin && !clientLogin"
              [value]="undefined"
              (click)="clearAllForm()"
            >
              -Select Organization/Client-
            </mat-option>
            <mat-option [value]="'organization'"> Organization </mat-option>
            <mat-option [value]="'client'" > Client </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedType === 'organization'" class="mt-3">

    <app-orgnization-manage-elements></app-orgnization-manage-elements>

  </div>

  <div *ngIf="selectedType === 'client'" class="mt-3 client">

    <app-manage-elements-list></app-manage-elements-list>
  </div>
</div>
