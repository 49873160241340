import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { SettingsService } from 'src/app/providers/settings.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake  from "html-to-pdfmake";
import { DatePipe } from '@angular/common';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-employee-payslip',
  templateUrl: './employee-payslip.component.html',
  styleUrls: ['./employee-payslip.component.scss']
})
export class EmployeePayslipComponent implements OnInit {
  showtable:boolean=false;
  loader:any= false;
  isProcessing = false;

 p: number = 1;
  pagePerItem:any=10;
  mySelect:any =5;
  filterForm:FormGroup;
  finYearList: any =[];
  finPeriodList: any=[];
  submitted: boolean=false;
  noRecordsFound: boolean =false;
  ManagePayslipdata: any=[];
  activeDesignationData: any=[];
  organzationId: any;
  clientId:any;
  userdata: any;
  financialyearError: boolean=false;
  organizationSettings: any;
  dateFormat : any;
  @ViewChild('pdfTable')pdfTable!: ElementRef;
  payrolldata: any;
  CTC=0;
  Amount=0;
  EarningYTD=0;
  DeductionYTD=0;
  employeeId: any;
  employeeNumber: any;
  chooseFinId: any;
  
  constructor(
    private settingsService: SettingsService,
    public fb:FormBuilder,
    private gs:GlobalserviceService,
    private Employeeservice: EmployeeService,
    @Inject(AppComponent) public AppData: any,
    private datepipe  : DatePipe,
    public router:Router
  ) { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =  this.userdata.organization.organizationId;
    this.employeeId = this.userdata.employee ? this.userdata.employee.employeeId : 0;
    this.employeeNumber = this.userdata.employee ? this.userdata.employee.employeeNumber: "";
    console.log("organziationid " + this.organzationId);

    this.clientId =  this.userdata?.employee?.client?.clientId;
    console.log("clientId " + this.clientId);


    this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;

    if(this.organzationId){
    this.settingsService.getFinicalyearActiveList(this.organzationId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }
  if(this.clientId){
    this.settingsService.getFinicalyearActiveListClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }


    // this.Employeeservice.getActiveDesignation().subscribe((result: any) => {
    //   this.activeDesignationData = result;
    //   console.log(this.activeDesignationData);
    // })
    if(this.organzationId){
      this.Employeeservice.getActiveOrgDesignation(this.organzationId).subscribe((result: any) => {
        this.activeDesignationData = result;
        console.log(this.activeDesignationData);
      })
    }
    if(this.clientId){
      this.Employeeservice.getActiveClientDesignation(this.clientId).subscribe((result: any) => {
        this.activeDesignationData = result;
        console.log(this.activeDesignationData);
      })
    }


    if(this.organzationId){
    this.gs.getOrganizationdetails(this.organzationId).subscribe(
      (data:any)=>{
        this.organizationSettings=data;
        console.log("this.organizationSettings",this.organizationSettings);
      })
    }

    if(this.clientId){
      this.gs.getClientdetails(this.clientId).subscribe(
        (data:any)=>{
          this.organizationSettings=data;
          console.log("this.organizationSettings",this.organizationSettings);
        })
      }



    this.filterForm=this.fb.group({
      designation : [null,Validators.required],
      financialyear : [null,Validators.required],
      period: [null,Validators.required]
    });
  }


  searchSubmit(){
    this.loader=true;
    this.isProcessing=true;

    console.log(this.filterForm.value);
    if((this.filterForm.value.employee == null || this.filterForm.value.employee == '')
      && (this.filterForm.value.designation == null || this.filterForm.value.designation == 'null')
      && (this.filterForm.value.period == null || this.filterForm.value.period == 'null')
      && (this.filterForm.value.financialyear == null || this.filterForm.value.financialyear == 'null')){
        this.loader=false;
        this.isProcessing=false;
        alert("Please select Any Field to Search");
        return
    }
    this.CTC=0;
    this.Amount=0;
    this.EarningYTD=0;
    this.DeductionYTD=0;
  
    if( (this.filterForm.value.period == null || this.filterForm.value.period == 'null')
    && (this.filterForm.value.financialyear != null || this.filterForm.value.financialyear != 'null')){

      if(this.organzationId){
      this.gs.getManagePayslipByEmployeeIdAndFinancialyearOrg(this.filterForm.value.financialyear,this.employeeNumber,this.organzationId).subscribe(
        (data:any)=>{
          this.showtable=true;
          this.isProcessing=false;
          this.loader=false;
          console.log(data);
          if(data.statusCode == 204){
            this.ManagePayslipdata =[];
            this.noRecordsFound = true;
          }
          else{
            this.ManagePayslipdata=data;
            this.noRecordsFound = false;
            for(let j=0;j<this.ManagePayslipdata.length;j++){   
              this.CTC  += this.ManagePayslipdata[j].ctc;
              console.log(" this.CTC", this.CTC);
              this.Amount  += this.ManagePayslipdata[j].amount;
              this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
              this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
            }
          }
        }
      )
    }
    if(this.clientId){
      this.gs.getManagePayslipByEmployeeIdAndFinancialyearClient(this.filterForm.value.financialyear,this.employeeNumber,this.clientId).subscribe(
        (data:any)=>{
          this.showtable=true;
          this.isProcessing=false;
          this.loader=false;
          console.log(data);
          if(data.statusCode == 204){
            this.ManagePayslipdata =[];
            this.noRecordsFound = true;
          }
          else{
            this.ManagePayslipdata=data;
            this.noRecordsFound = false;
            for(let j=0;j<this.ManagePayslipdata.length;j++){   
              this.CTC  += this.ManagePayslipdata[j].ctc;
              console.log(" this.CTC", this.CTC);
              this.Amount  += this.ManagePayslipdata[j].amount;
              this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
              this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
            }
          }
        }
      )
    }
      } else if((this.filterForm.value.period != null || this.filterForm.value.period != 'null')
      && (this.filterForm.value.financialyear != null || this.filterForm.value.financialyear != 'null')){

        if(this.organzationId){
        this.gs.getManagePayslipByEmployeeIdAndFinancialperiodOrg(this.filterForm.value.financialyear,this.filterForm.value.period,this.employeeNumber,this.organzationId).subscribe(
          (data:any)=>{
            this.showtable=true;
            this.isProcessing=false;
            this.loader=false;
            console.log(data);
            if(data.statusCode == 204){
              this.ManagePayslipdata =[];
              this.noRecordsFound = true;
            }
            else{
              this.ManagePayslipdata=data;
              this.noRecordsFound = false;
              for(let j=0;j<this.ManagePayslipdata.length;j++){   
                this.CTC  += this.ManagePayslipdata[j].ctc;
                console.log(" this.CTC", this.CTC);
                this.Amount  += this.ManagePayslipdata[j].amount;
                this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
              }
            }
          }
        )
      }

      if(this.clientId){
        this.gs.getManagePayslipByEmployeeIdAndFinancialperiodClient(this.filterForm.value.financialyear,this.filterForm.value.period,this.employeeNumber,this.clientId).subscribe(
          (data:any)=>{
            this.showtable=true;
            this.isProcessing=false;
            this.loader=false;
            console.log(data);
            if(data.statusCode == 204){
              this.ManagePayslipdata =[];
              this.noRecordsFound = true;
            }
            else{
              this.ManagePayslipdata=data;
              this.noRecordsFound = false;
              for(let j=0;j<this.ManagePayslipdata.length;j++){   
                this.CTC  += this.ManagePayslipdata[j].ctc;
                console.log(" this.CTC", this.CTC);
                this.Amount  += this.ManagePayslipdata[j].amount;
                this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
              }
            }
          }
        )
      }
        }
        
  }

  


 

  // searchSubmit(){
  //   console.log(this.filterForm.value);
  //   if((this.filterForm.value.employee == null || this.filterForm.value.employee == '')
  //     && (this.filterForm.value.designation == null || this.filterForm.value.designation == 'null')
  //     && (this.filterForm.value.period == null || this.filterForm.value.period == 'null')
  //     && (this.filterForm.value.financialyear == null || this.filterForm.value.financialyear == 'null')){
  //       alert("Please select fields");
  //       return
  //   }
  //   else{
  //     if((this.filterForm.value.employee == null || this.filterForm.value.employee == '')){
  //       if(this.filterForm.value.period == null || this.filterForm.value.period == 'null'){
  //         console.log("designation B");
  //         this.gs.getManagePayslipByDesignation(this.filterForm.value.designation).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               console.log("designation B if");
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               console.log("designation B else");
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }
  //       else if(this.filterForm.value.designation == null || this.filterForm.value.designation == 'null'){
  //         console.log("period c");
  //         this.gs.getManagePayslipByFinancialperiod(this.filterForm.value.financialyear,this.filterForm.value.period).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }
  //       else{
  //         console.log("designation & period BC");
  //         this.gs.getManagePayslipByFinancialperiodAndDesignation(this.filterForm.value.financialyear,this.filterForm.value.period,this.filterForm.value.designation).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }
  //     }
  //     else if(this.filterForm.value.designation == null || this.filterForm.value.designation == 'null'){
  //       if(this.filterForm.value.period == null || this.filterForm.value.period == 'null'){
  //         console.log("employee A");
  //         this.gs.getManagePayslipByEmployeeId(this.filterForm.value.employee).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }
  //       else{
  //         console.log("employee & period AC");
  //         this.gs.getManagePayslipByEmployeeIdAndFinancialperiod(this.filterForm.value.financialyear,this.filterForm.value.period,this.filterForm.value.employee).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }
  //     } 
  //     else if(this.filterForm.value.period == null || this.filterForm.value.period == 'null'){
  //       if((this.filterForm.value.employee == null || this.filterForm.value.employee == '')){
  //         console.log("designation B");
  //         this.gs.getManagePayslipByDesignation(this.filterForm.value.designation).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }
  //       else if(this.filterForm.value.designation == null || this.filterForm.value.designation == 'null'){
  //         console.log("period A");
  //         this.gs.getManagePayslipByEmployeeId(this.filterForm.value.employee).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }
  //       else{
  //         console.log("designation & employee AB");
  //         this.gs.getManagePayslipByEmployeeIdAndDesignation(this.filterForm.value.employee,this.filterForm.value.designation).subscribe(
  //           (data:any)=>{
  //             console.log(data);
  //             if(data.statusCode == 204){
  //               this.ManagePayslipdata =[];
  //               this.noRecordsFound = true;
  //             }
  //             else{
  //               this.ManagePayslipdata=data;
  //               this.noRecordsFound = false;
  //             }
  //           }
  //         )
  //       }  
  //     }
  //     else{
  //       console.log(" ALL ABC");
  //       this.gs.getManagePayslipByEmployeeIdAndFinancialperiodAndDesignation(this.filterForm.value.financialyear,this.filterForm.value.period,this.filterForm.value.employee,this.filterForm.value.designation).subscribe(
  //         (data:any)=>{
  //           console.log(data);
  //           if(data.statusCode == 204){
  //             this.ManagePayslipdata =[];
  //             this.noRecordsFound = true;
  //           }
  //           else{
  //             this.ManagePayslipdata = data;
  //             this.noRecordsFound = false;
  //           }
  //         }
  //       )
  //     }
  //   }
  // }
  get f() {
    return this.filterForm.controls;
  }
  selectFinancialyear(){
    // console.log(event.target.value);
    this.filterForm.get('financialyear').value;
    this.chooseFinId=this.filterForm.get('financialyear').value;

    this.filterForm.value.period = null;
    this.filterForm.patchValue({
      period:null
    })
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe(
      (resultData: any) => {
        console.log("PeriodData", resultData);
        this.finPeriodList = resultData.finanicalPeriod;
        this.financialyearError=false;
      }
    );
  }
  viewpayslip(payrollHeaderId){
    this.router.navigateByUrl(`/home/viewemppayslip/${payrollHeaderId}`);
  }
  public downloadAsPDF(payrollHeaderId) {
    console.log("download",this.pdfTable);
  
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize:true,
    });
    this.gs.getManagePayslipById(payrollHeaderId).subscribe(
      (data:any)=>{
        console.log(data);
        this.payrolldata=data;
        const formattedDate = this.datepipe.transform(this.payrolldata.createDateTime, this.dateFormat);
        const documentDefinition = {
           content: html , 
           pageMargins: [ 40, 40, 40, 150],
            imagesByReference:true ,
           info: {
             title:`Report ${formattedDate}`
             },
    
             footer: function (currentPage, pageCount) {
              return {
                table: {
                  body: [
                    [ 
                      { 
                        text: 'Employee Signature', alignment: 'left', fontSize: 10,
                        margin: [40, 40, 0, 0]
                        // pageMargins: [50, 0, 0, 0]
                      },
                      { 
                        text: 'Date', alignment: 'right', fontSize: 10,
                        margin: [350, 40, 0, 0]
                        // pageMargins: [0, 0, 100, 0]
                      },
                    ],
                    [
                      // 'Approver Signature',
                      // { 
                      //   text: 'Date', alignment: 'right', fontSize: 9,
                      //   pageMargins: [700, 0, 300, 0]
                      // },
    
                      { 
                        text: 'Approver Signature', alignment: 'left', fontSize: 10,
                        margin: [40, 40, 0, 0]
                        // pageMargins: [50, 0, 0, 0]
                      },
                      { 
                        text: 'Date', alignment: 'right', fontSize: 10,
                        margin: [350, 40, 0, 0]
                        // pageMargins: [500, 0, 100, 0]
                      },
                    ],
                  ]
                },
                layout: {
                  defaultBorder: false,
                },
                // layout: 'noBorders'
              };
            },
            pageSize: 'A4',
            };
         console.log(documentDefinition);
        pdfMake.createPdf(documentDefinition).download(`Report ${formattedDate}.pdf`);     
      }
    )
  }
  showChanges(){
    console.log("show Change");
    this.p=1;
  }
}
