import { Component, Input, OnInit, ViewChild } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { AdminService } from 'src/app/providers/admin.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { UserService } from 'src/app/providers/user.service';
// import { base_url } from '../../providers/properties';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatboxmsgComponent } from 'src/app/chatboxmsg/chatboxmsg.component';
import { MatDialog } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload';
import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-admin-menus-home',
  templateUrl: './admin-menus-home.component.html',
  styleUrls: ['./admin-menus-home.component.scss'],
})
export class AdminMenusHomeComponent implements OnInit {


 
  resultData: any = {};
  resultDatarec: any;
  errorMsg: any;
  admindashboard: any;
  ProfileShared: number;
  Interviewed: any;
  Notinterviewed: number;
  recruiterSearchform: FormGroup;
  @Input() requiredError: boolean;
  reportfromdate: any;
  reporttodate: any;
  startmaxdate: Date = new Date();
  endmindate;
  recruiterlist: any;
  loader: any = false;
  backdrop: any = false;
  dataForExcel = [];
  true: any;
  interviewStatuslist: any;
  success: any = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  results: any = [];
  localStorageMenuId: any;
  moduleId: any;
  first_segment: any;
  usertype: any;
  menudata: any = [];
  moduledata: any = [];
showFeedSection: boolean = false;
element: any;
  userType: any;
  usertData: any;
  isAdmin: any;
  isEmployee:any;
  isRequirements: any;
  isHumanResource:any;
  isUserAdmin: boolean = false;
  constructor(
    private dashboardservice: RecruitmentService,
    private adminservice: AdminService,
    private superadminservice: SuperadminService,
    private UserService: UserService,
    private datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private headerservice : HeaderService


  ) {
    //console.log("routes");
    //console.log(activatedRoute.snapshot.url); // array of states
    if (activatedRoute.snapshot) {
      this.first_segment = activatedRoute.snapshot.url[0].path;
    }
  }

  ngOnInit(): void {
    this.headerservice.setTitle('')


        ClassicEditor.create( document.getElementById( '#editor' ), {
          plugins: [  /* ... */ ],
          toolbar: { items: [
            'heading',
            '|',
            'bold',
            'italic', 
            'link',
            '|',
            'undo',
            'redo'
          ]}
      } )
      .then(editor => {
        console.log('Editor was initialized', editor);
      })
      .catch(error => {
        // console.error(error.stack);
      });
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      this.userType = [];
      // this.usertData.employee.role.forEach((role) => {
      //   this.userType.push(role?.roleName);
      // });
      let OrganizationID = this.usertData ? this.usertData.organization.organizationId : 0;

      console.log(OrganizationID);
      if(this.usertData?.employee != undefined){
        if (this.usertData.employee.role != undefined) {
          // Populate userType array with roles
          this.usertData?.employee?.role?.forEach((role) => {
            this.userType.push(role?.roleName);
          });
          // Check if the user is an Admin or Recruiter
          this.isAdmin = this.userType.includes('Admin');
          this.isRequirements = this.userType.includes('Recruiter');
          this.isEmployee = this.userType.includes('Employee');
          this.isHumanResource = this.userType.includes('Human Resource');
        }
      }
      // else{
      //   if (this.usertData.role != undefined) {
      //     this.usertData?.role?.forEach((role) => {
      //       this.userType.push(role?.roleName);
      //     });
      //     // this.isUserAdmin = true;
      //   }
      // }
      console.log("this.userType",this.userType);
      console.log( "this.isAdmin", this.isAdmin);
      console.log( "this.isHumanResource ", this.isHumanResource);
      console.log( "this.isRequirements ", this.isRequirements);
      console.log( "this.isEmployee" , this.isEmployee);
   
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    if (this.usertype.employee) {
      // console.log("emp");
      if (this.usertype.employee.role) {
        this.usertype.employee.role.forEach((element) => {
          if (element.menu) {
            element.menu.forEach((mennuelement) => {
              let hasmenu = this.menudata.some(
                (m) => m.menuId == mennuelement.menuId
              );
              if (!hasmenu) {
                this.menudata.push(mennuelement);
                //   console.log( this.menudata);
              }
              //  console.log( "module length" + mennuelement.module.length);
              mennuelement.module.forEach((moduleelement) => {
                let moduledata = this.moduledata.some(
                  (m) => m.moduleId == moduleelement.moduleId
                );
                if (!moduledata) {
                  this.moduledata.push(moduleelement);
                }
              });
              //  console.log( "moduledata" +this.moduledata);
            });
          }
        });
      }
    } else {
      if (this.usertype.role) {
        console.log('userrole');
        this.usertype.role.forEach((element) => {
          if (element.menu) {
            element.menu.forEach((mennuelement) => {
              let hasmenu = this.menudata.some(
                (m) => m.menuId == mennuelement.menuId
              );
              if (!hasmenu) {
                this.menudata.push(mennuelement);
                //   console.log( this.menudata);
              }
              //  console.log( "module length" + mennuelement.module.length);
              mennuelement.module.forEach((moduleelement) => {
                let moduledata = this.moduledata.some(
                  (m) => m.moduleId == moduleelement.moduleId
                );
                if (!moduledata) {
                  if(moduleelement.moduleId == 1){
                    this.moduledata.push(moduleelement);
                  }
                }
              });
              //  console.log( "moduledata" ,this.moduledata);
            });
          }
        });
      }
    }
    this.getModulesList();
  }

  checkmoduledata(modulecode) {
    // console.log("modulecode" + modulecode);
    // console.log(this.moduledata);
    return this.moduledata.some((e) => e.moduleCode == modulecode);
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getModulesList() {
    /* Get Designation Data */
    this.superadminservice.getActiveModule().subscribe(
      (result: any) => {
        console.log("result",result);
        result.forEach((element) => {
          let hasmodule = this.moduledata.some(
            (mmd) => mmd.moduleId == element.moduleId
          );

          let hasremodules = this.results.some(
            (em) => em.moduleId == element.moduleId
          );
          if (hasmodule) {
            this.results.push(element);
          }
        });
         console.log("this.results",this.results);
        /*  this.router.navigate(['/home/admin-home']).then(() => {
        window.location.reload();
      }); */
      },
      (err) => {
        this.errorMsg = err.error.message;
        console.log(this.errorMsg);
      }
    );
  }

  selectModules(moduleCode) {
    localStorage.setItem('moduleCode', moduleCode);

    this.localStorageMenuId = localStorage.getItem('moduleCode');

    if (moduleCode == 'CHRMS') {
      //Core HRMS
      //this.router.navigate(['/home/dashboard']);

      this.router
        .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
        .then(() => {
          // this.router.navigate(['/home/dashboard']);
          this.router.navigate(['/home/business-list']);
        });
    } else if (moduleCode == 'RQMNT') {
      //Recruitment
      // if (this.usertype.role[0].roleName == 'Admin') {
        if (this.isAdmin) {
        this.router
          .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/home/admin-dashboard']);
          });
      } else {
        this.router
          .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/home/recruiter-dashboard']);
          });
      }
  
    }
    else if(moduleCode == 'PMS') //PMS
    {
      if (this.isAdmin) {
      this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home/pms-admin-dashboard']);
      });
      }
      else{
        this.router.navigate(['/home/appraisal']);
      }

    }
    else if(moduleCode == 'PAYROLL') //PAYROLL
    {
      if (this.isAdmin) {
        this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/home/working-days']);
        });
      }
      else{
        this.router.navigate(['/home/employee-payslip']);
      }
    }
    else if(moduleCode == 'LEAVE') //Leave
    {
      if (this.isAdmin || this.isHumanResource) {
        this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/home/leave-dashboard']);  
        });
      }
      else{
        this.router.navigate(['/home/emp-leave-dashboard']);
      }

    } 
  //   else if(moduleCode == 'LEAVE') //Leave
  //   {
      
  //     this.router.navigateByUrl('/refreshcomponent', { skipLocationChange: true }).then(() => {
  //       this.router.navigate(['/home/leave-dashboard']);
  //   });
  // }
  else if (moduleCode == 'PROJECTMANAGEMENT') {
    if (this.isAdmin) {
    this.router
      .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/home/project-management']);
      });
    }
    else{
      this.router.navigate(['/home/time-employee-dashboard']);
    }
  }
  else if (moduleCode == 'EXPENSEREIMBURSEMENT') {
    if (this.isAdmin) {
    this.router
      .navigateByUrl('/refreshcomponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/home/category']);
      });
    }
    else{
      this.router.navigate(['/home/category']);
    }
  }
    }
  showFeed(message: string, date: string) {
    // Perform any actions you need here, such as fetching data or updating variables
    console.log("Generating feed for announcement:", message, "Date:", date);
    
    // Show the feed section
    this.showFeedSection = true;
    }
    hideFeed() {
      this.showFeedSection = false;
  }
  viewchatbox(chatboxdata:any){
    console.log(chatboxdata);
    this.dialog.open(ChatboxmsgComponent,{
      width: '400px',
      height:'fit-content',
      data :chatboxdata,
      position: { top:'90px', bottom:'10px', right: '50px' } // Adjust the positioning as needed

    })
  }
      
  }

