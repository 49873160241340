import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ViewdescripeComponent } from '../viewdescripe/viewdescripe.component';
// import { MatDialog, Sort } from "@angular/material";
// import { GlobalserviceService } from "../providers/globalservice.service";
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
// import { log } from 'console';
// import { element } from "protractor";
import { TimeSheetComponent } from '../time-sheet/time-sheet.component';
import { DataSource } from '@angular/cdk/collections';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { Sort } from '@angular/material/sort';
import { HeaderService } from 'src/app/providers/header.service';
import {
  ReplaySubject,
  Subject,
  catchError,
  switchMap,
  take,
  takeUntil,
  throwError,
} from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { MatSelect } from '@angular/material/select';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-employee-task-report',
  templateUrl: './employee-task-report.component.html',
  styleUrls: ['./employee-task-report.component.scss'],
})
export class EmployeeTaskReportComponent implements OnInit {
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
  public employeeProjects: any = new ReplaySubject<any[]>(1);
  selectedEmployeeSearch: any;
  selectedemployee: any;
  employeeNotFound: boolean = false;
  checkingEmployee: number = 0;
  tableShow: boolean = false;
  employeeSelected: boolean = false;
  searchButtonDisable: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  projectId: any = undefined;
  projectList: any = [];
  taskList: any;
  beforesearchpage: any;
  projectStatus: any = '0';
  timesheetDate: any;
  p: any;
  user_type: string;
  search: any;
  userType: any;
  isData: boolean;
  taskId: number = 0;

  colon = ':';
  public timesheetreport: any[] = [];
  isproject: boolean;
  nodata: boolean = true;
  loader = false;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  employeelist: any = ([] = []);
  employeeId: any = undefined;
  employeeTimeSheetData: any[] = [];
  projectlist: any;
  selectedemployeeId: number;
  startDate = undefined;
  endDate = undefined;
  disable_project: boolean = true;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  tasktotalTime: any;
  today: any;
  employee_required: boolean = false;
  organizationId: any;
  organizationName:any;
  orgAddress:any;
  empId: any;
  project_required: boolean = false;
  orgemployeedata: any;
  taskstatus: any;
  noDataMessage: any;
  // employeeProjects: any[] = [];
  // employeeProjects:any = new ReplaySubject<any[]>(1);

  employee: string = undefined;
  employeeNumber: any;
  // selectedemployee: any = undefined;
  // selectedEmployeeSearch = new FormControl('');
  // selectedEmployeeSearch = new FormControl();
  // selectedemployee = new FormControl();

  searchEmployeePlace: any = '';
  // public bankFilterCtrl: FormControl<string> = new FormControl<string>('');
  // selectedEmployeeSearch:any = undefined;
  // selectedemployee = new FormControl('');
  // public bankFilterCtrl: FormControl<string> = new FormControl<string>('');
  showEmptyData: boolean = false;
  endSelectedFirst: boolean;
  checkEmployeeIds: any[] = [];
  // public filteredEmployees: ReplaySubject<any> = new ReplaySubject<any>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();

  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;

  constructor(
    private gs: ProjectManagementService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService,
    private renderer: Renderer2
  ) {}

  removePlaceholder(): void {
    if (this.inputElement && this.inputElement.nativeElement) {
      this.renderer.removeAttribute(
        this.inputElement.nativeElement,
        'placeholder'
      );
    }
  }

  ngOnInit(): void {
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage('');
    this.timesheet.setSuccessUpdateMessage('');
    // this.headerservice.setTitle('Employee Task Report');
    const data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = data.employee.employeeId;
    this.organizationId = data.organization.organizationId;
    console.log('organzationid' + this.organizationId);

    this.organizationName=  data.organization.organizationName;
    this.orgAddress=  data.organization.address;

    this.today = new Date();
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');

    console.log(' this.empId', this.empId);

    // this.gs.getResource().subscribe(
    //   (d: any) => {
    //     //this.loader = false;
    //     this.employeelist = d;
    //   },
    //   (error) => {
    //     //  this.loader = false;
    //     //  this.nodata = true;
    //     console.log("error");
    //     // if(error.status == 404){
    //     //   this.nodata = true;
    //     //   console.log("404")
    //     // }
    //   }
    // );

    // this.gs.listProjectList(this.organizationId).subscribe((data: any) => {
    //   console.log('data', data);

    //   console.log('data', data[0].projectId);
    //   this.projectList.push(data);
    //   this.projectList = data;
    //   this.projectlist = data;

    //   console.log('this.projectlist', this.projectlist[0].projectName);
    // });

    this.gs.getAllProjectNames(this.organizationId).subscribe((data: any) => {
      console.log('data', data);

      console.log('data', data[0].projectId);
      // this.projectList.push(data);
      this.projectList = data;
      this.projectlist = data;

      console.log('this.projectlist', this.projectlist[0].projectName);
    });
    this.gs.getResource().subscribe((data: any) => {
      console.log('data', data);

      // Convert data to an array if it's not already an array
      if (!Array.isArray(data)) {
        data = [data];
      }

      this.orgemployeedata = data;

      data.forEach((element) => {
        let employee = {
          employee: {
            employeeId: element.employeeId,
            employeeName: element.employeeName,
          },
        };

        this.employeelist.push(employee);
      });

      console.log(this.employeelist);
    });
  }
  ngAfterViewInit() {
    this.setInitialValue();

    this.removePlaceholder();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.employeeProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  protected filterBanks() {
    if (!this.employee) {
      return;
    }
    // get the search keyword
    let search = this.selectedemployee.value;
    if (!search) {
      this.employeeProjects.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.employeeProjects.next(
      this.employeeProjects.filter(
        (emp) => emp.name.toLowerCase().indexOf(search) > -1
      )
    );
  }
  // onInputChange() {
  //   this.search = this.selectedemployee.trim();
  // }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }

  // onInputClick() {
  //   if (this.search === '') {
  //     this.selectedemployee = ''; // Clear the input value
  //   }
  // }

  selectProject() {
    this.projectId = undefined;
    this.employeelist.length = 0;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.employeeNotFound = false;
    this.employeeProjects = undefined;
  }
  taskPending() {
    this.taskId = undefined;
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    // if (event.value) {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.remove('black-placeholder');
    // } else {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }
    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');
    if (this.endSelectedFirst && this.startDate > this.endDate) {
      // If end date was selected first and start date is greater than end date
      // Set start date to be one day before end date
      this.startDate = new Date(this.endDate);
      this.startDate.setDate(this.endDate.getDate() - 1);
    }

    if (this.startDate) {
      this.startdate_required = false;
    }
    // Check if start date is greater than end date and update flag accordingly
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.startDate = undefined;
      this.endDate = undefined;

      this.startDate = '';
      this.endDate = '';
      this.startdate_required = false;
      this.enddate_required = false;
      alert('Start date cannot be greater than end date.');
    }
  }

  checkStartDate(): void {
    // if (this.startDate > this.endDate || this.endDate < this.startDate) {
    //   alert('Start date cannot be greater than end date.');
    //   this.startDate = '';
    //   this.endDate = '';
    //   this.startDate = undefined;
    //   this.endDate = undefined;
    // }
    // if (this.startDate && this.endDate && this.startDate > this.endDate) {
    //   alert('Start date cannot be greater than end date.');
    //   this.startDate = '';
    //   this.endDate = '';
    //   this.startDate = undefined;
    //   this.endDate = undefined;
    // }
  }
  getTask() {
    if (this.projectId) {
      this.disable_project = false;
      this.project_required = false;

      this.gs.getEmployeeProject(this.projectId).subscribe(
        (data: any) => {
          console.log('dataError', data);
          this.employeelist.length = 0;
          this.employeelist = data;

          // this.employeelist.push({data})

          //   for (let index = 0; index < this.employeelist.length; index++) {
          //     this.checkEmployeeIds = [];
          //     if (this.employeelist[index]?.employeeId === this.employeeId) {
          //         this.checkEmployeeIds.push(this.employeelist[index]?.employeeId);
          //     }
          //     console.log('currentEmployee', this.checkEmployeeIds);
          //     console.log('array',this.employeelist);
          //     console.log('this.employeelist[index]', this.checkEmployeeIds)
          //     console.log('this.employeeId', this.employeeId);

          //     if ( this.checkEmployeeIds[index] === this.employeeId) {
          //         // if (this.selectedemployeeId === undefined) {
          //         //     this.selectedemployee = this.employee;
          //         // }
          //         matchFound = true;
          //         break; // Exit the loop as we found a match
          //     }

          //     console.log('this.selectedemployee',this.selectedemployee);

          //     if(this.selectedemployee  != undefined){

          //       if(this.checkEmployeeIds[index] != this.employeeId){

          //         if (this.employeeId != undefined && this.selectedemployee != undefined) {

          //           this.selectedemployee = undefined;
          //           this.employeeNotFound = false;
          //           this.employeeProjects.length = 0;
          //           this.employeeId = undefined;
          //           this.employee = undefined;
          //           this.employeeNumber = undefined;
          //           matchFound = false;
          //           break;
          //         }

          //       }
          //     }

          // }

          console.log();

          if (this.selectedEmployeeSearch != undefined) {
            let hasemployee = this.employeelist.some(
              (item) => item.employeeId === this.employeeId
            );
            // console.log("hasemployee" + hasemployee);
            // console.log("index" + index);

            if (!hasemployee) {
              this.selectedemployee = undefined;
              this.selectedEmployeeSearch = undefined;

              this.employeeNotFound = false;
              // this.employeeProjects.length = 0;
              this.employeeProjects = undefined;
              this.employeeId = undefined;
              this.employee = undefined;
              this.employeeNumber = undefined;
              matchFound = false;
            }
          }
        },
        (error) => {
          console.error('Error fetching employee data:', error);
          // Handle the error (e.g., show an error message to the user)
        }
      );

      //   if (Array.isArray(this.employeelist)) {
      //     (this.employeelist as any).filter((res) => {
      //         console.log('res', res.employee.employeeId);

      //         if (res.employee.employeeId === this.employeeId) {
      //             console.log('this.employeeId', this.employeeId);
      //             console.log('res.employee.employeeId', res.employee.employeeId);

      //             if (this.selectedemployeeId === undefined) {
      //                 this.selectedemployee = this.employee;
      //             }
      //         } else if (res.employee.employeeId !== this.employeeId) {
      //             console.log('this.selectedemployee', this.selectedemployee);

      //             if (this.selectedemployeeId === undefined) {
      //                 this.selectedemployeeId = undefined; // This line seems redundant
      //             }
      //             this.employeeNotFound = false;
      //             this.employeeProjects.length = 0;
      //             this.employeeId = undefined;
      //             this.employee = undefined;
      //             this.employeeNumber = undefined;
      //             this.selectedemployee = undefined;
      //         }
      //     });
      // }
    } else {
      this.employeelist.length = 0;
      this.orgemployeedata.forEach((element) => {
        let employee = {
          employee: {
            employeeId: element.employeeId,
            employeeName: element.employeeName,
          },
        };

        this.employeelist.push(employee);
      });
    }

    let matchFound = false;

    // If matchFound is false, reset the necessary properties
    // if (!matchFound) {
    //     this.selectedemployee = undefined;
    //     this.employeeNotFound = false;
    //     this.employeeProjects.length = 0;
    //     this.employeeId = undefined;
    //     this.employee = undefined;
    //     this.employeeNumber = undefined;
    // }

    // return matchFound;
  }

  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  changefirstpage() {
    this.p = 1;
  }

  showErrorMessage = false;

  @ViewChild('projectContainer') projectContainer: ElementRef | undefined;
  @ViewChild('projectSelect') projectSelect: ElementRef | undefined;

  onSelectionChange() {
    // this.employeeNotFound = false;
    // this.employeeProjects.length = 0;
    // this.employeeId = undefined;
    // this.employee = undefined;
    // this.employeeNumber = undefined;
    // this.selectedemployee = undefined;
    // this.selectedemployeeId = undefined;
    // //this.showErrorMessage = true;;
    this.getTask();
  }

  empSearch(event, event1: KeyboardEvent): void {
   
    if (event1.key === ' ') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      if (this.timesheetreport.length > 0) {
        this.nodata = false;
      }

      this.employeeProjects = [];
      console.log(event.target.value);
      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log(employeenumber);

      // Cancel the previous API request
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();

      if (employeenumber.length == 0) {
        this.employeeNotFound = false;
        console.log('enterd into the condition!!');
        // this.employeeId = undefined;
        // this.employee = undefined;
      }
      console.log('checking Project Id', this.projectId);

      if (
        this.projectId === 0 ||
        this.projectId === undefined ||
        this.projectId === null
      ) {
        console.log('employee key', employeenumber);
        if (employeenumber.length >= 2) {
          this.gs
            .getEmployeesBynumber(employeenumber)
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() => this.gs.getEmployeesBynumber(employeenumber)),
              catchError((err) => {
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.employeeNotFound = true;
                }
                console.error('Error fetching employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error fetching employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);

              if (result.statusCode === 204) {
                this.employeeNotFound = true;
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
              }
              // this.employeeProjects.length = 0;

              if (result.length > 0) {
                (result as any)?.forEach((element) => {
                  let employee = {
                    employee: {
                      employeeId: element.employeeId,
                      firstName: element.firstName,
                      employeeNumber: element.employeeNumber,
                    },
                  };

                  if (Object.keys(employee).length > 0) {
                    this.employeeNotFound = false;
                  } else {
                    this.employeeNotFound = true;
                  }
                  this.employeeProjects.push(employee);
                  console.log('this.employeeProjects', this.employeeProjects);
                });
              }

              // if (this.selectedemployee.length >= 1) {
              //   this.employeeSelected = true;
              // }

              if (result.errorCode === 204) {
                // alert('No Employee Found');
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
                this.employeeNotFound = true;
                this.employeeProjects.length = 0;
                this.employeeProjects.push('No Employee Found');
                this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
              }
            });
        }
      }

      // Employee List Api
      if (employeenumber && this.projectId > 0) {
        if (employeenumber.length >= 2) {
          this.gs
            .filterEmployeesByProjects(
              this.organizationId,
              this.projectId,
              employeenumber
            )
            .pipe(
              takeUntil(this.unsubscribe$),
              switchMap(() =>
                this.gs.filterEmployeesByProjects(
                  this.organizationId,
                  this.projectId,
                  employeenumber
                )
              ),
              catchError((err) => {
                console.log('emp', err);

                if (err.errorCode === 204) {
                  // this.projectId = [];
                  this.employeeNotFound = true;
                  this.selectedemployee = undefined;
                  this.selectedemployeeId = undefined;
                }
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.employeeNotFound = true;
                }
                this.employeeNotFound = true;
                console.error('Error filtering employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error filtering employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);
              if (result.errorCode === 204) {
                // this.projectId = [];
                this.employeeNotFound = true;
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
              }
              this.employeeProjects.length = 0;

              (result as any).forEach((res) => {
                console.log('employeeId', res.employee.employeeId);

                let employee = {
                  employee: {
                    employeeId: res.employee.employeeId,
                    firstName: res.employee.firstName,
                    employeeNumber: res.employee.employeeNumber,
                  },
                };

                if (Object.keys(employee).length > 0) {
                  this.employeeNotFound = false;
                } else {
                  this.employeeNotFound = true;
                }

                console.log('this.employeeProjects', this.employeeProjects);

                this.employeeProjects.push(employee);
                // this.employeeProjects = employee;
              });

              // if (this.selectedemployee.length >= 1) {
              //   this.employeeSelected = true;
              // }

              if (result.errorCode === 204) {
                // alert('No Employee Found');
                this.selectedemployee = undefined;
                this.selectedemployeeId = undefined;
                this.employeeNotFound = true;
                this.employeeProjects.length = 0;
                // this.employeeProjects.push('No Employee Found');
                this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
              }
            });
        }
      } else {
        // Set selectedemployeeId to null if the input is empty
        // this.selectedemployeeId = null;
      }
    }
  }

  handleBackspace(event): void {
    // Assuming `event.target.value` holds the current value of the input field
    const inputValue = event.target.value;

    if (this.timesheetreport.length > 0) {
      this.nodata = false;
    }
    if (event.key === 'Backspace' && inputValue.length <= 0) {
      this.selectedemployeeId = undefined;
      this.employeeId = undefined;
      // this.employeeProjects.length = 0;
      this.employeeProjects = undefined;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.employeeNotFound = false; // Set employeeNotFound to false
    }
  }
  noEmployeeFound() {
    this.employeeNotFound = false;
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    // this.employeeProjects.length = 0;
    this.employeeProjects = undefined;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
  }

  empSelect(emp) {
    console.log('empChecking', emp);

    // if (emp.length > 0) {
    this.employeeId = emp?.employee?.employeeId;
    this.employee =
      emp?.employee?.employeeNumber + '-' + emp?.employee?.firstName;
    this.employeeNumber = emp?.employeeNumber;
    this.selectedemployee = this.employee;
    // this.selectedemployee.setValue(this.employee);
    this.selectedemployeeId = this.employeeId;
    // }
  }

  formatTime(timeString: string): string {
    // Assuming e.totalWorkingHours contains the time in the format "HH:mm:ss:SS"
    const hours = timeString.substr(3, 2);
    const minutes = timeString.substr(6, 2);
    const seconds = timeString.substr(9, 2);

    // Return the formatted time
    return `${hours}:${minutes}:${seconds}`;
  }

  searchTimesheet() {
    // Check if start date is defined and update flag accordingly
    if (this.startDate !== undefined) {
      this.startdate_required = false;
    }

    // Check if end date is undefined and update flag accordingly
    if (this.endDate === undefined) {
      this.enddate_required = true;
    }

    if (this.startDate == undefined && this.endDate == undefined) {
      this.startdate_required = false;
      this.enddate_required = false;
    }

    if (!this.selectedEmployeeSearch) {
      this.selectedemployee = '';
      // this.selectedemployee.setValue('');
    }
    console.log(
      'project',
      this.projectId,
      this.selectedemployeeId,
      this.taskstatus,
      this.startDate,
      this.endDate
    );
    // this.timesheetreport = [];
    // this.nodata = false;
    this.changefirstpage();
    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');

    if (
      this.projectId != undefined &&
      this.selectedemployeeId != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.taskstatus != undefined
    ) {
      this.searchButtonDisable = true;
      console.log('getEmpIdProIdStardateEnddatestatus');
      this.tableShow = false;

      this.loader = true;
      // if (this.selectedemployeeId) {
      //   this.selectedemployee = this.employee;
      //   // this.selectedemployee.setValue(this.employee);
      // }

      this.gs
        .getEmpIdProIdStardateEnddateAndStatus(
          this.projectId,
          this.selectedemployeeId,
          this.startDate,
          this.endDate,
          this.taskstatus
        )
        .subscribe(
          (data: any) => {
            // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
            console.log(data);
            this.isproject = true;
            this.nodata = false;
            // this.timesheetreport = data;
            this.timesheetreport = Array.isArray(data) ? data : [];
            this.tableShow = true;
            this.searchButtonDisable = false;
            this.loader = false;
            this.noDataMessage = data.message;
            this.employeeNotFound = false;
            console.log('this.timesheetreport', this.timesheetreport);
            this.employeeTimeSheetData = data;
            // if (data.length != undefined) {
            //   this.nodata = true;
            // } else {
            //   this.nodata = false;
            // }

            if (data.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.searchButtonDisable = false;
              this.timesheetreport.length = 0;
            }
            return;
          },
          (error) => {
            this.loader = false;
            this.searchButtonDisable = false;
            this.tableShow = true;
            // this.timesheetreport.pop();
            // this.timesheetreport.splice(0, this.timesheetreport.length);
            // this.nodata = true;
            if (error.errorCode == 204) {
              this.nodata = true;
              this.loader = false;
              this.searchButtonDisable = false;
              this.timesheetreport.length = 0;
            }
          }
        );
      // }
    }
    // if (this.startDate && this.endDate && this.startDate > this.endDate) {
    //   this.startDate = undefined;
    //   this.endDate = undefined;
    //   this.startdate_required = true;
    //   this.enddate_required = true;
    //   alert('Start date cannot be greater than end date.');
    // }

    if (
      (this.projectId == 0 || this.projectId == undefined) &&
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.selectedemployeeId == undefined &&
      this.taskstatus == undefined
    ) {
      //     this.project_required =true;
      alert('Please Choose Project or Start Date , End Date');
      return;
    } else if (
      this.projectId == undefined &&
      this.selectedemployeeId != undefined &&
      this.taskstatus != undefined &&
      this.startDate == null &&
      this.endDate == null
    ) {
      //     this.project_required =true;
      alert('Please Choose Project or Start Date , End Date');
      return;
    } else if (
      this.projectId == undefined &&
      this.selectedemployeeId == undefined &&
      this.taskstatus != undefined &&
      this.startDate == null &&
      this.endDate == null
    ) {
      alert('Please Choose Project or Start Date , End Date');
      return;
    }

    if (
      (this.projectId == 0 || this.projectId == undefined) &&
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.selectedemployeeId != undefined &&
      this.taskstatus == undefined
    ) {
      //     this.project_required =true;
      this.startdate_required = true;
      return;
    }

    if (
      (this.startDate != undefined && this.endDate == undefined) ||
      (this.startDate == undefined && this.endDate != undefined)
    ) {
      if (this.startDate == undefined) {
        this.startdate_required = true;
      } else if (this.endDate == undefined) {
        this.enddate_required = true;
      }
      return;
    }

    if (
      (this.selectedemployeeId !== undefined && this.startDate == undefined) ||
      (this.startDate == null && this.endDate == undefined) ||
      (this.endDate == null &&
        this.projectId !== undefined &&
        this.taskstatus !== undefined)
    ) {
      console.log('getTaskByProjectAndEmployeestatus');

      if (
        this.selectedemployeeId != undefined &&
        this.startDate == null &&
        this.endDate == null &&
        this.taskstatus !== undefined
      ) {
        this.loader = true;
        this.tableShow = false;
        this.searchButtonDisable = true;
        if (this.selectedemployeeId) {
          this.selectedemployee = this.employee;
          // this.selectedemployee.setValue(this.employee);
        }

        this.gs
          .getTaskByProjectAndEmployeeAndStatus(
            this.projectId,
            this.selectedemployeeId,
            this.taskstatus
          )
          .subscribe(
            (data: any) => {
              data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              console.log(data);
              this.isproject = true;
              // this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.tableShow = true;
              this.searchButtonDisable = false;
              this.loader = false;
              this.nodata = false;
              this.employeeNotFound = false;
              this.noDataMessage = data.message;
              console.log('this.timesheetreport', this.timesheetreport);
              this.employeeTimeSheetData = data;
              // this.nodata = false;
              // if (data.length != undefined) {
              // }

              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.timesheetreport.length = 0;
              }
              return;
            },
            (error) => {
              this.searchButtonDisable = false;
              this.tableShow = true;
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);
              // this.nodata = true;
              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.timesheetreport.length = 0;
              }
            }
          );
      }
    }
    if (
      (this.selectedemployeeId != undefined && this.startDate != undefined) ||
      (this.startDate != null && this.endDate != undefined) ||
      (this.endDate != null && this.projectId == 0) ||
      (this.projectId == undefined && this.taskstatus != undefined)
    ) {
      console.log('getTaskByProjectAndEmployeestatus');
      if (
        this.projectId == undefined ||
        (this.projectId == 0 &&
          this.taskstatus != undefined &&
          this.selectedemployeeId != undefined &&
          this.startDate != null &&
          this.endDate != null)
      ) {
        if (
          this.taskstatus != undefined &&
          this.selectedemployeeId != undefined
        ) {
          this.loader = true;
          this.searchButtonDisable = true;

          this.tableShow = false;
          if (this.selectedemployeeId) {
            this.selectedemployee = this.employee;
            // this.selectedemployee.setValue(this.employee);
          }

          this.gs
            .gettaskbyEmployeeAndStartDateAndEndDateAndStatus(
              this.selectedemployeeId,

              this.startDate,
              this.endDate,
              this.taskstatus
            )
            .subscribe(
              (data: any) => {
                // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
                console.log(data);
                this.isproject = true;
                // this.timesheetreport = data;
                this.timesheetreport = Array.isArray(data) ? data : [];
                this.tableShow = true;
                this.nodata = false;
                this.searchButtonDisable = false;
                this.loader = false;
                this.noDataMessage = data.message;
                this.employeeNotFound = false;
                console.log('this.timesheetreport', this.timesheetreport);
                this.employeeTimeSheetData = data;
                // this.nodata = false;
                // if (data.length != undefined) {
                // }

                if (data.errorCode === 204) {
                  this.nodata = true;
                  this.loader = false;
                  this.timesheetreport.length = 0;
                }
                return;
              },
              (error) => {
                // this.timesheetreport.pop();
                // this.timesheetreport.splice(0, this.timesheetreport.length);
                // this.nodata = true;
                if (error.errorCode === 204) {
                  this.nodata = true;
                  this.loader = false;
                  this.timesheetreport.length = 0;
                }
              }
            );
        }
      }
    }
    if (
      this.projectId == undefined ||
      (this.projectId == 0 &&
        this.selectedemployeeId == undefined &&
        this.taskstatus == undefined &&
        this.startDate != undefined &&
        this.endDate != undefined)
    ) {
      if (
        this.selectedemployeeId == undefined &&
        this.taskstatus == undefined
      ) {
        this.loader = true;
        this.searchButtonDisable = true;
        this.tableShow = false;
        this.gs.getTaskByDate(this.startDate, this.endDate).subscribe(
          (data: any) => {
            if (Array.isArray(data)) {
              // Data is an array
              this.timesheetreport = data;
              this.nodata = data.length === 0;
            } else if (data && data.statusCode === 204 && data.message === 'No Data') {
              // No data response
              this.timesheetreport = [];
              this.nodata = true;
              this.noDataMessage = data.message;
            } else {
              // Handle unexpected cases
              console.warn('Unexpected data format:', data);
              this.timesheetreport = [];
              this.nodata = true;
            }
        
            this.isproject = true;
            this.tableShow = true;
            this.searchButtonDisable = false;
            this.loader = false;
            this.employeeNotFound = false;
            console.log('this.timesheetreport', this.timesheetreport);
        
            return;
          },
          (error) => {
            console.log('error', error);
        
            if (error.statusCode === 204 || error.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.timesheetreport = []; // Ensure it's an array
            }
          }
        );
        
      }
    }
    if (
      (this.taskstatus != undefined &&
        this.startDate != undefined &&
        this.endDate != undefined &&
        this.selectedemployeeId == undefined &&
        this.projectId == undefined) ||
      this.projectId == 0
    ) {
      if (
        this.taskstatus !== undefined &&
        this.selectedemployeeId == undefined &&
        this.startDate != null &&
        this.endDate != null
      ) {
        this.loader = true;
        this.tableShow = false;
        this.searchButtonDisable = true;
        this.gs
          .getTaskByDateAndTaskStatus(
            this.taskstatus,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              console.log(data);
              this.isproject = true;
              // this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.tableShow = true;
              this.employeeNotFound = false;
              this.searchButtonDisable = false;
              this.loader = false;
              this.noDataMessage = data.message;
              this.nodata = false;
              console.log('this.timesheetreport', this.timesheetreport);
              this.employeeTimeSheetData = data;
              // if (data.length != undefined) {
              //   this.nodata = false;
              // } else {
              //   this.nodata = false;
              // }

              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.timesheetreport.length = 0;
              }
              return;
            },
            (error) => {
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);
              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.timesheetreport.length = 0;
              }
              // this.nodata = true;
            }
          );
      }
    }
    if (
      this.projectId == 0 ||
      (this.projectId == undefined &&
        this.selectedemployeeId !== undefined &&
        this.startDate !== undefined &&
        this.endDate !== undefined &&
        this.taskstatus == undefined)
    ) {
      console.log('gettaskbyEmployeeAndStartDateAndEndDate');

      if (
        this.selectedemployeeId != undefined &&
        this.taskstatus == undefined &&
        this.startDate !== null &&
        this.endDate !== null
      ) {
        this.loader = true;
        this.tableShow = false;
        this.searchButtonDisable = true;
        // if (this.selectedemployeeId) {
        //   this.selectedemployee = this.employee;
        //   // this.selectedemployee.setValue(this.employee);
        // }

        this.gs
          .gettaskbyEmployeeAndStartDateAndEndDate(
            this.selectedemployeeId,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              console.log(data);
              this.isproject = true;
              // this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.tableShow = true;
              this.employeeNotFound = false;
              this.searchButtonDisable = false;
              this.loader = false;
              this.noDataMessage = data.message;
              console.log('this.timesheetreport', this.timesheetreport);
              this.employeeTimeSheetData = data;
              // if (data.length != undefined) {
              //   this.nodata = false;
              // } else {
              //   this.nodata = false;
              // }
              this.nodata = false;
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.timesheetreport.length = 0;
              }
              return;
            },
            (error) => {
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);
              // this.nodata = true;

              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.timesheetreport.length = 0;
              }
            }
          );
      }
    } else if (this.projectId == 0 || this.projectId == undefined) {
      // this.project_required = true;
      return;
    } else {
      this.project_required = false;
    }
    if (
      this.projectId == 0 ||
      (this.projectId == undefined &&
        this.startDate == undefined &&
        this.endDate == undefined &&
        this.selectedemployeeId)
    ) {
      // alert('Please select project or date range');s
      return;
    }

    if (
      (this.startDate != undefined && this.endDate == undefined) ||
      (this.startDate == undefined && this.endDate != undefined)
    ) {
      if (this.startDate == undefined) {
        this.startdate_required = true;
      } else if (this.endDate == undefined) {
        this.enddate_required = true;
      }
      return;
    }

    this.employee_required = false;

    console.log('this.selectedemployeeId' + this.selectedemployeeId);

    if (
      this.selectedemployeeId != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.projectId != undefined &&
      this.taskstatus == undefined
    ) {
      this.loader = true;
      this.searchButtonDisable = true;

      this.tableShow = false;
      // if (this.selectedemployeeId) {
      //   this.selectedemployee = this.employee;
      //   // this.selectedemployee.setValue(this.employee);
      // }

      console.log('getEmpIdProIdStardateEnddate');
      this.gs
        .getEmpIdProIdStardateEnddate(
          this.projectId,
          this.selectedemployeeId,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            this.isproject = true;
            // this.timesheetreport = data;
            this.timesheetreport = Array.isArray(data) ? data : [];
            this.tableShow = true;
            this.searchButtonDisable = false;
            this.loader = false;
            this.nodata = false;
            this.employeeNotFound = false;
            this.noDataMessage = data.message;
            console.log('this.timesheetreport', this.timesheetreport);
            this.employeeTimeSheetData = data;
            // if (data.length != undefined) {
            //   this.nodata = false;
            // } else {
            //   this.nodata = false;
            // }
            // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
            if (data.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.timesheetreport.length = 0;
            }
            return;
          },
          (error) => {
            this.timesheetreport.pop();
            this.timesheetreport.splice(0, this.timesheetreport.length);
            // this.nodata = true;

            if (error.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.timesheetreport.length = 0;
            }
          }
        );
    } else if (
      this.selectedemployeeId !== undefined &&
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.projectId != undefined &&
      this.taskstatus == undefined
    ) {
      console.log('getTaskByProjectAndEmployee');
      this.loader = true;
      this.searchButtonDisable = true;

      console.log('this.selectedemployeeIdProjecta', this.selectedemployeeId);

      // if (this.selectedemployeeId) {
      //   this.selectedemployee = this.employee;
      //   // this.selectedemployee.setValue(this.employee);
      // }

      this.tableShow = false;
      this.gs
        .getTaskByProjectAndEmployee(this.selectedemployeeId, this.projectId)
        .subscribe(
          (data: any) => {
            console.log(data);
            this.isproject = true;
            // this.timesheetreport = data;
            this.timesheetreport = Array.isArray(data) ? data : [];
            this.tableShow = true;
            this.searchButtonDisable = false;
            this.employeeNotFound = false;
            this.loader = false;
            this.nodata = false;
            this.noDataMessage = data.message;
            console.log('this.timesheetreport', this.timesheetreport);
            this.employeeTimeSheetData = data;
            // if (data.length != undefined) {
            //   this.nodata = true;
            // } else {
            //   this.nodata = false;
            // }

            // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
            if (data.errorCode === 204) {
              this.nodata = true;
              this.tableShow = true;
              this.loader = false;
              this.timesheetreport.length = 0;
            }
            return;
          },
          (error) => {
            // this.timesheetreport.pop();
            // this.timesheetreport.splice(0, this.timesheetreport.length);
            if (error.errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.tableShow = true;
              this.timesheetreport.length = 0;
            }
          }
        );
    } else if (
      this.selectedemployeeId == undefined &&
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.projectId != undefined &&
      this.taskstatus == undefined
    ) {
      console.log('taskByProject');
      this.loader = true;
      this.searchButtonDisable = true;
      this.tableShow = false;

      this.gs.taskByProject(this.projectId).subscribe(
        (data: any) => {
          console.log(data);
          this.isproject = true;
          this.timesheetreport.length = 0;

          data.forEach((item: any) => {
            if (item?.employee) {
              this.timesheetreport.push(item);
            }
          });

          this.tableShow = true;
          this.searchButtonDisable = false;
          this.loader = false;
          this.employeeNotFound = false;
          this.noDataMessage = data.message;
          console.log('this.timesheetreport', this.timesheetreport);
          this.employeeTimeSheetData = data;
          this.nodata = false;
          // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
          if (data.errorCode === 204) {
            this.timesheetreport.length = 0;
            this.nodata = true;
            this.loader = false;
            this.tableShow = true;
            this.timesheetreport.length = 0;
          }
          return;
        },
        (error) => {
          console.log('error', error);

          // this.timesheetreport.pop();
          // this.timesheetreport.splice(0, this.timesheetreport.length);
          if (error.errorCode === 204) {
            this.timesheetreport.length = 0;
            this.nodata = true;
            this.loader = false;
            this.tableShow = true;
            this.timesheetreport.length = 0;
          }
          // this.nodata = true;
        }
      );
    }
    if (
      this.selectedemployeeId == undefined &&
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.projectId == 0 &&
      this.taskstatus == undefined
    ) {
      this.loader = true;
      this.searchButtonDisable = true;
      this.tableShow = false;
      this.gs.getTaskByDate(this.startDate, this.endDate).subscribe(
        (data: any) => {
          // Check if the data is an array
          if (Array.isArray(data)) {
            this.timesheetreport = data;
            // this.nodata = data.length === 0;
          } 
          // Check for the no data response
          else if (data && data.statusCode === 204 && data.message === 'No Data') {
            this.timesheetreport = [];
            this.nodata = true;
            this.tableShow = true;
            this.noDataMessage = data.message;
          } 
          // Handle other object responses
          else if (data && typeof data === 'object') {
            this.timesheetreport = [];
            console.warn('Received an object instead of an array. Did you mean to use the keyvalue pipe?');
            // Optionally handle the object case
            // this.timesheetreport = Object.entries(data); // Convert object to array of key-value pairs
          } 
          // Handle other unexpected cases
          else {
            this.timesheetreport = [];
            this.nodata = true;
          }
      
          this.isproject = true;
          this.tableShow = true;
          this.searchButtonDisable = false;
          this.loader = false;
          this.employeeNotFound = false;
          console.log('this.timesheetreport', this.timesheetreport);
      
          return;
        },
        (error) => {
          console.log('error', error);
      
          if (error.statusCode === 204 || error.errorCode === 204) {
            this.nodata = true;
            this.loader = false;
            this.tableShow = true;
            this.timesheetreport.length = 0;
          }
        }
      );
      
      
    }
    if (
      (this.taskstatus != undefined && this.startDate != undefined) ||
      (this.startDate != null && this.endDate != undefined) ||
      (this.endDate != null && this.projectId == undefined) ||
      (this.projectId == 0 && this.selectedemployeeId == undefined)
    ) {
      if (
        (this.taskstatus !== undefined &&
          this.selectedemployeeId == undefined &&
          this.projectId == undefined) ||
        this.projectId == 0
      ) {
        this.loader = true;
        this.searchButtonDisable = true;
        this.tableShow = false;
        this.gs
          .getTaskByDateAndTaskStatus(
            this.taskstatus,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              console.log(data);
              this.searchButtonDisable = false;
              this.isproject = true;
              // this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.tableShow = true;
              this.nodata = false;
              this.loader = false;
              this.searchButtonDisable = false;
              this.employeeNotFound = false;
              this.noDataMessage = data.message;
              console.log('this.timesheetreport', this.timesheetreport);
              this.employeeTimeSheetData = data;
              // if (data.length != undefined) {
              //   this.nodata = false;
              // } else {
              //   this.nodata = false;
              // }

              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.tableShow = true;
                this.timesheetreport.length = 0;
              }
              return;
            },
            (error) => {
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);
              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.tableShow = true;
                this.timesheetreport.length = 0;
              }
              // this.nodata = true;
            }
          );
      }
    } else if (
      this.selectedemployeeId == undefined &&
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.projectId != undefined &&
      this.taskstatus != undefined 
    ) {
      console.log('taskByProjectstatus');
      this.loader = true;
      this.searchButtonDisable = true;
      this.tableShow = false;
      this.gs.taskByProjectAndStatus(this.projectId, this.taskstatus).subscribe(
        (data: any) => {
          // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
          console.log('timesheetreport', data);
          // this.timesheetreport = data;
          this.timesheetreport = Array.isArray(data) ? data : [];
          this.tableShow = true;
          this.employeeNotFound = false;
          if (data.errorCode == 204) {
            this.nodata = true;
            this.loader = false;
            this.tableShow = true;
            this.timesheetreport.length = 0;
            this.timesheetreport = [];
          }
          this.isproject = true;

          this.nodata = false;
          this.searchButtonDisable = false;
          this.loader = false;
          this.noDataMessage = data.message;
          console.log('this.timesheetreport', this.timesheetreport);
          this.employeeTimeSheetData = data;
          // if (data.length != undefined) {
          //   this.nodata = true;
          // } else {
          //   this.nodata = false;
          // }
          return;
        },
        (error) => {
          // this.timesheetreport.pop();
          // this.timesheetreport.splice(0, this.timesheetreport.length);
          // this.nodata = true;
          if (error.errorCode == 204) {
            this.nodata = true;
            this.tableShow = true;
            this.loader = false;
            this.timesheetreport.length = 0;
          }
        }
      );
    }
    if (
      this.projectId != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.taskstatus == undefined &&
      this.selectedemployeeId == undefined
    ) {
      console.log('taskByProjectstatus');
      this.loader = true;
      this.searchButtonDisable = true;
      this.tableShow = false;
      this.gs
        .getTaskByProjectAndDate(this.projectId, this.startDate, this.endDate)
        .subscribe(
          (data: any) => {
            // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
            console.log('res', data);
            this.timesheetreport = data;
            
            if (data.errorCode === 204) {
              this.nodata = true;
              this.tableShow = true;
              this.loader = false;
              this.timesheetreport.length = 0;
            }
            this.isproject = true;
            // this.nodata = false;
            // this.timesheetreport = data;
            this.timesheetreport = Array.isArray(data) ? data : [];
            this.employeeNotFound = false;
            this.tableShow = true;
            this.searchButtonDisable = false;
            this.loader = false;
            this.noDataMessage = data.message;
            console.log('this.timesheetreport', this.timesheetreport);
            this.employeeTimeSheetData = data;
            // if (data.length != undefined) {
            //   this.nodata = true;
            // } else {
            //   this.nodata = false;
            // }
            return;
          },
          (error) => {
            // this.timesheetreport.pop();
            // this.timesheetreport.splice(0, this.timesheetreport.length);
            // this.nodata = true;

            this.nodata = true;
            this.loader = false;
            this.timesheetreport.length = 0;
            if (error.errorCode === 204) {
              this.nodata = true;
              this.tableShow = true;
              this.loader = false;
              this.timesheetreport.length = 0;
            }
          }
        );
    }
    console.log('this.selectedemployeeId', this.selectedemployeeId);

    if (
      this.projectId != undefined &&
      this.selectedemployeeId == undefined &&
      this.taskstatus != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined
    ) {
      console.log('getTaskByProjectAndDatestatus');

      if (this.selectedemployeeId == undefined) {
        this.loader = true;
        this.searchButtonDisable = true;
        this.tableShow = false;
        this.gs

          .getTaskByProjectAndDateAndStatus(
            this.projectId,
            this.taskstatus,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              console.log(data);
              this.isproject = true;
              this.nodata = false;
              this.tableShow = true;
              // this.timesheetreport = data;
              this.timesheetreport = Array.isArray(data) ? data : [];
              this.searchButtonDisable = false;
              this.loader = false;
              this.employeeNotFound = false;
              this.noDataMessage = data.message;
              console.log('this.timesheetreport', this.timesheetreport);
              this.employeeTimeSheetData = data;
              // if (data.length != undefined) {
              //   this.nodata = false;
              // } else {
              //   this.nodata = false;
              // }
              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.tableShow = true;
                this.timesheetreport.length = 0;
              }
            },
            (error) => {
              // this.timesheetreport.pop();
              // this.timesheetreport.splice(0, this.timesheetreport.length);
              // this.nodata = true;
              if (error.errorCode === 204) {
                this.timesheetreport.length = 0;
                this.nodata = true;
                this.loader = false;
                this.tableShow = true;
              }
              return;
            }
          );
      }
    }
  }
  
  @ViewChild('yourForm', { static: false }) yourForm: NgForm;

  //   resetForms() {
  //     this.yourForm.resetForm();
  //     // this.timesheetreport.length != 0;
  //     this.employeelist.length = 0;
  //     this.timesheetreport.length === 0;
  //     this.timesheetreport = [];
  //     this.orgemployeedata.forEach(element => {
  //     let employee = {
  //       employee: {
  //         employeeId : element.employeeId,
  //         employeeName : element.employeeName
  //       }

  //     }

  //  this.employeelist.push(employee);
  //   });
  //     // Add additional logic to reset other form-related properties if needed
  //   }

  resetForms() {
    this.projectId = undefined;
    this.startDate = undefined;
    this.tableShow = false;
    this.endDate = undefined;
    // this.employeeProjects.length = 0;
    this.employeelist.length = 0;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.taskstatus = undefined;
    this.showEmptyData = true;
    this.timesheetreport.length = 0;
    this.startdate_required = false;
    this.enddate_required = false;
    this.project_required = false;
    this.employeeNotFound = false;
    this.loader = false;
    this.tableShow = false;
    this.searchButtonDisable = false;
    // this.employeeProjects = [];
    this.employeeProjects = undefined;
    // this.timesheetreport.length === 0;
    // this.timesheetreport = [];
    this.nodata = true;
  }

  datechange(event: any) {
    console.log(event);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  withDrawStatus(i) {
    if (i.taskStatusName == null) {
      return;
    }
    if (i.taskStatusName == 'To Do') {
      return '#ffc107';
    }
    if (i.taskStatusName == 'Completed') {
      return '#28a745';
    }
    if (i.taskStatusName == 'In Progress') {
      return '#f44336';
    }
  }

  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == 'Pending') {
      return '#ffc107';
    }
    if (i.approvalStatusName == 'Approved') {
      return '#28a745';
    }
    if (i.approvalStatusName == 'Denied') {
      return '#f44336';
    }
  }

  // viewTimeSheet(e: any) {
  //   const dialogRef = this.dialog.open(TimeSheetComponent, {
  //     minWidth: "900px",
  //     minHeight: "500px",
  //     data: this.employeeTimeSheetData,
  //   });
  // }

  viewTimeSheet(e: any) {
    // this.changefirstpage();

    // this.p = this.beforesearchpage;
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)'); // Adjust the breakpoint as needed

    // console.log('eee', e);
    const dialogRef = this.dialog.open(TimeSheetComponent, {
      minWidth: isMobile ? '97vw' : '900px',
      minHeight: isMobile ? '30vh' : '500px',
      data: e,
    });
  }

  sortData(sort: Sort) {
    const data = this.timesheetreport.slice();
    if (!sort.active || sort.direction === '') {
      this.timesheetreport = data;
      return;
    }

    this.timesheetreport = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employeeName':
          return (
            this.compare(
              a?.employee?.firstName,
              b?.employee?.firstName,
              isAsc
            ) ||
            this.compare(a?.employee?.lastName, b?.employee?.lastName, isAsc) ||
            this.compare(a.taskName, b.taskName, isAsc)
          );
        case 'projectName':
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );

        case 'taskName':
          return this.compare(a.taskName, b.taskName, isAsc);
        case 'taskCOde':
          return this.compare(a.taskCode, b.taskCode, isAsc);
        case 'taskStartDate':
          return this.compare(a.taskStartDate, b.taskStartDate, isAsc);
        case 'taskEndDate':
          return this.compare(a.taskEndDate, b.taskEndDate, isAsc);
        case 'workedHours':
          return this.compare(a.totalWorkingHours, b.totalWorkingHours, isAsc);
        case 'assignedhours':
          return this.compare(
            a?.empolyeeTasks[0]?.assignedhours,
            b?.empolyeeTasks[0]?.assignedhours,
            isAsc
          );
        case 'taskStatusName':
          return this.compare(a.taskStatusName, b.taskStatusName, isAsc);
        case 'approvalStatusName':
          return this.compare(
            a.approval.approvalStatusName,
            b.approval.approvalStatusName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  handleKeyPress(event) {
    if (event.key === ' ' && event.target.selectionStart === 0) {
      event.preventDefault();
    }
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML);
    
  
    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    console.log(pdfTable.innerHTML);
    
  
    const documentDefinition = {
      content: html,
      pageMargins: [25, 40, 25, 40],
      info: {
        title: `Employee Task Report`
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);
  
        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download("Employee Task Report");
  }
}
