Sub header start here
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Groups</span>
                </div>
                <div class="sub-header-buttons">
                 
                    <a routerLink="/home/groups-list-emp" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">
            
                   
                <div class="card-body">
                    <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                        <div class="row">

                            <!-- <div class="form-group col-md-3">
                                <label class="col-form-label">Client<span class="star">*</span></label>
                                <select formControlName="assignmentType" placeholder="- Select client -" (change)="changeassingment()"  class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select Assignment type --- </option>
                                    <option [value]="0" >Upload employee</option>
                                    <option [value]="1" >Choose employee</option>
                                 
                                </select>
                                </div> -->
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Client<span class="star">*</span></label>
                                <select formControlName="client" placeholder="- Select client -" (change)="getemployees()"  class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select client --- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option>
                                </select>
                                <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                    <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Group Name<span class="star">*</span></label>
                                <input type="text" formControlName="groupName" id="groupName" (keyup)="validateemployeegroupname($event)" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder="Employee Group Name" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.groupName.invalid && validate.groupName.touched) || validate.groupName.dirty || submitted">
                                    <span *ngIf="validate.groupName.errors?.required" class="text-danger">Please enter group name</span>
                                </div>
                                <span *ngIf="employeegroupnameexist" class="text-danger">{{existMsg}}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Group Description</label>
                                <input type="text" formControlName="groupDescription" id="groupDescription"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder="Employee Group Name" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.groupDescription.invalid && validate.groupDescription.touched) || validate.groupDescription.dirty || submitted">
                                    <span *ngIf="validate.groupDescription.errors?.required" class="text-danger">Please enter group description</span>
                                </div>
                            </div>
                        
                                      
                        </div> 
                    
                        <div class="row">
                            <div class="col-md-12" *ngIf="empList.length > 0">
                                <div class="row">
                                    <div class="col-md-11 text-right">
                                        <p class="show-count">Show</p>
                                    </div>
                                    <div class="col-md-1 text-left pl-0">
                                        <select [(ngModel)]="pagePerItem" (change)="pageChages()"  class="form-control report-filter">
                                            <option value="10" selected>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <!-- <option value="1500">1500</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="table-responsive" *ngIf="empList.length != 0">
                            <div class="row">
                                <div class="col-md-8">
                                    <p><b>EMPLOYEE</b></p>
                                </div>
                                <div class="col-md-4">
                                    <span *ngIf="selectedEmployeeData.length == 0 && submitted" class="text-danger">Please select employees</span>
                                </div>
                            </div>
                            
                            <table class="table  --table-bordered table-border-new" style="width: 100%;border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                <thead class="t-head">
                                    <tr>
                                        <!-- <th class="text-center">S.No</th> -->
                                        <th class="text-center">Action</th>
                                       
                                        <th>Emp Code</th>
                                        <th>Emp Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Desgination</th>
                                        <th>DOJ</th>
                                        <th>Gender</th>
                                      
                                        <!-- <th class="text-center">Status</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of empList | paginate: { itemsPerPage: pagePerItem, currentPage: p }  let i=index;">
                                        <!-- <td class="text-center">{{i+1}}</td> -->
                                       
            
                              
                                        <td>{{row.employeeNumber}}</td>                                      
                                        <td>{{row.firstName}}  </td>
                                        <td>{{row.mobileNumber}}</td>
                                        <td>{{row.organizationEmail}}</td>
                                        <td *ngIf="row.designation !=undefined" >{{row.designation.designationName}}</td>
                                        <td *ngIf="row.designation == undefined" >--</td>
                                        <td>{{row.dateOfJoining | date : dateFormat}}</td>
                                        <td>{{row.gender.listTypeValueName}}</td>
                                                
                                        <!-- capitalizeWordsName -->
                                        <!-- <td class="active-status text-center">
                                            <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                                {{row.status.listTypeValueName}}
                                            </span>
            
                                            <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                                {{row.status.listTypeValueName}}
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style="text-align: center;" colspan="12" *ngIf="nodatafound">No matching records found!</td>
                                    </tr>
                                </tbody>
                            </table>
                         </div>
                         <div *ngIf="empList.length > 0 ">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="showing-record">
                                        {{empList.length}} rows
                                    </div>
                                </div>
                                <div class="col-md-8 text-right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                       
                            <div class="col-md-12 text-right">
                                <button type="reset" class="submit action-button btn mr-2 btn-sm" (click)="clearemployeelist()"  mat-raised-button  style="margin-right: 20px;"
                                >Clear</button>                              
                                <button type="submit" mat-raised-button color="primary">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
             

               
         
          
       
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records </button>
   
</div>