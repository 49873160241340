<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-5">
            <div class="row">
              
                <div class="sub-header-buttons">
                  <a routerLink="/home/leave-adjustment-list" mat-raised-button title="Back" class="" style="font-family: Mulish;">Back</a>
              </div>
            </div>
        </div>
    </div>
</section> -->

<nav class="navbar  bg" style="margin-top: 67px;">
    <a class="navbar-brand navDesign" style="font-size: 22px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;">Create Leave Adjustment
    </a>
   
   
  </nav>
<div class="common-content ">
    <div class="card-new colDesign ">
      <div class="" style="font-family: Mulish;">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-12" >
                <div class="col-sm-9">
                    <mat-card class="mat-card" style="font-family: Mulish;">
                        <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center" style="" >
                            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <!-- <ng-container *ngIf="isLoading; else elseTemplate">
                            <div class="loading">
                                <h4 class="text-muted">loading....</h4>
                                <br>
                                <div class="spinner-grow text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-success" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-danger" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-info" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-dark" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </ng-container> -->
                        <!-- <ng-template #elseTemplate> -->
                            <div >
                                <form [formGroup]="leaveForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- <h3 class="title">*Create Leave Adjustment*</h3> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">                    
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Search Employee<span class="star"> *</span></label>
                                                    <input type="text"  (keyup)="keyFunc($event,$event)" value="" placeholder="Search..."
                                                    formControlName="employeeId" [matAutocomplete]="temName"class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;" autocomplete="off"           
                                                    (input)="validateInput($event)"
                                                    (keydown)="validateInput($event)"
                                                    (keyup)="validateInput($event)"
                                                    (keydown)="handleBackspace($event)" 
                                                    (keydown.space)="preventSpaceBeforeInput($event)">
                                                    <small class="text-muted">Note : Emp No., Employee Name.</small>
                                                    <mat-autocomplete #temName="matAutocomplete">
                                                        <mat-option *ngFor="let searchData of employeeIdList" [value]="searchData.employeeId" (click)="empSelect(searchData)">
                                                            {{searchData.employeeNumber}} - {{searchData.firstName }}
                                                        </mat-option>
                                                        <mat-option
                                                        [value]="null"
                                                        *ngIf="employeeNotFound"
                                                        (click)="noEmployeeFound()"
                                                        >No Employee Found</mat-option
                                                    >
                                                    </mat-autocomplete>

                                                    <!-- <mat-autocomplete #temName="matAutocomplete">
                                                        <mat-option *ngFor="let searchData of employeeIdList" [value]="searchData.employeeId" (click)="empSelect(searchData)">
                                                            {{searchData.employeeNumber}} - {{searchData.firstName +' '+ searchData.lastName}}
                                                        </mat-option>
                                                    </mat-autocomplete> -->
                                                    <!-- <mat-error *ngIf="f.employeeId.hasError('required')&& (f.employeeId.touched || f.employeeId.dirty)">
                                                        Please select <strong>Employee Number</strong>
                                                    </mat-error> -->
    
                                                    <div *ngIf="(f.employeeId.invalid && submitted) || f.employeeId.dirty">
                                                        <mat-error *ngIf="f.employeeId.errors?.required"> Please select Employee Number</mat-error>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div>
                                                <mat-error class="error_msg" *ngIf="all_null">Choose valid employee.</mat-error>
                        
                                            </div>
                                        </div>

                                        
                                        <div class="col-md-6">                
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Employee Name<span class="star"> *</span></label>
                                                
                                                    <input formControlName="employeeName" [(ngModel)]="employeeValue" readonly 
                                                    class="form control select-placeholder email-input "  style="background-image: none;outline:none;border-radius: 5px;" placeholder="Employee name"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Leave Type<span class="star"> *</span></label>
                                                    <mat-select placeholder="Select Leave Type" formControlName="leaveBalance" 
                                                    class="form control select-placeholder email-input " style="font-family: Mulish; background-image: none;outline:none;border-radius: 5px;">
                                                       <mat-option [value]="null">-Select Leave Type-</mat-option>
                                                        <mat-option *ngFor="let t of leaveTypeList; let i = index" value="{{ t.leaveBalanceId }}"
                                                        (click)="leave(t.noOfDays)">
                                                        {{ t.leaveName }} <em> * </em>bal: {{ t.noOfDays }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <!-- <mat-error *ngIf="f.leaveBalance.hasError('required')&& (f.leaveBalance.touched || f.leaveBalance.dirty)">
                                                        Please select <strong>Leave Type</strong>
                                                    </mat-error> -->
    
                                                    <div *ngIf="(f.leaveBalance.invalid && submitted) || f.leaveBalance.dirty">
                                                        <mat-error *ngIf="f.leaveBalance.errors?.required" > Please select Leave Type</mat-error>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Leave Adjustment<span class="star"> *</span></label>
                                                    <mat-select formControlName="leaveadjustmenttype" 
                                                    class="form control select-placeholder email-input "  style="font-family: Mulish;background-image: none;outline:none;border-radius: 5px;">
                                                        <mat-option value="add">Add</mat-option>
                                                        <mat-option value="detect">Detect</mat-option>
                                                    </mat-select>
                                                    <!-- <mat-error *ngIf="f.leaveadjustmenttype.hasError('required')&& (f.leaveadjustmenttype.touched || f.leaveadjustmenttype.dirty)">
                                                        Please Select <strong>Leave Adjustment </strong>
                                                    </mat-error> -->
                                                    <div *ngIf="(f.leaveadjustmenttype.invalid && submitted) || f.leaveadjustmenttype.dirty">
                                                        <mat-error *ngIf="f.leaveadjustmenttype.errors?.required" > Please select Leave Adjustment</mat-error>

                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Adjustment Days <span class="star"> *</span></label>
                                                    <input  [maxlength]="length" (keypress)="noNumber($event)" (paste)="onPaste($event)"  formControlName="leaveadjustmentdays"
                                                    (keyup)="leaveData($event)" class="form control select-placeholder email-input "  style="background-image: none;padding-left: 2%; outline:none;border-radius: 5px;width: 100% !important; "/>
                                                    <!-- <mat-error *ngIf="f.leaveadjustmentdays.hasError('required')&& (f.leaveadjustmentdays.touched || f.leaveadjustmentdays.dirty)">
                                                        Please Enter <strong>Adjustment Days </strong>
                                                    </mat-error> -->
                                                    <div *ngIf="(f.leaveadjustmentdays.invalid && submitted) || f.leaveadjustmentdays.dirty">
                                                        <mat-error *ngIf="f.leaveadjustmentdays.errors?.required"> Please Select Adjustment Days</mat-error>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Adjustment Reason <span class="star"> *</span></label>
                                                    <textarea  formControlName="leaveadjustmentreason"class="form control textarea email-input "           (input)="validateInputs($event)"
                                                    style="background-image: none; padding-left:2%; outline:none;border-radius: 5px;width: 100% !important; "></textarea>
                                                    <!-- <mat-error *ngIf="f.leaveadjustmentreason.hasError('required')&& (f.leaveadjustmentreason.touched || f.leaveadjustmentreason.dirty)">
                                                        Please Enter <strong>Adjustment Reason</strong>
                                                    </mat-error> -->
                                                    <mat-error *ngIf="(f.leaveadjustmentreason.errors?.pattern )">
                                                        Please Enter a Valid Adjustment Reason
                                                    </mat-error>  
    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="d-flex">
                                            <div style="width: 45%;margin: 0 auto;">
                                                <!-- <button mat-stroked-button class="cancel-button" color="accent" type="button"
                                           (click)="cancel()"  style="font-family: Mulish;"> Cancel</button> -->
                                           <button type="reset"   class=""      mat-raised-button  (click)="cancel()"  style="
                                           float: right; 
                                           
                                           margin-left: 7px;
                                             background: none;
                                          
                                             border: 1px solid #ddd;
                                             font-size: 14px;
                                             font-weight: 300;
                                             height: 40px !important;
                                           "  
                                            style="color:var(--Red-Color, #FF5D5D); height:35px; margin-left:85%"
                                           mat-dialog-close>Cancel</button>
                                            </div>
                                            <div style="width:45%;margin: 0 auto;">
                                                <button
          type="submit"
          mat-raised-button
          class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
          [disabled]="!searchButton ||leaveForm.invalid"
        >
          Create
        </button>
                                                <!-- <button mat-stroked-button class="apply-button" color="primary" type="submit" style="font-family: Mulish;">Create</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            
                        <!-- </ng-template> -->
                    </mat-card>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
  </div>




