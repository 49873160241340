import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';


import { DatePipe } from '@angular/common';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDatepicker} from '@angular/material/datepicker';
import { Sort } from '@angular/material/sort';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { ApproverStatusComponent } from 'src/app/leave/approver-status/approver-status.component';
import { event } from 'jquery';
import { ShowerrorComponent } from '../../../app/showerror/showerror.component';
import { HeaderService } from 'src/app/providers/header.service';
import { ExpenseAdvanceapproverstatusComponent} from 'src/app/advanceapproverstatus/expenseadvanceapproverstatus.component';
const moment = _rollupMoment || _moment;

@Component({
  selector:'app-advance',
  templateUrl:'./advance.component.html',
  styleUrls: ['./advance.component.scss']
})
export class AdvanceComponent implements OnInit {
  formValues: any;
sucess_msg: any;
errorMsg: any;
importerror: boolean = false;
errormessagejson: any;
search: any;
  beforesearchpage: number;
  listTypeValueId: any;
  btnDisable: boolean=false;
  storeApproveData: any;
  storeFirstname: any;
tableShow: boolean=false;

  viewform(_t118: any) {
    throw new Error('Method not implemented.');
    }
      advanceform: FormGroup;
      advancecreatedata:any;
      create_sucess_msg:any;
      update_error_msg:any;
      loader:any= false;
      backdrop:any= false;
      success:boolean=false;
      error:any=false;
      usertype:any;
      employeeId:any;
      advanceupdatedata:any;
      advancedata:any;
      organizationId:any;
      usersdata:any;
      advancespentinvalid : any = false;
      today: any =  new Date();
      advanceamountreadonly : any =false;
      advancegivenbyreadonly :any = false;
      btnsDisable: boolean=false;
      data: any = {};
      submitted:boolean = false;

    
    // for listing //
      advancelistdata:any[];
      p: number = 1;
      public searchFilter: any = '';
      query: any = '';
      beforepage :any;
      mySelect:any =5;
      usersId:any;
      // employeeId:any;
      // loader:any= false;  
      list_loader = false;
      nodata = false;
      // organizationId:any
      advancelistdatafilted :any = [];
      // success:any=false;
      // error:any=false;
      // create_sucess_msg:any;
      // update_error_msg:any;
      localdata :any = {};
      empId: number;
      advanceType: any;
      searchForm: FormGroup; 
      advanceTypeData:any;
      advanceTypeSearchData:any;
      isOptionSelected: boolean = false;
      noRecordsFound: boolean =false;
      group:FormGroup;
      formsubmitted: boolean = false;
      sethide:boolean=true;
      setbool:boolean=false

      constructor(private router:Router, 
        private formBuilder: FormBuilder,
        
        private http: HttpClient,
        public ActivatedRoute:ActivatedRoute,
        private advanceservice :ProjectManagementService,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private headerservice: HeaderService,

         private datepipe : DatePipe){
          this.searchForm = this.formBuilder.group({
            advanceTypeSearch: [null],
          });
         }
        //   private dialogref : MatDialogRef<AdvanceComponent> ,
        // @Inject(MAT_DIALOG_DATA) public data: any) { }
    
        
    
      ngOnInit(): void 
      {
        // this.headerservice.setTitle('Advance');
        this.headerservice.setTitle('');
        this.list_loader = true;
        this.listAdvanceType();
        this.noRecordsFound = true;

        console.log(this.listAdvanceType);
    
        this.today =this.datepipe.transform(this.today, 'yyyy-MM-dd')
        ///localhost stroage data
        this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
        console.log(this.usertype);
        console.log(this.usertype.employee.employeeId);
    
        this.empId=this.usertype.employee.employeeId;
        console.log("this.empId::",this.empId);
    
        this.organizationId = this.usertype.organization.organizationId;
        console.log(this.organizationId);
    
        // this.employeeId = this.usertype.employeeId;
        // console.log(this.employeeId);
    
        // this.advanceservice
        // // .userslist(this.organizationId)
        // .subscribe((data)=>{
        //   this.usersdata=data;
        //   console.log(this.usersdata);
        //   this.usersdata=data.filter((users) => users.employeeId != this.usertype.employeeId  && users.status.listTypeValueName == "Active");
        //   console.log(this.usersdata);
        // });
        
        // this.advanceform = new FormGroup(
        //   {
        //     advanceamount : new FormControl("",[Validators.required,  Validators.pattern(/^[1-9]\d*$/)]),
        //     advanceType:new FormControl("",[Validators.required]),
        //     description : new FormControl("")
            
        //   });
          this.advanceform = this.fb.group({
            // advanceamount: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
            // advanceamount: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
            advanceamount : new FormControl(this.advanceform,[Validators.required,Validators.pattern(/^[1-9]\d{0,10}$/)]),

                        advanceType: ['', Validators.required],
            description: ['']
          });
        
          console.log(this.data);
    
          if(this.data.advanceId)
          {
            this.advanceamountreadonly = true;
            this.advancegivenbyreadonly = true;
            this.advanceservice
            // .userslist(this.organizationId)
            // .subscribe((data)=>{
            //   this.usersdata=data;
            //   console.log(this.usersdata);
    
            //   this.advanceservice
            //   .advancebyid(this.data.advanceId)
            //   .subscribe((advancedata:any) =>
            //   {
            //     this.advancedata=advancedata;
            //     console.log(this.advancedata);
    
            //     this.advanceform.patchValue({
            //       advanceamount: this.advancedata.advanceAmount,
            //       advancegivenon: this.advancedata.advanceGivenOn,
            //   //    advancespent: this.advancedata.advanceSpent,
            //   //    advancebalance:this.advancedata.advanceBalance, 
            //       advancegivenby:this.advancedata.advanceGivenBy.employeeId, 
            //       description:this.advancedata.description  
            //     });
            //   });
            // });
          }
    
      // for listing //
    
      
    
    
      this.searchForm = this.formBuilder.group({
        advanceTypeSearch: [null]
        // advanceTypeSearch:new FormControl("",[Validators.required]),

      });
      // this.searchForm =new FormGroup({
      //   // advanceTypeSearch: [null]
      //   advanceTypeSearch:new FormControl("",[Validators.required]),

      // });
    
      this.localdata = JSON.parse(localStorage.getItem('enoteUserData'));
      this.organizationId = this.localdata.organization.organizationId;
      console.log(this.localdata);
      console.log(this.organizationId);
      // this.getadvance(this.empId);
      
      
      // this.advanceservice
      //   .advancelisting(this.empId,7)
        
      //   .subscribe((advancelistdata:any) =>
      //   {
          
      //     console.log(this.advancelistdata);
          
      //     this.list_loader = false;
      //     this.nodata = false;
      //     this.advancelistdata = advancelistdata;
          
      //     console.log(this.advancelistdata);
    
      //     this.advancelistdata.forEach(element => {
            
      //       var elementobj = {
      //         advanceId: element.advanceId,
      //         advanceAmount: element.advanceAmount ,
      //         advanceGivenOn:this.datepipe.transform(element.advanceGivenOn, 'dd-MM-yyyy') ,
      //         advanceSpent: element.advanceSpent ,
      //         advanceBalance: element.advanceBalance ,
      //         advanceGivenBy:  element.advanceGivenBy 
              
      //       }  
    
      //       this.advancelistdatafilted.push(elementobj);
      //            console.log(this.advancelistdata);
      //  });
       
      //  if(advancelistdata.length ==0)
      //  {
      //    this.nodata = true;
      //    console.log("nodata");
      //  }
      //     if(advancelistdata.statusCode)
      //     {
      //       this.nodata = true;
      //       console.log("nodata");
      //     }
      //   },
      //   (error) => {
      //     this.list_loader = false;
      //     this.nodata = true;
      //     console.log("error")
      //     if(error.status == 404){
      //       this.nodata = true;
      //       console.log("404")
      //     }
      //   }
      //   ) 
      }
      validatePositiveNumber(control) {
        if (control.value <= 0) {
          return { 'negativeNumber': true };
        }
        return null;
      }
      
      get f(){
        return this.advanceform.controls;
      }
      showerrormessage()
      {
        console.log(this.errormessagejson);
        const dialogRef = this.dialog.open(ShowerrorComponent, {
          //height: 'auto',width: '600px',
          width: '500px',height:'fit-content',data: this.errormessagejson
        });
        this.error = false;
      }
      changefirstpage() {
        this.p = 1;
        }
    
      calucatebalance()
      {
         if(this.advanceform.value.advanceamount && this.advanceform.value.advancespent)
         {
              if(Number(this.advanceform.value.advancespent) > Number(this.advanceform.value.advanceamount))
              {
                this.advancespentinvalid = true;
              }else{
                this.advancespentinvalid = false;
                console.log( this.advanceform.value.advanceamount - this.advanceform.value.advancespent)
                 this.advanceform.patchValue({
                  advancebalance :   this.advanceform.value.advanceamount - this.advanceform.value.advancespent
                 })
               
              }
         }
      }
      clearforms(){
        // this.advanceType=undefined
        this.advancelistdatafilted=[]
        this.noRecordsFound=false
        console.log('clear')
        this.searchForm.clearValidators
        this.noRecordsFound=true
        this.btnDisable=false
        this.btnsDisable=false
        this.nodata=false
        this.loader=false
        // this.search=''
        this.tableShow=false;

        

      }
    
//       noNumber(event: any) {
//         // const pattern = /[0-9]|\./;
//         // const inputChar = String.fromCharCode(event.charCode);
//         // if (event.keyCode !== 8 && !pattern.test(inputChar)) {
//         //   event.preventDefault();
//         // }
//   //       const allowedChars = /[0-9]/;
//   // const inputChar = event.key;

//   // if (!allowedChars.test(inputChar)) {
//   //   event.preventDefault();
//   // }
//    // Allow essential keys like Backspace, Delete, Tab, Arrow keys, and Enter
//    const inputValue = event.target.value;

//    // Check if the pressed key is not a number or backspace
//    if (isNaN(Number(event.key)) && event.key !== 'Backspace') {
//      event.preventDefault();
//    }
 
//    // Check if the input length exceeds the maxlength
//    if (inputValue.length >= event.target.maxLength && event.key !== 'Backspace') {
//      event.preventDefault();
//    }
//  }
noNumber(event: any) {
  // const inputValue = event.target.value;


  const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
}


 
 noPaste(event: any) {
   // Prevent pasting non-numeric characters
   const clipboardData = event.clipboardData.getData('text/plain');
   if (isNaN(Number(clipboardData))) {
     event.preventDefault();
   }
      }
      noSpace(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
        }
    }
    noTab(event: KeyboardEvent) {
      // Prevent input if the Tab key is pressed
      if (event.key === 'Tab') {
        event.preventDefault();
      }
    }
    
      submitData(){
                // console.log("this.empId::",this.empId);
        this.p=1
        this.search=''
        // this.error = false;
        // this.success = false;
        this.submitted=true
        // this.nodata = false;
        // this.noRecordsFound=false
     
        if( this.advancespentinvalid == true) 
        {
          // this.submitted=false

          return;
        }
        // this.btnsDisable=false

        if(this.advanceform.valid){
          this.btnsDisable=true
        this.submitted=false
        
        // this.btnDisable = true;
        console.log(this.data.advanceId);
        if(this.data.advanceId)
        {
          // this.submitted=false
          var advanceupdate =
          { 
            advanceAmount: this.advanceform.value.advanceamount,
            advanceGivenOn: this.datepipe.transform(this.advanceform.value.advancegivenon, 'yyyy-MM-dd'),
            advanceSpent:  this.data.advanceSpent,
            advanceBalance: this.data.advanceBalance, 
            advanceGivenBy:{
              employeeId:this.advanceform.value.advancegivenby
            }, 
            advanceGivenTo : {
              employeeId: this.empId
            },
            description:this.advanceform.value.description,  
            advanceId : this.data.advanceId,
            organization:{
              organizationId:this.organizationId
            },  
            // lastUpdatedBy:this.employeeId,
            status : this.data.status  
          };       
          console.log(advanceupdate);
       
           
          this.backdrop = true;
           this.loader=true;
           this.setbool=false
            this.advanceservice
            .advanceupdate( this.data.advanceId,advanceupdate)
            .subscribe( (data:any) =>
            {
              this.advanceupdatedata= data;
              console.log(this.advanceupdatedata);
              this.backdrop = false;
               this.loader = false;
               this.setbool=false

              // this.dialogref.close(data);
              // this.success = true;
              // this.create_sucess_msg = this.advanceupdatedata.description;
              // console.log(this.create_sucess_msg);
              //   setTimeout(() => {
              //    window.location.reload();
              //   }, 2000)
              if(data.StatusCode==200){
                this.success=true
                this.btnsDisable=true
                this.setbool=false

                // this.loader=true
                this.advanceform.reset()
                this.searchSubmit()

                // this.submitData()
                // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                // const currentUrl = this.router.url;
                // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                //     this.router.navigateByUrl(currentUrl);
                // });
                this.sucess_msg=data.message
                // this.loader=false

                setTimeout(()=>{
                  this.btnsDisable=false

                  // this.submitted=false
                  // this.loader=false


                  this.success=false
                },5000)
              }
              },
              (err) =>
              {
                this.backdrop = false;
                // this.loader = false;
                // this.btnDisable = false;
                // this.dialogref.close(err.error);       
                    this.setbool=false

                this.error = true;
                this.errorMsg = err.error.message;
                console.log(this.errorMsg);
                setTimeout(() => {this.error = false},5000)
              });
        }
        else
        {
          // this.btnsDisable=false

          console.log("this.empId::",this.empId);
          
          console.log("this.advanceform.value",this.advanceform.value);
          this.formValues=this.advanceform.value
          console.log("Form Values::",this.formValues);
          
            var payload =
            {          
              advanceAmount : this.formValues.advanceamount,
              // advanceSpent:0,
              // advanceBalance: this.formValues.advanceamount,
              advanceType:{
                listTypeValueId:this.formValues.advanceType
              },
              advanceGivenTo :this.empId,
              organizationId:this.organizationId,
              description:this.formValues.description,
              createdBy:this.empId,
              approvalStatus:3


              
    
            };
            console.log("payload creation values::",payload);
            this.backdrop = true;
            this.loader=true;
            this.tableShow=false;
            this.setbool=false


            // return
           
            this.advanceservice
           
            .advancecreate(payload)
            .subscribe( (data:any) =>
            {
                //  this.btnsDisable=true
                this.loader=false;
                this.tableShow=true
                if(data.statusCode==200){
                  this.advanceform.reset()
                  this.formsubmitted=true;

                  this.success=true
                  this.sucess_msg=data.message

                  this.btnsDisable=true
                  this.noRecordsFound=false

                  this.searchSubmit()

                  // this.submitted=false

                  // console.log(" this.sucess_msg", this.sucess_msg);
                  // this.onSubmit()
                  setTimeout(()=>{
                    // this.noRecordsFound = true; 
                    this.success=false
                    this.btnsDisable=false
                    // this.advanceform.reset()
                    // this.submitted=false

                    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    // const currentUrl = this.router.url;
                    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    //     this.router.navigateByUrl(currentUrl);
  
                    // });
                   
                  },3000)
                }
              
            },
              (err) =>
              {
                this.backdrop = false;
                this.loader = false;
                this.setbool=false

                // this.btnDisable = false;
                // this.dialogref.close(err.error);
                this.error = true;
                this.errorMsg = err.error.message;
                console.log(this.errorMsg);
                setTimeout(() => {this.error = false,
                  this.btnsDisable=false

                }, 5000)
              });
            }
          }

            // this.advanceform.reset()
      }
       formcancel()
      {
        this.loader=false
        // this.nodata=false
        this.submitted=false
        this.btnsDisable=false
        this.advanceform.reset();// this.dialog.closeAll();
        // this.search=''
      }
    
      
      listTypeValueid(event: any)
      {
          // this.isOptionSelected = event.target.value !== '';
          // console.log('event', event)
          // console.log("listTypeValueid::",this.listTypeValueid);
          console.log('event', event)
          this.listTypeValueId = event.target;
          console.log('this.listTypeValueId', this.listTypeValueId)
          if( event.target.value == 'null')
          {
            this.searchForm.patchValue({
      
              advanceTypeSearch : null
            })
          }
      }
    
      searchSubmit() 
      {
        console.log("this.searchForm.value.advanceTypeSearch::",this.searchForm.value.advanceTypeSearch);
        
        // if(this.searchForm.value.advanceTypeSearch==undefined  && this.searchForm.value.advanceTypeSearch==null){
        //   alert('Please Choose The Element To Search!! ')
        //   return
        // }
        this.p=1
        this.search=''
        this.sethide=false

      //   if (this.searchForm.get('advanceTypeSearch').value === null) {
      //     // Display an alert message
      //     alert('Please select an advance type.');
      //     return; // Stop further execution
      // }
        this.advancelistdatafilted=[]
        this.noRecordsFound=false
        // this.btnDisable=true
        this.nodata=false
        this.loader=false

        console.log(this.searchForm.value)
        console.log( "formsubmitted" + this.formsubmitted);
        if(this.searchForm.value.advanceTypeSearch == null   && this.formsubmitted == false){
          console.log("this.searchForm.value.categoryTypeSearch",this.searchForm.value.advanceTypeSearch)
          // this.elementList();
          alert("Please Select Advance Type");
          this.sethide=true
        
          this.noRecordsFound = false;
          return
      //  this.loader=true
        }
         if(this.searchForm.value.advanceTypeSearch != null){
          this.btnDisable=true
          this.loader=true
          this.tableShow=false

          console.log("this.searchForm.value.categoryTypeSearch",this.searchForm.value.advanceTypeSearch)
          const categoryType = this.searchForm.get('advanceTypeSearch').value;
          // this.noRecordsFound = false;

          this.advanceservice.advancelisting(this.empId, this.searchForm.value.advanceTypeSearch).subscribe((data:any) => {
            // this.noRecordsFound = false;

          console.log('data',data);
            this.advancelistdatafilted = data;
            this.btnDisable=false
            this.nodata=false
            this.loader=false
            this.sethide=true
            this.tableShow=true
            this.noRecordsFound = false;

            // this.advancelistdatafilted = this.advancelistdatafilted.length;
            if( data.message == 'No Data'){
              this.noRecordsFound = true;
              // this.btnDisable=false
              this.nodata=true
              this.loader=false
              this.tableShow=true


            }
            this.noRecordsFound = false;

            this.btnsDisable=false
            this.loader=false


            console.log('this.elementslistData1',this.advancelistdatafilted);
           
          }, (error) => {
            console.log('error')
          });
        }else if(this.searchForm.value.advanceTypeSearch == null && this.formsubmitted == true){
          console.log('both true')
          this.noRecordsFound = false;
          this.tableShow=false
        }
        this.formsubmitted = false;
        // this.noRecordsFound = false;


   

      
        // // else{
        //   const selectedAdvanceType = this.searchForm.get('advanceTypeSearch').value;
        // // this.advanceservice.advancelisting(this.empId, selectedAdvanceType).subscribe((data) => {
        //   this.advanceservice.advancelisting(this.empId, selectedAdvanceType).subscribe((data) => {
        //   // Update advancelistdatafilted with the received data
        //   this.advancelistdatafilted = data;
        //   console.log(this.advancelistdatafilted)

        //   this.advancelistdatafilted.forEach(item => {
        //     // Access the approvalStatus property of each item
        //     const approvalStatus = item.approvalStatus.listTypeValueName;
        //     // Do whatever you need with the approval status here
        //     console.log("approvalStatus::",approvalStatus);
        // });


        // });

        // }
        
      }
    
      //////get values from form //////
      get advanceamount()
      {
        return this.advanceform?.get('advanceamount');  
      }
      get advancegivenon()
      {
        return this.advanceform.get('advancegivenon');  
      }
      get advancespent()
      {
        return this.advanceform.get('advancespent');  
      }
      get advancebalance()
      {
        return this.advanceform.get('advancebalance');  
      }
      get advancegivenby()
      {
        return this.advanceform.get('advancegivenby');  
      }
      get description()
      {
        return this.advanceform.get('description');  
      }
    
     
      
      
    // for listing //
    
    // getadvance(empId)
    //   {
    //     this.advancelistdatafilted.length = 0;
    //     console.log(this.usersdata.employeeId);
    //     this.advanceservice
    //     .advancelist(this.empId)
        
    //     .subscribe((advancelistdata:any) =>
    //     {
    //       console.log(advancelistdata);
    //       this.list_loader = false;
    //       this.nodata = false;
    //       this.advancelistdata = advancelistdata;
          
    //       console.log(this.advancelistdata);
    
    //       this.advancelistdata.forEach(element => {
             
    //         var elementobj = {
    //           advanceId: element.advanceId,
    //           advanceAmount: element.advanceAmount ,
    //           advanceGivenOn:this.datepipe.transform(element.advanceGivenOn, 'dd-MM-yyyy') ,
    //           advanceSpent: element.advanceSpent ,
    //           advanceBalance: element.advanceBalance ,
    //           advanceGivenBy:  element.advanceGivenBy 
              
    //         }  
    
    //         this.advancelistdatafilted.push(elementobj);
    //              console.log(this.advancelistdata);
    //    });
    //    if(advancelistdata.length ==0)
    //    {
    //      this.nodata = true;
    //      console.log("nodata");
    //    }
    //       if(advancelistdata.statusCode)
    //       {
    //         this.nodata = true;
    //         console.log("nodata");
    //       }
    //     },
    //     (error) => {
    //       this.list_loader = false;
    //       this.nodata = true;
    //       console.log("error")
    //       if(error.status == 404){
    //         this.nodata = true;
    //         console.log("404")
    //       }
    //     }
    //     )  
    //   }
    
    
    
    
      Add_client(){
        // const dialogbox = this.dialog.open(AdvanceComponent, { 
        //   width: '700px',height:'fit-content',
        //   data:"" 
        // });
    
        // dialogbox.afterClosed().subscribe(
        //   (res)=>{
        //     console.log(res);
        //      if(res)
        //      {
        //        if(res.statusCode == 200)
        //        {
        //           this.success = true;          
        //          this.create_sucess_msg = res.message;
    
        //             setTimeout(() => {
        //               this.getadvance();
        //               this.success  = false;               
        //           }, 2000)
        //        }else{
        //           this.error = true;
        //            this.update_error_msg = res.message;
        //            setTimeout(() => {this.error = false}, 2000)
        //        }
       
        //      }
        //   }
        // );
      }
      disablePaste(event: ClipboardEvent): void {
        event.preventDefault();
      }
      
      listAdvanceType()
      
      {
        this.advanceservice.listCategoryType().subscribe((result: any) => {
          this.advanceTypeData = result;
          console.log(this.advanceTypeData);
          this.advanceTypeSearchData = result;
          console.log(this.advanceTypeData[0].listTypeValueId);
        })
      }
    
      updateform(advance)
      { 
        // console.log(advance);  
        //  const dialogupdatebox = this.dialog.open(AdvanceComponent, { 
        //   width: '700px',height:'fit-content',
        //   data:advance
        // });    
    
        // dialogupdatebox.afterClosed().subscribe(
        //   (res)=>{
        //     console.log(res);
        //      if(res)
        //      {
        //        if(res.statusCode == 200)
        //        {
        //           this.success = true;          
        //          this.create_sucess_msg = res.description;
    
        //             setTimeout(() => {
        //               this.getadvance();
        //               this.success  = false;               
        //           }, 2000)
        //        }else{
        //           this.error = true;
        //            this.update_error_msg = res.message;
        //            setTimeout(() => {this.error = false}, 2000)
        //        }
       
        //      }
        //   }
        // );
      }
    
      viewapproverstatus(approvaldata:any){
        console.log(approvaldata);
        this.dialog.open(ExpenseAdvanceapproverstatusComponent,{
          width: '500px',
          height: '450px',
          data :approvaldata
        })
      }
    
      
      statuschangeactive(advanceId)
      {
        console.log("advanceId"+advanceId);    
    
       this.advanceservice
       .advancestatustoDeactive(advanceId)
       .subscribe( (data:any) =>
       {
          console.log(data);
          window.location.reload();
       })
      }
      statuschangeinactive(advanceId)
      {
        console.log("advanceId"+advanceId);    
    
        this.advanceservice
        .advancestatustoactive(advanceId)
        .subscribe( (data:any) =>
        {
           console.log(data);
           window.location.reload(); 
        })
      }
      
      
      changepage()
      {
        console.log(this.advancelistdatafilted.length);
        if(this.search.length!= 0)
        {
           this.p = 1;
           console.log(this.advancelistdatafilted.length);
          // this.taskEmpList.length=0;
          // console.log(this.taskEmpList.length);
          // if(this.taskEmpList.length == 0)
          //     {
          //       this.nodata = true;
          //       console.log("nodata")
          //     }
          // else
          // {
          //   this.p = 1;
            console.log(this.p);
            console.log("success Data");
        }
        else{
           this.p = this.beforesearchpage;
          // this.nodata = true;
          console.log(this.advancelistdatafilted.length);
          console.log("nodata");
        }
      }
      sortData(sort: Sort) {
        // const data = this.advancelistdatafilted.slice();
        // if (!sort.active || sort.direction === '') {
        //   this.advancelistdatafilted = data;
        //   return;
        // }
        if (!Array.isArray(this.advancelistdatafilted)) {
          console.error('advancelistdatafilted is not an array.');
          return;
      }
  
      const data = this.advancelistdatafilted.slice();
    
    
        this.advancelistdatafilted .forEach((x:any)=>{
          x.advanceGivenOn = x.advanceGivenOn.split('-').reverse().join("-");
        });
        this.advancelistdatafilted = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'advanceAmount':
              return this.compare(a.advanceAmount, b.advanceAmount, isAsc);
            case 'advanceGivenOn':
              return this.compare(new Date(a.advanceGivenOn), new Date(b.advanceGivenOn), isAsc);
            case 'advanceSpent':
              return this.compare(a.advanceSpent, b.advanceSpent, isAsc);
            case 'advanceBalance':
              return this.compare(a.advanceBalance, b.advanceBalance, isAsc);
            case 'employeeName':
              return this.compare(a.advanceGivenBy.employeeName, b.advanceGivenBy.employeeName, isAsc);
            default:
              return 0;
          }
        });
        this.advancelistdatafilted .forEach((x:any)=>{
          x.advanceGivenOn = x.advanceGivenOn.split('-').reverse().join("-");
        });
      }
    
      compare(a: number | string| Date , b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }

}
