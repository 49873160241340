import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/providers/admin.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake  from "html-to-pdfmake";
import { DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { HttpClient } from '@angular/common/http';
import { RecruitmentService } from '../providers/recruitment.service';

@Component({
  selector: 'app-preview-payslip',
  templateUrl: './preview-payslip.component.html',
  styleUrls: ['./preview-payslip.component.scss']
})
export class PreviewPayslipComponent implements OnInit {

  payrollHeaderId: any;
  fileblob: any;
  resultData: any;
  organzationId: any;
  userdata: any;
  payrolldata:any;
  Earnings: any=[];
  EarningsCTCtotal = 0;
  EarningsAmounttotal = 0;
  EarningsYTDTotal = 0;
  Deductions: any=[];
  DeductionsCTCtotal = 0;
  DeductionsAmounttotal = 0;
  DeductionsYTDTotal = 0;
  NetPay: number;
  NetPayInWords: string;
  @ViewChild('pdfTable')pdfTable!: ElementRef;
  dateformat : any;
  base64data: string | ArrayBuffer | null = null;
  organizationSettings: any;
  // dateformat : string = "dd-MMM-yyyy";
  globalResultData: any;


  constructor(
    private router :Router,
    public activeRoute : ActivatedRoute,
    public gs : GlobalserviceService,
    private Employeeservice:EmployeeService,
    private AdminService:AdminService,
    private SuperadminService: SuperadminService,
    @Inject(AppComponent) public AppData: any,
    private datepipe  : DatePipe,
    private http: HttpClient,
    private recuiterservice: RecruitmentService
  ) { }

  ngOnInit(): void {

    // this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    // // console.log(this.userdata);
    // this.organzationId =  this.userdata.organization.organizationId;
    // // console.log("organziationid " + this.organzationId);
    // this.dateFormat = localStorage.getItem('dateFormatName');
    console.log(this.activeRoute.snapshot.params.id);
    this.payrollHeaderId = this.activeRoute.snapshot.params.id
    this.gs.getManagePayslipById(this.payrollHeaderId).subscribe(
      (data:any)=>{
        console.log("payrolldata",data);
        this.payrolldata=data;
        console.log("payrolldata",!this.payrolldata == false);
        console.log("payrolldata",!this.payrolldata == true);
        // console.log("payrolldata",this.payrolldata != undefined);

        this.gs.usersbyid(this.payrolldata.employee.employeeId).subscribe(
          (data:any)=>{
            this.userdata = data;
            this.organzationId= this.userdata.organization.organizationId;
            console.log( this.organzationId);
            console.log("userdata", this.userdata);
            this.gs.getOrganizationdetails(this.organzationId).subscribe(
              (data:any)=>{
                this.organizationSettings=data;
                console.log("organizationSettings",this.organizationSettings);

                this.gs.dateformateByOrg(this.organzationId).subscribe(
                  (data:any)=>{
                    this.dateformat = data.dateFormatName;
                    console.log("dateformat",this.dateformat);
                    this.dateformat = data.dateFormat ? data.dateFormat.dateFormatName : "dd-MMM-yyyy";

                    this.NetPay = this.payrolldata.earningYtd - this.payrolldata.deductionYtd;
                    // console.log("this.NetPay",this.NetPay);
        
                    // console.log("this.NetPayInWords",this.NetPayInWords);
                    let EarningsArray =[];
                    let DeductionsArray =[];
                    this.payrolldata.payrollLine.forEach(payrollLine => {
                      if(payrollLine.hrmspaySlipElement.categoryType.listTypeValueId == 42){
                        EarningsArray.push(payrollLine);
                        this.Earnings= EarningsArray;
                        console.log("EarningsArray",this.Earnings);
                      }
                      else{
                        if(!payrollLine.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYER"))
                        {
                          DeductionsArray.push(payrollLine);
                          this.Deductions=DeductionsArray;
                      }
            
                     
                  //      console.log("DeductionsArray",this.Deductions);
                      }
                    });
                    if(this.Earnings.length !=0){
                      for(let j=0;j<this.Earnings.length;j++){   
                        this.EarningsCTCtotal += this.Earnings[j].ctcReference;
                        this.EarningsAmounttotal += this.Earnings[j].amount;
                        this.EarningsYTDTotal += this.Earnings[j].ytd;
                      }
                    }
                    if(this.Deductions.length !=0){
                      for(let j=0;j<this.Deductions.length;j++){   
                        this.DeductionsCTCtotal += this.Deductions[j].ctcReference;
                        this.DeductionsAmounttotal += this.Deductions[j].amount;
                        this.DeductionsYTDTotal += this.Deductions[j].ytd;
                      }
                    }
        
                    let total =  Number(this.EarningsAmounttotal - this.DeductionsAmounttotal).toFixed(0);
              //      console.log("total : " + total)
                    this.NetPayInWords = this.inWords(total);
        
                    this.SuperadminService.getOrganizationById(this.organzationId).subscribe(
                      (resultData:any) =>{
                        this.resultData = resultData;
                     //   console.log("this.resultData",this.resultData);
                        if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
                          this.Employeeservice.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
                          (blob) => {
                              const a = document.createElement('a')
                              const objectUrl: any = blob
                              this.fileblob = blob;
                              this.http.get(this.fileblob, { responseType: 'blob' })
                              .subscribe(res => {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  this.base64data = reader.result;                
                        //            console.log(this.base64data);
                                }
                                reader.readAsDataURL(res); 
                                // console.log(res);
                                setTimeout(() => {
                                  console.log("setTimeout after 2m");
                                  this.printPdf();
                                }, 200);
                              });
                            },
                            err =>{
                              this.fileblob= "assets/uploads/no-image.png"
                         //     console.log("else",this.fileblob);
                              this.http.get(this.fileblob, { responseType: 'blob' })
                              .subscribe(res => {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  this.base64data = reader.result;                
                            //        console.log(this.base64data);
                                }
                                reader.readAsDataURL(res); 
                                // console.log(res);
                                setTimeout(() => {
                              //    console.log("setTimeout after 2m");
                                  this.printPdf();
                                }, 200);
                              });
                            }
                          );
                        }
                        else{
                          this.fileblob= "assets/uploads/no-image.png";
                        //  console.log("else",this.fileblob);
                          this.http.get(this.fileblob, { responseType: 'blob' })
                          .subscribe(res => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              this.base64data = reader.result;                
                                // console.log(this.base64data);
                            }
                            reader.readAsDataURL(res); 
                            // console.log(res);
                            setTimeout(() => {
                              console.log("setTimeout after 2m");
                              this.printPdf();
                            }, 200);
                          });
                        }
                      }
                    )
                  }
                )
              }
            )

            // this.SuperadminService.getOrganizationById(this.organzationId).subscribe(
            //   (globalResultData:any) =>{
            //     this.globalResultData = globalResultData;
            //     console.log("globalResultData",globalResultData);
            //   }
            // )
          }
        )
      })

  }
  printPdf()
  {
  //  console.log("PrintPDF");
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    this.gs.getManagePayslipById(this.payrollHeaderId).subscribe(
      (data:any)=>{
     //   console.log(data);
        this.payrolldata=data;
        const formattedDate = this.datepipe.transform(this.payrolldata.createDateTime, this.dateformat);
        const documentDefinition = { 
        content: html ,
        // image: this.fileblob, // Use the dataURL here
        // width: 200,
        // height: 100,
        pageMargins: [ 30, 30, 30, 35 ],
        info: {
          title:`Report ${formattedDate}`
          },
          pageSize: 'A4',
          pageOrientation: 'landscape',
        };
       pdfMake.createPdf(documentDefinition).download(this.payrolldata.employee.employeeNumber +"-" +this.payrolldata.employee.firstName+"-"+this.payrolldata.finanicalPeriod.financialperiodMonth + "-" + this.payrolldata.finanicalPeriod.financialPeriodYear+".pdf"); 
      })
  }
  inWords (num) {
    let a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
    let  b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
    
      if ((num = num.toString()).length > 9) 
      {
        return 'overflow';
      }
      let n=[];
       n= ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) {
        return ""; 
      }
      var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Only ' : '';
        if(!str.includes("Only"))
        {
          str =  str + 'Only';
        }
            return str;
  }
  // InWords(num) {
  //   let a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
  //   let b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
  
  //   if (isNaN(num) || num < 0 || num >= 1000000000) {
  //     return 'Invalid input';
  //   }
  
  //   if (num === 0) {
  //     return 'Zero';
  //   }
  
  //   // Separate the whole and decimal parts
  //   const [wholePart, decimalPart] = num.toString().split('.');
  
  //   let wholeInWords = '';
  //   if (Number(wholePart) > 0) {
  //     wholeInWords = this.inWords(wholePart);
  //   } else {
  //     wholeInWords = 'Zero';
  //   }
  
  //   let decimalInWords = '';
  //   if (decimalPart) {
  //     const paisa = Number(decimalPart);
  //     if (paisa > 0) {
  //       decimalInWords = ' and ' + this.inWords(paisa) + ' Paisa';
  //     }
  //   }
  
  //   return wholeInWords + decimalInWords + ' Only';
  // }

}
