import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';


@Component({
  selector: 'app-variablepayqualifer',
  templateUrl: './variablepayqualifer.component.html',
  styleUrls: ['./variablepayqualifer.component.scss']
})
export class VariablepayqualiferComponent implements OnInit {

 

  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeetData:any;
  qualificationNameExistStatus:any;

  qualificationId:any;
  usertype: any;
  organizationId: any;
  qualiferId: any;

  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<VariablepayqualiferComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let userId = this.usertype.userId;
     this.organizationId = this.usertype.organization.organizationId;
     
    //Post Form
    this.postForm = this.formBuilder.group({
      rating : ['',[Validators.required , Validators.pattern(/^\d*\.?\d*$/)]],
      percentage : ['',[Validators.required ,  Validators.pattern("^[0-9]*$")]],
    });

    if(this.edit_data)
    {
      this.qualiferId = this.edit_data.qualiferId;
      
      this.settingsservice.getvariblepayqualiferById(this.qualiferId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          rating: this.resultData.rating,
          percentage: this.resultData.percentage,
        });
      });
    }
  }

  // convenience getter for easy access to form fields
  get validate() { return this.postForm.controls; }

  //Form Submit Create / Edit
  onSubmit() //form Submit Function
  {
    this.submitted = true;

    //stop here if form is invalid
    if (this.postForm.invalid || this.qualificationNameExistStatus == true) {return;}

    if(this.edit_data) //Edit
    {
      let postValues = this.postForm.value;
      let id = this.edit_data.qualiferId;

      postValues['organization'] = {organizationId:  this.organizationId}
      postValues['qualiferId'] = id;
      postValues['qualiferStatus'] = this.edit_data.qualiferStatus;

      this.backdrop = true;
      this.loader=true;

      this.settingsservice.updatevarpayqualifer(id,postValues).subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/department' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
    else //Create
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId:  this.organizationId}

     /*  if(this.postForm.valid &&  this.qualificationNameExistStatus == true)
      {
        console.log("if condition")
        return
      } */

      this.settingsservice.createvarpayqualifer(postValues).subscribe((variablepayqualifer: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(variablepayqualifer);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
  }

  checkExistQualificationName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */

    // if(this.postForm.value.streamName.length != 0)
    // {
    //   let postValues = this.postForm.value;
    //   postValues['organization'] = {organizationId: organizationId}

    //   if(this.edit_data)
    //   {
    //     postValues['streamId'] = this.edit_data.streamId;

    //     // this.settingsservice.updateCheckExistQualificationName(postValues).subscribe((result: any) =>
    //     // {
    //     //   if(result.statusCode == 200)
    //     //   {
    //     //     this.qualificationNameExistStatus = false;
    //     //   }
    //     //   else if(result.statusCode > 400)
    //     //   {
    //     //     this.qualificationNameExistStatus = true;
    //     //   }
    //     // })
    //   }
    //   else
    //   {
    //     this.settingsservice.createCheckExistQualificationName(postValues).subscribe((result: any) =>
    //     {
    //       if(result.statusCode == 200)
    //       {
    //         this.qualificationNameExistStatus = false;
    //       }
    //       else if(result.statusCode > 400)
    //       {
    //         this.qualificationNameExistStatus = true;
    //       }
    //     })
    //   }
    // }
    // else
    // {
    //   this.qualificationNameExistStatus = false;
    // }
  }


}
