<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <!-- <span class="h5 sub-content"> Add Entity </span> -->
          <span routerLink="/home/branch" class="h5 sub-content">
            {{ entityId ? "Edit" : "Add" }} Entity</span
          >
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="entitygroup" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-md-3">
              <label class="col-form-label"
                >Entity Name <span class="star">*</span></label
              >
              <input
                type="text"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                placeholder="Entity Name"
                formControlName="entityName"
                autocomplete="off"
                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.entityName?.invalid &&
                    validate?.entityName?.touched) ||
                  validate?.entityName?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.entityName.errors?.required"
                  class="text-danger"
                  >Please enter Entity name</span
                >
                <span
                  *ngIf="validate.entityName.errors?.pattern"
                  class="text-danger"
                  >Please enter valid Entity name</span
                >
                <!-- <span *ngIf="organizationNameExistStatus" class="span-error">Organization name already exist!</span> -->
                <!-- <span *ngIf="existMsg" class="text-danger">{{existMsg}}</span> -->
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="col-form-label"
                >User Email <span class="star">*</span></label
              >
              <input
                type="text"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                placeholder="User Email"
                formControlName="userEmail"
                autocomplete="off"
                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate.userEmail.invalid && validate.userEmail.touched) ||
                  validate.userEmail.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.userEmail.errors?.required"
                  class="text-danger"
                  >Please enter User Email</span
                >
                <span
                  *ngIf="validate.userEmail.errors?.pattern"
                  class="text-danger"
                  >Please enter valid User Email</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="col-form-label"
                >Password <span class="star">*</span></label
              >
              <!-- <input [type]="password" formControlName="password" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="Password">
                             -->
              <input
                [type]="password"
                formControlName="password"
                minlength="8"
                maxlength="8"
                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Password"
                [ngClass]="{
                  'is-valid':
                    (validate.password.dirty || submitted) && passwordValid,
                  'is-invalid':
                    (validate.password.dirty || submitted) && !passwordValid
                }"
              />

              <!-- required -->
              <div
                class="valid-msg"
                *ngIf="(validate.password.dirty || submitted ||validate.password.touched) && !requiredValid"
              >
                <span class="text-danger">Please enter password</span>
              </div>

              <!-- Password validation rules start -->
              <div class="invalid-feedback">
                <!-- valid-msg ng-star-inserted -->
                <div class="mt-3" *ngIf="requiredValid">
                  <!-- minlength -->
                  <div
                    [ngClass]="{
                      'text-success': minLengthValid,
                      'text-danger': !minLengthValid
                    }"
                  >
                    <i
                      [attr.class]="
                        minLengthValid ? 'bi-check-square-fill' : 'bi-x-square'
                      "
                    ></i>
                    Must be at least 8 characters long
                  </div>

                  <!-- requires digit -->
                  <div
                    [ngClass]="{
                      'text-success': requiresDigitValid,
                      'text-danger': !requiresDigitValid
                    }"
                  >
                    <i
                      [attr.class]="
                        requiresDigitValid
                          ? 'bi-check-square-fill'
                          : 'bi-x-square'
                      "
                    ></i>
                    Must contain at least 1 digit
                  </div>

                  <!-- requires uppercase -->
                  <div
                    [ngClass]="{
                      'text-success': requiresUppercaseValid,
                      'text-danger': !requiresUppercaseValid
                    }"
                  >
                    <i
                      [attr.class]="
                        requiresUppercaseValid
                          ? 'bi-check-square-fill'
                          : 'bi-x-square'
                      "
                    ></i>
                    Must contain at least 1 uppercase character
                  </div>

                  <!-- requires lowercase -->
                  <div
                    [ngClass]="{
                      'text-success': requiresLowercaseValid,
                      'text-danger': !requiresLowercaseValid
                    }"
                  >
                    <i
                      [attr.class]="
                        requiresLowercaseValid
                          ? 'bi-check-square-fill'
                          : 'bi-x-square'
                      "
                    ></i>
                    Must contain at least 1 lowercase character
                  </div>

                  <!-- requires special characters -->
                  <div
                    [ngClass]="{
                      'text-success': requiresSpecialCharsValid,
                      'text-danger': !requiresSpecialCharsValid
                    }"
                  >
                    <i
                      [attr.class]="
                        requiresSpecialCharsValid
                          ? 'bi-check-square-fill'
                          : 'bi-x-square'
                      "
                    ></i>
                    Must contain at least 1 special character
                  </div>
                </div>
              </div>
              <!-- Password validation rules end -->

              <!-- <div class="valid-msg" *ngIf="(validate.password.invalid && validate.password.touched) || validate.password.dirty || submitted">
                                <span *ngIf="validate.password.errors?.required" class="text-danger">Please enter password</span>
                                <span *ngIf="validate.password.errors?.pattern" class="text-danger">Please enter valid password</span>
                            </div> -->
              <span class="view-password " *ngIf="!isUpdating" (click)="showPassword()">
                <i class="fa fa-eye" *ngIf="!show"></i>
                <i class="fa fa-eye-slash" *ngIf="show"></i>
              </span>
            </div>
            <div class="form-group col-md-3">
                <label class="col-form-label"
                  > Proxy URL <span class="star">*</span></label
                >
                <input
                  type="url"
                  placeholder="Proxy URL"
                  formControlName="proxyUrl"
                  autocomplete="off"
                  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.proxyUrl.invalid && validate.proxyUrl.touched) ||
                    validate.proxyUrl.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.proxyUrl.errors?.required"
                    class="text-danger"
                    >Please enter Proxy URL</span
                  >
                  <span
                    *ngIf="validate.proxyUrl.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Proxy URL</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label">Description</label>
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="description"
                  placeholder="Description"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.description?.invalid &&
                      validate.description?.touched) ||
                    validate.description?.dirty ||
                    submitted
                  "
                >
                  <!-- <span *ngIf="validate.addressLine2.errors?.required" class="text-danger">Please enter Address Line 2</span> -->
                  <span
                    *ngIf="validate.description.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Description</span
                  >
                </div>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
                <button routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2" (click)="clear()">
                    Cancel
                </button>
                <button type="submit" mat-raised-button color="primary">Save</button>
            </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="text-center" *ngIf="success">
  <div class="alert alert-resource alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>