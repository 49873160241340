<router-outlet></router-outlet>

<div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Modules</span>
            </div>
            <div class="sub-header-buttons">
             
            </div>
        </div>
    </div>
</div>
<div class="common-content">
    <div class="card-new p-0 mb-5">
        <div class="home-cards">
            <div class="row db-card">
                <div *ngFor="let row of results"   class="modules-name col-md-4 mb-4">
                      
                    <a  (click)="selectModules(row.moduleCode)" class="text-primary1" *ngIf="row.moduleName !=undefined" title="{{ucFirst(row.moduleName)}}" routerLinkActive="active">
                        <div [ngStyle]="{'background-color': row.moduleCode == 'CHRMS' ? '#f1faff' : (row.moduleCode =='RQMNT' ? '#f1faff' : null) }" class="bg-light-primary left-section modules p-4 mt-1">
                            <div class="top-row" >
                                <mat-icon *ngIf=" row.moduleCode == 'CHRMS'" class="icon-primary1">verified_user</mat-icon>
                                <mat-icon *ngIf=" row.moduleCode == 'RQMNT'" class="icon-primary1">work</mat-icon>
                                <mat-icon *ngIf=" row.moduleCode == 'PAYROLL'" class="icon-primary1">payment</mat-icon>
                                <mat-icon *ngIf=" row.moduleCode == 'PMS'" class="icon-primary1">graphic_eq</mat-icon>
                                <mat-icon *ngIf=" row.moduleCode == 'LEAVE'" class="icon-primary1">calendar_month</mat-icon>
                                <mat-icon *ngIf=" row.moduleCode == 'PROJECTMANAGEMENT'"  class="icon-primary1">home</mat-icon>
                                <mat-icon *ngIf=" row.moduleCode == 'EXPENSEREIMBURSEMENT'" class="icon-primary1"> attach_money</mat-icon>
                                <span class="card-name1">
                                    {{ucFirst(row.moduleName)}}
                                </span>
                            
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
      
    </div>

</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
<!-- <h3>Welcome To JesperApps HCM</h3> -->

<!-- <div class="row" style="font-family: Mulish;">

    <div class="col-md-7   mt-5" style="max-height: 1000px; overflow-y: scroll; overflow-x: hidden;">
        <div *ngIf="!showFeedSection"  >
            <div  class="card new_class1" style="margin-left: 15px; margin-top: 30px;padding: 18px;">
                <h3 style="font-family: Mulish; font-weight: bolder;">create a feed!</h3>
                <div class="row">
                    <div class="col-md-2 col-sm-2 col-2 col-xs-2 col-xl-1" style="padding: 2px;" >
                        <img src="../../../assets/uploads/Prof Pic.png" alt="" width="50px" height="50px " >
    
                    </div>
                    <div class="col-md-10  col-sm-10 col-10 col-xs-10 col-xl-11">

                          <ckeditor #editor  data="<p></p>"></ckeditor>


                    </div>
                </div>
    
                <div class="row mt-3">
                    <div class="col-md-12 col-12 d-flex justify-content-end">
                        <button type="submit" class="btn btn-primary search-btn mb-3 mb-lg-0 mr-lg-3"
                            style="background-color:white; padding: 12px; display: flex; justify-content: center; align-items: center;border: 1px solid #ddd ;">
                            <i class="fa fa-paperclip" aria-hidden="true" style="color: black;"></i>
                            <small style="font-size: 13px; color: black; margin-left: 5px;font-weight:bolder;">Add File</small>
                        </button>
                        <button type="submit" class="btn btn-primary search-btn mb-3 mb-lg-0 mr-lg-3"
                            style="background-color:#1A83FF; padding: 12px; display: flex; justify-content: center; align-items: center;border: 0px solid black ;">
                            <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                            <small style="font-size: 13px; color: white; margin-left: 5px;"> Post</small>
                        </button>
                    </div>
                </div>
    
    
    
    
            </div>
    
            <div class="col-md-12 p-0">
                <div class="card mt-4 new_class1 " style="margin-left: 15px; margin-top: 30px;padding: 18px;">
                    <div class="row">
                        <div class="col-xl-1 col-sm-2 col-2 col-xs-2 col-md-2 ">
                            <img src="../../../assets/uploads/Image-47-10.png" alt="" width="50px" height="50px ">
    
                        </div>
                        <div class="col-xl-8 col-sm-7 col-7 col-md-7 ">
                            <p style="margin-top: 5px; font-weight: bolder; margin-left: 10px;">Harini <br><span><small>Angular
                                        Developer</small></span>
                            </p>
                        </div>
                        <div class="col-xl-3 col-sm-3 col-3 col-md-3 " style="margin-top: 5px;">
                            <small style="float: right;white-space: nowrap;">Dec 18,2023 <br><span><small
                                        style="float:right">4h</small></span></small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        <p>Good Morning Team!!! <br>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum, a est reprehenderit rem vel, aliquid ut consequuntur eligendi optio laudantium molestiae dolorem doloribus non harum. Excepturi dolores exercitationem officia sit.</p>
                        </div>
    
                    </div>
    
                    <div class=" col-md-12">
                        <div class="row mt-4">
                            <div class="col-xl-4 col-4 col-md-4" style="white-space: nowrap;" >
                                <div>
                                    <i class="fa fa-thumbs-o-up" style="margin-left: 30px;" aria-hidden="true"></i>
                                    <p style="display:inline; margin-left: 5px;">Like</p>
                                </div>
    
                            </div>
                            <div class="col-xl-4 col-4 col-md-4"  style="white-space: nowrap;">
                                <i class="fa fa-comment-o" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Comment</p>
    
                            </div>
                            <div class="col-xl-4 col-4 col-md-4" style="white-space: nowrap;">
                                <i class="fa fa-share-alt" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Share</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-xl-1 col-sm-2 col-2 col-xs-2 col-md-2">
                            <img src="../../../assets/uploads/Image-47-3.png" alt="" width="50px" height="50px ">
    
                        </div>
                        <div class="col-xl-8 col-sm-7 col-7 col-md-7 ">
                            <p style="margin-top: 5px; font-weight: bolder; margin-left: 10px;">Vaishnavi <br><span><small>Angular
                                        Developer</small></span>
                            </p>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3 col-xl-3 " style="margin-top: 5px;">
                            <small style="float: right;white-space: nowrap;">Dec 18,2023 <br><span><small
                                        style="float:right">4h</small></span></small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        <p>Good Morning Team!!!</p>
                        </div>
    
                    </div>
    
                    <div class=" col-md-12">
                        <div class="row mt-4">
                            <div class="col-md-4 col-4" style="white-space: nowrap;" >
                                <div>
                                    <i class="fa fa-thumbs-o-up" style="margin-left: 30px;" aria-hidden="true"></i>
                                    <p style="display:inline; margin-left: 5px;">Like</p>
                                </div>
    
                            </div>
                            <div class="col-md-4 col-4"  style="white-space: nowrap;">
                                <i class="fa fa-comment-o" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Comment</p>
    
                            </div>
                            <div class="col-md-4 col-4" style="white-space: nowrap;">
                                <i class="fa fa-share-alt" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Share</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-xl-1 col-sm-2 col-2 col-xs-2 col-md-2">
                            <img src="../../../assets/uploads/Prof Pic.png" alt="" width="50px" height="50px ">
    
                        </div>
                        <div class="col-xl-8 col-sm-7 col-7 col-md-7 ">
                            <p style="margin-top: 5px; font-weight: bolder; margin-left: 10px;">Divya <br><span><small>Angular
                                        Developer</small></span>
                            </p>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3 col-xl-3 " style="margin-top: 5px;">
                            <small style="float: right;white-space: nowrap;">Dec 18,2023 <br><span><small
                                        style="float:right">4h</small></span></small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <img src="../../../assets/uploads/Feed base.png" alt="" width="100%" height="100%">
    
                        </div>
    
                    </div>
    
                    <div class=" col-md-12">
                        <div class="row mt-4">
                            <div class="col-md-4 col-4" style="white-space: nowrap;" >
                                <div>
                                    <i class="fa fa-thumbs-o-up" style="margin-left: 30px;" aria-hidden="true"></i>
                                    <p style="display:inline; margin-left: 5px;">Like</p>
                                </div>
    
                            </div>
                            <div class="col-md-4 col-4"  style="white-space: nowrap;">
                                <i class="fa fa-comment-o" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Comment</p>
    
                            </div>
                            <div class="col-md-4 col-4" style="white-space: nowrap;">
                                <i class="fa fa-share-alt" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Share</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-xl-1 col-sm-2 col-2 col-xs-2 col-md-2">
                            <img src="../../../assets/uploads/Image-47-9.png" alt="" width="50px" height="50px ">
    
                        </div>
                        <div class="col-xl-8 col-sm-7 col-7 col-md-7 ">
                            <p style="margin-top: 5px; font-weight: bolder; margin-left: 10px;">Nandini Manohor <br><span><small>Angular
                                        Developer</small></span>
                            </p>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3 col-xl-3" style="margin-top: 5px;">
                            <small style="float: right;white-space: nowrap;">Dec 18,2023 <br><span><small
                                        style="float:right">4h</small></span></small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, velit cupiditate. Vel labore laudantium mollitia asperiores possimus unde ex cupiditate facilis deleniti, consequatur eaque molestiae, voluptates neque optio reiciendis fugit.
                            Facere illum saepe modi inventore temporibus iusto laborum dolore hic! Tempore magni obcaecati necessitatibus aliquid ea unde dolorem, earum reprehenderit ex, sunt qui optio voluptatibus atque accusamus illo repudiandae minima.
                            Nesciunt ea, fuga quod harum ullam, qui omnis illum maiores accusamus optio non laboriosam. Quia autem non fuga. Quibusdam accusantium, at necessitatibus a voluptas quo temporibus nam assumenda fuga consectetur!
                            Magnam dolorem itaque illum modi voluptatem quod doloribus minima commodi nesciunt repudiandae fugiat nihil quos esse velit accusamus, doloremque dicta in, sapiente officia eos error ea iste! Quibusdam, maxime libero!
                            Adipisci aperiam placeat sit quae architecto fugit magnam doloribus doloremque molestiae inventore porro perferendis laboriosam ut aliquam ullam, harum facere, natus, quam necessitatibus quidem? Odio aut molestias accusantium fugiat adipisci.
                            Error, quo et. Deleniti perspiciatis perferendis in quod vel iure aperiam ducimus natus nisi mollitia dolore earum suscipit recusandae dicta, corrupti harum eligendi iusto. Modi maiores dolorum fugit quos libero.
                            Harum, quia ullam rem cum dolor dolorum repudiandae, enim vitae aut corporis voluptate commodi nihil quo incidunt. Maiores esse reiciendis accusamus libero deserunt voluptas nobis, quos ea fugiat quisquam dolores.
                            Quo, impedit. Ullam atque, vel ipsum officia unde quae dolore eum reprehenderit natus. Aut sequi, aperiam eius reprehenderit voluptas quaerat distinctio eos iure qui debitis doloremque quisquam eligendi praesentium nihil?</p>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <img src="../../../assets/uploads/Feed base.png" alt="" width="100%" height="100%">
    
                        </div>
    
                    </div>
    
                    <div class=" col-md-12">
                        <div class="row mt-4">
                            <div class="col-md-4 col-4" style="white-space: nowrap;" >
                                <div>
                                    <i class="fa fa-thumbs-o-up" style="margin-left: 30px;" aria-hidden="true"></i>
                                    <p style="display:inline; margin-left: 5px;">Like</p>
                                </div>
    
                            </div>
                            <div class="col-md-4 col-4"  style="white-space: nowrap;">
                                <i class="fa fa-comment-o" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Comment</p>
    
                            </div>
                            <div class="col-md-4 col-4" style="white-space: nowrap;">
                                <i class="fa fa-share-alt" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Share</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-xl-1 col-sm-2 col-2 col-xs-2 col-md-2">
                            <img src="../../../assets/uploads/Prof Pic.png" alt="" width="50px" height="50px ">
    
                        </div>
                        <div class="col-xl-8 col-sm-7 col-7 col-md-7 ">
                            <p style="margin-top: 5px; font-weight: bolder; margin-left: 10px;">keerthana <br><span><small>Angular
                                        Developer</small></span>
                            </p>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3 col-xl-3 " style="margin-top: 5px;">
                            <small style="float: right;white-space: nowrap;">Dec 18,2023 <br><span><small
                                        style="float:right">4h</small></span></small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <img src="../../../assets/uploads/Feed base.png" alt="" width="100%" height="100%">
    
                        </div>
    
                    </div>
    
                    <div class=" col-md-12">
                        <div class="row mt-4">
                            <div class="col-md-4 col-4" style="white-space: nowrap;" >
                                <div>
                                    <i class="fa fa-thumbs-o-up" style="margin-left: 30px;" aria-hidden="true"></i>
                                    <p style="display:inline; margin-left: 5px;">Like</p>
                                </div>
    
                            </div>
                            <div class="col-md-4 col-4"  style="white-space: nowrap;">
                                <i class="fa fa-comment-o" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Comment</p>
    
                            </div>
                            <div class="col-md-4 col-4" style="white-space: nowrap;">
                                <i class="fa fa-share-alt" style="margin-left: 30px;" aria-hidden="true"></i>
                                <p style="display:inline; margin-left: 5px;">Share</p>
                            </div>
                        </div>
                    </div>
                    <hr>
        </div>
   
            </div>
        </div>



        <div class="row">
            <div *ngIf="showFeedSection">
                <div class="card " style="position: relative; font-family: Mulish; margin-left: 15px; margin-top: 30px;">
                    <i class="fa fa-arrow-left" (click)="hideFeed()" aria-hidden="true" style="position: absolute; top: 15px; left: 10px; background-color: #F7F7F7; border-radius: 20px; padding: 9px;"></i>
                    <img src="../../../assets/uploads/Feed base.png" alt="">
                    <h2 style="color: #567AFA; font-size: x-large; font-family: Mulish; padding: 20px;">Hooray Christmas is coming!!!</h2>
                    <h3 style="font-weight: bolder; font-family: Mulish; padding: 20PX;">Dear JesPERIANS!</h3>
                   <p style="margin-left: 3%; margin-top: -1%; margin-right: 3%;">Christmas is a cherished holiday celebrated globally, marking the birth of Jesus Christ. It's a time of joy, giving, and togetherness, where families and friends come together to exchange gifts, share meals, and create lasting memories. Festive decorations adorn homes and streets, twinkling lights illuminate the night sky, and the air is filled with the melodies of beloved carols. From the anticipation of Santa's visit to the warmth of gathering around a crackling fire, Christmas embodies the spirit of love, hope, and goodwill to all.</p>
                    <h3 style="font-weight: bolder; font-family: Mulish; padding: 20PX;">what is screat santa?</h3>
                    <p style="margin-left: 3%; margin-top: -1%; margin-right: 3%;">
It seems like you're referring to "Santa Claus," which is a figure associated with Christmas. Santa Claus, also known as Saint Nicholas, Kris Kringle, Father Christmas, or simply Santa, is a legendary figure originating in Western Christian culture. He is said to bring gifts to the homes of well-behaved children on the night of Christmas Eve (December 24th) or during the early morning hours of Christmas Day (December 25th). Santa Claus is typically depicted as a jolly, white-bearded man wearing a red coat with white fur trim, red trousers, and a red hat with a pom-pom. He is often portrayed as residing at the North Pole with a workshop staffed by elves who help him make toys for children. The modern image of Santa Claus has been influenced by various cultural and historical sources, including the Dutch figure of Sinterklaas, the English figure of Father Christmas, and the 1823 poem "A Visit from St. Nicholas" (commonly known as "The Night Before Christmas"). Santa Claus is a central figure in the celebration of Christmas in many parts of the world, representing the spirit of giving, generosity, and joy during the holiday season.</p>


                </div>
                
            </div>


        </div>

    </div>

    <div class="col-md-5 mt-5 ">
        <div class="card new_class jan" style="margin-right: 15px; margin-top: 30px;border-top-left-radius: 10px;border-top-right-radius: 10px; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px; ">
            <div class="col-md-12" style="background-color:#426AF8 ;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                <h3 style="color: white;margin:0px;padding:7px 0px
                ">Announcement</h3>

            </div>

            <div style="max-height: 400px; overflow-y: scroll; overflow-x: hidden;">
                <div class="row mt-3">
                    <div class="col-md-12">
                        <ng-template #announcementTemplate let-message="message" let-date="date" let-messageg1="message1" let-time="time">
                            <div class="row">
                                <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                                    <i class="fa fa-circle mr-2" style="color: grey; margin-left: 15px;" aria-hidden="true"></i>
                                </div>
                                <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                                    <p style="display: inline; font-weight: bolder;">{{ message }}</p>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                                    <small style="float: right; margin-right: 10px; white-space: nowrap;">{{ date }}</small> 
                                </div>
                            </div>
                        </ng-template>
                
                        <div (click)="showFeed('Hooray! Christmas is coming!', 'Dec 15, 2023')">
                            <ng-container *ngTemplateOutlet="announcementTemplate; context: { message: 'Hooray! Christmas is coming!', date: 'Dec 15, 2023' }"></ng-container>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-8 col-9 col-sm-9">
                        <small style="display: block; margin-left: 32px; ">"Hooray! Christmas is coming!"</small>

                    </div>
                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                        <small style="float: right; margin-right: 10px;">10.00 AM</small>
                    </div>

                </div>
                <hr>
                
                <div class="row">
                    <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                        <i class="fa fa-circle mr-2" style="color: grey; margin-left: 15px;" aria-hidden="true"></i>

                    </div>
                    <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                        <p style="display: inline; font-weight: bolder;">"Hooray! Christmas is coming!" </p>

                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                        <small
                        style="float: right; margin-right: 10px; white-space: nowrap;">Dec 15, 2023</small> 
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-8 col-9 col-sm-9">
                        <small style="display: block; margin-left: 32px; ">"Hooray! Christmas is coming!"</small>

                    </div>
                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                        <small style="float: right; margin-right: 10px;">10.00 AM</small>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                        <i class="fa fa-circle mr-2" style="color: grey; margin-left: 15px;" aria-hidden="true"></i>

                    </div>
                    <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                        <p style="display: inline; font-weight: bolder;">"Hooray! Christmas is coming!" </p>

                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                        <small
                        style="float: right; margin-right: 10px; white-space: nowrap;">Dec 15, 2023</small> 
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-8 col-9 col-sm-9">
                        <small style="display: block; margin-left: 32px; ">"Hooray! Christmas is coming!"</small>

                    </div>
                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                        <small style="float: right; margin-right: 10px;">10.00 AM</small>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                        <i class="fa fa-circle mr-2" style="color: grey; margin-left: 15px;" aria-hidden="true"></i>

                    </div>
                    <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                        <p style="display: inline; font-weight: bolder;">"Hooray! Christmas is coming!" </p>

                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                        <small
                        style="float: right; margin-right: 10px; white-space: nowrap;">Dec 15, 2023</small> 
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-8 col-9 col-sm-9">
                        <small style="display: block; margin-left: 32px; ">"Hooray! Christmas is coming!"</small>

                    </div>
                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                        <small style="float: right; margin-right: 10px;">10.00 AM</small>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                        <i class="fa fa-circle mr-2" style="color: grey; margin-left: 15px;" aria-hidden="true"></i>

                    </div>
                    <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                        <p style="display: inline; font-weight: bolder;">"Hooray! Christmas is coming!" </p>

                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                        <small
                        style="float: right; margin-right: 10px; white-space: nowrap;">Dec 15, 2023</small> 
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-8 col-9 col-sm-9">
                        <small style="display: block; margin-left: 32px; ">"Hooray! Christmas is coming!"</small>

                    </div>
                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                        <small style="float: right; margin-right: 10px;">10.00 AM</small>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                        <i class="fa fa-circle mr-2" style="color: grey; margin-left: 15px;" aria-hidden="true"></i>

                    </div>
                    <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                        <p style="display: inline; font-weight: bolder;">"Hooray! Christmas is coming!" </p>

                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                        <small
                        style="float: right; margin-right: 10px; white-space: nowrap;">Dec 15, 2023</small> 
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-8 col-9 col-sm-9">
                        <small style="display: block; margin-left: 32px; ">"Hooray! Christmas is coming!"</small>

                    </div>
                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                        <small style="float: right; margin-right: 10px;">10.00 AM</small>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                        <i class="fa fa-circle mr-2" style="color: grey; margin-left: 15px;" aria-hidden="true"></i>

                    </div>
                    <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                        <p style="display: inline; font-weight: bolder;">"Hooray! Christmas is coming!" </p>

                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                        <small
                        style="float: right; margin-right: 10px; white-space: nowrap;">Dec 15, 2023</small> 
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-8 col-9 col-sm-9">
                        <small style="display: block; margin-left: 32px; ">"Hooray! Christmas is coming!"</small>

                    </div>
                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                        <small style="float: right; margin-right: 10px;">10.00 AM</small>
                    </div>

                </div>
                <hr>
            </div>


        </div>
        <div class="card new_class" style="margin-right: 15px; margin-top: 30px;border-top-left-radius: 10px;border-top-right-radius: 10px; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div class="col-md-12" style="background-color:#426AF8  ;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                <h3 style="color: white;margin:0px;padding:7px 0px">Message <span style="float: right;"><i class="fa fa-pencil-square-o"
                            aria-hidden="true"></i></span></h3>

            </div>
            <div class="col-md-12 col-12 mt-3">
                <input class="form-control search-input form-control-lg form-control-solid" type="text"
                    placeholder="Search.." name="search">
                <i class="fa fa-search"
                    style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%);"></i>

            </div>
            <div style="max-height: 390px; overflow-y: scroll; overflow-x: hidden;">
                <div class="row" style="padding: 20px;"   (click)="viewchatbox(element)">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Prof Pic.png" alt="" width="50px" height="50px" style="padding:6px;"
                        >
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor <br><span><small>Hi Thrilokesh quick question can i
                                    help u?</small></span></p>
                    </div>
                    <div class="col-xl-4 col-md-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-10.png" alt="" width="50px" height="50px" style="padding:6px;"
                        >
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor <br><span><small>Hi Thrilokesh quick question can i
                                    help u?</small></span></p>
                    </div>
                    <div class="col-md-4 col-xl-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-2.png" alt="" width="50px" height="50px" style="padding:6px;"
                        >
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor <br><span><small>Hi Thrilokesh quick question can i
                                    help u?</small></span></p>
                    </div>
                    <div class="col-md-4 col-xl-4 col-4" style="margin-top: 5px;">
                        <small style="float: right;white-space: nowrap;">Dec 18, 2023 <br><span><small style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-3.png" alt="" width="50px" height="50px" style="padding:6px;"
                        >
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor <br><span><small>Hi Thrilokesh quick question can i
                                    help u?</small></span></p>
                    </div>
                    <div class="col-md-4 col-xl-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-9.png" alt="" width="50px" height="50px" style="padding:6px;"
                        >
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor <br><span><small>Hi Thrilokesh quick question can i
                                    help u?</small></span></p>
                    </div>
                    <div class="col-xl-4 col-md-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">

            </div>


        </div>
    </div>

</div> -->