import { Component, OnInit,Inject } from '@angular/core';
import { UserService } from '../../providers/user.service';
import { AddUserComponent } from '../add-user/add-user.component';
import { httpOptions,redirectMsgTimeOut,responseMsgTimeOut}  from '../../providers/properties';
import {Sort} from '@angular/material/sort';
import { ExcelService} from '../../services/excel.service';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit
{
  constructor(
    private userservice: UserService,
    public ExcelService:ExcelService,
    @Inject(AppComponent) public AppData: any,
  ) { }

  searchForm!:FormGroup;

  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  success = false;
  error:any=false;
  tableSearch : any;

  result:any;
  errorMsg : any;
  sucess_msg:any;
  error_msg:any;
  dataExport = [];
  sortedData: any[];
  exportingdata:any;
  exported:any;
  firstName:any;
  lastName:any;
  employeeNumber:any;
  loader:any= false;
  backdrop:any= false;
  nodatafound: boolean = true;
  employeetData:any;

  dateFormat : any;
  organizationId : any;

  ngOnInit(): void
  {
    this.dateFormat = localStorage.getItem('dateFormatName');
   // this.organizationId = this.AppData.organizationId;
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId =  this.employeetData.organization.organizationId
    this.getFilterFormData();
  }

  getFilterFormData()
  {
    this.searchForm = new FormGroup(
      {
        keywords : new FormControl(this.searchForm)
      }
    );
  }

  searchSubmit()
  {
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;

    if(keywords == null)
    {
      alert('Please search keywords');
      return;
    }

    if(keywords)
    {
      this.loader=true;
      this.backdrop=true;

      let organizationId = this.organizationId;

      this.userservice.getUserListKeyword(organizationId,keywords).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
  }

  getUserList()
  {
    /* this.userservice.getUserList().subscribe((result: any) => {
      this.resultData = result;
    }) */

    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;

    if(keywords)
    {
      this.loader=true;
      this.backdrop=true;

      let organizationId = this.organizationId;

      this.userservice.getUserListKeyword(organizationId,keywords).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
  }

  //Export start here
  exportToExcel()
  {
    if(this.searchForm.value.keywords !=  null)
    {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData ;
      
      for(let exportingdata of this.exportingdata)
      {
        if(exportingdata.employee != undefined && exportingdata.employee.firstName){
          this.firstName = exportingdata.employee.firstName;
        }else{
          this.firstName ="";
        }

        if(exportingdata.employee != undefined && exportingdata.employee.lastName){
          this.lastName = exportingdata.employee.lastName;
        }else{
          this.lastName ="";
        }

        if(exportingdata.employee != undefined && exportingdata.employee.employeeNumber){
          this.employeeNumber = exportingdata.employee.employeeNumber;
        }else{
          this.employeeNumber ="";
        }

        this.exported ={
          employeeNumber : this.employeeNumber,
          emailId        : exportingdata.emailId,
          Name           : this.firstName + " " + this.lastName,
        }
        this.dataExport.push(this.exported);
      }

      this.ExcelService.exportAsExcelFile(this.dataExport,"user_list");
    }
    else
    {
       this.dataExport.length = 0;
      this.exportingdata = this.resultData ;

      for(let exportingdata of this.exportingdata)
      {
        if(exportingdata.employee != undefined && exportingdata.employee.firstName){
          this.firstName = exportingdata.employee.firstName;
        }else{
          this.firstName ="";
        }

        if(exportingdata.employee != undefined && exportingdata.employee.lastName){
          this.lastName = exportingdata.employee.lastName;
        }else{
          this.lastName ="";
        }

        if(exportingdata.employee != undefined && exportingdata.employee.employeeNumber){
          this.employeeNumber = exportingdata.employee.employeeNumber;
        }else{
          this.employeeNumber ="";
        }

        this.exported ={
          employeeNumber : this.employeeNumber,
          emailId        : exportingdata.emailId,
          Name           : this.firstName + " " + this.lastName,
        }
        this.dataExport.push(this.exported);
      }
      console.log("EX",this.exported);
      this.ExcelService.exportAsExcelFile(this.dataExport,"user_list");
    }
  }
  //Export end here

  updateStatus(userId,status)
	{
    this.userservice.updateUserStatus(userId,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.getUserList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  /* updateStatus(userId,status)
	{
    this.userservice.updateUserStatus(userId,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.getUserList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	} */

  ucFirst(string)
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '')
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) =>
    {
      const isAsc = sort.direction === 'asc';

      switch (sort.active)
      {
        case 'employeeNumber':
          return compare(a.employeeNumber, b.employeeNumber, isAsc);

        case 'Name':
          return compare(a.Name, b.Name, isAsc);

        case 'Email':
          return compare(a.Email, b.Email, isAsc);

        default:
          return 0;
      }
    });
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
