<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content pr-3">Date Formats</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/super-admin-home/settings" mat-raised-button title="Back" class="">Back</a>
                    <a (click)="addForm()" title="Add Date Format" routerLinkActive="active" color="primary" mat-raised-button class="ml-2">
                        Add Date Format
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-8">
                        <div class="row mb-3 pl-0">
                            <div class="col-md-4">
                                <input type="search" [(ngModel)]="tableSearch" autocomplete="off" class="form-control form-control-lg form-control-solid search-input" name="keywords" value="" placeholder="Search...">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-9 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0">
                                <select [(ngModel)]="pagePerItem" class="form-select form-select-solid form-select-lg report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <option value="1500">1500</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <table matSort (matSortChange)="sortData($event)" class="table --table-responsive table-bordered table-border-new">
                    <thead class="t-head">
                        <tr>
                            <!-- <th class="text-center">S.No</th> -->
                            <th class="text-center">Action</th>
                            <th mat-sort-header="dateFormat">Date Format</th>
                            <th mat-sort-header="dateFormatDescription" class="text-left">Date Format Description</th>
                            <th class="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                            <!-- <td class="text-center">{{i+1}}</td> -->
                            <td class="text-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="controls-list">
                                    <button mat-menu-item (click)="updateform(row)">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button>

                                    <button mat-menu-item *ngIf="row.status.listTypeValueId == 1" (click)="updateStatus(row.dateFormatId,2)">
                                        <mat-icon>done</mat-icon>
                                        <span>Inactive</span>
                                    </button>

                                    <button mat-menu-item *ngIf="row.status.listTypeValueId == 2" (click)="updateStatus(row.dateFormatId,1)">
                                        <mat-icon>done</mat-icon>
                                        <span>Active</span>
                                    </button>
                                </mat-menu>
                            </td>
                            
                            <td class="text-left">{{row.dateFormatName}}</td>
                            <td class="text-left">{{ucFirst(row.dateFormatDescription)}}</td>
                            <td class="active-status text-center">
                                <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                    {{row.status.listTypeValueName}}
                                </span>

                                <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                    {{row.status.listTypeValueName}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row">
                    <div class="col-md-4">
                        <div class="showing-record">
                            <ng-template pTemplate="paginatorleft" let-state>
                                Showing {{(state.page * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                            </ng-template>
                            <!--  {{resultData.length}} rows -->
                        </div>
                    </div>
                    <div class="col-md-8 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>