<section>
    <div class="sub-header">
      <div class="col-md-12 mb-3">
        <div class="row">
          <div class="">
            <span class="h5 sub-content"> Manage Employee Level</span>
          </div>
          <div class="sub-header-buttons">
            <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
            <a routerLink="/home/" mat-raised-button  class=""
              >Back</a
            >
            <a
              routerLink="/home/assignEmp"
            
              mat-raised-button
              class="ml-2 searchBtn"
            >
              Add Employee Level
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


  <div class="common-content">
    <div class="card-new">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
            <div class="row">
                <div class="form-group col-md-3">
                    <label class="form-control-label"
                      >Employee Code</label
                    >
                    <!-- <input formControlName="employeecode" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search..."> -->
                    <input
                      type="text"
                      placeholder="Search Employee Code"
                      formControlName="employeecode"
                      [matAutocomplete]="auto"
                      class="form-control email-input select-placeholder"
                      (keydown)="onKeyDown($event)"
                    (keyup)="empSearch($event)"
                    
                    
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      
                      <mat-option
                        *ngFor="let emp of empList"
                        [value]="emp.employeeId"
                        (click)="empSelect(emp)"
                      >
                        {{ emp.employeeNumber }} - {{ emp.firstName }}
                      </mat-option>
                      <mat-option *ngIf="empCode">Employee Code Not Found</mat-option>
                    </mat-autocomplete>
                    <mat-icon
                      class="material-symbols-outlined"
                      style="position: absolute; top: 40px; right: 20px"
                    >
                      search
                    </mat-icon>
                    <!-- <div
                      class="valid-msg"
                      *ngIf="
                        (validate.employeecode.invalid &&
                          validate.employeecode.touched) ||
                        validate.employeecode.dirty ||
                        submitted
                      "
                    >
                      <span
                        *ngIf="validate.employeecode.errors?.required"
                        class="text-danger"
                        >Please Select Employee Code</span
                      >
                    </div> -->
                  </div>


              <div class="form-group col-md-3">
                <label class="form-control-label"> Employee Level Name<span class="star"> *</span> </label>
                <mat-select
                  formControlName="assignEmployeelist"
                  class="form-control email-input select-placeholder"
                  
                  placeholder="Select Business Unit Name  "
                  (selectionChange)="onCityChange($event)"
                 
                >
                  <mat-option [value]="null">-- Select Employee Level  ---</mat-option>
                  <mat-option [value]="1">Business Group</mat-option>
                      <mat-option [value]="2">Business Unit</mat-option>
                      <mat-option [value]="3">Organization</mat-option>
                </mat-select>
              </div>


              <div class="form-group col-md-3">
                <label class="form-control-label"> Business Level Name<span class="star"> *</span></label>
                <mat-select
                  formControlName="LevelName"
                  class="form-control email-input select-placeholder"
                  placeholder="Select Business Level Name"
                >
                  <mat-option [value]="null">-- Select Business Level ---</mat-option>
                  <!-- <mat-option [value]="null" selected="selected"> ---Payroll Run Level Name --- </mat-option> -->
                  <mat-option  *ngFor="let row of BusinessUnit; let i=index;" [value]="row.businessGroupId" (click)="passedId(row)" >{{row.businessGroupName}}</mat-option>
    
                  <mat-option  *ngFor="let row of BusinessName; let i=index;" [value]="row.businessUnitId"(click)="passedId(row)" >{{row.businessUnitName}}</mat-option>
                  <mat-option *ngFor="let row of OrganizationName; let i=index;"[value]="row.organizationId"(click)="passedId(row)" >{{row.organizationName}}</mat-option>
                  
                </mat-select>
              </div>
              <div class="col-md-3 button-margin" >
                <!-- <button type="submit" mat-raised-button color="primary"
                                      class="btn btn-primary search-btn">Search</button> -->
  
                <button
                  type="submit"
                  mat-raised-button
                  class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                  [disabled]="isProcessing"
                  style="margin-top:30px"
                >
                  Search
                </button>
                <!-- <button style="margin-left: 10px;" type="reset" mat-raised-button
                                       class="btn search-btn mr-2"
                                      (click)="clearTable()">Clear</button> -->
  
                <button
                  mat-stroked-button
                  type="reset"
                  class="apply-buttons"
                  (click)="clearTable()"
                  [disabled]="isProcessing"
                  style="
                    margin: 8px 0px 0px 5px;
                    background: white;
                    border: 1px solid #ddd;
                    position: relative;
                    
                    font-family: Mulish;
                    color: black;
                    font-size: 16px;
                    font-weight: 610;
                    top:-8%;
                  "
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
          <div
            class="row pb-3"
            *ngIf="!loader && showtable && resultData?.length > 0"
          >
          
  
            <div class="text-right">
              <div class="select-pagination m-2" *ngIf="resultData?.length>5">
                <span>Show : </span>
                <select
                  class="select_list new-select_list listDesign"
                  [(ngModel)]="pagePerItem"
                  (change)="pageChages($event)"
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>
  
          <div *ngIf="showtable && !loader" class="table-responsive">
            <!-- <p><b>EMPLOYEE LEAVE</b></p> -->
            <table
              *ngIf="!loader"
              class="table --table-bordered table-border-new"
              style="border-top-left-radius: 10px; border-top-right-radius: 10px"
            >
              <thead class="t-head">
                <tr>
                    <th class="col-md-2"   style="border-radius: 15px 1px 0px 0">Action</th>
                    <th class="col-md-3">Employee Code</th>
                    <th class="col-md-3">Employee  Name</th>
                    <th class="col-md-4"   style="border-radius: 0px 15px 0px 0">Level Name</th>
                </tr>
              </thead>
              <tbody *ngIf="resultData.length > 0">
                <tr
                  *ngFor="
                    let row of resultData
                      | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                      | searchFilter : tableSearch;
                    let i = index
                  "
                >
                  <td class="">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
  
                    <mat-menu #menu="matMenu" class="controls-list">
                      <button
                        mat-menu-item
                        routerLink="/home/editassignEmp/{{
                          row.employeelevelAssignId
                        }}"
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                      
                    </mat-menu>
                  </td>

                  <td>{{row.employee.employeeNumber}}</td>
                  <td>
                    {{row.employee.firstName}} {{row.employee.lastName}}
                    </td>
                    <td>
                        {{row.businessgroup?.businessGroupName || row.organization?.organizationName || row.businessunit?.businessUnitName }}
                    </td>

                  
                  
                  
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    style="text-align: center; font-size: 21px; font-weight: 770"
                    colspan="6"
                    *ngIf="!loader && noRecordsFound"
                  >
                    No Records Found!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <div>
            <div class="row">
              <!-- <div class="col-md-4" *ngIf="!this.noRecordsFound && !loader">
                <div class="showing-record">{{ resultData.length }} rows</div>
              </div> -->
              <div class="col-md-8" *ngIf="!loader && resultData?.length > 5">
                <pagination-controls
                  style="float: right"
                  (pageChange)="p = $event"
                  *ngIf="!this.noRecordsFound"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  <!-- <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
    <button
      *ngIf="importerror"
      (click)="showerrormessage()"
      class="btn btn-danger"
    >
      View Error Records
    </button>
  </div> -->
  
  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
      role="status"
    ></div>
  </div>