<!-- <div class="container" style="margin-top:70px">
    <h4 class="material-icons float-right" style="color:red;"  align="end" mat-dialog-close>
        cancel
    </h4>
    <h4>Assign Approvers</h4>
    <form [formGroup]="projectApproverForm" (ngSubmit)="Addprojectapprover()">
      <div class="input-width" id="loginform">
        <label class="form-control-label">Project<span class="text-danger">*</span></label>
        <mat-select formControlName="project" 
        class="form-control email-input select-placeholder"
        placeholder="Select Project"
          [disabled]="projectreadonly"  
          (selectionChange) = "projectapprovervalidate()">
          <mat-option *ngFor="let project of projectList" [value]="project.projectId">
            {{project.projectName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.project.invalid && submitted1">Project is required</mat-error>
      </div>
      <span *ngIf="projectapproverexist"  class="span-error"> Approver Already Exist</span>
      <div  class="input-width"  id="loginform">
          <label  class="form-control-label">Approver<span class="text-danger">*</span></label>
          <mat-select formControlName="approver"
            placeholder="Select Approver Rule"
            class="form-control email-input select-placeholder">
            <mat-option *ngFor="let approver of ApproverList" [value]="approver.approverId">
              {{approver.rulename}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.approver.invalid && submitted1">Approver is required</mat-error>
      </div>
      <div mat-dialog-actions align="end">
        <button
          class="btn create-button"
          type="submit"
          [disabled]="projectApproverForm.invalid"
          cdkFocusInitial
          style="margin-bottom:15px;"
        >
        {{data.projectApproverId ? 'Update': 'Create'}}
        </button>
      </div>
    </form>
  </div> -->
<!-- <mat-card style="margin-top: 70px;"> -->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Assign Approvers</span>
        </div>
        <div class="sub-header-buttons">
          <a
            routerLink="/home/assign-approver"
            mat-raised-button
            title="Back"
            class=""
            >Back</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card" style="background: none">
      <div
        class="card-body"
        style="
          border-radius: 15px;
          border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
          background: #fdfdfd;
        "
      >
        <div class="mb-2">
          <div class="col-md-12 p-0">
            <form
              [formGroup]="projectApproverForm"
              (ngSubmit)="Addprojectapprover()"
            >
              <!-- Task Type -->
              <div class="row">
                <div class="col-md-3">
                  <div class="input-width" id="loginform">
                    <label class="col-control-label"
                      >Project<span class="text-danger"> *</span></label
                    >
                    <mat-select
                      formControlName="project" [disabled]="projectreadonly"
                      class="form-select form-select-solid form-select-lg"
                      placeholder="Select employee"
                      (selectionChange)="projectapprovervalidate()"
                    >
                      <mat-option
                        *ngFor="let project of projectList"
                        [value]="project.projectId"
                      >
                        {{ project.projectName }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.project.invalid && submitted1"
                      >Project is required</mat-error
                    >
                  </div>
                  <span *ngIf="projectapproverexist" class="span-error">
                    Approver Already Exist</span
                  >
                </div>
                <div class="col-md-3">
                  <!-- Task Type -->
                  <div class="input-width" id="loginform">
                    <label class="col-control-label"
                      >Approver<span class="text-danger"> *</span></label
                    >
                    <mat-select
                      formControlName="approver"
                      placeholder="Select Approver Rule"
                      class="form-select form-select-solid form-select-lg"
                      class="form-control email-input select-placeholder"
                    >
                      <mat-option
                        *ngFor="let approver of ApproverList"
                        [value]="approver.approverId"
                      >
                        {{ approver.rulename }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.approver.invalid && submitted1"
                      >Approver is required</mat-error
                    >
                  </div>
                  <mat-error *ngIf="f.approver.invalid && submitted1"
                    >Approver is required</mat-error
                  >
                </div>
                <!-- <div class="col-md-3">
                                        <div  class="input-width" id="loginform">
                                            <label class="col-control-label">Approver Type<span class="text-danger"> *</span></label>
                                            <mat-select formControlName="approverType"
                                                placeholder="Select Approver Type"
                                                class="form-select form-select-solid form-select-lg">
                                                <mat-option *ngFor="let approvertype of approvertype" [value]="approvertype.approverTypeId">
                                                {{approvertype.approverTypeName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="f.approverType.invalid && submitted1">Approver is required</mat-error>
                                        </div>
                                    </div> -->
                <div class="col-md-3">
                  <button
                    class="btn create-button"
                    type="submit"
                    [disabled]="projectApproverForm.invalid"
                    cdkFocusInitial
                    style="margin-bottom: 15px"
                  >
                    {{ data.projectApproverId ? "Update" : "Create" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
