import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, JsonPipe } from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';
import { PayrollService } from 'src/app/providers/payroll.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras } from '@angular/router';
import { AddEmployeeComponent} from '../add-employee/add-employee.component'
 
@Component({
  selector: 'app-matclientcomponents',
  templateUrl: './matclientcomponents.component.html',
  styleUrls: ['./matclientcomponents.component.scss']
})
export class MatclientcomponentsComponent implements OnInit {
  
  clientInfo!: FormGroup;
  userdata: any;
  organizationId: any;
  clientList: any;
  submitted: boolean = false;
  clientId:any;
  constructor(private formBuilder: FormBuilder,
    private router : Router,
    private datepipe : DatePipe,
    private payrollService: PayrollService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MatclientcomponentsComponent>
    )
    
     { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId =this.userdata.organization.organizationId;
    this.clientInfo = this.formBuilder.group({
      client : [null,[Validators.required]]
    })
     
    this.payrollService.getClientNameList(this.organizationId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
  }

  basicSubmit()
  {
    // this.submitted=false;
    console.log("submit",this.clientInfo);
    if(this.clientInfo.invalid||this.clientInfo.value.client=="null"||this.clientInfo.value.client==null)
    {
      console.log("Return")
      return;
    }
    this.clientId= this.clientInfo.value.client;
    console.log("clientId",this.clientId);
  //   const dialogRef = this.dialog.open(MatclientcomponentsComponent);

  // dialogRef.afterClosed().subscribe(result => {
  //   // Handle the result when the dialog is closed (e.g., navigate to the next page)
  //   if (result) {
      // Replace '/next-page' with the desired route
  //   }
  // const clientData = { client: this.clientInfo.value.client };
  // console.log("clientData",clientData);
  // this.dialogRef.close(clientData);
  // const clientData = {
  //   client: this.clientInfo.value.client
  //   // Add any other data you want to pass
  // };

  // const navigationExtras: NavigationExtras = {
  //   state: {
  //     data: clientData
  //   }
  // };
  
  // this.dialogRef.close();
  // this.router.navigate(['hhome/add-employee'], navigationExtras);
  
  // this.router.navigate(['home/add-employee']);
  // });
    // this.router.navigate(['home/add-employee']);

    const dataToSend = {
      client: this.clientInfo.value.client
      // Add any other data you want to pass
    };

    this.dialogRef.close();
    // Navigate to the desired route with query parameters
    this.router.navigate(['home/add-employee'], {
      queryParams: dataToSend
    });
  } 

  get basic()
  {
    return this.clientInfo.controls;
  }

}
