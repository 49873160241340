<div
  class="container scroll"
  style="height: 550px; overflow: auto; overflow-x: hidden"
>
  <!-- <h3
    class="material-icons float-right"
    style="color: red"
    align="end"
    mat-dialog-close
  >
    cancel
  </h3> -->
  <mat-icon
    class="material-icons float-right"
    style="color: red !important; margin-top: 17px"
    mat-dialog-close
    color="custom-red"
    >clear</mat-icon
  >

  <h4 class="add-project">{{ Id ? "Edit Project" : "Create Project" }}</h4>
  <!-- <form action="" [formGroup]="projectForm" (ngSubmit)="save()"  class="add-project-form">
    <div class="row">
      <div class="col-sm-6" id="loginform">
   
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>lock_outline</mat-icon>
            Project Code
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            matInput
            placeholder="Enter Project Code"
            formControlName="projectCode"
            (keyup)="projectcodevalidate()"
            (input)="validateInput($event)"
            class="form-control email-input select-placeholder"
          />
          <mat-error *ngIf="f.projectCode && submitted">{{
            projectCode()
          }}</mat-error>
        </div>
        <span *ngIf="projectcodeexist" class="span-error">
          Project Code Already Exist</span
        >
      </div>

      <div class="col-sm-6  " id="loginform">
      
        <div class="input-width  project-margin-mobile">
          <label class="form-control-label " >
            <mat-icon class="icon-size" matPrefix>layers</mat-icon> Project Name
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            matInput
            placeholder="Enter Project Name"
            formControlName="projectName"
            maxlength="20"
            (keyup)="projectnamevalidate()"
            (input)="validateInput($event)"
            class="form-control email-input select-placeholder"
          />
          <mat-error *ngIf="f.projectName && submitted">{{
            projectNameError()
          }}</mat-error>
        </div>
        <span *ngIf="ProjectNameexist" class="span-error">
          Project Name Already Exist</span
        >
      </div>
    </div>

   
    <div class="input-width project-margin-mobile">
      <label class="form-control-label mt-n1">
        <mat-icon class="icon-size" matPrefix>chat_bubble_outline </mat-icon>
        Project Detail Description
        <span class="text-danger">*</span>
      </label>
      <textarea
        name=""
        matInput
        id=""
        formControlName="projectDescription"
        placeholder="Enter Project Detail Description"
        class="form-control email-input select-placeholder textarea"
        (input)="validateInput($event)"
      ></textarea>
      <mat-error *ngIf="f.projectDescription && submitted">{{
        projectDescriptionError()
      }}</mat-error>
    </div>

    <div class="row mt-2">
      <div class="col-sm-6" id="loginform">
      
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>business_center</mat-icon>
            Project Type
            <span class="text-danger">*</span>
          </label>
          <mat-select
            formControlName="projectType"
            placeholder="Select projectType"
            class="form-control email-input select-placeholder"
          >
            <mat-option
              *ngFor="let project of projectType"
              [value]="project.listTypeValueId"
              (click)="stage(project.listTypeValueName)"
            >
              {{ project.listTypeValueName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.projectType && submitted">{{
            proType()
          }}</mat-error>
        </div>
      </div>

      <div class="col-sm-6" id="loginform">
      
        <div class="input-width project-margin-mobile">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>moving</mat-icon>
            Project Stage
            <span class="text-danger">*</span>
          </label>
          <mat-select
            formControlName="projectStage"
            placeholder="Select project Stage"
            class="form-control email-input select-placeholder"
          >
            <mat-option
              *ngFor="let project of projectStage"
              [value]="project.projectstageId"
            >
              {{ project.projectstagename }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.projectStage && submitted">{{
            proStage()
          }}</mat-error>
        </div>
      </div>
    </div>


    <div class="input-width" *ngIf="customerValue">
      <label class="form-control-label">
        <mat-icon class="icon-size" matPrefix>person_outline</mat-icon>
        Customer Name
        <span class="text-danger">*</span>
      </label>
      <mat-select
        formControlName="customer"
        placeholder="Select Customer Name"
        class="form-control email-input select-placeholder"
      >
        <mat-option *ngFor="let e of Customer" [value]="e.customerId">
          {{ e.customername }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.customer && submitted">{{
        customerError()
      }}</mat-error>
    </div>


    <div class="row mt-2">
     
      <div class="col-sm-6 " id="loginform">
        <div class="input-width  project-margin-mobile">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Planned Start Date
            <span class="text-danger">*</span>
          </label>
          <input
            matInput
            readonly
            [matDatepicker]="picker1"
            (click)="picker1.open()"
            formControlName="projectStartDate"
            (dateChange)="someMethodName($event);  updateEndDateMin($event); checkStartDate()"
            [disabled]="startDate_disable"
            class="form-control email-input select-placeholder"
            placeholder="Enter project start date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #picker1 touchUi></mat-datepicker>
          <mat-error *ngIf="f.projectStartDate && submitted">{{
            projectStartDate()
          }}</mat-error>
        </div>
      </div>

  
      <div class="col-sm-6 project-margin-mobile" id="loginform">
        <div class="input-width ">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Planned End Date
            <span class="text-danger">*</span>
          </label>
          <input
            matInput
            readonly
            [matDatepicker]="p1"
            (click)="p1.open()"
            formControlName="projectEndDate"
            [min]="min"
            [disabled]="endDate_disable"
            class="form-control email-input select-placeholder"
            placeholder="Enter project end date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="p1"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #p1 touchUi></mat-datepicker>
          <mat-error *ngIf="f.projectEndDate && submitted">{{
            projectEndDate()
          }}</mat-error>
        </div>
      </div>
    </div>


    <div class="row mt-2">
     
      <div class="col-sm-6" id="loginform">
        <div class="input-width project-margin-mobile">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Actual Start Date
          </label>
          <input
            matInput
            readonly
            [matDatepicker]="picker"
            (click)="picker.open()"
            formControlName="projectActualStartDate"
            (dateChange)="someMethodName1($event);updateEndDateMin($event); checkStartDate()"
            class="form-control email-input select-placeholder"
            placeholder="Enter project actual start date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #picker touchUi></mat-datepicker>
      
        </div>
      </div>


      <div class="col-sm-6" id="loginform">
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Actual End Date
          </label>
          <input
            matInput
            readonly
            [matDatepicker]="p"
            (click)="p.open()"
            formControlName="projectActualEndDate"
            [min]="min1"
            [disabled]="Date_disable"
            class="form-control email-input select-placeholder"
            placeholder="Enter project actual end date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="p"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #p touchUi></mat-datepicker>
       
        </div>
      </div>
    </div>

    <div class="row mt-n2">
   
      <div class="col-sm-6" id="loginform">
        <div class="input-width">
          <label class="form-control-label">
          
            <mat-icon class="icon-size" matPrefix>schedule</mat-icon>
            Budget Hours
          </label>
          <input
            (keypress)="noNumber($event)"
            formControlName="projectBudgetHours"
            class="form-control email-input select-placeholder"
            maxlength="4"
            minlength="2"
            placeholder="Enter project budget hours"
          />
          <mat-error *ngIf="f.projectBudgetHours && submitted">{{
            projectBudgetHours()
          }}</mat-error>
          <mat-error *ngIf="f.projectBudgetHours && submitted">{{
            projectBudgetHours1()
          }}</mat-error>
        </div>
      </div>

      <div class="col-sm-6" id="loginform">
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>moving</mat-icon>
            Project Status
            <span class="text-danger">*</span>
          </label>
          <mat-select
            formControlName="projectStatus"
            placeholder="Select project Status"
            class="form-control email-input select-placeholder"
            (selectionChange)="onProjectStatusChange($event)"
          >
            <mat-option
              *ngFor="let project of projectStatus"
              [value]="project.listTypeValueId"
            >
              {{ project.listTypeValueName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.projectStatus && submitted">{{
            proStatus()
          }}</mat-error>
        </div>
      </div>
      
    </div>

    <div align="end">
      <button mat-raised-button class="searchBtn " type="submit" >
        {{ Id ? "Update" : "Create" }}
      </button>
    </div>
  </form> -->

  <form
    action=""
    [formGroup]="projectForm"
    (ngSubmit)="save()"
    class="add-project-form"
  >
    <div class="row">
      <div class="col-sm-6">
        <!-- Project Code -->
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>lock_outline</mat-icon>
            Project Code
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            matInput
            placeholder="Enter Project Code"
            formControlName="projectCode"
            (keyup)="changePlaceholderColor($event)"
            (keyup)="projectcodevalidate()"
            (input)="validateInput($event)"
            class="form-control email-input select-placeholder"
          />
          <mat-error *ngIf="f.projectCode && submitted">{{
            projectCode()
          }}</mat-error>
        </div>
        <span *ngIf="projectcodeexist" class="span-error"
          >Project Code Already Exist</span
        >
      </div>

      <div class="col-sm-6">
        <!-- Project Name -->
        <div class="input-width project-margin-mobile">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>layers</mat-icon> Project Name
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            matInput
            placeholder="Enter Project Name"
            formControlName="projectName"
            (keyup)="changePlaceholderColor($event)"
            maxlength="30"
            (keyup)="projectnamevalidate()"
            (input)="validateInput($event)"
            class="form-control email-input select-placeholder"
          />
          <mat-error *ngIf="f.projectName && submitted">{{
            projectNameError()
          }}</mat-error>
        </div>
        <span *ngIf="ProjectNameexist" class="span-error"
          >Project Name Already Exist</span
        >
      </div>
    </div>

    <!-- Project description -->
    <div class="input-width project-margin-mobile mt-2">
      <label class="form-control-label">
        <mat-icon class="icon-size" matPrefix>chat_bubble_outline</mat-icon>
        Project Detail Description
        <span class="text-danger">*</span>
      </label>
      <textarea
        name=""
        matInput
        id=""
        (keyup)="changePlaceholderColor($event)"
        formControlName="projectDescription"
        placeholder="Enter Project Detail Description"
        class="form-control email-input textarea text-truncate"
        (input)="validateInput($event)"
      ></textarea>
      <mat-error *ngIf="f.projectDescription && submitted">{{
        projectDescriptionError()
      }}</mat-error>
    </div>

    <div class="row mt-3">
      <div class="col-sm-6">
        <!-- Project Type -->
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>business_center</mat-icon>
            Project Type
            <span class="text-danger">*</span>
          </label>
          <mat-select
            formControlName="projectType"
            placeholder="Select projectType"
            class="form-control email-input select-placeholder"
          >
            <mat-option
              *ngFor="let project of projectType"
              [value]="project.listTypeValueId"
              (click)="stage(project.listTypeValueName)"
            >
              {{ project.listTypeValueName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.projectType && submitted">{{
            proType()
          }}</mat-error>
        </div>
      </div>

      <div class="col-sm-6">
        <!-- Project Stage -->
        <div class="input-width project-margin-mobile">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>moving</mat-icon>
            Project Stage
            <span class="text-danger">*</span>
          </label>
          <mat-select
            formControlName="projectStage"
            placeholder="Select project Stage"
            class="form-control email-input select-placeholder"
          >
            <mat-option
              *ngFor="let project of projectStage"
              [value]="project.projectstageId"
            >
              {{ project.projectstagename }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.projectStage && submitted">{{
            proStage()
          }}</mat-error>
        </div>
      </div>
    </div>

    <!-- Customer Name -->
    <div class="input-width mt-2" *ngIf="customerValue">
      <label class="form-control-label">
        <mat-icon class="icon-size" matPrefix>person_outline</mat-icon>
        Customer Name
        <span class="text-danger">*</span>
      </label>
      <mat-select
        formControlName="customer"
        placeholder="Select Customer Name"
        class="form-control email-input select-placeholder"
      >
        <mat-option *ngFor="let e of Customer" [value]="e.clientId">
          {{ e.clientName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.customer && submitted">{{
        customerError()
      }}</mat-error>
    </div>

    <!-- Project Start and End Date -->
    <div class="row mt-3">
      <div class="col-sm-6">
        <!-- Project Start date -->
        <div class="input-width project-margin-mobile">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Planned Start Date
            <span class="text-danger">*</span>
          </label>
          <!-- Input and Datepicker code goes here -->
          <input
            matInput
            readonly
            [matDatepicker]="picker1"
            (click)="picker1.open()"
            formControlName="projectStartDate"
            #startDateInput
            (dateChange)="
              actualDates($event);
              someMethodName($event);
              updateEndDateMin($event);
              checkStartDate()
            "
            [disabled]="startDate_disable"
            class="form-control email-input select-placeholder"
            placeholder="Enter project start date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="f.projectStartDate && submitted">{{
            projectStartDate()
          }}</mat-error>
        </div>
      </div>

      <div class="col-sm-6 mt-n4 mt-md-0">
        <!-- Project End date -->
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Planned End Date
            <span class="text-danger">*</span>
          </label>
          <!-- Input and Datepicker code goes here -->
          <input
            matInput
            readonly
            [matDatepicker]="p1"
            (click)="p1.open()"
            #endDateInput
            formControlName="projectEndDate"
            [min]="min"
            (dateChange)="
              actualDates($event);
              someMethodName($event);
              updateEndDateMin($event);
              checkStartDate()
            "
            [disabled]="endDate_disable"
            class="form-control email-input select-placeholder"
            placeholder="Enter project end date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="p1"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #p1></mat-datepicker>
          <mat-error *ngIf="f.projectEndDate && submitted">{{
            projectEndDate()
          }}</mat-error>
        </div>
      </div>
    </div>

    <!-- Actual Start and End Date -->
    <div class="row mt-n2">
      <div class="col-sm-6">
        <!-- Project Actual Start date -->
        <div class="input-width project-margin-mobile">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Actual Start Date
          </label>
          <!-- Input and Datepicker code goes here -->
          <input
            matInput
            readonly
            [matDatepicker]="picker"
            #ActualStart
            (click)="picker.open()"
            formControlName="projectActualStartDate"
            (dateChange)="
              someMethodName1($event);
              updateEndDateMin($event);
              checkStartDate();
              actualDates($event)
            "
            class="form-control email-input select-placeholder"
            placeholder="Enter project actual start date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>

      <div class="col-sm-6 mt-n4 mt-md-0">
        <!-- Project Actual End date -->
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>today</mat-icon>
            Actual End Date
          </label>
          <!-- Input and Datepicker code goes here -->
          <input
            matInput
            readonly
            #ActualEnd
            [matDatepicker]="p"
            (dateChange)="actualDates($event)"
            (click)="p.open()"
            formControlName="projectActualEndDate"
            [min]="min1"
            [disabled]="Date_disable"
            class="form-control email-input select-placeholder"
            placeholder="Enter project actual end date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="p"
            style="float: right; position: relative; bottom: 42px"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #p></mat-datepicker>
        </div>
      </div>
    </div>

    <!-- Budget Hours and Project Status -->
    <div class="row mt-n4">
      <div class="col-sm-6">
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>schedule</mat-icon>
            Budget Hours
            <span class="text-danger">*</span>
          </label>
          <input
            (keypress)="noNumber($event); noZero($event)"
            formControlName="projectBudgetHours"
            (keyup)="changePlaceholderColor($event)"
            class="form-control email-input select-placeholder"
            maxlength="4"
            minlength="2"
            (paste)="onPaste($event)"
            placeholder="Enter project budget hours"
          />
          <mat-error *ngIf="f.projectBudgetHours && submitted">{{
            projectBudgetHours()
          }}</mat-error>
          <mat-error *ngIf="f.projectBudgetHours && submitted">{{
            projectBudgetHours1()
          }}</mat-error>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="input-width">
          <label class="form-control-label">
            <mat-icon class="icon-size" matPrefix>moving</mat-icon>
            Project Status
            <span class="text-danger">*</span>
          </label>
          <mat-select
            formControlName="projectStatus"
            placeholder="Select project Status"
            class="form-control email-input select-placeholder"
            (selectionChange)="onProjectStatusChange($event)"
          >
            <mat-option
              *ngFor="let project of projectStatus"
              [value]="project.listTypeValueId"
            >
              {{ project.listTypeValueName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.projectStatus && submitted">{{
            proStatus()
          }}</mat-error>
        </div>
      </div>
    </div>

    <!-- Button -->
    <div align="end" class="mt-3">
      <button
        mat-raised-button
        class="searchBtn"
        type="submit"
        [disabled]="
          isSaveButtonDisabled ||
          !saveDisable ||
          projectcodeexist ||
          ProjectNameexist
        "
      >
        Save
      </button>
    </div>
  </form>
</div>
