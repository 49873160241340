import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, EMPTY, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-employee-leave-add',
  templateUrl: './employee-leave-add.component.html',
  styleUrls: ['./employee-leave-add.component.scss']
})
export class EmployeeLeaveAddComponent implements OnInit {
  isprocessing1=false;

  length: number;
  dataArray: any = [];
  financialYear: any;
  period: string;
  employeelistsub: any;
  inputValue: any;
  finid: any;
  perid: any;
  users(users: any) {
    throw new Error('Method not implemented.');
  }
  leaveForm: FormGroup;
  today: any = new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  usertData: any;
  orgId: number;
  clientList: any;
  empList: any = [];
  empNum: number;
  nodatafound: boolean = true;
  update_error_msg: any;
  loader: any = false;
  backdrop: any = false;
  resultData: any = [];
  noRecordsFound: boolean = false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate: any;
  filterenddate: any;
  clientId: any;
  pagePerItem: any = 5;
  p: number = 1;
  tableSearch: any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg: any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported: any;
  exportingdata: any;
  beforepage: any;
  dateFormat: any;
  finYearList: any = [];
  chooseFinId: any;
  finPeriodList: any = [];
  empCode: boolean = false;
  empLeaves: any = [];
  selectedEmployeeData: any = [];
  financialPeriodList: any = [];
  empleaveId: number;
  employeetData: any;
  userdata: any;
  reportData: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  data: any[];
  // pagePerIist:any=10;
  startIndex: number;
  showtable:boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
    private payrollservice: PayrollService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,) { }


  ngOnInit(): void {
    this.loader = true; // Start loader

    this.data = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`);
    this.updatePagedData();

    this.leaveForm = this.fb.group({
      financialyear: [null, [Validators.required]],
      period: [null, [Validators.required]],
      employeecode: [null, [Validators.required]],
      Leaves: this.fb.array([], [Validators.required]),
      // employeeId:['']
    })

   // this.addRow();
    this.empleaveId = this.ActivatedRoute.snapshot.params.employeeleaveId;
    console.log(this.empleaveId);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);
    this.loader=true;

    if(this.orgId){
    this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if (item.financialYearStatus.listTypeValueId == 1) {
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
      this.loader=false;

    });
  }
  if(this.clientId){
    this.settingsService.getClientFinicalyearList( this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if (item.financialYearStatus.listTypeValueId == 1) {
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
      this.loader=false;

    });
  }


  

    if (this.empleaveId) {
      console.log("if patch");
      // this.selectedEmployeeData=[];
      
      this.payrollservice.getEmployeeLeaveById(this.empleaveId).subscribe((userdata: any) => {
        console.log('userdata', userdata)
        this.userdata = userdata;
        this.leaveForm.patchValue({
          financialyear: this.userdata.financialPeriod.finanicalYear.financialYearId, // Replace with the desired value
          period: this.userdata.financialPeriod.financialPeriodId, // Replace with the desired value
          employeecode: this.userdata.employee.employeeNumber
          // employeeNumber: 'EMP001', // Replace with the desired value
        });
        console.log("this.postForm", this.leaveForm)
        // Adding a row with leaveDays
        this.showtable = true;
        const updateData = {
          employeeId: this.userdata.employee.employeeId,
          employeeNumber: this.userdata.employee.employeeNumber,
          firstName: this.userdata.employee.firstName,
          lastName: this.userdata.employee.lastName,
          empleave: this.userdata.leave
        }
        console.log("updateData", updateData);
        this.selectedEmployeeData.push(updateData);

        console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
        const rowsArray = this.leaveForm.get('Leaves') as FormArray;
        console.log("Data:"+rowsArray);
        this.addRow();
        rowsArray.at(0).patchValue({
          employeeId: userdata.employee.employeeId,
          employeeNumber: userdata.employee.employeeNumber,
          firstName: userdata.employee.firstName,
          lastName: userdata.employee.lastName,
          empleave: userdata.leaveDays,
          employeeLeaveId: this.userdata.employeeLeaveId
        })

        

        this.employeeId = userdata.employee.employeeId;
        if(this.orgId){
        this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
          console.log(resultData);
          // resultData.forEach((fYear) => {
          //   if (fYear.financialYearStatus.listTypeValueId == 1) {
          //   // this.financialYearList = resultData;
          // }
          // });

          this.settingsService.getFinancialYearId(this.leaveForm.value.financialyear).subscribe((resultData1: any) => {
            console.log("resultData1", resultData1.finanicalPeriod)

            this.finPeriodList = resultData1.finanicalPeriod;
            this.leaveForm.get('period').patchValue(this.userdata.finanicalPeriod.financialPeriodId);
            console.log("this.financialPeriodList", this.finPeriodList);

          });

        });
      }
      if(this.clientId){
        this.settingsService.getFinicalyearListbyClient( this.clientId).subscribe((resultData: any) => {
          console.log(resultData);
          // resultData.forEach((fYear) => {
          //   if (fYear.financialYearStatus.listTypeValueId == 1) {
          //   // this.financialYearList = resultData;
          // }
          // });

          this.settingsService.getFinancialYearId(this.leaveForm.value.financialyear).subscribe((resultData1: any) => {
            console.log("resultData1", resultData1.finanicalPeriod)

            this.finPeriodList = resultData1.finanicalPeriod;
            this.leaveForm.get('period').patchValue(this.userdata.finanicalPeriod.financialPeriodId);
            console.log("this.financialPeriodList", this.finPeriodList);

          });

        });
      }

      })
      // }
    }

  }
  // notZeroValidatorAsync(control: AbstractControl) {
  //   console.log("not zero")
  //   const value = control.value;
  //   return new Promise((resolve) => {
  //     if (value === 0. || value === '0.') {
  //       resolve({ notZero: true }); // Return an object with a custom error key if value is zero
  //     } else {
  //       resolve(null); // Return null if the value is not zero, indicating the control is valid
  //     }
  //   });
  // }

  // notZeroValidatorAsync(control:AbstractControl) {
  //   const value=control.value;
  //   // const regex=/^(?!0\.0$)\d+(\.\d)?$/;
  //   // const regex=/^(?:\d{1,2}(\.\d)?|\d{1,2})$/;
  //   // const regex=/^(?:\d{1,2}(\.\d)?|\d{1,2})$/;
  //   const regex = /^(?:[1-9]|[12][0-9]|30)$/;
  //   return new Promise((resolve) => {
  //   if (!regex.test(value.toString()) ) 
  //   {
  //   console.log("notVaild");
  //   resolve({ notZero:true }); // Return an object with a custom error key if value is zero
  //   } 
  //   else 
  //   {
  //   console.log("Vaild");
  //   resolve(null); // Return null if the value is not zero, indicating the control is valid
  //   }
  //     });
  //   }


  notZeroValidatorAsync(control: AbstractControl) {
    const value = control.value;
  
    // Regex to match numbers between 0 to 30, including decimals like 0.5, 1.5
    const regex = /^(?:[0-9](?:\.\d+)?|[12][0-9](?:\.\d+)?|30(?:\.0+)?)$/;
  
    return new Promise((resolve) => {
      console.log('Validator called with value:', value);

      // If the value is null, undefined, or does not match the regex, return an error
      if ( !regex.test(value.toString())) {
        console.log("Invalid");
        resolve({ notZero: true }); // Return error object
      } else {
        console.log("Valid");
        resolve(null); // Valid input
      }
    });
  }
  
  

  get validate() { return this.leaveForm.controls; }

  addRow() {
    const control = this.leaveForm.get('Leaves') as FormArray;
    control.push(this.initRows());
    console.log(control);
  }

  initRows() {
    return this.fb.group({
      empleave: ['', [Validators.required], this.notZeroValidatorAsync],
      firstName: [''],
      lastName: [''],
      employeeId: [''],
      employeeNumber: [''],
      employeeLeaveId: ['']
    });
  }
  onKeyDown(event: KeyboardEvent): void {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  noEmployeeFound() {
    // this.selectedemployeeId = undefined;
    // this.selectedemployeeId = undefined; 
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    // this.selectedemployee = undefined;
    // this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 
  
    }

  onSubmit() 
  {
    this.isprocessing1 = true; // Disable the button

    console.log("submit");
    this.submitted = true;
    const empLeavesControls = this.leaveForm.get('Leaves') as FormArray;
    console.log(empLeavesControls);
    if (this.leaveForm.invalid) {
      this.isprocessing1 = false; // Enable the button if the form is invalid
 
      console.log("invalid");
      if(this.leaveForm.value.employeecode.length!=0&&empLeavesControls.length==0)
      {
        console.log("inside form:"+this.leaveForm.value.employeecode);
        this.leaveForm.patchValue({
          employeecode:null
        });
        console.log(this.leaveForm);
        this.error = true;
        this.error_msg = "Please Select Employee";
        setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
      }
      else
      {
        console.log("Employee Code Null")
      }
      // console.log("empLeavesControls.length",empLeavesControls.length)
      // if(empLeavesControls.length != 0){
      //   console.log("empLeavesControls.length if",empLeavesControls.length)
      //   console.log('ee',empLeavesControls.controls.some(control => control.value.empleave === ''))
      //   if(empLeavesControls.controls.some(control => control.value.empleave === '') == true){
      //     // alert('Leave Days Should not be empty')
      //     console.log("true")
      //     this.error = true;
      //     this.error_msg = "Leave Days Should not be empty";
      //     setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
      //   }
      // }
      return;
    }

    if (this.empleaveId) //Update 
    {
      this.selectedEmployeeData =[];
      this.empLeaves = [];
      // console.log("Empty",this.empRatings);
      console.log("update", this.empleaveId);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      console.log("employeeLeaveControls", empLeavesControls)
      for (let i = 0; i < empLeavesControls.length; i++) {
        // Check if any Leave Days field is empty
        if (empLeavesControls.controls.some(control => control.value.empleave === '')) {
          // Display an error message or handle the empty Leave Days scenario
          // alert('Leave Days Should not be empty')
          console.log("Leave Days Should not be empty")
          this.error_msg = "Leave Days Should not be empty";
          return;
        }
        const employeeId = empLeavesControls.at(i).value.employeeId;
        console.log('employeeId', employeeId);
        console.log("Id", empLeavesControls.at(i).value.empleaveId);
        if (empLeavesControls.at(i).value.employeeLeaveId == undefined) {
          console.log("Id not present", empLeavesControls.at(i).value.employeeLeaveId);
          const updateEmployeeLeave = {
            leaveDays: empLeavesControls.at(i).value.empleave,
            financialPeriod: {
              financialPeriodId: this.leaveForm.value.period
            },
            employee: {
              employeeId: empLeavesControls.at(i).value.employeeId
            },
            status: {
              listTypeValueId: 1
            }
          };
          this.empLeaves.push(updateEmployeeLeave);
        }
        else {
          console.log("id present", empLeavesControls.at(i).value.employeeLeaveId);
          const updateEmployeeLeave = {
            employeeLeaveId: this.empleaveId,
            leaveDays: empLeavesControls.at(i).value.empleave,
            financialPeriod: {
              financialPeriodId: this.leaveForm.value.period
            },
            employee: {
              employeeId: empLeavesControls.at(i).value.employeeId
            },
            status: {
              listTypeValueId: 1
            }
          };
          this.empLeaves.push(updateEmployeeLeave);
        }
      }

      console.log('employeeLeaveArray', this.empLeaves);
      this.payrollservice.updateEmployeeLeave(0, this.empLeaves).subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.success = true;
          this.sucess_msg = result.description;
          // this.searchSubmit();
          setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
          // this.clientupdateId = null;
          // this.leaveForm.reset();
          this.submitted = false;
          setTimeout(() => {
            this.router.navigate(["home/manage-employee-leave"])
          }, redirectMsgTimeOut)
        }
        else {
          // this.error = true;
          // this.error_msg = result.description;
          // this.errormessagejson = result;
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          console.log("err1", result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson', this.errormessagejson);
          this.importerror = true;
        }
        this.isprocessing1=false;
      },
        err => {
          // console.log("code",err);
          // console.log("code",err.statusCode);
          // if(err.error.statusCode == 409)
          // {
          console.log("code1", err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut);
          this.isprocessing1=false;

          // }

        })
    }
    else {
      this.selectedEmployeeData =[];
      this.empLeaves = [];
      console.log("create");
      console.log(empLeavesControls.value);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      this.reportData = empLeavesControls.value;
      console.log("this.reportDate", this.reportData);
      // for (let i = 0; i < this.reportData.length; i++) {
      //   const employeeId = this.reportData.at(i).employeeId;
      //   console.log('employeeId', employeeId);
      //   const createEmployeeLeave = {
      //     financialPeriod:
      //     {
      //       financialPeriodId: this.leaveForm.value.period
      //     },
      //     employee:
      //     {
      //       employeeId: this.reportData.at(i).employeeId 
      //     },
      //     leaveDays: this.reportData.at(i).empleave,
      //     status: { listTypeValueId: 1 }
      //   };
      //   this.empLeaves.push(createEmployeeLeave);
      //   console.log(this.empLeaves);
      // }

      for (let i = 0; i < this.reportData.length; i++) {
        const employeeId = this.reportData.at(i).employeeId;
        console.log('employeeId', employeeId);
      
        // Check if leaveDays is not 0 before creating and pushing the object
        if (this.reportData.at(i).empleave > 0) {
          const createEmployeeLeave = {
            financialPeriod: {
              financialPeriodId: this.leaveForm.value.period
            },
            employee: {
              employeeId: this.reportData.at(i).employeeId
            },
            leaveDays: this.reportData.at(i).empleave,
            status: { listTypeValueId: 1 }
          };
          this.empLeaves.push(createEmployeeLeave);
          console.log(this.empLeaves);
        } else {
          console.log(`Skipped record for employeeId: ${employeeId} because leaveDays is 0.`);
        }
      }
      
      this.payrollservice.createEmployeeLeave(this.empLeaves).subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.success = true;
          this.sucess_msg = result.description;
          setTimeout(() => { this.success = false; this.sucess_msg = ''  }, redirectMsgTimeOut)
          // this.leaveForm.reset();
          this.submitted = false;
          // this.searchSubmit();
          setTimeout(() => {
            this.router.navigate(["home/manage-employee-leave"])
          }, redirectMsgTimeOut)
        }
        else {
          console.log("err1", result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          setTimeout(()=>{
            this.error = false;
            this.error_msg = '';
          },3000)
          console.log('this.errormessagejson', this.errormessagejson);
          this.importerror = true;
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          // setTimeout(() => {
          // }, redirectMsgTimeOut)
        }
        this.isprocessing1=false;


      }, err => {
        console.log("code", err);
        console.log("code", err.statusCode);
        // if(err.error.statusCode == 409)
        // {
        //   console.log("code1",err.statusCode);
        // setTimeout(() => {
          this.error = true;
          this.errorMsg = err.error.description;
        // }, redirectMsgTimeOut);
        setTimeout(()=>{
          this.error = false;
          this.error_msg = '';
        },3000);
        this.isprocessing1=false;

        // }

      })
    }
  }
  get getFormControls() {
    this.dataArray = [];
    const control = this.leaveForm.get('Leaves') as FormArray;
    this.length = control.length;
    if (control != null) {
      control.controls.forEach((formGroup: FormGroup) => {
        const data = formGroup.value;
        this.dataArray.push(data);
      });
      // console.log(this.dataArray);
      return control;
    }
  }
  selectFinancialyear() {
    console.log("Select indidual Employee");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    // this.chooseFinId = event.target.value;
    this.chooseFinId = this.leaveForm.controls['financialyear'].value;
    console.log(this.chooseFinId);
    if (this.chooseFinId == null || this.chooseFinId == 'null') {
      console.log('if');
      this.finPeriodList = [];
    } else {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
        this.leaveForm.patchValue({
          period: null,
        });
      });
    }
  }

  // empSearch(event) {
  //   this.empList = [];
  //   this.empCode = false;
  //   console.log(event.target.value);
  //   this.userId = event.target.value;
  //   console.log(this.userId);
  //   // Employee List Api
  //   // this.empList.length == 0;
  //   if(event.target.value.length >= 2)
  //   {
  //     if(this.employeelistsub)
  //     {
  //       this.employeelistsub.unsubscribe();
  //     }
  //     this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
  //       .pipe(
  //         takeUntil(this.unsubscribe$), // Cancel previous request
  //         switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
  //       )
  //       .subscribe((result: any) => {
  //         this.empList.length = 0;

  //         if (result.statusCode) {
  //           console.log(result.statusCode);
  //           this.empList.length = 0;
  //         } else {
  //           this.empList = result;
  //         }
  //       });
	// }
	
  // }

  empSearch(event){
    console.log(event.target.value)
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement) // Cast the event target as an input element
  this.inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
   console.log(this.inputValue)
    // Clear the employee list and reset flags if input is empty
    if (this.inputValue === '') {
      this.empList = [];
      this.empCode = false;
      console.log('Input is empty, employee list cleared');
      return;
    }
  
    // Check if input length is sufficient for the search
    if (this.inputValue.length >= 2) {
      // Unsubscribe from the previous API request if necessary
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
  
      // Reset and create a new Subject for cancellation
      this.unsubscribe$ = new Subject<void>();

      if(this.orgId){
      this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        
        );
        }

        if(this.clientId){
          this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              catchError((err) => {
                if (err.error.status === 500) {
                  this.empList = [];
                  this.empCode = true; // Set the flag to indicate an error state
                }
                console.error('An error occurred:', err);
                return EMPTY; // Return EMPTY to propagate completion downstream
              })
            )
            .subscribe(
              (result: any) => {
                if (result.statusCode === 204) {
                  console.log('No employees found (status code 204)');
                  this.empList = [];
                  this.empCode = true; // Indicate no results found
                } else if (result.statusCode) {
                  console.log('Other status code:', result.statusCode);
                  this.empList = [];
                  this.empCode = true;
                } else {
                  // Handle the case where result is an array or an object
                  this.empList = Array.isArray(result) ? result : Object.values(result);
                  this.empCode = false;
                }
              },
              (err) => {
                if (err.error.status === 500) {
                  this.empList = [];
                  this.empCode = true;
                }
                console.error('An error occurred:', err);
              }
            
            );
            }
      }
    } 


  
  empSelect(emp) {

    this.employeeId = emp.employeeId;
    this.firstname = emp.firstName;
    this.lastname = emp.lastName;
    this.empnumber = emp.employeeNumber;
    console.log("select", this.selectedEmployeeData);
    let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
    this.showtable = true;
    // if (!hasemployee) {
    //   this.showtable = true;
    //   this.selectedEmployeeData.push(emp);
    //   const rowsArray = this.leaveForm.get('Leaves') as FormArray;
    //   // rowsArray.clear();
    //   console.log("control", rowsArray);
    //   rowsArray.push(this.formBuilder.group({
    //     employeeId: [this.employeeId],
    //     employeeNumber: [this.empnumber],
    //     firstName: [this.firstname],
    //     lastName: [this.lastname],
    //     empleave: ['', [Validators.required], this.notZeroValidatorAsync],
    //   }));
    // }


    if (!hasemployee) {
      this.showtable = true;
    
      // Check if the employee is already in selectedEmployeeData
      if (!this.selectedEmployeeData.some(e => e.employeeId === emp.employeeId)) {
        this.selectedEmployeeData.push(emp);
      }
    
      const rowsArray = this.leaveForm.get('Leaves') as FormArray;
      console.log("control", rowsArray);
      // Check if a row with the same employeeId already exists in rowsArray
      if (!rowsArray.controls.some(row => row.get('employeeId').value === this.employeeId)) {
        console.log("control", rowsArray);
    
        rowsArray.push(this.formBuilder.group({
          employeeId: [this.employeeId],
          employeeNumber: [this.empnumber],
          firstName: [this.firstname],
          lastName: [this.lastname],
          empleave: ['', [Validators.required], this.notZeroValidatorAsync],
        }));
      }
    }
    

    console.log(this.leaveForm.get('financialyear').value);
    console.log(this.leaveForm.get('period').value);
  this.finid=this.leaveForm.get('financialyear').value;
    this.perid=this.leaveForm.get('period').value;

    if (!this.finid || !this.perid) {
      alert("Please select both Financial Year and Period before proceeding.");
        this.leaveForm.controls['employeecode'].reset();
        this.selectedEmployeeData=[];

      return; // Stops execution
  }
    if (this.employeeId) {
      this.loader = true;
      this.payrollservice.getEmployeecount(this.employeeId,this.perid, this.finid).subscribe(
        (resultData: any) => {
          //console.log(resultData);
          this.loader = false;
          this.resultData = resultData;
       
        
          console.log('resultData:', this.resultData);
          console.log(this.resultData.leave_count) ;       
         this.leaveForm.patchValue({
          empleave: this.resultData.leave_count,
         });
         const rowsArray = this.leaveForm.get('Leaves') as FormArray;
         console.log("Data:"+rowsArray);
        // //  this.addRow();
         rowsArray.at(0).patchValue({
          
          empleave: this.resultData.leave_count,
         })
      
          
        }
      );
    }

    console.log(this.leaveForm.get('empleave').value)
    

    // }

  }

  close() {
    console.log("close");
    this.router.navigateByUrl('/home/manage-employee-leave');
  }


  pageChages() {
    console.log("page changes");
    this.p = 1;
  }

  
  empAllSelect() {
    // this.empList=[];
    // this.selectedEmployeeData=[];
    console.log("all Employee");
    
    console.log(this.leaveForm.get('financialyear').value);
    console.log(this.leaveForm.get('period').value);
  this.finid=this.leaveForm.get('financialyear').value;
    this.perid=this.leaveForm.get('period').value;

    if (!this.finid || !this.perid) {
      alert("Please select both Financial Year and Period before proceeding.");
        this.leaveForm.controls['employeecode'].reset();
        this.selectedEmployeeData=[];

      return; // Stops execution
  }
    if (this.leaveForm.value.employeecode == "All") {
      this.loader=true;

      if(this.orgId){
      this.payrollservice
        .getEmployeecountAll(this.orgId,this.perid, this.finid).subscribe((result: any) => {
          console.log(result);
          if (result.length == 0) {
            console.log("noData");
            // this.empList.length = 0;
          }
          // this.empList=result;
          console.log("All", this.empList);
          result.forEach(element => {
            let hasemployee = this.selectedEmployeeData.some(e => e.employee_id == element.employee_id);
            this.showtable = true;
            if (!hasemployee) {
              this.selectedEmployeeData.push(element);
              // console.log('selectedEmployeeData', this.selectedEmployeeData);
              const rowsArray = this.leaveForm.get('Leaves') as FormArray;
              rowsArray.push(this.formBuilder.group({
                employeeId: [element.employee_id],
                employeeNumber: [element.employee_number],
                firstName: [element.first_name],
                lastName: [element.last_name],
                empleave: [element.leave_count,[Validators.required], this.notZeroValidatorAsync],
              }));
            }
      
          });
          this.loader=false;

        })
      }

      if(this.clientId){
        this.payrollservice
          .getEmployeecountAllClient(this.clientId,this.perid, this.finid).subscribe((result: any) => {
            console.log(result);
            if (result.length == 0) {
              console.log("noData");
              // this.empList.length = 0;
            }
            // this.empList=result;
            console.log("All", this.empList);
            result.forEach(element => {
              let hasemployee = this.selectedEmployeeData.some(e => e.employee_id == element.employee_id);
              this.showtable = true;
              if (!hasemployee) {
                this.selectedEmployeeData.push(element);
                // console.log('selectedEmployeeData', this.selectedEmployeeData);
                const rowsArray = this.leaveForm.get('Leaves') as FormArray;
                rowsArray.push(this.formBuilder.group({
                  employeeId: [element.employee_id],
                  employeeNumber: [element.employee_number],
                  firstName: [element.first_name],
                  lastName: [element.last_name],
                  empleave: [element.leave_count,[Validators.required], this.notZeroValidatorAsync],
                }));
              }
        
            });
            this.loader=false;
  
          })
        }


    }
  }

  isRowValid(index: number): boolean {
    const rowsArray = this.leaveForm.get('Leaves') as FormArray;
    // console.log(control);
    const row = rowsArray.at(index) as FormGroup;
    return row.valid;
  }

  showerrormessage() {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px', height: 'fit-content', data: this.errormessagejson
    });
    this.error = false;
  }

  // deleteemployee(index) 
  //   {
  //   console.log(index);
  //   console.log(this.dataArray);
  //   if (index != -1) {
  //     this.dataArray.splice(index, 1);
  //     console.log(this.dataArray);
  //   }
  //   }
  deleteemployee(index) {
    // console.log(index + index);
    // if (index != -1) {
    //   const rowsArray = this.leaveForm.get('Leaves') as FormArray;
    //     if(rowsArray.at(index).value.employeeleaveid==undefined)
    //     {
    //       rowsArray.removeAt(index);
    //       this.selectedEmployeeData.splice(index, 1);
    //       console.log(this.selectedEmployeeData.length);
    //     }
    //  }
    console.log(index + index);
    if (index != -1) 
    {
      const rowsArray = this.leaveForm.get('Leaves') as FormArray;
      console.log(rowsArray);
      // for (let i = 0; i < rowsArray.length; i++) {
      const emprattingId = rowsArray.at(index).value.employeeId;
      console.log('emprattingId', emprattingId);
      console.log("Id", rowsArray.at(index).value.employeeLeaveId);
      if (rowsArray.at(index).value.employeeLeaveId == undefined) 
      {
        rowsArray.removeAt(index);
        this.selectedEmployeeData.splice(index, 1);
        console.log(this.selectedEmployeeData.length);
      }
    }
  }
  onPageChange(event: any): void {
    this.p = event;
    this.updatePagedData();
  }

  updatePagedData(): void {
    const startIndex = (this.p - 1) * this.pagePerItem;
    const endIndex = startIndex + this.pagePerItem;
    console.log(startIndex);
    this.startIndex = startIndex;
    console.log(this.startIndex);
    console.log(endIndex);
  }

  get leaveDaysFormArray() : FormArray {
    return this.leaveForm.controls["Leaves"] as FormArray
   }

   pasteCount: number = 0;
   onPaste(event: ClipboardEvent) {
     event.preventDefault(); // Prevent default paste behavior
     console.log('Pasting is not allowed!');
     this.pasteCount--;
   }

   validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  validateInputs(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }
}
