import { Component, OnInit, ViewChild, Inject, ElementRef,Renderer2} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { leavedenyreason } from '../withdraw-list/withdraw-list.component';
import Swal from 'sweetalert2';
import { LeaveService } from 'src/app/providers/leave.service';
import { ApproverStatusComponent } from '../approver-status/approver-status.component';
import { LocationComponent } from './location/location.component';
import { HeaderService } from 'src/app/providers/header.service';
import { LeavemanagementstatusComponent } from 'src/app/leavemanagementstatus/leavemanagementstatus.component';

@Component({
  selector: 'app-mybusiness-travel-list',
  templateUrl: './mybusiness-travel-list.component.html',
  styleUrls: ['./mybusiness-travel-list.component.scss']
})
export class MybusinessTravelListComponent implements OnInit {

  list_count = 0
  data: any[] = []
  isLoading:Boolean;
  noData=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno',
    'startDate',
    'endDate',
    "noOfDays",
    "reason",
    "viewapprovers",
    "status",
    'star',];
  // dataSource: any;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  changeasDenyReason: boolean=false;
  isMenuOpen: boolean = false;
  clickedbusinessTravelId: any;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  success: boolean = false;
  error: boolean = false;
  success_msg: string;
  error_msg: any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
list_counts:boolean = false;
  beforesearchpage: number;
  paginators: boolean = true;
  constructor(
    private gs: GlobalserviceService, 
    private router: Router, 
    public dialog: MatDialog,
    private renderer: Renderer2,
    private leaveservice:LeaveService,
    private headerservice: HeaderService

    ) {
  }

  ngOnInit() {
    // this.headerservice.setTitle('Business Travel List')
    this.headerservice.setTitle('');

  //   let x = JSON.parse(localStorage.getItem("enoteUserData"));
  //   let id = x.employee.employeeId;
  //    console.log("success");
  //   this.leaveservice.getMyBusinessTravel_List(id, 2).subscribe((d: any) => {
  //     this.loader=false;
  //     if(d.length==0)
  //     {
  //       this.noData=true;
  //       console.log("noData");
  //     }
  //     if(d.statusCode==204)
  //     {
  //        this.noData=true;
  //        console.log("204");
  //     }
  //     this.data = d
  //     this.dataSource = new MatTableDataSource<any>(this.data);
  //     this.dataSource.paginator = this.paginator;
  //     this.list_count = this.data.length;
  //   },
  //  (error) =>
  //   {
  //     this.loader=false;
  //     this.noData= true;
  //     console.log("error");
  //     if(error.statusCode==204)
  //     {
  //        this.noData=true;
  //        console.log("204");
  //     }
  //   }

  //   )
  this.fetchbusiness();
  }

  fetchbusiness(){
    let x = JSON.parse(localStorage.getItem("enoteUserData"));
    let id = x.employee.employeeId;
     console.log("success");
    this.leaveservice.getMyBusinessTravel_List(id, 2).subscribe((d: any) => {
      this.loader=false;
      this.list_counts=true;
      if(d.length==0)
      {
        this.noData=true;
        console.log("noData");
      }
      if(d.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length;
    },
   (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }

    )
  }

  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  apply_bt(){
    this.router.navigateByUrl("/home/apply-businesstravel");
  }

  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(LeavemanagementstatusComponent,{
      width: '500px',
      height:'fit-content',
      data: {
        referredwith: "BUSINESS TRAVEL",
        id: approvaldata.businessTravelId
      }    })
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.noData = true;
    }
    else{
      this.noData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Reason(i) {
    this.dialog.open(Reason, {
      width:'300px',
      data: i.reason
    });
  }
  showdenyreason(i){
    console.log(i)
    let x = i;
    this.dialog.open(leavedenyreason, {
      data: x
    });
  }
  denyReason(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if(this.isMenuOpen==true){
      this.changeasDenyReason=true;
      console.log(this.changeasDenyReason);
    }
    else{
      this.changeasDenyReason=false;
      console.log(this.changeasDenyReason);
    }
  }
  cancel(e){
   
    Swal.fire({
      title: 'Are you sure? Do you want to Cancel Business Travel Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor:'#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if(result.value){
      //   e['businessTravelStatus'] = 8;
      //   e.approvals['approverlevel'] = 1;
      //   e.approvals.approvalStatus.listTypeValueId = 8 ;
      //   e.approvals.approvalStatus.listTypeValueName = "Cancelled";
      //   e.approvals['employeeId'] = e.employee.employeeId;
      // this.loader = true
      this.leaveservice.approvalsById("BUSINESS TRAVEL", e.businessTravelId).subscribe((data:any)=>{
        console.log(data);
       
        this.loader = false;
        // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);
          e['businessTravelStatus'] = 8;
                  e['approverlevel'] = 1;
                  e['approvals'] = data;

                  e.approvals.approvalStatus.listTypeValueId = 8 ;
                  e.approvals.approvalStatus.listTypeValueName = "Cancelled";
                  e['employeeId'] = e.employee.employeeId;
                  this.loader = true;

                  this.leaveservice.AcctionBusiness(e.businessTravelId,e).subscribe(
                    d=>{
                      // Swal.fire({
                      //   text:'Cancelled',
                      //   icon:'success'
                      // })
                      this.success = true;
                      this.success_msg =  (d as any).message;
                      this.loader = false
                      setTimeout(() => { this.success = false; }, 3000);   
                      this.fetchbusiness();       // window.location.reload()
                    },
                    err =>{
                      // Swal.fire({
                      //   text:err.error.message,
                      //   icon:'error'
                      // })
                      this.error = true;
                      this.error_msg = err.error.message;
                      this.loader = false
            
                      setTimeout(() => { this.error = false; }, 3000);   
                            },
                            () => {
                              this.loader = false
                            }
                  )
      },)
      
      }
    })
  }

  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if(i == 'Cancelled'){
      return '#e40000'
    }
  }
  // show Loacation
  showLocation(i) {
    this.dialog.open(LocationComponent, {
      data: i
    });

  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}



// // location
// @Component({
//   selector: 'location',
//   templateUrl: 'location.html',
// })
// export class LocationComponent {
//   constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
//   }
// }
