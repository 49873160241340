<div class="sb-div">
    <div class="share-close-div">
     <div class="share-div">
         Share
     </div> 
     <!-- <div class="close-div"> -->
         <button class="close-div" mat-dialog-close>X</button>
     <!-- </div> -->
    </div>
     <div class="sb-div-1">
         <share-buttons theme="circles-dark"
         [include]="['copy', 'email', 'whatsapp']"
         [showIcon]="true"
         [showText]="false"
         url="{{link}}"
         description="Resource Feedback"
         class="share-btns">
         </share-buttons>
     </div>
    
     <div class="share-link-div">
         <a>{{link}}</a>
         <button class="btn btn-primary search-btn copy-btn" (click)="copyText(link)">Copy</button>
     </div>
 </div>