<style>
  .input-width {
    width: 100%;
  }
</style>
<div style="margin-top: 100px" class="container">
  <div
    class="table-responsive mt-5"
    style="overflow: hidden; overflow-x: hidden"
  >
    <form action="" [formGroup]="taskForm" >

      <mat-card class="material">
        <div class="login-100">
          <div class="row">
            <div class="col-md-6">
              <h4
                class="float-left assign-employee"
                style="margin: 10px 0 20px 0"
              >
              {{empTaskId ? 'Edit Employee Task'  : 'Assign Employee Task'}}
              </h4>
            </div>
          </div>
          <div class="row">
     
            <div class="col-md-6 " id="loginform" appearance="fill">
              <!-- <div class=""> -->
                <label class="form-control-label"
                  >Search Employee<span class="text-danger">*</span></label
                >

                <input
                type="text"
                matInput
                (input)="validateInput($event)"
                (keydown)="validateInput($event)"
                (keyup)="validateInput($event)"
                placeholder="Search Employee"
                formControlName="employee"
                [matAutocomplete]="auto"
                (keydown)="handleBackspace($event)"
                class="form-control email-input select-placeholder"
                (keyup)="empSearch($event,$event)"
                
              />

              <mat-autocomplete #auto="matAutocomplete">
         
                <mat-option
                  *ngFor="let emp of employeeProjects"
                  [value]="emp?.employee.employeeId"
                  (click)="empSelect(emp)"
                  
                >
                  {{ emp?.employee.employeeNumber }} -
                  {{ emp?.employee.firstName }}
                </mat-option>
                <mat-option *ngIf="employeeNotFound"  (click)="noEmployeeFound()"
                  >No Employee Found</mat-option
                >
              </mat-autocomplete>
                <mat-icon class="material-symbols-outlined search">
                  search
                </mat-icon>
            
              <!-- </div> -->
            </div>

            <div class="col-md-6 mb-2 mb-md-0 mt-n3 mt-md-0 " id="loginform">
              <label class="form-control-label"
                >Project<span class="text-danger">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                placeholder="Select project"
                formControlName="project"
              >
                <mat-option
                  *ngFor="let project of projectList"
                  [value]="project.projectId"
                  (click)="projectData(project)"
                >
                  {{ project.projectName }}
                </mat-option>
              </mat-select>
              <div *ngIf="(project.invalid && submitted1) || project.dirty">
                <small
                  *ngIf="project.errors?.required"
                  class="span-error mt-n3"
                  >Please enter project</small
                >
              </div>
            </div>
          </div>
          <div class="row">
          
            <div class="col-md-6 mb-2 mb-md-0" id="loginform">
              <label class="form-control-label"
                >Task Name<span class="text-danger">*</span></label
              >
              <mat-select
                placeholder="Select Task Name"
                formControlName="task"
                (selectionChange)="empolyeeTaskvalidate()"
                class="form-control email-input select-placeholder"
                placeholder="Select Task Name"
              >
                <mat-option
                  *ngFor="let t of taskList"
                  [value]="t?.taskId"
                  (click)="taskData(t)"
                >
                  {{ t.taskName }}
                </mat-option>
              </mat-select>
              <div *ngIf="(task.invalid && submitted1) || task.dirty">
                <small *ngIf="task.errors?.required" class="text-danger"
                  >Please enter task name</small
                >
              </div>
              <small *ngIf="employeetaskexist" class="text-danger">
                Task Already Assigned</small
              >
            </div>

            <ng-container class="">
              <div class="full-width col-md-3  mb-2 mb-md-0" appearance="fill" id="loginform">
                <label class="form-control-label"
                  >Assigned Hours<span class="text-danger">*</span></label
                >
        


            <input
            matInput
            formControlName="assignedhours"
            placeholder="Enter Assigned Hours"
            maxlength="3"
            (paste)="onPaste($event)" 
            (keypress)="noNumber($event)"
            autocomplete="off"
            (change)="set($event)"
            class="form-control email-input select-placeholder"
          />
            <!-- <input #myText    class="form-control email-input select-placeholder"    formControlName="assignedhours"     (paste)="onPaste($event)" (input)="onInput(myText.value)" /> -->
   
                <div
                  *ngIf="
                    (assignedhours.invalid && submitted1) || assignedhours.dirty
                  "
                >
                  <small
                    *ngIf="assignedhours.errors?.required"
                    class="text-danger"
                    >Assigned Hours is required</small
                  >
                </div>
              </div>

              <div class="full-width col-md-3 mb-2 mb-md-0" appearance="fill" id="loginform">
                <label class="form-control-label"
                  >Assigned Minutes<span class="span-error">*</span></label
                >
                <input
                  matInput
                  formControlName="assignedminutes"
                  placeholder="Enter Assigned Minutes"
                  maxlength="2"
                  (keypress)="noNumber($event)"
                  autocomplete="off"
                  (paste)="onPaste($event)" 
                  (change)="set($event)"
                  class="form-control email-input select-placeholder"
                />
                <div
                  *ngIf="
                    (assignedminutes.invalid && submitted1) ||
                    assignedminutes.dirty
                  "
                >
                  <small
                    *ngIf="assignedminutes.errors?.required"
                    class="text-danger"
                    >Assigned Minutes is required</small
                  >
                </div>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="full-width col-md-3" appearance="fill" id="loginform">
                <label class="form-control-label"
                  >Assign Hours<span class="text-danger">*</span></label
                >
                <!-- <input
                  matInput
                  formControlName="assignedhours"
                  placeholder="Enter Assign Hours"
                  maxlength="3"
                  (keypress)="noNumber($event)"
                 
                  (change)="set($event)"
                  class="form-control email-input select-placeholder"
                /> -->


                <input
                matInput
                formControlName="assignedhours"
                placeholder="Enter Assign Hours"
                maxlength="2"
                (keypress)="noNumber($event)"
                autocomplete="off"
                (change)="set($event)"
                class="form-control email-input select-placeholder"
              />
                <!-- <div
                  *ngIf="
                    (assignedhours.invalid && submitted1) || assignedhours.dirty
                  "
                >
                  <small
                    *ngIf="assignedhours.errors?.required"
                    class="text-danger"
                    >Assign Hours is required</small
                  >
                </div> -->

                <div *ngIf="(assignedhours.invalid && submitted1) || assignedhours.dirty">
                  <small *ngIf="assignedhours.errors?.required" class="text-danger"
                    >Assign Hours is required</small
                  >
                </div>
              </div>

              <div class="full-width col-md-3" appearance="fill" id="loginform">
                <label class="form-control-label"
                  >Assign Minutes<span class="text-danger">*</span></label
                >
                <input
                  matInput
                  formControlName="assignedminutes"
                  placeholder="Enter Assign Minutes"
                  maxlength="2"
                  (keypress)="noNumber($event)"
                  autocomplete="off"
                  (change)="set($event)"
                  class="form-control email-input select-placeholder"
                />
             
                <div *ngIf="(assignedminutes.invalid && submitted1) || assignedminutes.dirty">
                  <small *ngIf="assignedminutes.errors?.required" class="text-danger"
                    >Assign Minutes is required</small
                  >
                </div>
              </div>
            </ng-template>
          </div>

          <div class="row mt-3 mt-sm-n3">
            <!-- <div class="  col-md-6 col-sm-6" id="loginform">
              <label class="form-control-label"
                >StartDate<span class="text-danger">*</span></label
              >
              <input
                matInput
                [matDatepicker]="picker"
                (dateChange)="updateEndDateMin($event)"
                readonly
                [disabled]="!taskForm.controls['task'].value"
                (click)="picker.open()"
                formControlName="startdate"
                [min]="min"
                [max]="max"
            
                class="form-control email-input select-placeholder"
                placeholder="Enter start date"
              />
              <mat-datepicker-toggle
                matSuffix
                [disabled]="!taskForm.controls['task'].value"
                [for]="picker"
                style="float: right; position: relative; bottom: 42px"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #picker touchUi></mat-datepicker>
              <div *ngIf="(startdate.invalid && submitted1) || startdate.dirty">
                <small *ngIf="startdate.errors?.required" class="text-danger"
                  >Please select start date</small
                >
              </div>
            </div> -->
            <div class=" col-md-6 col-sm-6 apply-margin" id="loginform">
              <label class="form-control-label">
                StartDate<span class="text-danger">*</span></label
              >
              <input
                matInput
          
                [matDatepicker]="picker"   
                 (dateChange)="updateEndDateMin()"
                 (click)="updateEndDateMin()"
                 readonly
                (click)="p.open()"
                [disabled]="!taskForm.controls['task'].value"
                formControlName="startdate"
                [min]="min"
                [max]="max"
     
                class="form-control email-input select-placeholder"
                placeholder="Enter start date"
              />
              <mat-datepicker-toggle
              matSuffix
              [disabled]="!taskForm.controls['task'].value"
              [for]="picker"
              
    
              style="float: right; position: relative; bottom: 42px"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #picker  ></mat-datepicker>
              <div *ngIf="(startdate.invalid && submitted1) || startdate.dirty">
                <small *ngIf="startdate.errors?.required" class="text-danger"
                  >Please select start date</small
                >
              </div>
            </div>
          
            <div class=" col-md-6 col-sm-6 apply-margin" id="loginform">
              <label class="form-control-label">
                EndDate<span class="text-danger">*</span></label
              >
              <input
                matInput
          
                [matDatepicker]="p"
                (click)="p.open()"
                [disabled]="!taskForm.controls['startdate'].value"
                formControlName="enddate"
                [min]="taskForm.controls['startdate'].value"
                (dateChange)="updateEndDateMin()"
                [max]="max"
                readonly
     
                class="form-control email-input select-placeholder"
                placeholder="Enter end date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="p"
                [disabled]="!taskForm.controls['startdate'].value"
                style="float: right; position: relative; bottom: 42px"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #endDatePicker #p ></mat-datepicker>
              <div *ngIf="(enddate.invalid && submitted1) || enddate.dirty">
                <small *ngIf="enddate.errors?.required" class="span-error"
                  >Please select end date</small
                >
              </div>
            </div>
          </div>

          <div align="end">
            <button
              mat-stroked-button
              type="button"
              (click)="cancel()"
              style="color:var(--Red-Color, #FF5D5D);"
          
            >
              Cancel
            </button>
            <button
              mat-raised-button
              class="searchBtn mt-3 mt-md-0"
              style="margin-left: 20px"
              type="submit"
              (click)="Create_task()"

              [disabled]="taskForm.invalid || !buttonDisableOnSingleClick || projectList.length == 0 || taskList.length == 0 || employeetaskexist"
            >
              <!-- {{ empTaskId ? "Update" : "Assign" }} -->
              Save
            </button>
          </div>
        </div>
      </mat-card>
    </form>
  </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="width: 3rem; height: 3rem"></div>
</div>

