<div class="resource-background">

    <!-- <i class='fas fa-file-export export-icon' ></i> -->
    <div class="resource-title">
        <h1>RESOURCE</h1>
    </div>
    <div class="main-div-flex">
        <div class="form-div">
            <div class="form-name"><b class="title-b">CANDIDATE EVALUATION FORM</b></div>
            <!-- <div class="col-3 p-0">
                <div class="d-flex float-right">
                    <button class="btn" >
                        <mat-icon (click)="openDialog1(resourceObj.resourceId, resourceObj.resourceFeedBack.feedBackId)" class="material-symbols-outlined"  style="position:relative; top:3px;">share</mat-icon>
                    </button>
                    <button class="btn" (click)="downloadAsPDF();">
                        <mat-icon class="material-symbols-outlined"
                            style="position:relative; top:3px;">file_download</mat-icon>
                    </button>
                    <button class="btn" (click)="printpdf();">
                        <mat-icon class="material-symbols-outlined" style="position:relative; top:3px;">print</mat-icon>
                    </button>
                </div>
            </div> -->
        </div>

        <div class="form-input">
            <div>
                <label for="applicant-name"><b>Applicant Name:</b></label> {{resName}}
            </div>
            <div class="position-div">
                <label for="position"><b>Position:</b></label> {{position}}
            </div>
        </div>
        <div class="description">
            Please use this form as a guide to evaluate the applicant’s qualifications for
            employment. Check the appropriate numeric value corresponding to the applicant’s level of qualification and
            provide appropriate comments in the space below.
        </div>
        <!-- <div class="rating-scale">
            <label class="r-scale-label" for="rating-scale"><b>Rating Scale:</b></label>
            <div class="r-scale" *ngFor='let rating of ratings'><b class="bold">{{rating.rating}}</b><span>.
                </span><b class="bold">{{rating.ratingName}}</b></div>
        </div> -->
        <div class="rating-scale">
            <div class="r-scale-label" for="rating-scale"><b>Rating Scale:</b></div>
            <div class="r-scale">
                <div *ngFor='let rating of ratings'>
                    <div *ngIf="rating.rating == 5 || rating.rating == 4 || rating.rating == 3">
                        {{rating.ratingId }}. {{ rating.ratingName }}
                    </div>
                </div>
            </div>
            <div class="r-scale">
                <div *ngFor='let rating of ratings'>
                    <div *ngIf="rating.rating == 2 || rating.rating == 1">{{ rating.ratingId }}. {{
                        rating.ratingName }}
                    </div>
                </div>
            </div>
        </div>
        <div class="rating">
            <table class="">
                <tr style="border-radius: 6px;" class="rating-tr">
                    <td class="rating-td0"></td>
                    <div class="rating-label"><b>Rating</b></div>
                    <div class="rating-tbl-div">
                        <td class="rating-td1" *ngFor='let rating of ratings'><b>{{rating.rating}}</b></td>
                    </div>
                </tr>
            </table>
        </div>
        <div class="skills">
            <table class="skills-table">
                <tr class="skill-tr" *ngFor='let res of resRating'>
                    <td class="skill-td">
                        <p class="skill-p"><b>{{res.skill.skillName}}<span>:
                                </span></b>
                            {{res.skill.skillDescription}}</p>
                    </td>
                    <td class="rating-td" *ngIf="res.rating.rating == 5">
                        <div class="checkmark"></div>
                    </td>
                    <td class="rating-td" *ngIf="res.rating.rating != 5"></td>
                    <td class="rating-td" *ngIf="res.rating.rating == 4">
                        <div class="checkmark"></div>
                    </td>
                    <td class="rating-td" *ngIf="res.rating.rating != 4"></td>
                    <td class="rating-td" *ngIf="res.rating.rating == 3">
                        <div class="checkmark"></div>
                    </td>
                    <td class="rating-td" *ngIf="res.rating.rating != 3"></td>
                    <td class="rating-td" *ngIf="res.rating.rating == 2">
                        <div class="checkmark"></div>
                    </td>
                    <td class="rating-td" *ngIf="res.rating.rating != 2"></td>
                    <td class="rating-td" *ngIf="res.rating.rating == 1">
                        <div class="checkmark"></div>
                    </td>
                    <td class="rating-td" *ngIf="res.rating.rating != 1"></td>

                </tr>
            </table>
        </div>
        <div class="comments">
            <label for=""><b>Comments:
                    <!-- (Please summarize your perceptions of the candidate’s strengths and any concerns
                    that should be considered: -->
                </b></label>
            {{comments}}
        </div>
        <div class="recommendation">
            <label class="recommendation-label" for="recommendation"><b>Recommendation: </b></label>
            <ul class="list-recommendations">
                <li class="reco-li" *ngFor="let recommendation of recommendations"><input class="rec-checkbox"
                        type="checkbox" [checked]="recommendation.selected" [disabled]="isDisabled">
                    <p class="reco-p">{{recommendation.listTypeValueName}}</p>
                </li>
            </ul>

        </div>
    </div>
</div>