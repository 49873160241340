


// import { Component, OnInit } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
// import { HeaderService } from 'src/app/providers/header.service';
// import { PmsService } from 'src/app/providers/pms.service';
// import { PmsDashboardDialogComponentComponent } from 'src/app/pms-dashboard-dialog-component/pms-dashboard-dialog-component.component';
// import { forkJoin } from 'rxjs';
// import { FormBuilder, FormGroup } from '@angular/forms';
// import { ifError } from 'assert';

// @Component({
//   selector: 'app-pms-admin-dashboard',
//   templateUrl: './pms-admin-dashboard.component.html',
//   styleUrls: ['./pms-admin-dashboard.component.scss']
// })
// export class PmsAdminDashboardComponent implements OnInit {

//   form: FormGroup;
//   userdata: any;
//   organizationId: any;
//   appriasalstatusid: any;
//   resultData: any = {};
//   errorMsg: any;
//   empdata: any = {};
//   // listData: any;
//   // listData1: any;
//   // listData2: any;
//   listData: any[] = [];
// listData1: any[] = [];
// listData2: any[] = [];
//   loader: boolean = false; 
//   periodData:any[] = [];

//   listPending:any;

//   orgId: any;
//   currentYear:number;
//   currentMonth:string;
//   currentMonth1:string;
//   currentYear1:number;
//   selectedYears:any;
//   selectedPeriodIds:any;
 
//   finalMatchedPeriodId:any;

//   patchPeriodId:any;
//   patchYearId:any;
//   [x: string]: any;
//   valuePeriod:any;

//   constructor(
//     private headerservice: HeaderService,
//     private pmsService: PmsService,
   
//     public dialog: MatDialog,
//     private fb: FormBuilder
//   ) {}

//   ngOnInit(): void {
//     this.headerservice.setTitle('- Dashboard');
//     this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
//     this.organizationId = this.userdata.organization.organizationId;
//     // this.loader=true;
//     this.form = this.fb.group({
//       period: ['']  
//     });

//     const currentDate = new Date();
//     console.log(currentDate);


//     const currentMonth: string = currentDate.toLocaleString('default', { month: 'long' });
//     console.log(currentMonth);
//     this.currentMonth1 = currentMonth;
//     console.log(this.currentMonth1);

//     const currentYear: number = currentDate.getFullYear();
//     console.log(currentYear);
//     this.currentYear1 = currentYear;
//     console.log(this.currentYear1);

//     // this.formattedTodayDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
//     // console.log(this.formattedTodayDate);
//     // this.formattedLastDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
//     // console.log(this.formattedLastDate);
    
//     this.currentYear = new Date().getFullYear();
//     console.log(this.currentYear);

    

//     this.pmsService.getPeriodList(this.organizationId, this.currentYear).subscribe((Data: any) => {
//       this.periodData = Data; 
//       console.log(this.periodData);
  
      
//       const currentDate = new Date();
//       this.currentYear = currentDate.getFullYear();
//       this.currentMonth = currentDate.toLocaleString('default', { month: 'long' });
//       console.log(currentYear,"currentYear"); 
//       console.log(currentMonth,"currentMonth");
  
      
//       this.periodData.forEach(period => {
//         console.log('period',period);
        
//         console.log("Financial Period Month:", period.financialperiodMonth);
//         console.log("Financial Period Year:", period.financialPeriodYear);
//         console.log("Financial Period Year id:", period.finanicalYear.financialYearId);
//         console.log("Financial Period Month id:", period.financialPeriodId);
//         this.yearid = period.finanicalYear.financialYearId;
//         console.log("this.currentMonth1 ",     this.currentMonth1 );
//         if (period.financialperiodMonth === this.currentMonth1) {
//           // If it matches, assign the financialPeriodId to this.monthid
//           this.monthid = period.financialPeriodId;
//         }
//       });

      
  
      
//       const finalIds = this.periodData.filter(period => {
//         console.log("Comparing financial period:", period.financialperiodMonth, period.financialPeriodYear);
//         console.log("With current month and year:", currentMonth, currentYear);
      
//         return period.financialperiodMonth === currentMonth || period.financialPeriodYear === currentYear;
        
//     });
    
//     console.log("finalIds Periods:", finalIds);
//     if (finalIds.length > 0) {
      
//       finalIds.forEach(matchingPeriod => {
          
//           console.log("finalIds:", matchingPeriod.finanicalYear.financialYearId,matchingPeriod.financialPeriodId  );
    
//           this.patchYearId = matchingPeriod.finanicalYear.financialYearId;
//           this.patchPeriodId = matchingPeriod.financialPeriodId;
  
//           this.form.patchValue({
//             period: this.patchPeriodId
//           });
  
//           console.log("patchmonthid",this.patchPeriodId);
          
          
         
//       });
//     } else {
//         console.log("No matching period");
        
//     }

//     this.loadData();
  
      
//   });

//  }

//  onPeriodChange(){

//   console.log('value',this.form.controls['period'].value);

//   this.valuePeriod =this.form.controls['period'].value;
//   this.loader=true;

//   forkJoin([
//     this.pmsService.PmsdashboardData(),
//     this.pmsService.PmsdashboardData1(this.organizationId),
//     this.pmsService.PmsdashboardData2(),
//     this.pmsService.PmsdashboardData3(),
//     this.pmsService.PmsdashboardData4()
//   ]).subscribe(
//     ([data, data1, data2, data3, data4]) => {
//       this.empdata = data;
//       this.resultData = data1;
//       console.log(this.resultData);

//       this.listData = [];
//       this.listData = data2;
//       console.log(this.listData);

//       this.listData1 = [];
//       this.listData1 = data3;
//       console.log(this.listData1);

//       this.listData2 = [];
//       this.listData2 = data4;
//       console.log(this.listData2);
      
//       this.loader = false;
//     },
//     err => {
//       this.errorMsg = err.error.message;
//       this.loader = false;
//     }
//   );
    
//  }




//   loadData(): void {
//     this.loader=true;
//     console.log("month id",this.monthid);

//     forkJoin([
//       this.pmsService.PmsdashboardData(),
//       this.pmsService.PmsdashboardData1(this.organizationId),
//       this.pmsService.PmsdashboardData2(),
//       this.pmsService.PmsdashboardData3(),
//       this.pmsService.PmsdashboardData4()
//     ]).subscribe(
//       ([data, data1, data2, data3, data4]) => {
//         this.empdata = data;
//         this.resultData = data1;
//         console.log(this.resultData);
        
//         this.listData = data2;
//         console.log(this.listData);
//         this.listData1 = data3;
//         console.log(this.listData1);
//         this.listData2 = data4;
//         console.log(this.listData2);
//         this.loader = false;
//       },
//       err => {
//         this.errorMsg = err.error.message;
//         this.loader = false;
//       }
//     );
//     // this.pmsService.PmsdashboardData().subscribe(
//     //   (result: any) => {
//     //     this.empdata = result;
//     //     this.loader=false;
//     //   },
//     //   (err) => {
//     //     this.errorMsg = err.error.message;
//     //     this.loader=false;
//     //   }
//     // );

//     // this.loader=true;
//     // this.pmsService.PmsdashboardData1(this.organizationId).subscribe(
//     //   (result: any) => {
//     //     this.resultData = result;
//     //     this.loader=false;
//     //   },
//     //   (err) => {
//     //     this.errorMsg = err.error.message;
//     //     this.loader=false;
//     //   }
//     // );

//     // this.loader=true;
//     // this.pmsService.PmsdashboardData2().subscribe(
//     //   (result: any) => {
//     //     this.listData = result;
//     //     this.loader=false;
//     //   },
//     //   (err) => {
//     //     this.errorMsg = err.error.message;
//     //     this.loader=false;
//     //   }
//     // );

//     // this.loader=true;
//     // this.pmsService.PmsdashboardData3().subscribe(
//     //   (result: any) => {
//     //     this.listData1 = result;
//     //     this.loader=false;
//     //   },
//     //   (err) => {
//     //     this.errorMsg = err.error.message;
//     //     this.loader=false;
//     //   }
//     // );


//     // this.loader=true;
//     // this.pmsService.PmsdashboardData4().subscribe(
//     //   (result: any) => {
//     //     this.listData2 = result;
//     //     this.loader=false;
//     //   },
//     //   (err) => {
//     //     this.errorMsg = err.error.message;
//     //     this.loader=false;
//     //   }
//     // );


//   }

//   viewAll(): void {
//     this.dialog.open(PmsDashboardDialogComponentComponent, {
//       width: '70%',
//       height: '50%',
//       data: {
        
//         listPending : " Pending Appraisal Details",
//         listData: this.listData,
//         monthid: this.monthid, 
//         yearid: this.yearid, 
        
//       }
//     });
//   }

//   viewReviwed(): void {
//     this.dialog.open(PmsDashboardDialogComponentComponent, {
//       width: '70%',
//       height: '50%',
//       data: {
        
//         listPending : " Reviewed Appraisal Details",
//         listData1: this.listData1,
//         monthid: this.monthid,
//         yearid: this.yearid, 
        
//       }
//     });
//   }

//   viewclosed(): void {
//     this.dialog.open(PmsDashboardDialogComponentComponent, {
//       width: '70%',
//       height: '50%',
//       data: {


//         listPending : " Closed Appraisal Details",
//         listData2: this.listData2,
//         monthid: this.monthid, 
//         yearid: this.yearid, 
        
//       }
//     });
//   }
// }




import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from 'src/app/providers/header.service';
import { PmsService } from 'src/app/providers/pms.service';
import { PmsDashboardDialogComponentComponent } from 'src/app/pms-dashboard-dialog-component/pms-dashboard-dialog-component.component';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pms-admin-dashboard',
  templateUrl: './pms-admin-dashboard.component.html',
  styleUrls: ['./pms-admin-dashboard.component.scss']
})
export class PmsAdminDashboardComponent implements OnInit {
  form: FormGroup;
  userdata: any;
  organizationId: any;
  appriasalstatusid: any;
  resultData: any[] = [];
  errorMsg: string = '';
  empdata: any[] =[];
  listData: any[] = [];
  listData1: any[] = [];
  listData2: any[] = [];
  listPendingCount :any[] = [];
  listReviewedCount :any[] = [];
  listClosedCount:any[]=[];
  loader: boolean = false;
  periodData: any[] = [];
  valuePeriod: any;
  yearid: any;
  monthid: any;
  patchYearId: any;
  patchPeriodId: any;
  currentMonth: string;
  currentYear: number;

  nodatafound:boolean = false;

  pendingCount:any = 0;
  reviewedCount:any=0;
  closedCount:any=0;

  noPendingdata:boolean = false;
  noRevieweddata:boolean = false;
  noCloseddata:boolean = false;

  changeYearId:any;
  changeMonthId:any;

  

  constructor(
    private headerservice: HeaderService,
    private pmsService: PmsService,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.headerservice.setTitle('- Dashboard');
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData') || '{}');
    this.organizationId = this.userdata.organization.organizationId;

    this.form = this.fb.group({
      period: ['']
    });

    const currentDate = new Date();
    this.currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    this.currentYear = currentDate.getFullYear();

    

    this.pmsService.getPeriodList(this.organizationId, this.currentYear).subscribe((data: any) => {
      // this.periodData = data;
      this.periodData = Array.isArray(data) ? data : [];
      this.matchCurrentPeriod();
      this.loadData();
    });
  }

  matchCurrentPeriod() {
    this.periodData.forEach((period) => {
      if (period.financialperiodMonth === this.currentMonth) {
        this.monthid = period.financialPeriodId;
      }
      this.yearid = period.finanicalYear.financialYearId;
      console.log("read",this.yearid);
      
    });

    const finalIds = this.periodData.filter(
      (period) =>
        period.financialperiodMonth === this.currentMonth || period.financialPeriodYear === this.currentYear
    );

    if (finalIds.length > 0) {
      const matchingPeriod = finalIds[0];
      this.patchYearId = matchingPeriod.finanicalYear.financialYearId;
      this.patchPeriodId = matchingPeriod.financialPeriodId;

      this.form.patchValue({
        period: this.patchPeriodId
      });
    } else {
      console.log('No matching period');
    }
  }


  changeid(event){
    console.log("ghfghfjdf",event);
    console.log(event.finanicalYear.financialYearId);
    console.log(event.financialPeriodId);
    this.changeYearId=event.finanicalYear.financialYearId;
    this.changeMonthId=event.financialPeriodId;
    
  }

  onPeriodChange() {

    this.periodData.forEach((period) => {
      if (period.financialperiodMonth === this.currentMonth) {
        this.monthid = period.financialPeriodId;
      }
      this.yearid = period.finanicalYear.financialYearId;
      console.log("read",this.yearid);
      
    });

    this.valuePeriod = this.form.controls['period'].value;
    
    this.loader = true;

    forkJoin([
      // this.pmsService.PmsdashboardData(),
      // this.pmsService.PmsdashboardData1(this.organizationId),
      this.pmsService.PmsdashboardData2(this.valuePeriod, this.yearid),
      this.pmsService.PmsdashboardData3(this.valuePeriod, this.yearid),
      this.pmsService.PmsdashboardData4(this.valuePeriod, this.yearid),
      this.pmsService.PmsdashboardDataPendingCount(this.valuePeriod, this.yearid),
      this.pmsService.PmsdashboardDataReviewedCount(this.valuePeriod, this.yearid),
      this.pmsService.PmsdashboardDataClosedCount(this.valuePeriod, this.yearid),
    ]).subscribe(
      ([ data2, data3, data4,dataPendingCount,dataReviewedCount,dataClosedCount]) => {

        // console.log('PmsdashboardData:', data);  
        // console.log('PmsdashboardData1:', data1);  
        console.log('PmsdashboardData2:', data2);  
        if (data2.statusCode === 204){
          this.noPendingdata=true;
        }else{
          this.noPendingdata=false;
        };
        console.log('PmsdashboardData3:', data3); 
        if (data3.statusCode === 204){
          this.noRevieweddata=true;
        }else{
          this.noRevieweddata=false;
        };
        console.log('PmsdashboardData4:', data4);
        if (data4.statusCode === 204){
          this.noCloseddata=true;
        }else{
          this.noCloseddata=false;
        };
        console.log('PmsdashboardDataPendingCount:',dataReviewedCount);
        console.log('PmsdashboardDataPendingCount:',dataClosedCount);

      // this.empdata = Array.isArray(data) ? data : [data];
      // this.resultData = Array.isArray(data1) ? data1 : [data1];
      this.listData = Array.isArray(data2) ? data2 : [data2];
      this.listData1 = Array.isArray(data3) ? data3 : [data3];
      this.listData2 = Array.isArray(data4) ? data4 : [data4];
      this.listPendingCount = Array.isArray(dataPendingCount) ? dataPendingCount : [dataPendingCount];


      this.listPendingCount.forEach((count =>{
        console.log("count",count.pendingAppraisalCount);
        this.pendingCount = count.pendingAppraisalCount;
      }));

      this.listReviewedCount = Array.isArray(dataReviewedCount) ? dataReviewedCount : [dataReviewedCount];

      this.listReviewedCount.forEach((count =>{
        console.log("count",count.reviewedAppraisalCount);
        this.reviewedCount = count.reviewedAppraisalCount;
      }));

      this.listClosedCount = Array.isArray(dataClosedCount) ? dataClosedCount : [dataClosedCount];

      this.listClosedCount.forEach((count =>{
        console.log("count",count.closedAppraisalCount);
        this.closedCount = count.closedAppraisalCount;
      }));
        // this.empdata = data;
        // this.resultData = data1;
        // this.listData = data2;
        // this.listData1 = data3;
        // this.listData2 = data4;
        this.loader = false;
      },
      (err) => {
        this.errorMsg = err.error.message;
        this.loader = false;
        
      }
    );
  }

  loadData(): void {
    console.log(this.monthid);
    console.log(this.yearid);
    
    
    this.loader = true;

    forkJoin([
      this.pmsService.PmsdashboardData(),
      this.pmsService.PmsdashboardData1(this.organizationId),
      this.pmsService.PmsdashboardData2(this.monthid, this.yearid),
      this.pmsService.PmsdashboardData3(this.monthid, this.yearid),
      this.pmsService.PmsdashboardData4(this.monthid, this.yearid),
      this.pmsService.PmsdashboardDataPendingCount(this.monthid, this.yearid),
      this.pmsService.PmsdashboardDataReviewedCount(this.monthid, this.yearid),
      this.pmsService.PmsdashboardDataClosedCount(this.monthid, this.yearid),
    ]).subscribe(
      ([data, data1, data2, data3, data4,dataPendingCount,dataReviewedCount,dataClosedCount]) => {

        console.log('PmsdashboardData2:', data2);
        if (data2.statusCode === 204){
          this.noPendingdata=true;
        }else{
          this.noPendingdata=false;
        };  
        console.log('PmsdashboardData3:', data3); 
        if (data3.statusCode === 204){
          this.noRevieweddata=true;
        }else{
          this.noRevieweddata=false;
        };
        console.log('PmsdashboardData4:', data4);
        if (data4.statusCode === 204){
          this.noCloseddata=true;
        }else{
          this.noCloseddata=false;
        };
        console.log('PmsdashboardDataPendingCount:',dataPendingCount);
        console.log('PmsdashboardDataPendingCount:',dataReviewedCount);
        console.log('PmsdashboardDataPendingCount:',dataClosedCount);

        this.empdata = Array.isArray(data) ? data : [data];
      this.resultData = Array.isArray(data1) ? data1 : [data1];
      this.listData = Array.isArray(data2) ? data2 : [data2];
      this.listData1 = Array.isArray(data3) ? data3 : [data3];
      this.listData2 = Array.isArray(data4) ? data4 : [data4];

      this.listPendingCount = Array.isArray(dataPendingCount) ? dataPendingCount : [dataPendingCount];

      this.listPendingCount.forEach((count =>{
        console.log("count",count.pendingAppraisalCount);
        this.pendingCount = count.pendingAppraisalCount;
      }));

      this.listReviewedCount = Array.isArray(dataReviewedCount) ? dataReviewedCount : [dataReviewedCount];

      this.listReviewedCount.forEach((count =>{
        console.log("count",count.reviewedAppraisalCount);
        this.reviewedCount = count.reviewedAppraisalCount;
      }));

      this.listClosedCount = Array.isArray(dataClosedCount) ? dataClosedCount : [dataClosedCount];

      this.listClosedCount.forEach((count =>{
        console.log("count",count.closedAppraisalCount);
        this.closedCount = count.closedAppraisalCount;
      }));

        // this.empdata = data;
        // this.resultData = data1;
        // this.listData = data2;
        // this.listData1 = data3;
        // this.listData2 = data4;
        this.loader = false;
      },
      (err) => {
        this.errorMsg = err.error.message;
        this.loader = false;
        
      }
    );
  }

  

  viewAll(): void {
  
    this.dialog.open(PmsDashboardDialogComponentComponent, {
      width: '70%',
      height: '50%',
      data: {
        listPending: 'Pending Appraisal Details',
        listData: this.listData,
        monthid: this.monthid,
        yearid: this.yearid,
        periodchange: this.valuePeriod 
      
      }
    });
  }

  viewReviwed(): void {
 
    this.dialog.open(PmsDashboardDialogComponentComponent, {
      width: '70%',
      height: '50%',
      data: {
        listPending: 'Reviewed Appraisal Details',
        listData1: this.listData1,
        monthid: this.monthid,
        yearid: this.yearid,
        periodchange: this.valuePeriod 
      }
    });
  }

  viewclosed(): void {
  //   if (this.noPendingdata) {
  //     return;  
  // }
    this.dialog.open(PmsDashboardDialogComponentComponent, {
      width: '70%',
      height: '50%',
      data: {
        listPending: 'Closed Appraisal Details',
        listData2: this.listData2,
        monthid: this.monthid,
        yearid: this.yearid,
        periodchange: this.valuePeriod 
      }
    });
  }
}
