import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
/* export class SearchFilterPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}
 */

export class SearchFilterPipe implements PipeTransform {

  // transform(value: any, args?: any): any 
  // {
  //     if(!value)return null;
  //     if(!args)return value;

  //     args = args.toLowerCase();

  //     return value.filter(function(data){
  //         return JSON.stringify(data).toLowerCase().includes(args);
  //     });
  // }

//   transform(value: any, args?: any): any {
//     console.log("search")
//     if (!value) return null;
//     if (!args) return value;

//     args = args.toLowerCase();
//     const searchTerms = args.split(' ');

//     return value.filter((data: any) => {
//         const dataString = JSON.stringify(data).toLowerCase();

//         let dateString = '';
//         if (typeof data === 'object') {
//             for (const key in data) {
//                 if (data.hasOwnProperty(key) && !isNaN(Date.parse(data[key]))) {
//                     const date = new Date(data[key]);
//                     dateString += date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).toLowerCase();
//                 }
//             }
//         }

//         const combinedString = dataString + dateString;
//         console.log(searchTerms.every(term => combinedString.includes(term)));
//         return searchTerms.every(term => combinedString.includes(term));
        
//     });
// }
transform(value: any, args?: any): any {
  console.log("search");
  if (!value) return null;
  if (!args) return value;

  args = args.toLowerCase();
  const searchTerms = args.split(' ');

  return value.filter((data: any) => {
      const dataString = JSON.stringify(data).toLowerCase();

      let dateString = '';
      if (typeof data === 'object') {
          for (const key in data) {
              if (data.hasOwnProperty(key) && !isNaN(Date.parse(data[key]))) {
                  const date = new Date(data[key]);
                  // Format date to match 'dd/mm/yy'
                  const day = date.getDate().toString().padStart(2, '0');
                  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-based
                  const year = date.getFullYear().toString().slice(-2); // get last two digits of year
                  dateString += `${day}/${month}/${year}`;
              }
          }
      }

      const combinedString = dataString + dateString;
      console.log(searchTerms.every(term => combinedString.includes(term)));
      return searchTerms.every(term => combinedString.includes(term));
  });
}

}