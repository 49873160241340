import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateServiceService {

  constructor() { }
  private logoutNotifier = new Subject<void>();
  logoutObservable$ = this.logoutNotifier.asObservable();

  notifyLogout() {
    this.logoutNotifier.next();
  }
}
