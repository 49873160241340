import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/providers/admin.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  clientform: FormGroup;
  clientcreatedata:any;
  sucess_msg:any;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  success:any=false;
  error:any=false;
  usertype:any;
  userId:any;
  clientupdatedata:any;
  clientdata:any;
  usertData:any;
  clientId:any;
  empList:any;
  submitted:any;
  countrylist:any;
  countryId: any;
  statelist: any;
  stateId: any;
  disrictId: any;
  disrictList: any;
  cityId: any;
  cityList: any;
  addClientButton:boolean =  true;
  constructor(private router:Router,
    public ActivatedRoute:ActivatedRoute,
    private recruitmentservice :RecruitmentService,
    public dialog: MatDialog, 
    private adminService : AdminService
    /* @Inject(MAT_DIALOG_DATA) public data: any
     */
    // private dialogref : MatDialogRef<ClientComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
    
    ) { }

  ngOnInit(): void
  {
    this.clientId = this.ActivatedRoute.snapshot.params.clientId;
    console.log(this.clientId);
    ///localhost stroage data
    /* this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    console  .log(this.usertype);

    this.userId = this.usertype.userId;
    console.log(this.userId); */

      this.clientform = new FormGroup(
      {
        clientName : new FormControl(this.clientform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
        clientCode: new FormControl(this.clientform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
        // pfNumber  :  new FormControl(this.clientform,[Validators.required,Validators.pattern(/^[A-Z0-9]+$/)]),
        // esiNumber :   new FormControl(this.clientform,[Validators.required,Validators.pattern(/^[A-Z0-9]+$/)]),
        // workingDays :   new FormControl(this.clientform,[Validators.required,Validators.pattern(/^[0-9]+$/)]),
        // ratePerDay :   new FormControl(this.clientform,[Validators.required,Validators.pattern(/^(?:\d{1,5}(?:\.\d{1,2})?|\d{1,5})$/)]),
        // ratePerMonth :   new FormControl(this.clientform,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]),
        // otWagesPercentage : new FormControl(this.clientform,[Validators.pattern(/^(?:\d{1,5}(?:\.\d{1,2})?|\d{1,5})$/)]),
        // otWagesPerHour :  new FormControl(this.clientform,[Validators.pattern(/^(?:\d{1,5}(?:\.\d{1,2})?|\d{1,5})$/)]),
        addressLine1 :  new FormControl(this.clientform,[Validators.required]),
        addressLine2 :  new FormControl(this.clientform,[Validators.required]),
        // location : new FormControl(this.clientform,[Validators.required]),
        clientDescription : new FormControl(this.clientform),
        // leaveEncashmentDays :   new FormControl(this.clientform,[Validators.required,Validators.pattern(/^[0-9]+$/)]),
        country : new FormControl(this.clientform,[Validators.required]),
        state : new FormControl(this.clientform,[Validators.required]),
        district :  new FormControl(this.clientform,[Validators.required]),
        city :  new FormControl(this.clientform,[Validators.required]),
        postalCode :  new FormControl(this.clientform,[Validators.required,Validators.pattern("^[0-9.-]+$")]),
      });


     // console.log(this.data);

      //this.edit_clientId = this.data.clientId;

      if(this.clientId)
      {
        this.loader=true
       /*   console.log("if")
        console.log(this.data.clientId);
         */

      //  this.clientId=this.data.clientId;
        this.recruitmentservice.clientbyid(this.clientId).subscribe((clientdata:any) =>
        {
          this.clientdata=clientdata;
          this.loader=false;
          console.log( this.clientdata);

          this.countryId = this.clientdata?.country?.countryId;
          this.stateId = this.clientdata?.state?.stateId;
          this.disrictId = this.clientdata?.district?.districtId;
          this.cityId = this.clientdata?.city?.cityId;

          this.clientform.patchValue({
            clientName: this.clientdata?.clientName,
            clientCode : this.clientdata?.clientCode,
            //location: this.clientdata.location.toString(),
            location: this.clientdata?.location,
            clientDescription: this.clientdata?.clientDescription,
            // pfNumber  :  this.clientdata.pfNumber,
            // esiNumber :    this.clientdata.pfNumber,
            // workingDays :   this.clientdata.workingDays, 
            // ratePerDay : this.clientdata.ratePerDay,  
            // ratePerMonth :   this.clientdata.ratePerMonth,
            // otWagesPercentage :  this.clientdata.otWagesPercentage,
           
            addressLine1 :  this.clientdata.addressLine1,
            addressLine2 :  this.clientdata.addressLine2, 
            // leaveEncashmentDays : this.clientdata.leaveEncashmentDays,
            country : this.clientdata?.country?.countryName,
            state :  this.clientdata?.state?.stateName,
            district :  this.clientdata?.district?.districtName,
            city :  this.clientdata?.city?.cityName,
            postalCode :  this.clientdata.postalCode,
          });
          // if(this.clientdata.otWagesPerHour == 0){
          //   this.clientform.patchValue({
          //     otWagesPerHour : null
          //   })
          // }
          // else{
          //   this.clientform.patchValue({
          //     otWagesPerHour : this.clientdata.otWagesPerHour
          //   })
          // }
        });
      }
  }

  empSearch(event)
  {

  }

  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }
  countrySelect(country){
    console.log(country);
    this.countryId=country.countryId;
    this.clientform.patchValue({
       country:country.countryName
    });
     console.log("this.clientform",this.clientform);
  }

  countrySearch(event)
  {
    this.adminService.countryAutoCompleteSearch(event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.countrylist = data;
       }
    }
    )
  }

  stateSelect(state)
  {
    this.stateId=state.stateId;
    
    
    this.clientform.patchValue({
      state:state.stateName
     });
  }

  stateSearch(event)
  {
    this.adminService.stateAutoCompleteSearch( this.countryId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.statelist = data;
       }
    }
    )
  }

  districtSelect(disrict)
  {
    this.disrictId=disrict.districtId;
    
    
    this.clientform.patchValue({
      district:disrict.districtName
     });
  }

  districtSearch(event)
  {
    this.adminService.districtAutoCompleteSearch(this.stateId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.disrictList = data;
       }
    }
    )
  }

  citySelect(city)
  {
    this.cityId=city.cityId;
    
    this.clientform.patchValue({
        city:city.cityName
    }); 
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  
  noNumber(event: any) {
    // const inputValue = event.target.value;
  
  
    const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
  }
  citySearch(event)
  {
    this.adminService.cityAutoCompleteSearch(this.disrictId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.cityList = data;
       }
    }
    )
  }

  calculatemontly()
  {
      if( this.clientform.value.ratePerDay && this.clientform.value.workingDays)
      {
        let montlyamount = this.clientform.value.ratePerDay * this.clientform.value.workingDays;
        this.clientform.patchValue({
          ratePerMonth : montlyamount?.toFixed(2)
        })
      }
   
  }

  calculatdaily(){
    if( this.clientform.value.ratePerMonth && this.clientform.value.workingDays)
      {
        let dailyamount = this.clientform.value.ratePerMonth / this.clientform.value.workingDays;
        this.clientform.patchValue({
          ratePerDay: dailyamount?.toFixed(2)
        })
      }
   
  }

  submitData()
  {
    // this.error = false;
    // this.success = false;
    // console.log("clientform",this.clientform);
    // console.log("this.clientform",this.clientform.value);
    // if(this.clientform.invalid)
    // {
    //    const controls = this.clientform.controls;
    //   console.log(controls);
    //   for (const name in controls) {
    //       if (controls[name].invalid) {
    //          console.log(name);
    //       }
    //   } 
    //   return;
    // }

    this.error = false;
    this.success = false;
    console.log("clientform", this.clientform);
    console.log("this.clientform", this.clientform.value);
    if (this.clientform.invalid) {
      Object.keys(this.clientform.controls).forEach(field => {
        const control = this.clientform.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }


      if( this.clientform.value.otWagesPercentage && this.clientform.value.otWagesPerHour)
      {
         alert("Please use ot wage percentage or rate per hour");
         
          return;
      }

    if(this.clientId )
    {
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      this.userId = this.usertData.userId;
      let organizationId = this.usertData.organization.organizationId;

      var clientupdate = this.clientform.value;
      
      clientupdate["clientId"] =  this.clientId;
      clientupdate["lastUpdatedBy"] = this.userId;
      clientupdate["status"]  = this.clientdata.status
      clientupdate['organization']  = {
        organizationId: organizationId
      },
      clientupdate.country={
        countryId : this.countryId
       },
       clientupdate.state={
         stateId : this.stateId
       },
       clientupdate.district={
         districtId : this.disrictId
       },        
       clientupdate.city={
         cityId : this.cityId
       }

      this.backdrop = true;
      // this.loader=true;
      console.log("clientupdate",clientupdate);
      this.addClientButton = false;
      this.recruitmentservice.clientupdate(clientupdate , this.clientId).subscribe( (data:any) =>
      {

         this.clientupdatedata= data;
        
        this.backdrop = false;
        // this.loader = false;
        this.success = true;

        this.sucess_msg = this.clientupdatedata.message;
        this.addClientButton = true;
    //    this.dialog.closeAll();
        setTimeout(() => {
          this.addClientButton=true

          this.router.navigate(['home/clients']);
        }, 4000)
          //window.location.reload();
       //   this.dialog.closeAll(); 
      },
      (err) =>
      {
        this.backdrop = false;
        // this.loader = false;
        this.error = true;
        this.sucess_msg = err.error.message;
        this.addClientButton = false;
        console.log("MY MESSAGE 2: ",this.sucess_msg);
        /* setTimeout(() => {this.error = false}, 3000) */
      });
    }
    else
    {
        this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
        this.userId = this.usertData.userId;
        let organizationId = this.usertData.organization.organizationId;
         
        var clientcreate = this.clientform.value;
        // {
        //   clientName : this.clientform.value.clientName,
        //   //location :this.clientform.value.location.split(" "),
        //   location :this.clientform.value.location,
        //   clientDescription : this.clientform.value.clientDescription,
        clientcreate['organization']  = {
            organizationId: organizationId
          },
        clientcreate['createdBy'] = this.userId,
        clientcreate.country={
         countryId : this.countryId
        },
        clientcreate.state={
          stateId : this.stateId
        },
        clientcreate.district={
          districtId : this.disrictId
        },        
        clientcreate.city={
          cityId : this.cityId
        }

        // };
        console.log(clientcreate);
        this.backdrop = true;
        // this.loader=true;
        console.log("clientcreate",clientcreate);
        this.addClientButton = false;
        this.recruitmentservice.clientcreate(clientcreate).subscribe( (data:any) =>
        {
          this.clientcreatedata= data;
          console.log(this.clientcreatedata);
          this.addClientButton = true;
          this.backdrop = false;
          // this.loader = false;
          if(this.clientcreatedata.statusCode == 409){
            this.error = true;
            this.addClientButton = false;

            this.update_error_msg = this.clientcreatedata.message;
          }
          else{
            this.success = true;
            this.sucess_msg = this.clientcreatedata.message;
            this.dialog.closeAll(); 
            this.addClientButton = true;
            setTimeout(() => {
              this.addClientButton = true;

              this.router.navigate(['home/clients']);
            }, 4000)
          }
          // this.dialogref.close(data);
        },
          (err) =>
          {
            this.backdrop = false;
            // this.loader = false;
            this.error = true;
            this.update_error_msg = err.error.message;
            console.log("MY MESSAGE 4: ",this.update_error_msg);
            this.addClientButton = false;
            /* setTimeout(() => {this.error = false}, 3000) */
          });
        }
  }
  
  formcancel()
  {
    console.log("close");
    this.router.navigate(['home/clients']);
   // this.dialog.closeAll();
  }

  //////get values from form //////
 // convenience getter for easy access to form fields
 get validate() { return this.clientform.controls; }


}
