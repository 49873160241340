<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Manage Approvers</span>
                </div>
                <div class="sub-header-buttons">
                    <div class="topnav-right">
                        <button (click)="Add_approver()" mat-button class="mat-focus-indicator ml-2 mat-raised-button mat-button-base " style="background:#1a83ff;color:#fff">Add Manage Appover</button>  
                    </div>     
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card" *ngIf="approverList.length > 0">
            <div class="card-body">
                <div class="row" *ngIf="approverList?.length > 5">
                    <div class="col-xl-12 col-lg-12">
                        <div class="select-pagination ">
                            <span>Show : </span>
                            <select class="select_list new-select_list" [(ngModel)]="mySelect"  (change)="changefirstpage()">
                                <option class="form-control" value="5"> 5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                            </select>
                        </div>
                    </div>  
                </div>
                <div class="table-responsive"style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                    <div *ngIf="approverList.length > 0">
                        <table class="table table-border-new" style="border-top-left-radius: 10px;border-top-right-radius: 10px;" matSort (matSortChange)="sortData($event)">
                            <thead class="t-head">
                                <tr>
                                    <th class="text-center table-data">Sl.No</th>
                                    <th mat-sort-header="rulename" class="table-data" style="width:50%">Rule&nbsp;Name</th>
                                    <th class="text-center table-data">View Approvers</th>
                                    <th class="text-center table-data">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let approver of approverList| mytask : search | paginate:{itemsPerPage:mySelect,currentPage:p};let i=index">
                                    <td class="text-center table-data" *ngIf="p">{{((p-1)*mySelect)+(i+1)}}</td>
                                    <td class="text-center table-data" *ngIf="!p">{{i+1}}</td>
                                    <td class="table-data">{{approver.rulename}}</td>
                                    <td class="text-center table-data">
                                        <mat-icon class="view-icon" (click)="viewapprover(approver)">visibility</mat-icon>
                                    </td>
                                    <td class="text-center table-data">
                                        <mat-icon class="edit-icon" (click)="updateapprover(approver.approverId)">edit</mat-icon>
                                    </td> 
                                </tr>
                            </tbody>
                            <!-- <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="10" *ngIf="nodata">No Records Found!</td>
                                </tr>
                            </tbody> -->
                            <tbody style="    border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;">
                                                            <tr>
                                                                <td   style="
                                                                text-align: center;
                                                                font-size: larger;
                                                                font-weight: bold;
                                                            " colspan="11" 
                                                            *ngIf="nodata||((approverList | mytask : search)?.length === 0 && search) ||approverList?.length == 0">Streams Records Not Found!</td>
                                                            </tr>
                                                        </tbody>
                        </table>
                    </div> 
                </div>
                <div class="row topnav-right pagination-content" *ngIf="approverList?.length > 5">        
                    <div class=" text-right">
                        <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                    </div>
                </div> 
            </div> 
        </div>
    </div>
</div>
<div *ngIf="list_loader" class="align-items-center text-center">
    <div class="spinner-border" 
    style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;top: 38%;" role="status"></div>
</div> 
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div> -->
<!-- <div class="text-center" *ngIf="success"> 
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{update_error_msg}}
</div> -->





