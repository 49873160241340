import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,AbstractControl, Validators, FormBuilder }from '@angular/forms';
import { RecruitmentService } from '../providers/recruitment.service';
import { PayrollService } from 'src/app/providers/payroll.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { SettingsService } from 'src/app/providers/settings.service';
import { responseMsgTimeOut}from '../../app/providers/properties'
import { Router,ActivatedRoute} from '@angular/router';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ImportClientworkingdaysComponent} from '../import-clientworkingdays/import-clientworkingdays.component';
@Component({
  selector: 'app-client-workingdays',
  templateUrl: './client-workingdays.component.html',
  styleUrls: ['./client-workingdays.component.scss']
})
export class ClientWorkingdaysComponent implements OnInit {
  clientworkingdaysForm:FormGroup;
  filterForm:FormGroup;
  clientdata: any={};
  usertData:any;
  dateFormat: any;
  orgId:number;
  noRecordsFound: boolean =false;
  clientList:any;
  error:any=false;
  resultData : any = [];
  submitted: boolean = false;
  errormessagejson: any;
  success = false;
  sucess_msg:any;
  error_msg:any;
  p: number = 1;
  showtable:boolean=false;
  loader:boolean=false;
  tableSearch : any;
  errorMsg : any;
  finYearList: any=[];
  pagePerItem:any=10;
  chooseFinId:any;
  finPeriodList: any=[];
  importerror: boolean = false;
  clientWorkingDaysId:number;
  filterData: any=[];
  chooseFinancialyearId: any;
  financialPeriodList: any[];
  perId: any;
  staus: any;
  employeetData:any;
  financialYearId:any;
  isProcessing:boolean=false;
  paginationSet:boolean=false;
  constructor(
    private recruitmentservice :RecruitmentService,
    private fb:FormBuilder,
    private dialog:MatDialog,
    private router:Router,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private ActivatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void 
  {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    
    this.filterForm = this.fb.group({
      filterclient:[null,[Validators.required]],
      filterfinancialyear:[null],
      filterperiod:[null],
    })
    
    this.clientworkingdaysForm = this.fb.group({
      client:[null,[Validators.required]],
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      workingdays:[null,[Validators.required,Validators.pattern(/^(0\.5|[1-9]\d{0,1}(\.\d)?)$/)]],
      leavedays :  [null ,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      paymentdate :   [null ,[Validators.required]],
    })
    
    
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })    

    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
    
    this.ActivatedRoute.paramMap.subscribe(params => {


      console.log('params', params);
      this.financialYearId = params.get('legalentityId');
      console.log('this.legalentityId', this.financialYearId);

    })

  }

  notZeroValidatorAsync(control: AbstractControl) 
  {
    const value = control.value;
    const regex =/^(?:\d\.\d{1,2}|\d{1})$/; 
    return new Promise((resolve) => {
      if (!regex.test(value.toString()) ) {
        console.log("Not Vaild");
        resolve({ notZero: true }); // Return an object with a custom error key if value is zero
      } else {
        console.log("Vaild");
        resolve(null); // Return null if the value is not zero, indicating the control is valid
      }
    });
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  importEmployee()
  {
    console.log("import");
    const dialogRef = this.dialog.open(ImportClientworkingdaysComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
  });

  
    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else 
      {
         if(result.length != 0)
         {
          console.log("Import Error");
          this.error = true;
          //this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         }
          else
          {
          if(result.statusCode > 400)
          {
            this.error = true;
            this.importerror = false;
          }
          }
        }
      });
     }
  exportToExcel()
  {
    console.log("Export");
  }
  
  searchSubmit()
  {
    this.filterData=[];
    console.log("Submit",this.filterForm.value);
    if((this.filterForm.value.filterclient==="null"||this.filterForm.value.filterclient===null)&&(this.filterForm.value.filterfinancialyear==="null"||this.filterForm.value.filterfinancialyear===null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null))
    {
      alert("Please Select Client Name");
      this.filterData=[];
      this.noRecordsFound = true;
      this.showtable=false
      return;
    }
    if((this.filterForm.value.filterfinancialyear!=="null" && this.filterForm.value.filterfinancialyear!==null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null) && (this.filterForm.value.filterclient!=="null"&&this.filterForm.value.filterclient!==null))
      {
       alert("Please select period as well.");
        this.filterData=[];
        this.noRecordsFound = false;
        // this.paginationSet=false;

        // this.getorworkingdayslist();
        this.loader=false;
        // this.isProcessing = false; 
        this.showtable=false;
       // return;
      }

    if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear==="null"||this.filterForm.value.filterfinancialyear===null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null)||
   (this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null))
    {
      this.isProcessing=true;
      this.loader=true;
      this.paginationSet=false;

      console.log("ClientID");
      this.payrollservice.getClientListByList2(this.filterForm.value.filterclient).subscribe((filterData:any) =>
      {
        this.noRecordsFound = false;
        this.showtable=true;
        this.loader=false

        this.filterData = filterData;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.filterclient==="null"||this.filterForm.value.filterclient===null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null))
    {
      this.isProcessing=true;
      this.loader=true;
      this.paginationSet=false;
      console.log("Finalcial Year only");
      this.payrollservice.getclientworkingdaysYearList(this.filterForm.value.filterfinancialyear,this.orgId).subscribe((filterData:any) =>
      {
        this.noRecordsFound = false;
        this.loader=false;
        this.filterData = filterData;
        this.showtable=true;

        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.filterclient==="null"||this.filterForm.value.filterclient===null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))
    {
      this.isProcessing=true;
      this.loader=true;
      this.paginationSet=false;
      console.log("Finalcial and Period");
      this.payrollservice.getFinanicalandPeriodList(this.filterForm.value.filterperiod,this.orgId).subscribe((filterData:any) =>
      {
        this.noRecordsFound = false;
        this.loader=false
        this.filterData = filterData;
        console.log(this.filterData);
                this.showtable=true;

        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))
    {
      this.isProcessing=true;
      this.loader=true;
      this.paginationSet=false;
      console.log("Both");
      this.payrollservice.getClientandFinperiodList(this.filterForm.value.filterclient,this.filterForm.value.filterfinancialyear,this.filterForm.value.filterperiod,this.orgId).subscribe((filterData:any) =>
      {
        this.noRecordsFound = false;
        this.loader=false;

        this.filterData = filterData;
        this.showtable=true;

        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
  }
 
   selectFilterFinancialyear(event)
  {
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinancialyearId=event.target.value;
    console.log(this.chooseFinancialyearId);
    if(this.chooseFinancialyearId == null || this.chooseFinancialyearId == 'null'){
      console.log('if');
      this.financialPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinancialyearId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.financialPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        filterperiod: null,
      });
    })
  }
  }
   onSubmit()
   {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    let clientId=this.employeetData?.employee?.client?.clientId
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;
    let clientDetailStore=clientId?{clientId:clientId}:null;
    // console.log('organizationId'+organizationId)
    // this.submitted=true;
    if(this.clientworkingdaysForm.invalid)
    {
      console.log("form invaild");
      return;
    }
    console.log("submit",this.clientworkingdaysForm.value);
    console.log(this.clientWorkingDaysId);
    if(this.clientWorkingDaysId)
    {
      console.log("Update"+this.clientWorkingDaysId);
      const updateWorkingDays= 
      {
        clientWorkingDaysId:this.clientWorkingDaysId,
        workingDays:this.clientworkingdaysForm.value.workingdays,
      //   client:
      //  {
      //   client:this.clientworkingdaysForm.value.client
      //  },
      client:clientDetailStore,

        financialPeriod:
        {
        financialPeriodId:this.clientworkingdaysForm.value.period
        },
        // organization:
        // {
        //  organizationId:this.orgId
        // },
       status:{
          listTypeValueId:this.staus
        }
      }
      console.log(updateWorkingDays);
      // return
      this. payrollservice.updateClientWorkingDays(this.clientWorkingDaysId,updateWorkingDays).subscribe((result: any) =>
        {
          
          if(result.statusCode == 200)
          {
            this.success = true;
            this.clientWorkingDaysId=0;
            console.log("this.clientWorkingDaysId"+this.clientWorkingDaysId);
            this.sucess_msg = result.description;
            // if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
            //  ((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
            // {
            //   console.log("Filter Call Method",this.filterForm.value);
            //   this.searchSubmit();
            // }
            if((this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null))
            {
              console.log("Without",this.filterForm.value);
              // this.searchSubmit();
            }
            else{
              console.log("Filter Call Method",this.filterForm.value);
              this.searchSubmit();
            }
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            // this.clientupdateId = null;
            this.clientworkingdaysForm.reset();
            this.submitted = false;
            // setTimeout(() => {
            //   this.router.navigate(["home/employee-rating-list"])
            // }, redirectMsgTimeOut)
          }
          // else if(result.statusCode == 409)
          // {
          //   console.log("Already Exist");
          // this.importerror = true;
          // // this.error = true;
          // this.error_msg = result.description;
          // this.errormessagejson = result;
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          // }
          else
          {
          console.log("err1",result.statusCode);
          this.error = true;
          this.importerror = true;
          this.errormessagejson = result;
          // console.log('this.errormessagejson',this.errormessagejson);
          //  this.error_msg = result.description;
          //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        },
         err =>
            {
                console.log("code1",err.statusCode);
                setTimeout(() => {
                  this.error = true;
                  this.errorMsg = err.error.description;
                }, redirectMsgTimeOut) 
            })
    }
    else
    {
      console.log("create");
      const createWorkingDays= 
      {
        workingDays:this.clientworkingdaysForm.value.workingdays,
        // client:
        // {
        //     clientId:this.clientworkingdaysForm.value.client
        // },
        financialPeriod:
        {
            financialPeriodId:this.clientworkingdaysForm.value.period
        },
        // organization:
        // {
        //  organizationId:this.orgId
        // },
        client:clientDetailStore,

    }
    console.log(createWorkingDays);
    return
    this.payrollservice.createClientWorkingDays(createWorkingDays).subscribe((result: any) =>
    {
      console.log("Create"+result);
     if(result.statusCode == 200)
     {
       this.success = true;
       this.sucess_msg = result.description; 
      //  if((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)||(this.filterForm.value.filterfinancialyear=="null"||(this.filterForm.value.filterfinancialyear==null))||((this.filterForm.value.filterfinancialyear=="null")||(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null))||
      //  ((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null)))
      //  {
      //    console.log("Filter Call Method",this.filterForm.value);
      //   //  this.searchSubmit();
      //  }
      if((this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null))
      {
        console.log("Without",this.filterForm.value);
        // this.searchSubmit();
      }
      else{
        console.log("Filter Call Method",this.filterForm.value);
        this.searchSubmit();
      }
       setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
       this.clientworkingdaysForm.reset();     
       this.submitted = false;
     }
    //  else if(result.statusCode == 409)
    //       {
    //         console.log("Already Exist");
    //         // this.error = true;
    //       this.importerror = true;
    //       this.error_msg = result.description;
    //    this.errormessagejson = result;
    //    setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         
    //       }
     else
     {
       console.log("err1",result.statusCode);
      //  console.log("err1",result.clientCode);
       this.error = true;
        this.importerror = true;
       this.errormessagejson = result;
      //  console.log('this.errormessagejson',this.errormessagejson);
      //  this.error_msg = result.description;
      //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
     }
   }, 
   err =>
   {
     console.log("code",err);
     console.log("code",err.statusCode);
       setTimeout(() => {
         this.error = true;
         this.errorMsg = err.error.description;
       }, redirectMsgTimeOut) 
   })
      
    }
    
   }

   updateform(row)
   {
    // this.clearForm();
    console.log("clear Form",row);
    this.clientWorkingDaysId=row.clientWorkingDaysId;
    console.log(this.clientWorkingDaysId);
    this.payrollservice.getClientworkingDaysList(this.clientWorkingDaysId,this.orgId).subscribe((resultData:any) =>
    {
      console.log("Api1",resultData);
      this.resultData=resultData;
      this.staus=resultData.status.listTypeValueId;
      console.log(this.staus);
      console.log(this.resultData);
      console.log(this.clientworkingdaysForm);

             console.log("this.clientworkingdaysForm 1", this.clientworkingdaysForm.value);
             this.settingsService.getFinancialYearId(this.resultData.financialPeriod.finanicalYear.financialYearId).subscribe(
            (resultData1: any) => {
              console.log("resultData1", resultData1.finanicalPeriod);
              this.finPeriodList = resultData1.finanicalPeriod;
              console.log("resultData2", this.finPeriodList);
              this.perId=this.resultData.financialPeriod.financialPeriodId;
              console.log("perId", this.perId);
              // this.clientworkingdaysForm.get('period').patchValue(this.resultData.financialPeriod.financialPeriodId); 
              console.log("patchvalue");
              setTimeout(() => {
                this.clientworkingdaysForm.patchValue({
                  client : this.resultData.client.clientId,
                  financialyear : this.resultData.financialPeriod.finanicalYear.financialYearId,
                  period : this.resultData.financialPeriod.financialPeriodId, 
                  workingdays : this.resultData.workingDays
                });
              },50);
              console.log("this.clientworkingdaysForm 2", this.clientworkingdaysForm.value);
            });
    })
   }

clientWorkingDaysIdage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

selectFinancialyear(event)
{
  console.log("Select indidual Employee");
  console.log(event.target.value);
  // console.log(this.filterForm.value.financialyear);
   this.chooseFinId=event.target.value;
  console.log(this.chooseFinId);
  if(this.chooseFinId == null || this.chooseFinId == 'null'){
    console.log('if');
    this.finPeriodList = [];
  }
  else{
  this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => 
  {
    console.log("PeriodData", resultData)
    this.finPeriodList = resultData.finanicalPeriod;
    console.log("finPeriodList",  this.finPeriodList)
    this.clientworkingdaysForm.patchValue({
      period: null,
    });
  })
  console.log("Inside selectFinancialyear func",this.clientworkingdaysForm);
}
}

clearTable()
{
  // this.resultData = [];
  // this.noRecordsFound = false;
  this.filterData=[];
  this.noRecordsFound = true;
  console.log('clear')
  this.filterForm.clearValidators;
}

clearForm() 
{
  this.clientworkingdaysForm.reset(); // This resets all form controls to their initial values
}

pageChages()
{
    console.log("page changes");
    this.p=1;
}
get validate() 
{ 
  return this.clientworkingdaysForm.controls; 
}
  
showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

 updateStatus(id,status)
	{
    this.payrollservice.deactiveWorkingDays(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}
}
