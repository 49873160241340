import { Component, OnInit, Inject,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';

@Component({
  selector: 'app-assign-employee-import-component',
  templateUrl: './assign-employee-import-component.component.html',
  styleUrls: ['./assign-employee-import-component.component.scss']
})
export class AssignEmployeeImportComponentComponent implements OnInit {

  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  disableButton:boolean=false;
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  fileInputLabel: string;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeefile :any;
  employeetData:any;
  errorrecords: any;
  constructor(
    // private settingsservice: SettingsService,
    // private EmployeeService: EmployeeService,
      private globalservice: ProjectManagementService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AssignEmployeeImportComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      file : ['',[Validators.required]]
    });
  }

  get validate() { return this.postForm.controls; }

  // onSubmit()
  // {
   

  //   this.submitted = true;
  //   if (this.postForm.invalid) {return;}

  //   this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

  //   let employeeId = this.employeetData.employeeId;
  //   let organizationId = this.employeetData.organization.organizationId;
  

    
    
  //   const formData = new FormData();
  //   formData.append("file", this.postForm.get('file').value);

  //   formData.append('organizationId', organizationId);


  //   this.globalservice.taskImport(formData).subscribe((result: any) =>
  //   {
  //     if(result.statusCode == 409)
  //     {
  //       this.loader = true;

  //       this.sucess_msg = result.description;

       
  //       this.error = true;
   
  //       this.dialogref.close(result);

  //     }
  //     else
  //     {

      
  //       this.success = true;
  //       this.error = true;
  //       this.error_msg = result.description;
  //       this.errorrecords = result;
  //       this.dialogref.close(result);
  //     }
  //   }, error => {
  //     console.log(error);
  //   }); //Create end here

  // }





  // onSubmit()
  // {
  //   /* if (!this.postForm.get('myfile').value)
  //   {
  //     alert('Please fill valid details!');
  //     return false;
  //   } */

  //   this.submitted = true;
  //   if (this.postForm.invalid) {return;}

  //   this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

  //   let employeeId = this.employeetData.employeeId;
  //   let organizationId = this.employeetData.organization.organizationId;
  

    
    
  //   const formData = new FormData();
  //   formData.append("file", this.postForm.get('file').value);

  //   formData.append('organizationId', organizationId);

  //   // this.EmployeeService.employeeimport(formData).subscribe((result: any) =>
  //   this.globalservice.taskImport(formData).subscribe((result: any) =>
  //   {
  //     if(result.statusCode == 409)
  //     {
  //       //this.loader = true;
  //       this.success = true;
  //       this.sucess_msg = result.description;
  //      this.error =  true;
       
      
  //       // setTimeout(() => {this.success  = false;},responseMsgTimeOut)
  //       // this.backdrop = false;
  //       // this.loader = false;
  //       // this.success = true;
  //       this.dialogref.close(result);

  //     }
  //     else
  //     {
  //       this.error = true;
  //       this.error_msg = result.description;
  //       this.errorrecords = result;
  //       this.dialogref.close(result);
  //     }
  //   }, error => {
  //     console.log(error);
  //   }); //Create end here

  // }



  onSubmit()
  {

    this.loader =  true;
    /* if (!this.postForm.get('myfile').value)
    {
      alert('Please fill valid details!');
      return false;
    } */

    this.submitted = true;
    if (this.postForm.invalid) {return;}

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;
  

    
    
    const formData = new FormData();
    formData.append("file", this.postForm.get('file').value);

    formData.append('organizationId', organizationId);

    // this.EmployeeService.employeeimport(formData).subscribe((result: any) =>
    this.globalservice.employeeTaskImport(formData).subscribe((result: any) =>
    {

      if(result.statusCode==200){
        this.success = true;
        // this.isbtnDisabled=true;
        this.loader=false
        // this.sucess_Msg=result.description
        // this.isButtonDisabled = true;

        setTimeout(()=>{
          this.success = false;
          // this.isbtnDisabled=true
          this.loader=false

          // this.sucess_Msg=result.description
          this.dialogref.close(result);

              },2000);
              // this.success = false;


      // this.router.navigate(['/home/assign-approver']);

      }
      if(result.statusCode == 409)
      {
        this.loader = false;
        this.success = true;
        // this.sucess_msg = result.description;

       
      
        // setTimeout(() => {this.success  = false;},responseMsgTimeOut)
        // this.backdrop = false;
        // this.loader = false;
        // this.success = true;
        this.dialogref.close(result);

      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        this.errorrecords = result;
        this.dialogref.close(result);
      }
    }, error => {
      console.log(error);
    }); //Create end here

  }

  // onFileSelect(event)
  // {
  //   //let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
  //   if (event.target.files.length > 0)
  //   {
  //     const file = event.target.files[0];
  //     //this.fileToUpload = event.item(0);
  //     // console.log(file);

  //     /*   if (!_.includes(af, file.type)) {
  //       alert('Only EXCEL Docs Allowed!');
  //     } else { */
  //       this.fileInputLabel = file.name;
  //       this.postForm.get('file').setValue(file);
  //     ///}
  //   }
  // }
  get f(){
    return this.postForm.controls;
  }
  get file() {
    return this.postForm.get('file');
  }
  onFileSelect(event) {

    const fileInput = event.target as HTMLInputElement;
    const filePath = fileInput.value;
    const allowedExtensions = /(\.xls|\.xlsx)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload a file with .xls or .xlsx extension.');
      fileInput.value = '';
      event.preventDefault();
    } else {
      if (event.target.files.length > 0) {
        this.disableButton = true;
        const file = event.target.files[0];
        this.fileInputLabel = file.name;
        this.postForm.get('file').setValue(file);
      }
    }




    // if (event.target.files.length > 0) {
    //   const file = event.target.files[0];
  
    //   // Null check for the file control
    //   const fileControl = this.postForm.get('file');
    //   if (fileControl && !fileControl.disabled ) {
    //     this.fileInputLabel = file.name;
    //     fileControl.setValue(file);
    //   }
    // }
  }
  onCancel() {

    if( this.postForm.invalid){
      this.submitted = false; 
      this.postForm.reset();
    }
    this.postForm.reset();
    this.postForm.get('file').reset();

    this.submitted = false; // Reset the submitted flag to false
    // this.postForm.reset(); // Optionally reset the form if needed
    // this.dialogref.close(); // Close the dialog
}

}
