import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl:'./feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  result: any[];
error1: boolean=false;
submitBoolean: boolean=false;

  constructor(private router: Router, private hrmsService: HrmsserviceService, private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,  private elementRef: ElementRef,
  ) { }

  resFeedbackCreateData: any;
  resourceId: any;
  loader: any = true;
  resourceObj: any;
  ratings: any = [];
  skills: any = [];
  recommendations: any = [];
  feedbackFormWithArray: FormGroup;
  feedbackId: any;
  recId: number;
  feedbackData: any;
  recCheckbox: any;
  rec: any;
  resRatingData: any;
  ratingsArray = [];
  skillsData: any;
  resName: string;
  position: string;
  update_data_error_msg: any;
  create_sucess_msg: any;
  backdrop: any = false;
  success: any = false;
  error: any = true;
  resRatingId: any;
  submitted: boolean = false;
  showerror: boolean = false;
  showradioerror: boolean = false;

  ngOnInit(): void {

    // this.feedbackFormWithArray =  new FormGroup({
    //   applicantId: new FormControl(),
    //   comments: new FormControl(this.feedbackFormWithArray,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
    //   skills: this.fb.array(
    //     this.skills.map((t) => {
    //       this.fb.control(t);
    //     })
    //   ),
    // });
    this.feedbackFormWithArray = this.fb.group({
      applicantId: [''],
      comments: [''],
      // comments:  new FormControl(this.feedbackFormWithArray,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      recommendation: ['', [Validators.required]],
      skills: this.fb.array(
        this.skills.map((t) => {
          this.fb.control(t, { validators: [Validators.requiredTrue] });
        })
      ),
    });
    this.resourceId = this.activatedRoute.snapshot.params.resourceId;
    this.hrmsService
      .findbyResourceId(this.resourceId)
      .subscribe((resource: any) => {
        this.resourceObj = resource;
        console.log(this.resourceObj);
        this.resName = this.resourceObj.resourceName;
        console.log('this.resName', this.resName)
        this.position = this.resourceObj.position.positionName
        this.loader = false;
      });

    if (this.activatedRoute.snapshot.params.feedbackId) {
      this.feedbackId = this.activatedRoute.snapshot.params.feedbackId;
      this.hrmsService.getAllRatings()
      .subscribe((ratingList: any) => {
        this.ratings = ratingList.reverse();
        console.log('ratingList', this.ratings);
        this.hrmsService.getAllSkills()
        .subscribe((skillList: any) => {
          this.skills = skillList;
          console.log('skillList', this.skills);
          this.feedbackFormWithArray = this.fb.group({
            applicantId: [''],
            comments: [''],
            recommendation: ['', [Validators.required]],
            skills: this.fb.array(
              this.skills.map((t) => {
                this.fb.control(t, { validators: [Validators.requiredTrue] });
              })
            ),
          });

          this.hrmsService.getAllRecommendations()
          .subscribe((recList: any) => {
            console.log('recList', recList);
            this.recommendations = recList;
            this.hrmsService.getResourceFeedbackByFeedbackId(this.activatedRoute.snapshot.params.feedbackId)
            .subscribe((feedback: any) => {
              this.feedbackData = feedback;
              console.log('feedbackdata',this.feedbackData);
              this.recommendations.map((recomendation) => {
                if (recomendation.listTypeValueId == feedback.recomendation.listTypeValueId) {
                  console.log('equal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                  recomendation.selected = !recomendation.selected;
                  this.feedbackFormWithArray.patchValue({
                    recommendation:feedback.recomendation.listTypeValueId,
                  });
                  console.log( this.feedbackFormWithArray);
                } else {
                  console.log('notequal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                  recomendation.selected = false;
                }
              })
              const data = this.feedbackData.resourceRating.map(x => x.rating.ratingId)
              console.log('data', data)
              var arr = data;
              this.feedbackFormWithArray.controls.skills.patchValue(arr)
              this.feedbackFormWithArray.patchValue({
                comments: this.feedbackData.comments,
              });
            }
            )
          });
        });
      });
    } else {
      this.hrmsService.getAllRatings()
        .subscribe((ratingList: any) => {
          this.ratings = ratingList.reverse();
          console.log('ratingList', this.ratings);
        });

      this.hrmsService.getAllSkills()
        .subscribe((skillList: any) => {
          this.skills = skillList;
          console.log('skillList', this.skills);
          this.feedbackFormWithArray = this.fb.group({
            applicantId: [''],
            comments: [''],
            recommendation: ['', [Validators.required]],
            skills: this.fb.array(
              this.skills.map((t) => {
                this.fb.control(t, { validators: [Validators.requiredTrue] });
              })
            ),
          });
        });

      this.hrmsService.getAllRecommendations()
        .subscribe((recList: any) => {
          console.log('recList', recList);
          this.recommendations = recList;
        });
    }
  }

  formcancel() {
    this.router.navigate(['home/resource-list']);
  }

  isAllSelected(recomendation) {
    console.log("isAllSelected");
    this.recommendations.forEach(val => {
      if (val.listTypeValueId == recomendation.listTypeValueId) {
        val.selected = !val.selected;
        if (val.selected == true) {
          this.showerror = false;
        } else {
          this.showerror = true;
        }
        this.recId = recomendation.listTypeValueId;
        console.log('this.feedbackFormWithArray.value.recommendation', this.feedbackFormWithArray.value.recommendation)
        console.log('this.recId', this.recId)
        console.log('selected', val.selected)
      } else {
        console.log('unselected', val.selected)
        val.selected = false;
        // this.recId = 0;
      }
    });
  }

  submitData() {
    this.submitBoolean=true
    console.log(this.feedbackFormWithArray);
    console.log(this.feedbackFormWithArray.value);
    console.log('this.feedbackFormWithArray.value.recommendation', this.feedbackFormWithArray.value.recommendation)
    this.submitted = true;

    if(this.feedbackFormWithArray.valid){
this.submitBoolean=false;
    }

    if (this.feedbackFormWithArray.value.recommendation == false) {
      this.showerror = true;
      return
    }

    if (this.feedbackId) {
      const resultArray = [];
      for (let i = 0; i < this.skills.length; i++) {
        if (this.feedbackFormWithArray.value.skills[i] == null) {
          this.showradioerror = true;
          return
        } else {
          this.showradioerror = false;
        }
        this.feedbackData.resourceRating.forEach((element, j) => {
          if (i == j) {
            console.log('resourceRatingId', element.resourceRatingId);
            this.resRatingId = element.resourceRatingId;
          }

        });
        console.log('resultArray1', resultArray);
        resultArray.push({
          resourceRatingId: this.resRatingId,
          skill: {
            skillId: this.skills[i].skillId,
          },
          rating: {
            ratingId: this.feedbackFormWithArray.value['skills'][i],
          },
          status: 1
        });
      }
      var feedbackUpdateData = {
        feedbackId: this.feedbackId,
        resource: {
          resourceId: this.resourceId
        },
        // recomendation: {
        //   listTypeValueId: this.feedbackFormWithArray.value.recommendation
        // },
        selected: true,
        comments: this.feedbackFormWithArray.value.comments,
        resourceRating: resultArray,
        status: {
          listTypeValueId: 1
        }
      }
      if (this.feedbackFormWithArray.value.recommendation == true ||
        this.feedbackFormWithArray.value.recommendation == false) {
        feedbackUpdateData['recomendation'] = {
          listTypeValueId: this.recId
        }
      } else {
        feedbackUpdateData['recomendation'] = {
          listTypeValueId: this.feedbackFormWithArray.value.recommendation
        }
      }
      this.submitBoolean=true

      this.hrmsService.updateResourceFeedback(this.feedbackId, feedbackUpdateData)
        .subscribe(
          (data: any) => {
            this.resFeedbackCreateData = data;
            console.log('sub update:', data)
            this.backdrop = false;
            this.loader = false;
            this.success = true;
            this.submitBoolean=true

            this.create_sucess_msg = this.resFeedbackCreateData.description;
            console.log("update" + this.create_sucess_msg);
            setTimeout(() => {
              this.router.navigate(['home/resource-list']);
            }, 4000)
            this.scrollToEditForm()

          },
          (err) => {
            console.log("err");
            this.backdrop = false;
            this.loader = false;
            this.error = true;
            this.update_data_error_msg = err.error.message;
            setTimeout(() => { this.error = false }, 4000)
          }
        )
    } else {
      const resultArray = [];
      for (let i = 0; i < this.skills.length; i++) {
        if (this.feedbackFormWithArray.value.skills[i] == null) {
          this.showradioerror = true;
          return
        } else {
          this.showradioerror = false;
          // return
        }
        resultArray.push({

          skill: {
            skillId: this.skills[i].skillId,
          },
          rating: {
            ratingId: this.feedbackFormWithArray.value['skills'][i],
          },
          status: 1
        });
      }
      console.log('resultArray', resultArray);
      let createfeedback = {
        resource: {
          resourceId: this.resourceId
        },
        recomendation: {
          listTypeValueId: this.recId
        },
        selected: true,
        comments: this.feedbackFormWithArray.value.comments,
        resourceRating: resultArray
      }
      console.log(createfeedback);
      this.submitBoolean=true
      this.hrmsService.createResourceFeedback(createfeedback)
        .subscribe((data: any) => {
          console.log('subs:', data)
          this.resFeedbackCreateData = data;
          console.log(this.resFeedbackCreateData);
          this.backdrop = false;
          this.loader = false;
          this.submitBoolean=true

          this.success = true;
          this.create_sucess_msg = this.resFeedbackCreateData.description;
          console.log('create_sucess_msg', this.create_sucess_msg);
          setTimeout(() => {
            this.submitBoolean=true

            this.router.navigate(['home/resource-list']);
          }, 4000)
          this.scrollToEditForm();

        },
          (err) => {
            this.backdrop = false;
            this.loader = false;
            this.error = true;
            this.update_data_error_msg = err.error.message;
            console.log('update_data_error_msg', this.update_data_error_msg);
            setTimeout(() => { this.error = false }, 4000)
          })
    }
  }
  
  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
  }
  back()
  {
    this.router.navigate(['home/resource-list'])
  }
  
  get comments() {
    return this.feedbackFormWithArray.get('comments');
  }
  get recommendation() {
    return this.feedbackFormWithArray.get('recommendation');
  }
}
