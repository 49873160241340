<h1></h1><br>
<style>
  #printable {
    display: none;
  }

  @media print {
    #non-printable {
      display: none;
    }

    #printable {
      display: block;
    }
  }

  .content-address-lft {
    float: left;
  }

  .content-address-rgt {
    float: right;
  }

  p.lic_no {
    float: left;
    margin: 0px 0px 2px 30px;
  }


  tr.top-row-one td {
    padding: 0px;
    border: 1px solid #b3b3b3;
    padding: 2px 0px 2px 10px;
  }


  .content {
    padding: 2.25rem 1.25rem;
    -ms-flex-positive: 1;
    flex-grow: 1
  }

  .content::after {
    display: block;
    clear: both;
    content: ""
  }

  table tr td {
    border: none;
    padding: 7px 7px;
  }

  .sub-contents {
    color: black;
    font-size: 20px;
  }

  .fonts {
    font-size: 18px;
  }
</style>

<section>
  <div class="sub-header">
      <div class="col-md-12 mb-3">
          <div class="row">
              <div class="">
                <span _ngcontent-xal-c401="" class="h5 sub-content">Employee PayRoll</span>
              </div>
              <div class="sub-header-buttons">
                <button type="button" class="btn btn-light  mx-3" (click)="back()" ><i class="fa fa-arrow-left" aria-hidden="true"></i>
                  back </button>
                  <a title="Download PDF" target="_blank" class="btn text-danger">
                 
                        <i class="fa fa-file-pdf-o" (click)="printPdf()"></i>
                   
                </a>
              </div>
          </div>
      </div>
  </div>
</section>


<div id="viewPdf" #viewPdf>
  <div *ngFor="let payroll of this.payrolldata" class="card p-3 mx-4 mt-5 mb-2">
    <table style="border: 1px solid grey;margin: 20px;"
      data-pdfmake="{'widths':['*', '*', '*', 'auto', '*', '*', '*' ,'auto', '*', '*', '*', '*']}">
      <tr style="border: 1px solid grey;">
        <td colspan="2" style="text-align:center;"><strong style="font-size: 14px;">WAGE SLIP</strong></td>
        <td colspan="8" style="text-align:center;"><strong style="font-size: 14px;"></strong>Month :
          {{payroll.finanicalPeriod.financialperiodMonth }} - {{ payroll.finanicalPeriod.financialPeriodYear}}</td>
        <td colspan="2" style="text-align:center;"><strong style="font-size: 14px;">Form XXVIII</strong></td>
      </tr>
      <tr style="border: 1px solid grey; ">
        <td colspan="12" style="text-align:center;"><strong style="font-size: 12px;">Under Rule 78 (7) (b) of the
            Tamilnadu Contract Labour Rules, 1975</strong></td>
      </tr>
      <tr style="border: 1px solid grey;">
        <td colspan="6" style="text-align:left;"><strong style="font-size: 18px;"> {{orgdata ? orgdata.organizationName
            : ""}}</strong><br>{{orgdata ? orgdata.city.cityName : ""}}</td>
        <td colspan="6" style="text-align:right;"><strong style="font-size: 14px;">M/S {{clientdata ?
            clientdata.clientName : ""}}</strong><br>{{clientdata ? clientdata.city.cityName : ""}}</td>
      </tr>
      <tr style="border: 1px solid grey;">
        <td colspan="3" style="text-align:left;"><strong style="font-size: 14px;">Emp no:
          </strong>{{payroll.employee.employeeNumber}}</td>
        <td colspan="3" style="text-align:left;"><strong style="font-size: 14px;">Name:
          </strong>{{payroll.employee.firstName}}</td>
        <td colspan="6" style="text-align:left;"><strong style="font-size: 14px;">Father/ Hus Name:
          </strong>{{payroll.employee.lastName}}</td>
      </tr>
      <tr style="border: 1px solid grey;font-size: 14px;">
        <td colspan="3"
          style="border-bottom: 1px solid grey;border-right: 1px solid grey;border-left: 1px solid grey">
          <p><strong>Total Paid Days</strong> <span style="padding-left:8px;">:
              {{payroll.employeeWorkingDays ? payroll.employeeWorkingDays.workingDays : 0 }}</span></p>
          <p><strong>AADHAR</strong><span style="padding-left:50px;">: {{payroll.employee.aadhaarNumber}}</span><br>
            <strong> DOB</strong><span style="padding-left:80px;">: {{payroll.employee.dateOfBirth}}</span><br>
            <strong> UN.NO</strong><span style="padding-left:65px;">: {{payroll.employee.uanNumber}}</span><br>
          <p><strong>ESI NO</strong><span style="padding-left:63px;">: {{payroll.employee.esiNumber}}</span><br>
            <strong>PF WAGE</strong><span style="padding-left:45px;">: {{payroll.pfWage?.toFixed(2)}}</span>
          </p>
        </td>
        <td colspan="1" style="border-right: 1px solid grey;text-align:center;width: 66px;border-bottom: 1px solid grey;"> 
        <!-- <span style="writing-mode: vertical-lr;
          text-orientation: upright;font-size:16px;text-indent:3px;letter-spacing:6px;text-align: center;"> EARNINGS</span> -->
           <div class="col-md-2">
               <div class="row" style="font-size:16px;text-align: center;">
                 E
               </div>
               <div class="row" style="font-size:16px;text-align: center;">
                A
              </div>
              <div class="row" style="font-size:16px;text-align: center;">
                R
              </div>
              <div class="row" style="font-size:16px;text-align: center;">
                N
              </div>
              <div class="row" style="font-size:16px;text-align: center;">
                I
              </div>
              <div class="row" style="font-size:16px;text-align: center;">
                N
              </div>
              <div class="row" style="font-size:16px;text-align: center;">
               G
              </div>
              <div class="row" style="font-size:16px;text-align: center;">
                S
               </div>
            </div>
        
          </td>
          <td colspan="3"
            style="border-bottom: 1px solid grey;border-right: 1px solid grey;">
            <table data-pdfmake="{'widths':['*', 'auto' ,'*']}">
              <tr *ngFor="let earning of payroll.earnings">
                <td style="width:50%;font-weight: bold;padding:0px;margin:0px;border: 0;">{{earning.hrmspaySlipElement.elementName}}
                </td>
                <td style="width:50%;font-weight: bold;padding:0px;margin:0px;border: 0;"><span style="padding-left:10px;">:</span>
                </td>
                <td style="padding:0px;margin:0px;float: inline-end;border: 0;text-align: right;">{{earning.amount.toFixed(2)}}</td>
              </tr>
            </table>
          </td>
        <td colspan="1"
          style="border-bottom: 1px solid grey ;border-right: 1px solid #808080;text-align:center;width:66px;">
         
            <div class="col-md-2">
            <div class="row" style="font-size:16px;">
              D
            </div>
            <div class="row"  style="font-size:16px;">
             E
           </div>
           <div class="row"  style="font-size:16px;">
            D
           </div>
           <div class="row"  style="font-size:16px;">
             U
           </div>
           <div class="row"  style="font-size:16px;">
             C
           </div>
           <div class="row"  style="font-size:16px;">
            T
           </div>
           <div class="row"  style="font-size:16px;">
            N
           </div>
         </div>
        </td>
        <td colspan="4" style="font-size: 14px;">
          <table data-pdfmake="{'widths':['*', 'auto' ,'*']}">
            <tr *ngFor="let earning of payroll.deductions">
              <td style="padding:0px;margin:0px;border: 0;"><strong>{{earning.hrmspaySlipElement.elementName}}</strong></td>
              <td style="font-weight: bold;padding:0px;margin:0px;padding-left:50px;border: 0;"><span
                  style="padding-right:2px;">:</span> </td>
              <td style="padding:0px;margin:0px;border: 0;"><span
                  style="padding-left:60px;float: inline-end;text-align: right;">{{earning.amount.toFixed(2)}} </span></td>
            </tr>
            <tr>
              <td style="padding:0px;margin:0px;border: 0;"><strong>Total</strong></td>
              <td style="font-weight: bold;padding:0px;margin:0px;padding-left:50px;border: 0;"><span
                  style="padding-right:2px;">:</span> </td>
              <td style="border: 0;"><span style="float: inline-end;text-align: right;">{{payroll.totaldeductions}} </span></td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="font-size: 14px;">
        <td colspan="4" style="border-right: 1px solid grey;border-bottom: 1px solid grey;border-left: 1px solid grey"></td>
        <td colspan="1" style="border-right: 0px solid grey;border-bottom: 1px solid grey;"><strong>Gross</strong></td>
        <td colspan="1" style="border-right: 0px solid grey;border-bottom: 1px solid grey;border-left: 0px solid grey;"><span style="padding-left:10px;">:</span></td>
        <td colspan="1" style="border-right: 1px solid grey;border-bottom: 1px solid grey;border-left: 0px solid grey;">
          <span style="float:right;text-align: right;">{{payroll.totalearnings}}</span>
        </td>
        <td style="border-right: 1px solid grey;border-bottom: 1px solid grey;border-left: 0px solid grey;"></td>
        <td colspan="2" style="border-right: 0px solid grey;border-bottom: 1px solid grey;border-left: 0px solid grey;"><strong>NET PAY Rs.</strong></td>
        <td colspan="1" style="border-bottom: 1px solid grey;border-left: 0px solid grey;"><span style="padding-left:10px;">:</span></td>
        <td colspan="1" style="border-bottom: 1px solid grey;border-left: 0px solid grey;">
          <span style="float:right;text-align: right;">{{payroll.netpay}}</span>
        </td>
      </tr>
      <tr style="border: 1px solid grey;">
        <td colspan="6" style="text-align:left;border-right: 1px solid grey;margin:0px;border-bottom: 1px solid grey;border-left: 1px solid grey">
          <table data-pdfmake="{'widths':['*','*']}">
            <tr>
              <td  style="border: 0;"><strong style="font-size: 14px;">Signature of<br> the Employee</strong>
              </td>
              <td style="border: 0;">
                <br>
                <span  style="float:right;padding-top:10px;">{{payroll.employee.employeeInfo.bankAccountNo ?
                  payroll.employee.employeeInfo.bankAccountNo : ""}}
                  <br>
                  {{payroll.employee.employeeInfo.ifscCode ? payroll.employee.employeeInfo.ifscCode : ""}}</span>
              </td>
            </tr>
          </table>
        </td>
        <td colspan="6" style="text-align:left;border-right: 1px solid grey;margin:0px;border-bottom: 1px solid grey;">
          <table data-pdfmake="{'widths':['*','*']}">
            <tr>
              <td style="border: 0;"> <strong style="font-size: 14px;">Signature of<br> the Employer</strong>
              </td>
              <td style="border: 0;">
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</div>

<style>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid grey;
    text-align: left;
    padding: 8px;
  }

  #font {
    font-size: 12px;
  }
</style>