import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApproverStatusComponent } from 'src/app/leave/approver-status/approver-status.component';
import { ExpenseDetailsFormComponent } from '../expense-details-form/expense-details-form.component';
import { PaymentAdviceDetailsComponent } from '../payment-advice-details/payment-advice-details.component';
import { Router } from '@angular/router';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Sort } from '@angular/material/sort';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import *  as htmlToPdfmake  from "html-to-pdfmake";
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { HeaderService } from 'src/app/providers/header.service';
import { ExpenseApprovalComponent } from 'src/app/expense-approval/expense-approval.component';
@Component({
  selector: 'app-expense-reimbursement',
  templateUrl: './expense-reimbursement.component.html',
  styleUrls: ['./expense-reimbursement.component.scss']
})
export class ExpenseReimbursementComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  
  categorylistdata:any[];
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  usersId:any;
  employeeId:any;
  loader:any= false;  
  list_loader :boolean= true;
  // nodata :boolean= true;
  organizationId:any;
  organization:any;
  expenseReimbursementList: any;
  id:any;
  expenseReimbursementListFiltered: any = [];
  empId:any;
  advancebalance: any;
  subTotal: any;
  totalRebusriment: any;
  finalbalance: any;
search: any;
  beforesearchpage: number;
paymentStatus:boolean=false
  approvals: any;
  stroreiumburseId: any;
noData:boolean=false;
tableShow: boolean=false;

  constructor(private router:Router,
    private expenseservice:ProjectManagementService,
    private dialog:MatDialog,
    private headerservice: HeaderService,

    private datepipe  : DatePipe) { }

  ngOnInit(): void {
    // this.headerservice.setTitle('Expense Reimbursement');
    this.headerservice.setTitle('');

    let  localdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(localdata);
      
    this.employeeId = localdata.employee.employeeId;
    this.organizationId = localdata.organization.organizationId;
    console.log(this.employeeId);
    this.empId=localdata.employeeId;

    this.organizationId = localdata.organization.organizationId;
    console.log("this.organizationId",this.organizationId);
    
    this.list_loader=true
    this.tableShow=false
    this.expenseservice
    .getexpensereburimentbyemployee( this.employeeId )
    .subscribe((expenseReimbursementList:any) =>
    {
      // this.nodata = false;
      this.noData=false;
    

      this.expenseReimbursementList = expenseReimbursementList;
      this.tableShow=true
      console.log("this.expenseReimbursementList::",this.expenseReimbursementList);
      // console.log("this.expenseReimbursementList.advance balance::",this.expenseReimbursementList.cashAdvance);

      if(expenseReimbursementList.statusCode==204){
        console.log("nodata",expenseReimbursementList.statusCode==204);
        this.noData=true
        
      }else{
        this.noData=false;
      }
      // console.log("this.expenseReimbursementList.approvals",this.expenseReimbursementList.approvals);
//       this.expenseReimbursementList.forEach((expense: any) => {
//         // Access the approvals object from each expense object
//         this.approvals = expense.approvals.approvalStatus?.listTypeValueId;
  
//         if(expense.approvals.approvalStatus?.listTypeValueId ===5){
//           console.log("entering into the payment condition!!");
          
// this.paymentStatus=true
//         }
//         // Now you can work with the approvals object as needed
//         // console.log(this.approvals);
//       });
      // if(this.approvals ==5){
      //   console.log("if entering inside payment condition!!!");
        
      //   this.paymentStatus=true
      // }
      
      this.list_loader=false
      if(expenseReimbursementList.length == 0)      
      {
        this.noData = true;
        console.log("nodata");

      }
    
      
      
      else
      {               
        if (!Array.isArray(this.expenseReimbursementList)) {
          // console.error('this.expenseReimbursementList is not an array or is undefined:', this.expenseReimbursementList);
          return;
        }
        
        this.expenseReimbursementList.forEach(element => {   
          // console.log("element.approveer",element.approver);
          
          
          var elementobj = {
            expenseReimbursementId: element.expenseReimbursementId,
          // employee: element.employee,
        //    approver:element.approver,
            fromDate:this.datepipe.transform(element.fromDate, 'dd-MM-yyyy'),
            toDate: this.datepipe.transform(element.toDate, 'dd-MM-yyyy'),
            subTotal:  element.subTotal,
            cashAdvance: element.cashAdvance,
            totalReimbursement: element.totalReimbursement,
            advanceBalance:element.advanceBalance,
      //      approvals: element.approvals,
            paymentAdvice:element.paymentAdvice
          }  
          elementobj['finalbalance']= element.cashAdvance - element.subTotal +'.00';
          // elementobj['finalbalance']= element.advanceBalance;

          
          // console.log("element.approvals::",element.approvals);

           if(element.approvals != undefined)
           {
            elementobj["approvals"] =  element.approvals
            elementobj["approver"] =  element.approvals.apporver;
            elementobj["approvalStatus"]  = element.approvalStatus;

           }
   
            if(this.expenseReimbursementListFiltered!==undefined){
              console.log("entered in to condition!!!");
              elementobj["approvalStatus"]  = element.approvalStatus;
              console.log(elementobj["approvalStatus"] );
              

              
            }
          this.expenseReimbursementListFiltered.push(elementobj);
          console.log("this.expenseReimbursementListFiltered",this.expenseReimbursementListFiltered);
          
          // console.log(this.expenseReimbursementListFiltered);
          // console.log("element obj::",elementobj);

        });
        
      }

    },
    (error) => {
      this.noData = true;
      console.log("Error occurred while fetching expense reimbursement data:", error)
      if(error.status == 404){
        this.noData = true;
        console.log("404")
      }
    }
    ) 
    
  }

  Add_expense(){
    this.router.navigate(['home/expenseReimbursementCreate'], { state: { title: 'Create Expense Reimbursement' } }); 
  }

  addexpensereburisment(updateexpensereburismentId:any){
  
    this.router.navigate([`home/payment-advice/${updateexpensereburismentId}`]);  
  }
  
  updateexpensereburisment(expensereimbursementId:any){
    // this.stroreiumburseId=expensereimbursementId
    console.log("this.stroreiumburseId",this.stroreiumburseId);
    
    this.router.navigate([`home/expenseReimbursementCreate/${expensereimbursementId}`],{ state: { title: 'Update Expense Reimbursement' } });
  }

  updatePaymentAdvice(updateexpensereburismentId:any){
    // this.router.navigate([`home/payment-advice/${updateexpensereburismentId}`]); 
    console.log("open Payment Details");
     this.dialog.open(PaymentAdviceDetailsComponent, {
        width: '600px',
        height:'fit-content',
        data:updateexpensereburismentId
    });
  }
  changepage(){
    console.log(this.expenseReimbursementListFiltered.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.expenseReimbursementListFiltered.length);
   
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.expenseReimbursementListFiltered.length);
      console.log("nodata");
    }
  }
  showChanges() {
    console.log('show Change');
    this.p = 1;
  }
  // (expenseReimbursement.expenseReimbursementId)
  // addpaymentAdvice(updateexpensereburismentId:any){
  //   console.log("add",updateexpensereburismentId);
  //   this.router.navigate([`home/payment-advice`]); 
  // }

  openDialog(updateexpensereburismentId){
    console.log("open");
     this.dialog.open(ExpenseDetailsFormComponent, {
        width: '900px',
        height:'98%',
        data:updateexpensereburismentId
    });
  }

  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(ExpenseApprovalComponent,{
      width: '500px',
      height: '500px',
      data :approvaldata
    })
  }
   statuschangeactive(clientId)
  { 
  //   console.log("clientId"+clientId);    
  //  this.expenseservice
  //  .categorystatus(clientId,"In-Active")
  //  .subscribe( (data:any) =>
  //  {
  //     window.location.reload();  
  //  })
  }
  statuschangeinactive(clientId)
  {
  //   console.log("clientId"+clientId); 
  //  this.expenseservice
  //  .categorystatus(clientId,"Active")
  //  .subscribe( (data:any) =>
  //  {
  //     window.location.reload();
  //  })
  }
  
  
  onKeyUpEvent(event)
  {
    if(this.query.length !=0)
    {
      this.p=1;
    }
    else
    {
      this.p = this.beforepage;
    }
  }

  sortData(sort: Sort) {
    // const data = this.expenseReimbursementListFiltered.slice();
    // if (!sort.active || sort.direction === '') {
    //   this.expenseReimbursementListFiltered = data;
    //   return;
    // }

    
    // this.expenseReimbursementListFiltered .forEach((x:any)=>{
    //   x.fromDate = x.fromDate.split('-').reverse().join("-");
    //   x.toDate = x.toDate.split('-').reverse().join("-");
    // });
    console.log('this.momlist::', this.expenseReimbursementListFiltered);

    const data = this.expenseReimbursementListFiltered.slice();
    console.log('check this codition::', !sort.active || sort.direction === '');

    if (!sort.active || sort.direction === '') {
      this.expenseReimbursementListFiltered = data;
      // this.checkNoData(); 
      return;
    }
    this.expenseReimbursementListFiltered = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fromDate':
          return this.compare(new Date(a.fromDate), new Date(b.fromDate), isAsc);
        case 'toDate':
          return this.compare(new Date(a.toDate), new Date(b.toDate), isAsc);
        case 'subTotal':
          return this.compare(a.subTotal, b.subTotal, isAsc);
        case 'cashAdvance':
          return this.compare(a.cashAdvance, b.cashAdvance, isAsc);
        case 'finalbalance':
          return this.compare(parseFloat(a.finalbalance), parseFloat(b.finalbalance), isAsc);
        case 'totalReimbursement':
          return this.compare(a.totalReimbursement, b.totalReimbursement, isAsc);
        default:
          return 0;
      }
    });
    this.expenseReimbursementListFiltered .forEach((x:any)=>{
      x.fromDate = x.fromDate.split('-').reverse().join("-");
      x.toDate = x.toDate.split('-').reverse().join("-");
    });
  }

  compare(a: number | string | Date, b: number | string| Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  public downloadAsPDF() {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html , tableAutoSize : true };
    pdfMake.createPdf(documentDefinition).open(); 
     
  }
  printpdf()
  {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).print(); 
  }
}
