
<ng-container *ngIf="fId != undefined && routeFeedbackId == undefined; else form" id="editForm">
  <div class="already-submitted-div">
    <div class="share-feedback-form-div col-md-6">
      Resource Feedback Already Submitted!
    </div>
  </div>
</ng-container>
<ng-template #form id="editForm" >
  <div class="resource-background" style="font-family:arial, sans-serif" >
    <div class="resource-title">
      <h1>{{feedbackId ? 'UPDATE' : 'CREATE'}} RESOURCE FEEDBACK</h1>
    </div>
    <div class="card feedback-card" id="editForm">
      <form [formGroup]="feedbackFormWithArray" (ngSubmit)="submitData()">
        <div class="res-data">
          <!-- <div class="applicant-name-div">
            <label class="form-control-label">  <b> Applicant Name:</b></label> {{resName}}
          </div>
          <div class="position-div">
            <label class="form-control-label" ><b>Position:</b></label> {{position}}
          </div> -->
          <div>
            <label for="applicant-name"><b>Applicant Name:</b></label> {{resName}}
        </div>
        <div class="position-div">
            <label for="position"><b>Position:</b></label> {{position}}
        </div>
        </div>
        <div class="description-div">Please use this form as a guide to evaluate the applicant’s qualifications for employment. Check the
          appropriate numeric value corresponding to the applicant’s level of qualification and provide appropriate
          comments in the space below.</div>
  
        <!-- <div>
          <label class="form-control-label">Rating Scale: </label>
          <div class="rating-scale-data-div" *ngFor='let rating of ratings'>{{rating.rating}}<span>.
            </span>{{rating.ratingName}}</div>
        </div> -->
        <div class="rating-scale">
          <div class="r-scale-label" for="rating-scale"><b>Rating Scale:</b></div>
          <div class="r-scale">
              <div *ngFor='let rating of ratings'>
                  <div *ngIf="rating.rating == 5 || rating.rating == 4 || rating.rating == 3">{{
                      rating.ratingId }}. {{ rating.ratingName }}
                  </div>
              </div>
          </div>
          <div class="r-scale">
              <div *ngFor='let rating of ratings'>
                  <div *ngIf="rating.rating == 2 || rating.rating == 1">{{ rating.ratingId }}. {{
                      rating.ratingName }}
                  </div>
              </div>
          </div>
      </div>
  
        <div class="rating">
          <table class="">
            <tr class="rating-tr0">
              <td class="rating-td0"></td>
              <td colspan="5" class="rating-label"><b>Rating</b></td>
          </tr>
          <tr class="rating-tr">
              <td class="rating-td0"></td>
              <td class="rating-td1" *ngFor='let rating of ratings' style="width: 10%;text-align: center;"><b>{{rating.ratingId}}</b></td>
          </tr>
          </table>
      </div>
        <table class="skills">
          <tbody formArrayName="skills" class="skills-tbody">
            <tr class="skill-tr" *ngFor="let skill of skills; let i = index">
                <td class="skill-td">
                  <p class="skill-p"><b value="skill.skillId"><span class="text-danger">*</span>{{skill.skillName}}<span>: </span></b>
                    {{skill.skillDescription}}
                  </p>
                
                </td>
                <td class="rating-td">
                  <input type="radio"  formControlName="{{ i }}" [value]="5" />
                </td>
                <td class="rating-td">
                  <input type="radio" formControlName="{{ i }}" [value]="4" />
                </td>
                <td class="rating-td">
                  <input type="radio" formControlName="{{ i }}" [value]="3" />
                </td>
                <td class="rating-td">
                  <input type="radio" formControlName="{{ i }}" [value]="2" />
                </td>
                <td class="rating-td">
                  <input type="radio" formControlName="{{ i }}" [value]="1" />
                </td>
            </tr>
            <!-- <div *ngIf="(submitted && skills.invalid)">
              <small *ngIf="skills.errors?.required" class="text-danger">Please select Skills</small>  
          </div>  -->
          </tbody>
        </table>
        <small *ngIf="showradioerror" class="text-danger">Please select skills</small>
        <!-- <div>
          <label for=""> Comments (Please summarize your perceptions of the candidate’s strengths and any concerns that
            should be considered: </label>
          <textarea name="" id="" cols="30" rows="5" formControlName="comments"></textarea>
        </div> -->
  
        <div id="holder" class="comments-div">
          <textarea class="textinput" placeholder="" rows="3"
             formControlName="comments" placeholder="Comments (Please summarize your perceptions of the candidate’s strengths and any concerns that should be considered:"></textarea>
          <!-- <span class="before-title">Comments (Please summarize your perceptions of the candidate’s strengths and any concerns that
            should be considered:</span> -->
            <!-- <div class="valid-msg" *ngIf="(comments.invalid && comments.touched) || comments.dirty">
              <small *ngIf="comments.errors?.required" class="text-danger">Please enter email</small>
              <small *ngIf="comments.errors?.pattern" class="text-danger">Please enter valid email</small>
          </div> -->
        </div>
  
        <div>
          <label class="form-control-label"><span class="text-danger">*</span>Recommendation:</label>
          <div class="recommendation-div">
            <div class="rec-div" *ngFor="let recommendation of recommendations">
              <label class="container">{{recommendation.listTypeValueName}}
              <input formControlName="recommendation" type="checkbox" value="{{recommendation.listTypeValueId}}"
                [checked]="recommendation.selected" (change)="isAllSelected(recommendation)" />
                <span class="mark"></span>
             </label>
              <!-- <div *ngIf="(submitted && recommendation.invalid)">
              <small *ngIf="recommendation.errors?.required" class="text-danger">Please select recommendation</small>  
          </div>  -->
            </div>
          </div>
            <small *ngIf="showerror" class="text-danger" >Please select recommendation</small>
        </div>
        
  
        <div class="row buttons">
          <div class="col-md-12">
              <!-- <Button type="submit"  [disabled]="feedbackFormWithArray.invalid "  class="btn button-btn" >{{feedbackId ? 'Update' : 'Create'}}</Button> -->
              <button type="submit"   [disabled]="feedbackFormWithArray.invalid || !checkBox || btnDisable"  class="btn button-btn" >{{feedbackId ? 'Update' : 'Create'}}</button>

             
              <!-- <Button type="cancel" class="btn cancel-button"(click)="formcancel()" style="border: 1px solid #ddd;">Cancel</Button> -->
          </div>
      </div>      
      
        <!-- <button class="btn button-btn" type="submit">Submit</button> -->
      </form>
    </div>
  </div>
  
  <!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
  </div> -->
  <div class="text-center" *ngIf="success" id="editForm"> 
    <div class="alert alert-position alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
  </div> 
  <div *ngIf="error" class="alert alert-position alert-danger alert-dismissible" id="editForm">
    <strong>Error!</strong><br>{{update_data_error_msg}}
  </div>

</ng-template>

