import { Component, Input, OnInit, ViewChild,Inject } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { AdminService } from 'src/app/providers/admin.service';
import { UserService } from 'src/app/providers/user.service';
// import { base_url} from '../../providers/properties';
import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { AppComponent } from '../../app.component';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit
{
  //dateFormat = GlobalConstants.dateFormat;
  dateFormat : any;
  organizationId: any;

  resultData: any = {};
  resultDatarec: any;
  
  errorMsg: any;
  admindashboard: any;
  ProfileShared: number;
  Interviewed: any;
  Notinterviewed: number;
  recruiterSearchform: FormGroup;
  @Input() requiredError: boolean;
  reportfromdate:any;
  reporttodate:any;
  startmaxdate :Date =  new Date();
  endmindate;
  recruiterlist:any;
  usertData:any;
  loader:any= false;
  backdrop:any= false;
  dataForExcel = [];
  true:any;
  interviewStatuslist:any;
  
  exportingdata:any;
  selectedrecruiter:any;
  employeeName:any;
  recruitmentservice: any;
  Adminservice: any;

  upcomingBirthday : any = {};
  upcomingWorkAnniversery : any = {};

  fileblob: any;
  fileblob1: any;

  success:any=false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  localStorageMenuId:any;
  userdata: any;
  clientCount: any;
  branchcount: any;

  constructor(
    private dashboardservice: RecruitmentService,
    private adminservice: AdminService,
    private UserService: UserService,
    private datepipe : DatePipe ,
    private activatedRoute : ActivatedRoute,
    @Inject(AppComponent) public AppData: any,
    private headerservice: HeaderService
  ) { }



  ngOnInit(): void
  { 

   this.headerservice.setTitle('- Dashboard');

   localStorage.setItem('localStorageMenuId', 'CHRMS');
    localStorage.setItem('localStorageMenuId', 'CHRMS');
    localStorage.setItem('moduleCode', 'CHRMS');
    // this.headerservice.setModule('CHRMS');
    console.log('this.AppData', this.AppData)
  
    // this.dateFormat = this.AppData.dateFormat;
    this.dateFormat = localStorage.getItem('dateFormatName');
   
    // this.organizationId = this.AppData.organizationId;
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId =this.userdata.organization.organizationId;

    console.log('this.organizationId', this.organizationId)
    

   
    this.dashboardDataNew();
  }

  ngOnDestroy(): void {
    // Cleanup logic here
    console.log('Component is destroyed');

   return this.headerservice.setTitle('');
  }

  dashboardDataNew()
  {
    this.loader = true;
    let organizationId = this.organizationId;
    this.adminservice.dashboardData(organizationId).subscribe((result: any) =>
    {
      this.loader = false;
      this.resultData = result;
      this.upcomingBirthday = result.upcomingBirthday;
      this.upcomingWorkAnniversery = result.upcomingWorkAnniversery;

      //Upcoming birthday list start
      var birthdayProfileImg = [];
      this.upcomingBirthday.forEach(elem =>
      {
        if(elem.userAttachment)
        {
          this.UserService.getuserImage(elem.userAttachment.fileViewUrl).subscribe( blob =>
            {
              const a = document.createElement('a')
              const objectUrl: any = blob

              if(blob)
              {
                birthdayProfileImg.push(blob);
              }
              this.fileblob = birthdayProfileImg;
             //this.fileblob = blob;
            },
            err => {}
          );
        }
      });
      //Upcoming birthday list end

      //Upcoming anniversery list start
      var workAnniversayProfileImg = [];
      this.upcomingWorkAnniversery.forEach(elem =>
      {
          if(elem.userAttachment)
          {
            this.UserService.getuserImage(elem.userAttachment.fileViewUrl).subscribe( blob =>
              {
                const a = document.createElement('a')
                const objectUrl: any = blob
                if(blob)
                {
                  workAnniversayProfileImg.push(blob);
                }
                this.fileblob1 = workAnniversayProfileImg;
                //console.log(fileblob);
                //this.fileblob = blob;
              },
              err => {}
            );
          }
      });
      //Upcoming anniversery list end

      /*if(resultData.userAttachment)
       {
          this.UserService.getuserImage(resultData.userAttachment.fileViewUrl).subscribe( blob => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;
          },
          err => {

          }
        );
      } */

    },err =>{
      this.errorMsg = err.error.message;
    })
  }

  ucFirst(string)
  {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }



}



