<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span
            routerLink="/super-admin-home/add-organization"
            class="h5 sub-content"
          >
            {{ organizationId ? "Edit" : "Add" }} Organization
          </span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="postForm">
          <div class="row">
            <!-- <div class="col-12">
              <h1 class="text-center headingDesign">{{ heading }}</h1>
            </div> -->
            <!-- <div class="col-12">
              <h3 class="text-center text-primary">Select Option</h3>
            </div> -->
            <!-- <div class="col-12 d-flex justify-content-center">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="options"
                  id="organization"
                  (change)="onOptionChange($event)"
                  checked
                  value="organization"
                />
                <label class="form-check-label" for="organization"
                  >Organization</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="options"
                  id="client"
                  value="client"
                  (change)="onOptionChange($event)"
                />
                <label class="form-check-label" for="client">Client</label>
              </div>
            </div> -->
          </div>

          <div *ngIf="selectedOption === 'organization'">
            <div class="row">
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Business Unit <span class="star">*</span></label
                >
                <mat-select
                formControlName="businessUnitorg"
                placeholder="Select Business Unit"
                class="form-control email-input select-placeholder"
              >
                <mat-option value="null" selected (click)="businessorgset()"
                  >-Select Business Unit-</mat-option
                >
                <mat-option *ngFor="let row of activeBusinessUnitList; let i=index;" [value]="row.businessUnitId">{{row.businessUnitName}}  
                 </mat-option>

              </mat-select>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.businessUnitorg?.invalid &&
                      validate.businessUnitorg?.touched) ||
                    validate.businessUnitorg?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.businessUnitorg.errors?.required"
                    class="text-danger"
                    >Please select Business Unit</span
                  >
                  <span
                    *ngIf="validate.businessUnitorg.errors?.pattern"
                    class="text-danger"
                    >Please select valid Business Unit</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Organization Name <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="checkOrganizationName($event)"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  placeholder="Organization Name"
                  formControlName="organizationName"
                  autocomplete="off"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.organizationName.invalid &&
                      validate.organizationName.touched) ||
                    validate.organizationName.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.organizationName.errors?.required"
                    class="text-danger"
                    >Please enter organization name</span
                  >
                  <span
                    *ngIf="validate.organizationName.errors?.pattern"
                    class="text-danger"
                    >Please enter valid organization name</span
                  >
                  <span *ngIf="organizationNameExistStatus" class="span-error"
                    >Organization name already exist!</span
                  >
                  <span *ngIf="existMsg" class="text-danger">{{
                    existMsg
                  }}</span>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Industry Type <span class="star">*</span></label
                >
                <!-- <select formControlName="industryType" id="industryType" class="form-select form-select-solid form-select-lg">
                                <option value="">- Select -</option>
                                <option *ngFor="let row of industryTypeLov; let i=index;" value="{{row.listTypeValueId}}">{{row.listTypeValueName}}</option>
                            </select> -->
                <mat-select
                  formControlName="industryType"
                  placeholder="Select Industry Type"
                  class="form-control email-input select-placeholder"
                >
                  <mat-option value="null" selected (click)="setIndustry()"
                    >-Select Industry Type-</mat-option
                  >
                  <mat-option *ngFor="let row of industryTypeLov; let i=index;" [value]="row.listTypeValueId">{{row.listTypeValueName}}
                  </mat-option>
                  <!-- <mat-option value="Service Based">Service Based</mat-option> -->
                </mat-select>

                <!--  <select formControlName="industryType" placeholder="- Select -" class="form-select form-select-solid form-select-lg select2-new">
                                <option value="null">- Select -</option>
                                <option *ngFor="let row of industryTypeLov" value="{{row.listTypeValueId}}">{{row.listTypeValueName}}</option>
                            </select> -->

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.industryType.invalid &&
                      validate.industryType.touched) ||
                    validate.industryType.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.industryType.errors?.required"
                    class="text-danger"
                    >Please select industry type</span
                  >
                  <span
                    *ngIf="validate.industryType.errors?.pattern"
                    class="text-danger"
                    >Please select valid industry type</span
                  >
                </div>
              </div>

              <!-- <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Contact Person <span class="star">*</span></label
                >
                <input
                  type="text"
                  formControlName="contactPerson"
                  id="contactPerson"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                  placeholder="Contact Person"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.contactPerson.invalid &&
                      validate.contactPerson.touched) ||
                    validate.contactPerson.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.contactPerson.errors?.required"
                    class="text-danger"
                    >Please enter contact person</span
                  >
                  <span
                    *ngIf="validate.contactPerson.errors?.pattern"
                    class="text-danger"
                    >Please enter valid contact person</span
                  >
                </div>
              </div> -->

              <!-- <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Contact Phone Number <span class="star">*</span></label
                >
                <input
                  type="text"
                  mimlength="9"
                  maxlength="10"
                  formControlName="contactPhoneNumber"
                  (keyup)="checkContactNumber($event)"
                  id="contactPhoneNumber"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                  placeholder="Contact Phone Number"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.contactPhoneNumber.invalid &&
                      validate.contactPhoneNumber.touched) ||
                    validate.contactPhoneNumber.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.contactPhoneNumber.errors?.required"
                    class="text-danger"
                    >Please enter contact phone number</span
                  >
                  <span
                    *ngIf="validate.contactPhoneNumber.errors?.pattern"
                    class="text-danger"
                    >Please enter valid contact phone number</span
                  >
                  <span *ngIf="contactNumberExistStatus" class="span-error"
                    >Contact number already exist!</span
                  >
                </div>
              </div> -->

              <!-- <div class="form-group col-md-3">
                            <label class="form-control-label">Contact Country Code <span class="star">*</span></label>
                            <input type="text" formControlName="contactCountryCode" id="contactCountryCode" class="form-control email-input select-placeholder mb-3 mb-lg-0" autocomplete="off" placeholder="Contact Country Code">
                            <div class="valid-msg" *ngIf="(validate.contactCountryCode.invalid && validate.contactCountryCode.touched) || validate.contactCountryCode.dirty || submitted">
                                <span *ngIf="validate.contactCountryCode.errors?.required" class="text-danger">Please enter Contact Country Code</span>
                                <span *ngIf="validate.contactCountryCode.errors?.pattern" class="text-danger">Please enter valid Contact Country Code</span>
                            </div>
                        </div> -->
            </div>

            <!-- <div class="row">
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Contact Email <span class="star">*</span></label
                >
                <input
                  type="text"
                  formControlName="contactEmail"
                  (keyup)="checkContactEmail($event)"
                  id="contactEmail"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                  placeholder="Contact Email"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.contactEmail.invalid &&
                      validate.contactEmail.touched) ||
                    validate.contactEmail.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.contactEmail.errors?.required"
                    class="text-danger"
                    >Please enter contact email</span
                  >
                  <span
                    *ngIf="validate.contactEmail.errors?.pattern"
                    class="text-danger"
                    >Please enter valid contact email</span
                  >
                  <span *ngIf="contactEmailExistStatus" class="span-error"
                    >Contact email already exist!</span
                  >
                </div>
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label">Postal Code</label>
                <input
                  type="text"
                  maxlength="6"
                  formControlName="postalCode"
                  id="postalCode"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0 only_name"
                  placeholder="Postal Code"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.postalCode.invalid &&
                      validate.postalCode.touched) ||
                    validate.postalCode.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.postalCode.errors?.required"
                    class="text-danger"
                    >Please enter postal code</span
                  >
                  <span
                    *ngIf="validate.postalCode.errors?.pattern"
                    class="text-danger"
                    >Please enter valid postal code</span
                  >
                </div>
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label">Address</label>
                <input
                  type="text"
                  formControlName="address"
                  id="address"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="Address"
                  autocomplete="off"
                />
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label">Fiscal Month Start</label>
                <input
                  type="text"
                  formControlName="fiscalYearStart"
                  id="fiscalYearStart"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="Fiscal Month Start (MM)"
                  autocomplete="off"
                />
              </div>
            </div> -->

            <div class="row">
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Country <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="countryAutoCompleteSearch($event)"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="countryName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="Country Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="countryId"
                  id="countryId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.countryId.invalid &&
                      validate.countryId.touched &&
                      autoCompleteCountrySearch == 1) ||
                    (validate.countryName.invalid &&
                      validate.countryName.touched) ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.countryId.errors?.required"
                    class="text-danger"
                    >Please enter country name.</span
                  >
                </div>

                <!-- AutoComplete Search here -->
                <span *ngIf="autoCompleteCountrySearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="countryData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(
                          searchData.countryId,
                          searchData.countryName,
                          1
                        )
                      "
                      *ngFor="let searchData of countryData"
                    >
                      {{ searchData.countryName }}
                    </li>
                  </ul>
                </span>
                <!-- AutoComplete End here -->
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label">State <span class="star">*</span> </label>
                <input
                  type="text"
                  (keyup)="stateAutoCompleteSearch($event)"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="stateName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="State Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="stateId"
                  id="stateId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.stateId.invalid &&
                      validate.stateId.touched &&
                      autoCompleteStateSearch == 1) ||
                    (validate.stateName.invalid &&
                      validate.stateName.touched) ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.stateId.errors?.required"
                    class="text-danger"
                    >Please enter state name.</span
                  >
                </div>

                <!-- AutoComplete Search here -->
                <span *ngIf="autoCompleteStateSearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="stateData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(
                          searchData?.stateId,
                          searchData?.stateName,
                          2
                        )
                      "
                      *ngFor="let searchData of stateData"
                    >
                      {{ searchData?.stateName }}
                    </li>
                  </ul>
                </span>
                <!-- AutoComplete End here -->
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label">District <span class="star">*</span> </label>
                <input
                  type="text"
                  (keyup)="districtAutoCompleteSearch($event)"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="districtName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="District Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="districtId"
                  id="districtId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.districtId.invalid &&
                      validate.districtId.touched &&
                      autoCompleteDistrictSearch == 1) ||
                    (validate.districtName.invalid &&
                      validate.districtName.touched) ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.districtId.errors?.required"
                    class="text-danger"
                    >Please enter district name.</span
                  >
                </div>

                <!-- AutoComplete Search here -->
                <span *ngIf="autoCompleteDistrictSearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="districtData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(
                          searchData.districtId,
                          searchData.districtName,
                          3
                        )
                      "
                      *ngFor="let searchData of districtData"
                    >
                      {{ searchData?.districtName }}
                    </li>
                  </ul>
                </span>
                <!-- AutoComplete End here -->
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label">City  <span class="star">*</span></label>
                <input
                  type="text"
                  (keyup)="cityAutoCompleteSearch($event)"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="cityName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="City Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="cityId"
                  id="cityId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.cityId.invalid &&
                      validate.cityId.touched &&
                      autoCompleteCitySearch == 1) ||
                    (validate.cityName.invalid && validate.cityName.touched) ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.cityId.errors?.required"
                    class="text-danger"
                    >Please enter city name.</span
                  >
                </div>

                <!-- AutoComplete Search here -->
                <span *ngIf="autoCompleteCitySearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="cityData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(searchData.cityId, searchData.cityName, 4)
                      "
                      *ngFor="let searchData of cityData"
                    >
                      {{ searchData?.cityName }}
                    </li>
                  </ul>
                </span>
                <!-- AutoComplete End here -->
              </div>
            </div>

            <div class="row">
              <!-- <div class="form-group col-md-3">
                <label class="form-control-label">Default Time Zone</label> -->
                <!-- <select formControlName="timezoneId" id="timezoneId" class="form-select form-select-solid form-select-lg">
                                <option value="">- Select -</option>
                                <option *ngFor="let row of activeTimeZoneData; let i=index;" value="{{row.timezoneId}}">{{row.timezoneName}}</option>
                            </select> -->
                <!-- <select
                  formControlName="timezoneId"
                  placeholder="- Select -"
                  class="form-select form-select-solid form-select-lg select2-new"
                >
                  <option value="null">- Select -</option>
                  <option
                    *ngFor="let row of activeTimeZoneData"
                    value="{{ row.timezoneId }}"
                  >
                    {{ row.timezoneName }}
                  </option>
                </select> -->
                <!-- <mat-select
                formControlName="timezoneId"
                placeholder="Select Default Time Zone "
                class="form-control email-input select-placeholder"
              >
                <mat-option value="null" selected (click)="setTimeZone()"
                  >-Select Default Time Zone-</mat-option
                >
                <mat-option  *ngFor="let row of activeTimeZoneData"
                [value]="row?.timezoneId">{{ row?.timezoneName }}

                </mat-option> -->
                <!-- <mat-option value="Service Based">Service Based</mat-option> -->
              <!-- </mat-select>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.timezoneId.invalid &&
                      validate.timezoneId.touched) ||
                    validate.timezoneId.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.timezoneId.errors?.required"
                    class="text-danger"
                    >Please select time zone</span
                  >
                  <span
                    *ngIf="validate.timezoneId.errors?.pattern"
                    class="text-danger"
                    >Please select valid time zone</span
                  >
                </div>
              </div> -->

              <div class="form-group col-md-3">
                <label class="form-control-label">Logo <span class="star">*</span></label>
                <input
                  type="file"
                  formControlName="profile_image"
                  (change)="getFileDetails($event)"
                  accept=".png,.jpg,.jpeg"
                  id="profile_image"
                  class="form-control"
                />
                <img
                  *ngIf="organizationId && fileblob"
                  class="profile-image"
                  src="{{ fileblob }}"
                  alt="Organization Logo"
                  style="
                    width: 150px;
                    height: 75px;
                    margin-top: 10px;
                    border-radius: 5px;
                  "
                />
                <!-- <mat-error
                *ngIf="validate.profile_image.hasError('required') && submitted"
              >
                Please select Logo
              </mat-error>  -->
              <div
              class="valid-msg"
              *ngIf="
                (validate.profile_image.invalid &&
                  validate.profile_image.touched ) ||
                (validate.profile_image.invalid && validate.profile_image.touched) ||
                submitted
              "
            >
              <span
                *ngIf="validate.profile_image.errors?.required"
                class="text-danger"
                >Please Select Logo</span
              >
            </div>
              </div>

              <!-- <div class="form-group col-md-3">
                            <label class="form-control-label">Reporting Currency</label>
                            <input type="text" formControlName="reportingCurrency" id="reportingCurrency" class="form-control email-input select-placeholder mb-3 mb-lg-0" placeholder="Reporting Currency (Rupee)" autocomplete="off">
                        </div> -->
            </div>

            <!--<div class="row">
                          <div class="form-group col-md-3">
                            <label class="form-control-label">Country <span class="star">*</span></label>
                            <select formControlName="countryId" (change)="countryChange($event)" class="select2Select form-select form-select-solid form-select-lg">
                                <option value="">- Select -</option>
                                <option *ngFor="let row of activeCountryData; let i=index;" value="{{row.countryId}}">{{row.countryName}}</option>
                            </select>
                       
                            <div class="valid-msg" *ngIf="(validate.countryId.invalid && validate.countryId.touched) || validate.countryId.dirty || submitted">
                                <span *ngIf="validate.countryId.errors?.required" class="text-danger">Please select country</span>
                                <span *ngIf="validate.countryId.errors?.pattern" class="text-danger">Please select valid country</span>
                            </div>
                        </div>

                       <div class="form-group col-md-3">
                            <label class="form-control-label">State</label>
                            <select formControlName="stateId" id="stateId" (change)="stateChange($event)" class="form-select form-select-solid form-select-lg">
                                <option value="">- Select -</option>
                                <option *ngFor="let row of stateList; let i=index;" value="{{row.stateId}}">{{row.stateName}}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="form-control-label">District</label>
                            <select formControlName="districtId" id="districtId" (change)="districtChange($event)" class="form-select form-select-solid form-select-lg">
                               <option value="">- Select -</option>
                               <option *ngFor="let row of districtList; let i=index;" value="{{row.districtId}}">{{row.districtName}}</option>
                            </select>
                        </div> 

                        <div class="form-group col-md-3">
                            <label class="form-control-label">City</label>
                            <select formControlName="cityId" id="cityId" class="form-select form-select-solid form-select-lg">
                                <option value="">- Select -</option>
                                <option *ngFor="let row of cityList; let i=index;" value="{{row.cityId}}">{{row.cityName}}</option>
                            </select>
                        </div>
                    </div> -->

            <!-- Login details start here -->
            <!-- <div class="login-details" *ngIf="organizationId == null">
              <span>
                <h4><b class="">Login Details</b></h4>
              </span>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >User Type <span class="star">*</span></label
                  > -->
                  <!-- <select
                    formControlName="userTypeId"
                    id="userTypeId"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="">- Select -</option>
                    <option
                      *ngFor="let row of userType; let i = index"
                      value="{{ row.userTypeId }}"
                    >
                      {{ row.userType }}
                    </option>
                  </select> -->
                  <!-- <mat-select
                formControlName="userTypeId"
                placeholder="Select User Type"
                class="form-control email-input select-placeholder"
              >
                <mat-option value="" selected="selected"
                  >-Select User Type-</mat-option
                >
                <mat-option  *ngFor="let row of userType; let i = index"
                [value]="row.userTypeId">{{ row.userType }}
 
                </mat-option> -->
                <!-- <mat-option value="Service Based">Service Based</mat-option> -->
              <!-- </mat-select>

                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.userTypeId.invalid &&
                        validate.userTypeId.touched) ||
                      validate.userTypeId.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="validate.userTypeId.errors?.required"
                      class="text-danger"
                      >Please select user type</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >Email <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="emailId"
                    id="emailId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="Email"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.emailId.invalid && validate.emailId.touched) ||
                      validate.emailId.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="validate.emailId.errors?.required"
                      class="text-danger"
                      >Please enter email</span
                    >
                    <span
                      *ngIf="validate.emailId.errors?.pattern"
                      class="text-danger"
                      >Please enter valid email</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >Password <span class="star">*</span></label
                  > -->
                  <!-- <input [type]="password" formControlName="password" class="form-control email-input select-placeholder mb-3 mb-lg-0" autocomplete="off" placeholder="Password">
                                 -->
                  <!-- <input
                    [type]="password"
                    formControlName="password"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="Password"
                    [ngClass]="{
                      'is-valid':
                        (validate.password.dirty || submitted) && passwordValid,
                      'is-invalid':
                        (validate.password.dirty || submitted) && !passwordValid
                    }"
                  /> -->

                  <!-- required -->
                  <!-- <div
                    class="valid-msg"
                    *ngIf="
                      (validate.password.dirty || submitted) && !requiredValid
                    "
                  >
                    <span class="text-danger">Please enter password</span>
                  </div> -->

                  <!-- Password validation rules start -->
                  <!-- <div class="invalid-feedback"> -->
                    <!-- valid-msg ng-star-inserted -->
                    <!-- <div class="mt-3" *ngIf="requiredValid"> -->
                      <!-- minlength
                      <div
                        [ngClass]="{
                          'text-success': minLengthValid,
                          'text-danger': !minLengthValid
                        }"
                      >
                        <i
                          [attr.class]="
                            minLengthValid
                              ? 'bi-check-square-fill'
                              : 'bi-x-square'
                          "
                        ></i>
                        Must be at least 8 characters long
                      </div> -->

                      <!-- requires digit -->
                      <!-- <div
                        [ngClass]="{
                          'text-success': requiresDigitValid,
                          'text-danger': !requiresDigitValid
                        }"
                      >
                        <i
                          [attr.class]="
                            requiresDigitValid
                              ? 'bi-check-square-fill'
                              : 'bi-x-square'
                          "
                        ></i>
                        Must contain at least 1 digit
                      </div> -->

                      <!-- requires uppercase -->
                      <!-- <div
                        [ngClass]="{
                          'text-success': requiresUppercaseValid,
                          'text-danger': !requiresUppercaseValid
                        }"
                      >
                        <i
                          [attr.class]="
                            requiresUppercaseValid
                              ? 'bi-check-square-fill'
                              : 'bi-x-square'
                          "
                        ></i>
                        Must contain at least 1 uppercase character
                      </div> -->

                      <!-- requires lowercase -->
                      <!-- <div
                        [ngClass]="{
                          'text-success': requiresLowercaseValid,
                          'text-danger': !requiresLowercaseValid
                        }"
                      >
                        <i
                          [attr.class]="
                            requiresLowercaseValid
                              ? 'bi-check-square-fill'
                              : 'bi-x-square'
                          "
                        ></i>
                        Must contain at least 1 lowercase character
                      </div> -->

                      <!-- requires special characters -->
                      <!-- <div
                        [ngClass]="{
                          'text-success': requiresSpecialCharsValid,
                          'text-danger': !requiresSpecialCharsValid
                        }"
                      >
                        <i
                          [attr.class]="
                            requiresSpecialCharsValid
                              ? 'bi-check-square-fill'
                              : 'bi-x-square'
                          "
                        ></i>
                        Must contain at least 1 special character
                      </div>
                    </div>
                  </div> -->
                  <!-- Password validation rules end -->

                  <!-- <div class="valid-msg" *ngIf="(validate.password.invalid && validate.password.touched) || validate.password.dirty || submitted">
                                    <span *ngIf="validate.password.errors?.required" class="text-danger">Please enter password</span>
                                    <span *ngIf="validate.password.errors?.pattern" class="text-danger">Please enter valid password</span>
                                </div> -->
                  <!-- <span class="view-password" (click)="showPassword()">
                    <i class="fa fa-eye" *ngIf="!show"></i>
                    <i class="fa fa-eye-slash" *ngIf="show"></i>
                  </span>
                </div>

                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >Roles <span class="star">*</span></label
                  >
                  <mat-select
                    formControlName="roleId"
                    placeholder="Select Roles"
                    #select
                    multiple
                    class="form-control email-input select-placeholder"
                  >
                    <div class="select-all">
                      <mat-checkbox
                        class="check-all"
                        [(ngModel)]="allSelected"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="toggleAllSelection()"
                      >
                        Select All
                      </mat-checkbox>
                    </div>

                    <mat-option
                      *ngFor="let row of roleresultData"
                      [value]="row.roleId"
                    >
                      {{ row.roleName }}
                    </mat-option> -->

                    <!-- <button mat-raised-button color="primary"style="margin: 0 18px 18px 18px" (click)="add()" class="btn create-button">
                                        Add
                                    </button>
                
                                    <button mat-raised-button color="danger" (click)="cancel()" class="btn cancel-button">
                                        Cancel
                                    </button> -->
                  <!-- </mat-select>

                  <mat-error
                    *ngIf="validate.roleId.hasError('required') && submitted"
                  >
                    Please select Roles
                  </mat-error> -->

                  <!--  <div class="valid-msg" *ngIf="(f.roleId.invalid && f.roleId.touched) || f.roleId.dirty || submitted">
                                    <span *ngIf="f.roleId.errors?.required" class="text-danger">Please select roles.</span>
                                </div> -->

                  <!-- <mat-select formControlName="roleId" class="form-select form-select-solid form-select-lg" appearance="fill" placeholder="- Select -">
                                    <mat-option value="">- Select -</mat-option>
                                    <mat-option *ngFor="let row of activeRolesData" [value]="row.roleId">{{row.roleName}}</mat-option>
                                </mat-select> -->
                  <!-- <div class="valid-msg" *ngIf="(validate.departmentCode.invalid && validate.departmentCode.touched) || validate.departmentCode.dirty || submitted">
                                    <span *ngIf="validate.departmentCode.errors?.required" class="text-danger">Please enter department code</span>
                                    <span *ngIf="validate.departmentCode.errors?.pattern" class="text-danger">Please enter valid department code</span>
                                    <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
                                </div> -->
                <!-- </div> -->
              <!-- </div> -->
            <!-- </div> -->
            <div class="row">
              <div class="col-md-12 text-right">
                <a
                  routerLink="/home/organization"
                  routerLinkActive="active"
                  mat-raised-button
                  [disabled]="isbtndisable"

                  class="mr-2"
                  style="    margin-left: 10px;
                  background: none;
                  color: var(--Red-Color, #ff5d5d);
                  border: 1px solid #ddd !important;
                  height: 32px;
                  position: relative;
                  
                  font-weight: 550;
                  padding-top: 10px;"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  [disabled]="isbtndisable"
                  mat-raised-button
                 class="btndesign"
                  (click)="onSubmit()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <!-- Login details end here -->

          <!-- CLIENT FORM STARTS HERE -->
          <!-- <div *ngIf="selectedOption === 'client'">
            <div class="row">
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Business Unit <span class="star">*</span></label
                >
                <mat-select
                formControlName="businessUnits"
                placeholder="Select Business Unit"
                class="form-control email-input select-placeholder"
              >
                <mat-option value="null" selected (click)="business()"
                  >-Select Business Unit-</mat-option
                >
      
                <mat-option value="Service Based">Service Based</mat-option>
              </mat-select>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.businessUnits?.invalid &&
                      validate.businessUnits?.touched) ||
                    validate.businessUnits?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.businessUnits.errors?.required"
                    class="text-danger"
                    >Please select Business Unit</span
                  >
                  <span
                    *ngIf="validate.businessUnits.errors?.pattern"
                    class="text-danger"
                    >Please select valid Business Unit</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Client Code <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="clientCode"
                  placeholder="Client Code"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                  placeholder="Client Code "
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.clientCode?.invalid &&
                      validate.clientCode?.touched) ||
                    validate.clientCode?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.clientCode.errors?.required"
                    class="text-danger"
                    >Please enter Client Code</span
                  >
                  <span
                    *ngIf="validate.clientCode.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Client Code</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Client Name <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="clientName"
                  placeholder="Client Name"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.clientName?.invalid &&
                      validate.clientName?.touched) ||
                    validate.clientName?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.clientName.errors?.required"
                    class="text-danger"
                    >Please enter Client Name</span
                  >
                  <span
                    *ngIf="validate.clientName.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Client Name</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Supplier Name <span class="star">*</span></label
                >
               
                <mat-select
                formControlName="supplierName"
                placeholder="Select Supplier Name"
                class="form-control email-input select-placeholder"
              >
                <mat-option value="null" selected (click)="setSupplier()"
                  >-Select Supplier Name-</mat-option
                >
                
                <mat-option value="Service Based">Service Based</mat-option>
              </mat-select>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.supplierName?.invalid &&
                      validate.supplierName?.touched) ||
                    validate.supplierName?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.supplierName.errors?.required"
                    class="text-danger"
                    >Please select Supplier Name</span
                  >
                  <span
                    *ngIf="validate.supplierName.errors?.pattern"
                    class="text-danger"
                    >Please select valid Supplier Name</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Country <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="countryAutoCompleteSearch($event)"
                  formControlName="countryName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="Country Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="countryId"
                  id="countryId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.countryId?.invalid &&
                      validate.countryId?.touched &&
                      autoCompleteCountrySearch == 1) ||
                    (validate.countryName?.invalid &&
                      validate.countryName?.touched) ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.countryId.errors?.required"
                    class="text-danger"
                    >Please enter country name.</span
                  >
                </div>

                <span *ngIf="autoCompleteCountrySearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="countryData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(
                          searchData.countryId,
                          searchData.countryName,
                          1
                        )
                      "
                      *ngFor="let searchData of countryData"
                    >
                      {{ searchData.countryName }}
                    </li>
                  </ul>
                </span>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label">State </label>
                <input
                  type="text"
                  (keyup)="stateAutoCompleteSearch($event)"
                  formControlName="stateName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="State Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="stateId"
                  id="stateId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.stateId?.invalid &&
                      validate.stateId?.touched &&
                      autoCompleteStateSearch == 1) ||
                    (validate.stateName?.invalid &&
                      validate.stateName?.touched) ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.stateId.errors?.required"
                    class="text-danger"
                    >Please enter state name.</span
                  >
                </div>

                <span *ngIf="autoCompleteStateSearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="stateData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(
                          searchData.stateId,
                          searchData.stateName,
                          2
                        )
                      "
                      *ngFor="let searchData of stateData"
                    >
                      {{ searchData.stateName }}
                    </li>
                  </ul>
                </span>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label">District </label>
                <input
                  type="text"
                  (keyup)="districtAutoCompleteSearch($event)"
                  formControlName="districtName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="District Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="districtId"
                  id="districtId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.districtId?.invalid &&
                      validate.districtId?.touched &&
                      autoCompleteDistrictSearch == 1) ||
                    (validate.districtName?.invalid &&
                      validate.districtName?.touched) ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.districtId.errors?.required"
                    class="text-danger"
                    >Please enter district name.</span
                  >
                </div>

                <span *ngIf="autoCompleteDistrictSearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="districtData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(
                          searchData.districtId,
                          searchData.districtName,
                          3
                        )
                      "
                      *ngFor="let searchData of districtData"
                    >
                      {{ searchData.districtName }}
                    </li>
                  </ul>
                </span>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label">City </label>
                <input
                  type="text"
                  (keyup)="cityAutoCompleteSearch($event)"
                  formControlName="cityName"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  placeholder="City Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="cityId"
                  id="cityId"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />

                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.cityId?.invalid &&
                      validate.cityId?.touched &&
                      autoCompleteCitySearch == 1) ||
                    (validate.cityName?.invalid &&
                      validate.cityName?.touched) ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.cityId.errors?.required"
                    class="text-danger"
                    >Please enter city name.</span
                  >
                </div>

                <span *ngIf="autoCompleteCitySearch == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="cityData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(searchData.cityId, searchData.cityName, 4)
                      "
                      *ngFor="let searchData of cityData"
                    >
                      {{ searchData.cityName }}
                    </li>
                  </ul>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label class="form-control-label">Postal Code </label>
                <input
                  type="text"
                  minlength="6"
                  maxlength="6"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="postalCode"
                  placeholder="Postal Code"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.postalCode?.invalid &&
                      validate.postalCode?.touched) ||
                    validate.postalCode?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.postalCode.errors?.required"
                    class="text-danger"
                    >Please enter Postal Code</span
                  >
                  <span
                    *ngIf="validate.postalCode.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Postal Code</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Address Line 1 <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="addressLine1"
                  placeholder="Address Line 1"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.addressLine1?.invalid &&
                      validate.addressLine1?.touched) ||
                    validate.addressLine1?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.addressLine1.errors?.required"
                    class="text-danger"
                    >Please enter Address Line 1</span
                  >
                  <span
                    *ngIf="validate.addressLine1.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Addrss Line 1</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label">Address Line 2</label>
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="addressLine2"
                  placeholder="Address Line 2"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.addressLine2?.invalid &&
                      validate.addressLine2?.touched) ||
                    validate.addressLine2?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.addressLine2.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Addrss Line 2</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label">Description</label>
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="description"
                  placeholder="Description"
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.description?.invalid &&
                      validate.description?.touched) ||
                    validate.description?.dirty ||
                    submitted1
                  "
                >
                  <span
                    *ngIf="validate.description.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Description</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <a
                  routerLink="/home/organization"
                  routerLinkActive="active"
                  mat-raised-button
                  
                  class="mr-2"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  mat-raised-button
                 class="btndesign"
                  (click)="submitClient()"
                >
                  Save
                </button>
              </div>
            </div>
          </div> -->

          <!-- <div class="row">
                        <div class="col-md-12 text-right">
                            <a routerLink="/super-admin-home/organization" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2">
                                Cancel
                            </a>
                            <button type="submit" mat-raised-button color="primary">Save</button>
                        </div>
                    </div> -->
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>

<div class="" *ngIf="success">
  <div class="alert alert-resource alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
