<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Shift</span>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift Name<span class="star"></span></label>
                                <input formControlName="filtershiftname" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Shift Name">
                                <small class="text-muted">Note : Shift Name</small>
                            </div>
                            <!-- <div class="valid-msg" *ngIf="(validate.filtershiftname.invalid && validate.filtershiftname.touched) || validate.filtershiftname.dirty || submitted">
                                <span *ngIf="validate.filtershiftname.errors?.required" class="text-danger">Please enter name</span>
                            </div> -->
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Client Name<span class="star"></span></label>
                                <select formControlName="filterclient" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]="null"> -- Select Client -- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select>
                            </div>
                            <!-- <div class="valid-msg" *ngIf="(validate.filterclient.invalid && validate.filterclient.touched) || validate.filterclient.dirty || submitted">
                                    <span *ngIf="validate.filterclient.errors?.required" class="text-danger">Please select client</span>
                            </div> -->
                            <!-- <div class="form-group col-md-1" style="position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary" 
                                    class="btn btn-primary search-btn">Search</button>
                            </div> -->
                            <div class="col-md-3 col-sm-5 col-" style="float: right;position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button>
                                <button style="margin-left: 20px;" type="reset" mat-raised-button
                                     class="btn mr-2 search-btn" (click)="clearTable()"
                                    >Clear</button>
                            </div>
                            <!-- <div class="form-group col-md-1" *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" style="position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Export</button>
                            </div> -->
                        </div>
                    </form>
                        <form [formGroup]="shiftForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Client Name<span class="star">*</span></label>
                                    <select formControlName="client" class="form-select form-select-solid form-select-lg select2-new" (change)="clientData($event)">
                                        <option [value]="null"> -- Select Client --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                        <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Shift Calendar Name<span class="star">*</span></label>
                                    <!-- <label class="col-form-label">Shift Calendar<span class="star">*</span></label> -->
                                    <input type="text" formControlName="calendar" id="calendar" readonly class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder=" Shift Calendar Name" autocomplete="off">
                                    <!-- <div class="valid-msg" *ngIf="(validate.calendar.invalid && validate.calendar.touched) || validate.calendar.dirty || submitted">
                                        <span *ngIf="validate.calendar.errors?.required" class="text-danger">Please enter calendar name</span>
                                    </div> -->
                                    <span *ngIf="calenName" class="text-danger">shiftcalendar not avaliable for client</span>
                                </div>
                                 <!-- <div class="form-group col-md-3">
                                    <select formControlName="calendar" class="form-select form-select-solid form-select-lg select2-new" >
                                        <option [value]="null"> -- Select Shift Calendar --- </option>
                                        <option *ngFor="let row of shiftCalendar; let i=index;" value="{{row.shiftCalendarId}}">{{row.shiftCalendarName}}</option> 
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.calendar.invalid && validate.calendar.touched) || validate.calendar.dirty || submitted">
                                        <span *ngIf="validate.calendar.errors?.required" class="text-danger">Please select calendar</span>
                                        
                                    </div>
                                 </div> -->
                                 <div class="form-group col-md-3">
                                    <label class="col-form-label">Shift Name<span class="star">*</span></label>
                                    <input formControlName="name" type="search" autocomplete="off" (keyup)="validatecalendarname($event)" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Shift Name">
                                    <!-- <small class="text-muted">Note : Shift Name</small> -->
                                    <div class="valid-msg" *ngIf="(validate.name.invalid && validate.name.touched) || validate.name.dirty || submitted">
                                        <span *ngIf="validate.name.errors?.required" class="text-danger">Please enter name</span>
                                    </div>
                                    <span *ngIf="calendarnameexist" class="text-danger">{{existMsg}}</span>
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label class="col-form-label">Description<span class="star">*</span></label>
                                        <textarea formControlName="description" id="elementDescription" rows="1"
                                            class="form-control search-input form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            placeholder="Enter Description" autocomplete="off"></textarea>
                                 
                                        <div class="valid-msg" *ngIf="(validate.description.invalid && validate.description.touched) || validate.description.dirty || submitted">
                                            <span *ngIf="validate.description.errors?.required" class="text-danger">Please enter description</span>
                                        </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Start Time<span class="star">*</span></label>
                                    <input formControlName="starttime" id="starttime" type="text"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                    placeholder=" Enter Start Time " (keyup)="timeStartDate($event)">
                                    <small class="text-muted" style="font-weight: bold;">Note :hh:mm meridian 12 hour format</small>
                                <!-- <mat-datepicker-toggle matSuffix [for]="startdate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                                <mat-datepicker #startdate   startView="multi-year"
                                (dateChange)="onDateChange($event)"
                                panelClass="example-month-picker"></mat-datepicker> -->
                                    <div class="valid-msg" *ngIf="(validate.starttime.invalid && validate.starttime.touched) || validate.starttime.dirty || submitted">
                                        <span class="text-danger" *ngIf="validate.starttime.errors?.required">Please select start time</span>
                                    </div>
                                    <div class="valid-msg" *ngIf="vaildStartTime">
                                        <span class="text-danger">vaild start time</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">End Time<span class="star">*</span></label>
                                    <input formControlName="endtime" id="endtime" type="text"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                    placeholder=" Enter End Time " (keyup)="timeEndDate($event)">
                                    <small class="text-muted"  style="font-weight: bold;">Note :hh:mm meridian 12 hour format</small>
                                <!-- <mat-datepicker-toggle matSuffix [for]="startdate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                                <mat-datepicker #startdate   startView="multi-year"
                                (dateChange)="onDateChange($event)"
                                panelClass="example-month-picker"></mat-datepicker> -->
                                    <div class="valid-msg" *ngIf="(validate.endtime.invalid && validate.endtime.touched) || validate.endtime.dirty || submitted">
                                        <span class="text-danger" *ngIf="validate.endtime.errors?.required">Please select end time</span>
                                    </div>
                                    <div class="valid-msg" *ngIf="vaildEndTime">
                                        <span class="text-danger">vaild end time</span>
                                    </div>
                                    <div class="valid-msg" *ngIf="lessTime">
                                        <span class="text-danger">Time equal or greater start time</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="form-group col-md-3">
                                        <input formControlName="startdate" id="startdate" matInput [matDatepicker]="startdate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                        placeholder="- Select Start Date -">
                                    <mat-datepicker-toggle matSuffix [for]="startdate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                                    <mat-datepicker #startdate   startView="multi-year"
                                    (dateChange)="onDateChange($event)"
                                    panelClass="example-month-picker"></mat-datepicker>
                                    <div class="valid-msg" *ngIf="(validate.startdate.invalid && validate.startdate.touched) || validate.startdate.dirty || submitted">
                                        <span class="text-danger" *ngIf="validate.startdate.errors?.required">Please select start date</span>
                                    </div>
                                 </div>
                                 <div class="form-group col-md-3">
                                        <input formControlName="enddate" id="enddate"  matInput [matDatepicker]="enddate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input"
                                        [min]="shiftForm.value.startdate" placeholder="- Select End Date -">
                                        <mat-datepicker-toggle matSuffix [for]="enddate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                                        <mat-datepicker #enddate></mat-datepicker>
                                        <div class="valid-msg" *ngIf="(validate.enddate.invalid && validate.enddate.touched) || validate.enddate.dirty || submitted">
                                            <span class="text-danger" *ngIf="validate.enddate.errors?.required">Please select end date</span>
                                        </div>
                                 </div>
                            </div> -->
                            <div class="row pb-3">
                                <div class="col-md-12 text-right">
                                        <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                            style="margin-right: 20px;">Clear</button>
                                        <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                            [disabled]="shiftForm.invalid">Save</button>
                                </div>
                            </div>
                        </form>
                   
                            <div class="row pb-3" *ngIf="resultData?.length > 10">
                                <div class="col-md-11 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-1 text-left pl-0">
                                    <select [(ngModel)]="pagePerItem" (click)="pageChages()" class="form-control report-filter">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                       
                       <div class="table-responsive" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                        <p><b>SHIFT</b></p>
                        <table class="table  --table-bordered table-border-new" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                            <thead class="t-head">
                                <tr>
                                    <th>Action</th>
                                    <th>Shift Code</th>
                                    <th>Shift Name</th>
                                    <th>Shift Description</th>
                                    <th>Client</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                             <tbody>
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item (click)="updateform(row)"  >
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                            <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==1" (click)="updateStatus(row.shiftId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==2" (click)="updateStatus(row.shiftId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                    <td>{{row.shiftCode}}</td>
                                    <td>{{row.shiftName}}</td>
                                    <td>{{row.shiftDescription}}</td>
                                    <td>{{row.client.clientName}}</td>
                                    <td>{{row.startTime | timeconverter}}</td>
                                    <td>{{row.endTime | timeconverter}}</td>
                                    <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId==1">
                                            {{row.status.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId==2">
                                            {{row.status.listTypeValueName}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody> 
                            <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="5" *ngIf="noRecordsFound">No  Records Found!</td>
                                </tr>
                            </tbody>
                        </table>
                     </div>
                    <div *ngIf="resultData?.length > 0 ">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>