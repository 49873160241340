<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">{{approverId ? 'Update Manage' : 'Add Manage'}} Approvers</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/manage-approver-list" mat-raised-button  class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="background-color">
    <div *ngIf="loader" class="align-items-center text-center">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
    </div>
    <div class="text-center" *ngIf="success"> 
        <div class="alert alert-success" role="alert">
            <strong>Success!</strong><br>{{create_sucess_msg}}
        </div>
    </div>
    <div *ngIf="error" class="alert alert-danger alert-dismissible text-center">
        <strong>Error!</strong><br>{{update_error_msg}}
    </div>
    <!-- <div class="row">
        <div class="col-md-6 resource-title">
            <h2>EMPLOYEE APPROVER</h2>
        </div>
    </div> -->
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body form-scroll">
                    <form [formGroup]="approverform">
                        <div class="row">
                            <div class="col-md-6 loginpadding">
                                <!-- <div class="row">
                                    <div class="col-md-12 sub-title">
                                        <h2>{{approverId ? 'UPDATE' : 'ADD'}} APPROVER</h2>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-md-12" id="loginform-name">
                                        <label class="col-control-label">Rule Name<span class="text-danger"> *</span></label>
                                        <input type="text"  (input)="validateInput($event)" class="form-control form-control-solid form-control-lg" formControlName="rulename" placeholder="Enter rule name">
                                        <div *ngIf="(f.rulename.invalid && submitted) || f.rulename.dirty">
                                            <mat-error *ngIf="f.rulename.errors?.required">Please enter rule name</mat-error>
                                            <mat-error *ngIf="f.rulename.errors?.pattern ">
                                                Please enter a valid title
                                            </mat-error> 
                                        </div> 
                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" id="loginform-date">
                                        <label class="col-control-label">Start Date<span class="text-danger"> *</span></label>
                                        <input matInput [matDatepicker]="picker" 
                                            formControlName="startdate" readonly 
                                            class="form-control form-control-solid form-control-lg" 
                                            placeholder="Select start date" style="padding-left: 14px;"
                                            (dateChange)="someMethodName1($event)">
                                        <mat-datepicker-toggle matSuffix [for]="picker" style="float: right;position: relative;bottom: 40px;"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <div *ngIf="(f.startdate.invalid && submitted) || f.startdate.dirty">
                                            <mat-error *ngIf="f.startdate.errors?.required">Select start date</mat-error>
                                        </div> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" id="loginform-date">
                                        <label class="col-control-label">End Date<span class="text-danger"> *</span></label>
                                        <input matInput [matDatepicker]="picker1" [min]="min"
                                            formControlName="enddate" readonly style="padding-left: 14px;"
                                            class="form-control form-control-solid form-control-lg" 
                                            placeholder="Select end date"
                                            (dateChange)="someMethodName2($event)">
                                        <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 40px;"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        <div *ngIf="(f.enddate.invalid && submitted) || f.enddate.dirty">
                                            <mat-error *ngIf="f.enddate.errors?.required">Select end date</mat-error>
                                        </div> 
                                        <mat-error *ngIf="show_error_msg">
                                            Start date should not be greater than End date.
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" id="loginform">
                                        <label class="col-control-label">FYI</label>
                                        <mat-select formControlName="fyi"
                                            (selectionChange)="onSelectionChange($event)"
                                            class=" form-select-solid form-select-lg"
                                            placeholder="Select FYI">
                                            <mat-option>- Select FYI -</mat-option>
                                            <mat-option *ngFor="let emp of employeeList" [value]="emp.employeeId">
                                            {{emp.organizationEmail}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="submitted && f.fyi.hasError('required')">FYI is required</mat-error><br>
                                    </div>
                                    <mat-error style="margin-bottom: 10px;" *ngIf="hasduplicate || hasduplicatefyi">Approvers Cannot Be Repeated</mat-error>
                                </div>
                            </div>
                            <div class="col-md-6 loginpadding">
                                <button type="button" class="m-1 btn btn-light add-button" (click)="add()">
                                    <mat-icon class="material-icons-outlined add-icon">add</mat-icon>Approver
                                </button>
                                <div formArrayName="approver" class="row">
                                    <div *ngFor="let approverGroup of approverform.get('approver')['controls'];let i =index"
                                    [formGroup]="approverGroup" class="col-md-12">
                                        <div id="loginform">
                                        <label class="col-control-label">Approver</label>
                                        <mat-icon matPrefix class="delete-icon material-icons-outlined" style="color: red;" *ngIf="i>0" (click)="delete(i)">delete</mat-icon>
                                        <mat-select formControlName="approver1"  
                                            class=" form-select-solid form-select-lg"
                                            placeholder="Select approver"
                                            (selectionChange)="onSelectionChange($event)">
                                            <mat-option>--- Select Approver ---</mat-option>
                                            <mat-option *ngFor="let emp of employeeList" [value]="emp.employeeId">
                                            {{emp.organizationEmail}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="submitted && approverGroup['controls'].approver1?.errors?.required">
                                            Approver is required
                                        </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 loginpadding">
                                <button mat-raised-button
                                class="searchBtn ml-2 mat-raised-button mat-button-base" 
                                type="submit" 
                                (click)="submit()"
                                [disabled]="btnDisable">{{approverId ? 'Update' : 'Save'}}</button>
                                <button 
                                mat-raised-button
                                style="color:var(--Red-Color, #FF5D5D);height: 34px;font-size: 15px;margin-right: 15px;"
                                class="mat-focus-indicator ml-2 mat-raised-button mat-button-base btn" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 100vh!important;"></div>
<div *ngIf="form_loader" class="align-items-center text-center">
    <div class="spinner-border" 
    style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;top: 45%;" role="status"></div>
</div> 

