<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <div class="">
                        <span class="h5 sub-content pr-3" style=" position: relative;
                        top: 6px;
                        color: #181c32;
                        font-weight: 800;
                        font-size: 22px;
                        font-family: 'Mulish', sans-serif;
                        right: 15px;">{{employeeKraId? "Edit" : "Add"}}  Assign KRA</span>
                    </div>
                    <!-- <span routerLink="/home/kra-template" class="h5 sub-content">Add Branch</span> -->
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body" style="font-family: Mulish;">
                <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <!-- <div class="form-group col-md-3">
                            <label class="col-form-label">Employee  <span class="star">*</span></label>
                            <input type="text" (keyup)="getEmployeeList($event)" formControlName="employeeName" id="employeeName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Employee" autocomplete="off">
                            <input type="hidden" formControlName="employeeId" id="employeeId" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.employeeId.invalid && validate.employeeId.touched && autoCompleteSearch == 1) || (validate.employeeName.invalid && validate.employeeName.touched) || submitted">
                                <span *ngIf="validate.employeeId.errors?.required" class="text-danger">Please select employee.</span>
                            <span *ngIf="autoCompleteSearch == 0 ">
                                <ul class="auto-complete" *ngIf="employeeData?.length > 0">
                                     <li (click)="getFilterData(searchData.employeeId,searchData.firstName,searchData.lastName,searchData.employeeNumber);" *ngFor="let searchData of employeeData">{{searchData.firstName+" "+searchData.lastName}} - {{searchData.employeeNumber}}</li> 
                                </ul>
                            </span>
                        </div> -->
                        <div class="form-group col-md-3" style="margin-top:2px;">
                            <div class="input-width" id="loginform">
                                <label class="col-form-label " style="font-weight: 600;  ">Employee<span class="star"> *</span></label>
                                <input 
                                type="text" 
                matInput
                (input)="validateInput($event)"
                (keydown)="validateInput($event)"
                (keyup)="validateInput($event)"
                placeholder="Search Employee"
                formControlName="employeeName"
                [matAutocomplete]="auto"
                class="form-control email-input select-placeholder"
                (keyup)="empSearch($event,$event)"
                (keydown)="handleBackspace($event)"
                (keydown.space)="preventSpaceBeforeInput($event)"
               >
                            <mat-autocomplete #auto="matAutocomplete">
                                        <!-- <mat-option value="All" (click)="empAllSelect()">All</mat-option> -->
                                        <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" 
                                        (click)="empSelect(emp)">
                                            {{emp.employee.employeeNumber}} - {{emp.employee.firstName}}  
                                        </mat-option>
                                        <mat-option
                                            [value]="null"
                                            *ngIf="employeeNotFound"
                                            (click)="noEmployeeFound()"
                                            >No Employee Found</mat-option>
                                        
                                    </mat-autocomplete>

                                <!-- <mat-error class="mt-1" *ngIf="validate.employeeName.invalid && submitted">Employee is required</mat-error><br> -->
                                <!-- <mat-error class="mt-1" *ngIf="validate.employeeName.invalid && (validate.employeeName.dirty || validate.employeeName.touched)">Employee is required</mat-error><br> -->
                                <div class="valid-msg" *ngIf="(validate.employeeName.invalid && validate.employeeName.touched) || validate.employeeName.dirty || submitted">
                                    <span *ngIf="validate.employeeName.errors?.required" class="text-danger">Please search Employee</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label selectLabel" style="font-weight: 600;  color:black;">Select KRA Template <span class="star">*</span></label>
                            <mat-select formControlName="kraTemplateHeaderId" id="kraTemplateHeaderId"  placeholder="Select KRA Template" class="form-control email-input select-placeholder">
                                <!-- <mat-option value=""  disabled selected>Select KRA Template</mat-option> -->
                                <mat-option *ngFor="let row of activekraData; let i=index;" [value]="row.kraTemplateHeaderId">{{row.templateCode}} - {{row.templateName}} </mat-option>
                            </mat-select>
                            <div class="valid-msg" *ngIf="(validate.kraTemplateHeaderId.invalid && validate.kraTemplateHeaderId.touched) || validate.kraTemplateHeaderId.dirty || submitted">
                                <span *ngIf="validate.kraTemplateHeaderId.errors?.required" class="text-danger">Please select KRA Template</span>
                                <!--  <span *ngIf="validate.kraTemplateHeaderId.errors?.pattern" class="text-danger">Please enter valid template name</span> -->
                            </div>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="col-form-label" style="font-weight: 600;  color:black;">Period From <span class="star">*</span></label>
                            <input type="text" formControlName="periodFrom"  [value]="today" id="periodFrom" class="form-control email-input select-placeholder   mb-3 mb-lg-0 email-input" matInput [matDatepicker]="periodFrom" readonly="readonly" placeholder="Select Period From" style="background: none;" 
                            (dateChange)="checkStartDate();">
                            
                            <mat-datepicker-toggle matSuffix [for]="periodFrom" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #periodFrom></mat-datepicker>
                            <div class="valid-msg" *ngIf="(validate.periodFrom.invalid && validate.periodFrom.touched) || validate.periodFrom.dirty || submitted">
                                <span *ngIf="validate.periodFrom.errors?.required" class="text-danger">Please select Period From</span>
                            </div>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="col-form-label" style="font-weight: 600; color:black;">Period To  <span class="star">*</span></label>
                            <input type="text" placeholder="Period To" formControlName="periodTo" [value]="today" autocomplete="off" class="form-control select-placeholder email-input   mb-3 mb-lg-0" matInput [matDatepicker]="periodTo" [min]="minPeriodToDate" readonly="readonly" placeholder="Select Period To" style="background: none;" (dateChange)="checkStartDate();">
                            <mat-datepicker-toggle matSuffix [for]="periodTo" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #periodTo></mat-datepicker>
                            <div class="valid-msg" *ngIf="(validate.periodTo.invalid && validate.periodTo.touched) || validate.periodTo.dirty || submitted">
                                <span *ngIf="validate.periodTo.errors?.required" class="text-danger">Please select Period To</span>
                            </div>
                        </div>

                        
                            
                             
                          

                        
                          
                
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-left;" style="margin-top: -30px;">
                            <a routerLink="/home/assign-kra-template" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2" style="font-family: Mulish; color:var(--Red-Color, #FF5D5D);">
                                Cancel
                            </a>
                            <!-- <button type="submit" mat-raised-button color="primary" title="Save" style="font-family: Mulish;" [disabled]="submitButtonDisable">Save</button> -->

                            <button type="submit" mat-raised-button title="Save" style="font-family: Mulish;"
  [disabled]="submitButtonDisable"
  [color]="submitButtonDisable ? 'warn' : 'primary'"
  [ngClass]="{'disabled-button': submitButtonDisable}">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>