import { Component, ElementRef, EventEmitter, HostListener, Inject, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { number } from 'mathjs';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import Swal from 'sweetalert2';
import { ShowErrorComponent } from '../show-error/show-error.component';
import { HeaderService } from 'src/app/providers/header.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';

@Component({
  selector: 'app-projectstage',
  templateUrl: './projectstage.component.html',
  styleUrls: ['./projectstage.component.scss'],
})
export class ProjectstageComponent implements OnInit {
  tableShow:boolean =  false;
  pagePerItem: any;
  search: any;
  p: number=1;
  projectstageId:number
  beforesearchpage:any;
    submitted: Boolean=false;
  usertData: any;
  orgId: any;
  showError:boolean=false
// projectstage: FormGroup;
projectStage: FormGroup;
  creationData: any;
  // projectstageId: any;
  payloads: any={};
  liststyleId: any;
mySelect: any=5;
prjtstage: any=[];
  taskProjectstagelist: any;
  prjstStageList: any=[];
  nodata: boolean=false;
  loader: boolean=true;
  datas:any[]=[];
  errormessagejson: any;
  originalData: any[]=[]; 
projectstageNameexist: boolean;
  sucess_msg: any;
  success: boolean=false;
error:any=false;
  error_msg:any;
error_Msg: any;
importerror: boolean = false;
isbuttondisabled: boolean=false;

  constructor(
    private gs: ProjectManagementService,
    private dialog: MatDialog,
    private fb:FormBuilder,
    private zone: NgZone,
    private elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
  ) {

    // this.gs.GetProjectStage().subscribe((response)=>{
    //   console.log('Project Stage Data : ', response);
    // })
   
  }

  ngOnInit() {

    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage(
      ''
    );

    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    // this.headerservice.setTitle('Project Stage');
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.orgId = this.usertData.organization.organizationId;
    console.log("Organization Id::", this.orgId);
    
   this.originalData = this.data.slice();
    
    
    // this.projectStage = this.fb.group({
    //   projectstage : ['',Validators.required],
    // })
    // this.projectStage = new FormGroup(
    //   {
    //     projectstage : new FormControl(this.projectStage,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
    //   });
    this.projectStage = this.fb.group({
      // taskgroupname: ['', [Validators.required, Validators.pattern(/^\S.*$/)]],
      projectstage : new FormControl(this.projectStage,[Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")
    ]),

    });

      this.projectStage.get('projectstage').valueChanges.subscribe(() => {
        this.clearErrorMessage();
      });
    
  
    //
    this.storeOriginalData(); 
    this.projectstageData()
   this.scrollToEditForm();

  }
  clearErrorMessage() {
    this.projectstageNameexist = false;
  }
  storeOriginalData() {
    this.originalData = JSON.parse(JSON.stringify(this.data)); // Deep copy the original data
  }
  changefirstpage() {
    this.p = 1;
  }
  projectstageData(){
      this.gs.GetProjectStage().subscribe(
      (d:any)=>{
        this.loader = false;

        this.data = d;
        this.datas = d;
        this.tableShow =  true;
        this.taskProjectstagelist=d;
        this.prjstStageList=d;
      console.log('dcheck',d);

        console.log("project stage response::",this.data);

        if (this.search && this.search.length === 0) {
          this.datas = d;
        }

        if (d.statusCode === 204) {
          this.nodata = true;
        }
  
        // Check if this.taskProjectstagelist is defined and has a length property
        if (this.taskProjectstagelist && this.taskProjectstagelist.length == 0) {
          this.nodata = true;
          console.log("nodata")
        }
    },
    (error) => {
      this.nodata = true;
      this.loader = false;
      console.log("error")
      if(error.status == 404){
        this.nodata = true;
        console.log("404")
      }
    }
  );
      }
      get projectstage()
{
  return this.projectStage.get('projectstage');  
}
clearForm(){
this.submitted=false
// this.search=''

}
  update(projectGroup:any,index:number) {
    // if (this.projectStage.valid) {
      console.log("valid data::",this.projectStage.valid);
      console.log("row::",projectGroup);
      console.log("response value::",this.projectStage.value);

      this.projectStage.patchValue({
        projectstage:projectGroup.projectstagename
      })
      
      this.projectstageId=projectGroup.projectstageId
      this.liststyleId=projectGroup.status.listTypeValueId
      console.log("project id from row selected::",this.projectstageId);
      console.log("liststyle id from row selected::",this.liststyleId);
      
      
      if(this.projectStage.valid){
        const updateDataValue=this.projectStage.value
        console.log("Updating Data Value::",updateDataValue);
      // }
    }
    this.scrollToEditForm()
  }
  handlePageChange(event: number): void {
    this.p = event;
  }
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if the click event is not on the search input field
  //   if (!(event.target as HTMLElement).classList.contains('search-menu')) {
  //     // Clear the search input field
  //     this.search = '';
  //     // Call the changepage() function to update the UI accordingly
  //     // this.filterByValue();
  //   }
  // }
  ngAfterViewInit() {
    // Scroll to the edit form after the view is initialized
    this.scrollToEditForm();
  }

  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
  }
  showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowErrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}
  onSubmit(event: Event) {
    this.search=''
    this.submitted = true;
    this.showError=true
    event.preventDefault()
    if(this.projectStage.valid ){
      // alert("form is submitted")
      this.projectStage.markAllAsTouched();

      console.log("form is submitted");
      this.showError=false
      this.submitted=false
      this.isbuttondisabled=true

      this.creationData=this.projectStage.value
      console.log("Creation Data:",this.creationData);

      if( this.projectstageId){
        console.log("if ");
        console.log("update!....");
        
        console.log("project stage Id::  if", this.projectstageId);
      this.payloads={
      projectstageId:this.projectstageId,
      projectstagename:this.creationData.projectstage,
      status: {
        listTypeValueId: this.liststyleId
      },
      organizationId:this.orgId
    };
    
    // return
        this.gs.updateProjectStage( this.payloads,this.projectstageId).subscribe((response:any)=>{
          console.log("payload resp::",response);
          // alert("form is updated")
          this.submitted = false;
this.showError=false
          if(response.statusCode==200){
            this.projectstageId=0
            // this.submitted = false;
            this.success = true;
            this.sucess_msg=response.description
            this.isbuttondisabled=true

            
            setTimeout(()=>{
              this.success=false
              this.isbuttondisabled=false

              this.projectStage.reset()

            },2000)
          }
          else{
            if(response.statusCode==409){
            this.error = true;

            this.error_Msg= response.description;
            setTimeout(() => {
              this.error = false;

              // this.taskGroup.reset();

            }, 2000);
          }
        }
          // if(response.description=='ProjectStageName is already exists'){
          //   this.projectstageId=0
          //   // this.submitted = false;
          //   this.success = true;
          //   this.sucess_msg=response.description
          // }
          // else{
          //   console.log("came out to the else!!");
            
          // }
          this.projectstageData()
        },
        err=>{
          console.log(err);
          
          console.log("error came here!!");
          
          this.error=true
          this.error_Msg=err.error.description
          setTimeout(()=>{
            this.error=false

          },2000)
        });
      }

      else{
        console.log("else");
        const payload = {
          // projectstageId:1,
          // projectstageId :this.creationData.projectstageId,
          projectstagename:this.creationData.projectstage,  //here,syntax: api payload name:formvalues.formcontrolname//
          organizationId:this.orgId
        };
        console.log("payload creation data::",payload);
        this.gs.AddProjectStage(payload).subscribe((data:any)=>{
          const datasValuesAdd=data;
          console.log("datas Values Adding in api::",datasValuesAdd);
          this.isbuttondisabled=true

          if(data.statusCode==200){
            this.submitted=false
            this.showError=false
            this.success=true
            this.isbuttondisabled=true

            this.sucess_msg=data.description
            setTimeout(()=>{
              this.success=false
              this.isbuttondisabled=false

              this.projectStage.reset()

            },2000)
          }
          if(datasValuesAdd.message=='ProjectStageName is already exists'){
            this.success = true;
            this.sucess_msg = datasValuesAdd.description;
            // this.isbuttondisabled=true
            this.isbuttondisabled=true


            setTimeout(() => {
              this.success = false;
              this.isbuttondisabled=false

              // this.isbuttondisabled=false

            }, 3000);
          }
          this.projectstageData()
        },
        err=>{
          this.error=true
          this.error_Msg=err.error.message
          setTimeout(()=>{
            this.error=false
          },2000)
        }
        )
      }
    }
    this.isbuttondisabled=false

  }

  
  // filterByValue() {
  //   console.log(this.search);
  //     if(this.search.length != 0)
  //     {
  //       this.p = 1;
  //     console.log("after if " + this.datas.length);
  //       this.datas = this.datas.filter(o =>
  //         Object.keys(o).some(k => String(o[k]).toLowerCase().includes(this.search.toLowerCase())));
        
  //     }else{
  //               this.p = this.beforesearchpage;
  //             this.datas = this.datas;
  //             this.resetData();
  //     }
  // }

  onPageChange(event:number){
    this.p = event;
    

  }
  filterByValue() {
    // if (this.search && this.search.length !== 0) {
    //   this.p = 1;
    //   this.datas = this.datas.filter((o) =>
    //     Object.keys(o).some(
    //       (k) =>
    //         String(o[k])
    //           .toLowerCase()
    //           .includes(this.search.toLowerCase())
    //     )
    //   );
    // } else {
    //   this.p = this.beforesearchpage;
    //   this.datas = [...this.data]; 
    // }
    // console.log(this.prjstStageList.length);
    // if(this.search.length!= 0)
    // {
    //    this.p = 1;
    //    console.log(this.prjstStageList.length);
    //   // this.taskEmpList.length=0;
    //   // console.log(this.taskEmpList.length);
    //   // if(this.taskEmpList.length == 0)
    //   //     {
    //   //       this.nodata = true;
    //   //       console.log("nodata")taskgroupList
    //   //     }
    //   // else
    //   // {
    //   //   this.p = 1;
    //     console.log(this.p);
    //     console.log("success Data");
    // }
    // else{
    //    this.p = this.beforesearchpage;
    //   // this.nodata = true;
    //   console.log(this.prjstStageList.length);
    //   console.log("nodata");
    // }
    // console.log(this.prjstStageList.length);
    // if (this.search.length != 0) {
    //   this.p = 1;
    //   console.log(this.prjstStageList.length);
    //   console.log(this.p);
    //   console.log("success Data");
    // } else {
    //   this.p = this.beforesearchpage;
    //   console.log(this.prjstStageList.length);
    //   console.log("nodata");
    // }
    console.log(this.prjstStageList.length);
    if (this.search.length != 0) {
      this.p = 1;
      console.log(this.prjstStageList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")taskgroupList
      //     }
      // else
      // {
      //   this.p = 1;
      console.log(this.p);
      console.log('success Data');
    } else {
      this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.prjstStageList.length);
      console.log('nodata');
    }
  }
    sortData(sort:Sort){
      const datas=this.datas.slice()
      if(!sort.active||sort.direction===''){
        this.prjstStageList=datas
        return
      }
      this.datas=datas.sort((a,b)=>{
        const isAsc=sort.direction==='asc';
        switch(sort.active){
          case 'projectstagename':
            return this.compare(a.projectstagename,b.projectstagename,isAsc);
            default:
              return 0;
        }
      })
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  

    }
    
    






