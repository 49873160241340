Sub header start here
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Assign Employee Shift</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/emp-shift-list" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">
            
                   
                <div class="card-body">
                    <form [formGroup]="postForm" (ngSubmit)="onSubmit()">                   
                        <div class="row">
                            <div class="form-group col-md-3" *ngIf="!shiftemployeeId">
                                <label class="col-form-label">Shift Schedule Type <span class="star">*</span></label>
                                <select  placeholder="- Select client -" (change)="showempldata($event)"  class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select Shift Schedule Type --- </option>
                                    <option [value]="1" >Schedule By Employee Group</option>
                                    <option [value]="2" >Schedule By Employee</option>
                                </select>
                                <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                    <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Client<span class="star">*</span></label>
                                <select formControlName="client" placeholder="- Select client -" (change)="getshiftcalendar()"  class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select client --- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option>
                                </select>
                                <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                    <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift Calendar<span class="star">*</span></label>
                                <input type="text" formControlName="shiftcalendar" id="shiftcalendar" readonly class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder=" Shift Calendar Name" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.shiftcalendar.invalid && validate.shiftcalendar.touched) || validate.shiftcalendar.dirty || submitted">
                                    <span *ngIf="validate.shiftcalendar.errors?.required" class="text-danger">Please enter calendar name</span>
                                   
                                </div>
                                <!-- <span *ngIf="calendarnameexist" class="text-danger">{{existMsg}}</span> -->
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift<span class="star">*</span></label>
                                <select formControlName="shift" placeholder="- Select Shift -" class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select Shift --- </option>
                                    <option *ngFor="let row of shiftList; let i=index;" value="{{row.shiftId}}">{{row.shiftName}}</option>
                                </select>
                                <div class="valid-msg" *ngIf="(validate.shift.invalid && validate.shift.touched) || validate.shift.dirty || submitted">
                                    <span *ngIf="validate.shift.errors?.required" class="text-danger">Please select shift</span>
                                </div>
                            </div>
                        
                                      
                        </div> 
                        <div class="row">     
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Rooster Type<span class="star">*</span></label>
                                <select formControlName="rosterType" placeholder="- Select roster -" class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select roster --- </option>
                                    <option *ngFor="let row of rostertype; let i=index;" value="{{row.listTypeValueId}}">{{row.listTypeValueName}}</option>
                                </select>
                                <div class="valid-msg" *ngIf="(validate.rosterType.invalid && validate.rosterType.touched) || validate.rosterType.dirty || submitted">
                                    <span *ngIf="validate.rosterType.errors?.required" class="text-danger">Please select roster type</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3"  *ngIf="!showemployeegroupserach">
                                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                    search
                                </mat-icon> -->
                                <label class="col-form-label">Employee<span class="star">*</span></label>
                                <input type="text"matInput  placeholder="Search Employee"  [readonly]="employeedisabled"
                                           formControlName="employee"
                                           [matAutocomplete]="auto"
                                           class="form-control" (keyup)="empSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                          </mat-option>
                                      <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employee.employeeNumber}}- {{emp.employee.firstName}} 
                                      </mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                        search
                                    </mat-icon>
                                <div class="valid-msg" *ngIf="submitted">
                                    <span *ngIf="selectedEmployeeData.length == 0 &&  !shiftemployeeId" class="text-danger">Please select employee</span>
                                    <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                                </div>
                            </div>
                            <div class="form-group col-md-3" *ngIf="showemployeegroupserach">
                                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                    search
                                </mat-icon> -->
                                <label class="col-form-label">Employee Group<span class="star">*</span></label>
                                <input type="text"matInput  placeholder="Search Employee"  [readonly]="employeedisabled"
                                           formControlName="employeegroup"
                                           [matAutocomplete]="auto"
                                           class="form-control" (keyup)="empgroupSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllGroupSelect()">All
                                          </mat-option>
                                      <mat-option *ngFor="let empgroup of empgroupList" [value]="empgroup.employeeGroupId" (click)="empGroupSelect(empgroup)">
                                        {{empgroup.employeeGroupCode}}- {{empgroup.employeeGroupName}}
                                      </mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                        search
                                    </mat-icon>
                                <div class="valid-msg" *ngIf="submitted">
                                    <span *ngIf="selectedEmployeeData.length == 0 &&  !shiftemployeeId" class="text-danger">Please select employee</span>
                                    <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift  StartDate <span class="star">*</span></label>
                                <input formControlName="startDate" id="startDate" readonly  matInput [matDatepicker]="startDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                    [max]="" placeholder="Select StartDate"  >
                                <mat-datepicker-toggle matSuffix [for]="startDate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                <mat-datepicker #startDate   
                                panelClass="example-month-picker"></mat-datepicker>

                                <div class="valid-msg" *ngIf="(validate.startDate.invalid && validate.startDate.touched) || validate.startDate.dirty || submitted">
                                    <span class="text-danger" *ngIf="validate.startDate.errors?.required">Please select start date</span>
                                </div>
                            </div>           

                            <div class="form-group col-md-3">
                                <label class="col-form-label">Shift  EndDate<span class="star">*</span></label>
                                <input formControlName="endDate" id="endDate"  readonly  matInput [matDatepicker]="endDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input"
                                     [min]="postForm.value.startDate" placeholder="Select End Date" >
                                <mat-datepicker-toggle matSuffix [for]="endDate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>

                                <div class="valid-msg" *ngIf="(validate.endDate.invalid && validate.endDate.touched) || validate.endDate.dirty || submitted">
                                    <span class="text-danger" *ngIf="validate.endDate.errors?.required">Please select end date</span>
                                </div>
                                <!-- <span *ngIf="dateexist" class="text-danger">{{dateexistMsg}}</span> -->
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-4" *ngIf="selectedEmployeeData?.length > 0">
                                <div class="row">
                                    <div class="col-md-3 text-right">
                                        <p class="show-count">Show</p>
                                    </div>
                                    <div class="col-md-3 text-left pl-0">
                                        <select [(ngModel)]="pagePerItem" (change)="pageChages()"  class="form-control report-filter">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <!-- <option value="1500">1500</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="table-responsive" *ngIf="selectedEmployeeData.length != 0">
                            <p><b>EMPLOYEE</b></p>
                            <table class="table  --table-bordered table-border-new" style="width: 100%;">
                                <thead class="t-head">
                                    <tr>
                                        <!-- <th class="text-center">S.No</th> -->
                                        <th class="text-center">Action</th>
                                       
                                        <th>Emp Code</th>
                                        <th>Emp Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Desgination</th>
                                        <th>DOJ</th>
                                        <th>Gender</th>
                                      
                                        <th class="text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of selectedEmployeeData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                        <!-- <td class="text-center">{{i+1}}</td> -->
                                        <td class="text-center">
                                            <button mat-menu-item  type="button" (click)="deleteemployee(i)"  >
                                                <mat-icon   >delete</mat-icon>
                                            </button>
                                               
                                              
                                         
                                        </td>
            
                              
                                        <td>{{row.employeeNumber}}</td>                                      
                                        <td>{{row.firstName}}  </td>
                                        <td>{{row.mobileNumber}}</td>
                                        <td>{{row.organizationEmail}}</td>
                                        <td *ngIf="row.designation !=undefined" >{{row.designation.designationName}}</td>
                                        <td *ngIf="row.designation == undefined" >--</td>
                                        <td>{{row.dateOfJoining | date : dateFormat}}</td>
                                        <td>{{row.gender.listTypeValueName}}</td>
                                                
                                        <!-- capitalizeWordsName -->
                                        <td class="active-status text-center">
                                            <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                                {{row.status.listTypeValueName}}
                                            </span>
            
                                            <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                                {{row.status.listTypeValueName}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No matching records found!</td>
                                    </tr>
                                </tbody>
                            </table>
                         </div>
                         <div *ngIf="selectedEmployeeData?.length > 0 ">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="showing-record">
                                        {{selectedEmployeeData.length}} rows
                                    </div>
                                </div>
                                <div class="col-md-8 text-right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive" *ngIf="selectedEmployeeGroupData.length != 0">
                            <p><b>EMPLOYEE Group</b></p>
                            <table class="table  --table-bordered table-border-new" style="width: 100%;">
                                <thead class="t-head">
                                    <tr>
                                        <!-- <th class="text-center">S.No</th> -->
                                        <th class="text-center">Action</th>
                                       
                                        <th>Emp Group Code</th>
                                        <th>Emp Group Name</th>
                                        <th>Employees</th>
                                      
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of selectedEmployeeGroupData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                        <!-- <td class="text-center">{{i+1}}</td> -->
                                        <td class="text-center">
                                            <button mat-menu-item  type="button" (click)="deleteemployeegroup(i)"  >
                                                <mat-icon   >delete</mat-icon>
                                            </button>
                                               
                                              
                                         
                                        </td>           
                                        <td>{{row.employeeGroupCode}}</td>
                                        <td>{{row.employeeGroupName}}</td>             
                                        <td> <button mat-menu-item  type="button" (click)="viewemployees(row)"  >
                                            <mat-icon class="material-symbols-outlined"  >
                                                visibility
                                                </mat-icon>
                                        </button></td>               
                                       
                                      
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No matching records found!</td>
                                    </tr>
                                </tbody>
                            </table>
                         </div>
                         <div *ngIf="selectedEmployeeGroupData?.length > 0 ">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="showing-record">
                                        {{selectedEmployeeGroupData.length}} rows
                                    </div>
                                </div>
                                <div class="col-md-8 text-right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                       
                            <div class="col-md-12 text-right">
                                <button type="reset" class="submit action-button btn mr-2 btn-sm" (click)="clearemployeelist()"   style="margin-right: 20px;" mat-raised-button
                                >Clear</button>                              
                                <button type="submit" mat-raised-button color="primary">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
             

               
         
          
       
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
   
</div>