<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class=""><span class="h5 sub-content headingDesing">Requirement</span></div>
                <div class="sub-header-buttons">
                    <button *ngIf="positionlistdata?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button  class=" download-button ml-2 btnDesigns">
                      Export
                    </button>

                    <a routerLink="/home/requirement-add"  *ngIf="isAdmin" routerLinkActive="active"  mat-raised-button title="Add Position" class="ml-2  btnDesign ">
                      Add Requirement 
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <section>
    <div class="sub-header"  style="height: 68px;">
            <div class="row">
                <div class="col-md-2">
                    <span class="h5 sub-content headingDesing">Requirement</span>
                </div>
                <div class="col-md-4" style="    position: relative;
    top: 7px;">

                            <div class="sidebar-search">
                                <div class="input-group">
                                    <input type="input" class="form-control search-menu search-placeholder" placeholder="Search..." [(ngModel)]="search" (keyup)="changepage()" name="searchText"/>
                                </div>
                            </div>
                        </div>
                <div class="col-md-6 btnDesignset" style="position: relative;left: 150px;">
                    <button *ngIf="positionlistdata?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button  class=" download-button ml-2 btnDesigns">
                        Export
                      </button>
                      <a routerLink="/home/requirement-add"  *ngIf="isAdmin" routerLinkActive="active"  mat-raised-button title="Add Position" class="ml-2  btnDesign ">
                        Add Requirement 
                      </a>
                </div>

    
        </div>
    </div>
</section> -->

<!-- <nav class="navbar bg" style="margin-top: 67px">
  <div class="row w-100">
    <div class="col-md-3">
      <h4 class="heading-tag" style="position: relative; left: 5px">
        Requirement
      </h4>
    </div>

    <div class="col-md-5">
      <div class="input-group">
        <input
          type="input"
          class="form-control search-menu search-placeholder"
          placeholder="Search..."
          [(ngModel)]="search"
          (keyup)="changepage()"
          name="searchText"
        />
      </div>
    </div>

    <div class="col-md-4 d-flex justify-content-end">
      <button
      *ngIf="positionlistdata?.length > 0 && (positionlistdata | mytask : search).length > 0"
        type="button"
        (click)="exportToExcel()"
        mat-raised-button
        class="download-button ml-2 btnDesigns"
      >
        Export
      </button>
      <a
        routerLink="/home/requirement-add"
        *ngIf="isAdmin"
        routerLinkActive="active"
        mat-raised-button
        class="ml-2 btnDesign"
      >
        Add Requirement
      </a>
    </div> -->
    <!-- </div> -->
  <!-- </div> -->
<!-- </nav> -->

<nav class="navbar bg" style="margin-top: 67px;">
  <div class="container-fluid">
      <div class="row w-100">
          <div class="col-sm-4 col-md-3 d-flex align-items-center mb-2 mb-sm-0">
              <h4 class="heading-tag" style="position: relative; left: 5px;">
                Requirement

              </h4>
          </div>

          <div class=" col-sm-8 col-md-9 d-flex justify-content-end align-items-center" style="  position: relative;
          top: -4px;
       ">
              <div class="input-group me-3" style="max-width: 400px;">
                  <input type="text" [(ngModel)]="search"
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid search-input email-input select-placeholder"
                      name="keywords"
                      value=""
                      placeholder="Search..." >
              </div>

              <div class="d-flex"  style="height: 38px;
                  padding-top: 5px !important;margin-top: -4px;">
                  <button
                  *ngIf="positionlistdata?.length > 0 && (positionlistdata | mytask : search).length > 0"
                    type="button"
                    (click)="exportToExcel()"
                    mat-raised-button
                    class="download-button ml-2 btnDesigns"
                  >
                    Export
                  </button>
                  <a
                  routerLink="/home/requirement-add"
                  *ngIf="isAdmin"
                  routerLinkActive="active"
                  mat-raised-button
                  class="ml-2 btnDesign"
                >
                  Add Requirement
                </a>
              </div>
          </div>
      </div>
  </div>
</nav>
<!-- Sub header end here-->

<mat-card
  style="
    border-radius: 15px;
    border: 2px solid #ddd !important;
    background: #fdfdfd;
    height: auto;
  "
>
  <div class="container">
    <div class="row">
      <form
        [formGroup]="positionSearchform"
        (ngSubmit)="submitData()"
        class="d-flex flex-wrap align-items-center w-100"
      >
        <div class="col" id="space1">
          <label class="form-control-label">Position</label>

          <mat-select
            id="framework"
            class="form-control email-input select-placeholder"
            formControlName="positionName"
            placeholder="Select Position"
          >
            <mat-option [value]="null">-Select Position-</mat-option>
            <mat-option
              *ngFor="let position of positionactivelist; let i = index"
              value="{{ position.positionId }}"
            >
              {{ position.positionName }}
            </mat-option>
          </mat-select>
        </div>

        <div class="col" id="space3" style="position: relative; top: 10px">
          <label class="form-control-label">Requirement Number</label>

          <input
            type="text"
            formControlName="requirementnumber"
            autocomplete="off"
            style="padding: 11px"
            placeholder="Enter Requirement Number"
            (keyup)="validateInput($event)"
            (input)="validateInput($event)"
            (keydown)="validateInput($event)"
            (keyup)="getrequirementno()"
            [value]="null"
            (keydown)="handleBackspace($event)"
            class="form-control email-input select-placeholder"
          />
          <small class="text-muted noteDesign">Note : Requirement Number</small>
        </div>

        <div class="col" id="space3">
          <label class="form-control-label">Client</label>

          <mat-select
            id="framework"
            class="form-control email-input select-placeholder"
            formControlName="clientId"
            placeholder="Select Client"
          >
            <mat-option [value]="null" selected="selected"
              >-Select Client-</mat-option
            >
            <mat-option [value]="0"> In-House</mat-option>
            <mat-option
              *ngFor="let e of clientname; let i = index"
              value="{{ e.clientId }}"
            >
              {{ e.clientName }}
            </mat-option>
          </mat-select>
        </div>

        <div
          class="col-auto button-align"
          style="position: relative; top: 9px"
        >
          <button
            type="submit"
            mat-raised-button
            class="search-btn searchBtn"
            [disabled]="btnDisables"
            style="background: #1a83ff; color: #fff"
          >
            Search
          </button>
          <button
            style="
              margin-left: 10px;
              background: none;
              color: black;
              border: 1px solid #ddd !important;
            "
            type="button"
            mat-raised-button
            class="btn-clear search-btn mr-2"
            (click)="clearforms()"
            [disabled]="btnDisables"
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="card-content">
    <!-- *ngIf="momList.length > 5" -->
    <div
      class="row p-0 m-0"
      *ngIf="
        positionlistdata?.length > 5 &&
        (positionlistdata | mytask : search)?.length > 0
      "
    >
      <div
        class="col-xl-12 col-lg-12"
       
        *ngIf="(positionlistdata | mytask : search)?.length > 5"
      >
        <div class="select-pagination">
          <span>Show : </span>
          <select
            class="select_list new-select_list"
            [(ngModel)]="mySelect"
            (change)="showChanges()"
          >
            <option class="form-control" value="5">5</option>
            <option class="form-control" value="10">10</option>
            <option class="form-control" value="15">15</option>
            <option class="form-control" value="20">20</option>
            <option class="form-control" value="25">25</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive" style="margin-top: 0px">
      <table
        class="table"
        matSort
        (matSortChange)="sortData($event)"
        *ngIf="tableShow"
        style="position: relative; top: 15px"
      >
        <thead>
          <tr id="trDesign">
            <th class="text-center" style="border-radius: 15px 1px 0px 0">
              Action
            </th>
            <th
              mat-sort-header="ReName"
              class=""
      
            >
              Req_Number
            </th>
            <th
              mat-sort-header="positionName"
              class=""
          
            >
              Position Name
            </th>
            <th mat-sort-header="minExperience" class="text-center">
              Min Experience
            </th>
            <th mat-sort-header="maxExperience" class="text-center">
              Max Experience
            </th>
            <th mat-sort-header="clientName">Client Name</th>
            <th mat-sort-header="ctc" class="text-center">Max CTC</th>
            <th class="text-center">Requirement Status</th>
            <th class="text-center" style="border-radius: 0px 15px 0px 0px">
              Status
            </th>
          </tr>
        </thead>
        <tbody
          *ngIf="positionlistdata?.length > 0"
          style="
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          "
        >
          <tr
            *ngFor="
              let e of positionlistdata
                | mytask : search
                | paginate : { itemsPerPage: mySelect, currentPage: p };
              let i = index
            "
          >
            <ng-container *ngIf="isRequirement; else edit">
              <td class="table-data text-center">
                <a
                  ><mat-icon
                    routerLink="/home/requirement-details/{{ e.requirementId }}"
                    class="material-icons-round icon-size"
                    style="position: relative; top: 10px !important"
                    >visibility</mat-icon
                  ></a
                >
              </td>
            </ng-container>

            <td class="table-data text-center" style="padding-top: 22px">
              {{ e.requirementNo }}
            </td>
            <td class="table-data text-center" style="padding-top: 22px">
              {{ e.position.positionName }}
            </td>
            <td class="table-data text-center" style="padding-top: 22px">
              {{ e.minExperience }} Years
            </td>
            <td class="table-data text-center" style="padding-top: 22px">
              {{ e.maxExperience }} Years
            </td>
            <td
              *ngIf="e.clientId === 0 || e.clientId === undefined"
              class="table-data text-center"
              style="padding-top: 22px"
            >
              In-House
            </td>
            <td
              *ngIf="e.clientId !== 0 && e.clientId !== undefined"
              class="table-data text-center"
              style="padding-top: 22px"
            >
              {{ e.client?.clientName }}
            </td>
            <td class="table-data text-center" style="padding-top: 22px">
              {{ e.ctc }}
            </td>
            <!-- <ng-template #changestatus>
                                            <td class=" table-data text-center text-success" *ngIf="e.requirementStatus == true"
                                             style="padding: 5px 10px 33px 9px;">
                                                <ul id="nav">
                                                    <li class="text-center dropdown-list">
                                                        <a class="text-success dropdown-list-status" *ngIf="e.requirementStatus == true">Open</a>
                                                      
                                                        <ul  class="dropdown-item" (click)="statuschangefalse(e.requirementId)" >
                                                            <li class="material-menu">
                                                                <mat-icon class="dropdown-list-icon text-success" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>

                                                                <a class="text-danger" style="position: relative; left: 5px;">Close</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td class=" table-data text-center text-danger" *ngIf="e.requirementStatus == false"  style="padding: 5px 10px 33px 9px;">
                                                <ul id="nav">
                                                    <li class="text-center dropdown-list" >
                                                        <a class="text-danger dropdown-list-status" *ngIf="e.requirementStatus == false">Close</a>
                                             <span>
                                                            <mat-icon class="dropdown-list-icon text-danger" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                                        </span> -->
            <!-- <ul class="dropdown-item" (click)="statuschangetrue(e.requirementId)">
                                                            <li class="material-menu">
                                                                <a class="text-success" style="position: relative; left: 5px;">Open</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </ng-template>  -->
            <td class="active-status text-center" style="padding-top: 22px">
              <span
                class="btn-outline-success"
                *ngIf="e.requirementStatus == true"
              >
                <!-- {{e.positionStatus}} -->
                Open
              </span>

              <span
                class="btn-outline-danger"
                *ngIf="e.requirementStatus == false"
              >
                <!-- {{e.positionStatus}} -->
                Close
              </span>
            </td>
            <!-- <ng-container *ngIf="usertype.role[0].roleName=='Recruiter' ; else changestatus" >   
                                            <td class="table-data-recruiter text-center">                                    
                                                <a class="text-success dropdown-list-status" style="text-decoration: none;" *ngIf="e.requirementStatus == true">Open</a> 
                                                <a class="text-danger dropdown-list-status" style="text-decoration: none;" *ngIf="e.requirementStatus== false">Close</a>                       
                                            </td>                
                                        </ng-container> -->
            <!-- <ng-template #changestatusactive> -->
            <td
              *ngIf="e.status.listTypeValueName == 'Active'"
              class="table-data text-center text-success"
              style="padding: 5px 10px 33px 9px"
            >
              <ul id="nav">
                <li class="text-center dropdown-list">
                  <a
                    class="text-success dropdown-list-status1 text-center"
                    style="margin: 0px 30px 0px 20px"
                    *ngIf="e.status.listTypeValueName == 'Active'"
                    >{{ e.status.listTypeValueName }}</a
                  >
                  <!-- <span>
                                                            <mat-icon class="dropdown-list-icon text-success" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                                        </span> -->
                  <ul
                    class="dropdown-item"
                    (click)="statuschangeactive(e.requirementId)"
                    *ngIf="setInactiveNo"
                  >
                    <li class="material-menu">
                      <a class="text-danger dropdown-item-status2" style="color:red;">In-Active</a>
                      <!-- <mat-icon class="text-danger material-menu-icon" style="position: relative;left: 5px;" aria-hidden="false" >do_disturb</mat-icon> -->
                    </li>
                  </ul>
                </li>
              </ul>
            </td>

            <td
              *ngIf="e.status.listTypeValueName == 'In-Active'"
              class="table-data text-center text-danger"
              style="padding: 5px 10px 33px 9px"
            >
              <ul id="nav">
                <li class="text-center dropdown-list">
                  <a
                    class="text-danger dropdown-list-status1 text-center"
                    style="margin: 0px 30px 0px 20px"
                    *ngIf="e.status.listTypeValueName == 'In-Active'"
                    >{{ e.status.listTypeValueName }}</a
                  >
                  <!-- <span>
                                                            <mat-icon class="dropdown-list-icon text-danger" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                                        </span> -->
                  <ul
                    class="dropdown-item"
                    (click)="statuschangeinactive(e.requirementId)"
                    *ngIf="setActiveNo"
                  >
                    <li class="material-menu">
                      <a class="text-success dropdownitem-status2" style="color:green;">Active</a>
                      <!-- <mat-icon class="material-menu-icon text-success" style="position: relative;left: 5px;"aria-hidden="false">verified</mat-icon> -->
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
            <!-- </ng-template>                                    -->
            <!-- <ng-container *ngIf="usertype.role[0].roleName=='Recruiter' ; else changestatusactive" > 
                                            <td class="table-data-recruiter text-center">                                     
                                                <a class="text-success dropdown-list-status1" style="margin: 0px 30px 0px 20px;text-decoration: none;" *ngIf="e.status.listTypeValueName =='Active'">{{e.status.listTypeValueName}}</a>
                                                <a class="text-danger dropdown-list-status1"style="margin: 0px 30px 0px 20px;text-decoration: none;" *ngIf="e.status.listTypeValueName =='In-Active'">{{e.status.listTypeValueName}}</a> 
                                            </td>                    
                                        </ng-container> -->

            <td
              class="table-data text-center"
              *ngIf="e.positionStatus == 'True'"
            >
              {{ e.positionStatus }}
            </td>
            <ng-template #edit>
              <td class="table-data text-center" >
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="controls-list" style="min-width: none !important; margin-left: -24px;">
                  <button
                    *ngIf="isAdmin"
                    mat-menu-item
                    routerLink="/home/requirement-edit/{{ e.requirementId }}"
                    routerLinkActive="active"
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button style=""
                    *ngIf="isAdmin"
                    mat-menu-item
                    routerLink="/home/requirement-details/{{ e.requirementId }}"
                    routerLinkActive="active"
                  >
                    <mat-icon >visibility</mat-icon>
                    <span>View</span>
                  </button>

                  <!-- <button mat-menu-item *ngIf="e.jobDescriptionAttachment != undefined" disabled>
                                                                <a class="icon-badge" href="{{e.jobDescriptionAttachment.fileDownloadUrl}}">
                                                                    <mat-icon class="material-icons-outlined icon-size">file_download</mat-icon>
                                                                    <span>Download</span>
                                                                </a>
                                                             
                                                            </button> -->
                  <!-- <button mat-menu-item *ngIf="e.jobDescriptionAttachment != undefined" disabled>
                                                                <a class="icon-badge" [href]="e.jobDescriptionAttachment?.fileDownloadUrl" 
                                                                   (click)="e.jobDescriptionAttachment == undefined ? $event.preventDefault() : null">
                                                                    <mat-icon class="material-icons-outlined icon-size">file_download</mat-icon>
                                                                    <span>Download</span>
                                                                </a>
                                                            </button> -->

                  <!-- <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                                <mat-icon class="menu-icon">more_vert</mat-icon>
                                                            </button> -->
                  <!-- <mat-menu #menu="matMenu"> -->
                  <mat-icon
                    _ngcontent-wwp-c277=""
                    role="img"
                    class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                    style="
                      position: relative;
                      top: 10px;
                      left: 10px;
                      
                    "
                    aria-hidden="true"
                    data-mat-icon-type="font"
                    >done</mat-icon
                  >
                  <button
                    mat-icon-button
                    (click)="toggleStatus(e)"
                    class="menu-button maticonHover"
                    [ngClass]="{
                      'text-danger': e.status?.listTypeValueName === 'Active',
                      'text-success':
                        e.status?.listTypeValueName === 'In-Active'
                    }"
                    style="
                      padding-left: 33px;
                      box-sizing: border-box;
                      font-size: 15px;
                      
                    "
                  >
                    {{
                      e.status.listTypeValueName === "Active"
                        ? "Inactive"
                        : "Active"
                    }}
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="e.requirementStatus == true"
                    (click)="statuschangefalse(e.requirementId)"
                    style="border: none"
                  >
                    <mat-icon>done</mat-icon>
                    <span class="matIcondesign" style="color:red;">Close</span>
                  </button>

                  <button
                    mat-menu-item
                    *ngIf="e.requirementStatus == false"
                    (click)="statuschangetrue(e.requirementId)"
                    style="border: none"
                  >
                    <mat-icon>done</mat-icon>
                    <span class="matIcondesign" style="color:green;">Open</span>
                  </button>
                  <!-- <button mat-icon-button (click)="toggleStatusopenclose(e)" class="menu-button"
                                                                [ngClass]="{ 'text-danger': e?.requirementStatus === 'true', 'text-success': e.requirementStatus === 'false' }"
                                                                 style="padding-left:33px; box-sizing: border-box; font-size: 15px;" >
                                                                
                                                                    {{ e.requirementStatus === 'true' ? 'false' : 'true' }} 
                                                                    
                                                                </button> -->
                  <!-- </mat-menu> -->
                  <!-- <button mat-menu-item routerLink="/home/view-position/{{e.positionId}}" routerLinkActive="active">
                                                        <mat-icon>remove_red_eye</mat-icon>
                                                        <span>View</span>
                                                    </button> -->
                </mat-menu>
                <!-- <ul id="nav" style="width: 50px!important;">
                                                    <li style="bottom: 7px;position: relative;"><mat-icon type="button" class="material-icons-outlined icon-size">more_vert</mat-icon>
                                                        <ul class="dropdown-menu"> 
                                                            <li>
                                                                <a class="icon-badge">
                                                                   <mat-icon routerLink="/home/requirement-edit/{{e.requirementId}}"class="material-icons-round edit-icon">edit</mat-icon>
                                                                </a>
                                                            </li>
                                                            <li  *ngIf="e.jobDescriptionAttachment != undefined">
                                                                <a class="icon-badge" href="{{e.jobDescriptionAttachment.fileDownloadUrl}}">
                                                                    <mat-icon class="material-icons-outlined icon-size">file_download</mat-icon>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul> -->
              </td>
            </ng-template>
          </tr>
        </tbody>

        <tbody
          style="border-left: 1px solid #ddd; border-right: 1px solid #ddd"
        >
          <tr>
            <td
              style="text-align: center; font-size: larger; font-weight: bold"
              colspan="9"
              *ngIf="
                nodata ||
                ((positionlistdata | mytask : search).length === 0 && search) ||
                positionlistdata.length == 0
              "
            >
              Requirement Records Not Found!
            </td>
          </tr>
        </tbody>

        <!-- </tbody> -->
      </table>
      <div
        class="pagination-content"
        *ngIf="
          positionlistdata?.length > 5 &&
          (positionlistdata | mytask : search).length > 0
        "
        id="page"
      >
        <pagination-controls
          *ngIf="(positionlistdata | mytask : search).length > 5"
          style="position: relative; float: inline-end; top: 7px"
          (pageChange)="p = $event"
          align="end"
        ></pagination-controls>
      </div>
    </div>
  </div>
</mat-card>

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
  style="height: 527vh !important"
></div>
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner"  style="width: 3rem; height: 3rem;color: #1A83FF;position: relative;bottom: 0;margin-left: 90%;margin-top: -100%;"  role="status"></div>
</div> -->
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="
      width: 3rem;
      height: 3rem;
      color: #1a83ff;
      position: relative;
      bottom: 0;
      margin-left: 97%;
      margin-top: 5%;
    "
    role="status"
  ></div>
</div>

<!-- 
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div> -->
