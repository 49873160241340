import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-tax-breakup',
  templateUrl: './select-tax-breakup.component.html',
  styleUrls: ['./select-tax-breakup.component.scss']
})
export class SelectTaxBreakupComponent implements OnInit {

  selectedType: string = '';
  usertData:any
  entityAdminLogin:boolean=false
  organizationLogin:boolean=false;
  clientLogin:boolean=false;
  constructor() { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData') || '{}');
    console.log('this.usertData', this.usertData);

  //   if (this.usertData?.organization?.organizationId) {
  //     this.organizationLogin = true;
  //     this.selectedType = 'organization'; // Auto-patch value
  //     console.log('this.organizationLogin', this.organizationLogin);
  //   } else if (this.usertData?.employee?.client) {
  //     this.clientLogin = true;
  //     this.selectedType = 'client'; // Auto-patch value
  //     console.log('this.clientLogin', this.clientLogin);
  //   } else {
  //     this.entityAdminLogin = true;
  //     this.selectedType = ''; // No auto-patch
  //   }
  // }
  if (this.usertData?.organization?.organizationId) {
    this.organizationLogin = true;
    this.selectedType = 'organization'; // Set type to Organization
    console.log('User logged in as Organization');
  } else if (this.usertData?.employee?.client) {
    this.clientLogin = true;
    this.selectedType = 'client'; // Set type to Client
    console.log('User logged in as Client');
  } else {
    this.entityAdminLogin = true;
    this.selectedType = ''; // No specific login type
    console.log('User logged in as Entity Admin');
  }}
  clearAllForm() {
    this.selectedType = '';


  }

  selected(event: any) {
    this.selectedType = event.value;


  }

}
