<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>{{dateFormatId ? "Edit" : "Add"}} Date Format</h3>

    <mat-dialog-content class="mat-typography">
       <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Date Format <span class="star">*</span></label>
                <input type="text" formControlName="dateFormatName"  id="dateFormatName" (keyup)="checkExistDateFormatName($event)" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="" placeholder="Date Format">
                <div class="valid-msg" *ngIf="(validate.dateFormatName.invalid && validate.dateFormatName.touched) || validate.dateFormatName.dirty || submitted">
                    <span *ngIf="validate.dateFormatName.errors?.required" class="text-danger">Please enter date format</span>
                    <span *ngIf="dateFormatNameExistStatus" class="span-error">Date format already exist!</span>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Date Format Description</label>
                <textarea formControlName="dateFormatDescription" id="dateFormatDescription" autocomplete="off" class="form-select form-select-solid form-select-lg" placeholder="Date Format Description"></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>
