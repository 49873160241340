import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { AssaignemployeeerrorComponent } from '../assaignemployeeerror/assaignemployeeerror.component';
import { responseMsgTimeOut } from 'src/app/providers/properties';
import Swal from 'sweetalert2';
import { MatOption } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { UserService } from 'src/app/providers/user.service';
import { EmployeeProjectImportComponentComponent } from 'src/app/employee-project-import-component/employee-project-import-component.component';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowErrorComponent } from '../show-error/show-error.component';
import { AssignProjectErrorComponent } from '../assign-project-error/assign-project-error.component';
import { HeaderService } from 'src/app/providers/header.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { AssignEmployeeErrorComponent } from '../assign-employee-error/assign-employee-error.component';

@Component({
  selector: 'app-assaign-project',
  templateUrl: './assaign-project.component.html',
  styleUrls: ['./assaign-project.component.scss']
})
export class AssaignProjectComponent implements OnInit {



  @ViewChild('select') select: MatSelect;
  isButtonDisabled: boolean = false;
  allSelected:boolean=false;
  employeeForm:FormGroup;
  employeeList:any = [];
  projectList:any = [];
  arrayData:any = [];
  ProjectName:any;
  project:any =[];
  roleList:any = [];
  empData:boolean
  projectId:any
  submitted=false;
  employeeId: number;
  employee: any[]=[];
  employeeNumber: number;

  today:any= new Date();
  employeelastdate:any ;
  success :boolean= false;
  error:any=false;
  success_msg:any;
  errormessagejson: any;
  importerror: boolean = false;
  error_msg:any;
  employeetData: any;
  organizationId: any;
  id:any
  activeEmployeesData: any=[];
  organiztionId: any;
  assignProjectForm: any;
  selectEmployee: any;
employeeProjects: any;
  empList: any=[];
  selectedemployeeId: string;
  emp: number;
  selectedEmployees: any[] = [];
isChecked: boolean=false;
  errorMsg: any;
sucess_msg: any;
  constructor(
    private dialog:MatDialog,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs:ProjectManagementService ,
    private userservice:UserService,
    private datepipe : DatePipe,
    private headerservice: HeaderService,
    private assignemp: AssignEmpService
   ) {  
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
      console.log("this.employeetData",this.employeetData);
      let organizationId = this.employeetData.organization.organizationId;
      this.organizationId = this.employeetData.organization.organization

      console.log( this.organizationId );
      console.log(organizationId);
      
      

      this.gs.getActiveEmployees(organizationId).subscribe((result: any[]) => {
        this.activeEmployeesData = result;

        
        // this.activeEmployeesData.push({result});
        console.log("employee list::",this.activeEmployeesData)
      },(error:any)=>{
        console.log("eror fetching active employees::", error);
        
      })

      this.gs.getAllProjectNames(organizationId).subscribe((response:any[])=>{
        this.projectList = response;
        console.log("project response::",this.projectList);
        this.projectList.forEach((project:any)=>{
          // this.projectList(project.employee.employeeId)
        })
      })

      // this.gs.getRoleName().subscribe((response)=>{
      //   this.roleList = response;
      //   console.log("role response::",this.roleList);
      //   console.log("role original response::",response);
        
      // })
      this.gs.getRole().subscribe((response: any) => {
        this.roleList = response;
        console.log("role response::",this.roleList);
        console.log("role original response::",response);
        
      })
  }
  

  ngOnInit() {
    this.headerservice.setTitle('');
 
    // this.headerservice.setTitle(' Assign Employee Project');
    this.assignemp.setSuccessUpdateMessage(
      ''
    );
    this.employeeForm = this.fb.group({
      employee: ['', Validators.required]  ,
      rolename:['',Validators.required],
      project:['',Validators.required]
    })

  
    // this.gs.getRole().subscribe(
    //   (x:any)=>{
    //     this.roleList = x;
    //   }
    // )
   
  }

  get f(){
    return this.employeeForm.controls;
  }

  _Employee(){
    return this.f.employee.hasError('required') ? 'Please select Employees' :''
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.employeeForm.controls.employee.setValue(this.activeEmployeesData.map(e => e.employeeId));
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this.employeeForm.controls.employee.setValue([]);
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  individualCheckboxChanged() {
    const selectedEmployeeIds = this.employeeForm.get('employee').value;
    if (selectedEmployeeIds.length === this.activeEmployeesData.length && selectedEmployeeIds.length !== 0) {
      this.allSelected = true;
      console.log("if entering inside option");
    } else {
      this.allSelected = false;
      console.log("else condition");
    }
  }
  add(){
    let x = this.employeeForm.value;
    if(x.employee == ''){
      alert("Please select the options")
    }
    else{
      this.select.close()
    }
    // this.employeeForm.reset()
    this.allSelected = false;

  }

  cancel(){
    this.employeeForm.patchValue({employee:''})
    this.select.close()
    this.allSelected = false;

  }
 
  empSelect(emp) {
    console.log('emp', emp);
    this.employeeId = emp.employeeId;
    // this.employee = emp.employeeNumber + '-' + emp.firstName;
    this.employee.push(emp.employeeNumber + '-' + emp.firstName)


    this.employeeNumber = emp.employeeNumber;

 
  
    this.employeeForm.patchValue({
      employee: this.employee
    });
    console.log("this.employee",this.employee)
    
  
    console.log("employee Id after selecting::", this.employeeId);
    console.log('this.employee::', this.selectedemployeeId);
    console.log("wtts id tell me?::", this.employeeId);
  }
  

empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      this.gs.getEmployeesBynumber( employeenumber ).subscribe((result: any) => {
        this.employeeProjects = result;
        this.activeEmployeesData=result
        
        console.log('this.employeeProjects',this.employeeProjects);
        if(result.statusCode)
        {
          
        }else{
          this.empList =   result;
          console.log('this.employeeProjects',this.employeeProjects);
        }
     });
    } 
  }
  showerrormessage()
  {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(AssignEmployeeErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }


  save(){
    // this.employeeForm.reset()

    this.submitted=true
    this.allSelected = false;
    // this.isButtonDisabled = true;

    if (this.employeeForm.valid) {
      
      this.emp=this.employeeId
      console.log("this.empids::",this.emp);
      
      this.submitted=false
      this.isButtonDisabled = true;

      console.log("this.employee.values::",this.employeeForm.valid);
      
      // Get form values
      const formValues = this.employeeForm.value;
      console.log("this.employee.values::",this.employeeForm.value);

      console.log("form values::",formValues);
      
  
      // Create payload based on form values
      // const payload = {
      //   project: {
      //     projectId: formValues.project
      //   },
      //   employeeId: formValues.employee,
      //   status: {
      //     projectId: formValues.project,
      //     listTypeValueId: 1 
      //   },
      //   employeeProjectRole:{
      //     employeeProjectRoleId:formValues.rolename
      //   }
      // };
      // this.employeeForm.reset()

      const payload = formValues.employee.map(employeeId => ({
        project: {
          projectId: formValues.project
        },
        employeeId:employeeId,
        status: {
          projectId: formValues.project,
          listTypeValueId: 1
        },
        employeeProjectRole: {
          employeeProjectRoleId: formValues.rolename
        }
      }));
      console.log("payload::",payload);
      
      this.gs.AssignEmpRole(payload).subscribe(
            (e:any)=>{
              console.log("e.statusCode"+e.StatusCode);
              console.log("response",e);
              this.isButtonDisabled=true
              this.allSelected = false;
              if(e.StatusCode==200){
                console.log("entered if...");
                this.success = true;
                this.sucess_msg=e.message
                this.isButtonDisabled=true
                setTimeout(() => {
                  this.success = false;
                  this.isButtonDisabled=false
                }, 2000) 

  
              }

              // alert("suceess!...........")
              // Swal.fire({
              //   text:e.message,
              //   icon:'success'
              // })
              // window.location.reload();
            },
            (err:any)=>{
                        // this.error = true;
                     
                      console.log("code",err);
                      console.log("code",err.status);
                      if(err.status==409){
                        console.log("error if entered");
                        this.isButtonDisabled=true

                        this.error = true;

                        this.error_msg = err.error.message;
                        console.log( "this.errorMsg::", this.error_msg);

                        setTimeout(() => {
                          this.isButtonDisabled=false

                          this.error = false;
                          
                        }, 2000)

                      }
                         
                      }
      )
     this.employeeForm.reset()
    //  this.isButtonDisabled=false;
    }
    // this.employeeForm.reset();
    // console.log("payload::",payload);
    // this.submitted=true;
    // if(this.employeeForm.invalid){
    //   return
    // }
    
    //      let x = this.employeeForm.value;

    //   x.employee.map(
    //     e => {
    //       let data;
    //       if (e) {
    //         data = {
    //           employee: {
    //             employeeId: e
    //           },
    //           project: {
    //             projectId: x.project
    //           },
    //           userRole: {
    //             userroleId: x.rolename
    //           }
    //         }
    //       }
    //       this.arrayData.push(data)
    //     }
    //   )
    //   console.log(JSON.stringify(this.arrayData))
    //   this.gs.AssignEmpRole(this.arrayData).subscribe(
    //     (e:any)=>{
    //     //   alert("success");
    //       Swal.fire({
    //         text:e.message,
    //         icon:'success'
    //       })
    //       // window.location.reload();
    //     },err =>{
    //        //alert("error")
    //       //  console.log(err.error);
    //       // Swal.fire({
    //       //   icon: 'error',
    //       //   text: err.error.message
            
    //       // })
    //   //    window.location.reload();
    //    this.arrayData.length = 0;
    //     }
    //   )
    }

  importEmployee() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(EmployeeProjectImportComponentComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
    // height: 'fit-content',
    // maxWidth: '90%', 
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        // this.success = true;
				// this.success_msg = result.description;
        setTimeout(() => {this.success  = false;},responseMsgTimeOut)
      }
      else 
      {
        if(result.length != 0)
       {

        this.error = true;
        // this.error_msg = result.description;
        this.importerror = true;
        this.errormessagejson = result;
        // this.showerrormessage();
        // setTimeout(() => {this.error = false}, responseMsgTimeOut)
       }
        else
        {
        if(result.statusCode > 400)
        {
          this.error = true;
        
          this.importerror = false;
        }
        }
      
      }
    });
  }

 


}
