import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { redirectMsgTimeOut, responseMsgTimeOut}from '../../app/providers/properties'
import { PayrollService } from '../providers/payroll.service';
import { SettingsService } from '../providers/settings.service';
import { ShowerrorComponent } from '../showerror/showerror.component';
import {ImportEmployeeworkingdaysComponent } from '../import-employeeworkingdays/import-employeeworkingdays.component';

@Component({
  selector: 'app-employeewokingdayslist',
  templateUrl: './employeewokingdayslist.component.html',
  styleUrls: ['./employeewokingdayslist.component.scss']
})
export class EmployeewokingdayslistComponent implements OnInit {
  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  leaveId: any;
  req: boolean=false;
  startIndex: number;
  empCode: boolean;
  employeecode: any;

  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private dialog:MatDialog,
    private router:Router) { }

  ngOnInit(): void {
    this.noRecordsFound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.filterForm = this.fb.group({
      client:[null],
      employeecode:[null],
      financialyear:[null],
      period:[null],
    })

    this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1){
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
    });

     
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })    
  }

  getclientEmployees()
  {
      this.empList.length = 0;
       console.log(this.filterForm.value.client)
      if(this.filterForm.value.client != null && this.filterForm.value.client != "null")
      {
        // this.payrollservice.getAllEmployeeClientList(this.filterForm.value.client).subscribe((ClientEmployees: any) => {
        //   if(ClientEmployees.length != undefined)
        //   {
        //     ClientEmployees.forEach(element => {
        //       this.empList.push(element.employee);
        //     });
        //   }
         
        // });
    }
  }

  empSearch(event) {
    this.empList = [];
    this.empCode = false;
    console.log(event.target.value);
    this.userId = event.target.value;
    console.log(this.userId);
    // Employee List Api
    // this.empList.length == 0;
    // this.payrollservice.getEmployeeNameList(this.userId, this.orgId).subscribe((result: any) => {
    //   if (result.statusCode == 204) {
    //     console.log('NO Data');
    //     this.empList.length = 0;
    //     this.empCode = true;
    //   }
    //   else {
    //     this.empList = result;
    //     this.empCode = false;
    //     console.log(this.empList);
    //   }
    // })
    this.payrollservice.getClienEmployeeList( this.filterForm.value.employeecode , this.filterForm.value.client).subscribe((result: any) => {
      this.empList.length = 0;
      if(result.statusCode)
      {
        
      }else{
        this.empList =  result;
      }
   
   })
  }
  empSelect(emp) {

    this.employeeId = emp.employeeId;
   
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
     this.employeecode = emp.employeeNumber;
      this.filterForm.patchValue({
        employeecode: this.employee 
     });

    // }

  }

  addWorkingdays()
  {
    console.log("create emp  leave");
    this.router.navigateByUrl('/home/employeeworkdays');
  }
  searchSubmit()
  {
    
    console.log("submit",this.filterForm.value);
    // this.req=false;
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   // alert('Please enter employee number');
    //   return;
    // }
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear===null|| this.filterForm.value.financialyear=== 'null')&&(this.filterForm.value.period===null||this.filterForm.value.period==='null'))
    {
      console.log("Required Field");
       this.resultData =[];
       this.noRecordsFound = true;
       alert("Please Enter Employee Number");
       return;
      // this.req=true;
    }
    this.loader = true;
    // if(((this.filterForm.value.employeecode!=''||this.filterForm.value.employeecode!=null)&&this.filterForm.value.financialyear==null)||(this.filterForm.value.employeecode==''||this.filterForm.value.employeecode==null)&&this.filterForm.value.financialyear!=null)
    // {
    //   this.req=false;
    // }
    console.log(this.filterForm.value);

    console.log('this.filterForm.value.employeecode',this.filterForm.value.employeecode);
    console.log('this.filterForm.value.financialyear',this.filterForm.value.financialyear);
    console.log('this.filterForm.value.period',this.filterForm.value.period);
    
    if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null') && this.filterForm.value.client==null || this.filterForm.value.client=='null')
    {
      console.log("both");
      this.payrollservice.EmployeeWorkingDaysFilteBothId(this.employeeId,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&(this.filterForm.value.period==null||this.filterForm.value.period=='null') && this.filterForm.value.client==null || this.filterForm.value.client=='null')
    {
      console.log("Finanical year only");
      this.payrollservice.EmployeeWorkingDaysFilterYear(this.filterForm.value.financialyear,this.orgId).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null') && this.filterForm.value.client==null || this.filterForm.value.client=='null')
    {
      console.log("year and period");
      this.payrollservice.EmployeeWorkingDaysFilterYearandPeriod(this.filterForm.value.financialyear,this.filterForm.value.period,this.orgId).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
   
    else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear ==null ||this.filterForm.value.financialyear=='null')
    &&(this.filterForm.value.period==null || this.filterForm.value.period=='null') && (this.filterForm.value.client!=null || this.filterForm.value.client !='null'))
      {
      console.log("Employeecode only");
      this.payrollservice.EmployeeWorkingDaysFilteremployeeId(this.employeeId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.loader = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
     } 
    // else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    // &&(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    // &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
    // {
    //   console.log("emp num and period");
    //   this.payrollservice.employeeworkingDaysbyEmpNumAndFinancialPeriod(this.filterForm.value.employeecode,this.filterForm.value.period).subscribe((resultData:any) =>
    //   {
    //     this.noRecordsFound = false;
    //     this.resultData = resultData;
    //     console.log(this.resultData);
    //     if(this.resultData.length == 0 || this.resultData.length == undefined)
    //     {
    //       this.noRecordsFound = true;
    //     }
    //     if( resultData.statusCode == 204){
    //       this.noRecordsFound = true;
    //     }
    //   })
    // }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    // &&(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    // &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
    // {
    //   console.log("period");
    //   this.payrollservice.listByFinancialPeriod(this.filterForm.value.period).subscribe((resultData:any) =>
    //   {
    //     this.noRecordsFound = false;
    //     this.resultData = resultData;
    //     console.log(this.resultData);
    //     if(this.resultData.length == 0 || this.resultData.length == undefined)
    //     {
    //       this.noRecordsFound = true;
    //     }
    //     if( resultData.statusCode == 204){
    //       this.noRecordsFound = true;
    //     }
    //   })
    // }
    else if(!(this.filterForm.value.employeecode=== 'null' || this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null || this.filterForm.value.employeecode=== '')
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&(this.filterForm.value.period==null || this.filterForm.value.period=='null') && (this.filterForm.value.client!=null || this.filterForm.value.client!='null'))
    {
      console.log("emp num and year");
      this.payrollservice.employeeworkingDaysByEmpIdAndFinancialYear(this.employeeId,this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.employeecode=== 'null' || this.filterForm.value.employeecode=== ''  || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear != null || this.filterForm.value.financialyear !='null')
    &&(this.filterForm.value.period == null || this.filterForm.value.period=='null') && (this.filterForm.value.client !=null || this.filterForm.value.client !='null'))
    {
      console.log("client and year ");
      this.payrollservice.employeeworkingDaysByclientAndFinancialYear(this.filterForm.value.client,this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.employeecode=== 'null'|| this.filterForm.value.employeecode=== ''   || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.period != null || this.filterForm.value.period !='null') && (this.filterForm.value.client !=null || this.filterForm.value.client !='null'))
    {
      console.log("client and period ");
      this.payrollservice.employeeworkingDaysByclientAndFinancialPeriod(this.filterForm.value.client,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.loader = false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
  }
  // pageChages()
  // {
  //   console.log("page changes");
  //   this.p=1;
  // }\
  // onItemsPerPageChange(event: any) {
  //   this.pagePerItem = event.target.value;
  //   this.p = 1; // Reset the pagination to the first page when items per page is changed
  // }

  clearTable()
  {this.resultData = [];
    console.log('clear')
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
  }

  pageChages(event)
  {
    console.log('event.target.value', event.target.value);
    if(event.target.value != null || event.target.value != 0){
      // this.p = event.target.value;
      console.log("page changes");
      this.p=1;
    }
    
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear(event)
  {
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    console.log('this.chooseFinId == null',this.chooseFinId == null)
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else{
      console.log('else');
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
        this.filterForm.patchValue({
          period: null,
        });
      });
    }
  
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.leaveId=row.employeerattingId;
    console.log(this.leaveId);
    this.router.navigate(['/home/edit-employee-leave/', this.leaveId])
  }
  updateStatus(id,status)
	{
    this.payrollservice.deactiveEmployeeWorkingDays(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}


  importEmployee()
  {
    console.log("import Employee");
    const dialogRef = this.dialog.open(ImportEmployeeworkingdaysComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });
  
    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else 
      {
         if(result.length != 0)
         {
          console.log("Import Error");
          this.error = true;
          //this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         }
          else
          {
          if(result.statusCode > 400)
          {
            this.error = true;
            this.importerror = false;
          }
          }
      }
    });
  }

}
