<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span
            class="h5 sub-content"
            style="font-size: 22px; font-family: 'Mulish'; font-weight: 800"
            >Assign Approvers</span
          >
        </div>
        <div class="sub-header-buttons">
          <a
            routerLink="/home/assign-approver"
            mat-raised-button
            title="Back"
            class=""
            style="border: 1px solid rgba(74, 70, 70, 0.566) !important"
            >Back</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<div class="common-content">
  <div class="card-new">
    <div class="card" style="background: none; border: none">
      <div
        class="card-body"
        style="
          border-radius: 15px;
          border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
          background: #fdfdfd;
        "
      >
        <div class="mb-2">
          <div class="col-md-12 p-0">
            <form
              [formGroup]="projectApproverForm"
              (ngSubmit)="Addprojectapprover()"
            >
              <!-- Task Type -->
              <div class="row">
                <div class="col-md-3">
                  <div class="input-width" id="loginform">
                    <label class="col-control-label labelDesign"
                      >Project<span class="text-danger"> *</span></label
                    >
                    <mat-select
                      formControlName="project"
                      class="form-control email-input select-placeholder"
                      placeholder="Select Project"
                      (selectionChange)="projectapprovervalidate()"
                    >
                      <mat-option
                        *ngFor="let project of projectList"
                        [value]="project.projectId"
                      >
                        {{ project.projectName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="f.project.invalid && submitted1"
                      style="color: red; font-size: 13px"
                    >
                      <span *ngIf="showError" style="color: red">
                        Project is required</span
                      ></mat-error
                    >
                  </div>
                  <mat-error style="color: red; font-size: 13px">
                    <span
                      *ngIf="projectapproverexist"
                      style="
                        color: red;
                        font-family: 'Mulish', sans-serif;
                        position: relative;
                        top: -19px;
                        font-size: 13px;
                      "
                    >
                      Approver Already Exist
                    </span>
                  </mat-error>
                </div>
                <div class="col-md-3">
                  <!-- Task Type -->
                  <div class="input-width" id="loginform">
                    <label class="col-control-label labelDesign"
                      >Approver<span class="text-danger"> *</span></label
                    >
                    <mat-select
                      formControlName="approver"
                      placeholder="Select Approver "
                      class="form-control email-input select-placeholder"
                    >
                      <mat-option
                        *ngFor="let approver of ApproverList"
                        [value]="approver.approverId"
                      >
                        {{ approver.rulename }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="f.approver.invalid && submitted1"
                      style=""
                      class="textdangerDesign"
                    >
                      <span *ngIf="showError" style="color: red">
                        Approver is required</span
                      ></mat-error
                    >
                  </div>
                  <!-- <mat-error *ngIf="f.approver.invalid && submitted1">Approver is required</mat-error> -->
                </div>

                <div class="col-md-3">
                  <div class="input-width" id="loginform">
                    <label class="col-control-label labelDesign"
                      >Approver Type<span class="text-danger"> *</span></label
                    >
                    <mat-select
                      formControlName="approverType"
                      placeholder="Select Approver Type"
                      class="form-control email-input select-placeholder"
                    >
                      <mat-option
                        *ngFor="let approvertype of approvertype"
                        [value]="approvertype.approverTypeId"
                      >
                        {{ approvertype.approverTypeName }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="f.approverType.invalid && submitted1"
                      style=""
                    >
                      <span
                        *ngIf="showError"
                        style="color: red"
                        class="textdangerDesign"
                        >Approver&nbsp;Type&nbsp;is required
                      </span>
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-3">
                  <button
                    mat-raised-button
                    class="create-button btnDesign"
                    type="submit"
                    style="margin-bottom: 11px"
                    [disabled]="projectapproverexist 
                                        || isButtonDisabled 
                                 || !saveButton 
                                        || projectApproverForm.invalid"
                  >
                    <!-- {{data.projectApproverId ? 'Update': 'Create'}} -->Save
                  </button>
                  <button
                    class="create-button btnDesign"
                    type="reset"
                    (click)="clearForm()"
                    style="
                      margin-bottom: 33px;
                      background: none;
                      border: 1px solid rgba(117, 109, 109, 0.4392156863) !important;
                      color: black !important;
                      margin-left: 6px;
                    "
                  >
                    Clear
                    <!-- {{data.projectApproverId ? 'Update': 'Create'}} -->
                  </button>
                  <!-- <button  class="btn create-button"
                                       >Clear</button>
                                       -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_Msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>
