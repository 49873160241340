import { Component, HostListener, Inject, Input, OnInit, ElementRef,ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UpdateactionComponent } from '../../project-management/updateaction/updateaction.component';
// import { UpdateactionComponent } from '../updateaction/updateaction.component';
import { CreatemomComponent } from '../createmom/createmom.component';
import { ShowErrorComponent } from '../../project-management/show-error/show-error.component';
import { Router } from '@angular/router';
import { HeaderService } from '../../../../app/providers/header.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AssignEmpService } from '../../../../app/providers/assign-emp.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { ActionitemService } from './actionitem.service';
import { Subscription } from 'rxjs';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-actionitemreport',
  templateUrl: './actionitemreport.component.html',
  styleUrls: ['./actionitemreport.component.scss'],
})
export class ActionitemreportComponent implements OnInit {
  @Input() updatedData: any;
  importerror: boolean = false;
  projectList: any = [];
  // loader:boolean = false;
  actionItemList: any = [];
  filteredActionItemLists: any = [];
  projectStatus: any;
  actionStatus: any;
  mySelect: any = 5;
  startDate: any;
  endDate: any;
  errormessagejson: any;
  formsubmitted: boolean = false;
  isbtndisabled: boolean = false;

  // p:any
  p: number = 1;
  User_Type: any;
  noData = false;
  beforesearchpage: any;
  search: any;
  errorMsg = false;
  today: any;
  projects: any;
  listValueId: any;
  projectIdValue: any;
  responseofX: any;
  @Input() payload: any;
  yourPayloadVariable: any;
  actionitemIds: number;
  datas: any[] = [];
  originalData: any[] = [];
  storeOrgId: any;
  listValueIds: number;
  actionItemLists: any = [];
  listvalueItdstore: any;
  firstArray: any;
  listtypevalueId: any;
  storeprojectId: any;
  sucess: boolean;
  sucess_msg: any;
  error: boolean;
  update_error_msg: any;
  success: any;
  error_Msg: any;
  enddate_required: boolean;
  startdate_required: boolean;
  maxEndDate: Date;
  startdate: any;
  endSelectedFirst: boolean;
  loader: boolean = false;
  private successUpdateMessageSubscription: Subscription;
  storeSuccess: boolean = true;
  tableShow: boolean = false;
  organizationName:any;
  orgAddress:any;

  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;

  // yourPayloadVariable:any
  constructor(
    private gs: ProjectManagementService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private headerservice: HeaderService,
    private actionitem: ActionitemService,
    private assignemp: AssignEmpService
  ) {}

  ngOnInit(): void {
    this.headerservice.setTitle('');
    this.actionitem.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage('');
    this.actionitem.setSuccessUpdateMessage('');
    // this.headerservice.setTitle('Action Item Report');
    this.actionItemList;
    console.log(' this.actionItemList::', this.actionItemList);

    // this.actionItemStatus=e.actionItemStatus
    this.noData = false;
    // this.today =new Date();
    // this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');
    //const myData = JSON.parse(localStorage.getItem('MOMdata'))
    const myData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('wtts this.data check::', this.data);

    this.organizationName=  myData.organization.organizationName;
    this.orgAddress=  myData.organization.address;

   

    this.successUpdateMessageSubscription = this.actionitem
      .getSuccessUpdateMessage()
      .subscribe((message) => {
        if (message) {
          // this.getAddTaskSuccessUpdateMessage(message);

          this.success = true;
          this.sucess_msg = message;
        }
      });

    window.setTimeout(() => {
      // this.successUpdate = false;
      // this.closeUpdateSuccessMessage();
    }, 3500);

    // this.errorSubscription = this.assignemp
    //   .getErroeMessage()
    //   .subscribe((message) => {
    //     // setTimeout(() => {
    //     if (message) {
    //       this.error = true;
    //       // this.errorMessage = message;
    //     }

    //     // Close the success message after 3 seconds (adjust duration as needed)
    //     window.setTimeout(() => {
    //       this.error = false;
    //       // this.closeErrorMessage();
    //     }, 3500);

    //     console.log('Success message:', message);

    //     // const timer$ = timer(300).pipe(take(1));
    //     // timer$.subscribe(() => {
    //     //   this.closeSuccessMessage();
    //     // });
    //   });

    this.storeOrgId = myData.organization.organizationId;
    this.listvalueItdstore = myData.status.listTypeValueId;
    console.log('list type value Ids::', this.listvalueItdstore);

    console.log('this.orgId::', this.storeOrgId);

    this.organizationName=  myData.organization.organizationName;
    this.orgAddress=  myData.organization.address;

    this.originalData = this.data.slice();

    this.gs
      .getResourceByIds(myData.organization.organizationId)
      .subscribe((x: any) => {
        // this.User_Type = x.userType.userType;
        // console.log("response ::",x)
        console.log(' this.actionItemList::', this.actionItemList);
      });
    // const payload = {
    //   actionItem: [
    //     {
    //       actionItemName: 'SomeName',
    //       actionItemTargetDate: 'SomeDate',
    //       actionItemOwner: 'SomeOwner',
    //       actionItemStatus: {
    //         listTypeValueId: 3,
    //         listTypeValueName: 'pending'
    //       },
    //       status: {
    //         listTypeValueId: 1,
    //         listTypeValueName: "Active",
    //       }
    //     },

    //   ]

    // };
    // this.yourPayloadVariable={
    //    actionItemStatus:payload.actionItem[0].actionItemStatus.listTypeValueId
    // }
    // console.log("payload action item ::" ,this.yourPayloadVariable);
    // this.actionitemIds=payload.actionItem[0].actionItemStatus.listTypeValueId

    // this.listValueIds=payload.actionItem[0].status.listTypeValueId
    // console.log("action item status id::",  this.actionitemIds);

    // console.log("status list id::", this.listValueIds);

    // this.gs.get_projectsByUserId(myData.employeeId).subscribe(
    //   e=>{
    //     this.projectList = e;
    //   }
    // )
    this.gs
      .getEmpProjectList(myData.organization.organizationId)
      .subscribe((pa: any) => {
        this.projectList = pa;
        this.storeprojectId = pa.projectId;
        // console.log("project list ::",this.projectList);
        // console.log("project iD::",this.storeprojectId);

        this.projectList.forEach((project: any) => {
          // console.log("projectName::",project.projectName);
          // console.log("projectIds::",project.projectId);
          this.projectIdValue = project.projectId;
          // console.log("projectsId::",this.projectIdValue);

          // console.log("status::",project.status.listTypeValueId);
          this.listValueId = project.status.listTypeValueId;
          // console.log("Actual Status::",this.listValueId);
        });
      });
    // this.gs.actionItemList(this.listvalueItdstore).subscribe((response)=>{
    //   this.actionItemLists=response
    //   console.log("this.actionItemList :: ",this.actionItemLists);

    //     this.firstArray = this.actionItemLists[0];
    //     console.log("this.firstarray::",this.firstArray);
    //     this.listtypevalueId=this.firstArray.listTypeValueId
    //     console.log("chk wtts::",this.listtypevalueId);

    //     this.filteredActionItemLists = this.actionItemLists.filter(
    //       (item) => item.listTypeValueName === "Completed" || item.listTypeValueName === "Pending"
    //     );
    // });
    this.getListItems();
    this.storeOriginalData();
  }
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if the clicked element is not the search input
  //   if (!(event.target as HTMLElement).closest('.form-control')) {
  //     this.search = ''; // Clear the search term
  //   }
  // }
  getListItems() {
    this.gs.actionItemList(this.listvalueItdstore).subscribe((response) => {
      this.actionItemLists = response;
      console.log('this.actionItemList :: ', this.actionItemLists);

      this.firstArray = this.actionItemLists[0];
      console.log('this.firstarray::', this.firstArray);
      this.listtypevalueId = this.firstArray.listTypeValueId;
      console.log('chk wtts::', this.listtypevalueId);

      this.filteredActionItemLists = this.actionItemLists.filter(
        (item) =>
          item.listTypeValueName === 'Completed' ||
          item.listTypeValueName === 'Pending'
      );
    });
  }

  showerrormessage() {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }
  storeOriginalData() {
    this.originalData = JSON.parse(JSON.stringify(this.data));
  }
  resetForms() {
    this.actionStatus = undefined;
    this.projectStatus = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.noData = false;
    // this.search=''
    this.isbtndisabled = false;
    // this.loader = true;
    this.actionItemList = [];
    this.tableShow= false;
  }
  mintaskendDate(date: any, index: any) {
    console.log('mindate');
    console.log(date.target.value);
    document
      .getElementById('enddtPicker' + index)
      .setAttribute('min', date.target.value);

    // this.endDate_disable = false;
  }

  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }

  checkStartDate(): void {
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      alert('Start date cannot be greater than end date.');

      this.startDate = '';
      this.endDate = '';
      this.startDate = undefined;
      this.endDate = undefined;
    }
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    if (this.startDate && this.endDate) {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
      this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');

      console.log('2dates', startDate, endDate);

      if (this.endDate < this.startDate) {
        alert('Start date cannot be greater than end date.');
        this.startDate = '';
        this.endDate = '';
        this.tableShow=false;
      } else {
      }
    }

    if (this.startDate === undefined && this.endDate !== undefined) {
      this.startdate_required = true;
      this.enddate_required = false;
      return;
    }

    if (this.startDate !== undefined && this.endDate !== undefined) {
      this.startdate_required = false;
      this.enddate_required = false;
      return;
    }

    if (this.endDate !== undefined) {
      this.enddate_required = false;
      return;
    }
  }

  searchTask() {
    this.noData = false;
    // this.checkStartDate()

    // console.log('startend',this.projectStatus,this.startDate,this.endDate,this.actionStatus,);

    // this.updateEndDateMin()
    //  if (this.startDate > this.endDate) {
    //   alert('Start date should not be greater than End date');
    //   // return;
    //   if(this.projectStatus===undefined && this.startDate===undefined && this.endDate===undefined && this.actionStatus===undefined){
    //     console.log("all 4 select");

    //     alert("Please Choose any of the Element to Search")
    //         }
    //  else  if(this.startDate!==undefined && this.endDate==undefined ){
    //   console.log( "enddate choose");

    //     alert("end date is required!!")
    //   }
    //   else if(this.endDate!==undefined && this.startDate==undefined){
    //     console.log( "startDate choose");

    //     alert("start date is required!!...")
    //   }
    //   else{
    //     console.log("else came out");

    //   }
    // this.actionItemList.length=[]
    this.p = 1;
    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');
    this.search = '';
    if (
      this.projectStatus == undefined &&
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.actionStatus === undefined
    ) {
      alert('Please Choose any of the Element to Search');
      this.isbtndisabled = false;
      // this.projectStatus=undefined
      // this.startDate=undefined
      // this.endDate=undefined
      // this.actionStatus=undefined

      return;
    }

    //    else if (this.startDate > this.endDate ) {
    //     this.isbtndisabled=false

    //       alert('Start date should not be greater than End date');
    //       // this.projectStatus=undefined
    // this.startDate=undefined
    // this.endDate=undefined
    // // this.actionStatus=undefined

    //       return;
    //     }

    // else if(this.endDate<this.startDate){
    //   alert('Start date should not be greater than End date')
    //   this.startDate=undefined
    //   this.endDate=undefined
    //   return
    // }
    else if (this.endDate == undefined && this.startDate != undefined) {
      alert('End Date is Required!!');
      this.isbtndisabled = false;
      //       this.projectStatus=undefined
      // this.startDate=undefined
      // this.endDate=undefined
      // this.actionStatus=undefined

      return;
    }
    //     else if(this.startDate<this.endDate){
    //       alert("Start date should be greather than end date!!")
    //        this.startDate=undefined
    //        this.endDate=undefined
    // //        this.taskStatusId=undefined
    // // this.selectedetaskGroupId=undefined

    // return
    //     }
    else if (this.startDate == undefined && this.endDate != undefined) {
      alert('Start Date is Required!!');
      this.isbtndisabled = false;
      //       this.projectStatus=undefined
      // this.startDate=undefined
      // this.endDate=undefined
      // this.actionStatus=undefined

      return;
    } else {
      console.log('else !...');
    }

    //  if (this.startDate > this.endDate) {
    //   // Alert the user that start date should be greater than end date
    //   alert('Start date should be greater than end date.');
    // }
    // if (!this.endDate) {
    //   // Alert the user that end date is required
    //   alert('End date is required.');
    // } else {
    //   // Proceed with your logic
    // }

    // if(this.formsubmitted==false){
    //   console.log("please select items");

    //   alert("Please select required Items")
    //   return
    // }
    // }
    // if(this.projectStatus==undefined && this.startDate==undefined && this.endDate==undefined && this.actionStatus==undefined){
    //   alert("Please Choose any of the Element to Search")
    //       }

    // if(this.startDate > this.endDate){
    //   alert('Start date should not be greater than End date')
    //   return
    // }
    // this.actionItemList = [];
    // console.log("this.actionItemList = []::",this.actionItemList);

    // console.log("this.projectStatus::",this.projectStatus);
    //all 4 selection below
    this.tableShow = false;
    if (
      this.projectStatus == undefined &&
      this.actionStatus != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined
    ) {
      // if(this.projectStatus=='non-project'){
      this.isbtndisabled = true;
      this.loader = true;
      this.actionItemList = [];
      this.gs
        .actionstartendDateselecting(
          this.startDate,
          this.endDate,
          this.actionStatus
        )
        .subscribe(
          (response) => {
            this.noData = false;
            this.loader = false;
            this.tableShow = true;

            // this.actionItemList = response;
            this.actionItemList = Array.isArray(response) ? response : [];
            this.isbtndisabled = true;
            this.loader = false;

            console.log(
              'this.actionItemList response Dates::',
              this.actionItemList
            );
            // if(this.actionItemList.length == 0 || this.actionItemList.errorCode == 204){
            //   this.noData = true;
            // }
            if (this.actionItemList.message == 'No Data is Available') {
              this.noData = true;
              this.isbtndisabled = false;
              this.loader = false;
              this.tableShow = true;
            }
            this.isbtndisabled = false;
          },
          (error) => {
            this.actionItemList.length = 0;
            if (error.errorCode == 404) {
              this.noData = true;
              this.tableShow = true;

              this.loader = false;
              console.log('No Data');
            }
          }
        );
    }
    if (
      this.projectStatus != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.actionStatus == undefined
    ) {
      console.log('only project and dates!!');
      if (this.projectStatus == 'non-project') {
        this.isbtndisabled = true;
        this.loader = true;
        this.actionItemList = [];
        this.gs.nonProjectStartEndDate(this.startDate, this.endDate).subscribe(
          (response) => {
            console.log('response', response);
            this.noData = false;
            this.tableShow = true;

            this.loader = false;
            // this.actionItemList = response;
            this.actionItemList = Array.isArray(response) ? response : [];
            this.isbtndisabled = true;
            this.loader = false;

            console.log(
              'this.actionItemList response Dates::',
              this.actionItemList
            );
            // if(this.actionItemList.length == 0 || this.actionItemList.errorCode == 204){
            //   this.noData = true;
            // }
            if (this.actionItemList.description == 'No Data is Available') {
              this.noData = true;
              this.isbtndisabled = false;
              this.tableShow = true;

              this.loader = false;
            }
            this.isbtndisabled = false;
          },
          (error) => {
            this.actionItemList.length = 0;
            if (error.errorCode == 404) {
              this.noData = true;
              this.loader = false;
              this.tableShow = true;

              console.log('No Data');
            }
          }
        );
      } else {
        console.log('else from project and dates');

        if (
          this.projectStatus != undefined &&
          this.startDate != undefined &&
          this.endDate != undefined &&
          this.actionStatus == undefined
        ) {
          if (this.projectStatus != 'non-project') {
            this.actionItemList = [];
            this.loader = true;
            this.gs
              .ProjectStartEndDate(
                this.startDate,
                this.endDate,
                this.projectStatus
              )
              .subscribe(
                (response) => {
                  this.isbtndisabled = false;
                  this.loader = true;
                  console.log('response', response);
                  this.noData = false;
                  this.tableShow = true;

                  this.loader = false;
                  this.actionItemList = response;
                  this.isbtndisabled = true;
                  this.loader = false;

                  console.log(
                    'this.actionItemList response Dates::',
                    this.actionItemList
                  );
                  if (this.actionItemList.message == 'No Data is Available') {
                    this.noData = true;
                    this.isbtndisabled = false;
                    this.tableShow = true;

                    this.loader = false;
                  }
                  this.isbtndisabled = false;
                },
                (error) => {
                  this.noData = true;
                  this.tableShow = true;

                  this.loader = false;
                  console.log('No Data');
                }
              );
          }
        }
      }
    }
    console.log('actionStatus::', this.actionStatus);

    if (
      this.projectStatus != undefined &&
      this.actionStatus != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined
    ) {
      this.isbtndisabled = true;
      this.loader = true;

      console.log('check 4 conditions  true ::');

      if (this.projectStatus == 'non-project') {
        console.log('project Staus', this.projectStatus);
        console.log('non-project');
        console.log('list value id::', this.listtypevalueId);
        this.loader = true;
        this.actionItemList = [];
        this.gs
          .get_actionItemByNonProject(
            this.startDate,
            this.endDate,
            this.actionStatus
          )
          .subscribe(
            (d) => {
              this.noData = false;
              this.loader = false;
              this.formsubmitted = true;
              this.tableShow = true;

              this.actionItemList = d;
              this.actionItemList = Array.isArray(d) ? d : [];
              this.isbtndisabled = true;
              this.loader = false;

              console.log(' selected actionItemList::', this.actionItemList);
              if (this.actionItemList.description == 'No Data is Available') {
                this.noData = true;
                this.isbtndisabled = false;
                this.tableShow = true;

                this.loader = false;
              }
              this.isbtndisabled = false;
            },
            (error) => {
              this.actionItemList.length = 0;
              if (error.errorCode == 404) {
                this.noData = true;
                this.loader = false;
                this.tableShow = true;

                this.isbtndisabled = false;

                console.log('No Data');
              }
            }
          );
      } else {
        this.loader = true;
        this.actionItemList = [];
        this.gs
          .getStartEndActionItemList(
            this.startDate,
            this.endDate,
            this.actionStatus,
            this.projectStatus
          )
          .subscribe(
            (response) => {
              this.isbtndisabled = true;
              this.loader = false;
              console.log(response);
              this.tableShow = true;

              this.noData = false;
              this.actionItemList = response;
              this.isbtndisabled = true;
              this.loader = false;

              console.log(
                'this.actionItemList response Dates::',
                this.actionItemList
              );
              // if(this.actionItemList.length == 0 || this.actionItemList.errorCode == 204){
              //   this.noData = true;
              // }
              if (this.actionItemList.message == 'No Data is Available') {
                this.noData = true;
                this.isbtndisabled = false;
                this.loader = false;
                this.tableShow = true;
              }
              this.isbtndisabled = false;
            },
            (error) => {
              this.actionItemList.length = 0;
              if (error.errorCode == 404) {
                this.noData = true;
                this.tableShow = true;

                this.loader = false;
                console.log('No Data');
              }
            }
          );
      }
    }
    //only start date and end date below//
    else if (
      this.startDate !== undefined &&
      this.endDate !== undefined &&
      this.actionStatus == undefined &&
      this.projectStatus == undefined
    ) {
      this.isbtndisabled = true;
      this.loader = true;
      this.actionItemList = [];
      this.gs.actionStartendDate(this.startDate, this.endDate).subscribe(
        (response) => {
          console.log('response::', response);
          this.noData = false;
          this.loader = false;
          // this.actionItemList = response;
          this.actionItemList = Array.isArray(response) ? response : [];
          this.isbtndisabled = true;
          this.tableShow = true;

          this.loader = false;

          console.log(
            'this.actionItemList response Date onlyy::',
            this.actionItemList
          );
          if (this.actionItemList.message == 'No Data is Available') {
            this.noData = true;
            this.isbtndisabled = false;
            this.loader = false;
            this.tableShow = true;
          }
          this.isbtndisabled = false;
        },
        (error) => {
          console.error('Error occurred:', error);
          this.actionItemList = []; // Clear the actionItemList
          if (error.errorCode === 404) {
            this.noData = true;
            this.loader = false;
            this.tableShow = true;

            console.log('No Data');
          }
        }
      );
    }

    //only project selection below//
    else if (
      this.startDate == undefined &&
      this.endDate == undefined &&
      this.actionStatus == undefined &&
      this.projectStatus !== undefined
    ) {
      if (this.projectStatus == 'non-project') {
        this.isbtndisabled = true;
        this.actionItemList = [];
        this.loader = true;
        this.gs.onlyNonProjectselection().subscribe(
          (response) => {
            console.log('response::', response);
            this.noData = false;
            this.actionItemList = response;
            this.isbtndisabled = true;
            this.loader = false;
            this.tableShow = true;

            console.log(
              'this.actionItemList response Dates::',
              this.actionItemList
            );

            if (this.actionItemList.description == 'No Data is Available') {
              this.noData = true;
              this.isbtndisabled = false;
              this.loader = false;
              this.tableShow = true;
            }
            this.isbtndisabled = false;
          },
          (error) => {
            this.noData = true;
            this.loader = false;
            this.tableShow = true;

            console.log('No Data');
          }
        );
      } else {
        if (
          this.startDate == undefined &&
          this.endDate == undefined &&
          this.actionStatus == undefined &&
          this.projectStatus !== undefined
        ) {
          this.loader = true;

          if (this.startDate == null && this.endDate == null) {
            this.actionItemList = [];

            this.gs.onlyProjectselection(this.projectStatus).subscribe(
              (response: any) => {
                // if(response.statusCode==200){
                this.isbtndisabled = true;
                this.loader = false;
                console.log('response::', response);
                this.noData = false;
                this.actionItemList = response;
                this.isbtndisabled = true;
                this.loader = false;
                this.tableShow = true;

                console.log(
                  'this.actionItemList response Dates::',
                  this.actionItemList
                );
                // }
                // if(this.actionItemList.length == 0 || this.actionItemList.statusCode == 201){
                //   this.noData = true;
                // }
                if (this.actionItemList.message == 'No Data is Available') {
                  this.noData = true;
                  this.isbtndisabled = false;
                  this.loader = false;
                  this.tableShow = true;
                }

                this.isbtndisabled = false;
              },
              (error) => {
                this.actionItemList.length = 0;
                if (error.errorCode == 404) {
                  this.noData = true;
                  this.tableShow = true;

                  this.loader = false;
                  console.log('No Data');
                }
              }
            );
          }
        }
      }
    }

    //project and action status//
    else if (
      this.startDate == undefined ||
      (this.startDate != null && this.endDate == undefined) ||
      (this.endDate != null &&
        this.actionStatus !== undefined &&
        this.projectStatus !== undefined)
    ) {
      if (this.projectStatus == 'non-project') {
        this.actionItemList = [];
        this.isbtndisabled = true;
        this.loader = true;
        this.gs.nonprojectActionList(this.actionStatus).subscribe(
          (response) => {
            console.log('response for action status and project::', response);
            this.noData = false;
            this.actionItemList = response;
            this.isbtndisabled = true;
            this.loader = false;
            this.tableShow = true;

            console.log(
              'this.actionItemList response Dates::',
              this.actionItemList
            );

            // if(this.actionItemList.length == 0 || this.actionItemList.errorCode == 204){
            //   this.noData = true;
            // }
            // if(this.actionItemList.statusCode == 201){
            //   this.noData = true;
            // }
            if (this.actionItemList.description === 'No Data is Available') {
              this.noData = true;
              this.isbtndisabled = false;
              this.loader = false;
              this.tableShow = true;
            }
            this.isbtndisabled = false;
          },
          (error) => {
            this.actionItemList.length = 0;
            if (error.errorCode == 404) {
              this.noData = true;
              this.tableShow = true;

              this.loader = false;
              console.log('No Data');
            }
          }
        );
      } else {
        if (
          this.startDate == undefined &&
          this.endDate == undefined &&
          this.actionStatus !== undefined &&
          this.projectStatus !== undefined
        ) {
          this.loader = true;
          this.actionItemList = [];
          this.gs
            .projectStatusList(this.actionStatus, this.projectStatus)
            .subscribe((response) => {
              this.isbtndisabled = true;
              this.loader = true;

              this.gs
                .projectStatusList(this.actionStatus, this.projectStatus)
                .subscribe(
                  (response) => {
                    console.log('response project status::', response);
                    this.noData = false;
                    this.loader = false;
                    this.actionItemList = response;
                    this.isbtndisabled = true;
                    this.loader = false;
                    this.tableShow = true;

                    console.log(
                      'this.actionItemList response Dates::',
                      this.actionItemList
                    );

                    // if(this.actionItemList.length == 0 || this.actionItemList.errorCode == 204){
                    //   this.noData = true;
                    // }
                    if (this.actionItemList.message == 'No Data is Available') {
                      this.noData = true;
                      this.isbtndisabled = false;
                      this.loader = false;
                      this.tableShow = true;
                    }
                    this.isbtndisabled = false;
                  },
                  (error) => {
                    // this.actionItemList.length=0
                    if (error.errorCode == 404) {
                      this.noData = true;
                      this.tableShow = true;

                      this.loader = false;
                      console.log('No Data');
                    }
                  }
                );
            });
        }

        //only Actionstatus//
        else if (
          this.startDate == null &&
          this.endDate == null &&
          this.actionStatus !== undefined &&
          this.projectStatus == undefined
        ) {
          // if(this,this.projectStatus=='non-project'){
          this.actionItemList = [];
          this.isbtndisabled = true;
          this.loader = true;
          this.gs.onlyActionsStatus(this.actionStatus).subscribe(
            (response: any) => {
              // this.noData=false;
              this.loader = false;
              this.actionItemList = response;
              this.isbtndisabled = true;
              this.loader = false;
              this.tableShow = true;

              // console.log("this.actionItemList response Dates::",this.actionItemList);

              // if(this.actionItemList.length == 0 || this.actionItemList.errorCode == 204){
              //   this.noData = true;
              // }
              if (this.actionItemList.message == 'No Data is Available') {
                this.noData = true;
                this.isbtndisabled = false;
                this.loader = false;
                this.tableShow = true;
              }
              this.isbtndisabled = false;

              // }
            },
            (error) => {
              this.noData = true;
              this.loader = false;
              this.tableShow = true;

              console.log(error.errorCode);
              if (error.errorCode == 204) {
                console.log('no data ');
              }
            }
          );
        }

        //by selecting the actionstatus,start end date//

        //project and start,end date
        else if (
          this.startDate != undefined &&
          this.endDate != undefined &&
          this.actionStatus == undefined &&
          this.projectStatus != undefined
        ) {
          console.log('enter to the project and dates');

          if (this.projectStatus == 'non-project') {
            this.actionItemList = [];
            this.isbtndisabled = true;
            this.loader = true;
            this.gs
              .nonProjectStartEndDate(this.startDate, this.endDate)
              .subscribe(
                (response) => {
                  console.log('response', response);
                  this.noData = false;
                  this.loader = false;
                  this.actionItemList = response;
                  this.isbtndisabled = true;
                  this.loader = false;
                  this.tableShow = true;

                  console.log(
                    'this.actionItemList response Dates::',
                    this.actionItemList
                  );
                  // if(this.actionItemList.length == 0 || this.actionItemList.errorCode == 204){
                  //   this.noData = true;
                  // }
                  if (
                    this.actionItemList.description == 'No Data is Available'
                  ) {
                    this.noData = true;
                    this.isbtndisabled = false;
                    this.loader = false;
                    this.tableShow = true;
                  }
                  this.isbtndisabled = false;
                },
                (error) => {
                  this.actionItemList.length = 0;
                  if (error.errorCode == 404) {
                    this.noData = true;
                    this.tableShow = true;

                    this.loader = false;
                    console.log('No Data');
                  }
                }
              );
          } else {
            this.loader = true;
            this.actionItemList = [];
            this.gs
              .ProjectStartEndDate(
                this.startDate,
                this.endDate,
                this.projectStatus
              )
              .subscribe(
                (response) => {
                  this.isbtndisabled = false;
                  this.loader = true;
                  console.log('response', response);
                  this.noData = false;
                  this.loader = false;
                  this.actionItemList = response;
                  this.isbtndisabled = true;
                  this.loader = false;
                  this.tableShow = true;

                  console.log(
                    'this.actionItemList response Dates::',
                    this.actionItemList
                  );
                  if (this.actionItemList.message == 'No Data is Available') {
                    this.noData = true;
                    this.isbtndisabled = false;
                    this.loader = false;
                    this.tableShow = true;
                  }
                  this.isbtndisabled = false;
                },
                (error) => {
                  this.noData = true;
                  this.loader = false;
                  this.tableShow = true;

                  console.log('No Data');
                }
              );
          }
        } else {
          console.log('all conditions came out!!!');
          // this.errorMsg=true;
        }

        this.showChanges();
      }
    }
  }

  action(e) {
    console.log(e);

    const dialogbox = this.dialog.open(UpdateactionComponent, {
      data: e,
      width: '500px',
    });
    // dialogbox.afterClosed().subscribe((response)=>{
    //   console.log("response::",response);

    //   console.log("dialogue entered here!!! ooutside");

    // if(this.success==true){
    //   console.log("check entered or not in if!!");

    //  this.actionitem.setAddTaskSuccessUpdateMessage('Action Item Report Updated Successfully!');
    // this.success = true;

    // setTimeout(()=>{
    //   this.actionitem.setAddTaskSuccessUpdateMessage('Action Item Report Updated Successfully!');

    // },2000)

    // }
    // else{
    //   this.success=false
    // }

    // this.actionitem.setAddTaskSuccessMessage('Action Item Report updated Successfully');

    // console.log(response);

    // if(response){
    //   this.getListItems()
    //   if(response.statusCode==200){
    //     this.sucess=true
    //     this.sucess_msg=response.message

    //     setTimeout(()=>{
    //       this.getListItems()
    //       this.sucess=false
    //     },2000)
    //   }else{
    //     this.error = true;
    //      this.update_error_msg = response.message;
    //      setTimeout(() => {this.error = false}, 2000)
    //  }
    // else{
    //   console.log("something worngg");
    //   alert("not upadetedd!...")

    // dialogbox.close()
    // })

    // if(this.success==true){
    //   console.log("check entered or not in if!!");

    //   this.success = true;
    // this.actionitem.setAddTaskSuccessMessage('Task Added Successfully');

    // }
    // else{
    //   this.success=false
    // }

    dialogbox.afterClosed().subscribe((result) => {
      console.log('dialogue entered here!!! ooutside');

      if (result == 'cancel') {
        console.log('dialogue entered here!!!');

        return;
      }
      if (this.success == true) {
        this.success = true;
        this.actionitem.setAddTaskSuccessMessage(
          'Action Item Report Updated Successfully!'
        );
      } else {
        this.success = false;
      }

      // this.sucess_msg = a.message;
      dialogbox.close();
    });
  }
  changepage() {
    // console.log("Search");
    // console.log(this.actionItemList.length);
    // if(this.search.length!= 0)
    // {
    //    this.p = 1;
    //    console.log(this.actionItemList.length);

    //     console.log(this.p);
    //     console.log("success Data");
    // }
    // else{
    //    this.p = this.beforesearchpage;
    //   // this.nodata = true;
    //   console.log(this.actionItemList.length);
    //   console.log("nodata");
    // }
    console.log(this.actionItemList.length);
    if (this.search.length != 0) {
      this.p = 1;
      console.log(this.actionItemList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
      console.log(this.p);
      console.log('success Data');
    } else {
      this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.actionItemList.length);
      console.log('nodata');
    }
  }

  sortData(sort: Sort) {
    const data = this.actionItemList.slice();
    if (!sort.active || sort.direction === '') {
      this.actionItemList = data;
      return;
    }

    this.actionItemList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'actionItemName':
          return this.compare(a.actionItemName, b.actionItemName, isAsc);
        case 'actionItemTargetDate':
          return this.compare(
            a.actionItemTargetDate,
            b.actionItemTargetDate,
            isAsc
          );
        case 'actionItemOwner':
          return this.compare(a.actionItemOwner, b.actionItemOwner, isAsc);
        case 'projectName':
          return this.compare(
            a.mom.project.projectName,
            b.mom.project.projectName,
            isAsc
          );
        case 'actionItemStatus':
          return this.compare(a.actionItemStatus, b.actionItemStatus, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  showChanges() {
    console.log('show Change');
    this.p = 1;
  }


  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML);
    
  
    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    console.log(pdfTable.innerHTML);
    
  
    const documentDefinition = {
      content: html,
      pageMargins: [25, 40, 25, 40],
      info: {
        title: `Action Item Report`
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);
  
        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download("Action Item Report");
  }
}
