import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from '../providers/payroll.service';
import { SettingsService } from '../providers/settings.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import { SalaryAdvanceDetailsComponent } from '../salary-advance-details/salary-advance-details.component';
import { MatDialog } from '@angular/material/dialog';
import { AdvanceApproverStatusComponent } from '../advance-approver-status/advance-approver-status.component';
import { Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-salary-advance-list',
  templateUrl: './salary-advance-list.component.html',
  styleUrls: ['./salary-advance-list.component.scss']
})
export class SalaryAdvanceListComponent implements OnInit {
  p: number = 1;
  pagePerItem:any=10;
  filterForm:FormGroup;
  employee: string;
  organzationId: any;
  empList: any = [];
  finYearList: any =[];
  finPeriodList: any=[];
  submitted: boolean=false;
  noRecordsFound: boolean =false;
  userdata: any;
  financialyearError: boolean=false;
  salaryAdvancedata: any=[];
  empployeeList: any=[];
  employeelistsub: any;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public payrollService:PayrollService,
    public fb:FormBuilder,
    private settingsService: SettingsService,
    private gs:GlobalserviceService,
    private dialog:MatDialog
    ) { }

  ngOnInit(): void 
  {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =  this.userdata.organization.organizationId;
    console.log("organziationid " + this.organzationId);
    this.noRecordsFound = true;
    this.settingsService.getFinicalyearList( this.organzationId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });    
    this.gs.getAllEmployeeByOrganization(this.organzationId).subscribe(
      (resultData: any) => {
      console.log(resultData);
      this.empployeeList = resultData;
    });
    this.filterForm=this.fb.group({
      // employee : [null,Validators.required],
      financialyear: [null,Validators.required],
      period:[null],
    });
  }
  searchSubmit()
  {
    this.submitted=true;
    console.log(this.filterForm.value);
    console.log(this.filterForm);
    if(this.filterForm.invalid){
      return
    }
    if(this.filterForm.value.financialyear == 'null'){
      this.financialyearError=true;
      return
    }
    if(this.filterForm.value.financialyear != null && (this.filterForm.value.period == null || this.filterForm.value.period == 'null')){
      console.log("only financial year");
      this.gs.getlistByFinancialyear(this.filterForm.value.financialyear, this.userdata.employee.employeeId).subscribe(
        (data:any)=>{
          console.log(data);
          this.salaryAdvancedata=data;
          if(this.salaryAdvancedata.length !=0){
            this.noRecordsFound=false;
          }
          if(this.salaryAdvancedata.statusCode ==204){
            console.log("1111");
            this.noRecordsFound=true;
          }
        }
      )
    }
    else{
      console.log("both");
      this.gs.getlistByFinancialyearandperiod(this.filterForm.value.period,this.filterForm.value.financialyear, this.userdata.employee.employeeId).subscribe(
        (data:any)=>{
          console.log(data);
          this.salaryAdvancedata=data;
          if(this.salaryAdvancedata.length !=0){
            this.noRecordsFound=false;
          }
          if(this.salaryAdvancedata.statusCode ==204){
            console.log("222");
            this.noRecordsFound=true;
          }
        }
      )
    }
  }
  openDialog(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(SalaryAdvanceDetailsComponent,{
      width: '700px',
      height:'fit-content',
      data :approvaldata
    })
  }  
  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(AdvanceApproverStatusComponent,{
      width: '500px',
      height:'fit-content',
      data :approvaldata
    })
  }
  get f() {
    return this.filterForm.controls;
  }
  empSearch(event){ 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    if(employeenumber.length >= 2)
    {
      if(this.employeelistsub)
      {
        this.employeelistsub.unsubscribe();
      }
      this.employeelistsub = this.payrollService.getEmployeeNameList(employeenumber,this.organzationId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.payrollService.getEmployeeNameList(employeenumber,this.organzationId))
        )
        .subscribe((result: any) => {
          this.empList.length = 0;

          if (result.statusCode) {
            console.log(result.statusCode);
            this.empList.length = 0;
          } else {
            this.empList = result;
          }
        });
	}
  }
  empSelect(emp) {
    console.log(emp);
    this.employee=emp.employeeNumber+"-"+emp.firstName;
      this.filterForm.patchValue({
        employee: this.employee 
     });
  }
  selectFinancialyear(event){
    console.log(event.target.value);
    this.filterForm.value.period = null;
    this.settingsService.getFinancialYearId(event.target.value).subscribe(
      (resultData: any) => {
        console.log("PeriodData", resultData);
        this.finPeriodList = resultData.finanicalPeriod;
        this.financialyearError=false;
      }
    );
  }
  selectemployee(event){
    console.log(event.target.value);
  }
}
