import { Component, OnInit } from '@angular/core';
import { GlobalserviceService } from '../providers/globalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { AdminService } from '../providers/admin.service';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit {

  submitted: boolean = false;
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  businessgroup: FormGroup;
  resultData: any;
  branchId: any;

  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;
  employeetData: any;
  isbtnDisable: boolean = false;
  businessId: any;
  tableShow: boolean = false;
  supplierList:any[] = [];
  beforepage: any;
  mySelect: any = 5;
  beforesearchpage: any;
  p: any;
  nodata: boolean = false;
searchForm: FormGroup;
  supplierValue: any;
btnDisabled: boolean=false;
  storeEntityId: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private gs: GlobalserviceService,
    private Adminservice:AdminService,
    private ActivatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);
    this.searchForm = this.formBuilder.group({
     
      supplierName: [
        '',
        [
          Validators.required,
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
     
    });
    // this.getAllsupplier();
  }

  getAllsupplier(){
    this.loader=true;
    this.tableShow=false;
    this.Adminservice.getAllSupplier(this.storeEntityId).subscribe((response:any)=>{
      this.supplierList=response;
      this.tableShow=true;
      this.loader=false;
      this.nodata=false;


      if(response.statusCode==204){
        this.nodata=true;
        this.tableShow=true;
        this.supplierList=[]
      }

    })
  }

 updateStatus(id,status)
    {
      this.Adminservice.updateSupplierStatus(id,status).subscribe((result:any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
          const updatedRow = this.supplierList?.find((row) => row.supplierId === id);
          if (updatedRow) {
            updatedRow.supplierStatus = status; // Update the status in the current data
          }
          setTimeout(() => {this.success  = false;},5000)
  
          // this.getAllsupplier();
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, 5000)
        }
      })
    }
  changefirstpage() {
    this.p = 1;
  }
  sortData(sort: Sort) {
    const data = this.supplierList.slice();
    if (!sort.active || sort.direction === '') {
      this.supplierList = data;
      return;
    }

    this.supplierList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'supplierCode':
          return this.compare(
            a.supplierCode,
            b.supplierCodee,
            isAsc
          );
        case 'supplierName':
          return this.compare(
            a.supplierName,
            b.supplierName,
            isAsc
          );
        case 'country':
          return this.compare(a.country,  b.country,isAsc);
        case 'address':
          return this.compare(
            a.addressLine1,
            b.addressLine1,
            isAsc
          );
       
        case 'supplierDescription':
          return this.compare(a.supplierDescription, b.supplierDescription, isAsc);
        case 'supplierStatus':
          return this.compare(a.supplierStatus, b.supplierStatus, isAsc);
        
        // return this.compare(a.assignedhours, b.assignedhours, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  get validate() {
    return this.businessgroup.controls;
  }
  
  clear(){
    console.log("clar fun came!!");
    
    this.searchForm.reset();
    this.nodata=false;
    this.tableShow=false;
    // this.Adminservice.getAllSupplier(this.storeEntityId).subscribe((response:any)=>{
    //   this.supplierList=response;
    //   this.tableShow=true;
    //   // this.loader=false;
    //   // this.nodata=false;


    //   // if(response.statusCode==204){
    //   //   this.nodata=true;
    //   //   this.tableShow=true;
    //   //   this.supplierList=[]
    //   // }

    // })

  }
  clearset(event:any){
    // this.getAllsupplier();
    // this.supplierList=[]
  
  const inputValue=event.target.value;
    // if (inputValue.length== 1 && this.supplierList.length==0) {
    //   console.log("Input is empty, fetching all suppliers...");
    //   this.Adminservice.getAllSupplier(this.storeEntityId).subscribe((response:any)=>{
    //     this.supplierList=response;
    //     this.tableShow=true;
    //     this.loader=false;
    //     this.nodata=false;
  
  
        
  
    //   })    }

  }
  searchSubmit(){
   this.tableShow=false;
    console.log("serach!!!");

    if(this.searchForm.invalid){
      alert("Please Type Keywods in Supplier to Search!!")
      return;
    }
    

    this.supplierValue=this.searchForm.controls['supplierName'].value;
    console.log("this.supplierValue:",this.supplierValue);
    

    if (this.supplierValue==undefined||this.supplierValue==''){
      alert("Please Type Keywods in Supplier to Search!!")
      return;
    }
    if(this.supplierValue!==undefined||this.supplierValue!==''){
     
     console.log("supplier calling!!");
     this.btnDisabled=true;

     this.Adminservice.getSupplierList(this.supplierValue,this.storeEntityId).subscribe((response:any)=>{
      console.log("response::",response);
      
      this.supplierList=response;
      this.btnDisabled=false;
      this.nodata=false;
      this.tableShow=true;


      if(response.statusCode==204){
        this.nodata=true;
        this.tableShow=true;

        this.btnDisabled=false;
        this.supplierList=[];
        console.log("no data");
        
      }



     })
     
    }
  }
  
}
