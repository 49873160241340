
<form [formGroup]="importForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title class="addTask">Employee Recovery Adjustment</h3>
    <!-- <div class="row">
        <div class="form-group col-md-10">
             <label class="col-form-label">Client<span class="star">*</span></label>
             <select formControlName="client" class="form-select form-select-solid form-select-lg select2-new" >
                <option [ngValue]="null"> -- Select Client --- </option>
                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
            </select>
             <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                 <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
             </div>
         </div>
     </div> -->
    <div class="row">
       <div class="col-md-10">
        <label class="file-label">
            <div class="row">
                <div class="col-md-1">
                    <mat-icon class="material-symbols-outlined">
                        attach_file_add
                    </mat-icon>
                </div>
                <div class="col-md-10">
                    <span>{{  fileInputLabel ||  'Attach File' }}</span>
                </div>
            </div>
         
             
            <input type="file"                                                        
            formControlName="file"
            placeholder="Enter description"  [ngClass]="{ 'is-invalid': submitted }"
            class="file-input"  (change)="onFileSelect($event)">
          </label>
            <div class="valid-msg" *ngIf="(validate.file.invalid && validate.file.touched) || validate.file.dirty || submitted">
                <span *ngIf="validate.file.errors?.required" class="text-danger">Please select .xls / xlsx file</span>
                <span *ngIf="validate.file.errors?.pattern" class="text-danger">Please enter valid .xls / xlsx file</span>
            </div>
           <div>
            <span style=" margin-bottom: 10px;" class="text-muted import-note ">Note : Upload format is .xls / xlsx.</span>
           </div>
		</div>
        
    </div>

    <div class="row">
        <div class="col-md-12">
            <span class="text-muted">
                *** Please download the sample file and import the data as mentioned format. ***
            </span>  
            <a class="download-sample text-danger" href="assets/backend/sample_documents/EmployeeRecoveryAdjustment.xlsx" download="recovery_adjustment_import.xlsx">
                Download Sample
            </a> 
        </div>
    </div>

    <mat-dialog-actions align="end">
        <!-- <button type="button"  style="color:var(--Red-Color, #FF5D5D);" mat-button mat-dialog-close>Cancel</button> -->
        <button
        mat-stroked-button
        type="button"
        mat-dialog-close
        style="color:var(--Red-Color, #FF5D5D);"
    
      >
        Cancel
      </button>
      <!--<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button>
    --> <button type="submit" mat-raised-button class="searchBtn">Upload</button>
    </mat-dialog-actions>
</form>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>


