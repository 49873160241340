<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">NetPay Report</span>
                </div>
                <!-- <div class="sub-header-buttons">
                    <div class="" style="position: relative;top: 6px;">
                        <a routerLink="employee" mat-raised-button title="Export" (click)="downloadAsExcel()" class="">Export</a>
                    </div>
                    <div class="">
                        <a title="Download PDF" target="_blank" class="btn text-danger">   
                            <i class="fa fa-file-pdf-o" (click)="printPdf()"></i>
                        </a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <!-- <input formControlName="employee" type="search" autocomplete="off" class="form-select form-select-solid form-select-lg select2-new" [value]=null placeholder="Search..."> -->
                            <label class="form-control-label" style="font-family:Mulish; font-size:16px;">Employee</label>
                            <input type="text" matInput  placeholder="Search Employee"   
                                formControlName="employeecode"
                                [matAutocomplete]="auto" 
                                class="example-input form-control" (keyup)="empSearch($event)">
                            <mat-autocomplete #auto="matAutocomplete">
                                <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                    </mat-option> -->
                                <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                {{emp.employeeNumber}}- {{emp.firstName}}  
                                </mat-option>
                                <mat-option [value]="null" *ngIf="empCode && empList.length === 0" (click)="noEmployeeFound()">No
                                    Employee Found</mat-option>
                            </mat-autocomplete>
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                search
                            </mat-icon>
                            <!-- <small class="text-muted">Note : Employee Number</small> -->
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label" style="font-family:Mulish; font-size:16px;">Financial Year<span class="star"> *</span></label> 
                            <mat-select formControlName="financialyear" placeholder="Select Financialyear" class="form-control email-input select-placeholder form-select-solid form-select-lg select2-new" (selectionChange)="selectFinancialyear()" >
                                <mat-option [value]=null> -Select Financialyear- </mat-option>
                                <mat-option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</mat-option>  
                            </mat-select>
                        </div>
                        <div class="form-group col-md-3 ">
                            <label class="form-control-label" style="font-family:Mulish; font-size:16px;">Financial Period<span class="star"> *</span></label> 
                            <mat-select formControlName="period" placeholder="Select Financial Period"  class="form-control email-input select-placeholder form-select-solid form-select-lg select2-new"  >
                                <mat-option [value]=null> -Select Financial Period- </mat-option>
                                <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option> 
                            </mat-select>
                        </div>
                             
                        <div class="col-md-3" style="margin-top: 24px;" >
                            <button type="submit" mat-raised-button  [disabled]="isProcessing"
                                class=" searchBtn">Search</button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                class="btn mr-2 search-btn" (click)="clearTable()"
                                >Clear</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                            <button  class="but mb-2" type="button" style="color: white;"   (click)="downloadAsExcel();">
                                <mat-icon class="material-symbols-outlined">file_download</mat-icon> Download Excel
                            </button>
                          
                        </div>
                        <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                            <button  class="but ml-2 mb-2" type="button"  style="color: white;"  (click)="printPdf();" >
                                <mat-icon class="material-symbols-outlined" >print</mat-icon> Download PDF
                            </button>
                        </div>
                    </div>
                </form>
                <!-- <div class="row pb-3">
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive">
                    <table style="width: 140%;" class="table  --table-bordered table-border-new">
                        <thead class="t-head">
                            <tr >
                                <th>Period</th>
                                <th>Emp Code</th>                              
                                <th>Emp Name</th>
                                <th>Net Pay</th>
                                <th>Bank</th>
                                <th>Branch</th>
                                <th>ACC No</th>
                                <th>IFSC</th>
                                <th>UAN</th>
                                <th>ESI No</th>
                                
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData.length>0">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td style="white-space: nowrap;">{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>                    
                                <td>{{row.employee.employeeNumber}}</td>                        
                                <td>{{row.employee.firstName}}</td>
                                <td>{{Roundof(row.totalAmount)}}</td>
                                <td>{{row.employee.employeeInfo.bankName ?row.employee.employeeInfo.bankName  : "" }}</td>
                                <td>{{row.employee.employeeInfo.bankBranchName ?row.employee.employeeInfo.bankBranchName  : "" }}</td>
                                <td>{{row.employee.employeeInfo.bankAccountNo ?row.employee.employeeInfo.bankAccountNo  : "" }}</td>
                                <td>{{row.employee.employeeInfo.ifscCode ?row.employee.employeeInfo.ifscCode  : "" }}</td>                 
                                <td>{{row.employee.uanNumber? row.employee.uanNumber : ""}}</td>
                                <td>{{row.employee.esiNumber? row.employee.esiNumber : ""}}</td>
                            </tr>
                            <tr>
                                <td style="text-align: right;" colspan="3"><b>Total</b></td> 
                                <td ><b>{{totalnetpay}}</b></td>
                                <td colspan="6"></td> 
                            </tr>
                        </tbody>
                    </table>
                   
                     
                    
                 </div> -->
                 <!-- <div *ngIf="noRecordsFound">
                    <p style="text-align: center;" [attr.colspan]="resultData.length">No Records Found!</p>
                </div>  -->
                <!-- <div *ngIf="noRecordsFound == false">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" style="height:3rem;width:3rem" role="status"></div>
</div>

<div  id="pdfTable" #pdfTable style="display: none;">   
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table-class" data-pdfmake="{'widths':['*','*'],'heights':10}">
                                <thead>
                                    <tr>
                                        <td class="text-center" width="40%">
                                            <img [src]="base64data" style="height:65px;width:180px;">
                                        </td>
                                        <td class="float-left" width="80%">
                                            <p style="font-size:18px;font-weight:600;text-align:left"><b style="text-align:left">{{ this.userData.organization.organizationName}}</b></p>
                                            <span style="padding-top:0px;float:left;width:100%;">{{ this.userData.organization.address}}</span><br>
                                        </td>
                                    </tr>
                                </thead>
                            </table> 
                                
                            <table    class="table-class"  data-pdfmake="{'widths':['*'],'heights':10}">
                                <thead>
                                    <tr>
                                        <td  style="text-align:center;font-size:14px;padding:10px;">
                                            <b>Net Pay Report </b>
                                            <br><br>
                                             <span style="font-weight:500">STATEMENT FOR THE PERIOD OF  {{period}}</span>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                         
                            <table class="table items table-bordered table-condensed table-hover" style="width: 100%;font-size: 14px;" 
                            data-pdfmake="{'dontBreakRows': true , 'widths':['auto','*','*','*','*','*'],'headerRows': 1}">
                            <thead>
                                <tr>
                                <th style="border:1px solid #ccc;" class="" colspan="1"  >SN</th>
                                <th style="border:1px solid #ccc;">Period</th>
                                <!-- <th style="border:1px solid #ccc;">Emp Code</th>                               -->
                                <th style="border:1px solid #ccc;">Emp Name</th>
                                <th style="border:1px solid #ccc;">Net Pay</th>
                                <th style="border:1px solid #ccc;">Bank</th>
                                <!-- <th style="border:1px solid #ccc;">Branch</th> -->
                                <th style="border:1px solid #ccc;">ACC No</th>
                                <!-- <th style="border:1px solid #ccc;">IFSC</th>
                                <th style="border:1px solid #ccc;">UAN</th>
                                <th style="border:1px solid #ccc;">ESI No</th> -->
                                </tr>
                            </thead>
                                <tbody *ngIf="resultData.length>0">
                                    <tr *ngFor="let row of resultData  let i=index">
                                        <td style="border:1px solid #ccc;text-align: center;line-height: 1.5;">{{i+1}}</td>
                                        <td style="white-space: nowrap;border:1px solid #ccc;">{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>                    
                                        <!-- <td style="border:1px solid #ccc;">{{row.employee.employeeNumber}}</td>                         -->
                                        <td style="border:1px solid #ccc;">{{row.employee.firstName}}</td>
                                        <td style="border:1px solid #ccc;">{{row.totalAmount}}</td>
                                        <td style="border:1px solid #ccc;">{{row.employee.employeeInfo?.bankName ?row.employee.employeeInfo?.bankName  : "" }}</td>
                                        <!-- <td style="border:1px solid #ccc;">{{row.employee.employeeInfo.bankBranchName ?row.employee.employeeInfo.bankBranchName  : "" }}</td> -->
                                        <td style="border:1px solid #ccc;">{{row.employee.employeeInfo?.bankAccountNo ?row.employee.employeeInfo?.bankAccountNo  : "" }}</td>
                                        <!-- <td style="border:1px solid #ccc;">{{row.employee.employeeInfo.ifscCode ?row.employee.employeeInfo.ifscCode  : "" }}</td>                 
                                        <td style="border:1px solid #ccc;">{{row.employee.uanNumber? row.employee.uanNumber : ""}}</td>
                                        <td style="border:1px solid #ccc;">{{row.employee.esiNumber? row.employee.esiNumber : ""}}</td>                     -->
                                        <!-- <td>{{row.employee.employeeInfo.}}</td> -->
                                    </tr>
                                    <tr>
                                        <!-- <td style="border:1px solid #ccc;" colspan="3"></td>  -->
                                        <td style="border:1px solid #ccc;text-align: center;" colspan="3"><b>Total</b></td> 
                                        <td style="border:1px solid #ccc;" ><b>{{totalnetpay}}</b></td>
                                        <td style="border:1px solid #ccc;" colspan="2"></td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div style="position: relative;top:110px;margin:15px" class="card --card-content-nodata --mat-card-nodata" *ngIf="noRecordsFound">
    <div class="card-body-nodata">
      <div class="alert-nodata --alert-primary">
        <h4 align="center" class="h4" style=" padding:10px;  margin-top: 14px;
        font-size: 18px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 11px;
        ">No NetPay Report Found</h4>
      </div>
    </div>
</div>