<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">
                     View Candidate
                  </span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <!--  <a routerLink="/home/resource-list" routerActive="active" mat-raised-button title="Back" class="ml-2">
                      Back
                    </a> -->
                    <!-- <a (click)="backbtn()" title="Back" class="btn btn-light btn-sm ml-2">
                        Back
                     </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-5"><label class="form-control-label">Candidate Name</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.resourceName}}</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Candidate Number</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">
                                <span *ngIf="resourceobj.resourceNumber != undefined"> {{resourceobj.resourceNumber}} </span>
                                <span *ngIf="resourceobj.resourceNumber == undefined"> -- </span>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Position</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.position.positionName}} - {{resourceobj.position.positionNumber}}</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Interview Date</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.interviewDate.slice(0,10)}}</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Total Experience</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.totalExperience}} Years</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Relevant Experience</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.relevantExperience}} Years</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Notice Period</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.noticePeriod.listTypeValueName}}</div>
                        </div>
                    </div>

                    <div class="col-md-6">

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Current CTC</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.currentCtc}}</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Expected CTC</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.expectedCtc}}</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Job Location</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.jobLocation}}</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Candidate Availability</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">

                                <div *ngFor="let resourceobj of resourceobj.resourceAvailability">
                                    <label class="form-control-label-class">
                                            Date : {{resourceobj.resourceAvailabilityDate.slice(0,10) }} <br>
                                            Time : {{resourceobj.resourceAvailabilityDate.substring(11,16)}}
                                        </label>
                                </div>


                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Interviewed</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.interviewed ? 'Yes' : 'No'}}</div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Interview Status</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">
                                {{resourceobj.interviewStatus.listTypeValueName}}
                                <!--  <span *ngIf="viewResultData.interviewStatus != undefined">{{viewResultData.interviewStatus.listTypeValueName}}</span>
                                    <span class="form-control-label-class" *ngIf="viewResultData.interviewStatus == undefined"> -- </span> -->
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5"><label class="form-control-label">Comments</label></div>
                            <div class="col-md-1">:</div>
                            <div class="col-md-6">{{resourceobj.comments}}</div>
                            <div>

                                <div class="row mt-2" *ngIf="resourceobj.resourcecvattachment != undefined">
                                    <div class="col-md-5"><label class="form-control-label">Comments</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6">
                                        <a class="file-download badge badge-secondary form-control-label" href="{{resourceobj.resourcecvattachment.fileDownloadUrl}}">
                                        Download
                                    </a>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="loader" class="align-items-center text-center">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
        </div>
