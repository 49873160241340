import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap, throwError } from 'rxjs';
import { httpOptions } from './properties';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService
{
  

  headers =  new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
});
  constructor(private http: HttpClient) { }

  /** Profiles Start here **/
  getUserList(id:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}user/organization/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getUserListKeyword(id:any,keyword:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}user/filter/keyword/${id}/${keyword}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  //user/employee/search/keyword/1/sath

  employeeSearch(id:any,keyword:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/search/keyword/${id}/${keyword}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  // employeeSearchNew(id:any): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.base_url}employee/${id}`,httpOptions).pipe(map(data=>data))
  //   return result;
  // }


 /*  updateUserStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.base_url}user/deactivate/${id}/${status}`,null,httpOptions).pipe(map(data=>data));
    return result;
  } */
  getSearchUserList(id:any,keywords:any,roleId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}user/filter/${roleId}/${keywords}/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  /* getUserListKeywords(id:any,keywords:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}user/filter/keyword/${keywords}/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  } */

  getUserListRole(id:any,roleId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}user/filter/role/${roleId}/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getUserById(id:any)
  {
    let result = this.http.get(`${environment.base_url}user/${id}`,httpOptions).pipe(map(data=>data));
    return result;
  }

  editUser(id:any,postValues:any)
  {
    let result = this.http.put(`${environment.base_url}user/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }

  editEmployeeProfile(id:any,postValues:any)
  {
    let result = this.http.put(`${environment.base_url}employee/profile/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }

  updateUserStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.base_url}user/deactive/${id}/${status}`,null,httpOptions).pipe(map(data=>data));
    return result;
  }

  /** Employee Start here **/
  getActiveEmployees(id): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/organization/active/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }
 

  createCheckExistemailId(postData: any)
  {
    let result = this.http.post(`${environment.base_url}user/create/emailId`,postData,httpOptions).pipe(map(data => data));
    return result;
  }
  updateCheckExistemailId(postData: any)
  {
    return this.http.post(`${environment.base_url}user/update/emailId`,postData,httpOptions).pipe(map(data => data));
  }

  createCheckExistemployeeId(postData: any)
  {
    let result = this.http.post(`${environment.base_url}user/create/employeeId`,postData,httpOptions).pipe(map(data => data));
    return result;
  }
  updateCheckExistemployeeId(postData: any)
  {
    return this.http.post(`${environment.base_url}user/update/employeeId`,postData,httpOptions).pipe(map(data => data));
  }
  createCheckExistCurrentPassword(postData: any)
  {
    let result = this.http.post(`${environment.base_url}user/currentpassword`,postData,httpOptions).pipe(map(data => data));
    return result;
  }

  updateChangePassword(postValues:any,id:any)
  {
    let result = this.http.patch(`${environment.base_url}user/resetpassword/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }
  /** Profiles End here **/


  /** Employee Start here **/
  getActiveRoles(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}role/active_role`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveBranch(orgId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}branch/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveDesignation(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}designation/active_designation`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveDepartment(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}department/active_department/1`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveCountry(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}country/active_country`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getOrganizationbyId(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}organization/1`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveState(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}state/active_state`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveDistrict(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}district/active_district`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveCity(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}city/active_city`,httpOptions).pipe(map(data=>data))
    return result;
  }

  createCheckExistEmployeeNumber(postData: any)
  {
    return this.http.post(`${environment.base_url}user/create/employeeNumber`,postData,httpOptions).pipe(map(data => data));
  }

  createCheckExistEmail(postData: any)
  {
    return this.http.post(`${environment.base_url}user/create/email`,postData,httpOptions).pipe(map(data => data));
  }

  createCheckExistAlternativeEmail(postData: any)
  {
    return this.http.post(`${environment.base_url}user/create/alternativeemail`,postData,httpOptions).pipe(map(data => data));
  }
  updateCheckExistAlternativeEmail(postData: any)
  {
    return this.http.post(`${environment.base_url}user/update/alternativeemail`,postData,httpOptions).pipe(map(data => data));
  }

  createCheckExistMobileNumber(postData: any)
  {
    return this.http.post(`${environment.base_url}user/create/phonenumber`,postData,httpOptions).pipe(map(data => data));
  }

  createUser(postValues: any): Observable<any>
  {
    let result = this.http.post(`${environment.base_url}user`,postValues,httpOptions).pipe(map(data=>data))
    return result;
  }

  editEmployee(postValues:any,id:any)
  {
    let result = this.http.put(`${environment.base_url}user/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }

  getuserImage(fileViewUrl: any)
  {
    let result = this.http.get(fileViewUrl, { responseType: 'blob',  headers: this.headers } ).pipe(switchMap(response => this.readFile(response)));
    return result;
  }

  getActiveStreams(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}stream/active_stream`,httpOptions).pipe(map(data=>data))
    return result;
  }
  /** Employee End here **/


  private readFile(blob: Blob): Observable<string>
  {
    return Observable.create(obs =>
      {
      const reader = new FileReader();
      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  uploadFile(postValues:any): Observable<any>
  {
    const ImporthttpOptions = {
        headers: new HttpHeaders({
            'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
        })
    }

    let result = this.http.post(`${environment.base_url}user/uploadfile`,postValues,ImporthttpOptions).pipe(map(data=>data))
    return result;
  }


  assignUserRole(userId: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}user/role/${userId}`,postValues,httpOptions).pipe(map(data=>data))
    return result;
  }
}
