import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
import { AddStateComponent } from '../add-state/add-state.component';
import { httpOptions,redirectMsgTimeOut,responseMsgTimeOut}  from '../../../providers/properties';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {

  public searchFilter: any = '';
  searchForm!:FormGroup;
  constructor(
    private settingsservice: SettingsService,
    private dialog:MatDialog,
  )
  { }

  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;

  loader:any= false;
  backdrop:any= false;
  nodatafound: boolean = true;
  sortedData: any[];

  ngOnInit(): void
  {
    //this.stateList();
    this.getFilterFormData();
  }

  getFilterFormData()
  {
    this.searchForm = new FormGroup(
      {
        keywords : new FormControl(this.searchForm)
      }
    );
  }

  searchSubmit()
  {
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;

    if(keywords == null)
    {
      alert('Please search keywords');
      return;
    }

    if(keywords)
    {
      
      this.loader=true;
      this.backdrop=true;

      this.settingsservice.getStateFilterList(keywords).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }

  }

  stateList()
  {
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;

    if(keywords)
    {
      this.loader=true;
      this.backdrop=true;

      this.settingsservice.getStateFilterList(keywords).subscribe((result: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = result;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
    else
    {
      this.loader = true;
      this.backdrop = true;

      this.settingsservice.getStateList().subscribe((result:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = result;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }

   /*  this.settingsservice.getStateList().subscribe((result: any) => {
      this.resultData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    }) */
  }

  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AddStateComponent, {
      //height: 'auto',width: '600px',
      width: '600px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      //this.stateList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData)
  {
    var dialogRef = this.dialog.open(AddStateComponent, {
      width: '500px',height:'fit-content',
      data:editData
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.stateList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }

  updateStatus(id,status)
	{
    this.settingsservice.updateStateStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.stateList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  /**** Global Variables *****/
  ucFirst(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };

  /**** Global Variables *****/
  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'countryName':
          return compare(a.countryName, b.countryName, isAsc);

        case 'stateName':
          return compare(a.stateName, b.stateName, isAsc);
        
        case 'stateCode':
          return compare(a.stateCode, b.stateCode, isAsc);

        case 'stateTin':
            return compare(a.stateTin, b.stateTin, isAsc);
            
        default:
          return 0;
      }
    });
  }

  Clear(){
 this.resultData=[];
 this.nodatafound=true;

  }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}