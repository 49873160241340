import { Component, Inject, OnInit, ViewEncapsulation,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { endWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-dashboard-dialogbox',
  templateUrl: './dashboard-dialogbox.component.html',
  styleUrls: ['./dashboard-dialogbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardDialogboxComponent implements OnInit {
  resourcelistdata: any[];
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  usertype: any;
  userType: any;
  // resourceSearchform:FormGroup;
  positionname: any;
  positionId: any;
  positionlist: any;
  interviewStatuslist: any;
  loader: boolean;
  backdrop: any = false;
  resorcelistdata: any;
  interviewstatus: any;
  interviewStatusinterviewed: any;
  interviewed: any;
  // data:any;
  false: any;
  true: any;
  Interviewed: any;
  Notinterviewed: any;
  resource: any;
  list_loader = true;
  nodata = false;
  length: any;
  user: any;
  position: any;
  startDate: string;
  endDate: string;
  fromdate: string;
  todate: string;
  recruiter: string;
  orgId: any;
  userTypes: any;
  search: any;
  isAdmin: boolean = false;
  isReqruiter: boolean = false;
  titleName: string;

  storeEmployeeId:any;
  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
  orgAddress: any;
  orgName: any;
  createdById:any;
  // dialogRef: any;
  userIdStore:any;
  constructor(
    private hrmsService: HrmsserviceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.titleName = this.data.titleName || 'Default Title';

    this.user = JSON.parse(localStorage.getItem('enoteUserData'));

    this.createdById=this.user.employee.createdBy;
    this.userIdStore=this.user.userId;


    console.log('this.user local data::',  this.createdById);

    this.storeEmployeeId=this.user.employee.employeeId;
console.log("storeEmployeeId",this.storeEmployeeId);


    this.userType = [];
    this.user.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    this.userType = [];
    this.user.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    this.isAdmin = this.userType.includes('Admin');
    this.isReqruiter = this.userType.includes('Recruiter');

    console.log(this.isAdmin);

    console.log(this.userType);

    console.log('rolename', this.userType);

    this.orgId = this.user.organization.organizationId;
    console.log(this.user);
    this.orgAddress = this.user.organization.address;
    this.orgName = this.user.organization.organizationName;
    console.log(this.data);
    console.log(this.data.status);
    console.log(this.data.interviewed);
    console.log(this.data.positionstatus);
    this.position = this.data.positionstatus;
    console.log(this.position);
    this.fromdate = this.datepipe.transform(this.data.startDate, 'yyyy-MM-dd');
    console.log('fromdate' + this.fromdate);
    this.todate = this.datepipe.transform(this.data.endDate, 'yyyy-MM-dd');
    console.log('rodate' + this.todate);
    this.recruiter = this.data.createdBy;
    console.log('reportrecruiter' + this.recruiter);
    let fdate = this.fromdate + ' ' + '00:00:00';
    let tdate = this.todate + ' ' + '23:59:00';
    console.log('fromdate' + fdate);
    console.log('todate' + tdate);
    this.startDate = fdate;
    this.endDate = tdate;
    // let status=1;
    // console.log(status);
    // console.log(this.user.userType.userType);
    if (this.userType.includes('Admin')) {
      console.log('Admin', this.data);
      console.log('status', this.data.status);
      console.log('interviewed', this.data.interviewed);
      console.log('startDate', this.data.startDate);
      console.log('endDate', this.data.endDate);
      console.log('createdBy', this.data.createdBy);
      if (
        (this.data.status != undefined || this.data.status != 0) &&
        this.data.interviewed == undefined &&
        this.data.startDate == undefined &&
        this.data.endDate == undefined &&
        this.data.createdBy == undefined
      ) {
        console.log('only Profile shared:' + this.data.status);
        // return;
        this.loader = true;

        this.hrmsService
          // .resourceStatus(this.data.status)
          .resourceByStatusAndOrganization(this.data.status, this.orgId)
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.loader = false;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            //  this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        (this.data.interviewed == true || this.data.interviewed == false) &&
        this.data.status == undefined &&
        this.data.startDate == undefined &&
        this.data.endDate == undefined &&
        this.data.createdBy == undefined
      ) {
        this.loader = true;

        console.log('only interviewed:' + this.data.interviewed);
        this.hrmsService
          // .resourceInterviewedStatus(this.data.interviewed)
          .resourceByInterviewedAndOrganization(
            this.data.interviewed,
            this.orgId
          )
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.loader = false;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            // this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        this.data.status != 0 &&
        this.data.interviewed == undefined &&
        (this.data.startDate != undefined || this.data.startDate != null) &&
        (this.data.endDate != null || this.data.endDate != undefined) &&
        (this.data.createdBy != null || this.data.createdBy != undefined)
      ) {
        // this.loader=true;

        console.log('Profile shared and Both Filter:' + this.data.interviewed);
        this.hrmsService
          // .resourceProfileSharedDateandRecruiterBoth(this.startDate,this.endDate,this.data.createdBy)
          .resourceProfileSharedDateandRecruiterBothorg(
            this.startDate,
            this.endDate,
            this.data.createdBy,
            this.orgId
          )
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        this.data.status != 0 &&
        this.data.interviewed == undefined &&
        (this.data.startDate != undefined || this.data.startDate != null) &&
        (this.data.endDate != null || this.data.endDate != undefined) &&
        (this.data.createdBy == null || this.data.createdBy == undefined)
      ) {
        // this.loader=true;

        console.log(
          'Profile shared and FromDate and ToDate Filter:' +
            this.data.interviewed
        );
        this.hrmsService
          // .resourceProfileSharedDate(this.startDate,this.endDate)
          .resourceProfileSharedDateorg(
            this.startDate,
            this.endDate,
            this.orgId
          )
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        this.data.status != 0 &&
        this.data.interviewed == undefined &&
        (this.data.startDate == undefined || this.data.startDate == null) &&
        (this.data.endDate == null || this.data.endDate == undefined) &&
        (this.data.createdBy != null || this.data.createdBy != undefined)
      ) {
        console.log(
          'Profile shared and Recruiter Filter' + this.data.interviewed
        );
        this.hrmsService
          // .resourceProfileSharedRecruiter(this.data.createdBy)
          .resourceProfileSharedRecruiterorg(this.data.createdBy, this.orgId)
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        (this.data.interviewed == true || this.data.interviewed == false) &&
        (this.data.startDate != undefined || this.data.startDate != null) &&
        (this.data.endDate != null || this.data.endDate != undefined) &&
        (this.data.createdBy != null || this.data.createdBy != undefined)
      ) {
        console.log('Interviewed and Both Filter:' + this.data.interviewed);
        this.hrmsService
          .resourceInterviewedDateandRecruiterBothorg(
            this.startDate,
            this.endDate,
            this.data.createdBy,
            this.data.interviewed,
            this.orgId
          )
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        (this.data.interviewed == true || this.data.interviewed == false) &&
        (this.data.startDate != undefined || this.data.startDate != null) &&
        (this.data.endDate != null || this.data.endDate != undefined) &&
        (this.data.createdBy == null || this.data.createdBy == undefined)
      ) {
        console.log(
          'Interviewed and FromDate and ToDate Filter:' + this.data.interviewed
        );
        this.hrmsService
          // .resourceInterviewedDate(this.startDate,this.endDate,this.data.interviewed)
          .resourceInterviewedDateorg(
            this.startDate,
            this.endDate,
            this.data.interviewed,
            this.orgId
          )
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        (this.data.interviewed == true || this.data.interviewed == false) &&
        (this.data.startDate == undefined || this.data.startDate == null) &&
        (this.data.endDate == null || this.data.endDate == undefined) &&
        (this.data.createdBy != null || this.data.createdBy != undefined)
      ) {
        console.log('Interviewed and Recruiter Filter' + this.data.interviewed);
        this.hrmsService
          // .resourceInterviewedRecruiter(this.data.createdBy,this.data.interviewed)
          .resourceInterviewedRecruiterorg(
            this.data.createdBy,
            this.data.interviewed,
            this.orgId
          )
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      }
    } else {
      console.log('Recuriter');
      //  this.loader=true
      // console.log("loader true or false::",this.loader);

      if (
        this.data.positionstatus != undefined ||
        this.data.positionstatus != null
      ) {
        // console.log("loader true or false inside condition::",this.loader);

        console.log('open Positions');
        this.loader = true;
        console.log('loder::', this.loader);

        // return

        this.hrmsService
          .resourceOpenPositionsorg(this.data.positionstatus, this.orgId)
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
      } else if (
        this.data.recruiter != 0 &&
        this.data.status != 0 &&
        this.data.interviewed == undefined
      ) {
        this.loader = true;

        if(this.createdById!==0){
          console.log("created by not 0!!!!");
          
        }
        else{
          
        console.log('position  shared');
        this.hrmsService
          .resourceProfileShared(this.userIdStore, this.data.status)
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.resourcelistdata = Array.isArray(resource) ? resource: [resource];
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
        }
      } 
      else if (
        (this.data.recruiter != 0 && this.data.interviewed != null) ||
        this.data.interviewed != undefined
      ) {
        this.loader = true;
        if(this.createdById!==0){
          console.log("created by not 0!!!!");
          
        }
        else{
        console.log('interview are not');
        this.hrmsService
          .resourceInterviewStatus(this.userIdStore, this.data.interviewed)
          .subscribe((resource: any) => {
            this.resourcelistdata = resource;
            this.length = this.resourcelistdata.length;
            console.log(this.length);
            this.loader = false;
            console.log(resource);
            console.log(this.resourcelistdata);
          });
        }
      }
    }
  }

  // back()
  //   {
  //     console.log("close");
  //   }
  showChanges() {
    console.log('show Change');
    this.p = 1;
  }

  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML);
    

    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    console.log(pdfTable.innerHTML);
    

    const documentDefinition = {
      content: html,
      pageMargins: [25, 40, 25, 40],
      info: {
        title: this.titleName
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);

        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download(`${this.titleName}.pdf`);
  }
}
