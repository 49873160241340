<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">List Types</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/super-admin-home/settings" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a title="Add List Type" (click)="addForm()" color="primary" mat-raised-button class="ml-2">
                        Add List Type
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">

                <!--  <div class="alert alert-success alert-dismissible" *ngIf="success">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Success!</strong> Indicates a successful or positive action.
                </div> -->

                <div class="row mb-2">
                    <div class="col-md-8">
                        <div class="row mb-3 pl-0">
                            <div class="col-md-4">
                                <input type="search" (input)="applyFilter()" [(ngModel)]="tableSearch" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" name="keywords" placeholder="Search...">
                            </div>

                            <!--  <div class="col-md-3 pl-0">
                                <button class="search-btn" mat-raised-button color="primary">Search</button>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-9 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0">
                                <select [(ngModel)]="pagePerItem" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <!-- <option value="1500">1500</option> -->
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <table  class="table --table-responsive table-bordered table-border-new">
                    <thead class="t-head">
                        <tr>
                            <!-- <th class="text-center">S.No</th> -->
                            <th class="text-center">Action</th>
                            <th>List Type Name</th>
                            <th class="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of resultData | searchFilter: tableSearch | paginate: { itemsPerPage: pagePerItem, currentPage: p }  let i=index;">
                            <!-- <td class="text-center">{{i+1}}</td> -->
                            <td class="text-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu" class="controls-list">
                                    <!-- <button mat-menu-item >
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button> --> <!-- (click)="updateform(row)" -->
                                    
                                    <button mat-menu-item routerLink="/super-admin-home/list-type-values/{{row.listTypeId}}" routerLinkActive="active">
                                        <mat-icon>view_list</mat-icon>
                                        <span>List Type Values</span>
                                    </button>

                                    <!-- <button mat-menu-item *ngIf="row.status == 1" (click)="updateStatus(row.departmentId,2)">
                                        <mat-icon>done</mat-icon>
                                        <span>Inactive</span>
                                    </button>

                                    <button mat-menu-item *ngIf="row.status == 2" (click)="updateStatus(row.departmentId,1)">
                                        <mat-icon>done</mat-icon>
                                        <span>Active</span>
                                    </button> -->
                                </mat-menu>
                            </td>

                            <td>{{row.listTypeName}}</td><!-- capitalizeWordsName -->
                            <td class="active-status text-center">
                                <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status == 1">
                                    Active
                                </span>

                                <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status == 2">
                                    Inactive
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody
                  style="border-right: 1px solid #ddd; border-left: 1px solid #ddd"
                >
                  <tr>
                    <td
                      style="
                        text-align: center;
                        font-size: larger;
                        font-weight: bold;
                      "
                      colspan="7"
                      *ngIf="nodatafound"
                    >
                    List Type Name Not Found!
                    </td>
                  </tr>
                </tbody>
                </table>

                <div class="row" >
                    <div class="col-md-4">
                        <div class="showing-record" *ngIf="!nodatafound">
                            <!-- <ng-template pTemplate="paginatorleft" let-state>
                                Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                            </ng-template> -->
                            {{filteredData?.length > 0 ? filteredData.length : resultData?.length}} rows
                        </div>
                    </div>
                    <div class="col-md-8 text-right" *ngIf="!nodatafound"

                        >
                        <pagination-controls *ngIf=" (resultData |searchFilter :tableSearch)?.length >5" (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>

