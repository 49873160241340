import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, EMPTY, Subject, takeUntil } from 'rxjs';
import { EmployeeService } from 'src/app/providers/employee.service';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';

@Component({
  selector: 'app-variable-pay-list',
  templateUrl: './variable-pay-list.component.html',
  styleUrls: ['./variable-pay-list.component.scss']
})
export class VariablePayListComponent implements OnInit {
  loader:any= false;
  showTable:boolean=false;

  isProcessing = false;
  isprocessing1=false;


  variablepayform: FormGroup;
  variablePaylistData1: any = [];
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  selectedEmployeeData: any = [];
  usertData: any;
  clientId:any;
  orgId: number;
  noRecordsFound: boolean = false;
  pagePerItem: any = 5;
  p: number = 1;
  tableSearch: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  empCode: boolean = false;
  empList: any[]= [];
  empNum: number;
  userId: number;
  employeeId: number;
  finYearList: any = [];
  chooseFinId: any;
  filterchooseFinId: any;
  finPeriodList: any = [];
  filterfinPeriodList: any = [];
  submitted: boolean = false;
  variablepayData: any;
  public searchFilter: any = '';
  searchForm:FormGroup;
  req: boolean=false;
  resultData : any = [];
  errormessagejson: any;
  importerror = false;
  errorMsg : any;
search: any;
mySelect: any=5;
  unsubscribe$: any;
  inputValue: any;
  employeelistsub: any;
  employee: any;
  selectedemployeeId: any;
  selectedemployee: any;
  // clientId:any
  constructor(
    private payrollservice: PayrollService,
    private formBuilder: FormBuilder,
    private employee_service: EmployeeService,
    private settingsService: SettingsService,
    private router : Router,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.noRecordsFound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);

    this.variablepayform = this.formBuilder.group({
      financialyear: [null, [Validators.required]],
      period: [null, [Validators.required]],
      employeecode: [null, [Validators.required]],

    })

    this.searchForm = this.formBuilder.group({
      financialyearSearch: [null, [Validators.required]],
      periodSearch: [null, [Validators.required]],
      employeecodeSearch: [null, [Validators.required]],

    })


    if(this.orgId){
    this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1){
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
    }
    // else if(this.clientId){
    //   console.log("client logged In");
      
    //   this.settingsService.getFinicalyearListbyClient( this.clientId).subscribe((resultData: any) => {
    //     console.log(resultData);
    //     resultData.forEach((item: any) => {
    //       this.finYearList=[]

    //       // console.log(item.financialYearStatus.listTypeValueId)
    //       // console.log(item.financialYearStatus.listTypeValueId == 1)
    //       if(item.financialYearStatus.listTypeValueId == 1){
    //         this.finYearList=[]
    //         console.log(item.financialYearStatus.listTypeValueId)
    //         this.finYearList.push(item);
    //         console.log(' this.finYearList', this.finYearList)
    //       }
    //     });
    //   });
    // }

  )}
  
  if(this.clientId){
    this.settingsService.getClientFinicalyearList(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1){
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
    });
  }
  }
  pageChages(event)
  {
    console.log('event.target.value', event.target.value);
    if(event.target.value != null || event.target.value != 0){
      // this.p = event.target.value;
      console.log("page changes");
      this.p=1;
    }
  }

  calculateVariablePay() {
    if(this.variablepayform.invalid){
            this.isprocessing1 = false; // Enable the button if the form is invalid

      return;
    }

//     if((this.variablepayform.value.employeecode !== undefined && this.variablepayform.value.employeecode !== null && this.variablepayform.value.employeecode !== '') ){
//       if((this.empnumber != this.variablepayform.value.employeecode ) || (this.empnumber == null) ){
//         window.alert('please select valid employee from dropdown.');
//         this.variablepayform.get("employeecode").reset();
//         this.empCode = false;
//         return;
//       }
//     }

if (this.variablepayform.value.employeecode !== undefined 
  && this.variablepayform.value.employeecode !== null 
  && this.variablepayform.value.employeecode !== '' 
  && this.variablepayform.value.employeecode !== 'All') {

if ((this.empnumber != this.variablepayform.value.employeecode) || (this.empnumber == null)) {
  window.alert('Please select a valid employee from the dropdown.');
  this.variablepayform.get("employeecode").reset();
        this.empCode = false;
  return;
    }
  }

      this.isprocessing1 = true; // Disable the button
    console.log(this.selectedEmployeeData)
    const newArray = this.selectedEmployeeData.map((employee) => {
      console.log('employee',employee.employeeId);
      console.log('financialPeriodId',this.variablepayform.value.period);
       console.log('financialPeriodId',this.variablepayform.value.period);
      return {
        employee: { employeeId: employee.employeeId},
        financialPeriod: { financialPeriodId: this.variablepayform.value.period},
        financialYear: { financialYearId: this.variablepayform.value.financialyear}
      };
    });
    console.log('newArray',newArray);

    const obj = {
      lines: newArray
    }
    this.variablepayData = obj;
    console.log(' this.variablepayData', this.variablepayData);
    this.payrollservice.calculateVariablePay(this.variablepayData).subscribe((result: any) =>
    {

      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;      
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        //this.router.navigate(['/home/employee']);
        this.variablepayform.reset();     
        this.submitted = false;
        setTimeout(() => {
          this.router.navigate(['/home/manage-variablepay']);
        }, redirectMsgTimeOut)
      }
      else
      {

        this.error = true;
        this.error_msg = result.description;
        this.errormessagejson = result;
        this.importerror = true;
        setTimeout(()=>{
          this.error = false;
          this.error_msg = '';
          this.importerror = false;
        },3000);
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)

      //   setTimeout(() => {
      // //    this.finicalyearList();
      //   }, redirectMsgTimeOut)
      }
      this.isprocessing1=false;

    }, err =>
    {

        // setTimeout(() => {
          this.error = true;
          this.errorMsg = err.error.message;
          setTimeout(()=>{
            this.error = false;
            this.error_msg = '';
            this.importerror = false;
          },3000);
          // this.router.navigate(['/home/financialyear']);
        // }, redirectMsgTimeOut);
        this.isprocessing1=false;

    })
  }

  clearTable()
  {this.resultData = [];
    this.noRecordsFound = false;
    console.log('clear')
    this.searchForm.clearValidators;
    this.noRecordsFound = true;
    this.finPeriodList=[];
    this.showTable=false;
    this.isProcessing = false; // Re-enable the button if validation fails
    this.filterfinPeriodList=[];

  }
  changefirstpage() {
    this.p = 1;
  }


  searchSubmit()  
  {
    
    console.log("submit");
    this.resultData =[];
    this.isProcessing = true; // Disable the button when the search starts

   

    if((this.searchForm.value.employeecodeSearch=== '' || this.searchForm.value.employeecodeSearch ===null)&&
    (this.searchForm.value.financialyearSearch===null || this.searchForm.value.financialyearSearch ==='null')&&
    (this.searchForm.value.periodSearch===null|| this.searchForm.value.periodSearch ==='null'))
    {
       console.log("required Fields");
       this.resultData =[];
       this.noRecordsFound = true;
       alert("Please Enter any Employee Name or Select Financial Year to Search!");
       this.isProcessing = false; 

       return;
    }

    console.log(this.searchForm.value);
    console.log('this.searchForm.value.employeecodeSearch',this.searchForm.value.employeecodeSearch)
    console.log('this.searchForm.value.financialyearSearch',this.searchForm.value.financialyearSearch)
    console.log('this.searchForm.value.periodSearch',this.searchForm.value.periodSearch)
    this.loader=true;
    if(!(this.searchForm.value.employeecodeSearch=== '' || this.searchForm.value.employeecodeSearch === null)
    &&!(this.searchForm.value.financialyearSearch==null || this.searchForm.value.financialyearSearch=='null')
    &&!(this.searchForm.value.periodSearch==null || this.searchForm.value.periodSearch=='null'))
    {
      console.log("all");
      this.payrollservice.getvariablepay(this.searchForm.value.financialyearSearch,this.searchForm.value.periodSearch,this.searchForm.value.employeecodeSearch).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.showTable=true;
        this.noRecordsFound = false;
        this.isProcessing=false
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing = false; // Re-enable the button if validation fails

      })
    }
    else if((this.searchForm.value.employeecodeSearch=== '' || this.searchForm.value.employeecodeSearch === null)
    &&(this.searchForm.value.financialyearSearch!=null || this.searchForm.value.financialyearSearch!='null')
    &&(this.searchForm.value.periodSearch==null||this.searchForm.value.periodSearch=='null'))
    {
      console.log("Finanical year only");
      this.payrollservice.getyearbasedvariablepay(this.searchForm.value.financialyearSearch).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.showTable=true;
        this.isProcessing=false

        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing = false; // Re-enable the button if validation fails

      })
    }
    
    else if((this.searchForm.value.employeecodeSearch=== '' || this.searchForm.value.employeecodeSearch === null)
    &&(this.searchForm.value.financialyearSearch!=null || this.searchForm.value.financialyearSearch!='null')
    &&(this.searchForm.value.periodSearch!=null ||this.searchForm.value.periodSearch!='null'))
    {
      console.log("finatical and period");
      this.payrollservice.getyearandperiodvariablepay(this.searchForm.value.financialyearSearch,this.searchForm.value.periodSearch).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.showTable=true;

        this.isProcessing=false

        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
   
    else if(!(this.searchForm.value.employeecodeSearch=== '' || this.searchForm.value.employeecodeSearch === null)
    &&(this.searchForm.value.financialyearSearch==null||this.searchForm.value.financialyearSearch=='null')
    &&(this.searchForm.value.periodSearch==null || this.searchForm.value.periodSearch=='null'))
    {
      console.log("Employeecode only");

      if(this.orgId){
      this.payrollservice.getemployeecodevariablepayOrg(this.searchForm.value.employeecodeSearch,this.orgId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.showTable=true;
        this.isProcessing=false

        this.noRecordsFound = false;

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing = false; // Re-enable the button if validation fails

      })
    }
    if(this.clientId){
      this.payrollservice.getemployeecodevariablepayClient(this.searchForm.value.employeecodeSearch,this.clientId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.showTable=true;
        this.isProcessing=false

        this.noRecordsFound = false;

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing = false; // Re-enable the button if validation fails

      })
    }
    }else if(!(this.searchForm.value.employeecodeSearch=== '' || this.searchForm.value.employeecodeSearch === null)
    &&!(this.searchForm.value.financialyearSearch==null||this.searchForm.value.financialyearSearch=='null')
    &&(this.searchForm.value.periodSearch==null || this.searchForm.value.periodSearch=='null'))
    {
      console.log("Employeecode and year");
      this.payrollservice.getvariablepaybyempCodeandFinYear(this.searchForm.value.employeecodeSearch, this.searchForm.value.financialyearSearch).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.showTable=true;

        this.noRecordsFound = false;
        this.isProcessing=false

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing = false; // Re-enable the button if validation fails

      })
    }
    else if(!(this.searchForm.value.employeecodeSearch=== '' || this.searchForm.value.employeecodeSearch === null)
    &&(this.searchForm.value.financialyearSearch==null||this.searchForm.value.financialyearSearch=='null')
    &&!(this.searchForm.value.periodSearch==null || this.searchForm.value.periodSearch=='null'))
    {
      console.log("Employeecode and period");
      this.payrollservice.getvariablepaybyempCodeandFinPeriod(this.searchForm.value.employeecodeSearch, this.searchForm.value.periodSearch).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.showTable=true;

        this.noRecordsFound = false;
        this.isProcessing=false

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing = false; // Re-enable the button if validation fails

      })
    }
  }
  get validate() { return this.variablepayform?.controls; }

  selectFinancialyear(event) {
    console.log("Select indidual Employee");
    console.log(event.value);
    // console.log(this.searchForm.value.financialyear);
    this.chooseFinId = event.value;
    console.log(this.chooseFinId);
    console.log('this.chooseFinId == null',this.chooseFinId == null)
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
      this.searchForm.patchValue({
        period: null
      })
      console.log("this.searchFilter",this.searchFilter)
    }else{
      console.log('else');
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
      });
    }
  }

  selectFilterFinancialyear(event) {
    console.log("Select indidual Employee");
    console.log(event.value);
    // console.log(this.searchForm.value.financialyear);
    this.filterchooseFinId = event.value;
    console.log(this.filterchooseFinId);
    console.log('this.filterchooseFinId == null',this.filterchooseFinId == null)
    if(this.filterchooseFinId == null || this.filterchooseFinId == 'null'){
      console.log('if');
      this.filterfinPeriodList = [];
      this.searchForm.patchValue({
        periodSearch: null
      })
      console.log("this.searchFilter",this.searchFilter)
    }else{
      console.log('else');
      this.settingsService.getFinancialYearId(this.filterchooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.filterfinPeriodList = resultData.finanicalPeriod;
      });
    }
  }
  

  // empSearch(event) {
  //   this.empList = [];
  //   this.empCode = false;
  //   console.log(event.target.value);
  //   this.userId = event.target.value;
  //   console.log(this.userId);
  //   // Employee List Api
  //   // this.empList.length == 0;
  //   this.payrollservice.getEmployeeNameList(this.userId, this.orgId).subscribe((result: any) => {
  //     if (result.statusCode == 204) {
  //       console.log('NO Data');
  //       this.empList.length = 0;
  //       this.empCode = true;
  //     }
  //     else {
  //       this.empList = result;
  //       this.empCode = false;
  //       console.log(this.empList);
  //     }
  //   })
  // }

  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 

  }


  empSearch(event){
    console.log(event.target.value)
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement) // Cast the event target as an input element
  this.inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
   console.log(this.inputValue)
    // Clear the employee list and reset flags if input is empty
    if (this.inputValue === '') {
      this.empList = [];
      this.empCode = false;
      console.log('Input is empty, employee list cleared');
      return;
    }
  
    // Check if input length is sufficient for the search
    if (this.inputValue.length >= 2) {
      // Unsubscribe from the previous API request if necessary
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
  
      // Reset and create a new Subject for cancellation
      this.unsubscribe$ = new Subject<void>();
  
      if(this.orgId){
      this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
  }

  if(this.clientId){
    this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
      .pipe(
        takeUntil(this.unsubscribe$), // Cancel previous request
        catchError((err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true; // Set the flag to indicate an error state
          }
          console.error('An error occurred:', err);
          return EMPTY; // Return EMPTY to propagate completion downstream
        })
      )
      .subscribe(
        (result: any) => {
          if (result.statusCode === 204) {
            console.log('No employees found (status code 204)');
            this.empList = [];
            this.empCode = true; // Indicate no results found
          } else if (result.statusCode) {
            console.log('Other status code:', result.statusCode);
            this.empList = [];
            this.empCode = true;
          } else {
            // Handle the case where result is an array or an object
            this.empList = Array.isArray(result) ? result : Object.values(result);
            this.empCode = false;
          }
        },
        (err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true;
          }
          console.error('An error occurred:', err);
        }
      );
}
    }
  } 

  empSelect(emp) {
    this.empList = [];
    this.employeeId = emp.employeeId;
    this.firstname = emp.firstName;
    this.lastname = emp.lastName;
    this.empnumber = emp.employeeNumber;
    console.log("select", this.selectedEmployeeData);
    let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
    if (!hasemployee) {

      this.selectedEmployeeData.push(emp);
      console.log(this.selectedEmployeeData);
      // const rowsArray = this.variablepayform.get('Leaves') as FormArray;
      // // rowsArray.clear();
      // console.log("control",rowsArray);
      // rowsArray.push(this.formBuilder.group({
      //   employeeId: [this.employeeId], 
      //   employeeNumber:[this.empnumber],
      //   firstName:[ this.firstname],
      //   lastName:[this.lastname],
      //   empleave: ['', [Validators.required]],
      // }));
    }

    // }

  }

  empAllSelect() {
    // this.empList=[];
    // this.selectedEmployeeData=[];
    console.log("all Employee");
    if (this.variablepayform.value.employeecode == "All") {
      console.log("select", this.selectedEmployeeData);

      if(this.orgId){
      this.payrollservice
        .getAllEmployeeByOrganization(this.orgId).subscribe((result: any) => {
          console.log(result);
          if (result.length == 0) {
            console.log("noData");
          }
          result.forEach(element => {
            let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
            if (!hasemployee) {
              this.selectedEmployeeData.push(element);
              console.log('selectedEmployeeData', this.selectedEmployeeData);
              // const rowsArray = this.variablepayform.get('Leaves') as FormArray;
              // rowsArray.push(this.formBuilder.group({
              //   employeeId: [element.employeeId], 
              //   employeeNumber:[element.employeeNumber],
              //   firstName:[element.firstName],
              //   lastName:[element.lastName],
              // }));
            }
          });
        })
      }
      if(this.clientId){
        this.payrollservice
          .getAllEmployeeByClient(this.clientId).subscribe((result: any) => {
            console.log(result);
            if (result.length == 0) {
              console.log("noData");
            }
            result.forEach(element => {
              let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
              if (!hasemployee) {
                this.selectedEmployeeData.push(element);
                console.log('selectedEmployeeData', this.selectedEmployeeData);
                // const rowsArray = this.variablepayform.get('Leaves') as FormArray;
                // rowsArray.push(this.formBuilder.group({
                //   employeeId: [element.employeeId], 
                //   employeeNumber:[element.employeeNumber],
                //   firstName:[element.firstName],
                //   lastName:[element.lastName],
                // }));
              }
            });
          })
        }

    }
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
}
