import { Component, ElementRef, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { Fruit } from 'src/app/recruitment/requirement/requirement-add/requirement-add/requirement-add.component';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MY_FORMATS } from 'src/app/app.module';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MatChipInputEvent } from '@angular/material/chips';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-experexpense-reimbursement-create',
  templateUrl: './experexpense-reimbursement-create.component.html',
  styleUrls: ['./experexpense-reimbursement-create.component.scss'],
  // providers: [
  //   {
  //     provide: DateAdapter,
  //     useClass: MomentDateAdapter,
  //     deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  //   },
  //   {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  // ],
})
export class ExperexpenseReimbursementCreateComponent implements OnInit {
  expenseform: FormGroup;
  control: FormArray;
  mode: boolean;
  touchedRows: any;
  categorylist = [];
  taskList: any;
  min: any;
  max: any;
  startDate_disable: boolean = true;
  endDate_disable: boolean = true;
  projecttaskexist: any = {};
  submitted: boolean = false;
  organzationid: any;
  expensearray: any = [];
  istaskexist = { istaskexist: false };
  setElementboolean:boolean=false
  tasknameexist: boolean = false;
  taskgrouplist: any;
  employeedata: any;
  advancebalance: any = 0;
  cashAdvance:any = 0 ;
  // TotaladvanceAmount:any =0;
  subTotal: any = 0;
  totalRebusriment: any = 0;
  filename: any;
  tablerowarray;
  expenseReimbursement: any;
  expensetypelist: any;
  loader: any = false;
  backdrop: any = false;
  success: any = false;
  error: any = false;
  create_sucess_msg: any;
  update_error_msg: any;
  today: any = new Date();
  advancebalancedata: any;
  data: any;
  fileName: any;
  showprojectname: Boolean = false;
  expensevalue: any;
  draft_button: boolean = false;
  submit_button: boolean = false;
  isenddate_Valid: boolean = false;
  isdate_Valid: boolean = false;
  expensedate: any;
  from_date: string;
  to_date: string;
  btnDisable: boolean = false;
  Approval_btnDisable: boolean = false;
  skillsarray: Fruit[] = [];
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitsdata: any;
  businessPurposedata: any;

  expensereimbursementId: any;
  fileChoosen: boolean[] = [];
  storeEmpId: any;
  advanceTypeData: any;
  advanceTypeSearchData: any;
  storeReiumbursement: any;
  storeRebrsType: any;
  advancebalanceamount: any;
  maxWords: number=99;
  maxChars: number=99;
  cashAdvances: any=0;
isEditing: boolean=false;
setHide: boolean=false;
  storeemail: any;
  selectedadvance: any;
  constructor(
    private fb: FormBuilder,
    private expenseservice: ProjectManagementService,
    private headerservice: HeaderService,
    private elementRef: ElementRef,
    private ActivatedRoute: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe
  ) {}
  isOptionSelected: boolean = false;

  ngOnInit(): void {
    // this.headerservice.setTitle('Expense Reiumbursement');

    // this.listAdvanceType()
    // this.listTypeValueid(event)

    this.today = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    const Data = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("Data",Data);
    // debugger;
    this.employeedata = Data;
    this.organzationid = Data.organization.organizationId;
    this.expensereimbursementId =
      this.ActivatedRoute.snapshot.params.expensereimbursementId;
    this.storeEmpId = this.employeedata.employee.employeeId;
    this.storeemail=this.employeedata.employee.organizationEmail
    console.log( " this.storeemail::",this.storeemail);
    
    console.log('this.storeEmpId', this.storeEmpId);

    if (this.ActivatedRoute.snapshot.params.expensereimbursementId) {
      this.loader = true;
      this.data = this.ActivatedRoute.snapshot.params.expensereimbursementId;
      this.startDate_disable = false;
      this.touchedRows = [];
      this.expenseform = this.fb.group({
        tableRows: this.fb.array([]),
        startdate: ['', [Validators.required]],
        enddate: ['', [Validators.required]],
        expenseType: ['', Validators.required],
        reimbursementType: ['', [Validators.required]],
        // projectName: [''],
        projectName: new FormControl('', [
          Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
        ]),
        

        // descri: [''],
        // descri: new FormControl('', [
        //   Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
        // ]),
          // descri: ['', [Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"), this.wordCountValidators(99)]],
          descri: new FormControl('', [
            Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
          ]),
        
        // businessPurpose: [''],
        // businessPurpose: [''],
        businessPurpose: ['', this.wordCountValidator.bind(this)]


      });
      //    this.addRow();
      this.tablerowarray = this.expenseform.get('tableRows') as FormArray;
      this.setHide=true;
      this.isEditing=true

      this.expenseservice
        .categorylist(Data.organization.organizationId)
        .subscribe((categorylist: any) => {
          // this.categorylist = categorylist;
          this.categorylist = categorylist.filter(
            (item) => item.status.listTypeValueName === 'Active'
          );
          this.isEditing=true

          this.expenseservice
            .expensetype()
            .subscribe((expensetypelist: any) => {
              this.expensetypelist = expensetypelist;
              this.setHide=true;
              console.log('this.expensetypelist::', this.expensetypelist);

              this.expenseservice
                .getexpensereburimentbyId(
                  this.ActivatedRoute.snapshot.params.expensereimbursementId
                )
                .subscribe((expenseReimbursementdata: any) => {
                  this.submit_button = true;
                  this.expenseReimbursement = expenseReimbursementdata;
                  console.log(' editing response::', this.expenseReimbursement);
                  // console.log(
                  //   'this.expenseReimbursement.reimbursementType.listTypeValueId::',
                  //   this.expenseReimbursement.reimbursementType.listTypeValueId
                  // );
                  // console.log("advance balce amount::",this.expenseReimbursement.advanceBalance);
                  
                  this.loader = false;
                  if (this.expenseReimbursement.draft == true) {
                    this.draft_button = true;
                  } else {
                    this.draft_button = false;
                  }
                  
                  // this.advancebalance = this.expenseReimbursement.cashAdvance;
                  this.advancebalance = this.expenseReimbursement.advanceBalance;
                //  const cashAdvance=this.expenseReimbursement.advanceBalance;
                  this.cashAdvance = this.expenseReimbursement.advanceAmount;
                  this.subTotal = this.expenseReimbursement.subTotal;
                  this.totalRebusriment =
                    this.expenseReimbursement.totalReimbursement;
                  this.min = this.expenseReimbursement.fromDate;

                  this.max = this.expenseReimbursement.toDate;
                  this.expenseform.patchValue({
                    startdate: this.expenseReimbursement.fromDate,
                    enddate: this.expenseReimbursement.toDate,
                    expenseType:
                      this.expenseReimbursement.expenseType.listTypeValueId,
                    projectName: this.expenseReimbursement.projectName,
                    reimbursementType:
                      this.expenseReimbursement.reimbursementType
                        .listTypeValueId,
                  });

                  if (this.expenseReimbursement.businessPurpose != undefined) {
                    this.businessPurposedata =
                      this.expenseReimbursement.businessPurpose.split(',');
                    // console.log(this.skillsdata);
                    let text;
                    this.businessPurposedata.forEach((element) => {
                      text = element;
                      // console.log(text);
                      this.skillsarray.push({ name: text });
                      console.log(this.skillsarray);
                    });
                  }
                  if (this.expenseReimbursement.description != undefined) {
                    this.expenseform.patchValue({
                      descri: this.expenseReimbursement.description,
                    });
                  }
                  if (
                    this.expenseReimbursement.expenseType.listTypeValueId == 14
                  ) {
                    this.expenseform
                      .get('projectName')
                      .setValidators([
                        Validators.required,
                        Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
                      ]);
                    this.showprojectname = true;
                  } else {
                    this.expenseform.controls['projectName'].clearValidators();
                    this.showprojectname = false;
                  }
                  if (
                    this.expenseReimbursement.expenseType.listTypeValueId == 14
                  ) {
                    this.showprojectname = true;
                    this.expenseform.patchValue({
                      projectName: this.expenseReimbursement.projectName,
                    });
                  } else {
                    this.showprojectname = false;
                    this.expenseform.patchValue({
                      projectName: '',
                    });
                  }
                  if (this.expenseReimbursement.expenses.length != 0) {
                    const control = this.expenseform.get('tableRows') as FormArray;

                    // console.log(control);

                    this.expenseReimbursement.expenses.forEach(
                      (element, index) => {
                        const row = control.at(index) as FormGroup;  // Cast to FormGroup

                        // console.log(element);
                        this.addRow();
                        control.at(index).patchValue({
                          expenseId: element.expenseId,
                          date: element.date,
                          description: element.description,
                          category: element.category.expenseCategoryId,
                          amount: Number(element.amount),
                          status: element.status.listTypeValueId,
                          // paymentStatus:element.paymentStatus.listTypeValueId
                          // expenseType : element.expenseType.listTypeValueId,
                          // expenseAttachment:element.expenseAttachment,

                        });
                        this.subscribeToRowChanges(row);


                        if (element.expenseAttachment != undefined) {
                          console.log(
                            'element.expenseAttachment',
                            element.expenseAttachment
                          );
                          this.fileName = element.expenseAttachment.fileName;
                          // console.log("!= undefined");
                          control.at(index).patchValue({
                            expenseAttachment: element.expenseAttachment,
                          });
                          // console.log('this.expenseform', this.expenseform);
                        }
                      //   if (Number(this.subTotal) > Number(this.advancebalance)) {
                      //     console.log("if total entering!!");
                          
                      //     this.totalRebusriment =Number(this.subTotal) - Number(this.advancebalance);
                      //     // this.cashAdvances=0
                      //   } else {
                      //     console.log("else total entere!");
                          
                      //     this.totalRebusriment = 0;
                      // }

                      }
                    );
                    this.checkForDuplicates();

                    // control.push(this.initiateForm());
                  

                }
                  
                });
               
                
            });
        });
        
        // console.log("  this.advancebalance::",  this.advancebalance);
        
    } else {
      this.draft_button = true;
      this.submit_button = false;
      this.touchedRows = [];
      this.expenseform = this.fb.group({
        tableRows: this.fb.array([]),
        startdate: ['', [Validators.required]],
        enddate: ['', [Validators.required]],
        expenseType: ['', Validators.required],
        reimbursementType: ['', [Validators.required]],
        // projectName: [''],
        projectName: new FormControl('', [
          Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
        ]),
        

        // descri: [''],
        // descri: new FormControl('', [
        //   Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
        // ]),
        // descri: ['', [Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"), this.wordCountValidators(99)]],
        descri: new FormControl('', [
          Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
        ]),
        // businessPurpose: [''],
        businessPurpose: ['', this.wordCountValidator.bind(this)]

        // businessPurpose: ['', [this.wordCountValidator(0, 99)]],
      });
      this.addRow();

      this.tablerowarray = this.expenseform.get('tableRows') as FormArray;

      this.expenseservice
        .categorylist(Data.organization.organizationId)
        .subscribe((categorylist: any) => {
          this.categorylist = categorylist.filter(
            (item) => item.status.listTypeValueName === 'Active'
          );
        });

      this.expenseservice.expensetype().subscribe((expensetypelist: any) => {
        this.expensetypelist = expensetypelist;
      });
      console.log(
        'this.employeedata.employee.employeeId :::',
        this.employeedata.employee.employeeId
      );

      // this.expenseservice.getadvancebalancegivento(this.storeEmpId).subscribe((advancebalance:any ) => {
      //   if(advancebalance){
      //     this.advancebalancedata = advancebalance;
      //     this.advancebalance = Number(advancebalance.advanceBalance);
      //   }

      //   })
    }
    //  this.listAdvanceType()
    this.expenseservice.listCategoryType().subscribe((result: any) => {
      this.advanceTypeData = result;
      console.log('advanceTypeData', this.advanceTypeData);
      this.advanceTypeSearchData = result;
      console.log(this.advanceTypeSearchData);

      console.log(this.advanceTypeData[0].listTypeValueId);
    });
    const tableRows = this.expenseform.get('tableRows') as FormArray;

    tableRows.controls.forEach((group: AbstractControl) => {
      const formGroup = group as FormGroup;
      formGroup
        .get('date')
        ?.valueChanges.subscribe(() =>
          this.checkForDuplicates()
        );
      formGroup
        .get('category')
        ?.valueChanges.subscribe(() =>
          this.checkForDuplicates()
        );
      formGroup
        .get('amount')
        ?.valueChanges.subscribe(() =>
          this.checkForDuplicates()
        );
    });
    this.scrollToEditForm();

  }
  getTotalWordCount(): number {
    return this.skillsarray.reduce((total, fruit) => total + fruit.name.split(/\s+/).length, 0);
  }

  getTotalCharacterCount(): number {
    return this.skillsarray.reduce((total, fruit) => total + fruit.name.replace(/\s+/g, '').length, 0);
  }
  wordCountValidators(maxWords: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount > maxWords ? { wordCountErrors: true } : null;
    };
  }
  checkLength() {
    const descriptionControl = this.expenseform.get('descri');

    const descriptionValue = descriptionControl?.value?.trim(); // Trim the input to check for spaces only
  
    // Check for space or double space validation
    if (descriptionControl?.value && !descriptionValue) {
      // Input is only spaces
      alert('Please enter a valid description without leading spaces!');
      descriptionControl.setValue('');
      return;
    }
    
  
    // Check length
    if (descriptionValue && descriptionValue.length > 99) {
      alert('Description must not exceed 99 characters!');
      descriptionControl.setValue('');
    }
  }
  

  wordCountValidator(control: AbstractControl): { [key: string]: boolean } | null {



    const totalWords = this.getTotalWordCount();
    const totalChars = this.getTotalCharacterCount();
    const value = control.value ? control.value.trim() : '';

    // Check for leading or trailing spaces
    if (control.value && (control.value.startsWith(' ') || control.value.includes('  '))) {
      return { spaceError: true };
    }

    if (totalChars > this.maxChars) {
      alert('You Can Add Business Purpose,Only Up 0-to 99 characters!');
      this.skillsarray.length=0

      return 
    }

    

    return totalWords > this.maxWords ? { wordCountError: true } : null;
  }
  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;

  //   // Add our fruit
  //   if ((value || '').trim()) {
  //     this.skillsarray.push({ name: value.trim() });
  //     console.log(this.skillsarray);
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }
  // }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    if (value) {
      const totalWords = this.getTotalWordCount();
      const newWords = value.split(/\s+/).length;

      // Check if adding new chip would exceed max words or characters
      if (totalWords + newWords <= this.maxWords) {
        this.skillsarray.push({ name: value });
        console.log(this.skillsarray);
      } else {
        alert('You can only add up to 99 words.');
      }

      this.expenseform.get('businessPurpose')?.updateValueAndValidity(); // Trigger validation check
    }

    if (input) {
      input.value = '';
    }
  }


  // remove(fruit: Fruit): void {
  //   // const index = this.skillsarray.indexOf(fruit);

  //   // if (index >= 0) {
  //   //   this.skillsarray.splice(index, 1);
  //   //   console.log(this.skillsarray);
  //   // }
  //   const index = this.skillsarray.indexOf(fruit);

  //   if (index >= 0) {
  //     this.skillsarray.splice(index, 1);
  //     console.log(this.skillsarray);
  //   }
  // }
  remove(fruit: Fruit): void {
    const index = this.skillsarray.indexOf(fruit);

    if (index >= 0) {
      this.skillsarray.splice(index, 1);
      console.log(this.skillsarray);
      this.expenseform.get('businessPurpose')?.updateValueAndValidity(); // Trigger validation check
    }
  }


  fruitConcat() {
    let result;
    let text;
    this.skillsarray.forEach((element) => {
      text = element.name;
      if (result == undefined) {
        result = text;
        // console.log(result);
        this.fruitsdata = result;
      } else {
        result = result + ',' + text;
        console.log(result);
        this.fruitsdata = result;
      }
    });
  }
  ngAfterOnInit() {
    this.control = this.expenseform.get('tableRows') as FormArray;
  }

  initiateForm(): FormGroup {
    return this.fb.group({
      expenseId: [''],
      date: ['', [Validators.required]],
      // description: [
      //   '',
      //   [Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
      //   ],
      // ],
      description: new FormControl('', [Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ -/']+$")]),

      category: ['', Validators.required],
      amount: [
        '',
        [Validators.required, Validators.pattern(/^[1-9]\d{0,10}$/)],
      ],
      expenseAttachment: [''],
      fileSource: new FormControl(''),
      fileBytes: new FormControl(''),
      status: new FormControl(''),
      paymentStatus: new FormControl(''),
      isEditable: [true],
    });
  }

  reminder(): FormArray {
    return this.expenseform?.get('tableRows') as FormArray;
  }

  // addRow() {
  //   const control =  this.expenseform.get('tableRows') as FormArray;
  //   control.push(this.initiateForm());
  // }
  addRow() {
    // const array = this.reminder(); // Get the FormArray
    // array.push(this.initiateForm());
    const array = this.expenseform.get('tableRows') as FormArray;
    const newRow = this.initiateForm();
    array.push(newRow);
  
    // Subscribe to changes on the new row to detect duplicates in real-time
    this.subscribeToRowChanges(newRow);
    this.checkForDuplicates();


    // Create a temporary FormGroup to hold the new entry
    // const newEntry = this.fb.group({
    //   date:['',Validators.required],
    //   category: ['', Validators.required],
    //     amount: ['', Validators.required],
    // });

    // Add value change listeners to check for duplicates immediately
    // newEntry.get('date_picker1')?.valueChanges.subscribe(() => {
    //   this.checkForDuplicates(newEntry, array);
    // });

    // newEntry.get('time1')?.valueChanges.subscribe(() => {
    //   this.checkForDuplicates(newEntry, array);
    // });

    // Push the new entry to the FormArray if valid
    // if (array.length < 5) {
    //   array.push(newEntry);
    // } else {
    //   alert('Allowed only five Slots');
    // }
    // this.checkForDuplicates(newEntry,array)
  }
  timeSetter() {
    const array = this.reminder();
    const newEntry = this.fb.group({
      date: ['', Validators.required],
      category: ['', Validators.required],
      amount: ['', Validators.required],
    });

    // Check for duplicates when time changes
    this.checkForDuplicates();
  }
  valueChanges() {
    const array = this.reminder();
    const newEntry = this.fb.group({
      date: ['', Validators.required],
      category: ['', Validators.required],
      amount: ['', Validators.required],
    });

    // Check for duplicates when value changes
    this.checkForDuplicates();
  }

  generateKey(date: string, category: string, amount: number): string {
    return `${date}-${category}-${amount}`;
  }
  genertKeys(category: string, amount: number): string {
    return `${category}-${amount}`;
  }
  subscribeToRowChanges(row: FormGroup) {
    row?.get('date')?.valueChanges.subscribe(() => {
      this.checkForDuplicates();
    });
    row?.get('category')?.valueChanges.subscribe(() => {
      this.checkForDuplicates();
    });
    row?.get('amount')?.valueChanges.subscribe(() => {
      this.checkForDuplicates();
    });
  }
  checkForDuplicates() {
    const control = this.expenseform.get('tableRows') as FormArray;
  
    for (let i = 0; i < control.length; i++) {
      const row1 = control.at(i) as FormGroup;
      const row1Date = this.datepipe.transform(row1.get('date')?.value, 'yyyy-MM-dd');
      const row1Category = row1.get('category')?.value;
      const row1Amount = row1.get('amount')?.value;
  
      if (!row1Date || !row1Category || !row1Amount) {
        continue; // Skip if any value is missing
      }
  
      for (let j = i + 1; j < control.length; j++) {
        const row2 = control.at(j) as FormGroup;
        const row2Date = this.datepipe.transform(row2.get('date')?.value, 'yyyy-MM-dd');
        const row2Category = row2.get('category')?.value;
        const row2Amount = row2.get('amount')?.value;
  
        if (row1Date === row2Date && row1Category === row2Category && row1Amount === row2Amount) {
          Swal.fire({
            icon: 'error',
            text: 'Duplicate Entry : Same Date, Category, and Amount Are Not Allowed!',
          });
  
          // Clear the duplicate values to force a new selection
          row2.get('category')?.setValue(null);
          row2.get('amount')?.setValue(null);
          return;  // Exit after showing the alert to avoid multiple alerts
        }
      }
    }
  }
  

  deleteRow(index: number, group) {
    if (this.ActivatedRoute.snapshot.params.expensereimbursementId) {
      const control = this.expenseform.get('tableRows') as FormArray;
      control.removeAt(index);
      this.calucateamountondeleterow();
      console.log(group);
      let expenseId = group.value.expenseId;
      console.log('expenseId' + expenseId);
      if (expenseId) {
        this.calucateamountondeleterow();
        console.log('has expense id');
        this.expenseservice.deleteexpense(expenseId).subscribe((data: any) => {
          Swal.fire({
            icon: 'success',
            text: data.description,
          });
        });
      }
    } else {
      const control = this.expenseform.get('tableRows') as FormArray;
      control.removeAt(index);
      this.calucateamountondeleterow();
    }
  }
  calucateamountondeleterow() {
    console.log("delete Row");
    const control = this.expenseform.get('tableRows') as FormArray;
    this.touchedRows = control.controls.map((row) => row.value);

    this.subTotal = 0;
    this.touchedRows.forEach((element) => {
      console.log('amount valide' + element.amount);
      if (element){
        if(this.expenseReimbursement != undefined){
          if (this.subTotal != undefined) {
            console.log("if");
            this.subTotal = Number(this.subTotal) + Number(element.amount);
            console.log("this.subTotal",this.subTotal);
            const currentsubTotal = this.subTotal;
            console.log("currentsubTotal",currentsubTotal);
            const initialSubtotal = this.expenseReimbursement.subTotal;
            console.log("initialSubtotal",initialSubtotal);
  
            this.totalRebusriment = this.expenseReimbursement.totalReimbursement;
            console.log("this.totalRebusriment",this.totalRebusriment);
            this.advancebalance = this.expenseReimbursement.advanceBalance;
            console.log("this.advancebalance",this.advancebalance);

  
            if(initialSubtotal >= currentsubTotal){ //1000 >=1700
              const subtractsubtotal = initialSubtotal - currentsubTotal;
              // if(subtractsubtotal >= this.advancebalance){
              //  this.advancebalance = this.advancebalance + subtractsubtotal;
              //  this.totalRebusriment = 0;
              // }
              // else{ 
              //   this.advancebalance = this.advancebalance - subtractsubtotal;
              //   this.totalRebusriment = 0;
              // }
              this.advancebalance = this.expenseReimbursement.advanceBalance;
               this.advancebalance = this.advancebalance + subtractsubtotal;
               this.totalRebusriment = 0;
            }
            else{  //1000 >=1700
              console.log("else");
              const subtractsubtotal = currentsubTotal - initialSubtotal; // 1700 - 1000  
              
              if(subtractsubtotal >= this.advancebalance){ // 700>=500
                this.advancebalance = this.expenseReimbursement.advanceBalance;
                this.totalRebusriment = subtractsubtotal- this.advancebalance; //700 - 500
                this.advancebalance =0;
              }
              else{ 
                this.advancebalance = this.expenseReimbursement.advanceBalance; //500
                this.advancebalance = this.advancebalance - subtractsubtotal;
                this.totalRebusriment = 0;
              }
            }
          } 
          else{
            console.log("else onlyyyyyyyyy");
            
            this.subTotal = Number(element.amount);
          }
        }
        else{
          this.subTotal = Number(this.subTotal) + Number(element.amount);
          this.seletedAdvanceType();
            // if (Number(this.subTotal) > Number(this.advancebalance)) {
            //   this.totalRebusriment =Number(this.subTotal) - Number(this.advancebalance);
            // } 
            // else {
            //   this.totalRebusriment = 0;
            // }
            // if (Number(this.subTotal) > Number(this.advancebalance)) {
            //   console.log("if total entering!!");
            //   this.totalRebusriment = Number(this.subTotal) - Number(this.advancebalance);
            // } else {
            //   console.log("else total entere!");
            //   this.advancebalance = Number(this.advancebalance) - Number(this.subTotal);
            //   this.totalRebusriment = 0;
            // }
        }
      }
    });

    console.log('advancebalance' ,  this.advancebalance);
    console.log('this.subTotal',this.subTotal);


  }

  editRow(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.expenseform.value);
  }

  get getFormControls() {
    const control = this.expenseform.get('tableRows') as FormArray;
    return control;
  }
  // In your component class
  trackByFn(index: number, item: any) {
    console.log('track fn entering');

    return item.id; // Assuming each item in your form array has an 'id' property
  }

  // In your template

  saveasdraft() {
    this.expensearray = [];
    let value = this.expenseform.controls['reimbursementType']?.value;
    console.log('values are::', value);

    this.error = false;
    this.success = false;
    this.submitted = true;
    this.fruitConcat();
    console.log('this.expenseform', this.expenseform.value);

    if (this.skillsarray.length == 0) {
      console.log('businessPurpose is required');
      return;
    }

    if (this.expenseform.invalid) {
      const controls = this.expenseform.controls;
      console.log(this.expenseform.controls);
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
        }
      }
      if(this.advancebalance ==0 ){
        alert("Expense Can't Able to Create,without Creating An Advance amount!")
        return
      }
      this.loader = false;
      this.btnDisable = false;
      return;
    }
    this.btnDisable = true;
    this.Approval_btnDisable=true
    // if(this.totalRebusriment <= 0)
    // {
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Rebustiment Cannot be Zero "
    //   })

    //    return
    // }
    // console.log("this.expenseReimbursement.status::",this.expenseReimbursement.status.listTypeValueId);

    // this.loader=true;
    if (this.ActivatedRoute.snapshot.params.expensereimbursementId) {
      const control = this.expenseform.get('tableRows') as FormArray;
      this.touchedRows = control.controls
        .filter((row) => row)
        .map((row) => row.value);
      console.log(this.touchedRows);

      this.touchedRows.forEach(async (element, index) => {
        console.log('elemnt::', element);

        var createexpense;
        let date = this.datepipe.transform(element.date, 'yyyy-MM-dd');
        if (element.expenseId) {
          // console.log(" listTypeValueId:element.status::",element.status);

          createexpense = {
            status: this.expenseReimbursement.status,
            expenseId: element.expenseId,
            date: date,
            description: this.capitalizeWordsName(element.description),
            category: {
              expenseCategoryId: element.category,
            },

            //   expenseType:{
            //     listTypeValueId:element.expenseType
            // },
            amount: element.amount,
          };

          if (element.fileSource && element.fileBytes) {
            if (
              this.expenseReimbursement.expenses[index].expenseAttachment !=
              undefined
            ) {
              console.log('if' + index);
              createexpense['expenseAttachment'] = {
                fileId:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileId,
                fileName: element.fileSource.name,
                fileType: element.fileSource.type,
                fileSize: element.fileSource.size,
                fileBytes :element.fileBytes
              };
            } else {
              console.log('if' + index);
              createexpense['expenseAttachment'] = {
                fileName: element.fileSource.name,
                fileType: element.fileSource.type,
                fileSize: element.fileSource.size,
                fileId: element.fileId,
                fileBytes :element.fileBytes

              };
            }
          } else {
            //   alert("else");
            console.log('else' + index);
            if (
              this.expenseReimbursement.expenses[index].expenseAttachment !=
              undefined
            ) {
              createexpense['expenseAttachment'] = {
                fileId:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileId,
                fileName:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileName,
                fileType:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileType,
                fileSize:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileSize,
                fileViewUrl:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileViewUrl,
                fileDownloadUrl:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileDownloadUrl,
                pictureLocation:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .pictureLocation,
                status:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .status,
              };
            }
          }
        } else {
          // debugger;
          console.log('else came');

          createexpense = {
            status: {
              listTypeValueId: 1,
            },
            // // paymentStatus:{
            // //   listTypeValueId:1
            // // },
            //   date:date,
            //   description:this.capitalizeWordsName(element.description),
            //   category: {
            //     expenseCategoryId: element.category
            //   },
            // status:this.expenseReimbursement.status,
            // expenseId : element.expenseId,
            date: date,
            description: this.capitalizeWordsName(element.description),
            category: {
              expenseCategoryId: element.category,
            },

            //   expenseType:{
            //     listTypeValueId:element.expenseType
            // },
            amount: element.amount,
          };
          if (element.expenseAttachment) {
            createexpense['expenseAttachment'] = {
              fileName: element.fileSource.name,
              fileType: element.fileSource.type,
              fileSize: element.fileSource.size,
              // fileId :element.fileId,
              fileBytes: element.fileBytes,
              createdBy: this.storeEmpId,
            };
          }
        }
        console.log(createexpense);
        this.expensearray.push(createexpense);
      });

      // debugger;
      console.log(this.expensearray);
      console.log(
        'this.expenseReimbursement.expenseReimbursementId wtts::',
        this.expenseReimbursement.expenseReimbursementId
      );

      var expenseReimbursementupdate = {
        expenseReimbursementId:
          this.expenseReimbursement.expenseReimbursementId,
        employeeId: this.storeEmpId,
        // approver: {
        //   employeeId: this.employeedata.approver.employeeId
        // },
        fromDate: this.datepipe.transform(
          this.expenseform.value.startdate,
          'yyyy-MM-dd'
        ),
        toDate: this.datepipe.transform(
          this.expenseform.value.enddate,
          'yyyy-MM-dd'
        ),
        subTotal: this.subTotal,
        cashAdvance: this.advancebalance,
        totalReimbursement: this.totalRebusriment,
        ['draft']: true,
        organizationId: this.employeedata.organization.organizationId,
        expenses: this.expensearray,
        paymentStatus: {
          listTypeValueId:
            this.expenseReimbursement.paymentStatus.listTypeValueId,
        },
        status: this.expenseReimbursement.status,
        approvalStatus: this.expenseReimbursement.approvalStatus,
        expenseType: {
          listTypeValueId: this.expenseform.value.expenseType,
        },
        reimbursementType: {
          listTypeValueId: this.expenseform.value.reimbursementType,
        },
        projectName: this.expenseform.value.projectName,
        submittedOn: this.today,
        approvedOn: this.today,
        businessPurpose: this.fruitsdata,
        description: this.expenseform.value.descri,
      };

      console.log(expenseReimbursementupdate);
      // this.btnDisable =false;
      //  return
      this.expenseservice
        .updateexpense(
          this.expenseReimbursement.expenseReimbursementId,
          expenseReimbursementupdate
        )
        .subscribe(
          (a: any) => {
            // this.loader = false;
            this.success = true;
            this.create_sucess_msg = a.description;
            this.btnDisable = true;
            this.Approval_btnDisable=true

            console.log(this.create_sucess_msg);
            setTimeout(() => {
              // this.dialog.closeAll();
              this.btnDisable = true;
              this.Approval_btnDisable=true

              this.router.navigate(['home/expenseReimbursement']);
            }, 5000);
            this.scrollToEditForm();

          },
          (err) => {
            this.btnDisable = false;
            // this.loader = false;
            this.error = true;
            this.update_error_msg = err.error.message;
            console.log(this.update_error_msg);
            setTimeout(() => {
              this.error = false;
            }, 5000);
            this.scrollToEditForm();

          }
        );
    } else {
      // if ( this.advancebalance === 0) {
      //   // Show an alert instead of submitting the form
      //   Swal.fire({
      //     icon: 'warning',
      //     title: 'Invalid Submission',
      //     text: 'Cash Advance and Advance Balance cannot both be 0.',
      //   });
      //   this.btnDisable=false;

      //   return;  // Stop the form submission
      // }
      const control = this.expenseform.get('tableRows') as FormArray;
      this.touchedRows = control.controls
        .filter((row) => row)
        .map((row) => row.value);
      console.log(this.touchedRows);

      this.touchedRows.forEach(async (element) => {
        console.log('element', element);

        let date = this.datepipe.transform(element.date, 'yyyy-MM-dd');

        var createexpense;
        createexpense = {
          //  status:{
          //   listTypeValueId:1
          //  } ,
          date: date,
          description: this.capitalizeWordsName(element.description),
          category: {
            expenseCategoryId: element.category,
          },
          amount: element.amount,
          // expenseType : element.expenseType,
          // expenseId : element.expenseId,
        };
        if (element.expenseAttachment) {
          createexpense['expenseAttachment'] = {
            fileName: element.fileSource.name,
            fileType: element.fileSource.type,
            fileSize: element.fileSource.size,
            // fileId :element.fileId,
            fileBytes: element.fileBytes,

            createdBy: this.storeEmpId,
          };
        }

        console.log(createexpense);
        this.expensearray.push(createexpense);
      });
      console.log('this.expenseform', this.expenseform.value);
      var payload = {
        employeeId: this.storeEmpId,
        // approver: {
        //   employeeId: this.employeedata.approver.employeeId
        // },
        fromDate: this.datepipe.transform(
          this.expenseform.value.startdate,
          'yyyy-MM-dd'
        ),
        toDate: this.datepipe.transform(
          this.expenseform.value.enddate,
          'yyyy-MM-dd'
        ),
        subTotal: this.subTotal,
        // cashAdvance: this.advancebalance,
        advanceAmount:this.cashAdvance,
        totalReimbursement: this.totalRebusriment,
        ['draft']: true,
        // approvalStatus: {
        //   listTypeValueId: 3
        // },
        // organizationId: this.employeedata.organization.organizationId,
        expenses: this.expensearray,
        expenseType: {
          listTypeValueId: this.expenseform.value.expenseType,
        },
        paymentStatus: {
          listTypeValueId: 1,
        },
        projectName: this.expenseform.value.projectName,
        businessPurpose: this.fruitsdata,
        description: this.expenseform.value.descri,
        status: {
          listTypeValueId: 1,
        },
        // paymentStatus:{
        //   listTypeValueId:1
        // },
        createdBy: this.storeEmpId,
        organizationId: this.employeedata.organization.organizationId,
        reimbursementType: {
          listTypeValueId: this.expenseform.value.reimbursementType,
        },

        submittedOn: this.today,
      };
      if(this.totalRebusriment == 0)
        {
          payload['cashAdvance']= this.advancebalance
        }
        else{
          payload['cashAdvance']= 0
        }

        if(this.totalRebusriment == 0)
      {
                      console.log("zero");
                      payload['advance'] ={
              // advanceId:this.advancebalancedata.advanceId,
              advanceBalance:this.advancebalance - this.subTotal
          };
      }else{
                console.log("not zero");
                payload['advance'] ={
                  // advanceId:this.advancebalancedata.advanceId,
                  advanceBalance:0
                }
        }
      console.log('payload', payload);
      // console.log("check wtts::",this.expenseform.value.this.storeRebrsType);

      // this.loader=true;
      // this.btnDisable =false;
      // return
      this.expenseservice.createexpense(payload).subscribe(
        (a: any) => {
          // this.loader = false;
          this.success = true;
          this.create_sucess_msg = a.description;
          console.log(this.create_sucess_msg);
          setTimeout(() => {
            this.router.navigate(['home/expenseReimbursement']);
          }, 5000);
          this.scrollToEditForm();

        },
        (err) => {
          this.btnDisable = false;
          this.loader = false;
          this.error = true;
          this.expensearray.length = 0;
          this.update_error_msg = err.error.message;
          console.log(this.update_error_msg);
          setTimeout(() => {
            this.error = false;
          }, 5000);
          this.scrollToEditForm();

        }
      );
    }
    // this.trackByFn(1,1)
  }
  setElement(){
    this.advancebalance=0;
    // cashAdvance=0
  }
  getListReiumbursement() {
    this.expenseservice.listCategoryType().subscribe((result: any) => {
      this.advanceTypeData = result;
      console.log(this.advanceTypeData);
      this.advanceTypeSearchData = result;
      console.log(this.advanceTypeSearchData);

      console.log(this.advanceTypeData[0].listTypeValueId);
    });
  }
  elementFun(){
    console.log("elementFun!!");
    
    this.advancebalance = [];
    this.advancebalance = 0;
    this.subTotal = 0; // Set subtoltotal to 0


  }
  listTypeValueid(event: any) {
    this.selectedadvance = event.value;
    // console.log('event', event);
    // console.log(event.value);

    // if (event && event.target && typeof event.target.value !== 'undefined') {
    //     this.isOptionSelected = event.target.value !== '';
    //     console.log("listTypeValueid::", this.listTypeValueid);
    // } else {
    //     // console.error('Event or its properties are undefined.');
    // }
    // if (event.value === undefined) {
    //   this.elementFun(); // Reset fields if "- Select Element -" is selected
    // } else {
    //   this.expenseservice.listCategoryType().subscribe((result: any) => {
    //     this.advanceTypeData = result;
    //     console.log(this.advanceTypeData);
    //     this.advanceTypeSearchData = result;
    //     console.log(this.advanceTypeSearchData);
  
    //     console.log(this.advanceTypeData[0].listTypeValueId);
    //   });
    // this.loader=true
      this.expenseservice
        .getadvancebalancegivento(this.storeEmpId, event.value)
        .subscribe(
          (advancebalance: any) => {
            if (advancebalance ) {
              console.log('advancebalance::', advancebalance);
                 this.advancebalancedata = advancebalance;
                //  this.loader=false
                if (Array.isArray(advancebalance)){
                  advancebalance.forEach((balance: any) => {
                    // Assuming you want to sum up some balance field
                    // this.advancebalance = balance.advanceAmount; 
                                        this.advancebalance = balance.advanceBalance; 
                                        this.cashAdvance = balance.advanceAmount;

                     // Example: sum the 'amount' field
                     console.log(" this.advancebalancedata::", this.advancebalance);
                     
                  });
                }
                
            // this.advancebalance = Number(advancebalance.advanceBalance);

            console.log("  this.advancebalance::",  this.advancebalance);
            
      
              if (this.advancebalancedata.statusCode === 204) {
                console.log('No data entered');
                // Set cashAdvance and advancebalance to 0 when no data is returned
                this.cashAdvances = 0;
                this.advancebalance = 0;
              }
            }


              if (Number(this.subTotal) > Number(this.advancebalance)) {
                console.log("if total entering!!");
                
                this.totalRebusriment =Number(this.subTotal) - Number(this.advancebalance);
                // this.cashAdvances=0
              } else {
                console.log("else total entere!");
                this.advancebalance = Number(this.advancebalance) - Number(this.subTotal);
                this.totalRebusriment = 0;
              }

          },
          (error: any) => {
            // Handle error
          }
        );
    }
  // }
  seletedAdvanceType(){
    this.expenseservice
    .getadvancebalancegivento(this.storeEmpId, this.selectedadvance)
    .subscribe(
      (advancebalance: any) => {
        if (advancebalance ) {
          console.log('advancebalance::', advancebalance);
             this.advancebalancedata = advancebalance;
            //  this.loader=false
            if (Array.isArray(advancebalance)){
              advancebalance.forEach((balance: any) => {
                // Assuming you want to sum up some balance field
                // this.advancebalance = balance.advanceAmount; 
                                    this.advancebalance = balance.advanceBalance; 
                                    this.cashAdvance = balance.advanceAmount;

                 // Example: sum the 'amount' field
                 console.log(" this.advancebalancedata::", this.advancebalance);
                 
              });
            }
            
        // this.advancebalance = Number(advancebalance.advanceBalance);

        console.log("  this.advancebalance::",  this.advancebalance);
        
  
          if (this.advancebalancedata.statusCode === 204) {
            console.log('No data entered');
            // Set cashAdvance and advancebalance to 0 when no data is returned
            this.cashAdvances = 0;
            this.advancebalance = 0;
          }
        }


          if (Number(this.subTotal) > Number(this.advancebalance)) {
            console.log("if total entering!!");
            
            this.totalRebusriment =Number(this.subTotal) - Number(this.advancebalance);
            // this.cashAdvances=0
          } else {
            console.log("else total entere!");
            this.advancebalance = Number(this.advancebalance) - Number(this.subTotal);
            this.totalRebusriment = 0;
          }

      },
      (error: any) => {
        // Handle error
      }
    );
}
  

  submitForm() {
    this.expensearray = [];
    this.error = false;
    this.success = false;
    this.submitted = true;
    this.fruitConcat();
    console.log('this.expenseform', this.expenseform.value);
    if (this.skillsarray.length == 0) {
      console.log('businessPurpose is required');
      // return
    }
    if (this.expenseform.invalid) {
      const controls = this.expenseform.controls;
      console.log(this.expenseform.controls);
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
        }
      }
      this.Approval_btnDisable = false;
      this.loader = false;

      return;
    }
    this.Approval_btnDisable = true;
    this.btnDisable=true

    // if(this.totalRebusriment <= 0)
    // {
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Rebustiment Cannot be Zero "
    //   })

    //    return
    // }

    // this.loader=true;
    if (this.ActivatedRoute.snapshot.params.expensereimbursementId) {
      const control = this.expenseform.get('tableRows') as FormArray;
      this.touchedRows = control.controls
        .filter((row) => row)
        .map((row) => row.value);
      console.log(this.touchedRows);
      // console.log("element::",element);

      this.touchedRows.forEach(async (element, index) => {
        console.log('element::', element);

        var createexpense;
        let date = this.datepipe.transform(element.date, 'yyyy-MM-dd');
        if (element.expenseId) {
          createexpense = {
            status: this.expenseReimbursement.status,
            expenseId: element.expenseId,
            date: date,
            description: this.capitalizeWordsName(element.description),
            category: {
              expenseCategoryId: element.category,
            },
            //   expenseType:{
            //     listTypeValueId:element.expenseType
            // },
            amount: element.amount,
          };

          if (element.fileSource && element.fileBytes) {
            if (
              this.expenseReimbursement.expenses[index].expenseAttachment !=
              undefined
            ) {
              console.log('if' + index);
              createexpense['expenseAttachment'] = {
                fileId:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileId,
                fileName: element.fileSource.name,
                fileType: element.fileSource.type,
                fileSize: element.fileSource.size,
                fileBytes: element.fileBytes,
              };
            } else {
              console.log('if' + index);
              createexpense['expenseAttachment'] = {
                fileName: element.fileSource.name,
                fileType: element.fileSource.type,
                fileSize: element.fileSource.size,
                fileBytes: element.fileBytes,
              };
            }
          } else {
            //   alert("else");
            console.log('else' + index);
            if (
              this.expenseReimbursement.expenses[index].expenseAttachment !=
              undefined
            ) {
              createexpense['expenseAttachment'] = {
                fileId:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileId,
                fileName:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileName,
                fileType:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileType,
                fileSize:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileSize,
                fileViewUrl:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileViewUrl,
                fileDownloadUrl:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .fileDownloadUrl,
                pictureLocation:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .pictureLocation,
                status:
                  this.expenseReimbursement.expenses[index].expenseAttachment
                    .status,
              };
            }
          }
        } else {
          createexpense = {
            status: {
              listTypeValueId: 1,
            },
            date: date,
            description: this.capitalizeWordsName(element.description),
            category: {
              expenseCategoryId: element.category,
            },
            //   expenseType:{
            //     listTypeValueId:element.expenseType
            // },
            amount: element.amount,
          };
          if (element.expenseAttachment) {
            createexpense['expenseAttachment'] = {
              fileName: element.fileSource.name,
              fileType: element.fileSource.type,
              fileSize: element.fileSource.size,
              fileId: element.fileId,
              fileBytes: element.fileBytes

            };
          }
        }

        console.log(createexpense);
        this.expensearray.push(createexpense);
      });

      console.log(this.expensearray);
      //  debugger;
      var expenseReimbursementupdate = {
        expenseReimbursementId:
          this.expenseReimbursement.expenseReimbursementId,
        employeeId: this.employeedata.employee.employeeId,
        // approver: {
        //   employeeId: this.employeedata.approver.employeeId
        // },
        fromDate: this.datepipe.transform(
          this.expenseform.value.startdate,
          'yyyy-MM-dd'
        ),
        toDate: this.datepipe.transform(
          this.expenseform.value.enddate,
          'yyyy-MM-dd'
        ),
        subTotal: this.subTotal,
        cashAdvance: this.advancebalance,
        // cashAdvance: this.cashAdvances,
        totalReimbursement: this.totalRebusriment,
        ['draft']: false,
        organizationId: this.employeedata.organization.organizationId,
        expenses: this.expensearray,
        status: this.expenseReimbursement.status,
        // approvalStatus : this.expenseReimbursement.approvalStatus,
        // approvalStatus:{
        //   listTypeValueId:3
        // },

        expenseType: {
          listTypeValueId: this.expenseform.value.expenseType,
        },
        projectName: this.expenseReimbursement.projectName,
        submittedOn: this.today,
        // approvedOn:this.today,
        businessPurpose: this.fruitsdata,
        description: this.expenseform.value.descri,
        reimbursementType: {
          listTypeValueId: this.expenseform.value.reimbursementType,
        },
      };
      if (this.expenseReimbursement.approvalStatus == undefined) {
        console.log('if entered');

        expenseReimbursementupdate['approvalStatus'] = {
          listTypeValueId: 3,
        };
      } else {
        console.log('else entered');

        expenseReimbursementupdate['approvalStatus'] =
          this.expenseReimbursement.approvalStatus;
      }

      console.log(
        'expenseReimbursementupdate payload::',
        expenseReimbursementupdate
      );
      // debugger;
      // return
      this.expenseservice
        .updateexpense(
          this.expenseReimbursement.expenseReimbursementId,
          expenseReimbursementupdate
        )
        .subscribe(
          (a: any) => {
            // this.loader = false;
            this.success = true;
            this.create_sucess_msg = a.description;
            this.Approval_btnDisable = true;
            this.btnDisable=true

            console.log(this.create_sucess_msg);
            this.scrollToEditForm();
            if (a.statusCode == 404) {
              // this.loader = false;
              this.success = false;
              this.error = true;
              this.update_error_msg = a.message;
              console.log(this.update_error_msg);
              this.scrollToEditForm();
            }
            setTimeout(() => {
              // this.dialog.closeAll();
              this.Approval_btnDisable = true;
              this.btnDisable=true

              this.router.navigate(['home/expenseReimbursement']);
            }, 5000);
          },
          (err) => {
            // this.loader = false;
            this.error = true;
            this.Approval_btnDisable = false;
            this.update_error_msg = err.error.message;
            console.log(this.update_error_msg);
            setTimeout(() => {
              this.error = false;
            }, 5000);
            this.scrollToEditForm();
          }
        );
    } else {
      const control = this.expenseform.get('tableRows') as FormArray;
      this.touchedRows = control.controls
        .filter((row) => row)
        .map((row) => row.value);
      console.log(this.touchedRows);

      this.touchedRows.forEach(async (element) => {
        let date = this.datepipe.transform(element.date, 'yyyy-MM-dd');

        var createexpense;
        createexpense = {
          status: {
            listTypeValueId: 1,
          },
          date: date,
          description: this.capitalizeWordsName(element.description),
          category: {
            expenseCategoryId: element.category,
          },
          amount: element.amount,
          // expenseType : element.expenseType,
        };
        if (element.expenseAttachment) {
          createexpense['expenseAttachment'] = {
            fileName: element.fileSource.name,
            fileType: element.fileSource.type,
            fileSize: element.fileSource.size,
            fileBytes: element.fileBytes,
          };
        }

        console.log(createexpense);
        this.expensearray.push(createexpense);
      });

      var expenseReimbursement = {
        employee: {
          employeeId: this.employeedata.employeeId,
        },
        // approver: {
        //   employeeId: this.employeedata.approver.employeeId
        // },
        fromDate: this.datepipe.transform(
          this.expenseform.value.startdate,
          'yyyy-MM-dd'
        ),
        toDate: this.datepipe.transform(
          this.expenseform.value.enddate,
          'yyyy-MM-dd'
        ),
        subTotal: this.subTotal,
        cashAdvance:  this.advancebalance,
        totalReimbursement: this.totalRebusriment,
        reimbursementType: {
          listTypeValueId: this.expenseform.value.this.storeRebrsType,
        },
        ['draft']: false,
        approvalStatus: {
          listTypeValueId: 3,
        },
        organization: {
          organizationId: this.employeedata.organization.organizationId,
        },
        expenses: this.expensearray,
        expenseType: {
          listTypeValueId: this.expenseform.value.expenseType,
        },
        paymentStatus: {
          listTypeValueId: 1,
        },
        projectName: this.expenseform.value.projectName,
        businessPurpose: this.fruitsdata,
        description: this.expenseform.value.descri,
        submittedOn: this.today,
      };

      if (this.totalRebusriment == 0) {
        console.log('zero');
        expenseReimbursement['advance'] = {
          advanceId: this.advancebalancedata.advanceId,
          advanceBalance: this.advancebalance - this.subTotal,
        };
      } else {
        console.log('not zero');
        expenseReimbursement['advance'] = {
          advanceId: this.advancebalancedata.advanceId,
          advanceBalance: 0,
        };
      }
      console.log(expenseReimbursement);
      this.loader = true;
      // return
      this.expenseservice.createexpense(expenseReimbursement).subscribe(
        (a: any) => {
          // this.loader = false;
          this.success = true;
          this.create_sucess_msg = a.description;
          console.log(this.create_sucess_msg);
          this.scrollToEditForm();
          setTimeout(() => {
            this.router.navigate(['home/expenseReimbursement']);
          }, 5000);
        },
        (err) => {
          // this.loader = false;
          this.error = true;
          this.expensearray.length = 0;
          this.update_error_msg = err.error.message;
          console.log(this.update_error_msg);
          this.scrollToEditForm();
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
      );
    }
  }

  /** @type {Event} evt */
  async readFile(evt) {
    const file = evt;
    if (!file) return;
    const data = await file.text();
    return data;
  }
  ngAfterViewInit() {
    // Scroll to the edit form after the view is initialized
    this.scrollToEditForm();
  }
  changeexpensetype(event) {
    console.log(event.value);
    this.expensevalue = event.value;
    if (this.expensevalue == 14) {
      console.log('if');
      this.showprojectname = true;
      this.expenseform
        .get('projectName')
        .setValidators([
          Validators.required,
          Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
        ]);
      if (this.expenseReimbursement.projectName != undefined) {
        this.expenseform.patchValue({
          projectName: this.expenseReimbursement.projectName,
        });
      }
    } else {
      console.log('else');
      this.expenseform.controls['projectName'].clearValidators();
      console.log(this.expenseform.controls);
      this.showprojectname = false;
      this.expenseform.patchValue({
        projectName: '',
      });
    }
  }
  noNumber(event: any) {
    // const pattern = /[0-9]|\./;
    // const inputChar = String.fromCharCode(event.charCode);
    // if (event.keyCode !== 8 && !pattern.test(inputChar)) {
    //   event.preventDefault();
    // }
    const inputValue = event.target.value;

    // Check if the pressed key is not a number or backspace
    if (isNaN(Number(event.key)) && event.key !== 'Backspace') {
      event.preventDefault();
    }

    // Check if the input length exceeds the maxlength
    if (
      inputValue.length >= event.target.maxLength &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  }

  noPaste(event: any) {
    // Prevent pasting non-numeric characters
    const clipboardData = event.clipboardData.getData('text/plain');
    if (isNaN(Number(clipboardData))) {
      event.preventDefault();
    }
  }
  noSpace(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }
  noTab(event: KeyboardEvent) {
    // Prevent input if the Tab key is pressed
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  }

  get startdate() {
    return this.expenseform?.get('startdate');
  }
  get enddate() {
    return this.expenseform?.get('enddate');
  }
  get expenseType() {
    return this.expenseform?.get('expenseType');
  }
  get projectName() {
    return this.expenseform?.get('projectName');
  }
  get descri() {
    return this.expenseform?.get('descri');
  }
  get businessPurpose() {
    return this.expenseform?.get('businessPurpose');
  }
  get reimbursementType() {
    return this.expenseform?.get('reimbursementType');
  }
  setendate(event: any) {
    this.min = this.datepipe.transform(event.target.value, 'yyyy-MM-dd');
    this.to_date = this.datepipe.transform(
      this.expenseform.value.enddate,
      'yyyy-MM-dd'
    );

    if (this.to_date != '' && this.to_date != '') {
      if (this.to_date < this.min) {
        this.isenddate_Valid = true;
      } else {
        this.isenddate_Valid = false;
      }
      const control = this.expenseform.get('tableRows') as FormArray;
      this.touchedRows = control.controls
        .filter((row) => row)
        .map((row) => row.value);

      this.touchedRows.forEach(async (element, index) => {
        this.expensedate = this.datepipe.transform(element.date, 'yyyy-MM-dd');

        if (this.expensedate < this.min) {
          control.at(index).patchValue({
            date: '',
          });
        } else {
          control.at(index).patchValue({
            date: element.date,
          });
        }
      });
    }
  }

  setmaxendate(event: any) {
    this.max = this.datepipe.transform(event.target.value, 'yyyy-MM-dd');
    this.from_date = this.datepipe.transform(
      this.expenseform.value.startdate,
      'yyyy-MM-dd'
    );

    if (this.max) {
      this.startDate_disable = false;
    }
    if (this.max < this.from_date) {
      this.isenddate_Valid = true;
    } else {
      this.isenddate_Valid = false;
    }
    const control = this.expenseform.get('tableRows') as FormArray;
    this.touchedRows = control.controls
      .filter((row) => row)
      .map((row) => row.value);

    this.touchedRows.forEach(async (element, index) => {
      this.expensedate = this.datepipe.transform(element.date, 'yyyy-MM-dd');
      if (this.expensedate > this.max) {
        control.at(index).patchValue({
          date: '',
        });
      } else {
        control.at(index).patchValue({
          date: element.date,
        });
      }
    });
  }

  capitalizeWordsName(text) {
    return text.replace(/(?:^|\s)\S/g, (res) => {
      return res.toUpperCase();
    });
  }

  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  getFileDetails(event, i, group) {
    this.fileChoosen[i] = true;
    console.log(group);
    console.log('index' + i);
    const files = event.target.files;
    console.log(files);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filename = file.name;

      let reader = new FileReader();

      reader.onload = () => {
        let x: any = reader.result;
        let y: any = x.split(',');
        let sourceByteArray = y[1];
        group.patchValue({
          fileSource: file,
          fileBytes: sourceByteArray,
        });
      };

      reader.readAsDataURL(file);
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  scrollToEditForm() {
    const editFormElement =
      this.elementRef.nativeElement.querySelector('#exepensecard');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  // calucateamount(i) {
  //   console.log('calucate index' + i);
  //   const tableRowsArray = this.expenseform.get('tableRows') as FormArray;
  //   // this.touchedRows = control.controls
  //   // .filter((row) => row)
  //   // .map((row) => row.value);
  //   const employeeFormGroup = tableRowsArray.at(i) as FormArray;
  //   tableRowsArray.controls.forEach((row, index) => {
  //     if (this.ActivatedRoute.snapshot.params.expensereimbursementId) {
  //       let amountField = row.get('amount').value;
  //       let totalAmount = tableRowsArray.controls.reduce((sum, row) => {
  //         const amountValue = row.get('amount')?.value || 0; // Get the amount, default to 0 if it's null or undefined
  //         return sum + Number(amountValue); // Ensure it's a number before adding
  //       }, 0);
  //       this.subTotal = totalAmount;

  //       if (this.currentAmount < totalAmount) {
  //         let subtractCurrentAmount = totalAmount - this.currentAmount;
  //         this.advancebalance = this.expenseReimbursement.advanceBalance;

  //      if (subtractCurrentAmount >= this.advancebalance) {
  //           console.log('>');
  //           this.totalRebusriment = subtractCurrentAmount - this.advancebalance;
  //           this.advancebalance = 0;
  //         }
          
  //         else if (subtractCurrentAmount < this.advancebalance) {
  //           console.log('<');

  //           this.advancebalance = this.advancebalance - subtractCurrentAmount;
  //           this.totalRebusriment = 0;
  //         }
  //       }
  //       // return
  //       else if (totalAmount <= this.currentAmount) {

  //         console.log('lessthan');
  //         this.advancebalance = this.expenseReimbursement.advanceBalance;
  //         let subtractCurrentAmount = this.currentAmount - totalAmount;
  //         this.advancebalance = this.advancebalance + subtractCurrentAmount;
  //         this.totalRebusriment =
  //         this.expenseReimbursement.totalReimbursement;
  //       }



  //     }
  //   });
  //   // this.touchedRows.forEach((element) => {
  //   //   console.log('amount valide' + element.amount);
  //   // if (element)
  //   //   if (this.subTotal != undefined) {

  //   //     this.subTotal = Number(this.subTotal) + Number(element.amount);


  //   //   }

  //   //   else {
  //   //     console.log("else onlyyyyyyyyy");

  //   //     this.subTotal = Number(element.amount);



  //   //   }



  //   // ---- 10-10-2024--


  //   // });



  //   // if (Number(this.subTotal) > Number(this.advancebalance)) {
  //   //   console.log("if total entering!!");

  //   //   this.totalRebusriment = Number(this.subTotal) - Number(this.advancebalance);

  //   // } else {
  //   //   console.log("else total entere!");

  //   //   this.totalRebusriment = 0;

  //   // }
  // }
  calucateamount(i) {
    console.log('calucate index' + i);
    const control = this.expenseform.get('tableRows') as FormArray;
    this.touchedRows = control.controls
      .filter((row) => row)
      .map((row) => row.value);

    this.subTotal = 0;
    this.touchedRows.forEach((element) => {
      console.log('amount valide' + element.amount);
      if (element){
        console.log("this.expenseReimbursement",this.expenseReimbursement)
        if(this.expenseReimbursement != undefined){
          if (this.subTotal != undefined) {
            console.log("if");
            this.subTotal = Number(this.subTotal) + Number(element.amount);
            console.log("this.subTotal",this.subTotal);
            const currentsubTotal = this.subTotal;
            console.log("currentsubTotal",currentsubTotal);
            const initialSubtotal = this.expenseReimbursement.subTotal;
            console.log("initialSubtotal",initialSubtotal);
  
            this.totalRebusriment = this.expenseReimbursement.totalReimbursement;
            console.log("this.totalRebusriment",this.totalRebusriment);
            this.advancebalance = this.expenseReimbursement.advanceBalance;
            console.log("this.advancebalance",this.advancebalance);

  
            if(initialSubtotal >= currentsubTotal){ //1000 >=1700
              const subtractsubtotal = initialSubtotal - currentsubTotal;
              // if(subtractsubtotal >= this.advancebalance){
              //  this.advancebalance = this.advancebalance + subtractsubtotal;
              //  this.totalRebusriment = 0;
              // }
              // else{ 
              //   this.advancebalance = this.advancebalance - subtractsubtotal;
              //   this.totalRebusriment = 0;
              // }
              this.advancebalance = this.expenseReimbursement.advanceBalance;
               this.advancebalance = this.advancebalance + subtractsubtotal;
               this.totalRebusriment = 0;
            }
            else{  //1000 >=1700
              console.log("else");
              const subtractsubtotal = currentsubTotal - initialSubtotal; // 1700 - 1000  
              
              if(subtractsubtotal >= this.advancebalance){ // 700>=500
                this.advancebalance = this.expenseReimbursement.advanceBalance;
                this.totalRebusriment = subtractsubtotal- this.advancebalance; //700 - 500
                this.advancebalance =0;
              }
              else{ 
                this.advancebalance = this.expenseReimbursement.advanceBalance; //500
                this.advancebalance = this.advancebalance - subtractsubtotal;
                this.totalRebusriment = 0;
              }
            }
          } 
          else{
            console.log("else onlyyyyyyyyy");
            
            this.subTotal = Number(element.amount);
          }
        }
        else{
          this.subTotal = Number(this.subTotal) + Number(element.amount);
          // this.advancebalance = this.expenseReimbursement.advanceBalance;
          this.seletedAdvanceType();
          console.log("this.advancebalance",this.advancebalance);
          if (Number(this.subTotal) > Number(this.advancebalance)) {
            console.log("if total entering!!");
            this.totalRebusriment = Number(this.subTotal) - Number(this.advancebalance);
          } else {
            console.log("else total entere!");
            this.advancebalance = Number(this.advancebalance) - Number(this.subTotal);
            this.totalRebusriment = 0;
          }
        }
      }
    });
  }

  checkLength1() {
    const descriptionControl = this.expenseform.get('businessPurpose');
    const descriptionValue = descriptionControl?.value?.trim(); // Trim the input to check for spaces only
  
    // Check for space or double space validation
    if (descriptionControl?.value && !descriptionValue) {
      // Input is only spaces
      // alert('Please enter a valid Business purpose without leading spaces!');
      descriptionControl.setValue('');
      return;
    }
    
  }

}


 