import { Component, OnInit,Inject} from '@angular/core';
import { RecruitmentService } from '../../providers/recruitment.service';
import { Router , ActivatedRoute } from '@angular/router';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-position-detail',
  templateUrl: './position-detail.component.html',
  styleUrls: ['./position-detail.component.scss']
})
export class PositionDetailComponent implements OnInit {
  loader:any= true;
  positionobj:any;
  positionId:any;

  constructor(private positionservice :RecruitmentService,
    private activatedRoute : ActivatedRoute,
    // @Inject(MAT_DIALOG_DATA) public data: MatDialog
    )
     { }

  ngOnInit(): void {
    // this.positionobj =this.data;
    // console.log(this.data);

    this.positionId = this.activatedRoute.snapshot.params.positionId;
    // console.log(this.positionId)
    this.positionservice
    .positionbyid(this.activatedRoute.snapshot.params.positionId)
    .subscribe((data:any) =>
    {
      this.positionobj = data;
      this.loader = false;
      console.log(this.positionobj);

    })
  }

}

