import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
//import { AddDepartmentComponent } from '../add-department/add-department.component';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  }  from '../../../providers/properties';

@Component({
  selector: 'app-list-types',
  templateUrl: './list-types.component.html',
  styleUrls: ['./list-types.component.scss']
})
export class ListTypesComponent implements OnInit 
{

  public searchFilter: any = '';
  filteredData: any;
  nodatafound: boolean = false;


  constructor(
    private settingsservice: SettingsService,
    private dialog:MatDialog,
  )
  { }

  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;

  ngOnInit(): void 
  {
    this.listTypeList();
  }

  listTypeList()
  {
    this.settingsservice.getListTypeList().subscribe((result: any) => {
      this.resultData = result;
      this.nodatafound=false;
      if(result.statusCode==204)
        {
          console.log("nodata" ,this.resultData.length);
          this.nodatafound=true;
        }
        if(this.resultData.length==0)
        {
          console.log(this.resultData.length)
          this.nodatafound=true;
        }

    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

  applyFilter(): void {
    console.log('resultData:', this.resultData?.length);
console.log('filteredData:', this.filteredData?.length);
    console.log('applyFilter() called'); // Add this line
    this.p = 1;
    const normalize = (str: string) => str.toLowerCase().replace(/\s+/g, ''); // Normalize: lowercase and remove spaces

    this.filteredData = this.resultData.filter(employee =>
        normalize(employee.listTypeName).includes(normalize(this.tableSearch))
       
    );
     

      if (this.filteredData.length > 0) {
        // this.resultData=this.filteredData;
        console.log("111111111",this.filteredData)
        this.nodatafound = false; // Matches found
      } else {
        console.log("222222222",this.filteredData)
        this.nodatafound = true; // No matches found
        this.filteredData = [];
        // this.resultData = []; // Clear resultData

      } 
    console.log(this.resultData.length) }
}
