<!-- <div class="container">

    <br>
    
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <h4 style="font-size:large;">Approvers</h4>
      </div>
      <div class="col-md-6 col-sm-6">
        <span class="material-icons c" style="color:red; float: right;" mat-dialog-close>cancel</span>
      </div>
    </div>
   -->
                 
                      
           
          
           <!-- <div>
            <div *ngIf="appNew?.approver?.approverOneObject">
               <div class="row">
                    <div class="col-md-8">
                      <h6>{{appNew.approver.approverOneObject.firstName}} {{appNew.approver.approverOneObject.lastName}}</h6>
                      <h6 class="email-id-new">{{appNew.approver.approverOneObject.organizationEmail }}</h6>
                    </div>
                    <div class="col-md-4">               
                      <h6  class="approver-status" [style.color]="approvalStatus(appNew.approverOneStatus.listTypeValueName)" 
                      [style.backgroundColor]="approvalBackground(appNew.approverOneStatus.listTypeValueName)" align="center"> {{ appNew.approverOneStatus.listTypeValueName }} </h6>
                    </div>                
                </div>
               <hr/>
            </div>
            <div *ngIf="appNew?.approver?.approverOneObject">
              <div class="row">
                   <div class="col-md-8">
                     <h6>{{appNew.approver.approverTwoObject.firstName}} {{appNew.approver.approverTwoObject.lastName}}</h6>
                     <h6 class="email-id-new">{{appNew.approver.approverTwoObject.organizationEmail }}</h6>
                   </div>
                   <div class="col-md-4">               
                     <h6  class="approver-status" [style.color]="approvalStatus(appNew.approverTwoStatus.listTypeValueName)" 
                     [style.backgroundColor]="approvalBackground(appNew.approverTwoStatus.listTypeValueName)" align="center"> {{ appNew.approverTwoStatus.listTypeValueName }} </h6>
                   </div>                
               </div>
              <hr/>
           </div>
  </div> -->



<!-- 

  <div class="container">
    <div class="top-content">        
        <h1>Approvers</h1>
        <span class="material-icons c" style="color:red;"  mat-dialog-close>cancel</span>
    </div>
    <div>
        <div>
            <div class="row">
                <div class="col-md-8">
                    <h4>{{ appNew?.approver?.approverOneObject?.firstName}}</h4>
                    <h6 class="email-id-new">{{appNew?.approver?.approverOneObject?.organizationEmail}}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="appNew?.approverOneStatus?.listTypeValueName == 'Pending'" align="center"> 
                    {{appNew?.approverOneStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="appNew?.approverOneStatus?.listTypeValueName == 'Approved'" align="center"> 
                    {{appNew?.approverOneStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="appNew?.approverOneStatus?.listTypeValueName == 'Denied'" align="center"> 
                    {{appNew?.approverOneStatus?.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div *ngIf="appNew?.approver?.approverTwo">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{appNew?.approver?.approverTwoObject?.firstName}}</h4>
                    <h6 class="email-id-new">{{appNew?.approver?.approverTwoObject?.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="appNew?.approverTwoStatus?.listTypeValueName == 'Pending'" align="center"> 
                    {{appNew?.approverTwoStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="appNew?.approverTwoStatus?.listTypeValueName == 'Approved'" align="center"> 
                    {{appNew?.approverTwoStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="appNew?.approverTwoStatus?.listTypeValueName == 'Denied'" align="center"> 
                    {{appNew?.approverTwoStatus?.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div *ngIf="appNew?.approver?.approverThree">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{appNew?.approver?.approverThreeObject?.firstName}}</h4>
                    <h6 class="email-id-new">{{appNew?.approver?.approverThreeObject?.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="appNew?.approverThreeStatus?.listTypeValueName == 'Pending'" align="center"> 
                    {{appNew?.approverThreeStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="appNew?.approverThreeStatus?.listTypeValueName == 'Approved'" align="center"> 
                    {{appNew?.approverThreeStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="appNew?.approverThreeStatus?.listTypeValueName == 'Denied'" align="center"> 
                    {{appNew?.approverThreeStatus?.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div  *ngIf="appNew?.approver?.approverFour">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{appNew?.approver?.approverFourObject?.firstName}}</h4>
                    <h6 class="email-id-new">{{appNew?.approver?.approverFourObject?.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="appNew?.approverFourStatus?.listTypeValueName == 'Pending'" align="center"> 
                    {{appNew?.approverFourStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="appNew?.approverFourStatus?.listTypeValueName == 'Approved'" align="center"> 
                    {{appNew?.approverFourStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="appNew?.approverFourStatus?.listTypeValueName == 'Denied'" align="center"> 
                    {{appNew?.approverFourStatus?.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div  *ngIf="appNew?.approver?.approverFive">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{appNew?.approver?.approverFiveObject?.firstName}}</h4>
                    <h6 class="email-id-new">{{appNew?.approver?.approverFiveObject?.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="appNew?.approverFiveStatus?.listTypeValueName == 'Pending'" align="center"> 
                    {{appNew?.approverFiveStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="appNew?.approverFiveStatus?.listTypeValueName == 'Approved'" align="center"> 
                    {{appNew?.approverFiveStatus?.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="appNew?.approverFiveStatus?.listTypeValueName == 'Denied'" align="center"> 
                    {{appNew?.approverFiveStatus?.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div class="top-content">
            <h1>FYI</h1>
        </div>
        <div>
            <div class="row" >
                <div class="col-md-6">
                    <h4>{{appNew?.approver?.fyiObject?.firstName}}</h4>
                    <h6 class="email-id-new">{{appNew?.approver?.fyiObject?.organizationEmail }}</h6>
                </div>
           </div>
        </div> 
    </div>
    <div class="card-body" *ngIf="nodata">
        <div class=" ">
          <h4 align="center" style="text-align: center;
          position: relative;
          /* left: 425px; */
          /* padding: 10px; */
          font-size: 19px;
          font-weight: 600;
          font-family: 'Mulish', sans-serif;
          "> Approvers Not Found</h4>
        </div>
      </div>
</div>
   -->




   <div class="container">
    <br />
    <!-- <h4 align="center">Assign Employee to this project</h4><br> -->
    <div class="top-content">
      <h4>Approvers</h4>
      <span class="material-icons mat-close" style="color: red" mat-dialog-close
        >cancel</span
      >
    </div>
  
    <div>
      
      <div  *ngIf="appNew?.approver?.approverOneObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ appNew?.approver?.approverOneObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ appNew?.approver?.approverOneObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="approvalStatus(appNew.approverOneStatus.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(appNew.approverOneStatus.listTypeValueName)
              "
              align="center"
            >
              <!-- {{ data?.approvalStatusName }} -->
              {{appNew.approverOneStatus.listTypeValueName}}
            </h6>
          </div>
        </div>
        <hr />
      </div>
      <div  *ngIf="appNew?.approver?.approverTwoObject" >
        <div class="row">
          <div class="col-md-8">
            <h6>{{ appNew?.approver?.approverTwoObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ appNew?.approver?.approverTwoObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4" >
            <h6
              class="approver-status"
              [style.color]="approvalStatus(appNew.approverTwoStatus.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(appNew.approverTwoStatus.listTypeValueName)
              "
              align="center"
            >
              {{appNew.approverTwoStatus.listTypeValueName}}
            </h6>
          </div>
        </div>
        <hr />
      </div>
      <div *ngIf="appNew?.approver?.approverThreeObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ appNew?.approver?.approverThreeObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ appNew?.approver?.approverThreeObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="
                approvalStatus(appNew.approverThreeStatus.listTypeValueName)
              "
              [style.backgroundColor]="
                approvalBackground(appNew.approverThreeStatus.listTypeValueName)
              "
              align="center"
            >
              {{ appNew.approverThreeStatus.listTypeValueName}}
            </h6>
          </div>
        </div>
        <hr />
      </div>
  
      <div *ngIf="appNew?.approver?.approverFourObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ appNew?.approver?.approverFourObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ appNew?.approver?.approverFourObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="approvalStatus(appNew?.approverFourStatus?.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(appNew?.approverFourStatus?.listTypeValueName)
              "
              align="center"
            >
              {{ appNew?.approverFourStatus?.listTypeValueName }}
            </h6>
          </div>
        </div>
        <hr />
      </div>
  
      <div *ngIf="appNew?.approver?.approverFiveObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ appNew?.approver?.approverFiveObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ appNew?.approver?.approverFiveObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="approvalStatus(appNew?.approverFiveStatus?.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(appNew?.approverFiveStatus?.listTypeValueName)
              "
              align="center"
            >
              {{ appNew?.approverFiveStatus?.listTypeValueName }}
            </h6>
          </div>
        </div>
        <hr />
      </div>
      <div class="top-content">
        <h4>FYI</h4>
      </div>
      <!-- <div> -->
        <!-- <div class="row"> -->
          <div class="col-md-6 mt-n3 ml-n3">
            <h6>{{ appNew?.approver?.fyiObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ appNew?.approver?.fyiObject?.organizationEmail }}
            </h6>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
  
  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem"
     
    ></div>
  </div>
  
  <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        <h4 align="center" class="h4">Approvers Not Found</h4>
      </div>
    </div>
  </mat-card>
  