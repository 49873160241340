import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { ViewApproversComponent } from '../view-approvers/view-approvers.component';
import { AddTimelogsComponent } from './add-timelogs/add-timelogs.component';
import { ExcelService } from 'src/app/providers/excel.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { Subscription } from 'rxjs';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { debounce } from 'lodash';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { HeaderService } from 'src/app/providers/header.service';
import { NgForm } from '@angular/forms';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { auto } from '@popperjs/core';
@Component({
  selector: 'app-emp-timelogs',
  templateUrl: './emp-timelogs.component.html',
  styleUrls: ['./emp-timelogs.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class EmpTimelogsComponent implements OnInit {
  @ViewChild('successMessageElement') successMessageElement: any;
  @ViewChild('myForm') timeForm: NgForm;
  successMessage: string = '';
  noDataFound: boolean;
  success: boolean = false;
  successUpdate: Boolean;
  warningMessage: string = '';
  warning: boolean = false;
  searchButton: boolean = true;
  successUpdateMessage: string;
  error: boolean = false;
  paginator: boolean = true;
  error_msg: string = '';
  checkErrorCode: number;
  nodataDates = false;
  tableShow: boolean = false;

  invalidData: number;
  submitToApprovals: any;
  selectedEmployeeSearch: any;
  private successMessageSubscription: Subscription;
  private afterTimesheetUpdation: Subscription;
  private successUpdateMessageSubscription: Subscription;
  private successWarningMessageSubscription: Subscription;
  private updateWarningMessageSubscription: Subscription;
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  Id: any;
  search: any;
  EmpList: any = ([] = []);
  User_Type;
  // p;
  beforesearchpage: any;
  beforepage: any;
  nodata = false;
  loader: boolean = true;
  startDate: any = undefined;
  endDate: any = undefined;
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  mySelect: any = 5;

  projectId: number = undefined;
  projectList: any = [];
  taskList: any;
  taskId: number = undefined;
  timesheetreport: any = [];
  isproject: boolean;
  timesheetDate: any;
  task_disable: boolean = true;
  employeeId: any;
  emptaskdate: any;
  formatteddate: any;
  project_required: boolean = false;
  exportdatalist: any = [];
  enddate_required: boolean;
  startdate_required: boolean;
  timesheetData: any;
  today = new Date();

  endSelectedFirst: boolean = false;
  // today :any;
  submitToApprovalData: any = [];
  organizationId: any;
  tasktotalTime: any;
  min: string; // Minimum value for start date
  max: string; // Maximum value for start date
  minEnd: string; // Minimum value for end date
  maxEnd: string;
  filteredEmpList: any;
  isAdmin:any;
  isEmployee:any
  usertData:any;
  userType: any;
  constructor(
    private dialog: MatDialog,
    private gs: ProjectManagementService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private timesheet: TimesheetService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private router: Router,
    private excelservice: ExcelService,
    private headerservice: HeaderService,
    private assignemp: AssignEmpService
  ) {
    this.max = this.getCurrentDate(); // Set max to current date initially
    this.minEnd = this.getCurrentDate();

    this.warning = false;
  }

  isStartDateGreaterThanEndDate(): boolean {
    alert('Start date cannot be greater than end date.');
    return this.startDate && this.endDate && this.startDate > this.endDate;
  }

  checkStartDate(): void {
    if (this.startDate && this.endDate) {
      // Convert dates to Date objects if they are not already
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      // Check if the start date is greater than the end date
      if (startDate > endDate) {
        alert('Start date cannot be greater than end date.');

        // Reset both startDate and endDate
        // this.startDate = undefined;
        // this.endDate = undefined;

        this.startDate = '';
        this.endDate = '';
        // this.startDate = undefined;
        console.log('after undefined', this.startDate);

        // Trigger change detection to update the UI
        // this.cdr.detectChanges();

        // Fetch employee time entry limit and update relevant data
        this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
          (data: any) => {
            this.loader = false;
            console.log('timesheettable', data);

            this.EmpList = data;
            // this.cdr.detectChanges();
            // this.ngZone.run(() => {
            //   // this.cdr.detectChanges();
            // });
            if ((data as any).errorCode === 204) {
              this.nodata = true;
              this.loader = false;
              this.tableShow = true;
            }
            console.log('tableData', data);

            this.tasktotalTime = this.EmpList[0]?.totalTime || 0;
            if (this.EmpList.length === 0) {
              this.nodata = true;
              console.log('nodata');
            }
          },
          (error) => {
            this.nodata = true;
            this.loader = false;
            console.log('error');
            if (error.status === 404) {
              this.nodata = true;
              console.log('404');
            }
          }
        );
      }
    }
  }

  ngOnInit() {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('local storage data::', this.usertData);
  

    this.userType = [];
    this.usertData.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    this.warning = false;
    // this.headerservice.setTitle('Timesheet');

    this.headerservice.setTitle('');
    this.assignemp.setSuccessUpdateMessage('');
    this.afterTimesheetUpdation = this.timesheet
      .getAfterSubmitTimesheet()
      .subscribe((message) => {
        if (message) {
          this.loader = true;
          this.tableShow = false;
        }
      });
    this.successMessageSubscription = this.timesheet
      .getSuccessMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        // this.loader = true;
        // this.tableShow = false;
        if (message) {
          // this.loader = true;
          // this.EmpList = [];
          this.success = true;
          this.successMessage = message;
          this.tableShow = false;
          this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
            (data: any) => {
              this.loader = false;
              console.log('timesheettable', data);
              this.EmpList = [];
              this.EmpList = data;
              this.nodata = false;
              this.tableShow = true;
              if ((data as any).errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.tableShow = true;
              }
              this.cdr.detectChanges();
              this.ngZone.run(() => {
                this.cdr.detectChanges();
              });
              this.cdr.detectChanges();
              console.log('tableData', data);

              this.tasktotalTime = this.EmpList[0].totalTime;
              if (this.EmpList.length == 0) {
                this.nodata = true;
                console.log('nodata');
              }
            },
            (error) => {
              this.nodata = true;
              this.loader = false;
              console.log('error');
              if (error.status == 404) {
                this.nodata = true;
                console.log('404');
              }
            }
          );
          this.warning = false;

          setTimeout(() => {
            this.success = false;
            this.successMessage = '';
            this.closeSuccessMessage();
            this.loader = false;
          }, 5000);
        }

        // Close the success message after 3 seconds (adjust duration as needed)
      });

    this.successUpdateMessageSubscription = this.timesheet
      .getSuccessUpdateMessage()
      .subscribe((message) => {
        if (message) {
          this.showUpdateSuccessMessage(message);

          setTimeout(() => {
            this.success = false;
            this.successMessage = '';
            this.loader = true;
            this.EmpList = [];
            this.tableShow = false;
            this.closeSuccessMessage();

            this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
              (data: any) => {
                this.loader = false;
                console.log('timesheettable', data);
                this.EmpList = [];
                this.EmpList = data;

                this.tableShow = true;

                if ((data as any).errorCode === 204) {
                  this.nodata = true;
                  this.loader = false;
                  this.tableShow = true;
                }
                this.cdr.detectChanges();
                this.ngZone.run(() => {
                  this.cdr.detectChanges();
                });
                this.cdr.detectChanges();
                console.log('tableData', data);

                this.tasktotalTime = this.EmpList[0].totalTime;
                if (this.EmpList.length == 0) {
                  this.nodata = true;
                  console.log('nodata');
                }
              },
              (error) => {
                this.nodata = true;
                this.loader = false;
                console.log('error');
                if (error.status == 404) {
                  this.nodata = true;
                  console.log('404');
                }
              }
            );
          }, 5000);
        }
      });

    setTimeout(() => {
      this.successUpdate = false;
      this.closeUpdateSuccessMessage();
    }, 5000);

    this.successWarningMessageSubscription = this.timesheet
      .getWarningSucessMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        if (message) {
          this.warning = true;
          this.warningMessage = message;
          setTimeout(() => {
            this.warning = false;
            this.warningMessage = '';
            this.closeWarningMessage();
          }, 4000);

          this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
            (data: any) => {
              this.loader = false;
              console.log('timesheettable', data);
              this.EmpList = [];
              this.EmpList = data;

              this.tableShow = true;

              if ((data as any).errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.tableShow = true;
              }
              this.cdr.detectChanges();
              this.ngZone.run(() => {
                this.cdr.detectChanges();
              });
              this.cdr.detectChanges();
              console.log('tableData', data);

              this.tasktotalTime = this.EmpList[0].totalTime;
              if (this.EmpList.length == 0) {
                this.nodata = true;
                console.log('nodata');
              }
            },
            (error) => {
              this.nodata = true;
              this.loader = false;
              console.log('error');
              if (error.status == 404) {
                this.nodata = true;
                console.log('404');
              }
            }
          );
        } else {
          this.warning = false;
          this.warningMessage = '';
          // setTimeout(() => {
          //   this.warning = false;
          //   this.warningMessage = '';
          //   this.closeWarningMessage();
          // }, 1000);
        }

        // Close the success message after 3 seconds (adjust duration as needed)
      });

    setTimeout(() => {
      this.warning = false;
      this.warningMessage = '';
      this.closeWarningMessage();
    }, 1000);

    this.updateWarningMessageSubscription = this.timesheet
      .getWarningUpdateMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        if (message) {
          this.warning = true;
          this.warningMessage = message;
        } else {
          this.warning = false;
          this.warningMessage = '';
        }

        // Close the success message after 3 seconds (adjust duration as needed)
        setTimeout(() => {
          this.success = false;
          // this.loader = true;
          this.closeWarningMessage();
          this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
            (data: any) => {
              this.loader = false;
              console.log('timesheettable', data);
              this.EmpList = [];
              this.EmpList = data;
              if ((data as any).errorCode === 204) {
                this.nodata = true;
                this.loader = false;
                this.tableShow = true;
              }
              this.tableShow = true;
              this.cdr.detectChanges();
              this.ngZone.run(() => {
                this.cdr.detectChanges();
              });
              this.cdr.detectChanges();
              console.log('tableData', data);

              this.tasktotalTime = this.EmpList[0].totalTime;
              if (this.EmpList.length == 0) {
                this.nodata = true;
                console.log('nodata');
              }
            },
            (error) => {
              this.nodata = true;
              this.loader = false;
              console.log('error');
              if (error.status == 404) {
                this.nodata = true;
                console.log('404');
              }
            }
          );
        }, 3000);
      });
    setTimeout(() => {
      this.warning = false;
      this.warningMessage = '';
      this.closeWarningMessage();
    }, 3000);
    this.today = new Date();
    // this.today = this.datepipe.transform(this.today, 'MMM YYYY');

    const localData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.Id = localData.employee.employeeId;
    this.employeeId = localData.employee.employeeId;
    this.organizationId = localData.organization.organizationId;

    console.log('id', this.Id);

    // this.userType = [];

    this.isAdmin = 
    (this.userType.includes('Employee') && this.userType.includes('Admin')) ||(this.userType.includes('Admin') && this.userType.includes('Human Resource')) ||(this.userType.includes('Admin') && this.userType.includes('Team Leader'));

    this.isEmployee = this.userType.includes('Employee') && !this.isAdmin;
    console.log("this employee",this.isEmployee);
    console.log("this is admin",this.isAdmin);
    
    

    // if (this.Id) {
   
    this.gs.getResourceByIds(this.Id).subscribe((x: any) => {
      console.log('getResourceId', x);
      this.User_Type = x?.userType?.userType;
      console.log(this.User_Type);
      console.log('this.User_Type', this.User_Type);
      console.log('getResourceId', x);

      // this.gs.listProjectList(this.organizationId).subscribe((pa: any) => {
      //   console.log('getEmpProjectList', pa);

      //   this.projectList = pa;
      //   console.log(this.projectList);
      // });

      //   this.gs.getProjectByEmployeeId(this.employeeId).subscribe((pa: any) => {
      //     console.log('getEmpProjectList', pa);

      //     this.projectList = pa;
      //     console.log(this.projectList);
      //   });
      // });
     

    

     
        this.gs.getProjectByEmployeeId(this.employeeId).subscribe((pa: any) => {
          console.log('getEmpProjectList', pa);
          this.projectList = Object.keys(pa).map((key) => pa[key]);
          // this.projectList = pa;
          console.log(this.projectList);
        }); 
      })
    
  
      
    
    // });
  
    this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
      (data: any) => {
        this.loader = false;
        console.log('timesheettable', data);

        this.EmpList = data;
        this.tableShow = true;
        if ((data as any).errorCode === 204) {
          this.nodata = true;
          this.loader = false;
          this.tableShow = true;
        }
        this.cdr.detectChanges();
        this.ngZone.run(() => {
          this.cdr.detectChanges();
        });
        this.cdr.detectChanges();

        this.tasktotalTime = this.EmpList[0].totalTime;

        console.log('totaltime', this.tasktotalTime);

        if (this.EmpList.length == 0) {
          this.nodata = true;
          console.log('nodata');
        }
      },
      (error) => {
        this.nodata = true;
        this.loader = false;
        console.log('error');
        if (error.status == 404) {
          this.nodata = true;
          console.log('404');
        }
      }
    );
    // }
  }

  ngOnDestroy() {
    console.log('calling ngOnDestroy');
    this.warningMessage = '';
    this.warning = false;

    if (this.successMessageSubscription) {
      this.successMessageSubscription.unsubscribe();
    }

    if (this.successUpdateMessageSubscription) {
      this.successUpdateMessageSubscription.unsubscribe();
    }

    if (this.successWarningMessageSubscription) {
      this.successWarningMessageSubscription.unsubscribe();
    }

    if (this.updateWarningMessageSubscription) {
      this.updateWarningMessageSubscription.unsubscribe();
    }
    this.warningMessage = '';
    this.warning = false;
    this.updateWarningMessageSubscription.unsubscribe();
    this.successWarningMessageSubscription.unsubscribe();
    this.timesheet.setWarningUpdateMessage('');
  }

  startDateChange(event: any) {
    // If the start date is changed, update the minimum date for the end date picker
    this.endDate = event.value;
  }

  endDateChange(event: any) {
    // If the end date is changed, update the maximum date for the start date picker
    this.startDate = event.value;
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    if (this.startDate && this.endDate) {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
      this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');

      console.log('2dates', startDate, endDate);

      if (this.endDate < this.startDate) {
        alert('Start date cannot be greater than end date.');
        this.startDate = '';
        this.endDate = '';
      } else {
      }
    }

    if (this.startDate === undefined && this.endDate !== undefined) {
      this.startdate_required = true;
      this.enddate_required = false;
      return;
    }

    if (this.startDate !== undefined && this.endDate !== undefined) {
      this.startdate_required = false;
      this.enddate_required = false;
      return;
    }

    if (this.endDate !== undefined) {
      this.enddate_required = false;
      return;
    }
  }

  mintaskstartDate(event: any) {
    this.minEnd = event.target.value; // Set the minimum value for end date
  }

  mintaskendDate(event: any) {
    this.max = event.target.value; // Set the maximum value for start date
  }

  getCurrentDate(): string {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if needed
    const day = today.getDate().toString().padStart(2, '0'); // Add leading zero if needed
    return `${today.getFullYear()}-${month}-${day}`;
  }

  checkEndDate() {
    if (this.startDate && this.endDate < this.startDate) {
      this.startDate = this.endDate;
    }
  }
  ngOnChanges(): void {
    this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
      (data: any) => {
        this.loader = false;
        this.EmpList = data;
        this.tasktotalTime = this.EmpList[0].totalTime;
        this.nodata = this.EmpList.length === 0; // Update nodata based on EmpList length
      },
      (error) => {
        this.nodata = true;
        this.loader = false;
        console.log('error', error);
        if (error.status == 404) {
          this.nodata = true;
          console.log('404');
        }
      }
    );

    // window.location.reload();

    // Manually trigger change detection if needed
    this.cdr.detectChanges();
  }
  showUpdateSuccessMessage(message: string) {
    this.successUpdate = true;
    this.successUpdateMessage = message;

    setTimeout(() => {
      this.closeUpdateSuccessMessage();
    }, 3000);
  }

  closeUpdateSuccessMessage() {
    this.successUpdate = false;
    this.successUpdateMessage = '';
  }

  closeWarningMessage() {
    this.warning = false;
    this.warningMessage = '';
  }

  taskPending() {
    this.taskId = undefined;
  }
  getTask() {
    this.taskList = [];
    this.project_required = false;
    this.task_disable = false;
    console.log('project id ' + this.projectId);
    if (this.projectId == 0) {
      this.taskList.length = 0;
    } else {
      this.gs.getTaskById(this.projectId).subscribe((pa: any) => {
        if (pa.errorCode != 204) {
          this.taskList = Object.keys(pa).map((key) => pa[key]);
          // this.taskList = pa;
          console.log(this.taskList);
        }
      });
    }
  }

  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if the clicked element is not the search input
  //   if (!(event.target as HTMLElement).closest('.form-control')) {
  //     this.search = ''; // Clear the search term
  //   }
  // }

  // formatDate(dateString: string) {
  //   // Convert dateString to a JavaScript Date object
  //   const date = new Date(dateString);

  //   // Format the date to MMM YYYY format
  //   const month = date.toLocaleString('default', { month: 'short' });
  //   const year = date.getFullYear();

  //   // Assign formatted date to the variable to display
  //   this.formatteddate = `${month} ${year}`;
  // }

  closeSuccessMessage() {
    this.success = false;
    this.successMessage = '';
  }
  selectProject() {
    this.projectId = undefined;
    this.taskId = undefined;
  }

  searchTimesheet() {
    this.search = '';

    this.loader = false;
    // this.nodata = false;
    this.nodataDates = false;
    this.changefirstpage();

    console.log('projectid', this.projectId);

    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');

    if (this.startDate == undefined && this.endDate != undefined) {
      this.startdate_required = true;
      this.enddate_required = false;
      return;
    }
    console.log(
      'check all fields',
      this.startDate,
      this.endDate,
      this.projectId,
      this.taskId
    );

    // this.checkStartDate();

    setTimeout(() => {
      if (
        (this.projectId == 0 || this.projectId == undefined) &&
        this.startDate == undefined &&
        this.endDate == undefined
      ) {
        alert('Please Choose any of the Element to Search');
        return;
      }
      return;
    }, 0);

    if (this.startDate != undefined && this.endDate == undefined) {
      this.enddate_required = true;
      return;
    }
    if (this.endDate != undefined && this.startDate == undefined) {
      this.startdate_required = true;
      return;
    }

    if (this.endDate != undefined) {
      this.enddate_required = false;
    }

    this.EmpList.length = 0;

    this.project_required = false;

    if (
      this.projectId != undefined &&
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.taskId == undefined
    ) {
      this.loader = true;
      console.log('projectId only with date');
      this.searchButton = false;
      if (
        this.projectId != undefined &&
        this.startDate != null &&
        this.endDate != null
      ) {
        console.log('projectId only with date');
        this.tableShow = false;
        this.gs
          .getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
            this.employeeId,
            this.projectId,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data) => {
              console.log(data);

              this.EmpList = [];
              this.EmpList = data;
              this.tableShow = true;
              if (this.EmpList.length === 0) {
                this.tableShow = true;
              }
              if (this.invalidData == 204) {
                console.log('this.invalidData');
                this.EmpList.length = 0;
                this.nodata = true;
              } else {
                this.nodata = false;
              }

              // this.searchButton = false;
              this.searchButton = true;
              this.loader = false;
              if ((data as any).errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              this.tasktotalTime = this.EmpList[0].totalTime;
              this.nodata = false;
            },
            (error) => {
              this.nodata = true;
              this.loader = false;
            }
          );
      }
    }
    if (
      ((this.projectId != 0 || this.projectId != undefined) &&
        this.taskId == 0) ||
      (this.taskId == undefined &&
        this.startDate == undefined &&
        this.endDate == undefined)
    ) {
    
      console.log('projectId only');
      if (
        this.projectId != undefined &&
        this.startDate == undefined &&
        this.endDate == undefined &&
        this.taskId == undefined
      ) {
        this.loader = true;
        this.tableShow = false;
        // this.loader = true;
        this.searchButton = false;
        console.log('projectId only without date');
        this.gs.getEmpByProject(this.projectId, this.employeeId).subscribe(
          (data) => {
            console.log('(data as any).errorCode', data);
            this.EmpList = [];
            if (Array.isArray(data)) {
              this.EmpList = data;
            }

            this.tableShow = true;
            this.loader = false;
            this.searchButton = true;

            if (this.EmpList.length === 0) {
              this.tableShow = true;
            }
            if (this.invalidData == 204) {
              console.log('this.invalidData');
              this.EmpList.length = 0;
              this.nodata = true;
            } else {
              this.nodata = false;
            }

            console.log('EmpList:', this.EmpList.errorCode);
            console.log('EmpList Length:', this.EmpList.length);
            console.log('EmpList[0]:', this.EmpList[0]);

            // this.tasktotalTime = this.EmpList[0].totalTime;
            this.nodata = false;
            if ((data as any).errorCode !== 204) {
              this.tasktotalTime = this.EmpList[0]?.totalTime;
            }
            if ((data as any).errorCode == 204) {
              this.nodata = true;
              this.loader = false;
            }
            console.log('data checking', data);
          },
          (error) => {
            console.log('nodata checking', error);

            this.nodata = true;
            this.loader = false;
            if ((error as any).errorCode === 204) {
              this.nodata = true;
              this.loader = false;
            }
          }
        );
      }
    }

    // this.startDate != undefined ||
    // (this.startDate != null && this.endDate != undefined) ||
    // (this.endDate != null &&
    //   this.employeeId != undefined &&
    //   this.projectId == undefined) ||
    // this.projectId == 0

    if (
      (this.projectId == undefined || this.projectId == 0) &&
      this.employeeId != undefined &&
      (this.startDate != undefined || this.startDate != null) &&
      (this.endDate != undefined || this.endDate != null)
    ) {
      this.loader = true;
      this.searchButton = false;
      this.tableShow = false;
      this.gs
        .getTimesheetReportByEmployeeIdAndStartDateAndEndDate(
          this.employeeId,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data: any) => {
            console.log('Received data:', data);
            this.invalidData = data.errorCode;
            data['weeklySubmit'] = true;

            // Ensure EmpList is an array
            this.EmpList = Array.isArray(data) ? data : [];

            this.tableShow = true;
            this.loader = false;
            this.searchButton = true;

            if (this.invalidData == 204) {
              console.log('Invalid data received');
              this.EmpList.length = 0;
              this.nodata = true;
            } else {
              this.nodata = false;
            }

            if (this.EmpList?.errorCode == 204) {
              this.EmpList.length = 0;
              this.nodata = true;
              this.loader = false;
            }

            if (data.errorCode == 204 || data.message === 'No Data') {
              this.nodata = true;
              this.loader = false;
            }
            console.log(this.EmpList);

            if (this.EmpList.length > 0) {
              this.tasktotalTime = this.EmpList[0]?.totalTime;
              this.filteredEmpList = this.EmpList.filter(
                (res) => res.update === true
              );
              console.log('Filtered EmpList', this.filteredEmpList.length);
            }
          },
          (err) => {
            console.log('errorCode', err);
            if (err.errorCode == 204) {
              this.nodata = true;
              this.loader = false;
            }
            console.log('Error on dates:', err);
          }
        );
    }

    if (
      (this.taskId != undefined || this.taskId != 0) &&
      this.employeeId != undefined &&
      (this.startDate == undefined || this.startDate == null) &&
      (this.endDate == undefined || this.endDate == null)
    ) {
    
      // console.log('taskId and projectId');

      // // if (this.startDate == null &&  this.endDate ==  null) {
      // console.log('taskId and projectId without date');
   
      if (this.taskId != undefined) {
        this.loader = true;
        this.tableShow = false;
        this.searchButton = false;
        this.gs
          .getTaskDetailByTaskIdAndEmployee(this.taskId, this.employeeId)
          .subscribe(
            (data) => {
              console.log(data);
              this.EmpList = [];
              this.EmpList = data;
              this.tableShow = true;
              this.loader = false;
              this.searchButton = true;

              if (this.invalidData == 204) {
                console.log('this.invalidData');
                this.EmpList.length = 0;
                this.nodata = true;
              } else {
                this.nodata = false;
              }

              if ((data as any).errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              this.tasktotalTime = this.EmpList[0].totalTime;

              this.nodata = false;
            },
            (error) => {
              this.nodata = true;
              this.loader = false;
            }
          );
      }
    }

    if (
      (this.taskId != undefined || this.taskId != 0) &&
      this.startDate != undefined &&
      this.endDate != undefined
    ) {
      console.log('taskId and projectId with date');
      this.loader = true;
      this.searchButton = false;
      this.tableShow = false;
      if (this.taskId != undefined) {
        this.gs
          .getEmpTimeLogbyEmployeeIdandStartDateAndEndDate(
            this.taskId,
            this.employeeId,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data) => {
              console.log(data);
              this.EmpList = [];
              this.EmpList = data;
              this.tableShow = true;
              this.loader = false;
              this.searchButton = true;
              if (this.invalidData == 204) {
                console.log('this.invalidData');
                this.EmpList.length = 0;
                this.nodata = true;
              } else {
                // this.nodata = false;
              }

              if ((data as any).errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              this.tasktotalTime = this.EmpList[0].totalTime;
              this.nodata = false;
            },
            (error) => {
              this.nodata = true;
              this.loader = false;
            }
          );
      }
    }
  }

  submitToApproval() {
    // console.log("submitToApproval");
    //console.log(this.EmpList);
    // this.EmpList =  [];

    this.loader = true;

    this.submitToApprovalData = [];

    this.EmpList.forEach((element) => {
      console.log('element', element);

      (this.timesheetData =
        // taskDetailId: element.taskDetailId,
        // projectId:element.project.projectId,
        // approvals:{
        // projectId:element.project.projectId
        // },
        // employeeId:element.employee.employeeId

        {
          taskDetailId: element.taskDetailId,
          projectId: element.project.projectId,
          task: {
            taskId: element.task.taskId,
          },
          approvals: {
            projectId: element.project.projectId,
            employeeId: element.employee.employeeId,
          },
          employeeId: element.employee.employeeId,
        }),
        console.log('jsoncheck', this.timesheetData);

      this.submitToApprovalData.push(this.timesheetData);
      console.log(this.submitToApprovalData);
    });
    console.log(this.submitToApprovalData);
    this.loader = true;
    this.tableShow = false;
    this.gs
      .ApprovalSubmit(this.startDate, this.endDate, this.submitToApprovalData)
      .subscribe(
        (data) => {
          console.log('data', data);
          // Swal.fire({
          //   icon: 'success',
          //   text: 'TimeSheet Submitted for Approval',
          // });
          this.tableShow = true;

          if ((data as any).statusCode === 200) {
            this.success = true;
            this.successMessage = 'TimeSheet Submitted for Approval';
            this.loader = false;
            // this.tableShow=true;
            // Set timeout for hiding success message after 3 seconds (for example)
            setTimeout(() => {
              this.success = false;
              this.successMessage = ''; // Clear the success message after 3 seconds
            }, 3000); // 3000 milliseconds = 3 seconds
            this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
              (data: any) => {
                this.loader = false;
                console.log('timesheettable', data);

                this.EmpList = data;
                this.startDate = undefined;
                this.endDate = undefined;
                this.tableShow = true;
                this.cdr.detectChanges();
                this.ngZone.run(() => {
                  this.cdr.detectChanges();
                });
                this.cdr.detectChanges();

                this.tasktotalTime = this.EmpList[0].totalTime;

                console.log('totaltime', this.tasktotalTime);

                if (this.EmpList.length == 0) {
                  this.nodata = true;
                  console.log('nodata');
                }
              },
              (error) => {
                this.nodata = true;
                this.loader = false;
                console.log('error');
                if (error.status == 404) {
                  this.nodata = true;
                  console.log('404');
                }
              }
            );
          }
          this.submitToApprovalData.length = 0;
        },
        (error) => {
          // Swal.fire({
          //   icon: 'error',
          //   text: 'Unable to Submit TimeSheet for Approval',
          // });

          this.error = true;
          this.error_msg = 'Unable to Submit TimeSheet for Approval';
          this.loader = false;
          setTimeout(() => {
            this.error = false;
            this.error_msg = ''; // Clear the success message after 3 seconds
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // const currentUrl = this.router.url;
            // this.router
            //   .navigateByUrl('/', { skipLocationChange: true })
            //   .then(() => {
            //     this.router.navigateByUrl(currentUrl);
            //   });
          }, 3000);
          this.submitToApprovalData.length = 0;
        }
      );
  }

  debouncedChangePage = debounce(this.changepage, 300);

  // Modify the original changepage function to use the debounced version
  changepage() {
    if (this.search.length !== 0) {
      this.p = 1;
      this.paginator = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginator = true;
    }
  }

  changefirstpage() {
    this.p = 1;
  }

  x: boolean;
  sort() {
    this.x = !this.x;
    console.log(this.x);
    if (this.x == true) {
      this.EmpList.sort(function (a: any, b: any) {
        return b.empNo - a.empNo;
      });
    } else {
      this.EmpList.sort(function (a: any, b: any) {
        return a.empNo - b.empNo;
      });
    }
  }

  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return;
    }
    if (i.task.taskStatusName == 'To Do') {
      return '#9400D3';
    }
    if (i.task.taskStatusName == 'Completed') {
      return '#28A745';
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#DC3545';
    }

    if (i.task.taskStatusName == 'Pending') {
      return '#FFC107';
    }
  }

  background(i) {
    if (i?.task?.taskStatusName == null) {
      return;
    }
    if (i.task.taskStatusName == 'To Do') {
      return '#9400D3';
    }
    if (i.task.taskStatusName == 'Completed') {
      return '#28A745';
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#DC3545';
    }

    if (i.task.taskStatusName == 'Pending') {
      return '#FFC107';
    }
  }

  approvalStatus(i) {
    if (i?.approvalStatusName == null) {
      return;
    }
    if (i?.approvalStatusName == 'Pending') {
      return '#FFC107';
    }
    if (i?.approvalStatusName == 'Approved') {
      return '#28a745';
    }
    if (i?.approvalStatusName == 'Denied') {
      return '#dc3545';
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#DC3545';
    }
  }

  approvalStatusTask(i) {
    if (i?.taskDetailStatus.listTypeValueName == null) {
      return;
    }
    if (i?.taskDetailStatus.listTypeValueName == 'Pending') {
      return '#FFC107';
    }
    if (i?.taskDetailStatus.listTypeValueName == 'Approved') {
      return '#28a745';
    }
    if (i?.taskDetailStatus.listTypeValueName == 'Denied') {
      return '#dc3545';
    }
    if (i.taskDetailStatus.listTypeValueName == 'In Progress') {
      return '#DC3545';
    }
    if (i.taskDetailStatus.listTypeValueName == 'Completed') {
      return '#28A745';
    }
    if (i.taskDetailStatus.listTypeValueName == 'To Do') {
      return '#9400D3';
    }
  }

  approvalBackground(i) {
    if (i.approval.approvalStatusName == null) {
      return;
    }
    if (i.approval.approvalStatusName == 'Pending') {
      return '#FFC107';
    }
    if (i.approval.approvalStatusName == 'Approved') {
      return '#c8e6c9';
    }
    if (i.approval.approvalStatusName == 'Denied') {
      return '#ffcdd2';
    }
  }

  viewapprover(taskdetail: any) {
    console.log(taskdetail);
    const dialogRef = this.dialog.open(ViewApproversComponent, {
      width: '500px',
      height: '500px',
      data: taskdetail,
    });
  }

  edittimesheet(taskdetail: any) {
    console.log('taskdetail:::', taskdetail);
    const dialogRef = this.dialog.open(AddTimelogsComponent, {
      width: '750px',
      data: taskdetail,
    });
  }

  searchTimesheetdesign(event: Event) {
    event.preventDefault();
    // Your search functionality here
  }

  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }

  exportexcel() {
    this.exportdatalist = [];

    this.EmpList.forEach((element) => {
      var timesheetData = {
        Project: element.project.projectName,
        Task: element.task.taskName,
        Date: element.date,
        Description: element.description,
        WorkedHours: element.workedHours,
        TimeSheetStatus: element.taskDetailStatusName,
      };
      this.exportdatalist.push(timesheetData);
    });

    this.excelservice.exportAsExcelFile(this.exportdatalist, 'TimeSheet');
  }

  Approve(i) {
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let emp = x.employeeId;
    Swal.fire({
      title: 'Are you sure? Do you want to approve',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve!',
    }).then((result) => {
      if (result.value) {
        i['approvalStatus'] = 5;
        i['employee'] = {
          employeeId: this.Id,
        };
        console.log(JSON.stringify(i));
        this.gs.Time_Entry_Approval(i, this.Id).subscribe((a) => {
          Swal.fire({
            icon: 'success',
            text: 'Approved',
          });
          // window.location.reload();
        });
      }
      // this.skillsArray.removeAt(id);
    });
    console.log(i);
  }
  Deny(i) {
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let emp = x.employeeId;
    Swal.fire({
      title: 'Are you sure? Do you want to deny',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Deny!',
    }).then((result) => {
      if (result.value) {
        i['approvalStatus'] = 6;
        i['employee'] = {
          employeeId: this.Id,
        };
        this.gs.Time_Entry_Approval(i, this.Id).subscribe((a) => {
          Swal.fire({
            icon: 'success',
            text: 'Denied',
          });
          // window.location.reload();
        });
      }
      // this.skillsArray.removeAt(id);
    });
  }
  // updateTime() {
  //   // let positionConfig = { top: '5%', left: '35%', right: '0'};
  //   // if (window.innerWidth = 320) {
  //   //   positionConfig = { top: '5%', left: '7%', right: '0' };
  //   // }

  //   const dialogRef = this.dialog.open(AddTimelogsComponent, {
  //     width: '750px',
  //     position:{ top: '5%', left: '35%', right: '0'}
  //   });
  // }

  updateTime() {
    let positionConfig = { top: '5%', left: '35%', right: '0' };

    // Adjust position for mobile view (screen width <= 768px)
    if ((window.innerWidth = 320)) {
      positionConfig = { top: '5%', left: '70%', right: '0' };
    }

    if ((window.innerWidth = 1440)) {
      positionConfig = { top: '5%', left: '35%', right: '0' };
    }

    const dialogRef = this.dialog.open(AddTimelogsComponent, {
      height: 'auto',
      width: '700px',
    });

    // this.startDate = '';
    // this.endDate = '';
    // this.EmpList = [];
    // this.loader = true;
    // this.tableShow = false;
    // this.nodata = false;

    console.log(
      'checkAllFields',
      this.employeeId,
      this.projectId,
      this.startDate,
      this.endDate
    );

    if (
      this.employeeId != undefined ||
      this.projectId != undefined ||
      this.startDate ||
      this.endDate
    ) {
      this.startDate = '';
      this.endDate = '';
      // this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
      //   (data: any) => {
      //     this.loader = false;
      //     console.log('timesheettable', data);
      //     this.EmpList = [];
      //     this.EmpList = data;
      //     if ((data as any).errorCode === 204) {
      //       this.nodata = true;
      //       this.loader = false;
      //       this.tableShow = true;
      //     }
      //     this.tableShow = true;
      //     this.cdr.detectChanges();
      //     this.ngZone.run(() => {
      //       this.cdr.detectChanges();
      //     });
      //     this.cdr.detectChanges();
      //     console.log('tableData', data);

      //     this.tasktotalTime = this.EmpList[0].totalTime;
      //     if (this.EmpList.length == 0) {
      //       this.nodata = true;
      //       console.log('nodata');
      //     }
      //   },
      //   (error) => {
      //     this.nodata = true;
      //     this.loader = false;
      //     console.log('error');
      //     if (error.status == 404) {
      //       this.nodata = true;
      //       console.log('404');
      //     }
      //   }
      // );
    }
  }

  resetForms() {
    this.searchButton = true;
    this.nodataDates = false;
    this.taskList = [];
    // this.EmpList =  [];
    console.log(
      'checking All fields',
      this.projectId,
      this.taskId,
      this.startDate,
      this.endDate
    );
    if (
      this.projectId !== 0 ||
      this.projectId !== undefined ||
      this.startDate !== undefined ||
      this.startDate !== null ||
      this.endDate !== undefined ||
      this.endDate !== undefined ||
      this.taskId !== 0 ||
      this.taskId !== undefined
    ) {
      console.log('first condition');

      // Your code here

      if (
        this.projectId != 0 ||
        this.startDate != undefined ||
        this.endDate != undefined
      ) {
        console.log('second condition');

        if (
          this.projectId != undefined ||
          this.startDate != undefined ||
          this.endDate != undefined
        ) {
          console.log('third condition');

          console.log('Form is valid');
          this.tableShow = false;
          this.projectId = undefined;
          this.startDate = undefined;
          this.endDate = undefined;
          this.taskId = undefined;
          this.timesheetreport.length = 0;
          this.startdate_required = false;
          this.enddate_required = false;
          this.project_required = false;

          this.EmpList = [];
          this.nodata = false;
          this.loader = true;
          this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
            (data: any) => {
              this.loader = false;
              console.log('timesheettable', data);

              this.EmpList = data;

              this.tableShow = true;
              this.cdr.detectChanges();
              this.ngZone.run(() => {
                this.cdr.detectChanges();
              });
              this.cdr.detectChanges();
              console.log('tableData', data);

              this.tasktotalTime = this.EmpList[0].totalTime;
              if (this.EmpList.length == 0) {
                this.nodata = true;
                console.log('nodata');
              }
            },
            (error) => {
              this.nodata = true;
              this.loader = false;
              console.log('error');
              if (error.status == 404) {
                this.nodata = true;
                console.log('404');
              }
            }
          );
        }
      }
    } else {
      console.log('Form is invalid');
    }
  }

  changePlaceholderColor(event): void {
    // if (event.target.value) {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.remove('search-menu');
    // } else {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }
  }
  sortData(sort: Sort) {
    const data = this.EmpList.slice();
    if (!sort.active || sort.direction === '') {
      this.EmpList = data;
      return;
    }

    this.EmpList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'projectName':
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );
        case 'taskName':
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'workedHours':
          return this.compare(a.workedHours, b.workedHours, isAsc);
        case 'taskStatusName':
          return this.compare(
            a.task.taskStatusName,
            b.task.taskStatusName,
            isAsc
          );
        case 'approvalStatusName':
          return this.compare(
            a.approvalStatusName,
            b.approvalStatusName,
            isAsc
          );
        case 'taskDetailStatusName':
          return this.compare(
            a.taskDetailStatus.listTypeValueName,
            b.taskDetailStatus.listTypeValueName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
