
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Run Employee PayRoll</span>
                </div> 
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/employee-payroll-list" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                        <form [formGroup]="payrollForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="form-control-label">Financial Year<span class="star"> *</span></label>
                                    <mat-select placeholder="-- Select Financialyear ---" formControlName="financialyear" class="form control
                                    select-placeholder
                                    email-input" style="outline: none;
    border-radius: 5px;font-family:Mulish" (selectionChange)="selectFinancialyear()" >
                                        <mat-option value="0"> -- Select Financialyear --- </mat-option>
                                        <mat-option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</mat-option> 
                                    </mat-select>
                                    <div class="valid-msg" *ngIf="(validate.financialyear.invalid && validate.financialyear.touched) || validate.financialyear.dirty || submitted">
                                        <span *ngIf="validate.financialyear.errors?.required" class="text-danger">Please select financialyear</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-control-label">Period<span class="star"> *</span></label>
                                    <mat-select style="outline: none;
    border-radius: 5px;font-family:Mulish" placeholder=" -- Select Period --- " formControlName="period" class="form control
                                    select-placeholder
                                    email-input" >
                                        <mat-option value="0"> -- Select Period --- </mat-option>
                                        <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option>
                                    </mat-select>
                                    <div class="valid-msg" *ngIf="(validate.period.invalid && validate.period.touched) || validate.period.dirty || submitted">
                                        <span *ngIf="validate.period.errors?.required" class="text-danger">Please select period</span>
                                    </div>
                                </div>
                            <!-- <div class="form-group col-md-3">
                             
                                <label class="form-control-label">Employee<span class="star">*</span></label>
                                <input style="outline: none;
                                  border-radius: 5px; font-family:Mulish" type="text"matInput  placeholder="Search Employee"   
                                           formControlName="employeecode"
                                           [matAutocomplete]="auto"
                                            class="form control
                                            select-placeholder
                                            email-input" (keyup)="empSearch($event)"
                                            >
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <mat-option  value="All" (click)="empAllSelect()">All
                                          </mat-option>
                                      <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employeeNumber}}- {{emp.firstName}}  
                                      </mat-option>
                                      <mat-option [value]="null" *ngIf="empCode" (click)="noEmployeeFound()">No
                                        Employee Found</mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:36px;right: 19px;">
                                        search
                                    </mat-icon>
                               
                            </div> -->

                            <div class="form-group col-md-3">
                                <label class="form-control-label">Employee Code</label>
                                <input
                                  type="text"
                                  placeholder="Search Employee Code"
                                  formControlName="employeecode"
                                  [matAutocomplete]="auto"
                                  
                                  class="form control
                                  select-placeholder
                                  email-input"style="outline: none;
                                  border-radius: 5px; font-family:Mulish"
                                  (keyup)="empSearch($event)"
                                  (input)="validateInput($event)"
                                  (keydown)="validateInputs($event)" (keyup)="validateInputs($event)"


                                               

                                  
                                />
                                <mat-autocomplete #auto="matAutocomplete">
                                  <ng-container *ngIf="empList.length > 0">
                                    <mat-option value="All" (click)="empAllSelect()">All</mat-option>
                                    <mat-option
                                      *ngFor="let emp of empList"
                                      [value]="emp.employeeNumber"
                                      (click)="empSelect(emp)"
                                    >
                                      {{ emp.employeeNumber }} - {{ emp.firstName }}
                                    </mat-option>
                                  </ng-container>
                                  <mat-option [value]="null" *ngIf="empList.length === 0 && empCode" (click)="noEmployeeFound()">
                                    No Employee Found
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-icon
                                  class="material-symbols-outlined"
                                  style="position: absolute; top: 37px; right: 20px"
                                >
                                  search
                                </mat-icon>
                                <div
                                  class="valid-msg"
                                  *ngIf="
                                    (validate.employeecode.invalid && validate.employeecode.touched) ||
                                    validate.employeecode.dirty ||
                                    submitted
                                  "
                                >
                                  <!-- <span
                                    *ngIf="validate.employeecode.errors?.required"
                                    class="text-danger"
                                    >Please Select Employee Code</span
                                  > -->
                                </div>
                              </div>
                              
                               
                            <div class="form-group col-md-3">
                                <label class="form-control-label"
                                  >Employment Type </label
                                >
                             
                                <mat-select
                                  formControlName="employmentType"
                                  id="employmentType"
                                  placeholder="Select Employment Type"
                                  
                                  class="form control
                                  select-placeholder
                                  email-input"
                                  (selectionChange)="onEmploymentTypeChange($event)"
                                  style="outline: none;
    border-radius: 5px; font-family:Mulish"

                                >
                                  <mat-option [value]="null">-Select Employment Type-</mat-option>
                                  <mat-option
                                    *ngFor="let row of employementTypeLov; let i = index"
                                    [value]="row.listTypeValueId"
                                  >
                                    {{ row.listTypeValueName }}
                                  </mat-option>
                                </mat-select>
                                <!-- <div
                                  class="valid-msg"
                                  *ngIf="
                                    employment_step &&
                                    employmentDetails.controls.employmentType.errors
                                  "
                                >
                                  <span
                                    class="text-danger"
                                    *ngIf="employment.employmentType.errors?.required"
                                    >Please employment type</span
                                  >
                                </div> -->
                              </div>



                            <div class="col-md-3 ">
                                <!-- <button  class="submit action-button btn btn-primary btn-sm" [routerLink]="['home/employee-rating-list']"
                                    style="margin-right: 20px;" (click)="close()">Cancel</button> -->
                                <!-- <button  style="margin-top: 42px;"   type="submit" class="submit action-button btn btn-primary btn-sm">Calucate Payroll</button> -->
                                <button  mat-raised-button type="submit" style="    "
                                class="searchBtn "
                                [disabled]="isprocessing1 ||payrollForm.invalid"  >
                                Calucate Payroll
                            </button>
                            
                            </div>
                            </div> 

                            <div *ngIf="!loader">
                                <div class="row pb-3 mt-3" *ngIf="selectedEmployeeData.length > 5 && !loader">
                                    <div class="col-md-12 text-right">
                                        <span class="show-count">Show : </span>
                                    <!-- </div> -->
                                    <!-- <div class="col-md-1 text-left pl-0"> -->
                                        <select [(ngModel)]="pagePerItem"  [ngModelOptions]="{standalone: true}" (click)="pageChages()" class="select_list new-select_list listDesign">
                                            <option value="5" selected>5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                        </select>
                                    </div>
                               </div>
                            </div>

                            <div *ngIf="!loader">
                                <table class="table  --table-bordered table-border-new mt-3"   *ngIf="selectedEmployeeData.length != 0 || empratingId != null" style="width: 100%; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                    <thead class="t-head">
                                        <tr>
                                            <!-- <th class="text-center">S.No</th> -->
                                            <!-- <th class="text-center">Action</th> -->
                                           
                                            <th>Emp Code</th>
                                            <th>Emp Name</th>
                                            <th>Mobile Number</th>
                                            <th>Email</th>
                                            <th>Desgination</th>
                                            <th>DOJ</th>
                                            <th>Gender</th>
                                          
                                            <!-- <th class="text-center">Status</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of selectedEmployeeData | paginate: { itemsPerPage: pagePerItem, currentPage: p }  let i=index;">
                                            <!-- <td class="text-center">{{i+1}}</td> -->
                                            <!-- <td class="text-center">
                                                <button mat-menu-item  type="button" (click)="deleteemployee(i)"  >
                                                    <mat-icon   >delete</mat-icon>
                                                </button>
                                            </td> -->
                
                                  
                                            <td>{{row.employeeNumber}}</td>                                      
                                            <td>{{row.firstName}}  </td>
                                            <td>{{row.mobileNumber}}</td>
                                            <td>{{row.organizationEmail}}</td>
                                            <td *ngIf="row.designation !=undefined" >{{row.designation.designationName}}</td>
                                            <td *ngIf="row.designation == undefined" >--</td>
                                            <td>{{row.dateOfJoining | date : dateFormat}}</td>
                                            <td>{{row.gender.listTypeValueName}}</td>
                                                    
                                            <!-- capitalizeWordsName -->
                                            <!-- <td class="active-status text-center">
                                                <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                                    {{row.status.listTypeValueName}}
                                                </span>
                
                                                <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                                    {{row.status.listTypeValueName}}
                                                </span>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                    <!-- <tbody>
                                        <tr *ngIf="selectedEmployeeData?.length == 0">
                                            <td style="text-align: center;" colspan="12" *ngIf="nodatafound">No matching records found!</td>
                                        </tr>
                                    </tbody> -->
                                </table>
                            </div>
                           
                        
                            <div *ngIf="selectedEmployeeData?.length > 0 && !loader ">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="showing-record">
                                            {{selectedEmployeeData.length}} rows
                                        </div>
                                    </div>
                                    <div class="col-md-8 text-right" *ngIf="!loader && selectedEmployeeData?.length > 5">
                                        <pagination-controls style="float: right;" (pageChange)="onPageChange($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                              
                            </div>  

                        </form>     
            </div>
        </div>
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <!-- <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button> -->
</div>



<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>