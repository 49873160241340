import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ManageEmployeeApproverComponent } from '../manage-employee-approver/manage-employee-approver.component';
import { Sort } from '@angular/material/sort';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { redirectMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-manage-employee-approver-list',
  templateUrl: './manage-employee-approver-list.component.html',
  styleUrls: ['./manage-employee-approver-list.component.scss'],
})
export class ManageEmployeeApproverListComponent implements OnInit {
  p: number = 1;
  pagePerItem: any = 10;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  approverList: any;
  success: any = false;
  error: any = false;
  create_sucess_msg: any;
  update_error_msg: any;
  loader: any = false;
  list_loader = true;
  nodata = false;
  data: any;
  approverId: string;
  empolyeereadonly: boolean;
  employeeApproverForm: FormGroup;
  btnDisable: boolean = false;
  organzationId: any;
  employeeList: any[];
  ApproverList: any[];
  submitted1: boolean;
  empolyeeapproverexist: boolean;
  selectedEmployeeData: any[];
  empolyeeapproverobject: any = {};
  showupdate: boolean = false;
  approvertype: any = [];
  success_msg: any;
  error_msg: any;
  tableShow: boolean = false;
  validBool: any;
  employeeDisabled: boolean = false;
search: any;
nodatafound: any;
clearapprover:any;


  constructor(
    private gs: GlobalserviceService,
    private router: Router,
    public dialog: MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const Data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId = Data.organization.organizationId;
    console.log('organziationid ' + this.organzationId);
    console.log(this.approverId);
    if (this.approverId) {
      this.employeeApproverForm = this.fb.group({
        employee: ['', Validators.required],
        approver: ['', Validators.required],
        approverType: ['', Validators.required],
      });
    }

    // this.gs.getAllEmployeeByOrganization(this.organzationId).subscribe(
    //   (d: any[]) => {
    //     console.log(d);
    //     this.employeeList = d;
    //     console.log(this.employeeList);
    //     console.log("test");

    // this.gs.getApprovers(this.organzationId).subscribe(
    //   (d: any[]) => {
    //     console.log(d);
    //     this.ApproverList= d;

    //     this.gs.getApproverType().subscribe(
    //       (approvertype:any)=>{
    //         this.approvertype=approvertype;
    //          console.log("this.approvertype",this.approvertype);

    //       }
    //     )

    //   }
    // )
    // }
    // )
    this.getEmployeeApproversList();

    this.gs.getApproverType().subscribe((approvertype: any) => {
      this.approvertype = approvertype;
      // this.list_loader = false;

      console.log('this.approvertype', this.approvertype);
    });
    this.gs.getApprovers(this.organzationId).subscribe((d: any[]) => {
      console.log(d);
      this.ApproverList = d;
      // this.list_loader = false;
      this.gs
        .getAllEmployeeByOrganization(this.organzationId)
        .subscribe((d: any[]) => {
          console.log(d);
          this.employeeList = d;
          // this.list_loader = false;

          console.log(this.employeeList);
          console.log('test');
        });
    });
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }

  getEmployeeApproversList() {
    this.tableShow = false;
    this.list_loader = true;

    this.gs.getEmployeeApprovers().subscribe(
      (data: any) => {
        this.approverList = data;
        this.tableShow = true;
        this.showupdate = false;

        // this.employeeApproverForm?.reset

        console.log(this.approverList);
        this.list_loader = false;
        this.nodata = false;
        console.log(this.list_loader);
        console.log(this.approverList);
        if (this.approverList.length == 0) {
          this.nodata = true;
          console.log('nodata');
        }
        if (data.statusCode) {
          this.nodata = true;
          console.log('nodata');
        }
      },
      (error) => {
        this.list_loader = false;
        this.nodata = true;
        console.log('error1');
        if (error.status == 404) {
          this.nodata = true;
          console.log('404');
        }
        if (error.errorCode == 204) {
          this.nodata = true;
          console.log('204');
        }
      }
    );
  }
  changefirstpage() {
    this.p = 1;
  }
  Add_empapprover() {
    // const dialogref = this.dialog.open(ManageEmployeeApproverComponent, {
    //   width: '450px',
    //   height:'fit-content',
    // })

    // dialogref.afterClosed().subscribe(
    //   (res)=>{
    //     console.log(res);
    //      if(res)
    //      {
    //       console.log(res);
    //        if(res.statusCode == 200)
    //        {
    //           this.success = true;
    //          this.create_sucess_msg = res.message;
    //          console.log(res.statusCode);

    //             setTimeout(() => {
    //               this.success  = false;

    //               this.gs.getEmployeeApprovers().subscribe((data:any)=>{
    //                 this.list_loader = false;
    //                 this.approverList = data;
    //                 console.log(this.approverList);
    //                 if(this.approverList.statusCode)
    //                 {
    //                   this.nodata = true;
    //                   console.log("nodata");
    //                 }
    //               },
    //               (error) => {
    //                 this.list_loader = false;
    //                 this.nodata = true;
    //                 console.log("error")
    //                 if(error.status == 404){
    //                   this.nodata = true;
    //                   console.log("404")
    //                 }
    //               }
    //               )
    //           }, 2000)
    //        }else{
    //         console.log("else");
    //           this.error = true;
    //            this.update_error_msg = res.message;
    //            setTimeout(() => {this.error = false}, 2000)
    //        }

    //      }
    //   }
    // );
    this.router.navigate(['home/manage-empl-approver']);
  }
  editprojectapprover(approver) {
    this.btnDisable = false;
    console.log(approver);
    this.approverId = approver.empolyeeapproverId;
    console.log(this.approverId);
    // const dialogref = this.dialog.open(ManageEmployeeApproverComponent, {
    //   width: '450px',
    //   height:'fit-content',
    //   data : approver
    // });
    // dialogref.afterClosed().subscribe(
    //   (res)=>{
    //     console.log(res);
    //      if(res)
    //      {
    //        if(res.statusCode == 200)
    //        {
    //           this.success = true;
    //          this.create_sucess_msg = res.description;

    //             setTimeout(() => {
    //               this.success  = false;
    //               this.gs.getEmployeeApprovers().subscribe((data:any)=>{
    //                 this.list_loader = false;
    //                 this.approverList = data;
    //                 console.log(this.approverList);
    //                 if(data.length == 0)
    //                 {
    //                   this.nodata = true;
    //                   console.log("nodata");
    //                 }
    //                 if(this.approverList.statusCode)
    //                 {
    //                   this.nodata = true;
    //                   console.log("nodata");
    //                 }
    //               },
    //               (error) => {
    //                 this.list_loader = false;
    //                 this.nodata = true;
    //                 console.log("error")
    //                 if(error.status == 404){
    //                   this.nodata = true;
    //                   console.log("404");
    //                 }
    //               })
    //           }, 2000)
    //        }else{
    //           this.error = true;
    //            this.update_error_msg = res.message;
    //            setTimeout(() => {this.error = false}, 2000)
    //        }

    //      }
    //   }
    // );
    // this.router.navigate([`home/manage-empl-approver-list/${approver.empolyeeapproverId}`]);
    this.showupdate = true;
     this.btnDisable=true;

    this.gs.getApproverType().subscribe((approvertype: any) => {
      this.approvertype = approvertype;
      this.list_loader = false;
      this.btnDisable=false;

      console.log('this.approvertype', this.approvertype);
    });
    this.gs.getApprovers(this.organzationId).subscribe((d: any[]) => {
      console.log(d);
      this.ApproverList = d;
      this.list_loader = false;
      this.gs
        .getAllEmployeeByOrganization(this.organzationId)
        .subscribe((d: any[]) => {
          console.log(d);
          this.employeeList = d;
          this.list_loader = false;

          console.log(this.employeeList);
          console.log('test');
        });
    });
    if (this.approverId) {
      console.log(this.approverId);
      this.empolyeereadonly = true;
      this.submitted1 = false;
      this.list_loader = true;

      this.gs.getEmployeeApproversById(this.approverId).subscribe((x: any) => {
        this.list_loader = false;
        console.log(x);

        // this.list_loader = true;
        this.employeeApproverForm.patchValue({
          organization: {
            organizationId: this.organzationId,
          },
          employee: x.employee.employeeId,
          approver: x.approvers.approverId,
        });
        // this.list_loader = false;

        this.employeeDisabled = true;
        if (x.approverType != undefined) {
          this.employeeApproverForm.patchValue({
            approverType: x.approverType.approverTypeId,
          });
        }
        if (this.employeeApproverForm.invalid) {
          this.submitted1 = true;
        }
      });
    }

    this.employeeApproverForm = this.fb.group({
      organization: {
        organizationId: this.organzationId,
      },
      approverId: this.approverId,
      employee: ['', Validators.required],
      approver: ['', Validators.required],
      approverType: ['', Validators.required],
    });
    this.approverId = this.ActivatedRoute.snapshot.params.id;

    this.list_loader = true;
  }
  get f() {
    return this.employeeApproverForm.controls;
  }
  get employee() {
    return this.employeeApproverForm.get('employee');
  }
  get approver() {
    return this.employeeApproverForm.get('approver');
  }
  get approverType() {
    return this.employeeApproverForm.get('approverType');
  }

  clearemployeelist() {
    this.selectedEmployeeData = [];
  }


  clearForm() {
    // this.employeeApproverForm.reset();  
  this.employeeApproverForm.controls['approver'].reset();
  console.log("approver");
  
  this.employeeApproverForm.controls['approverType'].reset();
    this.employeeDisabled = true;
    return
  }

  employeeapprovervalidate() {
    console.log('project : ' + this.employeeApproverForm.value.employee);
    if (!this.data) {
      if (this.employeeApproverForm.value.employee != 0) {
        this.gs
          .getEmployeeApproversvalidate(
            this.employeeApproverForm.value.employee
          )
          .subscribe((a) => {
            console.log(a);
            this.empolyeeapproverobject = a;
            if (this.empolyeeapproverobject.isexist) {
              console.log('employee name isexist');
              this.empolyeeapproverexist = true;
            } else {
              this.empolyeeapproverexist = false;
            }
          });
      } else {
        this.empolyeeapproverexist = false;
      }
    }
  }
  submit() {
    this.p=1;
    this.submitted1 = true;
    // this.loader=true
    console.log(this.btnDisable);
    console.log(this.employeeApproverForm.invalid);
    console.log(this.empolyeeapproverexist == true);
    // if (
    //   this.employeeApproverForm.invalid &&
    //   this.empolyeeapproverexist == true
    // ) {
    //   this.btnDisable = false;
    //   return;
    // }
    // this.btnDisable = true;
    if (
      this.employeeApproverForm.invalid
    ) {
return
    }
    let proappform = this.employeeApproverForm.value;
    console.log(proappform);
    console.log(this.approverId);
    if (proappform.approverId) {
      console.log('update form');
      console.log(proappform);
      var employeeapproverupdate = {
        empolyeeapproverId: proappform.approverId,
        // "employeeApproverStatus": this.data.employeeApproverStatus.listTypeValueId,
        employee: {
          employeeId: proappform.employee,
        },
        approvers: {
          approverId: proappform.approver,
        },
        organization: {
          organizationId: this.organzationId,
        },
        approverType: {
          approverTypeId: proappform.approverType,
        },
        // status : {
        //   listTypeValueId: this.data.status.listTypeValueId
        // }
      };
      this.btnDisable = true;
      console.log(employeeapproverupdate);
      this.gs
        .updateEmployeeApprover(employeeapproverupdate, proappform.approverId)
        .subscribe(
          (a: any) => {
            this.loader = false;
            this.btnDisable = true;

            this.success = true;
            this.success_msg = a.description;
            setTimeout(() => {
              this.success = false;
              // this.btnDisable = false;

              this.router.navigate(['/home/manage-emp-approver-list']);
              this.getEmployeeApproversList();
            }, redirectMsgTimeOut);
          },
          (err) => {
            console.log(err);
            this.btnDisable = false;
            this.loader = false;
            this.error = true;
            this.error_msg = err.error.description;
            setTimeout(() => {
              this.error = false;
            }, redirectMsgTimeOut);
          }
        );
    }
  }

  sortData(sort: Sort) {
    const data = this.approverList.slice();
    if (!sort.active || sort.direction === '') {
      this.approverList = data;
      return;
    }

    this.approverList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'rulename':
          return this.compare(
            a.approvers.rulename,
            b.approvers.rulename,
            isAsc
          );
        case 'employeeName':
          return this.compare(
            a.employee.firstName,
            b.employee.firstName,
            isAsc
          );
        case 'approvertype':
          return this.compare(
            a.employee.firstName,
            b.employee.firstName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
