<nav class="navbar  bg" style="margin-top: 67px;">
    <a class="navbar-brand navDesign" style="font-size: 22px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;">Leave Type List</a>
    <div class="d-flex">
    <input
          class="form-control me-2"
          type="text"
          placeholder="Search..."
          [(ngModel)]="searchs"
          (input)="validateInputs($event)"
        (keydown)="validateInputs($event)" (keyup)="validateInputs($event)"   (keyup)="changepage();"     
             name="search"
          aria-label="Search"
          style="
            width: 250px;
            height: 38px;
            flex-shrink: 0;
            border-radius: 5px;
            background: #fff;position: relative;right: 20px;
          "
        />
    
      </div>
   
  </nav>


<div class="common-content" style=" font-family: Mulish;">
    <div class="card-new">
        <div class="card colDesign ">
            <div class="card-body">

                <div class="row mb-2">
                    <div class="col-md-12">
                        <!-- <ng-container *ngIf="loader; else elseLoading" class="loading">
                        <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
                    </ng-container> 
                    <ng-template #elseLoading> -->
                        <div class="container-fluid" style="padding: 5px 15px 0px 15px;">
                            <!-- <div class="row mb-2">
                                <div class="col-md-10">
                                    <div class="row mb-3 pl-0">
                                        <div class="col-md-5 col-9 col-sm-4 col-xs-4"> -->
                                            <!-- <input
                                                class="--search form-control search-input form-control-lg form-control-solid"
                                                type="text" placeholder="Search.." (keyup)="applyFilter($event)"
                                                name="search" (input)="validateInput($event)"
                                                (keydown)="validateInput($event)" (keyup)="validateInput($event)"> -->
                                            <!-- <i class="fa fa-search"
                                                style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%);"></i> -->

                                        <!-- </div> -->
                                        <!-- <div class="col-xl-1 col-lg-1" style="display: contents;">
                                            <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn" style="font-family: Mulish;">Search</button>
                                        </div>                                                                                               -->
                                    <!-- </div>
                                </div>
                            </div> -->
                            <div class="--container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="title " style="font-size: large;">Manage leave type</h3>
                                    </div>
                                </div>
                                <form action="" [formGroup]="leaveTypeForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Leave Name<span class="star">
                                                            *</span></label>
                                                    <input type="text" placeholder="Enter leave Name!!"
                                                        formControlName="leaveName" (input)="validateInputs($event)"
                                                        [ngClass]="{ 'is-invalid': submitted && f.leaveName.errors }"
                                                        style="height: 38px;"
                                                        class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;">
                                                    <div
                                                        *ngIf="(f.leaveName.invalid && submitted) || f.leaveName.dirty">
                                                        <mat-error *ngIf="f.leaveName.errors?.required"> Please select
                                                            Leave Name</mat-error>
                                                    </div>
                                                    <mat-error *ngIf="(f.leaveName.errors?.pattern )">
                                                        Please enter a valid Leave Name
                                                    </mat-error> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <div class="input-width" id="loginform">
                                                    <label class="form-control-label">Select number of days<span
                                                            class="star"> *</span></label>
                                                    <input type="number" min="1" max="30"  (keypress)="noNumber($event)" (paste)="onPaste($event)"
                                                        placeholder="Enter Number Of Days" formControlName="noOfDays"
                                                        class="form control select-placeholder email-input " 
                                                        style=" background-image:none;outline:none;border-radius: 5px">
                                                        <!-- style=" background-image:none;outline:none;border-radius: 5px;height: 40px;"> -->
                                                        
                                                    <!-- <mat-select style="color: red; background-image:none" placeholder="Enter Number Of Days" formControlName="noOfDays"
                                                        class="form-select form-select-solid form-select-lg select2-new">
                                                        <mat-option value="1">1</mat-option>
                                                        <mat-option value="2">2</mat-option>
                                                        <mat-option value="3">3</mat-option>
                                                        <mat-option value="4">4</mat-option>
                                                        <mat-option value="5">5</mat-option>
                                                        <mat-option value="6">6</mat-option>
                                                        <mat-option value="7">7</mat-option>
                                                        <mat-option value="8">8</mat-option>
                                                        <mat-option value="9">9</mat-option>
                                                        <mat-option value="10">10</mat-option>
                                                        <mat-option value="11">11</mat-option>
                                                        <mat-option value="12">12</mat-option>
                                                        <mat-option value="13">13</mat-option>
                                                        <mat-option value="14">14</mat-option>
                                                        <mat-option value="15">15</mat-option>
                                                        <mat-option value="16">16</mat-option>
                                                        <mat-option value="17">17</mat-option>
                                                        <mat-option value="18">18</mat-option>
                                                        <mat-option value="19">19</mat-option>
                                                        <mat-option value="20">20</mat-option>
                                                        <mat-option value="21">21</mat-option>                                                    </mat-select> -->
                                                    <div *ngIf="(f.noOfDays.invalid && submitted) || f.noOfDays.dirty">
                                                        <mat-error *ngIf="f.noOfDays.errors?.required"> Please select
                                                            Number Of Days</mat-error>
                                                            <mat-error *ngIf="f.noOfDays.errors?.min">Number of days must be at least 1.</mat-error>
                                                            <mat-error *ngIf="f.noOfDays.errors?.max">Number of days cannot exceed 30.</mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- </div>
                                    <div class="form-group">
                                        <div class="row">-->
                                            <!-- <div class="col-md-6"></div>  -->
                                            <!-- Empty column to push buttons to the right -->
                                             
                                        <div class="col-md-2 d-flex justify-content-end"> <!-- Buttons column -->
                                            <div style="margin: 25px 1px;align-content: flex-start;">
                                                
                                                <button
                                                    type="submit"
                                                    mat-raised-button
                                                    class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                                                    [disabled]="processing  || leaveTypeForm.invalid"
                                                    >
                                                    Save
                                                    </button>
                                            </div>
                                            <div style="margin: 23px 10px;align-content: flex-start;">
                                                <button mat-stroked-button type="reset" class="apply-button"
                                                    (click)="Clear()"
                                                    style="margin: 1px 0px 0px 5px;background: white;border: 1px solid #ddd; font-family: Mulish; color: white; color: black; font-size: 16px;font-weight: 600;">Clear</button>
                                            </div>
                                          
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div  class="card resource-card" style="border: 0px solid rgba(0,0,0,.125);padding: 5px 20px;" *ngIf="list_count > 0  && (data |searchFilter :searchs).length >5">
                            <div class="row rowDesign" *ngIf="list_count > 5">
                                <div class="col-xl-12 col-lg-12" style="margin-top:10px">
                                    <div class="select-pagination">
                                        <span id="showDesign">Show : </span>
                                        <select class="select_list new-select_list" id="listDesign" [(ngModel)]="mySelect" (change)="showChanges()">
                                        <option class="form-control" value="5">5</option>
                                        <option class="form-control" value="10">10</option>
                                        <option class="form-control" value="15">15</option>
                                        <option class="form-control" value="20">20</option>
                                        <option class="form-control" value="25">25</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <!-- <ng-container>
                            <div class="container-fluid " style=" overflow: auto; ">
                                <div class="table-responsive"
                                    style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                    <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count > 0"
                                        class="table  --table-bordered table-border-new"
                                        style="border-top-left-radius: 10px; border-top-right-radius: 10px; overflow: hidden;">
                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef class="text-center">S.No</th>
                                            <td mat-cell class="text-center" *matCellDef="let element; let i = index">
                                                {{i+1}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="leaveName">
                                            <th mat-header-cell *matHeaderCellDef> Leave&nbsp;Type&nbsp;Name</th>
                                            <td mat-cell *matCellDef="let element"> {{element.leaveName | titlecase}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="noOfDays">
                                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                                No&nbsp;of&nbsp;Days</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                {{element.noOfDays}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef> Status </th>
                                            <td mat-cell *matCellDef="let element"
                                                [style.color]="statuscolor(element.status)"> {{element.status}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="star">
                                            <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                                    aria-label="Example icon-button with a menu">
                                                    <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <a mat-menu-item (click)="edit(element)">
                                                        <mat-icon class="cancel-trip-icon material-icons-outlined"
                                                            style="color:black">create</mat-icon>
                                                        <span class="cancel-trip-text" style="color:black">Edit</span>
                                                    </a>
                                                    <a mat-menu-item (click)="inactive(element)">
                                                        <mat-icon *ngIf="element.status=='Active'"
                                                            class="cancel-trip-icon material-icons-outlined"
                                                            style="color:#FF7171">block</mat-icon>
                                                        <span *ngIf="element.status=='Active'" class="cancel-trip-text"
                                                            style="color:#FF7171">In-Active</span>
                                                        <mat-icon *ngIf="element.status=='In-Active'"
                                                            class="cancel-trip-icon material-icons-outlined"
                                                            style="color:#01E325">done</mat-icon>
                                                        <span *ngIf="element.status=='In-Active'"
                                                            class="cancel-trip-text" style="color:#01E325">Active</span>
                                                    </a>
                                                </mat-menu>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                    <div class="container" *ngIf="noData">
                                        <div class="alert alert-primary" align="center"
                                            style="width: 100%; height: 50px;">
                                            No Leave Type List
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container> -->

                        <div class="container-fluid" style="overflow: auto;">
                            <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                <table class="table  --table-bordered table-border-new" matSort *ngIf="list_count > 0" style="border-top-left-radius: 10px; border-top-right-radius: 10px; overflow: auto;">
                                    <thead class="t-head" style="background-color: #f6f9fc;">
                                        <tr>
                                            <th class="text-center">S.No</th>
                                            <th class="text-center">Leave Type Name</th>
                                            <th class="text-center">No of Days</th>
                                            <th class="text-center">Status</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="t-body">
                                        <tr *ngFor="let element of data |searchFilter: searchs | paginate: { itemsPerPage: mySelect, currentPage: p }; let i = index" class="custom-row">
                                            <td class="text-center">{{ (p - 1) * mySelect + i + 1 }}</td>
                                            <td class="text-center">{{ element.leaveName | titlecase }}</td>
                                            <td class="text-center">{{ element.noOfDays }}</td>
                                            <td class="text-center"><span [style.color]="statuscolor(element.status)">{{ element.status }}</span></td>
                                            <td class="text-center">
                                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                    <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <a mat-menu-item (click)="edit(element)">
                                                        <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:black">create</mat-icon>
                                                        <span class="cancel-trip-text" style="color:black">Edit</span>
                                                    </a>
                                                    <a mat-menu-item (click)="inactive(element)">
                                                        <mat-icon *ngIf="element.status === 'Active'" class="cancel-trip-icon material-icons-outlined" style="color:#FF7171">block</mat-icon>
                                                        <span *ngIf="element.status === 'Active'" class="cancel-trip-text" style="color:#FF7171">In-Active</span>
                                                        <mat-icon *ngIf="element.status === 'In-Active'" class="cancel-trip-icon material-icons-outlined" style="color:#01E325">done</mat-icon>
                                                        <span *ngIf="element.status === 'In-Active'" class="cancel-trip-text" style="color:#01E325">Active</span>
                                                    </a>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                            style="
                                            text-align: center;
                                            font-size: larger;
                                            font-weight: bold;
                                          "
                                          colspan="9"
                                          *ngIf="noData || ((data |searchFilter :searchs).length === 0 && searchs) ||
                                                   data.length == 0">
                                   No Leave Type List                                 </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <div class="container" *ngIf="noData && !loader">
                                    <div class="alert alert-primary" align="center" style="width: 100%; height: 50px;">
                                        No Leave Type List
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div *ngIf="list_count >5 && (data |searchFilter :searchs).length >5">
                       
                            <pagination-controls class="float-right pagination " style="float: right;"
                            (pageChange)="p = $event; beforepage = $event"></pagination-controls>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loader" class="d-flex align-items-center justify-content-center" style="margin-top: 30%;">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;top: 50%; right: 50%; " role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="text-center" *ngIf="success">
        <div class="alert alert-success toast-msg" role="alert">
            <strong>Success!</strong><br>{{success_msg}}
        </div>
    </div>
    <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
        <strong>Error!</strong><br>{{error_msg}}
    </div>