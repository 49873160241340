import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/providers/admin.service';

@Component({
  selector: 'app-addsupplier',
  templateUrl: './addsupplier.component.html',
  styleUrls: ['./addsupplier.component.scss']
})
export class AddsupplierComponent implements OnInit {
  submitted:any;
  suppliergroup:FormGroup
  countrylist:any;
  countryId: any;
  statelist: any;
  stateId: any;
  disrictId: any;
  disrictList: any;
  cityId: any;
  cityList: any;
  formvalues: any;
  success: boolean;
  sucess_msg: any;
  error: boolean;
error_msg: any;
  supplierID: any;
  supplier: any=[];
  btnDisabled:boolean=false;
  loader: boolean=false;
  stroeEntityId: any;
  constructor(
    private adminService : AdminService,
    private formBuilder:FormBuilder,
    private router:Router,
    private ActivatedRoute: ActivatedRoute,



  ) { }

  ngOnInit(): void {
    const userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.stroeEntityId=userdata.entity.entityId
    console.log("this.store entityId:",this.stroeEntityId);
    

    this.suppliergroup=this.formBuilder.group({
      supplierName:['',
        [
          Validators.required,
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],],
        supplierCode:['',
          [
            Validators.required,
            Validators.pattern(
              "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
            ),
          ],],
        country:['',[Validators.required]],
        state:[''],
        district:[''],
        city:[''],
        postalCode: ['', [Validators.pattern('^[0-9.-]+$')]],
        addressLine1:['',
        [
          Validators.required,
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],],
        addressLine2:[''],
        description:['',[Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
)]]






    })
    console.log("this.activate router::",this.ActivatedRoute);
    
  
    this.supplierID=this.ActivatedRoute.snapshot.params.supplierId;

    console.log("this.supplier id::",this.supplierID);
    


    if(this.supplierID){
      this.loader=true;

      this.adminService.getSupplierEdit(this.supplierID).subscribe((response:any)=>{
        this.supplier=response;

        console.log("this.supplier",this.supplier);
        this.loader=false;


        this.suppliergroup.patchValue({
          supplierName:this.supplier.supplierName,
          supplierCode:this.supplier.supplierCode,
          country:this.supplier.country,
          state:this.supplier.state,
          district:this.supplier.district,
          city:this.supplier.city,
          postalCode:this.supplier.postalCode,
          addressLine1:this.supplier.addressLine1,
          addressLine2:this.supplier.addressLine2,
          description:this.supplier.supplierDescription,
          supplierStatus: this.supplier.supplierStatus
        })
        
      })

    }

    
  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  countrySelect(country){
    console.log(country);
    this.countryId=country.countryId;
    this.suppliergroup.patchValue({
       country:country.countryName
    });
    //  console.log("this.clientform",this.clientform);
  }
  countrySearch(event)
  {
    this.adminService.countryAutoCompleteSearch(event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.countrylist = data;
       }
    }
    )
  }
  stateSearch(event)
  {
    this.adminService.stateAutoCompleteSearch( this.countryId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.statelist = data;
       }
    }
    )
  }
  districtSelect(disrict)
  {
    this.disrictId=disrict.districtId;
    
    
    this.suppliergroup.patchValue({
      district:disrict.districtName
     });
  }
  districtSearch(event)
  {
    this.adminService.districtAutoCompleteSearch(this.stateId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.disrictList = data;
       }
    }
    )
  }
  citySearch(event)
  {
    this.adminService.cityAutoCompleteSearch(this.disrictId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.cityList = data;
       }
    }
    )
  }
  citySelect(city)
  {
    this.cityId=city.cityId;
    
    this.suppliergroup.patchValue({
        city:city.cityName
    }); 
  }
 
  clearForm(){
    this.router.navigateByUrl("/home/supplier-list");
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  stateSelect(state)
  {
    this.stateId=state.stateId;
    
    
    this.suppliergroup.patchValue({
      state:state.stateName
     });
  }
  noNumber(event: any) {
    // const inputValue = event.target.value;
  
  
    const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
  }
  get validate() { return this.suppliergroup.controls; }
  onSubmit(){
    this.submitted=true;
    if(this.suppliergroup.invalid){
      this.btnDisabled=false;

      return;
    }

    this.formvalues=this.suppliergroup.value;
    console.log("form values::",this.formvalues);

    if(this.supplierID){
      console.log("update!!");

      var postValues={
        supplierName:this.formvalues.supplierName,
        supplierCode:this.formvalues.supplierCode,
        country:this.formvalues.country,
        state:this.formvalues.state,
        district:this.formvalues.district,
        city:this.formvalues.city,
        postalCode:this.formvalues.postalCode,
        addressLine1:this.formvalues.addressLine1,
        addressLine2:this.formvalues.addressLine2,
        supplierDescription:this.formvalues.description,
        supplierStatus: this.supplier.supplierStatus,
        entity:{
          entityId:this.stroeEntityId
        }

        
      }
      console.log("put values",postValues);
      this.btnDisabled=true;

      this.adminService.updateSupplier(this.supplierID,postValues).subscribe((response:any)=>{
        this.btnDisabled=true;
        console.log(response);
        if (response.statusCode == 200) {
          this.success = true;
          this.btnDisabled=true;

          this.sucess_msg = response.description;
          setTimeout(() => {
            this.success = false;
            this.router.navigateByUrl("/home/supplier-list");
  }, 5000);
        } else {
          this.error = true;
          this.error_msg = response.description;

          setTimeout(() => {
            this.router.navigateByUrl("/home/supplier-list");
  }, 5000);
        }
        

      },
      (err:any)=>{
        console.log("err::",err);
        if(err.error.statusCode==409){
          
          this.error = true;
          this.btnDisabled=false;

          this.error_msg = err.error.description;

          setTimeout(() => {
            this.error = false;

            // this.router.navigateByUrl("/super-admin-home/entity");
          }, 5000);
        }
        
      })
      
      

    }
    else{

      this.btnDisabled=true;

    console.log("create!!");
    

    const payload={
      supplierName:this.formvalues.supplierName,
      supplierCode:this.formvalues.supplierCode,
      country:this.formvalues.country,
      state:this.formvalues.state,
      district:this.formvalues.district,
      city:this.formvalues.city,
      postalCode:this.formvalues.postalCode,
      addressLine1:this.formvalues.addressLine1,
      addressLine2:this.formvalues.addressLine2,
      supplierDescription:this.formvalues.description,
      entity:{
        entityId:this.stroeEntityId
      }

    }

    console.log("payload::",payload);

    this.adminService.addSupplier(payload).subscribe((response:any)=>{
      this.btnDisabled=true;

      console.log(response);
   
    if (response.statusCode == 200) {
      this.success = true;
      this.btnDisabled=true;

      this.sucess_msg = response.description;
      setTimeout(() => {
        this.success = false;

            this.router.navigateByUrl("/home/supplier-list");
  }, 5000);
    } else {
      this.error = true;
      this.btnDisabled=false;

      this.error_msg = response.description;

      setTimeout(() => {
                    this.router.navigateByUrl("/home/supplier-list");

      }, 5000);
    }
    

  },
  (err:any)=>{
    console.log("err::",err);
    if(err.error.statusCode==409){
      
      this.error = true;
      this.btnDisabled=false;

      this.error_msg = err.error.description;

      setTimeout(() => {
        this.error = false;

        // this.router.navigateByUrl("/super-admin-home/entity");
      }, 5000);
    }
    
  })
}
}}

