
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PayrollService } from '../providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { AppComponent } from 'src/app/app.component';
import {ExcelService } from '../services/excel.service';
import { SettingsService } from 'src/app/providers/settings.service';
import {catchError, EMPTY, Observable, Subject, switchMap, takeUntil} from 'rxjs';
import { Router } from '@angular/router';
import { responseMsgTimeOut }from '../../app/providers/properties'
import { ImportEmployeerecoveryadjustmentComponent } from '../import-employeerecoveryadjustment/import-employeerecoveryadjustment.component'
import { ShowErrorComponent } from '../project-management/project-management/show-error/show-error.component';

@Component({
  selector: 'app-recovery-adjustment-list',
  templateUrl: './recovery-adjustment-list.component.html',
  styleUrls: ['./recovery-adjustment-list.component.scss']
})
export class RecoveryAdjustmentListComponent implements OnInit {
  isProcessing = false;
  showtable:boolean=false;
  loader:any= false;

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=5;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  finEmpList: any;
  eleNameList: any;
  categoryId: number;
  categoryTypeData: any;
  categoryName: any;
  elementlist:any = [];
  clientelements: any;
  maxpfamount: any;
  max: any;
  employeePfAmount: any;
  employerPfAmount: any;
  maxesiamount: any;
  employeeEsiAmount: any;
  employerEsiAmount: any;
  maxstartdate: any;
  clientemployee: any;
  firstname: any;
  empnumber: any;
  empCode: boolean;
  employeelistsub: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  selectedEmployeeData: any = [];
  employeeAll: boolean = false;
  selectperiod:boolean=false;
  selectedemployeeId: any;
  selectedemployee: any;
  inputValue:any;
  setBool:boolean=false;
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any) { }

  ngOnInit(): void {
    this.noRecordsFound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;

    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);
   
    this.filterForm = this.fb.group({
      employeecode:[null],
      // client:[null],
      // employee:[null],
      financialyear:[null],
      period:[null],
      element:[null]
    })

    
    if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }


  if(this.clientId){
    this.settingsService.getFinicalyearActiveListClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }

    // this.payrollservice.getClientNameList(this.orgId ).subscribe((result: any) => {
    //   this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
    //   console.log(this.clientList);
    // }) 

    this.payrollservice.getCategoryList().subscribe((result: any) => {
      // this.filterForm.get('category').patchValue(result[0].listTypeValueId);
      this.categoryTypeData = result;
      this.categoryId=this.categoryTypeData[0].listTypeValueId;
      this.categoryName=this.categoryTypeData[0].listTypeValueName;
      console.log('result',this.categoryId);

      if(this.orgId){
      this.payrollservice.getElementListValues(this.categoryId, this.orgId).subscribe((d: any) => {
        console.log('element', d)
        this.eleNameList = d;
      })
    }

     if(this.clientId){
      this.payrollservice.getClientElementListValues(this.categoryId, this.clientId).subscribe((d: any) => {
        console.log('element', d)
        this.eleNameList = d;
      })
    }
    })
    // this.categoryId=43;
    // this.payrollservice.getElementListValues(this.categoryId).subscribe((d: any) => {
    //   console.log('element', d)
    //   this.eleNameList = d;
    // })

    if(this.orgId){
    this.settingsService.getElementList(this.orgId).subscribe((Data: any) => {
      console.log(Data);
      this.finEmpList = Data;
    });
  }

  if(this.clientId){
    this.settingsService.getClientElementList(this.clientId).subscribe((Data: any) => {
      console.log(Data);
      this.finEmpList = Data;
    });
  }


  }

  empAllSelect() {
      this.employeeAll = true;
    }

  addRecovery()
  {
    console.log("add recovery");
    this.router.navigateByUrl('/home/recovery-adjustment');
  }
  
  clearTable()
  {
    this.empList=[];
    this.resultData = [];
    console.log('clear')
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
    this.p =1;
    this.showtable=false;
    this.empCode=false;
    

      this.filterForm.reset();

  
  this.finPeriodList = [];

  
  this.selectperiod = false;
  }


  searchSubmit()
  {



    if((this.filterForm.value.employeecode !== undefined && this.filterForm.value.employeecode !== null && this.filterForm.value.employeecode !== '') ){
      if((this.employee != this.filterForm.value.employeecode ) || (this.employeeId == null)){
        window.alert('please select valid employee from dropdown.');
        return;
      }
    }

    console.log('element',this.filterForm.value.employeecode,this.filterForm.value.element,this.filterForm.value.financialyear,this.filterForm.value.period);
    
    this.showtable=true;
    this.loader=true;

    this.isProcessing=true;
    console.log("Filter",this.filterForm.value);
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   return;
    // }
    this.p =1;
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode ===null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null')&&(this.filterForm.value.element===null|| this.filterForm.value.element ==='null'))
    {
       console.log("required Fields");
       this.resultData =[];
       this.noRecordsFound = false;
       alert("Please select Any Field to Search");
       this.loader=false;
       this.showtable=false;
      //  return;
      this.isProcessing=false;
      // this.getrecoveryadjustment();
    }

     if((this.filterForm.value.employeecode !='' && this.filterForm.value.employeecode !=null)&&(this.filterForm.value.financialyear=== null || this.filterForm.value.financialyear === 'null')&&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')&&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
    { 
      console.log("employeecode");
      this.filterForm.patchValue({
        employeecode: this.empnumber +"-" + this.firstname
      });
      this.empCode=false;


      if(this.clientId){
      this.payrollservice.getAdjustmenClientList(this.empnumber,this.clientId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    }

    if(this.orgId){
      this.payrollservice.getAdjustmentOrgList(this.empnumber,this.orgId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    }
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode ===null)&&(this.filterForm.value.financialyear!= null && this.filterForm.value.financialyear != 'null')&&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')&&(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    {
      console.log("Year");
      this.payrollservice.getAdjustmentYearList(this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode ===null)&&(this.filterForm.value.financialyear!= null && this.filterForm.value.financialyear != 'null')&&(this.filterForm.value.period!= null &&  this.filterForm.value.period != 'null')&&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
    { 
      console.log("year and period"); 
      this.payrollservice.getAdjustmentYearandPeriodList(this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    }
    else if((this.filterForm.value.employeecode=='' || this.filterForm.value.employeecode==null)&&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')&&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')&&(this.filterForm.value.element!= null && this.filterForm.value.element != 'null'))
    { 
      console.log("element");
      this.payrollservice.getAdjustmentElementList(this.filterForm.value.element).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    }
    else if((this.filterForm.value.employeecode!= '' || this.filterForm.value.employeecode !=null) &&(this.filterForm.value.financialyear!= null && this.filterForm.value.financialyear != 'null')&&(this.filterForm.value.period!= null && this.filterForm.value.period != 'null')&&(this.filterForm.value.element!= null &&  this.filterForm.value.element != 'null'))
    { 
      this.filterForm.patchValue({
        employeecode: this.empnumber +"-" + this.firstname
      });
      this.empCode=false;
      console.log("Both");
      this.payrollservice.getAdjustmentAllList( this.empnumber,this.filterForm.value.financialyear,this.filterForm.value.period,this.filterForm.value.element).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    }
    else if((this.filterForm.value.employeecode!= '' || this.filterForm.value.employeecode !=null) &&(this.filterForm.value.financialyear!= null && this.filterForm.value.financialyear != 'null')&&(this.filterForm.value.period!= null && this.filterForm.value.period != 'null')&&(this.filterForm.value.element== null ||  this.filterForm.value.element == 'null'))
    { 
      console.log("Both");
      this.payrollservice.getAdjustmentemployeeandperiod( this.employeeId,this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    } else if((this.filterForm.value.employeecode!= '' || this.filterForm.value.employeecode !=null) &&(this.filterForm.value.financialyear!= null && this.filterForm.value.financialyear != 'null')&&(this.filterForm.value.period == null ||  this.filterForm.value.period == 'null')&&(this.filterForm.value.element== null ||  this.filterForm.value.element == 'null'))
    { 
      console.log("Both");
      this.payrollservice.getAdjustmentemployeeandyear( this.employeeId,this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;

      })
    }else if((this.filterForm.value.employeecode!= '' || this.filterForm.value.employeecode !=null) &&(this.filterForm.value.financialyear!= null && this.filterForm.value.financialyear != 'null')&&(this.filterForm.value.period == null || this.filterForm.value.period == 'null')&&(this.filterForm.value.element!= null &&  this.filterForm.value.element != 'null')){
      this.selectperiod =  true;
      this.loader =  false;
      this.showtable=  false;
      this.isProcessing=false;
    }
  }
  getrecoveryadjustment() {
     console.log("getrecoveryadjustment");
     this.loader=true;

      this.payrollservice.getAdjustmenttByorganziation(this.orgId).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.loader=false;

      },
      (error) => {
        this.loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
   
  }
  

  selectFinancialperiod(){
    this.selectperiod =  false;
  }

  // selectFinancialperiod() {
  //   const selectedPeriod = this.filterForm.controls['period'].value;
  
    
  //   if (!selectedPeriod) {
  //     this.selectperiod = true; 
  //   } else {
  //     this.selectperiod = false;
  //   }
  // }
  
  
  onItemsPerPageChange(event: any) {
    this.pagePerItem = event.target.value;
    this.p = 1; // Reset the pagination to the first page when items per page is changed
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

  selectFinancialyear()
  {
    console.log("Select");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    //  this.chooseFinId=event.target.value;
    this.chooseFinId= this.filterForm.controls['financialyear'].value;
    this.filterForm.controls['financialyear'].value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null')
    {
      console.log('if');
      this.finPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        period: null,
      });
    });
    }
  }
  // selectFinancialyear() {
  //   console.log("Select Financial Year triggered");
    
  //   // Get the selected Financial Year ID
  //   this.chooseFinId = this.filterForm.controls['financialyear'].value;
  //   console.log("Selected Financial Year ID:", this.chooseFinId);
  
  //   if (this.chooseFinId == null || this.chooseFinId === 'null') {
  //     // If no financial year is selected, clear the period list and reset the form
  //     console.log("Financial Year is null, clearing periods");
  //     this.finPeriodList = [];
  //     this.filterForm.patchValue({
  //       period: null, // Reset period field
  //     });
  //   } else {
  //     // If a financial year is selected, fetch periods from the API
  //     console.log("Fetching periods for Financial Year ID:", this.chooseFinId);
  //     this.settingsService.getFinancialYearId(this.chooseFinId).subscribe(
  //       (resultData: any) => {
  //         console.log("API Response for Period Data:", resultData);
  
  //         // Update the period list with data from the API
  //         this.finPeriodList = resultData.finanicalPeriod;
  
  //         // Reset period field in the form
  //         this.filterForm.patchValue({
  //           period: null,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error fetching periods:", error);
  
  //         // Handle API error by clearing the period list
  //         this.finPeriodList = [];
  //       }
  //     );
  //   }
  // }
  

  importEmployee()
  {
    console.log("import");
    console.log("import Employee");
    const dialogRef = this.dialog.open(ImportEmployeerecoveryadjustmentComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });
  
    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();

      console.log('resultcheck',result);
      
      if(result?.statusCode == 200)
      {
        this.success = true;
        this.setBool=true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false},redirectMsgTimeOut)
      }
     
      else 
      {
        console.log("result",result);
        if (Array.isArray(result)) {
          console.log("Array data",result);
          result.forEach((descript: any) => {
            if (descript.statusCode === 409) {
              console.log("400 or 409 ")
              this.error = true;
              this.importerror = false;
              this.error_msg = descript.description;
              this.loader = false;
              console.log(this.errormessagejson);
              // this.isprocessing1 = false;
              setTimeout(() => {
                this.error = false;
              }, 3000);
            }
          });
        }
        else if(result.statusCode = 400){
          this.error = true;
          this.importerror = false;
          this.error_msg = result.error.description;
          this.loader = false;
          console.log(this.error_msg);
          // this.isprocessing1 = false;
          setTimeout(() => {
            this.error = false;
          }, 3000);
        }
        else if(result.length != 0 && result != undefined)
         {
          console.log("Import Error");
          this.error = true;
          this.error_msg = result.message;
          this.importerror = true;
          this.setBool=false;
          this.errormessagejson = result;
          // this.showerrormessage()
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         }
          else
          {
            if(result?.statusCode >= 400)
            {
              this.error = true;
              this.importerror = false;
            }
         }
        
      }
      if (Array.isArray(result)) {
        result.forEach((descript: any) => {
          if (descript.statusCode === 409) {
            this.error = true;
            this.importerror = false;
            this.error_msg = descript.description;
            this.loader = false;
            // this.isprocessing1 = false;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          }
        });
      } else {
        // Handle result as an object
        if (result?.statusCode === 409) {
          this.error = true;
          this.error_msg = result.description;
          this.loader = false;
          // this.isprocessing1 = false;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
      }
    });
  }


 
//   empSearch(event)
// { 
//   this.empList=[];
//   this.empCode=false;
//   console.log(event.target.value);
//   this.userId=event.target.value;
//   console.log(this.userId);
//   // Employee List Api
//     // this.empList.length == 0;
// 	  if(event.target.value.length >= 2)
//     {
//       if(this.employeelistsub)
//       {
//         this.employeelistsub.unsubscribe();
//       }
//       this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
//         .pipe(
//           takeUntil(this.unsubscribe$), // Cancel previous request
//           switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
//         )
//         .subscribe((result: any) => {
//           this.empList.length = 0;

//           if (result.statusCode) {
//             console.log(result.statusCode);
//             this.empList.length = 0;
//           } else {
//             this.empList = result;
//           }
//         });
// 	}
// }


empSearch(event){
  console.log(event.target.value)
  const inputElement = event.target as HTMLInputElement;
  console.log(inputElement) // Cast the event target as an input element
this. inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
 console.log(this.inputValue)
  // Clear the employee list and reset flags if input is empty
  if (this.inputValue === '') {
    this.empList = [];
    this.empCode = false;
    console.log('Input is empty, employee list cleared');
    return;
  }

  // Check if input length is sufficient for the search
  if (this.inputValue.length >= 2) {
    // Unsubscribe from the previous API request if necessary
    if (this.employeelistsub) {
      this.employeelistsub.unsubscribe();
    }

    // Reset and create a new Subject for cancellation
    this.unsubscribe$ = new Subject<void>();

    if(this.orgId){
    this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
      .pipe(
        takeUntil(this.unsubscribe$), // Cancel previous request
        catchError((err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true; // Set the flag to indicate an error state
          }
          console.error('An error occurred:', err);
          return EMPTY; // Return EMPTY to propagate completion downstream
        })
      )
      .subscribe(
        (result: any) => {
          if (result.statusCode === 204) {
            console.log('No employees found (status code 204)');
            this.empList = [];
            this.empCode = true; // Indicate no results found
          } else if (result.statusCode) {
            console.log('Other status code:', result.statusCode);
            this.empList = [];
            this.empCode = true;
          } else {
            // Handle the case where result is an array or an object
            this.empList = Array.isArray(result) ? result : Object.values(result);
            this.empCode = false;
          }
        },
        (err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true;
          }
          console.error('An error occurred:', err);
        }
      );
    }

    if(this.clientId){
      this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }


  }

  
}

 
empSelect(emp) {
  // this.selectedEmployeeData=[];
  console.log('empSelect', emp);
  this.employeeAll = false;
  // this.empList=[];
  this.employeeId = emp.employeeId; 
  this.firstname=emp.firstName;
  // this.lastname=emp.lastName;
  this.empnumber=emp.employeeNumber;
  this.employee=this.empnumber +"-" + this.firstname;
  // if (this.employeeLeaveId) {
    this.filterForm.patchValue({
      employeecode: this.employee
    });
 

}




  get validate() { return this.filterForm.controls; }


  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 

  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Allow only alphanumeric characters, hyphens, and underscores
    const regex = /^[a-zA-Z0-9-_]*$/;
    if (!regex.test(value)) {
      value = value.replace(/[^a-zA-Z0-9-_]/g, '');
    }
  
    input.value = value;
    this.filterForm.get('employeecode').setValue(value);
  }
 
}
