import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PositionDetailsComponent } from '../position-details/position-details.component';
import { DatePipe } from '@angular/common';
import { HrmsService } from 'src/app/providers/hrms.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
})
export class ResourceComponent implements OnInit {
  resourceform: FormGroup;
  // extension: string;
  loader: any = false;
  backdrop: any = false;
  success: any = false;
  error: any = false;
  positionlist: any;
  // mydata: any = [];
  // myFile: any = [];
  isShow: boolean = false;
  employeeId: any;
  user: any;
  interviewStatuslist: any = [];
  update_data_error_msg: any;
  resourceId: any;
  resourceobj: any = {};
  selectedstatus: any = false;
  sucessmessage: any;
  selectedvalue: string = '';
  selectedValue: string;
  noticeperiod: any;
  isDetails: any;
  resourceselected: any = false;
  deletedresourcetime: any = [];
  i: any = [];
  requrimentlist: any;
  jobLenteredText: any;
  currentLenteredText: any;
  relocatestatus: any = false;
  // fileName: any;
  submitted: boolean;
  error_msg: any;
  sucess_msg: any;
  downloadbaseurl = environment.rec_base_url;

  buttonDisabled: boolean = false;
  maxExp: boolean;
  myFile: any[] = [];
  fileName: string = '';
  downloadUrl: string = '';
  mydata: any = {};
  extension: string = '';
  newFileSelected: boolean = false;
  payload: {
    fileName: string;
    fileType: string;
    fileSize: number;
    fileBytes: any;
    fileId: any;
    status: { listTypeValueId: number };
  };
  dowUrl: any;
  storeSourfileName: string;
  fileInputLabel: string;
  storeUserId: any;
  isUpdating: boolean = false;
  isEditing: boolean = false;
  storeDate: any;
  storetime: any;
  timeSet: any[] = [];
  orgId: any;
  constructor(
    private router: Router,
    private resourceservice: RecruitmentService,
    private positionservice: HrmsserviceService,
    private activatedRoute: ActivatedRoute,
    private hrmsService: HrmsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('enoteUserData'));
    this.orgId=this.user.organization.organizationId
    console.log(this.user);
    this.storeUserId = this.user.userId;

    console.log('this.storeuserid::', this.storeUserId);

    this.employeeId = this.user.employee.employeeId;
    console.log(this.employeeId);

    this.positionservice.positionactivelist(this.orgId).subscribe((positions: any) => {
      this.positionlist = positions;
      console.log(this.positionlist);
    });

    this.resourceservice
      .interviewedstatus()
      .subscribe((interviewStatus: any) => {
        this.interviewStatuslist = interviewStatus;
        console.log(this.interviewStatuslist);
      });
    //noticeperiod
    this.resourceservice.noticeperiod().subscribe((data: any) => {
      this.noticeperiod = data;
      console.log(this.noticeperiod);
    });

    console.log(
      'this.activatedRoute.snapshot.params.resourceId ' +
        this.activatedRoute.snapshot.params.resourceId
    );

    if (this.activatedRoute.snapshot.params.resourceId) {
      // this.isUpdating=true;
      this.resourceform = new FormGroup({
        // name: new FormControl(this.resourceform, [
        //   Validators.required,
        //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        // ]),
        name: new FormControl('', [
          Validators.required,
          Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -/']+$"),
        ]),
        position: new FormControl(this.resourceform),
        requirement: new FormControl(this.resourceform),

        interview_date: new FormControl(this.resourceform, [
          Validators.required,
        ]),
        dateOfJoining: new FormControl(this.resourceform),
        total_experience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
        ]),
        relevent_experience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
        ]),
        notice_period: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[0-9_a-z A-Z]*$/),
        ]),
        current_CTC: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/),
        ]),
        expected_CTC: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/),
        ]),
        job_location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        Current_location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        relocate: new FormControl(this.resourceform),
        // resource_availability : new FormControl(this.resourceform,[Validators.required]),
        resource_availability: this.fb.array([
          this.fb.group({
            date_picker1: ['', Validators.required],
            time1: ['', Validators.required],
            resourceAvailabilityId: [''],
          }),
        ]),
        // resource_availability: this.fb.array([this.createResourceAvailability()])
        // ,
        interviewed: new FormControl(this.resourceform, [Validators.required]),
        interviewStatus: new FormControl(this.resourceform),
        comments: new FormControl(this.resourceform),
        resourcecvattachment: new FormControl(this.resourceform),
      });

      this.loader = true;
      this.isUpdating = true;
      this.isEditing = true;
      // this.resourceform.controls['position'].disable();
      // this.resourceform.controls['requirement'].disable();

      this.resourceservice
        .findbyResourceId(this.activatedRoute.snapshot.params.resourceId)
        .subscribe((resource: any) => {
          this.isShow = true;
          this.resourceobj = resource;
          this.loader = false;
          this.isEditing = true;
          // this.isUpdating=false
          console.log(resource);
          console.log('this.resourceobj ::', this.resourceobj);

          let resourceAvailability = this.resourceobj.resourceAvailability;
          this.resourceId = this.activatedRoute.snapshot.params.resourceId;
          this.resourceform.patchValue({
            name: this.resourceobj.resourceName,
            position: this.resourceobj.position.positionId,
            // requirement : this.resourceobj.requirement.requirementNo,
            interview_date: this.resourceobj.interviewDate.substring(0, 10),
            // expectedjoining_date :  this.resourceobj.expectedJoiningDate.substring(0,10),
            total_experience: this.resourceobj.totalExperience,

            relevent_experience: this.resourceobj.relevantExperience,
            notice_period: this.resourceobj.noticePeriod.listTypeValueId,
            current_CTC: this.resourceobj.currentCtc,
            expected_CTC: this.resourceobj.expectedCtc,
            job_location: this.resourceobj.jobLocation,
            Current_location: this.resourceobj.currentLocation,
            relocate: this.resourceobj.relocate.toString(),
            interviewed: this.resourceobj.interviewed.toString(),
            comments: this.resourceobj.comments,
          });
          console.log(
            'this.resourceobj.requirement::',
            this.resourceobj.requirement
          );
          console.log(
            'this.resourceobj.requirement != null::',
            this.resourceobj.requirement != null
          );

          if (this.resourceobj.requirement != null) {
            this.resourceform.patchValue({
              requirement: this.resourceobj.requirement.requirementId,
            });
            console.log('this.resourceform::', this.resourceform);
          }

          this.selectedvalue = this.resourceobj.position.positionId;
          this.isDetails = this.resourceobj.position.positionId;

          this.resourceservice
            .requrimentbypostionActiveOpen(this.isDetails)
            .subscribe((requriments: any) => {
              this.requrimentlist = requriments;
              console.log(this.requrimentlist);
              this.requrimentlist.forEach((element) => {
                console.log('element.requirementId ', element.requirementId);
                // console.log('resource.requirementId', resource.requirement.requirementId)
                if (resource.requirement != null) {
                  console.log('if came');

                  if (
                    element.requirementId == resource.requirement.requirementId
                  ) {
                    console.log('else:::');

                    this.resourceform.patchValue({
                      requirement: resource.requirement.requirementId,
                    });
                    console.log('this.resourceform::', this.resourceform);
                  }
                }

                console.log('this.resourceform', this.resourceform);
              });

              // if(this.resourceform.value.requirement != null)
              // {
              //   var index = this.requrimentlist.findIndex(requriments=>  requriments.requirementId === this.resourceform.value.requirement);

              //   if(index < 0)
              //   {
              //     this.resourceform.patchValue({
              //       requirement:null
              //     })
              //   }
              // }
            });
          const formArray = this.resourceform.get(
            'resource_availability'
          ) as FormArray;

          console.log(resourceAvailability);
          console.log(resourceAvailability.length != 0);
          if (resourceAvailability.length != 0) {
            console.log(
              resourceAvailability[0].resourceAvailabilityDate.substring(11, 20)
            );
            let timestring =
              resourceAvailability[0].resourceAvailabilityDate.substring(
                11,
                20
              );
            let timestringarray = timestring.split(':');
            const time = new Date(
              resourceAvailability[0].resourceAvailabilityDate
            );
            time.setHours(timestringarray[0]);
            time.setMinutes(timestringarray[1]);

            formArray.at(0).patchValue({
              date_picker1:
                resourceAvailability[0].resourceAvailabilityDate.substring(
                  0,
                  10
                ),
              time1: time,
              resourceAvailabilityId:
                resourceAvailability[0].resourceAvailabilityId,
            });
          }

          console.log(resourceAvailability);
          // this.i=2;
          for (let i = 1; i < resourceAvailability.length; i++) {
            let timestring = resourceAvailability[
              i
            ].resourceAvailabilityDate.substring(11, 20);
            let timestringarray = timestring.split(':');

            const timefor = new Date(
              resourceAvailability[i].resourceAvailabilityDate
            );
            timefor.setHours(timestringarray[0]);
            timefor.setMinutes(timestringarray[1]);
            console.log('timefor' + timefor);
            console.log(i);
            formArray.push(
              this.fb.group({
                date_picker1: resourceAvailability[
                  i
                ].resourceAvailabilityDate.substring(0, 10),
                time1: timefor,
                resourceAvailabilityId:
                  resourceAvailability[i].resourceAvailabilityId,
              })
            );
          }

          this.selectedstatus = this.resourceobj.interviewed;

          this.relocatestatus =
            this.resourceobj.currentLocation.toLowerCase() ===
            this.resourceobj.jobLocation.toLowerCase();
          if (this.relocatestatus == false) {
            this.relocatestatus = true;
          } else {
            this.relocatestatus = false;
          }
          this.jobLenteredText = this.resourceobj.jobLocation;
          console.log('jobLenteredText', this.jobLenteredText);
          this.currentLenteredText = this.resourceobj.currentLocation;
          console.log('currentLenteredText', this.currentLenteredText);

          if (this.resourceobj.interviewStatus != undefined) {
            this.resourceform.patchValue({
              interviewStatus: this.resourceobj.interviewStatus.listTypeValueId,
            });

            if (
              this.resourceobj.interviewStatus.listTypeValueName == 'Selected'
            ) {
              this.resourceselected = true;
            }
          }
          if (this.resourceobj.requirement != undefined) {
            this.resourceform.patchValue({
              requirement: this.resourceobj.requirement.requirementId,
            });
          }
          //  if(this.resourceobj.expectedJoiningDate != undefined)
          //  {
          //   this.resourceform.patchValue({
          //     dateOfJoining : this.resourceobj.expectedJoiningDate
          //   });
          // }
          this.resourceform.patchValue({
            resourcecvattachment:
              this.resourceobj.resourcecvattachment?.cvFileName,
          });
        });
    } else {
      // this.isUpdating=false;
      this.resourceform = new FormGroup({
        // name: new FormControl(this.resourceform, [
        //   Validators.required,
        //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        // ]),
        name: new FormControl('', [
          Validators.required,
          Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z_ -./']+$"),
        ]),
        position: new FormControl(this.resourceform, [Validators.required]),
        requirement: new FormControl(this.resourceform),
        interview_date: new FormControl(this.resourceform, [
          Validators.required,
        ]),
        //  dateOfJoining : new FormControl(this.resourceform),
        total_experience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
        ]),
        relevent_experience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
        ]),
        notice_period: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[0-9_a-z A-Z]*$/),
        ]),
        current_CTC: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/),
        ]),
        expected_CTC: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[0-9]\d{0,10}$/),
        ]),
        job_location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        Current_location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        relocate: new FormControl(this.resourceform, [Validators.required]),
        // resource_availability : new FormControl(this.resourceform,[Validators.required]),
        resource_availability: this.fb.array([
          this.fb.group({
            date_picker1: ['', Validators.required],
            time1: ['', Validators.required],
          }),
        ]),
        // resource_availability: this.fb.array([this.createResourceAvailability()]),

        interviewed: new FormControl(this.resourceform),
        interviewStatus: new FormControl(this.resourceform),
        comments: new FormControl(this.resourceform),
        resourcecvattachment: new FormControl(this.resourceform),
      });
    }
    // this.setupValueChanges();
  }

  // add() {
  //   const newGroup = this.createResourceAvailability();
  //   this.resourceAvailability.push(newGroup);

  //   // Attach value changes to new form controls
  //   this.setupValueChanges();
  // }
  removeReminder(index: number) {
    this.resourceAvailability.removeAt(index);
  }

  interviewedchange(event: any) {
    console.log('value' + event.value);
    if (event.value == 'true') {
      this.selectedstatus = true;
      this.resourceform
        .get('interviewStatus')
        .setValidators(Validators.required);
    } else {
      //  this.resourceform.get('interviewStatus').clearValidators();
      this.resourceform.controls['interviewStatus'].setErrors(null);
      this.selectedstatus = false;
    }
  }

  interviewstatusChange(event: any) {
    if (event.value == 28) {
      this.resourceselected = true;
      //    this.resourceform.get('dateOfJoining').setValidators(Validators.required);
    } else {
      //  this.resourceform.controls['dateOfJoining'].setErrors(null);
      this.resourceselected = false;
    }
  }

  selected(event: any) {
    if (this.isUpdating) {
      return; // Prevent selection change if updating
    }
    // Update the UI
    this.selectedvalue = event.value;
    console.log(this.selectedvalue);

    if (this.selectedvalue != null) {
      this.resourceservice
        .requrimentbypostionActiveOpen(this.selectedvalue)
        .subscribe((requriments: any) => {
          this.requrimentlist = requriments;
          console.log(this.requrimentlist);

          // Set isShow to true if there are any requirements, otherwise false
          this.isShow = this.requrimentlist.length > 0;

          if (this.resourceform.value.requirement != null) {
            var index = this.requrimentlist.findIndex(
              (requriment) =>
                requriment.requirementId === this.resourceform.value.requirement
            );

            if (index < 0) {
              this.resourceform.patchValue({
                requirement: null,
              });
            }
          }
        });
    } else {
      this.positionDetails;
      this.isShow = false; // Hide requirement field if no position is selected
    }
  }

  positionDetails() {
    console.log('selectedvalue' + this.selectedvalue);
    var dialogbox = this.dialog.open(PositionDetailsComponent, {
      width: '750px',
      height: '400px',
      data: this.selectedvalue,
    });
    // this.selectedvalue != null
    // this.isDetails = ! this.isDetails;
  }
  dateTimeDuplicateValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const formGroup = control as FormGroup;
    const formArray = formGroup.parent as FormArray;

    if (!formArray) return null;

    const date = formGroup.get('date_picker1')?.value;
    const time = formGroup.get('time1')?.value;

    const duplicates = formArray.controls.filter((group: AbstractControl) => {
      return (
        group !== formGroup &&
        group.get('date_picker1')?.value === date &&
        group.get('time1')?.value === time
      );
    });

    return duplicates.length > 0 ? { duplicateDateTime: true } : null;
  }

  createResourceAvailability(): FormGroup {
    return this.fb.group(
      {
        date_picker1: ['', Validators.required],
        time1: ['', Validators.required],
      },
      { validators: this.dateTimeDuplicateValidator.bind(this) }
    );
  }

  submitData() {


    this.buttonDisabled = true;
    this.submitted = true;
    console.log('this.resourceform', this.resourceform);
    if (this.resourceform.invalid) {
      this.buttonDisabled = false;
      return;
    }
    this.error = false;
    this.success = false;

    let resourceAvailabilitylisrt =
      this.resourceform.value.resource_availability;

    if (this.resourceform.invalid) {
      const controls = this.resourceform.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
        }
      }
      this.createResourceAvailability();

      return;
    }

    this.resourceservice.noticeperiod().subscribe((data: any) => {
      this.noticeperiod = data;
      console.log(this.noticeperiod);
    });

    this.backdrop = true;
    // this.loader = true;

    if (this.resourceId) {
      this.isUpdating = true;
      this.isEditing = true;

      // let interviewdate =  this.resourceform.value.interview_date + " " + "00:00:00" ;
      // console.log("interviewdate " + interviewdate);

      //this.mydata
      var resourcedataupdate;

      if (
        this.resourceobj.resourcecvattachment != undefined &&
        this.mydata.length == 0
      ) {
        console.log('exsting  attachment');
        resourcedataupdate = {
          resourceId: this.resourceId,
          resourceName: this.resourceform.value.name,
          position: { positionId: this.resourceform.value.position },
          // requirement: {requirementId: this.resourceform.value.requirement},
          interviewDate: this.resourceform.value.interview_date,
          //     expectedJoiningDate : this.resourceform.value.dateOfJoining,
          totalExperience: this.resourceform.value.total_experience,
          relevantExperience: this.resourceform.value.relevent_experience,
          noticePeriod: {
            listTypeValueId: this.resourceform.value.notice_period,
          },
          currentCtc: this.resourceform.value.current_CTC,
          expectedCtc: this.resourceform.value.expected_CTC,
          jobLocation: this.resourceform.value.job_location,
          currentLocation: this.resourceform.value.Current_location,
          relocate: this.resourceform.value.relocate,
          resourceAvailability: this.resourceform.value.resource_availability,
          interviewed: this.resourceform.value.interviewed,
          // interviewStatus : {
          //   listTypeValueId :  this.resourceform.value.interviewStatus
          // },
          comments: this.resourceform.value.comments,
          resourcecvattachment: {
            resourceCvId: this.resourceobj.resourcecvattachment.resourceCvId,
            cvFileName: this.resourceobj.resourcecvattachment.cvFileName,
            cvFileSize: this.resourceobj.resourcecvattachment.cvFileSize,
            cvFileType: this.resourceobj.resourcecvattachment.cvFileType,
            cvBytearray: this.resourceobj.resourcecvattachment.cvBytearray,
            fileViewUrl: this.resourceobj.fileViewUrl,
            fileDownloadUrl: this.resourceobj.fileDownloadUrl,
          },
          createdBy: this.resourceobj.createdBy,
          lastUpdatedBy: this.resourceobj.createdBy,
          status: this.resourceobj.status,
          organizationId: this.user.organization.organizationId,
        };
      } else if (
        this.resourceobj.resourcecvattachment != undefined &&
        this.mydata.length != 0
      ) {
        console.log('exsting  attachment update');

        resourcedataupdate = {
          resourceId: this.resourceId,
          resourceName: this.resourceform.value.name,
          position: { positionId: this.resourceform.value.position },
          // requirement: {requirementId: this.resourceform.value.requirement},
          interviewDate: this.resourceform.value.interview_date,
          //  expectedJoiningDate : this.resourceform.value.dateOfJoining,
          totalExperience: this.resourceform.value.total_experience,
          relevantExperience: this.resourceform.value.relevent_experience,
          noticePeriod: {
            listTypeValueId: this.resourceform.value.notice_period,
          },
          currentCtc: this.resourceform.value.current_CTC,
          expectedCtc: this.resourceform.value.expected_CTC,
          jobLocation: this.resourceform.value.job_location,
          currentLocation: this.resourceform.value.Current_location,
          relocate: this.resourceform.value.relocate,
          resourceAvailability: this.resourceform.value.resource_availability,
          interviewed: this.resourceform.value.interviewed,
          // interviewStatus : {
          //   listTypeValueId :  this.resourceform.value.interviewStatus
          // },
          comments: this.resourceform.value.comments,
          resourcecvattachment: {
            resourceCvId: this.resourceobj.resourcecvattachment.resourceCvId,
            cvFileName: this.mydata.sourceFileName,
            cvFileSize: this.mydata.sourceFileSize,
            cvFileType: this.mydata.sourceFileType,
            cvBytearray: this.mydata.sourceByteArray,
            fileViewUrl: this.resourceobj.fileViewUrl,
            fileDownloadUrl: this.resourceobj.fileDownloadUrl,
          },
          createdBy: this.resourceobj.createdBy,
          lastUpdatedBy: this.resourceobj.createdBy,
          status: this.resourceobj.status,
          organizationId: this.user.organization.organizationId,
        };
      } else if (
        this.resourceobj.resourcecvattachment == undefined &&
        this.mydata.length != 0
      ) {
        console.log('new  attachment');
        resourcedataupdate = {
          resourceId: this.resourceId,
          resourceName: this.resourceform.value.name,
          position: { positionId: this.resourceform.value.position },
          // requirement: {requirementId: this.resourceform.value.requirement},
          interviewDate: this.resourceform.value.interview_date,
          //   expectedJoiningDate : this.resourceform.value.dateOfJoining,
          totalExperience: this.resourceform.value.total_experience,
          relevantExperience: this.resourceform.value.relevent_experience,
          noticePeriod: {
            listTypeValueId: this.resourceform.value.notice_period,
          },
          currentCtc: this.resourceform.value.current_CTC,
          expectedCtc: this.resourceform.value.expected_CTC,
          jobLocation: this.resourceform.value.job_location,
          currentLocation: this.resourceform.value.Current_location,
          relocate: this.resourceform.value.relocate,
          resourceAvailability: this.resourceform.value.resource_availability,
          interviewed: this.resourceform.value.interviewed,
          // interviewStatus : {
          //   listTypeValueId :  this.resourceform.value.interviewStatus
          // },
          comments: this.resourceform.value.comments,
          resourcecvattachment: {
            cvFileName: this.mydata.sourceFileName,
            cvFileSize: this.mydata.sourceFileSize,
            cvFileType: this.mydata.sourceFileType,
            cvBytearray: this.mydata.sourceByteArray,
          },
          createdBy: this.resourceobj.userId,
          lastUpdatedBy: this.resourceobj.userId,
          status: this.resourceobj.status,
          organizationId: this.user.organization.organizationId,
        };
      } else {
        console.log('no  attachment');
        resourcedataupdate = {
          resourceId: this.resourceId,
          resourceName: this.resourceform.value.name,
          position: { positionId: this.resourceform.value.position },
          // requirement: {requirementId: this.resourceform.value.requirement},
          interviewDate: this.resourceform.value.interview_date,
          //     expectedJoiningDate : this.resourceform.value.dateOfJoining,
          totalExperience: this.resourceform.value.total_experience,
          relevantExperience: this.resourceform.value.relevent_experience,
          noticePeriod: {
            listTypeValueId: this.resourceform.value.notice_period,
          },
          currentCtc: this.resourceform.value.current_CTC,
          expectedCtc: this.resourceform.value.expected_CTC,
          jobLocation: this.resourceform.value.job_location,
          currentLocation: this.resourceform.value.Current_location,
          relocate: this.resourceform.value.relocate,
          resourceAvailability: this.resourceform.value.resource_availability,
          interviewed: this.resourceform.value.interviewed,
          // interviewStatus : {
          //   listTypeValueId :  this.resourceform.value.interviewStatus
          // },
          comments: this.resourceform.value.comments,

          // resourcecvattachment :
          // {

          //   cvFileName:this.mydata.sourceFileName,
          //   cvFileSize:this.mydata.sourceFileSize,
          //   cvFileType:this.mydata.sourceFileType,
          //   cvBytearray:this.mydata.sourceByteArray
          // },
          createdBy: this.resourceobj.createdBy,
          lastUpdatedBy: this.resourceobj.createdBy,
          status: this.resourceobj.status,
          organizationId: this.user.organization.organizationId,
        };
      }

      if (
        this.resourceform.value.interviewStatus != null &&
        this.resourceform.value.interviewed == 'true'
      ) {
        resourcedataupdate['interviewStatus'] = {
          listTypeValueId: this.resourceform.value.interviewStatus,
        };
      }

      if (this.resourceform.value.requirement != null) {
        resourcedataupdate['requirement'] = {
          requirementId: this.resourceform.value.requirement,
        };
      } else {
        resourcedataupdate['requirement'] = null;
      }

      let resource_availabilityArray: any = [];

      for (let i = 0; i < resourceAvailabilitylisrt.length; i++) {
        console.log(resourceAvailabilitylisrt[i]);
        let date = resourceAvailabilitylisrt[i].date_picker1;
        let time = resourceAvailabilitylisrt[i].time1;

        console.log('date' + this.datepipe.transform(date, 'yyyy-MM-dd'));
        console.log('time' + this.datepipe.transform(time, 'HH:mm:ss'));

        let remindartime =
          this.datepipe.transform(date, 'yyyy-MM-dd') +
          'T' +
          this.datepipe.transform(time, 'HH:mm:ss');

        console.log('remindartime' + remindartime);

        var resource_availabilityupdateobj = {
          resourceAvailabilityId:
            resourceAvailabilitylisrt[i].resourceAvailabilityId,
          resourceAvailabilityDate: remindartime,
          resourceAvailabilityStatus: {
            listTypeValueId: 1,
          },
        };

        resource_availabilityArray.push(resource_availabilityupdateobj);
      }

      console.log(this.deletedresourcetime.length);
      for (let i = 0; i < this.deletedresourcetime.length; i++) {
        console.log(this.deletedresourcetime[i]);
        let date = this.deletedresourcetime[i].date_picker1;
        let time = this.deletedresourcetime[i].time1;

        console.log('date' + this.datepipe.transform(date, 'yyyy-MM-dd'));
        console.log('time' + this.datepipe.transform(time, 'HH:mm:ss'));

        let remindartime =
          this.datepipe.transform(date, 'yyyy-MM-dd') +
          'T' +
          this.datepipe.transform(time, 'HH:mm:ss');

        console.log('remindartime' + remindartime);

        var resource_availabilityupdateobj = {
          resourceAvailabilityId:
            this.deletedresourcetime[i].resourceAvailabilityId,
          resourceAvailabilityDate: remindartime,
          resourceAvailabilityStatus: {
            listTypeValueId: 2,
          },
        };

        resource_availabilityArray.push(resource_availabilityupdateobj);
      }

      resourcedataupdate['resourceAvailability'] = resource_availabilityArray;

      console.log(resourcedataupdate);

      console.log(resourcedataupdate);
      //  return
      this.resourceservice
        .resourceupdate(resourcedataupdate, this.resourceId)
        .subscribe(
          (data: any) => {
            // this.isUpdating = false;

            if (data.statusCode == 200) {
              console.log('success');
              this.success = true;
              this.loader = false;
              this.isUpdating = true;
              this.sucess_msg = data.description;

              setTimeout(() => {
                this.success = false;
                this.loader = false;

                this.router.navigate(['/home/resource-list']);
              }, redirectMsgTimeOut);
            } else {
              console.log('error');
              this.error = true;
              this.loader = false;

              this.error_msg = data.description;
              setTimeout(() => {
                this.error = false;
              }, redirectMsgTimeOut);
            }
          },
          (err) => {
            this.backdrop = false;
            this.loader = false;
            this.error = true;
            // this.isUpdating = false;

            this.update_data_error_msg = err.error.message;
            setTimeout(() => {
              this.error = false;
            }, redirectMsgTimeOut);
          }
        );
    } else {
      this.isUpdating = false;
      this.isEditing = false;

      var resourcedata;
      console.log(this.mydata);
      if (this.mydata.length != 0) {
        // this.isUpdating=false;
        resourcedata = {
          resourceName: this.resourceform.value.name,
          position: { positionId: this.resourceform.value.position },
          // requirement: {requirementId: this.resourceform.value.requirement},
          interviewDate: this.resourceform.value.interview_date,
          //   expectedJoiningDate : this.resourceform.value.dateOfJoining,
          totalExperience: this.resourceform.value.total_experience,
          relevantExperience: this.resourceform.value.relevent_experience,
          noticePeriod: {
            listTypeValueId: this.resourceform.value.notice_period,
          },
          currentCtc: this.resourceform.value.current_CTC,
          expectedCtc: this.resourceform.value.expected_CTC,
          jobLocation: this.resourceform.value.job_location,
          currentLocation: this.resourceform.value.Current_location,
          relocate: this.resourceform.value.relocate,
          //    resourceAvailability : this.resourceform.value.resource_availability,
          interviewed: this.resourceform.value.interviewed,
          // interviewStatus : {
          //   listTypeValueId :  this.resourceform.value.interviewStatus
          // },
          comments: this.resourceform.value.comments,
          resourcecvattachment: {
            cvFileName: this.mydata.sourceFileName,
            cvFileSize: this.mydata.sourceFileSize,
            cvFileType: this.mydata.sourceFileType,
            cvBytearray: this.mydata.sourceByteArray,
          },
          createdBy: this.storeUserId,
          organizationId: this.user.organization.organizationId,
        };

        console.log('payload::', resourcedata);

        if (this.resourceform.value.interviewStatus != null) {
          resourcedata['interviewStatus'] = {
            listTypeValueId: this.resourceform.value.interviewStatus,
          };
        }
        console.log(
          'this.resourceform.value.requirement',
          this.resourceform.value.requirement
        );
        if (this.resourceform.value.requirement != undefined) {
          console.log('if req');
          resourcedata['requirement'] = {
            requirementId: this.resourceform.value.requirement,
          };
        } else {
          console.log('el req');
          resourcedata['requirement'] = null;
        }
      } else {
        console.log('no file');
        resourcedata = {
          resourceName: this.resourceform.value.name,
          position: { positionId: this.resourceform.value.position },
          // requirement: {requirementId: this.resourceform.value.requirement},
          interviewDate: this.resourceform.value.interview_date,
          //     expectedJoiningDate : this.resourceform.value.dateOfJoining,
          totalExperience: this.resourceform.value.total_experience,
          relevantExperience: this.resourceform.value.relevent_experience,
          noticePeriod: {
            listTypeValueId: this.resourceform.value.notice_period,
          },
          currentCtc: this.resourceform.value.current_CTC,
          expectedCtc: this.resourceform.value.expected_CTC,
          jobLocation: this.resourceform.value.job_location,
          currentLocation: this.resourceform.value.Current_location,
          relocate: this.resourceform.value.relocate,
          //   resourceAvailability : this.resourceform.value.resource_availability,
          interviewed: this.resourceform.value.interviewed,
          // interviewStatus : {
          //   listTypeValueId :  this.resourceform.value.interviewStatus
          // },
          comments: this.resourceform.value.comments,
          // resourcecvattachment :
          // {
          //   cvFileName:this.mydata.sourceFileName,
          //   cvFileSize:this.mydata.sourceFileSize,
          //   cvFileType:this.mydata.sourceFileType,
          //   cvBytearray:this.mydata.sourceByteArray
          // },
          createdBy: this.storeUserId,
          organizationId: this.user.organization.organizationId,
        };
        console.log('payload 1::', resourcedata);

        if (this.resourceform.value.interviewStatus != null) {
          resourcedata['interviewStatus'] = {
            listTypeValueId: this.resourceform.value.interviewStatus,
          };
        }
        console.log(
          'this.resourceform.value.requirement',
          this.resourceform.value.requirement
        );
        if (this.resourceform.value.requirement != undefined) {
          console.log('if req');
          resourcedata['requirement'] = {
            requirementId: this.resourceform.value.requirement,
          };
        } else {
          console.log('el req');
          resourcedata['requirement'] = null;
        }
      }

      let resource_availabilityArray: any = [];

      for (let i = 0; i < resourceAvailabilitylisrt.length; i++) {
        console.log(resourceAvailabilitylisrt[i]);
        let date = resourceAvailabilitylisrt[i].date_picker1;
        let time = resourceAvailabilitylisrt[i].time1;

        console.log('date' + this.datepipe.transform(date, 'yyyy-MM-dd'));
        console.log('time' + this.datepipe.transform(time, 'HH:mm:ss'));

        let remindartime =
          this.datepipe.transform(date, 'yyyy-MM-dd') +
          'T' +
          this.datepipe.transform(time, 'HH:mm:ss');

        console.log('remindartime' + remindartime);

        var resource_availabilityobj = {
          resourceAvailabilityDate: remindartime,
        };
        resource_availabilityArray.push(resource_availabilityobj);
      }
      resourcedata['resourceAvailability'] = resource_availabilityArray;

      console.log(resourcedata);
      // return
      this.resourceservice.resourcecreate(resourcedata).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            console.log('success');
            this.success = true;
            this.loader = false;

            this.sucess_msg = data.description;

            setTimeout(() => {
              this.success = false;
              this.loader = false;

              this.router.navigate(['/home/resource-list']);
            }, redirectMsgTimeOut);
          } else {
            console.log('error');
            this.error = true;
            this.loader = false;

            this.error_msg = data.description;
            setTimeout(() => {
              this.error = false;
            }, redirectMsgTimeOut);
          }
        },
        (err) => {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
          this.update_data_error_msg = err.error.message;
          setTimeout(() => {
            this.error = false;
          }, redirectMsgTimeOut);
        }
      );
    }
  }
  formcancel() {
    this.router.navigate(['home/resource-list']);
  }
  downloadFile() {
    const a = document.createElement('a');
    a.href = this.downloadUrl;
    a.download = this.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  getFileDetails(event: any) {
    // const fileInput = event.target as HTMLInputElement;
    // const filePath = fileInput?.value;
    // const allowedExtensions = /(\.(?!jpg|jpeg|png|gif|bmp|webp|tiff)[^\.]+)$/i;

    // if (!allowedExtensions.exec(filePath)) {
    //   event.preventDefault();
    //   fileInput.value = '';
    //   alert('Only non-image files are allowed!');
    //   this.newFileSelected = false;

    //   return
    // } else {
    //   if (event.target.files.length > 0) {
    //     console.log("file if 1st!!");
    //     this.newFileSelected = true;

    //     const file = event.target.files[0];
    //     this.fileInputLabel = file.name;
    //     this.resourceform.get('resourcecvattachment').setValue(file);

    //     // event.preventDefault();

    //   }
    // }
    // const files = event.target.files;
    // console.log(files);

    // if (files) {
    //   console.log("files entered!!!");

    //   // this.newFileSelected = true;
    //     for (let i = 0; i < files.length; i++) {
    //     console.log("here for entered");

    //         if (files.length != 0) {
    //           console.log("entered here!!!");

    //             this.isShow = true;
    //             this.newFileSelected = true;

    //         }
    //         const image = {
    //             sourceFileName: '',
    //             sourceFileType: '',
    //             sourceFileSize: '',
    //             sourceByteArray: '',
    //             fileUrlName: '',
    //             size: '',
    //             filePath: '',
    //             url: '',
    //         };

    //         console.log(image);
    //         this.myFile.push(files[i]);
    //         const file = files[0];
    //         image.sourceFileName = files[i].name;
    //         this.storeSourfileName= image.sourceFileName
    //         console.log(" this.storeSourfileName::", this.storeSourfileName);

    //         console.log('sourceFileName' + image.sourceFileName);
    //         image.sourceFileType = files[i].type;
    //         console.log('sourceFileType' + image.sourceFileType);
    //         image.sourceFileSize = files[i].size;
    //         console.log('sourceFileSize' + image.sourceFileSize);
    //         image.size = image.size;
    //         console.log('size' + image.size);
    //         var totalBytes = files[i].size;
    //         console.log('totalBytes' + totalBytes);

    //         if (totalBytes < 1000000) {
    //             image.size = Math.floor(totalBytes / 1000) + 'KB';
    //         } else {
    //             image.size = Math.floor(totalBytes / 1000000) + 'MB';
    //         }
    //         var ext = image.sourceFileName.substring(
    //             image.sourceFileName.lastIndexOf('.') + 1
    //         );
    //         console.log('image.sourceFileType' + image.sourceFileType);

    //         if (
    //             image.sourceFileType.length == 0 &&
    //             (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc')
    //         ) {
    //             image.sourceFileType =
    //                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    //         }
    //         const reader = new FileReader();

    //         reader.onload = () => {
    //             image.filePath = reader.result + '';
    //             this.extension = image.sourceFileName.substring(
    //                 image.sourceFileName.lastIndexOf('.') + 1
    //             );
    //             var ext = image.sourceFileName.substring(
    //                 image.sourceFileName.lastIndexOf('.') + 1
    //             );

    //             let x: any = reader.result;
    //             let y: any = x.split(',');
    //             image.sourceByteArray = y[1];
    //             this.mydata = image;
    //             console.log(this.mydata);
    //         };
    //         reader.readAsDataURL(files[i]);
    //     }
    // }

    const fileInput = event.target as HTMLInputElement;
    const filePath = fileInput?.value;
    const allowedExtensions = /(\.(?!jpg|jpeg|png|gif|bmp|webp|tiff)[^\.]+)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = '';
      alert('Invalid File Type!');
      // this.newFileSelected = false;
      this.newFileSelected = true;
      event.preventDefault();
      return;
    } else {
      if (event.target.files.length > 0) {
        console.log('file if 1st!!');
        this.newFileSelected = true;

        const file = event.target.files[0];
        this.fileInputLabel = file.name;
        this.resourceform.get('resourcecvattachment')?.setValue(file);

        // event.preventDefault();
      }
    }
    console.log('att', event);
    const files = event.target.files;
    console.log('files', files);
    // if (files) {
    //   for (let i = 0; i < files.length; i++) {

    //     if (files.length != 0) {
    //       this.isShow = true;
    //       this.newFileSelected=true

    //     }
    //     const image = {
    //       sourceFileName: '',
    //       sourceFileType: '',
    //       sourceFileSize: '',
    //       sourceByteArray: '',
    //       fileUrlName: '',
    //       size: '',
    //       filePath: '',
    //       url: '',
    //     };

    //     const file = files[i];
    //     const fileType = file.type;
    //     const fileName = file.name;

    //     // Check if the file is an image
    //     // if (fileType.startsWith('image/')) {
    //     //   alert('Image files are not allowed.');
    //     //   this.newFileSelected=true
    //     //   return; // Skip processing this file
    //     // }

    //     this.fileName = fileName;
    //     console.log('this.fileName', this.fileName);
    //     console.log(image);
    //     this.myFile.push(file);
    //     image.sourceFileName = fileName;
    //     console.log('sourceFileName' + image.sourceFileName);
    //     image.sourceFileType = fileType;
    //     console.log('sourceFileType' + image.sourceFileType);
    //     image.sourceFileSize = file.size;
    //     console.log('sourceFileSize' + image.sourceFileSize);
    //     image.size = image.size;
    //     console.log('size' + image.size);
    //     var totalBytes = file.size;
    //     console.log('totalBytes' + totalBytes);

    //     if (totalBytes < 1000000) {
    //       image.size = Math.floor(totalBytes / 1000) + 'KB';
    //     } else {
    //       image.size = Math.floor(totalBytes / 1000000) + 'MB';
    //     }
    //     var ext = image.sourceFileName.substring(
    //       image.sourceFileName.lastIndexOf('.') + 1
    //     );
    //     console.log('image.sourceFileType' + image.sourceFileType);

    //     if (
    //       image.sourceFileType.length == 0 &&
    //       (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc')
    //     ) {
    //       image.sourceFileType =
    //         'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    //     }
    //     const reader = new FileReader();

    //     reader.onload = () => {
    //       image.filePath = reader.result + '';
    //       this.extension = image.sourceFileName.substring(
    //         image.sourceFileName.lastIndexOf('.') + 1
    //       );
    //       var ext = image.sourceFileName.substring(
    //         image.sourceFileName.lastIndexOf('.') + 1
    //       );

    //       let x: any = reader.result;
    //       let y: any = x.split(',');
    //       image.sourceByteArray = y[1];
    //       // this.mydata.push(image)
    //       this.mydata = image;
    //       console.log(this.mydata);
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // }
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files.length != 0) {
          this.isShow = true;
        }
        const image = {
          sourceFileName: '',
          sourceFileType: '',
          sourceFileSize: '',
          sourceByteArray: '',
          fileUrlName: '',
          size: '',
          filePath: '',
          url: '',
        };
        console.log(image);
        this.myFile.push(files[i]);
        image.sourceFileName = files[i].name;
        console.log('sourceFileName' + image.sourceFileName);
        image.sourceFileType = files[i].type;
        console.log('sourceFileType' + image.sourceFileType);
        image.sourceFileSize = files[i].size;
        console.log('sourceFileSize' + image.sourceFileSize);
        image.size = image.size;
        console.log('size' + image.size);
        var totalBytes = files[i].size;
        console.log('totalBytes' + totalBytes);

        if (totalBytes < 1000000) {
          image.size = Math.floor(totalBytes / 1000) + 'KB';
        } else {
          image.size = Math.floor(totalBytes / 1000000) + 'MB';
        }
        var ext = image.sourceFileName.substring(
          image.sourceFileName.lastIndexOf('.') + 1
        );
        console.log('image.sourceFileType' + image.sourceFileType);

        if (
          image.sourceFileType.length == 0 &&
          (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc')
        ) {
          image.sourceFileType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        const reader = new FileReader();

        reader.onload = () => {
          image.filePath = reader.result + '';
          this.extension = image.sourceFileName.substring(
            image.sourceFileName.lastIndexOf('.') + 1
          );
          var ext = image.sourceFileName.substring(
            image.sourceFileName.lastIndexOf('.') + 1
          );

          let x: any = reader.result;
          let y: any = x.split(',');
          image.sourceByteArray = y[1];
          // this.mydata.push(image)
          this.mydata = image;
          console.log(this.mydata);
        };
        reader.readAsDataURL(files[i]);
      }
    }
  }

  downloadfiles(dowUrl) {
    console.log('Download File' + dowUrl);
    const a = document.createElement('a');
    //  const objectUrl: any =  "data:" + filedata.fileType + filedata.fileEncoding + "," + filedata.fileByte;
    // const objectUrl =  `${base_url}download/${result}`
    let downloadurl = dowUrl;
    console.log('downloadurl' + downloadurl);
    const objectUrl = downloadurl;
    // const objectUrl =  `http://38.242.135.217:6018/api/enote/pms/download/TSDOC_6.jpg`
    a.href = objectUrl;
    a.download = objectUrl;
    a.click();
    URL.revokeObjectURL(objectUrl);
    console.log('Download File:' + a);
  }
  onFileSelected(event: any) {
    console.log('file Read', event);
    const file: File = event.target.files[0];
    console.log('file', file);

    if (file) {
      const fileName = file.name;
      const fileType = file.type;
      const fileSize = file.size;
      let fileId = null; // Initialize fileId as null

      // Check if fileId is available in this.resultData.announcementAttachment
      if (
        this.resourceobj &&
        this.resourceobj.resourcecvattachment &&
        this.resourceobj.resourcecvattachment.resourceCvId
      ) {
        fileId = this.resourceobj.resourcecvattachment.resourceCvId;
      }

      // Use the obtained file details in your payload
      this.payload = {
        fileName: fileName,
        fileType: fileType,
        fileSize: fileSize,
        fileBytes: null,
        fileId: fileId, // Include fileId in the payload
        status: {
          listTypeValueId: 1,
        },
      };

      this.resourceform.patchValue({
        fileName: fileName,
      });

      console.log(this.payload);
    }

    let reader = new FileReader();
    reader.onload = () => {
      let x: any = reader.result;
      let y: any = x.split(',');
      let sourceByteArray = y[1];
      this.payload.fileBytes = sourceByteArray;
    };
    reader.readAsDataURL(file);
  }
  get f() {
    return this.resourceform.controls;
  }

  // setResource_avalability(data):FormArray{
  //   // const formArray = this.resourceform.get('resource_availability') as FormArray;
  //   // let resourceAvailability = this.resourceobj.resourceAvailability;
  //   // console.log(resourceAvailability);
  //   // for(let i of resourceAvailability)
  //   // {
  //   //   console.log(i);
  //   //   formArray.push(
  //   //     this.fb.group({
  //   //       date_picker1:i.resourceAvailabilityDate,
  //   //     })
  //   //   )
  //   // }

  // }

  // reminder() {
  //   return this.resourceform.get('resource_availability') as FormArray;
  // }

  add() {
    const array = this.reminder(); // Get the FormArray

    // Create a temporary FormGroup to hold the new entry
    const newEntry = this.fb.group({
      date_picker1: ['', Validators.required],
      time1: ['', Validators.required],
    });

    // Add value change listeners to check for duplicates immediately
    // newEntry.get('date_picker1')?.valueChanges.subscribe(() => {
    //   this.checkForDuplicates(newEntry, array);
    // });

    // newEntry.get('time1')?.valueChanges.subscribe(() => {
    //   this.checkForDuplicates(newEntry, array);
    // });

    // Push the new entry to the FormArray if valid
    if (array.length < 5) {
      array.push(newEntry);
    } else {
      alert('Allowed only five Slots');
    }
    // this.checkForDuplicates(newEntry,array)
  }

  // onDateChange(index: number) {
  //   const array = this.reminder(); // Get the FormArray
  //   const formGroup = array.at(index) as FormGroup;
  //   this.checkForDuplicates(formGroup, array);
  // }

  // onTimeChange(index: number) {
  //   const array = this.reminder(); // Get the FormArray
  //   const formGroup = array.at(index) as FormGroup;
  //   this.checkForDuplicates(formGroup, array);
  // }

  // checkForDuplicates(newEntry: FormGroup, array: FormArray) {
  // const newDate = newEntry.get('date_picker1')?.value;
  // const newTime = newEntry.get('time1')?.value;

  // if (!newDate || !newTime) {
  //   return;
  // }

  // // Check for duplicates
  // const isDuplicate = array.controls.some((group: FormGroup) => {
  //   const existingDate = group.get('date_picker1')?.value;
  //   const existingTime = group.get('time1')?.value;

  //   // Check if the date is the same and the time is duplicated
  //   return existingDate === newDate && existingTime === newTime;
  // });

  // if (isDuplicate) {
  //   alert('This time already exists for the selected date. Please choose a different time slot.');
  //   newEntry.get('time1')?.setValue(null, { emitEvent: false });
  // }

// valueChanges(){
//   const array = this.reminder(); 
//   const newEntry = this.fb.group({
//     date_picker1: ['', Validators.required],
//     time1: ['', Validators.required],
//   });

//   console.log('newEntry',newEntry.get('time1').value);
  
//   if (newEntry.get('time1').value) {
//     this.checkForDuplicates(newEntry, array);
//   }
// }

  // timeSetter() {
  //   const array = this.reminder();
  //   const newEntry = this.fb.group({
  //     date_picker1: ['', Validators.required],
  //     time1: ['', Validators.required],
  //   });

  //   console.log('newEntry',newEntry.controls['time1'].value);
    

  //   // if (newEntry.controls['time1'].value) {/
  //     this.checkForDuplicates(newEntry, array);
  //   // }
  //   // this.checkForDuplicates(newEntry, array);
  // }
  timeSetter() {
    const array = this.reminder();
    const newEntry = this.fb.group({
        date_picker1: ['', Validators.required],
        time1: ['', Validators.required],
    });

    // Check for duplicates when time changes
    this.checkForDuplicates(newEntry, array);
}

valueChanges() {
    const array = this.reminder();
    const newEntry = this.fb.group({
        date_picker1: ['', Validators.required],
        time1: ['', Validators.required],
    });

    // Check for duplicates when value changes
    this.checkForDuplicates(newEntry, array);
}

  checkForDuplicates(newEntry: FormGroup, array: FormArray) {
    const control = this.resourceform.get('resource_availability') as FormArray;

    if (!control) {
      // console.error('FormArray not found');
      return;
    }

    // Create a map to track selected times for each date
    const dateTimeMap = new Map<string, Set<string>>();

    // Iterate over the controls to populate the map
    control.controls.forEach((row, index) => {
      const startDate = row.get('date_picker1')?.value;
      const time = row.get('time1')?.value;
      console.log(time);
      
    
      // Transform date and time to a standard format
      const formattedDate = this.datepipe.transform(startDate, 'yyyy-MM-dd');
      const formattedTime = this.datepipe.transform(time, 'HH:mm:ss');
    
      if (formattedTime) {
      
        for (let i = 0; i < control.controls.length; i++) {
          if (i !== index) { 
            const otherTime = control.controls[i].get('time1')?.value;
            const formattedOtherTime = this.datepipe.transform(otherTime, 'HH:mm:ss');
    
            if (formattedTime === formattedOtherTime) {
              Swal.fire({
                icon: 'error',
                text: 'Duplicated Time Not Acceptable for Same Date and Time and Also Should Not Repeated Any Duplicated Time',
                
              });
              control.controls.forEach((row)=>{
                row.get('time1').setValue(null);
                // row.get('time1').patchValue('');
              })

              return; // Stop checking further if a duplicate is found
            }
          }
        }
      }
  
    const newDate = newEntry.get('date_picker1')?.value;
    const newTime = newEntry.get('time1')?.value;

    const formattedNewDate = this.datepipe.transform(newDate, 'yyyy-MM-dd');
    const formattedNewTime = this.datepipe.transform(newTime, 'HH:mm:ss');

    if (!formattedNewDate || !formattedNewTime) {
      // console.error('New date or time formatting failed');
      return;
    }

    // Debugging output
    console.log(
      `Checking new entry: Date=${formattedNewDate}, Time=${formattedNewTime}`
    );

    // if (this.employeeIds.length != 0) {

    // }

    if (dateTimeMap.has(formattedNewDate)) {
      const times = dateTimeMap.get(formattedNewDate)!;

      // Check if the time is already selected for this date
      if (times.has(formattedNewTime)) {
        console.log('Duplicate found');
        // Swal.fire({
        //   icon: 'error',
        //   text: 'Same time selected for the same date multiple times.',
        // });
        return;
      } else {
        console.log('Time not duplicated');
      }
    } else {
      console.log('Date not found in existing entries');
    }

    // Proceed with patching the field or other logic if no duplicates
    // Example: this.someService.patchData(newEntry.value);
    console.log('No duplicates found, proceed with patching.');
  }
  )}


  get resourceAvailability(): FormArray {
    return this.resourceform.get('resource_availability') as FormArray;
  }
  //////get values from form //////
  get name() {
    return this.resourceform.get('name');
  }
  get position() {
    return this.resourceform.get('position');
  }
  get interview_date() {
    return this.resourceform.get('interview_date');
  }
  get expectedjoining_date() {
    return this.resourceform.get('expectedjoining_date');
  }
  get total_experience() {
    return this.resourceform.get('total_experience');
  }
  get relevent_experience() {
    return this.resourceform.get('relevent_experience');
  }
  get notice_period() {
    return this.resourceform.get('notice_period');
  }
  get current_CTC() {
    return this.resourceform.get('current_CTC');
  }
  get expected_CTC() {
    return this.resourceform.get('expected_CTC');
  }
  get job_location() {
    return this.resourceform.get('job_location');
  }
  get Current_location() {
    return this.resourceform.get('Current_location');
  }
  // get resource_availability()
  // {
  //   return this.resourceform.get('resource_availability');
  // }
  get interviewed() {
    return this.resourceform.get('interviewed');
  }
  get interviewStatus() {
    return this.resourceform.get('interviewStatus');
  }
  get comments() {
    return this.resourceform.get('comments');
  }
  get resourcecvattachment() {
    return this.resourceform.get('resourcecvattachment');
  }

  get resource_availability() {
    return this.resourceform.get('resource_availability');
  }

  // get dateOfJoining()
  // {
  //   return this.resourceform.get('dateOfJoining');
  // }

  get date_picker1() {
    return this.resourceform.get('resource_availability').get('date_picker1');
  }

  get requirement() {
    return this.resourceform.get('requirement');
  }
  get relocate() {
    return this.resourceform.get('relocate');
  }

  reminder(): FormArray {
    return this.resourceform.get('resource_availability') as FormArray;
  }
  keyUpmaxexp(event) {
    let relevent = +this.resourceform.value.relevent_experience;
    let total = +this.resourceform.value.total_experience;
    console.log('expected: ' + relevent);
    console.log('current: ' + total);

    if (
      this.resourceform.controls.relevent_experience.invalid ||
      this.resourceform.controls.total_experience.invalid
    ) {
      this.maxExp = true;
    } else {
      if (relevent <= total) {
        console.log('Valid experience input');
        this.maxExp = true;
      } else {
        console.log('Invalid experience input');
        this.maxExp = false;
      }
    }
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  noNumber(event: any) {
    // const inputValue = event.target.value;

    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  CurrentLKeyUp(event: any) {
    this.currentLenteredText = event.target.value;
    // Perform your logic with the entered text
    console.log('Entered job text:', this.jobLenteredText);
    console.log('Entered current text:', this.currentLenteredText);
    if (
      this.jobLenteredText != undefined &&
      this.currentLenteredText != undefined
    ) {
      this.relocatestatus =
        this.jobLenteredText.toLowerCase() ===
        this.currentLenteredText.toLowerCase();
      if (this.relocatestatus == false) {
        this.relocatestatus = true;
        if (this.relocatestatus == true) {
          this.resourceform.get('relocate').setValidators(Validators.required);
        }
      } else {
        this.relocatestatus = false;
        if (this.relocatestatus == false) {
          this.resourceform.controls['relocate'].setErrors(null);
          this.resourceform.value.relocate = false;
        }
      }

      console.log('relocatestatus', this.relocatestatus);
    }
  }

  jobLKeyUp(event: any) {
    this.jobLenteredText = event.target.value;
    // Perform your logic with the entered text
    console.log('Entered job text:', this.jobLenteredText);
    console.log('Entered current text:', this.currentLenteredText);
    if (
      this.jobLenteredText != undefined &&
      this.currentLenteredText != undefined
    ) {
      this.relocatestatus =
        this.jobLenteredText.toLowerCase() ===
        this.currentLenteredText.toLowerCase();
      if (this.relocatestatus == false) {
        this.relocatestatus = true;
        if (this.relocatestatus == true) {
          this.resourceform.get('relocate').setValidators(Validators.required);
        }
      } else {
        this.relocatestatus = false;
        if (this.relocatestatus == false) {
          this.resourceform.controls['relocate'].setErrors(null);
        }
      }
    }
  }
}
