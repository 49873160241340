import { Component, Input, OnInit, ViewChild } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { AdminService } from 'src/app/providers/admin.service';
import { UserService } from 'src/app/providers/user.service';
// import { base_url} from '../../providers/properties';
import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectedComponent } from '../selected/selected.component';
import { DashboardDialogboxComponent } from '../dashboard-dialogbox/dashboard-dialogbox.component';

@Component({
  selector: 'app-recruiter-dashboard',
  templateUrl: './recruiter-dashboard.component.html',
  styleUrls: ['./recruiter-dashboard.component.scss']
})
export class RecruiterDashboardComponent implements OnInit
{
  recruiterdashboard ;
  openPositions = 0;
  ProfileShared= 0;
  Interviewed= 0;
  Notinterviewed= 0;
 user: any;
 employeeId: any;
 selectedStatus=28;
 rejectedStatus=29;
 onHoldStatus=30;
 loader:any= false;
  orgId: any;
  backdrop:any= false;
setShowData: boolean;


 constructor(private dashboardservice : HrmsserviceService, public dialog: MatDialog) { }
 @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

 ///////// pie Chart //////////
 public pieChartOptions_user: ChartConfiguration['options'] = {
   responsive: true,
   plugins: {
     legend: {
       display: true,
       position: 'top',
     labels: {
       color: 'black'
     }
     },
     datalabels: {
       color:'white',
       formatter: (value, ctx) => {
         if (ctx.chart.data.labels) {
           return ctx.chart.data.labels[ctx.dataIndex];
         }
       },        
       font: {
         // weight: 'bold',
         size: 13,         
       },
     },
   }
 };
 public pieChartData_user: ChartData<'pie', number[], string | string[]> = {
   labels:[['Open Positions'],['Profile Shared'],['Interviewed'],['Not interviewed']],
   datasets: [ {
     data: [this.openPositions, this.ProfileShared, this.Interviewed, this.Notinterviewed],
     backgroundColor: ['#446C8C','#74C458','#9B5EEB','#FF6666'],
     hoverBackgroundColor: ['#446C8C','#74C458','#9B5EEB','#FF6666'],
     hoverBorderColor:['#446C8C','#74C458','#9B5EEB','#FF6666']

   } ],
 };
 public pieChartType_user: ChartType = 'pie';
 public pieChartPlugins_user = [ DatalabelsPlugin ];
 ngOnInit(): void {
this.user = JSON.parse(localStorage.getItem('enoteUserData'));
console.log(this.user.userId);

this.orgId = this.user.organization.organizationId

// this.employeeId = this.user.employeeId;
// console.log(this.employeeId);

   this.recruiterdashboard = {
       "rejected": 0,
       "notInterviewed":0,
       "selected": 0,
       "onHold": 0,
       "profilesource": 0,
       "openPosition": 0,
       "ProfileShared" :0,
       "interviewed" : 0
   }
   this.loader=true;
   this.setShowData=false;
   this.dashboardservice.recruiterdashboardorg(this.user.userId, this.orgId) .subscribe((recruiterdashboard:any) =>
   {
     this.loader=false;
       this.recruiterdashboard = recruiterdashboard;
       this.setShowData=true

       console.log(" this.recruiterdashboard " + JSON.stringify( this.recruiterdashboard));
       console.log(" this.recruiterdashboard.openPositio" + this.recruiterdashboard.openPosition);
       this.openPositions = this.recruiterdashboard.openPosition;
       this.ProfileShared  = this.recruiterdashboard.profilesource;
       this.Interviewed = this.recruiterdashboard.interviewed;
       this.Notinterviewed = this.recruiterdashboard.notInterviewed;


       this.pieChartData_user  = {
         labels:[['Open Positions'],['Profile Shared'],['Interviewed'],['Not interviewed']],
         datasets: [ {
           data: [this.openPositions, this.ProfileShared, this.Interviewed, this.Notinterviewed],
           backgroundColor: ['#446C8C','#74C458','#9B5EEB','#FF6666'],
           hoverBackgroundColor: ['#446C8C','#74C458','#9B5EEB','#FF6666'],
           hoverBorderColor:['#446C8C','#74C458','#9B5EEB','#FF6666'],
         } ],
       };
   }
   ) 

 }


 resources(id,statuscount,statusName:string)
 {
   console.log(id);
   console.log(statuscount);
   if(statuscount != 0)
   {
     let data={        
       createdBy: this.user.userId,
       interviewstatus:id,
       titleName: statusName  // Set the title name dynamically


      }
      console.log(data);
       this.dialog.open(SelectedComponent, {
         width:'70%',
         // height:'fit-content',
         height:'70%',
         data:data
       });
   }
 }

 openpos(openPosition)
 {
   console.log("openPosition"+openPosition);
   if(openPosition != 0)
   {
     console.log("openPosition1"+openPosition);
     let resdata={        
       // status: 1
        positionstatus:true,
        titleName: 'Open Positions '

      }
      console.log(resdata);
       this.dialog.open(DashboardDialogboxComponent, {
         width:'70%',
         height:'70%',
         data:resdata
       });
   }
 }

 sharedpro(profilesource)
 {
   console.log("profilesource"+profilesource);
   if( profilesource!= 0)
   {
     console.log("profilesource1"+profilesource);
     let resdata={        
       recruiter:this.user.userId,
       status: 1,
       titleName: 'Profile Shared'

       // interviewed:false
      }
      console.log(resdata);
       this.dialog.open(DashboardDialogboxComponent, {
         width:'70%',
         height:'70%',
         data:resdata
       });
   }
 }

 interview(interviewed)
 {
   console.log("interviewed"+interviewed);
   if(interviewed!= 0)
   {
     console.log("interviewed"+interviewed);
     let resdata={        
       recruiter:this.user.userId,
       interviewed:true,
       titleName: 'Interviewd'

      }
      console.log(resdata);
       this.dialog.open(DashboardDialogboxComponent, {
         width:'70%',
         height:'70%',
         data:resdata
       });
   }
 }
 notinterview(notInterviewed)
 {
   console.log("notInterviewed"+notInterviewed);
   if( notInterviewed!= 0)
   {
     console.log("profilesource1"+notInterviewed);
     let resdata={        
       // status: 1
       recruiter:this.user.userId,
       interviewed:false,
       titleName: 'Not Interviewd'

      }
      console.log(resdata);
       this.dialog.open(DashboardDialogboxComponent, {
         width:'70%',
         height:'70%',
         data:resdata
       });
   }
 }  
}
