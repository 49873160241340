<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Revenue Adjustment</span>
                </div>
                <div class="sub-header-buttons">
                    <a (click)="importrevenueadjustment()" mat-raised-button class="ml-2 mr-2" color="primary" title="Import">
                        Import
                      </a>
                    <!-- <a routerLink="/home/" mat-raised-button title="Back" class="">Back</a> -->
                    <a routerLink="/home/client-employee-revenue-adjustment" routerLinkActive="active" color="primary" mat-raised-button title="Add Employee" class="ml-2">
                        Add Revenue Adjustment
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Client Name<span class="star">*</span></label> 
                            <select formControlName="client"  class="form-select form-select-solid form-select-lg select2-new" (change)="getclientEmployees()">
                                <option [value]="null"> --- Select Client --- </option>
                                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                            </select>    
                            <!-- <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                - <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  
                            </div> -->
                        </div>
                        <div class="form-group col-md-3">
                            <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                search
                            </mat-icon> -->
                            <label class="col-form-label">Employee<span class="star">*</span></label>
                            <input type="text"matInput  placeholder="Search Employee"   
                                        formControlName="employeecode"
                                        [matAutocomplete]="auto" [readonly]="!filterForm.value.client || filterForm.value.client == 'null'"
                                        class="form-control" (keyup)="empSearch($event)">
                                <mat-autocomplete #auto="matAutocomplete" >
                                    <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                        </mat-option> -->
                                    <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                    {{emp.employeeNumber}} - {{emp.firstName}}  
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                    search
                                </mat-icon>
                            <!-- <div class="valid-msg" *ngIf="submitted">        
                                <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                            </div> -->
                        </div>

                        
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Financial year</label> 
                            <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                <option [value]="null"> -- Select Financial Year --- </option>
                                <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                            </select>
                        </div>
                        <div class="form-group col-md-3 pl-1">
                            <label class="col-form-label">Financial Period</label> 
                            <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                <option [value]="null"> -- Select Period --- </option>
                                <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3">
                            <select formControlName="element" class="form-select form-select-solid form-select-lg select2-new" >
                                <option [value]="null"> -- Select Element Name --- </option>
                                <option *ngFor="let row of categoryNameList; let i=index;" value="{{row.elementId}}" >{{row.elementName}}</option> 
                            </select>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" mat-raised-button color="primary"
                                class="btn btn-primary search-btn">Search</button>
                                <button style="margin-left: 10px;  height: 35px;" type="reset" mat-raised-button
                                    class="btn-clear  search-btn mr-2"
                                (click)="clearTable()">Clear</button>
                        </div>
                    </div>
                </form>
                <div class="row pb-3">
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem" (change)="pageChages($event)" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div>
                       
                <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <p><b>REVENUE ADJUSTMENT</b></p>
                    <table class="table  table-responsive2 --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <th>Action</th>
                                <th>Financial Year</th>
                                <th>Period</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Element Category</th>
                                <th>Element Name</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                            <tbody *ngIf="resultData.length>0">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td class="">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    
                                    <mat-menu #menu="matMenu"  class="controls-list">
                                        <button mat-menu-item routerLink="/home/client-employee-revenue-adjustment/{{row.revenueAdjusmentId}}">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                    </mat-menu>
                                </td>

                                <td>{{row.financialPeriod.finanicalYear.financialFromMonth}}
                                    -{{row.financialPeriod.finanicalYear.financialFromYear}} to
                                        {{row.financialPeriod.finanicalYear.financialToMonth}} 
                                        {{row.financialPeriod.finanicalYear.financialToYear}}
                                </td>
                                <td>{{row.financialPeriod.financialperiodMonth}} -
                                    {{row.financialPeriod.financialPeriodYear}}</td>
                                <td>{{row.employee.employeeNumber}}</td>
                                <td>{{row.employee.firstName}}</td>
                                <td>{{categoryName}}</td>
                                <td>{{row.hrmspaySlipElement.elementName}}</td>
                                <td>{{row.amount}}</td>
                            </tr>
                        </tbody>  
                        <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="8"  *ngIf="noRecordsFound">No Records Found!</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record" *ngIf="!this.noRecordsFound">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"  *ngIf="!this.noRecordsFound"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>