import { Component, Inject, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuperadminService } from '../../../providers/superadmin.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.scss']
})
export class AddModuleComponent implements OnInit
{

  @ViewChild('select') select: MatSelect;

  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  moduleCodeExistStatus:any;
  sucess_msg:any;
  error_msg:any;
  moduleId:any;
  arrayData:any = [];
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  allSelected=false;
  moduleNameExistStatus :any =false;
  updatemoduleNameExistStatus :any =false;
  activeMenusData :any;
  employeetData:any;
  constructor(
    private superadminservice: SuperadminService,
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {

      this.postForm = this.formBuilder.group({
      menuId : ['',[Validators.required]],
      moduleName : ['',[Validators.required]],
      moduleCode : ['',[Validators.required,Validators.minLength(3)]],
      moduleDescription : [''],
    });

    if(this.edit_data)
    {
      this.moduleId = this.edit_data.moduleId;
      this.superadminservice.getModuleById(this.edit_data.moduleId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          moduleCode: this.resultData.moduleCode,
          moduleName: this.resultData.moduleName,
          moduleDescription: this.resultData.moduleDescription,
          menuId:this.resultData.menu.map(
            d=> {return d.menuId}
          ),
        });
      });
    }

    this.getActivemenus();

  }

  get validate() { return this.postForm.controls; }

  onSubmit() //form Submit Function
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData?.organization?.organizationId;
    console.log('organizationId'+organizationId)
    this.submitted = true;
    if (this.postForm.invalid || this.moduleNameExistStatus == true ||this.moduleCodeExistStatus == true) {
          console.log("this.postForm.invalid :" + this.postForm.invalid);
        

           const controls = this.postForm.controls;
      console.log(controls);
      for (const name in controls) {
          if (controls[name].invalid) {
             console.log(name);
          }
      } 
      return;
    
    }

    if(this.edit_data) //Edit
    {
      let x = this.postForm.value;
      x.menuId.map(
        e => {
          let data;
          if (e) {
            data = {
                menuId: e
            }
          }
          this.arrayData.push(data)
        }
      )

      let postValues = this.postForm.value;

      //postValues['organization'] = {organizationId: organizationId}
      postValues['moduleId'] = this.edit_data.moduleId;
      postValues['status'] = this.edit_data.status;
      postValues['menu'] =  this.arrayData;

      this.backdrop = true;
      this.loader=true;

      /* if(this.postForm.valid && this.updateroleNameExistStatus == true)
      {
         return
      } */

      this.superadminservice.editModule(this.edit_data.moduleId,postValues)
      .subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/designation' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    else //Create
    {
      let x = this.postForm.value;
      x.menuId.map(
        e => {
          let data;
          if (e) {
            data = {
                menuId: e
            }
          }
          this.arrayData.push(data)
        }
      )

      let postValues = this.postForm.value;

      this.superadminservice.createModule(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
  }

  checkExistModuleName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */
    if(this.postForm.value.moduleName.length != 0)
    {
      let postValues = this.postForm.value;
      //postValues['organization'] = {organizationId:organizationId}

      if(this.edit_data)
      {
        postValues['moduleId'] = this.edit_data.moduleId;

        this.superadminservice.updateCheckExistModuleName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.moduleNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.moduleNameExistStatus = true;
          }
        })
      }
      else
      {
        this.superadminservice.createCheckExistModuleName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.moduleNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.moduleNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.moduleNameExistStatus = false;
    }
  }
  checkExistModuleCode(val)
  {
   let editData = 0;
    if(this.postForm.value.moduleCode.length != 0)
    {
      let postValues = this.postForm.value;
      if(this.edit_data)
      {
        postValues['moduleId'] = this.edit_data.moduleId;

        this.superadminservice.updateCheckExistModuleCode(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.moduleCodeExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.moduleCodeExistStatus = true;
          }
        })
      }
      else
      {
        this.superadminservice.createCheckExistModuleCode(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.moduleCodeExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.moduleCodeExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.moduleCodeExistStatus = false;
    }
  }


  toggleAllSelection()
  {
    if (this.allSelected)
    {
      this.select.options.forEach((item: MatOption) => item.select());
    }
    else
    {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick()
  {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) =>
    {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  add()
  {
    let x = this.postForm.value;
    if(x.menuId == '')
    {
      alert("Please select the options")
    }
    else
    {
      this.select.close()
    }
  }

  cancel()
  {
    this.postForm.patchValue({menuId:''})
    this.select.close()
  }


  getActivemenus()
  {
    this.settingsservice.getActivemenus().subscribe((result: any) => {
      this.activeMenusData = result;
    })
  }

}
