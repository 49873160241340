import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Calendar } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import * as XLSX from 'xlsx';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
 import dayGridPlugin from '@fullcalendar/daygrid';
import { PayrollService } from 'src/app/providers/payroll.service';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';


@Component({
  selector: 'app-shifthoildays-exceptions',
  templateUrl: './shifthoildays-exceptions.component.html',
  styleUrls: ['./shifthoildays-exceptions.component.scss']
})
export class ShifthoildaysExceptionsComponent implements OnInit {

  isShow: boolean
  data = [];
  myFilter: any
  calendarOptions: CalendarOptions
  leaveBalanceCount: any;
  calendarVisible = true;
  checked: boolean;
  branchId: any
  calendarId: any
  employeeId: any
  holidayType: any = []
  exceptionType: any = []
  calendarList: any = []
  calendarList1: any = []
  myArray: any = []
  myArray1: any = []
  arrayData: any = []
  userType: any;
  calendarName: any;
  workArray: any = []
  workingDays: any = []
  excel:any = [];
  exceptionFormat:any = [];
  startYear:any;
  endYear:any;
  fileName: any = "No file choosen...";
  expand: Boolean;
  selected = '2023';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  yearbyyearname: any;
  LeaveReport: any=[];
  Leavecurrentdate: any;
  Holidaycurrentdate: any;
  upComingHolidaydata: any;
  upcomingLeavedata: any;
  upcomingException:any;
  currentyear: number;
  totaldays: any[];
  exceptioncurrentdate: string;
  shiftcalendarId: any;

  
  constructor(private breakpointObserver: BreakpointObserver , 
    private payrollservice : PayrollService ,
    private dialog : MatDialog,
    private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {


    this.calendarOptions = {
     
      droppable: true,
      // weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      themeSystem: "bootstrap4",
      // themeName: 'Materia',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth'
      },
      initialView: 'dayGridMonth',
      validRange: {
        start: '2023-01-01',
        end: '2023-12-31'
      },
      displayEventTime: false,
      // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
      select: this.addEvent.bind(this),
      eventClick: this.updateEvent.bind(this),
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
      ],
    };

    this.shiftcalendarId = this.activatedRoute.snapshot.params.shiftcalendarId;
    

    this.payrollservice.getshiftcalendarbyId(this.shiftcalendarId).subscribe((resultData:any) =>
    {
       this.calendarName = resultData.shiftCalendarName;
       var event = [];
       resultData.holiday.forEach((e) => {
        // if(e.holidayStatus == 1){
        let MyDate = new Date(e.holidayDate)
        let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
        if (e.holidayStatus == 1) {
          event.push(
            {
              id: e.holidayId,
              title: e.holidayName,
              start: MyDateString,
              groupId: Number(e.holidaytype),
              color: '#00BB1E',
            })
        } 
        if (e.holidayStatus == 2) {
          event.push(
            {
              id: e.holidayId,
              title: e.holidayName,
              start: MyDateString,
              groupId: Number(e.holidaytype),
              color: '#00bb1e6b',
            })
        }

        this.calendarOptions.events = event;
        console.log("holiday",this.calendarOptions.events);
        this.arrayData = this.calendarOptions.events;
        this.dateClass()
        // }

      })

      resultData.exceptions.forEach((e) => {
        let MyDate = new Date(e.exceptionDate)
        let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
        if (e.exceptionStatus == 1) {
          event.push(
            {
              id: e.exceptionId,
              title: e.exceptionName,
              start: MyDateString,
              groupId: Number(e.exceptionType),
              color: '#40E0D0',
            })
        }
        if (e.exceptionStatus == 2) {
          event.push(
            {
              id: e.exceptionId,
              title: e.exceptionName,
              start: MyDateString,
              groupId: Number(e.exceptionType),
              color: '#40e0d070',
            })
        }

        this.calendarOptions.events = event;
        this.arrayData = this.calendarOptions.events;
        console.log("Exception",this.calendarOptions.events);
        this.dateClass();
      })
  });


 

    
  }

  exportAsXLSX(): void {
    this.payrollservice.exportAsExcelFile(this.excel, 'holiday');
  }

  exportAsExceptionXLSX(): void {
    this.payrollservice.exportAsExcelFile(this.exceptionFormat, 'exception');
  }

  MatDrawControl(){
    this.expand = !this.expand
    console.log(this.expand);
  }

   // ************ Add Event ************
   addEvent(selectInfo: DateSelectArg) {
    let data = {
      start: selectInfo.start,
      groupId: this.shiftcalendarId
    }
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
    const dialogRef = this.dialog.open(AddHoliday, {
      width: '400px',
      data: data
    })
  }

  // *********** Update Event ***********
  updateEvent(clickInfo) {
    console.log(clickInfo.event.groupId)
    this.payrollservice.getListTypeById(14).subscribe(
      (d: any) => {
        d.map(
          e => {
            if (clickInfo.event.groupId == e.listTypeValueId) {
              let eventData;
              eventData = {
                id: clickInfo.event.id,
                title: clickInfo.event.title,
                start: clickInfo.event.start,
                groupId: this.shiftcalendarId,
                holidayType: clickInfo.event.groupId,
              }

              const dialogRef = this.dialog.open(AddHoliday, {
                width: '400px',
                data: eventData
              })

            }
          }
        )

      }
    )

    this.payrollservice.getListTypeById(11).subscribe(
      (d: any) => {
        d.map(
          e => {
            if (clickInfo.event.groupId == e.listTypeValueId) {
              let eventData;
              eventData = {
                id: clickInfo.event.id,
                title: clickInfo.event.title,
                start: clickInfo.event.start,
                groupId: this.shiftcalendarId,
                holidayType: clickInfo.event.groupId,
              }

              const dialogRef = this.dialog.open(ExceptionHoliday, {
                width: '400px',
                data: eventData
              })

            }
          }
        )
      }
    )
    // if(clickInfo.event.groupId == '39' || clickInfo.event.groupId == '40'){
    //   console.log(clickInfo.event.groupId)
    //   let eventData;
    // eventData = {
    //   id: clickInfo.event.id,
    //   title: clickInfo.event.title,
    //   start: clickInfo.event.start,
    //   groupId: this.calendarId,
    //   holidayType: clickInfo.event.groupId,
    // }

    // const dialogRef = this.dialog.open(AddHoliday, {
    //   width: '400px',
    //   data: eventData
    // })
    // }

    // if (clickInfo.event.groupId == '41' || clickInfo.event.groupId == '42') {
    //   let eventData;
    //   eventData = {
    //     id: clickInfo.event.id,
    //     title: clickInfo.event.title,
    //     start: clickInfo.event.start,
    //     groupId: this.calendarId,
    //     holidayType: clickInfo.event.groupId,
    //   }

    //   const dialogRef = this.dialog.open(ExceptionHoliday, {
    //     width: '400px',
    //     data: eventData
    //   })
    // }

    
  }
  dateClass() {
    return (d: Date) => {
      const day = new Date(d).getDay();
      const time = new Date(d).getTime()
      return day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 5 ? 'special-date' : ''
    }
    // const datesToHighlight = ["2021-05-22T00:00:00.000Z", "2021-05-06T00:00:00.000Z"];

    // return  (date: Date): MatCalendarCellCssClasses => {
    //   const highlightDate = datesToHighlight
    //     .map(strDate => new Date(strDate))
    //     .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());

    //   return highlightDate ? 'special-date' : '';
    // }
  }
}


@Component({
  selector: 'AddHoliday',
  templateUrl: 'AddHoliday.html',
})
export class AddHoliday implements OnInit {

  holidayFrom: FormGroup;
  myFilter: any
  holidayType: any = [];
  exceptionType: any = [];
  holiday: boolean;
  exception: boolean;
  dateDisable: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private gs: PayrollService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public datepipe:DatePipe,
    public dialogRef: MatDialogRef<AddHoliday>
  ) { }

  ngOnInit() {
    this.holidayFrom = this.fb.group({
      holidayName: [null, Validators.required],
      holidayDate: this.data.start,
      holidayStatus: [null, Validators.required],
      holidaytype: [null, Validators.required],
      leaveType: [null, Validators.required],
      shiftCalender: {
        shiftCalendarId: Number(this.data.groupId)
      }
    })

    
    this.gs.getListTypeById(14).subscribe(
      (d: any) => {
        // alert(d.length);
        this.holidayType = d;
      }
    )

    this.gs.getListTypeById(11).subscribe(
      (d: any) => {
        console.log(d)
        this.exceptionType = d
        // d.map(
        //   e=>{
        //     if (e.listTypeValueName == "Leave") {
        //       this.exceptionType.push(e);
        //     }
        //   }
        // )
      }
    )

    if (this.data.id) {
      this.dateDisable = true
      this.gs.getHolidayById(this.data.id).subscribe(
        (d: any) => {
          this.holidayFrom.patchValue({
            holidayStatus: d.holidayStatus.toString()
          })
        }
      )
      this.holidayFrom.patchValue({
        holidayId: this.data.id,
        holidayName: this.data.title,
        holidayDate: this.data.start,
        holidaytype: this.data.holidayType,
        shiftCalender: {
          shiftCalendarId: Number(this.data.groupId)
        },
        leaveType: 0
      })
    }
    else {
      this.holidayFrom.patchValue({
        holidayStatus: 1
      })
    }
  }

  get f() {
    return this.holidayFrom.controls
  }

  holiClick(e) {
    
    if (e.target.value == 0) {
      this.holiday = true
      this.exception = false
    }
    if (e.target.value  == 1) {
      this.holiday = false
      this.exception = true
    }
  }

  delete(e) {
    this.gs.deleteHoliday(e.id).subscribe(
      (data: any) => {
        Swal.fire({
          text: data.description,
          icon: 'success'
        })
        window.location.reload()
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'

        })
      }
    )
  }

  onSubmit() {

    let x = this.holidayFrom.value;
    console.log(x);
    // alert(x);
    if (this.holidayFrom.invalid) {
      console.log(this.holidayFrom.controls);
      for (const name in this.holidayFrom.controls) {
        if (this.holidayFrom.controls[name].invalid) {
          console.log(name);
        }
      } 
      return
    }
    console.log("DATE");
    var date = new Date(x.holidayDate);
    console.log(x.holidayDate);
    // var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
    // new code
    var newdate=this.datepipe.transform(x.holidayDate, 'MM-dd-yyyy');
    console.log(newdate);
    x['holidayDate'] = newdate;

    console.log(JSON.stringify(x))
    if (this.data.id) {
      x['holidayId'] = this.data.id
      x['holidayStatus'] = Number(x.holidayStatus)
      x['holidaytype'] = Number(x.holidaytype)
      this.gs.UpdateHoliday(this.data.id, x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          setTimeout(() => {
             window.location.reload()
          }, 2000)

        },
        err => {
          Swal.fire({
            text: err.err.message,
            icon: 'error'
          })
          setTimeout(() => {
             window.location.reload()
          }, 2000)
        }
      )
    }
    else {
      if (x.leaveType == 0) {
        console.log(x.leaveType);
        x['holidayStatus'] = 1
        x['holidaytype'] = Number(x.holidaytype)
        this.gs.createHoliday(x).subscribe(
          (data: any) => {
            Swal.fire({
              text: data.description,
              icon: 'success'
            })
            this.dialogRef.close()
            setTimeout(() => {
               window.location.reload()
            }, 2000)
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.dialogRef.close()
          }
        )
      }
      if (x.leaveType == 1) {
        console.log(x.leaveType);
        let y = this.holidayFrom.value;
        y['exceptionName'] = y.holidayName;
        var dato = new Date(y.holidayDate);
        var newdato = (dato.getMonth() + 1) + '-' + dato.getDate() + '-' + dato.getFullYear();
        y['exceptionDate'] = newdato;
        y['exceptionStatus'] = 1;
        y['exceptionType'] = Number(y.holidaytype)
        y['shiftCalender'] = {
          shiftCalendarId: this.data.groupId
        }
        this.gs.createException(y).subscribe(
          (data: any) => {
            Swal.fire({
              text: data.description,
              icon: 'success'
            })
            this.dialogRef.close()
            setTimeout(() => {
                window.location.reload()
            }, 2000)
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.dialogRef.close()
          }
        )
      }
    }

  }
}

@Component({
  selector: 'ExceptionHoliday',
  templateUrl: 'ExceptionHoliday.html',
})
export class ExceptionHoliday implements OnInit {

  exceptionForm: FormGroup;
  exceptionType: any = []
  dateDisable: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private gs: PayrollService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddHoliday>
  ) { }

  ngOnInit() {
    this.exceptionForm = this.fb.group({
      exceptionName: [null, Validators.required],
      exceptionDate: [null, Validators.required],
      exceptionType: [null, Validators.required],
      shiftCalender: [null, Validators.required],
      exceptionStatus: [null, Validators.required]
    })

    this.gs.getListTypeById(6).subscribe(
      (d: any) => {
        this.exceptionType = d;
      }
    )

    if (this.data.id) {
      this.dateDisable = true
      this.gs.getExceptionById(this.data.id).subscribe(
        (d: any) => {
          this.exceptionForm.patchValue({
            exceptionStatus: d.exceptionStatus.toString()
          })
        }
      )
      this.exceptionForm.patchValue({
        exceptionId: this.data.id,
        exceptionName: this.data.title,
        exceptionDate: this.data.start,
        exceptionType: Number(this.data.holidayType),
        shiftCalender: {
          shiftCalendarId: Number(this.data.groupId)
        }
      })
    }
  }

  get f() {
    return this.exceptionForm.controls
  }



  onSubmit() {
    if (this.exceptionForm.invalid) {
      return
    }
    let x = this.exceptionForm.value
    var date = new Date(x.exceptionDate);
    var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
    x['exceptionDate'] = newdate;
    x['exceptionStatus'] = Number(x.exceptionStatus)

    if (this.data.id) {
      console.log(x)
      x['exceptionId'] = this.data.id
      this.gs.UpdateException(this.data.id, x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          this.dialogRef.close()
          setTimeout(() => {
            window.location.reload()
          }, 2000)

        },
        err => {
          Swal.fire({
            text: err.err.message,
            icon: 'error'
          })
          this.dialogRef.close()
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      )
    }
  }
}
