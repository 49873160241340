<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3> {{stateId ? "Update" : "Add"}} State</h3>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div formGroupName="country" class="form-group col-md-6">
                <label class="col-form-label">Country Name <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <select formControlName="countryId" id="countryId" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg">
                    <option value="">- Select -</option>
                    <option *ngFor="let row of countryData; let i=index;" value="{{row.countryId}}">{{row.countryName}}</option>
                </select>
                <div class="valid-msg" *ngIf="(validate.country.invalid && validate.country.touched) || validate.country.dirty || submitted">
                    <span *ngIf="validate.country.errors?.required" class="text-danger">Please select country.</span>
                </div>
            </div>

            <div class="form-group col-md-6">
                <label class="col-form-label">State Name <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentName($event)" -->
                <input type="text" (keyup)="checkExistStateName($event)" formControlName="stateName" id="stateName" placeholder="State Name" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="">
                <div class="valid-msg" *ngIf="(validate.stateName.invalid && validate.stateName.touched) || validate.stateName.dirty || submitted">
                    <span *ngIf="validate.stateName.errors?.required" class="text-danger">Please enter state name</span>
                    <span *ngIf="validate.stateName.errors?.pattern" class="text-danger">Please enter valid state name</span>
                    <span *ngIf="stateNameExistStatus" class="span-error"> State name already exist!</span>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="form-group col-md-6">
                <label class="col-form-label">State Code <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <input type="text" maxlength="3" formControlName="stateCode" id="stateCode" placeholder="State Code" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg" value="" autocomplete="off">
                <p class="text-muted">Ex : TN, KA..,</p>
                <div class="valid-msg" *ngIf="(validate.stateCode.invalid && validate.stateCode.touched) || validate.stateCode.dirty || submitted">
                    <span *ngIf="validate.stateCode.errors?.required" class="text-danger">Please enter state code</span>
                    <span *ngIf="validate.stateCode.errors?.pattern" class="text-danger">Please enter valid state code</span>
                    <!-- <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
                 -->
                </div>
            </div>
            <div class="form-group col-md-6">
                <label class="col-form-label">TIN No</label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <input type="text" maxlength="3" formControlName="stateTin" id="stateTin" placeholder="TIN No" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg" value="" autocomplete="off">
                <p class="text-muted">Ex : 31, 32..,</p>

                <!--   *ngIf="(validate.stateTin.invalid && validate.stateTin.touched) || validate.stateTin.dirty || submitted" -->
                <!-- <span *ngIf="validate.stateTin.errors?.required" class="text-danger">Please enter TIN No</span>
                    <span *ngIf="validate.stateTin.errors?.pattern" class="text-danger">Please enter valid TIN No</span> -->
                <!-- <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
               -->

            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <a routerLink="/home/state" routerLinkActive="active">Cancel</a> -->
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>
