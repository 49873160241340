import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';

@Component({
  selector: 'app-finanicalyeardetail',
  templateUrl: './finanicalyeardetail.component.html',
  styleUrls: ['./finanicalyeardetail.component.scss']
})
export class FinanicalyeardetailComponent implements OnInit {
  resultData: any = {};
  loader:boolean = false;
  constructor(
    private matDialog : MatDialog,
    private settingsservice: SettingsService,private router:Router,
    private dialogref : MatDialogRef<FinanicalyeardetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
   
    ) {


   }

  ngOnInit(): void {

    if(this.data)
    {
      this.loader=true;
      this.settingsservice.getFinancialYearId(this.data).subscribe((resultData:any) =>
      {
        this.loader  = false;
        this.resultData=resultData;
  
      
      });
    }
   
  }
  closedialog()
  {
     this.dialogref.close();
  }
}
