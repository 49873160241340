
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport data-color="red"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <div class="no-v-scroll">
      <!-- <mat-toolbar class="matTool p-0">
        <button type="button" mat-raised-button class="addButton" (click)="uploadfiles()">
          <span class="material-icons">file_upload</span>
          Holiday/Exception
        </button>
      </mat-toolbar> -->


      <div class="img">
        <mat-nav-list>
          <!-- <mat-card>
            <mat-calendar></mat-calendar>
          </mat-card> -->
          <button type="button" mat-raised-button class="addButton" (click)="uploadfiles()">
            <span class="material-icons">file_upload</span>
            Holiday/Exception
          </button>
          <button type="button" mat-raised-button class="addButton" (click)="createCalendar()">
            <span class="material-icons">add</span>
            Add Calendar
          </button>

          <mat-form-field class="full-width" apperance="outline" style="display: block;margin: 0px 15px;">
            <mat-label>Choose Year</mat-label>
            <mat-select   (selectionChange)="calendaryear($event)" [(value)]="selected">
              <mat-option *ngFor="let year of yearbyyearname" [value]="year.yearName">{{year.yearName}}</mat-option>
            </mat-select>
          </mat-form-field>
          
          <div class="img1">
            <div *ngFor="let cal of calendarList1">
              <a mat-list-item 
                [style.color]="cal.checked ? '#6699cc' : ''">
                <!-- <span class="material-icons">dashboard</span> -->
                <span class="text" [style.color]="cal.calendarStatus == 2 ? 'red' : ''"  (click)="changeCalendar(cal)"> {{cal.calendarName}}</span>
                <span class="material-icons" [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu">
                  <span [style.color]="cal.calendarStatus == 2 ? 'red' : ''">more_vert</span>
                </span>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="deactivateCalendar(cal)" *ngIf="cal.calendarStatus == 1">
                    <mat-icon>disabled_by_default</mat-icon>
                    <span>Deactivate</span>
                  </button>
  
                  <button mat-menu-item (click)="updateCalendar(cal)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </a>
            </div>
          </div>
       
          <hr style="width: 90%;">
        </mat-nav-list>
       
      </div>
      <div class="card" style="margin-left:1%; position: fixed; top: 76%; width: 12%; background: #F9FAFC; padding: 10px; border-radius: 8px; ">
        <p style="font-weight: bolder; font-family: Mulish; margin: 0 0 10px 0;">Hints <span class="star">*</span></p>
        <small style="display: block; margin-bottom: 5px;"><i class="fa fa-circle" style="color: green;" aria-hidden="true"></i> National Holiday</small>
        <small style="display: block; margin-bottom: 5px;"><i class="fa fa-circle" style="color: #FF9999;" aria-hidden="true"></i> Personal Leave</small>
        <small style="display: block; margin-bottom: 5px;"><i class="fa fa-circle" style="color: #40E0D0;" aria-hidden="true"></i> Exception</small>
      </div>
      
    </div>
   
  </mat-sidenav>
  <mat-sidenav-content>
      <section>
          <div class="sub-header" style="padding-left: 0px;">
              <div class="col-md-8 mb-1">
                  <div class="row">
                      <div class="col-sm-6">
                        <span class="h5 sub-content" style="font-size:x-large;top:8px">Calendar</span>
                      </div>
                      <div class="col-sm-5" style="position: relative;left: 50px;">
                        <div align="end"> 
                            <!-- <button (click)="exportAsXLSX()" class="  mt-1 mb-1 file-button"style="background-color: #1A83FF;color: white;border: solid 0px;padding: 8px; border-radius: 10px;">Download Holiday Excel</button>
                            <button (click)="exportAsExceptionXLSX()" class=" mt-1 ml-2 mb-1 file-button"style="background-color: #1A83FF;color: white;border: solid 0px;padding: 8px; border-radius: 10px;">Download Exception Excel</button> -->
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <div *ngIf="!loader">
        <div class="common-content">
          <mat-card class="large-calendar" style="margin:13px 13px 0px 13px;">
              <full-calendar #calendar 
                  #fullcalendar min="startYear" max="endYear"
                  *ngIf="calendarVisible"
                  [options]="calendarOptions"
                  plugins="calendarOptions.plugins"
              ></full-calendar> 
          </mat-card>
      </div>
      </div>
      
      <!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div> -->
      <div *ngIf="loader" class="align-items-center text-center">
        <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
      </div>
  </mat-sidenav-content>
</mat-sidenav-container>

