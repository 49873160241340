import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
// import { Reason } from '../../withdraw-list/withdraw-list.component';
// import { leavedenyreason } from '../../withdraw-list/withdraw-list.component';
// import { Console } from 'console';
import { LeaveService } from 'src/app/providers/leave.service';
import { ApproverStatusComponent} from '../approver-status/approver-status.component';
import { HeaderService } from 'src/app/providers/header.service';
import { LeavemanagementstatusComponent } from 'src/app/leavemanagementstatus/leavemanagementstatus.component';

@Component({
  selector: 'app-my-wfh-list',
  templateUrl: './my-wfh-list.component.html',
  styleUrls: ['./my-wfh-list.component.scss']
})
export class MyWFHListComponent implements OnInit {
  list_count = 0
  noData=false;
  data: any[] = []
  isLoading:Boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno','startDate', 'endDate', "noOfDays","reason","viewapprovers", "workFromHomeStatus", 'star',];
  // dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  changeasDenyReason: boolean=false;
  isMenuOpen: boolean = false;
  clickedbusinessTravelId: any;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  orgnaizationId: any;
  success: boolean = false;
  error: boolean = false;
  success_msg: string;
  error_msg: any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
list_counts:boolean = false;
  beforesearchpage: number;
  paginators: boolean = true;

  constructor(private gs: GlobalserviceService, 
    private router: Router, 
    public dialog: MatDialog,
    private renderer: Renderer2,
    private leaveservice: LeaveService,
    private headerservice: HeaderService
    ) {
  }
  ngOnInit() {
    // this.headerservice.setTitle('Work from Home List');
    this.headerservice.setTitle('');

    console.log("success");
    // let x = JSON.parse(localStorage.getItem("enoteUserData"))
    // let id = x.employee.employeeId;
    // this.orgnaizationId = x.organization.organizationId;
    // this.leaveservice.get_myWfh(id, this.orgnaizationId).subscribe((d: any) => {
    //   this.loader=false;
    //   if(d.length==0)
    //   {
    //     this.noData=true;
    //     console.log("noData");
    //   }
    //   if(d.statusCode==204)
    //   {
    //      this.noData=true;
    //      console.log("204");
    //   }
    //   this.data = d
    //   this.dataSource = new MatTableDataSource<any>(this.data);
    //   this.dataSource.paginator = this.paginator;
    //   this.list_count = this.data.length
    // },
    // (error) =>
    // {
    //   this.loader=false;
    //   this.noData= true;
    //   console.log("error");
    //   if(error.statusCode==204)
    //   {
    //      this.noData=true;
    //      console.log("204");
    //   }
    // }
    // )
    this.fetchwfh();
  }

  fetchwfh(){
    let x = JSON.parse(localStorage.getItem("enoteUserData"))
    let id = x.employee.employeeId;
    this.orgnaizationId = 1;
    this.loader=true;
    this.leaveservice.get_myWfh(id, this.orgnaizationId).subscribe((d: any) => {
      this.loader=false;
      this.list_counts=true;
      if(d.length==0)
      {
        this.noData=true;
        console.log("noData");
      }
      if(d.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }
  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(LeavemanagementstatusComponent,{
      width: '500px',
      height:'fit-content',
      data: {
        referredwith: "WFH",
        id: approvaldata.workFromHomeId
      }    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.noData = true;
    }
    else{
      this.noData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Reason(i) {
    console.log(i);
    // this.dialog.open(Reason, {
    //   data: i.reason
    // });
  }
  showdenyreason(i){
    console.log(i)
    let x = i;
    // this.dialog.open(leavedenyreason, {
    //   data: x
    // });
  }
  denyReason(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if(this.isMenuOpen==true){
      this.changeasDenyReason=true;
      console.log(this.changeasDenyReason);
    }
    else{
      this.changeasDenyReason=false;
      console.log(this.changeasDenyReason);
    }
  }
  cancel(e){
  
    Swal.fire({
      title: 'Are you sure? Do you want to Cancel Work From Home Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor:'#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if(result.value){
        // e['workFromHomeStatus'] = 8;
        // e.approvals['approverlevel'] = 1;
        // e.approvals.approvalStatus.listTypeValueId = 8 ;
        // e.approvals.approvalStatus.listTypeValueName = "Cancelled";
        // e.approvals['employeeId'] = e.employee.employeeId;
        this.loader = true;

        this.leaveservice.approvalsById("WFH",e.workFromHomeId).subscribe((data:any)=>{
          console.log(data)
          console.log(e)
          // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);
          this.loader = false;
                  e['workFromHomeStatus'] = 8;
                  e['approverlevel'] = 1;
                  e['approvals'] = data;
                  e.approvals.approverlevel=1;
                  e.approvals.approvalStatus.listTypeValueId = 8 ;
                e.approvals.approvalStatus.listTypeValueName = "Cancelled";
                 e['employeeId'] = e.employee.employeeId;
        this.loader = true;

        this.leaveservice.Actionwfh(e.workFromHomeId,e).subscribe(
          d=>{
            // Swal.fire({
            //   text:'Cancelled',
            //   icon:'success'
            // })
            this.success = true;
            this.success_msg = 'Work from Home List Cancelled Successfully';
            this.loader = false
            setTimeout(() => { this.success = false; }, 3000);     
            this.fetchwfh();       // window.location.reload()
          },
          err =>{
            // Swal.fire({
            //   text:err.error.message,
            //   icon:'error'
            // })
            this.error = true;
            this.error_msg = err.error.message;
            this.loader = false

            setTimeout(() => { this.error = false; }, 3000);     
                    },
                    () => {
                      this.loader = false
                    }      
        )

        },)
      
      }
    })
  }
  apply_wfh(){
    this.router.navigateByUrl("/home/apply-wfh");
  }
  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if(i == 'Cancelled'){
      return  '#e40000'
    }
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}


