import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { SettingsService } from 'src/app/providers/settings.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake  from "html-to-pdfmake";
import { DatePipe } from '@angular/common';
import { PayrollService } from '../providers/payroll.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-clientemployee-paylisp-list',
  templateUrl: './clientemployee-paylisp-list.component.html',
  styleUrls: ['./clientemployee-paylisp-list.component.scss']
})
export class ClientemployeePaylispListComponent implements OnInit {
  p: number = 1;
  pagePerItem:any=10;
  mySelect:any =5;
  filterForm:FormGroup;
  finYearList: any =[];
  finPeriodList: any=[];
  submitted: boolean=false;
  noRecordsFound: boolean =false;
  ManagePayslipdata: any=[];
  activeDesignationData: any=[];
  organzationId: any;
  userdata: any;
  financialyearError: boolean=false;
  organizationSettings: any;
  dateFormat : any;
  @ViewChild('pdfTable')pdfTable!: ElementRef;
  payrolldata: any;
  CTC=0;
  Amount=0;
  EarningYTD=0;
  DeductionYTD=0;
  empList:any=[];
  employee: any;
  employeeId: any;
  clientList:any;
  
  constructor(
    private settingsService: SettingsService,
    public fb:FormBuilder,
    private gs:GlobalserviceService,
    private Employeeservice: EmployeeService,
    @Inject(AppComponent) public AppData: any,
    private datepipe  : DatePipe,
    private payrollservice:PayrollService,
    public router:Router
  ) { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =  this.userdata.organization.organizationId;
    console.log("organziationid " + this.organzationId);
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;
    this.settingsService.getFinicalyearList(this.organzationId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
    this.Employeeservice.getActiveDesignation().subscribe((result: any) => {
      this.activeDesignationData = result;
      console.log(this.activeDesignationData);
    })
    this.gs.getOrganizationdetails(this.organzationId).subscribe(
      (data:any)=>{
        this.organizationSettings=data;
        console.log("this.organizationSettings",this.organizationSettings);
      }
    )
    this.filterForm=this.fb.group({
      employee : [null,Validators.required],
      client:[null,Validators.required],
      designation : [null],
      financialyear : [null],
      period: [null]
    });
    this.payrollservice.getClientNameList(this.organzationId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })
  }

  getclientEmployees(){
    this.empList.length = 0;
    console.log(this.filterForm.value.client);
    this.filterForm.value.employee = null;
    this.filterForm.patchValue({
      employee:null
    })
    if(this.filterForm.value.client != null && this.filterForm.value.client != "null"){
      // this.payrollservice.getemploye(this.filterForm.value.client).subscribe((ClientEmployees: any) => {
      //   if(ClientEmployees.length != undefined){
      //     ClientEmployees.forEach(element => {
      //       this.empList.push(element.employee);
      //     });
      //   }
      // });
    }
  }
  empSearch(event){ 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber){
      // this.payrollservice.getEmployeeNameList(employeenumber, this.orgId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }
      
      // })
      this.payrollservice.getClienEmployeeList( this.filterForm.value.employee , this.filterForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode){
        }else{
          this.empList = result;
        }
     })
    }
  }

  empSelect(emp){
    console.log(emp);
    console.log(this.employee);
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
      this.filterForm.patchValue({
        employee: this.employee 
      });
  }
 
  searchSubmit(){
    console.log(this.filterForm.value);
    if((this.filterForm.value.employee == null || this.filterForm.value.employee == '')){
      if(this.filterForm.value.client == null || this.filterForm.value.client == '' || this.filterForm.value.client == 'null'){
        alert("Please select client and employee");
        return;
      }
      else{
        alert("Please select employee");
        return;
      }
    }
    this.CTC=0;
    this.Amount=0;
    this.EarningYTD=0;
    this.DeductionYTD=0;
    if((this.filterForm.value.designation == null || this.filterForm.value.designation == 'null')){
      if((this.filterForm.value.period == null || this.filterForm.value.period == 'null')){
        if((this.filterForm.value.financialyear == null || this.filterForm.value.financialyear == 'null')){
          console.log("1");
          this.payrollservice.getemployeepayslipByClientandemployee(this.filterForm.value.client,this.employeeId).subscribe(
            (data:any)=>{
              console.log(data);
              if(data.statusCode == 204){
                this.ManagePayslipdata =[];
                this.noRecordsFound = true;
              }
              else{
                this.ManagePayslipdata=data;
                this.noRecordsFound = false;
                for(let j=0;j<this.ManagePayslipdata.length;j++){   
                  this.CTC  += this.ManagePayslipdata[j].ctc;
                  console.log(" this.CTC", this.CTC);
                  this.Amount  += this.ManagePayslipdata[j].amount;
                  this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                  this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
                }
              }
            }
          )
        }
        else{
          console.log("5");
          this.payrollservice.getemployeepayslipByClientandemployeeandFinancialyear(this.filterForm.value.client,this.employeeId,this.filterForm.value.financialyear).subscribe(
            (data:any)=>{
              console.log(data);
              if(data.statusCode == 204){
                this.ManagePayslipdata =[];
                this.noRecordsFound = true;
              }
              else{
                this.ManagePayslipdata=data;
                this.noRecordsFound = false;
                for(let j=0;j<this.ManagePayslipdata.length;j++){   
                  this.CTC  += this.ManagePayslipdata[j].ctc;
                  console.log(" this.CTC", this.CTC);
                  this.Amount  += this.ManagePayslipdata[j].amount;
                  this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                  this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
                }
              }
            }
          )
        }
      }
      else{
        console.log("6");  
        this.payrollservice.getemployeepayslipByClientandemployeeandPeriod(this.filterForm.value.client,this.employeeId,this.filterForm.value.period).subscribe(
          (data:any)=>{
            console.log(data);
            if(data.statusCode == 204){
              this.ManagePayslipdata =[];
              this.noRecordsFound = true;
            }
            else{
              this.ManagePayslipdata=data;
              this.noRecordsFound = false;
              for(let j=0;j<this.ManagePayslipdata.length;j++){   
                this.CTC  += this.ManagePayslipdata[j].ctc;
                console.log(" this.CTC", this.CTC);
                this.Amount  += this.ManagePayslipdata[j].amount;
                this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
              }
            }
          }
        )
      }
    }
    else{
      if((this.filterForm.value.period == null || this.filterForm.value.period == 'null')){
        if((this.filterForm.value.financialyear == null || this.filterForm.value.financialyear == 'null')){
          console.log("2");
          this.payrollservice.getemployeepayslipByClientandemployeeAndDesignation(this.filterForm.value.client,this.employeeId,this.filterForm.value.designation).subscribe(
            (data:any)=>{
              console.log(data);
              if(data.statusCode == 204){
                this.ManagePayslipdata =[];
                this.noRecordsFound = true;
              }
              else{
                this.ManagePayslipdata=data;
                this.noRecordsFound = false;
                for(let j=0;j<this.ManagePayslipdata.length;j++){   
                  this.CTC  += this.ManagePayslipdata[j].ctc;
                  console.log(" this.CTC", this.CTC);
                  this.Amount  += this.ManagePayslipdata[j].amount;
                  this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                  this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
                }
              }
            }
          )
        }
        else{
          console.log("3");
          this.payrollservice.getemployeepayslipByClientandemployeeAndDesignationandFinancialyear(this.filterForm.value.client,this.employeeId,this.filterForm.value.designation,this.filterForm.value.financialyear).subscribe(
            (data:any)=>{
              console.log(data);
              if(data.statusCode == 204){
                this.ManagePayslipdata =[];
                this.noRecordsFound = true;
              }
              else{
                this.ManagePayslipdata=data;
                this.noRecordsFound = false;
                for(let j=0;j<this.ManagePayslipdata.length;j++){   
                  this.CTC  += this.ManagePayslipdata[j].ctc;
                  console.log(" this.CTC", this.CTC);
                  this.Amount  += this.ManagePayslipdata[j].amount;
                  this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                  this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
                }
              }
            }
          )
        }
      }
      else{
        console.log("4");
        this.payrollservice.getemployeepayslipByClientandemployeeAndDesignationandPeriod(this.filterForm.value.client,this.employeeId,this.filterForm.value.designation,this.filterForm.value.period).subscribe(
          (data:any)=>{
            console.log(data);
            if(data.statusCode == 204){
              this.ManagePayslipdata =[];
              this.noRecordsFound = true;
            }
            else{
              this.ManagePayslipdata=data;
              this.noRecordsFound = false;
              for(let j=0;j<this.ManagePayslipdata.length;j++){   
                this.CTC  += this.ManagePayslipdata[j].ctc;
                console.log(" this.CTC", this.CTC);
                this.Amount  += this.ManagePayslipdata[j].amount;
                this.EarningYTD += this.ManagePayslipdata[j].earningYtd;
                this.DeductionYTD += this.ManagePayslipdata[j].deductionYtd;
              }
            }
          }
        )
      }
    }
  }

  get f() {
    return this.filterForm.controls;
  }
  selectFinancialyear(event){
    this.finPeriodList=[];
    console.log(event.target.value);
    this.filterForm.value.period = null;
    this.filterForm.patchValue({
      period:null
    })
    if(event.target.value != null){
      this.settingsService.getFinancialYearId(event.target.value).subscribe(
        (resultData: any) => {
          console.log("PeriodData", resultData);
          this.finPeriodList = resultData.finanicalPeriod;
          this.financialyearError=false;
        }
      );
    }
  }
  viewpayslip(payrollHeaderId){
    this.router.navigateByUrl(`/home/viewpayslip/${payrollHeaderId}`);
  }
  public downloadAsPDF(payrollHeaderId) {
    console.log("download",this.pdfTable);
  
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize:true,
    });
    this.gs.getManagePayslipById(payrollHeaderId).subscribe(
      (data:any)=>{
        console.log(data);
        this.payrolldata=data;
        const formattedDate = this.datepipe.transform(this.payrolldata.createDateTime, this.dateFormat);
        const documentDefinition = {
           content: html , 
           pageMargins: [ 40, 40, 40, 150],
            imagesByReference:true ,
           info: {
             title:`Report ${formattedDate}`
             },
    
             footer: function (currentPage, pageCount) {
              return {
                table: {
                  body: [
                    [ 
                      { 
                        text: 'Employee Signature', alignment: 'left', fontSize: 10,
                        margin: [40, 40, 0, 0]
                        // pageMargins: [50, 0, 0, 0]
                      },
                      { 
                        text: 'Date', alignment: 'right', fontSize: 10,
                        margin: [350, 40, 0, 0]
                        // pageMargins: [0, 0, 100, 0]
                      },
                    ],
                    [
                      // 'Approver Signature',
                      // { 
                      //   text: 'Date', alignment: 'right', fontSize: 9,
                      //   pageMargins: [700, 0, 300, 0]
                      // },
    
                      { 
                        text: 'Approver Signature', alignment: 'left', fontSize: 10,
                        margin: [40, 40, 0, 0]
                        // pageMargins: [50, 0, 0, 0]
                      },
                      { 
                        text: 'Date', alignment: 'right', fontSize: 10,
                        margin: [350, 40, 0, 0]
                        // pageMargins: [500, 0, 100, 0]
                      },
                    ],
                  ]
                },
                layout: {
                  defaultBorder: false,
                },
                // layout: 'noBorders'
              };
            },
            pageSize: 'A4',
            };
         console.log(documentDefinition);
        pdfMake.createPdf(documentDefinition).download(`Report ${formattedDate}.pdf`);     
      }
    )
  }
  showChanges(){
    console.log("show Change");
    this.p=1;
  }
}

