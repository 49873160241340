<nav class="navbar bg" style="margin-top: 67px">
  <div class="top-content row col-sm-12">
    <h4 class="heading-tag col-md-6 col-sm-12">Assign Employee Task</h4>

    <div class="col-md-1 col-lg-3 col-xl-4 col-sm-12 ml-sm-n3">
      <!-- <ul class="unstyled inbox-pagination"> -->
      <!-- <div class="sidebar-search"> -->
      <div class="input-group">
        <input
          type="input"
          class="form-control search-menu search-placeholder"
          placeholder="Search..."
          [(ngModel)]="search"
          (keyup)="changepage()"
          name="searchText"
        />
        <!-- <div class="material-icons-outlined search-icon">search</div> -->
      </div>
      <!-- </div> -->
      <!-- </ul> -->
    </div>
    <div
      style="margin-right: 10px"
      class="col-md-2 col-lg-4 col-xl-2 col-sm-5 mt-0 sm-mt-3 col-sm-12"
    >
      <button
        type="button"
        (click)="this.search = ''"
        mat-raised-button
        class="searchBtn"
        [routerLink]="['./assign-emp-task']"
      >
        <span>Assign Employee Task</span>
      </button>
    </div>
  </div>
</nav>
<mat-card class="time-mat-card">
  <div class="main-content">
    <div class="container-fluid">
      <div class="top-content row">
        <div>
          <!-- <h4 class="assign-employee">Assign Employee Task</h4> -->
        </div>
        <!-- <div class="col-md-5 col-sm-5">
        <ul class="unstyled inbox-pagination">
          <div class="sidebar-search">
            <div class="input-group">
              <input
                type="text"
                class="form-control search-menu"
                placeholder="Search..."
                [(ngModel)]="search"
                (keyup)="changepage()"
                name="searchText"
              />
            </div>
          </div>
        </ul>
      </div> -->

        <!-- <div class="col-md-2 col-sm-2">
        <button
          type="button"
          (click)="this.search = ''"
          mat-raised-button
          class="btn assign-button"
          [routerLink]="['./assign-emp-task']"
        >
          <span >Assign Employee Task</span>
        </button>
      </div> -->
      </div>
      <div class="search-content">
        <div class="row">
          <div class="col-sm-4">
            <label class="form-control-label"
              >Project<span class="text-danger"> *</span></label
            >
            <mat-select
              id="framework"
              (click)="this.search = ''"
              class="form-control email-input select-placeholder"
              [(ngModel)]="projectId"
              placeholder="Select Project"
              (selectionChange)="getproject(); this.search = ''"
            >
              <mat-option (click)="selectProject()"
                >-Select Project-</mat-option
              >
              <mat-option
                *ngFor="let pro of projectList"
                [value]="pro.projectId"
                (click)="projectData(pro)"
              >
                {{ pro.projectName }}
              </mat-option>
            </mat-select>
            <span class="error_msg" *ngIf="project_required"
              >Please select project</span
            >
          </div>

          <div class="col-sm-4 apply-margin">
            <label class="form-control-label">Search Employee</label>

            <mat-select
            [(ngModel)]="selectedEmployeeSearch"
         
              placeholder="Search Employee"
              class="form-control email-input select-placeholder text-truncate"
              [disabled]="!projectId"
    
              #singleSelect
            >
              <mat-option
        
              >
              <!-- [formControl]="selectedemployee" -->
                <ngx-mat-select-search
            
                  (keyup)="empSearch($event, $event)"
             
                  [(ngModel)]="selectedemployee"
                  placeholderLabel="Search Employee"
               
                  [disabled]="!projectId"
                 
                >
                  <span  ngxMatSelectNoEntriesFound>
                    No Employee Found
                    <!-- <button mat-button color="primary">
                    Add <mat-icon>add</mat-icon>
                  </button> -->
                  </span>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let emp of employeeProjects"
                [value]="emp?.employee.employeeId"
                (click)="empSelect(emp)"
              >
                {{ emp?.employee?.employeeNumber }} -
                {{ emp?.employee?.firstName }}
              </mat-option>
            </mat-select>
            <!-- <input
              type="text"
              matInput
              (input)="validateInput($event)"
              (keydown)="validateInput($event)"
              (keyup)="validateInput($event)"
              (click)="this.search = ''"
              placeholder="Search Employee"
              [(ngModel)]="selectedemployee"
              [matAutocomplete]="auto"
              [disabled]="!projectId"
              class="form-control email-input select-placeholder"
              (keyup)="empSearch($event, $event); this.search = ''"
              (keydown)="handleBackspace($event)"
            /> -->

            <!-- [readonly]="
                  !assignformbyemployee.value.employee ||
                  assignformbyemployee.value.employee == 'null'
                " -->
            <!-- <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let emp of employeeProjects"
                [value]="emp?.employee?.employeeId"
                (click)="empSelect(emp)"
              >
                {{ emp?.employee?.employeeNumber }} -
                {{ emp.employee.firstName }}
              </mat-option>
              <mat-option
                [value]="null"
                *ngIf="employeeNotFound"
                (click)="noEmployeeFound()"
                >No Employee Found</mat-option
              >
            </mat-autocomplete> -->
            <!-- <mat-icon
            class="material-symbols-outlined"
            style="position: absolute; top: 37px; right: 19px"
          >
            search
          </mat-icon> -->
          </div>
          <div class="col-sm-4 mt-4">
            <button
              type="button"
              mat-raised-button
              class="searchBtn1"
              (click)="searchTimesheet(); this.search = ''"
              [disabled]="!searchButtonDisable"
            >
              Search
            </button>

            <button
              type="button"
              mat-raised-button
              class="btn searchBtn ml-3"
              (click)="resetForms()"
              [disabled]="!searchButtonDisable"
              style="
                background: none;
                color: black;
                border: 1px solid #ddd;
                font-size: 16px;
                font-weight: 610;
              "
            >
              Clear
            </button>
          </div>
        </div>

        <div class="card-maargin">
          <div class="" *ngIf="tableShow">
            <div class="row p-0 m-0">
              <div
                class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9 text-right"
              ></div>

              <div
                class="col-xl-2 col-lg-3 col-md-3 col-sm-4 text-right"
                *ngIf="taskList.length > 5"
              >
                <div class="select-pagination">
                  <span>Show : </span>
                  <select
                    class="select_list new-select_list listDesign"
                    [(ngModel)]="mySelect"
                    (change)="changefirstpage()"
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive-new1 table-content card-content">
              <table class="table" matSort (matSortChange)="sortData($event)">
                <thead>
                  <th
                    mat-sort-header="sno"
                    scope="col"
                    style="text-align: center"
                  >
              
                    &nbsp;&nbsp;S.No
                  </th>
                  <th
                    mat-sort-header="projectCode"
                    style="text-align: center"
                    scope="col"
                  >
                    Project&nbsp;Code
                  </th>
                  <th mat-sort-header="projectName" scope="col">
                    Project&nbsp;Name
                  </th>
                  <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
                  <th mat-sort-header="taskCode" scope="col">Task&nbsp;Code</th>
                  <th mat-sort-header="employeeName" scope="col">
                    Resource&nbsp;Name
                  </th>
                  <th
                    mat-sort-header="startdate"
                    scope="col"
                    style="text-align: center"
                  >
                    Start&nbsp;Date
                  </th>
                  <th
                    mat-sort-header="enddate"
                    scope="col"
                    style="text-align: center"
                  >
                    End&nbsp;Date
                  </th>
                  <th
                    mat-sort-header="assignedhours"
                    style="text-align: center"
                  >
                    Assigned&nbsp;Hours
                  </th>
                  <th
                    class="sticky-col-tab"
                    mat-sort-header="action"
                    scope="col"
                  >
                
                    &nbsp;&nbsp;Action
                  </th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let e of taskList
                        | empTimesheet : search
                        | paginate : { itemsPerPage: mySelect, currentPage: p };
                      let i = index
                    "
                  >
                    <td style="text-align: center" *ngIf="p">
                      {{ (p - 1) * mySelect + (i + 1) }}
                    </td>
                    <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
                    <td style="text-align: center">
                      {{ e.task.project.projectCode }}
                    </td>
                    <td style="min-width: 150px !important">
                      {{ e.task.project.projectName }}
                    </td>
                    <td
                      style="
                        white-space: normal;
                        overflow-wrap: break-word;
                        word-break: normal;
                        min-width: 250px !important;
                      "
                    >
                      {{ e.task.taskName }}
                    </td>

                    <td>
                      <ng-container
                        *ngIf="!e.task.taskCode; else showTaskCode"
                        >{{ "-" }}</ng-container
                      >
                      <ng-template #showTaskCode>{{
                        e.task.taskCode
                      }}</ng-template>
                    </td>
                    <td>
                      {{ e.employee.firstName }} {{ e.employee.lastName }}
                    </td>
                    <td style="text-align: center; padding-right: 25px">
                      {{ e.startdate }}
                    </td>
                    <td style="text-align: center; padding-right: 25px;min-width: 150px !important">
                      {{ e.enddate }}
                    </td>
                    <td style="text-align: center">
                      {{ ( e.assignedhours)}}:{{
                        ( e.assignedminutes)
                      }}:00
                    </td>

                    <td style="text-align: center">
                      <span
                        class="material-icons"
                        [routerLink]="['./assign-emp-task', e.emptaskId]"
                        title="edit"
                      >
                        edit
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        font-size: larger;
                        font-weight: bold;
                      "
                      colspan="10"
                      *ngIf="
                        nodata ||
                        ((taskList | empTimesheet : search).length === 0 &&
                          search) ||
                        taskList.length == 0
                      "
                    >
                      Assign Employee Task Not Found!
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
            class="pagination-content"
            *ngIf="taskList.length > 5 && (taskList | empTimesheet : search).length > 0"
            id="page"
          >
              <pagination-controls
                *ngIf="
                
                  (taskList | empTimesheet : search).length > 5
                "
                class="text-right"
                style="float: right"
                (pageChange)="p = $event; beforesearchpage = $event"
                align="end"
              ></pagination-controls>
            </div>

              <!--             
            <div
              *ngIf="(taskList | empTimesheet : search).length === 0"
              class=" text-center"
            >
              <span class=""  style="
              text-align: center;
              position: relative;
    
              font-size: 19px;
              font-weight: 410;
            ">
                Assign Employee Task Not Found
              </span>
            </div> -->
            </div>
          </div>
        </div>
        <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
          <div class="card-body-nodata">
            <div class="alert-nodata alert-primary">
              <h4 align="center" class="h4">Assign Employee Task Not Found</h4>
            </div>
          </div>
        </mat-card> -->
      </div>
    </div>
  </div>
</mat-card>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
<div
  class="text-center successUpdate"
  style="margin-top: 180px"
  *ngIf="success"
  #successMessageElement
>
  <div class="alert alert-success toast-msg successMessage" role="alert">
    <strong>{{ successMessage }}</strong
    ><br />
  </div>
</div>

<div
  class="text-center successUpdate"
  style="margin-top: 180px"
  *ngIf="successUpdate"
>
  <div class="alert alert-success toast-msg successMessage" role="alert">
    <strong>{{ successUpdateMessage }}</strong
    ><br />
  </div>
</div>
