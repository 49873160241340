import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
import { httpOptions, redirectMsgTimeOut, responseMsgTimeOut } from '../../../providers/properties';
import { AddQualificationComponent } from '../add-qualification/add-qualification.component';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.scss']
})
export class QualificationComponent implements OnInit {
  public searchFilter: any = '';
  search: any;
  tableShow: boolean = false;
  mySelect: any = 5;
  nodatafound: boolean = false;
  loader: boolean = true;
  empId: any;
  organizationId: any;
  clientId: number;
  constructor(
    private settingsservice: SettingsService,
    private dialog: MatDialog,
  ) { }

  resultData: any = [];
  p: number = 1;
  collection: any[];
  pagePerItem: any = 10;
  tableSearch: any;

  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  sortedData: any[];
  //Documnt ready function
  ngOnInit(): void {
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('local',x);
    this.clientId = x?.employee?.client?.clientId;
    this.empId = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;
    this.resultData = Array.isArray(this.resultData) ? this.resultData : Object.values(this.resultData);

    this.qualificationList();
  }

  qualificationList() {
    this.p = 1
    this.tableShow = false;


    if(this.organizationId){
      this.loader = true
      this.settingsservice.getQualificationList(this.organizationId).subscribe((result: any) => {

        console.log('result', result);
  
  
        this.tableShow = true;
        this.loader = false
        this.nodatafound = false
  
        this.resultData = result;
        // this.resultData = result.data || []; 
  
        if (this.resultData.statusCode == 409) {
          this.resultData = [];
          this.nodatafound = true;
        }
        if (this.resultData.length === 0) {
          this.nodatafound = true;
        }
      }, err => {
        this.nodatafound = true
        //this.errorMsg = err.error.message;
      })
    }

    if(this.clientId){
      // this.loader = true
      this.settingsservice.getStreamListbyClient(this.clientId).subscribe((result: any) => {

        console.log('result', result);
  
  
        this.tableShow = true;
        this.loader = false
        this.nodatafound = false
  
        this.resultData = result;
        // this.resultData = result.data || []; 
  
        if (this.resultData.statusCode == 404) {
          this.resultData = [];
          this.nodatafound = true;
        }
        if (this.resultData.length === 0) {
          this.nodatafound = true;
        }
      }, err => {
        this.nodatafound = true
        //this.errorMsg = err.error.message;
      })
    }

  }

  changefirstpage() {
    this.p = 1;
  }
  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AddQualificationComponent, {
      //height: 'auto',width: '600px',
      width: '500px', height: 'fit-content',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.qualificationList();

      if (result.statusCode == 200) {
        this.success = true;
        this.sucess_msg = result.message;
        setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
      }
      else if (result.statusCode > 400) {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData) {
    var dialogRef = this.dialog.open(AddQualificationComponent, {
      width: '500px', height: 'fit-content',
      data: editData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.qualificationList();

      if (result.statusCode == 200) {
        this.success = true;
        // this.loader=true
        this.sucess_msg = result.message;
        setTimeout(() => {
          this.success = false;
          // this.loader=false
        }, 4000)
      }
      else if (result.statusCode > 400) {
        // this.loader=true
        // this.tableShow=false
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {
          this.error = false;
          // this.loader=false;
          // this.tableShow=true
        }, 4000)
      }
      //this.success = true;
    });
  }

  // updateStatus(id,status)
  // {
  //   // this.loader=true
  //   // this.tableShow=true;


  //   this.settingsservice.updateQualificationStatus(id,status).subscribe( (result:any) =>
  // 	{
  //     if(result.statusCode == 200)
  //     {
  //       this.loader=true
  //       this.tableShow=false;
  //       // this.success = true;
  // 			// this.sucess_msg = result.message;
  //       setTimeout(() => {
  //         // this.success  = false;
  //         // this.loader=false
  //         // this.tableShow=false
  //       },4000)

  //       this.qualificationList();
  //     }
  //     else
  //     {
  //       this.loader=false;
  //       this.tableShow=true;
  //       // this.error = true;
  //       // this.error_msg = result.message;
  //       setTimeout(() => {this.error = false}, 4000)
  //     }
  // 	})
  // }

  updateStatus(id, status) {
    this.settingsservice.updateQualificationStatus(id, status).subscribe((result: any) => {
      if (result.statusCode == 200) {
        // this.success = true;
        // this.sucess_msg = result.message;
        this.loader = true
        this.tableShow = false
        setTimeout(() => { this.success = false; }, redirectMsgTimeOut)

        this.qualificationList();
      }
      else {
        // this.error = true;
        // this.error_msg = result.message;
        this.loader = false
        this.tableShow = true
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }
    })
  }

  /**** Global Variables *****/
  ucFirst(text) {
    return text.replace(/(?:^|\s)\S/g, (res) => { return res.toUpperCase(); })
  };
  /**** Global Variables *****/

  sortData(sort: Sort) {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'streamName':
          return compare(a.streamName, b.streamName, isAsc);

        default:
          return 0;
      }
    });
  }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
