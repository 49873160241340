<div class="resource-background">
  <div class="resource-title">
    <h1>RESOURCE</h1>
  </div>
  <div class="card resource-card">
    <form>
      <div class="add-feedback-div">
        <mat-icon class="material-symbols-outlined back" (click)="back()" style="color:red;">arrow_back</mat-icon>
        <button class="btn add-feedback-btn" *ngIf="!feedbackId" routerLink="/home/resource/feedback/{{resourceId}}"
          style="margin-bottom: 30px;">ADD FEEDBACK</button>
        <button class="btn add-feedback-btn" *ngIf="feedbackId"
          routerLink="/home/resource/feedback/{{resourceId}}/{{feedbackId}}"
          style="margin-bottom: 30px; margin-right: 20px;">UPDATE FEEDBACK</button>
        <button class="btn add-feedback-btn" *ngIf="feedbackId" routerLink="/home/resourcefeedback/{{resourceId}}"
          style="margin-bottom: 30px;">VIEW FEEDBACK</button>

      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Requirement Number:</label>
          <label class="form-control-label-class"
            *ngIf="resourceobj?.requirement != undefined">{{resourceobj.requirement?.requirementNo}} </label>
          <label class="form-control-label-class" *ngIf="resourceobj?.requirement == undefined"> --</label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Creation Date:</label>
          <label class="form-control-label-class">{{resourceobj?.createDateTime.substring(0,10)}} </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Resource Number: </label>
          <label class="form-control-label-class" *ngIf="resourceobj?.resourceNumber != undefined">
            {{resourceobj?.resourceNumber}} </label>
          <label class="form-control-label-class" *ngIf="resourceobj?.resourceNumber == undefined"> -- </label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Resource Name : </label>
          <label class="form-control-label-class">{{resourceobj?.resourceName}} </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Interview date : </label>
          <!-- <label class="form-control-label-class">{{resourceobj.interviewDate.slice(0,10)}} </label> -->
          <label class="form-control-label-class" *ngIf="resourceobj?.interviewDate != undefined">
            {{resourceobj?.interviewDate.slice(0,10)}} </label>
          <label class="form-control-label-class" *ngIf="resourceobj?.interviewDate == undefined"> -- </label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Position : </label>
          <label class="form-control-label-class"> {{resourceobj?.position?.positionName}} -
            {{resourceobj?.position?.positionNumber}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Total Experience : </label>
          <label class="form-control-label-class">{{resourceobj?.totalExperience}} Years</label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Relevent Experience : </label>
          <label class="form-control-label-class">{{resourceobj?.relevantExperience}} Years</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Current CTC : </label>
          <label class="form-control-label-class">{{resourceobj?.currentCtc}}</label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Expected CTC : </label>
          <label class="form-control-label-class">{{resourceobj?.expectedCtc}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Job Location : </label>
          <label class="form-control-label-class">{{resourceobj?.jobLocation}}</label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Current Location : </label>
          <label class="form-control-label-class">{{resourceobj?.currentLocation}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform"
          *ngIf="resourceobj?.currentLocation?.toLowerCase() != resourceobj?.jobLocation?.toLowerCase()">
          <label class="form-control-label">Ready to Relocate : </label>
          <label class="form-control-label-class"
            *ngIf="resourceobj != undefined && resourceobj?.relocate == true">Yes</label>
          <label class="form-control-label-class"
            *ngIf="resourceobj != undefined && resourceobj?.relocate == false">No</label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Notice Period : </label>
          <label class="form-control-label-class">{{resourceobj?.noticePeriod?.listTypeValueName}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform" style="    max-height: 123px;">
          <label class="form-control-label">Resource Availability : </label>
          <div *ngFor="let resourceobj of resourceobj?.resourceAvailability"
            style="position: relative;left: 31%;bottom:28px">
            <label class="form-control-label-class">Date : {{resourceobj?.resourceAvailabilityDate?.slice(0,10)}} Time :
              {{resourceobj.resourceAvailabilityDate.substring(11,16)}}</label>
            <!-- <label class="form-control-label-class">{{resourceobj.resourceAvailability}}</label> -->
          </div>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Comments : </label>
          <label class="form-control-label-class">{{resourceobj?.comments}} </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Interviewed : </label>
          <label class="form-control-label-class">{{resourceobj?.interviewed ? 'Yes' : 'No'}}</label>
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Interview Status : </label>
          <label class="form-control-label-class"
            *ngIf="resourceobj?.interviewStatus != undefined">{{resourceobj?.interviewStatus?.listTypeValueName}}</label>
          <label class="form-control-label-class" *ngIf="resourceobj?.interviewStatus == undefined"> -- </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform" *ngIf="resourceobj?.resourcecvattachment != undefined">
          <div class="col-md-6" id="loginform" style="position: relative;bottom: 5px;">
            <!-- <a class="file-download badge badge-secondary form-control-label" href="{{resourceobj.resourcecvattachment.fileDownloadUrl}}">
                            <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>Download</a> -->
            <a class=" btn-primary create-button"
              href="{{downloadbaseurl+'download_CV/'+resourceobj.resourcecvattachment.resourceCvId}}">
              <!-- <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon> -->

              Download File</a>


          </div>
          <label 
          class="col-form-label"
          style="    font-family: Mulish;
          margin-left: 3%;
          margin-top: -11px;
          font-family: 'Mulish', sans-serif;
          font-size: 15px;
          font-weight: 660;
          position: relative;
          right: 14px;
          top: -8px;"
        >
          CV Attachment: {{ cvFileName }}
        </label>
        </div>

      </div>
    </form>
  </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="    width: 3rem;
  height: 3rem;
  position: absolute;
  top: 45%;
  left: 46%;" role="status"></div>
</div>
