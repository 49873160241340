<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title class="headerDesign">    {{ departmentId ? "Edit" : "Add" }} Department</h3>

    <mat-dialog-content class="mat-dialog-content mat-typography">
        <div class="row">
            <div class="form-group col-md-6" style="height: 70px !important;">
                <label class="form-control-label">Department Code <span class="star">*</span></label>
                <input type="text" (keyup)="checkExistDepartmentCode($event)" formControlName="departmentCode" id="departmentCode" [ngClass]="{ 'is-invalid': submitted }" class="form-control email-input select-placeholder" value="" placeholder="Department Code" autocomplete="off">
                <!-- <div class="valid-msg" *ngIf="(validate.departmentCode.invalid && validate.departmentCode.touched) || validate.departmentCode.dirty || submitted">
                    <span *ngIf="validate.departmentCode.errors?.required" class="text-danger">Please Enter Department Code</span>
                    <span *ngIf="validate.departmentCode.errors?.pattern" class="text-danger">Please Enter valid Department Code</span> -->
                    <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
                <!-- </div> -->
                <span *ngIf="validate.departmentCode.errors?.pattern" class="text-danger valid-msg">Please Enter valid Department Code</span>
                <span *ngIf="validate.departmentCode && submitted"  class="text-danger valid-msg" >{{
                    projectCode()
                  }}
                </span>
            </div>

            <div style="height: 70px !important;" class="form-group col-md-6">
                <label class="form-control-label">Department Name <span class="star">*</span></label>
                <input type="text" (keyup)="checkExistDepartmentName($event)" formControlName="departmentName" id="departmentName" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-control email-input select-placeholder" value="" placeholder="Department Name">
                <div class="valid-msg" *ngIf="(validate.departmentName.invalid && validate.departmentName.touched ) || validate.departmentName.dirty || submitted">
                    <span *ngIf="validate.departmentName.errors?.required" class="text-danger">Please Enter Department Name</span>
                    <span *ngIf="validate.departmentName.errors?.pattern" class="text-danger">Please Enter valid Department Name</span>

                    <span *ngIf="departNameExistStatus" class="span-error"> Department Name already exist!</span>
                </div>
            </div>
        </div>

        <div class="row" style="    position: relative;
    top: 13px;">
            <div class="form-group col-md-12">
                <label class="form-control-label">Department Description</label>
                <textarea formControlName="departmentDescription" id="departmentDescription" rows="3" class="form-control email-input select-placeholder" placeholder="Department Description" autocomplete="off"></textarea>
                <div class="valid-msg" *ngIf="(validate.departmentDescription.invalid && validate.departmentDescription.touched ) || validate.departmentDescription.dirty ">
                    <span *ngIf="validate.departmentDescription.errors?.pattern" class="text-danger">Please Enter valid Department Description</span>

                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button"
        mat-raised-button
        [mat-dialog-close]="true" 
        style="
          margin-left: 10px;
          background: none;
          color: var(--Red-Color, #ff5d5d);
          border: 1px solid #ddd !important;
          height: 32px;
          position: relative;
          top: 2px;
          font-weight: 550;
        "
[disabled]="isbtnDisable"

        class="btn search-btn mr-2">Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button 
                class=" search-btn searchBtn"
                [disabled]="isbtnDisable"
                style="">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 47%;
    right: 48%;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>