import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProjectManagementService } from '../providers/project-management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssignProjectErrorComponent } from '../project-management/project-management/assign-project-error/assign-project-error.component';
// import { AssignProjectErrorComponent } from '../project-management/assign-project-error/assign-project-error.component';

@Component({
  selector: 'app-employee-project-import-component',
  templateUrl: './employee-project-import-component.component.html',
  styleUrls: ['./employee-project-import-component.component.scss'],
})
export class EmployeeProjectImportComponentComponent implements OnInit {
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
buttonDisable:boolean = false;
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg: any;
  resultData: any;
  fileInputLabel: string;
  importerror: boolean = false;

  sucess_Msg: any;
  error_msg: any;
  loader: any = false;
  success: boolean = false;
  error: any = false;
  backdrop: any = false;

  employeefile: any;
  employeetData: any;
  errorrecords: any;
  employeeId: any;
  storeOrgId: any;
  stroeEmpId: any;
  errormessagejson: any;
  sucessDataMessage: boolean;
  isbtnDisabled: boolean = false;
  constructor(
    // private settingsservice: SettingsService,
    // private EmployeeService: EmployeeService,
    private globalservice: ProjectManagementService,
    private formBuilder: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,
    private dialog: MatDialog,

    private dialogref: MatDialogRef<EmployeeProjectImportComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) {}

  ngOnInit(): void {
    this.postForm = this.formBuilder.group({
      file: [null, [Validators.required]],
    });
    this.fileInputLabel = '';
  }

  get validate() {
    return this.postForm.controls;
  }

  onCancel() {
    if (this.postForm.invalid) {
      this.submitted = false;
      this.postForm.reset();
    }
    this.postForm.reset();
    this.postForm.get('file').reset();

    this.submitted = false; // Reset the submitted flag to false
    // this.postForm.reset(); // Optionally reset the form if needed
    // this.dialogref.close(); // Close the dialog
  }

  get f() {
    return this.postForm.controls;
  }
  get file() {
    return this.postForm.get('file');
  }
  onSubmit() {
    this.submitted = true;
    this.isbtnDisabled = true;

    if (this.postForm.invalid) {
      this.loader = false;
      return;
    }

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let employeeId = this.employeetData.employee.employeeId;
    let organizationId = this.employeetData.organization?.organizationId;

    const formData = new FormData();
    formData.append('file', this.postForm.get('file').value);
    formData.append('organizationId', organizationId);
    formData.append('employeeId', employeeId);
    console.log('file', formData);
    this.loader = true;
    // this.EmployeeService.employeeimport(formData).subscribe((result: any) =>
    this.globalservice.employeeProjectImport(formData).subscribe(
      (result: any) => {
        // console.log("result",result)
        if (result.statusCode == 200) {
          this.success = true;
          this.isbtnDisabled = true;
          this.loader = false;
          this.sucess_Msg = result.description;
          // this.isButtonDisabled = true;

          setTimeout(() => {
            this.success = false;
            this.isbtnDisabled = true;
            this.loader = false;

            this.sucess_Msg = result.description;
            this.dialogref.close(result);
          }, 2000);
          // this.success = false;

          // this.router.navigate(['/home/assign-approver']);
        }

        // if(result.statusCode==200)
        // {

        //   console.log("if entered....!");
        //   console.log("result",result)

        //   //this.loader = true;
        //   this.sucessDataMessage=result.description;
        //   this.success=true;

        //  console.log(" this.sucess_Msg::",this.sucessDataMessage);

        //   // setTimeout(() => {this.success  = false;
        //   // },2000)
        //   this.backdrop = false;
        //   this.loader = false;
        //   // this.success = true;
        //   this.dialogref.close(result);

        // }
        else {
          console.log('else entered....!');

          this.error = true;
          this.error_msg = result.description;
          this.errorrecords = result;
          this.isbtnDisabled = false;
          this.loader = false;

          this.dialogref.close(result);
        }
      },
      (error) => {
        this.isbtnDisabled = false;

        console.log(error);
      }
    ); //Create end here
  }

  showerrormessage() {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(AssignProjectErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }
  onFileSelect(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    const filePath = fileInput.value;
    const allowedExtensions = /(\.xls|\.xlsx)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload a file with .xls or .xlsx extension.');
      fileInput.value = ''; // Clear the file input safely
      this.buttonDisable = false;
      event.preventDefault();
    } else {
      if (fileInput.files && fileInput.files.length > 0) {
        this.buttonDisable = true;
        const file = fileInput.files[0];
        this.fileInputLabel = file.name;
        this.postForm.controls['file'].setValue(file); // Use setValue on form control
      }
    }
  }

  //   if (event.target.files.length > 0)
  //   {
  //     const file = event.target.files[0];

  //       this.fileInputLabel = file.name;
  //       this.postForm.get('file').setValue(file);

  //   }
  // }
}
