import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Tooltip } from 'bootstrap';
// import { DatePipe} from '@angular/common/';
import { Sort } from '@angular/material/sort';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
} from 'ng-apexcharts';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { DatePipe } from '@angular/common';
import { HeaderService } from 'src/app/providers/header.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { RecruitmentService } from 'src/app/providers/recruitment.service';

// import { ProjectManagementService } from 'src/app/providers/project-management.service';
declare var $: any;

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};
@Component({
  selector: 'app-project-management-admin',
  templateUrl: './project-management-admin.component.html',
  styleUrls: ['./project-management-admin.component.scss'],
})
export class ProjectManagementAdminComponent {
  @ViewChild('tooltipTarget') tooltipTarget!: ElementRef;
  @ViewChild('tooltipClose')  tooltipClose:ElementRef;
  projectCount: any = 0;
  clientCount: any = 0;
  employeeCount: any = 0;
  taskCount: any = 0;
  clientList: any = [];
  page: any;
  nodata = false;
  loader = true;
  search: any;

  public searchFilter: any = '';
  query: any = '';
  beforesearchpage: any;
  mySelect: any = 5;
  p: any;
  timesheetdate: any;
  notsubmittedtaskreport: any = [];
  projectBudgetHourslist: any = [];
  projectAcutalHourslist: any = [];
  projectNameList: any = [];
  public chartOptions: Partial<ChartOptions>;
  projectList: any;
  nodatachart = false;
  empId: any;
  organizationId: any;
  usertData: any = '';
  userType: any;
  constructor(
    private gs: ProjectManagementService,
    private datePipe: DatePipe,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService,
    private positionservice: RecruitmentService
  ) {}

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  ngOnInit(): void {
 
    this.headerservice.setTitle('- Dashboard');
    this.timesheet.setSuccessMessage('');
    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    this.assignemp.setSuccessUpdateMessage(
      ''
    );
    const data = JSON.parse(localStorage.getItem("enoteUserData"));
    this.empId = data.employeeId;
    this.organizationId = data.organization.organizationId;
    this.loader = true;
    var currentdate = new Date();
    this.timesheetdate = this.datePipe.transform(currentdate, 'yyyy-MM-dd');
    console.log('timesheetdate' + this.timesheetdate);

    this.gs
      .getnotsubmittedTaskReportByEmpolyee(this.timesheetdate)
      .subscribe((d: any) => {
        console.log("d::",d);
        
        this.notsubmittedtaskreport = d;
        console.log('this.notsubmittedtaskreport', this.notsubmittedtaskreport.length);

        // this.employeeCount = d.length;
      });

    this.getprojectlist();

    // this.gs.getTaskList().subscribe((d: any) => {
    //   this.taskCount = d.length;

    //   console.log('tasklist', d);
    // });

      this.gs.getTaskCount(
    ).subscribe(
    (d:any)=>{
      this.loader = false;

      console.log('taskCount',d.taskCount);
      
      this.taskCount = d.taskCount;
      d.map(
        e=>{
          let task = new Array()
          if(e.task.taskStatus == 31){
            task.push(d)
          }
          // this.taskPendingCount = task.length;
        }
      )
    },
    (error) => {
      this.nodata = true;
      this.loader = false;
      console.log("error")
      if(error.status == 404){
        this.nodata = true;
        console.log("404")
      }
    }
  )
    this.gs.getResource().subscribe((d: any) => {


      console.log('getResource Employee List::', d);
      this.employeeCount = d.activeEmployees;
      console.log("employe count::",this.employeeCount);
      });
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      console.log('local storage data::', this.usertData);
  
      this.userType = [];
      this.usertData.employee.role.forEach((role) => {
        this.userType.push(role?.roleName);
      });
  
      this.organizationId = this.usertData.organization.organizationId;
  
      this.positionservice
      .getclientlist(this.organizationId)
      .subscribe((resultData: any) => {
        console.log(' resultData', resultData);
        // this.clientListData = resultData;
        // this.Customer = resultData
        this.clientCount = resultData.length;
      });
    // this.gs.Get_Customer().subscribe(
    //   (d: any) => {
    //     console.log('getCustomer', d);

    //     this.clientCount = d.length;
    //     this.loader = false;
    //     this.clientList = d;
    //     if (this.clientList.length == 0) {
    //       this.nodata = true;
    //       console.log('nodata');
    //     }
    //   },
    //   (error) => {
    //     this.loader = false;
    //     this.nodata = true;
    //     console.log('error');
    //     if (error.status == 404) {
    //       this.nodata = true;
    //       console.log('404');
    //     }
    //   }
    // );
  }

  getproject(event: any) {
    console.log(event);
    this.projectBudgetHourslist = [];
    this.projectAcutalHourslist = [];
    this.projectNameList = [];
    if (event.value != 0) {
      this.gs.getprojectByID(event.value).subscribe((d: any) => {
        console.log('getprojectByID', d);

        if (d.projectBudgetHours) {
          this.projectBudgetHourslist.push(d.projectBudgetHours);
          this.projectAcutalHourslist.push(
            d.projectAcutalHours.substring(0, 2)
          );
          this.projectNameList.push(d.projectName);

          console.log(
            'this.projectBudgetHourslist',
            this.projectBudgetHourslist
          );
          console.log(
            'this.projectAcutalHourslist',
            this.projectAcutalHourslist
          );
        }
        if (this.projectNameList.length == 0) {
          this.nodatachart = true;
        } else {
          this.nodatachart = false;
        }

        this.chartOptions = {
          series: [
            {
              name: 'Budget Hours',
              data: this.projectBudgetHourslist,
            },
            { 
              name: 'Actual Hours',
              data: this.projectAcutalHourslist,
            },
          ],
        

          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              // endingShape: "rounded"
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: this.projectNameList,
          },
          yaxis: {
            title: {
              text: '(Hours)',
            },
          },
          fill: {
            opacity: 1,
          },
          // tooltip: {
          //   y: {
          //     formatter: function (val) {
          //       return val + ' Hours';
          //     },
          //   },
          // },
          
        };
      });
    } else {
      console.log('else');
      this.getprojectlist();
    }
  }

  getprojectlist() {
    this.gs.listProjectList().subscribe((d: any) => {
      this.projectCount = d.length;
      this.projectList = d;
      console.log('this.projectList;;;;', this.projectList);

      d.forEach((element) => {
        if (element.projectBudgetHours) {
          this.projectBudgetHourslist.push(element.projectBudgetHours);
          this.projectAcutalHourslist.push(
            element.projectAcutalHours.substring(0, 2)
          );

          console.log(
            'this.projectBudgetHourslist',
            this.projectBudgetHourslist
          );
          console.log(
            ' this.projectAcutalHourslist',
            this.projectAcutalHourslist
          );
          this.projectNameList.push(element.projectName);
        }
      });

      if (this.projectNameList.length == 0) {
        this.nodatachart = true;
      } else {
        this.nodatachart = false;
      }

      this.chartOptions = {
        series: [
          {
            name: 'Budget Hours',
            data: this.projectBudgetHourslist,
          },
          {
            name: 'Actual Hours',
            data: this.projectAcutalHourslist,
          },
        ],
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            // endingShape: "rounded"
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.projectNameList,
        },
        yaxis: {
          title: {
            text: '(Hours)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' Hours';
            },
          },
        }
      };
    });
  }

   ngAfterViewInit(): void {
    new Tooltip(this.tooltipTarget.nativeElement);
    new Tooltip(this.tooltipClose.nativeElement);
  }

  projectStatusOpen() {
    this.gs.listProjectList().subscribe((d: any) => {
      this.projectList = d.filter((element) => {
        return element?.projectStatus?.listTypeValueName === 'Open';
      });

      this.projectBudgetHourslist = [];
      this.projectAcutalHourslist = [];
      this.projectNameList = [];
      console.log('checking project list', this.projectNameList);

      this.projectList.forEach((element) => {
        if (element.projectBudgetHours) {
          this.projectBudgetHourslist.push(element.projectBudgetHours);
          this.projectAcutalHourslist.push(
            element.projectAcutalHours.substring(0, 2)
          );
          this.projectNameList.push(element.projectName);
        }
      });

      this.nodatachart = this.projectNameList.length === 0;
      this.chartOptions = {
        series: [
          {
            name: 'Budget Hours',
            data: this.projectBudgetHourslist,
          },
          {
            name: 'Acutal Hours',
            data: this.projectAcutalHourslist,
          },
        ],
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            // endingShape: "rounded"
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.projectNameList,
        },
        yaxis: {
          title: {
            text: '(Hours)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' Hours';
            },
          },
        },
      };
    });
  }


  projectStatusClose() {
    this.gs.listProjectList().subscribe((d: any) => {
      this.projectList = d.filter((element) => {
        return element?.projectStatus?.listTypeValueName === 'Closed';
      });

      this.projectBudgetHourslist = [];
      this.projectAcutalHourslist = [];
      this.projectNameList = [];
      console.log('checking project list', this.projectNameList);

      this.projectList.forEach((element) => {
        if (element.projectBudgetHours) {
          this.projectBudgetHourslist.push(element.projectBudgetHours);
          this.projectAcutalHourslist.push(
            element.projectAcutalHours.substring(0, 2)
          );
          this.projectNameList.push(element.projectName);
        }
      });

      this.nodatachart = this.projectNameList.length === 0;
      this.chartOptions = {
        series: [
          {
            name: 'Budget Hours',
            data: this.projectBudgetHourslist,
          },
          {
            name: 'Acutal Hours',
            data: this.projectAcutalHourslist,
          },
        ],
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            // endingShape: "rounded"
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.projectNameList,
        },
        yaxis: {
          title: {
            text: '(Hours)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' Hours';
            },
          },
        },
      };
    });
  }
  changefirstpage() {
    this.p = 1;
    this.changefirstpage();
    }

  sortData(sort: Sort) {
    const data = this.notsubmittedtaskreport.slice();
    if (!sort.active || sort.direction === '') {
      this.notsubmittedtaskreport = data;

    
      
      return;
    }

    this.notsubmittedtaskreport = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employeeName':
          return this.compare(a.employeeName, b.employeeName, isAsc);
        case 'organizationEmail':
          return this.compare(a.organizationEmail, b.organizationEmail, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  
}
