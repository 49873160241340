import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { EmployeeService } from '../providers/employee.service';

@Component({
  selector: 'app-assign-employee',
  templateUrl: './assign-employee.component.html',
  styleUrls: ['./assign-employee.component.scss']
})
export class AssignEmployeeComponent implements OnInit {

  employeeform: FormGroup;
  empCode: boolean = false;
  empList: any = [];
  employee:any;
  employeeId: number;
  firstname: any;
  lastname: any;
  empnumber: any;
  selectedEmployeeData: any = [];
  showtable:boolean = false;
  loader: any = false;
  usertData: any;
  orgId: number;
  userId: any;
  employeelistsub: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  tableSearch: any;
  dataArray: any = [];
  length: number;
  pagePerItem: any = 5;
  p: number = 1;
  startIndex: number;
  BusinessUnit: any;
  BusinessName: any;
  OrganizationName: any;
  businessgroup: any;
  businessunit: any;
  organization: any;
  isprocessing1=false;
  submitted: boolean = false;
  error: any = false;
  error_msg: any;
  empLevel: any = [];
  success = false;
  sucess_msg: any;
  errorMsg: any;
  errormessagejson: any;
  importerror: boolean = false;
  reportData: any;
  emplevelId: any;
  userdata: any = [];
  level: any;
  level1: any;
   buslevelName: number | null;
  entityId: any;
  resultData: any;
  buslevelName1: number;
   empLevelName1: string | undefined;



  constructor(private fb: FormBuilder,
    private Employeeservice: EmployeeService,

    private payrollservice: PayrollService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
  ) { }

  ngOnInit(): void {

 


    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log("Org id:",this.orgId );
    this.entityId = this.usertData.entity.entityId;
    console.log("Entity id:",this.entityId );


    this.emplevelId = this.ActivatedRoute.snapshot.params.employeelevelAssignId;
    console.log(this.emplevelId);

    
    this.employeeform = this.fb.group({
      assignEmployee: [null, [Validators.required]],
      // period: [null, [Validators.required]],
      employeecode: [null, [Validators.required]],
      LevelName: this.fb.array([], [Validators.required]),
      // employeeId:['']
    })


    if (this.emplevelId) {
      console.log("if patch");
      // this.selectedEmployeeData=[];
      this.loader=true;
      this.payrollservice.getPatchEmployeeLevel(this.emplevelId).subscribe((userdata: any) => {
        console.log('userdata', userdata)
        this.userdata = userdata;
        // this.userdata = Array.isArray(userdata) ? userdata : [userdata];


              console.log(this.userdata.businessgroup && this.userdata.businessgroup.businessGroupId);
              if (this.userdata.businessgroup && this.userdata.businessgroup.businessGroupId) {
                this.buslevelName = 1; 
                this.getBusinessGroupData();

              } else if (this.userdata.businessunit && this.userdata.businessunit.businessUnitId) {
                this.buslevelName = 2; 
                this.getBusinessUnit();

              } else if (this.userdata.organization && this.userdata.organization.organizationId) {
                this.buslevelName = 3; 
                this.getOrganizationData();
              }

            console.log(this.buslevelName);

            let empLevelName: string | undefined;

            if (this.userdata.businessgroup && this.userdata.businessgroup.businessGroupId) {
              empLevelName = this.userdata.businessgroup.businessGroupId;
            } else if (this.userdata.businessunit && this.userdata.businessunit.businessUnitId) {
            empLevelName = this.userdata.businessunit.businessUnitId;
               } else if (this.userdata.organization && this.userdata.organization.organizationId) {
            empLevelName = this.userdata.organization.organizationId;
           }
          console.log(empLevelName)

          

        this.employeeform.patchValue({
          assignEmployee: this.buslevelName,
          employeecode: this.userdata.employee.employeeNumber,
          // LevelName: empLevelName
          
        });
        console.log("this.postForm", this.employeeform)
        this.loader=false;
        this.showtable = true;

        const updateData = {
          employeeId: this.userdata.employee.employeeId,
          employeeNumber: this.userdata.employee.employeeNumber,
          firstName: this.userdata.employee.firstName,
          lastName: this.userdata.employee.lastName,
          LevelName: empLevelName
        }
        console.log("updateData", updateData);
        this.selectedEmployeeData.push(updateData);

        console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
        const rowsArray = this.employeeform.get('LevelName') as FormArray;
        console.log("Data:"+rowsArray);
        this.addRow();
        rowsArray.at(0).patchValue({
          employeeId: userdata.employee.employeeId,
          employeeNumber: userdata.employee.employeeNumber,
          firstName: userdata.employee.firstName,
          lastName: userdata.employee.lastName,
          LevelName: empLevelName,
          employeelevelAssignId: this.userdata.employeelevelAssignId
        })

        

        // this.employeeId = userdata.employee.employeeId;
        // this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
        //   console.log(resultData);
          

        //   this.settingsService.getFinancialYearId(this.employeeform.value.financialyear).subscribe((resultData1: any) => {
        //     console.log("resultData1", resultData1.finanicalPeriod)

        //     this.finPeriodList = resultData1.finanicalPeriod;
        //     this.leaveForm.get('period').patchValue(this.userdata.finanicalPeriod.financialPeriodId);
        //     console.log("this.financialPeriodList", this.finPeriodList);

        //   });

        // });

      })
      // }
      
    }

  }


  
  addRow() {
    const control = this.employeeform.get('LevelName') as FormArray;
    control.push(this.initRows());
    console.log(control);
  }

  initRows() {
    return this.fb.group({
      LevelName: ['', [Validators.required]],
      firstName: [''],
      lastName: [''],
      employeeId: [''],
      employeeNumber: [''],
      employeelevelAssignId: ['']
    });
  }

  notZeroValidatorAsync(control:AbstractControl) {
    const value=control.value;
    // const regex=/^(?!0\.0$)\d+(\.\d)?$/;
    // const regex=/^(?:\d{1,2}(\.\d)?|\d{1,2})$/;
    // const regex=/^(?:\d{1,2}(\.\d)?|\d{1,2})$/;
    const regex = /^(?:[1-9]|[12][0-9]|30)$/;
    return new Promise((resolve) => {
    if (!regex.test(value.toString()) ) 
    {
    console.log("notVaild");
    resolve({ notZero:true }); // Return an object with a custom error key if value is zero
    } 
    else 
    {
    console.log("Vaild");
    resolve(null); // Return null if the value is not zero, indicating the control is valid
    }
      });
    }

  pageChanges() {
    console.log("page changes");
    this.p = 1;
  }



  empSelect(emp) {
    // this.employeeform.get("employeecode").reset();
    this.employeeId = emp.employeeId;
    this.firstname = emp.firstName;
    this.lastname = emp.lastName;
    this.empnumber = emp.employeeNumber;
    console.log(this.firstname ,this.lastname ,  this.empnumber);
    console.log("select", this.selectedEmployeeData);
    let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
    this.showtable = true;
    if (!hasemployee) {
      this.showtable = true;
      this.selectedEmployeeData.push(emp);

      const rowsArray = this.employeeform.get('LevelName') as FormArray;
      // rowsArray.clear();
      console.log("control", rowsArray);
      rowsArray.push(this.formBuilder.group({
        employeeId: [this.employeeId],
        employeeNumber: [this.empnumber],
        firstName: [this.firstname],
        lastName: [this.lastname],
        // empleave: ['', [Validators.required], this.notZeroValidatorAsync],
        LevelName: ['', [Validators.required]],
      }));
    }

    if (this.employeeId) {
      this.loader = true;
      this.Employeeservice.getEmployeeById(this.employeeId).subscribe(
        (resultData: any) => {
          //console.log(resultData);
          this.loader = false;
          this.resultData = resultData;
          console.log('resultData:', this.resultData);

          if (this.resultData.businessgroup && this.resultData.businessgroup.businessGroupId) {
            this.buslevelName1 = 1; 
            this.getBusinessGroupData();

          }

          if (this.resultData.businessgroup && this.resultData.businessgroup.businessGroupId) {
            this.empLevelName1 = this.resultData.businessgroup.businessGroupId;
          }
        
          console.log(this.empLevelName1);
          console.log('resultData:', this.resultData);
          console.log('resultData:', this.resultData?.businessgroup?.businessGroupId);

        console.log(this.employeeform)
         this.employeeform.patchValue({
          assignEmployee: this.buslevelName1,
         });
         const rowsArray = this.employeeform.get('LevelName') as FormArray;
         console.log("Data:"+rowsArray);
        //  this.addRow();
         rowsArray.at(0).patchValue({
          
           LevelName: this.empLevelName1,
         })
        //  this.showtable = true;

        //  const updateData1 = {
        //    employeeId: this.resultData.employeeId,
        //    employeeNumber: this.resultData.employeeNumber,
        //    firstName: this.resultData.firstName,
        //    lastName: this.resultData.lastName,
        //   //  LevelName: empLevelName
        //  }
        //  console.log("updateData", updateData1);
        //  this.selectedEmployeeData.push(updateData1);
 
        //  console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
        //  const rowsArray = this.employeeform.get('LevelName') as FormArray;
        //  console.log("Data:"+rowsArray);
        //  this.addRow();
        //  rowsArray.at(0).patchValue({
        //    employeeId: resultData.employeeId,
        //    employeeNumber: resultData.employeeNumber,
        //    firstName: resultData.firstName,
        //    lastName: resultData.lastName,
        //    LevelName: empLevelName1,
        //   //  employeelevelAssignId: this.resultData.employeelevelAssignId
        //  })
          
        }
      );
    }

    // }

  }


  empAllSelect() {
    // this.empList=[];
    // this.selectedEmployeeData=[];
    console.log("all Employee");
    if (this.employeeform.value.employeecode == "All") {
      this.loader=true;

      console.log("select", this.selectedEmployeeData);
      this.payrollservice
        .getAllEmployeeByEntity(this.entityId).subscribe((result: any) => {
          console.log(result);
          if (result.length == 0) {
            console.log("noData");
            // this.empList.length = 0;
          }
          // this.empList=result;
          console.log("All", this.empList);
          result.forEach(element => {
            console.log(element);
            let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
            this.showtable = true;
            console.log(hasemployee);
            if (!hasemployee) {
              this.selectedEmployeeData.push(element);
              console.log('selectedEmployeeData', this.selectedEmployeeData);


              const rowsArray = this.employeeform.get('LevelName') as FormArray;

              rowsArray.push(this.formBuilder.group({
                employeeId: [element.employeeId],
                employeeNumber: [element.employeeNumber],
                firstName: [element.firstName],
                lastName: [element.lastName],
                // empleave: ['', [Validators.required], this.notZeroValidatorAsync],
                LevelName: ['', [Validators.required]],

              }));
            }
          });
          this.loader=false;

        })

    }
  }

  // empSearch(event) {
  //   this.empList = [];
  //   this.empCode = false;

  //   console.log(event.target.value);
  //   this.userId = event.target.value;
  //   console.log(this.userId);

  //   this.entityId = this.usertData.entity.entityId;
  //   console.log("Entity id:",this.entityId );

  //   this.orgId = this.usertData.organization.organizationId;
  //   console.log("Org id:",this.orgId );

  //   if (!this.userId || this.userId === "") {
  //     // this.selectedEmployeeData=[];
  //     this.dataArray=[];
  //     this.getFormControls.controls =[];
  //     this.selectedEmployeeData = [];
  //     console.log("User ID is invalid, skipping API call.");
  //     this.loader = false;  
  //     return;  
  //   }
  //   // Employee List Api
  //   // this.empList.length == 0;
  //   if(event.target.value.length >= 2)
  //   {
  //     if(this.employeelistsub)
  //     {
  //       this.employeelistsub.unsubscribe();
  //     }
  //     this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
  //       .pipe(
  //         takeUntil(this.unsubscribe$), // Cancel previous request
  //         switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
  //       )
  //       .subscribe((result: any) => {
  //         this.empList.length = 0;

  //         if (result.statusCode) {
  //           console.log(result.statusCode);
  //           this.empList.length = 0;
  //         } else {
  //           this.empList = result;
  //         }
  //       });
	// }
	
  // }

  empSearch(event) {
    this.empList = [];
    this.empCode = false;

    console.log(event.target.value);
    this.userId = event.target.value;
    console.log(this.userId);

    this.entityId = this.usertData.entity.entityId;
    console.log("Entity id:", this.entityId);

    this.orgId = this.usertData.organization.organizationId;
    console.log("Org id:", this.orgId);

    if (!this.userId || this.userId === "") {
        this.dataArray = [];
        this.getFormControls.controls = [];
        this.selectedEmployeeData = [];
        console.log("User ID is invalid, skipping API call.");
        this.loader = false;
        return;
    }

    // Employee List API
    if (event.target.value.length >= 2) {
        if (this.employeelistsub) {
            this.employeelistsub.unsubscribe();
        }

        // Call different APIs based on whether orgId is null or not
        if (this.orgId != null || this.orgId != undefined ) {
            this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId, this.orgId)
                .pipe(
                    takeUntil(this.unsubscribe$), // Cancel previous request
                    switchMap(() => this.payrollservice.getEmployeeNameList(this.userId, this.orgId))
                )
                .subscribe((result: any) => {
                    this.empList.length = 0;

                    if (result.statusCode) {
                        console.log(result.statusCode);
                        this.empList.length = 0;
                    } else {
                        this.empList = result;
                    }
                });
        } else {
            this.employeelistsub = this.payrollservice.getEmployeeNameListEntity(this.userId, this.entityId)
                .pipe(
                    takeUntil(this.unsubscribe$), // Cancel previous request
                    switchMap(() => this.payrollservice.getEmployeeNameListEntity(this.userId, this.entityId))
                )
                .subscribe((result: any) => {
                    this.empList.length = 0;

                    if (result.statusCode) {
                        console.log(result.statusCode);
                        this.empList.length = 0;
                    } else {
                        this.empList = result;
                    }
                });
        }
    }
}

  

  onKeyDown(event: KeyboardEvent): void {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  }


  get getFormControls() {
    this.dataArray = [];
    const control = this.employeeform.get('LevelName') as FormArray;
    // console.log(control);
    this.length = control.length;
    if (control != null) {
      control.controls.forEach((formGroup: FormGroup) => {
        const data = formGroup.value;
        this.dataArray.push(data);
      });
      // console.log(this.dataArray);
      return control;
    }
  }


  deleteemployee(index) {
    console.log("index::",index)
    
    console.log(index + index);
    if (index != -1) 
    {
      const rowsArray = this.employeeform.get('LevelName') as FormArray;
      console.log(rowsArray);
      // for (let i = 0; i < rowsArray.length; i++) {
      const emprattingId = rowsArray.at(index).value.employeeId;
      console.log('emprattingId', emprattingId);
      console.log("Id", rowsArray.at(index).value.employeeLeaveId);
      if (rowsArray.at(index).value.employeeLeaveId == undefined) 
      {
        rowsArray.removeAt(index);
        this.selectedEmployeeData.splice(index, 1);
        console.log(this.selectedEmployeeData.length);
      }
    }
    if(index==0){
      this.showtable=false;
    }
  }


  onCityChange(event: MatSelectChange) {
    console.log(event)
    const selectedValue = event.value;
    console.log(selectedValue);

    if (selectedValue === 1) {
      this.getBusinessGroupData();
    } else if (selectedValue === 2) {
      this.getBusinessUnit();
    } else if (selectedValue === 3) {
      this.getOrganizationData();
    }

    const empLeavesControls = this.employeeform.get('LevelName') as FormArray;
    empLeavesControls.controls.forEach((control: any) => {
      control.patchValue({
        LevelName: null, // Clear the LevelName field
      });
    });
  }

  passedId(row){
    console.log(row)
    console.log(row.businessGroupId);
    console.log(row.businessUnitId);
    console.log(row.organizationId);
   this. businessgroup=row.businessGroupId;
   this.businessunit=row.businessUnitId;
   this.organization=row.organizationId;



  }

  getBusinessGroupData() {
    this.payrollservice.getBusinessGroup(this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      this.BusinessUnit=data;
      // this.BusinessUnit = Array.isArray(data) ? data : [data];
      console.log(this.BusinessUnit);
      console.log(this.BusinessUnit.businessGroupId);

      
      
    });
    this.BusinessName=[];
    this.OrganizationName=[];

  }

  getBusinessUnit() {
    this.payrollservice.getBusinessUnit(this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      this.BusinessName=data;
      this.BusinessUnit=[];
      this.OrganizationName=[];

      
    });
  }

  getOrganizationData() {

    this.payrollservice.getOrganization(this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      this.OrganizationName=data;
      this.BusinessUnit=[];
      this.BusinessName=[];
  });
}

onPageChange(event: any): void {
  this.p = event;
  this.updatePagedData();
}

updatePagedData(): void {
  const startIndex = (this.p - 1) * this.pagePerItem;
  const endIndex = startIndex + this.pagePerItem;
  console.log(startIndex);
  this.startIndex = startIndex;
  console.log(this.startIndex);
  console.log(endIndex);
}

close() {
  console.log("close");
  this.router.navigateByUrl('/home/assignlist');
}



onSubmit() 
{
  this.isprocessing1 = true; // Disable the button
 

  if (this.employeeform.value.employeecode !== undefined 
    && this.employeeform.value.employeecode !== null 
    && this.employeeform.value.employeecode !== '' 
    && this.employeeform.value.employeecode !== 'All') {
  
//   if ((this.employee != this.employeeform.value.employeecode) || (this.employeeId == null)) {
//     window.alert('Please select a valid employee from the dropdown.');
//     return;
//     }
  }



  console.log("submit");
  this.submitted = true;
  const empLeavesControls = this.employeeform.get('LevelName') as FormArray;
 
  console.log(empLeavesControls);
  if (this.employeeform.invalid) {
    this.isprocessing1 = false; // Enable the button if the form is invalid

    console.log("invalid");
    if(this.employeeform.value.employeecode.length!=0&&empLeavesControls.length==0)
    {
      console.log("inside form:"+this.employeeform.value.employeecode);
      this.employeeform.patchValue({
        employeecode:null
      });
      console.log(this.employeeform);
      this.error = true;
      this.error_msg = "Please Select Employee";
      setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
    }
    else
    {
      console.log("Employee Code Null")
    }
   
    return;
  }

  if (this.emplevelId) //Update 
  {
    // this.selectedEmployeeData =[];
    // this.empLevel = [];
    // console.log("Empty",this.empRatings);
    console.log("update", this.emplevelId);
    console.log("this.selectedEmployeeData", this.selectedEmployeeData);
    console.log("employeeLeaveControls", empLeavesControls)
    empLeavesControls.controls.forEach((control: any) => {
      console.log("employeeLeaveControls", empLeavesControls)

      // Check if any Leave Days field is empty
      if (control.value.LevelName === '') {
        console.log("Leave Days Should not be empty");
        this.error_msg = "Leave Days Should not be empty";
        return;
      }
      console.log(this.businessgroup);
      console.log(this.employeeform);
      console.log(control.value.LevelName);
            console.log(control.value.assignEmployee);
            console.log(this.employeeform.value.assignEmployee)

      // console.log(control.value.businessunit.businessUnitId);
      // console.log(control.value.organization.organizationId);
      this.level=control.value.LevelName;
      this.level1=this.employeeform.value.assignEmployee;
      console.log(this.level1)

      const updateEmployeeLeave:any = {
        // employeelevelAssignId: control.value.employeelevelAssignId || this.emplevelId,
        employee: {
          employeeId: control.value.employeeId
        },
        businessgroup: this.level1 === 1 ? { businessGroupId: this.level } : null,
        businessunit: this.level1 === 2 ? { businessUnitId: this.level } : null,
        organization: this.level1=== 3 ? { organizationId: this.level } : null,
      };
    
      // Assign updateEmployeeLeave to empLevel as an object, not an array
      this.empLevel = updateEmployeeLeave;
    });
    

    console.log('employeeLevelArray', this.empLevel);
    // return;
    this.payrollservice.updateEmployeeLevel(this.emplevelId, this.empLevel).subscribe((result: any) => {
      if (result.statusCode == 200) {
        this.success = true;
        this.sucess_msg = result.description;
        // this.searchSubmit();
        setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
        
        this.submitted = false;
        setTimeout(() => {
          this.router.navigate(["home/assignlist"])
        }, redirectMsgTimeOut)
      }
      else {
     
        console.log("err1", result.statusCode);
        this.error = true;
        this.error_msg = result.description;
        this.errormessagejson = result;
        
        console.log('this.errormessagejson', this.errormessagejson);
        this.importerror = true;
      }
      this.isprocessing1=false;
    },
      err => {
        
        console.log("code1", err.statusCode);
        setTimeout(() => {
          this.error = true;
          this.errorMsg = err.error.description;
        }, redirectMsgTimeOut);
        this.isprocessing1=false;

        // }

      })
  }
  else {
    this.selectedEmployeeData =[];
    this.empLevel = [];
    console.log("create");
    console.log(empLeavesControls.value);
    console.log("this.selectedEmployeeData", this.selectedEmployeeData);
    this.reportData = empLeavesControls.value;
    console.log("this.reportDate", this.reportData);

    for (let i = 0; i < this.reportData.length; i++) {
      const employeeId = this.reportData.at(i).employeeId;
      console.log('employeeId', employeeId);
      console.log(this.empLevelName1)

     


      const createEmployeeLevel = {
  employee: {
    employeeId: this.reportData.at(i).employeeId
  },
  businessgroup: this.businessgroup!=null ? { businessGroupId: this.businessgroup }: null,
  businessunit: this.businessunit != null ? { businessUnitId: this.businessunit } : null,
  organization: this.organization != null ? { organizationId: this.organization } : null
};
      this.empLevel.push(createEmployeeLevel);
      console.log(this.empLevel);
    }
    
    // this.empLevel = empLevel;
    // return
    this.payrollservice.createEmployeeLevel(this.empLevel).subscribe((result: any) => {
      if (result.statusCode == 200) {
        console.log(result.description);
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => { this.success = false; this.sucess_msg = ''  }, redirectMsgTimeOut)
        // this.leaveForm.reset();
        this.submitted = false;
        // this.searchSubmit();
        setTimeout(() => {
          this.router.navigate(["home/assignlist"])
        }, redirectMsgTimeOut)
      }
      else  {
        console.log(result);
        console.log("err1", result.statusCode);
        this.error = true;
        // this.showtable = true;
        console.log(result.description);
        this.error_msg = result.description;
        this.errormessagejson = result;
        setTimeout(()=>{
          this.error = false;
          this.error_msg = '';
        },3000)
        setTimeout(() => {
          this.router.navigate(["home/assignlist"])
        }, redirectMsgTimeOut)
        console.log('this.errormessagejson', this.errormessagejson);
        this.importerror = true;
        // this.employeeform.reset();
        // this.empList = [];
        
        
       
       
      }
      this.isprocessing1=false;
      


    }, err => {
      console.log("code", err);
      console.log("code", err.statusCode);
      
        this.error = true;
        this.errorMsg = err.error.description;
      
      setTimeout(()=>{
        this.error = false;
        this.error_msg = '';
      },3000);
      this.isprocessing1=false;

      // }

    })
  }
}

  

}
