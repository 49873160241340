import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SuperadminService } from '../providers/superadmin.service';
import { DeleteClientSupplierComponent } from '../delete-client-supplier/delete-client-supplier.component';
import Swal from 'sweetalert2';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { PayrollService } from '../providers/payroll.service';

@Component({
  selector: 'app-assignclient-list',
  templateUrl: './assignclient-list.component.html',
  styleUrls: ['./assignclient-list.component.scss']
})
export class AssignclientListComponent implements OnInit {
  query:any = '';
  p:any;
  mySelect:any =5;
  beforepage:any;
  list_loader: boolean =true;
  approverList: any =[];
  nodata: boolean =false;
  search: any;
  organzationid: string;
  pagePerItem:any=5;
nodatafound: any;
btnDisables: any;
searchForm: FormGroup;
clientname: any;
supplierclientlist:any=[]
tableShow: any;
sucess_msg:any;
success:boolean=false;
error:boolean=false;
error_msg:any
storeSupplier:any;
storeclientId:any
supplierList:any;
  storeEntityId: any;
approvertype: any;
submitted1: any;
ApproverList: any;
selectedEmployeeData: any;
clientSupform:FormGroup
data: any;
btnDisable:boolean=false;
  clientKeywords: any;
  submitted:boolean=false;
  supplierKeywords:any
clientList: any=[];
  postdatamultiple: any[];
  employeeNotFound: boolean;
  storeEmpNumber: any;
  employeelistsub: any;
  disableFeildforAll: boolean=false;
  empolyeereadonly: boolean=false;
  readonlyDisable: boolean=false;
  employeeId: any;
  employee: string;
  clientassignform: FormGroup;
employeeDisabled: any;
empolyeeapproverexist: any;
showForm: any;
showupdate:boolean=false;
private unsubscribe$: Subject<void> = new Subject<void>();
showtable:boolean=false;
supplierClientForm:FormGroup
readonly: any;
  storeSuppClientAssignId: any;
  loader: boolean=false;
  storeClientID:any
  storeClientName: any;
  constructor(
    private router : Router,
    private approverservice :GlobalserviceService,
    private dialog : MatDialog,
    private formBulider:FormBuilder,
    private ActivatedRoute: ActivatedRoute,
    private SuperadminService:SuperadminService,
    private payrollservice:PayrollService
  ) { }

  ngOnInit(): void {
    const userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.storeEntityId=userdata.entity.entityId

    this.searchForm=this.formBulider.group({
      client:[''],
      supplier:['']
    })
    const data = JSON.parse(localStorage.getItem('enoteUserData'))
    this.organzationid = data.organization.organizationId;
    console.log("organzationid" +this.organzationid);

    
       
        this.SuperadminService.getActiveSupplierEntity(this.storeEntityId).subscribe((response:any)=>{
          console.log("response:",response);
          
          if(response.errorCode==204){
            this.nodata=true;
            this.showtable=true;
            this.supplierclientlist=[]
          }
          else{
            this.tableShow=true;

            this.supplierList=response;

          }
        })
       
        this.getAllclientSupplierList()
}

clearForm(){
  this.supplierClientForm.controls['supplier'].reset();
  this.submitted1=true

}
updateassignClientSupplier(client: any) {
  this.submitted1=false;
  console.log("single record::",client);
  this.storeSuppClientAssignId = client.clientSupplierId;
  console.log('storeEmpClientAssignId:', this.storeSuppClientAssignId);
  this.showupdate = true;
  this.showForm=true;

  this.list_loader=true;
  // this.payrollservice.getClientNameListBasedEntity(this.storeEntityId).subscribe((result: any) => {
  //   this.clientList = result.filter(client => client?.status?.listTypeValueId == 1);;
  //   console.log(this.clientList);
  // })

  this.SuperadminService.getActiveSupplierEntity(this.storeEntityId).subscribe((response:any)=> {
      // this.clientList = d.filter(client => client?.status?.listTypeValueId == 1);;

      console.log(response);
      this.list_loader=false;
      this.supplierList = response;
      console.log(this.supplierList);
    }
  );
 

  if (this.storeSuppClientAssignId) {
    this.list_loader = true;

    this.SuperadminService
      .getsupClientAssignGet(this.storeSuppClientAssignId)
      .subscribe((response: any) => {
        console.log('edit response::', response);
        this.list_loader = false;
        this.employeeDisabled=true;
        this.readonlyDisable=true

        this.storeClientID= response.client.clientId;
        this.storeClientName=response.client.clientName
        console.log("client Id::",this.storeClientID);
        


        this.supplierClientForm.patchValue({
          clientNames:this.storeClientName,
          // clientCode:response.client?.clientCode
          supplier: response.supplier?.supplierId,
        });
        this.empolyeereadonly = true;
        this.readonly = true;
      });
  }
  this.supplierClientForm = this.formBulider.group({
    clientSupplierId: this.storeSuppClientAssignId,
    clientNames: [null, Validators.required],
    supplier: [null, Validators.required],
  });
  // this.storeSuppClientAssignId = this.ActivatedRoute.snapshot.params.id;
  console.log( " this.storeSuppClientAssignId ::::",this.storeSuppClientAssignId );
  
  // this.loader=true;
}
clearEmpArray() {
  this.clientList = [];
}
empSearch(event){ 
  // this.empolyeereadonly=false;
  this.postdatamultiple = [];
  this.employeeNotFound = false; // Reset the no employee flag
  console.log(event.target.value);
  var employeenumber = event.target.value;
  this.storeEmpNumber=employeenumber
  console.log(employeenumber);
  
  // Employee List API
  if(employeenumber.length >= 2){
    if(this.employeelistsub) {
      this.employeelistsub.unsubscribe();
    }
    this.employeelistsub = this.SuperadminService.getSeachClientData(this.storeEmpNumber,this.storeEntityId)
      .pipe(
        takeUntil(this.unsubscribe$), // Cancel previous request
        switchMap(() => this.SuperadminService.getSeachClientData(this.storeEmpNumber,this.storeEntityId))
      )
      .subscribe((result: any) => {
        this.clientList = [];
        
        if (result.statusCode) {
          console.log(result.statusCode);
          this.clientList = [];
          this.employeeNotFound = true; // Set the flag if status code indicates no employees
        } else {
          this.clientList = result;
          if (this.clientList.length === 0) {
            this.employeeNotFound = true; // Set the flag if the list is empty
          }
        }
      

      })
    }
  }

noEmployeeFound() {
  this.employeeNotFound = false;
  // this.selectedemployeeId = undefined;
  // this.selectedemployeeId = undefined; // Set selectedemployeeId to null
  this.employeeId = undefined;
  // this.employeeProjects.length = 0;
  this.employee = undefined;
  // this.employeeNumber = undefined;
  this.employeeNotFound=false
}
empSelect(emp)
{ 
  this.postdatamultiple =[];
  // this.employeelistsub=[]

  this.disableFeildforAll=false;
  this.empolyeereadonly=false;
  this.readonlyDisable=false;
  console.log("emp",emp);

  this.employeeId=emp.clientId;
  this.employee=emp.clientCode+"-"+emp.clientName;
  console.log("client wt its::",this.employee);
  if(this.storeSuppClientAssignId){
    console.log("if");
    this.clientassignform.patchValue({
      clientNames: this.employee 
   });
  }else{
    console.log("else");
    this.clientassignform.patchValue({
      client: null
     });
  // console.log("this.selectedEmployeeData", this.selectedEmployeeData);
  
      let  hasemployee =   this.selectedEmployeeData.some(e=> e.clientId == emp.clientId);
        if(!hasemployee)
        {
          this.selectedEmployeeData.push(emp);
          console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
        }
        console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
  }
  if (this.selectedEmployeeData.length >= 1) {
    this.clientassignform.get('client')?.clearValidators();
  } else {
    this.clientassignform.get('client')?.setValidators([Validators.required]);
  }
  this.clientassignform.get('client')?.updateValueAndValidity();

}
submit(){
  this.submitted1=true;
  console.log("cliectassignId::",this.storeSuppClientAssignId);
  
  if(this.supplierClientForm.invalid){
    return;
  }
  const formValue=this.supplierClientForm.value;

  // if(formValue.clientSupplierId){
  if(this.storeSuppClientAssignId){

    var updatePayload={
      client:{
       clientId: this.storeClientID
      },
      supplier:{
        supplierId:formValue.supplier,
    },
    clientSupplierId:this.storeSuppClientAssignId,
    clientSupplierStatus:"Active"

  }
  console.log("payload::",updatePayload);
// return
this.btnDisable=true
  this.SuperadminService.updateclientSupp( formValue.clientSupplierId,updatePayload).subscribe((response:any)=>{
    console.log("response:",response);
    this.btnDisable=false

    if (response.statusCode == 200) {
      this.success = true;
      this.btnDisable=true
      this.sucess_msg = response.description;
      setTimeout(() => {
        this.success = false;
        this.getAllclientSupplierList()
        this.showForm=false;
        this.btnDisable=false

        this.supplierClientForm.reset();
      }, 5000);
     
    }
    else {
      if(response.error.statusCode==409){
        this.error = true;
        this.btnDisable=false

        this.error_msg=response.error.message
        setTimeout(() => {
          this.error = false;
          this.btnDisable=false

        }, 5000);
      }
      this.showForm=true;

    }
  },(err:any)=>{
    console.log("error:",err);
    this.btnDisable=false

    if(err.error.statusCode==409){
      this.error = true;
      this.btnDisable=false

        this.error_msg=err.error.message
        setTimeout(() => {
          this.error = false;
        }, 5000);
        this.showForm=true;

      }
    }
  )

  
}

}



getAllclientSupplierList(){
  const data = JSON.parse(localStorage.getItem('enoteUserData'))
  this.tableShow=false;
  this.list_loader=true
  this.showtable=false;

  this.SuperadminService.getAllClientSupplierList(this.storeEntityId).subscribe(
    (d: any) => {
      console.log(d);
      this.list_loader = false;
      this.showtable=true
      this.showtable=true
    
      this.nodata = false;
      console.log(this.list_loader);
      console.log(this.supplierclientlist);

      if(d.errorCode==204){
        console.log("npo data entered");
        
        this.nodata=true;
        this.showtable=true;
        this.supplierclientlist=[]
      }
      else{
        this.supplierclientlist = d;
        this.supplierclientlist = Array.isArray(d) ? d: [d];
      }
      // if(this.supplierclientlist.length == 0)
      // {
      //   this.nodatafound = true;
      //   console.log("nodata");
      // }
      // if(data.statusCode)
      // {
      //   this.nodatafound = true;
      //   console.log("nodata");
      // }
    },
    (error:any) => {
      
      this.list_loader = false;
      this.nodata = true;
      console.log("error",error)
      if(error.message == 'No Data'){
        this.nodata = true;
        console.log("404")
      }
    }
  );       
  
}
setSupnull(){
  this.searchForm.controls['supplier']=null
}
openDialog(e){
  // console.log("supplier Id::",supplierId);
  // console.log("client Id::",clientId);
  console.log("e",e);

  this.storeclientId=e.clientId;
  this.storeSupplier=e.supplierId
  
  
  
  Swal.fire({
    title: 'Are you sure? Do you want to Delete',

    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Delete!',
  }).then((result) => {
    console.log(result);

    if(!result.dismiss){
      console.log("not cancel,its delete");
      


    

    // return

    this.SuperadminService.deleteClientSup(this.storeclientId,this.storeSupplier).subscribe((response:any)=>{
      console.log("response::",response);
      if(response.statusCode==200){
        this.success=true;
        this.sucess_msg=response.message

        setTimeout(()=>{
          this.success=false;
          this.sucess_msg=false;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl);
            this.getAllclientSupplierList();
        });
        },5000)
      }
      
    },
  (err:any)=>{
    this.error=true;
    this.error_msg=err.error.message;
    setTimeout(()=>{
      this.error=false;
      
    },5000)
  }
)
  }
    
    // console.log(' this.empId', this.empId);

    // console.log("i before::",i)
  })

}

searchData(){

 console.log("client",this.searchForm.controls['client'].value);
 console.log("supplier:",this.searchForm.controls['supplier']?.value);

 this.clientKeywords=this.searchForm.controls['client'].value;
 this.supplierKeywords=this.searchForm.controls['supplier'].value;

 if(this.clientKeywords=='' && this.supplierKeywords==''){
  alert("Please Type Keywords in any one of the Field")
  return;
 }

 if(this.clientKeywords!=='' && this.supplierKeywords!==''){
  console.log("both calling!!");

  this.list_loader=true;
  this.tableShow=false

  this.SuperadminService.BothClientSupplier(this.clientKeywords,this.supplierKeywords,this.storeEntityId).subscribe((response:any)=>{
    console.log(response);
    this.supplierclientlist=response;

    if(this.supplierclientlist.statusCode==200){
      this.tableShow=true;
      this.list_loader=false;
      this.nodatafound=false
    }
   

    
  })
  
 }
 else if(this.clientKeywords!=='' && this.supplierKeywords==''){
  console.log("only client calling!!");
  this.SuperadminService.onlyClientAssign(this.clientKeywords,this.storeEntityId).subscribe((response:any)=>{
    console.log(response);
    this.supplierclientlist=response;

    if(this.supplierclientlist.statusCode==200){
      this.tableShow=true;
      this.list_loader=false;
      this.nodatafound=false
    }
   

    
  })
  
 }
 else if(this.clientKeywords=='' && this.supplierKeywords!==''){
  console.log("only supplier calling!!");
  this.SuperadminService.onlySuppAssign(this.supplierKeywords,this.storeEntityId).subscribe((response:any)=>{
    console.log(response);
    this.supplierclientlist=response;

    if(this.supplierclientlist.statusCode==200){
      this.tableShow=true;
      this.list_loader=false;
      this.nodatafound=false
    }
   

    
  })
  
 }
 else{
  console.log("all condition came out!!");
  
 }
 

}
get f() {
  return this.supplierClientForm.controls;
}
updateStatusClient(id,status)
{
  this.SuperadminService.updateClientStatus(id,status).subscribe((result:any) =>
  {
    console.log("result:",result);
    
    if(result.statusCode == 200)
    {
      this.success = true;
      this.sucess_msg = result.message;
      // this.getAllClientList();
      // const updatedRow = this.clientList?.find((row) => row.clientId === id);
      // if (updatedRow) {
      //   updatedRow.status.listTypeValueName = status; // Update the status in the current data
      // }
      setTimeout(() => {this.success  = false;},5000)  
    }
    else
    {
      console.log("error came");
      
      this.error = true;
      this.error_msg = result.description;
      setTimeout(() => {this.error = false}, 5000)
    }
  })
}
showChanges() {
  console.log('show Change');
  this.p = 1;
}

supplierChange(event: Event) {
  const target = event.target as HTMLSelectElement;
  const value = target.value;
  const text = target.options[target.selectedIndex].text;

  let client = {
    value: value,
    text: text,
  };

  console.log(client);
  // this.clientDetails = client.text;
  // console.log(this.clientDetails);
}
clearforms(){

  // this.getAllclientSupplierList()
  this.nodatafound=false;
  this.tableShow=true;
  this.SuperadminService.getAllClientSupplierList(this.storeEntityId).subscribe(
    (d: any) => {
      console.log(d);
      this.list_loader = false;
      this.tableShow=true
      this.supplierclientlist = d;
    })

}
validateInput(event) {
  const textarea = event.target;
  let value = textarea.value;

  // Allow space only if it's not the first character and the previous character is not a space
  if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
    event.preventDefault();
    return;
  }

  // Prevent Enter key, period, comma, and backticks
  if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
    event.preventDefault();
  }

  // Trim spaces, commas, and backticks at the start
  while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
    value = value.trimStart().replace(/^,|^`/, '');
    textarea.value = value;
  }

  // Remove extra spaces between words
  value = value.replace(/\s{2,}/g, ' ');
  textarea.value = value;

  // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
  const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
  const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

  if (datePattern1.test(value) || datePattern2.test(value)) {
    textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
  }
}
preventSpaces(event) {
  if (event.key === ' ') {
    event.preventDefault();
  }
}
Add_approver(){
  this.router.navigate(['home/ClientSup-add']);
}
updateapprover(approverId:any){
  this.router.navigate([`home/ClientSup-edit/${approverId}`]); 
}

changefirstpage() {
  this.p = 1;
  }
sortData(sort: Sort) {
  const data = this.supplierclientlist.slice();
  if (!sort.active || sort.direction === '') {
    this.supplierclientlist = data;
    return;
  }

  this.supplierclientlist = data.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'rulename':
        return this.compare(a.rulename, b.rulename, isAsc);
      default:
        return 0;
    }
  });
}

compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
}
