<!-- <div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Dashboard</span>
            </div>
            <div class="sub-header-buttons"> -->
<!-- <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                    Add Employee
                </a> -->
<!-- </div>
        </div>
    </div>
</div> -->


<div class="common-content">
    <!-- Graph and cards Star -->
    <div class="card-new ">
        <!-- <div class="graph1">
            <img src="assets/uploads/graph.jpg">
        </div> -->
        <div class="dashboard-cards">
            <div class="row">
                <div class="col-md-12">
                    <p style="font-size: x-large; font-weight: 500;">Summary</p>
                </div>

            </div>
            <div class="row ">
                <div class="col-md-3     mt-3">
                    <div class="card">
                        <div class="top-row  p-3 ">
                            <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                            <img src="assets/uploads/Icon 1.png" alt=""
                                style="background-color: #6583EF; border-radius: 30px; height: 30px; width: 30px; padding: 4px;">
                            <span class="card-name1  ">Total Employees <h2 class="count-card ">
                                    {{resultData.activeEmployees + resultData.inActiveEmployees}}</h2></span>

                        </div>
                      
                    </div>
                </div>
                <div class="col-md-3     mt-3">
                    <div class="card">
                        <div class="top-row  p-3 ">
                            <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                            <img src="assets/uploads/Payroll Cost icon.png" alt=""
                                style="background-color: #446C8C; border-radius: 30px; height: 30px; width: 30px; padding: 4px;">
                            <span class="card-name1  ">Total Payroll Cost <h2 class="count-card ">
                                {{this.payrollamount.totalAmount + this.payrollamount.totalDeduction |  amountFormat }}   </h2></span>

                        </div>
                 
                    </div>
                </div>
                <div class="col-md-3     mt-3">
                    <div class="card">
                        <div class="top-row  p-3 ">
                            <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                            <img src="assets/uploads/Net Salary Icon.png" alt=""
                                style="background-color: #F08B14; border-radius: 30px; height: 30px; width: 30px; padding: 4px;">
                            <span class="card-name1  ">Net Salary&nbsp;&nbsp;&nbsp;&nbsp;<h2 class="count-card ">
                                 {{this.payrollamount.totalAmount |  amountFormat }} </h2></span>

                        </div>
                    
                    </div>
                </div>  
                <div class="col-md-3     mt-3">
                    <div class="card">
                        <div class="top-row  p-3 ">
                            <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                            <img src="assets/uploads/deduction icon.png" alt=""
                                style="background-color: #FF7777; border-radius: 30px; height: 30px; width: 30px; padding: 4px;">
                            <span class="card-name1  ">Dedutions&nbsp;&nbsp;&nbsp;&nbsp;<h2 class="count-card ">
                                {{this.payrollamount.totalDeduction |  amountFormat }} </h2></span>

                        </div>
                    
                    </div>
                </div>  
                <!-- <div class="col-md-4 "> 
                    <div class=" ">
                        <div class="top-row ">
                            <mat-icon class="icon-primary">location_city</mat-icon>
                            <span class="card-name1 text-primary">Total Branches <h2 class="count-card text-primary">{{resultData.activeBranches + resultData.inActiveBranches}}</h2></span>

                        </div>
                        <div class="bottom-row text-primary">
                            <div class="row ">
                                <div class="col-6">
                                    <span class="card-name">Active : {{resultData.activeBranches}}</span>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="card-name">Inactive : {{resultData.inActiveBranches}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  -->

                <!-- <div class="col-md-4 mb-4">
                    <div class="bg-light-primary left-section p-4 mb-3">
                        <div class="top-row">
                            <mat-icon class="icon-primary">share</mat-icon>
                            <span class="card-name1 text-primary">Profiles Shared <h2 class="count-card text-primary">{{admindashboard.profilesource}}</h2></span>
                        </div>
                        <div class="bottom-row text-primary">
                            <div class="row">
                                <div class="col-6">
                                    <span class="card-name active-status-db">Active : {{admindashboard.activeBranches}}</span>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="card-name active-status-db">Inactive : {{admindashboard.inActiveBranches}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <!-- <div class="row db-card">
                <div class="col-md-4 mb-4">
                    <div class="bg-light-danger left-section p-4 mt-1">
                        <div class="top-row">
                            <mat-icon class="icon-danger">bar_chart</mat-icon>
                            <span class="card-name1 text-danger">Total Interviews <h2 class="count-card text-danger">{{admindashboard.interviewed + admindashboard.notInterviewed}}</h2></span>
                        </div>
                        <div class="bottom-row text-danger">
                            <div class="row">
                                <div class="col-6">
                                    <span class="card-name">Interviewed :{{admindashboard.interviewed}} </span>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="card-name">Not Interviewed : {{admindashboard.notInterviewed}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="col-md-4 mb-4">
                    <div class="bg-light-danger left-section p-4 mt-1">
                        <div class="top-row">
                            <mat-icon class="icon-danger"> bar_chart</mat-icon>
                        </div>
                        <div class="bottom-row text-danger">
                            <span class="card-name">Item Order</span>
                        </div>
                    </div>
                </div> 

                <div class="col-md-4 mb-4">
                    <div class="bg-light-success left-section p-4 mt-1">
                        <div class="top-row">
                            <mat-icon class="icon-success"> notes</mat-icon>
                        </div>
                        <div class="bottom-row text-success">
                            <span class="card-name">Bug Report</span>
                        </div>
                    </div>
                </div>

            </div> -->
        </div>
        <!-- Graph and cards End -->

        <!-- Todo Start -->
     
    </div>
    <!-- Todo End -->
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
    style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>
