<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Entity</span>
                </div>
            </div>
            <button (click)="Add_client()"  mat-raised-button  class="ml-2 btndesign">
                Add Entity
            </button>
        </div>
    </div>
</section>


<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row mb-3 pl-0">
                                <div class="ol-xl-3 col-lg-3 mb-2">
                                    <label class="col-form-label">Entity Name</label>
                                    <input formControlName="entityName" 
                                    (keydown)="clearTableData()"
                                    type="text" autocomplete="off" class="form-control email-input select-placeholder" value="" placeholder="Entity Name">
                                </div>
                                <div class="ol-xl-3 col-lg-3 mb-3" style="top: 41px;">
                                  
                                    <button type="reset" mat-raised-button
                                    class=" mr-2 clearDesign"
                                  
                                     (click)="clearData()">Clear
                                 </button>
                                 <button
                                 type="submit"
                                 mat-raised-button 
                                 class="  searchDesign"
                               >
                                 Search
                               </button>
                                  </div>
                            </div>
                        </form>
                        
                          <div class="row p-0 m-0"   *ngIf="resultData.length > 5 && resultData .length > 0 && tableShow">
                            <div class="col-xl-12 col-lg-12" style="margin-bottom:12px;"  *ngIf="resultData.length > 5">
                                <div class="select-pagination" style="float: right;">
                                    <span>Show : </span>
                                    <select class="select_list new-select_list listDesign" [(ngModel)]="pagePerItem"   [ngModelOptions]="{ standalone: true }" (change)="changefirstpage()">
                                        <option class="form-control" value="5"> 5</option>
                                        <option class="form-control" value="10">10</option>
                                        <option class="form-control" value="15">15</option>
                                        <option class="form-control" value="20">20</option>
                                        <option class="form-control" value="25">25</option>
                                    </select>
                                </div>
                            </div>  
                          </div>
                    </div>
                </div>
                <div class="grid_view" >
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12"> -->
                            <div class="table">
                                <table matSort   class="table ">
                                    <thead class="t-head">
                                        <tr  id="trDesign">
                                            <!-- <th class="text-center">S.No</th> -->
                                            <th class="text-center" style="border-radius: 15px 0px 0px 0px">Action</th>
                                            <th class="text-center">Entity Name</th>
                                            <th  class="text-center">User Email</th>
                                            <th class="text-center" style="border-radius: 0px 15px 0px 0">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody   style="    border-left: 1px solid #ddd;
                                        border-bottom: 1px solid #ddd;
                                        border-right: 1px solid #ddd;" *ngIf="!nodatafound && tableShow">
                                        <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } let i=index;">
                                            <!-- <td class="text-center">{{i+1}}</td> -->
                                            <td class="text-center" style="margin-left: -24px;">
                                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>

                                                <mat-menu #menu="matMenu" class="controls-list">
                                                    <button mat-menu-item [routerLink]="['/super-admin-home/edit-entity', row.entityId]">
                                                        <mat-icon>edit</mat-icon>
                                                        <span>Edit</span>
                                                    </button>


                                                    <button mat-menu-item *ngIf="row.entityStatus == 'Active'" (click)="updateStatus(row.entityId,'In-active')">
                                                        <mat-icon>done</mat-icon>
                                                        <span style="color:red">Inactive</span>
                                                    </button>

                                                    <button mat-menu-item *ngIf="row.entityStatus == 'In-active'" (click)="updateStatus(row.entityId,'Active')">
                                                        <mat-icon>done</mat-icon>
                                                        <span style="    color: #50cd89 !important;">Active</span>
                                                    </button>
                                                </mat-menu>
                                            </td>


                                          
                                            <td class="text-center">
                                                {{row.entityName ? row.entityName :"-"}}
                                            </td>
                                            <!-- <td class="text-center">
                                                {{row.user.emailId ? row.user.emailId :"-"}}
                                            </td> -->
                                            <td class="text-center">
                                                {{ row.user && row.user.length > 0 ? row.user[0].emailId : "-" }}</td>
                                            <!-- <td class="text-center">
                                                {{row.entityDescription ? row.entityDescription :"-"}}
                                            </td> -->

                                            <td class="active-status text-center">
                                                <span *ngIf="row.entityStatus == 'Active'" class="btn btn-sm btn-block" style="color: #50cd89 !important;">
                                                    {{ row.entityStatus }}
                                                </span>
                                                <span *ngIf="row.entityStatus == 'In-active'" class="btn btn-sm btn-block" style="color: red;">
                                                    {{ row.entityStatus }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd" *ngIf="tableShow">
                                        <tr>
                                          <td
                                            style="
                                               text-align: center;
                                            padding: 10px;
                                            font-size: 19px;
                                            font-weight: 600;
                                            font-family: 'Mulish', sans-serif;
                                            left: 5;
                                            position: relative;
                                           " colspan="8" 
                                            
                                          
                                            *ngIf="
                                              nodatafound"
                                          >
                                            Entity Records Not Found!
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>

                                <!-- <div *ngIf="nodatafound" class="no-data"> -->
                                    <!-- <div *ngIf="resultData?.length == 0" class="no-data"> -->
                                    <!-- <img src="assets/uploads/nodata.png"> -->
                                    <!-- <span class="no-data-found"> No Data Found </span> -->
                                <!-- </div> -->
                            </div>
                        <!-- </div> -->
                    <!-- </div> -->

                </div>
            
                <div class="pagination-content" *ngIf="
                resultData?.length > 5 &&
                resultData.length > 0 && tableShow
                " id="page">
                <pagination-controls *ngIf="resultData .length > 5"
                style="position: relative; float: inline-end; top: 7px" (pageChange)="p = $event" align="end">
                </pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- <div *ngIf="loader" class="align-items-center text-center" >
    <div class="spinner-border table-spinner " style="height:3rem;width:3rem;color: #009ef7;
    position: absolute;
    z-index: 1;
    top: 49%;
    right: 48%;" role="status"></div>
  </div> -->
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>