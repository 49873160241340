<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content headingDesing">
            {{ positionId ? "Edit" : "Add" }} Position
          </span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
          <a
            routerLink="/home/positions"
            mat-raised-button
            title="Back"
            class="ml-2"
          >
            Back
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="resourceform" (ngSubmit)="submitData()">
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Position Name <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control email-input select-placeholder form-control-lg form-control-solid mb-3 mb-lg-0"
                [ngClass]="{ 'is-invalid': submitted }"
                formControlName="positionName"
                placeholder="Enter Position Name"
                style="font-size: 16px !important"
              />

              <div
                class="valid-msg"
                *ngIf="
                  (positionName.invalid && positionName.touched) ||
                  positionName.dirty ||
                  submitted
                "
              >
                <span class="text-danger" *ngIf="positionName.errors?.required"
                  >Please Enter Position Name</span
                >
                <span class="text-danger" *ngIf="positionName.errors?.pattern"
                  >Please Enter Valid Position Name</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Min Experience <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control email-input select-placeholder mb-3 mb-lg-0"
                [ngClass]="{ 'is-invalid': submitted }"
                formControlName="minExperience"
                (keypress)="noNumber($event)"
                (keyup)="keyUpmaxexp($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                maxlength="4"
                placeholder="Enter Min Experience"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (minExperience.invalid && minExperience.touched) ||
                  minExperience.dirty ||
                  submitted
                "
              >
                <span *ngIf="minExperience.errors?.required" class="text-danger"
                  >Please Enter Min Experience</span
                >
                <span *ngIf="minExperience.errors?.pattern" class="text-danger"
                  >Please Enter Valid Min Experience</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Max Experience <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control email-input select-placeholder mb-3 mb-lg-0"
                formControlName="maxExperience"
                (keypress)="noNumber($event)"
                (keyup)="keyUpmaxexp($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                maxlength="4"
                placeholder="Enter Max Experience"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (maxExperience.invalid && maxExperience.touched) ||
                  maxExperience.dirty ||
                  submitted
                "
              >
                <span *ngIf="maxExperience.errors?.required" class="text-danger"
                  >Please Enter Max Experience</span
                >
                <span *ngIf="maxExperience.errors?.pattern" class="text-danger"
                  >Please Enter Valid Max Experience</span
                >
                <!-- <small *ngIf="this.maxExp==false" class="text-danger">Maximum experience should be greater than minimum experience</small>     -->
              </div>
              <span *ngIf="this.maxExp == false" class="text-danger"
                >Maximum Experience Should Be Greater Than Minimum
                Experience</span
              >
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Location <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control email-input select-placeholder mb-3 mb-lg-0"
                formControlName="location"
                placeholder="Enter Location"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (location.invalid && location.touched) ||
                  location.dirty ||
                  submitted
                "
              >
                <span *ngIf="location.errors?.required" class="text-danger"
                  >Please Enter Location</span
                >
                <span *ngIf="location.errors?.pattern" class="text-danger"
                  >Please Enter Valid Location</span
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Max CTC <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control email-input select-placeholder mb-3 mb-lg-0"
                (keypress)="noNumber($event)"
                (keyup)="keyUpmaxexp($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                maxlength="7"
                formControlName="ctc"
                placeholder="Enter Max CTC"
              />
              <div
                class="valid-msg"
                *ngIf="(ctc.invalid && ctc.touched) || ctc.dirty || submitted"
              >
                <span *ngIf="ctc.errors?.required" class="text-danger"
                  >Please Enter Max CTC</span
                >
                <span *ngIf="ctc.errors?.pattern" class="text-danger"
                  >Please Enter Valid Max CTC</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Hiring For <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                (selectionChange)="listTypeValueid($event)"
                appearance="fill"
                formControlName="hiring"
                placeholder="- Select Hiring For-"
              >
                <mat-option
                  *ngFor="let e of positionusertype"
                  class="text-color"
                  [value]="e.listTypeValueId"
                  >{{ e.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (hiring.invalid && hiring.touched) ||
                  hiring.dirty ||
                  submitted
                "
              >
                <span *ngIf="hiring.errors?.required" class="text-danger"
                  >Please Select Hiring For</span
                >
              </div>
            </div>

            <div class="form-group col-md-3" *ngIf="showclientname">
              <label class="form-control-label"
                >Client Name <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="client"
                placeholder="- Select Client Name-"
              >
                <mat-option
                  *ngFor="let client of positionlistdata"
                  class="text-color"
                  [value]="client.clientId"
                  >{{ client.clientName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (client.invalid && client.touched) ||
                  client.dirty ||
                  submitted
                "
              >
                <span *ngIf="client.errors?.required" class="text-danger"
                  >Please Select Client Name</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Job Type <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="jobType"
                placeholder="- Select Job Type-"
              >
                <mat-option
                  *ngFor="let e of jobtype"
                  class="text-color"
                  [value]="e.listTypeValueId"
                  >{{ e.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (jobType.invalid && jobType.touched) ||
                  jobType.dirty ||
                  submitted
                "
              >
                <span *ngIf="jobType.errors?.required" class="text-danger"
                  >Please Select Job Type</span
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Notice Period <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="noticePeriod"
                placeholder="Choose Notice Period"
                [multiple]="true"
              >
                <mat-option
                  *ngFor="let e of noticeperiod"
                  class="text-color"
                  [value]="e.listTypeValueId"
                  >{{ e.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (noticePeriod.invalid && noticePeriod.touched) ||
                  noticePeriod.dirty ||
                  submitted
                "
              >
                <span *ngIf="noticePeriod.errors?.required" class="text-danger"
                  >Please Select Notice Period</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Job Description <span class="star">*</span></label
              >
              <textarea
                type="text"
                rows="2"
                class="form-control email-input select-placeholder"
                placeholder="Job Description"
                style="
                  font-size: 15px;
                  overflow-x: auto;
                  overflow-y: auto;
                  min-height: 43px;
                  border: none;
                  padding: 3px 15px;
                "
                formControlName="jobDescription"
              ></textarea>

              <div
                *ngIf="
                  (jobDescription.invalid && jobDescription.touched) ||
                  jobDescription.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="jobDescription.errors?.required"
                  class="text-danger"
                  >Please Enter Job Description</span
                >
                <!-- <small *ngIf="jobDescription.errors?.pattern" class="text-danger">Please enter valid comments</small> -->
              </div>
              <div *ngIf="jobDescription.touched || jobDescription.dirty">
                <span *ngIf="jobDescription.errors?.pattern" class="text-danger"
                  >Please Enter Valid Job Description</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <!-- <label class="form-control-label">Job Description Attachment</label> -->
              <!-- <input type="file" class="form-control email-input select-placeholder mb-3 mb-lg-0" (change)="getFileDetails($event)" formControlName="jobDescriptionAttachment" placeholder="" accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple> -->

              <!-- <label class="file-label">
                                <div class="row">
                                    <div class="col-md-2">
                                        <mat-icon class="material-symbols-outlined">
                                            attach_file_add
                                           
                                            </mat-icon>
                                    </div>
                                    <div class="col-md-10" style="margin-left: -6px;" *ngIf="userdata.jobDescriptionAttachment == undefined">
        
                                        <span *ngIf="fileName != undefined">{{fileName}}</span>
                                        <span  *ngIf="fileName == undefined">{{'Attach File' }}</span>
                                    </div>
                                    <div class="col-md-10" style="margin-left: -6px;" *ngIf="userdata.jobDescriptionAttachment != undefined">
                                        <span *ngIf="userdata.jobDescriptionAttachment.sourceFileName != undefined">{{userdata.jobDescriptionAttachment.sourceFileName}}</span>
                                        <span  *ngIf="userdata.jobDescriptionAttachment.sourceFileName == undefined">{{'Attach File' }}</span>
                                        <div>
                                            <a class="btn btn-primary btn-sm" href="{{userdata.jobDescriptionAttachment.fileDownloadUrl}}">
                                                Download 
                                                </a>
                                        </div>
                                      
                                    </div>
                                    </div>
                                    <input type="file"                                                        
                                    formControlName="jobDescriptionAttachment"
                                    placeholder="" accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple
                                    class="file-input" (change)="getFileDetails($event)">  
                              </label> -->

              <!-- <label class="file-label form-control-label"
                >Job Description Attachment
                <div class="row" style="width: 135% !important">
                  <div
                    class="col-md-10"
                    style="margin-left: -6px; position: relative; top: 6px"
                  >
                    <input
                      type="file"
                      style="height: 41px"
                      formControlName="jobDescriptionAttachment"
                      placeholder=""
                      accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf"
                      [ngClass]="{ 'is-invalid': submitted }"
                      class="file-input form-control email-input select-placeholder"
                      (change)="getFileDetails($event)"
                    />
                  </div>

                  <div 
                    class="col-md-10"
                    style="
                      margin-left: -6px;
                      position: relative;
                      top: 11px;
                      left: 5px;
                    "
                    *ngIf="userdata.jobDescriptionAttachment != undefined"
                  >
                   
                    <div *ngIf="!newFileSelected ">
                      <a
                        class="btn btn-primary btn-sm"
                        href="{{
                          userdata.jobDescriptionAttachment.fileDownloadUrl
                        }}"
                        download
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </label>
              <label
                *ngIf="!newFileSelected  && resourceform.get('jobDescriptionAttachment').value"
                class="col-form-label"
                style="font-family: Mulish; margin-left: 1%;"
              >
                Current Attachment: {{ resourceform.get('jobDescriptionAttachment').value }}
              </label> -->

              <label class="file-label form-control-label">
                Job Description Attachment
                <div class="row" style="width: 135% !important">
                  <div class="col-md-10" style="margin-left: -6px; position: relative; top: 6px">
                    <input
                      type="file"
                      style="height: 41px"
                      formControlName="resourcecvattachment"
                      placeholder=""
                      accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf"
                      [ngClass]="{ 'is-invalid': submitted }"
                      class="file-input form-control email-input select-placeholder"
                      (change)="getFileDetails($event)"
                    />
                  </div>
              
                  <div
                    class="col-md-10"
                    style="margin-left: -6px; position: relative; top: 11px; left: 5px;"
                    *ngIf="!newFileSelected && userdata.jobDescriptionAttachment && userdata.jobDescriptionAttachment.jobDescriptionId"
                  >
                    <div>
                      <a
                        class="btn btn-primary btn-sm"
                        href="{{
                          userdata.jobDescriptionAttachment.fileDownloadUrl
                        }}"
                        download
                      >
                        Download File
                      </a>
                    </div>
                  </div>
                </div>
              </label>
              
              <label
                *ngIf="!newFileSelected  && resourceform.get('jobDescriptionAttachment').value"
                class="col-form-label"
                style="       font-family: Mulish;
                margin-left: 0%;
                margin-top: -2px;
                font-family: 'Mulish', sans-serif;
                font-size: 15px;
                font-weight: 660;"
              >
                Current Attachment: {{ resourceform.get('jobDescriptionAttachment').value }}
              </label>


              <!-- <div *ngIf="userdata.jobDescriptionAttachment != undefined"><br>
                                <a class="btn btn-primary btn-sm" href="{{userdata.jobDescriptionAttachment.fileDownloadUrl}}">
                                Download 
                                </a>
                            </div>  -->

              <!-- <div class="valid-msg" *ngIf="(jobDescriptionAttachment.invalid && jobDescriptionAttachment.touched) || jobDescriptionAttachment.dirty || submitted">
                                <span *ngIf="jobDescriptionAttachment.errors?.required" class="text-danger">Please enter job description </span>
                            </div>  -->
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Expiry Date <span class="star">*</span></label
              >
              <input
                matInput
                [matDatepicker]="picker1"
                [min]="today"
                formControlName="expiryDate"
                class="form-control email-input select-placeholder"
                readonly="readonly"
                placeholder="Choose a date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
                style="float: right; position: relative; bottom: 42px"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <div
                class="valid-msg"
                *ngIf="
                  (expiryDate.invalid && expiryDate.touched) ||
                  expiryDate.dirty ||
                  submitted
                "
              >
                <span *ngIf="expiryDate.errors?.required" class="text-danger"
                  >Please Enter Expiry Date</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="form-group col-md-5"
              appearance="fill"
              style="margin-left: 2px"
            >
              <label class="form-control-label"
                >Skills<span class="text-danger">*</span></label
              >
              <mat-chip-list
                #chipList
                aria-label="Fruit selection"
                class="form-control email-input select-placeholder custom-scrollbar"
                style="
                         overflow-y: auto;
    width: 120% !important;
    height: fit-content;
                 
                "
              >
                <mat-chip
                  *ngFor="let fruit of skillsarray"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(fruit)"
                >
                  <span class="skill-input">{{ fruit.name }}</span>
                  <!-- <img matChipRemove *ngIf="removable" class="settings-icon" src="assets/uploads/icons/107.png" alt=""> -->

                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Enter Skills..."
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)"
                  class="chip-input"
                  formControlName="skills"
                />
              </mat-chip-list>
              <!-- <div *ngIf="skills.touched || skills.dirty||(submmited && resourceform.controls['skills'].invalid)">
                                <small *ngIf="skills.errors?.required" class="text-danger">Please enter skills</small>
                            </div> -->
              <div
                *ngIf="
                  (skills.invalid && skills.touched) ||
                  skills.dirty ||
                  submitted
                "
              >
                <span *ngIf="skillsarray.length == 0" class="text-danger"
                  >Please Enter Skills</span
                >
              </div>
            </div>

            <!-- <div class="col-md-4" id="loginform" style="position: relative;top: -30px;"> -->
            <!-- <label class="form-control-label">Skills<span class="text-danger">*</span></label>
                            <textarea type="text" rows="2" class="form-control email-input" formControlName="skills"></textarea>
                            <div *ngIf="(skills.invalid && skills.touched) || skills.dirty">
                                <small *ngIf="skills.errors?.required" class="text-danger">Please enter comments</small> -->
            <!-- <small *ngIf="skills.errors?.pattern" class="text-danger">Please enter valid comments</small> -->
            <!-- </div>  -->

            <!-- </div>  -->
          </div>

          <!-- <div class="row">
                        <div class="col-md-12 text-right">
                            <a routerLink="/home/positions" mat-raised-button title="Cancel" class="btn btn-light btn-sm mr-2">
                                Cancel
                            </a>
                            <button type="submit" class="submit action-button btn btn-primary btn-sm" [disabled]="buttonDisabled">Save</button>
                        </div>
                    </div>        -->
          <div align="end">
            <!-- <button
              mat-raised-button
              type="button"
              [disabled]="buttonDisabled"
              class="searchBtn ml-2 mx-2 mt-2 mt-xl-0 cancel-button "
              routerLink="/home/positions"
              style="color: var(--Red-Color, #FF5D5D);
              height: 34px;
              font-weight: 550;"
            >
              Cancel
            </button> -->
            <button
            mat-raised-button
            routerLink="/home/positions"
            type="button"
            class="searchBtn ml-2 mx-2 mt-2 mt-xl-0"
            [disabled]="buttonDisabled"
            style="
              background: none;
              color: var(--Red-Color, #FF5D5D);
              border: 1px solid #ddd;
              font-size: 16px;
              font-weight: 550;
              margin-left: 5px;
              height: 35px;
            "
          >
          Cancel
          </button>
            <button
              mat-raised-button
              type="submit"
              style="height: 34px"
              class="searchBtnTime create-button m-1"
              [disabled]="buttonDisabled"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="    width: 3rem;
  height: 3rem;
  position: absolute;
  top: 37% !important;
  right: 48% !important;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
