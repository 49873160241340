<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content pr-3" style="    position: relative;
                    top: 6px;
                    color: #181c32;
                    font-weight: 800;
                    font-size: 22px;
                    font-family: 'Mulish', sans-serif;
                    right: 15px;">Assign KRA</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/add-assign-kra-template" routerLinkActive="active"
                        mat-raised-button title="Add Assign KRA " class="ml-2" style="    position: relative;
                        left: 22px; color:#fff; background-color: #4870ff;">
                        Add Assign KRA
                    </a>
                </div>
                <!-- <div class="sub-header-buttons"> -->
                <!--<a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                <!-- <a routerLink="/home/add-kra-template" routerLinkActive="active" color="primary" mat-raised-button title="Add KRA Template" class="ml-2">
                      Add KRA
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->
<div class="common-content">
    <div class="card-new">
        <div class="card" style="font-family: Mulish;">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row">
                                <!-- <div class="form-group col-md-4">
                                    <label class="col-form-label">Employee Code<span class="star"></span></label>
                                    <input type="text" (keyup)="getEmpCodeList($event)" formControlName="employeeCode" id="employeeCode" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"  autocomplete="off">
                                    <input type="hidden" formControlName="employeeId" id="employeeId" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off">
                                    <span *ngIf="autoCompleteSearch == 0 ">
                                        <ul class="auto-complete" *ngIf="employeeData?.length > 0">
                                            <li (click)="getFilterNumber(searchData,searchData.employeeNumber);" *ngFor="let searchData of employeeData"> {{searchData.employeeNumber}} -{{searchData.firstName}}</li>
                                        </ul>
                                    </span>
                                  </div>  -->
                                <div class="form-group col-md-4">
                                    <!-- <div class="input-width" id="loginform"> -->
                                    <!-- <label class="col-control-label">Employee<span class="text-danger"></span></label> -->
                                    <label class="col-form-label" style="white-space: nowrap; font-weight: 600;">Employee<span
                                            class="star"></span></label>
                                    <!-- <input formControlName="employeeCode"
                                        class="form-control form-control-solid form-select-lg"
                                        
                                        placeholder="Search employee" [disabled]="empolyeereadonly"
                                        [matAutocomplete]="auto"  (keyup)="empSearch($event,$event)"
                                        (keydown)="handleBackspace($event)"  (keydown)="preventSpaceBeforeInput($event)"
                                        > -->
                                        <input 
                                        type="text"
                        matInput
                        (input)="validateInput($event)"
                        (keydown)="validateInput($event)"
                        (keyup)="validateInput($event)"
                        placeholder="Search Employee"
                        formControlName="employeeCode"
                        [matAutocomplete]="auto"
                        class="form-control email-input select-placeholder"
                        (keyup)="empSearch($event,$event)"
                        (keydown)="handleBackspace($event)"
                        (keydown.space)="preventSpaceBeforeInput($event)"
                       >
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <!-- <mat-option value="All" (click)="empAllSelect()">All</mat-option> -->
                                        <mat-option *ngFor="let emp of empList" [value]="emp.employee.employeeId"
                                            (click)="empSelect(emp)">
                                            {{emp.employee.employeeNumber}} - {{emp.employee.firstName}}
                                        </mat-option>
                                        <mat-option
                                            [value]="null"
                                            *ngIf="employeeNotFound"
                                            (click)="noEmployeeFound()"
                                            >No Employee Found</mat-option
                                        >
                                    </mat-autocomplete>
                                    <mat-error *ngIf="validate.employeeCode.invalid && submitted">Employee is
                                        required</mat-error><br>
                                    <!-- <span *ngIf="empolyeeapproverexist" class="span-error"> Approver Already Exist</span> -->
                                    <!-- </div> -->
                                </div> 
                                <div class="form-group col-md-4 ">
                                    <label class="col-form-label" style="white-space: nowrap; font-weight: 600;">KRA Template Name<span
                                            class="star"></span></label>
                                    <!-- <input type="text" (keyup)="getTemplateCodeList($event)" formControlName="templateName" id="templateName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off">
                                    <input type="hidden" formControlName="employeeId" id="employeeId" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off">  -->
                                    <!-- <span *ngIf="autoCompleteSearch == 0 ">
                                        <ul class="auto-complete" *ngIf="employeeData?.length > 0">
                                            <li (click)="getFilterTemplateName(searchData.employeeKraId,searchData.kraTemplateHeader);" *ngFor="let searchData of employeeData">{{searchData.kraTemplateHeader.templateName}}</li>
                                        </ul>
                                    </span> -->

                                    <input type="text" 
                                    matInput
                                    
                                        (input)="validateInput($event)" 
                                        (keydown)="validateInput($event)"
                                        (keyup)="validateInput($event)"
                                        placeholder="Enter Template Name"
                                        formControlName="templateName"
                                        [matAutocomplete]="temName"
                                        class="form-control email-input select-placeholder form-control-lg form-control-solid mb-3 mb-lg-0"
                                        (keyup)="getTemplateCodeList($event,$event)"
                                        (keydown)="handleBackspace2($event)"
                                        (keydown.space)="preventSpaceBeforeInput($event)"
                                        style="height: 39px; font-family: Mulish; border-color: #f5f8fa;border: 1px solid #dee2e6;">


                                    <mat-autocomplete #temName="matAutocomplete">
                                        <mat-option *ngFor="let searchData of templateData"
                                            [value]="searchData.templateCode"
                                            (click)="empTemplate(searchData)">
                                            {{searchData.templateName}}

                                        </mat-option>
                                        <mat-option
                                            [value]="null"
                                            *ngIf="templateNotFound"
                                            (click)="noTemplateFound()"
                                            >No Template Found</mat-option
                                        >
                                    </mat-autocomplete>
                                </div>

                                <div class="form-group col-md-4 abc">
                                    <!-- <button type="submit" mat-raised-button 
                                        class="searchBtn" [disabled]="loading || !isFormValid()"
                                        style="margin-top: 37px;">Search</button> -->


                                        <button type="submit" mat-raised-button 
                                        class=" btn btn-primary searchBtn searchDesign searchBtn" [disabled]="buttonsDisabled"
                                        style="margin-top: 37px;">Search</button>
                                        
                                        
                                    <!-- <button style="margin-top: 37px; margin-left: 5px; font-family: Mulish; color: white;
           background: none;
          color: black;
          border: 1px solid #ddd; font-size: 16px;
          font-weight: 610;  height: 35px; width:0px;"
                                        type="button" mat-raised-button class=" mr-5"
                                        (click)="clearTable()" [disabled]="loading || !isFormValid()" >Clear</button> -->

                                        <button style="margin-top: 37px; margin-left: 5px; font-family: Mulish; color: white;
                                        background: none;
                                       color: black;
                                       border: 1px solid #ddd; font-size: 16px;
                                       font-weight: 610;  height: 35px; width:0px;"
                                                                     type="button" mat-raised-button class=" mr-5"
                                                                     (click)="clearTable()" [disabled]="buttonsDisabled" >Clear</button>
                                </div>
                                <!-- <div class="form-group col-md-2">
                                    <button style="margin-top: 37px;" type="reset" mat-raised-button
                                    class="btn search-btn mr-2"
                                     (click)="clearTable()">Clear</button>
                                </div> -->

                                <!-- <div class="col-md-6">
                                    <button type="submit" mat-raised-button color="primary"
                                        class="btn btn-primary search-btn">Search</button>
                                    <button style="margin-left: 20px;" type="reset" mat-raised-button
                                       class="btn search-btn mr-2"
                                        (click)="clearTable()">Clear</button>
                                </div> -->
                            </div>
                        </form>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-9 col-sm-9 col-9 colxs-9 text-right">
                                <p class="show-count" style="white-space: nowrap;">Show</p>
                            </div>
                            <div class="col-md-3  col-sm-3 col-xs-3 col-3 text-left pl-0">
                                <select [(ngModel)]="mySelect" class="form-select form-select-solid form-select-lg report-filter" style="padding-left:9px;">
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                  <option value="25">25</option>
                                  <option value="1500">1500</option>
                              </select>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="row">
                    <!-- <div class="col-md-6 offset-6" style="margin-bottom: 10px;">
                        <div class="row" style="margin-left: 100px;">
                            <div class="col-md-2 col-sm-2 col-2 colxs-2 offset-7" style="padding:0px">
                                <p class="show-count" style="white-space: nowrap;text-align: right;float:right;">Show:</p>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3 col-3" style="width: 10px;padding:0px">
                                <select [(ngModel)]="mySelect" class="form-select form-select-solid form-select-lg report-filter" style="width:67px;">
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                  <option value="25">25</option>
                                 
                              </select>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-xl-12 col-lg-12" style="margin-bottom: 10px;"
                    *ngIf="!error && !nodatafound && resultData?.length > 0 && showTable">
                        <div class="select-pagination" *ngIf="resultData.length >= 5">
                            <span>Show : </span>
                            <select class="select_list new-select_list" [(ngModel)]="mySelect"
                                (change)="onItemsPerPageChange()"
                                style="cursor: pointer;">
                                <option class="form-control" value="5"> 5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                            </select>
                        </div>
                    </div>
                </div> 
                <div *ngIf="showTable" class="table-responsive ">
                    <table matSort (matSortChange)="sortData($event)" class="table --table-responsive table-border-new"
                        style="border-radius: 10px;">
                        <thead class="t-head" >
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center" style="border:15px 1px 0px 0;">Action</th>
                                <th class="text-center" mat-sort-header="employeeNumber">Employee Code</th>
                                <th class="text-center" mat-sort-header="employeeName">Employee Name</th>
                                <!-- <th class="text-left">KRA Template Code</th> -->
                                <th class="text-center" mat-sort-header="templateName">KRA Template Name</th>
                                <th class="text-center">Period From</th>
                                <th class="text-center">Period To</th>
                                <th class="text-center" style="border:0px 15px 0px 0px;">Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData?.length > 0" style="    border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            border-bottom: 1px solid #ddd;">
                            <tr
                                *ngFor="let row of resultData | paginate: { itemsPerPage: mySelect, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item routerLink="/home/edit-assign-kra/{{row.employeeKraId}}"
                                            routerLinkActive="active">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 1"
                                            (click)="updateStatus(row.employeeKraId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>Inactive</span>
                                        </button>
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 2"
                                            (click)="updateStatus(row.employeeKraId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>
                                    </mat-menu>
                                </td>
                                <!-- <td class="text-left">{{ucFirst(row.employeeId)}}</td> -->
                                <td class="text-center">{{row.employee?.employeeNumber}}</td>
                                <td class="text-center">{{row.employee?.firstName}}-{{row.employee?.lastName}}</td>
                                <!-- <td class="text-left">{{row.kraTemplateHeader.templateCode}}</td> -->
                                <td class="text-center">{{row.kraTemplateHeader.templateName}}</td>
                                <td class="text-center">
                                    {{row?.periodFrom ? (row?.periodFrom | date : dateFormat) : ''}}
                                </td>
                                <td class="text-center">
                                    {{row?.periodTo ? (row?.periodTo | date : dateFormat) : ''}}
                                </td>
                                <td class="active-status text-center">
                                 <span class="btn btn-outline-success btn-sm btn-block"
                                        *ngIf="row.status.listTypeValueId == 1"
                                        >
                                        {{row.status.listTypeValueName}}
                                    </span>
                                    <span class="btn btn-outline-danger btn-sm btn-block"
                                        *ngIf="row.status.listTypeValueId == 2"
                                        >
                                        {{row.status.listTypeValueName}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td style="font-size: 22px;
                                font-family: 'Mulish', sans-serif;
                                font-weight: 800;
                                " colspan="8" *ngIf="nodatafound">No
                                    Records Found!</td>
                            </tr>
                        </tbody> -->
                        <tbody *ngIf="nodatafound">
                            <tr>
                                <td colspan="8" class="text-center" style="font-size: 22px; font-family: 'Mulish', sans-serif; font-weight: 800;">No Matching Assigned KRA  Found!</td>
                            </tr>
                        </tbody>
                    </table>
                   
                </div>


                <!-- <div *ngIf="resultData?.length > 0 ">
                    <div class="row mt-3">
                        <div class="col-md-8">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-4 text-right">
                            <pagination-controls (pageChange)="p = $event; beforepage = $event"></pagination-controls>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-md-5 " style="float: right; " *ngIf="!error && !nodatafound && resultData?.length > 5">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div> -->


                
                <pagination-controls
                    *ngIf="!error && !nodatafound && resultData  && resultData.length > 5 && showTable" 
                    class="text-right"
                    style="float: right; margin-top: 10px;"
                    (pageChange)="p = $event;"
                    align="end"
                    ></pagination-controls>
                

                

                

            </div>
        </div>
    </div>
</div>



<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>


<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border"
        style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;bottom: 0;"
        role="status"></div>
</div>

<!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodatafound" style="    margin-top: 285px;">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary" style="margin-top: 12px;">
       
        <h4 align="center" class="h4" style="    margin-top: 14px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 15px;
        "> Assign KRA Template Not Found </h4>
      </div>
    </div>
     </mat-card> -->