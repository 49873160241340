import { Component, OnInit,Inject } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';
import { PmsService } from '../../../providers/pms.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../../providers/properties';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-add-kra',
  templateUrl: './add-kra.component.html',
  styleUrls: ['./add-kra.component.scss']
})
export class AddKraComponent implements OnInit 
{
  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any;
  editId: any;
  editStatus: any;
  temCode:number;
  // add_remove_more: FormArray;
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  deletedresourcetime : any = [];
  usertData: any;
  temName: any;
  tempCodeExist: boolean=false;
  tempNameExist: boolean=false;
  userId: any;

  invalidInput: boolean = false;

  submitButtonDisable :boolean = false;
  pasteCount: any;
  isAddButtonDisabled: boolean = false;
  
  
  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private pmsService: PmsService,
    private ActivatedRoute:ActivatedRoute,
    @Inject(AppComponent) public AppData: any,
  ) { } 

  dateFormat : any;
  organizationId : any;

  ngOnInit(): void
  {
    // this.dateFormat = this.AppData.dateFormat;
    // this.organizationId = this.AppData.organizationId;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.organizationId = this.usertData.organization.organizationId;
    console.log(this.organizationId);
    this.userId = this.usertData.userId;
    console.log("Employye ID"+this.userId);
    this.postForm = this.formBuilder.group({
      // templateCode : ['',[Validators.required,Validators.pattern(/^[0-9_-]*$/)]],
      templateCode: ['', [Validators.required , Validators.pattern(/^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/)]],  
      templateName : ['',[Validators.required]],
      templateDescription : [''],
      add_remove_more:this.formBuilder.array([
        // this.formBuilder.group({
          // criteriaName: ['', Validators.required],
        //   weightage: ['', Validators.required],
        //   negativeRatingCheck: [''],
        //   kraTemplateHeaderLineId: [''],
        // })
      ]),
    });  
    this.editId = this.ActivatedRoute.snapshot.params.kraTemplateHeaderId;
    console.log(this.editId);
    if(this.editId)
      
    {
      this.loader = true;
      this.pmsService.getKRATemplateById(this.editId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;
        console.log(this.resultData);
        this.editStatus = this.resultData.status;
        let resourceAvailability = this.resultData.kratemplateLine;
        
        this.postForm.patchValue({
            templateCode        : this.resultData.templateCode,
            templateName        : this.resultData.templateName,
            templateDescription : this.resultData.templateDescription,
        });

        const formArray = this.postForm.get('add_remove_more') as FormArray;
        this.addMore();
        if(resourceAvailability.length !=0)
        {
          formArray.at(0).patchValue({
            kraTemplateHeaderLineId  : resourceAvailability[0].kraTemplateHeaderLineId,
            criteriaName             : resourceAvailability[0].criteriaName,
            weightage                : resourceAvailability[0].weightage,
            negativeRatingCheck      : resourceAvailability[0].negativeRatingCheck,
          });
        }
        // console.log("negativeRatingCheck"+resourceAvailability[0].negativeRatingCheck);
        for(let i=1 ; i < resourceAvailability.length ; i++)
        {
          formArray.push(this.formBuilder.group({
              kraTemplateHeaderLineId : resourceAvailability[i].kraTemplateHeaderLineId,
              criteriaName            : resourceAvailability[i].criteriaName,
              weightage               : resourceAvailability[i].weightage,
              negativeRatingCheck     : resourceAvailability[i].negativeRatingCheck
            })
          )
        }
        this.loader=false;
      });
      
    }
    else{
      this.addMore();
    }
  }
  

  get validate() { return this.postForm.controls; }
  
  notZeroValidatorAsync(control: AbstractControl) {
    const value = control.value;
    const regex =/^(?!\.)\d{1,8}(\.\d{1,2})?$|^\d{1,8}$/; 
    return new Promise((resolve) => {
      if (!regex.test(value.toString()) ) {
        console.log("invaild");
        resolve({ notZero: true }); // Return an object with a custom error key if value is zero
      } else {
        console.log("Vaild");
        resolve(null); // Return null if the value is not zero, indicating the control is valid
      }
    });
  }
  //Add More
  addMore()
  {
    const Array: any = this.postForm.get('add_remove_more') as FormArray;
    if(Array.length >= 10) 
    {
      alert("Maximum 10 Criteria and Weightage!")
      return;
    }
    else
    {
      // Array.push(this.formBuilder.group({
      //   criteriaName: ['', Validators.required],
      //   weightage: ['', Validators.required],
      //   negativeRatingCheck: [''],
      // })) 
      const control = this.postForm.get('add_remove_more') as FormArray;
      control.push(this.initRows());
      console.log(control);
    }
  }

  // addRow() 
  // {
  //   const control = this.postForm.get('add_remove_more') as FormArray;
  //   control.push(this.initRows());
  //   console.log(control);
  // }

  initRows() {
    return this.formBuilder.group({
      criteriaName: ['', Validators.required,],
      weightage: ['', Validators.required,this.notZeroValidatorAsync],
      negativeRatingCheck: [''],
      kraTemplateHeaderLineId: [''],
    });
  }

  //Remove More
  removeMore(index: number) 
  {
    if(index == 0)
    {
      return
    }
    else
    {
      if(this.editId)
      {
        let resavaupdate = this.add_remove().at(index).value;
        this.deletedresourcetime.push(resavaupdate);
        this.add_remove().removeAt(index);
      }
      else
      {
        this.add_remove().removeAt(index);
      }
    }
    this.updateAddButtonState();
  }
  
  updateAddButtonState() {
    let totalWeightage = 0;
    this.add_remove().controls.forEach(control => {
      const weightage = parseFloat(control.get('weightage')?.value) || 0;
      totalWeightage += weightage;
    });
    this.isAddButtonDisabled = totalWeightage >= 100;
  }


  // templateCode(event:any)
  // {
  //   console.log(event.target.value);
  //   this.temCode=event.target.value;
  //   console.log(this.temCode);
  //   if(this.editId) //update
  //   var temcode= 
  //   {
  //     // defaultTemplate: 
  //     kraTemplateHeaderId  : this.editId,
  //     templateCode         : this.postForm.value.templateCode,
  //     templateName         : this.postForm.value.templateName,
  //     templateDescription  : this.postForm.value.templateDescription,
  //   }
  //   this.pmsService.checkTemplateCode(temcode).subscribe((result: any) =>
  //     {
  //       console.log("Code Already Exists");
  //       if(result.statusCode==409)
  //       {
  //         this.tempCodeExist=true;
  //       }
  //       else
  //       {
  //         console.log("not Exists");
  //         this.tempCodeExist=false;
  //       }
  //     }, 
  //     err =>{
  //       console.log("Api Error result");
  //     })

  //   else{
  //     var temcode1= 
  //   {
  //     // defaultTemplate: 
  //     kraTemplateHeaderId  : this.editId,
  //     templateCode         : this.postForm.value.templateCode,
  //     templateName         : this.postForm.value.templateName,
  //     templateDescription  : this.postForm.value.templateDescription,
  //   }
  //   this.pmsService.checkTemplateCodeUpdate(temcode).subscribe((result: any) =>
  //     {
  //       console.log("Code Already Exists");
  //       if(result.statusCode==409)
  //       {
  //         this.tempCodeExist=true;
  //       }
  //       else
  //       {
  //         console.log("not Exists");
  //         this.tempCodeExist=false;
  //       }
  //     }, 
  //     err =>{
  //       console.log("Api Error result");
  //     })

  //   }
  // }

  // templateCode(event: any) {
  //   console.log(event.target.value);
  //   this.temCode = event.target.value;
  //   console.log(this.temCode);
  
  //   let temcode = {
  //     kraTemplateHeaderId: this.editId,
  //     templateCode: this.postForm.value.templateCode,
  //     templateName: this.postForm.value.templateName,
  //     templateDescription: this.postForm.value.templateDescription,
  //   };
  
  //   if (this.editId) { // update
  //     this.pmsService.checkTemplateCodeUpdate(temcode).subscribe(
  //       (result: any) => {
  //         console.log("Updated code");
  //         // if (result.statusCode == 409) {
  //         //   this.tempCodeExist = true;
  //         // } else {
  //         //   console.log("not Exists");
  //         //   this.tempCodeExist = false;
  //         // }
  //       },
  //       (err) => {
  //         console.log("Api Error result");
  //       }
  //     );
  //   } else {
  //     this.pmsService.checkTemplateCode(temcode).subscribe(
  //       (result: any) => {
  //         console.log("Code Already Exists");
  //         if (result.statusCode == 409) {
  //           this.tempCodeExist = true;
  //         } else {
  //           console.log("not Exists");
  //           this.tempCodeExist = false;
  //         }
  //       },
  //       (err) => {
  //         console.log("Api Error result");
  //       }
  //     );
  //   }
  // }
  templateCode(event: any) {
    console.log(event.target.value);
    this.temCode = event.target.value;
    console.log(this.temCode);
  
    let temcode = {
      kraTemplateHeaderId: this.editId,
      templateCode: this.postForm.value.templateCode,
      templateName: this.postForm.value.templateName,
      templateDescription: this.postForm.value.templateDescription,
    };

    const handleResponse = (result: any) => {
        console.log("Code Already Exists");
        if (result.statusCode === 409) {
            this.tempCodeExist = true;
        } else {
            console.log("Not Exists");
            this.tempCodeExist = false;
        }
    };

    const handleError = (err: any) => {
        console.log("Api Error result", err);
    };
  
    if (this.editId) { // update
        this.pmsService.checkTemplateCodeUpdate(temcode).subscribe(
            (result: any) => {
                console.log("Updated code");
                handleResponse(result);
            },
            handleError
        );
    } else {
        this.pmsService.checkTemplateCode(temcode).subscribe(
            handleResponse,
            handleError
        );
    }
}


 
  

  

  // templateName(event:any)
  // {
  //   console.log(event.target.value);
  //   this.temName=event.target.value;
  //   console.log(this.temName);
  //   var temname= 
  //   {
  //     kraTemplateHeaderId: this.editId,
  //     templateCode         : this.postForm.value.templateCode,
  //     templateName         : this.postForm.value.templateName,
  //     templateDescription  : this.postForm.value.templateDescription,
  //     // defaultTemplate: 
  //   };

    
  //   this.pmsService.checktemplateName(temname).subscribe((result: any) =>
  //     {
  //       console.log("Name Already Exists");
  //       if(result.statusCode==409)
  //       {
  //         this.tempNameExist=true;
  //       }
  //       else
  //       {
  //         console.log("not Exists");
  //         this.tempNameExist=false;
  //       }
  //     }, 
  //     err =>{
  //       console.log("Api Error result");
  //     })
  // }


  // templateName(event: any) {
  //   console.log(event.target.value);
  //   this.temName = event.target.value;
  //   console.log(this.temName);
  
  //   const temname = {
  //     kraTemplateHeaderId: this.editId,
  //     templateCode: this.postForm.value.templateCode,
  //     templateName: this.postForm.value.templateName,
  //     templateDescription: this.postForm.value.templateDescription,
  //   };
    
  //   if (this.editId) { 
  //   this.pmsService.checktemplateUpdateName(temname).subscribe(
  //     (result: any) => {
  //       console.log("Name");
  //       // if (result.statusCode == 409) {
  //       //   this.tempNameExist = true;
  //       // } else {
  //       //   console.log("not Exists");
  //       //   this.tempNameExist = false;
  //       // }
  //     },
  //     (err) => {
  //       console.log("Api Error result");
  //     }
  //   );
  // }else {
  //   this.pmsService.checktemplateName(temname).subscribe(
  //     (result: any) => {
  //       console.log(result);
  //       console.log("Code Already Exists");
  //       if (result.statusCode == 409) {
  //         this.tempNameExist = true;
  //       } else {
  //         console.log("not Exists");
  //         this.tempNameExist = false;
  //       }
  //     },
  //     (err) => {
  //       console.log("Api Error result");
  //     }
  //   );
  // }
  // }
  
  templateName(event: any) {
    console.log(event.target.value);
    this.temName = event.target.value;
    console.log(this.temName);
  
    const temname = {
      kraTemplateHeaderId: this.editId,
      templateCode: this.postForm.value.templateCode,
      templateName: this.postForm.value.templateName,
      templateDescription: this.postForm.value.templateDescription,
    };
    
    const handleResponse = (result: any) => {
        console.log(result);
        console.log("Code Already Exists");
        if (result.statusCode === 409) {
            this.tempNameExist = true;
        } else {
            console.log("Not Exists");
            this.tempNameExist = false;
        }
    };

    const handleError = (err: any) => {
        console.log("Api Error result", err);
    };
    
    if (this.editId) { 
        this.pmsService.checktemplateUpdateName(temname).subscribe(
            (result: any) => {
                console.log("Name updated");
                handleResponse(result);
            },
            handleError
        );
    } else {
        this.pmsService.checktemplateName(temname).subscribe(
            handleResponse,
            handleError
        );
    }
}

  

  add_remove() 
  {
    return this.postForm.get('add_remove_more') as FormArray;
  }

  

  onSubmit()
  {
    
    
    this.submitted = true;
    if (this.postForm.invalid) 
    {
      return;
    }

    this.submitButtonDisable= true;

    if(this.editId) //Update
    {
      let criteriaWeightage = this.postForm.value.add_remove_more;
      console.log(this.editId);
      var postValues1 = {
        kraTemplateHeaderId  : this.editId,
        organizationId       : this.resultData.organizationId,
        templateCode         : this.postForm.value.templateCode,
        templateName         : this.postForm.value.templateName,
        templateDescription  : this.postForm.value.templateDescription,
        defaultTemplate      : "",
        createdBy            : this.userId,
        lastUpdatedBy        : this.userId,
        status : {
          listTypeValueId   : 1
        }
        // status               : this.resultData.status,
        // kratemplateLine      : this.postForm.value.add_remove_more,
      }
      console.log(postValues1);
      /**** Add & Remove More start here *****/
      let criteriaWeightageArray : any = [];
      
      for(let i=0; i<criteriaWeightage.length; i++)
      {
        var criteriaWeightageItems =    {
          kraTemplateHeaderLineId :  criteriaWeightage[i].kraTemplateHeaderLineId,
          criteriaName            :  criteriaWeightage[i].criteriaName,
          weightage               :  criteriaWeightage[i].weightage,
          negativeRatingCheck     :  criteriaWeightage[i].negativeRatingCheck,
          status : {
            listTypeValueId       : 1
          }
        }
        criteriaWeightageArray.push(criteriaWeightageItems)
      }
      
      for(let i = 0 ; i < this.deletedresourcetime.length; i++ )
      {
        var criteriaWeightageItems = {
          kraTemplateHeaderLineId    :  this.deletedresourcetime[i].kraTemplateHeaderLineId,
          criteriaName               :  this.deletedresourcetime[i].criteriaName,
          weightage                  :  this.deletedresourcetime[i].weightage,
          negativeRatingCheck        :  this.deletedresourcetime[i].negativeRatingCheck,
          status: {
            listTypeValueId : 4
          }
        }
        criteriaWeightageArray.push(criteriaWeightageItems);
      }
      console.log(criteriaWeightageArray);
      postValues1['kratemplateLine'] = criteriaWeightageArray;
      console.log("this.editId:"+this.editId);
      console.log("FInal JSON:"+postValues1);
      /**** Add & Remove More end here *****/
      this.loader=true;
      this.pmsService.editKRATemplate(this.editId,postValues1).subscribe((result: any) =>
      {
        this.submitButtonDisable= false;
        this.loader=false;
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
          this.submitted = false;
          
        
          setTimeout(() =>
          {
            this.router.navigate(['/home/kra-template']);
          }, 3000)
        }
        else
        {
          this.error = true;
          this.submitButtonDisable= false;
          console.log("Error Create:"+result);
          this.error_msg = result.description;
          setTimeout(() => 
          {
            this.error = false;
            // this.router.navigate(['/home/kra-template']);
          }, 3000)
        }
      }, err =>{
        console.log("Error result");
        this.error = true;
        this.submitButtonDisable= false;
        this.errorMsg = err.error.description;
        
      })
    }
    else //Add
    {
      var postValues = {
        organizationId       : this.organizationId,
        templateCode         : this.postForm.value.templateCode,
        templateName         : this.postForm.value.templateName,
        templateDescription  : this.postForm.value.templateDescription,
        defaultTemplate      : "",
        createdBy            : this.userId
      }
     console.log(this.organizationId);
     console.log("postValues",postValues);
      //Add & Remove More start here
      let criteriaWeightage = this.postForm.value.add_remove_more;
      let criteriaWeightageArray : any = [];
      for(let i=0; i<criteriaWeightage.length; i++)
      {
        var resource_availabilityobj = {
          criteriaName        : criteriaWeightage[i].criteriaName,
          weightage           : criteriaWeightage[i].weightage,
          negativeRatingCheck : criteriaWeightage[i].negativeRatingCheck,
          // status : {
          //   listTypeValueId       : 1
          // }
        }
        criteriaWeightageArray.push(resource_availabilityobj)
      }
      postValues['kratemplateLine'] = criteriaWeightageArray;
      //Add & Remove More end here
      this.submitButtonDisable= true;
      this.loader=true;
      this.pmsService.createKRATemplate(postValues).subscribe((result: any) =>
      {
        this.submitButtonDisable= false;
        this.loader=false;
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
          
          this.submitted = false;
          setTimeout(() =>
          {
            this.router.navigate(['/home/kra-template']);
          }, 3000)
        }
        else
        {
          this.error = true;
          this.submitButtonDisable= false;
          console.log("Error Create:"+result);
          this.error_msg = result.description;
          setTimeout(() => 
          {
            this.error = false;
            // this.router.navigate(['/home/kra-template']);
          }, 3000)
        }
      },
       err =>
      {
         console.log("Error result");
         this.error = true;
         this.submitButtonDisable= false;
          this.errorMsg = err.error.description;
      })
    }
  }
  
  // updateTotalWeightage(index) {
  //   console.log("Total Value",index);
  //   const addRemoveMoreArray = this.postForm.get('add_remove_more') as FormArray;
  //   const total = this.calculateTotalWeightage();
  //   if(total>100)
  //   {
  //     alert("Please add the weightage below or equal to 100.");
  //     (addRemoveMoreArray.at(index) as FormGroup).get('weightage').patchValue('');
  //   }
  // }

  // updateTotalWeightage(index: number) {
  //   let total = 0;
  //   this.add_remove().controls.forEach(control => {
  //     const weightage = parseFloat(control.get('weightage')?.value) || 0;
  //     total += weightage;
  //   });
  //   this.isAddButtonDisabled = total >= 100;
  // }

// calculateTotalWeightage(): number 
// {
//   const formArray = this.postForm.get('add_remove_more') as FormArray;
//   let total = 0;
//   formArray.controls.forEach((formGroup) => {
//     const weightageControl = formGroup.get('weightage');
//     if (weightageControl.valid  && weightageControl.value) {
//       total += parseFloat(weightageControl.value);
//     }
//   });
//    console.log("Total"+total);
//    return total;
// }


updateTotalWeightage(index: number) {
  console.log("Total Value", index);
  const addRemoveMoreArray = this.postForm.get('add_remove_more') as FormArray;
  const total = this.calculateTotalWeightage();
  
  if (total > 100) {
    alert("Please add the weightage below or equal to 100.");
    (addRemoveMoreArray.at(index) as FormGroup).get('weightage').patchValue('');
  }

  let currentTotal = 0;
  this.add_remove().controls.forEach(control => {
    const weightage = parseFloat(control.get('weightage')?.value) || 0;
    currentTotal += weightage;
  });
  this.isAddButtonDisabled = currentTotal >= 100;
}

calculateTotalWeightage(): number {
  let total = 0;
  this.add_remove().controls.forEach(control => {
    const weightage = parseFloat(control.get('weightage')?.value) || 0;
    total += weightage;
  });
  return total;
}


get KraLineFormArray() : FormArray {
  return this.postForm.controls["add_remove_more"] as FormArray
 }

//  filterAndValidateInput(event: Event) {
//   const inputElement = event.target as HTMLInputElement;
//   const cursorPosition = inputElement.selectionStart;
//   const filteredValue = inputElement.value.replace(/[^a-zA-Z0-9-_]/g, '');

//   if (filteredValue !== inputElement.value) {
//     inputElement.value = filteredValue;
//     this.postForm.controls['templateName'].setValue(filteredValue);

//     // Adjust cursor position to account for removed characters
//     if (cursorPosition !== null) {
//       setTimeout(() => {
//         inputElement.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
//       }, 0);
//     }
//   }

//   this.validateInput(event);  // Call your validation function here
// }

  filterAndValidateInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart;

    // Allow alphanumeric, hyphens, underscores, and spaces, but remove leading spaces
    const filteredValue = inputElement.value.replace(/^\s+/g, '').replace(/[^a-zA-Z0-9-_ ]/g, '').replace(/\s{2,}/g, ' ');

    if (filteredValue !== inputElement.value) {
      inputElement.value = filteredValue;
      this.postForm.controls['templateName'].setValue(filteredValue);

      // Adjust cursor position to account for removed characters
      if (cursorPosition !== null) {
        const newCursorPosition = Math.min(cursorPosition, filteredValue.length);
        setTimeout(() => {
          inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
      }
    }

    this.validateInput(event);  // Call your validation function here
  }




 

// validateInput(event) {
//   const input = event.target;
//   let value = input.value;

//   if (value.startsWith(' ')) {
//     value = value.trimStart();
//     input.value = value;
//   }

//   this.invalidInput = value.trim().length === 0;
// }

validateInput(event) {
  const input = event.target;
  let value = input.value;

  // Trim leading spaces
  value = value.trimStart();

  // Replace multiple spaces with a single space
  value = value.replace(/\s+/g, ' ');

  // Set the modified value back to the input
  input.value = value;

  // Check if the input is invalid (only spaces or empty)
  this.invalidInput = value.trim().length === 0;
}





// noNumber(event: any) {
//   const inputValue = event.target.value;

//   // Check if the pressed key is not a number or backspace
//   if (isNaN(Number(event.key)) && event.key !== 'Backspace') {
//     event.preventDefault();
//   }

//   // Check if the input length exceeds the maxlength
//   if (inputValue.length >= event.target.maxLength && event.key !== 'Backspace') {
//     event.preventDefault();
//   }
// }

noPaste(event: any) {
  // Prevent pasting non-numeric characters
  const clipboardData = event.clipboardData.getData('text/plain');
  if (isNaN(Number(clipboardData))) {
    event.preventDefault();
  }
     }
     noSpace(event: KeyboardEvent) {
       if (event.key === ' ') {
           event.preventDefault();
       }
   }
   noTab(event: KeyboardEvent) {
     // Prevent input if the Tab key is pressed
     if (event.key === 'Tab') {
       event.preventDefault();
      }
    }


onPaste(event: ClipboardEvent) {
  event.preventDefault(); // Prevent default paste behavior
  console.log('Pasting is not allowed!');
  this.pasteCount--;
}

  noNumber(event: any) {
    const pattern = /[0-9]/; // Removed the \. from the pattern
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


handleKeyPress(event) {
  if (event.key === ' ' && event.target.selectionStart === 0) {
    event.preventDefault();
  }
}

validateWeightage(index: number) {
  const weightageControl = this.add_remove().at(index).get('weightage');
  if (weightageControl.value === '0') {
      // You can show an alert or any other notification here
      alert('Weightage cannot be 0');
      weightageControl.setValue(''); // Clear the field if needed
  }
}



}
