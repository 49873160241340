import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';

@Component({
  selector: 'app-add-designation',
  templateUrl: './add-designation.component.html',
  styleUrls: ['./add-designation.component.scss']
})
export class AddDesignationComponent implements OnInit {
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeetData:any;
  designationNameExistStatus:any;
  updatedesignationNameExistStatus:any;
  designationId: any;
  isbtnDisable: boolean;
  clientId:number;
  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddDesignationComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any

  ) { }
  ngOnInit(): void {

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    this.clientId =  this.employeetData?.employee?.client?.clientId;
    /* Add Validation Designation Data */
    this.postForm = this.formBuilder.group({
      designationName : ['',[Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      designationDescription: ['', Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]
      // designationDescription : [null,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")],
    });

    this.designationId=this.edit_data.designationId

    if(this.edit_data)
    {
      // this.isbtnDisable=true
      this.loader=true
      this.settingsservice.getDesignationById(this.edit_data.designationId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;
        // this.isbtnDisable=false
        this.loader=false;


        this.postForm.patchValue({
          designationName: this.resultData.designationName,
          designationDescription: this.resultData.designationDescription,
        });
      });
      // this.isbtnDisable=false

    }
  }

  get validate() { return this.postForm.controls; }

  onSubmit() //form Submit Function
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.submitted = true;

    //stop here if form is invalid
    if (this.postForm.invalid || this.designationNameExistStatus == true) {return;}

    if(this.edit_data) //Edit
    {

      if(organizationId){
        let postValues = this.postForm.value;

        postValues['organization'] = {organizationId: organizationId}
        postValues['designationId'] = this.edit_data.designationId;
        postValues['status'] = this.edit_data.status;
  
        // this.backdrop = true;
        this.loader=true;
        this.isbtnDisable=true
  
  
        this.settingsservice.editDesignation(this.edit_data.designationId,postValues)
        .subscribe((user: any) =>
        {
          //this.router.navigate([ '/home/designation' ]);
          //window.location.reload();
  
          this.backdrop = false;
          this.loader = false;
          this.isbtnDisable=true
  
          // this.success = true;
  
          this.dialogref.close(user);
        }, err =>{
          this.backdrop = false;
          this.loader = false;
          // this.isbtnDisable=false
  
          this.dialogref.close(err.error);
          // this.error = true;
          // this.update_error_msg = err.error.message;
          // console.log(this.update_error_msg);
          //setTimeout(() => {this.error = false}, 2000)
        })
        // this.isbtnDisable=false
      }

      if(this.clientId){
        let postValues = this.postForm.value;

        postValues['client'] = {clientId: this.clientId}
        postValues['designationId'] = this.edit_data.designationId;
        postValues['status'] = this.edit_data.status;
  
        // this.backdrop = true;
        this.loader=true;
        this.isbtnDisable=true
  
  
        this.settingsservice.editDesignation(this.edit_data.designationId,postValues)
        .subscribe((user: any) =>
        {
          //this.router.navigate([ '/home/designation' ]);
          //window.location.reload();
  
          this.backdrop = false;
          this.loader = false;
          this.isbtnDisable=true
  
          // this.success = true;
  
          this.dialogref.close(user);
        }, err =>{
          this.backdrop = false;
          this.loader = false;
          // this.isbtnDisable=false
  
          this.dialogref.close(err.error);
          // this.error = true;
          // this.update_error_msg = err.error.message;
          // console.log(this.update_error_msg);
          //setTimeout(() => {this.error = false}, 2000)
        })
        // this.isbtnDisable=false
      }
     

    }
    else //Create
    {
      if(organizationId){

        let postValues = this.postForm.value;
        postValues['organization'] = {organizationId: organizationId}
  
        /* if(this.postForm.valid && this.designationNameExistStatus == true)
        {
          console.log("if condition")
          return
        } */
          this.isbtnDisable=true
  
        this.settingsservice.createDesignation(postValues).subscribe((user: any) =>
        {
          this.backdrop = false;
          this.loader = false;
          this.isbtnDisable=true;
  
          // this.success = true;
  
          this.dialogref.close(user);
        }, err =>{
          this.backdrop = false;
          this.loader = false;
          // this.isbtnDisable=false
  
          this.dialogref.close(err.error);
          // this.error = true;
          // this.update_error_msg = err.error.message;
          // console.log(this.update_error_msg);
          //setTimeout(() => {this.error = false}, 2000)
        })
      }
    
      // this.isbtnDisable=false

      
      if(this.clientId){
        let postValues = this.postForm.value;
        postValues['client'] = {clientId: this.clientId}
  
        /* if(this.postForm.valid && this.designationNameExistStatus == true)
        {
          console.log("if condition")
          return
        } */
          this.isbtnDisable=true
  
        this.settingsservice.createDesignation(postValues).subscribe((user: any) =>
        {
          this.backdrop = false;
          this.loader = false;
          this.isbtnDisable=true;
  
          // this.success = true;
  
          this.dialogref.close(user);
        }, err =>{
          this.backdrop = false;
          this.loader = false;
          // this.isbtnDisable=false
  
          this.dialogref.close(err.error);
          // this.error = true;
          // this.update_error_msg = err.error.message;
          // console.log(this.update_error_msg);
          //setTimeout(() => {this.error = false}, 2000)
        })
      }
    }
  }
  projectCode() {
    return this.validate.designationName.hasError('required')
      ? 'Please Enter Designation Name'
      : '';

     
     
  }
  descripErrror(){
    return this.validate.designationDescription.hasError('pattern')
    ? 'Please Enter Valid Designation Description Name'
    : '';
  }
  checkExistDesignationName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;


    if(this.postForm.value.designationName.length != 0)
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      if(this.edit_data)
      {
        //editData = this.edit_data;
        postValues['designationId'] = this.edit_data.designationId;

        this.settingsservice.updateCheckExistDesignationName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.designationNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.designationNameExistStatus = true;
          }
        })

      }
      else
      {
        this.settingsservice.createCheckExistDesignationName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.designationNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.designationNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.designationNameExistStatus = false;
    }
  }

}
