<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span routerLink="/home/branch" class="h5 sub-content headerDesign" >App System Settings</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="row m-0">
    <div class="card main-card col-md-6">
        <div class="row">
            <div class="col-md-12" align="center">
            <h5 class="text-center title">App System Settings</h5>
            </div>
        </div>
        <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
            <div  class="col-md-12" id="loginform">
                <!-- <label class="form-control-label">Date Format</label>
                <select formControlName="dateFormatId" placeholder="- Select Date Format -" class="form-control email-input select-placeholder">
                    <option [value]="null" selected="selected"> - Select Date Format - </option>
                    <option *ngFor="let row of activeDateFormatData; let i=index;" value="{{row.dateFormatId}}">{{row.dateFormatName}}</option>
                </select> -->
                <label class="form-control-label">Date Format</label>
<div class="">
    <select formControlName="dateFormatId" placeholder="- Select Date Format -" class="form-select form-select-solid form-select-lg" (change)="onDateFormatChange($event)">
        <option [ngValue]="null"> - Select Date Format - </option>
        <option *ngFor="let row of activeDateFormatData; let i=index;" [ngValue]="row.dateFormatId">{{row.dateFormatName}}</option>
    </select>
    <mat-error text="danger" *ngIf="dateValidation">Please Choose Any Date Format</mat-error>
</div>

                <!-- <mat-select formControlName="dateFormatId" placeholder=" Select Date Format"
                  class="form-control email-input select-placeholder">
                    <mat-option [value]="null"selected="selected" >-Select Date Format-</mat-option>

                    <mat-option *ngFor="let row of activeDateFormatData;let i=index;"  value="{{row.dateFormatId}}">
                        {{row.dateFormatName}} 
                    </mat-option>
                  </mat-select> -->
            </div>  
            <div align="end">
                <button type="button"
                 mat-raised-button
                 style="
                 margin-left: 10px;
                 background: none;
                 color: var(--Red-Color, #ff5d5d);
                 border: 1px solid #ddd !important;
                 height: 32px;
                 position: relative;
                 top: 2px;
                 font-weight: 550;
                 position: relative;
                 right: 5px;
               "
               class="btn search-btn mr-2" 
               routerLink="/home/settings"
               routerLinkActive="active" 
             [disabled]="isbtnDisable">Cancel</button>
                <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
                <button
                type="submit"
                mat-raised-button
                class="search-btn searchBtn"
                [disabled]="isbtnDisable"
               >Save
              </button>
                <!-- <a routerLink="/home/settings" routerLinkActive="active" mat-raised-button  class="mr-2 cancelDesign">
                    Cancel
                </a>
                
                
                <button type="submit" mat-raised-button color="primary">Save</button> -->
</div>
        </form>
    </div>
</div>



<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>

