<div class="resource-background">
    <div class="row">
        <div class="col-md-11 resource-title"  >
            <h1>Financial Year</h1>
        </div>
        <div class="col-md-1"  >
          
                <mat-icon style="color: red !important;" class="material-icons-outlined" (click)="closedialog()">close</mat-icon>          
            
        </div>
       
    </div>  
    <div class="card resource-card">
        <form>
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Branch : </label>
                    <label class="form-control-label-class"> &nbsp; {{resultData.branch.branchName}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Financial From Month / Year :  </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.financialFromMonth}} - {{resultData.financialFromYear}}</label>
                </div>                                                  
              
            </div>  
            <div class="row">  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Financial To Month / Year : </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.financialToMonth}} -{{resultData.financialToYear}}</label>
                </div>
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Description : </label>
                    <label class="form-control-label-class"> &nbsp; {{resultData.financialDescription}} </label>
                </div>
              
            </div>
        
     
       
        </form>  
        <div class="table-responsive">
            <table class="table  --table-bordered table-border-new">
                <thead class="t-head">
                    <tr>
                        <!-- <th class="text-center">S.No</th> -->
                    
                       
                        <th >Financial Period Month</th>
                        <th  >Financial Period Year</th>
                      
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of resultData.finanicalPeriod   let i=index;">
                        <!-- <td class="text-center">{{i+1}}</td> -->
                    
    
                        <td    >{{row.financialperiodMonth}}</td>
                        <td  >{{row.financialPeriodYear}}</td>
                   
                       
                       
                      
                    </tr>
                </tbody>
            </table>
        </div>
    
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
