import { Component, OnInit,Inject,ViewChild, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../app/providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../app/providers/properties';
import { PayrollService } from '../providers/payroll.service';
import { ShowerrorComponent } from '../showerror/showerror.component';

@Component({
  selector: 'app-import-employeerevenueadjustment',
  templateUrl: './import-employeerevenueadjustment.component.html',
  styleUrls: ['./import-employeerevenueadjustment.component.scss']
})
export class ImportEmployeerevenueadjustmentComponent implements OnInit {
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  fileInputLabel: string;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeefile :any;
  employeetData:any;
  usertData: any;
  orgId: any;
  clientList: any = [];
  errorrecords: any;
  showtable: boolean = false;

  p:number = 1;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  employeegroupnameexist: boolean;
  existMsg: string;
importerror: any;
  errormessagejson: any;


  constructor(
    private settingsservice: SettingsService,
    private payrollservice: PayrollService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<ImportEmployeerevenueadjustmentComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any,private dialog:MatDialog
  ) { }

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })    

    this.postForm = this.formBuilder.group({
      file : ['',[Validators.required]],
    });
  }
  get validate() { return this.postForm.controls; }




  onSubmit()
  {
    this.submitted = true;
    if (this.postForm.invalid) {return;}

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;

    const formData = new FormData();
    formData.append("file", this.postForm.get('file').value);
    formData.append("organizationId", organizationId);
   

    this.payrollservice.importemployeerevenueadjustment(formData).subscribe((result: any) =>
    {
      if(result.statusCode == 200)
      {
        //this.loader = true;
        console.log("success");
        this.success = true;
        this.sucess_msg = result.description;

        //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        //this.router.navigate(['/home/onboardings']);

        /* setTimeout(() => {
          this.router.navigate(['/home/employee']);
          //this.loader = false;
        }, redirectMsgTimeOut)
       */
     //   window.location.reload();
        //this.uploadFileInput.nativeElement.value = "";
       // this.fileInputLabel = undefined;

         this.success = true;
        this.sucess_msg = result.message; 
       
        this.dialogref.close(result);

      }
      else
      {
        console.log("Error");
        this.error = true;
        this.error_msg = result.description;
        this.errorrecords = result;
        this.dialogref.close(result);
        //setTimeout(() => {this.error = false}, redirectMsgTimeOut)
     //   window.location.reload();
      }
      if(result.statusCode >= 400)
        {
          //this.loader = true;
          this.success = true;
          // this.sucess_msg = result.description;
  
         
        
          // setTimeout(() => {this.success  = false;},responseMsgTimeOut)
          // this.backdrop = false;
          // this.loader = false;
          // this.success = true;
          this.dialogref.close(result);
  
        }
        else
        {
  
          
          this.error = true;
          this.error_msg = result.description;
          this.errorrecords = result;
          this.dialogref.close(result);
        }
      }, error => {
        console.log(error);
        this.dialogref.close(error);
      }); //Crea
    //Create end here
  }


  

  onFileSelect(event)
  {
   // alert("function called");
    //let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0)
    {
      const file = event.target.files[0];
      //this.fileToUpload = event.item(0);
      // console.log(file);

      /*   if (!_.includes(af, file.type)) {
        alert('Only EXCEL Docs Allowed!');
      } else { */
      // this.postForm.patchValue({
      //    file : file.name
      // })
        this.fileInputLabel = file.name;
        console.log("this.fileInputLabel" +this.fileInputLabel);
        this.postForm.get('file').setValue(file);
      ///}
    }
  }

 
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

  closedialog(){
    this.dialogref.close();
  }

}
