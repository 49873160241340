import { Component, Inject, OnInit } from '@angular/core';
import { SuperadminService } from '../../../providers/superadmin.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-module',
  templateUrl: './view-module.component.html',
  styleUrls: ['./view-module.component.scss']
})
export class ViewModuleComponent implements OnInit {

  constructor(
    private superadminservice: SuperadminService,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void
  {
    console.log("data : ",this.data);
  }

}
