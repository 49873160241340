import { Component, Inject, OnInit } from '@angular/core';
import { PayrollService } from '../providers/payroll.service';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../providers/employee.service';
import { MatDialog } from '@angular/material/dialog';
import { RecruitmentService } from '../providers/recruitment.service';
import { AppComponent } from '../app.component';
import { ExcelService } from '../services/excel.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { redirectMsgTimeOut } from '../providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { MatSelectChange } from '@angular/material/select';
import { ImportClientComponent } from '../import-client/import-client.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SuperadminService } from '../providers/superadmin.service';

@Component({
  selector: 'app-assignclientemployeelist',
  templateUrl: './assignclientemployeelist.component.html',
  styleUrls: ['./assignclientemployeelist.component.scss'],
})
export class AssignclientemployeelistComponent implements OnInit {
  clientForm: FormGroup;
  filterForm: FormGroup;
  today: any = new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  usertData: any;
  orgId: number;
  clientList: any;
  empList: any = [];
  empNum: number;
  nodatafound: boolean = true;
  update_error_msg: any;
  loader: any = false;
  backdrop: any = false;
  resultData: any = [];
  noRecordsFound: boolean = false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate: any;
  filterenddate: any;
  clientId: any;
  pagePerItem: any = 10;
  p: number = 1;
  tableSearch: any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg: any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported: any;
  exportingdata: any;
  beforepage: any;
  dateFormat: any;
  clientdata: any = {};
  // pagePerIist:any=10;
  update: boolean = false;
  entityId: any;
  mySelect: number = 5;
  clientChangeId: any;
  brnDisable: boolean = false;
  btnDisable: any;
  selectedEmployeeData: any = [];
  empolyeeapproverexist: boolean;
  submitted1: any;
  empolyeereadonly: boolean = false;
  disableFeildforAll: boolean = false;
  data: any;
  readonlyDisable: boolean = false;
  employeeNotFound: boolean = false;
  clientexist: boolean = false;
  search: any;
  nodata: boolean = false;
  storeEmpClientAssignId: any;
  showtable: boolean = false;
  employeeClientForm: FormGroup<any>;
  showupdate: any;
  clientLists: any = [];
  readonly: boolean = true;
  storeEmpId:any;
  showForm:boolean=false;
  constructor(
    private fb: FormBuilder,
    private payrollservice: PayrollService,
    private datepipe: DatePipe,
    public excelService: ExcelService,
    private ActivatedRoute: ActivatedRoute,
    private employee_service: EmployeeService,
    private dialog: MatDialog,
    private router: Router,
    private SuperadminService: SuperadminService,
    @Inject(AppComponent) public AppData: any,
    private recruitmentservice: RecruitmentService
  ) {}

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    // this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;
    // this.dateFormat = this.AppData.dateFormat;
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.entityId = this.usertData.entity.entityId;
    console.log(this.orgId);

    this.filterForm = this.fb.group({
      filterclient: [null, [Validators.required]],
    });

    this.clientForm = this.fb.group({
      client: [null, Validators.required],
      employee: [null, [Validators.required]],
    });

    // this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
    //   this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
    //   console.log(this.clientList);
    // })
    this.getAllAssignclientList()
   
  }
  getAllAssignclientList(){
    this.loader = true;
    this.showtable = false;
    this.payrollservice.getOverAlllisting(this.entityId).subscribe(
      (result: any) => {
        this.clientList = result;
        this.loader = false;
        this.showtable = true;
        console.log('over all list::', this.clientList);
        if (this.clientList.statusCode == 200) {
          this.clientList = result;
          this.showtable = true;
          console.log(this.clientList);
        } else {
          if (this.clientList.errorCode == 204) {
            console.log('nod ata entered!');

            this.clientList = [];
            this.nodata = true;
            this.showtable = true;
          }
        }
        this.nodata = false;
      },
      (err: any) => {
        console.log('error', err);
      }
    );
  }

  clearForm() {
    this.submitted=false;
    this.employeeClientForm.controls['clientName'].reset();
  }
  changefirstpage() {
    this.p = 1;
  }
  clearEmpArray() {
    this.empList = [];
  }
  clearClientArray() {
    this.clientList = [];
  }
  noClientFound() {}
  submit() {
    this.submitted=true;
    if(this.employeeClientForm.invalid){
      return
      
    }
    const formValues = this.employeeClientForm.value;

    if (formValues.employeeClientAssignId) {
      console.log('update entered!');

      var updatePayload = {
        employee: {
          employeeId: this.storeEmpId,
        },
        client: {
          clientId: formValues.clientName,
        },
        employeeClientAssignId: formValues.employeeClientAssignId,
      };
      console.log('payload', updatePayload);
// return
this.btnDisable=true;
      this.payrollservice
        .updateClientEmpAssign(formValues.employeeClientAssignId, updatePayload)
        .subscribe((response: any) => {
          this.btnDisable=true;
          console.log('response:', response);

          if (response.statusCode == 200) {
            this.success = true;
            this.btnDisable=true
            this.sucess_msg = response.message;
            setTimeout(() => {
              this.success = false;
              this.btnDisable=false
              this.getAllAssignclientList();
              this.showForm=false;
              this.employeeClientForm.reset();
            }, 5000);
           
          }
          else {
            this.btnDisable=false

            if(response.error.statusCode==409){
              this.error = true;
              this.btnDisable=false

              this.error_msg=response.error.message
              setTimeout(() => {
                this.error = false;
              }, 5000);
            }
            this.showForm=true;

          }
         
        },(err:any)=>{
          console.log("error:",err);
          this.btnDisable=false

          if(err.error.statusCode==409){
            this.error = true;
            this.btnDisable=false

              this.error_msg=err.error.message
              setTimeout(() => {
                this.error = false;
              }, 5000);
              this.showForm=true;

            }
          }
        
      );
    }
  }
  updateassignClientEmp(client: any) {
    console.log(client);
    this.storeEmpClientAssignId = client.employeeClientAssignId;
    console.log('storeEmpClientAssignId:', this.storeEmpClientAssignId);
    this.showupdate = true;
    this.showForm=true;
this.submitted=false;
    this.loader=true

    this.SuperadminService.getAllClientActiveEntity(this.entityId).subscribe(
      (d: any[]) => {
        // this.clientList = d.filter(client => client?.status?.listTypeValueId == 1);;

        console.log(d);
        this.loader=false;
        this.clientLists = d;
        console.log(this.clientLists);
      }
    );
    // this.payrollservice.getClientNameListBasedEntity(this.entityId).subscribe((result: any) => {
    //   this.clientLists = result.filter(client => client?.status?.listTypeValueId == 1);;
    //   console.log(this.clientLists);
    // })

    if (this.storeEmpClientAssignId) {
      this.loader = true;

      this.payrollservice
        .getEmpClientAssignGet(this.storeEmpClientAssignId)
        .subscribe((response: any) => {
          console.log('edit response::', response);
          this.loader = false;

          this.storeEmpId=response.employee.employeeId;

          this.employeeClientForm.patchValue({
            clientName: response.client?.clientId,
            // clientCode:response.client?.clientCode
            employee: response.employee?.firstName,
          });
          this.empolyeereadonly = true;
          this.readonly = true;
        });
    }
    this.employeeClientForm = this.fb.group({
      employeeClientAssignId: this.storeEmpClientAssignId,
      employee: [null, Validators.required],
      clientName: [null, Validators.required],
    });
    this.storeEmpClientAssignId = this.ActivatedRoute.snapshot.params.id;
    // this.loader=true;
  }

  Add_AssignEmployeclient() {
    this.router.navigate(['home/empassign']);
  }
  noEmployeeFound() {
    this.employeeNotFound = false;
    // this.selectedemployeeId = undefined;
    // this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    // this.employeeProjects.length = 0;
    this.employee = undefined;
    // this.employeeNumber = undefined;
    this.employeeNotFound = false;
  }
  clientSearch(event) {
    console.log(event.target.value);
    this.userId = event.target.value;
    console.log(this.userId);
    // Employee List Api
    this.empList.length == 0;
    if (this.orgId != undefined) {
      console.log('only its calling organization!!');

      this.payrollservice
        .getEmployeeNameList(this.userId, this.orgId)
        .subscribe((result: any) => {
          if (result.statusCode) {
            this.empList.length = 0;
          } else {
            //  console.log("else")
            this.empList = result;
          }
        });
    } else {
      if (this.entityId !== undefined) {
        console.log('only calling the entity login!!');

        this.employee_service
          .getEmployeeListKeywordsEntity(this.entityId, this.userId)
          .subscribe((result: any) => {
            if (result.statusCode) {
              this.empList.length = 0;
            } else {
              //  console.log("else")
              this.empList = result;
            }
          });
      }
    }
  }
  clientSelect(client) {
    console.log(client);
    this.employeeId = client.employeeId;
    this.employee = client.employeeNumber + '-' + client.firstName;
    console.log(this.employee);
    this.clientForm.patchValue({
      employee: this.employee,
    });
  }
  empSearch(event) {
    console.log(event.target.value);
    this.userId = event.target.value;
    console.log(this.userId);
    // Employee List Api
    this.empList.length == 0;
    if (this.orgId != undefined) {
      console.log('only its calling organization!!');

      this.payrollservice
        .getEmployeeNameList(this.userId, this.orgId)
        .subscribe((result: any) => {
          if (result.statusCode) {
            this.empList.length = 0;
          } else {
            //  console.log("else")
            this.empList = result;
          }
        });
    } else {
      if (this.entityId !== undefined) {
        console.log('only calling the entity login!!');

        this.employee_service
          .getEmployeeListKeywordsEntity(this.entityId, this.userId)
          .subscribe((result: any) => {
            if (result.statusCode) {
              this.empList.length = 0;
            } else {
              //  console.log("else")
              this.empList = result;
            }
          });
      }
    }
  }

  empSelect(emp) {
    console.log(emp);
    this.employeeId = emp.employeeId;
    this.employee = emp.employeeNumber + '-' + emp.firstName;
    console.log(this.employee);
    this.clientForm.patchValue({
      employee: this.employee,
    });
  }

  selectionChangeIndustryType(data: MatSelectChange) {
    console.log('value', data?.value);
    this.clientChangeId = data?.value;
  }

  searchSubmit() {
    this.p = 1;
    console.log('Filter Search');
    this.noRecordsFound = false;
    this.resultData = [];
    console.log(this.filterForm.value.filterclient);
    this.clientId = this.filterForm.value.filterclient;
    console.log(this.clientId);
    console.log(this.filterForm.value.filterstartdate);
    console.log(this.filterForm.value.filterenddate);
    this.filterstardate = this.datepipe.transform(
      this.filterForm.value.filterstartdate,
      'yyyy-MM-dd'
    );
    console.log('stardate', this.filterstardate);
    this.filterenddate = this.datepipe.transform(
      this.filterForm.value.filterenddate,
      'yyyy-MM-dd'
    );
    console.log('stardate', this.filterenddate);
    if (this.filterForm.invalid) {
      console.log('Required Field');
      alert('Please select Client');
      return;
    } else if (this.filterenddate == null && this.filterstardate != null) {
      alert('End Date is Required!!');
      // this.isbtndisabled = false;
      //       this.projectStatus=undefined
      // this.startDate=undefined
      // this.endDate=undefined
      // this.actionStatus=undefined

      return;
    } else if (this.filterstardate == null && this.filterenddate != null) {
      alert('Start Date is Required!!');

      return;
    } else {
      console.log('all conditions came out!!');
    }

    if (
      this.filterForm.value.filterclient != null &&
      this.filterstardate != null &&
      this.filterenddate != null
    ) {
      console.log('both all Filter');
      this.payrollservice
        .getClientandDateBasedFilterList(
          this.clientId,
          this.filterstardate,
          this.filterenddate
        )
        .subscribe((resultData: any) => {
          this.loader = false;
          this.backdrop = false;
          this.nodatafound = false;

          this.resultData = resultData;
          console.log(this.resultData);
          if (
            this.resultData.length == 0 ||
            this.resultData.length == undefined
          ) {
            this.nodatafound = true;
          }
          if (resultData.statusCode == 204) {
            this.noRecordsFound = true;
          }
        });
    } else {
      this.payrollservice
        .getClientFilterList(this.clientId)
        .subscribe((resultData: any) => {
          console.log('Client Filter');
          this.loader = false;
          this.backdrop = false;
          this.nodatafound = false;

          this.resultData = resultData;
          console.log(this.resultData);

          if (
            this.resultData.length == 0 ||
            this.resultData.length == undefined
          ) {
            this.nodatafound = true;
          }
          if (resultData.statusCode == 204) {
            this.noRecordsFound = true;
          }
        });
    }
  }

  clearTable() {
    this.resultData = [];
    this.noRecordsFound = false;
    console.log('clear');
    this.filterForm.clearValidators;
  }

  pageChages() {
    console.log('page changes');
    this.p = 1;
  }

  onSubmit() {
    console.log('form values::', this.clientForm.value);

    this.submitted = true;
    if (this.clientForm.invalid) {
      console.log('invalid');
      return;
    }
    if (this.clientupdateId) {
      //Update Cilent
      console.log(this.clientupdateId);
      let clientValues = this.clientForm.value;
      console.log(clientValues);
      var updateAssignCreate = {
        clientAssignId: this.clientupdateId,
        client: {
          clientId: clientValues.client,
        },
        status: {
          listTypeValueId: this.employeeStatus,
        },
        employee: {
          employeeId: this.employeeId,
        },
        startDate: this.datepipe.transform(
          clientValues.startdate,
          'yyyy-MM-dd'
        ),
      };
      if (clientValues.enddate) {
        updateAssignCreate['endDate'] = this.datepipe.transform(
          clientValues.enddate,
          'yyyy-MM-dd'
        );
      }
      console.log(updateAssignCreate);

      this.payrollservice
        .clientAssignmentUpdate(this.clientupdateId, updateAssignCreate)
        .subscribe(
          (result: any) => {
            this.brnDisable = true;
            if (result.statusCode == 200) {
              this.success = true;
              this.brnDisable = true;
              this.sucess_msg = result.description;
              this.clientForm.reset();
              this.submitted = false;
              this.searchSubmit();
              setTimeout(() => {
                this.success = false;
              }, redirectMsgTimeOut);
              this.clientupdateId = null;
              this.update = false;
              this.brnDisable = false;
            } else {
              this.error = true;
              this.error_msg = result.description;
              setTimeout(() => {
                this.error = false;
              }, redirectMsgTimeOut);
            }
          },
          (err) => {
            this.errorMsg = err.error.message;
          }
        );
    } //cretae client Assignment
    else {
      const formValue = this.clientForm.value;
      this.stardate = this.datepipe.transform(
        this.clientForm.value.startdate,
        'yyyy-MM-dd'
      );
      console.log('stardate', this.stardate);
      this.enddate = this.datepipe.transform(
        this.clientForm.value.enddate,
        'yyyy-MM-dd'
      );
      console.log('enddate', this.enddate);
      var clientAssignCreate = {
        fromClient: {
          clientId: formValue.client,
        },
        toClient: {
          clientId: formValue.toclient,
        },
        employee: {
          employeeId: this.employeeId,
        },
        startDate: this.stardate,
        // endDate : this.enddate,
        // ratePerDay : this.clientForm.value.ratePerDay,
        // ratePerMonth : this.clientForm.value.ratePerMonth
      };
      console.log('payload::', clientAssignCreate);
      //   return;
      this.payrollservice.clientAssignmrntcreate(clientAssignCreate).subscribe(
        (result: any) => {
          this.brnDisable = true;
          if (result.statusCode == 200) {
            this.success = true;
            this.brnDisable = true;
            this.sucess_msg = result.description;
            setTimeout(() => {
              this.success = false;
            }, redirectMsgTimeOut);
            this.clientForm.reset();
            this.submitted = false;
            // this.searchSubmit();
            setTimeout(() => {}, 5000);
          } else {
            this.error = true;
            this.error_msg = result.description;
            setTimeout(() => {
              this.error = false;
            }, redirectMsgTimeOut);

            setTimeout(() => {}, redirectMsgTimeOut);
          }
        },
        (err) => {
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.message;
          }, redirectMsgTimeOut);
        }
      );
    }
  }

  get f() {
    return this.employeeClientForm.controls;
  }

  // viewdata(data)
  // {
  //   console.log(data.clientAssignId);
  //   var dialogRef = this.dialog.open(EmpassignmentdetailComponent, {
  //     width: '470px',height:'fit-content',
  //     data:data.clientAssignId
  //   });
  // }
  updateStatus(id, status) {
    this.payrollservice
      .updateClientStatus(id, status)
      .subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.success = true;
          this.sucess_msg = result.message;
          setTimeout(() => {
            this.success = false;
          }, 5000);
          this.searchSubmit();
          // this.finicalyearList();
        } else {
          this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
      });
  }

  exportToExcel() {
    console.log('DATA : ', this.resultData);
    console.log(this.filterForm.value.filterclient);
    console.log(this.filterForm.value.filterstartdate);
    console.log(this.filterForm.value.filterenddate);
    if (
      this.filterForm.value.filterclient != null &&
      this.filterForm.value.filterstartdate != null &&
      this.filterForm.value.filterenddate != null
    ) {
      console.log('Both');
      this.dataExport.length = 0;
      this.exportingdata = this.resultData;
      for (let exportingdata of this.exportingdata) {
        this.exported = {
          ClientName: exportingdata.client.clientName,
          // ClientCode      : exportingdata.client.clientCode,
          EmployeeNo: exportingdata.employee.employeeNumber,
          EmployeeName:
            exportingdata.employee.firstName +
            ' ' +
            exportingdata.employee.lastName,
          StartDate: exportingdata.startDate,
          EndDate: exportingdata.endDate,
          Status: exportingdata.status.listTypeValueName,
        };
        this.dataExport.push(this.exported);
      }
      this.excelService.exportAsExcelFile(this.dataExport, 'ClientEmployees');
    } else if (
      this.filterForm.value.filterclient != null &&
      this.filterForm.value.filterstartdate == null &&
      this.filterForm.value.filterenddate == null
    ) {
      console.log('Client');
      this.dataExport.length = 0;
      this.exportingdata = this.resultData;
      for (let exportingdata of this.exportingdata) {
        this.exported = {
          ClientName: exportingdata.client.clientName,
          //ClientCode      : exportingdata.client.clientCode,
          EmployeeNo: exportingdata.employee.employeeNumber,
          EmployeeName:
            exportingdata.employee.firstName +
            ' ' +
            exportingdata.employee.lastName,
          StartDate: exportingdata.startDate,
          EndDate: exportingdata.endDate,
          Status: exportingdata.status.listTypeValueName,
        };
        this.dataExport.push(this.exported);
      }
      console.log('EX', this.exported);
      this.excelService.exportAsExcelFile(this.dataExport, 'ClientEmployees');
    }
  }
}
