import { Component, OnInit ,Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
//import { HrmsserviceService } from '../providers/hrmsservice.service';
import { RecruitmentService } from '../../providers/recruitment.service'
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-notification-resource-detail',
  templateUrl: './notification-resource-detail.component.html',
  styleUrls: ['./notification-resource-detail.component.scss']
})
export class NotificationResourceDetailComponent implements OnInit 
{
    resourceId: any;
    loader:any= true;
    resourceobj : any;
    constructor(private resourceservice :RecruitmentService,
    private activatedRoute : ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
    ) { }

    ngOnInit(): void 
    {
      this.resourceservice.findbyResourceId(this.data).subscribe((resource:any) =>
      {
        this.resourceobj = resource;
        this.loader = false;
        console.log(resource);
      })
    }
}