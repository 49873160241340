<style>
    .input-width {
      width: 100%;
    }
  </style>
  
  <div class="row" style="display: flex;flex-wrap: inherit;">
    <div class="float-left col-md-10">
      <h1 mat-dialog-title>{{projectName}}</h1>
    </div>
    <!-- <div class="float-right col-md-5">
      <button class="float-right btn m-0" type="button" (click)="addTask()">Add Task</button>
    </div> -->
    <div align="end" class="col-md-2">
      <!-- <span class="material-icons" style="color:#dc3545;transform: translate(-6px, 6px);"
      mat-dialog-close>cancel</span> -->
      <mat-icon
      class="material-icons"
      style="color: red !important; "
      mat-dialog-close
      color="custom-red"
      >clear</mat-icon
    >
    </div>
  </div>
  
  <form  [formGroup]="taskForm" (ngSubmit)="Create_task()">
    <div class="row"> 
      <div class="col-md-6" id="loginform">
        <label class="form-control-label">Select Task Group<span class="text-danger">*</span></label>
        <mat-select
            class="form-control email-input select-placeholder"
            formControlName="taskGroup"
            placeholder="Select Task Group">
            <mat-option *ngFor="let taskgroup of taskgrouplist" [value]="taskgroup.taskGroupId">{{taskgroup.taskGroupName}}
            </mat-option>
        </mat-select>
        <div *ngIf="(taskGroup?.invalid && submitted1 ) || taskGroup?.dirty">
          <small *ngIf="taskGroup.errors?.required" class="text-danger">Please select task group</small>
        </div> 
      </div>
      <div class="col-md-6" id="loginform">
        <!-- Task -->
        <div class="input-width" appearance="legacy">
          <label class="form-control-label">Task Name<span class="text-danger">*</span></label>
          <input matInput 
          (selectionChange) = "validateprojecttask()"
          type="text" 
          formControlName="taskName" 
          placeholder="Enter Task Name" 
          class="form-control email-input select-placeholder"/>
          <!-- <mat-select placeholder="Select TaskName" 
            formControlName="taskName" 
            (selectionChange) = "validateprojecttask()"
            class="form-control email-input select-placeholder" > 
            <mat-option *ngFor="let task of taskType" 
              [value]="task.commontaskName" 
              (click)="change(task.commontaskId,task.taskDescription)">
              {{task.commontaskName}}
            </mat-option>
          </mat-select> -->
          <!-- <div *ngIf="(submitted1 && taskName.invalid) || taskName.dirty">
            <small *ngIf="taskName.errors?.required" class="text-danger">Please enter task name</small>
          </div> -->
          <div
          *ngIf="
            taskName?.invalid  ||
            taskName?.dirty
          "
        >
          <small *ngIf="taskName.errors?.pattern" class="text-danger textErrorDesign"
            >Please enter valid Task Name</small
          >
        </div>
        <!-- <div
        *ngIf="
          taskForm.get('taskName').hasError('required') && submitted1
        "  class="text-danger"
      >
<small class="text-danger textErrorDesign" >Task Name is required
</small>           
</div> -->
<div
              *ngIf="
taskForm?.get('taskName').hasError('required') && (taskForm?.get('taskName').dirty || submitted1)              "
              class="text-danger"
            >
              <small class="text-danger" *ngIf="taskName.errors?.required"> 
                <span>Task Name is required </span>  </small>
            </div>
        </div>
        <span *ngIf="projecttaskexist" class="span-error"> Task Already Exist</span>
      </div>
    <!-- Description -->
    <!-- <mat-form-field class="input-width" appearance="legacy">
      <mat-label>
        Task Detail Description*
      </mat-label>
      <input type="text" matInput [(ngModel)]="description" placeholder="Enter Task Detail Description"
        formControlName="taskDescription" />
      <mat-error *ngIf="f.taskDescription">{{TaskDescription_()}}</mat-error>
    </mat-form-field> -->
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="input-width" >
          <label class="form-control-label">Task Detail Description<span class="text-danger">*</span></label>
          <textarea 
       matInput
          class="form-control email-input select-placeholder" 
          formControlName="taskDescription"
          [(ngModel)]="description" rows="1" 
          style="font-size: 15px; overflow-x: auto; overflow-y: hidden; min-height: 43px; border: none; padding: 8px;"
          placeholder="Task Detail Description"></textarea>
          <!-- <div *ngIf="(taskDescription.invalid && submitted1) || taskDescription.dirty">
            <small *ngIf="taskDescription.errors?.required" class="text-danger">Please enter task description</small>
          </div> -->
          <div
          *ngIf="
            taskDescription?.invalid  ||
            taskDescription?.dirty
          "
        >
          <small *ngIf="taskDescription.errors?.pattern" class="text-danger textErrorDesign"
            >Please enter valid Task Detail Description</small
          >
        </div>
        <!-- <div
        *ngIf="
          taskForm.get('taskDescription').hasError('required') && submitted1
        "  class="text-danger"
      >
<small class="text-danger textErrorDesign" >Task Detail Description is required
</small>           
</div> -->
<div
              *ngIf="
taskForm?.get('taskDescription').hasError('required') && (taskForm?.get('taskDescription').dirty || submitted1)              "
              class="text-danger"
            >
              <small class="text-danger" *ngIf="taskDescription.errors?.required"> 
                <span>Task Detail Description is Required </span>  </small>
            </div>

        </div>
      </div>
      <div class="col-md-6" id="loginform">
        <!-- Task Start Date -->
        <div class="input-width">
          <label class="form-control-label">Start Date<span class="text-danger">*</span></label>
          <!-- <input matInput 
            [matDatepicker]="picker" 
            (click)="picker.open()" 
            formControlName="taskStartDate"
            (dateChange)="someMethodName($event);       
                  checkStartDate()
            " 
          
            class="form-control email-input select-placeholder"
            placeholder="Enter start date"> -->
            <input 
            placeholder="Start Date"
            matInput
            readonly
            [matDatepicker]="picker"
            class="form-control email-input date-input select-placeholder"
            formControlName="taskStartDate"
            style="height: 38px; padding: 2px; margin-left: -2px;"
            type="text"
            [min]="this.min"
            [max]="this.min"
            (dateChange)="someMethodName($event);       
            checkStartDate()
      " 
            id="taskStartDate"
            >
          <mat-datepicker-toggle matSuffix [for]="picker"
          style="float: right;position: relative;bottom: 42px;">
          </mat-datepicker-toggle>
          <mat-datepicker #picker ></mat-datepicker>
          <div *ngIf="(taskStartDate?.invalid && submitted1 ) || taskStartDate?.dirty">
            <small *ngIf="taskStartDate.errors?.required" class="text-danger">Please Enter Start Date</small>
          </div> 
        </div>
      </div>
    </div>
  
    <!-- Employee -->
  
    <!-- <mat-form-field class="input-width">
          <mat-label>Employee*</mat-label>
          <mat-select #mySelct multiple formControlName="employee" multiple>
            <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">Select All</mat-option>
            <mat-option *ngFor="let e of  employeeList"  [value]="e.employeeId" (click)="tosslePerOne(allSelected.viewValue)">
                {{e.employeeName}}
            </mat-option>
            <button mat-raised-button color="primary" style="margin:0 18px 18px 18px;" (click)="add()">Add</button>
            <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
          </mat-select>
          <mat-error *ngIf="f.employee.hasError('required')">
            Please select Employee
          </mat-error>
      </mat-form-field> -->
  
    <!-- Working Hours -->
    <div class="row">
      <div class="col-md-6" id="loginform">
        <!-- Task End date -->
        <div class="input-width">
          <label class="form-control-label">End Date<span class="text-danger">*</span></label>
          <!-- <input
           matInput 
            [matDatepicker]="p"  readonly
            (click)="p.open()" 
            formControlName="taskEndDate" 
            (dateChange)="someMethodName($event);       
            checkStartDate()
      " 
            [disabled]="!taskForm.controls['taskStartDate'].value"
            class="form-control email-input select-placeholder"
            placeholder="Enter end date"> -->
            <input 
            placeholder="End Date"
                    matInput
                    readonly
                    [matDatepicker]="p"
                    class="form-control email-input select-placeholder text-truncate date-input p-0"
                    style="height: 38px; padding: 2px; margin-left: -2px"
                    formControlName="taskEndDate"
                    type="text"
                    (dateChange)="someMethodName($event);       
                    checkStartDate()
              " 
                    [disabled]="!taskForm.controls['taskStartDate'].value"
                    [min]="this.max"
                    [max]="this.max"
                    id="taskEndDate"
                   >
          <mat-datepicker-toggle matSuffix [for]="p"
          style="float: right;position: relative;bottom: 42px;">
          </mat-datepicker-toggle>
          <mat-datepicker #p ></mat-datepicker>
          <div *ngIf="(taskEndDate?.invalid &&  submitted1 ) || taskEndDate?.dirty">
            <small *ngIf="taskEndDate.errors?.required" class="text-danger">Please Enter End Date</small>
          </div>
        </div>
      </div>
      <!-- <div class="full-width col-sm-6" appearance="legacy">
        <label class="form-control-label">Hours<span class="text-danger">*</span></label>
        <input matInput 
          formControlName="hours" 
          placeholder="Enter Hours" 
          maxlength="2" 
          (keypress)="noNumber($event)"
          autocomplete="off"
          class="form-control email-input select-placeholder"  />
          <div *ngIf="(hours.invalid &&  submitted1 ) || hours.dirty">
            <small *ngIf="hours.errors?.required" class="text-danger">Please enter hours</small>
        </div>
      </div> -->
    </div>
    <!-- <div class="row">
      <div class="full-width col-sm-6" appearance="legacy">
        <label class="form-control-label">Minutes<span class="text-danger">*</span></label>
        <input matInput 
          formControlName="minutes" 
          placeholder="Enter Minutes" 
          maxlength="2" 
          (keypress)="noNumber($event)"
          autocomplete="off" 
          class="form-control email-input select-placeholder"/>
          <div *ngIf="(minutes.invalid &&  submitted1 ) || minutes.dirty">
              <small *ngIf="minutes.errors?.required" class="text-danger">Please enter minutes</small>
          </div>
      </div>
    </div> -->
  </form>
  <!-- [mat-dialog-close]="true"   -->
  
  <div mat-dialog-actions align="end">
    <button   mat-raised-button  class="searchBtnTime "  
    type="submit" [disabled]="taskForm.invalid  || projecttaskexist 
    
    || !disableSaveButton"  
     (click)="Create_task()"> <span class="m-2">Save</span></button>
  </div>
  
  <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
    <!-- <button
      *ngIf="importerror"
      (click)="showerrormessage()"
      class="btn btn-danger"
    >
      View Error Records
    </button> -->
  </div>
  <div
  *ngIf="loader "
  class="align-items-center text-center"
>
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>