<!-- <section>
  <div class="sub-header">
      <div class="col-md-12 mb-5">
          <div class="row">
              <div class="">
                  <span class="h5 sub-content">Apply Permission</span>
              </div>
              <div class="sub-header-buttons">
                <a routerLink="/home/mypermission-list" mat-raised-button title="Back" class="">Back</a>
            </div>
          </div>
      </div>
  </div>
</section> -->
<nav class="navbar  bg" style="margin-top: 67px;">
  <a class="navbar-brand navDesign" style="font-size: 22px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;">Apply Permission</a>


</nav>

<div class="common-content">
  <div class="card-new colDesign">
    <!-- <div class="card"> -->
    <div class="card-body">
      <div class="row mb-2">

        <div class="col-md-12">
          <marquee bahaviour="scroll" direvtion="left" >
            <h4>Note : You can take only 2hours Permission for a month...</h4>
          </marquee>
          <div class="col-sm-12">
            <mat-card>
              <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center" >
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;top:46%;left:48%" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <!-- <ng-container *ngIf="isLoading; else elseTemplate">
                  <div class="loading">
                    <h4 class="text-muted">loading....</h4>
                    <br />
                    <div class="spinner-grow text-secondary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-info" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-dark" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </ng-container> -->
              <!-- <ng-template #elseTemplate> -->
           
                <form [formGroup]="permissionForm" (ngSubmit)="onSubmit()">
                  
                  <!-- employeeName -->
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group --col-md-3" id="loginform">
                        <label class="form-control-label">Employee Name<span class="star"> *</span></label>
                        <input type="text" formControlName="employeeName"
                        class="form control
                        select-placeholder
                        email-input " style="outline:none;border-radius: 5px;" readonly />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <!-- employeeEmail -->
                      <div class="form-group" id="loginform">
                        <label class="form-control-label">Email-id<span class="star"> *</span></label>
                        <input type="text" formControlName="organizationEmail"
                        class="form control
                        select-placeholder
                        email-input " style="outline:none;border-radius: 5px;" readonly />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <!-- Date -->
                      <div class="form-group">
                        <label class="form-control-label">Date<span class="star"> *</span></label>
                        <mat-datepicker-toggle matSuffix [for]="picker"
                          style="float: right;position: relative;top: 32px;"></mat-datepicker-toggle>
                        <input (click)="picker.open()" [matDatepicker]="picker" [matDatepickerFilter]="myFilter"
                          placeholder="Select date" formControlName="permissionDate" readonly
                          class="form control
                                                select-placeholder
                                                email-input " style="outline:none;border-radius: 5px;margin-top:-3%" />
                        <mat-datepicker #picker ></mat-datepicker>
                        <!-- <div class="valid-msg" *ngIf="(f.permissionDate.invalid && f.permissionDate.touched) || f.permissionDate.dirty">
                                <span class="text-danger" *ngIf="f.permissionDate">{{date()}}</span>
                              </div> -->
                        <div *ngIf="(f.permissionDate.invalid && submitted) || f.permissionDate.dirty">
                          <mat-error *ngIf="f.permissionDate.errors?.required">{{date()}}</mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label">Hours<span class="star"> *</span></label>
                        <mat-select placeholder="Select Hour" formControlName="permissionHours"
                          class="form control
                                                select-placeholder
                                                email-input "  style="background-image:none;outline:none;border-radius: 5px;font-family: Mulish;">
                          <mat-option [value]="null"> --- Select Hours --- </mat-option>
                          <mat-option value="15">15 Minutes</mat-option>
                          <mat-option value="30">30 Minutes</mat-option>
                          <mat-option value="1">1 Hour</mat-option>
                          <mat-option value="2">2 Hour</mat-option>
                        </mat-select>
                        <!-- <div class="valid-msg" *ngIf="(f.permissionHours.invalid && f.permissionHours.touched) || f.permissionHours.dirty">
                              <span class="text-danger" *ngIf="f.permissionHours">{{hour()}}</span>
                            </div> -->
                        <div *ngIf="(f.permissionHours.invalid && submitted) || f.permissionHours.dirty">
                          <mat-error *ngIf="f.permissionHours.errors?.required">{{hour()}}</mat-error>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-control-label">Reason for Permission<span class="star"> *</span></label>
                    <textarea type="text" formControlName="permissionReason" placeholder="Enter permission reason!!!!"
                      class="form control
                      textarea
                                                email-input " style="outline:none;border-radius: 5px;" rows="2"
                      (input)="validateInput($event)"></textarea>
                    <!-- <div class="valid-msg" *ngIf="(f.permissionReason.invalid && f.permissionReason.touched) || f.permissionReason.dirty">
                          <span class="text-danger" *ngIf="f.permissionReason">{{reason()}}</span>
                        </div> -->
                    <div *ngIf="(f.permissionReason.invalid && submitted) || f.permissionReason.dirty">
                      <mat-error *ngIf="f.permissionReason.errors?.required">{{reason()}}</mat-error>
                      <mat-error *ngIf="(f.permissionReason.errors?.pattern )">
                        Please enter a valid permission reason
                    </mat-error> 
                    </div>
                   
                  </div>


                  <div class="form-group">
                    <div class="d-flex">
                      <div style="width: 45%;margin: 0 auto;">
                        <!-- <button mat-stroked-button class="cancel-button" style="font-family: Mulish;" color="accent"
                          type="button" (click)="Cancel()">
                          Cancel
                        </button> -->
                        <button type="reset"   class=""      mat-raised-button (click)="Cancel()"  style="

 
 margin-left: 7px;
   background: none;

   border: 1px solid #ddd;
   font-size: 14px;
   font-weight: 300;
   height: 40px !important;
 "  
  style="color:var(--Red-Color, #FF5D5D); height:35px; margin-left:91% "
 >Cancel</button>
                      </div>
                      <div style="width: 45%;margin: 0 auto;">
                        <!-- <button mat-stroked-button class="apply-button"style="font-family: Mulish;" color="primary" type="submit">
                              &nbsp;Apply&nbsp;
                            </button> -->
                        <!-- <button type="submit" mat-raised-button
                          class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                          [disabled]="!searchButton || permissionForm.invalid">
                          Apply
                        </button> -->
                        <button type="submit"
                        class="searchBtn mr-2 custom-button"
                        [disabled]="!searchButton || permissionForm.invalid">
                            Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

              <!-- </ng-template> -->
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br>{{sucess_msg}}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br>{{error_msg}}
</div>