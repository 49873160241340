<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content pr-3">Employee Approvers</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/add-employee-approvers" routerLinkActive="active" color="primary" mat-raised-button title="Employee Approvers" class="ml-2">
                        Add Employee Approver
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-8">
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row mb-3 pl-0">
                                <div class="col-md-4">
                                    <input type="search"  formControlName="keywords" autocomplete="off" class="form-control form-control-lg form-control-solid search-input" name="keywords" value="" placeholder="Search...">
                                    <small class="text-muted">Note : Rule Name, Employee Code, Employee Name</small>
                                </div>
                                <div class="ol-xl-1 col-lg-1 mb-2">
                                    <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-9 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0">
                                <select [(ngModel)]="pagePerItem" class="form-select form-select-solid form-select-lg report-filter">
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="500">500</option>
                                  <option value="1500">1500</option>
                              </select>
                            </div>
                        </div>
                    </div>
                </div>

                <table matSort (matSortChange)="sortData($event)" class="table --table-responsive table-bordered table-border-new">
                    <thead class="t-head">
                        <tr>
                            <!-- <th class="text-center">S.No</th> -->
                            <th class="text-center">Action</th>
                            <th mat-sort-header="branchCode">Rule Name</th>
                            <th mat-sort-header="branchName">Employee Code</th>
                            <th mat-sort-header="branchName">Employee Name</th>
                            <th mat-sort-header="branchName">Email</th>
                            <th class="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="resultData?.length > 0">
                        <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                            <!-- <td class="text-center">{{i+1}}</td> -->
                            <td class="text-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                  <mat-icon>more_vert</mat-icon>
                              </button>

                                <mat-menu #menu="matMenu" class="controls-list">
                                    <button mat-menu-item routerLink="/home/edit-branch/{{row.branchId}}" routerLinkActive="active">
                                      <mat-icon>edit</mat-icon>
                                      <span>Edit</span>
                                    </button>

                                    <!-- <button mat-menu-item routerLink="/home/view-employee" routerLinkActive="active">
                                      <mat-icon>visibility</mat-icon>
                                      <span>View</span>
                                    </button> -->

                                    <button mat-menu-item *ngIf="row.branchStatus.listTypeValueId == 1" (click)="updateStatus(row.branchId,2)">
                                      <mat-icon>done</mat-icon>
                                      <span>Inactive</span>
                                    </button>
                                    <button mat-menu-item *ngIf="row.branchStatus.listTypeValueId == 2" (click)="updateStatus(row.branchId,1)">
                                      <mat-icon>done</mat-icon>
                                      <span>Active</span>
                                    </button>
                                </mat-menu>
                            </td>
                            <td>{{row.branchCode}}</td>
                            <td>{{ucFirst(row.branchName)}}</td>
                            <td>{{row.branchCode}}</td>
                            <td>{{ucFirst(row.branchName)}}</td>
                            <td class="active-status text-center">
                                <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.branchStatus.listTypeValueId == 1">
                                  {{row.branchStatus.listTypeValueName}}
                              </span>

                                <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.branchStatus.listTypeValueId == 2">
                                  {{row.branchStatus.listTypeValueName}}
                              </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td style="text-align: center;" colspan="8" *ngIf="nodatafound">No Matching Records Found!</td>
                        </tr> 
                    </tbody> 
                </table>
                <div *ngIf="loader" class="align-items-center text-center">
                    <div class="spinner-border" 
                    style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;bottom: 0;" role="status"></div>
                </div>
                <div *ngIf="resultData?.length > 0 ">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-6">
                        Showing 1 to 6 of 6 entries
                    </div>
                    <div class="col-md-6 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                        <!-- <a href="javascript::void(0);">&laquo;</a>
                        <a href="javascript::void(0);">1</a>
                        <a href="javascript::void(0);">2</a>
                        <a href="javascript::void(0);">3</a>
                        <a href="javascript::void(0);">4</a>
                        <a href="javascript::void(0);">5</a>
                        <a href="javascript::void(0);">6</a>
                        <a href="javascript::void(0);">&raquo;</a> -->
                    <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>
 
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>