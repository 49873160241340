  import { NgModule } from '@angular/core';
  import { RouterModule, Routes } from '@angular/router';
  import { LoginComponent } from './adminComponents/login/login.component';
  import { ForgetPasswordComponent } from './adminComponents/forget-password/forget-password.component';
  import { DashboardComponent } from './adminComponents/dashboard/dashboard.component';
  import { HomeComponent } from './home/home.component';


  import { EmployeeComponent } from './employeeComponents/employee/employee.component';
  import { SettingsComponent } from './settingsComponents/settings/settings.component';
  import { AddEmployeeComponent } from './employeeComponents/add-employee/add-employee.component';
  import { MyAccountComponent } from './adminComponents/my-account/my-account.component';
  import { ViewEmployeeComponent } from './employeeComponents/view-employee/view-employee.component';
  import { DepartmentComponent } from './settingsComponents/departmentComponent/department/department.component';
  import { BranchComponent } from './settingsComponents/branchComponents/branch/branch.component';
  import { AddBranchComponent } from './settingsComponents/branchComponents/add-branch/add-branch.component';
  import { AddDepartmentComponent } from './settingsComponents/departmentComponent/add-department/add-department.component';
  import { QualificationComponent } from './settingsComponents/qualificationComponent/qualification/qualification.component';
  import { AddQualificationComponent } from './settingsComponents/qualificationComponent/add-qualification/add-qualification.component';
  import { CountryComponent } from './settingsComponents/locationsComponent/country/country.component';
  import { DesignationComponent } from './settingsComponents/designationComponent/designation/designation.component';
  import { AddDesignationComponent } from './settingsComponents/designationComponent/add-designation/add-designation.component';
  import { AddCountryComponent } from './settingsComponents/locationsComponent/add-country/add-country.component';
  // import { StateComponent } from './settingsComponents/locationsComponent/state/state.component';
  import { AddStateComponent } from './settingsComponents/locationsComponent/add-state/add-state.component';
  import { DistrictComponent } from './settingsComponents/locationsComponent/district/district.component';
  import { AddDistrictComponent } from './settingsComponents/locationsComponent/add-district/add-district.component';
  import { CityComponent } from './settingsComponents/locationsComponent/city/city.component';
  import { RoleComponent } from './settingsComponents/roleComponent/role/role.component';
  import { ListTypesComponent } from './settingsComponents/listTypeComponent/list-types/list-types.component';
  import { ListTypeValuesComponent } from './settingsComponents/listTypeComponent/list-type-values/list-type-values.component';
  import { AuthGuard } from './services/auth.guard';
  //Recruitment Module
  import { PositionComponent } from './recruitment/position/position.component';
  import { ClientComponent } from './recruitment/client/client.component';
  import { EmpassignmentComponent } from './empassignment/empassignment.component';
  import { ClientListComponent } from './recruitment/client-list/client-list.component';
  import { ResourceListComponent } from './recruitment/resource-list/resource-list.component';
  import { PositionListComponent } from './recruitment/position-list/position-list.component';
  import { ResourceComponent } from './recruitment/resource/resource.component';
  import { ResourceDetailComponent } from './recruitment/resource-detail/resource-detail.component';
  import { ResourceDetailListComponent } from './recruitment/resource-detail-list/resource-detail-list.component';
  //import { AdminDashboardComponent } from './recruitment/admin-dashboard/admin-dashboard.component';
  import { PositionDetailComponent } from './recruitment/position-detail/position-detail.component';
  import { RecruiterDashboardComponent } from './recruitment/recruiter-dashboard/recruiter-dashboard.component';
  import { ResourceDetailNewComponent } from './recruitment/resource-detail-new/resource-detail-new.component';
  import { PositionDetailNewComponent } from './recruitment/position-detail-new/position-detail-new.component';
  import { SuperAdminComponent } from './superadminComponents/loginComponent/super-admin/super-admin.component';
  import { ModulesComponent } from './superadminComponents/moduleComponent/modules/modules.component';
  import { SuperAdminDashboardComponent } from './superadminComponents/super-admin-dashboard/super-admin-dashboard.component';
  import { SuperAdminSettingsComponent } from './superadminComponents/superadminsettingsComponents/super-admin-settings/super-admin-settings.component';
  import { SuperAdminHomeComponent } from './super-admin-home/super-admin-home.component';
  import { OrganizationComponent } from './superadminComponents/organizationComponent/organization/organization.component';
  import { AddOrganizationComponent } from './superadminComponents/organizationComponent/add-organization/add-organization.component';
  import { ViewOrganizationComponent } from './superadminComponents/organizationComponent/view-organization/view-organization.component';
  import { MenusComponent } from './superadminComponents/menuComponent/menus/menus.component';
  import { AdminMenusHomeComponent } from './adminComponents/admin-menus-home/admin-menus-home.component';
  import { DateFormatsComponent } from './superadminComponents/dateFormatComponents/date-formats/date-formats.component';

  import { EmployeeRolesComponent } from './employeeRoleComponent/employee-roles/employee-roles.component';
  import { AddEmployeeRolesComponent } from './employeeRoleComponent/add-employee-roles/add-employee-roles.component';
  import { ApplicationSettingsComponent } from './settingsComponents/application-settings/application-settings.component';
  import { UserComponent } from './userComponents/user/user.component';
  import { AddUserComponent } from './userComponents/add-user/add-user.component';
  import { DoubleScrollComponent } from './double-scroll/double-scroll.component';
  import { TimeZonesComponent } from './superadminComponents/timeZoneComponents/time-zones/time-zones.component';
  import { AddTimeZoneComponent } from './superadminComponents/timeZoneComponents/add-time-zone/add-time-zone.component';
  import { PmsAdminDashboardComponent } from './pms/pms-admin-dashboard/pms-admin-dashboard.component';

  import { KraComponent } from './pms/kraComponent/kra/kra.component';
  import { AddKraComponent } from './pms/kraComponent/add-kra/add-kra.component';

  import { ApproverRulesComponent } from './pms/approvals/approverRulesComponent/approver-rules/approver-rules.component';
  import { AddApproverRulesComponent } from './pms/approvals/approverRulesComponent/add-approver-rules/add-approver-rules.component';
  import { EmployeeApporversComponent } from './pms/approvals/employeeApproverComponent/employee-apporvers/employee-apporvers.component';
  import { AddEmployeeApproversComponent } from './pms/approvals/employeeApproverComponent/add-employee-approvers/add-employee-approvers.component';

  import { AssignKraComponent } from './pms/assignKraComponent/assign-kra/assign-kra.component';
  import { AddAssignKraComponent } from './pms/assignKraComponent/add-assign-kra/add-assign-kra.component';
  import { AppraisalComponent } from './pms/appraisal/appraisal.component';
  import { AddAppraisalComponent } from './pms/add-appraisal/add-appraisal.component';

  import { OnboardingListComponent } from './recruitment/onboarding-list/onboarding-list.component';
  import { OnboardingComponent } from './recruitment/onboarding/onboarding.component';
  import { FinanicalyearlistComponent } from './settingsComponents/finanicalyearlist/finanicalyearlist.component';
  import { FinanicalyearComponent } from './settingsComponents/finanicalyear/finanicalyear.component';
  import { FinanicalyeardetailComponent } from './settingsComponents/finanicalyeardetail/finanicalyeardetail.component';
  import { ManageElementsListComponent } from './payroll/manage-elements-list/manage-elements-list.component';
  import { VariablepayqualiferComponent } from './settingsComponents/variablepayqualifer/variablepayqualifer.component';
  import { VariablepayqualiferlistComponent } from './settingsComponents/variablepayqualiferlist/variablepayqualiferlist.component';

  import { ListElementPercentageComponent } from './settingsComponents/element-percentage-component/list-element-percentage/list-element-percentage.component';
  import { AddElementPercentageComponent } from './settingsComponents/element-percentage-component/add-element-percentage/add-element-percentage.component';
  import { CompanySettingsComponent } from './settingsComponents/company-settings/company-settings.component';
  import { ShiftcalendarComponent } from './shift/shiftcalendar/shiftcalendar.component';
  import { ShifthoildaysExceptionsComponent } from './shift/shiftcalendar/shifthoildays-exceptions/shifthoildays-exceptions.component';
  import { ShiftComponent } from './shift/shift.component';
  import { ShiftemployeelistComponent } from './shift/shiftemployeelist/shiftemployeelist.component';
  import { ShiftemployeeComponent } from './shift/shiftemployee/shiftemployee.component';
  import { EmployeeGroupsComponent } from './employee-groups/employee-groups.component';
  import { EmployeeGroupsListComponent } from './employee-groups-list/employee-groups-list.component';
  import { EmployeeLeaveAddComponent } from './employee-leave/employee-leave-add/employee-leave-add.component';
  import { EmployeeLeaveListComponent } from './employee-leave/employee-leave-list/employee-leave-list.component';
  import { EmployeeRatinglistComponent } from './employee-ratinglist/employee-ratinglist.component';
  import { EmployeeRatingComponent } from './employee-rating/employee-rating.component';
  import { SalaryManageApprovalsComponent } from './approvers/salary-manage-approvals/salary-manage-approvals.component';
  import { ManageApproverListComponent } from './approvers/manage-approver-list/manage-approver-list.component';
  import { ManageApproverComponent } from './approvers/manage-approver/manage-approver.component';
  import { ManageEmployeeApproverComponent } from './approvers/manage-employee-approver/manage-employee-approver.component';
  import { ManageEmployeeApproverListComponent } from './approvers/manage-employee-approver-list/manage-employee-approver-list.component';
  import { RequestSalaryAdvanceComponent } from './request-salary-advance/request-salary-advance.component';
  import { SalaryAdvanceListComponent } from './salary-advance-list/salary-advance-list.component';
  import { LoanComponent } from './loan/loan.component';
  import { LoanListComponent } from './loan-list/loan-list.component';
  import { LoanManageApprovalsComponent } from './loan-manage-approvals/loan-manage-approvals.component';
  import { ExistLoanDetailsComponent } from './exist-loan-details/exist-loan-details.component';
  import { ManagePayslipComponent } from './payroll/manage-payslip/manage-payslip.component';
  import { ViewPayslipComponent } from './payroll/view-payslip/view-payslip.component';
  import { RecoveryAdjustmentComponent } from './recovery-adjustment/recovery-adjustment.component';
  import { RecoveryAdjustmentListComponent } from './recovery-adjustment-list/recovery-adjustment-list.component';
  import { EmpRevenueAdjustmentAddComponent } from './emp-revenue-adjustment/emp-revenue-adjustment-add/emp-revenue-adjustment-add.component';
  import { EmpRevenueAdjustmentListComponent } from './emp-revenue-adjustment/emp-revenue-adjustment-list/emp-revenue-adjustment-list.component';
  import { RefreshcomponentComponent } from './refreshcomponent/refreshcomponent.component';
  import { EmployeeAnnexureListComponent } from './employee-annexure-list/employee-annexure-list.component';
  import { EmployeeAnnexureComponent } from './employee-annexure/employee-annexure.component';
  import { VariablePayAddComponent } from './variable-pay/variable-pay-add/variable-pay-add.component';
  import { VariablePayListComponent } from './variable-pay/variable-pay-list/variable-pay-list.component';
  import { EmployeePayrollComponent } from './employee-payroll/employee-payroll.component';
  import { EmployeePayrollListComponent } from './employee-payroll-list/employee-payroll-list.component';
  import { EmployeePayrollDetailComponent } from './employee-payroll-detail/employee-payroll-detail.component';
  import { PfReportsComponent } from './reports/pf-reports/pf-reports.component';
  import { TdsReportsComponent } from './reports/tds-reports/tds-reports.component';
  import { ConsolidatereportComponent } from './reports/consolidatereport/consolidatereport.component';
  import { ConsolidatePayslipComponent } from './reports/consolidate-payslip/consolidate-payslip.component';
  import { ClientElementAssignmentComponent } from './client-elements/client-element-assignment/client-element-assignment.component';
  import { ClientWorkingdaysComponent } from './client-workingdays/client-workingdays.component';
  import { EmployeeworkingdaysComponent } from './employeeworkingdays/employeeworkingdays.component';
  import { EmployeewokingdayslistComponent } from './employeewokingdayslist/employeewokingdayslist.component';
  import { PreviewPayslipComponent } from './view-payslip/preview-payslip.component';
  import { ClientElementListComponent } from './client-elements/client-element-list/client-element-list.component';
  import { ClientEmployeeAnnexueComponent } from './client-employee-annexue/client-employee-annexue.component';
  import { CleintEmployeeAnnexueListComponent } from './cleint-employee-annexue-list/cleint-employee-annexue-list.component';
  import { UserroleComponent } from './superadminComponents/userrole/userrole.component';
  import { UserrolelistComponent } from './superadminComponents/userrolelist/userrolelist.component';
  // import { RoleComponent } from './settingsComponents/roleComponent/role/role.component';
  import { ClientRunPayrollComponent } from './client-run-payroll/client-run-payroll.component';
  import { ClientemployeePayrollListComponent } from './clientemployee-payroll-list/clientemployee-payroll-list.component';
  import { ManageRecoveryadjustmentComponent } from './manage-recoveryadjustment/manage-recoveryadjustment.component';
  import { CreateManageRecoveryadjustmentComponent } from './create-manage-recoveryadjustment/create-manage-recoveryadjustment.component';
  import { ClientEmployeeRevenueAdjustmentListComponent } from './client-employee-revenue-adjustment-list/client-employee-revenue-adjustment-list.component';
  import { ClientEmployeeRevenueAdjustmentComponent } from './client-employee-revenue-adjustment/client-employee-revenue-adjustment.component';
  import { ClientemployeePaylispListComponent } from './clientemployee-paylisp-list/clientemployee-paylisp-list.component';
  import { MatclientcomponentsComponent } from './employeeComponents/matclientcomponents/matclientcomponents.component';
  import { ClientTaxBreakageComponent } from './client-tax-breakage/client-tax-breakage.component';
  import { ClientTaxBreakageListComponent } from './client-tax-breakage-list/client-tax-breakage-list.component';
  import { AppraisalReportsComponent } from './pms/appraisal-reports/appraisal-reports.component';
  import { AdminReportsComponent } from './pms/admin-reports/admin-reports.component';
  import { Component } from '@fullcalendar/core/preact';
  import { ClientemployeeConslidatepayslipComponent } from './reports/clientemployee-conslidatepayslip/clientemployee-conslidatepayslip.component';
  //import { AdminDashboardComponent } from './recruitment/admin-dashboard/admin-dashboard.component';
  import { MyleavelistComponent } from './leave/myleavelist/myleavelist.component';
  import { RequirementListComponent } from './recruitment/requirement/requirement-list/requirement-list/requirement-list.component';
  import { RequirementAddComponent } from './recruitment/requirement/requirement-add/requirement-add/requirement-add.component';
  import { RequirementDetailComponent } from './recruitment/requirement/requirement-detail/requirement-detail.component';
  import { AdminDashboardComponent } from './recruitment/admin-dashboard/admin-dashboard.component';
  import { AdminDashboardNewComponent } from './recruitment/admin-dashboard-new/admin-dashboard-new.component';
  import { FeedbackFormComponent } from './recruitment/resource-feedback/feedback-form/feedback-form.component';
  import { ResourceFeedbackComponent } from './recruitment/resource-feedback/resource-feedback.component';
  import { ShareFeedbackFormComponent } from './recruitment/resource-feedback/share-feedback-form/share-feedback-form.component';
  import { ViewAppraisalComponent } from './pms/view-appraisal/view-appraisal.component';
  import { ApprisalviewComponent} from './pms/apprisalview/apprisalview.component'
  import { OtReportsComponent } from './reports/ot-reports/ot-reports.component';
import { EsireportComponent } from './reports/esireport/esireport.component';
import { HdfcbankreportComponent } from './reports/hdfcbankreport/hdfcbankreport.component';
import { ReportshomeComponent } from './reports/reportshome/reportshome.component';
  import { LeaveDashboardComponent } from './leave/leave-dashboard/leave-dashboard.component';
  import { ApplyBusinessTravelComponent } from './leave/apply-business-travel/apply-business-travel.component';
  import { ApplyWorkFromHomeComponent } from './leave/apply-work-from-home/apply-work-from-home.component';
  import { ApplyPermissionComponent } from './leave/apply-permission/apply-permission.component';
  import { ApplyLeaveComponent } from './leave/apply-leave/apply-leave.component';
  import { MybusinessTravelListComponent } from './leave/mybusiness-travel-list/mybusiness-travel-list.component';
  import { MypermissionListComponent } from './leave/mypermission-list/mypermission-list.component';
  import { MyWFHListComponent } from './leave/my-wfh-list/my-wfh-list.component';
  import { ManageLeaveComponent } from './leave/manage-leave/manage-leave.component';
  import { LeaveTypeListComponent } from './leave/leave-type-list/leave-type-list.component';
  import { LeaveAdjustmentListComponent } from './leave/leave-adjustment-list/leave-adjustment-list.component';
  import { LeaveAdjustmentComponent } from './leave/leave-adjustment/leave-adjustment.component';
  import { TypeListComponent } from './leave/type-list/type-list.component';
  import { TypeAddComponent } from './leave/type-add/type-add.component';
  import { EmployeeTimeSettingsComponent } from './leave/employee-time-settings/employee-time-settings.component';
  import { ManagePermissionComponent } from './leave/manage-permission/manage-permission.component';
  import { ManageWorkfromhomeComponent } from './leave/manage-workfromhome/manage-workfromhome.component';
  import { ManageBusinesstravelComponent } from './leave/manage-businesstravel/manage-businesstravel.component';
  import { ManageWithdrawalComponent } from './leave/manage-withdrawal/manage-withdrawal.component';
  import { LeaveCalendarComponent } from './leave/leave-calendar/leave-calendar.component';
  import { LeaveReportComponent } from './leave/leave-report/leave-report.component';
  import { PermissionReportComponent } from './leave/permission-report/permission-report.component';
  import { WorkfromhomeReportComponent } from './leave/workfromhome-report/workfromhome-report.component';
  import { BusinesstravelReportComponent } from './leave/businesstravel-report/businesstravel-report.component';
  import { WithdrawReportComponent } from './leave/withdraw-report/withdraw-report.component';
  import { EmpLeaveDashboardComponent } from './leave/emp-leave-dashboard/emp-leave-dashboard.component';
  import { ProjectManagementAdminComponent } from '../app/project-management/project-management/project-management-admin/project-management-admin.component';
  import { TimeEmployeeDashboardComponent } from '../app/project-management/project-management/time-employee-dashboard/time-employee-dashboard.component';
  import { TimesheetreportsComponent } from '../app/project-management/project-management/timesheetreports/timesheetreports.component';
  import { ProjectsComponent } from '../app/project-management/project-management/projects/projects.component';
  import { EmpTimelogsComponent } from '../app/project-management/project-management/emp-timelogs/emp-timelogs.component';
  import { ClientEsiChallanComponent } from './reports/client-esi-challan/client-esi-challan.component';
  import { ClientPfChallanComponent } from './reports/client-pf-challan/client-pf-challan.component';
  import { ClientNetpayChallanComponent } from './reports/client-netpay-challan/client-netpay-challan.component';
  import { EmployeeLeaveDashboardComponent } from './leave/employee-leave-dashboard/employee-leave-dashboard.component';
  import { WageRegisterComponent } from './wage-register/wage-register.component';
  import { ViewPdfComponent } from './view-pdf/view-pdf.component';
  import { ClientIfReportsComponent } from './reports/client-if-reports/client-if-reports.component';
  import { AssaignProjectComponent } from '../app/project-management/project-management/assaign-project/assaign-project.component';
  import { TaskgroupComponent } from '../app/project-management/project-management/taskgroup/taskgroup.component';
  import { ProjectstageComponent } from '../app/project-management/project-management/projectstage/projectstage.component';
  import { ManageWorkHoursComponent } from './settingsComponents/manage-work-hours/manage-work-hours.component';
  import { DailytimesheetComponent } from '../app/project-management/project-management/dailytimesheet/dailytimesheet.component';
  import { MomreportsComponent } from '../app/project-management/project-management/momreports/momreports.component';
  import { ActionitemreportComponent } from '../app/project-management/project-management/actionitemreport/actionitemreport.component';
  import { CreatemomComponent } from '../app/project-management/project-management/createmom/createmom.component';
  import { AddMultipleTaskComponent } from '../app/project-management/project-management/add-multiple-task/add-multiple-task.component';
  import { AssignEmployeeTaskComponent } from '../app/project-management/project-management/assign-employee-task/assign-employee-task.component';
  // import { AssignEmpTaskComponent } from './project-management/assign-emp-task/assign-emp-task.component';
  // import { AssignEmpTaskEditComponent } from './project-management/assign-emp-task/assign-emp-task-edit/assign-emp-task-edit.component';
  import { ManageTimesheetComponent } from '../app/project-management/project-management/manage-timesheet/manage-timesheet.component';
  import { PreviewComponent } from './preview/preview.component';
  import { UpdatemomComponent } from '../app/project-management/project-management/updatemom/updatemom.component';
  import { ConsolidateTimesheetComponent } from '../app/project-management/project-management/consolidate-timesheet/consolidate-timesheet.component';
  import { EmployeeTaskReportComponent } from '../app/project-management/project-management/employee-task-report/employee-task-report.component';
  import { EmpReportComponent } from '../app/project-management/project-management/emp-report-module/emp-report.component';
import { AddassignapproverComponent } from '../app/project-management/project-management/addassignapprover/addassignapprover.component';
import { UpdateAssignComponent } from '../app/project-management/project-management/update-assign/update-assign.component';
import { AssignapproverComponent } from '../app/project-management/project-management/assignapprover/assignapprover.component';
import { CategoryComponent } from './reimbustment/category/category.component';
import { AdvanceApproverStatusComponent } from './advance-approver-status/advance-approver-status.component';
import { ExpenseReimbursementComponent } from './reimbustment/expense-reimbursement/expense-reimbursement.component';
import { ExperexpenseReimbursementCreateComponent } from './reimbustment/experexpense-reimbursement-create/experexpense-reimbursement-create.component';
import { PaymentAdviceComponent } from './reimbustment/payment-advice/payment-advice.component';
import { AdvanceComponent } from './reimbustment/advance/advance.component';
import { AddProjectComponent } from '../app/project-management/project-management/add-project/add-project.component';
import { OrganizationComplianceComponent } from './organization-compliance/organization-compliance.component';
import { OrganizationComplianceListComponent } from './organization-compliance-list/organization-compliance-list.component';
import { OrganizationworkingdaysComponent } from './organizationworkingdays/organizationworkingdays.component';
import { EmployeePayslipComponent } from './payroll/employee-payslip/employee-payslip.component';
import { PayrolldashboardComponent } from './payroll/payrolldashboard/payrolldashboard.component';
import { ManageApprovalsComponent } from './reimbustment/manage-approvals/manage-approvals.component';
import { AdvanceapproverComponent } from './reimbustment/advanceapprover/advanceapprover.component';
import { OnboardingdetailComponent } from './recruitment/onboardingdetail/onboardingdetail.component';
import { ShareresoursefeedbackComponent } from './recruitment/shareresoursefeedback/shareresoursefeedback.component';
import { EmployeeCalendarComponent } from './employee-calendar/employee-calendar.component';
import { StateComponent } from './settingsComponents/locationsComponent/state/state.component';
import { BusinessgroupComponent } from './businessgroup/businessgroup.component';
import { LegalentityComponent } from './legalentity/legalentity.component';
import { CorebusinessunitComponent } from './corebusinessunit/corebusinessunit.component';
import { EntityComponent } from './superadminComponents/entity/entity.component';
import { AddsupplierComponent } from './superadminComponents/addsupplier/addsupplier.component';
import { BusinessGroupListingComponent } from './business-group-listing/business-group-listing.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
// import { LegalEntityListComponent } from './legal-entity-list/legal-entity-list.component';
import { LegalListComponent } from './legal-list/legal-list.component';
import { AddbusinessunitComponent } from './addbusinessunit/addbusinessunit.component';
import { ListentityComponent } from './listentity/listentity.component';
import { OrganizationclientlistingComponent } from './organizationclientlisting/organizationclientlisting.component';
import { ClientcreationComponent } from './clientcreation/clientcreation.component';
import { ClientDataListComponent } from './client-data-list/client-data-list.component';

import { AssignEmployeeComponent} from './assign-employee/assign-employee.component';
import { AssignEmployeeListComponent } from './assign-employee-list/assign-employee-list.component';
import { AssignclientsupplierComponent } from './assignclientsupplier/assignclientsupplier.component';
import { SelectWorkingDaysComponent } from './select-working-days/select-working-days.component';
import { OrgWorkingDaysComponent } from './org-working-days/org-working-days.component';
import { SelectElementsComponent } from './select-elements/select-elements.component';
import { OrganizationElementAssignmentComponent } from './organization-element-assignment/organization-element-assignment.component';

import { AssignclientListComponent } from './assignclient-list/assignclient-list.component';

import { ManageannouncementComponent } from './manageannouncement/manageannouncement.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import {NewsandfeedComponent} from './newsandfeed/newsandfeed.component';
import { SelectTaxBreakupComponent } from './select-tax-breakup/select-tax-breakup.component';
import { SelectEmployeeTransferComponent } from './select-employee-transfer/select-employee-transfer.component';
import { SelectManageElementsComponent } from './select-manage-elements/select-manage-elements.component';
import { AssignclientemployeelistComponent } from './assignclientemployeelist/assignclientemployeelist.component';
import { EmployeeRoleComponent } from './project-management/project-management/employee-role/employee-role.component';
import { AddrecordsettingComponent } from './addrecordsetting/addrecordsetting.component';

  const routes: Routes = [

    // { path:'' , redirectTo:'login' , pathMatch:'prefix' },
    { path:'' , redirectTo:'login' , pathMatch:'full' },
    { path: 'lazy', loadChildren: () => import('./app.component').then(m => m.AppComponent)},

        // { path:'' ,  component: LoginComponent },


    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgetPasswordComponent },
    { path: 'super-admin', component: SuperAdminComponent },
    { path: 'refreshcomponent', component: RefreshcomponentComponent },

    {
      path: 'share-feedback-form/:resourceId',
      component: ShareFeedbackFormComponent,
    },
    {
      path: 'share-feedback-form/:resourceId/:feedbackId',
      component: ShareFeedbackFormComponent,
    },
   
    // { path: 'preview-payslip/:id', component: PreviewPayslipComponent },
    // {
    //   path: 'share-feedback-form/:resourceId',
    //   component: ShareFeedbackFormComponent,
    // },
    // {
    //   path: 'share-feedback-form/:resourceId/:feedbackId',
    //   component: ShareFeedbackFormComponent,
    // },
    // { path: 'share-resource-feedbacks/:resourceId/:feedbackId', component:ShareresoursefeedbackComponent},
    // { path: 'share-resource-details/:resourceId',component:OutsideResourcedetailComponent},

    {
      //canActivate:[AuthGuard],
      path: 'super-admin-home',
      component: SuperAdminHomeComponent,
      children: [
        { path: 'settings', component: SuperAdminSettingsComponent },
        { path: 'dashboard', component: SuperAdminDashboardComponent },
        { path: 'modules', component: ModulesComponent },
        { path: 'menus', component: MenusComponent },
        // { path: 'organization', component: OrganizationComponent },
        { path: 'entity', component: ListentityComponent },
        { path: 'add-entity', component: EntityComponent },
        { path: 'edit-entity/:enitytyId', component: EntityComponent },

        // { path: 'add-organization', component: AddOrganizationComponent },
        { path: 'preview-payslip/:id', component: PreviewPayslipComponent },
        
        // {
        //   path: 'edit-organization/:organizationId',
        //   component: AddOrganizationComponent,
        // },
        // {
        //   path: 'view-organization/:organizationId',
        //   component: ViewOrganizationComponent,
        // },
        { path: 'date-formats', component: DateFormatsComponent },

        { path: 'country', component: CountryComponent },
        { path: 'add-country', component: AddCountryComponent },
        { path: 'state', component: StateComponent },
        { path: 'add-state', component: AddStateComponent },
        { path: 'district', component: DistrictComponent },
        { path: 'add-district', component: AddDistrictComponent },
        { path: 'city', component: CityComponent },
        { path: 'add-city', component: CityComponent },

        { path: 'list-types', component: ListTypesComponent },
        {
          path: 'list-type-values/:listTypeValueId',
          component: ListTypeValuesComponent,
        },

        { path: 'time-zones', component: TimeZonesComponent },
        { path: 'user-roles', component: UserroleComponent },
        { path: 'user-roles-list', component: UserrolelistComponent },
      ],
    },
    {
    path: 'home', component: HomeComponent,canActivate:[AuthGuard], children : [
        // { path: '', redirectTo:'admin-home', pathMatch:'prefix'},
        { path: '', redirectTo:'newsfeed', pathMatch:'prefix'},
        { path: 'dashboard', component: DashboardComponent },


        
        { path: 'admin-home', component: AdminMenusHomeComponent },
        //{ path: 'dashboard', component: AdminDashboardComponent },
        { path: 'my-profile', component: MyAccountComponent },
        { path: 'settings', component: SettingsComponent },
        { path: 'employee', component: EmployeeComponent },
        { path: 'add-employee', component: AddEmployeeComponent },
        { path: 'add-employee', component: AddEmployeeComponent },
        { path: 'view-employee/:employeeId', component: ViewEmployeeComponent },
        { path: 'edit-employee/:employeeId', component: AddEmployeeComponent },

        // organization
        // { path: 'add-organization', component: AddOrganizationComponent },
        // {path: 'edit-organization/:organizationId',component: AddOrganizationComponent,},
        // {path: 'view-organization/:organizationId',component: ViewOrganizationComponent,},
        // { path: 'organization', component: OrganizationComponent },
        { path: 'add-organization', component: AddOrganizationComponent },
        {path: 'edit-organization/:organizationId',component: AddOrganizationComponent,},
        {path: 'view-organization/:organizationId',component: ViewOrganizationComponent,},
        { path: 'organization', component: OrganizationclientlistingComponent },
        { path: 'add-client', component: ClientcreationComponent },
        { path: 'edit-clients/:clientId', component: ClientcreationComponent },
        { path: 'client-listing', component: ClientDataListComponent },


        
        // business group
        {path: 'business-list',component:BusinessGroupListingComponent},
        {path:'business-group',component:BusinessgroupComponent},
        { path: 'business-group/:id', component: BusinessgroupComponent },
        {path: 'edit-business-group/:businessGroupId',component:BusinessGroupListingComponent},

        // business unit
        {path:'add-business-unit',component:AddbusinessunitComponent},
        {path:'edit-business-unit/:businessUnitId',component:AddbusinessunitComponent},
        {path:'business-unit',component:CorebusinessunitComponent},

        // supplier 
        {path: 'supplier-list',component:SupplierListComponent},
        {path:'add-supplier',component:AddsupplierComponent},
        {path:'edit-supplier/:supplierId',component:AddsupplierComponent},

        // Assign Client Supplier
        {path: 'ClientSup-add',component:AssignclientsupplierComponent},
        {path:'ClientSup-list',component:AssignclientListComponent},
        {path: 'ClientSup-edit',component:AssignclientsupplierComponent},

        // legal entity
        {path:'legal-entity',component:LegalentityComponent},
        {path:'edit-legal-entity/:legalentityId',component:LegalentityComponent},
        {path:'legal-list',component:LegalListComponent},

        //Add Client Employee
        { path: 'empassign', component: EmpassignmentComponent },
        {path:'empassign/:employeeClientAssignId',component:EmpassignmentComponent},
        {path:'assigns-cliemp-list',component:AssignclientemployeelistComponent},
          
      
          


        { path: 'department', component: DepartmentComponent },
        { path: 'add-department', component: AddDepartmentComponent },
        { path: 'branch', component: BranchComponent },
        { path: 'add-branch', component: AddBranchComponent },
        { path: 'edit-branch/:branchId', component: AddBranchComponent },
        { path: 'qualification', component: QualificationComponent },
        { path: 'add-qualification', component: AddQualificationComponent },
        { path: 'designation', component: DesignationComponent },
        { path: 'add-desgination', component: AddDesignationComponent },
        { path: 'country', component: CountryComponent },
        { path: 'add-country', component: AddCountryComponent },
        { path: 'state', component: StateComponent },
        { path: 'add-state', component: AddStateComponent },
        { path: 'district', component: DistrictComponent },
        { path: 'add-district', component: AddDistrictComponent },
        { path: 'city', component: CityComponent },
        { path: 'user-role', component: RoleComponent },
        { path: 'employee-user-role', component: EmployeeRoleComponent },
        { path: 'add-city', component: CityComponent },
        { path: 'list-types', component: ListTypesComponent },
        { path: 'list-type-values/:listTypeValueId', component: ListTypeValuesComponent },
        { path: 'financialyear', component: FinanicalyearlistComponent },
        { path: 'addfinancialyear', component: FinanicalyearComponent },
        { path: 'updatefinancialyear/:financialyear', component: FinanicalyearComponent },
        { path: 'variablepayqualifier', component: VariablepayqualiferlistComponent },
        { path: 'organizationcomplaice-list', component: OrganizationComplianceListComponent },
        { path: 'organizationcomplaice', component: OrganizationComplianceComponent },
        { path: 'organizationcomplaice/:organizationcomplaiceId', component: OrganizationComplianceComponent },
        { path: 'employee-rol/:employeeId', component: EmployeeRolesComponent },
        // { path: 'user-roles/:userId', component: EmployeeRolesComponent },
        { path: 'employee-rol', component: EmployeeRolesComponent },
        { path: 'add-employee-role', component: AddEmployeeRolesComponent },
        
        { path: 'users', component: UserComponent },
        { path: 'add-user', component: AddUserComponent },
        { path: 'edit-user/:userId', component: AddUserComponent},
        //Recruitment Module
        { path: 'admin-dashboard', component: AdminDashboardNewComponent },
        { path: 'add-position', component: PositionComponent },
        { path: 'edit-position/:positionId', component: PositionComponent },
        { path: 'view-position/:positionId', component: PositionDetailNewComponent },
        { path: 'positions', component: PositionListComponent,data: { title: 'Positions' } },
        // { path: 'add-client', component: ClientComponent },
        // { path: 'edit-client/:clientId', component: ClientComponent },
        { path: 'clients', component: ClientListComponent },
        { path: 'clientElementassign', component:ClientElementAssignmentComponent},
        { path: 'clientElementassign/:elementClientHeaderId', component:ClientElementAssignmentComponent},
        { path: 'shift', component: ShiftComponent },
        { path: 'resource-list', component: ResourceListComponent,data: { title: 'Candidates' }},
        { path: 'resource-list/:resource', component: ResourceListComponent},
        { path: 'add-resource', component: ResourceComponent}, //Add Resource
        { path: 'resource/:resourceId', component: ResourceComponent},
        { path: 'add-onboarding', component: OnboardingComponent },
        { path: 'edit-onboarding/:onboardingId', component: OnboardingComponent },
        { path: 'onboardings', component: OnboardingListComponent,data: { title: 'Onboardings' } },
        { path: 'onboardingdetail/:onboardingId', component: OnboardingdetailComponent},
        { path: 'assignemployee', component: AssignEmployeeComponent},
        { path: 'editassignEmp/:employeelevelAssignId', component: AssignEmployeeComponent},
        {path: 'assignlist', component: AssignEmployeeListComponent},
      

      
        


  
      ],
    },

    {
      path: 'home',
      component: HomeComponent,
      canActivate: [AuthGuard],
      children: [
        { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
        { path: '', redirectTo: 'project-management', pathMatch: 'prefix' },
        { path: '', redirectTo: 'leave-dashboard', pathMatch: 'prefix' },
       
        { path: 'dashboard', component: DashboardComponent,data: { title: 'dashboard' } },
        // { path: 'admin-home', component: AdminMenusHomeComponent },
        {path:'daily-timesheet',component:DailytimesheetComponent,data: { title: 'Daily Time Sheet' }},
        // { path: 'admin-home', component: AdminMenusHomeComponent },
        //{ path: 'dashboard', component: AdminDashboardComponent },
        // {path: 'legal-entity-list',component:LegalEntityListComponent},
   
        { path: 'my-profile', component: MyAccountComponent },
        { path: 'settings', component: SettingsComponent },
        { path: 'employee', component: EmployeeComponent },
        { path: 'add-employee', component: AddEmployeeComponent },
        { path: 'add-employee', component: AddEmployeeComponent },
        { path: 'view-employee/:employeeId', component: ViewEmployeeComponent },
        { path: 'edit-employee/:employeeId', component: AddEmployeeComponent },
        { path: 'department', component: DepartmentComponent },
        { path: 'add-department', component: AddDepartmentComponent },
        { path: 'branch', component: BranchComponent },
        { path: 'add-branch', component: AddBranchComponent },
        { path: 'edit-branch/:branchId', component: AddBranchComponent },
        { path: 'qualification', component: QualificationComponent },
        { path: 'add-qualification', component: AddQualificationComponent },
        { path: 'designation', component: DesignationComponent },
        { path: 'add-desgination', component: AddDesignationComponent },
        { path: 'country', component: CountryComponent },
        { path: 'add-country', component: AddCountryComponent },
        { path: 'state', component: StateComponent },
        { path: 'add-state', component: AddStateComponent },
        { path: 'district', component: DistrictComponent },
        { path: 'add-district', component: AddDistrictComponent },
        { path: 'city', component: CityComponent },
        { path: 'user-role', component: RoleComponent },
        { path: 'add-city', component: CityComponent },
        { path: 'list-types', component: ListTypesComponent },

        {
          path: 'list-type-values/:listTypeValueId',
          component: ListTypeValuesComponent,
        },
        { path: 'financialyear', component: FinanicalyearlistComponent },
        { path: 'addfinancialyear', component: FinanicalyearComponent },
        {
          path: 'updatefinancialyear/:financialyear',
          component: FinanicalyearComponent,
        },
        {
          path: 'variablepayqualifier',
          component: VariablepayqualiferlistComponent,
        },

        { path: 'employee-roles/:employeeId', component: EmployeeRolesComponent },
        { path: 'user-roles/:userId', component: EmployeeRolesComponent },
        { path: 'employee-roles', component: EmployeeRolesComponent },
        { path: 'add-employee-role', component: AddEmployeeRolesComponent },

        { path: 'users', component: UserComponent },
        { path: 'add-user', component: AddUserComponent },
        { path: 'edit-user/:userId', component: AddUserComponent },
        //Recruitment Module
        { path: 'admin-dashboard', component: AdminDashboardNewComponent },
        { path: 'add-position', component: PositionComponent },
        { path: 'edit-position/:positionId', component: PositionComponent },
        {
          path: 'view-position/:positionId',
          component: PositionDetailNewComponent,
        },
        { path: 'positions', component: PositionListComponent },
        // { path: 'add-client', component: ClientComponent },
        // { path: 'edit-client/:clientId', component: ClientComponent },
        // { path: 'clients', component: ClientListComponent },
        {
          path: 'clientElementassign',
          component: ClientElementAssignmentComponent,
        },
        {
          path: 'clientElementassign/:elementClientHeaderId',
          component: ClientElementAssignmentComponent,
        },
        { path: 'shift', component: ShiftComponent },
        { path: 'resource-list', component: ResourceListComponent },
        { path: 'resource-list/:resource', component: ResourceListComponent },
        { path: 'add-resource', component: ResourceComponent }, //Add Resource
        { path: 'resource/:resourceId', component: ResourceComponent },
        { path: 'add-onboarding', component: OnboardingComponent },
        { path: 'edit-onboarding/:onboardingId', component: OnboardingComponent },
        { path: 'onboardings', component: OnboardingListComponent },

        { path: 'resourcedetail', component: ResourceDetailComponent },
        {
          path: 'view-resource/:resourceId',
          component: ResourceDetailNewComponent,
        },
        { path: 'resourcedetail-list', component: ResourceDetailListComponent },
        { path: 'recruiter-dashboard', component: RecruiterDashboardComponent },

        { path: 'modules/:moduleId', component: DashboardComponent },
        { path: 'application-settings', component: ApplicationSettingsComponent },
        { path: 'pms-admin-dashboard', component: PmsAdminDashboardComponent },

        { path: 'kra-template', component: KraComponent },
        { path: 'add-kra-template', component: AddKraComponent },
        {
          path: 'edit-kra-template/:kraTemplateHeaderId',
          component: AddKraComponent,
        },

        { path: 'approver-rules', component: ApproverRulesComponent },
        { path: 'add-approver-rule', component: AddApproverRulesComponent },

        { path: 'employee-approvers', component: EmployeeApporversComponent },
        {
          path: 'add-employee-approvers',
          component: AddEmployeeApproversComponent,
        },

        { path: 'assign-kra-template', component: AssignKraComponent },
        { path: 'add-assign-kra-template', component: AddAssignKraComponent },
        {
          path: 'edit-assign-kra/:employeeKraId',
          component: AddAssignKraComponent,
        },

        { path: 'appraisal', component: AppraisalComponent },
        { path: 'add-appraisal', component: AddAppraisalComponent },
          { path: 'appraisal', component: AppraisalComponent},
          { path: 'reports-appra', component: AppraisalReportsComponent},
          { path: 'admin-reports',component:AdminReportsComponent},
          { path: 'add-appraisal', component: AddAppraisalComponent},
          { path: 'appraisalview/:apprisalHeaderId',component:ApprisalviewComponent},
          { path: 'positions', component: PositionListComponent },
          { path: 'view-appraisal/:apprisalHeaderId',component:ViewAppraisalComponent},
          { path: 'positions', component: PositionListComponent },
          // { path: 'add-client', component: ClientComponent },
          // { path: 'clients', component: ClientListComponent },
          { path: 'resources', component: ResourceListComponent },

        { path: 'positions', component: PositionListComponent },
        // { path: 'add-client', component: ClientComponent },
        // { path: 'clients', component: ClientListComponent },
        { path: 'resources', component: ResourceListComponent },

        { path: 'add-onboarding', component: OnboardingComponent },
        { path: 'edit-onboarding/:onboardingId', component: OnboardingComponent },
        { path: 'onboardings', component: OnboardingListComponent },
        { path: 'manage-elements', component: ManageElementsListComponent },

      { path: 'add-onboarding', component: OnboardingComponent },
      { path: 'edit-onboarding/:onboardingId', component: OnboardingComponent },
      { path: 'onboardings', component: OnboardingListComponent },
      { path: 'manage-elements', component: ManageElementsListComponent, data: { title: 'Manage Elements' }},
        { path: 'element-percentage', component: ListElementPercentageComponent },
        {
          path: 'add-element-percentage',
          component: AddElementPercentageComponent,
        },
        {
          path: 'edit-element-percentage/:elementPercentageId',
          component: AddElementPercentageComponent,
        },
        {path:'manage-work-hours',component:ManageWorkHoursComponent},
        { path: 'company-settings', component: CompanySettingsComponent },
        { path: 'empassign', component: EmpassignmentComponent },
        { path: 'client-workingdays', component: ClientWorkingdaysComponent },
        { path: 'select-workingdays', component: SelectWorkingDaysComponent },
        { path: 'org-workingdays', component: OrgWorkingDaysComponent },
        {path: 'select-elements', component:SelectElementsComponent},
        {path:'select-tax-breakup',component:SelectTaxBreakupComponent},
        // {path:'select-emp-transfer',component:SelectEmployeeTransferComponent},
        {path:'selectClientAssign',component:EmpassignmentComponent},

        {path:'organizatio-Assignemnt',component:OrganizationElementAssignmentComponent},
        {path:'organizatio-Assignemnt/:id',component:OrganizationElementAssignmentComponent},

        {path:'select-manage-elements',component:SelectManageElementsComponent},
    
        // { path: 'edit-client/:workingdays',component: ClientWorkingdaysComponent},

        //shift
        
        { path: 'shift-calendar', component: ShiftcalendarComponent },
        {
          path: 'shift-calendar-view/:shiftcalendarId',
          component: ShifthoildaysExceptionsComponent,
        },
        { path: 'emp-shift-list', component: ShiftemployeelistComponent },
        { path: 'employee-rating-list', component: EmployeeRatinglistComponent },
        { path: 'employee-rating', component: EmployeeRatingComponent },
        {
          path: 'edit-employee-rating/:employeerattingId',
          component: EmployeeRatingComponent,
        },
        { path: 'employee-shift', component: ShiftemployeeComponent },
        {
          path: 'employee-shift/:shiftemployeeId',
          component: ShiftemployeeComponent,
        },

      //shift
      { path: 'shift-calendar', component: ShiftcalendarComponent },
      {
        path: 'shift-calendar-view/:shiftcalendarId',
        component: ShifthoildaysExceptionsComponent,
      },
      { path: 'emp-shift-list', component: ShiftemployeelistComponent },
      { path: 'employee-rating-list', component: EmployeeRatinglistComponent,data: { title: 'Employee Rating' } },
      { path: 'employee-rating', component: EmployeeRatingComponent },
      {
        path: 'edit-employee-rating/:employeerattingId',
        component: EmployeeRatingComponent,
      },
      { path: 'employee-shift', component: ShiftemployeeComponent },
      {
        path: 'employee-shift/:shiftemployeeId',
        component: ShiftemployeeComponent,
      },

      //employee-groups
      { path: 'emp-groups', component: EmployeeGroupsComponent },
      {
        path: 'emp-groups/:employeeGroupId',
        component: EmployeeGroupsComponent,
      },
      { path: 'groups-list-emp', component: EmployeeGroupsListComponent },
      { path: 'add-employee-leave', component: EmployeeLeaveAddComponent },
      {
        path: 'edit-employee-leave/:employeeleaveId',
        component: EmployeeLeaveAddComponent,
      },
      { path: 'manage-employee-leave', component: EmployeeLeaveListComponent ,data: { title: 'Employee Leave' }},
      { path: 'recovery-adjustment', component: RecoveryAdjustmentComponent },
      {
        path: 'recovery-adjustment/:recoveryAdjustmentId',
        component: RecoveryAdjustmentComponent,
      },
      {
        path: 'recovery-adjustment-list',
        component: RecoveryAdjustmentListComponent,data: { title: 'Employee Recovery Adjustment' }
      },
      { path: 'manage-employee-leave', component: EmployeeLeaveListComponent },
        //employee-groups
        { path: 'emp-groups', component: EmployeeGroupsComponent },
        {
          path: 'emp-groups/:employeeGroupId',
          component: EmployeeGroupsComponent,
        },
        // { path: 'employee-groups-list', component: EmployeeGroupsListComponent },
        { path: 'add-employee-leave', component: EmployeeLeaveAddComponent },
        {
          path: 'edit-employee-leave/:employeeleaveId',
          component: EmployeeLeaveAddComponent,
        },
        { path: 'manage-employee-leave', component: EmployeeLeaveListComponent },
        { path: 'recovery-adjustment', component: RecoveryAdjustmentComponent },
        {
          path: 'recovery-adjustment/:recoveryAdjustmentId',
          component: RecoveryAdjustmentComponent,
        },
        {
          path: 'recovery-adjustment-list',
          component: RecoveryAdjustmentListComponent,
        },
        { path: 'manage-employee-leave', component: EmployeeLeaveListComponent },
          //annexure
          { path: 'employee-annexue-list', component: EmployeeAnnexureListComponent },
          { path: 'employee-annexue', component: EmployeeAnnexureComponent },
          { path: 'employee-annexue/:annexureHeaderId', component: EmployeeAnnexureComponent },
          { path: 'add-variablepay', component: VariablePayAddComponent },
          { path: 'manage-variablepay', component: VariablePayListComponent },
          //payroll
          { path: 'employee-payroll', component: EmployeePayrollComponent },
          { path: 'employee-payroll-list', component: EmployeePayrollListComponent},
          { path: 'view-employee-payroll/:payrollheaderId', component: EmployeePayrollDetailComponent},
          { path: 'pfreport-list', component: PfReportsComponent },
          { path: 'tdsreport-list', component: TdsReportsComponent },
          { path: 'consolidatereport-list', component: ConsolidatereportComponent },
          { path: 'consolidatePayslip', component: ConsolidatePayslipComponent },
          //client reports
          { path: 'client-consolidatePayslip', component: ClientemployeeConslidatepayslipComponent },
          { path: 'client-ot-report', component: OtReportsComponent },
          { path: 'client-esi-challan', component: ClientEsiChallanComponent },
          { path: 'client-pf-challan', component: ClientPfChallanComponent },
          { path: 'client-netpay-challan', component: ClientNetpayChallanComponent },
          { path: 'client-if-report', component: ClientIfReportsComponent },
            //wage-register
            { path: 'wage-register', component:WageRegisterComponent},
            {path:'view-wageslip',component:ViewPdfComponent},
            {path:'payroll-dashboard',component:PayrolldashboardComponent},

        // approvers
        { path: 'manage-approver-list', component: ManageApproverListComponent },
        { path: 'manage-approver', component: ManageApproverComponent },
        { path: 'manage-approver/:id', component: ManageApproverComponent },
        {
          path: 'manage-empl-approver',
          component: ManageEmployeeApproverComponent,
        },
        {
          path: 'manage-empl-approver/:id',
          component: ManageEmployeeApproverComponent,
        },
        {
          path: 'manage-emp-approver-list',
          component: ManageEmployeeApproverListComponent,
        },
        {
          path: 'RequestSalaryAdvance',
          component: RequestSalaryAdvanceComponent,
        },
        {
          path: 'RequestSalaryAdvance/:id',
          component: RequestSalaryAdvanceComponent,
        },
        { path: 'SalaryAdvance-list', component: SalaryAdvanceListComponent },
        {
          path: 'salary-manage-approvals',
          component: SalaryManageApprovalsComponent,data: { title: 'Manage Salary Approvals' }
        },
        { path: 'loan-list', component: LoanListComponent },
        { path: 'loan', component: LoanComponent },
        { path: 'loan/:id', component: LoanComponent },
        {
          path: 'loan-manage-approvals',
          component: LoanManageApprovalsComponent,
        },
        { path: 'exist-loan-details', component: ExistLoanDetailsComponent },
        // manage payslip
        { path: 'manage-payslip', component: ManagePayslipComponent },
        { path: 'viewpayslip/:id', component: ViewPayslipComponent },
        { path: 'viewemppayslip/:id', component: ViewPayslipComponent },
        {
          path: 'add-employee-revenue-adjustment',
          component: EmpRevenueAdjustmentAddComponent,
        },
        {
          path: 'edit-employee-revenue-adjustment/:revenueAdjustmentId',
          component: EmpRevenueAdjustmentAddComponent,
        },
        {
          path: 'manage-employee-revenue-adjustment',
          component: EmpRevenueAdjustmentListComponent,
        },

        //annexure
        {
          path: 'employee-annexue-list',
          component: EmployeeAnnexureListComponent,
        },
        { path: 'employee-annexue', component: EmployeeAnnexureComponent },
        {
          path: 'employee-annexue/:annexureHeaderId',
          component: EmployeeAnnexureComponent,
        },
        { path: 'add-variablepay', component: VariablePayAddComponent },
        { path: 'manage-variablepay', component: VariablePayListComponent,data: { title: 'Manage Variable Pay' } },
         //payroll
        { path: 'manage-variablepay', component: VariablePayListComponent },
        //payroll
        { path: 'employee-payroll', component: EmployeePayrollComponent },
        {
          path: 'employee-payroll-list',
          component: EmployeePayrollListComponent,
        },
        {
          path: 'view-employee-payroll/:payrollheaderId',
          component: EmployeePayrollDetailComponent,
        },
        { path: 'pfreport-list', component: PfReportsComponent },
        { path: 'tdsreport-list', component: TdsReportsComponent },
        { path: 'consolidatereport-list', component: ConsolidatereportComponent },
        { path: 'consolidatePayslip', component: ConsolidatePayslipComponent },
        { path: 'esireport', component: EsireportComponent },
        { path: 'netpayreport', component: HdfcbankreportComponent },
        { path: 'reports', component: ReportshomeComponent },

        //client reports
        { path: 'client-consolidatePayslip', component: ClientemployeeConslidatepayslipComponent },
        { path: 'client-ot-report', component: OtReportsComponent },

        //
        { path: 'employeeworkdays', component: EmployeeworkingdaysComponent },
        { path: 'employeeworkdays/:employeeWorkingDaysId', component: EmployeeworkingdaysComponent },
        { path: 'employeeworkdays-list', component: EmployeewokingdayslistComponent },
        { path: 'manage-client-element-list', component: ClientElementListComponent },
        { path: 'client-employee-annexue', component: ClientEmployeeAnnexueComponent },
        { path: 'client-employee-annexue/:annexureHeaderId', component: ClientEmployeeAnnexueComponent },
        { path: 'client-employee-annexue-list', component: CleintEmployeeAnnexueListComponent },
        { path: 'client-employee-payroll', component: ClientRunPayrollComponent },
        { path: 'client-employee-payroll-list', component: ClientemployeePayrollListComponent },
        { path: 'client-recovery-adjustment', component: CreateManageRecoveryadjustmentComponent },
        { path: 'client-recovery-adjustment/:recoveryAdjustmentId', component: CreateManageRecoveryadjustmentComponent },
        { path: 'client-recovery-adjustment-list', component: ManageRecoveryadjustmentComponent },
        { path: 'client-employee-revenue-adjustment-list', component: ClientEmployeeRevenueAdjustmentListComponent },
        { path: 'client-employee-revenue-adjustment', component: ClientEmployeeRevenueAdjustmentComponent },
        { path: 'client-employee-revenue-adjustment/:revenueAdjustmentId', component: ClientEmployeeRevenueAdjustmentComponent },
        { path: 'client-employee-payslip',component:ClientemployeePaylispListComponent},
        { path: 'client-tax-breakup',component:ClientTaxBreakageComponent},
        { path: 'client-tax-breakup-list',component:ClientTaxBreakageListComponent},
        { path: 'working-days',component:OrganizationworkingdaysComponent},
        { path: 'employee-payslip',component:EmployeePayslipComponent},
        { path: 'client-esi-challan', component: ClientEsiChallanComponent },
        { path: 'client-pf-challan', component: ClientPfChallanComponent },
        { path: 'client-netpay-challan', component: ClientNetpayChallanComponent },
        { path: 'client-if-report', component: ClientIfReportsComponent },
          //wage-register
          { path: 'wage-register', component:WageRegisterComponent},
          {path:'view-wageslip',component:ViewPdfComponent},
        {
          path: 'client-consolidatePayslip',
          component: ClientemployeeConslidatepayslipComponent,
        },
        { path: 'client-ot-report', component: OtReportsComponent },

        //
        { path: 'employeeworkdays', component: EmployeeworkingdaysComponent },
        {
          path: 'employeeworkdays/:employeeWorkingDaysId',
          component: EmployeeworkingdaysComponent,
        },
        {
          path: 'employeeworkdays-list',
          component: EmployeewokingdayslistComponent,
        },
        {
          path: 'manage-client-element-list',
          component: ClientElementListComponent,
        },
        {
          path: 'client-employee-annexue',
          component: ClientEmployeeAnnexueComponent,
        },
        {
          path: 'client-employee-annexue/:annexureHeaderId',
          component: ClientEmployeeAnnexueComponent,
        },
        {
          path: 'client-employee-annexue-list',
          component: CleintEmployeeAnnexueListComponent,
        },
        { path: 'client-employee-payroll', component: ClientRunPayrollComponent },
        {
          path: 'client-employee-payroll-list',
          component: ClientemployeePayrollListComponent,
        },
        {
          path: 'client-recovery-adjustment',
          component: CreateManageRecoveryadjustmentComponent,
        },
        {
          path: 'client-recovery-adjustment/:recoveryAdjustmentId',
          component: CreateManageRecoveryadjustmentComponent,
        },
        {
          path: 'client-recovery-adjustment-list',
          component: ManageRecoveryadjustmentComponent,
        },
        {
          path: 'client-employee-revenue-adjustment-list',
          component: ClientEmployeeRevenueAdjustmentListComponent,
        },
        {
          path: 'client-employee-revenue-adjustment',
          component: ClientEmployeeRevenueAdjustmentComponent,
        },
        {
          path: 'client-employee-revenue-adjustment/:revenueAdjustmentId',
          component: ClientEmployeeRevenueAdjustmentComponent,
        },
        {
          path: 'client-employee-payslip',
          component: ClientemployeePaylispListComponent,
        },
        { path: 'client-tax-breakup', component: ClientTaxBreakageComponent },
        {
          path: 'client-tax-breakup-list',
          component: ClientTaxBreakageListComponent,
        },

      // approvers
      { path: 'manage-approver-list', component: ManageApproverListComponent },
      { path: 'manage-approver', component: ManageApproverComponent },
      { path: 'manage-approver/:id', component: ManageApproverComponent },
      {
        path: 'manage-empl-approver',
        component: ManageEmployeeApproverComponent,
      },
      {
        path: 'manage-empl-approver/:id',
        component: ManageEmployeeApproverComponent,
      },
      {
        path: 'manage-emp-approver-list',
        component: ManageEmployeeApproverListComponent,
      },
      {
        path: 'RequestSalaryAdvance',
        component: RequestSalaryAdvanceComponent,
      },
      {
        path: 'RequestSalaryAdvance/:id',
        component: RequestSalaryAdvanceComponent,
      },
      { path: 'SalaryAdvance-list', component: SalaryAdvanceListComponent },
      {
        path: 'salary-manage-approvals',
        component: SalaryManageApprovalsComponent,
      },
      { path: 'loan-list', component: LoanListComponent },
      { path: 'loan', component: LoanComponent },
      { path: 'loan/:id', component: LoanComponent },
      {
        path: 'loan-manage-approvals',
        component: LoanManageApprovalsComponent,
      },
      { path: 'exist-loan-details', component: ExistLoanDetailsComponent },
      // manage payslip
      { path: 'manage-payslip', component: ManagePayslipComponent },
      { path: 'viewpayslip/:id', component: ViewPayslipComponent },
      {
        path: 'add-employee-revenue-adjustment',
        component: EmpRevenueAdjustmentAddComponent,
      },
      {
        path: 'edit-employee-revenue-adjustment/:revenueAdjustmentId',
        component: EmpRevenueAdjustmentAddComponent,
      },
      {
        path: 'manage-employee-revenue-adjustment',
        component: EmpRevenueAdjustmentListComponent,data: { title: 'Employee Revenue Adjustment' }
      },

      //annexure
      {
        path: 'employee-annexue-list',
        component: EmployeeAnnexureListComponent,data: { title: 'Manage Annexure' }
      },
      { path: 'employee-annexue', component: EmployeeAnnexureComponent },
      {
        path: 'employee-annexue/:annexureHeaderId',
        component: EmployeeAnnexureComponent,
      },
      { path: 'add-variablepay', component: VariablePayAddComponent },
      { path: 'manage-variablepay', component: VariablePayListComponent },
      //payroll
      { path: 'employee-payroll', component: EmployeePayrollComponent },
      {
        path: 'employee-payroll-list',
        component: EmployeePayrollListComponent,
      },
      {
        path: 'view-employee-payroll/:payrollheaderId',
        component: EmployeePayrollDetailComponent,
      },
      { path: 'pfreport-list', component: PfReportsComponent },
      { path: 'tdsreport-list', component: TdsReportsComponent },
      { path: 'consolidatereport-list', component: ConsolidatereportComponent },
      { path: 'consolidatePayslip', component: ConsolidatePayslipComponent },
      {
        path: 'client-consolidatePayslip',
        component: ClientemployeeConslidatepayslipComponent,
      },
      { path: 'client-ot-report', component: OtReportsComponent },
        //Leave
        { path: 'myleave-list', component: MyleavelistComponent },

        //Recruitment
        { path: 'requirement', component: RequirementListComponent },
        { path: 'requirement-add', component: RequirementAddComponent },
        {
          path: 'requirement-edit/:recuirementId',
          component: RequirementAddComponent,
        },
        {path:'requirement-details/:requirementId',component: RequirementDetailComponent,},

        {
          path: 'resourcefeedback/:resourceId',
          component: ResourceFeedbackComponent,
        },
        {
          path: 'resource/feedback/:resourceId',
          component: FeedbackFormComponent,
        },
        {
          path: 'resource/feedback/:resourceId/:feedbackId',
          component: FeedbackFormComponent,
        },
        { path: 'leave-dashboard', component: LeaveDashboardComponent },
        { path: 'emp-leave-dashboard', component: EmpLeaveDashboardComponent },
        { path: 'myleave-list', component: MyleavelistComponent },
        { path: 'mypermission-list', component: MypermissionListComponent },
        { path: 'mywfh-list', component: MyWFHListComponent },
        {
          path: 'mybusinesstravel-list',
          component: MybusinessTravelListComponent,
        },
        { path: 'apply-leave', component: ApplyLeaveComponent },
        {path: 'record-setting',component:AddrecordsettingComponent},
        { path: 'apply-permission', component: ApplyPermissionComponent },
        { path: 'apply-wfh', component: ApplyWorkFromHomeComponent },
        { path: 'apply-businesstravel', component: ApplyBusinessTravelComponent },
        { path: 'leave-balance', component: LeaveTypeListComponent },
        {
          path: 'leave-adjustment-list',
          component: LeaveAdjustmentListComponent,
        },
        { path: 'leave-adjustment', component: LeaveAdjustmentComponent },
        { path: 'type-list', component: TypeListComponent },
        { path: 'type-add', component: TypeAddComponent },
        { path: 'type-edit/:id', component: TypeAddComponent },
        {
          path: 'emptimesetting-list',
          component: EmployeeTimeSettingsComponent,
        },
        { path: 'manage-leave-request', component: ManageLeaveComponent },
        { path: 'manage-permission', component: ManagePermissionComponent },
        { path: 'manage-wfh', component: ManageWorkfromhomeComponent },
        {
          path: 'manage-business-travel',
          component: ManageBusinesstravelComponent,
        },
        {
          path: 'manage-withdrawal-request',
          component: ManageWithdrawalComponent,
        },
        { path: 'leave-calendar', component: LeaveCalendarComponent },
        { path: 'employee-leave-calendar', component: EmployeeCalendarComponent },

        { path: 'leave-Detail', component: LeaveReportComponent },
        { path: 'withdrawList', component: WithdrawReportComponent },
        { path: 'permission-Detail', component: PermissionReportComponent },
        { path: 'work-from-home', component: WorkfromhomeReportComponent },
        { path: 'business-travel', component: BusinesstravelReportComponent },
  //mom
        {path:'mom-reports',component:MomreportsComponent},
        {path:'mom-creation',component:CreatemomComponent,data: { title: 'Create MOM' }},
        {path:'action-item-report',component:ActionitemreportComponent,data: { title: 'Action Item Report' }},
        {path:'view-mom/:id',component:PreviewComponent},
        {path:'update-mom/:e',component:UpdatemomComponent},

        //project management

      //Recruitment
      { path: 'requirement', component: RequirementListComponent,data: { title: 'Requirement' } },
      { path: 'requirement-add', component: RequirementAddComponent },
      {
        path: 'requirement-edit/:recuirementId',
        component: RequirementAddComponent,
      },
      // {
      //   path: 'requirement-detail/:recuirementId',
      //   component: RequirementDetailComponent,
      // },
        {
          path: 'project-management',
          component: ProjectManagementAdminComponent,data: { title: 'Dashboard' }
        },
        {
          path: 'time-employee-dashboard',
          component: TimeEmployeeDashboardComponent,data: { title: 'Dashboard' }
        },
        { path: 'time-sheet-reports', component: EmpTimelogsComponent },
        // { path: 'projects', component: ProjectsComponent },
        {path:'assign-project',component:AssaignProjectComponent,data: { title: 'Assign Employee Project' }},
        {path:'task-group',component:TaskgroupComponent,data: { title: 'Task Group' }},
        {path:'client-list',component:ClientListComponent},
        {path:'project-stage',component:ProjectstageComponent,data: { title: 'Project Stage' }},
        { path: 'add-multiple-task',component:AddMultipleTaskComponent,data: { title: 'Add Task' }},
        { path: 'add-employee-task',component:AssignEmployeeTaskComponent,data: { title: 'Assign Employee Task' }},
        // {path:'assign-emp-task',component:AssignEmpTaskComponent},
        // {path:'edit-task/:id',component:AssignEmpTaskEditComponent}
          
        // { path: 'assign-task', loadChildren: () => import('./project-management/assign-emp-task/assign-emp-task.module')
        // .then(m => m.AssignEmpTaskModule) },

      {
        path: 'resourcefeedback/:resourceId',
        component: ResourceFeedbackComponent,
      },
      {
        path: 'resource/feedback/:resourceId',
        component: FeedbackFormComponent,
      },
      {
        path: 'resource/feedback/:resourceId/:feedbackId',
        component: FeedbackFormComponent,
      },
      { path: 'leave-dashboard', component: LeaveDashboardComponent },
      { path: 'emp-leave-dashboard', component: EmpLeaveDashboardComponent },
      { path: 'myleave-list', component: MyleavelistComponent },
      { path: 'mypermission-list', component: MypermissionListComponent },
      { path: 'mywfh-list', component: MyWFHListComponent },
      {
        path: 'mybusinesstravel-list',
        component: MybusinessTravelListComponent,
      },
      { path: 'apply-leave', component: ApplyLeaveComponent },
      { path: 'apply-permission', component: ApplyPermissionComponent },
      { path: 'apply-wfh', component: ApplyWorkFromHomeComponent },
      { path: 'apply-businesstravel', component: ApplyBusinessTravelComponent },
      { path: 'leave-balance', component: LeaveTypeListComponent },
      {
        path: 'leave-adjustment-list',
        component: LeaveAdjustmentListComponent,
      },
      { path: 'leave-adjustment', component: LeaveAdjustmentComponent },
      { path: 'type-list', component: TypeListComponent },
      { path: 'type-add', component: TypeAddComponent },
      { path: 'type-edit/:id', component: TypeAddComponent },
      {
        path: 'emptimesetting-list',
        component: EmployeeTimeSettingsComponent,
      },
      { path: 'manage-leave-request', component: ManageLeaveComponent },
      { path: 'manage-permission', component: ManagePermissionComponent },
      { path: 'manage-wfh', component: ManageWorkfromhomeComponent },
      {
        path: 'manage-business-travel',
        component: ManageBusinesstravelComponent,
      },
      {
        path: 'manage-withdrawal-request',
        component: ManageWithdrawalComponent,
      },
      { path: 'leave-calendar', component: LeaveCalendarComponent },
      { path: 'leave-Detail', component: LeaveReportComponent },
      { path: 'withdrawList', component: WithdrawReportComponent },
      { path: 'permission-Detail', component: PermissionReportComponent },
      { path: 'work-from-home', component: WorkfromhomeReportComponent },
      { path: 'business-travel', component: BusinesstravelReportComponent },
//mom
      {path:'mom-reports',component:MomreportsComponent,data: { title: 'MOM Reports' }},
      {path:'mom-creation',component:CreatemomComponent},
      {path:'action-item-report',component:ActionitemreportComponent},
      {path:'view-mom/:id',component:PreviewComponent},
      {path:'update-mom/:e',component:UpdatemomComponent},
      {path:'assign-approver',component:AssignapproverComponent,data: { title: 'Assign Approvers' }},
      {path:'add-assaign-approver',component:AddassignapproverComponent},
      {path:'update-assign-approver/:projectapproverId',component:UpdateAssignComponent},

      //project management
      {path:'daily-timesheet',component:DailytimesheetComponent},
      {
        path: 'project-management',
        component: ProjectManagementAdminComponent,
      },
      {
        path: 'time-employee-dashboard',
        component: TimeEmployeeDashboardComponent,
      },
      { path: 'time-sheet-reports', component: EmpTimelogsComponent },
      { path: 'projects', loadChildren: () => import('../app/project-management/project-management/projects/projects.module')
      .then(m => m.ProjectsModule) },
      {path:'assign-project',component:AssaignProjectComponent},
      {path:'task-group',component:TaskgroupComponent},
      {path:'client-list',component:ClientListComponent},
      {path:'project-stage',component:ProjectstageComponent},
      { path: 'add-multiple-task',component:AddMultipleTaskComponent},
      { path: 'add-employee-task',component:AssignEmployeeTaskComponent},
      // {path:'assign-emp-task',component:AssignEmpTaskComponent},
      // {path:'edit-task/:id',component:AssignEmpTaskEditComponent}
        
      // { path: 'assign-task', loadChildren: () => import('./project-management/assign-emp-task/assign-emp-task.module')
      // .then(m => m.AssignEmpTaskModule) },

      { path: 'manage-timesheet',component:ManageTimesheetComponent},
      { path: 'emp-timesheet', loadChildren: () => import('../app/project-management/project-management/emp-timesheet/emp-timesheet.module')
      .then(m => m.EmpTimesheetModule) },
      { path: 'emp-report', component: EmpReportComponent },
      { path: 'consolidate-timesheet',component:ConsolidateTimesheetComponent},
      { path: 'employee-task-report',component:EmployeeTaskReportComponent,data: { title: 'Employees' }},
      {path: 'category',component:CategoryComponent},
      {path: 'advance',component:AdvanceComponent},
      {path: "expenseReimbursement",component:ExpenseReimbursementComponent},
      {path: "expenseReimbursementCreate",component:ExperexpenseReimbursementCreateComponent},
      {path: "expenseReimbursementCreate/:expensereimbursementId",component:ExperexpenseReimbursementCreateComponent},
      {path: "payment-advice/:updateexpensereburismentId",component:PaymentAdviceComponent},
      {path: "payment-advice/:exReimbursementId/:paymentAdviceId",component:PaymentAdviceComponent},
      {path: 'manage-approvals',component:ManageApprovalsComponent},
      {path: 'manage-advance-approvals',component:AdvanceapproverComponent},
      {path: 'business-list',component:BusinessGroupListingComponent},
      { path: 'business-group/:id', component: BusinessgroupComponent },
      // {path:'legal',component:LegalListComponent}
      { path: 'assignEmp', component: AssignEmployeeComponent},
      { path: 'editassignemployee/:employeelevelAssignId', component: AssignEmployeeComponent},
      {path: 'assignlist', component: AssignEmployeeListComponent},
      { path: 'manage-announcement', component: ManageannouncementComponent },
      { path: 'announcement', component: AnnouncementComponent },
      {
        path: 'edit-announcement/:announcementId',
        component: AnnouncementComponent,
      },
      {path: 'newsfeed', component:NewsandfeedComponent}


    ],
  },
];

  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
  })
  export class AppRoutingModule {}
