import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';


@Component({
  selector: 'app-employee-annexure',
  templateUrl: './employee-annexure.component.html',
  styleUrls: ['./employee-annexure.component.scss']
})
export class EmployeeAnnexureComponent implements OnInit {
  isProcessing=false;

  submitted: boolean = false;
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  postForm: FormGroup;
  resultData: any = [];
  selected: true;
  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;
  branchlist: any = [];
  years: number[] = [];
  userdata: any;
  organizationId: any;
  sortedData: any;
  p: number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch: any;
  pagePerItem: any = 5;
  today: any = new Date();
  dateFormat: any;
  shiftList: any;
  employeeId: any;
  employee: string;
  empList: any = [];
  shiftcalendarList: any = [];
  livingcity: any;
  selectedEmployeeData: any = [];
  employeedisabled: boolean = true;
  errormessagejson: any;
  shiftemployeeId: any;
  importerror = false;
  shiftemployeedata: any;
  showemployeegroupserach: any;
  empgroupList: any;
  selectedEmployeeGroupData: any = [];
  elementlist: any = [];
  totalmontlyincome: any = 0;
  totalannualincome: any = 0;
  deletedrows: any = [];
  showctcerror: boolean;
  empannexueId: any;
  touchedRows: any[];
  checktotalamount: boolean = true;
  empannexue: any = {};
  maxstartdate: any;
  filterele: any;
  usertData: any;
  orgId: any;
  clientId:any;
  orgcompliancedata: any = [];
  private unsubscribe$ = new Subject<void>();
  employeelistsub: any;
  chooseEvent: any;
  BusinessName: any;
  Organization:any;
  BusinessUnit:any;
  BusinessGroupName: any;
  OrganizationName: any;
  businessgroup: any;
  businessunit: any;
  organization: any;
  entityId: any;
  min: string;
  show_error_msg: boolean=false;
  startdate_required: boolean;
  enddate_required: boolean;
  max: string;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private payrollService: PayrollService, private datepipe: DatePipe,
    private ActivatedRoute: ActivatedRoute, @Inject(AppComponent) public AppData: any,
    private dialog: MatDialog, private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
   this.loader=true;
    this.empannexueId = this.ActivatedRoute.snapshot.params.annexureHeaderId;
    this.postForm = this.formBuilder.group({
      PayrollRun: [null, [Validators.required]],
      PayrollLevel: [null],

      place: [null, [Validators.required, Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]],
      employee: [null, [Validators.required]],
      ctc: [null, [Validators.required]],
      livingCity: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      tableRows: this.formBuilder.array([]),
    });
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = this.userdata.organization.organizationId;
    // this.organizationId =  this.AppData.organizationId;
    console.log("this.organizationId", this.organizationId);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));

    this.orgId = this.usertData.organization.organizationId;
    this.clientId=this.usertData?.employee?.client?.clientId;
    this.entityId = this.usertData?.entity?.entityId;


    if(this.orgId){
    this.payrollService.getorgcompactivebyorganziationId(this.orgId).subscribe((data: any) => {
      this.loader=false;
      if (data.length != undefined) {
        this.orgcompliancedata = data;
      }
    });
  }
  if(this.clientId){
    this.payrollService.getorgcompactivebyClientId(this.clientId).subscribe((data: any) => {
      this.loader=false;
      if (data.length != undefined) {
        this.orgcompliancedata = data;
      }
    });
  }


    if (!this.empannexueId) {
      if(this.orgId){
      this.payrollService.getelements(this.organizationId).subscribe((result: any) => {
        this.loader=false;
        if (result.statusCode) {
          this.elementlist.length = 0;
        } else {
          console.log("else")
          this.elementlist = result;
          this.filterele = this.elementlist.filter(ele => ele.elementName.includes("Basic") || ele.elementName.includes("HRA"));
          this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
          console.log(this.filterele);

          const control = this.postForm.get('tableRows') as FormArray;

          console.log("this.filterele", this.filterele);
          this.filterele.forEach((element, index) => {
            this.addRow();
            if (element.elementName != "HRA") {
              control.at(index).patchValue({
                hrElementPercentagelist: element.hrElementPercentage,
                hrElementPercentage: element?.hrElementPercentage[0]?.elementPercentage,
                element: element.elementId,
                elementName: element.elementName
              })
            } else {
              control.at(index).patchValue({
                hrElementPercentagelist: element.hrElementPercentage,
                element: element.elementId,
                elementName: element.elementName
              })
            }
          });
          console.log(control);
        }

      })
    }
    if(this.clientId){
      this.payrollService.getelementsByClient(this.clientId).subscribe((result: any) => {
        this.loader=false;
        if (result.statusCode) {
          this.elementlist.length = 0;
        } else {
          console.log("else")
          this.elementlist = result;
          this.filterele = this.elementlist.filter(ele => ele.elementName.includes("Basic") || ele.elementName.includes("HRA"));
          this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
          console.log(this.filterele);

          const control = this.postForm.get('tableRows') as FormArray;

          console.log("this.filterele", this.filterele);
          this.filterele.forEach((element, index) => {
            this.addRow();
            if (element.elementName != "HRA") {
              control.at(index).patchValue({
                hrElementPercentagelist: element.hrElementPercentage,
                hrElementPercentage: element.hrElementPercentage[0].elementPercentage,
                element: element.elementId,
                elementName: element.elementName
              })
            } else {
              control.at(index).patchValue({
                hrElementPercentagelist: element.hrElementPercentage,
                element: element.elementId,
                elementName: element.elementName
              })
            }
          });
          console.log(control);
        }

      })
    }
      this.payrollService.getListTypeById(15).subscribe((result: any) => {
        this.loader=false;
        if (result.statusCode) {
          this.livingcity.length = 0;
        } else {
          console.log("else")
          this.livingcity = result;
        }

      })
    } else {

      if(this.orgId){
      this.payrollService.getelements(this.organizationId).subscribe((result: any) => {
        this.loader=false;
        if (result.statusCode) {
          this.elementlist.length = 0;
        } else {
          console.log("else")
          this.elementlist = result;

          this.payrollService.getemployeeannexuebyId(this.empannexueId).subscribe((result: any) => {
            this.loader=false;
            if (result.statusCode) {

            } else {
              this.empannexue = result;
              console.log(this.empannexue);

              let PayrollRun: number | null;


              if (this.empannexue.businessgroup && this.empannexue.businessgroup.businessGroupId) {
                PayrollRun = 1; 
                this.getBusinessGroupData();
              } else if (this.empannexue.businessunit && this.empannexue.businessunit.businessUnitId) {
                PayrollRun = 2; 
                this.getBusinessUnit();
              } else if (this.empannexue.organization && this.empannexue.organization.organizationId) {
                PayrollRun = 3; 
                this.getOrganizationData();
              }

            console.log(PayrollRun)

              let payrollLevel: string | undefined;

               if (this.empannexue.businessgroup && this.empannexue.businessgroup.businessGroupId) {
              payrollLevel = this.empannexue.businessgroup.businessGroupId;
               } else if (this.empannexue.businessunit && this.empannexue.businessunit.businessUnitId) {
               payrollLevel = this.empannexue.businessunit.businessUnitId;
                  } else if (this.empannexue.organization && this.empannexue.organization.organizationId) {
               payrollLevel = this.empannexue.organization.organizationId;
              }
             console.log(payrollLevel)
              this.postForm.patchValue({

                place: this.empannexue.place,
                employee: this.empannexue.employee.employeeNumber + "-" + this.empannexue.employee.firstName,
                ctc: this.empannexue.ctc,
                livingCity: this.empannexue.livingCity.listTypeValueId,
                startDate: this.empannexue.effectiveFromDate,
                endDate: this.empannexue.effectiveToDate,
                PayrollLevel:payrollLevel ,
                PayrollRun:PayrollRun
                
              })
              

              this.employeeId = this.empannexue.employee.employeeId
              console.log(this.postForm)
            }

            this.empannexue.lines.forEach((element, index) => {
              const control = this.postForm.get('tableRows') as FormArray;
              this.addRow();
              control.at(index).patchValue({
                annexureLineId: element.annexureLineId,
                elementName: element.element.elementName,
                element: element.element.elementId,
                hrElementPercentagelist: element.element.hrElementPercentage,
                hrElementPercentage: element.elementPercentage,
                salaryPerMonth: element.annexureSalaryPerMonth?.toFixed(2),
                salaryPerAnnum: element.annexureSalaryPerAnnum?.toFixed(2),
                isEditable: [true],
                status: element.status
              })
            });

          })
        }

      })
    }

    if(this.clientId){
      this.payrollService.getelementsByClient(this.clientId).subscribe((result: any) => {
        this.loader=false;
        if (result.statusCode) {
          this.elementlist.length = 0;
        } else {
          console.log("else")
          this.elementlist = result;

          this.payrollService.getemployeeannexuebyId(this.empannexueId).subscribe((result: any) => {
            this.loader=false;
            if (result.statusCode) {

            } else {
              this.empannexue = result;
              console.log(this.empannexue);

              let PayrollRun: number | null;


              if (this.empannexue.businessgroup && this.empannexue.businessgroup.businessGroupId) {
                PayrollRun = 1; 
                this.getBusinessGroupData();
              } else if (this.empannexue.businessunit && this.empannexue.businessunit.businessUnitId) {
                PayrollRun = 2; 
                this.getBusinessUnit();
              } else if (this.empannexue.organization && this.empannexue.organization.organizationId) {
                PayrollRun = 3; 
                this.getOrganizationData();
              }

            console.log(PayrollRun)

              let payrollLevel: string | undefined;

               if (this.empannexue.businessgroup && this.empannexue.businessgroup.businessGroupId) {
              payrollLevel = this.empannexue.businessgroup.businessGroupId;
               } else if (this.empannexue.businessunit && this.empannexue.businessunit.businessUnitId) {
               payrollLevel = this.empannexue.businessunit.businessUnitId;
                  } else if (this.empannexue.organization && this.empannexue.organization.organizationId) {
               payrollLevel = this.empannexue.organization.organizationId;
              }
             console.log(payrollLevel)
              this.postForm.patchValue({

                place: this.empannexue.place,
                employee: this.empannexue.employee.employeeNumber + "-" + this.empannexue.employee.firstName,
                ctc: this.empannexue.ctc,
                livingCity: this.empannexue.livingCity.listTypeValueId,
                startDate: this.empannexue.effectiveFromDate,
                endDate: this.empannexue.effectiveToDate,
                PayrollLevel:payrollLevel ,
                PayrollRun:PayrollRun
                
              })
              

              this.employeeId = this.empannexue.employee.employeeId
              console.log(this.postForm)
            }

            this.empannexue.lines.forEach((element, index) => {
              const control = this.postForm.get('tableRows') as FormArray;
              this.addRow();
              control.at(index).patchValue({
                annexureLineId: element.annexureLineId,
                elementName: element.element.elementName,
                element: element.element.elementId,
                hrElementPercentagelist: element.element.hrElementPercentage,
                hrElementPercentage: element.elementPercentage,
                salaryPerMonth: element.annexureSalaryPerMonth?.toFixed(2),
                salaryPerAnnum: element.annexureSalaryPerAnnum?.toFixed(2),
                isEditable: [true],
                status: element.status
              })
            });

          })
        }

      })
    }

      this.payrollService.getListTypeById(15).subscribe((result: any) => {
        if (result.statusCode) {
          this.livingcity.length = 0;
        } else {
          console.log("else")
          this.livingcity = result;
        }

      })


    }



  }

  validateannexure() {
    const control = this.postForm.get('tableRows') as FormArray;
    let allowanceindex;
    let totalannualincome = 0;
    control.value.forEach((element, i) => {
      console.log(element);

      if (element.elementName.toUpperCase() == "ALLOWANCE") {
        allowanceindex = i;
      } else if (element.elementName.toUpperCase() != "ALLOWANCE") {
        totalannualincome += Number(element.salaryPerAnnum)
      }
    })


    if (totalannualincome < this.postForm.value.ctc) {

      if (allowanceindex) {
        console.log("has allowance");
        control.at(allowanceindex).patchValue({

          salaryPerMonth: 0.00,
          salaryPerAnnum: 0.00
        })

        console.log("this.postForm.value.ctc" + this.postForm.value.ctc);
        console.log("this.totalannualincome" + totalannualincome);
        let Totalannualincome = totalannualincome; // Convert to number if it's not already
        let ctc = Number(this.postForm.value.ctc);
        console.log("totalannualincome" + totalannualincome);
        console.log("ctc" + ctc);
        let salaryannum = Totalannualincome - ctc;
        console.log("salaryannum" + salaryannum);
        salaryannum = Math.abs(salaryannum);
        let salaryPerMonth = salaryannum / 12;
        control.at(allowanceindex).patchValue({
          //  hrElementPercentagelist : element.hrElementPercentage ,
          salaryPerMonth: salaryPerMonth?.toFixed(2),
          salaryPerAnnum: salaryannum?.toFixed(2)
        })
      }

    }

  }

  get getFormControls() {
    //alert("getformcontrols")
    const control = this.postForm.get('tableRows') as FormArray;
    if (control != null) {
      return control;
    }


  }
  clear() {
    setTimeout(() => {
      const control = this.postForm.get('tableRows') as FormArray;
      control.clear();
      this.filterele.forEach((element, index) => {
        this.addRow();
        if (element.elementName != "HRA") {
          control.at(index).patchValue({
            hrElementPercentagelist: element.hrElementPercentage,
            hrElementPercentage: element.hrElementPercentage[0].elementPercentage,
            element: element.elementId,
            elementName: element.elementName
          })
        } else {
          control.at(index).patchValue({
            hrElementPercentagelist: element.hrElementPercentage,
            element: element.elementId,
            elementName: element.elementName
          })
        }
      });
    }
      , 1000)
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  deleteRow(i, group) {
    const control = this.postForm.get('tableRows') as FormArray;
    console.log(group);
    console.log("group.value.annexureLineId" + group.value.annexureLineId);
    if (group.value.annexureLineId) {
      this.deletedrows.push(group.value);
    }
    control.removeAt(i);
  }

  gethrapercentage() {
    console.log("else")
    const control = this.postForm.get('tableRows') as FormArray;
this.postForm.get('livingCity').value;
this.chooseEvent=this.postForm.get('livingCity').value;


    control.controls.forEach((element, index) => {
      if (element.value.elementName == "HRA") {
        console.log("has hra")
        element.value.hrElementPercentagelist.forEach(perelement => {
          if (perelement.livingCityType.listTypeValueId == this.chooseEvent) {


            let partialAmount = this.calculatePartialAmount(perelement.elementPercentage, control.at(0).value.salaryPerAnnum);
            let salaryPerMonth = partialAmount / 12;

            control.at(index).patchValue({
              salaryPerMonth: salaryPerMonth?.toFixed(2),
              salaryPerAnnum: partialAmount?.toFixed(2)
            })

            control.at(index).patchValue({
              hrElementPercentage: perelement.elementPercentage,

            })
          }

        });

      } else {
        // control.at(index).patchValue({
        //   salaryPerMonth :0,
        //   salaryPerAnnum : 0
        // })
      }

    });



  }

  calcuatectc(event) {
    if (event.target.value.length != 0) {
      let ctc = event.target.value;
      const control = this.postForm.get('tableRows') as FormArray;
      control.value.forEach((element, index) => {


        if (element.hrElementPercentage) {
          if (element.elementName.toUpperCase() === "HRA" || element.elementName.toUpperCase() === "EMPLOYEE PF" || element.elementName.toUpperCase() === "EMPLOYER PF"
            || element.elementName.toUpperCase() === "GRATUITY" ||element.elementName.toUpperCase() === "EMPLOYER ESI" || element.elementName.toUpperCase() === "EMPLOYEE ESI"
            || element.elementName.toUpperCase() === "EMPLOYEEPF" || element.elementName.toUpperCase() === "EMPLOYERPF"
            ||element.elementName.toUpperCase() === "EMPLOYERESI" || element.elementName.toUpperCase() === "EMPLOYEEESI") {
            if (element.elementName.toUpperCase() === "EMPLOYER PF" || element.elementName.toUpperCase() === "EMPLOYERPF") {
              let emptyelement;
              control.at(index).patchValue({
                salaryPerMonth: 0.00,
                salaryPerAnnum: 0.00

              })
            } else if (element.elementName.toUpperCase() === "EMPLOYER ESI" || element.elementName.toUpperCase() === "EMPLOYERESI") {
              console.log("if employee pf")
              control.at(index).patchValue({
    
                salaryPerMonth: 0.00,
                salaryPerAnnum: 0.00
              })
            }
    
            else if (element.elementName.toUpperCase() === "EMPLOYEE PF" || element.elementName.toUpperCase() === "EMPLOYEEPF") {
              let partialAmount = this.calcuatepfamount(element, control.at(0).value.salaryPerAnnum, control.at(0).value.salaryPerMonth);
              let salaryPerMonth = partialAmount / 12;
              console.log(this.postForm.value.ctc);

              control.at(index).patchValue({
                salaryPerMonth: salaryPerMonth?.toFixed(2),
                salaryPerAnnum: partialAmount?.toFixed(2)
              })

            }  else if (element.elementName.toUpperCase() === "EMPLOYEE ESI" || element.elementName.toUpperCase() === "EMPLOYEEESI") {
              console.log('empesi amt')
              let partialAmount = this.calcuateesiamount(element, control.at(0).value.salaryPerAnnum, control.at(0).value.salaryPerMonth);
              let salaryPerMonth = partialAmount / 12;
              console.log(this.postForm.value.ctc);
    
              control.at(index).patchValue({
                salaryPerMonth: salaryPerMonth?.toFixed(2),
                salaryPerAnnum: partialAmount?.toFixed(2)
              })
    
            } else {
              console.log("if");
              console.log(this.postForm.value.ctc);
              let partialAmount = this.calculatePartialAmount(element.hrElementPercentage, control.at(0).value.salaryPerAnnum);
              let salaryPerMonth = partialAmount / 12;
              console.log(this.postForm.value.ctc);

              control.at(index).patchValue({
                salaryPerMonth: salaryPerMonth?.toFixed(2),
                salaryPerAnnum: partialAmount?.toFixed(2)
              })
            }



          } else {
            console.log(this.postForm.value.ctc);
            let partialAmount = this.calculatePartialAmount(element.hrElementPercentage, this.postForm.value.ctc);
            let salaryPerMonth = partialAmount / 12;
            console.log(this.postForm.value.ctc);

            control.at(index).patchValue({
              salaryPerMonth: salaryPerMonth?.toFixed(2),
              salaryPerAnnum: partialAmount?.toFixed(2)
            })
          }

        }
      });
      console.log(control);
      this.showctcerror = false;
    }


  }

  // calcuatectcline(group, i) {
  //   console.log(group);
  //   const control = this.postForm.get('tableRows') as FormArray;
  //   if (this.postForm.value.ctc) {
  //     console.log("if");
  //     if (group.value.elementName.toUpperCase() === "HRA" || group.value.elementName.toUpperCase() === "EMPLOYER PF" || group.value.elementName.toUpperCase() === "EMPLOYEE PF"
  //       || group.value.elementName.toUpperCase() === "GRATUITY" || group.value.elementName.toUpperCase() === "EMPLOYER ESI" || group.value.elementName.toUpperCase() === "EMPLOYEE ESI" ) {
  //       console.log("element name" + group.value.elementName.toUpperCase())
  //       if (group.value.elementName.toUpperCase().includes("EMPLOYER")) {
  //         console.log("if employee pf")
  //         control.at(i).patchValue({

  //           salaryPerMonth: 0.00,
  //           salaryPerAnnum: 0.00
  //         })
  //       }
  //       // if (group.value.elementName.toUpperCase() === "EMPLOYER ESI") {
  //       //   console.log("if employee pf")
  //       //   control.at(i).patchValue({

  //       //     salaryPerMonth: 0.00,
  //       //     salaryPerAnnum: 0.00
  //       //   })
  //       // }


  //       else if (group.value.elementName.toUpperCase() === "EMPLOYEE PF") {
  //         console.log('emppf amt')
  //         let partialAmount = this.calcuatepfamount(group.value, control.at(0).value.salaryPerAnnum, control.at(0).value.salaryPerMonth);
  //         let salaryPerMonth = partialAmount / 12;
  //         console.log(this.postForm.value.ctc);

  //         control.at(i).patchValue({
  //           salaryPerMonth: salaryPerMonth?.toFixed(2),
  //           salaryPerAnnum: partialAmount?.toFixed(2)
  //         })

  //       }
  //       else if (group.value.elementName.toUpperCase() === "EMPLOYEE ESI") {
  //         console.log('empesi amt')
  //         let partialAmount = this.calcuateesiamount(group.value, control.at(0).value.salaryPerAnnum, control.at(0).value.salaryPerMonth);
  //         let salaryPerMonth = partialAmount / 12;
  //         console.log(this.postForm.value.ctc);

  //         control.at(i).patchValue({
  //           salaryPerMonth: salaryPerMonth?.toFixed(2),
  //           salaryPerAnnum: partialAmount?.toFixed(2)
  //         })

  //       }
  //       else {
  //         console.log("if");
  //         console.log(this.postForm.value.ctc);
  //         let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, control.at(0).value.salaryPerAnnum);
  //         let salaryPerMonth = partialAmount / 12;
  //         console.log(this.postForm.value.ctc);

  //         control.at(i).patchValue({
  //           salaryPerMonth: salaryPerMonth?.toFixed(2),
  //           salaryPerAnnum: partialAmount?.toFixed(2)
  //         })
  //       }

  //     } else {
  //       console.log(this.postForm.value.ctc);
  //       let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, this.postForm.value.ctc);
  //       let salaryPerMonth = partialAmount / 12;
  //       console.log(this.postForm.value.ctc);

  //       control.at(i).patchValue({
  //         salaryPerMonth: salaryPerMonth?.toFixed(2),
  //         salaryPerAnnum: partialAmount?.toFixed(2)
  //       })
  //     }


  //   }

  // }
  calcuatectcline(group, i) {
    console.log("calcuatectcline",group,group.value.elementName);
    console.log(group.value.elementName.toUpperCase() === "EMPLOYER PF")
        console.log(group.value.elementName.toUpperCase() === "EMPLOYEE PF")
    const control = this.postForm.get('tableRows') as FormArray;
    if (this.postForm.value.ctc) {
      console.log("if");
      if (group.value.elementName.toUpperCase() === "HRA" || group.value.elementName.toUpperCase() === "EMPLOYER PF" || group.value.elementName.toUpperCase() === "EMPLOYEE PF"
        || group.value.elementName.toUpperCase() === "GRATUITY" || group.value.elementName.toUpperCase() === "EMPLOYER ESI" || group.value.elementName.toUpperCase() === "EMPLOYEE ESI" 
        || group.value.elementName.toUpperCase() === "EMPLOYERPF" || group.value.elementName.toUpperCase() === "EMPLOYEEPF"
        || group.value.elementName.toUpperCase() === "EMPLOYERESI" || group.value.elementName.toUpperCase() === "EMPLOYEEESI" 
        || group.value.elementName.toUpperCase() === "EMPLOYEERPF" || group.value.elementName.toUpperCase() === "EMPLOYEERESI" 
        || group.value.elementName.toUpperCase() === "EMPLOYEER PF" || group.value.elementName.toUpperCase() === "EMPLOYEER ESI"  ) {
        console.log("element name" + group.value.elementName.toUpperCase())

        

        if (group.value.elementName.toUpperCase() === "EMPLOYER PF" || group.value.elementName.toUpperCase() === "EMPLOYERPF"
        || group.value.elementName.toUpperCase() === "EMPLOYEERPF" || group.value.elementName.toUpperCase() === "EMPLOYEER PF") {
          console.log("if employer pf",control.at(i))
          control.at(i).patchValue({

            salaryPerMonth: 0.00,
            salaryPerAnnum: 0.00
          })
        }
       else if  (group.value.elementName.toUpperCase() === "EMPLOYER ESI" || group.value.elementName.toUpperCase() === "EMPLOYERESI"
        || group.value.elementName.toUpperCase() === "EMPLOYEERESI" || group.value.elementName.toUpperCase() === "EMPLOYEER ESI") {
          console.log("if employer esi",control.at(i))
         

          control.at(i).patchValue({
           
            
            salaryPerMonth: 0.00,
            salaryPerAnnum: 0.00
          })
        }

        else if (group.value.elementName.toUpperCase() === "EMPLOYEE PF" || group.value.elementName.toUpperCase() === "EMPLOYEEPF") {
          console.log('emppf amt')
          let partialAmount = this.calcuatepfamount(group.value, control.at(0).value.salaryPerAnnum, control.at(0).value.salaryPerMonth);
          let salaryPerMonth = partialAmount / 12;
          console.log(this.postForm.value.ctc);

          control.at(i).patchValue({
            salaryPerMonth: salaryPerMonth?.toFixed(2),
            salaryPerAnnum: partialAmount?.toFixed(2)
          })

        }
        else if (group.value.elementName.toUpperCase() === "EMPLOYEE ESI" || group.value.elementName.toUpperCase() === "EMPLOYEEESI") {
          console.log('empesi amt')
          let partialAmount = this.calcuateesiamount(group.value, control.at(0).value.salaryPerAnnum, control.at(0).value.salaryPerMonth);
          let salaryPerMonth = partialAmount / 12;
          console.log(this.postForm.value.ctc);

          control.at(i).patchValue({
            salaryPerMonth: salaryPerMonth?.toFixed(2),
            salaryPerAnnum: partialAmount?.toFixed(2)
          })

        }
        else {
          console.log("if");
          console.log(this.postForm.value.ctc);
          let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, control.at(0).value.salaryPerAnnum);
          let salaryPerMonth = partialAmount / 12;
          console.log(this.postForm.value.ctc);

          control.at(i).patchValue({
            salaryPerMonth: salaryPerMonth?.toFixed(2),
            salaryPerAnnum: partialAmount?.toFixed(2)
          })
        }

      } else {
        console.log(this.postForm.value.ctc);
        let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, this.postForm.value.ctc);
        let salaryPerMonth = partialAmount / 12;
        console.log(this.postForm.value.ctc);

        control.at(i).patchValue({
          salaryPerMonth: salaryPerMonth?.toFixed(2),
          salaryPerAnnum: partialAmount?.toFixed(2)
        })
      }


    }

  }

  calculatePartialAmount(percentage, totalAmount) {
    return (percentage / 100) * totalAmount;
  }

  calcuatepfamount(element, basicsalarayannual, basicsalaraymontly) {
    let partialAmount = 0;
    console.log("if calcuatepfamount");
    console.log(this.postForm.value.ctc);
    console.log("this.orgcompliancedata.length :  " + this.orgcompliancedata.length);
    if (this.orgcompliancedata.length != 0) {
      if (basicsalaraymontly >= this.orgcompliancedata[0].pfMaxSalaryAmount) {
        console.log("wihtin settings")
        partialAmount = this.orgcompliancedata[0].employeePfAmount * 12;

      } else {
        partialAmount = this.calculatePartialAmount(element.hrElementPercentage, basicsalarayannual);
      }


    } else {
      partialAmount = this.calculatePartialAmount(element.hrElementPercentage, basicsalarayannual);
    }


    console.log("partialAmount:  " + partialAmount);
    return partialAmount;
  }


  calcuateesiamount(element, basicsalarayannual, basicsalaraymontly) {
    let partialAmount = 0;
    console.log("if calcuatepfamount");
    console.log(this.postForm.value.ctc);
    console.log("this.orgcompliancedata.length :  " + this.orgcompliancedata.length);
      let montlyctc = this.postForm.value.ctc /12;
      console.log("montlyctc : "+montlyctc);
    if (this.orgcompliancedata.length != 0) {
      if ( montlyctc  >= this.orgcompliancedata[0].esiMaxSalaryAmount) {
        console.log("wihtin settings")
        partialAmount = this.orgcompliancedata[0].employeeEsiAmount * 12;

      } else {
        partialAmount = this.calculatePartialAmount(element.hrElementPercentage, this.postForm.value.ctc);
      }


    } else {
      partialAmount = this.calculatePartialAmount(element.hrElementPercentage, this.postForm.value.ctc);
    }


    console.log("partialAmount:  " + partialAmount);
    return partialAmount;
  }


  get validate() { return this.postForm.controls; }

  empSearch(event) {
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    if(employeenumber.length==0 ){
      console.log("length 0 came here!!");
      this.empList=[];
      
    }
    // Employee List Api
    if(employeenumber.length >= 2)
    {
      if(this.employeelistsub)
      {
        this.employeelistsub.unsubscribe();
      }

      if(this.orgId){
      this.employeelistsub = this.payrollService.getEmployeeNameList(employeenumber,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.payrollService.getEmployeeNameList(employeenumber,this.orgId))
        )
        .subscribe((result: any) => {
          this.empList.length = 0;

          if (result.statusCode) {
            console.log(result.statusCode);
            this.empList.length = 0;
          } else {
            this.empList = result;
          }
        });
      }
      if(this.clientId){
        this.employeelistsub = this.payrollService.getClientEmployeeNameList(employeenumber,this.clientId)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            switchMap(() => this.payrollService.getClientEmployeeNameList(employeenumber,this.clientId))
          )
          .subscribe((result: any) => {
            this.empList.length = 0;
  
            if (result.statusCode) {
              console.log(result.statusCode);
              this.empList.length = 0;
            } else {
              this.empList = result;
            }
          });
        }
	}
  else{
    console.log("else came!!!");
    
    this.empList=[];
  }

  }

  empSelect(emp) {
    console.log(emp);
    console.log(this.employee);

    this.employeeId = emp.employeeId;
    this.employee = emp.employeeNumber + "-" + emp.firstName;

    this.postForm.patchValue({
      employee: this.employee
    });
    this.payrollService.getannexueversionbyemployee(this.employeeId).subscribe((result: any) => {
      if (result.statusCode) {

      } else {
        var date = new Date(result.effectiveToDate)

        // Add a day
        date.setDate(date.getDate() + 1)
        this.maxstartdate = date;
      }

    })


  }

  getelemenetpercentage(group, i, event) {
    const control = this.postForm.get('tableRows') as FormArray;
    console.log(control);
    let elementexist = false;
    //control.value.some(e=> e.employeeId == event.target.value &&  i != index);
    control.value.forEach((element, index) => {
      if (element.element == event.target.value && i != index) {
        elementexist = true;
      }
    });

    if (!elementexist) {
      this.elementlist.forEach((element) => {
        console.log(element);
        if (element.elementId == event.target.value) {

          if (element.elementName.toUpperCase() == "ALLOWANCE" && this.totalannualincome < this.postForm.value.ctc) {

            control.at(i).patchValue({

              salaryPerMonth: 0.00,
              salaryPerAnnum: 0.00
            })
            //  console.log("ctc" + this.postForm.value.ctc);
            //  console.log("this.totalannualincome" + this.totalannualincome);
            let salaryannum = this.postForm.value.ctc - this.totalannualincome;
            salaryannum = Math.abs(salaryannum);
            let salaryPerMonth = salaryannum / 12;
            control.at(i).patchValue({
              elementName: element.elementName,
              hrElementPercentagelist: element.hrElementPercentage,
              salaryPerMonth: salaryPerMonth?.toFixed(2),
              salaryPerAnnum: salaryannum?.toFixed(2)
            })
          } else {
            control.at(i).patchValue({
              elementName: element.elementName,
              hrElementPercentagelist: element.hrElementPercentage,
              salaryPerMonth: 0.00,
              salaryPerAnnum: 0.00
            })
          }

        }



      })
    } else {
      alert("Element already exist");
      control.at(i).patchValue({
        elementName: null,
        element: null,
        salaryPerMonth: 0.00,
        salaryPerAnnum: 0.00
      })
    }


  }

  addelement() {
    if (this.postForm.value.ctc) {
      const control = this.postForm.get('tableRows') as FormArray;
      this.addRow();
    } else {
      this.showctcerror = true;
    }

  }

  addRow() {
    const control = this.postForm.get('tableRows') as FormArray;
    control.push(this.initiateForm());
  }
  initiateForm(): FormGroup {

    return this.formBuilder.group({
      annexureLineId: [null],
      elementName: [null],
      element: [null, Validators.required],
      hrElementPercentagelist: [[]],
      hrElementPercentage: [null],
      salaryPerMonth: [null, [Validators.required]],
      salaryPerAnnum: [null, [Validators.required]],
      status: [null],
      isEditable: [true]
    });
  }


  calucuatemontly() {
    let totalAmount = 0;
    const control = this.postForm.get('tableRows') as FormArray;
    control.controls.forEach(element => {
      totalAmount = Number(totalAmount) + Number(element.value.salaryPerMonth);
    });
    this.totalmontlyincome = totalAmount.toFixed(2);
    return totalAmount;
  }

  calucuateyearly() {
    let totalAmount = 0;
    const control = this.postForm.get('tableRows') as FormArray;
    control.controls.forEach(element => {
      totalAmount = Number(totalAmount) + Number(element.value.salaryPerAnnum);
    });
    this.totalannualincome = totalAmount;
    return totalAmount;
  }

  pageChages() {
    this.p = 1;
  }
  onSubmit() {
    this.isProcessing = true; // Disable the button

    //alert("form submitted");
    this.submitted = true;
    //  const controls = this.postForm.controls;
    // console.log("postForm" + this.postForm.invalid)

    this.checktotalamount = this.postForm.value.ctc == this.totalannualincome
    console.log("checktotalamount : " + this.checktotalamount);
    if (this.postForm.invalid || this.checktotalamount == false) {
      this.isProcessing = false; // Disable the button

      console.log("invalid")
      console.log(this.postForm.controls);
      for (const name in this.postForm.controls) {
        if (this.postForm.controls[name].invalid) {
          console.log(name);
        }
      }
      return;
    }



    if (this.empannexueId) //Update Employee annexue
    {

      let postValues = this.postForm.value;
      let annuexdata:any = {
        annexureHeaderId: this.empannexue.annexureHeaderId,
        ctc: postValues.ctc,
        effectiveFromDate: this.datepipe.transform(postValues.startDate, 'yyyy-MM-dd'),
        effectiveToDate: this.datepipe.transform(postValues.endDate, 'yyyy-MM-dd'),
        place: postValues.place,
        employee: {
          employeeId: this.employeeId
        },
        livingCity: {
          listTypeValueId: postValues.livingCity
        },
        
       
        status: this.empannexue.status,
        draft: true

      }

      if (this.businessgroup) {
        annuexdata.businessgroup = {
          businessGroupId: this.businessgroup
        };
      }
    
      // Conditionally add businessunit if available
      if (this.businessunit) {
        annuexdata.businessunit = {
          businessUnitId: this.businessunit
        };
      }
    
      // Conditionally add organization if available
      if (this.organization) {
        annuexdata.organization = {
          organizationId: this.organization
        };
      }
      const control = this.postForm.get('tableRows') as FormArray;
      this.touchedRows = control.controls.filter(row => row).map(row => row.value);
      let linearray = [];
      this.touchedRows.forEach(element => {

        let line = {
          annexureLineId: element.annexureLineId,
          element: {
            elementId: element.element
          },
          elementPercentage: element.hrElementPercentage,
          salaryPerMonth: element.salaryPerMonth,
          salaryPerAnnum: element.salaryPerAnnum,
          status: element.status
        }
        linearray.push(line);
      });

      this.deletedrows.forEach(element => {
        let line = {
          annexureLineId: element.annexureLineId,
          element: {
            elementId: element.element
          },
          elementPercentage: element.hrElementPercentage,
          salaryPerMonth: element.salaryPerMonth,
          salaryPerAnnum: element.salaryPerAnnum,
          status: { listTypeValueId: 4 }
        }
        linearray.push(line);
      });

      annuexdata["lines"] = linearray;

      console.log(annuexdata);

      this.payrollService.updateEmployeeannexure(annuexdata, this.empannexueId).subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.success = true;
          this.sucess_msg = result.description;
          setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);


          setTimeout(() => {
            this.router.navigate(['/home/employee-annexue-list']);
          }, redirectMsgTimeOut)
        }
        else {
          this.error = true;
          this.error_msg = result.description;
          
        }
        this.isProcessing=false;

      }, err => {
        this.error = true;
        this.errorMsg = err.error.description;
        setTimeout(() => {
          this.error = false;
          // this.router.navigate(['/home/financialyear']);
        }, 3000);
        this.isProcessing=false;
      })

    }
    else  //Add Employee annexue
    {
      console.log()
      let postValues = this.postForm.value;
   
      let annuexdata:any= {
        ctc: postValues.ctc,
        effectiveFromDate: this.datepipe.transform(postValues.startDate, 'yyyy-MM-dd'),
        effectiveToDate: this.datepipe.transform(postValues.endDate, 'yyyy-MM-dd'),
        place: postValues.place,
        employee: {
          employeeId: this.employeeId 
        },
        livingCity: {
          listTypeValueId: postValues.livingCity
        },
      }
       // Conditionally add businessgroup if available
  if (this.businessgroup) {
    annuexdata.businessgroup = {
      businessGroupId: this.businessgroup
    };
  }

  // Conditionally add businessunit if available
  if (this.businessunit) {
    annuexdata.businessunit = {
      businessUnitId: this.businessunit
    };
  }

  // Conditionally add organization if available
  if (this.organization) {
    annuexdata.organization = {
      organizationId: this.organization
    };
  }
      const control = this.postForm.get('tableRows') as FormArray;
      this.touchedRows = control.controls.filter(row => row).map(row => row.value);
      let linearray = [];
      this.touchedRows.forEach(element => {

        let line = {
          element: {
            elementId: element.element
          },
          elementPercentage: element.hrElementPercentage,
          salaryPerMonth: element.salaryPerMonth,
          salaryPerAnnum: element.salaryPerAnnum,
        }
        linearray.push(line);
      });

      annuexdata["lines"] = linearray;

      console.log(annuexdata);
      this.payrollService.assignEmployeeannexure(annuexdata).subscribe((result: any) => {
        console.log(result);
        if (result.statusCode == 200) {
          this.success = true;
          this.sucess_msg = result.description;
          setTimeout(() => { this.success = false; }, 3000)
          //this.router.navigate(['/home/employee']);


          setTimeout(() => {
            this.router.navigate(['/home/employee-annexue-list']);
          }, redirectMsgTimeOut)
        }
        else {
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {
            this.error = false;
            // this.router.navigate(['/home/financialyear']);
          }, 3000);
        }
      this.isProcessing=false;
      }, err => {
        this.error = true;
        this.errorMsg = err.error.description;
        setTimeout(() => {
          this.error = false;
          // this.router.navigate(['/home/financialyear']);
        }, 3000);
        this.isProcessing=false;
      })





    }


  }
  onCityChange(event: MatSelectChange) {
    console.log(event)
    const selectedValue = event.value;
    console.log(selectedValue);

    if (selectedValue === 1) {
      this.getBusinessGroupData();
    } else if (selectedValue === 2) {
      this.getBusinessUnit();
    } else if (selectedValue === 3) {
      this.getOrganizationData();
    }
  }

  passedId(row){
    console.log(row)
    console.log(row.businessGroupId);
    console.log(row.businessUnitId);
    console.log(row.organizationId);
   this. businessgroup=row.businessGroupId;
   this.businessunit=row.businessUnitId;
   this.organization=row.organizationId;



  }

  getBusinessGroupData() {
    this.payrollService.getBusinessGroup(this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      // this.BusinessUnit=data;
      this.BusinessUnit = Array.isArray(data) ? data : [data];
      console.log(this.BusinessUnit);
      console.log(this.BusinessUnit.businessGroupId);

      
      
    });
    this.BusinessName=[];
    this.OrganizationName=[];

  }

  getBusinessUnit() {
    this.payrollService.getBusinessUnit(this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      this.BusinessName=data;
      this.BusinessUnit=[];
      this.OrganizationName=[];

      
    });
  }

  getOrganizationData() {

    this.payrollService.getOrganization(this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      this.OrganizationName=data;
      this.BusinessUnit=[];
      this.BusinessName=[];
      
    });
  }
  startDates(event){
    this.startdate_required=false;
    console.log("start Date:"+event.value);
    this.show_error_msg = false;
    this.min = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    if(this.max != null){
      if(this.max < this.min){
        console.log(this.max);
        this.show_error_msg = true;
      }
    }
  }
  EndDate(event){
    this.enddate_required=false;
    this.show_error_msg = false;
    console.log("start Date:"+event.value);
    this.max = this.datepipe.transform(event.value, 'yyyy-MM-dd');
  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Allow only alphanumeric characters, hyphens, and underscores
    const regex = /^[a-zA-Z0-9-_]*$/;
    if (!regex.test(value)) {
      value = value.replace(/[^a-zA-Z0-9-_]/g, '');
    }
  
    input.value = value;
    this.postForm.get('employee').setValue(value);
  }
}
