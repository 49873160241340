<!-- 
<share-buttons theme="circles-dark"
[include]="['copy', 'facebook', 'email', 'messenger', 'mix', 'line', 'linkedin', 'pinterest', 'print', 'reddit', 'sms', 'telegram', 'tumblr', 'twitter', 'viber', 'vk', 'xing', 'whatsapp']"
[showIcon]="true"
[showText]="false"
url="https://rodrigo.kamada.com.br/"
description="Angular Share Buttons"
twitterAccount="rodrigokamada"
class="pt-5">
</share-buttons> -->
<div class="sb-div">
    <div class="share-close-div">
     <div class="share-div">
         Share
     </div> 
     <!-- <div class="close-div"> -->
         <button class="close-div" mat-dialog-close style="color: red;">X</button>
     <!-- </div> -->
    </div>
     <div class="sb-div-1">
         <share-buttons theme="circles-dark"
         [include]="['copy', 'email', 'whatsapp']"
         [showIcon]="true"
         [showText]="false"
         url="{{link}}"
         description="Resource Feedback"
         class="share-btns">
         </share-buttons>
     </div>
    
     <!-- <div class="share-link-div">
         <a>{{link}}</a>
         <button class="btn btn-primary search-btn copy-btn"  [class.clicked]=""  (click)="copyText(link)">Copy</button>
     </div> -->
     <div class="share-link-div">
        <!-- <div class="tooltip"> -->
            <a>{{link}}</a>
            <button class="btn btn-primary search-btn copy-btn" (click)="copyText(link)" (mouseleave)="resetTooltip()">
              <!-- <span class="tooltiptext btn-primary search-btn copy-btn">{{ tooltipText }}</span> -->
              Copy 
            </button>
          <!-- </div> -->
 </div>
 
 