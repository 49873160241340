<div class="row" style="display:flex;flex-wrap: inherit;">
    <div class="col-md-10">
      <h4> {{data.customerId ? 'Update Customer': 'Create Customer'}}</h4>
    </div>
    <div align="end" class="col-md-2">
      <span class="material-icons"style="color:red;" mat-dialog-close>cancel</span>
    </div>
  </div>
  <form [formGroup]="customerForm" (ngSubmit)="save()">
      <div mat-dialog-content>
        <div class="full-width" id="loginform">
          <label class="form-control-label">Customer Name<span class="text-danger">*</span></label>
          <input type="text" matInput 
            formControlName="customername"  
            (keyup)="customernamevalidate()"
            class="form-control email-input select-placeholder"
            placeholder="Enter customer name" 
            />
          <mat-error *ngIf="f.customername && submitted">
            {{_CustomerName()}}
          </mat-error>
        </div>
        <div class="full-width" id="loginform">
          <label class="form-control-label">Customer Address<span class="text-danger">*</span></label>
          <input type="text" matInput 
            formControlName="customeraddress" 
            (keyup)="customernamevalidate()"
            class="form-control email-input select-placeholder"
            placeholder="Enter customer address" />
          <mat-error *ngIf="f.customeraddress && submitted">
            {{_CustomerAddress()}}
          </mat-error>
        </div>
         <span class="span-error"  *ngIf="customerNameexist">Customer Already Exist</span>
      </div>
  
      <div align="end" mat-dialog-actions>
        <button class="btn create-button" type="submit" style="margin-bottom:15px;">
          {{data.customerId ? 'Update': 'Create'}}</button>
      </div>
  </form>
  
  