<!-- <div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Dashboard</span>
            </div>
            <div class="sub-header-buttons">
            </div>
        </div>
    </div>
</div> -->
<div class="common-content" style="top: 64px;">
    <div class="card-new p-0">
        <div>
            <!-- <div class="row">
                <div class="col-sm-12">
                    <h1 class="greetings">Hi {{ User_Name | titlecase }}!</h1>
                </div>
            </div> -->
            <div class="dashboard-cards">
                <div class="row">
                    <div class="col-sm-12">
                        <h4 class="summary" style="--font-size:larger; --font-weight: 900;">Summary</h4>
                    </div>
                </div>
                <div class="row db-card">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card p-3">
                                    <div class="top-row">
                                        <!-- <mat-icon class="icon-success">receipt_long</mat-icon> -->
                                        <img src="assets/uploads/Total Employee.png" alt="" height="45px" width="45px" style="background-color: #73C854; padding: 10px; border-radius: 50px; color: white;">
                                        <span class="card-name1 ">Total Leave <h4 class="count-card ">{{totalLeave}}</h4></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card p-3">
                                    <div class="top-row">
                                        <img src="assets/uploads/Balance Leave.png" alt="" height="45px" width="45px" style="background-color: #F08B14; padding: 10px; border-radius: 50px; color: white;">

                                        <!-- <mat-icon class="icon-primary">pending_actions</mat-icon> -->
                                        <span class="card-name1 ">Balance Leave <h4 class="count-card ">{{noOfDays}}</h4></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                              <div align="center">
                                <button type="button" class="apply-button" mat-raised-button color="sucess" [routerLink]="['/home/apply-leave']">
                                  <span class="apply-text">Apply Leave
                                    <mat-icon class="material-symbols-outlined apply-icon">expand_less</mat-icon>
                                  </span>
                                </button>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="$('selected').prop('disabled',true);  card p-3">
                                    <div class="top-row" style="display: flex;">
                                        <img src="assets/uploads/Total Permission.png" alt="" height="45px" width="45px" style="background-color: #73C854; padding: 10px; border-radius: 50px; color: white;">
                                        <span class="card-name1 ">Total Permission </span>
                                        <h4 class="count-card">{{maximumPermissionHours}}Hr</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card p-3">
                                    <div class="top-row" style="display: flex;">
                                        <img src="assets/uploads/Balance Permission.png" alt="" height="45px" width="45px" style="background-color: #46B1F9; padding: 10px; border-radius: 50px; color: white;">
                                        <span class="card-name1 ">Balance Permission </span>
                                        <h4 class="count-card ">{{balancePermission}}Hr</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div align="center">
                                    <button type="button" class="apply-button" mat-raised-button color="primary" [routerLink]="['/home/apply-permission']">
                                        <span class="apply-text">Apply Permission
                                            <mat-icon class="material-symbols-outlined apply-icon">expand_less</mat-icon>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 responsive-814">
                        <div class="row">
                            <div class="col-sm-12">
                                <h4 class="mystats">My Status</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1 p-0" style="max-height: 125px;min-height: 110px;">
                                <mat-card class="statuscard-hover">
                                <h5 class="block4-title">Leave Request</h5>
                                <span class="b4">
                                    <span class="material-icons-outlined block4-icon">receipt_long</span>
                                </span>
                                <h4 class="" style="display: flex;">
                                    <span class="badge badge-approved">Approved</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{leaveApproveCount}}</span>
                                </h4>
                                <h4 class="" style="display: flex;margin: 0;">
                                    <span class="badge badge-denied">Denied</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{leaveDeniedCount}}</span>
                                </h4>
                                </mat-card>
                            </div>
                            <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1 p-0" style="max-height: 125px;min-height: 110px;">
                                <mat-card class="statuscard-hover">
                                <h5 class="block4-title">Permission</h5>
                                <span class="b4">
                                    <span class="material-icons-outlined block4-icon">history</span>
                                </span>
                                <h4 class="" style="display: flex;">
                                    <span class="badge badge-approved">Approved</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{permissionApproveCount}}</span>
                                </h4>
                                <h4 class="" style="display: flex;margin: 0;">
                                    <span class="badge badge-denied">Denied</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{permissionDeniedCount}}</span>
                                </h4>
                                </mat-card>
                            </div>
                           
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1 p-0" style="max-height: 125px;min-height: 110px;">
                                <mat-card class="statuscard-hover">
                                <h5 class="block4-title">Work From Home</h5>
                                <span class="b4">
                                    <span class="material-icons-outlined block4-icon">work</span>
                                </span>
                                <h4 class="" style="display: flex;">
                                    <span class="badge badge-approved">Approved</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{workApproveCount}}</span>
                                </h4>
                                <h4 class="" style="display: flex;margin: 0;">
                                    <span class="badge badge-denied">Denied</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{workDeniedCount}}</span>
                                </h4>
                                </mat-card>
                            </div>
                            
                            <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1 p-0" style="max-height: 125px;min-height: 110px;">
                                <mat-card class="statuscard-hover">
                                <h5 class="block4-title">Business Travel</h5>
                                <span class="b4">
                                    <span class="material-icons-outlined block4-icon">room</span>
                                </span>
                                <h4 class="" style="display: flex;">
                                    <span class="badge badge-approved">Approved</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{businessApproveCount}}</span>
                                </h4>
                                <h4 class="" style="display: flex;margin: 0;">
                                    <span class="badge badge-denied">Denied</span>
                                    <span class="badge" style="color:#656B76;text-align: right">{{businessDeniedCount}}</span>
                                </h4>
                                </mat-card>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-3">
                                <mat-card class="block6-left" style="margin: 10px 0px;">
                                <div class="title-card-left">
                                    <div class="row">
                                    <div style="width:50%;padding: 0px 10px;">
                                        <span class="intime">&nbsp;In-Time&nbsp;&nbsp;</span>
                                    </div>
                                    <div style="width:50%;padding: 0px 10px;">
                                        <span class="intime">9.00 AM</span>
                                    </div>
                                    </div>
                                </div>
                                </mat-card>
                            </div>
                            <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-3">
                                <mat-card class="block6-right" style="margin: 10px 0px;">
                                <div class="title-card-left">
                                    <div class="row">
                                    <div style="width:50%;padding: 0px 10px;">
                                        <span class="intime">Out-Time</span>
                                    </div>
                                    <div style="width:50%;padding: 0px 10px;">
                                        <span class="intime">6.30 PM</span>
                                    </div>
                                    </div>
                                </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 responsive-814">
                        <div class="row">
                            <div class="col-sm-12">
                                <h4 class="mystats">Upcoming Leaves</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-card class="block5" style="margin: 10px 0px;">
                                    <div class="table-responsive">
                                        <table class="table block5-table" style="margin-bottom: 0;">
                                        <thead>
                                            <tr>
                                            <th scope="col" class="text-center">Date</th>
                                            <th scope="col" class="text-center">Day</th>
                                            <th scope="col" class="text-center">Description</th>
                                            <th scope="col" class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody style="">
                                            <tr *ngIf="upcomingHolidayLoader == true" >
                                                <div *ngIf="upcomingHolidayLoader" class="align-items-center text-center">
                                                    <div class="spinner-border" style="width: 2rem; height: 2rem;" role="status"></div>
                                                </div>
                                            </tr>
                                            <tr *ngIf="January.length !=0">
                                                <span scope="row" colspan="5" class="month-title">January,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of January; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="February.length !=0">
                                            <span scope="row" colspan="5" class="month-title">February,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of February; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                        
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="March.length !=0">
                                            <span scope="row" colspan="5" class="month-title">March,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of March; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                        
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="April.length !=0">
                                            <span scope="row" colspan="5" class="month-title">April,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of April; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="May.length !=0">
                                            <span scope="row" colspan="5" class="month-title">May,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of May; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="June.length !=0">
                                            <span scope="row" colspan="5" class="month-title">June,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of June; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="July.length !=0">
                                            <span scope="row" colspan="5" class="month-title">July,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of July; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="August.length !=0">
                                            <span scope="row" colspan="5" class="month-title">August,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of August; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="September.length !=0">
                                            <span scope="row" colspan="5" class="month-title">September,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of September; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>    
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="October.length !=0">
                                            <span scope="row" colspan="5" class="month-title">October,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of October; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="November.length !=0">
                                            <span scope="row" colspan="5" class="month-title">November,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of November; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr *ngIf="December.length !=0">
                                            <span scope="row" colspan="5" class="month-title">December,&nbsp;{{currentyear}}</span>
                                            </tr>
                                            <tr *ngFor="let e of December; let i=index" class="month-list">
                                            <ng-container *ngIf="e.endate;else startDate">
                                                <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                                            </ng-container>
                                            <ng-template #startDate>
                                                <td class="text-center">{{e.appliedDate}}</td>
                                            </ng-template>
                                            <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                                                <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                                                <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                                            </td>
                                            <td class="text-center">{{e.description}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                                            <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                                            </tr>
                                            <tr>
                                                <td *ngIf="totaldayscount == 0" style="text-align: center; vertical-align:middle;  font-size: 21px;
                                                font-family: 'Mulish', sans-serif;
                                                font-weight: 770; color:black" class="text-center" colspan="5">
                                                    <span >NO DATA</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                       
                                        
                                        </table>
                                    </div>
              
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
</div>
