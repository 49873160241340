<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content headingDesing">Candidates</span>
                </div>
                <div class="sub-header-buttons">
                    <button *ngIf="resourcelistdata?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button
                        style="    background: #1a83ff!important;color:#fff;"   class=" download-button">
                        Export
                    </button>
                    <a routerLink="/home/add-resource" *ngIf="isRecruiter" routerLinkActive="active" mat-raised-button class="ml-2  btnDesign "
                      style="    background: #1a83ff!important;color:#fff;"  title="Add Client" class="ml-2" >   
                        Add Candidate
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <section>
    <div class="sub-header"  style="height: 68px;">
            <div class="row">
                <div class="col-md-2">
                    <span class="h5 sub-content headingDesing">Candidates</span>
                </div>
                <div class="col-md-4" style="    position: relative;
    top: 7px;">
                    
                            <div class="sidebar-search">
                                <div class="input-group">
                                    <input type="input" class="form-control search-menu search-placeholder" placeholder="Search..." [(ngModel)]="search" (keyup)="changepage()" name="searchText"/>
                                </div>
                            </div>
                        </div>
                <div class="col-md-6 btnDesignset" style="position: relative;left: 150px;">
                    <button *ngIf="resourcelistdata?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button
                        style="    background: #1a83ff!important;color:#fff;"   class=" download-button">
                        Export
                    </button>
                    <a routerLink="/home/add-resource" *ngIf="isRecruiter" routerLinkActive="active" mat-raised-button class="ml-2  btnDesign "
                      style="    background: #1a83ff!important;color:#fff;"  title="Add Client" class="ml-2" >   
                        Add Candidate
                    </a>
                </div>

    
        </div>
    </div>
</section> -->
<!-- <nav class="navbar bg" style="margin-top: 67px">
  <div class="row w-100">
    <div class="col-md-3">
      <h4 class="heading-tag" style="position: relative; left: 5px">
        Candidates
      </h4>
    </div>

    <div class="col-md-5">
      <div class="input-group">
        <input
          type="input"
          class="form-control search-menu search-placeholder"
          placeholder="Search..."
          [(ngModel)]="search"
          (keyup)="changepage()"
          name="searchText"
        />
      </div>
    </div>

    <div class="col-md-4 d-flex justify-content-end btnDesignset">
      <button
        *ngIf="resourcelistdata?.length > 0 && (resourcelistdata | mytask : search).length > 0"
        type="button"
        (click)="exportToExcel()"
        mat-raised-button
        style="
          background: #1a83ff !important;
          color: #fff;
          height: 36px;
          padding-top: 0px !important;
          position: relative;
          top: -5px;
        "
        class="download-button exportDesign"
      >
        Export
      </button>
      <a
        routerLink="/home/add-resource"
        *ngIf="isRecruiter"
        routerLinkActive="active"
        mat-raised-button
        class="ml-2 btnDesign btnSearch"
        style="
          background: #1a83ff !important;
          color: #fff;
          height: 36px;
          padding-top: 3px !important;
          position: relative;
          top: -5px;
        "
        class="ml-2"
      >
        Add Candidate
      </a>
    </div>
  </div>
</nav> -->
<nav class="navbar bg" style="margin-top: 67px;">
  <div class="container-fluid">
      <div class="row w-100">
          <div class="col-sm-4 col-md-3 d-flex align-items-center mb-2 mb-sm-0">
              <h4 class="heading-tag" style="position: relative; left: 5px;">
                Candidates
              </h4>
          </div>

          <div class=" col-sm-8 col-md-9 d-flex justify-content-end align-items-center" style="  position: relative;
          top: -4px;
          left: 28px;">
              <div class="input-group me-3" style="max-width: 400px;">
                  <input type="text" [(ngModel)]="search"
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid search-input email-input select-placeholder"
                      name="keywords"
                      value=""
                      placeholder="Search...">
              </div>

              <div class="d-flex"  style="height: 38px;
                  padding-top: 5px !important;margin-top: -4px;">

<button
*ngIf="resourcelistdata?.length > 0 && (resourcelistdata | mytask : search).length > 0"
type="button"
(click)="exportToExcel()"
mat-raised-button
style="
  background: #1a83ff !important;
  color: #fff;
  height: 36px;
  padding-top: 0px !important;
  position: relative;
  top: -1px;
"
class="download-button exportDesign"
>
Export
</button>
                  
                  <a
                  routerLink="/home/add-resource"
                  *ngIf="isRecruiter"
                  routerLinkActive="active"
                  mat-raised-button
                  class="ml-2 btnDesign btnSearch"
                  style="
                    background: #1a83ff !important;
                    color: #fff;
                    height: 36px;
                    padding-top: 3px !important;
                    position: relative;
                    top: -1px;
                  "
                  class="ml-2"
                >
                  Add Candidates
                </a>
              </div>
          </div>
      </div>
  </div>
</nav>
<!-- Sub header end here-->

<mat-card
  style="
    border-radius: 15px;
    border: 2px solid #ddd !important;
    background: #fdfdfd;
    height: auto;
  "
>
  <div class="container">
    <div class="row">
      <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
        <div class="row mb-3 pl-0">
          <div class="col-md-3 mb-2">
            <!-- <select formControlName="position" class="form-select form-select-solid form-select-lg">
                        <option value=""> - Select Position - </option>
                        <option class="text-color" *ngFor="let position of positionlist" [value]="position.positionId">{{position.positionName}} - {{position.positionNumber}}</option>
                    </select> -->

            <!-- <select [(ngModel)]="selectedPosition" 
                     formControlName="position"
                      placeholder="- Select Position -"
                        class="form-select form-select-solid form-select-lg select2-new"
                          (change)="positionselected($event)">
                        <option [value]="null" selected="selected"> - Select Position - </option>
                        <option *ngFor="let position of positionlist; let i=index;"
                            value="{{position.positionId}}">{{position.positionName}} -
                            {{position.positionNumber}}</option>
                    </select> -->
                    <label class="form-control-label">
                      Position
                    </label>
            <mat-select
              class="form-control email-input select-placeholder"
              [(ngModel)]="selectedPosition"
              formControlName="position"
              placeholder="Select Position "
              (selectionChange)="positionselected($event)"
            >
              <mat-option [value]="null" selected="selected"
                >-Select Position-</mat-option
              >
              <mat-option
                *ngFor="let position of positionlist; let i = index"
                value="{{ position.positionId }}"
              >
                {{ position.positionName }} - {{ position.positionNumber }}
              </mat-option>
            </mat-select>
          </div>
          <div class="col-md-3 mb-2">
            <!-- <select [(ngModel)]="selectedRequirement" formControlName="requirement" placeholder="- Select Requirement -"
                    class="form-select form-select-solid form-select-lg select2-new"  (change)="requirement1($event)">
                    <option [value]="null" selected="selected"> - Select Requirement - </option>
                    <option *ngFor="let requirement of requrimentlist; let i=index;"
                        value="{{requirement.requirementId}}">{{requirement.requirementNo}}</option>
                </select> -->
                <label class="form-control-label">Requirement</label>
            <mat-select
              class="form-control email-input select-placeholder"
              [(ngModel)]="selectedRequirement"
              formControlName="requirement"
              placeholder=" Select Requirement"
              (change)="requirement1($event)"
            >
              <mat-option [value]="null" selected="selected"
                >-Select Requirement-</mat-option
              >
              <mat-option
                *ngFor="let requirement of requrimentlist; let i = index"
                value="{{ requirement.requirementId }}"
                >{{ requirement.requirementNo }}
              </mat-option>
            </mat-select>
          </div>

          <div class="col-xl-3 col-lg-3 mb-2">
            <!-- <select formControlName="interviewStatus" class="form-select form-select-solid form-select-lg">
                      <option value=""> - Select Interview Status - </option>
                      <option class="text-color"  *ngFor="let InterviewStatus of interviewStatuslist "  [value]="InterviewStatus.listTypeValueId">{{InterviewStatus.listTypeValueName}}</option>
                    </select> -->

            <!-- <select formControlName="interviewStatus" placeholder="- Select Interview Status -"
                        class="form-select form-select-solid form-select-lg select2-new">
                        <option [value]="null" selected="selected"> - Select Interview Status -
                        </option>
                        <option *ngFor="let InterviewStatus of interviewStatuslist; let i=index;"
                            value="{{InterviewStatus.listTypeValueId}}">
                            {{InterviewStatus.listTypeValueName}}</option>
                    </select> -->

                    <label class="form-control-label">
                      Interview Status
                    </label>

            <mat-select
              class="form-control email-input select-placeholder"
              formControlName="interviewStatus"
              placeholder="Select Interview Status"
            >
              <mat-option [value]="null" selected="selected"
                >-Select Interview Status-</mat-option
              >
              <mat-option
                *ngFor="
                  let InterviewStatus of interviewStatuslist;
                  let i = index
                "
                value="{{ InterviewStatus.listTypeValueId }}"
              >
                {{ InterviewStatus.listTypeValueName }}
              </mat-option>
            </mat-select>
          </div>
          <!-- <div class="col-xl-3 col-lg-3">
                    <button type="submit" mat-raised-button 
                        class="btn btn-primary search-btn">Search</button>
                </div> -->
          <div class="col-xl-3 col-lg-3 mb-3" style="    position: relative;
    top: 21px;">
            <button
              type="submit"
              mat-raised-button
             
              class=" search-btn searchBtn"
              [disabled]="btnDisables"
              style="border: 0px !important;background:#1a83ff;color:#fff"
            >
              Search
            </button>
            <button
              style="
                margin-left: 10px;
                background: none;
                color: black;
                border: 1px solid #ddd !important;
              "
              type="button"
              mat-raised-button
              class="btn-clear search-btn mr-2"
              (click)="clearforms()"
              [disabled]="btnDisables"
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card-content">
    <div
      class="row"
      *ngIf="
        resourcelistdata?.length > 5 &&
        (resourcelistdata | mytask : search).length > 0
      "
    >
      <div
        class="col-xl-12 col-lg-12"
        *ngIf="(resourcelistdata | mytask : search).length > 5"
      >
        <div class="select-pagination">
          <span>Show : </span>
          <select
            class="select_list new-select_list"
            [(ngModel)]="mySelect"
            (change)="showChanges()"
          >
            <option class="form-control" value="5">5</option>
            <option class="form-control" value="10">10</option>
            <option class="form-control" value="15">15</option>
            <option class="form-control" value="20">20</option>
            <option class="form-control" value="25">25</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <div class="card-body table-responsive" style="margin-top: 0px">
    <table
      matSort
      (matSortChange)="sortData($event)"
      class="table table-responsive"
      style="width: 100%"
      *ngIf="tableShow"
    >
      <thead>
        <tr>
          <th class="text-center" style="border-radius: 15px 1px 0px 0">
            Action
          </th>
          <th mat-sort-header="candiateName" style="width: 10%">
            Candidate&nbsp;Name
          </th>
          <th
            mat-sort-header="candiateNumber"
            style="width: 10%"
            class="text-center"
          >
            Candidate&nbsp;Number
          </th>
          <th
            mat-sort-header="requirement"
            style="width: 10%"
            class="text-center"
          >
            Requirement&nbsp;Number
          </th>
          <th
            mat-sort-header="positionName"
            style="width: 12%; align-items: center; padding: 5px 64px"
          >
            Position
          </th>
          <th
            mat-sort-header="interviewDate"
            style="width: 10%"
            class="text-center"
          >
            Interview&nbsp;date
          </th>
          <th
            mat-sort-header="totalExperience"
            style="width: 10%"
            class="text-center"
          >
            Total&nbsp;Experience
          </th>
          <th
            mat-sort-header="relevantExperience"
            style="width: 10%"
            class="text-center"
          >
            Relevent&nbsp;Experience
          </th>
          <th
            mat-sort-header="noticePeriod"
            style="width: 10%"
            class="text-center"
          >
            Notice&nbsp;Period
          </th>
          <th
            mat-sort-header="currentCtc"
            style="width: 10%"
            class="text-center"
          >
            Current&nbsp;CTC
          </th>
          <th
            mat-sort-header="expectedCtc"
            style="width: 10%"
            class="text-center"
          >
            Expected&nbsp;CTC
          </th>
          <!-- <th class="date-width">Job Location</th>	 -->
          <!-- <th class="">Resource Availability</th>	 -->
          <th mat-sort-header="interviewed" class="">Interviewed</th>
          <th mat-sort-header="interviewStatus" style="width: 10%">
            Interview Status
          </th>
          <th
            style="width: 20%"
            class="text-center"
            style="border-radius: 0px 15px 0px 0px"
          >
            Status
          </th>
        </tr>
      </thead>
      <tbody
        *ngIf="resourcelistdata?.length > 0"
        style="border-left: 1px solid #ddd; border-right: 1px solid #ddd"
      >
        <tr
          *ngFor="
            let e of resourcelistdata
              | mytask : search
              | paginate : { itemsPerPage: mySelect, currentPage: p }
          "
        >
          <ng-template #edit>
            <td class="text-center">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="controls-list">
                <button
                  mat-menu-item
                  routerLink="/home/resource/{{ e.resourceId }}"
                >
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <!-- <button mat-menu-item *ngIf="e.resourcecvattachment != undefined">
                            <a class="icon-badge"
                                href="{{e.resourcecvattachment.fileDownloadUrl}}">
                                <mat-icon
                                    class="material-icons-outlined downlaod-icon">file_download</mat-icon>
                                    <span style="font-weight: 400;
                                    color: rgba(0,0,0,.87);">Download</span>
                                </a>
                               
                        </button> -->

                <button
                  mat-menu-item
                  *ngIf="e.status.listTypeValueId == 1"
                  (click)="statuschangeactive(e.resourceId, 2)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color:red;  font-family: 'Mulish', sans-serif;
">Inactive</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="e.status.listTypeValueId == 2"
                  (click)="statuschangeinactive(e.resourceId, 1)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color:green; font-family: 'Mulish', sans-serif;
">Active</span>
                </button>

                <button
                  mat-menu-item
                  routerLink="/home/view-resource/{{ e.resourceId }}"
                  routerLinkActive="active"
                >
                  <mat-icon>remove_red_eye</mat-icon>
                  <span>View</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="e.resourceFeedBack == undefined"
                  (click)="openDialog(e.resourceId)"
                >
                  <a
                    ><mat-icon
                      style="position: relative; top: 4px !important"
                      class="material-icons-round icon-size"
                      >share</mat-icon
                    ></a
                  >
                  <span>Share</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="e.resourceFeedBack != undefined"
                  (click)="
                    openDialog1(e.resourceId, e.resourceFeedBack.feedBackId)
                  "
                >
                  <a
                    ><mat-icon
                      style="position: relative; top: 4px !important"
                      class="material-icons-round icon-size"
                      >share</mat-icon
                    ></a
                  >
                  <span>Share</span>
                </button>
              </mat-menu>
            </td>
          </ng-template>
          <ng-container *ngIf="isAdmin; else edit">
            <td class="table-data text-center">
              <span style="display: flex">
                <a
                  ><mat-icon
                    routerLink="/home/view-resource/{{ e.resourceId }}"
                    class="material-icons-round icon-size"
                    style="position: relative; top: 4px !important"
                    >visibility</mat-icon
                  ></a
                >
                <a
                  ><mat-icon
                    *ngIf="e.resourceFeedBack != undefined"
                    routerLink="/home/resourcefeedback/{{ e.resourceId }}"
                    class="material-icons-round icon-size"
                    style="position: relative; top: 4px !important"
                    >feedback</mat-icon
                  ></a
                >
                <a
                  ><mat-icon
                    *ngIf="e.resourceFeedBack == undefined"
                    (click)="openDialog(e.resourceId)"
                    style="position: relative; top: 4px !important"
                    class="material-icons-round icon-size"
                    >share</mat-icon
                  ></a
                >
                <a
                  ><mat-icon
                    *ngIf="e.resourceFeedBack != undefined"
                    (click)="
                      openDialog1(e.resourceId, e.resourceFeedBack.feedBackId)
                    "
                    style="position: relative; top: 4px !important"
                    class="material-icons-round icon-size"
                    >share</mat-icon
                  ></a
                >
              </span>
            </td>
          </ng-container>
          <td class="tab-full-width">{{ ucFirst(e.resourceName) }}</td>
          <td class="text-center">{{ e.resourceNumber }}</td>
          <td *ngIf="e.requirement != undefined" class="text-center">
            {{ e.requirement.requirementNo }}
          </td>
          <td *ngIf="e.requirement == undefined" class="text-center">-</td>
          <td class="tab-full-width">{{ ucFirst(e.position.positionName) }}</td>
          <td class="text-center">
            <!-- {{e.interviewDate.slice(0,10)}} -->
            <!-- {{e.interviewDate.slice(0,10) | date: 'dd-MMM-yyyy'}} -->
            {{ e.interviewDate ? (e.interviewDate | date : dateFormat) : "" }}
          </td>
          <td class="text-center">{{ e.totalExperience }} Years</td>
          <td class="text-center">{{ e.relevantExperience }} Years</td>
          <td class="text-center">{{ e.noticePeriod.listTypeValueName }}</td>
          <td class="text-center">{{ e.currentCtc }}</td>
          <td class="text-center">{{ e.expectedCtc }}</td>
          <td class="text-center">{{ e.interviewed ? "Yes" : "No" }}</td>
          <td *ngIf="e.interviewStatus == undefined" class="text-center">--</td>
          <td *ngIf="e.interviewStatus != undefined" class="text-center">
            <span
              class="text-success"
              *ngIf="e.interviewStatus.listTypeValueId == 28"
              >{{ e.interviewStatus.listTypeValueName }}</span
            >
            <span
              class="text-danger"
              *ngIf="e.interviewStatus.listTypeValueId == 29"
              >{{ e.interviewStatus.listTypeValueName }}</span
            >
            <span
              class="text-muted" style="color: orange !important;"
              *ngIf="e.interviewStatus.listTypeValueId == 30"
              >{{ e.interviewStatus.listTypeValueName }}</span
            >
          </td>
          <td class="active-status text-center">
            <span
              class="btn-outline-success"
              *ngIf="e.status.listTypeValueId == 1"
            >
              Active
            </span>
            <span
              class="btn-outline-danger"
              *ngIf="e.status.listTypeValueId == 2"
            >
              In-Active
            </span>
          </td>
        </tr>
      </tbody>
      <!-- <tbody>
            <tr>
                <td style="text-align: center; font-size: 13px;" colspan="9" *ngIf="nodata">No Records Found!</td>
            </tr>
        </tbody> -->
      <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd">
        <tr>
          <td
            style="
              width: 100%;
              text-align: center;
              font-size: larger;
              font-weight: bold;
              position: relative;
            "
            colspan="9"
            *ngIf="
              nodata ||
              ((resourcelistdata | mytask : search).length === 0 && search) ||
              resourcelistdata.length == 0
            "
          >
            Candidates Records Not Found!
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="pagination-content"
      *ngIf="
        resourcelistdata?.length > 5 &&
        (resourcelistdata | mytask : search).length > 0
      "
      id="page"
    >
      <pagination-controls
        *ngIf="(resourcelistdata | mytask : search).length > 5"
        style="position: relative; float: inline-end"
        (pageChange)="p = $event"
        align="end"
      ></pagination-controls>
    </div>
  </div>
</mat-card>

<!-- </div> -->

<!-- </div> -->

<!-- 
<div *ngIf="nodata">
    <div class="no-data card text-center">
        <div class="card-body">No Data</div>
    </div>
</div> -->

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
  style="height: 527vh !important"
></div>
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" style="height: 3rem;width: 3rem;" role="status"></div>
</div> -->

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
<!-- <div class="row">
    <div class="col-md-12">
        <pagination-controls class="float-right pagination" (pageChange)="p = $event; beforepage = $event"></pagination-controls>
    </div>
</div> -->

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="
      width: 3rem;
      height: 3rem;
      color: #1a83ff;
      position: relative;
      bottom: 0;
      margin-left: 97%;
      margin-top: 5%;
    "
    role="status"
  ></div>
</div>
