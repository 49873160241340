<div style="margin-top: 70px">
    <div class="main-content">
        <div class="container-fluid">
          <div class="top-content row">
            <div class="col-md-5 col-sm-5" *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'">
              <h4>Timesheet</h4>
              <span class="span-content sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">Timesheet</span>
            </div>
            <div class="col-md-5 col-sm-5" *ngIf="User_Type == 'HumanResource' || User_Type == 'Manager'">
              <h4>Timesheet</h4>
              <span class="span-content sub-cont"  [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">Timesheet</span>
            </div>
            <!-- <div> -->
              <!-- <mat-form-field class="example-width">
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="search" (keyup) = "changepage()"/>
                <mat-icon matPrefix >search</mat-icon>
              </mat-form-field> -->
              <div class="col-md-5 col-sm-5">
                <ul class="unstyled inbox-pagination">
                  <div class="sidebar-search">
                    <div class="input-group">
                      <input type="text" 
                        class="form-control search-menu" 
                        placeholder="Search..." 
                        [(ngModel)]="search"  
                        (keyup) = "changepage()" 
                        name="searchText">
                      <div class="material-icons-outlined search-icon">search</div>
                    </div>
                  </div>
                </ul>
              </div>
              <div class="col-md-2 col-sm-2 col2-res">
                <button type="button" mat-raised-button class="btn float-right" (click)="updateTime()">
                  <!-- <span class="material-icons">add</span> -->
                  <span>Add TimeSheet</span>
                </button>
              </div>
            <!-- </div> -->
          </div>
          <div class="search-content">
            <div class="row">
              <div class="col-md-3 col-lg-3 max-height">
                <label class="form-control-label">Project</label>
                <mat-select id="framework" class="form-control email-input select-placeholder" [(ngModel)]="projectId" 
                (selectionChange) = "getTask()" placeholder="Select Project">
                  <mat-option  value="0">-Select Project-</mat-option>             
                  <mat-option  *ngFor="let pro of projectList" [value]="pro.projectId">
                    {{pro.projectName}}
                  </mat-option>
                </mat-select>
                <span class="error_msg" *ngIf="projectId">Please select project</span>   
              </div>
              <div class="col-md-3 col-lg-3  max-height">
                <label class="form-control-label">Task</label>
                <mat-select id="framework" class="form-control email-input select-placeholder" 
                [(ngModel)]="taskId" placeholder="Select Task"
                [disabled] = "task_disable">
                  <mat-option value="0">-Select Task-</mat-option>
                  <mat-option  *ngFor="let task of taskList" [value]="task.taskId">
                    {{task.taskName}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-2 col-lg-2  max-height">
                <label class="form-control-label">Start Date</label>
                <input placeholder="Select Date"  matInput [matDatepicker]="picker1" class="form-control email-input" style="height: 43px;"  
                  [(ngModel)]="startDate" type="text" [max]="today" (dateChange)="dateChange($event)" id="startDate">
                  <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>  
                  <span class="error_msg" *ngIf="startdate_required">Please select startdate</span>
              </div>
              <div class="col-md-2 col-lg-2  max-height">
                <label class="form-control-label">End Date</label>
                <input placeholder="Select Date" matInput [matDatepicker]="picker2" class="form-control email-input" style="height: 43px;"  
                  [(ngModel)]="endDate" type="text"  [min]="startDate" [max]="today"  (dateChange)="dateChange($event)" id="endDate">
                  <mat-datepicker-toggle matSuffix [for]="picker2" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>  
                  <span class="error_msg" *ngIf="enddate_required">Please select enddate</span>
              </div>
              <div class="col-md-2 col-lg-2  button-align">
                <button type="button" class="btn searchBtn ml-0" (click)="searchTimesheet()">Search</button>
                <!-- <button  *ngIf="!nodata"  type="button" class="btn searchBtn" style="margin-left:10px" (click)="exportexcel()">Export</button> -->
                <button mat-button [matMenuTriggerFor]="menu" *ngIf="!nodata" class="btn searchBtn menu-button" style="margin-left:10px;">
                  <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="exportexcel()">
                    <!-- <mat-icon>file_download</mat-icon> -->
                    <span>Export</span>
                  </button>
                  <button mat-menu-item (click)="submitToApproval()" *ngIf="EmpList.weeklySubmit == true"> 
                    <!-- <mat-icon>send</mat-icon> -->
                    <span>Submit to approval</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <mat-card class="card-content" *ngIf="EmpList.length > 0">
            <div class="row p-0 m-0">
              <ng-container *ngIf="EmpList.length > 5;else alignend">
                <div class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9">
                  <span class="badge badge-light totaltime">Total Time : {{tasktotalTime}}</span>
                </div>
              </ng-container>
              <ng-template #alignend>
                <div class="col-xl-12 col-lg-12">
                  <span class="badge badge-light totaltime">Total Time : {{tasktotalTime}}</span>
                </div>
              </ng-template>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4" *ngIf="EmpList.length > 5">
                  <div class="select-pagination">
                      <span>Show : </span>
                      <select class="select_list new-select_list" [(ngModel)]="mySelect" (change) = "changefirstpage()">
                          <option class="form-control" value="5"> 5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                      </select>
                  </div>
              </div>  
            </div>
            <div class="card-body table-responsive">
              <!-- <ng-container *ngIf="EmpList.length > 0;else elseProject"> -->
                <table class="table"  matSort (matSortChange)="sortData($event)">
                  <thead>
                    <th style="text-align: center;">S.No</th>
                    <th mat-sort-header="projectName">Project&nbsp;Name</th>
                    <th mat-sort-header="taskName">Task&nbsp;Name</th>
                    <th mat-sort-header="date" style="text-align: center;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th mat-sort-header="workedHours" style="text-align: center;" >Worked&nbsp;Hours</th>
                    <th mat-sort-header="taskStatusName" style="text-align: center;" >Task&nbsp;Status</th>
                    <th mat-sort-header="approvalStatusName" style="text-align: center;" >Approval&nbsp;Status</th>
                    <th style="text-align: center;">Action</th>
                  </thead>
                  <tbody>
                      <tr *ngFor="let entry of EmpList | timelog:search
                      | paginate:{ itemsPerPage: mySelect, currentPage: p }; let i = index">
                          <td style="text-align: center;" *ngIf="p">{{((p-1)*mySelect)+(i+1)}}</td>
                          <td style="text-align: center;" *ngIf="!p">{{i+1}}</td>
                          <td>{{entry.project.projectName}}</td>
                          <td>{{entry.task.taskName}}</td>
                          <td style="text-align: center;padding-right:23px;" >{{entry.date}}</td>
                          <!-- //entry.date -->
                          <td style="text-align: center;padding-right:30px;">{{entry.workedHours}}</td>
                          <td style="padding-right:30px;">
                            <p class="status" [style.color]="withDrawStatus(entry)">{{ entry?.task?.taskStatusName }}</p>
                          </td>
                          <td style="padding-right:30px;">
                            <p class="status" [style.color]="approvalStatus(entry)">{{entry?.approval?.approvalStatusName }}</p>
                          </td>
                          <td class="more" align="center">
                            <span
                              class="material-icons"
                              [matMenuTriggerFor]="menu"
                              aria-label="Example icon-button with a menu">
                              more_vert
                            </span>
                            <mat-menu #menu="matMenu">
                              <button class="mat-menu-item" mat-menu-item (click)="viewapprover(entry)">
                                <mat-icon class="daterange-icon">date_range</mat-icon>
                                <span class="view-approver">View Approver</span>
                              </button>       
                              <button class="mat-menu-item" [disabled]="!entry.update" mat-menu-item (click)="edittimesheet(entry)">
                                <mat-icon class="edit-icon">edit</mat-icon>
                                <span class="edit-form">Edit</span>
                              </button>                     
                            </mat-menu>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <pagination-controls (pageChange)="p = $event ; beforesearchpage = $event"
              *ngIf="EmpList.length > 5" align="end"></pagination-controls>
              <!-- </ng-container> -->
            </div>
          </mat-card>
          <mat-card class="card-content" *ngIf="nodata">
            <div class="card-body">
                <div class="alert alert-primary">
                  <h4 align="center">Timesheet Not Found</h4>
                </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div *ngIf="loader" class="align-items-center text-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
      </div>
</div>
