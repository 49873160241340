import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LeaveService } from '../providers/leave.service';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
import { LeavemessageService } from '../leavemessage.service';

@Component({
  selector: 'app-addrecordsetting',
  templateUrl: './addrecordsetting.component.html',
  styleUrls: ['./addrecordsetting.component.scss']
})
export class AddrecordsettingComponent {
  selectedSetting: string = ''; // Holds the current radio button selection
  minTime: string = '';
  selectedOption: string = '';
  maxTime: string = '';
  maxTimes: string = '';
  pasteCount: any;
  isSubmitted: boolean = false;

  timesettingsForm:FormGroup;
  permissionsettingsForm:FormGroup;
  lastValidValue: string;
isDisabled: any;
isLoading:any = false;
  orgId: any;
  close_dialogue: boolean;
  success_msg: any;
  error_msg: any;
  // dialogRef: any;

constructor(private fb: FormBuilder,
      public dialogRef: MatDialogRef<AddrecordsettingComponent>,
      private leavemessage : LeavemessageService,
  
      private datepipe : DatePipe,
      private router:Router,
      private leaveservice:LeaveService
        ) { }

        ngOnInit(): void {
          this.close_dialogue = false;

          let localData = JSON.parse(localStorage.getItem("enoteUserData"));
        
          this.orgId = localData.organization.organizationId;
        
          this.timesettingsForm = this.fb.group({
            mintime: ['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]],
            maxtime: ['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]],
            maxtimes: ['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]]

          });
        
          // this.permissionsettingsForm = this.fb.group({
          //   maxtimes: ['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]]
          // });
        }

  save() {
    console.log("Submit");
    this.isSubmitted = true;
    
    if (this.timesettingsForm.invalid) {
      console.log("invalid")
      this.isSubmitted=false;
      return;
    }
    this.close_dialogue = false

    this. minTime = this.timesettingsForm.value.mintime;
    this. maxTime = this.timesettingsForm.value.maxtime;
    this.maxTimes=this.timesettingsForm.value.maxtimes;
    console.log('Minimum Time:', this.minTime);
    console.log('Maximum Time:', this.maxTime);
    if (this.minTime >= this.maxTime) {
      alert('Maximum Time should be greater than Minimum Time.');
      this.timesettingsForm.get('mintime')?.reset();
      this.timesettingsForm.get('maxtime')?.reset();

      return;
    }

    if (this.maxTimes >= this.maxTime) {
      alert('Permission max time exceeds Time Settings max time');
      this.timesettingsForm.get('maxtimes')?.reset();

      return;
    }
  
    this.isLoading = true;
    this.isDisabled = true;
    const settingsobj = {
      "minimumWokrHours": this.minTime,
      "maximumWokrHours": this.maxTime,
      "maximumPermissionHours":this.maxTimes,
      organizationId:this.orgId
      // "status": this.settingsdata.status,
      // "maximumPermissionHours": this.settingsdata.maximumPermissionHours,
    };
    console.log(settingsobj);

        this.leaveservice
          .createsettings(settingsobj)
          .subscribe(
            (d: any) => {
              this.dialogRef.close();
              console.log(d)
              if(d.statusCode===200){
                // this.success1 = true;
                // this.success_msg1 = d.description;
                // this.isLoading = false;
                this.success_msg =d.description ;
                this.leavemessage.setSuccessMessage(
                  d.description);
        
                // console.log("Success Message:", this.success_msg1);
        
               
                setTimeout(() => {   
                    // window.location.reload();
                }, 5000);
              }
              if (d.statusCode === 409) {
                this.dialogRef.close();
                this.error_msg = d.description;
                this.leavemessage.setErrorMessage(
                 d.description
                ); // Call method to set success message
              }
              setTimeout(() => {
                // this.router.navigate(['home/Emptimesettings-list'])
              }, 3000);
              this.isLoading = false;
            },
            err => {
              this.dialogRef.close();
              this.error_msg = err.error.description;
              this.leavemessage.setErrorMessage(
                err.error.description
                      );
      
              console.log("error Message:", this.error_msg);
              this.isLoading = false;
              this.isDisabled = false; // Re-enable form inputs if an error occurs
    
            }
          );
  }
 
  handleBackspace(event): void {
    const inputValue = event.target.value;
    console.log("empty")

    if (event.key === 'Backspace' && inputValue.length <= 1) {
      console.log("empty")
    }
  }
    validateInput(event: Event): void {
      const input = event.target as HTMLInputElement;
      const value = input.value;
    
      // Allow empty value (for backspace)
      if (value === '') {
        console.log("empty")
        this.lastValidValue = ''; // Update last valid value to empty
        // this.timesettingsForm.controls['mintime'].setValue(''); // Update form control
        // this.timesettingsForm.controls['maxtimes'].setValue(''); // Update form control

        return;
      }
    
      // Check if the new value is valid (numeric and of 1 digit)
      if (/^\d{1}$/.test(value)) {
        this.lastValidValue = value; // Update last valid value
      } else {
        console.log("empty1")

        // Revert to last valid value if the current input is invalid
        input.value = this.lastValidValue;
        // this.timesettingsForm.controls['mintime'].setValue(this.lastValidValue);
        // this.timesettingsForm.controls['maxtimes'].setValue(this.lastValidValue); // Update form control

      }
    }
 
  validateInput1(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
  
    // Allow empty value (for backspace)
    if (value === '') {
      console.log("empty")
      this.lastValidValue = ''; // Update last valid value to empty
      this.timesettingsForm.controls['maxtime'].setValue(''); // Update form control
      this.timesettingsForm.controls['maxtimes'].setValue(''); // Update form control

      return;
    }
  
    // Check if the new value is valid (numeric and of 1 digit)
    if (/^\d{1}$/.test(value)) {
      this.lastValidValue = value; // Update last valid value
    } else {
      console.log("empty1")

      // Revert to last valid value if the current input is invalid
      input.value = this.lastValidValue;
      this.timesettingsForm.controls['maxtime'].setValue(this.lastValidValue);
      this.timesettingsForm.controls['maxtimes'].setValue(this.lastValidValue); // Update form control

    }
  }
  noNumber(event: any) {
    const pattern = /[1-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    const inputElement = event.target;
    const currentValue = inputElement.value;
  
    // Check if the input character is not a number and not a dot
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      return;
    }
  
    // Allow dot only if there is a numeric expression before it
    if (inputChar === '.') {
      if (currentValue === '' || currentValue.endsWith('.')) {
        event.preventDefault();
        return;
      }
    }
  }
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }
}
