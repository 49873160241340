<h3 mat-dialog-title>
    View Module</h3>
<mat-dialog-content class="mat-typography">
    <span class="menu-name" *ngIf="data.moduleId.moduleCode != undefined">
      Module Code : {{data.moduleId.moduleCode}}
    </span>
    <span class="menu-name">
        Module Name : {{data.moduleId.moduleName}}
      </span>

    <span class="menu-name" *ngIf="data.moduleId.moduleDescription !=undefined">
        Module Description : {{data.moduleId.moduleDescription}}
      </span>
    <span class="menu-name">
        Module Status : {{data.moduleId.status.listTypeValueName}}
      </span>
    <div class="menu-name">
        <span class="menu-name" *ngIf="data.moduleId.menu!=null || data.moduleId.menu!=undefined">
       Assigned Menus :
      </span>
        <span class="menu-menu-names" *ngFor="let menus of data.moduleId.menu">
       {{menus.menuname}}
    </span>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>