<!-- Sub header start here-->
<section id="editForm">
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Employees</span>
        </div>
        <div class="sub-header-buttons">
          <mat-button-toggle-group
            name="fontStyle"
            class="table-view-icons active-list"
            aria-label="Font Style"
          >
            <mat-button-toggle
              (click)="tableView(1)"
              [ngClass]="{ 'active-list-btn': pageViews === 1 }"
              title="Grid View"
              class="grid-icon"
            >
              <mat-icon>grid_on</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              (click)="tableView(2)"
              [ngClass]="{ 'active-list-btn': pageViews === 2 }"
              title="List View"
              class="list-icon"
            >
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              (click)="tableView(3)"
              [ngClass]="{ 'active-list-btn': pageViews === 3 }"
              title="Card View"
              class="view-icon"
            >
              <mat-icon>view_module</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>

          <button
            *ngIf="resultData?.length > 0"
            type="button"
            (click)="exportToExcel()"
            mat-raised-button
            class="btn download-button ml-2 hoverImport"
          >
            Export
          </button>

          <a
            (click)="importEmployee()"
            mat-raised-button
            class="ml-2 hoverImport"
          >
            Import
          </a>

          <a
            (click)="createEmp()"
            routerLinkActive="active"
            style="color: white"
            title="Add Employee"
            class="ml-2 btn-small btn-primary"
          >
            Add Employee
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->
<div class="common-content" id="editForm">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <div class="col-md-12 p-0">
            <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
              <div class="row mb-3 pl-0">
                <div class="ol-xl-3 col-lg-3 mb-2">
                  <!-- [(ngModel)]="tableSearch"  -->
                  <label class="col-form-label">Employee</label>
                  <input
                    formControlName="keywords"
                    type="text"
                    autocomplete="off"
                    class="form-control search-input form-control form-control-lg form-control-solid"
                    value=""
                    placeholder="Search..."
                  />
                  <small class="text-muted"
                    >Note : Emp No., First Name,Mobile Number,Email</small
                  >
                </div>

                <div class="col-xl-3 col-lg-3 mb-2">
                  <label class="col-form-label">Role</label>
                  <!-- <select
                    formControlName="roleId"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null" selected>- Select Role -</option>
                    <option
                      *ngFor="let row of activeRolesData; let i = index"
                      value="{{ row.roleId }}"
                    >
                      {{ row.roleName }}
                    </option>
                  </select> -->

                  <mat-select
                    formControlName="roleId"
                    placeholder="Role"
                    class="form-control email-input select-placeholder no-border-on-focus"
                  >
                    <mat-option value="null" selected (click)="setRoleValues()"
                      >-Select Role-</mat-option
                    >
                    <mat-option
                      *ngFor="let row of activeRolesData; let i = index"
                      value="{{ row.roleId }}"
                    >
                      {{ row.roleName }}
                    </mat-option>
                  </mat-select>

                  <!-- <mat-select formControlName="roleId" placeholder="- Select Role -" #singleSelect  class="form-select form-select-solid form-select-lg select2-new">
                                      <mat-option>
                                          <ngx-mat-select-search    [formControl]="entityFilterCtrl"  placeholderLabel="Find Role..."
                                          noEntriesFoundLabel="No matching roles found"></ngx-mat-select-search>
                                        </mat-option>
                                      < <mat-option [value]="null" selected="selected"> - Select Role - </mat-option> 
                                      <mat-option *ngFor="let row of filteredEntities | async  " value="{{row.roleId}}">{{row.roleName}}</mat-option>
                                  </mat-select> -->
                  <!-- <input type="text" placeholder="--- Select Role ---" 
                                  formControlName="roleId"
                                  [matAutocomplete]="auto"
                                  class="form-control designchange" (keyup)="roleserach($event)">
                           <mat-autocomplete #auto="matAutocomplete">
                             <mat-option *ngFor="let role of activeRolesData" [value]="role.roleId" (click)="roleassign(role)">
                               {{role.roleName}}
                             </mat-option>
                           </mat-autocomplete> -->
                  <!-- <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 20px;">
                               search
                           </mat-icon> -->
                </div>
                <!-- <div class="form-group col-md-3" *ngIf="organizationId">
                  <label class="col-form-label">Client</label> -->
                  <!-- <select
                    formControlName="client"
                    class="form-select form-select-solid form-select-lg select2-new"
                    (change)="getclientEmployees()"
                  >
                    <option [value]="null">--- Select Client ---</option>
                    <option
                      *ngFor="let row of clientList; let i = index"
                      value="{{ row.clientId }}"
                    >
                      {{ row.clientCode }}
                    </option>
                  </select> -->

                  <!-- <mat-select
                    formControlName="client"
                    placeholder="Client"
                    class="form-control email-input select-placeholder no-border-on-focus"
                  >
                    <mat-option value="null" selected
                      >-Select Client-</mat-option
                    >
                    <mat-option
                      *ngFor="let row of clientList; let i = index"
                      [value]="row.clientId"
                    >
                      {{ row.clientCode }}-{{row.clientName}}
                    </mat-option>
                  </mat-select> -->
                  <!-- <div class="valid-msg" *ngIf="(basic.client.invalid && basic.client.touched) || basic.client.dirty || submitted">
                                      <span *ngIf="basic.client.errors?.required" class="text-danger">Please select client</span> -->
                  <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                  <!-- </div> -->
                <!-- </div> -->
                <div class="ol-xl-3 col-lg-3 mb-3" style="margin-top: 35px">
                  <button type="submit" mat-raised-button class="search-btn">
                    Search
                  </button>
                  <button
                    style="
                      margin-left: 10px;
                      height: 34px;
                      position: relative;
                      top: 4px;
                    "
                    type="reset"
                    mat-raised-button
                    class="mr-2 search-btn1"
                    (click)="clearForm()"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </form>
            <div class="--col-md-12 bvcbc">
              <!-- <div class="row">
                <div class="col-md-11 text-right text-center-sm">
                  <p class="show-count " style="margin-top: -2px">Show : </p>
                </div>
                <div class="col-md-1 text-left pl-0">
                  <select
                    [(ngModel)]="pagePerItem"
                    class="select_list new-select_list listDesign"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div> -->
              <div
                class="row p-0 m-0"
                *ngIf="
                  resultData.length > 5 &&
                  (resultData | mytask : search).length > 0 &&
                  showtable
                "





                
              >
                <div
                  class="col-xl-12 col-lg-12"
                  style="margin-bottom: 12px"
                  *ngIf="(resultData | mytask : search).length > 5"
                >
                  <div class="select-pagination" style="float: right">
                    <span>Show : </span>
                    <select
                      class="select_list new-select_list listDesign"
                      [(ngModel)]="pagePerItem"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changefirstpage()"
                    >
                      <option class="form-control" value="5">5</option>
                      <option class="form-control" value="10">10</option>
                      <option class="form-control" value="15">15</option>
                      <option class="form-control" value="20">20</option>
                      <option class="form-control" value="25">25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  <div>
                  <app-double-scroll>
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                              <tr>

                                  <td>table cell</td>
                                  <td>table cell</td>
                                  <td>table cell</td>
                                  <td>table cell</td>
                                  <td>table cell</td>
                                  <td>table cell</td>
                                  <td>table cell</td>
                                  <td>table cell</td>
                                  <td>table cell</td>
                              </tr>
                          </tbody>
                      </table>
                  </app-double-scroll>
              </div> -->

        <div class="grid_view" *ngIf="showtable && pageViews === 1">
          <div class="row">
            <div class="col-md-12">
              <!-- <app-double-scroll> -->
              <div
                class="table-responsive"
                style="
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                "
              >
                <table
                  matSort
                  (matSortChange)="sortData($event)"
                  class="table table-responsive1 table-bordered table-border-new1"
                  style="
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                  "
                >
                  <thead class="t-head">
                    <tr>
                      <!-- <th class="text-center">S.No</th> -->
                      <th class="text-center">Action</th>
                      <!-- <th mat-sort-header="roleName" style="width:15%;">Employee Role</th> -->
                      <th
                        mat-sort-header="employeeNumber"
                        style="width: 13%"
                        class="text-center"
                      >
                        Emp No.
                      </th>
                      <th mat-sort-header="firstName" style="width: 15%">
                        Name
                      </th>

                      <th
                        mat-sort-header="mobileNumber"
                        style="width: 12%"
                        class="text-center"
                      >
                        Mobile Number
                      </th>
                      <th
                        mat-sort-header="dateOfBirth"
                        style="width: 3%"
                        class="text-center"
                      >
                        Date Of Birth
                      </th>
                      <th mat-sort-header="organizationEmail">Email</th>
                      <!--  <th mat-sort-header="dateOfBirth" style="width:13%;" class="text-center">Date of Birth</th>
                                             <th class="text-center">Gender</th> -->
                      <th
                        mat-sort-header="dateOfJoining"
                        style="width: 6%"
                        class="text-center"
                      >
                        Joining Date
                      </th>
                      <th class="text-center" style="width: 10%">Status</th>
                    </tr>
                  </thead>
                  <tbody
                    *ngIf="resultData?.length > 0"
                    style="
                      border-left: 1px solid #ddd;
                      border-right: 1px solid #ddd;
                    "
                  >
                    <tr
                      *ngFor="
                        let row of resultData
                          | paginate
                            : { itemsPerPage: pagePerItem, currentPage: p }
                          | searchFilter : tableSearch;
                        let i = index
                      "
                    >
                      <!-- <td class="text-center">{{i+1}}</td> -->
                      <td class="text-center">
                        <button
                          mat-icon-button
                          [matMenuTriggerFor]="menu"
                          aria-label="Example icon-button with a menu"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #menu="matMenu" class="controls-list">
                          <button
                            mat-menu-item
                            routerLink="/home/edit-employee/{{
                              row.employeeId
                            }}"
                            routerLinkActive="active"
                          >
                            <!-- (click)="updateform(row)" -->
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                          </button>

                          <button
                            mat-menu-item
                            routerLink="/home/view-employee/{{
                              row.employeeId
                            }}"
                            routerLinkActive="active"
                          >
                            <mat-icon>visibility</mat-icon>
                            <span>View</span>
                          </button>

                          <button
                            mat-menu-item
                            routerLink="/home/employee-rol/{{ row.employeeId }}"
                            routerLinkActive="active"
                          >
                            <mat-icon>supervised_user_circle</mat-icon>
                            <span>Roles</span>
                          </button>

                          <button
                            mat-menu-item
                            *ngIf="row.status.listTypeValueId == 1"
                            (click)="updateStatus(row.employeeId, 2)"
                          >
                            <mat-icon>done</mat-icon>
                            <span style="color: red">Inactive</span>
                          </button>

                          <button
                            mat-menu-item
                            *ngIf="row.status.listTypeValueId == 2"
                            (click)="updateStatus(row.employeeId, 1)"
                          >
                            <mat-icon>done</mat-icon>
                            <span style="color: green">Active</span>
                          </button>
                        </mat-menu>
                      </td>
                      <!--  <td>{{ucFirst(row.role.roleName)}}</td> -->
                      <td class="text-left">{{ row?.employeeNumber }}</td>
                      <!-- <td>{{ucFirst(row?.firstName)}} {{ucFirst(row?.lastName)}}</td> -->
                      <td class="text-left">
                        {{ ucFirst(row?.firstName) }}
                        <ng-container *ngIf="row?.lastName; else noLastName">{{
                          " " + ucFirst(row?.lastName)
                        }}</ng-container>
                        <ng-template #noLastName></ng-template>
                      </td>
                      <td class="text-left">{{ row?.mobileNumber }}</td>
                      <td class="text-center">
                        <!-- {{
                          row.dateOfBirth
                            ? (row.dateOfBirth | date : dateFormat)
                            : "-"
                        }} -->
                            {{row.dateOfBirth}}
                      </td>
                      <td class="text-left">{{ row?.organizationEmail }}</td>
                      <!--  <td width="20%" class="text-center">
                                                  {{ row?.dateOfBirth ? (row?.dateOfBirth | date: dateFormat) : "" }}
                                              </td>
                                              <td class="text-center">{{row.gender.listTypeValueName}}</td> -->
                      <td class="text-center">
                        <!-- {{row.createDateTime}} -->
                        <!-- {{
                          row?.dateOfJoining
                            ? (row?.dateOfJoining | date : dateFormat)
                            : ""
                        }} -->
                        {{ row?.dateOfJoining ? row.dateOfJoining : '-' }}
                        <!-- //{{row.createDateTime | date: dateFormat}} -->
                      </td>
                      <!-- <td class="text-center">{{employee.status.listTypeValueName}}</td> -->
                      <td class="active-status text-center">
                        <span style="    color: green;
    border: none;"
                          class="btn  btn-sm btn-block"
                          *ngIf="row?.status.listTypeValueId == 1"
                        >
                          {{ row?.status.listTypeValueName }}
                        </span>

                        <span style=   " color: red;
                        border: none;"
                          class="btn  btn-sm btn-block"
                          *ngIf="row?.status.listTypeValueId == 2"
                        >
                          {{ row?.status.listTypeValueName }}
                        </span>
                      </td>
                    </tr>
                    <!-- <tr >
                      <td style="text-align: center; font-size: larger; font-weight: bold;" 
                    colspan="9"  *ngIf="nodata || (resultData | mytask : search).length === 0 && search || resultData.length == 0 " > Employee Records Not Found!</td>
                    </tr> -->
                  </tbody>
                  <tbody
                    style="
                      border-left: 1px solid #ddd;
                      border-right: 1px solid #ddd;
                    "
                  >
                    <tr>
                      <td
                        style="
                          text-align: center;
                          font-size: larger;
                          font-weight: bold;
                          font-family: 'Mulish', sans-serif;
                          border: none;
                        "
                        colspan="7"
                        *ngIf="
                          nodatafound ||
                          ((resultData | mytask : search)?.length === 0 &&
                            search) ||
                          resultData?.length == 0
                        "
                      >
                        Employee Records Not Found!
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- <div *ngIf="nodatafound" class="no-data text-center">
              
                  <span class="no-data-found"> No Data Found </span>
                </div> -->
              </div>
              <!-- </app-double-scroll> -->
            </div>
          </div>
        </div>

        <div class="list_view" *ngIf="pageViews == 2">
          <div *ngIf="resultData?.length > 0">
            <div class="row mb-3">
              <div
                class="col-md-12 mb-3"
                *ngFor="
                  let row of resultData
                    | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                    | searchFilter : tableSearch;
                  let i = index
                "
              >
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <!-- <div class="col-md-2 usr-profile-img">
                                              <img src="{{fileblob}}" alt="Profile Image" class="profile-image text-center">
                                          </div> -->
                      <div class="col-md-12 user-basics">
                        <span class="user-name-list mb-2">
                          {{ ucFirst(row?.firstName) }}
                          <ng-container
                            *ngIf="row?.lastName; else noLastName"
                            >{{ " " + ucFirst(row?.lastName) }}</ng-container
                          >
                          <ng-template #noLastName></ng-template>
                          <p class="user-id-list">
                            ( # {{ row.employeeNumber }})
                          </p>
                        </span>
                        <button
                          class="bars click-menu"
                          mat-icon-button
                          [matMenuTriggerFor]="menu"
                          aria-label="Example icon-button with a menu"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <!--  <p class="user-role-list m-0" *ngIf="!row.role">
                                                  <mat-icon>work</mat-icon>{{ucFirst(row.role.roleName)}}
                                              </p> -->
                        <!-- <span class="text-muted">{{row.gender.listTypeValueName}}</span> -->
                        <span class="list-emp-date pr-2"
                          ><mat-icon>calendar_month</mat-icon>
                          {{ row.dateOfBirth | date : "dd-MMM-yyyy" }}</span
                        >
                        <span class="list-emp-date pr-2"
                          ><mat-icon>work_outline</mat-icon>
                          {{ row.dateOfJoining | date : "dd-MMM-yyyy" }}</span
                        >
                        <span class="list-emp-date pr-2"
                          ><mat-icon>phone</mat-icon>
                          {{ row.mobileNumber }}</span
                        >
                        <span class="list-emp-date pr-2"
                          ><mat-icon>email</mat-icon>
                          {{ row.organizationEmail }}</span
                        >
                        <p class="use-active-status">
                          <span
                            class="text-success font-weight-bold btn-sm"
                            *ngIf="row.status.listTypeValueId == 1"
                          >
                            {{ row.status.listTypeValueName }}
                          </span>

                          <span
                            class="text-danger font-weight-bold btn-sm"
                            *ngIf="row.status.listTypeValueId == 2"
                          >
                            {{ row.status.listTypeValueName }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <mat-menu
                      #menu="matMenu"
                      class="controls-list"
                      style="position: relative; left: -9 !important"
                    >
                      <button
                        routerLink="/home/edit-employee/{{ row.employeeId }}"
                        routerLinkActive="active"
                        mat-menu-item
                      >
                        <!-- (click)="updateform(row)" -->
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>

                      <button
                        mat-menu-item
                        routerLink="/home/view-employee/{{ row.employeeId }}"
                        routerLinkActive="active"
                      >
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="row.status.listTypeValueId == 1"
                        (click)="updateStatus(row.employeeId, 2)"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: red">Inactive</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="row.status.listTypeValueId == 2"
                        (click)="updateStatus(row.employeeId, 1)"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: green">Active</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="nodatafound" class="no-data">
            <img src="assets/uploads/nodata.png" />
         
          </div> -->

          <div class="row">
            <div class="col-md-12">
              <!-- <app-double-scroll> -->
              <!-- <div class="table-responsive" *ngIf="showtable" style=" border-top-left-radius: 10px; border-top-right-radius: 10px;"> -->
              <div
                *ngIf="nodatafound"
                class="no-data text-center"
                style="
                  border: 2px solid #1a83ff;
                  padding: 20px;
                  border-radius: 6px;
                "
              >
                <span
                  class="no-data-found"
                  style="
                    font-size: larger;
                    font-weight: 770;
                    font-family: 'Mulish', sans-serif;
                  "
                >
                  Employee Records Not Found
                </span>
              </div>

              <!-- <div *ngIf="nodatafound" class="no-data text-center">
              
                  <span class="no-data-found"> No Data Found </span>
                </div> -->
              <!-- </div> -->
              <!-- </app-double-scroll> -->
            </div>
          </div>
        </div>

        <div class="card_view" *ngIf="pageViews == 3">
          <div *ngIf="resultData?.length > 0">
            <div class="row mb-3">
              <div
                class="col-md-4 mb-3"
                *ngFor="
                  let row of resultData
                    | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                    | mytask : search;
                  let i = index
                "
              >
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <!-- <div class="col-md-2 usr-card-profile-img mb-2">
                                              <img src="{{fileblob}}" alt="Profile Image" class="profile-image text-center">
                                          </div> -->
                    </div>
                    <div class="row1">
                      <div class="user-basics-card-new">
                        <span class="user-name-list">
                          <!-- {{ucFirst(row.firstName)}} {{ucFirst(row.lastName)}} -->
                          {{ ucFirst(row?.firstName) }}
                          <ng-container
                            *ngIf="row?.lastName; else noLastName"
                            >{{ " " + ucFirst(row?.lastName) }}</ng-container
                          >
                          <ng-template #noLastName></ng-template>
                          <p class="user-id-list">
                            ( # {{ row.employeeNumber }})
                          </p>
                          <button
                            class="bars click-menu"
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            aria-label="Example icon-button with a menu"
                          >
                            <mat-icon mat-icon-button>more_vert</mat-icon>
                          </button>
                        </span>

                        <!--  <p class="user-role-list m-0" *ngIf="!row.role">
                                                  <mat-icon>work</mat-icon>{{row.role.roleName}}
                                              </p> -->

                        <!-- <span class="text-muted">{{row.gender.listTypeValueName}}</span> -->
                        <span class="card-date pt-3 pr-2"
                          ><mat-icon>calendar_month</mat-icon>
                          {{ row.dateOfBirth | date : "dd-MMM-yyyy" }}</span
                        >
                        <span
                          *ngIf="row.dateOfJoining != undefined"
                          class="card-date pr-2"
                          ><mat-icon>work_outline</mat-icon>
                          {{ row.dateOfJoining | date : "dd-MMM-yyyy" }}</span
                        >

                        <span class="card-date pr-2"
                          ><mat-icon>phone</mat-icon>
                          {{ row.mobileNumber }}</span
                        >
                        <span class="card-date pr-2"
                          ><mat-icon>email</mat-icon>
                          {{ row.organizationEmail }}</span
                        >
                        <p class="use-active-status">
                          <span
                            class="text-success font-weight-bold btn-sm"
                            *ngIf="row.status.listTypeValueId == 1"
                          >
                            {{ row.status.listTypeValueName }}
                          </span>

                          <span
                            class="text-danger font-weight-bold btn-sm"
                            *ngIf="row.status.listTypeValueId == 2"
                          >
                            {{ row.status.listTypeValueName }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <mat-menu #menu="matMenu" class="controls-list">
                      <button
                        routerLink="/home/edit-employee/{{ row.employeeId }}"
                        routerLinkActive="active"
                        mat-menu-item
                      >
                        <!-- (click)="updateform(row)" -->
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>

                      <button
                        mat-menu-item
                        routerLink="/home/view-employee/{{ row.employeeId }}"
                        routerLinkActive="active"
                      >
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="row.status.listTypeValueId == 1"
                        (click)="updateStatus(row.employeeId, 2)"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: red">Inactive</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="row.status.listTypeValueId == 2"
                        (click)="updateStatus(row.employeeId, 1)"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: green">Active</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="nodatafound" class="no-data">
            <img src="assets/uploads/nodata.png" />
    
          </div> -->
          <div class="row">
            <div class="col-md-12">
              <!-- <app-double-scroll> -->
              <!-- <div class="table-responsive"  style=" border-top-left-radius: 10px; border-top-right-radius: 10px;"> -->

              <div
                *ngIf="nodatafound"
                class="no-data text-center"
                style="
                  border: 2px solid #1a83ff;
                  padding: 20px;
                  border-radius: 6px;
                "
              >
                <span
                  class="no-data-found"
                  style="
                    font-size: larger;
                    font-weight: 770;
                    font-family: 'Mulish', sans-serif;
                  "
                >
                  Employee Records Not Found
                </span>
              </div>
              <!-- </div> -->
              <!-- </app-double-scroll> -->
            </div>
          </div>
        </div>

        <div *ngIf="resultData?.length > 0 && showtable">
          <div class="row mt-3">
            <div class="col-md-4">
              <div class="showing-record">
                <!-- <ng-template pTemplate="paginatorleft" let-state>
                                  Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                              </ng-template> -->
                {{ resultData.length }} rows
              </div>
            </div>
            <!-- <div class="col-md-8 text-right "  *ngIf="resultData.length > 10" >
              <pagination-controls 
                (pageChange)="p = $event" style="float: right;"
              ></pagination-controls>
            </div> -->
            <div
              class="pagination-content"
              *ngIf="
                resultData?.length > 5 &&
                (resultData | mytask : search).length > 0 &&
                showtable
              "
              id="page"
            >
              <pagination-controls
                *ngIf="(resultData | mytask : search).length > 5"
                style="position: relative; float: inline-end; top: 7px"
                (pageChange)="p = $event"
                align="end"
              >
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
  style="height: 527vh !important"
></div>
<div *ngIf="loader" id="editForm" class="align-items-center text-center">
  <div
    class="spinner-border table-spinner"
    style="
      height: 3rem;
      width: 3rem;
      color: #009ef7;
      position: absolute;
      z-index: 1;
      top: 49%;
      right: 48%;
    "
    role="status"
  ></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>
