import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Reason, ShowLeave } from '../withdraw-list/withdraw-list.component';
import { LeaveService } from 'src/app/providers/leave.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { catchError, EMPTY, Subject, takeUntil } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-withdraw-report',
  templateUrl: './withdraw-report.component.html',
  styleUrls: ['./withdraw-report.component.scss']
})
export class WithdrawReportComponent implements OnInit {
  @ViewChild('picker1') startDatePicker!: MatDatepicker<any>;
  @ViewChild('p1') endDatePicker!: MatDatepicker<any>;
  @ViewChild('picker') startDatePicker1!: MatDatepicker<any>;
  @ViewChild('p') endDatePicker2!: MatDatepicker<any>;
  list_count = 0
  data: any[] = [];
  noData=false;
  loader=false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber','employeeName', 'leaveType', "startDate", 'endDate', 'withdrawDays', 'status', 'star',];
  // dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  empid: any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
  beforesearchpage: number;
  paginators: boolean = true;
  leaveReport:FormGroup;
  employeeNotFound: boolean;
  unsubscribe$: any;
  all_null:boolean = false;
  employeeList: any[];
  submitted: boolean = false;
  startdate_required:boolean = false;
  enddate_required:boolean = false;
  isProcessing = false;
min: string;
  selectedemployeeId: any;
  employeeId: any;
  employee: string;
  employeeNumber: any;
  selectedemployee: any;
  max: string;
  show_EMsg:boolean = false;
  showTable:boolean=false;
  storeemployee: any;
  storeStartDate: any;
  storeEndtDate: any;
  startdate: any;
  enddate: any;
  dataS: any;
  orgId: any;
  organizationId: any;



  constructor(private leaveservice: LeaveService,  public datepipe:DatePipe, private fb: FormBuilder, private router: Router, public dialog: MatDialog) {
  }
  ngOnInit() {
    let x: any = JSON.parse(localStorage.getItem('enoteUserData'))
    let mail = x.emailId;
    this.empid = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;


    this.leaveReport = this.fb.group({
      startdate:[''],
      enddate:[''],
      employeeId:[''],
    });

    this.orgId=x.organization.organizationId;

    
  //   this.leaveservice.getAll_withdrawList(this.empid).subscribe((d: any) => {

  //     if(d.length==0){
  //       console.log("error");
  //       this.noData=true;
  //     }

  //     d.forEach(e => {
  //       if (e.leaveWithdraw.withdrawStatus == 3) {
  //         e.leaveWithdraw.withdrawStatus = "Pending"
  //       }
  //       if (e.leaveWithdraw.withdrawStatus == 4) {
  //         e.leaveWithdraw.withdrawStatus = "Deleted"
  //       }
  //       if (e.leaveWithdraw.withdrawStatus == 5) {
  //         e.leaveWithdraw.withdrawStatus = "Approved"
  //       }
  //       if (e.leaveWithdraw.withdrawStatus == 6) {
  //         e.leaveWithdraw.withdrawStatus = "Denied"
  //       }
  //       if (e.leaveWithdraw.withdrawStatus == 7) {
  //         e.leaveWithdraw.withdrawStatus = "Withdrawn"
  //       }
  //       if (e.leaveWithdraw.withdrawStatus == 8) {
  //         e.leaveWithdraw.withdrawStatus = "Cancelled"
  //       }
  //     })
  //     this.data = d;
  //     this.dataSource = new MatTableDataSource<any>(this.data);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.filterPredicate = (data, filter) => {
  //       const dataStr = data.employee.firstName + data.leaveType.leaveName
  //       + data.leaveWithdraw.startDate + data.leaveWithdraw.endDate
  //       + data.leaveWithdraw.withdrawDays + data.leaveWithdraw.withdrawStatus;
  //       return dataStr.trim().toLowerCase().indexOf(filter) != -1;
  //     }
  //     this.list_count = this.data.length;
  //     console.log("List Count:"+this.list_count);
  //     this.loader = false;  // Stop the loader once data is loaded

  //   },
  //   (error) =>
  //    {
  //     this.noData= true;
  //     console.log("error");
  //     if(error.statusCode==204)
  //      {
  //        this.noData=true;
  //        console.log("204");
  //      }
  //      this.loader = false;  // Stop the loader if an error occurs

  //    }
  //  )
  }
  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  withDrawStatus(i) {
    if (i.leaveWithdraw == null) {
      return
    }
    if (i.leaveWithdraw.withdrawStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveWithdraw.withdrawStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.leaveWithdraw.withdrawStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.leaveWithdraw.withdrawStatusName == 'Withdrawn') {
      return '#20B2AA'
    }    
    if (i.leaveWithdraw.withdrawStatusName == 'Cancelled') {
      return '#e40000'
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.noData = true;
    }
    else{
      this.noData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Reason(i) {
    this.dialog.open(Reason, {
      width: '300px',
      data: i.leaveWithdraw.withdrawReason
    });
  }
  show_leave(i) {
    this.dialog.open(ShowLeave, {
      data: i
    });
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }

  keyFunc(event: Event, event1: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
    let inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace

    if (event1.key === ' ') {
        // Handle the space key press
        console.log('Space key pressed');
        // Optionally, prevent the default behavior
        event1.preventDefault();
    } else {
        // Clear the employee list if the input is empty
        if (inputValue === '') {
            this.employeeList = [];
            this.employeeNotFound = false;
            this.all_null=false;


            console.log('Input is empty, employee list cleared');
            return;
        }

        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
        this.unsubscribe$ = new Subject<void>();

        this.leaveservice.getEmployeeListKeywords(this.organizationId, inputValue).pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
                return EMPTY; // Return EMPTY to propagate completion downstream
            })
        ).subscribe(
            (resultData: any) => {
                if (resultData.statusCode === 204) {
                    console.log("No employees found (status code 204)");
                    this.employeeList = [];
                    this.employeeNotFound = true;
                } else {
                    if (Array.isArray(resultData)) {
                        this.employeeList = resultData;
                    } else if (typeof resultData === 'object') {
                        this.employeeList = Object.values(resultData);
                    } else {
                        console.error('Unexpected data type:', resultData);
                    }
                    this.employeeNotFound = false;

                }
            },
            (err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
            }
        );
    }
}
validateInputs(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value;

  // Remove leading spaces
  if (value.startsWith(' ')) {
    value = value.trimStart();
  }

  // Remove spaces in between
  value = value.replace(/\s+/g, '');

  input.value = value;
}

handleBackspace(event): void {
  if (
    event.key === 'Backspace' &&
    !this.selectedemployee &&
    (this.selectedemployeeId === undefined || this.selectedemployeeId.trim().length === 0)
  ) {
    this.selectedemployeeId = undefined;
    this.employeeId = undefined;
    this.employeeList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
  }
}

preventSpaceBeforeInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
      event.preventDefault();
  }
}

empSelect(emp){
  console.log(emp);

  this.employeeId=emp.employeeId;
  this.employee=emp.employeeNumber+"-"+emp.firstName;
  this.employeeNumber = emp.employeeNumber;
    this.leaveReport.patchValue({ 
      employeeId: this.employee 
    });
}

checkStartDate(): void {
  const startDate = this.leaveReport.get('startdate').value;
  const endDate = this.leaveReport.get('enddate').value;
  let startDate1 = this.datepipe.transform(startDate, 'YYYY-MM-dd');
  let endDate1 = this.datepipe.transform(endDate, 'YYYY-MM-dd');
  if (startDate && endDate && startDate1 > endDate1) {
    alert('Start date cannot be greater than end date.');

    this.leaveReport.patchValue({
      startdate: null,
      enddate: null,
    });

    this.startDatePicker.close();
    this.endDatePicker.close();
  }


}

startDate(event){
  this.startdate_required=false;
  console.log("start Date:"+event.value);
  this.show_EMsg = false;
  this.min = this.datepipe.transform(event.value, 'yyyy-MM-dd');
  if(this.max != null){
    if(this.max < this.min){
      this.show_EMsg = true;
    }
  }
}
EndDate(event){
  this.enddate_required=false;
  this.show_EMsg = false;
  console.log("start Date:"+event.value);
  this.max = this.datepipe.transform(event.value, 'yyyy-MM-dd');
}

search(){
  this.storeemployee=this.leaveReport.controls['employeeId'].value
  this.storeStartDate=this.leaveReport.controls['startdate'].value
  this.storeEndtDate=this.leaveReport.controls['enddate'].value
  console.log("start",this.storeStartDate)
  console.log("employee",this.storeemployee)
console.log("end",this.storeEndtDate)

if((this.storeemployee ==undefined || this.storeemployee =='') &&(this.storeEndtDate ==undefined || this.storeEndtDate =='')  &&(this.storeStartDate ==undefined || this.storeStartDate =='')  ){
alert("Please Choose Any Field To Search")
return;
}
this.changefirstpage();

  this.submitted = true;
  console.log(this.leaveReport.value);
  if(this.leaveReport.invalid){
    return;
  }
  this.startdate = this.datepipe.transform(this.leaveReport.value.startdate,'yyyy-MM-dd');
  console.log(this.startdate);
  this.enddate = this.datepipe.transform(this.leaveReport.value.enddate,'yyyy-MM-dd');
  console.log(this.enddate);
  console.log(this.employeeId);

  // if(this.startdate == null && this.enddate == null){
  //   if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
  //     this.all_null=true;
  //     setTimeout(() => {
  //       this.all_null = false;
  //     }, 10000)
  //     return
  //   }
  // }
  // if (!this.employeeId) {
  //   this.leaveReport.patchValue({ 
  //      employeeId:undefined
  //   });    
  // }
  
  console.log(this.leaveReport.value.employeeId)
  
  if (!this.employee && (this.leaveReport.value.employeeId !== undefined && this.leaveReport.value.employeeId !== null)) {
    if (this.leaveReport.value.employeeId.length !== 0) {
        this.all_null = true;
        return;
    } else {
        this.all_null = false;
    }
}

  // if(this.startdate!=null && this.enddate == null){
  //   this.enddate_required = true;
  //   return
  // }
  // else if(this.enddate !=null && this.startdate == null){
  //   this.startdate_required = true;
  //   return
  // }
  // else{
  //   this.enddate_required=false;
  //   this.startdate_required=false;
  // }
  if (this.startdate != undefined && this.enddate == undefined) {
    this.enddate_required = true;
    return;
  }
  if (this.enddate != undefined && this.startdate == undefined) {
    this.startdate_required = true;
    return;
  }

  if (this.enddate != undefined) {
    this.enddate_required = false;
  }
  this.loader=true;
  this.isProcessing = true; // Enable buttons
    this.data=[];
  if(this.startdate != null && this.enddate != null){
    console.log(this.employeeId=='');
    if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
      this.leaveservice.withdrawreportfilter(this.startdate,this.enddate,this.orgId).subscribe(
        (d:any) => {
          console.log(d);
          this.loader=false;
          this.showTable=true;

          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            // Function to format date strings
            const formatDate = (dateStr) => {
              if (!dateStr) return '';
              const date = new Date(dateStr);
              if (isNaN(date.getTime())) return dateStr; 
              return date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
            };
          
            const formattedStartDate = formatDate(data.startDate);
            const formattedEndDate = formatDate(data.endDate);
          
            const dataStr = (
              data.employee.firstName + ' ' +
              data.employee.lastName + ' ' +
              data.employee.organizationEmail + ' ' +
              data.employee.employeeNumber + ' ' +
              data.employee.mobileNumber + ' ' +
              formattedStartDate + ' ' +
              formattedEndDate + ' ' +
              data.noOfLeaveDays + ' ' +
              data.leaveStatusName
            ).toLowerCase().trim();
          
            
            const filterStr = filter.toLowerCase().trim();
          
            const normalizedFilterStr = filterStr.replace(/\s+/g, ' ');
          
            return dataStr.indexOf(normalizedFilterStr) !== -1;
          };
          
          
          this.list_count = this.data.length;
          this.isProcessing=false;

        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
          this.isProcessing=false;

        }
        )
    }
    else{
      this.leaveReport.patchValue({ 
        employeeId: this.employee 
      });
      this.employeeNotFound = false;
      this.all_null=false;


      this.leaveservice.withdrawreportBystartdateenddateempname(this.startdate,this.enddate,this.employeeId).subscribe(
        (d:any) => {
          console.log(d);
          this.loader=false;
          this.showTable=true;

          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const formatDate = (dateStr) => {
              if (!dateStr) return '';
              const date = new Date(dateStr);
              if (isNaN(date.getTime())) return dateStr; 
              return date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
            };
          
            const formattedStartDate = formatDate(data.startDate);
            const formattedEndDate = formatDate(data.endDate);
          
            const dataStr = (
              data.employee.firstName + ' ' +
              data.employee.lastName + ' ' +
              data.employee.organizationEmail + ' ' +
              data.employee.employeeNumber + ' ' +
              data.employee.mobileNumber + ' ' +
              formattedStartDate + ' ' +
              formattedEndDate + ' ' +
              data.noOfLeaveDays + ' ' +
              data.leaveStatusName
            ).toLowerCase().trim();
          
            const filterStr = filter.toLowerCase().trim();
          
            const normalizedFilterStr = filterStr.replace(/\s+/g, ' ');
          
            return dataStr.indexOf(normalizedFilterStr) !== -1;
          };
          
          
          this.list_count = this.data.length;
          this.isProcessing=false;

        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
          this.isProcessing=false;

        }
        )
    }
  }
  else {
    this.leaveReport.patchValue({ 
      employeeId: this.employee 
    });
    this.employeeNotFound = false;
    this.all_null=false;


    this.leaveservice.withdrawreportByempname(this.employeeId).subscribe(
      (d:any) => {
        console.log(d);
        this.loader=false;
        this.showTable=true;

        this.noData =false;
        if(d.length==0)
        {
          this.noData = true;
          console.log("NoData");
        }
        if(d.statusCode == 204){
          this.noData = true;
          console.log("NoData");
        }
        this.data = d
        this.dataS = new MatTableDataSource<any>(d);
        this.dataS.paginator = this.paginator;
        this.dataS.filterPredicate = (data, filter) => {
          const formatDate = (dateStr) => {
            if (!dateStr) return '';
            const date = new Date(dateStr);
            if (isNaN(date.getTime())) return dateStr; 
            return date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
          };
        
          const formattedStartDate = formatDate(data.startDate);
          const formattedEndDate = formatDate(data.endDate);
        
          const dataStr = (
            data.employee.firstName + ' ' +
            data.employee.lastName + ' ' +
            data.employee.organizationEmail + ' ' +
            data.employee.employeeNumber + ' ' +
            data.employee.mobileNumber + ' ' +
            formattedStartDate + ' ' +
            formattedEndDate + ' ' +
            data.noOfLeaveDays + ' ' +
            data.leaveStatusName
          ).toLowerCase().trim();
        
          const filterStr = filter.toLowerCase().trim();
        
          const normalizedFilterStr = filterStr.replace(/\s+/g, ' ');
        
          return dataStr.indexOf(normalizedFilterStr) !== -1;
        };
        
        
        this.list_count = this.data.length;
        this.isProcessing=false;

      },
      (error) =>
      {
        this.loader=false;
        this.noData= true;
        console.log("error");
        if(error.statusCode==204)
        {
           this.noData=true;
           console.log("204");
        }
        this.isProcessing=false;

      }
      )
  }

}
Clear(){
  this.isProcessing=true;
  this.showTable=false;

  this.employeeList = [];
  this.employeeNotFound = false;
  this.noData =false;
  this.enddate_required = false;
  this.show_EMsg = false;
  this.startdate_required = false;
  this.all_null = false;
  this.leaveReport.reset();
  this.submitted = false;
  this.employeeId = null;
  this.employeeNumber = null;
  this.min = null;
  this.max = null;
//   this.data = [];
// this.dataS = new MatTableDataSource<any>([]);
// this.dataS.paginator = this.paginator;
this.list_count = 0;
this.isProcessing = false;
this.employee= undefined;
this.leaveReport.patchValue({ 
  employeeId: undefined
});
  // this.loader=true;
  // this.leaveservice.getLeaveListByOrg(this.orgId).subscribe(
  //   (d: any) => {
  //     this.loader=false;
  //     if(d.length==0)
  //     {
  //       this.noData =true;
  //       console.log("NoData");
  //     }
  //     this.data = d;
  //     this.dataS = new MatTableDataSource<any>(d);
  //     console.log(this.dataS);
  //     this.dataS.paginator = this.paginator;
  //     this.dataS.filterPredicate = (data, filter) => {
  //       const dataStr = data.employee.employeeName + data.employee.organizationEmail
  //       + data.employee.employeeNumber + data.employee.mobileNumber
  //       +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
  //       return dataStr.trim().toLowerCase().indexOf(filter) != -1;
  //     }
  //     this.list_count = this.data.length;
  //     this.isProcessing=false;

  //   },
  //   (error) =>
  //   {
  //     this.loader=false;
  //     this.noData= true;
  //     console.log("error");
  //     if(error.statusCode==204)
  //     {
  //        this.noData=true;
  //        console.log("204");
  //     }
  //     this.isProcessing=false;

  //   }
  //   )
}

noEmployeeFound() {
  this.selectedemployeeId = undefined;
  this.selectedemployeeId = undefined; // Set selectedemployeeId to null
  this.employeeId = undefined;
  this.employeeList.length = 0;
  this.employee = undefined;
  this.employeeNumber = undefined;
  this.selectedemployee = undefined;
  this.selectedemployeeId = undefined;
  this.employeeList = []; // Clear the employee list
  this.employeeNotFound = false; 
  this.all_null=false;

}
changefirstpage() {
  this.p = 1;
}
}
