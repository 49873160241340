<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">{{orgcomplianceid ? "Update" : "Create"}} Organization Compliance</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/organizationcomplaice-list" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="orgcomplianceForm" (ngSubmit)="onSubmit()">
                   
                    <div class="row">
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">PF Max Salary Amount<span class="star">*</span></label>
                            <input type="text" formControlName="PfMaxSalaryAmount" id="ratePerMonth"  placeholder="Enter PF max salary amount" 
                            (keypress)="onlyNumber($event)"
                            (paste)="blockPaste($event)"
                            (drop)="blockDrop($event)"
                            class="form control
                            select-placeholder
                            email-input" style="outline: none;border-radius: 5px;font-family: Mulish;">
                            <div class="valid-msg" *ngIf="(validate.PfMaxSalaryAmount.invalid && validate.PfMaxSalaryAmount.touched) || validate.PfMaxSalaryAmount.dirty || submitted">
                                <span *ngIf="validate.PfMaxSalaryAmount.errors?.required" class="text-danger">Please enter PF max salary amount</span>
                                <span *ngIf="validate.PfMaxSalaryAmount.errors?.pattern" class="text-danger">Please enter valid PF max salary amount</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">Employee PF <span class="star">*</span></label>
                            <input type="text" formControlName="EmployeePF" id="ratePerMonth"  placeholder="Enter Employee PF" 
                            (keypress)="onlyNumber($event)"
                            (paste)="blockPaste($event)"
                            (drop)="blockDrop($event)"
                            class="form control
                            select-placeholder
                            email-input"style="outline: none;border-radius: 5px;font-family: Mulish;">
                            <div class="valid-msg" *ngIf="(validate.EmployeePF .invalid && validate.EmployeePF.touched) || validate.EmployeePF.dirty || submitted">
                                <span *ngIf="validate.EmployeePF.errors?.required" class="text-danger">Please enter employee PF</span>
                                <span *ngIf="validate.EmployeePF.errors?.pattern" class="text-danger">Please enter valid employee PF</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">Employer PF <span class="star">*</span></label>
                            <input type="text" formControlName="EmployerPF" id="ratePerMonth" placeholder="Enter Employer PF " 
                            (keypress)="onlyNumber($event)"
                            (paste)="blockPaste($event)"
                            (drop)="blockDrop($event)"
                            class="form control
                            select-placeholder
                            email-input"style="outline: none;border-radius: 5px;font-family: Mulish;">
                            <div class="valid-msg" *ngIf="(validate.EmployerPF .invalid && validate.EmployerPF.touched) || validate.EmployerPF.dirty || submitted">
                                <span *ngIf="validate.EmployerPF.errors?.required" class="text-danger">Please enter employer PF</span>
                                <span *ngIf="validate.EmployerPF.errors?.pattern" class="text-danger">Please enter valid employer PF</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">ESI Max Salary Amount<span class="star">*</span></label>
                            <input type="text" formControlName="ESIMaxSalaryAmount" id="ratePerMonth"  placeholder="Enter ESI max salary amount" 
                            (keypress)="onlyNumber($event)"
                            (paste)="blockPaste($event)"
                            (drop)="blockDrop($event)"
                            class="form control
                            select-placeholder
                            email-input"style="outline: none;border-radius: 5px;font-family: Mulish;">
                            <div class="valid-msg" *ngIf="(validate.ESIMaxSalaryAmount.invalid && validate.ESIMaxSalaryAmount.touched) || validate.ESIMaxSalaryAmount.dirty || submitted">
                                <span *ngIf="validate.ESIMaxSalaryAmount.errors?.required" class="text-danger">Please enter ESI max salary amount</span>
                                <span *ngIf="validate.ESIMaxSalaryAmount.errors?.pattern" class="text-danger">Please enter valid ESI max salary amount</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">Employee ESI <span class="star">*</span></label>
                            <input type="text" formControlName="EmployeeESI" id="ratePerMonth"  placeholder="Enter Employee ESI" 
                            (keypress)="onlyNumber($event)"
                            (paste)="blockPaste($event)"
                            (drop)="blockDrop($event)"
                            class="form control
                            select-placeholder
                            email-input"style="outline: none;border-radius: 5px;font-family: Mulish;">
                            <div class="valid-msg" *ngIf="(validate.EmployeeESI.invalid && validate.EmployeeESI.touched) || validate.EmployeeESI.dirty || submitted">
                                <span *ngIf="validate.EmployeeESI.errors?.required" class="text-danger">Please enter employee ESI</span>
                                <span *ngIf="validate.EmployeeESI.errors?.pattern" class="text-danger">Please enter valid employee ESI</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">Employer ESI <span class="star">*</span></label>
                            <input type="text" formControlName="EmployerESI" id="ratePerMonth" placeholder="Enter Employer ESI " 
                            (keypress)="onlyNumber($event)"
                            (paste)="blockPaste($event)"
                            (drop)="blockDrop($event)"
                            class="form control
                            select-placeholder
                            email-input"style="outline: none;border-radius: 5px;font-family: Mulish;">
                            <div class="valid-msg" *ngIf="(validate.EmployerESI .invalid && validate.EmployerESI.touched) || validate.EmployerESI.dirty || submitted">
                                <span *ngIf="validate.EmployerESI.errors?.required" class="text-danger">Please enter employer ESI</span>
                                <span *ngIf="validate.EmployerESI.errors?.pattern" class="text-danger">Please enter valid employer ESI</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">Employer ESI Deduction<span class="star">*</span></label>
                            <mat-select placeholder="-- Select Employer ESI Deduct -- " formControlName="EmployerESIDeduct" class="form control
                            select-placeholder
                            email-input"style="outline: none;border-radius: 5px;font-family: Mulish;" >
                                <mat-option [value]=null> -- Select EmployerESI Deduct -- </mat-option>
                                <mat-option [value]=true >Include in CTC</mat-option> 
                                <mat-option [value]=false >Exclude in CTC</mat-option> 
                            </mat-select>
                            <div class="valid-msg" *ngIf="(validate.EmployerESIDeduct .invalid && validate.EmployerESIDeduct.touched) || validate.EmployerESIDeduct.dirty || submitted">
                                <span *ngIf="validate.EmployerESIDeduct.errors?.required" class="text-danger">Please enter employer ESI</span>
                                <!-- <span *ngIf="validate.EmployerESI.errors?.pattern" class="text-danger">Please enter valid employer ESI</span> -->
                            </div>
                        </div>
                     
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3" id="loginform">
                            <label class="form-control-label">Employee ESI Deduction<span class="star">*</span></label>
                            <mat-select placeholder="-- Select Employee ESI Deduct --" formControlName="EmployeeESIDeduct" class="form control
                            select-placeholder
                            email-input" style="outline: none;border-radius: 5px;font-family: Mulish;">
                                <mat-option [value]=null> -- Select Employee ESI Deduct -- </mat-option>
                                <mat-option  [value]=true >Include in CTC</mat-option> 
                                <mat-option  [value]=false >Exclude in CTC</mat-option> 
                            </mat-select>
                            <div class="valid-msg" *ngIf="(validate.EmployeeESIDeduct .invalid && validate.EmployeeESIDeduct.touched) || validate.EmployeeESIDeduct.dirty || submitted">
                                <span *ngIf="validate.EmployeeESIDeduct.errors?.required" class="text-danger">Please enter employer ESI</span>
                                <!-- <span *ngIf="validate.EmployerESI.errors?.pattern" class="text-danger">Please enter valid employer ESI</span> -->
                            </div>
                        </div>
                        <div class="col-md-9" style="float: right;">                            
                            <!-- <button type="submit" mat-raised-button color="primary">Save</button> -->
                            <button type="submit" mat-raised-button  style="margin-top: 24px;"
                            class=" searchBtn " [disabled]="isprocessing1 ||orgcomplianceForm.invalid">Save
                            </button>
                        </div>
                    </div>
                 
                </form>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <!-- <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records </button>-->
   
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>