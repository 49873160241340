import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShiftemployeeComponent } from '../shift/shiftemployee/shiftemployee.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-viewemployeegroupemployees',
  templateUrl: './viewemployeegroupemployees.component.html',
  styleUrls: ['./viewemployeegroupemployees.component.scss'],
})
export class ViewemployeegroupemployeesComponent implements OnInit {
  p: number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch: any;
  pagePerItem: any = 10;
  dateFormat: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public resultData: any,
    private dialogref: MatDialogRef<ShiftemployeeComponent>
  ) {}

  ngOnInit(): void {
    // this.dateFormat = this.AppData.dateFormat;
  }

  closedialog() {
    this.dialogref.close();
  }
}
