<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-5">
            <div class="row">

                <div class="sub-header-buttons">
                    <a routerLink="/home/mybusinesstravel-list" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<nav class="navbar  bg" style="margin-top: 67px;">
    <a class="navbar-brand navDesign" style="font-size: 22px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;">Apply Business Travel</a>


</nav>
<div class="common-content ">
    <div class="card-new colDesign">
        <div class="">
            <div class="card-body">
                <div class="col-sm-12">
                    <mat-card>

                        <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center"
                            style="">
                            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;top:50%;left:48%" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <!-- <ng-container *ngIf="isLoading; else elseLoading">
                            <div class="loading">
                                <h4 class="text-muted">loading....</h4>
                                <br>
                                <div class="spinner-grow text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-success" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-danger" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-info" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-dark" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </ng-container> -->

                        <!-- <ng-template #elseLoading> -->
                        <div>
                            <form action="" [formGroup]="businessGroup" (ngSubmit)="applyWFH()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- <h3 class="title">*Enter details to apply Business Travel*</h3> -->
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4 ">
                                        <!--  start date -->
                                        <div class="form-group">
                                            <label class="form-control-label">From Date<span class="star"> *</span></label>
                                            <mat-datepicker-toggle matSuffix [for]="picker"
                                                style="float: right;position: relative;top: 33px;"></mat-datepicker-toggle>
                                            <input (click)="picker.open()" [matDatepickerFilter]="myFilter"
                                                (dateChange)="someMethodName($event)" formControlName="startDate"
                                                [matDatepicker]="picker" placeholder="Select From Date"
                                                (dateInput)="nofoDays($event)" readonly
                                             

                                                  class="form control
                                                     select-placeholder
                                                  email-input " style="outline:none;border-radius: 5px;margin-top:-3%">
                                            <mat-datepicker  #picker></mat-datepicker>
                                            <!-- <mat-error *ngIf=" f.startDate.hasError('required') && (f.startDate.touched || f.startDate.dirty)">
                                                        Please select <strong>From Date</strong>
                                                    </mat-error> -->
                                            <div *ngIf="(f.startDate.invalid && submitted) || f.startDate.dirty">
                                                <mat-error *ngIf="f.startDate.errors?.required">Please select From
                                                    Date</mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <!-- endDate -->
                                        <div class="form-group">
                                            <label class="form-control-label">To Date<span class="star"> *</span></label>
                                            <mat-datepicker-toggle matSuffix [for]="a"
                                                style="float: right;position: relative;top: 33px;"></mat-datepicker-toggle>
                                            <input (click)="a.open()" [matDatepickerFilter]="myFilter"
                                                (dateInput)="nofoDays($event)" [min]="min" formControlName="endDate"
                                                [matDatepicker]="a"
                                                

                                                 class="form control
                                                  select-placeholder
                                                email-input " style="outline:none;border-radius: 5px;margin-top:-3%"
                                                placeholder="Select To Date" [disabled]="endDate_disable">
                                            <mat-datepicker  #a></mat-datepicker>
                                            <div *ngIf="(f.endDate.invalid && submitted) || f.endDate.dirty">
                                                <mat-error *ngIf="f.endDate.errors?.required">Please select To
                                                    Date</mat-error>
                                            </div>
                                            <mat-error *ngIf="show_error_msg">
                                                To date should be greater than from date.
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- nofoDays -->
                                        <div class="form-group">
                                        <mat-label class="form-label"></mat-label>
                                        <label class="form-control-label" style="font-family: Mulish;    margin-bottom: 10px;">No.of Days<span
                                                class="star"> *</span></label>
                                        <input type="text" [value]="noOFDays" readonly
                                          

                                            class="form control
                                            select-placeholder
                                               email-input " style="outline:none;border-radius: 5px;font-family: Mulish;" >
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- reason -->
                                        <div class="form-group">
                                            <label class="form-control-label">Reason<span class="star"> *</span></label>
                                            <textarea type="text" formControlName="reason"
                                                placeholder="Enter reason!!!!"
                                             

                                               class="form control
                                               textarea
                                                email-input " style="outline:none;border-radius: 5px;"
                                                (input)="validateInput($event)"></textarea>
                                            <div *ngIf="(f.reason.invalid && submitted) || f.reason.dirty">
                                                <mat-error *ngIf="f.reason.errors?.required">Please enter
                                                    reason</mat-error>
                                                    <mat-error *ngIf="(f.reason.errors?.pattern )">
                                                        Please enter a valid reason
                                                    </mat-error> 
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="business_controls">
                                    <div formArrayName="businessTravelDetail" class="location-part mt-1 mb-4"
                                        *ngFor="let item of travelControl.controls; let i = index">
                                        <div [formGroupName]="i">
                                            <div style="transform: translateY(-14px);">
                                                <div class="float-right">
                                                    <mat-icon class="material-icons-outlined" type="button"
                                                        *ngIf="i > 0" mat-raised-button color="primary"
                                                        matBadgePosition="before" matBadgeColor="accent"
                                                        (click)="remove_location(i)"
                                                        class="location-delete form control select-placeholder email-input " style="outline:none;border-radius: 5px;">delete_outline
                                                    </mat-icon>
                                                </div>
                                                <button type="button" mat-raised-button color="primary"
                                                    matBadgePosition="before" class="location-title"
                                                    matBadgeColor="accent">
                                                    Location - {{i + 1}}
                                                </button>
                                            </div>
                                            <!-- country -->
                                            <label class="form-control-label" style="font-family: Mulish;">Country<span
                                                    class="star">*</span></label>
                                            <mat-select placeholder="Select Country" class="example-full-width" formControlName="country"
                                            class="form control select-placeholder email-input " style="background-image: none; outline:none;border-radius: 5px;">
                                                <mat-option [value]="null"> --- Select Country --- </mat-option>
                                                <mat-option *ngFor="let c of countryList" value={{c.name}}>{{c.name}}
                                                </mat-option>
                                            </mat-select>
                                            <!-- <mat-error
                                                        *ngIf="travelControl.controls[i].get('country').errors?.required && (travelControl.controls[i].get('country').touched || travelControl.controls[i].get('country').dirty)">
                                                        Please select <strong>Country</strong>
                                                    </mat-error> -->
                                            <div style="font-family: Mulish;"
                                                *ngIf="(travelControl.controls[i].get('country').invalid && submitted) || travelControl.controls[i].get('country').dirty">
                                                <mat-error
                                                    *ngIf="travelControl.controls[i].get('country').errors?.required">
                                                    Please select Country</mat-error>
                                            </div>

                                            <div class="row">
                                                <!-- from location -->
                                                <div class="col-md-6">
                                                    <label class="form-control-label mt-3" style="font-family: Mulish;">From
                                                        Location<span class="star">
                                                            *</span></label>
                                                    <input type="text" formControlName="fromLocation"
                                                        placeholder="Enter From Location"
                                                        (input)="checkLocations(i)"
                                                        class="form control select-placeholder email-input " style="outline:none;border-radius: 5px; font-family: Mulish;"
                                                        (keypress)="omit_special_char($event) && keyPress($event)"  (input)="validateInput($event)">
                                                    <!-- <mat-error
                                                                *ngIf="travelControl.controls[i].get('fromLocation').errors?.required && (travelControl.controls[i].get('fromLocation').touched || travelControl.controls[i].get('fromLocation').dirty)">
                                                                Please Enter <strong>From Location</strong>
                                                            </mat-error> -->
                                                    <div style="font-family: Mulish;"
                                                        *ngIf="(travelControl.controls[i].get('fromLocation').invalid && submitted) || travelControl.controls[i].get('fromLocation').dirty">
                                                        <mat-error
                                                            *ngIf="travelControl.controls[i].get('fromLocation').errors?.required">
                                                            Please Enter From Location</mat-error>
                                                            <mat-error *ngIf="travelControl.controls[i].get('fromLocation').errors?.pattern ">
                                                                Please enter a valid From Location
                                                            </mat-error> 
                                                    </div>
                                                </div>
                                                <!-- to location -->
                                                <div class="col-md-6" style="font-family: Mulish;">
                                                    <label class="form-control-label mt-3">To Location <span class="star">
                                                            *</span></label>
                                                    <input type="text" formControlName="toLocation"
                                                        placeholder="Enter To Location"
                                                        (input)="checkLocations(i)"
                                                        class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;"
                                                        (keypress)="omit_special_char($event) && keyPress($event)" (input)="validateInput($event)">
                                                    <!-- <mat-error
                                                                *ngIf="travelControl.controls[i].get('toLocation').errors?.required && (travelControl.controls[i].get('toLocation').touched || travelControl.controls[i].get('toLocation').dirty)">
                                                                Please Enter <strong>To Location</strong>
                                                            </mat-error> -->
                                                    <div
                                                        *ngIf="(travelControl.controls[i].get('toLocation').invalid && submitted) || travelControl.controls[i].get('toLocation').dirty">
                                                        <mat-error
                                                            *ngIf="travelControl.controls[i].get('toLocation').errors?.required">
                                                            Please Enter To Location</mat-error>
                                                            <mat-error *ngIf="travelControl.controls[i].get('toLocation').errors?.pattern ">
                                                                Please enter a valid To Location
                                                            </mat-error> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="form-control-label mt-3">Preferred Time <span class="star">
                                                            *</span></label>
                                                    <input type="time" step="2" formControlName="preferredTime"
                                                    class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;">
                                                        
                                                    <!-- <mat-error
                                                                *ngIf="travelControl.controls[i].get('preferredTime').errors?.required && (travelControl.controls[i].get('preferredTime').touched || travelControl.controls[i].get('preferredTime').dirty)">
                                                                Please Select <strong>Preferred Time</strong>
                                                            </mat-error> -->
                                                    <div
                                                        *ngIf="(travelControl.controls[i].get('preferredTime').invalid && submitted) || travelControl.controls[i].get('preferredTime').dirty">
                                                        <mat-error
                                                            *ngIf="travelControl.controls[i].get('preferredTime').errors?.required">
                                                            Please Select Preferred Time</mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <!-- travel mode -->
                                                    <div formGroupName="travelMode">
                                                        <label class="form-control-label mt-3">Travel Mode <span class="star">
                                                                *</span></label>
                                                        <mat-select placeholder="Select Travel Mode" class="example-full-width"
                                                            formControlName="travelModeId"
                                                            class="form control select-placeholder email-input "  style="background-image: none;outline:none;border-radius: 5px;font-family: Mulish;">
                                                            <mat-option [value]="null"> --- Select Travel Mode --- </mat-option>
                                                            <mat-option *ngFor="let t of traveModeList"
                                                                [value]="t.travelModeId">{{t.travelModeName}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <!-- <mat-error
                                                                    *ngIf="travelControl.controls[i].get('travelMode').get('travelModeId').errors?.required && (travelControl.controls[i].get('travelMode').touched || travelControl.controls[i].get('travelMode').dirty)">
                                                                    Please Select <strong>Travel Mode</strong>
                                                                </mat-error> -->
                                                        <div
                                                            *ngIf="(travelControl.controls[i].get('travelMode').invalid && submitted) ||  travelControl.controls[i].get('travelMode').dirty">
                                                            <mat-error
                                                                *ngIf="travelControl.controls[i].get('travelMode').get('travelModeId').errors?.required">
                                                                Please Select Travel Mode</mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- buttons -->
                                <div class="form-group">
                                    <div class="row media-query">
                                        <section class="section-1">
                                            <button type="button" mat-stroked-button target="_blank"
                                                (click)="addLocation()" class="add-location">
                                                <span class="material-icons-outlined" style="font-family: Mulish;"
                                                    style="font-size: 15px;line-height: 1.6;">location_on</span>&nbsp;Add
                                                Location
                                            </button>
                                        </section>
                                        <div class="section-2" align="center">
                                            <!-- <button mat-stroked-button class="cancel-button" style="font-family: Mulish;"
                                                    color="accent" type="button" (click)="Cancel()">
                                                    Cancel
                                                </button> -->
                                            <button type="reset" class="" mat-raised-button (click)="Cancel()" style="
                                                      float: right; 
    
                                                            background: none;
   
                                                         border: 1px solid #ddd;
                                                           font-size: 14px;
                                           font-weight: 300;
                                                        height: 40px !important;
                                                    " style="color:var(--Red-Color, #FF5D5D); height:35px " mat-dialog-close>Cancel</button>
                                        </div>
                                        <div class="section-3">
                                            <!-- <button mat-stroked-button class="apply-button" style="font-family: Mulish;"
                                                    color="primary" type="submit"[disabled]="hasErrors()">
                                                    &nbsp;Apply&nbsp;
                                                </button> -->
                                            <!-- <button type="submit" mat-raised-button
                                                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                                                [disabled]="!searchButton ||businessGroup.invalid">
                                                Apply
                                            </button> -->
                                            <button type="submit"
                                            class="searchBtn mr-2 custom-button"
                                            [disabled]="!searchButton ||businessGroup.invalid">
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- </ng-template> -->
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{success_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>