import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
// import { fe_url } from 'src/app/providers/properties';
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-share-feedback-form-link',
  templateUrl: './share-feedback-form-link.component.html',
  styleUrls: ['./share-feedback-form-link.component.scss']
})
export class ShareFeedbackFormLinkComponent implements OnInit {


  resourceId:any;
  onboardingId:any;
  feedbackId:any;
  link:any;
  isClicked = false;
  textValue = 'Hello World';
  tooltipText = 'Copy to clipboard';
  constructor(private clipboard: Clipboard, 
    private snackBar:MatSnackBar,
    private http: HttpClient) { }

  ngOnInit(): void {
    
    // this.link = this.http.get(`${api_url}home/resourcefeedback/${this.resourceId}`)
    console.log("this.feedbackId",this.feedbackId)
    if(this.feedbackId == undefined){
      this.link = `${environment.fe_url}share-feedback-form/${this.resourceId}`;
      console.log(this.link)
    }else{
      this.link = `${environment.fe_url}share-feedback-form/${this.resourceId}/${this.feedbackId}`;
      console.log(this.link)
    }
  
 
  
  }
  // copyText(textToCopy: string) {
  //   this.clipboard.copy(textToCopy);
  //   this.snackBar.open('Link copied!', 'Close', {
  //     duration: 2000, // Duration in milliseconds
  //   });
  // }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    
    const config: MatSnackBarConfig = {
      duration:1000, // Duration in milliseconds
      verticalPosition: 'top', // Position the snack bar at the top
      horizontalPosition: 'center', // Optionally, position it in the center horizontally
      panelClass: ['center-snackbar'] // Add a custom class
    };
  
    this.snackBar.open('Link copied!', 'Close', config);
  }
  

  resetTooltip() {
    this.tooltipText = 'Copy to clipboard';
  }

}
