<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class=""><span class="sub-content">Super Admin Settings</span></div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="settings-section">
                                    <div class="settings-list1">
                                        <span class="to-do-header">Locations</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/country" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/country.png" alt="">
                                        <span class="settings-title">Countries</span>
                                        <span class="settings-sub-title">Manage all countries.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/state" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/state.png" alt="">
                                        <span class="settings-title">States</span>
                                        <span class="settings-sub-title">Manage all states.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/district" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/city.png" alt="">
                                        <span class="settings-title">District</span>
                                        <span class="settings-sub-title">Manage all districts.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/city" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/94.png" alt="">
                                        <span class="settings-title">Cities</span>
                                        <span class="settings-sub-title">Manage all cities.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="settings-section">
                                    <div class="settings-list1">
                                        <span class="to-do-header">System Settings</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/list-types" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/10.png" alt="">
                                        <span class="settings-title">List of Values</span>
                                        <span class="settings-sub-title">Manage all list of values.</span>
                                    </div> 

                                    <div class="settings-list" routerLink="/super-admin-home/user-roles-list" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/10.png" alt="">
                                        <span class="settings-title">User Roles</span>
                                        <span class="settings-sub-title">Manage all user roles.</span>
                                    </div> 

                                    <div class="settings-list" routerLink="/super-admin-home/modules" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/11.png" alt="">
                                        <span class="settings-title">Modules</span>
                                        <span class="settings-sub-title">Manage all Application Modules.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/menus" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/91.png" alt="">
                                        <span class="settings-title">Menus</span>
                                        <span class="settings-sub-title">Manage all Application Modules menus.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/date-formats" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/94.png" alt="">
                                        <span class="settings-title">Date Formats</span>
                                        <span class="settings-sub-title">Manage all Date Formats.</span>
                                    </div>

                                    <div class="settings-list" routerLink="/super-admin-home/time-zones" routerLinkActive="active">
                                        <img class="settings-icon" src="assets/uploads/icons/92.png" alt="">
                                        <span class="settings-title">Time Zones</span>
                                        <span class="settings-sub-title">Manage all Time Zones.</span>
                                    </div>

                                    <!-- <div class="settings-list">
                                      <img class="settings-icon" src="assets/uploads/icons/91.png" alt="">
                                      <span class="settings-title">Positions</span>
                                      <span class="settings-sub-title">Manage all positions.</span>
                                  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>