import { Component, OnInit,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  } from '../../providers/properties';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { ExcelService} from '../../services/excel.service';
import { DatePipe, JsonPipe } from '@angular/common';
import {Sort} from '@angular/material/sort';
//import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { AppComponent } from '../../app.component';
import { HeaderService } from 'src/app/providers/header.service';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

@Component({
  selector: 'app-onboarding-list',
  templateUrl: './onboarding-list.component.html',
  styleUrls: ['./onboarding-list.component.scss']
})

export class OnboardingListComponent implements OnInit
{
  //dateFormat = GlobalConstants.dateFormat;
  dateFormat : any;
  
  onboardinglistdata:any[];
  resourceData:any[];
  p: number = 1;
  public searchFilter: any = '';
  searchForm!:FormGroup
  usertData: any = '';

  query: any = '';
  beforepage :any;
  mySelect:any =5;

  listTypeValueName:any;
  onboardingId:any;
  nodata :boolean= true;

  sucess_msg :any;
  error_msg :any;
  success = false;
  error:any=false;
  tableSearch : any;

  usertype: any;
  userType: any;

  loader:any= false;
  backdrop:any= false;
  nodatafound: boolean = true;
  resultData: any = {};

  clientListData:any = [];
  dataExport :any = [];
  exported : any;
  exportingdata:any;
  clientName:any;
  emailConfirmation:any;
  sortedData: any[];
  combinedClients: any[];
  clientName1: any;
search: any;
btnDisables: any;
tableShow: boolean=false;
setActiveNo: boolean=false;
setInactiveNo: boolean=false;
setBtnBoolean:boolean=true;
  isAdmin: boolean=false;
  isReqruiter: boolean=false;
  isAdminReqruiter: boolean=false;
  beforesearchpage: number;
  orgId: any;
  constructor(
    private router:Router,
    private onboardingservice:RecruitmentService,
    private formBuilder:FormBuilder,
    public ExcelService: ExcelService,
    private headerservice: HeaderService,
    private resourceservice:HrmsserviceService,
    private datepipe : DatePipe ,
    @Inject(AppComponent) public AppData: any,
    private positionservice :RecruitmentService,
  ) { }

  ngOnInit(): void
  { 
    // this.headerservice.setTitle('Onboardings ')
    this.headerservice.setTitle('');

    this.nodata = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));

    this.orgId=this.usertData.organization.organizationId

    console.log(" this.usertData::", this.usertData);

    this.userType = [];
    this.usertData.employee.role.forEach(role => {
        this.userType.push(role?.roleName);
    });
    this.isAdminReqruiter=this.userType.includes('Admin') || this.userType.includes('Recruiter');
    this.isAdmin = this.userType.includes('Admin') 
   this.isReqruiter= this.userType.includes('Recruiter');

    console.log(this.isAdmin);
    

    console.log(    this.userType);
    
    
    let userId = this.usertData.userId;
    let organizationId = this.usertData.organization.organizationId;
    this.positionservice.getclientlist(organizationId).subscribe((resultData:any) =>
    {console.log(' resultData', resultData)
         this.clientListData = resultData;
     });
    this.dateFormat = localStorage.getItem('dateFormatName');
    
    //this.onboardingList();
    this.getFilterFormData();
    this.getReource();

  }

  RecruiterSet(){

   if (this.usertData.role.roleName=='Recruiter'){

    this.setBtnBoolean=false;


   }
      
  }
  getReource()
  {
    this.onboardingservice.resource(this.orgId).subscribe(( result:any) =>
    {
      this.resourceData = result;
    })
  }


  getFilterFormData()
  {
    /*  this.searchForm = new FormGroup(
      {
        keywords : new FormControl(this.searchForm),
        resourceId : new FormControl(this.searchForm)
      }
    ); */

    this.searchForm = this.formBuilder.group({
      keywords: ['',Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)],
      resourceId: [''],
    });
  }

  searchSubmit()
  {
    this.p=1;
    this.search=''
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    let organizationId = this.usertData.organization.organizationId;
    this.nodata = false;
    let keywords = this.searchForm.value.keywords;
    let resourceId = this.searchForm.value.resourceId;

    console.log("keywords::",keywords);
    console.log("resource::",resourceId);

    if((keywords==null || keywords.length=='') && (resourceId==null || resourceId.length=='')){
      alert("Please Choose Any Element to Search!!")
      this.loader=false
      // this.tableShow=true;
      this.nodata=true

    }
    

    // if( !keywords  && (!resourceId || resourceId == "null")  )
    // {      
    //   this.btnDisables=true;
    //   this.loader=true;
    //   this.tableShow=false

    //   this.onboardingservice.onboardinglist(organizationId).subscribe((onboardinglistdata:any) =>
    //   {
    //     this.backdrop=false;

    //     this.resultData = onboardinglistdata;
    //     this.btnDisables=true;
    //     this.loader=false;
    //     this.tableShow=true
    //     console.log("  this.resultData::",  this.resultData);
        

    //     if(this.resultData.length == 0)
    //     {
    //       this.btnDisables=false;
    //       this.nodata = true;
    //       this.loader=false;
    //       this.tableShow=true      
    //       }
    //       this.btnDisables=false;

    //   }
      
    // )
    // }

    if(keywords && (resourceId && resourceId != "null" ))
    {
      this.btnDisables=true;
      this.loader=true;
      this.tableShow=false

      this.onboardingservice.getSearchOnboardingList(organizationId, keywords, resourceId).subscribe((resultData: any) =>
      {
        this.backdrop=false;
        this.nodata = false;

        this.resultData = resultData;
        this.btnDisables=true;
        this.loader=false;
        this.tableShow=true
        console.log("  this.resultData::",  this.resultData);


        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.btnDisables=false;
          this.nodata = true;
          this.loader=false;
          this.tableShow=true
                }
        console.log('this.resultData',this.resultData)
        this.combinedClients = this.getCombinedClients();
        for (var j = 0; j < this.combinedClients.length; j++) {
          this.clientName1 = this.combinedClients[j].clientName;
          console.log('this.clientName1',this.clientName1)
          for (var i = 0; i < this.resultData.length; i++) {
            console.log("i", i, "j", j);
          
            if(i==j){
              console.log("ij", i,j);
              this.resultData[i].clientName = this.clientName1;
            }
            
           }
           }
           this.btnDisables=false;

        console.log('combinedClients',this.combinedClients)
        console.log("this.positionlistdata2", this.resultData)
      })
    }
    else if(keywords && (!resourceId || resourceId == "null"))
    {
      this.loader=true;
      this.backdrop=true;
      this.btnDisables=true;
      this.tableShow=false


      this.onboardingservice.getOnboardingListKeywords(organizationId,keywords).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodata = false;

        this.resultData = resultData;
        this.tableShow=true;
        this.btnDisables=true;
        console.log("  this.resultData::",  this.resultData);


        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.btnDisables=false;
          this.nodata = true;
          this.loader=false;
          this.tableShow=true
                }
        this.combinedClients = this.getCombinedClients();
        for (var j = 0; j < this.combinedClients.length; j++) {
          this.clientName1 = this.combinedClients[j].clientName;
          console.log('this.clientName1',this.clientName1)
          for (var i = 0; i < this.resultData.length; i++) {
            console.log("i", i, "j", j);
          
            if(i==j){
              console.log("ij", i,j);
              this.resultData[i].clientName = this.clientName1;
            }
            
           }
           }
           this.btnDisables=false;

        console.log('combinedClients',this.combinedClients)
        console.log("this.positionlistdata2", this.resultData)
      })
    }
    else if(!keywords && (resourceId && resourceId != "null"))
    {
      this.loader=true;
      this.backdrop=true;
      this.tableShow=false
      this.btnDisables=true

      this.onboardingservice.getOnboardingListResource(organizationId, resourceId).subscribe((resultData: any) =>
      {
        this.backdrop=false;
        this.nodata = false;

        this.resultData = resultData;
        this.tableShow=true;
        this.btnDisables=true
        this.loader=false;

        console.log("  this.resultData::",  this.resultData);


        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.btnDisables=false;
              this.nodata = true;
              this.loader=false;
              this.tableShow=true
        }
        this.combinedClients = this.getCombinedClients();
        for (var j = 0; j < this.combinedClients.length; j++) {
          this.clientName1 = this.combinedClients[j].clientName;
          console.log('this.clientName1',this.clientName1)
          for (var i = 0; i < this.resultData.length; i++) {
            console.log("i", i, "j", j);
          
            if(i==j){
              console.log("ij", i,j);
              this.resultData[i].clientName = this.clientName1;
            }
            
           }
           }
           this.btnDisables=false;

        console.log('combinedClients',this.combinedClients)
        console.log("this.positionlistdata2", this.resultData)
      })
    }
  }

  // getCombinedClients() {
  //   console.log('this.resultData', this.resultData)
  //   return this.resultData?.map(item => {
  //     console.log('item.resource.position.client',item.resource.position.client);
  //     const client = this.clientListData.find(aItem => aItem.clientId === item.resource.position.client);
  //     return { clientName: client ? client.clientName : 'Unknown' };
  //   });
  // }

  getCombinedClients() {
  console.log('this.resultData', this.resultData);

  if (!Array.isArray(this.resultData)) {
    // console.error('this.resultData is not an array:', this.resultData);
    return [];
  }

  return this.resultData.map(item => {
    console.log('item.resource.position.client', item.resource.position.client);
    const client = this.clientListData.find(aItem => aItem.clientId === item.resource.position.client);
    return { clientName: client ? client.clientName : 'Unknown' };
  });
}

  onboardingList()
  {
    let keywords = this.searchForm.value.keywords;
    let resourceId = this.searchForm.value.resourceId;
    let organizationId = this.usertData.organization.organizationId;

    if(keywords && (resourceId && resourceId != "null" ))
    {
      this.loader=true;
      this.backdrop=true;
      this.tableShow=false

      this.onboardingservice.getSearchOnboardingList(organizationId, keywords, resourceId).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodata = false;

        this.resultData = resultData;
        this.tableShow=true

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodata = true;
          this.tableShow=true
        }
      })
    }
    else if(keywords && (!resourceId || resourceId == "null"))
    {
      this.loader=true;
      this.backdrop=true;
      this.tableShow=false

      this.onboardingservice.getOnboardingListKeywords(organizationId,keywords).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodata = false;

        this.resultData = resultData;
        this.tableShow=true


        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodata = true;
          this.tableShow=true

        }
      })
    }
    else if(!keywords && (resourceId && resourceId != "null"))
    {
      this.loader=true;
      this.backdrop=true;
      this.tableShow=false


      this.onboardingservice.getOnboardingListResource(organizationId, resourceId).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodata = false;

        this.resultData = resultData;
        this.tableShow=true


        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodata = true;
          this.tableShow=true

        }
      })
    }
    else
    {
      this.loader=true;
      this.backdrop=true;
      this.tableShow=false


      this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

      console.log("this.usertype::",this.usertype);

      
      

      let userId = this.usertype.userId;
      let organizationId = this.usertype.organization.organizationId;
      this.usertype = this.usertype.role.roleName;

      console.log(" this.usertype .role.rolename::",this.usertype);
      

      this.onboardingservice.onboardinglist(organizationId).subscribe((onboardinglistdata:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.tableShow=true


        this.onboardinglistdata = onboardinglistdata;

        if(this.onboardinglistdata.length == 0)
        {
          this.nodata = true;
          this.tableShow=true

        }
      })
    }
  }

  exportToExcel()
  {
    let keywords = this.searchForm.value.keywords;
    let resourceId = this.searchForm.value.resourceId;

    if(keywords && resourceId)
    {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData ;

      for(let exportingdata of this.exportingdata)
      {
        if(exportingdata.client !=undefined)
        {
          this.clientName = exportingdata.client.clientName;
        }
        else if(exportingdata.client == undefined)
        {
          this.clientName = "In-House";
        }

        if(exportingdata.emailConfirmation == "true")
        {
          this.emailConfirmation = "Yes";
        }
        else if(exportingdata.emailConfirmation == "false")
        {
          this.emailConfirmation = "No";
        }

        let offerReleasedDate = this.datepipe.transform(exportingdata.offerReleasedDate, 'dd-MMM-yyyy');
        let offerAcceptedDate = this.datepipe.transform(exportingdata.offerAcceptedDate, 'dd-MMM-yyyy');

        this.exported ={
          ResourceName           : exportingdata.resource.resourceName,
          HiringFor              : exportingdata.hiring.listTypeValueName,
          ApprovedCTC            : exportingdata.approvedCtc,
          WorkLocation           : exportingdata.workLocation,

          ClientName             : this.clientName,
          OfferReleasedDate      : offerReleasedDate,
          OfferAcceptedDate      : offerAcceptedDate,
          WaitingPeriod          : exportingdata.waitingPeriod,
          ExpectedDateOfJoining  : exportingdata.workLocation,
          EmailConfirmation      : this.emailConfirmation,
        }

        this.dataExport.push(this.exported);
      }
      this.ExcelService.exportAsExcelFile(this.dataExport,"Export");
    }
    else
    {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData ;
      for(let exportingdata of this.exportingdata)
      {
        if(exportingdata.client !=undefined)
        {
          this.clientName = exportingdata.client.clientName;
        }
        else if(exportingdata.client == undefined)
        {
          this.clientName = "In-House";
        }

        if(exportingdata.emailConfirmation == "true")
        {
          this.emailConfirmation = "Yes";
        }
        else if(exportingdata.emailConfirmation == "false")
        {
          this.emailConfirmation = "No";
        }

        let offerReleasedDate = this.datepipe.transform(exportingdata.offerReleasedDate, 'dd-MMM-yyyy');
        let offerAcceptedDate = this.datepipe.transform(exportingdata.offerAcceptedDate, 'dd-MMM-yyyy');

        this.exported ={
          ResourceName           : exportingdata.resource.resourceName,
          HiringFor              : exportingdata.hiring.listTypeValueName,
          ApprovedCTC            : exportingdata.approvedCtc,
          WorkLocation           : exportingdata.workLocation,

          ClientName             : this.clientName,
          OfferReleasedDate      : offerReleasedDate,
          OfferAcceptedDate      : offerAcceptedDate,
          WaitingPeriod          : exportingdata.waitingPeriod,
          ExpectedDateOfJoining  : exportingdata.workLocation,
          EmailConfirmation      : this.emailConfirmation,
        }
        this.dataExport.push(this.exported);
      }
      this.ExcelService.exportAsExcelFile(this.dataExport,"Export");
    }
  }


  selectResource(event)
  {
    if(event.target.value==null)
    {
      this.searchForm.patchValue({
        resourceId: null
      });
    }
  }

  get keywords()
  {
    return this.searchForm.get('keywords');
  }
  statuschangeactive(onboardingId)
  {
   
    // console.log("onboardingId"+onboardingId);    

   this.onboardingservice
   .onboardingstatus(onboardingId,"In-Active")
   .subscribe( (data:any) =>
   {
    this.loader=true;
      console.log("onboardingId::",data);
      // window.location.reload();
      this.onboardingList()
    
   })
  }
  statuschangeinactive(onboardingId)
  {
   
    // console.log("onboardingId"+onboardingId);    

   this.onboardingservice
   .onboardingstatus(onboardingId,"Active")
   .subscribe( (data:any) =>
   {
    this.loader=true;
      console.log("onboardingId");
      // window.location.reload();
      this.onboardingList();
    
   })

  }
  toggleStatus(e: any) {
    console.log("position::",e);
    
    // category.status.listTypeValueName = category.status.listTypeValueName === 'Active' ? 'In-Active' : 'Active';
  
    if(e.status.listTypeValueName==='Active'){
      console.log("if active");
      
      this.updateStatus(e.onboardingId,1)
    }
    else{
      console.log("de active");
      
      this.updateStatus(e.onboardingId,2)
  
    }
    
  }
  updateStatus(id,status)
  {
    this.onboardingservice.updateOnboardingStatus(id,status).subscribe((result:any) =>
    {
      // if(result.statusCode == 200)
      // {
      //   this.success = true;
      //   this.sucess_msg = result.description;
      //   setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
    //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // const currentUrl = this.router.url;
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //     this.router.navigateByUrl(currentUrl);
    // });
        this.onboardingList();
      // }
      // else
      // {
      //   this.error = true;
      //   this.error_msg = result.message;
      //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      // }
    })
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    // Trim leading and trailing spaces
    value = value.trim();

    // Replace multiple spaces with a single space
    value = value.replace(/\s+/g, ' ');

    // Set the modified value back to the input field
    textarea.value = value;
  }
  preventSpaces(event) {
    if (event.key === ' ') {
        event.preventDefault();
    }
  }
  showChanges()
  {
    console.log("show Change");
    this.p=1;
  }
  Add_resource()
  {
    this.router.navigate(['home/onboarding']);
  }

  onKeyUpEvent(event)
  {
    if(this.query.length !=0)
    {
      console.log(this.query.length);
      this.p=1;
    }
    else
    {
      this.p= this.beforepage;
    }
  }

  clearforms(){
    // this.advanceType=undefined
 
  this.resultData.length = 0;
    this.searchForm.reset()
    // this.nodatafound=true
    // this.btnDisable=false
    // this.btnsDisable=false
    // this.loader=false
    this.btnDisables=false;
    this.nodata=true;
    this.tableShow=false;
  }

  ucFirst(string) 
  {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  changepage(){
    console.log(this.resultData.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.resultData.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.resultData.length);
      console.log("nodata");
    }
  }
  sortData(event: Sort) 
  {
  
    const sort = event as unknown as Sort;
    const data = this.resultData.slice();
    if (!sort.active || sort.direction === '') {
      this.resultData = data;
      return;
    }
  
    this.resultData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) 
      {
        case 'resourceName':
          return compare(a.resourceName, b.resourceName, isAsc);

        case 'approvedCtc':
            return compare(a.approvedCtc, b.approvedCtc, isAsc);

        case 'hiringFor':
          return compare(a.hiringFor, b.hiringFor, isAsc);
        
        case 'workLocation':
          return compare(a.workLocation, b.workLocation, isAsc);

        case 'clientName':
          return compare(a.clientName, b.clientName, isAsc);
        
        case 'offerReleasedDate':
          return compare(a.offerReleasedDate, b.offerReleasedDate, isAsc);
        
        case 'offerAcceptedDate':
          return compare(a.offerAcceptedDate, b.offerAcceptedDate, isAsc);
        
        case 'waitingPeriod':
          return compare(a.waitingPeriod, b.waitingPeriod, isAsc);

        case 'dateOfJoining':
          return compare(a.dateOfJoining, b.dateOfJoining, isAsc);

        case 'emailConfirmation':
          return compare(a.emailConfirmation, b.emailConfirmation, isAsc);
          
        default:
          return 0;
      }
    });

  }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

