import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { httpOptions } from './properties';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {


  
 
  getvariablepaybyempCodeandFinPeriod(employeecodeSearch: any, financialperiodSearch: any) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/financial-period/${financialperiodSearch}/employee-number/${employeecodeSearch}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getvariablepaybyempCodeandFinYear(employeecodeSearch: any, finyear: any) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/employee-number/${employeecodeSearch}/financial-year/${finyear}`,httpOptions).pipe(map(data=>data))
    return result;
  }


 

  getemployeecodevariablepay(employeecode:any) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/employee-number/${employeecode}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getemployeecodevariablepayOrg(employeecode:any,orgId:any) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/employee-number/${employeecode}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getemployeecodevariablepayClient(employeecode:any,clientId) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/employee-number/${employeecode}/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getyearandperiodvariablepay(financialyearid:any,periodid:any) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/financial-year/${financialyearid}/financial-period/${periodid}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getvariablepay(financialyearid:any,periodid:any,employeecode:any) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/financial-year/${financialyearid}/financial-period/${periodid}/employee-number/${employeecode}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getyearbasedvariablepay(financialyearid:any) {
    let result =  this.http.get<any>(`${environment.base_url}variable-pay/financial-year/${financialyearid}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  

  calculateVariablePay(variablepayData: any) {
    return this.http.post(`${environment.base_url}variable-pay/`,variablepayData,httpOptions)
    .pipe(map(data=>data)
    );
  }
  getCategoryListByName() {
    let result =  this.http.get<any>(`${environment.base_url}list-type-value/name/Earnings`,httpOptions).pipe(map(data=>data))
    return result;
  }

  listRevenueAdjustmentByFinancialYearAndFinancialPeriodAndElementCategory(financialyear: any, period: any, categoryName: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/financial-period/${period}/payslipelement/${categoryName}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByEmployeeCodeAndFinancialYear(employeecode: any, financialyear: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee-number/${employeecode}/financial-year/${financialyear}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByFinancialPeriodAndElement(period: any, element: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-period/${period}/payslipelement/${element}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByEmployeeCodeAndFinancialYearAndFinancialPeriod(employeecode: any, financialyear: any, period: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/financial-period/${period}/employeenumber/${employeecode}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByEmployeeCodeAndElementCategory(employeecode: any, categoryName: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employeenumber/${employeecode}/payslipelement/${categoryName}`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByFinancialPeriod(period: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-period/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByEmployeeCodeAndElementCategoryAndFinancialYearAndFinancialPeriod(employeecode: any, categoryName: any, financialyear: any, period: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/financial-period/${period}/employeenumber/${employeecode}/payslipelement/${categoryName}`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByFinancialYearAndFinancialPeriodAndElement(financialyear: any, period: any, element: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/financial-period/${period}/payslipelement/${element}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByFinancialYearAndElement(financialyear: any, element: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/payslipelement/${element}`,httpOptions)
    .pipe(map(data=>data));

  }
  listRevenueAdjustmentByEmployeeCodeAndElement(employeecode: any, element: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employeenumber/${employeecode}/payslipelement/${element}`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByPeriodAndEmployeeCodeAndElement(period: any, employeecode: any, element: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-period/${period}/employeenumber/${employeecode}/payslipelement/${element}`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByYearAndEmployeeCodeAndElement(year: any, employeecode: any, element: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${year}/employeenumber/${employeecode}/payslipelement/${element}`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByElement(element: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/payslipelement/${element}`,httpOptions)
    .pipe(map(data=>data));
  }
  searchRevenueAdjustmentByElementCategoryId(categoryName: any) {
    console.log('categoryName', categoryName);
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/payslipelement/${categoryName}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByFinancialYearAndFinancialPeriodAndElementCategoryId(financialyear: any, period: any, categoryName: any) {
    // console.log('financialyear', financialyear);
    // console.log('period', period);
    // console.log('categoryName', categoryName);
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/financial-period/${period}/payslipelement/${categoryName}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByFinancialYearAndElementCategoryId(financialyear: any, categoryName: any) {
    // console.log('financialyear', financialyear);
    // console.log('categoryName', categoryName);
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/payslipelement/${categoryName}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByFinancialYearAndFinancialPeriod(financialyear: any, period: any) {
    // console.log('financialyear', financialyear);
    // console.log('period', period);
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}/financial-period/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  listRevenueAdjustmentByFinancialYear(financialyear: any) {
    // console.log('financialyear', financialyear);
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/financial-year/${financialyear}`,httpOptions)
    .pipe(map(data=>data));
  }
  getEmployeeRevenueAdjustmentById(employeeRevenueAdjustmentId: any) {
    console.log('empRevenueAdjId', employeeRevenueAdjustmentId);
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/${employeeRevenueAdjustmentId}`,httpOptions)
    .pipe(map(data=>data));
  }
  searchRevenueAdjustmentByEmployeeNumber(employeecode: any) {
    // console.log('empNum', employeecode);
     return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee-number/${employeecode}`,httpOptions)
     .pipe(map(data=>data));
  }

  searchRevenueAdjustmentByEmployeeNumberOrg(employeecode: any,orgId:any) {
    // console.log('empNum', employeecode);
     return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee-number/${employeecode}/organization/${orgId}`,httpOptions)
     .pipe(map(data=>data));
  }

  searchRevenueAdjustmentByEmployeeNumberClient(employeecode: any,clientId:any) {
    // console.log('empNum', employeecode);
     return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee-number/${employeecode}/client/${clientId}`,httpOptions)
     .pipe(map(data=>data));
  }

  createEmployeeRevenueAdjustment(employeeLeaveArray: any[]) {
    return this.http.post(`${environment.base_url}employee-revenue-adjustment/`,employeeLeaveArray,httpOptions)
    .pipe(map(data=>data)
    );
  }
  updateEmployeeRevenueAdjustment(empRatings: any) {
    return this.http.put(`${environment.base_url}employee-revenue-adjustment/`,empRatings,httpOptions)
    .pipe(map(data=>data)
    );
  }

  getEmployeeCategoryList(empLeaveId: any) {
    console.log('empLeaveId', empLeaveId);
    return this.http.get(`${environment.base_url}employee-leave/${empLeaveId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getListTypeValueById(arg0: number) {
    console.log('arg0', arg0);
    return this.http.get(`${environment.base_url}list-type-value/${arg0}`,httpOptions)
    .pipe(map(data=>data));
  }
  getEmployeeLeaveById(empLeaveId: any) {
    console.log('empLeaveId', empLeaveId);
    return this.http.get(`${environment.base_url}employee-leave/${empLeaveId}`,httpOptions)
    .pipe(map(data=>data));
  }
  listByEmpNumAndFinancialPeriod(employeecode: any, period: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-leave/employee-number/${employeecode}/financial-period/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  listByFinancialPeriod(period: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-leave/financial-period/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  listByEmpNumAndFinancialYear(employeecode: any, financialyear: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-leave/employee-number/${employeecode}/financial-year/${financialyear}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  listByFinancialYearAndFinancialPeriod(financialyear: any, period: any) {
    console.log('financialyear', financialyear);
    console.log('period', period);
    return this.http.get(`${environment.base_url}employee-leave/financial-year/${financialyear}/financial-period/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  listByFinancialYear(financialyear: any) {
    console.log('financialyear', financialyear);
    return this.http.get(`${environment.base_url}employee-leave/financial-year/${financialyear}`,httpOptions)
    .pipe(map(data=>data));
  }


  createEmployeeLeave(employeeLeaveArray: any[]) {
    return this.http.post(`${environment.base_url}employee-leave/`,employeeLeaveArray,httpOptions)
    .pipe(map(data=>data)
    );
  }
  updateEmployeeLeave(employeeLeaveId: any, employeeLeaveArray: any[]) {
    return this.http.put(`${environment.base_url}employee-leave/`,employeeLeaveArray,httpOptions)
    .pipe(map(data=>data)
    );
  }

  


  createEmployeeLevel(empLevel: any[]) {
    return this.http.post(`${environment.base_url}employee-level-assign/`,empLevel,httpOptions)
    .pipe(map(data=>data)
    );
  }

  updateEmployeeLevel(employeelevelAssignId: any, employeeLevelArray: any[]) {
    return this.http.put(`${environment.base_url}employee-level-assign/${employeelevelAssignId}`,employeeLevelArray,httpOptions)
    .pipe(map(data=>data)
    );
  }

  getPatchEmployeeLevel(employeelevelAssignId: any) {
    return this.http.get(`${environment.base_url}employee-level-assign/employeelevelAssign/${employeelevelAssignId}`,httpOptions)
    .pipe(map(data=>data)
    );
  }

  searchByEmployeeNumber(empNum):Observable<any>
  {
    console.log('empNum', empNum);
     return this.http.get(`${environment.base_url}employee-leave/employee-number/${empNum}`,httpOptions)
     .pipe(map(data=>data));
  }

  searchByEmployeeNumberByOrg(empNum,orgId):Observable<any>
  {
    console.log('empNum', empNum);
     return this.http.get(`${environment.base_url}employee-leave/employee-number/${empNum}/organization/${orgId}`,httpOptions)
     .pipe(map(data=>data));
  }

  searchByEmployeeNumberByClient(empNum,clientId):Observable<any>
  {
    console.log('empNum', empNum);
     return this.http.get(`${environment.base_url}employee-leave/employee-number/${empNum}/client/${clientId}`,httpOptions)
     .pipe(map(data=>data));
  }

  

  employeeleavelistByOrganization(orgId: number) {
     return this.http.get(`${environment.base_url}employee-leave/organization/${orgId}`,httpOptions)
     .pipe(map(data=>data));

    }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
  }

 
  // getAllEmployeeByOrganization(organizationId: any) {
  //   return this.http.get(`${environment.base_url}employee/organization/${organizationId}`,httpOptions)
  //   .pipe(map(data=>data));
  // }performance api

  getAllEmployeeByOrganization(organizationId: any) {
    return this.http.get(`${environment.base_url}master/employee/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getAllEmployeeByClient(clientId: any) {
    return this.http.get(`${environment.base_url}master/employee/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  // getAllEmployeeByClient(clientId: any) {
  //   return this.http.get(`${environment.base_url}master/employee/client/${clientId}`,httpOptions)
  //   .pipe(map(data=>data));
  // }

  getAllEmployeeByEntity(entityId: any) {
    return this.http.get(`${environment.base_url}master/employee/entity/${entityId}`,httpOptions)
    .pipe(map(data=>data));
  }



  getEmployeecount(employeeId: any,id:any,id1:any) {
    return this.http.get(`${environment.base_url}employee-leave/leave-count/employee/financialPeriod/financialYear/${employeeId}/${id}/${id1}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeecountAll(organizationId: any,id:any,id1:any) {
    return this.http.get(`${environment.base_url}employee-leave/leave-count/organization/financialPeriod/financialYear/${organizationId}/${id}/${id1}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeecountAllClient(clientId: any,id:any,id1:any) {
    return this.http.get(`${environment.base_url}employee-leave/leave-count/client/financialPeriod/financialYear/${clientId}/${id}/${id1}`,httpOptions)
    .pipe(map(data=>data));
  }


  getEmployeecountRating(employeeId: any,id:any,id1:any) {
    return this.http.get(`${environment.base_url}employee-rating/employee/financialPeriod/financialYear/${employeeId}/${id}/${id1}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeecountAllRating(organizationId: any,id:any,id1:any) {
    return this.http.get(`${environment.base_url}employee-rating/organization/financialPeriod/financialYear/${organizationId}/${id}/${id1}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeecountAllRatingClient(clientId: any,id:any,id1:any) {
    return this.http.get(`${environment.base_url}employee-rating/client/financialPeriod/financialYear/${clientId}/${id}/${id1}`,httpOptions)
    .pipe(map(data=>data));
  }
  
  searchByCalendarName(calendarNameSearch: any):Observable<any>
  {
    console.log('calendarNameSearch', calendarNameSearch);
     return this.http.get(`${environment.base_url}financialyear/financeyearname/${calendarNameSearch}`,httpOptions)
     .pipe(map(data=>data));
  }

  searchByBranch(branchSearch: any):Observable<any>
  {
    console.log('branchSearch', branchSearch);
     return this.http.get(`${environment.base_url}financialyear/branch/${branchSearch}`,httpOptions)
     .pipe(map(data=>data));
  }
  searchByBranchAndCalendarName(branchSearch: any, calendarNameSearch: any):Observable<any>
  {
    console.log('branchSearch-cal', branchSearch, calendarNameSearch);
     return this.http.get(`${environment.base_url}financialyear/financeyearname/branch/${calendarNameSearch}/${branchSearch}`,httpOptions)
     .pipe(map(data=>data));
  }

  companySettingsbyOrganizationId(organizationId):Observable<any>
  {
    console.log('organizationId', organizationId);
     return this.http.get(`${environment.base_url}organizationsetting/organization/${organizationId}`,httpOptions)
     .pipe(map(data=>data));
  }

  listCountry():Observable<any>
  {
    console.log('listCountry')
    return this.http.get(`${environment.base_url}country`,httpOptions)
    .pipe(map(data=>data));
  }
  listByCountryId(countryId):Observable<any>
  {
    console.log('countryId', countryId);
     return this.http.get(`${environment.base_url}country/${countryId}`,httpOptions)
     .pipe(map(data=>data));
  }
  constructor(private http: HttpClient) { }

  updateOrganizationSettings(orgSettingsupdate:any , organizationSettingId :any):Observable<any>
  {
    return this.http.put(`${environment.base_url}organizationsetting/${organizationSettingId}`,orgSettingsupdate,httpOptions)
    .pipe(map(data=>data)
    );
  }
  //Element Definition
  elementbyid(elementId):Observable<any>
  {
    console.log('elementId', elementId);
     return this.http.get(`${environment.base_url}hrmspaySlipElement/${elementId}`,httpOptions)
     .pipe(map(data=>data));
  }

  companySettingsbyid(companySettingsId):Observable<any>
  {
    console.log('companySettingsId', companySettingsId);
     return this.http.get(`${environment.base_url}organizationsetting/${companySettingsId}`,httpOptions)
     .pipe(map(data=>data));
  }

  elementcreateorg(elementcreate:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}hrmspaySlipElement`,elementcreate,httpOptions)
    .pipe(map(data=>data)
    );
  }
  elementcreate(elementcreate:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}hrmspaySlipElement/client`,elementcreate,httpOptions)
    .pipe(map(data=>data)
    );
  }
  // http://localhost:6015/api/enote/hrms/hrmspaySlipElement/client
  elementupdateorg(elementupdate:any , elementId :any):Observable<any>
  {
    return this.http.put(`${environment.base_url}hrmspaySlipElement/${elementId}`,elementupdate,httpOptions)
    .pipe(map(data=>data)
    );
  }

  elementupdate(elementupdate:any , elementId :any):Observable<any>
  {
    return this.http.put(`${environment.base_url}hrmspaySlipElement/client/${elementId}`,elementupdate,httpOptions)
    .pipe(map(data=>data)
    );
  }

  // http://localhost:6015/api/enote/hrms/hrmspaySlipElement/client/62

  elementListbyOrganization(organizationId : any):Observable<any>
  {
    console.log('elementList by org')
    return this.http.get(`${environment.base_url}hrmspaySlipElement/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }


  elementList(organizationId : any):Observable<any>
  {
    console.log('elementList')
    return this.http.get(`${environment.base_url}hrmspaySlipElement/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  elementListByClient(clientId : any):Observable<any>
  {
    console.log('elementList')
    return this.http.get(`${environment.base_url}hrmspaySlipElement/active/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }



  elementstatus(elementId,status):Observable<any>
  {
    return this.http.put(`${environment.base_url}hrmspaySlipElement/deactive/${elementId}/${status}`,null,httpOptions)
    .pipe(map(data=>data));
  }

  listCategoryType(organizationId) :Observable<any>{
    return this.http.get(`${environment.base_url}list-type-values/8`,httpOptions)
    .pipe(map(data=>data));
  
  }

  listByCategoryId(categoryId, organizationId):Observable<any>{
    return this.http.get(`${environment.base_url}hrmspaySlipElement/categorytype/${categoryId}/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  listByCategoryIdClient(categoryId, clientId):Observable<any>{
    return this.http.get(`${environment.base_url}hrmspaySlipElement/categorytype/${categoryId}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

    listByClientCategoryType(categoryId:number, clientId:number){
      return this.http.get(`${environment.base_url}hrmspaySlipElement/categorytype/${categoryId}/client/${clientId}`,httpOptions)
      .pipe(map(data=>data));
    }

    // http://localhost:6015/api/enote/hrms/hrmspaySlipElement/categorytype/42/client/2
//Element Percentage
  elementpercentagebyid(elementPercentageId):Observable<any>
  {
    console.log('elementPercentageId', elementPercentageId);
     return this.http.get(`${environment.base_url}elementpercetage/${elementPercentageId}`,httpOptions)
     .pipe(map(data=>data));
  }

  elementpercantagecreate(elementcreate:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}elementpercetage`,elementcreate,httpOptions)
    .pipe(map(data=>data)
    );
  }
  elementpercantageupdate(elementupdate:any , elementPercentageId :any):Observable<any>
  {
    return this.http.put(`${environment.base_url}elementpercetage/${elementPercentageId}`,elementupdate,httpOptions)
    .pipe(map(data=>data)
    );
  }
  elementpercentageList():Observable<any>
  {
    console.log('elementList')
    return this.http.get(`${environment.base_url}elementpercetage`,httpOptions)
    .pipe(map(data=>data));
  }

  elementpercantagestatus(elementPercentageId,status):Observable<any>
  {
    return this.http.put(`${environment.base_url}elementpercetage/deactive/${elementPercentageId}/${status}`,null,httpOptions)
    .pipe(map(data=>data));
  }
  
  listLivingCity() :Observable<any>{
    return this.http.get(`${environment.base_url}list-type-values/15`,httpOptions)
    .pipe(map(data=>data));
  }

  listByElementId(categoryId):Observable<any>{
    return this.http.get(`${environment.base_url}elementpercetage/element/${categoryId}`,httpOptions)
    .pipe(map(data=>data));
  }

  gethraelementpercentage(value: any) {
    return this.http.get(`${environment.base_url}elementpercetage/livingcitytype/${value}/element-name/HRA/`,httpOptions)
    .pipe(map(data=>data));
  }
 

  getclientlist(organizationId) {
    return this.http.get(`${environment.base_url}client/organizationId/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  //Shift calendar
  getshiftcalendarbyId(shiftCalendarId: any) {
    return this.http.get(`${environment.base_url}shiftcalendar/${shiftCalendarId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getshiftcalendarbyClient(clientserach: any) {
    return this.http.get(`${environment.base_url}shiftcalendar/client/${clientserach}`,httpOptions)
    .pipe(map(data=>data));
  }

  getshiftcalendarbyClientAndDate(clientserach: any, startDateSearch: any, endDateSearch: any) {
    return this.http.get(`${environment.base_url}shiftcalendar/client/date/${startDateSearch}/${endDateSearch}/${clientserach}`,httpOptions)
    .pipe(map(data=>data));
  }
 
  getdefaultshiftcalendarbyclient(clientId: any) {
    return this.http.get(`${environment.base_url}shiftcalendar/client/default/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  updateShiftcalendarStatus(id: any, status: any) {
    return this.http.put(`${environment.base_url}shiftcalendar/deactivate/${id}/${status}`,null,httpOptions)
    .pipe(map(data=>data));
  }
  createShiftCalendar(shiftcalendarData: any) {
     return this.http.post(`${environment.base_url}shiftcalendar/`,shiftcalendarData,httpOptions)
    .pipe(map(data=>data));
  }
 
  updateShiftCalendar(shiftcalendarId: any, shiftcalendarData: any) {
    return this.http.put(`${environment.base_url}shiftcalendar/${shiftcalendarId}`,shiftcalendarData,httpOptions)
    .pipe(map(data=>data));
  }
  validateshiftcalendarname(fata: any) {
    let result =  this.http.post<any>(`${environment.base_url}shiftcalendar/create`,fata,httpOptions).pipe(map(data=>data))
    return result;
  }

  validatecalendarnameupdate(fata: any) {
    let result =  this.http.post<any>(`${environment.base_url}shiftcalendar/update`,fata,httpOptions).pipe(map(data=>data))
    return result;
  }
 
  updateShiftcalendardefault(shiftCalendarId: any) {
    return this.http.put(`${environment.base_url}shiftcalendar/default/${shiftCalendarId}`,null,httpOptions)
    .pipe(map(data=>data));
  }
 
  getListTypeById(listTypeValueId: number) {
    return this.http.get(`${environment.base_url}list-type-values/${listTypeValueId}`,httpOptions)
    .pipe(map(data=>data));
  }


  getHoliday() {
    return this.http.get(`${environment.base_url}holiday`);
  }
  getHolidayById(holidayId) {
    return this.http.get(`${environment.base_url}holiday/${holidayId}`,httpOptions);
  }
  getExceptionById(exceptionId) {
    return this.http.get(`${environment.base_url}exceptions/${exceptionId}`,httpOptions);
  }
  getHolidayByCalId(calendarId) {
    return this.http.get(`${environment.base_url}holiday/calendar/${calendarId}`,httpOptions);
  }
  getExceptionByCalId(calendarId) {
    return this.http.get(`${environment.base_url}holiday/calendar/${calendarId}`,httpOptions);
  }

  UpdateHoliday(holidayId, data) {
    return this.http.put(`${environment.base_url}holiday/${holidayId}`, data,httpOptions)
  }

  UpdateException(exceptionId, data) {
    return this.http.put(`${environment.base_url}exceptions/${exceptionId}`, data,httpOptions)
  }

  createHoliday(Data: FormData) {
    return this
      .http
      .post(`${environment.base_url}holiday`, Data ,httpOptions);
  }
  createMultipleHoliday(Data) {
    return this
      .http
      .post(`${environment.base_url}multiple/holidays`, Data,httpOptions);
  }

  createException(Data: FormData) {
    return this
      .http
      .post(`${environment.base_url}exceptions`, Data,httpOptions);
  }
  createMultipleException(Data) {
    return this
      .http
      .post(`${environment.base_url}multiple/exceptions`, Data,httpOptions);
  }

  deleteHoliday(id: any) {
    return this
    .http
    .delete(`${environment.base_url}holiday/id`,httpOptions);
  }
 

  getshiftbyclient(clientId: any) {
    return this.http.get(`${environment.base_url}shift/client/${clientId}`,httpOptions);
  }

  getemployeeshiftbyClientAndShift(shiftserach: any, clientId: any) {
    return this.http.get(`${environment.base_url}shift-employee-assign/client/shift/${clientId}/${shiftserach}`,httpOptions);
  }

  getemployeeshiftbyClientAndShiftAndDate(shiftserach: any, clientId: any, startDate: string, endDate: string) {
    return this.http.get(`${environment.base_url}shift-employee-assign/client/shift/start-date/end-date/${clientId}/${shiftserach}/${startDate}/${endDate}`,httpOptions);
  }

  updateEmployeeShiftStatus(id: any, status: any) {
    return this.http.put(`${environment.base_url}shift-employee-assign/status/${id}/${status}`,null,httpOptions)
    .pipe(map(data=>data));
   
  }

  getshiftbyshiftcalendar(shiftcalendarId: any) {
    return this.http.get(`${environment.base_url}shift/shift-calendar/${shiftcalendarId}`,httpOptions);
  }

  // client Employee Assignmnet
  getClientNameList(orgId: number) {
    return this.http.get(`${environment.base_url}client/organizationId/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientNameListbyClient(clientId: number) {
    return this.http.get(`${environment.base_url}client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientNameListBasedEntity(entityId: number) {
    return this.http.get(`${environment.base_url}client/entity/${entityId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getOverAlllisting(entityId:any){
    return this.http.get(`${environment.base_url}employeeClientAssign/entity/${entityId}`,httpOptions)
    .pipe(map(data=>data));

  }
  
  getOrganizationNameListBasedEntity(entityId: number) {
    return this.http.get(`${environment.base_url}organization/entity/${entityId}`,httpOptions)
    .pipe(map(data=>data));
  }
  // http://localhost:6015/api/enote/hrms/organization/entity/1

  // http://localhost:6015/api/enote/hrms/client/entity/1
  // http://localhost:6015/api/enote/hrms/employee/filter/1042/1
  
  getEmployeeNameList(userId:number,orgId: number) {
    return this.http.get(`${environment.base_url}employee/filter/${userId}/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientEmployeeNameList(userId:number,clientId: number) {
    return this.http.get(`${environment.base_url}employee/filter/client/${userId}/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeeNameListEntity(userId:number,entityId: number) {
    return this.http.get(`${environment.base_url}employee/filter/keyword/${userId}/entity/${entityId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeeClientList(employeenumber: any, client: any) {
    return this.http.get(`${environment.base_url}employeeclientassign/filter/employee/${employeenumber}/${client}`,httpOptions)
    .pipe(map(data=>data));
  }

  clientAssignmrntcreate(clientAssignCreate:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}employeeclientassign`,clientAssignCreate,httpOptions)
    .pipe(map(data=>data)
    );
  }
 // update
 clientAssignmentUpdate(clientupdateId: any, updateAssignCreate: any) {
  return this.http.put(`${environment.base_url}employeeclientassign/${clientupdateId}`,updateAssignCreate,httpOptions)
  .pipe(map(data=>data));
}

  // importemployeeclient(formData: any) {
  //   ImporthttpOptions
    
  //   return this.http.post(`${environment.base_url}employeeclientassign/uploadfile`,formData,httpOptions)
  //   .pipe(map(data=>data)
  //   );
  // }

  importemployeeclient(postValues:any): Observable<any>
  {
    const ImporthttpOptions = {
        headers: new HttpHeaders({
            'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
        })
    }

    let result = this.http.post(`${environment.base_url}employeeclientassign/uploadfile`,postValues,ImporthttpOptions).pipe(map(data=>data))
    return result;
  }

  importemployeeshift(formData: any): Observable<any> {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

  let result = this.http.post(`${environment.base_url}shift-employee-assign/data/import`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }

  // Filter List
  getClientFilterList(clientId: number) {
    return this.http.get(`${environment.base_url}employeeclientassign/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientandDateBasedFilterList(clientId:number,startdate,endDate) {
    return this.http.get(`${environment.base_url}employeeclientassign/client/startDate/endDate/
    ${clientId}/${startdate}/${endDate}`,httpOptions)
    .pipe(map(data=>data));
  }
  // deactive ClientStatus
  updateClientStatus(id: any, status: any) {
    let result =  this.http.put<any>(`${environment.base_url}employeeclientassign/deactive/${id}/${status}`,null,httpOptions).pipe(map(data=>data))
    return result;
  }
  
  getClientId(data: number) {
    return this.http.get(`${environment.base_url}employeeclientassign/${data}`,httpOptions)
    .pipe(map(data=>data));
  }
  // shift module
  // shift Filter
  getFilterShiftNameList(filterName: any) {
    return this.http.get(`${environment.base_url}shift/shift-name/${filterName}`,httpOptions)
    .pipe(map(data=>data));
  }
  getFilterClientList(filterClient: any) {
    return this.http.get(`${environment.base_url}shift/client/${filterClient}`,httpOptions)
    .pipe(map(data=>data));
  }
  getFilterShiftNameandClientList(filterName: any,filterClient: any) {
    return this.http.get(`${environment.base_url}shift/shift-name/client/${filterName}/${filterClient}`,httpOptions)
    .pipe(map(data=>data));
  }
  

  assignEmployeeShift(shiftemployeeDataList: any[]) {
    return this.http.post(`${environment.base_url}shift-employee-assign/multiple`,shiftemployeeDataList,httpOptions)
    .pipe(map(data=>data)
    );
  }

  assignEmployeeShiftUpdate(shiftemployeeId: any, shiftemployeeData: any ) {
    return this.http.put(`${environment.base_url}shift-employee-assign/${shiftemployeeId}`,shiftemployeeData,httpOptions)
    .pipe(map(data=>data)
    );
  }

  getShiftemployeeId(shiftemployeeId: any) {
    return this.http.get(`${environment.base_url}shift-employee-assign/${shiftemployeeId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getemployeeshiftbyClient(clientserach: any) {
    return this.http.get(`${environment.base_url}shift-employee-assign/client/${clientserach}`,httpOptions)
    .pipe(map(data=>data));
  }

  // Shift create

  creteShift(createshift:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}shift/`,createshift,httpOptions)
    .pipe(map(data=>data)
    );
  }
 // update
 UpdateShift(shiftupdateId: any, updateData: any) {
  return this.http.put(`${environment.base_url}shift/${shiftupdateId}`,updateData,httpOptions)
  .pipe(map(data=>data));
  }

  getShiftId(shiftupdateId: number) {
    return this.http.get(`${environment.base_url}shift/${shiftupdateId}`,httpOptions)
    .pipe(map(data=>data));
  }

  updateShiftStatus(id: any, status: any) {
    let result =  this.http.put<any>(`${environment.base_url}shift/status/${id}/${status}`,null,httpOptions).pipe(map(data=>data))
    return result;
  }

  validateshiftname(fata: any) {
    let result =  this.http.post<any>(`${environment.base_url}shift/validate`,fata,httpOptions).pipe(map(data=>data))
    return result;
  }

  validateshiftnameupdate(fata: any,shiftupdateId:number) {
    let result =  this.http.put<any>(`${environment.base_url}shift/validate/${shiftupdateId}`,fata,httpOptions).pipe(map(data=>data))
    return result;
  }

  getAllEmployeeClientList(client: any) {
    return this.http.get(`${environment.base_url}employeeclientassign/filter/all/employee/${client}`,httpOptions)
    .pipe(map(data=>data));

  }

  getEmployeegroups(keywords: any, clientId: any) {
    return this.http.get(`${environment.base_url}employee-groups/filter/${keywords}/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeeGroupsByclient(clientId: any) {
    return this.http.get(`${environment.base_url}employee-groups/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeegroupsName(keywords: any) {
    return this.http.get(`${environment.base_url}employee-groups/name/${keywords}`,httpOptions)
    .pipe(map(data=>data));
  }
 
  updateEmployeeGroupsStatus(id: any, status: any) {
    return this.http.put(`${environment.base_url}employee-groups/status/${id}/${status}`,null , httpOptions)
    .pipe(map(data=>data));
  }

  employeegroupCreate(employeeGroupData:any ) {
    return this.http.post(`${environment.base_url}employee-groups/`,employeeGroupData , httpOptions)
    .pipe(map(data=>data));
  }

  getEmployeegroupsById(employeegroupId: any) {
    return this.http.get(`${environment.base_url}employee-groups/${employeegroupId}`,httpOptions)
    .pipe(map(data=>data));
  }

  employeegroupUpdate(employeegroupId: any, employeeGroupData: any) {
    return this.http.put(`${environment.base_url}employee-groups/${employeegroupId}`,employeeGroupData , httpOptions)
    .pipe(map(data=>data));
  }

  validateemployeegroupname(employeeGroupData: any) {
    return this.http.post(`${environment.base_url}employee-groups/validate`,employeeGroupData , httpOptions)
    .pipe(map(data=>data));
  }

  importemployeegroup(formData: any): Observable<any> {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

  let result = this.http.post(`${environment.base_url}employee-groups/upload`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }

  getEmployeeGroupClientList(code: any, client: any) {
    return this.http.get(`${environment.base_url}employee-groups/groupcode/${code}/${client}`,httpOptions)
    .pipe(map(data=>data));
  }
 
  assignEmployeeShiftbyemployeegroup(shiftemployeeData: any) {
    return this.http.post(`${environment.base_url}shift-employee-assign/multiple/employeeGroup`,shiftemployeeData , httpOptions)
    .pipe(map(data=>data));
  }
 
  // CreateRating
  createrating(createRating:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}employee-rating/`,createRating,httpOptions)
    .pipe(map(data=>data)
    );
  }

  updateEmployeeRating(empRatings: any) {
    return this.http.put(`${environment.base_url}employee-rating/`,empRatings,httpOptions)
    .pipe(map(data=>data));
    }

    getEmployeeRatingId(employeerattingId: number) {
      return this.http.get(`${environment.base_url}employee-rating/${employeerattingId}`,httpOptions)
      .pipe(map(data=>data));
    }

    //  employeeratiglist
    // http://localhost:6015/api/enote/hrms/employee-rating/financial-year/11077/financial-period/1008/employeenumber/1029
  getemployeerating(financialyearid:any,periodid:any,employeecode:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-rating/financial-year/${financialyearid}/financial-period/${periodid}/employeenumber/${employeecode}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getemployeeLeave(employeecode:any,periodid:any,) {
    let result =  this.http.get<any>(`${environment.base_url}employee-leave/employee-number/${employeecode}/financial-period/${periodid}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getyearandperiod(financialyearid:any,periodid:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-rating/financial-year/${financialyearid}/financial-period/${periodid}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getemployeecode(employeecode:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-rating/employee-number/${employeecode}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getemployeecodeOrg(employeecode:any,orgId) {
    let result =  this.http.get<any>(`${environment.base_url}employee-rating/employee-number/${employeecode}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getemployeecodeClient(employeecode:any,clientId) {
    let result =  this.http.get<any>(`${environment.base_url}employee-rating/employee-number/${employeecode}/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }


  getyearbased(financialyearid:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-rating/financial-year/${financialyearid}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getRatingById(ratingid: any) {
    return this.http.get(`${environment.base_url}employee-rating/${ratingid}`,httpOptions)
    .pipe(map(data=>data));
  }

  getannexuebyEmployee(employee: any , organizationId:any) {
    return this.http.get(`${environment.base_url}employee-annexure/employee-number/${employee}/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getannexuebyClientEmployee(employee: any , clientId:any) {
    return this.http.get(`${environment.base_url}employee-annexure/employee-number/${employee}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }


  getemployeeannexureByEmployee(employeeId: any) {
    return this.http.get(`${environment.base_url}employee-annexure/employee/${employeeId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getannexuebyEmployeeAndDate(employee: any, startDate: string, endDate: string, organizationId: any) {
    return this.http.get(`${environment.base_url}employee-annexure/employee-number/${employee}/from-date/${startDate}/to-date/${endDate}/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getannexuebyEmployeeAndDateClient(employee: any, startDate: string, endDate: string, clientId: any) {
    return this.http.get(`${environment.base_url}employee-annexure/employee-number/${employee}/from-date/${startDate}/to-date/${endDate}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getemployeeannexureByEmployeeAndFromdateAndTodate(employeeId: any, startDate: string, endDate: string) {
    return this.http.get(`${environment.base_url}employee-annexure/employee/${employeeId}/from-date/${startDate}/to-date/${endDate}`,httpOptions)
    .pipe(map(data=>data));
  }


  getemployeeannexureByEmployeeAndClientAndFromdateAndTodate(employee:any,client:any,fromdate:any,todate:any,organization:any){
    return this.http.get(`${environment.base_url}employee-annexure/employee/${employee}/client/${client}/from-date/${fromdate}/to-date/${todate}/organization/${organization}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeeannexureByEmployeeAndClient(employee:any,client:any,organization:any){
    return this.http.get(`${environment.base_url}employee-annexure/employee/${employee}/client/${client}/organization/${organization}`,httpOptions)
    .pipe(map(data=>data));
  }

  getannexuebyorg(organizationId: any) {
    return this.http.get(`${environment.base_url}employee-annexure/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getemployeepayrollByEmployeeAndClientAndFinancialperiod(employee:any,client:any,financialPeriod:any){
    return this.http.get(`${environment.base_url}pay-roll/employee/${employee}/client/${client}/finicalperiod/${financialPeriod}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeepayrollByClientAndFinancialperiod(client:any,financialPeriod:any){
    return this.http.get(`${environment.base_url}pay-roll/client/${client}/finicalperiod/${financialPeriod}`,httpOptions)
    .pipe(map(data=>data));
  }

  getemployeepayrollByEmployeeAndClientFinancialyear(employee:any,client:any,financialyear:any){
    return this.http.get(`${environment.base_url}pay-roll/employee/${employee}/client/${client}/finicalyear/${financialyear}`,httpOptions)
    .pipe(map(data=>data));
  }  
  getemployeepayrollByEmployeeAndClient(employee:any,client:any){
    return this.http.get(`${environment.base_url}pay-roll/employee/${employee}/client/${client}`,httpOptions)
    .pipe(map(data=>data));
  }
  //Client Employee payslip
  getemployeepayslipByClientandemployee(client:any,employee:any){
    return this.http.get(`${environment.base_url}payslip/client/${client}/employee/${employee}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeepayslipByClientandemployeeandPeriod(client:any,employee:any,period:any){
    return this.http.get(`${environment.base_url}payslip/client/${client}/employee/${employee}/financial-period/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeepayslipByClientandemployeeandFinancialyear(client:any,employee:any,fiYear:any){
    return this.http.get(`${environment.base_url}payslip/client/${client}/employee/${employee}/financial-year/${fiYear}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeepayslipByClientandemployeeAndDesignation(client:any,employee:any,designation:any){
    return this.http.get(`${environment.base_url}payslip/client/${client}/employee/${employee}/designation/${designation}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeepayslipByClientandemployeeAndDesignationandFinancialyear(client:any,employee:any,designation:any,fiYear:any){
    return this.http.get(`${environment.base_url}payslip/client/${client}/employee/${employee}/designation/${designation}/financial-year/${fiYear}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeepayslipByClientandemployeeAndDesignationandPeriod(client:any,employee:any,designation:any,period:any){
    return this.http.get(`${environment.base_url}payslip/client/${client}/employee/${employee}/designation/${designation}/financial-period/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  // Client employee Revenue Adjustment
  getemployeeRevenueByClientandemployee(employee:any,client:any){
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employee}/client/${client}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeeRevenueByClientandemployeeFinancialyear(employee:any,client:any,fiYear:any){
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employee}/client/${client}/financial-year/${fiYear}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeeRevenueByClientandemployeePeriod(employee:any,client:any,period:any){
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employee}/client/${client}/financial-period/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeeRevenueByClientandemployeeAndpayslipelement(employee:any,client:any,payslipelement:any){
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employee}/client/${client}/payslipelement/${payslipelement}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeeRevenueByClientandemployeeAndpayslipelementFinancialyear(employee:any,client:any,payslipelement:any,fiYear:any){
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employee}/client/${client}/payslipelement/${payslipelement}/financial-year/${fiYear}`,httpOptions)
    .pipe(map(data=>data));
  }
  getemployeeRevenueByClientandemployeeAndpayslipelementPeriod(employee:any,client:any,payslipelement:any,period:any){
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employee}/client/${client}/payslipelement/${payslipelement}/financial-period/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  
  
  getelements(organizationId: any) {
    return this.http.get(`${environment.base_url}hrmspaySlipElement/active/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

    
  getelementsByClient(clientId: any) {
    return this.http.get(`${environment.base_url}hrmspaySlipElement/active/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }
getElementByclientElementId(clientId:any){
  return this.http.get(`${environment.base_url}hrmspaySlipElement/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
}
  assignEmployeeannexure(annuexdata: any) {
    return this.http.post(`${environment.base_url}employee-annexure/`,annuexdata,httpOptions)

  }

  importemployeeannexue(formData: FormData) {
    return this.http.post(`${environment.base_url}employee-annexure/data/import`,formData).pipe(map(data=>data))
  }
  
  // getBusinessUnit(){
  //   return this.http.get(`${environment.base_url}active/businessUnit/entity/1`,httpOptions)
  //   .pipe(map(data=>data));  }

  //   getBusinessGroup(){
  //     return this.http.get(`${environment.base_url}businessGroup/entity/1`,httpOptions)
  //     .pipe(map(data=>data));  }


  //     getOrganization(){
  //       return this.http.get(`${environment.base_url}organization/entity/1`,httpOptions)
  //       .pipe(map(data=>data));  }

        getEmployementType(organizationId:any,id:any){
          return this.http.get(`${environment.base_url}employee/organization/${organizationId}/employeementtype/${id}`,httpOptions)
          .pipe(map(data=>data)); 
         }

         getClientEmployementType(clientId:any,id:any){
          return this.http.get(`${environment.base_url}employee/client/${clientId}/employeementtype/${id}`,httpOptions)
          .pipe(map(data=>data)); 
         }


  // Adjustment 
  getAdjustmentById(recAdjustmentId: any) {
    return this.http.get(`${environment.base_url}employee-recovery-adjustment/${recAdjustmentId}`,httpOptions)
    .pipe(map(data=>data));
  }

  createAdjustment(createRating:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}employee-recovery-adjustment/`,createRating,httpOptions)
    .pipe(map(data=>data)
    );
  }

  getemployeeannexuebyId(empannexueId: any) {
    return this.http.get(`${environment.base_url}employee-annexure/${empannexueId}`,httpOptions)
    .pipe(map(data=>data));
  }

  updateEmployeeannexure(annuexdata: any, empannexueId: any) {
    return this.http.put(`${environment.base_url}employee-annexure/${empannexueId}`,annuexdata,httpOptions)
    .pipe(map(data=>data)
    );
  }

  getannexueversionbyemployee(employeeId: any) {
    return this.http.get(`${environment.base_url}employee-annexure/employee/${employeeId}`,httpOptions)
    .pipe(map(data=>data)
    );
  }

  getemployeeannexureClient(client: any) {
    return this.http.get(`${environment.base_url}employee-annexure/client/${client}`,httpOptions)
    .pipe(map(data=>data)
    );
  }

  updateAdjustment(empRatings: any) {
    return this.http.put(`${environment.base_url}employee-recovery-adjustment/`,empRatings,httpOptions)
    .pipe(map(data=>data));
    }

    importRecoveryAdjustment(formData:any): Observable<any>
  {
    const ImporthttpOptions = {
        headers: new HttpHeaders({
          
            'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
        })
    }
  let result = this.http.post(`${environment.base_url}employee-recovery-adjustment/data/import/file`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }
  importRecoveryAdjustmentbyClient(formData:any): Observable<any>
  {
    const ImporthttpOptions = {
        headers: new HttpHeaders({
          
            'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
        })
    }
  let result = this.http.post(`${environment.base_url}employee-recovery-adjustment/data/import/file/client`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }
    getCategoryList() {
    let result =  this.http.get<any>(`${environment.base_url}list-type-value/name/Deduction`,httpOptions).pipe(map(data=>data))
    return result;
    }
    getEarningsCategoryList() {
      let result =  this.http.get<any>(`${environment.base_url}list-type-value/name/Earnings`,httpOptions).pipe(map(data=>data))
      return result;
      }

    getElementListValues(category:any, organizationId: any) {
      let result =  this.http.get<any>(`${environment.base_url}hrmspaySlipElement/categorytype/${category}/organization/${organizationId}`,httpOptions).pipe(map(data=>data))
      return result;
      }

      getClientElementListValues(category:any, clienId: any) {
        let result =  this.http.get<any>(`${environment.base_url}hrmspaySlipElement/categorytype/${category}/client/${clienId}`,httpOptions).pipe(map(data=>data))
        return result;
        }

      getRecoveryAdjustmentById(recAdjustmentId:number) {
        return this.http.get(`${environment.base_url}employee-recovery-adjustment/${recAdjustmentId}`,httpOptions)
        .pipe(map(data=>data));
      }

 
  
    getAdjustmentList(client:any,employee:any,financialyearid:any,periodid:any,elementid:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/financial-year/${financialyearid}/financial-period/${periodid}/employee/${employee}/payslipelement/${elementid}/client/${client}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getYearandPeriodList(financialyearid:any,periodid:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/financial-year/${financialyearid}/financial-period/${periodid}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    getElementList(elementid:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/payslipelement/${elementid}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    getYearList(financialyearid:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/financial-year/${financialyearid}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    getClientandEmpList(employee:any,client:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/employee/${employee}/client/${client}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    //  AdjustmentList Filter
    // getAdjustmentEmpList(employeecode:any) {
    //   let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/employee-number/${employeecode}`,httpOptions).pipe(map(data=>data))
    //   return result;
    // }

    getAdjustmenClientList(employeecode:any, clientId:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/employee-number/${employeecode}/client/${clientId}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getAdjustmentOrgList(employeecode:any, orgId:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/employee-number/${employeecode}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
      return result;
    }



    getAdjustmentYearList(financialyear:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/financial-year/${financialyear}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    getAdjustmentYearandPeriodList(financialyear:any,period:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/financial-year/${financialyear}/financial-period/${period}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    getAdjustmentElementList(element:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/payslipelement/${element}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    getAdjustmentAllList(employeecode:any,financialyear:any,period:any,element:any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/financial-year/${financialyear}/financial-period/${period}/employeenumber/${employeecode}/payslipelement/${element}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getAdjustmenttByorganziation(orgId: any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/organization/${orgId}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getAdjustmentemployeeandperiod(empnumber: any, financialyear: any, period: any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/employee/${empnumber}/financial-period/${period}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getAdjustmentemployeeandyear(employeeId: number, financialyear: any) {
      let result =  this.http.get<any>(`${environment.base_url}employee-recovery-adjustment/employee/${employeeId}/financial-year/${financialyear}`,httpOptions).pipe(map(data=>data))
      return result;
    }
  
  

    // Pf Report List
    getemployeeList(employeecode:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/employee/${employeecode}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    getyearList(financialyearid:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/year/${financialyearid}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    geEmpandYear(employeecode:any,financialyearid:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/employee/year/${employeecode}/${financialyearid}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    
    geYearandPeriod(financialyearid:any,fromperiod:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/year/period/${financialyearid}/${fromperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    geYearandPeriodOrg(financialyearid:any,fromperiod:any,orgId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/organization/year/period/${orgId}/${financialyearid}/${fromperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    geYearandPeriodClient(financialyearid:any,fromperiod:any,clientId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/client/year/period/${clientId}/${financialyearid}/${fromperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }


    getpfList(employeecode:any,financialyearid:any,fromperiod:any,toperiod:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/employee/year/period/${employeecode}/${financialyearid}/${fromperiod}/${toperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getpfListbyemployeeandperiod(employeecode: any, financialyear: any, fromperiod: any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/employee/period/${employeecode}/${fromperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getpfListbyemployeeandperiodOrg(employeecode: any, financialyear: any, fromperiod: any, orgId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/employee/organization/year/period/${employeecode}/${orgId}/${financialyear}/${fromperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }


    getpfListbyemployeeandperiodClient(employeecode: any, financialyear: any, fromperiod: any, clientId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/pf/employee/client/year/period/${employeecode}/${clientId}/${financialyear}/${fromperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }


    

    // TDS Report List
    gettdsemployeeList(employeecode:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/employee/${employeecode}`,httpOptions).pipe(map(data=>data))
      return result;
    }


    gettdsemployeeListOrg(employeecode:any,orgId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/employee/${employeecode}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
      return result;
    }


    gettdsemployeeListClient(employeecode:any,clientId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/employee/${employeecode}/client/${clientId}`,httpOptions).pipe(map(data=>data))
      return result;
    }


    gettdsyearList(financialyearid:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/year/${financialyearid}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    
    gettdsEmpandYear(employeecode:any,financialyearid:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/employee/year/${employeecode}/${financialyearid}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    
    gettdsYearandPeriod(financialyearid:any,fromperiod:any,toperiod:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/year/period/${financialyearid}/${fromperiod}/${toperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    gettdsYearandPeriodOrg(financialyearid:any,fromperiod:any,toperiod:any,orgId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/organization/year/period/${financialyearid}/${orgId}/${fromperiod}/${toperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    gettdsYearandPeriodClient(financialyearid:any,fromperiod:any,toperiod:any,clientId) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/client/year/period/${financialyearid}/${clientId}/${fromperiod}/${toperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    gettdsList(employeecode:any,financialyearid:any,fromperiod:any,toperiod:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/tds/employee/year/period/${employeecode}/${financialyearid}/${fromperiod}/${toperiod}`,httpOptions).pipe(map(data=>data))
      return result;
    }

   //payroll 
    runpayrollbyemployee(emppayroll: any) {
      return this.http.post(`${environment.base_url}pay-roll/run-payroll/employee`,emppayroll,httpOptions)
    }

    runpayrollbyemployeeAll(employeepayroll: any , orgId:any) {
      return this.http.post(`${environment.base_url}pay-roll/run-payroll/employee/all/${orgId}`,employeepayroll,httpOptions)
    }

      runpayrollbyemployeeType(orgId: any , id:any,employeepayroll:any,) {
      return this.http.post(`${environment.base_url}pay-roll/run-payroll/employee/organization/${orgId}/employeementtype/${id}`,employeepayroll,httpOptions)
    }

    runpayrollbyemployeeTypeClient(clientId: any , id:any,employeepayroll:any,) {
      return this.http.post(`${environment.base_url}pay-roll/run-payroll/employee/client/${clientId}/employeementtype/${id}`,employeepayroll,httpOptions)
    }
    
    runpayrollbyclientemployeeAll(employeepayroll:any, client: any) {
      return this.http.post(`${environment.base_url}pay-roll/run-payroll/wages/employee/all/${client}`,employeepayroll,httpOptions)
    }

    runpayrollbyemployeeswages(employeepayroll: { finanicalPeriod: { financialPeriodId: any; }; employees: any; client: { clientId: any; }; }) {
      return this.http.post(`${environment.base_url}pay-roll/run-payroll/wages/employees/`,employeepayroll,httpOptions)
    }

    // getpayrollbyEmployeecode(employeecode: any) {
    //   let result =  this.http.get<any>(`${environment.base_url}pay-roll/employee/${employeecode}`,httpOptions).pipe(map(data=>data))
    //   return result;
    // }
    getpayrollbyEmployeecode(employeecode: any, orgId:any) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/employee/organization/${employeecode}/${orgId}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getpayrollperiod(period: any) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/finicalperiod/${period}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getpayrollbyfinicalyear(financialyear: any) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/finicalyear/${financialyear}`,httpOptions).pipe(map(data=>data))
      return result;
    }


    getpayrollbyfinicalyearEmp(employeecode:any,financialyear: any) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/employee/finicalyear/${employeecode}/${financialyear}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getpayrollbyorg(orgId: number) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/organization/${orgId}`,httpOptions).pipe(map(data=>data))
      return result;
    }
  

    getpayrollbyEmployeecodeAndFinicalYear(employeecode: any , finicalyear :any) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/employee/finicalyear/${employeecode}/${finicalyear}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getpayrollEmployeecodeandperiod(employeecode: any , period: any) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/employee/finicalperiod/${employeecode}/${period}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getpayrollbyId(payrollId: any) {
      let result =  this.http.get<any>(`${environment.base_url}pay-roll/${payrollId}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getconsolidatebyEmployeecode(employeecode: any) {
        let result =  this.http.get<any>(`${environment.base_url}payrollreports/employee/${employeecode}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getconsolidatebyEmployeecodebyOrg(employeecode: any,orgId:any) {
      let result =  this.http.get<any>(`${environment.base_url}payrollreports/employee/${employeecode}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getconsolidatebyEmployeecodebyClient(employeecode: any,clientId:any) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/employee/${employeecode}/client/${clientId}`,httpOptions).pipe(map(data=>data))
  return result;
}

  //   getconsolidatebyEmployeecode(employeecode: any) {
  //     let result =  this.http.get<any>(`${environment.base_url}payrollreports/${employeecode}`,httpOptions).pipe(map(data=>data))
  //   return result;
  // }
    
  getconsolidateperiod(period: any) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/period/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }


  getconsolidateperiodClient(period: any,year:any, clientId) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/client/year/period/${clientId}/${year}/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getconsolidateperiodOrg(period: any,year:any, orgId) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/organization/year/period/${orgId}/${year}/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }












  getconsolidatebyfinicalyear(financialyear: any) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/financialYear/${financialyear}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getconsolidateEmployeecodeandperiod(employeecode: any, period: any) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/employee/period/${employeecode}/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getconsolidateEmployeecodeandperiodOrg(employeecode: any, period: any,year:any, orgId) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/employee/organization/year/period/${employeecode}/${orgId}/${year}/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getconsolidateEmployeecodeandperiodClient(employeecode: any, period: any,year:any, clientId) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/employee/client/year/period/${employeecode}/${clientId}/${year}/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }







  getconsolidatebyemployeeandfinicalyear(employeecode: any, financialyear: any) {
    let result =  this.http.get<any>(`${environment.base_url}payrollreports/employee/financialYear/${employeecode}/${financialyear}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  // Client WorkingDays
  // http://localhost:6015/api/enote/hrms/clientworkingdays
    createClientWorkingDays(createWorkingDays: any) 
    {
        return this.http.post(`${environment.base_url}clientworkingdays`,createWorkingDays,httpOptions)
        .pipe(map(data=>data)
        );
    }

    updateClientWorkingDays(clientWorkingDaysId:any , updateWorkingDays :any):Observable<any>
  {
    return this.http.put(`${environment.base_url}clientworkingdays/${clientWorkingDaysId}`,updateWorkingDays,httpOptions)
  }

  createEmployeeWorkingDays(empworkingDays: any) {
    return this.http.post(`${environment.base_url}employee-working-days/`,empworkingDays,httpOptions)
    .pipe(map(data=>data)
    );
  }
    getClientworkingDaysList(clientWokingdaysId:any,clientId:any) {
      let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/${clientWokingdaysId}/client/${clientId}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    // http://localhost:6015/api/enote/hrms/clientworkingdays/client/1
    // getClientList(filterclient:any,orgId:any) {
    //   let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/client/${filterclient}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    //   return result;
    // }

    getClientList(filterclient:any) {
      let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/client/${filterclient}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    getClientListByList2(client:any) {
      let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/client/${client}`,httpOptions).pipe(map(data=>data))
      return result;
    }



    // http://localhost:6015/api/enote/hrms/clientworkingdays/client/3
    // http://192.168.2.22:6015/api/enote/hrms/clientworkingdays/financial-year/2027
    // getclientworkingdaysYearList(filterfinancialyear: any,orgId:any) {
    //   let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/financial-year/${filterfinancialyear}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    //   return result;
    // }

    getclientworkingdaysYearList(filterfinancialyear: any,client:any) {
      let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/financial-year/${filterfinancialyear}/client/${client}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    // http://localhost:6015/api/enote/hrms/clientworkingdays/financial-year/7/client/3
    // clientworkingdays/financialPeriod/1010
    // getFinanicalandPeriodList(filterperiod: any,orgId:any) {
    //   let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/financialPeriod/${filterperiod}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    //   return result;
    // }

    getFinanicalandPeriodList(filterperiod: any,client:any) {
      let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/financialPeriod/${filterperiod}/client/${client}`,httpOptions).pipe(map(data=>data))
      return result;
    }
    // http://localhost:6015/api/enote/hrms/clientworkingdays/financialPeriod/139/client/3
    getClientandFinperiodList(filterclient: any, filterfinancialyear: any,filterperiod: any,clientId:any) {
      let result =  this.http.get<any>(`${environment.base_url}clientworkingdays/financial-year/${filterfinancialyear}/financialPeriod/${filterperiod}/client/${clientId}`,httpOptions).pipe(map(data=>data))
      return result;
    }

    deactiveWorkingDays(taxbreakageId:any,status):Observable<any>
  {
    return this.http.put(`${environment.base_url}tax-breakage/status/${taxbreakageId}/${status}`,null,httpOptions)
    .pipe(map(data=>data));
  }
  
  importclientworkingdaysclient(postValues:any): Observable<any>
  {
    const ImporthttpOptions = {
        headers: new HttpHeaders({
            'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
        })
    }

    let result = this.http.post(`${environment.base_url}clientworkingdays/uploadfile`,postValues,ImporthttpOptions).pipe(map(data=>data))
    return result;
  }
  updateEmployeeWorkingdays(empworkingDays: any) {
    return this.http.put(`${environment.base_url}employee-working-days/`,empworkingDays,httpOptions)
    .pipe(map(data=>data)
    );
  }


  employeeworkingDaysbyemployee(employeecode: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-working-days/employee-number/${employeecode}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  employeeworkingDaysbyEmpNumAndFinancialPeriod(employeecode: any, period: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-working-days/employee-number/${employeecode}/financial-period/${period}`,httpOptions).pipe(map(data=>data))
    return result;
  }
 
  employeeworkingDaysByEmpNumAndFinancialYear(employeecode: any, financialyear: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-working-days/employee-number/${employeecode}/financial-year/${financialyear}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  
  employeeworkingDaysByclientAndFinancialYear(client: any, financialyear: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-working-days/client/${client}/financial-year/${financialyear}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  employeeworkingDaysByclientAndFinancialPeriod(client: any, financialperiod: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-working-days/client/${client}/financial-period/${financialperiod}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  


  getEmployeeWorkingDays(employeeWorkingDaysId: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-working-days/${employeeWorkingDaysId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  EmployeeWorkingDaysFilterYear(financialyear: any,orgId:any) {
    return this.http.get(`${environment.base_url}employee-working-days/financial-year/${financialyear}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  EmployeeWorkingDaysFilterYearandPeriod(financialyear: any,period:any,orgId:any) {
    return this.http.get(`${environment.base_url}employee-working-days/financial-year/${financialyear}/financial-period/${period}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  EmployeeWorkingDaysFilteremployeeCode(employeecode: any,orgId:any) {
    return this.http.get(`${environment.base_url}employee-working-days/employee-number/${employeecode}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  EmployeeWorkingDaysFilteBoth(employeecode: any,period:any,orgId:any) {
    return this.http.get(`${environment.base_url}employee-working-days/employee-number/${employeecode}/financial-period/${period}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  deactiveEmployeeWorkingDays(employeeWorkingDaysId,status):Observable<any>
  {
    return this.http.put(`${environment.base_url}employee-working-days/status/${employeeWorkingDaysId}/${status}`,null,httpOptions)
    .pipe(map(data=>data));
  }
  employeeworingDaysupload(formData: FormData) {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }
}
// EmployeeWorkingDaysImport Api
importEmployeeworkingdays(formData:any): Observable<any>
  {
    const ImporthttpOptions = {
        headers: new HttpHeaders({
          
            'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
        })
    }

  let result = this.http.post(`${environment.base_url}employee-working-days/data/import`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }

  importEmployeeworkingdayswithelements(formData: FormData) {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
        
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

let result = this.http.post(`${environment.base_url}employee-working-days/data/elements/import`,formData,ImporthttpOptions).pipe(map(data=>data))
return result;
  }

 
  getelementsbyorgnull() {
    return this.http.get(`${environment.base_url}hrmspaySlipElement/active/organization/default/`,httpOptions)
    .pipe(map(data=>data));
  }
  getCalculateByorg(orgId:any){
    return this.http.get(`${environment.base_url}hrmspaySlipElement/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getCalculateByClient(clientId:any){
    return this.http.get(`${environment.base_url}hrmspaySlipElement/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getorgelementformula(orgId: number) {
    return this.http.get(`${environment.base_url}element-client-assign/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getElementAssignByOrg(Id: number) {
    return this.http.get(`${environment.base_url}element-client-assign/${Id}`,httpOptions)
    .pipe(map(data=>data));
  }


  createClientelement(clientelement: any) {
    let result = this.http.post(`${environment.base_url}element-client-assign/client`,clientelement,httpOptions).pipe(map(data=>data))
    return result;
  }




  createOrganizationElement(orgDetails:any){
    let result = this.http.post(`${environment.base_url}element-client-assign/organization`,orgDetails,httpOptions).pipe(map(data=>data))
    return result;
  }

  // http://localhost:6015/api/enote/hrms/element-client-assign/organization

  // clientelements(client: any) {
  //   return this.http.get(`${environment.base_url}element-client-assign/client/${client}`,httpOptions)
  //   .pipe(map(data=>data));
  // }
  
  clientelements(client: any) {
    return this.http.get(`${environment.base_url}element-client-assign/client/${client}`,httpOptions)
    .pipe(map(data=>data));
  }

  searchOrganizationElements(orgId:number){
    return this.http.get(`${environment.base_url}element-client-assign/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  // http://localhost:6015/api/enote/hrms/element-client-assign/organization/1

  organizationelements(client: any) {
    return this.http.get(`${environment.base_url}element-client-assign/clients/${client}`,httpOptions)
    .pipe(map(data=>data));
  }

  // http://localhost:6015/api/enote/hrms/element-client-assign/organization/1
  getclientelementbyId(clientElementId: any) {
    return this.http.get(`${environment.base_url}element-client-assign/${clientElementId}`,httpOptions)
    .pipe(map(data=>data));
  }

  updateClientelement(clientelement:any, clientElementId: any) {
    let result = this.http.put(`${environment.base_url}element-client-assign/client/${clientElementId}`,clientelement,httpOptions).pipe(map(data=>data))
    return result;
  }
  // http://localhost:6015/api/enote/hrms/element-client-assign/client/1
  updateOrganizationelement(orgelement:any, orgElementId: any) {
    let result = this.http.put(`${environment.base_url}element-client-assign/organization/${orgElementId}`,orgelement,httpOptions).pipe(map(data=>data))
    return result;
  }
  // http://localhost:6015/api/enote/hrms/element-client-assign/organization/1

  runpayrollbyemployeewages(employeepayroll: any) {
    let result = this.http.post(`${environment.base_url}pay-roll/wages/run-payroll/employee/`,employeepayroll,httpOptions).pipe(map(data=>data))
    return result;
  }

  getpayrollbyEmployeeandclient(employeeId: number, client: any) {
    return this.http.get(`${environment.base_url}pay-roll/employee/${employeeId}/client/${client}`,httpOptions)
    .pipe(map(data=>data));
  }


  searchRevenueAdjustmentByEmployeeandclient(employeeId: any, client: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employeeId}/client/${client}`,httpOptions)
    .pipe(map(data=>data));
  }

  importemployeerevenueadjustment(formData: any): Observable<any> {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
        
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

let result = this.http.post(`${environment.base_url}employee-revenue-adjustment/data/import/file`,formData,ImporthttpOptions).pipe(map(data=>data))
return result;
  }
  importemployeerevenueadjustmentbyClient(formData: any): Observable<any> {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
        
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

let result = this.http.post(`${environment.base_url}employee-revenue-adjustment/data/import/file/client`,formData,ImporthttpOptions).pipe(map(data=>data))
return result;
  }

  EmployeeWorkingDaysFilteremployeeId(employeeId: number) {
    return this.http.get(`${environment.base_url}employee-working-days/employee/${employeeId}/`,httpOptions)
    .pipe(map(data=>data));
  }

  EmployeeWorkingDaysFilteBothId(employeeId: number, period: any) {
    return this.http.get(`${environment.base_url}employee-working-days/employee/${employeeId}/financial-period/${period}/`,httpOptions)
    .pipe(map(data=>data));
  }

  employeeworkingDaysByEmpIdAndFinancialYear(employeeId: any, financialyear: any) {
    return this.http.get(`${environment.base_url}employee-working-days/employee/${employeeId}/financial-year/${financialyear}`,httpOptions)
    .pipe(map(data=>data))
  }

  listRevenueAdjustmentByclientEmployeeAndFinancialYear(client: any, employeeId: any, financialyear: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employeeId}/client/${client}/financial-year/${financialyear}/`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByClientEmployeeAndFinancialPeriod(client: any, employeeId: any, period: any) {
    return this.http.get(`${environment.base_url}employee-revenue-adjustment/employee/${employeeId}/client/${client}/financial-period/${period}/`,httpOptions)
    .pipe(map(data=>data));
  }

  listRevenueAdjustmentByorganziation(orgId: any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-revenue-adjustment/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }


  getemployeeclient(client: any) {
    return this.http.get(`${environment.base_url}employee/client/${client}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClienEmployeeList(employee: any, client: any) {
    return this.http.get(`${environment.base_url}employee/employeenumber/client/${employee}/${client}`,httpOptions)
    .pipe(map(data=>data));
  }

  getemployeeannexureByclientAndFromdateAndTodate(client: any, startDate: string, endDate: string) {
    return this.http.get(`${environment.base_url}employee-annexure/client/${client}/from-date/${startDate}/to-date/${endDate}`,httpOptions)
    .pipe(map(data=>data));
  }



  gettaxbreakupbyclient(filterclient: any) {
    return this.http.get(`${environment.base_url}tax-breakage/client/${filterclient}/`,httpOptions)
    .pipe(map(data=>data));
  }


  createClientTaxBreakup(createtaxbreakage: any) {
    return this.http.post(`${environment.base_url}tax-breakage/`,createtaxbreakage, httpOptions)
    .pipe(map(data=>data));
  }

  gettaxbreagebyId(taxbreakageId: number) {
    return this.http.get(`${environment.base_url}tax-breakage/${taxbreakageId}/`,httpOptions)
    .pipe(map(data=>data));
  }

  updateClientTaxBreakage(taxbreakageId: number, updateclienttaxbreakage: any) {
    return this.http.put(`${environment.base_url}tax-breakage/${taxbreakageId}/`, updateclienttaxbreakage, httpOptions)
    .pipe(map(data=>data));
  }

  getOtReportByEmployeeId(employeeId: number, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/ot/employee/period/${employeeId}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
 
  getOtReportByClientId(clienId: number, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/ot/client/period/${clienId}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEsiCallanByEmployeeId(employeeId: number, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/esi/employee/period/${employeeId}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEsiCallanByEmployeeIdOrg(employeeId: number, period: any, year:any, orgId) {
    return this.http.get(`${environment.base_url}payrollreports/esi/employee/organization/year/period/${employeeId}/${orgId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEsiCallanByEmployeeIdClient(employeeId: number, period: any, year:any, clientId) {
    return this.http.get(`${environment.base_url}payrollreports/esi/employee/client/year/period/${employeeId}/${clientId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEsiCallanReportByClientId(clienId: number, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/esi/client/period/${clienId}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEsiCallanReportByorgYear(orgId: number,year:any, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/esi/organization/year/period/${orgId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getEsiCallanReportByClientYear(clientId: number, year:any,period: any) {
    return this.http.get(`${environment.base_url}payrollreports/esi/client/year/period/${clientId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  

  getPFCallanByEmployeeId(employeeId: number, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/pf/employee/period/${employeeId}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  getPFCallanReportByClientId(client: any, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/pf/client/period/${client}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getNetPayCallanReportByClientId(client: any, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/netpay/client/period/${client}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
  getNetPayCallanByEmployeeId(employeeId: number, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/netpay/employee/period/${employeeId}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getNetPayCallanByEmployeeIdOrg(employeeId: number, period: any, year:any, orgId) {
    return this.http.get(`${environment.base_url}payrollreports/netpay/employee/organization/year/period/${employeeId}/${orgId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getNetPayCallanByEmployeeIdClient(employeeId: number, period: any, year:any, clientId) {
    return this.http.get(`${environment.base_url}payrollreports/netpay/employee/client/year/period/${employeeId}/${clientId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }
 

  getNetPayCallanReportPeriodYear(organizationId :any ,year:any, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/netpay/organization/year/period/${organizationId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getNetPayCallanReportPeriodClientYear(clientId :any ,year:any, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/netpay/client/year/period/${clientId}/${year}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }



  getNetPayCallanByorgEmployeeId(employeeId: number, period: any) {
    return this.http.get(`${environment.base_url}payrollreports/netpay/employee/period/${employeeId}/${period}`,httpOptions)
    .pipe(map(data=>data));
  }

  getorgcompbyorganziationId(orgId: number) {
    return this.http.get(`${environment.base_url}organization-compliance/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getorgcompbyclientId(clientId: number) {
    return this.http.get(`${environment.base_url}organization-compliance/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }


  getorgcompbyId(orgcomplianceid: any) {
    return this.http.get(`${environment.base_url}organization-compliance/${orgcomplianceid}`,httpOptions)
    .pipe(map(data=>data));
  }

  createOrganizationcomplaice(organizationcomplaice:any) {
    return this.http.post(`${environment.base_url}organization-compliance/`,organizationcomplaice , httpOptions)
    .pipe(map(data=>data));
  }

  updateOrganizationcomplaice(organizationcomplaice:any, orgcomplianceid: any) {
    return this.http.put(`${environment.base_url}organization-compliance/organizationCompaliance/${orgcomplianceid}`,organizationcomplaice , httpOptions)
    .pipe(map(data=>data));
  }
  
 

  getorgcompactivebyorganziationId(orgId: any) {
    return this.http.get(`${environment.base_url}organization-compliance/active/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getorgcompactivebyClientId(clientId: any) {
    return this.http.get(`${environment.base_url}organization-compliance/active/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }
 

  importEmployeeratings(formData: FormData) {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
        
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

let result = this.http.post(`${environment.base_url}employee-rating/data/import`,formData,ImporthttpOptions).pipe(map(data=>data))
return result;
  }


  getworkingdaysYearList(filterfinancialyear: any, orgId: number) {
    return this.http.get(`${environment.base_url}organizationWorkingDays/financial-year/${filterfinancialyear}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientworkingdaysYearList(filterfinancialyear: any, clientId: number) {
    return this.http.get(`${environment.base_url}organizationWorkingDays/financial-year/${filterfinancialyear}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getWorkingDaysListOnlyByOrganization(orgId:number){
    return this.http.get(`${environment.base_url}organizationWorkingDays/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }
  // http://localhost:6015/api/enote/hrms/organizationWorkingDays/organization/1

  getorgFinanicalandPeriodList(filterperiod: any, orgId: number) {
    return this.http.get(`${environment.base_url}organizationWorkingDays/financialPeriod/${filterperiod}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientFinanicalandPeriodList(filterperiod: any, clientId: number) {
    return this.http.get(`${environment.base_url}organizationWorkingDays/financialPeriod/${filterperiod}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }

  updateorgWorkingDays(orgWorkingDaysId: number, updateWorkingDays:any) {
    return this.http.put(`${environment.base_url}organizationWorkingDays/${orgWorkingDaysId}`,updateWorkingDays,httpOptions)
  }
  createorgWorkingDays(createWorkingDays: any) {
    return this.http.post(`${environment.base_url}organizationWorkingDays`,createWorkingDays,httpOptions)
  }


  getorgWorkingDaysList(orgWorkingDaysId: number) {
    return this.http.get(`${environment.base_url}organizationWorkingDays/${orgWorkingDaysId}`,httpOptions)
  }
 
  getorgWorkingDaysbyorganList(orgId: number) {
    return this.http.get(`${environment.base_url}organizationWorkingDays/organization/${orgId}`,httpOptions)
  }


  getifreportByclient(client: any, fromperiod: any, toperiod: any) {
    return this.http.get(`${environment.base_url}payrollreports/if/client/period/${client}/${fromperiod}/${toperiod}`,httpOptions)
    .pipe(map(data=>data));
  }





  getBusinessUnit(id:any){
    return this.http.get(`${environment.base_url}active/businessUnit/entity/${id}`,httpOptions)
    .pipe(map(data=>data));  }

    getBusinessGroup(id:any){
      return this.http.get(`${environment.base_url}businessGroup/active/entity/${id}`,httpOptions)
      .pipe(map(data=>data));  }


      getOrganization(id:any){
        return this.http.get(`${environment.base_url}organization/active/entity/${id}`,httpOptions)
        .pipe(map(data=>data));  }


  
 getBusinessGroupandLevelName(LevelName:any,) {
    let result =  this.http.get<any>(`${environment.base_url}employee-level-assign/employeelevelAssign/businessgroup/${LevelName}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getBusinessUnitandLevelName(LevelName:any,) {
    let result =  this.http.get<any>(`${environment.base_url}employee-level-assign/employeelevelAssign/businessunit/${LevelName}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getOrganizationandLevelName(LevelName:any,) {
    let result =  this.http.get<any>(`${environment.base_url}employee-level-assign/employeelevelAssign/organization/${LevelName}`,httpOptions).pipe(map(data=>data))
    return result;
  }


  getEmpCodeandBusinessGroupandLevelName(LevelName:any,empid:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-level-assign/employeelevelAssign/businessgroup/${LevelName}/employee/${empid}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getEmpCodeandBusinessUnitandLevelName(LevelName:any,empid:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-level-assign/employeelevelAssign/businessunit/${LevelName}/employee/${empid}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getEmpCodeandOrganizationandLevelName(LevelName:any,empid:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-level-assign/employeelevelAssign/organization/${LevelName}/employee/${empid}`,httpOptions).pipe(map(data=>data))
    return result;
  }


  getOnlyEmployee(empid:any) {
    let result =  this.http.get<any>(`${environment.base_url}employee-level-assign/employeelevelAssign/employee/${empid}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getOrgFilterList(orgId:any) {
    let result =  this.http.get<any>(`${environment.base_url}employeeclientassign/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getOrgandDateBasedFilterList(orgId:any,startdate:any){
    let result =this.http.get<any>(`${environment.base_url}employeeclientassign/organization/startdate/${orgId}/${startdate}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  OrgAssignmrntcreate(data: any) {
    return this.http.post(`${environment.base_url}employeeclientassign`,data,httpOptions)
  }
  OrgAssignmentUpdate(orgId: number, data:any) {
    return this.http.put(`${environment.base_url}organizationWorkingDays/${orgId}`,data,httpOptions)
  }
  gettaxbreakupbyOrg(orgId:any){
    let result =this.http.get<any>(`${environment.base_url}tax-breakage/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  createOrgTaxBreakup(data:any){
    return this.http.post(`${environment.base_url}tax-breakage/organization/`,data,httpOptions)

  }
  updateOrgTaxBreakage(taxbreakId: number, data:any) {
    return this.http.put(`${environment.base_url}tax-breakage/organization/taxBreakage/${taxbreakId}`,data,httpOptions)
  }
  getEmpClientAssignGet(Id:any){
    let result =this.http.get<any>(`${environment.base_url}employeeClientAssign/${Id}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  updateClientEmpAssign(assignId: number, data:any) {
    return this.http.put(`${environment.base_url}updateEmployeeClient/${assignId}`,data,httpOptions)
  }
}
