<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">{{calendarName}}</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/shift-calendar" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
     
     
     <div class="common-content" style="padding-left: 10px;padding-right: 10px;">

        <!-- <div class="row" *ngIf="userType != 'Employee'">
          <div class="col-sm-6">
            <button (click)="exportAsXLSX()" class="btn btn-info mb-4 mt-2">Download Holiday Excel</button>
          </div>
          <div class="col-sm-6" align="end">
            <button (click)="exportAsExceptionXLSX()" class="btn btn-info mb-4 mt-2 ml-5">Download Exception Excel</button>
          </div>
        </div> -->
       
        <mat-card class="large-calendar">
          <full-calendar #calendar 
            #fullcalendar min="startYear" max="endYear"
            *ngIf="calendarVisible"
            [options]="calendarOptions"
            plugins="calendarOptions.plugins"
          ></full-calendar> 
  
  <!-- 
          <h1 style="text-align: center">Intercept 'prev' and 'next' buttons</h1>
            <div id='calendar'></div> -->
        </mat-card>
  
      </div>
  
 
  