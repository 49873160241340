
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Recovery Adjustment</span>
                </div> 
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                        <form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Element Category<span class="star"> *</span></label>
                                    <select formControlName="category" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                        <!-- <option value="null"> -- Select Category --- </option> -->
                                        <!-- <option value="deductions" selected>Deductions</option>
                                        <option value="earnings">Earnings</option>
                                        <option value="both">Both</option> -->
                                       <option *ngFor="let row of categoryTypeData; let i=index;" value="{{row.listTypeValueId}}" >{{row.listTypeValueName}}</option>
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.category.invalid && validate.category.touched) || validate.category.dirty || submitted">
                                        <span *ngIf="validate.category.errors?.required" class="text-danger">Please select category</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Element Name<span class="star"> *</span></label>
                                    <mat-select  formControlName="element" class="form-control email-input select-placeholder" >
                                        <mat-option [value]="undefined" > -- Select Element Name -- </mat-option>
                                        <mat-option *ngFor="let row of eleNameList; let i=index;" value="{{row.elementId}}">{{row.elementName}}</mat-option>
                                    </mat-select>
                                    <div class="valid-msg" *ngIf="(validate.element.invalid && validate.element.touched) || validate.element.dirty || submitted">
                                        <span *ngIf="validate.element.errors?.required" class="text-danger">Please Select Element Name</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Client Name<span class="star">*</span></label> 
                                    <select formControlName="client"  class="form-select form-select-solid form-select-lg select2-new" (change)="getclientEmployees()">
                                        <option [value]="null"> --- Select Client --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>    
                                    <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                        <span *ngIf="validate.client.errors?.required" class="text-danger">Please Select Client</span>
                                        <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                        search
                                    </mat-icon> -->
                                    <label class="col-form-label">Employee<span class="star">*</span></label>
                                    <input type="text"matInput  placeholder="Search Employee"   
                                                formControlName="employee"
                                                [matAutocomplete]="auto" [readonly]="!recoveryForm.value.client || recoveryForm.value.client == 'null'"
                                                class="form-control" (keyup)="empSearch($event)">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                                </mat-option> -->
                                            <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                            {{emp.employeeNumber}}- {{emp.firstName}}  
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                            search
                                        </mat-icon>
                                    <div class="valid-msg" *ngIf="submitted">        
                                        <span *ngIf="validate.employee.errors?.required" class="text-danger">Please Select Employee</span> 
                                    </div>
                                </div>
                                <!-- <div class="form-group col-md-3">
                                    <label class="col-form-label">Employee Code<span class="star"> *</span></label>
                                    <input type="text" placeholder="Search Employee Code" 
                                    formControlName="employeecode"
                                    [matAutocomplete]="auto" 
                                    class="form-control designchange" (keyup)="empSearch($event)" (keydown)="onKeyDown($event)">
                                    <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option value="All"  (click)="empAllSelect()">All</mat-option>
                                    <mat-option *ngFor="let emp of empList" [value]="emp.employeeNumber"  (click)="empSelect(emp)">
                                        {{emp.employeeNumber}} - {{emp.firstName}}
                                    </mat-option>
                                    <mat-option *ngIf="empCode">Employee Code Not Found</mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 20px;">
                                        search
                                    </mat-icon>
                                    <div class="valid-msg" *ngIf="(validate.employeecode.invalid && validate.employeecode.touched) || validate.employeecode.dirty || submitted">
                                        <span *ngIf="validate.employeecode.errors?.required" class="text-danger">Please Select Employee Code</span>
                                    </div>
                                </div> -->
                            </div> 
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Financial Year<span class="star"> *</span></label>
                                    <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                        <option [ngValue]="null"> -- Select Financial Year --- </option>
                                        <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.financialyear.invalid && validate.financialyear.touched) || validate.financialyear.dirty || submitted">
                                        <span *ngIf="validate.financialyear.errors?.required" class="text-danger">Please Select Financial Year</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Period<span class="star"> *</span></label>
                                    <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                        <option [ngValue]="null"> -- Select Period --- </option>
                                        <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.period.invalid && validate.period.touched) || validate.period.dirty || submitted">
                                        <span *ngIf="validate.period.errors?.required" class="text-danger">Please Select Period</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Remarks<span class="star"></span></label>
                                    <input type="text" 
                                    formControlName="remarks"
                                    class="form-control designchange">
                                    <!-- <div class="valid-msg" *ngIf="(validate.remarks.invalid && validate.remarks.touched) || validate.remarks.dirty || submitted">
                                        <span *ngIf="validate.remarks.errors?.required" class="text-danger">Please select remarks</span>
                                    </div> -->
                                </div>
                            </div> 
                            <div class="row pb-3" *ngIf="selectedEmployeeData.length != 0 || empratingId != null">
                                <div class="col-md-11 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-1 text-left pl-0">
                                    <select [(ngModel)]="pagePerItem" [ngModelOptions]="{standalone: true}"  (change)="onItemsPerPageChange($event)" class="form-control report-filter">
                                        <option value="10" selected>10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                           </div>
                             <table class="mt-2 table  --table-bordered table-border-new"  *ngIf="selectedEmployeeData.length != 0 || empratingId != null">
                                <thead class="t-head">
                                    <tr>
                                        <th class="col-md-2">Action</th>
                                        <th class="col-md-3">Employee Code</th>
                                        <th class="col-md-3">Employee Name</th>
                                        <th class="col-md-4">Amount</th>
                                    </tr>
                                </thead> 
                                <tbody>
                                    <ng-container formArrayName="Recovery" *ngFor="let group of getFormControls.controls  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                        <ng-container *ngIf="p==1; else nextpage">
                                            <tr  formGroupName="{{ i }}">
                                                <td class="text-center" style="position: relative; bottom: 5px;">
                                                        <button mat-menu-item type="button" (click)="deleteemployee(i)">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                </td>
                                                <td>{{group.value.employeeNumber}}</td>
                                                <td>{{group.value.firstName}} {{group.value.lastName}}</td>
                                                    <td>
                                                        <input type="text" formControlName="amount" class="form-control designchange" (keypress)="onlyNumber($event)"><br>
                                                                  <mat-error
                                                                  *ngIf="submitted || amountFormArray.controls[i].get('amount')?.touched && amountFormArray.controls[i].get('amount')?.invalid">
                                                                  <span
                                                                      *ngIf="amountFormArray.controls[i].get('amount')?.hasError('required')">Please
                                                                      Enter Amount.</span>
                                                                  <span
                                                                      *ngIf="amountFormArray.controls[i].get('amount')?.hasError('notZero')">
                                                                      Please Enter Vaild Amount</span>
                                                              </mat-error>
                                                    </td>
                                                
                                            </tr>
                                          </ng-container>
                                          <ng-template #nextpage>
                                            <tr  formGroupName="{{ i + startIndex }}">
                                                <td class="text-center" style="position: relative; bottom: 5px;">
                                                        <button mat-menu-item type="button" (click)="deleteemployee(i)">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                </td>
                                                <td>{{group.value.employeeNumber}}</td>
                                                <td>{{group.value.firstName}} {{group.value.lastName}}</td>
                                                <td>
                                                        <input type="text" formControlName="amount" class="form-control designchange" (keypress)="onlyNumber($event)"><br>
                                                                  <mat-error
                                                                  *ngIf="submitted || amountFormArray.controls[i + startIndex].get('amount')?.touched && amountFormArray.controls[i + startIndex].get('amount')?.invalid">
                                                                  <span
                                                                      *ngIf="amountFormArray.controls[i + startIndex].get('amount')?.hasError('required')">Please
                                                                      Enter Amount.</span>
                                                                  <span
                                                                      *ngIf="amountFormArray.controls[i + startIndex].get('amount')?.hasError('notZero')">
                                                                      Please Enter Vaild Amount</span>
                                                              </mat-error>
                                                </td>
                                                
                                            </tr>
                                          </ng-template>
                                       
                                       
                                    </ng-container>
                                </tbody>
                             
                                <!-- <tbody>
                                    <tr>
                                        <td style="text-align: center;" colspan="5" *ngIf="noRecordsFound">No matching records found!</td>
                                    </tr>
                                </tbody>  -->
                             </table> 
                             <div *ngIf="selectedEmployeeData?.length > 0 ">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="showing-record">
                                            {{selectedEmployeeData.length}} rows
                                        </div>
                                    </div>
                                    <div class="col-md-8 text-right">
                                        <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-md-12 text-right">
                                    <button type="button" class="submit action-button btn mr-2 btn-sm" mat-raised-button [routerLink]="['home/employee-rating-list']"
                                        style="margin-right: 20px;" (click)="close()">Cancel</button>
                                    <button type="submit" class="submit action-button btn btn-primary btn-sm">Save</button>
                                </div>
                            </div>  

                        </form>     
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>