import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { ShowerrorComponent } from '../showerror/showerror.component';
// import { PayrollService } from '../../providers/payroll.service';
// import { SettingsService } from '../../providers/settings.service';
// import { ShowerrorComponent } from '../../showerror/showerror.component';

@Component({
  selector: 'app-organization-elements',
  templateUrl: './organization-elements.component.html',
  styleUrls: ['./organization-elements.component.scss']
})
export class OrganizationElementsComponent implements OnInit {

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  organizationList:any[]=[];
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any[] = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  leaveId: any;
  req: boolean=false;
  startIndex: number;
  entityId:number;
  buttonDisabled:boolean= false;
  tableShow:boolean =  false;
  clientLogin: boolean = false;
  organizationLogin: boolean = false;
  entityAdminLogin: boolean = false;
  beforesearchpage:any;
    mySelect: number = 5;

orgName: any;
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private dialog:MatDialog,
    private router:Router) { }

  ngOnInit(): void {
    this.noRecordsFound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.entityId = this.usertData.entity.entityId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.orgName = this.usertData.organization.organizationName;


    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;
      this.orgId = this.usertData.organization.organizationId;

    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;

     this.clientId = this.usertData?.employee?.client?.clientId;

    } else {
      this.entityAdminLogin = true;

    }

    this.filterForm = this.fb.group({
      organization:[null],
      // financialyear:[null],
      // period:[null],
    })

    this.payrollservice.getOrganizationNameListBasedEntity( this.entityId).subscribe((result: any) => {
      this.organizationList =  result.filter(organization =>organization?.organizationStatus?.listTypeValueId == 1);;
      console.log(this.organizationList);
      // this.organizationList = result;

      // if(this.organizationLogin == true){
      //   this.filterForm.get('organization')?.patchValue(String(this.orgName));
      //   this.filterForm.get('organization')?.disable();
      // }
    
    });  
    if(this.organizationLogin == true){
      this.filterForm.get('organization')?.patchValue(String(this.orgName));
      this.filterForm.get('organization')?.disable();
      console.log('Filterorg Disabled:', this.filterForm);
    }
  }

  pageChages()
  {
      console.log("page changes");
      this.p=1;
  }
  changefirstpage() {
    this.p = 1;

  }
  assignclientelements()
  {
    console.log("create emp  leave");
    this.router.navigateByUrl('/home/organizatio-Assignemnt');
  }
  searchSubmit()
  {
    console.log("submit",this.filterForm.value);
    // this.req=false;
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   // alert('Please enter employee number');
    //   return;
    // }
    if((this.filterForm.value.organization=== '' || this.filterForm.value.organization === null))
    {
      console.log("Required Field");
       this.resultData =[];
       this.noRecordsFound = true;
       alert("Please Select Organization");
       return;
      // this.req=true;
    }

 
    console.log(this.filterForm.value);

   
    if(!(this.filterForm.value.organization === '' || this.filterForm.value.organization === null))
    {
      console.log("both");
      this.buttonDisabled =  true;
      this.loader =  true;
      this.tableShow = false;
      this.payrollservice.searchOrganizationElements(this.orgId).subscribe((resultData:any) =>
      {

        console.log('resultData',resultData);
        this.buttonDisabled =  false;
        this.noRecordsFound = false;
        this.loader =  false;
        this.resultData = resultData;
       
        this.tableShow = true;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    
  }
  // pageChages()
  // {
  //   console.log("page changes");
  //   this.p=1;
  // }\
  // onItemsPerPageChange(event: any) {
  //   this.pagePerItem = event.target.value;
  //   this.p = 1; // Reset the pagination to the first page when items per page is changed
  // }

  clearTable()
  {
    this.resultData = [];
    console.log('clear')
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
    this.tableShow =  false;
    const currentOrganizationName =this.orgName;
    console.log("currentOrganizationName",currentOrganizationName);
    

    this.filterForm.patchValue({
      organization: currentOrganizationName, // Preserve the current client name
              // Reset other fields
    });
    this.filterForm.get('organization')?.disable();
  }

  // pageChages(event)
  // {
  //   console.log('event.target.value', event.target.value);
  //   if(event.target.value != null || event.target.value != 0){
  //     // this.p = event.target.value;
  //     console.log("page changes");
  //     this.p=1;
  //   }
    
  // }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear(event)
  {
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    console.log('this.chooseFinId == null',this.chooseFinId == null)
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else{
      console.log('else');
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
        this.filterForm.patchValue({
          period: null,
        });
      });
    }
  
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.leaveId=row.employeerattingId;
    console.log(this.leaveId);
    this.router.navigate(['/home/edit-employee-leave/', this.leaveId])
  }
  get validate() { return this.filterForm.controls; }

}
