<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">My Profile</span>
        </div>
        <!-- <div class="sub-header-buttons">
                    <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                    <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                        Add Employee
                    </a>
                </div> -->
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="card-new">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-md-2"
                *ngIf="
                  resultData.employee && resultData.employee.employmentType
                "
              >
                <div
                  class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
                >
                  <!-- <img class="profile-image" src="{{fileblob}}" alt="Profile Image">
                                    -->
                  <span *ngIf="fileblob">
                    <img
                      class="profile-image"
                      src="{{ fileblob }}"
                      alt="Profile Image"
                    />
                  </span>

                  <span *ngIf="!fileblob">
                    <img
                      class="profile-image"
                      src="assets/uploads/load.png"
                      alt="Profile Image"
                    />
                  </span>
                  <!--  <a class="edit-icon" routerLink="#"><mat-icon class="edit-profile">edit</mat-icon></a>
                                    -->
                </div>
              </div>

              <div class="col-md-10">
                <div
                  class="profile-name mb-2"
                  class="col-md-8"
                  *ngIf="
                    resultData.employee && resultData.employee.employmentType
                  "
                >
                <div class="row">
                  <span
                    class="profile-icons"
                    >
                    <mat-icon class="profile-mat-icon"
                        >person</mat-icon>
                    {{ resultData.employee.firstName ? resultData.employee.firstName : "" }}
                    {{ resultData.employee.middleName ? resultData.employee.middleName : "" }}
                    {{ resultData.employee.lastName ? resultData.employee.lastName : "" }}
                  </span>
                </div >

                </div>
                <div
                  class="profile-title"
                  class="col-md-8"
                  *ngIf="
                    resultData.employee && resultData.employee.employmentType
                  "
                >
                  <div class="row">
                    <span
                      class="profile-icons"
                      *ngIf="resultData.employee.designation"
                      ><mat-icon class="profile-mat-icon"
                        >account_circle</mat-icon
                      >
                      {{
                        resultData.employee.designation.designationName
                      }}</span
                    >
                    <span
                      class="profile-icons"
                      *ngIf="resultData.employee.address"
                      ><mat-icon class="profile-mat-icon">location_on</mat-icon>
                      {{ resultData.employee.address }}</span
                    >
                    <span
                      class="profile-icons"
                      *ngIf="resultData.employee.organizationEmail"
                      ><mat-icon class="profile-mat-icon">email</mat-icon>
                      {{ resultData.employee.organizationEmail }}</span
                    >
                  </div>
                </div>

                <div class="profile-tabs mt-2">
                  <div class="row">
                    <form
                    [formGroup]="changePasswordSubmitForm"
                    (ngSubmit)="changePasswordSubmit()"
                  >
                   
                    <!-- <div class="card passsowrd-style">
                      <div class="card-body">
                        <h2>Change Password</h2>
                        <div class="row">
                          <div class="form-group col-md-4 current-password">
                            <label class="col-form-label"
                              >Current Password <span class="star">*</span></label
                            >
                            <input
                              type="{{ showPassword == false ? 'password' : 'text' }}"
                              (keyup)="checkCurrentPassword()"
                              formControlName="password"
                              [ngClass]="{ 'is-invalid': change_password_submitted }"
                              id="password"
                              placeholder="Current Password"
                              class="form-control email-input select-placeholder mb-3 mb-lg-0"
                              value=""
                              autocomplete="off"
                            />
                            <span class="show-hide ">
                              <mat-icon
                                matSuffix
                                style="cursor: pointer"
                                (click)="toggleShowPassword(1)"
                              >
                                {{ !showPassword ? "visibility" : "visibility_off" }}
                              </mat-icon>
                            </span>
                         
                            <div
                              class="valid-msg"
                              *ngIf="
                                (change_password_validate.password.invalid &&
                                  change_password_validate.password.touched) ||
                                change_password_validate.password.dirty ||
                                change_password_submitted
                              "
                            >
                              <span
                                *ngIf="
                                  change_password_validate.password.errors?.required
                                "
                                class="text-danger"
                                >Please enter current password</span
                              >
                              <span
                                *ngIf="currentPasswordExistStatus"
                                class="text-danger"
                              >
                                {{ currentPasswordExistMessage }}</span
                              >
                            </div>
                          </div>
        
                          <div class="form-group col-md-4 current-password">
                            <label class="col-form-label"
                              >New Password <span class="star">*</span></label
                            >
                            <input
                              type="{{
                                showNewPassword === false ? 'password' : 'text'
                              }}"
                              formControlName="new_password"
                              id="new_password"
                              [ngClass]="{ 'is-invalid': change_password_submitted }"
                              placeholder="New Password"
                              autocomplete="off"
                              class="form-control email-input select-placeholder mb-3 mb-lg-0"
                              value=""
                            />
                            <span class="show-hide">
                              <mat-icon
                                matSuffix
                                style="cursor: pointer"
                                (click)="toggleShowPassword(2)"
                              >
                                {{ !showNewPassword ? "visibility" : "visibility_off" }}
                              </mat-icon>
                            </span>
                           
                            <div
                              class="valid-msg"
                              *ngIf="
                                (change_password_validate.new_password.invalid &&
                                  change_password_validate.new_password.touched) ||
                                change_password_validate.new_password.dirty ||
                                change_password_submitted
                              "
                            >
                              <span
                                *ngIf="
                                  change_password_validate.new_password.errors?.required
                                "
                                class="text-danger"
                                >Please enter new password</span
                              >
                            </div>
                          </div>
                        </div>
        
                        <div class="row">
                          <div class="form-group col-md-4 current-password">
                            <label class="col-form-label"
                              >New Confirm Password <span class="star">*</span></label
                            >
                            <input
                              type="{{
                                showConfirmPassword === false ? 'password' : 'text'
                              }}"
                              formControlName="confirm_new_password"
                              id="confirm_new_password"
                              [ngClass]="{ 'is-invalid': change_password_submitted }"
                              placeholder="Confirm New Password"
                              class="form-control email-input select-placeholder mb-3 mb-lg-0"
                              autocomplete="off"
                              value=""
                            />
                            <span class="show-hide">
                              <mat-icon
                                matSuffix
                                style="cursor: pointer"
                                (click)="toggleShowPassword(3)"
                              >
                                {{
                                  !showConfirmPassword ? "visibility" : "visibility_off"
                                }}
                              </mat-icon>
                            </span>
                           
                            <div
                              class="valid-msg"
                              *ngIf="
                                (change_password_validate.confirm_new_password
                                  .invalid &&
                                  change_password_validate.confirm_new_password
                                    .touched) ||
                                change_password_validate.confirm_new_password.dirty ||
                                change_password_submitted
                              "
                            >
                              <span
                                *ngIf="
                                  change_password_validate.confirm_new_password.errors
                                    ?.required
                                "
                                class="text-danger"
                                >Please enter confirm new password</span
                              >
                              <span
                                *ngIf="
                                  change_password_validate.confirm_new_password.errors
                                    ?.mustMatch
                                "
                                class="text-danger"
                                >Passwords must match</span
                              >
                            </div>
                          </div>
                        </div>
        
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <button mat-raised-button class="searchBtn" [disabled]="changePasswordSubmitForm.invalid || !updatePasswordDisable">
                              Update Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="card password-style">
                      <div class="card-body">
                        <h2>Change Password</h2>
                        <div class="row">
                          <div class="form-group col-md-4 current-password">
                            <label class="col-form-label">Current Password <span class="star">*</span></label>
                            <div class="input-container">
                              <input
                                type="{{ showPassword == false ? 'password' : 'text' }}"
                                (keyup)="checkCurrentPassword()"
                                (keydown)="preventSpace($event)"
                                formControlName="password"
                                [ngClass]="{ 'is-invalid': change_password_submitted }"
                                id="password"
                                placeholder="Current Password"
                                class="form-control email-input select-placeholder mb-3 mb-lg-0"
                                autocomplete="off"
                              />
                              <mat-icon
                                matSuffix
                                class="visibility-icon"
                                (click)="toggleShowPassword(1)"
                              >
                                {{ !showPassword ? "visibility" : "visibility_off" }}
                              </mat-icon>
                            </div>
                            <div
                              class="valid-msg"
                              *ngIf="(change_password_validate.password.invalid &&
                                      change_password_validate.password.touched) ||
                                      change_password_validate.password.dirty ||
                                      change_password_submitted"
                            >
                              <span
                                *ngIf="change_password_validate.password.errors?.required"
                                class="text-danger"
                              >Please enter current password</span>
                              <span *ngIf="currentPasswordExistStatus" class="text-danger">
                                {{ currentPasswordExistMessage }}
                              </span>
                            </div>
                          </div>
                    
                          <div class="form-group col-md-4 current-password">
                            <label class="col-form-label">New Password <span class="star">*</span></label>
                            <div class="input-container">
                              <input
                                type="{{ showNewPassword === false ? 'password' : 'text' }}"
                                (keydown)="preventSpace($event)"
                                formControlName="new_password"
                                id="new_password"
                                [ngClass]="{ 'is-invalid': change_password_submitted }"
                                placeholder="New Password"
                                autocomplete="off"
                                class="form-control email-input select-placeholder mb-3 mb-lg-0"
                              />
                              <mat-icon
                                matSuffix
                                class="visibility-icon"
                                (click)="toggleShowPassword(2)"
                              >
                                {{ !showNewPassword ? "visibility" : "visibility_off" }}
                              </mat-icon>
                            </div>
                            <div
                              class="valid-msg"
                              *ngIf="(change_password_validate.new_password.invalid &&
                                      change_password_validate.new_password.touched) ||
                                      change_password_validate.new_password.dirty ||
                                      change_password_submitted"
                            >
                              <span
                                *ngIf="change_password_validate.new_password.errors?.required"
                                class="text-danger"
                              >Please enter new password</span>
                            </div>
                          </div>
                        </div>
                    
                        <div class="row">
                          <div class="form-group col-md-4 current-password">
                            <label class="col-form-label">New Confirm Password <span class="star">*</span></label>
                            <div class="input-container">
                              <input
                                type="{{ showConfirmPassword === false ? 'password' : 'text' }}"
                                (keydown)="preventSpace($event)"
                                formControlName="confirm_new_password"
                                id="confirm_new_password"
                                [ngClass]="{ 'is-invalid': change_password_submitted }"
                                placeholder="Confirm New Password"
                                class="form-control email-input select-placeholder mb-3 mb-lg-0"
                                autocomplete="off"
                              />
                              <mat-icon
                                matSuffix
                                class="visibility-icon"
                                (click)="toggleShowPassword(3)"
                              >
                                {{ !showConfirmPassword ? "visibility" : "visibility_off" }}
                              </mat-icon>
                            </div>
                            <div
                              class="valid-msg"
                              *ngIf="(change_password_validate.confirm_new_password.invalid &&
                                      change_password_validate.confirm_new_password.touched) ||
                                      change_password_validate.confirm_new_password.dirty ||
                                      change_password_submitted"
                            >
                              <span
                                *ngIf="change_password_validate.confirm_new_password.errors?.required"
                                class="text-danger"
                              >Please enter confirm new password</span>
                              <span
                                *ngIf="change_password_validate.confirm_new_password.errors?.mustMatch"
                                class="text-danger"
                              >Passwords must match</span>
                            </div>
                          </div>
                        </div>
                    
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <button mat-raised-button class="searchBtn" [disabled]="changePasswordSubmitForm.invalid || !updatePasswordDisable">
                              Update Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                  </form>
                    <div class="col-md-9">
                      <!-- <span
                        class="profile-tab"
                        [ngClass]="{ 'profile-active': tabindex === 1 }"
                        (click)="profileTabs(1)"
                        title="My Profile"
                        >My Profile</span
                      >
                      <span
                        class="profile-tab"
                        [ngClass]="{ 'profile-active': tabindex === 2 }"
                        title="Edit Profile"
                        (click)="profileTabs(2)"
                        >Edit Profile</span
                      > -->
                      <!-- <span
                        class="profile-tab"
                        [ngClass]="{ 'profile-active': tabindex === 3 }"
                        title="Change Password"
                        (click)="profileTabs(3)"
                        >Change Password</span
                      > -->

                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-new">
    <div class="row">
      <div class="col-md-12">
        <section *ngIf="tabindex == 1" class="my_prifile">
          <div class="row">
            <!-- <div class="col-md-6"> -->
              <!-- <div class="card"> -->
<!--      
                <div
                  class="card-body"
                  *ngIf="
                    resultData.userType &&
                    resultData.userType.userType == 'Admin'
                  "
                >
                  <h3 class="profile-header-title">Organization Information</h3>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Organization Name</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.organizationName
                      }}</span>
                    </div>
                  </div>

                  <div
                    class="row mt-2"
                    *ngIf="resultData.organization.industryType"
                  >
                    <div class="col-md-5"><label>Industry Type</label></div>
                    <div class="col-md-7">
                      <span>
                        {{
                          resultData.organization.industryType
                            ? resultData.organization.industryType
                                .listTypeValueName
                            : ""
                        }}</span
                      >
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5">
                      <label>Contact Person Name</label>
                    </div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.contactPerson
                          ? resultData.organization.contactPerson
                          : ""
                      }}</span>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5">
                      <label>Contact Phone Number</label>
                    </div>
                    <div class="col-md-7">
                      <span>
                        {{
                          resultData.organization.contactPhoneNumber
                            ? resultData.organization.contactPhoneNumber
                            : ""
                        }}</span
                      >
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Contact Email</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.contactEmail
                          ? resultData.organization.contactEmail
                          : ""
                      }}</span>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Financial Year</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.fiscalYearStart
                          ? resultData.organization.fiscalYearStart
                          : ""
                      }}</span>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Default Time Zone</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.defaulTimezone
                          ? resultData.organization.defaulTimezone
                          : ""
                      }}</span>
                    </div>
                  </div>
                </div> -->
          
                <!-- <div
                  class="card-body"
                  *ngIf="
                    resultData.employee && resultData.employee.employmentType
                  "
                >
                  <h3 class="profile-header-title">Employee Information</h3>

                  <div class="row">
                    <div class="col-md-5"><label>Name</label></div>
                    <div class="col-md-7">
                      <span
                        >{{ resultData.employee.firstName }}
                        {{ resultData.employee.middleName }}
                        {{ resultData.employee.lastName }}</span
                      >
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Email</label></div>
                    <div class="col-md-7">
                      <span>{{ resultData.employee.organizationEmail }}</span>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Mobile Number</label></div>
                    <div class="col-md-7">
                      <span>{{ resultData.employee.mobileNumber }}</span>
                    </div>
                  </div>
                </div> -->
  
              <!-- </div> -->

              <!-- <div class="card mt-3">
                                <div class="card-body">
                                    <h3 class="profile-header-title">About Me</h3> <div class="row mt-2">
                                        <div class="col-md-12">
                                            <span>
                                                Jesperapps is settled in india and overall pioneer in DAS(Delivery As Service) model with broad involvement with giving the class, Innovative, Interesting Software Solutions in different vertical business around the globe.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
            <!-- </div> -->

            <div class="col-md-6">
              <!-- <div class="card"> -->
                <!-- Organization contact start -->
                <!-- <div
                  class="card-body"
                  *ngIf="
                    resultData.userType &&
                    resultData.userType.userType == 'Admin'
                  "
                >
                  <h3 class="profile-header-title">Contact Information</h3>

                  <div class="row mt-2" *ngIf="resultData.organization.country">
                    <div class="col-md-5"><label>Country</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.country.countryName
                      }}</span>
                    </div>
                  </div>

                  <div class="row mt-2" *ngIf="resultData.organization.state">
                    <div class="col-md-5"><label>State</label></div>
                    <div class="col-md-7">
                      <span>{{ resultData.organization.state.stateName }}</span>
                    </div>
                  </div>

                  <div
                    class="row mt-2"
                    *ngIf="resultData.organization.district"
                  >
                    <div class="col-md-5"><label>District</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.district.districtName
                      }}</span>
                    </div>
                  </div>

                  <div class="row mt-2" *ngIf="resultData.organization.city">
                    <div class="col-md-5"><label>City</label></div>
                    <div class="col-md-7">
                      <span>{{ resultData.organization.city.cityName }}</span>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Postal Code</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.postalCode
                          ? resultData.organization.postalCode
                          : ""
                      }}</span>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5"><label>Address</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.organization.address
                          ? resultData.organization.address
                          : ""
                      }}</span>
                    </div>
                  </div>
                </div> -->
                <!-- Organization contact end -->

                <!-- Employee contact start -->
                <!-- <div
                  class="card-body"
                  *ngIf="
                    resultData.employee && resultData.employee.employmentType
                  "
                > -->
                  <!-- <h3 class="profile-header-title">Contact Information</h3>

                  <div class="row mt-2" *ngIf="resultData.employee.userInfo">
                    <div class="col-md-5"><label>Country</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.employee.userInfo.country.countryName
                      }}</span>
                    </div>
                  </div>

                  <div class="row mt-2" *ngIf="resultData.employee.userInfo">
                    <div class="col-md-5"><label>State</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.employee.userInfo.state.stateName
                      }}</span>
                    </div>
                  </div> -->

                  <!-- <div class="row mt-2" *ngIf="resultData.employee.userInfo">
                    <div class="col-md-5"><label>City</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.employee.userInfo.city.cityName
                      }}</span>
                    </div>
                  </div> -->

                  <!-- <div class="row mt-2" >
                    <div class="col-md-5"><label>Postal Code</label></div>
                    <div class="col-md-7">
                      <span>{{
                        resultData.employee.postalCode
                          ? resultData.employee.postalCode
                          : "--"
                      }}</span>
                    </div>
                  </div> -->

                  <!-- <div class="row mt-2" ng-if="resultData.postalCode != ''">
                                        <div class="col-md-5"><label>Website</label></div>
                                        <div class="col-md-7"><span>{{resultData.postalCode}}</span></div>
                                    </div> -->

                  <!-- <div class="row mt-2">
                    <div class="col-md-5">
                      <label>Address</label>
                    </div>
                    <div class="col-md-7">
                      <span>
                        {{
                          resultData.employee.address
                            ? resultData.employee.address
                            : "--"
                        }}
                      </span>
                    </div>
                  </div> -->
                <!-- </div> -->
                <!-- Employee contact end -->
              </div>
            <!-- </div> -->
          </div>
        </section>

        <section *ngIf="tabindex == 2" class="edit_prifile">
     
          <div
            *ngIf="
              resultData.userType && resultData.userType.userType == 'Admin'
            "
          >
            <form [formGroup]="orgpostForm" (ngSubmit)="orgOnSubmit()">
              <div class="card">
                <div class="card-body">
                  <h3>Organization Information</h3>

                  <div class="row">
                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >Organization Name <span class="star">*</span></label
                      >
                      <!-- (keyup)="checkOrganizationName($event)" -->
                      <input
                        type="text"
                        placeholder="Organization Name"
                        formControlName="organizationName"
                        autocomplete="off"
                        class="form-control email-input select-placeholder mb-3 mb-lg-0"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.organizationName.invalid &&
                            org_validate.organizationName.touched) ||
                          org_validate.organizationName.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.organizationName.errors?.required"
                          class="text-danger"
                          >Please enter organization name</span
                        >
                        <span
                          *ngIf="org_validate.organizationName.errors?.pattern"
                          class="text-danger"
                          >Please enter valid organization name</span
                        >
                        <!--     <span *ngIf="organizationNameExistStatus" class="span-error">Organization name already exist!</span>
                                                <span *ngIf="existMsg" class="text-danger">{{existMsg}}</span>
                                             -->
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >Industry Type<span class="star">*</span></label
                      >
                      <select
                        formControlName="industryType"
                        id="industryType"
                        class="form-control email-input select-placeholder"
                      >
                        <option value="">- Select -</option>
                        <option
                          *ngFor="let row of industryTypeLov; let i = index"
                          value="{{ row.listTypeValueId }}"
                        >
                          {{ row.listTypeValueName }}
                        </option>
                      </select>

                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.industryType.invalid &&
                            org_validate.industryType.touched) ||
                          org_validate.industryType.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.industryType.errors?.required"
                          class="text-danger"
                          >Please select industry type</span
                        >
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >Contact Person <span class="star">*</span></label
                      >
                      <input
                        type="text"
                        formControlName="contactPerson"
                        id="contactPerson"
                        class="form-control email-input select-placeholder mb-3 mb-lg-0"
                        autocomplete="off"
                        placeholder="Contact Person"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.contactPerson.invalid &&
                            org_validate.contactPerson.touched) ||
                          org_validate.contactPerson.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.contactPerson.errors?.required"
                          class="text-danger"
                          >Please enter contact person</span
                        >
                        <span
                          *ngIf="org_validate.contactPerson.errors?.pattern"
                          class="text-danger"
                          >Please enter valid contact person</span
                        >
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >Contact Phone Number <span class="star">*</span></label
                      >
                      <!-- (keyup)="checkContactNumber($event)" -->
                      <input
                        type="text"
                        formControlName="contactPhoneNumber"
                        id="contactPhoneNumber"
                        class="form-control email-input select-placeholder mb-3 mb-lg-0"
                        autocomplete="off"
                        placeholder="Contact Phone Number"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.contactPhoneNumber.invalid &&
                            org_validate.contactPhoneNumber.touched) ||
                          org_validate.contactPhoneNumber.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="
                            org_validate.contactPhoneNumber.errors?.required
                          "
                          class="text-danger"
                          >Please enter contact phone number</span
                        >
                        <span
                          *ngIf="
                            org_validate.contactPhoneNumber.errors?.pattern
                          "
                          class="text-danger"
                          >Please enter valid contact phone number</span
                        >
                        <!--   <span *ngIf="contactNumberExistStatus" class="span-error">Contact number already exist!</span>
                                            -->
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >Contact Email <span class="star">*</span></label
                      >
                      <!-- (keyup)="checkContactEmail($event)" -->
                      <input
                        type="text"
                        formControlName="contactEmail"
                        id="contactEmail"
                        class="form-control email-input select-placeholder mb-3 mb-lg-0"
                        autocomplete="off"
                        placeholder="Contact Email"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.contactEmail.invalid &&
                            org_validate.contactEmail.touched) ||
                          org_validate.contactEmail.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.contactEmail.errors?.required"
                          class="text-danger"
                          >Please enter contact email</span
                        >
                        <span
                          *ngIf="org_validate.contactEmail.errors?.pattern"
                          class="text-danger"
                          >Please enter valid contact email</span
                        >
                        <!-- <span *ngIf="contactEmailExistStatus" class="span-error">Contact email already exist!</span>
                                            -->
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label">Postal Code </label>
                      <input
                        type="text"
                        formControlName="postalCode"
                        id="postalCode"
                        class="form-control email-input select-placeholder mb-3 mb-lg-0 only_name"
                        placeholder="Postal Code"
                        autocomplete="off"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.postalCode.invalid &&
                            org_validate.postalCode.touched) ||
                          org_validate.postalCode.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.postalCode.errors?.required"
                          class="text-danger"
                          >Please enter postal code</span
                        >
                        <span
                          *ngIf="org_validate.postalCode.errors?.pattern"
                          class="text-danger"
                          >Please enter valid postal code</span
                        >
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label">Address </label>
                      <input
                        type="text"
                        formControlName="address"
                        id="address"
                        class="form-control email-input select-placeholder mb-3 mb-lg-0"
                        placeholder="Address"
                        autocomplete="off"
                      />
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label">Fiscal Year Start</label>
                      <input
                        type="text"
                        formControlName="fiscalYearStart"
                        id="fiscalYearStart"
                        class="form-control email-input select-placeholder mb-3 mb-lg-0"
                        placeholder="Fiscal Year Start (YYYY)"
                        autocomplete="off"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-3">
                      <label class="col-form-label">Default Time Zone</label>
                      <!-- <input type="text" formControlName="defaulTimezone" id="defaulTimezone" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Default Time Zone (Asia/Kolkata)" autocomplete="off"> -->
                      <select
                        formControlName="defaulTimezone"
                        id="defaulTimezone"
                        class="form-control email-input select-placeholder"
                      >
                        <option value="">- Select -</option>
                        <option
                          *ngFor="let row of activeTimeZoneData; let i = index"
                          value="{{ row.timezoneId }}"
                        >
                          {{ row.timezoneName }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >Country <span class="star">*</span></label
                      >
                      <select
                        formControlName="countryId"
                        id="countryId"
                        (change)="countryChange($event)"
                        [ngClass]="{ 'is-invalid': submitted }"
                        class="form-control email-input select-placeholder"
                      >
                        <!-- [ngClass]="{ 'is-invalid': submitted }" -->
                        <option value="">- Select -</option>
                        <option
                          *ngFor="let row of countryData; let i = index"
                          value="{{ row.countryId }}"
                        >
                          {{ row.countryName }}
                        </option>
                      </select>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.countryId.invalid &&
                            org_validate.countryId.touched) ||
                          org_validate.countryId.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.countryId.errors?.required"
                          class="text-danger"
                          >Please select country</span
                        >
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >State <span class="star">*</span></label
                      >
                      <select
                        formControlName="stateId"
                        id="stateId"
                        (change)="stateChange($event)"
                        [ngClass]="{ 'is-invalid': submitted }"
                        class="form-control email-input select-placeholder"
                      >
                        <!-- [ngClass]="{ 'is-invalid': submitted }" -->
                        <option value="">- Select -</option>
                        <option
                          *ngFor="let row of stateList; let i = index"
                          value="{{ row.stateId }}"
                        >
                          {{ row.stateName }}
                        </option>
                      </select>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.stateId.invalid &&
                            org_validate.stateId.touched) ||
                          org_validate.stateId.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.stateId.errors?.required"
                          class="text-danger"
                          >Please select state</span
                        >
                      </div>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="col-form-label">District <span class="star">*</span></label>
                        <select
                          formControlName="districtId"
                          id="districtId"
                          (change)="districtChange($event)"
                          [ngClass]="{ 'is-invalid': submitted && postForm.get('districtId').invalid }"
                          class="form-control email-input select-placeholder"
                        >
                          <option value="">- Select -</option>
                          <option *ngFor="let row of districtList; let i = index" value="{{ row.districtId }}">
                            {{ row.districtName }}
                          </option>
                        </select>
                        <div
                          class="valid-msg"
                          *ngIf="(postForm.get('districtId').invalid && postForm.get('districtId').touched) || (submitted && postForm.get('districtId').invalid)"
                        >
                          <span *ngIf="postForm.get('districtId').errors?.required" class="text-danger">Please select district</span>
                        </div>
                      </div>
                      
                  </div>

                  <div class="row">
                    <div class="form-group col-md-3">
                      <label class="col-form-label"
                        >City <span class="star">*</span></label
                      >
                      <select
                        formControlName="cityId"
                        id="cityId"
                        [ngClass]="{ 'is-invalid': submitted }"
                        class="form-control email-input select-placeholder"
                      >
                        <!-- [ngClass]="{ 'is-invalid': submitted }" -->
                        <option value="">- Select -</option>
                        <option
                          *ngFor="let row of cityList; let i = index"
                          value="{{ row.cityId }}"
                        >
                          {{ row.cityName }}
                        </option>
                      </select>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (org_validate.cityId.invalid &&
                            org_validate.cityId.touched) ||
                          org_validate.cityId.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="org_validate.cityId.errors?.required"
                          class="text-danger"
                          >Please select city</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button type="submit" mat-raised-button color="primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Organization end here -->

          <!-- Employee start here -->
          <div
            *ngIf="resultData.employee && resultData.employee.employmentType"
          >
            <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
              <div class="card">
                <div class="card-body">
                  <h3>User Information</h3>
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >First Name <span class="star">*</span></label
                      >
                      <input
                        type="text"
                        formControlName="firstName"
                        id="firstName"
                        [ngClass]="{ 'is-invalid': submitted }"
                        placeholder="First Name"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        value=""
                        autocomplete="off"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.firstName.invalid &&
                            validate.firstName.touched) ||
                          validate.firstName.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.firstName.errors?.required"
                          class="text-danger"
                          >Please enter first name</span
                        >
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label class="col-form-label">Last Name </label>
                      <input
                        type="text"
                        formControlName="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        value=""
                        autocomplete="off"
                      />
                    </div>

                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >Email <span class="star">*</span></label
                      >
                      <input
                        type="text"
                        formControlName="organizationEmail"
                        id="organizationEmail"
                        [ngClass]="{ 'is-invalid': submitted }"
                        placeholder="Email"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        autocomplete="off"
                        value=""
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.organizationEmail.invalid &&
                            validate.organizationEmail.touched) ||
                          validate.organizationEmail.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.organizationEmail.errors?.required"
                          class="text-danger"
                          >Please enter email</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >Mobile Number<span class="star">*</span></label
                      >
                      <input
                        type="text"
                        formControlName="mobileNumber"
                        minlength="10"
                        maxlength="15"
                        id="mobileNumber"
                        [ngClass]="{ 'is-invalid': submitted }"
                        placeholder="Mobile Number"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        value=""
                        autocomplete="off"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.mobileNumber.invalid &&
                            validate.mobileNumber.touched) ||
                          validate.mobileNumber.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.mobileNumber.errors?.required"
                          class="text-danger"
                          >Please mobile number</span
                        >
                        <span
                          *ngIf="validate.mobileNumber.errors?.pattern"
                          class="text-danger"
                          >Please enter valid mobile number</span
                        >
                      </div>
                    </div>
                  </div>

                  <h3>Contact Information</h3>
                  <div class="row">
                    <!-- <div class="form-group col-md-4">
                                            <label class="col-form-label">Country <span class="star">*</span></label>
                                            <select formControlName="countryId" id="countryId" (change)="countryChange($event)" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg">
                                                <option value="">- Select -</option>
                                                <option *ngFor="let row of countryData; let i=index;" value="{{row.countryId}}">{{row.countryName}}</option>
                                            </select>
                                            <div class="valid-msg" *ngIf="(validate.countryId.invalid && validate.countryId.touched) || validate.countryId.dirty || submitted">
                                                <span *ngIf="validate.countryId.errors?.required" class="text-danger">Please select country</span>
                                            </div>
                                        </div> -->
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >Country<span class="star"> *</span></label
                      >
                      <input
                        type="text"
                        placeholder=" Search Country"
                        formControlName="countryId"
                        [matAutocomplete]="autocountry"
                        class="form-control designchange"
                        (keyup)="countrySearch($event)"
                      />
                      <mat-autocomplete #autocountry="matAutocomplete">
                        <mat-option
                          *ngFor="let country of countrylist"
                          [value]="country.countryId"
                          (click)="countrySelect(country)"
                        >
                          {{ country.countryName }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-icon
                        class="material-symbols-outlined"
                        style="position: absolute; top: 48px; right: 20px"
                      >
                        search
                      </mat-icon>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.countryId.invalid &&
                            validate.countryId.touched) ||
                          validate.countryId.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.countryId.errors?.required"
                          class="text-danger"
                          >Please select country</span
                        >
                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                      </div>
                    </div>

                    <!-- <div class="form-group col-md-4">
                                            <label class="col-form-label">State <span class="star">*</span></label>
                                            <select formControlName="stateId" id="stateId" (change)="stateChange($event)" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg">
                                                <option value="">- Select -</option>
                                                <option *ngFor="let row of stateList; let i=index;" value="{{row.stateId}}">{{row.stateName}}</option>
                                            </select>
                                            <div class="valid-msg" *ngIf="(validate.stateId.invalid && validate.stateId.touched) || validate.stateId.dirty || submitted">
                                                <span *ngIf="validate.stateId.errors?.required" class="text-danger">Please select state</span>
                                            </div>
                                        </div> -->
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >State<span class="star"> *</span></label
                      >
                      <input
                        type="text"
                        placeholder=" Search State"
                        formControlName="stateId"
                        [matAutocomplete]="stateauto"
                        class="form-control designchange"
                        (keyup)="stateSearch($event)"
                      />
                      <mat-autocomplete #stateauto="matAutocomplete">
                        <mat-option
                          *ngFor="let state of statelist"
                          [value]="state.stateId"
                          (click)="stateSelect(state)"
                        >
                          {{ state.stateName }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-icon
                        class="material-symbols-outlined"
                        style="position: absolute; top: 48px; right: 20px"
                      >
                        search
                      </mat-icon>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.stateId.invalid &&
                            validate.stateId.touched) ||
                          validate.stateId.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.stateId.errors?.required"
                          class="text-danger"
                          >Please select state</span
                        >
                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                      </div>
                    </div>

                    <!-- <div class="form-group col-md-4">
                                            <label class="col-form-label">District <span class="star">*</span></label>
                                            <select formControlName="districtId" id="districtId" (change)="districtChange($event)" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg">
                                            
                                                <option value="">- Select -</option>
                                                <option *ngFor="let row of districtList; let i=index;" value="{{row.districtId}}">{{row.districtName}}</option>
                                            </select>
                                            <div class="valid-msg" *ngIf="(validate.districtId.invalid && validate.districtId.touched) || validate.districtId.dirty || submitted">
                                                <span *ngIf="validate.districtId.errors?.required" class="text-danger">Please select district</span>
                                            </div>
                                        </div> -->
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >District<span class="star"> *</span></label
                      >
                      <input
                        type="text"
                        placeholder=" Search District"
                        formControlName="districtId"
                        [matAutocomplete]="districtauto"
                        class="form-control designchange"
                        (keyup)="districtSearch($event)"
                      />
                      <mat-autocomplete #districtauto="matAutocomplete">
                        <mat-option
                          *ngFor="let district of disrictList"
                          [value]="district.districtId"
                          (click)="districtSelect(district)"
                        >
                          {{ district.districtName }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-icon
                        class="material-symbols-outlined"
                        style="position: absolute; top: 48px; right: 20px"
                      >
                        search
                      </mat-icon>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.districtId.invalid &&
                            validate.districtId.touched) ||
                          validate.districtId.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.districtId.errors?.required"
                          class="text-danger"
                          >Please select district</span
                        >
                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                      </div>
                    </div>

                    <!-- <div class="form-group col-md-4">
                                            <label class="col-form-label">City <span class="star">*</span></label>
                                            <select formControlName="cityId" id="cityId" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg"> -->
                    <!-- [ngClass]="{ 'is-invalid': submitted }" -->
                    <!-- <option value="">- Select -</option>
                                                <option *ngFor="let row of cityList; let i=index;" value="{{row.cityId}}">{{row.cityName}}</option>
                                            </select>
                                            <div class="valid-msg" *ngIf="(validate.cityId.invalid && validate.cityId.touched) || validate.cityId.dirty || submitted">
                                                <span *ngIf="validate.cityId.errors?.required" class="text-danger">Please select city</span>
                                            </div>
                                        </div> -->
                  </div>

                  <div class="row">
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >City<span class="star"> *</span></label
                      >
                      <input
                        type="text"
                        placeholder=" Search City"
                        formControlName="cityId"
                        [matAutocomplete]="cityauto"
                        class="form-control designchange"
                        (keyup)="citySearch($event)"
                      />
                      <mat-autocomplete #cityauto="matAutocomplete">
                        <mat-option
                          *ngFor="let city of cityList"
                          [value]="city.cityId"
                          (click)="citySelect(city)"
                        >
                          {{ city.cityName }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-icon
                        class="material-symbols-outlined"
                        style="position: absolute; top: 48px; right: 20px"
                      >
                        search
                      </mat-icon>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.cityId.invalid &&
                            validate.cityId.touched) ||
                          validate.cityId.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.cityId.errors?.required"
                          class="text-danger"
                          >Please select city</span
                        >
                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                      </div>
                    </div>
                    <!-- <div class="form-group col-md-4">
                                            <label class="col-form-label">Postal Code <span class="star">*</span></label>
                                            <input type="text" formControlName="postalCode" id="postalCode" minlength="6" maxlength="6" [ngClass]="{ 'is-invalid': submitted }" placeholder="Postal Code" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" value="">
                                            <div class="valid-msg" *ngIf="(validate.postalCode.invalid && validate.postalCode.touched) || validate.postalCode.dirty || submitted">
                                                <span *ngIf="validate.postalCode.errors?.required" class="text-danger">Please enter postal code</span>
                                                <span *ngIf="validate.postalCode.errors?.pattern" class="text-danger">Please enter valid postal code</span>

                                            </div>
                                        </div> -->
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >Postal Code<span class="star"> *</span></label
                      >
                      <input
                        type="text"
                        formControlName="postalCode"
                        maxlength="6"
                        id="permenantPostalCode"
                        [ngClass]="{
                          'is-invalid': validate && validate.postalCode.errors
                        }"
                        autocomplete="off"
                        class="form-select form-select-solid form-select-lg"
                        value="null"
                        placeholder="Postal Code"
                      />
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.postalCode.invalid &&
                            validate.postalCode.touched) ||
                          validate.postalCode.dirty ||
                          submitted
                        "
                      >
                        <span
                          class="text-danger"
                          *ngIf="validate.postalCode.errors?.required"
                          >Please enter permenant postal code</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="validate.postalCode.errors?.pattern"
                          >Please enter permenant valid postal code</span
                        >
                      </div>
                    </div>

                    <!--<div class="form-group col-md-4">
                                            <label class="col-form-label">Website <span class="star">*</span></label>
                                            <input type="url" name="website" id="website" required=""  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" value="" placeholder="">
                                        </div> -->

                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >Address <span class="star">*</span></label
                      >
                      <textarea
                        formControlName="address"
                        id="address"
                        [ngClass]="{ 'is-invalid': submitted }"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        autocomplete="off"
                        value=""
                        placeholder="Address"
                      ></textarea>
                      <div
                        class="valid-msg"
                        *ngIf="
                          (validate.address.invalid &&
                            validate.address.touched) ||
                          validate.address.dirty ||
                          submitted
                        "
                      >
                        <span
                          *ngIf="validate.address.errors?.required"
                          class="text-danger"
                          >Please enter address</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- <h3>About Me</h3>
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label class="col-form-label">About Me <span class="star">*</span></label>
                                            <textarea name="about_me" id="about_me" autocomplete="off" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder=""></textarea>
                                        </div>
                                    </div>
                                    -->

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button type="submit" mat-raised-button color="primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Employee end here -->
        </section>

        <!-- <section *ngIf="tabindex == 3" class="change_password">
          <form
            [formGroup]="changePasswordSubmitForm"
            (ngSubmit)="changePasswordSubmit()"
          >
           
            <div class="card">
              <div class="card-body">
                <h3>Change Password</h3>
                <div class="row">
                  <div class="form-group col-md-4 current-password">
                    <label class="col-form-label"
                      >Current Password <span class="star">*</span></label
                    >
                    <input
                      type="{{ showPassword == false ? 'password' : 'text' }}"
                      (keyup)="checkCurrentPassword()"
                      formControlName="password"
                      [ngClass]="{ 'is-invalid': change_password_submitted }"
                      id="password"
                      placeholder="Current Password"
                      class="form-control email-input select-placeholder mb-3 mb-lg-0"
                      value=""
                      autocomplete="off"
                    />
                    <span class="show-hide">
                      <mat-icon
                        matSuffix
                        style="cursor: pointer"
                        (click)="toggleShowPassword(1)"
                      >
                        {{ !showPassword ? "visibility" : "visibility_off" }}
                      </mat-icon>
                    </span>
                 
                    <div
                      class="valid-msg"
                      *ngIf="
                        (change_password_validate.password.invalid &&
                          change_password_validate.password.touched) ||
                        change_password_validate.password.dirty ||
                        change_password_submitted
                      "
                    >
                      <span
                        *ngIf="
                          change_password_validate.password.errors?.required
                        "
                        class="text-danger"
                        >Please enter current password</span
                      >
                      <span
                        *ngIf="currentPasswordExistStatus"
                        class="text-danger"
                      >
                        {{ currentPasswordExistMessage }}</span
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-4 current-password">
                    <label class="col-form-label"
                      >New Password <span class="star">*</span></label
                    >
                    <input
                      type="{{
                        showNewPassword === false ? 'password' : 'text'
                      }}"
                      formControlName="new_password"
                      id="new_password"
                      [ngClass]="{ 'is-invalid': change_password_submitted }"
                      placeholder="New Password"
                      autocomplete="off"
                      class="form-control email-input select-placeholder mb-3 mb-lg-0"
                      value=""
                    />
                    <span class="show-hide">
                      <mat-icon
                        matSuffix
                        style="cursor: pointer"
                        (click)="toggleShowPassword(2)"
                      >
                        {{ !showNewPassword ? "visibility" : "visibility_off" }}
                      </mat-icon>
                    </span>
                   
                    <div
                      class="valid-msg"
                      *ngIf="
                        (change_password_validate.new_password.invalid &&
                          change_password_validate.new_password.touched) ||
                        change_password_validate.new_password.dirty ||
                        change_password_submitted
                      "
                    >
                      <span
                        *ngIf="
                          change_password_validate.new_password.errors?.required
                        "
                        class="text-danger"
                        >Please enter new password</span
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4 current-password">
                    <label class="col-form-label"
                      >New Confirm Password <span class="star">*</span></label
                    >
                    <input
                      type="{{
                        showConfirmPassword === false ? 'password' : 'text'
                      }}"
                      formControlName="confirm_new_password"
                      id="confirm_new_password"
                      [ngClass]="{ 'is-invalid': change_password_submitted }"
                      placeholder="Confirm New Password"
                      class="form-control email-input select-placeholder mb-3 mb-lg-0"
                      autocomplete="off"
                      value=""
                    />
                    <span class="show-hide">
                      <mat-icon
                        matSuffix
                        style="cursor: pointer"
                        (click)="toggleShowPassword(3)"
                      >
                        {{
                          !showConfirmPassword ? "visibility" : "visibility_off"
                        }}
                      </mat-icon>
                    </span>
                   
                    <div
                      class="valid-msg"
                      *ngIf="
                        (change_password_validate.confirm_new_password
                          .invalid &&
                          change_password_validate.confirm_new_password
                            .touched) ||
                        change_password_validate.confirm_new_password.dirty ||
                        change_password_submitted
                      "
                    >
                      <span
                        *ngIf="
                          change_password_validate.confirm_new_password.errors
                            ?.required
                        "
                        class="text-danger"
                        >Please enter confirm new password</span
                      >
                      <span
                        *ngIf="
                          change_password_validate.confirm_new_password.errors
                            ?.mustMatch
                        "
                        class="text-danger"
                        >Passwords must match</span
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-right">
                    <button mat-raised-button color="primary">
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section> -->
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<!-- <div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div> -->

<div class="text-center" *ngIf="success">
  <div class="alert alert-resource alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>
</div>
