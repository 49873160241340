import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-add-elements',
  templateUrl: './add-elements.component.html',
  styleUrls: ['./add-elements.component.scss']
})
export class AddElementsComponent implements OnInit {
  elementform:FormGroup;
  sucess_msg:any;
  error_msg:any;
  error:any=false;
  success:any=false;
  loader:any= false;
  backdrop:any= false;
  elementId:any;
  submitted: boolean = false;
  categoryTypeData :any;
  usertype:any;
  update_data_error_msg:any;
  userdata:any=[];
  showCategoryType: boolean = false;
  employeetData: any;
  organizationId: any;
  constructor(public dialog: MatDialog,
    private router:Router,
    private payrollService:PayrollService,
    private ActivatedRoute:ActivatedRoute,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.organizationId = organizationId;
    this.listCategoryType();
    this.patchresource();

    if(this.ActivatedRoute.snapshot.params.elementId)
    { 
      console.log('elementId present')
      
        this.elementform = new FormGroup(
        {
          categoryType: new FormControl(this.elementform,[Validators.required]), 
          elementName : new FormControl(this.elementform,[Validators.required,Validators.pattern(/^[a-zA-Z&_]+(([. ][a-zA-Z&_ ])?[a-zA-Z&_]*(&[a-zA-Z&_]*)?)*$/)]),
          orderNumber : new FormControl(this.elementform,[Validators.pattern(/^[0-9]*$/)]), 
          elementDescription : new FormControl(this.elementform), 
        }
      );
     
    }
    else
    {   console.log('elementId not present')
    alert("element add")
        this.elementform = new FormGroup(
        {
          categoryType: new FormControl(this.elementform,[Validators.required]), 
          elementName : new FormControl(this.elementform,[Validators.required,Validators.pattern(/^[a-zA-Z&_]+(([. ][a-zA-Z&_ ])?[a-zA-Z&_]*(&[a-zA-Z&_]*)?)*$/)]),
          orderNumber : new FormControl(this.elementform,[Validators.pattern(/^[0-9]*$/)]), 
          elementDescription : new FormControl(this.elementform), 
        }
      );
    } 
  }


  patchresource()
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    console.log('patch')
    if(this.ActivatedRoute.snapshot.params.elementId)
    {
      this.elementId = this.ActivatedRoute.snapshot.params.elementId;
      
      this.payrollService.elementbyid(this.ActivatedRoute.snapshot.params.elementId).subscribe((userdata:any) =>
      {        console.log('userdata', userdata)
        this.userdata=userdata;
         console.log('this.userdata', this.userdata)
        
        this.elementform.patchValue({

          elementId : this.userdata.elementId,
          elementName: this.userdata.elementName,
          elementDescription: this.userdata.elementDescription,
          orderNumber: this.userdata.orderNumber,
          calculationFormula: "",
          categoryType:{listTypeValueId:this.userdata.categoryType},
          organization:{organizationId:organizationId},
         
        });
        this.elementform.get('categoryType').patchValue(this.userdata.categoryType.listTypeValueId); 
        if(this.elementform.invalid)
        {
          const controls = this.elementform.controls;
          for (const name in controls) 
          {
            if (controls[name].invalid) {
              console.log(name)
            }
          }
        }
      })
    }
  }

  listTypeValueid(event)
  {
    //  if(event.value == 42 || event.value == 43)
    //  {
        // this.showCategoryType = true;
        // this.elementform.get('categoryType').setValidators([Validators.required ]); 
    //  }
    //  else
    //  {
    //   this.elementform.controls['categoryType'].setErrors(null);
    //   this.showCategoryType = false;
    //  }
  }



  submitData(){
    console.log('submit')
    this.submitted = true;

    this.error = false;
    this.success = false;

    // this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    // console.log('userdata', this.usertype)
    // let employeeId = this.usertype.userId;
    // let organizationId = this.usertype.organization.organizationId;

    if(this.elementId) 
    { 
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

      let organizationId = this.employeetData.organization.organizationId;
      console.log('organizationId'+organizationId)
      var elementupdate;

      elementupdate=
        {
          elementId : this.elementId,
          elementName: this.elementform.value.elementName,
          elementDescription: this.elementform.value.elementDescription,
          orderNumber: this.elementform.value.orderNumber,
          calculationFormula: "",
          categoryType:{listTypeValueId:this.elementform.value.categoryType},
          organization:{organizationId:organizationId},
          status:this.userdata.status,
        };
        console.log('elementupdate', elementupdate)
      this.payrollService.elementupdate(elementupdate,this.elementId).subscribe( (data:any) =>
      {
        if(data.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = data.description;
          
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          this.router.navigate(['/home/manage-elements']);
        }
        else
        {
          this.error = true;
          this.error_msg = data.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }
      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;     
        this.update_data_error_msg = err.error.message;
        setTimeout(() => {this.error = false}, 3000)
      })
    }
    else //Create
    {
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

      let organizationId = this.employeetData.organization.organizationId;
      console.log('organizationId'+organizationId)
      var elementdata;

      elementdata =
        { 
          elementName: this.elementform.value.elementName,
          elementDescription: this.elementform.value.elementDescription,
          orderNumber: this.elementform.value.orderNumber,
          calculationFormula: "",
          categoryType:{listTypeValueId:this.elementform.value.categoryType},
          organization:{organizationId:organizationId},
          status:{listTypeValueId:1},
        };
        console.log('elementdata', elementdata)
      this.backdrop=true;
      this.loader=true;
      this.payrollService.elementcreate(elementdata).subscribe( (data:any) =>
      {
        if(data.statusCode == 200)
        {
          console.log('data.statusCode if success', data.statusCode)
          this.success = true;
          this.sucess_msg = data.description;
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          this.router.navigate(['/home/manage-elements']);
        }
        else
        {  console.log('data.statusCode if failure', data.statusCode)
          this.error = true;
          this.error_msg = data.description;
          console.log(this.error_msg)
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }
      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;
        this.error_msg = err.error.message;
        
        setTimeout(() => {this.error = false}, 3000)
      });
    }
  }

  formcancel()
  {
    this.router.navigate(['home/manage-elements']); 
  }
  listCategoryType()
  {
    this.payrollService.listCategoryType(this.organizationId).subscribe((result: any) => {
      this.categoryTypeData = result;
    })
  }


  get elementName()
  {
    return this.elementform.get('elementName');
  }

  get categoryType()
  {
    return this.elementform.get('categoryType');
  }

  
  get orderNumber()
  {
    return this.elementform.get('orderNumber');
  }
}
