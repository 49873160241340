<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content"> Employee Approver</span>
                </div>
                <div class="sub-header-buttons ">
                    <button (click)="Add_empapprover()" mat-button class="setbtnDesign mat-focus-indicator ml-2 mat-raised-button mat-button-base mat-primary">Assign Employee Approver</button>  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content" >
    <div class="card-new">
        <div class="card" *ngIf="tableShow">
            <div class="card-body"  >
                <div >
                    <div class="row" *ngIf="showupdate == true">
                        <div class="container">
                            <form [formGroup]="employeeApproverForm"  (ngSubmit)="submit()">
                                <div class="row mx-2">
                                    <div class="col-md-3 mx-2">
                                        <div class="form-group" id="loginform" >
                                            <label class="form-control-label">Employee<span class="text-danger"> *</span></label>
                                            <mat-select formControlName="employee" 
                                            class="form-control email-input select-placeholder"
                                            placeholder="Select employee"
                                            [disabled]="employeeDisabled"
                                     
                                            (selectionChange)="employeeapprovervalidate()">
                                            <mat-option *ngFor="let employee of employeeList" [value]="employee.employeeId">
                                                {{employee.employeeNumber}}- {{employee.firstName}}  {{employee.lastName}}
                                            </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="employee.invalid && submitted1">Employee is required</mat-error><br>
                                            <span *ngIf="empolyeeapproverexist" class="span-error"> Approver Already Exist</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3 mx-2"> 
                                        <div class="form-group" id="loginform">
                                            <label class="form-control-label">Approver Rule Name<span class="text-danger"> *</span></label>
                                            <mat-select formControlName="approver"
                                                placeholder="Select Approver Rule Name"
                                                class="form-control email-input select-placeholder">
                                                <mat-option *ngFor="let approver of ApproverList" [value]="approver.approverId">
                                                {{approver.rulename}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="approver.invalid && submitted1">Approver Rule Name is required</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3 mx-2">
                                        <!-- Task Type -->
                                        <div class="input-width" id="loginform">
                                            <label class="form-control-label">Approver Type<span class="text-danger"> *</span></label>
                                            <mat-select formControlName="approverType"
                                                placeholder="Select Approver Type"
                                                class="form-control email-input select-placeholder">
                                                <mat-option *ngFor="let approvertype of approvertype" [value]="approvertype.approverTypeId">
                                                {{approvertype.approverTypeName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="approverType.invalid && submitted1">Approver Type is required</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2 col-xl-2 mx-2">
                                        <div class="row buttons mx-2 rowDesign">
                                            <div class="col-md-4 col-lg-6 col-xl-6">
                                                <button (click)=" clearForm()"    mat-raised-button [disabled]="btnDisable" class="mr-2 btnCancel " style="    height: 35px;
                                                position: relative;
                                                left: 16px;">Clear</button>
                                            </div>
                                            <div class="col-md-4 col-lg-6 col-xl-6">
                                                <button type="submit" class="--btn-primary search-btn mat-raised-button"
                                                style="height: 35px;"  [disabled]="btnDisable" 
                                                mat-raised-button 
                                                [ngStyle]="{
                                                    'position': 'relative',
                                                    'left': '38px',
                                                    'height': '35px',
                                                    'top': '0px',
                                                    'color': btnDisable ? 'black' : 'white',
                                                    'background-color': btnDisable ? '#0000001f' : '#1a83ff'
                                                  }">
                                                Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="approverList?.length > 5 ">
                    <div class="col-xl-12 col-lg-12">
                        <div class="select-pagination mb-2" >
                            <span>Show : </span>
                            <select class="select_list new-select_list" [(ngModel)]="mySelect"  (change)="changefirstpage()" style="margin-right: 13px;" >
                                <option class="form-control" value="5"> 5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="table-responsive"style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                    <div  >
                        <table class="table" style="    border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        margin-top: 13px;" matSort (matSortChange)="sortData($event)">
                            <thead class="t-head">
                                <tr>
                                    <th class="text-center table-head">Sl.No</th>
                                    <th mat-sort-header="rulename" class="table-head">Approver&nbsp;Rule&nbsp;Name</th>
                                    <th mat-sort-header="approvertype" class="table-head">Approver&nbsp;Type</th> 
                                    <th mat-sort-header="employeeName" class="table-head">Employee&nbsp;&nbsp;Name</th>              
                                    <th class="text-center table-head">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="approverList?.length > 0 ">
                                <tr *ngFor="let approver of approverList| mytask : search | paginate: { itemsPerPage: mySelect, currentPage: p };let i=index">
                                    <td class="text-center table-data" *ngIf="p">{{((p-1)*mySelect)+(i+1)}}</td>
                                    <td class="text-center table-data" *ngIf="!p">{{i+1}}</td>
                                    <td class="table-data">{{approver.approvers.rulename}}</td>
                                    <td class="table-data" *ngIf="approver.approverType != undefined">{{approver.approverType.approverTypeName}}</td>                
                                    <td class="table-data text-center" *ngIf="approver.approverType == undefined"> -- </td>  
                                    <td class="table-data">{{approver.employee.firstName}} {{approver.employee.lastName}}</td>                 
                                    <td class="text-center table-data">
                                        <mat-icon class="edit-icon" (click)="editprojectapprover(approver)">create</mat-icon>      
                                    </td>
                                </tr>
                            </tbody>
                            <!-- <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="5" *ngIf="nodata">No Records Found!</td>
                                </tr>
                            </tbody> -->
                            <tbody style="    border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;">
                                                            <tr>
                                                                <td   style="
                                                                text-align: center;
                                                                font-size: larger;
                                                                font-weight: bold;
                                                            " colspan="11" 
                                                            *ngIf="nodata||((approverList | mytask : search)?.length === 0 && search)">Employee Approvers Records Not Found!</td>
                                                            </tr>
                                                        </tbody>
                        </table>
                    </div> 
                </div>
                <div class="row topnav-right pagination-content" *ngIf="approverList?.length > 5 ">        
                    <div class="col-md-12 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div> 
            </div>
        </div>
    </div> 
</div>
<div  *ngIf="list_loader" class="align-items-center text-center">
    <div class="spinner-border" 
    style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;top: 31%;" role="status"></div>
</div> 
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{success_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
