import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProjectsService {


  public projectStatusSubject = new BehaviorSubject<any>(null);

  setProjectStatus(data: any) {
    this.projectStatusSubject.next(data);
  }

  getProjectStatus(): Observable<any> {
    return this.projectStatusSubject.asObservable();
  }
}
