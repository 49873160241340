import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../../providers/settings.service';
import {httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../../../providers/properties';

@Component({
  selector: 'app-add-approver-rules',
  templateUrl: './add-approver-rules.component.html',
  styleUrls: ['./add-approver-rules.component.scss']
})
export class AddApproverRulesComponent implements OnInit 
{
  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any;
  branchId: any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  employeetData:any;

  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private ActivatedRoute:ActivatedRoute,
  ) 
  { 
    
  } 

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      ruleName : ['',[Validators.required]],
      ruleDescription : [''],
      startDate : [''],
      endDate : [''],
      fyi:[''],
      add_remove_more:this.formBuilder.array([
        this.formBuilder.group({
          approverId: ['', Validators.required],
          approvalLevelId: ['', Validators.required],
        })
      ]),
     });
     
     this.branchId = this.ActivatedRoute.snapshot.params.branchId;

     if(this.branchId)
    {
      this.settingsservice.getBranchById(this.branchId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          branchCode: this.resultData.branchCode,
          branchName: this.resultData.branchName,
          branchDescription: this.resultData.branchDescription,
          phoneNumber: this.resultData.phoneNumber,
          alternativePhoneNumber: this.resultData.alternativePhoneNumber,
          branchAddress: this.resultData.branchAddress,
          branchEmail: this.resultData.branchEmail,
        });
      });
    }
  }
  get validate() { return this.postForm.controls; }


  //Add More
  addMore() 
  {
    const Array: any = this.postForm.get('add_remove_more') as FormArray;

    if (Array.length >= 10) 
    {
      alert("Maximum 10 approvers & Levels");
      return
    }
    else 
    {
      Array.push(this.formBuilder.group({
          approverId: ['', Validators.required],
          approvalLevelId: ['', Validators.required],
      })) 
    }
  }

  //Remove More
  removeMore(index: number) 
  {
    if(index == 0)
    {
      return
    }
    else
    {
       if(this.branchId)
       {
         /*  let resavaupdate = this.reminder().at(index).value;
          console.log(resavaupdate);
          this.deletedresourcetime.push(resavaupdate);
          console.log(this.deletedresourcetime.length)
          this.add_remove().removeAt(index); */
        }
        else
        {
          this.add_remove().removeAt(index);
        }
    }
  }

  add_remove() 
  {
    return this.postForm.get('add_remove_more') as FormArray;
  }

  onSubmit()
  {
    console.log(this.postForm);
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.submitted = true;

    if (this.postForm.invalid) 
    {
      return;
    }

    if(this.branchId) //Update Branch
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['branchId'] = this.branchId;
      postValues['branchStatus'] = this.resultData.branchStatus;

      this.settingsservice.editBranch(this.branchId,postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }

        //this.router.navigate([ '/home/branch' ]);
      }, err =>{

        this.errorMsg = err.error.message;

      })
    }
    else  //Add Branch
    {
        let postValues = this.postForm.value;
        postValues['organization'] = {organizationId: organizationId}

      this. settingsservice.createBranch(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }

      }, err =>
      {
          this.errorMsg = err.error.message;

      })
    }
  }

}
