<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content"> {{ recAdjustmentId ? "Edit Employee Recovery Adjustment" : "Add Employee Recovery Adjustment" }}</span>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Element Category<span class="star"> *</span></label
              >
              <!-- (change)="selectFinancialyear()" -->
              <mat-select
                formControlName="category"
                class="form-control email-input select-placeholder"
                placeholder="Select Category"
              
              >
                <!-- <option value="null"> -- Select Category --- </option> -->
                <!-- <option value="deductions" selected>Deductions</option>
                                        <option value="earnings">Earnings</option>
                                        <option value="both">Both</option> -->
                <mat-option
                  *ngFor="let row of categoryTypeData; let i = index"
                  [value]="row.listTypeValueId"
                  >{{ row.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.category.invalid && validate.category.touched) ||
                  validate.category.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.category.errors?.required"
                  class="text-danger"
                  >Please select category</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Element Name<span class="star"> *</span></label
              >
              <mat-select
                formControlName="element"
                placeholder="Element Name"
                class="form-control email-input select-placeholder"
              >
                <mat-option [value]="undefined">
                  -- Select Element Name --
                </mat-option>
                <mat-option
                  *ngFor="let row of eleNameList; let i = index"
                  [value]="row.elementId "
                  >{{ row.elementName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.element.invalid && validate.element.touched) ||
                  validate.element.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.element.errors?.required"
                  class="text-danger"
                  >Please Select Element Name</span
                >
              </div>
            </div>
            <!-- <div class="form-group col-md-3">
              <label class="form-control-label"
                >Employee Code<span class="star"> *</span></label
              >
              <input
                type="text"
                placeholder="Search Employee Code"
                formControlName="employeecode"
                [matAutocomplete]="auto"
                class="example-input designchange form-control"
                (keyup)="empSearch($event)"
                (keydown)="onKeyDown($event)"
                (paste)="onPaste($event)" 
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option value="All" (click)="empAllSelect()"
                  >All</mat-option
                  
                >
                
                <mat-option
                  *ngFor="let emp of empList"
                  [value]="emp.employeeNumber"
                  (click)="empSelect(emp)"
                >
                  {{ emp.employeeNumber }} - {{ emp.firstName }}
                </mat-option>
                <mat-option [value]="null" *ngIf="empCode" (click)="noEmployeeFound()">No
                  Employee Found</mat-option>
              </mat-autocomplete>
              <mat-icon
                class="material-symbols-outlined"
                style="position: absolute; top: 37px; right: 20px"
              >
                search
              </mat-icon>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.employeecode.invalid &&
                    validate.employeecode.touched) ||
                  validate.employeecode.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.employeecode.errors?.required"
                  class="text-danger"
                  >Please Select Employee Code</span
                >
              </div>
            </div> -->



            <div class="form-group col-md-3">
              <label class="form-control-label">Employee Code<span class="star"> *</span></label>
              <input
                type="text"
                placeholder="Search Employee Code"
                formControlName="employeecode"
                [matAutocomplete]="auto"
                class="example-input designchange form-control"
                (keyup)="empSearch($event)"
                (keydown)="onKeyDown($event)"
                (paste)="onPaste($event)" 
                (input)="validateInput($event)"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <ng-container *ngIf="empList.length > 0">
                  <mat-option value="All" (click)="empAllSelect()">All</mat-option>
                  <mat-option
                    *ngFor="let emp of empList"
                    [value]="emp.employeeNumber"
                    (click)="empSelect(emp)"
                  >
                    {{ emp.employeeNumber }} - {{ emp.firstName }}
                  </mat-option>
                </ng-container>
                <mat-option [value]="null" *ngIf="empList.length === 0 && empCode" (click)="noEmployeeFound()">
                  No Employee Found
                </mat-option>
              </mat-autocomplete>
              <mat-icon
                class="material-symbols-outlined"
                style="position: absolute; top: 37px; right: 20px"
              >
                search
              </mat-icon>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.employeecode.invalid && validate.employeecode.touched) ||
                  validate.employeecode.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.employeecode.errors?.required"
                  class="text-danger"
                  >Please Select Employee Code</span
                >
              </div>
            </div>
            
          </div>
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Financial Year<span class="star"> *</span></label
              >
              <mat-select
                formControlName="financialyear"
                placeholder="Select Financial Year"
                class="form-control email-input select-placeholder"
                (selectionChange)="selectFinancialyear()"
               
              >
                <mat-option [value]="null">
                  -- Select Financial Year ---
                </mat-option>
                <mat-option
               
                  *ngFor="let row of finYearList; let i = index"
                  [value]=" row.financialYearId "
                  >{{ row.financialFromMonth }}-{{ row.financialFromYear }} To
                  {{ row.financialToMonth }}-{{
                    row.financialToYear
                  }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.financialyear.invalid &&
                    validate.financialyear.touched) ||
                  validate.financialyear.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.financialyear.errors?.required"
                  class="text-danger"
                  >Please Select Financial Year</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Period<span class="star"> *</span></label
              >
              <mat-select
                formControlName="period"
                placeholder="Select Period"
                class="form-control email-input select-placeholder"
              >
                <mat-option [value]="null"> -- Select Period --- </mat-option>
                <mat-option
                  *ngFor="let row of finPeriodList; let i = index"
                  [value]="row.financialPeriodId "
                  >{{ row.financialperiodMonth }}-{{
                    row.financialPeriodYear
                  }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.period.invalid && validate.period.touched) ||
                  validate.period.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.period.errors?.required"
                  class="text-danger"
                  >Please Select Period</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Remarks<span class="star"></span
              ></label>
              <input
                type="text"
                formControlName="remarks"
                placeholder="Enter the Remarks"
                class="form-control email-input select-placeholder"
              />
              <!-- <div class="valid-msg" *ngIf="(validate.remarks.invalid && validate.remarks.touched) || validate.remarks.dirty || submitted">
                                        <span *ngIf="validate.remarks.errors?.required" class="text-danger">Please select remarks</span>
                                    </div> -->
            </div>
          </div>
          <div *ngIf="!loader">
            <div
              class="row pb-3"
              *ngIf="selectedEmployeeData.length > 5  || empratingId != null"
            >
              <div class="col-md-11 text-right">
                <span class="show-count">Show :</span>
              </div>
              <div class="col-md-1 text-left pl-0">
                <select
                  [(ngModel)]="pagePerItem"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onItemsPerPageChange($event)"
                  class="select_list new-select_list listDesign"
                >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>

          <div *ngIf="!loader && tableShow">
            <table
              style="border-radius: 5px"
              class=" table --table-bordered table-border-new mt-2"
              *ngIf="selectedEmployeeData.length != 0 || empratingId != null  "
            >
              <thead class="t-head">
                <tr>
                  <th class="col-md-2">Action</th>
                  <th class="col-md-3">Employee Code</th>
                  <th class="col-md-3">Employee Name</th>
                  <th class="col-md-4">Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  formArrayName="Recovery"
                  *ngFor="
                    let group of getFormControls.controls
                      | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                      | searchFilter : tableSearch;
                    let i = index
                  "
                >
                  <ng-container *ngIf="p == 1; else nextpage">
                    <tr formGroupName="{{ i }}">
                      <td class="text-center" style="">
                        <!-- <button mat-menu-item type="button" > -->
                        <mat-icon (click)="deleteemployee(i)" style="color: red"
                          >delete</mat-icon
                        >
                        <!-- </button> -->
                      </td>
                      <td>{{ group.value.employeeNumber }}</td>
                      <td>
                        {{ group.value.firstName }} {{ group.value.lastName }}
                      </td>
                      <!-- <td>
                                                            <input type="text" formControlName="amount" 
                                                            placeholder="Select Amount"
                                                        
                                                            class="example-input designchange form-control" (keypress)="onlyNumber($event)"><br>
                                                                      <mat-error
                                                                      *ngIf="submitted || amountFormArray.controls[i].get('amount')?.touched && amountFormArray.controls[i].get('amount')?.invalid">
                                                                      <span
                                                                          *ngIf="amountFormArray.controls[i].get('amount')?.hasError('required')">Please
                                                                          Enter Amount.</span>
                                                                      <span
                                                                          *ngIf="amountFormArray.controls[i].get('amount')?.hasError('notZero')">
                                                                          Please Enter Vaild Amount</span>
                                                                  </mat-error>
                                                        </td> -->

                      <td class="center-input">
                        <input
                          style="margin-left: 57px !important"
                          type="text"
                          formControlName="amount"
                          placeholder="Enter the Amount"
                          class="example-input designchange form-control"
                          (keypress)="onlyNumber($event)"
                          (paste)="onPaste($event)" 

                        />
                        <!-- <br /> -->
                        <mat-error
  *ngIf="
    (submitted || amountFormArray.controls[i].get('amount')?.dirty) &&
    amountFormArray.controls[i].get('amount')?.invalid
  "
>
  <span
    style="margin-left: -91px;"
    *ngIf="amountFormArray.controls[i].get('amount')?.hasError('required')"
    >Please Enter Amount.</span
  >
  <span
    *ngIf="amountFormArray.controls[i].get('amount')?.hasError('notZero')"
    >Please Enter Valid Amount</span
  >
</mat-error>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #nextpage>
                    <tr formGroupName="{{ i + startIndex }}">
                      <td class="text-center" >
                        <!-- <button mat-menu-item type="button" > -->
                        <mat-icon style="color: red" (click)="deleteemployee(i)"
                          >delete</mat-icon
                        >
                        <!-- </button> -->
                      </td>
                      <td>{{ group.value.employeeNumber }}</td>
                      <td>
                        {{ group.value.firstName }} {{ group.value.lastName }}
                      </td>
                      <td>
                        <input
                          type="text"
                          formControlName="amount"
                          placeholder="Enter the Amount"
                          class="form-control email-input select-placeholder"
                          (keypress)="onlyNumber($event)"
                        /><br />
                        <mat-error
                          *ngIf="
                            submitted ||
                            (amountFormArray.controls[i + startIndex].get(
                              'amount'
                            )?.touched &&
                              amountFormArray.controls[i + startIndex].get(
                                'amount'
                              )?.invalid)
                          "
                        >
                          <span
                            *ngIf="
                              amountFormArray.controls[i + startIndex]
                                .get('amount')
                                ?.hasError('required')
                            "
                            >Please Enter Amount.</span
                          >
                          <span
                            *ngIf="
                              amountFormArray.controls[i + startIndex]
                                .get('amount')
                                ?.hasError('notZero')
                            "
                          >
                            Please Enter Vaild Amount</span
                          >
                        </mat-error>
                      </td>
                    </tr>
                  </ng-template>
                </ng-container>
              </tbody>

              <!-- <tbody>
                                        <tr>
                                            <td style="text-align: center;" colspan="5" *ngIf="noRecordsFound">No matching records found!</td>
                                        </tr>
                                    </tbody>  -->
            </table>
          </div>

          <div *ngIf="selectedEmployeeData?.length > 0 &&!loader">
            <div class="row">
              <div class="col-md-4">
                <div class="showing-record">
                  {{ selectedEmployeeData.length }} rows
                </div>
              </div>
              <div
                class="col-md-8 text-right"
                *ngIf="selectedEmployeeData?.length > 5 && !loader"
              >
                <pagination-controls
                  style="float: right"
                  (pageChange)="onPageChange($event)"
                ></pagination-controls>
              </div>
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-md-12 text-right">
              <!-- <button type="button" class="submit action-button btn mr-2 btn-sm" mat-raised-button [routerLink]="['home/employee-rating-list']"
                                        style="margin-right: 20px;" (click)="close()">Cancel</button> -->
              <button
                type="button"
                class="submit"
                mat-raised-button
                (click)="close()"
                [routerLink]="['home/employee-rating-list']"
                style="
                  background: none;

                  border: 1px solid #ddd;
                  font-size: 14px;
                  font-weight: 300;
                  height: 40px !important;
                "
                style="
                  color: var(--Red-Color, #ff5d5d);
                  height: 35px;
                  margin-right: 1%;
                "
              >
                Cancel
              </button>
              <!-- <button type="submit" class="submit action-button btn btn-primary btn-sm">Save</button> -->
              <button
                mat-raised-button
                type="submit"
                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                [disabled]="isprocessing1 || recoveryForm.invalid"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
    role="status"
  ></div>
</div>
