import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { PayrollService } from '../providers/payroll.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../providers/settings.service';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { AppComponent } from '../app.component';
import { GlobalserviceService } from '../providers/globalservice.service';
import { AdminService } from '../providers/admin.service';
import { SuperadminService } from '../providers/superadmin.service';

@Component({
  selector: 'app-assignclientsupplier',
  templateUrl: './assignclientsupplier.component.html',
  styleUrls: ['./assignclientsupplier.component.scss']
})
export class AssignclientsupplierComponent implements OnInit {
  clientassignform: FormGroup;
  employeeList: any = [];
  ApproverList: any = [];
  empId :any;
  empolyeereadonly :boolean = false;
  empolyeeapproverobject: any = {};
  empolyeeapproverexist: boolean;
  ruleNameexist: boolean;
  organzationId :any;
  submitted1=false;
  loader: boolean;
  success: boolean;
  success_msg: any;
  error: boolean;
  error_msg: any;
  btnDisable: boolean=false;
  selectedEmployeeData: any = [];
  clientList: any = [];
  approverId: string;
  data: any;
  p: number = 1;
  pagePerItem: any = 10;
  dateFormat: any;
  employee: string;
  employeeId: any;
  postdatamultiple: any=[];
  userdata: any;
  approvertype:any =[];
  private unsubscribe$: Subject<void> = new Subject<void>();
  employeelistsub: any;
  disableFeildforAll:boolean=false;
readonlyDisable: boolean=false;
employeeNotFound: boolean = false;
  supplierList: any=[];
  storeEntityId: any;
  storeEmpNumber: any;

  constructor(
    // public dialogRef: MatDialogRef<ManageEmployeeApproverComponent>,
    private router:Router,
    // @Inject(MAT_DIALOG_DATA) public data: any, 
    private fb: FormBuilder, 
    private gs: GlobalserviceService , 
    private superadmin:SuperadminService,
    // private dialog:MatDialog,
    // private dialogref : MatDialogRef<ManageEmployeeApproverComponent>,
    private ActivatedRoute:ActivatedRoute,
    private payrollService: PayrollService,
    @Inject(AppComponent) public AppData: any ,  
    ) { }

  ngOnInit(): void {
    // this.dateFormat = this.AppData.dateFormat;
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =this.userdata.organization.organizationId;
    
    this.storeEntityId=this.userdata.entity.entityId;

    this.dateFormat = localStorage.getItem('dateFormatName');
    console.log("organziationid " + this.organzationId);
   
    this.clientassignform = this.fb.group({
   
      client: ['', Validators.required],
      supplier: ['', Validators.required],
      // approverType: ['', Validators.required]
    
    })

    this.empId = this.userdata.employeeId;
    this.approverId = this.ActivatedRoute.snapshot.params.id;
    // this.gs.getAllEmployeeByOrganization(this.organzationId).subscribe(
    //   (d: any[]) => {
    //     console.log(d);
    //     this.employeeList = d;
    //     console.log(this.employeeList);
    //   }
    // )
    this.superadmin.getActiveSupplierEntity(this.storeEntityId).subscribe((response:any)=>{
      console.log("response:",response);
      
      this.supplierList=response;
    })
    // this.loader=true;
    // this.gs.getApprovers(this.organzationId).subscribe(
    //   (d: any[]) => {
    //     console.log(d);
    //     this.loader=false;

    //     this.ApproverList= d;
     
    //   }
    // )
    // this.gs.getApproverType().subscribe(
    //   (approvertype:any)=>{
    //     this.supplierList=approvertype;
    //      console.log("this.supplierList",this.supplierList);
    //   }
    // )
  }
  

  //control values of form values
  get f() {
    return this.clientassignform?.controls;
  }
  // empAllSelect()
  // {
    
  //   if(this.clientassignform.value.employee == "All")
  //   {
  //     this.disableFeildforAll=false;
  //     this.empolyeereadonly=true;

  //     // this.selectedEmployeeData=[]
  //     this.loader=true
  //     this.payrollService.getAllEmployeeByOrganization(this.organzationId).subscribe((result: any) => {
  //        if(result.statusCode){
             
  //        }else{
  //          console.log("else");
  //          this.clientassignform.patchValue({
  //           employee: null
  //        });
  //        this.loader=false
  //        this.empolyeereadonly=true;

  //         this.clientList = result;
  //         console.log(" this.clientList",this.clientList);
  //         console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
  //            result.forEach(element => {
  //             let  hasemployee =   this.selectedEmployeeData.some(e=> e.employeeId == element.employeeId);
  //             if(!hasemployee)
  //             {
  //               this.selectedEmployeeData.push(element);
  //             }
  //             // console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
  //            });
  //           //  console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
  //        }
  //     })
  //   }
  // }
  empAllSelect() {
    if (this.clientassignform.value.client === "All") {
      // Disable the field immediately after selecting "All"
      this.disableFeildforAll = true;
      this.empolyeereadonly = true;
      this.readonlyDisable=true;
  
      this.loader = true;
      this.payrollService.getAllEmployeeByOrganization(this.organzationId).subscribe(
        (result: any) => {
        if (result.statusCode) {
          // Handle error response
          console.error("Error response:", result);
        } else {
          console.log("else");
          this.clientassignform.patchValue({
            client: null
          });
          this.clientList = result;
          console.log("this.clientList", this.clientList);
          console.log("this.selectedEmployeeData", this.selectedEmployeeData);
          result.forEach(element => {
            let hasemployee = this.selectedEmployeeData.some(e => e.clientId === element.clientId);
            if (!hasemployee) {
              this.selectedEmployeeData.push(element);
            }
          });
          console.log("this.selectedEmployeeData", this.selectedEmployeeData);
        }
        // Enable the field after processing the response
        this.disableFeildforAll = false;
        this.empolyeereadonly = false;
        this.loader = false;
        this.readonlyDisable=false;

      }, error => {
        // Handle error during the HTTP call
        console.error("Error fetching employees:", error);
        this.disableFeildforAll = false;
        this.empolyeereadonly = false;
        this.readonlyDisable=false;
        this.loader = false;
      });
    }
  }

  clearEmpArray(){
    this.clientList=[]
  }
  supplierSearch(event){
    this.postdatamultiple = [];
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    var employeenumber = event.target.value;
    this.storeEmpNumber=employeenumber
    console.log(employeenumber);
    
    // Employee List API
    if(employeenumber.length >= 2){
      if(this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
      this.employeelistsub = this.superadmin.getSeachClientData(this.storeEmpNumber,this.storeEntityId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.superadmin.getSeachClientData(this.storeEmpNumber,this.storeEntityId))
        )
        .subscribe((result: any) => {
          this.clientList = [];
          
          if (result.statusCode) {
            console.log(result.statusCode);
            this.clientList = [];
            this.employeeNotFound = true; // Set the flag if status code indicates no employees
          } else {
            this.clientList = result;
            if (this.clientList.length === 0) {
              this.employeeNotFound = true; // Set the flag if the list is empty
            }
          }
        
  
        })
      }
  }
  empSearch(event){ 
    // this.empolyeereadonly=false;
    this.postdatamultiple = [];
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    var employeenumber = event.target.value;
    this.storeEmpNumber=employeenumber
    console.log(employeenumber);
    
    // Employee List API
    if(employeenumber.length >= 2){
      if(this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
      this.employeelistsub = this.superadmin.getSeachClientData(this.storeEmpNumber,this.storeEntityId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.superadmin.getSeachClientData(this.storeEmpNumber,this.storeEntityId))
        )
        .subscribe((result: any) => {
          this.clientList = [];
          
          if (result.statusCode) {
            console.log(result.statusCode);
            this.clientList = [];
            this.employeeNotFound = true; // Set the flag if status code indicates no employees
          } else {
            this.clientList = result;
            if (this.clientList.length === 0) {
              this.employeeNotFound = true; // Set the flag if the list is empty
            }
          }
        
  
        })
      }
    }
  
  noEmployeeFound() {
    this.employeeNotFound = false;
    // this.selectedemployeeId = undefined;
    // this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    // this.employeeProjects.length = 0;
    this.employee = undefined;
    // this.employeeNumber = undefined;
    this.employeeNotFound=false
  }
  empSelect(emp)
  { 
    this.postdatamultiple =[];
    // this.employeelistsub=[]

    this.disableFeildforAll=false;
    this.empolyeereadonly=false;
    this.readonlyDisable=false;
    console.log("emp",emp);

    this.employeeId=emp.employeeId;
    this.employee=emp.clientCode+"-"+emp.clientName;
    console.log("client wt its::",this.employee);
    if(this.approverId){
      console.log("if");
      this.clientassignform.patchValue({
        client: this.employee 
     });
    }else{
      console.log("else");
      this.clientassignform.patchValue({
        client: null
       });
    // console.log("this.selectedEmployeeData", this.selectedEmployeeData);
    
        let  hasemployee =   this.selectedEmployeeData.some(e=> e.clientId == emp.clientId);
          if(!hasemployee)
          {
            this.selectedEmployeeData.push(emp);
            console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
          }
          console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
    }
    if (this.selectedEmployeeData.length >= 1) {
      this.clientassignform.get('client')?.clearValidators();
    } else {
      this.clientassignform.get('client')?.setValidators([Validators.required]);
    }
    this.clientassignform.get('client')?.updateValueAndValidity();
  
  }

  pageChages() {
    this.p = 1;
  }
  deleteemployee(index) {
    if (index != -1) {
      this.selectedEmployeeData.splice(index, 1);
    }
  }
  clearemployeelist(){
    this.selectedEmployeeData =  [];
  }
  clearForm() {
    // this.legalEntitygroup.reset();
    this.router.navigate(['/home/ClientSup-list']);

  }
  submit(){
    console.log("form::",this.clientassignform.invalid);
    
    this.submitted1=true;
    console.log(this.clientassignform.invalid);
    console.log(this.empolyeeapproverexist ==true);
    console.log(this.selectedEmployeeData.length ==0);
    if (this.selectedEmployeeData.length === 0 || 
      (this.selectedEmployeeData.length <= 1 && this.clientassignform.invalid)) {
    return;
  }
 
    let proappform = this.clientassignform.value;
    console.log(proappform);
    // if(this.approverId)
    // {         
    //   console.log("update form");
    //   console.log(proappform);   
    //   var employeeapproverupdate  = 
    //   {          
    //     empolyeeapproverId : this.approverId,
    //     employeeApproverStatus: this.data.employeeApproverStatus.listTypeValueId,
    //     employee: {
    //     employeeId: proappform.employee
    //     },
    //     approvers: {
    //     approverId:  proappform.approver
    //     },
    //     organization: {
    //       organizationId: this.organzationId
    //     },  
    //     approverTypeId : proappform.approverType,
    //     status : {
    //       listTypeValueId: this.data.status.listTypeValueId
    //     }
              
    //   }
      
    //   console.log(employeeapproverupdate);
    //   this.gs.updateEmployeeApprover(employeeapproverupdate , this.data.empolyeeapproverId)
    //   .subscribe((a:any )=> 
    //   {
    //     this.loader = false;
    //     this.success = true;
    //     this.success_msg = a.message;
    //     setTimeout(() => {
    //       this.success  = false;
    //       this.router.navigate(['/home/ClientSup-list']);
    //     }, 5000);
    //   },
    //   err =>{
    //     this.btnDisable = false;
    //     this.loader = false;
    //     this.error = true;
    //     this.error_msg = err.error.message;
    //     setTimeout(() => {
    //       this.error = false;
    //     }, 500);
    //   })
    // }
    // else
    // {
      // this.loader=true;
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      this.postdatamultiple=[];
      this.selectedEmployeeData.forEach((element: any) => {
        console.log("element::",element);
        
        let employeeapprover = {
         
          "client":{
            clientId:element.clientId,
          } ,
          
          "supplier": {
            "supplierId": proappform.supplier
          },
          
          // status : {
          //   listTypeValueId: 1
          // }
        };
        this.postdatamultiple.push(employeeapprover);
      });
      console.log("payload::",this.postdatamultiple);

    
      if(this.clientassignform.controls['supplier'].value) {
        console.log("if entered!!");
        this.btnDisable = true;

      this.superadmin.createClientSupplier(this.postdatamultiple).subscribe(
      (a:any) => {
        this.btnDisable = true;
        console.log();
        

if(a.statusCode==200){
  this.loader = false;
        this.success = true;
        this.success_msg = a.message;
        this.btnDisable = true;
        setTimeout(() => {
          this.success  = false;
          this.router.navigate(['/home/ClientSup-list']);
        }, 5000);

}
        
      },
      (err) =>{
        console.log(err);
        this.btnDisable = false;
        this.loader = false;
        this.error = true;
        this.error_msg = err.error.message;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      })   
    }                  
    }
  // }
 
  employeeapprovervalidate(){
    console.log("project : " +  this.clientassignform.value.employee);    
    if(!this.data)
    {
      if(this.clientassignform.value.employee != 0)
      {           
        this.gs.getEmployeeApproversvalidate(this.clientassignform.value.employee).subscribe(a => 
        {
          console.log(a);
          this.empolyeeapproverobject = a;
          if(this.empolyeeapproverobject.isexist)
          {
            console.log("employee name isexist");
            this.empolyeeapproverexist = true;
          }
          else{
            this.empolyeeapproverexist = false;
          }
        })
      }
      else{
        this.empolyeeapproverexist = false;
      }
    }   
  }
}

