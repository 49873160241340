import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { EMPTY } from 'rxjs';
import { isEmpty } from 'rxjs-compat/operator/isEmpty';
import { MatChipInputEvent } from '@angular/material/chips';
// import { MatChipEditedEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { HeaderService } from 'src/app/providers/header.service';
import { environment } from 'src/environments/environment';
export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-requirement-add',
  templateUrl: './requirement-add.component.html',
  styleUrls: ['./requirement-add.component.scss'],
})
export class RequirementAddComponent implements OnInit {
  resourceform: FormGroup;
  positioncraetedata: any;
  positionusertype: any;
  jobtypeId: any;
  mydata: any = [];
  myFile: any = [];
  isShow: boolean;
  extension: any;
  usertype: any;
  employeeId: string;
  error_msg: any;
  sucess_msg: any;
  loader: any = false;
  backdrop: any = false;
  success: any = false;
  error: any = false;
  positionId: any;
  requirementId: any;
  userdata: any = [];
  showclientname: boolean = false;
  jobtype: any;
  noticeperiod: any;
  clientname: any;
  positionlistdata: any;
  clientlistdata: any = [];
  maxExp: any = true;
  today: any = new Date();
  minexpmaxlength: any = 2;
  maxexpmaxlength: any = 2;
  noticeP: any;
  noticeParray: any = [];
  listTypeValue: any;
  noticePatcharray: any = [];
  positionName: any;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  skillsarray: Fruit[] = [];
  fruitsdata: any;
  skillsdata: any = [];
  finaldata: any = [];
  submmited: boolean = false;
  date: string;
  upDate: string;
  fileName: any;
  buttonDisabled: boolean = false;
  isSkillValid: boolean = true;
  fileChoosen: boolean[] = [];
  filename: any;
  downloadbaseurl = environment.rec_base_url;
  showFileInput: boolean = false;
  fileInputLabel: string;
  storeSourfileName: string;
  newFileSelected: boolean= false;
  orgId: any;

  constructor(
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    private requirementservice: HrmsserviceService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private headerservice: HeaderService,

    private positionservice: RecruitmentService
  ) {}

  ngOnInit(): void {
    // this.headerservice.setTitle('Recruitment ');

    ///localhost stroage data
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    this.orgId=this.usertype.organization.organizationId
    console.log(this.usertype);

    this.employeeId = this.usertype.employeeId;
    console.log(this.employeeId);
    let organizationId = this.usertype.organization.organizationId;

    //hiringfor
    this.requirementservice.hiringfor().subscribe((data: any) => {
      this.positionusertype = data;
      console.log('positionusertype::', this.positionusertype);
    });

    this.positionservice
      .getclientlist(organizationId)
      .subscribe((resultData: any) => {
        console.log(' resultData', resultData);
        resultData.forEach((item: any) => {
          if (item.status.listTypeValueId == 1) {
            console.log(item.status.listTypeValueId);
            this.clientlistdata.push(item);
            console.log(' this.clientlistdata', this.clientlistdata);
          }
        });
      });
    //   //clientname
    //   this.requirementservice
    //   .clientList(organizationId)
    //  .subscribe((client:any) =>
    //   {
    //     this.clientlistdata = client;
    //    // this.patchresource();
    //     console.log(this.clientlistdata);
    //   })
    // position active list
    this.requirementservice.positionactivelist(this.orgId).subscribe((position: any) => {
      this.positionlistdata = position;
      this.patchresource();
      console.log(this.positionlistdata);
    });

    //noticeperiod
    this.requirementservice.noticeperiod().subscribe((data: any) => {
      this.noticeperiod = data;
      console.log(this.noticeperiod);
    });

    this.requirementservice.jobtypebyid().subscribe((data: any) => {
      this.jobtype = data;
      console.log(this.positionusertype);
    });

    if (this.ActivatedRoute.snapshot.params.recuirementId) {
      this.loader=true;
      this.resourceform = new FormGroup({
   
        // positionName : new FormControl(this.resourceform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
        position: new FormControl(this.resourceform, [Validators.required]),
        // requirementNo:new FormControl(this.resourceform,[Validators.required]),
        maxExperience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^(\d)*(\.)?([0-9]{1})?$/),
          this.noSpecialCharactersValidator()

        ]),
        minExperience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^(\d)*(\.)?([0-9]{1})?$/),this.noSpecialCharactersValidator()

        ]),
        client: new FormControl(this.resourceform),
        location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        // ctc: new FormControl(this.resourceform, [Validators.required, Validators.pattern(/^[0-9]\d{0,10}$/)]),
        ctc: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[1-9]\d{0,10}$/),
        ]),

        jobType: new FormControl(this.resourceform, [Validators.required]),
        hiring: new FormControl(this.resourceform, [Validators.required]),
        noticePeriod: new FormControl(this.resourceform, [Validators.required]),
        // noticePeriod: this.fb.array([]),
        jobDescriptionAttachment: new FormControl(this.resourceform),
        expiryDate: new FormControl(this.resourceform, [Validators.required]),
        // skills : new FormControl(this.resourceform,[Validators.required]),
        // skills: new FormControl(this.resourceform, [Validators.required, Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),

        skills: new FormControl(this.resourceform),
        // jobDescription: new FormControl(this.resourceform, [Validators.required]),
        jobDescription: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z-]+(([. ][a-zA-Z- ])?[a-zA-Z-]*)*$/),
        ]),
      });
    } else {
      console.log("elsse!!!");
      
      // alert("else");
      this.today = new Date();
      this.resourceform = new FormGroup({
        position: new FormControl(this.resourceform, [Validators.required]),
        // requirementNo:new FormControl(this.resourceform,[Validators.required]),
        // positionName : new FormControl(this.resourceform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
        maxExperience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^(\d)*(\.)?([0-9]{1})?$/),
              this.noSpecialCharactersValidator()

        ]),
        minExperience: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^(\d)*(\.)?([0-9]{1})?$/),
          this.noSpecialCharactersValidator()

        ]),
        client: new FormControl(this.resourceform),
        location: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ]),
        ctc: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[1-9]\d{0,10}$/),
        ]),
        jobType: new FormControl(this.resourceform, [Validators.required]),
        hiring: new FormControl(this.resourceform, [Validators.required]),
        noticePeriod: new FormControl(this.resourceform, [Validators.required]),
        // noticePeriod: this.fb.array([]),
        jobDescriptionAttachment: new FormControl(this.resourceform),
        expiryDate: new FormControl(this.resourceform, [Validators.required]),
        // skills : new FormControl(this.resourceform,[Validators.required]),
        // skills: new FormControl(this.resourceform),
        // skills: new FormControl(this.resourceform,[Validators.required, Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
        // skills: new FormControl(this.resourceform, [ Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
        skills: new FormControl(this.resourceform),

        // jobDescription: new FormControl(this.resourceform, [Validators.required]),
        jobDescription: new FormControl(this.resourceform, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z-]+(([. ][a-zA-Z- ])?[a-zA-Z-]*)*$/),
        ]),
      });
    }

    // this.updateSkillValidator()
    // this.projectDescriptionError()
  }
  // skillsArrayValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     if (this.skillsarray.length > 0) {
  //       this.isSkillValid = false;
  //       return null; // No error if skillsarray length is greater than 0
  //     } else {
  //       this.isSkillValid = false;
  //       return { required: true }; // Error if skillsarray length is 0
  //     }
  //   };
  // }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    console.log(this.skillsarray);
    console.log(this.skillsarray.length);

    // Add our fruit
    if ((value || '').trim()) {
      this.skillsarray.push({ name: value.trim() });
      console.log(this.skillsarray);
    }
    // if(this.skillsarray.length>0){
    //   console.log("if condition legth greter 0");

    //   this.isSkillValid = false;
    // }
    // else{
    //   this.isSkillValid=true
    // }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    // this.updateSkillValidator();
  }

  noSpecialCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = /[!@#$%^&*(),.?":{}|<>]/.test(control.value);
      return forbidden ? { specialCharacters: true } : null;
    };
  }
  updateSkillsValidator() {
    this.resourceform.controls['skills'].updateValueAndValidity();
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  remove(fruit: Fruit): void {
    const index = this.skillsarray.indexOf(fruit);

    if (index >= 0) {
      this.skillsarray.splice(index, 1);
      console.log(this.skillsarray);
    }
    // this.updateSkillValidator();
  }
  fruitConcat() {
    let result;
    let text;
    this.skillsarray.forEach((element) => {
      text = element.name;
      if (result == undefined) {
        result = text;
        console.log(result);
        this.fruitsdata = result;
      } else {
        result = result + ',' + text;
        console.log(result);
        this.fruitsdata = result;
      }
    });
  }

  // edit(fruit: Fruit, event: MatChipEditedEvent) {
  //   const value = event.value.trim();

  //   // Remove fruit if it no longer has a name
  //   if (!value) {
  //     this.remove(fruit);
  //     return;
  //   }

  //   // Edit existing fruit
  //   const index = this.skillsarray.indexOf(fruit);
  //   if (index >= 0) {
  //     this.skillsarray[index].name = value;
  //   }
  // }
  keyUpmaxexp(event) {
    let minExperience = +this.resourceform.value.minExperience;
    let maxExperience = +this.resourceform.value.maxExperience;
    console.log('MIN: ' + minExperience);
    console.log('MAX: ' + maxExperience);

    if (
      this.resourceform.controls.minExperience.invalid ||
      this.resourceform.controls.maxExperience.invalid
    ) {
      this.maxExp = true;
    } else {
      if (maxExperience >= minExperience) {
        console.log('MAX 1: ');
        this.maxExp = true;
      } else {
        console.log('MAX 2: ');
        this.maxExp = false;
      }
    }
  }
  get getFormControls() {
    return this.resourceform.get('jobDescriptions') as FormArray;
  }
  checkdecimal() {
    if (this.resourceform.value.minExperience.includes('.')) {
      // not an decimal
      console.log(' Decimal');
      this.minexpmaxlength = 3;
    } else {
      console.log(' No Decimal');
      this.minexpmaxlength = 2;
    }
  }

  checkdecimalmax() {
    if (this.resourceform.value.maxExperience.includes('.')) {
      // not an decimal
      console.log(' Decimal');
      this.maxexpmaxlength = 3;
    } else {
      console.log(' No Decimal');
      this.maxexpmaxlength = 2;
    }
  }

  patchresource() {
    // this.loader = true;

    console.log(
      'this.activatedRoute.snapshot.params.requirementId',
      this.ActivatedRoute.snapshot.params.recuirementId
    );
    if (this.ActivatedRoute.snapshot.params.recuirementId) {
      this.loader = true;
      this.requirementId = this.ActivatedRoute.snapshot.params.recuirementId;
      console.log(this.requirementId);
      this.loader = true;

      this.requirementservice
        .recuirementbyid(this.ActivatedRoute.snapshot.params.recuirementId)
        .subscribe((data: any) => {
          this.userdata = data;
          this.loader = false;

          console.log(this.userdata);

          for (let i = 0; i < this.userdata.noticePeriod.length; i++) {
            this.noticePatcharray.push(
              this.userdata.noticePeriod[i].listTypeValueId
            );
          }
          console.log('noticePatcharray length' + this.noticePatcharray.length);
          this.today = new Date(this.userdata.expiryDate);

          if (this.userdata.skills != undefined) {
            this.skillsdata = this.userdata.skills.split(',');
            console.log(this.skillsdata);
            let text;
            this.skillsdata.forEach((element) => {
              text = element;
              console.log(text);
              this.skillsarray.push({ name: text });
              console.log(this.skillsarray);
            });
          }

          this.resourceform.patchValue({
            // position:this.userdata.position.positionName,

            position: this.userdata.position.positionId,
            requirementNo: this.userdata.requirementNo,
            // positionName: this.userdata.positionName,
            maxExperience: this.userdata.maxExperience,
            minExperience: this.userdata.minExperience,
            // client :this.userdata.client.clientId,
            location: this.userdata.location,
            ctc: this.userdata.ctc,
            hiring: this.userdata.hiring.listTypeValueId,
            jobType: this.userdata.jobType.listTypeValueId,
            noticePeriod: this.noticePatcharray,
            expiryDate: this.userdata.expiryDate.substring(0, 10),
            // skills : this.userdata.skills,
            jobDescription: this.userdata.jobDescription,
            // jobDescriptionAttachment :
            // {
            //   sourceFileName:this.mydata.sourceFileName,
            //   sourceFileSize:this.mydata.sourceFileSize,
            //   sourceFileType:this.mydata.sourceFileType,
            //   sourceByteArray:this.mydata.sourceByteArray,
            //   fileDownloadUrl:this.mydata.fileDownloadUrl,
            //   fileViewUrl:this.mydata.fileViewUrl
            // }
            // this.userdata.jobDescriptionAttachment
          });
          if (this.userdata.hiring.listTypeValueId == 32) {
            this.showclientname = true;
            this.resourceform
              .get('client')
              .setValidators([Validators.required]);
          } else {
            console.log('inhouse');
            this.resourceform.controls['client'].setErrors(null);
            this.showclientname = false;
          }

          //   this.showclientname = this.userdata.hiring.listTypeValueId;
          //   if(this.userdata.client != undefined)
          //   {

          //   this.userdata.patchValue({
          //     client:this.userdata.client.clientId,
          //   });
          // }

          if (this.userdata.client != undefined) {
            this.resourceform.patchValue({
              client: this.userdata.client.clientId,
            });
          }

          if (this.resourceform.invalid) {
            //   alert("invalid")
            const controls = this.resourceform.controls;
            for (const name in controls) {
              if (controls[name].invalid) {
                console.log(name);
              }
            }
          }

          const controls = this.resourceform.controls;
          for (const name in controls) {
            if (controls[name].invalid) {
              console.log(name);
            }
          }
          this.resourceform.patchValue({
            jobDescriptionAttachment: this.userdata.jobDescriptionAttachment?.sourceFileName,
        });
        });
    }
  }

  positiondata(event) {
    console.log(event);
    this.maxExp = true;
    this.loader = true;
    this.skillsarray = [];
    this.requirementservice.positionbyid(event.value).subscribe((data: any) => {
      this.loader = false;
      if (data.noticePeriod != undefined) {
        this.noticePatcharray.length = 0;
        for (let i = 0; i < data.noticePeriod.length; i++) {
          this.noticePatcharray.push(data.noticePeriod[i].listTypeValueId);
        }
      }

      console.log(event);
      console.log('value' + event.value);
      if (data.hiring.listTypeValueId == 32) {
        this.showclientname = true;
        this.resourceform.get('client').setValidators([Validators.required]);
        this.resourceform.patchValue({
          client: data.clientId.clientId,
        });
      } else {
        console.log('inhouse');
        this.resourceform.controls['client'].setErrors(null);
        this.showclientname = false;
      }

      if (data.skills != undefined) {
        this.skillsdata = data.skills.split(',');
        console.log(this.skillsdata);
        let text;
        this.skillsdata.forEach((element) => {
          text = element;
          console.log(text);
          this.skillsarray.push({ name: text });
          console.log(this.skillsarray);
        });
      }

      // if (data.skills != undefined) {
      //   let stringdata = data.skills.split(",");
      //   stringdata.forEach((element) => {
      //     this.skillsarray.push({ name: element.trim() });
      //   })

      // }

      this.resourceform.patchValue({
        maxExperience: data.maxExperience,
        minExperience: data.minExperience,
        // client :this.userdata.client.clientId,
        location: data.location,
        ctc: data.ctc,
        hiring: data.hiring.listTypeValueId,
        jobType: data.jobType.listTypeValueId,
        noticePeriod: this.noticePatcharray,
        jobDescription: data.jobDescription,
        // skills:
      });
    });
  }
  // updateSkillValidator() {
  //   const skillsControl = this.resourceform.get('skills');

  //   if (this.skillsarray.length > 0) {
  //     this.isSkillValid=false
  //     console.log("if -not validation");

  //     skillsControl.clearValidators();
  //   } else {
  //     console.log("else validation");

  //     skillsControl.setValidators([Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]);
  //     // this.projectDescriptionError()
  //   }

  //   // skillsControl.updateValueAndValidity();
  // }

  listTypeValueid(event) {
    console.log(event);
    console.log('value' + event.value);
    if (event.value == 32) {
      this.showclientname = true;
      this.resourceform.get('client').setValidators([Validators.required]);
    } else {
      console.log('inhouse');
      this.resourceform.controls['client'].setErrors(null);
      this.showclientname = false;
      this.resourceform.controls['client'].setValue(null);
      // Clear all validators
      this.resourceform.get('client').clearValidators();
      // Update the validity state
      this.resourceform.get('client').updateValueAndValidity();
      console.log(this.resourceform);
    }
  }
  projectDescriptionError() {
    const skillsControl = this.resourceform.get('skills');
    return skillsControl?.hasError('required') ? 'Please Enter Skills*' : '';
  }

  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  submitData() {
    console.log(this.resourceform);

    this.buttonDisabled = true;
    this.submmited = true;
    this.error = false;
    this.success = false;
    this.noticeParray.length = 0;
    this.backdrop = true;
    this.loader = false;
    // let clientname ;
    //   if(this.resourceform.value.hiring == 32)
    //   {
    //     clientname = this.resourceform.value.client;

    //   }else{
    //     clientname = "";
    //   }
    // this.updateSkillValidator()

    // if(this.skillsarray.length == 0 ){
    //   console.log("Skills are required");

    //   return
    // }
    // if(this.resourceform.controls['skills'].value?.length>=0){
    //   this.isSkillValid=false
    // }
    if (this.resourceform.invalid) {
      console.log('invaild');
      // this.submmited=true
      this.buttonDisabled = false;
      this.loader = false;
      return;
    } else if (!this.resourceform.invalid && this.skillsarray.length == 0) {
      this.loader = false;
      return;
    }

    // if (this.resourceform.valid) {
    //   console.log('Form Submitted');
    // } else {
    //   console.log('Form is invalid');
    // }

    console.log('Submit');
    console.log('Id', this.requirementId);
    this.fruitConcat();

    if (this.requirementId) {
      //Update
      var requirementupdate;
      this.upDate = this.datepipe.transform(
        this.resourceform.value.expiryDate,
        'YYYY-MM-dd'
      );
      console.log(this.upDate);
      if (
        this.userdata.jobDescriptionAttachment != undefined &&
        this.mydata.length == 0
      ) {
        console.log('exsting  attachment');
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }
        requirementupdate = {
          // positionId : this.positionId,
          requirementId: Number(this.requirementId),
          position: this.resourceform.value.position,
          requirementStatus: this.userdata.requirementStatus,
          requirementNo: this.resourceform.value.requirementNo,
          // positionName : this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          //client :  {clientId:this.resourceform.value.client},
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          // jobDescription: this.resourceform.value.JobDescription,
          location: this.resourceform.value.location,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            jobDescriptionId:
              this.userdata.jobDescriptionAttachment.jobDescriptionId,
            sourceFileName:
              this.userdata.jobDescriptionAttachment.sourceFileName,
            sourceFileSize:
              this.userdata.jobDescriptionAttachment.sourceFileSize,
            sourceFileType:
              this.userdata.jobDescriptionAttachment.sourceFileType,
            sourceByteArray:
              this.userdata.jobDescriptionAttachment.sourceByteArray,
            fileViewUrl: this.userdata.jobDescriptionAttachment.fileViewUrl,
            fileDownloadUrl:
              this.userdata.jobDescriptionAttachment.fileDownloadUrl,
          },
          lastUpdatedBy: this.employeeId,
          status: this.userdata.status,
          // expiryDate : this.resourceform.value.expiryDate,
          expiryDate: this.upDate,
        };
        console.log('Update', requirementupdate);
      } else if (
        this.userdata.jobDescriptionAttachment == undefined &&
        this.mydata.length != 0
      ) {
        console.log('exsting attachment');
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }
        requirementupdate = {
          // positionId : this.positionId,
          requirementId: this.requirementId,
          position: this.resourceform.value.position,
          requirementNo: this.resourceform.value.requirementNo,
          requirementStatus: this.userdata.requirementStatus,
          // positionName : this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          // client :  {clientId:this.resourceform.value.client},
          location: this.resourceform.value.location,
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            // jobDescriptionId : this.userdata.jobDescriptionAttachment.jobDescriptionId,
            sourceFileName: this.mydata.sourceFileName,
            sourceFileSize: this.mydata.sourceFileSize,
            sourceFileType: this.mydata.sourceFileType,
            sourceByteArray: this.mydata.sourceByteArray,
          },
          lastUpdatedBy: this.employeeId,
          status: this.userdata.status,
          expiryDate: this.upDate,
          // expiryDate : this.resourceform.value.expiryDate,
        };
        // if(this.resourceform.value.client !=null && this.resourceform.value.hiring == 32){
        //   console.log("client conditio !!");
          
        //   requirementupdate['client'] = this.resourceform.value.client
        // }
        console.log('Update', requirementupdate);
      } else if (
        this.userdata.jobDescriptionAttachment != undefined &&
        this.mydata.length != 0
      ) {
        console.log('exsting attachment update');
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }
        requirementupdate = {
          position: {
            positionId: this.resourceform.value.position,
          },
          // positionId : this.positionId,
          requirementId: this.requirementId,
          requirementNo: this.resourceform.value.requirementNo,
          // positionName : this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          // client :  {clientId:this.resourceform.value.client},
          location: this.resourceform.value.location,
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            jobDescriptionId:
              this.userdata.jobDescriptionAttachment.jobDescriptionId,
            sourceFileName: this.mydata.sourceFileName,
            sourceFileSize: this.mydata.sourceFileSize,
            sourceFileType: this.mydata.sourceFileType,
            sourceByteArray: this.mydata.sourceByteArray,
            fileViewUrl: this.userdata.jobDescriptionAttachment.fileViewUrl,
            fileDownloadUrl:
              this.userdata.jobDescriptionAttachment.fileDownloadUrl,
          },
          lastUpdatedBy: this.employeeId,
          status: this.userdata.status,
          expiryDate: this.upDate,
          // expiryDate : this.resourceform.value.expiryDate,
        };
          // if(this.resourceform.value.client !=null && this.resourceform.value.hiring == 32){
        //   console.log("client conditio !!");
          
        //   requirementupdate['client'] = this.resourceform.value.client
        // }
        console.log('Update', requirementupdate);
      } else if (
        this.userdata.jobDescriptionAttachment == undefined &&
        this.mydata.length == 0
      ) {
        console.log('exsting attachment');
        this.noticeP = this.resourceform.value.noticePeriod;
        console.log(this.noticeP);
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }
        requirementupdate = {
          // positionId : this.positionId,
          requirementId: this.requirementId,
          position: {
            positionId: this.resourceform.value.position,
          },
          requirementNo: this.resourceform.value.requirementNo,
          // positionName : this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          // client :  {clientId:this.resourceform.value.client},
          location: this.resourceform.value.location,
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          // jobDescriptionAttachment:{
          //   jobDescriptionId : this.userdata.jobDescriptionAttachment.jobDescriptionId,
          //   sourceFileName:this.mydata.sourceFileName,
          //   sourceFileSize:this.mydata.sourceFileSize,
          //   sourceFileType:this.mydata.sourceFileType,
          //   sourceByteArray:this.mydata.sourceByteArray,
          //   fileViewUrl :   this.userdata.jobDescriptionAttachment.fileViewUrl,
          //   fileDownloadUrl : this.userdata.jobDescriptionAttachment.fileDownloadUrl
          // },
          lastUpdatedBy: this.employeeId,
          status: this.userdata.status,
          expiryDate: this.upDate,
          // expiryDate : this.resourceform.value.expiryDate,
        };
          if(this.resourceform.value.client !=null && this.resourceform.value.hiring == 32){
          console.log("client conditio !!");
          
          requirementupdate['client'] = this.resourceform.value.client
        }
        console.log('Update', requirementupdate);
      }

      if (
        this.resourceform.value.client != null &&
        this.resourceform.value.hiring == 32
      ) {
        // requirementupdate['client'] = {clientId:this.resourceform.value.client}
        requirementupdate['clientId'] = this.resourceform.value.client;
      } else {
        requirementupdate['clientId'] = 0;
      }

      console.log(requirementupdate);
      console.log(this.requirementId);

      this.requirementservice
        .recuirementupdate(this.requirementId, requirementupdate)
        .subscribe(
          (data: any) => {
            if (data.statusCode == 200) {
              console.log('success');
              this.success = true;
              this.loader=false;
              this.sucess_msg = data.description;

              setTimeout(() => {
                this.success = false;
                this.loader=false
                this.router.navigate(['/home/requirement']);
              }, 4000);
            } else {
              console.log('error');
              this.error = true;
              this.error_msg = data.description;
              setTimeout(() => {
                this.error = false;
              }, 4000);
            }
          },
          (err) => {
            this.backdrop = false;
            this.loader = false;
            this.error = true;
            this.error_msg = err.error.message;
            setTimeout(() => {
              this.error = false;
            }, redirectMsgTimeOut);
          }
        );
    }
    //Create
    else {
      console.log('exsting attachment Create');
      console.log(this.resourceform.value);
      this.date = this.datepipe.transform(
        this.resourceform.value.expiryDate,
        'YYYY-MM-dd'
      );
      console.log(this.date);
      var requrimentdata;
      if (this.mydata.length != 0) {
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }
        requrimentdata = {
          position: {
            positionId: this.resourceform.value.position,
          },
          requirementNo: this.resourceform.value.requirementNo,
          // positionName : this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          // client :  {clientId:this.resourceform.value.client},
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          location: this.resourceform.value.location,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          jobDescriptionAttachment: {
            sourceFileName: this.mydata.sourceFileName,
            sourceFileSize: this.mydata.sourceFileSize,
            sourceFileType: this.mydata.sourceFileType,
            sourceByteArray: this.mydata.sourceByteArray,
          },
          // expiryDate : this.resourceform.value.expiryDate,
          expiryDate: this.date,
          createdBy: this.employeeId,
        };
        console.log(requrimentdata);
        console.log(requrimentdata.jobDescriptionAttachment.length);
        console.log(
          'this.resourceform.value.client::',
          this.resourceform.value.client
        );

        if (this.resourceform.value.client != null) {
          console.log('if client !!!');

          // requrimentdata['client'] = {clientId:this.resourceform.value.client}
          requrimentdata['clientId'] = this.resourceform.value.client;
        } else {
          console.log('else client 0!!!');

          requrimentdata['clientId'] = 0;
        }
      } //Create
      else {
        console.log('no file');
        this.noticeP = this.resourceform.value.noticePeriod;
        for (let id of this.noticeP) {
          this.noticeParray.push({ listTypeValueId: id });
          console.log(this.noticeParray);
        }
        requrimentdata = {
          position: {
            positionId: this.resourceform.value.position,
          },

          //  requirementNo:this.resourceform.value.requirementNo,
          // positionName : this.resourceform.value.positionName,
          maxExperience: this.resourceform.value.maxExperience,
          minExperience: this.resourceform.value.minExperience,
          // client :  {clientId:this.resourceform.value.client},
          skills: this.fruitsdata,
          jobDescription: this.resourceform.value.jobDescription,
          location: this.resourceform.value.location,
          ctc: this.resourceform.value.ctc,
          hiring: { listTypeValueId: this.resourceform.value.hiring },
          jobType: { listTypeValueId: this.resourceform.value.jobType },
          noticePeriod: this.noticeParray,
          // jobDescriptionAttachment:{
          //   sourceFileName:this.mydata.sourceFileName,
          //   sourceFileSize:this.mydata.sourceFileSize,
          //   sourceFileType:this.mydata.sourceFileType,
          //   sourceByteArray:this.mydata.sourceByteArray
          // },
          // expiryDate : this.resourceform.value.expiryDate,
          expiryDate: this.date,
          createdBy: this.employeeId,
        };

        console.log(requrimentdata);
        //  console.log(positiondata.jobDescriptionAttachment.length);
        console.log(
          'this.resourceform.value.client::',
          this.resourceform.value.client
        );

        if (this.resourceform.value.client != null) {
          console.log('if condition!!!');

          // requrimentdata['client'] = {clientId:this.resourceform.value.client}
          requrimentdata['clientId'] = this.resourceform.value.client;
        } else {
          console.log('else client 0!!!');

          requrimentdata['clientId'] = 0;
        }
      }

      // console.log("Outsie",positiondata);
      // console.log("Outsie",positiondata.jobDescriptionAttachment.length);
      // return;
      this.backdrop = true;
      // this.loader = true;
      // return
      this.requirementservice.recuriementcreate(requrimentdata).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            console.log('success');
            this.success = true;
            this.loader=false;
            this.sucess_msg = data.description;

            setTimeout(() => {
              this.success = false;
              this.loader=false
              this.router.navigate(['/home/requirement']);
            }, 4000);
          } else {
            console.log('error');
            this.error = true;
            this.error_msg = data.description;
            setTimeout(() => {
              this.error = false;
            }, 4000);
          }
        },
        (err) => {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
          this.error_msg = err.error.message;
          setTimeout(() => {
            this.error = false;
          }, 4000);
        }
      );
    }
  }

  formcancel() {
    this.router.navigate(['home/requirement']);
  }

//   getFileDetails(event: any) {

//     const fileInput = event.target as HTMLInputElement;
//     const filePath = fileInput.value;
//     const allowedExtensions = /(\.(?!jpg|jpeg|png|gif|bmp|webp|tiff)[^\.]+)$/i;
    
//     if (!allowedExtensions.exec(filePath)) {
//       fileInput.value = '';
//       alert('Only non-image files are allowed!');
//       this.newFileSelected = true;

//       event.preventDefault();
//       return
//     } else {
//       if (event.target.files.length > 0) {
//         console.log("file if 1st!!");
        
//         const file = event.target.files[0];
//         this.fileInputLabel = file.name;
//         this.resourceform.get('jobDescriptionAttachment')?.setValue(file);
       
//         this.newFileSelected = true;
//         // event.preventDefault();


//       }
//     }
//     const files = event.target.files;
//     console.log(files);

//     if (files) {
//       console.log("files entered!!!");
      
//       // this.newFileSelected = true;
//         for (let i = 0; i < files.length; i++) {
//         console.log("here for entered");
        
//             if (files.length != 0) {
//               console.log("entered here!!!");
              
//                 this.isShow = true;
//                 this.newFileSelected = true;

//             }
//             const image = {
//                 sourceFileName: '',
//                 sourceFileType: '',
//                 sourceFileSize: '',
//                 sourceByteArray: '',
//                 fileUrlName: '',
//                 size: '',
//                 filePath: '',
//                 url: '',
//             };
         
//             console.log(image);
//             this.myFile.push(files[i]);
//             const file = files[0];
//             image.sourceFileName = files[i].name;
//             this.storeSourfileName= image.sourceFileName
//             console.log(" this.storeSourfileName::", this.storeSourfileName);
            
//             console.log('sourceFileName' + image.sourceFileName);
//             image.sourceFileType = files[i].type;
//             console.log('sourceFileType' + image.sourceFileType);
//             image.sourceFileSize = files[i].size;
//             console.log('sourceFileSize' + image.sourceFileSize);
//             image.size = image.size;
//             console.log('size' + image.size);
//             var totalBytes = files[i].size;
//             console.log('totalBytes' + totalBytes);

//             if (totalBytes < 1000000) {
//                 image.size = Math.floor(totalBytes / 1000) + 'KB';
//             } else {
//                 image.size = Math.floor(totalBytes / 1000000) + 'MB';
//             }
//             var ext = image.sourceFileName.substring(
//                 image.sourceFileName.lastIndexOf('.') + 1
//             );
//             console.log('image.sourceFileType' + image.sourceFileType);

//             if (
//                 image.sourceFileType.length == 0 &&
//                 (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc')
//             ) {
//                 image.sourceFileType =
//                     'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
//             }
//             const reader = new FileReader();

//             reader.onload = () => {
//                 image.filePath = reader.result + '';
//                 this.extension = image.sourceFileName.substring(
//                     image.sourceFileName.lastIndexOf('.') + 1
//                 );
//                 var ext = image.sourceFileName.substring(
//                     image.sourceFileName.lastIndexOf('.') + 1
//                 );

//                 let x: any = reader.result;
//                 let y: any = x.split(',');
//                 image.sourceByteArray = y[1];
//                 this.mydata = image;
//                 console.log(this.mydata);
//             };
//             reader.readAsDataURL(files[i]);
//         }
//     }
// }

getFileDetails(event: any) {


  const fileInput = event.target as HTMLInputElement;
  const filePath = fileInput?.value;
  const allowedExtensions = /(\.(?!jpg|jpeg|png|gif|bmp|webp|tiff)[^\.]+)$/i;
  
  if (!allowedExtensions.exec(filePath)) {
    fileInput.value = '';
    alert('Invalid File Type!');
    // this.newFileSelected = false;
    this.newFileSelected = true;
    event.preventDefault();
    return
  } else {
    if (event.target.files.length > 0) {
      console.log("file if 1st!!");
      this.newFileSelected = true;

      const file = event.target.files[0];
      this.fileInputLabel = file.name;
      this.resourceform.get('jobDescriptionAttachment')?.setValue(file);
     
      // event.preventDefault();


    }
  }
  console.log('att', event);
  const files = event.target.files;
  console.log('files', files);
  // if (files) {
  //   for (let i = 0; i < files.length; i++) {

  //     if (files.length != 0) {
  //       this.isShow = true;
  //       this.newFileSelected=true

  //     }
  //     const image = {
  //       sourceFileName: '',
  //       sourceFileType: '',
  //       sourceFileSize: '',
  //       sourceByteArray: '',
  //       fileUrlName: '',
  //       size: '',
  //       filePath: '',
  //       url: '',
  //     };

  //     const file = files[i];
  //     const fileType = file.type;
  //     const fileName = file.name;

  //     // Check if the file is an image
  //     // if (fileType.startsWith('image/')) {
  //     //   alert('Image files are not allowed.');
  //     //   this.newFileSelected=true
  //     //   return; // Skip processing this file
  //     // }

  //     this.fileName = fileName;
  //     console.log('this.fileName', this.fileName);
  //     console.log(image);
  //     this.myFile.push(file);
  //     image.sourceFileName = fileName;
  //     console.log('sourceFileName' + image.sourceFileName);
  //     image.sourceFileType = fileType;
  //     console.log('sourceFileType' + image.sourceFileType);
  //     image.sourceFileSize = file.size;
  //     console.log('sourceFileSize' + image.sourceFileSize);
  //     image.size = image.size;
  //     console.log('size' + image.size);
  //     var totalBytes = file.size;
  //     console.log('totalBytes' + totalBytes);

  //     if (totalBytes < 1000000) {
  //       image.size = Math.floor(totalBytes / 1000) + 'KB';
  //     } else {
  //       image.size = Math.floor(totalBytes / 1000000) + 'MB';
  //     }
  //     var ext = image.sourceFileName.substring(
  //       image.sourceFileName.lastIndexOf('.') + 1
  //     );
  //     console.log('image.sourceFileType' + image.sourceFileType);

  //     if (
  //       image.sourceFileType.length == 0 &&
  //       (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc')
  //     ) {
  //       image.sourceFileType =
  //         'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  //     }
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       image.filePath = reader.result + '';
  //       this.extension = image.sourceFileName.substring(
  //         image.sourceFileName.lastIndexOf('.') + 1
  //       );
  //       var ext = image.sourceFileName.substring(
  //         image.sourceFileName.lastIndexOf('.') + 1
  //       );

  //       let x: any = reader.result;
  //       let y: any = x.split(',');
  //       image.sourceByteArray = y[1];
  //       // this.mydata.push(image)
  //       this.mydata = image;
  //       console.log(this.mydata);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }
  if (files) 
    {
      for (let i = 0; i < files.length; i++) {
        if (files.length != 0) {
          this.isShow = true;
        }
        const image = {
          sourceFileName: '',
		      sourceFileType: '',
          sourceFileSize: '',
          sourceByteArray: '',
          fileUrlName:'',
          size: '',
          filePath: '',
          url: '',
        };
            console.log(image);
        this.myFile.push(files[i]);
        image.sourceFileName = files[i].name;
            console.log("sourceFileName"+image.sourceFileName);
        image.sourceFileType = files[i].type;
            console.log("sourceFileType"+image.sourceFileType);
        image.sourceFileSize = files[i].size;
            console.log("sourceFileSize"+image.sourceFileSize);
        image.size = image.size;
            console.log("size"+image.size);
        var totalBytes = files[i].size;
            console.log("totalBytes"+totalBytes);

        if (totalBytes < 1000000) {
          image.size = Math.floor(totalBytes / 1000) + 'KB';
        }
        else {
          image.size = Math.floor(totalBytes / 1000000) + 'MB';
        }
        var ext = image.sourceFileName.substring(image.sourceFileName.lastIndexOf('.') + 1);
        console.log("image.sourceFileType" + image.sourceFileType);

         if( image.sourceFileType.length == 0  && (ext.toLowerCase() == "docx" ||  ext.toLowerCase() == "doc"))
         {
          image.sourceFileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
         }
        const reader = new FileReader();

        reader.onload = () => {
          image.filePath = reader.result + '';
          this.extension = image.sourceFileName.substring(image.sourceFileName.lastIndexOf('.') + 1);
          var ext = image.sourceFileName.substring(image.sourceFileName.lastIndexOf('.') + 1);
        

          let x: any = reader.result;
          let y: any = x.split(',')
          image.sourceByteArray = y[1];
          // this.mydata.push(image)
          this.mydata=image;
          console.log(this.mydata);
        }
        reader.readAsDataURL(files[i])
      }
    }
}

  toggleFileInput() {
    this.showFileInput = !this.showFileInput;
  }

  //////get values from form //////
  get position() {
    return this.resourceform.get('position');
  }
  get requirementNo() {
    return this.resourceform.get('requirementNo');
  }
  get maxExperience() {
    return this.resourceform.get('maxExperience');
  }
  get minExperience() {
    return this.resourceform.get('minExperience');
  }
  get client() {
    return this.resourceform.get('client');
  }
  get location() {
    return this.resourceform.get('location');
  }
  get ctc() {
    return this.resourceform.get('ctc');
  }
  get jobType() {
    return this.resourceform.get('jobType');
  }
  get hiring() {
    return this.resourceform.get('hiring');
  }
  get noticePeriod() {
    return this.resourceform.get('noticePeriod');
  }
  get jobDescriptionAttachment() {
    return this.resourceform.get('jobDescriptionAttachment');
  }
  get expiryDate() {
    return this.resourceform.get('expiryDate');
  }
  get skills() {
    return this.resourceform.get('skills');
  }
  get jobDescription() {
    return this.resourceform.get('jobDescription');
  }
  noNumber(event: any) {
    // const inputValue = event.target.value;

    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  cancel() {
    this.router.navigate(['/home/requirement']);
  }
}
