import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-data-list',
  templateUrl: './client-data-list.component.html',
  styleUrls: ['./client-data-list.component.scss']
})
export class ClientDataListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
