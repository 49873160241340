<style>
    body {
        color: #1A237E;
        overflow-x: hidden;
        height: 100%;
        background-color: #B0BEC5;
        background-repeat: no-repeat;
    }
    
    .card0 {
        box-shadow: 0px 4px 8px 0px #757575;
        border-radius: 0px;
    }
    
    .card2 {
        margin: 0px 40px;
    }
    
    .logo {
        width: 200px;
        height: 70px;
        margin-top: 20px;
        margin-left: 35px;
    }
    
    .image {
        width: 360px;
        height: 280px;
    }
    
    .border-line {
        border-right: 1px solid #EEEEEE;
    }
    
    .facebook {
        background-color: #3b5998;
        color: #fff;
        font-size: 18px;
        padding-top: 5px;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        cursor: pointer;
    }
    
    .twitter {
        background-color: #1DA1F2;
        color: #fff;
        font-size: 18px;
        padding-top: 5px;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        cursor: pointer;
    }
    
    .linkedin {
        background-color: #2867B2;
        color: #fff;
        font-size: 18px;
        padding-top: 5px;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        cursor: pointer;
    }
    
    .line {
        height: 1px;
        width: 45%;
        background-color: #E0E0E0;
        margin-top: 10px;
    }
    
    .or {
        width: 10%;
        font-weight: bold;
    }
    
    .text-sm {
        font-size: 14px !important;
    }
    
    ::placeholder {
        color: #BDBDBD;
        opacity: 1;
        font-weight: 300;
    }
    
    :-ms-input-placeholder {
        color: #BDBDBD;
        font-weight: 300;
    }
    
    ::-ms-input-placeholder {
        color: #BDBDBD;
        font-weight: 300;
    }
    
    input, textarea {
        padding: 10px 12px 10px 12px;
        border: 1px solid lightgrey;
        border-radius: 2px;
        margin-bottom: 5px;
        margin-top: 2px;
        width: 100%;
        box-sizing: border-box;
        color: #2C3E50;
        font-size: 14px;
        letter-spacing: 1px;
    }
    
    input:focus, textarea:focus {
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: 1px solid #304FFE;
        outline-width: 0;
    }
    
    button:focus {
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        outline-width: 0;
    }
    
    a {
        color: inherit;
        cursor: pointer;
    }
    
    .btn-blue {
        background-color: #1A237E;
        width: 150px;
        color: #fff;
        border-radius: 2px;
    }
    
    .btn-blue:hover {
        background-color: #000;
        cursor: pointer;
    }
    
    .bg-blue {
        color: #fff;
        background-color: #1A237E;
    }
    
    @media screen and (max-width: 991px) {
        .logo {
            margin-left: 0px;
        }
    
        .image {
            width: 300px;
            height: 220px;
        }
    
        .border-line {
            border-right: none;
        }
    
        .card2 {
            border-top: 1px solid #EEEEEE !important;
            margin: 0px 15px;
        }
    }
    
    span.star {
        color: red;
        font-size: 15px;
    }
    
    .btn-blue:hover {
        background-color: #1c258f !important;
        cursor: pointer;
    }
    
    .access-account {
        font-weight: 400;
    }
    </style>
    
    <div class="container-fluid">
        <div class="row d-flex">
            <div class="col-lg-7">
                <div class="card1 pb-5">
                    <div class="row">
                        <img src="assets/uploads/logo.png" class="logo">
                    </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
                        <img src="assets/uploads/backround.jpg" class="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <form action="" method="post">
                  <div class="card2 card border-0 px-4 py-5">
                      <div class="row px-3">
                          <h6 class="mr-4 mt-2 mb-0">Forgot Your Password ?</h6>
                          <!-- <div class="facebook text-center mr-3"><div class="fa fa-facebook"></div></div>
                          <div class="twitter text-center mr-3"><div class="fa fa-twitter"></div></div>
                          <div class="linkedin text-center mr-3"><div class="fa fa-linkedin"></div></div> -->
                      </div>
                      <div class="row px-3 mt-0 mb-4">
                          <h6 class="mb-0 mr-4 mt-2 access-account">Enter your email address below and we'll send you a forgot passworrd through email.</h6>
                             <!-- <div class="line"></div>
                          <small class="or text-center">Or</small>
                          <div class="line"></div> -->
                      </div>
                      <div class="row px-3">
                          <label class="mb-1"><h6 class="mb-0 text-sm">Email <span class="star">*</span></h6></label>
                          <input class="mb-4" required type="text" name="email" placeholder="Enter Email">
                      </div>
                      
                      
                      <div class="row mb-3 px-3">
                          <button type="submit" class="btn btn-blue text-center">Submit</button>
                      </div>

                      <div class="row px-3 mb-4 mt-2">
                         <a href="login" class="ml-auto mb-0 text-sm"><i class="fa fa-arrow-left" aria-hidden="true"></i>  Back to Sign in</a>
                     </div>
                      <!-- <div class="row mb-4 px-3">
                          <small class="font-weight-bold">Don't have an account? <a class="text-danger ">Register</a></small>
                      </div> -->
                  </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Footer Start here -->
    <div class="bg-blue py-4">
        <div class="row px-3-">
          <small class="ml-4 ml-sm-5 mb-2">
              Copyright &copy; 2022. All rights reserved.
          </small>
          <div class="social-contact ml-5 ml-sm-auto">
              <!-- <span class="fa fa-facebook mr-4 text-sm"></span>
              <span class="fa fa-google-plus mr-4 text-sm"></span>
              <span class="fa fa-linkedin mr-4 text-sm"></span>
              <span class="fa fa-twitter mr-4 mr-sm-5 text-sm"></span> -->
              Developed and Maintained by <a href="https://www.jesperapps.com/" target="_blank" title="Jesperapps">Jesperapps</a>
          </div>
        </div>
    </div>