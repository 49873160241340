import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SuperadminService } from '../../../providers/superadmin.service';
import { EmployeeService } from 'src/app/providers/employee.service';

@Component({
  selector: 'app-view-organization',
  templateUrl: './view-organization.component.html',
  styleUrls: ['./view-organization.component.scss']
})
export class ViewOrganizationComponent implements OnInit {

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;

  resultData: any = {};
  organizationId: any;
  fileblob: any;

  loader:any= false;
  backdrop:any= false;

 constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private SuperadminService: SuperadminService,
    private EmployeeService: EmployeeService,
    private ActivatedRoute:ActivatedRoute,
  ) { }

  ngOnInit(): void
  {
    this.organizationId = this.ActivatedRoute.snapshot.params.organizationId;

    if(this.organizationId) //View User
    {
      this.loader=true;
      this.backdrop=true;

      this.SuperadminService.getOrganizationById(this.organizationId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;

        this.resultData=resultData;

        console.log("ORG DETAIL : ",this.resultData);

        if(resultData.organizationAttachment)
        {
          this.EmployeeService.getemployeeImage(resultData.organizationAttachment.fileViewUrl).subscribe(
            blob => {
              const a = document.createElement('a')
              const objectUrl: any = blob
              this.fileblob = blob;

            },
            err => 
            {
              this.fileblob= "assets/uploads/no-image.png"
            }
          );
        }
        else
        {
          this.fileblob= "assets/uploads/no-image.png"
        }
      });
        /* if(this.resultData.organizationStatus.listTypeValueId == 1)
        {

        } */
    }
  }

}
