import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatboxmsg',
  templateUrl: './chatboxmsg.component.html',
  styleUrls: ['./chatboxmsg.component.scss']
})
export class ChatboxmsgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
