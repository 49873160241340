

<form [formGroup]="appraisalDialogForm" style="padding: 15px;">
    
    <div class="row">
        <div class="full-width col-md-12" id="loginform">
            <label class="form-control-label"
                >Have you discussed appraisal with your manager!</label
            >
            <mat-select
                class="form-control email-input select-placeholder"
                placeholder="Select Yes/No"
                formControlName="submit" style="height: 35px; font-weight: 600;">
                <mat-option value="yes" >Yes</mat-option>
                <mat-option value="no" >No</mat-option>
            </mat-select>
            <mat-error *ngIf="appraisalDialogForm.get('submit').hasError('required') && appraisalDialogForm.get('submit').touched && submitted">Please select an option.</mat-error>
           
           
          
        </div>
    </div>

    <div class="row" style="margin-top: 35px;">
        <div class="col-md-12">
            <button type="submit" mat-raised-button  style="float: inline-end; margin-top: 15px; background-color: #365eef; color:#fff" (click)="submitForm()">OK</button>
            <button type="cancel" mat-raised-button style="float: inline-end; margin-top: 15px; margin-right: 10px; color:red;" (click)="cancel()">CANCEL</button>
        </div>
    </div>

 

    </form>

    

    <div *ngIf="loader" class="align-items-center text-center">
        <div class="spinner-border"
            style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;bottom: 0;" role="status">
        </div>
    </div>
    
    
  

