<h2 mat-dialog-title class="title">Leave List</h2>
<div align="end" style="position: relative;right: 10px;bottom: 10px;">
  <span class="material-icons-outlined icons" mat-button (click)="closeDialog()"
  style="color:red;font-size: 22px;position: absolute;transform: translate(-12px, -50px); cursor: pointer;" 
  cdkFocusInitial>highlight_off</span>
<div class="">
  <div class="table-responsive">
    <table class="table table-hover" style="width: 100%; position: sticky; top: 0;">
      <thead>  
        <tr>
          <!-- <th scope="col" class="text-center">&nbsp;S.No&nbsp;</th> -->
          <th scope="col">Employee&nbsp;Name</th>
          <th scope="col">From&nbsp;Date</th>
          <th scope="col">To&nbsp;Date</th>
          <th scope="col">Leave&nbsp;Type</th> 
          <th scope="col" class="text-center">No.of&nbsp;Days</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <!-- <td class="text-center">{{i+1}}</td> -->
          <td>{{Data.employee.firstName}} {{Data.employee.lastName}}</td>
          <td>{{Data.startDate.substring(0,10)}}</td>
          <td>{{Data.endDate.substring(0,10)}}</td>
          <td>{{Data.leaveType.leaveName}}</td>
          <td class="text-center">{{Data.noOfLeaveDays}}</td>
          <td [style.color]="test(Data)">{{Data.leaveWithdraw.withdrawStatus}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<style>
  .title{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 38px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: #4A4A4A;
  }
  table thead tr{
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
  }
  table thead tr th{
  
      font-weight: 600;
      font-size: 13px;
      line-height: 21px;
      align-items: center;
      letter-spacing: 0.005em;
  }
  td{
    
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      align-items: center;
      letter-spacing: 0.005em;
      color: #4A4A4A;
  }
  table tbody tr{
      border: 1px solid #EEEEEE;
      background: #FFFFFF;
  }
  .table th{
    border-top: 0!important;
  }
  .table thead th{
      border-bottom: 0!important;
  }
  .table th {
    color: #fff;
    font-family: Mulish;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.075px;
    vertical-align: middle;
    font-family: Mulish;
  
    color: #fff;
    border-bottom-color: rgba(0, 0, 0, 0.1215686275);
    border-top: 0px;
    background: #708fff;
    text-align: center;
    
  }
  
  .table td {
    color: var(--Text-Color---Non-Select, #424242);
    text-align: center;
    font-family: Mulish;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.075px;
  
  
  }
  
  
  .table-responsive-new1{
    border-top-left-radius: 10px; /* Adjust the radius as needed */
  }
  
  /* Style for the right top corner header */
  .table-responsive-new1 {
    border-top-right-radius: 10px; /* Adjust the radius as needed */
  }
  
  /* Style for the middle headers without border radius */
  .table th:not(:first-child):not(:last-child) {
    /* border-top: 0px; */
  }
  
  .table td,
  .table th {
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
  }

  table {
    border-collapse: collapse;
    background-color: white;
    text-align: left;
    overflow: hidden;
  }  

  .table-responsive {
    overflow-x: auto;
}

.table {
    width: 100%;
}

.table th,
.table td {
    white-space: nowrap;
}
.table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Make sure the table header is responsive */
@media (max-width: 767px) {
  .table th {
      width: auto !important;
      min-width: 100px;
      max-width: 200px;
  }
}

table {
  border-collapse: collapse;
  background-color: white;
  text-align: left;
  overflow: hidden;

}



.table-responsive-new1 {
  width: 100%; /* Adjust the width as needed */
  max-height: 200px; /* Set the maximum height as needed */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}
.table-responsive-new1::-webkit-scrollbar {
  height: 2px;
  background-color: #f5f5f5;
}


.mat-header-cell {

  font-size: 12px !important;

  border-bottom-width: 0px;

  padding: 0 5px;

  border-radius: 20p;

  background-color: #708FFF;

  color: white;

}
</style>