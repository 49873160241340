import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnnouncementService } from '../announcement.service';
import { redirectMsgTimeOut } from '../providers/properties';
import { FormGroup } from '@angular/forms';
import { HeaderService } from '../providers/header.service';

@Component({
  selector: 'app-manageannouncement',
  templateUrl: './manageannouncement.component.html',
  styleUrls: ['./manageannouncement.component.scss']
})
export class ManageannouncementComponent implements OnInit {
  displayedColumns =
    ['S.No','Action','Title','Status'];
list_count: any;
dataS: any;
noData: any;
paginationSet:boolean=false;
employeesData: any[] = [];
query:any='';
mySelect:number=5;   
p: number =1;
  sucess_msg: any;
  success= false;
  error:any=false;
  error_msg: any;
  filterContent: any;
  loader=true;

  nodatafound: boolean = false;
  kralistData:any=[];
  searchForm!:FormGroup;
  [x: string]: any;

  showTable:boolean=false


  constructor(

    private router: Router,
    private AnnouncementService: AnnouncementService,
    private headerservice: HeaderService
  ) {}

  ngOnInit(): void {
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
      this.organizationId = this.usertype.organization.organizationId;
      this.employeeId = this.usertype.employee.employeeId;
      this.clientId=this.usertype.employee?.client?.clientId;
    this.getFilterFormData(this.id);
    this.headerservice.setTitle('manage-announcement')

  }
 
 add_announcement(){
    this.router.navigateByUrl("/home/announcement");
  }
  updateStatus(id,status)
	{
    console.log(" Id:"+id,"Status:"+status);
    this.AnnouncementService.updateAnnouncementStatus(id,status).subscribe((result:any) =>
		{
      console.log("111111",result);
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        console.log("111111111111111",result.description,this.sucess_msg);
        
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
    
       
      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      this.getFilterFormData(this.id);

		})
	}
  // searchSubmit()
  // {
  //   let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
  //   console.log(keywords);
  //   this.filterContent=keywords;
  //   console.log(this.filterContent);
  //   if(keywords == null)
  //   {
  //     alert('Please Search Keywords');
  //     return;
  //   }
  //     console.log("orgId:",this.orgId);
  //     this.AnnouncementService.getAnnouncementOrgAndKeywordList(this.orgId).subscribe((filterData: any) => 
  //     {
  //       this.loader=false;
  //       this.nodatafound = false;
  //       this.employeesData = filterData;
  //       console.log(this.employeesData);
  //       if(this.employeesData.statusCode==204)
  //       {
  //         console.log("nodata");
  //         this.nodatafound=true;
  //       }
  //       if(this.employeesData.length == 0 || this.employeesData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //       }

  //       this.p = 1;
        
  //     })
  //   // } 
  //   // else 
  //   //  {
  //   //   console.log('Value is an integer'+temCode);
  //   //   this.loader=true;
  //   //   this.backdrop=true;
  //   //   this.PmsService.getKRATemplateIdList(keywords).subscribe((resultData: any) => 
  //   //   {
  //   //     this.loader=false;
  //   //     this.backdrop=false;
  //   //     this.nodatafound = false;
  //   //     this.resultData = resultData;
  //   //     if(this.resultData.length == 0 || this.resultData.length == undefined)
  //   //     {
  //   //       this.nodatafound = true;
  //   //     }
  //   //   })
  // // }
  // }
  getFilterFormData(id)
  {
    console.log("List Api data"+id);
    console.log(this.clientId,this.organizationId)
    if(this.clientId != undefined ){
      this.showTable=false;
      // this.loader=true;
      this.AnnouncementService.getannouncementListClient1(this.clientId).subscribe((data:any) =>
        {
        console.log("data",data);
        this.employeesData=data;
        console.log(this.employeesData);
        this.loader=false;
        this.showTable=true
        this.nodatafound=false;
        if(data.statusCode==204)
        {
          console.log("nodata");
          this.nodatafound=true;
        }
        if(this.employeesData.length==0)
        {
          this.nodatafound=true;
        }
        })
    }else{
      this.AnnouncementService.getannouncementListOrganization1(this.organizationId).subscribe((data:any) =>
        {
        console.log("data",data);
        this.employeesData=data;
        console.log(this.employeesData);
        this.loader=false;
        this.showTable=true

        this.nodatafound=false;
        if(data.statusCode==204)
        {
          console.log("nodata");
          this.nodatafound=true;
        }
        if(this.employeesData.length==0)
        {
          this.nodatafound=true;
        }
        })
    }
   
  }
 applyFilter(): void {
  console.log('applyFilter() called'); // Add this line
  this.p = 1;
    this.filteredData = this.employeesData.filter(employee =>
      employee.announcementTitle.toLowerCase().includes(this.query.toLowerCase())
    );

    if (this.filteredData.length > 0) {
      console.log("111111111",this.filteredData)
      this.nodatafound = false; // Matches found
    } else {
      console.log("222222222",this.filteredData)
      this.nodatafound = true; // No matches found
      this.filteredData = [];
    }  }

  onItemsPerPageChange() {
    this.p = 1;
  }

}
