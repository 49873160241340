<!-- Sub header start here-->
<section>
    <div class="sub-header">
      <div class="col-md-12 mb-3">
        <div class="row">
          <div class="">
            <span  class="h5 sub-content">
                Supplier</span
            >
          </div>
          <div class="add-business" >
            <button routerLink="/home/add-supplier" mat-raised-button class="m-1 searchBtn" style="    position: relative;
    top: -7px;">
              Add Supplier
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Sub header end here-->
  
  <div class="common-content" style="    padding: 10px;
    margin-top: 0px;">
    <div class="main-content" style="   margin:-2%;
    padding: 13px;border-radius: 10px;">
      <div class="container-fluid">
        <div class="top-content row">
          <div>
            <!-- <h4 class="assign-employee">Assign Employee Task</h4> -->
          </div>
        </div>
        <div class="search-content">
          <div class=card style="padding:2%">
            <form [formGroup]="searchForm" >
              <div class="row">
                <div class="col-sm-4">
                  <label class="form-control-label">Supplier Name</label>
                  <input type="text" 
                  formControlName="supplierName" 
                  autocomplete="off"
                  value=""
                  (keydown)="clearset($event)"
                  class="form-control email-input select-placeholder"
                  placeholder="Search Supplier Name" >
                  <small class="text-muted" style="  
                  color: rgba(0, 0, 0, 0.42) !important;
                  position: relative;
                  left: 6px;">Note :Supplier Name</small>
                  <!-- <mat-select
                    id="framework"
                    class="form-control email-input select-placeholder"
                    placeholder="Select Business Group Name"
                  >
                    <mat-option>-Select Supplier Name</mat-option>
                    <mat-option> pro.projectName </mat-option>
                  </mat-select> -->
                </div>
      
                
                <div class="col-sm-4 mt-4">
                  <button type="button" mat-raised-button class="searchBtn1" [disabled]="btnDisabled"
                  (click)="searchSubmit()">
                    Search
                  </button>
      
                  <button
                    type="button"
                    mat-raised-button
                    (click)="clear()"
                    [disabled]="btnDisabled"
                    class="btn searchBtn ml-3"
                    style="
                      background: none;
                      color: black;
                      border: 1px solid #ddd;
                      font-size: 16px;
                      font-weight: 610;
                    "
                  >
                    Clear
                  </button>
                </div>
              </div>
            </form>
              <div class="card-maargin">
                    <div class="" >
                      <div class="row p-0 m-0">
                        <div
                          class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9 text-right"
                        ></div>
          
                        <div
                          class="col-xl-2 col-lg-3 col-md-3 col-sm-4 text-right"
                          *ngIf="supplierList.length > 5"
                        >
                          <div class="select-pagination">
                            <span>Show : </span>
                            <select
                              class="select_list new-select_list listDesign"
                              [(ngModel)]="mySelect"
                              (change)="changefirstpage()"
                            >
                              <option class="form-control" value="5">5</option>
                              <option class="form-control" value="10">10</option>
                              <option class="form-control" value="15">15</option>
                              <option class="form-control" value="20">20</option>
                              <option class="form-control" value="25">25</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive-new1 table-content card-content">
                        <table class="table" matSort (matSortChange)="sortData($event)" *ngIf="tableShow && !loader">
                          <thead>
                            <!-- <th
                              mat-sort-header="sno"
                              scope="col"
                              style="text-align: center"
                            >
                        
                              &nbsp;&nbsp;S.No
                            </th> -->
                            <th
                            class="sticky-col-tab"
                            mat-sort-header="action"
                            scope="col"
                          >
                        
                            &nbsp;&nbsp;Action
                          </th>
                            <th
                              mat-sort-header="supplierCode"
                              style="text-align: center"
                              scope="col"
                            >
                              Supplier&nbsp;Code
                            </th>
                            <th mat-sort-header="supplierName" scope="col">
                              Supplier&nbsp;Name
                            </th>
    
                            <th mat-sort-header="country" scope="col">
                              Country
                            </th>
                            <th
                              mat-sort-header="address"
                              scope="col"
                              style="text-align: center"
                            >
                            Address&nbsp;Line1
                            </th>
                            <th
                              mat-sort-header="supplierDescription"
                              scope="col"
                              style="text-align: center"
                            >
                            Supplier&nbsp;Description
                            </th>
                            <th
                              mat-sort-header="status"
                              style="text-align: center"
                            >
                              Status
                            </th>
                           
                          </thead>
                          <tbody  style="
                          border-left: 1px solid #ddd;
                          border-right: 1px solid #ddd;
                        ">
                            <tr
                              *ngFor="
                                let e of supplierList
                               
                                  | paginate : { itemsPerPage: mySelect, currentPage: p };
                                let i = index
                              "
                            >
                              <!-- <td style="text-align: center" *ngIf="p">
                                {{ (p - 1) * mySelect + (i + 1) }}
                              </td>
                              <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td> -->
                              
                              
                              <td class="text-center" style="margin-left: -24px;">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
    
                                <mat-menu #menu="matMenu" class="controls-list">
                                    <button mat-menu-item [routerLink]="['/home/edit-supplier', e.supplierId]">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button>
    
    
                                    <button mat-menu-item *ngIf="e.supplierStatus == 'Active'" (click)="updateStatus(e.supplierId,'In-active')">
                                        <mat-icon>done</mat-icon>
                                        <span style="color:red">Inactive</span>
                                    </button>
    
                                    <button mat-menu-item *ngIf="e.supplierStatus == 'In-active'" (click)="updateStatus(e.supplierId,'Active')">
                                        <mat-icon>done</mat-icon>
                                        <span style="    color: #50cd89 !important;">Active</span>
                                    </button>
                                </mat-menu>
                            </td>
                              <td style="text-align: center">
                                {{ e.supplierCode }}
                              </td>
                              <td style="min-width: 150px !important">
                                {{ e.supplierName }}
                              </td>
                              <td
                                style="
                                  white-space: normal;
                                  overflow-wrap: break-word;
                                  word-break: normal;
                                  min-width: 250px !important;
                                "
                              >
                                {{ e.country }}
                              </td>
          
                             
                              <td>
                                {{ e.addressLine1 }}
                              </td>
                              <td>
                                {{ e.supplierDescription ? e.supplierDescription : '-' }}
                              </td>
                              
                              <td class="active-status text-center">
                                <span *ngIf="e.supplierStatus == 'Active'" class="btn btn-sm btn-block" style="color: #50cd89 !important;">
                                    {{ e.supplierStatus }}
                                </span>
                                <span *ngIf="e.supplierStatus == 'In-active'" class="btn btn-sm btn-block" style="    color: red;
        padding: 10px;">
                                    {{ e.supplierStatus }}
                                </span>
                            </td>
                             
          
                            </tr>
                            
                          </tbody>
                          <tbody
                          style="
                            border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                          "
                          
                        >
                          <tr>
                            <td
                              style="
                                text-align: center;
                                padding: 10px;
                                font-size: 19px;
                                font-weight: 600;
                                font-family: 'Mulish', sans-serif;
                                left: 5;
                                position: relative;
                              "
                              colspan="8"
                              *ngIf="nodata "
                            >
                            Supplier Records Not Found!
                          </td>
                          </tr>
                        </tbody>
                        </table>
                        <div
                      class="pagination-content"
                      *ngIf="supplierList.length > 5"
                      id="page"
                    >
                        <pagination-controls
                          *ngIf="
                          
                            supplierList.length > 5
                          "
                          class="text-right"
                          style="float: right"
                          (pageChange)="p = $event; beforesearchpage = $event"
                          align="end"
                        ></pagination-controls>
                      </div>
          
                      
                      </div>
                    </div>
                  </div>
          </div>
          
          <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
                <div class="card-body-nodata">
                  <div class="alert-nodata alert-primary">
                    <h4 align="center" class="h4">Assign Employee Task Not Found</h4>
                  </div>
                </div>
              </mat-card> -->
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="loader" class="align-items-cEnter text-cEnter">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top: 51%;color:#1a83ff;"
      role="status"
    ></div>
  </div>
  
  <div class="text-cEnter" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>
  