<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content" style="font-family: 'Mulish', sans-serif;">Manage Client Employee Assignment</span>
                </div>
                <div class="sub-header-buttons">
                    <a  (click)="importEmployee()" mat-raised-button class="ml-2" color="primary" title="Import">
                        <mat-icon class="mat-sidebar-icon">publish</mat-icon>
                        Import
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <!-- <label class="form-control-label">Organization Name<span class="star"></span></label> -->
                                <!-- <select formControlName="filterorg" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]="null"> -- Select Organization --- </option>
                                    <option *ngFor="let row of orgList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select> -->
                                <label class="form-control-label">Organization</label>

                                <mat-select
                                formControlName="filterorg"
                                placeholder="Select Organization "
                                class="form-control email-input select-placeholder"
                              >
                                <mat-option [value]="undefined" selected="selected"
                                  >-Select Organization Name-</mat-option
                                >
                                <mat-option
                                  *ngFor="let row of orgList; let i = index"
                                  value="{{ row.organizationId }}"
                                  >{{ row.organizationName }}
                                </mat-option>
                              </mat-select>
                               
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Start Date<span class="star"></span></label>
                                <input formControlName="filterstartdate" 
                                id="filterstartdate"  
                                readonly 
                                matInput [matDatepicker]="filterstartdate" 
                                class="form-control email-input select-placeholder" 
                                     placeholder=" Start Date" [max]="today">
                                <mat-datepicker-toggle matSuffix [for]="filterstartdate" style="float: right;position: absolute;bottom: 38px;right: 5%;"></mat-datepicker-toggle>
                                <mat-datepicker #filterstartdate   startView="multi-year"
                                (dateChange)="onDateChange($event)"
                                panelClass="example-month-picker"></mat-datepicker>

                                
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">End Date<span class="star"></span></label>
                                <input formControlName="filterenddate" 
                                id="filterenddate" 
                                readonly  
                                matInput [matDatepicker]="filterenddate" 
                                class="form-control email-input select-placeholder"
                                   [min]="filterForm.value.filterstartdate"  [max]="today" placeholder="End Date">
                                <mat-datepicker-toggle matSuffix [for]="filterenddate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                <mat-datepicker #filterenddate></mat-datepicker>

                                <!-- <div class="valid-msg" *ngIf="(validate.yearto.invalid && validate.yearto.touched) || validate.yearto.dirty || submitted">
                                    <span class="text-danger" *ngIf="validate.yearto.errors?.required">Please select Year to</span>
                                </div> -->
                            </div>
                            <!-- <div class="form-group col-md-1"  style="position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary" 
                                    class="btn btn-primary search-btn">Search</button>
                            </div> -->
                            
                            <div class="col-md-3" style="float: right;position: relative; top: 23px;">
                                <button type="submit" mat-raised-button style="color: #fff;background: #1a83ff;"
                                    class="btn search-btn">Search</button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                    class="btn-clear search-btn mr-2" (click)="clearTable()"
                                    >Clear</button>
                            </div>
                            <div class="form-group col-md-1" *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" style="position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary" 
                                    class="btn btn-primary search-btn">Export</button>
                            </div>
                        </div>
                    </form>
                        <!-- <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
                            <h2 style="font-family: 'Mulish', sans-serif;">Add/update client employee</h2>
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="form-control-label">Client Name<span class="star">*</span></label> 
                                    <select formControlName="client" (change)="getratedetails(false)"  class="form-select form-select-solid form-select-lg select2-new">
                                        <option [value]="null"> --- Select Client --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>    
                                    <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                        <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                      
                                    </div>
                                </div>
                                <div class="form-group col-md-3">

                                    <label class="form-control-label">Employee Name<span class="star">*</span></label>
                                    <input type="text" placeholder=" Search Employee" 
                                               formControlName="employee"
                                               [matAutocomplete]="auto"
                                               class="form-control designchange" (keyup)="empSearch($event)">
                                        <mat-autocomplete #auto="matAutocomplete">
                                          <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                            {{emp.employeeNumber}}- {{emp.firstName}}  
                                          </mat-option>
                                        </mat-autocomplete>
                                        <mat-icon class="material-symbols-outlined" style="position:absolute;top:49px;right: 20px;">
                                            search
                                        </mat-icon>
                                    <div class="valid-msg" *ngIf="(validate.employee.invalid && validate.employee.touched) || validate.employee.dirty || submitted">
                                        <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span>
                                       
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-control-label">Start Date<span class="star">*</span></label>
                                    <input formControlName="startdate" id="startdate" readonly [matDatepicker]="startdate" class="form-control email-input select-placeholder" 
                                        placeholder=" Select Start Date ">
                                    <mat-datepicker-toggle matSuffix [for]="startdate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                    <mat-datepicker #startdate   startView="multi-year"
                                    (dateChange)="onDateChange($event)"
                                    panelClass="example-month-picker"></mat-datepicker>
                                    <div class="valid-msg" *ngIf="(validate.startdate.invalid && validate.startdate.touched) || validate.startdate.dirty || submitted">
                                        <span class="text-danger" *ngIf="validate.startdate.errors?.required">Please select start date</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-control-label">End Date<span class="star">*</span></label>
                                    <input formControlName="enddate" id="enddate"  readonly [matDatepicker]="enddate" class="form-control email-input select-placeholder"
                                    [min]="clientForm.value.startdate" placeholder=" Select End Date ">
                                    <mat-datepicker-toggle matSuffix [for]="enddate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                    <mat-datepicker #enddate></mat-datepicker>
                                    <div class="valid-msg" *ngIf="(validate.enddate.invalid && validate.enddate.touched) || validate.enddate.dirty || submitted">
                                        <span class="text-danger" *ngIf="validate.enddate.errors?.required">Please select end date</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-3" id="loginform">
                                    <label class="form-control-label" style="  font-family: 'Mulish', sans-serif;">Wages per month <span class="star">*</span></label>
                                    <input type="text" formControlName="ratePerMonth" id="ratePerMonth"  placeholder="Enter wages per month" class="form-control email-input select-placeholder" (keyup)= "calculatdaily()" value=""  autocomplete="off">
                                    <div class="valid-msg" *ngIf="(validate.ratePerMonth.invalid && validate.ratePerMonth.touched) || validate.ratePerMonth.dirty">
                                        <span *ngIf="validate.ratePerMonth.errors?.required" class="text-danger">Please enter wage per month</span>
                                        <span *ngIf="validate.ratePerMonth.errors?.pattern" class="text-danger">Please enter valid wage per month</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3" id="loginform">
                                    <label class="form-control-label"style="  font-family: 'Mulish', sans-serif;" >Wages per day <span class="star">*</span></label>
                                    <input type="text" readonly formControlName="ratePerDay" id="ratePerDay" placeholder="Enter wages per day"  
                                    class="form-control email-input select-placeholder" value="" (keyup)= "calculatemontly()" autocomplete="off">
                                    <div class="valid-msg" *ngIf="(validate.ratePerDay.invalid && validate.ratePerDay.touched) || validate.ratePerDay.dirty">
                                        <span *ngIf="validate.ratePerDay.errors?.required" class="text-danger">Please enter wage per day</span>
                                        <span *ngIf="validate.ratePerDay.errors?.pattern" class="text-danger">Please enter valid wage per day</span>
                                    </div>
                                </div>
                            </div>
                               
                                <div class="row pb-3">
                                    <div class="col-md-12 text-right">
                                        <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="margin-right: 20px;">Clear</button>
                                        <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                            [disabled]="clientForm.invalid">Save</button>
                                    </div>
                                </div>
                        </form> -->
                            
                        <form [formGroup]="organizationForm" (ngSubmit)="onSubmit()">
                            <!-- <h2>Organization Employee Transfer</h2> -->
                            <div  class="col-md-12 sideDesign"><b >Organization Employee Transfer</b></div>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="form-control-label">From Organization<span class="star">*</span></label> 
                                    <!-- <select formControlName="organization" (change)="getratedetails($event)"  [attr.disabled]="update  ? true : null"  class="form-select form-select-solid form-select-lg select2-new">
                                        <option [value]="null"> --- Select Organization --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>     -->
                                    <mat-select
                                    formControlName="organization"
                                    placeholder="Select From Organization "
                                    class="form-control email-input select-placeholder"
                                  >
                                    <mat-option [value]="undefined" selected="selected"
                                      >-Select From Organization Name-</mat-option
                                    >
                                    <mat-option
                                      *ngFor="let row of orgList; let i = index"
                                      value="{{ row.organizationId }}"
                                      >{{ row.organizationName }}
                                    </mat-option>
                                  </mat-select>
                                    <div class="valid-msg" *ngIf="(validate.organization.invalid && validate.organization.touched) || validate.organization.dirty || submitted">
                                        <span *ngIf="validate.organization.errors?.required" class="text-danger">Please select Organization</span>
                                        <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                        search
                                    </mat-icon> -->
                                    <label class="form-control-label">Employee Name<span class="star">*</span></label>
                                    <input type="text" placeholder=" Search Employee" 
                                               formControlName="employee"
                                               [matAutocomplete]="auto"  [disabled]="update"
                                               class="form-control email-input select-placeholder" (keyup)="empSearch($event)">
                                        <mat-autocomplete #auto="matAutocomplete">
                                          <mat-option *ngFor="let emp of empList" [value]="emp.employeeNumber" (click)="empSelect(emp)">
                                            {{emp.employeeNumber}}- {{emp.firstName}}  
                                          </mat-option>
                                        </mat-autocomplete>
                                        <!-- <mat-icon class="material-symbols-outlined" style="position:absolute;top:49px;right: 20px;">
                                            search
                                        </mat-icon> -->
                                    <div class="valid-msg" *ngIf="(validate.employee.invalid && validate.employee.touched) || validate.employee.dirty || submitted">
                                        <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span>
                                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                                <div *ngIf="!clientupdateId"  class="form-group col-md-3">
                                    <label class="form-control-label">To Organization<span class="star">*</span></label> 
                                    <!-- <select formControlName="toOrganization"  (change)="valiatetoclient($event)"    class="form-select form-select-solid form-select-lg select2-new">
                                        <option [value]="null"> --- Select Organization --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>     -->
                                    <mat-select
                                    formControlName="toOrganization"
                                    placeholder="Select To Organization "
                                    class="form-control email-input select-placeholder"
                                    (selectionChange)="valiatetoclient($event)" 
                                  >
                                    <mat-option [value]="undefined" selected="selected"
                                      >-Select To Organization Name-</mat-option
                                    >
                                    <mat-option
                                      *ngFor="let row of orgList; let i = index"
                                      value="{{ row.organizationId }}"
                                      >{{ row.organizationName }}
                                    </mat-option>
                                  </mat-select>
                                    <div class="valid-msg" *ngIf="(validate?.toOrganization?.invalid && validate?.toOrganization?.touched) || validate?.toOrganization?.dirty || submitted">
                                        <span *ngIf="validate?.toOrganization?.errors?.required" class="text-danger">Please select To Organization</span>
                                        <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                                <div   class="form-group col-md-3">
                                    <label class="form-control-label">Start Date<span class="star">*</span></label>
                                    <input formControlName="startdate" 
                                    id="startdate" readonly [matDatepicker]="startdate" 
                                    class="form-control email-input select-placeholder" 
                                        placeholder=" Select Start Date ">
                                    <mat-datepicker-toggle matSuffix [for]="startdate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                    <mat-datepicker #startdate   startView="multi-year"
                                    (dateChange)="onDateChange($event)"
                                    panelClass="example-month-picker"></mat-datepicker>
                                    <div class="valid-msg" *ngIf="(validate.startdate.invalid && validate.startdate.touched) || validate.startdate.dirty || submitted">
                                        <span class="text-danger" *ngIf="validate.startdate.errors?.required">Please select start date</span>
                                    </div>
                                </div>
                                <div *ngIf="clientupdateId"  class="form-group col-md-3">
                                    <label class="form-control-label">End Date</label>
                                    <input formControlName="enddate" id="enddate"  readonly  [matDatepicker]="enddate" class="form-control email-input select-placeholder"
                                    [min]="organizationForm.value.startdate" placeholder=" Select End Date ">
                                    <mat-datepicker-toggle matSuffix [for]="enddate" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                    <mat-datepicker #enddate></mat-datepicker>
                                    <div class="valid-msg" *ngIf="(validate.enddate.invalid && validate.enddate.touched) || validate.enddate.dirty || submitted">
                                        <span class="text-danger" *ngIf="validate.enddate.errors?.required">Please select end date</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="form-group col-md-3" id="loginform">
                                    <label class="form-control-label">Wages per month <span class="star">*</span></label>
                                    <input type="text" formControlName="ratePerMonth" id="ratePerMonth"  placeholder="Enter wages per month" class="form-control email-input select-placeholder" (keyup)= "calculatdaily()" value=""  autocomplete="off">
                                    <div class="valid-msg" *ngIf="(validate.ratePerMonth.invalid && validate.ratePerMonth.touched) || validate.ratePerMonth.dirty">
                                        <span *ngIf="validate.ratePerMonth.errors?.required" class="text-danger">Please enter wage per month</span>
                                        <span *ngIf="validate.ratePerMonth.errors?.pattern" class="text-danger">Please enter valid wage per month</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3" id="loginform">
                                    <label class="form-control-label">Wages per day <span class="star">*</span></label>
                                    <input type="text" readonly formControlName="ratePerDay" id="ratePerDay" placeholder="Enter wages per day"  
                                    class="form-control email-input select-placeholder" value="" (keyup)= "calculatemontly()" autocomplete="off">
                                    <div class="valid-msg" *ngIf="(validate.ratePerDay.invalid && validate.ratePerDay.touched) || validate.ratePerDay.dirty">
                                        <span *ngIf="validate.ratePerDay.errors?.required" class="text-danger">Please enter wage per day</span>
                                        <span *ngIf="validate.ratePerDay.errors?.pattern" class="text-danger">Please enter valid wage per day</span>
                                    </div>
                                </div>
                            </div> -->
                                <!-- <div class="row mb-4">
                                    <div class="col-md-8">
                                    </div>
                                    <div class="col-md-4 text-right">
                                        <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                            [disabled]="clientForm.invalid">Save</button>
                                    </div>
                                </div> -->
                                <!-- <div class="row">
                                 
                                    <div class=" form-group col-md-3" style="margin-top: 20px">
                                        <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="margin-right: 20px;margin-top: 20px">Clear</button>
                                        <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                            [disabled]="organizationForm.invalid">Save</button>
                                    </div>
                                </div> -->
                                <div class="row" >
                                    
                                    <div class=" form-group col-md-3" style="margin-top: -21px">
                                    <button type="submit" mat-raised-button  style="color: #fff;background: #1a83ff;"
                                        class="btn  search-btn">Save</button>
                                    <button style="position: relative;left: 7px;" type="reset" mat-raised-button
                                        class="btn-clear search-btn mr-2" 
                                        >Clear</button>
                                        </div>
                                </div>
                        </form>
                        <!-- <div class="row pb-3" *ngIf="resultData?.length > 10">
                                <div class="col-md-11 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-1 text-left pl-0">
                                    <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="row p-0 m-0">
                                <div
                                  class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9 text-right"
                                ></div>
                  
                                <div
                                  class="col-xl-2 col-lg-3 col-md-3 col-sm-4 text-right"
                                  *ngIf="resultData.length > 5"
                                >
                                  <div class="select-pagination">
                                    <span>Show : </span>
                                    <select
                                      class="select_list new-select_list listDesign"
                                      [(ngModel)]="mySelect"
                                      (change)="changefirstpage()"
                                    >
                                      <option class="form-control" value="5">5</option>
                                      <option class="form-control" value="10">10</option>
                                      <option class="form-control" value="15">15</option>
                                      <option class="form-control" value="20">20</option>
                                      <option class="form-control" value="25">25</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                       
                       <div class="table">
                        <!-- <p style="font-family:'Mulish', sans-serif;"><b>EMPLOYEE ASSIGNMENT</b></p> -->
                        <div  class="col-md-12 sideDesign"><b >Employee Assignment</b></div>

                        <table class="table">
                            <thead class="t-head">
                                <tr>
                                    <th style="border-radius: 15px 1px 0px 0;">Action</th>
                                    <th >Organization Name</th>
                                    <th>Employee No</th>
                                    <th>Employee Name</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <!-- <th>Wage Per Day</th> -->
                                    <!-- <th>EWage Per Month</th> -->
                                    <th  style="border-radius: 0px 15px 0px 0;">Status</th>
                                </tr>
                            </thead>
                             <tbody  style="
                             border-right: 1px solid #ddd;
                             border-left: 1px solid #ddd;
                             border-bottom: 1px solid #ddd;
                            
                           ">
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item (click)="updateform(row)"  >
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                            <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==1" (click)="updateStatus(row.clientAssignId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==2" (click)="updateStatus(row.clientAssignId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button>
                                        </mat-menu>
                                    </td>
        
                                    <td>{{row.client.clientName}}</td>
                                    <td>{{row.employee.employeeNumber}}</td>
                                    <td>{{row.employee.firstName}}</td>
                                    <td>{{row.startDate| date :dateFormat}}</td>
                                    <td>{{row.endDate | date :dateFormat}}</td>
                                    <!-- <td class="text-center">{{row.ratePerDay}}</td>
                                    <td class="text-center" >{{row.ratePerMonth}}</td> -->
                                    <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId==1">
                                            {{row.status.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId==2">
                                            {{row.status.listTypeValueName}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody> 
                            <!-- <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="9" *ngIf="noRecordsFound">No  Records Found!</td>
                                </tr>
                            </tbody> -->
                            <tbody
                            style="
                              border-left: 1px solid #ddd;
                              border-right: 1px solid #ddd;
                            "
                          >
                            <tr>
                              <td
                                style="
                                  text-align: center;
                                  padding: 10px;
                                  font-size: 19px;
                                  font-weight: 600;
                                  font-family: 'Mulish', sans-serif;
                                  left: 5;
                                  position: relative;
                                "
                                colspan="7"
                                *ngIf="noRecordsFound || resultData.length==0"
                              >
                                Organization Records Not Found!
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     </div>
                    
                    <!-- <div *ngIf="nodatafound" class="no-data"> -->
                        <!-- <img src="assets/uploads/nodata.png"> -->
                        <!-- <span class="no-data-found"> No Data Found </span> -->
                    <!-- </div> -->
                    <div *ngIf="resultData?.length > 0 ">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div> -->

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>