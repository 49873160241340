import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
// import { ManageApproverComponent } from '../manage-approver/manage-approver.component';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
// import { ViewManageApproversComponent } from '../view-manage-approvers/view-manage-approvers.component';
import { Sort } from '@angular/material/sort';
import { ViewManageApproversComponent } from '../view-manage-approvers/view-manage-approvers.component';


@Component({
  selector: 'app-manage-approver-list',
  templateUrl: './manage-approver-list.component.html',
  styleUrls: ['./manage-approver-list.component.scss']
})
export class ManageApproverListComponent implements OnInit {
  query:any = '';
  p:any;
  mySelect:any =5;
  beforepage:any;
  list_loader: boolean =true;
  approverList: any =[];
  nodata: boolean =false;
  search: any;
  organzationid: string;
  pagePerItem:any=10;


  constructor(
    private router : Router,
    private approverservice :GlobalserviceService,
    private dialog : MatDialog
  ) { }

  ngOnInit(): void {
      const data = JSON.parse(localStorage.getItem('enoteUserData'))
      this.organzationid = data.organization.organizationId;
      console.log("organzationid" +this.organzationid);

      
          this.approverservice.getApprovers(this.organzationid).subscribe(
            (d: any[]) => {
              console.log(d);
              this.list_loader = false;
              this.approverList = d;
              this.nodata = false;
              console.log(this.list_loader);
              console.log(this.approverList);
              if(this.approverList.length == 0)
              {
                this.nodata = true;
                console.log("nodata");
              }
              if(data.statusCode)
              {
                this.nodata = true;
                console.log("nodata");
              }
            },
            (error) => {
              this.list_loader = false;
              this.nodata = true;
              console.log("error1")
              if(error.message == 'No Data'){
                this.nodata = true;
                console.log("404")
              }
            }
          );          
  }
  Add_approver(){
      this.router.navigate(['home/manage-approver']);
  }
  viewapprover(d : any){
      console.log(d);
      const dialogRef = this.dialog.open(ViewManageApproversComponent, {
        width: '500px',
        height:'fit-content',
        data :d
      })
  }
  updateapprover(approverId:any){
    this.router.navigate([`home/manage-approver/${approverId}`]); 
  }


  // delete(i) {
  //   console.log(i);
  //   Swal.fire({
  //     title: 'Are you sure? Do you want to delete',
  //     // text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete!'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.approverservice.deleteProject(i.projectId).subscribe(a => {
  //         console.log(a)
  //         Swal.fire({
  //           icon: 'success',
  //           text: "Deleted"
  //         });
  //         window.location.reload()
  //       })
  //     }
  //     // this.skillsArray.removeAt(id);
  //   })
  // }
  changefirstpage() {
    this.p = 1;
    }
  sortData(sort: Sort) {
    const data = this.approverList.slice();
    if (!sort.active || sort.direction === '') {
      this.approverList = data;
      return;
    }

    this.approverList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'rulename':
          return this.compare(a.rulename, b.rulename, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}


