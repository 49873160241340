import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { EMPTY, empty } from 'rxjs';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import Swal from 'sweetalert2';
import { ShowErrorComponent } from '../show-error/show-error.component';
import {
  redirectMsgTimeOut,
  responseMsgTimeOut,
} from 'src/app/providers/properties';
import { HeaderService } from 'src/app/providers/header.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';

@Component({
  selector: 'app-taskgroup',
  templateUrl: './taskgroup.component.html',
  styleUrls: ['./taskgroup.component.scss'],
})
export class TaskgroupComponent implements OnInit {
  selectedItem: any;
  // @ViewChild('editForm', {static: false}) editFormRef: ElementRef;
  tableShow:boolean =  false;
  item: any;
  
  clientworkingdaysForm: FormGroup<any>;
  p: number = 1;
  taskName: any;
  taskDescription: any;
  // myForm: FormGroup<{ taskgroupname: any; }>;
  editItem: any;
  usertData: any;
  orgId: any;
  row: any;
  taskGroupId: any;
  tskgname: any;
  tskGroupId: any;
  payloads: any;
  tskgId: any;
  tskgName: any;
  filteredData: any;
  employeeData: any;
  searchTerm: string = '';
  Taskgrouplist: any = [];
  beforesearchpage: any;
  // taskgroupname: any;
  // description: any;
  taskgroupList: any = [];
  mySelect: any = 5;
  financialPeriodList: any;
  finYearList: any;
  clientList: any;
  filterForm: FormGroup<any>;
  // filterData: any;
  pagePerItem: number = 5;
  tableSearch: any;
  taskgrouplist: any;
  // taskGroupName: string='';
  // taskdescript: string='';
  showError: Boolean=false;
  loanForm: any;
  submitted: Boolean = false;
  updateTaskgroup: any;
  taskGroup: FormGroup;
  createdata: any;
  userData: any;
  taskgroupdata: any;
  taskgroupid: any;
  updatedata: any;
  taskGroups: any;
  search: any;
  taskGROUPid: any;
  datas: any[] = [];
  prjstGroupList: any = [];
  originalData: any[] = [];
  taskProjectstagelist: any;
  nodata: boolean = false;
  loader: boolean = true;
  tastGrpId: any;
  taskId: any;
  displayedData: any[] = []; // Array to hold the data displayed on the current page

  success: boolean = false;
  sucess_msg: any;
  isEditMode: boolean = false;

  error: any = false;
  errorMsg: any;
  error_msg: any;
  importerror: boolean = false;
  errormessagejson: any;
isbuttondisabled: boolean=false;
saveButtonDisabled:boolean =  true;

  // totalItems: number = 30; // Assuming you have a total of 30 items
  // currentPage: number = 1; // Assuming you start on the first page

  constructor(
    public dialogRef: MatDialogRef<TaskgroupComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: FormBuilder,
    private gs: ProjectManagementService,
    private router: Router,
    private http: HttpClient,
    private headerservice: HeaderService,
    private elementRef: ElementRef,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
  ) {
    // this.gs.getTaskGroupname().subscribe((response)=>{
    //   this.taskgrouplist=response;
    //   console.log("response task group::", this.taskgrouplist);
    // taskdescscript:['',Validators.required]
    // taskgroup:['',Validators.required]
    // this.projectData()
    // })
  }
  ngOnInit(): void {
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage(
      ''
    );
    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    console.log('ngonit called');
    // this.headerservice.setTitle('Task Group');
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('this.data wtts check::', this.data);

    this.orgId = this.usertData.organization.organizationId;
    console.log('Organization Id::', this.orgId);

    this.originalData = this.data.slice();

    this.taskGroup = this.fb.group({
      // taskgroupname: ['', [Validators.required, Validators.pattern(/^\S.*$/)]],
      // taskgroupname: new FormControl(this.taskGroup, [
      //   Validators.required,
      //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      // ]),
      // taskgroupname: ['',[Validators.required, Validators.pattern(/^[a-zA-Z!@#$%^&*.?/]+(([. ][a-zA-Z!@#$%^&*.?/ ])?[a-zA-Z!@#$%^&*.?/]*)*$/)]],
      taskgroupname:new FormControl('',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")
]),

      // description: new FormControl(this.taskGroup, [
      //   Validators.required,
      //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      // ]),
      description:new FormControl('',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")
    ]),

      // description: ['', [Validators.required,Validators.pattern(/^[a-zA-Z!@#$%^&*.?/]+(([. ][a-zA-Z!@#$%^&*.?/ ])?[a-zA-Z!@#$%^&*.?/]*)*$/)]]


      // description: ['', Validators.required],
      // TaskGroupNameset()
    });
    this.storeOriginalData();
    this.projectData();
    // this.loadData();
    this.scrollToEditForm();

    
  }
  storeOriginalData() {
    this.originalData = JSON.parse(JSON.stringify(this.data)); // Deep copy the original data
  }
  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
  }
  
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if the clicked element is not the search input
  //   if (!(event.target as HTMLElement).closest('.form-control')) {
  //     this.search = ''; // Clear the search term
  //   }
  // }
  ngAfterViewInit() {
    // Scroll to the edit form after the view is initialized
    this.scrollToEditForm();
  }

  projectData() {
    this.tableShow =  false;
    console.log('projectdata is called');
    this.loader=true
    this.taskgrouplist = [];
    this.gs.getTaskGroupname().subscribe(
      (d: any) => {
        this.data = d;
        this.datas = d;
        this.tableShow =  true;
        this.loader = false;

        this.prjstGroupList = d;
        this.taskgroupList = d;
        this.tastGrpId = d.taskGroupId;

        console.log('response::', this.data);
        this.taskgroupList.forEach((item: any) => {
          // Access taskGroupId for each item
          this.taskId = item.taskGroupId;
          // console.log('Task Group ID:', this.taskId);
        });

        // if (this.search.length === 0) {
        //   this.datas = d;
        // }
        // if (this.taskProjectstagelist.length == 0) {
        //   this.nodata = true;
        //   console.log('nodata');
        // }

        this.taskgroupList.forEach((item: any) => {
          this.tskGroupId = item.taskGroupId;
          this.tskgname = item.taskGroupName;
          // console.log('Task Group ID:', this.tskGroupId);
          // console.log('Task Group Name::', this.tskgname);
        });
        // const arrayLength = d.length;
        // console.log("Length of d:", arrayLength);
      },
      (error) => {
        this.nodata = true;
        this.loader = false;
        this.tableShow =  true;
        console.log('error');
        if (error.status == 404) {
          this.nodata = true;
          console.log('404');
        }
      }
    );
  }
  get taskgroupname() {
    return this.taskGroup.get('taskgroupname');
  }
  get description() {
    return this.taskGroup.get('description');
  }
  clearForm() {
        console.log("this.taskGroup.value::",this.taskGroup);
        this.submitted=false
        // this.search=''

        this.taskGroup.patchValue({
          taskgroupname: '',
          description: '',
        });

        this.tskgId = undefined;
  }
  // formcancel() {
  //   console.log("this.taskGroup.value::",this.taskGroup.value);
    
  //   console.log("form cancel entered@!");
  //   this.taskGroup.get('description').setErrors(null); 
  //   // Clear error state for description control
  //   this.showError = false; 
    
  //   //  this.taskGroup.reset();
  //   //  this.showError=false
  //   // this.taskGroup.get('taskgroupname').reset();
  //   // this.taskGroup.get('taskgroupname').setErrors(null);

  //   // this.taskGroup.get('description').setErrors(null); // Clear errors for description control
  //   //  this.submitted=false

  //   // Reset edit mode to false
  //   // this.isEditMode = false;
  //   // this.submitted=true;
  //   // this.taskGroup.reset();
  //   // this.showError=false

  // //  this.clearForm()
  // }
  addtaskgroup() {}

  update(tskgroup: any, index: number) {
    console.log('valid', this.taskGroup.valid);
    console.log('row::', tskgroup);
    console.log('resp value::', this.taskGroup.value);

    this.taskGroup.patchValue({
      taskgroupname: tskgroup.taskGroupName,
      description: tskgroup.taskGroupDescription,
    });
    console.log('wtts taskgroup::', tskgroup);
    this.tskgId = tskgroup.taskGroupId;
    if (this.taskGroup.valid) {
      const dataUpdate = this.taskGroup.value;
      console.log('update data::', dataUpdate);
    }
    this.isEditMode = true;
    this.scrollToEditForm()

  }
  get f() {
    return this.taskGroup.controls;
  }
  onSubmit(event: Event) {
    this.search=''
    this.submitted = true;
    // this.showError=true
   
    // event.preventDefault();
    if (this.taskGroup.valid) {
      this.saveButtonDisabled =  false;
      this.submitted = false;
      this.showError=false
      this.isbuttondisabled=true
      const creationData = this.taskGroup.value;
      console.log('creationData', creationData);

      console.log('taskGroupId::', this.tskgId);
      console.log('task grou name::', this.tskgName);

      if (this.tskgId ) {
        console.log('taskGroupId::  if', this.tskgId);
        this.payloads = {
          taskGroupId: this.tskgId,
          taskGroupName: creationData.taskgroupname,
          taskGroupDescription: creationData.description,
          status: {
            listTypeValueId: 1,
          },
          organizationId: this.orgId,
        };
        this.gs
          .updatetaskgroup(this.payloads, this.tskgId)
          .subscribe((response: any) => {
            this.tskgId=undefined
            console.log('payload resp::', response);
            console.log(response.message);
            // this.isbuttondisabled=true


            if (response.StatusCode == 200) {
              this.success = true;
              this.sucess_msg = response.message;
              this.isbuttondisabled=true
              this.saveButtonDisabled =  true;
              setTimeout(() => {
                this.success = false;
                this.isbuttondisabled=false
                this.taskGroup.reset();


              }, 2000);
            }


            else{
              if(response.message=='TaskGroup already exists'){
              this.error = true;
              this.errorMsg = response.message;
              setTimeout(() => {
                this.error = false;
                // this.taskGroup.reset();

              }, 2000);
            }
          }
           
            (err) => {
              console.log('code', err);
              console.log('code', err.status);
              this.error = true;
              this.errorMsg = this.error.message;
              setTimeout(() => {
                this.error = false;
              }, 1000);
            };

            this.projectData(); //without reload the page//
            this.isEditMode = true;
          });
      } 
      // else {

      if (this.tskgId == undefined) {
        
    
        console.log('else');
        const payload = {
          taskGroupName: creationData.taskgroupname,
          taskGroupDescription: creationData.description,
          status: {
            listTypeValueId: 1,
          },
          organizationId: this.orgId,
        };
        const data = payload.taskGroupName;
        const arrayLength = data.length;
        console.log('Length of data:', arrayLength);
        console.log('payload::', payload);

        // if (this.tskgName == null || this.tskgName.trim() == '') {
          this.gs.createtaskgroup(payload).subscribe(
            (response: any) => {
              console.log(' updated payload response::', response);
              this.isbuttondisabled=true
              this.saveButtonDisabled =  true;
              console.log('response .statuscode::', response.StatusCode);
              if (response.StatusCode == 200) {
                this.success = true;
                this.isbuttondisabled=true

                this.sucess_msg = response.message;
                setTimeout(() => {
                  this.success = false;
                  this.isbuttondisabled=false
                  this.taskGroup.reset();
     

                }, 3000);
              }
              if(response.message=='TaskGroup already exists'){
                this.success = true;
                this.sucess_msg = response.message;
                this.isbuttondisabled=true

                setTimeout(() => {
                  this.success = false;
                  this.isbuttondisabled=false

                }, 3000);
              }

              this.projectData();
            },
            (err) => {
              this.error = true;
              this.errorMsg = err.error.message;
              console.log('code', err);
              console.log('code', err.status);
              setTimeout(() => {
                this.error = false;

              }, 3000);
            }
          );

        }
        // }
      // }
    }
    this.isbuttondisabled=false

  }

  changefirstpage() {
    this.p = 1;
  }
  showerrormessage() {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }
  filterByValue() {
    // if (this.search && this.search.length !== 0) {
    //   this.p = 1;
    //   this.datas = this.datas.filter((o) =>
    //     Object.keys(o).some(
    //       (k) =>
    //         String(o[k])
    //           .toLowerCase()
    //           .includes(this.search.toLowerCase())
    //     )
    //   );
    // } else {
    //   this.p = this.beforesearchpage;
    //   this.datas = [...this.data]; // Reset to original data when search is cleared
    // }
    // console.log(this.taskgroupList.length);
    // if (this.search.length != 0) {
    //   this.p = 1;
    //   console.log(this.taskgroupList.length);
    //   // this.taskEmpList.length=0;
    //   // console.log(this.taskEmpList.length);
    //   // if(this.taskEmpList.length == 0)
    //   //     {
    //   //       this.nodata = true;
    //   //       console.log("nodata")taskgroupList
    //   //     }
    //   // else
    //   // {
    //   //   this.p = 1;
    //   console.log(this.p);
    //   console.log('success Data');
    // } else {
    //   this.p = this.beforesearchpage;
    //   // this.nodata = true;
    //   console.log(this.taskgroupList.length);
    //   console.log('nodata');
    // }
    console.log(this.taskgroupList.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.taskgroupList.length);
   
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.taskgroupList.length);
      console.log("nodata");
    }
  }
  // clearForm() {

  //   this.taskId = null;
  // }

  
  sortData(sort: Sort) {
    const datas = this.taskgroupList.slice();
    if (!sort.active || sort.direction === '') {
      this.taskgroupList = datas;
      return;
    }
    this.datas = datas.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'taskGroupName':
          return this.compare(a.taskGroupName, b.taskGroupName, isAsc);
        case 'taskGroupDescription':
          return this.compare(
            a.taskGroupDescription,
            b.taskGroupDescription,
            isAsc
          );
        case 'statusName':
          return this.compare(a.statusName, b.statusName, isAsc);

        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
