<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
     <h3> {{countryId ? "Update" : "Add"}} Country</h3>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="form-group col-md-6">
                <label class="col-form-label">Country Name <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <input type="text" (keyup)="checkExistCountryName($event)" formControlName="countryName" id="countryName" placeholder="Country Name" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg" value="" autocomplete="off">
                <div class="valid-msg" *ngIf="(validate.countryName.invalid && validate.countryName.touched) || validate.countryName.dirty || submitted">
                    <span *ngIf="validate.countryName.errors?.required" class="text-danger">Please enter country name</span>
                    <span *ngIf="validate.countryName.errors?.pattern" class="text-danger">Please enter valid country name</span>
                    <span *ngIf="countryNameExistStatus" class="span-error"> Country name already exist!</span>
                </div>
            </div>

            <div class="form-group col-md-6">
                <label class="col-form-label">Country Code <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentName($event)" -->
                <input type="text" (keyup)="checkExistCountryCode($event)" formControlName="countryCode" id="countryCode" placeholder="Country Code" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="">
                <p class="text-muted">Ex : +91,+92,+93, etc..</p>

                <div class="valid-msg" *ngIf="(validate.countryCode.invalid && validate.countryCode.touched ) || validate.countryCode.dirty || submitted">
                    <span *ngIf="validate.countryCode.errors?.required" class="text-danger">Please enter country code</span>
                    <span *ngIf="validate.countryCode.errors?.pattern" class="text-danger">Please enter valid country code</span>
                    <span *ngIf="countryCodeExistStatus" class="span-error"> Country Code already exist!</span>
                    <!--  <span *ngIf="departNameExistStatus" class="span-error"> Department name already exist!</span>
                --></div>
            </div>
        </div>


        <div class="row">
            <div class="form-group col-md-6">
                <label class="col-form-label">Country Symbol <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <input type="text" formControlName="countrySymbol" id="countrySymbol" placeholder="Country Symbol" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg" value="" autocomplete="off">
                <p class="text-muted">Ex : $,AL,AX, etc..</p>
                <div class="valid-msg" *ngIf="(validate.countrySymbol.invalid && validate.countrySymbol.touched) || validate.countrySymbol.dirty || submitted">
                    <span *ngIf="validate.countrySymbol.errors?.required" class="text-danger">Please enter country symbol</span>
                    <span *ngIf="validate.countrySymbol.errors?.pattern" class="text-danger">Please enter valid country symbol</span>
                    <!-- <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
                 -->
                </div>
            </div>

            <div class="form-group col-md-6">
                <label class="col-form-label">Currency Code <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentName($event)" -->
                <input type="text" formControlName="currencyCode" id="currencyCode" placeholder="Currency Code" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="">
                <p class="text-muted">Ex : INR,USD,EUR,etc...</p>
                <div class="valid-msg" *ngIf="(validate.currencyCode.invalid && validate.currencyCode.touched ) || validate.countryCode.dirty || submitted">
                    <span *ngIf="validate.currencyCode.errors?.required" class="text-danger">Please enter currency code</span>
                    <span *ngIf="validate.currencyCode.errors?.pattern" class="text-danger">Please enter valid currency code</span>
                    <!--  <span *ngIf="departNameExistStatus" class="span-error"> Department name already exist!</span>
                --></div>
            </div>
            <div class="form-group col-md-6">
                <label class="col-form-label">Mobile Number Length</label>
                <!-- (keyup)="checkExistDepartmentName($event)" -->
                <input type="text" formControlName="maxPhoneNumberLength" id="maxPhoneNumberLength" placeholder="Mobile Number Length" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="">
                <p class="text-muted">Ex : 9,10,11...</p>
                <!-- <div class="valid-msg" *ngIf="(validate.currencyCode.invalid && validate.currencyCode.touched ) || validate.countryCode.dirty || submitted">
                  <span *ngIf="validate.currencyCode.errors?.required" class="text-danger">Please enter currency code</span>
                  <span *ngIf="validate.currencyCode.errors?.pattern" class="text-danger">Please enter valid currency code</span>
                  <span *ngIf="departNameExistStatus" class="span-error"> Department name already exist!</span>
              </div> -->
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>