<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Loan</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/loan" routerLinkActive="active" color="primary" mat-raised-button title="Request Salary Advance" class="ml-2">
                        Employee Loan
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="table-responsive" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                    <table class="table table-border-new"style="border-top-left-radius: 10px;border-top-right-radius: 10px;" >
                        <thead class="t-head">
                            <tr>
                                <th class="text-center">Action</th>
                                <th class="text-center">Employee Loan Number</th>
                                <th class="text-center">Approved Loan Amount</th>
                                <th class="text-center">Request Loan Amount</th>
                                <th class="text-center">Loan Type</th>
                                <th class="text-center">Loan Interest %</th>
                                <th class="text-center">No.of Installments</th>
                                <th class="text-center">Total Salary</th>
                                <th class="text-center">View approvers</th>
                                <th class="text-center">Approval&nbsp;Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let e of loanList | paginate: { itemsPerPage: pagePerItem, currentPage: p } ">
                                <td class="text-center">
                                    <button class="menu-button"
                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="openDialog(e.employeeLoanId)" class="menu-class">
                                            <mat-icon class="material-symbols-outlined">visibility</mat-icon>
                                            <span mat-button class="">View</span>
                                        </button>
                                        <button [disabled]="e.approval" mat-menu-item class="menu-class" 
                                        routerLinkActive="active" routerLink="/home/loan/{{e.employeeLoanId}}">
                                            <mat-icon class="material-icons-outlined">edit</mat-icon>
                                            <span mat-button class="">Edit</span>
                                        </button>
                                    </mat-menu>
                                </td>
                                <td class="text-center">{{e.employeeLoanNumber}}</td>
                                <td class="text-right">{{e.loanApprovedAmount.toFixed(2)}}</td>
                                <td class="text-right">{{e.loanRequestedAmount.toFixed(2)}}</td>
                                <td class="">Loan Typ ????</td>
                                <td class="text-center">{{organizationSettings.maxLoanInterest}} %</td>
                                <td class="text-center">{{e.employeeLoanInstallments.length}}</td>
                                <td class="text-right">{{e.totalSalary.toFixed(2)}}</td>
                                <td class="text-center">
                                    <mat-icon class="view-icon" *ngIf="e.approval != undefined" 
                                    (click)="viewapproverstatus(e)">visibility</mat-icon>
                                    <span *ngIf="e.approval == undefined">  --  </span>
                                </td>
                                <td class="text-center">
                                    <div *ngIf="e.approval != undefined">
                                        <span class="text-success" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Approved'">
                                            {{e.approval.approvalStatus.listTypeValueName}}
                                        </span>
                                        <span class="text-warning" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Pending'">
                                            {{e.approval.approvalStatus.listTypeValueName}}
                                        </span>
                                        <span class="text-danger" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Denied'">
                                            {{e.approval.approvalStatus.listTypeValueName}}
                                        </span>
                                    </div>
                                    <div class="text-success" *ngIf="e.approval == undefined">Saved as Draft</div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="10" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
