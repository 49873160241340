<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class=" h5 sub-content">{{userId ? 'User' : 'Employee'  }} Roles</span>
                </div>
                <div class="sub-header-buttons">
                    <a *ngIf="userId == null "  routerLink="/home/employee" mat-raised-button class="ml-2">
                       Back
                    </a>
                    <a *ngIf="userId != null "  routerLink="/home/users" mat-raised-button class="ml-2">
                        Back
                     </a>

                    <a (click)="addForm(resultData);"  style="background: #1a83ff;color:#fff" mat-raised-button class="ml-2">
                        Role Assignment
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="row">
            <div class="col-md-12">
                <div class="card" *ngIf="!loader">
                    <div class="card-body" *ngIf="userId  == null  ">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">

                                    <span *ngIf="fileblob">
                                        <img class="profile-image" src="{{fileblob}}" alt="Profile Image" style="width: 75px;height: 75px;" >
                                    </span>

                                    <span *ngIf="!fileblob">
                                        <img class="profile-image" src="assets/uploads/load.png" style="width: 75px;height: 75px;" alt="Profile Image">
                                    </span>

                                    <!-- <a class="edit-icon" routerLink="#"><mat-icon class="edit-profile">edit</mat-icon></a>
                                    -->
                                </div>
                            </div>

                            <div class="col-md-10">
                                <div class="profile-name mb-2">
                                    {{resultData.middleName ? resultData.middleName:""}}  {{resultData.firstName}}  {{resultData.lastName}}
                                </div>
                                <div class="profile-title">
                                    <div class="row">
                                        <span class="profile-icons" *ngIf="resultData.designation"><mat-icon class="profile-mat-icon">account_circle</mat-icon>
                                            {{resultData.designation ? resultData.designation.designationName :""}}
                                        </span>
                                        <span *ngIf="resultData.address" class="profile-icons"><mat-icon class="profile-mat-icon">location_on</mat-icon>
                                            {{resultData.address ? resultData.address :""}}
                                        </span>
                                        <span *ngIf="resultData.organizationEmail" class="profile-icons"><mat-icon class="profile-mat-icon">email</mat-icon>
                                            {{resultData.organizationEmail ? resultData.organizationEmail :""}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="userId  != null && !loader">
                        <div class="row">

                            <div class="col-md-12">
                                <!-- <div class="profile-name mb-2">
                                    {{resultData.firstName}} {{resultData.middleName ? resultData.middleName:""}} {{resultData.lastName}}
                                </div> -->
                                <div class="profile-title">
                                    <div class="row">
                                     
                                        <span *ngIf="userresultData.emailId" class="profile-icons"><mat-icon class="profile-mat-icon">email</mat-icon>
                                            {{userresultData.emailId ? userresultData.emailId :""}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   
    <div class="card-new">
        <div class="card"   *ngIf="(resultData?.role?.length > 0 || userresultData?.role?.length > 0)">
            <div class="card-body" >

                <!-- <div class="row mb-2">
                    <div class="col-md-8">
                        <div class="row mb-3 pl-0">
                            <div class="col-md-4">
                                <input type="search"  autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" name="keywords" value="" placeholder="Search...">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-9 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0"> 
                                <select  class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- matSort (matSortChange)="sortData($event)" -->
                <table  
                class="table --table-responsive table-bordered table-border-new">
                    <thead class="t-head">
                        <tr>
                            <!--  <th class="text-center">Action</th> -->
                            <th>Role Name</th>
                            <!-- <th>Role Description</th> -->
                            <!-- <th class="text-center">Status</th> -->
                        </tr>
                    </thead>
                    <tbody>
                       <tr *ngFor="let row of resultData.role"> <!--  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index; -->
                            <!-- <td class="text-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu" class="controls-list">
                                    <button mat-menu-item (click)="updateform(row)">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button>

                                    <button mat-menu-item *ngIf="row.departmentStatus.listTypeValueId == 1" (click)="updateStatus(row.departmentId,2)">
                                        <mat-icon>done</mat-icon>
                                        <span>Inactive</span>
                                    </button>

                                    <button mat-menu-item *ngIf="row.departmentStatus.listTypeValueId == 2" (click)="updateStatus(row.departmentId,1)">
                                        <mat-icon>done</mat-icon>
                                        <span>Active</span>
                                    </button>
                                </mat-menu>
                            </td> -->

                            <td>{{row.roleName}}</td>
                            <!-- <td>{{row.roleDescription}}</td> -->
                            <!-- <td class="active-status text-center">
                                <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.departmentStatus.listTypeValueId == 1">
                                    {{row.departmentStatus.listTypeValueName}}
                                </span>

                                <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.departmentStatus.listTypeValueId == 2">
                                    {{row.departmentStatus.listTypeValueName}}
                                </span>
                            </td> -->
                        </tr>
                        <ng-container *ngIf="userresultData != undefined">
                            <tr *ngFor="let row of userresultData.role"> <!--  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index; -->
                                <!-- <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="updateform(row)">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.departmentStatus.listTypeValueId == 1" (click)="updateStatus(row.departmentId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>Inactive</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.departmentStatus.listTypeValueId == 2" (click)="updateStatus(row.departmentId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>
                                    </mat-menu>
                                </td> -->
    
                                <td>{{row.roleName}}</td>
                                <td>{{row.roleDescription}}</td>
                                <!-- <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.departmentStatus.listTypeValueId == 1">
                                        {{row.departmentStatus.listTypeValueName}}
                                    </span>
    
                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.departmentStatus.listTypeValueId == 2">
                                        {{row.departmentStatus.listTypeValueName}}
                                    </span>
                                </td> -->
                            </tr>
                        </ng-container>
                        
                    </tbody>
                     <!-- <tbody
              style="border-right: 1px solid #ddd; border-left: 1px solid #ddd"
            >
              <tr>
                <td
                  style="
                    text-align: center;
                    font-size: larger;
                    font-weight: bold;
                  "
                  colspan="7"
                  *ngIf="noRecordsFound"
                >
                 Client Working Days Records Not Found!
                </td>
              </tr>
            </tbody> -->
                </table>

                <!-- <div class="row">
                    <div class="col-md-4">
                        <div class="showing-record">
                            {{resultData.length}} rows
                        </div>
                    </div>
                    <div class="col-md-8 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
   

</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"   style="width: 3rem; height: 3rem;"></div>
</div>



<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
    <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
        <strong>Error!</strong><br>{{error_msg}}
    </div>
</div>