import { Component, OnInit, Inject,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../../app/providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
// import { base_url,httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';

@Component({
  selector: 'app-import-employee',
  templateUrl: './import-employee.component.html',
  styleUrls: ['./import-employee.component.scss']
})
export class ImportEmployeeComponent implements OnInit
{
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;


  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  fileInputLabel: string;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:boolean=false;
  error:any=false;
  backdrop:any= false;
  selectedFile: File | null = null;
  employeefile :any;
  employeetData:any;
  errorrecords: any;
  constructor(
    private settingsservice: SettingsService,
    private EmployeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<ImportEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      file : ['',[Validators.required]]
    });

    console.log("edit_data::",this.edit_data);
    // this.fileInputLabel = '';
  }

  get validate() { return this.postForm.controls; }
  onCancel() {
    if (this.postForm.invalid) {
      this.submitted = false;
      this.postForm.reset();
    }
    this.postForm.reset();
    this.postForm.get('file').reset();

    this.submitted = false; // Reset the submitted flag to false
    // this.postForm.reset(); // Optionally reset the form if needed
    // this.dialogref.close(); // Close the dialog
  }
  get file() {
    return this.postForm.get('file');
  }
  onSubmit()
  {
    /* if (!this.postForm.get('myfile').value)
    {
      alert('Please fill valid details!');
      return false;
    } */

    this.submitted = true;
    if (this.postForm.invalid) {return;}

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;
    let clientid=this.employeetData.employee?.client?.clientId;
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;
    let clientDetailStore=clientid?{clientId:clientid}:null;
  

    
    if(organizationId){
      const formData = new FormData();
      // formData?.append('file', this.selectedFile);

      formData?.append('file', this.postForm?.get('file').value);
      formData?.append('organizationId', organizationId);
      console.log('formData',formData);

      // this.EmployeeService.employeeimport(formData).subscribe((result: any) =>
      this.EmployeeService.employeeimportV2(formData).subscribe((result: any) =>
      {
        console.log("resulr:::",result);
        
        if(result.statusCode == 409)
        {
          //this.loader = true;
          this.success = true;
          // this.sucess_msg = result.description;
  
         
        
          // setTimeout(() => {this.success  = false;},responseMsgTimeOut)
          // this.backdrop = false;
          // this.loader = false;
          // this.success = true;
          this.dialogref.close(result);
  
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          this.errorrecords = result;
          this.dialogref.close(result);
        }
      }, error => {
        console.log(error);
      }); //Create end here
    }
    else{
      if(clientid){
        const formData = new FormData();
    formData?.append('file', this.postForm?.get('file').value);

    console.log('formData',formData);
    
    formData?.append('clientId',clientid );

    // this.EmployeeService.employeeimport(formData).subscribe((result: any) =>
    this.EmployeeService.employeeimportV2byClient(formData).subscribe((result: any) =>
    {
      if(result.statusCode == 409)
      {
        //this.loader = true;
        this.success = true;
        // this.sucess_msg = result.description;

       
      
        // setTimeout(() => {this.success  = false;},responseMsgTimeOut)
        // this.backdrop = false;
        // this.loader = false;
        // this.success = true;
        this.dialogref.close(result);

      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        this.errorrecords = result;
        this.dialogref.close(result);
      }
    }, error => {
      console.log(error);
    }); //Create end here
      }
    }
    

  }

  onFileSelect(event)
  {
    //let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0)
    {
      const file = event.target.files[0];
      //this.fileToUpload = event.item(0);
      // console.log(file);

      /*   if (!_.includes(af, file.type)) {
        alert('Only EXCEL Docs Allowed!');
      } else { */
        this.fileInputLabel = file.name;
        this.postForm?.get('file')?.setValue(file);
        // this.selectedFile = file;
        // console.log("this.selectedFile",this.selectedFile);
      ///}
    }
  }
  // onFileSelect(event: Event): void {
  //   const fileInput = event.target as HTMLInputElement;

  //   console.log("fileInput",fileInput);
    
  
  //   // Ensure the input element exists before accessing properties
  //   if (!fileInput || !fileInput.files) {
  //     return;
  //   }
  
  //   const filePath = fileInput.value;
  //   const allowedExtensions = /(\.xls|\.xlsx)$/i;
  
  //   if (!allowedExtensions.exec(filePath)) {
  //     alert('Please upload a file with .xls or .xlsx extension.');
      
  //     // Create a safer way to reset the file input
  //     fileInput.value = ''; // May still cause error in some cases
  //     event.preventDefault();
  //   } else {
  //     if (fileInput.files.length > 0) {
  //       const file = fileInput.files[0];
  //       this.fileInputLabel = file.name;
  //       this.postForm?.controls['file']?.setValue(file); // Bind the file to your form control
  //     }
  //   }
  // }
}

