<!-- <div class="main-content" style="mrgin-top:70px;"> -->
    <!-- <div class="container-fluid" > -->
      <!-- <div class="top-content row">
        <div
          class="col-md-5"
          style="mrgin-top:70px;"
        >
          <h4>My Tasks</h4>
         
        </div>
       
        <div>
        
  
          <div class="example-width">
            <ul class="unstyled inbox-pagination">
              <div class="sidebar-search">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control search-menu"
                    placeholder="Search..."
                    [(ngModel)]="search"
                    (keyup)="changepage()"
                    name="searchText"
                  />
                  <div class="material-icons-outlined search-icon">search</div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div> -->
      <nav class="navbar bg" style="margin-top: 68px">
        <div class="container-fluid">
          <a class="navbar-brand brandDesign">My Tasks</a>
          <div class="col-md-5 col-sm-5">
            <ul class="unstyled inbox-pagination">
              <div class="sidebar-search">
                <div class="input-group">
                  <input
                  type="input"
                    class="form-control search-menu search-placeholder"
                    placeholder="Search..."
                    [(ngModel)]="search"
                    (keyup)="changepage()"
                    name="searchText"
                  />
                  <!-- <div class="material-icons-outlined search-icon">search</div> -->
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav> 
      <mat-card style="border-radius: 15px;
      border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.50));
      background: #FDFDFD;
     ">
      <div class="search-content">
        <!-- <form  [formGroup]="taskListForm"> -->
        <div class="row">
          <div class="col-sm-3 min-height">
            <label class="form-control-label">Task Group</label>
            <mat-select
            (selectionChange)="search = ''"
              class="form-control email-input select-placeholder"
              [(ngModel)]="selectedetaskGroupId"
              placeholder="Select Task Group"
            >
              <mat-option >-Select Task Group-</mat-option>
              <mat-option
                *ngFor="let taskgroup of taskgrouplist"
                [value]="taskgroup.taskGroupId"
              >
                {{ taskgroup.taskGroupName }}
              </mat-option>
            </mat-select>
           
          </div>
          <div class="col-sm-3 min-height">
            <label class="form-control-label">Task Status</label>
            <mat-select
            (selectionChange)="search = ''"
              id="framework"
              class="form-control email-input select-placeholder"
              [(ngModel)]="taskStatusId"
              placeholder="Select Task Status"
            >
              <mat-option  >-Select Task Status-</mat-option>
              <mat-option [value]="31">To Do</mat-option>
              <mat-option [value]="29">In Progress</mat-option>
              <mat-option [value]="30">Completed</mat-option>
              <mat-option [value]="3">Pending</mat-option>

            </mat-select>
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2 mt-sm-3 col-xl-2">
            <label class="form-control-label">Start Date</label>
            <input
              placeholder="Start Date"
              (click)="search = ''"
              matInput
              [matDatepicker]="picker1"
              class="form-control email-input select-placeholder"
              [(ngModel)]="startDate"
              type="text"
              [min]="today" readonly
              (dateChange)="checkStartDate();updateEndDateMin($event)"
              id="startDate"
            />
            <mat-datepicker-toggle
            (click)="search = ''"
              matSuffix
              [for]="picker1"
              style="float: right; position: relative; bottom: 42px"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <!-- <span class="error_msg" *ngIf="startdate_required"
              >Please select startdate</span
            > -->
            <!-- <div *ngIf="(isSubmitted && taskListForm.controls['StartDate'].invalid)">
                    <small *ngIf="taskListForm.controls['StartDate'].errors?.required"  style="color:red;font-size:10px;">please select startdate</small>
                  </div> -->
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2 mt-sm-3 col-xl-2">
            <label class="form-control-label">End Date</label>
            <input
              placeholder="End Date"
              matInput
              (click)="search = ''"
              [matDatepicker]="picker2"
              readonly
              [(ngModel)]="endDate"

              class="form-control email-input select-placeholder"
              
              type="text"
              [min]="startDate"
              [max]="today"         
              (dateChange)="dateChange($event);checkStartDate();updateEndDateMin($event)"
  
               id="endDate"
            />
            <mat-datepicker-toggle
            (click)="search = ''"
              matSuffix
              [for]="picker2"
              style="float: right; position: relative; bottom: 42px"
            ></mat-datepicker-toggle>
            <mat-datepicker  #picker2></mat-datepicker>
            <!-- <span class="error_msg" *ngIf="enddate_required"
              >Please select enddate</span
            > -->
            <!-- <span class="error_msg" *ngIf="errorMsg">Please Give Vaild EndDate</span> -->
            <!-- <div *ngIf="(isSubmitted && taskListForm.controls['EndDate'].invalid)"> -->
            <!-- <small *ngIf="taskListForm.controls['EndDate'].errors?.required"  style="color:red;font-size:10px;">please select enddate</small> -->
            <!-- </div> -->
            <!-- <span
              class="error_msg"
              *ngIf="errorMsg"
              style="color: red; font-size: 12px"
              >Invalid enddate</span
            > -->
          </div>
          <div class="col-md-6 col-lg-2 col-xl-2 button-align mt-xl-n1" style="align-items: center; display: inline-flex;top: 7px;">
            <button type="button" mat-raised-button class="btns searchBtn " (click)="searchTask()" [disabled]="isbtnDisabled">
              Search
            </button>
            <button style="    margin-left: 10px;
            font-size: 16px;font-weight: 610;
            height: 35px;
            top: 1px;
            background: none;
            border:1px solid rgba(74, 70, 70, 0.566) !important;
            box-shadow: none;
            color: black;" (click)="resetForms()" mat-raised-button
            [disabled]="isbtnDisabled"
            class="btns search-btn mr-2 btnDesign btn1"
            >Clear</button>
          </div>
        </div>
        <!-- </form> -->
      </div>
      <!-- *ngIf="taskList.length > 0" -->
      
      <div *ngIf="tableShow">
        <div class="card-content" >
          <div class="card-body table-responsive">
            <!-- <ng-container *ngIf="taskEmpList.length > 0;else elseProject"> -->
              <!-- *ngIf="taskList.length > 5" -->
            <div class="row p-0 m-0"  *ngIf="taskList.length > 5 && (taskList | mytask : search).length > 0">
              <div class="col-xl-12 col-lg-12" *ngIf="(taskList | mytask : search).length > 5">
                <div class="select-pagination">
                  <span>Show : </span>
                  <select
                    class="select_list new-select_list"
                    [(ngModel)]="mySelect"
                    (change)="showChanges()" style="
                    border-radius: 5px;
                    border: 1px solid ;
                    background: #FFF;
                    margin-bottom: 8px;"
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div>
            <table class="table" matSort (matSortChange)="sortData($event)">
              <thead>
                <tr class="tr" id="trDesign">
                  <th style="text-align: center" scope="col" 
                  style="border-radius: 15px 1px 0px 0;border: none;text-align: center;">S.No</th>
                  <th mat-sort-header="taskGroupName" scope="col">
                    Group&nbsp;Name
                  </th>
                  <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
                  <th
                    mat-sort-header="taskStatusName"
                    style="text-align: center"
                    scope="col"
                  >
                    Task&nbsp;Code
                  </th>
                  <th
                    mat-sort-header="taskStartDate"
                    scope="col"
                    style="text-align: center"
                  >
                    Start&nbsp;Date
                  </th>
                  <th
                    mat-sort-header="taskEndDate"
                    scope="col"
                    style="text-align: center"
                  >
                    &nbsp;End&nbsp;Date&nbsp;
                  </th>
                  <th
                    mat-sort-header="workhours"
                    style="text-align: center"
                    scope="col"
                  >
                    Total&nbsp;Hours
                  </th>
                  <th
                    mat-sort-header="taskStatusNames"
                    style="text-align: center"
                    scope="col"
                    style="border-radius: 0px 15px 0px 0px;text-align: center;"
                  >
                    Task&nbsp;Status
                  </th>
                </tr>
              </thead>
              <tbody style="border-bottom: 1px solid #ddd;
              border-left: 1px solid #ddd;
              border-right: 1px solid #ddd;">
                <tr
                  *ngFor="
                    let e of taskList
                      | mytask : search
                      | paginate : { itemsPerPage: mySelect, currentPage: p };
                    let i = index
                    
                  "
                >
                  <td *ngIf="p" style="text-align: center">
                    {{ (p - 1) * mySelect + (i + 1) }}
                  </td>
                  <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
                  <td *ngIf="e.taskGroup != undefined">
                    {{ e.taskGroup.taskGroupName }}
                  </td>
                  <td *ngIf="e.taskGroup == undefined">--</td>
                  <td>{{ e.taskName }}</td>
                  <td class="text-center">
                    <ng-container *ngIf="!e.taskCode; else showTaskCode">{{
                      "-"
                    }}</ng-container>
                    <ng-template #showTaskCode>{{ e.taskCode }}</ng-template>
                  </td>
  
                  <td style="text-align: center; padding-right: 25px">
                    {{ e.taskStartDate }}
                  </td>
                  <td style="text-align: center; padding-right: 25px">
                    {{ e.taskEndDate }}
                  </td>
                  <td style="text-align: center">
                    {{ (e.workhours !== undefined ? e.workhours?.toString().padStart(2, '0') : '00') }}:{{ (e.workminutes !== undefined ? e.workminutes?.toString().padStart(2, '0') : '00') }}:00
                  </td>
                
                  <td
                    style="text-align: center; padding-right: 25px"
                    [style.color]="taskStatus(e)"
                  >
                    {{ e.taskStatusName }}
                  </td>
                </tr>
                <!-- <tr *ngIf="(taskList | mytask : search).length === 0 || nodata || taskList.length == 0">
                  <td class="searchTaskdesign" colspan="/* number of columns */" style="    font-size: 19px;
                  font-weight: 600;" > My Tasks Not Found!</td>
                </tr> -->
                <td style="text-align: center; font-size: larger; font-weight: bold;" 
                colspan="9"  *ngIf="nodata || (taskList | mytask : search).length === 0 && search || taskList.length == 0 " >  My Tasks Not Found!</td>
              </tbody>
            </table>
            <!-- <pagination-controls (pageChange)="p = $event"
              align="end" id="page"></pagination-controls> -->
             
            <!-- <div
              class="col-md-12 text-right"
              *ngIf="taskList.length > 5 && (taskList | mytask : search).length > 0" id="page"
              style="padding-right: 0px;margin-top: 29px;"
            > -->
              <!-- <pagination-controls  *ngIf=" taskList.length > 5" style="    position: relative;
              float: inline-end;"
                (pageChange)="p = $event"
              ></pagination-controls> -->
              <div
      class="pagination-content"
      *ngIf="taskList.length > 5 && (taskList | mytask : search).length > 0"
      id="page"
    >
      <pagination-controls  *ngIf="(taskList | mytask : search).length > 5"
        style="position: relative; float: inline-end"
        (pageChange)="p = $event"
        align="end"
      ></pagination-controls>
    </div>
            <!-- </div> -->
            <!-- </ng-container> -->
          </div>
        </div>
        <!-- <div class="card-content"  *ngIf="nodata">
          <div class="card-body">
            <div class="alert alert-primary">
              <h4 align="center" style="font-size: 26px;
              font-family: 'Mulish', sans-serif;
              margin-top: 6px;
              " >My Task Not Found</h4>
            </div>
          </div>
        </div> -->
        <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
          <div class="card-body-nodata">
            <div class="alert-nodata alert-primary">
            
              <h4 align="center" style="
              "  class="h4" style="margin-top: 10px;font-size: 24px;font-family: Roboto, Helvetica Neue, sans-serif;;">My Tasks Not Found</h4>
            </div>
          </div>
        </mat-card> -->
      </div>
    </mat-card>
    <!-- </div> -->
  <!-- </div> -->
  <!-- <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem;margin-left: 600px;margin-left:1000px;color: #1A83FF;"
      role="status"
    ></div>
  </div> -->

  <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem"></div>
  </div>
  