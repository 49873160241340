<div class="resource-background">
  <div
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <div class="resource-title">
      <h2
        style="
          font-size: 23px;
          font-family: 'Mulish', sans-serif;
          font-weight: 700;
        "
      >
        View Position
      </h2>
    </div>
    <div class="">
      <mat-icon class="material-symbols-outlined back" mat-dialog-close>
        cancel
      </mat-icon>
    </div>
  </div>

  <div class="resource-card">
    <form>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Position Name : </label>
          <label class="form-control-label-class">
            {{ positionobj?.positionName }}</label
          >
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Position Number : </label>
          <label class="form-control-label-class">
            {{ positionobj?.positionNumber }}</label
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Experience : </label>
          <label class="form-control-label-class">
            {{ positionobj?.minExperience }} -
            {{ positionobj?.maxExperience }} Years</label
          >
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Location : </label>
          <label class="form-control-label-class">
            {{ positionobj?.location }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">CTC : </label>
          <label class="form-control-label-class">
            {{ positionobj?.ctc }}</label
          >
        </div>
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Hiring For : </label>
          <label class="form-control-label-class">
            {{ positionobj?.hiring?.listTypeValueName }}</label
          >
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Client Name : </label>
                    <div class="form-control-label-class" *ngIf="positionobj.clientId != undefined"> {{positionobj.clientId.clientName}}</div>
                    <div class="form-control-label-class" *ngIf="positionobj.clientId == undefined">     --    </div>
                </div>    -->
        <div class="col-md-6" id="loginform">
          <label class="form-control-label">Job Type : </label>
          <label class="form-control-label-class">
            {{ positionobj?.jobType?.listTypeValueName }}</label
          >
        </div>
      </div>
      <!-- <div class="row">  
                <div class="col-md-6">
                    <label class="form-control-label">Notice Period : </label>
                    <div>
                        <span *ngFor="let e of positionobj">  
                            <label class="form-control-label-class badge badge-info"> {{e.noticePeriod.listTypeValueName}}</label>
                        </span>
                    </div>
                </div>  
                                                
            </div> -->
    </form>
    <div *ngIf="loader" class="align-items-center text-center">
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      ></div>
    </div>
  </div>
</div>
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div> -->
