import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, Subject, switchMap, takeUntil } from 'rxjs';
// import { AppComponent } from 'src/app/AppComponent';
import { AppComponent } from '../../app.component';
// import { PayrollService } from 'src/app/providers/payroll.service';
// import { SettingsService } from 'src/app/providers/settings.service';
// import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
// import { ExcelService } from 'src/app/services/excel.service';
// import { ExportExcelService} from 'src/app/services/export-excel.service';
import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { PayrollService } from '../../providers/payroll.service';
import { SettingsService } from '../../providers/settings.service';
// import { ShowerrorComponent } from '../../../app/showerror/showerror.component';
import { ExcelService } from '../../services/excel.service';
import { ExportExcelService } from '../../services/export-excel.service';
import { SuperAdminComponent } from 'src/app/superadminComponents/loginComponent/super-admin/super-admin.component';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-esireport',
  templateUrl: './esireport.component.html',
  styleUrls: ['./esireport.component.scss']
})
export class EsireportComponent implements OnInit {
  isProcessing:boolean=false;
  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  userData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;

  dataExport = [];
  exported:any;
  exportingdata:any;
  storeStausCode:any
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  totalamountbeforededuction: number= 0;
  totalnetpay: number = 0;
  totalDedution: number = 0;
  period: string = "";
  employeeName: any = "";
  firstname: any;
  lastname: any;
  empnumber: any;
  empCode: boolean;
  clientaddressobj: any = {};
  dataForExcel = []; 
  base64data: string | ArrayBuffer | null = null;
  excelData: any;
  headerForExcel = [
    {'MONTH':'',  'Emp NO':'','ESI NO':'',  'Name':'',  'Working Days':'',  'ESI Wage':'', 'Total ESI':'',  'Father Name':'',
     'DOJ':'','DOB':'', 'AADHAR':'',   'UAN':'',
   }
  ];
  employeelistsub: any;

  private unsubscribe$: Subject<void> = new Subject<void>();
  orgworkingdaysList: any = [];
  orgworkingdays: any;
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  fileblob: string;
  selectedemployeeId: any;
  selectedemployee: any;
  inputValue: any;
tableShow: boolean=false;
mySelect:number=5
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router, private superAminservice :SuperadminService , private EmployeeService : EmployeeService, private http: HttpClient,
    @Inject(AppComponent) public AppData: any , private ExportExcelService  : ExportExcelService) { }

  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem('enoteUserData') );
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.userData.userId;
    this.orgId = this.userData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.userData?.employee?.client?.clientId;
    console.log(this.clientId);


    
if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });
  }
  if(this.clientId){
    this.settingsService.getFinicalyearActiveListbyClient(this.clientId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      
    });
  }


    
   
    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null,],
      period:[null,],
      client:[null,],
    })
   

    if(this.orgId){
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
  }
  if(this.clientId){
    this.payrollservice.getClientNameListbyClient(this.clientId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
  }
    

  if(this.orgId){
    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }
  if(this.clientId){
    this.settingsService.getClientdetails(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }


  if(this.orgId){
    this.superAminservice.getOrganizationById(this.orgId).subscribe(
      (resultData:any) =>{
      this.resultData = resultData;
      console.log(this.resultData);
      if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
        console.log(this.resultData.organizationAttachment.fileViewUrl);
        this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
         (blob) => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          },
          err =>{
            this.fileblob= "assets/uploads/no-image.png"
            console.log("else",this.fileblob);
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          }
        );
      }
      else{
        this.fileblob= "assets/uploads/no-image.png";
        console.log("else",this.fileblob);
        this.http.get(this.fileblob, { responseType: 'blob' })
        .subscribe(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64data = reader.result;                
              // console.log(this.base64data);
          }
          reader.readAsDataURL(res); 
          // console.log(res);
        });
      }
    }
    )
  }

  if(this.clientId){
    this.superAminservice.getClientById(this.clientId).subscribe(
      (resultData:any) =>{
      this.resultData = resultData;
      console.log(this.resultData);
      if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
        console.log(this.resultData.organizationAttachment.fileViewUrl);
        this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
         (blob) => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          },
          err =>{
            this.fileblob= "assets/uploads/no-image.png"
            console.log("else",this.fileblob);
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          }
        );
      }
      else{
        this.fileblob= "assets/uploads/no-image.png";
        console.log("else",this.fileblob);
        this.http.get(this.fileblob, { responseType: 'blob' })
        .subscribe(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64data = reader.result;                
              // console.log(this.base64data);
          }
          reader.readAsDataURL(res); 
          // console.log(res);
        });
      }
    }
    )
  }
  }

  changefirstpage(){
    this.p=1
  }
  searchSubmit()
  {

    // if((this.filterForm.value.employeecode !== undefined && this.filterForm.value.employeecode !== null && this.filterForm.value.employeecode !== '') ){
    //   if((this.employee != this.filterForm.value.employeecode ) || (this.employeeId == null)){
    //     window.alert('please select valid employee from dropdown.');
    //     return;
    //   }
    // }
    this.isProcessing=true;
    this.loader=true;
    this.noRecordsFound = false;
    console.log("submit");
    this.req=false;
    this.p = 1;
    console.log(this.filterForm.value);
    console.log("this.filterForm.value.employeecode" , this.filterForm.value.employeecode);
    console.log("this.filterForm.value.financialyear" , this.filterForm.value.financialyear);
    console.log("this.filterForm.value.period" , this.filterForm.value.period);
    // if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null ) && (this.filterForm.value.client=== 'null' || this.filterForm.value.client === null ) &&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period === 'null')){
    //   console.log("Required Field");
    //   this.resultData =[];
    //   this.noRecordsFound = false;
    //   alert("Please select  Requried fields");
    //   this.isProcessing=false;
    //   this.loader=false;
    //   // this.req=true;
    //   return
    // }

    // if((this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null'))
    //   {
    //     console.log("Required Field");
    //     this.resultData =[];
    //     this.noRecordsFound = false;
    //     alert("Please Enter Requried Fields");
    //     this.isProcessing=false;
    //     this.loader=false
  
    //     return;
    //   }
  
    //   if((this.filterForm.value.period===null|| this.filterForm.value.period ==='null'))
    //     {
    //       console.log("Required Field");
    //       this.resultData =[];
    //       this.noRecordsFound = false;
    //       alert("Please Enter Requried Fields");
    //       this.isProcessing=false;
    //       this.loader=false
    
    //       return;
    //     }
    if (
      this.filterForm.value.employeecode &&
      (this.employee !== this.filterForm.value.employeecode || this.employeeId == null)
    ) {
      window.alert('Please select a valid employee from the dropdown.');
      return;
    }
  
    this.isProcessing = true;
    this.loader = true;
    this.noRecordsFound = false;
    console.log("Submit");
    console.log("this.filterForm.value", this.filterForm.value);
  
    if (
      (!this.filterForm.value.employeecode || this.filterForm.value.employeecode === '') &&
   
      (!this.filterForm.value.financialyear || this.filterForm.value.financialyear ===null) &&
      (!this.filterForm.value.period || this.filterForm.value.period ===null)
    ) {
      console.log("Required Field");
      this.resultData = [];
      this.noRecordsFound = false;
      alert("Please select required fields");
      this.isProcessing = false;
      this.loader = false;
      return;
    }
  
    if (!this.filterForm.value.period || this.filterForm.value.period === 'null') {
      alert("Please select a financial period.");
      this.isProcessing = false;
      this.loader = false;
      return;
    }
   else if((this.filterForm.value.employeecode) && ( this.filterForm.value.financialyear) && (this.filterForm.value.period ))
    {
          
      this.getorgworkingdays();
      console.log("both");
      this.loader=true;
      this.tableShow=false;
      
      if(this.orgId){
      this.payrollservice.getEsiCallanByEmployeeIdOrg(this.employeeId,this.filterForm.value.period,this.filterForm.value.financialyear,this.orgId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.tableShow=true;
        this.isProcessing=false;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.resultData = Array.isArray(resultData) ? resultData : [resultData];

        //console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
          this.tableShow=true;
          this.resultData =[];
          console.log("no data came!!");
          

        }else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0]?.finanicalPeriod?.financialperiodMonth + " " + this.resultData[0]?.finanicalPeriod?.financialPeriodYear;
          // this.resultData =     this.resultData.filter(ele=> ele.employeeEsi != 0);
          
        }
        
        if( resultData.statusCode == 204)
        {
          console.log("no data came");
          this.resultData =[];
          this.noRecordsFound = true;
          this.tableShow=true;

        }
        this.isProcessing=false;
      })
      }

      if(this.clientId){
        this.payrollservice.getEsiCallanByEmployeeIdClient(this.employeeId,this.filterForm.value.period,this.filterForm.value.financialyear,this.clientId).subscribe((resultData:any) =>
        {
          this.loader=false;
          this.tableShow=true;
          this.isProcessing=false;
  
          this.noRecordsFound = false;
          this.resultData = resultData;
          this.resultData = Array.isArray(resultData) ? resultData : [resultData];
  
          //console.log(this.resultData);
          if(this.resultData.length == 0 || this.resultData.length == undefined)
          {
            this.noRecordsFound = true;
            this.tableShow=true;
            this.resultData =[];
            console.log("no data came!!");
            
  
          }else{
            let lastindex = this.resultData.length -1;
            this.period = this.resultData[0]?.finanicalPeriod?.financialperiodMonth + " " + this.resultData[0]?.finanicalPeriod?.financialPeriodYear;
            // this.resultData =     this.resultData.filter(ele=> ele.employeeEsi != 0);
            
          }
          
          if( resultData.statusCode == 204)
          {
            console.log("no data came");
            this.resultData =[];
            this.noRecordsFound = true;
            this.tableShow=true;
  
          }
          this.isProcessing=false;
        })
        }
    }
    
    else if((!this.filterForm.value.employeecode) && (this.filterForm.value.financialyear) && (this.filterForm.value.period  ) )
    {
      this.loader = true;
  this.tableShow = false;

  this.getorgworkingdays();
  console.log("financial and period");

  if (this.orgId) {
    this.loader = true;
    this.tableShow = false;

    this.payrollservice.getEsiCallanReportByorgYear(this.orgId,this.filterForm.value.financialyear, this.filterForm.value.period).subscribe(
      (resultData: any) => {
        this.noRecordsFound = false;
        this.loader = false;
        this.tableShow = true;
        this.isProcessing = false;

        // Ensure resultData is an array
        this.resultData = Array.isArray(resultData) ? resultData : [resultData];

        if (!this.resultData || this.resultData.length === 0) {
          this.noRecordsFound = true;
          this.loader = false;
          this.tableShow = true;
          this.resultData =[];
        } else {
          let firstRecord = this.resultData[0];
          this.period = `${firstRecord?.finanicalPeriod?.financialperiodMonth || ""} ${firstRecord?.finanicalPeriod?.financialPeriodYear || ""}`;
        }

        if (this.resultData[0]?.statusCode === 204) {
          this.noRecordsFound = true;
          this.loader = false;
          this.tableShow = true;
        }

        this.isProcessing = false;
      },
      (error) => {
        this.noRecordsFound = true;
        this.loader = false;
        this.tableShow = true;
        this.isProcessing = false;
      }
    );
  }

  if (this.clientId) {
    this.loader = true;
    this.tableShow = false;

    this.payrollservice.getEsiCallanReportByClientYear(this.clientId,this.filterForm.value.financialyear, this.filterForm.value.period).subscribe(
      (resultData: any) => {
        console.log("with pusing",resultData)
        this.noRecordsFound = false;
        this.loader = false;
        this.tableShow = true;
        this.isProcessing = false;

        // Ensure resultData is an array
        this.resultData = Array.isArray(resultData) ? resultData : [resultData];
console.log("resultdata:",resultData)
        if (!this.resultData || this.resultData.length === 0) {
          console.log("length came here!!!")
          this.noRecordsFound = true;
          this.loader = false;
          this.tableShow = true;
          this.resultData =[];
        } else {
          let firstRecord = this.resultData[0];
          this.period = `${firstRecord?.finanicalPeriod?.financialperiodMonth || ""} ${firstRecord?.finanicalPeriod?.financialPeriodYear || ""}`;
        }
        this.resultData.forEach((respo:any)=>{
          this.storeStausCode=respo.statusCode
          console.log("storeStausCode:",this.storeStausCode)
        })

        if (this.resultData.message =='No Data') {
          console.log("no data entered!!!")
          this.noRecordsFound = true;
          this.loader = false;
          this.tableShow = true;
          this.resultData =[];
        }

        this.isProcessing = false;
      },
      (error) => {
        console.log("erroe entered!!")
        this.noRecordsFound = true;
        this.loader = false;
        this.tableShow = true;
        this.isProcessing = false;
      }
    );
  }
    }
    
    this.clientList?.forEach(element => {
      console.log("foreach")
      if(element.clientId == this.filterForm.value.client)
      {
        console.log(element)
         this.clientaddressobj = element;
      }
   });
  }

  clearTable()
  {
    this.resultData.length = 0;
    this.noRecordsFound=false;
    this.empList =[];
    this.empCode=false;
  }

  // empSearch(event)
  // { 
  //   this.empList=[];
  //   this.empCode=false;
  //   console.log(event.target.value);
  //   this.userId=event.target.value;
  //   console.log(this.userId);
  //   // Employee List Api
  //     // this.empList.length == 0;
  //     if(event.target.value.length >= 2)
  //     {
  //       if(this.employeelistsub)
  //       {
  //         this.employeelistsub.unsubscribe();
  //       }
  //       this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
  //         .pipe(
  //           takeUntil(this.unsubscribe$), // Cancel previous request
  //           switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
  //         )
  //         .subscribe((result: any) => {
  //           this.empList.length = 0;
  
  //           if (result.statusCode) {
  //             console.log(result.statusCode);
  //             this.empList.length = 0;
  //           } else {
  //             this.empList = result;
  //           }
  //         });
  //   }
  // }

  empSearch(event){
    console.log(event.target.value)
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement) // Cast the event target as an input element
  this. inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
   console.log(this.inputValue)
    // Clear the employee list and reset flags if input is empty
    if (this.inputValue === '') {
      this.empList = [];
      this.empCode = false;
      console.log('Input is empty, employee list cleared');
      return;
    }
  
    // Check if input length is sufficient for the search
    if (this.inputValue.length >= 2) {
      // Unsubscribe from the previous API request if necessary
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
  
      // Reset and create a new Subject for cancellation
      this.unsubscribe$ = new Subject<void>();


      if(this.orgId){
      this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }

      if(this.clientId){
        this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true; // Set the flag to indicate an error state
              }
              console.error('An error occurred:', err);
              return EMPTY; // Return EMPTY to propagate completion downstream
            })
          )
          .subscribe(
            (result: any) => {
              if (result.statusCode === 204) {
                console.log('No employees found (status code 204)');
                this.empList = [];
                this.empCode = true; // Indicate no results found
              } else if (result.statusCode) {
                console.log('Other status code:', result.statusCode);
                this.empList = [];
                this.empCode = true;
              } else {
                // Handle the case where result is an array or an object
                this.empList = Array.isArray(result) ? result : Object.values(result);
                this.empCode = false;
              }
            },
            (err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true;
              }
              console.error('An error occurred:', err);
            }
          );
        }
    }
  }
   
  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log(emp);
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;

  
   
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;

    console.log(this.employee);
    
      this.filterForm.patchValue({
        employeecode: this.employee
     });
  
    // }
  
  }

  roundofdata()
  {
    this.resultData.map(  (e) => {
      e.payrollLine.map((payelement)=> {
        payelement.amount = Math.round(payelement.amount);
      }) 
   })

  }
  
  pageChages(){
    console.log("page changes");
    this.p=1;
  }
  // selectFinancialyear(){
  //   console.log("Select");
  //   // console.log(event.target.value);
  //   this.filterForm.get('financialyear').value;
  //   // console.log(this.FilterForm.value.financialyear);
  //    this.chooseFinId=this.filterForm.get('financialyear').value;
  //   console.log(this.chooseFinId);
    
  //   if(this.chooseFinId== 'null')
  //   {
  //     this.filterForm.patchValue({
      
  //       financialyear:null,
       
  //     })
  //   }else{
  //   this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
  //     console.log("PeriodData", resultData)
  //     this.finPeriodList = resultData.finanicalPeriod;
     
  //   });
  // }
  // }
  selectFinancialyear() {
    console.log("Select");
  
    this.chooseFinId = this.filterForm.get('financialyear')?.value;
    console.log(this.chooseFinId);
  
    // Reset period to null when a different financial year is selected
    this.filterForm.patchValue({
      period: null,
    });
  
    if (this.chooseFinId == null || this.chooseFinId === 'null') {
      this.filterForm.patchValue({
        financialyear: null,
      });
      this.finPeriodList = []; // Clear the period list
    } else {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData);
        this.finPeriodList = resultData.finanicalPeriod;
      });
    }
  }
  selectFinancialfromPeriod(event)
  {
    if(event.target.value == 'null')
    {
      this.filterForm.patchValue({
      
        fromperiod:null,
      
      })
  }
}
selectFinancialtoPeriod(event)
{
  if(event.target.value == 'null')
  {
    this.filterForm.patchValue({
    
      toperiod:null,
    
    })
}
}

calucatenetpay(earnings,deductions)
{
   let netpay = earnings - deductions;

  return  netpay?.toFixed(2);
}
 
  public downloadAsExcel(){
      let clientcode = "" ;
    this.dataForExcel.length = 0;
    let totaldaysofwages = 0;
    let totalOtHours = 0;
    this.excelData=this.resultData;
    console.log(this.excelData);
    for(let e of this.excelData){
      let data= {
        
          period : e?.finanicalPeriod?.financialperiodMonth + "-"+e?.finanicalPeriod?.financialPeriodYear,                    
          employeeNumber :   e.employee?.employeeNumber,
          esiNumber :  e.employee?.esiNumber ? e.employee?.esiNumber : "" ,
          firstName :  e.employee?.firstName,
          workingDays : this.getempworkingdays(e), 
          esiwage :   e.totalAmount?.toFixed(2),
          employeeEsi :  e.employeeEsi?.toFixed(2),
          lastName:    e.employee?.lastName,
          dateOfJoining :   e.employee?.dateOfJoining,
          dateOfBirth :    e.employee?.dateOfBirth,
          aadhaarNumber :    e.employee?.aadhaarNumber ? e.employee?.aadhaarNumber : "",
          uanNumber :   e.employee?.uanNumber? e.employee?.uanNumber : ""
        }
     

       

      //  let netpay = Math.round(e.totalEarnings) -  Math.round(e.totalDeduction) ;
      //   data["Dedecutions"]  =  Math.round(e.totalDeduction) ;

      

     
      console.log("table data",data);
      this.dataForExcel.push(Object.values(data));
      console.log(this.dataForExcel);
    }
   
   

 
   
   
    let reportDatabyrecruiter = {
      title: 'ESI Challan',
       exceldate: "Period : " +  this.period,
      data: this.dataForExcel,
      employeeName :this.employeeName,
      headers: Object.keys(this.headerForExcel[0]),
    }
    console.log(reportDatabyrecruiter);
    this.ExportExcelService.exportExcel(reportDatabyrecruiter);
  }

  getorgworkingdays()
  {
    if(this.orgId){
    this.payrollservice.getorgFinanicalandPeriodList(this.filterForm.value.period ,this.orgId).subscribe((filterData:any) =>
    {
      // this.noRecordsFound = false;
      this.orgworkingdaysList = filterData;
//       if ((this.orgworkingdaysList.length === 0 || this.orgworkingdaysList === undefined) && 
//     (this.resultData.length === 0 || this.resultData === undefined)) {
//     this.noRecordsFound=true;
// }

       if( this.orgworkingdaysList.length !=  undefined)
      {
            this.orgworkingdays = this.orgworkingdaysList[0].workingDays;
      }
      console.log(this.orgworkingdays);
      
    })
  }
  if(this.clientId){
    this.payrollservice.getClientFinanicalandPeriodList(this.filterForm.value.period ,this.clientId).subscribe((filterData:any) =>
    {
      // this.noRecordsFound = false;
      this.orgworkingdaysList = filterData;
//       if ((this.orgworkingdaysList.length === 0 || this.orgworkingdaysList === undefined) && 
//     (this.resultData.length === 0 || this.resultData === undefined)) {
//     this.noRecordsFound=true;
// }

       if( this.orgworkingdaysList.length !=  undefined)
      {
            this.orgworkingdays = this.orgworkingdaysList[0].workingDays;
      }
      console.log(this.orgworkingdays);
      
    })
  }
  }

  getempworkingdays(element)
  {
     if(this.orgworkingdays && element.employeeLeave)
     {
        return(this.orgworkingdays - element.employeeLeave.leaveDays);
     }else if (this.orgworkingdays && !element.employeeLeave ){

        return this.orgworkingdays
     }
  }

  printPdf()
  {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { 
      content: html ,
      pageMargins: [ 25, 40, 25, 40 ],
      info: {
        title:`ESI Report`
        },
        background: function (currentPage, pageCount) {
          console.log('currentPage:', currentPage);
          console.log('pageCount:', pageCount);
      
          return {
              text: 'Page ' + currentPage.toString(),
              alignment: 'center',
              margin: [0, 8],
              fontSize: 10,
          };
         },
        pageSize: 'A4',
        pageOrientation : 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download("ESI Report"); 
  }

  Roundof(amount){
    return Math.round(amount);
  }
  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 

  }

}
