<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Withdraw List</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<nav class="navbar  bg" style="margin-top: 67px;">
  <a class="navbar-brand navDesign" style="font-size: 22px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;">Withdraw List</a>
  
  <div class="d-flex">
  <input
        class="form-control me-2"
        type="text"
        placeholder="Search..."
        [(ngModel)]="searchs"
         (input)="validateInput($event)"
         (keyup)="changepage();"
                name="search"
        aria-label="Search"
        style="
          width: 250px;
          height: 38px;
          flex-shrink: 0;
          border-radius: 5px;
          background: #fff;position: relative;right: 20px;
        "
      />
      <button class="btn" type="submit" [routerLink]="['/home/leave-Detail']" style="    background-color: #1a83ff;
      color: whitesmoke;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 610;
      font-family: 'Mulish', sans-serif;
      position: relative;
      /* float: left; */
      right: 10px;">Back</button>
    </div>
 
</nav>
<div class="common-content">
  <div class="card-new">
    <div class="card colDesign" >
      <div class="card-body" style="font-family: Mulish;"  >
        <!-- <div class="container-fluid">
          <div class="row">
    <div class="col-md-8 d-flex align-items-center">
        <div class="col-md-4"> -->
            <!-- <input class="form-control search-input form-control-lg form-control-solid" type="text" placeholder="Search.." (keyup)="applyFilter($event)"           (input)="validateInput($event)"
            name="search"> -->
        <!-- </div>
        <div class="col-xl-4 col-lg-4"> -->
            <!-- <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn" style="font-family: Mulish;">Search</button> -->
        <!-- </div>
    </div> -->
    <!-- <div class="col-md-4 d-flex justify-content-end align-items-center"> -->
        <!-- <button mat-stroked-button class="apply-button" [routerLink]="['/home/leave-Detail']" style="margin: 10px 0px; font-family: Mulish;">
            <mat-icon class="material-icons-outlined apply-button-icon">keyboard_arrow_left</mat-icon> Back
        </button> -->
    <!-- </div>
</div> -->

       
        <!-- </div> -->

        <form [formGroup]="leaveReport" class="--container-fluid">
          <div class="row">
              <div class="col-xl-3 col-md-4">
                  <div class="form-group">
                      <label class="form-control-label">Search Employee</label>
                      <!-- <input type="text" (keyup)="keyFunc($event)" value="" placeholder="Search..."
                          formControlName="employeeId" [matAutocomplete]="temName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off"           (input)="validateInput($event)"
                          > -->
                      <input type="text" (keyup)="keyFunc($event,$event)" value="" placeholder="Search..."
                          formControlName="employeeId" [matAutocomplete]="temName"
                          class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;"
                          autocomplete="off" (input)="validateInputs($event)"
                          (keydown)="validateInputs($event)" (keyup)="validateInputs($event)"
                          (keydown)="handleBackspace($event)"
                          (keydown.space)="preventSpaceBeforeInput($event)">

                      <small class="text-muted">Note : Emp No., Employee Name.</small>
                      <mat-autocomplete #temName="matAutocomplete">
                          <mat-option *ngFor="let searchData of employeeList" [value]="searchData.employeeId"
                              (click)="empSelect(searchData)">
                              {{searchData.employeeNumber}} - {{searchData.firstName 
                            }}
                          </mat-option>
                          <mat-option [value]="null" *ngIf="employeeNotFound" (click)="noEmployeeFound()">No
                              Employee Found</mat-option>
                      </mat-autocomplete>
                      <!-- <mat-autocomplete #temName="matAutocomplete">
                              <mat-option *ngFor="let searchData of employeelist" [value]="searchData.employeeId" (click)="empSelect(searchData)">
                                  {{searchData.employeeNumber}} - {{searchData.firstName +' '+ searchData.lastName}}
                              </mat-option>
                          </mat-autocomplete> -->
                      <mat-error class="error_msg"
                          *ngIf="submitted && leaveReport.get('employeeId').hasError('required')">Please
                          select employee</mat-error>

                      <!-- <mat-select
                          placeholder="Select Employee" id="selvalue" formControlName="employeeId" 
                          (selectionChange) = "isselected($event)">
                          <mat-option value="0">--  Select Employee -- </mat-option>
                          <mat-option *ngFor="let employee of employeelist" [value]="employee.employeeId">
                              {{employee.employeeName}}
                          </mat-option>
                          </mat-select> -->
                  </div>
              </div>
              <div class="col-xl-3 col-md-4 col-sm-6 col-12 col-xs-3 " style="bottom: 5px;">
                  <div class="form-group">
                      <label class="form-control-label">Start Date</label>
                      <mat-datepicker-toggle matSuffix [for]="picker1"
                          style="float: right;position: relative;top: 33px;"></mat-datepicker-toggle>
                      <input formControlName="startdate" placeholder="Choose Start Date"
                          [matDatepicker]="picker1" (dateChange)="startDate($event);
                               checkStartDate()" readonly
                   class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;margin-top: -4%;">
                      <mat-datepicker #picker1></mat-datepicker>
                  </div>
                  <mat-error class="error_msg" *ngIf="startdate_required">Please select start date</mat-error>
              </div>
              <div class="col-xl-3 col-md-4 col-sm-6 col-xs-3 col-12" style="bottom:5px">
                  <div class="form-group">
                      <label class="form-control-label">End Date</label>
                      <mat-datepicker-toggle matSuffix [for]="picker2"
                          style="float: right;position: relative;top: 33px;"></mat-datepicker-toggle>
                      <input formControlName="enddate" placeholder="Choose End Date" [matDatepicker]="picker2"
                          (dateChange)="EndDate($event);
                              checkStartDate()" [min]="min" readonly
                       class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;margin-top: -4%;">
                      <mat-datepicker #picker2></mat-datepicker>
                  </div>
                  <mat-error class="error_msg" *ngIf="enddate_required">Please select end date</mat-error>
                  <!-- <mat-error class="error_msg" *ngIf="show_EMsg">End date should be greater than start date</mat-error> -->
              </div>
              <div class="col-xl-3 col-md-4 but max-height">
                  <!-- <button mat-raised-button type="button" class="" (click)="search()"  [disabled]="isProcessing" 
                      style="margin: 8px 5px 0px 0px;background: #1A83FF;border: 1px solid #1A83FF;position: relative;
                      top: 43%; font-family: Mulish; font-size: 16px; font-weight: 610;">Search</button> -->
                  <button type="button" mat-raised-button
                      class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                     (click)="search()"  [disabled]="isProcessing">
                      Search
                  </button>
                  <button mat-stroked-button type="reset" class="apply-buttons" (click)="Clear()"
                      [disabled]="isProcessing"
                      style="margin: 8px 0px 0px 5px;background:white;border: 1px solid #ddd;position: relative ;
                      top: 26%;  font-family: Mulish; color: black; font-size: 16px; font-weight: 610;">Clear</button>
              </div>
          </div>
          <div>
              <mat-error class="error_msg" *ngIf="all_null">Choose valid employee.</mat-error>

          </div>
      </form>

        <div class="card resource-card" style="border: 0px solid rgba(0,0,0,.125);padding: 5px 5px;" *ngIf="list_count > 5 && (data |searchFilter :searchs).length >5">
          <div class="row rowDesign">
              <div class="col-xl-12 col-lg-12">
                  <div class="select-pagination">
                      <span id="showDesign">Show : </span>
                      <select class="select_list new-select_list" id="listDesign" [(ngModel)]="mySelect" (change)="showChanges()">
                          <option class="form-control" value="5">5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                      </select>
                  </div>
              </div>
          </div>
      </div>

        <!-- <ng-container>
          <div class=" table-responsive mt-1">
            <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count>0 && !loader">
              <ng-container matColumnDef="employeeNumber">
                <th mat-header-cell *matHeaderCellDef > <b>Emp&nbsp;No</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.employee.employeeNumber}}</td>
              </ng-container>
        
              <ng-container matColumnDef="employeeName">
                <th mat-header-cell *matHeaderCellDef > <b>Emp&nbsp;Name</b> </th>
                <td mat-cell *matCellDef="let element">  {{element.employee.firstName | titlecase}} {{element.employee.lastName | titlecase}} </td>
              </ng-container>
        
              <ng-container matColumnDef="leaveType">
                <th mat-header-cell *matHeaderCellDef > <b>Leave&nbsp;Type</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.leaveType.leaveName}} </td>
              </ng-container>
        
              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef> <b>From&nbsp;Date</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.leaveWithdraw.startDate | date}} </td>
              </ng-container>
        
              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef> <b>To&nbsp;Date</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.leaveWithdraw.endDate | date}} </td>
              </ng-container>
        
              <ng-container matColumnDef="withdrawDays">
                <th mat-header-cell *matHeaderCellDef class="text-center"> <b>No.of&nbsp;Days</b> </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.leaveWithdraw.withdrawDays}} </td>
              </ng-container>
        
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> <b>Status</b> </th>
                <td mat-cell *matCellDef="let element" [style.color]="withDrawStatus(element)">
                  {{element.leaveWithdraw.withdrawStatus}} </td>
              </ng-container>
        
              <ng-container matColumnDef="star" >
                <th style="font-weight: bolder;" mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <a mat-menu-item (click)="show_leave(element)">
                      <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#5E06D0">menu</mat-icon>
                      <span class="cancel-trip-text" style="color:#5E06D0">Show Leave List</span>
                    </a>
                    <a mat-menu-item (click)="Reason(element)">
                      <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#20b2aa">rate_review</mat-icon>
                      <span class="cancel-trip-text" style="color:#20b2aa">WithDraw Reason</span>
                    </a>
                  </mat-menu>
                </td>
              </ng-container>
        
              <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="container" style="padding-top:15px;" *ngIf="noData"> 
              <div class="alert alert-primary" align="center" style="width: 100%">
                No Withdraw list
              </div>
            </div>
          </div>
        </ng-container> -->
        <div class="--container-fluid" style="overflow: auto;">
          <div class="table-responsive" *ngIf="showTable && !loader" style="border-top-left-radius: 10px; border-top-right-radius: 10px; margin-top: 15px;">
              <table class="table --table-bordered table-border-new" matSort style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                  <thead class="t-head" style="background-color: #f6f9fc;">
                      <tr>
                          <th class="text-center">Emp No</th>
                          <th class="text-left">Emp Name</th>
                          <th class="text-left">Leave Type</th>
                          <th class="text-center">From Date</th>
                          <th class="text-center">To Date</th>
                          <th class="text-center">No.of Days</th>
                          <th class="text-center">Status</th>
                          <th class="text-center">Action</th>
                      </tr>
                  </thead>
                  <tbody class="t-body">
                      <tr *ngFor="let element of data |searchFilter: searchs | paginate: { itemsPerPage: mySelect, currentPage: p }">
                          <td class="text-center">{{element.employee.employeeNumber}}</td>
                          <td class="text-left">{{element.employee.firstName | titlecase}} {{element.employee.lastName | titlecase}}</td>
                          <td class="text-left">{{element.leaveType.leaveName}}</td>
                          <td class="text-center">{{element.leaveWithdraw.startDate |date:'dd/MM/yyyy'}}</td>
                          <td class="text-center">{{element.leaveWithdraw.endDate |date:'dd/MM/yyyy'}}</td>
                          <td class="text-center">{{element.leaveWithdraw.withdrawDays}}</td>
                          <td class="text-center" [style.color]="withDrawStatus(element)">{{element.leaveWithdraw.withdrawStatusName}}</td>
                          <td class="text-center">
                              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                  <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                              </button>
                              <mat-menu #menu="matMenu">
                                  <a mat-menu-item (click)="show_leave(element)">
                                      <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#5E06D0">menu</mat-icon>
                                      <span class="cancel-trip-text" style="color:#5E06D0">Show Leave List</span>
                                  </a>
                                  <a mat-menu-item (click)="Reason(element)">
                                      <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#20b2aa">rate_review</mat-icon>
                                      <span class="cancel-trip-text" style="color:#20b2aa">WithDraw Reason</span>
                                  </a>
                              </mat-menu>
                          </td>
                      </tr>
                      <tr>
                        <td
                        style="
                        text-align: center;
                        font-size: larger;
                        font-weight: bold;
                      "
                      colspan="9"
                      *ngIf="noData || ((data |searchFilter :searchs).length === 0 && searchs) ||
                               data.length == 0">
            No Withdraw List                                   </td>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>

      <div *ngIf="list_count > 5 && (data |searchFilter :searchs).length >5">
          <pagination-controls class="float-right pagination" style="float: right;" (pageChange)="p = $event; beforepage = $event"></pagination-controls>
      </div>
      <!-- <div *ngIf="noData">
          <div class="alert alert-primary" style="width: 100%; text-align: center;">No Leave List</div>
      </div> -->
        <!-- <div [hidden]="loader">
          <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
          </mat-paginator>
      </div> -->
      </div>
    </div>
  </div>
</div>

  
<div *ngIf="loader" class="d-flex align-items-center justify-content-center" style="margin-top: 15%;" >
  <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;left:48%;top:50%" role="status">
      <span class="sr-only">Loading...</span>
  </div>