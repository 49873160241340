import { Component, HostListener, Inject, OnInit, ViewChild,ElementRef  } from '@angular/core';
import { CreatemomComponent } from '../createmom/createmom.component';
import { Sort } from '@angular/material/sort';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
// import { UpdatemomComponent } from '../../../app/project-management/updatemom/updatemom.component';
import { HeaderService } from '../../../../app/providers/header.service';
import { DateAdapter } from 'chart.js';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TimesheetService } from '../../../../app/providers/timesheet.service';
import { AssignEmpService } from '../../../../app/providers/assign-emp.service';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-momreports',
  templateUrl: './momreports.component.html',
  styleUrls: ['./momreports.component.scss'],
})
export class MomreportsComponent implements OnInit {
  prjct_Ids: number[] = [];
  momList: any = [];
  mombyproject: any = [];
  projectId: number;
  projectList: any = [];
  projectStatus: any;
  startDate: any;
  mySelect: any = 5;
  endDate: any;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  // minDate = new Date(2000, 0, 1);
  // maxDate = new Date(2020, 0, 1);
  // p:any;
  p: any;
  endSelectedFirst: boolean;

  loader:boolean = false;
  User_Type: any;
  noData = false;
  // nodata: boolean = false;
  beforesearchpage: any;
  search: any;
  errorMsg = false;
  today: any;
  projectIds: any;
  response: any;
  datas: any[] = [];
  originalData: any[] = [];
  storeorg: any;
  storeId: any;
  organizationName:any;
  orgAddress:any;
  noDataFound:boolean=false;
  isbuttondisable:boolean=false;
  maxEndDate: Date;
tableShow: boolean=false;
  // loader: boolean=false;

  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;

  constructor(
    private gs: ProjectManagementService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private headerservice:HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
    
  ) {}
  
  ngOnInit() {
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage(
      ''
    );

    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    // this.headerservice.setTitle('MOM Reports');
    this.noData = false;
    this.today = new Date();
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');
    // const myData = JSON.parse(localStorage.getItem('MOMdata'))
    const myData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('myData::', myData);
    console.log('this.data::', this.data);
    this.storeorg = myData.organization.organizationId;
    console.log('this.stroreorgId', this.storeorg);

    this.organizationName=  myData.organization.organizationName;
    this.orgAddress=  myData.organization.address;

    this.originalData = this.data.slice();

    this.gs
      .getResourceByIds(myData.organization.organizationId)
      .subscribe((x: any) => {
        // this.User_Type = x.userType.userType;
        // console.log('this.User_Type values::', this.User_Type);
      });
    // this.gs.get_projectsByUserId(myData.employeeId).subscribe(
    //   (x:any)=>{
    //     this.projectList = x
    //   }
    // )
    this.gs
      .getEmpProjectList(myData.organization.organizationId)
      .subscribe((pa: any) => {
        this.projectList = pa;
        this.storeId = pa.projectId;
        console.log('response project original::', this.projectList);
        console.log('this.storeId::', this.storeId);

        this.projectList.forEach((project: any) => {
          if (project.projectId) {
            this.prjct_Ids.push(project.projectId);
          }
        });
        console.log('project_ids::', this.prjct_Ids);
        // this.projectIds=project.projectId
        // console.log("project-Lists::",this.projectIds);

        // console.log("pr list::",this.projectList);
        // console.log("project-Lists::",this.projectIds);
      });

    this.storeOriginalData();
  }
  storeOriginalData() {
    this.originalData = JSON.parse(JSON.stringify(this.data)); // Deep copy the original data
  }

  // dateChange(event:MatDatepickerInputEvent<Date>) {
  //   this.maxEndDate = event.value;
    
  //   // Reset end date if it's before the new start date
  //   // if (this.endDate < this.startDate) {
  //   //   // this.endDate = null;
  //   //   alert("Start date should be greater than the End Date!!")
  //   //   this.endDate=null
  //   //   this.startDate=null
  //   // }
  // }
  
  checkStartDate(): void {
  
  }
  // dateChange(event) {
  //   console.log(event.value);
  //   // this.enddate_required = false;
  //   this.startdate_required = false;
  // }
  // dateChange1(event) {
  //   console.log(event.value);
  //   // this.enddate_required = false;
  //   // this.startdate_required = false;
  //   this.errorMsg = false;
  // }
  dateChange(event) {
    console.log(event.value);
    // this.enddate_required = false;
    // this.startdate_required = false;
    this.errorMsg=false;
  }
  resetForms() {
    this.projectStatus = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    // this.momList.length =[];
    this.momList = [];

    this.noData=false
    this.isbuttondisable=false;
    this.tableShow =  false;
    // this.search=''
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    // if (event.value) {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.remove('black-placeholder');
    // } else {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }

    if (this.endSelectedFirst && this.startDate > this.endDate) {
      // If end date was selected first and start date is greater than end date
      // Set start date to be one day before end date
      this.startDate = new Date(this.endDate);
      this.startDate.setDate(this.endDate.getDate() - 1);
    }

    // Check if start date is defined and update flag accordingly
    if (this.startDate !== undefined) {
      this.startdate_required = false;
    }

    // Check if end date is undefined and update flag accordingly
    if (this.endDate === undefined) {
      this.enddate_required = true;
    }

    if (this.startDate == undefined && this.endDate == undefined) {
      this.startdate_required = false;
      this.enddate_required = false;
    }
    // Check if start date is greater than end date and update flag accordingly
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.startDate = undefined;
      this.endDate = undefined;

      this.startDate = '';
      this.endDate = '';
      this.startdate_required = false;
      this.enddate_required = false;
      alert('Start date cannot be greater than end date.');
    }
  }
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if the clicked element is not the search input
  //   if (!(event.target as HTMLElement).closest('.form-control')) {
  //     this.search = ''; // Clear the search term
  //   }
  // }


  searchTask() {
    this.search=''
    this.isbuttondisable = false;

    //   if (!this.endDate) {
    //   // Alert the user that end date is required
    //   alert('End date is required.');
    // } 
    // else if (this.startDate > this.endDate) {
    //   // Alert the user that start date should be greater than end date
    //   alert('Start date should be greater than end date.');
    // } else {
    //   // Proceed with your logic
    // }
    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');
    console.log(
      'check thisss::',
      this.projectStatus == 'null' ||
        this.startDate == undefined ||
        this.endDate == undefined
    );

    // if(this.projectStatus == "null" || this.startDate == undefined || this.endDate == undefined){
    //   alert("Please select the required Items")
    //   return
    // }
    this.isbuttondisable = false;

    if(this.projectStatus==undefined && this.startDate==undefined && this.endDate==undefined){
      this.isbuttondisable = false;

   alert("Please Choose any of the Element to Search")
   this.isbuttondisable = false;
  //  this.projectStatus=undefined
  //  this.startDate=undefined
  //  this.endDate=undefined
return
    }

   else if (this.startDate > this.endDate) {
    this.isbuttondisable = false;

      alert('Start date should not be greater than End date');
      // this.projectStatus=undefined
      this.startDate=undefined
      this.endDate=undefined
      return;
    }
//     else if(this.startDate<this.endDate){
//       alert("Start date should be greather than end date!!")
   
//        this.startDate=undefined
//        this.endDate=undefined
// //        this.taskStatusId=undefined
// // this.selectedetaskGroupId=undefined

// return
//     }
    else if(this.endDate==undefined && this.startDate!=undefined){
      // this.isbuttondisable = false;

      alert("end is required!!")
      this.isbuttondisable = false;
  //     this.projectStatus=undefined
  //  this.startDate=undefined
  //  this.endDate=undefined
      return

    }
    else if(this.startDate==undefined && this.endDate!=undefined){
      // this.isbuttondisable = false;
      alert("start date is required!!")
      this.isbuttondisable = false;
      // this.projectStatus=undefined
      // this.startDate=undefined
      // this.endDate=undefined
return

    }
      else{
        console.log("else !...");
      }
    
    this.momList = [];
    this.tableShow=false
    console.log('check wtts::', this.prjct_Ids);
    console.log(
      'wts cndtion true::',
      this.startDate,
      this.endDate,
      this.projectStatus
    );
    console.log("this.projectStatus",this.projectStatus);
    

    if (this.startDate && this.endDate && this.projectStatus) {
      this.isbuttondisable=true
      console.log(this.startDate, this.endDate, this.projectStatus);
this.loader = true;
      this.gs
        .get_momByProject(this.startDate, this.endDate, this.projectStatus)
        .subscribe(
          (data: any) => {
            this.noData = false;
            this.loader = false;
            this.momList = Array.isArray(data) ? data : [];
            // this.momList = data;
            this.tableShow=true

            this.isbuttondisable=false
            this.loader=false

            // this.response=data
            // console.log("response mom list::",this.response);
            console.log('response mom Lists::', this.momList);
            if(data.message==='No Data is Available'){

              this.noData = true;
              this.tableShow=true

              this.isbuttondisable=false
              this.loader=false

            }
            this.isbuttondisable=false

          },
          (error) => {
            this.loader = false;
            this.tableShow=true

            this.noData = true;
            this.isbuttondisable=false

            console.log('No Data');
          }
        );
    } 
    else if (this.startDate && this.endDate && !this.projectStatus) {
      this.isbuttondisable=true
      this.loader = true;
      this.gs
        .getStartEndProjectList(this.startDate, this.endDate, this.storeorg)
        .subscribe(
          (response) => {
            console.log(response);

            this.noData = false;
            this.tableShow=true

            this.loader = false;
            this.momList = Array.isArray(response) ? response : [];
            // this.momList = response;
            this.isbuttondisable=false
            this.loader=false

            console.log('this.actionItemList response Dates::');
            if(this.momList.message=='No Data is Available'){
              this.noData = true;
              this.isbuttondisable=false
              this.tableShow=true

              this.loader=false     
               }
            this.isbuttondisable=false

          },
          (error) => {
            this.noData = true;
            this.tableShow=true

            this.loader=false
            this.isbuttondisable=false

            console.log('No Data');
          }
        );
    }
     else if (
      this.projectStatus !== undefined &&
      !this.startDate &&
      !this.endDate
    ) {
      this.isbuttondisable=true
      this.loader = true;
      if (this.projectStatus == 'non-project') {

        this.gs.nonProjectSelection().subscribe(
          (response:any) => {
            console.log('response::', response);
            this.noData = false;
            this.loader = false;
            this.tableShow=true

            // this.momList = response;
            this.momList = Array.isArray(response) ? response : [];
            this.isbuttondisable=false
            this.loader=false

            // console.log('this.actionItemList response projects ::');
            if(response.message=='No Data is Available'){
              this.noData=true
              console.log("no data");
              this.isbuttondisable=false    
              this.loader=false  
              this.tableShow=true
        
            }
            this.isbuttondisable=false

          },

          (error) => {
            this.noData = true;
            this.loader=false
            this.tableShow=true

            this.isbuttondisable=false

            console.log('No Data');
          }
        );
      } else {
        this.isbuttondisable=true
        this.loader = true;
        this.gs.onlyProjectSelection(this.projectStatus).subscribe(
         
          (response:any) => {
            console.log('response', response);
            this.noData = false;
            this.loader = false;
            // this.momList = response;
            this.momList = Array.isArray(response) ? response : [];
            this.tableShow=true

            this.isbuttondisable=false
            this.loader=false
            if(response.message=='No Data is Available'){
              this.noData=true
              console.log("no data");
              this.isbuttondisable=false  
              this.loader=false            
              this.tableShow=true

            }
            this.isbuttondisable=false              

            console.log('this.actionItemList response projects ::');
          },
          (error) => {
            this.loader=false
            this.noData = true;
            this.tableShow=true

            console.log('No Data');
            this.isbuttondisable=false  

          }
        );
      }
    } 
    else if(this.startDate!==undefined && this.endDate!==undefined && this.projectStatus==undefined){
      this.isbuttondisable=true
      this.loader=true

      console.log("only date!....");
      this.loader = true;
      this.gs.OnlyDateSelection(this.startDate,this.endDate).subscribe((response)=>{
        this.noData=false
        this.loader = false;
        this.tableShow=true

        // this.momList=response
        this.momList = Array.isArray(response) ? response : [];
        this.isbuttondisable=true
        this.loader=false

        console.log("this.momlist::",this.momList);
        if( this.momList.message==='No Data is Available'){
          this.noData = true;
          this.isbuttondisable=false
          this.tableShow=true

          this.loader=false

        }
        this.isbuttondisable=false              

      },
      (err:any)=>{
        this.noData=true
        this.isbuttondisable=false  
        this.tableShow=true
           

        console.log("no data");
        
      }
      )
    }
    else {
      // this.noData = true;
      console.log("else all condition !...");
      
    }
    this. showChanges()
  }



  update() {
    this.router.navigate(['/home/mom-creation']);
  }
  createMOM() {
    this.router.navigate(['/home/mom-creation']);
    // const dialogRef = this.dialog.open(CreateMOMComponent,{
    //   data:'',
    //   height:'600px',
    //   width:'800px',
    //   disableClose:true
    // })
  }
  showChanges() {
    console.log('show Change');
    this.p = 1;
  }
  // filterByValue() {
  //   if (this.search && this.search.length !== 0) {
  //     this.p = 1;
  //     this.datas = this.datas.filter((o) =>
  //       Object.keys(o).some(
  //         (k) =>
  //           String(o[k])
  //             .toLowerCase()
  //             .includes(this.search.toLowerCase())
  //       )
  //     );
  //   } else {
  //     this.p = this.beforesearchpage;
  //     this.datas = [...this.data]; // Reset to original data when search is cleared
  //   }
  // }

  // changepage() {
  //   console.log(this.search);
  //   console.log(this.momList.length);
  //   if (this.search.length != 0) {
  //     this.p = 1;
  //     console.log(this.momList.length);

  //     console.log(this.p);
  //     console.log('success Data');
  //   } else {
  //     this.p = this.beforesearchpage;
  //     this.noData = true;
  //     console.log(this.momList.length);
  //     console.log('nodata');
  //   }
  //   this.checkNoData(); // Check for no data after sorting

  // }
  changepage() {
    // console.log(this.search);
    // console.log(this.momList.length);
    // if (this.search.length !== 0) {
    //   this.p = 1;
    //   console.log(this.momList.length);
    //   console.log(this.p);
    //   console.log('success Data');
    // } else {
    //   this.p = this.beforesearchpage;
    //   console.log(this.momList.length);
    //   console.log('nodata');
    //   this.checkNoData(); // Check for no data after changing page
    //   if (this.noDataFound) {
    //     console.log('Display message: No data found.');
    //   }
    // }
    console.log(this.momList.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.momList.length);
   
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.momList.length);
      console.log("nodata");
    }
  }
  
  sortData(sort: Sort) {
    console.log('this.momlist::', this.momList);

    const data = this.momList.slice();
    console.log('check this codition::', !sort.active || sort.direction === '');

    if (!sort.active || sort.direction === '') {
      this.momList = data;
      this.checkNoData(); 
      return;
    }

    this.momList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'momName':
          return this.compare(a.momName, b.momName, isAsc);
        case 'momDate':
          return this.compare(a.momDate, b.momDate, isAsc);
        case 'client':
          return this.compare(a.client, b.client, isAsc);
        case 'projectName':
          return this.compare(a.project.projectName, b.project.projectName, isAsc);
          case 'meetingPlace':
            return this.compare(a.meetingPlace, b.meetingPlace, isAsc);
        default:
          return 0;
      }
    });
    this.checkNoData(); // Check for no data after sorting

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
 


  checkNoData() {
    this.noDataFound = this.momList.length === 0;
  }
  edit(e) {
    this.router.navigate(['home/update-mom', e]);
    console.log('wtts e::', e);

    // const dialogRef = this.dialog.open(UpdatemomComponent,{
    //   data:e,
    //   height:'600px',
    //   width:'850px',
    //   disableClose:true
    // })
  }
  preview(e) {
    this.router.navigate(['home/view-mom', e]);
    // const dialogRef = this.dialog.open(PreviewFileComponent,{
    //   data:e,
    //   width:'900px',
    //   height:'600px'
    // })
  }

  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML);
    
  
    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    console.log(pdfTable.innerHTML);
    
  
    const documentDefinition = {
      content: html,
      pageMargins: [25, 40, 25, 40],
      info: {
        title: `MOM Report`
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);
  
        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download("MOM Report");
  }
}
