import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalserviceService } from '../providers/globalservice.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { AdminService } from '../providers/admin.service';
@Component({
  selector: 'app-addbusinessunit',
  templateUrl: './addbusinessunit.component.html',
  styleUrls: ['./addbusinessunit.component.scss']
})
export class AddbusinessunitComponent implements OnInit {
  @ViewChild('picker1') picker1: MatDatepicker<Date>;
  @ViewChild('picker2') picker2: MatDatepicker<Date>;
    submitted: boolean = false;
    statusCode: any;
    errorMsg: any;
    existMsg: any;
    businessunitgroup: FormGroup;
    resultData: any;
    branchId: any;
  
    sucess_msg: any;
    error_msg: any;
    loader: any = false;
    success: any = false;
    error: any = false;
    backdrop: any = false;
    employeetData: any;
    isbtnDisable: boolean = false;
    businessId: any;
    today: any = new Date();
    enddate_required: boolean;
    startdate_required: boolean;
    startDate: any;
    endDate: string;
legalentityList: any=[];
  formValues: any;
  businessunitId: any;
  businessresponse: any;
  storeEntityId: any;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private datepipe: DatePipe,
    private gs: GlobalserviceService,
    private adminservice:AdminService,
    private ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);
    
    this.businessunitgroup=this.formBuilder.group({
      legalEntityName:['',[Validators.required]],
      businessUnitName: [
        '',
        [
          Validators.required,
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      startDate:['',[Validators.required]],
      location:['',
        [
          Validators.required,
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],],
        description: [
          '',
          [
            
            Validators.pattern(
              "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
            ),
          ],
        ],
    }) 
    this.adminservice.getAllActiveLegalEntitylist(this.storeEntityId).subscribe((response:any)=>{
      this.legalentityList=response;
      console.log("this.legal entity active list:",this.legalentityList);
      this.legalentityList = Array.isArray(this.legalentityList) ? this.legalentityList: [this.legalentityList];
    })  
    this.businessunitId=this.ActivatedRoute.snapshot.params.businessUnitId;

    console.log("this.supplier id::",this.businessunitId);

    if(this.businessunitId){
      this.loader=true;
              this.isbtnDisable=true

      this.adminservice.getBusinessUnitById(this.businessunitId).subscribe((response:any)=>{
        console.log("edit response:",response);

        this.businessresponse=response;
        this.loader=false;
        this.isbtnDisable=false;

        
        this.businessunitgroup.patchValue({
          businessUnitName:this.businessresponse.businessUnitName,
          startDate:this.businessresponse.businessStartDate,
          
            legalEntityName:this.businessresponse.legalEntity.legalEntityId,
          location:this.businessresponse.location,
          description:this.businessresponse.businessUnitDescription,
          businessUnitStatus:this.businessresponse.businessUnitStatus
          
        })

      })
    }
  }
  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }


  clearAll(){
    // this.businessunitgroup.reset()
    this.router.navigateByUrl("/home/business-unit")
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    if (this.startDate && this.endDate) {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
      this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');

      console.log('2dates', startDate, endDate);

      // if (this.endDate < this.startDate) {
      //   alert('End date cannot be earlier than start date!');
      
        
      //   this.picker1.close();    
      //   this.picker2.close();
      //   this.businessunitgroup.controls['startDate'].reset();    
      //   this.businessunitgroup.controls['endDate'].reset(); 
      //   return  
      // } else {
      // }
    }

    if (this.startDate === undefined && this.endDate !== undefined) {
      this.startdate_required = true;
      this.enddate_required = false;
      return;
    }

    if (this.startDate !== undefined && this.endDate !== undefined) {
      this.startdate_required = false;
      this.enddate_required = false;
      return;
    }

    if (this.endDate !== undefined) {
      this.enddate_required = false;
      return;
    }
  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  get validate() {
    return this.businessunitgroup.controls;
  }
  onSubmit() {
    console.log('form values::', this.businessunitgroup.value);

    this.submitted = true;

    this.formValues=this.businessunitgroup.value;
    console.log("for values::",this.formValues);
    this.startDate = this.datepipe.transform(this.formValues.startDate, 'YYYY-MM-dd');
    console.log("start date:",this.startDate);
    

    

    if (this.businessunitgroup.invalid) {
      this.isbtnDisable=false;
      return;
    }

    if(this.businessunitId){
      const payload={
        businessUnitName:this.formValues.businessUnitName,
        businessUnitDescription:this.formValues.description,
        businessStartDate:this.startDate,
        location:this.formValues.location,
        legalEntity:{
          legalEntityId:this.formValues.legalEntityName
        },
        businessUnitStatus:this.businessresponse.businessUnitStatus

      }
      console.log("payload:",payload);
          this.isbtnDisable=true

      this.adminservice.updateBusinessUnit(this.businessunitId,payload).subscribe((response:any)=>{
        console.log("response:",response);
      
        if (response.statusCode == 200) {
          this.success = true;
          this.isbtnDisable=true
          this.sucess_msg = response.description;
          setTimeout(() => {
            this.router.navigateByUrl("/home/business-unit");
  }, 5000);
        } else {
    //       
        }
      },
      (err:any)=>{
        console.log(err);
        
        if(err.error.statusCode==409){
          this.error = true;
          this.isbtnDisable=false
  
          this.error_msg = err.error.description;
  
          setTimeout(() => {
            this.error=false
            // this.router.navigateByUrl("/home/business-unit");
  }, 5000);
        }
      }
    )    
    


    }else{


          this.isbtnDisable=true

    const payload={
      businessUnitName:this.formValues.businessUnitName,
      businessUnitDescription:this.formValues.description,
      businessStartDate:this.startDate,
      location:this.formValues.location,
      legalEntity:{
        legalEntityId:this.formValues.legalEntityName
      }
      
    }
    console.log("payload:",payload);

    this.adminservice.createBusinessUnit(payload).subscribe((response:any)=>{
      console.log("response:",response);
              this.isbtnDisable=true

      if (response.statusCode == 200) {
        this.success = true;
        this.isbtnDisable=true

        this.sucess_msg = response.description;
        setTimeout(() => {
          this.router.navigateByUrl("/home/business-unit");
}, 5000);
      } else {
        if(response.statusCode==409){
          this.error = true;
          this.isbtnDisable=false
  
          this.error_msg = response.description;
  
          setTimeout(() => {
            this.error=false
            // this.router.navigateByUrl("/home/business-unit");
  }, 5000);
        }
      
      }
    },
    (err:any)=>{
      console.log(err);
      
      if(err.error.statusCode==409){
        this.error = true;
        this.isbtnDisable=false

        this.error_msg = err.error.description;

        setTimeout(() => {
          this.error=false
          // this.router.navigateByUrl("/home/business-unit");
}, 5000);
      }
    }
  )    
  }
}

}
