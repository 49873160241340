import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { httprecruitmentOptions, httpOptions} from '../providers/properties';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HrmsserviceService {



  positionlist():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}position`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  positionstatus(positionId,positionstatus):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}position/deactive/${positionId}/${positionstatus}`,null, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  positionstatuschange(positionId,positionStatus):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}position/positionstatus/${positionId}/${positionStatus}`,null, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  search(positionName:any,clientId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}position/positionName/clientId/${positionName}/${clientId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  positionname(positionName:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}position/positionName/${positionName}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  clientid(clientId:any,orgId:any):Observable<any>
   {
     return this.http.get(`${environment.rec_base_url}position/client/${clientId}/organizationId/${orgId}`, httprecruitmentOptions)
     .pipe(map(data=>data));
   }
  resourcebyrequriment(requriment: any,orgId:any) {
    return this.http.get(`${environment.rec_base_url}resource/requirement/org/${requriment}/${orgId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  overallFilter(position:any,requirement:any,interviewStatus:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/position/requirement/interviewStatus/org/${position}/${requirement}/${interviewStatus}/${orgId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  positionandrecuritment(position:any,requirement:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/position/requirement/org/${position}/${requirement}/${orgId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  searchresource(positionId:any,interviewStatus:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/position/interviewStatus/org/${positionId}/${interviewStatus}/${orgId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  requrimentandinterviewstatus(requriment: any, interviewStatus: any,orgId:any) {
    return this.http.get(`${environment.rec_base_url}resource/requirement/interviewStatus/org/${requriment}/${interviewStatus}/${orgId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  position(positionId:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/position/org/${positionId}/${orgId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  
  constructor(private http: HttpClient) { }
  requrimentbypostion(postionid: any,orgId:any) {
    return this.http.get(`${environment.rec_base_url}requirements/position/org/${postionid}/${orgId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  
  getRessourceFeedbackByResourceId(resourceId:any):Observable<any>{
    return this.http.get(`${environment.rec_base_url}resourcefeedback/resource/${resourceId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }


  findbyResourceId(resourceId: any):Observable<any> {
    return this.http.get(`${environment.rec_base_url}resource/${resourceId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  //Rating
  getAllRatings():Observable<any>{
    return this.http.get(`${environment.rec_base_url}rating`, httprecruitmentOptions)
  .pipe(map(data=>data));
  }
//skill
getAllSkills():Observable<any>{
  return this.http.get(`${environment.rec_base_url}skill`, httprecruitmentOptions)
  .pipe(map(data=>data));
}
  //Recommendation
  getAllRecommendations():Observable<any>{
    return this.http.get(`${environment.rec_base_url}list-type-values/9`, httprecruitmentOptions)
  .pipe(map(data=>data));
  }

  getResourceFeedbackByFeedbackId(feedbackId: any):Observable<any>{
   
    return this.http.get(`${environment.rec_base_url}resourcefeedback/${feedbackId}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  updateResourceFeedback(feedbackId,feedbackData:any){
    console.log('update service',feedbackId,'---',feedbackData)
    return this.http.put(`${environment.rec_base_url}resourcefeedback/${feedbackId}`,feedbackData, httprecruitmentOptions)
    .pipe(map(data=>data));
  }
//Resource Feedback
createResourceFeedback(resourceFeedback: any) {
  console.log('Create service',resourceFeedback);
  return this.http.post(`${environment.rec_base_url}resourcefeedback`, resourceFeedback, httprecruitmentOptions)
   .pipe(map(data=>data)); 
}
   //Dashboard
   recruiterdashboard(employeeId):Observable<any>
   {
      return this.http.get(`${environment.rec_base_url}recruiter-dashboard/${employeeId}`, httprecruitmentOptions)
      .pipe(map(data=>data));
 
   }
   //Dashboard
   recruiterdashboardorg(employeeId, orgid):Observable<any>
   {
      return this.http.get(`${environment.rec_base_url}recruiter-dashboard/organization/${employeeId}/${orgid}`, httprecruitmentOptions)
      .pipe(map(data=>data));
 
   }

  geRecruiter(orgaId:any) {
    return this.http.get(`${environment.base_url}employee/role/Recruiter/organizationId/${orgaId}`, httpOptions)
    .pipe(map(data=>data));
  }

  admindashboard() {
    return this.http.get(`${environment.rec_base_url}admin-dashboard`, httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  admindashboardByOrganization(orgid) {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/${orgid}`, httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  admindashbaordreport(fromdate :any , todate :any) {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/${fromdate}/${todate}`, httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  admindashbaordreportbyOrg(fromdate :any , todate :any, orgid) {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/organization/${fromdate}/${todate}/${orgid}`, httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  admindashbaordrecuriterreport(reportrecruiter:any)
  {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/${reportrecruiter}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  admindashbaordrecuriterreportorg(reportrecruiter:any, orgid)
  {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/organization/${reportrecruiter}/${orgid}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  admindashbaordreportbyrecruiter(fromdate: string, todate: string, recruiter: any) {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/${fromdate}/${todate}/${recruiter}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  admindashbaordreportbyrecruiterorg(fromdate: string, todate: string, recruiter: any, orgid) {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/organization/${fromdate}/${todate}/${recruiter}/${orgid}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

    // Resource Date only report in Dashboard
    resourcrreportdate(startDate,endDate,interviewstatus)
    {
      return this.http.get(`${environment.rec_base_url}resource/recruiter/${startDate}/${endDate}/${interviewstatus}`, httprecruitmentOptions)
      .pipe(map(data=>data));
    }
   // Resource Date only report in Dashboard
   resourcrreportdatebyorg(startDate,endDate,interviewstatus, orgid)
   {
     return this.http.get(`${environment.rec_base_url}resource/recruiter/organization/${startDate}/${endDate}/${interviewstatus}/${orgid}`, httprecruitmentOptions)
     .pipe(map(data=>data));
   }
    
    // Resource recuriter only report in Dashboard
  resourcrreportecruiter(createdBy,interviewstatus)
  {
    return this.http.get(`${environment.rec_base_url}resource/recruiter/${createdBy}/${interviewstatus}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  resourcrreportecruiterorg(createdBy,interviewstatus,orgid)
  {
    return this.http.get(`${environment.rec_base_url}resource/recruiter/organization/${createdBy}/${interviewstatus}/${orgid}`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }


  //search client name
  clientname():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}client/status/active`, httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  // positionactivelist
  // positionactivelist():Observable<any>
  // {
  //   return this.http.get(`${environment.rec_base_url}position/status/1`,httprecruitmentOptions)
  //   .pipe(map(data=>data));
  // }
  // positionactivelist(organizationId:any):Observable<any>
  // {
  //   return this.http.get(`${environment.rec_base_url}v2/position/activeStatus/organizationId/${organizationId}`,httprecruitmentOptions)
  //   .pipe(map(data=>data));
  // }
  positionactivelist(organizationId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}v2/position/activeStatus/organizationId/${organizationId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

//requirementlist Api
requirementlist():Observable<any>
{
  return this.http.get(`${environment.rec_base_url}requirement`, httprecruitmentOptions)
  .pipe(map(data=>data));
}
// requirement list Status Active and Inactive
requirementstatus(requirementId,requirementStatus):Observable<any>
{
  return this.http.put(`${environment.rec_base_url}requirement/deactive/${requirementId}/${requirementStatus}`,null,httprecruitmentOptions)
  .pipe(map(data=>data));
}
  // requirement list  Requirement Status open and close
  requirementstatuschange(requirementId,requirementStatus):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}requirement/requirementstatus/${requirementId}/${requirementStatus}`,null,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  recuirementoverallfilterList(recuriementnumber:any,positionName:any,clientId:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}requirement/requirementNo/position/client/org/${recuriementnumber}/${positionName}/${clientId}/${orgId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  positionandclientList(positionName:any,clientId:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}requirement/position/client/organization/${positionName}/${clientId}/${orgId}`,httprecruitmentOptions)  
    .pipe(map(data=>data));
  }

  positionandrecuriementList(recuriementnumber:any,positionName:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}requirement/requirementNo/position/org/${recuriementnumber}/${positionName}/${orgId}`,httprecruitmentOptions)  
    .pipe(map(data=>data));
  }
    //  recuriementnumber and client Api
    recuriementandclientList(recuriementnumber:any,clientId:any,orgId:any):Observable<any>
    {
      return this.http.get(`${environment.rec_base_url}requirement/requirementNo/client/org/${recuriementnumber}/${clientId}/${orgId}`,httprecruitmentOptions)  
      .pipe(map(data=>data));
    }
    // Position only Api
   positionList(positionName:any,orgId:any):Observable<any>
   {
     return this.http.get(`${environment.rec_base_url}requirement/position/org/${positionName}/${orgId}`,httprecruitmentOptions)
     .pipe(map(data=>data));
   }

    // recuirement only Api
    recuriementList(recuriementnumber:any,orgId:any):Observable<any>
    {
      return this.http.get(`${environment.rec_base_url}requirement/requirementNo/org/${recuriementnumber}/${orgId}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }

    
    // client only Api
    clientList(clientId:any,orgId:any):Observable<any>
    // {
    //   return this.http.get(`${environment.rec_base_url}requirement/client/${clientId}`,httprecruitmentOptions)
    //   .pipe(map(data=>data));
    // }
    // clientList(clientId:any):Observable<any>
    {
      return this.http.get(`${environment.rec_base_url}requirements/client/org/${clientId}/${orgId}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }
    hiringfor():Observable<any>
    {
       return this.http.get(`${environment.rec_base_url}list-type-values/6`,httprecruitmentOptions)
       .pipe(map(data=>data));
    }

    //create clientname
  client():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}client/status/active`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
    
  noticeperiod():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}list-type-values/8`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  jobtypebyid() {
    return this.http.get(`${environment.rec_base_url}list-type-values/7`,httprecruitmentOptions)
    .pipe(map(data=>data));
 }
  // recuirement by id use get data
  recuirementbyid(recuriementId:any,orgId:any):Observable<any>
  {
     return this.http.get(`${environment.rec_base_url}requirement/org/${recuriementId}/${orgId}`,httprecruitmentOptions)
     .pipe(map(data=>data));
  }
  onboardingbyid(onboardingId:any):Observable<any>
  {
     return this.http.get(`${environment.rec_base_url}onboarding/${onboardingId}`,httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  positionbyid(positionId:any):Observable<any>
  {
     return this.http.get(`${environment.rec_base_url}position/${positionId}`,httprecruitmentOptions)
     .pipe(map(data=>data));
  }
  
  // Update Recuirement
  recuirementupdate(requirementId: any,positionupdate: any, ) {
    return this.http.put(`${environment.rec_base_url}requirement/${requirementId}`,positionupdate,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
   // recuriement Create
   recuriementcreate(positiondata:any):Observable<any>
   {
     return this.http.post(`${environment.rec_base_url}requirement`,positiondata,httprecruitmentOptions)
     .pipe(map(data=>data));
   }

    // Dashboard resource active ot inactive based list api
  resourceStatus(status)
  {
    return this.http.get(`${environment.rec_base_url}resource/status/${status}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

    // Dashboard resource active ot inactive based list api
    resourceByStatusAndOrganization(status, organizatinId)
    {
      return this.http.get(`${environment.rec_base_url}resource/status/${status}/organization/${organizatinId}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }

    // Dashboard resource interviewed or notinterviewed based list api
    resourceInterviewedStatus(interviewed)
    {
      return this.http.get(`${environment.rec_base_url}resource/interviewed/${interviewed}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }

      // Dashboard resource interviewed or notinterviewed based list api
      resourceByInterviewedAndOrganization(interviewed, organizatinId)
      {
        return this.http.get(`${environment.rec_base_url}resource/interviewed/${interviewed}/organization/${organizatinId}`,httprecruitmentOptions)
        .pipe(map(data=>data));
      }

       // Dashboard resource profile shared fromdate and todate and recuriter filter list api
   resourceProfileSharedDateandRecruiterBoth(startDate,endDate,createdBy)
   {
     return this.http.get(`${environment.rec_base_url}resource/${startDate}/${endDate}/${createdBy}`,httprecruitmentOptions)
     .pipe(map(data=>data));
   }

   resourceProfileSharedDateandRecruiterBothorg(startDate,endDate,createdBy,orgid)
   {
     return this.http.get(`${environment.rec_base_url}resource/organization/${startDate}/${endDate}/${createdBy}/${orgid}`,httprecruitmentOptions)
     .pipe(map(data=>data));
   }
    // Dashboard resource profile shared fromdate and todate  filter list api
    resourceProfileSharedDate(startDate,endDate)
    {
      return this.http.get(`${environment.rec_base_url}resource/${startDate}/${endDate}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }
    resourceProfileSharedDateorg(startDate,endDate,orgid)
    {
      return this.http.get(`${environment.rec_base_url}resource/organization/${startDate}/${endDate}/${orgid}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }
      // Dashboard resource profile shared recruiter based filter list api
      resourceProfileSharedRecruiter(createdBy)
      {
        return this.http.get(`${environment.rec_base_url}resource/recruiter/${createdBy}`,httprecruitmentOptions)
        .pipe(map(data=>data));
      }
 // Dashboard resource profile shared recruiter based filter list api
 resourceProfileSharedRecruiterorg(createdBy, orgid)
 {
   return this.http.get(`${environment.rec_base_url}resource/recruiter/organization/${createdBy}/${orgid}`,httprecruitmentOptions)
   .pipe(map(data=>data));
 }
      
   // Dashboard resource  Interviewed fromdate and todate and recuriter filter list api
   resourceInterviewedDateandRecruiterBoth(startDate,endDate,createdBy,interviewed)
   {
     return this.http.get(`${environment.rec_base_url}resource/recruiter/interviewed/${startDate}/${endDate}/${createdBy}/${interviewed}`,httprecruitmentOptions)
     .pipe(map(data=>data));
   }

      // Dashboard resource  Interviewed fromdate and todate and recuriter filter list api
      resourceInterviewedDateandRecruiterBothorg(startDate,endDate,createdBy,interviewed,orgid)
      {
        return this.http.get(`${environment.rec_base_url}resource/recruiter/interviewed/true/organization/${startDate}/${endDate}/${createdBy}/${interviewed}/${orgid}`,httprecruitmentOptions)
        .pipe(map(data=>data));
      }
    // Dashboard resource  Interviewed fromdate and todate  filter list api
    resourceInterviewedDate(startDate,endDate,interviewed)
    {
      return this.http.get(`${environment.rec_base_url}resource/interviewed/${startDate}/${endDate}/${interviewed}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }

     // Dashboard resource  Interviewed fromdate and todate  filter list api
     resourceInterviewedDateorg(startDate,endDate,interviewed, orgid)
     {
       return this.http.get(`${environment.rec_base_url}resource/interviewed/organization/${startDate}/${endDate}/${interviewed}/${orgid}`,httprecruitmentOptions)
       .pipe(map(data=>data));
     }
    // Dashboard resource  Interviewed recuriter filter list api
    resourceInterviewedRecruiter(createdBy,interviewed)
    {
      return this.http.get(`${environment.rec_base_url}resource/recruiter/interviewed/${createdBy}/${interviewed}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }
    // Dashboard resource  Interviewed recuriter filter list api
    resourceInterviewedRecruiterorg(createdBy,interviewed, orgid)
    {
      return this.http.get(`${environment.rec_base_url}resource/recruiter/interviewed/organization/${createdBy}/${interviewed}/${orgid}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }

    // resource OpenPositions Api in Recuriter Dashboard
  resourceOpenPositions(positionstatus)
  {
    return this.http.get(`${environment.rec_base_url}position/positionstatus/${positionstatus}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  resourceOpenPositionsorg(positionstatus, orgid)
  {
    return this.http.get(`${environment.rec_base_url}position/positionstatus/organization/${positionstatus}/${orgid}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
    //resource Profiled Shared Api in Recuriter Dashboard
    resourceProfileShared(recruiter:any,status:any)
    {
      return this.http.get(`${environment.rec_base_url}resource/recruiter/status/${recruiter}/${status}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }
      //resource Interview Status Api in Recuriter Dashboard
   resourceInterviewStatus(recruiter,interviewed)
   {
     return this.http.get(`${environment.rec_base_url}resource/recruiter/interviewed/${recruiter}/${interviewed}`,httprecruitmentOptions)
     .pipe(map(data=>data));
   }

   
  // Resource date and recuriter report in Dashboard
  resourcrreportstartdateandrecruiter(startDate,endDate,interviewstatus,createdBy)
  {
    return this.http.get(`${environment.rec_base_url}resource/recruiter/${startDate}/${endDate}/${interviewstatus}/${createdBy}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  resourcrreportstartdateandrecruiterorg(startDate,endDate,interviewstatus,createdBy,orgid)
  {
    return this.http.get(`${environment.rec_base_url}resource/recruiter/organization/${startDate}/${endDate}/${interviewstatus}/${createdBy}/${orgid}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
 //search interviewstatus
 interviewstatus(interviewStatus:any,orgId:any):Observable<any>
 {
   return this.http.get(`${environment.rec_base_url}resource/interviewStatus/org/${interviewStatus}/${orgId}`,httprecruitmentOptions)
   .pipe(map(data=>data));
 }
 
  //search interviewstatus
  resourcebyinterviewstatusandorganization(interviewStatus:any, organizationId):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/interviewStatus/${interviewStatus}/organization/${organizationId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  

}
