Sub header start here
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Shift</span>
                </div>
                
                <div class="sub-header-buttons">
                    <a routerLink="employee" (click)="importshiftemploye()" mat-raised-button class="ml-2" title="Import">Import</a>
                    <!-- <a routerLink="/home/" mat-raised-button title="Back" class="">Back</a> -->
                    <a routerLink="/home/employee-shift" routerLinkActive="active" color="primary" mat-raised-button title="Add Employee" class="ml-2">
                        Assign Employee Shift
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                     <div class="row mb-2">
                            <div class="col-md-12">
                                <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                                    <div class="row mb-3 pl-0">
                                        <div class="col-md-3">        
                                            <label class="col-form-label">Client<span class="star">*</span></label>                                    
                                            <select class="form-select form-select-solid form-select-lg"
                                                appearance="fill" formControlName="clientserach"   (change)="getshift($event)"                       
                                                placeholder="- Select Client -">
                                                <option [value]="null" selected="selected"> --- Select client --- </option>
                                                <option *ngFor="let e of clientList" class="text-color"
                                                    [value]="e.clientId">{{e.clientName}}</option>
                                            </select>
                                            <!-- (selectionChange)="listTypeValueid($event)" -->
                                        </div>
                                        <div class="col-md-3">  
                                            <label class="col-form-label">Shift<span class="star">*</span></label>                                        
                                            <select class="form-select form-select-solid form-select-lg"
                                                appearance="fill" formControlName="shiftserach"                          
                                                placeholder="- Select Shift -">
                                                <option [value]="null" selected="selected"> --- Select Shift --- </option>
                                                <option *ngFor="let e of shiftList" class="text-color"
                                                    [value]="e.shiftId">{{e.shiftName}}</option>
                                            </select>
                                            <!-- (selectionChange)="listTypeValueid($event)" -->
                                        </div>
                                        <div class="col-md-3">
                                            <label class="col-form-label">Start Date</label>    
                                            <input formControlName="startDateSearch" id="startDateSearch" matInput [matDatepicker]="startDateSearch" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                               placeholder="Select Start Date"   readonly  >
                                            <mat-datepicker-toggle matSuffix [for]="startDateSearch" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                            <mat-datepicker #startDateSearch   
                                            
                                            panelClass="example-month-picker"></mat-datepicker>
                                     
            
                                            <div class="valid-msg" *ngIf="(serachvalidate.startDateSearch.invalid && serachvalidate.startDateSearch.touched) || serachvalidate.startDateSearch.dirty">
                                                <span class="text-danger" *ngIf="serachvalidate.startDateSearch.errors?.required">Please Start Date</span>
                                            </div>
                                        </div>
                                        <div class="col-md-3" >     
                                            <label class="col-form-label">End Date</label>                                         
                                            <input formControlName="endDateSearch" id="endDateSearch"  matInput [matDatepicker]="endDateSearch" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input"
                                                placeholder="Select End Date" readonly  [min]="searchForm.value.startDateSearch">
                                            <mat-datepicker-toggle matSuffix [for]="endDateSearch" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                            <mat-datepicker #endDateSearch></mat-datepicker>
            
                                            <div class="valid-msg" *ngIf="(serachvalidate.endDateSearch.invalid && serachvalidate.endDateSearch.touched) || serachvalidate.endDateSearch.dirty">
                                                <span class="text-danger" *ngIf="serachvalidate.endDateSearch.errors?.required">Please select End Date</span>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="float: right;">
                                            <button type="submit" mat-raised-button color="primary"
                                                class="btn btn-primary search-btn">Search</button>
                                            <button style="margin-left: 20px;" type="reset" mat-raised-button
                                               class="btn mr-2 search-btn"
                                                (click)="clearTable()">Clear</button>
                                               <!-- (click)="clearTable()" -->
                                               <button style="margin-left: 20px;" *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button color="primary" class="btn btn-primary search-btn">
                                                Export
                                              </button>
                                        </div>
                                    </div>
                                </form>
                            <!--  <div class="col-md-3 pl-0">
                                <button class="search-btn" mat-raised-button color="primary">Search</button>
                            </div> -->
                        </div>
                   
                   
            

                  
               
           
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-11 text-right">
                            <p class="show-count">Show</p>
                        </div>
                        <div class="col-md-1 text-left pl-0">
                            <select [(ngModel)]="pagePerItem" (change)="pageChages()"  class="form-control report-filter">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <!-- <option value="1500">1500</option> -->
                            </select>
                        </div>
                    </div>
                </div>
                 <div class="table-responsive"  style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                    <p><b>MANAGE EMPLOYEE SHIFT</b></p>
                    <table class="table  --table-bordered table-border-new" style="width: 120%; border-top-left-radius: 10px;border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center">Action</th>
                                <th>Client</th>
                                <th>Shift Calendar</th>
                                <th>Shift Name</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Roster Type</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Mobile Number</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                               
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <!-- <td class="text-center">{{i+1}}</td> -->
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="updateform(row)"  >
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                        
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 1" (click)="updateStatus(row.shiftEmployeeAssignId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>In-Active</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 2" (click)="updateStatus(row.shiftEmployeeAssignId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>
                                    </mat-menu>
                                </td>
    
                                <td>{{row.client.clientName}}</td>
                                <td>{{row.shiftCalender.shiftCalendarName}}</td>
                                <td>{{row.shift.shiftName}}</td>
                                <td>{{row.shift.startTime | timeconverter }}</td>
                                <td>{{row.shift.endTime | timeconverter}}</td>
                                <td>{{row.rosterType.listTypeValueName}}</td>
                                <td>{{row.employee.employeeNumber}}</td>
                               
                                <td>{{row.employee.firstName}} </td>
                                <td>{{row.employee.mobileNumber}}</td>
                                <td>{{row.startDate | date : dateFormat}}</td>
                                <td>{{row.endDate | date : dateFormat}}</td>
                                        
                                <!-- capitalizeWordsName -->
                                <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                        {{row.status.listTypeValueName}}
                                    </span>
    
                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                        {{row.status.listTypeValueName}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No matching records found!</td>
                            </tr>
                        </tbody>
                    </table>
                 </div>
             

               
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="showing-record">
                        <!-- <ng-template pTemplate="paginatorleft" let-state>
                            Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                        </ng-template> -->
                        {{resultData.length}} rows
                    </div>
                </div>
                <div class="col-md-8 text-right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records</button>
</div>