import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PayrollService } from 'src/app/providers/payroll.service';
import { responseMsgTimeOut , redirectMsgTimeOut  } from 'src/app/providers/properties';
import { ActivatedRoute, Router } from '@angular/router';
// import { Console } from 'console';

@Component({
  selector: 'app-list-element-percentage',
  templateUrl: './list-element-percentage.component.html',
  styleUrls: ['./list-element-percentage.component.scss']
})
export class ListElementPercentageComponent implements OnInit {

  searchForm: FormGroup;
  list_loader = false;
  elementslistData: any[];
  elementslistData1: any[];
  nodata = false;
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  p: number = 1;
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  pagePerItem: any = 5;
  categoryTypeData: any =[];
  listTypeValueId: any = [];
  listTypeValueId1: any;
  elementData: any;

  elementform: FormGroup;
  loader: any = false;
  backdrop: any = false;
  elementPercentageId: any;
  submitted: boolean = false;
  livingCityData: any;
  elementNameData: any;
  usertype: any;
  update_data_error_msg: any;
  userdata: any = [];
  showCategoryType: boolean = false;

  showLivingCity: boolean = false;
  categoryId: any;
  eleName: any;

  elementslistData1length = 0;

  noRecordsFound: boolean = false;

  isDataLoading: boolean = true;
  elementDatalist: any =[];
  elementNameData1: any = [];
  elementNameData2: any = [];
  employeetData: any;
  organizationId: any;
  clientId:any;
  btnDisable: boolean=false;
  tableShow: boolean=false;
  search: any;

  constructor(private payrollservice: PayrollService,
    private dialog: MatDialog,
    private payrollService: PayrollService,
    private formBuilder: FormBuilder,
    private ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.organizationId = organizationId;

    let clientId = this.employeetData?.employee?.client?.clientId;
    console.log('clientId',clientId)
    this.clientId=clientId
    
    // this.noRecordsFound = true;
    this.searchForm = this.formBuilder.group({
      elementNameSearch: [null, Validators.required] 
    });

    // this.elementpercentageList();
    this.listByElementId();
    this.listAllElement();

    this.listCategoryType();
    this.patchresource();
    this.listLivingCity();


    if (this.elementPercentageId) {
      console.log('elementId present')

      this.elementform = new FormGroup(
        {
          categoryType: new FormControl(this.elementform, [Validators.required]),
          elementName: new FormControl(this.elementform, [Validators.required]),
          percentage: new FormControl(this.elementform, [Validators.required]),
          livingCity: new FormControl(this.elementform),
        }
      );

    }
    else {
      console.log('elementId not present')
      this.elementform = new FormGroup(
        {
          categoryType: new FormControl(this.elementform, [Validators.required]),
          elementName: new FormControl(this.elementform, [Validators.required]),
          percentage: new FormControl(this.elementform, [Validators.required]),
          livingCity: new FormControl(this.elementform),
        }
      );
    }
  }

  edit(elementPercentageId) {
    console.log('elementPercentageId', elementPercentageId)
    this.elementPercentageId = elementPercentageId;
    this.patchresource();
  }

  clearTable() {
    console.log('clear')
    this.elementslistData1 = [];
    // this.noRecordsFound = true;
  }

  clear() {
    this.elementform.reset();
    return;
  }


  elementpercentageList() {
    console.log('list')
    this.isDataLoading = true;
    this.list_loader = true;
    this.payrollservice.elementpercentageList().subscribe((elementslistData: any) => {
      console.log('elementslistData', elementslistData)
      this.isDataLoading = false;
      this.list_loader = false;
      this.elementslistData1 = elementslistData;
      console.log(this.elementslistData1);
      this.elementslistData1length = this.elementslistData1.length;
      if (this.elementslistData1.length == 0) {
        this.noRecordsFound = true;
        console.log("nodata");
      }else{
        this.noRecordsFound = false;
      }

      if (elementslistData.statusCode == 204) {
        this.noRecordsFound = true;
        console.log("nodata");
      }else{
        this.noRecordsFound = false;
      }
    },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if (error.status == 404) {
          this.noRecordsFound = true;
          console.log("404")
        }
      }
    )
  }
  formcancel()
  {
    this.list_loader=false
    // this.nodata=false
    this.submitted=false
    // this.btnsDisable=false
    this.elementform.reset();// this.dialog.closeAll();
    // this.search=''
  }
  clearforms(){
    this.searchForm.reset()
  }
  searchSubmit() {
    this.elementslistData1 = [];
    console.log('searchSubmit', this.searchForm)
    this.noRecordsFound = false;
    this.list_loader = true;
    if (this.searchForm.value.elementNameSearch == null || this.searchForm.value.elementNameSearch == 'null') {
      console.log("this.searchForm.value.elementNameSearch", this.searchForm.value.elementNameSearch)
      this.noRecordsFound = true;
      this.list_loader = false;
      alert("Please Select Element Name");
     return
      //  this.elementpercentageList();
    } 
    else {
      // this.isDataLoading = true;
      this.btnDisable=true;
      this.tableShow=false
      this.payrollservice.listByElementId(this.listTypeValueId).subscribe((data: any) => {
        console.log('data', data);
        // this.isDataLoading = false;
        this.list_loader = false;
        this.btnDisable=false;
        this.tableShow=true
        this.noRecordsFound = false;

        if (data.statusCode == 204) {
          this.noRecordsFound = true;
          this.btnDisable=false;
          this.tableShow=true


        } else {
          this.elementslistData1 = data;
          this.elementslistData1length = this.elementslistData1.length;
          this.noRecordsFound = false;
          this.tableShow=true

        }

        //console.log('this.elementslistData1', this.elementslistData1);
      }, (error) => {
        console.log('error')
      });
    }
  }


  statuschangeactive(elementId, status) {
    console.log("elementId : " + elementId);
    console.log("status" + status);
    this.list_loader=true

    this.payrollservice.elementpercantagestatus(elementId, status).subscribe((data: any) => {
      this.list_loader=false

      if (data.statusCode == 200) {
        this.success = true;
        this.sucess_msg = data.message;
        this.list_loader=false

        setTimeout(() => { this.success = false;
          this.list_loader=false

         }, redirectMsgTimeOut)

        if (this.listTypeValueId == null || this.listTypeValueId == 'null' || this.listTypeValueId == 0 || this.listTypeValueId == undefined) {
          this.elementpercentageList();
        } else {
          this.listByElementId();

        }
      }
      else {
        this.error = true;
        this.error_msg = data.message;
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }

    })
  }

  // listAllElement() {
  //   if(this.organizationId){
  //   this.payrollService.elementList(this.organizationId).subscribe((result: any) => {
  //     // this.elementData = result;

  //     this.elementDatalist = result;

  //   })
  // } else if(this.clientId){
  //   this.payrollService.elementListByClient(this.clientId).subscribe((result: any) => {
  //     // this.elementData = result;

  //     this.elementDatalist = result;

  //   })
  // }
  // else{
  //   console.log("no id's found")
  // }
  // }

  listAllElement() {
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    

    let clientId = this.employeetData?.employee?.client?.clientId;
    console.log('clientId',clientId)

    console.log("organizationId:", this.organizationId);
    console.log("clientId:", this.clientId);
  
    if (this.organizationId) {
      console.log("Fetching elements by organizationId");
      this.payrollService.elementList(this.organizationId).subscribe((result: any) => {
        this.elementDatalist = result;
      });
    } else if (this.clientId) {
      console.log("Fetching elements by clientId");
      this.payrollService.elementListByClient(this.clientId).subscribe((result: any) => {
        this.elementDatalist = result;
      });
    } else {
      console.log("No IDs found");
    }
  }
  

  listCategoryType() {
    this.payrollService.listCategoryType(this.organizationId).subscribe((result: any) => {
      this.categoryTypeData = result;
      console.log('this.categoryTypeData ', this.categoryTypeData)
    })
  }

  listByElementId() {
    // this.tableShow=false

   console.log('listByElementId', this.searchForm.value.elementNameSearch);
   if(this.searchForm.value.elementNameSearch != null){
    this.list_loader=true
    this.payrollService.listByElementId(this.searchForm.value.elementNameSearch).subscribe((result: any) => {
      this.list_loader=false

      if (result.statusCode == 204) {
        this.noRecordsFound = true;
        this.list_loader=false

        // this.tableShow=false
      } else {
        this.elementslistData1 = result;
        this.elementslistData1length = this.elementslistData1.length;
        this.noRecordsFound = false;
      }
    })
   }else{
    console.log('listByElementId null', this.searchForm.value.elementNameSearch);
    // this.noRecordsFound = true;
    this.tableShow=false
   }
   
  }
  get validate() { return this.elementform?.controls; }
  onItemsPerPageChange(event: any) {
    this.pagePerItem = event.target.value;
    this.p = 1; // Reset the pagination to the first page when items per page is changed
  }
  statuschangeinactive(elementId, status) {
    this.list_loader=true

    this.payrollservice.elementpercantagestatus(elementId, status).subscribe((data: any) => {
      this.list_loader=false

      if (data.statusCode == 200) {
        this.success = true;
        this.list_loader=false

        this.sucess_msg = data.message;
        setTimeout(() => { this.success = false; 
          this.list_loader=false


        }, redirectMsgTimeOut)

        if (this.listTypeValueId == null || this.listTypeValueId == 'null' || this.listTypeValueId == 0 || this.listTypeValueId == undefined) {
          this.elementpercentageList();
        } else {
          this.listByElementId();

        }
      }
      else {
        this.error = true;
        this.error_msg = data.message;
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }
    })
  }

  onKeyUpEvent(event) {
    if (this.query.length != 0) {
      this.p = 1;
    }
    else {
      this.p = this.beforepage;
    }
  }
  get elementNameSearch() {
    return this.searchForm.get('elementNameSearch');
  }

  patchresource() {
    console.log('patch')
    if (this.elementPercentageId) {
      this.list_loader=true
      this.payrollService.elementpercentagebyid(this.elementPercentageId).subscribe((userdata: any) => {
        console.log('userdata', userdata)
        this.userdata = userdata;
        this.list_loader=false
        console.log('this.userdata', this.userdata)

        this.elementform.patchValue({

          elementPercentageId: this.userdata.elementPercentageId,
          percentage: this.userdata.elementPercentage,
          elementName: this.userdata.hrmspaySlipElement.elementId
        });
        console.log(" this.elementform", this.elementform);

        this.elementform.get('categoryType').patchValue(this.userdata.hrmspaySlipElement.categoryType.listTypeValueId);
        this.elementform.get('elementName').patchValue(this.userdata.hrmspaySlipElement.elementId);

        console.log("this.userdata.livingCityType ", this.userdata);
        if (this.userdata.livingCityType != undefined) {
          this.elementform.get('livingCity').patchValue(this.userdata.livingCityType.listTypeValueId);
        }

        this.payrollService.listByCategoryId(this.elementform.value.categoryType, this.organizationId).subscribe((result: any) => {
          this.elementNameData2 = result;
          this.elementNameData2 = Array.isArray(result) ? result: [result];
          console.log(' this.elementNameData', this.elementNameData2);
          var eleName = this.elementform.value.elementName;
          this.payrollService.elementbyid(eleName).subscribe((userdata: any) => {
            if (userdata.elementName.toLowerCase() === "hra".toLowerCase()) {
              console.log(" if hra")
              this.showLivingCity = true;
              this.listLivingCity();
            } else {
              this.showLivingCity = false;
            }
          })
        })


        if (this.elementform.invalid) {
          const controls = this.elementform.controls;
          for (const name in controls) {
            if (controls[name].invalid) {
              console.log(name)
            }
          }
        }
      })
    }
  }

  elementType(event) {
    // this.clearTable();
    // this.noRecordsFound = true;
    console.log('event', event.value)
    console.log('event', event.value)
    this.listTypeValueId1 = event.value;
    this.listElementByCategoryId2(this.listTypeValueId1);
  }

  // listElementByCategoryId2(event) {
    
  //   this.payrollService.listByCategoryId(event, this.organizationId).subscribe((result: any) => {
  //     console.log('result', result)
  //       this.elementNameData2 = result;
  //       if (result.statusCode == 204) {
  //         this.noRecordsFound = true;
  //       } else {
  //         this.elementNameData2 = result;
  //         this.elementslistData1length = this.elementNameData2.length;
  //         // this.noRecordsFound = false;
  //         console.log('this.elementform.valid', this.searchForm.valid)
          
  //       }
  //   })
  // }

  listElementByCategoryId2(event) {
    if (this.organizationId) {
      this.payrollService.listByCategoryId(event, this.organizationId).subscribe((result: any) => {
        console.log('result', result);
        this.elementNameData2 = result;
        this.elementNameData2 = Array.isArray(result) ? result: [result];

        if (result.statusCode == 204) {
          this.noRecordsFound = true;
        } else {
          this.elementNameData2 = result;
          this.elementslistData1length = this.elementNameData2.length;
          console.log('this.searchForm.valid', this.searchForm.valid);
        }
      });
    } else if (this.clientId) {
      this.payrollService.listByCategoryIdClient(event, this.clientId).subscribe((result: any) => {
        console.log('result', result);
        this.elementNameData2 = result;
        this.elementNameData2 = Array.isArray(result) ? result: [result];

        if (result.statusCode == 204) {
          this.noRecordsFound = true;
        } else {
          this.elementNameData2 = result;
          this.elementslistData1length = this.elementNameData2.length;
          console.log('this.searchForm.valid', this.searchForm.valid);
        }
      });
    } else {
      console.log("No organizationId or clientId found");
      this.noRecordsFound = true;
    }
  }
  
  changefirstpage() {
    this.p = 1;
    }

  listTypeValueid(event) {
    console.log('event', event.value)
    console.log('event', event.value)
    this.listTypeValueId = event.value;
    // this.listElementByCategoryId(this.listTypeValueId);
  }

  listTypeValueidElementName(event) 
  {
    console.log("element name dropdown", event.value)
    this.payrollService.elementbyid(event.value).subscribe((userdata: any) => {
      console.log('userdata.elementName', userdata.elementName)
      this.eleName = userdata.elementName;
      console.log(" userdata.elementName", userdata.elementName)
      if (this.eleName.toLowerCase() === "hra".toLowerCase())
       {
        console.log(" if hra")
        this.showLivingCity = true;
        this.listLivingCity();
      } 
      else 
      {
        this.showLivingCity = false;
      }
    })
  }

  // submitData() {
  //   console.log('submit')
  //   // this.noRecordsFound = false;
  //   this.submitted = true;
  //   this.tableShow=false


  //   this.error = false;
  //   this.success = false;
  //   if (this.elementPercentageId) {
  //     var elementupdate;
  //     if (this.elementform.value.livingCity != null) {
  //       elementupdate =
  //       {
  //         elementPercentageId: this.elementPercentageId,
  //         elementPercentage: this.elementform.value.percentage,
  //         hrmspaySlipElement: { elementId: this.elementform.value.elementName },
  //         livingCityType: { listTypeValueId: this.elementform.value.livingCity },
  //         percentageStatus: this.userdata.percentageStatus,
  //         clientId: {clientId:this.clientId}
  //       };
  //     } else {
  //       elementupdate =
  //       {
  //         elementPercentageId: this.elementPercentageId,
  //         elementPercentage: this.elementform.value.percentage,
  //         hrmspaySlipElement: { elementId: this.elementform.value.elementName },
  //         livingCityType: null,
  //         percentageStatus: this.userdata.percentageStatus,
  //         clientId: {clientId:this.clientId}
  //       };
  //     }

  //     console.log('elementupdate', elementupdate)
  //     this.list_loader=true
  //     this.payrollService.elementpercantageupdate(elementupdate, this.elementPercentageId).subscribe((data: any) => {
  //      this.list_loader=false
  //       if (data.statusCode == 200) {
  //         this.elementform.reset();
  //         this.listByElementId();
  //         this.success = true;
  //         this.sucess_msg = data.description;
  //         setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
  //       }
  //       else {
  //         console.log("nodata", this.noRecordsFound)
  //         this.error = true;
  //         this.error_msg = data.description;
  //         setTimeout(() => { this.error = false }, redirectMsgTimeOut)
  //       }
  //     },
  //       (err) => {
  //         this.backdrop = false;
  //         this.loader = false;
  //         this.error = true;
  //         this.update_data_error_msg = err.error.message;
  //         setTimeout(() => { this.error = false }, 3000)
  //       })
  //   }
  //   else //Create
  //   {
  //     var elementdata;

  //     console.log('this.elementform.value.livingCity', this.elementform.value.livingCity)
  //     if (this.elementform.value.livingCity != null) {
  //       console.log('if', this.elementform.value.livingCity)
  //       elementdata =
  //       {
  //         elementPercentage: this.elementform.value.percentage,
  //         hrmspaySlipElement: { elementId: this.elementform.value.elementName },
  //         livingCityType: { listTypeValueId: this.elementform.value.livingCity },
  //         clientId: {clientId:this.clientId}
  //       };
  //     } else {
  //       console.log('if', this.elementform.value.livingCity)
  //       elementdata =
  //       {
  //         elementPercentage: this.elementform.value.percentage,
  //         hrmspaySlipElement: { elementId: this.elementform.value.elementName },
  //         livingCityType: null,
  //         clientId: {clientId:this.clientId}
  //       };
  //     }


  //     console.log('elementdata', elementdata)
  //     this.backdrop = true;
  //     this.list_loader = true;
  //     this.tableShow=false
      
  //     this.payrollService.elementpercantagecreate(elementdata).subscribe((data: any) => {
  //       console.log(data);
        
  //       this.list_loader = false;
  //       this.tableShow=true

  //       if (data.statusCode == 200) {
  //         this.elementform.reset();
  //         this.listByElementId();
  //         this.success = true;
  //         // this.tableShow=true;
  //         this.sucess_msg = data.description;
  //         setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
  //       }
  //       else {
  //         this.elementslistData1 = [];
  //         // this.noRecordsFound = true;
  //         this.tableShow=false
  //         console.log('data.statusCode if failure', data.statusCode)
  //         this.error = true;
  //         this.error_msg = data.description;
  //         console.log(this.error_msg)
  //         setTimeout(() => { this.error = false }, redirectMsgTimeOut)
  //       }
  //     },
  //       (err) => {
  //         this.backdrop = false;
  //         this.loader = false;
  //         this.error = true;
  //         this.error_msg = err.error.message;

  //         setTimeout(() => { this.error = false }, 3000)
  //       });
  //   }
  // }

  submitData() {
    console.log('submit');
    this.submitted = true;
    this.tableShow = false;
  
    this.error = false;
    this.success = false;
  
    // Retrieve the organizationId or clientId dynamically
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData?.organization?.organizationId;
    let clientId = this.employeetData?.employee?.client?.clientId;
  
    // Determine the entity payload
    const entityData = organizationId
      ? { organization: { organizationId: organizationId } }
      : { client: { clientId: clientId } };
  
    if (this.elementPercentageId) { // Update element percentage
      let elementupdate;
  
      if (this.elementform.value.livingCity != null) {
        elementupdate = {
          elementPercentageId: this.elementPercentageId,
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: { listTypeValueId: this.elementform.value.livingCity },
          percentageStatus: this.userdata.percentageStatus,
          ...entityData // Include organization or client
        };
      } else {
        elementupdate = {
          elementPercentageId: this.elementPercentageId,
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: null,
          percentageStatus: this.userdata.percentageStatus,
          ...entityData // Include organization or client
        };
      }
  
      console.log('elementupdate', elementupdate);
      this.list_loader = true;
      this.payrollService.elementpercantageupdate(elementupdate, this.elementPercentageId).subscribe(
        (data: any) => {
          this.list_loader = false;
  
          if (data.statusCode === 200) {
            this.elementform.reset();
            this.listByElementId();
            this.success = true;
            this.sucess_msg = data.description;
            setTimeout(() => { this.success = false; }, redirectMsgTimeOut);
          } else {
            console.log("nodata", this.noRecordsFound);
            this.error = true;
            this.error_msg = data.description;
            setTimeout(() => { this.error = false; }, redirectMsgTimeOut);
          }
        },
        (err) => {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
          this.update_data_error_msg = err.error.message;
          setTimeout(() => { this.error = false; }, 3000);
        }
      );
    } else { // Create element percentage
      let elementdata;
  
      if (this.elementform.value.livingCity != null) {
        elementdata = {
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: { listTypeValueId: this.elementform.value.livingCity },
          ...entityData // Include organization or client
        };
      } else {
        elementdata = {
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: null,
          ...entityData // Include organization or client
        };
      }
  
      console.log('elementdata', elementdata);
      this.backdrop = true;
      this.list_loader = true;
      this.tableShow = false;
  
      this.payrollService.elementpercantagecreate(elementdata).subscribe(
        (data: any) => {
          console.log(data);
  
          this.list_loader = false;
          this.tableShow = true;
  
          if (data.statusCode === 200) {
            this.elementform.reset();
            this.listByElementId();
            this.success = true;
            this.sucess_msg = data.description;
            setTimeout(() => { this.success = false; }, redirectMsgTimeOut);
          } else {
            this.elementslistData1 = [];
            this.tableShow = false;
            console.log('data.statusCode if failure', data.statusCode);
            this.error = true;
            this.error_msg = data.description;
            setTimeout(() => { this.error = false; }, redirectMsgTimeOut);
          }
        },
        (err) => {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
          this.error_msg = err.error.message;
          setTimeout(() => { this.error = false; }, 3000);
        }
      );
    }
  }
  

 
  

  listElementByCategoryId(event) {
    this.payrollService.listByCategoryId(event, this.organizationId).subscribe((result: any) => {
     
      if (result.statusCode == 204) {
        // this.noRecordsFound = true;
      } else {
        this.elementslistData1 = result;
        this.elementslistData1length = this.elementslistData1.length;
        this.noRecordsFound = false;
      }

    })
  }

  listLivingCity() {
    this.payrollService.listLivingCity().subscribe((result: any) => {
      this.livingCityData = result;
      console.log('this.livingCityData', this.livingCityData)
    })
  }
  get elementName() {
    return this.elementform.get('elementName');
  }

  get categoryType() {
    return this.elementform.get('categoryType');
  }


  get orderNumber() {
    return this.elementform.get('orderNumber');
  }
  get livingCity() {
    return this.elementform.get('livingCity');
  }
  get percentage() {
    return this.elementform.get('percentage');
  }

}
