<div class="row ml-2 employee-dashboard">
  <!-- <div class="col-lg-5 col-md-12"> -->
  <mat-card class="right-card right-card-margin">
    <h4 class="text-left heading">Summary</h4>
    <div class="row">
      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-6">
        <mat-card class="card-content">
          <div class="row">
            <div class="d-flex align-items-center">
              <span class="material-icons description-icon mr-2"
                >description</span
              >
              <div class="project-count mt-n1">Projects</div>
            </div>
          </div>
          <div class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              <div class="project-count mt-project-count">
                {{ projectCount }}
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-6">
        <mat-card class="card-content">
          <div class="row">
            <div class="d-flex align-items-center">
              <span class="material-icons client-icon mr-2"
                >account_circle</span
              >
              <div class="project-count mt-n1">Clients</div>
            </div>
          </div>
          <div class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              <div class="project-count mt-project-count">
                {{ customerCount }}
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-6">
        <mat-card class="card-content">
          <div class="row">
            <div class="d-flex align-items-center">
              <span class="material-icons tick-icon mr-2">check_box</span>
              <div class="project-count mt-n1">Tasks</div>
            </div>
          </div>
          <div class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              <div class="project-count mt-project-count">
                {{ taskCount }}
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-card>

  <mat-card class="right-card">
    <div class="body-card">
      <h4 class="text-left heading">Project Status</h4>

      <!-- <mat-card class="small-cards">
        <div class="row status-content">
          <div class="col-md-2 col-sm-2">
            <div class="star-icon">
              <span class="project-status-icons">playlist_add_check</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 summary" style="text-align: left">
            Total Task
          </div>
          <div class="col-md-2 col-sm-2 badge badge">
            {{ taskCount }}
          </div>
        </div>
      </mat-card> -->

      <mat-card class="card-content">
        <div class="row">
          <div class="d-flex align-items-center">
            <div class="star-icon me-4">
              <span class="project-status-icons">playlist_add_check</span>
            </div>
            <div class="project-count mt-n1">Total Task</div>
          </div>
        </div>
        <div class="text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="project-count mt-project-count">
              {{ taskCount }}
            </div>
          </div>
        </div>
      </mat-card>

      <!-- <mat-card class="small-cards">
        <div class="row status-content">
          <div class="col-md-2 col-sm-2">
            <div class="tune-icon">
              <span class="project-status-icons">tune</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 summary" style="text-align: left">
            Pending Task
          </div>
          <div class="col-md-2 col-sm-2 badge badge">
            {{ taskPendingCount }}
          </div>
        </div>
      </mat-card> -->

      <mat-card class="card-content">
        <div class="row">
          <div class="d-flex align-items-center">
            <div class="tune-icon me-4">
              <span class="project-status-icons">tune</span>
            </div>
            <div class="project-count mt-n1 pending-task">Pending&nbsp;Task</div>
          </div>
        </div>
        <div class="text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="project-count mt-project-count">
              {{ taskPendingCount }}
            </div>
          </div>
        </div>
      </mat-card>
      <!-- 
      <mat-card class="small-cards">
        <div class="row status-content">
          <div class="col-md-2 col-sm-2">
            <div class="project-icon">
              <span class="project-status-icons material-icons"
                >insert_drive_file</span
              >
            </div>
          </div>
          <span
            class="col-md-8 col-sm-8 summary"
            style="text-align: left; font-family: 'Mulish', sans-serif"
            >Total Projects</span
          >
          <div class="col-md-2 col-sm-2 badge badge">
            {{ projectCount }}
          </div>
        </div>
      </mat-card> -->

      <mat-card class="card-content">
        <div class="row">
          <div class="d-flex align-items-center">
            <div class="project-icon me-4">
              <span class="project-status-icons material-icons"
                >insert_drive_file</span
              >
            </div>
            <div class="project-count mt-n1">Total Projects</div>
          </div>
        </div>
        <div class="text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="project-count mt-project-count">
              {{ projectCount }}
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </mat-card>
</div>

<mat-card class="client-card justify-content-center mat-width" *ngIf="projectList.length > 0">
  <div class="row p-0 m-0 justify-content-center mat-width">
    <div class="col-xl-6 col-lg-6">
      <div class="text-left heading">Project Status</div>
    </div>
    <div class="col-xl-6 col-lg-6" *ngIf="projectList.length > 5">
      <div class="select-pagination">
        <span>Show : </span>
        <select class="select_list new-select_list listDesign" [(ngModel)]="mySelect"
        [ngModelOptions]="{ standalone: true }"
        (change)="changefirstpage()">
          <option class="form-control" value="5">5</option>
          <option class="form-control" value="10">10</option>
          <option class="form-control" value="15">15</option>
          <option class="form-control" value="20">20</option>
          <option class="form-control" value="25">25</option>
        </select>
      </div>
    </div>
  </div>

  <div class="table-responsive-new1" *ngIf="projectList.length > 0">
    <table matSort class="table trDesign tablebordered">
      <thead>
        <th style="text-align: center">S.No</th>
        <th>Project Name</th>
        <th>Project Stage</th>
        <th>Project Type</th>
        <th style="text-align: center">Status</th>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let pro of projectList
              | paginate : { itemsPerPage: mySelect, currentPage: p };
            let i = index
          "
        >
          <td style="text-align: center" *ngIf="p">
            {{ (p - 1) * mySelect + (i + 1) }}
          </td>
          <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
          <td>{{ pro.projectName }}</td>
          <td>{{ pro.projectStage.projectstagename }}</td>
          <td>
            {{ pro.projectTypeName }}
          </td>
          <td>
            <p class="status status-paid">Active</p>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
    *ngIf="projectList.length > 5"
      class="text-right"
      style="float: right"
      (pageChange)="p = $event; beforesearchpage = $event"
      align="end"
    ></pagination-controls>
  </div>

  <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        <h4 align="center" class="h4">Projects Not Found</h4>
      </div>
    </div>
  </mat-card>
</mat-card>

<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="width: 3rem; height: 3rem"></div>
</div>