import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss']
})
export class AddCityComponent implements OnInit
{

  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  countryData:any;
  stateData:any;
  districtData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  cityNameExistStatus:any;
  stateList:any = [];
  districtList:any  = [];
  cityId:any;

  employeetData:any;
  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddCityComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.getLovCountries();
    this.getLovStates();
    this.getLovDistricts();

    
    this.postForm = this.formBuilder.group({
      //cityName : ['' ,[Validators.required,Validators.pattern(/^[A-Za-z0-9_+-]*$/)]],
      cityName : ['',[Validators.required]],
      country : this.formBuilder.group({
      countryId : ['',[Validators.required]]}),
      state : this.formBuilder.group({
      stateId : ['',[Validators.required]]}),
      district : this.formBuilder.group({
      districtId : ['',[Validators.required]]})
    });

    /* this.postForm = this.formBuilder.group({
      countryId : ['',[Validators.required]],
      stateId : ['',[Validators.required]],
      districtId : ['',[Validators.required]],
      cityName : ['',[Validators.required]],
    }); */

    if(this.edit_data)
    {
      this.cityId = this.edit_data.cityId;
      this.settingsservice.getCityById(this.edit_data.cityId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;
        //console.log(this.resultData.cityName);
        this.postForm.patchValue({
          cityName: this.resultData.cityName,
          country: { countryId:this.resultData.country.countryId },
          state: { stateId:this.resultData.state.stateId },
          district: { districtId:this.resultData.district.districtId },
        });
      });
    }

   
  }

  getLovCountries()
  {
    this.settingsservice.getLovCountries().subscribe((result: any) => {
      this.countryData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

  getLovStates()
  {
    this.settingsservice.getLovStates().subscribe((result: any) => {
      this.stateList = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

  getLovDistricts()
  {
    this.settingsservice.getLovDistricts().subscribe((result: any) => {
      this.districtList = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }



  // convenience getter for easy access to form fields
  get validate() { return this.postForm.controls; }

   //Submit Form
   onSubmit() //form Submit Function
   {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
     this.submitted = true;

     //stop here if form is invalid
     if (this.postForm.invalid || this.cityNameExistStatus == true) {return;}

     if(this.edit_data) //Edit
     {
       let postValues = this.postForm.value;

       postValues['organization'] = {organizationId: organizationId}
       postValues['cityId'] = this.edit_data.cityId;
       postValues['cityStatus'] = this.edit_data.cityStatus;

       this.backdrop = true;
       this.loader=true;
       this.settingsservice.editCity(this.edit_data.cityId,postValues).subscribe((user: any) =>
       {
         //this.router.navigate([ '/home/department' ]);
         //window.location.reload();

         this.backdrop = false;
         this.loader = false;
         this.success = true;

         this.dialogref.close(user);
       }, err =>{
         this.backdrop = false;
         this.loader = false;
         this.dialogref.close(err.error);
       })
     }
     else //Create
     {
        let postValues = this.postForm.value;
        postValues['organization'] = {organizationId: organizationId}

        /*if(this.postForm.valid && this.departCodeExistStatus == true || this.departNameExistStatus == true)
        {
          console.log("if condition")
          return
        }*/

        this.settingsservice.createCity(postValues).subscribe((user: any) =>
        {
          this.backdrop = false;
          this.loader = false;
          this.success = true;

          this.dialogref.close(user);
        }, err =>{
          this.backdrop = false;
          this.loader = false;
          this.dialogref.close(err.error);
       })
     }
   }


   checkExistCityName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */

    if(this.postForm.value.cityName.length != 0)
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      if(this.edit_data)
      {
        postValues['cityId'] = this.edit_data.cityId;

        this.settingsservice.updateCheckExistCityName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.cityNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.cityNameExistStatus = true;
          }
        })
      }
      else
      {
        this.settingsservice.createCheckExistCityName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.cityNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.cityNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.cityNameExistStatus = false;
    }
  }

  countryChange(event)
  {
    let countryId = event.target.value;
    if(countryId)
    {
      this.settingsservice.getCountryAgainstStateList(countryId).subscribe((resultData:any) =>
      {
        if(resultData.statusCode)
        {
          this.stateList=[];
        }
        else
        {
          this.stateList=resultData; 
        }
        this.districtList=[];
      });
    }
    else
    {
      this.stateList.length = 0;
    }
  }

  stateChange(event)
  {
    let stateId = event.target.value;
    if(stateId)
    {
      this.settingsservice.getStateAgainstDistictList(stateId).subscribe((resultData:any) =>
      {
        if(resultData.statusCode)
        {
          this.districtList.length = [];
        }
        else
        {
          this.districtList=resultData;
        }
      });
    }
    else
    {
      this.districtList = [];
    }
  }
}
