
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">PF Report</span>
                </div>
              
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="pfForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Employee</label>
                                <input type="text"matInput  placeholder="Search Employee"   
                                           formControlName="employeecode"
                                           [matAutocomplete]="auto"
                                            class="example-input form-control" (keyup)="empSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <!-- <mat-option  value="All" (click)="empAllSelect()">All
                                          </mat-option> -->
                                      <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employeeNumber}}- {{emp.firstName}}  
                                      </mat-option>

                                      <mat-option [value]="null" *ngIf="empCode && empList.length === 0" (click)="noEmployeeFound()">No
                                        Employee Found</mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:10px;right: 19px;">
                                        search
                                    </mat-icon>
                              
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Financial year <span class="star">*</span></label>
                                <mat-select formControlName="financialyear" class="form control
                                select-placeholder
                                email-input" placeholder="-- Select Financialyear ---"  style="border-radius:5px;outline:none;font-family:Mulish" (selectionChange)="selectFinancialyear()" >
                                    <mat-option [value]=null> -- Select Financialyear --- </mat-option>
                                    <mat-option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" style="color: #000;">{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</mat-option>  
                                </mat-select>
                            </div>
                            <div class="form-group col-md-3 pl-1">
                                <label class="form-control-label">Financial Period <span class="star">*</span></label>
                                <mat-select formControlName="fromperiod" class="form control
                                select-placeholder
                                email-input" style="border-radius:5px;outline:none;font-family:Mulish" placeholder=" -- Select From Period ---">
                                    <mat-option [value]=null> -- Select From Period --- </mat-option>
                                    <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}" style="color: #000;">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option> 
                                </mat-select>
                            </div>
                            <div class="col-md-3 pl-1" style="margin-top: 19px;" >
                                <!-- <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button> -->
                                    <button type="submit" mat-raised-button
                                    class="searchBtn mat-raised-button mat-button-base mr-2 custom-button" [disabled]="isProcessing"
                                    >
                                    Search
                                </button>
                                        <!-- <button style="margin-left: 10px;" type="reset" mat-raised-button
                                    class="btn mr-2 search-btn" (click)="clearTable()"
                                    >Clear</button> -->
                                    <button mat-stroked-button type="reset" class="apply-buttons" (click)="clearTable()" [disabled]="isProcessing"
                                  
                                    style="margin: 8px 0px 0px 5px;background:white;border: 1px solid #ddd;position: relative ;
                                    top: -8%;  font-family: Mulish; color: black; font-size: 16px; font-weight: 610;">Clear</button>
                            </div>
                            <!-- <div class="form-group col-md-3 pl-1">
                                <select formControlName="toperiod" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]=null> -- Select To Period --- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option> 
                                </select>
                            </div> -->
                           
                        </div>
                        <div class="row">
                            <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                                <button  class="but mb-2" type="button" style="color: white;"   (click)="downloadAsExcel();">
                                    <mat-icon class="material-symbols-outlined">file_download</mat-icon> Download Excel
                                </button>
                              
                            </div>
                            <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                                <button  class="but ml-2 mb-2" type="button"  style="color: white;"  (click)="printPdf();" >
                                    <mat-icon class="material-symbols-outlined" >print</mat-icon> Download PDF
                                </button>
                            </div>
                        </div>
                    </form>
                    <!-- <div class="row pb-3">
                        <div class="col-md-11 text-right">
                            <p class="show-count">Show</p>
                        </div>
                        <div class="col-md-1 text-left pl-0">
                            <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                   </div> -->
                <div  *ngIf="show"  class="table-responsive">
                    <p><b>PF REPORT</b></p>
                    <table class="table  --table-bordered table-border-new">
                        <thead class="t-head">
                            <tr>
                                <th style="white-space: nowrap;">Employee Code</th>
                                <!-- <th style="white-space: nowrap;">PF Number</th> -->
                                <th style="white-space: nowrap;">Employee Name</th>
                                <!-- <th style="white-space: nowrap;">Worked Days</th> -->
                                <th style="white-space: nowrap;">Earned Wages</th>
                                <th style="white-space: nowrap;">Period</th>
                                <th style="white-space: nowrap;">Employee PF</th>
                                <th style="white-space: nowrap;">Employer PF</th>
                                <th style="white-space: nowrap;">PF Total Amount</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData.length>0">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td style="text-align: center;">{{row.employee.employeeNumber}}</td> 
                                <!-- <td>{{row.employee.employeeNumber}}</td>  -->
                                <td style="white-space: nowrap;">{{row.employee.firstName}}</td>
                                <!-- <td>{{row.employee.firstName}}-}</td> -->
                                <td style="text-align: center;">{{Roundof(row.totalAmount)}}</td>
                                <td style="white-space: nowrap;text-align: left;">{{row.finanicalPeriod.financialperiodMonth}}-{{row.finanicalPeriod.financialPeriodYear}}</td>
                                <td style="text-align: center;">{{row.employeePF?.toFixed(2)}}</td>
                                <td style="text-align: center;">{{row.employeerPF?.toFixed(2)}}</td>
                                <td style="text-align: center;">{{row.totalPF?.toFixed(2)}}</td>
                            </tr>
                            <tr>
                                <td colspan="3"></td> 
                                <td style="text-align: right;"><b>Total</b></td> 
                                <td style="text-align: center;"><b>{{employeePFval?.toFixed(2)}}</b></td> 
                                <td style="text-align: center;"><b>{{employeerPF?.toFixed(2)}}</b></td>
                                <td style="text-align: center;"><b>{{totalPF?.toFixed(2)}}</b></td> 
                            </tr>
                        </tbody> 
                        <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="7" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr> 
                        </tbody> 
                    </table>
                 </div>

                 <div *ngIf="resultData.length>0 && show">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<section>
    <style>
        table tr, td
        {
            font-family: 'Helvetica',sans-serif !important;
            
        }
        .table-class {
            font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
            font-size: 11px;
        }
        
        .table-class td, .table-class th {
            border: 1px solid #8b8b8b;
            padding: 6px;
            font-size: 11px;
            font-weight: 400;
        }
        
        .table-class tr:nth-child(even){background-color: #fff;}
        
        .table-class tr:hover {background-color: #ddd;}
        
        .table-class th {
            padding-top: 6px;
            padding-bottom: 6px;
            text-align: left;
            background-color: #fff;
            color: #000;
            text-align: center;
        }
         tr.th-background th{background:#ddd;border:1px solid #b7b7b7;font-size:11px;}
        tbody.fields-td-new tr td{font-size:11px;}
    </style>
    
    <div  style="display: none;" id="pdfTable" #pdfTable>
        <div class="common-content" ><!-- Content start-->
            <div class="card" ><!-- Card start-->
                <div class="card-body"  >
                    <table class="table-class" data-pdfmake="{'widths':['*','*'],'heights':10}">
                        <thead>
                            <tr>
                                <td class="text-center" width="40%">
                                    <img [src]="base64data" style="height:65px;width:180px;">
                                </td>
                                <td class="float-left" width="80%">
                                    <p style="font-size:18px;font-weight:600;text-align:left"><b style="text-align:left">{{ this.usertData.organization.organizationName}}</b></p>
                                    <span style="padding-top:0px;float:left;width:100%;">{{ this.usertData.organization.address}}</span><br>
                                    <span style="padding-top:8px;float:left;width:100%;">PF : ER7824KLJF45</span><br>
                                </td>
                            </tr>
                        </thead>
                    </table> 
                        
                    <table    class="table-class"  data-pdfmake="{'widths':['*'],'heights':10}">
                        <thead>
                            <tr>
                                <td  style="text-align:center;font-size:14px;padding:10px;">
                                    <b>EMPLOYEE PROVIDENT FUND SCHEME  </b>
                                    <br><br>
                                     <span style="font-weight:500">STATEMENT FOR THE PERIOD OF  {{period}}</span>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <table class="table-class"  data-pdfmake="{'widths':['auto','auto','auto','auto','auto','auto','*','*'],'heights':10}">
                        <thead class="bg-secondary">
                            <tr class="th-background">
                                <th>#</th>
                                <th class="text-center" style="text-align:left">Employee Code</th>
                                <th class="text-center" style="text-align:left">UAN Number</th>
                                <th class="text-center">Employee Name</th>
                                <th class="text-center" style="text-align:center">Worked Days</th>
                                <th class="text-center">Earned Wages</th>
                                <th class="text-center">Period</th>
                                <th class="text-center">Employee PF</th>
                                <!-- <th class="text-center">Employer PF</th> -->
                                <!-- <th class="text-center">PF Total Amount</th> -->
                            </tr>
                        </thead>
                            
                        <tbody *ngIf="resultData.length>0">
                                    <tr  *ngFor="let row of resultData  let i=index; ">
                                        <td class="tab-medium-width text-center" style="text-align:center">
                                          {{i+1}}
                                        </td>
                                        <td class="tab-medium-width" style="text-align:center">
                                            {{row.employee.employeeNumber}}
                                        </td>
                                        <td class="tab-medium-width" style="text-align:center">
                                            {{row.employee.uanNumber}}
                                        </td>
        
                                        <td class="tab-medium-width">
                                            {{row.employee.firstName}}
                                        </td>
                                        <td class="tab-medium-width" style="text-align:center">
                                           {{getempworkingdays(row)}}
                                        </td>
                                        <td class="tab-medium-width " style="text-align:right">
                                                {{row.ctc?.toFixed(2)}}
                                        </td>
                                        <td class="text-right text-center" style="text-align:center">
                                            {{row.finanicalPeriod.financialperiodMonth}}-{{row.finanicalPeriod.financialPeriodYear}}
                                        </td>
                                       
                                        
                                        <td class="tab-medium-width text-right" style="text-align:right">
                                            {{row.employeePF?.toFixed(2)}}
                                        </td>
                                        <!-- <td class="tab-medium-width text-right" style="text-align:right">
                                            {{row.employeerPF?.toFixed(2)}}
                                        </td> -->
                                        <!-- <td class="tab-medium-width text-right" style="text-align:right">
                                            {{ row.totalPF?.toFixed(2)}}
                                        </td> -->
                                            
                                            
                                    </tr>
                                
                            
                        
                                    <tr>
                                        
                                        <td colspan="7" class="text-left px-2" style="text-align:right"> 
                                            <b>Total</b>
                                        </td>
                                        <td class="text-right px-2" style="text-align:right">
                                            <b>{{employeePFval?.toFixed(2)}}</b>
                                        </td>
                                        
                                        <!-- <td class="text-right px-2" style="text-align:right">
                                            <b>
                                                {{employeerPF?.toFixed(2)}}
                                            </b>
                                        </td>
         -->
                                        <!-- <td class="text-right px-2" style="text-align:right">
                                            <b> {{totalPF?.toFixed(2)}}</b>
                                        </td> -->
                                        
                                    </tr>
                                
                        </tbody>
                    </table>	
                            <!-- <span style='margin:15px 0px;float:left;width:100%;'> <b>Amount In Words :</b> Rupees <?php echo amountInWords($netPay);?></span> -->
                        
                    </div>
            </div><!-- Card end-->
            
        </div>
    </div>
    
 </section>
 <mat-card style="margin-top: 22%; " class="card-content-nodata mat-card-nodata" *ngIf="noRecordsFound">
    <div class="card-body-nodata">
      <div class="alert-nodata --alert-primary">
        <h4 align="center" class="h4" style=" padding:10px;;   margin-top: 14px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 11px;
        ">No Records Found</h4>
      </div>
    </div>
  </mat-card>



  <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>