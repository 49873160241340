import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { LeaveService } from 'src/app/providers/leave.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

// import { WorkingDays } from '../../dashboard/dashboard.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-apply-work-from-home',
  templateUrl: './apply-work-from-home.component.html',
  styleUrls: ['./apply-work-from-home.component.scss']
})
export class ApplyWorkFromHomeComponent implements OnInit {
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() dateInput: EventEmitter<MatDatepickerInputEvent<any>>;

  matcher = new MyErrorStateMatcher();
  noOFDays: any = 0
  WorkingDays: any;
  endDate_disable: boolean;
  min: any;
  startMin:any;
  isLoading: Boolean;
  myFilter: any;
  workfromHomeFrom: FormGroup;
  first_approver:string;
  first_approver_id:number;
  second_approver:string;
  second_approver_id:number;
  secondError:boolean;
  arrayData:any =[]
  branchId:any;
  workDay:any =[];
  From_Date:any;
  End_Date:any;
  halfDay: boolean;
  weekDay: boolean;
  show_error_msg: boolean=false;
  empId: any;
  currentyear: any;
  submitted = false;
  error: any = false;
  success = false;
  success_msg: string;
  error_msg: any;
  searchButton:boolean=true;



  constructor(
    private fb: FormBuilder,
    private gs: GlobalserviceService,
    private leaveservice:LeaveService,
    private router: Router,
    private datepipe:DatePipe,
    private location: Location
  ) { }

  ngOnInit(): void {
    // this.startMin = new Date();
    this.endDate_disable = true;
    this.workfromHomeFrom = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      reason: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      workFromHomeStatus: ['', Validators.required],
      // applyTo: new FormControl(''),
      // cc: new FormControl(''),
      // employee: this.fb.group({
        employeeId: [''],
      //   employeeName: ['']
      // }),
      category: this.fb.group({
        categoryId: ['']
      })
    });
    this.min = new Date(this.f.startDate.value);

    // defaults
    this.f.workFromHomeStatus.setValue(3);
    this.workfromHomeFrom.patchValue({
      category: {
        categoryId: 1
      }
    })

    //  empdata
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let id = x.employee.employeeId;
    this.empId = x.employee.employeeId;


      // this.second_approver = d.reportingToSecond;
      this.workfromHomeFrom.patchValue({
        // employee: {
          employeeId:x.employee.employeeId,
        //   employeeName: x.employee.firstName +' '+ x.employee.lastName,
        // }
      })
      this.leaveservice.getEmpListById(this.empId).subscribe(
        (x:any)=>{
          this.branchId = x.branch?.branchId;

      if(this.branchId){
        const currentDate = new Date();
        this.currentyear = currentDate.getFullYear();
        // this.leaveservice.getCalByBranch(this.branchId).subscribe(
          this.leaveservice.getCalByBranchAndyearname(this.branchId,this.currentyear).subscribe(
          (dato: any) => {
            dato.map(
              log => {
                this.leaveservice.getCalendarById(log.calendarId).subscribe(
                  (i: any) => {
                    var exception
                    let data
                    i.exceptions.map(
                      b => {
                        if(b.exceptionStatus == 1){
                          if(b.exceptionType != '41'){
                            exception = b.exceptionDate
                            data = new Date(b.exceptionDate)
                            data.setDate(data.getDate());
                            this.arrayData.push(data)
                          }
                        }

                      }
                    )

                    i.holiday.map(a => {
                      if(a.holidayStatus == 1){
                        data = new Date(a.holidayDate)
                        data.setDate(data.getDate());
                        this.arrayData.push(data)
                      }
                    })

                    let b = ["1","2","3","4","5","6","0"]
                    i.calendarWorkingDays.map(dot => {
                      const workingDays = b.filter((dayCode) => {
                        const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
                        return dayNumber < dot.workingdays;
                      })
                      .map((dayCode) => dayCode);
                      dot['calendarworkingdays'] = workingDays;
                      console.log("dot.workingdays", dot.calendarworkingdays);

                      var difference:any = b.filter(x => dot.calendarworkingdays.indexOf(x) === -1);
                      this.workDay = difference
                      this.myFilter = (d: Date): boolean => {
                        const day = new Date(d).getDay();
                        const time = new Date(d).getTime()
                        return day !== Number(difference[0]) && day !== Number(difference[1])
                        && day !== Number(difference[2]) && day !== Number(difference[3])
                        && day !== Number(difference[4]) && day !== Number(difference[5])
                        && day !== Number(difference[6])&& !this.arrayData.find(x => new Date(x).getTime() == time)
                      }
                    })
                  }
                )
              }
            )

          }
        )
      }
    })

  }
  Cancel(){
    this.location.back();
  }
  get f() {
    return this.workfromHomeFrom.controls;
  }

  applyWFH() {
    this.searchButton=true;

    let x = this.workfromHomeFrom.value;
    this.submitted =true;
    this.isLoading = false;

    if(this.workfromHomeFrom.invalid || this.invalidInput) {
      this.searchButton=true;

      return;
    }

    x['noOfDays'] = this.noOFDays
    let submit = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    x['submittedOn'] = submit;
    x['status'] = 1;

    let a = this.f.startDate.value;
    // startDate
    if (x) {
      let b = new Date(a);
      b.setMinutes(b.getMinutes() + 480);
      let s = b.toISOString()
      x['startDate'] = s
    }
    // endDate
    let c = this.f.endDate.value;
    if(c) {
      let d = new Date(c);
      d.setMinutes(d.getMinutes() + 480);
      let e = d.toISOString();
      x['endDate'] = e;
    }
    x['createdBy'] = this.empId;
    x.employeeId=this.empId

    console.log(JSON.stringify(x));
    this.savedata(x)
  }
  invalidInput: boolean = false;

  validateInput(event: any) {
      const inputElement = event.target;
      let value = inputElement.value;
  
      if (value.startsWith(' ')) {
          value = value.trimStart();
          inputElement.value = value;
      }
  
      this.invalidInput = value.trim().length === 0; // Check if the input consists of only spaces
  }
  private savedata(x) {
    Swal.fire({
      title: 'Are you sure? Do you want to Apply!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Apply'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.searchButton=false;

        this.leaveservice.addWfm(x).subscribe(
          d=> {
            // Swal.fire( {
            //   text: 'Applied',
            //   icon: 'success'
            // }
            // );
            console.log((d as any).message);
            this.success = true;
            this.success_msg =(d as any).message;
            this.isLoading = false;
            this.searchButton=false;

            setTimeout(() => { this.success = false;  this.router.navigate(['/home/mywfh-list'])
          }, 3000);
            // this.router.navigate(['/home/mywfh-list']);
          },
          err => {
            console.log(err)
            // Swal.fire(
            //     {
            //       text: err.error.message,
            //       icon: 'error'
            //     }
            //   )
            this.error = true;
            this.error_msg = err.error.message;
            this.isLoading = false
            this.searchButton=true;


      setTimeout(() => { this.error = false; }, 3000); 
          },
          () => {
            console.log('done')
            this.isLoading = false
            this.searchButton=false;

          }
        )
      }
    })
  }

  someMethodName(date: any) {
    this.min = new Date(date.value);
    this.endDate_disable = false

    let w = this.datepipe.transform(this.f.endDate.value, 'YYYY-MM-dd');
    let v = this.datepipe.transform(this.min, 'YYYY-MM-dd');
    if(w != null){
      if(w < v){
        this.show_error_msg = true;
      }
    }
  }

  // number of days
  nofoDays(i) {
    if (this.f.startDate.value>this.f.endDate.value) {
      console.log("date In Vaild");
      return
    }
    this.show_error_msg = false;
    console.log("No of Days in Wfh");
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value)
    console.log(start);
    console.log(end);
    let v = this.datepipe.transform(start, 'YYYY-MM-dd');
    let w = this.datepipe.transform(end, 'YYYY-MM-dd');
    console.log(v);
    console.log(w);
     this.From_Date=v;
     this.End_Date=w;
     if (v != w) {
      this.weekDay = true;
      this.halfDay = false;
    }
    if (v == w) {
      this.halfDay = true;
      this.weekDay = false;
    }
    // Copy date objects so don't modify originals=
    var s: any = new Date(+start);
    var e: any = new Date(+end);
    console.log(s);
    console.log(e);
    this.From_Date=s;
    console.log(this.From_Date);
    this.End_Date=e;
    console.log(this.End_Date);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12, 0, 0, 0);
    e.setHours(12, 0, 0, 0);

    // Get the difference in whole days
    if(e!=0&&s!=0)
    {
      console.log("e to s");
      var totalDays = Math.round((e - s) / 8.64e7);
      console.log(totalDays);
    }
    else{
      console.log("s to e");
      var totalDays = Math.round((s - e) / 8.64e7);
      console.log(totalDays);
    }

    // var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    // Estimate business days as number of whole weeks * 5
    var days = 0
    days = wholeWeeks * this.WorkingDays + 1;
    // console.log(days)
    let dateArray = new Array()
    let result = new Array()
    while (start <= end) {
      dateArray.push(new Date(start));
      start.setDate(start.getDate() + 1);
    }
    dateArray.map(
      c => {
        if (this.arrayData) {
          this.arrayData.map(
            t => {
              let MyDate = new Date(c)
              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)

              let MyDate1 = new Date(t)
              let MyDateString1 = MyDate1.getFullYear() + '-' + ('0' + (MyDate1.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate1.getDate()).slice(-2)
              if (MyDateString1 == MyDateString) {
                result.push(t)
              }
            }
          )
        }
      }
    )

    // If not even number of weeks, calc remaining weekend days

    s.setDate(s.getDate() + wholeWeeks * 7);
    let dat = new Array()

    dateArray.map(
      e => {
        if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
        && e.getDay() != this.workDay[3]  && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
        && e.getDay() != this.workDay[6]){
          dat.push(e)
        }
        // if (this.WorkingDays == 5){
        //   if (e.getDay() != 0 && e.getDay() != 6) {
        //     dat.push(e)
        //   }
        // }
        // else{
        //   if (e.getDay() != 0 && e.getDay() != 7) {
        //     dat.push(e)
        //   }
        // }

      }
    )

    let uniqueArray = result
      .map(function (date) { return date.getTime() })
      .filter(function (date, i, array) {
        return array.indexOf(date) === i;
      })
      .map(function (time) { return new Date(time); });

      let uniqueArray1 = new Array()

      uniqueArray.map(
        e=>{
          if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
          && e.getDay() != this.workDay[3]  && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
          && e.getDay() != this.workDay[6]){
            uniqueArray1.push(e)
          }
        }
      )

      if (uniqueArray1.length > dat.length) {
        days = uniqueArray1.length - dat.length
      }
      if (dat.length > uniqueArray1.length) {
        days = dat.length - uniqueArray1.length
      }

    this.noOFDays = days;
    return days;

  }
}

