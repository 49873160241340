import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Inject,
  ViewChild,
  NgZone,
} from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { AddProjectComponent } from '../add-project/add-project.component';
import { EmpListComponent } from '../emp-list/emp-list.component';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { ProjectsService } from 'src/app/providers/projects.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/providers/project.service';
import { Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { HeaderService } from 'src/app/providers/header.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  @ViewChild(AddProjectComponent) addProjectComponent: AddProjectComponent;
  @ViewChild('successMessageElement') successMessageElement: any;
  selectedListTypeValueId: number;
  selectedListTypeValueName: string;
  projectList: any[] = [];
  empProjectList: any[] = [];
  isData: boolean;
  error: boolean = false;
  error_msg: string = '';
  user_type: string;
  search: any;
  activeStatus: any[];
  employeeList: any;
  p: any;
  menuData: any = [];
  empId: any;
  nodata = false;
  loader = true;
  projectStatus: any;
  projectStatusByEmployee:any;
  finalProjectStatus: any;
  percentage: number;
  projectStatusCheck: any;
  listTypeValueName: any;

  percentageByEmployee: number;
  projectStatusCheckByEmployee: any;
  listTypeValueNameByEmployee: any;
  successMessage: string = '';
  private successMessageSubscription: Subscription;
  private successUpdateMessageSubscription: Subscription;
  successUpdate: Boolean;
  successUpdateMessage: string;
  secondProjectList: any[] = [];
  project: any;
  organizationId: number;
  success: boolean = false;
  warning: boolean = false;
  warningMessage: string = '';
  employeeID: number;
  filterByProjectStatus: number;
  filterByProjectStatusEmployeeProjects: number;
  buttonDisable: boolean = true;
  roleNames: any[] = [];
  filterByProjectStatusByEmployee:number;
  usertData: any;
  userType: any;
  isHumanResource:any;
  isRequirements: any;
  isAdmin: any;
  isEmployee:any;
  isUserAdmin:boolean = false;
  constructor(
    private gs: ProjectManagementService,
    private router: Router,
    public dialog: MatDialog,
    private projectStatusService: ProjectsService,
    private projectService: ProjectService,
    private ngZone: NgZone,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
  ) {
    this.projectStatusService.getProjectStatus().subscribe((data: any) => {
      console.log('addProjectComponent', data);
    });
  }

  truncateProjectCode(code: string): string {
    return code.length > 13 ? code.substring(0, 13) + '...' : code;
  }

  truncateProjectCode1(code: string): string {
    return code.length > 6 ? code.substring(0, 6) + '...' : code;
  }

  projectStage(code: string): string {
    return code.length > 13 ? code.substring(0, 13) + '...' : code;
  }
  ngOnInit() {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.userType = [];

    if(this.usertData?.employee != undefined){
      if (this.usertData.employee.role != undefined) {
        // Populate userType array with roles
        this.usertData?.employee?.role?.forEach((role) => {
          this.userType.push(role?.roleName);
        });
        // Check if the user is an Admin or Recruiter
        this.isAdmin = this.userType.includes('Admin');
        this.isRequirements = this.userType.includes('Recruiter');
        this.isEmployee = this.userType.includes('Employee');
        this.isHumanResource = this.userType.includes('Human Resource');
      }
    }
    else{
      if (this.usertData.role != undefined) {
        this.usertData?.role?.forEach((role) => {
          this.userType.push(role?.roleName);
        });
        this.isUserAdmin = true;
      }
    }
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage('');
    this.timesheet.setSuccessUpdateMessage('');
    this.headerservice.setTitle('');
    // this.headerservice.setTitle('Projects');
    this.successMessageSubscription = this.projectService
      .getSuccessMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        if (message) {
          this.success = true;
          this.successMessage = message;
        }

        // Close the success message after 3 seconds (adjust duration as needed)
        window.setTimeout(() => {
          this.success = false;
          this.closeSuccessMessage();
        }, 3000);

        console.log('Success message:', message);

        // const timer$ = timer(300).pipe(take(1));
        // timer$.subscribe(() => {
        //   this.closeSuccessMessage();
        // });
      });
    this.successMessageSubscription = this.projectService
      .getErrorMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        if (message) {
          this.error = true;
          this.error_msg = message;
        }

        // Close the success message after 3 seconds (adjust duration as needed)
        setTimeout(() => {
          this.success = false;
          this.error = false;
          this.closeSuccessMessage();
        }, 3000);

        console.log('Success message:', message);

        // const timer$ = timer(300).pipe(take(1));
        // timer$.subscribe(() => {
        //   this.closeSuccessMessage();
        // });
      });

    this.successUpdateMessageSubscription = this.projectService
      .getSuccessUpdateMessage()
      .subscribe((message) => {
        if (message) {
          this.showUpdateSuccessMessage(message);
        }
      });

    window.setTimeout(() => {
      this.successUpdate = false;
      this.closeUpdateSuccessMessage();
    }, 3000);

    this.gs.projectStatus().subscribe((data: any) => {
      this.projectStatus = data;
      this.projectStatusByEmployee = data;

      console.log('this.projectStatus', this.projectStatus);
    });

    //   projectCompletionPercentage
    //   below 100,
    //  project status is closed
    //  then percentage should be 100%

    this.isData = false;
    const Data = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('Data', Data);

    this.roleNames = Data.employee.role;
    console.log('this.roleNames', this.roleNames);
    const hasLongName = this.roleNames.some(
      (r) =>
        r.roleName === 'Admin' ||
        r.roleName === 'HumanResource' ||
        r.roleName === 'Manager' ||
        r.roleName === 'TeamLeader'
    );

    console.log('hasLongName', hasLongName);

    this.organizationId = Data.organization.organizationId;
    console.log('dataaa', Data);

    if (Data && Data.employee) {
      this.empId = Data.employee.employeeId;
    } else {
      // Handle the case when Data or Data.employee is undefined
      console.error('Data or Data.employee is undefined.');
    }
    // this.gs.listProjectList(this.organizationId).subscribe(
    //   (d: any[]) => {
    //     this.loader = false;

    //     console.log('d;;;;', d);

    //     this.projectList = d.sort((a, b) =>
    //       a.projectName.localeCompare(b.projectName)
    //     );
    //     console.log('this.projectList::', this.projectList);

    //     for (let project of this.projectList) {
    //       this.projectStatusCheck = project.projectStatus;

    //       for (const key in this.projectStatusCheck) {
    //         if (this.projectStatusCheck.hasOwnProperty('listTypeValueName')) {
    //           this.listTypeValueName =
    //             this.projectStatusCheck['listTypeValueName'];
    //         }
    //       }

    //       if (
    //         (project.projectCompletionPercentage < 100 &&
    //           this.listTypeValueName !== 'Closed') ||
    //         project.projectStatus?.listTypeValueId != 48
    //       ) {
    //         project.projectCompletionPercentage;
    //         console.log('this.percentage', this.percentage);
    //         // window.location.reload();
    //       } else if (
    //         (project.projectCompletionPercentage < 100 &&
    //           this.listTypeValueName === 'Closed') ||
    //         project.projectStatus?.listTypeValueId === 48
    //       ) {
    //         this.percentage = 100;
    //         project.projectCompletionPercentage = 100;
    //         console.log(
    //           'percentage:',
    //           project.projectCompletionPercentage,
    //           this.listTypeValueName
    //         );
    //         // window.location.reload();
    //       } else {
    //         this.percentage = project.projectCompletionPercentage;
    //         // window.location.reload();
    //       }
    //     }

    //     // ...

    //     // if (this.projectList.length == 0) {
    //     //   this.nodata = true;
    //     //   console.log('nodata');
    //     // }

    //     if ((d as any).errorCode === 204) {
    //       this.nodata = true;
    //     }
    //     this.secondProjectList = d.sort((a, b) =>
    //       a.projectName.localeCompare(b.projectName)
    //     );
    //   },
    //   (err) => {
    //     this.nodata = true;
    //     this.loader = false;
    //     if (err.error.errorCode === 204) {
    //       this.nodata = true;
    //     }
    //     if (err.errorCode === 204) {
    //       this.nodata = true;
    //     }
    //     if (err.status == 404) {
    //       this.nodata = true;
    //       console.log('404');
    //     }
    //   }
    // );

    if (hasLongName) {
      this.gs.listProjectList().subscribe(
        (d: any[]) => {
          this.loader = false;

          console.log('data::::', d);
          // Set the default value to true
          const defaultListTypeValueId = 46;


          if ((d as any).statusCode === 204) {
            this.nodata = true;
          }
          this.projectList = d.filter((res) => {
            this.filterByProjectStatus = 46;

            console.log(
              'this.filterByProjectStatus',
              this.filterByProjectStatus
            );

            return (
              res.projectStatus?.listTypeValueId === defaultListTypeValueId &&
              res.projectStatus?.listTypeValueName === 'Open'
            );
          });
          // this.filterByProjectStatus = res.projectStatus?.listTypeValueName === "Open";
          console.log('this.projectList::', this.projectList);

          this.projectList = this.projectList.sort((a, b) =>
            a.projectName.localeCompare(b.projectName)
          );

          for (let project of this.projectList) {
            this.projectStatusCheck = project.projectStatus;
            this.listTypeValueName = this.projectStatusCheck.listTypeValueName;

            this.percentage = project.projectCompletionPercentage;

            if (
              (project.projectCompletionPercentage < 100 &&
                this.listTypeValueName !== 'Closed') ||
              project.projectStatus?.listTypeValueId !== 48
            ) {
              console.log('this.percentage', this.percentage);
            } else if (
              (project.projectCompletionPercentage < 100 &&
                this.listTypeValueName === 'Closed') ||
              project.projectStatus?.listTypeValueId === 48
            ) {
              this.percentage = 100;
              project.projectCompletionPercentage = 100;
              console.log(
                'percentage:',
                this.percentage,
                this.listTypeValueName
              );
            } else {
              this.percentage = project.projectCompletionPercentage;
            }
          }

          if (this.projectList.length === 0) {
            this.nodata = true;
            console.log('nodata');
          }

          this.secondProjectList = d.sort((a, b) =>
            a.projectName.localeCompare(b.projectName)
          );
        },
        (error) => {
          this.nodata = true;
          this.loader = false;

          if (error.status === 404) {
            this.nodata = true;
            console.log('404');
          }
        }
      );
    }

    if (!hasLongName) {
      console.log('this.empId', this.empId);

      // this.gs.getProjectsByEmployee(this.empId).subscribe(
      //   (d: any[]) => {

      //       this.loader = false;

      //       console.log('data::::', d);
      //       // Set the default value to true
      //       const defaultListTypeValueId = 46;

      //       this.empProjectList = d.filter((res) => {
      //         this.filterByProjectStatus = 46;

      //         console.log(
      //           'this.filterByProjectStatus',
      //           this.filterByProjectStatus
      //         );

      //         return (
      //           res.projectStatus?.listTypeValueId === defaultListTypeValueId &&
      //           res.projectStatus?.listTypeValueName === 'Open'
      //         );
      //       });
      //       // this.filterByProjectStatus = res.projectStatus?.listTypeValueName === "Open";
      //       console.log('this.projectList::', this.projectList);

      //       this.empProjectList = this.empProjectList.sort((a, b) =>
      //         a.projectName.localeCompare(b.projectName)
      //       );

      //       for (let project of this.empProjectList) {
      //         this.projectStatusCheck = project.projectStatus;
      //         this.listTypeValueName = this.projectStatusCheck.listTypeValueName;

      //         this.percentage = project.projectCompletionPercentage;

      //         if (
      //           (project.projectCompletionPercentage < 100 &&
      //             this.listTypeValueName !== 'Closed') ||
      //           project.projectStatus?.listTypeValueId !== 48
      //         ) {
      //           console.log('this.percentage', this.percentage);
      //         } else if (
      //           (project.projectCompletionPercentage < 100 &&
      //             this.listTypeValueName === 'Closed') ||
      //           project.projectStatus?.listTypeValueId === 48
      //         ) {
      //           this.percentage = 100;
      //           project.projectCompletionPercentage = 100;
      //           console.log(
      //             'percentage:',
      //             this.percentage,
      //             this.listTypeValueName
      //           );
      //         } else {
      //           this.percentage = project.projectCompletionPercentage;
      //         }
      //       }

      //       if (this.empProjectList.length === 0) {
      //         this.nodata = true;
      //         console.log('nodata');
      //       }

      //       this.secondProjectList = d.sort((a, b) =>
      //         a.projectName.localeCompare(b.projectName)
      //       );

      //   },
      //   (err) => {
      //     // this.nodata = true;
      //     this.loader = false;
      //     console.log('error');
      //     if (err.status == 404) {
      //       this.nodata = true;
      //       console.log('404');
      //     }

      //     if (err.error.errorCode === 204) {
      //       this.nodata = true;
      //     }
      //     if (err.errorCode === 204) {
      //       this.nodata = true;
      //     }
      //   }
      // );
      this.gs.getProjectsByEmployee(this.empId).subscribe(
        (d: any[]) => {
          this.loader = false;
          const defaultListTypeValueId = 46;
          console.log("d",d);
          this.empProjectList = d.filter((res) => {
            this.filterByProjectStatusEmployeeProjects = 46;
            return (
              res.projectStatus?.listTypeValueId === defaultListTypeValueId &&
              res.projectStatus?.listTypeValueName === 'Open'
            );
          });
          this.empProjectList = this.empProjectList.sort((a, b) =>
            a.projectName.localeCompare(b.projectName)
          );

          for (let project of this.empProjectList) {
            this.projectStatusCheckByEmployee = project.projectStatus;
            this.listTypeValueNameByEmployee =
              this.projectStatusCheckByEmployee.listTypeValueName;

            this.percentageByEmployee = project.projectCompletionPercentage;

            if (
              (project.projectCompletionPercentage < 100 &&
                this.listTypeValueNameByEmployee !== 'Closed') ||
              project.projectStatus?.listTypeValueId !== 48
            ) {
              console.log('this.percentage', this.percentageByEmployee);
            } else if (
              (project.projectCompletionPercentage < 100 &&
                this.listTypeValueNameByEmployee === 'Closed') ||
              project.projectStatus?.listTypeValueId === 48
            ) {
              this.percentageByEmployee = 100;
              project.projectCompletionPercentage = 100;
              console.log(
                'percentage:',
                this.percentageByEmployee,
                this.listTypeValueName
              );
            } else {
              this.percentageByEmployee = project.projectCompletionPercentage;
            }
          }
          console.log('this.empProjectList:::', this.empProjectList);
          if (this.empProjectList.length == 0) {
            this.nodata = true;
            console.log('nodata');
          }

          if ((d as any).errorCode === 204) {
            this.nodata = true;
          }
        },
        (err) => {
          // this.nodata = true;
          this.loader = false;
          console.log('error');
          if (err.status == 404) {
            this.nodata = true;
            console.log('404');
          }

          if (err.error.errorCode === 204) {
            this.nodata = true;
          }
          if (err.errorCode === 204) {
            this.nodata = true;
          }
        }
      );
    }

    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    {
      this.User_Type(x);
    }
  }

  onProjectStatusChange(event: MatSelectChange): void {
    this.loader = true;
    this.nodata = false;
    this.projectList = [];
    this.search = '';
    this.selectedListTypeValueId = event.value;
    this.selectedListTypeValueName = this.projectStatus.find(
      (project) => project.listTypeValueId === this.selectedListTypeValueId
    )?.listTypeValueName;

    this.gs.getProjectList().subscribe(
      (d: any[]) => {
        this.loader = false;

        console.log('data::::', d);
        this.projectList = d.filter((res) => {
          // this.projectList.length = 0;
          this.nodata = false;
          console.log(
            'project status',
            res.projectStatus?.listTypeValueId === this.selectedListTypeValueId
          );

          return (
            res.projectStatus?.listTypeValueId === this.selectedListTypeValueId
          );
        });
       

        // this.empProjectList = d.filter((res) => {
        //   // this.projectList.length = 0;
        //   this.nodata = false;
        //   console.log(
        //     'project status',
        //     res.projectStatus?.listTypeValueId === this.selectedListTypeValueId
        //   );

        //   return (
        //     res.projectStatus?.listTypeValueId === this.selectedListTypeValueId
        //   );
        // });
        console.log('this.projectList::', this.projectList);

        this.projectList = this.projectList.sort((a, b) =>
          a.projectName.localeCompare(b.projectName)
        );

        // this.empProjectList = this.empProjectList.sort((a, b) =>
        //   a.projectName.localeCompare(b.projectName)
        // );


        if (this.projectList.length > 0) {
          this.nodata = false;
        }
        if (this.projectList.length === 0) {
          this.nodata = true;

        }

        for (let project of this.projectList) {
          this.projectStatusCheck = project.projectStatus;
          this.listTypeValueName = this.projectStatusCheck.listTypeValueName;

          this.percentage = project.projectCompletionPercentage;

          if (
            (project.projectCompletionPercentage < 100 &&
              this.listTypeValueName !== 'Closed') ||
            project.projectStatus?.listTypeValueId !== 48
          ) {
            console.log('this.percentage', this.percentage);
          } else if (
            (project.projectCompletionPercentage < 100 &&
              this.listTypeValueName === 'Closed') ||
            project.projectStatus?.listTypeValueId === 48
          ) {
            this.percentage = 100;
            project.projectCompletionPercentage = 100;
            console.log('percentage:', this.percentage, this.listTypeValueName);
          } else {
            this.percentage = project.projectCompletionPercentage;
          }
        }

        // for (let project of this.empProjectList) {
        //   this.projectStatusCheck = project.projectStatus;
        //   this.listTypeValueName = this.projectStatusCheck.listTypeValueName;

        //   this.percentage = project.projectCompletionPercentage;

        //   if (
        //     (project.projectCompletionPercentage < 100 &&
        //       this.listTypeValueName !== 'Closed') ||
        //     project.projectStatus?.listTypeValueId !== 48
        //   ) {
        //     console.log('this.percentage', this.percentage);
        //   } else if (
        //     (project.projectCompletionPercentage < 100 &&
        //       this.listTypeValueName === 'Closed') ||
        //     project.projectStatus?.listTypeValueId === 48
        //   ) {
        //     this.percentage = 100;
        //     project.projectCompletionPercentage = 100;
        //     console.log('percentage:', this.percentage, this.listTypeValueName);
        //   } else {
        //     this.percentage = project.projectCompletionPercentage;
        //   }
        // }

        // if (this.projectList.length == 0) {
        //   this.nodata = true;
        //   console.log('nodata');
        // }

        // if (this.empProjectList.length == 0) {
        //   this.nodata = true;
        //   console.log('nodata');
        // }

        this.secondProjectList = d.sort((a, b) =>
          a.projectName.localeCompare(b.projectName)
        );
      },
      (error) => {
        this.nodata = true;
        this.loader = false;

        if (error.status === 404) {
          this.nodata = true;
          console.log('404');
        }
      }
    );

    // Now you can use the selected values as needed
    console.log('Selected ListTypeValueId:', this.selectedListTypeValueId);
    console.log('Selected ListTypeValueName:', this.selectedListTypeValueName);
  }

  selectedListTypeValueIdByEmployee: any;
  selectedListTypeValueNameByEmployee: any;
  onProjectStatusChangeByEmployee(event: MatSelectChange): void {
    this.loader = true;
    this.nodata =false;
    this.empProjectList = [];
    this.search = '';
    this.selectedListTypeValueIdByEmployee = event.value;
    this.selectedListTypeValueNameByEmployee = this.projectStatus.find(
      (project) =>
        project.listTypeValueId === this.selectedListTypeValueIdByEmployee
    )?.listTypeValueName;

    this.gs.getProjectsByEmployee(this.empId).subscribe((d: any[]) => {
      this.loader = false;
      console.log('data::::', d);

      this.empProjectList = d.filter((res) => {
        this.nodata = false;
        console.log(
          'project status',
          res.projectStatus?.listTypeValueId === this.selectedListTypeValueId
        );

        return (
          res.projectStatus?.listTypeValueId ===
          this.selectedListTypeValueIdByEmployee
        );
      });

      console.log('this.projectList::', this.empProjectList);

      this.empProjectList = this.empProjectList.sort((a, b) =>
        a.projectName.localeCompare(b.projectName)
      );

      for (let project of this.empProjectList) {
        this.projectStatusCheckByEmployee = project.projectStatus;
        this.listTypeValueNameByEmployee =
          this.projectStatusCheckByEmployee.listTypeValueName;

        this.percentageByEmployee = project.projectCompletionPercentage;

        if (
          (project.projectCompletionPercentage < 100 &&
            this.listTypeValueNameByEmployee !== 'Closed') ||
          project.projectStatus?.listTypeValueId !== 48
        ) {
          console.log('this.percentage', this.percentageByEmployee);
          
        }else if ((project.projectCompletionPercentage < 100 && 
          this.listTypeValueNameByEmployee === 'Closed'
        ) || project.projectStatus?.listTypeValueId === 48){
          this.percentageByEmployee = 100;
          project.projectCompletionPercentage;
          console.log('percentage:', this.percentageByEmployee, this.listTypeValueName);
        }else{
          this.percentageByEmployee = project.projectCompletionPercentage;
        }
      }

      if(this.empProjectList.length == 0){

        this.nodata = true;
        console.log('nodata');
      }

      this.secondProjectList =  d.sort((a,b)=>
        a.projectName.localeCompare(b.projectName)
      );
    },(error)=>{
      this.nodata = true;
      this.loader =  false;

      if(error.status === 404){
        this.nodata = true;
        
      }
    }
  );
  }

  changePlaceholderColor(event): void {
    // if (event.target.value) {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.remove('search-placeholder');
    // } else {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }
  }
  closeSuccessMessage() {
    this.success = false;
    this.successMessage = '';
  }

  showUpdateSuccessMessage(message: string) {
    this.successUpdate = true;
    this.successUpdateMessage = message;

    setTimeout(() => {
      this.closeUpdateSuccessMessage();
    }, 3000);
  }

  closeUpdateSuccessMessage() {
    this.successUpdate = false;
    this.successUpdateMessage = '';
  }
  clearSuccessMessage() {
    this.projectService.clearSuccessMessage();
  }

  ngAfterViewInit() {
    // Access projectStatus after the view has been initialized
    this.projectStatusService.getProjectStatus().subscribe((data: any) => {
      console.log('addProjectComponent', data);
    });
  }
  private User_Type(x) {
    this.user_type = x?.userType?.userType;
  }

  //methods
  view(i) {
    this.router.navigate(['deliverables/view/']);
  }

  viewEmployees(e) {
    const dialogRef = this.dialog.open(EmpListComponent, {
      data: e,
      width: '500px',
      height: '500px',
    });
  }

  // edit(d) {
  //   // let x = d.projectId;
  //   // console.log('data', d);

  //   // const dialogRef = this.dialog.open(AddProjectComponent, {
  //   //   width: '800px',
  //   //   data: { x, d }
  //   // });
  //   // console.log('data-----------checking', d);
  //   this.router.navigate (['home/editproject',d])
  // }

  edit(d) {
    let x = d.projectId;
    console.log('data', d);

    const dialogRef = this.dialog.open(AddProjectComponent, {
      width: '800px', // Set initial width
      height: 'auto',
      // width: '800px',
      data: { x, d },
      // position: { top: '5%', left: '35%', right: '0' }
    });
    console.log('data-----------checking', d);
  }

  // addProject() {

  //   const dialogRef = this.dialog.open(AddProjectComponent, {
  //     width: '800px',
  //     data: '',
  //     position: { top: '5%', left: '35%', right: '0' }, // Center horizontally and vertically
  //   });
  // }

  addProject() {
    let positionConfig = { top: '5%', left: '35%', right: '0' };

    // Adjust position for mobile view (screen width <= 768px)
    if ((window.innerWidth = 320)) {
      positionConfig = { top: '5%', left: '70%', right: '0' };
    }

    if ((window.innerWidth = 1440)) {
      positionConfig = { top: '5%', left: '35%', right: '0' };
    }

    const dialogRef = this.dialog.open(AddProjectComponent, {
      width: '800px', // Set initial width
      height: 'auto',
    });
  }

  ngOnDestroy() {
    // if (this.successMessageSubscription) {
    this.successMessageSubscription.unsubscribe();
    // }
    // if (this.successUpdateMessageSubscription) {
    this.successUpdateMessageSubscription.unsubscribe();
    // }
  }

  delete(i) {
    Swal.fire({
      title: 'Are you sure? Do you want to delete',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!',
    }).then((result) => {
      if (result.value) {
        this.gs.deleteProject(i.projectId).subscribe(
          (a) => {
            console.log(a);
            // Swal.fire({
            //   icon: 'success',
            //   text: 'Deleted',
            // });

            if ((a as any).statusCode === 200) {
              this.success = true;
              this.successMessage = 'Project Deleted Successfully';
            }

            setTimeout(() => {
              this.success = false;
              this.successMessage = '';

              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              const currentUrl = this.router.url;
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.router.navigateByUrl(currentUrl);
                });
            }, 5000);
            // window.location.reload();
          },
          (err) => {
            console.log('err', err);
            if (err.error.errorCode) {
              this.warning = true;
              this.warningMessage = err.error.message;
            }
            setTimeout(() => {
              this.warning = false;
              this.warningMessage = '';
            }, 5000);
          }
        );
      }
      // this.skillsArray.removeAt(id);
    });
  }

  // approvalStatus(i){
  //   if (i?.projectStatus?.listTypeValueName === null) {
  //     return 'black'
  //   }
  //   if (i?.projectStatus?.listTypeValueName === 'In Progress') {
  //     return '#ffc107'
  //   }
  //   if (i?.projectStatus?.listTypeValueName === 'Approved') {
  //     return '#28a745'
  //   }
  //   if (i?.projectStatus?.listTypeValueName == 'Closed') {
  //     return '#FF2525'
  //   }
  // }

  approvalStatus(status: string): string {
    if (!status) {
      return '#fff';
    }
    if (status) {
      switch (status) {
        case 'In Progress':
          return '#D05700';
        case 'Open':
          return '#002BC4';
        case 'Closed':
          return '#FF2525';

        case 'Yet To Start':
          return 'black';
        default:
          return '';
      }
    }
  }

  approvalBackground(status: string): string {
    if (!status) {
      return '#fff';
    }

    if (status) {
      switch (status) {
        case 'In Progress':
          return '#FFE7A9';
        case 'Open':
          return '#D0E6FF';
        case 'Closed':
          return '#FFE5E5';

        case 'Yet To Start':
          return '#DBDBDB';
        default:
          return '';
      }
    }
  }

  move() {
    this.router.navigate(['emp-report']);
  }
}
