import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router,ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ViewemployeegroupemployeesComponent } from 'src/app/viewemployeegroupemployees/viewemployeegroupemployees.component';

@Component({
  selector: 'app-shiftemployee',
  templateUrl: './shiftemployee.component.html',
  styleUrls: ['./shiftemployee.component.scss']
})
export class ShiftemployeeComponent implements OnInit {

  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any = [];
  selected :true;
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  branchlist: any = [];
  years: number[] = [];
  usertype: any;
  organizationId: any;
  sortedData: any;
  p:number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  today: any =  new Date();
  dateFormat: any;
  shiftList: any;
  employeeId: any;
  employee: string;
  empList:any=[];
  shiftcalendarList: any = [];
  rostertype: any;
   selectedEmployeeData :any = [];
   employeedisabled:boolean = true;
  errormessagejson: any;
  shiftemployeeId: any;
  importerror = false;
  shiftemployeedata: any;
  showemployeegroupserach:any;
  empgroupList: any;
  selectedEmployeeGroupData: any = [];


  constructor( private formBuilder: FormBuilder,
    private router : Router,
    private payrollService: PayrollService, private datepipe : DatePipe ,
    private ActivatedRoute:ActivatedRoute, @Inject(AppComponent) public AppData: any ,  
     private dialog:MatDialog) { }

  ngOnInit(): void {

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertype.userId;
     this.organizationId = this.usertype.organization.organizationId;
     
     this.shiftemployeeId = this.ActivatedRoute.snapshot.params.shiftemployeeId;
           if( this.shiftemployeeId)
           {
            this.postForm = this.formBuilder.group({
              client : [null,[Validators.required]],
              shiftcalendar : [null,[Validators.required]],
              employee : [null,[Validators.required ]],
              shift : [null,[Validators.required]],
              rosterType:[null,[Validators.required]],
              startDate : [null,[Validators.required]],
              endDate : [null,[Validators.required]],                 
              });
       
            
     this.payrollService.getShiftemployeeId( this.shiftemployeeId).subscribe((resultData:any) =>
     {
        if(!resultData.statusCode){

              this.shiftemployeedata=resultData;
              this.employee=this.shiftemployeedata.employee.employeeNumber+"-"+this.shiftemployeedata.employee.firstName+" "+this.shiftemployeedata.employee.lastName;
             // this.getshift(this.shiftemployeedata.shiftCalender.shiftCalendarId);   
              this.postForm.patchValue({
                    client : this.shiftemployeedata.client.clientId,
                    shiftcalendar : this.shiftemployeedata.shiftCalender.shiftCalendarName,
                    employee :this.employee,
                    shift : this.shiftemployeedata.shift.shiftId,
                    rosterType:this.shiftemployeedata.rosterType.listTypeValueId,
                    startDate :this.shiftemployeedata.startDate,
                    endDate : this.shiftemployeedata.endDate,                 
                    });
                  }
                  this.getshiftcalendar();
              });

           }else{


              this.postForm = this.formBuilder.group({
                client : [null,[Validators.required]],
                shiftcalendar : [null,[Validators.required]],
                employee : [null,[]],
                employeegroup : [null,[]],
                shift : [null,[Validators.required]],
                rosterType:[null,[Validators.required]],
                startDate : [null,[Validators.required]],
                endDate : [null,[Validators.required]],                 
                });
           }
      

  

     this.payrollService.getclientlist(this.organizationId).subscribe((resultData:any) =>
     {
       this.clientList=resultData;
     });

     this.payrollService.getListTypeById(14).subscribe((resultData:any) =>
     {
       this.rostertype=resultData;
     });
  }

  empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employee.employeeId;
    this.employee=emp.employee.employeeNumber+"-"+emp.employee.firstName+" "+emp.employee.lastName;
    if( this.shiftemployeeId )
    {
      this.postForm.patchValue({
        employee: this.employee 
     });
    }else{
    
      this.postForm.patchValue({
          employee: null
       });
  
        let  hasemployee =   this.selectedEmployeeData.some(e=> e.employeeId == emp.employee.employeeId);
          if(!hasemployee)
          {
            this.selectedEmployeeData.push(emp.employee);
          }
        
    }
   
  }


  empAllSelect()
  {
    
    if(this.postForm.value.employee == "All" && this.postForm.value.client)
    {
      this.payrollService.getAllEmployeeClientList(this.postForm.value.client).subscribe((result: any) => {
         if(result.statusCode)
         {
             
         }else{
           console.log("else")
           this.postForm.patchValue({
            employee: null
         });
          this.empList = result;
             result.forEach(element => {
              let  hasemployee =   this.selectedEmployeeData.some(e=> e.employeeId == element.employee.employeeId);
              if(!hasemployee)
              {
                this.selectedEmployeeData.push(element.employee);
              }
             });
       
         }
      
      })

    }
   
   
  }

  
  getshiftcalendar()
  {
    
    let clientId = this.postForm.value.client;
    console.log("clientId" + clientId);
    if(clientId)
    {
      this.employeedisabled =  false;
      this.payrollService.getdefaultshiftcalendarbyclient(clientId).subscribe((resultData:any) =>
      {
        this.shiftcalendarList=resultData;
            this.postForm.patchValue({
              shiftcalendar : this.shiftcalendarList[0].shiftCalendarName
            })
            this.getshift(this.shiftcalendarList[0].shiftCalendarId);
      });
    }
   
  }
  
  getshift(shiftcalendarId)
  {
    this.payrollService.getshiftbyshiftcalendar(shiftcalendarId).subscribe((resultData:any) =>
    {

      if(resultData.statusCode)
      {

      }else{
        this.shiftList=resultData;
      }
    

      
    });
  }
      empSearch(event)
    { 
      console.log(event.target.value);
      let employeenumber = event.target.value;
      console.log(employeenumber);
      // Employee List Api
      if(employeenumber && this.postForm.value.client)
      {
        this.payrollService.getEmployeeClientList(employeenumber,this.postForm.value.client).subscribe((result: any) => {
           if(result.statusCode)
           {
            this.empList.length = 0;
           }else{
             console.log("else")
            this.empList = result;
           }
        
        })

      }
      
    }

    empAllGroupSelect()
    {
      if(this.postForm.value.employeegroup == "All" && this.postForm.value.client)
      {
        this.payrollService.getEmployeeGroupsByclient(this.postForm.value.client).subscribe((result: any) => {
           if(result.statusCode)
           {
               
           }else{
             console.log("else")
             this.postForm.patchValue({
              employee: null
           });
         
               result.forEach(element => {
                  if(element.employeeGroupStatus.listTypeValueId == 1)
                  {
                    let  hasemployee =   this.selectedEmployeeGroupData.some(e=> e.employeeGroupId == element.employeeGroupId);
                    if(!hasemployee)
                    {
                      this.selectedEmployeeGroupData.push(element);
                    }
                  }

              
               
               });
         
           }
        
        })
  
      }
     
    }

    viewemployees(empgroup)
    {
      const dialogRef = this.dialog.open(ViewemployeegroupemployeesComponent, {
        //height: 'auto',width: '600px',
        width: '950px',height:'fit-content',data: empgroup
      });
    }

    empgroupSearch(event)
    {
      console.log(event.target.value);
      let keyword = event.target.value;
      console.log(keyword);
      // Employee List Api
      if(keyword && this.postForm.value.client)
      {
        this.payrollService.getEmployeeGroupClientList(keyword,this.postForm.value.client).subscribe((result: any) => {
           if(result.statusCode)
           {
            this.empgroupList.length = 0;
           }else{
             console.log("else")
            this.empgroupList = result.filter(employeegroup =>employeegroup.employeeGroupStatus.listTypeValueId == 1);
           }
        
        })

      }
    }

    empGroupSelect(employeegroup)
    {
      console.log(employeegroup);
      console.log(this.employee);
      
      
        this.postForm.patchValue({
            employeegroup: null
         });
    
          let  hasemployee =   this.selectedEmployeeGroupData.some(e=> e.employeeGroupId == employeegroup.employeeGroupId);
            if(!hasemployee  && employeegroup.employeeGroupStatus.listTypeValueId == 1)
            {
              this.selectedEmployeeGroupData.push(employeegroup);
            }
          
      
    }

pageChages()
{
  this.p=1;
}
  get validate() { return this.postForm.controls; }

  clearemployeelist()
  {
    this.selectedEmployeeData =  [];
  }

  deleteemployee(index){
    console.log( index + index );
     if(index != -1)
     {
      this.selectedEmployeeData.splice(index,1);

      
        console.log( this.selectedEmployeeData.length );
     }
  }

  deleteemployeegroup(index){
    console.log( index + index );
     if(index != -1)
     {
      this.selectedEmployeeGroupData.splice(index,1);

      
        console.log( this.selectedEmployeeGroupData.length );
     }
  }

  showempldata(event)
  {
      if(event.target.value == 1){
         this.showemployeegroupserach = true;
      }else{
         this.showemployeegroupserach = false;
      }
  }

  
  onSubmit()
  {
    //alert("form submitted");
    this.submitted = true;
   const controls = this.postForm.controls;
  console.log("postForm" + this.postForm.invalid)
  
     if(this.shiftemployeeId){
      if (this.postForm.invalid) {
        console.log("invalid")
          console.log(controls);
          for (const name in controls) {
              if (controls[name].invalid) {
                console.log(name);
              }
          } 
          return;
      }
     }else{
          if (this.postForm.invalid || (this.selectedEmployeeData.length == 0 && this.selectedEmployeeGroupData.length == 0)) {
            console.log("invalid")
              console.log(controls);
              for (const name in controls) {
                  if (controls[name].invalid) {
                    console.log(name);
                  }
              } 
              return;
          }
     }

 

    if(this.shiftemployeeId) //Update Shiftcalendar
    {
      
      let postValues = this.postForm.value;
      //  let  shiftemployeeDataList = [];
     
           console.log(postValues);
          
            let shiftemployeeData = 
            {
              shiftEmployeeAssignId : this.shiftemployeeId,
              startDate : this.datepipe.transform(postValues.startDate , "yyyy-MM-dd"),
              endDate : this.datepipe.transform(postValues.endDate , "yyyy-MM-dd") ,
              employee :  this.shiftemployeedata.employee ,
              client : {clientId :postValues.client },
              shift : {shiftId : postValues.shift},
              shiftCalender : {shiftCalendarId : this.shiftcalendarList[0].shiftCalendarId},
              rosterType : {listTypeValueId : postValues.rosterType},
              status : this.shiftemployeedata.status
            };
        
            this.importerror = false;

              console.log(shiftemployeeData);

             //return
      this. payrollService.assignEmployeeShiftUpdate(this.shiftemployeeId,shiftemployeeData).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);
          this.postForm.reset();     
          this.submitted = false;
          setTimeout(() => {
            this.router.navigate(['/home/emp-shift-list']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
           setTimeout(() => {this.error = false}, redirectMsgTimeOut)

        //   setTimeout(() => {
        // //    this.finicalyearList();
        //   }, redirectMsgTimeOut)
        }

      }, err =>
      {
       
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.message;
            // this.router.navigate(['/home/financialyear']);
          }, redirectMsgTimeOut)
      })
    }
    else  //Add Shiftcalendar
    {
            let postValues = this.postForm.value;
            let  shiftemployeeDataList = [];

              if(this.showemployeegroupserach)
              {
             
                  let shiftemployeeData = 
                  {
                    startDate : this.datepipe.transform(postValues.startDate , "yyyy-MM-dd"),
                    endDate : this.datepipe.transform(postValues.endDate , "yyyy-MM-dd") ,
                    employeeGroups :  this.selectedEmployeeGroupData ,
                    client : {clientId :postValues.client },
                    shift : {shiftId : postValues.shift},
                    shiftCalender : {shiftCalendarId : this.shiftcalendarList[0].shiftCalendarId},
                    rosterType : {listTypeValueId : postValues.rosterType}
                  };
             
                  this. payrollService.assignEmployeeShiftbyemployeegroup(shiftemployeeData).subscribe((result: any) =>
                  {
                    if(result.statusCode == 200)
                    {
                      this.success = true;
                      this.sucess_msg = result.description;      
                      setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                      //this.router.navigate(['/home/employee']);
                      this.postForm.reset();     
                      this.submitted = false;
                      setTimeout(() => {
                        this.router.navigate(['/home/emp-shift-list']);
                      }, redirectMsgTimeOut)
                    }
                    else
                    {
                      this.error = true;
                      this.error_msg = result.description;
                      this.errormessagejson = result;
                      this.importerror = true;
                    //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
  
                    //   setTimeout(() => {
                    // //    this.finicalyearList();
                    //   }, redirectMsgTimeOut)
                    }
  
                  }, err =>
                  {
                    
                      setTimeout(() => {
                        this.error = true;
                        this.errorMsg = err.error.message;
                        // this.router.navigate(['/home/financialyear']);
                      }, redirectMsgTimeOut)
                  })


              }else{
                console.log(postValues);
                this.selectedEmployeeData.forEach(element => {
                  let shiftemployeeData = 
                  {
                    startDate : this.datepipe.transform(postValues.startDate , "yyyy-MM-dd"),
                    endDate : this.datepipe.transform(postValues.endDate , "yyyy-MM-dd") ,
                    employee :  { employeeId : element.employeeId } ,
                    client : {clientId :postValues.client },
                    shift : {shiftId : postValues.shift},
                    shiftCalender : {shiftCalendarId : this.shiftcalendarList[0].shiftCalendarId},
                    rosterType : {listTypeValueId : postValues.rosterType}
                  };
                  shiftemployeeDataList.push(shiftemployeeData);
                });
                  

                    console.log(shiftemployeeDataList);

                  //   return
                this. payrollService.assignEmployeeShift(shiftemployeeDataList).subscribe((result: any) =>
                {
                  if(result.statusCode == 200)
                  {
                    this.success = true;
                    this.sucess_msg = result.description;      
                    setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                    //this.router.navigate(['/home/employee']);
                    this.postForm.reset();     
                    this.submitted = false;
                    setTimeout(() => {
                      this.router.navigate(['/home/emp-shift-list']);
                    }, redirectMsgTimeOut)
                  }
                  else
                  {
                    this.error = true;
                    this.error_msg = result.description;
                    this.errormessagejson = result;
                    this.importerror = true;
                  //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)

                  //   setTimeout(() => {
                  // //    this.finicalyearList();
                  //   }, redirectMsgTimeOut)
                  }

                }, err =>
                {
                  
                    setTimeout(() => {
                      this.error = true;
                      this.errorMsg = err.error.message;
                      // this.router.navigate(['/home/financialyear']);
                    }, redirectMsgTimeOut)
                })
              }
  
     
          
    }


  }

  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

}
