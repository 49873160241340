<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Request Salary Advance
                        <!-- {{employeeLeaveId ? "Edit" : "Request"}} Salary Advance -->
                     </span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/SalaryAdvance-list" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="SalaryAdvanceForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Employee Number</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class">{{userdata.employee.employeeNumber}}</label>
                                </div>
                            </div>           
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Employee Name</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class">{{userdata.employee.firstName}} {{userdata.employee.lastName}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Total Salary</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label *ngIf="salaryAdvanceId == undefined && advanceDetails != undefined" class="form-control-label-class">{{totalSalary}}</label>
                                    <label *ngIf="salaryAdvanceId == undefined && advanceDetails == undefined" class="form-control-label-class">&nbsp;&nbsp;--</label>
                                    <label *ngIf="salaryAdvanceId != undefined" class="form-control-label-class">{{totalSalary}}</label>
                                </div>
                            </div>           
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Designation</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class">{{userdata.employee.designation.designationName}}</label>
                                </div>
                            </div>
                        </div>
                    </div>                
                    <div class="row">
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Financial Year<span class="text-danger"> *</span></label>
                                <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                    <option [value]="null"> -- Select Financialyear --- </option>
                                    <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                </select>
                                <mat-error *ngIf="submitted && SalaryAdvanceForm.controls['financialyear'].errors?.required">
                                    Financial year is required
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Period<span class="text-danger"> *</span></label>
                                <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" (change)="selectPeriod($event)">
                                    <option [value]="null"> -- Select Period --- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>
                                <mat-error *ngIf="submitted && SalaryAdvanceForm.controls['period'].errors?.required">
                                    Period is required
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                            <label class="col-control-label">Advance Paid<span class="text-danger"> *</span><small>(for the Month)</small></label>
                            <input type="text" matInput formControlName="advancePaid" 
                                class="form-control search-input form-control form-control-lg form-control-solid form-control-label-class" readonly>
                                <mat-error *ngIf="submitted && SalaryAdvanceForm.controls['advancePaid'].errors?.required">
                                    Advance Paid is required
                                </mat-error>
                            </div>
                        </div>
                    </div>   
                    <div class="row">
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Advance Eligible<span class="text-danger"> *</span><small>(for the Month)</small></label>
                                <input type="text" matInput formControlName="advanceEligible"
                                    class="form-control search-input form-control form-control-lg form-control-solid form-control-label-class" readonly>
                                    <mat-error *ngIf="submitted && SalaryAdvanceForm.controls['advanceEligible'].errors?.required">
                                        Advance Eligible is required
                                    </mat-error>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Advance Amount<span class="text-danger"> *</span></label>
                                <input type="text" matInput formControlName="advanceamount" (keyup)="checkAdvanceEligibility($event)"
                                placeholder="Enter Advance Amount"
                                class="form-control search-input form-control form-control-lg form-control-solid form-control-label-class">
                                <mat-error *ngIf="submitted && SalaryAdvanceForm.controls['advanceamount'].errors?.required">
                                    Advance amount is required
                                </mat-error>
                                <div *ngIf="showError" style="color: red;font-size: 11px;">
                                    Advance Amount cannot be greater than Advance Eligible.
                                </div>
                            </div>
                        </div>
                    </div>    
                    <div class="row buttons">
                        <div class="col-md-12 text-right">
                            <button type="reset" (click)="cancel()"
                                class="mat-focus-indicator ml-2 mat-raised-button mat-button-base">Cancel</button> 
                            <button type="submit" (click)="submitAsDraft()" [disabled]="Disable_submitAsDraft" *ngIf="show_draft"
                                class="mat-focus-indicator ml-2 mat-raised-button mat-button-base mat-primary">Save as Draft</button>                             
                            <button type="submit" (click)="submitToApprover()" [disabled]="Disable_submitToApprover" *ngIf="show_submit"
                                class="mat-focus-indicator ml-2 mat-raised-button mat-button-base mat-primary">Submit to Approval</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success"> 
    <div class="alert alert-resource alert-success" role="alert">
        <strong>Success!</strong><br>{{sucessmessage}}
    </div>
</div>
<!-- <div *ngIf="error" class="alert alert-resource alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{update_data_error_msg}}
</div> -->