import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { LeaveService } from 'src/app/providers/leave.service';
import { MatOption } from '@angular/material/core';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { UserService } from 'src/app/providers/user.service';

@Component({
  selector: 'app-mycalendar',
  templateUrl: './mycalendar.component.html',
  styleUrls: ['./mycalendar.component.scss']
})
export class MycalendarComponent implements OnInit {
  organization: any[];
  year: any[];
  calendarForm: FormGroup;
  branchList: any = [];
  holiday: boolean;
  exception: boolean;
  submitted:boolean = false;
  organizationId: any;
  btnDisable: boolean=false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private leaveservice: LeaveService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MycalendarComponent>,
    public userservice: UserService
  ) { }

  ngOnInit() {
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId =x.organization.organizationId;
    
    this.leaveservice.getOrganization().subscribe(
      (data: any) => {
        this.organization = data;
      }
    )

    this.leaveservice.getYear().subscribe(
      (data: any) => {
        this.year = data;
      }
    )

    this.calendarForm = this.fb.group({
      calendarName: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      // organization: [null, Validators.required],
      year: [null, Validators.required],
      branch: [null, Validators.required],
      calendarStatus: [1, Validators.required]
    })
      // console.log(this.data.year.yearId);
    if (this.data.calendarId) {
      this.userservice.getActiveBranch(this.organizationId).subscribe(
        (a: any) => {
          a.map(
            e => {
              console.log("e",e);
              if (e.organization.organizationId == this.data.organizationId) {
                this.branchList.push(e);

              }
              console.log("this.branchList",this.branchList);
            }
          )
        }
      )
      console.log(this.data.year.yearId);

      this.calendarForm.patchValue({
        calendarName: this.data.calendarName,
        // organization: this.data.organizationId,
        branch: this.data.branchId,
        year: Number(this.data.year.yearId),
        calendarStatus: this.data.calendarStatus.toString()
      })
    }
    else {
      this.userservice.getActiveBranch(this.organizationId).subscribe(
        (a: any) => {
          a.map(
            e => {
              console.log("e",e);
              if (e.organization.organizationId == this.organizationId) {
                this.branchList.push(e);

              }
              console.log("this.branchList",this.branchList);
            }
          )
        }
      )
      this.calendarForm.patchValue({
        calendarStatus: 1
      })
    }
  }

  change(d) {
    console.log(d.target.value);
    this.branchList = [];
    this.userservice.getActiveBranch(this.organizationId).subscribe(
      (a: any) => {
        console.log(a);
        a.map(
          e => {
            console.log(e);
            if (e.organization.organizationId == d.target.value) {
              this.branchList.push(e);
              console.log(this.branchList);
            }
          }
        )
      }
    )
  }

  get f() {
    return this.calendarForm.controls;
  }

  onSubmit() {
    this.submitted =true;
    let x = this.calendarForm.value;
    if (this.calendarForm.invalid) {
      return
    }
    x['organizationId'] = Number(this.organizationId);
    x['year'] = {
      'yearId': x.year
    }
    x['branchId'] = x.branch
    console.log(x);
    if (this.data.calendarId) {
      this.btnDisable=true;
      x['calendarId'] = this.data.calendarId
      x['calendarStatus'] = Number(x.calendarStatus)
      x['organizationId']=this.organizationId


      console.log(x);
      this.leaveservice.updateCalendar(this.data.calendarId, x).subscribe(

        (data: any) => {
          this.btnDisable=false;
          Swal.fire({
            text: data.description,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000 

          })
          this.dialogRef.close(this.data.calendarId)
          setTimeout(() => {
            //  window.location.reload();
          }, 3000)
        },
        error => {
          this.btnDisable = false;
          Swal.fire({
            text: error.error.message,
            icon: 'error'
          })
          this.dialogRef.close();
        }
      )
    }
    else {
      this.btnDisable=true;
      x['calendarStatus'] = 1,
      x['organizationId']=this.organizationId
      this.leaveservice.createCalendar(x).subscribe(
        (data: any) => {
          this.btnDisable=false;
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          this.dialogRef.close(x);
          setTimeout(() => {
            // window.location.reload();
         }, 3000)
                },
        error => {
          this.btnDisable = false;
          Swal.fire({
            text: error.error.message,
            icon: 'error'
          })
          // this.dialogRef.close();
        }
      )
    }
  }


  
}
