
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { ViewApproversComponent } from '../view-approvers/view-approvers.component';
// import { AddTimelogsComponent } from '../add-timelogs/add-timelogs.component';
import { ExcelService} from 'src/app/providers/excel.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { EmployeeTimesheetViewdescriptionComponent } from '../employee-timesheet-viewdescription/employee-timesheet-viewdescription.component';
@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.scss']
})
export class TimeSheetComponent implements OnInit {

  showTable:boolean = false;
  projectId: number = 0;
  projectList: any = [];
  taskList: any;
  projectStatus: any = "0";
  timesheetDate: any;
  p1: any;
  user_type: string;
  search: any;
  userType: any;
  isData: boolean;
  taskId: number = 0;
  timesheet: any[] = [];
  isproject: boolean;
  nodata = false;
  loader = true;
  public searchFilter: any = "";
  query: any = "";
  beforepage1: any;
  mySelect1: any = 5;
  employeelist: any = [];
  employeeId: any;
  totalTime: any;
  projectlist: any;
  selectedemployeeId: any;
  startDate: any;
  endDate: any;
  disable_project: boolean = true;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  tasktotalTime: any;
  today: any;

  employee_required: boolean = false;
  empId: any;
  employeeIds: any[];
  beforesearchpage1:any;
  constructor(
    private gs: ProjectManagementService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    // this.data;
    // console.log("data:", this.data);

    // console.log("taskId:", this.data.taskId);
    // // this.empId=this.data.employeeTasks.employeeId
    // console.log("this.empId::", this.empId);
    // console.log("this.data.employeeTasks::",this.data.empolyeeTasks);

    this.changefirstpage1()
    this.data;
    console.log("data:", this.data);

    console.log("taskId:", this.data.taskId);
    console.log("empId:", this.data.empolyeeTasks[0].empolyeeId);

    this.taskId = this.data.taskId;
    this.employeeId = this.data.empolyeeTasks[0].empolyeeId;

    console.log("this.employeeId" ,this.employeeId);
    
    
    // const employeeTasks = this.data.empolyeeTasks || []; 
    // this.employeeIds = [];
    
    // for (let i = 0; i < employeeTasks.length; i++) {
    //     const empolyeeId = employeeTasks[i]?.empolyeeId; 
    //     if (empolyeeId !== undefined) {
    //         this.employeeIds.push(empolyeeId);
    //     }
    // }
    
    // console.log(this.employeeIds);
    
    // console.log("empId:", this.data.employeeTasks[0].employee.employeeId);

  //   if (this.data.employeeTasks && this.data.employeeTasks.length > 0) {
  //     console.log("entered if!...");
      
  //     const firstEmployeeTask = this.data.employeeTasks[0];
  //     console.log("firstEmployeeTask",firstEmployeeTask);
      
  //     console.log("Employee ID:", firstEmployeeTask.empolyeeId);
  //     console.log("Assigned Hours:", firstEmployeeTask.assignedhours);
  //     console.log("Assigned Minutes:", firstEmployeeTask.assignedminutes);
  //     // Access other properties as needed
  // } else {
  //     console.log("No employee tasks found");
  // }
    // this.taskId = this.data.taskId;
    // this.employeeId =  this.data.employeeTasks[0].employee.employeeId;

    // console.log("empId:", this.employeeId );

   
    

    // this.gs.taskDetailByTaskEmployee(this.taskId, this.employeeId).subscribe(
    //   (res:any) => {
    //     console.log("res", res);
    //     if(res.errorCode ==  204){
    //       this.nodata=true
    //       this.loader = false;
    //       console.log("if entered no for to-do");
    //     }
    //     // console.log(
    //     //   "taskId",
    //     //   res[0].task.taskId,
    //     //   "empId",
    //     //   res[0].employee.employeeId
    //     // );

    //     this.timesheetreport = res;
    //     this.showTable = true;
    //     this.loader = false;
    //     console.log("response from time sheet::", this.timesheetreport);
       
      
        

    //     const detailTaskId = res[0].task.taskId;
    //     const detailEmployeeId = res[0].employee.employeeId;
    //     if (
    //       this.taskId === detailTaskId &&
    //       this.employeeId === detailEmployeeId
    //     ) {
    //       this.nodata = false;
    //       this.timesheetreport = res;
    //       this.loader = false;
    //       // this.nodata =  true;

    //       this.totalTime = res[0].totalTime;
    //       console.log("this.timesheetreport", this.timesheetreport[0]);
    //       console.log("totaltime");
    //     }
    //   },
    //   (error) => {
    //     //  this.loader = false;
    //     // this.timesheetreport.length = 0;
    //     this.showTable = true;
    //     this.loader = false;
    //     // if (this.timesheetreport.length <= 0) {
    //     //   this.nodata = true;
    //     // }
    //     console.log("error");
    //     // if(error.status == 404){

    //     //   console.log("404")
    //     // }
    //   }
    // );


    // Preserving the pagination state
this.gs.taskDetailByTaskEmployee(this.taskId, this.employeeId).subscribe(
  (res: any) => {
    console.log("res", res);
    if (res.errorCode === 204) {
      this.nodata = true;
      this.loader = false;
      console.log("if entered no for to-do");
    }

    this.timesheet = res;
    this.showTable = true;
    this.loader = false;
    console.log("response from time sheet::", this.timesheet);

    const detailTaskId = res[0].task.taskId;
    const detailEmployeeId = res[0].employee.employeeId;
    if (this.taskId === detailTaskId && this.employeeId === detailEmployeeId) {
      this.nodata = false;
      this.timesheet = res;
      this.loader = false;

      this.totalTime = res[0].totalTime;
      console.log("this.timesheetreport", this.timesheet[0]);
      console.log("totaltime");

      // Ensure the pagination state is preserved
      // No need to reset p or mySelect here, as they are already preserved in the parent component
    }
  },
  (error) => {
    this.showTable = true;
    this.loader = false;
    console.log("error");
  }
);

    this.today = new Date();
    this.today = this.datepipe.transform(this.today, "YYYY-MM-dd");

    this.gs.getResource().subscribe(
      (d: any) => {
        //this.loader = false;
        this.employeelist = d;
      },
      (error) => {
        //  this.loader = false;
        //  this.nodata = true;
        console.log("error");
        // if(error.status == 404){
        //   this.nodata = true;
        //   console.log("404")
        // }
      }
    );
  }

  getTask() {
    this.employee_required = false;
    this.disable_project = false;
    console.log(this.disable_project);
    console.log("employeeId" + this.selectedemployeeId);
    if (this.selectedemployeeId == undefined) {
      this.projectlist.length = 0;
    } else {
      this.gs
        .getprojectbyEmployeeId(this.selectedemployeeId)
        .subscribe((data: any) => {
          console.log(data);
          this.projectlist = data;
        });
    }
  }
  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  changefirstpage1() {
    this.p1 = 1;
  }

  datechange(event: any) {
    console.log(event);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return;
    }
    if (i.task.taskStatusName == "To Do") {
      return "#ffc107";
    }
    if (i.task.taskStatusName == "Completed") {
      return "#28a745";
    }
    if (i.task.taskStatusName == "In Progress") {
      return "#f44336";
    }
  }

  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }

  description(e) {
    const dialogRef = this.dialog.open(
      EmployeeTimesheetViewdescriptionComponent,
      {
        width: "500px",
        data: e, // Pass the entire object 'e' to the dialog
      }
    );
  }
  sortData(sort: Sort) {
    const data = this.timesheet.slice();
    if (!sort.active || sort.direction === "") {
      this.timesheet = data;
      return;
    }

    this.timesheet = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "employeeName":
          // return this.compare(
          //   a.employee.employeeName,
          //   b.employee.employeeName,
          //   isAsc
          // );
          return (
            this.compare(
              a?.employee?.firstName,
              b?.employee?.firstName,
              isAsc
            ) ||
            this.compare(a?.employee?.lastName, b?.employee?.lastName, isAsc) ||
            this.compare(a.taskName, b.taskName, isAsc)
          );
        case "projectName":
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );
        case "taskName":
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "workedHours":
          return this.compare(a.workedHours, b.workedHours, isAsc);
        case "taskStatusName":
          return this.compare(
            a.task.taskStatusName,
            b.task.taskStatusName,
            isAsc
          );
        case "approvalStatusName":
          return this.compare(
            a.approvalStatusName,
            b.approvalStatusName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  remove() {
    console.log("remove");
    this.dialog.closeAll();
  }
}
