<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class=""><span class="h5 sub-content">Manage Elements</span></div>
                <div class="sub-header-buttons">
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="common-content" id="editForm">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-md-8">
            <div class="row mb-3 pl-0">
              <div class="">
                <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                  <div class="row mb-3 pl-0">
                    <div class="form-group col-md-3">
              
                      <!-- <mat-select formControlName="filterclient" class="form control
                      select-placeholder
                      email-input" style="border-radius: 5px;font-family: Mulish;" placeholder=" -- Select Client Name ---"  >
                          <mat-option [value]="null"> -- Select Client Name --- </mat-option>
                          <mat-option *ngFor="let row of clientList; let i=index;" [value]="row.clientId" >{{row.clientCode}}</mat-option> 
                      </mat-select> -->
                      <input
                      type="text"
                     
                      placeholder="Client Name"
                      formControlName="filterclient"
                      autocomplete="off"
                     readonly
                     [disabled]="true"
                     style="    background: #dddddd87 !important;"
                      [value]="clientName"
                      class="form-control email-input select-placeholder"
                    />
                      <!-- <div class="valid-msg" *ngIf="(validate.financialyear.invalid && validate.financialyear.touched) || validate.financialyear.dirty || submitted">
                          <span *ngIf="validate.financialyear.errors?.required" class="text-danger">Please Select Financial Year</span>
                      </div> -->
                  </div>
                    <div class="col-md-3">
                      <mat-select
                        class="form-select form-select-solid form-select-lg select-placeholder email-input"
                        style="font-family: Mulish"
                        (selectionChange)="listTypeValueid()"
                        appearance="fill"
                        formControlName="categoryTypeSearch"
                        placeholder="- Select Element -"
                      >
                        <mat-option class="text-color" [value]="null" selected
                          >- Select Element -
                        </mat-option>
                        <mat-option
                          *ngFor="let e of categoryTypeData"
                          class="text-color"
                          [value]="e.listTypeValueId"
                          >{{ e.listTypeValueName }}</mat-option
                        >
                      </mat-select>
                      <span style="color: rgb(148, 146, 146)"
                        >Note : Element Type.</span
                      >
                    </div>

                    <div class="col-md-4">
                      <!-- <button type="submit" mat-raised-button color="primary"
                                                class="btn btn-primary search-btn">Search</button> -->
                      <button
                        type="submit"
                        mat-raised-button
                        class="searchBtn mat-raised-button mat-button-base mr-2"
                        [disabled]="isProcessing"
                      >
                        Search
                      </button>
                      <!-- <button style="margin-left: 20px;" type="reset" mat-raised-button
                                               class="btn search-btn mr-2"
                                                (click)="clearTable()">Clear</button> -->
                      <button
                        mat-stroked-button
                        type="button"
                        class="apply-buttons"
                        (click)="clearTable()"
                        [disabled]="isProcessing"
                        style="
                          margin: 8px 0px 0px 5px;
                          background: white;
                          border: 1px solid #ddd;
                          position: relative;
                          top: -8%;
                          font-family: Mulish;
                          color: black;
                          font-size: 16px;
                          font-weight: 610;
                        "
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-4">

                        <div class="text-right" *ngIf="!loader && showtable && elementslistData1?.length > 10">
                            <div class="select-pagination m-2">
                              <span>Show : </span>
                              <select
                                class="select_list new-select_list listDesign"
                                [(ngModel)]="pagePerItem"
                                (change)="onItemsPerPageChange($event)"
                              > -->
          <!-- <option class="form-control" value="5">5</option> -->
          <!-- <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                              </select>
                            </div>
                          </div> -->
          <!-- <div class="row" *ngIf="!loader && showtable && elementslistData1.length > 10">
                            <div class="col-md-9 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0">
                                <select [(ngModel)]="pagePerItem" (change)="onItemsPerPageChange($event)"
                                    class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                        </div> -->
          <!-- <div class="row mt-5" *ngIf="elementslistData1?.length > 5 && !loader && showtable ">
                            <div class="col-md-9 text-right">
                                    <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0">
                                <select [(ngModel)]="pagePerItem" (change)="onItemsPerPageChange($event)" class="form-control report-filter">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                </select>
                           </div>
                        </div>  -->
          <!-- </div> -->
          <div class="card-body">
            <form [formGroup]="elementform" (ngSubmit)="submitData()">
              <div class="row">

                <div class="form-group col-md-3">
                  <label class="form-control-label">Client Name</label>
                  <!-- <mat-select formControlName="client" class="form control
                  select-placeholder
                  email-input" style="border-radius: 5px;font-family: Mulish;" placeholder=" -- Select Client Name ---"  >
                      <mat-option [value]="null"> -- Select Client Name --- </mat-option>
                      <mat-option *ngFor="let row of clientList; let i=index;" 
                      [value]="row.clientId" >{{row.clientCode}}</mat-option> 
                  </mat-select> -->
                  <input
                  type="text"
                 
                  placeholder="Client Name"
                  formControlName="client"
                  autocomplete="off"
                 readonly
                 style="    background: #dddddd87 !important;"
                  value={{clientName}}
                  class="form-control email-input select-placeholder"
                />
                  <!-- <div class="valid-msg" *ngIf="(validate.financialyear.invalid && validate.financialyear.touched) || validate.financialyear.dirty || submitted">
                      <span *ngIf="validate.financialyear.errors?.required" class="text-danger">Please Select Financial Year</span>
                  </div> -->
              </div>
                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >Element Type <span class="star">*</span></label
                  >
                  <mat-select
                    class="form-select form-select-solid form-select-lg select-placeholder email-input"
                    style="font-family: Mulish"
                    (change)="listTypeValueid1()"
                    appearance="fill"
                    formControlName="categoryType"
                    placeholder="- Select Element -"
                  >
                    <mat-option class="text-color" [value]="null" selected
                      >- Select Element -</mat-option
                    >
                    <mat-option
                      *ngFor="let e of categoryTypeData"
                      class="text-color"
                      [value]="e.listTypeValueId"
                      >{{ e.listTypeValueName }}</mat-option
                    >
                  </mat-select>
                  <div
                    class="valid-msg"
                    *ngIf="
                      elementform?.controls?.categoryType?.touched &&
                      elementform?.controls?.categoryType?.invalid
                    "
                  >
                    <span
                      *ngIf="elementform?.controls?.categoryType?.errors?.required"
                      class="text-danger"
                      >Please Select Element Type</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >Element Name <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    style="font-family: Mulish"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 form control select-placeholder email-input"
                    formControlName="elementName"
                    id="elementName"
                    placeholder="Element Name"
                    required
                  />
                  <span style="color: grey">Ex: Variable Pay</span>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (elementName?.invalid && elementName?.touched) ||
                      elementName?.dirty
                    "
                  >
                    <span
                      *ngIf="elementName?.errors?.required"
                      class="text-danger"
                      >Please Enter Element Name</span
                    >
                    <span
                      *ngIf="elementName.errors?.pattern"
                      class="text-danger"
                      >Please Enter Valid Element Name</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >Element Code <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    (keypress)="onlyLetters($event)"
                    style="font-family: Mulish"
                    class="form-control form-control-lg form-control-solid select-placeholder email-input mb-3 mb-lg-0"
                    formControlName="elementCode"
                    id="elementCode"
                    placeholder="Element Code"
                    required
                  />
                  <!-- <span style="color: grey;">Ex: Element Code</span> -->
                  <div
                    class="valid-msg"
                    *ngIf="
                      (elementCode?.invalid && elementCode?.touched) ||
                      elementCode?.dirty
                    "
                  >
                    <span
                      *ngIf="elementCode?.errors?.required"
                      class="text-danger"
                      >Please Enter Element Code
                    </span>
                    <!-- <span *ngIf="elementCode.errors?.pattern" class="text-danger">Please Enter Valid
                                            Element Code
                                        </span> -->
                    <span
                      *ngIf="
                        elementform.get('elementCode').hasError('minlength')
                      "
                      class="text-danger"
                    >
                      Minimum length is 2 characters.
                    </span>
                    <span
                      *ngIf="
                        elementform.get('elementCode').hasError('maxlength')
                      "
                      class="text-danger"
                    >
                      Maximum length is 10 characters.
                    </span>
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="form-control-label"
                    >Sequence No.<span class="star">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    formControlName="orderNumber"
                    placeholder="0"
                    (keypress)="onlyNumber($event)"
                    (paste)="onPaste($event)"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (orderNumber?.invalid && orderNumber?.touched) ||
                      orderNumber?.dirty
                    "
                  >
                    <span
                      *ngIf="orderNumber.errors?.required"
                      class="text-danger"
                      >Please Enter Sequence No
                    </span>
                    <!-- <span *ngIf="orderNumber.errors?.pattern" class="text-danger">Please Enter Valid
                                            Sequence Number
                                        </span> -->
                    <span
                      *ngIf="
                        elementform.get('orderNumber').hasError('minlength')
                      "
                      class="text-danger"
                    >
                      Minimum length is 1 characters.
                    </span>
                    <span
                      *ngIf="
                        elementform.get('orderNumber').hasError('maxlength')
                      "
                      class="text-danger"
                    >
                      Maximum length is 2 characters.
                    </span>
                  </div>
                </div>


                <!-- <div class="row"> -->
                  <div class="form-group col-md-3">
                    <label class="form-control-label">Element Description </label>
                    <textarea
                      formControlName="elementDescription"
                      id="elementDescription"
                      rows="1"
                      class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Element Description"
                      autocomplete="off"
                    ></textarea>
                  </div>
                <!-- </div> -->
                <!-- <div class="form-group col-md-3">
                                    <label class="col-form-label">Element Description </label>
                                    <textarea formControlName="elementDescription" id="elementDescription" rows="1"
                                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        placeholder="" autocomplete="off"></textarea>
                                </div> -->
              </div>
            

              <div class="row">
                <div class="col-md-12 text-right">
                  <!-- <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="margin-right: 20px;">Clear</button> -->

                  <button
                    mat-stroked-button
                    type="button"
                    class="apply-buttons"
                    style="
                      margin: 8px 8px 0px 5px;
                      background: white;
                      border: 1px solid #ddd;
                      position: relative;
                      top: -7%;
                      font-family: Mulish;
                      color: black;
                      font-size: 16px;
                      font-weight: 610;
                    "
                    (click)="clearForm()"
                  >
                    Clear
                  </button>
                  <!-- <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                        [disabled]="elementform.invalid">Save</button> -->

                  <button
                    type="submit"
                    mat-raised-button
                    class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                    [disabled]="isprocessing1 || elementform?.invalid"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div *ngIf="showtable" class="table-responsive">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <p *ngIf="showtable"><b>MANAGE ELEMENT</b></p>
              </div>
              <div class="col-md-6">
                <div
                  class="text-right"
                  *ngIf="!loader && showtable && elementslistData1?.length > 5"
                >
                  <div class="select-pagination m-2">
                    <span>Show : </span>
                    <select
                      class="select_list new-select_list listDesign"
                      [(ngModel)]="pagePerItem"
                      (change)="onItemsPerPageChange($event)"
                    >
                      <option class="form-control" value="5">5</option>
                      <option class="form-control" value="10">10</option>
                      <option class="form-control" value="15">15</option>
                      <option class="form-control" value="20">20</option>
                      <option class="form-control" value="25">25</option>
                    </select>
                  </div>
                </div>
                <!-- <div class="row" *ngIf="!loader && showtable && elementslistData1.length > 10">
                <div class="col-md-9 text-right">
                    <p class="show-count">Show</p>
                </div>
                <div class="col-md-3 text-left pl-0">
                    <select [(ngModel)]="pagePerItem" (change)="onItemsPerPageChange($event)"
                        class="form-control report-filter">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div> -->
                <!-- <div class="row mt-5" *ngIf="elementslistData1?.length > 5 && !loader && showtable ">
                <div class="col-md-9 text-right">
                        <p class="show-count">Show</p>
                </div>
                <div class="col-md-3 text-left pl-0">
                    <select [(ngModel)]="pagePerItem" (change)="onItemsPerPageChange($event)" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                    </select>
               </div>
            </div>  -->
              </div>
            </div>
          </div>

          <table
            *ngIf="!loader"
            class="table --table-bordered table-border-new"
            style="border-top-left-radius: 10px; border-top-right-radius: 10px"
          >
            <thead class="t-head">
              <tr>
                <th class="text-center">Action</th>
                <th>Element Type</th>
                <th>Element Name</th>
                <th>Element Code</th>
                <th>Sequence No.</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let e of elementslistData1
                    | searchFilter : query
                    | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                "
              >
                <td class="text-center">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="controls-list">
                    <button
                      mat-menu-item
                      routerLinkActive="active"
                      (click)="edit(e.elementId)"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="e.status.listTypeValueId == 1"
                      (click)="statuschangeactive(e.elementId, 2)"
                    >
                      <mat-icon>done</mat-icon>
                      <span class="matIcondesign" style="color: red"
                        >Inactive</span
                      >
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="e.status.listTypeValueId == 2"
                      (click)="statuschangeinactive(e.elementId, 1)"
                    >
                      <mat-icon>done</mat-icon>
                      <span class="matIcondesign" style="color: green"
                        >Active</span
                      >
                    </button>
                  </mat-menu>
                </td>

                <td>{{ e.categoryType.listTypeValueName }}</td>
                <td>{{ e.elementName }}</td>
                <td>{{ e.elementCode }}</td>
                <td>{{ e.orderNumber }}</td>
                <td class="active-status text-center">
                  <span
                    class="btn btn-outline-success btn-sm btn-block"
                    *ngIf="e.status.listTypeValueId == 1"
                  >
                    Active
                  </span>

                  <span
                    class="btn btn-outline-danger btn-sm btn-block"
                    *ngIf="e.status.listTypeValueId == 2"
                  >
                    In-Active
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td
                  style="text-align: center; font-size: 21px; font-weight: 770"
                  colspan="7"
                  *ngIf="noRecordsFound"
                >
                  No Records Found!
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="showing-record"></div>
          </div>
          <div
            class="col-md-8 text-right"
            *ngIf="elementslistData1?.length > 5 && !loader"
          >
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
    role="status"
  ></div>
</div>
