import { HttpClient,HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  httpOptionsHrms, httpleaveOptions,} from './providers/properties';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(private http : HttpClient) { }

  getannouncementList(id:any) {
    return this.http.get(`${environment.base_url}announcement`,httpOptionsHrms);
  }

  getannouncementListClient(id:any) {
    return this.http.get(`${environment.base_url}announcement/active/client/${id}`,httpOptionsHrms);
  }
  getannouncementListOrganization(id:any) {
    return this.http.get(`${environment.base_url}announcement/active/organization/${id}`,httpOptionsHrms);
  }

  
  getannouncementListClient1(id:any) {
    return this.http.get(`${environment.base_url}announcement/client/${id}`,httpOptionsHrms);
  }
  getannouncementListOrganization1(id:any) {
    return this.http.get(`${environment.base_url}announcement/organization/${id}`,httpOptionsHrms);
  }

  getAnnouncementOrgAndKeywordList(orgId)
  {
    let result = this.http.get(`${environment.base_url}announcement/organization/${orgId}`,httpOptionsHrms).pipe(map(data=>data));
    return result;
  }
  updateAnnouncementStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.base_url}announcement/${id}/${status}`,null,httpOptionsHrms).pipe(map(data=>data));
    return result;
  }
  applyAnnouncement(data:any){
    return this.http.post(`${environment.base_url}announcement`,data,httpOptionsHrms)
    .pipe(map(res => res));
  }
  editAnnouncement(id:any,postValues:any)
  {
    let result = this.http.put(`${environment.base_url}announcement/${id}`,postValues,httpOptionsHrms).pipe(map(data=>data));
    return result;
  }
  getAnnouncementById(id:any)
  {
    let result = this.http.get(`${environment.base_url}announcement/${id}`,httpOptionsHrms).pipe(map(data=>data));
    return result;
  }
  getAnnouncementByActive()
  {
    let result = this.http.get(`${environment.base_url}announcement/active`,httpOptionsHrms).pipe(map(data=>data));
    return result;
  }
  applyFeed(data:any){
    return this.http.post(`${environment.base_url}feed`,data,httpOptionsHrms)
    .pipe(map(res => res));
  }
  getFeedList(id:any) {
    return this.http.get(`${environment.base_url}feed`,httpOptionsHrms);
  }
  applyLike(data:any){
    return this.http.post(`${environment.base_url}likes`,data,httpOptionsHrms)
    .pipe(map(res => res));
  }
  applyComments(data:any){
    return this.http.post(`${environment.base_url}comments`,data,httpOptionsHrms)
    .pipe(map(res => res));
  }
  getComments(id:any) {
    return this.http.get(`${environment.base_url}comments/feed/${id}`,httpOptionsHrms);
  }

 
}
