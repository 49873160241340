<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span  class="h5 sub-content">
            {{ businessGroupId ? "Edit" : "Add" }} Business Group</span
          >
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="businessgroup" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-md-3" style="height: 70px !important">
              <label class="form-control-label">Entity Name</label>
              <input
                type="text"
               
                placeholder="Entity Name"
                formControlName="entityName"
                autocomplete="off"
               readonly
               style="    background: #dddddd87 !important;"
                value={{storeEntityName}}
                class="form-control email-input select-placeholder"
              />
            </div>
            <div class="form-group col-md-3" style="height: 70px !important">
              <label class="form-control-label"
                >Business Group Name <span class="star">*</span></label
              >
              <input
                type="text"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                formControlName="businessgroupName"
                class="form-control email-input select-placeholder"
                autocomplete="off"
                placeholder="Business Group name"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.businessgroupName?.invalid &&
                    validate?.businessgroupName?.touched) ||
                  validate?.businessgroupName?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.businessgroupName.errors?.required"
                  class="text-danger"
                  >Please Enter Business Group name</span
                >
                <span
                  *ngIf="validate.businessgroupName.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Business Group name</span
                >
              </div>
            </div>

            <div class="form-group col-md-3" style="height: 70px !important">
              <label class="form-control-label">Description </label>
              <input
                type="text"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                formControlName="description"
                class="form-control email-input select-placeholder"
                autocomplete="off"
                placeholder="Enter Description"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.description?.invalid &&
                    validate?.description?.touched) ||
                  validate?.description?.dirty
                "
              >
                <span
                  *ngIf="validate.description.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Description</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Industry Type<span class="star">*</span></label
              >
              <mat-select
              formControlName="industryType"
              placeholder="Select Industry Type"
              class="form-control email-input select-placeholder"
              (selectionChange)="selectionChangeIndustryType($event)"
            >
              <mat-option [value]="undefined" selected="selected">-Select Industry Type-</mat-option>
              <mat-option *ngFor="let row of industryTypeListing" [value]="row.listTypeValueId">
                {{ row.listTypeValueName }}
              </mat-option>
            </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.industryType?.invalid &&
                    validate?.industryType?.touched) ||
                  validate?.industryType?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.industryType.errors?.required"
                  class="text-danger"
                  >Please Select Industry Type</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <a
                mat-raised-button
                (click)="clearAll()"
                style="
                     margin-left: 10px;
    background: none;
    color: var(--Red-Color, #ff5d5d);
    border: 1px solid #ddd !important;
    height: 36px;
    position: relative;
    top: -2px;
    font-weight: 550;
    padding: 1px !important;
                "
                class="btn search-btn mr-2"
                [disabled]="isbtnDisable"
              >
                Cancel
              </a>
              <button
                type="submit"
                mat-raised-button
                class="n m-1 searchBtn"
                [disabled]="isbtnDisable"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="align-items-cEnter text-cEnter">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 51%;color: #1a83ff"
    role="status"
  ></div>
</div>

<div class="text-cEnter" *ngIf="success">
  <div class="alert alert-resource alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
