<!-- <div class="main-content"> -->
<!-- <div class="container-fluid" style="margin-top:70px"> -->
<!-- <div class="top-content row">
        <div class="col-md-5 col-sm-5" *ngIf=" user_type != 'HumanResource' &&  user_type != 'Manager'">
          <h4>Assign Approvers</h4>
        </div>
       
       
          <div class="col-md-5 col-sm-5">
            <ul class="unstyled inbox-pagination">
              <div class="sidebar-search">
                <div class="input-group">
                  <input type="text"
                   class="form-control search-menu" 
                   placeholder="Search..."
                    [(ngModel)]="search"
                      (keyup)="changepage()" 
                      name="searchText"
                    >
                  <div class="material-icons-outlined search-icon">search</div>
                </div>
              </div>
            </ul>
          </div>
          <div class="col-md-2 col-sm-2">
            <button type="button" mat-raised-button class="btn"  (click)="viewProject('e')"
           >
              <span>Assign Approver</span>
            </button>
          </div>
      </div> -->
<!-- <nav class="navbar  bg" style="margin-top: 68px;padding: 7px;">
        <h4 class="heading-tag m-2 ml-2 col-md-4" 
        >Assign Approvers</h4>
      
          <div class="col-md-4">
            <div class="input-group">
              <input
              type="input"
                class="form-control search-menu search-placeholder"
                placeholder="Search..."
                [(ngModel)]="search"
                (keyup)="changepage()"
                name="searchText"
              />
            </div>
      </div>
      <div class="col-md-4">
        <button
        type="button"
        (click)="viewProject('e')" 
        mat-raised-button
        class="assignBtn btndesign"
      >
        <span> Assign Approver</span>
      </button>
      </div>
       
      </nav> -->

<nav class="navbar bg" style="margin-top: 67px">
  <div class="container-fluid">
    <div class="row w-100">
      <div class="col-12 col-md-4">
        <h4 class="heading-tag" style="position: relative; left: 5px">
          Assign Approvers
        </h4>
      </div>

      <div class="col-12 col-md-4">
        <div class="input-group">
          <input
            type="input"
            class="form-control search-menu search-placeholder"
            placeholder="Search..."
            [(ngModel)]="search"
            (keyup)="changepage()"
            name="searchText"
          />
        </div>
      </div>

      <div class="col-12 col-md-4 isBTN">
        <button
          type="button"
          (click)="viewProject('e')"
          mat-raised-button
          class="searchBtn1"
        >
          <span> Assign Approver</span>
        </button>
      </div>
    </div>
  </div>
</nav>

<mat-card class="card-content">
  <div class="search-content">
    <form [formGroup]="projectApproverForm">
      <!-- Task Type -->
      <div class="row">
        <div class="col-md-4" style="margin-top: 12px">
          <div class="input-width" id="loginform">
            <label class="col-control-label labelDesign">Project</label>
            <mat-select
              formControlName="project"
              class="form-control email-input select-placeholder"
              placeholder="Select Project"
              (selectionChange)="projectapprovervalidate()"
            >
              <mat-option
                *ngFor="let project of projectList"
                [value]="project.projectId"
              >
                {{ project.projectName }}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="f?.project?.invalid && submitted1" style="color: red;font-size: 13px;" >
                         <span *ngIf="showError"  style="color: red;">  Project is required</span></mat-error> -->
          </div>
          <!-- <mat-error   style="color: red;font-size: 13px;"> 
                      <span *ngIf="projectapproverexist"  style="    color: red;
                      font-family: 'Mulish', sans-serif;
                      position: relative;
                      top: -19px;
                      font-size: 13px;
                      "> Approver Already Exist </span> </mat-error> -->
        </div>
        <div class="col-md-4" style="margin-top: 12px">
          <!-- Task Type -->
          <div class="input-width" id="loginform">
            <label class="col-control-label labelDesign">Approver</label>
            <mat-select
              formControlName="approver"
              placeholder="Select Approver "
              class="form-control email-input select-placeholder"
            >
              <mat-option
                *ngFor="let approver of ApproverList"
                [value]="approver.approverId"
              >
                {{ approver.rulename }}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="f.approver.invalid && submitted1" style="" class="textdangerDesign">
                        <span *ngIf="showError" style="color: red;">  Approver is required</span></mat-error> -->
          </div>
          <!-- <mat-error *ngIf="f.approver.invalid && submitted1">Approver is required</mat-error> -->
        </div>

        <!-- <div class="col-md-4" style="margin-top: 12px">
                  <div  class="input-width" id="loginform">
                      <label class="col-control-label labelDesign">Approver Type</label>
                      <mat-select formControlName="approverType"
                          placeholder="Select Approver Type"
                        class="form-control email-input select-placeholder">
                          <mat-option *ngFor="let approvertype of approvertype" [value]="approvertype.approverTypeId">
                          {{approvertype.approverTypeName}}
                          </mat-option>
                      </mat-select>
                     
                  </div>
              </div> -->
        <div class="col-md-4 buttonDesign" style="margin-top: 30px">
          <button
            type="button"
            mat-raised-button
            [disabled]="isButtonDisabled"
            class="btn btn-primary search-btn searchDesign searchBtn"
            style="margin-left: -6px; margin-top: 6px"
            (click)="SearchApproveProject()"
          >
            Search
          </button>
          <button
            type="reset"
            class="searchBtn ml-2 mx-2 mt-2 mt-xl-0"
            (click)="clearForm()"
            [disabled]="isButtonDisabled"
            style="
              margin-left: 10px;
              font-size: 16px;
              background: none;
              color: black;
              padding: 7px 12px 7px 12px;
              font-family: 'Mulish', sans-serif;
              font-style: normal;
              font-weight: 610;
              line-height: normal;
              letter-spacing: 0.075px;
              height: 35px;
              margin-top: 6px;
              border: 1px solid #ddd !important;
              position: relative;
              top: 4px;
            "
          >
            Clear
          </button>
        </div>
      </div>
    </form>
    <!-- *ngIf="approverList.length > 0 " -->
    <div class="card-body table-responsive">
      <!-- <ng-container *ngIf="approverList.length > 0;else elseProject"> -->
      <!-- *ngIf="approverList.length > 5 " -->
      <div
        class="row p-0 m-0"
        *ngIf="
          approverList.length > 5 && (approverList | mytask : search).length > 0
        "
      >
        <div
          class="col-xl-12 col-lg-12"
          style="margin-bottom: 12px"
          *ngIf="(approverList | mytask : search).length > 5"
        >
          <div class="select-pagination">
            <span>Show : </span>
            <select
              class="select_list new-select_list listDesign"
              [(ngModel)]="mySelect"
              [ngModelOptions]="{ standalone: true }"
              (change)="changefirstpage()"
            >
              <option class="form-control" value="5">5</option>
              <option class="form-control" value="10">10</option>
              <option class="form-control" value="15">15</option>
              <option class="form-control" value="20">20</option>
              <option class="form-control" value="25">25</option>
            </select>
          </div>
        </div>
      </div>
      <table
        class="table"
        matSort
        (matSortChange)="sortData($event)"
        *ngIf="tableShow"
      >
        <thead>
          <tr id="trDesign">
            <th
              style="
                text-align: center;
                border-radius: 15px 0px 0px 0px;
                height: 32px;
              "
            >
              Sl.No
            </th>
            <!-- <th>Project&nbsp;&nbsp;Code</th>
                    <th>Project&nbsp;&nbsp;Name</th> -->
            <th mat-sort-header="rulename">Approver&nbsp;&nbsp;</th>
            <th mat-sort-header="projectName" style="width: 22%">
              Project&nbsp;&nbsp;Name
            </th>
            <th mat-sort-header="approvertype" class="table-head">
              Approver&nbsp;Type
            </th>

            <!-- <th>Start&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>End&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th> -->
            <th
              style="text-align: center"
              style="border-radius: 0px 15px 0px 0; text-align: center"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody
          style="
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            height: 50px;
          "
        >
          <tr
            *ngFor="
              let approver of approverList
                | mytask : search
                | paginate : { itemsPerPage: mySelect, currentPage: p };
              let i = index
            "
          >
            <!-- <tr> -->
            <td style="text-align: center" *ngIf="p">
              {{ (p - 1) * mySelect + (i + 1) }}
            </td>
            <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
            <!-- <td>{{approver.project.projectCode}}</td>
                    <td>{{approver.project.projectName}}</td> -->
            <td>{{ approver?.approvers?.rulename }}</td>
            <td>{{ approver?.project?.projectName }}</td>
            <td class="table-data" *ngIf="approver?.approverType != undefined">
              {{ approver?.approverType?.approverTypeName }}
            </td>
            <td
              class="table-data text-center"
              *ngIf="approver?.approverType == undefined"
            >
              --
            </td>
            <!-- <td>{{approver.project.projectStartDate | date}}</td>
                    <td>{{approver.project.projectEndDate | date}}</td> -->
            <td style="text-align: center" class="more">
              <span
                class="material-icons icons"
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                more_vert
              </span>

              <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item *ngIf="user_type != 'Employee'" (click)="viewEmployee(approver)">
                          <mat-icon>streetview</mat-icon>
                          <span>Assign Employees</span>
                        </button> -->
                <button mat-menu-item (click)="editprojectapprover(approver)">
                  <mat-icon class="edit-icon">create</mat-icon>
                  <span class="edit-form">Edit</span>
                </button>
                <!-- <button mat-menu-item *ngIf="user_type != 'Employee'">
                          <mat-icon>delete_forever</mat-icon>
                          <span>Delete</span>
                        </button>  -->
              </mat-menu>
            </td>
          </tr>
          <!-- <tr *ngIf="(approverList | mytask : search).length === 0 && search">
                    <td colspan="/* number of columns */" style="
                     text-align: center;
    position: relative;
    left: 352px;
    font-size: 19px;
    font-weight: 600;">  Assign Approvers Not Found
    
  
  </td>
                  </tr> -->
          <tr>
            <td
              style="text-align: center; font-size: larger; font-weight: bold"
              colspan="9"
              *ngIf="
                nodata ||
                ((approverList | mytask : search).length === 0 && search) ||
                approverList.length == 0
              "
            >
              Assign Approvers Not Found!
            </td>
          </tr>
        </tbody>
      </table>
      <!--            
            <div
      class="pagination-content"
      *ngIf="approverList.length > 5 && (approverList | mytask : search).length > 0"
      id="page"
    >
      <pagination-controls
        style="position: relative; float: inline-end"
        (pageChange)="p = $event"
        align="end"
      ></pagination-controls>
    </div> -->
      <div
        class="pagination-content"
        *ngIf="
          approverList.length > 5 && (approverList | mytask : search).length > 0
        "
        id="page"
      >
        <pagination-controls
          *ngIf="(approverList | mytask : search).length > 5"
          style="position: relative; float: inline-end"
          (pageChange)="p = $event"
          align="end"
        ></pagination-controls>
      </div>
    </div>

    <!-- <div class="card-content" *ngIf="nodata">
            <div class="card-body">
                <div class="alert alert-primary">
                  <h4 align="center" style="    font-size: 25px;
                  font-family: 'Mulish', sans-serif;
                  font-weight: 500;
                  padding-top: 5px;" >Assign Approvers Not 
                
                
                </h4>
                </div>
            </div>
          </div> -->
    <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
            <div class="card-body-nodata">
              <div class="alert-nodata alert-primary">
             
                <h4 align="center" class="h4" style="    margin-top: 14px;
                font-size: 24px;
                font-family: Roboto, Helvetica Neue, sans-serif;
                margin-bottom: 11px;
                ">Assign Approvers Not Found</h4>
              </div>
            </div>
          </mat-card> -->
  </div>
</mat-card>

<!-- </div> -->
<!-- </div> -->
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 2rem;margin-left:1100px;color: #1A83FF;" role="status"></div>
</div> -->
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="width: 3rem; height: 3rem"></div>
</div>
