import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-employeeworkingdays',
  templateUrl: './employeeworkingdays.component.html',
  styleUrls: ['./employeeworkingdays.component.scss']
})
export class EmployeeworkingdaysComponent implements OnInit {

  length: number;
  dataArray: any = [];
  financialYear: any;
  period: string;
  employeeWorkingDaysId: any;
  users(users: any) {
    throw new Error('Method not implemented.');
  }
  empForm: FormGroup;
  today: any = new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  usertData: any;
  orgId: number;
  clientList: any;
  empList: any = [];
  empNum: number;
  nodatafound: boolean = true;
  update_error_msg: any;
  loader: any = false;
  backdrop: any = false;
  resultData: any = [];
  noRecordsFound: boolean = false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate: any;
  filterenddate: any;
  clientId: any;
  pagePerItem: any = 10;
  p: number = 1;
  tableSearch: any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg: any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported: any;
  exportingdata: any;
  beforepage: any;
  dateFormat: any;
  finYearList: any = [];
  chooseFinId: any;
  finPeriodList: any = [];
  empCode: boolean = false;
  empworkingDays: any = [];
  selectedEmployeeData: any = [];
  financialPeriodList: any = [];
  employeetData: any;
  userdata: any;
  reportData: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  data: any[];
  // pagePerIist:any=10;
  startIndex: number;
  workindaysId :any;

  constructor(private fb: FormBuilder,
    private payrollservice: PayrollService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,) { }


  ngOnInit(): void {
   
    this.data = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`);
    this.updatePagedData();

    this.empForm = this.fb.group({
      financialyear: [null, [Validators.required]],
      period: [null, [Validators.required]],
      employeecode: [null, [Validators.required]],
      client : [null],
      workingDays: this.fb.array([], [Validators.required]),
      // employeeId:['']
    })

   // this.addRow();
    this.employeeWorkingDaysId = this.ActivatedRoute.snapshot.params.employeeWorkingDaysId;
    console.log(this.employeeWorkingDaysId);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if (item.financialYearStatus.listTypeValueId == 1) {
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });

    });

     
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })    

    
  

    if (this.employeeWorkingDaysId) {
      console.log("if patch");
      // this.selectedEmployeeData=[];
      
      this.payrollservice.getEmployeeWorkingDays(this.employeeWorkingDaysId).subscribe((userdata: any) => {
        console.log('userdata', userdata)
        this.userdata = userdata;
        this.empForm.patchValue({
          financialyear: this.userdata.financialPeriod.finanicalYear.financialYearId, // Replace with the desired value
          period: this.userdata.financialPeriod.financialPeriodId, // Replace with the desired value
          employeecode: this.userdata.employee.employeeNumber,
          client:this.userdata.client.clientId
          // employeeNumber: 'EMP001', // Replace with the desired value
        });
        console.log("this.postForm", this.empForm)
        // Adding a row with leaveDays

        const updateData = {
          employeeId: this.userdata.employee.employeeId,
          employeeNumber: this.userdata.employee.employeeNumber,
          firstName: this.userdata.employee.firstName,
          lastName: this.userdata.employee.lastName,
          workingDays: this.userdata.workingDays,
          otHours : this.userdata.otHours,
          arrearDays: this.userdata.arrearDays

        }
        console.log("updateData", updateData);
        this.selectedEmployeeData.push(updateData);

        console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
        const rowsArray = this.empForm.get('workingDays') as FormArray;
        console.log("Data:"+rowsArray);
        this.addRow();
        rowsArray.at(0).patchValue({
          employeeId: userdata.employee.employeeId,
          employeeNumber: userdata.employee.employeeNumber,
          firstName: userdata.employee.firstName,
          lastName: userdata.employee.lastName,
          workingdays: this.userdata.workingDays,
          otHours : this.userdata.otHours,
          arrearDays: this.userdata.arrearDays,
          employeeWorkingDaysId: this.userdata.employeeWorkingDaysId
        })

        this.employeeId = userdata.employee.employeeId;
        this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
          console.log(resultData);
          // resultData.forEach((fYear) => {
          //   if (fYear.financialYearStatus.listTypeValueId == 1) {
          //   // this.financialYearList = resultData;
          // }
          // });

          this.settingsService.getFinancialYearId(this.empForm.value.financialyear).subscribe((resultData1: any) => {
            console.log("resultData1", resultData1.finanicalPeriod)

            this.finPeriodList = resultData1.finanicalPeriod;
            console.log(this.userdata.finanicalPeriod);
            this.empForm.get('period').patchValue(this.userdata.financialPeriod.financialPeriodId);
            console.log("this.financialPeriodList", this.financialPeriodList);

          });

        });

      })
      // }
    }

  }
  // notZeroValidatorAsync(control: AbstractControl) {
  //   console.log("not zero")
  //   const value = control.value;
  //   return new Promise((resolve) => {
  //     if (value === 0. || value === '0.') {
  //       resolve({ notZero: true }); // Return an object with a custom error key if value is zero
  //     } else {
  //       resolve(null); // Return null if the value is not zero, indicating the control is valid
  //     }
  //   });
  // }

  getclientEmployees()
  {
      this.empList.length = 0;
       console.log(this.empForm.value.client)
      if(this.empForm.value.client != null && this.empForm.value.client != "null")
      {
        this.payrollservice.getemployeeclient(this.empForm.value.client).subscribe((ClientEmployees: any) => {
          if(ClientEmployees.length != undefined)
          {
             this.empList = ClientEmployees;
          }
         
        }); 
    }
  }

  notZeroValidatorAsync(control:AbstractControl) {
    const value=control.value;
    // const regex=/^(?!0\.0$)\d+(\.\d)?$/;
    // const regex=/^(?:\d{1,2}(\.\d)?|\d{1,2})$/;
    const regex=/^(?:\d{1,2}(\.\d)?|\d{1,2})$/;
    return new Promise((resolve) => {
    if (!regex.test(value.toString()) ) 
    {
    console.log("notVaild");
    resolve({ notZero:true }); // Return an object with a custom error key if value is zero
    } 
    else 
    {
    console.log("Vaild");
    resolve(null); // Return null if the value is not zero, indicating the control is valid
    }
      });
    }

  get validate() { return this.empForm.controls; }

  addRow() {
    const control = this.empForm.get('workingDays') as FormArray;
    control.push(this.initRows());
    console.log(control);
  }

  initRows() {
    return this.fb.group({
      firstName: [''],
      lastName: [''],
      employeeId: [''],
      employeeNumber: [''],
      employeeWorkingDaysId: [''],
      workingdays: ['', [Validators.required], this.notZeroValidatorAsync],
      otHours: ['', [Validators.required]],
      arrearDays: ['', [Validators.required]],
    });
  }
  onKeyDown(event: KeyboardEvent): void {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit() 
  {
    console.log("this.empForm.value",this.empForm.value);
    console.log("submit");
    this.submitted = true;
    const empworkingDaysControls = this.empForm.get('workingDays') as FormArray;
    console.log(empworkingDaysControls);
    if (this.empForm.invalid) {
      console.log("invalid");
      if(this.empForm.value.employeecode.length!=0&&empworkingDaysControls.length==0)
      {
        console.log("inside form:"+this.empForm.value.employeecode);
        this.empForm.patchValue({
          employeecode:null
        });
        console.log(this.empForm);
        this.error = true;
        this.error_msg = "Please Select Employee";
        setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
      }
      else
      {
        console.log("Employee Code Null")
      }
      // console.log("empworkingDaysControls.length",empworkingDaysControls.length)
      // if(empworkingDaysControls.length != 0){
      //   console.log("empworkingDaysControls.length if",empworkingDaysControls.length)
      //   console.log('ee',empworkingDaysControls.controls.some(control => control.value.empleave === ''))
      //   if(empworkingDaysControls.controls.some(control => control.value.empleave === '') == true){
      //     // alert('Leave Days Should not be empty')
      //     console.log("true")
      //     this.error = true;
      //     this.error_msg = "Leave Days Should not be empty";
      //     setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
      //   }
      // }
      return;
    }

    if (this.employeeWorkingDaysId) //Update 
    {
      this.empworkingDays = [];
      // console.log("Empty",this.empRatings);
      this.reportData = empworkingDaysControls.value;
      console.log("update", this.employeeWorkingDaysId);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      console.log("employeeLeaveControls", empworkingDaysControls)
      for (let i = 0; i < empworkingDaysControls.length; i++) {
        // Check if any Leave Days field is empty
        if (empworkingDaysControls.controls.some(control => control.value.empleave === '')) {
          // Display an error message or handle the empty Leave Days scenario
          // alert('Leave Days Should not be empty')
          console.log("Leave Days Should not be empty")
          this.error_msg = "Leave Days Should not be empty";
          return;
        }
        const employeeId = empworkingDaysControls.at(i).value.employeeId;
        console.log('employeeId', employeeId);
        console.log("Id", empworkingDaysControls.at(i).value.empleaveId);
        if (empworkingDaysControls.at(i).value.employeeWorkingDaysId == undefined) {
          console.log("Id not present", empworkingDaysControls.at(i).value.employeeWorkingDaysId);
          const updateEmployeeLeave = {
            workingDays: this.reportData.at(i).workingdays,
            otHours : this.reportData.at(i).otHours,
            arrearDays: this.reportData.at(i).arrearDays,
            financialPeriod: {
              financialPeriodId: this.empForm.value.period
            },
            organization:
            {
            organizationId:this.orgId
            },
            employee: {
              employeeId: empworkingDaysControls.at(i).value.employeeId
            },
            status: {
              listTypeValueId: 1
            },
            client:{
              clientId:this.empForm.value.client
            }
          };
          this.empworkingDays.push(updateEmployeeLeave);
        }
        else {
          console.log("id present", empworkingDaysControls.at(i).value.employeeWorkingDaysId);
          const updateEmployeeLeave = {
            employeeWorkingDaysId: this.employeeWorkingDaysId,
            workingDays: this.reportData.at(i).workingdays,
            otHours : this.reportData.at(i).otHours,      
            arrearDays: this.reportData.at(i).arrearDays,  
            financialPeriod: {
              financialPeriodId: this.empForm.value.period
            },
            organization:
            {
            organizationId:this.orgId
            },
            employee: {
              employeeId: empworkingDaysControls.at(i).value.employeeId
            },
            status: {
              listTypeValueId: 1
            },
            client:{
              clientId:this.empForm.value.client
            }
          };
          this.empworkingDays.push(updateEmployeeLeave);
        }
      }

      console.log('employeeLeaveArray', this.empworkingDays);
    //  return
      this.payrollservice.updateEmployeeWorkingdays(this.empworkingDays).subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.success = true;
          this.sucess_msg = result.description;
          // this.searchSubmit();
          setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
          // this.clientupdateId = null;
          this.empForm.reset();
          this.submitted = false;
          setTimeout(() => {
            this.router.navigate(["home/employeeworkdays-list"])
          }, redirectMsgTimeOut)
        }
        else {
          // this.error = true;
          // this.error_msg = result.description;
          // this.errormessagejson = result;
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          console.log("err1", result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson', this.errormessagejson);
          this.importerror = true;
        }
      },
        err => {
          // console.log("code",err);
          // console.log("code",err.statusCode);
          // if(err.error.statusCode == 409)
          // {
          console.log("code1", err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut)
          // }

        })
    }
    else 
    {
      this.empworkingDays = [];
      console.log("create");
      console.log(empworkingDaysControls.value);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      this.reportData = empworkingDaysControls.value;
      console.log("this.reportDate", this.reportData);
      for (let i = 0; i < this.reportData.length; i++)
       {
        const employeeId = this.reportData.at(i).employeeId;
        console.log('employeeId', employeeId);
        const createEmployeeLeave = {
          financialPeriod:
          {
            financialPeriodId: this.empForm.value.period
          },
          organization:
          {
            organizationId:this.orgId
          },
          employee:
          {
            employeeId: this.reportData.at(i).employeeId
          },
          workingDays: this.reportData.at(i).workingdays,
          otHours : this.reportData.at(i).otHours,
          arrearDays: this.reportData.at(i).arrearDays,
          status: { listTypeValueId: 1 },
          client:{
            clientId:this.empForm.value.client
          }
        };
        this.empworkingDays.push(createEmployeeLeave);
        console.log(this.empworkingDays);
      }
      //return;
      this.payrollservice.createEmployeeWorkingDays(this.empworkingDays).subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.success = true;
          this.sucess_msg = result.description;
          setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
          this.empForm.reset();
          this.submitted = false;
          // this.searchSubmit();
          setTimeout(() => {
            this.router.navigate(["home/employeeworkdays-list"])
          }, redirectMsgTimeOut)
        }
        else 
        {
          console.log("err1", result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson', this.errormessagejson);
          this.importerror = true;
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          // setTimeout(() => {
          // }, redirectMsgTimeOut)
        }

      }, err => 
      {
        console.log("code", err);
        console.log("code", err.statusCode);
        // if(err.error.statusCode == 409)
        // {
        //   console.log("code1",err.statusCode);
        setTimeout(() => {
          this.error = true;
          this.errorMsg = err.error.description;
        }, redirectMsgTimeOut)
        // }

      })
    }
  }
  get getFormControls() {
    this.dataArray = [];
    const control = this.empForm.get('workingDays') as FormArray;
    // console.log(control);
    this.length = control.length;
    if (control != null) {
      control.controls.forEach((formGroup: FormGroup) => {
        const data = formGroup.value;
        this.dataArray.push(data);
      });
      // console.log(this.dataArray);
      return control;
    }
  }
  selectFinancialyear(event) {
    console.log("Select indidual Employee");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    this.chooseFinId = event.target.value;
    console.log(this.chooseFinId);
    if (this.chooseFinId == null || this.chooseFinId == 'null') {
      console.log('if');
      this.finPeriodList = [];
    } else {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
        this.empForm.patchValue({
          period: null,
        });
      });
    }
  }

  empSearch(event) {
    this.empList = [];
    this.empCode = false;
    console.log(event.target.value);
    this.userId = event.target.value;
    console.log(this.userId);
    // Employee List Api
    // this.empList.length == 0;
    // this.payrollservice.getEmployeeNameList(this.userId, this.orgId).subscribe((result: any) => {
    //   if (result.statusCode == 204) {
    //     console.log('NO Data');
    //     this.empList.length = 0;
    //     this.empCode = true;
    //   }
    //   else {
    //     this.empList = result;
    //     this.empCode = false;
    //     console.log(this.empList);
    //   }
    // })
    this.payrollservice.getClienEmployeeList( this.empForm.value.employeecode , this.empForm.value.client).subscribe((result: any) => {
      this.empList.length = 0;
      if(result.statusCode)
      {
        
      }else{
        this.empList =  result;
      }
   
   })
  }
  empSelect(emp) {

    this.employeeId = emp.employeeId;
    this.firstname = emp.firstName;
    this.lastname = emp.lastName;
    this.empnumber = emp.employeeNumber;
    console.log("select", this.selectedEmployeeData);
    let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
    if (!hasemployee) {

      this.selectedEmployeeData.push(emp);
      const rowsArray = this.empForm.get('workingDays') as FormArray;
      // rowsArray.clear();
      console.log("control", rowsArray);
      rowsArray.push(this.formBuilder.group({
        employeeId: [this.employeeId],
        employeeNumber: [this.empnumber],
        firstName: [this.firstname],
        lastName: [this.lastname],
        workingdays: ['', [Validators.required], this.notZeroValidatorAsync],
        otHours: ['', [Validators.required]],
        arrearDays: ['', [Validators.required]]
      }));
    }

    // }

  }

  close() {
    console.log("close");
    this.router.navigateByUrl('/home/employeeworkdays-list');
  }
  pageChages() {
    console.log("page changes");
    this.p = 1;
  }
  empAllSelect() {
     this.empList=[];
    // this.selectedEmployeeData=[];
    console.log("all Employee");
    console.log("client value" + this.empForm.value.client);
    this.selectedEmployeeData.length = 0;
    if (this.empForm.value.employeecode == "All" &&  this.empForm.value.client == null || this.empForm.value.client == "null") {
      console.log("select", this.selectedEmployeeData);
      this.payrollservice
        .getAllEmployeeByOrganization(this.orgId).subscribe((result: any) => {
          console.log(result);
          if (result.length == 0) {
            console.log("noData");
            // this.empList.length = 0;
          }
          // this.empList=result;
          console.log("All", this.empList);
          result.forEach(element => {
            let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
            if (!hasemployee) {
              this.selectedEmployeeData.push(element);
              console.log('selectedEmployeeData', this.selectedEmployeeData);
              const rowsArray = this.empForm.get('workingDays') as FormArray;
              rowsArray.push(this.formBuilder.group({
                employeeId: [element.employeeId],
                employeeNumber: [element.employeeNumber],
                firstName: [element.firstName],
                lastName: [element.lastName],
                workingdays: ['', [Validators.required], this.notZeroValidatorAsync],
                otHours: ['', [Validators.required]],
                arrearDays: ['', [Validators.required]],
              }));
            }
          });
        })

    }else if (this.empForm.value.employeecode == "All" &&   this.empForm.value.client != null && this.empForm.value.client != "null") {
      console.log("else");
      this.selectedEmployeeData.length = 0;
      this.empList.length = 0;
      this.payrollservice.getemployeeclient(this.empForm.value.client).subscribe((ClientEmployees: any) => {
        if(ClientEmployees.length != undefined)
        {
          this.empList = ClientEmployees;
          // ClientEmployees.forEach(element => {
          //   let hasemployee = this.empList.some(e => e.employeeId == element.employeeId);
          //   if (!hasemployee) {
          //     this.empList.push(element.employee);
          //   }
           
          // });
          const rowsArray = this.empForm.get('workingDays') as FormArray;
          rowsArray.clear();
          this.empList.forEach(element => {
            let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
            if (!hasemployee) {
              this.selectedEmployeeData.push(element);
              console.log('selectedEmployeeData', this.selectedEmployeeData);
             
              
              rowsArray.push(this.formBuilder.group({
                employeeId: [element.employeeId],
                employeeNumber: [element.employeeNumber],
                firstName: [element.firstName],
                lastName: [element.lastName],
                workingdays: ['', [Validators.required], this.notZeroValidatorAsync],
                otHours: ['', [Validators.required]],
                arrearDays: ['', [Validators.required]],
              }));
            }
          });
        }
       
      });


     
   
    }
  }

  isRowValid(index: number): boolean {
    const rowsArray = this.empForm.get('workingDays') as FormArray;
    // console.log(control);
    const row = rowsArray.at(index) as FormGroup;
    return row.valid;
  }

  showerrormessage() {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px', height: 'fit-content', data: this.errormessagejson
    });
    this.error = false;
  }

  // deleteemployee(index) 
  //   {
  //   console.log(index);
  //   console.log(this.dataArray);
  //   if (index != -1) {
  //     this.dataArray.splice(index, 1);
  //     console.log(this.dataArray);
  //   }
  //   }
  deleteemployee(index) {
    // console.log(index + index);
    // if (index != -1) {
    //   const rowsArray = this.empForm.get('workingDays') as FormArray;
    //     if(rowsArray.at(index).value.employeeWorkingDaysId==undefined)
    //     {
    //       rowsArray.removeAt(index);
    //       this.selectedEmployeeData.splice(index, 1);
    //       console.log(this.selectedEmployeeData.length);
    //     }
    //  }
    console.log(index + index);
    if (index != -1) 
    {
      const rowsArray = this.empForm.get('workingDays') as FormArray;
      console.log(rowsArray);
      // for (let i = 0; i < rowsArray.length; i++) {
      const emprattingId = rowsArray.at(index).value.employeeId;
      console.log('emprattingId', emprattingId);
      console.log("Id", rowsArray.at(index).value.employeeWorkingDaysId);
      if (rowsArray.at(index).value.employeeWorkingDaysId == undefined) 
      {
        rowsArray.removeAt(index);
        this.selectedEmployeeData.splice(index, 1);
        console.log(this.selectedEmployeeData.length);
      }
    }
  }
  onPageChange(event: any): void {
    this.p = event;
    this.updatePagedData();
  }

  updatePagedData(): void {
    const startIndex = (this.p - 1) * this.pagePerItem;
    const endIndex = startIndex + this.pagePerItem;
    console.log(startIndex);
    this.startIndex = startIndex;
    console.log(this.startIndex);
    console.log(endIndex);
  }

  get workingDaysFormArray() : FormArray {
    return this.empForm.controls["workingDays"] as FormArray
   }

}
