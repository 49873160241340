export const environment = {
    production: false,
    base_url: `https://enote.jespersoft.com:6015/api/enote/hrms/`,
    rec_base_url: `https://enote.jespersoft.com:6016/api/enote/recuritment/`,
    pms_base_url: ` https://enote.jespersoft.com:6018/api/enote/pms/`,
    // export const fe_url='http://localhost:4200/#/';
    fe_url: `https://enote.jespersoft.com/#/`,
    leave_base_url: `https://enote.jespersoft.com:6019/api/enote/leave/`,
    time_entry_url: ` https://enote.jespersoft.com:6022/api/enote/timeentry/`,
    api_url: `https://enote.jespersoft.com:6020/api/enote/reimbursement/`

};
