<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">
            {{ clientId ? "Edit" : "Add" }} Client</span
          >
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-md-12">
            <form [formGroup]="clientform" (ngSubmit)="submitData()">
              <!-- <mat-dialog-content class="mat-typography"> -->

              <div class="row">
                <div class="form-group col-md-3" id="loginform">
                  <label class="col-form-label"
                    >Client Code <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="clientCode"
                    id="clientCode"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    class="form-control email-input select-placeholder"
                    value=""
                    placeholder="Enter Client Code"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.clientCode.invalid &&
                        validate.clientCode.touched) ||
                      validate.clientCode.dirty
                    "
                  >
                    <span
                      *ngIf="validate.clientCode.errors?.required"
                      class="text-danger"
                      >Please enter client code</span
                    >
                    <span
                      *ngIf="validate.clientCode.errors?.pattern"
                      class="text-danger"
                      >Please enter valid client code</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3" id="loginform">
                  <label class="col-form-label"
                    >Client Name <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="clientName"
                    id="clientName"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    class="form-control email-input select-placeholder"
                    value=""
                    placeholder="Enter Client Name"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.clientName.invalid &&
                        validate.clientName.touched) ||
                      validate.clientName.dirty
                    "
                  >
                    <span
                      *ngIf="validate.clientName.errors?.required"
                      class="text-danger"
                      >Please enter client name</span
                    >
                    <span
                      *ngIf="validate.clientName.errors?.pattern"
                      class="text-danger"
                      >Please enter valid client name</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                search
                            </mat-icon> -->
                  <label class="col-form-label"
                    >Country<span class="star"> *</span></label
                  >
                  <input
                    type="text"
                    placeholder=" Search Country"
                    formControlName="country"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    [matAutocomplete]="autocountry"
                    class="form-control email-input select-placeholder"
                    (keyup)="countrySearch($event)"
                  />
                  <mat-autocomplete #autocountry="matAutocomplete">
                    <mat-option
                      *ngFor="let country of countrylist"
                      [value]="country.countryId"
                      (click)="countrySelect(country)"
                    >
                      {{ country.countryName }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-icon
                    class="material-symbols-outlined"
                    style="position: absolute; top: 48px; right: 20px"
                  >
                    search
                  </mat-icon>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.country.invalid && validate.country.touched) ||
                      validate.country.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="validate.country.errors?.required"
                      class="text-danger"
                      >Please select country</span
                    >
                    <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                search
                            </mat-icon> -->
                  <label class="col-form-label"
                    >State<span class="star"> *</span></label
                  >
                  <input
                    type="text"
                    placeholder=" Search State"
                    formControlName="state"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    [matAutocomplete]="stateauto"
                    class="form-control email-input select-placeholder"
                    (keyup)="stateSearch($event)"
                  />
                  <mat-autocomplete #stateauto="matAutocomplete">
                    <mat-option
                      *ngFor="let state of statelist"
                      [value]="state.stateId"
                      (click)="stateSelect(state)"
                    >
                      {{ state.stateName }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-icon
                    class="material-symbols-outlined"
                    style="position: absolute; top: 48px; right: 20px"
                  >
                    search
                  </mat-icon>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.state.invalid && validate.state.touched) ||
                      validate.state.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="validate.state.errors?.required"
                      class="text-danger"
                      >Please select state</span
                    >
                    <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                search
                            </mat-icon> -->
                  <label class="col-form-label"
                    >District<span class="star"> *</span></label
                  >
                  <input
                    type="text"
                    placeholder=" Search District"
                    formControlName="district"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    [matAutocomplete]="districtauto"
                    class="form-control email-input select-placeholder"
                    (keyup)="districtSearch($event)"
                  />
                  <mat-autocomplete #districtauto="matAutocomplete">
                    <mat-option
                      *ngFor="let district of disrictList"
                      [value]="district.districtId"
                      (click)="districtSelect(district)"
                    >
                      {{ district.districtName }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-icon
                    class="material-symbols-outlined"
                    style="position: absolute; top: 48px; right: 20px"
                  >
                    search
                  </mat-icon>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.district.invalid &&
                        validate.district.touched) ||
                      validate.district.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="validate.district.errors?.required"
                      class="text-danger"
                      >Please select district</span
                    >
                    <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                search
                            </mat-icon> -->
                  <label class="col-form-label"
                    >City<span class="star"> *</span></label
                  >
                  <input
                    type="text"
                    placeholder=" Search City"
                    formControlName="city"
                    [matAutocomplete]="cityauto"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    class="form-control email-input select-placeholder"
                    (keyup)="citySearch($event)"
                  />
                  <mat-autocomplete #cityauto="matAutocomplete">
                    <mat-option
                      *ngFor="let city of cityList"
                      [value]="city.cityId"
                      (click)="citySelect(city)"
             
                    >
                      {{ city.cityName }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-icon
                    class="material-symbols-outlined"
                    style="position: absolute; top: 48px; right: 20px"
                  >
                    search
                  </mat-icon>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.city.invalid && validate.city.touched) ||
                      validate.city.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="validate.city.errors?.required"
                      class="text-danger"
                      >Please select city</span
                    >
                    <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Postal Code<span class="star"> *</span></label
                  >
                  <input
                    type="text"
                    formControlName="postalCode"
                    maxlength="6"
                    (keyup)="validateInput($event)"
                    (keypress)="noNumber($event)"
                    (keydown)="validateInput($event)"
                    (paste)="blockPaste($event)"
                    (drop)="blockDrop($event)"
                    id="permenantPostalCode"
                    [ngClass]="{
                      'is-invalid': validate && validate.postalCode.errors
                    }"
                    autocomplete="off"
                    class="form-control email-input select-placeholder"
                    value="null"
                    placeholder="Postal Code"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.postalCode.invalid &&
                        validate.postalCode.touched) ||
                      validate.postalCode.dirty ||
                      submitted
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="validate.postalCode.errors?.required"
                      >Please enter  postal code</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="validate.postalCode.errors?.pattern"
                      >Please enter  valid postal code</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >AddressLine 1<span class="star"> *</span></label
                  >
                  <textarea
                    formControlName="addressLine1"
                    id="addressLine1"
                    rows="3"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    class="form-control email-input select-placeholder"
                    placeholder="Enter Address"
                    autocomplete="off"
                  ></textarea>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.addressLine1.invalid &&
                        validate.addressLine1.touched) ||
                      validate.addressLine1.dirty
                    "
                  >
                    <span
                      *ngIf="validate.addressLine1.errors?.required"
                      class="text-danger"
                      >Please enter AddressLine 1</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Address Line 2<span class="star"> *</span></label
                  >
                  <textarea
                    formControlName="addressLine2"
                    id="addressLine2"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    rows="3"
                    class="form-control email-input select-placeholder"
                    placeholder="Enter Address"
                    autocomplete="off"
                  ></textarea>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.addressLine2.invalid &&
                        validate.addressLine2.touched) ||
                      validate.addressLine2.dirty
                    "
                  >
                    <span
                      *ngIf="validate.addressLine2.errors?.required"
                      class="text-danger"
                      >Please enter AddressLine 2</span
                    >
                  </div>
                </div>
                <!-- </div>
            
                    <div class="row"> -->
                <!-- <div class="form-group col-md-3" id="loginform">
                            <label class="col-form-label">PF Number <span class="star">*</span></label>
                            <input type="text" formControlName="pfNumber" id="pfNumber" class="form-select form-select-solid form-select-lg" value="" placeholder="Enter PF number" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.pfNumber.invalid && validate.pfNumber.touched) || validate.pfNumber.dirty">
                                <span *ngIf="validate.pfNumber.errors?.required" class="text-danger">Please enter pf number</span>
                                <span *ngIf="validate.pfNumber.errors?.pattern" class="text-danger">Please enter valid pf number</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3" id="loginform">
                            <label class="col-form-label">ESI Number <span class="star">*</span></label>
                            <input type="text" formControlName="esiNumber" id="esiNumber" class="form-select form-select-solid form-select-lg" value="" placeholder="Enter ESI number" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.esiNumber.invalid && validate.esiNumber.touched) || validate.esiNumber.dirty">
                                <span *ngIf="validate.esiNumber.errors?.required" class="text-danger">Please enter esi number</span>
                                <span *ngIf="validate.esiNumber.errors?.pattern" class="text-danger">Please enter valid esi number</span>
                            </div>
                        </div> -->
                        <!-- <div class="form-group col-md-3" id="loginform">
                            <label class="col-form-label">Working Days <span class="star">*</span></label>
                            <input type="text" formControlName="workingDays" id="workingDays" class="form-control email-input select-placeholder" value="" placeholder=" Working Days" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.workingDays.invalid && validate.workingDays.touched) || validate.workingDays.dirty">
                                <span *ngIf="validate.workingDays.errors?.required" class="text-danger">Please enter working days</span>
                                <span *ngIf="validate.workingDays.errors?.pattern" class="text-danger">Please enter valid working days</span>
                            </div>
                        </div> -->
                     
                  <!-- </div>
                    
                    <div class="row"> -->

                <!-- 
                        <div class="form-group col-md-3" id="loginform">
                            <label class="col-form-label">Location <span class="star">*</span></label>
                            <input type="text" formControlName="location" id="location" class="form-select form-select-solid form-select-lg" value="" placeholder="Enter Location" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.location.invalid && validate.location.touched) || validate.location.dirty">
                                <span *ngIf="validate.location.errors?.required" class="text-danger">Please enter location</span>
                                <span *ngIf="validate.location.errors?.pattern" class="text-danger">Please enter valid location</span>
                            </div>
                        </div> -->
                <div class="form-group col-md-3">
                  <label class="col-form-label">Description</label>
                  <textarea
                    formControlName="clientDescription"
                    (keyup)="validateInput($event)"
                    (keydown)="validateInput($event)"
                    id="clientDescription"
                    rows="3"
                    class="form-control email-input select-placeholder"
                    placeholder="Enter Description"
                    autocomplete="off"
                  ></textarea>
                  <div
                    class="valid-msg"
                    *ngIf="
                      (validate.clientDescription.invalid &&
                        validate.clientDescription.touched) ||
                      validate.clientDescription.dirty
                    "
                  >
                    <span
                      *ngIf="validate.clientDescription.errors?.required"
                      class="text-danger"
                      >Please enter Description</span
                    >
                  </div>
                </div>
              </div>
              <!-- </mat-dialog-content> -->
              <div style="float: right">
                <button 
                type="button" 
                class="btn m-1"
                (click)="formcancel()" 
                mat-raised-button 
                mat-dialog-close 
                [disabled]="!addClientButton"
                style="
                background: none;
                color: var(--Red-Color, #FF5D5D);
                border: 1px solid #ddd;
                font-size: 16px;
                font-weight: 550;
                margin-left: 5px;
                height: 35px;
              "                         >Cancel</button>
                <button type="submit" [disabled]="!addClientButton" mat-raised-button class="searchBtn">
                  Save
                </button>
              </div>

              <!-- <button type="submit" [disabled]="clientform.invalid" mat-raised-button color="primary">{{data.clientId ? "Update" : "Create"}}</button> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loader" class="align-items-cEnter text-cEnter">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 51%"
    role="status"
  ></div>
</div>
<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->

<div class="text-center" *ngIf="success">
  <div class="alert alert-success" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
  <strong>Error!</strong><br />{{ update_error_msg }}
</div>
