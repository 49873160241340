<!-- <div class="d-flex" style="margin-top: 70px;">
    <h4 style="margin-bottom: 15px;width: 97%;">Minutes Of Meeting</h4>
    <span class="material-icons" mat-dialog-close  style="float: right;cursor: pointer;color: red;">cancel</span>
  </div> -->
  <div id="editForm" style="position: relative;
  visibility: hidden;
  "></div>
  <nav class="navbar bg"  style="margin-top: 68px;" >
    <div class="container-fluid" >
      <div class="navbar-brand" style="font-size: 22px;font-weight: 800; font-family: 'Mulish', sans-serif;
      ">Update - Minutes of Meeting</div>
    </div>
  </nav>

<mat-card   id="editForm" style="    border-radius: 15px;
border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
background: #FDFDFD;">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class=" col-md-6 ">
          <label class="form-control-label">MOM Name<span class="text-danger"> *</span></label>
          <input matInput class="form-control email-input select-placeholder" 
          formControlName="momName" placeholder="Enter MOM Name" (keypress)="keyPress($event)">
          <!-- <div *ngIf="(f.momName.invalid && submitted) || f.momName.dirty">
            <small *ngIf="f.momName.errors?.required" class="text-danger">MOM name is required</small>
          </div> -->
          <div *ngIf="(submitted && momName.invalid) || momName.dirty">
            <small *ngIf="momName.errors?.required" class="text-danger"
              >Mom Name is required</small
            >
          </div>
          <div
          *ngIf="
            momName.invalid || momName.dirty"
        >
        <small *ngIf="momName.errors?.pattern" class="text-danger"
        >Please enter valid  MOM Name</small
      >
    </div>
        
        </div>
        <div class="col-md-6 ">
          <label class="form-control-label">MOM Date<span class="text-danger"> *</span></label>
          <input matInput [matDatepicker]="picker1" formControlName="momDate" readonly
          [max]="tomorrow"  style="height: 38px;" class="form-control email-input select-placeholder" placeholder="Select MOM Date" (dateChange)="dateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;right: 20px;left: 6px;"> </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <div *ngIf="(f.momDate.invalid && submitted) || f.momDate.dirty">
            <small *ngIf="f.momDate.errors?.required" class="text-danger">MOM Date is required</small>
          </div>
        
        </div>
      </div>
      <div style="position: relative;bottom:15px">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="form-control-label">Client Name<span class="text-danger"> *</span></label>
            <!-- <input matInput formControlName="client" (keypress)="keyPress($event)"
              class="form-control email-input select-placeholder" style="height: 43px;"
              placeholder="Enter Client Name"> -->
              <mat-select formControlName="clientId" (keypress)="keyPress($event)"
              class="form-control email-input select-placeholder" style="height: 43px;"
              placeholder="Enter Client Name">
                <mat-option *ngFor="let e of customerList" [value]="e.clientId">
                    {{e.clientName}}
                </mat-option>
              </mat-select>
            <div *ngIf="(f.clientId.invalid && submitted) || f.clientId.dirty">
              <small *ngIf="f.clientId.errors?.required" class="text-danger">Client Name is required</small>
            </div>
            <!-- <mat-error *ngIf="f.client.hasError('required')">
              Client Name is required
            </mat-error> -->
          </div>
          <div class="form-group col-md-6 ">
            <label class="form-control-label">Meeting Place<span class="text-danger"> *</span></label>
            <input matInput formControlName="meetingPlace" class="form-control email-input select-placeholder"
            placeholder="Enter Meeting Place" >
            <!-- <div *ngIf="(f.meetingPlace.invalid && submitted) || f.meetingPlace.dirty">
              <small *ngIf="f.meetingPlace.errors?.required" class="text-danger">Meeting place is required</small>
            </div> -->
            <!-- <mat-error *ngIf="f.meetingPlace.hasError('required')">
              Meeting Place is required
            </mat-error> -->
            <div *ngIf="(submitted && meetingPlace.invalid) || meetingPlace.dirty">
              <small *ngIf="meetingPlace.errors?.required" class="text-danger"
                >Meeting Place is required</small
              >
            </div>
            <div
            *ngIf="
              meetingPlace.invalid || meetingPlace.dirty"
          >
          <small *ngIf="meetingPlace.errors?.pattern" class="text-danger"
          >Please enter valid Meeting Place</small
        >
      </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 ">
            <label class="form-control-label">Attendees<span class="text-danger"> *</span></label>
            <textarea matInput formControlName="momAttendees" row="200"
           
            style="font-size: 15px; overflow-x: auto; overflow-y: hidden; min-height: 43px; border: none; padding: 8px;"

              placeholder="Enter Attendees" class="form-control email-input select-placeholder"></textarea>
            <!-- <div *ngIf="(f.momAttendees.invalid && submitted) || f.momAttendees.dirty">
              <small *ngIf="f.momAttendees.errors?.required" class="text-danger">Attendees are required</small>
            </div> -->
            <div
            *ngIf="
              momAttendees.invalid || momAttendees.dirty"
          >
          <small *ngIf="momAttendees.errors?.pattern" class="text-danger"
          >Please enter valid Attendees</small
        >
      </div>
     
  <div *ngIf="(submitted && momAttendees.invalid) || momAttendees.dirty">
    <small *ngIf="momAttendees.errors?.required" class="text-danger"
      >Attendees is required</small
    >
  </div>
            <!-- <mat-error *ngIf="f.momAttendees.hasError('required')">
              Attendees are required
            </mat-error> -->
          </div>
          <div class="form-group col-md-6 ">
            <label class="form-control-label">Project Type<span class="text-danger"> *</span></label>
            <mat-select  class="form-control email-input select-placeholder" formControlName="projectValue" 
              placeholder="Select Project Type">
              <mat-option *ngFor="let pro of myProject" (click)="onChange(pro.id)" [value]="pro.id">
                {{pro.value}}
              </mat-option>
            </mat-select>
            <div *ngIf="(f.projectValue.invalid && submitted) || f.projectValue.dirty">
              <small *ngIf="f.projectValue.errors?.required" class="text-danger">MOM type is required</small>
            </div>
            <!-- <mat-error *ngIf="f.projectValue.hasError('required')">
              Project Type is required
            </mat-error> -->
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6" *ngIf="isShow">
            <label class="form-control-label">Project<span class="text-danger"> *</span></label>
            <mat-select formControlName="project" class="form-control email-input select-placeholder"
              placeholder="Project">
              <mat-option *ngFor="let pro of projectList" [value]="pro.projectId">
                {{pro.projectName}}
              </mat-option>
            </mat-select>
            <div *ngIf="(f.project.invalid && submitted) || f.project.dirty">
              <small *ngIf="f.project.errors?.required" class="text-danger">Project is required</small>
            </div>
            <!-- <mat-error *ngIf="f.project.hasError('required')">
              Project Name is required
            </mat-error> -->
          </div>
        </div>
        <div formArrayName="pointsDiscussed" >
          <div class="row">
            <!-- <div class="float-left col-md-12">
              <b>The following points were discussed in the meeting.</b>
              <button type="button" class="btn add-buttons" (click)="add()"><mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon></button>
            </div> -->
            <div class="col-md-12 p-0">
              <div class="col-md-6 float-left">
                <b>The following points were discussed in the meeting.</b>
              </div>
              <div class="col-md-6 float-left">
                <button type="button" class="btn add-buttons" (click)="add()">
                 <mat-icon class="material-symbols-outlined addDesign" style="       /* font-size: 22px; */
                 font-size: 22px;
                 /* margin-left: 2px; */
                 /* margin-bottom: -5px; */
                 /* border: none !important; */">add</mat-icon>
                 </button>
               </div>
            </div>
          </div>
          <!-- <div align="end">
            <button type="button" class="btn add-buttons" (click)="add()">Add</button>
          </div> -->
          <div *ngFor="let con of content().controls; let i=index">
            <!-- <p>{{i}}</p> -->
            <div [formGroupName]="i" class="d-flex">
              <div class="form-group" style="width: 95%;">
                <label class="form-control-label">Discussed Points<span class="text-danger"> *</span></label>
                <textarea formControlName="pointsDiscussedDetails" matInput 
              

                  class="form-control email-input select-placeholder"
                  placeholder="Enter Discussed Points" ></textarea>
                <!-- <div *ngIf="(submitted && con['controls'].pointsDiscussedDetails.invalid) || con['controls'].pointsDiscussedDetails.dirty">
                  <small *ngIf="con['controls'].pointsDiscussedDetails.errors?.required" class="text-danger">Discussed points are required</small>
                </div>  -->
                <div *ngIf="con['controls'].pointsDiscussedDetails">
                  <div *ngIf="((submitted && con['controls'].pointsDiscussedDetails.invalid) || con['controls'].pointsDiscussedDetails.dirty) && (con['controls'].pointsDiscussedDetails.errors?.pattern)">
                    <small class="text-danger">Please enter valid Discussed Points Details</small>
                  </div>
                 
                  <div *ngIf="((submitted && con['controls'].pointsDiscussedDetails.invalid) || con['controls'].pointsDiscussedDetails.dirty) ">
                    <small *ngIf="con['controls'].pointsDiscussedDetails.errors?.required" class="text-danger">Discussed Points Details is required</small>
                  </div>
                </div>
                <!-- <mat-error *ngIf="content().controls[i].get('contentTitle').errors?.required">
                  Discussed Points is required
                </mat-error> -->
              </div>
              <div class="form-group aligncenter">
                <label></label>
                <mat-icon matSuffix (click)="remove(i,con)" *ngIf="i >= 1"
                class="material-icons-outlined delete-icon" style="    top: 18px;
                ">delete</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="pointsAgreed" >
            
            <div class="col-md-12 p-0">
              <div class="col-md-6 float-left">
                <b>The following points were agreed in the meeting.</b>
              </div>
              <div class="col-md-6 float-left">
               <button type="button"  class="btn add-buttons" (click)="addAgree()" >
                 <mat-icon class="material-symbols-outlined addDesign" style="      /* font-size: 22px; */
                 font-size: 22px;
                 /* margin-left: 2px; */
                 /* margin-bottom: -5px; */
                 /* border: none !important; */">add</mat-icon>
               </button>
              </div>
            </div>
            <div *ngFor="let con of agree().controls; let i=index">
              <!-- <p>{{agree().controls}}</p> -->
              <div [formGroupName]="i" style="width: 96%;">
                <div class="form-group">
                  <label class="form-control-label">Agreed points<span class="text-danger"> *</span></label>
                  <textarea formControlName="pointsAgreedDetails" matInput class="form-control email-input select-placeholder"
               

                  placeholder="Enter agreed points"></textarea>
                  <div *ngIf="(submitted && con['controls'].pointsAgreedDetails.invalid) || con['controls'].pointsAgreedDetails.dirty">
                    <small *ngIf="con['controls'].pointsAgreedDetails.errors?.required" class="text-danger"> Agreed Points are required</small>
                  </div>
                  <div *ngIf="con.get('pointsAgreedDetails')">
                    <div *ngIf="((submitted && con.get('pointsAgreedDetails').invalid) || con.get('pointsAgreedDetails').dirty) && (con.get('pointsAgreedDetails').errors?.pattern)">
                      <small class="text-danger">Please enter valid Agreed Points </small>
                    </div>
                    </div>
                  <!-- <div *ngIf="(submitted && agrecon['controls'].pointsAgreedDetails.invalid) || agrecon['controls'].pointsAgreedDetails.dirty">
                    <small *ngIf="agrecon['controls'].pointsAgreedDetails.errors?.required" class="text-danger">Agreed points are required</small>
                  </div>  -->
                  <!-- <div *ngIf="con.get('pointsAgreedDetails')">
                    <div *ngIf="((submitted && con.get('pointsAgreedDetails').invalid) || con.get('pointsAgreedDetails').dirty) && (con.get('pointsAgreedDetails').errors?.pattern)">
                      <small class="text-danger">Please enter valid  Agreed points Details</small>
                    </div>
                    <div *ngIf="con.get('pointsAgreedDetails').errors?.required && submitted" class="text-danger">
                      <small class="text-danger">points Agreed points Details is required</small>           
                    </div>
                  </div> -->
                  
                </div>
                <div class="form-group aligncenter">
                  <mat-icon matSuffix (click)="removeAgree(i,con)"
                  *ngIf="i >= 1" class="material-icons-outlined delete-icon" style="    top: -50px;
                 ">delete</mat-icon>
                </div>
              </div>
            </div>
          </div>
       
          
        </div>
        <div formArrayName="actionItem">
          <div class="row">
            <!-- <div class="float-left col-md-12">
              <b>The following points are action items in the meeting.</b>
              <button *ngIf="myForm.value.actionItem" type="button" class="btn add-buttons" (click)="addItem()"><mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon></button>
            </div> --> 
            <div class="col-md-12 p-0">
              <div class="col-md-6 float-left">
                <b>The following points were action Items in the meeting.</b>
              </div>
               <div class="col-md-6 float-left">
                 <button type="button" class="btn add-buttons" (click)="addItem()">
                   <mat-icon class="material-symbols-outlined addDesign" style="    /* font-size: 22px; */
                       /* font-size: 22px; */
    font-size: 22px;
    /* margin-left: 2px; */
    /* margin-bottom: -5px; */">add</mat-icon>
                 </button>
               </div>
            </div>
          </div>
          <!-- <div align="end" *ngIf="myForm.value.actionItem">
              <button type="button" class="btn add-buttons" (click)="addItem()">Add</button>
          </div> -->
          <div *ngIf="myForm.value.actionItem">
            <div *ngFor="let action of actionItem().controls; let i=index">
              <div [formGroupName]="i" >
                <div class="row">
                  <div class="col-md-4">
                      <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
                      <input matInput formControlName="actionItemName" (keypress)="keyPress($event)"
                      class="form-control email-input select-placeholder"
                      placeholder="Enter Action Item Name"/>
                      <!-- <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty">
                        <small *ngIf="action['controls'].actionItemName.errors?.required" class="text-danger">Action item name is required</small>
                      </div>  -->
                      <div *ngIf="action.get('actionItemName')">
                        <div *ngIf="action.get('actionItemName').invalid || action.get('actionItemName').dirty">
                          <small *ngIf="action.get('actionItemName').errors?.pattern" class="text-danger">
                            Please enter a valid Action Item Name
                          </small>
                        
                        </div>
                      </div>

                      <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty">
                        <small *ngIf="action['controls'].actionItemName.errors?.required" class="text-danger">Action Item name is required</small>
                      </div> 
                      <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemName').errors?.required">
                        Action Item Name is required
                      </mat-error> -->
                  </div>
                  <div class="col-md-3 ">
                      <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                      <input matInput [matDatepicker]="picker" formControlName="actionItemTargetDate"  readonly
                        class="form-control email-input select-placeholder"  
                        style="height:38px;"
                        placeholder="Target Date"readonly
                        [min]="momdate">
                        <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
                      <mat-datepicker-toggle matSuffix [for]="picker"  placeholder="Select target date"
                      style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                        <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target Date is required</small>
                      </div> 
                      <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemTargetDate').errors?.required">
                        Action Item TargetDate is required
                      </mat-error> -->
                  </div>
                  <div class="col-md-5  M-responsive">
                      <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                      <input matInput formControlName="actionItemOwner" (keypress)="keyPress($event)"
                      placeholder="Enter Action Item Owner"
                      class="form-control email-input select-placeholder"/>
                      <div *ngIf="action.get('actionItemOwner')">
                        <div *ngIf="action.get('actionItemOwner').invalid || action.get('actionItemOwner').dirty">
                          <small *ngIf="action.get('actionItemOwner').errors?.pattern" class="text-danger">
                            Please enter a valid Action Item Owner 
                          </small>
                        
                        </div>
                      </div>

                      <div *ngIf="(submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty">
                        <small *ngIf="action['controls'].actionItemOwner.errors?.required" class="text-danger">Action Item Owner is required</small>
                      </div> 
                  </div>
                  <!-- <div class="aligncenter1"> -->
                    <!-- <mat-icon matSuffix (click)="removeItem(i,action)" *ngIf="i >= 0" 
                      class="material-icons-outlined deleteitem-icon" style=" left: 456px;">delete</mat-icon> -->
                      <!-- <mat-icon matSuffix (click)="removeItem(i,action)"
                      *ngIf="i >= 0" class="material-icons-outlined delete-icon" style=" top: -50px;
                     ">delete</mat-icon>
                    </div> -->
                    <div class="form-group aligncenter">
                      <mat-icon matSuffix (click)="removeItem(i,action)"
                      *ngIf="i >= 0" class="material-icons-outlined delete-icon" style="       top: -69px;
    left: 97%;
                     ">delete</mat-icon>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div align="end">
          <button mat-raised-button type="button" class=" cancel-button m-1"
          mat-dialog-close  style="color: var(--Red-Color, #FF5D5D);" (click)="cancel()">Cancel</button>
          <button mat-raised-button type="submit" class=" create-button m-1" [disabled]="isbuttondisable" style="border: none;">
            update</button>
        </div>
      <!-- </div> -->
    </form>
    </mat-card>
    <div class="text-center" *ngIf="success"  id="editForm">
      <div class="alert alert-success toast-msg" role="alert" style="">
          <strong>Success!</strong><br>{{sucess_msg}}
      </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg" style="margin-top: 67px;">
      <strong>Error!</strong><br>{{errorMsg}}
      <button *ngIf="importerror" (click)="showerrormessage()"   class="btn btn-danger">view report recoeds
      </button>
  </div>


    
  