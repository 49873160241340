import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../../providers/settings.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../../../providers/properties';

@Component({
  selector: 'app-add-employee-approvers',
  templateUrl: './add-employee-approvers.component.html',
  styleUrls: ['./add-employee-approvers.component.scss']
})

export class AddEmployeeApproversComponent implements OnInit 
{
  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any;
  branchId: any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  employeetData:any;

  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private ActivatedRoute:ActivatedRoute,
  ) 
  { 
    
  } 

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      approverHeaderId : ['',[Validators.required]],
      empId : ['',[Validators.required]],
     });
     
     this.branchId = this.ActivatedRoute.snapshot.params.branchId;

     if(this.branchId)
    {
      this.settingsservice.getBranchById(this.branchId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          branchCode: this.resultData.branchCode,
          branchName: this.resultData.branchName,
          branchDescription: this.resultData.branchDescription,
          phoneNumber: this.resultData.phoneNumber,
          alternativePhoneNumber: this.resultData.alternativePhoneNumber,
          branchAddress: this.resultData.branchAddress,
          branchEmail: this.resultData.branchEmail,
        });
      });
    }
  }
  get validate() { return this.postForm.controls; }

  onSubmit()
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.submitted = true;

    if (this.postForm.invalid) 
    {
      return;
    }

    if(this.branchId) //Update Branch
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['branchId'] = this.branchId;
      postValues['branchStatus'] = this.resultData.branchStatus;

      this.settingsservice.editBranch(this.branchId,postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }

        //this.router.navigate([ '/home/branch' ]);
      }, err =>{

        this.errorMsg = err.error.message;

      })
    }
    else  //Add Branch
    {
        let postValues = this.postForm.value;
        postValues['organization'] = {organizationId: organizationId}

      this. settingsservice.createBranch(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, redirectMsgTimeOut)
        }

      }, err =>
      {
          this.errorMsg = err.error.message;

      })
    }
  }

}
