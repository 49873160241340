import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalserviceService } from '../providers/globalservice.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { SuperadminService } from '../providers/superadmin.service';
import { AdminService } from '../providers/admin.service';

@Component({
  selector: 'app-corebusinessunit',
  templateUrl: './corebusinessunit.component.html',
  styleUrls: ['./corebusinessunit.component.scss']
})
export class CorebusinessunitComponent implements OnInit {
nodatafound: any;
resultData: any;
tableShow: boolean=false;
mySelect: number=5;
searchForm:FormGroup;
p:number
pagePerItem: number=5;
tableSearch: any;
  success: boolean;
  sucess_msg: any;
  error: boolean;
  error_msg: any;
loader: any;
  businessValue: any;
  legalValue: any;
btnDisabled: boolean=false;
search: any;
  storeEntityId: any;
 

  constructor( private formBuilder: FormBuilder,
    private router: Router,
    private datepipe: DatePipe,
    private gs: GlobalserviceService,
    private adminservice:AdminService,
    private SuperadminService:SuperadminService,
    private ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);
    
    


    this.searchForm=this.formBuilder.group({
      // legalEntityname:[''],
      
      legalEntityname: [
        '',
        [
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      // businessunitName:['']
      businessunitName: [
        '',
        [
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ], 

    })

    

  //  this.getAllbusinessUnitList()
  }
  getAllbusinessUnitList(){
    this.tableShow=false;
    this.loader=true

    this.SuperadminService.getAllbusinessUnit(this.storeEntityId).subscribe((response:any)=>{
      this.resultData=response;
      this.tableShow=true;
      this.loader=false;
      console.log("resuldata",this.resultData);

      if(this.resultData.statusCode==204){
        this.nodatafound=true;
        this.resultData=[];
        this.tableShow=true
      }
      this.nodatafound=false;
      

    })
  }
  changefirstpage() {
    this.p = 1;
    }
    clearall(event:any){
      // this.getAllbusinessUnitList()
      this.nodatafound = false;

      const inputValue=event.target.value;
      this.tableShow=false;

      // if(inputValue.length==1 && (this.resultData.length==0 || this.resultData.length>0)){
      //   this.SuperadminService.getAllbusinessUnit(this.storeEntityId).subscribe((response:any)=>{
      //     this.resultData=response;
      //     this.tableShow=true; 
      //     this.loader=false;
      //     this.nodatafound = false;

      //     console.log("resuldata",this.resultData);
          
    
      //   })
      }
    clearData(){
      this.nodatafound = false;
      this.searchForm.reset();
      this.tableShow=false;
      // this.getAllbusinessUnitList()
      // this.SuperadminService.getAllbusinessUnit(this.storeEntityId).subscribe((response:any)=>{
      //   this.resultData=response;
      //   this.tableShow=true;
      //   this.loader=false;
      //   console.log("resuldata",this.resultData);
        
  
      // })
    }
    validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }
    searchSubmit(){

      // if(this.searchForm.invalid){
      //   alert("Please Slect Any Field to Search!")
      // }
      let businessValue = this.searchForm.controls['businessunitName'].value?.trim();
      let legalValue = this.searchForm.controls['legalEntityname'].value?.trim();
    
      console.log("Trimmed businessValue:", businessValue);
      console.log("Trimmed legalValue:", legalValue);
    
      // Check if both fields are empty or null
      if ((!businessValue || businessValue === "") && (!legalValue || legalValue === "")) {
        alert("Please Type any Keywords in any Field to Search!!");
        console.log("Both fields are empty or null");
        return;
      }
    
      // Check if only legalValue is provided
      if ((!businessValue || businessValue === "") && legalValue) {
        // alert("Only Legal Value calling!!!");
        console.log("only legal calling!!!");
        

   this.btnDisabled=true;
        this.tableShow=false;
        this.loader=true
        this.adminservice.getLegalOnly(legalValue,this.storeEntityId).subscribe((response:any)=>{

          console.log(response);
          this.btnDisabled=false

          if (response && response.length > 0) {
            this.tableShow = true;
            this.btnDisabled=false
            this.loader=false;
  
            console.log("Search result:", response);
            this.resultData = response;  
            console.log(" this.resultData::", this.resultData);
  
            
            
            this.nodatafound = false;
    
            
          } 
          else {
            console.log("else came!!!");
            if(response.statusCode==204){
              console.log("no data!!");
              this.nodatafound=true;
              this.tableShow=true;
              this.btnDisabled=false
              this.loader=false;
  
              this.resultData=[];
            }
         
          }

       

        })

        return;
      }
    
      // Check if both values are provided
      if (businessValue && legalValue) {
        // alert("Both values calling!!");
        console.log("Both values calling!")
             this.btnDisabled=true;
          this.tableShow=false;
          this.loader=true
          this.adminservice.getbusinessLegalList(businessValue,legalValue,this.storeEntityId).subscribe((response:any)=>{

            console.log(response);
            this.btnDisabled=false
  
            if (response && response.length > 0) {
              this.tableShow = true;
              this.btnDisabled=false
              this.loader=false;
    
              console.log("Search result:", response);
              this.resultData = response;  
              console.log(" this.resultData::", this.resultData);
    
              
              
              this.nodatafound = false;
      
              
            } 
            else {
              console.log("else came!!!");
              if(response.statusCode==204){
                console.log("no data!!");
                this.nodatafound=true;
                this.tableShow=true;
                this.btnDisabled=false
                this.loader=false;
    
                this.resultData=[];
              }
           
            }
  
          })
        return;
      }
    
      // Check if only businessValue is provided
      if (businessValue && (!legalValue || legalValue === "")) {
        // alert("Only Business value calling!!");

            console.log("bussiness only");
        this.btnDisabled=true;
        this.tableShow=false;
        this.loader=true

        this.adminservice.getBusinessOnly(businessValue,this.storeEntityId).subscribe((response:any)=>{

          console.log(response);
          this.btnDisabled=false


          if (response && response.length > 0) {
            this.tableShow = true;
            this.btnDisabled=false
            this.loader=false;
  
            console.log("Search result:", response);
            this.resultData = response;  
            console.log(" this.resultData::", this.resultData);
  
            
            
            this.nodatafound = false;
    
            
          } 
          else {
            console.log("else came!!!");
            if(response.statusCode==204){
              console.log("no data!!");
              this.nodatafound=true;
              this.tableShow=true;
              this.btnDisabled=false
              this.loader=false;
  
              this.resultData=[];
            }
         
          }
          

        })




        return;
      }
      // if((this.businessValue==' '||this.businessValue==null||this.businessValue.length==0)&&(this.legalValue==' '||this.legalValue==null||this.legalValue.length==0)){
      //   alert("Please Type any Keywords in the Field to Search ")
      //   return;
      // }

      // if((this.businessValue!==' '||this.businessValue!==null||this.businessValue.length!=0)&&(this.legalValue==' '||this.legalValue==null||this.legalValue.length==0)){
      //   console.log("bussiness only");
      //   this.btnDisabled=true;
      //   this.tableShow=false;
      //   this.loader=true

      //   this.adminservice.getBusinessOnly(this.businessValue,this.storeEntityId).subscribe((response:any)=>{

      //     console.log(response);
      //     this.btnDisabled=false


      //     if (response && response.length > 0) {
      //       this.tableShow = true;
      //       this.btnDisabled=false
      //       this.loader=false;
  
      //       console.log("Search result:", response);
      //       this.resultData = response;  
      //       console.log(" this.resultData::", this.resultData);
  
            
            
      //       this.nodatafound = false;
    
            
      //     } 
      //     else {
      //       console.log("else came!!!");
      //       if(response.statusCode==204){
      //         console.log("no data!!");
      //         this.nodatafound=true;
      //         this.tableShow=true;
      //         this.btnDisabled=false
      //         this.loader=false;
  
      //         this.resultData=[];
      //       }
         
      //     }
          

      //   })

      // }
      // else if((this.businessValue==' '||this.businessValue==null)&&(this.legalValue!==' '||this.legalValue!==null)){
      //   console.log("legal only");
      //   this.btnDisabled=true;
      //   this.tableShow=false;
      //   this.loader=true
      //   this.adminservice.getLegalOnly(this.legalValue,this.storeEntityId).subscribe((response:any)=>{

      //     console.log(response);
      //     this.btnDisabled=false

      //     if (response && response.length > 0) {
      //       this.tableShow = true;
      //       this.btnDisabled=false
      //       this.loader=false;
  
      //       console.log("Search result:", response);
      //       this.resultData = response;  
      //       console.log(" this.resultData::", this.resultData);
  
            
            
      //       this.nodatafound = false;
    
            
      //     } 
      //     else {
      //       console.log("else came!!!");
      //       if(response.statusCode==204){
      //         console.log("no data!!");
      //         this.nodatafound=true;
      //         this.tableShow=true;
      //         this.btnDisabled=false
      //         this.loader=false;
  
      //         this.resultData=[];
      //       }
         
      //     }

       

      //   })

      // }
      // else{
      //   if((this.businessValue!==' '||this.businessValue!==null||this.businessValue.length!=0)&&(this.legalValue!==' '||this.legalValue!==null)){
      //     console.log("both");
      //     this.btnDisabled=true;
      //     this.tableShow=false;
      //     this.loader=true
      //     this.adminservice.getbusinessLegalList(this.businessValue,this.legalValue,this.storeEntityId).subscribe((response:any)=>{

      //       console.log(response);
      //       this.btnDisabled=false
  
      //       if (response && response.length > 0) {
      //         this.tableShow = true;
      //         this.btnDisabled=false
      //         this.loader=false;
    
      //         console.log("Search result:", response);
      //         this.resultData = response;  
      //         console.log(" this.resultData::", this.resultData);
    
              
              
      //         this.nodatafound = false;
      
              
      //       } 
      //       else {
      //         console.log("else came!!!");
      //         if(response.statusCode==204){
      //           console.log("no data!!");
      //           this.nodatafound=true;
      //           this.tableShow=true;
      //           this.btnDisabled=false
      //           this.loader=false;
    
      //           this.resultData=[];
      //         }
           
      //       }
  
      //     })
      //   }
      // }
     
    }

    Add_businessunit(){
      this.router.navigateByUrl("/home/add-business-unit")
    }
    updateStatus(id,status)
    {
      this.SuperadminService.updateBusinessStatus(id,status).subscribe((result:any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
          const updatedRow = this.resultData?.find((row) => row.businessUnitId === id);
          if (updatedRow) {
            updatedRow.businessUnitStatus = status; // Update the status in the current data
          }
          setTimeout(() => {this.success  = false;},5000)  
          // this.getAllbusinessUnitList();
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, 5000)
        }
      })
    }

}
