import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { DatePipe } from '@angular/common';
import { Sort } from '@angular/material/sort';
// import { ViewdescripeComponent } from '../viewdescripe/viewdescripe.component';
import { ViewdescripeComponent } from '../viewdescripe/viewdescripe.component';

@Component({
  selector:'app-viewtimesheetreport',
  templateUrl:'./viewtimesheetreport.component.html',
  styleUrls: ['./viewtimesheetreport.component.scss']
})
export class ViewtimesheetreportComponent implements OnInit {
  tableShow:boolean =  false;
  p: any=1;
  loader:boolean =  true;
  submittedreport:any=[];
  employeeId:any;
  today:any=new Date();
  timesheetDate: any;
  beforesearchpage: any;
  currenttimesheetDate:any;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelectpopup:any =5;
  search: any;
  page:any;
  beforesearchpagepopup: any;
  nodata = true;
  tasktotalTime:any;
  storeEmpId: any;
  mySelect: any = 5;


  constructor(
    public dialog: MatDialog,
    private gs: ProjectManagementService,
    private datepipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.today = new Date();
    console.log('today date',this.today);
    
    this.currenttimesheetDate = this.datepipe.transform(this.today, 'YYYY-MM-dd');
    console.log('this.currenttimesheetDate',this.currenttimesheetDate);
   }

  ngOnInit(): void {
    console.log('this.data',this.data);
    // this.employeeId=this.data;
    this.storeEmpId=this.data.employeeId
    console.log(this.storeEmpId);
    console.log("this.data.projectId::",this.data.projectId);
    if (this.data.timesheetDate== '') {
      
    

    this.gs.getDetailSubmittedTimesheetByEmployeeId(this.currenttimesheetDate, this.storeEmpId)
    .subscribe((data)=>{
      console.log(data,'data');
      this.submittedreport =data;
      this.nodata = false;
      this.tasktotalTime = this.submittedreport[0].totalTime;  this.submittedreport =data;
      this.loader = false;
      this.tableShow =  true;
      this.nodata = false;
      this.tasktotalTime = this.submittedreport[0]?.totalTime;
      if (Array.isArray(data)) {
        this.submittedreport = data;
        this.tasktotalTime = this.submittedreport[0]?.totalTime;
      } else {
        this.submittedreport = []; // or handle as needed
      }
    },
    (error) => {
      this.nodata = true;
    });
    // if((this.data.projectId == 0 || this.data.projectId == undefined) && (this.data.timesheetDate!== '' || this.data.timesheetDate!==null) ){

    //   console.log('this.currenttimesheetDate',this.currenttimesheetDate);
    //   console.log("time sheet emplty");
    //   this.tableShow =  false;
    //   this.nodata =  true;

    //   this.gs.getDetailSubmittedTimesheetByEmployeeId(this.currenttimesheetDate, this.storeEmpId)
    //   .subscribe((data)=>{
    //     console.log(data);
    //     this.submittedreport =data;
    //     this.loader = false;
    //     this.tableShow =  true;
    //     this.nodata = false;
    //     this.tasktotalTime = this.submittedreport[0]?.totalTime;
    //     if (Array.isArray(data)) {
    //       this.submittedreport = data;
    //       this.tasktotalTime = this.submittedreport[0]?.totalTime;
    //     } else {
    //       this.submittedreport = []; // or handle as needed
    //     }
    
    //     // if (data.message == 'No Data') {
    //     //   this.nodata = true;
    //     //   this.loader = false;
    //     // }
    //   },
    //   (error) => {
    //     this.nodata = true;
    //   });
    // }
    // {
    // console.log("this.data.timesheetDate::",this.data.timesheetDate);
    
    // if(this.data.timesheetDate==''){
    //   this.timesheetDate=this.currenttimesheetDate
    //   console.log("if came!!");
    //   this.nodata=true
    //   this.loader=false
      
    // }
  }
    else{

    console.log("else came!!");
    

    this.nodata =  true;
      if ((this.data.projectId == 0 || this.data.projectId == undefined) && (this.data.timesheetDate == '' || this.data.timesheetDate==null)) {

        console.log("dateEmpty");
        this.tableShow =  false;
        this.gs.getDetailSubmittedTimesheetByEmployeeId( this.data.timesheetDate,this.storeEmpId)
        .subscribe((data:any)=>{
          
          console.log('data',data);
          // this.loader = true;

          this.submittedreport =data;
          this.loader = false;
          this.tableShow =  true;
          this.nodata = false;
          this.tasktotalTime = this.submittedreport[0]?.totalTime;
          if (Array.isArray(data)) {
            this.submittedreport = data;
            this.tasktotalTime = this.submittedreport[0]?.totalTime;
          } else {
            this.submittedreport = []; // or handle as needed
          }
      
          if (data.message == 'No Data') {
            this.nodata = true;
            this.loader = false;
          }
        },
        (error) => {
          this.nodata = true;
        });
      }
      else if((this.data.projectId != 0 || this.data.projectId == undefined) && (this.data.timesheetDate != '' || this.data.timesheetDate!==null)){
        console.log("else enteringg!!");
        this.tableShow =  false;
        this.gs.getDetailSubmittedTimesheetByEmployeeIdAndProjectId(this.data.timesheetDate, this.storeEmpId,this.data.projectId)
        .subscribe((data:any)=>{
          console.log(data);
          this.submittedreport = data[0];
          this.tableShow =  true;
          this.loader = false;
          this.nodata = false;
          this.tasktotalTime = this.submittedreport[0]?.totalTime;
          if (Array.isArray(data)) {
            this.submittedreport = data;
            this.tasktotalTime = this.submittedreport[0]?.totalTime;
          } else {
            this.submittedreport = []; // or handle as needed
          }
      
          if (data.message == 'No Data') {
            this.nodata = true;
            this.loader = false;
          }

        },
        (error) => {
          this.nodata = true;
        });
      }else{
        this.nodata =  true;
        this.tableShow =  false;
        this.gs.getDetailSubmittedTimesheetByEmployeeId(this.data.timesheetDate,this.storeEmpId)
        .subscribe((data:any)=>{
          console.log(data);
          this.submittedreport =data;
          this.loader = false;
          this.nodata = false;
          this.tableShow =  true;
          this.tasktotalTime = this.submittedreport[0]?.totalTime;
          if (Array.isArray(data)) {
            this.submittedreport = data;
            this.tasktotalTime = this.submittedreport[0]?.totalTime;
          } else {
            this.submittedreport = []; 
          }
      
          if (data.message == 'No Data') {
            this.nodata = true;
            this.loader = false;
          }
        },
        (error) => {
          this.nodata = true;
        });
      }
    }
  }
  changefirstpage() {
    this.p = 1;
  }
  changepage(){
    if(this.search.length != 0)
    {
      this.page = 1;
    }else{
      this.page = this.beforesearchpagepopup;
    }
  }
  x: boolean;
  sort() {
    this.x = !this.x
    if (this.x == true) {

      this.submittedreport.sort(function (a: any, b: any) { return b.empNo - a.empNo });
    }
    else {
      this.submittedreport.sort(function (a: any, b: any) { return a.empNo - b.empNo });
    }
  }
  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return
    }
    if (i.approvalStatusName == 'Pending') {
      return '#ffc107'
    }
    if (i.approvalStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.approvalStatusName == 'Denied') {
      return '#f44336'
    }
  }
  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return
    }
    if (i.task.taskStatusName == 'To Do') {
      return '#ffc107'
    }
    if (i.task.taskStatusName == 'Completed') {
      return '#28a745'
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#f44336'
    }
    if (i.task.taskStatusName == 'Pending') {
      return '#ffc107'
    }

    }
  description(e) {
    console.log(e.description)
    const dialogRef = this.dialog.open(ViewdescripeComponent, {
      width: '500px',
      data: e
    });
  }
  sortData(sort: Sort) {
    const data = this.submittedreport.slice();
    if (!sort.active || sort.direction === '') {
      this.submittedreport = data;
      return;
    }

    this.submittedreport = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'projectName':
          return this.compare(a.project.projectName, b.project.projectName, isAsc);
        case 'taskName':
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case 'employeeName':
          return this.compare(a.employee.employeeName, b.employee.employeeName, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'workedHours':
            return this.compare(a.workedHours, b.workedHours, isAsc);
        case 'taskStatusName':
            return this.compare(a.task.taskStatusName, b.task.taskStatusName, isAsc);


        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
