import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-advance-approver-status',
  templateUrl: './advance-approver-status.component.html',
  styleUrls: ['./advance-approver-status.component.scss']
})
export class AdvanceApproverStatusComponent implements OnInit {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.approval.approver.fyiObject.employeeName);
  }

}
