import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-withdraw-details',
  templateUrl: './withdraw-details.component.html',
  styleUrls: ['./withdraw-details.component.scss']
})
export class WithdrawDetailsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public withdraw_details: any, 
    private fb: FormBuilder, 
    private gs: GlobalserviceService
  ) { }

  ngOnInit() {
    console.log(this.withdraw_details);
   }

}
