import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-assign-employee-error',
  templateUrl: './assign-employee-error.component.html',
  styleUrls: ['./assign-employee-error.component.scss']
})
export class AssignEmployeeErrorComponent implements OnInit {

  p:number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  data: any;

  constructor(@Inject(MAT_DIALOG_DATA) public resultData : any , private dialogref : MatDialogRef<AssignEmployeeErrorComponent> ) { }

  ngOnInit(): void {
    // let data=this.resultData;
     console.log(this.resultData);
     this.data=this.resultData;
     console.log(this.data);
    //   this.resultData = [
    //     {
    //         "statusCode": 409,
    //         "description": "EmployeeClient Alredy Assing StartDate 2023-06-26 EndDate 2023-06-27",
    //         "employeeNumber": "1041",
    //         "firstLogin": false,
    //         "createdBy": 0,
    //         "lastUpdatedBy": 0
    //     }
    // ]
  }

  closedialog()
  {
     this.dialogref.close();
  }
 


}
