<!-- <section>
  <div class="sub-header">
      <div class="col-md-12 mb-3">
          <div class="row">
              <div class="">
                  <span class="h5 sub-content">Leave Adjustment</span>
              </div>
          </div>
      </div>
  </div>
</section> -->

<nav class="navbar  bg" style="margin-top: 67px;">
  <a class="navbar-brand navDesign" style="font-size: 22px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;">Leave Adjustment</a>
  <div class="d-flex">
  <input
        class="form-control me-2"
        type="text"
        placeholder="Search..."
        [(ngModel)]="searchs"
         (input)="validateInput($event)"
         (keyup)="changepage();"
         name="search"  
                 aria-label="Search"
        style="
          width: 250px;
          height: 38px;
          flex-shrink: 0;
          border-radius: 5px;
          background: #fff;position: relative;right: 20px;
        "
      />
   
      <button class="btn" type="submit" 
      [routerLink]="['./../leave-adjustment']"
      style="    background-color: #1a83ff;
      color: whitesmoke;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 610;
      font-family: 'Mulish', sans-serif;
      position: relative;
      /* float: left; */
      right: 10px;">Create Leave Adjustment</button>
    </div>
  
 
</nav>


<div class="common-content">
  <div class="card-new ">
    <div class="card colDesign" style="font-family: Mulish;">
      <div class="card-body">
        <!-- <ng-container *ngIf="loader; else elseLoading" class="loading">
          <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
        </ng-container>
        <ng-template #elseLoading> -->
          <!-- <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="row mb-3 pl-0">
                        <div class="col-md-4 col-xs-4 col-sm-4 col-4">   -->
                            <!-- <input class="--search form-control search-input form-control-lg form-control-solid"
                                   type="text" placeholder="Search.." (keyup)="applyFilter($event)"
                                   name="search"           (input)="validateInput($event)"
                                   >                                                                                                 -->
                        <!-- </div> -->
                        <!-- <div class="col-xl-1 col-lg-1 col-sm-1 col-3 col-xs-1 col-md-1">
                            <button type="submit" mat-raised-button color="primary" class="btn btn-primary --search-btn" style="font-family: Mulish; display: flex;">Search</button>
                        </div>  -->
                        <!-- <div class="col-md-7 col-5"> -->
                          <!-- <button class="apply-button" [routerLink]="['./../leave-adjustment']" color="primary" style="margin: 5px 0px; float: right; font-family: Mulish; display: flex; align-items: center; padding
                          :5px">
                              <mat-icon class="material-icons-outlined apply-button-icon">keyboard_arrow_right</mat-icon> 
                              <span class="content" style="margin-left: 5px;" title="Create Leave Adjustment" >Create Leave Adjustment</span>
                          </button> -->
                      <!-- </div>
                      
                    </div>
                </div>
            </div> -->
        <!-- </div> -->
        
             

        
          <form [formGroup]="leaveReport">
            <div class="row">
              <div class="col-md-3">
                <label class="form-control-label">Search Employee</label>
                <!-- <input type="text" (keyup)="keyFunc($event)" value="" placeholder="Search..."
                  formControlName="employeeId" [matAutocomplete]="temName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" (input)="validateInput($event)"> -->
                  <input type="text"  (keyup)="keyFunc($event,$event)" value="" placeholder="Search..."
                        formControlName="employeeId" [matAutocomplete]="temName" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;" autocomplete="off"           (input)="validateInputs($event)"
                        (keydown)="validateInputs($event)"
                                    (keyup)="validateInputs($event)"
                        (keydown)="handleBackspace($event)"  (keydown.space)="preventSpaceBeforeInput($event)"   >
                  <small class="text-muted">Note : Emp No., Employee Name.</small>
                  <!-- <mat-autocomplete #temName="matAutocomplete">
                    <mat-option *ngFor="let searchData of employeelist" [value]="searchData.employeeId" (click)="empSelect(searchData)">
                      {{searchData.employeeNumber}} - {{searchData.firstName +' '+ searchData.lastName}}
                    </mat-option>
                  </mat-autocomplete> -->
                  <mat-autocomplete #temName="matAutocomplete">
                    <mat-option *ngFor="let searchData of employeeList" [value]="searchData.employeeId" (click)="empSelect(searchData)">
                        {{searchData.employeeNumber}} - {{searchData.firstName}}
                    </mat-option>
                    <mat-option
                    [value]="null"
                    *ngIf="employeeNotFound"
                    (click)="noEmployeeFound()"
                    >No Employee Found</mat-option
                >
                </mat-autocomplete>
                <!-- <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('employeeId').hasError('required')">Please
                  select employee</mat-error> -->
              </div>
              <div class="col-xl-3 col-md-3">
                  <label class="form-control-label">Select Leave Type</label>
                  <mat-select placeholder="Select Leave Type" id="selvalue" formControlName="leaveType"
                  class="form control select-placeholder email-input "  style="background-image:none;outline:none;border-radius: 5px;font-family:Mulish">
                    <mat-option value="0">-- Select Leave Type -- </mat-option>
                    <mat-option *ngFor="let leavetype of leavetypelist" [value]="leavetype.leaveTypeId">
                      {{leavetype.leaveName}}
                    </mat-option>
                  </mat-select>
                <!-- <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('leaveType').hasError('required')">Please
                  select Leave Type</mat-error> -->

              </div>
              <div class="col-xl-3 col-md-4 but max-height">
                <!-- <button mat-stroked-button type="button" class="apply-button" (click)="search()"  [disabled]="isProcessing" style="margin: 40px 5px 0px 0px;background: #1A83FF;border: 1px solid #1A83FF; font-family: Mulish;">Search</button> -->
                <button
                type="button"
                mat-raised-button
                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                (click)="search()"  [disabled]="isProcessing"
              >
                Search
              </button>
                <button mat-stroked-button type="reset" class="apply-buttons" (click)="Clear()"  [disabled]="isProcessing" style="margin: 40px 0px 0px 5px;background: white;border: 1px solid #ddd; font-family: Mulish; color: black; font-size:16px; font-weight:600;top:-18%">Clear</button>
              </div>
            </div>
            <div >
              
              <mat-error class="error_msg" *ngIf="all_null">Choose valid employee.</mat-error>
            </div>
           
          </form>
          
          <!-- <ng-container>
            <div class="table-responsive  " style="border-top-left-radius: 10px; border-top-right-radius: 10px;margin-top: 15px;">
              <div class="">

                <table mat-table [dataSource]="dataS" matSort *ngIf="list_count > 0 && !noData && !loader" class="table  --table-bordered table-border-new"style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
            
                  <ng-container matColumnDef="employeeNumber" sticky>
                    <th mat-header-cell *matHeaderCellDef class="text-center">Emp&nbsp;No </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.employee.employeeNumber}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="employeeName">
                    <th mat-header-cell *matHeaderCellDef>Emp&nbsp;Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.employee.firstName | titlecase}} {{element.employee.lastName | titlecase}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="organizationEmail">
                    <th mat-header-cell *matHeaderCellDef>
                     Organization&nbsp;Email
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.employee.organizationEmail}} </td>
                  </ng-container>
                  <ng-container matColumnDef="LeaveType">
                    <th mat-header-cell *matHeaderCellDef>
                     Leave&nbsp;Type
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.leaveBalance.leaveType.leaveName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="leaveadjustmentdays">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                     Leave&nbsp;Adjustment&nbsp;Days
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.leaveadjustmentdays}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="leaveadjustmenttype" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                      Leave&nbsp;Adjustment&nbsp;Type
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center" [style.color]="statuscolor(element.leaveadjustmenttype)"> {{element.leaveadjustmenttype}} </td>
                  </ng-container>
  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
                </table>
                <div class="--container" *ngIf="noData && !loader">
                  <div class="alert alert-primary" style="width: 100%; text-align: center;">No Adjustment list</div>
                </div>
              </div>
            </div>
            
           
          </ng-container> -->
        <!-- </ng-template> -->
        <div  class="card resource-card" style="border: 0px solid rgba(0,0,0,.125);padding: 5px 20px;" *ngIf="list_count > 0&& (data |searchFilter :searchs).length >5">
          <div class="row rowDesign" *ngIf="list_count >5 && !loader">
              <div class="col-xl-12 col-lg-12" style="margin-top:10px">
                  <div class="select-pagination">
                      <span id="showDesign">Show : </span>
                      <select class="select_list new-select_list" id="listDesign" [(ngModel)]="mySelect" (change)="showChanges()">
                        <option class="form-control" value="5">5</option>
                        <option class="form-control" value="10">10</option>
                        <option class="form-control" value="15">15</option>
                        <option class="form-control" value="20">20</option>
                        <option class="form-control" value="25">25</option>
                      </select>
                  </div>
              </div>
          </div>
        </div>

        <div class="--container-fluid" style="overflow: auto;">
          <div class="table-responsive" *ngIf="!loader" style="border-top-left-radius: 10px; border-top-right-radius: 10px; margin-top: 15px;">
              <table class="table table-responsive-new1 --table-bordered table-border-new" matSort *ngIf="showTable" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                  <thead class="t-head" style="background-color: #f6f9fc;">
                      <tr>
                          <th class="text-center">Emp No</th>
                          <th class="text-center">Emp Name</th>
                          <th class="text-center">Organization Email</th>
                          <th class="text-center">Leave Type</th>
                          <th class="text-center">Leave Adjustment Days</th>
                          <th class="text-center">Leave Adjustment Type</th>
                      </tr>
                  </thead>
                  <tbody class="t-body">
                      <tr *ngFor="let element of data |searchFilter:searchs | paginate: { itemsPerPage: mySelect, currentPage: p }; let i = index">
                          <td class="text-center">{{ element.employee.employeeNumber }}</td>
                          <td class="text-center">{{ element.employee.firstName | titlecase }} {{ element.employee.lastName | titlecase }}</td>
                          <td class="text-center">{{ element.employee.organizationEmail }}</td>
                          <td class="text-center">{{ element.leaveBalance.leaveType.leaveName }}</td>
                          <td class="text-center">{{ element.leaveadjustmentdays }}</td>
                          <td class="text-center" [style.color]="statuscolor(element.leaveadjustmenttype)">{{ element.leaveadjustmenttype }}</td>
                      </tr>
                      <tr>
                        <td
                        style="
                        text-align: center;
                        font-size: larger;
                        font-weight: bold;
                      "
                      colspan="9"
                      *ngIf="noData || ((data |searchFilter :searchs).length === 0 && searchs) ||
                               data.length == 0">
            No Adjustment list                                 </td>
                    </tr>
                  </tbody>
              </table>
              <!-- <div  *ngIf="noData && !loader">
                <div class="alert alert-primary" style="width: 100%; text-align: center;">No Adjustment list</div>
              </div> -->
          </div>
        </div>
        <div *ngIf="list_count >5 && (data |searchFilter :searchs).length >5 ">
          <!-- <div [hidden]="loader "    > -->
            <!-- <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
            </mat-paginator> -->
            <pagination-controls class="float-right pagination" style="float: right;"
            (pageChange)="p = $event; beforepage = $event"></pagination-controls>  
        </div>
        <div *ngIf="loader" class="d-flex align-items-center justify-content-center loader-container" style="margin-top: 10%;" >
          <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;left: 48%;top: 50%;" role="status">
              <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>