<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span routerLink="/home/users" class="h5 sub-content">Add User</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card" style="font-family: Mulish;">
            <div class="card-body">
                <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <!-- <div class="form-group col-md-3">
                            <label class="col-form-label">Employee <span class="star">*</span></label>
                            <select (change)="CheckExistemployeeId($event)" formControlName="employeeId" class="form-select form-select-solid form-select-lg">
                                <option value="">- Select -</option>
                                <option *ngFor="let row of activeEmployeesData" [value]="row.employeeId">{{row.employeeNumber}}</option>
                            </select>
                            <div class="valid-msg" *ngIf="(validate.employeeId.errors && validate.employeeId.touched) || validate.employeeId.dirty || submitted">
                                <span class="text-danger" *ngIf="validate.employeeId.errors?.required">Please select Employee ID</span>
                                <span class="span-error" *ngIf="employeeExistStatus"> Employee Code already exist!</span>
                            </div>
                        </div>
                        -->

                        <div class="form-group col-md-3">
                            <label class="col-form-label">Employee <span class="star">*</span></label>
                            <input type="text" (keyup)="getEmployeeList($event)" formControlName="employeeName" id="employeeName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Employee" autocomplete="off">
                            <input type="hidden" formControlName="employeeId" id="employeeId" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off">

                            <!-- <div class="valid-msg" *ngIf="(validate.employeeName.invalid && validate.employeeName.touched) || submitted">
                                <span *ngIf="validate.employeeName.errors?.required" class="text-danger">Please Enter Employee Name</span>
                                <span class="text-danger" *ngIf="validate.employeeName.errors?.pattern">Please enter valid email</span>
                            </div> -->

                            <div class="valid-msg" *ngIf="(validate.employeeId.invalid && validate.employeeId.touched && autoCompleteSearch == 1) || (validate.employeeName.invalid && validate.employeeName.touched) || submitted">
                                <span *ngIf="validate.employeeId.errors?.required" class="text-danger">Please enter valid employee.</span>
                                <!--  <span class="text-danger" *ngIf="validate.employeeId.errors?.pattern">Please enter valid email</span> -->
                            </div>

                            <!-- AutoComplete Search Start here -->
                            <span *ngIf="autoCompleteSearch == 0 ">
                                <ul class="auto-complete" *ngIf="employeeData?.length > 0">
                                    <li (click)="getFilterData(searchData.employeeId,searchData.firstName,searchData.lastName,searchData.employeeNumber);" *ngFor="let searchData of employeeData">{{searchData.firstName+" "+searchData.lastName}} - {{searchData.employeeNumber}}</li>
                                </ul>
                            </span>

                            <!--  <span *ngIf="autoCompleteSearch == 1 ">
                                <ul class="auto-complete" *ngIf="employeeData.statusCode == 204">
                                    <li (click)="getFilterData(0,0,0,0);">No data found.</li>
                                </ul>
                            </span> -->
                            <!-- AutoComplete Search End here -->

                            <!--<div class="valid-msg" *ngIf="(emailExistStatus || validate.emailId.invalid && validate.emailId.touched) || submitted">
                                <span *ngIf="validate.emailId.errors?.required" class="text-danger">Please Enter Email ID</span>
                                <span class="text-danger" *ngIf="validate.emailId.errors?.pattern">Please enter valid email</span>
                                <span class="span-error" *ngIf="emailExistStatus"> Email already exist!</span>
                            </div>-->
                        </div>
                        <!-- <div class="col-xl-3 col-lg-3 form-group">
                            <select formControlName="roleId" class="form-select form-select-solid form-select-lg">
                              <option value="null" selected> - Select Role - </option>
                              <option *ngFor="let row of activeRolesData; let i=index;" value="{{row.roleId}}">{{row.roleName}}</option>
                            </select>
                        </div> -->

                        <div class="form-group col-md-3">
                            <label class="col-form-label">E Mail <span class="star">*</span></label>
                            <input type="email" (keyup)="CheckExistemailId($event)" formControlName="emailId" id="emailId" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Email" autocomplete="off">
                            <div class="valid-msg" *ngIf="(emailExistStatus || validate.emailId.invalid && validate.emailId.touched) || submitted">
                                <span *ngIf="validate.emailId.errors?.required" class="text-danger">Please Enter Email ID</span>
                                <span class="text-danger" *ngIf="validate.emailId.errors?.pattern">Please enter valid email</span>
                                <span class="span-error" *ngIf="emailExistStatus"> Email already exist!</span>
                            </div>
                        </div>
                        <div *ngIf="!userId" class="form-group col-md-3">
                            <label class="col-form-label">Password <span class="star">*</span></label>
                            <input type="password" formControlName="password" id="password" class="form-control form-control-solid form-control-lg" placeholder="Password" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.password.errors && validate.password.touched) || validate.password.dirty || submitted">
                                <span *ngIf="validate.password.errors?.required" class="text-danger">Please Enter Password</span>
                            </div>
                        </div>
                        <!-- <div class="form-group col-md-3">
                          <label class="col-form-label">Country </label>
                          <select formControlName="country_id" id="country_id" class="form-select form-select-solid form-select-lg fw-bold">
                            <option >Select</option>
                            <option >India</option>
                            <option >Dubai</option>
                          </select>
                      </div>

                      <div class="form-group col-md-3">
                          <label class="col-form-label">State </label>
                          <select formControlName="state_id" id="state_id" class="form-select form-select-solid form-select-lg fw-bold">
                            <option >Select</option>
                            <option >Tamilnadu</option>
                            <option >Andhra</option>
                          </select>
                      </div>

                      <div class="form-group col-md-3">
                          <label class="col-form-label">District </label>
                          <select formControlName="district_id" id="district_id" class="form-select form-select-solid form-select-lg fw-bold">
                            <option >Select</option>
                            <option >Krishnagiri</option>
                            <option >Salem</option>
                          </select>
                      </div>
                      <div class="form-group col-md-3">
                          <label class="col-form-label">City</label>
                          <select formControlName="city_id" id="city_id" class="form-select form-select-solid form-select-lg fw-bold">
                            <option >Select</option>
                            <option >Krishnagiri</option>
                            <option >Hosur</option>
                          </select>
                      </div> -->
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a routerLink="/home/users" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2" style="font-family: Mulish;">
                              Cancel
                          </a>
                            <button type="submit" mat-raised-button color="primary" style="font-family: Mulish;">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
