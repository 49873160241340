import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { HeaderService } from 'src/app/providers/header.service';
import { retry } from 'rxjs';

@Component({
  selector: 'app-payment-advice',
  templateUrl: './payment-advice.component.html',
  styleUrls: ['./payment-advice.component.scss']
})
export class PaymentAdviceComponent implements OnInit {

  update_data_error_msg:any;
  create_sucess_msg:any;
  submmited: boolean=false;
  paymentadviceform: FormGroup;
  loader:any= false;
  backdrop:any= false;
  success:any=false;
  error:any=false;
  btnDisable: boolean=false;
  usersId:any;
  today :any = new Date();
  userdata:any=[];
  usersUpdateData:any=[];
  createdata: any;
  paymentModeType: any;
  paymentId: any;
  expId: any;
  paydata: any;
  paymentData:any=[];
  formatteddate:any;
  constructor(private router:Router, 
    public ActivatedRoute: ActivatedRoute, 
    private datepipe : DatePipe,
    private headerservice: HeaderService,
    private usersservice : ProjectManagementService) { }

  ngOnInit(): void {
    // this.positionId = this.activatedRoute.snapshot.params.positionId;
    this.headerservice.setTitle('Payment Advice');

   this.usersId= this.ActivatedRoute.snapshot.params.updateexpensereburismentId;
    console.log("create PayID",this.usersId);
    
    let listTypeId=4;
     this.usersservice.
    paymentModelist(listTypeId).
    subscribe((data:any)=>
    { 
      
      console.log(data);
      this.paymentModeType = data;
      console.log(this.paymentModeType);
    }
    );
    
    this.expId= this.ActivatedRoute.snapshot.params.exReimbursementId;
    console.log("Payment Update Exp id",this.expId);

    this.paymentId= this.ActivatedRoute.snapshot.params.paymentAdviceId;
    console.log("Payment Update pay iD",this.paymentId);
    
    if(this.paymentId)
    {
      this.loader =true;
      console.log("Update Patch");
      // return
    this.usersservice
    .getexpensereburimentbyId(this.expId)
    .subscribe((userdata:any) =>
    {
         this.loader =false;
         this.btnDisable=false
         this.paymentData=userdata;
         console.log(this.paymentData);
         console.log(this.paymentData.paymentAdvice.paymentMode.listTypeValueId);
         console.log(this.paymentData.paymentAdvice.paymentMode.listTypeValueName);
        this.paymentadviceform.patchValue({
        name : this.paymentData.employee.firstName,
        adviceNo : this.paymentData.paymentAdvice.adviceNo,
        adviceDate : this.paymentData.paymentAdvice.adviceDate,
        paymentDate :this.paymentData.paymentAdvice.payPeriod,
        paymentamount : this.paymentData.paymentAdvice.paymentAmount,
        // paymentMode:{  
        //   listTypeValueId:this.paymentData.paymentAdvice.paymentMode.listTypeValueId
        //   },
         paymentMode:this.paymentData.paymentAdvice.paymentMode.listTypeValueId,
        payPeriodBeginDate:  this.paymentData.paymentAdvice.payPeriodStartDate,
        payPeriodEndDate: this.paymentData.paymentAdvice.payPeriodEndDate, 
        description: this.paymentData.paymentAdvice.description, 

          // emplnameoyeeName : this.userdata.employeeName,
          // employeeNumber: this.userdata.employeeNumber,
          // mobileNumber : this.userdata.mobileNumber,
          // dateOfBirth :this.datepipe.transform(this.userdata.dateOfBirth, 'yyyy-MM-dd'),
          // gender :this.userdata.gender.listTypeValueId,
          // organizationEmail :this.userdata.organizationEmail,  
          // userType : this.userdata.userType.userTypeId,
        
          // password :this.userdata.password         
        });

        
              this.formatteddate =  new Date(this.paymentData.paymentAdvice.payPeriodStartDate);
    });
    this.paymentadviceform = new FormGroup(
      {
        name : new FormControl(this.paymentadviceform,[Validators.required]),
        // adviceNo : new FormControl(this.paymentadviceform,[Validators.required , Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9-]+$")]),
        adviceNo: new FormControl(this.paymentadviceform, [
          Validators.required,
          Validators.pattern("^[0-9]+$")
        ]),        
        adviceDate : new FormControl(this.paymentadviceform,[Validators.required]), 
        paymentDate : new FormControl(this.paymentadviceform,[Validators.required]), 
        paymentamount : new FormControl(this.paymentadviceform,[Validators.required]),
        paymentMode: new FormControl(this.paymentadviceform,[Validators.required]), 
        payPeriodBeginDate: new FormControl(this.paymentadviceform,[Validators.required]), 
        // payPeriodBeginDate: new FormControl(this.paymentadviceform,[Validators.required]), 
        payPeriodEndDate: new FormControl(this.paymentadviceform,[Validators.required]), 
        description: new FormControl(this.paymentadviceform), 

        // approver : new FormControl(this.resourceform)
        // personalEmail : new FormControl(this.resourceform,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]), 
        // confirmpassword : new FormControl(this.resourceform,[Validators.required]),  
      });
    }
    else
    {
      this.loader=true
      console.log("Create Patch");
      this.usersservice
      .getexpensereburimentbyId(this.usersId)
      .subscribe((userdata:any) =>
      {
        this.userdata=userdata;
        this.loader=false
        console.log(this.userdata);
          this.paymentadviceform.patchValue({
          name : this.userdata.employee.firstName,
          // adviceNo : new FormControl(this.paymentadviceform,[Validators.required,Validators.pattern(/^[0-9_a-z A-Z]*$/)]),
          // adviceDate : new FormControl(this.paymentadviceform,[Validators.required,Validators.pattern(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/)]), 
          // paymentDate : new FormControl(this.paymentadviceform,[Validators.required]), 
          paymentamount : this.userdata.totalReimbursement,
          // adviceNo:this.userdata.paymentAdvice.adviceNo,
          // paymentMode: new FormControl(this.paymentadviceform,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]), 
          
          payPeriodBeginDate:  this.userdata.fromDate,
          payPeriodEndDate: this.userdata.toDate, 
          // description:this.userdata.description
          // description: new FormControl(this.paymentadviceform,[Validators.required]), 
  
            // emplnameoyeeName : this.userdata.employeeName,
            // employeeNumber: this.userdata.employeeNumber,
            // mobileNumber : this.userdata.mobileNumber,
            // dateOfBirth :this.datepipe.transform(this.userdata.dateOfBirth, 'yyyy-MM-dd'),
            // gender :this.userdata.gender.listTypeValueId,
            // organizationEmail :this.userdata.organizationEmail,  
            // userType : this.userdata.userType.userTypeId,
          
            // password :this.userdata.password         
          });
          this.formatteddate =  new Date(userdata.fromDate);
      });
      this.paymentadviceform = new FormGroup(
        {
          name : new FormControl(this.paymentadviceform,[Validators.required]),
          adviceNo : new FormControl(this.paymentadviceform,[Validators.required , Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9-]+$")]),
          adviceDate : new FormControl(this.paymentadviceform,[Validators.required]), 
          paymentDate : new FormControl(this.paymentadviceform,[Validators.required]), 
          paymentamount : new FormControl(this.paymentadviceform,[Validators.required]),
          paymentMode: new FormControl(this.paymentadviceform,[Validators.required]), 
          payPeriodBeginDate: new FormControl(this.paymentadviceform,[Validators.required]), 
          // payPeriodBeginDate: new FormControl(this.paymentadviceform,[Validators.required]), 
          payPeriodEndDate: new FormControl(this.paymentadviceform,[Validators.required]), 
          description: new FormControl(this.paymentadviceform), 
  
          // approver : new FormControl(this.resourceform)
          // personalEmail : new FormControl(this.resourceform,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]), 
          // confirmpassword : new FormControl(this.resourceform,[Validators.required]),  
        });
        
    }
 }
  // Method to prevent cut, copy, paste actions
  disableAction(event: ClipboardEvent): void {
    event.preventDefault();
  }

  // Method to restrict input to numbers only
  restrictInput(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  submitData(){
    // console.log("Create");
    this.submmited=true;
    if(this.paymentadviceform.invalid)
    {
      return;
    }

    this.btnDisable=true;
    if(this.paymentId)
    {
      const controls = this.paymentadviceform.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
             console.log("name  : " + name);
          }
      }

      var paymentAdviceUpdateData=
      {
          // name : this.paymentadviceform.value.name,
          // adviceNo : this.paymentadviceform.value.adviceNo,
          // adviceDate : this.paymentadviceform.value.adviceDate,
          // paymentDate : this.paymentadviceform.value.employeeNumber,
          // paymentamount : this.paymentadviceform.value.employeeNumber,
          // paymentMode: this.paymentadviceform.value.paymentMode,
          // payPeriodBeginDate: this.paymentadviceform.value.employeeNumber,
          // // payPeriodBeginDate: new FormControl(this.paymentadviceform,[Validators.required]), 
          // payPeriodEndDate: this.paymentadviceform.value.employeeNumber,
          // description: this.paymentadviceform.value.description
            paymentAdviceId:this.paymentId,
            adviceNo:this.paymentadviceform.value.adviceNo,
            
            // payPeriod:this.paymentadviceform.value.paymentDate,
            
            // adviceDate:this.paymentadviceform.value.adviceDate,
            payPeriod:this.datepipe.transform(this.paymentadviceform.value.paymentDate,'yyyy-MM-dd'),
          
             adviceDate:this.datepipe.transform(this.paymentadviceform.value.adviceDate, 'yyyy-MM-dd'),

            
            paymentTowards:this.userdata.businessPurpose,
            
            payPeriodStartDate:this.paymentadviceform.value.payPeriodBeginDate,
            
            payPeriodEndDate:this.paymentadviceform.value.payPeriodEndDate,
            
            paymentMode:{
            
            listTypeValueId:this.paymentadviceform.value.paymentMode
            
            },
            
            description:this.paymentadviceform.value.description,
            
            paymentAmount:this.paymentadviceform.value.paymentamount,
            
            status: {
            
            listTypeValueId: 1
            
            },
            
            expenseReimbursement:
            
            {
            
            expenseReimbursementId:this.expId
            
            }
      }
        
      console.log(paymentAdviceUpdateData);
      console.log(this.paymentId);
      

                 this.backdrop=true;
                // this.loader=true;
                this.usersservice
                .paymentAdviceUpdate(paymentAdviceUpdateData,this.paymentId)
                .subscribe( (data:any) =>
                {
                  this.usersUpdateData= data;
                  console.log(this.usersUpdateData);
                  this.backdrop = false;
                  this.loader = false;
                  this.success = true;
                  this.create_sucess_msg = this.usersUpdateData.message;
                  console.log(this.create_sucess_msg);
                    setTimeout(() => {
                      this.router.navigate(['home/expenseReimbursement']); 
                    }, 4000)
                  },
                  (err) =>
                  {
                    console.log("err::",err);
                    
                    console.log("error came in update!!");
                    console.log("err.error.statusCode::",err.error.statusCode);
                    

                    if(err.error.statusCode===409){
                      this.error = true;
                      this.update_data_error_msg =err.error.description;

                      console.log(this.update_data_error_msg);
                    }
                    this.backdrop = false;
                    this.loader = false;
                    // this.error = true;
                    // this.update_data_error_msg = err.error.message;
                    // console.log(this.update_data_error_msg);
                    setTimeout(() =>{this.error = false , this.update_data_error_msg=false,this.btnDisable = false;
                    }, 4000);     
                  });  
           }
    else
    {
      console.log(this.paymentadviceform.value.adviceDate);
      console.log(this.paymentadviceform.value.paymentDate);
    var paymentAdviceData=
    {
        // name : this.paymentadviceform.value.name,
        // adviceNo : this.paymentadviceform.value.adviceNo,
        // adviceDate : this.paymentadviceform.value.adviceDate,
        // paymentDate : this.paymentadviceform.value.employeeNumber,
        // paymentamount : this.paymentadviceform.value.employeeNumber,
        // paymentMode: this.paymentadviceform.value.paymentMode,
        // payPeriodBeginDate: this.paymentadviceform.value.employeeNumber,
        // // payPeriodBeginDate: new FormControl(this.paymentadviceform,[Validators.required]), 
        // payPeriodEndDate: this.paymentadviceform.value.employeeNumber,
        // description: this.paymentadviceform.value.description
          adviceNo:this.paymentadviceform.value.adviceNo,
          
          payPeriod:this.datepipe.transform(this.paymentadviceform.value.paymentDate,'yyyy-MM-dd'),
          
          adviceDate:this.datepipe.transform(this.paymentadviceform.value.adviceDate, 'yyyy-MM-dd'),
          
          paymentTowards:this.userdata.businessPurpose,
          
          payPeriodStartDate:this.paymentadviceform.value.payPeriodBeginDate,
          
          payPeriodEndDate:this.paymentadviceform.value.payPeriodEndDate,
          
          paymentMode:{
          
          listTypeValueId:this.paymentadviceform.value.paymentMode
          
          },
          
          description:this.paymentadviceform.value.description,
          
          paymentAmount:this.paymentadviceform.value.paymentamount,
          
          status: {
          
          listTypeValueId: 1
          
          },
          
          expenseReimbursement:
          {
            expenseReimbursementId:this.usersId
          
          },

          // createdBy:this.paymentadviceform.value.createdBy
          
          

    }
      console.log("pay mode",paymentAdviceData);
    this.usersservice
    .paymentAdviceCreate(paymentAdviceData)
    .subscribe( (data:any) =>
    {
      this.createdata= data;
      console.log(this.createdata);
      this.backdrop = false;
      this.loader = false;
      this.success = true;
      this.create_sucess_msg = this.createdata.message;


      console.log(this.create_sucess_msg);
        setTimeout(() => {
          this.router.navigate(['home/expenseReimbursement']); 
        }, 3000)
        

       
      },
      (err) =>
      {
        console.log("err",err);
        console.log("err.error.errorCode",err.error.statusCode);
        console.log( err.error.description);
        
        if(err.error.description==='Advice No Already Exists'){
          this.error = true;
          this.update_data_error_msg =err.error.description;
          console.log(this.update_data_error_msg);
        }
        
        this.btnDisable = false;
        this.backdrop = false;
        this.loader = false;
      
      
        setTimeout(() =>{this.error = false;
          this.update_data_error_msg=false;
          this.btnDisable=false

        }, 3000);  
      });
    }
  }
  
  get name()
  {  
    return this.paymentadviceform.get('name');  
  }
  get adviceNo()
  {
    return this.paymentadviceform.get('adviceNo');  
  }
  get adviceDate()
  {
    return this.paymentadviceform.get('adviceDate');  
  }
  get paymentDate()
  {
    return this.paymentadviceform.get('paymentDate');  
  }
  get paymentamount()
  {
    return this.paymentadviceform.get('paymentamount');  
  }
  get paymentMode()
  {
    return this.paymentadviceform.get('paymentMode');  
  }
  get payPeriodBeginDate()
  {
    return this.paymentadviceform.get('payPeriodBeginDate');  
  }
  get payPeriodEndDate()
  {
    return this.paymentadviceform.get('payPeriodEndDate');  
  }
  get description()
  {
    return this.paymentadviceform.get('description');  
  }

  formcancel(){
    this.router.navigate(['home/expenseReimbursement']); 
  }

   
}
