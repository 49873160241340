import { Component, HostListener, OnInit } from '@angular/core';
import { MatSort,Sort } from '@angular/material/sort';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HeaderService } from '../../../../app/providers/header.service';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-mytask',
  templateUrl:'./mytask.component.html',
  styleUrls:['./mytask.component.scss']
})
export class MytaskComponent implements OnInit {
  tableShow:boolean = false;
  isData: boolean;
  // taskListForm:FormGroup;
  Id: any;
  myData: any;
  user_type: string
  search: any;
  projectName: any = {};
  taskEmpList: any = [];
  taskList: any=[];//TaskList Array
  listCount:any;
  employeeId:any;
  menuData: any = [];
  p:number= 1;
  taskShow: boolean
  beforesearchpage: any;
  mySelect:any =5;
  nodata = false;
  endSelectedFirst: boolean;

  // endDate_disable: boolean  =true;


  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  enddate_required:any;
  startdate_required:any;
  startDate: any;
  // endDate:Date|null=null;
  endDate:any
  selectedetaskGroupId: any;
  today:any;
  taskgrouplist: any;
  taskStatusId:any;
  taskgroup_required:any;
  loader: boolean = true;
   errorMsg=false;
  orgId: any;
isbtnDisabled: boolean=false;
  startAtDate: Date;
  // isSubmitted = false;
  startdate:any;
  maxEndDate: Date;

  constructor(private gs: ProjectManagementService, 
    private router: Router, 
    public dialog: MatDialog, 
    private route: ActivatedRoute,
    private datepipe  : DatePipe,
    private fb: FormBuilder,
    private headerservice: HeaderService,
    private dateAdapter: DateAdapter<Date>

    ) { 
      
    }


  ngOnInit() {
    // this.headerservice.setTitle('My Task');
    this.headerservice.setTitle('');
    this.isData = false;
    let x = JSON.parse(localStorage.getItem('enoteUserData'))

    console.log("x local response::",x);
    this.orgId=x.organization.organizationId


    console.log("org Id::",this.orgId);
    
    this.employeeId = x.
    employee
    .employeeId
    console.log("emp_id::",this.employeeId);
    // this.today = new Date();
    // this.startDate=new Date()
    // this.endDate = null;
    // this.endDate = new Date();
    // this.startAtDate.setDate(1);
    // this.today =new Date();
    // this.today = this.datepipe.transform('YYYY-MM-dd');
    // const currentDate = new Date();
    // const firstDayOfMonth = this.getFirstDayOfMonth(currentDate);
    // this.endDate = this.datepipe.transform(firstDayOfMonth, 'MMM yyyy');
    // this.dateAdapter.setLocale('en-GB');

    // this.endDate.setDate(1);
    // this.endDate.setMonth(this.endDate.getMonth() + 1);
    // this.endDate.setDate(0);

   
    { this.routingParams() }
    // if(this.user_type == 'HumanResource' || this.user_type == 'Manager') {
    { this.getTask(this.Id, x.employeeId) }
    // }
    // else{
    { this.Project(this.Id) }
    // }

    this.gs.gettaskgroup()
    .subscribe((data:any) =>{
      this.loader = false;
      this.taskgrouplist = data;
      console.log(this.taskgrouplist);
    }
  )

  //Form Group
  // this.taskListForm = this.fb.group({
  //   StartDate:['',Validators.required],
  //   EndDate:['',Validators.required]
  // })
  this.getTaskLists(this.Id,)
  }

  dateFilter = (date: Date | null): boolean => {
    const currentDate = new Date();
    currentDate.setDate(0); // Set to the first day of the current month
    return !date || date >= currentDate;
  }
  // getFirstDayOfMonth(date: Date): Date {
  //   return new Date(date.getFullYear(), date.getMonth(), 0);
  // }

  private routingParams() {
    let id: any
    this.route.paramMap.subscribe(d => {
      let x = d.get('id');
      this.Id = x
      id = x
    });
  }

  private getTask(id, empId) {
  
    
   
      this.gs.getEmpProjectList(this.orgId).subscribe(
        (d: any) => {
          d.map(
            e => {
              if (e.projectId == this.Id) {
                e.employeeProjects.map(
                  a => {
                    if (a.employee.employeeId == this.employeeId) {
                      this.menuData = a.userRole.menu
                      this.menuData.map(
                        b => {
                          if (b.menuname == 'Assign Task') {
                            this.taskShow = true
                          }
                        }
                      )
                    }
                  }
                )
              }
            }
          )
        }
      )
      // this.gs.getTaskByEmployee(this.employeeId, id).subscribe(
      //   (data: any[]) => {
      //     console.log(data);
      //     // this.taskEmpList = data.sort((a, b) => a.taskName.localeCompare(b.taskName));
      //     // console.log(this.taskEmpList);
      //     this.taskEmpList=data;
      //     console.log("this.taskEmpList",this.taskEmpList);
      //     console.log("this.taskEmpList.length",this.taskEmpList.length);
      //   // Add Code to Assign another array
      //   this.taskEmpList.forEach(element => {
      //     var elementobj = {
      //         taskId: element.taskId,
      //         taskGroup: element.taskGroup,
      //         taskName:  element.taskName,
      //         taskStartDate: element.taskStartDate ,
      //         taskEndDate: element.taskEndDate ,
      //         workhours: element.workhours,
      //         workminutes: element.workminutes, 
      //         taskStatusName: element.taskStatusName 
      //         // advanceBalance: element.status ,
      //         // advanceGivenBy:  element.advanceGivenBy
      //     }  
      //      this.taskList.push(elementobj);
      //      console.log(this.taskEmpList);
      //     });      
      //     console.log("Data Length:"+this.taskList.length);
      //     this.listCount=this.taskList.length;
      //     console.log(this.listCount);


      //     this.taskEmpList.map(
      //       h => {
      //         let hour = 0
      //         let minute = 0
      //         if (h.taskHours != '') {
      //           hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
      //             return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
      //           })
      //           minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
      //             return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
      //           })
      //         }
      //         else {
      //           hour = 0
      //           minute = 0
      //         }

      //         let data: any
      //         let data1: any

      //         if (h.workhours) {
      //           data = Number(h.workhours) + Number(hour)
      //           data1 = Number(h.workminutes) + Number(minute)
      //         }
      //         else {
      //           data = 0 + Number(hour)
      //           data1 = 0 + Number(minute)
      //         }


      //         if (data1 >= 60) {
      //           // Divide minutes by 60 and add result to hours
      //           data += Math.floor(data1 / 60);
      //           // Add remainder of totalM / 60 to minutes
      //           data1 = data1 % 60;
      //         }
      //         data = ('0' + data).slice(-2)
      //         data1 = ('0' + data1).slice(-2)
      //         h['workhours'] = data
      //         h['workminutes'] = data1
      //       }
      //     )
      //     if(this.taskList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      //   },
      //   (error) => {
      //     this.nodata = true;
      //     console.log("error")
      //     if(error.status == 404){
      //       this.nodata = true;
      //       console.log("404")
      //     }
      //   }
      // )
      // this.getTaskLists(id,empId)
    
  }

  getTaskLists(id){
    this.loader=true
    this.taskList=[]
    this.gs.getTaskByEmployee(this.employeeId, id).subscribe(
      (data: any[]) => {
        this.loader=false
        console.log("data reponse::",data);
        // this.taskEmpList = data.sort((a, b) => a.taskName.localeCompare(b.taskName));
        // console.log(this.taskEmpList);
        // this.taskEmpList=data;
        this.taskList=data
        this.tableShow = true;
        console.log("this.tasklist::" ,this.taskList);
        
        if(this.taskList.message=='No Data'){
          this.nodata=true
          this.tableShow = true;

        }
        console.log("this.taskEmpList",this.taskEmpList);
        this.taskEmpList.forEach((item) => {
          const taskCode = item.taskCode;
          console.log("Task Code:", taskCode);
        
        },
        (error:any)=>{
          console.log("entered in to the task error world");
          this.loader = false;
          this.nodata=true;
          this.tableShow = true;


        }
      );
        // console.log("this.taskEmpList.statusName",this.taskEmpList.taskStatus.listTypeValueName);
        
        // console.log("this.taskEmpList.message",this.taskEmpList.message);
        // if(this.taskEmpList.message =='No Data')
        // {
        //   this.nodata = true;
        //   console.log("nodata")

        // }
      // Add Code to Assign another array
      this.taskEmpList.forEach(element => {
        var elementobj = {
            taskId: element.taskId,
            taskGroup: element.taskGroup,
            taskName:  element.taskName,
            taskStartDate: element.taskStartDate ,
            taskEndDate: element.taskEndDate ,
            workhours: element.workhours,
            workminutes: element.workminutes, 
            taskStatusName: element.taskStatusName 
            // advanceBalance: element.status ,
            // advanceGivenBy:  element.advanceGivenBy
        }  
         this.taskList.push(elementobj);
        //  console.log(this.taskEmpList);
        });      
        console.log("Data Length:"+this.taskList.length);
        this.listCount=this.taskList.length;
        console.log(this.listCount);


        this.taskEmpList.map(
          h => {
            let hour = 0
            let minute = 0
            if (h.taskHours != '') {
              hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
                return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
              })
              minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
                return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
              })
            }
            else {
              hour = 0
              minute = 0
            }

            let data: any
            let data1: any

            if (h.workhours) {
              data = Number(h.workhours) + Number(hour)
              data1 = Number(h.workminutes) + Number(minute)
            }
            else {
              data = 0 + Number(hour)
              data1 = 0 + Number(minute)
            }


            if (data1 >= 60) {
              // Divide minutes by 60 and add result to hours
              data += Math.floor(data1 / 60);
              // Add remainder of totalM / 60 to minutes
              data1 = data1 % 60;
            }
            data = ('0' + data).slice(-2)
            data1 = ('0' + data1).slice(-2)
            h['workhours'] = data
            h['workminutes'] = data1
          }
        )
     
      },
      (error) => {
        this.nodata = true;
        console.log("error")
        if(error.status == 404){
          this.nodata = true;
          console.log("404")
        }
      }
    )
  }


  private Project(id) {
    this.gs.getprojectByID(id).subscribe(
      (x: any) => {
        this.projectName = x;
      }
    )
  }

//   resetForms() {
//     this.taskStatusId = undefined;
//       this.selectedetaskGroupId= undefined;
//       this.startDate  = undefined;
//       this.endDate=undefined
//       // this.taskEmpList.length=0  
//       this.getTaskLists(this.Id,this.employeeId)
         
//  }
resetForms() {
  //  this.taskList.length = 0;
// this.taskStatusId = undefined;
  // this.selectedetaskGroupId= undefined;
  // this.startDate  = undefined;
  // this.endDate=undefined
  // this.taskList=[];
  console.log("check all fields::", this.selectedetaskGroupId , this.taskStatusId , this.startDate , this.endDate);
  
  // this.search=''

  // this.taskList.length = 0;
  // let x = JSON.parse(localStorage.getItem('enoteUserData'))
  // this.employeeId = x.
  // employee
  // .employeeId
  // console.log("emp_id::",this.employeeId);
  // { this.getTask(this.Id, x.employeeId) }
//  window.location.reload();

// console.log('id,empid',this.Id, x.employeeId);
if(this.selectedetaskGroupId==undefined && this.taskStatusId==undefined && this.startDate==null && this.endDate==null){
  this.loader=false
  console.log("if...came");
  
}

// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
//     const currentUrl = this.router.url;
//     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
//         this.router.navigateByUrl(currentUrl);
//     });
else{
  console.log("else...came");
  
  if (
      this.selectedetaskGroupId !== 0 ||
      this.selectedetaskGroupId !== undefined ||
      this.startDate !== undefined ||
      this.startDate !== null ||
      this.endDate !== undefined ||
      this.endDate !== null ||
      this.taskStatusId !== 0 ||
      this.taskStatusId !== undefined
    ) {
      console.log("first condition");

      // Your code here

      if (
        this.selectedetaskGroupId != 0||
        this.startDate != undefined ||
        this.endDate != undefined ||
        this.taskStatusId!= 0

      ) {
        console.log("second condition");

        if (
          this.selectedetaskGroupId != undefined ||
          this.startDate != undefined ||
          this.endDate != undefined ||
          this.taskStatusId!= undefined

        ) {
          console.log("third condition");

          console.log('Form is valid');
          this.tableShow = false;
          this.selectedetaskGroupId = undefined;
          this.startDate = undefined;
          this.endDate = undefined;
          this.taskStatusId = undefined;
          // this.taskList=undefined
          // this.timesheetreport.length = 0;
          // this.taskList.length=0

          // this.taskList = [];
          this.nodata = false;
          this.loader = true;
          // this.getTaskLists(this.Id);{
            this.gs.getTaskByEmployee(this.employeeId, this.Id).subscribe(
              (data: any[]) => {
                this.loader=false
                console.log("data reponse::",data);
                // this.taskEmpList = data.sort((a, b) => a.taskName.localeCompare(b.taskName));
                // console.log(this.taskEmpList);
                // this.taskEmpList=data;
                this.taskList=data
                this.tableShow = true;
                console.log("this.tasklist::" ,this.taskList);
                
                if(this.taskList.message=='No Data'){
                  this.nodata=true
                  console.log("no data first");
                  
                }
                // if(this.taskList.length=0){
                //   this.nodata=true
                //   console.log("no data entered here");
                  
                // }
                console.log("this.taskEmpList",this.taskEmpList);
                this.taskEmpList.forEach((item) => {
                  const taskCode = item.taskCode;
                  console.log("Task Code:", taskCode);
                
                },
                (error:any)=>{
                  console.log("entered in to the task error world");
                  this.loader = false;
                  this.nodata=true
        
                }
              );
                // console.log("this.taskEmpList.statusName",this.taskEmpList.taskStatus.listTypeValueName);
                
                // console.log("this.taskEmpList.message",this.taskEmpList.message);
                // if(this.taskEmpList.message =='No Data')
                // {
                //   this.nodata = true;
                //   console.log("nodata")
        
                // }
              // Add Code to Assign another array
              this.taskEmpList.forEach(element => {
                var elementobj = {
                    taskId: element.taskId,
                    taskGroup: element.taskGroup,
                    taskName:  element.taskName,
                    taskStartDate: element.taskStartDate ,
                    taskEndDate: element.taskEndDate ,
                    workhours: element.workhours,
                    workminutes: element.workminutes, 
                    taskStatusName: element.taskStatusName 
                    // advanceBalance: element.status ,
                    // advanceGivenBy:  element.advanceGivenBy
                }  
                 this.taskList.push(elementobj);
                //  console.log(this.taskEmpList);
                });      
                console.log("Data Length:"+this.taskList.length);
                this.listCount=this.taskList.length;
                console.log(this.listCount);
        
        
                this.taskEmpList.map(
                  h => {
                    let hour = 0
                    let minute = 0
                    if (h.taskHours != '') {
                      hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
                        return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                      })
                      minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
                        return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                      })
                    }
                    else {
                      hour = 0
                      minute = 0
                    }
        
                    let data: any
                    let data1: any
        
                    if (h.workhours) {
                      data = Number(h.workhours) + Number(hour)
                      data1 = Number(h.workminutes) + Number(minute)
                    }
                    else {
                      data = 0 + Number(hour)
                      data1 = 0 + Number(minute)
                    }
        
        
                    if (data1 >= 60) {
                      // Divide minutes by 60 and add result to hours
                      data += Math.floor(data1 / 60);
                      // Add remainder of totalM / 60 to minutes
                      data1 = data1 % 60;
                    }
                    data = ('0' + data).slice(-2)
                    data1 = ('0' + data1).slice(-2)
                    h['workhours'] = data
                    h['workminutes'] = data1
                  }
                )
             
              },
              (error) => {
                this.nodata = true;
                console.log("error")
                if(error.status == 404){
                  this.nodata = true;
                  console.log("404")
                }
              })
        }}}}


}




  changepage(){
    console.log(this.taskEmpList.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.taskEmpList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.taskEmpList.length);
      console.log("nodata");
    }
  }

  


  //methods
  view(i) {
    this.router.navigate(['deliverables/view/'])
  }

 
  taskStatus(i) {0
    // console.log("i wtts::",i);
    // console.log("i check here::", i.taskStatusName);
    
    
    if (i.taskStatusName == null) {
      return
    }
    if (i.taskStatusName == 'To Do') {
      return '#8313ff'
    }
    if (i.taskStatusName == 'Completed') {
      return '#28a745'
    }
    if (i.taskStatusName == 'In Progress') {
      return '#f44336'
    }
    if (i.taskStatusName == 'Pending') {
      return '#f44336'
    }
  }

  // edit(d) {
  //   d['commontaskId'] = d.commonTasks.commontaskId;
  //   this.myData = d;
  //   const dialogRef = this.dialog.open(TaskUpdationComponent, {
  //     width: '800px',
  //     maxHeight: '600px',
  //     data: this.myData,
  //     disableClose: true
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result == 'cancel') {
  //       return
  //     }
  //     this.gs.updateTask(result, d.taskId).subscribe(
  //       d => {
  //         // this.getTask(this.Id)
  //         Swal.fire({
  //           text: d.message,
  //           icon: 'success'
  //         })
  //         window.location.reload()
  //       },
  //     )
  //   });
  // }

  // delete(e, i) {
  //   this.gs.deleteTask(e.taskId).subscribe(
  //     (d: any) => {
  //       this.taskList.splice(i, 1)
  //       Swal.fire({
  //         text: d.message,
  //         icon: 'success'
  //       })
  //       window.location.reload();
  //     }
  //   )
  // }
  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  
  checkStartDate(): void {
 
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    // if (event.value) {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.remove('black-placeholder');
    // } else {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }

    if (this.endSelectedFirst && this.startDate > this.endDate) {
      // If end date was selected first and start date is greater than end date
      // Set start date to be one day before end date
      this.startDate = new Date(this.endDate);
      this.startDate.setDate(this.endDate.getDate() - 1);
    }

    // Check if start date is defined and update flag accordingly
    if (this.startDate !== undefined) {
      this.startdate_required = false;
    }

    // Check if end date is undefined and update flag accordingly
    if (this.endDate === undefined) {
      this.enddate_required = true;
    }

    if (this.startDate == undefined && this.endDate == undefined) {
      this.startdate_required = false;
      this.enddate_required = false;
    }
    // Check if start date is greater than end date and update flag accordingly
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.startDate = undefined;
      this.endDate = undefined;

      this.startDate = '';
      this.endDate = '';
      this.startdate_required = false;
      this.enddate_required = false;
      alert('Start date cannot be greater than end date.');
    }
  }
  startDateChanged(event: MatDatepickerInputEvent<Date>) {
    // if (!this.endDate) {
    //   this.endDate = null; 
    // }
    this.maxEndDate = event.value;
    
    // Reset end date if it's before the new start date
    // if (this.endDate < this.startDate) {
    //   // this.endDate = null;
    //   alert("Start date should be greater than the End Date!!")
    //   this.endDate=null
    //   this.startDate=null

    // }
  
  }
  changefirstpage()
  {
    this.p = 1;
  }

  // changefirstpage()
  // {
  //   this.p = 1;
  // }
  
  searchTask(){
    this.search=''
    this.changefirstpage();
    // console.log(this.taskListForm.value.StartDate);
    //  console.log(this.taskListForm.value.EndDate);
    //  let startDate = this.datepipe.transform(this.taskListForm.value.StartDate, 'YYYY-MM-dd');
     this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
     this.endDate = this.datepipe.transform(this.endDate , 'YYYY-MM-dd');
     console.log("Start Date:"+this.startDate);
     console.log("End date:"+this.endDate);
    //  if (this.startDate > this.endDate) {
    //   alert('Start date should not be greater than End date');
    //   return;
    // }
   
  
 
  // else{
  //   console.log("else date");
    
  // }

    // this.endDate = this.datepipe.transform( this.endDate , 'YYYY-MM-dd');

    // this.isSubmitted = true;
    // console.log("this.startDate" + this.startDate);
    // console.log("this.endDate" + this.endDate);
    // console.log("this.selectedetaskGroupId == undefined || this.selectedetaskGroupId == 0 : " + this.selectedetaskGroupId == undefined || this.selectedetaskGroupId == 0);
    if(this.taskStatusId == undefined && this.selectedetaskGroupId ==undefined && this.startDate ==undefined && this.endDate ==undefined )
    {
      alert("Please Choose any of the Element to Search");
      this.getTaskLists(this.Id)
      this.isbtnDisabled=false
      this.loader=false
      // this.taskStatusId=undefined
      // this.selectedetaskGroupId=undefined
      // this.startDate=undefined
      // this.endDate=undefined


        return
    }
  //   else if(this.startDate > this.endDate) {
  //     alert('Start date should not be greater than End date');
  //     this.getTaskLists(this.Id)

  //     return;
  // }
  else if(this.startDate>this.endDate){
    this.loader=true
    alert("start date should be greater than the end date!!")
    this.getTaskLists(this.Id)
    this.isbtnDisabled=false
    this.loader=false
    this.nodata=false
    // this.isbtnDisabled=false
      // this.taskStatusId=undefined
      // this.selectedetaskGroupId=undefined
      this.startDate=undefined
      this.endDate=undefined

    return

  }
//   else if(this.startDate<this.endDate){
//     alert("Start date should be greather than end date!!")
//     this.getTaskLists(this.Id)
//     this.isbtnDisabled=false
//      this.startDate=undefined
//      this.endDate=undefined
// //        this.taskStatusId=undefined
// // this.selectedetaskGroupId=undefined

// return
//   }
 else if (this.endDate==undefined && this.startDate!=undefined) {
    alert(" Please choose End Date");
    // this.getTaskLists(this.Id)
    this.isbtnDisabled=false
    this.loader=false
    // this.isbtnDisabled=false
    // this.taskStatusId=undefined
    // this.selectedetaskGroupId=undefined
    // this.startDate=undefined
    // this.endDate=undefined

    return;
}
else if (this.startDate==undefined && this.endDate!=undefined) {
  alert('Please choose Start Date ');
  // this.getTaskLists(this.Id)
  this.isbtnDisabled=false
  this.loader=false
  // this.isbtnDisabled=false
      // this.taskStatusId=undefined
      // this.selectedetaskGroupId=undefined
      // this.startDate=undefined
      // this.endDate=undefined


  return;
}
    else{
      console.log("no data");
    }
      console.log("this.startDate" + this.startDate);
    console.log("this.endDate" + this.endDate);
    console.log( this.taskStatusId);

    console.log(this.selectedetaskGroupId);
    // if(this.startDate <= this.endDate) {
    //  alert("start date  should be less than end date!!")
    // }


    if(this.startDate == undefined && this.endDate == undefined && this.selectedetaskGroupId!==undefined && this.taskStatusId!==undefined){
      console.log("task group and task status");
      this.taskEmpList.length=0;
      this.taskList.length=0;
      this.isbtnDisabled=true
      this.loader =  true;
      this.tableShow = false;
      
        this.gs.getTaskByProjectAndEmployeeAndTaskGroupAndTaskstatus(this.Id,this.employeeId,this.selectedetaskGroupId,this.taskStatusId)
        .subscribe((data)=>{
          this.loader = false;
          this.nodata = false;
          this.taskList = data;
          this.isbtnDisabled=true
          this.tableShow = true;
          console.log(this.taskEmpList.length);
          console.log(this.taskList.length);
          console.log(this.taskEmpList);
          if(this.taskList.length == 0 || this.taskList.errorCode == 204){
            this.nodata = true;
            this.isbtnDisabled=false
          }
          this.isbtnDisabled=false

        }
        ,(error) => {
          // // this.nodata = true;
          // console.log("Error");
          this.taskList.length=0;
          this.loader = false;
          console.log(this.loader);
          if(error.status == 404){
            console.log("404");
            this.nodata = true;
          }
        }
        );
      }
    else  if(this.taskStatusId!==undefined && this.startDate==undefined && this.endDate==undefined && this.selectedetaskGroupId==undefined ){
      this.taskEmpList.length=0;
      this.taskList.length=0;
      console.log("only task status....!");
        this.isbtnDisabled=true
        this.loader =  true;
        this.tableShow = false;
        this.gs.getTaskByProjectAndEmployeeAndTaskstatus(this.Id,this.employeeId,this.taskStatusId)
        .subscribe((data)=>{
          console.log(data);
          this.taskList = data;
          this.tableShow = true;
          this.isbtnDisabled=true
          this.loader =  false;

          console.log(this.taskList.length);
          this.nodata = false;
          if(this.taskList.length == 0 || this.taskList.errorCode == 204 ){
            this.nodata = true;
            this.isbtnDisabled=false

          }
          this.isbtnDisabled=false

        }
        ,(error) => {
          // this.nodata = true;
          // console.log("Error");
          this.taskList.length=0;
          this.loader = false;
          console.log(this.loader);
          if(error.status == 404){
            console.log("404");
            this.nodata = true;
          }
        }
        );
      }
      else if(this.taskStatusId == undefined && this.selectedetaskGroupId!==undefined && this.startDate==undefined && this.endDate==undefined){
        
        this.taskEmpList.length=0;
        this.taskList.length=0;
        console.log("its only by selecting task group ...!");
        this.isbtnDisabled=true
        this.loader =  true;
        this.tableShow = false;
        
        this.gs.getTaskByProjectAndEmployeeAndTaskGroup(this.Id,this.employeeId,this.selectedetaskGroupId)
        .subscribe((data)=>{
          console.log(data);
          this.taskList= data;
          this.tableShow = true;
          this.isbtnDisabled=true
          this.loader =  false;

          console.log(this.taskList.length);
          this.nodata = false;
          if(this.taskList.length == 0 || this.taskList.errorCode == 204){
            this.nodata = true;
            this.isbtnDisabled=false

          }
          this.isbtnDisabled=false

        },(error) => {
          // this.nodata = true;
          // console.log("Error");
          this.taskList.length=0;
          this.loader = false;
          console.log(this.loader);
          if(error.status == 404){
            console.log("404");
            this.nodata = true;
          }
        }
        );
      }
      // else if(this.taskStatusId == undefined && !this.selectedetaskGroupId && this.startDate && this.endDate){
      //   console.log("its only by selecting task group , ...!");
        
      //   this.gs.getTaskByProjectAndEmployeeAndTaskGroup(this.Id,this.employeeId,this.selectedetaskGroupId)
      //   .subscribe((data)=>{
      //     console.log(data);
      //     this.taskList= data;
      //     console.log(this.taskList.length);
      //     this.nodata = false;
      //     if(this.taskList.length == 0 || this.taskList.errorCode == 204){
      //       this.nodata = true;
      //     }
      //   },(error) => {
      //     // this.nodata = true;
      //     // console.log("Error");
      //     this.taskList.length=0;
      //     this.loader = false;
      //     console.log(this.loader);
      //     if(error.status == 404){
      //       console.log("404");
      //       this.nodata = true;
      //     }
      //   }
      //   );
      // }
  

      else if(this.taskStatusId==undefined && this.selectedetaskGroupId==undefined && this.startDate!==undefined && this.endDate!==undefined){
        this.taskEmpList.length=0;
        this.taskList.length=0;
      
        console.log("only date");

        this.isbtnDisabled=true
        this.loader =  true;
        this.tableShow = false;
        this.gs.getTaskByProjectAndEmployeeAndDate(this.Id,this.employeeId,this.startDate,this.endDate)
        .subscribe((data)=>{
          console.log(data);
          this.taskList = data;
          this.tableShow = true;
          this.isbtnDisabled=true
          this.nodata = false;
          this.loader =  false;

          console.log(this.taskList.length);
          if(this.taskList.length == 0 || this.taskList.errorCode == 204){
            this.nodata = true;
            this.isbtnDisabled=false

          }
                      this.isbtnDisabled=false

        },(error) => {
          // this.nodata = true;
          // console.log("Error");
          this.taskList.length=0;
          this.loader = false;
          // this.nodata = true;
          console.log(this.loader);
          if(error.status == 404){
            console.log("404");
            this.nodata = true;
          }
        }
        );
      }
      else if(this.selectedetaskGroupId == undefined && this.startDate !==undefined && this.endDate!==undefined && this.taskStatusId!==undefined ){
       
        this.taskEmpList.length=0;
        this.taskList.length=0;
        console.log("start,end,task status...!");
        this.isbtnDisabled=true
        this.loader =  true;

        this.tableShow = false;
        this.gs.getTaskByProjectAndEmployeeAndTaskstatusAndDate(this.Id,this.employeeId,this.taskStatusId,this.startDate,this.endDate)
        .subscribe((data)=>{
          console.log(data);
          this.taskList = data;
          this.tableShow = true;
          this.nodata=false
          this.loader =  false;

          console.log(this.taskList.length);
          this.nodata = false;
          this.isbtnDisabled=true

          if(this.taskList.length == 0 || this.taskList.errorCode == 204){
            this.nodata = true;
            this.isbtnDisabled=false

          }
          this.isbtnDisabled=false

        },(error) => {
          // this.nodata = true;
          // console.log("Error");
          this.taskList.length=0;
          this.loader = false;
          console.log(this.loader);
          if(error.status == 404){
            console.log("404");
            this.nodata = true;
          }
        }
        );
      }
      else if(this.taskStatusId==undefined && this.startDate!==undefined && this.endDate!==undefined && this.selectedetaskGroupId!==undefined){
        this.taskEmpList.length=0;
        this.taskList.length=0;
        
        console.log("task group , start date and end date....!");
        this.isbtnDisabled=true
        this.loader =  true;
        this.tableShow = false;

        this.gs.getTaskByProjectAndEmployeeAndTaskGroupAndDate(this.Id,this.employeeId,this.selectedetaskGroupId,this.startDate,this.endDate)
        .subscribe((data)=>{
          console.log(data);
          this.taskList = data;
          this.loader =  false;
          this.tableShow = true;
          console.log(this.taskList.length);
          this.nodata = false;
          this.isbtnDisabled=true

          if(this.taskList.length == 0 || this.taskList.errorCode == 204){
            this.nodata = true;
            this.isbtnDisabled=false

            console.log("no data");
          }
          this.isbtnDisabled=false

        },(error) => {
          // this.nodata = true;
          // console.log("Error");
          this.taskList.length=0;
          this.loader = false;
          console.log(this.loader);
          if(error.status == 404){
            console.log("404");
            this.nodata = true;
          }
        }
        );
      }
      else if(this.selectedetaskGroupId!==undefined && this.taskStatusId!==undefined && this.startDate!==undefined &&this.endDate!==undefined){
        this.taskEmpList.length=0;
        this.taskList.length=0;
       
        console.log("all 4 selection!....");
        this.isbtnDisabled=true
        this.loader =  true;
        this.tableShow = false;
        this.gs.getTaskByProjectAndEmployeeAndTaskGroupAndTaskstatusAndDate(this.Id,this.employeeId,this.selectedetaskGroupId,this.taskStatusId,this.startDate,this.endDate,)
        .subscribe((data)=>{
          console.log(data);
        this.taskList = data;
        this.tableShow = true;
        this.loader =  false;

        console.log(this.taskList.length);
        this.nodata = false;
        this.isbtnDisabled=true

        if(this.taskList.length == 0 || this.taskList.errorCode == 204){
          this.nodata = true;
          this.isbtnDisabled=false

        }
        this.isbtnDisabled=false

        },
        (error) => {
          // this.nodata = true;
          // console.log("Error");
          this.taskList.length=0;
          this.loader = false;
          console.log(this.loader);
          if(error.status == 404){
            console.log("404");
            this.nodata = true;
          }
        }

        )
        

      }
     
      else
      {
        console.log("StartDate is Less Then End date");
        console.log(this.taskEmpList.length);
        this.errorMsg=true;
      }
    // }
  }


  // sortData(event: Event) {
  //   const data = this.taskList.slice();
  //   if (!sort.active || sort.direction === '') {
  //     this.taskList  = data;
  //     return;
  //   }

  //   this.taskList = data.sort((a, b) => {
  //     const isAsc = sort.direction === 'asc';
  //     switch (sort.active) {
       
  //       case 'taskGroupName':
  //         return this.compare(a.taskGroup.taskGroupName, b.taskGroup.taskGroupName, isAsc);
  //       case 'taskName':
  //         return this.compare(a.taskName, b.taskName, isAsc);
  //       case 'taskStartDate':
  //         return this.compare(a.taskStartDate, b.taskStartDate, isAsc);
  //       case 'taskEndDate':
  //         return this.compare(a.taskEndDate, b.taskEndDate, isAsc);
  //       case 'workhours':
  //         return this.compare(a.workhours, b.workhours, isAsc);
  //       case 'taskStatusName':
  //         return this.compare(a.taskStatusName, b.taskStatusName, isAsc);
  //       default:
  //         return 0;
  //     }
  //   });
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if the clicked element is not the search input
  //   if (!(event.target as HTMLElement).closest('.form-control')) {
  //     this.search = ''; // Clear the search term
  //   }
  // }

  sortData(event: Sort) {
    const sort = event as unknown as Sort;
    const data = this.taskList.slice();
    if (!sort.active || sort.direction === '') {
      this.taskList = data;
      return;
    }
  
    this.taskList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'taskGroupName':
          return this.compare(a.taskGroupName, b.taskGroupName, isAsc);
        case 'taskName':
          return this.compare(a.taskName, b.taskName, isAsc);
        case 'taskStartDate':
          return this.compare(a.taskStartDate, b.taskStartDate, isAsc);
          case 'taskEndDate':
            return this.compare(a.taskEndDate, b.taskEndDate, isAsc);
            case 'workhours':
              return this.compare(a.workhours, b.workhours, isAsc);
              case 'taskStatusName':
                return this.compare(a.taskStatusName, b.taskStatusName, isAsc);
                case 'taskStatusNames':
                  return this.compare(a.taskStatusNames, b.taskStatusNames, isAsc);
        default:
          return 0;
      }
    });
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  showChanges()
  {
    console.log("show Change");
    this.p=1;
  }

}
