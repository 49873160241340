import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DenyreasonComponent } from '../denyreason/denyreason.component';
import { LeaveService } from 'src/app/providers/leave.service';
import { DialogData } from '../myleavelist/myleavelist.component';

@Component({
  selector: 'app-withdraw-list',
  templateUrl: './withdraw-list.component.html',
  styleUrls: ['./withdraw-list.component.scss']
})
export class WithdrawListComponent implements OnInit {

  list_count = 0
  data: any[] = []
  isLoading:Boolean;
  leaveData=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber','employeeName', 'startDate', "endDate",'reason','noOfLeaveDays', 'star',];
  dataSource: any;
  personalEmail
  //  dataSource = ELEMENT_DATA;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  mail: any;
  constructor(
    private gs: GlobalserviceService, 
    private router: Router, 
    public dialog: MatDialog,
    public leaveservice:LeaveService
    ) {
  }
  ngOnInit() 
  {
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let mail = x.emailId;
    this.mail=mail;
    console.log(this.mail);
    this.leaveservice.getemp_withdraw_list(mail).subscribe((d: any) => {
      this.loader=false;
      if(d.length==0)
        {
          this.leaveData=true;
          console.log("NoData");
        }
      this.data = d;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.leaveWithdraw.startDate + data.leaveWithdraw.endDate
        +data.leaveWithdraw.withdrawDays;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length
    },
    (error) =>
    {
      console.log("withdraw list error");
      if(error.error.statusCode==204)
      {
        this.loader=false;
        this.leaveData=true;
        console.log("204");
      }
    }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  Accept(i) 
  {
    console.log(i)
    Swal.fire({
      title: 'Are you sure? Do you want to Accept',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {

      if (result.value) {
        let x = i.leaveWithdraw
        let data = {
          leaveWithdrawId: x.leaveWithdrawId,
          startDate: i.leaveWithdraw.startDate,
          endDate: i.leaveWithdraw.endDate,
          withdrawDays: x.withdrawDays,
          withdrawStatus: 5,
          status: 1,
          withdrawReason: x.withdrawReason,
          leaveRequest: {
            leaveRequestId: i.leaveRequestId,
            startDate: i.startDate,
            endDate: i.endDate,
            leaveType: i.leaveType,
            applyTo: [i.employee.organizationEmail],
            cc: [i.employee.personalEmail],
            halfday: i.halfday,
            employee: {
              employeeId: i.employee.employeeId,
              employeeName: i.employee.employeeName,
              organizationEmail: i.employee.organizationEmail
            }
          }
        }
        console.log(data);
        // return
        this.isLoading = true
        this.leaveservice.withdrawAction(x.leaveWithdrawId, data).subscribe(
          d => {
            Swal.fire({
              text: 'Accepted',
              icon: 'success'
            });
            this.isLoading = false
            this.afterRefresh();
            // setTimeout(() => {
            //   window.location.reload()
            // }, 1000);
          },
          err => {
            Swal.fire({
              text: err.error.message
            })
            this.isLoading = false
          }
        )
      }
      //
    })

  }
  reject(i) {
    console.log(i)
    Swal.fire({
      title: 'Are you sure? Do you want to Reject',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {

        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              let x = i.leaveWithdraw
              let data = {
                leaveWithdrawId: x.leaveWithdrawId,
                startDate: i.leaveWithdraw.startDate,
                endDate: i.leaveWithdraw.endDate,
                withdrawDays: x.withdrawDays,
                withdrawStatus: 6,
                withdrawReason: x.withdrawReason,
                status: 1,
                leavewithdrawdenyReason: result.denyReason,
                leaveRequest: {
                  leaveRequestId: i.leaveRequestId,
                  startDate: i.startDate,
                  endDate: i.endDate,
                  leaveType: i.leaveType,
                  halfday: i.halfday,
                  applyTo: [i.employee.organizationEmail],
                  cc: [i.employee.personalEmail],
                  employee: {
                    employeeId: i.employee.employeeId,
                    employeeName: i.employee.employeeName
                  }
                }
              }
              console.log(data);
              // return
              this.isLoading = true

              this.leaveservice.withdrawAction(x.leaveWithdrawId, data).subscribe(
                d => {
                  Swal.fire({
                    text: 'Denied',
                    icon: 'success'
                  });
                  this.isLoading = false
                  this.afterRefresh();
                },
                err => {
                  Swal.fire({
                    text: err.error.message,
                    icon:'error'
                  })
                  this.isLoading = false
                },
                // () => {
                //   setTimeout(() => {
                //     // window.location.reload()
                //   }, 1000);
                //   this.isLoading = false
                // }
              )
            }
          });
        }
      }
      //
    })
  }

  afterRefresh()
  {
    console.log("Referesh");
    this.leaveservice.getemp_withdraw_list(this.mail).subscribe((d: any) => {
      this.loader=false;
      if(d.length==0)
        {
          this.leaveData=true;
          console.log("NoData");
        }
      this.data = d;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.leaveWithdraw.startDate + data.leaveWithdraw.endDate
        +data.leaveWithdraw.withdrawDays;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length
    },
    (error) =>
    {
      console.log("withdraw list error");
      if(error.error.statusCode==204)
      {
        this.list_count=0;
        this.loader=false;
        this.leaveData=true;
        console.log("204");
      }
    }
    )
  }
  // dialog
  // openDialog(i) {
  //   console.log(i)
  //   this.dialog.open(Reason, {
  //     data: i.leaveWithdraw.withdrawReason
  //   });
  // }
  show_leave(i) {
    console.log(i)
    this.dialog.open(ShowLeave, {
      data: i
    });
  }
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

@Component({
  selector: 'reason',
  templateUrl: 'reason.html',
})
export class Reason {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<Reason>) { }

  ngOnInit() {
  }
  closeLeaveDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'leavedenyreason',
  templateUrl: 'leavedenyreason.html',
})
export class leavedenyreason {
  Data: any = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<ShowLeave>) { }

  ngOnInit() {
    this.Data = this.data;
    console.log(this.Data);
  }
  closeDenyDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'showleave',
  templateUrl: 'showleave.html',
})
export class ShowLeave implements OnInit {
  Data: any = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<ShowLeave>) { }

  ngOnInit() {
    this.Data = this.data;
    console.log(this.Data);
    if (this.Data.leaveWithdraw.withdrawStatus == 3) {
      this.Data.leaveWithdraw.withdrawStatus = 'Pending'
    }
  }
  test(i) {
    if (i.leaveWithdraw.withdrawStatus == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Approved') {
      return '#28a745'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Denied') {
      return '#f44336'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Withdrawn') {
      return '#20B2AA'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Cancelled') {
      return '#e40000'
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}

