import { Component, OnInit,Inject  } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../providers/settings.service';
import { EmployeeService } from '../../providers/employee.service';
//import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-view-employee',
  templateUrl: './view-employee.component.html',
  styleUrls: ['./view-employee.component.scss']
})
export class ViewEmployeeComponent implements OnInit {
  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;

  resultData: any = {};
  employeeId: any;
  fileblob: any;

  loader:any= false;
  backdrop:any= false;

  dateFormat : any;

  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private employee_service: EmployeeService,
    private ActivatedRoute:ActivatedRoute,
    @Inject(AppComponent) public AppData: any,
  ) { }

  ngOnInit(): void
  {
    this.dateFormat = localStorage.getItem('dateFormatName');

    this.employeeId = this.ActivatedRoute.snapshot.params.employeeId;

    if(this.employeeId) //View User
    {
      this.loader=true;
      this.backdrop=true;

      this.employee_service.getEmployeeById(this.employeeId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;

        this.resultData=resultData;

        if(resultData.employeeAttachment)
        {
          this.loader=true;
          this.employee_service.getemployeeImage(resultData.employeeAttachment.fileViewUrl)
          .subscribe(
            blob => {
              const a = document.createElement('a')
              const objectUrl: any = blob
              this.fileblob = blob;
              this.loader=false;

            },
            err => {
              if (err.status === 400) {
                this.loader = true;
              } else {
                this.loader = false;
              }
            }
          );
        }
        else
        {
          this.fileblob= "assets/uploads/no-image.png"
        }
      });
    }
  }





}
