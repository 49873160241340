import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatadialogexampledialogComponent} from '../manage-leave/datadialogexampledialog/datadialogexampledialog.component';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { DenyreasonComponent } from '../denyreason/denyreason.component';
import { ResourceLoader } from '@angular/compiler';
import { LeaveService } from 'src/app/providers/leave.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { HeaderService } from 'src/app/providers/header.service';




@Component({
  selector: 'app-manage-leave',
  templateUrl: './manage-leave.component.html',
  styleUrls: ['./manage-leave.component.scss']
})
export class ManageLeaveComponent implements OnInit {

  dataLoadedSpinner: boolean = false;

  data: any[] = []
  isData = 0;
  leaveData=false;
  isLoading:Boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber', 'employeeName', 'organizationEmail', 'startDate', 'endDate', 'noOfLeaveDays','reason','leaveStatus','star'];
  // dataSource: any;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  mail: any;
  empId: any;
  approvallist:any=[];
  organizationId: any;
  list_count = 0
  startDate: any;
  endDate:any;
  noOfLeaveDays:any;
  success: boolean = false;

  success_msg: string;
  error_msg: any;
  error: boolean = false;
  ab:any;
  bc:any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
list_counts=false;
  beforesearchpage: number;
  paginators: boolean = true;
  constructor(
    private gs: GlobalserviceService,
    private router: Router, 
    public dialog: MatDialog,
    public leaveservice : LeaveService,
    private headerservice : HeaderService
    ) {
  }
  ngOnInit() {
    // this.headerservice.setTitle('Leave Request');
    this.headerservice.setTitle('');


    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(x);

    // let user_type = x.userType.userType;
    let mail = x.emailId;
    this.mail=mail;
    console.log(this.mail);
    this.empId = x.employee.employeeId;
    this.organizationId  = x.organization.organizationId;
      // this.leaveservice.getleaveReqPending(this.empId).subscribe(
      //   (d: any) => {
      //     this.loader=false;
      //     if(d.length==0){
      //       this.leaveData=true;
      //       console.log("NOData");
      //     }
      //     this.data = d;
      //     console.log(this.data,'naveennnnnnnnnnnnnnnnnnnnnnnnnn');
          
      //     this.data.map(
      //       y => {
  
      //         console.log(y,'janarthannnnnnnnnnnnnnnnnnnnnnnnnnnnnnn');
      //         console.log(y.approvals?.approver?.fyi,this.empId,y.approvals?.approver?.approverOne,y.approvals?.approverOneStatus?.listTypeValueId)
      //         console.log(y.approvals)
      //         if(y.approvals?.approver?.fyi == this.empId ){ 
             
      //           this.leaveData = false;
      //           y['fyistatus'] = true;
      //           this.approvallist.push(y);   
      //              console.log("fyi");
      //         }
      //         if (y.approvals?.approver?.approverOne == this.empId && y.approvals?.approverOneStatus.listTypeValueId == 3
      //           && y.approvals.approver.approverOne) {
                  
      //           this.leaveData = false;
      //           this.approvallist.push(y);  
      //           console.log("approverOne");   
      //         }
      //         if (y.approvals?.approver?.approverTwo == this.empId && y.approvals?.approverOneStatus.listTypeValueId == 5
      //           && y.approvals.approverTwoStatus.listTypeValueId == 3) {
      //           this.leaveData = false;
      //           this.approvallist.push(y);
      //           console.log("approverTwo");  
      //         }
      //         if (y.approvals?.approver?.approverThree == this.empId && y.approvals?.approverOneStatus.listTypeValueId == 5
      //           && y.approvals?.approverTwoStatus.listTypeValueId == 5 && y.approvals?.approverThreeStatus.listTypeValueId == 3) {
      //             this.leaveData = false;
      //           this.approvallist.push(y);
      //           console.log(this.approvallist);  
      //           console.log("approverThree");  
      //         }
      //         if (y.approvals?.approver?.approverFour == this.empId && y.approvals?.approverOneStatus.listTypeValueId == 5
      //           && y.approvals?.approverTwoStatus.listTypeValueId == 5 && y.approvals?.approverThreeStatus.listTypeValueId == 5
      //           && y.approvals?.approverFourStatus.listTypeValueId == 3) {
                  
  
      //             this.leaveData = false;
      //           this.approvallist.push(y);
      //           console.log("approverFour");  
      //         }
      //         if (y.approvals?.approver?.approverFive == this.empId && y.approvals?.approverOneStatus.listTypeValueId == 5
      //           && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals?.approverThreeStatus.listTypeValueId == 5
      //           && y.approvals?.approverFourStatus.listTypeValueId == 5 && y.approvals?.approverFiveStatus.listTypeValueId == 3) {
      //             this.leaveData = false;
      //           this.approvallist.push(y);
      //           console.log("approverFive");  
      //         }
      //         this.approvallist = this.approvallist.reduce((accumalator, current) => {
      //           console.log(accumalator);
      //           console.log(current);
      //           if (!accumalator.some(item => item === current)) {
      //             accumalator.push(current);
      //           }
      //           return accumalator;
      //         }, []);
      //         console.log("this.approvallist",this.approvallist);  
  
      //         if(this.approvallist.length == 0){
      //           this.leaveData = true;
      //           console.log("NO_DATA");
      //         }
      //       }
      //     )
      //     this.isData = this.data.length;
      //     this.dataSource = new MatTableDataSource<any>(this.approvallist);
      //     this.dataSource.filterPredicate = (data, filter) => {
      //       const dataStr = data.employee.employeeNumber + data.employee.firstName+data.employee.lastName+
      //       + data.employee.organizationEmail + data.employee.organizationEmail + data.employee.mobileNumber
      //       + data.startDate + data.endDate + data.noOfLeaveDays +data.leaveReason+ data.leaveStatusName;
      //       return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      //     }
      //     this.dataSource.paginator = this.paginator;
      //     this.dataSource.sort = this.sort;
      //     this.list_count = this.approvallist.length;
      //   },
      //   (error:any) =>{
          
      //     console.log(error);
      //     console.log("error");
      //     if(error.error.statusCode==204){
      //       this.loader=false;
      //       this.leaveData=true;
      //       console.log("204");
      //     }
      //   }
      //   )
     this.fetchLeaveRequests();
  }
  fetchLeaveRequests(): void {
    this.loader=true;
    this.leaveservice.getleaveReqPending(this.empId).subscribe(

      (d: any) => {
        console.log("111111111111");

        this.loader = false;
        this.list_counts=true;
        this.approvallist=[];
        if (d.length === 0) {
          this.leaveData = true;
          console.log("NO Data");
        }
        this.data = d;
        console.log(this.data, 'naveennnnnnnnnnnnnnnnnnnnnnnnnn');
  
        this.data.forEach(y => {
          console.log(y, 'janarthannnnnnnnnnnnnnnnnnnnnnnnnnnnnnn');
          console.log(y.approvals);
          if (y.approvals?.approver?.fyi === this.empId) {
            this.leaveData = false;
            y['fyistatus'] = true;
            this.approvallist.push(y);
            console.log("fyi");
          } else if (
            y.approvals?.approver?.approverOne === this.empId &&
            y.approvals?.approverOneStatus?.listTypeValueId === 3 &&
            y.approvals.approver.approverOne
          ) {
            this.leaveData = false;
            this.approvallist.push(y);
            console.log("approverOne");
          } else if (
            y.approvals?.approver?.approverTwo === this.empId &&
            y.approvals?.approverOneStatus?.listTypeValueId === 5 &&
            y.approvals.approverTwoStatus?.listTypeValueId === 3
          ) {
            this.leaveData = false;
            this.approvallist.push(y);
            console.log("approverTwo");
          } else if (
            y.approvals?.approver?.approverThree === this.empId &&
            y.approvals?.approverOneStatus?.listTypeValueId === 5 &&
            y.approvals?.approverTwoStatus?.listTypeValueId === 5 &&
            y.approvals?.approverThreeStatus?.listTypeValueId === 3
          ) {
            this.leaveData = false;
            this.approvallist.push(y);
            console.log("approverThree");
          } else if (
            y.approvals?.approver?.approverFour === this.empId &&
            y.approvals?.approverOneStatus?.listTypeValueId === 5 &&
            y.approvals?.approverTwoStatus?.listTypeValueId === 5 &&
            y.approvals?.approverThreeStatus?.listTypeValueId === 5 &&
            y.approvals?.approverFourStatus?.listTypeValueId === 3
          ) {
            this.leaveData = false;
            this.approvallist.push(y);
            console.log("approverFour");
          } else if (
            y.approvals?.approver?.approverFive === this.empId &&
            y.approvals?.approverOneStatus?.listTypeValueId === 5 &&
            y.approvals?.approverTwoStatus?.listTypeValueId === 5 &&
            y.approvals?.approverThreeStatus?.listTypeValueId === 5 &&
            y.approvals?.approverFourStatus?.listTypeValueId === 5 &&
            y.approvals?.approverFiveStatus?.listTypeValueId === 3
          ) {
            this.leaveData = false;
            this.approvallist.push(y);
            console.log("approverFive");
          }
        });
  
        this.approvallist = this.approvallist.reduce((accumalator, current) => {
          if (!accumalator.some(item => item === current)) {
            accumalator.push(current);
          }
          return accumalator;
        }, []);
  
        console.log("this.approvallist", this.approvallist);
  
        if (this.approvallist.length === 0) {
          this.leaveData = true;
          console.log("NO_DATA");
        }
  
        this.isData = this.data.length;
        this.dataSource = new MatTableDataSource<any>(this.approvallist);
        this.dataSource.filterPredicate = (data, filter) => {
          const dataStr = data.employee.employeeNumber + data.employee.firstName + data.employee.lastName +
            +data.employee.organizationEmail + data.employee.organizationEmail + data.employee.mobileNumber
            + data.startDate + data.endDate + data.noOfLeaveDays + data.leaveReason + data.leaveStatusName;
          return dataStr.trim().toLowerCase().indexOf(filter) !== -1;
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.list_count = this.approvallist.length;
      },
      (error: any) => {
        console.log(error);
        console.log("error");
        if (error.error.statusCode === 204) {
          this.loader = false;
          this.leaveData = true;
          console.log("204");
        }
      }
    );
  }
  
  showChanges() {
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; 
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
 
  applyFilter(event: Event) {
    console.log("search list")
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.leaveData = true;
    }
    else{
      this.leaveData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  edit(i) {
    this.router.navigate(['../../employee-edit', i.employeeId])
    console.log("Edit I cameedittttttttttttt",i);
  }

  leaveStatus(i){
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
  }


  approve(i) 
  {
    console.log(i,"IIII cameeeeeeeeeeeee",i.approvals.approver.approverThree, i.approvals.approverOneStatus.listTypeValueId,i.approvals.approverTwoStatus.listTypeValueId,i.approvals.approverThreeStatus.listTypeValueId,this.empId);
    // this.loader = true;
  
    
    Swal.fire({
      title: 'Are you sure? Do you want to Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Approve'
    }).then((result) => {
      if (result.value) {
        // i['leaveStatus'] = 5;
        i['organization']={
          organizationId :this.organizationId
        }
        let count = 0
        if(i.approvals.approver.approverOne){
          count = count+1
        }
        if(i.approvals.approver.approverTwo){
          count = count+1
        }
        if(i.approvals.approver.approverThree){
          count = count+1
        }
        if(i.approvals.approver.approverFour){
          count = count+1
        }
        if(i.approvals.approver.approverFive){
          count = count+1
        }
       
    
        
        if (i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3) {
          console.log("{{{{{{{{{{{{{{{{{ The approverlevel 1111111111}}}}}}}}}}}}}}}}")
          i.approvals.approverOneStatus.listTypeValueId = 5
          i.approvals.approverlevel  = 1
          
          i.approvals['employeeId'] = i.employee.employeeId;
          console.log(i.approvals.approverOneStatus.listTypeValueId);
        }
        if (i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 2222222222222222222}}}}}}}}}}}}}}}}")
            i.approvals.approverTwoStatus.listTypeValueId = 5
            i.approvals.approverlevel = 2
            i.approvals['employeeId'] =i.employee.employeeId;
        }
        if (i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 333333333333333333}}}}}}}}}}}}}}}}")
            i.approvals.approverThreeStatus.listTypeValueId = 5
            i.approvals.approverlevel = 3
            i.approvals['employeeId'] = i.employee.employeeId;
        }
        if (i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
          && i.approvals.approverFourStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 4444444444444444444444}}}}}}}}}}}}}}}}")
            i.approvals.approverFourStatus.listTypeValueId = 5
            i.approvals.approverlevel = 4
            i.approvals['employeeId'] = i.employee.employeeId;
        }
        if (i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
          && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
          && i.approvals.approverFourStatus.listTypeValueId == 5 && i.approvals.approverFiveStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 555555555555555555555}}}}}}}}}}}}}}}}")
            i.approvals.approverFiveStatus.listTypeValueId = 5
            i.approvals.approvalStatus.listTypeValueId = 5
            i.approvals.approverlevel = 5
            i.approvals['employeeId'] = i.employee.employeeId;
            i.leaveStatus = 5
        }
    
        if(count == 1 && i.approvals.approverOneStatus.listTypeValueId  == 5){
          console.log("{{{{{{{{{{{{{{{{{ The count 11111111111111111}}}}}}}}}}}}}}}}")
          i.leaveStatus = 5
          i.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 2 && i.approvals.approverOneStatus.listTypeValueId  == 5 && i.approvals.approverTwoStatus.listTypeValueId  == 5){
          console.log("{{{{{{{{{{{{{{{{{ The count 222222222222222222222222}}}}}}}}}}}}}}}}")
          i.leaveStatus = 5
          i.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 3 && i.approvals.approverOneStatus.listTypeValueId  == 5 && i.approvals.approverTwoStatus.listTypeValueId  == 5
          && i.approvals.approverThreeStatus.listTypeValueId == 5){
            console.log("{{{{{{{{{{{{{{{{{ The count 333333333333333333}}}}}}}}}}}}}}}}")
            i.leaveStatus = 5
            i.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 4 && i.approvals.approverOneStatus.listTypeValueId  == 5 && i.approvals.approverTwoStatus.listTypeValueId  == 5
          && i.approvals.approverThreeStatus.listTypeValueId  == 5 && i.approvals.approverFourStatus.listTypeValueId == 5){
            console.log("{{{{{{{{{{{{{{{{{ The count 4444444444444444444444}}}}}}}}}}}}}}}}")
            i.leaveStatus = 
            i.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 5 && i.approvals.approverOneStatus.listTypeValueId  == 5 && i.approvals.approverTwoStatus.listTypeValueId  == 5
          && i.approvals.approverThreeStatus.listTypeValueId  == 5 && i.approvals.approverFourStatus.listTypeValueId  == 5 && i.approvals.approverFiveStatus.listTypeValueId  == 5){
            console.log("{{{{{{{{{{{{{{{{{ The count 555555555555555555}}}}}}}}}}}}}}}}")
            i.leaveStatus = 5
            i.approvals.approvalStatus.listTypeValueId = 5
        }
    
        console.log(i,"yashwantttttttttttttttttttttttthhhhhhhhhhhhhhhhhhhhhh")
      
        this.loader = true;
        // return
        this.leaveservice.UpdateLeaveStatus(i.leaveRequestId, i).subscribe(d => {
          console.log(d)
         
          this.success = true;
                this.success_msg = 'Leave Approved Successfully';
                
          this.loader = false;
          setTimeout(() => { this.success = false; 
            // this.list_count=0;
            // this.approvallist=[];
            this.fetchLeaveRequests();
 
          }, 3000);
   

          
        },
          err => {
          
            this.error = true;
                this.error_msg = err.error.message;

            this.loader = false
            setTimeout(() => { this.error = false; }, 3000);

          },
        );

      } else {
        this.loader = false;
    }
      //
    })
  }

  denied(i) {
    console.log(i);
    // this.loader = true;
    
    console.log(i,'00000000000000000000000000000000000000');

    // leaveStatus
    Swal.fire({
      title: 'Are you sure? Do you want to Deny',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Deny'
    }).then((result) => {
      if (result.value) {
        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(result => {

            if (result) {

              i['leavedenyReason'] = result.denyReason;
              i['organization']={
                organizationId :this.organizationId
              }
          
              if(i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3){
                console.log("{{{{{{{{{{{{{{{{{ The approverlevel 111111111}}}}}}}}}}}}}}}}")
                
                i.approvals.approverOneStatus.listTypeValueId = 6
                console.log("approverone status");
                console.log(i.approvals.approverOneStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 1
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              if(i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5){
                console.log("{{{{{{{{{{{{{{{{{ The approverlevel 22222222}}}}}}}}}}}}}}}}")
                i.approvals.approverTwoStatus.listTypeValueId = 6
                console.log("approverTwoStatus");
                console.log(i.approvals.approverTwoStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 2
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              if(i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5){
                console.log("{{{{{{{{{{{{{{{{{ The approverlevel 33333333333333}}}}}}}}}}}}}}}}")
                i.approvals.approverThreeStatus.listTypeValueId = 6
                console.log("approverThreeStatus");
                console.log(i.approvals.approverThreeStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 3
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              if(i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5 
               && i.approvals.approverThreeStatus.listTypeValueId == 5){
                console.log("{{{{{{{{{{{{{{{{{ The approverlevel 4444444444444}}}}}}}}}}}}}}}}")
                i.approvals.approverFourStatus.listTypeValueId = 6
                console.log("approverFourStatus");
                console.log(i.approvals.approverFourStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 4
                i.approvals['employeeId'] =i.employee.employeeId;
              }
              if(i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5 
                && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5){
                  console.log("{{{{{{{{{{{{{{{{{ The approverlevel 55555555555}}}}}}}}}}}}}}}}")
                  i.approvals.approverFiveStatus.listTypeValueId = 6
                  console.log("approverFiveStatus");
                  console.log(i.approvals.approverFiveStatus.listTypeValueId);
                  i.approvals.approvalStatus.listTypeValueId = 6
                  i.approvals.approverlevel = 5
                  i.approvals['employeeId'] = i.employee.employeeId;
              }
              this.loader = true
              i['leaveStatus'] = 6

              this.leaveservice.UpdateLeaveStatus(i.leaveRequestId, i).subscribe(d => {
                
                // Swal.fire({
                //   text:'Denied',
                //   icon:'success'
                // })
                this.success = true;
                this.success_msg = 'Leave Denied Successfully';
                this.loader = false;
                setTimeout(() => { this.success = false;
                  // this.list_count=0;
                  // this.approvallist=[];
                  this.fetchLeaveRequests();
                 }, 3000);
               

                // window.location.reload();
                // this.mainLayComponent.leaveRequest();
                // this.mainLayComponent.notifytest = 100;
                // this.afterRefresh();
              },
                err => {
                  // Swal.fire({
                  //   text:err.error.message,
                  //   icon:'error'
                  // })
                  this.error = true;
                this.error_msg = err.error.message;
                  this.loader = false
                  setTimeout(() => { this.error = false; }, 3000);

                }
              );
              
            }else{
              this.loader = false;

            }
          })
        }
      }
      //
    })
  }
  // dialog
  openDialog(i) {
    // let id = i.employee.employeeId;
    this.ab=i.employee.employeeId
    
    
    // let leave_list_ofEmp: any = []
    // this.leaveservice.getMyleave(id).subscribe((a: any) => {
    //   this.bc=a;
    //   a.forEach(element => {
    //     leave_list_ofEmp.push(element)
    //   });
    // })
    // let v = leave_list_ofEmp;
    const x = this.dialog.open(DatadialogexampledialogComponent, {
      data:  this.ab
      
    });
    x.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  reason(i) {
    this.dialog.open(Reason, {
      width: '300px',
      data: i.leaveReason
    });
  }

  // afterRefresh()
  // {
  //   console.log("Refresh:"+this.mail);
  //   this.leaveservice.getleaveReqPending(this.empId).subscribe(
  //     (d: any) => {
  //       this.loader=false;
  //       if(d.length==0)
  //       {
  //         this.leaveData=true;
  //         console.log("NOData");
  //       }
  //       this.data = d;
  //       console.log(this.data);
  //       let x = JSON.parse(localStorage.getItem('enoteUserData'));
  //       this.isData = this.data.length;
  //       this.dataSource = new MatTableDataSource<any>(this.data);
  //       this.dataSource.filterPredicate = (data, filter) => {
  //         const dataStr = data.employee.employeeNumber + data.employee.employeeName
  //         + data.employee.organizationEmail + data.employee.organizationEmail + data.employee.mobileNumber
  //         + data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
  //         return dataStr.trim().toLowerCase().indexOf(filter) != -1;
  //       }
  //       this.dataSource.paginator = this.paginator;
  //       this.dataSource.sort = this.sort;
  //     },
  //     (error:any) =>
  //     {
  //       console.log(error);
  //       console.log("error");
  //       if(error.error.statusCode==204)
  //       {
  //         this.isData=0;
  //         this.loader=false;
  //         this.leaveData=true;
  //          console.log("204");
  //       }
  //     }
  //     )
  // }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}


@Component({
  selector: 'dialog',
  templateUrl: 'dialog.html',
})
export class dialog implements OnInit {
  isData: boolean
  x = this.data
  displayedColumns: string[] = ['leaveRequestId'];
  dataSource = this.data;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any[]) {

    this.isData = false;
    if (this.x.length > 0) {
      this.isData = true
    }
  }
  // data = this.data
  ngOnInit() {
  }
}
