<!-- <section>
  <div class="sub-header">
      <div class="col-md-12 mb-3">
          <div class="row">
              <div class="">
                  <span class="h5 sub-content">My Permission List</span>
              </div>
              <div class="sub-header-buttons">
                  <a (click)="apply_permission()" color="primary" mat-raised-button title="Add Client" class="ml-2">
                    Apply Permission
                  </a>
              </div>
          </div>
      </div>
  </div>
</section> -->
<!-- <div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
     
        <ng-template>
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-md-8">
                <div class="row mb-3 pl-0">
                  <div class="col-md-4">                                                                  
                    <input class="form-control search-input form-control-lg form-control-solid" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
                  </div>
                  <div class="ol-xl-1 col-lg-1">
                    <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn">Search</button>
                  </div>
                </div>
                <span> </span>
              </div>
            </div>
          </div>
          <ng-container>
            <div class="--example-container --mat-elevation-z8 container-fluid">
              <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count >0" class="table table-responsive --table-bordered table-border-new">
                <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef class="text-center">&nbsp;S.No&nbsp;</th>
                  <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
                </ng-container> 
                <ng-container matColumnDef="permissionDate">
                  <th mat-header-cell *matHeaderCellDef>
                      Permission&nbsp;Date
                    </th>
                  <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.permissionDate | date}} </td>
                </ng-container>
                <ng-container matColumnDef="permissionHours">
                  <th mat-header-cell *matHeaderCellDef>
                   Permission&nbsp;Hours
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.permissionHours}} </td>
                </ng-container>
                <ng-container matColumnDef="reason">
                  <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%">Reason</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <span class="reason-span" *ngIf="changeasDenyReason==false">{{element.permissionReason}}</span>
                    <span class="reason-span" *ngIf="changeasDenyReason==true && clickedbusinessTravelId != i && (isMenuOpen==true || isMenuOpen==false)">{{element.permissionReason}}</span>
                    <span class="reason-span" *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==false">{{element.permissionReason}}</span>
                    <span class="denied-span menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">Denied Reason</span>
                    <span class="reason-span-deny menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">{{element.permissiondenyReason}}</span>
                  </td>
                </ng-container> 
                <ng-container matColumnDef="viewapprovers">
                  <th></th>
                  <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%">View Approvers</th>
                  <td *matCellDef="let element" style="text-align:center">
                      <mat-icon class="view-icon" *ngIf="element.approvals != undefined" style="position: relative;top: 8px;"
                      (click)="viewapproverstatus(element)">visibility</mat-icon>
                      <span *ngIf="element.approvals == undefined">  --  </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="workFromHomeStatus">
                  <th mat-header-cell *matHeaderCellDef class="text-center">
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element let i = index" style="white-space: nowrap;" [style.color]="withDrawStatus(element.approvals.approvalStatus.listTypeValueName)">
                    {{element.approvals.approvalStatus.listTypeValueName}} 
                    <span *ngIf="element.approvals.approvalStatus.listTypeValueName=='Approved'" class="material-icons-outlined" 
                      style="font-size: 16px;transform: translate(4px, 4px);">check_circle</span>
                    <a *ngIf="element.approvals.approvalStatus.listTypeValueName=='Denied'" class="material-icons-outlined" #toggleButton (click)="denyReason(i)"
                      style="font-size: 16px;transform: translate(4px, 4px);">error_outline</a>
                    <a *ngIf="element.approvals.approvalStatus.listTypeValueName=='Denied'"
                      style="font-size: 20px;">*</a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="star">
                  <th mat-header-cell *matHeaderCellDef class="text-center"></th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <ng-container *ngIf="element.approvals.approvalStatus.listTypeValueName == 'Pending' ; else elseblock">
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                      </button>
                    </ng-container>
                    <ng-template #elseblock>
                      <mat-icon style="font-size: 17px;"> -- </mat-icon>
                    </ng-template>
                    <mat-menu #menu="matMenu">
                      <ng-container *ngIf="element.approvals.approvalStatus.listTypeValueName == 'Pending'">
                        <a mat-menu-item (click)="cancel(element)">
                          <mat-icon class="cancel-trip-icon material-icons-outlined">clear</mat-icon>
                          <span class="cancel-trip-text">Cancel</span>
                        </a>
                      </ng-container>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              </table>
              <div *ngIf="noData"> 
                <div class="alert alert-primary"  align="center" style="width: 100%" >
                    No permission list
                </div>
            </div>
            </div>
          </ng-container>
        </ng-template>
        <div>
          <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div> -->

<nav class="navbar  bg" style="margin-top: 67px;">
  <a class="navbar-brand navDesign" style="font-size: 22px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;">My Permission List</a>
  <div class="d-flex">
  <input
        class="form-control me-2"
        type="text"
        placeholder="Search..."
        [(ngModel)]="searchs" 
             (input)="validateInput($event)"
                      (keydown)="validateInput($event)"
                      (keyup)="validateInput($event)"
                      (keyup)="changepage();"
        name="search"
        aria-label="Search"
        style="
          width: 250px;
          height: 38px;
          flex-shrink: 0;
          border-radius: 5px;
          background: #fff;position: relative;right: 20px;
        "
      />
      <button class="btn" type="submit" (click)="apply_permission()" mat-raised-button title="Apply Permission"
       style="    background-color: #1a83ff;
      color: whitesmoke;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 610;
      font-family: 'Mulish', sans-serif;
      position: relative;
      /* float: left; */
      right: 10px;"> Apply Permission</button>
    </div>
 
</nav>
<div class="common-content">
  <div class="card-new" style="font-family: Mulish; ">
    <div [ngClass]="{'card colDesign': !loader, 'hidden': loader}" *ngIf="list_counts">
      <div [ngClass]="{'card-body': !loader, 'hidden': loader}" >
        <!-- <div class="row mb-2">
          <div class="col-md-12">
            <ng-container *ngIf="loader; else elseLoading" class="loading">
              <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
            </ng-container>
          </div>
        </div> -->
        <!-- <ng-template #elseLoading> -->

          <!-- <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-md-12">
                <div class="row mb-3 pl-0">
                  <div class="col-md-4 col-6"> -->
                    <!-- <input class="form-control search-input form-control-lg form-control-solid" type="text"
                      placeholder="Search.." (keyup)="applyFilter($event)" name="search"           (input)="validateInput($event)"
                      (keydown)="validateInput($event)"
                      (keyup)="validateInput($event)"
                      
                      > -->
                      <!-- <i class="fa fa-search" style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%);"></i> -->

                  <!-- </div> -->
                  <!-- <div class="col-md-4 col-3">
                    <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn"
                      style="font-family: Mulish;">Search</button>
                  </div> -->
                  <!-- <div class="col-md-8 col-6 d-flex justify-content-end align-items-center"> -->
                    <!-- <a (click)="apply_permission()" color="primary" mat-raised-button title="Apply Permission" class="ml-2"
                      style="font-family: Mulish; ">
                      Apply Permission
                    </a> -->
                  <!-- </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="card resource-card" style="border: 0px solid rgba(0,0,0,.125); padding: 5px 5px;" *ngIf="list_count > 5  && loader == false && (data |searchFilter :searchs).length >5">
            <div class="row rowDesign">
              <div class="col-xl-12 col-lg-12">
                <div class="select-pagination">
                  <span id="showDesign">Show : </span>
                  <select class="select_list new-select_list" id="listDesign" [(ngModel)]="mySelect" (change)="showChanges()">
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="--example-container --mat-elevation-z8 --container-fluid">
            <div *ngIf="!loader">
              <!-- <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count > 0 && !loader" class="table table-responsive --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef class="text-center">&nbsp;S.No&nbsp;</th>
                  <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
                </ng-container>
                <ng-container matColumnDef="permissionDate">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                    Permission&nbsp;Date
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.permissionDate | date}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="permissionHours">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                    Permission&nbsp;Hours
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.permissionHours}} </td>
                </ng-container>
                <ng-container matColumnDef="reason">
                  <th mat-header-cell *matHeaderCellDef class="" style="width:20%; padding-left:2%">Reason</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <span class="reason-span" *ngIf="changeasDenyReason==false">{{element.permissionReason}}</span>
                    <span class="reason-span"
                      *ngIf="changeasDenyReason==true && clickedbusinessTravelId != i && (isMenuOpen==true || isMenuOpen==false)">{{element.permissionReason}}</span>
                    <span class="reason-span"
                      *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==false">{{element.permissionReason}}</span>
                    <span class="denied-span menu" #menu
                      *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">Denied
                      Reason</span>
                    <span class="reason-span-deny menu" #menu
                      *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">{{element.permissiondenyReason}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="viewapprovers">
                  <th></th>
                  <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%">View Approvers</th>
                  <td *matCellDef="let element" style="text-align:center">
                    <mat-icon class="view-icon" 
                      style="position: relative;top: 8px;" (click)="viewapproverstatus(element)">visibility</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="workFromHomeStatus">
                  <th mat-header-cell *matHeaderCellDef class="text-center">
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element let i = index" style="white-space: nowrap;"
                    [style.color]="withDrawStatus(element.permissionStatusName)">
                    {{element.permissionStatusName}}
                    <span *ngIf="element.permissionStatusName=='Approved'"
                      class="material-icons-outlined"
                      style="font-size: 16px;transform: translate(4px, 4px);">check_circle</span>
                    <a *ngIf="element.permissionStatusName=='Denied'"
                      class="material-icons-outlined" #toggleButton (click)="denyReason(i)"
                      style="font-size: 16px;transform: translate(4px, 4px);">error_outline</a>
                    <a *ngIf="element.permissionStatusName=='Denied'" style="font-size: 20px;">*</a>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="star">
                  <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <ng-container
                      *ngIf="element.permissionStatusName == 'Pending' ; else elseblock">
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                      </button>
                    </ng-container>
                    <ng-template #elseblock>
                      <mat-icon style="font-size: 17px;"> -- </mat-icon>
                    </ng-template>
                    <mat-menu #menu="matMenu">
                      <ng-container *ngIf="element.permissionStatusName == 'Pending'">
                        <a mat-menu-item (click)="cancel(element)">
                          <mat-icon class="cancel-trip-icon material-icons-outlined">clear</mat-icon>
                          <span class="cancel-trip-text">Cancel</span>
                        </a>
                      </ng-container>
                    </mat-menu>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table> -->
              <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px; margin-top: 15px;">
                <table class="table --table-bordered table-border-new" matSort style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                  <thead class="t-head" style="background-color: #f6f9fc;">
                    <tr>
                      <th class="text-center">S.No</th>
                      <th class="text-left">Permission Date</th>
                      <th class="text-left">Permission Hours</th>
                      <th class="text-left" style="width:20%;">Reason</th>
                      <th class="text-center">View Approvers</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody class="t-body">
                    <tr *ngFor="let element of data |searchFilter:searchs | paginate: { itemsPerPage: mySelect, currentPage: p }; let i = index">
                      <td class="text-center">{{ (p - 1) * mySelect + i + 1 }}</td>
                      <td class="text-left">{{element.permissionDate | date:'dd/MM/yyyy' }}</td>
                      <td class="text-left">{{element.permissionHours }}</td>
                      <td class="text-left">
                        <span class="reason-span" style="white-space: normal;padding:3%" *ngIf="!changeasDenyReason">{{element.permissionReason}}</span>
                        <span class="reason-span" style="white-space: normal;padding:3%" *ngIf="changeasDenyReason && clickedbusinessTravelId != i && isMenuOpen">{{element.permissionReason}}</span>
                        <span class="reason-span" style="white-space: normal;padding:3%" *ngIf="changeasDenyReason && clickedbusinessTravelId == i && !isMenuOpen">{{element.permissionReason}}</span>
                        <span class="denied-span menu" #menu *ngIf="changeasDenyReason && clickedbusinessTravelId == i && isMenuOpen">Denied Reason</span>
                        <span class="reason-span-deny menu" #menu *ngIf="changeasDenyReason && clickedbusinessTravelId == i && isMenuOpen">{{element.permissiondenyReason}}</span>
                      </td>
                      <td class="text-center">
                        <mat-icon class="view-icon" style="position: relative; top: 8px;" (click)="viewapproverstatus(element)">visibility</mat-icon>
                      </td>
                      <td class="text-center" [style.color]="withDrawStatus(element.permissionStatusName)">
                        {{element.permissionStatusName}}
                        <span *ngIf="element.permissionStatusName == 'Approved'" class="material-icons-outlined" style="font-size: 16px; transform: translate(4px, 4px);">check_circle</span>
                        <a *ngIf="element.permissionStatusName == 'Denied'" class="material-icons-outlined" #toggleButton (click)="denyReason(i)" style="font-size: 16px; transform: translate(4px, 4px);">error_outline</a>
                        <a *ngIf="element.permissionStatusName == 'Denied'" style="font-size: 20px;">*</a>
                      </td>
                      <td class="text-center">
                        <button *ngIf="element.permissionStatusName == 'Pending'" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                          <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                        </button>
                        <mat-icon *ngIf="element.permissionStatusName != 'Pending'" style="font-size: 17px;">--</mat-icon>
                        <mat-menu #menu="matMenu">
                          <a mat-menu-item *ngIf="element.permissionStatusName == 'Pending'" (click)="cancel(element)">
                            <mat-icon class="cancel-trip-icon material-icons-outlined">clear</mat-icon>
                            <span class="cancel-trip-text">Cancel</span>
                          </a>
                        </mat-menu>
                      </td>
                    </tr>
                    <tr>
                      <td
                      style="
                      text-align: center;
                      font-size: larger;
                      font-weight: bold;
                    "
                    colspan="9"
                    *ngIf="noData || ((data |searchFilter :searchs).length === 0 && searchs) ||
                             data.length == 0">
    No permission list                                </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="list_count > 5 && loader == false && (data |searchFilter :searchs).length >5">
                <pagination-controls class="float-right pagination" style="float: right;" (pageChange)="p = $event; beforepage = $event "></pagination-controls>
              </div>
              <!-- <div *ngIf="noData">
                <div class="alert alert-primary" style="width: 100%; text-align: center;">No permission list</div>
              </div> -->
            </div>
          </div>
        <!-- </ng-template> -->
        <!-- <div   [hidden]="loader">

          <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div> -->

      </div>

    </div>
  </div>
</div>
<div  *ngIf="loader" class="d-flex align-items-center justify-content-center" style="margin-top: 15%;" >
  <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;left: 48%;top: 50%;" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong><br>{{success_msg}}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br>{{error_msg}}
</div>