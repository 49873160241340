<!-- <div class="card card-new sub-header">
    <div class="card-body">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-5 sub-header-title">
                   Add Employee
                </div>
                <div class="col-md-7 sub-header-btn text-right pr-0 ">
                    <a routerLink="/home/employee" title="Back" routerLinkActive="active" mat-raised-button class="ml-2">
                        Back
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
 -->
<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">
            {{ employeeId ? "Edit" : "Add" }} Employee
          </span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
          <a
            routerLink="/home/employee"
            mat-raised-button
            title="Back"
            class="ml-2"
          >
            Back
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->
<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <div class="profile-tabs">
          <div class="row- mb-3">
            <div class="text-center1" *ngIf="!employeeId">
              <!-- add -->
              <span
                class="profile-tab"
                (click)="employeeDetails(1)"
                [ngClass]="{ 'profile-active': tabindex === 1 }"
                title="Basic Information"
                >Basic Information</span
              >

              <span
                class="profile-tab"
                *ngIf="insertEmployeeId"
                (click)="employeeDetails(2)"
                [ngClass]="{ 'profile-active': tabindex === 2 }"
                title="Employment Details"
                >Employment Details</span
              >
              <span
                class="profile-tab"
                *ngIf="insertEmployeeId"
                (click)="employeeDetails(3)"
                [ngClass]="{ 'profile-active': tabindex === 3 }"
                title="ID Information"
                >ID Information</span
              >
              <span
                class="profile-tab"
                *ngIf="insertEmployeeId"
                (click)="employeeDetails(4)"
                [ngClass]="{ 'profile-active': tabindex === 4 }"
                title="Address"
                >Address</span
              >
              <span
                class="profile-tab"
                *ngIf="insertEmployeeId"
                (click)="employeeDetails(5)"
                [ngClass]="{ 'profile-active': tabindex === 5 }"
                title="Bank Details"
                >Bank Details</span
              >

              <span
                class="profile-tab profile-unclick"
                *ngIf="!insertEmployeeId"
                title="Employment Details"
                >Employment Details</span
              >
              <span
                class="profile-tab profile-unclick"
                *ngIf="!insertEmployeeId"
                title="ID Information"
                >ID Information</span
              >
              <span
                class="profile-tab profile-unclick"
                *ngIf="!insertEmployeeId"
                title="Address"
                >Address</span
              >
              <span
                class="profile-tab profile-unclick"
                *ngIf="!insertEmployeeId"
                title="Bank Details"
                >Bank Details</span
              >

              <!-- <span class="profile-tab" *ngIf="!employeeId" (click)="employeeDetails(6);" [ngClass]="{'profile-active': tabindex ===  6}" title="Login">Login</span> -->
            </div>

            <div class="text-center1" *ngIf="employeeId">
              <!-- Edit -->
              <span
                class="profile-tab"
                (click)="employeeDetails(1)"
                [ngClass]="{ 'profile-active': tabindex === 1 }"
                title="Basic Information"
                >Basic Information</span
              >
              <span
                class="profile-tab"
                (click)="employeeDetails(2)"
                [ngClass]="{ 'profile-active': tabindex === 2 }"
                title="Employment Details"
                >Employment Details</span
              >
              <span
                class="profile-tab"
                (click)="employeeDetails(3)"
                [ngClass]="{ 'profile-active': tabindex === 3 }"
                title="ID Information"
                >ID Information</span
              >
              <span
                class="profile-tab"
                (click)="employeeDetails(4)"
                [ngClass]="{ 'profile-active': tabindex === 4 }"
                title="Address"
                >Address</span
              >
              <span
                class="profile-tab"
                (click)="employeeDetails(5)"
                [ngClass]="{ 'profile-active': tabindex === 5 }"
                title="Bank Details"
                >Bank Details</span
              >
              <!-- <span class="profile-tab" *ngIf="!employeeId" (click)="employeeDetails(6);" [ngClass]="{'profile-active': tabindex ===  6}" title="Login">Login</span> -->
            </div>
            <!-- progressbar -->
            <!--  <ul id="progressbar">
                            <li [ngClass]="step>=1 ? 'active' : 'inactive'">Basic Information</li>
                            <li [ngClass]="step>=2 ? 'active' : 'inactive'">Employment Details</li>
                            <li [ngClass]="step>=3 ? 'active' : 'inactive'">ID Information</li>
                            <li [ngClass]="step>=4 ? 'active' : 'inactive'">Address</li>
                            <li [ngClass]="step>=5 ? 'active' : 'inactive'">Bank Details</li>
                            <li *ngIf="!employeeId" [ngClass]="step==6 ? 'active' : 'inactive'">Login</li>
                        </ul> -->
            <!-- fieldsets -->
          </div>
        </div>
        <!-- Basic Information start -->
        <section>
          <!-- *ngIf="tabindex == 1" -->
          <form [formGroup]="basicInformationDetails" *ngIf="tabindex == 1">
            <!-- [formGroup]="postForm" (ngSubmit)="onSubmit()" -->
            <fieldset *ngIf="tabindex == 1">
              <!-- *ngIf="step == 1" -->
              <h3>Basic Information</h3>
              <div class="row">
                <!-- <div class="form-group col-md-3">
                                    <label class="col-form-label">Employee Role <span class="star">*</span></label>
                                     <select formControlName="roleId" [ngClass]="{ 'is-invalid': basic_step && basic.roleId.errors }" class="form-select form-select-solid form-select-lg">
                                        <option value="null">- Select -</option>
                                        <option *ngFor="let row of activeRolesData; let i=index;" value="{{row.roleId}}">{{row.roleName}}</option>
                                    </select>  -->

                <!-- <mat-select formControlName="roleId" class="form-select form-select-solid form-select-lg" appearance="fill" placeholder="- Select -">
                                        <mat-option value="null">- Select -</mat-option>
                                        <mat-option *ngFor="let row of activeRolesData" [value]="row.roleId">{{row.roleName}}</mat-option>
                                    </mat-select> -->

                <!-- <div class="valid-msg" *ngIf="(validate.roleId.invalid && validate.roleId.touched) || validate.roleId.dirty || submitted">
                                        <span *ngIf="validate.roleId.errors?.required" class="text-danger">Please select role</span>
                                    </div>
                                    <div class="valid-msg" *ngIf="basic_step && basicInformationDetails.controls.roleId.errors">
                                        <span class="text-danger" *ngIf="basic.roleId.errors?.required">Please select role</span>
                                    </div>
                                </div> -->
                <!-- <div class="form-group col-md-3">
                                    <label class="col-form-label">Client Name<span class="star">* </span></label> <br>
                                    <label class="form-select form-select-solid form-select-lg select2-new">{{ClientName}}<span class="star"></span></label> 
                                      <input type="text" formControlName="client"  class="form-select form-select-solid form-select-lg select2-new" readonly> -->
                <!-- <select formControlName="client"  class="form-select form-select-solid form-select-lg select2-new" (change)="getclientEmployees()">
                                        <option [value]="null"> --- Select Client --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>     -->
                <!-- <div class="valid-msg" *ngIf="(basic.client.invalid && basic.client.touched) || basic.client.dirty || submitted">
                                        <span *ngIf="basic.client.errors?.required" class="text-danger">Please select client</span>  -->
                <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                <!-- </div>  -->
                <!-- </div> -->

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Business Group <span class="star">*</span></label
                  >
                  <!-- <select
                    formControlName="employmentType"
                    id="employmentType"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of employementTypeLov; let i = index"
                      value="{{ row.listTypeValueId }}"
                    >
                      {{ row.listTypeValueName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="businessgroup"
                    
                    placeholder="Select Business Group"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Business Group-</mat-option>
                    <mat-option
                      *ngFor="let row of BusinessUnit; let i = index"
                      [value]="row.businessGroupId"
                    >
                      {{row.businessGroupName }}
                    </mat-option>
                  </mat-select>
                  <div class="valid-msg" *ngIf="(basicInformationDetails?.controls?.businessgroup?.invalid && basicInformationDetails?.controls?.businessgroup?.touched) ||basicInformationDetails?.controls?.businessgroup?.dirty || submitted">
                    <span *ngIf="basic.businessgroup.errors?.required" class="text-danger">Please select  Business Group</span>
                  </div>
                </div>



                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Employee No <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    (keypress)="noNumber($event)"
                    (paste)="blockPaste($event)"
                    (drop)="blockDrop($event)"
                    (keyup)="checkExistEmployeeNo($event)"
                    
                    formControlName="employeeNumber"
                    maxlength="8"
                    id="employeeNumber"
                    [ngClass]="{
                      'is-invalid': basic_step && basic.employeeNumber.errors
                    }"
                    placeholder="Employee No"
                    value="null"
                    autocomplete="off"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  />

                  <div
                    class="valid-msg"
                    *ngIf="
                      (basicInformationDetails.controls.employeeNumber
                        .invalid &&
                        basicInformationDetails.controls.employeeNumber
                          .touched) ||
                      basicInformationDetails.controls.employeeNumber.dirty ||
                      employeeNumberExistStatus ||
                      (basic_step &&
                        basicInformationDetails.controls.employeeNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="basic.employeeNumber.errors?.required"
                      >Please enter employee number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="basic.employeeNumber.errors?.pattern"
                      >Please enter vaild employee number</span
                    >
                    <span
                      class="span-error"
                      *ngIf="
                        employeeNumberExistStatus &&
                        !basic.employeeNumber.errors?.pattern
                      "
                    >
                      Employee no already exist!</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Employee First Name <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="firstName"
                    (keyup)="empFirstName($event)"
                    (keyup)="validateInputSpace($event)"
                    (keydown)="validateInputSpace($event)"
                    id="firstName"
                    [ngClass]="{
                      'is-invalid': basic_step && basic.firstName.errors
                    }"
                    placeholder="Employee First Name"
                    required=""
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    style="text-transform: capitalize"
                    value="null"
                  />
                  <!-- <div class="valid-msg" *ngIf="(validate.firstName.invalid && validate.firstName.touched) || validate.firstName.dirty || submitted">
                                        <span *ngIf="validate.firstName.errors?.required" class="text-danger">Please enter first name</span>
                                    </div> -->
                  <div
                    class="valid-msg"
                    *ngIf="
                      (basicInformationDetails.controls.firstName.invalid &&
                        basicInformationDetails.controls.firstName.touched) ||
                      basicInformationDetails.controls.firstName.dirty ||
                      (basic_step &&
                        basicInformationDetails.controls.firstName.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="basic.firstName.errors?.required"
                      >Please enter first name</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="basic.firstName.errors?.pattern"
                      >Please enter vaild first name</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Employee Last Name <span class="star"></span
                  ></label>
                  <input
                    type="text"
                    formControlName="lastName"
                    id="lastName"
                    (keyup)="validateInputSpace($event)"
                    (keydown)="validateInputSpace($event)"
                    [ngClass]="{
                      'is-invalid': basic_step && basic.lastName.errors
                    }"
                    placeholder="Employee Last Name"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0 only_name"
                    value="null"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (basic.lastName.invalid && basic.lastName.touched) ||
                      basic.lastName.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="basic.lastName.errors?.pattern"
                      class="text-danger"
                      >Please enter vaild last name</span
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label">Surname</label>
                  <input
                    type="text"
                    formControlName="middleName"
                    (keyup)="validateInputSpace($event)"
                    (keydown)="validateInputSpace($event)"
                    id="middleName"
                    placeholder="Sur Name"
                    autocomplete="off"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    value="null"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (basic.middleName.invalid && basic.middleName.touched) ||
                      basic.middleName.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="basic.middleName.errors?.pattern"
                      class="text-danger"
                      >Please vaild sur name</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Mobile Number <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    maxlength="10"
                    minlength="10"
                    (keypress)="noNumber($event)"
                    (paste)="blockPaste($event)"
                    (drop)="blockDrop($event)"
                    (keyup)="validateInputSpace($event)"
                    (keydown)="validateInputSpace($event)"
                    (keyup)="checkExistMobileNumber($event)"
                    formControlName="mobileNumber"
                    id="mobileNumber"
                    [ngClass]="{
                      'is-invalid': basic_step && basic.mobileNumber.errors
                    }"
                    placeholder="Mobile Number"
                    required=""
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    value="null"
                  />
                  <!-- <div class="valid-msg" *ngIf="(validate.mobileNumber.invalid && validate.mobileNumber.touched) || validate.mobileNumber.dirty || submitted">
                                        <span *ngIf="validate.mobileNumber.errors?.required" class="text-danger">Please enter mobile number</span>
                                    </div> -->

                  <div
                    class="valid-msg"
                    *ngIf="
                      (basic.mobileNumber.invalid &&
                        basic.mobileNumber.touched) ||
                      basic.mobileNumber.dirty ||
                      mobileNumberExistStatus ||
                      (basic_step &&
                        basicInformationDetails.controls.mobileNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="basic.mobileNumber.errors?.required"
                      >Please enter mobile number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="basic.mobileNumber.errors?.pattern"
                      >Please enter valid mobile number</span
                    >
                    <!-- <span *ngIf="basic.mobileNumber.errors?.minlength || basic.mobileNumber.errors?.maxlength" class="text-danger">Mobile Number must be exactly 10 digits</span> -->

                    <span class="span-error" *ngIf="mobileNumberExistStatus">
                      Mobile number already exist!</span
                    >
                  </div>

                  <!-- <div *ngIf="mobileVali == true">
                                        <span class="span-error" *ngIf="mobileVali">Please enter valid mobile number</span>
                                    </div> -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Alternate Mobile Number</label>
                  <input
                    maxlength="10"
                    minlength="10"
                    (keypress)="noNumber($event)"
                    (paste)="blockPaste($event)"
                    (drop)="blockDrop($event)"
                    (keyup)="validateInputSpace($event)"
                    (keydown)="validateInputSpace($event)"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        basic_step && basic.alternativeMobileNumber.errors
                    }"
                    formControlName="alternativeMobileNumber"
                    id="alternativeMobileNumber"
                    placeholder="Alternate Mobile Number"
                    class="form-control email-input select-placeholder"
                    value="null"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      (basic.alternativeMobileNumber.invalid &&
                        basic.alternativeMobileNumber.touched) ||
                      basic.alternativeMobileNumber.dirty ||
                      alternateMobileNumberExistStatus ||
                      (basic_step &&
                        basicInformationDetails.controls.alternativeMobileNumber
                          .errors)
                    "
                  >
                    <!-- <span class="text-danger" *ngIf="basic.mobileNumber.errors?.required">Please enter mobile number</span>
                                        -->
                    <span
                      class="text-danger"
                      *ngIf="basic.alternativeMobileNumber.errors?.pattern"
                      >Please enter valid mobile number</span
                    >
                    <!-- <span *ngIf="basic.alternativeMobileNumber.errors?.pattern" class="text-danger">Please Enter Valid Alternate Mobile Number</span> -->

                    <span
                      class="span-error"
                      *ngIf="alternateMobileNumberExistStatus"
                      >Alternative mobile number already exist!</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Email <span class="star">*</span></label
                  >
                  <input *ngIf="employeeId == undefined ; else disabledEmail"
                    type="email"
                    (keyup)="validateInputSpace($event)"
                (keyup)="checkExistEmail($event)"
               
                    (keydown)="validateInputSpace($event)"
                    formControlName="organizationEmail"
                    id="organizationEmail"
              
                    [ngClass]="{
                      'is-invalid': basic_step && basic.organizationEmail.errors
                    }"
                    placeholder="Email"
                    autocomplete="off"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    value="null"
                  />
                  <ng-template #disabledEmail>
                    <input
                      type="email"
                      formControlName="organizationEmail"
                      id="organizationEmail"
                      readonly="true"
                      [ngClass]="{
                        'is-invalid': basic_step && basic.organizationEmail.errors
                      }"
                      placeholder="Email"
                      autocomplete="off"
                      class="form-control email-input select-placeholder mb-3 mb-lg-0"
                      value="null"
                    />
                  </ng-template>
                  <!--  <div class="valid-msg" *ngIf="(validate.organizationEmail.invalid && validate.organizationEmail.touched) || validate.organizationEmail.dirty || submitted">
                                        <span *ngIf="validate.organizationEmail.errors?.required" class="text-danger">Please enter email</span>
                                    </div> -->
                  <div
                    class="valid-msg"
                    *ngIf="
                      emailExistStatus ||
                      basic.organizationEmail.dirty ||
                      (basic_step &&
                        basicInformationDetails.controls.organizationEmail
                          .errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="basic.organizationEmail.errors?.required"
                      >Please enter email</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="basic.organizationEmail.errors?.pattern"
                      >Please enter valid email</span
                    >
                    <span class="span-error" *ngIf="emailExistStatus">
                      Email already exist!</span
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label">Alternate E-Mail</label>
                  <input
                    type="text"
                    (keyup)="alternateCheckExistEmail($event)"
                    (keydown)="preventSpace($event)"
                    formControlName="alternativeEmail"
                    (keyup)="validateInputSpace($event)"
                    (keydown)="validateInputSpace($event)"
                    id="alternativeEmail"
                    placeholder="Alternate E-Mail"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0 only_name"
                    value="null"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      alternateemailExistStatus ||
                      basic.alternativeEmail.dirty ||
                      (basic_step &&
                        basicInformationDetails.controls.alternativeEmail
                          .errors)
                    "
                  >
                    <!--<span class="text-danger" *ngIf="basic.alternateEmail.errors?.required">Please enter email</span> -->
                    <span
                      class="text-danger"
                      *ngIf="basic.alternativeEmail.errors?.required"
                      >Please enter email</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="basic.alternativeEmail.errors?.pattern"
                      >Please enter valid email</span
                    >
                    <span class="span-error" *ngIf="alternateemailExistStatus">
                      Email already exist!</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Date of Birth <span class="star">*</span></label
                  >
                  <!-- <input
                    (dateChange)="empDob($event)"
                    formControlName="dateOfBirth"
                    id="dateOfBirth"
                    [ngClass]="{
                      'is-invalid': basic_step && basic.dateOfBirth.errors
                    }"
                    matInput
                    [matDatepicker]="dateOfBirth"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    readonly="readonly"
                    [max]="today"
                    placeholder="Select Date of Birth"
                  /> -->
                  <!-- <mat-datepicker-toggle
                    matSuffix
                    [for]="dateOfBirth"
                    style="float: right; position: relative; bottom: 38px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateOfBirth></mat-datepicker> -->
                  <input
                    placeholder="Date of Birth"
                    readonly="readonly"
                    readonly
                    matInput
                    [max]="today"
                    [matDatepicker]="dateOfBirth"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    style="background: #fff"
                    formControlName="dateOfBirth"
                    (dateChange)="validateInputSpace($event)"
                   
                    type="text"
                    id="date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateOfBirth"
                    style="float: right; position: relative; bottom: 42px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateOfBirth></mat-datepicker>

                  <div
                    class="valid-msg"
                    *ngIf="
                      basic_step &&
                      basicInformationDetails.controls.dateOfBirth.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="basic.dateOfBirth.errors?.required"
                      >Please select date of birth</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Gender
                    <span class="star">*</span>
                  </label>
                  <!-- <select
                    formControlName="gender"
                    id="gender"
                    [ngClass]="{
                      'is-invalid': submitted
                    }"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="Select" (click)="selectFun()">- Select -</option>
                    <option
                      *ngFor="let row of genderLov; let i = index"
                      value="{{ row.listTypeValueId }}"
                    >
                      {{ row.listTypeValueName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="gender"
                    id="gender"
                    placeholder="Select Gender"
                 
                    [ngClass]="{
                      'is-invalid': submitted
                    }"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Gender-</mat-option>

                    <mat-option
                      *ngFor="let row of genderLov; let i = index"
                      [value]="row.listTypeValueId"
                    >
                      {{ row.listTypeValueName }}
                    </mat-option>
                  </mat-select>

                  <!--  <div class="valid-msg" *ngIf="(validate.gender.invalid && validate.gender.touched) || validate.gender.dirty || submitted">
                                        <span *ngIf="validate.gender.errors?.required" class="text-danger">Please select gender</span>
                                    </div> -->
                  <!-- <div
                    class="valid-msg"
                    *ngIf="
                      basic_step &&
                      basicInformationDetails.controls.gender.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="basic.gender.errors?.required"
                      >Please select gender</span
                    >
                  </div> -->
                  <div
                    *ngIf="
                      (submitted &&
                        basicInformationDetails.controls['gender'].invalid) ||
                      setValid
                    "
                    class="valid-msg"
                  >
                    <span class="text-danger">Gender is required</span>
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Blood Group</label>
                  <!-- <select
                    formControlName="bloodGroup"
                    id="bloodGroup"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of bloodGroupLov; let i = index"
                      value="{{ row.listTypeValueId }}"
                    >
                      {{ row.listTypeValueName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="bloodGroup"
                    id="bloodGroup"
                    placeholder="Select Blood Group"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Blood Group-</mat-option>

                    <mat-option
                      *ngFor="let row of bloodGroupLov; let i = index"
                      [value]="row.listTypeValueId"
                    >
                      {{ row.listTypeValueName }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label">Stream</label>
                  <!-- <select
                    formControlName="streamId"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of activeStreamData; let i = index"
                      value="{{ row.streamId }}"
                    >
                      {{ row.streamName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="streamId"
                    placeholder="Select Stream"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Stream-</mat-option>

                    <mat-option
                      *ngFor="let row of activeStreamData; let i = index"
                      [value]="row.streamId"
                    >
                      {{ row.streamName }}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label">Qualification</label>
                  <input
                    type="text"
                    formControlName="qualification"
                    id="qualification"
                    placeholder="Qualification"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0 only_name"
                    value="null"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      basic.qualification.dirty ||
                      (basic_step &&
                        basicInformationDetails.controls.qualification.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="
                        basicInformationDetails
                          .get('qualification')
                          .hasError('pattern')
                      "
                      >Please enter vaild qualification</span
                    >
                    <!-- <span class="text-danger"*ngIf="basicInformationDetails.get('password').hasError('maxlength')">Maximum length is 20 characters</span> -->
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Marital Status</label>
                  <!-- <select
                    formControlName="martialStatus"
                    id="martialStatus"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of martialStatusLov; let i = index"
                      value="{{ row.listTypeValueId }}"
                    >
                      {{ row.listTypeValueName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="martialStatus"
                    id="martialStatus"
                    placeholder="Marital Status"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Marital Status-</mat-option>
                    <mat-option
                      *ngFor="let row of martialStatusLov; let i = index"
                      [value]="row.listTypeValueId"
                    >
                      {{ row.listTypeValueName }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Profile Image</label>
                  <input
                    type="file"
                    formControlName="profile_image"
                    (change)="getFileDetails($event)"
                    accept=".png,.jpg,.jpeg"
                    id="profile_image"
                    class="form-control email-input select-placeholder"
                  />
                  <img
                    *ngIf="employeeId"
                    class="profile-image"
                    src="{{ fileblob }}"
                    alt="Profile Image"
                    style="
                      width: 100px;
                      height: 100px;
                      margin-top: 10px;
                      border-radius: 5px;
                    "
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-3" *ngIf="!employeeId">
                  <label class="col-form-label"
                    >Password <span class="star">*</span></label
                  >
                  <input
                  [type]="passwordVisible ? 'text' : 'password'"
                    formControlName="password"
                    id="password"
                    [ngClass]="{ 'is-invalid': basic && basic.password.errors }"
                    class="form-control email-input select-placeholder only_name"
                    placeholder="Password"
                    autocomplete="new-password"
                    readonly
                  />
                  <mat-icon matSuffix class="visibility-icon"
                    (click)="togglePasswordVisibility()">

                    {{ passwordVisible ? 'visibility_off' : 'visibility' }}         
                    </mat-icon>
                  <div
                    class="valid-msg"
                    *ngIf="
                      basic_step &&
                      basicInformationDetails.controls.password.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="
                        basicInformationDetails
                          .get('password')
                          .hasError('required')
                      "
                      >Please enter password</span
                    >
                    <!-- <span class="text-danger"*ngIf="basicInformationDetails.get('password').hasError('maxlength')">Maximum length is 20 characters</span> -->
                  </div>
                  <!-- <div class="valid-msg" *ngIf="this.finalPassword.length > 20">
                                        <span class="text-danger">Maximum length is 20 characters</span>
                                    </div> -->
                  <div *ngIf="this.finalPassword.length > 20; else elseBlock">
                    <span class="text-danger"
                      >Maximum length is 20 characters</span
                    >
                  </div>
                  <ng-template #elseBlock> </ng-template>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">
                  <!-- <a
                    routerLink="/home/employee"
                    routerLinkActive="active"
                    mat-raised-button
                    title="Cancel"
                    class="mr-2"
                  >
                    Cancel
                  </a> -->

                  <button
                    mat-stroked-button
                    type="button"
                    routerLink="/home/employee"
                    (click)="employeeDetails(1)"
                    routerLinkActive="active"
                    style="
                      background: none;
                      color: var(--Red-Color, #ff5d5d);
                      border: 1px solid #ddd;
                      font-size: 16px;
                      font-weight: 550;
                      margin-left: 5px;
                      height: 35px;
                    "
                    class="mx-3"
                    [disabled]="!basicInfoDisabled"
                  >
                    Cancel
                  </button>
                  <!--<button type="button" (click)="next()" mat-raised-button color="primary">Next</button> -->
                  <!-- <input (click)="next()" type="button" name="next" class="next action-button btn btn-primary btn-sm" value="Save & Next"> -->
                  <!-- <input
                    (click)="basicSubmit()"
                    mat-raised-button
                    type="submit"
                    name="submit"
                    class="searchBtn"
                    value="Save & Next"
                    [disabled]="basicInformationDetails.invalid || !basicInfoDisabled"
                  /> -->
                  <button
                    (click)="basicSubmit()"
                    mat-raised-button
                    type="submit"
                    name="submit"
                    class="searchBtnTime create-button m-1"
                    [disabled]="!basicInfoDisabled"
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <!-- Basic Information end -->

        <!-- Employment details start -->
        <section>
          <form [formGroup]="employmentDetails" *ngIf="tabindex == 2">
            <!-- [formGroup]="postForm" (ngSubmit)="onSubmit()" -->
            <!-- *ngIf="tabindex == 2" -->
            <fieldset>
              <!-- *ngIf="step == 2" -->
              <h3>Employment Details</h3>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Employment Type <span class="star">*</span></label
                  >
                  <!-- <select
                    formControlName="employmentType"
                    id="employmentType"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of employementTypeLov; let i = index"
                      value="{{ row.listTypeValueId }}"
                    >
                      {{ row.listTypeValueName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="employmentType"
                    id="employmentType"
                    placeholder="Select Employment Type"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Employment Type-</mat-option>
                    <mat-option
                      *ngFor="let row of employementTypeLov; let i = index"
                      [value]="row.listTypeValueId"
                    >
                      {{ row.listTypeValueName }}
                    </mat-option>
                  </mat-select>
                  <div
                    class="valid-msg"
                    *ngIf="
                      employment_step &&
                      employmentDetails.controls.employmentType.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="employment.employmentType.errors?.required"
                      >Please Seletc Employment Type</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Branch</label>
                  <!-- <select
                    formControlName="branchId"
                    id="branchId"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of activeBranchData; let i = index"
                      value="{{ row.branchId }}"
                    >
                      {{ row.branchName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="branchId"
                    id="branchId"
                    placeholder="Select Branch"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Branch-</mat-option>
                    <mat-option
                      *ngFor="let row of activeBranchData; let i = index"
                      [value]="row.branchId"
                    >
                      {{ row.branchName }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Designation <span class="star">*</span></label
                  >
                  <!-- <select
                    formControlName="designationId"
                    id="designationId"
                    [ngClass]="{
                      'is-invalid':
                        employment && employment.designationId.errors
                    }"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of activeDesignationData; let i = index"
                      value="{{ row.designationId }}"
                    >
                      {{ row.designationName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="designationId"
                    id="branchId"
                    placeholder="Select Designation"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Designation-</mat-option>
                    <mat-option
                      *ngFor="let row of activeDesignationData; let i = index"
                      [value]="row.designationId"
                    >
                      {{ row.designationName }}
                    </mat-option>
                  </mat-select>
                  <div
                    class="valid-msg"
                    *ngIf="
                      employment_step &&
                      employmentDetails.controls.designationId.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="employment.designationId.errors?.required"
                      >Please select designation</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Department <span class="star">*</span></label
                  >
                  <!-- <select
                    formControlName="departmentId"
                    id="departmentId"
                    [ngClass]="{
                      'is-invalid': employment && employment.departmentId.errors
                    }"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of activeDepartmentData; let i = index"
                      value="{{ row.departmentId }}"
                    >
                      {{ row.departmentName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="departmentId"
                    id="departmentId"
                    placeholder="Select Department"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Department-</mat-option>
                    <mat-option
                      *ngFor="let row of activeDepartmentData; let i = index"
                      [value]="row.departmentId"
                    >
                      {{ row.departmentName }}
                    </mat-option>
                  </mat-select>
                  <div
                    class="valid-msg"
                    *ngIf="
                      employment_step &&
                      employmentDetails.controls.departmentId.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="employment.departmentId.errors?.required"
                      >Please select department</span
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Joining Confirmation Date</label
                  >
                  <!-- <input type="text" formControlName="dateOfLeaving" id="dateOfLeaving" placeholder="Date of Leaving" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off">
                                    -->
                  <!-- <input
                    formControlName="confirmationDate"
                    id="confirmationDate"
                    matInput
                    [matDatepicker]="confirmationDate" [max]="today"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0 email-input"
                    readonly="readonly"
                    placeholder="Select Confirmation Date"
                    (dateChange)="ConfirmationDate($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="confirmationDate"
                    style="float: right; position: relative; bottom: 38px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #confirmationDate></mat-datepicker> -->
                  <input
                    placeholder="Select Confirmation Date"
                    readonly
                    matInput
                    [matDatepicker]="confirmationDate"
                    class="form-control email-input select-placeholder"
                    style="background: #fff"
                    formControlName="confirmationDate"
                    type="text"
                    [max]="today"
                    (dateChange)=" checkStartDate();onConfirmationDateChange($event, confirmationDate)"
                    id="date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="confirmationDate"
                    style="float: right; position: relative; bottom: 42px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #confirmationDate #picker1></mat-datepicker>

                  <!-- <div class="valid-msg" *ngIf="employment_step && employmentDetails.controls.dateOfJoining.errors">
                                        <span class="text-danger" *ngIf="employment.dateOfJoining.errors?.required">Please select date of leaving</span>
                                    </div> -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Date of Joining <span class="star">*</span></label
                  >
                  <!-- <input type="text" formControlName="dateOfJoining" id="dateOfJoining" [ngClass]="{ 'is-invalid': employment && employment.dateOfJoining.errors }" placeholder="Date of Joining" autocomplete="off" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                     -->
                  <!-- <input
                    formControlName="dateOfJoining"
                    id="dateOfJoining"
                    [ngClass]="{
                      'is-invalid':
                        employment && employment.dateOfJoining.errors
                    }"
                    matInput
                    [matDatepicker]="dateOfJoining" [max]="today" [min]="employmentDetails.controls.confirmationDate.value"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    readonly="readonly"
                    placeholder="Select Date of Joining"
                    (dateChange)="DateofJoining($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateOfJoining"
                    style="float: right; position: relative; bottom: 38px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateOfJoining></mat-datepicker> -->
                  <input
                    placeholder="Select Date of Joining"
                    readonly
                    matInput
                    [matDatepicker]="picker2"
                    class="form-control email-input select-placeholder"
                    style="background: #fff"
                    formControlName="dateOfJoining"
                    type="text"
                    [min]="this.employmentDetails.get('confirmationDate').value"
                    (dateChange)="checkStartDate();onDateOfJoiningChange($event,picker2)"
                    id="date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                    style="float: right; position: relative; bottom: 42px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <!-- <div
                    class="valid-msg"
                    *ngIf="
                      employment_step &&
                      employmentDetails.controls.dateOfJoining.errors
                    "
                  > -->
                    <span
                      class="text-danger"
                      *ngIf="employment.dateOfJoining.errors?.required && setDatebool"
                      >Please select Date of Joining</span
                    >
                  <!-- </div> -->
                  <mat-error *ngIf="show_error_msg">
                    Joining Confirmation date cannot be greater than Joining
                    date.
                  </mat-error>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Year of Experience When Joining</label
                  >
                  <input
                    type="text"
                    formControlName="yearOfExperiance"
                    maxlength="2"
                    id="yearOfExperiance"
                    (paste)="noNumber($event)"
                    (keypress)="noNumber($event)"
                    [ngClass]="{
                      'is-invalid':
                        employment && employment.yearOfExperiance.errors
                    }"
                    placeholder="Year of Experience"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      employment_step &&
                      employmentDetails.controls.yearOfExperiance.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="employment.yearOfExperiance.errors?.required"
                      >Please enter year of experience</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="employment.yearOfExperiance.errors?.pattern"
                      >Please enter valid year of experience</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Rate Per Day</label>
                  <input
                    type="text"
                    formControlName="ratePerDay"
                    maxlength="4"
                    id="ratePerDay"
                    (paste)="noNumber($event)"
                    (keypress)="noNumber($event)"
                    [ngClass]="{
                      'is-invalid': employment && employment.ratePerDay.errors
                    }"
                    autocomplete="off"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="Rate Per Day"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      employment_step &&
                      employmentDetails.controls.ratePerDay.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="employment.ratePerDay.errors?.required"
                      >Please enter Rate Per Day</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="employment.ratePerDay.errors?.pattern"
                      >Please enter valid rate per day</span
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label">Rate Per Hour</label>
                  <input
                    type="text"
                    formControlName="ratePerHour"
                    maxlength="4"
                    id="ratePerHour"
                    (paste)="noNumber($event)"
                    (keypress)="noNumber($event)"
                    [ngClass]="{
                      'is-invalid': employment && employment.ratePerHour.errors
                    }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="Rate per Hour"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      employment_step &&
                      employmentDetails.controls.ratePerHour.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="employment.ratePerHour.errors?.required"
                      >Please enter rate per hour</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="employment.ratePerHour.errors?.pattern"
                      >Please enter valid rate per hour</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Pay Frequency</label>
                  <!-- <select
                    formControlName="payFrequency"
                    id="payFrequency"
                    class="form-select form-select-solid form-select-lg"
                  >
                    <option value="null">- Select -</option>
                    <option
                      *ngFor="let row of payFrequencyStatusLov; let i = index"
                      value="{{ row.listTypeValueId }}"
                    >
                      {{ row.listTypeValueName }}
                    </option>
                  </select> -->
                  <mat-select
                    formControlName="payFrequency"
                    id="payFrequency"
                    placeholder="Select Pay Frequency"
                    class="form-control email-input select-placeholder"
                  >
                    <mat-option value="">-Select Pay Frequency-</mat-option>
                    <mat-option
                      *ngFor="let row of payFrequencyStatusLov; let i = index"
                      [value]="row.listTypeValueId"
                    >
                      {{ row.listTypeValueName }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Last Working Date</label>
                  <input
                    formControlName="lastWorkingDate"
                    id="lastWorkingDate"
                    matInput
                    [min]="minLastWorkingDate"
                    (dateChange)="onLastWorkingDateChange($event,lastWorkingDate)"
                    [matDatepicker]="lastWorkingDate"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    readonly="readonly"
                    placeholder="Last Working Date"
                  />
                  <!-- [max] = "today" -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="lastWorkingDate"
                    style="float: right; position: relative; bottom: 38px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #lastWorkingDate></mat-datepicker>

                  <!-- <input type="text" formControlName="lastWorkingDate" id="lastWorkingDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="Last Working Date">
                                 -->
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">
                  <!--<a (click)="employeeDetails(1);" mat-raised-button title="Cancel" class="mr-2">
                                        Back
                                    </a>
                                    <button type="submit" mat-raised-button color="primary">Next</button>
                                    -->
                  <!-- <input (click)="next()" type="button" name="next" class="next action-button btn btn-primary btn-sm" value="Save & Next" /> -->

                  <!-- <input
                    (click)="employeeDetails(1)"
                    type="button"
                    name="previous"
                    class="previous action-button-previous btn btn-light btn-sm mr-2"
                    value="Back"
                  /> -->

                  <button
                    mat-raised-button
                    type="button"
                    class="mx-3 mt-2"
                    (click)="employeeDetails(1)"
                    style="
                      background: none;
                      color: black;
                      border: 1px solid #ddd;
                      font-size: 16px;
                      font-weight: 610;
                      margin-left: 5px;
                      height: 36px;
                    "
                  >
                    Back
                  </button>
                  <!-- <input
                    (click)="employmentSubmit()"
                    type="submit"
                    [disabled]="employmentDetails.invalid || !employeeDetailsDisabled"
                    name="submit"
                    class="submit action-button btn btn-primary btn-sm"
                    value="Save & Next"
                  /> -->

                  <button
                    (click)="employmentSubmit()"
                    mat-raised-button
                    type="submit"
                    name="submit"
                    class="searchBtn"
                    [disabled]="!employeeDetailsDisabled"
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <!-- Employment details end -->

        <!-- ID Information start -->
        <section>
          <!-- *ngIf="tabindex == 3" -->
          <form [formGroup]="idDetails" *ngIf="tabindex == 3">
            <!-- [formGroup]="postForm" (ngSubmit)="onSubmit()" -->
            <!-- *ngIf="tabindex == 3" -->

            <fieldset>
              <!--  *ngIf="step == 3" -->
              <h3>ID Information</h3>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Aadhaar No <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="aadhaarNumber"
                    minlength="12"
                    maxlength="12"
                    (keypress)="noNumber($event)"
                    (paste)="blockPaste($event)"
                    (drop)="blockDrop($event)"
                    id="aadhaarNumber"
                    [ngClass]="{ 'is-invalid': id && id.aadhaarNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="Aadhaar No"
                  />

                  <span class="small">Ex : 489118465046</span>
                  <!-- <div
                    class="valid-msg"
                    *ngIf="
                      id.aadhaarNumber.dirty ||
                      id.aadhaarNumber.touched ||
                      (id_step && idDetails.controls.aadhaarNumber.errors) && setAdharbool
                    "
                  > -->
                  <br>
                    <span
                      class="text-danger"
                      *ngIf="id.aadhaarNumber.errors?.required  && setAdharbool"
                      >Please enter aadhaar number</span>

                    <!-- <span
                      class="text-danger"
                      *ngIf="id.aadhaarNumber.errors?.pattern"
                      >Please enter valid aadhaar number</span
                    > -->
                  <!-- </div> -->
                  <!-- <div class="valid-msg" >
                    <span
                      class="text-danger"
                      *ngIf="aadhaarNumber?.errors?.invalidAadhaarNumber "
                    >
                      Please enter Valid aadhaar number
                    </span>
                  </div>
                </div> -->
                  <div class="valid-msg">
    <span class="text-danger" *ngIf="id.aadhaarNumber.errors?.minlength">
      Please enter Valid aadhaar number
    </span>
    <span class="text-danger" *ngIf="id.aadhaarNumber.errors?.maxlength">
      Please enter Valid aadhaar number
    </span>
  </div>
  </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">PAN No</label>
                  <!-- <input
                    type="text"
                    formControlName="panNumber"
                    id="panNumber"
                    [ngClass]="{ 'is-invalid': id && id.panNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="PAN No"
                  /> -->
                  <input
                    type="text"
                    formControlName="panNumber"
                    id="panNumber"
                    [maxlength]="10"
                    [minlength]="10"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    (input)="onPanNumberInput($event)"
                    [ngClass]="{ 'is-invalid': id && id.panNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="PAN No"
                  />
                  <span class="small">Ex : ABCDE1234F</span>
                  <div
                    class="valid-msg"
                    *ngIf="
                      id.panNumber.dirty ||
                      (id_step && idDetails.controls.panNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="id.panNumber.errors?.required"
                      >Please enter PAN number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="id.panNumber.errors?.pattern"
                      >Please enter valid PAN number</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Passport No</label>
                  <input
                    type="text"
                    formControlName="passportNumber"
                    id="passportNumber"
                    [maxlength]="8"
                    [minlength]="8"
                    (input)="onPassportNumberChange($event)"
                    [ngClass]="{ 'is-invalid': id && id.passportNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="Passport No"
                  />
                  <span class="small">Ex : A1234567</span>
                  <div
                    class="valid-msg"
                    *ngIf="
                      id.passportNumber.dirty ||
                      (id_step && idDetails.controls.passportNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="id.passportNumber.errors?.required"
                      >Please enter passport number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="id.passportNumber.errors?.pattern"
                      >Please enter valid passport number</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Passport Expiry Date</label>
                  <input
                    formControlName="passportValidityDate"
                    id="passportValidityDate"
                    matInput
                    [matDatepicker]="passportValidityDate"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0 email-input"
                    readonly="readonly"
                    placeholder="Select Expiry Date"
                  />
                  <!-- [max] = "today" -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="passportValidityDate"
                    style="float: right; position: relative; bottom: 38px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #passportValidityDate></mat-datepicker>
                  <!-- <div class="valid-msg" *ngIf="id_step && idDetails.controls.passportNumber.errors">
                                        <span class="text-danger" *ngIf="id.passportExpiryDate.errors?.required">Please enter passport number</span>
                                        <span class="text-danger" *ngIf="id.passportExpiryDate.errors?.pattern">Please enter passport number</span>
                                    </div> -->
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label">Driving Licence No</label>
                  <input
                    type="text"
                    formControlName="licenceNumber"
                    id="licenceNumber"
                    [maxlength]="15"
                    [minlength]="15"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    (input)="convertToUppercase()"
                    [ngClass]="{ 'is-invalid': id && id.licenceNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="Driving Licence No"
                  />
                  <span class="small">Ex : TN0619850034761</span>
                  <div
                    class="valid-msg"
                    *ngIf="
                      id.licenceNumber.dirty ||
                      (id_step && idDetails.controls.licenceNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="id.licenceNumber.errors?.required"
                      >Please enter licence number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="id.licenceNumber.errors?.pattern"
                      >Please enter valid licence number</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Driving Licence Expiry Date</label
                  >
                  <input
                    formControlName="licenceValidityDate"
                    id="licenceValidityDate"
                    matInput
                    [matDatepicker]="licenceValidityDate"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0 email-input"
                    readonly="readonly"
                    placeholder="Select Expiry Date"
                  />
                  <!-- [max] = "today" -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="licenceValidityDate"
                    style="float: right; position: relative; bottom: 38px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #licenceValidityDate></mat-datepicker>
                  <!-- <div class="valid-msg" *ngIf="id_step && idDetails.controls.passportNumber.errors">
                                        <span class="text-danger" *ngIf="id.passportExpiryDate.errors?.required">Please enter passport number</span>
                                        <span class="text-danger" *ngIf="id.passportExpiryDate.errors?.pattern">Please enter passport number</span>
                                    </div> -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Voter ID</label>
                  <input
                    type="text"
                    formControlName="voterId"
                    id="voterId"
                    [maxlength]="10"
                    [minlength]="10"
                    [ngClass]="{ 'is-invalid': id && id.voterId.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    (input)="convertToUpperCase($event)"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    placeholder="Voter ID"
                  />
                  <span class="small">Ex : SRD0676361</span>
                  <div
                    class="valid-msg"
                    *ngIf="
                      id.voterId.dirty ||
                      (id_step && idDetails.controls.voterId.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="id.voterId.errors?.required"
                      >Please enter voter ID</span
                    >
                    <span class="text-danger" *ngIf="id.voterId.errors?.pattern"
                      >Please enter valid voter ID</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">PF No</label>
                  <input
                    type="text"
                    formControlName="pfNumber"
                    id="pfNumber"
                    [ngClass]="{ 'is-invalid': id && id.pfNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="PF No"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      id.pfNumber.dirty ||
                      (id_step && idDetails.controls.pfNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="id.pfNumber.errors?.required"
                      >Please enter PF number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="id.pfNumber.errors?.pattern"
                      >Please enter valid PF number</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">ESI No</label>
                  <input
                    type="text"
                    formControlName="esiNumber"
                    id="esiNumber"
                    [ngClass]="{ 'is-invalid': id && id.esiNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0 only_name"
                    placeholder="ESI No"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      id.esiNumber.dirty ||
                      (id_step && idDetails.controls.esiNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="id.esiNumber.errors?.required"
                      >Please enter ESI number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="id.esiNumber.errors?.pattern"
                      >Please enter valid ESI number</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label">UAN No </label>
                  <!-- <label class="col-form-label">UAN No</label> -->
                  <input
                    type="text"
                    formControlName="uanNumber"
                    id="uanNumber"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    [maxlength]="12"
                    [minlength]="12"
                    (input)="validateInput($event)"
                    [ngClass]="{ 'is-invalid': id && id.uanNumber.errors }"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                    placeholder="UAN No"
                    numericOnly
                  />
                  <span class="small">Ex :100015555042</span>
                  <!-- <div
                    class="valid-msg"
                    *ngIf="
                      id.uanNumber.dirty ||
                      (id_step && idDetails.controls.uanNumber.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="id.uanNumber.errors?.required"
                      >Please enter UAN number</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="id.uanNumber.errors?.pattern"
                      >Please enter valid UAN number</span
                    >
                  </div> -->
                  <!-- <div *ngIf="idDetails.get('uanNumber')?.invalid && (idDetails.get('uanNumber')?.dirty || idDetails.get('uanNumber')?.touched)">
                    <span class="text-danger" *ngIf="idDetails.get('uanNumber')?.errors?.required">Please enter UAN number</span>
                  </div> -->
                  <div
                  class="valid-msg"
                  
                >
                  <!-- <span class="text-danger" *ngIf="idDetails.get('uanNumber').errors.pattern">
                    Please enter Valid UAN Number
                  </span> -->
                  <span class="text-danger" *ngIf="id.uanNumber.errors?.pattern">
                    Please enter Valid UAN Number
                  </span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <input
                    (click)="adddocument()"
                    type="button"
                    style="margin-top: 39px"
                    class="btn btn-primary btn-sm"
                    value="Add Documents"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="table-responsive"
                    *ngIf="getFormControls.controls.length != 0"
                    style="
                      max-height: 363px;
                      overflow: auto;
                      overflow-x: hidden;
                    "
                  >
                    <table class="table table-border">
                      <thead class="text-nowrap">
                        <th>
                          Document Name<span class="text-danger">*</span
                          ><span class="resizer"></span>
                        </th>
                        <th>
                          Upload Document <span class="text-danger">*</span
                          ><span class="resizer"></span>
                        </th>
                        <th>
                          Document Type<span class="text-danger">*</span
                          ><span class="resizer1"></span>
                        </th>
                        <th>
                          Description<span class="resizer1"></span>
                        </th>
                        <th>Action</th>
                      </thead>
                      <tbody>
                        <ng-container
                          formArrayName="tableRows"
                          *ngFor="
                            let group of getFormControls.controls;
                            let i = index
                          "
                        >
                          <tr
                            *ngIf="group.get('isEditable').value"
                            [formGroupName]="i"
                          >
                            <td>
                              <div>
                                <!-- <select
                                  formControlName="identiyDocumentType"
                                  class="form-select form-select-solid form-select-lg"
                                >
                                  <option value="null">- Select -</option>
                                  <option
                                    *ngFor="
                                      let row of identityDocumentTypes;
                                      let i = index
                                    "
                                    value="{{ row.listTypeValueId }}"
                                  >
                                    {{ row.listTypeValueName }}
                                  </option>
                                </select> -->
                                <mat-select
                                  formControlName="identiyDocumentType"
                                  placeholder="Select Document Name"
                                  class="form-control email-input select-placeholder"
                                >
                                  <mat-option value=""
                                    >-Select Document Name-</mat-option
                                  >
                                  <mat-option
                                    *ngFor="
                                      let row of identityDocumentTypes;
                                      let i = index
                                    "
                                    [value]="row.listTypeValueId"
                                  >
                                    {{ row.listTypeValueName }}
                                  </mat-option>
                                </mat-select>
                                <!-- <div
                                  *ngIf="
                                    (submitted &&
                                      group['controls'].identiyDocumentType
                                        .invalid) ||
                                    group['controls'].identiyDocumentType.dirty ||  group['controls'].identiyDocumentType.touched
                                  "
                                > -->
                                <div
                                  *ngIf="
                                    id_step &&
                                    group['controls'].identiyDocumentType.errors
                                  "
                                >
                                  <small
                                    *ngIf="
                                      group['controls'].identiyDocumentType
                                        .errors?.required
                                    "
                                    class="text-danger"
                                    >Please select Document Name</small
                                  >
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <label class="file-label">
                                  <div class="row">
                                    <div class="col-md-2">
                                      <mat-icon
                                        class="material-symbols-outlined"
                                      >
                                        attach_file_add
                                      </mat-icon>
                                    </div>
                                    <div class="col-md-10">
                                      <span>{{
                                        group.get("fileName").value ||
                                          "Attach File"
                                      }}</span>
                                    </div>
                                  </div>
                                  <input
                                    type="file"
                                    formControlName="filedata"
                                    placeholder="Enter description"
                                    accept=".png,.jpg,.jpeg,.pdf"
                                    class="file-input"
                                    (change)="
                                      getIdentityFileDetails($event, i, group)
                                    "
                                  />
                                </label>
                                <i
                                  *ngIf="group.get('fileId').value != undefined"
                                  style="    color: #007bff;
                                  padding: 8px;
                                  position: relative;
                                  top: -7px;"
                                  class="fa fa-download"
                                  (click)="
                                    downloadfiles(group.get('fileId').value)
                                  "
                                  aria-hidden="true"
                                ></i>
                                <!-- <button *ngIf="group.get('fileId').value != undefined"   (click) = "downloadfiles(group.get('fileId').value)"  ><a *ngIf="group.get('fileId').value != undefined"  class="file-download badge badge-secondary form-control-label" >
                                                                    Download
                                                                </a></button> -->

                                <!-- <div 
                                  *ngIf="(submitted &&   group['controls'].filedata.invalid)">
                                  <small
                                    *ngIf="
                                      group['controls'].filedata.errors
                                        ?.required
                                    "
                                    class="text-danger"
                                    >Please upload file</small> -->
                                    <!-- <div *ngIf="submitted && setDocument">
                                      <small class="text-danger">Please Upload Document</small>
                                    </div> -->
                                    <div
                                    *ngIf="
                                      id_step &&
                                      group['controls'].filedata.errors
                                    "
                                  >
                                    <small
                                      *ngIf="
                                        group['controls'].filedata
                                          .errors?.required
                                      "
                                      class="text-danger"
                                      >Please Upload Document</small
                                    >
                                  </div>
                                    <!-- <div *ngIf="submitted && !group.get('filedata').value">
                                      <small class="text-danger">Please Upload Document</small>
                                    </div> -->
                                <!-- </div> -->
                              </div>
                            </td>
                            <td>
                              <div>
                                <!-- <select
                                  formControlName="documentType"
                                  class="form-select form-select-solid form-select-lg"
                                >
                                  <option value="null">- Select -</option>
                                  <option
                                    *ngFor="
                                      let dcrow of documentypes;
                                      let i = index
                                    "
                                    value="{{ dcrow.listTypeValueId }}"
                                  >
                                    {{ dcrow.listTypeValueName }}
                                  </option>
                                </select> -->
                                <mat-select
                                  formControlName="documentType"
                                  placeholder="Select Document Type"
                                  class="form-control email-input select-placeholder"
                                >
                                  <mat-option value=""
                                    >-Select Document Type-</mat-option
                                  >
                                  <mat-option
                                    *ngFor="
                                      let dcrow of documentypes;
                                      let i = index
                                    "
                                    [value]="dcrow.listTypeValueId"
                                  >
                                    {{ dcrow.listTypeValueName }}
                                  </mat-option>
                                </mat-select>
                                <div
                                  *ngIf="
                                    id_step &&
                                    group['controls'].documentType.errors
                                  "
                                >
                                  <small
                                    *ngIf="
                                      group['controls'].documentType.errors
                                        ?.required
                                    "
                                    class="text-danger"
                                    >Please select Document Type</small
                                  >
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  type="text"
                                  formControlName="description"
                                  id="description"
                                  (keyup)="validateInputSpace($event)"
                                  (keydown)="validateInputSpace($event)"
                                  [ngClass]="{
                                    'is-invalid': id && group['controls'].errors
                                  }"
                                  class="form-control email-input select-placeholder mb-3 mb-lg-0 only_name"
                                  placeholder="Description"
                                  autocomplete="off"
                                />
                                <div
                                  class="valid-msg"
                                  *ngIf="
                                    id_step &&
                                    group['controls'].description.errors
                                  "
                                >
                                  <span
                                    class="text-danger"
                                    *ngIf="
                                      group['controls'].description.errors
                                        ?.required
                                    "
                                    >Please enter Description</span
                                  >
                                  <span
                                    class="text-danger"
                                    *ngIf="
                                      group['controls'].description.errors
                                        ?.pattern
                                    "
                                    >Please enter valid Description</span
                                  >
                                </div>
                              </div>
                            </td>

                            <td style="text-align: center">
                              <mat-icon
                                style="
                                  color: red;
                                  position: relative;
                                  top: 10px;
                                "
                                class="material-symbols-outlined delete"
                                (click)="deleteRow(i, group)"
                                >delete</mat-icon
                              >
                            </td>
                          </tr>
                          <tr *ngIf="!group.get('isEditable').value">
                            <td>
                              {{ group.get("taskname").value }}
                            </td>
                            <td>
                              {{ group.get("taskdescription").value }}
                            </td>
                            <td>
                              {{ group.get("startdate").value }}
                            </td>
                            <td>
                              {{ group.get("enddate").value }}
                            </td>
                            <td>
                              {{ group.get("hours").value }}
                            </td>
                            <td>
                              {{ group.get("minutes").value }}
                            </td>
                            <td>
                              <!-- <mat-icon class="edit" (click)="editRow(group)">edit</mat-icon> -->
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <small *ngIf="attachmentypesexit" class="text-danger"
                      >Documents Already Exist!</small
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <!-- <a (click)="employeeDetails(2);" mat-raised-button title="Cancel" class="mr-2">
                                        Back
                                    </a>
                                    <button type="submit" mat-raised-button color="primary">Next</button>
                                    -->
                  <!-- <input (click)="previous()" type="button" name="previous" class="previous action-button-previous btn btn-light btn-sm mr-2" value="Previous" />
                                    <input (click)="next()" type="button" name="next" class="next action-button btn btn-primary btn-sm" value="Save & Next" />
                                    -->
                  <!-- <input
                    (click)="employeeDetails(2)"
                    type="button"
                    name="previous"
                    class="previous action-button-previous btn btn-light btn-sm mr-2"
                    value="Back"
                  /> -->

                  <button
                    mat-raised-button
                    type="button"
                    class="mx-3 mt-2"
                    (click)="employeeDetails(2)"
                    style="
                      background: none;
                      color: black;
                      border: 1px solid #ddd;
                      font-size: 16px;
                      font-weight: 610;
                      margin-left: 5px;
                      height: 36px;
                    "
                  >
                    Back
                  </button>
                  <!-- <input
                    (click)="idSubmit()"
                    type="submit"
                    name="submit"
                    class="submit action-button btn btn-primary btn-sm"
                    value="Save & Next"
                  /> -->
                  <!-- [disabled]="idDetails.invalid || !iDInformation" -->
                  <button
                    (click)="idSubmit()"
                    mat-raised-button
                    type="submit"
                    name="submit"
                    class="searchBtn"
                    [disabled]="!iDInformation"
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <!-- ID Information end -->

        <!-- Address end -->
        <section>
          <!--  -->
          <form [formGroup]="addressDetails">
            <fieldset *ngIf="tabindex == 4">
              <!-- *ngIf="step == 4" -->
              <h3>Address</h3>
              <h4>Current Address</h4>

              <!--  <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Country <span class="star">*</span></label>
                                    <select formControlName="countryId" (change)="countryChange($event)" id="countryId" [ngClass]="{ 'is-invalid': address1 && address1.countryId.errors }" class="form-select form-select-solid form-select-lg">
                                        <option value="null">- Select -</option>
                                        <option *ngFor="let row of activeCountryData; let i=index;" value="{{row.countryId}}">{{row.countryName}}</option>
                                    </select>
                                    <div class="valid-msg" *ngIf="address_step && addressDetails.controls.countryId.errors">
                                        <span class="text-danger" *ngIf="address1.countryId.errors?.required">Please select country</span>
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label class="col-form-label">State <span class="star">*</span></label>
                                    <select formControlName="stateId" id="stateId" (change)="stateChange($event)" [ngClass]="{ 'is-invalid': address1 && address1.stateId.errors }" class="form-select form-select-solid form-select-lg">
                                        <option value="null">- Select -</option>
                                        <option *ngFor="let row of stateList; let i=index;" value="{{row.stateId}}">{{row.stateName}}</option>
                                    </select>
                                    <div class="valid-msg" *ngIf="address_step && addressDetails.controls.stateId.errors">
                                        <span class="text-danger" *ngIf="address1.stateId.errors?.required">Please select state</span>
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label class="col-form-label">District <span class="star">*</span></label>
                                    <select formControlName="districtId" id="districtId" (change)="districtChange($event)" [ngClass]="{ 'is-invalid': address1 && address1.districtId.errors }" class="form-select form-select-solid form-select-lg">
                                       <option value="null">- Select -</option>
                                       <option *ngFor="let row of districtList; let i=index;" value="{{row.districtId}}">{{row.districtName}}</option>
                                    </select>
                                    <div class="valid-msg" *ngIf="address_step && addressDetails.controls.districtId.errors">
                                        <span class="text-danger" *ngIf="address1.districtId.errors?.required">Please select district</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">City</label>
                                    <select formControlName="cityId" id="cityId" class="form-select form-select-solid form-select-lg">
                                        <option value="null">- Select -</option>
                                         <option *ngFor="let row of cityList; let i=index;" value="{{row.cityId}}">{{row.cityName}}</option>
                                    </select>
                                </div>
                            </div> -->

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Country <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    (keyup)="countryAutoCompleteSearch($event)"
                    formControlName="countryName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="Country Name"
                    autocomplete="off"
                  />
                  <input
                    type="hidden"
                    formControlName="countryId"
                    id="countryId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      address_step && addressDetails.controls.countryId.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="address1.countryId.errors?.required"
                      >Please select country</span
                    >
                  </div>

                  <!-- AutoComplete Search here -->
                  <span *ngIf="autoCompleteCountrySearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="countryData?.length > 0"
                    >
                      <li
                        (click)="
                          getFilterData(
                            searchData.countryId,
                            searchData.countryName,
                            1
                          )
                        "
                        *ngFor="let searchData of countryData"
                      >
                        {{ searchData.countryName }}
                      </li>
                    </ul>
                  </span>
                  <!-- AutoComplete End here -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >State <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    (keyup)="stateAutoCompleteSearch($event)"
                    formControlName="stateName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="State Name"
                    autocomplete="off"
                  />
                  <input
                    type="hidden"
                    formControlName="stateId"
                    id="stateId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />

                  <div
                    class="valid-msg"
                    *ngIf="
                      address_step && addressDetails.controls.stateId.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="address1.stateId.errors?.required"
                      >Please select state</span
                    >
                  </div>

                  <!-- AutoComplete Search here -->
                  <span *ngIf="autoCompleteStateSearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="stateData?.length > 0"
                    >
                      <li
                        (click)="
                          getFilterData(
                            searchData.stateId,
                            searchData.stateName,
                            2
                          )
                        "
                        *ngFor="let searchData of stateData"
                      >
                        {{ searchData.stateName }}
                      </li>
                    </ul>
                  </span>
                  <!-- AutoComplete End here -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >District <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    (keyup)="districtAutoCompleteSearch($event)"
                    formControlName="districtName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="District Name"
                    autocomplete="off"
                  />
                  <input
                    type="hidden"
                    formControlName="districtId"
                    id="districtId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />

                  <!-- <div
                    class="valid-msg"
                    *ngIf="
                      address_step && addressDetails.controls.districtId.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="address1.districtId.errors?.required"
                      >Please select district</span
                    >
                  </div> -->
                  <div
                  class="valid-msg"
                  *ngIf="
                    address1.districtId.dirty ||
                    (address_step &&
                      addressDetails.controls.districtId.errors)
                  "
                >
                  <span
                    class="text-danger"
                    *ngIf="address1.districtId.errors?.required"
                    >Please select district</span
                  >
                
                </div>

                  <!-- AutoComplete Search here -->
                  <span *ngIf="autoCompleteDistrictSearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="districtData?.length > 0"
                    >
                      <li
                        (click)="
                          getFilterData(
                            searchData.districtId,
                            searchData.districtName,
                            3
                          )
                        "
                        *ngFor="let searchData of districtData"
                      >
                        {{ searchData.districtName }}
                      </li>
                    </ul>
                  </span>
                  <!-- AutoComplete End here -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >City <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    (keyup)="cityAutoCompleteSearch($event)"
                    formControlName="cityName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="City Name"
                    autocomplete="off"
                  />
                  <input
                    type="hidden"
                    formControlName="cityId"
                    id="cityId"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    autocomplete="off"
                  />

                  <div
                    class="valid-msg"
                    *ngIf="
                      address_step && addressDetails.controls.cityId.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="address1.cityId.errors?.required"
                      >Please select City</span
                    >
                  </div>

                  <!-- AutoComplete Search here -->
                  <span *ngIf="autoCompleteCitySearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="cityData?.length > 0"
                    >
                      <li
                        (click)="
                          getFilterData(
                            searchData.cityId,
                            searchData.cityName,
                            4
                          )
                        "
                        *ngFor="let searchData of cityData"
                      >
                        {{ searchData.cityName }}
                      </li>
                    </ul>
                  </span>
                  <!-- AutoComplete End here -->
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Postal Code <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="postalCode"
                    [maxlength]="6"
                    [minlength]="6"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    (input)="onPostalCodeInput($event)"
                    id="postalCode"
                    (keyup)="postalCode()"
                    [ngClass]="{
                      'is-invalid': address1 && address1.postalCode.errors
                    }"
                    autocomplete="off"
                    class="form-control email-input select-placeholder"
                    placeholder="Postal Code"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      address1.postalCode.dirty ||
                      (address_step &&
                        addressDetails.controls.postalCode.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="address1.postalCode.errors?.required"
                      >Please enter postal code</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="address1.postalCode.errors?.pattern"
                      >Please enter valid postal code</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Address <span class="star">*</span></label
                  >
                  <textarea
                    formControlName="address"
                    id="address"
                    placeholder="Address"
                    (keyup)="copyAddress()"
                    [ngClass]="{
                      'is-invalid': address1 && address1.address.errors
                    }"
                    class="form-control email-input select-placeholder"
                    rows="1"
                  ></textarea>
                  <div
                    class="valid-msg"
                    *ngIf="
                      address1.address.dirty ||
                      (address_step && addressDetails.controls.address.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="address1.address.errors?.required"
                      >Please enter address</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="address1.address.errors?.pattern"
                      >Please enter valid address</span
                    >
                  </div>
                </div>
              </div>

              <h4>Permanent  Address</h4>
              <input
                type="checkbox"
                (click)="checkUncheckAll()"
                formControlName="copyCurrentAddress"
                value="1"
                id="copyCurrentAddress"
              />
              &nbsp;
              <span class="small " style="position: relative;
              top: -3px;
              font-weight: 500;">Copy Current Address</span>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label">Country </label>
                  <input
                    type="text"
                    (keyup)="permenantcountryAutoCompleteSearch($event)"
                    formControlName="permenantCountryName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="Country Name"
                    autocomplete="off"
                    
                  />
                  <input
                    type="hidden"
                    formControlName="permenantCountryId"
                    id="permenantCountryId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />
                  <!-- AutoComplete Search here -->
                  <span *ngIf="permenantautoCompleteCountrySearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="permenantcountryData?.length > 0"
                    >
                      <li
                        (click)="
                          permenantgetFilterData(
                            searchData.countryId,
                            searchData.countryName,
                            1
                          )
                        "
                        *ngFor="let searchData of permenantcountryData"
                      >
                        {{ searchData.countryName }}
                      </li>
                    </ul>
                  </span>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label">State </label>
                  <input
                    type="text"
                    (keyup)="permenantstateAutoCompleteSearch($event)"
                    formControlName="permenantStateName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="State Name"
                    autocomplete="off"
                  />
                  <input
                    type="hidden"
                    formControlName="permenantStateId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />

                  <!-- AutoComplete Search here -->
                  <span *ngIf="permenantautoCompleteStateSearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="permenantstateData?.length > 0"
                    >
                      <li
                        (click)="
                          permenantgetFilterData(
                            searchData.stateId,
                            searchData.stateName,
                            2
                          )
                        "
                        *ngFor="let searchData of permenantstateData"
                      >
                        {{ searchData.stateName }}
                      </li>
                    </ul>
                  </span>
                  <!-- AutoComplete End here -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">District </label>
                  <input
                    type="text"
                    (keyup)="permenantdistrictAutoCompleteSearch($event)"
                    formControlName="permenantDistrictName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="District Name"
                    autocomplete="off"
                  />
                  <input
                    type="hidden"
                    formControlName="permenantDistrictId"
                    id="permenantDistrictId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />

                  <!-- AutoComplete Search here -->
                  <span *ngIf="permenantautoCompleteDistrictSearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="permenantdistrictData?.length > 0"
                    >
                      <li
                        (click)="
                          permenantgetFilterData(
                            searchData.districtId,
                            searchData.districtName,
                            3
                          )
                        "
                        *ngFor="let searchData of permenantdistrictData"
                      >
                        {{ searchData.districtName }}
                      </li>
                    </ul>
                  </span>
                  <!-- AutoComplete End here -->
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">City </label>
                  <input
                    type="text"
                    (keyup)="permenantcityAutoCompleteSearch($event)"
                    formControlName="permenantCityName"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    placeholder="City Name"
                    autocomplete="off"
                  />
                  <input
                    type="hidden"
                    formControlName="permenantCityId"
                    id="cityId"
                    class="form-control email-input select-placeholder mb-3 mb-lg-0"
                    autocomplete="off"
                  />
                  <!-- AutoComplete Search here -->
                  <span *ngIf="permenantautoCompleteCitySearch == 0">
                    <ul
                      class="auto-complete-country"
                      *ngIf="permenantcityData?.length > 0"
                    >
                      <li
                        (click)="
                          permenantgetFilterData(
                            searchData.cityId,
                            searchData.cityName,
                            4
                          )
                        "
                        *ngFor="let searchData of permenantcityData"
                      >
                        {{ searchData.cityName }}
                      </li>
                    </ul>
                  </span>
                  <!-- AutoComplete End here -->
                </div>

                <!-- <div class="form-group col-md-3">
                                      <label class="col-form-label">State</label>
                                      <select formControlName="permenantStateId" (keyup)="permenantstateAutoCompleteSearch($event)" id="permenantStateId" class="form-select form-select-solid form-select-lg">
                                          <option value="null">- Select -</option>
                                          <option *ngFor="let row of perstateList; let i=index;" value="{{row.stateId}}">{{row.stateName}}</option>
                                      </select>
                                  </div>

                                  <div class="form-group col-md-3">
                                      <label class="col-form-label">District </label>
                                      <select formControlName="permenantDistrictId" (keyup)="permenantdistrictAutoCompleteSearch($event)" (keyup)="stateAutoCompleteSearch($event)" id="permenantDistrictId" class="form-select form-select-solid form-select-lg">
                                          <option value="null">- Select -</option>
                                          <option *ngFor="let row of perdistrictList; let i=index;" value="{{row.districtId}}">{{row.districtName}}</option>
                                      </select>
                                  </div>
                                  <div class="form-group col-md-3">
                                      <label class="col-form-label">City</label>
                                      <select formControlName="permenantCityId" id="permenantCityId" class="form-select form-select-solid form-select-lg">
                                          <option value="null">- Select -</option>
                                          <option *ngFor="let row of percityList; let i=index;" value="{{row.cityId}}">{{row.cityName}}</option>
                                      </select>
                                  </div> -->
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label">Postal Code </label>
                  <input
                    type="text"
                    formControlName="permenantPostalCode"
                    maxlength="6"
                    id="permenantPostalCode"
                    [ngClass]="{
                      'is-invalid':
                        address1 && address1.permenantPostalCode.errors
                    }"
                    autocomplete="off"
                    class="form-control email-input select-placeholder"
                    value="null"
                    placeholder="Postal Code"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      address1.permenantPostalCode.dirty ||
                      (address_step &&
                        addressDetails.controls.permenantPostalCode.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="address1.permenantPostalCode.errors?.required"
                      >Please enter postal code</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="address1.permenantPostalCode.errors?.pattern"
                      >Please enter valid postal code</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Address</label>
                  <textarea
                    formControlName="permenantAddress"
                    id="permenantAddress"
                    class="form-control email-input select-placeholder"
                    rows="1"
                  ></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">
                  <!-- <a (click)="employeeDetails(3);" mat-raised-button title="Cancel" class="mr-2">
                                        Back
                                    </a>
                                    <button type="submit" mat-raised-button color="primary">Next</button>
                                    -->
                  <!-- <input (click)="previous()" type="button" name="previous" class="previous action-button-previous btn btn-light btn-sm mr-2" value="Previous" />
                                    <input (click)="next()" type="button" name="next" class="next action-button btn btn-primary btn-sm" value="Save & Next" />
                                    -->
                  <!-- <input
                    (click)="employeeDetails(3)"
                    type="button"
                    name="previous"
                    class="previous action-button-previous btn btn-light btn-sm mr-2"
                    value="Back"
                  /> -->

                  <button
                    mat-stroked-button
                    type="button"
                    (click)="employeeDetails(3)"
                    style="
                      background: none;
                      color: black;
                      border: 1px solid #ddd;
                      font-size: 16px;
                      font-weight: 510;
                      margin-left: 5px;
                      height: 36px;
                    "
                    class="mx-3"
                  >
                    Back
                  </button>
                  <!-- <input
                    (click)="addressSubmit()"
                    type="submit"
                    name="submit"
                    class="submit action-button btn btn-primary btn-sm"
                    value="Save & Next"
                  /> -->

                  <button
                    (click)="addressSubmit()"
                    mat-raised-button
                    type="submit"
                    name="submit"
                    class="searchBtn"
                    [disabled]="!addressDisabled"
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <!-- Address end -->

        <!-- Bank Details start -->
        <section>
          <!-- *ngIf="tabindex == 5" -->
          <form [formGroup]="bankDetails" *ngIf="tabindex == 5">
            <!-- [formGroup]="postForm" (ngSubmit)="onSubmit()" -->
            <!-- *ngIf="tabindex == 5" -->
            <fieldset>
              <!-- *ngIf="step == 5" -->
              <h3>Add Bank Details</h3>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Bank A/c No <span class="star">*</span
                  ></label>
                  <input
                    type="text"
                    formControlName="bankAccountNo"
                    [maxlength]="16"
                    [minlength]="11"
                    id="bankAccountNo"
                    (input)="onBankAcctNoInput($event)"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"

                    [ngClass]="{
                      'is-invalid': bank && bank.bankAccountNo.errors
                    }"
                    class="form-control email-input select-placeholder"
                    placeholder="Acc No"
                    autocomplete="off"
                  />
                  <span class="small">Ex : 1234567891234567</span>

                  <div class="valid-msg" *ngIf="bank_step && bankDetails.controls.bankAccountNo.errors && setbankbool">
                  <span class="text-danger" *ngIf="bank.bankAccountNo.errors?.required">Please enter bank a/c no</span>
                  <span
                    class="text-danger"
                    *ngIf="bank.bankAccountNo.errors?.pattern"
                    >Please enter valid bank a/c no</span
                  >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Bank A/c Holder Name <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="bankAccountHolderName"
                    id="bankAccountHolderName"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    (keypress)="preventNumberInput($event)"
                    [ngClass]="{
                      'is-invalid': bank && bank.bankAccountHolderName.errors
                    }"
                    autocomplete="off"
                    class="form-control email-input select-placeholder"
                    placeholder="Bank A/c Holder Name"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      bank.bankAccountHolderName.dirty ||
                      (bank_step &&
                        bankDetails.controls.bankAccountHolderName.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="bank.bankAccountHolderName.errors?.required"
                      >Please enter bank a/c holder name</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="bank.bankAccountHolderName.errors?.pattern"
                      >Please enter valid bank a/c holder name</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >Bank Name <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="bankName"
                    id="bankName"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    (keypress)="preventNumberInput($event)"

                    [ngClass]="{ 'is-invalid': bank && bank.bankName.errors }"
                    class="form-control email-input select-placeholder"
                    autocomplete="off"
                    placeholder="Bank Name"
                  
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      bank.bankName.dirty ||
                      (bank_step && bankDetails.controls.bankName.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="bank.bankName.errors?.required"
                      >Please enter bank name</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="bank.bankName.errors?.pattern"
                      >Please enter valid bank name</span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="col-form-label">Bank Branch</label>
                  <input
                    type="text"
                    formControlName="bankBranchName"
                    id="bankBranchName"
                    class="form-control email-input select-placeholder only_name"
                    placeholder="Branch Name"
                    autocomplete="off"
                  />
                  <div
                    class="valid-msg"
                    *ngIf="
                      bank.bankBranchName.dirty ||
                      (bank_step && bankDetails.controls.bankBranchName.errors)
                    "
                  >
                    <!-- <span class="text-danger" *ngIf="bank.bankBranchName.errors?.required">Please enter bank name</span> -->
                    <span
                      class="text-danger"
                      *ngIf="bank.bankBranchName.errors?.pattern"
                      >Please enter valid bank branch name</span
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label class="col-form-label"
                    >IFSC Code <span class="star">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="ifscCode"
                    [maxlength]="11"
                    (input)="toUpperCase($event)"
                    [minlength]="11"
                    (paste)="preventCopyPaste($event)"
                    (copy)="preventCopyPaste($event)"
                    id="ifscCode"
                    [ngClass]="{ 'is-invalid': bank && bank.ifscCode.errors }"
                    autocomplete="off"
                    class="form-control email-input select-placeholder"
                    placeholder="IFSC Code"
                  />
                  <span class="small">Ex : IDIB0001214</span>
                  <div
                    class="valid-msg"
                    *ngIf="
                      bank.ifscCode.dirty ||
                      (bank_step && bankDetails.controls.ifscCode.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="bank.ifscCode.errors?.required"
                      >Please enter IFSC Code</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="bank.ifscCode.errors?.pattern"
                      >Please enter valid IFSC Code</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">MICR Code</label>
                  <input
                    type="text"
                    formControlName="micrCode"
                    maxlength="9"
                    (keypress)="noNumber($event)"
                    (paste)="blockPaste($event)"
                    (drop)="blockDrop($event)"
                    id="micrCode"
                    [ngClass]="{ 'is-invalid': bank && bank.micrCode.errors }"
                    class="form-control email-input select-placeholder"
                    autocomplete="off"
                    placeholder="MICR Code"
                  />
                  <span class="small">Ex : 600019003</span>
                  <div
                    class="valid-msg"
                    *ngIf="
                      bank.micrCode.dirty ||
                      (bank_step && bankDetails.controls.micrCode.errors)
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="bank.micrCode.errors?.required"
                      >Please enter MICR Code</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="bank.micrCode.errors?.pattern"
                      >Please enter valid MICR Code</span
                    >
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label class="col-form-label">Address</label>
                  <input
                    type="text"
                    formControlName="bankAddress"
                    id="bankAddress"
                    class="form-control email-input select-placeholder only_name"
                    placeholder="Address"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">
                  <!-- <a (click)="employeeDetails(4);" mat-raised-button title="Cancel" class="mr-2">
                                        Back
                                    </a>
                                    <button type="submit" mat-raised-button color="primary">Next</button>
                                    -->
                  <!-- <input (click)="previous()" type="button" name="previous" class="previous action-button-previous btn btn-light btn-sm mr-2" value="Previous" />

                                    <input *ngIf="!employeeId" (click)="next()" type="button" name="next" class="next action-button btn btn-primary btn-sm mr-2" value="Save & Next" />
                                    <input *ngIf="employeeId" (click)="submit()" type="submit" name="submit" class="submit action-button btn btn-primary btn-sm" value="Update" />
                                    -->

                  <!-- <input
                    (click)="employeeDetails(4)"
                    type="button"
                    name="previous"
                    class="previous action-button-previous btn btn-light btn-sm mr-2"
                    value="Back"
                  /> -->

                  <button
                    mat-raised-button
                    type="button"
                    class="mx-3 mt-2"
                    (click)="employeeDetails(1)"
                    style="
                      background: none;
                      color: black;
                      border: 1px solid #ddd;
                      font-size: 16px;
                      font-weight: 610;
                      margin-left: 5px;
                      height: 36px;
                    "
                  >
                    Back
                  </button>
                  <!-- <input
                    *ngIf="!employeeId"
                    (click)="bankSubmit()"
                    type="submit"
                    name="submit"
                    class="searchBtn"
                    value="Save & Next"
                  /> -->
                  <button style="    position: relative;
    top: 0px;"
                    *ngIf="employeeId"
                    mat-raised-button
                    (click)="bankSubmit()"
                    type="submit"
                    name="submit"
                    class="searchBtn"
                    
                    [disabled]="!addBankDetails"
                  >Save
                  </button>

                  <!-- <button
                  *ngIf="!employeeId"
                  (click)="bankSubmit()"
                  mat-raised-button
                  type="submit"
                  name="submit"
                  [disabled]="!addBankDetails"
                  class="searchBtn"
                 
                >
                  Save & Next
                </button> -->
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <!-- Bank Details end -->

        <!-- Login Details start -->
        <!-- <section>
                   *ngIf="tabindex == 6"
                    <form [formGroup]="loginDetails" id="msform-1">
                        <fieldset *ngIf="tabindex == 6">
                            *ngIf="step == 6"
                            <h3>Login Details</h3>
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Password <span class="star">*</span></label>
                                    <input type="password" formControlName="password" id="password" [ngClass]="{ 'is-invalid': login && login.password.errors }" class="form-select form-select-solid form-select-lg only_name" placeholder="Password" autocomplete="off">
                                    <div class="valid-msg" *ngIf="login_step && loginDetails.controls.password.errors">
                                        <span class="text-danger" *ngIf="login.password.errors?.required">Please enter password</span>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <a (click)="employeeDetails(5);" mat-raised-button title="Cancel" class="mr-2">
                                        Back
                                    </a>
                                    <button type="submit" mat-raised-button color="primary">Submit</button>

                                    <input (click)="previous()" type="button" name="previous" class="previous action-button-previous btn btn-light btn-sm" value="Previous" />
                                    <input (click)="submit()" type="submit" name="submit" class="submit action-button btn btn-primary btn-sm" value="Submit" />


                                    <input (click)="employeeDetails(5);" type="button" name="previous" class="previous action-button-previous btn btn-light btn-sm mr-2" value="Back" />
                                    <input (click)="loginSubmit()" type="submit" name="submit" class="submit action-button btn btn-primary btn-sm" value="Submit" />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </section>
                 Login Details end -->
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>
 -->

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>

<div class="text-center">
  <div
    *ngIf="success"
    class="alert alert-resource alert-success toast-msg"
    role="alert"
  >
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div class="text-center">
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>
</div>
