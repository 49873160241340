<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Clients</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <button *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button color="primary" class="btn download-button">
                      Export
                    </button>

                    <a (click)="Add_client()" color="primary" mat-raised-button title="Add Client" class="ml-2">
                      Add Client
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-8">
                        <!-- <div class="row mb-3 pl-0">
                            <div class="col-md-4">
                                <input type="search" [(ngModel)]="query" (keyup)="onKeyUpEvent($event)" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" name="keywords" value="" placeholder="Search...">
                            </div>
                        </div> -->
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row mb-3 pl-0">
                                <div class="col-md-4">
                                    <input formControlName="keywords" type="text" autocomplete="off" class="form-control email-input select-placeholder" value="" placeholder="Search..."  (input)="validateInput($event)">
                                    <small class="text-muted">Note : Client Name , Client Code</small>
                                </div>
                                <div class="ol-xl-1 col-lg-1 mb-2">
                                    <button type="submit" mat-raised-button  [disabled]="!searchClient" class=" searchBtn">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-4">
                        <div class="row"  *ngIf="resultData?.length > 5 &&(resultData | mytask : search)?.length > 0" style="margin-top: 40px;float: right;">
                            <div class="col-xl-12 col-lg-12" style="margin-top: -36px"
                            *ngIf="(resultData | mytask : search)?.length > 5">
                            <div class="select-pagination">
                                <span>Show : </span>
                                <select [(ngModel)]="pagePerItem" 
                                class="select_list new-select_list listDesign"
                                (change)="changefirstpage()">
                                    <option class="form-control" value="5">5</option>
                                    <option class="form-control" value="10">10</option>
                                    <option class="form-control" value="25">25</option>
                                    <option class="form-control" value="50">50</option>
                                    <option class="form-control" value="100">100</option>
                                    <option class="form-control" value="500">500</option>
                                  <!-- <option value="1500">1500</option> -->
                              </select>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <table class="table -table-responsive --table-bordered table-border-new"> -->
                <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <table matSort (matSortChange)="sortData($event)" *ngIf="showtable"
                     style="width: 100%;" class="table table-responsive1 table-bordered table-border-new"
                     style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <th class="text-center">Action</th>
                                <th mat-sort-header="clientName">Client&nbsp;Name</th>
                                <th mat-sort-header="clientCode">Client&nbsp;Code</th>
                                <!-- <th mat-sort-header="pfNumber">PF&nbsp;Number</th>
                                <th mat-sort-header="esiNumbber">ESI&nbsp;Number</th> -->
                                <!-- <th mat-sort-header="workindays">Working&nbsp;Days</th>
                                <th mat-sort-header="wageperday">Wage&nbsp;Per Day</th>
                                <th mat-sort-header="wagepermonth">Wage&nbsp;Per Month</th>
                                <th mat-sort-header="otrate">OT&nbsp;Rate / hour</th>
                                <th mat-sort-header="otpercentage">OT&nbsp;Rate Percentage</th>
                                <th mat-sort-header="Leave enachment">Leave Encashment&nbsp;Days</th>  -->
                                <th mat-sort-header="address">Address</th>
                                <!-- <th mat-sort-header="location">Location</th> -->
                                <th mat-sort-header="clientDescription">Client Description</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData?.length > 0">
                            <tr *ngFor="let e of resultData | mytask: search | paginate: { itemsPerPage: pagePerItem, currentPage: p } ">
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="updateform(e)">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>

                                        <button mat-menu-item *ngIf="e.status.listTypeValueId == 1" (click)="statuschangeactive(e.clientId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span style="color:red">Inactive</span>
                                        </button>

                                        <button mat-menu-item *ngIf="e.status.listTypeValueId ==2" (click)="statuschangeinactive(e.clientId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span style="color:green;">Active</span>
                                        </button>

                                    </mat-menu>
                                </td>
                                <td class="text-left">{{ucFirst(e.clientName)}}</td>
                                <td class="text-left">{{e.clientCode}}</td>
                                <!-- <td>{{e.pfNumber}}</td>
                                <td>{{e.esiNumber}}</td> -->
                                <!-- <td class="text-center">{{e.workingDays}}</td>
                                <td class="text-center" >{{e.ratePerDay}}</td>
                                <td class="text-center" >{{e.ratePerMonth}}</td>
                                <td *ngIf= "e.otWagesPerHour == 0.0" class="text-center" >-</td>
                                <td *ngIf= "e.otWagesPerHour > 0"class="text-center" >{{e.otWagesPerHour}}</td>
                                <td class="text-center" >{{e.otWagesPercentage  ? e.otWagesPercentage :"-" }}</td>
                                <td class="text-center" >{{e.leaveEncashmentDays }}</td> -->
                                <td class="text-left" >{{e.addressLine1}} {{ e.addressLine2}}</td>
                                <!-- <td *ngIf="e.location != undefined">{{ucFirst(e.location)}}</td> -->
                                <td class="text-left" *ngIf="e.clientDescription != undefined">{{e.clientDescription ? ucFirst(e.clientDescription) : ""}}</td>
                                <td *ngIf="e.clientDescription == undefined">--</td>  
                                <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="e.status.listTypeValueId  == 1">
                                        Active
                                    </span>

                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="e.status.listTypeValueId  == 2">
                                        In-Active
                                    </span>
                                </td>
                                <!-- <td class="table-data text-center">
                                <span><mat-icon (click)="updateform(e)" style="position: relative;top: 4px;"
                                aria-hidden="false" aria-label="Example edit icon" class="material-icons-round edit-icon">edit</mat-icon></span>
                            </td> -->
                            </tr>
                        </tbody>
                        <tbody  *ngIf="nodatafound">
                            <tr>
                                <td style="    text-align: center;
                                font-size: larger;
                                font-weight: bold;" colspan="15" >Clients Records Not Found!</td>
                            </tr> 
                        </tbody> 
                    </table>
                </div>

                

                <div *ngIf="resultData?.length > 5 ">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <!-- <div class="col-md-8 text-right" style="float: right;">
                            <pagination-controls (pageChange)="p = $event" class="float-right"></pagination-controls>
                        </div> -->
                        <div class="pagination-content" *ngIf="
                        resultData?.length > 5 &&
                        (resultData | mytask : search).length > 0
                      " id="page">
                      <pagination-controls *ngIf="(resultData | mytask : search).length > 5"
                        style="position: relative; float: inline-end; top: 7px" (pageChange)="p = $event" align="end">
                      </pagination-controls>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 100vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" style="height:3rem;width:3rem; top:55%;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
<!-- <div class="row topnav-right pagination-content">
    <div class="col-md-12">
        <pagination-controls class="float-right pagination" (pageChange)="p = $event; beforepage = $event"></pagination-controls>
    </div>
</div> -->