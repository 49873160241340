import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { catchError, EMPTY, Subject, switchMap, takeUntil } from 'rxjs';
import { ImportEmployeerevenueadjustmentComponent } from 'src/app/import-employeerevenueadjustment/import-employeerevenueadjustment.component';
import { ShowErrorComponent } from 'src/app/project-management/project-management/show-error/show-error.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';

@Component({
  selector: 'app-emp-revenue-adjustment-list',
  templateUrl: './emp-revenue-adjustment-list.component.html',
  styleUrls: ['./emp-revenue-adjustment-list.component.scss']
})
export class EmpRevenueAdjustmentListComponent implements OnInit {
  isProcessing = false;
  showtable:boolean=false;

  filterForm:FormGroup;
  finYearList: any =[];
  categoryNameList: any =[];
  finPeriodList: any=[];
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  pagePerItem:any=5;
  p: number = 1;
  tableSearch : any;
  chooseFinId: any;
  list_loader = true;
  employeetData: any;
  categoryTypeData: any;
  categoryId: any;
  categoryName: any;
  usertData: any;
  dateFormat: string;
  orgId: any;
  clientId:any;
  success: boolean;
  importerror: boolean;
  errormessagejson: any;
  sucess_msg: any;
  error: boolean;
  employeeAll: boolean;
  empList: any[];
  empCode: boolean = false;
  userId: any;
  employeelistsub: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  employeeId: any;
  empnumber: any;
  firstname: any;
  error_msg: any;
  employee: any;
  selectedemployeeId: any;
  selectedemployee: any;
  inputValue: any;
  
  
  constructor(  private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private formBuilder: FormBuilder,private dialog  : MatDialog) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;

    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);
   
    
    this.noRecordsFound = true;

if(this.orgId){
    this.settingsService.getFinicalyearList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1){
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList);
        }
      });
    });
    }

    if(this.clientId){
      this.settingsService.getClientFinicalyearList(this.clientId).subscribe((resultData: any) => {
        console.log(resultData);
        resultData.forEach((item: any) => {
          // console.log(item.financialYearStatus.listTypeValueId)
          // console.log(item.financialYearStatus.listTypeValueId == 1)
          if(item.financialYearStatus.listTypeValueId == 1){
            console.log(item.financialYearStatus.listTypeValueId)
            this.finYearList.push(item);
            console.log(' this.finYearList', this.finYearList);
          }
        });
      });
      }

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

      let organizationId = this.employeetData.organization.organizationId;

    // this.settingsService.getCategoryNameList(organizationId).subscribe((resultData: any) => {
    //   console.log(resultData);
    //   this.categoryNameList = resultData;
    // });

    this.payrollservice.getEarningsCategoryList().subscribe((result: any) => {
      // this.filterForm.get('category').patchValue(result[0].listTypeValueId);
      this.categoryTypeData = result;
      this.categoryId=this.categoryTypeData[0].listTypeValueId;
      this.categoryName=this.categoryTypeData[0].listTypeValueName;
      console.log('result',this.categoryId);
      
      if(this.orgId){
      this.payrollservice.getElementListValues(this.categoryId, this.orgId).subscribe((d: any) => {
        console.log('element', d)
        this.categoryNameList = d;
      })
    }
    if(this.clientId){
      this.payrollservice.getClientElementListValues(this.categoryId, this.clientId).subscribe((d: any) => {
        console.log('element', d)
        this.categoryNameList = d;
      })
    }
    })

    this.filterForm = this.formBuilder.group({
      employeecode:[null],
      financialyear: [null],
      period: [null],
      element: [null],
      // employee: [null, []],
      // remarks: [null, []],
      // Rows: this.formBuilder.array([
      // ]),
    });


  }


  // empSearch(event)
  // { 

 
  //   this.empList=[];
  //   this.empCode=false;
  //   console.log(event.target.value);
  //   this.userId=event.target.value;
  //   console.log(this.userId);
  //   // Employee List Api
  //     // this.empList.length == 0;
  //     if(event.target.value.length >= 2)
  //     {
  //       if(this.employeelistsub)
  //       {
  //         this.employeelistsub.unsubscribe();
  //       }
  //       this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
  //         .pipe(
  //           takeUntil(this.unsubscribe$), // Cancel previous request
  //           switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
  //         )
  //         .subscribe((result: any) => {
  //           this.empList.length = 0;
  
  //           if (result.statusCode) {
  //             console.log(result.statusCode);
  //             this.empList.length = 0;
  //           } else {
  //             this.empList = result;
  //           }
  //         });
  //   }
  // }


  empSearch(event){
    console.log(event.target.value)
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement) // Cast the event target as an input element
  this. inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
   console.log(this.inputValue)
    // Clear the employee list and reset flags if input is empty
    if (this.inputValue === '') {
      this.empList = [];
      this.empCode = false;
      console.log('Input is empty, employee list cleared');
      return;
    }
  
    // Check if input length is sufficient for the search
    if (this.inputValue.length >= 2) {
      // Unsubscribe from the previous API request if necessary
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
  
      // Reset and create a new Subject for cancellation
      this.unsubscribe$ = new Subject<void>();



      if(this.orgId){
      this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
                          // this.tableShow = true;

            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }

      if(this.clientId){
        this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true; // Set the flag to indicate an error state
              }
              console.error('An error occurred:', err);
              return EMPTY; // Return EMPTY to propagate completion downstream
            })
          )
          .subscribe(
            (result: any) => {
              if (result.statusCode === 204) {
                console.log('No employees found (status code 204)');
                this.empList = [];
                this.empCode = true; // Indicate no results found
              } else if (result.statusCode) {
                console.log('Other status code:', result.statusCode);
                this.empList = [];
                this.empCode = true;
              } else {
                // Handle the case where result is an array or an object
                this.empList = Array.isArray(result) ? result : Object.values(result);
                this.empCode = false;
                            // this.tableShow = true;
  
              }
            },
            (err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true;
              }
              console.error('An error occurred:', err);
            }
          );
        } 
    }
  }
   
  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log('empSelect', emp);
    this.employeeAll = false;
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    // this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;
    this.employee=this.empnumber +"-" + this.firstname    // if (this.employeeLeaveId) {
      this.filterForm.patchValue({
        employeecode: this.empnumber +"-" + this.firstname
      });
   
  
  }
  

  
  empAllSelect() {
    this.employeeAll = true;
  }

  pageChages(event)
  {
    console.log('event.target.value', event.target.value);
    if(event.target.value != null || event.target.value != 0){
      // this.p = event.target.value;
      console.log("page changes");
      this.p=1;
    }
    
  }
  selectFinancialyear()
  {
    console.log("Select");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    //  this.chooseFinId=event.target.value;
    this.chooseFinId=this.filterForm.controls['financialyear'].value;
    // console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else{
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        period: null,
      });
    });
  }
  }
  clearTable()
  {
    this.empList = [];
    this.resultData = [];
    console.log('clear')
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
    this.showtable=false;
    this.empCode = false; 


  }
  searchSubmit()
  {
   this.empList=[];
    if((this.filterForm.value.employeecode !== undefined && this.filterForm.value.employeecode !== null && this.filterForm.value.employeecode !== '') ){
      if((this.employee != this.filterForm.value.employeecode )|| (this.employeeId == null)){
        window.alert('please select valid employee from dropdown.');
        return;
      }
    }
    this.showtable=true;
    this.loader=true;
    this.isProcessing=true;

    console.log("Filter",this.filterForm.value);
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode ===null)
    &&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')
    &&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null')
    &&(this.filterForm.value.element===null|| this.filterForm.value.element ==='null'))
    {
       console.log("required Fields");
       this.resultData =[];
       this.noRecordsFound = false;
       alert("Please select Any Field to Search");
      //  return;
      this.isProcessing=false;
      this.showtable=false;
      this.loader=false;
      // this.getallrevenueadjustment();
    }
    console.log("emp code",this.filterForm.value.employeecode);
    console.log("year",this.filterForm.value.financialyear);
    console.log("period",this.filterForm.value.period);
    console.log("element",this.filterForm.value.element);

    if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear=== null || this.filterForm.value.financialyear === 'null')
    &&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')
    &&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
    { 
      console.log("emp num"); 

  if(this.orgId){
      this.payrollservice.searchRevenueAdjustmentByEmployeeNumberOrg(this.empnumber,this.orgId).subscribe((resultData1: any) => {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
      if(this.resultData.statusCode == 204)
      {
        this.noRecordsFound = true;
        console.log("nodata");
      }
      this.isProcessing=false;
      this.loader=false;
    },
    (error) => { 
      
      this.list_loader = false;
      this.noRecordsFound = true;
      console.log("error")
      if(error.status == 404){
        this.noRecordsFound = true;
        console.log("404")
      }
    }
    
    )
    }
    if(this.clientId){
      this.payrollservice.searchRevenueAdjustmentByEmployeeNumberClient(this.empnumber,this.clientId).subscribe((resultData1: any) => {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
      if(this.resultData.statusCode == 204)
      {
        this.noRecordsFound = true;
        console.log("nodata");
      }
      this.isProcessing=false;
      this.loader=false;
    },
    (error) => { 
      
      this.list_loader = false;
      this.noRecordsFound = true;
      console.log("error")
      if(error.status == 404){
        this.noRecordsFound = true;
        console.log("404")
      }
    }
    
    )
    }
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')
    &&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
    { 
      console.log("year only");
      // this.noRecordsFound = false;
    this.payrollservice.listRevenueAdjustmentByFinancialYear(this.filterForm.value.financialyear).subscribe((resultData1: any) => {
      this.showtable=true;

      this.noRecordsFound = false;
      this.resultData = resultData1;
      console.log("this.resultData",this.resultData);
      if(this.resultData.statusCode == 204)
      {
        this.noRecordsFound = true;
        console.log("nodata");
      }
      this.isProcessing=false;
      this.loader=false;
    },
    (error) => {
      this.list_loader = false;
      this.noRecordsFound = true;
      console.log("error")
      if(error.status == 404){
        this.noRecordsFound = true;
        console.log("404")
      }
    }
    )
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear=== null || this.filterForm.value.financialyear === 'null')
    &&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    {
      console.log("element");
      // this.noRecordsFound = false;
      this.payrollservice.searchRevenueAdjustmentByElementCategoryId(this.filterForm.value.element).subscribe((resultData1: any) => {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
      if(this.resultData.statusCode == 204)
      {
        this.noRecordsFound = true;
        console.log("nodata");
      }
      this.isProcessing=false;
      this.loader=false;
    },
    (error) => {
      this.list_loader = false;
      this.noRecordsFound = true;
      console.log("error")
      if(error.status == 404){
        this.noRecordsFound = true;
        console.log("404")
      }
    }
    )
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
    { 
      console.log("year and period");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByFinancialYearAndFinancialPeriod(this.filterForm.value.financialyear, this.filterForm.value.period).subscribe((resultData1: any) => {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }
    else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("all");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndElementCategoryAndFinancialYearAndFinancialPeriod(this.empnumber,this.filterForm.value.element, this.filterForm.value.financialyear, this.filterForm.value.period).subscribe((resultData1: any) => {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }  else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("period");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByFinancialPeriod(this.filterForm.value.period).subscribe((resultData1: any) => {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("emp code and fin year");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndFinancialYear(this.empnumber, this.filterForm.value.financialyear).subscribe((resultData1: any) => {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("emp code and fin year and period");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndFinancialYearAndFinancialPeriod(this.empnumber, this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("period and element");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByFinancialPeriodAndElement(this.filterForm.value.period,this.filterForm.value.element).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("fin year and period and element");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByFinancialYearAndFinancialPeriodAndElement(this.filterForm.value.financialyear,this.filterForm.value.period, this.filterForm.value.element).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("fin year and element");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByFinancialYearAndElement(this.filterForm.value.financialyear,this.filterForm.value.element).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log(" element based");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByElement(this.filterForm.value.element).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("emp num and element");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndElement(this.filterForm.value.employeecode,this.filterForm.value.element, ).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("period andemp num and element");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByPeriodAndEmployeeCodeAndElement(this.filterForm.value.period,this.filterForm.value.employeecode,this.filterForm.value.element, ).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
    &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
    &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
    { 
      console.log("year andemp num and element");
      // this.noRecordsFound = false;
      this.payrollservice.listRevenueAdjustmentByYearAndEmployeeCodeAndElement(this.filterForm.value.financialyear,this.filterForm.value.employeecode,this.filterForm.value.element, ).subscribe((resultData1: any) => {
        this.noRecordsFound = false;
        this.showtable=true;

        this.resultData = resultData1;
        console.log("this.resultData",this.resultData);
        if(this.resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        this.isProcessing=false;
        this.loader=false;
      },
      (error) => {
        this.list_loader = false;
        this.noRecordsFound = true;
        console.log("error")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
      )
    }
  }


  getallrevenueadjustment()
  {
    this.loader=true;

    this.payrollservice.listRevenueAdjustmentByorganziation(this.orgId).subscribe((resultData1: any) => {
      this.noRecordsFound = false;
      this.resultData = resultData1;
      console.log("this.resultData",this.resultData);
      if(this.resultData.statusCode == 204)
      {
        this.noRecordsFound = true;
        console.log("nodata");
      }
      this.loader=false;

    },
    (error) => {
      this.list_loader = false;
      this.loader = false;

      this.noRecordsFound = true;
      console.log("error")
      if(error.status == 404){
        this.noRecordsFound = true;
        console.log("404")
      }
    }
    )
  }
  
  importrevenueadjustment()
  {
    console.log("import Employee");
    const dialogRef = this.dialog.open(ImportEmployeerevenueadjustmentComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });
  
    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else 
      {
        if (result?.length != 0 ) {
          this.error = true;
          // this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, responseMsgTimeOut)
        }
          else
          {
          if(result.statusCode > 400)
          {
            this.error = true;
            this.importerror = false;
          }
          }
  
      }
    });
  }

  showerrormessage() {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '600px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }

  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 

  }
}
