<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Assign Client Supplier</span>
        </div>
        <div class="sub-header-buttons">
          <div class="topnav-right">
            <button
              (click)="Add_approver()"
              mat-button
              class="mat-focus-indicator ml-2 mat-raised-button mat-button-base"
              style="background: #1a83ff; color: #fff"
            >
              Add Assign Client Supplier
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="common-content" style="margin-top: 5px !important">
  <div class="card-new" *ngIf="showtable">
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="showupdate == true">
          <div class="container">
            <form
              [formGroup]="supplierClientForm"
              (ngSubmit)="submit()"
              *ngIf="showForm"
            >
              <div class="row mx-2" style="margin-bottom: 22px">
                <!-- Task Type -->
                <!-- <div class="row"> -->
                <div class="col-md-3 mx-2">
                  <div class="input-width" id="loginform">
                    <label class="form-control-label"
                      >Client <span class="text-danger"> *</span></label
                    >
                    <input
                      formControlName="clientNames"
                      class="form-control email-input select-placeholder"
                      placeholder="Select employee"
                      [disabled]="empolyeereadonly"
                      [readonly]="readonly"
                      style="background: #817d7d26 !important"
                      (keyup)="empSearch($event)"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <!-- <mat-option value="All" (click)="empAllSelect()"  [disabled]="empolyeereadonly || disableFeildforAll">All</mat-option> -->
                      <mat-option
                        *ngFor="let client of clientList"
                        [value]="client.clientId"
                        (click)="empSelect(client)"
                      >
                        {{ client.clientCode }}- {{ client.clientName }}
                      </mat-option>
                      <mat-option
                        [value]="null"
                        *ngIf="employeeNotFound"
                        (click)="noEmployeeFound()"
                        >No Client Found
                      </mat-option>
                    </mat-autocomplete>
                    <!-- <mat-error *ngIf="employee.invalid && submitted1">Employee is required</mat-error><br> -->
                    <span *ngIf="empolyeeapproverexist" class="span-error">
                      Client Already Exist</span
                    >
                  </div>
                </div>

                <div class="col-md-3 mx-2">
                  <div class="input-width" id="loginform">
                    <label class="form-control-label"
                      >Supplier <span class="text-danger"> *</span></label
                    >
                    <mat-select
                      formControlName="supplier"
                      placeholder="Select Supplier"
                      class="form-control email-input select-placeholder"
                    >
                      <mat-option
                        *ngFor="let supplier of supplierList"
                        [value]="supplier.supplierId"
                      >
                        {{ supplier.supplierName }}
                      </mat-option>
                      <!-- <mat-option value="supplier 1">supplier 1</mat-option> -->
                    </mat-select>
                    <mat-error *ngIf="f.supplier.invalid && submitted1"
                      >Supplier Type is required</mat-error
                    >
                  </div>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 mx-2">
                  <div
                    class="row buttons mx-2 rowDesign"
                    style="position: relative; top: 28px"
                  >
                    <div class="col-md-4 col-lg-6 col-xl-6">
                      <button
                        (click)="clearForm()"
                        mat-raised-button
                        [disabled]="btnDisable"
                        class="mr-2 btnCancel"
                        style="height: 35px; position: relative; left: 16px"
                      >
                        Clear
                      </button>
                    </div>
                    <div class="col-md-4 col-lg-6 col-xl-6">
                      <button
                        type="submit"
                        class="search-btn mat-raised-button"
                        style="height: 35px"
                        [disabled]="btnDisable"
                        mat-raised-button
                        [ngStyle]="{
                          position: 'relative',
                          left: '38px',
                          height: '35px',
                          top: '0px',
                          color: btnDisable ? 'black' : 'white',
                          'background-color': btnDisable
                            ? '#0000001f'
                            : '#1a83ff'
                        }"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="supplierclientlist?.length > 0">
          <div
            class="col-xl-12 col-lg-12"
            *ngIf="supplierclientlist?.length > 5"
          >
            <div class="select-pagination">
              <span>Show : </span>
              <select
                class="select_list new-select_list"
                [(ngModel)]="mySelect"
                (change)="changefirstpage()"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>

        <div class="table">
          <!-- <div > -->
          <table class="table" matSort>
            <thead class="t-head">
              <tr>
                <th
                  class="text-center table-data"
                  style="border-radius: 15px 1px 0px 0"
                >
                  Sl.No
                </th>
                <th class="text-center table-data">Client&nbsp;Code</th>
                <th class="text-center table-data">Client&nbsp;Name</th>
                <th class="text-center table-data">Suuplier Code</th>
                <th class="text-center table-data">Supplier Name</th>
                <th
                  class="text-center table-data"
                  style="border-radius: 0px 15px 0px 0px"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let client of supplierclientlist
                    | mytask : search
                    | paginate : { itemsPerPage: mySelect, currentPage: p };
                  let i = index
                "
              >
                <td class="text-center table-data" *ngIf="p">
                  {{ (p - 1) * mySelect + (i + 1) }}
                </td>
                <td class="text-center table-data" *ngIf="!p">{{ i + 1 }}</td>
                <td class="text-center table-data">
                  {{ client.client?.clientCode }}
                </td>
                <td class="text-center table-data">
                  {{ client.client?.clientName }}
                </td>
                <td class="text-center table-data">
                  {{ client.supplier?.supplierCode }}
                </td>
                <td class="text-center table-data">
                  {{ client.supplier?.supplierName }}
                </td>

                <td class="text-center table-data">
                  <mat-icon
                    class="edit-icon"
                    (click)="updateassignClientSupplier(client)"
                    >edit</mat-icon
                  >
                </td>
              </tr>
            </tbody>
            <!-- <tbody>
                              <tr>
                                  <td style="text-align: center;" colspan="10" *ngIf="nodata">No Records Found!</td>
                              </tr>
                          </tbody> -->
            <tbody
              style="border-left: 1px solid #ddd; border-right: 1px solid #ddd"
            >
              <tr>
                <td
                  style="
                    text-align: center;
                    padding: 10px;
                    font-size: 19px;
                    font-weight: 600;
                    font-family: 'Mulish', sans-serif;
                    left: 5;
                    position: relative;
                  "
                  colspan="10"
                  *ngIf="nodata||((supplierclientlist | mytask : search)?.length === 0 && search) ||supplierclientlist?.length == 0"
                >
                   Client Spplier Records Not Found!
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>  -->
        </div>

        <!-- <div class="row topnav-right pagination-content" *ngIf="supplierclientlist?.length > 5">        
                  <div class=" text-right">
                      <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                  </div>
              </div>  -->
        <div
          class="pagination-content"
          *ngIf="
            supplierclientlist?.length > 5 &&
            (supplierclientlist | mytask : search).length > 0 &&
            showtable
          "
          id="page"
        >
          <pagination-controls
            *ngIf="(supplierclientlist | mytask : search).length > 5"
            style="position: relative; float: inline-end; top: 7px"
            (pageChange)="p = $event"
            align="end"
          >
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="list_loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="
      width: 3rem;
      height: 3rem;
      color: #009ef7;
      position: absolute;
      top: 55%;
    "
    role="status"
  ></div>
</div>

<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div> -->
<div class="text-center" *ngIf="success" >
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <!-- <button  class="btn btn-danger">View Error Records</button> -->
</div>