<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Consolidated Reports</span>
                </div>
                <!-- <div class="sub-header-buttons">
                    <button  class="but mb-2" type="button">
                        <mat-icon class="material-symbols-outlined" (click)="downloadAsExcel();">file_download</mat-icon>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Search Employee</label>
                                <input formControlName="employeecode" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid"  style="outline: none; " value="" placeholder="Search...">
                                    <small class="text-muted">Note : Employee Number</small>
                                 <!-- <div *ngIf="this.req">
                                     <span style="color:red">*Employee Number required</span>
                                 </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Financial Year</label>
                                <mat-select formControlName="financialyear" class="form control
                                select-placeholder
                                email-input" placeholder=" -- Select Financial Year ---" style="outline: none; border-radius: 5px;font-family: Mulish; " (selectionChange)="selectFinancialyear()" >
                                    <mat-option [value]=null> -- Select Financial Year --- </mat-option>
                                    <mat-option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</mat-option> 
                                </mat-select>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Period</label>
                                <mat-select formControlName="period" class="form control
                                select-placeholder
                                email-input" placeholder="-- Select Period --- "  style="outline: none; border-radius: 5px;font-family: Mulish; " (selectionChange)="selectperiod()">
                                    <mat-option [value]=null> -- Select Period --- </mat-option>
                                    <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="col-md-3 margin-button">
                                <!-- <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button> -->
                                    <button type="submit" mat-raised-button
                                    class="searchBtn mat-raised-button mat-button-base mr-2 custom-button" [disabled]="isProcessing"
                                    >
                                    Search
                                </button>
                                    <!-- <button style="margin-left: 10px;" type="reset" mat-raised-button
                                    class="btn mr-2 search-btn" (click)="clearTable()"
                                    >Clear</button> -->
                                    <button mat-stroked-button type="reset" class="apply-buttons" (click)="clearTable()" [disabled]="isProcessing"
                                  
                                    style="margin: 8px 0px 0px 5px;background:white;border: 1px solid #ddd;position: relative ;
                                    top: -8%;  font-family: Mulish; color: black; font-size: 16px; font-weight: 610;">Clear</button>
                           
                            </div>
                        </div>
                    </form>

                    <div class="row">
                        <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                            <button  class="but mb-2" type="button" style="color: white;"   (click)="downloadAsExcel();">
                                <mat-icon class="material-symbols-outlined">file_download</mat-icon> Download Excel
                            </button>
                          
                        </div>
                        <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                            <button  class="but ml-2 mb-2" type="button"  style="color: white;"  (click)="printPdf();" >
                                <mat-icon class="material-symbols-outlined" >print</mat-icon> Download PDF
                            </button>
                        </div>
                    </div>
                    <!-- <div class="row pb-3">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left pl-0">
                                <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                    </div> -->
                       
                       <!-- <div class="table-responsive">
                    
                        <table class="table  --table-bordered table-border-new">
                            <thead class="t-head">
                                <tr>
                                    <th>Emp Code</th>
                                    <th>Emp Name</th>
                                    <th>Account Number</th>
                                    <th>IFSC Code</th>
                                    <th>Bank Name</th>
                                    <th>Branch Name</th>
                               
                                    <th>Period</th>
                                    <th>Amount Before Deduction  ( {{organizationSettings.country.currencyCode}} )</th>
                                    <th>Deduction  ( {{organizationSettings.country.currencyCode}} ) </th>
                                    <th>Net Pay ( {{organizationSettings.country.currencyCode}} ) </th>
                                   
                                    
                                </tr>
                            </thead>
                            <tbody *ngIf="resultData.length>0">
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                    <td>{{row.employee.employeeNumber}}</td>
                                    <td>{{row.employee.firstName}}</td>
                                    <td >{{row.employee.employeeInfo.bankAccountNo}}</td>
                                    <td >{{row.employee.employeeInfo.ifscCode}}</td>
                                    <td >{{row.employee.employeeInfo.bankName}}</td>
                                    <td>{{row.employee.employeeInfo.bankBranchName}}</td>
                                    <td>{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                    <td>{{row.totalAmountbeforededuction?.toFixed(2) }} </td>
                                    <td>{{row.totalDeduction?.toFixed(2) }} </td>
                                    <td>{{Roundof(row.totalAmountbeforededuction - row.totalDeduction )}} </td>
                                </tr>
                                <tr>
                                    <td colspan="7" class="text-right">
                                        <b>Total :</b>
                                    </td>
                                    <td  >
                                       {{this.totalamountbeforededuction?.toFixed(2)}}
                                    </td>
                                    <td >
                                        {{this.totalDedution?.toFixed(2)}}
                                     </td>
                                     <td>
                                        {{Roundof(this.totalnetpay)}}
                                     </td>
                        
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngIf="noRecordsFound">
                                    <td style="text-align: center;" colspan="10" >No Records Found!</td>
                                </tr> 
                            </tbody> 
                        </table>
                     </div> -->
                    
                    <!-- <div *ngIf="noRecordsFound == false">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
</div>

<div  id="pdfTable" #pdfTable style="display: none;">   
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table-class" data-pdfmake="{'widths':['*','*'],'heights':10}">
                                <thead>
                                    <tr>
                                        <td class="text-center" width="40%">
                                            <img [src]="base64data" style="height:65px;width:180px;">
                                        </td>
                                        <td class="float-left" width="80%">
                                            <p style="font-size:18px;font-weight:600;text-align:left"><b style="text-align:left">{{ this.usertData.organization.organizationName}}</b></p>
                                            <span style="padding-top:0px;float:left;width:100%;">{{ this.usertData.organization.address}}</span><br>
                                        </td>
                                    </tr>
                                </thead>
                            </table> 
                                
                            <table    class="table-class"  data-pdfmake="{'widths':['*'],'heights':10}">
                                <thead>
                                    <tr>
                                        <td  style="text-align:center;font-size:14px;padding:10px;">
                                            <b>Consolidated Report </b>
                                            <br><br>
                                             <span style="font-weight:500">STATEMENT FOR THE PERIOD OF  {{period}}</span>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                   
                            <table class="table items table-bordered table-condensed table-hover" style="width: 80%;" data-pdfmake="{'widths':[5,auto,auto,40,35,45,40,40,35,35],'heights':20 ,'headerRows': 1}">
                                <th style="border: 1px solid #ccc;">Emp Code</th>
                                <th style="border: 1px solid #ccc;">Emp Name</th>
                                <th style="border: 1px solid #ccc;">Account Number</th>
                                <th style="border: 1px solid #ccc;">IFSC Code</th>
                                <th style="border: 1px solid #ccc;">Bank Name</th>
                                <!-- <th style="border: 1px solid #ccc;">Branch Name</th> -->
                                <th style="border: 1px solid #ccc;">Period</th>
                                <th style="border: 1px solid #ccc;">CTC</th>
                                <th style="border: 1px solid #ccc;">Amount Before Deduction ({{organizationSettings?.country?.currencyCode}})</th>
                                <th style="border: 1px solid #ccc;">Deduction ({{organizationSettings?.country?.currencyCode}})</th>
                                <th style="border: 1px solid #ccc;">Net Pay ({{organizationSettings?.country?.currencyCode}})</th>
                                
                                


                                <tbody *ngIf="resultData.length>0">
                                    <tr *ngFor="let row of resultData  let i=index">
                
                                        <td style="border: 1px solid #ccc;">{{row.employee.employeeNumber}}</td>
                                        <td style="border: 1px solid #ccc;">{{row.employee.firstName}}</td>
                                        <td style="border: 1px solid #ccc;">{{row.employee.employeeInfo?.bankAccountNo}}</td>
                                        <td style="border: 1px solid #ccc;">{{row.employee.employeeInfo?.ifscCode}}</td>
                                        <td style="border: 1px solid #ccc;">{{row.employee.employeeInfo?.bankName}}</td>
                                        <!-- <td style="border: 1px solid #ccc;">{{row.employee.employeeInfo.bankBranchName}}</td> -->
                                        <td style="border: 1px solid #ccc;">{{row.finanicalPeriod.financialperiodMonth}} - {{row.finanicalPeriod.financialPeriodYear}}</td>
                                        <td style="border: 1px solid #ccc;">{{ (row.employeeAnnexure.annexureCtc / 12 )?.toFixed(2)}}</td>
                                        <td style="border: 1px solid #ccc;">{{row.totalAmount?.toFixed(2)}}</td>
                                        <td style="border: 1px solid #ccc;">{{row.totalDeduction?.toFixed(2)}}</td>
                                        <td style="border: 1px solid #ccc;">{{Roundof(row.totalAmountbeforededuction - row.totalDeduction)}}</td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>    
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
</div>

<mat-card style="margin-top: 24%; " class="card-content-nodata mat-card-nodata" *ngIf="noRecordsFound">
    <div class="card-body-nodata">
      <div class="alert-nodata --alert-primary">
        <h4 align="center" class="--h4" style=" padding:10px; margin-top: 14px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 11px;
        ">No Records Found </h4>
      </div>
    </div>
</mat-card>