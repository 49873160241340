import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  } from '../../providers/properties';
import { HeaderService } from 'src/app/providers/header.service';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  @ViewChild('picker1') offerReleasedDates!: MatDatepicker<any>;
  @ViewChild('picker2') offerAcceptedDates!: MatDatepicker<any>;
  @ViewChild('picker3') dateOfJoinings!: MatDatepicker<any>;

  usertype:any;
  employeeId :string;
  onboardingcreatedata:any;
  onboardingusertype:any;
  onboardingresource:any;
  positionId:any;
  resourceId:any;
  jobtypeId:any;
  data:any = [];
  jobtypebyiddata :any;
  create_sucess_msg:any;
  update_data_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  success:any=false;
  error:any=false;
  onboaringform: FormGroup;
  onboardingdata: any;
  onboardingId: any;
  resourcereadonly : any = false;
  acceptminDate :any;
  joiningminDate:any;
  showclient :any = false;
  clientname:any;
  clientname1:any = [];
  submitted: boolean = false;
  buttonDisabled: boolean = false;
  endSelectedFirst: boolean;
  // acceptMinDate: null;
  acceptminDates=Date;
today: any;
  orgId: any;
  constructor(
      private router:Router,
      private onboardingservice:RecruitmentService ,
      private ActivatedRoute:ActivatedRoute, 
      private datepipe : DatePipe,
      private positionservice :RecruitmentService,
      private headerservice: HeaderService,

    ) 
  { }

  ngOnInit(): void 
  {
    this.headerservice.setTitle('Onboardings ')

    /* this.usertype = JSON.parse(localStorage.getItem('user_hrms'));
    this.employeeId = this.usertype.employeeId;
     */
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    
    let employeeId = this.usertype.userId;
    let organizationId = this.usertype.organization.organizationId;

    this.orgId=organizationId;

    //usertype id
    this.onboardingservice.hiringfor().subscribe((data:any) =>
    {
      this.onboardingusertype = data;
    })

    //clientname
    // this.onboardingservice.clientName().subscribe((data:any) =>
    // {
    //   this.clientname = data;
    // }) 
    //clientdata
    this.positionservice.getclientlist(organizationId).subscribe((resultData:any) =>
    {console.log(' resultData', resultData)
      resultData.forEach((item: any) => {
       if (item.status.listTypeValueId == 1) {
         console.log(item.status.listTypeValueId)
         this.clientname1.push(item);
         console.log(' this.clientname1', this.clientname1)
       }
     });

    });


    //resource id
    this.onboardingservice.resource(this.orgId).subscribe(( resourceId:any) =>
    {
     this.data = resourceId;
    })

    this.onboaringform = new FormGroup(
      {
        // employeeNumber : new FormControl(this.onboaringform,[Validators.required,Validators.pattern(/^[0-9_a-z A-Z]*$/)]),
        // resource : new FormControl(this.onboaringform,[Validators.required]),
        resource : new FormControl(null,[Validators.required]),
        // hiring : new FormControl(this.onboaringform), 
        hiring : new FormControl(null), 
        approvedCtc : new FormControl(this.onboaringform,[Validators.required,Validators.pattern(/^[1-9]\d{0,10}$/)]), 
        workLocation : new FormControl(this.onboaringform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),   
        clientName : new FormControl(this.onboaringform),      
        offerReleasedDate : new FormControl(this.onboaringform,[Validators.required]),
        offerAcceptedDate : new FormControl(this.onboaringform,[Validators.required]),
        waitingPeriod : new FormControl(this.onboaringform,[Validators.required,Validators.pattern(/^[0-9_a-z A-Z]*$/)]), 
        dateOfJoining : new FormControl(this.onboaringform,[Validators.required]),  
        emailConfirmation : new FormControl(this.onboaringform,[Validators.required]), 
        // resourcecvattachment : new FormControl(this.onboaringform,[Validators.required]),  
      }
    );

    if(this.ActivatedRoute.snapshot.params.onboardingId)
    {
      this.resourcereadonly = true;
      this.onboardingId = this.ActivatedRoute.snapshot.params.onboardingId;
      this.loader=true;
      this.onboardingservice.onboardingbyid(this.ActivatedRoute.snapshot.params.onboardingId).subscribe((onboarding:any) =>
      {
        this.onboardingdata=onboarding;
        this.loader=false
         console.log("this.onboardingdata::",this.onboardingdata);
        //   console.log("offerReleasedDate" + this.onboardingdata.offerReleasedDate.substring(0,10));
        this.onboaringform.patchValue({
            // employeeNumber : this.onboardingdata.employeeNumber,
            resource :  this.onboardingdata.resource.resourceId,
            hiring :  this.onboardingdata.hiring.listTypeValueId, 
            approvedCtc :  this.onboardingdata.approvedCtc, 
            workLocation :  this.onboardingdata.workLocation,            
            offerReleasedDate :  this.onboardingdata.offerReleasedDate.substring(0,10),
            offerAcceptedDate :  this.onboardingdata.offerAcceptedDate.substring(0,10),
            waitingPeriod : this.onboardingdata.waitingPeriod, 
              dateOfJoining :  this.onboardingdata.dateOfJoining.substring(0,10),  
            emailConfirmation :  this.onboardingdata.emailConfirmation, 
        });
        console.log(' this.onboaringform',  this.onboaringform)
        if(this.onboardingdata.resource.position.hiring.listTypeValueId == 32)
        {         
          this.showclient = true;
          if( this.onboardingdata.resource.position.client != undefined )
          {               console.log('this.onboardingdata.resource.position.client.clientId', this.onboardingdata.resource.position.client.clientId)   
            this.onboaringform.patchValue({
              // clientName :  this.onboardingdata.resource.position.client.clientId
              clientName :  this.onboardingdata.resource.position.client
            });
          }
        }
        else
        {          
          this.showclient = false;
        }
      })
    }
  }

  noNumber(event: any) {
    // const inputValue = event.target.value;
  
  
    const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
  }

  //Submit Form ( Create / Edit )
  submitData()
  {
    this.buttonDisabled = true;
    this.submitted = true;
    this.error = false;
    this.success = false;

    if(this.onboaringform.invalid)
    {
      this.buttonDisabled = false;
      return;
    }
     
    let clientname;
    
    if(this.onboaringform.value.hiring == 32)
    {
      clientname = this.onboaringform.value.clientName;
    }
    else
    {
      clientname = "";
    }
 
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    
    let employeeId = this.usertype.userId;
    let organizationId = this.usertype.organization.organizationId;

    if(this.onboardingId)
    {
        var onboardingupdatedata =
        { 
          organizationId : organizationId,
          onboardingId : this.onboardingId,
          // employeeNumber : this.onboaringform.value.employeeNumber,
          resource : {resourceId:this.onboaringform.value.resource},
          hiring : {listTypeValueId:this.onboaringform.value.hiring},
          approvedCtc : this.onboaringform.value.approvedCtc,
          workLocation: this.onboaringform.value.workLocation,
          // client : this.onboaringform.value.clientName,
          offerReleasedDate : this.datepipe.transform(this.onboaringform.value.offerReleasedDate, 'yyyy-MM-dd'),
          offerAcceptedDate : this.datepipe.transform(this.onboaringform.value.offerAcceptedDate, 'yyyy-MM-dd'),
          waitingPeriod : this.onboaringform.value.waitingPeriod,
          dateOfJoining : this.datepipe.transform(this.onboaringform.value.dateOfJoining, 'yyyy-MM-dd'),
          emailConfirmation : this.onboaringform.value.emailConfirmation,               
          lastUpdatedBy:this.employeeId,
          status :  this.onboardingdata.status
        };

        if(this.onboaringform.value.clientName != null ||  this.onboaringform.value.clientName != undefined)
        {
          onboardingupdatedata['client'] =  {clientId:this.onboaringform.value.clientName} ;
        }
        console.log("payload updation::",onboardingupdatedata);
        
        
        this.backdrop=true;
        // this.loader=true;
        // return

        this.onboardingservice.onboardingupdate(this.onboardingId,onboardingupdatedata).subscribe( (data:any) =>
        {
          // this.buttonDisabled = false;
          if(data.statusCode == 200)
            {
              this.backdrop = false;
              this.loader = false;
              this.success = true;
              this.buttonDisabled = true;

              this.create_sucess_msg = data.description;
              setTimeout(() => {
                this.router.navigate(['home/onboardings']); 
              }, 3000)
            }else{
              this.backdrop = false;
              this.loader = false;
              this.error = true;
                      this.buttonDisabled = false;

              this.update_data_error_msg = data.description;
              console.log(this.update_data_error_msg);
              setTimeout(() => {this.error = false}, 3000)
            }
        },(err) =>
        {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
                  this.buttonDisabled = false;

          this.update_data_error_msg = err.error.message;
          console.log(this.update_data_error_msg);
          setTimeout(() => {this.error = false}, 3000)
        }
      );       
    }
    else
    {
      console.log("form::",this.onboaringform);
      
      // var onboardingdata =
      // { 
      //   //employeeNumber : this.onboaringform.value.employeeNumber,
      //   organizationId : organizationId,
      //   resource : {resourceId:this.onboaringform.value.resource},
      //   hiring : {listTypeValueId:this.onboaringform.value.hiring},
      //   approvedCtc : this.onboaringform.value.approvedCtc,
      //   workLocation: this.onboaringform.value.workLocation,
      //   // client : this.onboaringform.value.clientName,
      //   offerReleasedDate : this.datepipe.transform(this.onboaringform.value.offerReleasedDate, 'yyyy-MM-dd'),
      //   offerAcceptedDate : this.datepipe.transform(this.onboaringform.value.offerAcceptedDate, 'yyyy-MM-dd'),
      //   waitingPeriod : this.onboaringform.value.waitingPeriod,
      //   dateOfJoining : this.datepipe.transform(this.onboaringform.value.dateOfJoining, 'yyyy-MM-dd'),
      //   emailConfirmation : this.onboaringform.value.emailConfirmation,
      //   // resourcecvattachment : this.onboaringform.value.resourcecvattachment
      //   createdBy:this.employeeId
      // };

      // // if(this.onboaringform.value.clientName != null ||  this.onboaringform.value.clientName != undefined)
      // // {
      // //   onboardingdata['client'] =  {clientId:this.onboaringform.value.clientName} ;
      // // }
      // if (this.onboaringform.controls['clientName'].value != 0) {
      //   // If not zero, patch the form with the clientId
      //   this.onboaringform.patchValue({
      //     client: { clientId:this.onboaringform.value.clientName }
      //   });
      // } else {
      //   //
      //   this.onboaringform.patchValue({
      //     client: { clientId: 0 }
      //   });      }      
                  
      // // this.backdrop=true;
      // // this.loader=true;
      // console.log("create payload::",onboardingupdatedata);
      let onboardingdata: any = {
        organizationId: organizationId,
        resource: { resourceId: this.onboaringform.value.resource },
        hiring: { listTypeValueId: this.onboaringform.value.hiring },
        approvedCtc: this.onboaringform.value.approvedCtc,
        workLocation: this.onboaringform.value.workLocation,
        offerReleasedDate: this.datepipe.transform(this.onboaringform.value.offerReleasedDate, 'yyyy-MM-dd'),
        offerAcceptedDate: this.datepipe.transform(this.onboaringform.value.offerAcceptedDate, 'yyyy-MM-dd'),
        waitingPeriod: this.onboaringform.value.waitingPeriod,
        dateOfJoining: this.datepipe.transform(this.onboaringform.value.dateOfJoining, 'yyyy-MM-dd'),
        emailConfirmation: this.onboaringform.value.emailConfirmation,
        createdBy: this.employeeId,
      };
      
      // Check if the client name is defined and not zero
      if (this.onboaringform.controls['clientName'].value && this.onboaringform.controls['clientName'].value != 0) {
        onboardingdata.client = this.onboaringform.value.clientName 
      } else {
        // If client name is not provided or zero, set clientId to 0
        onboardingdata.client =  0 
      }
      
      // Debugging: Check the value of the payload before proceeding
      console.log("create payload::::", onboardingdata);
      
      
      // return

      this.onboardingservice.onboardingcreate(onboardingdata).subscribe( (data:any) =>
      {
        this.onboardingcreatedata= data;
        // this.buttonDisabled = false;
        if(data.statusCode == 200)
          {
            this.backdrop = false;
            this.loader = false;
            this.success = true;
            this.create_sucess_msg = data.description;
            setTimeout(() => {
              this.router.navigate(['home/onboardings']); 
            }, 3000)
          }else{
            this.backdrop = false;
            this.loader = false;
            this.error = true;
                    this.buttonDisabled = false;

            this.update_data_error_msg = data.description;
            console.log(this.update_data_error_msg);
            setTimeout(() => {this.error = false}, 3000)
          }
     
      },(err) =>
        {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
                  this.buttonDisabled = false;

          this.update_data_error_msg = err.error.message;
          console.log(this.update_data_error_msg);
          setTimeout(() => {this.error = false}, 3000)
        }
      );
    }
  }
  
  listTypeValueid(event)
  {
     console.log("listTypeValueid"+event.value);    
  }

  resourcechange(event)
  {
    let positiondata ; 

    
    this.data.forEach(element => {
        console.log( element.resourceId);
        if(event.value == element.resourceId)
        {
          positiondata = element.position;
        }
    });
    
    this.onboaringform.patchValue({
      hiring : positiondata.hiring.listTypeValueId,                  
    });
      
    if(positiondata.hiring.listTypeValueId == 32)
    {
      if( positiondata.client != undefined )
      {                
        this.onboaringform.patchValue({
          clientName :  positiondata.client.clientId
        });
      }
        this.showclient = true;
    }
    else
    {
      this.showclient = false;
    }
  }


  checkdif()
  {
     console.log(  this.onboaringform.value.offerReleasedDate , "+" , this.onboaringform.value.offerAcceptedDate);

    if(this.onboaringform.value.offerReleasedDate != null && this.onboaringform.value.offerAcceptedDate != null)
    {
      let offerAcceptedDate = new Date(this.onboaringform.value.offerAcceptedDate);
      let offerReleasedDate = new Date(this.onboaringform.value.offerReleasedDate);
      let days =   Math.floor((Date.UTC(offerAcceptedDate.getFullYear(), offerAcceptedDate.getMonth(), offerAcceptedDate.getDate()) - Date.UTC(offerReleasedDate.getFullYear(), offerReleasedDate.getMonth(), offerReleasedDate.getDate()) ) /(1000 * 60 * 60 * 24));
      console.log("days : " +days);
      var waitingPeriod = days;
      this.onboaringform.patchValue({
        waitingPeriod: waitingPeriod 
      });
    }

    if(this.onboaringform.value.offerReleasedDate != null)
    {
      this.acceptminDate = this.onboaringform.value.offerReleasedDate
    }

    if(this.onboaringform.value.offerAcceptedDate != null)
    {
      this.joiningminDate = this.onboaringform.value.offerAcceptedDate
    }
    if (this.onboaringform.value.offerReleasedDate && this.onboaringform.value.offerAcceptedDate && this.onboaringform.value.offerReleasedDate > this.onboaringform.value.offerAcceptedDate) {
      console.log("entering here!!");
      alert('Offer Releasing Date cannot be greater than Offer Acceptance Date.');

      this.onboaringform.patchValue({
        offerReleasedDate: null,
        offerAcceptedDate: null,
        waitingPeriod:null,
        dateOfJoining:null
      });
  
      return
    }
  }

  onOfferReleasedDateChange(event: any): void {
    const offerReleasedDate = event.value;
    if (offerReleasedDate) {
      // this.acceptMinDates = offerReleasedDate;
    }
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    if (this.endSelectedFirst &&  this.onboaringform.value.offerReleasedDate>  this.onboaringform.value.offerAcceptedDate) {
      // If end date was selected first and start date is greater than end date
      // Set start date to be one day before end date
      this.onboaringform.value.offerReleasedDate = new Date(this.onboaringform.value.offerAcceptedDate);
      this.onboaringform.value.offerReleasedDate.setDate(this.onboaringform.value.offerAcceptedDate.getDate() - 1);
    }


    if (event.value) {
      this.acceptminDate = new Date(event.value);
      this.acceptminDate.setDate(this.acceptminDate.getDate() + 1); // setting minimum date to the next day of selected date
    }
    // console.log(  this.joiningminDate , "+" ,  this.acceptminDate);
   
    // this.onboaringform.value.offerAcceptedDate = null;
    // this.onboaringform.value.offerReleasedDate = null;

    this.joiningminDate = '';
    this.acceptminDate = '';
    // if (event.value) {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.remove('black-placeholder');
    // } else {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }

    if (this.endSelectedFirst && this.joiningminDate > this.acceptminDate) {
      // If end date was selected first and start date is greater than end date
      // Set start date to be one day before end date
      this.joiningminDate = new Date(this.acceptminDate);
      this.joiningminDate.setDate(this.acceptminDate.getDate() - 1);
    }

    // Check if start date is defined and update flag accordingly
    if (this.joiningminDate !== undefined) {
      // this.joiningmindate_required = false;
    }

    // Check if end date is undefined and update flag accordingly
    if (this.acceptminDate === undefined) {
      // this.acceptmindate_required = true;
    }

    if (this.joiningminDate == undefined && this.acceptminDate == undefined) {
      // this.joiningmindate_required = false;
      // this.acceptmindate_required = false;
    }
    // Check if start date is greater than end date and update flag accordingly
    if ( this.acceptminDate > this.joiningminDate) {
      
      // console.log(  this.joiningminDate , "+" ,  this.acceptminDate);
      
      
      this.joiningminDate = null;
      this.acceptminDate = null;

      this.joiningminDate = '';
      this.acceptminDate = '';
      // this.joiningmindate_required = false;
      // this.acceptmindate_required = false;
      alert('Offer Releasing Date cannot be greater than Offer Acceptance Date.');
    
      return
    }
    this.offerAcceptedDates.close()
    this.offerReleasedDates.close()
    this.dateOfJoinings.close()
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  get resource()
  {
    return this.onboaringform.get('resource');
  }

  get hiring()
  {
    return this.onboaringform.get('hiring');
  }

  get approvedCtc()
  {
    return this.onboaringform.get('approvedCtc');
  }

  get  workLocation()
  {
    return this.onboaringform.get('workLocation');
  }

  get clientName()
  {
    return this.onboaringform.get('clientName');
  }

  get offerReleasedDate()
  {
    return this.onboaringform.get('offerReleasedDate');
  }

  get offerAcceptedDate()
  {
    return this.onboaringform.get('offerAcceptedDate');
  }

  get waitingPeriod()
  {
    return this.onboaringform.get('waitingPeriod');
  }

  get dateOfJoining()
  {
    return this.onboaringform.get('dateOfJoining');
  }

  get emailConfirmation ()
  {
    return this.onboaringform.get('emailConfirmation');
  }

}
