
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="col-md-6">
          <span class="h5 sub-content">Employee Recovery Adjustment</span>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <div class="sub-header-buttons">
            <a
              (click)="importEmployee()"
              mat-raised-button
              class="ml-2 mr-2 searchBtn2"
              title="Import"
            >
              Import
            </a>
            <button
              mat-raised-button
              class="searchBtn2"
              (click)="addRecovery()"
            >
              Add Employee Recovery Adjustment
            </button>
            <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                            Add Financial Calendar
                        </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
          <div class="row">
            <!-- <div class="form-group col-md-3">
                                <input formControlName="employeecode" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search...">
                                    <small class="text-muted">Note : Employee Number</small>
                                 <div *ngIf="this.req">
                                     <span style="color:red">*Employee Number required</span>
                                 </div>
                            </div> -->
            <div class="form-group col-md-3">
              <!-- <label class="col-form-label">Employee<span class="star">*</span></label> -->
              <label class="form-control-label">Search Employee</label>

              <input
                type="text"
                matInput
                placeholder="Search Employee"
                formControlName="employeecode"
                [matAutocomplete]="auto"
                class="form-control email-input select-placeholder"
                (keyup)="empSearch($event)"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <!-- <mat-option  value="All" (click)="empAllSelect()">All
                                          </mat-option> -->
                <mat-option
                  *ngFor="let emp of empList"
                  [value]="emp.employeeId"
                  (click)="empSelect(emp)"
                >
                  {{ emp.employeeNumber }}- {{ emp.firstName }}
                </mat-option>
                <mat-option [value]="null" *ngIf="empCode" (click)="noEmployeeFound()">No
                  Employee Found</mat-option>
              </mat-autocomplete>
              <!-- <mat-icon class="material-symbols-outlined" style="position:absolute;top:10px;right: 19px;">
                                        search
                                    </mat-icon> -->
              <div class="valid-msg" *ngIf="submitted">
                <span
                  *ngIf="
                    this.selectedEmployeeData.length == 0 && submitted == true
                  "
                  class="text-danger"
                  >Please select employee</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">Select Financial Year </label>
              <mat-select
                formControlName="financialyear"
                placeholder="Select Financial Year"
                class="form-control email-input select-placeholder"
                (selectionChange)="selectFinancialyear()"
              >
                <mat-option [value]="null">
                  -- Select Financial Year ---
                </mat-option>
                <mat-option
                  *ngFor="let row of finYearList; let i = index"
                  [value]="row.financialYearId "
                  >{{ row.financialFromMonth }}-{{ row.financialFromYear }} To
                  {{ row.financialToMonth }}-{{
                    row.financialToYear
                  }}</mat-option
                >
              </mat-select>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">Select Period </label>
              <mat-select
                (selectionChange)="selectFinancialperiod()"
                formControlName="period"
                placeholder="Select Financial Year"
                class="form-control email-input select-placeholder"
              >
                <mat-option [value]="null"> -- Select Period --- </mat-option>
                <mat-option
                  *ngFor="let row of finPeriodList; let i = index"
                  [value]=" row.financialPeriodId "
                  >{{ row.financialperiodMonth }}-{{
                    row.financialPeriodYear
                  }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="selectperiod">Please select period</mat-error>
            </div>
            <div class="form-group col-md-3">
              <!-- <select formControlName="element" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]=null> -- Select Element --- </option>
                                    <option *ngFor="let row of finEmpList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select> -->
              <label class="form-control-label">Select Element </label>
              <mat-select
                formControlName="element"
                placeholder="Select Financial Year"
                class="form-control email-input select-placeholder"
              >
                <mat-option [value]="null"> -- Select Element --- </mat-option>
                <mat-option
                  *ngFor="let row of eleNameList; let i = index"
                  [value]=" row.elementId "
                  >{{ row.elementName }}</mat-option
                >
              </mat-select>
            </div>
            <!-- <div class="col-md-3">
                                <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search
                                </button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                   class="btn search-btn"
                                    (click)="clearTable()">Clear
                                </button>
                            </div> -->
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- <button type="submit" mat-raised-button color="primary"
                                        class="btn btn-primary search-btn">Search
                                    </button> -->
              <button
                type="submit"
                mat-raised-button
                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                [disabled]="isProcessing"
              >
                Search
              </button>
              <!-- <button style="margin-left: 10px;" type="reset" mat-raised-button
                                       class="btn search-btn mr-2"
                                        (click)="clearTable()">Clear
                                    </button> -->
              <button
                mat-stroked-button
                type="reset"
                class="apply-buttons"
                (click)="clearTable()"
                [disabled]="isProcessing"
                style="
                  margin: 8px 0px 0px 5px;
                  background: white;
                  border: 1px solid #ddd;
                  position: relative;
                  top: -8%;
                  font-family: Mulish;
                  color: black;
                  font-size: 16px;
                  font-weight: 610;
                "
              >
                Clear
              </button>
            </div>
          </div>
        </form>
        <div class="row mt-5" *ngIf="resultData?.length > 5 && !loader">
          <!-- <div class="col-md-11 text-right"> -->
          <!-- <p class="show-count">Show</p> -->
          <!-- </div> -->
          <!-- <div class="col-md-1 text-left pl-0">
                            <select          class="select_list new-select_list listDesign" [(ngModel)]="pagePerItem" (change)="onItemsPerPageChange($event)" >
                                    <option  value="10">10</option>
                                    <option  value="25">25</option>
                                    <option  value="50">50</option>
                                    <option  value="100">100</option>
                                    <option  value="500">500</option>
                            </select>
                       </div> -->
        </div>

        <div *ngIf="showtable && !loader" class="table-responsive mt-3">
          <!-- <div><p class="recovery-adjustment"><b>RECOVERY ADJUSTMENT</b></p></div> -->

          <div class="text-right" *ngIf="resultData?.length > 5 && !loader">
            <div class="select-pagination m-2">
              <span>Show : </span>
              <select
                class="select_list new-select_list listDesign"
                [(ngModel)]="pagePerItem"
                (change)="onItemsPerPageChange($event)"
              >
                <!-- <option class="form-control" value="5">5</option> -->
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>

              </select>
            </div>
          </div>
          <table
            class="table --table-bordered table-border-new"
            style="border-top-left-radius: 10px; border-top-right-radius: 10px"
          >
            <thead class="t-head">
              <tr>
                <th>Action</th>
                <th>Financial Year</th>
                <th>Period</th>
                <th>Employee Code</th>
                <th>Employee Name</th>
                <th>Element Category</th>
                <th>Element Name</th>
                <th>amount</th>
              </tr>
            </thead>
            <tbody *ngIf="resultData.length > 0">
              <tr
                *ngFor="
                  let row of resultData
                    | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                    | searchFilter : tableSearch;
                  let i = index
                "
              >
                <td class="">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" class="controls-list">
                    <button
                      mat-menu-item
                      routerLink="/home/recovery-adjustment/{{
                        row.recoveryAdjustmentId
                      }}"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                  </mat-menu>
                </td>
                <td>
                  {{ row.financialPeriod.finanicalYear.financialFromMonth }}-{{
                    row.financialPeriod.finanicalYear.financialFromYear
                  }}
                  To {{ row.financialPeriod.finanicalYear.financialToMonth }}-{{
                    row.financialPeriod.finanicalYear.financialToYear
                  }}
                </td>
                <td>
                  {{ row.financialPeriod.financialperiodMonth }}-{{
                    row.financialPeriod.financialPeriodYear
                  }}
                </td>
                <td>{{ row.employee.employeeNumber }}</td>
                <td>{{ row.employee.firstName }}</td>
                <td>{{ categoryName }}</td>
                <td>{{ row.hrmspaySlipElement.elementName }}</td>
                <td>{{ row.amount }}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td
                  style="text-align: center; font-weight: 770; font-size: 21px"
                  colspan="8"
                  *ngIf="noRecordsFound"
                >
                  No Records Found!
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="resultData.length > 5 && !loader ">
          <div class="row">
            <div class="col-md-4">
              <div class="showing-record">{{ resultData.length }} rows</div>
            </div>
            <div
              class="col-md-8 text-right"
              *ngIf="!loader && resultData.length > 5"
            >
              <pagination-controls
                style="float: right"
                (pageChange)="p = $event"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<!-- <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div> -->

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
    role="status"
  ></div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
  
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>