import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { LeaveService } from 'src/app/providers/leave.service';
import Swal from 'sweetalert2';
import { ApproverStatusComponent } from '../approver-status/approver-status.component';
import { HeaderService } from 'src/app/providers/header.service';
import { LeavemanagementstatusComponent } from 'src/app/leavemanagementstatus/leavemanagementstatus.component';
// import { leavedenyreason } from '../my-wfh-list/my-wfh-list.component.spec';

@Component({
  selector: 'app-mypermission-list',
  templateUrl: './mypermission-list.component.html',
  styleUrls: ['./mypermission-list.component.scss']
})
export class MypermissionListComponent implements OnInit {
  list_count = 0;
  data: any[] = [];
  isLoading:Boolean;
  noData=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno','permissionDate', "permissionHours","reason","viewapprovers", "workFromHomeStatus", 'star',];
  // dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  changeasDenyReason: boolean=false;
  isMenuOpen: boolean = false;
  clickedbusinessTravelId: any;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  success: boolean = false;
  error: boolean = false;
  success_msg: string;
  error_msg: any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
  paginators: boolean = true;
  beforesearchpage: any;

searchs: any;
list_counts:boolean = false;
  constructor(private gs: LeaveService, private router: Router, public dialog: MatDialog,
    private renderer: Renderer2, private headerservice : HeaderService) {
    // this.renderer.listen('window', 'click',(e:Event)=>{
    //   if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement){
    //       this.isMenuOpen=false;
    //       console.log(this.isMenuOpen);
    //   }
    // });
  }
  ngOnInit() {
    // this.headerservice.setTitle('My Permission List');
    this.headerservice.setTitle('');


    // let x = JSON.parse(localStorage.getItem("enoteUserData"));
    // let id = x.employee.employeeId;
    // console.log(id)
    // // console.log("success");
    // this.gs.get_permissionList(id).subscribe((d: any) => {
    //   this.loader=false;
    //   console.log(d.length);
    //   if(d.length==0){
    //     console.log("noData");
    //   }
    //   if(d.statusCode==204)
    //   {
    //      this.noData=true;
    //      console.log("204");
    //   }
    //   console.log(d)
    //   this.data = d
    //   this.dataSource = new MatTableDataSource<any>(this.data);
    //   this.dataSource.paginator = this.paginator;
    //   // this.dataSource.sort = this.sort;
    //   this.list_count = this.data.length;
      
    // },
    // (error) =>
    // {
    //   this.loader=false;
    //   this.noData= true;
    //   console.log("error");
    //   if(error.statusCode==204)
    //   {
    //      this.noData=true;
    //      console.log("204");
    //   }
    // }
    // )
    this.fetchpermission();
  }

  fetchpermission(){

    let x = JSON.parse(localStorage.getItem("enoteUserData"));
    let id = x.employee.employeeId;
    console.log(id)
    // console.log("success");
    this.loader=true;
    this.gs.get_permissionList(id).subscribe((d: any) => {
      this.loader=false;
      this.list_counts=true;
      console.log(d.length);
      if(d.length==0){
        console.log("noData");
      }
      if(d.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
      console.log(d)
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
      this.list_count = this.data.length;
      
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }
  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(LeavemanagementstatusComponent,{
      width: '500px',
      height:'fit-content',
      data: {
        referredwith: "PERMISSION",
        id: approvaldata.permissionId
      }
      
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log("this.dataSource",this.dataSource.filteredData);
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.noData = true;
    }
    else{
      this.noData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  denyReason(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if(this.isMenuOpen==true){
      this.changeasDenyReason=true;
      console.log(this.changeasDenyReason);
    }
    else{
      this.changeasDenyReason=false;
      console.log(this.changeasDenyReason);
    }
  }
  cancel(e){
    console.log(e);
    

    Swal.fire({
      title: 'Are you sure? Do you want to Cancel Permission Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor:'#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
    //     e['permissionStatus'] = 8;
    // e['permissionStatusName'] ="Cancelled";
    // e.approvals['approverlevel'] = 1;
    // e.approvals.approvalStatus.listTypeValueId = 8 ;
    // e.approvals.approvalStatus.listTypeValueName = "Cancelled";
    // e.approvals['employeeId'] = e.employee.employeeId;
    //     this.loader = true;
    this.loader = true;
    this.gs.approvalsById("PERMISSION",e.permissionId).subscribe((data:any)=>{
      console.log(data);
      this.loader = false;
        e['permissionStatus'] = 8;
            // e['permissionStatusName'] ="Cancelled";

            e['approverlevel'] = 1;
            e['approvals'] = data;
            e.approvals.approverlevel=1;
            e.approvals.approvalStatus.listTypeValueId = 8 ;
            e.approvals.approvalStatus.listTypeValueName = "Cancelled";
            e['employeeId'] = e.employee.employeeId;
            this.loader = true;

            this.gs.Permission_Status(e.permissionId, e).subscribe(
              d => {
                // Swal.fire({
                //   text: 'Cancelled',
                //   icon: 'success'
                // })
                console.log((d as any).message);
                this.success = true;
                this.success_msg =(d as any).message ;
                this.loader = false
                setTimeout(() => { this.success = false; }, 3000);   
                this.fetchpermission();
                
    
                // window.location.reload();
              },
              err => {
                // Swal.fire({
                //   text: err.error.message,
                //   icon: 'error'
                // })
                this.error = true;
                this.error_msg = err.error.message;
                this.loader = false
    
                setTimeout(() => { this.error = false; }, 3000);   
    
              },
              () => {
                this.loader = false
              }
            )
      // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);

    },)
       
      }
    })
  }
  apply_permission(){
    this.router.navigateByUrl("/home/apply-permission");
  }

  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if (i == 'Cancelled') {
      return '#e40000'
    }
  }


  reason(i) {
    this.dialog.open(Permission, {
      width:'300px',
      data: i.permissionReason
    });
  }
  // showdenyreason(i){
  //   console.log(i)
  //   let x = i;
  //   this.dialog.open(leavedenyreason, {
  //     data: x
  //   });
  // }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}

@Component({
  selector: 'permission',
  templateUrl: 'permission.html',
})
export class Permission implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(){

  }
}

