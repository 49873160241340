import { Component, Inject, OnInit } from '@angular/core';
import { GlobalserviceService } from '../providers/globalservice.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-salary-advance-details',
  templateUrl: './salary-advance-details.component.html',
  styleUrls: ['./salary-advance-details.component.scss']
})
export class SalaryAdvanceDetailsComponent implements OnInit {
  advancedata:any={};
  expenses:any[];
  advancebalance
  approvers:any =[];
  fyi:any =[];
  empId:any;
  empNumber:any;
  totalsalary: any;
  advancePaid: any;
  organzationId: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public dialog: MatDialog,
    public gs: GlobalserviceService,
    private router:Router,
    private datepipe  : DatePipe,
  ) { }

  ngOnInit(): void {
    let localdata = JSON.parse(localStorage.getItem('enoteUserData'));
    // console.log(localdata);
    this.empId = localdata.employee.employeeId;
    this.organzationId = localdata.organization.organizationId;
   
    // console.log(this.empId);

    // console.log("data1",this.data);

    this.gs.getListBySalaryadvanceId(this.data).subscribe(
      (advancedata:any ) => {
      this.advancedata =advancedata;
      console.log(this.advancedata);
      console.log("approvalStatus",this.advancedata.approval.approvalStatus);
      // this.advancedata.expenses.sort((a,b) => a.date.localeCompare(b.date));
      // this.advancedata.fromDate=this.datepipe.transform(this.advancedata.fromDate, 'dd-MM-yyyy');
      // this.advancedata.toDate=this.datepipe.transform(this.advancedata.toDate, 'dd-MM-yyyy');
      // this.advancedata.createDateTime=this.datepipe.transform(this.advancedata.createDateTime, 'dd-MM-yyyy');
      // this.advancedata.submittedOn=this.datepipe.transform(this.advancedata.submittedOn, 'dd-MM-yyyy');
      // this.advancedata.approvedOn=this.datepipe.transform(this.advancedata.approvedOn, 'dd-MM-yyyy');
      // this.advancedata.expenses.map(
      //   e=>{
      //     e.date = this.datepipe.transform(e.date, 'dd-MM-yyyy');
      //   })
      if(advancedata.approval != undefined){
        if(advancedata.approval.approver.approverOne) {
            this.approvers.push(advancedata.approval.approver.approverOneObject.firstName +''+ advancedata.approval.approver.approverOneObject.lastName);
        }
        if(advancedata.approval.approver.approverTwo) {
            this.approvers.push(advancedata.approval.approver.approverTwoObject.firstName +''+ advancedata.approval.approver.approverTwoObject.lastName);
        }
        if(advancedata.approval.approver.approverThree) {
            this.approvers.push(advancedata.approval.approver.approverThreeObject.firstName +''+ advancedata.approval.approver.approverThreeObject.lastName);
        }
        if(advancedata.approval.approver.approverFour) {
            this.approvers.push(advancedata.approval.approver.approverFourObject.firstName +''+ advancedata.approval.approver.approverFourObject.lastName);
        }
        if(advancedata.approval.approver.approverFive) {
            this.approvers.push(advancedata.approval.approver.approverFiveObject.firstName +''+ advancedata.approval.approver.approverFiveObject.lastName);
        }
        if(advancedata.approval.approver.fyi){
          this.fyi.push(advancedata.approval.approver.fyiObject.firstName +''+ advancedata.approval.approver.fyiObject.lastName);
        }
        console.log(this.approvers);
        console.log(this.fyi);
      }
      this.gs.getAdvancedetailsForUpdate(this.advancedata.financialPeriod.financialPeriodId,localdata.employee.employeeId,this.advancedata.salaryAdvanceId,this.organzationId).subscribe(
        (dataIn:any)=>{
          console.log("data",dataIn);
          this.totalsalary = dataIn.totalSalary;
          this.advancePaid = dataIn.advanceAmountPaid;
        })
    })

  }
  formcancel() {
    this.dialog.closeAll();
  }
}
