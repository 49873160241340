<section>
    <div class="sub-header" style="top:64px">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <div class="">
                        <span class="h5 sub-content pr-3" style=" position: relative;
                        top: 6px;
                        color: #181c32;
                        font-weight: 800;
                        font-size: 22px;
                        font-family: 'Mulish', sans-serif;
                        right: 15px;">{{editId? "Edit" : "Add"}} Announcement</span>
                    </div>
                    <!-- <span routerLink="/home/kra-template" class="h5 sub-content">Add Branch</span> -->
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>



<form [formGroup]="announcementForm">

  <div class="row">
    <div class="col-md-12">
      <div class="card" style="margin-top: 12%; margin-left: 2%; margin-right: 2%;">
        <label class="col-form-label" style="font-family: Mulish;margin-top: 2%;margin-left: 1%; font-weight: 600;">Title <span class="star"> *</span></label>

        <input type="text" class="form-control" style="width: 30%; margin-left: 1%;" placeholder="title......"         (input)="validateInput($event)"
          formControlName="announcementTitle">
          <div *ngIf="(f.announcementTitle.invalid && submitted) || f.announcementTitle.dirty">
            <mat-error style="margin-left: 1.5%; font-weight:500;" *ngIf="f.announcementTitle.errors?.required">{{announcementTitle()}}</mat-error>
        </div> 

        <div *ngIf="invalidInput && (f.announcementTitle.pristine || !f.announcementTitle.errors?.required)">
          <mat-error style="margin-left: 1.5%;  font-weight:500;">Reason cannot consist of only spaces.</mat-error>
        </div>
        <label class="col-form-label" style="font-family: Mulish;margin-left: 1%;margin-top: 2%;font-weight: 600;">Content</label>

        <!-- <ckeditor [(ngModel)]="editorContent" [editor]="Editor" (change)="onEditorChange($event)"
          formControlName="announcementContent">

        </ckeditor> -->
        <quill-editor   (onEditorCreated)="onEditorCreated($event)" formControlName="announcementContent">
          <div quill-editor-toolbar>
            <span class="ql-formats">
              <button type="button" class="ql-bold"></button>
              <button type="button" class="ql-italic"></button>
              <button type="button" class="ql-underline"></button>
              <button type="button" class="ql-strike"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-code-block"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-header" value="1"></button>
              <button type="button" class="ql-header" value="2"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-list" value="ordered"></button>
              <button type="button" class="ql-list" value="bullet"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-script" value="sub"></button>
              <button type="button" class="ql-script" value="super"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-indent" value="-1"></button>
              <button type="button" class="ql-indent" value="+1"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-direction" value="rtl"></button>
            </span>
          <span  class="ql-formats">
            <select class="ql-size">
              <option value="small"></option>
              <option selected></option>
              <option value="large"></option>
              <option value="huge"></option>
            </select>
          </span>
          <span  class="ql-formats">
            <select class="ql-header">
              <option value="1"></option>
              <option value="2"></option>
              <option value="3"></option>
              <option value="4"></option>
              <option value="5"></option>
              <option value="6"></option>
              <option selected></option>
            </select>
          </span>
          <span class="ql-formats">
            <select class="ql-color" [title]="'Char color'">
            </select>
            <select class="ql-background" [title]="'Bg color'">
            </select>
          </span>
          <span class="ql-formats">
            <select class="ql-font">
                <option value="serif" selected>Serif</option>
                <option value="Arial">Arial</option>
                <option value="Courier New">Courier</option>
                <option value="Georgia">Georgia</option>
                <option value="Tahoma">Tahoma</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Trebuchet MS">Trebuchet MS</option>
                <option value="Verdana">Verdana</option>
                <option value="Comic Sans MS">Comic Sans MS</option>
                <option value="Calibri">Calibri</option>
                <option value="Roboto">Roboto</option>
                <option value="Noto Sans">Noto Sans</option>
                <option value="Helvetica">Helvetica</option>
            </select>
            </span>
            <span class="ql-formats">
              <select class="ql-align">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
              
              </select>
              </span>
              <span class="ql-formats">
                <button type="button" class="ql-clean" ></button>
              </span>
              <span class="ql-formats">
                <button class="ql-link"></button>
                <button class="ql-image"></button>
              </span>
            <span class="ql-formats">
              <!-- <div class="image-upload">
                <button (click)="fileInput.click()"  type="button" >
                  <mat-icon   style="font-size: 18px;
                  font-weight: 500; ">attach_file</mat-icon>
                  <input  #fileInput  type="file" id="input-file-now" style="display:none;"  (change)="fileChange($event)" class="file_upload"
                  accept=".xls,.xlsx,.png,.jpg,.gif,.jpeg,.docx,.doc,.docm,.dot,.dotm,
                          .dotx,.rtf,.xlsx,.xlsm,.xls,.xml,.zip,.rar,.pdf,.pptx" multiple>
                </button>
              </div> -->
            </span>
          </div>
        </quill-editor>
        <div *ngIf="(f.announcementContent.invalid && submitted) || f.announcementContent.dirty">
          <mat-error style="margin-left: 1.5%; font-weight:500;" *ngIf="f.announcementContent.errors?.minlength && ! isContentLengthValid()">Please Enter Content</mat-error>

          <mat-error style="margin-left: 1.5%;  font-weight:500;" *ngIf="f.announcementContent.errors?.required">{{announcementContent()}}</mat-error>
      </div> 


        <label class="col-form-label" style="font-family: Mulish;margin-left: 1%; font-weight: 600;">Attachment</label>
        <div class="d-flex align-items-center" style="padding: 10px;">
          <input type="file" name="file" id="file" class="form-control" style="width: 30%; margin-right: 10px; line-height: 1.5;" (change)="onFileSelected($event)">
          <div *ngIf="editId && dowUrl" style="display: flex; align-items: center;">
            <button type="button" class="btn btn-primary"  (click)="downloadfiles(dowUrl)" style="padding: 5px; background-color: #1A83FF;font-family:Mulish">
              <div class="button-content" >
                <i class="fa fa-download" style="color: #fff;"></i>
                <span class="button-text ml-2" >Download</span>
              </div>
            </button>
          </div>
        </div>
        
        <label *ngIf="announcementForm.get('fileName').value" class="col-form-label"
          style="font-family: Mulish;margin-left: 1%;">Current Attachment: {{ announcementForm.get('fileName').value
          }}</label>

        <!-- <input type="text" style="width:13%; margin-top: 15px; margin-left: 14px;" [value]="announcementForm.get('fileName').value"  /> -->
        <!--
          <div>
            <label for="fileInput">Attachment</label>
            <input id="fileInput" type="file" (change)="onFileSelected($event)" style="display: none;" />
            <input type="text" [value]="announcementForm.get('fileName').value" readonly />
            <button type="button" (click)="triggerFileInput()">Choose File</button>
          </div> -->
        <div class="row">
          <div class="col-md-12" style="padding: 25px;">
            <div class="d-flex justify-content-end">
              <!-- <button class="save-button" style="font-family: Mulish; width: 7%; background-color: #1A83FF; color: white; margin-right: 10px; padding: 6px; border: 0px; border-radius: 6px;" type="button" (click)="onSubmit()" >
                Save
              </button>
              <button mat-stroked-button class="save-button" style="font-family: Mulish; width: 7%; margin-right: 10px;" type="button" (click)="Cancel1()">
                Cancel
              </button> -->
              <button
              type="button"
              class="submit action-button btn mr-2 btn-sm"
              mat-raised-button
              (click)="Cancel1()"
              style="margin-right: 20px"
             
            >
              Clear
            </button>
            <!-- <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                      [disabled]="orgWorkingDaysForm.invalid && isprocessing1">Save
                                  </button> -->

            <button
              type="submit"
              mat-raised-button
              style="top: 0%"
              class="searchBtn"
              (click)="onSubmit()"
              [disabled]="isprocessing1 || announcementForm.invalid"
            >
              Save
            </button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    
  </div>
  <div class="text-center" *ngIf="success1">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg1}}
    </div>
  </div>
  <div *ngIf="error1" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg1}}
  </div>

</form>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 80%; right: 47.8%"
    role="status"
  ></div>
</div>
