<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Revenue Adjustment</span>
                </div>
                <div class="sub-header-buttons">
                  
                    <a (click)="importrevenueadjustment()" mat-raised-button class="ml-2 mr-2 searchBtn2"  title="Import">
                        Import
                      </a>
                    <a routerLink="/home/add-employee-revenue-adjustment" routerLinkActive="active"  mat-raised-button title="Add Employee" class="ml-2 searchBtn2">
                        Add Employee Revenue Adjustment
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Search Employee</label>

                                <!-- <label class="col-form-label">Employee<span class="star">*</span></label> -->
                                <input type="text"matInput  placeholder="Search Employee"   (input)="validateInput($event)"
                                           formControlName="employeecode"
                                           [matAutocomplete]="auto"
                                           class="form-control email-input select-placeholder" (keyup)="empSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <!-- <mat-option  value="All" (click)="empAllSelect()">All
                                          </mat-option> -->
                                      <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employeeNumber}}- {{emp.firstName}}  
                                      </mat-option>
                                      <mat-option [value]="null" *ngIf="empCode" (click)="noEmployeeFound()">No
                                        Employee Found</mat-option>
                                    </mat-autocomplete>
                                    <!-- <mat-icon class="material-symbols-outlined" style="position:absolute;top:10px;right: 19px;">
                                        search
                                    </mat-icon> -->
                              
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Select Financial Year</label>

                                <mat-select formControlName="financialyear"  placeholder="Select Financial Year"      class="form-control email-input select-placeholder" (selectionChange)="selectFinancialyear()" >
                                    <mat-option [value]="null"> -- Select Financial Year --- </mat-option>
                                    <mat-option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</mat-option> 
                                </mat-select>
                            </div>
                            <div class="form-group col-md-3 pl-1">
                                <label class="form-control-label">Select Period</label>

                                <mat-select formControlName="period"   placeholder="Select Period" [disabled]="finPeriodList.length === 0"    class="form-control email-input select-placeholder" >
                                    <mat-option [value]="null"> -- Select Period --- </mat-option>
                                    <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option>
                                </mat-select>
                                
                            </div>
                            <div class="form-group col-md-3 pl-1">
                                <label class="form-control-label">Select Element Name</label>

                                <mat-select formControlName="element"  placeholder="Select Element Name"      class="form-control email-input select-placeholder" >
                                    <mat-option [value]="null"> -- Select Element Name --- </mat-option>
                                    <mat-option *ngFor="let row of categoryNameList; let i=index;" value="{{row.elementId}}" >{{row.elementName}}</mat-option> 
                                </mat-select>
                            </div>
                        
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <!-- <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button> -->
                                    <button type="submit" mat-raised-button
                                    class="searchBtn mat-raised-button mat-button-base mr-2 custom-button" [disabled]="isProcessing"
                                    >
                                    Search
                                </button>
                                    <!-- <button style="margin-left: 10px;  height: 35px;" type="reset" mat-raised-button
                                     class="btn search-btn mr-2"
                                    (click)="clearTable()">Clear</button> -->
                                    <button mat-stroked-button type="reset" class="apply-buttons" (click)="clearTable()" [disabled]="isProcessing"
                                  
                                    style="margin: 8px 0px 0px 5px;background:white;border: 1px solid #ddd;position: relative ;
                                    top: -8%;  font-family: Mulish; color: black; font-size: 16px; font-weight: 610;">Clear</button>
                            </div>
                        </div>
                      
                    </form>
                    <!-- <div class="row pb-3" *ngIf="resultData?.length > 5 && !loader ">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="select_list new-select_list listDesign">
                                <select [(ngModel)]="pagePerItem" (change)="pageChages($event)" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                    </div> -->
                       
                       <div *ngIf="showtable && !loader" class="table-responsive mt-3">
                        <!-- <p><b>REVENUE ADJUSTMENT</b></p> -->
                        <div class="text-right" *ngIf="resultData.length > 5">
                            <div class="select-pagination m-2">
                              <span>Show : </span>
                              <select
                                class="select_list new-select_list listDesign"
                                [(ngModel)]="pagePerItem"
                                (change)="pageChages($event)"
                            
                              >
                                <option class="form-control" value="5">5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                              </select>
                            </div>
                          </div>
                        <table class="table  --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                            <thead class="t-head">
                                <tr>
                                    <th>Action</th>
                                    <th>Financial Year</th>
                                    <th>Period</th>
                                    <th>Employee Code</th>
                                    <th>Employee Name</th>
                                    <th>Element Category</th>
                                    <th>Element Name</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                             <tbody *ngIf="resultData.length>0">
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        
                                        <mat-menu #menu="matMenu"  class="controls-list">
                                            <button mat-menu-item routerLink="/home/edit-employee-revenue-adjustment/{{row.revenueAdjusmentId}}">
                                                <mat-icon style="margin-top: 3px;">edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                        </mat-menu>
                                    </td>
        
                                    <td>{{row.financialPeriod.finanicalYear.financialFromMonth}}
                                        -{{row.financialPeriod.finanicalYear.financialFromYear}} to
                                         {{row.financialPeriod.finanicalYear.financialToMonth}} 
                                         {{row.financialPeriod.finanicalYear.financialToYear}}
                                    </td>
                                    <td>{{row.financialPeriod.financialperiodMonth}} -
                                        {{row.financialPeriod.financialPeriodYear}}</td>
                                    <td>{{row.employee.employeeNumber}}</td>
                                    <td>{{row.employee.firstName}}</td>
                                    <td>{{categoryName}}</td>
                                    <td>{{row.hrmspaySlipElement.elementName}}</td>
                                    <td>{{row.amount}}</td>
                                </tr>
                            </tbody>  
                            <tbody>
                                <tr>
                                    <td style="text-align: center;font-weight: 770;font-size: 21px;" colspan="8"  *ngIf="noRecordsFound">No Records Found!</td>
                                </tr> 
                           </tbody>
                        </table>
                     </div>
                    
                     <div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record" *ngIf="resultData.length>5">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right" *ngIf="!loader && resultData.length>5">
                                <pagination-controls style="float: right;" (pageChange)="p = $event"  *ngIf="!this.noRecordsFound"></pagination-controls>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div> 


<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>