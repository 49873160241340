import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportshome',
  templateUrl: './reportshome.component.html',
  styleUrls: ['./reportshome.component.scss']
})
export class ReportshomeComponent implements OnInit {


  results = [
    { reportName : "Consolidated Report" , routerlink : "consolidatereport-list" } ,
    { reportName : "PF Report" , routerlink : "pfreport-list" } ,
    { reportName : "TDS Report" , routerlink : "tdsreport-list" } ,
    { reportName : "Consolidate Payslip Report" , routerlink : "consolidatePayslip" } ,
    { reportName : "ESI Report" , routerlink : "esireport" } ,
    { reportName : "NetPay Report" , routerlink : "netpayreport" } ]
    usertData: any;
  moduledata: any= [];
  menudata: any = [];
  constructor( private router: Router) { }

  ngOnInit(): void
  {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
  
    

  
    if( this.usertData.employee)
    {
     // console.log("emp");
          if(this.usertData.employee.role)
          {
            this.usertData.employee.role.forEach(element => {
                  if(element.menu)
                  {
                    element.menu.forEach(mennuelement => {
                     let  hasmenu = this.menudata.some(m=> m.menuId == mennuelement.menuId);
                     if(!hasmenu)
                     {
                      this.menudata.push(mennuelement);
                   //   console.log( this.menudata);
                     }    
                   //  console.log( "module length" + mennuelement.module.length);                 
                        mennuelement.module.forEach(moduleelement => {
                          let  moduledata = this.moduledata.some(m=> m.moduleId == moduleelement.moduleId);
                          if(!moduledata)
                          {
                           this.moduledata.push(moduleelement);
                          }                     
                        });
                      //  console.log( "moduledata" +this.moduledata);
                    });
                  }
              });
          }
    }
    else
    {
      if(this.usertData.role)
      {
          console.log("userrole")
          this.usertData.role.forEach(element => {
              if(element.menu)
              {
                element.menu.forEach(mennuelement => {
                 let  hasmenu = this.menudata.some(m=> m.menuId == mennuelement.menuId);
                 if(!hasmenu)
                 {
                  this.menudata.push(mennuelement);
               //   console.log( this.menudata);
                 }    
               //  console.log( "module length" + mennuelement.module.length);                 
                    mennuelement.module.forEach(moduleelement => {
                      let  moduledata = this.moduledata.some(m=> m.moduleId == moduleelement.moduleId);
                      if(!moduledata)
                      {
                       this.moduledata.push(moduleelement);
                    
                      }                     
                    });
                  //  console.log( "moduledata" +this.moduledata);
                });
              }
          });
      }
    }


  }


  selectReports(routerlink){
    this.router.navigate([`/home/${routerlink}`]);
  }

  checkmenudata(menuname)
  {
    // console.log(this.menudata);
    //console.log("menuname" + menuname);
   // console.log(this.menudata);
  // console.log(menuname);
     return  this.menudata.some(e=> e.menuname == menuname);
  }

}
