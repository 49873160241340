
<div>
    <div class="row p-2" style="margin-top: 70px;">
    <h2> Employee and Appraisal Status</h2>
    </div>
    
     <div class="row p-2" style="margin-top: -15px;">
                    <div class="col-md-3 ">
                        <div class="card">
                            <div class="top-row p-3 ">
                                <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                                <img src="assets/uploads/Icon 1.png" alt=""
                                    style="background-color: #6583EF; border-radius: 30px; height: 35px; width: 35px; padding: 5px;">
                                <span class="card-name1 " style="font-weight: 600; font-size: 16px;" > Employees </span>
    
                            </div>
                            <div class="bottom-row ">
                                <div class="row p-2">
                                    <div *ngIf="resultData.length != 0">
                                    <div class="col-11  text-right" *ngFor="let r of resultData" >
                                        <span class="card-name" style="font-size: larger; font-weight:bolder;" >
                                            {{r.activeEmployees}}
                                            </span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" >
                        <div class="card">
                            <div class="top-row  p-3 ">
                                <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                                <img src="../../../assets/uploads/Submitted.png" alt=""
                                    style="background-color: #689ac9; border-radius: 30px; height: 35px; width: 35px;">
                                <span class="card-name1" style="font-weight: 600; padding-left: 5px; font-size: 16px;">Pending Appraisal   </span>
    
                            </div>
                            <div class="bottom-row ">
                                <div class="row p-2">
                                    <div *ngIf="empdata.length != 0">
                                    <div class="col-11  text-right"  *ngFor="let e of empdata">
                                        <span class="card-name" style="font-size: larger; font-weight:bolder;" >
                                            {{e.apprisalPending}}
                                            </span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
    
                    <div class="col-md-3 ">
                        <div class="card">
                            <div class="top-row  p-3">
                                <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                                <img src="../../../assets/uploads/Appraisal approve.png" alt=""
                                    style="background-color: #469365; border-radius: 30px; height: 35px; width: 35px; ">
                                <span class="card-name1  " style="font-weight: 600; padding-left: 4px; font-size: 16px;"> Reviewed Appraisal </span>
    
                            </div>
                            <div class="bottom-row ">
                                <div class="row p-2">
                                    <div *ngIf="empdata.length != 0">
                                    <div class="col-11 text-right" *ngFor="let e of empdata">
                                        <span class="card-name" style="font-size: larger; font-weight:bolder;">
                                            {{e.apprisalReviewed}}
                                            </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     
                    <div class="col-md-3" >
                        <div class="card">
                            <div class="top-row  p-3 ">
                                <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                                <img src="../../../assets/uploads/Closed.png" alt=""
                                    style="background-color: #f1ac57; border-radius: 30px; height: 35px; width: 35px;">
                                <span class="card-name1 " style="font-weight: 600; padding-left: 5px; font-size: 16px;">Closed Appraisal</span>
    
                            </div>
                            <div class="bottom-row ">
                                <div class="row p-2">
                                    <div *ngIf="empdata.length != 0">
                                    <div class="col-11  text-right"  *ngFor="let e of empdata">
                                        <span class="card-name" style="font-size: larger; font-weight:bolder;">
                                            {{e.apprisalClosed}}
                                            </span>
                                        
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    </div>
    
    
    <form [formGroup]="form">
    <div class="row p-2">
        <div class="col-md-4"><h2>Appraisal List</h2></div>
        <div class="col-md-3 float-right mb-3 " style="margin-left:41%;">
            <mat-select (selectionChange)="onPeriodChange()"  formControlName="period" id="period" class="form-select form-select-solid form-select-lg" style="padding-bottom:5px; ">
                <mat-option (click)="changeid(year)"(change)="changeid(year)" *ngFor="let year of periodData" [value]="year.financialPeriodId">
                       
                        
                        {{ year.financialperiodMonth }}-{{ year.financialPeriodYear }}
                        </mat-option> 
            </mat-select>
        </div>
    </div>
    </form>
    
    <div class="row p-2" style="margin-top: -15px;">
        <div class="col-md-4" >
            <div class="card1 scrollable-content" style=" background-color: white; " >
                
                <div class="row"  style="position: absolute;  background-color: white; z-index:9999; width: 92%; padding: 1%; margin-left: 0.5%; padding-top:2%">
                    <div class="col-md-9"  >
                        <h4 style="font-weight: 600; font-size: 16px;">Pending Appraisal ({{pendingCount}})</h4>
                        <!-- <h4  *ngIf="listPendingCount.length == 0" style="font-weight: 600; font-size: 16px;">Pending Appraisal ()</h4> -->
                    </div>
                    <!-- <div class="col-md-3">
                        <span style="float: right; color:#6583EF ;" (click)="viewAll()">View all</span>
                    </div>  -->
                    <div class="col-md-3">
                        <span 
                    style="float: right; cursor: pointer;" 
                    [ngStyle]="{ 'color': noPendingdata ? '#A9A9A9' : '#6583EF' }"
                    [ngClass]="{ 'disabled-link': noPendingdata }"
                    (click)=" !noPendingdata && viewAll()"
                >
                    View all
                </span>
                    </div>
                    
                </div> <br><br>
            
    
                
                <div >
                    <div class="list" style="padding:5%; margin-bottom: -10%;" *ngFor="let item of listData">
                        <div class="row" >
                            <div class="col-md-8" >
                                <p><span style="color:black; font-weight: 500;">{{item.employee?.firstName}} {{item.employee?.lastName}}</span></p>
                            </div>
                            <!-- <div class="col-md-4">
                                <span style="float: right; color:rgb(240, 76, 103)">#3010</span>
                            </div>  -->
                            <div class="col-md-4">
                                <div *ngIf="item.employee?.employeeNumber" style="float: right; background-color: rgb(248, 199, 207); color:rgb(250, 84, 112); padding: 5px;">
                                #{{item.employee?.employeeNumber}}
                                </div>
                            </div>
                        </div>
    
                        <div class="row" style="display: flex;">
                            <div class="col-md-12">
                                <small *ngIf="item.approvals?.approver.approverOneObject?.firstName" >Reviewer:<span style="color:black ; font-weight: 500;">  {{item.approvals?.approver?.approverOneObject?.firstName}}</span>
                                </small>
                            </div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-md-4">
                                <small *ngIf="item?.employeeReviewRating" >Emp Rating:<span style="color:black; font-weight: 500;">{{item?.employeeReviewRating}}</span></small>
                            </div>
                            <div class="col-md-5" style="margin-left: -5%;">
                                <small *ngIf="item?.managementRating" >Approver Rating:<span style="color:black; font-weight: 500;">{{item?.managementRating}}</span></small>
                            </div>
                            <div class="col-md-3" style="white-space: nowrap;margin-left: -5%;">
                                <small *ngIf="item?.managementTotalRating" >Final Rating:<span style="color:black; font-weight: 500;">{{item?.managementTotalRating}}</span></small>
                            </div>
                        </div>
                        <!-- <div class=" rating-container d-flex align-items-center">
                            <small class="rating-item" style="padding-right: 10px;">Emp Rating:<span style="color:black;"> {{item.employeeReviewRating}}</span></small>
                            <small class="rating-item" style="padding-right: 10px;">Approver Rating: <span style="color:black;">{{item.managementRating}}</span></small>
                            <small class="rating-item">Final Rating: <span style="color:black;">{{item.managementTotalRating}}</span></small>
                        </div> -->
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-md-7">
                                <small *ngIf="item?.todayDate" >Submitted On:<span style="color:black; font-weight: 500;">{{item?.todayDate}}</span></small>
                            </div>
                            <div class="col-md-5">
                                <small *ngIf="item?.apprisalStatus?.listTypeValueName"  style="float: right;">Status:<span style="color: red; font-weight: 600;">{{item.apprisalStatus?.listTypeValueName}}</span></small>
                            </div>
                        </div>
                        <div *ngIf="noPendingdata">
                            <h3 class="text-center"  style="font-weight: 500; font-size: x-large; margin-top: 100px;">NO DATA</h3>
                        </div>
                        <hr *ngIf="!noPendingdata">
                    </div>
                    </div>
                    
                
                   
                
            
    
            </div>
            
    
        </div>
    
        <div class="col-md-4">
            <div class="card1 scrollable-content" style=" background-color: white;" >
                <div class="row" style="position: absolute;  background-color: white; z-index:9999; width: 92%; padding: 1%; margin-left: 0.5%; padding-top:2%">
                    <div class="col-md-9" >
                        <h4 style="font-weight: 600; font-size: 16px;">Reviewed Appraisal ({{reviewedCount}})</h4>
                    </div>
                    <!-- <div class="col-md-3">
                        <span style="float: right; color:#6583EF ;" (click)="viewReviwed()">View all</span>
                    </div>  -->
                    <div class="col-md-3">
                        <span 
                    style="float: right; cursor: pointer;" 
                    [ngStyle]="{ 'color': noRevieweddata ? '#A9A9A9' : '#6583EF' }"
                    [ngClass]="{ 'disabled-link': noRevieweddata }"
                    (click)=" !noRevieweddata && viewReviwed()"
                >
                    View all
                </span>
                    </div>
                </div> <br><br>
    
            <div >
                <div class="list" style="padding:5%; margin-bottom: -10%;" *ngFor="let row of listData1">
                    <div class="row">
                        <div class="col-md-8">
                            <p ><span style="color:black; font-weight: 500;">{{row.employee?.firstName}} {{row.employee?.lastName}}</span></p>
                        </div>
                        <!-- <div class="col-md-4">
                            <span style="float: right; color:rgb(240, 76, 103)">#3010</span>
                        </div>  -->
                        <div class="col-md-4">
                            <div *ngIf="row.employee?.employeeNumber" style="float: right; background-color: rgb(248, 199, 207); color:rgb(250, 84, 112); padding: 5px;">
                                #{{row.employee?.employeeNumber}}
                            </div>
                        </div>
                    </div>
    
                    <div class="row" style="display: flex;">
                        <div class="col-md-12">
                            <small *ngIf="row.approvals?.approver.approverOneObject?.firstName">Reviewer:<span style="color:black; font-weight: 500;">  {{row.approvals?.approver?.approverOneObject?.firstName}}</span></small>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 5px;">
                        <div class="col-md-4">
                            <small *ngIf="row?.employeeReviewRating">Emp Rating:<span style="color:black; font-weight: 500;">{{row?.employeeReviewRating}}</span></small>
                        </div>
                        <div class="col-md-5" style="margin-left: -6%;">
                            <small *ngIf="row?.managementRating">Approver Rating:<span style="color:black; font-weight: 500;">{{row?.managementRating}}</span></small>
                        </div>
                        <div class="col-md-3" style="white-space: nowrap;margin-left: -5%;">
                            <small *ngIf="row?.managementTotalRating">Final Rating:<span style="color:black; font-weight: 500;">{{row?.managementTotalRating}}</span></small>
                        </div>
                    </div>
                    <!-- <div class=" rating-container d-flex align-items-center" >
                        <small class="rating-item" style="padding-right: 10px;">Emp Rating:<span style="color:black"> {{row.employeeReviewRating}}</span></small>
                        <small class="rating-item" style="padding-right: 10px;">Approver Rating: <span style="color:black">{{row.managementRating}}</span></small>
                        <small class="rating-item">Final Rating: <span style="color:black">{{row.managementTotalRating}}</span></small>
                    </div> -->
                    <div class="row" style="padding-top: 5px;">
                        <div class="col-md-7">
                            <small *ngIf="row?.todayDate">Submitted On:<span style="color:black; font-weight: 500;">{{row?.todayDate}}</span></small>
                        </div>
                        <div class="col-md-5">
                            <small *ngIf="row?.apprisalStatus?.listTypeValueName" style="float: right;">Status:<span style="color:orange; font-weight: 600;">{{row.apprisalStatus?.listTypeValueName}}</span></small>
                        </div>
                    </div>
                    <div *ngIf="noRevieweddata">
                        <h3 class="text-center" style="font-weight: 500; font-size: x-large; margin-top:100px;">NO DATA</h3>
                    </div>
                    <hr *ngIf="!noRevieweddata">
                </div>
                </div>
            
    
            </div>
            
    
        </div>
    
        <div class="col-md-4">
            <div class="card1 scrollable-content" style=" background-color: white;">
                <div class="row" style="position: absolute; background-color: white; z-index:9999;width: 92%; padding: 1%; margin-left: 0.5%; padding-top:2%">
                    <div class="col-md-9">
                        <h4 style="font-weight: 600; font-size: 16px;">Closed Appraisal ({{closedCount}})</h4>
                    </div>
                    <!-- <div class="col-md-3">
                        <span style="float: right; color:#6583EF ;" (click)="viewclosed()">View all</span>
                    </div>  -->
                    <div class="col-md-3">
                        <span 
                    style="float: right; cursor: pointer;" 
                    [ngStyle]="{ 'color': noCloseddata ? '#A9A9A9' : '#6583EF' }"
                    [ngClass]="{ 'disabled-link': noCloseddata }"
                    (click)="!noCloseddata && viewclosed()"
                >
                    View all
                </span>
                    </div>
                    
                </div> <br><br>
    
            <div >
                <div class="list" style="padding:5%; margin-bottom: -10%;" *ngFor="let col of listData2">
                    <div class="row">
                        <div class="col-md-8">
                            <p ><span style="color:black; font-weight: 500;">{{col.employee?.firstName}} {{col.employee?.lastName}}</span></p>
                        </div>
                        <!-- <div class="col-md-4">
                            <span style="float: right; color:rgb(240, 76, 103)">#3010</span>
                        </div>  -->
                        <div class="col-md-4">
                            <div *ngIf="col.employee?.employeeNumber" style="float: right; background-color: rgb(248, 199, 207); color:rgb(250, 84, 112); padding: 5px;">
                                #{{col.employee?.employeeNumber}}
                            </div>
                        </div>
                    </div>
    
                    <div class="row" style="display: flex;">
                        <div class="col-md-12">
                            <small *ngIf="col.approvals?.approver.approverOneObject?.firstName">Reviewer:<span style="color:black; font-weight: 500;">   {{col.approvals?.approver?.approverOneObject?.firstName}}</span></small>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 5px;">
                        <div class="col-md-4">
                            <small *ngIf="col?.employeeReviewRating">Emp Rating:<span style="color:black; font-weight: 500;">{{col?.employeeReviewRating}}</span></small>
                        </div>
                        <div class="col-md-5" style="margin-left: -5%;">
                            <small *ngIf="col?.managementRating" >Approver Rating:<span style="color:black; font-weight: 500;">{{col?.managementRating}}</span></small>
                        </div>
                        <div class="col-md-3" style="white-space: nowrap;margin-left: -6%;">
                            <small  *ngIf="col?.managementTotalRating">Final Rating:<span style="color:black; font-weight: 500;"> {{col?.managementTotalRating}}</span></small>
                        </div>
                    </div>
                    <!-- <div class="rating-container d-flex align-items-center">
                        <small class="rating-item" style="padding-right: 10px;">Emp Rating:<span style="color:black"> {{col.employeeReviewRating}}</span></small>
                        <small class="rating-item" style="padding-right: 10px;">Approver Rating:<span style="color:black">{{col.managementRating}}</span></small>
                        <small class="rating-item">Final Rating:<span style="color:black"> {{col.managementTotalRating}}</span></small>
                    </div> -->
                    <div class="row" style="padding-top: 5px;">
                        <div class="col-md-7">
                            <small *ngIf="col?.todayDate">Submitted On:<span style="color:black; font-weight: 500;">{{col?.todayDate}}</span></small>
                        </div>
                        <div class="col-md-5">
                            <small *ngIf="col?.apprisalStatus?.listTypeValueName"  style="float: right;">Status:<span style="color:lightgreen; font-weight: 600;">{{col.apprisalStatus?.listTypeValueName}}</span></small>
                        </div>
                    </div>
                    <div *ngIf="noCloseddata">
                        <h3 class="text-center"  style="font-weight: 500; font-size: x-large; margin-top: 100px;">NO DATA</h3>
                    </div>
                    <hr *ngIf="!noCloseddata">
                </div>
            </div>
            
    
            </div>
            
    
        </div>
    
    
        
    </div>
    
    <div  *ngIf="loader" class="align-items-center text-center">
        <div class="spinner-border table-spinner" style="height: 3rem;width: 3rem;" role="status"></div>
    </div>
    
    
    
    </div>
    