import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeaveService } from 'src/app/providers/leave.service';

@Component({
  selector: 'app-datadialogexampledialog',
  templateUrl: './datadialogexampledialog.component.html',
  styleUrls: ['./datadialogexampledialog.component.scss']
})
export class DatadialogexampledialogComponent implements OnInit {
  isData: boolean;
  x = this.data;
  loader=true;
  displayedColumns: string[] = ['leaveRequestId'];
  dataSource = this.data;
  leave_list_ofEmp: any = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: any[],
  private leaveservice : LeaveService
) {
  }

  ngOnInit(): void {
    console.log(this.data)
    const id=this.data
     this.loader=true;
      this.leaveservice.getMyleave(this.data).subscribe((a: any) => {

        
        this.leave_list_ofEmp=a;
        this.loader = false;
      },
      (error) => {
        console.error(error);
        this.loader = false; // Stop loader even if there's an error
      }
    )
   
  }

  test(i) {
    if (i.leaveStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.leaveStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.leaveStatusName == 'Withdrawn') {
      return '#20B2AA'
    }
    if (i.leaveStatusName == 'Cancelled') {
      return '#e40000'
    }
  }
}
