import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { EmployeeService } from '../../providers/employee.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../providers/settings.service'; //,filterSearchRequired
import {  httpOptions, responseMsgTimeOut, redirectMsgTimeOut } from '../../providers/properties';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';
// import { ImportEmployeeComponent } from '../../project-management/import-employee/import-employee.component';
import { MatclientcomponentsComponent } from '../matclientcomponents/matclientcomponents.component'
import { Sort } from '@angular/material/sort';
//import { NgSelect2Module } from 'select';
import { takeUntil, take } from 'rxjs/operators';

//import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables

import { AppComponent } from '../../app.component';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ReplaySubject, Subject } from 'rxjs';
import { PayrollService } from 'src/app/providers/payroll.service'
import { Router } from '@angular/router';
import { ImportEmployeeComponent } from '../../project-management/project-management/import-employee/import-employee.component';
import { HeaderService } from 'src/app/providers/header.service';
import { EmployeeErrorComponent } from 'src/app/employee-error/employee-error.component';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})

export class EmployeeComponent implements OnInit {
  public searchFilter: any = '';
  employeetData: any = '';
  pageViews: any = 1; //Employee Profile Tabs Default My Profile 1
  //dateFormat = GlobalConstants.dateFormat;
  dateFormat: any;
  organizationId: any;
  public entityFilterCtrl: FormControl = new FormControl();
  fileblob: any; data
  fileblob1: any;
  searchForm!: FormGroup;
  errormessagejson: any;
  errorMessageFromjson: any;
  importerror: boolean;
  public filteredEntities: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  userdata: any;
  mySelect: any = 5;
  search: any;
  nodata: boolean = false;
  entityId: any;
  clientId:any

  constructor(
    private payrollService: PayrollService,
    private settingsservice: SettingsService,
    private employee_service: EmployeeService,
    public ExcelService: ExcelService,
    public datepipe: DatePipe,
    public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private elementRef: ElementRef,

    @Inject(AppComponent) public AppData: any,
    private headerservice: HeaderService

  ) { }

  resultData: any = {};
  p: number = 1;
  collection: any[];
  dataExport = [];
  exported: any;
  exportingdata: any;
  pagePerItem: any = 5;
  tableSearch: any;
  employeeName: any;
  Role: any;
  EmployeeNumber: any;
  MobileNumber: any;
  Email: any;
  DateofBirth: any;
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  department: any;
  bankAccountNo: any;
  accountName: any;
  bankName: any;
  bankBranchName: any;
  ifscCode: any;
  bankAddress: any;

  countryName: any;
  stateName: any;
  districtName: any;

  employmentType: any;
  bloodGroup: any;
  gender: any;
  designation: any;

  loader: any = false;
  backdrop: any = false;
  nodatafound: boolean = false;
  activeRolesData: any;
  elementlist: any = [];
  /// public activeRolesData: Array<NgSelect2Module>;
  searchTerm: any;
  cityName: any;
  sortedData: any[];
  empList: any = [];
  max: any;
  employeePfAmount: any;
  employerPfAmount: any;
  maxesiamount: any;
  employeeEsiAmount: any;
  employerEsiAmount: any;
  clientemployee: any;
  annexuretype: any;
  clientelements: any;
  maxpfamount: any;
  clientList: any=[];
  showtable: any = false;

  /* private _value: string;
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    console.log('set value with ' + value);
    this._value = value;
  }
 */
  private _onDestroy = new Subject<void>();
  ngOnInit(): void {

    this.headerservice.setTitle('');
    this.dateFormat = localStorage.getItem('dateFormatName');
    // this.organizationId = this.AppData.organizationId;
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = this.userdata.organization.organizationId;
    this.entityId = this.userdata.entity.entityId;
    this.clientId=this.userdata?.employee?.client?.clientId;
    console.log(this.entityId)



if(this.organizationId){
this.payrollService.getClientNameList(this.organizationId).subscribe((result: any) => {
      // this.clientList = result.filter(client => client.status.listTypeValueId == 1);
      console.log(this.clientList);
      this.clientList=result;
      this.clientList=Array.isArray(result) ? result: [result];
    })
}
    

    //this.getEmployeeList();
    this.getFilterFormData();
    this.getActiveRoles();

    this.entityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEntities();
      });
  }

  setRoleValues() {
    this.searchForm.controls['roleId'].setValue(null);
  }

  getActiveRoles() {
    if( this.entityId && this.organizationId==undefined && this.clientId==undefined){
      console.log("entity login role here!!!");
      
      this.employee_service.getActivesuperadminRoles().subscribe((result: any) => {
        this.activeRolesData = result;
        this.activeRolesData = Array.isArray(result) ? result: [];
  
      })
    }
    else if(this.clientId && this.organizationId==undefined ){
      this.employee_service.getActivesClientRoles(this.clientId).subscribe((result: any) => {
        this.activeRolesData = result;
        this.activeRolesData = Array.isArray(result) ? result: [];
  
      })

      console.log("client login role here!!!");
    }
    else{
      if( this.organizationId && this.clientId==undefined){
        this.employee_service.getActivesOrganizationRoles(this.organizationId).subscribe((result: any) => {
          this.activeRolesData = result;
          this.activeRolesData = Array.isArray(result) ? result: [];
    
        })
        console.log("organization login role here!!!");
      }   
     }

    }
   
  

  roleserach(event) {

  }

  roleassign() {

  }
  /*  public changeValue() {
     this.value = 'car2';
   }
 
   public changeData() {
     this.employee_service.getActiveRoles().subscribe((result: any) => {
       this.activeRolesData = result;
     })
   }
 
   public changePlaceholder() {
     this.placeholder = 'placeholder2';
   }
  */
  getFilterFormData() {
    /* this.searchForm = new FormGroup(
      {
        keywords : new FormControl(this.searchForm),
        roleId : new FormControl(this.searchForm)
      }
    ); */

    this.searchForm = this.formBuilder.group({
      keywords: [''],
      roleId: [null],
      // client: [null]
    });
  }
  changefirstpage() {
    this.p = 1;
  }
  searchSubmit() {

    this.p = 1;
    this.showtable = false;
    this.nodatafound = false;
    let organizationIds = this.organizationId;
    let clientIds=this.clientId
    let entityIds=this.entityId

    let keywords = this.searchForm.value.keywords;

    let roleId = this.searchForm.value.roleId;

    console.log("organizationIds::",organizationIds , "clientIds::",clientIds ,"roleId::" ,roleId ,"keywords::",keywords ,"enity:",this.entityId)


    console.log('roleId', roleId);
    console.log('keywords', keywords);
    let client = this.searchForm.value.client
    console.log("this.searchForm", this.searchForm.value);
    if ((keywords === null || keywords === 'null' || keywords === '') && (roleId === null || roleId === 'null')) {
      alert('Please Choose Any Field to Search');
      console.log("null");
      return;
    }

    // if(organizationIds){
    //   if (keywords && roleId && client == null ) {

    //     alert('Please choose a client or remove the role, or search for a client only.');
    //     // this.loader=true;
    //     // this.backdrop=true;
  
  
    //   }
    //   return
    // }

  

    // this.resultData.length = 0;

    
    //  if(keywords&& (organizationIds !== undefined) ) {
    //   console.log("Condition: Only Employee");
    //   this.loader = true;
    //   this.backdrop = true;
  
    //   this.employee_service.getEmployeeListKeywords(organizationIds, keywords).subscribe((resultData: any) => {
    //     console.log("Only Employee Result", resultData);
    //     this.loader = false;
    //             this.backdrop = false;
    //             this.nodatafound = false;
    //             this.showtable = true;
    //             this.resultData = resultData;
        
    //             if (this.resultData.length == 0 || this.resultData.length == undefined) {
    //               this.nodatafound = true;
    //             }
    //   });
    // }
  // else  if(keywords&& (clientIds !== undefined) ) {
  //     console.log("Condition: Only Employee");
  //     this.loader = true;
  //     this.backdrop = true;
  
  //     this.employee_service.getEmployeeListKeywordsbyClient(keywords,clientIds).subscribe((resultData: any) => {
  //       console.log("Only Employee Result", resultData);
  //       this.loader = false;
  //               this.backdrop = false;
  //               this.nodatafound = false;
  //               this.showtable = true;
  //               this.resultData = resultData;
        
  //               if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //                 this.nodatafound = true;
  //               }
  //     });
  //   }
  
//  else if (keywords && (roleId && roleId != 'null') && (client && client != 'null')) {
//       console.log("both");
//       this.loader = true;
//       this.backdrop = true;

//       this.employee_service.getSearchEmployeeList(organizationIds, keywords, roleId, client).subscribe((resultData: any) => {
//         this.loader = false;
//         this.backdrop = false;
//         this.nodatafound = false;
//         this.showtable = true;
//         this.resultData = resultData;

//         if (this.resultData.length == 0 || this.resultData.length == undefined) {

//           this.nodatafound = true;
//         }
//       })
//     }
  //  else if (keywords && (!roleId || roleId == 'null')&& (this.entityId || this.entityId !=undefined || this.entityId !='null') && (this.organizationId == undefined )) {
  //     console.log(roleId)
  //     console.log("employee code based on entity")
  //     this.loader = true;
  //     this.backdrop = true;
  //     console.log("Keyword");
  //     this.employee_service.getEmployeeListKeywordsEntity(this.entityId, keywords).subscribe((resultData: any) => {
  //       console.log("only employee", resultData);
  //       this.loader = false;
  //       this.backdrop = false;
  //       this.nodatafound = false;
  //       this.showtable = true;
  //       this.resultData = resultData;

  //       if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //         this.nodatafound = true;
  //       }
  //     })
  //   }
  //   else if (!keywords && (roleId && roleId != 'null') && (organizationIds == undefined) && (this.entityId || this.entityId !=undefined || this.entityId !='null')) {
  //     this.loader = true;
  //     this.backdrop = true;
  //     console.log("Role based on entityId");
  //     this.employee_service.getEmployeeListRoleEntity(this.entityId, roleId).subscribe((resultData: any) => {
  //       this.loader = false;
  //       this.backdrop = false;
  //       this.nodatafound = false;
  //       this.showtable = true;
  //       this.resultData = resultData;

  //       if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //         this.nodatafound = true;
  //       }
  //     })
  //   }
  // else if(!keywords && (roleId && roleId != 'null') && (organizationIds!== undefined) && (this.entityId || this.entityId ==undefined || this.entityId =='null')) {
  //     console.log("Condition: Only Employee , by organ calling!!!");
  //     this.loader = true;
  //     this.backdrop = true;
  
  //     this.employee_service.getEmployeeListKeywords(keywords,organizationIds).subscribe((resultData: any) => {
  //       console.log("Only Employee Result", resultData);
  //       this.loader = false;
  //               this.backdrop = false;
  //               this.nodatafound = false;
  //               this.showtable = true;
  //               this.resultData = resultData;
        
  //               if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //                 this.nodatafound = true;
  //               }
  //     });
  //   }
  //   else if(!keywords && (roleId && roleId != 'null') && (organizationIds!== undefined) && (this.entityId || this.entityId ==undefined || this.entityId =='null')) {
  //     console.log("Condition: Only Employee , by client calling calling!!!");
  //     this.loader = true;
  //     this.backdrop = true;
  
  //     this.employee_service.getEmployeeListKeywordsbyClient(keywords,clientIds).subscribe((resultData: any) => {
  //       console.log("Only Employee Result", resultData);
  //       this.loader = false;
  //               this.backdrop = false;
  //               this.nodatafound = false;
  //               this.showtable = true;
  //               this.resultData = resultData;
        
  //               if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //                 this.nodatafound = true;
  //               }
  //     });
  //   }


  //   else if (!keywords && (roleId && roleId != 'null') && (organizationIds!= undefined)) {
  //     this.loader = true;
  //     this.backdrop = true;
  //     console.log("Role");
  //     this.employee_service.getEmployeeListRole(organizationIds, roleId).subscribe((resultData: any) => {
  //       this.loader = false;
  //       this.backdrop = false;
  //       this.nodatafound = false;
  //       this.showtable = true;
  //       this.resultData = resultData;

  //       if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //         this.nodatafound = true;
  //       }
  //     })
  //   }
    // else if (!keywords && !roleId && (client && client != 'null')) {
      // this.loader = true;
      // this.backdrop = true;
      // console.log("Client");
      // this.employee_service.getEmployeeListClient(organizationIds, client).subscribe((resultData: any) => {
      //   this.loader = false;
      //   this.backdrop = false;
      //   this.nodatafound = false;
      //   this.showtable = true;
      //   this.resultData = resultData;

      //   if (this.resultData.length == 0 || this.resultData.length == undefined) {
      //     this.nodatafound = true;
      //   }
      // })
    // }
    // else if(keywords&&(roleId!=='null'||!roleId)){
    //   console.log("employee & role")
    //   if(this.entityId || this.entityId !=undefined || this.entityId !='null'){
    //     this.loader = true;
    //     this.backdrop = true;
    //     console.log("Role");
    //     this.employee_service.getEmployeeRolebyEntity(keywords,roleId,this.entityId ).subscribe((resultData: any) => {
    //       this.loader = false;
    //       this.backdrop = false;
    //       this.nodatafound = false;
    //       this.showtable = true;
    //       this.resultData = resultData;
  
    //       if (this.resultData.length == 0 || this.resultData.length == undefined) {
    //         this.nodatafound = true;
    //       }
    //     })
    //   }
     

    //   return
    // }



    if (clientIds !== undefined && clientIds !== null) {
      // Client is present, call client-based logic
      console.log("Call client-based logic");
      this.callClientLogic(clientIds, keywords, roleId);
    } else if (organizationIds !== undefined && organizationIds !== null) {
      // Organization is present, call organization-based logic
      console.log("Call organization-based logic");
      this.callOrganizationLogic(organizationIds, keywords, roleId);
    } else if (entityIds !== undefined && entityIds !== null) {
      // Only entity is present, call entity-based logic
      console.log("Call entity-based logic");
      this.callEntityLogic(entityIds, keywords, roleId);
    } else {
      console.log("No valid data found for client, organization, or entity.");
    }
}

  
callClientLogic(clientId: any, keywords: any, roleId: any) {
  console.log("Executing client-based search", { clientId, keywords, roleId });
  // Add your client-specific API call or logic here
  if (keywords && !roleId) {
    // Scenario 1: Only keywords
    console.log("Scenario: Only keywords");
        this.loader = true;
      this.backdrop = true;
  
      this.employee_service.getEmployeeListKeywordsbyEmployee(keywords,clientId).subscribe((resultData: any) => {
        console.log("Only Employee Result", resultData);
        this.loader = false;
                this.backdrop = false;
                this.nodatafound = false;
                this.showtable = true;
                this.resultData = resultData;
        
                if (this.resultData.length == 0 || this.resultData.length == undefined) {
                  this.nodatafound = true;
                }
      });
  } else if (!keywords && roleId) {
    // Scenario 2: Only role
    console.log("Scenario: Only role");
    this.loader = true;
    this.backdrop = true;

    this.employee_service.getEmployeeListKeywordsbyRole(roleId,clientId).subscribe((resultData: any) => {
      console.log("Only Employee Result", resultData);
      this.loader = false;
              this.backdrop = false;
              this.nodatafound = false;
              this.showtable = true;
              this.resultData = resultData;
      
              if (this.resultData.length == 0 || this.resultData.length == undefined) {
                this.nodatafound = true;
              }
    });
    
  } else if (keywords && roleId) {
    // Scenario 3: Both keywords and role
    console.log("Scenario: Both keywords and role");

    this.loader = true;
      this.backdrop = true;
  
      this.employee_service.getEmployeeListbKeywordsRolebyClient(keywords,roleId,clientId).subscribe((resultData: any) => {
        console.log("Only Employee Result", resultData);
        this.loader = false;
                this.backdrop = false;
                this.nodatafound = false;
                this.showtable = true;
                this.resultData = resultData;
        
                if (this.resultData.length == 0 || this.resultData.length == undefined) {
                  this.nodatafound = true;
                }
      });
  } 
  else {
    console.log("No valid input for client-based search.");
    this.loader = false;
    this.backdrop = false;
  }
}

callOrganizationLogic(organizationId: any, keywords: any, roleId: any) {
  console.log("Executing organization-based search", { organizationId, keywords, roleId });
  // Add your organization-specific API call or logic here
  if (keywords && !roleId) {
    // Scenario 1: Only keywords
    console.log("Scenario: Only keywords");
        this.loader = true;
      this.backdrop = true;
  
      this.employee_service.getEmployeeListKeywords(keywords,organizationId).subscribe((resultData: any) => {
        console.log("Only Employee Result", resultData);
        this.loader = false;
                this.backdrop = false;
                this.nodatafound = false;
                this.showtable = true;
                this.resultData = resultData;
        
                if (this.resultData.length == 0 || this.resultData.length == undefined) {
                  this.nodatafound = true;
                }
      });
   
  } else if (!keywords && roleId) {
    // Scenario 2: Only role
    console.log("Scenario: Only role");
         this.loader = true;
      this.backdrop = true;
      console.log("Role");
      this.employee_service.getEmployeeListRole(organizationId, roleId).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.showtable = true;
        this.resultData = resultData;
        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
        }
      })
      
   
  } else if (keywords && roleId) {
    // Scenario 3: Both keywords and role
    console.log("Scenario: Both keywords and role");

    this.loader=true;
    this.employee_service.getEmployeeRolebyOrganization(keywords,roleId,organizationId).subscribe((resultData:any)=>{
      this.loader = false;
      this.backdrop = false;
      this.nodatafound = false;
      this.showtable = true;
      this.resultData = resultData;
      if (this.resultData.length == 0 || this.resultData.length == undefined) {
        this.nodatafound = true;
      }
    })
   
  } 
  else {
    console.log("No valid input for client-based search.");
    this.loader = false;
    this.backdrop = false;
  }
}

callEntityLogic(entityId: any, keywords: any, roleId: any) {
  console.log("Executing entity-based search", { entityId, keywords, roleId });
  // Add your entity-specific API call or logic here
  if (keywords && !roleId) {
    // Scenario 1: Only keywords
    console.log("Scenario: Only keywords");
        this.loader = true;
      this.backdrop = true;
      console.log("Keyword");
      this.employee_service.getEmployeeListKeywordsEntity(entityId, keywords).subscribe((resultData: any) => {
        console.log("only employee", resultData);
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.showtable = true;
        this.resultData = resultData;

        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
        }
      })
   
  } else if (!keywords && roleId) {
    // Scenario 2: Only role
    console.log("Scenario: Only role");
    this.loader = true;
    this.backdrop = true;
    console.log("Role based on entityId");
    this.employee_service.getEmployeeListRoleEntity(entityId, roleId).subscribe((resultData: any) => {
      this.loader = false;
      this.backdrop = false;
      this.nodatafound = false;
      this.showtable = true;
      this.resultData = resultData;

      if (this.resultData.length == 0 || this.resultData.length == undefined) {
        this.nodatafound = true;
      }
    })
   
  } else if (keywords && roleId) {
    // Scenario 3: Both keywords and role
    console.log("Scenario: Both keywords and role");
         this.loader = true;
        this.backdrop = true;
        console.log("Role");
        this.employee_service.getEmployeeRolebyEntity(keywords,roleId,entityId ).subscribe((resultData: any) => {
          this.loader = false;
          this.backdrop = false;
          this.nodatafound = false;
          this.showtable = true;
          this.resultData = resultData;
  
          if (this.resultData.length == 0 || this.resultData.length == undefined) {
            this.nodatafound = true;
          }
        })
  
  } 
  else {
    console.log("No valid input for client-based search.");
    this.loader = false;
    this.backdrop = false;
  }
}

  getEmployeeList() {
    this.loader = true;
    this.backdrop = true;

    let organizationId = this.organizationId;
    let keywords = this.searchForm.value.keywords;
    let roleId = this.searchForm.value.roleId;
    let client = this.searchForm.value.client;
    if (keywords && (roleId && roleId != 'null') && (client && client != 'null')) {
      this.loader = true;
      this.backdrop = true;

      this.employee_service.getSearchEmployeeList(organizationId, keywords, roleId, client).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.showtable = true;
        this.resultData = resultData;
        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
        }
      })
    }
    else if (keywords && (!roleId || roleId == 'null')) {
      this.loader = true;
      this.backdrop = true;

      this.employee_service.getEmployeeListKeywords(organizationId, keywords).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.showtable = true;
        this.resultData = resultData;

        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
        }
      })
    }
    else if (!keywords && (roleId && roleId != 'null')) {
      this.loader = true;
      this.backdrop = true;

      this.employee_service.getEmployeeListRole(organizationId, roleId).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.showtable = true;
        this.resultData = resultData;

        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
        }
      })
    }
    else if (!keywords && !roleId && (client && client != 'null')) {
      this.loader = true;
      this.backdrop = true;
      console.log("Client");
      this.employee_service.getEmployeeListClient(organizationId, client).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.showtable = true;
        this.resultData = resultData;

        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
        }
      })
    }
    else {
      this.employee_service.getEmployeeList(organizationId).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.showtable = true;
        this.resultData = resultData;

        //ProfileImg start here
        var profileImg = [];
        resultData.forEach(elem => {
          if (elem.employeeAttachment) {
            this.employee_service.getemployeeImage(elem.employeeAttachment.fileViewUrl).subscribe(blob => {
              const a = document.createElement('a')
              const objectUrl: any = blob

              if (blob) {
                profileImg.push(blob);
              }
              this.fileblob = profileImg;
              //this.fileblob = blob;
            },
              err => {
                //alert("asdfasdf");
              }
            );
          }
        });
        //ProfileImg end here


      }, err => {
        //this.errorMsg = err.error.message;
      })
    }
  }

  getclientEmployees() {
    this.empList.length = 0;
    console.log(this.searchForm.value.client)
    if (this.searchForm.value.client != null && this.searchForm.value.client != "null") {
      this.payrollService.getAllEmployeeClientList(this.searchForm.value.client).subscribe((ClientEmployees: any) => {
        if (ClientEmployees.length != undefined) {
          // ClientEmployees.forEach(element => {
          //   this.empList.push(element.employee);
          // });
        }

      });

      this.payrollService.clientelements(this.searchForm.value.client).subscribe((result: any) => {
        if (result.statusCode) {
          this.elementlist.length = 0;
        } else {
          console.log("else")
          this.clientelements = result;
          this.maxpfamount = this.clientelements[0].pfMaxSalaryAmount;
          this.employeePfAmount = this.clientelements[0].employeePfAmount;
          this.employerPfAmount = this.clientelements[0].employerPfAmount;

          this.maxesiamount = this.clientelements[0].esiMaxSalaryAmount;
          this.employeeEsiAmount = this.clientelements[0].employeeEsiAmount;
          this.employerEsiAmount = this.clientelements[0].employerEsiAmount;

          //     result.forEach(cleintelemets => {
          //       cleintelemets.lines.forEach(lines => {
          //           this.elementlist.push(lines.element);
          //       });

          //     });

          // this.filterele =  this.elementlist.filter(ele=> ele.elementName.includes( "Basic" ) ||  ele.elementName.includes( "HRA" ) );
          // this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
          //        console.log(this.filterele);

          // const control = this.postForm.get('tableRows') as FormArray;

          // console.log("this.filterele",this.filterele);
          // this.filterele.forEach((element ,index) => {
          //          this.addRow();
          //           if( element.elementName != "HRA")
          //           {
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,
          //               hrElementPercentage :  element.percentage ? element.percentage.elementPercentageId : 0,
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }else{
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,                  
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }
          //     });
          //  console.log(control); 
        }

      })
    }


  }
  updateStatus(id, status) {
    this.employee_service.updateEmployeeStatus(id, status).subscribe((result: any) => {
      console.log(result);
      if (result.statusCode == 200) {
        this.loader = true
        this.showtable = false;
        // this.success = true;
        // this.sucess_msg = result.message;
        setTimeout(() => { this.loader = false }, redirectMsgTimeOut)

        this.searchSubmit();
      }
      else {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }
    })
  }

  //tableView Profile Tabs click
  tableView(val) //Grid, List & Card View
  {
    this.pageViews = val;
    console.log(" this.pageViews::", this.pageViews);

    // this.showtable = this.pageViews === 1; 
    // this.nodatafound = this.resultData.length == 0 ;

  }

  exportToExcel() {

    let keywords = this.searchForm.value.keywords;
    let roleId = this.searchForm.value.roleId;

    if (keywords && roleId) {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData;

      for (let exportingdata of this.exportingdata) {
        let dob = this.datepipe.transform(exportingdata.dateOfBirth, 'dd-MMM-yyyy')
        let createDateTime = this.datepipe.transform(exportingdata.createDateTime, 'dd-MMM-yyyy')
        let dateOfJoining = this.datepipe.transform(exportingdata.dateOfJoining, 'dd-MMM-yyyy')

        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.city) {
          this.cityName = exportingdata.employeeInfo.city.cityName;
        } else {
          this.cityName = "";
        }

        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.country) {
          this.countryName = exportingdata.employeeInfo.country.countryName;
        } else {
          this.countryName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.state) {
          this.stateName = exportingdata.employeeInfo.state.stateName;
        } else {
          this.stateName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.district) {
          this.districtName = exportingdata.employeeInfo.district.districtName;
        } else {
          this.districtName = "";
        }

        if (exportingdata.employmentType != undefined && exportingdata.employmentType.listTypeValueName) {
          this.employmentType = exportingdata.employmentType.listTypeValueName;
        } else {
          this.employmentType = "";
        }

        if (exportingdata.bloodGroup != undefined && exportingdata.bloodGroup.listTypeValueName) {
          this.bloodGroup = exportingdata.bloodGroup.listTypeValueName;
        } else {
          this.bloodGroup = "";
        }

        if (exportingdata.gender != undefined && exportingdata.gender.listTypeValueName) {
          this.gender = exportingdata.gender.listTypeValueName;
        } else {
          this.gender = "";
        }

        if (exportingdata.designation != undefined && exportingdata.designation.designationName) {
          this.designation = exportingdata.designation.designationName;
        } else {
          this.designation = "";
        }
        if (exportingdata.department != undefined && exportingdata.department.departmentName) {
          this.department = exportingdata.department.departmentName;
        } else {
          this.department = "";
        }

        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankAccountNo) {
          this.bankAccountNo = exportingdata.employeeInfo.bankAccountNo;
        } else {
          this.bankAccountNo = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.accountName) {
          this.accountName = exportingdata.employeeInfo.accountName;
        } else {
          this.accountName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankName) {
          this.bankName = exportingdata.employeeInfo.bankName;
        } else {
          this.bankName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankBranchName) {
          this.bankBranchName = exportingdata.employeeInfo.bankBranchName;
        } else {
          this.bankBranchName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.ifscCode) {
          this.ifscCode = exportingdata.employeeInfo.ifscCode;
        } else {
          this.ifscCode = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankAddress) {
          this.bankAddress = exportingdata.employeeInfo.bankAddress;
        } else {
          this.bankAddress = "";
        }
        this.exported = {
          EmployeeRole: exportingdata.role.roleName,
          EmployeeNo: exportingdata.employeeNumber,
          EmployeeName: exportingdata.firstName + " " + exportingdata.lastName,
          MobileNumber: exportingdata.mobileNumber,
          AlternateMobileNumber: exportingdata.alternativeMobileNumber,
          Email: exportingdata.organizationEmail,
          DateofBirth: dob,
          Gender: this.gender,
          BloodGroup: this.bloodGroup,
          EmploymentType: this.employmentType,
          DesignationName: this.designation,
          DepartmentName: this.department,
          DateOfJoining: dateOfJoining,
          BankAccountNo: this.bankAccountNo,
          AccountHolderName: this.accountName,
          BankName: this.bankName,
          BankBranchName: this.bankBranchName,
          IFSCCode: this.ifscCode,
          BankAddress: this.bankAddress,

          CountryName: this.countryName,
          StateName: this.stateName,
          DistrictName: this.districtName,
          CityName: this.cityName,
          PostalCode: exportingdata.postalCode,
          Address: exportingdata.address,

          CreatedDate: createDateTime,
        }

        this.dataExport.push(this.exported);
      }
      this.ExcelService.exportAsExcelFile(this.dataExport, "Export");
    }
    else {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData;
      for (let exportingdata of this.exportingdata) {
        let dob = this.datepipe.transform(exportingdata.dateOfBirth, 'dd-MMM-yyyy')
        let createDateTime = this.datepipe.transform(exportingdata.createDateTime, 'dd-MMM-yyyy')
        let dateOfJoining = this.datepipe.transform(exportingdata.dateOfJoining, 'dd-MMM-yyyy')

        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.city) {
          this.cityName = exportingdata.employeeInfo.city.cityName;
        } else {
          this.cityName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.country) {
          this.countryName = exportingdata.employeeInfo.country.countryName;
        } else {
          this.countryName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.state) {
          this.stateName = exportingdata.employeeInfo.state.stateName;
        } else {
          this.stateName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.district) {
          this.districtName = exportingdata.employeeInfo.district.districtName;
        } else {
          this.districtName = "";
        }

        if (exportingdata.employmentType != undefined && exportingdata.employmentType.listTypeValueName) {
          this.employmentType = exportingdata.employmentType.listTypeValueName;
        } else {
          this.employmentType = "";
        }

        if (exportingdata.bloodGroup != undefined && exportingdata.bloodGroup.listTypeValueName) {
          this.bloodGroup = exportingdata.bloodGroup.listTypeValueName;
        } else {
          this.bloodGroup = "";
        }

        if (exportingdata.gender != undefined && exportingdata.gender.listTypeValueName) {
          this.gender = exportingdata.gender.listTypeValueName;
        } else {
          this.gender = "";
        }

        if (exportingdata.designation != undefined && exportingdata.designation.designationName) {
          this.designation = exportingdata.designation.designationName;
        } else {
          this.designation = "";
        }
        if (exportingdata.department != undefined && exportingdata.department.departmentName) {
          this.department = exportingdata.department.departmentName;
        } else {
          this.department = "";
        }

        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankAccountNo) {
          this.bankAccountNo = exportingdata.employeeInfo.bankAccountNo;
        } else {
          this.bankAccountNo = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.accountName) {
          this.accountName = exportingdata.employeeInfo.accountName;
        } else {
          this.accountName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankName) {
          this.bankName = exportingdata.employeeInfo.bankName;
        } else {
          this.bankName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankBranchName) {
          this.bankBranchName = exportingdata.employeeInfo.bankBranchName;
        } else {
          this.bankBranchName = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.ifscCode) {
          this.ifscCode = exportingdata.employeeInfo.ifscCode;
        } else {
          this.ifscCode = "";
        }
        if (exportingdata.employeeInfo != undefined && exportingdata.employeeInfo.bankAddress) {
          this.bankAddress = exportingdata.employeeInfo.bankAddress;
        } else {
          this.bankAddress = "";
        }

        this.exported = {
          EmployeeRole: exportingdata.role.roleName,
          EmployeeNo: exportingdata.employeeNumber,
          EmployeeName: exportingdata.firstName + " " + exportingdata.lastName,
          MobileNumber: exportingdata.mobileNumber,
          AlternateMobileNumber: exportingdata.alternativeMobileNumber,
          Email: exportingdata.organizationEmail,
          DateofBirth: dob,
          Gender: this.gender,
          BloodGroup: this.bloodGroup,
          EmploymentType: this.employmentType,
          DesignationName: this.designation,
          DepartmentName: this.department,
          DateOfJoining: dateOfJoining,
          BankAccountNo: this.bankAccountNo,
          AccountHolderName: this.accountName,
          BankName: this.bankName,
          BankBranchName: this.bankBranchName,
          IFSCCode: this.ifscCode,
          BankAddress: this.bankAddress,

          CountryName: this.countryName,
          StateName: this.stateName,
          DistrictName: this.districtName,
          CityName: this.cityName,
          PostalCode: exportingdata.postalCode,
          Address: exportingdata.address,

          CreatedDate: createDateTime,
        }

        this.dataExport.push(this.exported);
      }

      this.ExcelService.exportAsExcelFile(this.dataExport, "Export");
    }
  }

  importEmployee() //Add Dialog Box
  {
    console.log("import calling!!!");
    

    const dialogRef = this.dialog.open(ImportEmployeeComponent, {
      //height: 'auto',width: '600px',
      width: '500px', height: 'fit-content',
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log("result::",result);
      
      //this.departmentList();
      if (result.statusCode == 200) {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
      }
      else {
        if (result.length != 0) {

          this.error = true;
          //this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }
        else {
          if (result.statusCode > 400) {
            this.error = true;

            this.importerror = false;
          }
        }

      }
    });
  }

  createEmp() {
    console.log("open Mat DialogBox");
    // this.dialog.open(MatclientcomponentsComponent);
    // {
    //   //height: 'auto',width: '600px',
    //   width: '500px', height:'fit-content',
    // };

    //  this.dialog.open(MatclientcomponentsComponent, {
    //   //height: 'auto',width: '600px',
    //   width: '300px',height:'fit-content',
    // });

    this.router.navigate(['home/add-employee'], {

    });

  }

  showerrormessage() {
    const dialogRef = this.dialog.open(EmployeeErrorComponent, {
      //height: 'auto',width: '600px',
      width: '600px', height: 'fit-content', data: this.errormessagejson
    });
    this.error = false;
  }


  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort) {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'roleName':
          return compare(a.roleName, b.roleName, isAsc);

        case 'employeeNumber':
          return compare(a.employeeNumber, b.employeeNumber, isAsc);

        case 'firstName':
          return compare(a.firstName, b.firstName, isAsc);

        case 'mobileNumber':
          return compare(a.mobileNumber, b.mobileNumber, isAsc);

        case 'organizationEmail':
          return compare(a.organizationEmail, b.organizationEmail, isAsc);

        case 'dateOfBirth':
          return compare(a.dateOfBirth, b.dateOfBirth, isAsc);

        case 'createDateTime':
          return compare(a.createDateTime, b.createDateTime, isAsc);

        case 'dateOfJoining':
          return compare(a.dateOfJoining, b.dateOfJoining, isAsc);

        default:
          return 0;
      }
    });
  }


  filterEntities() {
    console.log("roleid" + this.searchForm.value.roleId);
    if (!this.entityFilterCtrl.value) {
      return;
    }
    this.data = this.activeRolesData
    // get the search keyword
    let search = this.entityFilterCtrl.value;
    console.log("search" + search);
    if (!search) {
      this.filteredEntities.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the entitys
    console.log(this.filteredEntities);
    this.filteredEntities.next(
      this.data.filter(entity => entity.roleName.toLowerCase().indexOf(search) > -1)
    );
  }

  clearForm() {
    this.searchForm.reset();
    this.resultData.length = 0;
    this.nodatafound = false;
    this.showtable = false;
  }

  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  }

} //Export end

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

