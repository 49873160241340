import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatOption } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { MatSelectChange } from '@angular/material/select';
import { ProjectService } from 'src/app/providers/project.service';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
// import { MatSelectChange } from "@angular/material";
// import { ProjectsService } from "../projects.service";

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
})
export class AddProjectComponent implements OnInit {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('picker1') startDatePicker!: MatDatepicker<any>;
  @ViewChild('picker') startDatePicker1!: MatDatepicker<any>;
  @ViewChild('p1') endDatePicker!: MatDatepicker<any>;
  @ViewChild('p') endDatePicker2!: MatDatepicker<any>;
  IsId: boolean = true;
  Team = new FormControl('', Validators.required);
  projectForm: FormGroup;
  submitted = false;
  Id: any = undefined;
  startDate: any = {};
  endDate: any;
  newStartDate: any = {};
  projectId;
  managerList: any = [];
  TeamLeaderList: any = [];
  TeamList: any;
  project: any;
  min: any;
  min1: any;
  date = {
    year: 2000,
    month: 2,
    day: 1,
  };
  projectListId: any;
  projectList: any;
  endDate_disable: boolean = false;
  projectType: any;
  projectStage;
  projectStatus: any;
  Customer;
  customerValue: boolean;
  Date_disable: boolean = false;
  minDate: any;
  arrayData: any[] = [];
  organizationId: any;
  projectcodeexist: any;
  projectcodeobject: any = {};
  ProjectNameexist: any;
  projectNameobject: any = {};
  startDate_disable: boolean;
  selectedListTypeValueId: number;
  selectedListTypeValueName: string;
  projectTypeName: string;
  employeeId: number;

  buttonDisabled: boolean = false;
  resource = new FormControl('', Validators.required);
  success = false;
  error: any = false;
  sucess_msg: any;
  errormessagejson: any;
  importerror: any;
  error_msg: any;
  endSelectedFirst: boolean;
  submitClicked: boolean = false;
  saveDisable:boolean =  true;
  usertData: any = '';
  userType: any;
  constructor(
    private fb: FormBuilder,
    private gs: ProjectManagementService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AddProjectComponent>,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private datepipe: DatePipe,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private positionservice: RecruitmentService
  ) {
    console.log('patchingdata', this.data);
  }

  ngOnInit() {
    // if (this.projectForm.valid) {
    //   this.submitted = false;
    // }

    
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('local storage data::', this.usertData);

    this.userType = [];
    this.usertData.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });

    this.organizationId = this.usertData.organization.organizationId;

    this.positionservice
    .getclientlist(this.organizationId)
    .subscribe((resultData: any) => {
      console.log(' resultData', resultData);
      // this.clientListData = resultData;
      this.Customer = resultData
    });
    // this.gs.Get_Customer().subscribe((data: any) => {
      
    //   this.Customer = data;

    //   console.log('customerArray',data);
      
    // });
    console.log('datachecking in addProject------------', this.data);

    let userData = JSON.parse(localStorage.getItem('enoteUserData'));

    this.employeeId = userData.employee.employeeId;
 
    // this.IsId = false;
    this.getRouterData();

    this.gs.GetListById(5).subscribe((data) => {
      this.projectType = data;
    });

    this.gs.GetProjectStage().subscribe((data) => {
      this.projectStage = data;
    });

 

    this.gs.projectStatus().subscribe((data: any) => {
      this.projectStatus = data;

      console.log('data', this.projectStatus);
    });

    if (this.Id) {
      this.project = 'Edit Project';
      this.endDate_disable = true;
      this.startDate_disable = true;
      this.projectForm = this.fb.group({
        organization: {
          organizationId: [''],
        },
        projectId: [''],
        projectName: ['', [Validators.required]],
        projectDescription: ['', Validators.required],
        projectStartDate: ['', Validators.required],
        projectEndDate: ['', Validators.required],
        projectCode: ['', Validators.required],
        projectActualStartDate: [''],
        projectActualEndDate: [''],
        projectType: ['', Validators.required],
        projectStage: ['', Validators.required],
        customer: [''],
        projectBudgetHours: ['', Validators.required],
        projectStatus: ['', Validators.required],
      });
    } else {
      this.project = 'Create Project';
      this.projectForm = this.fb.group({
        organization: {
          organizationId: [''],
        },
        projectId: [''],
        projectName: ['', [Validators.required]],
        projectDescription: ['', Validators.required],
        projectStartDate: ['', Validators.required],
        projectEndDate: ['', Validators.required],
        projectActualStartDate: [''],
        projectActualEndDate: [''],
        projectCode: ['', Validators.required],
        projectType: ['', Validators.required],
        projectStage: ['', Validators.required],
        customer: [''],
        projectBudgetHours: ['', Validators.required],
        projectStatus: ['', Validators.required],
      });
    }
    this.projectForm.patchValue({
      organization: userData.organization,
    });
  }

  updateCustomerValidators(projectType: string) {
    const customerControl = this.projectForm.get('customer');
    if (projectType === 'Customer') {
      customerControl.setValidators([Validators.required]);
    } else {
      customerControl.clearValidators();
    }
    customerControl.updateValueAndValidity();

    // Update the form validity
    this.projectForm.updateValueAndValidity();
  }

  onProjectTypeChange() {
    this.projectForm.get('projectType').valueChanges.subscribe((value) => {
      this.updateCustomerValidators(value);
    });
  }
  get isCustomerRequired(): boolean {
    return this.projectForm.get('projectType').value === 'Customer';
  }

 

  get isSaveButtonDisabled(): boolean {
    const projectType = this.projectForm.get('projectType').value;

    const c1 = this.projectForm.get('projectName').value;
    const c2 = this.projectForm.get('projectDescription').value;
    const c3 = this.projectForm.get('projectStartDate').value;
    const c4 = this.projectForm.get('projectEndDate').value;
    const c5 = this.projectForm.get('projectCode').value;
    const c8 = this.projectForm.get('projectType').value;
    const c9 = this.projectForm.get('projectStage').value;
    const c10 = this.projectForm.get('projectBudgetHours').value;
    const c11 = this.projectForm.get('projectStatus').value;
    const customerControl1 = this.projectForm.get('customer').value;

    if (
      projectType === 33 &&
      customerControl1 &&
      c1 &&
      c2 &&
      c3 &&
      c4 &&
      c5 &&
      c8 &&
      c9 &&
      c10 &&
      c11
    ) {
      return false; // Enable button
    } else if (
      projectType === 32 &&
      c1 &&
      c2 &&
      c3 &&
      c4 &&
      c5 &&
      c8 &&
      c9 &&
      c10 &&
      c11
    ) {
      // delete this.projectForm['customer'];
      // this.projectForm.removeControl('customer');
      return false; // Enable button
    } else {
      return true; // Disable button
    }
  }

  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  getRouterData() {
    this.Id = this.data?.x;
    if (this.Id) {
      this.IsId = false;

      this.gs.Get_Customer().subscribe((data: any) => {
      
        // this.Customer = data;
  
        console.log('customerArray',data);
        this.gs.getprojectByID(this.Id).subscribe((a) => {
          console.log('a', a);
          this.PatchProjectData(a);
        });
        
      });
     
    }else{
      this.gs.Get_Customer().subscribe((data: any) => {
      
        // this.Customer = data;
  
        console.log('customerArray',data);
        
      });
    }
  }
  PatchProjectData(data) {
    console.log('data', data);
    console.log('customer', data?.customer?.customerId);
    console.log('projectType', data?.projectType);
    if (data.projectType == 33) {
      this.customerValue = true;
    }else{
      this.customerValue = false;
    }
   

 

    this.projectForm.patchValue({
      organization: data.organizationId,
      projectId: data.projectId,
      projectName: data.projectName,
      projectDescription: data.projectDescription,
      projectStartDate: data.projectStartDate,
      projectEndDate: data.projectEndDate,
      projectCode: data.projectCode,
      projectType: data.projectType,
      projectStage: data.projectStage.projectstageId,
      customer: data?.customer?.customerId,
      
      projectBudgetHours: data.projectBudgetHours,
      projectStatus: data.projectStatus?.listTypeValueId,
    });
    if (data.projectActualStartDate != '' && data.projectActualEndDate != '') {
      this.projectForm.patchValue({
        projectActualStartDate: data.projectActualStartDate,
        projectActualEndDate: data.projectActualEndDate,
      });
    } else {
      this.projectForm.patchValue({
        projectActualStartDate: '',
        projectActualEndDate: '',
      });
    }
    if( data?.projectType == 32){
      this.projectForm.patchValue({
        customer:null
      })
  
      // delete this.projectForm['customer'];
      // this.projectForm.removeControl('customer');
    
    }
    this.checkStartDate();
    console.log('projectStatus', this.projectStatus);
  }

  // isFormValid(): boolean {
  //   return this.projectForm.valid;
  // }

  projectNameError() {
    return this.f.projectName.hasError('required')
      ? 'Please Enter Project Name*'
      : '';
  }

  projectDescriptionError() {
    return this.f.projectDescription.hasError('required')
      ? 'Please Enter Project Detail Description*'
      : '';
  }

  projectStartDate() {
    return this.f.projectStartDate.hasError('required')
      ? 'Please Select Start Date*'
      : '';
  }

  projectEndDate() {
    return this.f.projectEndDate.hasError('required')
      ? 'Please Select End Date'
      : '';
  }

  projectBudgetHours() {
    return this.f.projectBudgetHours.hasError('required')
      ? 'Please Enter  Budget Hours'
      : '';
  }

  projectBudgetHours1() {
    return this.f.projectBudgetHours.hasError('pattern')
      ? 'Please Enter  Budget Hours'
      : '';
  }

  // _ActualStartDate(){
  //   return this.f.projectActualStartDate.hasError('required') ? 'Please Select Actual Start Date*' :
  //     ''
  // }

  // _ActualEndDate() {
  //   return this.f.projectActualEndDate.hasError('required') ? 'Please Select Actual End Date' :
  //     ''
  // }

  projectCode() {
    return this.f.projectCode.hasError('required')
      ? 'Please Enter Project Code*'
      : '';
  }
  proType() {
    return this.f.projectType.hasError('required')
      ? 'Please Select Project Type*'
      : '';
  }

  proStage() {
    return this.f.projectStage.hasError('required')
      ? 'Please Select Project Stage*'
      : '';
  }
  customerError() {
    return this.f.customer.hasError('required')
      ? 'Please Select Customer Name*'
      : '';
  }

  proStatus() {
    return this.f.projectStatus.hasError('required')
      ? 'Please Select Project Status*'
      : '';
  }
  //control values of form values
  get f() {
    return this.projectForm.controls;
  }

  stage(data) {
    console.log('stageData', data);
    this.projectTypeName = data;

    if (data == 'Customer') {
      this.customerValue = true;
    } else {
      this.customerValue = false;
    }
  }
  // date validation
  someMethodName(date: any) {
    this.min = new Date(date.value);
    this.endDate_disable = false;
  }

  toggleStartDateClasses() {
    const startDateControl = this.projectForm.get('projectStartDate');
    const inputElement =
      this.elementRef.nativeElement.querySelector('#startDate');

    if (startDateControl && startDateControl.value) {
      this.renderer.removeClass(inputElement, 'select-placeholder');
      this.renderer.addClass(inputElement, 'black-placeholder-date');
    } else {
      this.renderer.addClass(inputElement, 'select-placeholder');
      this.renderer.removeClass(inputElement, 'black-placeholder-date');
    }
  }

  someMethodName1(date: any) {
    this.min1 = new Date(date.value);
    this.Date_disable = false;
  }

  imageFile: any;
  fileChange(i) {
    console.log(i);
    let fileReader = new FileReader();
    let File = i.target.files;
    if (File.length == 1) {
      let x = File[0];
      let name = x.name;
      let type = x.type;
      let size = x.size;
      console.log(name, type, size);
      fileReader.readAsDataURL(x);
      console.log(fileReader);
      fileReader.onload = () => {
        let x: any = fileReader.result;
        let y = x.split(',');
        this.imageFile = [
          {
            fileName: name,
            fileType: type,
            fileSize: size,
            fileByte: y[1],
          },
        ];
        console.log(this.imageFile);
      };
    } else if (File.length > 1) {
      let x = File;
    }
  }
  // save method

  save() {
    console.log('checkValues',this.ProjectNameexist,this.Id,this.projectcodeexist);
    this.submitted = true;
    this.submitClicked = false;
    this.saveDisable = false;
    let pro = this.projectForm.value;
    console.log('pro', pro);
    pro['status'] = 1;
    if (pro.projectStartDate != '') {
      //start date
      let a = pro.projectStartDate;
      let x = new Date(a);
      x.setMinutes(x.getMinutes() + 480);
      let s = x.toISOString();
      pro['projectStartDate'] = s;
    }
    if (pro.projectEndDate != '') {
      //end date
      let b = pro.projectEndDate;
      let y = new Date(b);
      y.setMinutes(y.getMinutes() + 480);
      let t = y.toISOString();
      pro['projectEndDate'] = t;
    }

    console.log('projectActualStartDate ' + pro?.projectActualStartDate);
    //Actual start date
    // if (pro?.projectActualStartDate?.length != 0) {
    //   let c = pro?.projectActualStartDate;
    //   let i = new Date(c);
    //   i.setMinutes(i.getMinutes() + 480);
    //   let v = i.toISOString();
    //   pro['projectActualStartDate'] = v;
    // } else {
    //   pro['projectActualStartDate'] = '';
    // }

    //Actual end date
    // if (pro?.projectActualEndDate?.length != 0) {
    //   let d = pro?.projectActualEndDate;
    //   let j = new Date(d);
    //   j.setMinutes(j.getMinutes() + 480);
    //   let w = j.toISOString();
    //   pro['projectActualEndDate'] = w;
    //   console.log(
    //     'projectActualEndDate inside if ' + pro?.projectActualEndDate
    //   );
    // } else {
    //   pro['projectActualEndDate'] = '';
    // }

    let project = {
      projectstageId: pro.projectStage,
    };

    // pro['projectStage'] = project;

    let projectStatus = {
      listTypeValueId: this.selectedListTypeValueId,
      listTypeValueName: this.selectedListTypeValueName,
    };

    pro['projectStatus'] = projectStatus;

    console.log('projectStatus', projectStatus);

    // if (this.customerValue) {
    //   if (pro.customer == 'dd') {
    //     this.projectForm.controls['customer'].setErrors({ required: true });
    //     return;
    //   }
    //   pro['customer'] = {
    //     customerId: pro.customer,
    //   };
    // } else {
    //   this.f.customer.setValue('dd');
    //   delete pro['customer'];
    // }
    const body = {
      projectName: this.projectForm.controls['projectName'].value,
      projectCode: this.projectForm.controls['projectCode'].value,
      projectStartDate: pro.projectStartDate,
      projectEndDate: pro.projectEndDate,
      projectDescription: pro.projectDescription,

      status: {
        listTypeValueId: 1,
      },
      organizationId: pro.organization.organizationId,
      projectActualStartDate:
        this.projectForm.controls['projectActualStartDate'].value,
      projectActualEndDate:
        this.projectForm.controls['projectActualEndDate'].value,
      projectType: pro.projectType,
      // customer:{
      //   customerId:this.projectForm.controls['customer'].value
      // },
      clientId:this.projectForm.controls['customer'].value,
      projectStage: {
        projectstageId: this.projectForm.controls['projectStage'].value,
      },
      projectTypeName: this.projectTypeName,
      employeeId: this.employeeId,
      projectStatus: {
        listTypeValueId: pro.projectStatus.listTypeValueId,
        listTypeValueName: pro.projectStatus.listTypeValueName,
      },
      projectBudgetHours: pro.projectBudgetHours,
    };

    console.log('create', body);

    // if (this.projectForm.invalid) {
    //   return;
    // }
    // else {

    console.log('empnumberexist ' + this.projectcodeexist);

    // if (
    //   (this.projectForm.valid && this.projectcodeexist == true) ||
    //   this.ProjectNameexist == true
    // ) {
    //   console.log('if condition');
    //   return;
    // }

  
    pro['projectName'] = this.capitalizeWordsName(pro.projectName);
    pro['projectDescription'] = this.capitalizeWordsDescription(
      pro.projectDescription
    );

    console.log('pro---------checking', pro);
    console.log('create', body);
 
  

    const updateBody = {
      projectId: this.Id,
      projectName: pro.projectName,
      projectCode: pro.projectCode,
      projectStartDate: pro.projectStartDate,
      projectEndDate: pro.projectEndDate,
      projectDescription: pro.projectDescription,
      // this.data?.d?.status?.listTypeValueId
      status: {
        listTypeValueId: 1,
      },
      // organizationId: pro.organization.organizationId,
      organizationId:pro.organization,
      projectActualStartDate:  this.projectForm.controls['projectActualStartDate'].value,
      projectActualEndDate:   this.projectForm.controls['projectActualEndDate'].value,
      projectType: pro.projectType,
      // customer:{
      //   customerId:this.projectForm.controls['customer'].value
      // } ,
      clientId:this.projectForm.controls['customer'].value,
      projectStage: {
        projectstageId: this.projectForm.controls['projectStage'].value,
      },
      projectTypeName: this.projectTypeName,
      employeeId: this.employeeId,
      projectStatus: {
        listTypeValueId: this.projectForm.controls['projectStatus'].value,
        listTypeValueName: pro.projectStatus.listTypeValueName,
      },
      projectBudgetHours: pro.projectBudgetHours,
    };

    console.log('updateBody', updateBody);
    console.log('this.dataChecking', updateBody.projectType );
    if (this.data?.projectType == 32 || 
      updateBody?.clientId == null) {
      // this.projectForm.patchValue({
      //   customer: null
      // });
    
      delete updateBody?.clientId;
      delete updateBody?.clientId;
      delete this.projectForm['customer'];
      // this.projectForm.removeControl('customer');
    }
    if (body.projectType == 32 || 
      body?.clientId == '') {
      // this.projectForm.patchValue({
      //   customer: null
      // });
    
      delete body?.clientId;
      delete body?.clientId;
      delete this.projectForm['customer'];
      // this.projectForm.removeControl('customer');
    }
    console.log('updateBodyCheckingAfterDelete', updateBody);
    const bodyJSON: any = JSON.stringify(updateBody);
    console.log('idcheck',this.Id);

    //  returncreateProject
    if (this.Id  != undefined ) {
      this.projectcodeexist = false;
      // this.projectForm.reset();
      this.gs.updateProject(bodyJSON, this.Id).subscribe((a) => {
        // Swal.fire({
        //   icon: 'success',
        //   text: 'Project Updated Successfully',
        // });
        // this.success = true;
        this.dialog.closeAll();
        if ((a as any).statusCode === 200) {
          this.submitted = false;
          this.sucess_msg = 'Project Updated Successfully';
          this.projectService.setSuccessMessage('Project Updated Successfully');

          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          const currentUrl = this.router.url;
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigateByUrl(currentUrl);
            });
        }
        // this.sucess_msg = 'Project Updated Successfully';
        // this.projectService.setSuccessUpdateMessage(
        //   'Project Updated Successfully'
        // ); // Call method to set success message

        setTimeout(() => {
          // window.location.reload();

          this.clearSuccessMessage();
          this.success = false;
        }, 900);

        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.ngZone.run(() => {
              this.closeSuccessMessage();
            });
          }, 3000);
        });

        setTimeout(() => {
          this.success = false;
          this.clearSuccessMessage();
          // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // const currentUrl = this.router.url;

          // this.sucess_msg ='Project Updated Successfully';
          // this.projectService.setSuccessUpdateMessage( 'Project Updated Successfully'); // Call method to set success message
        }, 300);

        // setTimeout(() => {
        //   // this.success = true;
        //   this.sucess_msg ='Project Updated Successfully';
        //   this.projectService.setSuccessUpdateMessage( 'Project Updated Successfully'); // Call method to set success message
        // }, 300);
        // setTimeout(() => {
        //   // window.location.reload();
        //   this.dialog.closeAll();
        // }, 900);

        // setTimeout(() => {
        //   this.success = false;
        //   // this.sucess_msg ='Project Updated Successfully';
        //   // this.projectService.setSuccessUpdateMessage( 'Project Updated Successfully'); // Call method to set success message
        // }, 300);
        return;
      });
    }
   else  if (this.Id === undefined && this.ProjectNameexist == false && this.projectcodeexist == false ) {
    this.gs.createProject(body).subscribe(
      (a) => {
        this.dialog.closeAll();
        if ((a as any).statusCode === 200) {
          this.submitted = false;
          this.sucess_msg = 'Project Created Successfully';
          this.projectService.setSuccessMessage(
            'Project Created Successfully'
          );
    
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          const currentUrl = this.router.url;
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigateByUrl(currentUrl);
            });
        }
    
        setTimeout(() => {
          this.dialog.closeAll();
          this.clearSuccessMessage();
          this.success = false;
        }, 900);
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.ngZone.run(() => {
              this.closeSuccessMessage();
            });
          }, 3000);
        });
        setTimeout(() => {
          this.success = false;
          this.clearSuccessMessage();
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          const currentUrl = this.router.url;
        }, 300);
      },
      (err) => {
        // Handle errors here
        this.dialogRef.close();
        let msg = err.error.message;
        if (err.error.statusCode == 409) {
          this.projectService.setErrorMessage(msg);
    
          // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // const currentUrl = this.router.url;
          // this.router
          //   .navigateByUrl('/', { skipLocationChange: true })
          //   .then(() => {
          //     this.router.navigateByUrl(currentUrl);
          //   });
        }
        console.error('Error occurred:', err);
        // Add error handling logic here, like displaying error messages or logging errors
      }
    );  
  }


 

      console.log('createbody', body);

    
  
  }

  actualDates(event: MatDatepickerInputEvent<Date>) {
    const ActualStart = document.querySelector(
      '[formControlName="projectActualStartDate"]'
    ) as HTMLElement;
    const ActualEnd = document.querySelector(
      '[formControlName="projectActualEndDate"]'
    ) as HTMLElement;
    const startDateActual = this.projectForm.get(
      'projectActualStartDate'
    ).value;
    const EndDateActual = this.projectForm.get('projectActualEndDate').value;

    // if (startDateActual) {
    //   ActualStart.classList.remove('select-placeholder');
    // } else {
    //   ActualStart.classList.add('black-placeholder-date');
    // }

    // if (EndDateActual) {
    //   ActualEnd.classList.remove('select-placeholder');
    // } else {
    //   ActualEnd.classList.add('black-placeholder-date');
    // }

    const startDateInput = document.querySelector(
      '[formControlName="projectStartDate"]'
    ) as HTMLElement;
    const endDateInput = document.querySelector(
      '[formControlName="projectEndDate"]'
    ) as HTMLElement;
    const startDate1 = this.projectForm.get('projectStartDate').value;
    const EndDate1 = this.projectForm.get('projectEndDate').value;

    // if (startDate1) {
    //   startDateInput.classList.remove('select-placeholder');
    // } else {
    //   startDateInput.classList.add('black-placeholder-date');
    // }

    // if (EndDate1) {
    //   endDateInput.classList.remove('select-placeholder');
    // } else {
    //   endDateInput.classList.add('black-placeholder-date');
    // }
  }

  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    this.projectForm.get('projectEndDate').valueChanges.subscribe((endDate) => {
      const startDate = this.projectForm.get('projectStartDate').value;
      if (this.projectForm.get('projectStartDate').value) {
        // startDate.classList.remove('select-placeholder');
      } else {
        // startDate.classList.add('black-placeholder-date');
      }
      if (this.endSelectedFirst && startDate > endDate) {
        // If end date was selected first and start date is greater than end date
        // Set start date to be one day before end date
        const newStartDate = new Date(endDate);
        newStartDate.setDate(endDate.getDate() - 1);
        this.projectForm.get('projectStartDate').setValue(newStartDate); // Update form control value
      }
    });

    this.projectForm
      .get('projectActualEndDate')
      .valueChanges.subscribe((endDate) => {
        const startDate = this.projectForm.get('projectActualStartDate').value;
        if (this.endSelectedFirst && startDate > endDate) {
          // If end date was selected first and start date is greater than end date
          // Set start date to be one day before end date
          const newStartDate = new Date(endDate);
          newStartDate.setDate(endDate.getDate() - 1);
          this.projectForm.get('projectActualStartDate').setValue(newStartDate); // Update form control value
        }
      });
  }

  // checkStartDate(): void {
  //   const startDate = this.projectForm.get('projectStartDate').value;
  //   const endDate = this.projectForm.get('projectEndDate').value;
  //   if (startDate && endDate && startDate > endDate) {
  //     alert('Start date cannot be greater than end date.');
  //     this.projectForm.reset({
  //       projectStartDate: null,
  //       projectEndDate: null
  //     });

  //     this.startDatePicker.close();
  //     this.endDatePicker.close();
  //     this.startDatePicker1.close();
  //     this.endDatePicker2.close();
  //   }

  //   const startDateActual = this.projectForm.get('projectActualStartDate').value;
  //   const endDateActual = this.projectForm.get('projectActualEndDate').value;
  //   if (startDateActual && endDateActual && startDateActual > endDateActual) {
  //     alert('Start date cannot be greater than end date.');
  //     this.projectForm.reset({
  //       projectActualStartDate: null,
  //       projectActualEndDate: null
  //     });
  //     this.startDatePicker.close();
  //     this.endDatePicker.close();
  //     this.startDatePicker1.close();
  //     this.endDatePicker2.close();
  //   }
  // }

  noZero(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const inputElement = event.target as HTMLInputElement;

    // Prevent default behavior for all non-digit characters
    if (!/^\d$/.test(inputChar)) {
      event.preventDefault();
    }

    // Prevent 0 as the first character
    if (inputElement.value.length === 0 && inputChar === '0') {
      event.preventDefault();
    }
  }
  checkStartDate(): void {
    const startDate = this.projectForm.get('projectStartDate').value;
    const endDate = this.projectForm.get('projectEndDate').value;
    let startDate1 = this.datepipe.transform(startDate, 'YYYY-MM-dd');
    let endDate1 = this.datepipe.transform(endDate, 'YYYY-MM-dd');
    if (startDate && endDate && startDate1 > endDate1) {
      alert('Start date cannot be greater than end date.');

      this.projectForm.patchValue({
        projectStartDate: null,
        projectEndDate: null,
      });

      this.startDatePicker.close();
      this.endDatePicker.close();
      
    }

    const startDateActual = this.projectForm.get(
      'projectActualStartDate'
    ).value;
    const endDateActual = this.projectForm.get('projectActualEndDate').value;
    let startDateActual1 = this.datepipe.transform(startDateActual, 'YYYY-MM-dd');
    let endDateActual1 = this.datepipe.transform(endDateActual, 'YYYY-MM-dd');
    if (startDateActual && endDateActual && startDateActual1 > endDateActual1) {
      alert('Start date cannot be greater than end date.');

      this.projectForm.patchValue({
        projectActualStartDate: null,
        projectActualEndDate: null,
      });

      this.startDatePicker1.close();
      this.endDatePicker2.close();
      
    }
  }



  clearSuccessMessage() {
    this.projectService.clearSuccessMessage();
  }
  closeSuccessMessage() {
    this.success = false;
    // this.successMessage = '';
  }
  onProjectStatusChange(event: MatSelectChange): void {
    this.selectedListTypeValueId = event.value;
    this.selectedListTypeValueName = this.projectStatus.find(
      (project) => project.listTypeValueId === this.selectedListTypeValueId
    )?.listTypeValueName;

    // Now you can use the selected values as needed
    console.log('Selected ListTypeValueId:', this.selectedListTypeValueId);
    console.log('Selected ListTypeValueName:', this.selectedListTypeValueName);
  }
  projectcodevalidate() {
    console.log('projectCode' + this.projectForm.value.projectCode);
    let projectid = 0;
    if (this.data) {
      projectid = this.data.x;
    }
    if (this.projectForm.value.projectCode.length != 0) {
      this.gs
        .getprojectcode(this.projectForm.value.projectCode, projectid)
        .subscribe((a) => {
          console.log(a);
          this.projectcodeobject = a;
          if (this.projectcodeobject.isexist) {
            console.log('isexist');
            if (this.Id) {
              this.projectcodeexist = false;
            } else {
              this.projectcodeexist = true;
            }
          } else {
            this.projectcodeexist = false;
          }
        });
    } else {
      this.projectcodeexist = false;
    }
  }

  projectnamevalidate() {
    console.log('projectName ' + this.projectForm.value.projectName);
    let projectid = 0;
    if (this.data) {
      projectid = this.data;
    }
    if (this.projectForm.value.projectName.length != 0) {
      this.gs
        .getprojectName(this.projectForm.value.projectName, projectid)
        .subscribe((a) => {
          console.log(a);
          this.projectNameobject = a;
          if (this.projectNameobject.isexist) {
            console.log('email isexist');
            this.ProjectNameexist = true;
          } else {
            this.ProjectNameexist = false;
          }
        });
    } else {
      this.ProjectNameexist = false;
    }
  }
  capitalizeWordsName(text) {
    return text.replace(/(?:^|\s)\S/g, (res) => {
      return res.toUpperCase();
    });
  }

  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  noNumber(event: any) {
    const pattern = /[0-9]/; // Removed the `\.` from the pattern
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  changePlaceholderColor(event: any): void {
    if (event.target.value) {
      const inputValue = event.target.value.trim(); // Remove leading and trailing spaces
      if (inputValue !== '') {
        // Check if input value is not just a space
        const input = event.target as HTMLInputElement;
        // input.classList.remove('select-placeholder');
        // input.classList.remove('textarea');
      }
      // else {
      //     const input = event.target as HTMLInputElement;
      //     input.classList.add('black-placeholder');
      // }
    }
  }
  pasteCount: number = 0;
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }

}
