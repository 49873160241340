import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit  ,ChangeDetectorRef} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router,ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { EmployeeService } from '../providers/employee.service';


@Component({
  selector: 'app-client-employee-annexue',
  templateUrl: './client-employee-annexue.component.html',
  styleUrls: ['./client-employee-annexue.component.scss']
})
export class ClientEmployeeAnnexueComponent implements OnInit {
  
  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any = [];
  selected :true;
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  branchlist: any = [];
  years: number[] = [];
  userdata: any;
  organizationId: any;
  sortedData: any;
  p:number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  today: any =  new Date();
  dateFormat: any;
  shiftList: any;
  employeeId: any;
  employee: string;
  empList:any=[];
  shiftcalendarList: any = [];
  livingcity: any;
   selectedEmployeeData :any = [];
   employeedisabled:boolean = true;
  errormessagejson: any;
  shiftemployeeId: any;
  importerror = false;
  shiftemployeedata: any;
  showemployeegroupserach:any;
  empgroupList: any;
  selectedEmployeeGroupData: any = [];
  elementlist:any = [];
  totalmontlyincome:any = 0;
  totalannualincome :any = 0;
  deletedrows: any = [];
  showctcerror: boolean;
  empannexueId: any;
  touchedRows: any[];
  checktotalamount: boolean = true;
  empannexue: any = {};
  maxstartdate: any;
  filterele:any;
  clientemployee: any;
  annexuretype: any;
  clientelements: any;
  maxpfamount: any;
  max: any;
  employeePfAmount: any;
  employerPfAmount: any;
  maxesiamount: any;
  employeeEsiAmount: any;
  employerEsiAmount: any;
  

  constructor( private formBuilder: FormBuilder,
    private router : Router,
    private payrollService: PayrollService, private datepipe : DatePipe ,
    private ActivatedRoute:ActivatedRoute, @Inject(AppComponent) public AppData: any ,  
     private dialog:MatDialog,private cdr: ChangeDetectorRef , private employee_service: EmployeeService) { }

     ngAfterViewInit() {
    
      this.cdr.detectChanges();
    }

  ngOnInit(): void {

    this.empannexueId =  this.ActivatedRoute.snapshot.params.annexureHeaderId;
    this.postForm = this.formBuilder.group({
      client :  [null,[Validators.required]],     
      place : [null,[Validators.required , Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]],
     // employee : [null,[Validators.required ]],
      employee : [null,[]],
      ctc : [null,[Validators.required]],
      livingCity:[null,[Validators.required]],
      startDate : [null,[Validators.required]],
      endDate : [null,[Validators.required]],    
      annexuretype : [null,[Validators.required]],  
      tableRows: this.formBuilder.array([]) ,        
      });
      this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
      this.organizationId =this.userdata.organization.organizationId;
      // this.organizationId =  this.AppData.organizationId;
      console.log("this.organizationId",this.organizationId);
         
      this.payrollService.getelements(this.organizationId).subscribe((result: any) => {
        console.log('result',result);
        if(result.statusCode)
        {
         this.elementlist.length = 0;
        }else{
          console.log("else")
         this.elementlist = result;

         if(!this.empannexueId)
         {

          this.filterele =  this.elementlist.filter(ele=> ele.elementName.includes("Basic")  || ele.elementName.includes("HRA"));
          this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
                 console.log(this.filterele);
  
          const control = this.postForm.get('tableRows') as FormArray;
                 
          console.log("this.filterele",this.filterele);
          this.filterele.forEach((element ,index) => {
                   this.addRow();
                    if( element.elementName != "HRA")
                    {
                      control.at(index).patchValue({
                        hrElementPercentagelist : element.hrElementPercentage,
                        hrElementPercentage : element.hrElementPercentage[0].elementPercentage,
                        element : element.elementId,
                        elementName : element.elementName
                       })
                    }else{
                      control.at(index).patchValue({
                        hrElementPercentagelist : element.hrElementPercentage,                  
                        element : element.elementId,
                        elementName : element.elementName
                       })
                    }
              });
          console.log(control); 
         }

      
        
        }
     
     })

     
      this.payrollService.getClientNameList(this.organizationId).subscribe((result: any) => {
        this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
        console.log(this.clientList);
      })  

       if(!this.empannexueId)
       {
      
        this.payrollService.getListTypeById(15).subscribe((result: any) => {
                if(result.statusCode)
                {
                this.livingcity.length = 0;
                }else{
                  console.log("else")
                this.livingcity = result;
                }
            
            })
            this.payrollService.getListTypeById(16).subscribe((result: any) => {
              if(result.statusCode)
              {
              this.annexuretype.length = 0;
              }else{
                console.log("else")
              this.annexuretype = result;
              }
            })
       }else{
             
        this.payrollService.getelements(this.organizationId).subscribe((result: any) => {
          if(result.statusCode)
          {
           this.elementlist.length = 0;
          }else{
            console.log("else")
           this.elementlist = result;
          
           this.payrollService.getemployeeannexuebyId(this.empannexueId).subscribe((result: any) => {
            if(result.statusCode)
            {
       
            }else{
              this.empannexue = result;
                this.postForm.patchValue({
                  place : this.empannexue.place,
                 
                  ctc : this.empannexue.ctc,
                  livingCity:this.empannexue.livingCity.listTypeValueId,
                  startDate :this.empannexue.effectiveFromDate,
                  endDate : this.empannexue.effectiveToDate,     
                  
                  annexuretype : this.empannexue.annexureType.listTypeValueId
                })
 
                  if( this.empannexue.employee)
                  {
                    this.postForm.patchValue({
                    employee : this.empannexue.employee.employeeNumber + "-"+this.empannexue.employee.firstName+" "+this.empannexue.employee.lastName })
                  this.employeeId =  this.empannexue.employee.employeeId;

                    this.employee_service.getEmployeeById(this.employeeId).subscribe((empresult: any) => {
                      if(empresult.client)
                      {
                        this.postForm.patchValue({
                          client : empresult.client.clientId,
                          });
                      }
                     
                    })

                  }else if (this.empannexue.client)
                  {
                    this.postForm.patchValue({
                    client : this.empannexue.client.clientId,
                    });
                  }
              
           
  
                this.empannexue.lines.forEach((element , index) => {
                  const control = this.postForm.get('tableRows') as FormArray;
                  this.addRow();                                                 
                  control.at(index).patchValue({
                    annexureLineId :element.annexureLineId ,
                    elementName :element.element.elementName,
                   element: element.element.elementId,
                   hrElementPercentagelist:element.element.hrElementPercentage,
                  hrElementPercentage:element.elementPercentage,
                   salaryPerMonth: element.salaryPerMonth?.toFixed(2),
                   salaryPerAnnum:element.salaryPerAnnum?.toFixed(2),
                   isEditable: [true],
                   status : element.status
                  })
                });
        

                this.payrollService.clientelements(this.postForm.value.client).subscribe((result: any) => {
                  if(result.statusCode)
                  {
                   this.elementlist.length = 0;
                  }else{
                    console.log("else")
                     this.clientelements = result;
                       this.maxpfamount = this.clientelements[0].pfMaxSalaryAmount;
                       this.employeePfAmount = this.clientelements[0].employeePfAmount;
                       this.employerPfAmount = this.clientelements[0].employerPfAmount;
        
                       this.maxesiamount = this.clientelements[0].esiMaxSalaryAmount;
                       this.employeeEsiAmount = this.clientelements[0].employeeEsiAmount;
                       this.employerEsiAmount = this.clientelements[0].employerEsiAmount;
        
                
                  }
               
               })
              }
        })

          }
       
       })

        this.payrollService.getListTypeById(15).subscribe((result: any) => {
          if(result.statusCode)
          {
          this.livingcity.length = 0;
          }else{
            console.log("else")
          this.livingcity = result;
          }
      
      })

      this.payrollService.getListTypeById(16).subscribe((result: any) => {
        if(result.statusCode)
        {
        this.annexuretype.length = 0;
        }else{
          console.log("else")
        this.annexuretype = result;
        }
    
    })
      
       }
     
    

  }


    


      validateannexure()
    {
      const control = this.postForm.get('tableRows') as FormArray;
      let allowanceindex;
      let totalannualincome = 0;
      control.value.forEach((element , i)=> {   
        console.log(element);    
         
            if(element.elementName.toUpperCase() == "ALLOWANCE")
            {
              allowanceindex =  i;
            }else if (element.elementName.toUpperCase() != "ALLOWANCE")
            {
              totalannualincome += Number(element.salaryPerAnnum)
            }
      })

      
      if(totalannualincome < this.postForm.value.ctc )
      {
       
       
       
  
          // if(allowanceindex)
          // {
          //   console.log("has allowance");
          //   control.at(allowanceindex).patchValue({
          
          //     salaryPerMonth :0.00,
          //     salaryPerAnnum : 0.00     
          //   })
      
          //   console.log("this.postForm.value.ctc" + this.postForm.value.ctc);
          //   console.log("this.totalannualincome" +totalannualincome);
          //   let Totalannualincome = totalannualincome; // Convert to number if it's not already
          //     let ctc = Number(this.postForm.value.ctc);
          //     console.log("totalannualincome" + totalannualincome);
          //     console.log("ctc" + ctc);
          //     let salaryannum = Totalannualincome - ctc;
          //       console.log("salaryannum" + salaryannum);
          //      salaryannum = Math.abs(salaryannum);
          //      let salaryPerMonth =  salaryannum / 12;
          //      control.at(allowanceindex).patchValue({
          //     //  hrElementPercentagelist : element.hrElementPercentage ,
          //       salaryPerMonth : salaryPerMonth?.toFixed(2),
          //       salaryPerAnnum : salaryannum?.toFixed(2)   
          //     })
          // }
       
      }
      
    }
 
  get getFormControls() {
    //alert("getformcontrols")
    const control = this.postForm.get('tableRows') as FormArray;
    if(control != null)
    {
      return control;
    }
   
    
  }
  clear(){
    setTimeout(() => {
      const control = this.postForm.get('tableRows') as FormArray;
      control.clear();
      this.filterele.forEach((element ,index) => {
        this.addRow();
        if( element.elementName != "HRA"){
          control.at(index).patchValue({
            hrElementPercentagelist : element.hrElementPercentage,
            hrElementPercentage : element.hrElementPercentage[0].elementPercentage,
            element : element.elementId,
            elementName : element.elementName
            })
        }else{
          control.at(index).patchValue({
            hrElementPercentagelist : element.hrElementPercentage,                  
            element : element.elementId,
            elementName : element.elementName
            })
        }
      });
    }
    ,1000)
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  deleteRow(i,group)
  {
    const control =  this.postForm.get('tableRows') as FormArray; 
    console.log(group);
    console.log("group.value.annexureLineId" + group.value.annexureLineId);
    if(group.value.annexureLineId)
    {
      this.deletedrows.push(group.value);
    }
    control.removeAt(i);
  }

  gethrapercentage(event)
  {
   
        console.log("else")
        const control = this.postForm.get('tableRows') as FormArray;
               

        control.controls.forEach((element ,index) => {
           if(element.value.elementName == "HRA" )
           {
             console.log("has hra")
              element.value.hrElementPercentagelist.forEach(perelement => {
                 if(perelement.livingCityType.listTypeValueId == event.target.value)
                 {
                  
               
                  let partialAmount = this.calculatePartialAmount(perelement.elementPercentage,  control.at(0).value.salaryPerAnnum);             
                   let salaryPerMonth =  partialAmount / 12;
                   
                  control.at(index).patchValue({
                    salaryPerMonth : salaryPerMonth?.toFixed(2),
                    salaryPerAnnum : partialAmount?.toFixed(2)
                  })

                   control.at(index).patchValue({
                    hrElementPercentage : perelement.elementPercentage,
                
                   })
                 }
              
              });
           
           }else{
            // control.at(index).patchValue({
            //   salaryPerMonth :0,
            //   salaryPerAnnum : 0
            // })
           }
          
     });
      
  
  
  }

  calcuatectc(event)
  {
     if(event.target.value.length != 0)
     {
      let ctc = event.target.value;
      const control = this.postForm.get('tableRows') as FormArray;
      control.value.forEach((element , index ) => {

       
         if(element.hrElementPercentage)
         {
          if(element.elementName.toUpperCase() === "HRA" || element.elementName.toUpperCase() === " PF" || element.elementName.toUpperCase() === "EMPLOYER PF" 
          ||   element.elementName.toUpperCase() === "GRATUITY"  )
           {
              // if(element.elementName.toUpperCase() === "EMPLOYER PF")
              // {
              //    let emptyelement; 
              //   control.at(index).patchValue({
              //     salaryPerMonth : 0.00,
              //     salaryPerAnnum : 0.00
               
              //   })
              // }
  
            console.log("if");
            console.log(this.postForm.value.ctc);
            let partialAmount = this.calculatePartialAmount(element.hrElementPercentage, control.at(0).value.salaryPerAnnum);
            let salaryPerMonth =  partialAmount / 12;
            console.log(this.postForm.value.ctc);
    
            control.at(index).patchValue({
              salaryPerMonth : salaryPerMonth?.toFixed(2),
              salaryPerAnnum : partialAmount?.toFixed(2)
            })
           }else
           {
            console.log(this.postForm.value.ctc);
            let partialAmount = this.calculatePartialAmount(element.hrElementPercentage, this.postForm.value.ctc);
            let salaryPerMonth =  partialAmount / 12;
            console.log(this.postForm.value.ctc);
    
            control.at(index).patchValue({
              salaryPerMonth : salaryPerMonth?.toFixed(2),
              salaryPerAnnum : partialAmount?.toFixed(2)
            })
           }
           
         }
      });
        console.log(control);
         this.showctcerror = false;
     }
     
     
  }

  calcuatectcline(group , i){
       console.log(group);
       const control = this.postForm.get('tableRows') as FormArray;
      if(this.postForm.value.ctc)
      {
           
        console.log(group.value.elementName.toUpperCase().includes("EMPLOYEE PF"))
         if(group.value.elementName.toUpperCase().includes("HRA" ) || group.value.elementName.toUpperCase().includes("PF" ) || group.value.elementName.toUpperCase().includes("ESI" )
        ||   group.value.elementName.toUpperCase().includes("GRATUITY"  )  )
         {
             console.log("element name" + group.value.elementName.toUpperCase())
            // if(group.value.elementName.toUpperCase() === "EMPLOYER PF" || group.value.elementName.toUpperCase().includes("EMPLOYER ESI"))
            // {
            //   console.log("if employee pf")
            //   control.at(i).patchValue({

            //     salaryPerMonth : 0.00,
            //     salaryPerAnnum : 0.00
            //   })
            // }else 
            console.log("group.value.elementName.toUpperCase().co" + group.value.elementName.toUpperCase().includes("PF"))
            
            if(group.value.elementName.toUpperCase().includes("PF"))
            {
                  console.log("this.postForm.value.ctc <= this.maxpfamount " + this.postForm.value.ctc >= this.maxpfamount)
                 if(this.postForm.value.ctc >= this.maxpfamount )
                 {
                     if(group.value.elementName.toUpperCase().includes("EMPLOYEE PF"))
                     {
                      control.at(i).patchValue({
                        salaryPerMonth : this.employeePfAmount?.toFixed(2),
                        salaryPerAnnum : Number(this.employeePfAmount*12)?.toFixed(2)
                      })
                     }else{
                      control.at(i).patchValue({
                        salaryPerMonth : this.employerPfAmount?.toFixed(2),
                        salaryPerAnnum : Number(this.employerPfAmount*12)?.toFixed(2)
                      })
                     }
                 

                 }else{

                  console.log(this.postForm.value.ctc);
                  let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, control.at(0).value.salaryPerAnnum);
                  let salaryPerMonth =  partialAmount / 12;
                  console.log("salaryPerMonth : " +salaryPerMonth);
                  console.log(this.postForm.value.ctc);
          
                  control.at(i).patchValue({
                    salaryPerMonth : salaryPerMonth?.toFixed(2),
                    salaryPerAnnum : partialAmount?.toFixed(2)
                  })
                 }
            }else if(group.value.elementName.toUpperCase().includes("ESI"))
            {
                     console.log("this.postForm.value.ctc" + this.postForm.value.ctc);
                     console.log(" this.maxesiamount" +  this.maxesiamount);
                     console.log(" this.postForm.value.ctc <= this.maxesiamount" +  this.postForm.value.ctc <= this.maxesiamount);

                 if(this.postForm.value.ctc >= this.maxesiamount )
                 {
                   console.log("max amount")
                   if(group.value.elementName.toUpperCase().includes("EMPLOYEE ESI"))
                   {
                  control.at(i).patchValue({
                    salaryPerMonth : this.employeeEsiAmount?.toFixed(2),
                    salaryPerAnnum : Number(this.employeeEsiAmount*12)?.toFixed(2)
                  })

                } else{
                  control.at(i).patchValue({
                    salaryPerMonth : this.employerEsiAmount?.toFixed(2),
                    salaryPerAnnum : Number(this.employerEsiAmount*12)?.toFixed(2)
                  })
                }
                 }else{

                  console.log( "else" + this.postForm.value.ctc);
                  let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, control.at(0).value.salaryPerAnnum);
                  let salaryPerMonth =  partialAmount / 12;
                  console.log(this.postForm.value.ctc);
          
                  control.at(i).patchValue({
                    salaryPerMonth : salaryPerMonth?.toFixed(2),
                    salaryPerAnnum : partialAmount?.toFixed(2)
                  })
                 }
            }
            
            else{
              console.log(this.postForm.value.ctc);
              let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, control.at(0).value.salaryPerAnnum);
              let salaryPerMonth =  partialAmount / 12;
              console.log(this.postForm.value.ctc);
      
              control.at(i).patchValue({
                salaryPerMonth : salaryPerMonth?.toFixed(2),
                salaryPerAnnum : partialAmount?.toFixed(2)
              })
            }

          
        
         }else
         {
          console.log(this.postForm.value.ctc);
          let partialAmount = this.calculatePartialAmount(group.value.hrElementPercentage, this.postForm.value.ctc);
          let salaryPerMonth =  partialAmount / 12;
          console.log(this.postForm.value.ctc);
  
          control.at(i).patchValue({
            salaryPerMonth : salaryPerMonth?.toFixed(2),
            salaryPerAnnum : partialAmount?.toFixed(2)
          })
         }
       
         
      }
    
  }
  checkelement(value)
  {
     console.log("value" +value)
     if(value != null){
        if(!value.includes('Basic') &&  !value.includes('HRA')) 
        {
           return true;
        }else{
          return false;
        }
     }
  }

   calculatePartialAmount(percentage, totalAmount) {
    return (percentage / 100) * totalAmount;
  }

  get validate() { return this.postForm.controls; }

  empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      // this.payrollService.getEmployeeNameList(employeenumber, this.organizationId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }
      
      // })

      this.payrollService.getClienEmployeeList( this.postForm.value.employee , this.postForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode)
        {
          
        }else{
            this.empList = result;
        }
     
     })

    }
    
  }

  empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
  
      this.postForm.patchValue({
        employee: this.employee 
     });
     this.payrollService.getannexueversionbyemployee(this.employeeId).subscribe((result: any) => {
      if(result.statusCode)
      {
        
      }else{
        var date = new Date(result.effectiveToDate)

        // Add a day
        date.setDate(date.getDate() + 1)
        this.maxstartdate = date ; 
      }
   
   })

   
   this.payrollService.getEmployeeClientList(emp.employeeNumber , this.postForm.value.client).subscribe((result: any) => {
    if(result.statusCode)
    {
      
    }else{
        this.clientemployee = result;
        console.log(  this.clientemployee)
        console.log( this.clientemployee[0].ratePerMonth)
           this.postForm.patchValue({
            ctc : Number(this.clientemployee[0].ratePerMonth)
           })
    }
 
 })
     
   
  }

  getclientEmployees()
  {
      this.empList.length = 0;
       console.log(this.postForm.value.client)
      if(this.postForm.value.client != null && this.postForm.value.client != "null")
      {
        this.payrollService.getemployeeclient(this.postForm.value.client).subscribe((ClientEmployees: any) => {
          if(ClientEmployees.length != undefined)
          {
            // ClientEmployees.forEach(element => {
            //   this.empList.push(element.employee);
            // });
          }
         
        });

        this.payrollService.clientelements(this.postForm.value.client).subscribe((result: any) => {
          if(result.statusCode)
          {
           this.elementlist.length = 0;
          }else{
            console.log("else")
             this.clientelements = result;
               this.maxpfamount = this.clientelements[0].pfMaxSalaryAmount;
               this.employeePfAmount = this.clientelements[0].employeePfAmount;
               this.employerPfAmount = this.clientelements[0].employerPfAmount;

               this.maxesiamount = this.clientelements[0].esiMaxSalaryAmount;
               this.employeeEsiAmount = this.clientelements[0].employeeEsiAmount;
               this.employerEsiAmount = this.clientelements[0].employerEsiAmount;

          //     result.forEach(cleintelemets => {
          //       cleintelemets.lines.forEach(lines => {
          //           this.elementlist.push(lines.element);
          //       });

          //     });
        
          // this.filterele =  this.elementlist.filter(ele=> ele.elementName.includes( "Basic" ) ||  ele.elementName.includes( "HRA" ) );
          // this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
          //        console.log(this.filterele);
  
          // const control = this.postForm.get('tableRows') as FormArray;
                 
          // console.log("this.filterele",this.filterele);
          // this.filterele.forEach((element ,index) => {
          //          this.addRow();
          //           if( element.elementName != "HRA")
          //           {
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,
          //               hrElementPercentage :  element.percentage ? element.percentage.elementPercentageId : 0,
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }else{
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,                  
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }
          //     });
        //  console.log(control); 
          }
       
       })
    }

    
  }

  getelemenetpercentage(group ,i,event)
  {
    const control = this.postForm.get('tableRows') as FormArray;
    console.log(control);
   let elementexist =false;
    //control.value.some(e=> e.employeeId == event.target.value &&  i != index);
    control.value.forEach((element,index) => {
        if(element.element == event.target.value && i != index)
        {
            elementexist = true;
        }
    });

     if(!elementexist)
     {
      this.elementlist.forEach((element)=> {   
        console.log(element);    
          if(element.elementId ==  event.target.value)               
          {
           
            if(element.elementName.toUpperCase() == "ALLOWANCE" && this.totalannualincome < this.postForm.value.ctc)
            {
               
              control.at(i).patchValue({
            
           //     salaryPerMonth :0.00,
           //     salaryPerAnnum : 0.00    
              })
                //  console.log("ctc" + this.postForm.value.ctc);
                //  console.log("this.totalannualincome" + this.totalannualincome);
                 let salaryannum  = this.postForm.value.ctc -  this.totalannualincome;
                 salaryannum = Math.abs(salaryannum);
                 let salaryPerMonth =  salaryannum / 12;
                 control.at(i).patchValue({
                  elementName : element.elementName,
                  hrElementPercentagelist : element.hrElementPercentage ,
                  salaryPerMonth : salaryPerMonth?.toFixed(2),
                  salaryPerAnnum : salaryannum?.toFixed(2) 
                })
            }else{
              control.at(i).patchValue({
                elementName : element.elementName,
                hrElementPercentagelist : element.hrElementPercentage     ,
             //   salaryPerMonth :0.00 ,
              //  salaryPerAnnum : 0.00      
              })
            }
           
          }          
      
  
        
      })
     }else{
          alert("Element already exist");
          control.at(i).patchValue({
            elementName : null,
            element: null,
            salaryPerMonth :0.00 ,
                salaryPerAnnum : 0.00      
          })
     }
    
 
  }

  addelement()
  {
     if(this.postForm.value.ctc)
     {
      const control =  this.postForm.get('tableRows') as FormArray;
      this.addRow();
     }else{
         this.showctcerror = true;
     }
   
  }

  addRow() {
    const control =  this.postForm.get('tableRows') as FormArray;
    control.push(this.initiateForm());
  }
  initiateForm(): FormGroup {
   
     return this.formBuilder.group({
      annexureLineId : [null],
      elementName : [null],
      element: [null , Validators.required] ,
      hrElementPercentagelist:[[]],
      hrElementPercentage: [ null],
      salaryPerMonth: [null, [Validators.required]],
      salaryPerAnnum: [null, [Validators.required]],
      status : [ null],
      isEditable: [true]
    });
 }


 calucuatemontly()
 {
   let totalAmount = 0;
  const control =  this.postForm.get('tableRows') as FormArray;
         control.controls.forEach(element => {
          totalAmount = Number(totalAmount) + Number(element.value.salaryPerMonth);
       });
         this.totalmontlyincome = totalAmount.toFixed(2);
       return totalAmount;
 }

 calucuateyearly()
 {
   let totalAmount = 0;
  const control =  this.postForm.get('tableRows') as FormArray;
         control.controls.forEach(element => {
          totalAmount = Number(totalAmount) + Number(element.value.salaryPerAnnum);
       });
       this.totalannualincome = totalAmount;
       return totalAmount;
 }

  calucuateyearlynopercentage(group , i)
  {
    const control = this.postForm.get('tableRows') as FormArray;
      let totalamuualamount =      group.value.salaryPerMonth * 12;                                        
    control.at(i).patchValue({
      salaryPerAnnum :  totalamuualamount
    })

  }

  pageChages()
  {
    this.p=1;
  }
  onSubmit()
  {
    //alert("form submitted");
    this.submitted = true;
  //  const controls = this.postForm.controls;
  // console.log("postForm" + this.postForm.invalid)
       if(this.postForm.value.annexuretype == 59)
       {
         console.log("montly");
        this.checktotalamount = this.postForm.value.ctc * 12 == this.totalannualincome
       }else{
        console.log("yearly"); 
        this.checktotalamount = this.postForm.value.ctc == this.totalannualincome
       }

     
    
      console.log("checktotalamount : " + this.checktotalamount);
      if (this.postForm.invalid  || this.checktotalamount == false) {
        console.log("invalid")
          console.log(this.postForm.controls);
          for (const name in this.postForm.controls) {
              if (this.postForm.controls[name].invalid) {
                console.log(name);
              }
          } 
          return;
      }
     
      // console.log("checktotalamount : " + this.checktotalamount);
      // if (this.postForm.invalid) {
      //   console.log("invalid")
      //     console.log(this.postForm.controls);
      //     for (const name in this.postForm.controls) {
      //         if (this.postForm.controls[name].invalid) {
      //           console.log(name);
      //         }
      //     } 
      //     return;
      // }
     
 

    if(this.empannexueId) //Update Employee annexue
    {
      
      let postValues = this.postForm.value;
      let annuexdata =  {
        annexureHeaderId : this.empannexue.annexureHeaderId ,
        ctc: postValues.ctc,
        effectiveFromDate: this.datepipe.transform(postValues.startDate,'yyyy-MM-dd'),
        effectiveToDate :this.datepipe.transform(postValues.endDate,'yyyy-MM-dd'),
        place:postValues.place,
        // employee: {
        //   employeeId: this.employeeId 
        // },
        livingCity: {
          listTypeValueId: postValues.livingCity
        },
        status :  this.empannexue.status,
        draft:true,
        annexureType : {
          listTypeValueId: postValues.annexuretype
        },
      
      }
        if(this.employeeId)
        {
          annuexdata["employee"] = {
            employeeId: this.employeeId 
          }
        }else{

          annuexdata["client"] = {
            clientId:postValues.client
        }
      }
      const control = this.postForm.get('tableRows') as FormArray;
      this.touchedRows = control.controls.filter(row => row).map(row => row.value);
         let linearray = [];
        this.touchedRows.forEach(element => {
          
           let line = {
            annexureLineId : element.annexureLineId,
            element: {
              elementId: element.element
            },
            elementPercentage : element.hrElementPercentage,
            salaryPerMonth: element.salaryPerMonth,
            salaryPerAnnum: element.salaryPerAnnum,
            status: element.status
           }
           linearray.push(line);
        });

            this.deletedrows.forEach(element => {
              let line = {
                annexureLineId : element.annexureLineId,
                element: {
                  elementId: element.element
                },
                elementPercentage : element.hrElementPercentage,
                salaryPerMonth: element.salaryPerMonth,
                salaryPerAnnum: element.salaryPerAnnum,
                status: {listTypeValueId : 4}
               }
               linearray.push(line);
            });
      
        annuexdata["lines"] = linearray;

         console.log(annuexdata);
       
      this. payrollService.updateEmployeeannexure(annuexdata , this.empannexueId).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);
        
         
          setTimeout(() => {
            this.router.navigate(['/home/client-employee-annexue-list']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
        }

      }, err =>
      {
        
        setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
            // this.router.navigate(['/home/financialyear']);
          }, redirectMsgTimeOut)
      })

    }
    else  //Add Employee annexue
    {
            let postValues = this.postForm.value;
                let annuexdata =  {
                  
                    ctc: postValues.ctc,
                    effectiveFromDate: this.datepipe.transform(postValues.startDate,'yyyy-MM-dd'),
                    effectiveToDate :this.datepipe.transform(postValues.endDate,'yyyy-MM-dd'),
                    place:postValues.place,
                   
                    livingCity: {
                      listTypeValueId: postValues.livingCity
                    }, annexureType : {
                      listTypeValueId: postValues.annexuretype
                    },
                  
                  
                    
                  }

                  if(this.employeeId)
                  {
                    annuexdata["employee"] = {
                      employeeId: this.employeeId 
                    }
                  }else{

                    annuexdata["client"] = {
                      clientId:postValues.client
                  }
                }

                  const control = this.postForm.get('tableRows') as FormArray;
                  this.touchedRows = control.controls.filter(row => row).map(row => row.value);
                     let linearray = [];
                    this.touchedRows.forEach(element => {
                      
                       let line = {
                        element: {
                          elementId: element.element
                        },
                        elementPercentage : element.hrElementPercentage,
                        salaryPerMonth: element.salaryPerMonth,
                        salaryPerAnnum: element.salaryPerAnnum,
                       }
                       linearray.push(line);
                    });
                  
                    annuexdata["lines"] = linearray;

                     console.log(annuexdata);
                 //return
                  this. payrollService.assignEmployeeannexure(annuexdata).subscribe((result: any) =>
                  {
                    if(result.statusCode == 200)
                    {
                      this.success = true;
                      this.sucess_msg = result.description;      
                      setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                      //this.router.navigate(['/home/employee']);
                    
                     
                      setTimeout(() => {
                        this.router.navigate(['/home/client-employee-annexue-list']);
                      }, redirectMsgTimeOut)
                    }
                    else
                    {
                      this.error = true;
                      this.error_msg = result.description;
                    }
  
                  }, err =>
                  {
                    
                      setTimeout(() => {
                        this.error = true;
                        this.errorMsg = err.error.description;
                        // this.router.navigate(['/home/financialyear']);
                      }, redirectMsgTimeOut)
                  })


            
     
          
    }


  }

}
