<!-- Sub header start here-->
<section>
    <div class="sub-header">
      <div class="col-md-12 mb-3">
        <div class="row">
          <div class="">
            <span  class="h5 sub-content">
              {{ legalentityId ? "Edit" : "Add" }} Legal Entity</span
            >
          </div>
          <div class="sub-header-buttons">
            <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Sub header end here-->
  
  <div class="common-content">
    <div class="card-new">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="legalEntitygroup" (ngSubmit)="onSubmit()">
            <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Business Group Name<span class="star">*</span></label
              >
              <mat-select
                formControlName="businessgroup"
                placeholder="Select Business Group Name"
                class="form-control email-input select-placeholder"
                (selectionChange)="selectionChangeOfBusinessGroup($event)"
              >
                <mat-option [value]="undefined" selected="selected">-Select Business Group Name-</mat-option>
                <mat-option *ngFor="let row of businessList; let i=index;" [value]="row.businessGroupId">{{row.businessGroupName}} 
                </mat-option>
                <!-- <mat-option value="Service Based">Service Based</mat-option> -->
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  (validate?.businessgroup?.invalid &&
                    validate?.businessgroup?.touched) ||
                  validate?.businessgroup?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate?.businessgroup?.errors?.required"
                  class="text-danger"
                  >Please Select Business Group Name</span
                >
              </div>
            </div>

              <div class="form-group col-md-3" style="height: 70px !important;">
                <label class="form-control-label"
                  >Legal Entity Name <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpaceWord($event)"
                  (keydown)="validateInputSpaceWord($event)"
                  formControlName="legalEntityName"
                  class="form-control email-input select-placeholder"
                  autocomplete="off"
                  placeholder="Enter Legal Entity Name"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate?.legalEntityName?.invalid &&
                      validate?.legalEntityName?.touched) ||
                    validate?.legalEntityName?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate?.legalEntityName.errors?.required"
                    class="text-danger"
                    >Please Enter Legal Entity Name</span
                  >
                  <span
                    *ngIf="validate?.legalEntityName.errors?.pattern"
                    class="text-danger"
                    >Please Enter Valid Legal Entity Name</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3" style="height: 70px !important">
                <label class="form-control-label">Description </label>
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="description"
                  class="form-control email-input select-placeholder"
                  autocomplete="off"
                  placeholder="Enter Description"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate?.description?.invalid &&
                      validate?.description?.touched) ||
                    validate?.description?.dirty
                  "
                >
                  <span
                    *ngIf="validate?.description.errors?.pattern"
                    class="text-danger"
                    >Please Enter Valid Description</span
                  >
                </div>
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label"
                  > Entity Type<span class="star">*</span></label
                >
                <!-- <mat-select
                  formControlName="entityType"
                  placeholder="Select Entity Type"
                  class="form-control email-input select-placeholder"
                  (selectionChange)="entityTypeSelectionChange($event)"

                >
                  <mat-option [value]="undefined" selected="selected">-Select Entity Type-</mat-option>
                 
                  <mat-option [value]="ent.listTypeValueId" *ngFor="let ent of entityList">{{ent.listTypeValueName}}</mat-option>
                </mat-select> -->

                <input
                type="text"
                (keyup)="entityTypeSearch($event)"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                formControlName="entityType"
                class="form-control email-input select-placeholder"
                autocomplete="off"
                placeholder="Enter Entity Type"
              />
              <input
              type="hidden"
              formControlName="entityTypeId"
              id="entityType"
              class="form-control email-input select-placeholder mb-3 mb-lg-0"
              autocomplete="off"
            />
            
    
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate?.entityType?.invalid &&
                      validate?.entityType?.touched) ||
                    validate?.entityType?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate?.entityType.errors?.required"
                    class="text-danger"
                    >Please Select Entity Type</span
                  >
                </div>
                <span *ngIf="autoCompleteCountrySearch1 == 0">
                  <ul
                    class="auto-complete-country"
                    *ngIf="entytyData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterDataEntity(
                          searchDataset.listTypeValueId,
                          searchDataset.listTypeValueName)"
                      *ngFor="let searchDataset of entytyData"
                    >
                      {{ searchDataset.listTypeValueName}}
                    </li>
                  </ul>
                </span>
              </div>
  
              <div class="form-group col-md-3" >
                <label class="form-control-label"
                  >Registration Number</label
                >
                <input
                type="tel"
                formControlName="regNumber"
                maxlength="21"
                minlength="21"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                (input)="onRegNumberInput($event)"
                class="form-control email-input select-placeholder"
                autocomplete="off"
                placeholder="Registration Number"
              />
                <span class="small smallDesign">EX:L12345TN1234ORG123456</span>
                <div
                class="valid-msg"
                 *ngIf="
        (legalEntitygroup.get('regNumber')?.invalid &&
          legalEntitygroup.get('regNumber')?.touched) ||
        legalEntitygroup.get('regNumber')?.dirty
      "
              >
              <!-- <span
                  *ngIf="legalEntitygroup.get('regNumber').errors?.required"
                  class="text-danger"
                >
                  Please Enter Registration Number
                </span> -->
                <span
                  *ngIf="validate?.regNumber.errors?.pattern"
                  class="text-danger"
                >
                  Please Enter a Valid Registration Number
                </span>
              </div>

              </div>
              <div class="form-group col-md-3" *ngIf="showRegistrationDate">
                <label class="form-control-label">Registration Date <span class="star">*</span></label>
                <input
                formControlName="registrationDate"
                matInput
                [max]="today"
                [matDatepicker]="regValidityDate"
                [disabled]="legalEntitygroup.get('registrationDate').disabled"
                class="form-control email-input select-placeholder mb-3 mb-lg-0 email-input"
                placeholder="Select Registration Date"
                readonly
              />
            
              <!-- Date Picker Toggle -->
              <mat-datepicker-toggle
                matSuffix
                [for]="regValidityDate"
                style="float: right; position: relative; bottom: 38px"
              ></mat-datepicker-toggle>
              <mat-datepicker #regValidityDate></mat-datepicker>
                <div
                class="valid-msg"
                *ngIf="
                (legalEntitygroup.get('registrationDate')?.invalid &&
                  legalEntitygroup.get('registrationDate')?.touched) ||
                legalEntitygroup.get('registrationDate')?.dirty ||
                submitted1
              "
              >
              <span
                  *ngIf="validate?.registrationDate.errors?.required"
                  class="text-danger"
                >
                  Please Enter Registration Date
                </span>
                
              </div>
              </div>

              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Country of Incorporation <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="countryAutoCompleteSearch($event,$event)"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                 

                  formControlName="countryName"
                  class="form-control email-input select-placeholder"
                  placeholder="Search Country Name"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  formControlName="countryId"
                  id="countryId" 
                  class="form-control email-input select-placeholder mb-3 mb-lg-0"
                  autocomplete="off"
                />
                <div
                class="valid-msg"
                *ngIf="
                  (validate?.countryName?.invalid &&
                      validate?.countryName?.touched) ||
                    validate?.countryName?.dirty || submitted
                "
              >
              <span
                  *ngIf="validate?.countryName.errors?.required"
                  class="text-danger"
                >
                  Please Enter Country Name 
                </span>
                <span
                *ngIf="validate?.countryName.errors?.pattern"
                class="text-danger"
              >
                Please Enter valid Country Name 
              </span>
                
              </div>

                <!-- AutoComplete Search here -->
                <span >
                  <ul
                    class="auto-complete-country"
                    *ngIf="autoCompleteCountrySearch === 0 && countryData?.length > 0"
                  >
                    <li
                      (click)="
                        getFilterData(
                          searchData.countryId,
                          searchData.countryName,
                          1
                        )
                      "
                      *ngFor="let searchData of countryData"
                    >
                      {{ searchData.countryName }}
                    </li>
                  </ul>
                </span>
                <!-- AutoComplete End here -->
              </div>
              <div class="form-group col-md-3" style="height: 70px !important">
                <label class="form-control-label">Legal Address <span class="star">*</span> </label>
                <input
                  type="text"
                  formControlName="legalAddress"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  class="form-control email-input select-placeholder only_name"
                  placeholder="Enter Legal Address"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate?.legalAddress?.invalid &&
                      validate?.legalAddress?.touched) ||
                    validate?.legalAddress?.dirty || submitted
                  "
                >
                <span
                    *ngIf="validate?.legalAddress.errors?.required"
                    class="text-danger"
                    >Please Enter Legal Address</span
                  >
                  <span
                    *ngIf="validate?.legalAddress.errors?.pattern"
                    class="text-danger"
                    >Please Enter Valid Legal Address</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3" style="height: 70px !important">
                <label class="form-control-label">Operating Address <span class="star">*</span> </label>
                <input
                  type="text"
                  formControlName="operatingAddress"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  class="form-control email-input select-placeholder only_name"
                  placeholder="Enter Operating Address"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate?.operatingAddress?.invalid &&
                      validate?.operatingAddress?.touched) ||
                    validate?.operatingAddress?.dirty || submitted
                  "
                >
                <span
                    *ngIf="validate?.operatingAddress.errors?.required"
                    class="text-danger"
                    >Please Enter  Operating Address</span
                  >
                  <!-- <span
                    *ngIf="validate?.operatingAddress.errors?.pattern"
                    class="text-danger"
                    >Please Enter Valid Operating Address</span
                  > -->
                </div>
              </div>
             
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <a
                  mat-raised-button
                  style="
                       margin-left: 10px;
      background: none;
      color: var(--Red-Color, #ff5d5d);
      border: 1px solid #ddd !important;
      height: 36px;
      position: relative;
      top: -2px;
      font-weight: 550;
      padding-top: 10px;
                  "
                  class="btn search-btn mr-2"
                  [disabled]="isbtnDisables"
                  (click)="clearForm()"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  mat-raised-button
                  class="n m-1 searchBtn"
                  [disabled]="isbtnDisables"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="loader" class="align-items-cEnter text-cEnter">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top: 51%"
      role="status"
    ></div>
  </div>
  
  <div class="text-cEnter" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>
  