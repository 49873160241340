import { Component, OnInit } from '@angular/core';
import { MatDialog , MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';
//import { AddRoleComponent } from 'src/app/settingsComponents/roleComponent/add-role/add-role.component';
import { ViewRoleComponent } from 'src/app/settingsComponents/roleComponent/view-role/view-role.component';
import { UserroleComponent } from '../userrole/userrole.component';
import { SuperadminService } from 'src/app/providers/superadmin.service';

@Component({
  selector: 'app-userrolelist',
  templateUrl: './userrolelist.component.html',
  styleUrls: ['./userrolelist.component.scss']
})
export class UserrolelistComponent implements OnInit {
  resultData: any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;
  errorMsg : any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  sortedData: any[];
  nodatafound: boolean = false;

  menuData : any[];
  filteredData: any;


  constructor(
      private settingsservice: SettingsService,
      private dialog:MatDialog, private  superadminservice: SuperadminService 
  ) { }

  ngOnInit(): void {
    this.getRoleList();
  }

  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(UserroleComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getRoleList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData)
  {
    var dialogRef = this.dialog.open(UserroleComponent, {
      width: '500px',height:'fit-content',
      data:editData
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getRoleList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }

  updateStatus(roleId,status)
	{
    this.settingsservice.updateRoleStatus(roleId,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.getRoleList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  getRoleList()
  {
    /* Get Designation Data */
    this.superadminservice.getUserRoleList().subscribe((result: any) => {
      this.resultData = result;
      if(result.statusCode==204)
        {
          console.log("nodata" ,this.resultData.length);
          this.nodatafound=true;
        }
        if(this.resultData.length==0)
        {
          console.log(this.resultData.length)
          this.nodatafound=true;
        }
    },err =>{
       this.errorMsg = err.error.message;
       console.log(this.errorMsg);
    })
  }

  ucFirst(string)
  {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  viewForm(roleId) //View Dialog Box
  {
    const dialogRef = this.dialog.open(ViewRoleComponent, {
    width: '500px',height:'auto',
    data:{roleId}
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getRoleList();

      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  /* menuNames(menus)
  {
    if(menus.length >0)
    {
      let menuNames = menus.map(item => {
        return item.menu;
       });


       console.log("AD1:",menus);


       this.menuData =  menus;
    }
  } */

  sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '')
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) =>
    {
      const isAsc = sort.direction === 'asc';

      switch (sort.active)
      {
        case 'roleName':
          return compare(a.roleName, b.roleName, isAsc);

        case 'roleDescription':
          return compare(a.roleDescription, b.roleDescription, isAsc);

        default:
          return 0;
      }
    });
    
  }
  applyFilter(): void {
    
    console.log('resultData:', this.resultData?.length);
    console.log('filteredData:', this.filteredData?.length);
    console.log('applyFilter() called'); // Add this line
    this.p = 1;
    const normalize = (str: string) => str.toLowerCase().replace(/\s+/g, ''); 

    this.filteredData = this.resultData.filter(employee =>
        normalize(employee.roleName).includes(normalize(this.tableSearch))
       
    );
     

      if (this.filteredData.length > 0) {
        console.log("111111111",this.filteredData)
        this.nodatafound = false; 
      } else {
        console.log("222222222",this.filteredData)
        this.nodatafound = true; 
        this.filteredData = [];

      } 
    console.log(this.filteredData) }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

