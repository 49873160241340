import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
import { AddCityComponent } from '../add-city/add-city.component';
import { httpOptions,redirectMsgTimeOut,responseMsgTimeOut} from '../../../providers/properties';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import {Sort} from '@angular/material/sort';
@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit 
{
  public searchFilter: any = '';
  searchForm!:FormGroup;
  constructor(
    private settingsservice: SettingsService,
    private dialog:MatDialog, 
  ) 
  { }

  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;
  
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;

  loader:any= false;
  backdrop:any= false;
  nodatafound: boolean = true;
  sortedData: any[];
  ngOnInit(): void 
  {
    //this.cityList();
    this.getFilterFormData();
  }

  getFilterFormData()
  {
    this.searchForm = new FormGroup(
      {
        keywords : new FormControl(this.searchForm) 
      }
    );
  }

  searchSubmit()
  {
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
    
    if(keywords == null)
    {
      alert('Please search keywords');
      return;
    }

    if(keywords)
    {
      this.loader=true;
      this.backdrop=true;

      this.settingsservice.getCityFilterList(keywords).subscribe((resultData: any) => 
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
       
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
  }


  cityList()
  {

    let keywords = this.searchForm.value.keywords;
    
    if(keywords)
    {
      this.loader=true;
      this.backdrop=true;

      this.settingsservice.getCityFilterList(keywords).subscribe((resultData: any) => 
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
       
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
    else
    {
      this.settingsservice.getCityList().subscribe((resultData: any) => 
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
       
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      },err =>{
        //this.errorMsg = err.error.message;
      })
    }
  }

  addForm() //Add dialog box
  {
    const dialogRef = this.dialog.open(AddCityComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result => 
    {
      //this.cityList();
     
      if(result.statusCode == 200)
      {
        this.success = true;          
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData) //Update dialog box
  {
    var dialogRef = this.dialog.open(AddCityComponent, { 
      width: '500px',height:'fit-content',
      data:editData
    });   
    
    dialogRef.afterClosed().subscribe(result => 
    {
      this.cityList();
      
      if(result.statusCode == 200)
      {
        this.success = true;          
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }
  
  updateStatus(id,status)
	{
    this.settingsservice.updateCityStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;          
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.cityList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  ucFirst(text)
  {
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
  
  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'countryName':
          return compare(a.countryName, b.countryName, isAsc);

        case 'stateName':
          return compare(a.stateName, b.stateName, isAsc);
        
        case 'districtName':
          return compare(a.districtName, b.districtName, isAsc);
        
        case 'cityName':
          return compare(a.cityName, b.cityName, isAsc);

        default:
          return 0;
      }
    });
  }

  Clear(){
    this.resultData=[];
    this.nodatafound=true;
   
     }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

