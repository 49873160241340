<mat-card class="projects-card" style="margin-top: 80px">
  <div style="margin-top: 10px" class="main-content">
    <div class="container-fluid">
      <div class="top-content row">
        <div class="col-xl-2 col-lg-2 col-sm-12 col-xs-12">
          <h4 class="projects heading-tag">Projects</h4>
        </div>

        <div class="col-xl-3 col-md-2 col-lg-2 col-sm-12 col-xs-12">
          <!-- <ul class="unstyled inbox-pagination">
                <div class="sidebar-search"> -->
          <div class="input-group">
            <input
              type="text"
              class="form-control search search-menu search-placeholder"
              (keyup)="changePlaceholderColor($event)"
              placeholder="Search..."
              [(ngModel)]="search"
              name="searchText"
            />
          </div>
          <!-- </div>
            </ul> -->
        </div>

        <!-- *ngIf="
        user_type == 'HumanResource' ||
        user_type == 'Manager' ||
        user_type == 'TeamLeader' ||
        user_type == 'Admin'
      " -->
        <div
          id="loginform"
          class="col-md-3 col-xl-3 col-lg-3 col-sm-12 mb-3"
          *ngIf="this.isAdmin || this.isHumanResource"
        >
          <mat-select
            [(ngModel)]="filterByProjectStatus"
            placeholder="Select project Stage"
            class="form-control email-input select-placeholder"
            (selectionChange)="onProjectStatusChange($event)"
          >
            <mat-option
              *ngFor="let project of projectStatus"
              [value]="project.listTypeValueId"
            >
              {{ project.listTypeValueName }}
            </mat-option>
          </mat-select>
        </div>

        <!-- *ngIf="
        user_type != 'HumanResource' &&
        user_type != 'Manager' &&
        user_type != 'TeamLeader' &&
        user_type != 'Admin'
      " -->
        <div
          id="loginform"
          class="col-md-3 col-xl-3 col-lg-3 col-sm-12 mb-3"
          *ngIf="!this.isAdmin && !this.isHumanResource"
        >
          <mat-select
            [(ngModel)]="filterByProjectStatusEmployeeProjects"
            placeholder="Select project Stage"
            class="form-control email-input select-placeholder"
            (selectionChange)="onProjectStatusChangeByEmployee($event)"
          >
            <mat-option
              *ngFor="let project of projectStatusByEmployee"
              [value]="project.listTypeValueId"
            >
              {{ project.listTypeValueName }}
            </mat-option>
          </mat-select>
        </div>

        <div
          class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12"
          *ngIf="this.isAdmin || this.isHumanResource"
        >
          <button
            type="button"
            mat-raised-button
            class="searchBtn"
            (click)="addProject()"
          >
            <span>Add Project</span>
          </button>
        </div>
      </div>

      <!-- *ngIf="
      user_type == 'HumanResource' ||
      user_type == 'Manager' ||
      user_type == 'TeamLeader' ||
      user_type == 'Admin'
    " -->
      <div *ngIf="this.isAdmin || this.isHumanResource">
        <div>
          <div
            class="row"
            *ngIf="(projectList | project : search).length > 0; else noProjects"
          >
            <div
              class="col-xl-6 col-md-12"
              *ngFor="let project of projectList | project : search"
            >
              <div class="card">
                <div
                  class="mat-card-header col-xl-12 m-0"
                  style="background-color: #708fff"
                >
                  <div class="col-md-11 p-0">
                    <h5 class="m-0 project-name projects text-truncate">
                      {{ project?.projectName }}
                    </h5>
                  </div>

                  <div class="col-md-1 float-right pr-0">
                    <span
                      class="material-icons-outlined action"
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      more_vert
                    </span>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xxl-4 col-xl-4 col-md-4">
                        <div class="project-text-heading d-flex">
                          <div>
                            <h4 class="">Description:</h4>
                          </div>
                          <div
                            class="text-truncate project-description project-text-size ml-custom"
                          >
                            {{ project?.projectDescription }}
                          </div>
                        </div>
                        <div class="project-text-heading d-flex">
                          <h4 class="">Start&nbsp;Date:</h4>
                          <div
                            class="text-truncate project-others project-text-size ml-custom"
                          >
                            {{ projectStage(project?.projectStartDate | date) }}
                          </div>
                        </div>

                        <div class="project-text-heading d-flex">
                          <h4 class="">End&nbsp;Date:</h4>
                          <div
                            class="text-truncate project-others project-text-size ml-custom"
                          >
                            {{ projectStage(project?.projectEndDate | date) }}
                          </div>
                        </div>

                        <div class="project-text-heading d-flex">
                          <h4 class="">Project&nbsp;Stage:</h4>
                          <div
                            class="text-truncate project-description project-text-size ml-custom"
                          >
                            {{
                              projectStage(
                                project?.projectStage?.projectstagename
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-md-4">
                        <!-- <div class=""> -->
                        <div
                          class="project-status-bg"
                          [style.background-color]="
                            approvalBackground(
                              project?.projectStatus?.listTypeValueName
                            )
                          "
                        >
                          <div
                            class="project-status-txt"
                            [style.color]="
                              approvalStatus(
                                project.projectStatus?.listTypeValueName
                              )
                            "
                          >
                            {{ project?.projectStatus?.listTypeValueName }}
                          </div>
                        </div>
                        <div class="project-text-heading d-flex row-xs mt-2">
                          <h4 class="">Project&nbsp;Code:</h4>
                          <div
                            class="project-others project-text-size ml-custom"
                          >
                            {{ truncateProjectCode1(project?.projectCode) }}
                          </div>
                        </div>
                        <div class="project-text-heading d-flex">
                          <h4 class="">Project&nbsp;Budget&nbsp;Hours:</h4>
                          <div
                            class="project-others project-text-size ml-custom"
                            *ngIf="project.projectBudgetHours != undefined"
                          >
                            {{
                              truncateProjectCode(
                                project?.projectBudgetHours + ":00"
                              )
                            }}

                            <!-- {{
  truncateProjectCode(
    project.projectBudgetHours 
  )
}} -->
                          </div>
                        </div>

                        <div class="project-text-heading d-flex">
                          <h4 class="">Project&nbsp;Actual&nbsp;Hours:</h4>
                          <!-- <div
                            class="project-others project-text-size ml-custom"
                          >
                            {{
                              truncateProjectCode(
                                project?.projectAcutalHours.substring(0, 5)
                              )
                            }}
                          </div> -->
                          <div class="project-others project-text-size ml-custom">
                            {{
                              project?.projectAcutalHours ? truncateProjectCode(project?.projectAcutalHours.substring(0, 5)) : 'N/A'
                            }}
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                      <div class="col-xxl-4 col-xl-2 col-md-4">
                        <circle-progress
                          style="margin-left: 11px"
                          [percent]="project?.projectCompletionPercentage"
                          [radius]="50"
                          titleFontSize="22"
                          [outerStrokeWidth]="7"
                          [innerStrokeWidth]="7"
                          [outerStrokeColor]="'#78C000'"
                          [innerStrokeColor]="'#C7E596'"
                          [animation]="true"
                          [animationDuration]="300"
                          [space]="3"
                        ></circle-progress>
                      </div>
                    </div>
                  </div>
                  <div>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="viewEmployees(project)">
                        <mat-icon class="material-icons-outlined sub-icon"
                          >streetview</mat-icon
                        >
                        <span class="sub-content">Assign Employees</span>
                      </button>

                      <button
                        mat-menu-item
                        routerLink="emp-report/{{ project?.projectId }}"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >receipt_long</mat-icon
                        >

                        <span class="sub-content projects">View Reports</span>
                      </button>

                      <button
                        mat-menu-item
                        [routerLink]="['assign-task', project?.projectId]"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >add_task</mat-icon
                        >
                        <span class="sub-content projects">Add Task</span>
                      </button>

                      <button
                        mat-menu-item
                        routerLink="my-task/{{ project?.projectId }}"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >date_range</mat-icon
                        >
                        <span class="sub-content projects">My Tasks</span>
                      </button>
                      <button mat-menu-item (click)="edit(project)">
                        <mat-icon class="material-icons-outlined sub-icon"
                          >edit</mat-icon
                        >
                        <span class="sub-content projects">Edit</span>
                      </button>
                      <button mat-menu-item (click)="delete(project)">
                        <mat-icon class="material-icons-outlined sub-icon"
                          >delete</mat-icon
                        >
                        <span class="sub-content projects">Delete</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-template #noProjects>
            <mat-card
              class="card-content-nodata mat-card-nodata"
              *ngIf="!nodata && this.projectList.length !== 0"
            >
              <div class="card-body-nodata">
                <div class="alert-nodata alert-primary">
                  <h4 align="center" class="h4">Projects Not Found</h4>
                </div>
              </div>
            </mat-card>
          </ng-template>
        </div>

        <div
          class="col-xl-6"
          *ngFor="let project of projectList | project : search"
        >

        <div *ngIf="this.isAdmin || this.isHumanResource">
          <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata ">
            <div class="card-body-nodata">
              <div class="alert-nodata alert-primary">
                <h4 align="center" class="h4">Projects Not Found</h4>
              </div>
            </div>
          </mat-card>
        </div>
        </div>
      </div>
      <!-- 
      *ngIf="
      user_type != 'HumanResource' &&
      user_type != 'Manager' &&
      user_type != 'TeamLeader' &&
      user_type != 'Admin'
    " -->
      <div *ngIf="!this.isAdmin || !this.isHumanResource">
        <!-- <div>
          <div
            class="row"
            *ngIf="
              (empProjectList | project : search).length > 0;
              else noProjects
            "
          >
            <div
              class="col-xl-6 col-md-12"
              *ngFor="let project of empProjectList | project : search"
            >
              <div class="card">
                <div
                  class="mat-card-header col-xl-12 m-0"
                  style="background-color: #708fff"
                >
                  <div class="col-md-11 p-0">
                    <h5 class="m-0 project-name projects text-truncate">
                      {{ project.projectName }}
                    </h5>
                  </div>

                  <div class="col-md-1 float-right pr-0">
                    <span
                      class="material-icons-outlined action"
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      more_vert
                    </span>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="col-xs-12">
                      <div class="row col-xs-12">
                        <div class="col-md-6">
                          <div class="project-text-heading d-flex">
                            <div>
                              <h4 class="">Description:</h4>
                            </div>
                            <div
                              class="text-truncate project-description project-text-size ml-custom"
                            >
                              {{ project.projectDescription }}
                            </div>
                          </div>

                          <div class="project-text-heading d-flex">
                            <h4 class="">Start Date:</h4>
                            <div
                              class="text-truncate project-others project-text-size ml-custom"
                            >
                              {{
                                projectStage(project.projectStartDate | date)
                              }}
                            </div>
                          </div>

                          <div class="project-text-heading d-flex">
                            <h4 class="">End Date:</h4>
                            <div
                              class="text-truncate project-others project-text-size ml-custom"
                            >
                              {{ projectStage(project.projectEndDate | date) }}
                            </div>
                          </div>

                          <div class="project-text-heading d-flex">
                            <h4 class="">Project Stage:</h4>
                            <div
                              class="text-truncate project-description project-text-size ml-custom"
                            >
                              {{
                                projectStage(
                                  project.projectStage.projectstagename
                                )
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-xs-12 text-right">
                          <div class="circle-progress">
                            <circle-progress
                              [percent]="project.projectCompletionPercentage"
                              [radius]="50"
                              titleFontSize="22"
                              [outerStrokeWidth]="7"
                              [innerStrokeWidth]="7"
                              [outerStrokeColor]="'#78C000'"
                              [innerStrokeColor]="'#C7E596'"
                              [animation]="true"
                              [animationDuration]="300"
                              [space]="3"
                            ></circle-progress>
                          </div>

                          <div class="col-xs-12 right-other-codes ml-xl-n2">
                            <div class="making-left">
                              <div
                                class="project-status-bg"
                                [style.background-color]="
                                  approvalBackground(
                                    project.projectStatus?.listTypeValueName
                                  )
                                "
                              >
                                <div
                                  class="project-status-txt"
                                  [style.color]="
                                    approvalStatus(
                                      project.projectStatus?.listTypeValueName
                                    )
                                  "
                                >
                                  {{ project.projectStatus?.listTypeValueName }}
                                </div>
                              </div>
                              <div
                                class="project-text-heading d-flex row-xs mt-3-code"
                              >
                                <h4 class="">Project Code:</h4>
                                <div
                                  class="text-truncate project-others project-text-size ml-custom"
                                >
                                  {{ truncateProjectCode(project.projectCode) }}
                                </div>
                              </div>
                              <div class="project-text-heading d-flex">
                                <h4 class="">Project Budget Hours:</h4>
                                <div
                                  class="text-truncate project-others project-text-size project-budget-hours ml-custom"
                                  *ngIf="
                                    project.projectBudgetHours != undefined
                                  "
                                >
                                  {{
                                    truncateProjectCode(
                                      project.projectBudgetHours + ":00"
                                    )
                                  }}
                                </div>
                              </div>

                              <div class="project-text-heading d-flex">
                                <h4 class="">Project Actual Hours:</h4>
                                <div
                                  class="text-truncate project-others project-text-size ml-custom"
                                >
                                  {{
                                    truncateProjectCode(
                                      project.projectAcutalHours.substring(0, 5)
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="viewEmployees(project)">
                        <mat-icon class="material-icons-outlined sub-icon"
                          >streetview</mat-icon
                        >
                        <span class="sub-content">Assign Employess</span>
                      </button>

                      <button
                        mat-menu-item
                        routerLink="emp-report/{{ project.projectId }}"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >receipt_long</mat-icon
                        >

                        <span class="sub-content projects">View Reports</span>
                      </button>

                      <button
                        mat-menu-item
                        [routerLink]="['assign-task', project.projectId]"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >date_range</mat-icon
                        >
                        <span class="sub-content projects">Add Task</span>
                      </button>

                      <button
                        mat-menu-item
                        routerLink="my-task/{{ project.projectId }}"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >date_range</mat-icon
                        >
                        <span class="sub-content projects">My Tasks</span>
                      </button>
                      <button mat-menu-item (click)="edit(project)">
                        <mat-icon class="material-icons-outlined sub-icon"
                          >edit</mat-icon
                        >
                        <span class="sub-content projects">Edit</span>
                      </button>
                      <button mat-menu-item (click)="delete(project)">
                        <mat-icon class="material-icons-outlined sub-icon"
                          >delete</mat-icon
                        >
                        <span class="sub-content projects">Delete</span>
                      </button>
                    </mat-menu>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>

          <ng-template #noProjects>
            

            <mat-card class="card-content-nodata mat-card-nodata"    *ngIf="!nodata && this.projectList.length !== 0">
              <div class="card-body-nodata">
                <div class="alert-nodata alert-primary">
                  <h4 align="center" class="h4">Projects Not Found</h4>
                </div>
              </div>
            </mat-card>
          </ng-template>
        </div> -->
        <div>
          <div
            class="row"
            *ngIf="
              (empProjectList | project : search).length > 0;
              else noProjects
            "
          >
            <div
              class="col-xl-6 col-md-12"
              *ngFor="let project of empProjectList | project : search"
            >
              <div class="card">
                <div
                  class="mat-card-header col-xl-12 m-0"
                  style="background-color: #708fff"
                >
                  <div class="col-md-11 p-0">
                    <h5 class="m-0 project-name projects text-truncate">
                      {{ project.projectName }}
                    </h5>
                  </div>

                  <div class="col-md-1 float-right pr-0">
                    <span
                      class="material-icons-outlined action"
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      more_vert
                    </span>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xxl-4 col-xl-4 col-md-4">
                        <div class="project-text-heading d-flex">
                          <div>
                            <h4 class="">Description:</h4>
                          </div>
                          <div
                            class="text-truncate project-description project-text-size ml-custom"
                          >
                            {{ project.projectDescription }}
                          </div>
                        </div>
                        <div class="project-text-heading d-flex">
                          <h4 class="">Start&nbsp;Date:</h4>
                          <div
                            class="text-truncate project-others project-text-size ml-custom"
                          >
                            {{ projectStage(project.projectStartDate | date) }}
                          </div>
                        </div>

                        <div class="project-text-heading d-flex">
                          <h4 class="">End&nbsp;Date:</h4>
                          <div
                            class="text-truncate project-others project-text-size ml-custom"
                          >
                            {{ projectStage(project.projectEndDate | date) }}
                          </div>
                        </div>

                        <div class="project-text-heading d-flex">
                          <h4 class="">Project&nbsp;Stage:</h4>
                          <div
                            class="text-truncate project-description project-text-size ml-custom"
                          >
                            {{
                              projectStage(
                                project.projectStage.projectstagename
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-md-4">
                        <!-- <div class=""> -->
                        <div
                          class="project-status-bg"
                          [style.background-color]="
                            approvalBackground(
                              project.projectStatus?.listTypeValueName
                            )
                          "
                        >
                          <div
                            class="project-status-txt"
                            [style.color]="
                              approvalStatus(
                                project.projectStatus?.listTypeValueName
                              )
                            "
                          >
                            {{ project.projectStatus?.listTypeValueName }}
                          </div>
                        </div>
                        <div class="project-text-heading d-flex row-xs mt-2">
                          <h4 class="">Project&nbsp;Code:</h4>
                          <div
                            class="project-others project-text-size ml-custom"
                          >
                            {{ truncateProjectCode(project.projectCode) }}
                          </div>
                        </div>
                        <div class="project-text-heading d-flex">
                          <h4 class="">Project&nbsp;Budget&nbsp;Hours:</h4>
                          <div
                            class="project-others project-text-size ml-custom"
                            *ngIf="project.projectBudgetHours != undefined"
                          >
                            {{
                              truncateProjectCode(
                                project.projectBudgetHours + ":00"
                              )
                            }}

                            <!-- {{
  truncateProjectCode(
    project.projectBudgetHours 
  )
}} -->
                          </div>
                        </div>

                        <div class="project-text-heading d-flex">
                          <h4 class="">Project&nbsp;Actual&nbsp;Hours:</h4>
                          <div
                            class="project-others project-text-size ml-custom"
                          >
                            {{
                              truncateProjectCode(
                                project.projectAcutalHours.substring(0, 5)
                              )
                            }}
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                      <div class="col-xxl-4 col-xl-2 col-md-4">
                        <circle-progress
                          style="margin-left: 11px"
                          [percent]="project.projectCompletionPercentage"
                          [radius]="50"
                          titleFontSize="22"
                          [outerStrokeWidth]="7"
                          [innerStrokeWidth]="7"
                          [outerStrokeColor]="'#78C000'"
                          [innerStrokeColor]="'#C7E596'"
                          [animation]="true"
                          [animationDuration]="300"
                          [space]="3"
                        ></circle-progress>
                      </div>
                    </div>
                  </div>
                  <div>
                    <mat-menu #menu="matMenu">
                      <button
                        mat-menu-item
                        (click)="viewEmployees(project)"
                        *ngIf="
                          user_type == 'HumanResource' ||
                          user_type == 'Manager' ||
                          user_type == 'TeamLeader' ||
                          user_type == 'Admin'
                        "
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >streetview</mat-icon
                        >
                        <span class="sub-content">Assign Employees</span>
                      </button>

                      <button
                        *ngIf="
                          user_type == 'HumanResource' ||
                          user_type == 'Manager' ||
                          user_type == 'TeamLeader' ||
                          user_type == 'Admin'
                        "
                        mat-menu-item
                        routerLink="emp-report/{{ project.projectId }}"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >receipt_long</mat-icon
                        >

                        <span class="sub-content projects">View Reports</span>
                      </button>

                      <button
                        mat-menu-item
                        [routerLink]="['assign-task', project.projectId]"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >add_task</mat-icon
                        >
                        <span class="sub-content projects">Add Task</span>
                      </button>

                      <button
                        mat-menu-item
                        routerLink="my-task/{{ project.projectId }}"
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >date_range</mat-icon
                        >
                        <span class="sub-content projects">My Tasks</span>
                      </button>
                      <button
                        mat-menu-item
                        (click)="edit(project)"
                        *ngIf="
                          user_type == 'HumanResource' ||
                          user_type == 'Manager' ||
                          user_type == 'TeamLeader' ||
                          user_type == 'Admin'
                        "
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >edit</mat-icon
                        >
                        <span class="sub-content projects">Edit</span>
                      </button>
                      <button
                        mat-menu-item
                        (click)="delete(project)"
                        *ngIf="
                          user_type == 'HumanResource' ||
                          user_type == 'Manager' ||
                          user_type == 'TeamLeader' ||
                          user_type == 'Admin'
                        "
                      >
                        <mat-icon class="material-icons-outlined sub-icon"
                          >delete</mat-icon
                        >
                        <span class="sub-content projects">Delete</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-template #noProjects>
            <!-- <mat-card
              class="card-content mt-xl-3"
              *ngIf="!nodata && this.projectList.length !== 0"
            >
              <div class="card-body">
                <div class="alert alert-primary">
                  <h4 align="center" class="nodata-h4">Projects Not Found</h4>
                </div>
              </div>
            </mat-card> -->

            <mat-card
              class="card-content-nodata mat-card-nodata"
              *ngIf="!nodata && this.empProjectList.length !== 0"
            >
              <div class="card-body-nodata">
                <div class="alert-nodata alert-primary">
                  <h4 align="center" class="h4">Projects Not Found</h4>
                </div>
              </div>
            </mat-card>
          </ng-template>
        </div>

        <!-- *ngIf="
        user_type != 'HumanResource' &&
        user_type != 'Manager' &&
        user_type != 'TeamLeader' &&
        user_type != 'Admin'
      " -->
        <div
          class="col-xl-6"
          *ngFor="let project of empProjectList | project : search"
        ></div>
        <div *ngIf="!this.isAdmin || !this.isHumanResource">
          <!-- <mat-card class="card-content mt-xl-3" *ngIf="nodata">
            <div class="card-body">
              <div class="alert alert-primary">
                <h4 align="center" class="nodata-h4">Projects Not Found</h4>
              </div>
            </div>
          </mat-card> -->

          <!-- || this.empProjectList.length == 0 && !loader -->
          <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata ">
            <div class="card-body-nodata">
              <div class="alert-nodata alert-primary">
                <h4 align="center" class="h4">Projects Not Found</h4>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loader" class="align-items-center text-center mt-3">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
  </div>
</mat-card>

<div class="text-center" *ngIf="success" #successMessageElement>
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />
    {{ successMessage }}
  </div>
</div>

<div class="text-center" *ngIf="successUpdate">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />
    {{ successUpdateMessage }}
  </div>
</div>

<div class="text-center successUpdate mt-3" *ngIf="warning">
  <div class="alert alert-warning toast-msg successUpdate" role="alert">
    <strong>Warning!</strong><br />{{ warningMessage }}
    <br />
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
