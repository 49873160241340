import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router ,ActivatedRoute} from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { ManageApproverListComponent } from '../manage-approver-list/manage-approver-list.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { AppComponent } from 'src/app/app.component';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { Subject, switchMap, takeUntil } from 'rxjs';


@Component({
  selector: 'app-manage-employee-approver',
  templateUrl: './manage-employee-approver.component.html',
  styleUrls: ['./manage-employee-approver.component.scss']
})
export class ManageEmployeeApproverComponent implements OnInit {
  employeeApproverForm: FormGroup;
  employeeList: any = [];
  ApproverList: any = [];
  empId :any;
  empolyeereadonly :boolean = false;
  empolyeeapproverobject: any = {};
  empolyeeapproverexist: boolean;
  ruleNameexist: boolean;
  organzationId :any;
  submitted1=false;
  loader: boolean;
  success: boolean;
  success_msg: any;
  error: boolean;
  error_msg: any;
  btnDisable: boolean=false;
  selectedEmployeeData: any = [];
  empList: any = [];
  approverId: string;
  data: any;
  p: number = 1;
  pagePerItem: any = 10;
  dateFormat: any;
  employee: string;
  employeeId: any;
  postdatamultiple: any=[];
  userdata: any;
  approvertype:any =[];
  private unsubscribe$: Subject<void> = new Subject<void>();
  employeelistsub: any;
  disableFeildforAll:boolean=false;
readonlyDisable: boolean=false;
employeeNotFound: boolean = false;

  constructor(
    // public dialogRef: MatDialogRef<ManageEmployeeApproverComponent>,
    private router:Router,
    // @Inject(MAT_DIALOG_DATA) public data: any, 
    private fb: FormBuilder, 
    private gs: GlobalserviceService , 
    // private dialog:MatDialog,
    // private dialogref : MatDialogRef<ManageEmployeeApproverComponent>,
    private ActivatedRoute:ActivatedRoute,
    private payrollService: PayrollService,
    @Inject(AppComponent) public AppData: any ,  
    ) { }

  ngOnInit(): void {
    // this.dateFormat = this.AppData.dateFormat;
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =this.userdata.organization.organizationId;
    this.dateFormat = localStorage.getItem('dateFormatName');
    console.log("organziationid " + this.organzationId);
   
    this.employeeApproverForm = this.fb.group({
      organization: {
        organizationId:  this.organzationId
      },
      employee: ['', Validators.required],
      approver: ['', Validators.required],
      approverType: ['', Validators.required]
    
    })

    this.empId = this.userdata.employeeId;
    this.approverId = this.ActivatedRoute.snapshot.params.id;
    this.gs.getAllEmployeeByOrganization(this.organzationId).subscribe(
      (d: any[]) => {
        console.log(d);
        this.employeeList = d;
        console.log(this.employeeList);
      }
    )
    // this.loader=true;
    this.gs.getApprovers(this.organzationId).subscribe(
      (d: any[]) => {
        console.log(d);
        this.loader=false;

        this.ApproverList= d;
     
      }
    )
    this.gs.getApproverType().subscribe(
      (approvertype:any)=>{
        this.approvertype=approvertype;
         console.log("this.approvertype",this.approvertype);
      }
    )
  }
  

  //control values of form values
  get f() {
    return this.employeeApproverForm.controls;
  }
  // empAllSelect()
  // {
    
  //   if(this.employeeApproverForm.value.employee == "All")
  //   {
  //     this.disableFeildforAll=false;
  //     this.empolyeereadonly=true;

  //     // this.selectedEmployeeData=[]
  //     this.loader=true
  //     this.payrollService.getAllEmployeeByOrganization(this.organzationId).subscribe((result: any) => {
  //        if(result.statusCode){
             
  //        }else{
  //          console.log("else");
  //          this.employeeApproverForm.patchValue({
  //           employee: null
  //        });
  //        this.loader=false
  //        this.empolyeereadonly=true;

  //         this.empList = result;
  //         console.log(" this.empList",this.empList);
  //         console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
  //            result.forEach(element => {
  //             let  hasemployee =   this.selectedEmployeeData.some(e=> e.employeeId == element.employeeId);
  //             if(!hasemployee)
  //             {
  //               this.selectedEmployeeData.push(element);
  //             }
  //             // console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
  //            });
  //           //  console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
  //        }
  //     })
  //   }
  // }
  empAllSelect() {
    if (this.employeeApproverForm.value.employee === "All") {
      // Disable the field immediately after selecting "All"
      this.disableFeildforAll = true;
      this.empolyeereadonly = true;
      this.readonlyDisable=true;
  
      this.loader = true;
      this.payrollService.getAllEmployeeByOrganization(this.organzationId).subscribe(
        (result: any) => {
        if (result.statusCode) {
          // Handle error response
          console.error("Error response:", result);
        } else {
          console.log("else");
          this.employeeApproverForm.patchValue({
            employee: null
          });
          this.empList = result;
          console.log("this.empList", this.empList);
          console.log("this.selectedEmployeeData", this.selectedEmployeeData);
          result.forEach(element => {
            let hasemployee = this.selectedEmployeeData.some(e => e.employeeId === element.employeeId);
            if (!hasemployee) {
              this.selectedEmployeeData.push(element);
            }
          });
          console.log("this.selectedEmployeeData", this.selectedEmployeeData);
        }
        // Enable the field after processing the response
        this.disableFeildforAll = false;
        this.empolyeereadonly = false;
        this.loader = false;
        this.readonlyDisable=false;

      }, error => {
        // Handle error during the HTTP call
        console.error("Error fetching employees:", error);
        this.disableFeildforAll = false;
        this.empolyeereadonly = false;
        this.readonlyDisable=false;
        this.loader = false;
      });
    }
  }

  clearEmpArray(){
    this.empList=[]
  }
  empSearch(event){ 
    // this.empolyeereadonly=false;
    this.postdatamultiple = [];
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    
    // Employee List API
    if(employeenumber.length >= 2){
      if(this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
      this.employeelistsub = this.payrollService.getEmployeeNameList(employeenumber, this.organzationId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.payrollService.getEmployeeNameList(employeenumber, this.organzationId))
        )
        .subscribe((result: any) => {
          this.empList = [];
          
          if (result.statusCode) {
            console.log(result.statusCode);
            this.empList = [];
            this.employeeNotFound = true; // Set the flag if status code indicates no employees
          } else {
            this.empList = result;
            if (this.empList.length === 0) {
              this.employeeNotFound = true; // Set the flag if the list is empty
            }
          }
        
  
        })
      }
    }
  
  noEmployeeFound() {
    this.employeeNotFound = false;
    // this.selectedemployeeId = undefined;
    // this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    // this.employeeProjects.length = 0;
    this.employee = undefined;
    // this.employeeNumber = undefined;
    this.employeeNotFound=false
  }
  empSelect(emp)
  { 
    this.postdatamultiple =[];
    // this.employeelistsub=[]

    this.disableFeildforAll=false;
    this.empolyeereadonly=false;
    this.readonlyDisable=false;
    console.log(emp);

    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
    console.log(this.employee);
    if(this.approverId){
      console.log("if");
      this.employeeApproverForm.patchValue({
        employee: this.employee 
     });
    }else{
      console.log("else");
      this.employeeApproverForm.patchValue({
          employee: null
       });
    // console.log("this.selectedEmployeeData", this.selectedEmployeeData);
    
        let  hasemployee =   this.selectedEmployeeData.some(e=> e.employeeId == emp.employeeId);
          if(!hasemployee)
          {
            this.selectedEmployeeData.push(emp);
            console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
          }
          console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
    }
    if (this.selectedEmployeeData.length >= 1) {
      this.employeeApproverForm.get('employee')?.clearValidators();
    } else {
      this.employeeApproverForm.get('employee')?.setValidators([Validators.required]);
    }
    this.employeeApproverForm.get('employee')?.updateValueAndValidity();
  
  }

  pageChages() {
    this.p = 1;
  }
  deleteemployee(index) {
    if (index != -1) {
      this.selectedEmployeeData.splice(index, 1);
    }
  }
  clearemployeelist(){
    this.selectedEmployeeData =  [];
  }
  submit(){
    console.log("form::",this.employeeApproverForm.invalid);
    
    this.submitted1=true;
    console.log(this.employeeApproverForm.invalid);
    console.log(this.empolyeeapproverexist ==true);
    console.log(this.selectedEmployeeData.length ==0);
    if (this.selectedEmployeeData.length === 0 || 
      (this.selectedEmployeeData.length <= 1 && this.employeeApproverForm.invalid)) {
    return;
  }
 
    let proappform = this.employeeApproverForm.value;
    console.log(proappform);
    if(this.approverId)
    {         
      console.log("update form");
      console.log(proappform);   
      var employeeapproverupdate  = 
      {          
        empolyeeapproverId : this.approverId,
        employeeApproverStatus: this.data.employeeApproverStatus.listTypeValueId,
        employee: {
        employeeId: proappform.employee
        },
        approvers: {
        approverId:  proappform.approver
        },
        organization: {
          organizationId: this.organzationId
        },  
        approverTypeId : proappform.approverType,
        status : {
          listTypeValueId: this.data.status.listTypeValueId
        }
              
      }
      
      console.log(employeeapproverupdate);
      this.gs.updateEmployeeApprover(employeeapproverupdate , this.data.empolyeeapproverId)
      .subscribe((a:any )=> 
      {
        this.loader = false;
        this.success = true;
        this.success_msg = a.message;
        setTimeout(() => {
          this.success  = false;
          this.router.navigate(['/home/manage-emp-approver-list']);
        }, redirectMsgTimeOut);
      },
      err =>{
        this.btnDisable = false;
        this.loader = false;
        this.error = true;
        this.error_msg = err.error.message;
        setTimeout(() => {
          this.error = false;
        }, redirectMsgTimeOut);
      })
    }
    else
    {
      // this.loader=true;
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      this.postdatamultiple=[];
      this.selectedEmployeeData.forEach((element: any) => {
        console.log("element::",element);
        
        let employeeapprover = {
          "employee": {
            "employeeId": this.employeeId
          },
          "approvers": {
            "approverId": proappform.approver
          },
          "organization": {
            "organizationId": this.organzationId
          },
          "approverType":{
            "approverTypeId" : proappform.approverType,
          }
          // status : {
          //   listTypeValueId: 1
          // }
        };
        this.postdatamultiple.push(employeeapprover);
      });
      console.log(this.postdatamultiple);

    
      if(this.employeeApproverForm.controls['approver'].value && this.employeeApproverForm.controls['approverType'].value){
        this.btnDisable = true;
      this.gs.createEmployeeApprover(this.postdatamultiple).subscribe(
      (a:any) => {
        this.loader = false;
        this.success = true;
        this.success_msg = a.message;
        this.btnDisable = false;
        setTimeout(() => {
          this.success  = false;
          this.router.navigate(['/home/manage-emp-approver-list']);
        }, redirectMsgTimeOut);
      },
      (err) =>{
        console.log(err);
        this.btnDisable = false;
        this.loader = false;
        this.error = true;
        this.error_msg = err.error.message;
        setTimeout(() => {
          this.error = false;
        }, redirectMsgTimeOut);
      })   
    }                  
    }
  }
 
  employeeapprovervalidate(){
    console.log("project : " +  this.employeeApproverForm.value.employee);    
    if(!this.data)
    {
      if(this.employeeApproverForm.value.employee != 0)
      {           
        this.gs.getEmployeeApproversvalidate(this.employeeApproverForm.value.employee).subscribe(a => 
        {
          console.log(a);
          this.empolyeeapproverobject = a;
          if(this.empolyeeapproverobject.isexist)
          {
            console.log("employee name isexist");
            this.empolyeeapproverexist = true;
          }
          else{
            this.empolyeeapproverexist = false;
          }
        })
      }
      else{
        this.empolyeeapproverexist = false;
      }
    }   
  }
}
