import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
import { httpOptions, redirectMsgTimeOut, responseMsgTimeOut } from '../../../providers/properties';
import { Sort } from '@angular/material/sort';
import { SuperadminService } from '../../../providers/superadmin.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  public searchFilter: any = '';
  search: any;
  mySelect: number = 5;
  mySelectList: number = 5;
  mySelectGrid: number = 5;
  nodata: boolean = false;
  buttonDisabled: boolean = false;
  storeEntityId: any;
  constructor(
    private SuperadminService: SuperadminService,
    private SettingsService: SettingsService,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    public ExcelService: ExcelService,
    public datepipe: DatePipe,
    private router: Router,
  ) { }

  resultData: any[] = [];
  p: any;
  pList: any;
  pGrid: any;
  collection: any[];
  pagePerItem: any = 5;
  pagePerItemOfList: any = 5;
  pagePerItemOfGrid: any = 5;
  tableSearch: any;

  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  sortedData: any[];
  searchForm!: FormGroup;
  tableShow: boolean = false;
  loader: any = false;
  backdrop: any = false;
  nodatafound: boolean = true;
  industryTypeLov: any;

  dataExport = [];
  exported: any;
  exportingdata: any;

  pageViews: any = 1; //Employee Profile Tabs Default My Profile 1
  industryType: any;
  beforesearchpage: any;
  beforeSearchList: any;
  beforeSearchGrid: any;
  beforepage: any;
  organizationListData: any[] = [];
  organizationListIconData: any[] = [];
  organizationViewModuleData: any[] = [];

  ngOnInit(): void {

    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);

    //this.organizationList();
    this.getFilterFormData();
    this.getIndustryTypeLov(7);
    // this.getEntityList();
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }
  handleBackspace(event:any) {
   
  console.log("Key pressed:", event.key);

  // Get the current value in the input field
  const inputValue = event.target.value;
  console.log("Current input value:", inputValue);
  console.log("Current input value:", inputValue.length);

  if ( inputValue.length == 1 ) {
    this.tableShow=false
  
  }
  this.nodata = false;

    
  }
  getIndustryTypeLov(listTypeValueId) {
    this.SettingsService.getListTypeValues(listTypeValueId).subscribe((resultData: any) => {


      this.industryTypeLov = resultData;

    });
  }


  selectionChangeOfIndustryType(event: MatSelectChange) {
    console.log('event', event.value);
  }
  getFilterFormData() {
    this.searchForm = this.formBuilder.group({
      keywords: [''],
      industryType: [''],
      businessUnitname: ['']
    });
  }
  clearData() {
    // this.resultData=[];
    this.tableShow = false;
    this.organizationListIconData =[];
    this.organizationViewModuleData=[]

    // let keywords = this.searchForm.value.keywords;
    // let industryType = this.searchForm.value.industryType;
    // let businessUnitname = this.searchForm.value.businessUnitname;

    // console.log('keywords',keywords,industryType,businessUnitname);
    // if (keywords  && industryType  && businessUnitname ) {

    //   this.getEntityList();
    // }else if(keywords){
    //   this.getEntityList();
    // }else if(industryType){
    //   this.getEntityList();
    // }else if(businessUnitname){
    //   this.getEntityList();
    // }
    // this.searchForm.reset();
  }

  getEntityList() {

    this.SuperadminService.listOragnizationBasedOnEntity(this.storeEntityId).subscribe((response: any) => {
      console.log('response', response);
      this.organizationListData = response;
      this.organizationListIconData = response;
      console.log("organizationListIconData::",this.organizationListIconData)
      this.organizationViewModuleData = response;
      console.log("organizationViewModuleData:")
      this.nodata = false;
      this.loader = false;
      this.tableShow = true;
      if(response.statusCode==204){
        this.nodata=true;
        this.tableShow=true;
        this.organizationListData=[];
      }

    })
  }
  searchSubmit() {
    console.log("this.search form", this.searchForm.value);

    let keywords = this.searchForm.controls['keywords'].value?.trim();
    let industryType = this.searchForm.controls['industryType'].value;
    let businessUnitname = this.searchForm.controls['businessUnitname'].value?.trim();

    console.log('keywords', keywords, industryType, businessUnitname);

    if (!keywords && (!industryType || industryType == 'null') && !businessUnitname) {
        alert('Please Select Industry Type to Search!!');
        console.log("1")
        return;
    } else if (keywords && (!industryType || industryType == 'null') && businessUnitname) {
        alert('Please select an Industry Type as well!');
        console.log("2")

        return;
    } else if (!keywords && (!industryType || industryType == 'null') && businessUnitname) {
        alert('Please select an Industry Type as well!');
        console.log("3")

        return;
    }
    else{
      console.log("else came!!")
      if (keywords && !businessUnitname && (!industryType || industryType == 'null')) {
        alert('Please select an Industry Type as well!');
        console.log("4")

        return;

      }
    }




    if (keywords && (industryType && industryType != 'null') && !businessUnitname) {
      this.loader = true;
      this.tableShow = false;
      // this.backdrop=true;
      this.buttonDisabled = true;
      this.SuperadminService.getSearchOrganizationList(keywords, industryType,this.storeEntityId).subscribe((resultData: any) => {
        this.loader = false;
        this.tableShow = true;
        // this.backdrop=false;
        this.nodata = false;
        this.buttonDisabled = false;
        this.organizationListData = resultData;
        this.organizationViewModuleData=resultData;
        this.organizationListIconData =resultData;

        

        if (this.organizationListData.length == 0 || this.organizationListData.length == undefined) {
          this.nodata = true;
          this.tableShow = true;
          this.buttonDisabled = false;

        }
        if (resultData.statusCode==204){
          this.nodata=true;
          this.organizationListData=[];
          this.tableShow=true;
          this.buttonDisabled = false;

        }
      })
    }
    else if (keywords && (!industryType || industryType == 'null') && !businessUnitname) {
      this.loader = true;
      this.backdrop = true;
      this.tableShow = false;
      console.log('only keywords');

      this.buttonDisabled = true;
      this.SuperadminService.keywordsSearchOfOrganizationName(keywords,this.storeEntityId).subscribe((resultData: any) => {
        this.loader = false;
        // this.backdrop=false;
        this.tableShow = true;
        this.nodata = false;

        this.organizationListData = [];
        this.organizationListData = resultData;
        this.organizationViewModuleData=resultData;
        this.organizationListIconData =resultData;
        this.resultData = resultData;
        this.buttonDisabled = false;
        console.log('onlyOrganization', resultData);

        if (resultData.statusCode === 204) {
          this.organizationListData = [];
          this.nodata = true;
          this.tableShow = true;
          this.buttonDisabled = false;
        }

        if (this.organizationListData.length == 0 || this.organizationListData.length == undefined) {
          this.nodata = true;
          this.tableShow = true;
          this.buttonDisabled = false;

        }
      })
    }
    else if (!keywords && (industryType && industryType != 'null') && !businessUnitname) {
      this.loader = true;
      this.backdrop = true;
      this.tableShow = false;
      this.buttonDisabled = true;
      this.SuperadminService.getOrganizationListIndustryType(industryType, this.storeEntityId).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.tableShow = true;
        this.nodata = false;
        this.buttonDisabled = false;

        // this.organizationListData = resultData;
        this.organizationListData = Array.isArray(resultData) ? resultData : [];
        this.organizationViewModuleData=resultData;
        this.organizationListIconData =resultData;
        console.log('resultData', resultData);
        if (resultData?.statusCode === 204) {
          this.nodata = true;
          this.tableShow = true;
          this.loader = false;
          this.buttonDisabled = false;
        }

        if (this.organizationListData.length == 0 || this.organizationListData.length == undefined) {
          this.nodata = true;
          this.tableShow = true;
          this.buttonDisabled = false;

        }
      })
    }
    else if (!keywords && industryType && businessUnitname) {
      console.log('!keywords && industryType && businessUnitname');

      this.loader = true;
      this.backdrop = true;
      this.tableShow = false;
      this.buttonDisabled = true;
      this.SuperadminService.searchBasedOnBusinessUnitNameAndIndutryType(businessUnitname, industryType ,this.storeEntityId).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.tableShow = true;
        this.nodata = false;
        this.buttonDisabled = false;
        // this.organizationListData = [];
        console.log('resultData', resultData);
        this.organizationListData = resultData;
        this.organizationViewModuleData=resultData;
        this.organizationListIconData =resultData;
        // resultData.forEach(element => {
        //   this.organizationListData = element;
        // });
        // this.organizationListData = resultData[0];

        if (resultData.statusCode === 204) {
          this.organizationListData = [];
          this.nodata = true;
          this.tableShow = true;
          this.loader = false;
          this.buttonDisabled = false;

        }
        if (this.organizationListData.length == 0 || this.organizationListData.length == undefined) {
          this.nodata = true;
          this.tableShow = true;
          this.buttonDisabled = false;

        }
      }, (err => {

        console.log('error', err.error);
        if (err.error.statusCode === 404) {
          this.organizationListData = [];
          this.nodatafound = true;
          this.tableShow = true;
          this.loader = false;
          this.buttonDisabled = false;
        }

      }))
    }

    else if (keywords && industryType && businessUnitname) {
      console.log('!keywords && industryType && businessUnitname');

      this.loader = true;
      this.backdrop = true;
      this.tableShow = false;
      this.buttonDisabled = true;
      this.SuperadminService.searchBasedOnOrganizationBusinessUnitIndustryType(keywords, businessUnitname, industryType,this.storeEntityId).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.tableShow = true;
        this.nodata = false;
        console.log('resultData', resultData);
        this.buttonDisabled = false;
        // this.organizationListData = [];
        console.log('resultData', resultData);
        // resultData.forEach(element => {
        this.organizationListData = resultData;
        this.organizationViewModuleData=resultData;
        this.organizationListIconData =resultData;

        if (resultData.statusCode == 204) {
          this.organizationListData = [];
          this.nodata = true;
          this.tableShow = true;
          this.buttonDisabled = false;
        }
        // });
        // this.organizationListData = resultData[0];

        if (this.organizationListData.length == 0 || this.organizationListData.length == undefined) {
          this.nodata = true;
          this.tableShow = true;
          this.buttonDisabled = false;

        }
      })
    }
  }

  organizationList() {
    /* this.SuperadminService.getOrganizationList().subscribe((result: any) => 
    {
      this.resultData = result;
    },err =>
    {
      //this.errorMsg = err.error.message;
    }) */

    let keywords = this.searchForm.value.keywords;
    let industryType = this.searchForm.value.industryType;

    if (keywords && (industryType && industryType != 'null')) {
      this.loader = true;
      this.backdrop = true;

      this.SuperadminService.getSearchOrganizationList(keywords, industryType,this.storeEntityId).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;

        this.organizationListData = resultData;

        if (this.organizationListData.length == 0 || this.organizationListData.length == undefined) {
          this.nodatafound = true;
        }
      })
    }
    else if (keywords && (!industryType || industryType == 'null')) {
      this.loader = true;
      this.backdrop = true;

      this.SuperadminService.getOrganizationListKeywords(keywords).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;

        this.organizationListData = resultData;

        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
        }
      })
    }
    // else if (!keywords && (industryType && industryType != 'null')) {
    //   this.loader = true;
    //   this.backdrop = true;

    //   this.SuperadminService.getOrganizationListIndustryType(industryType, this.storeEntityId).subscribe((resultData: any) => {


    //     this.loader = false;
    //     this.backdrop = false;
    //     this.nodatafound = false;
    //     this.organizationListData = [];
    //     this.organizationListData = resultData;

    //     if (this.resultData.length == 0 || this.organizationListData.length == undefined) {
    //       this.nodatafound = true;
    //     }
    //   })
    // }
  }

  exportToExcel() {
    let keywords = this.searchForm.value.keywords;
    let industryType = this.searchForm.value.industryType;

    if (keywords && industryType) {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData;

      for (let exportingdata of this.exportingdata) {
        //let createDateTime =this.datepipe.transform(exportingdata.createDateTime, 'dd-MMM-yyyy')

        if (exportingdata.industryType != undefined && exportingdata.industryType.listTypeValueName) {
          this.industryType = exportingdata.industryType.listTypeValueName;
        } else {
          this.industryType = "";
        }


        //{{row.industryType ? row.industryType.listTypeValueName :""}}

        this.exported = {
          OrganizationName: exportingdata.organizationName,
          IndustryType: this.industryType,
          ContactPerson: exportingdata.contactPerson,
          ContactPhoneNumber: exportingdata.contactPhoneNumber,
          ContactEmail: exportingdata.contactEmail,
          PostalCode: exportingdata.postalCode,
          Address: exportingdata.address,
          //CreatedDate            : createDateTime,
        }

        this.dataExport.push(this.exported);
      }
      this.ExcelService.exportAsExcelFile(this.dataExport, "Export");
    }
    else {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData;
      for (let exportingdata of this.exportingdata) {
        //let createDateTime =this.datepipe.transform(exportingdata.createDateTime, 'dd-MMM-yyyy')

        if (exportingdata.industryType != undefined && exportingdata.industryType.listTypeValueName) {
          this.industryType = exportingdata.industryType.listTypeValueName;
        } else {
          this.industryType = "";
        }

        this.exported = {
          OrganizationName: exportingdata.organizationName,
          IndustryType: this.industryType,
          ContactPerson: exportingdata.contactPerson,
          ContactPhoneNumber: exportingdata.contactPhoneNumber,
          ContactEmail: exportingdata.contactEmail,
          PostalCode: exportingdata.postalCode,
          Address: exportingdata.address,
          //CreatedDate            : createDateTime,
        }

        this.dataExport.push(this.exported);
      }

      this.ExcelService.exportAsExcelFile(this.dataExport, "Export");
    }
  }
  Add_client() {
    this.router.navigateByUrl("/home/add-organization");

  }


  tableView(val) //Grid, List & Card View
  {
    this.pageViews = val;
  }

  updateStatus(id, status) {
    this.SuperadminService.updateOrganizationStatus(id, status).subscribe((result: any) => {
      if (result.statusCode == 200) {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => { this.success = false; }, redirectMsgTimeOut)

        // this.getEntityList();
        this.searchSubmit()
      }
      else {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }
    })
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort) {
    //const data = this.resultData.slice();
    const data = this.organizationListData;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'organizationName':
          return compare(a.organizationName, b.organizationName, isAsc);

        case 'Industry':
          return compare(a.Industry, b.Industry, isAsc);

        case 'BusinessUnit':
          return compare(a.BusinessUnit, b.BusinessUnit, isAsc);

        case 'address':
          return compare(a.address, b.address, isAsc);

        case 'Status':
          return compare(a.Status, b.Status, isAsc);

        // case 'postalCode':
        //   return compare(a.postalCode, b.postalCode, isAsc);

        // case 'address':
        //     return compare(a.address, b.address, isAsc);

        default:
          return 0;
      }
    });
  }
  changefirstpage() {
    this.p = 1;
    this.pGrid = 1;
    this.pList = 1;
  }

  changefirstpageList() {
    this.pList = 1;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
