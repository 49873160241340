import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuperadminService } from '../../../providers/superadmin.service';


@Component({
  selector: 'app-add-time-zone',
  templateUrl: './add-time-zone.component.html',
  styleUrls: ['./add-time-zone.component.scss']
})
export class AddTimeZoneComponent implements OnInit 
{
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  dateFormatNameExistStatus :any;
  updatedateFormatNameExistStatus :any;
  modulesLov :any;
  timezoneId :any;

  constructor(
    private superadminservice: SuperadminService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddTimeZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void 
  {
    this.postForm = this.formBuilder.group({
      timezoneName : ['',[Validators.required]],
      timezoneDescription : [''],
    });

    if(this.edit_data)
    {
      this.timezoneId = this.edit_data.timezoneId;

      this.superadminservice.getTimezoneById(this.edit_data.timezoneId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;
        
        this.postForm.patchValue({
          timezoneName: this.resultData.timezoneName,
          timezoneDescription: this.resultData.timezoneDescription,
        });
      });
    }
  }

  get validate() { return this.postForm.controls; }

  onSubmit() //form Submit Function
  {
    this.submitted = true;

    if(this.edit_data) //Edit
    {
      if (this.postForm.invalid || this.dateFormatNameExistStatus == true)
      {
        return;
      }

      //let postValues = this.postForm.value;

      var postValues = {
        timezoneName:this.postForm.value.timezoneName,
        timezoneDescription:this.postForm.value.timezoneDescription,
      }

      postValues['timezoneId'] = this.edit_data.timezoneId;
      postValues['timezoneStatus'] = this.edit_data.timezoneStatus;

      this.backdrop = true;
      this.loader=true;

      this.superadminservice.editTimeZone(this.edit_data.timezoneId,postValues).subscribe((resultData: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;
        this.sucess_msg = resultData.description;

        this.dialogref.close(resultData);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    else //Create
    {
      if (this.postForm.invalid || this.dateFormatNameExistStatus == true)
      {
        return;
      }

      var postValues = {
        timezoneName:this.postForm.value.timezoneName,
        timezoneDescription:this.postForm.value.timezoneDescription,
      }
    
      this.superadminservice.createTimeZone(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>
      {
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
  }

  checkExistTimeZoneName(val)
  {
    let editData = 0;
   
    if(this.postForm.value.timezoneName.length != 0)
    {
      if(this.edit_data)
      {
        var postValues = {
          timezoneId:this.edit_data.timezoneId,
          timezoneName:this.postForm.value.timezoneName,
          timezoneDescription:this.postForm.value.timezoneDescription,
        }

        postValues['timezoneStatus'] = {
          "listTypeValueId" : this.edit_data.timezoneStatus
        };
        
        this.superadminservice.updateCheckExistTimezoneName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.dateFormatNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.dateFormatNameExistStatus = true;
          }
        })
      }
      else
      { 
        //alert("adsf");
        var CreatePostValues = {
          timezoneName:this.postForm.value.timezoneName,
          timezoneDescription:this.postForm.value.timezoneDescription,
        }
        
        this.superadminservice.createCheckExistTimezoneName(CreatePostValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.dateFormatNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.dateFormatNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.dateFormatNameExistStatus = false;
    }
  }

}
