import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { ViewApproversComponent } from '../view-approvers/view-approvers.component';
// import { AddTimelogsComponent } from '../add-timelogs/add-timelogs.component';
import { ExcelService} from 'src/app/providers/excel.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';

@Component({
  selector: 'app-timesheetreports',
  templateUrl: './timesheetreports.component.html',
  styleUrls: ['./timesheetreports.component.scss']
})
export class TimesheetreportsComponent implements OnInit {

  Id: any;
  search: any;
  EmpList :any =[];
  User_Type;
  // p;
  beforesearchpage: any;
  beforepage:any;
  nodata = false;
  loader = true;
  startDate:any;
  endDate:any;
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  mySelect:any =5;
  
  projectId:number = 0;
  projectList:any = []
  taskList:any;
  taskId :number = 0;
  timesheetreport :any = [];
  isproject :boolean ;
  timesheetDate:any;
  task_disable:boolean =true;
  employeeId:any;
  emptaskdate:any;
  formatteddate:any;
  project_required:boolean =false;
  exportdatalist :any = [];
  enddate_required: boolean;
  startdate_required: boolean;
  today :any;
  submitToApprovalData:any=[];
  tasktotalTime: any;
  organizationIds: any;

  constructor(private dialog: MatDialog, private gs: ProjectManagementService,
    private route: ActivatedRoute, private datepipe : DatePipe,
    private router: Router , private excelservice :  ExcelService) { }

  ngOnInit() {

    this.today = new Date();
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');

    const localData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.Id = localData.employeeId;
    this.employeeId = localData.employeeId;
    this.organizationIds=localData.organizationId
    console.log("emp ide::",this.employeeId);
    console.log("org_id::",this.organizationIds);
    
    
    

    if (this.Id) {
      this.gs.getResourceById(this.employeeId,this.organizationIds).subscribe(
        (x:any)=>{
          this.User_Type = x.userType.userType;
          console.log(this.User_Type)
          this.gs.getEmpProjectList(this.Id).subscribe(
            (pa:any)=>{
              
              this.projectList = pa;
              console.log(this.projectList);
            }
          )
        }
      )
      this.gs.GetEmployeTimeEntryLimit(this.Id).subscribe(
        (data: any) => {
          this.loader = false
          console.log(data)
          this.EmpList = data;
          this.tasktotalTime = this.EmpList[0].totalTime;
          if(this.EmpList.length == 0)
            {
              this.nodata = true;
              console.log("nodata")
            }
          },
          (error) => {
            this.nodata = true;
            this.loader = false;
            console.log("error")
            if(error.status == 404){
              this.nodata = true;
              console.log("404")
            }
          }
        );
    }
  }
  getTask()
  {
    this.project_required =false;
    this.task_disable =false;
    console.log("project id "  + this.projectId);
       if(this.projectId  == 0)
       {
         this.taskList.length = 0;
       }else{
         this.gs.getTaskById(this.projectId).subscribe(
           (pa:any)=>{
            
             this.taskList = pa;
             console.log(this.taskList);
           }
         )
       }
      }

  searchTimesheet()
  {
    this.changefirstpage();
   
      this.startDate = this.datepipe.transform( this.startDate , 'YYYY-MM-dd');
      this.endDate = this.datepipe.transform( this.endDate , 'YYYY-MM-dd');
      // if(this.projectId == 0 )
      // {
      //   this.project_required =true;
      //   return
      // } 

      if(this.projectId == 0 && this.taskId == 0 && this.startDate ==undefined && this.endDate ==undefined )
      {
        alert("Please Choose any of the Element to Search");
          return
      }
    
       if( this.startDate !=undefined && this.endDate==undefined ){
        this.enddate_required = true;
        return
      }
      if( this.endDate !=undefined && this.startDate==undefined ){
        this.startdate_required = true;
        return
      }
      
      this.EmpList.length=0;

      this.project_required =false;
      if(this.projectId != 0 && this.taskId == 0)
      {
        console.log("projectId only");

        if(!this.startDate && !this.endDate)
        {
          console.log("projectId only without date");
          this.gs.getEmpByProject(this.projectId,this.employeeId).subscribe((data)=>{
            console.log(data);
            this.EmpList = data;
            this.tasktotalTime = this.EmpList[0].totalTime;
            this.nodata = false;
          },
          (error) => {
            this.nodata = true;
          });
        }
        else
        {
          console.log("projectId only with date");
          this.gs.getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate(this.employeeId,this.projectId,this.startDate,this.endDate)
          .subscribe((data)=>{
            console.log(data);
            this.EmpList = data;
            this.tasktotalTime = this.EmpList[0].totalTime;
            this.nodata = false;
          },
          (error) => {
            this.nodata = true;
          });
        }
      }
      else if(this.taskId !=0 && this.projectId !=0)
      {
        console.log("taskId and projectId");

        if(!this.startDate && !this.endDate){
          console.log("taskId and projectId without date");
          this.gs.getTaskDetailByTaskIdAndEmployee(this.taskId,this.employeeId).subscribe((data)=>{
            console.log(data);
            this.EmpList = data;
            this.tasktotalTime = this.EmpList[0].totalTime;
            this.nodata = false;
          },
          (error) => {
            this.nodata = true;
          });
        }
        else{
          console.log("taskId and projectId with date");
          this.gs.getEmpTimeLogbyEmployeeIdandStartDateAndEndDate(this.taskId,this.employeeId,this.startDate,this.endDate)
          .subscribe((data)=>{
            console.log(data);
            this.EmpList = data;
            this.tasktotalTime = this.EmpList[0].totalTime;
            this.nodata = false;
          },
          (error) => {
            this.nodata = true;
          });
        }
      }else{
        
        this.gs.getTimesheetReportByEmployeeIdAndStartDateAndEndDate(this.employeeId,this.startDate,this.endDate)
          .subscribe((data)=>{
            console.log(data);
            data['weeklySubmit'] = true;
            this.EmpList = data;
            console.log(this.EmpList);
            this.tasktotalTime = this.EmpList[0].totalTime;
            this.nodata = false;
          },
          (error) => {
            this.nodata = true;
          });
      }
  }

  submitToApproval(){
   // console.log("submitToApproval");
    //console.log(this.EmpList);
    this.submitToApprovalData =[];

    this.EmpList.forEach(element => {
      var timesheetData ={
        taskDetailId : element.taskDetailId 
      }
      //console.log(timesheetData);

      this.submitToApprovalData.push(timesheetData);
      console.log(this.submitToApprovalData);
    })
    console.log(this.submitToApprovalData);
    this.gs.ApprovalSubmit(this.startDate,this.endDate,this.submitToApprovalData)
    .subscribe((data)=>{
      console.log(data);
      Swal.fire({
        icon: 'success',
        text: 'TimeSheet Submitted for Approval'
      })
      this.submitToApprovalData.length = 0;
    },
    (error)=>{
      Swal.fire({
        icon: 'error',
        text: 'Unable to Submit TimeSheet for Approval'
      })
      this.submitToApprovalData.length = 0;
    }
    )
  }

  changepage(){
    if(this.search.length != 0)
    {
      this.p = 1;
    }else{
      this.p = this.beforesearchpage;
    }
  }

  changefirstpage()
  {
    this.p = 1;
  }

  x: boolean;
  sort() {
    this.x = !this.x
    console.log(this.x)
    if (this.x == true) {
      this.EmpList.sort(function (a: any, b: any) { return b.empNo - a.empNo });
    }
    else {
      this.EmpList.sort(function (a: any, b: any) { return a.empNo - b.empNo });
    }
  }


  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return
    }
    if (i.task.taskStatusName == 'To Do') {
      return '#ffc107'
    }
    if (i.task.taskStatusName== 'Completed') {
      return '#28a745'
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#dc3545'
    }
  }


  background(i) {
    if (i?.task?.taskStatusName == null) {
      return
    }
    if (i.task.taskStatusName == 'To Do') {
      return '#fff0c2'
    }
    if (i.task.taskStatusName== 'Completed') {
      return '#c8e6c9'
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#ffcdd2'
    }
  }

  approvalStatus(i){
    if (i?.approval?.approvalStatusName == null) {
      return
    }
    if (i?.approval?.approvalStatusName == 'Pending') {
      return '#ffc107'
    }
    if (i?.approval?.approvalStatusName == 'Approved') {
      return '#28a745'
    }
    if (i?.approval?.approvalStatusName == 'Denied') {
      return '#dc3545'
    }
  }

  approvalBackground(i){
    if (i.approval.approvalStatusName == null) {
      return
    }
    if (i.approval.approvalStatusName == 'Pending') {
      return '#fff0c2'
    }
    if (i.approval.approvalStatusName == 'Approved') {
      return '#c8e6c9'
    }
    if (i.approval.approvalStatusName == 'Denied') {
      return '#ffcdd2'
    }
  }

  viewapprover(taskdetail : any)
  {
      console.log(taskdetail);
      const dialogRef = this.dialog.open(ViewApproversComponent, {
        width: '500px',
        height:'500px',
        data : taskdetail
      })
  }
  
  edittimesheet(taskdetail : any)
  {
      console.log(taskdetail);
      // const dialogRef = this.dialog.open(AddTimelogsComponent, {
      //   width:'750px',
      //   data : taskdetail
      // })

  }

  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  
  exportexcel()
  {
    this.exportdatalist = [];

      this.EmpList.forEach(element => {
        
          var timesheetData = 
          {
              Project : element.project.projectName,
              Task  :   element.task.taskName,
              Date :     element.date,
              Description : element.description,
              WorkedHours : element.workedHours,
              TimeSheetStatus : element.taskDetailStatusName
          }
          this.exportdatalist.push(timesheetData);
      });

      this.excelservice.exportAsExcelFile(this.exportdatalist , "TimeSheet")
  }

  Approve(i) {
    let x = JSON.parse(localStorage.getItem("UserData"))
    let emp = x.employeeId
    Swal.fire({
      title: 'Are you sure? Do you want to approve',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve!'
    }).then((result) => {
      if (result.value) {

        i['approvalStatus'] = 5
        i['employee'] = {
          employeeId: this.Id
        }
        console.log(JSON.stringify(i))
        this.gs.Time_Entry_Approval(i, this.Id).subscribe(a => {
          Swal.fire({
            icon: 'success',
            text: "Approved"
          });
          window.location.reload();
        })
      }
      // this.skillsArray.removeAt(id);
    })
    console.log(i)
  }
  Deny(i) {
    let x = JSON.parse(localStorage.getItem("UserData"))
    let emp = x.employeeId
    Swal.fire({
      title: 'Are you sure? Do you want to deny',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Deny!'
    }).then((result) => {
      if (result.value) {
        i['approvalStatus'] = 6
        i['employee'] = {
          employeeId: this.Id
        }
        this.gs.Time_Entry_Approval(i, this.Id).subscribe(a => {
          Swal.fire({
            icon: 'success',
            text: "Denied"
          });
          window.location.reload();
        })
      }
      // this.skillsArray.removeAt(id);
    })
  }
  updateTime(){
    // const dialogRef = this.dialog.open(AddTimelogsComponent,{
    //   width:'750px',
    // })
  }

  sortData(sort: Sort) {
    const data = this.EmpList.slice();
    if (!sort.active || sort.direction === '') {
      this.EmpList = data;
      return;
    }

    this.EmpList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'projectName':
          return this.compare(a.project.projectName, b.project.projectName, isAsc);
        case 'taskName':
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'workedHours':
          return this.compare(a.workedHours, b.workedHours, isAsc);
        case 'taskStatusName':
            return this.compare(a.task.taskStatusName, b.task.taskStatusName, isAsc);
        case 'approvalStatusName':
          return this.compare(a.approval.approvalStatusName, b.approval.approvalStatusName, isAsc);
                  
        default:
          return 0;
      }
    });
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
