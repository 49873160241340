<div class="">
    <div class="d-flex top-content">
        <div style="width: 80%;">
            <h1>Approvers</h1>
        </div>        
        <div class="edit-icon">
            <span class="material-icons float-right" style="font-size:18px ;color:#1a83ff;"
            (click)="updateapprover(data.approverId)" mat-dialog-close>edit</span>
        </div>
        <div style="position: relative;bottom: 13px;left:13px">
            <span class="material-icons float-right" style="color:red;"  align="end" mat-dialog-close>close</span>
        </div> 
        <!-- <div>
            <span class="material-icons c" style="color:red;position: relative;left: 8px;bottom: 6px;"  mat-dialog-close>cancel</span>
        </div> -->
    </div>
    <div>
        <div>
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approverOneObject.firstName}} {{data.approverOneObject.lastName}}</h4>
                    <h6 class="email-id-new">{{ data.approverOneObject.organizationEmail }}</h6>
                </div>
            </div>
            <hr/>
        </div>
        <div *ngIf="data.approverTwoObject">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approverTwoObject.firstName}} {{ data.approverTwoObject.lastName}}</h4>
                    <h6 class="email-id-new">{{ data.approverTwoObject.organizationEmail }}</h6>
                </div>
            </div>
            <hr/>
        </div>
        <div *ngIf="data.approverThreeObject">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approverThreeObject.firstName}} {{ data.approverThreeObject.lastName}}</h4>
                    <h6 class="email-id-new">{{ data.approverThreeObject.organizationEmail }}</h6>
                </div>
            </div>
            <hr/>
        </div>
        <div  *ngIf="data.approverFourObject">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approverFourObject.firstName}} {{data.approverFourObject.lastName}}</h4>
                    <h6 class="email-id-new">{{ data.approverFourObject.organizationEmail }}</h6>
                </div>
            </div>
            <hr/>
        </div>
        <div  *ngIf="data.approverFiveObject">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approverFiveObject.firstName}} {{data.approverFiveObject.lastName}}</h4>
                    <h6 class="email-id-new">{{ data.approverFiveObject.organizationEmail }}</h6>
                </div>
            </div>
            <hr/>
        </div>
        <div class="top-content">
            <h1>FYI</h1>
        </div>
        <div>
            <div class="row" >
                <div class="col-md-6">
                    <h4>{{ data.fyiObject.firstName}} {{ data.fyiObject.lastName}}</h4>
                    <h6 class="email-id-new">{{ data.fyiObject.organizationEmail }}</h6>
                </div>                 
            </div>
        </div>
    </div>
</div>
  

