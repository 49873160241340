import { Component, Inject, OnInit , ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import {httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';
import { DatePipe, JsonPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { UserService } from 'src/app/providers/user.service';

@Component({
  selector: 'app-add-employee-roles',
  templateUrl: './add-employee-roles.component.html',
  styleUrls: ['./add-employee-roles.component.scss']
})

export class AddEmployeeRolesComponent implements OnInit 
{
  @ViewChild('select') select: MatSelect;

  isMasterSel:boolean;
  activeRolesData :any = [];

  allSelected=false;
  postForm:FormGroup;
  submitted=false;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeeId: any;

  arrayData:any = [];
  arrayDataPatch:any = [];
  userId: any;
  usertData: any;
  orgId: any;
  userdata:any = {};
  organizationId:any
  entityId:any;
  clientId:any
  constructor(
    private Employeeservice: EmployeeService,
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private datepipe : DatePipe ,
    private ActivatedRoute:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogref : MatDialogRef<AddEmployeeRolesComponent>,
    private userservice : UserService
   /* @Inject(MAT_DIALOG_DATA) public edit_data: any */
  ) { this.isMasterSel = false; }


  ngOnInit(): void 
  {
    this.employeeId = this.data.employeeId;
    this.userId  = this.data.userId;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = this.usertData?.organization?.organizationId;
    this.entityId = this.usertData?.entity?.entityId;
    this.clientId=this.usertData?.employee?.client?.clientId;
    this.orgId = this.usertData?.organization?.organizationId;
    this.postForm = this.formBuilder.group({
      roleId:[null,Validators.required]
    })
    console.log(" this.entityId::", this.entityId,
      "this.organizationId",this.organizationId,
      "this.clientId:",this.clientId
    );
  

    // if(this.employeeId)
    // {
    //    console.log("employee");
    //   this.Employeeservice.getEmployeeById(this.employeeId).subscribe((resultData:any) =>
    //   {
    //     this.loader=false;
    //     this.backdrop=false;
    //     this.userdata = resultData;
    //     this.getActiveRoles();
    //     this.postForm.patchValue({
    //       roleId:resultData.role.map(
    //         d => {return d.roleId}
    //       ),
    //     });
    //   });
    // }else if (this.userId){
    //   console.log("user");
    //   this.userservice.getUserById(this.userId).subscribe((resultData:any) =>
    //   {

    //     this.userdata = resultData;
    //     this.loader=false;
    //     this.backdrop=false;
    //     this.getActiveRoles();
    //     this.postForm.patchValue({
    //       roleId:resultData.role.map(
    //         d => {return d.roleId}
    //       ),

         
    //     });
     
       
        
      
     
       
      // });
    // }
    // console.log(th/is.postForm.controls)
    this.getActiveRoles()
  }

  get f(){return this.postForm.controls;}


  getActiveRoles()
  {

    // this.Employeeservice.getActiveRolesByOrg().subscribe((result: any) => {
    //   this.activeRolesData = result;
    //   this.userdata.role.forEach(element => {
    //     let hasrole =  this.activeRolesData.some(ac => ac.roleId == element.roleId);
    //      if(!hasrole)
    //      {
    //        this.activeRolesData.push(element);
    //      }
    //    });
    // })
    console.log(" this.entityId::", this.entityId,
      "this.organizationId",this.organizationId,
      "this.clientId:",this.clientId
    );
    
    if( this.entityId && this.organizationId==undefined && this.clientId==undefined){
      console.log("entity login role here!!!");
      
      this.Employeeservice.getActivesuperadminRoles().subscribe((result: any) => {
        this.activeRolesData = result;
        this.activeRolesData = Array.isArray(result) ? result: [];
  
      })
    }
    else if(this.clientId && this.organizationId==undefined ){
      this.Employeeservice.getActivesClientRoles(this.clientId).subscribe((result: any) => {
        this.activeRolesData = result;
        this.activeRolesData = Array.isArray(result) ? result: [];
  
      })

      console.log("client login role here!!!");
    }
    else{
      if( this.organizationId && this.clientId==undefined){
        this.Employeeservice.getActivesOrganizationRoles(this.organizationId).subscribe((result: any) => {
          this.activeRolesData = result;
          this.activeRolesData = Array.isArray(result) ? result: [];
    
        })
        console.log("organization login role here!!!");
      }   
     }
  }

  onSubmit()
  {
    if (this.employeeId)
    {
      this.submitted = true;
      //stop here if form is invalid
      if (this.postForm.invalid) {return;}

      let x = this.postForm.value;
      x.roleId.map(
        e => {
          let data;
          if (e) {
            data = {
                roleId: e
            }
          }
          this.arrayData.push(data)
        }
      )
      let postValues = this.postForm.value;
      postValues['employeeId'] = this.employeeId;
      postValues['role'] =  this.arrayData;

      this.Employeeservice.assignEmployeeRole(this.employeeId,postValues).subscribe((resultData: any) =>
      {
        
        //this.router.navigate([ '/home/designation' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(resultData);
        if(resultData.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = resultData.description;

          // window.location.reload();
        }
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })

    }else if(this.userId)
    {
      this.submitted = true;
      //stop here if form is invalid
      if (this.postForm.invalid) {return;}

      let x = this.postForm.value;
      x.roleId.map(
        e => {
          let data;
          if (e) {
            data = {
                roleId: e
            }
          }
          this.arrayData.push(data)
        }
      )
      let postValues = this.postForm.value;
      postValues['userId'] = this.userId;
      postValues['role'] =  this.arrayData;

      console.log(postValues);
      // return
      this.userservice.assignUserRole(this.userId,postValues).subscribe((resultData: any) =>
      {
        
        //this.router.navigate([ '/home/designation' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(resultData);
        if(resultData.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = resultData.description;

          window.location.reload();
        }
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
  

  }

  toggleAllSelection() 
  {
    if (this.allSelected) 
    {
      this.select.options.forEach((item: MatOption) => item.select());
    } 
    else 
    {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() 
  {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => 
    {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    console.log("allSelected" +  this.allSelected)
  }

  add()
  {
    let x = this.postForm.value;
    if(x.roleId == '')
    {
      alert("Please select the options")
    }
    else
    {
      this.select.close()
    }
  }

  cancel()
  {
    this.postForm.patchValue({roleId:''})
    this.select.close()
  }
  
}
