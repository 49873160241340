import { Component, OnInit } from '@angular/core';
import { GlobalserviceService } from '../providers/globalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { AdminService } from '../providers/admin.service';
import { event } from 'jquery';
import { MatSelectChange } from '@angular/material/select';


@Component({
  selector: 'app-legal-list',
  templateUrl: './legal-list.component.html',
  styleUrls: ['./legal-list.component.scss']
})
export class LegalListComponent implements OnInit {

  submitted: boolean = false;
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  searchForm: FormGroup;
  resultData: any;
  branchId: any;

  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;
  employeetData: any;
  isbtnDisable: boolean = false;
  businessId: any;
  tableShow: boolean = false;
  legalList: any[] = [];
  beforepage: any;
  mySelect: any = 5;
  beforesearchpage: any;
  p: any;
  nodata: boolean = false;
businessList: any=[];
legalEntityList:any=[]
nodatafound: boolean=false;
  legalentityKeywords: any;
  businessGroup: any;
pagePerItem: number=5;
  storeBusinessGroupValue: any;
  storeEntityId: any;
  showValidationMessage:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private gs: GlobalserviceService,
    private AdminService:AdminService,
    private ActivatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);
    
    this.searchForm = this.formBuilder.group({
      // legalentityNames: [''],
      legalentityNames: [
        '',
        [
          
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      businessgName: [ '',
        [
          
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],]
     
    });
    // this.loader=true
    // this.tableShow=false;

    // this.AdminService.getBranchlistset(this.storeEntityId).subscribe((response: any) => {
    //   console.log("response::",response);
    //   this.loader=false;
      
      // this.businessList = response;
      // this.tableShow=true;

      // console.log("businessList::",this.businessList);

      // if(response.statusCode==204){
      //   console.log("no data entered!!");
        
      //   this.nodatafound=true;
      //   this.tableShow=true;
      //   this.businessList=[];
      // }
      // this.nodatafound=false;
    //   if (response && response.length > 0) {
    //     this.tableShow = true;
    //     console.log("Search result:", response);
    //     this.legalList = response;  
    //     console.log(" this.legalList::", this.legalList);
        

    //     this.nodatafound = false;

    //   } 
    //   else {
    //     console.log("else came!!!");
    //     if(response.statusCode==204){
    //       console.log("no data!!");
    //       this.nodatafound=true;
    //       this.tableShow=true;
    //       this.resultData=[];
    //     }
    //   }

    //   // this.businessList = Array.isArray(response) ? response : [response];
    // });


  }

  handleBackspace(event:any) {
    // if (event.key === ' ') {
    //   event.preventDefault();
    // }

    // Get the key that was pressed
  console.log("Key pressed:", event.key);

  // Get the current value in the input field
  const inputValue = event.target.value;
  console.log("Current input value:", inputValue);
  console.log("Current input value:", inputValue.length);

  if ( inputValue.length == 1 ) {
    this.tableShow=false
    // this.businessGroupList = [];
    // this.loader = true;
    // this.tableShow = false;
    // this.showValidationMessage = false;
    // this.hrmsservice.getAllBusinessGroup(this.storeEntityId).subscribe((response: any) => {
    //   console.log('response', response);
    //   this.loader =  false;
    //   this.businessGroupList = response;
    //   this.nodata = false;
    //   this.tableShow = true;
    //   // this.businessGroupList.push(response);

    // })
  }
  // Your logic here (like calling a service or performing other actions)
  this.nodata = false;

    
  }

   
  selectionChangeBusinessGroup(data: MatSelectChange) {
    console.log('value', data?.value);
    this.storeBusinessGroupValue = data?.value;
  }
  updateStatus(id,status)
	{
    this.AdminService.updateLegalEntityStatus(id,status).subscribe((result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        const updatedRow = this.legalList?.find((row) => row.legalEntityId === id);
        if (updatedRow) {
          updatedRow.legalEntityStatus = status; // Update the status in the current data
        }
        setTimeout(() => {this.success  = false;},5000)

        // this.getAllEntityList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, 5000)
      }
		})
	}

  changefirstpage() {
    this.p = 1;
  }
  sortData(sort: Sort) {
    const data = this.legalList.slice();
    if (!sort.active || sort.direction === '') {
      this.legalList = data;
      return;
    }

    this.legalList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'entityName':
          return this.compare(
            a.businessGroup.entity.entityName,
            b.businessGroup.entity.entityName,
            isAsc
          );
        case 'businessName':
          return this.compare(
            a.businessGroup.businessGroupName,
            b.businessGroup.businessGroupName,
            isAsc
          );
        case 'legalEntityName':
          return this.compare(
            a.legalEntityName,
            b.legalEntityName,
            isAsc
          );
          case 'country':
            return this.compare(a.countryOfIncorporation, b.countryOfIncorporation, isAsc);
          case 'country':
            return this.compare(
              a.countryOfIncorporation,
              b.countryOfIncorporation,
              isAsc
            );
        case 'legalAddress':
          return this.compare(a.legalAddress, b.legalAddress, isAsc);
        case 'oAddress':
          return this.compare(
            a.operatingAddress,
            b.operatingAddress,
            isAsc
          );
          case 'industryType':
          return this.compare(
            a.businessGroup.industryType.listTypeValueName,
            b.businessGroup.industryType.listTypeValueName,
            isAsc
          );
        
        
        // return this.compare(a.assignedhours, b.assignedhours, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }
  get validate() {
    return this.searchForm.controls;
  }
  clearAll() {
    this.searchForm.reset();
    // this.getAllEntityList();
    this.nodatafound=false;
    this.tableShow=false;
    


    // this.AdminService.getBranchlistset(this.storeEntityId).subscribe((response:any)=>{
    //   console.log("response:",response);

    //   this.legalList=response;
    //   this.tableShow=true;
    //   this.loader=false;
      
    // })
  }
  searchSubmit() {
    console.log('form values::', this.searchForm.value);

    this.legalentityKeywords=this.searchForm.value.legalentityNames;
    this.businessGroup=this.searchForm.value.businessgName;

    console.log("this.businessGroup::",this.businessGroup);
    console.log("this.legalentityKeywords::",this.legalentityKeywords);
    
    

    if((this.legalentityKeywords==0||this.legalentityKeywords==null||this.legalentityKeywords==' ')&&(this.businessGroup==null || this.businessGroup==0||this.businessGroup==' ')){
      alert("Please Choose or Type any Keywords to Search!!");
      return;
    }

    if((this.legalentityKeywords!==0||this.legalentityKeywords!==null||this.legalentityKeywords!==' ')&&(this.businessGroup==null || this.businessGroup==0||this.businessGroup==' ')){
      console.log("only legal entity !!!");
      this.tableShow = false;
      this.loader=true

    this.AdminService.getlegalEntityListKeyword(this.legalentityKeywords,this.storeEntityId).subscribe((response:any)=>{
      console.log("response:",response);

      if (response && response.length > 0) {
        this.tableShow = true;
        this.loader=false
        console.log("Search result:", response);
        this.legalList = response;  
        console.log(" this.legalList::", this.legalList);
        

        this.nodatafound = false;

        
      } 
      else {
        console.log("else came!!!");
        if(response.statusCode==204){
          console.log("no data!!");
          this.nodatafound=true;
          this.tableShow=true;
          this.loader=false

          this.legalList=[];
        }
     
      }
      
    })
      
    }
    else if((this.legalentityKeywords==0||this.legalentityKeywords==null||this.legalentityKeywords==' ')&&(this.businessGroup!==null || this.businessGroup!==0||this.businessGroup==' ')){
      console.log("only business group !!!");

      this.tableShow = false;
      this.loader=true

      this.AdminService.getBusinessGroupListKeyword(this.businessGroup,this.storeEntityId).subscribe((response:any)=>{
        console.log("response:",response);
  
        if (response && response.length > 0) {
          this.tableShow = true;
          this.loader=false
          console.log("Search result:", response);
          this.legalList = response;  
          console.log(" this.legalList::", this.legalList);
          
  
          this.nodatafound = false;
  
          
        } 
        else {
          console.log("else came!!!");
          if(response.statusCode==204){
            console.log("no data!!");
            this.nodatafound=true;
            this.tableShow=true;
            this.loader=false

            this.legalList=[];
          }
       
        }
        
      })

      
    }
    else if((this.legalentityKeywords!==0||this.legalentityKeywords!==null||this.legalentityKeywords==' ')&&(this.businessGroup!==null || this.businessGroup!==0||this.businessGroup==' ')){
      console.log("both calling!!!");
      this.loader=true;
      this.tableShow=false;

      this.AdminService.getBusinessGroupLegalEntityList(this.legalentityKeywords,this.businessGroup,this.storeEntityId).subscribe((response:any)=>{
        console.log("response:",response);
  
        if (response && response.length > 0) {
          this.tableShow = true;
          this.loader=false
          console.log("Search result:", response);
          this.legalList = response;  
          console.log(" this.legalList::", this.legalList);
          
  
          this.nodatafound = false;
  
          
        } 
        else {
          console.log("else came!!!");
          if(response.statusCode==204){
            console.log("no data!!");
            this.nodatafound=true;
            this.tableShow=true;
            this.loader=false
            this.legalList=[];
          }
       
        }
        
      })

      
    }


  
  }
  resetTable(event:any){
    console.log(event);
    
    this.nodatafound=false;
    const inputValue=event.target.value

   
  if(inputValue.length==1 && (this.legalList.length==0 || this.legalList.length>0)){
    this.AdminService.getlegalEntityList(this.storeEntityId).subscribe((response:any)=>{
      console.log("response:",response);

      this.legalList=response;
      this.tableShow=true;
      this.loader=false;
      
  })
}}
  clearTableData(event:any){
    console.log("keydown fun came!!!");
    
    // this.getAllEntityList();
    this.nodatafound=false;
    this.showValidationMessage=false
    // const inputValue=event.target.value
    this.tableShow=false
    

    // if(inputValue==1 && (this.legalEntityList.length==0 || this.legalEntityList.length>=0)){

    // this.AdminService.getBranchlistset(this.storeEntityId).subscribe((response:any)=>{
    //   console.log("response:",response);

    //   this.legalList=response;
    //   this.tableShow=true;
    //   this.loader=false;
      
    // })
    // }
  }

}
