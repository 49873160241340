import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPaginate'
})
export class CustomPaginatePipe implements PipeTransform {

  transform(array: any[], args: { itemsPerPage: number, currentPage: number }): any[] {
    if (!array || !args) {
      return array;
    }
    const startIndex = (args.currentPage - 1) * args.itemsPerPage;
    return array.slice(startIndex, startIndex + args.itemsPerPage);
  }

}
