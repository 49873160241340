<!-- <div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Dashboard</span>
            </div>
            <div class="sub-header-buttons"> -->
<!-- <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                    Add Employee
                </a> -->
<!-- </div>
        </div>
    </div>
</div> -->


<div class="common-content" *ngIf="!loader" >
    <!-- Graph and cards Star -->
    <div class="card-new ">
        <!-- <div class="graph1">
            <img src="assets/uploads/graph.jpg">
        </div> -->
        <div class="dashboard-cards">
            <div class="row">
                <div class="col-md-12">
                    <p style="font-size: x-large; font-weight: 500;">Summary</p>
                </div>

            </div>
            <div class="row ">
                <div class="col-md-4     mt-3">
                    <div class="card">
                        <div class="top-row  p-3 ">
                            <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                            <img src="assets/uploads/Icon 1.png" alt=""
                                style="background-color: #6583EF; border-radius: 30px; height: 30px; width: 30px; padding: 4px;">
                            <span class="card-name1  ">Total Employees <h4 class="count-card ">
                                    {{resultData.activeEmployees + resultData.inActiveEmployees}}</h4></span>

                        </div>
                        <div class="bottom-row ">
                            <div class="row p-2">
                                <div class="col-6 ">
                                    <span class="card-name"
                                        style="margin-left: 30%; color: green; white-space: nowrap">Active :
                                        {{resultData.activeEmployees}}</span>
                                </div>
                                <div class="col-6  text-right">
                                    <span class="card-name" style="color: red;">Inactive :
                                        {{resultData.inActiveEmployees}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4     mt-3">
                    <div class="card">
                        <div class="top-row  p-3 ">
                            <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                            <img src="assets/uploads/Icons 2.png" alt=""
                                style="background-color: #6BB589; border-radius: 30px; height: 30px; width: 30px; padding: 4px;">
                            <span class="card-name1  ">Total Branches <h4 class="count-card ">
                                    {{resultData.activeBranches + resultData.inActiveBranches}}</h4></span>

                        </div>
                        <div class="bottom-row ">
                            <div class="row p-2">
                                <div class="col-6 ">
                                    <span class="card-name"
                                        style="margin-left: 30%; color: green; white-space: nowrap">Active :
                                        {{resultData.activeBranches}}</span>
                                </div>
                                <div class="col-6  text-right">
                                    <span class="card-name" style="color: red;">Inactive :
                                        {{resultData.inActiveBranches}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4     mt-3">
                    <div class="card">
                        <div class="top-row  p-3 ">
                            <!-- <mat-icon style="background-color: red; color: white;" >superviser_account</mat-icon> -->
                            <img src="assets/uploads/Icon 3.png" alt=""
                                style="background-color: #CAA2FF; border-radius: 30px; height: 30px; width: 30px; padding: 4px;">
                            <span class="card-name1  ">Total Clients <h4 class="count-card ">
                                    {{resultData.activeClient + resultData.inActiveClient}}</h4></span>

                        </div>
                        <div class="bottom-row ">
                            <div class="row p-2">
                                <div class="col-6 ">
                                    <span class="card-name"
                                        style="margin-left: 30%; color: green; white-space: nowrap">Active :
                                        {{resultData.activeClient}}</span>
                                </div>
                                <div class="col-6  text-right">
                                    <span class="card-name" style="color: red;">Inactive :
                                        {{resultData.inActiveClient}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  

                <!-- <div class="col-md-4 "> 
                    <div class=" ">
                        <div class="top-row ">
                            <mat-icon class="icon-primary">location_city</mat-icon>
                            <span class="card-name1 text-primary">Total Branches <h4 class="count-card text-primary">{{resultData.activeBranches + resultData.inActiveBranches}}</h4></span>

                        </div>
                        <div class="bottom-row text-primary">
                            <div class="row ">
                                <div class="col-6">
                                    <span class="card-name">Active : {{resultData.activeBranches}}</span>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="card-name">Inactive : {{resultData.inActiveBranches}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  -->

                <!-- <div class="col-md-4 mb-4">
                    <div class="bg-light-primary left-section p-4 mb-3">
                        <div class="top-row">
                            <mat-icon class="icon-primary">share</mat-icon>
                            <span class="card-name1 text-primary">Profiles Shared <h4 class="count-card text-primary">{{admindashboard.profilesource}}</h4></span>
                        </div>
                        <div class="bottom-row text-primary">
                            <div class="row">
                                <div class="col-6">
                                    <span class="card-name active-status-db">Active : {{admindashboard.activeBranches}}</span>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="card-name active-status-db">Inactive : {{admindashboard.inActiveBranches}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <!-- <div class="row db-card">
                <div class="col-md-4 mb-4">
                    <div class="bg-light-danger left-section p-4 mt-1">
                        <div class="top-row">
                            <mat-icon class="icon-danger">bar_chart</mat-icon>
                            <span class="card-name1 text-danger">Total Interviews <h4 class="count-card text-danger">{{admindashboard.interviewed + admindashboard.notInterviewed}}</h4></span>
                        </div>
                        <div class="bottom-row text-danger">
                            <div class="row">
                                <div class="col-6">
                                    <span class="card-name">Interviewed :{{admindashboard.interviewed}} </span>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="card-name">Not Interviewed : {{admindashboard.notInterviewed}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="col-md-4 mb-4">
                    <div class="bg-light-danger left-section p-4 mt-1">
                        <div class="top-row">
                            <mat-icon class="icon-danger"> bar_chart</mat-icon>
                        </div>
                        <div class="bottom-row text-danger">
                            <span class="card-name">Item Order</span>
                        </div>
                    </div>
                </div> 

                <div class="col-md-4 mb-4">
                    <div class="bg-light-success left-section p-4 mt-1">
                        <div class="top-row">
                            <mat-icon class="icon-success"> notes</mat-icon>
                        </div>
                        <div class="bottom-row text-success">
                            <span class="card-name">Bug Report</span>
                        </div>
                    </div>
                </div>

            </div> -->
        </div>
        <!-- Graph and cards End -->

        <!-- Todo Start -->
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="todo-list1">
                            <span class="to-do-header">Employee Birthday List</span>
                        </div>  
                        <div *ngIf="resultData.upcomingBirthday?.length > 0">
                            <div class="todo-list" *ngFor="let row of resultData.upcomingBirthday">
                                <div class="row">
                                    <!-- <div class="col-md-1 col-1"> -->
                                        <!-- <mat-icon class="large-icon">account_circle</mat-icon>  -->
                                        <!-- <img src="assets/uploads/use.png" alt=""> -->
                                    <!-- </div> -->








                                    <div class="col-md-12 col-12 py-1 " style="margin-bottom: -20px;">

                                        <span class="todo-title">
                                            <img src="assets/uploads/use.png" alt="" width="10" height="10" style="margin-top: 20px;">
                                         <span style="margin-left: 8px;">{{row?.middleName}} {{ucFirst(row?.firstName)}} 
                                            {{ucFirst(row.lastName)}}  </span><br> <span style="position: absolute; margin-top: -23px; margin-left: 55px;">
                                                <small>Dept:{{ row.department ? row.department.departmentName : "-"}}</small></span> 
                                                 <h6 style="background-color: #FFE5E5; margin-top: 24px;">
                                                #{{row.employeeNumber}}
                                            </h6></span>
                                                
                                        <span class="todo-sub-title" style="margin-left: 55px;"><mat-icon>cake</mat-icon> {{row.dateOfBirth ?
                                            (row.dateOfBirth | date: dateFormat) :""}} <h6 class="emp-contact">
                                                <mat-icon>call</mat-icon> {{row.mobileNumber}}</h6></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3" *ngIf="resultData.upcomingBirthday?.length == 0">
                            No Data Found.
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="todo-list1">
                            <span class="to-do-header">Employee Work Anniversary List</span>
                        </div>
                        <div *ngIf="resultData.upcomingWorkAnniversery?.length > 0 ">
                            <div class="todo-list" *ngFor="let row of resultData.upcomingWorkAnniversery">
                                <div class="row">
                                    <!-- <div class="col-md-1 col-1 col-xs-1 c0l-sm-1 "> -->
                                        <!-- <img src="assets/uploads/use.png" alt=""> -->

                                        <!-- <img src="{{fileblob1}}" class="header-logo1" alt="" matTooltip="Enote" /> -->
                                    <!-- </div> -->
                                    <div class="col-md-12 col-12 col-xs-12 col-sm-12 py-1  " style="margin-bottom: -20px;">
                                        <span class="todo-title">                                       
                                             <img src="assets/uploads/use.png" alt="" style="margin-top: 20px;"><span  style="margin-left: 8px;">{{row.middleName}} {{ucFirst(row.firstName)}} 
                                                {{ucFirst(row.lastName ? row.lastName  : "")}}</span>
                                            <h6 style="background-color: #FFE5E5; margin-top: 24px;">
                                                #{{row.employeeNumber}}</h6></span>
                                                <span style="position: absolute; margin-top: -23px; margin-left: 55px;">
                                                    <small>Dept:{{row.department ? row.department.departmentName : "-"}}</small></span>
                                        <span class="todo-sub-title" style="margin-left: 55px;"><mat-icon>business_center</mat-icon>
                                            {{row.dateOfJoining ? (row.dateOfJoining | date: dateFormat) :""}} <h6
                                                class="emp-contact"> <mat-icon> call</mat-icon> {{row.mobileNumber}}
                                            </h6></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3" *ngIf="resultData.upcomingWorkAnniversery?.length == 0 ">
                            No Data Found.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Todo End -->
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
    style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner"     style="height: 3rem;
    width: 3rem;"  role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>