import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router ,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { PayrollService } from '../providers/payroll.service';
import { SettingsService } from '../providers/settings.service';
// import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ShowerrorComponent } from '../../app/showerror/showerror.component';
import { ExcelService } from '../services/excel.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake  from "html-to-pdfmake";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { PmsService } from '../providers/pms.service';

@Component({
  selector: 'wage-register',
  templateUrl:'./wage-register.component.html',
  styleUrls: ['./wage-register.component.scss']
})
export class WageRegisterComponent implements OnInit {

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  userData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =true;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: any;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  @ViewChild('pdfTable')pdfTable!: ElementRef;
  period: string;
  clientaddressobj: any;
  clientElements: any = [];
  headerelements: any= [];
  variablesHeader: any = "";
  datepaid: any;
  salarystartdate: string;
  salaryenddate: any;
  periodData: any = {};

  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any , private pmsService : PmsService) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('enoteUserData')!);

    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.userData.userId;
    this.orgId = this.userData.organization.organizationId;
    console.log(this.orgId);
    
    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null],
      period:[null],
      client:[null],
    })

    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });

    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      console.log("this.organizationSettings",this.organizationSettings);
      
    });

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
  }

  getclientEmployees()
  {
      this.empList.length = 0;
      console.log(this.filterForm.value.client);
      this.filterForm.value.employeecode = null;
      this.filterForm.patchValue({
        employeecode:null
      })
      if(this.filterForm.value.client != null && this.filterForm.value.client != "null")
      {
        this.payrollservice.getemployeeclient(this.filterForm.value.client).subscribe((ClientEmployees: any) => {
          if(ClientEmployees.length != undefined)
          {
           this.empList = ClientEmployees;
          }
        });
        //   this.payrollservice.clientelements(this.filterForm.value.client).subscribe((Clientelements: any) => {
        //     if(Clientelements.length != undefined)
        //     {
        //      this.clientElements = Clientelements;
        //      console.log( this.clientElements)  ;
        //      this.clientElements.forEach(element => {
        //       this.headerelements = element.lines.filter(data => {
        //         if(!data.element.elementName.includes("Basic") && !data.element.elementName.includes("HRA") && !data.element.elementName.includes("PF")
        //         && !data.element.elementName.includes("Gross") && !data.element.elementName.includes("ESI") )
        //         {
          
        //                return  data;
        //         }
                          
        //    });
        //      });
            
        //       console.log( this.headerelements)  ;
        //     }
         
        // });
    }
  }

  empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      // this.payrollservice.getEmployeeNameList(employeenumber, this.orgId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }
      
      // })
      this.payrollservice.getClienEmployeeList( this.filterForm.value.employeecode , this.filterForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode)
        {
          
        }else{
          this.empList =  result;
        }
     
     })
    }
    
  }

  empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
  
      this.filterForm.patchValue({
        employeecode: this.employee 
     });
  }

  addpayroll(){
    this.router.navigateByUrl('/home/client-employee-payroll');
  }
  searchSubmit()
  {
    console.log("submit");
    this.req=false;
    this.p = 1;
    console.log(this.filterForm.value);
    console.log("this.filterForm.value.employeecode" + this.filterForm.value.employeecode);
    console.log("this.filterForm.value.financialyear" + this.filterForm.value.financialyear);
    console.log("this.filterForm.value.period" + this.filterForm.value.period);
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null ) && (this.filterForm.value.client=== 'null' || this.filterForm.value.client === null ) &&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period === 'null')){
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = true;
      alert("Please select  Requried fields");
      // this.req=true;
      return
    }
  
    else if((this.filterForm.value.client) && (this.filterForm.value.financialyear) && (this.filterForm.value.period  ) )
    {

           this.clientList.map(element => {
              if(element.clientId == this.filterForm.value.client){
                   if(element.salaryStartDate != 0 && element.salaryEndDate != 0 )
                   {
                       this.finPeriodList.map(finicalperiod => {
                          if(this.filterForm.value.period  == finicalperiod.financialPeriodId)
                          {
                            let finimonth  =  element.salaryEndDate+"-" + finicalperiod.financialperiodMonth + "-" + finicalperiod.financialPeriodYear;
                            console.log("finimonth" +finimonth);
                            this.salaryenddate =  this.datepipe.transform(finimonth , "dd/MM/yyyy");
                             console.log(this.salaryenddate);
                              let month =  new Date(finimonth);
                              console.log("month" + month);
                              console.log("month num" + month.getMonth());
                              this.salarystartdate = element.salaryStartDate+"/" +month.getMonth().toString() + "/" + finicalperiod.financialPeriodYear ;
                            //  this.salarystartdate =  this.datepipe.transform(finiendmonth,"dd/MM/yyyy");
                               console.log(this.salarystartdate);

                          }
                       })
                   }
              }
           });

      console.log("finactical and period");
      this.payrollservice.getemployeepayrollByClientAndFinancialperiod(this.filterForm.value.client,this.filterForm.value.period).subscribe(
        (resultData:any) =>{
        this.noRecordsFound = false;
        this.resultData = resultData;
      
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined){
          this.noRecordsFound = true;
        }else{

          this.payrollservice.getClientandFinperiodList(this.filterForm.value.client,"",this.filterForm.value.period,this.orgId).subscribe((clientworkingdays:any) =>
          {
             console.log(clientworkingdays);
               if(clientworkingdays.length != 0)
               {
                  this.datepaid =  this.datepipe.transform(clientworkingdays[0].datePaid , this.dateFormat)
               }
          })

          this.pmsService.getPeriodBasedList(this.filterForm.value.period).subscribe((Data: any) => 
              {
                this.periodData = Data;
               
             
                this.period=this.periodData.financialperiodMonth+"-"+ this.periodData.financialPeriodYear;
                console.log(this.period);
              })
        
            this.resultData.forEach(element => {
              let basichra :any ;
              let otlw :any ;
              let pfesi :any ;
              let ptax :any;
              let totaldeductions: any = 0;
              let variablesamount:any;
              let headername ;
                element.payrollLine.forEach(payline => {
                   if(payline.hrmspaySlipElement.elementName.includes("Basic") || payline.hrmspaySlipElement.elementName.includes("HRA"))
                   {
                    if(basichra)
                    {
                     basichra = basichra + '<br>' + payline.amount?.toFixed(2);
                    }else{
                     basichra  = payline.amount?.toFixed(2);
                    }
                   }
                   else  if(payline.hrmspaySlipElement.elementName.includes("OT Wages") || payline.hrmspaySlipElement.elementName.includes("Leave Encashment"))
                   {
                    // console.log("payline.hrmspaySlipElement.elementName" + payline.hrmspaySlipElement.elementName)
                    if(otlw)
                    {
                      otlw = otlw + '<br>' + payline.amount?.toFixed(2);
                    }else{
                      otlw  = payline.amount?.toFixed(2);
                    }
                   }
                 else  if(payline.hrmspaySlipElement.elementName.includes("Employee PF") || payline.hrmspaySlipElement.elementName.includes("Employee ESI") || payline.hrmspaySlipElement.elementName.includes("LWW"))
                   {
                    if(pfesi)
                    {
                      pfesi = pfesi + '<br>' + payline.amount?.toFixed(2);
                    }else{
                      pfesi  = payline.amount?.toFixed(2);
                    }
                   }  else  if(payline.hrmspaySlipElement.elementName.includes("Professional Tax") )
                   {
                    if(ptax)
                    {
                      ptax = ptax + '<br>' + payline.amount?.toFixed(2);
                    }else{
                      ptax  = payline.amount?.toFixed(2);
                    }
                   }
                   
                   if(payline.hrmspaySlipElement.categoryType.listTypeValueId == 43 && !payline.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYER"))
                   {
                        
                     totaldeductions += payline.amount;
                 }
                 if(payline.hrmspaySlipElement.elementName.includes("Bonus") || payline.hrmspaySlipElement.elementName.includes("Allowance"))
                 {
                  
                      if( payline.hrmspaySlipElement.elementName === "Production Bonus" )
                      {
                        if(headername &&  !headername.includes("Pr.Bonus"))
                        {
                          headername = headername + '<br>' +  "Pr.Bonus";
                        }else{
                          headername  = "Pr.Bonus";
                        } 
                      } 
                      else if( payline.hrmspaySlipElement.elementName === "Skill  Allowance" ){
                          if(headername &&  !headername.includes("SK.Allow"))
                          {
                            headername = headername + '<br>' +  "SK.Allow";
                          }else{
                            headername  = "SK.Allow";
                          } 
                      }
                      else if( payline.hrmspaySlipElement.elementName === "Night Shift Allowance" ){
                          if(headername &&  !headername.includes("Ni.Shift"))
                          {
                            headername = headername + '<br>' +  "Ni.Shift";
                          }else{
                            headername  = "Ni.Shift";
                          } 
                      }



                    //  console.log("payline.hrmspaySlipElement.elementName" + payline.hrmspaySlipElement.elementName);
                   if(variablesamount)
                   {
                    variablesamount = variablesamount + '<br>' + payline.amount?.toFixed(2);
                   }else{
                    variablesamount  = payline.amount?.toFixed(2);
                   }
               }
                 
                });
                element["basichra"] = basichra;
                element["otlw"] = otlw;
                element["pfesi"] = pfesi;
                element["ptax"] = ptax;
                element["variablesamount"] = variablesamount;
                element["totaldeductions"] = totaldeductions?.toFixed(2);
                this.variablesHeader = headername;
            });
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    
    this.clientList.forEach(element => {
      console.log("foreach")
      if(element.clientId == this.filterForm.value.client)
      {
        console.log(element)
         this.clientaddressobj = element;
      }
   });
  }


 

  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear(event){
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      this.filterForm.patchValue({
        period: null
     });
    }
    else{
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
      });
    }
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.ratingId=row.employeerattingId;
    console.log(this.ratingId);
    // this.router.navigateByUrl('/home/edit-employee-rating/',row.employeerattingId);
    this.router.navigate(['/home/view-employee-payroll/', this.ratingId])
  }

  showmontlyctc(row , ctcamount){
      console.log(row);
    if(row.employeeAnnexure.annexureType.listTypeValueId == 59)
    {
     
    }else{
      ctcamount = ctcamount / 12 
    }
  
   return  Number(ctcamount).toFixed(2);
  }

  

  printPdf(){
    console.log("PrintPDF");
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { 
      content: html ,
      // image: this.fileblob, // Use the dataURL here
      // width: 200,
      // height: 100,
      pageMargins: [ 40, 40, 40, 40 ],
      info: {
        title:`Wage Register`
        },
        pageSize: 'A3',
        pageOrientation: 'landscape',
      };
      pdfMake.createPdf(documentDefinition).open(); 
  }


}
