<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Working Days</span>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="empForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Financial Year<span class="star"> *</span></label>
                            <select formControlName="financialyear"
                                class="form-select form-select-solid form-select-lg select2-new"
                                (change)="selectFinancialyear($event)">
                                <option [ngValue]="null"> -- Select Financial Year --- </option>
                                <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}">
                                    {{row.financialFromMonth}}-{{row.financialFromYear}} To
                                    {{row.financialToMonth}}-{{row.financialToYear}}</option>
                            </select>
                            <div class="valid-msg"
                                *ngIf="(validate.financialyear.invalid && validate.financialyear.touched) || validate.financialyear.dirty || submitted">
                                <span *ngIf="validate.financialyear.errors?.required" class="text-danger">Please Select
                                    Financial Year</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Period<span class="star"> *</span></label>
                            <select formControlName="period"
                                class="form-select form-select-solid form-select-lg select2-new">
                                <option [ngValue]="null"> -- Select Period --- </option>
                                <option *ngFor="let row of finPeriodList; let i=index;"
                                    value="{{row.financialPeriodId}}">
                                    {{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                            </select>
                            <div class="valid-msg"
                                *ngIf="(validate.period.invalid && validate.period.touched) || validate.period.dirty || submitted">
                                <span *ngIf="validate.period.errors?.required" class="text-danger">Please Select
                                    Period</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Client</label> 
                            <select formControlName="client" (change)="getclientEmployees()"  class="form-select form-select-solid form-select-lg select2-new">
                                <option [value]="null"> --- Select Client --- </option>
                                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                            </select>    
                            <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Employee Code<span class="star"> *</span></label>
                            <!-- <input formControlName="employeecode" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search..."> -->
                            <input type="text" placeholder="Search Employee Code" formControlName="employeecode"  [readonly]="!empForm.value.client || empForm.value.client == 'null'"
                                [matAutocomplete]="auto" class="example-input designchange form-control form-control"   (keydown)="onKeyDown($event)" (keyup)="empSearch($event)">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option value="All" (click)="empAllSelect()">All</mat-option>
                                <mat-option *ngFor="let emp of empList" [value]="emp.employeeNumber"
                                    (click)="empSelect(emp)">
                                    {{emp.employeeNumber}} - {{emp.firstName}}
                                </mat-option>
                                <mat-option *ngIf="empCode">Employee Code Not Found</mat-option>
                            </mat-autocomplete>
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 20px;">
                                search
                            </mat-icon>
                            <div class="valid-msg"
                                *ngIf="(validate.employeecode.invalid && validate.employeecode.touched) || validate.employeecode.dirty || submitted">
                                <span *ngIf="validate.employeecode.errors?.required" class="text-danger">Please Select
                                    Employee Code</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3" *ngIf="selectedEmployeeData.length != 0 || workindaysId != null">
                        <div class="col-md-11 text-right">
                            <p class="show-count">Show</p>
                        </div>
                        <div class="col-md-1 text-left pl-0">
                            <select [(ngModel)]="pagePerItem" [ngModelOptions]="{standalone: true}"
                                (change)="pageChages()" class="form-control report-filter">
                                <option value="10" selected>10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                    <table class="mt-2 table  --table-bordered table-border-new"
                        *ngIf="selectedEmployeeData.length != 0 || workindaysId != null">
                        <thead class="t-head">
                            <tr>
                                <th class="col-md-2">Action</th>
                                <th class="col-md-3">Employee Code</th>
                                <th class="col-md-3">Employee Name</th>
                                <th class="col-md-2">Worked Days</th>
                                <th class="col-md-2">Ot Hours</th>
                                <th class="col-md-2">Arrear Days</th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container formArrayName="workingDays"
                                *ngFor="let group of getFormControls.controls  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <ng-container *ngIf="p==1; else nextpage">
                                    <tr formGroupName="{{ i }}">
                                        <td class="text-center" style="position: relative; bottom: 5px;">
                                            <button mat-menu-item type="button" (click)="deleteemployee(i)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                        <td>{{group.value.employeeNumber}}</td>
                                        <td>{{group.value.firstName}} {{group.value.lastName}}</td>
                                        <td  >
                                            <input type="text" formControlName="workingdays"
                                                class="daysinput designchange" (keypress)=" onlyNumber($event)"><br>
                                            <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->
                                            <mat-error
                                                *ngIf="submitted || workingDaysFormArray.controls[i].get('workingdays')?.touched && workingDaysFormArray.controls[i].get('workingdays')?.invalid">
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i].get('workingdays')?.hasError('required')">
                                                    Please Enter Working Days
                                                </span>
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i].get('workingdays')?.hasError('notZero')">
                                                    Please Enter Valid Working Days
                                                </span>
                                                
                                            </mat-error>
                                        </td>
                                        <td>
                                            <input type="text" formControlName="otHours"
                                                class="daysinput designchange" (keypress)=" onlyNumber($event)"><br>
                                            <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->


                                            <mat-error
                                                *ngIf="submitted || workingDaysFormArray.controls[i + startIndex].get('otHours')?.touched && workingDaysFormArray.controls[i + startIndex].get('otHours')?.invalid">
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('otHours')?.hasError('required')">Please
                                                    Enter OT Hours.</span>
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('otHours')?.hasError('notZero')">Please
                                                    Enter Valid OT Hours
                                                </span>
                                            </mat-error>
                                        </td>
                                        <td>
                                            <input type="text" formControlName="arrearDays"
                                                class="daysinput designchange" (keypress)=" onlyNumber($event)"><br>
                                            <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->


                                            <mat-error
                                                *ngIf="submitted || workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.touched && workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.invalid">
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.hasError('required')">Please
                                                    Enter Arrear Days.</span>
                                                <!-- <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.hasError('notZero')">Please
                                                    Enter Valid Leave Days
                                                </span> -->
                                            </mat-error>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #nextpage>
                                    <tr formGroupName="{{i + startIndex}}">
                                        <td class="text-center" style="position: relative; bottom: 5px;">
                                            <button mat-menu-item type="button" (click)="deleteemployee(i)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                        <td>{{group.value.employeeNumber}}</td>
                                        <td>{{group.value.firstName}} {{group.value.lastName}}</td>
                                        <td>
                                            <input type="text" formControlName="workingdays"
                                                class="daysinput designchange" (keypress)=" onlyNumber($event)"><br>
                                            <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->


                                            <mat-error
                                                *ngIf="submitted || workingDaysFormArray.controls[i + startIndex].get('workingdays')?.touched && workingDaysFormArray.controls[i + startIndex].get('workingdays')?.invalid">
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('workingdays')?.hasError('required')">Please
                                                    Enter Working Days.</span>
                                                <span 
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('workingdays')?.hasError('notZero')">Please
                                                    Enter Valid Working Days
                                                </span>
                                            </mat-error>
                                        </td>
                                        <td>
                                            <input type="text" formControlName="otHours"
                                                class="daysinput designchange" (keypress)=" onlyNumber($event)"><br>
                                            <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->


                                            <mat-error
                                                *ngIf="submitted || workingDaysFormArray.controls[i + startIndex].get('otHours')?.touched && workingDaysFormArray.controls[i + startIndex].get('otHours')?.invalid">
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('otHours')?.hasError('required')">Please
                                                    Enter OT Hours.</span>
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('otHours')?.hasError('notZero')">Please
                                                    Enter Valid Leave Days
                                                </span>
                                            </mat-error>
                                        </td>
                                        <td>
                                            <input type="text" formControlName="arrearDays"
                                                class="daysinput designchange" (keypress)=" onlyNumber($event)"><br>
                                            <!-- <div *ngIf="submitted" [class.invalid]="!isRowValid(i)">
                                                    <small *ngIf="!isRowValid(i)" class="text-danger">Please Enter Leave Days</small>  
                                                </div>  -->


                                            <mat-error
                                                *ngIf="submitted || workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.touched && workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.invalid">
                                                <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.hasError('required')">Please
                                                    Enter Arrear Days.</span>
                                                <!-- <span
                                                    *ngIf="workingDaysFormArray.controls[i + startIndex].get('arrearDays')?.hasError('notZero')">Please
                                                    Enter Valid Leave Days
                                                </span> -->
                                            </mat-error>
                                        </td>
                                    </tr>
                                </ng-template>
                            </ng-container>
                        </tbody>

                        <!-- <tbody>
                                    <tr>
                                        <td style="text-align: center;" colspan="5" *ngIf="noRecordsFound">No matching records found!</td>
                                    </tr>
                                </tbody>  -->
                    </table>
                    <div *ngIf="selectedEmployeeData?.length > 0 ">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{selectedEmployeeData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md-12 text-right">
                            <button type="button" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                [routerLink]="['home/employee-leave-list']" style="margin-right: 20px;"
                                (click)="close()">Cancel</button> 
                            <button type="submit" class="submit action-button btn btn-primary btn-sm">Save</button>
                            <!-- [disabled]="this.empForm.invalid" -->
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror" (click)="showerrormessage()" class="btn btn-danger">View Error Records
    </button>
</div>