import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import Swal from 'sweetalert2';
import { ProjectManagementService } from '../../../providers/project-management.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { ShowErrorComponent } from '../../project-management/show-error/show-error.component';
import { HeaderService } from '../../../providers/header.service';
import { RecruitmentService } from 'src/app/providers/recruitment.service';

@Component({
  selector: 'app-updatemom',
  templateUrl: './updatemom.component.html',
  styleUrls: ['./updatemom.component.scss']
})
export class UpdatemomComponent implements OnInit {

  submitted:boolean = false;
  myForm: FormGroup;
  @ViewChild('select') select: MatSelect;

  allSelected=false;
  employeeList:any = []
  myProject = [
    {
      id:1,
      value:'Project'
    },
    {
      id:2,
      value:'Non Project'
    }
  ]
  momId:number
  projectList:any = []
  isShow:Boolean = false;
  mombyId: any = [];
  deletedDiscussedpoints: any=[];
  discussedpoints: any;
  deletedactionItems: any =[];
  actionItems:any;
  deletedpointsAgreed: any =[];
  pointsAgreed:any;
  customerList:any
  momdate: any;
  modifiedmomdate: string;
  modifiedTargetDate: string;
  tomorrow = new Date();
  eValue: any;
projectValue: string|number;
i: FormGroup;

success :boolean= false;
error:any=false;
sucess_msg:any
errorMsg: any;
errormessagejson: any;
importerror: boolean = false;
  isbuttondisable: boolean=false;
  usertData: any = '';
  userType: any;
  organizationId:any;
clientToNumber:any;

  constructor(private gs:ProjectManagementService,
    public dialogRef: MatDialogRef<UpdatemomComponent>,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:FormBuilder,
    private router:Router,
    private datepipe : DatePipe,
    private zone: NgZone,
    private headerservice: HeaderService,
    private elementRef: ElementRef,
    private positionservice: RecruitmentService,

    private route:ActivatedRoute) { }

  ngOnInit() {
    this.headerservice.setTitle('Update MOM')
    this.route.params.subscribe(params => {
      this.eValue = params['e'];
      console.log("Value of 'e' in update-mom component:", this.eValue);
  
      // Now you can use 'eValue' as needed in this component
    });
    console.log(this.myProject);
    console.log(this.data);

    const myData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("myData",myData);
    this.momId = this.data.momId;
    console.log("mom ID::",this.momId);
    
    // this.gs.get_projects().subscribe(
    //   (a:any)=>{
    //     this.projectList = a
    //   }
    // )

    this.gs.getEmpProjectList(myData.organization.organizationId)
    .subscribe((pa:any)=>{
      this.projectList = pa;
    console.log("response project original::",this.projectList);


    });
    

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('local storage data::', this.usertData);

    this.userType = [];
    this.usertData.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    
    this.organizationId = this.usertData.organization.organizationId;


    this.positionservice
    .getclientlist(this.organizationId)
    .subscribe((resultData: any) => {
      console.log(' resultData', resultData);
      // this.clientListData = resultData;
      this.customerList = resultData
    });


    // this.gs.Get_Customer().subscribe((x:any)=>{
    //     this.customerList = x;
    //     console.log("this.customerList",this.customerList);
    //   })

    this.myForm = this.fb.group({
      // momName:['',Validators.required,
      // Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")],
      momName: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
    
      // momAttendees:['',Validators.required],
      momAttendees: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],

      clientId:['',Validators.required],
      // meetingPlace:['',Validators.required],
      meetingPlace: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],

      momDate:['',Validators.required],
      projectValue:['',Validators.required],
      project: [''],
      pointsDiscussed: this.fb.array([
       // this.newContent()
      ]),
      pointsAgreed:this.fb.array([
      //  this.newAgree()
      ]),
      actionItem:this.fb.array([
        // this.fb.group({
        //   actionItemId:[''],
        //   actionItemName: [''],
        //   actionItemTargetDate:[''],
        //   actionItemOwner:['']
        // })
      ])

    });
   

    if(this.eValue){
      console.log("momid");
      this.gs.get_momById(this.eValue).subscribe(
        (a:any)=>{
          this.mombyId = a
          console.log("mom id::",this.mombyId);
          console.log("before patching momId::",this.mombyId);
          console.log("adata",a);
          if(this.mombyId.project){
            this.isShow = true;
            console.log("if entered" );
            
            this.myForm.patchValue({
              momName:this.mombyId.momName,
              momAttendees:this.mombyId.momAttendees,
              momDate:this.mombyId.momDate,
              clientId:this.mombyId.clientId,
              projectValue:1,
              project:this.mombyId.project.projectId,
              meetingPlace:this.mombyId.meetingPlace,
              //pointsDiscussed:this.mombyId.pointsDiscussed,
              ///actionItem:this.mombyId.actionItem,
              //pointsAgreed:this.mombyId.pointsAgreed,
            });
            const pointsDiscussedcontrol = this.myForm.get('pointsDiscussed') as FormArray;
            this.mombyId.pointsDiscussed.forEach((element,index)=> {
              this.add();
              pointsDiscussedcontrol.at(index).patchValue({
                pointsDiscussedId:element.pointsDiscussedId,
                pointsDiscussedDetails:element.pointsDiscussedDetails,
                status:element.status
              });         
            });


            const pointsAgreedcontrol = this.myForm.get('pointsAgreed') as FormArray;
            console.log("Check this condition:", this.mombyId.pointsAgreed && this.mombyId.pointsAgreed.length > 0);

            if (this.mombyId.pointsAgreed && this.mombyId.pointsAgreed.length > 0) {
              console.log("Points Agreed Length", this.mombyId.pointsAgreed.length);

              this.mombyId.pointsAgreed.forEach((element, index) => {
                console.log("Index:", index);
                console.log("Element:", element);
                console.log("Element.pointsAgreedDetails:", element.pointsAgreedDetails);

                this.addAgree();
                console.log("After addAgree:", pointsAgreedcontrol);
                
                // Add some debugging logs to check the structure of the form
                console.log("Form Structure:", this.myForm.value);

                pointsAgreedcontrol.at(index).patchValue({
                  pointsAgreedId: element.pointsAgreedId,
                  pointsAgreedDetails: element.pointsAgreedDetails,
                  status: element.status
                });
              });
            } else {
              console.log("No agreed points available.");

              this.addAgree();
              pointsAgreedcontrol.at(0).patchValue({
                pointsAgreedId: null,
                pointsAgreedDetails: '',
                status: ''
              });
            }

            if(this.mombyId.actionItem.length != 0)
            {
              const actionItemcontrol = this.myForm.get('actionItem') as FormArray;
              this.mombyId.actionItem.forEach((element,index)=> {
                this.addItem();
                actionItemcontrol.at(index).patchValue({
                  actionItemId:element.actionItemId,
                  actionItemName:element.actionItemName,
                  actionItemOwner:element.actionItemOwner,
                  actionItemTargetDate:element.actionItemTargetDate,
                  // actionItemStatus:element.actionItemStatus,
                  actionItemStatus: index === 0 ? { listTypeValueId: 3, listTypeValueName: 'pending' } : element.actionItemStatus,
                  // status: element.status
                  status:element.status
                });             
              });
            }
          }
          else{
            console.log("else");
            this.myForm.patchValue({
              momName:this.mombyId.momName,
              momAttendees:this.mombyId.momAttendees,
              momDate:this.mombyId.momDate,
              clientId:this.mombyId.clientId,
              projectValue:2,
              project:'',
              meetingPlace:this.mombyId.meetingPlace,
              // pointsDiscussed:this.mombyId.pointsDiscussed,
              // actionItem:this.mombyId.actionItem,
              // pointsAgreed:this.mombyId.pointsAgreed
            })
            if(this.myForm.value.project==''){
              console.log("nonproject entered!!");
              
              this.myForm.get('project').clearValidators();

            }

            


            const pointsDiscussedcontrol = this.myForm.get('pointsDiscussed') as FormArray;
            if (this.mombyId.pointsDiscussed && this.mombyId.pointsDiscussed.length > 0) {
              console.log(this.mombyId.pointsDiscussed.length);
            // console.log(this.mombyId.pointsDiscussed.length);
            this.mombyId.pointsDiscussed.forEach((element,index)=> {
              console.log(index);
              this.add();
              pointsDiscussedcontrol.at(index).patchValue({
                pointsDiscussedId:element.pointsDiscussedId,
                pointsDiscussedDetails:element.pointsDiscussedDetails,
                status:element.status
              });             
            });
          }
          else {
            // Handle the case when pointsDiscussed is empty
            console.log("No discussed points available.");
          
            // You might want to add a default value or perform other actions here
            // For example, you can add an empty control to the FormArray
            this.add();
            pointsDiscussedcontrol.at(0).patchValue({
              pointsDiscussedId: null,
              pointsDiscussedDetails: '',
              status: ''
            });
          }



      

                        const pointsAgreedcontrol = this.myForm.get('pointsAgreed') as FormArray;
              console.log("Check this condition:", this.mombyId.pointsAgreed && this.mombyId.pointsAgreed.length > 0);

              if (this.mombyId.pointsAgreed && this.mombyId.pointsAgreed.length > 0) {
                console.log("Points Agreed Length", this.mombyId.pointsAgreed.length);

                this.mombyId.pointsAgreed.forEach((element, index) => {
                  console.log("Index:", index);
                  console.log("Element:", element);
                  console.log("Element.pointsAgreedDetails:", element.pointsAgreedDetails);

                  this.addAgree();
                  console.log("After addAgree:", pointsAgreedcontrol);
                  
                  // Add some debugging logs to check the structure of the form
                  console.log("Form Structure:", this.myForm.value);

                  pointsAgreedcontrol.at(index).patchValue({
                    pointsAgreedId: element.pointsAgreedId,
                    pointsAgreedDetails: element.pointsAgreedDetails,
                    status: element.status
                  });
                });
              } else {
                console.log("No agreed points available.");

                this.addAgree();
                pointsAgreedcontrol.at(0).patchValue({
                  pointsAgreedId: null,
                  pointsAgreedDetails: '',
                  status: ''
                });
              }


            if(this.mombyId.actionItem.length != 0)
            {
              const actionItemcontrol = this.myForm.get('actionItem') as FormArray;
              this.mombyId.actionItem.forEach((element,index)=> {
                this.addItem();
                actionItemcontrol.at(index).patchValue({
                  actionItemId:element.actionItemId,
                  actionItemName:element.actionItemName,
                  actionItemOwner:element.actionItemOwner,
                  actionItemTargetDate:element.actionItemTargetDate,
                  actionItemStatus: index === 0 ? { listTypeValueId: 3, listTypeValueName: 'pending' } : element.actionItemStatus,
                  status:element.status
                });             
              });
            }
          }
        }
      )
    }



    //  if(this.momId){
    //    if(this.data.project){
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       momDate:['',Validators.required],
    //       project:{
    //         projectId:Number(this.data)
    //       },
    //       content: this.fb.array([
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemId:[''],
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //     this.myForm.patchValue({
    //       momName:this.data.momName,
    //       momAttendees:this.data.momAttendees,
    //       momDate:this.data.momDate,
    //       client:this.data.client,
    //       project:{
    //         projectId:this.data.project.projectId,
    //       },
    //       meetingPlace:this.data.meetingPlace,
    //       content:  this.data.content.forEach(
    //         e=>{
    //           this.content().push(
    //             this.fb.group({
    //               contentId:e.contentId,
    //               contentTitle: e.contentTitle,
    //               subContent: this.setProjects(e)
    //             })
    //           )
    //         }
    //       ),
    //       actionItem:this.data.actionItem
    //     })
    //    }
    //    else{
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       momDate:['',Validators.required],
    //       content: this.fb.array([
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemId:[''],
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //     this.myForm.patchValue({
    //       momName:this.data.momName,
    //       momAttendees:this.data.momAttendees,
    //       momDate:this.data.momDate,
    //       client:this.data.client,
    //       meetingPlace:this.data.meetingPlace,
    //       content:  this.data.content.forEach(
    //         e=>{
    //           this.content().push(
    //             this.fb.group({
    //               contentId:e.contentId,
    //               contentTitle: e.contentTitle,
    //               subContent: this.setProjects(e)
    //             })
    //           )
    //         }
    //       ),
    //       actionItem:this.data.actionItem
    //     })
    //    }
    //   // this.employeeList = this.data.momAttendees.split(',')


    // }
    // else{
    //   if(this.data == ''){
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       momDate:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       content: this.fb.array([
    //         this.newContent()
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //   }
    //   else{
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       momDate:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       project:{
    //         projectId:Number(this.data)
    //       },
    //       content: this.fb.array([
    //         this.newContent()
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //   }

    // }
    this.scrollToEditForm();

  }
   ngAfterViewInit() {
    // Scroll to the edit form after the view is initialized
    this.scrollToEditForm();
  }
  get momName() {
    return this.myForm.get('momName');
  }
  get meetingPlace() {
    return this.myForm.get('meetingPlace');
  }
  get momAttendees() {
    return this.myForm.get('momAttendees');
  }


  dateChange(event) {
    console.log(event.value);
    this.momdate = event.value;
  }
  setProjects(x) {
    let arr = new FormArray([]);
    x.subContent.forEach(y => {
      arr.push(
        this.fb.group({
          subContentId:y.subContentId,
          subContentTitle: y.subContentTitle
        })
      );
    });
    return arr;
  }

  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
  }
  content(): FormArray {
    return this.myForm.get('pointsDiscussed') as FormArray;
  }
  agree(): FormArray {
    return this.myForm.get('pointsAgreed') as FormArray;
  }
  actionItem(): FormArray {
    return this.myForm.get('actionItem') as FormArray;
  }

  newContent(): FormGroup {
    return this.fb.group({
      pointsDiscussedId :[''],
      pointsDiscussedDetails: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      status:1
      //subContent: this.fb.array([])
    });
  }
  newAgree(): FormGroup {
    return this.fb.group({
      pointsAgreedId : [''],
      pointsAgreedDetails: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      status:1
      //agreeTitle: ['',Validators.required],
      
    });
  }

  newAction(): FormGroup {
    return this.fb.group({
      actionItemId : [''],
      actionItemName: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      actionItemTargetDate:['',Validators.required],
      actionItemOwner: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      // actionItemStatus:'Pending',
      actionItemStatus: this.fb.group({
        listTypeValueId: 3, 
        listTypeValueName: 'Pending'  
      }),
      status:{
        listTypeValueId:1
      }
       
    });
  }

  get f(){
    return this.myForm.controls;
  }

  add() {
    this.content().push(this.newContent());
  }

  addAgree(){
    this.agree().push(this.newAgree());

    
  }

  addItem() {
    this.actionItem().push(this.newAction());
  }

  onChange(e){
    if (e == 1) {
      this.isShow = true;
      this.myForm.get('project').setValidators([Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]);
    } else {
      this.isShow = false;
      this.myForm.get('project').clearValidators();
    }
    // Trigger validation update after modifying validators
    this.myForm.get('project').updateValueAndValidity();
  }

  remove(index: number , group) {
    console.log(group);
    // if(index == 0){
    //   return
    // }
    // else{
    //   this.content().removeAt(index);
    // }
    if(this.eValue)
    {
      let pointsDiscussedId =  group.value.pointsDiscussedId;
      console.log("pointsDiscussedId" + pointsDiscussedId);

      if(pointsDiscussedId)
      {
        this.discussedpoints = {
          ['pointsDiscussedId'] : group.value.pointsDiscussedId,
          ['pointsDiscussedDetails']: group.value.pointsDiscussedDetails,
          ['status'] : 4
        }
        this.deletedDiscussedpoints.push(this.discussedpoints);
        console.log(this.deletedDiscussedpoints);
        const control =  this.myForm.get('pointsDiscussed') as FormArray;
        control.removeAt(index);
      }else{
        const control =  this.myForm.get('pointsDiscussed') as FormArray;
        control.removeAt(index);
      }
    }
  }

  removeItem(index: number, action) {
    console.log(action);
    // if(index == 0){
    //   return
    // }
    // else{
    //   this.actionItem().removeAt(index);
    // }
    if(this.eValue)
    {
      let actionItemId =  action.value.actionItemId;
      console.log("actionItemId" + actionItemId);

      if(actionItemId)
      {
        this.actionItems = {
          ['actionItemId'] : action.value.actionItemId,
          ['actionItemName']: action.value.actionItemName,
          ['actionItemTargetDate'] : action.value.actionItemTargetDate,
          ['actionItemOwner'] : action.value.actionItemOwner,
          ['status'] : 4
        }
        this.deletedactionItems.push(this.actionItems);
        console.log(this.deletedactionItems);
        const control =  this.myForm.get('actionItem') as FormArray;
        control.removeAt(index);
      }else{
        const control =  this.myForm.get('actionItem') as FormArray;
        control.removeAt(index);
      }
    }
  }

  removeAgree(index: number,group) {
    // if(index == 0){
    //   return
    // }
    // else{
    //   this.agree().removeAt(index);
    // }
    if(this.eValue)
    {
      let pointsAgreedId =  group.value.pointsAgreedId;
      console.log("pointsAgreedId" + pointsAgreedId);

      if(pointsAgreedId)
      {
        this.pointsAgreed = {
          ['pointsAgreedId'] : group.value.pointsAgreedId,
          ['pointsAgreedDetails']: group.value.pointsAgreedDetails,
          ['status'] : 4
        }
        this.deletedpointsAgreed.push(this.pointsAgreed);
        console.log(this.deletedpointsAgreed);
        const control =  this.myForm.get('pointsAgreed') as FormArray;
        control.removeAt(index);
      }
      else{
        console.log("else");
        const control =  this.myForm.get('pointsAgreed') as FormArray;
        control.removeAt(index);
      }
    }
  }

  myContent(index: number): FormArray {
    return this.content()
      .at(index)
      .get('subContent') as FormArray;
  }

  newSkill(): FormGroup {
    return this.fb.group({
      subContentTitle: ['',Validators.required],
    });
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z0-9_. -]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  addsubContent(index: number) {
    this.myContent(index).push(this.newSkill());
  }

  removesubContent(index: number, i: number) {
    this.myContent(index).removeAt(i);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  // addEmpList(){
  //   let x = this.myForm.value;
  //   if(x.momAttendees == ''){
  //     alert("Please select the options")
  //   }
  //   else{
  //     this.select.close()
  //   }
  // }

  // cancel(){
  //   this.myForm.patchValue({momAttendees:''})
  //   this.select.close()
  // }
  cancel(){
    this.router.navigate(['home/mom-reports'])
  }

  onSubmit() {
    this.submitted=true;
    console.log(this.submitted);
if(this.myForm.valid){
  this.submitted=false
  this.isbuttondisable=true

}
    this.modifiedmomdate= this.datepipe.transform(this.myForm.value.momDate , 'YYYY-MM-dd');
    console.log(this.modifiedmomdate);
    this.myForm.value['momDate'] =this.modifiedmomdate;

    this.myForm.value.actionItem.forEach(
      e=>{
        this.modifiedTargetDate= this.datepipe.transform(e.actionItemTargetDate, 'YYYY-MM-dd');
        e['actionItemTargetDate'] =this.modifiedTargetDate;
      })
if(this.myForm.value.project==''){
  console.log("nonproject entered!!");
  
  this.myForm.get('project').clearValidators();
  console.log("this.eValue wtts::",this.eValue);


}
console.log(this.myForm);

    if(this.myForm.invalid){
      console.log("entering in to the invalid checkk");
      return
    }
   
    let x = this.myForm.value;
    console.log(x);

    // let text =  x.momAttendees.toString();
    // x.momAttendees = text
console.log("this.eValue wtts::",this.eValue);

    if(this.eValue){
      console.log("is data");
      let x = this.myForm.value;
      console.log("x::",x);
      x['momId'] = this.eValue;
      if (x && x.pointsDiscussed) {
        x.pointsDiscussed.forEach((element) => {
          if (element.pointsDiscussedId === '') {
            delete element.pointsDiscussedId;
          }
        });
      }
      if(x.projectValue == 2){
        delete x.project;
      }
      
      if (x && x.pointsAgreed) {
        x.pointsAgreed.forEach((element) => {
          if (element.pointsAgreedId ==='') {
            delete element.pointsAgreedId;
          }
        });
      }
      if (x && x.actionItem) {
        x.actionItem.forEach((element) => {
          if (element.actionItemId === '') {
            delete element.actionItemId;
          }
        });
      }
      // if (x && x.project && x.projectValue) {
      //   delete x.project;
      //   delete x.projectValue;
      // }
   
      this.deletedDiscussedpoints.forEach((element,index)=>{
        console.log(element);
        console.log(index);
        x.pointsDiscussed.push(element);
        console.log(x.pointsDiscussed);       
      })
      x.pointsDiscussed = x.pointsDiscussed

      this.deletedpointsAgreed.forEach((element,index)=>{
        console.log(element);
        console.log(index);
        x.pointsAgreed.push(element);
        console.log(x.pointsAgreed);  
      })
      x.pointsAgreed = x.pointsAgreed

      this.deletedactionItems.forEach((element,index)=>{
        console.log(element);
        console.log(index);
        x.actionItem.push(element);
        console.log(x.actionItem);
      })
      x.actionItem =  x.actionItem

       
      // this.scrollToEditForm();

        
        
        
      
      // if (!x.pointsAgreedId) {
      //   delete x.pointsAgreedId;
      // }
    
      // if (!x.actionItemId) {
      //   delete x.actionItemId;
      // }
      console.log(x);
      // console.log(JSON.stringify(x))
      //return
      this.gs.Update_mom(this.eValue,x).subscribe(
        (d:any)=>{

          console.log("update response::");
          console.log("update.statuscode",d.statusCode);
          if(d.statusCode==200){
            this.success=true
            this.sucess_msg=d.description
            this.isbuttondisable=true

            setTimeout(() => {
              this.zone.run(() => {
                this.isbuttondisable=false
                this.router.navigate(['/home/mom-reports']);
              });
            },4000);
          }
          this.scrollToEditForm()

          this.isbuttondisable=false

        },
        err=>{
          this.error=true
          this.errorMsg=err.message

          setTimeout(() => {
            this.error=false
          }, 5000);
         
        
        }
      )

    }
    else{
      console.log("else enterd");
      
      if(x.projectValue == 1 && x.project == ''){
        this.myForm.controls['project'].setErrors({ 'required': true })
        return
      }
      if(x.projectValue == 1){
        x['project'] = {
          projectId : Number(x.project)
        }
      }
      if(x.projectValue == 2){
      //  x.removeControl('project');
        x['project'] = {
          projectId : null
        }
      }
      console.log(JSON.stringify(x))
      return
        this.gs.Create_mom(this.myForm.value).subscribe(
          (d:any)=>{
            Swal.fire({
              text:d.description,
              icon:'success'
            })
            //window.location.reload()
          },
          err=>{
            Swal.fire({
              text:err.error.message,
              icon:'error'
            })
            //window.location.reload()
          }
        )
    } 
  
  
    // this.router.navigate(['home/mom-reports'])


  }

  showerrormessage()
  {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
}
