import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'app-userrole',
  templateUrl: './userrole.component.html',
  styleUrls: ['./userrole.component.scss']
})
export class UserroleComponent implements OnInit {

  @ViewChild('select') select: MatSelect;

  postForm: FormGroup;
  submitted = false;
  errorMsg : any;
  resultData:any;
  arrayData:any = [];
  arrayDataPatch:any = [];
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  isMasterSel:boolean;
  allSelected=false;
  roleNameExistStatus :any;
  updateroleNameExistStatus :any;
  activeMenusData :any;
  employeetData :any;
  
  constructor(
    private settingsservice: SettingsService,
    private employeeservice: EmployeeService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<UserroleComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any,
    private gs:GlobalserviceService

  ){ this.isMasterSel = false; }

  ngOnInit(): void {

     /* Add Validation Designation Data */
     this.postForm = this.formBuilder.group({
      menuId : ['',[Validators.required]],
      roleName : ['',[Validators.required]],
      roleDescription : [''],
    });

    if(this.edit_data)
    {
      this.settingsservice.getRoleById(this.edit_data.roleId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;


        this.postForm.patchValue({
          roleName: this.resultData.roleName,
          roleDescription: this.resultData.roleDescription,
          menuId:this.resultData.menu.map(
            d=> {return d.menuId}
          ),
        });
      });
     
    }

    this.getActivemenus();

  }
  get validate() { return this.postForm.controls; }

  getActivemenus()
  {
    this.settingsservice.getActivemenus().subscribe((result: any) => {
      this.activeMenusData = result;
    })
  }


  onSubmit() //form Submit Function
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    // let organizationId = this.employeetData.organization.organizationId;
    // console.log('organizationId'+organizationId)
    this.submitted = true;
    //stop here if form is invalid
    if (this.postForm.invalid || this.roleNameExistStatus == true) {return;}

    if(this.edit_data) //Edit
    {
      let x = this.postForm.value;
      x.menuId.map(
        e => {
          let data;
          if (e) {
            data = {
                menuId: e
            }
          }
          this.arrayData.push(data)
        }
      )

      let postValues = this.postForm.value;
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    //  let organizationId = this.employeetData.organization.organizationId;

   //   postValues['organization'] = {organizationId: organizationId}
      postValues['roleId'] = this.edit_data.roleId;
      postValues['roleStatus'] = this.edit_data.roleStatus;
      postValues['menu'] =  this.arrayData;

      this.backdrop = true;
      this.loader=true;

      /* if(this.postForm.valid && this.updateroleNameExistStatus == true)
      {
         return
      } */

      this.settingsservice.editRole(this.edit_data.roleId,postValues)
      .subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/designation' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    else //Create
    {
      let x = this.postForm.value;
      x.menuId.map(
        e => {
          let data;
          if (e) {
            data = {
                menuId: e
            }
          }
          this.arrayData.push(data)
        }
      )

      let postValues = this.postForm.value;
   //   postValues['organization'] = {organizationId: organizationId}
      postValues['menu'] =  this.arrayData;
      console.log("PV:",postValues);
      console.log("AD:",this.arrayData);
      /* if(this.postForm.valid && this.roleNameExistStatus == true)
        {
          return
        }
      */

      this.settingsservice.createRole(postValues).subscribe((user: any) =>
      {

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
  }

checkExistRoleName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

  //  let organizationId = this.employeetData.organization.organizationId;
   // console.log('organizationId'+organizationId)
    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */
    if(this.postForm.value.roleName.length != 0)
    {
      let postValues = this.postForm.value;
   //   postValues['organization'] = {organizationId: organizationId}

      if(this.edit_data)
      {
        postValues['roleId'] = this.edit_data.roleId;

        this.settingsservice.updateCheckExistRoleName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.roleNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.roleNameExistStatus = true;
          }
        })
      }
      else
      {
        this.settingsservice.createCheckExistRoleName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.roleNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.roleNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.roleNameExistStatus = false;
    }
  }
/*
updatecheckExistRoleName(val)
  {
        this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    if(this.edit_data)
    {
      editData = this.edit_data;
    }

    if(this.postForm.value.roleName.length != 0)
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['roleId'] = this.edit_data.roleId;

      console.log(postValues);

      this.settingsservice.updateCheckExistRoleName(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.updateroleNameExistStatus = false;
        }
        else if(result.statusCode > 400)
        {
          this.updateroleNameExistStatus = true;
        }
      })
    }
    else
    {
      this.updateroleNameExistStatus = false;
    }
  }
 */


  toggleAllSelection()
  {
    if (this.allSelected)
    {
      this.select.options.forEach((item: MatOption) => item.select());
    }
    else
    {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick()
  {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) =>
    {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  add()
  {
    let x = this.postForm.value;
    if(x.menuId == '')
    {
      alert("Please select the options")
    }
    else
    {
      this.select.close()
    }
  }

  cancel()
  {
    this.postForm.patchValue({menuId:''})
    this.select.close()
  }


  save()
  {
    /* this.submitted=true;
    if(this.postForm.invalid){return}
      let x = this.postForm.value;
      x.employee.map(
        e => {
          let data;
          if (e) {
            data = {
              menu: {
                menuId: x.menuname
              }
            }
          }
          this.arrayData.push(data)
        }
      ) */
     /* console.log(JSON.stringify(this.arrayData))this.gs.AssignEmpRole(this.arrayData).subscribe(
        (e:any)=>{
        //   alert("success");
          Swal.fire({
            text:e.message,
            icon:'success'
          })
          window.location.reload();
        },err =>{
          this.arrayData.length = 0;
        }
      ) */
  }

}


