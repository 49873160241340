import { Component, Inject, OnInit } from '@angular/core';
import { LeaveService } from '../providers/leave.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-leavemanagementstatus',
  templateUrl: './leavemanagementstatus.component.html',
  styleUrls: ['./leavemanagementstatus.component.scss']
})
export class LeavemanagementstatusComponent implements OnInit {
  storeFirstname: void;
  storeApproveData: any;
  nodata: boolean=false;
   loader :any =true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private leaveservice :LeaveService

  ) { }

  ngOnInit(): void {
    console.log("this.data",this.data);
    // console.log(this.data.paymentAdvice.paymentAdviceId);
console.log(this.data.referredwith);
// console.log(this.data.approver.approverOneObject.firstName);
    this.leaveservice.approvalsById(this.data.referredwith,this.data.id).subscribe((data:any)=>{
      console.log(data);
      this.storeApproveData=data
      this.storeFirstname=data.approver.approverOneObject.firstName
      console.log(" this.storeFirstname::", this.storeFirstname);
      console.log(this.storeApproveData);
      this.loader = false;
      // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);

    },)
  }
  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }
   approvalBackground(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#fff0c2'
    }
    if (approvalStatusName == 'Approved') {
      return '#c8e6c9'
    }
    if (approvalStatusName == 'Denied') {
      return '#ffcdd2'
    }
  }
}
