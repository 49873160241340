<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span routerLink="/home/financialyear" class="h5 sub-content heading-tag">Add Financial Year</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Branch<span class="star">*</span></label>
                            <select formControlName="branch" placeholder="- Select Branch -" class="form-control email-input select-placeholder">
                                <option [value]="null" selected="selected"> --- Select Branch --- </option>
                                <option *ngFor="let row of branchlist; let i=index;" value="{{row.branchId}}">{{row.branchName}}</option>
                            </select>
                            <div class="valid-msg" *ngIf="(validate.branch.invalid && validate.branch.touched) || validate.branch.dirty || submitted">
                                <span *ngIf="validate.branch.errors?.required" class="text-danger">Please enter Branch Code</span>
                                <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>
                                <span *ngIf="existMsg" class="text-danger">{{existMsg}}</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label">
                                Financial From Month<span class="star">*</span></label>
                            <select formControlName="monthfrom" placeholder="- Select Month -" class="form-control email-input select-placeholder">
                                <option [value]="null" selected="selected"> --- Select Month--- </option>
                                <option *ngFor="let month of months" [value]="month">{{ month }}</option>
                            </select>
                            <div class="valid-msg" *ngIf="(validate.monthfrom.invalid && validate.monthfrom.touched) || validate.monthfrom.dirty || submitted">
                                <span *ngIf="validate.monthfrom.errors?.required" class="text-danger">Please enter From Month</span>
                                <!-- <span *ngIf="validate.monthfrom.errors?.pattern" class="text-danger">Please enter valid Branch Name</span> -->
                            </div>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="form-control-label"> Financial From Year<span class="star">*</span></label>
                            <select formControlName="yearfrom" placeholder="--- Select Year-- -" (change)= "validateyear()" class="form-control email-input select-placeholder">
                                <option [value]="null" selected="selected"> --- Select Year --- </option>
                                <option *ngFor="let year of years; let i=index;" value="{{year}}">{{year}}</option>
                            </select>
                            <div class="valid-msg" *ngIf="(validate.yearfrom.invalid && validate.yearfrom.touched) || validate.yearfrom.dirty || submitted">
                                <span *ngIf="validate.yearfrom.errors?.required" class="text-danger">Please Enter From Year</span>
                                <!-- <span *ngIf="validate.monthfrom.errors?.pattern" class="text-danger">Please Enter Valid Branch Name</span> -->
                            </div>
                        </div>

                     
                        <div class="form-group col-md-3">
                            <label class="form-control-label">
                                Financial To Month<span class="star">*</span></label>
                            <!-- <select formControlName="monthto" placeholder="- Select Month -" class="form-control email-input select-placeholder">
                                <option [value]="null" selected="selected"> --- Select Month --- </option>
                                <option *ngFor="let month of months" [value]="month">{{ month }}</option>
                            </select> -->
                            <mat-select formControlName="monthto" placeholder="Select Month" class="form-control email-input select-placeholder">
                                <mat-option [value]="null" selected="selected"> -Select Month- </mat-option>
                                <mat-option *ngFor="let month of months" [value]="month">
                                  {{month}} 
                                </mat-option>
                              </mat-select>
                            <div class="valid-msg" *ngIf="(validate.monthto.invalid && validate.monthto.touched) || validate.monthto.dirty || submitted">
                                <span *ngIf="validate.monthto.errors?.required" class="text-danger">Please Enter To Month</span>
                                <!-- <span *ngIf="validate.yearfrom.errors?.pattern" class="text-danger">Please enter valid Month</span> -->
                            </div>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="form-control-label"> Financial To Year<span class="star">*</span></label>
                            <select formControlName="yearto" placeholder="- Select Year -" (change)= "validateyear()" class="form-control email-input select-placeholder">
                                <option [value]="null" selected="selected"> --- Select Year --- </option>
                                <option *ngFor="let year of years; let i=index;" value="{{year}}">{{year}}</option>
                            </select>
                            <div class="valid-msg" *ngIf="(validate.yearto.invalid && validate.yearto.touched) || validate.yearto.dirty || submitted">
                                <span *ngIf="validate.yearto.errors?.required" class="text-danger">Please Enter To Year</span>
                                <!-- <span *ngIf="validate.monthfrom.errors?.pattern" class="text-danger">Please enter valid Branch Name</span> -->
                            </div>
                              <span *ngIf="yearinvalid == true" class="text-danger">Please Select valid Year</span> 
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Description<span class="star">*</span></label>
                            <input type="text" formControlName="description" id="description" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder="Description" autocomplete="off">
                            <div class="valid-msg" *ngIf="(validate.yearto.invalid && validate.yearto.touched) || validate.yearto.dirty || submitted">
                                <span *ngIf="validate.yearto.errors?.required" class="text-danger">Please Enter To Year</span>
                                <!-- <span *ngIf="validate.monthfrom.errors?.pattern" class="text-danger">Please enter valid Branch Name</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                         <div class="col-md-8">
                            <span style="float:left;color:red">
                                <b>Note : </b> After submission, months will be created for the selected financial year
                            </span>
                         </div>
                        <div class="col-md-4 text-right">
                            <a routerLink="/home/financialyear" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2">
                                Cancel
                            </a>
                            <button type="submit" mat-raised-button color="primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>