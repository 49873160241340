<!-- <div class="container" >
    <div class="row" style="margin-top: 70px;">
        <div class="col-md-6 resource-title">
            <h2>ADVANCE</h2>
        </div>
        <div class="col-md-3 form-group search-input topnav-right" style="margin-left: 100px;">                                                                     
            <input matInput class="search form-control" type="text" placeholder="Search.." [(ngModel)]="query" (keyup)="onKeyUpEvent($event)" name="search" style="padding-left: 40px;">
            <mat-icon aria-hidden="false" aria-label="Example search icon" class="material-icons-round search-input-icon" style="position: relative; bottom: 26px; padding-left:5px; ">search</mat-icon>                                                                                                      
        </div>
    </div> -->
    <nav class="navbar bg" style="margin-top: 68px">
        <div class="container-fluid">
          <a class="navbar-brand navDesign" style=" font-size: 22px;
          font-weight: 800;
          font-family: 'Mulish', sans-serif;"> Advance</a>
          <form class="d-flex">
            <input
              class="form-control mr-2 searchDesign"
              aria-label="Search"
              type="text"
              class="form-control search-menu"
              placeholder="Search..."
              [(ngModel)]="search"
              (keyup)="changepage()"
              name="searchText"
            />
          </form>
        </div>
      </nav>

      <mat-card style="border-radius: 15px;
      border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.50));
      background: #FDFDFD;
      ">
    
    <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
    <div class="row" style="display: flex;">
        
        <div class="col-md-6 advanceDesign">
            <label class="form-control-label" style="font-size:15px;">Advance Type </label>
            <mat-select class="mat-select form-control email-input select-placeholder" style="max-width: 243px;
            /* margin-top: 50px; */
            display: block;"
                (change)="listTypeValueid($event)" appearance="fill"
                formControlName="advanceTypeSearch" placeholder="Select Advance Type"  >
                <mat-option class="text-color " [value]="null" selected>Select Advance
                </mat-option>
                <mat-option *ngFor="let e of advanceTypeData" class="text-color"
                    [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
            </mat-select>
            
        </div>
        
        <div class="col-md-6 btnDesign" style="margin-top: 25px;">
            <button type="submit" mat-raised-button style="background: #1a83ff;color: #fff;"
                class="btn search-btn" [disabled]="btnDisable" >Search</button>
            <button style="margin-left: 20px;" type="reset" mat-raised-button
               class="btn-clear search-btn mr-2" (click)="clearforms()"[disabled]="btnDisable"
                >Clear</button>
        </div>
        
    </div>
    </form>

    <div class="col-md-12 DesigniLabel">
      <b>Manage Advance</b>
    </div>

    
    <form (ngSubmit)="submitData()" [formGroup]="advanceform" >
        <div class="row labelsDesign" style="margin-top:30px;">
            <div class="form-group col-md-3 formStyle">
                <label class="form-control-label">Advance Amount<span class="star">*</span></label>
                
              <input type="text" class="form-control email-input select-placeholder"  (paste)="disablePaste($event)" maxlength="6" formControlName="advanceamount" 
              [readonly]="advanceamountreadonly" placeholder="Enter Advance Amount" (keypress)=" noNumber($event)"
              >
              <div *ngIf="advanceamount?.invalid || advanceamount?.dirty">
                <!-- <small *ngIf="advanceamount.errors?.required" class="text-danger">Please Enter Advance Amount</small> -->
                <small *ngIf="advanceamount.errors?.pattern" class="text-danger">Please Enter valid Advance Amount</small>   
            </div> 

              <!-- <div *ngIf="advanceamount && (advanceamount.invalid && advanceamount.touched || advanceamount.dirty)">
                <small *ngIf="advanceamount.errors?.required" class="text-danger">Please enter advance amount</small>
                <small *ngIf="advanceamount.errors?.pattern" class="text-danger">Please enter valid advance amount</small>
            </div> -->
            <!-- <mat-error
              *ngIf="
                advanceform.get('advanceamount').hasError('required') && submitted" style="font-size: 12px;">
              Advance Amount is required -->
              <!-- <small *ngIf="advanceform.get('advanceamount').errors?.pattern" class="text-danger">Please enter a valid amount</small>  -->
              <!-- <small *ngIf="advanceamount.errors?.pattern" class="text-danger">Please enter valid advance amount</small> -->
              <!-- <small *ngIf="advanceform.get('advanceamount').errors?.pattern" class="text-danger">Please enter a valid amount (greater than 0)</small> -->
              <!-- <small *ngIf="advanceamount.errors?.pattern" class="text-danger">Please enter valid advance amount</small> -->

            <!-- </mat-error> -->
            <div
            *ngIf="
          advanceform.get('advanceamount').hasError('required') && (advanceform.get('advanceamount').dirty || submitted)              "
            class="text-danger"
          >
            <small class="text-danger" *ngIf="advanceamount.errors?.required"> 
              <span>Advance Amount is required </span>  </small>
          </div>
              
            </div>
            <div class="form-group col-md-3" >
              <label class="form-control-label">Description</label>
              <input
              formControlName="description" placeholder="Description"
                        class="form-control email-input select-placeholder"
                       />
                      
             
            </div>
         
            <div class="col-md-3" >
              <label class="form-control-label">Advance Type<span class="text-danger"> *</span></label>
              <mat-select 
              class="form-control email-input select-placeholder"
              (change)="listTypeValueid($event)" appearance="fill"
              formControlName="advanceType" placeholder="Select Advance Type">
              <mat-option class="text-color " [value]="undefined" selected>Select Advance Type
</mat-option>
              <mat-option *ngFor="let e of advanceTypeData" class="text-color"
                  [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
          </mat-select>
          <!-- <div *ngIf="advanceform.get('advanceType').invalid && advanceform.get('advanceType').touched">
              <small class="text-danger">Please select expense type</small>
            </div> -->
            <!-- <div *ngIf="(f.advanceType.invalid && submitted) || f.advanceType.dirty">
              <small *ngIf="f.advanceType.errors?.required" class="text-danger">Advance type is required</small>
            </div> -->
            <div
            *ngIf="
              advanceform.get('advanceType').hasError('required') && submitted" class="text-danger"
          
          >
          <small class="text-danger">Advance Type is required

          </small>    
          </div>
          </div>

            <div class="col-md-2 buttonDesign" style="margin-top: 28px;">
              <button   mat-raised-button type="submit" [disabled]="btnsDisable"
              class="searchBtn  mr-2"  style=" margin-left: 10px;
              height: 30px;
              position: relative;
              top: 0px;"
              >Save</button>
                <button type="button" mat-raised-button 
                    class="btn   btnRes  mr-2"  (click)="formcancel()"  [disabled]="btnsDisable">Clear</button>
              
            </div>
            <!-- <div
            class="card-body table-responsive float-end"
           
          > -->
            <!-- <ng-container *ngIf="timesheetreport.length > 0;else elseProject"> -->
            <div class="float-end"  >
              <div class="row rowDesignshow" *ngIf="advancelistdatafilted.length>0 "  >
                <div class="select-pagination" style=" margin-top: 85px;margin-left: 15px;margin-bottom: 10px;"*ngIf="advancelistdatafilted.length>5">
                  <span class="showPage showDesign">Show</span>
                  <select
                    class="select_list new-select_list listDesign"
                    [(ngModel)]="mySelect"
                    [ngModelOptions]="{standalone: true}" (change)="changefirstpage()"
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive" >
              <table class="table  --table-bordered table-border-new"  matSort (matSortChange)="sortData($event)" style="border-bottom: 1px solid #ddd;" *ngIf="tableShow">
                  <thead class="t-head">
                      <tr  id="trDesign" >
                          <th style="border-radius: 15px 0px 0px 0px" class="text-center" mat-sort-header="advanceAmount">Advance Amount</th>
                          <th  class="text-center">Advance&nbsp;Given&nbsp;On Date</th>
                          <th  mat-sort-header="advanceSpent" class="text-center">Advance Spent</th>
                          <th mat-sort-header="advanceBalance" class="text-center" >Advance Balance</th>
                          <th  class="text-center table-head">View Approvers</th>
                          <th  class="text-center table-head" style="border-radius: 0px 15px 0px 0">Approval Status</th>
                        </tr>
                  </thead>
                   <tbody style="    border-left: 1px solid #ddd;
                   border-bottom: 1px solid #ddd;
                   border-right: 1px solid #ddd;">
                      <tr  *ngFor="
                     let e of advancelistdatafilted| mytask : search
 | paginate: { itemsPerPage: mySelect, currentPage: p } 
                    ">
                       
                       <td class="text-center table-data">{{e.advanceAmount}}</td>
                       <td class="text-center table-data"> {{ e.advanceGivenOn ? e.advanceGivenOn : '--' }}</td>
                       <td class="text-center table-data">{{e.advanceSpent}}</td>
                       <td class="text-center table-data">{{e.advanceBalance}}</td>
                       <td class="table-data text-center">
                        <mat-icon style="position: relative; top:5px;" class="view-icon"
                        (click)="viewapproverstatus(e)">visibility</mat-icon>
                        <!-- <span *ngIf="e.approvals == undefined">  --  </span> -->
                    </td>
                    <td class="text-center table-data">
                        <div *ngIf="e.approvalStatusName != undefined">
                            <span class="text-success" *ngIf="e.approvalStatusName == 'Approved'">
                                {{e.approvalStatusName}}
                            </span>
                            <span class="text-warning" *ngIf="e.approvalStatusName == 'Pending'">
                                {{e.approvalStatusName}}
                            </span>
                            <span class="text-danger" *ngIf="e.approvalStatusName == 'Denied'">
                                {{e.approvalStatusName}}
                            </span>
                            <span class="text-muted" *ngIf="e?.approvalStatusName==undefined">
                              --
                          </span>
                           
                        </div>
                        <!-- <div >
                          <span class="text-success" *ngIf="e?.approvalStatusName=== 'Approved'">
                            {{ e?.approvalStatusName }}
                        </span>
                        <span class="text-warning" *ngIf="e?.approvalStatusName === 'Pending'">
                            {{ e?.approvalStatusName }}
                        </span>
                        <span class="text-danger" *ngIf="e?.approvalStatusName === 'Denied'">
                            {{ e?.approvalStatusName }}
                        </span>
                        <span class="text-muted" *ngIf="!e?.approvalStatusName">
                            --
                        </span>
                      </div> -->
                      
                        
                        <div class="text-success" *ngIf="e.approval != undefined">Saved as Draft</div>
                    </td>
                      </tr>
                     
                      <!-- <tr *ngIf="advancelistdatafilted.length === 0 && !search" style="border: 1px solid #ddd;">
                        <td style="    text-align: center;
                        padding: 10px;
                        font-size: 19px;
                        font-weight: 600;
                        font-family: 'Mulish', sans-serif;
                        left: 5;
                        position: relative;
                       " colspan="6" *ngIf="nodata">Advance Records Not
                            Found!</td>
                    </tr> -->
                    <tr *ngIf="(advancelistdatafilted | mytask : search).length === 0 && search">
                      <td style="    text-align: center;
                      padding: 10px;
                      font-size: 19px;
                      font-weight: 600;
                      font-family: 'Mulish', sans-serif;
                      left: 5;
                      position: relative;
                      ;" colspan="6" >Search Advance  Not
                          Found!</td>

                    </tr>
                     
                  </tbody> 
                  <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd">
                    <tr>
                      <td
                        style="
                           text-align: center;
                        padding: 10px;
                        font-size: 19px;
                        font-weight: 600;
                        font-family: 'Mulish', sans-serif;
                        left: 5;
                        position: relative;
                       " colspan="6" 
                        
                      
                        *ngIf="
                          nodata ||advancelistdatafilted.length == 0"
                      >
                        Advance Records Not Found!
                      </td>
                    </tr>
                  </tbody>
                
                  <!-- <tr>
                    <td style="    text-align: center;
                    position: relative;
                    left: 82px;" colspan="5" *ngIf="noRecordsFound">No Records
                        Found!</td>
                </tr> -->
               
              </table>
           </div>
           <div *ngIf="
           advancelistdatafilted.length > 5 &&
           (advancelistdatafilted | mytask : search).length > 0 && setbool
         "  >
            <pagination-controls
           (pageChange)="p = $event" class="float-right pagination" style="float:right"
           *ngIf="(advancelistdatafilted | mytask : search).length > 5"

           align="end" 
         ></pagination-controls>
           </div>
           
       </div>
      
   
     
        
 <div *ngIf="loader" class="align-items-center text-center">
   <!-- <div
     class="spinner-border"
     style="width: 2rem; height: 2rem;align-items: center;margin-left:1000px;color: #1A83FF;margin-top:20px;"
     role="status"
   ></div> -->
 </div>
 <!-- </div> -->
 <!-- </div> -->
 </form>
 <!-- <div class="card-body" *ngIf="nodata" style="border:1px solid #ddd">
  <div class=" ">
    <h4 align="center" style="text-align: center;
    position: relative;
    /* left: 425px; */
    /* padding: 10px; */
    font-size: 19px;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    "> Advance Not Found</h4>
  </div>
</div> -->

</mat-card>


    <div *ngIf="loader" class="align-items-center text-center">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
    </div>
    <!-- <div class="text-center" *ngIf="success"> 
        <div class="alert alert-success" role="alert" style="margin-top: 67px;">
            <strong>Success!</strong><br>{{create_sucess_msg}}
        </div>
    </div>
    <div *ngIf="error" class="alert alert-danger alert-dismissible">
        <strong>Error!</strong><br>{{update_error_msg}}
    </div>
         -->

<!-- </div> -->
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert" style="margin-top: 80px;">
      <strong>Success!</strong><br>{{sucess_msg}}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br>{{errorMsg}}
  <button *ngIf="importerror" (click)="showerrormessage()"   class="btn btn-danger"> View Error Records
  </button>
</div>

