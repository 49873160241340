Sub header start here
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Annexue</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/" mat-raised-button title="Back" class="">Back</a>
                    <a   routerLink="/home/client-employee-annexue"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Annexue
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row mb-4">
                            <div class="col-md-12">
                                <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                                    <div class="row pl-0">
                                        <div class="form-group col-md-3">
                                            <label class="col-form-label">Client Name<span class="star"> *</span></label> 
                                            <select formControlName="client"  class="form-select form-select-solid form-select-lg select2-new" (change)="getclientEmployees()">
                                                <option [value]="null"> --- Select Client --- </option>
                                                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                            </select>    
                                            <div class="valid-msg" *ngIf="(serachvalidate.client.invalid && serachvalidate.client.touched) || serachvalidate.client.dirty || submitted">
                                                <span *ngIf="serachvalidate.client.errors?.required" class="text-danger">Please select client</span>
                                                <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                            </div>
                                        </div>
                                        <!-- <div class="ol-xl-3 col-lg-3 mb-2">

                                            <label class="col-form-label">Employee</label>          
                                            <input formControlName="employee" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search...">
                                            <small class="text-muted">Note : Emp No.</small>
                                        </div> -->
                                        <div class="form-group col-md-3">
                                            <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                                search
                                            </mat-icon> -->
                                            <label class="col-form-label">Employee<span class="star"> *</span></label>
                                            <input type="text"matInput  placeholder="Search Employee"   
                                                        formControlName="employee"
                                                        [matAutocomplete]="auto" [readonly]="!searchForm.value.client || searchForm.value.client == 'null'"
                                                        class="form-control" (keyup)="empSearch($event)">
                                                <mat-autocomplete #auto="matAutocomplete" >
                                                    <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                                        </mat-option> -->
                                                    <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                                    {{emp.employeeNumber}}- {{emp.firstName}}  
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                                    search
                                                </mat-icon>
                                            <!-- <div class="valid-msg" *ngIf="submitted">        
                                                <span *ngIf="serachvalidate.employee.errors?.required" class="text-danger">Please select employee</span> 
                                            </div> -->
                                        </div>
                                        <div class="col-md-3">
                                            <label class="col-form-label">Start Date</label>         
                                            <input formControlName="startDateSearch" id="startDateSearch" matInput [matDatepicker]="startDateSearch" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
                                               placeholder="Select Start Date">
                                            <mat-datepicker-toggle matSuffix [for]="startDateSearch" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                            <mat-datepicker #startDateSearch panelClass="example-month-picker"></mat-datepicker>
                                            <div class="valid-msg" *ngIf="(serachvalidate.startDateSearch.invalid && serachvalidate.startDateSearch.touched) || serachvalidate.startDateSearch.dirty">
                                                <span class="text-danger" *ngIf="serachvalidate.startDateSearch.errors?.required">Please Start Date</span>
                                            </div>
                                        </div>
                                        <div class="col-md-3" >       
                                            <label class="col-form-label">End Date</label>                                            
                                            <input formControlName="endDateSearch" id="endDateSearch"  matInput [matDatepicker]="endDateSearch" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input"
                                                [min]="searchForm.value.startDateSearch" placeholder="Select End Date">
                                            <mat-datepicker-toggle matSuffix [for]="endDateSearch" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                            <mat-datepicker #endDateSearch></mat-datepicker>
                                            <div class="valid-msg" *ngIf="(serachvalidate.endDateSearch.invalid && serachvalidate.endDateSearch.touched) || serachvalidate.endDateSearch.dirty">
                                                <span class="text-danger" *ngIf="serachvalidate.endDateSearch.errors?.required">Please select End Date</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <button type="submit" mat-raised-button color="primary"
                                                class="btn btn-primary search-btn">Search</button>
                                            <button style="margin-left: 20px;" type="reset" mat-raised-button
                                               class="btn-clear search-btn mr-2" (click)="clearTable()">Clear</button>
                                        </div>
                                    </div>
                                </form>
                            <!--  <div class="col-md-3 pl-0">
                                <button class="search-btn" mat-raised-button color="primary">Search</button>
                            </div> -->
                        </div>
                   
                   
            

                  
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left pl-0">
                                <select [(ngModel)]="pagePerItem"  (change)="pageChages()" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <!-- <option value="1500">1500</option> -->
                                </select>
                            </div>
                        </div>
                    </div>
              
                 <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <!-- <p><b>SHIFT CALENDAR</b></p> -->
                    <table class="table  --table-bordered table-border-new" style="width: 110%;border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center">Action</th>
                                <th >{{this.searchForm.value.employee ? 'Employee Code' : 'Client Code'}}</th>
                                <th>{{this.searchForm.value.employee ? 'Employee Name' : 'Client Name'}}</th>
                                <th>Version</th>
                                <th>Effective From Date</th>
                                <th>Effective To Date</th>
                                <th>City</th>
                                <th>Created Date</th>
                                <th class="text-center" >Montly Income</th>
                                <th class="text-center" >Total Compensation Per Annum</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <!-- <td class="text-center">{{i+1}}</td> -->
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="updateform(row)"  >
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                       
                                      
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 1" (click)="updateStatus(row.annexureHeaderId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>In-Active</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 2" (click)="updateStatus(row.annexureHeaderId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>
                                    </mat-menu>
                                </td>
    
                                <td>{{row.employee ? row.employee.employeeNumber : row.client.clientCode}}</td>
                                <td>{{row.employee ? row.employee.firstName : row.client.clientName}} </td>
                                <td>{{row.version}}</td>
                                <td>{{row.effectiveFromDate | date :dateFormat}}</td>
                                <td>{{row.effectiveToDate | date :dateFormat }}</td>
                                <td>{{row.place}}</td>
                                <td style="white-space: nowrap;">{{row.createDateTime  | date :dateFormat }}</td>
                                <td class="text-center"  >{{row.monthlyIncome}}</td>
                                <td class="text-center" >{{row.annualIncome}}</td>
                                <!-- capitalizeWordsName -->
                                <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                        {{row.status.listTypeValueName}}
                                    </span>
    
                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                        {{row.status.listTypeValueName}}
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="resultData.length != 0">
                                <td colspan="8" class="text-right">
                                    <b>Total :</b>
                                </td>
                                <td  class="text-center">
                                   {{this.totalmontlyincome}}
                                </td>
                                <td class="text-center">
                                    {{this.totalannualincome}}
                                 </td>
                            </tr>
                        
                          
                        </tbody>
                        <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr>
                        </tbody>
                    </table>
                 </div>
             

               
            </div>
            <div class="row" *ngIf="resultData.length != 0">
                <div class="col-md-4">
                    <div class="showing-record">
                        <!-- <ng-template pTemplate="paginatorleft" let-state>
                            Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                        </ng-template> -->
                        {{resultData.length}} rows
                    </div>
                </div>
                <div class="col-md-8 text-right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div> -->