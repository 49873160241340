
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { AppComponent } from 'src/app/app.component';
import { SettingsService } from 'src/app/providers/settings.service';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { ExportExcelService} from 'src/app/services/export-excel.service';
// import * as pdfMake from "pdfmake/build/pdfmake";
import { DatePipe } from '@angular/common';
import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { HttpClient } from '@angular/common/http';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-tds-reports',
  templateUrl: './tds-reports.component.html',
  styleUrls: ['./tds-reports.component.scss']
})
export class TdsReportsComponent implements OnInit {
  isProcessing = false;

 
  tdsForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  base64data: string | ArrayBuffer | null = null;
  req: boolean=false;
  finEmpList: any;
  eleNameList: any;
  categoryId: number;
  categoryTypeData: any;
  categoryName: any;
  employeePFval: number=0;
   totalPF: number=0;
  employeerPF: number=0;
  @ViewChild('tdsfTable')
  tdsfTable!: ElementRef;
  totaltds: any;
  period: string ="";
  dataForExcel = []; 
  employeeName: any = "";
  fileblob: string;
  headerForExcel = [
    {'Employee Code':'', 'Employee Name':'', 'Earned Wages':'', 'Period':'', 'TDS Amount':'', 'TDS YTD Amount':''}
  ];
  fromPer: any;
  toPer: any;
  fPeriod: any;
  tPeriod: any;
  constructor(
    private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    private dialog:MatDialog,
    private router:Router,
    private EmployeeService : EmployeeService,
    private superAminservice : SuperadminService ,
    private http: HttpClient,
    public ExportExcelService: ExportExcelService,
    @Inject(AppComponent) public AppData: any
  ) { }


  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.usertData.employee?.client?.clientId;
    console.log(this.clientId);
   
    this.tdsForm = this.fb.group({
      employeecode:[null,[Validators.required]],
      financialyear:[null,],
      fromperiod:[null,],
      toperiod:[null,]
    })


    if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }
  if(this.clientId){
    this.settingsService.getFinicalyearActiveListbyClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }


  if(this.orgId){
    this.superAminservice.getOrganizationById(this.orgId).subscribe(
      (resultData:any) =>{
      this.resultData = resultData;
      if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
        console.log(this.resultData.organizationAttachment.fileViewUrl);
        this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
         (blob) => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          },
          err =>{
            this.fileblob= "assets/uploads/no-image.png"
            console.log("else",this.fileblob);
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          }
        );
      }
      else{
        this.fileblob= "assets/uploads/no-image.png";
        console.log("else",this.fileblob);
        this.http.get(this.fileblob, { responseType: 'blob' })
        .subscribe(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64data = reader.result;                
              // console.log(this.base64data);
          }
          reader.readAsDataURL(res); 
          // console.log(res);
        });
      }
    }
    )
  }
  if(this.clientId){
    this.superAminservice.getClientById(this.clientId).subscribe(
      (resultData:any) =>{
      this.resultData = resultData;
      if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
        console.log(this.resultData.organizationAttachment.fileViewUrl);
        this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
         (blob) => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          },
          err =>{
            this.fileblob= "assets/uploads/no-image.png"
            console.log("else",this.fileblob);
            this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;                
                  // console.log(this.base64data);
              }
              reader.readAsDataURL(res); 
              // console.log(res);
            });
          }
        );
      }
      else{
        this.fileblob= "assets/uploads/no-image.png";
        console.log("else",this.fileblob);
        this.http.get(this.fileblob, { responseType: 'blob' })
        .subscribe(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64data = reader.result;                
              // console.log(this.base64data);
          }
          reader.readAsDataURL(res); 
          // console.log(res);
        });
      }
    }
    )
  }
  }

  // clearTable()
  // {
  //   this.resultData.length = 0;
  // }

  searchSubmit()
  {
    this.isProcessing=true;
    this.loader=true;
    this.noRecordsFound = false;
    console.log("Filter",this.tdsForm.value);
    if((this.tdsForm.value.employeecode=== '' || this.tdsForm.value.employeecode === null)&&(this.tdsForm.value.financialyear===null || this.tdsForm.value.financialyear ==='null')&&(this.tdsForm.value.fromperiod===null|| this.tdsForm.value.fromperiod ==='null')&&(this.tdsForm.value.toperiod===null|| this.tdsForm.value.toperiod ==='null'))
    {
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = false;
      alert("Please Enter Employee number");
      this.isProcessing=false;
      this.loader=false;
      return;
    }
    // if((this.pfForm.value.employeecode!=''|| this.pfForm.value.employeecode!=null)&&(this.pfForm.value.financialyear===null|| this.pfForm.value.financialyear==='null')&&(this.pfForm.value.fromperiod!=null|| this.pfForm.value.fromperiod!='null')&&(this.pfForm.value.toperiod!=null|| this.pfForm.value.toperiod!='null')
    // ||(this.pfForm.value.employeecode===''|| this.pfForm.value.employeecode===null)&&(this.pfForm.value.financialyear===null|| this.pfForm.value.financialyear==='null')&&(this.pfForm.value.fromperiod!=null|| this.pfForm.value.fromperiod!='null')&&(this.pfForm.value.toperiod!=null|| this.pfForm.value.toperiod!='null')
    // )
    // {
    //   console.log("Required financialyear");
    //   alert("Please Enter financialyear");
    // }
    if((this.tdsForm.value.employeecode=== '' || this.tdsForm.value.employeecode === null)&&(this.tdsForm.value.financialyear===null || this.tdsForm.value.financialyear ==='null')&&(this.tdsForm.value.fromperiod===null|| this.tdsForm.value.fromperiod ==='null')&&(this.tdsForm.value.toperiod===null|| this.tdsForm.value.toperiod ==='null'))
    {
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = false;
      alert("Please Enter Employee number");
      this.isProcessing=false;
      this.loader=false;
      return;
    }
    console.log(this.tdsForm.value);

    if((this.tdsForm.value.fromperiod !== null)  && (this.tdsForm.value.toperiod === null )){
      this.resultData = [];
      this.noRecordsFound = false;
      alert("Select To Period");
      this.isProcessing = false;
      this.loader = false;
    }
    else if((this.tdsForm.value.fromperiod===null) && (this.tdsForm.value.toperiod!==null)){
      this.resultData = [];
      this.noRecordsFound = false;
      alert("Select From Period");
      this.isProcessing = false;
      this.loader = false;
    }

     if((this.tdsForm.value.employeecode!='' || this.tdsForm.value.employeecode!= null)&&(this.tdsForm.value.financialyear===null || this.tdsForm.value.financialyear==='null')&&(this.tdsForm.value.fromperiod===null|| this.tdsForm.value.fromperiod==='null')&&(this.tdsForm.value.toperiod===null|| this.tdsForm.value.toperiod==='null'))
    {
      console.log("EMPLOYEE");

      if(this.orgId){
      this.payrollservice.gettdsemployeeListOrg(this.tdsForm.value.employeecode,this.orgId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {
        //   this.calculate( this.resultData);
        // }
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else
        {
          this.calculate(this.resultData);
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
          + " to "  + this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
          this.employeeName =  "Employee : " +" "+ this.tdsForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }
    if(this.clientId){
      this.payrollservice.gettdsemployeeListClient(this.tdsForm.value.employeecode,this.clientId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {
        //   this.calculate( this.resultData);
        // }
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else
        {
          this.calculate(this.resultData);
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
          + " to "  + this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
          this.employeeName =  "Employee : " +" "+ this.tdsForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }
    }
   
    else if((this.tdsForm.value.employeecode==='' || this.tdsForm.value.employeecode=== null)&&(this.tdsForm.value.financialyear!=null || this.tdsForm.value.financialyear!='null')&&(this.tdsForm.value.fromperiod===null|| this.tdsForm.value.fromperiod==='null')&&(this.tdsForm.value.toperiod===null|| this.tdsForm.value.toperiod==='null'))
    {
      console.log("YEAR");
      this.payrollservice.gettdsyearList(this.tdsForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.isProcessing=false;

        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {
        //   this.calculate( this.resultData);
        // }
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        // else
        // {
        //   console.log(this.resultData)
        //   this.resultData =     this.resultData.filter(ele=> ele.totalTDS != 0);
        //   this.calculate(this.resultData);
        //   this.period = this.resultData[0]?.finanicalPeriod?.finanicalYear.financialFromMonth + "-" + this.resultData[0]?.finanicalPeriod?.finanicalYear.financialFromYear
        //   + " to "  + this.resultData[0]?.finanicalPeriod?.finanicalYear.financialToMonth + "-" + this.resultData[0]?.finanicalPeriod?.finanicalYear.financialToYear
        //   this.employeeName ="";
        //   console.log(this.employeeName);

        // }
       else {
          console.log(this.resultData);
          
          this.resultData = this.resultData.filter(ele => ele.totalTDS != 0);
          
          if (this.resultData.length === 0) {
            console.log("No record found");
            this.noRecordsFound=true;
            this.employeeName = "";  
          } else {
            this.calculate(this.resultData);
            this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + "-" + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear
            + " to "  + this.resultData[0].finanicalPeriod.finanicalYear.financialToMonth + "-" + this.resultData[0].finanicalPeriod.finanicalYear.financialToYear;
            this.employeeName = "";  
            console.log(this.employeeName);
          }
        }       
        
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }

     else if((this.tdsForm.value.employeecode!='' || this.tdsForm.value.employeecode!= null)&&(this.tdsForm.value.financialyear!=null || this.tdsForm.value.financialyear!='null')&&(this.tdsForm.value.fromperiod===null|| this.tdsForm.value.fromperiod==='null')&&(this.tdsForm.value.toperiod===null|| this.tdsForm.value.toperiod==='null'))
    {
      console.log("EMPLOYEE AND YEAR");
      this.payrollservice.gettdsEmpandYear(this.tdsForm.value.employeecode,this.tdsForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {
        //   this.calculate( this.resultData);
        // }
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else
        {
          this.calculate(this.resultData);
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + "-" + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear
          + " to "  + this.resultData[0].finanicalPeriod.finanicalYear.financialToMonth + "-" + this.resultData[0].finanicalPeriod.finanicalYear.financialToYear
          this.employeeName ="";
           this.employeeName =  "Employee : " +" "+ this.tdsForm.value.employeecode +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }

    else if((this.tdsForm.value.employeecode==='' || this.tdsForm.value.employeecode=== null)&&(this.tdsForm.value.financialyear!=null || this.tdsForm.value.financialyear!='null')&&(this.tdsForm.value.fromperiod!=null|| this.tdsForm.value.fromperiod!='null')&&(this.tdsForm.value.toperiod!=null|| this.tdsForm.value.toperiod!='null'))
    {
      console.log("YEAR AND PERIOD");
      this.payrollservice.gettdsYearandPeriod(this.tdsForm.value.financialyear,this.tdsForm.value.fromperiod,this.tdsForm.value.toperiod).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {
        //   this.calculate( this.resultData);
        // }
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        // else
        // {
        //   this.resultData =     this.resultData.filter(ele=> ele.totalTDS != 0);
        //   this.calculate(this.resultData);
        //   console.log(this.finPeriodList);
        //   this.fromPer=this.tdsForm.value.fromperiod;
        //   this.fromPeriodFunction(this.fromPer);
        //   this.toPer=this.tdsForm.value.toperiod;
        //   this.toPeriodFunction(this.toPer);
        //   console.log(this.fPeriod);
        //   this.period = this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
        //   + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
        //   this.employeeName ="";
        // }

        else {
          console.log(this.resultData);
          
          this.resultData = this.resultData.filter(ele => ele.totalTDS != 0);
          
          if (this.resultData.length === 0) {
            console.log("No record found");
            this.noRecordsFound=true;
            this.employeeName = "";  
          } else {
            this.calculate(this.resultData);
                  console.log(this.finPeriodList);
          this.fromPer=this.tdsForm.value.fromperiod;
          this.fromPeriodFunction(this.fromPer);
          this.toPer=this.tdsForm.value.toperiod;
          this.toPeriodFunction(this.toPer);
          console.log(this.fPeriod);
          this.period = this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
          + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
          this.employeeName ="";
          }
        } 
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }

    else if((this.tdsForm.value.employeecode!='' || this.tdsForm.value.employeecode!= null)&&(this.tdsForm.value.financialyear!=null || this.tdsForm.value.financialyear!='null')&&(this.tdsForm.value.fromperiod!=null|| this.tdsForm.value.fromperiod!='null')&&(this.tdsForm.value.toperiod!=null|| this.tdsForm.value.toperiod!='null'))
    {
      console.log("BOTH");
      this.payrollservice.gettdsList(this.tdsForm.value.employeecode,this.tdsForm.value.financialyear,this.tdsForm.value.fromperiod,this.tdsForm.value.toperiod).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {
        //   this.calculate( this.resultData);
        // }
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        // else
        // {
        //   this.resultData =     this.resultData.filter(ele=> ele.totalTDS != 0);
        //   this.calculate(this.resultData);
        //   console.log(this.finPeriodList);
        //   this.fromPer=this.tdsForm.value.fromperiod;
        //   this.fromPeriodFunction(this.fromPer);
        //   this.toPer=this.tdsForm.value.toperiod;
        //   this.toPeriodFunction(this.toPer);
        //   this.period =this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
        //   + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
        //   this.employeeName =  "Employee : " +" "+ this.tdsForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        // }

        else {
          console.log(this.resultData);
          
          this.resultData = this.resultData.filter(ele => ele.totalTDS != 0);
          
          if (this.resultData.length === 0) {
            console.log("No record found");
            this.noRecordsFound=true;
            this.employeeName = "";  
          } else {
            this.calculate(this.resultData);
                console.log(this.finPeriodList);
          this.fromPer=this.tdsForm.value.fromperiod;
          this.fromPeriodFunction(this.fromPer);
          this.toPer=this.tdsForm.value.toperiod;
          this.toPeriodFunction(this.toPer);
          this.period =this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
          + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
          this.employeeName =  "Employee : " +" "+ this.tdsForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
          }
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
     }
 
  }

  fromPeriodFunction(fromPer: any) {
    console.log(fromPer);
    this.fPeriod=[];
    this.finPeriodList.forEach(element => {
      console.log(element);
      if(element.financialPeriodId==fromPer)
      {
       this.fPeriod=element;
       console.log(this.fPeriod.financialperiodMonth);
      }
   });

  
  }
  toPeriodFunction(toPer: any) {
    console.log(toPer);
    this.tPeriod=[];
    this.finPeriodList.forEach(element => {
      console.log(element);
      if(element.financialPeriodId==toPer)
      {
        this.tPeriod=element;
        console.log(this.tPeriod);
      }
   });
  }
  clearTable()
  {
    this.resultData = [];
    console.log('clear')
    this.tdsForm.clearValidators;
    this.noRecordsFound = false;
    this.finPeriodList=[];
  }
  calculate(resultData: any) {
    this.employeePFval=0;
    this.employeerPF=0;
    this.totalPF=0;
    console.log("resultData",resultData);
    resultData.forEach(element => {
       console.log(element);
   //   this.totaltds += element.
   if(element.totalTDS!=0)
   {
    console.log(this.employeePFval);
    this.employeePFval=this.employeePFval+element.totalTDS;
    console.log(this.employeePFval);
   }
   if(element.ytd!=0)
   {
    this.totalPF= this.totalPF+element.ytd;
    console.log(this.totalPF);
   }
    });
  }
  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }

  selectFinancialyear()
  {
    console.log("Select");
    // console.log(event.target.value);
    // console.log(this.FilterForm.value.financialyear);
    this.tdsForm.get('financialyear').value;
     this.chooseFinId=this.tdsForm.get('financialyear').value;

    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.tdsForm.patchValue({
        fromperiod: null,
        toperiod: null
      });
    });
  }
    // this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
    //   console.log("PeriodData", resultData)
    //   this.finPeriodList = resultData.finanicalPeriod;
    // });
  }
   
  public downloadAsExcel()
  {
  // this.excelData=this.resultData;
  // console.log(this.excelData);
  this.dataForExcel.length = 0;
  for(let e of this.resultData)
  {
    let data= {
         employeeNumber:e.employee.employeeNumber,
         firstName:e.employee.firstName+' '+e.employee.lastName,
         ctc:this.Roundof(e.totalAmount),
         financialperiodMonth:e.finanicalPeriod.financialperiodMonth+' '+e.finanicalPeriod.financialPeriodYear,
         employeePF:e.totalTDS?.toFixed(2),
         ytd:e.ytd?.toFixed(2)
        //  totalPF:e.totalPF
      }
   this.dataForExcel.push(Object.values(data))
   console.log(this.dataForExcel);
  }
  let data= {
    employeeNumber:"",
    firstName:"",
    ctc:"",
    financialperiodMonth:"Total",
    employeePF:this.employeePFval?.toFixed(2),
    ytd:this.totalPF?.toFixed(2)
    // totalPF:this.totalPF
 }
this.dataForExcel.push(Object.values(data))
   let reportDatabyrecruiter = {
     title: 'TDS Report',
     exceldate: "Period : " +  this.period,
     data: this.dataForExcel,
     employeeName :this.employeeName,
     headers: Object.keys(this.headerForExcel[0])
   }
   console.log(reportDatabyrecruiter);
   this.ExportExcelService.exportExcel(reportDatabyrecruiter);
  }
  
  printPdf()
  {
    const tdsTable = this.tdsfTable.nativeElement;
    console.log(tdsTable);
    var html = htmlToPdfmake(tdsTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { 
      content: html ,
      pageMargins: [ 40, 40, 40, 40 ],
      info: {
        title:`TDS Report`
        },
        background: function (currentPage, pageCount) {
          console.log('currentPage:', currentPage);
          console.log('pageCount:', pageCount);
      
          return {
              text: 'Page ' + currentPage.toString(),
              alignment: 'center',
              margin: [0, 8],
              fontSize: 10,
          };
         },
        pageSize: 'A4',
        pageOrientation : 'landscape'
    };
    console.log(tdsTable);
    pdfMake.createPdf(documentDefinition).download("TDS Report"); 
  }

  Roundof(amount){
    return Math.round(amount);
  }
}
