<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content" style="top:14px">
              Announcement</span
          >
        </div>
        <div class="add-business"style=    "position: relative;
        left: 66%;
        top: -11px;" >
          <button (click)=" add_announcement()" mat-raised-button class="m-1 searchBtn" style="    position: relative;
  top: -7px;">
            Add Announcement
          </button>
        </div>
      </div>
    </div>
  </div>
</section>


<div class="row ">
    <div class="col-md-12" style="      position: relative;
    top: 108px;
    padding: 24px;">
        <div class="card " style="    padding: 11px;" *ngIf="showTable">
            <!-- <div class="row mt-2">

                <div class="col-md-4 col-6">
                 
                      <input matInput class="search form-control" type="text" placeholder="Search.." [(ngModel)]="query"
                      (input)="applyFilter()" name="search" style="width: 100%;  ">
    
                </div>
                <div class="col-md-8 col-6 d-flex justify-content-end align-items-center">
                    <a (click)=" add_announcement()" color="primary" mat-raised-button title="Add Announcement" class="ml-2"
                      style="font-family: Mulish; ">
                    Add Announcement  </a>
                  </div>

            </div> -->

            <!-- <div class="row mt-5" >
          
              <div class="col-xl-12 col-lg-12" style="margin-bottom: 10px;" *ngIf="!loader">
                  <div class="select-pagination" style="float: right; margin-right: 10px;" >
                      <span>Show : </span>
                      <select class="select_list new-select_list" [(ngModel)]="mySelect" 
                      style="    border-radius: 5px;"
                      (change)="onItemsPerPageChange()" >
                          <option class="form-control" value="5"> 5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                      </select>
                  </div>
              </div>
          </div>         -->
          <div class="row">
          
           
            <div class="col-md-6">
              <div class="text-right" *ngIf="employeesData?.length > 5">
                <div
                  class="select-pagination m-2"
                  style="position: relative; top: 0px"
                >
                  <span>Show : </span>
                  <select
                    class="select_list new-select_list listDesign"
                    [(ngModel)]="pagePerItem"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="pageChages()"
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
            <div>
              
              <div *ngIf="!loader">
              <table  matSort (matSortChange)="sortData($event)"  class="table" style="border-radius: 10px;">
                  <thead class="t-head">
                      <tr>
                          <!-- <th class="text-center">S.No</th> -->

                          <th class="text-center" style="border-radius: 15px 1px 0px 0;border-top: 0px;">S.no</th>
                          <th  class="text-center">Title</th>
                          <th class="text-center">Status</th>
                          <th class="text-center" style="border-radius: 0px 15px 0px 0;border-top:0px;">Action</th>

                        </tr>
                  </thead>
                  <tbody *ngIf="!nodatafound"   style="
                  border-right: 1px solid #ddd;
                  border-left: 1px solid #ddd;
                  border-bottom: 1px solid #ddd;
                  /* border: none; */
                ">
                      <tr *ngFor="let employee of employeesData | searchFilter: query | paginate: { itemsPerPage: mySelect, currentPage: p };  let i = index">
                      
                        <td>{{ (p - 1) * mySelect + i + 1 }}</td>
                        
                          <td class="text-center">{{ employee.announcementTitle }}</td>
                          <td class="active-status text-center">
                              <span style="color:green;" class="btn  btn-sm btn-block" *ngIf="employee.status.listTypeValueId == 1">
                                {{employee.status.listTypeValueName}}
                            </span>

                              <span style="color:red;" class="btn  btn-sm btn-block" *ngIf="employee.status.listTypeValueId == 2">
                                {{employee.status.listTypeValueName}}
                            </span>
                          </td>
                          <td class="text-center">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                              <mat-icon>more_vert</mat-icon>
                          </button>
  
                            <mat-menu #menu="matMenu" class="controls-list">
                                <button mat-menu-item routerLink="/home/edit-announcement/{{employee.announcementId}}" routerLinkActive="active">
                                  <mat-icon>edit</mat-icon>
                                  <span>Edit</span>
                                </button>
  
                                <!-- <button mat-menu-item routerLink="/home/view-employee" routerLinkActive="active">
                                  <mat-icon>visibility</mat-icon>
                                  <span>View</span>
                                </button> -->
  
                                <button mat-menu-item *ngIf="employee.status.listTypeValueId == 1"  (click)="updateStatus(employee.announcementId,2)">
                                  <mat-icon>done</mat-icon>
                                  <span style="color: red;">Inactive</span>
                                </button>
                                <button mat-menu-item *ngIf="employee.status.listTypeValueId == 2"  (click)="updateStatus(employee.announcementId,1)">
                                  <mat-icon>done</mat-icon>
                                  <span style="color: green;">Active</span>
                                </button>
                            </mat-menu>
                        </td> 
                      </tr>
                  </tbody>
                  <!-- <tbody>
                      <tr>
                          <td style="text-align: center; font-size: larger; font-weight: bold;" colspan="8"  *ngIf="nodatafound">No Matching Records Found!</td>
                      </tr> 
                  </tbody>  -->
                  <tbody
                  style="border-right: 1px solid #ddd; border-left: 1px solid #ddd"
                >
                  <tr>
                    <td
                      style="
                        text-align: center;
                        font-size: larger;
                        font-weight: bold;
                      "
                      colspan="7"
                      *ngIf="nodatafound"
                    >
                     Announcement Records Not Found!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

            <!-- <div class="col-md-12 mt-5">
              <div class="panel panel-primary" style="margin-top: 30px;">
                <div class="panel-body">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>s.no</th>
                          <th>Action</th> 
                          <th>Title</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let employee of employeesData | searchFilter:query | paginate: { itemsPerPage: mySelect, currentPage: p }; let i = index">
                          <td>{{ (p - 1) * mySelect + i + 1 }}</td>
        
                          <td class="text-center">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                              <mat-icon>more_vert</mat-icon>
                          </button>

                            <mat-menu #menu="matMenu" class="controls-list">
                                <button mat-menu-item routerLink="/home/edit-announcement/{{employee.announcementId}}" routerLinkActive="active">
                                  <mat-icon>edit</mat-icon>
                                  <span>Edit</span>
                                </button>

                               

                                <button mat-menu-item *ngIf="employee.status.listTypeValueId == 1" (click)="updateStatus(employee.announcementId,2)" >
                                  <mat-icon>done</mat-icon>
                                  <span>Inactive</span>
                                </button>
                                <button mat-menu-item *ngIf="employee.status.listTypeValueId == 2" (click)="updateStatus(employee.announcementId,1)" >
                                  <mat-icon>done</mat-icon>
                                  <span>Active</span>
                                </button>
                            </mat-menu>
                        </td>                  
                                <td>{{ employee.announcementTitle }}</td>
                                <td class="active-status text-center">
                                  <span class="btn btn-outline-success btn-sm btn-block" *ngIf="employee.status.listTypeValueId == 1">
                                    {{employee.status.listTypeValueName}}
                                </span>
  
                                  <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="employee.status.listTypeValueId == 2">
                                    {{employee.status.listTypeValueName}}
                                </span>
                              </td>

                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
        
              </div>
            </div>
            -->
            <!-- <div *ngIf="loader" class="d-flex align-items-center justify-content-center" style="margin-top: 30%;" >
              <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
          </div>   -->
          <div *ngIf="loader" class="align-items-center text-center">
            <div
              class="spinner-border"
              style="width: 3rem; height: 3rem; top: 80%; right: 47.8%"
              role="status"
            ></div>
          </div>
          <!-- <div [hidden]="loader">

          <div class="col-md-7 " style="float: right; padding-left: 230px;"*ngIf="(employeesData | searchFilter: query).length > 0">
              <pagination-controls (pageChange)="p = $event" ></pagination-controls>
          </div>
        </div> -->
        <div
        class="pagination-content"
        *ngIf="
          employeesData.length > 5 && employeesData.length > 0 && paginationSet
        "
        id="page"
      >
        <pagination-controls
          *ngIf="employeesData.length > 5"
          style="position: relative; float: inline-end"
          (pageChange)="p = $event"
          align="end"
        ></pagination-controls>
      </div>
        </div>

    </div>

</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong><br>{{sucess_msg}}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br>{{error_msg}}
</div>
