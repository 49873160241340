import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-assign-employee-list',
  templateUrl: './assign-employee-list.component.html',
  styleUrls: ['./assign-employee-list.component.scss']
})
export class AssignEmployeeListComponent implements OnInit {

  filterForm: FormGroup;
  empCode: boolean = false;
  empList: any = [];
  employee:any;
  employeeId: number;
  firstname: any;
  lastname: any;
  empnumber: any;
  selectedEmployeeData: any = [];
  showtable:boolean = false;
  loader: any = false;
  usertData: any;
  orgId: number;
  userId: any;
  employeelistsub: any;
  
  tableSearch: any;
  dataArray: any = [];
  length: number;
  pagePerItem: any = 5;
  p: number = 1;
  startIndex: number;
  BusinessUnit: any;
  BusinessName: any;
  OrganizationName: any;
  businessgroup: any;
  businessunit: any;
  organization: any;
  
  isProcessing=false
  submitted: boolean = false;
  error: any = false;
  error_msg: any;
  empLevel: any = [];
  success = false;
  sucess_msg: any;
  errorMsg: any;
  errormessagejson: any;
  importerror: boolean = false;
  reportData: any;
  resultData:any = [];
  noRecordsFound: boolean =false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  entityId: any;

  constructor(
    private fb: FormBuilder,
    private payrollservice: PayrollService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
  ) { }

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.entityId = this.usertData.entity.entityId;


    // this.filterForm = this.fb.group({
    //   assignEmployeelist: [null, [Validators.required]],
    //   // period: [null, [Validators.required]],
    //   employeecode: [null, [Validators.required]],
    //   LevelName: [null, [Validators.required]],
    //   // employeeId:['']
    // })

    this.filterForm = this.fb.group({
      employeecode: [''],
      assignEmployeelist: [''],
      LevelName: [{ value: '', disabled: true }],
    });

    this.filterForm.get('assignEmployeelist')?.valueChanges.subscribe((value) => {
      const levelNameControl = this.filterForm.get('LevelName');
      if (value) {
        levelNameControl?.enable();  // Enable if Employee Level Name is selected
      } else {
        levelNameControl?.disable(); // Disable if not selected
        levelNameControl?.reset();   // Clear any selected value in Business Level Name
      }
    });
  }

  pageChanges() {
    console.log("page changes");
    this.p = 1;
  }

  searchSubmit(){
    if((this.filterForm.value.employeecode !== undefined && this.filterForm.value.employeecode !== null && this.filterForm.value.employeecode !== '') ){
      if((this.employee != this.filterForm.value.employeecode ) || (this.employeeId == null)){
        window.alert('please select valid employee from dropdown.');
        return;
      }
    }
    // this.showtable=true;
    this.isProcessing=true;
    this.loader = true;

    console.log("submit",this.filterForm.value);
    // this.loader=true;

    console.log(this.filterForm.value);

    console.log('this.filterForm.value.employeecode',this.filterForm.value.employeecode);
    console.log('this.filterForm.value.assignEmployeelist',this.filterForm.value.assignEmployeelist);
    console.log('this.filterForm.value.LevelName',this.filterForm.value.LevelName);

    if (
      !this.filterForm.value.employeecode &&
      !this.filterForm.value.assignEmployeelist &&
      !this.filterForm.value.LevelName
  ) {
      alert("Please select at least one field to proceed.");
      this.isProcessing = false;
      this.loader = false;
      return;
  }

    const employeeLevelSelected = this.filterForm.get('assignEmployeelist')?.value;
    const businessLevelSelected = this.filterForm.get('LevelName')?.value;

    if (employeeLevelSelected && !businessLevelSelected) {
      alert("Please select a Business Level Name");
      this.isProcessing=false;
      this.loader = false;
      return;
    }

    // if (!this.filterForm.get('LevelName')?.value) {
    //   alert("Please select a Business Level Name");
    //   this.isProcessing=false;
    //   return;
    // }

    if (this.filterForm.value.employeecode && !this.filterForm.value.LevelName) {
      console.log("Only Employee");
      this.payrollservice.getOnlyEmployee(this.employeeId).subscribe((resultData: any) => {
          this.showtable = true;
          this.noRecordsFound = false;
          this.loader=false;
          this.resultData = resultData;
          console.log(this.resultData);
          console.log(this.resultData.length);
          console.log(this.resultData.statusCode);
  
          if (this.resultData.length == 0 || this.resultData.length == undefined) {
              this.noRecordsFound = true;
              console.log("......");
              console.log("Length:", this.resultData.length);
          }
          if (resultData.statusCode == 204) {
              this.noRecordsFound = true;
              this.loader=false;
          }
          this.isProcessing = false;
      });
      return; // Prevent any further checks
    }

    if(!this.filterForm.value.employeecode && this.filterForm.value.LevelName == this. businessgroup ){
      console.log("businessGroup and level name");
      this.payrollservice.getBusinessGroupandLevelName(this.filterForm.value.LevelName).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.loader=false;
        }
        this.isProcessing=false;

      })
    }

    if(!this.filterForm.value.employeecode && this.filterForm.value.LevelName == this.businessunit){
      console.log("businessUnit and level name");
      this.payrollservice.getBusinessUnitandLevelName(this.filterForm.value.LevelName).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }

    if(!this.filterForm.value.employeecode && this.filterForm.value.LevelName == this.organization){
      console.log("Organization and level name");
      this.payrollservice.getOrganizationandLevelName(this.filterForm.value.LevelName).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.loader=false;
        }
        this.isProcessing=false;

      })
    }


   
    if( this.filterForm.value.employeecode && this.filterForm.value.LevelName == this.businessgroup ){
      console.log("Employee,businessGroup and level name");
      this.payrollservice.getEmpCodeandBusinessGroupandLevelName(this.filterForm.value.LevelName,this.employeeId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.loader=false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        // this.resultData = Array.isArray(resultData) ? resultData : [];
        
          console.log(this.resultData);
          console.log(this.resultData.length);
          console.log(this.resultData.statusCode);



        if(this.resultData.length == 0 || this.resultData.length == undefined)
        // if (!Array.isArray(this.resultData) || this.resultData.length === 0)
        {
          this.noRecordsFound = true;
          console.log("......");
          console.log("Length:",this.resultData.length);
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.loader=false;
        }
        this.isProcessing=false;

      })
    }

    if( this.filterForm.value.employeecode && this.filterForm.value.LevelName == this.businessunit ){
      console.log("Employee,businessUnit and level name");
      this.payrollservice.getEmpCodeandBusinessUnitandLevelName(this.filterForm.value.LevelName,this.employeeId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.loader=false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        // this.resultData = Array.isArray(resultData) ? resultData : [];
        
          console.log(this.resultData);
          console.log(this.resultData.length);
          console.log(this.resultData.statusCode);



        if(this.resultData.length == 0 || this.resultData.length == undefined)
        // if (!Array.isArray(this.resultData) || this.resultData.length === 0)
        {
          this.noRecordsFound = true;
          console.log("......");
          console.log("Length:",this.resultData.length);
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.loader=false;
        }
        this.isProcessing=false;

      })
    }


    if( this.filterForm.value.employeecode && this.filterForm.value.LevelName == this.organization ){
      console.log("Employee,Organization and level name");
      this.payrollservice.getEmpCodeandOrganizationandLevelName(this.filterForm.value.LevelName,this.employeeId).subscribe((resultData:any) =>
      {
        this.showtable=true;
      this.loader=false;
        this.noRecordsFound = false;
        this.resultData = resultData;
        // this.resultData = Array.isArray(resultData) ? resultData : [];
        
          console.log(this.resultData);
          console.log(this.resultData.length);
          console.log(this.resultData.statusCode);



        if(this.resultData.length == 0 || this.resultData.length == undefined)
        // if (!Array.isArray(this.resultData) || this.resultData.length === 0)
        {
          this.noRecordsFound = true;
          console.log("......");
          console.log("Length:",this.resultData.length);
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.loader=false;
        }
        this.isProcessing=false;

      })
    }


    


    

    

  }

  onKeyDown(event: KeyboardEvent): void {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  }


  // empSearch(event) {
  //   this.empList = [];
  //   this.empCode = false;
  //   console.log(event.target.value);
  //   this.userId = event.target.value;
  //   console.log(this.userId);

  //   if (!this.userId || this.userId === "") {
  //     // this.selectedEmployeeData=[];
  //     this.dataArray=[];
  //     // this.getFormControls.controls =[];
  //     this.selectedEmployeeData = [];
  //     console.log("User ID is invalid, skipping API call.");
  //     this.loader = false;  
  //     return;  
  //   }
  //   // Employee List Api
  //   // this.empList.length == 0;
  //   if(event.target.value.length >= 2)
  //   {
  //     if(this.employeelistsub)
  //     {
  //       this.employeelistsub.unsubscribe();
  //     }
  //     this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
  //       .pipe(
  //         takeUntil(this.unsubscribe$), // Cancel previous request
  //         switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
  //       )
  //       .subscribe((result: any) => {
  //         this.empList.length = 0;

  //         if (result.statusCode) {
  //           console.log(result.statusCode);
  //           this.empList.length = 0;
  //         } else {
  //           this.empList = result;
  //         }
  //       });
	// }
	
  // }

  empSearch(event) {
    this.empList = [];
    this.empCode = false;

    console.log(event.target.value);
    this.userId = event.target.value;
    console.log(this.userId);

    this.entityId = this.usertData.entity.entityId;
    console.log("Entity id:", this.entityId);

    this.orgId = this.usertData.organization.organizationId;
    console.log("Org id:", this.orgId);

    if (!this.userId || this.userId === "") {
        this.dataArray = [];
        // this.getFormControls.controls = [];
        this.selectedEmployeeData = [];
        console.log("User ID is invalid, skipping API call.");
        this.loader = false;
        return;
    }

    // Employee List API
    if (event.target.value.length >= 2) {
        if (this.employeelistsub) {
            this.employeelistsub.unsubscribe();
        }

        // Call different APIs based on whether orgId is null or not
        if (this.orgId != null || this.orgId != undefined ) {
            this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId, this.orgId)
                .pipe(
                    takeUntil(this.unsubscribe$), // Cancel previous request
                    switchMap(() => this.payrollservice.getEmployeeNameList(this.userId, this.orgId))
                )
                .subscribe((result: any) => {
                    this.empList.length = 0;

                    if (result.statusCode) {
                        console.log(result.statusCode);
                        this.empList.length = 0;
                    } else {
                        this.empList = result;
                    }
                });
        } else {
            this.employeelistsub = this.payrollservice.getEmployeeNameListEntity(this.userId, this.entityId)
                .pipe(
                    takeUntil(this.unsubscribe$), // Cancel previous request
                    switchMap(() => this.payrollservice.getEmployeeNameListEntity(this.userId, this.entityId))
                )
                .subscribe((result: any) => {
                    this.empList.length = 0;

                    if (result.statusCode) {
                        console.log(result.statusCode);
                        this.empList.length = 0;
                    } else {
                        this.empList = result;
                    }
                });
        }
    }
}


  // empSelect(emp) {

  //   this.employeeId = emp.employeeId;
  //   this.firstname = emp.firstName;
  //   this.lastname = emp.lastName;
  //   this.empnumber = emp.employeeNumber;
  //   console.log(this.firstname ,this.lastname ,  this.empnumber);
  //   console.log("select", this.selectedEmployeeData);

  //   let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
  //   this.showtable = true;

  //   if (!hasemployee) {
  //     this.showtable = true;
  //     this.selectedEmployeeData.push(emp);

  //     const rowsArray = this.filterForm.get('LevelName') as FormArray;
  //     // rowsArray.clear();
  //     console.log("control", rowsArray);
  //     rowsArray.push(this.formBuilder.group({
  //       employeeId: [this.employeeId],
  //       employeeNumber: [this.empnumber],
  //       firstName: [this.firstname],
  //       lastName: [this.lastname],
  //       // empleave: ['', [Validators.required], this.notZeroValidatorAsync],
  //       // LevelName: ['', [Validators.required]],
  //     }));
  //   }

  //   // }

  // }

  empSelect(emp) {
    
    console.log('empSelect', emp);
    
    
    this.employeeId = emp.employeeId;
    console.log("Emp Id:",this.employeeId)
    this.firstname=emp.firstName;
    
    this.empnumber=emp.employeeNumber;
    this.employee=this.empnumber +"-" + this.firstname
      this.filterForm.patchValue({
        employeecode: this.employee
      });
   
  
  }




  pageChages(event)
  {
    console.log('event.target.value', event.target.value);
    if(event.target.value != null || event.target.value != 0){
      // this.p = event.target.value;
      console.log("page changes");
      this.p=1;
    }
    
  }

  clearTable(){
    this.showtable=false;
  }

  passedId(row){
    console.log(row)
    console.log(row.businessGroupId);
    console.log(row.businessUnitId);
    console.log(row.organizationId);
   this. businessgroup = row.businessGroupId;
   this.businessunit = row.businessUnitId;
   this.organization = row.organizationId;



  }

 
  onCityChange(event: MatSelectChange) {
    console.log(event)
    const selectedValue = event.value;
    console.log(selectedValue);

    if (selectedValue === 1) {
      this.getBusinessGroupData();
    } else if (selectedValue === 2) {
      this.getBusinessUnit();
    } else if (selectedValue === 3) {
      this.getOrganizationData();
    }
  }

  getBusinessGroupData() {
    this.payrollservice.getBusinessGroup( this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      // this.BusinessUnit=data;
      this.BusinessUnit = Array.isArray(data) ? data : [data];
      console.log(this.BusinessUnit);
      console.log(this.BusinessUnit.businessGroupId);

      
      
    });
    this.BusinessName=[];
    this.OrganizationName=[];

  }

  getBusinessUnit() {
    this.payrollservice.getBusinessUnit( this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      this.BusinessName=data;
      this.BusinessUnit=[];
      this.OrganizationName=[];

      
    });
  }

  getOrganizationData() {

    this.payrollservice.getOrganization( this.entityId).subscribe(data => {
      console.log('Business Unit Data:', data);
      this.OrganizationName=data;
      this.BusinessUnit=[];
      this.BusinessName=[];
      
    });
  }
 

}
