import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { DatePipe} from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LeaveService } from 'src/app/providers/leave.service';
import { HeaderService } from 'src/app/providers/header.service';
import { EMPTY, Subject, catchError, takeUntil } from 'rxjs';

@Component({
  selector: 'app-leave-adjustment-list',
  templateUrl: './leave-adjustment-list.component.html',
  styleUrls: ['./leave-adjustment-list.component.scss']
})
export class LeaveAdjustmentListComponent implements OnInit {
  noData=false;
  list_count = 0
  data: any[] = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber', 'employeeName', 'organizationEmail', 'LeaveType', 'leaveadjustmentdays', 'leaveadjustmenttype'];
  dataS: any;
  branchId:any
  organization:any
  loader:boolean=false;

  leaveReport:FormGroup;
  startdate: any;
  enddate: string;
  submitted: boolean = false;
  min: string;
  show_EMsg:boolean = false;
  startdate_required:boolean = false;
  enddate_required:boolean = false;
  max: string;
  employeelist: any;
  leavetypelist:any
  employeeId: any;
  orgId:any;
  all_null:boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  leaveType: any;
  organizationId: any;
  employeeNumber: any;
  employee: string;
  employeeList: any[];
  employeeNotFound: boolean;
  unsubscribe$: any;
  selectedemployeeId: any;
  selectedemployee: any;
  isProcessing = false;
  storeleavetype: any;
  storeemployee: any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
showTable:boolean=false;
  beforesearchpage: number;
  paginators: boolean = true;

  constructor(private gs: GlobalserviceService, 
    private router: Router,
    private fb: FormBuilder,
    public datepipe:DatePipe,
    public leaveservice:LeaveService,
 private headerservice: HeaderService) {
  }

  ngOnInit() {
    
    // this.headerservice.setTitle('Leave Adjustment List')
    this.headerservice.setTitle('');

    let data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = data.organization.organizationId;
    console.log("11111111111")
    console.log(data);
    console.log()

    // this.leaveservice.activeemployeelist().subscribe(
    //   (d:any)=>{
    //     console.log(d);
    //     this.employeelist = d;
    //   })
    this.leaveservice.getLeaveType(this.organizationId).subscribe(
      (d: any) => {
        console.log(d);
        this.leavetypelist = d;
    })
    this.leaveReport = this.fb.group({
      employeeId:[''],
      leaveType:[''],

    });
    //  this.employeeId=
    // this.leaveservice.getEmpListById(data.employee.employeeId).subscribe(
      
    //   (d:any)=>{
    //     console.log(d);
    //     this.loader=false;
    //     if(d.length==0)
    //         {
    //           this.noData =true;
    //           console.log("NoData");
    //         }
    //     this.organization = d.organization.organizationId
    //     console.log(this.organization); 
    //     // this.loader=true;
    //     // this.leaveservice.getAdjustment(this.organization).subscribe(
    //     //   (d: any) => {
    //     //     this.loader=false;
    //     //     if(d.length==0){
    //     //       this.noData =true;
    //     //       console.log("NoData");
    //     //     }
    //     //     this.data = d
    //     //     this.dataS = new MatTableDataSource<any>(d);
    //     //     this.dataS.filterPredicate = (data, filter) => {
    //     //       console.log( data.employee.employeeNumber,"11111111111111112222222222222222211")
    //     //       const dataStr =  data.employee.firstName+data.employee.LastName +data.employee.employeeNumber+ data.employee.organizationEmail
    //     //       +data.leaveBalance.leaveType.leaveName + data.leaveadjustmentdays + data.leaveadjustmenttype
    //     //       return dataStr.trim().toLowerCase().indexOf(filter) != -1;
    //     //     }
    //     //     this.dataS.paginator = this.paginator;
    //     //     this.list_count = this.data.length;
    //     //   },
    //     //   (error) =>
    //     // {
    //     //   this.loader=false;
    //     //    this.noData= true;
    //     //   console.log("error");
    //     //   if(error.statusCode==204)
    //     //   {
    //     //      this.noData=true;
    //     //      console.log("204");
    //     //   }
    //     // }
    //     //   )
    //   },
    //   (error) =>
    //     {
    //       this.loader=false;
    //        this.noData= true;
    //       console.log("error");
    //       if(error.statusCode==404)
    //       {
    //          this.noData=true;
    //          console.log("404");
    //       }
    //     }
    // )

  }
  
  keyFunc(event: Event, event1: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
    let inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace

    if (event1.key === ' ') {
        // Handle the space key press
        console.log('Space key pressed');
        // Optionally, prevent the default behavior
        event1.preventDefault();
    } else {
        // Clear the employee list if the input is empty
        if (inputValue === '') {
            this.employeeList = [];
            this.employeeNotFound = false;
            this.all_null=false;
            

            console.log('Input is empty, employee list cleared');
            return;
        }

        // Cancel the previous API request if there's any
        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
        this.unsubscribe$ = new Subject<void>();

        this.leaveservice.getEmployeeListKeywords(this.organizationId, inputValue).pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
                return EMPTY; // Return EMPTY to propagate completion downstream
            })
        ).subscribe(
            (resultData: any) => {
                if (resultData.statusCode === 204) {
                    console.log("No employees found (status code 204)");
                    this.employeeList = [];
                    this.employeeNotFound = true;
                } else {
                    if (Array.isArray(resultData)) {
                        this.employeeList = resultData;
                    } else if (typeof resultData === 'object') {
                        this.employeeList = Object.values(resultData);
                    } else {
                        console.error('Unexpected data type:', resultData);
                    }
                    this.employeeNotFound = false;
                }
            },
            (err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
            }
        );
    }
}




  // status color
  statuscolor(i:any) {
    if (i == null) {
      return
    }
    if (i == 'add') {
      return '#28a745'
    }
    if (i == 'detect') {
      return '#f44336'
    }
  }

  empSelect(emp){
    console.log(emp);
    this.employeeId=emp.employeeId;
    console.log(this.employeeId);
    this.employee=emp.employeeNumber+"-"+emp.firstName;
    this.employeeNumber = emp.employeeNumber;
      this.leaveReport.patchValue({ 
        employeeId: this.employee 
      });
  }

  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p = 1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search(){
    this.storeleavetype=this.leaveReport.controls['leaveType'].value
    this.storeemployee=this.leaveReport.controls['employeeId'].value
    console.log("employee",this.storeemployee);
    console.log("leavetype",this.storeleavetype)
    this.submitted = true;

    if((this.storeemployee ==undefined || this.storeemployee =='') &&(this.storeleavetype ==undefined || this.storeleavetype =='') ){
        alert("Please Choose Any Field To Search")
        return;
    }

    console.log(this.leaveReport.value);
    if(this.leaveReport.invalid){
      return;
    }
    this.leaveType= this.leaveReport.value.leaveType;
    // this.employeeId=this.leaveReport.value.employee;
    console.log(this.leaveType);
    console.log(this.employeeId);

    // if(this.leaveType == 0 || this.leaveType == '' || this.leaveType == null){
    //   if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
    //     this.all_null=true;
    //     setTimeout(() => {
    //       this.all_null = false;
    //     }, 2000)
    //     return
    //   }
    // }

   if (!this.employee && (this.leaveReport.value.employeeId !== undefined && this.leaveReport.value.employeeId !== null)) {
      if (this.leaveReport.value.employeeId.length !== 0) {
          this.all_null = true;
          return;
      } else {
          this.all_null = false;
      }
  }
  
  // if (!this.employee) {
  //   this.leaveReport.patchValue({ 
  //     employeeId: undefined
  //   });
  // }
  this.changefirstpage();

    this.loader=true;
    this.isProcessing = true; // Enable buttons
    this.data=[];

    if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
      console.log("show leave type");
      this.leaveservice.leaveadjustmentByLeavetype(this.leaveType).subscribe(
        (d:any) => {
             console.log(d);
          this.loader=false;
          this.showTable=true;

          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail
            + data.employee.employeeNumber + data.employee.mobileNumber
            +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.list_count = this.data.length;
          this.isProcessing=false;

        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204){
            this.noData=true;
            console.log("204");
          }
          this.isProcessing=false;

        }
      )
    }
    else if(this.leaveType == 0 || this.leaveType == '' || this.leaveType == null){
      if ((this.employeeId != null || this.employeeId != undefined) ) {
        this.leaveReport.patchValue({
          employeeId: this.employee,
        });
        this.employeeNotFound = false;
        this.all_null=false;


      
      // this.leaveReport.patchValue({ 
      //   employeeId: this.employee 
      // });
      console.log("show Emp type",this.employeeId);
      this.leaveservice.leaveadjustmentByEmp(this.employeeId).subscribe(
        (d:any) => {
             console.log(d);
          this.loader=false;
          this.showTable=true;

          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail
            + data.employee.employeeNumber + data.employee.mobileNumber
            +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.list_count = this.data.length;
          this.isProcessing=false;

        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
          this.isProcessing=false;

        }
      )
    }
    }
    else{
      if ((this.employeeId != null || this.employeeId != undefined) ) {
        this.leaveReport.patchValue({
          employeeId: this.employee,
        });
        this.employeeNotFound = false;
        this.all_null=false;


      
      console.log("show all");
      console.log("values for",this.leaveType,this.employeeId);

      this.leaveservice.leaveadjustmentByEmpAndLeavetype(this.leaveType,this.employeeId).subscribe(
        (d:any) => {
             console.log(d);
          this.loader=false;
          this.showTable=true;

          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail
            + data.employee.employeeNumber + data.employee.mobileNumber
            +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.list_count = this.data.length;
          this.isProcessing=false;

        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
          this.isProcessing=false;

        }
      )
    }
  }
  }

 
  
  // Helper method to handle API response
  // private handleSearchResults(data: any) {
  //   this.loader = false;
  //   this.showTable = true;
  
  //   if (!data || data.length === 0 || data.statusCode === 204) {
  //     this.noData = true;
  //     console.log("No data found.");
  //   } else {
  //     this.data = data;
  //     this.dataS = new MatTableDataSource<any>(data);
  //     this.dataS.paginator = this.paginator;
  //     this.dataS.filterPredicate = (data, filter) => {
  //       const dataStr = `${data.employee.employeeName} ${data.employee.organizationEmail}
  //         ${data.employee.employeeNumber} ${data.employee.mobileNumber}
  //         ${data.startDate} ${data.endDate} ${data.noOfLeaveDays} ${data.leaveStatusName}`;
  //       return dataStr.trim().toLowerCase().includes(filter.toLowerCase());
  //     };
  //     this.list_count = this.data.length;
  //   }
  
  //   this.isProcessing = false; // Disable processing indicator
  // }
  
  // // Helper method to handle API errors
  // private handleSearchError() {
  //   this.loader = false;
  //   this.noData = true;
  //   console.log("Error occurred during API call.");
  //   this.isProcessing = false; // Disable processing indicator
  // }
  
  


 
  


  Clear(){
    this.isProcessing=true;
    this.showTable=false;

    this.employeeList = [];
    this.employeeNotFound = false;
        this.noData =false;
    this.enddate_required = false;
    this.show_EMsg = false;
    this.startdate_required = false;
    this.all_null=false;
    this.leaveReport.reset();
    this.submitted = false;
    this.employeeId = null;
    this.employeeNumber = null;
    this.min = null;
    this.max = null;
    // this.loader=true;
    this.list_count = 0;
    this.isProcessing = false;
    this.employee= undefined;

    this.leaveReport.patchValue({ 
      employeeId: undefined
    });
      // this.leaveservice.getAdjustment(this.organization).subscribe(
      // (d: any) => {
      //   this.loader=false;
      //   if(d.length==0)
      //   {
      //     this.noData =true;
      //     console.log("NoData");
      //   }

      //   this.data = d
      //   this.dataS = new MatTableDataSource<any>(d);
      //   console.log(this.dataS);
      //   this.dataS.paginator = this.paginator;
      //   this.dataS.filterPredicate = (data, filter) => {
      //     const dataStr = data.employee.employeeName + data.employee.organizationEmail
      //     + data.employee.employeeNumber + data.employee.mobileNumber
      //     +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
      //     return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      //   }
      //   this.list_count = this.data.length;
      //   this.isProcessing=false;

      // },
      // (error) =>
      // {
      //   this.loader=false;
      //   this.noData= true;
      //   console.log("error");
      //   if(error.statusCode==204)
      //   {
      //      this.noData=true;
      //      console.log("204");
      //   }
      //   this.isProcessing=false;

      // }
      // )
  }


  // applyFilter(event: Event) {
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataS.filter = filterValue.trim().toLowerCase();
      this.list_count = this.dataS.filteredData.length;
      if(this.list_count ==0){
        this.noData = true;
      }
      else{
        this.noData = false;
      }
      if (this.dataS.paginator) {
        this.dataS.paginator.firstPage();
      }

    // const filterValue = (event.target as HTMLInputElement).value;
    // console.log(filterValue);
    // console.log("Search");
    
    // this.dataS.filter = filterValue.trim().toLowerCase();
    // if(!isNaN(Date.parse(filterValue)))
    // {
    //    console.log("date");
    //    const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
    //    console.log(searchDate);
    //    this.dataS.filter = searchDate .trim().toLowerCase();
    //    this.list_count = this.dataS.filteredData.length;
    //    if(this.list_count ==0){
    //      this.noData = true;
    //    }
    //    else{
    //      this.noData = false;
    //    }
    //    if (this.dataS.paginator) 
    //    {
    //       this.dataS.paginator.firstPage();
    //    }
    // }
    // else
    // {
    //   console.log("Number");
    //   this.dataS.filter = filterValue .trim().toLowerCase();
    //   this.list_count = this.dataS.filteredData.length;
    //   if(this.list_count ==0){
    //     this.noData = true;
    //   }
    //   else{
    //     this.noData = false;
    //   }
    //   // this.dataSource.filter = filterValue .trim().toLowerCase();
    //  if (this.dataS.paginator) {
    //   this.dataS.paginator.firstPage();
    // }
    // }
    // if (this.dataS.paginator) {
    //   this.dataS.paginator.firstPage();
    // }
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }

 validateInputs(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }
  
  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.employeeList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.employeeList = []; // Clear the employee list
    this.employeeNotFound = false; 
  }
  preventSpaceBeforeInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
        event.preventDefault();
    }
  }
  handleBackspace(event): void {
    if (
      event.key === 'Backspace' &&
      !this.selectedemployee &&
      (this.selectedemployeeId === undefined || this.selectedemployeeId.trim().length === 0)
    ) {
      this.selectedemployeeId = undefined;
      this.employeeId = undefined;
      this.employeeList.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.selectedemployeeId = undefined;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
  changefirstpage() {
    this.p = 1;
  }
}

