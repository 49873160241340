<!-- <div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Dashboard</span>
            </div>
            <div class="sub-header-buttons"> -->
                <!-- <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                    Add Employee
                </a> -->
            <!-- </div>
        </div>
    </div>
</div> -->
<body class="admin-dashboard " >
    <!-- <div class="dashboard-title">
        <h2>DASHBOARD</h2>
    </div> -->

    <div class="card mx-3" *ngIf="setShowData">

        <div class="col-md-12"  >
            <div class="row">
                <h1 style="margin-top: 20px; margin-left: 10px;">Summary</h1>

            </div>

        </div>
        <div class="col-md-12" style="margin-top: -110px;" *ngIf="setShowData">  
            <div class="dashboard-cards">
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="card status-card">                        
                            <div class="card-body status-card-body">
                                <div class="row" (click)="openpos(recruiterdashboard.openPosition)">
                                    <div class="col">
                                        <h2 class="card-category mr-2 pb-0">Open Positions</h2>
                                        <h2 class="card-title pb-0">{{recruiterdashboard.openPosition}}</h2>
                                    </div>
                                    <div class="col-auto"> 
                                        <div class="icon-card" style="background-color: #446C8C">
                                            <img src="assets/uploads/Open Position.png" width="20px" height="20px ">

                                            <!-- <mat-icon  aria-hidden="false" aria-label="Example volunteer_activism" class="material-icons-round admin-dashboard-icons">launch</mat-icon> -->
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="card status-card">                       
                            <div class="card-body status-card-body">
                                <div class="row" (click)="sharedpro(recruiterdashboard.profilesource)">
                                    <div class="col">
                                        <h2 class="card-category pb-0">Profile Shared</h2>
                                        <h2 class="card-title pb-0">{{recruiterdashboard.profilesource}}</h2>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon-card" style="background-color: #73C854;">
                                            <img src="assets/uploads/Profile Shared.png" width="20px" height="20px ">

                                            <!-- <mat-icon aria-hidden="false" aria-label="Example group" class="material-icons-round admin-dashboard-icons">source</mat-icon> -->
                                        </div> 
                                    </div>
                                </div> 
                            </div>                                  
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="card status-card">                        
                            <div class="card-body status-card-body">
                                <div class="row" (click)="interview(recruiterdashboard.interviewed)">
                                    <div class="col">
                                        <h2 class="card-category pb-0">Interviewed</h2>  
                                        <h2 class="card-title pb-0">{{recruiterdashboard.interviewed}}</h2> 
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon-card" style="background-color: #9747FF">
                                            <img src="assets/uploads/Interview.png" width="20px" height="20px ">
                                        </div>   
                                    </div>  
                                </div>
                            </div>                                 
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="card status-card">                        
                            <div class="card-body status-card-body">
                                <div class="row" (click)="notinterview(recruiterdashboard.notInterviewed)">
                                    <div class="col">
                                        <h2 class="card-category pb-0">Not Interviewed</h2> 
                                        <h2 class="card-title pb-0">{{recruiterdashboard.notInterviewed}}</h2>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon-card" style="background-color: #FF5D5D">
                                            <img src="assets/uploads/Non Interview.png" width="20px" height="20px ">

                                            <!-- <mat-icon  aria-hidden="false" aria-label="Example volunteer_activism" class="material-icons-round admin-dashboard-icons">highlight_off</mat-icon> -->
                                        </div> 
                                    </div>
                                </div>   
                            </div>                     
                        </div>                                   
                    </div>
                    <!-- <div class="col-xl-3 col-lg-6">
                        <div class="card status-card">                        
                            <div class="card-body status-card-body">
                                <div class="row">
                                    <div class="col">
                                        <p class="card-category mr-2 pb-0">On Hold</p>
                                        <h2 class="card-title pb-0">34</h2>
                                    </div>
                                    <div class="col-auto"> 
                                        <div class="icon-card bg-warning">
                                            <mat-icon aria-hidden="false" aria-label="Example volunteer_activism" class="material-icons-round admin-dashboard-icons">do_disturb</mat-icon>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div> -->
                </div> 
            </div> 
        </div>  
    </div>
         
   
</body> 
<div *ngIf="loader"  class="align-items-center text-center" >
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

            <!-------------- pie chart --------------------------------->  
<div class="piechart-bg" *ngIf="setShowData">
    <div class="row m-0 p-0" >
        <div class="col-xl-8">
            <div class="card piechart-card">
                <div class="card-title piechart-card-body">
                    <div class="header">
                        <h2 class=" table-title">Resources Status</h2>
                    </div>
                    <div class="chart">
                        <canvas *ngIf="this.ProfileShared !=0" baseChart
                            style="color: white;width: 70%!important;height: 70%!important;" [data]="pieChartData_user"
                            [type]="pieChartType_user" [options]="pieChartOptions_user"
                            [plugins]="pieChartPlugins_user">
                        </canvas>
                        <!-- <p *ngIf="this.ProfileShared ==0">No Records Found</p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4"  *ngIf="setShowData">
            <div class="card table-card">
                <div class="card-title table-title border-0 m-0">
                    <h2 style="    font-family: 'Mulish', sans-serif;
                    font-weight: 770;
                    font-size: 24px;">Interview Status</h2>
                </div>
                <div class="table-responsive">
                    <table class="interviewed-status-table">
                        <thead style="background-color: #f6f9fc;">
                            <tr>
                                <th class="table-head1">Status</th>
                                <th class="text-center table-head2">Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="resources(selectedStatus, recruiterdashboard.selected ,'Selected')">
                                <td class="table-data1 text-success">Selected</td>
                                <td class="text-center table-data2 text-success">{{recruiterdashboard.selected}}</td>
                            </tr>
                            <tr (click)="resources(rejectedStatus, recruiterdashboard.rejected,'Rejected')">
                                <td class="table-data1 text-danger">Rejected</td>
                                <td class="text-center table-data2 text-danger">{{recruiterdashboard.rejected}}</td>
                            </tr>
                            <tr (click)="resources(onHoldStatus, recruiterdashboard.onHold,'On-Hold')" >
                                <td class="table-data1" style="color:#ff9100">On Hold</td>
                                <td class="text-center table-data2" style="color:#ff9100">{{recruiterdashboard.onHold}}</td>
                            </tr>
                        </tbody>
                    </table>                                 
                </div>
            </div>
        </div>
    </div>
</div>    


<!-- 
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
    style="height: 527vh!important;"></div> -->

