
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { AppComponent } from 'src/app/app.component';
import { SettingsService } from 'src/app/providers/settings.service';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
// import * as pdfMake from "pdfmake/build/pdfmake";
import { DatePipe } from '@angular/common';
import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Stream } from 'stream';
import { ExportExcelService} from 'src/app/services/export-excel.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { EmployeeComponent } from 'src/app/employeeComponents/employee/employee.component';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-client-if-reports',
  templateUrl: './client-if-reports.component.html',
  styleUrls: ['./client-if-reports.component.scss']
})
export class ClientIfReportsComponent implements OnInit {



    filterForm:FormGroup;
    today: any =  new Date();
    submitted: boolean = false;
    success = false;
    error:any=false;
    sucess_msg:any;
    error_msg:any;
    usertData:any;
    orgId:number;
    clientList:any;
    empList:any=[];
    empNum:number;
    nodatafound: boolean = true;
    update_error_msg:any;
    loader:any= false;
    backdrop:any= false;
    resultData : any = [];
    noRecordsFound: boolean =true;
    filteredEmp: Observable<string[]>;
    control: any;
    userId: number;
    employee: any;
    employeeId: number;
    stardate: any;
    enddate: any;
    filterstardate:any;
    filterenddate:any;
    clientId:any;
    pagePerItem:any=10;
    p: number = 1;
    tableSearch : any;
    public searchFilter: any = '';
    clientupdateId: any;
    errorMsg : any;
    employeeStatus: any;
    errormessagejson: any;
    importerror: boolean = false;
    dataExport = [];
    exported:any;
    exportingdata:any;
    beforepage :any;
    dateFormat: any;
    finYearList: any =[];
    finPeriodList: any=[];
    chooseFinId: number;
    ratingId: any;
    req: boolean=false;
    finEmpList: any;
    eleNameList: any;
    categoryId: number;
    categoryTypeData: any;
    categoryName: any;
    employeePFval: number=0;
    totalPF: number=0;
    employeerPF: number=0;
    @ViewChild('pdfTable')
    pdfTable!: ElementRef;
    excelData: any;
   
   
    orgdata: any;
    fileblob: string;
    period: string ="";
    paySlipElement: any;
    Amount =0;
    employeeName: string;
    fromPeriod: any;
    toperiod: any;
    fPeriod: any;
    tPeriod: any;
    paySlipElementlength: number ;
    selectedEmployeeData: any;
    firstname: any;
    lastname: any;
    empnumber: any;
    empCode: boolean;
    totalDeduction: number;
    totalNetpay: number;
  categoryMale: any  = [];
  categoryFemale: any= [];
  totalmaleemployees: number = 0;
  totalmaledutydatys: number = 0;
  totalmaleothours:number = 0;
  totalmalegrosswages: number = 0;
  totalmaleesiWages:number = 0;
  totalmaleemployeepf:number = 0;
  totalmaleemployeeesi: number = 0;
  totalfemaleemployees: number = 0;
  totalfemaleothours: number = 0;
  totalfemaledutydatys: number = 0;
  totalfemalegrosswages: number = 0;
  totalfemaleesiWages: number = 0;
  totalfemaleemployeepf: number = 0;
  totalfemaleemployeeesi: number = 0;
  clientName: any = "";
  clientCode: any= "";

    
    constructor( private fb:FormBuilder,
      private settingsService: SettingsService,
      private payrollservice:PayrollService,
      private datepipe:DatePipe,
      private dialog:MatDialog,
      private router:Router,
      public ExportExcelService: ExportExcelService,
      @Inject(AppComponent) public AppData: any ,
      private superAminservice : SuperadminService ,  
      private EmployeeService : EmployeeService,
      private gs :GlobalserviceService) { }

    ngOnInit(): void {
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      this.dateFormat = localStorage.getItem('dateFormatName');
      let userId = this.usertData.userId;
      this.orgId = this.usertData.organization.organizationId;
      console.log(this.orgId);
     
      this.filterForm = this.fb.group({
        employeecode:[null,[Validators.required]],
        financialyear:[null,],
        fromperiod:[null,],
        toperiod:[null,],
        client:[null,],
      })
      this.settingsService.getFinicalyearActiveList(this.orgId).subscribe(
        (resultData: any) => {
        console.log(resultData);
        this.finYearList = resultData;
      });
  
      this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
        this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
        console.log(this.clientList);
      })  
      
  
      
      this.gs.getpaySlipElementByOrganaization(this.orgId).subscribe(
        (data:any)=>{
          this.paySlipElement = data;
          this.paySlipElementlength =  this.paySlipElement.length + 9;
          console.log("table headers count",this.paySlipElementlength)
          console.log("paySlipElement",this.paySlipElement);
          const customOrder = ['Basic DA','HRA'];
          this.paySlipElement.sort((a, b) => {
            const aIndex = customOrder.indexOf(a.elementName);
            const bIndex = customOrder.indexOf(b.elementName);
            if (aIndex === -1 && bIndex === -1) {
              return 0; // Both elements are not in customOrder, maintain their relative order
            } else if (aIndex === -1) {
              return 1; // Only 'a' is not in customOrder, move it to the end
            } else if (bIndex === -1) {
              return -1; // Only 'b' is not in customOrder, move it to the end
            }
            return aIndex - bIndex; // Compare their positions in customOrder
          });
  
  
          console.log(this.paySlipElement);
          this.paySlipElement.map(  (e) => {
                e['amount'] =0;
          })
        }
      )
    }
  
    clearTable()
    {
      this.resultData.length = 0;
    }
  
    searchSubmit(){
      console.log("Filter",this.filterForm.value);
  
  
        if(this.filterForm.value.client == null && this.filterForm.value.financialyear===null && this.filterForm.value.fromperiod===null && this.filterForm.value.toperiod===null){
  
             alert("Please select requried fields")
           return;
         }
  
         if( (this.filterForm.value.fromperiod !== null && this.filterForm.value.toperiod===null )|| (this.filterForm.value.fromperiod === null && this.filterForm.value.toperiod !==null ) ){
          alert("Please select from and to period")
        return;
      }
  
  
       this.loader = true;
      if(  this.filterForm.value.client != null  && this.filterForm.value.financialyear!== null && this.filterForm.value.fromperiod !=null && this.filterForm.value.toperiod !=null)
        {
          this.payrollservice.getifreportByclient( this.filterForm.value.client ,this.filterForm.value.fromperiod , this.filterForm.value.toperiod).subscribe(
            (data:any)=>{
              console.log(data);
              this.resultData = data;
             // this.roundofdata();
             this.loader = false;
              if(this.resultData.length !=undefined  )
              {
                console.log("result data")
                this.calculate();
                this.noRecordsFound = false;
                this.fromPeriod=this.filterForm.value.fromperiod;
                this.fromPeriodFunction(this.fromPeriod);
                this.toperiod=this.filterForm.value.toperiod;
                this.toPeriodFunction(this.toperiod);
                this.period = this.fPeriod.financialPeriodYear
             
                this.employeeName =  "Client : " +" "+ this.resultData[0].client.clientCode
              }
              else{
                this.noRecordsFound = true;
              }
            }
          )
        }
    }
    fromPeriodFunction(fromPer: any) {
      console.log(fromPer);
      this.fPeriod=[];
      this.finPeriodList.forEach(element => {
        console.log(element);
        if(element.financialPeriodId==fromPer)
        {
         this.fPeriod=element;
         console.log(this.fPeriod);
        }
     });
    }
    toPeriodFunction(toPer: any) {
      console.log(toPer);
      this.tPeriod=[];
      this.finPeriodList.forEach(element => {
        console.log(element);
        if(element.financialPeriodId==toPer)
        {
          this.tPeriod=element;
          console.log(this.tPeriod);
        }
     });
    }
    calculate() {
  

      this.categoryMale =  this.resultData.filter(ele=> ele.gender === "Male");

      this.categoryMale.forEach(catmalelement => {
           this.totalmaleemployees =  this.totalmaleemployees += catmalelement.totalEmployees;
           this.totalmaledutydatys =  this.totalmaledutydatys += catmalelement.employeeWorkingDays;
           this.totalmaleothours =  this.totalmaleothours += catmalelement.otHours;
           this.totalmalegrosswages =  this.totalmalegrosswages += catmalelement.grossWages;
           this.totalmaleesiWages =  this.totalmaleesiWages += catmalelement.esiWages;
           this.totalmaleemployeepf =  this.totalmaleemployeepf += catmalelement.employeePf;
           this.totalmaleemployeeesi =  this.totalmaleemployeeesi += catmalelement.employeeEsi;
      });



      this.categoryFemale =  this.resultData.filter(ele=> ele.gender === "Female");
      this.categoryFemale.forEach(catfemalelement => {
        this.totalfemaleemployees =  this.totalfemaleemployees += catfemalelement.totalEmployees;
        this.totalfemaledutydatys =  this.totalfemaledutydatys += catfemalelement.employeeWorkingDays;
        this.totalfemaleothours =  this.totalfemaleothours += catfemalelement.otHours;
        this.totalfemalegrosswages =  this.totalfemalegrosswages += catfemalelement.grossWages;
        this.totalfemaleesiWages =  this.totalfemaleesiWages += catfemalelement.esiWages;
        this.totalfemaleemployeepf =  this.totalfemaleemployeepf += catfemalelement.employeePf;
        this.totalfemaleemployeeesi =  this.totalfemaleemployeeesi += catfemalelement.employeeEsi;
   
   });
    }
  
    getclientdetails(event :any)
    {
      console.log(event.target.value)
      this.clientList.map(
        client => {
        if(client.clientId == event.target.value)
        {
           this.clientName = client.clientName;
           this.clientCode = client.clientCode;
        }
        });
    }


    printPdf(){
      console.log("PrintPDF");
      const pdfTable = this.pdfTable.nativeElement;
      var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
      const documentDefinition = { 
        content: html ,
        // image: this.fileblob, // Use the dataURL here
        // width: 200,
        // height: 100,
        pageMargins: [ 40, 40, 40, 40 ],
        info: {
          title:`IF Report`
          },
          pageSize: 'A4',
          pageOrientation: 'landscape',
        };
        pdfMake.createPdf(documentDefinition).open(); 
    }
  
   
    
    pageChages(){
      console.log("page changes");
      this.p=1;
    }
    selectFinancialyear(event){
      console.log("Select");
      console.log(event.target.value);
      // console.log(this.FilterForm.value.financialyear);
       this.chooseFinId=event.target.value;
      console.log(this.chooseFinId);
      
      if(event.target.value == 'null')
      {
        this.filterForm.patchValue({
        
          financialyear:null,
        
        })
      }else{
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
      });
    }
    }
    selectFinancialfromPeriod(event)
    {
      if(event.target.value == 'null')
      {
        this.filterForm.patchValue({
        
          fromperiod:null,
        
        })
    }
  }
  selectFinancialtoPeriod(event)
  {
    if(event.target.value == 'null')
    {
      this.filterForm.patchValue({
      
        toperiod:null,
      
      })
  }
  }
  
 
   
  

}
