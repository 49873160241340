import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-task-creation',
  templateUrl: './task-creation.component.html',
  styleUrls: ['./task-creation.component.scss'],
})
export class TaskCreationComponent implements OnInit {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('mySelct') private mySelect;
  @ViewChild('picker') startDatePicker!: MatDatepicker<any>;
  @ViewChild('p') endDatePicker!: MatDatepicker<any>;
  success = false;
  error: any = false;
  sucess_msg: any;
  errormessagejson: any;
  error_msg: any;
  taskForm: FormGroup;
  employeeList: any[];
  minStart: any;
  endDate_disable: boolean=true;
  description: any;
  min: any;
  max: any;
  taskType: any;
  arrayData: any[] = [];
  commonTaskId;
  projectName: any;
  projecttaskobject: any = {};
  projecttaskexist: boolean;
  userData: any;
  submitted1 = false;
  taskgrouplist: any;
  formattedStartDate:string
  formattedEndDate:string
loader: boolean=false;
disableSaveButton:boolean = true;
  constructor(
    public dialogRef: MatDialogRef<TaskCreationComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private timesheet: TimesheetService,
    private datepipe: DatePipe,
    private gs: ProjectManagementService
  ) {}

  ngOnInit() {
    console.log("this.data",this.data);
// console.log("this.data.projectStartDate::",this.data.projectStartDate);
// console.log("this.data.projectEndDate::",this.data.projectEndDate);

    // this.startDate_disable = false;
    // this.disableTaskId = false;
    // let control = this.taskForm.get('tableRows') as FormArray;
    // control.controls.forEach((element) => {
    //   element.patchValue({
    //     taskStartDate: '',
    //     taskEndDate: '',
    //   });}
    // )

    this.userData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.gs.getCommonTaskName().subscribe((data: any) => {
      this.taskType = data;
    });
    {
      this.CreateTaskFrom();
    }
    // this.gs.getResource().subscribe(
    //   (data) => {
    //     this.employeeList = data
    //   }
    // )

    this.gs.getprojectByID(this.data).subscribe((x: any) => {
      console.log(x);
      console.log("start date::",x.projectStartDate);
      console.log("end date::",x.projectEndDate);

      this.min = x.projectStartDate.substring(0, 10);
      this.max = x.projectEndDate.substring(0,10);
      console.log('this.min::',this.min);
      console.log("this.max",this.max);
      
      
      // this.startDate_disable = false;
      // this.disableTaskId = false;
      let control = this.taskForm.get('tableRows') as FormArray;
      control?.controls?.forEach((element) => {
        element.patchValue({
          taskStartDate: '',
          taskEndDate: '',
        });
      });
      
      
      // if (x.projectActualStartDate != 0) {
      //   this.min = x.projectActualStartDate;
      // } else {
      //   this.min = x.projectStartDate;
      // }

      // console.log(' x.projectActualEndDate' + x.projectActualEndDate.length);
      // if (x.projectActualEndDate.length != 0) {
      //   //  alert("yes actual enddate")
      //   this.max = x.projectActualEndDate;
      // } else {
      //   //alert("no actual enddate")
      //   this.max = x.projectEndDate;
      // }

      this.projectName = x.projectName;
      this.employeeList = x.employee;
      console.log("   this.projectName ",   this.projectName );
      
    });

    this.gs.gettaskgroup().subscribe((data: any) => {
      this.taskgrouplist = data;
      console.log(this.taskgrouplist);
    });
  }

  get f() {
    return this.taskForm.controls;
  }

  private CreateTaskFrom() {
    this.taskForm = this.fb.group({
      organization: {
        organizationId: [],
      },
      project: {
        projectId: [],
      },
      taskGroup: ['', Validators.required],
      // taskName: ['', Validators.required],
      taskName : new FormControl(this.taskForm,[Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"),
    ]),

      // taskDescription: ['', Validators.required],
      taskDescription : new FormControl(this.taskForm,[Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"),
]),
      // hours: ['', Validators.required],
      // minutes: ['', Validators.required],
      taskStartDate: ['', Validators.required],
      taskEndDate: ['', Validators.required],
    });
  }

  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (
      this.taskForm.controls.employee.value.length == this.employeeList.length
    )
      this.allSelected.select();
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.taskForm.controls.employee.patchValue([
        ...this.employeeList.map((item) => item.employeeId),
        0,
      ]);
    } else {
      this.taskForm.controls.employee.patchValue([]);
    }
  }

  add() {
    let x = this.taskForm.value;
    if (x.employee == '') {
      alert('Please select the options');
    } else {
      this.mySelect.close();
    }
  }

  cancel() {
    this.taskForm.patchValue({ employee: '' });
    this.mySelect.close();
  }

  // keys

  omit_special_char(event) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return (
      (n > 'A' && n < 'z') ||
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57) ||
      k === 1
    );
  }
  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // date validation
  someMethodName(date:any) {
    // this.submitted = true;
    // if (this.taskForm.invalid) {
    //   return; 
    // }
    // this.min = new Date(date.value)
    // this.endDate_disable = false
    // const startDate = this.taskForm.get('taskStartDate').value;
    // const endDate = this.taskForm.get('taskEndDate').value;
    
    
  
    
    // const startdatestore = this.taskForm.controls['taskStartDate'].value;
    // const enddatestore = this.taskForm.controls['taskEndDate'].value;
    // if (startdatestore && enddatestore) {
     
    
    //   // Ensure dates are valid before creating Date objects
    //   let startDate: Date | string = startdatestore ? new Date(startdatestore) : new Date();
    //   let endDate: Date | string = enddatestore ? new Date(enddatestore) : new Date();
    
    //   // Transform the dates
    //   startDate = this.datepipe.transform(startdatestore, 'yyyy-MM-dd');
    //   endDate = this.datepipe.transform(enddatestore, 'yyyy-MM-dd');
    
    //   console.log('2dates', startDate, endDate);
    
    //   // Convert transformed dates back to Date objects for comparison
    //   const startDateObj = new Date(startDate);
    //   const endDateObj = new Date(endDate);
    
    //   if (startDateObj > endDateObj) {
    //     alert('Start date cannot be greater than end date.');
    //     this.taskForm.patchValue({
    //       taskStartDate: null,
    //       taskEndDate: null,
    //     });
    //     if (this.taskForm.controls.taskStartDate.errors?.required && !this.taskForm.controls.taskStartDate.touched) {
    //       this.startDatePicker.close()
    //    this.endDatePicker.close()
    //       return; // If not touched and empty, do not show error
    //     }
    
    //     if (this.taskForm.controls.taskEndDate.errors?.required && !this.taskForm.controls.taskEndDate.touched) {
    //       this.startDatePicker.close()
    //       this.endDatePicker.close()
    //       return; // If not touched and empty, do not show error
    //     }
    
    //     if (this.taskForm.invalid) {
    //       this.startDatePicker.close()
    //    this.endDatePicker.close()
    //       return; // If form is invalid, don't proceed further
    //     }
    //     console.log("controls::",this.taskForm);

    //     // this.submitted=false
  
    //    this.startDatePicker.close()
    //    this.endDatePicker.close()
    //   }
    //   } else {
    //     // Additional logic if needed
    //   }
    }
    updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
      const endDate = event.value;
    
      // Iterate through the FormArray
      const tableRowsArray = this.taskForm.get('tableRows') as FormArray;
      let invalidEndDateFound = false; // Flag to track if any invalid end date is found
    
      tableRowsArray.controls.forEach((row) => {
        const startDate = row.get('startdate').value;
        const rowEndDate = row.get('enddate').value;
    
        // Check if start date is greater than end date for each row
        if (startDate && rowEndDate && startDate > rowEndDate) {
          // Set error state for the row
          row.get('startdate').setErrors({ 'endDateInvalid': true });
          invalidEndDateFound = true; // Set flag if an invalid end date is found
        } else {
          // Reset error if valid
          row.get('startdate').setErrors(null);
        }
      });
    
      // If any invalid end date is found, alert the user
      if (invalidEndDateFound) {
        alert('Start date cannot be greater than end date');
        const control = this.taskForm.get('tableRows') as FormArray;
        control.controls.forEach((row) => {
          // row.get('taskname').reset(); // Reset employee control
          // row.get('taskdescription').reset(); // Reset startdate control
          row.get('startdate').reset(); // Reset enddate control
          row.get('enddate').reset(); // Reset hours control
          // row.get('minutes').reset(); // Reset minutes control
        });
        this.startDatePicker.close();
        this.endDatePicker.close();
      }
    
      // Other code...
    }
    checkStartDate(): void {
      const startDate = this.taskForm.get('taskStartDate').value;
      const endDate = this.taskForm.get('taskEndDate').value;
  
      if (startDate && endDate && startDate > endDate) {
        alert('Start date cannot be greater than End date.');
  
        this.taskForm.patchValue({
          taskStartDate: null,
          taskEndDate: null,
        });
  
        this.startDatePicker.close();
        this.endDatePicker.close();
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /* validation ++++++++++++++++ */
  get projects() {
    return this.taskForm.get('projects');
  }
  get employee() {
    return this.taskForm.get('employee');
  }
  // get taskGroup() {
  //   return this.taskForm.get('taskGroup');
  // }
  get taskGroup() {
    return  this.taskForm?.get('taskGroup');
  }
  get task() {
    return this.taskForm.get('task');
  }
  get taskName() {
    return this.taskForm?.get('taskName');
  }
  get taskDescription() {
    return this.taskForm?.get('taskDescription');
  }
  //  get hours()
  //  {
  //    return this.taskForm.get('hours');
  //  }
  //  get minutes()
  //  {
  //    return this.taskForm.get('minutes');
  //  }
  get taskStartDate() {
    return this.taskForm?.get('taskStartDate');
  }
  get taskEndDate() {
    return this.taskForm?.get('taskEndDate');
  }

  change(id, t) {
    this.description = t;
    this.commonTaskId = id;
  }

  addTask() {
    this.dialogRef.close();
    this.router.navigate(['./add-task']);
  }

  Create_task() {
    this.submitted1 = true;
    let x = this.taskForm.value;

    if (this.taskForm.invalid) {
      return;
    }
this.disableSaveButton = false;
    if (this.taskForm.valid && this.projecttaskexist == true) {
      //  console.log("has error")
      return;
    }

    // x['employee'] = [{
    //   employeeId:11
    // }]

    // x.employee.map(
    //   e => {
    //     let d;
    //     if (e) {
    //       d = {
    //         'employeeId': e
    //       }
    //     }
    //     this.arrayData.push(d)
    //     x['employee'] = this.arrayData
    //   }
    // )

    //end date
    let a = x.taskStartDate;
    let i = new Date(a);
    i.setMinutes(i.getMinutes() + 480);
    let j = i.toISOString();
    x['taskStartDate'] = j;

    //end date
    let b = x.taskEndDate;
    let y = new Date(b);
    y.setMinutes(y.getMinutes() + 480);
    let t = y.toISOString();
    x['taskEndDate'] = t;

    x['taskStatus'] = {
      listTypeValueId: 31,
    };
    // x['workingHours'] = `${x.hours}:${x.minutes}:00`
    if (x.hours == 0 && x.minutes == 0) {
      alert('Task Hours Should be greater than 0');
      return;
    }

    // x['workhours'] = x.hours
    // x['workminutes'] = x.minutes
    x['organizationId'] = this.userData.organization.organizationId;

    x['project'] = {
      projectId: Number(this.data),
    };

    x['status'] = {
      listTypeValueId:1
    }
    // x['commonTasks'] = {
    //   commontaskId:this.commonTaskId
    // }
    x['taskGroup'] = {
      taskGroupId: x.taskGroup,
    };

    x['taskName'] = this.capitalizeWordsName(x.taskName);
    x['taskDescription'] = this.capitalizeWordsDescription(x.taskDescription);

    delete x.hours;
    delete x.minutes;
    delete x.organization?.organizationName;
    delete x.organization;
    console.log(JSON.stringify(x));
    // return
    this.gs.createTask(x).subscribe((a: any) => {
      // Swal.fire({
      //   icon: 'success',
      //   text: a.message,
      // });
      // window.location.reload()
 

      // setTimeout(() => {
      //   this.success=false
        // this.dialogRef.close(a);
// }, 3000)

if (a.StatusCode == 200) {
  this.success = true;
  this.sucess_msg = a.message;
this.loader=false
  // this.sucess_msg = 'Timesheet Updated Successfully';

  // this.sucess_msg = 'Timesheet Updated Successfully';
  this.timesheet.setAddTaskSuccessMessage('Task Added Successfully');
  this.disableSaveButton = true;
  // setTimeout(() => {
  //   window.location.reload();
  // this.dialogRef.close(a);

  // }, 400);
  // this.dialogRef.close(a);

  setTimeout(() => {
    this.success=false
  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
const currentUrl = this.router.url;
this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
this.router.navigateByUrl(currentUrl);
});
  }, 4000)
    this.dialogRef.close(a);

}

    
// this.success=true
// this.sucess_msg = a.message;
// this.loader=false
        
// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
// const currentUrl = this.router.url;
// this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
// this.router.navigateByUrl(currentUrl);
// });

    });
  }

  validateprojecttask() {
    console.log('employee : ' + this.taskForm.value.taskName);

    if (this.taskForm.value.taskName.length != 0) {
      this.gs
        .getprojecttaskvalidate(this.taskForm.value.taskName, this.data)
        .subscribe((a) => {
          console.log(a);
          this.projecttaskobject = a;
          if (this.projecttaskobject.isexist) {
            console.log('role name isexist');
            this.projecttaskexist = true;
          } else {
            this.projecttaskexist = false;
          }
        });
    } else {
      this.projecttaskexist = false;
    }
  }



  capitalizeWordsName(text) {
    return text.replace(/(?:^|\s)\S/g, (res) => {
      return res.toUpperCase();
    });
  }

  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
}
