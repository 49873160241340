<!-- Sub header start here-->
<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Departments</span>
                </div>
                <div class="sub-header-buttons"> -->
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <!-- <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                    <a (click)="addForm()" color="primary" mat-raised-button title="Add Department" class="ml-2">
                        Add Department
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<nav class="navbar bg" style="margin-top: 67px;">
    <div class="container-fluid">
        <div class="row w-100">
            <div class="col-sm-4 col-md-3 d-flex align-items-center mb-2 mb-sm-0">
                <h4 class="heading-tag" style="position: relative; left: 5px;">
                    Departments
                </h4>
            </div>

            <div class=" col-sm-8 col-md-9 d-flex justify-content-end align-items-center" style="  position: relative;
            top: -4px;
            left: 28px;">
                <div class="input-group me-3" style="max-width: 400px;">
                    <input type="text" [(ngModel)]="search"
                        autocomplete="off"
                        class="form-control form-control-lg form-control-solid search-input email-input select-placeholder"
                        name="keywords"
                        value=""
                        placeholder="Search...">
                </div>

                <div class="d-flex" style="height: 38px;
                    padding-top: 5px !important;margin-top: -4px;">
                    <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                    <a (click)="addForm()" color="primary" mat-raised-button title="Add Department" class="ml-2"  style="
                    background: #1a83ff !important;color: #fff;">
                        Add Department
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>
<!-- Sub header end here-->



<div class="common-content"  style="top: 0px;"  *ngIf="tableShow">
    <div class="card-new" style="    position: relative;
 
    margin: 5px !important;">
        <div class="card">
            <div class="card-body">

                <!--  <div class="alert alert-success alert-dismissible" *ngIf="success">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Success!</strong> Indicates a successful or positive action.
                </div> -->

                <div class="row mb-2" *ngIf="tableShow">
                    <div class="col-md-12 text-right">
                        <div class="row p-0 m-0"    *ngIf="resultData.length > 5 && (resultData | mytask : search).length > 0" >
                            <div class="col-xl-12 col-md-12  "   *ngIf="(resultData | mytask : search).length > 5">
                              <div class="select-pagination">
                                <span>Show : </span>
                                <select style="    border-radius: 6px;"
                                  class="select_list new-select_list"
                                  [(ngModel)]="mySelect"
                                  (change)="changefirstpage()">
                                  <option class="form-control" value="5">5</option>
                                  <option class="form-control" value="10">10</option>
                                  <option class="form-control" value="15">15</option>
                                  <option class="form-control" value="20">20</option>
                                  <option class="form-control" value="25">25</option>
                                </select>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>

                <table matSort (matSortChange)="sortData($event)" *ngIf="tableShow" class="table table-border-new"
                 style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <thead class="t-head">
                        <tr>
                            <!-- <th class="text-center">S.No</th> -->
                            <th class="text-center">Action</th>
                            <th mat-sort-header="departmentCode">Department Code</th>
                            <th mat-sort-header="departmentName">Department Name</th>
                            <th mat-sort-header="status" class="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody style="border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;">
                        <tr *ngFor="let row of resultData | mytask : search
  | paginate : { itemsPerPage: mySelect, currentPage: p }; let i=index;">
                            <!-- <td class="text-center">{{i+1}}</td> -->
                            <td class="text-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu" class="controls-list">
                                    <button mat-menu-item (click)="updateform(row)">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button>

                                    <button mat-menu-item *ngIf="row.departmentStatus.listTypeValueId == 1" (click)="updateStatus(row.departmentId,2)">
                                        <mat-icon>done</mat-icon>
                                        <span style="color:red;">Inactive</span>
                                    </button>

                                    <button mat-menu-item *ngIf="row.departmentStatus.listTypeValueId == 2" (click)="updateStatus(row.departmentId,1)">
                                        <mat-icon>done</mat-icon>
                                        <span style="color:green;">Active</span>
                                    </button>
                                </mat-menu>
                            </td>

                            <td  class="text-center" >{{row.departmentCode}}</td>
                            <td  class="text-center" >{{ucFirst(row.departmentName)}}</td><!-- capitalizeWordsName -->
                            <td class="active-status text-center">
                                <span class=" btn-outline-success " *ngIf="row.departmentStatus.listTypeValueId == 1">
                                    {{row.departmentStatus.listTypeValueName}}
                                </span>

                                <span class=" btn-outline-danger" *ngIf="row.departmentStatus.listTypeValueId == 2">
                                    {{row.departmentStatus.listTypeValueName}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody style="    border-left: 1px solid #ddd;
                    border-right: 1px solid #ddd;">
                                                    <tr>
                                                        <td   style="
                                                        text-align: center;
                                                        font-size: larger;
                                                        font-weight: bold;
                                                      " colspan="11" 
                                                      *ngIf="nodatafound||((resultData | mytask : search)?.length === 0 && search) ||resultData?.length == 0">Departments Records Not Found!</td>
                                                    </tr>
                                                </tbody>
                                                <div class="row" *ngIf="resultData?.length > 5 && (resultData | mytask : search).length > 0 && tableShow">
                                                    <div class="col-md-12" *ngIf="(resultData | mytask : search).length > 5">
                                                        <div class="showing-record">
                                                            <!-- <ng-template pTemplate="paginatorleft" let-state>
                                                                Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                                                            </ng-template> -->
                                                            {{resultData?.length}} rows
                                                        </div>
                                                    </div>
                                                    </div>
                </table>

               
                    <div
                    class="pagination-content"
                    *ngIf="resultData?.length > 5 && (resultData | mytask : search).length > 0"
                    id="page"
                  >
                    <pagination-controls  *ngIf="(resultData | mytask : search).length > 5"
                      style="position: relative; float: inline-end;top: 7px;"
                      (pageChange)="p = $event"
                      align="end"
                    ></pagination-controls>
                  </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 37% !important;
    right: 48% !important;" role="status"></div>
  </div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
