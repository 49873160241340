<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span routerLink="/home" class="h5 sub-content headerDesign">Company Settings</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="companySettingsForm" (ngSubmit)="submitData()">
                    <div class="row">   
                    <div class="form-group col-md-3" >
                        <label class="form-control-label">Country <span class="star">*</span></label>
                        <mat-select class="form-control email-input select-placeholder" (selectionChange)="listTypeValueid($event)" appearance="fill" formControlName="country" placeholder="- Select Country -">
                            <mat-option *ngFor="let e of countryData" class="text-color" [value]="e.countryId">{{e.countryName}}</mat-option>
                        </mat-select>
                        <div class="valid-msg" *ngIf="(country.invalid && country.touched) || country.dirty || submitted">
                            <span *ngIf="country.errors?.required" class="text-danger">Please select country</span>
                        </div> 
                    </div>  
                    <!-- <p *ngIf="countryInfo"> -->
                    <div class="form-group col-md-3">
                        <label class="form-control-label">Country Code </label>
                        <input type="text" class="form-control email-input select-placeholder" [disabled]="isFieldDisabled" [readonly]="isFieldDisabled" formControlName="countryCode" placeholder="Enter Country Code ">  
                    </div>  

                    <div class="form-group col-md-3">
                        <label class="form-control-label">Currency Symbol </label>
                        <input type="text" class="form-control email-input select-placeholder" [disabled]="isFieldDisabled" [readonly]="isFieldDisabled" formControlName="currencySymbol" placeholder=" Enter Currency Symbol">  
                    </div> 

                    <div class="form-group col-md-3">
                        <label class="form-control-label">Currency Code </label>
                        <input type="text" class="form-control email-input select-placeholder" [disabled]="isFieldDisabled" [readonly]="isFieldDisabled" formControlName="currencyCode" placeholder=" Enter Currency Code  ">  
                    </div> 

                    <div class="form-group col-md-3">
                        <label class="form-control-label">Decimal Digit Value <span class="star">*</span></label>
                        <input type="text" (keypress)="validateNumber($event)"
                   (paste)="blockPaste($event)"
                   (drop)="blockDrop($event)"
                   class="form-control email-input select-placeholder"
                   formControlName="decimalDigitValue"
                   placeholder="Enter Decimal Digit Value">

                        <!-- <div class="valid-msg"
                            *ngIf="(decimalDigitValue.invalid && decimalDigitValue.touched) || decimalDigitValue.dirty">
                            <span *ngIf="decimalDigitValue.errors?.required" class="text-danger">Please Enter
                                Decimal Digit Value</span>
                            <span *ngIf="decimalDigitValue.errors?.pattern" class="text-danger">Please Enter
                                Decimal Digit Value</span>
                        </div> -->
                        <small class="text-muted" style="color: rgba(0, 0, 0, 0.42) !important;">Note : It should be between 0 and 5.</small>
                        <div style=" font-family: 'Mulish', sans-serif" class="valid-msg" *ngIf="companySettingsForm.get('decimalDigitValue').invalid && (companySettingsForm.get('decimalDigitValue').dirty || companySettingsForm.get('decimalDigitValue').touched)">
                            <span *ngIf="companySettingsForm.get('decimalDigitValue').errors?.required" class="validation-error">Please enter Decimal Digit Value</span>
                            <span *ngIf="companySettingsForm.get('decimalDigitValue').errors?.pattern" class="validation-error">Invalid Decimal Digit Value</span>
                        </div>
                    </div> 
                    <div class="form-group col-md-3">
                        <label class="form-control-label">Max Salary Advance Limit<span class="star"> *</span></label>
                        <input type="text" class="form-control email-input select-placeholder"  formControlName="maxSalaryAdvanceLimit" placeholder="Enter Max Salary Advance Limit " appPercentageDirective>  
                        <div class="valid-msg" *ngIf="(maxSalaryAdvanceLimit.invalid && maxSalaryAdvanceLimit.touched) || maxSalaryAdvanceLimit.dirty || submitted">
                            <span *ngIf="maxSalaryAdvanceLimit.errors?.required" class="text-danger">Please enter Max Salary Advance Limit</span>
                            <span *ngIf="maxSalaryAdvanceLimit.errors?.pattern" class="text-danger">Invalid Max Salary Advance Limit</span>
                        </div> 
                    </div> 
                    <div class="form-group col-md-3">
                        <label class="form-control-label">Max Loan Limit<span class="star"> *</span></label>
                        <input type="text" class="form-control email-input select-placeholder"  formControlName="maxLoanLimit" placeholder="Enter Max Loan Limit" appPercentageDirective>  
                        <div class="valid-msg" *ngIf="(maxLoanLimit.invalid && maxLoanLimit.touched) || maxLoanLimit.dirty || submitted">
                            <span *ngIf="maxLoanLimit.errors?.required" class="text-danger ">Please enter Max Loan Limit</span>
                            <span *ngIf="maxLoanLimit.errors?.pattern" class="text-danger">Invalid Max Loan Limit</span>
                        </div> 
                    </div>
                    <!-- </p> -->
                </div>

                <div class="row">
                    <div class="col-md-12 text-right">
                        <!-- <a routerLink="/home/settings" mat-raised-button title="Cancel" class="btn btn-light cancelDesign btn-sm mr-2">
                            Cancel
                        </a>
                        <button type="submit" class="submit action-button btn btn-primary btn-sm" [disabled]="companySettingsForm.invalid">Save</button>[disabled]="resourceform.invalid || !maxExp" -->
                        <button [disabled]="isbtnDisabled" type="button" 
                        routerLink="/home/settings"
                        mat-button mat-dialog-close mat-raised-button class="cancelDesign" 
                        style="
                           background: none;
    color: var(--Red-Color, #FF5D5D);
    border: 1px solid #ddd;
    font-size: 16px;
    font-weight: 550;
    height: 35px;
    position: relative;
    right: 9px;
                      ">Cancel</button>
                        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
                        <button type="submit" mat-raised-button  [disabled]="isbtnDisabled" class="searchBtn">Save</button>
                    </div>
                </div>  
                </form>  
            </div>  
        </div>  
    </div>  
</div> 
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;position: absolute;top: 55%;"></div>
  </div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>