<!-- <div class="row" style="display: flex; flex-wrap: inherit">
  <div class="col-md-10 col-sm-2">
    <h4 class="float-left add-timesheet">Add Timesheet</h4>
  </div>
  <div align="end" class="col-md-2">
    <span class="material-icons float-right" style="color: red" mat-dialog-close
      >cancel</span
    >
  </div>
</div> -->


<div class="container">
  <div class="row">
    <div class="col-md-10 col-sm-8">
      <!-- <h4 class="add-timesheet">Add Timesheet</h4> -->

      <h4 class="add-timesheet">{{ data ? "Edit Timesheet" : "Add Timesheet" }}</h4>
    </div>
    <div class="col-md-2 col-sm-8 text-right mt-n5 mt-md-0">
      <span class="material-icons" style="color: red; font-size: 24px;" mat-dialog-close>cancel</span>
    </div>
  </div>
<form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div class="row">
      <!-- First form -->
      <div class="col-md-6">
        <!-- Your form content here -->
        <label class="form-control-label">Project Name<span class="text-danger">*</span></label>
        <mat-select
          class="form-control email-input select-placeholder"
          placeholder="Select project name"
          formControlName="project"
          required
        >
          <mat-option
            *ngFor="let pro of projectList"
            [value]="pro?.projectId"
            (click)="projectData(pro)"
          >
            {{ pro?.projectName }}
          </mat-option>
        </mat-select>
        <div *ngIf="(project?.invalid && submitted) || project?.dirty">
          <small *ngIf="project?.errors?.required" class="text-danger">Project Name is required</small>
        </div>
      </div>
    
      <!-- Second form -->
      <div class="col-md-6 margin-top">
        <!-- Your second form content here -->
        <label class="form-control-label">Task Name<span class="text-danger">*</span></label>
        <mat-select
        class="form-control email-input select-placeholder"
        placeholder="Select task name"
        formControlName="task"
        required
      >
        <mat-option
          *ngFor="let task of taskList"
          [value]="task?.taskId"
          (click)="getTask(task)"
        >
          {{ task?.taskName }}
        </mat-option>
      </mat-select>
        <div *ngIf="(task.invalid && submitted) || task.dirty">
          <small *ngIf="task.errors?.required" class="text-danger">Task Name is required</small>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class=" col-md-6" id="loginform">
        <label class="form-control-label"
          >Date<span class="text-danger">*</span></label
        >
        <input
          matInput
          [matDatepicker]="picker"
          readonly
          [min]="min"
          [max]="today"
          [disabled]="!disableDate"
          formControlName="date"
          (click)="picker.open()"
          class="form-control email-input select-placeholder"
          placeholder="Choose a date"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
          [disabled]="!disableDate"
          style="float: right; position: relative; bottom: 42px"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker ></mat-datepicker>
        <div *ngIf="(date.invalid && submitted) || date.dirty">
          <small *ngIf="date.errors?.required" class="text-danger"
            >Date is required</small
          >
        </div>
      </div>

      <div class=" col-md-6 mt-n4 mt-md-0" id="loginform">
        <label class="form-control-label"
          >Hours<span class="text-danger">*</span></label
        >
        <input
          class="form-control email-input select-placeholder"
          matInput
          type="text"
          placeholder="Enter Hours"
          maxlength="2"
          (keypress)="noNumber($event)"
          formControlName="workedHours"
          (keyup)="hourData($event)"
          (paste)="onPaste($event)" 
          autocomplete="off"
        />
        <div *ngIf="(workedHours?.invalid && submitted) || workedHours?.dirty">
          <small *ngIf="workedHours?.errors?.required" class="text-danger"
            >Hours are required</small
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class=" col-md-6" id="loginform">
        <label class="form-control-label"
          >Minutes<span class="text-danger">*</span></label
        >
        <input
          class="form-control email-input select-placeholder"
          matInput
          type="text"
          placeholder="Enter minutes"
          maxlength="2"
          (keypress)="noNumber($event)"
          (paste)="onPaste($event)" 
          formControlName="minutes"
          (keyup)="minuteData($event)"
          autocomplete="off"
        />
        <div *ngIf="(minutes.invalid && submitted) || minutes.dirty">
          <small *ngIf="minutes.errors?.required" class="text-danger"
            >Minutes are required</small
          >
        </div>
      </div>

      <div class=" col-md-6" id="loginform">
        <label class="form-control-label"
          >Task Status<span class="text-danger">*</span></label
        >
        <!-- <mat-select class="form-control email-input select-placeholder" 
          formControlName="taskDetailStatus" 
          placeholder="Select task status"
          required>
          <mat-option value="29">InProgress</mat-option>
          <mat-option value="30">Completed</mat-option>
        </mat-select> -->
        <!-- <div>
          <progress value="50" max="100"></progress>
        </div> -->

        <!-- <div class="progress aqua" [attr.data-width]="percentage">
   
          <div class="progress-bar" [style.width]="percentage">
            <div class="progress-text">{{percentage}}</div>
          </div>
        </div> -->

        <!-- app.component.html -->
     
        <!-- <mat-slider
          thumbLabel
          formControlName="taskDetailStatus"
          [displayWith]="formatLabel"
          tickInterval="100"
          showTickMarks
          min="1"
          class="primary mt-n2 mt-md-0"
          color="primary"
          max="100"
          (change)="hideValidationMessage(); taskStatusValueChange()"
        ></mat-slider> -->


        <mat-slider
    thumbLabel
    formControlName="taskDetailStatus"
    [displayWith]="formatLabel"
    tickInterval="100"
    showTickMarks
    min="1"
    class="primary mt-n2 mt-md-0"
    color="primary"
    max="100"
    (change)="hideValidationMessage(); taskStatusValueChange($event)"
  ></mat-slider>

        <div>
          <small
            *ngIf="enterTaskDetailStatus && showValidationMessage"
            class="text-danger"
          >
            Task Status is Required
          </small>
        </div>
      </div>

      <div
        *ngIf="
          (taskDetailStatus.invalid && submitted) || taskDetailStatus.dirty
        "
      >
        <small *ngIf="taskDetailStatus.errors?.required" class="text-danger"
          >TaskStatus is required</small
        >
      </div>
    </div>
    <div class="row mt-n3 mt-md-0">
      <div class=" col-md-6">
        <label class="form-control-label"
          >Task Detail Description<span class="text-danger">*</span></label
        >
        <textarea
          class="form-control email-input select-placeholder textarea"
          name=""
          matInput
          id=""
    
          minlength="1"
          formControlName="description"
          placeholder="Task Detail Description"
          (keyup)="validateInput($event)"
          (keydown)="validateInput($event)"
        ></textarea>
        <div *ngIf="(description.invalid && submitted) || description.dirty">
          <small *ngIf="description.errors?.required" class="text-danger"
            >Task Detail Description is required</small
          >
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button class="searchBtn"    mat-raised-button  type="submit" [disabled]="!isFormValid()">   
      <!-- {{ data ? "Update" : "Create" }} -->

      Save
    </button>
  </div>
</form>

<!-- <div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div> -->
<!-- <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }} -->
<!-- <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button> -->
<!-- </div> -->
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem;
    height: 3rem;
    position: absolute;
    z-index: 1;
    top: 20%;
    right: 47%;"
    role="status"
  ></div>
</div>