

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

//Local URL
// export const base_url = 'http://localhost:6024/api/enote/hrms/';
// export const rec_base_url = 'http://localhost:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://localhost:6018/api/enote/pms/';
// export const fe_url='http://localhost:4200/#/';
// export const leave_base_url = 'http://localhost:6019/api/enote/leave/';
// export const time_entry_url = `http://localhost:6022/api/enote/timeentry/`;
// export const api_url = "http://localhost:6020/api/enote/reimbursement/"    


// export const base_url = 'http://192.168.18.25:6015/api/enote/hrms/';
// export const base_url = 'http://192.168.0.176:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.2.57:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.0.176:6018/api/enote/pms/';
// export const base_url = 'http://192.168.10.61:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.2.57:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.18.25:6018/api/enote/pms/';
// export const base_url = 'http://38.242.135.217:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.2.57:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://38.242.135.217:6018/api/enote/pms/';
// export const base_url = 'http://192.168.2.57:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.2.57:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.2.57:6018/api/enote/pms/';

// export const base_url = 'http://192.168.1.21:6015/api/enote/hrms/';
// // export const rec_base_url = 'http://192.168.18.21:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.1.21:6018/api/enote/pms/';

// //Local

// export const base_url = 'http://192.168.18.25:6015/api/enote/hrms/';
// // export const base_url = 'http://192.168.18.25:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.18.25:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.18.25:6018/api/enote/pms/';
// export const leave_base_url = 'http://192.168.18.25:6019/api/enote/leave/';
// export const fe_url='http://enote.jespersoft.com/#/';
// export const api_url = "http://192.168.18.25:6020/api/enote/reimbursement/"    
// export const time_entry_url = `http://192.168.18.25:6022/api/enote/timeentry/`;


// Test URL

// export const base_url = 'http://192.168.18.25:6040/api/enote/hrms/';

// export const rec_base_url = 'http://192.168.0.198:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.0.198:6018/api/enote/pms/';
// export const leave_base_url = 'http://192.168.0.198:6019/api/enote/leave/';
// export const fe_url='http://enote.jespersoft.com/#/';
// export const api_url = "http://192.168.0.198:6020/api/enote/reimbursement/"    
// export const time_entry_url = `http://192.168.18.60:6022/api/enote/timeentry/`;


// Test URL
// export const base_url = 'http://192.168.18.25:6040/api/enote/hrms/';

// export const base_url = 'http://38.242.135.217:6015/api/enote/hrms/';
// export const rec_base_url = 'http://38.242.135.217:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://38.242.135.217:6018/api/enote/pms/';
// export const leave_base_url = 'http://192.168.0.193:6019/api/enote/leave/';
// export const fe_url='http://enote.jespersoft.com/#/';
// export const api_url = "http://38.242.135.217:6020/api/enote/reimbursement/"    
// export const time_entry_url = `http://38.242.135.217:6019/timeentry/`;
//..........................................................................................................................

// export const base_url = 'https://38.242.135.217:6015/api/enote/hrms/';
// export const rec_base_url = 'https://38.242.135.217:6016/api/enote/recuritment/';
// export const pms_base_url = 'https://38.242.135.217:6018/api/enote/pms/';
// export const leave_base_url = 'https://38.242.135.217:6019/api/enote/leave/';
// export const fe_url='https://enote.jespersoft.com/#/';
// export const api_url = "https://38.242.135.217:6020/api/enote/reimbursement/"    
// export const time_entry_url = `https://38.242.135.217:6019/timeentry/`;
// http://localhost:6022/api/enote/timeentry/


// export const base_url = 'https://213.199.51.189:6015/api/enote/hrms/';
// export const rec_base_url = 'https://213.199.51.189:6016/api/enote/recuritment/';
// export const pms_base_url = 'https://213.199.51.189:6018/api/enote/pms/';
// export const leave_base_url = 'https://213.199.51.189:6019/api/enote/leave/';
// export const fe_url='https://enote.jespersoft.com/#/';
// export const api_url = "https://213.199.51.189:6020/api/enote/reimbursement/"    
// export const time_entry_url = `https://213.199.51.189:6019/timeentry/`;

//domain name

// export const base_url = 'https://enote.jespersoft.com:6015/api/enote/hrms/';
// export const rec_base_url = 'https://enote.jespersoft.com:6016/api/enote/recuritment/';
// export const pms_base_url = 'https://enote.jespersoft.com:6018/api/enote/pms/';
// export const leave_base_url = 'https://enote.jespersoft.com:6019/api/enote/leave/';
// export const fe_url='https://enote.jespersoft.com/#/';
// export const api_url = "https://enote.jespersoft.com:6020/api/enote/reimbursement/"    
// export const time_entry_url = `https://enote.jespersoft.com:6022/api/enote/timeentry/`;


//Test

// export const base_url = 'http://38.242.135.217:6015/api/enote/hrms/';
// export const rec_base_url = 'http://38.242.135.217:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://38.242.135.217:6018/api/enote/pms/';
// // export const fe_url='http://38.242.135.217:4200/#/';
// // export const fe_url='http://localhost:4200/#/';
// // export const fe_url='http://38.242.135.217:4200/#/';
// // export const fe_url='http://localhost:4200/#/';
// export const fe_url='http://enote.jespersoft.com/#/';
// export const leave_base_url = 'http://192.168.10.76:6019/api/enote/leave/';
// export const time_entry_url = `http://192.168.10.76:6022/api/enote/timeentry/`;
// export const api_url = "http://192.168.10.76:6020/api/enote/reimbursement/" 


// export const rec_base_url = 'http://192.168.1.21:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.1.19:6018/api/enote/pms/';
// export const fe_url='http://192.168.1.21:4200/#/';
// export const leave_base_url = 'http://192.168.1.21:6019/api/enote/leave/';
// export const time_entry_url = `http://192.168.1.21:6022/api/enote/timeentry/`;
// export const api_url = "http://192.168.1.21:6020/api/enote/reimbursement/" 
// export const base_url = 'http://38.242.135.217:6015/api/enote/hrms/';
// export const rec_base_url = 'http://38.242.135.217:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://38.242.135.217:601/8/api/enote/pms/';
// export const leave_base_url = 'http://38.242.135.217:6019/api/enote/leave/';
// export const fe_url='http://enote.jespersoft.com/#/';
// export const api_url = "http://38.242.135.217:6020/api/enote/reimbursement/"    
// export const time_entry_url = `http://38.242.135.217:6019/timeentry/`;
// http://localhost:6022/api/enote/timeentry/

// --------------------------------------------------------------------------------------------------
//Test

// export const base_url =`https://enote.jespersoft.com:6015/api/enote/hrms/`;
// export const api_url =`https://enote.jespersoft.com:6020/api/enote/reimbursement/`;
// export const rec_base_url = `https://enote.jespersoft.com:6016/api/enote/recuritment/`;
// export const pms_base_url = `https://enote.jespersoft.com:6018/api/enote/pms/`;
// // export const fe_url='http://localhost:4200/#/';
// export const fe_url=`https://enote.jespersoft.com/#/`;
// export const leave_base_url = `https://enote.jespersoft.com:6019/api/enote/leave/`;
// export const time_entry_url = `https://enote.jespersoft.com:6022/api/enote/timeentry/`;


// export const base_url =`http://192.168.0.200:6015/api/enote/hrms/`;
// export const api_url =`http://192.168.0.200:6020/api/enote/reimbursement/`;
// export const rec_base_url = `http://192.168.0.200:6016/api/enote/recuritment/`;
// export const pms_base_url = `http://192.168.0.200:6018/api/enote/pms/`;
// // export const fe_url='http://localhost:4200/#/';
// export const fe_url=`http://enote.jespersoft.com/#/`;
// export const leave_base_url = `http://192.168.0.200:6019/api/enote/leave/`;
// export const time_entry_url = `https://192.168.0.200:6022/api/enote/timeentry/`;

// export const time_entry_url = `http://192.168.0.199:6022/api/enote/hometimeentry/`;

// export const base_url = 'https://38.242.135.217:6015/api/enote/hrms/';
// export const rec_base_url = 'https://38.242.135.217:6016/api/enote/recuritment/';
// export const pms_base_url = 'https://38.242.135.217:6018/api/enote/pms/'; 
// // export const fe_url='http://localhost:4200/#/';
// export const fe_url='https://enote.jespersoft.com/#/';
// export const leave_base_url = 'https://38.242.135.217:6019/api/enote/leave/';
// export const time_entry_url = `https://38.242.135.217:6022/api/enote/timeentry/`;
// export const api_url = "https://38.242.135.217:6020/api/enote/reimbursement/" 

// // Production
// export const base_url = 'https://www.jesperapps-hcm.jesperapps.com:6040/api/enote/hrms/';
// export const rec_base_url = 'https://www.jesperapps-hcm.jesperapps.com:6044/api/enote/recuritment/';
// export const pms_base_url = 'https://www.jesperapps-hcm.jesperapps.com:6043/api/enote/pms/';
// export const leave_base_url = 'https://www.jesperapps-hcm.jesperapps.com:6042/api/enote/leave/';
// export const time_entry_url = 'https://www.jesperapps-hcm.jesperapps.com:6041/api/enote/timeentry/';
// export const fe_url='https://www.jesperapps-hcm.jesperapps.com/#/';
// export const api_url = "http://38.242.135.217:6020/api/enote/reimbursement/";




//new test

// export const base_url =`https://enote.jespersoft.com:6040/api/enote/hrms/`;
// export const rec_base_url = `https://enote.jespersoft.com:6044/api/enote/recuritment/`;
// export const pms_base_url = `https://enote.jespersoft.com:6043/api/enote/pms/`;
// export const leave_base_url = `https://enote.jespersoft.com:6042/api/enote/leave/`;
// export const time_entry_url = `https://enote.jespersoft.com:6041/api/enote/timeentry/`;
// export const fe_url=`https://enote.jespersoft.com.com/#/`;
// export const api_url = `http://enote.jespersoft.com:6020/api/enote/reimbursement/`;

// export const base_url = `https://enote.jespersoft.com:6015/api/enote/hrms/`;
// export const rec_base_url = `https://enote.jespersoft.com:6016/api/enote/recuritment/`;
// export const pms_base_url =` https://enote.jespersoft.com:6018/api/enote/pms/`; 
// // export const fe_url='http://localhost:4200/#/';
// export const fe_url=`https://enote.jespersoft.com/#/`;
// export const leave_base_url = `https://enote.jespersoft.com:6019/api/enote/leave/`;
// export const time_entry_url =` https://enote.jespersoft.com:6022/api/enote/timeentry/`;
// export const api_url = `https://enote.jespersoft.com:6020/api/enote/reimbursement/`;

// export const base_url = `http://enote.jespersoft.com:6015/api/enote/hrms/`;
// export const rec_base_url = `https://enote.jespersoft.com:6016/api/enote/recuritment/`;
// export const pms_base_url =` https://enote.jespersoft.com:6018/api/enote/pms/`; 
// // export const fe_url='http://localhost:4200/#/';
// export const fe_url=`https://enote.jespersoft.com/#/`;
// export const leave_base_url = `https://enote.jespersoft.com:6019/api/enote/leave/`;
// export const time_entry_url =` https://enote.jespersoft.com:6022/api/enote/timeentry/`;
// export const api_url = `https://enote.jespersoft.com:6020/api/enote/reimbursement/`;

// export const base_url = 'http://192.168.18.25:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.18.44:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.18.25:6018/api/enote/pms/';
// // export const fe_url='http://localhost:4200/#/';  
// export const base_url = 'http://192.168.0.184:6015/api/enote/hrms/';

// export const rec_base_url = 'http://192.168.10.56:6016/api/enote/recuritment/';

// export const base_url = 'http://192.168.0.171:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.18.25:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.0.171:6018/api/enote/pms/';
// // export const fe_url='http://localhost:4200/#/';  
// export const fe_url='http://enote.jespersoft.com/#/';
// export const leave_base_url = 'http://192.168.0.184:6019/api/enote/leave/';
// export const time_entry_url = `http://192.168.10.56:6022/api/enote/timeentry/`;

// export const api_url = "http://192.168.0.187:6020/api/enote/reimbursement/" 

// export const base_url = 'http://192.168.18.25:6015/api/enote/hrms/';
// export const rec_base_url = 'http://192.168.18.44:6016/api/enote/recuritment/';
// export const pms_base_url = 'http://192.168.18.25:6018/api/enote/pms/';
// // export const fe_url='http://localhost:4200/#/';  
// export const fe_url='http://enote.jespersoft.com/#/';
// export const leave_base_url = 'http://192.168.0.184:6019/api/enote/leave/';


export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ZW5vdGU6ZW5vdGUkaHJtcyM=',
  }),
};



export const httprecruitmentOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ZW5vdGU6ZW5vdGUkcmVjdXJpdG1lbnQj',
  }),
};

export const httppmsOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ZW5vdGU6ZW5vdGUkcG1zIw==',
  }),
};
export const httpleaveOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ZW5vdGU6ZW5vdGUkbGVhdmUj',
  }),
};

export const httpOptionsPlain = {
  headers: new HttpHeaders({
    Accept: 'text/plain',
    'Content-Type': 'text/plain',
  }),
  responseType: 'text',
};

export const httpOptionsTimeEntry = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ZW5vdGU6ZW5vdGUkdGltZUVudHJ5Iw==',
  }),
};

export const httpOptionsHrms = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ZW5vdGU6ZW5vdGUkaHJtcyM=',
  }),
};
export const httpOptionsReimbursement = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ZW5vdGU6ZW5vdGUkZXhwZW5zZSM=',
  }),
};


export const responseMsgTimeOut = 20000;
export const redirectMsgTimeOut = 3000;
export const globalTimeOut = 80000;
//export const text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
