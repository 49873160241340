import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import * as XLSX from 'xlsx';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';

import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Calendar } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Router ,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LeaveService } from 'src/app/providers/leave.service';
import { MatOption } from '@angular/material/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatSelect } from '@angular/material/select';
import { UploadfileComponent } from './uploadfile/uploadfile.component';
import { AddHolidayComponent } from './add-holiday/add-holiday.component';
import { MycalendarComponent } from './mycalendar/mycalendar.component';
import { multiplyScalarDependencies } from 'mathjs';
import { ExceptionHoliday } from 'src/app/shift/shiftcalendar/shifthoildays-exceptions/shifthoildays-exceptions.component';
import { ExceptionholidayleaveComponent } from './exceptionholidayleave/exceptionholidayleave.component';
import { X } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-leave-calendar',
  templateUrl: './leave-calendar.component.html',
  styleUrls: ['./leave-calendar.component.scss']
})
export class LeaveCalendarComponent implements OnInit {
  isShow: boolean
  data = [];
  myFilter: any
  calendarOptions: CalendarOptions;
  leaveBalanceCount: any;
  calendarVisible = true;
  checked: boolean;
  branchId: any
  calendarId: any
  employeeId: any
  holidayType: any = []
  exceptionType: any = []
  calendarList: any = []
  calendarList1: any = []
  myArray: any = []
  myArray1: any = []
  arrayData: any = []
  userType: any;
  calendarName: any
  workArray: any = []
  workingDays: any = []
  excel:any = [];
  exceptionFormat:any = [];
  startYear:any;
  endYear:any;
  fileName: any = "No file choosen...";
  expand: Boolean;
  selected = '2025';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  yearbyyearname: any;
  LeaveReport: any=[];
  Leavecurrentdate: any;
  Holidaycurrentdate: any;
  upComingHolidaydata: any;
  upcomingLeavedata: any;
  upcomingException:any;
  currentyear: number;
  totaldays: any[];
  exceptioncurrentdate: string;
  organizationId: any;
  loader:boolean = true;
  isAdmin: any;
  isRequirements: any;
  isEmployee: any;
  isHumanResource: any;
  calendarYear: any;
  displayedHolidays: any[];
  calendars: any;
  // backdrop: any = false;

  
    constructor(private breakpointObserver: BreakpointObserver,
      private router: Router,
      private leaveservice:LeaveService,
      private datepipe:DatePipe,
      private dialog: MatDialog) { }
  
    ngOnInit() {
      var currentDate = new Date();
      var theFirst = new Date(currentDate.getFullYear(), 0, 1);
      var theLast = new Date(currentDate.getFullYear(), 11, 31);
      this.startYear = this.datepipe.transform(theFirst, 'YYYY-MM-dd');
      this.endYear = this.datepipe.transform(theLast, 'YYYY-MM-dd');
      console.log(this.startYear);
      console.log(this.endYear);
      this.expand = true;
      let oned = 24 * 60 * 60 * 1000;
      let data = JSON.parse(localStorage.getItem('enoteUserData'));
      // this.userType = data.userType.userType;
      this.employeeId = data.employee.employeeId;
      this.organizationId = data.organization.organizationId;
      this.userType = [];
      data.employee.role.forEach((role) => {
        this.userType.push(role?.roleName);
      });
      this.isAdmin = this.userType.includes('Admin');
    this.isRequirements = this.userType.includes('Recruiter');
    this.isEmployee = this.userType.includes('Employee');
    this.isHumanResource = this.userType.includes('Human Resource');

    console.log( "this.isAdmin", this.isAdmin);
    console.log( "this.isHumanResource ", this.isHumanResource);
    console.log( "this.isRequirements ", this.isRequirements);
    console.log( "this.isEmployee" , this.isEmployee);


      if (this.isHandset$) {
        console.log(this.isHandset$);
        this.expand = true
      }
     console.log("this.userType",this.userType);
      if (this.isAdmin != 'Employee') {
        this.calendarOptions = {
          // height: 530,
          droppable: true,
          // weekends: true,
          editable: false,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          themeSystem: "bootstrap4",
          // themeName: 'Materia',
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth'
          },
          initialView: 'dayGridMonth',
          validRange: {
            start: '2025-01-01',
            end: '2025-12-31'
          },
          displayEventTime: false,
          // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
          select: this.addEvent.bind(this),
          eventClick: this.updateEvent.bind(this),
          plugins: [interactionPlugin,dayGridPlugin],
          
        };
      }
      else {
        this.calendarOptions = {
          // height: 530,
          droppable: true,
          // weekends: true,
          // editable: true,
          // selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          themeSystem: "bootstrap4",
          // themeName: 'Materia',
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth'
          },
          initialView: 'dayGridMonth',
          buttonText: {
            dayGridMonth:'Month',
            today:'Today',
            },
          displayEventTime: false,
          plugins: [interactionPlugin],
        };

      }
      this.leaveservice.getEmpListById(this.employeeId).subscribe(
        (x:any)=>{
          console.log("x",x);
          this.branchId = x.branch.branchId;
        }
      )
      this.leaveservice.getListTypeById(5).subscribe(
        (d: any) => {
          this.holidayType = d;
          console.log("this.holidayType",this.holidayType);
        }
      )
  
      this.leaveservice.getListTypeById(6).subscribe(
        (d: any) => {
          this.exceptionType = d;
          console.log("this.exceptionType",this.exceptionType);
        }
      )
      this.leaveservice.getYear().subscribe(
        (d: any)=> {
          this.yearbyyearname = d;
          console.log("yearbyyearname",this.yearbyyearname);
        }
      )
      let currentyear = new Date().getFullYear();
        this.leaveservice.calenderOfYear(currentyear, this.organizationId).subscribe(
        (x: any) => {
          if(x.statusCode == undefined){
            this.calendarList1 = x;
            console.log(this.calendarList1);
            // this.changeCalendar(this.calendarList1[0]);
            this.calendarId = this.calendarList1[0].calendarId
            this.calendarList1.map(
              e => {
                if (e.calendarId == this.calendarId) {
                  e.checked = true
                  this.calendarName = e.calendarName
                }
                else {
                  e.checked = false
                }
              }
            )
          }
        })
  
      this.leaveservice.GetMYleaveBalance(data.employee.employeeId).subscribe(
        d => {
          d.map(
            e => {
              this.leaveBalanceCount = e.noOfDays;
            }
          )
        }
      )
      // this.upcomingHoliday();
      this.fetchData()
   
  
      this.excel = [
        {
          'HolidayDate': '10-01-2024',
          'HolidayName': "Gandhi Jayanthi",
          'LeaveType': "Holiday",
          'HolidayType': "National"
        },
        {
          'HolidayDate': '31-07-2024',
          'HolidayName': "4th saturday",
          'LeaveType': "Holiday",
          'HolidayType': "Holiday"
        }
      ]
  
      this.exceptionFormat = [
        {
          'HolidayDate': '20-08-2024',
          'HolidayName': "Moogaram",
          'LeaveType': "Exception",
          'HolidayType': "Leave"
        },
        {
          'HolidayDate': '31-07-2024',
          'HolidayName': "Compensate",
          'LeaveType': "Exception",
          'HolidayType': "Working day"
        }
      ]
    }
    
    calendaryear(event)
    {
      this.calendarList1=[];
      console.log("YEAR");
      this.calendarYear=event.value
      console.log(event.value);
      var start='-01-01';
      var end='-12-32';
        var year=event.value;
        console.log("YEAR"+year);
        var yearStart=year+start;
        console.log(yearStart);
        var yearEnd=year+end;
        console.log(yearEnd);
       
        console.log("Calendar"+year)
        this.calendarOptions = {
          droppable: true,
          // weekends: true,
          editable: false,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          
          themeSystem: "bootstrap4",
          // themeName: 'Materia',
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
             right: 'dayGridMonth' 
            // right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          },
         
          initialView: 'dayGridMonth',
          validRange: {
            start: yearStart,
            end: yearEnd
          },
          
          displayEventTime: false,
          select: this.addEvent.bind(this),
          eventClick: this.updateEvent.bind(this),
          plugins: [interactionPlugin,dayGridPlugin],
        };
  
        this.leaveservice.calenderOfYear(event.value,this.organizationId).subscribe(
          (data:any)=>{
            if(data.statusCode == undefined){
            this.calendarList1 = data;
            console.log("calendarList1---",this.calendarList1);
            // this.changeCalendar(this.calendarList1[0]);
            this.calendarId = this.calendarList1[0].calendarId
            this.calendarList1.map(
              e => {
                if (e.calendarId == this.calendarId) {
                  e.checked = true
                  this.calendarName = e.calendarName
                }
                else {
                  e.checked = false
                }
              }
            )
            }
          }
        )
        this.fetchData()
    }

   
    
  
    MatDrawControl(){
      this.expand = !this.expand
      console.log(this.expand);
    }
    exportAsXLSX(): void {
      this.leaveservice.exportAsExcelFile(this.excel, 'holiday');
    }
  
    exportAsExceptionXLSX(): void {
      this.leaveservice.exportAsExcelFile(this.exceptionFormat, 'exception');
    }
  
    fetchData() {
      this.loader=true;
      this.leaveservice.getEmpListById(this.employeeId).subscribe(
        (x:any)=>{
          this.loader =  false;
          this.branchId = x.branch.branchId;
          console.log("this.branchId",this.branchId);
          //getbranch
          this.leaveservice.getCalByBranch(this.branchId).subscribe(
            (d: any) => {
              this.loader =  false;
              console.log('getCalByBranch',d);
              d.map(
                e => {
                  let date = new Date()
                  if (e.year.yearName == date.getFullYear()) {
                    this.calendarId = e.calendarId

                  // getWoringDays
                  if(this.isAdmin !='Employee'){
                    console.log("this.calendarId",this.calendarId);
                    this.leaveservice.getCalendarById(this.calendarId).subscribe(
                      (d: any) => {
                        console.log('getCalendarById',d);
                        let b = ["1", "2", "3", "4", "5","6","0"]
                        console.log(d.calendarWorkingDays);

                        d.calendarWorkingDays.map(dot => {

                          const workingDays = b.filter((dayCode) => {
                            const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
                            return dayNumber < dot.workingdays;
                          })
                          .map((dayCode) => dayCode);
                          dot['workingdays'] = workingDays;

                          console.log("dot.workingdays", dot.workingdays);

                          // this.workingDays = dot.workingdays;
                          var difference: any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                          difference.map(
                            a => {
                              this.workArray.push(Number(a));
                            }
                          )
                          // this.calendarOptions.hiddenDays = this.workArray
                        })
                        var event = []
                        this.leaveservice.leavereportByempname(this.employeeId).subscribe(
                          (data:any)=>{
                              console.log('leavereportByempname',data);
                              
                              this.LeaveReport=data;

                              if (Array.isArray(this.LeaveReport)) {
                                  this.LeaveReport.forEach(d => {
                                  // console.log(this.LeaveReport);
                                  let MyDate = new Date(d.startDate);
                                  let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                                  if(d.leaveStatusName=='Approved'){
                                    if(d.endDate == d.startDate){
                                      event.push({
                                        id: d.leaveRequestId,
                                        title: d.leaveType.leaveName,
                                        start: MyDateString,
                                        groupId:d.leaveStatusName,
                                        color:'#FF9999',

                                      })
                                    }
                                    else{
                                      let MyEndDate = new Date(d.endDate);
                                      let MyEndDateString = MyEndDate.getFullYear() + '-' + ('0' + (MyEndDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyEndDate.getDate()).slice(-2)
                                      event.push({
                                        id: d.leaveRequestId,
                                        title: d.leaveType.leaveName,
                                        start:d.startDate,
                                        groupId:d.leaveStatusName,
                                        color:'#FF9999',
                                        end:d.endDate,
                                      })
                                    }
                                  } 
                                  this.calendarOptions.events = event;
                                  console.log("Leave",this.calendarOptions.events);
                                  this.arrayData = this.calendarOptions.events;
                                  this.dateClass();
                                })
                              }

                            // getHolidays
                            d.holiday.forEach((e) => {
                              // if(e.holidayStatus == 1){
                              let MyDate = new Date(e.holidayDate)
                              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                              if (e.holidayStatus == 1) {
                                event.push(
                                  {
                                    id: e.holidayId,
                                    title: e.holidayName,
                                    start: MyDateString,
                                    groupId: Number(e.holidaytype),
                                    color: '#00BB1E',
                                  })
                              } 
                              if (e.holidayStatus == 2) {
                                event.push(
                                  {
                                    id: e.holidayId,
                                    title: e.holidayName,
                                    start: MyDateString,
                                    groupId: Number(e.holidaytype),
                                    color: '#00bb1e6b',
                                  })
                              }

                              this.calendarOptions.events = event;
                              console.log("holiday",this.calendarOptions.events);
                              this.arrayData = this.calendarOptions.events;
                              console.log(this.arrayData,"111111111111111")
                              this.dateClass()
                              // }

                            })

                            d.exceptions.forEach((e) => {
                              let MyDate = new Date(e.exceptionDate)
                              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                              if (e.exceptionStatus == 1) {
                                event.push(
                                  {
                                    id: e.exceptionId,
                                    title: e.exceptionName,
                                    start: MyDateString,
                                    groupId: Number(e.exceptionType),
                                    color: '#40E0D0',
                                  })
                              }
                              if (e.exceptionStatus == 2) {
                                event.push(
                                  {
                                    id: e.exceptionId,
                                    title: e.exceptionName,
                                    start: MyDateString,
                                    groupId: Number(e.exceptionType),
                                    color: '#40e0d070',
                                  })
                              }

                              this.calendarOptions.events = event;
                              // this.arrayData = this.calendarOptions.events;
                              console.log("Exception",this.calendarOptions.events);
                              this.dateClass();
                            })
                            this.loader = false;
                          }
                        )

                      }
                    )
                  }
                  else{
                    this.leaveservice.getCalendarById_Active(this.calendarId).subscribe(
                      (d: any) => {
                        console.log('getCalendarById_Active',d);
                        let b = ["1", "2", "3", "4", "5","6","0"]
                        console.log("calendarWorkingDays",d.calendarWorkingDays);
                        d.calendarWorkingDays.map(dot => {
                          this.workingDays = dot.workingdays
                          var difference: any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                          difference.map(
                            a => {
                              this.workArray.push(Number(a))
                            }
                          )
                          // this.calendarOptions.hiddenDays = this.workArray
                        })
                        console.log(d.calendarWorkingDays);
                        var event = []
                        this.leaveservice.leavereportByempname(this.employeeId).subscribe(
                          (data:any)=>{
                              console.log('leavereportByempname',data);
                              this.LeaveReport=data;
                              console.log(!this.LeaveReport.statusCode);
                              if(!this.LeaveReport.statusCode){
                              this.LeaveReport.forEach(d => {
                                console.log(this.LeaveReport);
                                let MyDate = new Date(d.startDate);
                                let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                                if(d.leaveStatusName=='Approved'){
                                  if(d.endDate == d.startDate){
                                    event.push({
                                      id: d.leaveRequestId,
                                      title: d.leaveType.leaveName,
                                      start: MyDateString,
                                      groupId:d.leaveStatusName,
                                      color:'#FF9999',
          
                                    })
                                  }
                                  else{
                                    let MyEndDate = new Date(d.endDate);
                                    let MyEndDateString = MyEndDate.getFullYear() + '-' + ('0' + (MyEndDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyEndDate.getDate()).slice(-2)
                                    event.push({
                                      id: d.leaveRequestId,
                                      title: d.leaveType.leaveName,
                                      start:d.startDate,
                                      groupId:d.leaveStatusName,
                                      color:'#FF9999',
                                      end:d.endDate,
                                    })
                                  }
                                } 
                                this.calendarOptions.events = event;
                                console.log("Leave",this.calendarOptions.events);
                                this.arrayData = this.calendarOptions.events;
                                this.dateClass();
                              })
                              }
                              
                            // getHolidays
                            d.holiday.forEach((e) => {
                              // if(e.holidayStatus == 1){
                              let MyDate = new Date(e.holidayDate)
                              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                              if (e.holidayStatus == 1) {
                                event.push(
                                  {
                                    id: e.holidayId,
                                    title: e.holidayName,
                                    start: MyDateString,
                                    groupId: Number(e.holidaytype),
                                    color: '#00BB1E',
                                  })
                              } 
                              if (e.holidayStatus == 2) {
                                event.push(
                                  {
                                    id: e.holidayId,
                                    title: e.holidayName,
                                    start: MyDateString,
                                    groupId: Number(e.holidaytype),
                                    color: '#00bb1e6b',
                                  })
                              }

                              this.calendarOptions.events = event;
                              console.log("holiday",this.calendarOptions.events);
                              this.arrayData = this.calendarOptions.events;
                              this.dateClass()
                              // }

                            })

                            d.exceptions.forEach((e) => {
                              let MyDate = new Date(e.exceptionDate)
                              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                              if (e.exceptionStatus == 1) {
                                event.push(
                                  {
                                    id: e.exceptionId,
                                    title: e.exceptionName,
                                    start: MyDateString,
                                    groupId: Number(e.exceptionType),
                                    color: '#40E0D0',
                                  })
                              }
                              if (e.exceptionStatus == 2) {
                                event.push(
                                  {
                                    id: e.exceptionId,
                                    title: e.exceptionName,
                                    start: MyDateString,
                                    groupId: Number(e.exceptionType),
                                    color: '#40e0d070',
                                  })
                              }

                              this.calendarOptions.events = event;
                              this.arrayData = this.calendarOptions.events;
                              console.log("Exception",this.calendarOptions.events);
                              this.dateClass();
                          
                            })
                            this.loader = false;
                          }
                        )
                      }
                    )
                  }
                }
              }
            )
            }
          )
          let currentyear = new Date().getFullYear();
            this.leaveservice.calenderOfYear(currentyear,this.organizationId).subscribe(
            (x: any) => {
              if(x.statusCode == undefined){
                x.map(e => {
                  this.calendarList.push(e)
                })
                this.calendarList.map(
                  e => {
                    if (e.calendarId == this.calendarId) {
                      e.checked = true
                      this.calendarName = e.calendarName
                    }
                    else {
                      e.checked = false
                    }
                  }
                )
              }

            }
          )
        }
      )
    }

 
  
   
    
  
    
    
  
  
   
    
 
    
    
   
    
    

    
    upcomingHoliday() {
      let alldata = [];
      let todaydate = new Date();
      this.Holidaycurrentdate = this.datepipe.transform(todaydate, 'MM-dd-yyyy'),
      console.log(this.Holidaycurrentdate);
      this.currentyear =  (new Date()).getFullYear();
      this.leaveservice.getUpComingHoliday(this.employeeId, this.Holidaycurrentdate,this.organizationId).subscribe(
        (data: any) => {
          this.upComingHolidaydata = data.holiday;
          console.log("holidays", this.upComingHolidaydata);
          let newobject = {};
          if( this.upComingHolidaydata != undefined){
            this.upComingHolidaydata.map(
              (d: any) => {
                console.log(d);
                newobject = {
                  appliedDate: this.datepipe.transform(d.holidayDate, 'MM-dd-yyyy'),
                  // appliedDate: d.holidayDate,
                  description: d.holidayName,
                  holidaytypename: d.holidaytypename,
                  leavestatus: d.holidayStatus
                }
                alldata.push(newobject);
                console.log(alldata);
              }
            )
          }
          let todaydate = new Date();
          this.Leavecurrentdate = this.datepipe.transform(todaydate, 'yyyy-MM-dd'),
          console.log(this.Leavecurrentdate);
            this.leaveservice.getUpComingLeaves(this.employeeId, this.Leavecurrentdate).subscribe(
              (data: any) => {
                this.upcomingLeavedata = data.leaveRequest;
                console.log("leaves", this.upcomingLeavedata);
                let newobject2 = {};
                if( this.upcomingLeavedata != undefined){
                  this.upcomingLeavedata.map(
                    (d: any) => {
                      console.log(d);
                      newobject2 = {
                        appliedDate: this.datepipe.transform(d.startDate, 'MM-dd-yyyy'),
                        // appliedDate: d.startDate,
                        description: d.leaveType.leaveName,
                        leavestatus: d.leaveStatusName
                      }
                      if(d.endDate != d.startDate){
                        newobject2['endate']=this.datepipe.transform(d.endDate, 'MM-dd-yyyy')
                        // newobject2['endate']=d.endDate
                      }
                      alldata.push(newobject2);
                    }
                  )
                }
                console.log("holidays and Leaves", alldata);
                this.exceptioncurrentdate = this.datepipe.transform(todaydate, 'MM-dd-yyyy'),
                console.log(this.Leavecurrentdate);
                this.leaveservice.getExceptionlist(this.employeeId, this.exceptioncurrentdate,this.branchId).subscribe(
                  (data: any) => {
                    console.log(data);
                    this.upcomingException = data.exceptions;
                    console.log("Exception", this.upcomingException);
                    let newobject3 = {};
                    if( this.upcomingException != undefined){
                      this.upcomingException.map(
                        (d: any) => {
                          console.log(d);
                          if(d.exceptionType == 30){
                            newobject3 = {
                              appliedDate: this.datepipe.transform(d.exceptionDate, 'MM-dd-yyyy'),
                              // appliedDate: d.holidayDate,
                              description: d.exceptionName,
                              holidaytypename: d.exceptionType,
                              leavestatus: d.exceptionStatus
                            }
                            alldata.push(newobject3);
                            console.log(alldata);
                          }
                        }
                      )
                    }
                    this.totaldays = alldata;
                    console.log(this.totaldays);
                    this.totaldays.sort(function(a, b) { 
                    return new Date(a.appliedDate).valueOf() - new Date(b.appliedDate).valueOf() 
                  })
                })
              }
            )
          console.log(this.totaldays);
          this.totaldays = this.totaldays;
        }
      )
    }
    //
    logout() {
      Swal.fire({
        title: 'Are you sure? Do you want to logout',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3f51b5',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'Logout'
      }).then((result) => {
        if (result.value) {
          // Swal.fire(
          //   'Done!'
          // )
          window.close()
          localStorage.removeItem('calendarData')
          this.router.navigate(['login'])
        }
        //
  
      })
    }
  
    changeCalendar(cal) {
      // getWoringDays
      this.loader = true; // Show loader

      console.log("changeCalendar:", cal);
      this.calendarId = cal.calendarId
      console.log(this.calendarId)
      this.calendarList.map(
        e => {
          if (e.calendarId == this.calendarId) {
            e.checked = true
            this.calendarName = e.calendarName
          }
          else {
            e.checked = false
          }
        }
      )
      this.calendarList1.map(
        e => {
          if (e.calendarId == this.calendarId) {
            e.checked = true
            this.calendarName = e.calendarName
          }
          else {
            e.checked = false
          }
        }
      )
      this.workArray = []
      this.workingDays = []
      console.log(this.myArray);
      this.myArray.forEach(element => {
        console.log(element.calender.calendarId);
        if(element.calender.calendarId !=undefined){
          this.leaveservice.getCalendarById(this.calendarId).subscribe(
            (d: any) => {
              console.log(d);
              var event = []
              this.leaveservice.leavereportByempname(this.employeeId).subscribe(
                (data:any)=>{
                    console.log('leavereportByempname',data);
                    
                    this.LeaveReport=data;
                    
                    this.LeaveReport.forEach(d => {
                    // console.log(this.LeaveReport);
                    let MyDate = new Date(d.startDate);
                    let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                    if(d.leaveStatusName=='Approved'){
                      if(d.endDate == d.startDate){
                        event.push({
                          id: d.leaveRequestId,
                          title: d.leaveType.leaveName,
                          start: MyDateString,
                          groupId:d.leaveStatusName,
                          color:'#FF9999',

                        })
                      }
                      else{
                        let MyEndDate = new Date(d.endDate);
                        let MyEndDateString = MyEndDate.getFullYear() + '-' + ('0' + (MyEndDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyEndDate.getDate()).slice(-2)
                        event.push({
                          id: d.leaveRequestId,
                          title: d.leaveType.leaveName,
                          start:d.startDate,
                          groupId:d.leaveStatusName,
                          color:'#FF9999',
                          end:d.endDate,
                        })
                      }
                    } 
                    this.calendarOptions.events = event;
                    console.log("Leave",this.calendarOptions.events);
                    this.arrayData = this.calendarOptions.events;
                    this.dateClass();
                  })
              if (d.holiday == '') {
                event.push(
                  {
                    id: '',
                    title: '',
                    start: '',
                    end: '',
                    groupId: '',
                  })
                this.calendarOptions.events = event;
                this.arrayData = this.calendarOptions.events;
              }
              if (d.exception == '') {
                event.push(
                  {
                    id: '',
                    title: '',
                    start: '',
                    end: '',
                    groupId: '',
                  })
                this.calendarOptions.events = event;
                this.arrayData = this.calendarOptions.events;
              }
              // getHolidays
              d.holiday.forEach((e) => {
                let MyDate = new Date(e.holidayDate)
                let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
      
                if (e.holidayStatus == 1) {
                  event.push(
                    {
                      id: e.holidayId,
                      title: e.holidayName,
                      start: MyDateString,
                      end: MyDateString,
                      groupId: Number(e.holidaytype),
                      color: '#00BB1E',
                    })
                }
                if (e.holidayStatus == 2) {
                  event.push(
                    {
                      id: e.holidayId,
                      title: e.holidayName,
                      start: MyDateString,
                      end: MyDateString,
                      groupId: Number(e.holidaytype),
                      color: '#00bb1e6b',
                    })
                }
                this.calendarOptions.events = event;
                this.arrayData = this.calendarOptions.events;
              })
      
              d.exceptions.forEach((e) => {
                let MyDate = new Date(e.exceptionDate)
                let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
      
                if (e.exceptionStatus == 1) {
                  event.push(
                    {
                      id: e.exceptionId,
                      title: e.exceptionName,
                      start: MyDateString,
                      end: MyDateString,
                      groupId: Number(e.exceptionType),
                      color: '#40E0D0',
                    })
                }
                if (e.exceptionStatus == 2) {
                  event.push(
                    {
                      id: e.exceptionId,
                      title: e.exceptionName,
                      start: MyDateString,
                      end: MyDateString,
                      groupId: Number(e.exceptionType),
                      color: '#40e0d070',
                    })
                }
                this.calendarOptions.events = event;
                this.arrayData = this.calendarOptions.events;
              })
              let b = ["1", "2", "3", "4", "5","6","0"]
              if (d.calendarWorkingDays == '') {
                this.calendarOptions.hiddenDays = []
              }
              else {
                d.calendarWorkingDays.map(dot => {
                  if (dot.workingdays != '') {
                    this.workingDays = dot.workingdays
                    var difference: any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                    difference.map(
                      a => {
                        this.workArray.push(Number(a))
                      }
                    )
                    // this.calendarOptions.hiddenDays = this.workArray
                  }
                })
              }
              this.loader = false;
            })
            }
          )
        }})
      this.leaveservice.getCalendarById(this.calendarId).subscribe(
        (d: any) => {
          console.log(d);
          var event = []
          this.leaveservice.leavereportByempname(this.employeeId).subscribe(
            (data:any)=>{
                console.log('leavereportByempname',data);
                
                this.LeaveReport=data;
                
                this.LeaveReport.forEach(d => {
                // console.log(this.LeaveReport);
                let MyDate = new Date(d.startDate);
                let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                if(d.leaveStatusName=='Approved'){
                  if(d.endDate == d.startDate){
                    event.push({
                      id: d.leaveRequestId,
                      title: d.leaveType.leaveName,
                      start: MyDateString,
                      groupId:d.leaveStatusName,
                      color:'#FF9999',

                    })
                  }
                  else{
                    let MyEndDate = new Date(d.endDate);
                    let MyEndDateString = MyEndDate.getFullYear() + '-' + ('0' + (MyEndDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyEndDate.getDate()).slice(-2)
                    event.push({
                      id: d.leaveRequestId,
                      title: d.leaveType.leaveName,
                      start:d.startDate,
                      groupId:d.leaveStatusName,
                      color:'#FF9999',
                      end:d.endDate,
                    })
                  }
                } 
                this.calendarOptions.events = event;
                console.log("Leave",this.calendarOptions.events);
                this.arrayData = this.calendarOptions.events;
                this.dateClass();
              })
          if (d.holiday == '') {
            event.push(
              {
                id: '',
                title: '',
                start: '',
                end: '',
                groupId: '',
              })
            this.calendarOptions.events = event;
            this.arrayData = this.calendarOptions.events;
          }
          if (d.exception == '') {
            event.push(
              {
                id: '',
                title: '',
                start: '',
                end: '',
                groupId: '',
              })
            this.calendarOptions.events = event;
            this.arrayData = this.calendarOptions.events;
            
          }
          // getHolidays
          d.holiday.forEach((e) => {
            let MyDate = new Date(e.holidayDate)
            let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
  
            if (e.holidayStatus == 1) {
              event.push(
                {
                  id: e.holidayId,
                  title: e.holidayName,
                  start: MyDateString,
                  end: MyDateString,
                  groupId: Number(e.holidaytype),
                  color: '#00BB1E',
                })
            }
            if (e.holidayStatus == 2) {
              event.push(
                {
                  id: e.holidayId,
                  title: e.holidayName,
                  start: MyDateString,
                  end: MyDateString,
                  groupId: Number(e.holidaytype),
                  color: '#00bb1e6b',
                })
            }
            this.calendarOptions.events = event;
            this.arrayData = this.calendarOptions.events;
          })
  
          d.exceptions.forEach((e) => {
            let MyDate = new Date(e.exceptionDate)
            let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
  
            if (e.exceptionStatus == 1) {
              event.push(
                {
                  id: e.exceptionId,
                  title: e.exceptionName,
                  start: MyDateString,
                  end: MyDateString,
                  groupId: Number(e.exceptionType),
                  color: '#40E0D0',
                })
            }
            if (e.exceptionStatus == 2) {
              event.push(
                {
                  id: e.exceptionId,
                  title: e.exceptionName,
                  start: MyDateString,
                  end: MyDateString,
                  groupId: Number(e.exceptionType),
                  color: '#40e0d070',
                })
            }
            this.calendarOptions.events = event;
            this.arrayData = this.calendarOptions.events;
          })
          let b = ["1", "2", "3", "4", "5","6","0"]
          if (d.calendarWorkingDays == '') {
            this.calendarOptions.hiddenDays = []
          }
          else {
            d.calendarWorkingDays.map(dot => {
              if (dot.workingdays != '') {
                const workingDays = b.filter((dayCode) => {
                  const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
                  return dayNumber < dot.workingdays;
                })
                .map((dayCode) => dayCode);
                dot['workingdays'] = workingDays;

                console.log("dot.workingdays", dot.workingdays);
                // this.workingDays = dot.workingdays
                var difference: any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                difference.map(
                  a => {
                    this.workArray.push(Number(a))
                  }
                )
              }
            })
          }
          this.loader = false;
        })
        }
      )
    }
  
    // deactivateCalendar(cal) {
    //   this.leaveservice.deactiveCalendar(cal.calendarId, '').subscribe(
    //     (d: any) => {
    //       Swal.fire({
    //         text: d.description,
    //         icon: 'success'
    //       })
    //       setTimeout(() => {
    //          window.location.reload()
    //       }, 2000)
    //     },
    //     err => {
    //       Swal.fire({
    //         text: err.error.message,
    //         icon: 'error'
    //       })
    //     }
    //   )
    // }

    deactivateCalendar(cal) {
      this.leaveservice.deactiveCalendar(cal.calendarId, '').subscribe(
        (d: any) => {
          // Show success message
          Swal.fire({
            text: d.description,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          
          // Update the local data (remove or mark as inactive)
          this.leaveservice.getCalendarById(cal.calendarId).subscribe(
            (d: any) => {
              console.log(d);
              this.loader = false;
              cal.calendarStatus = d.calendarStatus;
              console.log(cal.calendarStatus);
            }
          );
    
          // Optionally, you can filter out the deactivated calendar
          // this.calendars = this.calendars.filter(calendar => calendar.calendarId !== cal.calendarId);
        },
        err => {
          // Handle error response
          Swal.fire({
            text: err.error.message,
            icon: 'error'
          });
        }
      );
    }
    
  
    dateClass() {
      return (d: Date) => {
        const day = new Date(d).getDay();
        const time = new Date(d).getTime()
        return day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 5 ? 'special-date' : ''
      }
      // const datesToHighlight = ["2021-05-22T00:00:00.000Z", "2021-05-06T00:00:00.000Z"];
  
      // return  (date: Date): MatCalendarCellCssClasses => {
      //   const highlightDate = datesToHighlight
      //     .map(strDate => new Date(strDate))
      //     .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
  
      //   return highlightDate ? 'special-date' : '';
      // }
    }
  
    createCalendar() {
      const dialogbox = this.dialog.open(MycalendarComponent, {
        width: '500px',
        data: 'e'
      })

      dialogbox.afterClosed().subscribe(
        (res) => {
          console.log(res);
        
          if (res) { 
            console.log(res); 
            console.log(this.calendarYear)
            this.leaveservice.calenderOfYear(this.calendarYear,this.organizationId).subscribe(
              (x: any) => {
                if(x.statusCode == undefined){
                  this.calendarList1 = x;
                  console.log(this.calendarList1);
                  // this.changeCalendar(this.calendarList1[0]);
                  this.calendarId = this.calendarList1[0].calendarId
                  this.calendarList1.map(
                    e => {
                      if (e.calendarId == this.calendarId) {
                        e.checked = true
                        this.calendarName = e.calendarName
                      }
                      else {
                        e.checked = false
                      }
                    }
                  )
                }
              })
            // this.leaveservice.getCalendarById(X).subscribe(
            //   (d: any) => {
            //     console.log(d);
            //     this.loader = false;
            //     X.calendarName = d.calendarName;
            //     console.log(cal.calendarName);
            //   }
            // );
          }
        }
      );
    }
  
    updateCalendar(cal) {
      console.log(cal)
      const dialogbox = this.dialog.open(MycalendarComponent, {
        width: '500px',
        data: cal
      })

      dialogbox.afterClosed().subscribe(
        (res) => {
          console.log(res);
        
          if (res) {  
            this.leaveservice.getCalendarById(cal.calendarId).subscribe(
              (d: any) => {
                console.log(d);
                this.loader = false;
                cal.calendarName = d.calendarName;
                cal.calendarStatus = d.calendarStatus;
                console.log(cal.calendarName, cal.calendarStatus);
              }
            );
          }
        }
      );
      
      
      
      
    }

    uploadfiles(){
      const dialogRef = this.dialog.open(UploadfileComponent, {
        width: '350px',
        data: 'e'
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        
          // this.leaveservice.getCalendarById(this.calendarId).subscribe(
          //   (d: any) => {
          //     const events = [];
              
             
          //     if (d.holiday == '') {
          //       events.push({ id: '', title: '', start: '', end: '', groupId: '' });
          //     } else {
          //       d.holiday.forEach((e) => {
          //         let MyDate = new Date(e.holidayDate);
          //         let MyDateString = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + MyDate.getDate()).slice(-2)}`;
      
          //         if (e.holidayStatus == 1) {
          //           events.push({
          //             id: e.holidayId,
          //             title: e.holidayName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.holidaytype),
          //             color: '#00BB1E',
          //           });
          //         } else if (e.holidayStatus == 2) {
          //           events.push({
          //             id: e.holidayId,
          //             title: e.holidayName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.holidaytype),
          //             color: '#00bb1e6b',
          //           });
          //         }
          //       });
          //     }
    
            
          //     if (d.exception == '') {
          //       events.push({ id: '', title: '', start: '', end: '', groupId: '' });
          //     } else {
          //       d.exceptions.forEach((e) => {
          //         let MyDate = new Date(e.exceptionDate);
          //         let MyDateString = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + MyDate.getDate()).slice(-2)}`;
      
          //         if (e.exceptionStatus == 1) {
          //           events.push({
          //             id: e.exceptionId,
          //             title: e.exceptionName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.exceptionType),
          //             color: '#40E0D0',
          //           });
          //         } else if (e.exceptionStatus == 2) {
          //           events.push({
          //             id: e.exceptionId,
          //             title: e.exceptionName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.exceptionType),
          //             color: '#40e0d070',
          //           });
          //         }
          //       });
          //     }
    
          //     // Update the calendar events without page reload
          //     this.calendarOptions.events = events;
          //     this.arrayData = this.calendarOptions.events;
    
          //     // Handling working days
          //     let b = ["1", "2", "3", "4", "5", "6", "0"];
          //     // this.workArray = []; // Reset workArray
    
          //     if (d.calendarWorkingDays != '') {
          //       d.calendarWorkingDays.map(dot => {
          //         if (dot.workingdays != '') {
          //           const workingDays = b.filter((dayCode) => {
          //             const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
          //             return dayNumber < dot.workingdays;
          //           });
          //           dot['workingdays'] = workingDays;
    
          //           var difference = b.filter(x => dot.workingdays.indexOf(x) === -1);
          //           difference.map(a => this.workArray.push(Number(a)));
          //         }
          //       });
          //       // Update hiddenDays in calendarOptions
          //       // this.calendarOptions.hiddenDays = this.workArray;
          //     } 
          //     // else {
          //     //   this.calendarOptions.hiddenDays = [];
          //     // }
    
          //     this.loader = false;
          //   }
          // );
          this.fetchData();
        }
      });
    }

    
  
    // ************ Add Event ************
    // addEvent(selectInfo: DateSelectArg) {
    //   let data = {
    //     start: selectInfo.start,
    //     groupId: this.calendarId
    //   }
    //   const calendarApi = selectInfo.view.calendar;
    //   calendarApi.unselect();
    //   const dialogRef = this.dialog.open(AddHolidayComponent, {
    //     width: '400px',
    //     data: data
    //   })
    // }


    addEvent(selectInfo: DateSelectArg) {
      let data = {
        start: selectInfo.start,
        groupId: this.calendarId
      };
      const calendarApi = selectInfo.view.calendar;
      calendarApi.unselect();
    
      const dialogRef = this.dialog.open(AddHolidayComponent, {
        width: '400px',
        data: data
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Fetch the updated calendar data after the new holiday is created
          // this.leaveservice.getCalendarById(this.calendarId).subscribe(
          //   (d: any) => {
          //     const events = [];
              
          //     // Check if holidays are empty
          //     if (d.holiday == '') {
          //       events.push({ id: '', title: '', start: '', end: '', groupId: '' });
          //     } else {
          //       d.holiday.forEach((e) => {
          //         let MyDate = new Date(e.holidayDate);
          //         let MyDateString = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + MyDate.getDate()).slice(-2)}`;
      
          //         if (e.holidayStatus == 1) {
          //           events.push({
          //             id: e.holidayId,
          //             title: e.holidayName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.holidaytype),
          //             color: '#00BB1E',
          //           });
          //         } else if (e.holidayStatus == 2) {
          //           events.push({
          //             id: e.holidayId,
          //             title: e.holidayName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.holidaytype),
          //             color: '#00bb1e6b',
          //           });
          //         }
          //       });
          //     }
    
          //     // Check if exceptions are empty
          //     if (d.exception == '') {
          //       events.push({ id: '', title: '', start: '', end: '', groupId: '' });
          //     } else {
          //       d.exceptions.forEach((e) => {
          //         let MyDate = new Date(e.exceptionDate);
          //         let MyDateString = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + MyDate.getDate()).slice(-2)}`;
      
          //         if (e.exceptionStatus == 1) {
          //           events.push({
          //             id: e.exceptionId,
          //             title: e.exceptionName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.exceptionType),
          //             color: '#40E0D0',
          //           });
          //         } else if (e.exceptionStatus == 2) {
          //           events.push({
          //             id: e.exceptionId,
          //             title: e.exceptionName,
          //             start: MyDateString,
          //             end: MyDateString,
          //             groupId: Number(e.exceptionType),
          //             color: '#40e0d070',
          //           });
          //         }
          //       });
          //     }
    
          //     // Update the calendar events without page reload
          //     this.calendarOptions.events = events;
          //     this.arrayData = this.calendarOptions.events;
    
          //     // Handling working days
          //     let b = ["1", "2", "3", "4", "5", "6", "0"];
          //     // this.workArray = []; // Reset workArray
    
          //     if (d.calendarWorkingDays != '') {
          //       d.calendarWorkingDays.map(dot => {
          //         if (dot.workingdays != '') {
          //           const workingDays = b.filter((dayCode) => {
          //             const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
          //             return dayNumber < dot.workingdays;
          //           });
          //           dot['workingdays'] = workingDays;
    
          //           var difference = b.filter(x => dot.workingdays.indexOf(x) === -1);
          //           difference.map(a => this.workArray.push(Number(a)));
          //         }
          //       });
          //       // Update hiddenDays in calendarOptions
          //       // this.calendarOptions.hiddenDays = this.workArray;
          //     } 
          //     // else {
          //     //   this.calendarOptions.hiddenDays = [];
          //     // }
    
          //     this.loader = false;
          //   }
          // );
          this.fetchData();
        }
      });
    }
    
    
  
    // *********** Update Event ***********
    // updateEvent(clickInfo) {
    //   console.log(clickInfo);
    //   console.log(clickInfo.event.groupId)
    //   this.leaveservice.getListTypeById(5).subscribe(
    //     (d: any) => {
    //       console.log("d5",d);
    //       d.map(
    //         e => {
    //           if (clickInfo.event.groupId == e.listTypeValueId) {
    //             let eventData;
    //             eventData = {
    //               id: clickInfo.event.id,
    //               title: clickInfo.event.title,
    //               start: clickInfo.event.start,
    //               groupId: this.calendarId,
    //               holidayType: clickInfo.event.groupId,
    //             }
  
    //             const dialogRef = this.dialog.open(AddHolidayComponent, {
    //               width: '400px',
    //               data: eventData
    //             })
  
    //           }
    //         }
    //       )
  
    //     }
    //   )
  
    //   this.leaveservice.getListTypeById(6).subscribe(
    //     (d: any) => {
    //       console.log("d6",d);
    //       d.map(
    //         e => {
    //           if (clickInfo.event.groupId == e.listTypeValueId) {
    //             let eventData;
    //             eventData = {
    //               id: clickInfo.event.id,
    //               title: clickInfo.event.title,
    //               start: clickInfo.event.start,
    //               groupId: this.calendarId,
    //               holidayType: clickInfo.event.groupId,
    //             }
  
    //             const dialogRef = this.dialog.open(ExceptionholidayleaveComponent, {
    //               width: '400px',
    //               data: eventData
    //             })
  
    //           }
    //         }
    //       )
    //     }
    //   )
    // }

    updateEvent(clickInfo) {
      console.log(clickInfo);
      console.log(clickInfo.event.groupId);
    
      // Function to handle event update logic
      const handleEventUpdate = (eventData, component) => {
        const dialogRef = this.dialog.open(component, {
          width: '400px',
          data: eventData
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            // Fetch the updated calendar data after the event is updated
            // this.leaveservice.getCalendarById(this.calendarId).subscribe(
            //   (d: any) => {
            //     const events = [];
    
            //     // Populate holidays
            //     if (d.holiday == '') {
            //       events.push({ id: '', title: '', start: '', end: '', groupId: '' });
            //     } else {
            //       d.holiday.forEach((e) => {
            //         let MyDate = new Date(e.holidayDate);
            //         let MyDateString = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + MyDate.getDate()).slice(-2)}`;
        
            //         if (e.holidayStatus == 1) {
            //           events.push({
            //             id: e.holidayId,
            //             title: e.holidayName,
            //             start: MyDateString,
            //             end: MyDateString,
            //             groupId: Number(e.holidaytype),
            //             color: '#00BB1E',
            //           });
            //         } else if (e.holidayStatus == 2) {
            //           events.push({
            //             id: e.holidayId,
            //             title: e.holidayName,
            //             start: MyDateString,
            //             end: MyDateString,
            //             groupId: Number(e.holidaytype),
            //             color: '#00bb1e6b',
            //           });
            //         }
            //       });
            //     }
    
            //     // Populate exceptions
            //     if (d.exception == '') {
            //       events.push({ id: '', title: '', start: '', end: '', groupId: '' });
            //     } else {
            //       d.exceptions.forEach((e) => {
            //         let MyDate = new Date(e.exceptionDate);
            //         let MyDateString = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + MyDate.getDate()).slice(-2)}`;
        
            //         if (e.exceptionStatus == 1) {
            //           events.push({
            //             id: e.exceptionId,
            //             title: e.exceptionName,
            //             start: MyDateString,
            //             end: MyDateString,
            //             groupId: Number(e.exceptionType),
            //             color: '#40E0D0',
            //           });
            //         } else if (e.exceptionStatus == 2) {
            //           events.push({
            //             id: e.exceptionId,
            //             title: e.exceptionName,
            //             start: MyDateString,
            //             end: MyDateString,
            //             groupId: Number(e.exceptionType),
            //             color: '#40e0d070',
            //           });
            //         }
            //       });
            //     }
    
            //     // Update the calendar events without page reload
            //     this.calendarOptions.events = events;
            //     this.arrayData = this.calendarOptions.events;
    
            //     // Handling working days
            //     let b = ["1", "2", "3", "4", "5", "6", "0"];
            //     // this.workArray = []; // Reset workArray
    
            //     if (d.calendarWorkingDays != '') {
            //       d.calendarWorkingDays.map(dot => {
            //         if (dot.workingdays != '') {
            //           const workingDays = b.filter((dayCode) => {
            //             const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
            //             return dayNumber < dot.workingdays;
            //           });
            //           dot['workingdays'] = workingDays;
    
            //           var difference = b.filter(x => dot.workingdays.indexOf(x) === -1);
            //           difference.map(a => this.workArray.push(Number(a)));
            //         }
            //       });
            //       // Update hiddenDays in calendarOptions
            //       // this.calendarOptions.hiddenDays = this.workArray;
            //     } 
            //     // else {
            //     //   this.calendarOptions.hiddenDays = [];
            //     // }
    
            //     this.loader = false;
            //   }
            // );
            this.fetchData();
          }
        });
      };
    
      // Fetch the necessary list types and open the appropriate dialog based on groupId
      this.leaveservice.getListTypeById(5).subscribe((d: any) => {
        d.forEach(e => {
          if (clickInfo.event.groupId == e.listTypeValueId) {
            let eventData = {
              id: clickInfo.event.id,
              title: clickInfo.event.title,
              start: clickInfo.event.start,
              groupId: this.calendarId,
              holidayType: clickInfo.event.groupId,
            };
            handleEventUpdate(eventData, AddHolidayComponent);
          }
        });
      });
    
      this.leaveservice.getListTypeById(6).subscribe((d: any) => {
        d.forEach(e => {
          if (clickInfo.event.groupId == e.listTypeValueId) {
            let eventData = {
              id: clickInfo.event.id,
              title: clickInfo.event.title,
              start: clickInfo.event.start,
              groupId: this.calendarId,
              holidayType: clickInfo.event.groupId,
            };
            handleEventUpdate(eventData, ExceptionholidayleaveComponent);
          }
        });
      });
    }
    
  } 
