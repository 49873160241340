import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ExcelService } from 'src/app/services/excel.service';
import { ExportExcelService} from 'src/app/services/export-excel.service';
import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { HttpClient } from '@angular/common/http';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { EmployeeService } from 'src/app/providers/employee.service';

@Component({
  selector: 'app-consolidatereport',
  templateUrl: './consolidatereport.component.html',
  styleUrls: ['./consolidatereport.component.scss']
})
export class ConsolidatereportComponent implements OnInit {
  isProcessing = false;

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  totalamountbeforededuction: number= 0;
  totalnetpay: number = 0;
  totalDedution: number = 0;
  dataForExcel = []; 
  base64data: string | ArrayBuffer | null = null;
  headerForExcel = [
    {'Employee Code':'', 'Employee Name':'', 'Account Number':'', 'IFSC Code':'', 'Bank Name':'' ,  'Bank Branch Name':'', 'Period':'', 'CTC ':'' , 'Amount Before Deduction ':'' ,
    'Deduction ':'' , 'Net Pay':''}
  ];
  period: string = "";
  employeeName: any = "";
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  fileblob: string;
  chooseFinId1: any;
  storePeriod:any

  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any , private ExportExcelService  : ExportExcelService , 
    private superAminservice :SuperadminService , private EmployeeService : EmployeeService, private http: HttpClient) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.clientId = this.usertData.employee?.client?.clientId;
    console.log(this.clientId);

    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null],
      period:[null],
    })


    if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });
  }

  if(this.clientId){
    this.settingsService.getFinicalyearActiveListbyClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      this.finYearList = Array.isArray(resultData) ? resultData: [resultData];
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });
  }


  if(this.orgId){
    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }
  if(this.clientId){
    this.settingsService.getClientdetails(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }


  // if(this.orgId){
  //   this.superAminservice.getOrganizationById(this.orgId).subscribe(
  //     (resultData:any) =>{
  //     this.resultData = resultData;
  //     console.log(this.resultData);
  //     if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
  //       console.log(this.resultData.organizationAttachment.fileViewUrl);
  //       this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
  //        (blob) => {
  //           const a = document.createElement('a')
  //           const objectUrl: any = blob
  //           this.fileblob = blob;
  //           this.http.get(this.fileblob, { responseType: 'blob' })
  //           .subscribe(res => {
  //             var reader = new FileReader();
  //             reader.onloadend = () => {
  //               this.base64data = reader.result;                
  //                 // console.log(this.base64data);
  //             }
  //             reader.readAsDataURL(res); 
  //             // console.log(res);
  //           });
  //         },
  //         err =>{
  //           this.fileblob= "assets/uploads/no-image.png"
  //           console.log("else",this.fileblob);
  //           this.http.get(this.fileblob, { responseType: 'blob' })
  //           .subscribe(res => {
  //             const reader = new FileReader();
  //             reader.onloadend = () => {
  //               this.base64data = reader.result;                
  //                 // console.log(this.base64data);
  //             }
  //             reader.readAsDataURL(res); 
  //             // console.log(res);
  //           });
  //         }
  //       );
  //     }
  //     else{
  //       this.fileblob= "assets/uploads/no-image.png";
  //       console.log("else",this.fileblob);
  //       this.http.get(this.fileblob, { responseType: 'blob' })
  //       .subscribe(res => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           this.base64data = reader.result;                
  //             // console.log(this.base64data);
  //         }
  //         reader.readAsDataURL(res); 
  //         // console.log(res);
  //       });
  //     }
  //   }
  //   )
  // }

  // if(this.clientId){
  //   this.superAminservice.getClientById(this.clientId).subscribe(
  //     (resultData:any) =>{
  //     this.resultData = resultData;
  //     console.log(this.resultData);
  //     if(this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl !=undefined){
  //       console.log(this.resultData.organizationAttachment.fileViewUrl);
  //       this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
  //        (blob) => {
  //           const a = document.createElement('a')
  //           const objectUrl: any = blob
  //           this.fileblob = blob;
  //           this.http.get(this.fileblob, { responseType: 'blob' })
  //           .subscribe(res => {
  //             const reader = new FileReader();
  //             reader.onloadend = () => {
  //               this.base64data = reader.result;                
  //                 // console.log(this.base64data);
  //             }
  //             reader.readAsDataURL(res); 
  //             // console.log(res);
  //           });
  //         },
  //         err =>{
  //           this.fileblob= "assets/uploads/no-image.png"
  //           console.log("else",this.fileblob);
  //           this.http.get(this.fileblob, { responseType: 'blob' })
  //           .subscribe(res => {
  //             const reader = new FileReader();
  //             reader.onloadend = () => {
  //               this.base64data = reader.result;                
  //                 // console.log(this.base64data);
  //             }
  //             reader.readAsDataURL(res); 
  //             // console.log(res);
  //           });
  //         }
  //       );
  //     }
  //     else{
  //       this.fileblob= "assets/uploads/no-image.png";
  //       console.log("else",this.fileblob);
  //       this.http.get(this.fileblob, { responseType: 'blob' })
  //       .subscribe(res => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           this.base64data = reader.result;                
  //             // console.log(this.base64data);
  //         }
  //         reader.readAsDataURL(res);

  //         // console.log(res);
  //       });
  //     }
  //   }
  //   )
  // }

  }


  addpayroll()
  {
   
    this.router.navigateByUrl('/home/employee-payroll');
  }


  searchSubmit()
  {
    this.resultData =[];

    this.isProcessing=true;
    this.loader=true;
    this.noRecordsFound = false;

    console.log("submit");
    this.req=false;
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   // alert('Please enter employee number');
    //   return;
    // }
    console.log(this.filterForm.value);
    console.log("this.filterForm.value.employeecode" + this.filterForm.value.employeecode);
    console.log("this.filterForm.value.financialyear" + this.filterForm.value.financialyear);
    console.log("this.filterForm.value.period" + this.filterForm.value.period);
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period === 'null')){
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = false;
      alert("Please Enter Employee number");
      this.isProcessing=false;
      this.loader=false;

      // this.req=true;
    }
    // if(((this.filterForm.value.employeecode!=''||this.filterForm.value.employeecode!=null)&&this.filterForm.value.financialyear==null)||(this.filterForm.value.employeecode==''||this.filterForm.value.employeecode==null)&&this.filterForm.value.financialyear!=null)
    // {
    //   this.req=false;
    // }
   else if((this.filterForm.value.employeecode) && ( this.filterForm.value.financialyear) && (this.filterForm.value.period ))
    {
      console.log("both");

      if(this.orgId){
      this.payrollservice.getconsolidateEmployeecodeandperiodOrg(this.filterForm.value.employeecode,this.filterForm.value.period,this.filterForm.value.financialyear,this.orgId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.calucatetotal();
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else
        {
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
           this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }

    if(this.clientId){
      this.payrollservice.getconsolidateEmployeecodeandperiodClient(this.filterForm.value.employeecode,this.filterForm.value.period,this.filterForm.value.financialyear,this.clientId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.calucatetotal();
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else
        {
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
           this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }
    }
    
    else if((!this.filterForm.value.employeecode) && (this.filterForm.value.financialyear) && (this.filterForm.value.period  ) )
    {
      console.log("finactical and period");

      if(this.orgId){
      this.payrollservice.getconsolidateperiodOrg(this.filterForm.value.period,this.filterForm.value.financialyear,this.orgId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
          this.employeeName = "";

        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }
    if(this.clientId){
      this.payrollservice.getconsolidateperiodClient(this.filterForm.value.period,this.filterForm.value.financialyear,this.clientId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
          this.employeeName = "";

        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }
    }
    else if((this.filterForm.value.employeecode)&&(this.filterForm.value.financialyear) && (!this.filterForm.value.period  ) )
    {
      console.log("finatical and empcode");
      this.payrollservice.getconsolidatebyemployeeandfinicalyear(this.filterForm.value.employeecode , this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear
          + " to "  + this.resultData[0].finanicalPeriod.finanicalYear.financialToMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialToYear

           this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }else if((this.filterForm.value.employeecode)&&(!this.filterForm.value.financialyear) && (!this.filterForm.value.period))
    {
      console.log("Employeecode only");

      if(this.orgId){
      this.payrollservice.getconsolidatebyEmployeecodebyOrg(this.filterForm.value.employeecode,this.orgId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;

        this.resultData = resultData;
        console.log(this.resultData);
         this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else
        {
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
          + " to "  + this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear

           this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }
    if(this.clientId){
      this.payrollservice.getconsolidatebyEmployeecodebyClient(this.filterForm.value.employeecode,this.clientId).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;

        this.resultData = resultData;
        console.log(this.resultData);
         this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else
        {
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
          + " to "  + this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear

           this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode  +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }
    } else if((!this.filterForm.value.employeecode )&&( this.filterForm.value.financialyear ) &&  ( ! this.filterForm.value.period ))
    {
      console.log("finical year only");
      this.payrollservice.getconsolidatebyfinicalyear(this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.noRecordsFound = false;

        this.resultData = resultData;
        console.log(this.resultData);
         this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
          console.log("no data came here1!!")

        }
        else
        {
          this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear
          + " to "  + this.resultData[0].finanicalPeriod.finanicalYear.financialToMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialToYear
          this.employeeName = "";
          // this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode +" " + this.resultData[0].employee.firstName+' '+this.resultData[0].employee.lastName
        }
        if( resultData.statusCode == 204){
          console.log("no data came here2!!")
          this.noRecordsFound = true;
          this.loader=false;
          this.resultData=[]
        }
        this.isProcessing=false;
        this.loader=false;


      })
    }
  }


  public downloadAsExcel()
  {
    this.dataForExcel.length = 0;
  for(let e of this.resultData)
  {
    let data= {
         employeeNumber:e.employee.employeeNumber,
         firstName:e.employee.firstName,
         bankAccountNo :  e.employee.employeeInfo?.bankAccountNo,
         ifscCode :  e.employee.employeeInfo?.ifscCode,
         bankName:   e.employee.employeeInfo?.bankName,
         bankBranchName :e.employee.employeeInfo?.bankBranchName,
         Period :   e.finanicalPeriod.financialperiodMonth + "-" +   e.finanicalPeriod.financialPeriodYear,
         ctc : e.employeeAnnexure.annexureCtc / 12,
         totalAmountbeforededuction :  e.totalAmount?.toFixed(2) ,
         totalDeduction:    e.totalDeduction?.toFixed(2) ,
         totalAmount:   Math.round(e.totalAmount - e.totalDeduction) 
      }
   this.dataForExcel.push(Object.values(data))
   console.log(this.dataForExcel);
  }


  let data= {
    employeeNumber:"",
         firstName : "",
         bankAccountNo : "",
         ifscCode :"",
         bankName:  "",
         bankBranchName :"",
         Period :  "",
         ctc :  "Total",
    totalAmountbeforededuction : this.totalamountbeforededuction?.toFixed(2) ,
    totalDeduction:    this.totalDedution?.toFixed(2) ,
    totalAmount:   Math.round(this.totalnetpay)
 }
this.dataForExcel.push(Object.values(data))

   let reportDatabyrecruiter = {
     title: 'Consolidated Report',
     exceldate: "Period : " +  this.period,
     data: this.dataForExcel,
     employeeName :this.employeeName,
     headers: Object.keys(this.headerForExcel[0])
   }
   console.log(reportDatabyrecruiter);
   this.ExportExcelService.exportExcel(reportDatabyrecruiter);
  }

  clearTable()
  {
    // this.resultData.length = 0;
    this.resultData=[];
    this.noRecordsFound = false;
    this.filterForm.clearValidators;
    this.finPeriodList=[];

  }

  calucatetotal()
  {
  
      this.totalamountbeforededuction =  0;
    this.totalnetpay = 0;
    this.totalDedution = 0;
     if(this.resultData.length != undefined )
     {
      this.resultData.forEach(element => {
        // console.log("element.employeeAnnexure.ctc" + element.employeeAnnexure.ctc)
         console.log("this.totalDedution" +this.totalDedution);
        this.totalamountbeforededuction += element.totalAmount;
        this.totalnetpay += element.totalAmount -  element.totalDeduction;
        this.totalDedution +=  element.totalDeduction;
        console.log("this.totalDedution",this.totalDedution)
    });
    console.log("this.resultData",this.resultData);
     }
   
  }

  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear()
  {
    this.filterForm.get('financialyear').value;
    this.chooseFinId= this.filterForm.get('financialyear').value;
      if( this.chooseFinId == null || this.chooseFinId == 'null' )
      {
        this.filterForm.patchValue({
        
          financialyear:null,
        
        })
      }else{
        console.log("Select");
        // console.log(event.target.value);
        // console.log(this.filterForm.value.financialyear);
         this.chooseFinId= this.filterForm.get('financialyear').value;

        console.log(this.chooseFinId);
        this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
          console.log("PeriodData", resultData)

          // resultData.forEach((period:any)=>{
          //   this.storePeriod=period.finanicalPeriod
            
          // })
              this.finPeriodList = resultData.finanicalPeriod;
              console.log("this.finPeriodList ",this.finPeriodList );
              
          // this.finPeriodList = this.storePeriod
          // this.finPeriodList = Array.isArray( this.storePeriod) ?  this.storePeriod: [ this.storePeriod];
        });
      }
   
  }

  selectperiod()
  {
    this.filterForm.get('period').value;
    this.chooseFinId1= this.filterForm.get('period').value;
    if(this.chooseFinId1 == 'null')
    {
      console.log("null perios")
      this.filterForm.patchValue({
      
        period:null,
      
      })
    }
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.ratingId=row.employeerattingId;
    console.log(this.ratingId);
    // this.router.navigateByUrl('/home/edit-employee-rating/',row.employeerattingId);
    this.router.navigate(['/home/view-employee-payroll/', this.ratingId])
  }

  showmontlyctc(ctcamount){

    ctcamount = ctcamount / 12 
   return  ctcamount.toFixed(2);
  }

  Roundof(amount){
    return Math.round(amount);
  }
  printPdf()
  {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML); // Add this line for debugging

    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    console.log(pdfTable.innerHTML); // Add this line for debugging

    const documentDefinition = { 
      content: html ,
      pageMargins: [ 25, 40, 25, 40 ],
      info: {
        title:`Consolidate Report`
        },
        background: function (currentPage, pageCount) {
          console.log('currentPage:', currentPage);
          console.log('pageCount:', pageCount);
      
          return {
              text: 'Page ' + currentPage.toString(),
              alignment: 'center',
              margin: [0, 8],
              fontSize: 10,
          };
         },
        pageSize: 'A4',
        pageOrientation : 'landscape' 
    };
    console.log(pdfTable);
    // pdfMake.createPdf(documentDefinition).open(); 
    pdfMake.createPdf(documentDefinition).download("Consolidated Report"); 
  }
}
