<mat-card class="header-card" style="margin-top: 66px !important">
  <!-- <div style="margin-top: 3px" class="main-content"></div> -->
  <!-- <div class="container-fluid"> -->
  <div class="top-content row  mt-2  m-2">
    <!-- <div
      class="col-md-5 col-sm-12"
      *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'"
    >
      <h4 class="timesheet">Timesheet</h4>
    </div> -->
    <!-- *ngIf="User_Type == 'HumanResource' || User_Type == 'Manager'" -->
    <div class="col-xl-5 col-md-12 col-lg-2 col-sm-12 col-xs-12 ">
      <h4 class="heading-tag">Timesheet</h4>
    </div>

    <div class="col-xl-2 col-md-12 col-lg-2 col-sm-12 col-xs-12">
      <!-- <ul class="unstyled inbox-pagination"> -->
      <!-- <div class="sidebar-search"> -->
      <!-- <div class="input-group"> -->
      <input
        type="input"
        class="form-control search-menu search-placeholder"
        placeholder="Search..."
        [(ngModel)]="search"
        (keyup)="changepage(); changePlaceholderColor($event)"
        name="searchText"
      />
    </div>
    <div class="col-xl-2 col-md-12 col-lg-2 col-md-2 col-sm-4 col-xs-12 add-timesheet">
      <button
        type="button"
        mat-raised-button
        class="searchBtn "
        (click)="updateTime()"
      >
        <span>Add TimeSheet</span>
      </button>
    </div>
    </div>
  <!-- </div> -->

</mat-card>

<mat-card class="time-mat-card">
  <div style="margin-top: 20px" class="main-content">
    <div class="container-fluid">
      <!-- <div class="search-content"> -->
      <form #myForm="ngForm">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <label class="form-control-label">Project</label>
            <mat-select
              id="framework"
              (click)="this.search = ''"
              style="height: 38px; margin-left: -3px"
              class="form-control email-input select-placeholder"
              [(ngModel)]="projectId"
              [ngModelOptions]="{ standalone: true }"
              (selectionChange)="getTask(); this.search = ''"
              placeholder="Select Project"
            >
              <mat-option value="undefined" (click)="selectProject()"
                >-Select Project-</mat-option
              >
              <mat-option
                *ngFor="let pro of projectList"
                [value]="pro.projectId"
              >
                {{ pro.projectName }}
              </mat-option>
            </mat-select>
            <span class="error_msg" *ngIf="project_required"
              >Please&nbsp;select&nbsp;project</span
            >
          </div>
          <div class="col-md-3 col-lg-3">
            <label class="form-control-label">Task</label>
            <mat-select
              id="framework"
              style="height: 38px; margin-left: -3px"
              class="form-control email-input select-placeholder"
              (selectionChange)="this.search = ''"
              [(ngModel)]="taskId"
              [ngModelOptions]="{ standalone: true }"
              (click)="this.search = ''"
              placeholder="Select Task"
              [disabled]="task_disable"
            >
              <mat-option value="0" (click)="taskPending()"
                >-Select Task-</mat-option
              >
              <mat-option *ngFor="let task of taskList" [value]="task.taskId">
                {{ task.taskName }}
              </mat-option>
            </mat-select>
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2">
            <label class="form-control-label">Start Date</label>
            <div style="position: relative">
              <input
                placeholder="Start Date"
                readonly
                [matDatepicker]="picker1"
                class="form-control email-input select-placeholder text-truncate"
                style="height: 38px; padding: 2px; margin-left: -2px"
                [(ngModel)]="startDate"
                [ngModelOptions]="{ standalone: true }"
                type="text"
                [max]="today"
                (dateChange)="updateEndDateMin($event)"
                (click)="this.search = ''"
                id="startDate"
              />
              <span
                class="error_msg"
                *ngIf="startdate_required"
                style="position: absolute; top: 100%; left: 0; width: 100%"
                >Please&nbsp;select&nbsp;start&nbsp;date</span
              >
              <mat-datepicker-toggle
                matSuffix
                (click)="this.search = ''"
                [for]="picker1"
                style="
                  float: right;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  margin-right: -3px;
                "
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </div>
          </div>

          <div class="col-md-3 col-lg-3 col-xl-2">
            <label class="form-control-label">End Date</label>
            <div style="position: relative">
              <input
                placeholder="End Date"
                readonly
                [matDatepicker]="picker2"
                class="form-control email-input select-placeholder text-truncate"
                style="
                  height: 38px;
                  padding: 2px;
                  margin-left: -2px;
                  background: #fff;
                "
                [(ngModel)]="endDate"
                [ngModelOptions]="{ standalone: true }"
                type="text"
                [min]="startDate"
                [max]="today"
                (dateChange)="this.search = ''; updateEndDateMin($event)"
                (click)="this.search = ''; endSelectedFirst = true"
                id="endDate"
              />
              <span
                class="error_msg"
                *ngIf="enddate_required"
                style="position: absolute; top: 100%; left: 0; width: 100%"
                >Please&nbsp;select&nbsp;end&nbsp;date</span
              >
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
                (click)="this.search = ''"
                style="
                  float: right;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  margin-right: -3px;
                "
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </div>
          </div>

          <!-- Alert for invalid date range -->
          <!-- <div *ngIf="isStartDateGreaterThanEndDate()" class="text-right">
            <div class="alert alert-danger d-inline-block">Start date cannot be greater than end date.</div>
          </div> -->

          <div class="col-md-6 col-lg-2 col-xl-2 button-align mt-3 ml-n3">
            <div class="col-md-6 col-lg-2 col-xl-2 button-align mt-2">
              <button
                mat-raised-button
                type="button"
                class="searchBtnTime"
                (click)="this.search = '';searchTimesheet();    "
                [disabled]="searchButton == false"
              >
                Search
              </button>
              <!-- <button  *ngIf="!nodata"  type="button" class="btn searchBtn" style="margin-left:10px" (click)="exportexcel()">Export</button> -->

              <div class="mt-md-0 mt-2 margin-mobile">
                <button
                  mat-raised-button
                  [disabled]="!searchButton"
                  type="button"
                  class="searchBtn ml-2"
                  (click)="resetForms()"
                  style="
                    background: none;
                    color: black;
                    border: 1px solid #ddd;
                    font-size: 16px;
                    font-weight: 610;
                    margin-left: 5px;
                  "
                >
                  Clear
                </button>
              </div>
              <div class="mt-md-0 mt-2 margin-mobile">
                <button
                  mat-button
                  [matMenuTriggerFor]="menu"
                  *ngIf="!nodata"
                  [disabled]="!searchButton || nodata || EmpList.length == 0"
                  class="btn-small searchBtn menu-button"
                  style="margin-left: 10px"
                >
                  <mat-icon class="menu">menu</mat-icon>
                </button>
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="exportexcel()">
                  <!-- <mat-icon>file_download</mat-icon> -->
                  <span>Export</span>
                </button>
                <!-- *ngIf="EmpList.weeklySubmit == true" -->
                <button
                  mat-menu-item
                  (click)="submitToApproval()"
                  *ngIf="EmpList.weeklySubmit == true"
                  [disabled]="filteredEmpList?.length === 0"
                >
                  <!-- <mat-icon>send</mat-icon> -->
                  <span>Submit to approval</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </form>
      <!-- </div> -->
      <!-- *ngIf="EmpList.length > 0" -->
      <div class="card-content table-content mt-4" *ngIf="tableShow">
        <div class="row p-0 m-0">
          <div class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9 text-right">
            <ng-container *ngIf="EmpList.length > 0">
              <span class="badge badge-light totaltime"
                >Total Time : {{ tasktotalTime }}</span
              >
            </ng-container>
            <!-- <ng-template #alignend *ngIf="EmpList.length > 5">
              <span class="badge badge-light totaltime"
                >Total Time : {{ tasktotalTime }}</span
              >
            </ng-template> -->
          </div>

          <!-- *ngIf="EmpList.length > 5" -->
          <div
            class="col-xl-2 col-lg-3 col-md-3 col-sm-4 text-right"
            *ngIf="EmpList.length > 5 && (EmpList | mytask : search).length > 0"
          >
            <div
              class="select-pagination"
              *ngIf="(EmpList | mytask : search).length > 5"
            >
              <span>Show : </span>
              <select
                class="select_list new-select_list listDesign"
                [(ngModel)]="mySelect"
                (change)="changefirstpage()"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>

        <div class="table-responsive-new1 lg-mt-3 md-mt-3">
          <table matSort (matSortChange)="sortData($event)" class="table">
            <thead>
              <th
                mat-sort-header="sNo"
                style="margin-left: 5px !important"
                style="text-align: center"
              >
                <div><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div></div>
                &nbsp;&nbsp;S.No
              </th>
              <th mat-sort-header="projectName">
             &nbsp;&nbsp;&nbsp;Project&nbsp;Name&nbsp;&nbsp;&nbsp;
              </th>
              <th mat-sort-header="taskName">&nbsp;&nbsp;&nbsp;&nbsp;Task&nbsp;Name</th>
              <th mat-sort-header="date" style="text-align: center">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </th>
              <th mat-sort-header="workedHours" style="text-align: center">
                Worked&nbsp;Hours
              </th>

              <th mat-sort-header="taskStatusName" style="text-align: center">
                Task&nbsp;Status
              </th>
              <th
                mat-sort-header="approvalStatusName"
                style="text-align: center"
              >
                Approval&nbsp;Status
              </th>
              <th
                mat-sort-header="taskDetailStatusName"
                style="text-align: center"
              >
                TimeSheet&nbsp;Status
              </th>
              <th mat-sort-header="action" style="text-align: center">
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                &nbsp;&nbsp; Action
              </th>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let entry of EmpList
                    | timelog : search
                    | paginate : { itemsPerPage: mySelect, currentPage: p };
                  let i = index
                "
              >
                <td style="text-align: center" *ngIf="p">
                  {{ (p - 1) * mySelect + (i + 1) }}
                </td>
                <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
                <td style="
                white-space: normal;
                overflow-wrap: break-word;
                word-break: normal;
                min-width: 150px !important;
              ">  
                  {{
                    entry.project.projectName
                  }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td
                  style="
                    white-space: normal;
                    overflow-wrap: break-word;
                    word-break: normal;
                    min-width: 250px !important;
                  "
                >
                  {{ entry.task.taskName }}
                </td>
                <td style="text-align: center; padding-right: 23px">
                  {{ entry.date }}
                </td>

                <td style="text-align: center; padding-right: 30px">
                  {{ entry.workedHours }}
                </td>

                <td style="padding-right: 30px">
                  <p class="status" [style.color]="withDrawStatus(entry)">
                    {{ entry?.task?.taskStatusName }}
                  </p>
                </td>
                <td style="padding-right: 30px">
                  <p class="status" [style.color]="approvalStatus(entry)">
                    {{ entry?.approvalStatusName }}
                  </p>
                </td>

                <td style="padding-right: 30px">
                  <p class="status" [style.color]="approvalStatusTask(entry)">
                    {{ entry?.taskDetailStatus?.listTypeValueName }}
                  </p>
                </td>
                <td class="more" align="center">
                  <span
                    class="material-icons cursor-pointer"
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    more_vert
                  </span>
                  <mat-menu #menu="matMenu">
                    <button
                      class="mat-menu-item"
                      mat-menu-item
                      (click)="viewapprover(entry)"
                    >
                      <mat-icon class="daterange-icon">date_range</mat-icon>
                      <span class="view-approver">View Approver</span>
                    </button>

                    <button
                      class="mat-menu-item"
                      mat-menu-item
                      (click)="edittimesheet(entry)"
                      [disabled]="!entry?.update"
                    >
                      <mat-icon class="edit-icon">edit</mat-icon>
                      <span class="edit-form">Edit</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    text-align: center;
                    font-size: larger;
                    font-weight: bold;
                  "
                  colspan="9"
                  *ngIf="
                    nodata ||
                    ((EmpList | mytask : search).length === 0 && search) ||
                    EmpList.length == 0
                  "
                >
                  Timesheet Not Found!
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="pagination-content"
            *ngIf="EmpList.length > 5 && (EmpList | mytask : search).length > 0"
            id="page"
          >
            <pagination-controls
              *ngIf="(EmpList | mytask : search).length > 5"
              style="position: relative; float: inline-end"
              (pageChange)="p = $event"
              align="end"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="width: 3rem; height: 3rem"></div>
</div>

<div class="text-center successUpdate" *ngIf="success" #successMessageElement>
  <div class="alert alert-success toast-msg successUpdate" role="alert">
    <strong>{{ successMessage }}</strong
    ><br />
  </div>
</div>

<div class="text-center successUpdate" *ngIf="successUpdate">
  <div class="alert alert-success toast-msg successUpdate" role="alert">
    <strong>{{ successUpdateMessage }}</strong
    ><br />
  </div>
</div>

<div class="text-center successUpdate" *ngIf="warning">
  <div class="alert alert-warning toast-msg successUpdate" role="alert">
    <strong>{{ warningMessage }}</strong
    ><br />
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
<!-- <mat-card class="card-content-nodata mat-card-nodata" style="margin-top: 30px !important;" *ngIf="nodata || nodataDates">
  <div class="card-body-nodata">
    <div class="alert-nodata alert-primary">
      <h4 align="center" class="h4">Timesheet Not Found</h4>
    </div>
  </div>
</mat-card> -->
