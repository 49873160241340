import {
 

  httpOptions,
  httpOptionsHrms,
  httpOptionsReimbursement,
  httpOptionsTimeEntry,
  httpleaveOptions,
  
} from './properties';
import { ConsolidatePayslipComponent } from '../reports/consolidate-payslip/consolidate-payslip.component';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { globalTimeOut } from './properties';
import { Observable, ObservableInput, Subject } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { map, catchError, timeout } from 'rxjs/operators';
// import { ErrorObservable } from "rxjs/observable/ErrorObservable";
// import { ErrorObserver } from 'rxjs';
// import { HttpErrorResponse } from '@angular/common/http/src/response';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectManagementService {
  organzationid: any;

  @Output() collapsedEvent = new EventEmitter<boolean>();
  handleError: (err: any, caught: Observable<Object>) => ObservableInput<any>;

  constructor(private http: HttpClient) {
    const data = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('localStorage Data', data);
    if (data != undefined) {
      this.organzationid = data.organization.organizationId;
      console.log('organzationid:::' , this.organzationid);
    }
  }
  collapsed = false;
  dataId: any;
  toggleCollapsed() {
    this.collapsed = !this.collapsed;
    return this.collapsedEvent.emit(this.collapsed);
  }

  // getcatogory() {
  //   return this.http.get(`http://${this.organzationid}92.${this.organzationid}68.${this.organzationid}.${this.organzationid}60/vasantabhavan_ae/api/BranchCategorys/20`)
  //     .pipe(map(res => res), timeout((globalTimeOut)));
  // }

  //---------------------getmethod sample---------------------------------
  // userType

  getclientlist(organizationId) {
    return this.http.get(`${environment.base_url}v2/client/active/organizationId/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getUserType() {
    return this.http.get(`${environment.time_entry_url}user_type`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getApprovals(id:number): Observable<any> {
    return this.http.get(`${environment.base_url}timeEntry/approvals/TASKDETAIL/${id}`, httpOptionsHrms).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  // http://38.242.135.217:6015/api/enote/hrms/timeEntry/approvals/TASKDETAIL/245
  // resource+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  createResource(data): Observable<any> {
    return this.http.post(`${environment.time_entry_url}employee`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  getEmployeesBynumber(employeeNo: number) {
    return this.http
      .get(
        `${environment.base_url}employee/filter/keyword/${employeeNo}/${this.organzationid}`,
        httpOptionsHrms
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskCount() {
    return this.http.get(`${environment.time_entry_url}count/task_by_organization/${this.organzationid}`,httpOptionsTimeEntry)
  }

  getPendingTaskCount(employeeId:number){
    return this.http.get(`${environment.time_entry_url}employee_task_by_employee/count/${employeeId}`,httpOptionsTimeEntry);
  }

  getTaskCountByEmployee(employeeId:number){
    return this.http.get(`${environment.time_entry_url}count/task_by_empolyee/${employeeId}`,httpOptionsTimeEntry);
  }

  // http://38.242.135.217:6022/api/enote/timeentry/count/task_by_organization/1
  getTaskByDateAndTaskStatus( taskstatus :any,startDate: any, endDate: any ) {
    return this.http.get(`${environment.time_entry_url}task/taskstatus/startdate/enddate/${taskstatus}/${startDate}/${endDate}`,httpOptionsTimeEntry);
  }


  // getTaskDetailByDate(startDate: any, endDate: any) {
  //   return this.http.get(`${environment.time_entry_url}taskDetail_by_employee/report/date/${startDate}/${endDate}`,httpOptionsTimeEntry);
  // }

  getTaskDetailByDate(startDate: any, endDate: any) {
    return this.http.get(`${environment.time_entry_url}v2/taskDetail_by_employee/report/date/${startDate}/${endDate}`,httpOptionsTimeEntry);
  }



  // getTaskById(id) {
  //   return this.http.get(`${url}task_by_project/${id}`).pipe(
  //     map((res) => res),
  //     timeout(globalTimeOut)
  //   );
  // }



  // http://localhost:6022/api/enote/timeentry/task/taskstatus/startdate/enddate/31/2021-07-01/2021-07-10
  // http://192.168.18.11:6015/api/enote/hrms/employee/filter/keyword/1016/1

  getempolyeeNumber(employeeNumber: any, employeeid: any) {
    return this.http
      .get(
        `${environment.time_entry_url}employee/empolyeenumber/${employeeNumber}/${employeeid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  taskDetailByEmployees(
    projectId: number,
    approvalStatus: number,
    startDate: any,
    endDate: any
  ) {
    return this.http.get(
      `${environment.time_entry_url}taskDetail_by_employees/project/date/approvalStatus/${projectId}/${approvalStatus}/${startDate}/${endDate}`,  httpOptionsTimeEntry
    );
  }

  // getTaskDetailByProject(projectId: number, approvalStatus: number) {
  //   return this.http
  //     .get(
  //       `${environment.time_entry_url}taskDetail_by_project/approvalStatus/${projectId}/${approvalStatus}`,
  //       httpOptionsTimeEntry
  //     )
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }


  getTaskDetailByProject(projectId: number, approvalStatus: number) {
    return this.http
      .get(
        `${environment.time_entry_url}v2/taskDetail_by_project/approvalStatus/${projectId}/${approvalStatus}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  // getTaskDetailByProjectId(projectId: number) {
  //   return this.http.get(`${environment.time_entry_url}taskDetail_by_project/${projectId}`,httpOptionsTimeEntry);
  // }

  getTaskDetailByProjectId(projectId: number) {
    return this.http.get(`${environment.time_entry_url}v22/taskDetail_by_project/${projectId}`,httpOptionsTimeEntry);
  }


  getEmployeeProject(projectId: number) {
    return this.http.get(
      `${environment.time_entry_url}employee_project/project/${projectId}`,
      httpOptionsTimeEntry
    );
  }
  getempolyeeEmail(emailid: any, employeeid: any) {
    return this.http
      .get(`${environment.time_entry_url}employee/email/${emailid}/${employeeid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getprojectcode(projectCode: any, projectid: number) {
    return this.http
      .get(
        `${environment.time_entry_url}project/projectcode/${projectCode}/${projectid}/${this.organzationid}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getprojectName(projectName: any, projectid: number) {
    return this.http
      .get(
        `${environment.time_entry_url}project/projectname/${projectName}/${projectid}/${this.organzationid}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getResource(): Observable<any> {
    return this.http
      .get(
        `${environment.base_url}v2/employee/organization/active/${this.organzationid}`,
        httpOptionsHrms
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

    getActiveEmployees(id): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}v2/employee/organization/status/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  //  http://localhost:6015/api/enote/hrms/v2/employee/organization/status/1
  getResourceEmployees(): Observable<any> {
    return this.http
      .get(
        `${environment.base_url}v22/employee/organization/${this.organzationid}`,
        httpOptions
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // http://localhost:6015/api/enote/hrms/v2/employee/organization/1
  getEmp_Project(empProId): Observable<any> {
    return this.http.get(`${environment.time_entry_url}employee_project/${empProId}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

    //........................Assign emp project...........................................//

    getAllProjectNames(orgid:any): Observable<any> {
      return this.http
        .get(`${environment.time_entry_url}v3/project_by_organization/org/${orgid}`,httpOptionsTimeEntry)
        .pipe(
          map((res) => res),
          timeout(globalTimeOut)
        );
    }

    // http://localhost:6022/api/enote/timeentry/v3/project_by_organization/org/1

    updatetaskgroup(payload, taskgroupid) {
      return this.http.put(`${environment.time_entry_url}task-group/${taskgroupid}`, payload,httpOptionsTimeEntry);
    }
    createtaskgroup(payload:any) {
      return this.http.post(`${environment.time_entry_url}task-group`, payload,httpOptionsTimeEntry);
    }

    getTaskGroupname():Observable<any> {
      return this.http
        .get(`${environment.time_entry_url}task-group/organization/${this.organzationid}`,httpOptionsTimeEntry)
        .pipe(
          map((res) => res),
          timeout(globalTimeOut)
        );
    }

    //............project stage...................................//

     GetProjectStage() {
        return this.http
          .get(`${environment.time_entry_url}project_stage/organization/${this.organzationid}`,httpOptionsTimeEntry)
          .pipe(
            map((res) => res),
            timeout(globalTimeOut)
          );
      }
    AddProjectStage(payload){
      return this.http
      .post(`${environment.time_entry_url}project_stage`,payload,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
    }
    updateProjectStage(payload,projectstageId){
      return this.http.put(`${environment.time_entry_url}project_stage/${projectstageId}`, payload,httpOptionsTimeEntry);
    }

    //................................................Setting (manage work hours)................................................................................

    Getmaxworkhours() {
      return this.http
        .get(`${environment.time_entry_url}maxworkhours/organization/${this.organzationid}`,httpOptionsTimeEntry)
        .pipe(
          map((res) => res),
          timeout(globalTimeOut)
        );
    }
    updatemaxworkhours(data,organzationid) :Observable<any>{
      return this.http.put(`${environment.time_entry_url}maxworkhours/${organzationid}`, data,httpOptionsTimeEntry).pipe(
        map((res) => res),
        timeout(globalTimeOut),
        catchError(this.handleError)
      );
    }
    createMaxHours(data:any):Observable<any>{
      return this.http.post(`${environment.time_entry_url}maxworkhours`, data,httpOptionsTimeEntry).pipe(
        map((res) => res),
        timeout(globalTimeOut),
        catchError(this.handleError)
      );
    }
    //..................................................daily time sheet..............................................................................
    
    getProjectByOrganization(orgid) {
      return this.http.get(`${environment.time_entry_url}v3/project_by_organization/org/${orgid}`,httpOptionsTimeEntry).pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
    }
    // getEmployeeProject(projectId: number) {
    //   return this.http.get(`${environment.time_entry_url}employee_project/project/${projectId}`,httpOptionsTimeEntry);
    // }
    getTaskDetailReportByProject(date: any, projectId: number) {
      return this.http.get(
        `${environment.time_entry_url}taskDetail_report_by_project/${date}/${projectId}`,httpOptionsTimeEntry
      );
    }
    getSubmittedTimesheetByEmployeeIdAngProjectId(
      date: any,
      employeeId: any,
      projectId: any
    ) {
      return this.http
        .get(
          `${environment.time_entry_url}taskDetail_report_by_employee/${date}/${employeeId}/${projectId}`,httpOptionsTimeEntry
        )
        .pipe(
          map((res) => res),
          timeout(globalTimeOut)
        );
    }
    
    // getTaskReportByEmpolyee(timesheetDate) {
    //   return this.http
    //     .get(`${environment.time_entry_url}taskDetail_report/${timesheetDate}/${this.organzationid}`,httpOptionsTimeEntry)
    //     .pipe(
    //       map((res) => res),
    //       timeout(globalTimeOut)
    //     );
    // }

    getTaskReportByEmpolyee(timesheetDate) {
      return this.http
        .get(`${environment.time_entry_url}v2/taskDetail_report/${timesheetDate}/${this.organzationid}`,httpOptionsTimeEntry)
        .pipe(
          map((res) => res),
          timeout(globalTimeOut)
        );
    }


    // getnotsubmittedTaskReportByEmpolyee(timesheetDate) {
    //   return this.http
    //     .get(
    //       `${environment.time_entry_url}taskDetail_report/notsubmitted/${timesheetDate}/${this.organzationid}`,httpOptionsTimeEntry
    //     )
    //     .pipe(
    //       map((res) => res),
    //       timeout(globalTimeOut)
    //     );
    // }

    getnotsubmittedTaskReportByEmpolyee(timesheetDate) {
      return this.http
        .get(
          `${environment.time_entry_url}v2/taskDetail_report/notsubmitted/${timesheetDate}/${this.organzationid}`,httpOptionsTimeEntry
        )
        .pipe(
          map((res) => res),
          timeout(globalTimeOut)
        );
    }


    getResourceById(userId:number,orgId: number) {
      return this.http.get(`${environment.base_url}employee/filter/${userId}/${orgId}`,httpOptions).pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
    }
    getProjectList(){
      return this.http.get(`${environment.time_entry_url}v3/project_by_organization/org/${this.organzationid}`,httpOptionsTimeEntry).pipe(map((res) => res),
      timeout(globalTimeOut)
      );
    }


    // filterEmployeesByProjects(organizationId:number,projectId:number,employee:string){
    //   return this.http.get(`${environment.time_entry_url}employee_project/project/filter/${organizationId}/${projectId}/${employee}}`,httpOptionsTimeEntry).pipe(map((res) => res),timeout(globalTimeOut))
    // }



    filterEmployeesByProjects(organizationId: number, projectId: number, employee: string) {
      return this.http.get(`${environment.time_entry_url}employee_project/project/filter/${organizationId}/${projectId}/${employee}`, httpOptionsTimeEntry)
          .pipe(
              map((res) => res),
              timeout(globalTimeOut)
          );
  }
    getEmployeeNameList(userId:any , orgId:any){
      return this.http.get(`${environment.base_url}employee/filter/keyword${userId}/${orgId}`,httpOptions).pipe(
        map((res) => res),
        timeout(globalTimeOut)
      )
    }
  //   getEmployeesBynumber(employeeNo: number) {
  //     return this.http
  //       .get(
  //         `${base_url}employee/filter/keyword/${employeeNo}/${this.organzationid}`,httpOptionsHrms
  //       )
  //       .pipe(
  //         map((res) => res),
  //         timeout(globalTimeOut)
  //       );
  //   }
    
    
    //...................................mom....................................................................................................................
    
    getResourceByIds(id) {
      return this.http.get(`${environment.base_url}employee/${id}`,httpOptionsHrms).pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
    }
    //................check this.........................
    get_momByProject(startDate, endDate, projectId) {
      return this.http.get(`${environment.time_entry_url}mom/${startDate}/${endDate}/${projectId}`,httpOptionsTimeEntry);
    }
    getStartEndProjectList(startDate, endDate,organzationid) {
      return this.http.get(`${environment.time_entry_url}mom/org/${startDate}/${endDate}/${organzationid}`,httpOptionsTimeEntry);
    }
    onlyProjectSelection(projectId) {
      return this.http.get(`${environment.time_entry_url}mom/project/${projectId}`,httpOptionsTimeEntry);
    }
    OnlyDateSelection(startDate,endDate){
      return this.http.get(`${environment.time_entry_url}startDate/endDate`,httpOptionsTimeEntry)
    }

   

    nonProjectSelection() {
      return this.http.get(`${environment.time_entry_url}mom/non-project`,httpOptionsTimeEntry);
    }
    //..........ACTION ITEM API...................................................................................................................

    getStartEndActionItemList(startDate,endDate,listtypeId,projectId) {
      return this.http.get(`${environment.time_entry_url}action-item/${startDate}/${endDate}/${listtypeId}/${projectId}`,httpOptionsTimeEntry);
    }

    //start date end date only
    actionStartendDate(startDate,endDate) {
      return this.http.get(`${environment.time_entry_url}action-item/date/${startDate}/${endDate}`,httpOptionsTimeEntry);
    }
    //only project selection//
    onlyProjectselection(projectId) {
      return this.http.get(`${environment.time_entry_url}action-item/project/${projectId}`,httpOptionsTimeEntry);
    }
    //only non-project selection//
    onlyNonProjectselection() {
      return this.http.get(`${environment.time_entry_url}action-item/non-project`,httpOptionsTimeEntry);
    }
    //project and action status//
    projectStatusList(actionStatus,projectId) {
      return this.http.get(`${environment.time_entry_url}action-item/project/${actionStatus}/${projectId}`,httpOptionsTimeEntry);
    }
    //only non-project and action status//
    nonprojectActionList(actionStatus) {
      return this.http.get(`${environment.time_entry_url}action-item/non-project/${actionStatus}`,httpOptionsTimeEntry);
    }
    //only action Status//
    onlyActionsStatus(actionStatus) {
      return this.http.get(`${environment.time_entry_url}action-item/actionStatus/${actionStatus}`,httpOptionsTimeEntry);
    }
    //action status, start date ,end date//
    actionstartendDateselecting(startDate,endDate,actionStatus) {
      return this.http.get(`${environment.time_entry_url}action-item/date/status/${startDate}/${endDate}/${actionStatus}`,httpOptionsTimeEntry);
    }
   //non project and start and end date//
   nonProjectStartEndDate(startDate,endDate) {
      return this.http.get(`${environment.time_entry_url}action-item/non-project/${startDate}/${endDate}`,httpOptionsTimeEntry);
    }
    ProjectStartEndDate(startDate,endDate,projectId) {
      return this.http.get(`${environment.time_entry_url}action-item/${startDate}/${endDate}/${projectId}`,httpOptionsTimeEntry);
    }

    //.....Reimbustment.............
    getexpensereburimentbyId(expensereimbursementId: any) {
      return this.http.get(`${environment.api_url}expense-reimbursement/${expensereimbursementId}`,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    // categorylist(organizationId:any){
    //   return this.http.get(`${api_url}category/organization/${organizationId}`,httpOptionsReimbursement)
    //   .pipe(map(data=>data));
    // }
    categorylist(organizationId:any){
      return this.http.get(`${environment.api_url}v2/category/organization/${organizationId}`,httpOptionsReimbursement)
      // .pipe(map(data=>data));
    }
    expensetype() {
      return this.http.get(`${environment.api_url}list-type-values/5`,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    // getadvancebalancegivento(employeeId: any,advanceType:any) {
    //   return this.http.get(`${api_url}advance/advanceGivenTo/approvalStatus/${employeeId}/advanceType/${advanceType}`,httpOptionsReimbursement)
    //   .pipe(map(data=>data));
    // }
    getadvancebalancegivento(employeeId: any,advanceType:any) {
        return this.http.get(`${environment.api_url}v2/expense/advance/advanceGivenTo/${employeeId}/advanceType/${advanceType}`,httpOptionsReimbursement)
        .pipe(map(data=>data));
      }
    deleteexpense(expenseId: any) {
      return this.http.delete(`${environment.api_url}expenses/${expenseId}`,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }

    updateexpense(expenseReimbursementId: any, expenseReimbursement: any) {
      return this.http.put(`${environment.api_url}expense-reimbursement/${expenseReimbursementId}`,expenseReimbursement,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    createexpense(createexpense:any){
      return this.http.post(`${environment.api_url}expense-reimbursement`,createexpense,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }

    // getexpensereburimentbyemployee(organizationId: any) {
    //   return this.http.get(`${api_url}expense-reimbursement/organization/${organizationId}`,httpOptionsReimbursement)
    //   .pipe(map(data=>data));
    // }
    getexpensereburimentbyemployee(employeeId: any) {
      return this.http.get(`${environment.api_url}v2/expense-reimbursement/employee/${employeeId}`,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }

    // getexpensereibursementbyemployeeapprover(employeeId:any){
    //   return this.http.get(`${api_url}expense-reimbursement/employeeapprover/${employeeId}/3`,httpOptionsReimbursement)
    //   .pipe(map(data=>data));

    // }
    getexpensereibursementbyemployeeapprover(employeeId:any){
      return this.http.get(`${environment.api_url}v3/expense-reimbursement/pending/${employeeId}`,httpOptionsReimbursement)
      .pipe(map(data=>data));

    }
    getAdvanceList(employeeId){
      return this.http.get(`${environment.api_url}v3/advance/pending/${employeeId}`,httpOptionsReimbursement)
      .pipe(map(data=>data));

    }
    getListtypeValuebyId(paymentId: any){
      return this.http.get(`${environment.api_url}list-type-values/${paymentId}`,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    expenseReimbursementApproval(expenseReimbursementId:any,data:any){
      return this.http.put(`${environment.api_url}expense_reimbursement_status/${expenseReimbursementId}`,data,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    advanceApproval(adavnceId:any,data:any){
      return this.http.put(`${environment.api_url}advance_status/${adavnceId}`,data,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    // getApproverlist(expenseReimbursementId:any){
    //   return this.http.get(`${environment.time_entry_url}/approvals/REIUMBURSEMENT/${expenseReimbursementId}`,httpOptionsTimeEntry,)
    //   .pipe(map(data=>data));
    // }
    // getexpensereburimentbyId(expensereimbursementId: any) {
    //   return this.http.get(`${api_url}expense-reimbursement/${expensereimbursementId}`,httpOptionsReimbursement)
    //   .pipe(map(data=>data));
    // }
    paymentModelist(listTypeId:any):Observable<any>
    {
      return this.http.get(`${environment.api_url}list-type-values/${listTypeId}`,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }

    paymentAdviceUpdate(paymentAdviceUpdateData: any, paymentId: any) {
      return this.http.put(`${environment.api_url}paymentAdvice/${paymentId}`,paymentAdviceUpdateData,httpOptionsReimbursement)
      .pipe(map(res => res));
    }
    paymentAdviceCreate(paymentAdviceData:any):Observable<any>
    {
      return this.http.post(`${environment.api_url}paymentAdvice`,paymentAdviceData,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    categorybyid(categoryid:any){
      return this.http.get(`${environment.api_url}category/${categoryid}`,httpOptionsReimbursement)
      .pipe(map(data=>data));
    }
    categorystatustoDeactive(expenseCategoryId):Observable<any>
  {
    return this.http.put(`${environment.api_url}category/deactivate/${expenseCategoryId}`,null,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  categorystatustoactive(expenseCategoryId):Observable<any>
  {
    return this.http.put(`${environment.api_url}category/activate/${expenseCategoryId}`,null,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  categoryupdate(expenseCategoryId:any,categoryupdate:any){
    return this.http.put(`${environment.api_url}category/${expenseCategoryId}`,categoryupdate,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  categorycreate(category:any){
    return this.http.post(`${environment.api_url}category`,category,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  // advancelisting(employeeId:any,advanceType:any){
  //   return this.http.get(`${api_url}advance/advanceGivenTo/${employeeId}/advanceType/${advanceType}`,httpOptionsReimbursement)
  //   .pipe(map(data=>data));
  // }
  advancelisting(employeeId:any,advanceType:any){
    return this.http.get(`${environment.api_url}v2/advance/advanceGivenTo/${employeeId}/advanceType/${advanceType}`,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  advanceupdate(advanceId:any,advanceupdate:any){
    return this.http.put(`${environment.api_url}advance/${advanceId}`,advanceupdate,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  approvalsList(adavnceId:any){
    return this.http.get(`${environment.base_url}approvalss/ADVANCE/${adavnceId}`,httpOptionsHrms)
  }
  approvalsLists(adavnceId:any){
    return this.http.get(`${environment.base_url}approvalss/ADVANCE/${adavnceId}`,httpOptionsHrms)
  }
  expenseApprovalList(expenseId:any){
    return this.http.get(`${environment.base_url}approvalss/REIMBURSEMENT/${expenseId}`,httpOptionsHrms)
  }
  advancecreate(advance:any){
    return this.http.post(`${environment.api_url}v2/advance`,advance,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  listCategoryType(){
    return this.http.get(`${environment.api_url}list-type-values/2`,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  listReimbursmentType(){
    return this.http.get(`${environment.api_url}list-type-values/3`,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  advancestatustoDeactive(advanceId):Observable<any>
  {
    return this.http.put(`${environment.api_url}advance/deactivate/${advanceId}`,null,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }
  advancestatustoactive(advanceId):Observable<any>
  {
    return this.http.put(`${environment.api_url}advance/activate/${advanceId}`,null,httpOptionsReimbursement)
    .pipe(map(data=>data));
  }



   

   










    //.........................................................................................
    getRoleName(): Observable<any> {
      return this.http
        .get(`${environment.time_entry_url}user_role`,httpOptionsTimeEntry)
        .pipe(
          map((res) => res),
          timeout(globalTimeOut)
        );
    } 
     getCommonTaskName(): Observable<any> {
    return this.http
      .get(`${environment.time_entry_url}common_task/organization/${this.organzationid}`,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getCommonTaskNameById(id): Observable<any> {
    return this.http
      .get(
        `${environment.time_entry_url}common_task/organization/commontasktype/${this.organzationid}/${id}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getCommontaskName(commontaskname: any, genericTaskid: number) {
    return this.http
      .get(
        `${environment.time_entry_url}common_task/commontaskname/${commontaskname}/${genericTaskid}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  
  // getResourceById(id) {
  //   return this.http.get(`${base_url}employee/${id}`, httpOptionsHrms).pipe(
  //     map((res) => res),
  //     timeout(globalTimeOut)
  //   );
  // }

  // http://localhost:6015/api/enote/hrms/employee/8010
  getResourceImageById(id) {
    return this.http.get(`${environment.time_entry_url}resource_profile/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  public getResourceByOrganization(id): Observable<any> {
    return this.http
      .get(`${environment.time_entry_url}resource_by_organization/${id}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getresourcebyproject(id, orgId) {
    return this.http.get(`${environment.time_entry_url}resource_by_project/${id}/${orgId}`);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++ team +++++++++++++++++++++++++++++++++++++++++++++++
  getTeamByOrganization(id) {
    return this.http.get(`${environment.time_entry_url}team_by_organization/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTeam() {
    return this.http.get(`${environment.time_entry_url}team`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTeamById(id) {
    return this.http.get(`${environment.time_entry_url}team/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  //  get team resource
  getTeamLeader() {
    return this.http.get(`${environment.time_entry_url}team_by_teamleader`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTeamResource() {
    return this.http.get(`${environment.time_entry_url}team_by_resource`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // project++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // getProjectByOrganization(id) {
  //   return this.http.get(`${environment.time_entry_url}project_by_organization/${id}`).pipe(
  //     map((res) => res),
  //     timeout(globalTimeOut)
  //   );
  // }
  // getProjectByOrganization(id) {
  //   return this.http
  //     .get(
  //       `${environment.time_entry_url}project_by_organization/${id}`,
  //       httpOptionsTimeEntry
  //     )
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }
  // getProjectList() {
  //   return this.http
  //     .get(`${environment.time_entry_url}project_by_organization/${this.organzationid}`)
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }

  // http://38.242.135.217:6022/api/enote/timeentry/project_by_empolyee/6

  getProjectsByEmployee(id:number){
    return this.http.get(`${environment.time_entry_url}project_by_empolyee/${id}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }



  getEmpProjectList(orgid) {
    return this.http.get(`${environment.time_entry_url}v3/project_by_organization/org/${orgid}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  listProjectList(){
    return this.http.get(`${environment.time_entry_url}v3/project_by_organization/${this.organzationid}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  actionItemList(id) {
    return this.http.get(`${environment.time_entry_url}list-type-values/${id}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }


  getRole(): Observable<any> {
    return this.http
      .get(`${environment.time_entry_url}user_role_by_organziation/${this.organzationid}`,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  // getprojectByID(id) {
  //   return this.http.get(`${environment.time_entry_url}project/${id}`,httpOptionsTimeEntry).pipe(
  //     map((res) => res),
  //     timeout(globalTimeOut)
  //   );
  //   return this.http
  //     .get(`${environment.time_entry_url}project_by_empolyee/${id}`, httpOptionsTimeEntry)
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }
  // .pipe(
  //   map((res) => res),
  //   timeout(globalTimeOut)
  // );
  getProjectByEmployeeId(empID:number){
    return this.http
    .get(`${environment.time_entry_url}project_by_empolyee/${empID}`,httpOptionsTimeEntry).pipe(
        map((res) => res))
   
  }

  getProjectsIfTasks(organization:number){
    return this.http
    .get(`${environment.time_entry_url}project/task/organization/${organization}`,httpOptionsTimeEntry)
    .pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // http://38.242.135.217:6022/api/enote/timeentry/project/task/organization/1
  // http://38.242.135.217:6022/api/enote/timeentry/project/task
  // http://38.242.135.217:6022/api/enote/timeentry/project_by_empolyee/6
  getprojectByID(id) {
    return this.http
      .get(`${environment.time_entry_url}project/${id}`, httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  employeeTaskImport(body: any) {
    const httpOptionsTimeEntry = {
      headers: new HttpHeaders({
        Authorization: 'Basic ZW5vdGU6ZW5vdGUkdGltZUVudHJ5Iw==',
      }),
    };

    return this.http.post(
      `${environment.time_entry_url}employee-task/data/import`,
      body,
      httpOptionsTimeEntry
    );
  }
  getprojectif_task_(taskId, orgId) {
    return this.http
      .get(`${environment.time_entry_url}project_by_resource/task/${taskId}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }
  gettaskByProject_if_resource_task_present(proId, resId, orgId) {
    return this.http
      .get(`${environment.time_entry_url}task/project/resource/${proId}/${resId}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  GetResourceProjects(resId, orgId) {
    return this.http.get(`${environment.time_entry_url}project_by_resource/${resId}/${orgId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      
    );
  }

  // taskByProject(projectId: any) {
  //   return this.http.get(`${environment.time_entry_url}task_by_project/${projectId}`,httpOptionsTimeEntry);
  // }

  taskByProject(projectId: any) {
    return this.http.get(`${environment.time_entry_url}v22/task_by_project/${projectId}`,httpOptionsTimeEntry);
  }

  getEmpIdProIdStardateEnddateAndStatus(projectId: any, selectedemployeeId: any, startDate: any, endDate: any, taskstatus: any) {
    return this.http.get(`${environment.time_entry_url}task/project/employee/taskstatus/startdate/enddate/${projectId}/${selectedemployeeId}/${taskstatus}/${startDate}/${endDate}`,httpOptionsTimeEntry);
  }


  getTaskByProjectAndEmployeeAndStatus(projectId: any, selectedemployeeId: any, taskstatus: any) {
    return this.http.get(`${environment.time_entry_url}task/project/employee/taskstatus/${projectId}/${selectedemployeeId}/${taskstatus}`,httpOptionsTimeEntry);
 }

 taskByProjectAndStatus(projectId: any, taskstatus: any) {
  return this.http.get(`${environment.time_entry_url}task/project/taskstatus/${projectId}/${taskstatus}`,httpOptionsTimeEntry);
}
  // +++++++++++++++++++++++++++++++++++++deliverable+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getDeliverables(): Observable<any> {
    return this.http.get(`${environment.time_entry_url}deliverable`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getDeliverablesById(id): Observable<any> {
    return this.http.get(`${environment.time_entry_url}deliverable/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getDeliverableOnlyIf_TaskPresent(orgId) {
    return this.http.get(
      `${environment.time_entry_url}deliverable_by_organization/task/${orgId}`
    );
  }
  getdeliverablebyProjectId(id, orgId) {
    return this.http
      .get(`${environment.time_entry_url}deliverable_by_project/${id}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getDeliverableByOrganization(id) {
    return this.http
      .get(`${environment.time_entry_url}deliverable_by_organization/${id}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getdeliverableByProject(proId, OrgId) {
    return this.http
      .get(`${environment.time_entry_url}deliverable_by_project/${proId}/${OrgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getDeliverableByProjectId_if_Task(proId, orgId) {
    return this.http
      .get(`${environment.time_entry_url}deliverable_by_project/task/${proId}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  //timeput ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getdata(): Observable<any> {
    return this.http.get(`${environment.time_entry_url}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // profile ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getProfileData(id) {
    return this.http.get(`${environment.time_entry_url}resource/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // task++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // enote entry starts here
  getTaskList() {
    return this.http
      .get(
        `${environment.time_entry_url}task_by_organization/${this.organzationid}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // getEmployeeByOrganization() {
  //   return this.http
  //     .get(
  //       `${base_url}employee/organization/${this.organzationid}`,
  //       httpOptionsHrms
  //     )
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }

  getEmployeeByOrganization() {
    return this.http
      .get(
        `${environment.base_url}v22/employee/organization/${this.organzationid}`,
        httpOptionsHrms
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getCustomerOrganization() {
    return this.http
      .get(
        `${environment.time_entry_url}customer/organization/${this.organzationid}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTimeentryProject(projectId: number) {
    return this.http
      .get(`${environment.time_entry_url}project/${projectId}`, httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskDetailByProjectIdDate(
    projectId: number,
    startdate: any,
    enddate: any
  ) {
    return this.http.get(
      `${environment.time_entry_url}taskDetail_by_employee/report/project/${projectId}/${startdate}/${enddate}`,httpOptionsTimeEntry
    );
  }

  // getProjectList() {
  //   return this.http
  //     .get(
  //       `${environment.time_entry_url}project_by_organization/${this.organzationid}`,
  //       httpOptionsTimeEntry
  //     )
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }

  // taskDetailReport(date:any){
  //   return this.http.get(
  //     `${environment.time_entry_url}taskDetail_report/notsubmitted/${date}/${this.organzationid}`,
  //     httpOptionsTimeEntry
  //   );
  // }

  taskDetailReport(date:any){
    return this.http.get(
      `${environment.time_entry_url}v2/taskDetail_report/notsubmitted/${date}/${this.organzationid}`,
      httpOptionsTimeEntry
    );
  }


  
  lastUpdatedTimesheet(taskId:number,empId:number){
    return this.http.get(
      `${environment.time_entry_url}taskDetail_by_task_and_employee/lastUpdated/${taskId}/${empId}`,
      httpOptionsTimeEntry
    );
  }


  // http://38.242.135.217:6022/api/enote/timeentry/taskDetail_by_task_and_employee/lastUpdated/2/6
  //http://192.168.18.11:6022/api/enote/timeentry/project_by_organization/1

  // get_permissionList(empId:any){
  //   return this.http.get(`${leave_base_url}permissions/empolyee/${empId}`,httpOptionsTimeEntry)
  //   .pipe(map(res => res));
  // }
  getEmployeeTask() {
    return this.http.get(`${environment.time_entry_url}employee_task`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  // getemployeetask(employeeId: any) {
  //   return this.http
  //     .get(
  //       `${environment.time_entry_url}employee_task_by_employee/limit/${employeeId}`,
  //       httpOptionsTimeEntry
  //     )
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }

  getemployeetask(employeeId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}v2/employee_task_by_employee/limit/${employeeId}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmployeetaskvalidate(empolyeeId: any, taskId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}employee_task/empolyeetask/${empolyeeId}/${taskId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmployeeTaskByEmpolyee(employeeId) {
    return this.http.get(`${environment.time_entry_url}employee_task_by_employee/${employeeId}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
    // return this.http
    //   .get(
    //     `${environment.time_entry_url}employee_task_by_employee/${employeeId}`,
    //     httpOptionsTimeEntry
    //   )
    //   .pipe(
    //     map((res) => res),
    //     timeout(globalTimeOut)
    //   );
  }
  getEmployeeTaskById(empTaskId) {
    return this.http
      .get(`${environment.time_entry_url}employee_task/${empTaskId}`, httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTask(taskId) {
    return this.http
      .get(`${environment.time_entry_url}task/${taskId}`, httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmpTimeLogs(empId) {
    return this.http.get(`${environment.time_entry_url}taskDetail_by_employee/${empId}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/${empId}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskById(id) {
    return this.http
      .get(`${environment.time_entry_url}v22/task_by_project/task/${id}`, httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // getTaskById(id) {
  //   return this.http
  //     .get(`${environment.time_entry_url}v2/task_by_project/${id}`, httpOptionsTimeEntry)
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }
  getTaskByTaskId(id) {
    return this.http.get(`${environment.time_entry_url}v2/task/${id}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  // http://localhost:6022/api/enote/timeentry/v2/task/233
  getTaskDetailByTaskId(id) {
    return this.http.get(`${environment.time_entry_url}taskDetail_by_task/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTaskDetailByTaskIdAndEmployee(id, employee) {
    return this.http
      .get(`${environment.time_entry_url}taskDetail_by_task_and_employee/${id}/${employee}`,httpOptionsTimeEntry)
      
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskByEmployee(empId, projectId) {
    return this.http
      .get(
        `${environment.time_entry_url}task_by_empolyee/project/${empId}/${projectId}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmpByPro(projectId, empId, approverStatus) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/project/approvalStatus/${projectId}/${empId}/${approverStatus}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmpByProject(projectId, empId) {
    return this.http
      .get(`${environment.time_entry_url}taskDetail_by_employee/project/${projectId}/${empId}`,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getMenuList() {
    return this.http.get(`${environment.time_entry_url}menu`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getProjectsIfDeliverable(id) {
    return this.http.get(
      `${environment.time_entry_url}project_by_organization/deliverable/${id}`
    );
  }
  getTaskbyDeliverable_id(delId, orgId) {
    return this.http
      .get(`${environment.time_entry_url}task_by_deliverable/${delId}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  // resource's tasklist
  getresourceTasks(resId, orgId) {
    return this.http.get(`${environment.time_entry_url}task_by_resource/${resId}/${orgId}`);
    // .pipe(map(res => res), timeout(globalTimeOut), catchError(this.handleError));
  }
  getTaskByDeliverable(delId) {
    return this.http.get(`${environment.time_entry_url}deliverable_by_task/${delId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // profile
  getResourceProfilePic(resId) {
    return this.http.get(`${environment.time_entry_url}resource_profile/${resId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  GetListById(id) {
    return this.http.get(`${environment.time_entry_url}list-type-values/${id}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

 
  GetCommonTaskEntry() {
    return this.http
      .get(`${environment.time_entry_url}common_task/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetGenericTaskEntry() {
    return this.http
      .get(`${environment.time_entry_url}generictask/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetGenericTaskEntryById(id) {
    return this.http
      .get(`${environment.time_entry_url}generictaskdetail/employee/${id}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  Get_Customer() {
    return this.http
      .get(`${environment.time_entry_url}customer/organization/${this.organzationid}`,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getCustomerName(data) {
    return this.http
      .post(`${environment.time_entry_url}customer/customernamevalidate`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getProjectStageName() {
    return this.http
      .get(`${environment.time_entry_url}project_stage/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getApprovers() {
    return this.http
      .get(`${environment.base_url}v2/approvers/organziation/${this.organzationid}`,httpOptionsHrms)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getApproverType(){
    return this.http.get(`${environment.base_url}approvertype`,httpOptions)
    .pipe(map(data=>data));
  }
  getApproverRuleName(rulename: any, approverId: number) {
    return this.http
      .get(
        `${environment.time_entry_url}approvers/approversrulename/${rulename}/${approverId}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getApproverPolicy() {
    return this.http
      .get(
        `${environment.time_entry_url}approverspolicy/organziation/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getapproverpolicyById(approversPolicyId: any) {
    return this.http
      .get(`${environment.time_entry_url}approverspolicy/${approversPolicyId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getApproverPolicyName(approverspolicyname: any, approverspolicyId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}approverspolicy/approverspolicyname/${approverspolicyname}/${approverspolicyId}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getApproverPolicyDefault() {
    return this.http
      .get(
        `${environment.time_entry_url}approverspolicy/default/organziation/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  
  createapproverHiearchy(approverpolicy: any) {
    return this.http
      .post(`${environment.time_entry_url}approverspolicy`, approverpolicy)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  updateapproverHiearchy(approverpolicyupdate: any) {
    return this.http
      .put(
        `${environment.time_entry_url}approverspolicy/${approverpolicyupdate.approversPolicyId}`,
        approverpolicyupdate
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogByApproverhierarchy(empId: any) {
    return this.http
      .get(`${environment.time_entry_url}taskDetail_by_approver/taskdetail/${empId}/3`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpApproversById(empId) {
    return this.http.get(`${environment.time_entry_url}approvers/apporver/${empId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getEmpApprovers(empId) {
    return this.http.get(`${environment.time_entry_url}approvers/employee/${empId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getApproversById(approverId) {
    return this.http.get(`${environment.time_entry_url}approvers/${approverId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getEmployeeByApprover() {
    return this.http.get(`${environment.time_entry_url}employeeapprovers`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getProjectApprover(organziationId) {
    return this.http
      .get(
        `${environment.base_url}projectapprovers_by_project/organization/${this.organzationid}`,httpOptionsHrms
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
   getEmployeeApprovers() {
      return this.http.get(`${environment.base_url}employeeapprovers`,httpOptions)
      .pipe(map(res => res));
    }
  getResourceByEmpId(id) {
    return this.http.get(`${environment.time_entry_url}employee/${id}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getprojectApproversByProject(id: any) {
    return this.http
      .get(`${environment.base_url}projectapprovers_by_project/${id}`, httpOptionsHrms)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getprojectApprovers(project: any) {
    return this.http
      .get(`${environment.base_url}projectapprovers/validate/${project}`,httpOptionsHrms)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  createProjectApprover(projectapprover: any) {
    return this.http
      .post(`${environment.base_url}projectapprovers`, projectapprover,httpOptionsHrms)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  updateProjectApprover( projectApproverId: any,payload:any) {
    return this.http
      .put(
        `${environment.base_url}projectapprovers/${projectApproverId}`,payload,httpOptionsHrms
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskReportByProject(timesheetDate: any, projectId: number) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_report_by_project/${timesheetDate}/${projectId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskReportByTask(timesheetDate: any, taskId: number) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_report_by_task/${timesheetDate}/${taskId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogByApprover(data) {
    return this.http
      .post(`${environment.time_entry_url}taskDetail_by_employees/approvalStatus/3`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

 
 

  getEmpTimeLogbyProjectId(projectId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}v2/employee_task_by_project/${projectId}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }


  getEmpTimeLogFromProjectId(projectId: any){
    return this.http.get(`${environment.time_entry_url}v2/employee_task_by_project/${projectId}`,httpOptionsTimeEntry)
  }
  getEmpTimeLogbyEmployeeIdandProjectId(employeeId: any, projectId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}employee_task_by_employee/project/${employeeId}/${projectId}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogbyEmployeeIdandProjectIdandDate(
    projectId: any,
    employeeId: any,
    date: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/project/${projectId}/${employeeId}/${date}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmpTimeLogbyEmployeeIdandTaskIdandDate(
    taskId: any,
    employeeId: any,
    date: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/task/${taskId}/${employeeId}/${date}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getprojectbyEmployeeId(employeeId: any) {
    return this.http
      .get(`${environment.time_entry_url}employee_project/employee/${employeeId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getManagereportByEmployeeId(employeeId: any, approvalStatus: any) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/approvalStatus/${employeeId}/${approvalStatus}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getManagereportByEmployeeIdAndProjectId(
    projectId: any,
    employeeId: any,
    approvestatus:any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/project/approvalStatus/${projectId}/${employeeId}/${approvestatus}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getManagereportByEmployeeIdAndStartDateAndEndDate(
    employeeId: any,
    approvalStatus: any,
    fromdate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employees/date/approvalStatus/${employeeId}/${approvalStatus}/${fromdate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getManagereportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
    employeeId: any,
    projectId: any,
    approvalStatus: any,
    fromdate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employees/employee/project/date/approvalStatus/${employeeId}/${projectId}/${approvalStatus}/${fromdate}/${toDate}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTimesheetReportByEmployeeId(employeeId: any) {
    return this.http
      .get(`${environment.time_entry_url}taskDetail_by_employee/${employeeId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTimesheetReportByEmployeeIdAndProjectId(projectId: any, employeeId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/project/${projectId}/${employeeId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTimesheetReportByEmployeeIdAndStartDateAndEndDate(
    employeeId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/report/${employeeId}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
    projectId: any,
    employeeId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/report/project/${employeeId}/${projectId}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  

  getEmpTimeLogbyEmployeeIdandStartDateAndEndDate(
    taskId: number,
    employeeId: any,
    startDate: any,
    endDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/report/task/${employeeId}/${taskId}/${startDate}/${endDate}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getSubmittedTimesheetByEmployeeId(date: any, employeeId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_report_by_employee/${date}/${employeeId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        
        timeout(globalTimeOut)
      );
  }
 
  getDetailSubmittedTimesheetByEmployeeId(date: any, employeeId: any) {
    return this.http
      .get(`${environment.time_entry_url}taskDetail_by_employee/date/${date}/${employeeId}`,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getDetailSubmittedTimesheetByEmployeeIdAndProjectId(
    date: any,
    employeeId: any,
    ProjectId: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/date/${date}/${employeeId}/${ProjectId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskByProjectandTaskGroup(Id: any, selectedemployeeId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/task-group/${Id}/${selectedemployeeId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // getTaskByProjectandTaskGroupdate(Id: any, selectedetaskGroupId: any, startDate: any, endDate: any) {
  //   return this.http.get(`${environment.time_entry_url}task/project/task-group/${Id}/${selectedetaskGroupId}`)
  //   .pipe(map(res => res), timeout(globalTimeOut));
  // }

  // My task filter
  getTaskByProjectAndEmployeeAndTaskGroup(
    projectId: any,
    employeeId: any,
    taskgroupId: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/employee/taskgroup/${projectId}/${employeeId}/${taskgroupId}`,httpOptionsTimeEntry

      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskstatus(
    projectId: any,
    employeeId: any,
    taskStatusId: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/employee/taskstatus/${projectId}/${employeeId}/${taskStatusId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskGroupAndTaskstatus(
    projectId: any,
    employeeId: any,
    taskgroupId: any,
    taskStatusId: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/employee/taskgroup/taskStatus/${projectId}/${employeeId}/${taskgroupId}/${taskStatusId}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndDate(
    projectId: any,
    employeeId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/employee/startdate/enddate/${projectId}/${employeeId}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskGroupAndDate(
    projectId: any,
    employeeId: any,
    taskgroupId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/employee/taskgroup/startdate/enddate/${projectId}/${employeeId}/${taskgroupId}/${fromDate}/${toDate}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskstatusAndDate(
    projectId: any,
    employeeId: any,
    taskStatus: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/employee/taskstatus/startdate/enddate/${projectId}/${employeeId}/${taskStatus}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskGroupAndTaskstatusAndDate(
    projectId: any,
    employeeId: any,
    taskgroupId: any,
    taskStatusId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/employee/taskgroup/taskstatus/startdate/enddate/${projectId}/${employeeId}/${taskgroupId}/${taskStatusId}/${fromDate}/${toDate}`,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // Assign task filter
  getTaskByProjectAndTaskgroupAndTaskstatus(
    projectId: any,
    taskstatusId: any,
    taskgroupId: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/taskstatus/taskgroup/${projectId}/${taskstatusId}/${taskgroupId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskstatus(projectId: any, taskstatusId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/taskstatus/${projectId}/${taskstatusId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskgroup(projectId: any, taskGroupId: any) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/task-group/${projectId}/${taskGroupId}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskgroupAndTaskstatusAndDate(
    projectId: any,
    taskstatusId: any,
    taskgroupId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/taskstatus/taskgroup/startdate/enddate/${projectId}/${taskstatusId}/${taskgroupId}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskByProjectAndTaskstatusAndDate(
    projectId: any,
    taskstatusId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/taskstatus/startdate/enddate/${projectId}/${taskstatusId}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskgroupAndDate(
    projectId: any,
    taskGroupId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/taskgroup/startdate/enddate/${projectId}/${taskGroupId}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndDate(projectId: any, fromDate: any, toDate: any) {
    return this.http
      .get(
        `${environment.time_entry_url}task/project/startdate/enddate/${projectId}/${fromDate}/${toDate}`,httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // Forget password
  forgetpassword(data: any) {
    return this.http.post(
      `${environment.time_entry_url}Forgotpassword/OrganizationEmail`,
      data
    );
  }
  //GetEmployeeByEmail
  getemployyebyEmail(organizationEmail: any) {
    return this.http
      .get(`${environment.time_entry_url}employee/email/${organizationEmail}`)
      .pipe(map((data) => data));
  }
  ///otp
  otp(otpdata: any): Observable<any> {
    return this.http
      .post(`${environment.time_entry_url}login/Otpvaildation`, otpdata)
      .pipe(map((data) => data));
  }
  ////// Reset Password /////
  resetpassword(passwordResetdata: any, employeeId: any): Observable<any> {
    return this.http
      .patch(
        `${environment.time_entry_url}employee/resetpassword/${employeeId}`,
        passwordResetdata
      )
      .pipe(map((data) => data));
  }
  validatepassword(currentPassword: any): Observable<any> {
    return this.http
      .post(`${environment.time_entry_url}employee/currentpassword`, currentPassword)
      .pipe(map((data) => data));
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //deliverable_by_organization/task/{organizationId}
  //-------------------------  ----------------------------------------------
  //----------------- post method sample ------------------------------------
  //registration
  // register(params: FormData): Observable<any> {
  //   return this.http.post(`${environment.time_entry_url}resource`, params)
  //     .pipe(map(res => res), timeout(globalTimeOut), catchError(this.handleError));
  // }
  // //login
  // login(data): Observable<any> {
  //   return this.http.post(`${environment.time_entry_url}user_validation`, data)
  //     .pipe(map(res => res), timeout(globalTimeOut), catchError(this.handleError));
  // }
  //create project
  createProject(data) {
    return this.http.post(`${environment.time_entry_url}project`, data,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  postDeliverables(data) {
    return this.http.post(`${environment.time_entry_url}deliverable`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  createTeam(data) {
    return this.http.post(`${environment.time_entry_url}team`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  //
  createTask(data) {
    return this.http.post(`${environment.time_entry_url}task`, data,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  createMutipletask(taskarray: any) {
    return this.http
      .post(`${environment.time_entry_url}task/multiple`, taskarray, httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }

  getTaskByProjectAndDateAndStatus(projectId: any,taskstatus: any, startDate: any, endDate: any ) {
    return this.http.get(`${environment.time_entry_url}task/project/taskstatus/startdate/enddate/${projectId}/${taskstatus}/${startDate}/${endDate}`,httpOptionsTimeEntry);
  }

  gettaskbyEmployeeAndStartDateAndEndDateAndStatus(selectedemployeeId: any, startDate: any, endDate: any, taskstatus: any) {
    return this.http.get(`${environment.time_entry_url}task/employee/startdate/enddate/taskStatus/${selectedemployeeId}/${startDate}/${endDate}/${taskstatus}`,httpOptionsTimeEntry);
  }
  // http://38.242.135.217:6022/api/enote/timeentry/task/employee/startdate/enddate/taskStatus/1010/2024-05-02/2024-05-22/3
  gettaskbyEmployeeAndStartDateAndEndDate(selectedemployeeId: any, startDate: any, endDate: any) {
    return this.http.get(`${environment.time_entry_url}task/employee/startdate/enddate/${selectedemployeeId}/${startDate}/${endDate}`,httpOptionsTimeEntry);
  }

  getTaskByProjectAndEmployee(selectedemployeeId: any,projectId: any) {
    return this.http.get(`${environment.time_entry_url}task_by_empolyee/project/${selectedemployeeId}/${projectId}`,httpOptionsTimeEntry);
  }

  
  taskImport(formData: any): Observable<any> {
    const httpOptionsTimeEntry = {
      headers: new HttpHeaders({
        Authorization: 'Basic ZW5vdGU6ZW5vdGUkdGltZUVudHJ5Iw==',
      }),
    };

    return this.http.post(
      `${environment.time_entry_url}task/data/import`,
      formData,
      httpOptionsTimeEntry
    );
  }
  // taskImport(formData: File) {
  //   const httpOptionsTimeEntry = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: 'Basic ZW5vdGU6ZW5vdGUkdGltZUVudHJ5Iw==',
  //     }),
  //   };

  // let result = this.http.post(`${environment.time_entry_url}task/data/import`,formData,httpOptionsTimeEntry).pipe(map(data=>data))
  // return result;
  // }

  // taskImport(body: any) {
  //   return this.http.post(`${environment.time_entry_url}task/data/import`,body,httpOptionsTimeEntry);

  //   // let result = this.http.post(`${base_url}v2/employee/data/import/`,formData,ImporthttpOptions).pipe(map(data=>data))
  //   // return result;
  // }
  createGenericTask(data) {
    return this.http.post(`${environment.time_entry_url}generictask`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  createCommonTask(data) {
    return this.http.post(`${environment.time_entry_url}common_task`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  createMenu(data) {
    return this.http.post(`${environment.time_entry_url}menu`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  AssignEmpRole(data) {
    return this.http
      .post(`${environment.time_entry_url}employee_project/multiple`, data,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }
  forgotPassword(data) {
    return this.http.post(`${environment.time_entry_url}forgot_password`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  assignEmployee(data) {
    return this.http
      .post(
        `${environment.time_entry_url}employee_task/multiple`,
        data,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }

  getprojecttaskvalidate(taskName: any, projectId: any) {
    return this.http
      .get(`${environment.time_entry_url}task/validatetask/${taskName}/${projectId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  gettaskvalidate(taskName: any) {
    return this.http.get(`${environment.time_entry_url}task/validatetask/${taskName}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  //Get
  getDesignation() {
    return this.http.get(`${environment.time_entry_url}designation`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  GetEmployeTimeEntry(empID) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/${empID}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetEmployeTimeEntryLimit(Id: any) {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_employee/limit/${Id}`,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetEmployeTimeSheet() {
    return this.http
      .get(
        `${environment.time_entry_url}taskDetail_by_empolyee/org/${this.organzationid}/3`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetEmployeTimeSheetByID(empId, approverStatus) {
    return this.http
      .get(`${environment.time_entry_url}taskDetail_by_empolyee/${empId}/${approverStatus}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  projectStatus() {
    return this.http.get(`${environment.time_entry_url}list-type-values/8`,httpOptionsTimeEntry);
  }

  timeEntry(data: any) {
    return this.http
      .post(`${environment.time_entry_url}taskDetail`, data, httpOptionsTimeEntry)
      .pipe(
        map((res) => res)
        // timeout(globalTimeOut),
        // catchError(this.handleError)
      );
  }

  updatetimeEntry(data: any) {
    return this.http
      .put(
        `${environment.time_entry_url}taskDetail/${data.taskDetailId}`,
        data,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }

  GenericTimeEntry(data) {
    return this.http.post(`${environment.time_entry_url}generictaskdetail`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  Create_Customer(data) {
    return this.http.post(`${environment.time_entry_url}customer`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  Create_Role(data) {
    return this.http.post(`${environment.time_entry_url}user_role`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  addProjectStage(data) {
    return this.http.post(`${environment.time_entry_url}project_stage`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  addApprover(data) {
    return this.http.post(`${environment.time_entry_url}approvers`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  createEmployeeApprover(data) {
    return this.http.post(`${environment.time_entry_url}employeeapprovers/mutiple`, data);
  }

  
  gettaskgroup() {
    return this.http.get(
      `${environment.time_entry_url}task-group/organization/group/${this.organzationid}`,
      httpOptionsTimeEntry
    );
  }
  gettaskgroupbyId(taskgroupid) {
    return this.http.get(`${environment.time_entry_url}task-group/${taskgroupid}`);
  }
  

  assigningTaskByEmployee(employeeId: number) {
    return this.http.get(`${environment.time_entry_url}task_by_empolyee/${employeeId}`);
  }

  selectingTaskByEmployee(employeeId: number, projectId: number) {
    return this.http.get(
      `${environment.time_entry_url}task_by_empolyee/project/${employeeId}/${projectId}`,
      httpOptionsTimeEntry
    );
  }

//  getTaskByDate(startDate: any, endDate: any) {
//     return this.http.get(`${environment.time_entry_url}task/startdate/enddate/${startDate}/${endDate}`,httpOptionsTimeEntry);
//   }

  getTaskByDate(startDate: any, endDate: any) {
    return this.http.get(`${environment.time_entry_url}v2/task/startdate/enddate/${startDate}/${endDate}`,httpOptionsTimeEntry);
  }

  // http://localhost:6022/api/enote/timeentry/v2/task/startdate/enddate/2024-06-01/2024-07-18
  getEmpIdProIdStardateEnddate(
    projectId: number,
    employeeId: number,
    taskStartDate: any,
    taskEndDate: any
  ) {
    return this.http.get(
      `${environment.time_entry_url}task/project/employee/startdate/enddate/${projectId}/${employeeId}/${taskStartDate}/${taskEndDate}`,httpOptionsTimeEntry
    );
  }
  selectingEmployeeIdDates(
    projectId: number,
    taskStartDate: any,
    taskEndDate: any
  ) {
    return this.http.get(
      `${environment.time_entry_url}task/project/startdate/enddate/${projectId}/${taskStartDate}/${taskEndDate}`
    );
  }

  selectingDatesEmployeeIdProjectId(
    employeeId: number,
    taskStartDate: any,
    taskEndDate: any
  ) {
    return this.http.get(
      `${environment.time_entry_url}task/employee/startdate/enddate/${employeeId}/${taskStartDate}/${taskEndDate}`,httpOptionsTimeEntry
    );
  }


  getProjectsBasedOnProjectStatus(

  ) {
    return this.http.get(
      `${environment.time_entry_url}v2/project_by_organization/projectStatus/${this.organzationid}`,httpOptionsTimeEntry
    );
  }

  // http://localhost:6022/api/enote/timeentry/v2/project_by_organization/projectStatus/1

  taskDetailByTaskEmployee(taskID: number, employeeId: any) {
    //  http://38.242.135.217:6009/timeentry/
    return this.http.get(
      `${environment.time_entry_url}taskDetail_by_task_and_employee/${taskID}/${employeeId}`,
      httpOptionsTimeEntry
    );
  }

  //localhost:6022/api/enote/timeentry/taskDetail_report/notsubmitted/2021-07-05/1

  //-----------------------------------------------------------------------
  //-----------------put method sample-------------------------------------
  // resource ++
  updateResource(data, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}employee/${id}`, data,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  updateProfile(data: FormData, id) {
    return this.http.put(`${environment.time_entry_url}profile/${id}`, data);
  }
  updateCommonTask(data, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}common_task/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  changepassword(data, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}change_password/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  updateProject(data: any, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}project/${id}`, data,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  updateDeliverable(data, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}deliverable/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  updateTeam(data, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}team/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  updateTask(data, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}task/${id}`, data,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  resetPassword(data, id): Observable<any> {
    return this.http.put(`${environment.time_entry_url}reset_password/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  Time_Entry_Approval(data, taskid): Observable<any> {
    return this.http
      .put(`${environment.time_entry_url}taskDetailUpdateStatus/${taskid}`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }

  Generic_Task_Approval(data, generictaskdetailId): Observable<any> {
    return this.http
      .put(`${environment.time_entry_url}generictaskdetail/${generictaskdetailId}`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }
  updateGenericTask(genericId, data) {
    return this.http
      .put(`${environment.time_entry_url}generictask/${genericId}`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }
  updateCustomer(customerId, data) {
    return this.http.put(`${environment.time_entry_url}customer/${customerId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

 

  // getProjectStageName(projectstagename: any, projectstageId: number) {
  //   return this.http
  //     .get(
  //       `${environment.time_entry_url}project_stage/projectstagename/${projectstagename}/${projectstageId}/${this.organzationid}`
  //     )
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut),
  //       // catchError(this.handleError)
  //     );

  // }
  // updateProjectStage(projectstageId, data) {
  //   return this.http
  //     .put(`${environment.time_entry_url}project_stage/${projectstageId}`, data)
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //       // catchError(this.handleError)
  //     );
  // }

  // getProjectStageName(projectstagename: any, projectstageId: number) {
  //   return this.http
  //     .get(
  //       `${environment.time_entry_url}project_stage/projectstagename/${projectstagename}/${projectstageId}/${this.organzationid}`
  //     )
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //       // catchError(this.handleError)
  //     );
  // }

  updateApprover(approverId, data) {
    return this.http.put(`${environment.time_entry_url}approvers/${approverId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  updateEmployeeTask(empTaskId, data) {
    return this.http
      .put(
        `${environment.time_entry_url}employee_task/${empTaskId}`,
        data,
        httpOptionsTimeEntry
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }
  update_Role(data) {
    return this.http.put(`${environment.time_entry_url}user_role`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  update_EmpRole(empProId, data) {
    return this.http
      .put(`${environment.time_entry_url}employee_project/${empProId}`, data,httpOptionsTimeEntry)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }
  //-----------------------------------------------------------------------

  //-----------------delete method sample--------------------------------

  deleteProject(id) {
    return this.http.delete(`${environment.time_entry_url}project/${id}`,httpOptionsTimeEntry).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  deleteResource(id) {
    return this.http.delete(`${environment.time_entry_url}employee/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteDeliverable(id) {
    return this.http.delete(`${environment.time_entry_url}deliverable/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteTeam(id) {
    return this.http.delete(`${environment.time_entry_url}team/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteTask(id) {
    return this.http.delete(`${environment.time_entry_url}task/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteCommonTask(id) {
    return this.http.delete(`${environment.time_entry_url}common_task/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteGenericTask(id) {
    return this.http.delete(`${environment.time_entry_url}generictask/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteSkill(id) {
    return this.http.delete(`${environment.time_entry_url}skill/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteTag(id) {
    return this.http.delete(`${environment.time_entry_url}tag/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteCustomer(id) {
    return this.http.delete(`${environment.time_entry_url}customer/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }
  deleteProjectStage(projectstageId) {
    return this.http
      .delete(`${environment.time_entry_url}project_stage/${projectstageId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
        // catchError(this.handleError)
      );
  }

  
  
  // Getmaxworkhours() {
  //   return this.http
  //     .get(`${environment.time_entry_url}maxworkhours/organization/${this.organzationid}`)
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //     );
  // }
  // updatemaxworkhours(organzationid, data) {
  //   return this.http
  //     .put(`${environment.time_entry_url}maxworkhours/${organzationid}`, data)
  //     .pipe(
  //       map((res) => res),
  //       timeout(globalTimeOut)
  //       // catchError(this.handleError)
  //     );
  // }

  createtaskemployee(taskdata: any) {
    return this.http.post(`${environment.time_entry_url}task/employeetask`, taskdata).pipe(
      map((res) => res),
      timeout(globalTimeOut)
      // catchError(this.handleError)
    );
  }

  ApprovalSubmit(fromDate: any, toDate: any, data: any) {
    return this.http.put(
      `${environment.time_entry_url}taskDetail/apporvalsubmit/${fromDate}/${toDate}`,
      data,httpOptionsTimeEntry
    );
  }
  updateBulkApprovalStatus(data: any) {
    return this.http.put(
      `${environment.time_entry_url}taskDetail/updateapprovalstatus`,
      data,
      httpOptionsTimeEntry
    );
  }

  //---------------------getmethod sample---------------------------------

  RegisetrOrgUser(data: any) {
    return this.http.post(
      `${environment.time_entry_url}organization/user/registration`,
      data
    );
  }

  Create_project(data: any) {
    return this.http.post(`${environment.time_entry_url}project`, data);
  }

  Create_mom(data: any) {
    return this.http.post(`${environment.time_entry_url}create/mom`, data,httpOptionsTimeEntry);
  }

  Create_Nonmom(data: any) {
    return this.http.post(`${environment.time_entry_url}mom`, data,httpOptionsTimeEntry);
  }

  Update_mom(momId, data: any) {
    return this.http.put(`${environment.time_entry_url}update/mom/${momId}`, data,httpOptionsTimeEntry);
  }

  Update_project(projectId: any, x: any) {
    return this.http.put(`${environment.time_entry_url}project/${projectId}`, x,httpOptionsTimeEntry);
  }

  Delete_project(projectId: any) {
    return this.http.delete(`${environment.time_entry_url}project/${projectId}`,httpOptionsTimeEntry);
  }

  get_projects() {
    return this.http.get(`${environment.time_entry_url}project`);
  }

  get_projectsByUserId(id: any) {
    return this.http.get(`${environment.time_entry_url}mom/user/${id}`);
  }

  get_actionItem() {
    return this.http.get(`${environment.time_entry_url}action-item`);
  }

  get_actionItemByProject(startDate, endDate,listValueId, projectId) {
    return this.http.get(
      `${environment.time_entry_url}action-item/${startDate}/${endDate}/${listValueId}/${projectId}`,httpOptionsTimeEntry
    );
  }

  get_actionItemByNonProject(startDate, endDate, liststyleId) {
    return this.http.get(
      `${environment.time_entry_url}action-item/non-project/${startDate}/${endDate}/${liststyleId}`,httpOptionsTimeEntry
    );
  }

  
  // get_momByProject(startDate, endDate, projectId) {
  //   return this.http.get(
  //     `${environment.time_entry_url}mom/${startDate}/${endDate}/${projectId}`
  //   );
  // }

  get_mombyproject(id: number) {
    let ul = `${environment.time_entry_url}mom/project/${id}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(ul, options);
  }

  get_mom() {
    let ul = `${environment.time_entry_url}mom`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(ul, options);
  }

  get_momById(momId) {
    return this.http.get(`${environment.time_entry_url}mom/${momId}`,httpOptionsTimeEntry);
  }

  get_NONProjectmom() {
    let ul = `${environment.time_entry_url}mom/non-project`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(ul, options);
  }

  get_location(userId: any) {
    return this.http.get(`${environment.time_entry_url}locationDetails/user/${userId}`);
  }

  login(formData) {
    return this.http.post<any>(`${environment.time_entry_url}login`, formData);
  }

  register(formData) {
    return this.http.post<any>(`${environment.time_entry_url}user`, formData);
  }

  UpdateEmployeeProfile(id: any, data: any) {
    return this.http.put(`${environment.time_entry_url}employee_image/${id}`, data);
  }

  UpdateActionItem(id: any, data: any) {
    return this.http.put(`${environment.time_entry_url}action-item/${id}`, data,httpOptionsTimeEntry);
  }

  //--------------------------------------------------------------------
  // private handleError(error: HttpErrorResponse) {
  //   console.log(error.error);
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error("An error occurred:", error.error.message);
  //     alert(`An error occurred: ${error.error.message}`);
  //     // alert('Something bad happened; please try again later.');
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,
  //     if (error.error === undefined) {
  //       alert(error.name);
  //     } else {
  //       if (error.error.message == undefined) {
  //         Swal.fire({
  //           position: "top-end",
  //           icon: "error",
  //           text: "net::ERR_CONNECTION_REFUSED",
  //           showConfirmButton: false,
  //           timer: 4500,
  //         });
  //       } else if (error.error.message != "No Data") {
  //         Swal.fire({
  //           position: "top-end",
  //           icon: "warning",
  //           text: error.error.message,
  //           showConfirmButton: false,
  //           timer: 4500,
  //         });
  //       } else {
  //         console.log(error);
  //       }
  //     }
  //     console.error(
  //       `Backend returned code ${error.error.statusCode}, ` +
  //         `body was: ${error.error.message}`
  //     );
  //   }
  //   // return an ErrorObservable with a user-facing error message
  //   return new ErrorObservable();
  //   // 'Something went wrong; please try again later.'
  // }

  
  onlyprojectApprovers(projectId: any) {
    return this.http.get(`${environment.base_url}projectapprovers_by_project/${projectId}`,httpOptionsHrms);
  }
  onlyApproverType(projectApproverId: any) {
    return this.http.get(`${environment.base_url}projectApprovers_by_approvers/approverId/${projectApproverId}`,httpOptionsHrms);
  }
  projectapprovertypeAll(projectApproverId: any,projectId:any) {
    return this.http.get(`${environment.base_url}projectApprovers_by_empolyee/approverId/project/${projectApproverId}/${projectId}`,httpOptionsHrms);
  }
  employeeProjectImport(body: any) {
    const httpOptionsTimeEntry = {
      headers: new HttpHeaders({
        Authorization: 'Basic ZW5vdGU6ZW5vdGUkdGltZUVudHJ5Iw==',
      }),
    }

    return this.http.post(`${environment.time_entry_url}employee-project/data/import`, body, httpOptionsTimeEntry);

    // let result = this.http.post(`${base_url}v2/employee/data/import/`,formData,ImporthttpOptions).pipe(map(data=>data))
    // return result;
}}

