import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssignEmpTaskComponent } from './assign-emp-task/assign-emp-task.component';
import { EmpTimesheetComponent } from './emp-timesheet.component';

const routes: Routes = [
  { path: '', component: EmpTimesheetComponent },
  { path:'assign-emp-task',component:AssignEmpTaskComponent},
  { path:'assign-emp-task/:id',component:AssignEmpTaskComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmpTimesheetRoutingModule { }
