import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { add } from 'timelite/time';
import { sub } from 'timelite/time';
import { DatePipe } from '@angular/common';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { EmpTimesheetModule } from '../emp-timesheet.module';
import { Location } from '@angular/common';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { aR } from '@fullcalendar/core/internal-common';
import {
  EMPTY,
  Subject,
  catchError,
  switchMap,
  takeUntil,
  throwError,
} from 'rxjs';
import { HeaderService } from 'src/app/providers/header.service';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { nonZeroHoursMinutesValidator } from '../../non-zero';

interface Project {
  projectId: string;
  projectName: string;
}

interface Task {
  project: Project;
}

interface ProjectList {
  [key: string]: {
    task: Task;
  };
}
@Component({
  selector: 'app-assign-emp-task',
  templateUrl: './assign-emp-task.component.html',
  styleUrls: ['./assign-emp-task.component.scss'],
})
export class AssignEmpTaskComponent implements OnInit {
  @ViewChild('picker') startDatePicker!: MatDatepicker<any>;
  @ViewChild('endDatePicker') endDatePicker!: MatDatepicker<any>;
  orgemployeedata: any;
  disableAssign: boolean = false;
  employeelist: any = ([] = []);
  taskId: number;
  taskForm: FormGroup;
  employeeList: any = [];
  employeeData: any;
  endDate_disable: boolean = true;
  min: any;
  empIdToPatch: number;
  arrayData: any[] = [];
  commonTaskId;
  tasKDetails: any;
  taskList: any[] = [];
  max: any;
  minStart: any;
  minData: any;
  maxdata: any;
  employeeTask: any[] = [];
  hourDisable: boolean;
  Id: any;
  consumedHours: any;
  consumedMinutes: any;
  assignedMinutes: any;
  assignedHours: any;
  empTaskId: any;
  myTask: any = [];
  employeetaskobject: any = {};
  employeetaskexist: boolean;
  submitted1 = false;
  employeeNotFound: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  // employee: any;
  today: any = new Date();
  employeelastdate: any;
  selectedemployeeId: any;
  employeeProjects: any[] = [];
  // employeeProjects: { employeeId: string, employeeNumber: string, firstName: string }[] = [];
  employeeId: any;
  projectId: any = 0;
  employeeNumber: any;
  disable_project: boolean = true;
  empList: any;
  projectList: any[] = [];
  empId: any;
  organizationId: any;
  error: boolean = false;
  errorMessage: string = '';
  sucess_msg: string;
  endSelectedFirst: boolean;
  buttonDisableOnSingleClick: boolean = true;
  employeeNamePatch: any;
  loader:boolean = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private gs: ProjectManagementService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private location: Location,
    private assignemp: AssignEmpService,
    private headerservice: HeaderService
  ) {
    let localData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = localData.employee.employeeId;
    this.organizationId = localData.organization.organizationId;
  }

  onSelectionChange() {
    // //this.showErrorMessage = true;;
    this.getTask();
  }
  getTask() {
    if (this.projectId) {
      this.disable_project = false;
      this.gs.getEmployeeProject(this.projectId).subscribe(
        (data: any) => {
          console.log('data', data);
          this.employeelist = data;

          // this.employeelist.push({data})
        },
        (error) => {
          console.error('Error fetching employee data:', error);
          // Handle the error (e.g., show an error message to the user)
        }
      );
    } else {
      this.employeelist.length = 0;
      this.orgemployeedata.forEach((element) => {
        let employee = {
          employee: {
            employeeId: element.employeeId,
            employeeName: element.employeeName,
          },
        };

        this.employeelist.push(employee);
      });
    }
  }

  ngOnInit() {
    this.headerservice.setTitle('');
    // this.headerservice.setTitle('Assign Employee Task');
    const data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = data.employeeId;
    this.assignemp.setSuccessUpdateMessage('');
    this.organizationId = data.organization.organizationId;
    // this.gs.listProjectList(this.organizationId).subscribe((x: any) => {
    //   this.projectList = x;

    //   console.log('projectList', this.projectList);
    // });

    // this.route.params.subscribe((params) => {
    //   this.taskId = params['id'];
    //   // Now, use taskId to fetch the task details or perform necessary actions.
    // });

    {
      this.CreateTaskFrom();
    }
    // this.gs.getResource().subscribe(
    //   (x: any) => {
    //       console.log(x);
    //       this.today =this.datepipe.transform(this.today, 'YYYY-MM-dd');
    //       for(let a of x){
    //         console.log(a.employeeLastWorkingDate);

    //         if(a.employeeLastWorkingDate != undefined){
    //           console.log("IF 1");

    //           this.employeelastdate = a.employeeLastWorkingDate
    //           console.log(this.employeelastdate);

    //           if(this.employeelastdate >= this.today){
    //             console.log("IF 2");
    //             this.employeeList.push(a);
    //             console.log(this.employeeList);
    //           }
    //         }
    //         else{
    //           this.employeeList.push(a);
    //           console.log(this.employeeList);
    //         }
    //       }
    //       console.log( this.employeeList);
    //   }
    // )

    this.route.paramMap.subscribe((x: any) => {
      console.log('xdata', x);

      this.empTaskId = x.get('id');
      console.log('empTaskId', this.empTaskId);
      console.log('xdata', this.empTaskId);
      console.log('this.empTaskId' + this.empTaskId);
      if (this.empTaskId != null) {
        this.loader = true;
        this.endDate_disable = false;
        this.gs.getEmployeeTaskById(this.empTaskId).subscribe((d: any) => {
          console.log('checkingd', d.task.project.projectId);
          this.gs
          .getProjectsByEmployee(d.employee.employeeId)
          .subscribe((x: any) => {
            this.projectList = x;
            this.gs.getTaskById(d.task.project.projectId).subscribe((x: any) => {
              this.gs.getEmployeeTaskById(this.empTaskId).subscribe((d: any) => {
                console.log('checkingd', d.task.project.projectId);
  
                this.gs
                  .getTaskById(d.task.project.projectId)
                  .subscribe((x: any) => {
                    // this.taskList.length=0;
                    // this.taskList = x;
                    // this.taskForm.patchValue({
                    //   task: x?.task?.taskId,
                    //   // Add other control values if necessary
                    // });
                    if (Array.isArray(x)) {
                      // this.taskList.length=0;
                      this.taskList = x;
                    } else if (typeof x === 'object' && x !== null) {
                      // this.taskList.length=0;
                      this.taskList = [x]; // Wrap the object in an array
                    } else {
                      // Handle unexpected data type
                      console.error('Unexpected data type for taskList:', x);
                    }
                    console.log('this.taskList', this.taskList);
                  });
  
                console.log('checking d', d);
  
                // this.gs.listProjectList(this.organizationId).subscribe((x: any) => {
                //   this.projectList = x;
  
                //   console.log('projectList', this.projectList);
                // });
                this.gs.getTask(d.task.taskId).subscribe((h: any) => {
                  let hour = 0;
                  let minute = 0;
                  this.minData = h.taskStartDate;
                  this.maxdata = h.taskEndDate;
                  if (h.taskHours != '') {
                    hour = h.taskHours
                      .map((a) => a.extrahours)
                      .reduce(function (a, b) {
                        return (
                          Number(('0' + a).slice(-2)) +
                          Number(('0' + b).slice(-2))
                        );
                      });
                    minute = h.taskHours
                      .map((a) => a.extraminutes)
                      .reduce(function (a, b) {
                        return (
                          Number(('0' + a).slice(-2)) +
                          Number(('0' + b).slice(-2))
                        );
                      });
                  } else {
                    hour = 0;
                    minute = 0;
                  }
  
                  let data: any = 0;
                  let data1: any = 0;
  
                  if (h.workhours) {
                    data = Number(h.workhours) + Number(hour);
                    data1 = Number(h.workminutes) + Number(minute);
                  } else {
                    data = Number(0) + Number(hour);
                    data1 = Number(0) + Number(minute);
                  }
  
                  if (data1 >= 60) {
                    data += Math.floor(data1 / 60);
                    data1 = data1 % 60;
                  }
  
                  data = ('0' + data).slice(-2);
                  data1 = ('0' + data1).slice(-2);
  
                  let x, y;
                  this.myTask = h.empolyeeTasks;
                  if (h.empolyeeTasks != '') {
                    x = h.empolyeeTasks
                      .map((a) => a.assignedhours)
                      .reduce(function (a, b) {
                        return (
                          Number(('0' + a).slice(-2)) +
                          Number(('0' + b).slice(-2))
                        );
                      });
                    y = h.empolyeeTasks
                      .map((a) => a.assignedminutes)
                      .reduce(function (a, b) {
                        return (
                          Number(('0' + a).slice(-2)) +
                          Number(('0' + b).slice(-2))
                        );
                      });
  
                    if (y >= 60) {
                      x += Math.floor(y / 60);
                      y = y % 60;
                    }
                    console.log(x, y);
                    this.consumedHours = x;
                    this.consumedMinutes = y;
                    this.taskForm.patchValue({
                      consumedhours: x,
                      consumedminutes: y,
                    });
                    let _totalHM = `${data}:${data1}`;
                    let _consumedHM = `${x}:${y}`;
                    let _availableHM = sub([_totalHM, _consumedHM]);
                    this.taskForm.patchValue({
                      avaliablehours: _availableHM[0],
                      avaliableminutes: _availableHM[1],
                    });
                  } else {
                    this.taskForm.patchValue({
                      consumedhours: 0,
                      consumedminutes: 0,
                    });
                    this.taskForm.patchValue({
                      avaliablehours: data,
                      avaliableminutes: data1,
                    });
                  }
  
                  this.hourDisable = false;
                  this.taskForm.patchValue({
                    totalhours: data,
                    totalminutes: data1,
                  });
                });
  
                console.log('chekcking projectid', d.task.project.projectId);
                console.log('checkingTaskId', d.task.taskId);
                console.log('employeeId', d.employee.employeeId);
  
                this.empIdToPatch = d.employee.employeeId;
                console.log('d', d);
                this.tasKDetails = d;
             
                // this.taskForm.controls['task'].setValue(d.task.taskId);
                this.taskForm.controls['startdate'].setValue(d.startdate);
                this.taskForm.controls['enddate'].setValue(d.enddate);
                this.employeeNamePatch =
                  d.employee.employeeNumber + '-' + d.employee.firstName;
                this.taskForm.patchValue({
                  employee: this.employeeNamePatch,
                  project: d.task.project.projectId,
                  task: d?.task?.taskId,
                  //      avaliablehours: d.avaliablehours,
                  //     avaliableminutes: d.avaliableminutes,
  
                  assignedhours: d.assignedhours,
                  assignedminutes: d.assignedminutes,
                  // consumedhours: d.consumedhours,
                  // consumedminutes: d.consumedminutes,
                  startdate: d.startdate,
                  enddate: d.enddate,
                });
  
                this.loader = false;
                //       this.assignedHours = d.assignedhours
                //       this.assignedMinutes = d.assignedminutes
              });
              // this.taskList.length=0;
              // this.taskList = x;
  
              if (Array.isArray(x)) {
                // this.taskList.length=0;
                this.taskList = x;
              } else if (typeof x === 'object' && x !== null) {
                // this.taskList.length=0;
                this.taskList = [x]; // Wrap the object in an array
              } else {
                // Handle unexpected data type
                console.error('Unexpected data type for taskList:', x);
              }
              console.log('this.taskList', this.taskList);
              // this.taskForm.patchValue({
              //   task: x?.task?.taskId,
              //   // Add other control values if necessary
              // });
            });
            console.log('projectList', this.projectList);
          });
         

          console.log('checking d', d);

          // this.gs.listProjectList(this.organizationId).subscribe((x: any) => {
          //   this.projectList = x;

          //   console.log('projectList', this.projectList);
          // });
          this.gs.getTask(d.task.taskId).subscribe((h: any) => {
            let hour = 0;
            let minute = 0;
            this.minData = h.taskStartDate;
            this.maxdata = h.taskEndDate;
            if (h.taskHours != '') {
              hour = h.taskHours
                .map((a) => a.extrahours)
                .reduce(function (a, b) {
                  return (
                    Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                  );
                });
              minute = h.taskHours
                .map((a) => a.extraminutes)
                .reduce(function (a, b) {
                  return (
                    Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                  );
                });
            } else {
              hour = 0;
              minute = 0;
            }

            let data: any = 0;
            let data1: any = 0;

            if (h.workhours) {
              data = Number(h.workhours) + Number(hour);
              data1 = Number(h.workminutes) + Number(minute);
            } else {
              data = Number(0) + Number(hour);
              data1 = Number(0) + Number(minute);
            }

            if (data1 >= 60) {
              data += Math.floor(data1 / 60);
              data1 = data1 % 60;
            }

            data = ('0' + data).slice(-2);
            data1 = ('0' + data1).slice(-2);

            let x, y;
            this.myTask = h.empolyeeTasks;
            if (h.empolyeeTasks != '') {
              x = h.empolyeeTasks
                .map((a) => a.assignedhours)
                .reduce(function (a, b) {
                  return (
                    Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                  );
                });
              y = h.empolyeeTasks
                .map((a) => a.assignedminutes)
                .reduce(function (a, b) {
                  return (
                    Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                  );
                });

              if (y >= 60) {
                x += Math.floor(y / 60);
                y = y % 60;
              }
              console.log(x, y);
              this.consumedHours = x;
              this.consumedMinutes = y;
              this.taskForm.patchValue({
                consumedhours: x,
                consumedminutes: y,
              });
              let _totalHM = `${data}:${data1}`;
              let _consumedHM = `${x}:${y}`;
              let _availableHM = sub([_totalHM, _consumedHM]);
              this.taskForm.patchValue({
                avaliablehours: _availableHM[0],
                avaliableminutes: _availableHM[1],
              });
            } else {
              this.taskForm.patchValue({
                consumedhours: 0,
                consumedminutes: 0,
              });
              this.taskForm.patchValue({
                avaliablehours: data,
                avaliableminutes: data1,
              });
            }

            this.hourDisable = false;
            this.taskForm.patchValue({
              totalhours: data,
              totalminutes: data1,
            });
          });

          console.log('chekcking projectid', d.task.project.projectId);
          console.log('checkingTaskId', d.task.taskId);
          console.log('employeeId', d.employee.employeeId);

          this.empIdToPatch = d.employee.employeeId;
          console.log('d', d);
          this.tasKDetails = d;
          this.gs
            .getProjectsByEmployee(d.employee.employeeId)
            .subscribe((x: any) => {
              this.projectList = x;

              console.log('projectList', this.projectList);
            });
          // this.taskForm.controls['task'].setValue(d.task.taskId);
          x;

          //       this.assignedHours = d.assignedhours
          //       this.assignedMinutes = d.assignedminutes
        });
      }
    });
  }
  // getEmployeeProjectsIdValues() {
  //   return Object.values(this.projectList);
  // }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.selectedemployeeId = null; // Set selectedemployeeId to null
  }
  empSearch(event, event1: KeyboardEvent): void {
    event1.preventDefault();
    // console.log('');

    if (event1.key === '') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      console.log(event.target.value);
      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log(employeenumber);

      // Cancel the previous API request
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();

      if (employeenumber.length == 0) {
        this.employeeNotFound = false;
        console.log('enterd into the condition!!');
        this.employeeId = undefined;
        this.employeeData = undefined;
      }
      // Employee List Api
      if (employeenumber.length >= 2) {
        this.gs
          .getEmployeesBynumber(employeenumber)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            switchMap(() => this.gs.getEmployeesBynumber(employeenumber)),
            catchError((err) => {
              if (err.error.status === 500) {
                this.projectId = [];
                this.employeeNotFound = true;
              }
              console.error('Error fetching employees:', err);
              // Handle the error here, for example, showing an alert
              // alert('Error fetching employees. Please try again later.');
              return throwError(err); // Rethrow the error to propagate it downstream
            })
          )
          .subscribe((result: any) => {
            console.log('result', result);

            if (result.statusCode === 204) {
              this.employeeNotFound = true;
            }
            this.employeeProjects.length = 0;

            result.forEach((element) => {
              let employee = {
                employee: {
                  employeeId: element.employeeId,
                  firstName: element.firstName,
                  employeeNumber: element.employeeNumber,
                },
              };

              this.employeeProjects.push(employee);
              console.log('this.employeeProjects', this.employeeProjects);
            });

            // if (this.selectedemployee.length >= 1) {
            //   this.employeeSelected = true;
            // }

            if (result.errorCode === 204) {
              // alert('No Employee Found');
              this.employeeNotFound = true;
              this.employeeProjects.length = 0;
              this.employeeProjects.push('No Employee Found');
              this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
            }
          });
      }
    }
  }

  empSelect(emp) {
    this.projectList = []; // Clear projectList
    this.employeeProjects = []; // Clear employeeProjects
    this.employeeId = ''; // Reset employeeId
    this.employeeData = ''; // Reset employeeData
    this.employeeNumber = ''; // Reset employeeNumber
    this.taskList = [];
    console.log('emp', emp);
    this.employeeId = emp.employee.employeeId;
    this.employeeData =
      emp.employee.employeeNumber + '-' + emp.employee.firstName;
    this.employeeNumber = emp.employee.employeeNumber;

    console.log('this.employeeId', this.employeeId);

    this.gs.getProjectsByEmployee(this.employeeId).subscribe((x: any) => {
      if (Array.isArray(x)) {
        // Check if x is an array
        this.projectList = x;
      } else {
        console.error('Projects not returned as array:', x);
      }

      console.log('projectList', this.projectList);
    });

    // Patch the form with employee data
    this.taskForm.patchValue({
      employee: this.employeeData,
    });

    console.log('patching', this.taskForm.controls['employee'].value);
  }

  get f() {
    return this.taskForm.controls;
  }
  get project() {
    return this.taskForm.get('project');
  }
  get employee() {
    return this.taskForm.get('employee');
  }
  get task() {
    return this.taskForm.get('task');
  }
  // get avaliablehours()
  // {
  //   return this.taskForm.get('avaliablehours');
  // }
  // get avaliableminutes()
  // {
  //   return this.taskForm.get('avaliableminutes');
  // }
  // get consumedhours()
  // {
  //   return this.taskForm.get('consumedhours');
  // }
  // get consumedminutes()
  // {
  //   return this.taskForm.get('consumedminutes');
  // }
  get assignedhours() {
    return this.taskForm.get('assignedhours');
  }
  get assignedminutes() {
    return this.taskForm.get('assignedminutes');
  }
  get startdate() {
    return this.taskForm.get('startdate');
  }
  get enddate() {
    return this.taskForm.get('enddate');
  }
  empData(emp) {
    console.log('emp', emp);
    // this.projectList = [];
    // this.gs.listProjectList( this.organizationId).subscribe(
    //   (x: any) => {
    //     this.projectList = x;

    //     console.log('projectList',this.projectList);

    //   }
    // )

    // this.gs.getEmpProjectList(emp.employeeId).subscribe(
    //   (x: any) => {
    //     this.projectList = x;

    //     console.log('this.projectList',this.projectList);

    //   } ,  err =>{
    //      this.projectList.length = 0;
    //      this.taskForm.patchValue({
    //       project: null

    //     });
    //   }
    // )
  }

  CreateTaskFrom() {
    this.taskForm = this.fb.group({
      project: ['', Validators.required],
      task: ['', Validators.required],

      employee: ['', Validators.required],
      assignedhours: ['', Validators.required],
      assignedminutes: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
    });
    this.taskForm.valueChanges.subscribe(() => {
      const hours = this.taskForm.get('assignedhours').value;
      const minutes = this.taskForm.get('assignedminutes').value;

      if (parseInt(hours) === 0 && parseInt(minutes) === 0) {
        alert('Both hours and minutes cannot be zero');
        this.taskForm.get('assignedhours').setValue('');
        this.taskForm.get('assignedminutes').setValue('');
      }
    });
  }
  onlyThreeDigitsValidator() {
    return (control) => {
      const value = control.value;
      if (!/^\d{3}$/.test(value)) {
        return { onlyThreeDigits: true };
      }
      return null;
    };
  }

  handleInput(event) {
    event.preventDefault();
    alert('Pasting is not allowed!');
    return false;
  }

  handleBackspace(event): void {
    const value = this.taskForm.get('employee').value;
    this.employeetaskexist = false;
    const inputValue = event.target.value;

    if (event.key === 'Backspace' && inputValue.length <= 0) {
      this.selectedemployeeId = undefined;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.taskForm.reset();
      this.employeetaskexist = false;
      this.projectList = [];
      this.taskList = [];
      this.employeetaskexist = false;
      // this.employee = undefined;
      this.employeeNumber = undefined;
      // this.selectedemployee = undefined;
      this.employeeNotFound = false; // Set employeeNotFound to false
    }

    if (
      event.key === 'Backspace' &&
      !value &&
      !this.taskForm?.get('employee').value.trim()
    ) {
      this.employeeNotFound = true;
      this.taskForm.reset();
      this.projectList = [];
      this.employeetaskexist = false;
      this.employeetaskexist = false;
    } else {
      this.employeeNotFound = false;
    }
  }

  removeSpaces(string) {
    return string.split(' ').join('');
  }
  pasteCount: number = 0;
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }

  onInput(content: string) {
    console.log('New content: ', content);
  }

  projectData(pro) {
    this.taskList = [];
    this.employeetaskexist = false;
    this.taskForm.controls['task'].reset();
    this.taskForm.controls['startdate'].reset();
    this.taskForm.controls['enddate'].reset();
    console.log('pro', pro);
    this.gs.getTaskById(pro.projectId).subscribe((x: any) => {
      console.log('Received task data:', x); // Log the received data
    
      if (Array.isArray(x)) {
        this.taskList = x;
      } else if (typeof x === 'object' && x !== null) {
        this.taskList = [x]; // Wrap the object in an array
      } else {
        // Handle unexpected data type
        console.error('Unexpected data type for taskList:', x);
        this.taskList = []; // Default to an empty array
      }
    
      console.log('this.taskList', this.taskList);
    
      this.taskForm.patchValue({
        task: x?.task?.taskId,
        // Add other control values if necessary
      });
    });
    

    // this.min = new Date(pro.task.project.projectStartDate);
    // this.max = new Date(pro.task.project.projectEndDate);
  }
  onKeyDown(event: KeyboardEvent) {
    // Check if the keyboard shortcut for paste is used (Ctrl + V for Windows/Linux, Cmd + V for macOS)
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      event.preventDefault();
    }
  }
  //   onPaste(event: ClipboardEvent) {
  //     event.preventDefault();
  // }
  taskData(h) {
    console.log('h', h);

    if (!h || !h.taskStartDate) {
      console.error('Invalid data:', h);
      return; // or handle the error in a different way
    }

    this.min = h.taskStartDate;
    this.max = h.taskEndDate;

    console.log('max,min', this.min, this.max);

    this.minData = h.taskStartDate;
    this.maxdata = h.taskEndDate;
    let hour = 0;
    let minute = 0;
    if (h.taskHours != '') {
      hour = h.taskHours
        .map((a) => a.extrahours)
        .reduce(function (a, b) {
          return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2));
        });
      minute = h.taskHours
        .map((a) => a.extraminutes)
        .reduce(function (a, b) {
          return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2));
        });
    } else {
      hour = 0;
      minute = 0;
    }

    let data: any = 0;
    let data1: any = 0;

    if (h.workhours) {
      data = Number(h.workhours) + Number(hour);
      data1 = Number(h.workminutes) + Number(minute);
    } else {
      data = Number(0) + Number(hour);
      data1 = Number(0) + Number(minute);
    }

    if (data1 >= 60) {
      data += Math.floor(data1 / 60);
      data1 = data1 % 60;
    }

    data = ('0' + data).slice(-2);
    data1 = ('0' + data1).slice(-2);

    let x, y;
    if (h.empolyeeTasks != '') {
      x = h.empolyeeTasks
        .map((a) => a.assignedhours)
        .reduce(function (a, b) {
          return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2));
        });
      y = h.empolyeeTasks
        .map((a) => a.assignedminutes)
        .reduce(function (a, b) {
          return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2));
        });

      if (y >= 60) {
        x += Math.floor(y / 60);
        y = y % 60;
      }
      this.consumedHours = x;
      this.consumedMinutes = y;
      this.taskForm.patchValue({
        consumedhours: x,
        consumedminutes: y
      });
      let _totalHM = `${data}:${data1}`;
      let _consumedHM = `${x}:${y}`;
      let _availableHM = sub([_totalHM, _consumedHM]);
      this.taskForm.patchValue({
        avaliablehours: _availableHM[0],
        avaliableminutes: _availableHM[1],
      });
    } else {
      this.taskForm.patchValue({
        consumedhours: 0,
        consumedminutes: 0,
      });
      this.taskForm.patchValue({
        avaliablehours: data,
        avaliableminutes: data1,
      });
    }

    this.hourDisable = false;
    this.taskForm.patchValue({
      totalhours: data,
      totalminutes: data1,
      assignedhours: '',
      assignedminutes: '',
    });
  }

  set(e) {
    let x = this.taskForm.value;
    if (this.empTaskId) {
      let c = 0;
      let d = 0;
      let arr = new Array();
      this.myTask.map((e) => {
        if (e.emptaskId != this.empTaskId) {
          arr.push(e);
          c = arr
            .map((a) => a.assignedhours)
            .reduce(function (a, b) {
              return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2));
            });
          d = arr
            .map((a) => a.assignedminutes)
            .reduce(function (a, b) {
              return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2));
            });
        }
      });
      if (d >= 60) {
        c += Math.floor(d / 60);
        d = d % 60;
      }
      if (x.assignedhours != '' && x.assignedminutes != '') {
        let newH: any = `${('0' + x.assignedhours).slice(-2)}:${(
          '0' + x.assignedminutes
        ).slice(-2)}`;
        let oldH: any = `${('0' + c).slice(-2)}:${('0' + d).slice(-2)}`;
        let total: any = add([newH, oldH]);
        var startTimeObject = `${total[0]}:${total[1]}`;
        let x1 = ('0' + total[0]).slice(-2);
        let x2 = ('0' + total[1]).slice(-2);
        startTimeObject = `${x1}:${x2}`;

        this.taskForm.patchValue({
          consumedhours: total[0],
          consumedminutes: total[1],
        });

        //Create date object and set the time to that
        var endTimeObject = `${x.totalhours}:${x.totalminutes}`;
        let y1 = ('0' + x.totalhours).slice(-2);
        let y2 = ('0' + x.totalminutes).slice(-2);
        endTimeObject = `${y1}:${y2}`;

        if (startTimeObject > endTimeObject) {
          alert(
            `Consumed Time ${x.consumedhours}:${x.consumedminutes} Should be less than Total Time ${x.totalhours}:${x.totalminutes}`
          );
          return;
        } else {
          let d: any = `${('0' + total[0]).slice(-2)}:${('0' + total[1]).slice(
            -2
          )}`;
          let e: any = `${('0' + x.totalhours).slice(-2)}:${(
            '0' + x.totalminutes
          ).slice(-2)}`;
          let data: any = 0;
          data = sub([e, d]);
          this.taskForm.patchValue({
            avaliablehours: data[0],
            avaliableminutes: data[1],
          });
        }
      }
    } else {
      let CM: any = 0;
      let CH: any = 0;
      let a: any;
      let b: any;
      if (x.assignedhours != '' && x.assignedminutes != '') {
        a = `${('0' + x.assignedhours).slice(-2)}:${(
          '0' + x.assignedminutes
        ).slice(-2)}`;
        b = `${('0' + x.avaliablehours).slice(-2)}:${(
          '0' + x.avaliableminutes
        ).slice(-2)}`;
        CM = sub([b, a]);
      }

      if (a > b) {
        alert(
          `Assigned Time ${x.assignedhours}:${x.assignedminutes} should be less than Available Time ${x.avaliablehours}:${x.avaliableminutes}`
        );
        return;
      } else {
        console.log(x);
        if (x.assignedhours != '' && x.assignedminutes != '') {
          let c: any = `${('0' + this.consumedHours).slice(-2)}:${(
            '0' + this.consumedMinutes
          ).slice(-2)}`;
          CH = add([a, c]);
          console.log(a, c);
          this.taskForm.patchValue({
            consumedhours: CH[0],
            consumedminutes: CH[1],
          });
          let d: any = `${('0' + CH[0]).slice(-2)}:${('0' + CH[1]).slice(-2)}`;
          let e: any = `${('0' + x.totalhours).slice(-2)}:${(
            '0' + x.totalminutes
          ).slice(-2)}`;
          let data: any = 0;
          data = sub([e, d]);
          this.taskForm.patchValue({
            avaliablehours: data[0],
            avaliableminutes: data[1],
          });
        }
      }
    }
  }

  // keys

  omit_special_char(event) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return (
      (n > 'A' && n < 'z') ||
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57) ||
      k === 1
    );
  }
  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // noNumber(event: any) {
  //   const pattern = /[0-9]|\./;
  //   const inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode !== 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }

  noNumber(event: any) {
    const pattern = /[0-9]/; // Removed the `\.` from the pattern
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }

  updateEndDateMin() {
    console.log('eventlog', event);

    let startDate = this.taskForm.get('startdate').value;
    let endDate = this.taskForm.get('enddate').value;

    let startDate1 = this.datepipe.transform(startDate, 'YYYY-MM-dd');
    let endDate1 = this.datepipe.transform(endDate, 'YYYY-MM-dd');
    console.log('startdate', startDate);
    console.log('endDate', endDate);

    // Check if start date is greater than end date
    if (startDate1 && endDate1 && endDate1 < startDate1) {
      // Set error state for the end date control
      this.taskForm.get('enddate').setErrors({ endDateInvalid: true });
      // Alert the user
      alert('Start date cannot be greater than end date');

      this.taskForm.controls['startdate'].reset();
      this.taskForm.controls['enddate'].reset();
      this.startDatePicker.close();
      this.endDatePicker.close();
    } else {
      // Reset error if valid
      this.taskForm.get('enddate').setErrors(null);
    }

    // Other code...
  }

  // date validation
  someMethodName(date: any) {
    this.minStart = new Date(date.value);
    this.endDate_disable = false;
  }

  cancel() {
    // event.preventDefault();

    this.assignemp.setSuccessUpdateMessage('');
    // this.location.back();
    this.router.navigate(['/home/emp-timesheet']);

    // Your cancel logic here
  }

  Create_task() {
    console.log('taskForm', this.taskForm.value);
    this.submitted1 = true;
    this.buttonDisableOnSingleClick = false;

    // this.taskForm.patchValue({
    //   employee: this.employeeNamePatch,
    // });

    if (this.employeeNamePatch == undefined) {
      this.taskForm.patchValue({
        employee: this.employeeData,
      });
    }

    this.employeeNotFound = false;
    console.log('taskForm', this.taskForm.value);
    console.log('valid', this.taskForm.valid);
    if (this.taskForm.valid && this.employeetaskexist == true) {
      console.log('valid ');

      this.disableAssign = true;
      return;
    }

    // console.log("form sumbitted");

    let x = this.taskForm.value;
    if (x.startdate) {
      //start date
      let a = x.startdate;
      let i = new Date(a);
      i.setMinutes(i.getMinutes() + 480);
      let j = i.toISOString();
      x['startdate'] = j;
    }
    if (x.enddate) {
      //end date
      let b = x.enddate;
      let y = new Date(b);
      y.setMinutes(y.getMinutes() + 480);
      let t = y.toISOString();
      x['enddate'] = t;
    }

    if (this.empTaskId) {
      // let x1 =('0' + x.consumedhours).slice(-2)
      // let x2 =('0' + x.consumedminutes).slice(-2)
      // var startTimeObject = `${x1}:${x2}`
      // let y1 =('0' + x.totalhours).slice(-2)
      // let y2 =('0' + x.totalminutes).slice(-2)
      // var endTimeObject = `${y1}:${y2}`
      // console.log(startTimeObject,endTimeObject)
      // if(startTimeObject > endTimeObject){
      //   alert(`Consumed Time ${x.consumedhours}:${x.consumedminutes} Should be less than Total Time ${x.totalhours}:${x.totalminutes}`)
      //   window.location.reload()
      //   return
      // }
      // let c = 0
      // let d = 0
      // let arr = new Array()
      // this.myTask.map(
      //   e=>{
      //     if(e.emptaskId != this.empTaskId){
      //       arr.push(e)
      //       c = arr.map(a => a.assignedhours).reduce(function (a, b) {
      //         return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
      //       })
      //       d = arr.map(a => a.assignedminutes).reduce(function (a, b) {
      //         return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
      //       })
      //     }
      //   }
      // )
      // if (d >= 60) {
      //   c += Math.floor(d / 60);
      //   d = d % 60;
      // }
      // if (x.assignedhours != '' && x.assignedminutes != '') {
      //   let newH:any = `${('0' + x.assignedhours).slice(-2)}:${('0' + x.assignedminutes).slice(-2)}`
      //   let oldH:any = `${('0' + c).slice(-2)}:${('0' + d).slice(-2)}`
      //   let total:any = add([newH,oldH])
      //   var startTimeObject = `${total[0]}:${total[1]}`
      //   let x1 =('0' + total[0]).slice(-2)
      //   let x2 =('0' + total[1]).slice(-2)
      //   startTimeObject = `${x1}:${x2}`
      //   this.taskForm.patchValue({
      //     consumedhours:total[0],
      //     consumedminutes:total[1]
      //   })
      //   //Create date object and set the time to that
      //   var endTimeObject =`${x.totalhours}:${x.totalminutes}`
      //   let y1 =('0' + x.totalhours).slice(-2)
      //   let y2 =('0' + x.totalminutes).slice(-2)
      //   endTimeObject = `${y1}:${y2}`
      //   if(startTimeObject > endTimeObject){
      //     alert(`Consumed Time ${x.consumedhours}:${x.consumedminutes} Should be less than Total Time ${x.totalhours}:${x.totalminutes}`)
      //     window.location.reload()
      //     return
      //   }
      //   else{
      //     let d: any = `${('0' + total[0]).slice(-2)}:${('0' + total[1]).slice(-2)}`
      //     let e: any = `${('0' + x.totalhours).slice(-2)}:${('0' + x.totalminutes).slice(-2)}`
      //     let data:any = 0;
      //     data = sub([e,d])
      //     this.taskForm.patchValue({
      //       avaliablehours: data[0],
      //       avaliableminutes: data[1]
      //     })
      //   }
      // }
    } else {
      let CM: any = 0;
      let CH: any = 0;
      let a: any = `${('0' + x.assignedhours).slice(-2)}:${(
        '0' + x.assignedminutes
      ).slice(-2)}`;
      let b: any = `${('0' + x.avaliablehours).slice(-2)}:${(
        '0' + x.avaliableminutes
      ).slice(-2)}`;
      CM = sub([b, a]);
      // if (a > b) {
      //   alert(`Assigned Time ${x.assignedhours}:${x.assignedminutes} should be less than Available Time ${x.avaliablehours}:${x.avaliableminutes}`)
      //   return
      // }
      // else{
      if (x.assignedhours != '' && x.assignedminutes != '') {
        let c: any = `${('0' + this.consumedHours).slice(-2)}:${(
          '0' + this.consumedMinutes
        ).slice(-2)}`;
        CH = add([a, c]);
        this.taskForm.patchValue({
          consumedhours: CH[0],
          consumedminutes: CH[1],
        });
        let d: any = `${('0' + CH[0]).slice(-2)}:${('0' + CH[1]).slice(-2)}`;
        let e: any = `${('0' + x.totalhours).slice(-2)}:${(
          '0' + x.totalminutes
        ).slice(-2)}`;
        let data: any = 0;
        data = sub([e, d]);
        this.taskForm.patchValue({
          avaliablehours: data[0],
          avaliableminutes: data[1],
        });
        if (d > e) {
          alert('You have assigned maximum number of hours');
          this.taskForm.patchValue({
            avaliablehours: 0,
            avaliableminutes: 0,
          });
          window.location.reload();
          return;
        }
      }

      // }
    }

    var arrayData = new Array();
    console.log(x);

    let assignedhours = Number(x.assignedhours);
    let assignedMinutes = Number(x.assignedminutes);

    if (assignedMinutes >= 60) {
      assignedhours += Math.floor(assignedMinutes / 60);
      assignedMinutes = assignedMinutes % 60;
    }

    console.log('assignedhours' + assignedhours);
    console.log('assignedMinutes' + assignedhours);

    let data = [
      {
        task: {
          taskId: x.task,
        },

        empolyeeId: this.employeeId,
        startdate: x.startdate,
        enddate: x.enddate,
        assignedhours: assignedhours,
        assignedminutes: assignedMinutes,
        estimatedHours: assignedhours,
        estimatedMinutes: assignedMinutes,
        organizationId: this.organizationId,

        status: {
          listTypeValueId: 1,
          listTypeValueName: 'Active',
        },
      },
    ];

    let UpdateData = {
      emptaskId: this.empTaskId,
      task: {
        taskId: x.task,
      },

      empolyeeId: this.empIdToPatch,
      startdate: x.startdate,
      enddate: x.enddate,
      assignedhours: assignedhours,
      assignedminutes: assignedMinutes,
      estimatedHours: assignedhours,
      estimatedMinutes: assignedMinutes,
      organizationId: this.organizationId,

      status: {
        listTypeValueId: 1,
        listTypeValueName: 'Active',
      },
    };

    if (this.empTaskId) {
      console.log('arrayData', UpdateData);
      data['emptaskId'] = this.empTaskId;

      console.log('this.empTaskId', this.empTaskId);

      console.log(JSON.stringify(data));
      // return
      this.gs
        .updateEmployeeTask(this.empTaskId, UpdateData)
        .subscribe((x: any) => {
          // Swal.fire({
          //   text: x.message,
          //   icon: 'success',
          // });

          console.log('x', x);

          if (x.StatusCode === 200) {
            this.sucess_msg = 'Employee Task Updated Successfully';
            this.assignemp.setSuccessUpdateMessage(
              'Employee Task Updated Successfully'
            );
            // this.location.back();
            this.router.navigate(['/home/emp-timesheet']);
          }

          // if(x.statusCode === 409 || x.StatusCode === 409 ){
          //   this.errorMessage = 'Task is Already Assigned for the Empolyees';
          //   this.assignemp.setErrorMessage(
          //     'Task is Already Assigned for the Empolyees'
          //   );
          //   this.location.back();
          // }
          // this.router.navigate(['/edit-task'])
          // this.router.navigate(['./assign-task',  this.taskId]);
          // this.router.navigate(['./emp-timesheet']);
        });
    } else {
      this.gs.assignEmployee(data).subscribe((a: any) => {
        // Swal.fire({
        //   icon: 'success',
        //   text: a.message,
        // });
        if (a.StatusCode === 200) {
          this.sucess_msg = 'Employee Task Assigned Successfully';
          this.assignemp.setSuccessMessage(
            'Employee Task Assigned Successfully'
          ); // Call method to set success message
          // this.router.navigate(['/edit-task'])
          this.router.navigate(['/home/emp-timesheet']);
          // this.location.back();
        }

        // if(a.errorCode === 409){
        //   this.errorMessage = 'Empolyee  Already Assigned To Task';
        //   this.assignemp.setErrorMessage('Empolyee  Already Assigned To Task'); // Call method to set success message
        //   // this.router.navigate(['/edit-task'])
        //   // this.router.navigate(['./emp-timesheet']);
        //   this.location.back();
        // }
      });
    }
  }

  empolyeeTaskvalidate() {
    console.log('employee : ' + this.taskForm.value.employee);

    if (!this.empTaskId) {
      if (this.taskForm.value.employee != 0 && this.taskForm.value.task != 0) {
        this.gs
          .getEmployeetaskvalidate(this.employeeId, this.taskForm.value.task)
          .subscribe((a) => {
            console.log(a);
            this.employeetaskobject = a;
            if (this.employeetaskobject.isexist) {
              console.log('role name isexist');
              this.employeetaskexist = true;
            } else {
              this.employeetaskexist = false;
            }
          });
      } else {
        this.employeetaskexist = false;
      }
    }
  }
}
