import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { DatePipe } from '@angular/common';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-manage-approver',
  templateUrl:'./manage-approver.component.html',
  styleUrls: ['./manage-approver.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ManageApproverComponent implements OnInit {
  approverform:FormGroup;
  submitted:boolean = false;
  organizationId:any;
  employeeList:any;
  min:any;
  max:any;
  hasduplicate :any;
  hasduplicatefyi:any;
  ruleNameexist: boolean;
  approverId:any;
  ruleNameobject: any  = {};
  success:any=false;
  error:any=false;
  loader:any= false;
  create_sucess_msg: any;
  update_error_msg: any;
  btnDisable:boolean=false;
  form_loader:boolean =true;
  backdrop:boolean = true;
  endDate_disable: boolean = true;
  show_error_msg: boolean=false;

  constructor(
  private router : Router,
  private approverservice :GlobalserviceService,
  private fb :FormBuilder,
  private ActivatedRoute:ActivatedRoute,
  private datepipe:DatePipe,
  ) { }

  ngOnInit(): void {
    this.form_loader=false;
    let  localdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.submitted = false;
    this.organizationId = localdata.organization.organizationId;
    console.log(this.organizationId);
    
    // this.approverservice
    // .getAllEmployeeByOrganization(this.organizationId)
    // .subscribe((data:any) =>
    // {
    //   this.employeeList = data;
    // });

    this.approverform = this.fb.group({
        rulename: ['', [Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
        startdate: ['', Validators.required],
        enddate: ['', Validators.required],
        fyi: ['', Validators.required],
        approver: this.fb.array([
          this.fb.group({
            approver1: ['', Validators.required]
          })
        ])
    });

    this.ActivatedRoute.paramMap.subscribe(
      (d)=>{
        this.approverId = d.get('id');
        console.log(this.approverId);
        this.loader = false;
        if(this.approverId)
        {
          this.form_loader=true;
          this.approverservice.getApproversById(this.approverId).subscribe(
            (x:any)=>{
              console.log(x);

              this.approverservice
              .getAllEmployeeByOrganization(this.organizationId)
              .subscribe((data:any) =>
              {
                this.employeeList = data;
                console.log("  this.employeeList::",this.employeeList);
                
                  this.loader = false;
                this.approverform.patchValue({
                  rulename:x.rulename,
                  startdate:x.startDate,
                  enddate:x.endDate,
                  fyi:x.fyiObject.employeeId,

                })
              //   this.approverform.setControl('approver',this.setApprover(x));
              //   this.form_loader = false;
              //   this.backdrop = false;
              // });
              const approverFormArray = this.setApprover(x);
              this.approverform.setControl('approver', approverFormArray);
      
              this.form_loader = false;
              this.backdrop = false;
            });
            
            }
          )
        }else{
          this.approverservice
          .getAllEmployeeByOrganization(this.organizationId)
          .subscribe((data:any) =>
          {
            this.employeeList = data;
            this.form_loader=false;
            this.backdrop = false;
          });

        }
      })

  }
  onSelectionChange(event: any) {
    console.log('Selected value:', event.value);
    let k = this.approverform.value;
    console.log(k);
    this.hasduplicate = this.checkForDuplicates(k.approver);
    this.hasduplicatefyi = false; 
    for (let i = 0; i < k.approver.length; i++) 
      {
        console.log("k.fyi",k.fyi);
        if(k.fyi != ""){
          if(k.fyi == k.approver[i].approver1)
            {
              this.hasduplicatefyi = true;
              console.log(this.hasduplicatefyi);
            }
        }
      }
  }
  // onSelectionChangeFYI(event: any) {
  //   console.log('Selected value:', event.value);
  //   let k = this.approverform.value;
  //   console.log(k);
  //   this.hasduplicate = this.checkForDuplicates(k.approver);
  //   this.hasduplicatefyi = false; 
  //   for (let i = 0; i < k.approver.length; i++) 
  //     {
  //       if(k.fyi == k.approver[i].approver1)
  //       {
  //         this.hasduplicatefyi = true;
  //         console.log(this.hasduplicatefyi);
  //       }
  //     }
  // }
  submit(){
    this.submitted =true;
    this.error = false;
    this.success = false;
    let k = this.approverform.value;
    console.log(k);
    if(this.approverform.invalid){
      return
    } 
    this.btnDisable=true;
   
      this.hasduplicate = this.checkForDuplicates(k.approver);
      this.hasduplicatefyi = false; 

      for (let i = 0; i < k.approver.length; i++) 
      {
        if(k.fyi == k.approver[i].approver1)
        {
          this.hasduplicatefyi = true;
          console.log(this.hasduplicatefyi);
        }
      }
    
      console.log()

    if (this.approverform.invalid &&  (this.hasduplicate ||  this.hasduplicatefyi)) {
      this.btnDisable=false;
      return
    }else if(this.approverform.valid && (this.hasduplicate ||  this.hasduplicatefyi))
    {
      this.btnDisable=false;
      return
    }else if(this.ruleNameexist){
      this.btnDisable=false;
        return
    }

    // this.loader=true;
    this.btnDisable=true;
    // Approver
    for (let i = 0; i < k.approver.length; i++) {
      if (k.approver.length == 1) {
        k['approverOne'] = k.approver[0].approver1
      }
      if (k.approver.length == 2) {
        k['approverOne'] = k.approver[0].approver1
        k['approverTwo'] = k.approver[1].approver1
      }
      if (k.approver.length == 3) {
        k['approverOne'] = k.approver[0].approver1
        k['approverTwo'] = k.approver[1].approver1
        k['approverThree'] = k.approver[2].approver1
      }
      if (k.approver.length == 4) {
        k['approverOne'] = k.approver[0].approver1
        k['approverTwo'] = k.approver[1].approver1
        k['approverThree'] = k.approver[2].approver1
        k['approverFour'] = k.approver[3].approver1
      }
      if (k.approver.length == 5) {
        k['approverOne'] = k.approver[0].approver1
        k['approverTwo'] = k.approver[1].approver1
        k['approverThree'] = k.approver[2].approver1
        k['approverFour'] = k.approver[3].approver1
        k['approverFive'] = k.approver[4].approver1
      }
    }

    k['organization'] = {
      organizationId: this.organizationId
    }

    //Actual start date
    let c = k.startdate;
    let i = new Date(c);
    i.setMinutes(i.getMinutes() + 480);
    let v = i.toISOString()
    k['startDate'] = v

    //Actual end date
    let d = k.enddate;
    let j = new Date(d);
    j.setMinutes(j.getMinutes() + 480);
    let w = j.toISOString()
    k['endDate'] = w

    console.log(JSON.stringify(k))
    delete k.approver

  k['rulename'] = this.capitalizeWordsName(this.approverform.value.rulename)
  console.log(k);
    if(this.approverId){
      k['approverId'] = Number(this.approverId);
      console.log(JSON.stringify(k));
      this.approverservice.updateApprover(this.approverId,k).subscribe(
        (x:any)=>{
          this.loader = false;
          this.success = true;
          this.create_sucess_msg = x.message;
          console.log(this.create_sucess_msg);
          setTimeout(() => {
          this.router.navigate(['home/manage-approver-list'])
        }, 2000) 
      },
        err =>{
          this.btnDisable = false;
          this.loader = false;
          this.error = true;
          this.update_error_msg = err.error.message;
          console.log(this.update_error_msg);
          setTimeout(() => {this.error = false}, 2000)
      }
      )
    }
    else{
      this.approverservice.addApprover(k).subscribe(
        (data:any)=>{
          this.loader = false;
          this.success = true;
          this.create_sucess_msg = data.message;
          console.log(this.create_sucess_msg);
          setTimeout(() => {
          this.router.navigate(['home/manage-approver-list']);
        }, 2000) 
      },
        err =>{
          this.btnDisable = false;
          this.loader = false;
          this.error = true;
          this.update_error_msg = err.error.message;
          console.log(this.update_error_msg);
          setTimeout(() => {this.error = false}, 2000)
      }
      )
    }
  }
  cancel(){
    this.router.navigate(['home/manage-approver-list']);
  }
  add() {
    const Array: any = this.approverform.get('approver') as FormArray;
    console.log(Array);

    if (Array.length >= 5) {
      alert("Allowded only five approvers")
      return
    }
    else {
      Array.push(this.fb.group({
        approver1: ['', Validators.required]
      }))
    }
  }
  delete(i) {
    const arrayData = this.approverform.get('approver') as FormArray;
    if(arrayData.length == 1){
      return
    }
    arrayData.removeAt(i)
  }
  someMethodName1(e){
    this.min = new Date(e.value);
    console.log("this.min",this.min);
    // this.endDate_disable = false;

    let w = this.datepipe.transform(this.f.enddate.value, 'YYYY-MM-dd');
    let v = this.datepipe.transform(this.min, 'YYYY-MM-dd');
    if(w != null){
      if(w < v){
        this.show_error_msg = true;
        console.log("if");
      }
      else{
        this.show_error_msg = false;
        console.log("else");
      }
    }
  }
  someMethodName2(e){
    this.max = new Date(e.value);
    console.log("this.min",this.min);
    // this.endDate_disable = false;

    let w = this.datepipe.transform(this.max, 'YYYY-MM-dd');
    let v = this.datepipe.transform(this.f.startdate.value, 'YYYY-MM-dd');
    if(w != null){
      if(w < v){
        this.show_error_msg = true;
        console.log("if");
      }
      else{
        this.show_error_msg = false;
        console.log("else");
      }
    }
  }
  checkForDuplicates(array) {
    console.log(array);
    let valuesAlreadySeen = []
  
    for (let i = 0; i < array.length; i++) {
      let value = array[i].approver1;
      console.log(value);
      if (valuesAlreadySeen.indexOf(value) !== -1) {
        return true
      }
      valuesAlreadySeen.push(value);
      console.log(valuesAlreadySeen);
    }
    return false
  } 
    customernamevalidate(){
    console.log("customername " +  this.approverform.value.rulename);
    let approverId = 0;
    if(this.approverId)
    {
      approverId = this.approverId;
    }
     if(this.approverform.value.rulename.length != 0)
     {
          console.log("projectstageId : " +  approverId);

       this.approverservice.getApproverRuleName(this.approverform.value.rulename , approverId, this.organizationId).subscribe(a => {
            console.log(a);
           this.ruleNameobject = a;
              if(this.ruleNameobject.isexist)
              {
                 console.log("role name isexist");
                 this.ruleNameexist = true;
              }else{
               this.ruleNameexist = false;
              }
       })
     }else{
       this.ruleNameexist = false;
     }
   }

  capitalizeWordsName(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
  setApprover(data):FormArray{
    const formArray = new FormArray([]);
    if(data.approverOneObject){
      formArray.push(
        this.fb.group({
          approver1:data.approverOneObject.employeeId,
        }),
      )
    }

    if(data.approverTwoObject){
      formArray.push(
        this.fb.group({
          approver1:data.approverTwoObject.employeeId,
        }),
      )
    }

    if(data.approverThreeObject){
      formArray.push(
        this.fb.group({
          approver1:data.approverThreeObject.employeeId,
        }),
      )
    }

    if(data.approverFourObject){
      formArray.push(
        this.fb.group({
          approver1:data.approverFourObject.employeeId,
        }),
      )
    }

    if(data.approverFiveObject){
      formArray.push(
        this.fb.group({
          approver1:data.approverFiveObject.employeeId,
        }),
      )
    }

    return formArray
  }
  get f() {
    return this.approverform.controls
  }
  invalidInput: boolean = false;

  validateInput(event: any) {
    const inputElement = event.target;
    let value = inputElement.value;

    if (value.startsWith(' ')) {
        value = value.trimStart();
        inputElement.value = value;
    }

    this.invalidInput = value.trim().length === 0; // Check if the input consists of only spaces
}
}
