
import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PayrollService } from 'src/app/providers/payroll.service';
import { responseMsgTimeOut}from '../../app/providers/properties'
import { FormGroup, FormControl, AbstractControl,FormArray,Validators, FormBuilder }from '@angular/forms';
import { ImportClientComponent} from '../import-client/import-client.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {EMPTY, Observable, Subject} from 'rxjs';
import {startWith, map, switchMap, takeUntil, catchError} from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AsyncPipe} from '@angular/common';
import {  MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { EmpassignmentdetailComponent} from '../empassignmentdetail/empassignmentdetail.component'
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { AppComponent } from 'src/app/app.component';
import {ExcelService } from '../services/excel.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { Router,ActivatedRoute} from '@angular/router';
import { EmployeeService } from '../providers/employee.service';
@Component({
  selector: 'app-employee-rating',
  templateUrl: './employee-rating.component.html',
  styleUrls: ['./employee-rating.component.scss']
})
export class EmployeeRatingComponent implements OnInit {
  length: number;
  dataArray: any=[];
  financialYear: any;
  period: string;
  startIndex: number;
  isprocessing1=false;

  ratingForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:boolean = false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=5;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any=[];
  chooseFinId:any;
  finPeriodList: any=[];
  empCode: boolean=false;
  empRatings:any=[];
  selectedEmployeeData: any = [];
  financialPeriodList: any = [];
  empratingId: any;
  employeetData: any;
  userdata: any;
  reportData: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  data: any[];
  employeelistsub: any;
  showtable:boolean =  false;
  // pagePerIist:any=10;
	private unsubscribe$: Subject<void> = new Subject<void>();
  inputValue: any;
  pasteCount: any;
  finid: any;
  perid: any;

  constructor(private fb:FormBuilder,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    private router:Router,
    private formBuilder: FormBuilder,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private employee_service: EmployeeService,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    @Inject(AppComponent) public AppData: any) { }
  

  ngOnInit(): void {
    
    this.data = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`);
    this.updatePagedData();
    this.ratingForm = this.fb.group({
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      employeecode:[null,[Validators.required]],
      Ratings: this.fb.array([],[Validators.required]),
      // employeeId:['']
    })
    this.empratingId=this.ActivatedRoute.snapshot.params.employeerattingId;
    console.log(this.empratingId);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.clientId=this.usertData?.employee?.client?.clientId
    console.log(this.orgId);
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);

   this.loader=true;

   if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      this.loader=false;

    });
  }
  if(this.clientId){
    this.settingsService.getFinicalyearActiveListClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      this.loader=false;

    });
  }

    if(this.empratingId)
    {
      console.log("if patch");
      // this.selectedEmployeeData=[];
        this.payrollservice.getRatingById(this.empratingId).subscribe((userdata:any) =>
        {       
           console.log('userdata', userdata)
          this.userdata=userdata;
          this.ratingForm.patchValue({
            financialyear: this.userdata.financialPeriod.finanicalYear.financialYearId,
            period: this.userdata.financialPeriod.financialPeriodId, 
            employeecode:this.userdata.employee.employeeNumber
           
          });
          console.log("this.postForm",this.ratingForm)
          // Adding a row with leaveDays

          const updateData= {
            employeeId: this.userdata.employee.employeeId,
            employeeNumber:this.userdata.employee.employeeNumber,
            firstName:this.userdata.employee.firstName,
            lastName:this.userdata.employee.lastName,
            emprating: this.userdata.rating,
            employeerattingid:this.userdata.employeerattingId
          }
          console.log("updateData",updateData);
          this.selectedEmployeeData.push(updateData);

          console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
          const rowsArray = this.ratingForm.get('Ratings') as FormArray;
          console.log("Data:",rowsArray);
          this.addRow();
          rowsArray.at(0).patchValue({
            employeeId:userdata.employee.employeeId , 
            employeeNumber:userdata.employee.employeeNumber,
            firstName: userdata.employee.firstName,
            lastName: userdata.employee.lastName,
            emprating: userdata.rating,
            employeerattingId:this.userdata.employeerattingId
          });
          this.showtable = true;
          console.log(rowsArray);
          // rowsArray.push(this.formBuilder.group({
          //   employeeId:userdata.employee.employeeId , 
          //   employeeNumber:userdata.employee.employeeNumber,
          //   firstName: userdata.employee.firstName,
          //   lastName: userdata.employee.lastName,
          //   emprating: userdata.rating,
          //   employeerattingid:this.userdata.employeerattingId
          // }));
           
          this.employeeId = userdata.employee.employeeId;

          if(this.orgId){
          this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
            console.log(resultData);
            // resultData.forEach((fYear) => {
            //   if (fYear.financialYearStatus.listTypeValueId == 1) {
            //   // this.financialYearList = resultData;
            // }
            // });
          
            this.settingsService.getFinancialYearId(this.ratingForm.value.financialyear).subscribe((resultData1: any) => {
              console.log("resultData1", resultData1.finanicalPeriod)
        
              this.finPeriodList = resultData1.finanicalPeriod;
              this.ratingForm.get('period').patchValue(this.userdata.finanicalPeriod.financialPeriodId); 
              console.log(this.userdata.finanicalPeriod.financialPeriodId);
              
              console.log("this.financialPeriodList", this.finPeriodList);
            });
          });
        }
        if(this.clientId){
          this.settingsService.getFinicalyearActiveListbyClient(this.clientId).subscribe((resultData: any) => {
            console.log(resultData);
            // resultData.forEach((fYear) => {
            //   if (fYear.financialYearStatus.listTypeValueId == 1) {
            //   // this.financialYearList = resultData;
            // }
            // });
          
            this.settingsService.getFinancialYearId(this.ratingForm.value.financialyear).subscribe((resultData1: any) => {
              console.log("resultData1", resultData1.finanicalPeriod)
        
              this.finPeriodList = resultData1.finanicalPeriod;
              this.ratingForm.get('period').patchValue(this.userdata.finanicalPeriod.financialPeriodId); 
              console.log(this.userdata.finanicalPeriod.financialPeriodId);
              
              console.log("this.financialPeriodList", this.finPeriodList);
            });
          });
        }
        })
      // }
    }
            
  }

  get validate() 
  { 
    return this.ratingForm.controls; 
  }


  // notZeroValidatorAsync(control: AbstractControl) {
  //   const value = control.value;
  //    const regex = /^(?:[1-4](?:\.\d{1,2})?|5(?:\.0{1,2})?)$/;
  //   return new Promise((resolve) => {
  //     if (!regex.test(value.toString()) ) {
  //       console.log("notZero");
  //       resolve({ notZero: true }); 
  //     } else {
  //       console.log("Zero");
  //       resolve(null); 
  //     }
  //   });
  // }



  notZeroValidatorAsync(control: AbstractControl) {
    const value = control.value;
    // Regular expression to allow values from 0 to 5, including up to two decimal places
    const regex = /^(?:[0-4](?:\.\d{1,2})?|5(?:\.0{1,2})?)$/;
    
    return new Promise((resolve) => {
      if (!regex.test(value.toString())) {
        console.log("Invalid input");
        resolve({ notZero: true }); 
      } else {
        console.log("Valid input");
        resolve(null); 
      }
    });
  }
  

 
  


  

  addRow() {
    const control = this.ratingForm.get('Ratings') as FormArray;
    control.push(this.initRows());
    console.log(control);
  }
  
  initRows() {
    return this.fb.group({
      emprating: ['', [Validators.required],this.notZeroValidatorAsync],
      firstName: [''],
      lastName: [''],
      employeeId: [''],
      employeeNumber:[''],
      employeerattingId:['']
    });
  }

  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) 
    {
      event.preventDefault();
    }
  }

  onKeyDown(event: KeyboardEvent): void 
{      
    if (event.keyCode === 13) {      
          event.preventDefault();  
        }      
}  

  onSubmit()
  {
    this.isprocessing1 = true;

    console.log("submit");
    this.submitted=true;
    console.log(this.ratingForm);
    const empRatingsControls = this.ratingForm.get('Ratings') as FormArray;
    console.log(empRatingsControls);
    const rowsArray = this.ratingForm.get('Ratings') as FormArray;
    console.log("Data:",rowsArray);

    // if (this.ratingForm.value.employeecode !== undefined 
    //   && this.ratingForm.value.employeecode !== null 
    //   && this.ratingForm.value.employeecode !== '' 
    //   && this.ratingForm.value.employeecode !== 'All') {
    
    // if ((this.empnumber != this.ratingForm.value.employeecode) || (this.empnumber == null)) {
    //   window.alert('Please select a valid employee from the dropdown.');
    //   this.ratingForm.get("employeecode").reset();
    //         this.empCode = false;
    //   return;
    //     }
    //   }


    if(this.ratingForm.invalid) 
      {
        this.isprocessing1 = true; 

          console.log(this.ratingForm.value.employeecode);
         
          if(this.ratingForm.value.employeecode.length!=0&&empRatingsControls.length==0)
        {
          console.log("inside form:"+this.ratingForm.value.employeecode);
          this.ratingForm.patchValue({
            employeecode:null
          });
          this.error = true;
          this.error_msg = "Please Select Employee";
          setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
        }
        else
        {
          console.log("Employee Code Null")
        }
        return;
      }
    if(this.empratingId) //Update 
    {
      this.empRatings=[];
      // console.log("Empty",this.empRatings);
      console.log("update", this.empratingId);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      console.log("employeeLeaveControls", empRatingsControls)
      for (let i = 0; i < empRatingsControls.length; i++) {
        const employeeId = empRatingsControls.at(i).value.employeeId;
        console.log('employeeId', employeeId);
        console.log("Id", empRatingsControls.at(i).value.employeerattingId);
        if(empRatingsControls.at(i).value.employeerattingId==undefined)
        {
          console.log("Id not present", empRatingsControls.at(i).value.employeerattingId);
          const updateEmployeeRating = {
            // employeerattingId:this.empratingId,
            rating:empRatingsControls.at(i).value.emprating,
            financialPeriod: {
              financialPeriodId:this.ratingForm.value.period
            },
            employee: {
              employeeId:empRatingsControls.at(i).value.employeeId
            },
            status: {
                    listTypeValueId: 1
                }
          };
          this.empRatings.push(updateEmployeeRating);
        }
        else
        {
          console.log("id present", empRatingsControls.at(i).value.employeerattingId);
          const updateEmployeeRating = {
            employeerattingId:this.empratingId,
            rating:empRatingsControls.at(i).value.emprating,
            financialPeriod: {
              financialPeriodId:this.ratingForm.value.period
            },
            employee: {
              employeeId:empRatingsControls.at(i).value.employeeId
            },
            status: {
                    listTypeValueId: 1
                }
          };
          this.empRatings.push(updateEmployeeRating);
        }
      }

      console.log('employeeLeaveArray', this.empRatings);          
        this. payrollservice.updateEmployeeRating(this.empRatings).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.showtable = false;
            this.success = true;
            this.sucess_msg = result.description;
            // this.searchSubmit();
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            // this.clientupdateId = null;
            // this.ratingForm.reset();
            this.submitted = false;
            setTimeout(() => {
              this.router.navigate(["home/employee-rating-list"])
            }, redirectMsgTimeOut)
          }
          else
          {
            // this.error = true;
            // this.error_msg = result.description;
            // this.errormessagejson = result;
            // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          console.log("err1",result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson',this.errormessagejson);
          this.importerror = true;
          }
          this.isprocessing1=false;
        },
         err =>
            {
              // console.log("code",err);
              // console.log("code",err.statusCode);
              // if(err.error.statusCode == 409)
              // {
                console.log("code1",err.statusCode);
                setTimeout(() => {
                  this.error = true;
                  this.errorMsg = err.error.description;
                }, redirectMsgTimeOut);
                this.isprocessing1=false;

              // }
               
            })
    }
    else
    {
      this.empRatings=[];
      console.log("create");
      console.log(empRatingsControls.value);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      this.reportData=empRatingsControls.value;
      console.log("this.reportDate",this.reportData);
      // for (let i = 0; i < this.reportData.length; i++) {
      //   const employeeId = this.reportData.at(i).employeeId;
      //   console.log('employeeId', employeeId);
      //   const createEmployeeRating = {
      //     financialPeriod: 
      //              { 
      //                financialPeriodId: this.ratingForm.value.period
      //              },
      //     employee: 
      //     { 
      //       employeeId: this.reportData.at(i).employeeId 
      //     },
      //     rating: this.reportData.at(i).emprating,
      //     status: { listTypeValueId: 1 }
      //   };
      //   this.empRatings.push(createEmployeeRating);
      //   console.log(this.empRatings);
      // }

      for (let i = 0; i < this.reportData.length; i++) {
        const employeeId = this.reportData.at(i).employeeId;
        console.log('employeeId', employeeId);
      
        // Check if the rating is valid (greater than 0) before creating and pushing the object
        if (this.reportData.at(i).emprating > 0) {
          const createEmployeeRating = {
            financialPeriod: { 
              financialPeriodId: this.ratingForm.value.period 
            },
            employee: { 
              employeeId: this.reportData.at(i).employeeId 
            },
            rating: this.reportData.at(i).emprating,
            status: { 
              listTypeValueId: 1 
            }
          };
      
          this.empRatings.push(createEmployeeRating);
          console.log(this.empRatings);
        } else {
          console.log(`Skipped record for employeeId: ${employeeId} because rating is invalid (${this.reportData.at(i).emprating}).`);
        }
      }
      
          this.payrollservice.createrating(this.empRatings).subscribe((result: any) =>
       {

        if(result.statusCode == 200)
        {
          this.showtable = false;
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          // this.ratingForm.reset();     
          this.submitted = false;
          // this.searchSubmit();
          setTimeout(() => {
            this.router.navigate(["home/employee-rating-list"])
          }, redirectMsgTimeOut)
        }
        else
        {
          console.log("err1",result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson',this.errormessagejson);
          this.importerror = true;
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          // setTimeout(() => {
          // }, redirectMsgTimeOut)
        }
        this.isprocessing1=false;


      }, err =>
      {
        console.log("code",err);
        console.log("code",err.statusCode);
        // if(err.error.statusCode == 409)
        // {
        //   console.log("code1",err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut) ;
          this.isprocessing1=false;

        // }
         
      })
    }
  }
  get getFormControls() {
    this.dataArray=[];
    const control = this.ratingForm.get('Ratings') as FormArray;
    // console.log(control);
    this.length=control.length;
    if(control != null)
    {
       control.controls.forEach((formGroup: FormGroup) => {
        const data = formGroup.value;
        this.dataArray.push(data);
      });
      // console.log(this.dataArray);
      return control;
    }    
  }

  selectFinancialyear()
  {
    console.log("Select indidual Employee");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    //  this.chooseFinId=event.target.value;
    this.chooseFinId = this.ratingForm.controls['financialyear'].value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.ratingForm.patchValue({
        period: null,
      });
     
    })
    console.log(this.ratingForm);
  }
}


noEmployeeFound() {
  // this.selectedemployeeId = undefined;
  // this.selectedemployeeId = undefined; 
  this.employeeId = undefined;
  this.empList.length = 0;
  this.employee = undefined;
  this.empnumber = undefined;
  // this.selectedemployee = undefined;
  // this.selectedemployeeId = undefined;
  this.empList = []; // Clear the employee list
  this.empCode = false; 

  }

  
//   empSearch(event)
// { 
//   this.empList=[];
//   this.empCode=false;
//   console.log(event.target.value);
//   this.userId=event.target.value;
//   console.log(this.userId);
//   // Employee List Api
//     // this.empList.length == 0;
// 	  if(event.target.value.length >= 2)
//     {
//       if(this.employeelistsub)
//       {
//         this.employeelistsub.unsubscribe();
//       }
//       this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
//         .pipe(
//           takeUntil(this.unsubscribe$), // Cancel previous request
//           switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
//         )
//         .subscribe((result: any) => {
//           this.empList.length = 0;

//           if (result.statusCode) {
//             console.log(result.statusCode);
//             this.showtable = true;
//             this.empList.length = 0;
//           } else {
//             this.empList = result;
//           }
//         });
// 	}
// }

empSearch(event){
  console.log(event.target.value)
  const inputElement = event.target as HTMLInputElement;
  console.log(inputElement) // Cast the event target as an input element
this.inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
 console.log(this.inputValue)
  // Clear the employee list and reset flags if input is empty
  if (this.inputValue === '') {
    this.empList = [];
    this.empCode = false;
    console.log('Input is empty, employee list cleared');
    return;
  }

  // Check if input length is sufficient for the search
  if (this.inputValue.length >= 2) {
    // Unsubscribe from the previous API request if necessary
    if (this.employeelistsub) {
      this.employeelistsub.unsubscribe();
    }

    // Reset and create a new Subject for cancellation
    this.unsubscribe$ = new Subject<void>();


    if(this.orgId){
    this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
      .pipe(
        takeUntil(this.unsubscribe$), // Cancel previous request
        catchError((err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true; // Set the flag to indicate an error state
          }
          console.error('An error occurred:', err);
          return EMPTY; // Return EMPTY to propagate completion downstream
        })
      )
      .subscribe(
        (result: any) => {
          if (result.statusCode === 204) {
            console.log('No employees found (status code 204)');
            this.empList = [];
            this.empCode = true; // Indicate no results found
          } else if (result.statusCode) {
            console.log('Other status code:', result.statusCode);
            this.empList = [];
            this.empCode = true;
          } else {
            // Handle the case where result is an array or an object
            this.empList = Array.isArray(result) ? result : Object.values(result);
            this.empCode = false;
          }
        },
        (err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true;
          }
          console.error('An error occurred:', err);
        }
      );
    }

    if(this.clientId){
      this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }
    }
  } 


empSelect(emp) {
  // this.selectedEmployeeData=[];
  console.log(emp);
  // this.empList=[];
  this.employeeId = emp.employeeId;
  this.firstname=emp.firstName;
  this.lastname=emp.lastName;
  this.empnumber=emp.employeeNumber;
  // if (this.employeeLeaveId) {
  //   this.ratingForm.patchValue({
  //     employee: this.employee
  //   });
  // } 
  // else {

    // this.ratingForm.patchValue({
    //   employee: null
    // });
    console.log("select",this.selectedEmployeeData);
    let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
    this.showtable = true;
    if (!hasemployee) {
      
      this.selectedEmployeeData.push(emp);
      const rowsArray = this.ratingForm.get('Ratings') as FormArray;
      // rowsArray.clear();
      console.log("control",rowsArray);
      // rowsArray.push(this.formBuilder.group({
      //   employeeId: [this.employeeId], 
      //   employeeNumber:[this.empnumber],
      //   firstName:[ this.firstname],
      //   lastName:[this.lastname],
      //   // employeerattingid:[this.employeerattingId],
      //   emprating: ['', [Validators.required],this.notZeroValidatorAsync],
      // }));


      if (!rowsArray.controls.some(row => row.get('employeeId').value === this.employeeId)) {
        console.log("control", rowsArray);
    
        rowsArray.push(this.formBuilder.group({
          employeeId: [this.employeeId],
          employeeNumber: [this.empnumber],
          firstName: [this.firstname],
          lastName: [this.lastname],
          emprating: ['', [Validators.required],this.notZeroValidatorAsync],
        }));
      }
      console.log("cotrol patch value",rowsArray);
    }


    console.log(this.ratingForm.get('financialyear').value);
    console.log(this.ratingForm.get('period').value);
  this.finid=this.ratingForm.get('financialyear').value;
    this.perid=this.ratingForm.get('period').value;

    if (!this.finid || !this.perid) {
      alert("Please select both Financial Year and Period before proceeding.");
        this.ratingForm.controls['employeecode'].reset();
        this.selectedEmployeeData=[];

      return; // Stops execution
  }
    if (this.employeeId) {
      this.loader = true;
      this.payrollservice.getEmployeecountRating(this.employeeId,this.perid, this.finid).subscribe(
        (resultData: any) => {
          //console.log(resultData);
          this.loader = false;
          this.resultData = resultData;
       
        
          console.log('resultData:', this.resultData);
          console.log(this.resultData.management_total_rating) ;       
         this.ratingForm.patchValue({
          emprating: this.resultData.management_total_rating,
         });
         const rowsArray = this.ratingForm.get('Ratings') as FormArray;
         console.log("Data:"+rowsArray);
        // //  this.addRow();
         rowsArray.at(0)?.patchValue({
          
          emprating: this.resultData.management_total_rating,
         })
      
          
        }
      );
    }

  // }

}

// empSelect(emp)
// {
//   console.log(emp);
//   this.employeeId=emp.employeeId;
//   this.employee=emp.employeeId;
//   console.log(this.employee);
//   this.ratingForm.patchValue({
//     employeecode: this.employee
//    });
// }


close()
{
  console.log("close");
  this.router.navigateByUrl('/home/employee-rating-list');
}

  onItemsPerPageChange(event: any) 
  {
    this.pagePerItem = event.target.value;
    this.p = 1; // Reset the pagination to the first page when items per page is changed
  }

empAllSelect()
{
  // this.empList=[];
  // this.selectedEmployeeData=[];
  console.log("all Employee");

  console.log(this.ratingForm.get('financialyear').value);
    console.log(this.ratingForm.get('period').value);
  this.finid=this.ratingForm.get('financialyear').value;
    this.perid=this.ratingForm.get('period').value;

    if (!this.finid || !this.perid) {
      alert("Please select both Financial Year and Period before proceeding.");
        this.ratingForm.controls['employeecode'].reset();
        this.selectedEmployeeData=[];

      return; // Stops execution
  }

  if(this.ratingForm.value.employeecode == "All")
  {
    if(this.orgId){
    this.loader=true;
    console.log("select",this.selectedEmployeeData);

    if(this.orgId){
    this.payrollservice
    .getEmployeecountAllRating(this.orgId,this.perid,this.finid).subscribe((result: any) => {
      console.log(result);
      if(result.length==0)
      {
        console.log("noData");
        // this.empList.length = 0;
      }
      // this.empList=result;
      console.log("All",this.empList);
      result.forEach(element => {
        let hasemployee = this.selectedEmployeeData.some(e => e.employee_id == element.employee_id);
        this.showtable = true;
        if (!hasemployee) {
          this.selectedEmployeeData.push(element);
          console.log('selectedEmployeeData', this.selectedEmployeeData);
          const rowsArray = this.ratingForm.get('Ratings') as FormArray;
          rowsArray.push(this.formBuilder.group({
            employeeId: [element.employee_id], 
            employeeNumber:[element.employee_number],
            firstName:[element.first_name],
            lastName:[element.last_name],
            // employeerattingid:[element.employeerattingId],
            emprating: [element.management_total_rating, [Validators.required],this.notZeroValidatorAsync],
          }));
        }
      });
      this.loader=false;
    })
  }

  if(this.clientId){
    this.payrollservice
    .getEmployeecountAllRatingClient(this.clientId,this.perid,this.finid).subscribe((result: any) => {
      console.log(result);
      if(result.length==0)
      {
        console.log("noData");
        // this.empList.length = 0;
      }
      // this.empList=result;
      console.log("All",this.empList);
      result.forEach(element => {
        let hasemployee = this.selectedEmployeeData.some(e => e.employee_id == element.employee_id);
        this.showtable = true;
        if (!hasemployee) {
          this.selectedEmployeeData.push(element);
          console.log('selectedEmployeeData', this.selectedEmployeeData);
          const rowsArray = this.ratingForm.get('Ratings') as FormArray;
          rowsArray.push(this.formBuilder.group({
            employeeId: [element.employee_id], 
            employeeNumber:[element.employee_number],
            firstName:[element.first_name],
            lastName:[element.last_name],
            // employeerattingid:[element.employeerattingId],
            emprating: [element.management_total_rating, [Validators.required],this.notZeroValidatorAsync],
          }));
        }
      });
      this.loader=false;
    })
  }

  }
}
}

deleteemployee(index) 
{
  console.log(index + index);
  if (index != -1) 
  {
    const rowsArray = this.ratingForm.get('Ratings') as FormArray;
    console.log(rowsArray);
    // this.ratingForm.controls["Ratings"] as FormArray
    // const rowsArray = this.ratingForm.get('Ratings') as FormArray;
    // console.log("Data:",rowsArray);
    // for (let i = 0; i < rowsArray.length; i++) {
      const emprattingId = rowsArray.at(index).value.employeeId;
      console.log('emprattingId', emprattingId);
      console.log("Id", rowsArray.at(index).value.employeerattingId);
      if(rowsArray.at(index).value.employeerattingId == undefined)
      {
        rowsArray.removeAt(index);
        this.selectedEmployeeData.splice(index, 1);
        console.log(this.selectedEmployeeData.length);
      }
  }
}

isRowValid(index: number): boolean {
  const rowsArray = this.ratingForm.get('Ratings') as FormArray;
  console.log(rowsArray);
  const row = rowsArray.at(index) as FormGroup;
  return row.valid;
}



showerrormessage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

// deleteemployee(index) 
//   {
//   console.log(index);
//   console.log(this.dataArray);
//   if (index != -1) {
//     this.dataArray.splice(index, 1);
//     console.log(this.dataArray);
//   }
//   }
 
  onPageChange(event: any): void {
    console.log(event)
    this.p = event;
    this.updatePagedData();
  }

  updatePagedData(): void {
    const startIndex = (this.p - 1) * this.pagePerItem;
    const endIndex = startIndex + this.pagePerItem;
    console.log(startIndex);
    this.startIndex=startIndex;
    console.log(this.startIndex);
    console.log(endIndex);
  }

 get rattingFormArray() : FormArray {
  return this.ratingForm.controls["Ratings"] as FormArray
 }

 disablePaste(event: ClipboardEvent) {
  event.preventDefault();
}
onPaste(event: ClipboardEvent) {
  event.preventDefault(); // Prevent default paste behavior
  console.log('Pasting is not allowed!');
  this.pasteCount--;
}
}