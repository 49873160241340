import { Component, ElementRef,OnInit } from '@angular/core';
import { PayrollService } from 'src/app/providers/payroll.service';
// import { redirectMsgTimeOut, responseMsgTimeOut } from '../../providers/properties';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { redirectMsgTimeOut } from '../providers/properties';
import { SettingsService } from '../providers/settings.service';

@Component({
  selector: 'app-orgnization-manage-elements',
  templateUrl: './orgnization-manage-elements.component.html',
  styleUrls: ['./orgnization-manage-elements.component.scss']
})
export class OrgnizationManageElementsComponent implements OnInit {

  showtable:boolean=false;
  isProcessing = false;
  isprocessing1=false;

  searchForm:FormGroup;
  list_loader = true;
  elementslistData:any[];
  elementslistData1:any[];
  nodata = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  p: number = 1;
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  pagePerItem:any=5;
  categoryTypeData :any;
  listTypeValueId:any;
  employeetData : any;
  isDataLoading: boolean = true;
  elementform:FormGroup;
  loader:any= false;
  backdrop:any= false;
  elementId:number;
  submitted: boolean = false;
  usertype:any;
  update_data_error_msg:any;
  userdata:any=[];
  showCategoryType: boolean = false;
  categoryTypeSearchData:any;
  noRecordsFound: boolean =false;

  elementslistData1length = 0;
  length: any
  formsubmitted: boolean = false;
  organizationId: any;
  pasteCount: any;
  organizationNameData:any[]=[];
  storeEntityId:any;
  clientLogin: boolean = false;
  organizationLogin: boolean = false;
  entityAdminLogin: boolean = false;
  usertData: any;
  orgId: any;
  clientId:any;
  orgName:any
  constructor(private payrollservice:PayrollService,
    private dialog:MatDialog,
    private payrollService:PayrollService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private settingsService: SettingsService,
    private ActivatedRoute:ActivatedRoute,) { 
    }

  ngOnInit(): void {
  this.onItemsPerPageChange;
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.organizationId = organizationId;
    this.storeEntityId = this.employeetData.entity.entityId;
    this.listCategoryType();
    this.patchresource();

    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;
      this.orgId = this.usertData.organization.organizationId;
      this.clientId = this.usertData?.employee?.client?.clientId;
      this.orgName = this.usertData.organization.organizationName;

    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;    
    } else {
      this.entityAdminLogin = true;

    }

    this.noRecordsFound = true;
    this.settingsService.getOrganizationListForDropdown(this.storeEntityId).subscribe((response)=>{
      console.log('Entityresponse',response);
      this.organizationNameData =  response.filter(organization =>organization?.organizationStatus?.listTypeValueId == 1);;

      // this.organizationNameData = response;
  
      })
    if(this.elementId)
    { 
      console.log('elementId present')
      
        this.elementform = new FormGroup(
        {
          organizationName:new FormControl(this.elementform,[Validators.required]),
          categoryType: new FormControl(this.elementform,[Validators.required]), 
          elementName : new FormControl(this.elementform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
          // orderNumber : new FormControl(this.elementform,[Validators.pattern(/^[0-9]*$/),Validators.minLength(1), Validators.maxLength(2)]), 
          orderNumber : new FormControl(this.elementform,[Validators.required,Validators.minLength(1), Validators.maxLength(2)]), 
          elementDescription : new FormControl(this.elementform), 
          elementCode : new FormControl(this.elementform,[Validators.required,Validators.minLength(2), Validators.maxLength(10)])
        }
      );
   
    }
    else
    {   console.log('elementId not present')
        this.elementform = new FormGroup(
        {
          organizationName:new FormControl(this.elementform,[Validators.required]),
          categoryType: new FormControl(this.elementform,[Validators.required]), 
          elementName : new FormControl(this.elementform,[Validators.required,Validators.pattern(/^[a-zA-Z&_]+(([. ][a-zA-Z&_ ])?[a-zA-Z&_]*(&[a-zA-Z&_]*)?)*$/)]),
          // orderNumber : new FormControl(this.elementform,[Validators.pattern(/^[0-9]*$/),Validators.minLength(1), Validators.maxLength(2)]), 
          orderNumber : new FormControl(this.elementform,[Validators.required,Validators.minLength(1), Validators.maxLength(2)]), 
          elementDescription : new FormControl(this.elementform), 
          // elementCode : new FormControl(this.elementform,[Validators.required])
          elementCode : new FormControl(this.elementform,[Validators.required,Validators.minLength(2), Validators.maxLength(10)])
        }
      );
    } 

    this.searchForm = this.formBuilder.group({
      filterorganizationName: [null, Validators.required],
      categoryTypeSearch: [null, Validators.required] // Initialize with null
    });
    this.listCategoryType();

    // if(this.organizationLogin == true){
    //   this.elementform.get('organizationName')?.patchValue((this.orgName));
    //   this.elementform.get('organizationName')?.disable();
    //   this.searchForm.get('filterorganizationName')?.patchValue((this.orgName));
    //   this.searchForm.get('filterorganizationName')?.disable();
    // }
    if(this.organizationLogin == true){
      this.elementform.get('organizationName')?.patchValue(this.orgName);
      this.elementform.get('organizationName')?.disable();
 
      
    }
    if(this.organizationLogin == true){
      this.searchForm.get('filterorganizationName')?.patchValue(this.orgName);
     this.searchForm.get('filterorganizationName')?.disable();
    }
  }

  clear(){
    this.elementform.reset();
   
    return;
  }
  
get validate() 
{ 
  return this.elementform.controls; 
}

  clearTable()
  {this.elementslistData1 = [];
    this.noRecordsFound = false;
    console.log('clear')
    this.searchForm.clearValidators;
    this.noRecordsFound = true;
    this.showtable=false;
    // this.onItemsPerPageChange;
    this.p=1;
    const currentClientName = this.orgName;

    this.searchForm.patchValue({
      filterorganizationName: currentClientName,
      categoryTypeSearch:null
    })

  }
  clearForm() {
    console.log("reset came!!!")
    const currentClientName =this.orgName; 
    console.log("currentClientName",currentClientName);
    
    this.elementform.get('elementCode')?.reset();
    this.elementform.get('categoryType')?.reset();
    this.elementform.get('elementName')?.reset();
    this.elementform.get('orderNumber')?.reset();
    this.elementform.get('elementDescription')?.reset();
    this.elementform.patchValue({
      // elementCode: null,
      // categoryType:null,
      // elementName:null,
      organizationName:currentClientName,
      // orderNumber:null,
      // elementDescription:null
    });
    this.elementform.get('organizationName')?.disable();

  }
  onlyNumber(event) {
    console.log("only number"+event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) 
    {
      event.preventDefault();
    }
  }

  onlyLetters(event: KeyboardEvent) {
    console.log("Only text:"+event);
    console.log("only letters: " + event.key);
    const pattern = /[a-zA-Z]/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }
  
  
  elementList()
  {
    this.loader=true;
    this.isDataLoading = true;
    console.log('list')
    this.noRecordsFound = false;
    this.payrollservice.elementList(this.organizationId).subscribe((elementslistData:any) =>
    {
      this.loader=false;

      this.isDataLoading = false;
      console.log('elementslistData',elementslistData)
      this.list_loader = false;
      this.elementslistData1 = elementslistData;
      console.log(this.elementslistData1);
      this.elementslistData1length = this.elementslistData1.length;
      if(this.elementslistData1.length == 0)
      {
        this.noRecordsFound = true;
        console.log("nodata");
      }else{
        this.noRecordsFound = false;
      }

      if(elementslistData.statusCode == 204){
        this.noRecordsFound = true;
        console.log("nodata");
      }{
        this.noRecordsFound = false;
      }
    },
    (error) => {
      this.loader=false;

      this.list_loader = false;
      this.noRecordsFound = true;
      console.log("error")
      if(error.status == 404){
        this.noRecordsFound = true;
        console.log("404")
      }
    }
    )
  }
  

  listTypeValueid()
  {
    this.searchForm.get('categoryTypeSearch').value;
    console.log('event', this.searchForm.get('categoryTypeSearch').value);
    this.listTypeValueId = this.searchForm.get('categoryTypeSearch').value;
    console.log('this.listTypeValueId', this.listTypeValueId)
    if( this.listTypeValueId  == 'null')
    {
      this.searchForm.patchValue({

        categoryTypeSearch : null
      })
    }
    
  }

  listTypeValueid1()
  {
    this.elementform.get('categoryType').value;
    console.log('event', this.searchForm.get('categoryType').value);
    this.listTypeValueId = this.searchForm.get('categoryType').value;
    console.log('this.listTypeValueId', this.listTypeValueId)
    if( this.listTypeValueId  == 'null')
    {
      this.searchForm.patchValue({

        categoryType : null
      })
    }
    
  }
  onItemsPerPageChange(event: any) {
    this.pagePerItem = event.target.value;
    this.p = 1; // Reset the pagination to the first page when items per page is changed
  }
  searchSubmit()
  {
    this.p=1;
    this.onItemsPerPageChange;
    this.showtable=true;
    this.isProcessing=true;

    this.elementslistData1 = [];
    this.noRecordsFound = false;
    this.loader=true;
    console.log(this.searchForm.value)
    console.log( "formsubmitted" + this.formsubmitted);
    if(this.searchForm.value.categoryTypeSearch == null    && this.formsubmitted == false){
      console.log("this.searchForm.value.categoryTypeSearch",this.searchForm.value.categoryTypeSearch)
      // this.elementList();
     alert("Please Select Element Type ");
    this.isProcessing=false;
      this.noRecordsFound = false;
      this.loader=false;
      this.showtable=false;
   
    }else if(this.searchForm.value.categoryTypeSearch != null){
      console.log("this.searchForm.value.categoryTypeSearch",this.searchForm.value.categoryTypeSearch)
      const categoryType = this.searchForm.get('categoryTypeSearch').value;
      this.payrollservice.listByCategoryId(this.searchForm.value.categoryTypeSearch, this.orgId).subscribe( (data:any) =>{
        this.showtable=true;

        console.log('data',data);
        this.elementslistData1 = data;
        this.elementslistData1length = this.elementslistData1.length;
        this.loader=false;
        if( data.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        console.log('this.elementslistData1',this.elementslistData1);
       
      }, (error) => {
        console.log('error')
        this.loader=false;
        this.isProcessing=false;
      });
    }else if(this.searchForm.value.categoryTypeSearch == null && this.formsubmitted == true){
      console.log('both true')
      this.noRecordsFound = true;
      this.loader=false;
      this.isProcessing=false;

    }
    this.formsubmitted = false;
  }

  callTable(){
    this.payrollservice.listByCategoryId(this.elementform.value.categoryType,  this.orgId).subscribe( (data:any) =>{
      this.showtable=true;

      console.log('data',data);
      this.elementslistData1 = data;
      this.elementslistData1length = this.elementslistData1.length;
      this.loader=false;
      if( data.statusCode == 204){
        this.noRecordsFound = true;
      }
      this.isProcessing=false;
      console.log('this.elementslistData1',this.elementslistData1);
     
    }, (error) => {
      console.log('error')
      this.loader=false;
      this.isProcessing=false;
    });
  }
  searchCallTable(){

  }

  statuschangeactive(elementId,status)
  {
    console.log('this.listTypeValueId',this.listTypeValueId);
    console.log("elementId : "+elementId);
    console.log("status"+status);

   this.payrollservice.elementstatus(elementId,status).subscribe( (data:any) =>
   {
      if(data.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = data.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        if(this.listTypeValueId == null || this.listTypeValueId == 'null' || this.listTypeValueId == 0 || this.listTypeValueId == undefined){
           this.elementList();
         }else{
          this.listByCategoryId();
         }
      }
      else
      {
        this.error = true;
        this.error_msg = data.message;

        setTimeout(()=>{
          this.error = false;
          this.error_msg = '';
        },3000);
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }

   })
  }

listByCategoryId(){
  if(this.orgId){
  this.payrollservice.listByCategoryId(this.searchForm.value.categoryTypeSearch, this.orgId).subscribe( (data:any) =>{
    console.log('data',data);
    console.log(this.searchForm.value.categoryTypeSearch);
    // console.log(this.searchForm.value.filterorganizationName );
    this.elementslistData1 = data;
    this.elementslistData1length = this.elementslistData1.length;
    if( data.statusCode == 204){
      this.noRecordsFound = true;
    }
    console.log('this.elementslistData1',this.elementslistData1);
   
  }, (error) => {
    console.log('error')
  });
}

if(this.clientId){
  this.payrollservice.listByCategoryIdClient(this.searchForm.value.categoryTypeSearch, this.clientId ).subscribe( (data:any) =>{
    console.log('data',data);
    console.log(this.searchForm.value.categoryTypeSearch);
    // console.log(this.searchForm.value.filterorganizationName );
    this.elementslistData1 = data;
    this.elementslistData1length = this.elementslistData1.length;
    if( data.statusCode == 204){
      this.noRecordsFound = true;
    }
    console.log('this.elementslistData1',this.elementslistData1);
   
  }, (error) => {
    console.log('error')
  });
}
}

  listCategoryType()
  {

    this.payrollService.listCategoryType(this.organizationId).subscribe((result: any) => {
      this.categoryTypeData = result;
      this.categoryTypeSearchData = result;
    })
  }
  statuschangeinactive(elementId,status)
  {
    console.log('this.listTypeValueId',this.listTypeValueId);

   this.payrollservice.elementstatus(elementId,status).subscribe( (data:any) =>
   {
      if(data.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = data.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        if(this.listTypeValueId == null || this.listTypeValueId == 'null' || this.listTypeValueId == 0 || this.listTypeValueId == undefined){
          this.elementList();
        }else{
         this.listByCategoryId();
        }
      
      }
      else
      {
        this.error = true;
        this.error_msg = data.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
   })
  }

  onKeyUpEvent(event)
  {
    if(this.query.length !=0)
    {
      this.p=1;
    }
    else
    {
      this.p = this.beforepage;
    }
  }

  get categoryTypeSearch()
  {
    return this.searchForm.get('categoryTypeSearch');
  }
  edit(elementId){
    console.log('elementId', elementId)
    this.elementId = elementId;
    
    this.patchresource();
    this.scrollToEditForm();
  }

  
  patchresource()
  { 
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    console.log('patch')
    if(this.elementId)
    {      
      this.loader=true;
      this.payrollService.elementbyid(this.elementId).subscribe((userdata:any) =>
      {        console.log('userdata', userdata)
        this.userdata=userdata;
        this.loader=false;
         console.log('this.userdata', this.userdata)
        
        this.elementform.patchValue({
          organizationName:this.orgName,
          // organizationName:this.userdata.organization.organizationId,
          elementId : this.userdata.elementId,
          elementName: this.userdata.elementName,
          elementDescription: this.userdata.elementDescription,
          orderNumber: this.userdata.orderNumber,
          calculationFormula: "",
          elementCode: this.userdata.elementCode,
          categoryType:{listTypeValueId:this.userdata.categoryType},
          organization:{organizationId:organizationId},
         
        });
        this.elementform.get('categoryType').patchValue(this.userdata.categoryType.listTypeValueId); 
        if(this.elementform.invalid)
        {
          const controls = this.elementform.controls;
          for (const name in controls) 
          {
            if (controls[name].invalid) {
              console.log(name)
            }
          }
        }
      })
    }
  }

  submitData()
  {
    this.p=1;
    // this.isprocessing1 = true; // Disable the button

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;

    console.log('patch')
    this.noRecordsFound = false;
    this.showtable=false;
    console.log('submit')
    this.submitted = true;
    this.error = false;
    this.success = false;
    console.log(this.elementId);
    if(this.elementId) 
    { 
      console.log("Update");
      var elementupdate;
      elementupdate=
        {
          elementId : this.elementId,
          elementName: this.elementform.value.elementName,
          elementDescription: this.elementform.value.elementDescription,
          orderNumber: this.elementform.value.orderNumber,
          calculationFormula: "",
          elementCode: this.elementform.value.elementCode,
          categoryType:{listTypeValueId:this.elementform.value.categoryType},
          organization:organizationIstore,
          status:this.userdata.status,
        };
        console.log('elementupdate', elementupdate)
        if(this.elementform.value.orderNumber == null){
          console.log('this.elementform.value.orderNumber'+this.elementform.value.orderNumber)
          elementupdate.orderNumber = 0;
        }
        this.loader =true;
        // return
      this.payrollService.elementupdateorg(elementupdate,this.elementId).subscribe( (data:any) =>
      {
      

       
          // Handle result as an object
          if (data?.statusCode === 409) {
            console.log("409 entered!!")
            this.error = true;
            this.error_msg = data.description;
            this.loader = false;
            this.isprocessing1 = false;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          }
          this.validate?.orderNumber?.markAsUntouched();
        const currentClientName =this.orgName; 
        console.log("currentClientName",currentClientName);
        
      
        this.elementform.patchValue({
          // elementCode: null,
          // categoryType:null,
          // elementName:null,
          organizationName:currentClientName,
          // orderNumber:null,
          // elementDescription:null
        });
        if(data.statusCode == 200)
        {
          this.elementform.get('elementCode')?.reset();
          this.elementform.get('categoryType')?.reset();
          this.elementform.get('elementName')?.reset();
          this.elementform.get('orderNumber')?.reset();
          this.elementform.get('elementDescription')?.reset();
          this.loader =false;
          // this.callTable();
          // this.elementform.reset();
          // this.validate.organizationName.markAsUntouched();
          this.formsubmitted = true;
          this.elementId=0;
          //this.elementList();
          //  this.searchSubmit();
 
          this.success = true;
          this.sucess_msg = data.description;
          // if((this.searchForm.value.filterclient!="null"||this.searchForm.value.filterclient!=null)||(this.searchForm.value.filterfinancialyear!="null"||(this.searchForm.value.filterfinancialyear!=null))||((this.searchForm.value.filterfinancialyear!="null")||(this.searchForm.value.filterfinancialyear!=null)&&(this.searchForm.value.filterperiod!="null"||this.searchForm.value.filterperiod!=null))||
          // ((this.searchForm.value.filterclient!="null"||this.searchForm.value.filterclient!=null)&&(this.searchForm.value.filterfinancialyear!="null"||this.searchForm.value.filterfinancialyear!=null)&&(this.searchForm.value.filterperiod!="null"||this.searchForm.value.filterperiod!=null)))
          // {
          //   this.searchSubmit();
          //   console.log("search update")
          // }
        if(this.searchForm.value.categoryTypeSearch!=null){
          this.searchSubmit();
            console.log("search update")
        }
          
          if((this.searchForm.value.filterclient==null || this.searchForm.value.filterclient== 'null' )){
            console.log("Without",this.searchForm.value);
    
          }
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        }
       else
        // else
        // {
        //   this.loader =false;
        //   this.noRecordsFound = true;
        //   this.error = true;
        //   this.error_msg = data.description;
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
        this.isprocessing1=false;
      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;     
        this.update_data_error_msg = err.error.message;
        setTimeout(() => {this.error = false}, 3000);
        this.isprocessing1=false;

      })
    }
    else //Create
    {
      console.log("this.elementform.value",this.elementform.value);
      console.log("Create");
      var elementdata;

      elementdata =
        { 
          elementName: this.elementform.value.elementName,
          elementDescription: this.elementform.value.elementDescription,
          orderNumber: this.elementform.value.orderNumber,
          calculationFormula: "",
          elementCode: this.elementform.value.elementCode,
          categoryType:{listTypeValueId:this.elementform.value.categoryType},
          // organization:{organizationId:this.elementform.value.organizationName},
           organization:organizationIstore,

          
          status:{listTypeValueId:1},
        };
        console.log('elementdata', elementdata)
        if(this.elementform.value.orderNumber == null){
          console.log('this.elementform.value.orderNumber'+this.elementform.value.orderNumber)
          elementdata.orderNumber = 0;
        }
      this.backdrop=true;
      this.loader=true;
      this.payrollService.elementcreateorg(elementdata).subscribe( (data:any) =>
      {
        if (data?.statusCode === 409) {
          this.error = true;
          this.error_msg = data.description;
          this.loader = false;
          this.isprocessing1 = false;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
        const currentClientName =this.orgName; 
        console.log("currentClientName",currentClientName);
        
      
        this.elementform.patchValue({
          // elementCode: null,
          // categoryType:null,
          // elementName:null,
          organizationName:currentClientName,
          // orderNumber:null,  
          // elementDescription:null
        });

        this.elementform.get('organizationName')?.disable();
        if(data.statusCode == 200)
        {  console.log('this.elementform reset', this.elementform)
          this.elementform.get('elementCode')?.reset();
          this.elementform.get('categoryType')?.reset();
          this.elementform.get('elementName')?.reset();
          this.elementform.get('orderNumber')?.reset();
          this.elementform.get('elementDescription')?.reset();

          this.loader=false;
          // this.callTable();
          // this.elementform.reset();
          // this.validate.organizationName.markAsUntouched();
             this.formsubmitted = true;
       //   this.elementList();
          this.submitted = true;
            this.success = true;
          this.sucess_msg = data.description;
            // this.searchSubmit();
            // if((this.searchForm.value.filterorganizationName!="null"||this.searchForm.value.filterorganizationName!=null)||(this.searchForm.value.categoryTypeSearch!="null"||(this.searchForm.value.categoryTypeSearch!=null))||((this.searchForm.value.filterfinancialyear!="null")||(this.searchForm.value.categoryTypeSearch!=null))||
            // ((this.searchForm.value.filterorganizationName!="null"||this.searchForm.value.filterorganizationName!=null)&&(this.searchForm.value.categoryTypeSearch!="null"||this.searchForm.value.categoryTypeSearch!=null)))
            // {
            //   this.searchSubmit();
            // }

            if(this.searchForm.value.categoryTypeSearch!=null){
              this.searchSubmit();
                console.log("search update")
            }
            if((this.searchForm.value.filterorganizationName==null || this.searchForm.value.filterorganizationName== 'null' )){
              console.log("Without",this.searchForm.value);
      
            }
     
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        }
    //     else
    //  {
    //    console.log("err1",data.statusCode);
    //   //  console.log("err1",result.clientCode);
    //    this.error_msg = true;
    //     // this.importerror = true;
    //   //  this.errormessagejson = data;
    //   //  console.log('this.errormessagejson',this.errormessagejson);
    //   //  this.error_msg = result.description;
    //   //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
    //  }
        // else
        // {   this.noRecordsFound = true; 
        //   console.log('data.statusCode if failure', data.statusCode)
        //   this.loader=false;

        //   this.error = true;
        //   this.error_msg = data.description;
        //   console.log(this.error_msg)
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
        this.isprocessing1=false;

      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;
        this.error_msg = err.error.message;
        
        setTimeout(() => {this.error = false}, 3000);
        this.isprocessing1=false;

      });
    }
  }

  get elementCode()
  {
    return this.elementform.get('elementCode');
  }

  get elementName()
  {
    return this.elementform.get('elementName');
  }

  get categoryType()
  {
    return this.elementform.get('categoryType');
  }

  
  get orderNumber()
  {
    return this.elementform.get('orderNumber');
  }

  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }
}
