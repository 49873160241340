import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
// import { MyErrorStateMatcher } from 'src/app/Authenticaton/login/login.component';
import { LeaveService } from 'src/app/providers/leave.service';

@Component({
  selector: 'app-type-add',
  templateUrl: './type-add.component.html',
  styleUrls: ['./type-add.component.scss']
})
export class TypeAddComponent implements OnInit {
  submitted = false;
  button_label = 'Save'
  label: string = 'create leave type';
  // matcher = new MyErrorStateMatcher();
  leaveTypeForm: FormGroup
  id: any;
daysOptions: any;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private gs: GlobalserviceService,
    private leaveservice:LeaveService) {
  }

  ngOnInit() {

    this.leaveTypeForm = this.fb.group({
      leaveName: ['', Validators.required],
      noOfDays: ['', Validators.required],
      createdOn: [''],
      status: ['', Validators.required]
    });

    // get by id for edit
    this.route.paramMap.subscribe(d => {
      this.id = d.get('id');
      if (this.id) {
        this.label = 'update'
        this.button_label = 'update'
      }
    })

    if (this.id) {
      this.leaveservice.getUserTypeById(this.id).subscribe(
        d => {
          this.getEditDataId(d)
        }
      )
    }
    else{
      this.leaveTypeForm.patchValue({
        status:1
      })
    }
  }

  // patch value
  private getEditDataId(d) {
    this.leaveTypeForm.patchValue({
      leaveTypeId: d.leaveTypeId,
      leaveName: d.leaveName,
      noOfDays: d.noOfDays.toString(),
      status: d.status.toString()
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.leaveTypeForm.invalid) {
      return
    }
    if (this.id) {
      let x = this.leaveTypeForm.value
      x['leaveTypeId'] = this.id
      var date = new Date();
      x['createdOn'] = date;
      let days = Number(x.noOfDays);
      x['noOfDays'] = days;
      x['status'] = Number(x.status);

      this.leaveservice.updateLeaveType(this.id, x).subscribe(
        d => {
          Swal.fire({
            text: 'Updated Successfully',
            icon: 'success'
          })
          this.router.navigate(['/home/type-list']);
        },
        err => {
          Swal.fire({
            text: err.error.message,
            icon: 'error'
          })
        },
        () => {
          console.log("done");
        }
      )
    }
    else {
      let x = this.leaveTypeForm.value
      var date = new Date();
      x['createdOn'] = date;
      let days = Number(x.noOfDays);
      x['noOfDays'] = days;
      x['status'] = 1;
      this.leaveservice.createLeaveType(x).subscribe(d => {
        Swal.fire({
          text: 'Created Successfully',
          icon: 'success'
        });
        this.router.navigate(['/home/type-list'])
      },
        err => {
          Swal.fire({
            text: err.error.message,
            icon: 'error'
          })
        },
        () => {
          console.log("done");
        }
      )
    }
  }
  get f() {
    return this.leaveTypeForm.controls
  }

}


