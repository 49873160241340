import { Component, OnInit,EventEmitter,ViewChild  } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AnnouncementService } from '../announcement.service';
import Swal from 'sweetalert2';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import Quill from 'quill';


@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  public Editor = ClassicEditor;
announcementForm: FormGroup;
public editorContent: string = ' ';

  orgId: any;
  success1=false;
  sucess_msg: any;
  isLoading: boolean;
  editId: number;
  resultData: any;
  editStatus: any;
  error1:any=false;
  error_msg: any;
  errorMsg: any;
  submitted: boolean;
  usertData: any;
  userId: any;
  organizationId: any;
  [x: string]: any;
 public payload:any;
 dowUrl: any;
 passContent:any;
loader:boolean=false;
isprocessing1:boolean=false;


 onEditorChange(event: any) {
  console.log("Editor change event:", event);
  if (event && event.editor) {
    this.editorContent = event.editor.getData();
    console.log("Editor content changed:", this.editorContent);
  }
}

// onEditorChange(event: any) {
//   // Get the content from the CKEditor
//   this.editorContent = event.editor.getData();

//   // Parse the CKEditor output to extract the oembed or img URL
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(this.editorContent, 'text/html');
  
//   // Check for figure elements containing oembed or img tags
//   const figureEmbed = doc.querySelector('figure oembed');
//   const figureImg = doc.querySelector('figure img') as HTMLImageElement;

//   let extractedUrl = '';

//   if (figureEmbed) {
//     extractedUrl = figureEmbed.getAttribute('url') || '';
//     this.passContent = extractedUrl;
//     console.log("Extracted oembed URL:", extractedUrl);  // Output: Embedded URL (e.g., YouTube)
//   } else if (figureImg) {
//     extractedUrl = figureImg.src;
//     console.log("Extracted image URL:", extractedUrl);  // Output: Image URL
//   } else {
//     console.log("No media found in figure tag");
//   }
// }






  constructor(
    private router: Router,
    private location:Location,
    private fb: FormBuilder,
    private http: HttpClient ,
    private AnnouncementService: AnnouncementService,
    private ActivatedRoute:ActivatedRoute,




  ) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.organizationId = this.usertData.organization.organizationId;
    console.log(this.organizationId);
    this.userId = this.usertData.userId;
    this.clientId=this.usertData.employee?.client?.clientId

 
  this.announcementForm = this.fb.group({
    announcementTitle: ['', Validators.required], 
    announcementContent: ['',[Validators.required,Validators.minLength(10)]],
    fileName:['']
  });
  this.editId = this.ActivatedRoute.snapshot.params.announcementId;

  console.log(this.editId);
  // console.log(this.announcementTitle);

console.log(this.payload);

  if(this.editId)
  {
    this.loader=true;
    this.AnnouncementService.getAnnouncementById(this.editId).subscribe((resultData:any) =>
    {

      this.resultData=resultData;
      this.loader=false
      console.log('resultdata',this.resultData);
      // console.log(this.resultData.announcementAttachment.fileViewUrl)
      // console.log(this.resultData?.announcementAttachment.fileName)
      // console.log(this.resultData.announcementAttachment.fileDownloadUrl)


      this.editStatus = this.resultData.status;
      if (this.resultData.announcementAttachment) {
        this.announcementForm.patchValue({
          announcementTitle: this.resultData.announcementTitle,
          announcementContent: this.resultData.announcementContent,
          fileName: this.resultData.announcementAttachment.fileName
        });
      } else {
        // If announcementAttachment is not present, set fileName to null or empty string
        this.announcementForm.patchValue({
          announcementTitle: this.resultData.announcementTitle,
          announcementContent: this.resultData.announcementContent,
          fileName: null // or '' for empty string
        });
        
      }
      // this.fileName = this.resultData.announcementAttachment.fileName;
      this.dowUrl=this.resultData.announcementAttachment.fileDownloadUrl;
      console.log(this.dowUrl)
      // console.log(this.resultData.announcementAttachment.fileDownloadUrl)

      console.log( this.announcementForm);

   
   
    });
  }
  }

 

 

  Cancel1(){
    this.router.navigate(['/home/manage-announcement']);

  }


  // onSubmit() {
  //   // this.submitted = true;
  //   // if (this.permissionForm.invalid || this.invalidInput) {
  //   //   return
  //   // }
  //   this.submitted = true;

  //   if(this.editId) //Update
  //   {
  //     console.log("1111111111111",this.usertData.organization.organizationId)
  //   //  console.log(this.resultData.announcementAttachment.fileName)
  //     console.log(this.editId);
  //     var postValues1 = {
  //       announcementId  : this.editId,
  //       announcementTitle         : this.announcementForm.value.announcementTitle,
  //       announcementContent         : this.announcementForm.value.announcementContent,
  //       // announcementAttachment: this.payload,
  //       organization: {
  //         // createdBy:this.userId,
  //         // lastUpdatedBy: this.userId,
  //         organizationId: this.usertData.organization.organizationId,
  //         // organizationName: this.usertData.organization.organizationName
  //     },
  //       status : {
  //         listTypeValueId   : 1
  //       }
     
  //     }
  //     console.log(postValues1);
  //     // console.log(this.resultData.announcementAttachment.fileName)

      
   
  //     console.log("this.editId:"+this.editId);
  //     console.log("FInal JSON:"+JSON.stringify(postValues1));
      
  //     this.AnnouncementService.editAnnouncement(this.editId,postValues1).subscribe((result: any) =>
  //     {
  //       if(result.statusCode == 200)
  //       {
  //         this.success = true;
  //         this.sucess_msg = result.description;
  //         this.submitted = false;
  //         setTimeout(() =>
  //         {
  //           this.router.navigate(['/home/manage-announcement']);
  //         }, redirectMsgTimeOut)
  //       }
  //       else
  //       {
  //         this.error = true;
  //         console.log("Error Create:"+result);
  //         this.error_msg = result.description;
  //         setTimeout(() => 
  //         {
  //           this.error = false;
  //           // this.router.navigate(['/home/kra-template']);
  //         }, redirectMsgTimeOut)
  //       }
  //     }, err =>{
  //       console.log("Error result");
  //       this.errorMsg = err.error.description;
  //     })
  //   }
  //   else //Add
  //   {
  //     console.log("1111111111111nnnnnn",this.usertData.organization);
  //     console.log(this.payload);
  //     var postValues = {
  //       announcementTitle         : this.announcementForm.value.announcementTitle,
  //       announcementContent         : this.announcementForm.value.announcementContent,
  //       announcementAttachment:this.payload,

  //       organization: {
       
  //         organizationId: this.usertData.organization.organizationId
  //     },
      
  //     status : {
  //       listTypeValueId   : 1
  //     }

   
  //     }
  //    console.log(this.organizationId);
  //    console.log("postValues",postValues);
  //     //Add & Remove More start here
   
      
  //     let x = postValues;
  //   // x['status'] = 1;
   
   
  //   Swal.fire({
  //     title: 'Are you sure? Do you want to Add Announcement!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3f51b5',
  //     cancelButtonColor: '#f44336',
  //     confirmButtonText: 'Add'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.isLoading = true;
  //       this.AnnouncementService.applyAnnouncement(x).subscribe(
  //         (result: any) => {
  //           this.success = true;
  //           this.sucess_msg = result.description;
  //           // this.searchSubmit();
  //           setTimeout(() => {
  //             this.success = false;
  //           }, responseMsgTimeOut)
  //           setTimeout(() => {
  //             this.router.navigate(['/home/manage-announcement']);
  //             this.isLoading = false;
  //           }, redirectMsgTimeOut)
  //         },
  //          err => {
  //           setTimeout(() => {
  //               Swal.fire({
  //                   text: err.error.message,
  //                   icon: 'error'
  //               });
  //               this.isLoading = false; 
  //           }, redirectMsgTimeOut); 
  //         },
          
  //         () => {
  //           this.isLoading = false; 
  //         } 

  //       )
  //     }
  //   })
  //   }



  // }
//   onSubmit() {
//     this.submitted = true;

//     if (this.editId) { // Update
//         console.log("Updating announcement with ID:", this.editId);

//         // Prepare the base payload
//         var postValues1: any = {
//             announcementId: this.editId,
//             announcementTitle: this.announcementForm.value.announcementTitle,
//             announcementContent: this.announcementForm.value.announcementContent,
//             organization: {
//                 organizationId: this.usertData.organization.organizationId,
//             },
//             status: {
//                 listTypeValueId: 1
//             }
//         };

//         // Include the existing announcementAttachment if it hasn't been changed
//         if (!this.payload && this.resultData.announcementAttachment) {
//             postValues1.announcementAttachment = this.resultData.announcementAttachment;
//         } else if (this.payload) {
//             postValues1.announcementAttachment = this.payload;
//         }

//         console.log("Final JSON for update:", JSON.stringify(postValues1));

//         this.AnnouncementService.editAnnouncement(this.editId, postValues1).subscribe((result: any) => {
//             if (result.statusCode == 200) {
//                 this.success = true;
//                 this.sucess_msg = result.description;
//                 this.submitted = false;
//                 setTimeout(() => {
//                     this.router.navigate(['/home/manage-announcement']);
//                 }, redirectMsgTimeOut);
//             } else {
//                 this.error = true;
//                 console.log("Error Create:", result);
//                 this.error_msg = result.description;
//                 setTimeout(() => {
//                     this.error = false;
//                 }, redirectMsgTimeOut);
//             }
//         }, err => {
//             console.log("Error result");
//             this.errorMsg = err.error.description;
//         });
//     } else { // Add
//         console.log("Adding new announcement");

//         // Prepare the base payload
//         var postValues: any = {
//             announcementTitle: this.announcementForm.value.announcementTitle,
//             announcementContent: this.announcementForm.value.announcementContent,
//             organization: {
//                 organizationId: this.usertData.organization.organizationId
//             },
//             status: {
//                 listTypeValueId: 1
//             }
//         };

//         // Conditionally include the announcementAttachment if it exists
//         if (this.payload) {
//             postValues.announcementAttachment = this.payload;
//         }

//         console.log("Final JSON for add:", JSON.stringify(postValues));

//         Swal.fire({
//             title: 'Are you sure? Do you want to Add Announcement!',
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3f51b5',
//             cancelButtonColor: '#f44336',
//             confirmButtonText: 'Add'
//         }).then((result) => {
//             if (result.value) {
//                 this.isLoading = true;
//                 this.AnnouncementService.applyAnnouncement(postValues).subscribe(
//                     (result: any) => {
//                         this.success = true;
//                         this.sucess_msg = result.description;
//                         setTimeout(() => {
//                             this.success = false;
//                         }, responseMsgTimeOut);
//                         setTimeout(() => {
//                             this.router.navigate(['/home/manage-announcement']);
//                             this.isLoading = false;
//                         }, redirectMsgTimeOut);
//                     },
//                     err => {
//                         setTimeout(() => {
//                             Swal.fire({
//                                 text: err.error.message,
//                                 icon: 'error'
//                             });
//                             this.isLoading = false;
//                         }, redirectMsgTimeOut);
//                     },
//                     () => {
//                         this.isLoading = false;
//                     }
//                 );
//             }
//         });
//     }
// }
get f() {
    return this.announcementForm.controls;
  }
  announcementTitle() {
    return this.f.announcementTitle.hasError('required') ? 'Please Enter Title' : ''

  }
  announcementContent() {
    return this.f.announcementContent.hasError('required') ? 'Please Enter Content' : ''

  }
// onSubmit() {
    
//     this.submitted = true;
//     if (this.announcementForm.invalid || this.invalidInput) {
//       return
//     }

//     if (this.editId) { // Update
//       Swal.fire({
//         title: 'Are you sure? Do you want to Update the Announcement!',
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3f51b5',
//         cancelButtonColor: '#f44336',
//         confirmButtonText: 'Update'
//     }).then((result) => {
//       if (result.value) {
//           console.log("Updating announcement with ID:", this.editId);

//         // Prepare the base payload
//         let postValues1: any = {
//             announcementId: this.editId,
//             announcementTitle: this.announcementForm.value.announcementTitle,
//             announcementContent: this.announcementForm.value.announcementContent,
//             // announcementAttached:this.payload,
//             organization: {
//                 organizationId: this.usertData.organization.organizationId,
//             },
//             // status: {
//             //     listTypeValueId: 1
//             // }
//             status: {
//               listTypeValueId: this.resultData.status.listTypeValueId 
//           }
//         };

//         // if (this.payload) {
          
//         //     postValues1.announcementAttachment = this.payload;
//         // } else if (this.resultData.announcementAttachment && this.payload) {
//         //     postValues1.announcementAttachment = {
//         //         fileId: this.resultData.announcementAttachment.fileId,
//         //         fileName: this.resultData.announcementAttachment.fileName,
//         //         fileType: this.resultData.announcementAttachment.fileType,
//         //         fileSize: this.resultData.announcementAttachment.fileSize,
//         //         fileDownloadUrl: this.resultData.announcementAttachment.fileDownloadUrl,
//         //         fileBytes: this.payload
//         //     };
//         // }
//         if (this.payload) {
//           console.log("111111",this.payload);
//           postValues1.announcementAttachment = {
//               fileName: this.payload.fileName,
//               fileType: this.payload.fileType,
//               fileSize: this.payload.fileSize,
//               fileBytes: this.payload.fileBytes,
//               fileId: this.resultData && this.resultData.announcementAttachment ? this.resultData.announcementAttachment.fileId : null,

//               status: {
//                   listTypeValueId: 1
//               }
//           };
//       } else if (this.resultData && this.resultData.announcementAttachment ) {
//           // Include existing attachment if no new attachment is selected
//           postValues1.announcementAttachment = {
//               fileId: this.resultData.announcementAttachment.fileId,
//               fileName: this.resultData.announcementAttachment.fileName,
//               fileType: this.resultData.announcementAttachment.fileType,
//               fileSize: this.resultData.announcementAttachment.fileSize,
//               fileDownloadUrl: this.resultData.announcementAttachment.fileDownloadUrl,
//               fileViewUrl: this.resultData.announcementAttachment.fileViewUrl,

//               //  fileBytes: this.payload.announcementAttachment.fileBytes,
//               status: {
//                   listTypeValueId: 1
//               }
//           };
      
//       }

//         console.log("Final JSON for update:", JSON.stringify(postValues1));

//         this.AnnouncementService.editAnnouncement(this.editId, postValues1).subscribe((result: any) => {
//             if (result.statusCode === 200) {
//                 this.success = true;
//                 this.sucess_msg = result.description;
//                 Swal.fire({
//                   text: 'Updated successfully',
//                   icon: 'success'
//               });
//                 this.submitted = false;
//                 setTimeout(() => {
//                     this.success = false;

//                 }, redirectMsgTimeOut);
//                 setTimeout(() => {
//                   this.router.navigate(['/home/manage-announcement']);
//                 }, redirectMsgTimeOut);
//             } else {
//                 this.error = true;
//                 console.log("Error Create:", result);
//                 this.error_msg = result.description;
//                 setTimeout(() => {
//                     this.error = false;
//                 }, redirectMsgTimeOut);
//             }
//         }, err => {
//             console.log("Error result");
//             this.errorMsg = err.error.description;
//             Swal.fire({
//               text: err.error.description,
//               icon: 'error'
//           });
//         });
//       }
//     });
//     } else { // Add
//         console.log("Adding new announcement");

//         // Prepare the base payload
//         let postValues: any = {
//             announcementTitle: this.announcementForm.value.announcementTitle,
//             announcementContent: this.announcementForm.value.announcementContent,
//             organization: {
//                 organizationId: this.usertData.organization.organizationId
//             },
//             status: {
//                 listTypeValueId: 1
//             }
//         };

//         // Conditionally include the announcementAttachment if it exists
//         if (this.payload) {
//             postValues.announcementAttachment = this.payload;
//         }

//         console.log("Final JSON for add:", JSON.stringify(postValues));

//         Swal.fire({
//             title: 'Are you sure? Do you want to Add Announcement!',
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3f51b5',
//             cancelButtonColor: '#f44336',
//             confirmButtonText: 'Add'
//         }).then((result) => {
//             if (result.value) {
//                 this.isLoading = true;
//                 this.AnnouncementService.applyAnnouncement(postValues).subscribe(
//                     (result: any) => {
//                         this.success = true;
//                         this.sucess_msg = result.description;
//                         Swal.fire({
//                           text: 'Added',
//                           icon: 'success'
//                         }
//                         );
//                         setTimeout(() => {
//                             this.success = false;
//                         }, responseMsgTimeOut);
//                         setTimeout(() => {
//                             this.router.navigate(['/home/manage-announcement']);
//                             this.isLoading = false;
//                         }, redirectMsgTimeOut);
//                     },
//                     err => {
//                         setTimeout(() => {
//                             Swal.fire({
//                                 text: err.error.message,
//                                 icon: 'error'
//                             });
//                             this.isLoading = false;
//                         }, redirectMsgTimeOut);
//                     },
//                     () => {
//                         this.isLoading = false;
//                     }
//                 );
//             }
//         });
//     }
// }
onSubmit() {
  // this.submitted = true;
  this.isprocessing1=true;
  if (this.announcementForm.invalid || this.invalidInput) {
    this.isprocessing1=false;

    return;
  }

  if (this.editId) { // Update
    // Prepare the base payload
    let postValues1: any = {
      announcementId: this.editId,
      announcementTitle: this.announcementForm.value.announcementTitle,
      announcementContent: this.announcementForm.value.announcementContent,
      // organization: {
      //   organizationId: this.usertData.organization.organizationId,
      // },
      status: {
        listTypeValueId: this.resultData.status.listTypeValueId 
      }
    };
    if (this.organizationId) {
      postValues1.organization = {
        organizationId: this.organizationId
      };
    } else if (this.clientId) {
      postValues1.client = {
        clientId: this.clientId
      };
    }

    if (this.payload) {
      postValues1.announcementAttachment = {
        fileName: this.payload.fileName,
        fileType: this.payload.fileType,
        fileSize: this.payload.fileSize,
        fileBytes: this.payload.fileBytes,
        fileId: this.resultData && this.resultData.announcementAttachment ? this.resultData.announcementAttachment.fileId : null,
        status: {
          listTypeValueId: 1
        }
      };
    } else if (this.resultData && this.resultData.announcementAttachment ) {
      // Include existing attachment if no new attachment is selected
      postValues1.announcementAttachment = {
        fileId: this.resultData.announcementAttachment.fileId,
        fileName: this.resultData.announcementAttachment.fileName,
        fileType: this.resultData.announcementAttachment.fileType,
        fileSize: this.resultData.announcementAttachment.fileSize,
        fileDownloadUrl: this.resultData.announcementAttachment.fileDownloadUrl,
        fileViewUrl: this.resultData.announcementAttachment.fileViewUrl,
        status: {
          listTypeValueId: 1
        }
      };
    }

    console.log("Final JSON for update:", JSON.stringify(postValues1));

    this.AnnouncementService.editAnnouncement(this.editId, postValues1).subscribe((result: any) => {
      if (result.statusCode == 200) {
        this.success1 = true;
        this.sucess_msg1 = result.description;
        console.log("1111111",result.description,this.sucess_msg1)
        setTimeout(() => {
          this.success1 = false;
          this.router.navigate(['/home/manage-announcement']);
        }, 1000);
      } else {
        this.error1 = true;
        this.isprocessing1=false;

        console.log("Error Create:", result);
        this.error_msg1 = result.message;
        setTimeout(() => {
          this.error1 = false;
        }, 1000);
      }
    }, err => {
      console.log("Error result");
      this.errorMsg = err.error.description;
      this.isprocessing1=false;

      setTimeout(() => {
        this.error1 = false;
      },1000);
    });
  } else { // Add
    // Prepare the base payload
    let postValues: any = {
      announcementTitle: this.announcementForm.value.announcementTitle,
      announcementContent: this.announcementForm.value.announcementContent,
     
      status: {
        listTypeValueId: 1
      }
    };

    if (this.organizationId) {
      postValues.organization = {
        organizationId: this.organizationId
      };
    } else if (this.clientId) {
      postValues.client = {
        clientId: this.clientId
      };
    }

    if (this.payload) {
      postValues.announcementAttachment = this.payload;
    }

    console.log("Final JSON for add:", JSON.stringify(postValues));
    // return;
    this.AnnouncementService.applyAnnouncement(postValues).subscribe(
      (result: any) => {
        this.success1 = true;
        this.sucess_msg1 = result.description;
        setTimeout(() => {
          this.success1 = false;
          this.router.navigate(['/home/manage-announcement']);
        }, 1000);
      },
      err => {
        this.error1 = true;
        this.isprocessing1=false;

        console.log("Error:", err);
        this.error_msg1 = err.error.message;
        setTimeout(() => {
          this.error1 = false;
        }, 1000);
      }
    );
  }
}



onFileSelected(event: any) {
  console.log("file Read", event);
  const file: File = event.target.files[0];
  console.log("file", file);

  if (file) {
      const fileName = file.name;
      const fileType = file.type;
      const fileSize = file.size;
      let fileId = null; // Initialize fileId as null

      // Check if fileId is available in this.resultData.announcementAttachment
      if (this.resultData && this.resultData.announcementAttachment && this.resultData.announcementAttachment.fileId) {
          fileId = this.resultData.announcementAttachment.fileId;
         
      }

      // Use the obtained file details in your payload
      this.payload = {
          fileName: fileName,
          fileType: fileType,
          fileSize: fileSize,
          fileBytes: null,
          fileId: fileId, // Include fileId in the payload
          status: {
              listTypeValueId: 1
          }
      };

      this.announcementForm.patchValue({
          fileName: fileName
      });

      console.log(this.payload);
  }

  let reader = new FileReader();
  reader.onload = () => {
      let x: any = reader.result;
      let y: any = x.split(',');
      let sourceByteArray = y[1];
      this.payload.fileBytes = sourceByteArray;
  };
  reader.readAsDataURL(file);
}

  downloadfiles(dowUrl)
  {
    console.log("Download File"+dowUrl);
    const a = document.createElement('a')
                //  const objectUrl: any =  "data:" + filedata.fileType + filedata.fileEncoding + "," + filedata.fileByte;
                // const objectUrl =  `${base_url}download/${result}`
                let downloadurl=dowUrl;
                console.log("downloadurl"+downloadurl);
                const objectUrl =  downloadurl
                // const objectUrl =  `http://38.242.135.217:6018/api/enote/pms/download/TSDOC_6.jpg`
                a.href = objectUrl;
                a.download = objectUrl;
                a.click();
                URL.revokeObjectURL(objectUrl);
                console.log("Download File:"+a);
  }
  // triggerFileInput() {
  //   const fileInput = document.getElementById('fileInput') as HTMLInputElement;
  //   if (fileInput) {
  //     fileInput.click();
  //   }
  // }
  invalidInput: boolean = false;

  validateInput(event: any) {
      const inputElement = event.target;
      let value = inputElement.value;
  
      if (value.startsWith(' ')) {
          value = value.trimStart();
          inputElement.value = value;
      }
  
      this.invalidInput = value.trim().length === 0; // Check if the input consists of only spaces
  }
 
  isContentLengthValid() {
    const contentControl = this.announcementForm.get('announcementContent');
    return contentControl && contentControl.value && contentControl.value.length >= 10;
  }


  public onEditorCreated(event: Quill): void {
    console.log(event);
    this.quill = event;
    console.log("Content",this.quill);
    
  }
  
  }
  

