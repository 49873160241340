export class GlobalConstants 
{
    public static apiURL: string = "https://jesperapps.com/";
    public static siteTitle: string = "JesperApps HCM";
    public static appLogo: string = "assets/uploads/HCM_Logo_with BG.png";
    public static appLogoIcon: string = "assets/uploads/hcmapplogo.png";
    public static dateFormat: string = "dd-MMM-yyyy";
    
    /* public static appUrl: string = "https://www.example.com/";
    public static appName: string = "Example Site";
    public static appLogo: string = "assets/images/logo.png";
    public static appEmail: string = "johndoe@example.com"; */
}