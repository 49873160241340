import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  @ViewChild('select') select: MatSelect;
  isbtnDisable:boolean=false;
  postForm: FormGroup;
  submitted = false;
  errorMsg : any;
  resultData:any;
  arrayData:any = [];
  arrayDataPatch:any = [];
  sucess_msg:any;
  
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  isMasterSel:boolean;
  allSelected=false;
  roleNameExistStatus :any;
  updateroleNameExistStatus :any;
  activeMenusData :any;
  employeetData :any;
Id: any=undefined;
  role: string;
roleId: any;
  p: number;
  storeRoleId:any
  constructor(
    private settingsservice: SettingsService,
    private employeeservice: EmployeeService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private ActivatedRoute: ActivatedRoute,

    private dialogref : MatDialogRef<AddRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any,
    
    private gs:GlobalserviceService

  ){ this.isMasterSel = false; }

  ngOnInit(): void {

    console.log("edit_data::",this.edit_data);
    


    if(this.edit_data){
      this.role='Edit Role';
      this.postForm = this.formBuilder.group({
        menuId : ['',[Validators.required]],
        roleName : ['',[Validators.required ,  Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],
        // roleDescription : ['' , [ Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],
      });
    }
    else{
      this.role='Add Role';
      this.postForm = this.formBuilder.group({
        menuId : [null,[Validators.required]],
        roleName : [null,[Validators.required ,  Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],
        // roleDescription : [null, [ Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],
      });

    }

     /* Add Validation Designation Data */
    
     
    this.roleId=this.edit_data?.roleId;
    console.log(" this.roleId::", this.roleId);
    

    if(this.edit_data)
    {
      this.loader=true;
      this.settingsservice.getRoleById(this.edit_data.roleId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;
        this.loader=false;
        console.log("this.resultData::",this.resultData);
        this.storeRoleId=this.resultData.roleId
        


        this.postForm.patchValue({
          roleName: this.resultData.roleName,
          roleDescription: this.resultData.roleDescription,
          menuId:this.resultData.menu.map(
            d=> {return d.menuId}
          ),
        });
      });
      console.log("PF : ", this.postForm);
    }

    this.getActivemenus();

  }
  get validate() { return this.postForm.controls; }

  getActivemenus()
  {
    this.settingsservice.getActivemenus().subscribe((result: any) => {
      this.activeMenusData = result;
    })
  }
 

  projectCode() {
    return this.validate.roleName.hasError('required')
      ? 'Please Enter Role Name'
      : '';
     
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  onSubmit() {
    this.submitted = true;
    console.log("Submit method called:", this.submitted);
    console.log("Form value:", this.postForm.value);
  
    if (this.postForm.invalid || this.roleNameExistStatus) {
      this.isbtnDisable = false;
      return;
    }
  
    // Parse employee data from localStorage
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData') || '{}');
    const organizationId = this.employeetData?.organization?.organizationId || null;
    const clientId = this.employeetData?.employee?.client?.clientId || null;
  
    console.log("Organization ID:", organizationId);
    console.log("Client ID:", clientId);
  
    let postValues = { ...this.postForm.value };
  
    // Handle organization logic
    if (organizationId) {
      console.log("Organization logic handling...");
  
      postValues['organization'] = { organizationId };
      postValues['menu'] = postValues.menuId?.map((menuId: number) => ({ menuId })) || [];
  
      if (this.edit_data) {
        // Organization Update
        postValues['roleId'] = this.edit_data.roleId;
        postValues['roleStatus'] = this.edit_data.roleStatus;
  
        console.log("Organization Update Payload:", postValues);
        this.isbtnDisable = true;
        this.settingsservice.editRole(this.edit_data.roleId, postValues).subscribe(
          (response: any) => {
            console.log("Edit role success for organization:", response);
            this.dialogref.close(response);
          },
          (err: any) => {
            console.error("Error while editing role for organization:", err);
            this.isbtnDisable = false;
            this.dialogref.close(err.error);
          }
        );
      } else {
        // Organization Create
        console.log("Organization Create Payload:", postValues);
        this.isbtnDisable = true;
        this.settingsservice.createRole(postValues).subscribe(
          (response: any) => {
            console.log("Create role success for organization:", response);
            this.dialogref.close(response);
          },
          (err: any) => {
            console.error("Error while creating role for organization:", err);
            this.isbtnDisable = false;
            this.dialogref.close(err.error);
          }
        );
      }
    }
  
    // Handle client logic
    if (clientId) {
      console.log("Client logic handling...");
  
      postValues['client'] = { clientId };
      postValues['menu'] = postValues.menuId?.map((menuId: number) => ({ menuId })) || [];
  
      if (this.edit_data) {
        // Client Update
        postValues['roleId'] = this.edit_data.roleId;
        postValues['roleStatus'] = this.edit_data.roleStatus;
  
        console.log("Client Update Payload:", postValues);
        this.isbtnDisable = true;
        this.settingsservice.editClientRole(this.edit_data.roleId, postValues).subscribe(
          (response: any) => {
            console.log("Edit role success for client:", response);
            this.dialogref.close(response);
          },
          (err: any) => {
            console.error("Error while editing role for client:", err);
            this.isbtnDisable = false;
            this.dialogref.close(err.error);
          }
        );
      } else {
        // Client Create
        console.log("Client Create Payload:", postValues);
        this.isbtnDisable = true;
        this.settingsservice.clientCreateRole(postValues).subscribe(
          (response: any) => {
            console.log("Create role success for client:", response);
            this.dialogref.close(response);
          },
          (err: any) => {
            console.error("Error while creating role for client:", err);
            this.isbtnDisable = false;
            this.dialogref.close(err.error);
          }
        );
      }
    }
  }
  
  // onSubmit() //form Submit Function
  // {
//     this.submitted = true;
//     console.log("submit methos calling!!!",this.submitted);
//     console.log("form value::",this.postForm.value);
//     console.log("form:",this.postForm);
//     if (this.postForm.invalid || this.roleNameExistStatus == true) {
//       this.isbtnDisable=false;
//       return;
//     }
//     else{
//       if(this.postForm.invalid){
//         // this.isbtnDisable=true
//         return
//       }
//     }
    
    
//     this.p=1;
//     this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
//     console.log("local data::",this.employeetData);
    

//     let organizationIds = this.employeetData?.organization?.organizationId;
//     let clientIds=this.employeetData?.employee?.client?.clientId
//     // let organizationIstore = organizationIds ? { organizationId: organizationIds } : null;
//     let clientDetailStore=clientIds?{clientId:clientIds}:null;
//     console.log('organizationId',organizationIds)
//     console.log("client id::",clientIds);
//     // console.log("organizationIstore::",organizationIstore ,"clientDetailStore::",clientDetailStore);
    
    
 
//           // this.isbtnDisable=false;

//     //stop here if form is invalid
   

      

//     if(this.edit_data) //Edit
//     {
//       let x = this.postForm.value;
//       x.menuId.map(
//         e => {
//           let data;
//           if (e) {
//             data = {
//                 menuId: e
//             }
//           }
//           this.arrayData.push(data)
//         }
//       )
     
//       if(organizationIds != null && organizationIds !== ''){
//         let postValues = this.postForm.value;
//         postValues['organization'] = { organizationId: organizationIds };
//         postValues['roleId'] = this.edit_data.roleId;
//         postValues['roleStatus'] = this.edit_data.roleStatus;
//         postValues['menu'] =  this.arrayData;
  
//         this.backdrop = true;
//         // this.loader=true;
  
//         /* if(this.postForm.valid && this.updateroleNameExistStatus == true)
//         {
//            return
//         } */
//        this.isbtnDisable=true
  
//         this.settingsservice.editRole(this.edit_data.roleId,postValues)
//         .subscribe((user: any) =>
//         {
//           //this.router.navigate([ '/home/designation' ]);
//           //window.location.reload();
  
//           this.backdrop = false;
//           this.loader = false;
//           // this.success = true;
//           this.isbtnDisable=true
  
//           this.dialogref.close(user);
//         }, err =>{
//           this.backdrop = false;
//           this.loader = false;
//           this.dialogref.close(err.error);
//           // this.error = true;
//           // this.update_error_msg = err.error.message;
//           // console.log(this.update_error_msg);
//           //setTimeout(() => {this.error = false}, 2000)
//         })
//       }
//       else{
//       let x = this.postForm.value;
//       x.menuId.map(
//         e => {
//           let data;
//           if (e) {
//             data = {
//                 menuId: e
//             }
//           }
//           this.arrayData.push(data)
//         }
//       )
//       console.log("menu calling!!",this.arrayData);
        
//       let postValues = this.postForm.value;
//       postValues['organization'] = { organizationId: organizationIds };
//       postValues['menu'] =  this.arrayData;
//       console.log("PV:",postValues);
//       console.log("AD:",this.arrayData);
//       /* if(this.postForm.valid && this.roleNameExistStatus == true)
//         {
//           return
//         }
//       */
//         this.isbtnDisable=true

//         console.log("create payload",postValues);
        

//       this.settingsservice.createRole(postValues).subscribe((user: any) =>
//       {
//         this.backdrop = false;
//         this.loader = false;
//         // this.success = true;
//         // if(user.statusCode==200){
//         // this.success = true;
//         // this.sucess_msg=user.description;
//         this.isbtnDisable=true
        

//         // setTimeout(() => {
//         //   console.log("dialoge box closing ");
          
//         //                 this.isbtnDisable=false;
    
//         //                 // this.actionitem.setSuccessUpdateMessage('');
    
          
//         //                 //  this.zone.run(() => {
//         //                 // this.dialogRef.close(x)
          
//         //                 // this.router.navigate(['/home/action-item-report']);
//         //                 this.success=false
//         //                 this.router.routeReuseStrategy.shouldReuseRoute = () => false;
//         //               const currentUrl = this.router.url;
//         //               this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
//         //               this.router.navigateByUrl(currentUrl);
//         //               });
//         //           },5000)
//         this.dialogref.close(user);
                
// ''
//       }, err =>{
//         this.backdrop = false;
//         this.loader = false;
//         this.dialogref.close(err.error);
//         // this.error = true;
//         // this.update_error_msg = err.error.message;
//         // console.log(this.update_error_msg);
//         //setTimeout(() => {this.error = false}, 2000)
//       })
//     }
// 
  // }
  changePlaceholderColor(event: any): void {
    if (event.target.value) {
      const inputValue = event.target.value.trim(); // Remove leading and trailing spaces
      if (inputValue !== '') {
        // Check if input value is not just a space
        const input = event.target as HTMLInputElement;
        // input.classList.remove('select-placeholder');
        // input.classList.remove('textarea');
      }
      // else {
      //     const input = event.target as HTMLInputElement;
      //     input.classList.add('black-placeholder');
      // }
    }
  }

  
  checkExistRoleName(val) {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
  
    // Extract organizationId and clientId from localStorage data
    let organizationId = this.employeetData.organization.organizationId;
    let clientId = this.employeetData?.employee?.client?.clientId; // Assuming clientId exists under 'client'
  
    console.log('organizationId:', organizationId);
    console.log('clientId:', clientId);
  
    if (this.postForm.value.roleName.length !== 0) {
      let postValues = this.postForm.value;
  
      // Assign organizationId or clientId based on conditions
      if (organizationId) {
        postValues['organization'] = { organizationId: organizationId };
      }
  
      if (clientId) {
        postValues['client'] = { clientId: clientId };
      }
  
      if (this.edit_data) {
        postValues['roleId'] = this.edit_data.roleId;
  
        console.log("Payload for update:", postValues);
  
        this.settingsservice.updateCheckExistRoleName(postValues).subscribe((result: any) => {
          if (result.statusCode === 200) {
            this.roleNameExistStatus = false;
          } else if (result.statusCode > 400) {
            this.roleNameExistStatus = true;
          }
        });
      } else {
        console.log("Create operation initiated.");
        console.log("Payload for create:", postValues);
  
        this.settingsservice.createCheckExistRoleName(postValues).subscribe((result: any) => {
          if (result.statusCode === 200) {
            this.roleNameExistStatus = false;
          } else if (result.statusCode > 400) {
            this.roleNameExistStatus = true;
          }
        });
      }
    } else {
      this.roleNameExistStatus = false;
    }
  }
  
/*
updatecheckExistRoleName(val)
  {
        this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    if(this.edit_data)
    {
      editData = this.edit_data;
    }

    if(this.postForm.value.roleName.length != 0)
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['roleId'] = this.edit_data.roleId;

      console.log(postValues);

      this.settingsservice.updateCheckExistRoleName(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.updateroleNameExistStatus = false;
        }
        else if(result.statusCode > 400)
        {
          this.updateroleNameExistStatus = true;
        }
      })
    }
    else
    {
      this.updateroleNameExistStatus = false;
    }
  }
 */


  toggleAllSelection()
  {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.postForm.controls.menuId.setValue(this.activeMenusData.map(row =>row.menuId));
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this.postForm.controls.menuId.setValue([]);
    }
  }
  individualCheckboxChanged() {
    const selectedEmployeeIds = this.postForm.get('menuId').value;
    if (selectedEmployeeIds.length === this.activeMenusData.length && selectedEmployeeIds.length !== 0) {
      this.allSelected = true;
      console.log("if entering inside option");
    } else {
      this.allSelected = false;
      console.log("else condition");
    }
  }
  optionClick()
  {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) =>
    {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  add()
  {
    let x = this.postForm.value;
    if(x.menuId == '')
    {
      alert("Please select the options")
    }
    else
    {
      this.select.close()
    }
  }

  cancel()
  {
    this.loader=false;
    this.postForm.patchValue({menuId:''})
    this.select.close()
  }


  save()
  {
    /* this.submitted=true;
    if(this.postForm.invalid){return}
      let x = this.postForm.value;
      x.employee.map(
        e => {
          let data;
          if (e) {
            data = {
              menu: {
                menuId: x.menuname
              }
            }
          }
          this.arrayData.push(data)
        }
      ) */
     /* console.log(JSON.stringify(this.arrayData))this.gs.AssignEmpRole(this.arrayData).subscribe(
        (e:any)=>{
        //   alert("success");
          Swal.fire({
            text:e.message,
            icon:'success'
          })
          window.location.reload();
        },err =>{
          this.arrayData.length = 0;
        }
      ) */
  }
}
