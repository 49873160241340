
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee PayRoll</span>
                </div> 
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/employee-payroll-list" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                        <form [formGroup]="payrollForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Financial Year<span class="star"> *</span></label>
                                    <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                        <option [value]="null"> -- Select Financialyear --- </option>
                                        <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.financialyear.invalid && validate.financialyear.touched) || validate.financialyear.dirty || submitted">
                                        <span *ngIf="validate.financialyear.errors?.required" class="text-danger">Please select financialyear</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Period<span class="star"> *</span></label>
                                    <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                        <option [value]="null"> -- Select Period --- </option>
                                        <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.period.invalid && validate.period.touched) || validate.period.dirty || submitted">
                                        <span *ngIf="validate.period.errors?.required" class="text-danger">Please select period</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Client</label> 
                                    <select formControlName="client" (change)="getclientEmployees()"  class="form-select form-select-solid form-select-lg select2-new">
                                        <option [value]="null"> --- Select Client --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>    
                                    <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                        <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                         <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  
                                    </div>
                                </div>
                                     <div class="form-group col-md-3">
                             
                                <label class="col-form-label">Employee<span class="star">*</span></label>
                                <input type="text"matInput  placeholder="Search Employee"   
                                           formControlName="employeecode" 
                                           [matAutocomplete]="auto"
                                           class="form-control" (keyup)="empSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <mat-option  value="All" (click)="empAllSelect()">All
                                          </mat-option>
                                      <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employeeNumber}}- {{emp.firstName}}  
                                      </mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                        search
                                    </mat-icon>
                                <div class="valid-msg" *ngIf="submitted">        
                                    <span *ngIf="this.selectedEmployeeData.length == 0 && submitted == true" class="text-danger">Please select employee</span> 
                                </div>
                            </div>
                     
                            </div> 
                            <div class="row">
                                <div class="col-md-12" style="float: right;">
                                    <!-- <button  class="submit action-button btn btn-primary btn-sm" [routerLink]="['home/employee-rating-list']"
                                        style="margin-right: 20px;" (click)="close()">Cancel</button> -->
                                    <button    type="submit" class="submit action-button btn btn-primary btn-sm" style="margin-bottom: 10px;">Calucate Payroll</button>
                                </div>
                            </div>
                            <div class="row" *ngIf="selectedEmployeeData.length != 0">
                                <div class="col-md-11 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-1 text-left p1-0">
                                    <select [(ngModel)]="pagePerItem"  [ngModelOptions]="{standalone: true}" (click)="pageChages()" class="form-control report-filter">
                                        <option value="10" selected>10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                           </div>
                           <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                            <table class="table  --table-bordered table-border-new" style="width: 100%;border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                <thead class="t-head">
                                    <tr>
                                        <!-- <th class="text-center">S.No</th> -->
                                        <!-- <th class="text-center">Action</th> -->
                                       
                                        <th>Emp Code</th>
                                        <th>Emp Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Desgination</th>
                                        <th>DOJ</th>
                                        <th>Gender</th>
                                      
                                        <!-- <th class="text-center">Status</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of selectedEmployeeData | paginate: { itemsPerPage: pagePerItem, currentPage: p }  let i=index;">
                                        <!-- <td class="text-center">{{i+1}}</td> -->
                                        <!-- <td class="text-center">
                                            <button mat-menu-item  type="button" (click)="deleteemployee(i)"  >
                                                <mat-icon   >delete</mat-icon>
                                            </button>
                                        </td> -->
            
                              
                                        <td>{{row.employeeNumber}}</td>                                      
                                        <td>{{row.firstName}}  </td>
                                        <td>{{row.mobileNumber}}</td>
                                        <td>{{row.organizationEmail}}</td>
                                        <td *ngIf="row.designation !=undefined" >{{row.designation.designationName}}</td>
                                        <td *ngIf="row.designation == undefined" >--</td>
                                        <td>{{row.dateOfJoining | date : dateFormat}}</td>
                                        <td>{{row.gender.listTypeValueName}}</td>
                                                
                                        <!-- capitalizeWordsName -->
                                        <!-- <td class="active-status text-center">
                                            <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                                {{row.status.listTypeValueName}}
                                            </span>
            
                                            <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                                {{row.status.listTypeValueName}}
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                                <!-- <tbody>
                                    <tr *ngIf="selectedEmployeeData?.length == 0">
                                        <td style="text-align: center;" colspan="12" *ngIf="nodatafound">No matching records found!</td>
                                    </tr>
                                </tbody> -->
                            </table>
                           </div>
                          
                            <div *ngIf="selectedEmployeeData?.length > 0 ">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="showing-record">
                                            {{selectedEmployeeData.length}} rows
                                        </div>
                                    </div>
                                    <div class="col-md-8 text-right">
                                        <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                              
                            </div>  

                        </form>     
            </div>
        </div>
    </div>
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border " role="status"></div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <!-- <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button> -->
</div>