<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Manage Leave Request</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<nav class="navbar  bg" style="margin-top: 67px;">
    <a class="navbar-brand navDesign" style="font-size: 22px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;">Leave Request</a>
    <div class="d-flex">
    <input
          class="form-control me-2"
          type="text"
          placeholder="Search..."
      [(ngModel)]="searchs" name="search"    (input)="validateInput($event)"
          (keydown)="validateInput($event)"
          (keyup)="validateInput($event)"
          (keyup)="changepage();"
          name="search"
          aria-label="Search"
          style="
            width: 250px;
            height: 38px;
            flex-shrink: 0;
            border-radius: 5px;
            background: #fff;position: relative;right: 20px;
          "
        />
       
       
      </div>
   
  </nav>

<div class="common-content">
    <div class="card-new">
        <div class="colDesign card" *ngIf="list_counts">
            <div class="card-body" >
                <!-- <div [ngClass]="{'card colDesign': !loader, 'hidden': loader}">
                    <div [ngClass]="{'card-body': !loader, 'hidden': loader}" > -->
                <!-- <div class="row mb-2">
                    <div class="col-md-12">
                        <ng-container *ngIf="loader; else elseLoading" class="loading">
                            <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
                        </ng-container> 
                    </div>
                </div> -->
                    <!-- <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-md-8">
                                <div class="row mb-3 pl-0">
                                    <div class="col-md-6 col-6">                                                                   -->
                                        <!-- <input class="form-control search-input form-control-lg form-control-solid" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search"    (input)="validateInput($event)"
                                        (keydown)="validateInput($event)"
                                        (keyup)="validateInput($event)"
                          
                                        > -->
                                        <!-- <i class="fa fa-search" style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%);"></i> -->
                                    <!-- </div> -->
                                    <!-- <div class="col-xl-4 col-lg-4 col-6">
                                        <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn" style="font-family: Mulish;">Search</button>
                                    </div> -->
                                <!-- </div>
                                <span> </span>
                            </div>
                        </div>
                    </div> -->
                    <!-- <ng-container>
                        <div class="--example-container --mat-elevation-z8  --container-fluid">
                            <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count > 0" 
                                class="table table-responsive --table-bordered table-border-new" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                                <ng-container matColumnDef="employeeNumber">
                                    <th mat-header-cell *matHeaderCellDef class="text-center">
                                        Emp&nbsp;No
                                    </th>
                                    <td mat-cell class="text-center" *matCellDef="let element"> {{element.employee.employeeNumber}} </td>
                                </ng-container>
                                <ng-container matColumnDef="employeeName">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Emp&nbsp;Name
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.employee.firstName | titlecase}} {{element.employee.lastName | titlecase}}  </td>
                                </ng-container>
                                <ng-container matColumnDef="organizationEmail">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Organization&nbsp;Email
                                    </th>
                                    <td mat-cell *matCellDef="let element" [matTooltip]="element.leaveReason" [matTooltipPosition]="'right'">
                                        {{element.employee.organizationEmail}} </td>
                                </ng-container>
                                <ng-container matColumnDef="startDate">
                                    <th mat-header-cell *matHeaderCellDef>
                                        From&nbsp;Date
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.startDate | date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="endDate">
                                    <th mat-header-cell *matHeaderCellDef>
                                        To&nbsp;Date
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.endDate | date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="noOfLeaveDays">
                                    <th mat-header-cell *matHeaderCellDef class="text-center">
                                        No.of&nbsp;Days
                                    </th>
                                    <td mat-cell class="text-center" *matCellDef="let element"> {{element.noOfLeaveDays}} </td>
                                </ng-container>
                                <ng-container matColumnDef="reason">
                                    <th mat-header-cell *matHeaderCellDef class="" style="width:20%;text-align:left;padding-left:2%"><b>Leave&nbsp;Reason</b></th>
                                    <td mat-cell *matCellDef="let element; let i = index">
                                        <span class="reason-span" >{{element.leaveReason}}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="leaveStatus">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Status
                                    </th>
                                    <td mat-cell *matCellDef="let element" [style.color]="leaveStatus(element.leaveStatusName)">
                                        {{element.leaveStatusName}} </td>
                                </ng-container>
                                <ng-container matColumnDef="star">
                                    <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <a mat-menu-item (click)="approve(element)" *ngIf="element?.approvals?.approver?.fyi != empId">
                                                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#01E325">done</mat-icon>
                                                <span class="cancel-trip-text" style="color:#01E325">Approve</span>
                                            </a>
                                            <a mat-menu-item (click)="denied(element)" *ngIf="element?.approvals?.approver?.fyi != empId">
                                                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#FF7171">close</mat-icon>
                                                <span class="cancel-trip-text" style="color:#FF7171">Deny</span>
                                            </a>
                                            <a mat-menu-item (click)="openDialog(element)"  *ngIf="element?.approvals?.approver?.fyi != empId">
                                                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#5E06D0">toc</mat-icon>
                                                <span class="cancel-trip-text" style="color:#5E06D0">Show Leave List</span>
                                            </a>
                                        </mat-menu>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <div class="container" *ngIf="leaveData ==true">
                                <div class="alert alert-primary" style="width: 100%; justify-content: center;text-align: center;"> No Leave Request</div>
                            </div>
                        </div>
                    </ng-container> -->
                    <div  class="card resource-card" style="border: 0px solid rgba(0,0,0,.125);padding: 5px 5px;" *ngIf="list_count >5 && (approvallist |searchFilter :searchs).length >5">
                        <div class="row rowDesign">
                            <div class="col-xl-12 col-lg-12">
                                <div class="select-pagination">
                                    <span id="showDesign">Show : </span>
                                    <select class="select_list new-select_list" id="listDesign" [(ngModel)]="mySelect" (change)="showChanges()">
                                      <option class="form-control" value="5">5</option>
                                      <option class="form-control" value="10">10</option>
                                      <option class="form-control" value="15">15</option>
                                      <option class="form-control" value="20">20</option>
                                      <option class="form-control" value="25">25</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="--container-fluid" style="overflow: auto;">
                        <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px; margin-top: 15px;">
                            <table class="table --table-bordered table-border-new" matSort   style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                <thead class="t-head" style="background-color: #f6f9fc;">
                                    <tr>
                                        <th class="text-center">Emp No</th>
                                        <th class="text-left">Emp Name</th>
                                        <th class="text-left">Organization Email</th>
                                        <th class="text-center">From Date</th>
                                        <th class="text-center">To Date</th>
                                        <th class="text-center">No.of Days</th>
                                        <th class="text-left">Leave Reason</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="t-body">
                                    <tr *ngFor="let element of approvallist |searchFilter:searchs | paginate: { itemsPerPage: mySelect, currentPage: p }">
                                        <td class="text-center">{{element.employee.employeeNumber}}</td>
                                        <td class="text-left">{{element.employee.firstName | titlecase}} {{element.employee.lastName | titlecase}}</td>
                                        <td class="text-left">{{element.employee.organizationEmail}}</td>
                                        <td class="text-center">{{element.startDate | date:'dd/MM/YY'}}</td>
                                        <td class="text-center">{{element.endDate | date:'dd/MM/YY'}}</td>
                                        <td class="text-center">{{element.noOfLeaveDays}}</td>
                                        <td style="width:20%;text-align:left;"><span class="reason-span">{{element.leaveReason}}</span></td>
                                        <td class="text-center" [style.color]="leaveStatus(element.leaveStatusName)">
                                            {{element.leaveStatusName}}
                                        </td>
                                        <td class="text-center">
                                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <a mat-menu-item (click)="approve(element)" *ngIf="element?.approvals?.approver?.fyi != empId">
                                                    <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#01E325">done</mat-icon>
                                                    <span class="cancel-trip-text" style="color:#01E325">Approve</span>
                                                </a>
                                                <a mat-menu-item (click)="denied(element)" *ngIf="element?.approvals?.approver?.fyi != empId">
                                                    <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#FF7171">close</mat-icon>
                                                    <span class="cancel-trip-text" style="color:#FF7171">Deny</span>
                                                </a>
                                                <a mat-menu-item (click)="openDialog(element)" *ngIf="element?.approvals?.approver?.fyi != empId">
                                                    <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#5E06D0">toc</mat-icon>
                                                    <span class="cancel-trip-text" style="color:#5E06D0">Show Leave List</span>
                                                </a>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                        style="
                                        text-align: center;
                                        font-size: larger;
                                        font-weight: bold;
                                      "
                                      colspan="9"
                                      *ngIf="leaveData || ((approvallist |searchFilter :searchs).length === 0 && searchs) ||
                                               approvallist.length == 0">
                            No Leave Request                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div  *ngIf="list_count >5 && (approvallist |searchFilter :searchs).length >5 ">
                        <!-- <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
                        <pagination-controls class="float-right pagination" style="float: right;"
                        (pageChange)="p = $event; beforepage = $event"></pagination-controls> 
                    </div>
            </div>
        </div>
        <!-- <div class="colDesign card" *ngIf="leaveData || ((approvallist |searchFilter :searchs).length === 0 && searchs) ||
                    approvallist.length == 0">
            <div class="card-body" >
                <div >
                    <div class="alert alert-primary" style="width: 100%; text-align: center;">No Leave Request</div>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div *ngIf="loader " class="d-flex align-items-center justify-content-center" >
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;left: 48%;top:50%" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{success_msg}}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
  </div>

  
  