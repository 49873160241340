import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PayrollService } from 'src/app/providers/payroll.service';
import { responseMsgTimeOut}from '../../app/providers/properties'
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { ImportClientComponent} from '../import-client/import-client.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AsyncPipe} from '@angular/common';
import {  MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { EmpassignmentdetailComponent} from '../empassignmentdetail/empassignmentdetail.component'
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShiftdetailsComponent} from '../shiftdetails/shiftdetails.component'
// import { ExcelService} from '../../services/excel.service';
import {ExcelService } from '../services/excel.service'

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss']
})
export class ShiftComponent implements OnInit {
  usertData: any;
  orgId: any;
  shiftForm:FormGroup;
  filterForm:FormGroup;
  clientList:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  nodatafound: boolean = true;
  noRecordsFound: boolean =false;
  submitted: boolean = false;
  vaildStartTime:boolean =false;
  vaildEndTime:boolean =false;
  calenName:boolean=false;
  lessTime:boolean=false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  empList:any=[];
  empNum:number;
  
  update_error_msg:any;
 
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  filterName: any;
  filterClient: any;
  endTime: any;
  startTime: any;
  clientID: any;
  shiftCalendar: any;
  calendar: any;
  shiftupdateId: any;
  result: any;
  sTime: String;
  eTime: String;
  shiftCalId: any;
  hours: string;
  minutes: string;
  finalStartTime: any;
  finalEndTime: string;
  timeStart: string;
  timeEnd: string;
  updatetimeEnd: string;
  updatetimeStart: string;
  dataExport = [];
  exportingdata:any;
  exported:any;
  exporttimeStart: string;
  exporttimeEnd: string;
  calendarnameexist: boolean =false;
  existMsg : any;
  constructor(
    private fb:FormBuilder,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    private dialog:MatDialog,
    public excelService:ExcelService
  ) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    
    this.filterForm = this.fb.group({
      filtershiftname:[null],
      filterclient:[null]
    })
 
    this.shiftForm = this.fb.group({
      client:[null,[Validators.required]],
      calendar:[null,[Validators.required]],
      name:[null,[Validators.required]],
      description:[null,[Validators.required]],
      starttime:[null,[Validators.required]],
      endtime:[null,[Validators.required]],
    })  
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })
  }

  clientData(event)
  {
    console.log(event.target.value);
    this.calenName=false;
    this.clientID=event.target.value;
    this.payrollservice.getdefaultshiftcalendarbyclient(this.clientID).subscribe((result: any) => {
      console.log(result);
      this.shiftCalendar= result;
      if(result.length==0||result.statusCode==204)
     {
        console.log("No data");
        this.calenName=true;
        this.shiftForm.patchValue
      ({
        calendar:null
      });
     }
    else{
      console.log("patch");
      this.calenName=false;
      this.calendar=result[0].shiftCalendarName;
      console.log(this.calendar);
      this.shiftCalId=result[0].shiftCalendarId;
      console.log(this.shiftCalId);
      this.shiftForm.patchValue
      ({
        calendar:this.calendar
      });
    }
    })
    
    
  }
  onSubmit()
  {
    console.log("submit");
    this.submitted=true;
    if (this.shiftForm.invalid ) {
      console.log("invalid")
      return;
    }
    
    if(this.shiftupdateId) //Update Cilent
    {
      console.log(this.shiftupdateId);
      let shiftValues = this.shiftForm.value;
      console.log(shiftValues);

      if(shiftValues.starttime!=null)
      {
        var convertedTime = this.convertTo24HourFormat(shiftValues.starttime);
        this.updatetimeStart=convertedTime+":00";
        console.log(this.updatetimeStart)
      }
      if(shiftValues.endtime!=null)
      {
        var convertedTime = this.convertTo24HourFormat(shiftValues.endtime);
        this.updatetimeEnd=convertedTime+":00";
        console.log(this.updatetimeEnd)
      }
        var updateData={
          shiftId:this.shiftupdateId,
          shiftName:shiftValues.name,
          shiftDescription:shiftValues.description,
          startTime:this.updatetimeStart,
          endTime:this.updatetimeEnd,
          // startTime:this.finalStartTime,
          // endTime:this.finalEndTime,
          client:{
              clientId:shiftValues.client
          },
          shiftCalender:{
              shiftCalendarId:this.shiftCalId
          },
          status:{
              listTypeValueId:1
          }
        }
         console.log(updateData);         
        this. payrollservice.UpdateShift(this.shiftupdateId , updateData).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;
            this.shiftForm.reset();
            this.submitted = false;
            // this.searchSubmit();
            this.searchSubmit();
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                 this.shiftupdateId = null;
            setTimeout(() => {
             
            }, redirectMsgTimeOut)
          }
          else
          {
            this.error = true;
            this.error_msg = result.description;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        },
         err =>
            {
                this.errorMsg = err.error.message;
            })
    }
    else  //cretae client Assignment
    {
      var createshift={
          shiftName:this.shiftForm.value.name,
          shiftDescription:this.shiftForm.value.description,
          startTime:this.finalStartTime,
          endTime:this.finalEndTime,
          client:{
              clientId:this.shiftForm.value.client
          },
          shiftCalender:{
              shiftCalendarId:this.shiftCalId
          },
          status:{
              listTypeValueId:1
          }
        }
          this.payrollservice.creteShift(createshift).subscribe((result: any) =>
       {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          this.shiftForm.reset();     
          this.submitted = false;
          // this.searchSubmit();
          setTimeout(() => {
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
          }, redirectMsgTimeOut)
        }

      }, err =>
      {
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.message;
          }, redirectMsgTimeOut)
      })

    }
  }
  validatecalendarname(event)
  {
   if(event.target.value.length !=0 && this.shiftForm.value.name)
   {
    console.log(event.target.value);
       if(this.shiftupdateId){
         let fata = {
          shiftId:this.shiftupdateId,
          shiftName:event.target.value,
          client:{
              clientId:this.shiftForm.value.client
          },
          shiftCalender:{
              shiftCalendarId:this.shiftCalId
          }           
        }
        console.log("Update",fata);
        this.payrollservice.validateshiftnameupdate(fata,this.shiftupdateId).subscribe( (result:any) =>
        {
          if(result.shiftExist == false)
          {
             this.calendarnameexist = false;
          }
          else
          {
            this.existMsg = "Shift Name Already Exist"
            this.calendarnameexist = true;
          }
        })
       }else{
        let fata = {
          shiftName:event.target.value,
          client:{
              clientId:this.shiftForm.value.client
          },
          shiftCalender:{
              shiftCalendarId:this.shiftCalId
          }            
         }
         console.log("create",fata);

        this.payrollservice.validateshiftname(fata).subscribe( (result:any) =>
        {
          if(result.shiftExist == false)
          {
             this.calendarnameexist = false;
          }
          else
          {
            this.existMsg = "Shift Name Already Exist"
            this.calendarnameexist = true;
          }
        })
       }
   }else{
    this.calendarnameexist = false;
   }
  }

  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  clearTable()
    {
      this.resultData = [];
      this.noRecordsFound = false;
      console.log('clear')
      this.filterForm.clearValidators;
    }
  searchSubmit()
  {
    this.p=1;
    console.log("Filter");
    this.noRecordsFound = false;
    this.resultData = [];
    console.log(this.filterForm.value.filtershiftname);
    console.log(this.filterForm.value.filterclient);
    this.filterName=this.filterForm.value.filtershiftname;
    console.log(this.filterName);
    this.filterClient=this.filterForm.value.filterclient;
    console.log(this.filterClient);
    if((this.filterForm.value.filtershiftname==null&&this.filterForm.value.filterclient==null))
    {
      console.log("Required Field");
      alert('Please give shiftname or select client');
      return;
    }
    
    else if(this.filterForm.value.filtershiftname!=null&&(this.filterForm.value.filterclient==null||this.filterForm.value.filterclient.length==4))
    {
      console.log("shiftname only");
      this.payrollservice.getFilterShiftNameList(this.filterName).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.filtershiftname==null||this.filterForm.value.filtershiftname.length==0)&&this.filterForm.value.filterclient!=null)
    {
      console.log("client only");
      this.payrollservice.getFilterClientList(this.filterClient).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else 
    {
      console.log("both");
      console.log(this.filterForm.value.filtershiftname);
       this.payrollservice.getFilterShiftNameandClientList(this.filterName,this.filterClient).subscribe((resultData:any) =>
      {
        console.log("shiftname and client");
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }

  }


  onDateChange(event: any): void {
    let selectedDate = event.value;
    const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
     selectedDate = firstDayOfMonth;
    this.filterForm.setValue ({
      filterstartdate : selectedDate
    })
  }

  get validate() { return this.shiftForm.controls; }

  updateform(editData)
  {
    console.log(editData);
    this.shiftupdateId = editData.shiftId;
    this.payrollservice.getShiftId(this.shiftupdateId).subscribe((resultData:any) =>
    {
      this.result=resultData;
      console.log(this.result);
      this.shiftCalId=this.result.shiftCalender.shiftCalendarId;
      console.log(this.shiftCalId);
      if(this.result.startTime!=null)
      {
        var convertedTime = this.convertTo12HourFormat(this.result.startTime);
        this.timeStart=convertedTime;
        console.log(this.timeStart)
      }
      if(this.result.endTime!=null)
      {
        var convertedTime = this.convertTo12HourFormat(this.result.endTime);
        this.timeEnd=convertedTime;
        console.log(this.timeEnd)
      }
      
      console.log(convertedTime); 

            this.shiftForm.patchValue({
              name : this.result.shiftName,
              description : this.result.shiftDescription,
              starttime : this.timeStart,
              endtime : this.timeEnd,
              client : this.result.client.clientId,
              calendar : this.result.shiftCalender.shiftCalendarName,
              // status : this.result.status.listTypeValueId
             });
             console.log( this.shiftForm);
          })
  }
  viewdata(data)
  {
    console.log(data.shiftId);
    var dialogRef = this.dialog.open(ShiftdetailsComponent, {
      width: '470px',height:'fit-content',
      data:data.shiftId
    });
  }
  updateStatus(id,status)
	{
    this.payrollservice.updateShiftStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  timeStartDate(event)
  {
    console.log("Time",event.target.value);
    var timeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9](\s)?([AP]M|am|pm)$/;

    if (timeRegex.test(event.target.value)) {
      console.log('Time is valid!');
      this.vaildStartTime=false;
      this.sTime=event.target.value;
      // Usage example:
      // var timeString = "03:30 PM";
      var convertedTime = this.convertTo24HourFormat(this.sTime);
      console.log(convertedTime); 
      this.finalStartTime=convertedTime+":00";
      console.log(this.finalStartTime);
    } else {
      console.log('Invalid time format!');
      this.vaildStartTime=true;
    }
  }

  timeEndDate(event)
  {
    this.lessTime=false;
    console.log("Time",event.target.value);
    var timeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9](\s)?([AP]M|am|pm)$/;
    if (timeRegex.test(event.target.value)) {
      console.log('Time is valid!');
      this.vaildEndTime=false;
      this.eTime=event.target.value;
      var convertedTime = this.convertTo24HourFormat(this.eTime);
      console.log(convertedTime); 
      this.finalEndTime=convertedTime+":00";
      console.log(this.finalEndTime);
      // this.endTime=this.eTime.substring(0,5)+":00";
      // console.log(this.endTime);
    } else {
      console.log('Invalid time format!');
      this.vaildEndTime=true;
    }
    if(this.finalStartTime.substring(0,2)==this.finalEndTime.substring(0,2))
    {
      if(this.finalStartTime.substring(3,5)>=this.finalEndTime.substring(3,5))
     {
      console.log("Minutes");
       this.lessTime=true;
     }
    }
    else if(this.finalStartTime.substring(0,2)>=this.finalEndTime.substring(0,2))
    {
      console.log("Hours");
      this.lessTime=true;
    }
  }

   convertTo24HourFormat(time) 
   {
    // Split the time string into hours, minutes, and meridian
    console.log("24 Hours Format");
    var timeParts = time.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);
    var meridian = time.slice(-2).toLowerCase();
  
    // Adjust hours based on meridian
    if (meridian === "pm" && hours !== 12) {
      hours += 12;
    } else if (meridian === "am" && hours === 12) {
      hours = 0;
    }
    // Add leading zeros to hours and minutes if needed
     this.hours = String(hours).padStart(2, "0");
     this.minutes = String(minutes).padStart(2, "0");
  
    // Create the formatted time string
    var formattedTime = this.hours + ":" + this.minutes;
  
    return formattedTime;
  }
  
  
  convertTo12HourFormat(patchtime) 
  {
    // Split the time string into hours and minutes
    var timeParts = patchtime.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);
  
    // Determine the meridian (AM or PM)
    var meridian = hours >= 12 ? "pm" : "am";
  
    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours || 12; // If hours is 0, set it to 12
  
    // Add leading zeros to hours and minutes if needed
    this.hours = String(hours).padStart(2, "0");
    this.minutes = String(minutes).padStart(2, "0");
  
    // Create the formatted time string
    var formattedTime = this.hours + ":" + this.minutes + " " + meridian;
  
    return formattedTime;
  }
  
  // exportToExcel()
  // {
  //     console.log("DATA : ",this.resultData);
  //    if(this.filterForm.value.filtershiftname!=null&&this.filterForm.value.filterclient!=null)
  //     {
  //       console.log("Both");
  //       this.dataExport.length = 0;
  //       this.exportingdata = this.resultData ;
  //       for(let exportingdata of this.exportingdata)
  //       {
  //         if(exportingdata.startTime!=null)
  //         {
  //           var convertedTime = this.convertTo12HourFormat(exportingdata.startTime);
  //           this.exporttimeStart=convertedTime;
  //         }
  //         if(exportingdata.endTime!=null)
  //         {
  //           var convertedTime = this.convertTo12HourFormat(exportingdata.endTime);
  //           this.exporttimeEnd=convertedTime;
  //         }
  //         this.exported ={
  //           ShiftName   : exportingdata.shiftName,
  //           ShiftDescription     : exportingdata.shiftDescription,
  //           Client  : exportingdata.client.clientName,
  //           StartDate  : this.exporttimeStart,
  //           EndDate : this.exporttimeEnd,
  //         }
  //         this.dataExport.push(this.exported);
  //       }
  //       this.excelService.exportAsExcelFile(this.dataExport,"Export");
  //     }
  //     else if(this.filterForm.value.filtershiftname!=null&&this.filterForm.value.filterclient==null)
  //     {
  //       console.log("Name");
  //       this.dataExport.length = 0;
  //       this.exportingdata = this.resultData ;
  //       for(let exportingdata of this.exportingdata)
  //       {
  //         if(exportingdata.startTime!=null)
  //         {
  //           var convertedTime = this.convertTo12HourFormat(exportingdata.startTime);
  //           this.exporttimeStart=convertedTime;
  //         }
  //         if(exportingdata.endTime!=null)
  //         {
  //           var convertedTime = this.convertTo12HourFormat(exportingdata.endTime);
  //           this.exporttimeEnd=convertedTime;
  //         }
  //         this.exported ={
  //           ShiftName   : exportingdata.shiftName,
  //           ShiftDescription     : exportingdata.shiftDescription,
  //           Client  : exportingdata.client.clientName,
  //           StartDate  : this.exporttimeStart,
  //           EndDate : this.exporttimeEnd,
  //         }
  //         this.dataExport.push(this.exported);
  //       }
  //       console.log("EX",this.exported);
  //       this.excelService.exportAsExcelFile(this.dataExport,"Export");
  //   }
  //   else if(this.filterForm.value.filtershiftname==null&&this.filterForm.value.filterclient!=null)
  //     {
  //       console.log("client");
  //       this.dataExport.length = 0;
  //       this.exportingdata = this.resultData ;
  //       for(let exportingdata of this.exportingdata)
  //       {
  //         if(exportingdata.startTime!=null)
  //         {
  //           var convertedTime = this.convertTo12HourFormat(exportingdata.startTime);
  //           this.exporttimeStart=convertedTime;
  //         }
  //         if(exportingdata.endTime!=null)
  //         {
  //           var convertedTime = this.convertTo12HourFormat(exportingdata.endTime);
  //           this.exporttimeEnd=convertedTime;
  //         }
  //         this.exported ={
  //           ShiftName   : exportingdata.shiftName,
  //           ShiftDescription     : exportingdata.shiftDescription,
  //           Client  : exportingdata.client.clientName,
  //           StartDate  : this.exporttimeStart,
  //           EndDate : this.exporttimeEnd,
  //         }
  //         this.dataExport.push(this.exported);
  //       }
  //       console.log("EX",this.exported);
  //       this.excelService.exportAsExcelFile(this.dataExport,"Export");
  //   }
  // }
  
  
}
