import { Component, OnInit } from '@angular/core';
import { PmsService } from '../../../providers/pms.service';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
//import { AddBranchComponent } from '../add-branch/add-branch.component';
import { httpOptions,responseMsgTimeOut , redirectMsgTimeOut} from '../../../providers/properties';
import {MatSort,Sort} from '@angular/material/sort';
import { Router,ActivatedRoute } from '@angular/router';
import { DatePipe, JsonPipe } from '@angular/common';
import { UserService } from '../../../providers/user.service';
import { EMPTY, Subject, catchError, switchMap, takeUntil, throwError } from 'rxjs';
import { HeaderService } from 'src/app/providers/header.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-assign-kra',
  templateUrl: './assign-kra.component.html',
  styleUrls: ['./assign-kra.component.scss']
})
export class AssignKraComponent implements OnInit
{
  searchForm!:FormGroup;
  public searchFilter: any = '';
  orgId: number;
  templateData: any;
  employeeId: any;
  employeeCode: string;
  empolyeereadonly :boolean = true;
  empList: any;
  empPatch: string = undefined;
  templateId: any;
  activekraData: any;
  // unsubscribe$: any;
  employeeNotFound: boolean;
  templateNotFound:boolean;      
  search:any;
 
  employeeStoring:string
  employeeNumber: number;
  selectedemployee: any="";
  selectedemployeeId: any;
  selectedtemplateId:any;
  // employeeIds: any;
  assignformbyemployee: FormGroup;
  employeeCodes: string;

  
  resultData : any;
  p:number = 1;
  mySelect:any =5;
  beforepage :any;
  autoCompleteSearch: number;
  collection: any[];
  pagePerItem:any=5;
  success = false;
  error:any=false;
  tableSearch : any;
  errorMsg : any;
  sucess_msg:any;
  error_msg:any;
  usertData: any = '';
  sortedData: any[];
  dateFormat : any;
  submitted: any=false;
  nodatafound: boolean = false;
  loader:boolean= false;
  backdrop:any= false;
  employeetData: any = '';
  employeeData: any;
  temName:any;

  loading:boolean=false;
  userStartedTyping: boolean = false;
  
  
 
  employeeSelected: boolean;
  tempnameSelected : boolean = false;

  unsubscribe$: Subject<void> = new Subject<void>();

  showTable:boolean=false;
  clearButtonEnabled: boolean = false;
  buttonsDisabled: boolean = false;
  keywordstyped: any;

  
 
  constructor(private pmsservice: PmsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private ActivatedRoute:ActivatedRoute,
    private datepipe : DatePipe,
    private userservice: UserService,
    private headerservice: HeaderService, 
    
    
     ) { }

   

  ngOnInit(): void
  {
    // this.loader=true;
    // this.loading=true;
    // this.nodatafound = true;
    // this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    // let userId = this.usertData.userId;
    // this.orgId = this.usertData.organization.organizationId;
    // this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    // let organizationId = this.usertData.organization.organizationId;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.searchForm = this.formBuilder.group({
      employeeCode : [''],
      employeeId: [''],
      templateName: ['']
    });

    
    // this.getFilterFormData();
    // this.getAssignkraList(this.orgId);
    // this.headerservice.setTitle('Assign KRA')
    this.headerservice.setTitle('');

    // this.searchForm.valueChanges.subscribe(value => {
    //   // Set flag to true when the user starts typing
    //   if (value.employeeCode || value.templateName) {
    //     this.userStartedTyping = true;
    //   }
    // });


 
//  this.updateStatus(empid,status)

  }

  getAssignkraList(orgId)
  {
    this.loader = true;
    this.showTable=false;
    this.buttonsDisabled = true;
    this.pmsservice.getAssignkraList(this.orgId).subscribe((result: any) =>
    {
      console.log("Result Data",this.resultData);
      this.nodatafound = false;
      this.resultData = result;
      this.loader = false;
      this.showTable=true;
      if(result.statusCode==204)
        {
          console.log("nodata");
          this.nodatafound=true;
        }
      this.buttonsDisabled = false;
    }, error => {
      // this.loader = false;
      console.error(error);
    })
  }

  // isFormValid() {
  
  // return  this.searchForm.get('employeeCode').value  || this.searchForm.get('templateName').value ;
  // }

  isFormValid(): boolean {
    return (
      this.userStartedTyping &&
      (this.searchForm.get('employeeCode').value !== '' || this.searchForm.get('templateName').value !== '')
    );
  }

 

  

  // updateStatus(employeeKraId,status)
	// {
  //   // this.loader = true;
   
  //   this.pmsservice.updateAssignkraStatus(employeeKraId,status).subscribe( (result:any) =>
	// 	{
  

  //     if(result.statusCode == 200)
  //     {
  //       this.success = true;
  //       this.loader = false; 
        
	// 			this.sucess_msg = result.message;
  //       setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
  //       this.getAssignkraList(this.orgId);
       
  //     }
  //     else
  //     {
  //       this.error = true;
  //       this.loader = false;
  //       this.error_msg = result.message;
  //       setTimeout(() => {this.error = false}, redirectMsgTimeOut)
  //     }
     
      
  //   }, error => {
  //     this.loader = false;
  //     console.error(error);
	// 	})
	// } 


  
 
  // searchSubmit()
  // {
  //   this.resultData=[];
  //   let empCode= this.searchForm.value.employeeCode ? this.searchForm.value.employeeCode : null;
  //   console.log(empCode);
  //   let temName= this.searchForm.value.templateName ? this.searchForm.value.templateName : null;
  //   console.log(temName);
  //   this.submitted=true;
  //   if(empCode == null && temName == null)
  //   {
  //     alert('Please Search Keywords');
  //     this.getAssignkraList(this.orgId)
  //     return;
  //   }
  //   // this.loader=true;
  //  if(empCode!=null && temName!=null)
  //   this.searchForm.patchValue({
  //     employeeCode: this.employeeCodes
  //   });

    
  //  {
  //     console.log("EmpCode and TempName");
  //     this.loader=true;
  //     this.showTable=false;
      
  //     this.pmsservice.getKRATemplateTextList(this.employeeId,this.empPatch).subscribe((resultData: any) => 
  //     {
  //       this.loader=false;
  //       this.showTable=true;
  //       // this.backdrop=false;
  //       this.submitted=true;
  //       this.nodatafound = false;
  //       this.resultData = resultData;
  //       if(this.resultData.statusCode==204)
  //         {
  //           console.log("nodata");
  //           this.nodatafound=true;
  //           this.loader=false;
  //           this.showTable=true;
  //         }

  //       if(this.resultData.length == 0 || this.resultData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //         this.resultData=[];
  //         this.loader=false;
  //         this.showTable=true;
  //       }
  //     })
  //  }

   
  // if(empCode!=null && temName == null)
  //   this.searchForm.patchValue({
  //     employeeCode: this.employeeCodes
  //   });
    
  //  {
  //   console.log("EmpCode");
  //   console.log(this.employeeId);
  //   this.loader=true;
  //   this.showTable=false;
   
    
  //   this.pmsservice.getAssignKRAEmpCodeList(this.employeeId).subscribe((resultData: any) => 
  //     {
  //       this.loader=false;
  //       this.showTable=true;
  //       // this.backdrop=false;
  //       this.nodatafound = false;
  //       this.resultData = resultData;
  //       if(this.resultData.length == 0 || this.resultData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //         this.resultData=[];
  //       }
  //     }, error => {
  //       this.loader = false;
  //       this.showTable=true;
  //       console.error(error);
  //     })
  //  }

  
  //  if(temName!=null && empCode ==null )
  //  {
  //   console.log("TempName");
  //   this.loader=true;
  //     this.showTable=false;
    
  //   this.pmsservice.getAssignKRATemplateNameList(this.empPatch).subscribe((resultData: any) => 
  //     {
  //       this.loader=false;
  //       this.showTable=true;
  //       // this.backdrop=false;
  //       this.nodatafound = false;
  //       this.resultData = resultData;
  //       if(this.resultData.length == 0 || this.resultData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //         this.resultData=[];
  //       }
  //     }, error => {
  //       this.loader = false;
  //       this.showTable=true;
  //       console.error(error);
  //     })

  //  }
  //  this.clearButtonEnabled = true; 
  // }


  // searchSubmit() {
  //   this.resultData = [];
  //   let empCode = this.searchForm.value.employeeCode ? this.searchForm.value.employeeCode : null;
  //   console.log(empCode);
  //   let temName = this.searchForm.value.templateName ? this.searchForm.value.templateName : null;
  //   console.log(temName);
  //   this.submitted = true;
  
  //   if ((empCode == null || empCode == undefined) && (temName == null || temName == undefined)) {
  //     alert('Please Search Keywords');
  //     this.getAssignkraList(this.orgId);
  //     return;
  //   }
  
  //   // Disable the search and clear buttons
  //   this.loading = true;
  
  //   if ((empCode != null || empCode != undefined) && (temName != null || temName != undefined)) {
  //     this.searchForm.patchValue({
  //       employeeCode: this.employeeCodes
  //     });
  
  //     console.log("EmpCode and TempName");
  //     this.loader = true;
  //     this.showTable = false;
  
  //     this.pmsservice.getKRATemplateTextList(this.employeeId, this.empPatch).subscribe((resultData: any) => {
  //       this.loader = false;
  //       this.showTable = true;
  //       this.submitted = true;
  //       this.nodatafound = false;
  //       this.resultData = resultData;
  //       if (this.resultData.statusCode == 204) {
  //         console.log("nodata");
  //         this.nodatafound = true;
  //         this.loader = false;
  //         this.showTable = true;
  //       }
  
  //       if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //         this.nodatafound = true;
  //         this.resultData = [];
  //         this.loader = false;
  //         this.showTable = true;
  //       }
  //       // Re-enable the search and clear buttons
  //       this.loading = false;
  //     }, error => {
  //       // Re-enable the search and clear buttons in case of an error
  //       this.loading = false;
  //     });
  //   }
  
  //   if ((empCode != null || empCode != undefined) && (temName == null || temName == undefined)) {
  //     this.searchForm.patchValue({
  //       employeeCode: this.employeeCodes
  //     });
  
  //     console.log("EmpCode");
  //     console.log(this.employeeId);
  //     this.loader = true;
  //     this.showTable = false;
  
  //     this.pmsservice.getAssignKRAEmpCodeList(this.employeeId).subscribe((resultData: any) => {
  //       this.loader = false;
  //       this.showTable = true;
  //       this.nodatafound = false;
  //       this.resultData = resultData;
  //       if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //         this.nodatafound = true;
  //         this.resultData = [];
  //       }
  //       // Re-enable the search and clear buttons
  //       this.loading = false;
  //     }, error => {
  //       // Re-enable the search and clear buttons in case of an error
  //       this.loading = false;
  //     });
  //   }
  
  //   if ((temName != null || temName != undefined) && (empCode == null || empCode == undefined)) {
  //     console.log("TempName");
  //     this.loader = true;
  //     this.showTable = false;
  
  //     this.pmsservice.getAssignKRATemplateNameList(this.empPatch).subscribe((resultData: any) => {
  //       this.loader = false;
  //       this.showTable = true;
  //       this.nodatafound = false;
  //       this.resultData = resultData;
  //       if (this.resultData.length == 0 || this.resultData.length == undefined) {
  //         this.nodatafound = true;
  //         this.resultData = [];
  //       }
  //       // Re-enable the search and clear buttons
  //       this.loading = false;
  //     }, error => {
  //       // Re-enable the search and clear buttons in case of an error
  //       this.loading = false;
  //     });
  //   }
  
  //   // this.clearButtonEnabled = true;
  // }


  updateStatus(employeeKraId,status)
	{

    let empCode = this.searchForm.value.employeeCode ? this.searchForm.value.employeeCode : null;
    console.log(empCode);
    let temName = this.searchForm.value.templateName ? this.searchForm.value.templateName : null;
    console.log(temName);


    this.pmsservice.updateAssignkraStatus(employeeKraId,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.loader=true
        this.showTable=false;
        // this.success = true;
				// this.sucess_msg = result.message;
        setTimeout(() => {
          // this.success  = false;
          this.loader=false
          this.showTable=true;
          // this.getAssignkraList(this.orgId); 
          if ((this.employeeId != null || this.employeeId != undefined) && (this.empPatch  != null || this.empPatch  != undefined)) {
           
    
            
    
            console.log("EmpCode and TempName");
            this.loader = true;
            this.showTable = false;
            this.buttonsDisabled = true;
    
            this.pmsservice.getKRATemplateTextList(this.employeeId, this.empPatch).subscribe((resultData: any) => {
                this.loader = false;
                this.showTable = true;
                this.submitted = true;
                this.nodatafound = false;
                this.resultData = resultData;
                if (this.resultData.statusCode == 204) {
                    console.log("nodata");
                    this.nodatafound = true;
                    this.loader = false;
                    this.showTable = true;
                }
    
                if (this.resultData.length == 0 || this.resultData.length == undefined) {
                    this.nodatafound = true;
                    this.resultData = [];
                    this.loader = false;
                    this.showTable = true;
                }
                // Re-enable the search and clear buttons
                this.loading = false;
                this.buttonsDisabled = false;
            }, error => {
                // Re-enable the search and clear buttons in case of an error
                this.loading = false;
                this.buttonsDisabled = false;
            });
        }

       else if (this.employeeId != undefined  && this.empPatch == undefined) {
          console.log('AfterIf',this.empPatch ,this.employeeId );
            
    
            console.log("EmpCode");
            console.log(this.employeeId);
            this.loader = true;
            this.showTable = false;
            this.buttonsDisabled = true;
    

    
            this.pmsservice.getAssignKRAEmpCodeList(this.employeeId).subscribe((resultData: any) => {
                this.loader = false;
                this.showTable = true;
                this.nodatafound = false;
                this.resultData = resultData;
                if (this.resultData.length == 0 || this.resultData.length == undefined) {
                    this.nodatafound = true;
                    this.resultData = [];
                }
                // Re-enable the search and clear buttons
                this.loading = false;
                this.buttonsDisabled = false;
            }, error => {
                // Re-enable the search and clear buttons in case of an error
                this.loading = false;
                this.buttonsDisabled = false;
                
            });
        }

       else if ((this.empPatch  != null || this.empPatch  != undefined) && (this.employeeId == null || this.employeeId == undefined)) {
          console.log("TempName");
          this.loader = true;
          this.showTable = false;
          this.buttonsDisabled = true;
  
          
          
  
          this.pmsservice.getAssignKRATemplateNameList(this.empPatch).subscribe((resultData: any) => {
              this.loader = false;
              this.showTable = true;
              this.templateNotFound=false;
              this.nodatafound = false;
              this.resultData = resultData;
              if (this.resultData.length == 0 || this.resultData.length == undefined) {
                  this.nodatafound = true;
                  this.resultData = [];
              }
              
              this.loading = false;
              this.buttonsDisabled = false;
          }, error => {
             
              this.loading = false;
              this.buttonsDisabled = false;
          });
      }

      else if (empCode == null && temName == null ) {
        // alert('Please Search Keywords');
        this.getAssignkraList(this.orgId);
        
      }

        },3000)

        

      }
      else
      {
        // this.error = true;
        // this.error_msg = result.message;
        setTimeout(() => {this.error = false}, 3000)
      }
		})
	}

  searchSubmit() {
console.log('emp &&  TempName',this.employeeId, this.empPatch);

    
    this.resultData = [];
    this.p = 1;

    let empCode = this.searchForm.value.employeeCode ? this.searchForm.value.employeeCode : null;
    console.log(empCode);
    let temName = this.searchForm.value.templateName ? this.searchForm.value.templateName : null;
    console.log(temName);

     
    this.submitted = true;

    if (empCode == null && temName == null ) {
      // alert('Please Search Keywords');
      this.getAssignkraList(this.orgId);
      return;
    }
  

//     if (!this.employee) {
//       this.selectedemployee = '';
//     }

      

    // if (!this.empPatch) {
    //   this.searchForm.patchValue({
    //     templateName: ''
    //   });
    //  }

    if(!this.employeeId && !this.empPatch){
      this.searchForm.controls['employeeCode'].reset();
      this.searchForm.controls['templateName'].reset();
      this.employeeId=undefined;
      this.empPatch=undefined;
       this.employeeNotFound = false;
       this.templateNotFound=false;
      console.log("type");
      if (this.unsubscribe$) {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
      
    }

if (this.employeeId == undefined && this.empPatch  == undefined ) {

  console.log("calling");
        alert('Please Search valid Employee or KRA template name');
        this.searchForm.controls['employeeCode'].reset();
        this.searchForm.controls['templateName'].reset();
        this.employeeNotFound=false;
        this.templateNotFound=false;
        // this.getAssignkraList(this.orgId);
        this.showTable=false;
        
       
        return;
        
        
    }

    
   

    // Check if empCode is valid
    // if (empCode && !this.empList.some(emp => emp.employeeId === empCode)) {
    //     this.searchForm.patchValue({ employeeCode: null });
    //     this.getAssignkraList(this.orgId); 
    //     this.loading = false;
    //     return;
    // }

     // Disable the search and clear buttons
     this.loading = true;

    

    if ((this.employeeId != null || this.employeeId != undefined) && (this.empPatch  != null || this.empPatch  != undefined)) {
        this.searchForm.patchValue({
            employeeCode: this.employeeCodes,
           
        });

        // if(this.templateId != undefined){
        //   this.searchForm.patchValue({
        //         templateName: this.empPatch 
        //      });
        // }

        if(this.templateId == undefined){
          this.searchForm.patchValue({
                templateName:undefined
             });
             this.empPatch == undefined; 
        }
      //   this.searchForm.patchValue({
      //     templateName: this.empPatch 
      //  });

        console.log("EmpCode and TempName");
        this.loader = true;
        this.showTable = false;
        this.buttonsDisabled = true;

        this.pmsservice.getKRATemplateTextList(this.employeeId, this.empPatch).subscribe((resultData: any) => {
            this.loader = false;
            this.showTable = true;
            this.submitted = true;
            this.nodatafound = false;
            this.resultData = resultData;
            if (this.resultData.statusCode == 204) {
                console.log("nodata");
                this.nodatafound = true;
                this.loader = false;
                this.showTable = true;
            }

            if (this.resultData.length == 0 || this.resultData.length == undefined) {
                this.nodatafound = true;
                this.resultData = [];
                this.loader = false;
                this.showTable = true;
            }
            // Re-enable the search and clear buttons
            this.loading = false;
            this.buttonsDisabled = false;
        }, error => {
            // Re-enable the search and clear buttons in case of an error
            this.loading = false;
            this.buttonsDisabled = false;
        });
    }


    console.log('beforeIf',this.empPatch ,this.employeeId );
    
    if (this.employeeId != undefined  && this.empPatch == undefined) {
      console.log('AfterIf',this.empPatch ,this.employeeId );
        this.searchForm.patchValue({
            employeeCode: this.employeeCodes
        });

        if (this.employeeId != undefined && this.empPatch  == undefined ) {

          console.log("calling");
                // alert('Please Search valid Employee or KRA template name');
                // this.searchForm.controls['employeeCode'].reset();
                this.searchForm.controls['templateName'].reset();
                // this.employeeNotFound=false;
                this.templateNotFound=false;
                // this.getAssignkraList(this.orgId);
                // this.showTable=false;
                
               
              
                
                
            }

        console.log("EmpCode");
        console.log(this.employeeId);
        this.loader = true;
        this.showTable = false;
        this.buttonsDisabled = true;

// if (this.templateId == undefined) {
//   this.searchForm.patchValue({
//     templateName: undefined
//  });
// }else{
//   this.searchForm.patchValue({
//     templateName: this.empPatch
//  });
// }


// if(this.templateId){
//   this.searchForm.patchValue({
//     templateName: this.empPatch 
//   })
// }

        this.pmsservice.getAssignKRAEmpCodeList(this.employeeId).subscribe((resultData: any) => {
            this.loader = false;
            this.showTable = true;
            this.nodatafound = false;
            this.resultData = resultData;
            if (this.resultData.length == 0 || this.resultData.length == undefined) {
                this.nodatafound = true;
                this.resultData = [];
            }
            // Re-enable the search and clear buttons
            this.loading = false;
            this.buttonsDisabled = false;
        }, error => {
            // Re-enable the search and clear buttons in case of an error
            this.loading = false;
            this.buttonsDisabled = false;
            
        });
    }

    if ((this.empPatch  != null || this.empPatch  != undefined) && (this.employeeId == null || this.employeeId == undefined)) {
        console.log("TempName");
        this.loader = true;
        this.showTable = false;
        this.buttonsDisabled = true;

        // if (this.employeeId == undefined) {
        //   this.searchForm.patchValue({
        //     employeeCode: undefined
        //  });
        // }else{
        //   this.searchForm.patchValue({
        //     employeeCode: this.employeeCodes
        //  });
        // }

        this.searchForm.patchValue({
          templateName: this.empPatch 
       });

       if (this.employeeId == undefined && this.empPatch  != undefined ) {

        console.log("calling");
              // alert('Please Search valid Employee or KRA template name');
              this.searchForm.controls['employeeCode'].reset();
              // this.searchForm.controls['templateName'].reset();
              this.employeeNotFound=false;
              // this.templateNotFound=false;
              // this.getAssignkraList(this.orgId);
              // this.showTable=false;
              
             
            
              
              
          }
        
        

        this.pmsservice.getAssignKRATemplateNameList(this.empPatch).subscribe((resultData: any) => {
            this.loader = false;
            this.showTable = true;
            this.templateNotFound=false;
            this.nodatafound = false;
            this.resultData = resultData;
            if (this.resultData.length == 0 || this.resultData.length == undefined) {
                this.nodatafound = true;
                this.resultData = [];
            }
            
            this.loading = false;
            this.buttonsDisabled = false;
        }, error => {
           
            this.loading = false;
            this.buttonsDisabled = false;
        });
    }


}


  
  
  
  

  

 

  

  getEmpCodeList(event)
  {
    console.log(event);
    let keywords  = event.target.value;
    console.log(keywords);
    //let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
    if(keywords)
    {
      this.autoCompleteSearch = 0;
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
      let organizationId = this.employeetData.organization.organizationId;
      this.userservice.employeeSearch(organizationId,keywords).subscribe((result: any) =>
      {
        this.employeeData = result;
        console.log("employeeData",this.employeeData);
        if(this.employeeData.statusCode == 204)
        {
          this.autoCompleteSearch = 1; //No data Found
          this.searchForm.patchValue({
            //employeeName : firstName+" "+lastName,
            employeeId : ""
          });
        }
        else
        {
          this.autoCompleteSearch = 0;
        }
      })
    }
    else
    {
      this.autoCompleteSearch = 1;
      this.searchForm.patchValue({
        //employeeName : firstName+" "+lastName,
        employeeId : ""
      });
    }
  }

  
  noEmployeeFound() {
   
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
  
    this.empList = []; // Clear the employee list
    this.employeeNotFound = false; // Keep track of no employee found state
    
  }

  noTemplateFound(){
    this.empPatch == undefined;
    this.templateData.length = 0;
    this.templateId=undefined;
    this.templateNotFound = false;
    this.selectedtemplateId = undefined;
    this.templateData = [];
    
  }

 
  empSelect(emp) {
    console.log('emp',emp);
  
    this.employeeId= emp.employee.employeeId;
    this.employeeCodes = emp.employee.employeeNumber + '-' + emp.employee.firstName;
    // this.employeeCode = this.employeeStoring;
    console.log(this.employeeCodes);
    // console.log(this.employeeStoring);
    this.employeeNumber = emp.employee.employeeNumber;
    // this.selectedemployee = this.employee;
    this.selectedemployeeId=this.employee;

    this.searchForm.patchValue({
      employeeCode: this.employeeCodes
    });
console.log("employee Id after selecting::",this.employeeId);

    console.log('this.employee::',this.selectedemployeeId);
    console.log("wtts id ::",this.employeeId);
    // this.getTask()

    
}

empTemplate(searchData)
{
  console.log('searchData',searchData);
  console.log(this.employee);
  this.templateId=searchData.kraTemplateHeaderId;
  console.log(this.templateId);
  this.empPatch=searchData.templateName;
  console.log(this.empPatch);
  // if(this.approverId){
    this.searchForm.patchValue({
      templateName: this.empPatch 
   });
  //  this.selectedtemplateId=this.templateId;
   this.selectedtemplateId=this.empPatch
}


 

 
  
  employee(employee: any) {
    throw new Error('Method not implemented.');
  }

  // getTemplateCodeList(event)
  // {
  //   let keywords  = event.target.value;
  //   console.log(keywords);
  //   // this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
  //   // let organizationId = this.employeetData.organization.organizationId;
  //   //let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
  //   if(keywords)
  //   {
  //     // this.autoCompleteSearch = 0;
  //     this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
  //     let organizationId = this.employeetData.organization.organizationId;

  //     // this.loader=true;
  //     this.pmsservice.employeeTemplateSearch(organizationId,keywords).subscribe((result: any) =>
  //     {
        
  //       console.log("templateData",this.templateData);
  //       if(result.statusCode == 204)
  //       {
  //         // this.loader=false;
  //         this.templateData.length = 0;
  //       }
  //       else
  //       {
  //         console.log("else");
  //         this.templateData = result;
  //         console.log("this.empList",this.templateData);
  //        }
  //     })
  //   }
  // }

  getTemplateCodeList(event, event1: KeyboardEvent): void {

  
    if (event1.key === ' ') {
      this.templateNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');
      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      if (this.templateData?.length > 0) {
        this.nodatafound = false;
      }
  
      this.templateData = [];
      console.log(event.target.value);
      let empPatch = event.target.value;
      console.log(empPatch);
      let keywords  = event.target.value;
    console.log(keywords);
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    
  
      // Cancel the previous API request
      if (this.unsubscribe$) {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
      this.unsubscribe$ = new Subject<void>();
      console.log(this.templateId);
      // if (
      //   this.templateId === 0 ||
      //   this.templateId === undefined ||
      //   this.templateId === null
      // ) 
      {
        console.log('employee key', keywords);
  
        if (keywords && keywords.length >= 2) {
          this.pmsservice
            .employeeTemplateSearch(organizationId, keywords)
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() => this.pmsservice.employeeTemplateSearch(organizationId, keywords)),
              catchError((err) => {
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.templateData= [];
                  this.templateNotFound = true;
                }
                console.error('Error fetching employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error fetching employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);
  
              if (result && result.statusCode === 204) {
                this.templateNotFound = true;
              }
              this.templateData.length = 0;
  
              if (result && Array.isArray(result)) {
                result.forEach((element) => {
                  // let template = {
                  //   template: {
                  //     templateId:element.kraTemplateHeaderId,
                  //     templateName: element.templateName
                    
                  //   },
                  // };
  
                  if (Object.keys(element).length > 0) {
                    this.templateNotFound = false;
                  } else {
                    this.templateNotFound = true;
                  }
                  this.templateData.push(element);
                  console.log(this.templateData);
                });
  
                if (this.selectedtemplateId?.length >= 1) {
                  this.tempnameSelected = true;
                }
              }
  
              if (result && result.errorCode === 204) {
                // alert('No Employee Found');
                this.templateNotFound = true;
                this.templateData.length = 0;
                this.templateData.push('No Employee Found');
                this.selectedtemplateId = null; // Set selectedemployeeId to null if no employee is found
              }
            });

           
        }
      } 
      // else {
      //   // Set selectedemployeeId to null if the input is empty
        this.selectedtemplateId = null;
      // }
    }
  }

  clearTable()
  {
    this.resultData=[];
    this.resultData.length=0; 
    this.empList = [];
    this.templateData = [];
    this.templateData=undefined;
    this.searchForm.reset();
    
    console.log('clear')
    this.searchForm.clearValidators;
    this.nodatafound = false;
    // this.getAssignkraList(this.orgId);
    this.employeeNotFound = false;
    this.clearButtonEnabled = false;
    // this.empPatch=undefined;
    // this.employeeCode=undefined;
    this.employeeCodes=undefined;
    this.employeeId=undefined;
    this.empPatch=undefined;
    this.userStartedTyping = false;
    this.templateId=undefined;
    this.templateNotFound=false;
    this.showTable=false;
    
   
   
  }


    sortData(sort: Sort)
    {
    
      //const data = this.resultData.slice();
      const data = this.resultData;
      console.log("Sort Data",data);
      if (!sort.active || sort.direction === '')
      {
        this.sortedData = data;
        return;
      }

      this.sortedData = data.sort((a, b) =>
      {
        const isAsc = sort.direction === 'asc';

        switch (sort.active)
        {
          case 'employeeNumber':
            return compare(a.employeeNumber, b.employeeNumber, isAsc);

            case 'employeeName':
              return compare(a.employee.firstName + ' ' + a.employee.lastName, b.employee.firstName + ' ' + b.employee.lastName, isAsc);

          case 'templateName':
            return compare(a.templateName, b.templateName, isAsc);

          // case 'kraTemplateName':
          //   return compare(a.kraTemplateName, b.kraTemplateName, isAsc);

          default:
            return 0;
        }
      });
    }
    
  get validate() 
  { 
    return this.searchForm.controls; 
  }

  onItemsPerPageChange() {
    this.p = 1; // Reset pagination to first page when items per page changes
  }

  
  // validateInput(event) {
  //   const input = event.target;
  //   let value = input.value;
  
  //   if (value.startsWith(' ')) {
  //     value = value.trimStart();
  //     input.value = value;
  //   }
  
    
  // }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }











empSearch(event, event1: KeyboardEvent): void {

  
  if (event1.key === ' ') {
    this.employeeNotFound = false;
    
    // Handle the space key press
    console.log('Space key pressed');
    // Optionally, prevent the default behavior
    event1.preventDefault();
  } else {
    if (this.empList?.length > 0) {
      this.nodatafound = false;
    }
    

    this.empList = [];
    console.log(event.target.value);
    let employeeNumber = event.target.value;
    employeeNumber = employeeNumber.replace(/-.*/, '');
    console.log(employeeNumber);

    // Cancel the previous API request
    if (this.unsubscribe$) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
    this.unsubscribe$ = new Subject<void>();

    if (
      this.employeeId === 0 ||
      this.employeeId === undefined ||
      this.employeeId === null
    ) {
      console.log('employee key', employeeNumber);

      if (employeeNumber && employeeNumber.length >= 2) {
        this.userservice
          .employeeSearch(this.orgId, employeeNumber)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            switchMap(() => this.userservice.employeeSearch(this.orgId, employeeNumber)),
            catchError((err) => {
              if (err.error.status === 500) {
                // this.projectId = [];
                this.empList = [];
                this.employeeNotFound = true;
              }
              console.error('Error fetching employees:', err);
              // Handle the error here, for example, showing an alert
              // alert('Error fetching employees. Please try again later.');
              return throwError(err); // Rethrow the error to propagate it downstream
            })
          )
          .subscribe((result: any) => {
            console.log('result', result);

            if (result && result.statusCode === 204) {
              this.employeeNotFound = true;
            }
            this.empList.length = 0;

            if (result && Array.isArray(result)) {
              result.forEach((element) => {
                let employee = {
                  employee: {
                    employeeId: element.employeeId,
                    firstName: element.firstName,
                    employeeNumber: element.employeeNumber,
                  },
                };

                if (Object.keys(employee).length > 0) {
                  this.employeeNotFound = false;
                } else {
                  this.employeeNotFound = true;
                }
                this.empList.push(employee);
                console.log(this.empList);
              });

              if (this.selectedemployeeId?.length >= 1) {
                this.employeeSelected = true;
              }
            }

            if (result && result.errorCode === 204) {
              // alert('No Employee Found');
              this.employeeNotFound = true;
              this.empList.length = 0;
              this.empList.push('No Employee Found');
              this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
            }
          });
      }
    } else {
      // Set selectedemployeeId to null if the input is empty
      this.selectedemployeeId = null;
    }
  }

  if (event.target.value  === "" || event.target.value.length  == null || event.target.value == undefined) {
    // this.nodatafound = false;
    console.log('empty');
    this.employeeNotFound = false;
  }
}

// empSearch(event, event1: KeyboardEvent): void {
//   if (event1.key === ' ') {
//     this.employeeNotFound = false;
//     // Handle the space key press
//     console.log('Space key pressed');

//     // Optionally, prevent the default behavior
//     event1.preventDefault();
//   } else {
 
//   console.log(event.target.value);
//   let employeenumber = event.target.value;
//   console.log(employeenumber);

//   // Cancel the previous API request
//   this.unsubscribe$.next();
//   this.unsubscribe$.complete();
//   this.unsubscribe$ = new Subject<void>();
//   // console.log('checking Project Id', this.projectId);

//   if(employeenumber.length==0){
//     this.employeeNotFound=false
//     console.log("enterd into the condition!!");
//     this.employeeId = undefined;
//     this.employee = undefined;
    
//   }

//   if (
//     this.employeeId === 0 ||
//     this.employeeId === undefined ||
//     this.employeeId === null
//   ) {
//     console.log('employee key', employeenumber);
//     if (employeenumber.length >= 2) {
//       this.userservice
//         .employeeSearch(this.orgId,employeenumber)
//         .pipe(
//           takeUntil(this.unsubscribe$), // Cancel previous request
//           switchMap(() => this.userservice.employeeSearch(this.orgId,employeenumber)),
//           catchError((err) => {
//             if (err.error.status === 500) {
//               // this.projectId = [];
//                     this.empList = [];
//               this.employeeNotFound = true;
//             }
//             console.error('Error fetching employees:', err);
//             // Handle the error here, for example, showing an alert
//             // alert('Error fetching employees. Please try again later.');
//             return throwError(err); // Rethrow the error to propagate it downstream
//           })
//         )
//         .subscribe((result: any) => {
//           console.log('result', result);

//           if (result && result.statusCode === 204) {
//             this.employeeNotFound = true;
//           }
//           // this.empList.length = 0;

//           result.forEach((element) => {
//             let employee = {
//               employee: {
//                 employeeId: element.employeeId,
//                 firstName: element.firstName,
//                 employeeNumber: element.employeeNumber,
//               },
//             };


//             if (Object.keys(employee).length > 0) {
//               this.employeeNotFound = false;
//             } else {
//               this.employeeNotFound = true;
//             }
//             // this.employeeProjects.push(employee);
//             // console.log('this.employeeProjects', this.employeeProjects);
//           });

//           // if (this.selectedemployeeId.length >= 1) {
//           //   this.employeeSelected = true;
//           // }

//           if (result.errorCode === 204) {
//             // alert('No Employee Found');
//             this.employeeNotFound = true;
//             // this.employeeProjects.length = 0;
//             // this.employeeProjects.push('No Employee Found');
//             this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
//           }
//         });
//     }
//   }

//   }
// }













handleBackspace(event): void {


  if (
    event.key === 'Backspace' &&
    !this.selectedemployee &&
    (this.selectedemployeeId === undefined || this.selectedemployeeId === null || this.selectedemployeeId?.trim().length === 0) 
    // (this.selectedtemplateId === undefined || this.selectedtemplateId === null || this.selectedtemplateId.trim().length === 0)
  ) {
    this.selectedemployeeId = undefined;
    this.employeeId = undefined;
    // this.empList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    // this.selectedtemplateId = undefined;
    this.empList = [];
   

   ;
   console.log('emp &&  TempName',this.employeeId, this.empPatch);
  }


  
  // if(
  //   event.key === 'Backspace' &&
  //   (this.selectedtemplateId === undefined || this.selectedtemplateId === null || this.selectedtemplateId.trim().length === 0)
  // ){
  //   this.templateData.length=0;
  //   this.selectedtemplateId === undefined;
  // }
}

handleBackspaceTemp(event): void {
  if (
    event.key === 'Backspace' &&
    !this.empPatch &&
    (this.templateId === undefined || this.templateId === null || this.selectedtemplateId?.trim().length === 0) 
    // (this.selectedtemplateId === undefined || this.selectedtemplateId === null || this.selectedtemplateId.trim().length === 0)
  ) {
    this.templateId = undefined;
    // this.employeeId = undefined;
    // this.empList.length = 0;
    // this.employee = undefined;
    // this.employeeNumber = undefined;
    // this.selectedemployee = undefined;
    this.templateId = undefined;
    this.selectedtemplateId = undefined;
    this.empPatch = undefined;
    this.templateData=[];
   

    this.searchForm.patchValue({
      templateName: undefined
   });
  }

}





handleBackspace2(event): void {
 this.empPatch= undefined;

  // if (
  //   event.key === 'Backspace' &&
  //   !this.selectedemployee &&
  //   (this.selectedemployeeId === undefined || this.selectedemployeeId === null || this.selectedemployeeId.trim().length === 0) 
  //   // (this.selectedtemplateId === undefined || this.selectedtemplateId === null || this.selectedtemplateId.trim().length === 0)
  // ) {
  //   this.selectedemployeeId = undefined;
  //   this.employeeId = undefined;
  //   // this.empList.length = 0;
  //   this.employee = undefined;
  //   this.employeeNumber = undefined;
  //   this.selectedemployee = undefined;
  //   this.selectedemployeeId = undefined;

  //  ;
   console.log('emp &&  TempName',this.employeeId, this.empPatch);
  // }


  

}



// preventspaceafterlistCODE//

preventSpaceBeforeInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
      event.preventDefault();
    }
  }


// handleBackspace(event): void {
   
//   const inputValue = event.target.value;

//   if (event.key === 'Backspace' && inputValue.length <= 0) {
//       this.selectedemployeeId = undefined;
//       this.employeeId = undefined;
//       // this.employeeProjects.length = 0;
//         this.postForm.controls['employeeCode'].value = undefined;

//       this.employeeNumber = undefined;
//       this.selectedemployee = undefined;
//       this.employeeNotFound = false; 
//   }
// }

// get postForm(){
//   return this.postForm.value
// }





  
}





function compare(a: string | number, b: string | number, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
