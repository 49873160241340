<div class="resource-background">
    <div class="row">
        <div class="col-md-11 resource-title"  >
            <h2>Shift Details</h2>
        </div>
        <div class="col-md-1"  >
                <mat-icon style="color: red !important;" class="material-icons-outlined" (click)="closedialog()">close</mat-icon>          
        </div>
    </div>  
    <div class="card resource-card">
       
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Shift Name : </label>
                    <label class="form-control-label-class"> &nbsp; {{resultData.shiftName}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Shift Status: </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.status.listTypeValueName}}</label>
                </div>                                                  
            </div>
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Client : </label>
                    <label class="form-control-label-class"> &nbsp; {{resultData.client.clientName}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Client Code : </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.client.clientCode}}</label>
                </div>                                                  
            </div>
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Start Time : </label>
                    <label class="form-control-label-class"> &nbsp; {{resultData.startTime}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">End Time : </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.endTime}}</label>
                </div>                                                  
            </div>  
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Shift Description: </label>
                    <label class="form-control-label-class"> &nbsp; {{resultData.shiftDescription}}</label>
                </div> 
                                                             
            </div>
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
