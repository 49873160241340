import { Component, OnInit } from '@angular/core';
import { PayrollService } from '../providers/payroll.service';
import { SettingsService } from '../providers/settings.service';
import { FormBuilder } from '@angular/forms';
import { GlobalserviceService } from '../providers/globalservice.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-exist-loan-details',
  templateUrl: './exist-loan-details.component.html',
  styleUrls: ['./exist-loan-details.component.scss']
})
export class ExistLoanDetailsComponent implements OnInit {
  p: number = 1;
  pagePerItem:any=5;
  organzationId: any;
  loanList: any =[];
  noRecordsFound: boolean =false;
  userdata: any;
  organizationSettings: any;
  interestPercentage:any;
  tableData: any[] = [];
  installmentCount: number = 0;
  totalPayment: any;
  InterestPayable: any;
  emi:any;
  expand: boolean=false;
  approvers:any =[];
  fyi:any =[];
  constructor(
    public payrollService:PayrollService,
    public fb:FormBuilder,
    private settingsService: SettingsService,
    private gs:GlobalserviceService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organzationId =  this.userdata.organization.organizationId;
    console.log("organziationid " , this.organzationId);
    this.noRecordsFound = true;
    this.gs.getOrganizationdetails(this.organzationId).subscribe(
      (data:any)=>{
        this.organizationSettings=data;
        console.log("this.organizationSettings",this.organizationSettings);
        this.interestPercentage=this.organizationSettings.maxLoanInterest;
      }
    )
    this.gs.getLoanlistByemployee(this.userdata.employee.employeeId).subscribe(
      (data: any) => {
        this.loanList = data;
        console.log(this.loanList);
        this.noRecordsFound = false;
        if(this.loanList.length == 0){
          this.noRecordsFound = true;
          console.log("nodata");
        }
        else if(data.statusCode) {
          this.noRecordsFound = true;
          console.log("nodata");
        }
        else{
          this.loanList.forEach(loandata => {
            loandata['expandEMI']=false;
            this.approvers =[];
            if(loandata.approval != undefined){
              if(loandata.approval.approver.approverOne) {
                  this.approvers.push(loandata.approval.approver.approverOneObject.firstName +' '+ loandata.approval.approver.approverOneObject.lastName);
              }
              if(loandata.approval.approver.approverTwo) {
                  this.approvers.push(loandata.approval.approver.approverTwoObject.firstName +' '+ loandata.approval.approver.approverTwoObject.lastName);
              }
              if(loandata.approval.approver.approverThree) {
                  this.approvers.push(loandata.approval.approver.approverThreeObject.firstName +' '+ loandata.approval.approver.approverThreeObject.lastName);
              }
              if(loandata.approval.approver.approverFour) {
                  this.approvers.push(loandata.approval.approver.approverFourObject.firstName +' '+ loandata.approval.approver.approverFourObject.lastName);
              }
              if(loandata.approval.approver.approverFive) {
                  this.approvers.push(loandata.approval.approver.approverFiveObject.firstName +' '+ loandata.approval.approver.approverFiveObject.lastName);
              }
              if(loandata.approval.approver.fyi){
                this.fyi.push(loandata.approval.approver.fyiObject.firstName +' '+ loandata.approval.approver.fyiObject.lastName);
              }
              console.log(this.approvers);
              console.log(this.fyi);
            }
          });
  
        }
      },
      (error) => {
        this.noRecordsFound = true;
        console.log("error1")
        if(error.status == 404){
          this.noRecordsFound = true;
          console.log("404")
        }
      }
    );   
  }
  
  // generateTable(employeeLoanInstallments,index,loanRequestedAmount,expandEMI,employeeLoanId)
  generateTable(loandata,index){
    this.installmentCount = loandata.employeeLoanInstallments.length;
    console.log("index",index);
    console.log("employeeLoanInstallments",this.installmentCount);
    console.log("expandEMI",loandata.expandEMI);
    console.log("employeeLoanId",loandata.employeeLoanId);
    console.log("this.loanList",this.loanList);
    console.log("loandata",loandata);
    loandata.expandEMI == true;

    this.expand=!this.expand;
    loandata.employeeLoanInstallments.forEach(element => {
      this.tableData = [];
      if(this.installmentCount > 0) {
        this.totalPayment= Math.round(element.loanInstallmentAmount * this.installmentCount).toFixed(2);
        console.log(this.totalPayment);
        for(let i = 1; i <= loandata.employeeLoanInstallments.length; i++) {
          let installmentsObject={
            installmentNumber: i, 
            amount: element.loanInstallmentAmount
          }
          this.tableData.push(installmentsObject);
        }
        console.log(this.tableData);
        this.InterestPayable= Math.round(this.totalPayment- loandata.loanRequestedAmount).toFixed(2);
        console.log(" this.InterestPayable", this.InterestPayable);
      }
    });
  }
  
  formcancel() {
    this.dialog.closeAll();
  }

}
