<!-- <div class=""> -->
<!-- <div class="row">
        <div class="col-md-6 resource-title">
            <h2>EXPENSE REIMBURSEMENT APPROVALS</h2>
        </div>
        <div class="col-md-3 form-group search-input topnav-right">                                                                     
            <input matInput class="search form-control" type="text" placeholder="Search.." [(ngModel)]="query" (keyup)="onKeyUpEvent($event)" name="search">
            <mat-icon aria-hidden="false" aria-label="Example search icon" class="material-icons-round search-input-icon">search</mat-icon>                                                                                                      
        </div>
    </div> -->
<nav class="navbar bg" style="margin-top: 68px" id="editForm">
  <div class="container-fluid">
    <a
      class="navbar-brand brandNav"
      style="
        font-size: 22px;
        font-weight: 800;
        font-family: 'Mulish', sans-serif;
      "
      >Manage Approvals</a
    >
    <form class="d-flex">
      <input
        aria-label="Search"
        class="search-menu searchDesign me-2 no-border-on-focus"
        placeholder="Search..."
        [(ngModel)]="search"
        (keyup)="changepage()"
        name="searchText"
      />
    </form>
  </div>
</nav>
<!-- <div class="text-center" *ngIf="success" style="
       position: absolute;
        z-index: 99;
        float: right;
        right: 14px;
        top: 10%;"> 
        <div class="alert alert-success" role="alert">
            <strong>Success!</strong><br>Approver Status Updated Successfully
        </div>
    </div> -->
<div class="text-center" *ngIf="success" id="editForm">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div
  class="text-center"
  *ngIf="error"
  style="position: absolute; z-index: 99; float: right; right: 14px; top: 10%"
>
  <div class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br />Unable to Update Approver Status
  </div>
</div>
<div
  *ngIf="list_loader"
  class="align-items-center text-center"
  style="position: absolute; z-index: 99; left: 50%; top: 50%"
>
  <div
    class="spinner-border"
    style="
      width: 3rem;
      height: 3rem;
      color: #1a83ff;
      position: relative;
      /* bottom: 0; */
      align-items: center;
      /* margin-left: 90%; */
      /* top: 95px; */
      z-index: 1;
    "
    role="status"
  ></div>
</div>

<div class="card resource-card" *ngIf="tableShow">
  <div class="row" *ngIf="approvallist?.length > 0">
    <div
      class="col-xl-12 col-lg-6"
      *ngIf="
        approvallist?.length > 5 && (approvallist | mytask : search).length > 0
      "
    >
      <div class="select-pagination" *ngIf="!nodata">
        <span>Show : </span>
        <select
          class="select_list new-select_list"
          [(ngModel)]="mySelect"
          (change)="changefirstpage()"
        >
          <option class="form-control" value="5">5</option>
          <option class="form-control" value="10">10</option>
          <option class="form-control" value="15">15</option>
          <option class="form-control" value="20">20</option>
          <option class="form-control" value="25">25</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="padding: 16px">
      <div class="resource-table table-responsive">
        <div >
          <table
            class="interviewed-status-table"
            *ngIf="sethidetable"
            matSort
            (matSortChange)="sortData($event)"
          >
            <thead style="background-color: #f6f9fc">
              <tr>
                <th
                  mat-sort-header="employeeName"
                  class="table-head"
                  style="border-radius: 15px 0px 0px 0px"
                >
                  Employee&nbsp;Name
                </th>
                <th mat-sort-header="employeeId" class="table-head text-center">
                  Employee Code
                </th>
                <th mat-sort-header="organizationEmail" class="table-head">
                  Email
                </th>
                <th mat-sort-header="subTotal" class="table-head text-center">
                  Subtotal
                </th>
                <th
                  mat-sort-header="cashAdvance"
                  class="table-head text-center"
                >
                  Cash&nbsp;Advance
                </th>
                <th
                  mat-sort-header="totalReimbursement"
                  class="table-head text-center"
                >
                  Total Reimbursement
                </th>
                <th
                  mat-sort-header="approvalstatus"
                  class="table-head text-center"
                >
                  Approval&nbsp;Status
                </th>
                <th
                  class="table-head text-center"
                  style="border-radius: 0px 15px 0px 0"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody *ngIf="approvallist?.length > 0">
              <tr
                *ngFor="
                  let e of approvallist
                    | mytask : search
                    | paginate : { itemsPerPage: mySelect, currentPage: p }
                "
              >
                <td class="table-data">{{ e?.employee?.firstName }}</td>
                <td class="table-data text-center">
                  {{ e?.employee?.employeeId }}
                </td>
                <td class="table-data">{{ e?.employee?.organizationEmail }}</td>
                <td class="table-data text-center">{{ e?.subTotal }}</td>
                <td class="table-data text-center">{{ e?.cashAdvance }}</td>
                <td class="table-data text-center">
                  {{ e?.totalReimbursement }}
                </td>
                <td class="table-data text-center">
                  <div *ngIf="e.approvals != undefined">
                    <span
                      class="text-success"
                      *ngIf="
                        e?.approvals?.approvalStatus?.listTypeValueName ==
                        'Approved'
                      "
                    >
                      {{ e?.approvals?.approvalStatus?.listTypeValueName }}
                    </span>
                    <span
                      class="text-warning"
                      *ngIf="
                        e?.approvals?.approvalStatus?.listTypeValueName ==
                        'Pending'
                      "
                    >
                      {{ e?.approvals?.approvalStatus?.listTypeValueName }}
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        e?.approvals?.approvalStatus?.listTypeValueName ==
                        'Denied'
                      "
                    >
                      {{ e?.approvals?.approvalStatus?.listTypeValueName }}
                    </span>
                    <span *ngIf="e?.approvals === undefined">-- </span>
                  </div>
                </td>
                <td
                  class="table-data text-center"
                  *ngIf="e.fyistatus == undefined"
                >
                  <button
                    class="menu-button"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon class="menu-icon">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="openDialog(e.expenseReimbursementId)"
                    >
                      <mat-icon class="material-symbols-outlined viewicon"
                        >visibility</mat-icon
                      >
                      <span mat-button class="text">View</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="approve(e)"
                      *ngIf="e?.approvals?.approver?.fyi != empId"
                      [disabled]="!approveDisabled"
                    >
                      <mat-icon class="viewicon" style="color: #50cd89"
                        >verified</mat-icon
                      >
                      <span class="text text-success">Approve</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="deny(e)"
                      *ngIf="e?.approvals?.approver?.fyi != empId"
                      [disabled]="!approveDisabled"
                    >
                      <mat-icon
                        class="material-symbols-outlined viewicon"
                        style="color: red"
                        >block</mat-icon
                      >
                      <span class="text text-danger">Deny</span>
                    </button>
                  </mat-menu>
                </td>
                <td class="table-data text-center" *ngIf="e.fyistatus == true">
                  <button
                    class="menu-button"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon class="menu-icon">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="openDialog(e.expenseReimbursementId)"
                    >
                      <mat-icon class="material-symbols-outlined view-icon"
                        >visibility</mat-icon
                      >
                      <span mat-button class="text">View</span>
                    </button>
                    <!-- <button mat-menu-item (click)="viewapproverstatus(e)" [disabled]="e.fyistatus == true">
                                                <mat-icon class="view-icon">visibility</mat-icon>
                                                <span mat-button class="text">View Approvers</span>
                                            </button> -->
                  </mat-menu>
                </td>
              </tr>
              <!-- <tr *ngIf="(approvallist | mytask : search).length === 0">
                                    <td colspan="/* number of columns */" class="searchnodataDesign" style="padding: 11px 12px 14px 12px;
                                    font-size: 19px;
                                    font-weight: 600;
                                    font-family: 'Mulish', sans-serif;
                                    left: 408px;
                                    position: relative;"> Search Manage Approvals Not found</td>
                                  </tr> -->
              <tr
                *ngIf="(approvallist | mytask : search).length === 0 && search"
                style="border: 1px solid #ddd"
              >
                <td
                  style="
                    text-align: center;
                    padding: 12px;
                    font-size: 19px;
                    font-weight: 600;
                    font-family: 'Mulish', sans-serif;
                    position: relative;
                  "
                  colspan="9"
                >
                  Search Manage Approvals Records Not Found!
                </td>
              </tr>
            </tbody>
            <tbody style="border: 1px solid #ddd; " *ngIf=" tableShow && (nodata==true ||
            approvallist.length == 0)">
      <tr>
        <td
          style="
           text-align: center;
  padding: 12px;
  font-size: 19px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
  position: relative;
          "
          colspan="9"
         
        >
        Manage  Approvals Records Not Found!
        </td>
      </tr>
    </tbody>
          </table>
        </div>
        <!-- <div *ngIf="nodata">
                        <div class="no-data card text-center">
                            <div class="card-body">Manage Approvals Not Found</div> 
                        </div>
                    </div>   -->
      </div>
    </div>
  </div>
  <!-- <div class="row topnav-right bgIpagination-content">         -->
  <!-- <div class="col-md-12" *ngIf="approvallist">
                <pagination-controls class="float-right pagination" style="float: right;"
                *ngIf="approvallist?.length > 5 &&  (approvallist | mytask : search).length > 0"
                 (pageChange)="p = $event"></pagination-controls>  
            </div>  -->
  <!-- <div class="col-md-12" *ngIf="approvallist">
                <pagination-controls class="float-right pagination" style="float: right;"
                                    *ngIf="(approvallist | mytask : search).length > 5"
                                    (pageChange)="p = $event"></pagination-controls>
            </div>  -->
  <div
    class="pagination-content"
    *ngIf="
      approvallist.length > 5 && (approvallist | mytask : search).length > 0
    "
    id="page"
  >
    <pagination-controls
      (pageChange)="p = $event"
      style="float: right"
    ></pagination-controls>
  </div>
  <!-- </div>  -->
</div>
<!-- </div> -->

<!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        <h4 align="center" class="h4" style="    margin-top: 14px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 11px;
        ">Manage Approvals Not Found </h4>
      </div>
    </div>
  </mat-card> -->
