<!-- Sub header start here-->
<section>
    <div class="sub-header">
      <div class="col-md-12 mb-3">
        <div class="row">
          <div class="">
            <span  class="h5 sub-content">
              {{ businessunitId ? "Edit" : "Add" }} Bussiness Unit</span
            >
          </div>
          <div class="sub-header-buttons">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Sub header end here-->
  
  <div class="common-content">
    <div class="card-new">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="businessunitgroup" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="form-group col-md-3" style="height: 70px !important">
                <label class="form-control-label"
                  >Legal Entity Name <span class="star">*</span></label
                >
                <mat-select
                  formControlName="legalEntityName"
                  placeholder="Select Legal Entity Name"
                  class="form-control email-input select-placeholder"
                >
                  <mat-option [value]="undefined" selected="selected"
                    >-Select Legal Entity Name-</mat-option
                  >
                  <mat-option *ngFor="let row of legalentityList; let i=index;" [value]="row.legalEntityId">{{row.legalEntityName}} 
                  </mat-option>
                  <!-- <mat-option value="Service Based">Service Based</mat-option> -->
                </mat-select>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.legalEntityName?.invalid &&
                      validate.legalEntityName?.touched) ||
                    validate.legalEntityName?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.legalEntityName.errors?.required"
                    class="text-danger"
                    >Please Select Legal Entity Name</span
                  >
                  <span
                    *ngIf="validate.legalEntityName.errors?.pattern"
                    class="text-danger"
                    >Please Select Legal Entity Name</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Business Unit Name<span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="businessUnitName"
                  class="form-control email-input select-placeholder"
                  autocomplete="off"
                  placeholder="Enter Business Unit Name"
                />
  
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.businessUnitName?.invalid &&
                      validate.businessUnitName?.touched) ||
                    validate.businessUnitName?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.businessUnitName.errors?.required"
                    class="text-danger"
                    >Please Enter Business Unit Name</span
                  >
                </div>
              </div>
  
              <div class="form-group col-md-3" id="space3">
                <label class="form-control-label"
                  >Business Start Date<span class="text-danger"> *</span>
                </label>
                <input
                  placeholder="Business Start Date"
                  matInput
                  [matDatepicker]="picker1"
                  class="form-control email-input select-placeholder"
                  style="height: 38px"
                  formControlName="startDate"
                  type="text"
                  [min]="today"
                  readonly
                  
                  id="startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                  style="float: right; position: relative; bottom: 42px"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.startDate?.invalid &&
                      validate.startDate?.touched) ||
                    validate.startDate?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.startDate.errors?.required"
                    class="text-danger"
                    >Please Select Start Date
                  </span>
                </div>
              </div>
              <!-- <div class="form-group col-md-3" id="space4">
                <label class="form-control-label"
                  >End Date
                  <span class="text-danger"> *</span>
                </label>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  readonly
                  class="form-control email-input select-placeholder"
                  type="text"
                  formControlName="endDate"
                  [max]="today"
                  (dateChange)="
                    dateChange($event); checkStartDate(); updateEndDateMin($event)
                  "
                  placeholder="End Date"
                  id="date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                  style="float: right; position: relative; bottom: 42px"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.endDate?.invalid && validate.endDate?.touched) ||
                    validate.endDate?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.endDate.errors?.required"
                    class="text-danger"
                    >Please Select End Date
                  </span>
                </div>
              </div> -->
  
              <div class="form-group col-md-3" style="height: 70px !important">
                <label class="form-control-label"
                  >Location <span class="star">*</span>
                </label>
                <input
                  type="text"
                  formControlName="location"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  class="form-control email-input select-placeholder only_name"
                  placeholder="Enter Location"
                  autocomplete="off"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.location?.invalid && validate.location?.touched) ||
                    validate.location?.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.location.errors?.required"
                    class="text-danger"
                    >Please Enter Location
                  </span>
                  <span
                    *ngIf="validate.location.errors?.pattern"
                    class="text-danger"
                    >Please Enter Valid Operating Address</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3" style="    margin-top: -25px;">
                <label class="form-control-label"
                  >Description</label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  formControlName="description"
                  class="form-control email-input select-placeholder"
                  autocomplete="off"
                  placeholder="Enter Description"
                />
  
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.description?.invalid &&
                      validate.description?.touched) ||
                    validate.description?.dirty 
                    
                  "
                >
                  <span
                    *ngIf="validate.description.errors?.pattern"
                    class="text-danger"
                    >Enter Valid Description</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <a
                  routerLinkActive="active"
                  mat-raised-button
                  style="
                    margin-left: 10px;
                    background: none;
                    color: var(--Red-Color, #ff5d5d);
                    border: 1px solid #ddd !important;
                    height: 36px;
                    position: relative;
                    top: -2px;
                    font-weight: 550;
                    padding-top: 10px;
                  "
                  class="btn search-btn mr-2"
                  [disabled]="isbtnDisable"
                  (click)="clearAll()"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  mat-raised-button
                  class="n m-1 searchBtn"
                  [disabled]="isbtnDisable"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="loader" class="align-items-cEnter text-cEnter">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top:45%"
      role="status"
    ></div>
  </div>
  
  <div class="text-cEnter" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>
  