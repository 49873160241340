import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import *  as htmlToPdfmake  from "html-to-pdfmake";
import { Router,ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgxPrinterService} from 'ngx-printer'
import pdfMake from 'pdfmake/build/pdfmake';;
import htmlToPdfmake from 'html-to-pdfmake';


import { ProjectManagementService } from 'src/app/providers/project-management.service';
@Component({
  selector: 'app-expense-details-form',
  templateUrl: './expense-details-form.component.html',
  styleUrls: ['./expense-details-form.component.scss']
})
export class ExpenseDetailsFormComponent implements OnInit {

  expensedata:any={};
  expenses:any[];
  advancebalance
  approvers:any =[];
  fyi:any =[];
  empId:any;
  empNumber:any;
  loader:boolean = true;
@ViewChild('pdfTable')
pdfTable!: ElementRef;

  imageprint: boolean=false;
saveBool: boolean=false;

constructor( @Inject(MAT_DIALOG_DATA) public data: MatDialog,
public dialog: MatDialog,
public expenseservice: ProjectManagementService,
private ActivatedRoute: ActivatedRoute, 
private router:Router,
private datepipe  : DatePipe,
private printerService: NgxPrinterService

) { }

ngOnInit(): void {
  let localdata = JSON.parse(localStorage.getItem('enoteUserData'));
  console.log(localdata);
  this.empId = localdata.employee.employeeId;
  console.log(this.empId)

  console.log("data1"+this.data);
  console.log("data2"+this.data);
  this.saveBool=false
  this.expenseservice
  .getexpensereburimentbyId(this.data) 
  .subscribe((expensedata:any ) => {
    this.expensedata =expensedata
    this.saveBool=true
    console.log("this.expensedata",this.expensedata);
     this.loader = false;
    console.log("approvalStatus",this.expensedata.approvalStatus);
    this.expensedata?.expenses?.sort((a,b) => a.date.localeCompare(b.date));
    this.expensedata.fromDate=this.datepipe.transform(this.expensedata.fromDate, 'dd-MM-yyyy');
    this.expensedata.toDate=this.datepipe.transform(this.expensedata.toDate, 'dd-MM-yyyy');
    this.expensedata.createDateTime=this.datepipe.transform(this.expensedata.createDateTime, 'dd-MM-yyyy');
    this.expensedata.submittedOn=this.datepipe.transform(this.expensedata.submittedOn, 'dd-MM-yyyy');
    this.expensedata.approvedOn=this.datepipe.transform(this.expensedata.approvedOn, 'dd-MM-yyyy');
    this.expensedata?.expenses?.map(
      e=>{
        e.date = this.datepipe.transform(e.date, 'dd-MM-yyyy');
      })
    if(expensedata.approvals != undefined){
      if(expensedata.approvals.approver.approverOne) {
          this.approvers.push(expensedata.approvals.approver.approverOneObject.firstName);
      }
      if(expensedata.approvals.approver.approverTwo) {
          this.approvers.push(expensedata.approvals.approver.approverTwoObject.firstName);
      }
      if(expensedata.approvals.approver.approverThree) {
          this.approvers.push(expensedata.approvals.approver.approverThreeObject.firstName);
      }
      if(expensedata.approvals.approver.approverFour) {
          this.approvers.push(expensedata.approvals.approver.approverFourObject.firstName);
      }
      if(expensedata.approvals.approver.approverFive) {
          this.approvers.push(expensedata.approvals.approver.approverFiveObject.firstName);
      }
      if(expensedata.approvals.approver.fyi){
        this.fyi.push(expensedata.approvals.approver.fyiObject.firstName);
      }
      console.log(this.approvers);
      console.log(this.fyi);
    }
  })
}

public downloadAsPDF() {
  console.log("download");
  const pdfTable = this.pdfTable.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML, {
    tableAutoSize:true,
  });
  
  const documentDefinition = {
     content: html , 
     pageMargins: [ 40, 40, 40, 150],
      imagesByReference:true ,
     info: {
       title:`Expense Reimbursement ${this.expensedata.fromDate} - ${this.expensedata.toDate}`
       },

       footer: function (currentPage, pageCount) {
        return {
          table: {
            body: [
              [ 
                { 
                  text: 'Employee Signature', alignment: 'left', fontSize: 10,
                  margin: [40, 40, 0, 0]
                  // pageMargins: [50, 0, 0, 0]
                },
                { 
                  text: 'Date', alignment: 'right', fontSize: 10,
                  margin: [350, 40, 0, 0]
                  // pageMargins: [0, 0, 100, 0]
                },
              ],
              [
                // 'Approver Signature',
                // { 
                //   text: 'Date', alignment: 'right', fontSize: 9,
                //   pageMargins: [700, 0, 300, 0]
                // },

                { 
                  text: 'Approver Signature', alignment: 'left', fontSize: 10,
                  margin: [40, 40, 0, 0]
                  // pageMargins: [50, 0, 0, 0]
                },
                { 
                  text: 'Date', alignment: 'right', fontSize: 10,
                  margin: [350, 40, 0, 0]
                  // pageMargins: [500, 0, 100, 0]
                },
              ],
            ]
          },
          layout: {
            defaultBorder: false,
          },
          // layout: 'noBorders'
        };
      },
      pageSize: 'A4',
      };
   console.log(documentDefinition);
  pdfMake.createPdf(documentDefinition).download(`Expense Reimbursement ${this.expensedata.fromDate} - ${this.expensedata.toDate}.pdf`); 
   
}
printpdf() {
  const pdfTable = this.pdfTable.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });

  const documentDefinition = {
    content: html,
    pageMargins: [40, 40, 40, 150],
    info: {
      title: `Expense Reimbursement ${this.expensedata.fromDate} - ${this.expensedata.toDate}` // Add a comma here
    },
    footer: function (currentPage, pageCount) {
      return {
        table: {
          body: [
            [
              {
                text: 'Employee Signature', alignment: 'left', fontSize: 10,
                margin: [40, 40, 0, 0]
              },
              {
                text: 'Date', alignment: 'right', fontSize: 10,
                margin: [350, 40, 0, 0]
              },
            ],
            [
              {
                text: 'Approver Signature', alignment: 'left', fontSize: 10,
                margin: [40, 40, 0, 0]
              },
              {
                text: 'Date', alignment: 'right', fontSize: 10,
                margin: [350, 40, 0, 0]
              },
            ],
          ]
        },
        layout: {
          defaultBorder: false
        }
      };
    },
    pageSize: 'A4'
  };

  pdfMake.createPdf(documentDefinition).print();
}

// printImageCurrent(url: string) {
//   console.log("print image calling here!!!", url);
  
//   // If you are trying to print a PDF from a URL, you can directly open the URL in a new tab.
//   window.open(url, '_blank');

//   // If you want to use pdfMake, you need to load the PDF data into pdfMake, not just the URL.
//   // The following code assumes you are generating a PDF from HTML content.
  
//   const pdfTable = this.pdfTable.nativeElement;
//   var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
  
//   const documentDefinition = {
//     content: html,
//     pageMargins: [40, 40, 40, 60],
//     info: {
//       title: `Expense Reimbursement`
//     }
//   };

//   // Correct way to use print without passing a URL
//   pdfMake.createPdf(documentDefinition).print();
// }

printImageCurrent(url: string) {
  console.log("print image calling here!!!", url);

  // Create a new window or an iframe
  const printWindow = window.open('', '_blank');

  // Add the image HTML into the new window
  printWindow?.document.write(`
    <html>
      <head>
        <title>Print Image</title>
        <style>
          body {
            text-align: center;
            margin: 0;
          }
          img {
            max-width: 100%;
            height: auto;
          }
        </style>
      </head>
      <body>
        <img src="${url}" alt="Image to Print"/>
      </body>
    </html>
  `);

  // Wait until the image is fully loaded before triggering print
  printWindow?.document.close(); // Close document to finish writing
  printWindow?.focus(); // Focus on the new window

  printWindow?.addEventListener('load', () => {
    printWindow?.print(); // Trigger the print dialog
    printWindow?.close(); // Optional: Close the print window after printing
  });
}



// printImg(url: string): void {
//   const printWindow = window.open('', '_blank');
//   if (printWindow) {
//       printWindow.document.write(`
//           <html>
//           <head><title>Print Image</title></head>
//           <body>
//               <img src="${url}" onload="window.print(); window.close();" />
//           </body>
//           </html>
//       `);
//       printWindow.document.close();
//   }
// }

formcancel()
{
  this.dialog.closeAll();
}
}
