<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">{{ this.emplevelId  ? "Edit" : "Add" }} Employee Level</span>
          <!-- <span class="h5 sub-content">Assign Employee Level</span> -->
          
        </div>
      </div>
    </div>
  </div>
</section>




<div class="common-content">
    <div class="card-new">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="employeeform" (ngSubmit)="onSubmit()" >
            <div class="row">
                <div class="form-group col-md-4">
                    <label class="form-control-label">Assign Employee Level<span class="star">*</span></label>
                    <mat-select 
                      formControlName="assignEmployee" 
                      placeholder="Assign Employee Level" 
                      style="border-radius: 5px; outline:none; font-family: Mulish;" 
                      (selectionChange)="onCityChange($event)" 

                      class="form-control select-placeholder email-input">
                      
                      <mat-option [value]="null" selected="selected"> -Assign Employee Level - </mat-option>
                      
                      <mat-option [value]="1">Business Group</mat-option>
                      <mat-option [value]="2">Business Unit</mat-option>
                      <mat-option [value]="3">Organization</mat-option>
                    </mat-select>
                    
                    <!-- <div class="valid-msg" *ngIf="(validate.PayrollRun.invalid && validate.PayrollRun.touched) || validate.PayrollRun.dirty || submitted">
                      <span *ngIf="validate.PayrollRun.errors?.required" class="text-danger">Please select a Payroll Run Level</span>
                    </div> -->
                  </div>
                  

                
             
              
              <div class="form-group col-md-4">
                <label class="form-control-label"
                  >Employee Code<span class="star"> *</span></label
                >
                <!-- <input formControlName="employeecode" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search..."> -->
                <input
                  type="text"
                  placeholder="Search Employee Code"
                  formControlName="employeecode"
                  [matAutocomplete]="auto"
                  class="form-control email-input select-placeholder"
                  (keydown)="onKeyDown($event)"
                (keyup)="empSearch($event)"
                
                
                />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option value="All" (click)="empAllSelect()"
                    >All</mat-option
                  >
                  <mat-option
                    *ngFor="let emp of empList"
                    [value]="emp.employeeNumber"
                    (click)="empSelect(emp)"
                  >
                    {{ emp.employeeNumber }} - {{ emp.firstName }}
                  </mat-option>
                  <mat-option *ngIf="empCode">Employee Code Not Found</mat-option>
                </mat-autocomplete>
                <mat-icon
                  class="material-symbols-outlined"
                  style="position: absolute; top: 40px; right: 20px"
                >
                  search
                </mat-icon>
                <!-- <div
                  class="valid-msg"
                  *ngIf="
                    (validate.employeecode.invalid &&
                      validate.employeecode.touched) ||
                    validate.employeecode.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.employeecode.errors?.required"
                    class="text-danger"
                    >Please Select Employee Code</span
                  >
                </div> -->
              </div>
            </div>
            <div
              class="row pb-3"
              
            >
              <!-- <div class="col-md-11 text-right" *ngIf="getFormControls.length >= 5">
                <p class="show-count">Show</p>
              </div>
              <div class="col-md-1 text-left pl-0" >
                <select
                  [(ngModel)]="pagePerItem"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="pageChages()"
                  class="form-control report-filter"
                >
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div> -->
              <div class="text-right" *ngIf="getFormControls.length >5">
                <div class="select-pagination m-2" >
                  <span>Show : </span>
                  <select
                    class="select_list new-select_list listDesign"
                    [(ngModel)]="pagePerItem"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="pageChanges()"
                   
                    
                
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div>
 
            <div  class="table-responsive" *ngIf="showtable">
                <table
                  
                  class="table"
                  *ngIf="selectedEmployeeData.length !=0 && !loader"
                 
                  
                >
                  <thead class="t-head">
                    <tr  id="trDesign">
                      <th class="col-md-2" style="text-align: center;border-radius: 15px 1px 0px 0">Action</th>
                      <th class="col-md-3" style="text-align: center">Employee Code</th>
                      <th class="col-md-3" style="text-align: center">Employee  Name</th>
                      <th class="col-md-4" style="text-align: center;border-radius: 0px 15px 0px 0">Level Name</th>
                    </tr>
                  </thead>
                  <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd"  >
                    <ng-container
                      formArrayName="LevelName"
                      *ngFor="
                        let group of getFormControls.controls
                          | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                          | searchFilter : tableSearch;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="p == 1; else nextpage">
                        <tr formGroupName="{{ i }}">
                          <td style="color: red">
                            <!-- <button mat-menu-item type="button" (click)="deleteemployee(i)"> -->
                            <mat-icon (click)="deleteemployee(i)">delete</mat-icon>
                            <!-- </button> -->
                          </td>
                          <td>{{ group.value.employeeNumber }}</td>
                          <td>
                            {{ group.value.firstName }} {{ group.value.lastName }}
                          </td>


                          <td>
                            <div class="select-box" >
                                
                                <mat-select formControlName="LevelName"   placeholder="Level Name"   class="form control
                                select-placeholder
                                email-input" style="    font-size: 16px;
                                font-family: 'Mulish', sans-serif;
                                font-weight: 550;">
                                    <mat-option [value]="null" selected="selected"> --- Level Name --- </mat-option>
                                    <mat-option  *ngFor="let row of BusinessUnit; let i=index;" [value]="row.businessGroupId" (click)="passedId(row)" >{{row.businessGroupName}}</mat-option>
    
                                    <mat-option  *ngFor="let row of BusinessName; let i=index;" [value]="row.businessUnitId"(click)="passedId(row)" >{{row.businessUnitName}}</mat-option>
                                    <mat-option *ngFor="let row of OrganizationName; let i=index;"[value]="row.organizationId"(click)="passedId(row)" >{{row.organizationName}}</mat-option>
    
                                </mat-select>
                               
                                
                            </div>
                 
                  <mat-error *ngIf="submitted || (getFormControls.controls[i].get('LevelName')?.touched && getFormControls.controls[i].get('LevelName')?.invalid)">
                    <span style="margin-right: 15%;" *ngIf="getFormControls.controls[i].get('LevelName')?.hasError('required')">
                      Please Enter Level Name
                    </span>
                  </mat-error>
                  
                          </td>
                          
                        </tr>
                      </ng-container>

                      <ng-template #nextpage>
                        <tr formGroupName="{{ i + startIndex }}">
                          <!-- <td class="text-center" style="position: relative; bottom: 5px;">
                                                <button mat-menu-item type="button" (click)="deleteemployee(i)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </td> -->
                          <td style="color: red">
                            <!-- <button mat-menu-item type="button" (click)="deleteemployee(i)"> -->
                            <mat-icon (click)="deleteemployee(i)">delete</mat-icon>
                            <!-- </button> -->
                          </td>
                          <td>{{ group.value.employeeNumber }}</td>
                          <td>
                            {{ group.value.firstName }} {{ group.value.lastName }}
                          </td>

                          <td>
                            <div class="select-box" >

                                
                                <mat-select formControlName="LevelName"   placeholder="Level Name"  class="form control
                                select-placeholder
                                email-input" style=" font-family: 'Mulish', sans-serif;
">
                                    <mat-option [value]="null" selected="selected"> -Payroll Run Level Name- </mat-option>
                                    <mat-option  *ngFor="let row of BusinessUnit; let i=index;" [value]="row.businessGroupId" (click)="passedId(row)" >{{row.businessGroupName}}</mat-option>
    
                                    <mat-option  *ngFor="let row of BusinessName; let i=index;" [value]="row.businessUnitId"(click)="passedId(row)" >{{row.businessUnitName}}</mat-option>
                                    <mat-option *ngFor="let row of OrganizationName; let i=index;"[value]="row.organizationId"(click)="passedId(row)" >{{row.organizationName}}</mat-option>
    
                                </mat-select>
                            
                                
                            </div>
                  <mat-error *ngIf="submitted || (getFormControls.controls[i].get('LevelName')?.touched && getFormControls.controls[i].get('LevelName')?.invalid)">
                    <span  style="margin-right: 20%;" *ngIf="getFormControls.controls[i].get('LevelName')?.hasError('required')">
                      Please Enter Level Name
                    </span>
                  </mat-error>
                  
                          </td>
                          
                        </tr>
                      </ng-template>
                    </ng-container>
                  </tbody>
    
                 
                </table>
              </div>
  
            
            <div *ngIf="selectedEmployeeData?.length > 0 && !loader &&showtable" >
              <div class="row">
                <!-- <div class="col-md-4">
                  <div class="showing-record">
                    {{ selectedEmployeeData.length }} rows
                  </div>
                </div> -->
  
                <div class="col-md-8" *ngIf="!loader && selectedEmployeeData?.length > 5" >
                  <pagination-controls
                    style="float: right"
                    (pageChange)="onPageChange($event)"
                  ></pagination-controls>
                </div>
              </div>
            </div>
            <div class="row pb-3" *ngIf="!loader">
              <div class="col-md-12 text-right">
                <!-- <button type="button" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                  [routerLink]="['home/employee-leave-list']" style="margin-right: 20px;"
                                  (click)="close()">Cancel</button> -->
  
                <button *ngIf="selectedEmployeeData?.length > 0&&showtable" 
                  type="button"
                  class="submit"
                  mat-raised-button
                  (click)="close()"
                  [routerLink]="['home/assignemployeelist']"
                  
                  style="
                    background: none;
  
                    border: 1px solid #ddd;
                    font-size: 14px;
                    font-weight: 300;
                    height: 40px !important;
                  "
                  style="
                    color: var(--Red-Color, #ff5d5d);
                    height: 35px;
                    margin-right: 1%;
                  "
                >
                  Cancel
                </button>
                <!-- <button type="submit" class="submit action-button btn btn-primary btn-sm">Save</button> -->
                <button *ngIf="selectedEmployeeData?.length > 0" 
                  mat-raised-button
                  type="submit" style="    background: #1a83ff;
    color: #fff;"
                  class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                  [disabled]="isprocessing1 || employeeform.invalid"
                  
                >
                  Save
                </button>
                <!-- [disabled]="this.leaveForm.invalid" -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
    <!-- <button
      *ngIf="importerror"
      (click)="showerrormessage()"
      class="btn btn-danger"
    >
      View Error Records
    </button> -->
  </div>

  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
      role="status"
    ></div>
  </div>
