import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionitemService {
  private successMessageSubject = new BehaviorSubject<string>('');
  private successUpdateMessageSubject =  new BehaviorSubject<string>('');
  private warningMessageSubject =  new BehaviorSubject<string>('');
  private warningUpdateMessageSubject =  new BehaviorSubject<string>('');
  private successAddTaskMessageSubject = new BehaviorSubject<string>('');
 
  private successAddTaskUpdateMessageSubject = new BehaviorSubject<string>('');
  private successUpdateMessageSubscription: Subscription;
 
  constructor() { }
  setSuccessMessage(description: string) {
    this.successMessageSubject.next(description);
    console.log('Message from service:', description);
  }

  getSuccessMessage() {
    return this.successMessageSubject.asObservable();
  }

  clearSuccessMessage() {
    this.successMessageSubject.next('');
  }

  setSuccessUpdateMessage(description: string) {
    this.successUpdateMessageSubject.next(description);
    console.log('Message from service:', description);
  }

  getSuccessUpdateMessage() {
    return this.successUpdateMessageSubject.asObservable();
  }

  clearSuccessUpdateMessage() {
    this.successUpdateMessageSubject.next('');
  }


  setWarningMessage(description: string) {
    this.warningMessageSubject.next(description);
    console.log('Message from service:', description);
  }

  getWarningSucessMessage() {
    return this.warningMessageSubject.asObservable();
  }

  clearWarningSuccessMessage() {
    this.warningMessageSubject.next('');
  }


  setWarningUpdateMessage(description: string) {
    this.warningUpdateMessageSubject.next(description);
    console.log('Message from service:', description);
  }

  getWarningUpdateMessage() {
    return this.warningUpdateMessageSubject.asObservable();
  }

  clearWarningUpdateMessage() {
    this.warningUpdateMessageSubject.next('');
  }


  
  setAddTaskSuccessMessage(description: string) {
    this.successAddTaskMessageSubject.next(description);
    console.log('Message from service:', description);
  }

  getAddTaskSuccessMessage() {
    return this.successAddTaskMessageSubject.asObservable();
  }

  clearAddTaskSuccessMessage() {
    this.successAddTaskMessageSubject.next('');
  }

  setAddTaskSuccessUpdateMessage(description: string) {
    this.successAddTaskUpdateMessageSubject.next(description);
    console.log('Message from service:', description);
  }

  getAddTaskSuccessUpdateMessage() {
    return this.successAddTaskUpdateMessageSubject.asObservable();
  }

  clearAddTaskSuccessUpdateMessage() {
    this.successAddTaskUpdateMessageSubject.next('');
  }

}


