<style>
    #printable { display: none; }

@media print
{
    #non-printable { display: none; }
    #printable { display: block; }
}
.content-address-lft{float:left;}
.content-address-rgt{float:right;}
p.lic_no {
float: left;
margin: 0px 0px 2px 30px;
}


tr.top-row-one td {
    padding: 0px;
    border: 1px solid #b3b3b3;
    padding: 2px 0px 2px 10px;
}


.content {
padding: 2.25rem 1.25rem;
-ms-flex-positive: 1;
flex-grow: 1
}

.content::after {
display: block;
clear: both;
content: ""
}
</style>

<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content"> View  Payroll</span>
                </div> 
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/employee-payroll-list" mat-raised-button title="Back" style="margin-right: 1%;" class="">Back</a>
                     <div  class="new-import-btn new-button-1" style="margin-top: 5px;" > 
                        <a title="Save as PDF" class="" style="font-size:20px" (click)="printpdf()">
                            <i class="fa fa-file-pdf-o"></i>
                        </a> 
                     </div>
                </div>
            </div>
        </div>
    </div>
</section>

	


    <div *ngIf="!loaders"  class="common-content content"><!-- Content start-->
        <div class="card"><!-- Card start-->
            <div class="card-body">
             
                <div id="pdfTable" #pdfTable>
                    <div class="p-6">
                       
                            <div  class="container" style="border:1px solid #b3b3b3;padding:0px">
                               <span style="text-align:center;float:left;width:100%;padding:10px;font-size:22px;font-weight: 500;">PAYROLL</span>
                                <table  width="100%"  style="border:0px solid #b3b3b3;" data-pdfmake="{'widths':['*','*' ,*','*'],'heights':10}">
                                    <tr width="100%" style="text-align:left;">
                                        <td width="50%" colspan="2">
                                            <img src="{{fileblob}}" alt="logo" class="bottom-logo" style="float:left;width: 230px;">
                                        </td>
                                        <td colspan="2" style="font-size:16px;;">
                                            <span style="font-size:20px;"><b>{{ this.usertData.organization.organizationName}}</b></span><br>
                                            {{ this.usertData.organization.address}}  <br>
                                           
                                        </td>
                                    </tr>
                                    
                                    <tr class="top-row-one">
                                        <td colspan="1" ><b>Emp Code</b></td>
                                        <td> {{this.resultData.employee.employeeNumber  }} </td>
                                        <td><b>Place</b></td>
                                           
                                        <td>
                                             
                                            {{this.resultData.employeeAnnexure.place  }}
                                            
                                        </td>
                                    </tr>
                                    <tr class="top-row-one">
                                        <td colspan="1"><b>Emp Name</b></td>
                                        <td>
                                            {{this.resultData.employee.firstName  }}   {{this.resultData.employee.lastName  }} 
                                                
                                            
                                        </td>
                                        <td><b>Created Date</b></td>
                                        <td>
                                            {{this.resultData.createDateTime | date :dateFormat  }}
                                        </td>
                                    </tr>
                                    <tr class="top-row-one">
                                        <td colspan="1"><b>Desgination</b></td>
                                        <td *ngIf="this.resultData.employee.designation != undefined"> 
                                            {{this.resultData.employee.designation.designationName  }}
                                        </td>
                                        <td *ngIf="this.resultData.employee.designation == undefined"> 
                                           --
                                        </td>
                                        <td><b>Period</b></td>
                                        <td>
                                            {{this.resultData.finanicalPeriod.financialperiodMonth }} -  {{ this.resultData.finanicalPeriod.financialPeriodYear}}
                                                
                                        </td>
                                    </tr>
                                </table>
                                
                                
                                 <span style="float:left;width:100%;text-align:center;padding:10px 0px; font-size:21px;color:#008cd1;"><b>Your annualized Compensation & Benefits statement</b></span>
                                <span style="float:left;width:100%;text-align:center;padding:10px 0px; border-top:1px solid #b3b3b3; border-bottom:0px solid #000; font-size:14px;color:#000;">
                                    This is your personalized Compensation & Benefits statement. Incase you have any clarifications to seek, please contact your HR.Certain items have notional costs based on prevailing market practice.
                                </span>  
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <span style="float:left;width: 100%;margin-bottom: 10px;">
                                            <b>EARNINGS</b>
                                        </span>
                                        <table id="myTable" class="table table-bordered table-hover  dataTable" data-pdfmake="{'widths':['*','*','*','*'],'heights':10}">
                                            <tbody>
                                                <tr >
                                                    <td width="20%"><b>Element Name</b></td>
                                                    <td width="25%" class="text-center"><b>CTC ( {{organizationSettings?.country?.countrySymbol}} )</b></td>
                                                    <td width="25%" class="text-center"><b>Amount  ( {{organizationSettings?.country?.countrySymbol}} )</b></td>
                                                    <td width="25%" class="text-center"><b>YTD  ( {{organizationSettings?.country?.countrySymbol}} )</b></td>
                                                </tr>
                                                
                                                    
                                                            
                                                            <tr  *ngFor="let earnings of earningdata ;" >
                                                                <td >	{{earnings.hrmspaySlipElement.elementName}} </td>
                                                                <td class="text-right px-2" style="text-align: right;">
                                                                    {{earnings.ctcReferenceDouble?.toFixed(2)}}
                                                                </td>
                                                                <td class="text-right px-2" style="text-align: right;">
                                                                    {{earnings.amountDouble?.toFixed(2)}}
                                                                    
                                                                </td>
                                                                <td class="text-right px-2" style="text-align: right;">
                                                                    {{earnings.ytdDouble?.toFixed(2)}}
                                                                </td>
                                                            </tr>

                                                      
                                                            
                                                    
                                                
                                                <tr >
                                                    <td class="text-left px-2 text-left"> 
                                                        <b>Total </b>
                                                    </td>
                                                    <td class="text-right px-2" style="text-align: right;">
                                                        <b>
                                                            {{resultData.totalctc?.toFixed(2)}}
                                                        </b>
                                                    </td>
    
                                                    <td class="text-right px-2" style="text-align: right;">
                                                        <b>{{resultData.totalAmount?.toFixed(2)}} </b>
                                                    </td>
    
                                                    <td class="text-right px-2" style="text-align: right;">
                                                        <b>{{resultData.totalEarningYtd?.toFixed(2)}} </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                              
    
    
                                 <div class="row mt-4"> 
                                    <div class="col-md-12">
                                        <span style="float:left;width: 100%;margin-bottom: 10px;">
                                            <b>DEDUCTIONS</b>
                                        </span>
                                        <table id="myTable" class="table table-bordered table-hover dataTable"  data-pdfmake="{'widths':['*','*','*','*'],'heights':10}">
                                            <tbody>
                                                <tr >
                                                    <td width="20%"><b>Element Name</b></td>
                                                    <td width="25%" class="text-center"><b>CTC (  {{organizationSettings?.country?.countrySymbol}} )</b></td>
                                                    <td width="25%" class="text-center"><b>Amount  (  {{organizationSettings?.country?.countrySymbol}} )</b></td>
                                                    <td width="25%" class="text-center"><b>YTD  (  {{organizationSettings?.country?.countrySymbol}} )</b></td>
                                                </tr>
                                                 <ng-container  *ngFor="let deductions of deductionsdata ;" >
                                                    <tr  *ngIf="!deductions.hrmspaySlipElement.elementName.includes('Employer')">
                                                        <td >{{deductions.hrmspaySlipElement.elementName}}</td>
                                                        <td class="text-right px-2" style="text-align: right;" style="text-align: right;">{{deductions.ctcReferenceDouble?.toFixed(2)}}</td>
                                                      
                                                        <td *ngIf="deductions.amountDouble > 0; else nominus" class="text-right px-2" style="text-align: right;" style="text-align: right;">
                                                            <!-- {{ "-" + " " + deductions.amountDouble?.toFixed(2) }} -->
                                                            {{ deductions.amountDouble?.toFixed(2) }}
                                                        </td>
                                                        <ng-template #nominus>
                                                            <td class="text-right px-2" style="text-align: right;">{{deductions.amountDouble?.toFixed(2)}}</td>
                                                        </ng-template>
                                                        <td class="text-right px-2" style="text-align: right;">{{deductions.ytdDouble?.toFixed(2)}}</td>
                                                    </tr>
                                                 </ng-container>
                                             
                                             
                                                <tr *ngIf="deductionsdata.length == 0 ;" >
                                                    <td colspan="4">No data</td>
                                                </tr>
                                              
                                                <tr >
                                                    <td class="text-left px-2 text-left">
                                                        <b>Total :</b>
                                                    </td>
                                                    <td class="text-right px-2" style="text-align: right;">
                                                        <b>{{ totaldetectionsctc?.toFixed(2) }}</b>
                                                    </td>
                                                    <td class="text-right px-2" style="text-align: right;">
                                                        <b>{{ totaldetectionsamount?.toFixed(2) }}</b>
                                                    </td>
                                                    <td class="text-right px-2" style="text-align: right;">
                                                        <b>{{ resultData.totalDedutionYtd?.toFixed(2) }}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                    <div class="row">
                                    <div class="col-md-12">
                                        <table class="table items table-bordered table-condensed table-hover" data-pdfmake="{'widths':['*','*'],'heights':10}">
                                            <thead class="table_heading">
                                                <tr>
                                                    <td class="" style='height:30px;font-weight: 700;' colspan="2">
                                                        Total
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <th class="tab-medium-width1 text-center" colspan="1">Element Category</th>
                                                    <th class="text-center" colspan="1">AMOUNT (  {{organizationSettings?.country?.countrySymbol}} )</th>
                                             
                                                </tr>
                                            </thead>
                                            <tbody class="table_heading">
                                                <tr>
                                                    <td class="tab-medium-width text-center" colspan="1">
                                                        Earnings
                                                    </td>
                                                
                                                     <td class="tab-medium-width text-right" style="text-align: right;" colspan="1">
                                                        {{resultData.totalAmount?.toFixed(2)}}
                                                    </td>  
                                                    
                                                    
                                                
                                                
                                                </tr>
                                                 
                                                
                                                
                                                <tr>
                                                    <td class="tab-medium-width text-center" colspan="1">
                                                        Deductions
                                                    </td>
                                                     <td class="tab-medium-width text-right" style="text-align: right;" colspan="1">
                                                         
                                                            {{totaldetectionsamount?.toFixed(2)}}
                                                        
                                                    </td>  
                                                
                                                    
                                                
                                                 
                                                </tr>
                                                <tr>
                                                    <td class="tab-medium-width text-center" colspan="1">
                                                        <b>	Total</b>
                                                    </td>
                                                     <td class="tab-medium-width text-right" style="text-align: right;" colspan="1">
                                                        <b>
                                                            {{ calucatetotalamount (resultData.totalAmount , totaldetectionsamount)?.toFixed(2)}}
                                                        </b>	
                                                    </td>  
                                                
                                                
                                                
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                        <span style='padding:10px 0px;float:left;width:100%;display:none;'> <b>Amount In Words :</b> Rupees   amountInWords($ytd);</span>
                                    </div>
                                </div>  
                                
    
                            </div>
                        
                    </div> 
                </div>
            </div>
      
    
        </div>
    </div>


    <div *ngIf="loaders" class="align-items-center text-center">
        <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
      </div>

