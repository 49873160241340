import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { LeaveService } from 'src/app/providers/leave.service';
import { MatOption } from '@angular/material/core';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.scss']
})
export class UploadfileComponent implements OnInit {

  fileuploadform: FormGroup;
  calendarId: any
  holidayType: any = []
  myArray: any = []
  myArray1: any = []
  workArray: any = []
  fileName: any = "No file choosen...";
  exceptionType: any = []
  calendarList: any = [];
  attachment: boolean = false;
  jsonData: any;  
  btnDisable: boolean=false;
  attachmentError: boolean;
  employeeId: any;
  organizationId: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private leaveservice: LeaveService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    public dialogRef: MatDialogRef<UploadfileComponent>
  ) { }

  ngOnInit() {
    let data = JSON.parse(localStorage.getItem('enoteUserData')|| '{}');
      // this.userType = data.userType.userType;
      this.employeeId = data.employee.employeeId;
      this.organizationId = data.organization.organizationId;
    console.log(this.data,"data")
    let currentyear = new Date().getFullYear();
    // this.leaveservice.getCalendar().subscribe(
    this.leaveservice.calenderOfYear(currentyear,this.organizationId).subscribe(
      (x: any) => {
        x.map(e => {
          console.log(e);
          this.calendarList.push(e)
          console.log(this.calendarList);
        })
      })
    this.fileuploadform = this.fb.group({
      calendar: [null, Validators.required],
      attachment: ['', Validators.required]
    })
    console.log(this.fileuploadform);
  }

  // Convvert xlsx into JSON
  // onFileChange(ev) {
  //   console.log(ev);
  //   this.myArray = []
  //   let workBook = null;
  //   this.jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev.target.files[0];
  //   this.fileName = file.name;
  //   console.log(this.fileName);
  //   if (this.fileName != undefined) {
  //     this.attachment = false;
  //   }
  //   reader.onload = (event) => {
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: 'binary', cellDates: true });
  //     this.jsonData = workBook.SheetNames.reduce((initial, name) => {
  //       const sheet = workBook.Sheets[name];
  //       initial[name] = XLSX.utils.sheet_to_json(sheet);
  //       return initial;
  //     }, {});
  //     const dataString = JSON.stringify(this.jsonData);
  //     console.log(this.jsonData)
  //     // return

  //   }
  //   reader.readAsBinaryString(file);
  // }

 
  onFileChange(ev) {
    console.log(ev);
    this.myArray = [];
    let workBook = null;
    this.jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    
    // Check file type
    const allowedExtensions = ['.xls', '.xlsx'];
    const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    const isValidFile = allowedExtensions.includes(`.${fileExtension}`);
    
    if (!isValidFile) {
      Swal.fire({
        text: 'Invalid file type. Please upload an Excel file (.xls or .xlsx).',
        icon: 'error'
      });
      // Clear the file input
      ev.target.value = '';
      this.fileName = 'No file chosen...';
       this.btnDisable = true;
       this.attachment = true;


      return;
    }
    this.attachment = false;

    this.btnDisable = false;

    this.fileName = file.name;
    console.log(this.fileName);
    this.attachment = false;
  
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary', cellDates: true });
      this.jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(this.jsonData);
      console.log(this.jsonData);
    };
    
    reader.readAsBinaryString(file);
  }
  
  
 


  

  holiday() {
    console.log(this.myArray);
    // return
    this.leaveservice.createMultipleHoliday(this.myArray).subscribe(
      (d: any) => {
        this.btnDisable = false;
        Swal.fire({
          text: d.description,
          icon: 'success',
          showConfirmButton: false,
            timer: 2000
        
        })
        this.dialogRef.close(this.myArray)

        setTimeout(() => {
          // window.location.reload()
        }, 2000)
      },
      err => {
        this.btnDisable = false;
        Swal.fire({
          text: err.error.description,
          icon: 'error'
        })
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000)
      }
    )

  }

  exception() {
    console.log(this.myArray1);
    // return
    this.leaveservice.createMultipleException(this.myArray1).subscribe(
      (d: any) => {
        this.btnDisable = false;
        Swal.fire({
          text: d.description,
          icon: 'success',
          showConfirmButton: false,
            timer: 2000
        })
        this.dialogRef.close(this.myArray1)

        setTimeout(() => {
          // window.location.reload()
        }, 2000)
      },
      err => {
        this.btnDisable = false;
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        })
        // setTimeout(() => {
        //   window.location.reload()
        // }, 2000)
      }
    )
  }
  onSubmit() {
    let x = this.fileuploadform.value;
    console.log(x);
    if (this.fileuploadform.value.attachment == '') {
      this.attachment = true;
    }
    else {
      this.attachment = false;
    }
    if (this.fileuploadform.invalid) {
      return
    }
    let arr = new Array()
    let arr1 = new Array()
    console.log(this.jsonData.Sheet1,x,"1111111111")
    this.jsonData.data.map(e => {
      let x = {}
      if (e.LeaveType == "Holiday") {
          this.holidayType.map(v => {
              if (e.HolidayType == v.listTypeValueName) {
                  x['holidaytype'] = v.listTypeValueId
              }
          });
          let date = new Date(e.HolidayDate);
          console.log(date);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setDate(date.getDate() + 1);
          console.log(date);
          var newdate = this.datepipe.transform(date, 'MM-dd-yyyy');
          console.log(newdate);
          x['holidayDate'] = newdate;
          x['holidayStatus'] = 1;
          x['calender'] = {
              calendarId: this.fileuploadform.value.calendar
          };
          x['holidayName'] = e.HolidayName;
          arr.push(x);
          this.myArray.push(x);
      }

      if (e.LeaveType == "Exception") {
          this.exceptionType.map(v => {
              if (e.HolidayType == v.listTypeValueName) {
                  x['exceptionType'] = v.listTypeValueId;
              }
          });
          var date = new Date(e.HolidayDate);
          console.log(date);
          date.setDate(date.getDate() + 1);
          console.log(date);
          var newdate = this.datepipe.transform(date, 'MM-dd-yyyy');
          console.log(newdate);
          x['exceptionDate'] = newdate;
          x['exceptionStatus'] = 1;
          x['calender'] = {
              calendarId: this.fileuploadform.value.calendar
          };
          x['exceptionName'] = e.HolidayName;
          arr1.push(x);
      }
  });

    this.myArray = arr
    this.myArray1 = arr1

    this.myArray1.map(
      x => {
        let date = new Date(x.exceptionDate)
        this.workArray.map(
          y => {
            // if(x.exceptionType == 41){
            if (date.getDay() == y) {
              alert(`${x.exceptionDate} already considered as a leave`)
              this.myArray1 = []
              setTimeout(() => {
                window.location.reload()
              }, 2000)
              return
            }
            // }
          }
        )
      }
    )

    // this.myArray1.map(
    //   x=>{
    //     let date = new Date(x.exceptionDate)
    //     this.workingDays.map(
    //       y=>{
    //         if(x.exceptionType == 31){
    //           if(date.getDay() == y){
    //             alert(`${x.exceptionDate} already considered as a working day`)
    //             this.myArray1 = []
    //             setTimeout(()=>{
    //               window.location.reload()
    //             },2000)
    //             return
    //           }
    //         }

    //       }
    //     )
    //   }
    // )
    console.log(this.myArray);
    this.btnDisable=true;
    if (this.myArray != '') {
      this.holiday()
    }
    console.log(this.myArray1);
    if (this.myArray1 != '') {
      this.exception()
    }
  }

 
  
  
  
  
  get f() {
    return this.fileuploadform.controls;
  }

}
