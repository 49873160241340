<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">ESI Report</span>
        </div>
        <!-- <div class="sub-header-buttons">
                 <a routerLink="employee" mat-raised-button title="Export" (click)="downloadAsExcel()" class="">Export</a>
                </div> -->
      </div>
    </div>
  </div>
</section>
<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
          <div class="row">
            <div class="form-group col-md-3">
              <!-- <input formControlName="employee" type="search" autocomplete="off" class="form-select form-select-solid form-select-lg select2-new" [value]=null placeholder="Search..."> -->
              <label
                class="form-control-label"
                style="font-family: Mulish; font-size: 16px"
                >Employee</label
              >
              <input
                type="text"
                matInput
                placeholder="Search Employee"
                formControlName="employeecode"
                [matAutocomplete]="auto"
                class="example-input form-control"
                (keyup)="empSearch($event)"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                    </mat-option> -->
                <mat-option
                  *ngFor="let emp of empList"
                  [value]="emp.employeeId"
                  (click)="empSelect(emp)"
                >
                  {{ emp.employeeNumber }}- {{ emp.firstName }}
                </mat-option>
                <mat-option
                  [value]="null"
                  *ngIf="empCode && empList.length === 0"
                  (click)="noEmployeeFound()"
                  >No Employee Found</mat-option
                >
              </mat-autocomplete>
              <mat-icon
                class="material-symbols-outlined"
                style="position: absolute; top: 48px; right: 19px"
              >
                search
              </mat-icon>
              <!-- <small class="text-muted">Note : Employee Number</small> -->
            </div>
            <div class="form-group col-md-3">
              <label
                class="form-control-label"
                style="font-family: Mulish; font-size: 16px"
                >Financial Year<span class="star">*</span></label
              >
              <mat-select
                formControlName="financialyear"
                placeholder="Select Financialyear"
                class="form-control email-input select-placeholder form-select-solid form-select-lg select2-new"
                (selectionChange)="selectFinancialyear()"
              >
                <mat-option [value]="null">
                  -Select Financialyear-
                </mat-option>
                <mat-option
                  *ngFor="let row of finYearList; let i = index"
                  value="{{ row.financialYearId }}"
                  >{{ row.financialFromMonth }}-{{ row.financialFromYear }} To
                  {{ row.financialToMonth }}-{{
                    row.financialToYear
                  }}</mat-option
                >
              </mat-select>
            </div>
            <div class="form-group col-md-3">
              <label
                class="form-control-label"
                style="font-family: Mulish; font-size: 16px"
                >Financial Period<span class="star">*</span></label
              >
              <mat-select
                formControlName="period"
                placeholder=" Select Financial Period"
                class="form-control email-input select-placeholder form-select-solid form-select-lg select2-new"
              >
                <mat-option [value]="null">
                  -Select Financial Period-
                </mat-option>
                <mat-option
                  *ngFor="let row of finPeriodList; let i = index"
                  value="{{ row.financialPeriodId }}"
                  >{{ row.financialperiodMonth }}-{{
                    row.financialPeriodYear
                  }}</mat-option
                >
              </mat-select>
            </div>

            <div class="col-md-3" style="margin-top: 24px">
              <button
                type="submit"
                mat-raised-button
                [disabled]="isProcessing"
                class="searchBtn"
              >
                Search
              </button>
              <button
                style="margin-left: 10px"
                type="reset"
                mat-raised-button
                class="btn mr-2 search-btn"
                (click)="clearTable()"
              >
                Clear
              </button>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-2 pl-1" *ngIf="resultData.length > 0 && !loader">
              <button
                class="but mb-2"
                type="button"
                style="color: white"
                (click)="downloadAsExcel()"
              >
                <mat-icon class="material-symbols-outlined"
                  >file_download</mat-icon
                >
                Download Excel
              </button>
            </div>
            <div class="form-group col-md-2 pl-1" *ngIf="resultData.length > 0 && !loader">
              <button
                class="but ml-2 mb-2"
                type="button"
                style="color: white"
                (click)="printPdf()"
              >
                <mat-icon class="material-symbols-outlined">print</mat-icon>
                Download PDF
              </button>
            </div>
          </div>
        </form>
        <!-- <div class="row pb-3">
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive">
                    <table style="width: 140%;" class="table  --table-bordered table-border-new">
                        <thead class="t-head">
                            <tr >
                                <th>Period</th>
                                <th>Emp Code</th>
                                <th>EsI No</th>
                                <th>Emp Name</th>
                                <th>Working Days</th>
                                <th>Earned Salary</th>
                                <th>Total ESI</th>
                                <th>Father Name</th>
                                <th>DOJ</th>
                                <th>DOB</th>             
                                <th>Aadhar</th>
                                <th>UAN</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData.length>0">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td style="white-space: nowrap;">{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>                    
                                <td>{{row.employee.employeeNumber}}</td>
                                <td>{{row.employee.esiNumber ? row.employee.esiNumber : ""}}</td>
                                <td>{{row.employee.firstName}}</td>
                                <td>{{getempworkingdays(row)}}</td>
                                <td>{{row.totalAmount?.toFixed(2)}}</td>
                                <td>{{row.employeeEsi?.toFixed(2)}}</td>
                                <td>{{row.employee.lastName}}</td>
                                <td>{{row.employee.dateOfJoining}}</td>
                                <td>{{row.employee.dateOfBirth}}</td>
                                <td>{{row.employee.aadhaarNumber ? row.employee.aadhaarNumber : ""}}</td>
                                <td>{{row.employee.uanNumber? row.employee.uanNumber : ""}}</td>
                              
                              
                            </tr>
                           
                        </tbody>
                    </table>
                
                      
                 
                 </div> -->
        <!-- <div *ngIf="resultData.length == 0">
                    <p style="text-align: center;" [attr.colspan]="resultData.length">No Records Found!</p>
                </div>  -->
        <!-- <div *ngIf="noRecordsFound == false">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
  style="height: 527vh !important"
></div>
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div> -->
<div class="float-end" *ngIf="tableShow">
    <div class="row rowDesignshow" *ngIf="resultData?.length > 0">
      <div
        class="select-pagination"
        style="
        
          margin-left: 15px;
          margin-bottom: 10px;
        "
        *ngIf="resultData?.length > 5"
      >
        <span class="showPage showDesign" style="    position: relative;
right: 5px;">Show</span>
        <select
          class="select_list new-select_list listDesign"
          [(ngModel)]="mySelect"
      
          (change)="changefirstpage()"
        >
          <option class="form-control" value="5">5</option>
          <option class="form-control" value="10">10</option>
          <option class="form-control" value="15">15</option>
          <option class="form-control" value="20">20</option>
          <option class="form-control" value="25">25</option>
        </select>
      </div>
    </div>
  </div>
<div id="pdfTable" #pdfTable style="display: none">
  <div class="common-content">
    <div class="card-new">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <table
                class="table-class"
                data-pdfmake="{'widths':['*','*'],'heights':10}"
              >
                <thead>
                  <tr>
                    <td class="text-center" width="40%">
                      <img
                        [src]="base64data"
                        style="height: 65px; width: 180px"
                      />
                    </td>
                    <td class="float-left" width="80%">
                      <p
                        style="
                          font-size: 18px;
                          font-weight: 600;
                          text-align: left;
                        "
                      >
                        <b style="text-align: left">{{
                          this.userData.organization.organizationName
                        }}</b>
                      </p>
                      <span
                        style="padding-top: 0px; float: left; width: 100%"
                        >{{ this.userData.organization.address }}</span
                      ><br />
                    </td>
                  </tr>
                </thead>
              </table>
              <table
              class="table-class"
              data-pdfmake="{'widths':['*'],'heights':10}"
            >
              <thead>
                <tr>
                  <td
                    style="text-align: center; font-size: 14px; padding: 10px"
                  >
                    <b>EMPLOYEE ESI FUND </b>
                    <br /><br />
                    <span style="font-weight: 500"
                      >STATEMENT FOR THE PERIOD OF {{ period }}</span
                    >
                  </td>
                </tr>
              </thead>
            </table>
<!-- 
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div> -->

             
              <table
                class="table items table-bordered table-condensed table-hover"
                style="width: 100%"
                *ngIf="tableShow"
                data-pdfmake="{'widths':[auto,50,auto,auto,auto,auto,45,55,'auto'],'heights':20 ,'headerRows': 1}"
              >
                <th style="border: 1px solid #ccc" colspan="1">Period</th>
                <th style="border: 1px solid #ccc" colspan="1">Emp Code</th>
                <th style="border: 1px solid #ccc" colspan="1">ESI No</th>
                <th style="border: 1px solid #ccc" colspan="1">Emp Name</th>
                <th style="border: 1px solid #ccc" colspan="1">Working Days</th>
                <th style="border: 1px solid #ccc" colspan="1">
                  Earned Salary
                </th>
                <th style="border: 1px solid #ccc" colspan="1">Total ESI</th>
                <!-- <th style="border:1px solid #ccc;"  colspan="1">Father Name</th> -->
                <th style="border: 1px solid #ccc" colspan="1">DOJ</th>
                <!-- <th style="border:1px solid #ccc;"  colspan="1">DOB</th>              -->
                <!-- <th style="border:1px solid #ccc;"  colspan="1">Aadhar</th> -->
                <th style="border: 1px solid #ccc" colspan="1">UAN</th>

                <tbody
                  style="
                    border-left: 1px solid #ddd;

                    border-right: 1px solid #ddd;
                  "
                >
                  <tr *ngFor="let row of resultData | paginate: { itemsPerPage: mySelect, currentPage: p }let i = index">
                    <td style="border: 1px solid #ccc; white-space: nowrap">
                      {{ row?.finanicalPeriod?.financialperiodMonth }} -
                      {{ row?.finanicalPeriod?.financialPeriodYear }}
                    </td>
                    <td style="border: 1px solid #ccc">
                      {{ row?.employee?.employeeNumber }}
                    </td>
                    <td style="border: 1px solid #ccc">
                      {{ row?.employee?.esiNumber ? row?.employee?.esiNumber : "" }}
                    </td>
                    <td style="border: 1px solid #ccc">
                      {{ row?.employee?.firstName }}
                    </td>
                    <td style="border: 1px solid #ccc">
                      {{ getempworkingdays(row) }}
                    </td>
                    <td style="border: 1px solid #ccc">
                      {{ Roundof(row.totalAmount) }}
                    </td>
                    <td style="border: 1px solid #ccc">
                      {{ row.employeeEsi?.toFixed(2) }}
                    </td>
                    <!-- <td style="border:1px solid #ccc;">{{row.employee.lastName}}</td> -->
                    <td style="border: 1px solid #ccc">
                      {{ row?.employee?.dateOfJoining }}
                    </td>
                    <!-- <td style="border:1px solid #ccc;">{{row.employee.dateOfBirth}}</td> -->
                    <!-- <td style="border:1px solid #ccc;">{{row.employee.aadhaarNumber ? row.employee.aadhaarNumber : ""}}</td> -->
                    <td style="border: 1px solid #ccc">
                      {{ row?.employee?.uanNumber ? row?.employee?.uanNumber : "" }}
                    </td>
                    <!-- <td>{{row.employee.employeeInfo.}}</td> -->
                  </tr>
                </tbody>
                <!-- <tbody
                  style="
                    border-left: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                  "
                  *ngIf="tableShow"
                >
                  <tr>
                    <td
                      style="
                        text-align: center;
                        padding: 10px;
                        font-size: 19px;
                        font-weight: 600;
                        font-family: 'Mulish', sans-serif;
                        left: 5;
                        position: relative;
                      "
                      colspan="11"
                      *ngIf="noRecordsFound"
                    >
                       ESI Reports Records Not Found!
                    </td>
                  </tr>
                </tbody> -->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="position: relative;top:110px;margin:15px" class="card --card-content-nodata --mat-card-nodata" *ngIf="noRecordsFound">
    <div class="card-body-nodata">
      <div class="alert-nodata --alert-primary">
        <h4 align="center" class="h4" style="  text-align: center;
        padding: 10px;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Mulish', sans-serif;
        margin: 15px;
        ">ESI Reports Records Not Found!</h4>
      </div>
    </div>
  </div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
    role="status"
  ></div>
</div>
