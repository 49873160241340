import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import * as math from 'mathjs';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-organization-element-assignment',
  templateUrl: './organization-element-assignment.component.html',
  styleUrls: ['./organization-element-assignment.component.scss']
})
export class OrganizationElementAssignmentComponent implements OnInit {

  organizationElementForm: FormGroup;
  organizationList: any;
  usertData: any;
  orgId: any;
  btndisable:boolean=false
  submitted: boolean = false;
  showctcerror: boolean;
  deletedrows: any = [];
  elementlist: any = [];
  filterele: any;
  clientElementId: any;
  livingcity: any;
  empannexue: any = {};
  employeeId: any;
  toppingList: string[];
  selectedOption: string | null = null;
  refreancelements: any = [];
  touchedRows: any;
  success: boolean;
  sucess_msg: any;
  error: boolean;
  error_msg: any;
  clientElement: any;
  entityId: number;
  employeetData:any
  clientId:number;
  orgName:any;
  loader:boolean=false;
  clientLogin: boolean = false;
  organizationLogin: boolean = false;
  entityAdminLogin: boolean = false;
  constructor(private fb: FormBuilder,
    public payrollservice: PayrollService, private router: Router, private ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;
      this.orgId = this.usertData.organization.organizationId;
      console.log('this.organizationLogin', this.organizationLogin);

    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;
    
      console.log('this.clientLogin', this.clientLogin);
    } else {
      this.entityAdminLogin = true;
  
    }
    this.orgId = this.usertData?.organization?.organizationId;
    this.orgName = this.usertData?.organization?.organizationName;

    this.clientId=this.usertData?.employee?.client?.clientId;
    this.entityId = this.usertData?.entity?.entityId;


    this.organizationElementForm = this.fb.group({
      organization: [null, [Validators.required]],
      PfMaxSalaryAmount: [null, [Validators.required, Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      EmployeePF: [null, [Validators.required, Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      EmployerPF: [null, [Validators.required, Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      ESIMaxSalaryAmount: [null, [Validators.required, Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      EmployeeESI: [null, [Validators.required, Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      EmployerESI: [null, [Validators.required, Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      tableRows: this.fb.array([]),
    })

    if(this.organizationLogin == true){
      this.organizationElementForm.get('organization')?.patchValue(this.orgName);
      this.organizationElementForm.get('organization')?.disable();
    }


    this.payrollservice.getOrganizationNameListBasedEntity(this.entityId).subscribe((result: any) => {
      console.log('result', result);
      this.organizationList =  result.filter(organization =>organization?.organizationStatus?.listTypeValueId == 1);;

      // this.clientList =  result.filter(client =>client?.status?.listTypeValueId == 1);
      // console.log(this.clientList);
      // this.organizationList = result;
    })

    console.log('activated route', this.ActivatedRoute.snapshot.params);

    this.clientElementId = this.ActivatedRoute.snapshot.params.id;
    console.log('activated2', this.ActivatedRoute.snapshot.params.id);
    if (!this.clientElementId) {
      this.payrollservice.getelements(this.orgId).subscribe(
        (result: any) => {
          if (result.statusCode) {
            this.elementlist.length = 0;
          }
          else {
            console.log("else")
            this.elementlist = result;
            this.refreancelements=result
            // this.refreancelements = result.filter(elements => elements.status.listTypeValueId == 1 && elements.categoryType.listTypeValueId == 42);
            // this.filterele = this.elementlist.filter(ele => ele.elementName.includes("Basic") || ele.elementName.includes("HRA"));
            this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
            console.log(this.filterele);
            const control = this.organizationElementForm.get('tableRows') as FormArray;
            console.log("this.filterele", this.filterele);
            this.filterele.forEach((element, index) => {
              this.addRow();
              if (element.elementName != "HRA") {
                control.at(index).patchValue({
                  Percentagelist: element.hrElementPercentage,
                  //   Percentage : element.hrElementPercentage[0].elementPercentage,
                  element: element.elementId,
                  elementName: element.elementName
                })
              }
              else {
                control.at(index).patchValue({
                  Percentagelist: element.hrElementPercentage,
                  //   Percentage : element.hrElementPercentage[0].elementPercentage,
                  element: element.elementId,
                  elementName: element.elementName
                })
              }
            });
            console.log(control);
          }

          if(this.orgId){
            this.payrollservice.getCalculateByorg(this.orgId).subscribe(
              (result: any) => {
                if (result.statusCode) {
  
                }
                else {
                  result.forEach(element => {
                    this.refreancelements.push(element);
                  });
  
                }
              })
          }
          else {
            // if(this.clientId){
            //   this.payrollservice.getCalculateByClient(this.clientId).subscribe(
            //     (result: any) => {
            //       if (result.statusCode) {
    
            //       }
            //       else {
            //         result.forEach(element => {
            //           this.refreancelements.push(element);
            //         });
    
            //       }
            //     })
            // }
          }

    
        })



      this.payrollservice.getListTypeById(15).subscribe(
        (result: any) => {
          if (result.statusCode) {
            this.livingcity.length = 0;
          }
          else {
            console.log("else")
            this.livingcity = result;
          }
        })
    }
    else {
      console.log('inside Else');

      // this.payrollservice.getelements(this.orgId).subscribe((result: any) => {
      //   if (result.statusCode) {
      //     this.elementlist.length = 0;
      //   }
      //   else {
      //     console.log("else")
      //     this.elementlist = result;
      //     this.refreancelements = result.filter(elements => elements.status.listTypeValueId == 1 && elements.categoryType.listTypeValueId == 42);
      //     console.log("this.refreancelements : " + this.refreancelements)
      //     this.payrollservice.getelementsbyorgnull().subscribe(
      //       (result: any) => {
      //         if (result.statusCode) {

      //         }
      //         else {
      //           result.forEach(element => {
      //             this.refreancelements.push(element);
      //           });
      //           this.payrollservice.getElementAssignByOrg(this.clientElementId).subscribe((result: any) => {
      //             if (result.statusCode) {

      //             } 
      //             else {

      //               console.log('result', result);

      //               this.clientElement = result;
      //               this.organizationElementForm.patchValue({
      //                 organization: this.clientElement.organization.organizationId,
      //                 PfMaxSalaryAmount: this.clientElement.pfMaxSalaryAmount,
      //                 EmployeePF: this.clientElement.employeePfAmount,
      //                 EmployerPF: this.clientElement.employerPfAmount,
      //                 ESIMaxSalaryAmount: this.clientElement.esiMaxSalaryAmount,
      //                 EmployeeESI: this.clientElement.employeeEsiAmount,
      //                 EmployerESI: this.clientElement.employerEsiAmount
      //               })

      //               //     this.employeeId =  this.empannexue.employee.employeeId
      //             }
      //             this.clientElement.lines.forEach((element, index) => {
      //               let elementids = [];
      //               element.payslipElement.forEach(payslipelement => {
      //                 elementids.push(payslipelement.elementId);
      //               });
      //               console.log("elementids:" + elementids);


      //               const control = this.organizationElementForm.get('tableRows') as FormArray;
      //               this.addRow();
      //               control.at(index).patchValue({
      //                 elementClientLineId: element.elementClientLineId,
      //                 elementName: element.elementName,
      //                 element: element.element.elementId,

      //                 Percentagelist: element.element.hrElementPercentage,
      //                 Percentage: element.percentage ? element.percentage.elementPercentageId : 0,
      //                 calcElement: elementids,
      //                 //calcElementName: [null],
      //                 Formula: element.formula,
      //                 isEditable: [true],
      //                 status: element.status
      //               })
      //             });
      //           })
      //         }
      //       })
        // }


      // })
      this.loader=true;
  
      this.payrollservice.getelements(this.orgId).subscribe(
        (result: any) => {
          this.elementlist=result;
          this.loader=false;

          // this.refreancelements =  result.filter(elements =>elements.status.listTypeValueId == 1 && elements.categoryType.listTypeValueId == 42);
          this.refreancelements=result;
          console.log("else")
          this.elementlist = result;
          // this.refreancelements =  result.filter(elements =>elements.status.listTypeValueId == 1 && elements.categoryType.listTypeValueId == 42);
          this.filterele =  this.elementlist.filter(ele=> ele.elementName.includes("Basic")    || ele.elementName.includes("HRA"));
          this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
          // console.log(this.filterele);
          const control = this.organizationElementForm.get('tableRows') as FormArray;
          console.log("this.filterele",this.filterele);
          this.filterele.forEach((element ,index) => {
            this.addRow()
            if(element.elementName != "HRA"){
              control.at(index).patchValue({
                Percentagelist : element.hrElementPercentage,
             //   Percentage : element.hrElementPercentage[0].elementPercentage,
                element : element.elementId,
                elementName : element.elementName
              })
            }
            else{
              control.at(index).patchValue({
                Percentagelist : element.hrElementPercentage,
             //   Percentage : element.hrElementPercentage[0].elementPercentage,
                element : element.elementId,
                elementName : element.elementName
              })
            }
          })

        })
      this.payrollservice.getElementAssignByOrg(this.clientElementId).subscribe((result: any) => {
        console.log('Result:', result);
        this.loader=false;

        this.clientElement = result;
        this.organizationElementForm.patchValue({
         organization: this.orgName,
      PfMaxSalaryAmount: this.clientElement?.pfMaxSalaryAmount,
          EmployeePF: this.clientElement?.employeePfAmount,
          EmployerPF: this.clientElement?.employerPfAmount,
          ESIMaxSalaryAmount: this.clientElement?.esiMaxSalaryAmount,
          EmployeeESI: this.clientElement?.employeeEsiAmount,
          EmployerESI: this.clientElement?.employerEsiAmount,
        });
      
        const control = this.organizationElementForm.get('tableRows') as FormArray;
      
        this.clientElement.lines.forEach((element, index) => {
          console.log('Element:', element);
      
          // Reset percentages for each element
          const percentages = element.element.hrElementPercentage.map((p) => p.elementPercentageId);
          const elementIds = element.payslipElement.map((p) => p.elementId);
      
          console.log('Percentages:', percentages);
          console.log('Element IDs:', elementIds);
      
          // Add a new row if needed
          if (control.length <= index) {
            this.addRow();
          }
      
          // Patch values into the form row
          control.at(index).patchValue({
            elementClientLineId: element.elementClientLineId,
            elementName: element.elementId,
            element: element.element.elementId,
            calcElement: elementIds,
            Percentagelist: element.element.hrElementPercentage,
            Percentage: percentages.length > 0 ? percentages[0] : 0, // Default to first percentage or 0
            Formula: element.formula,
            isEditable: true,
            status: element.status,
          });
        });
      });
      
      

      // this.payrollservice.getListTypeById(15).subscribe((result: any) => {
      //   if (result.statusCode) {
      //     this.livingcity.length = 0;
      //   }
      //   else {
      //     console.log("else")
      //     this.livingcity = result;
      //   }
      // })
    }
  }
  onSubmit() {
    console.log("form values", this.organizationElementForm.value);
    this.submitted = true;
    const control = this.organizationElementForm.get('tableRows') as FormArray;
    control.controls.forEach((lineelement, index) => {
      this.validateformula(lineelement, index);
    });

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    let clientId=this.employeetData?.employee?.client?.clientId
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;
    let clientDetailStore=clientId?{clientId:clientId}:null;

    if (this.organizationElementForm.invalid) {
      console.log("invalid");

      return;
    }

    if (!this.clientElementId) {
      let clientelement = {
        // client: {
        //   clientId: this.clientElementForm.value.client
        // },
        // organization: {
        //   organizationId: Number(this.organizationElementForm.value.organization)
        // },
        organization:organizationIstore,
        pfMaxSalaryAmount: this.organizationElementForm.value.PfMaxSalaryAmount,
        employeePfAmount: this.organizationElementForm.value.EmployeePF,
        employerPfAmount: this.organizationElementForm.value.EmployerPF,
        esiMaxSalaryAmount: this.organizationElementForm.value.ESIMaxSalaryAmount,
        employeeEsiAmount: this.organizationElementForm.value.EmployeeESI,
        employerEsiAmount: this.organizationElementForm.value.EmployerESI,
      }

      const control = this.organizationElementForm.get('tableRows') as FormArray;
      this.touchedRows = control.controls.filter(row => row).map(row => row.value);
      let elementlnelist = [];
      let elementlne;
      this.touchedRows.forEach(lineelement => {
        elementlne = {

          element: {
            elementId: lineelement.element
          },

          formula: lineelement.Formula,
        }
        let payslipElement = [];
        lineelement.calcElement.forEach(refrenaceelement => {
          let payslipelemnt = {
            elementId: refrenaceelement
          }
          payslipElement.push(payslipelemnt);
          elementlne['payslipElement'] = payslipElement;


        });


        if (lineelement.Percentage != "0" && lineelement.Percentage != "null") {
          let percentage = {
            elementPercentageId: lineelement.Percentage
          };
          elementlne['percentage'] = percentage;
        }

        elementlnelist.push(elementlne);
      });

      clientelement['lines'] = elementlnelist;
      console.log(clientelement);
      //  return
      this.btndisable=true
      this.payrollservice.createOrganizationElement(clientelement).subscribe(
        (result: any) => {
          if (result.statusCode == 200) {
            this.success = true;
            this.btndisable=true

            this.sucess_msg = result.description;
            // this.searchSubmit();
            setTimeout(() => { this.success = false; this.sucess_msg = '' }, 3000)
            // this.clientupdateId = null;

            this.submitted = false;
            setTimeout(() => {
              this.router.navigate(["home/select-elements"])
            }, redirectMsgTimeOut);
          }
          else {
            // this.error = true;
            // this.error_msg = result.description;
            // this.errormessagejson = result;
            // setTimeout(() => {this.error = false},redirectMsgTimeOut)
            console.log("err1", result.statusCode);
            this.error = true;
            this.btndisable=false;

            this.error_msg = result.description;
            setTimeout(() => {   this.error = false;this.error_msg = '' }, 3000)

          }
        },
        err => {
          // console.log("code",err);
          // console.log("code",err.statusCode);
          // if(err.error.statusCode == 409)
          // {
          console.log("code1", err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.error_msg = err.error.description;
          }, 3000)
          setTimeout(() => {   this.error = false;this.error_msg = '' }, 3000)
          // }

        })

    } else {
      let clientelement = {
        elementClientHeaderId: Number(this.clientElementId),
        // organization: {
        //   organizationId: Number(this.organizationElementForm.value.organization)
        // },
        organization:organizationIstore,

        pfMaxSalaryAmount: this.organizationElementForm.value.PfMaxSalaryAmount,
        employeePfAmount: this.organizationElementForm.value.EmployeePF,
        employerPfAmount: this.organizationElementForm.value.EmployerPF,
        esiMaxSalaryAmount: this.organizationElementForm.value.ESIMaxSalaryAmount,
        employeeEsiAmount: this.organizationElementForm.value.EmployeeESI,
        employerEsiAmount: this.organizationElementForm.value.EmployerESI,
      }

      const control = this.organizationElementForm.get('tableRows') as FormArray;
      this.touchedRows = control.controls.filter(row => row).map(row => row.value);
      let elementlnelist = [];
      let elementlne;
      this.touchedRows.forEach(lineelement => {
        elementlne = {
          elementClientLineId: lineelement.elementClientLineId,
          element: {
            elementId: lineelement.element
          },

          formula: lineelement.Formula,
          status: { listTypeValueId: 1 }
        }
        let payslipElement = [];
        lineelement.calcElement.forEach(refrenaceelement => {
          let payslipelemnt = {
            elementId: refrenaceelement
          }
          payslipElement.push(payslipelemnt);
          elementlne['payslipElement'] = payslipElement;


        });


        if (lineelement.Percentage != "0" && lineelement.Percentage != "null") {
          let percentage = {
            elementPercentageId: lineelement.Percentage
          };
          elementlne['percentage'] = percentage;
        }

        elementlnelist.push(elementlne);
      });

      this.deletedrows.forEach(deletelineelement => {
        elementlne = {
          elementClientLineId: deletelineelement.elementClientLineId,
          element: {
            elementId: deletelineelement.element
          },

          formula: deletelineelement.Formula,
          status: { listTypeValueId: 4 }
        }
        let payslipElement = [];
        deletelineelement.calcElement.forEach(refrenaceelement => {
          let payslipelemnt = {
            elementId: refrenaceelement
          }
          payslipElement.push(payslipelemnt);
          elementlne['payslipElement'] = payslipElement;
          if (deletelineelement.Percentage != "0" && deletelineelement.Percentage != "null") {
            let percentage = {
              elementPercentageId: deletelineelement.Percentage
            };
            elementlne['percentage'] = percentage;
          }
        });




        elementlnelist.push(elementlne);
      });

      clientelement['lines'] = elementlnelist;
      console.log(clientelement);
      // return
      this.btndisable=true

      this.payrollservice.updateOrganizationelement(clientelement, this.clientElementId).subscribe(
        (result: any) => {
          if (result.statusCode == 200) {
            this.success = true;
            this.btndisable=true

            this.sucess_msg = result.description;
            // this.searchSubmit();
            setTimeout(() => { this.success = false; }, 3000)
            // this.clientupdateId = null;

            this.submitted = false;
            setTimeout(() => {
              this.router.navigate(["home/select-elements"])
            }, redirectMsgTimeOut)
          }
          else {
            // this.error = true;
            // this.error_msg = result.description;
            // this.errormessagejson = result;
            // setTimeout(() => {this.error = false},redirectMsgTimeOut)
            console.log("err1", result.statusCode);
            this.error = true;
            this.btndisable=false;

            this.error_msg = result.description;
            setTimeout(() => {   this.error = false;this.error_msg = '' }, 3000)

          }
        },
        err => {
          // console.log("code",err);
          // console.log("code",err.statusCode);
          // if(err.error.statusCode == 409)
          // {
          console.log("code1", err.statusCode);
          // setTimeout(() => {
            this.error = true;
            this.btndisable=false

            this.error_msg = err.error.description;
            setTimeout(() => {   this.error = false;this.error_msg = '' }, 3000)
          // }, redirectMsgTimeOut)
          // }

        })
    }



  }
  addelement() {

    const control = this.organizationElementForm?.get('tableRows') as FormArray;
    this.addRow();


  }

  getelemenetpercentage(group, i, event) {
    const control = this.organizationElementForm.get('tableRows') as FormArray;
    console.log(control);
    let elementexist = false;
    //control.value.some(e=> e.employeeId == event.target.value &&  i != index);
    control.value.forEach((element, index) => {
      if (element.element == event.target.value && i != index) {
        elementexist = true;
      }
    });

    if (!elementexist) {
      this.elementlist.forEach((element) => {
        console.log(element);
        if (element.elementId == event.target.value) {

          //  console.log("ctc" + this.postForm.value.ctc);
          //  console.log("this.totalannualincome" + this.totalannualincome);

          control.at(i).patchValue({
            elementName: element.elementName,
            Percentagelist: element.hrElementPercentage,

          })

        }





      })
    } else {
      alert("Element already exist");
      control.at(i).patchValue({
        elementName: null,
        element: null,

      })
    }


  }


  addRow() {
    const control = this.organizationElementForm?.get('tableRows') as FormArray;
    console.log("control", control);
    control?.push(this.initiateForm());
  }
  initiateForm(): FormGroup {
    return this.fb.group({
      elementClientLineId: [null],
      elementName: [null],
      element: [null, Validators.required],
      Percentage: [null, Validators.required],
      Percentagelist: [[]],
      calcElement: [null],
      calcElementName: [null],
      Formula: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s()+\-*/.]+$/)], [this.formulaValidatorAsync]],
      status: [null],
      isEditable: [true]
    });
  }


  defineformula(group, i) {
    console.log(group);
    let formulacode = null;
    let calucationelements = group.value.calcElement;
    calucationelements.forEach(element => {
      this.refreancelements.forEach(refreaelement => {
        if (element == refreaelement.elementId) {
          let elementcode = refreaelement.elementCode;
          if (formulacode == null) {
            formulacode = elementcode;
          } else {
            formulacode = formulacode + " " + elementcode;
          }
          console.log(formulacode);

          const control = this.organizationElementForm.get('tableRows') as FormArray;

          control.at(i).patchValue({

            Formula: formulacode
          })
        }
      });
    });
  }


  // Import the math.js library

  // validateformula(group, i) {
  //   const formula = group.value.Formula;
  //   const selectedElements = group.value.calcElement;

  //   // Check if each selected element code is present in the formula
  //   const allElementsPresent = selectedElements.every(elementId => {
  //     const elementCode = this.refreancelements.find(elem => elem.elementId === elementId)?.elementCode;
  //     return formula.includes(elementCode);
  //   });

  //   console.log("allElementsPresent : " + allElementsPresent);
  //   if (!allElementsPresent) {
  //     group.get('Formula').setErrors({ elementsNotInFormula: true });
  //     return;
  //   }


  //       //   // Use a regular expression to validate the formula format
  //       // const validFormulaPattern = /^[a-zA-Z0-9\s()+\-*/.]+$/;
  //       // const isValidFormula = validFormulaPattern.test(formula);

  //       // if (isValidFormula) {
  //       //   group.get('Formula').setErrors(null); // Clear any previous errors
  //       // } else {
  //       //   group.get('Formula').setErrors({ invalidFormula: true }); // Set a validation error
  //       // }

  //   try {
  //     // Try to parse and evaluate the formula
  //     math.parse(formula);
  //     console.log("allElementsPresent : " + allElementsPresent);
  //     group.get('Formula').setErrors(null); // Clear any previous errors
  //   } catch (error) {
  //     group.get('Formula').setErrors({ invalidFormula: true }); // Set a validation error
  //   }
  // }

  // validateformula(group, i) {
  //   const formula = group.value.Formula;
  //   const selectedElements = group.value.calcElement;

  //   const allElementsPresent = selectedElements.every(elementId => {
  //     const elementCode = this.refreancelements.find(elem => elem.elementId === elementId)?.elementCode;
  //     return formula.includes(elementCode);
  //   });

  //   console.log("allElementsPresent : " + allElementsPresent);
  //   if (!allElementsPresent) {
  //     group.get('Formula').setErrors({ elementsNotInFormula: true });
  //     return;
  //   }

  //   try {
  //     // Wrap the synchronous operation in a Promise
  //     return new Promise(resolve => {
  //         math.parse(formula);
  //       console.log("allElementsPresent : " + allElementsPresent);
  //       group.get('Formula').setErrors(null); // Clear any previous errors
  //       resolve(null); // Resolve the Promise (this indicates successful validation)
  //     });
  //   } catch (error) {
  //     group.get('Formula').setErrors({ invalidFormula: true }); // Set a validation error
  //     return Promise.resolve({ invalidFormula: true }); // Resolve the Promise with an error
  //   }
  // }

  validateformula(group, i) {
    const formula = group.value.Formula;
    const selectedElements = group.value.calcElement;
    if (selectedElements != null) {
      // Check if each selected element code is present in the formula
      const allElementsPresent = selectedElements.every(elementId => {
        const elementCode = this.refreancelements.find(elem => elem.elementId === elementId)?.elementCode;
        return formula.includes(elementCode);
      });

      const allElementsValid = selectedElements.every(elementCode => {
        const elementId = this.refreancelements.find(elem => elem.elementCode === elementCode)?.elementId;

        if (formula.includes(elementId)) {
          // Check if the elementCode is also present in selectedElements
          return selectedElements.includes(elementId);
        }
      });


      const elementCodes = selectedElements.map(elementId =>
        this.refreancelements.find(elem => elem.elementId === elementId)?.elementCode
      );

      console.log(elementCodes);
      // Check if the formula only contains valid characters
      //   const validFormulaPattern = new RegExp(`^([${elementCodes.join('')}\\s()+\\-*/.]+)$`);
      // Generate a regex pattern that matches the allowed element codes, operators, parentheses, whitespace, and numbers
      const validFormulaPattern = new RegExp(`^([${elementCodes.join('')}\\s()+\\-*/.\\d]*)$`);
      console.log(validFormulaPattern);
      const isValidFormula = validFormulaPattern.test(formula);
      console.log("isValidFormula : " + isValidFormula);
      if (!allElementsPresent || !isValidFormula) {
        group.get('Formula').setErrors({ invalidFormula: true });
        return;
      }

      // Custom validation: Ensure that formula contains operators
      const hasOperators = /[+\-*/]/.test(formula);
      if (!hasOperators) {
        group.get('Formula').setErrors({ noOperators: true });
        return;
      }

      try {
        math.parse(formula);
        group.get('Formula').setErrors(null); // Clear any previous errors
      } catch (error) {
        group.get('Formula').setErrors({ invalidFormula: true }); // Set a validation error
      }
    }

  }


  formulaValidatorAsync(control: AbstractControl) {
    const formula = control.value.Formula;
    const selectedElements = control.value.calcElement;

    // alert("validate function callled")
    return new Promise((resolve) => {
      if (selectedElements != null) {
        const allElementsPresent = selectedElements.every(elementId => {
          const elementCode = this.refreancelements.find(elem => elem.elementId === elementId)?.elementCode;
          return formula.includes(elementCode);
        });


        // Check if the formula only contains valid characters
        const validFormulaPattern = /^[a-zA-Z0-9\s()+\-*/.]+$/;
        const isValidFormula = validFormulaPattern.test(formula);

        if (!allElementsPresent || !isValidFormula) {
          // group.get('Formula').setErrors({ invalidFormula: true });
          // return;
          resolve({ invalidFormula: true });
        }

        // Custom validation: Ensure that formula contains operators
        const hasOperators = /[+\-*/]/.test(formula);
        if (!hasOperators) {
          // group.get('Formula').setErrors({ noOperators: true });
          // return;
          resolve({ noOperators: true });
        }

        try {
          math.parse(formula);
          resolve(null);  // Clear any previous errors
        } catch (error) {
          resolve({ invalidFormula: true }); // Set a validation error
        }

      } else {
        resolve(null);
      }



      // if (!regex.test(value.toString()) ) 
      // {
      // console.log("notVaild");
      // resolve({ notZero:true }); // Return an object with a custom error key if value is zero
      // } 
      // else 
      // {
      // console.log("Vaild");
      // resolve(null); // Return null if the value is not zero, indicating the control is valid
      // }
    });
  }


  deleteRow(i, group) {
    const control = this.organizationElementForm.get('tableRows') as FormArray;
    console.log(group);
    console.log("group.value.annexureLineId" + group.value.elementClientLineId);
    if (group.value.elementClientLineId) {
      this.deletedrows.push(group.value);
    }
    control.removeAt(i);
  }
  get getFormControls() {
    const control = this.organizationElementForm?.get('tableRows') as FormArray;
    if (control != null) {
      return control;
    }
  }
  get validate() { return this.organizationElementForm?.controls; }


}
