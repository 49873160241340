<!-- <div class="card-container"> -->
    <!-- <div class="card"> -->
      <!-- Option Selector (Time Setting or Permission Setting) -->
      <!-- <div class="d-flex mb-3">
      
        <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Hours<span class="star"> *</span></label>
              <mat-select placeholder="Select Hour" [(ngModel)]="selectedOption"
                class="form control
                                      select-placeholder
                                      email-input "  style="background-image:none;outline:none;border-radius: 5px;font-family: Mulish;">
                <mat-option [value]="null"> --- Select Setting --- </mat-option>
                <mat-option value="timeSetting">Time Setting</mat-option>
                <mat-option value="permissionSetting">Permission Setting</mat-option>
               
              </mat-select>
            
           
            </div>
          </div>
      </div> -->
  
      <!-- Time Setting Form (Visible when "Time Setting" is selected) -->
      <form  [formGroup]="timesettingsForm" >
        <div class="row">
          <div class="col-md-6"> 
           <label for="" style="font-family: Mulish;font-size: 16px;font-weight:600;">Time Setting</label>
          </div>
          
        </div>
        <div class="row">
          <div class="col-md-6" id="loginform" style="font-family: Mulish;">
            <mat-label class="form-control-label">Min Time<span class="text-danger"> *</span></mat-label>
            <input formControlName="mintime" placeholder="Enter minimum time" maxlength="1" (paste)="onPaste($event)" 
              (keypress)="noNumber($event)" (keydown)="handleBackspace($event)" class="form-control select-placeholder email-input"
              (input)="validateInput($event)" style="background-image: none; outline: none; border-radius: 5px;">
              <div *ngIf="(timesettingsForm.controls['mintime'].invalid && isSubmitted) || timesettingsForm.controls['mintime'].dirty">
                <mat-error *ngIf="timesettingsForm.controls['mintime'].errors?.required">Min time required</mat-error>
            </div> 
          </div>
          <div class=col-md-6>
            <mat-label class="form-control-label">Max Time<span class="text-danger"> *</span></mat-label>
            <input formControlName="maxtime" placeholder="Enter maximum time" maxlength="1" (paste)="onPaste($event)" 
              (keypress)="noNumber($event)" (keydown)="handleBackspace($event)" class="form-control select-placeholder email-input"
              (input)="validateInput1($event)" style="background-image: none; outline: none; border-radius: 5px;">
              <div *ngIf="(timesettingsForm.controls['maxtime'].invalid && isSubmitted) || timesettingsForm.controls['maxtime'].dirty">
                <mat-error *ngIf="timesettingsForm.controls['maxtime'].errors?.required">Max time required</mat-error>
            </div> 
           
          </div>
        </div>
  
        <div class="row mt-2" >
          <div class="row">
            <div class="col-md-6"> 
              <label for="" style="font-family: Mulish;font-size: 16px;font-weight:600;margin-top: 10px;">Permission Setting</label>
             </div>
          </div>
          <div class="col-md-12" id="l" style="font-family: Mulish;">
            <mat-label class="form-control-label">Max Time<span class="text-danger"> *</span></mat-label>
            <input 
                  formControlName="maxtimes" 
                  placeholder="Enter maximum time" 
                  maxlength="1" 
                  (paste)="onPaste($event)" 
                    (keypress)="noNumber($event)"
                    (keydown)="handleBackspace($event)"
                  class="form-control select-placeholder email-input" 
                  (input)="validateInput($event)" 
                  style="background-image: none; outline: none; border-radius: 5px;">
              <div *ngIf="(timesettingsForm.controls['maxtimes'].invalid && isSubmitted) || timesettingsForm.controls['maxtimes'].dirty">
                <mat-error *ngIf="timesettingsForm.controls['maxtimes'].errors?.required">Max time required</mat-error>
            </div> 
          </div>
        </div>
  
        <div class="form-group" align="end">
          <div class="d-flex">
              <div style="width: 45%;margin: 10px auto;">
                  <!-- <button mat-stroked-button mat-dialog-close class="cancel-button" color="accent" type="button"  >Cancel</button> -->
                  <button type="reset"   class="" mat-dialog-close      mat-raised-button   style="
                  float: right; 
                  
                  margin-left: 7px;
                    background: none;
                 
                    border: 1px solid #ddd;
                    font-size: 14px;
                    font-weight: 300;
                    height: 40px !important;
                  "  
                   style="color:var(--Red-Color, #FF5D5D); height:35px "
                  mat-dialog-close>Cancel</button>
              </div>
              <div style="width: 45%;margin: 10px auto;display:flex">
                  <button mat-stroked-button class="searchBtn"   type="submit" 
                [disabled]="timesettingsForm.invalid"  (click)="save()">&nbsp;Save&nbsp;</button>
      
                  
              </div>
          </div>
      </div>
      </form>
  
     

  