import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { DenyreasonComponent } from '../denyreason/denyreason.component';
import { LeaveService } from 'src/app/providers/leave.service';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-manage-permission',
  templateUrl: './manage-permission.component.html',
  styleUrls: ['./manage-permission.component.scss']
})
export class ManagePermissionComponent implements OnInit {

  isLoading: Boolean;
  list_count = 0
  data: any[] = [];
  noData = false;
  query
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber', 'employeeName', 'organizationEmail', 'PermissionDate', 'permissionHours', 'reason', 'workFromHomeStatus', 'star',];
  // dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  loader = true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  mail: any;
  empId: any;
  approvallist: any = [];
  organizationId: any;
  success_msg: string;
  error_msg: any;
  success: boolean = false;
  error: boolean = false;

  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
  searchs: any;
  list_counts=false;
  beforesearchpage: number;
  paginators: boolean = true;
  
  constructor(private gs: GlobalserviceService,
    private router: Router,
    public dialog: MatDialog,
    public leaveservice: LeaveService,  
      private headerservice : HeaderService

  ) {
  }
  ngOnInit() {
    // this.headerservice.setTitle('Permission');
    this.headerservice.setTitle('');

    let x = JSON.parse(localStorage.getItem("enoteUserData"));
    let mail = x.organizationEmail;
    this.mail = mail;
    console.log("this.mail" + this.mail);
    this.empId = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;
    // this.leaveservice.getEmpListById(this.empId).subscribe(
    //   (data: any) => {
    //     this.loader=false;
    //       if(data.lenth==0){
    //         this.noData=true;
    //         console.log("noData");
    // }
    console.log("Employee inside Api to PemissionApi");
    // this.leaveservice.getPermission_Manage(this.empId).subscribe((d: any) => {
    //   this.loader = false;
    //   console.log(d.statusCode);
    //   if(d.length==0){
    //     console.log("noData");
    //   }
    //   if (d.statusCode == 204) {
    //     this.noData = true;
    //     console.log("noData");
    //   }
    //   this.data = d;
    //   this.data.map(
    //     y => {
        
    //       if (y.approvals?.approver?.fyi == this.empId) {
    //         this.noData = false;
    //         y['fyistatus'] = true;
    //         this.approvallist.push(y);
    //         console.log("fyi");
    //       }
    //       if (y.approvals?.approver?.approverOne == this.empId && y.approvals.approverOneStatus.listTypeValueId == 3
    //         && y.approvals.approver.approverOne) {
    //         this.noData = false;
    //         this.approvallist.push(y);
    //         console.log("approverOne");
    //       }
    //       if (y.approvals?.approver?.approverTwo == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 3) {
    //         this.noData = false;
    //         this.approvallist.push(y);
    //         console.log("approverTwo");
    //       }
    //       if (y.approvals?.approver?.approverThree == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 3) {
    //         this.noData = false;
    //         this.approvallist.push(y);
    //         console.log(this.approvallist);
    //         console.log("approverThree");
    //       }
    //       if (y.approvals?.approver?.approverFour == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
    //         && y.approvals.approverFourStatus.listTypeValueId == 3) {
    //         this.noData = false;
    //         this.approvallist.push(y);
    //         console.log("approverFour");
    //       }
    //       if (y.approvals?.approver?.approverFive == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
    //         && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
    //         && y.approvals.approverFourStatus.listTypeValueId == 5 && y.approvals.approverFiveStatus.listTypeValueId == 3) {
    //         this.noData = false;
    //         this.approvallist.push(y);
    //         console.log("approverFive");
    //       }
    //       this.approvallist = this.approvallist.reduce((accumalator, current) => {
    //         console.log(accumalator);
    //         console.log(current);
    //         if (!accumalator.some(item => item === current)) {
    //           accumalator.push(current);
    //         }
    //         return accumalator;
    //       }, []);
    //       console.log("this.approvallist", this.approvallist);
    //       if (this.approvallist.length == 0) {
    //         this.noData = true;
    //         console.log("NO_DATA");
    //       }
    //     }
    //   )
    //   this.dataSource = new MatTableDataSource<any>(this.approvallist);
    //   this.dataSource.filterPredicate = (data, filter) => {
    //     const dataStr =data.employee.employeeNumber+ data.employee.firstName +data.employee.lastName+ data.employee.organizationEmail + data.permissionDate
    //       + data.permissionHours +data.permissionReason+ data.permissionStatusName;
    //       console.log(data);
    //       console.log(data.employee.firstName,"333333333333333333333333333333")
    //     return dataStr.trim().toLowerCase().indexOf(filter) != -1;
    //   }
    //   this.dataSource.paginator = this.paginator;
    //   this.list_count = this.approvallist.length;
    //   // })
    // },
    // (error) => {
    //   console.log("error");
    //   if (error.error.statusCode == 204) {
    //     this.loader = false;
    //     this.noData = true;
    //     console.log("204");
    //   }
    // }
    // )
    //   },
    //   (error) =>
    // {
    //   this.loader=false;
    //   this.noData= true;
    //   console.log("error");
    //   if(error.status==404)
    //   {
    //      this.noData=true;
    //      console.log("404");
    //   }
    // }
    // )
this.fetchAndProcessPermissionData();
  }
  
  fetchAndProcessPermissionData() {
    
    this.loader = true;

    this.leaveservice.getPermission_Manage(this.empId).subscribe((d: any) => {
      this.loader = false;
      this.list_counts=true;
      console.log(d.statusCode);
      this.approvallist=[];
      if (d.length === 0 || d.statusCode == 204) {
        this.noData = true;
        console.log("noData");
        // this.data = [];
        // this.approvallist = [];
        // this.dataSource = new MatTableDataSource<any>(this.approvallist);
        // this.dataSource.paginator = this.paginator;
        // this.list_count = 0;
        // return;
      }
  
      this.data = d;
      this.data.forEach(y => {
        let approverCondition = (approver, status) => approver === this.empId && status === 3;
  
        if (y.approvals?.approver?.fyi === this.empId) {
          this.noData = false;
          y['fyistatus'] = true;
          this.approvallist.push(y);
          console.log("fyi");
        }
  
        if (approverCondition(y.approvals?.approver?.approverOne, y.approvals.approverOneStatus.listTypeValueId)) {
          this.noData = false;
          this.approvallist.push(y);
          console.log("approverOne");
        }
  
        if (approverCondition(y.approvals?.approver?.approverTwo, y.approvals.approverTwoStatus.listTypeValueId) &&
            y.approvals.approverOneStatus.listTypeValueId === 5) {
          this.noData = false;
          this.approvallist.push(y);
          console.log("approverTwo");
        }
  
        if (approverCondition(y.approvals?.approver?.approverThree, y.approvals.approverThreeStatus.listTypeValueId) &&
            y.approvals.approverOneStatus.listTypeValueId === 5 &&
            y.approvals.approverTwoStatus.listTypeValueId === 5) {
          this.noData = false;
          this.approvallist.push(y);
          console.log("approverThree");
        }
  
        if (approverCondition(y.approvals?.approver?.approverFour, y.approvals.approverFourStatus.listTypeValueId) &&
            y.approvals.approverOneStatus.listTypeValueId === 5 &&
            y.approvals.approverTwoStatus.listTypeValueId === 5 &&
            y.approvals.approverThreeStatus.listTypeValueId === 5) {
          this.noData = false;
          this.approvallist.push(y);
          console.log("approverFour");
        }
  
        if (approverCondition(y.approvals?.approver?.approverFive, y.approvals.approverFiveStatus.listTypeValueId) &&
            y.approvals.approverOneStatus.listTypeValueId === 5 &&
            y.approvals.approverTwoStatus.listTypeValueId === 5 &&
            y.approvals.approverThreeStatus.listTypeValueId === 5 &&
            y.approvals.approverFourStatus.listTypeValueId === 5) {
          this.noData = false;
          this.approvallist.push(y);
          console.log("approverFive");
        }
      });
  
      this.approvallist = this.approvallist.reduce((accumulator, current) => {
        if (!accumulator.some(item => item === current)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
  
      console.log("this.approvallist", this.approvallist);
  
      if (this.approvallist.length === 0) {
        this.noData = true;
        console.log("NO_DATA");
      }
  
      this.dataSource = new MatTableDataSource<any>(this.approvallist);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeNumber + data.employee.firstName + data.employee.lastName +
          data.employee.organizationEmail + data.permissionDate + data.permissionHours +
          data.permissionReason + data.permissionStatusName;
        console.log(data);
        console.log(data.employee.firstName, "333333333333333333333333333333");
        return dataStr.trim().toLowerCase().indexOf(filter) !== -1;
      };
      this.dataSource.paginator = this.paginator;
      this.list_count = this.approvallist.length;
  
    }, (error) => {
      console.log("error");
      if (error.error.statusCode == 204) {
        this.loader = false;
        this.noData = true;
        console.log("204");
      }
    });
  }

  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  test(i) {
    if (i.permissionStatusName == null) {
      return
    }
    if (i.permissionStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.permissionStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.permissionStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.permissionStatusName == 'Withdrawn') {
      return '#20B2AA'
    }
    if (i.permissionStatusName == 'Cancelled') {
      return '#e40000'
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.noData = true;
    }
    else{
      this.noData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  Accept(i) {
    console.log(i);
    // this.loader = true;

    Swal.fire({
      title: 'Are you sure? Do you want to Accept',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {

      if (result.value) {
        
    i['organization'] = {
      organizationId: this.organizationId
    }
    let count = 0
    if (i.approvals.approver.approverOne) {
      count = count + 1
    }
    if (i.approvals.approver.approverTwo) {
      count = count + 1
    }
    if (i.approvals.approver.approverThree) {
      count = count + 1
    }
    if (i.approvals.approver.approverFour) {
      count = count + 1
    }
    if (i.approvals.approver.approverFive) {
      count = count + 1
    }
   console.log(i.approvals.approver.approverFive )
    if (i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3) {
      i.approvals.approverOneStatus.listTypeValueId = 5
      i.approvals.approverlevel = 1
      i.approvals['employeeId'] = i.employee.employeeId;
      console.log(i.approvals.approverOneStatus.listTypeValueId);
      
    }
    if (i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
      && i.approvals.approverTwoStatus.listTypeValueId == 3) {
      i.approvals.approverTwoStatus.listTypeValueId = 5
      i.approvals.approverlevel = 2
      i.approvals['employeeId'] = i.employee.employeeId;
    }
    if (i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
      && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 3) {
      i.approvals.approverThreeStatus.listTypeValueId = 5
      i.approvals.approverlevel = 3
      i.approvals['employeeId'] = i.employee.employeeId;;
    }
    if (i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
      && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
      && i.approvals.approverFourStatus.listTypeValueId == 3) {
      i.approvals.approverFourStatus.listTypeValueId = 5
      i.approvals.approverlevel = 4
      i.approvals['employeeId'] = i.employee.employeeId;
    }
    if (i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
      && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
      && i.approvals.approverFourStatus.listTypeValueId == 5 && i.approvals.approverFiveStatus.listTypeValueId == 3) {
      i.approvals.approverFiveStatus.listTypeValueId = 5
      i.approvals.approvalStatus.listTypeValueId = 5
      i.approvals.approverlevel = 5
      i.approvals['employeeId'] = i.employee.employeeId;
      i.permissionStatus = 5
    }
    
    if(count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5){
      i.permissionStatus = 5
      i.approvals.approvalStatus.listTypeValueId = 5
    }
    if(count == 2 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5){
      i.permissionStatus = 5
      i.approvals.approvalStatus.listTypeValueId = 5
    }
    if(count == 3 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
      && i.approvals.approverThreeStatus.listTypeValueId == 5){
        i.permissionStatus = 5
        i.approvals.approvalStatus.listTypeValueId = 5
    }
    if(count == 4 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
      && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5){
        i.permissionStatus = 5
        i.approvals.approvalStatus.listTypeValueId = 5
    }
    if(count == 5 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
      && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5 && i.approvals.approverFiveStatus.listTypeValueId == 5){
        i.permissionStatus = 5
        i.approvals.approvalStatus.listTypeValueId = 5
    }
        // i['permissionStatus'] = 5
        this.loader = true;
        this.leaveservice.Permission_Status(i.permissionId, i).subscribe(
          d => {
            // Swal.fire({
            //   text: 'Approved',
            //   icon: 'success'
            // });
            this.success = true;
            this.success_msg = 'Permission Approved Successfully';
            this.loader = false;
            setTimeout(() => { this.success = false; 
              // this.list_count=0;
              // this.approvallist = [];
              this.fetchAndProcessPermissionData();
            }, 3000);
         
            // this.afterrefresh();
            // setTimeout(() => {
            // window.location.reload();
            // }, 1000);
          },
          err => {
            // Swal.fire({
            //   text: err.error.message
            // })
            this.error = true;
            this.error_msg = err.error.message;
            this.loader = false;
            setTimeout(() => { this.error = false; }, 3000);

          }
        )
      }else{
        this.loader = false;

      }
      //
    })

  }

  Denied(i) {
    console.log(i);
    // this.loader = true;
  

    Swal.fire({
      title: 'Are you sure? Do you want to Denied',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {

      if (result.value) {
        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              i['permissiondenyReason'] = result.denyReason;
              i['organization'] = {
                organizationId: this.organizationId
              }
          
              if (i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3) {
                i.approvals.approverOneStatus.listTypeValueId = 6
                console.log("approverone status");
                console.log(i.approvals.approverOneStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 1
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              if (i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5) {
                i.approvals.approverTwoStatus.listTypeValueId = 6
                console.log("approverTwoStatus");
                console.log(i.approvals.approverTwoStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 2
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              if (i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5) {
                i.approvals.approverThreeStatus.listTypeValueId = 6
                console.log("approverThreeStatus");
                console.log(i.approvals.approverThreeStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 3
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              if (i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
                && i.approvals.approverThreeStatus.listTypeValueId == 5) {
                i.approvals.approverFourStatus.listTypeValueId = 6
                console.log("approverFourStatus");
                console.log(i.approvals.approverFourStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 4
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              if (i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
                && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5) {
                i.approvals.approverFiveStatus.listTypeValueId = 6
                console.log("approverFiveStatus");
                console.log(i.approvals.approverFiveStatus.listTypeValueId);
                i.approvals.approvalStatus.listTypeValueId = 6
                i.approvals.approverlevel = 5
                i.approvals['employeeId'] = i.employee.employeeId;
              }
              console.log(i);
              i['permissionStatus'] = 6;

              this.loader = true

              this.leaveservice.Permission_Status(i.permissionId, i).subscribe(
                d => {
                  // Swal.fire({
                  //   text: 'Denied',
                  //   icon: 'success'
                  // });
                  this.success = true;
                  this.success_msg = 'Permission Denied Successfully';
                  this.loader = false
                  setTimeout(() => { this.success = false;
                    // this.list_count=0;
                    // this.approvallist = [];
                    this.fetchAndProcessPermissionData();
                   }, 3000);

                  // this.afterrefresh();
                  // setTimeout(() => {
                    
                  // window.location.reload()
                  // }, 1000);
                },
                err => {
                  // Swal.fire({
                  //   text: err.error.message,
                  //   icon: 'error'
                  // })
                  this.error = true;
                  this.error_msg = err.error.message;
                  this.loader = false;
                  setTimeout(() => { this.error = false; }, 3000);   
                             }
              )
            }else{
              this.loader = false;

            }
          });
        }
      }
      //
    })

  }

  reason(e) {
    // const dialogRef = this.dialog.open(MPermission, {
    //   data: e.permissionReason
    // })
  }

  afterrefresh() {
    console.log("refersh");
    this.leaveservice.getPermission_Manage(this.mail).subscribe((d: any) => {
      this.loader = false;
      console.log(d.statusCode);
      if (d.statusCode == 204) {
        this.noData = true;
        console.log("noData");
      }
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.permissionDate
          + data.permissionHours + data.permissionStatusName;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length;
      // })
    },
      (error) => {
        if (error.error.statusCode == 204) {
          this.loader = false;
          this.noData = true;
          console.log("204");
        }
      }
    )
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}

// @Component({
//   selector: 'MPermission',
//   templateUrl: 'MPermission.html',
// })
// export class MPermission implements OnInit {

//   constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
//   ngOnInit() {
//   }
// }



