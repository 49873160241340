<!-- <p style="margin-top: 70px;">manage-work-hours works!</p> -->

<!-- <div class="container">
    <div class="row">
        <div class="col-md-12">
            <h1 class="mainHeading">Manage Work Hours</h1>
    </div>
</div> -->
<nav class="navbar bg" style="margin-top: 67px;">
    <div class="container-fluid">
        <div class="row w-100">
            <div class="col-sm-4 col-md-3 d-flex align-items-center mb-2 mb-sm-0">
                <h4 class="heading-tag" style="    position: relative;
                left: 5px;
                top: -19px;">
                    Maximum Work Hours
                </h4>
            </div>

           
        </div>
    </div>
</nav>
<div class="container-fluid">
    <!-- <div class="top-content row">
      <div class="col-md-5 col-sm-5">
        <h4 class="mainHeading">Maximum Work Hours</h4>
     
      </div>
  </div> -->
  <div class="row m-0">
    <div class="card main-card col-md-6">
        <div class="row">
            <div class="col-md-12" align="center">
            <h5 class="text-center title">Maximum Work Hours</h5>
            </div>
        </div>
        <form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
            <div class="col-md-12" id="loginform">
                <label class="form-control-label">Maximum Work Hours<span class="star"> *</span></label>

                <input type="text" maxlength="2" class="form-control email-input select-placeholder" 
                formControlName="maxWorkHours" placeholder="Enter maximum work hours" (keyup)="WorkHours($event)" (keypress)="noNumber($event)">
                <div *ngIf="(f.maxWorkHours.invalid && f.maxWorkHours.touched) || f.maxWorkHours.dirty||submitted">
                    <small *ngIf="f.maxWorkHours.errors?.required" class="text-danger">Please enter max work hours</small>
                    <small *ngIf="f.maxWorkHours.errors?.pattern" class="text-danger">Please enter valid max work hours</small>   
                </div> 
            </div>  
            <div align="end">
                <!-- <button class="btn" type="submit">Save</button> -->
                <button type="submit" mat-raised-button  class="btn  search-btn "  [disabled]="isbtnDisable" style="    position: relative;
    top: 0px;">Save</button>
            </div>
        </form>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{errorMsg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>
  
