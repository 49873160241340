<form [formGroup]="timesettingsForm" (ngSubmit)="save()">
    <div class="d-flex mb-3" >
        <div class="title-block">
            <div class="title">Enter Permission Setting</div>
        </div>
        <div class="concel-block">
            <mat-icon class="material-icons cancel-icon" mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" id="loginform" style="font-family: Mulish;">
            <!-- <mat-form-field class="example-full-width" appearance="outline"> -->
                <mat-label class="form-control-label">Max Permission Hours<span class="text-danger"> *</span></mat-label> 
                <!-- <input matInput formControlName="maxtime" placeholder="Enter maximum permission hours" (paste)="onPaste($event)" (keypress)="noNumber($event)" maxlength="1" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;"> -->
            
                <input 
                formControlName="maxtime" 
                placeholder="Enter maximum time" 
                maxlength="1" 
                (paste)="onPaste($event)" 
                  (keypress)="noNumber($event)"
                  (keydown)="handleBackspace($event)"
                class="form-control select-placeholder email-input" 
                (input)="validateInput($event)" 
                style="background-image: none; outline: none; border-radius: 5px;">    <!-- <mat-error *ngIf="(isSubmitted && timesettingsForm.controls['maxtime'].invalid) || timesettingsForm.controls['maxtime'].dirty || timesettingsForm.controls['maxtime'].touched">
                    <small *ngIf="timesettingsForm.controls['maxtime'].errors?.required"  id="req" style="color:red;font-size:12px;font-family: 'Mulish', sans-serif;">Max time required</small>
                </mat-error> -->
                <div *ngIf="(timesettingsForm.controls['maxtime'].invalid && isSubmitted) || timesettingsForm.controls['maxtime'].dirty">
                    <mat-error *ngIf="timesettingsForm.controls['maxtime'].errors?.required">Max Permission Hours required</mat-error>
                </div> 
            <!-- </mat-form-field> -->
        </div>
    </div>
    <div class="row float-right mt-2">
        <div style="margin: 0 15px;">
            <button mat-stroked-button class="apply-button" color="primary" type="submit" [disabled]="isDisabled">
                Update
            </button>
        </div>
    </div>
</form>




