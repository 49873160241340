<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Salary Advance</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/RequestSalaryAdvance" routerLinkActive="active" color="primary" mat-raised-button title="Request Salary Advance" class="ml-2">
                        Employee Salary Advance
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Financial Year<span class="text-danger"> *</span></label>
                                <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                    <option [value]=null> -- Select Financialyear --- </option>
                                    <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                </select>
                                <mat-error *ngIf="submitted && (filterForm.controls['financialyear'].errors?.required || financialyearError)">
                                    Financial year is required
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Period</label>
                                <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]=null> -- Select Period --- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>
                                <!-- <mat-error *ngIf="submitted && filterForm.controls['period'].errors?.required">
                                    Period is required
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" mat-raised-button color="primary" style="position: relative;top: 32px;"
                                class="btn btn-primary search-btn">Search</button>
                        </div>
                    </div>
                </form>
                <div class="table-responsive" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                    <!-- <p><b>MANAGE ELEMENT</b></p> -->
                    <table class="table --table-bordered table-border-new" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <th class="text-center">Action</th>
                                <th>Employee Number</th>
                                <th>Employee Name</th>
                                <th>Designation</th>
                                <th>Salary</th>
                                <th>Advance</th>
                                <!-- <th>Balance</th> -->
                                <th>View approvers</th>
                                <th>Approval Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let e of salaryAdvancedata | paginate: { itemsPerPage: pagePerItem, currentPage: p } ">
                                <td class="text-center">
                                    <button class="menu-button"
                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon class="menu-icon">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="openDialog(e.salaryAdvanceId)">
                                            <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                            <span mat-button class="text">View</span>
                                        </button>
                                        <button [disabled]="e.approval" mat-menu-item>
                                            <mat-icon class="material-icons-outlined" routerLinkActive="active" routerLink="/home/RequestSalaryAdvance/{{e.salaryAdvanceId}}">edit</mat-icon>
                                            <span mat-button class="text">Edit</span>
                                        </button>
                                    </mat-menu>
                                </td>
                                <td>{{e.employee.employeeNumber}}</td>
                                <td>{{e.employee.firstName}} {{e.employee.lastName}}</td>
                                <td>{{e.employee.designation.designationName}}</td>
                                <td>{{e.totalSalary}}</td>
                                <td>{{e.requestedAdvanceAmount}}</td>
                                <!-- <td>{{e.balance}}</td> -->
                                <td class="table-data text-center">
                                    <mat-icon class="view-icon" *ngIf="e.approval != undefined" 
                                    (click)="viewapproverstatus(e)">visibility</mat-icon>
                                    <span *ngIf="e.approval == undefined">  --  </span>
                                </td>
                                <td class="table-data text-center">
                                    <div *ngIf="e.approval != undefined">
                                        <span class="text-success" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Approved'">
                                            {{e.approval.approvalStatus.listTypeValueName}}
                                        </span>
                                        <span class="text-warning" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Pending'">
                                            {{e.approval.approvalStatus.listTypeValueName}}
                                        </span>
                                        <span class="text-danger" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Denied'">
                                            {{e.approval.approvalStatus.listTypeValueName}}
                                        </span>
                                    </div>
                                    <div class="text-success" *ngIf="e.approval == undefined">Saved as Draft</div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="8" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
