import { Component, OnInit} from '@angular/core';
import { FormGroup,FormArray,FormBuilder } from '@angular/forms';
import { SettingsService } from '../../../../providers/settings.service';
import {PmsService } from '../../../../providers/pms.service'
//import { AddBranchComponent } from '../add-branch/add-branch.component';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  } from '../../../../providers/properties';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-approver-rules',
  templateUrl: './approver-rules.component.html',
  styleUrls: ['./approver-rules.component.scss']
})

export class ApproverRulesComponent implements OnInit 
{
  
  public searchFilter: any = '';
  nodatafound: boolean =true;
  usertData: any;
  orgId: any;
  filterContent: any;
  constructor(private settingsservice: SettingsService,
    private pmsService:PmsService,
    private fb:FormBuilder) { }
  searchForm!:FormGroup;
  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  success = false;
  error:any=false;
  tableSearch : any;
  loader:any= false;
  errorMsg : any;
  sucess_msg:any;
  error_msg:any;

  sortedData: any[];

  ngOnInit(): void 
  {
    this.nodatafound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.searchForm=this.fb.group({
      keywords:[null]
    })
    this.getBranchList();
  }

  getBranchList()
  {
    this.pmsService.getApproverBranchList(this.orgId).subscribe((result: any) => 
    {
      console.log("data",result);
      this.resultData = result;
      console.log(this.resultData);
      this.loader=false;
      this.nodatafound=false;
      if(result.statusCode==204)
      {
        console.log("nodata");
        this.nodatafound=true;
      }
      if(this.resultData.length==0)
      {
        this.nodatafound=true;
      }
    })
  }

  searchSubmit()
  {
    console.log("Search");
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
    console.log(keywords);
    this.filterContent=keywords;
    console.log(this.filterContent);
    if(keywords == null)
    {
      alert('Please Search Keywords');
      return;
    }
    console.log("orgId:",this.orgId);
      this.pmsService.getKRATemplateTextList(this.orgId,keywords).subscribe((filterData: any) => 
      {
        this.loader=false;
        this.nodatafound = false;
        this.resultData = filterData;
        console.log(this.resultData);
        if(this.resultData.statusCode==204)
        {
          console.log("nodata");
          this.nodatafound=true;
        }
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
  }
  updateStatus(branchId,status)
	{
    this.pmsService.updateBranchStatus(branchId,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        if(this.searchForm.value.keywords!=null||this.searchForm.value.keywords!=undefined)
        {
          console.log("FilterContent");
          this.searchSubmit();
        }
        else
        {
          console.log("FilterList");
          this.getBranchList()
        }
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  ucFirst(string) 
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    console.log("Data",data)
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) 
      {
        case 'branchCode':
          return compare(a.branchCode, b.branchCode, isAsc);

        case 'branchName':
          return compare(a.branchName, b.branchName, isAsc);
        
        // case 'mobileNumber':
        //   return compare(a.mobileNumber, b.mobileNumber, isAsc);

        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
