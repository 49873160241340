import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { httpOptions,redirectMsgTimeOut,responseMsgTimeOut}  from '../../../providers/properties';
import { SuperadminService } from '../../../providers/superadmin.service';
import { AddMenuComponent } from '../add-menu/add-menu.component';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit
{

  resultData: any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;
  errorMsg : any;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  sortedData: any[];

  constructor(
    private superadminservice: SuperadminService,
    private dialog:MatDialog,
  )
  { }

  ngOnInit(): void
  {
    this.getMenusList();
  }

  getMenusList()
  {
    this.superadminservice.getMenusList().subscribe((result: any) =>
    {
      this.resultData = result;
    },err =>
    {
       this.errorMsg = err.error.message;
       console.log(this.errorMsg);
    })
  }

  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AddMenuComponent,
    {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getMenusList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData)
  {
    var dialogRef = this.dialog.open(AddMenuComponent, {
      width: '500px',height:'fit-content',
      data:editData
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getMenusList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }

  updateStatus(Id,status)
	{
    this.superadminservice.updateMenuStatus(Id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.getMenusList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  ucFirst(string)
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '')
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) =>
    {
      const isAsc = sort.direction === 'asc';

      switch (sort.active)
      {
        /* case 'moduleName':
          return compare(a.moduleName, b.moduleName, isAsc); */

        case 'menuName':
          return compare(a.menuName, b.menuName, isAsc);

        case 'menuDescription':
          return compare(a.menuDescription, b.menuDescription, isAsc);

        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
