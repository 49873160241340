<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title class="headerDesign">Assign Employee Role</h3>

    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Roles  <span class="star">*</span></label>
                <mat-select formControlName="roleId" placeholder="Select Roles" #select multiple class="form-control email-input select-placeholder">
                    <div class="select-all">
                        <mat-checkbox class="check-all" [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }" (change)="toggleAllSelection()">
                            Select All
                        </mat-checkbox>
                    </div>
                    
                    <mat-option *ngFor="let row of activeRolesData" (click)="optionClick()"   [value]="row.roleId">
                        {{row.roleName}}
                    </mat-option>

                    <!-- <button mat-raised-button color="primary"style="margin: 0 18px 18px 18px" (click)="add()" class="btn create-button">
                        Add
                    </button>

                    <button mat-raised-button color="danger" (click)="cancel()" class="btn cancel-button">
                        Cancel
                    </button> -->
                </mat-select>

                <mat-error *ngIf="f.roleId.hasError('required') && submitted">
                  Please select Roles
                </mat-error>

               <!--  <div class="valid-msg" *ngIf="(f.roleId.invalid && f.roleId.touched) || f.roleId.dirty || submitted">
                    <span *ngIf="f.roleId.errors?.required" class="text-danger">Please select roles.</span>
                </div> -->

                <!-- <mat-select formControlName="roleId" class="form-select form-select-solid form-select-lg" appearance="fill" placeholder="- Select -">
                    <mat-option value="">- Select -</mat-option>
                    <mat-option *ngFor="let row of activeRolesData" [value]="row.roleId">{{row.roleName}}</mat-option>
                </mat-select> -->
                <!-- <div class="valid-msg" *ngIf="(validate.departmentCode.invalid && validate.departmentCode.touched) || validate.departmentCode.dirty || submitted">
                    <span *ngIf="validate.departmentCode.errors?.required" class="text-danger">Please enter department code</span>
                    <span *ngIf="validate.departmentCode.errors?.pattern" class="text-danger">Please enter valid department code</span>
                    <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
                </div> -->
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button type="button"   style="
        float: right; 
        
        margin-left: 7px;
          background: none;
       
          border: 1px solid #ddd;
          font-size: 14px;
          font-weight: 550;
          color:var(--Red-Color, #FF5D5D);
        "  
        mat-raised-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button style="background: #1a83ff;color:#fff">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div> -->
