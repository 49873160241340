import { Component, OnInit } from '@angular/core';
import { FormArray,FormGroup,FormBuilder } from '@angular/forms';
import { PmsService} from '../../../../providers/pms.service'
import { SettingsService } from '../../../../providers/settings.service';
//import { AddBranchComponent } from '../add-branch/add-branch.component';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  } from '../../../../providers/properties';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-employee-apporvers',
  templateUrl: './employee-apporvers.component.html',
  styleUrls: ['./employee-apporvers.component.scss']
})
export class EmployeeApporversComponent implements OnInit 
{
  public searchFilter: any = '';
  empData: any;
  orgId: any;
  constructor(private settingsservice: SettingsService,
    private fb: FormBuilder,
    private pmsService:PmsService
    ) { }
  searchForm!:FormGroup;
  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  success = false;
  error:any=false;
  tableSearch : any;
  loader:any= false;
  errorMsg : any;
  sucess_msg:any;
  error_msg:any;
  nodatafound: boolean = true;
  sortedData: any[];

  ngOnInit(): void 
  {
    this.nodatafound = true;
    this.empData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.empData.userId;
    this.orgId = this.empData.organization.organizationId;
    this.searchForm=this.fb.group({
      keywords:[null]
    })
    this.getBranchList();
  }

  getBranchList()
  {
    console.log("List Api data");
    this.pmsService.getEmployeeApproverList(this.orgId).subscribe((result: any) => 
    {
      console.log("data",result);
      this.resultData = result;
      console.log(this.resultData);
      this.loader=false;
      this.nodatafound=false;
      if(result.statusCode==204)
      {
        console.log("nodata");
        this.nodatafound=true;
      }
      if(result.length==0)
      {
        this.nodatafound=true;
      }
    })
  }

  searchSubmit()
  {
    console.log("search");
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
    console.log(keywords);
    if(keywords == null)
    {
      alert('Please Search Keywords');
      return;
    }
    console.log("orgId:",this.orgId);
    this.pmsService.getEmpApproverFiilterList(this.orgId,keywords).subscribe((filterData: any) => 
    {
        this.loader=false;
        this.nodatafound = false;
        this.resultData = filterData;
        console.log(this.resultData);
        if(this.resultData.statusCode==204)
        {
          console.log("nodata");
          this.nodatafound=true;
        }
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
    })
  }

  updateStatus(id,status)
	{
    console.log("Template Id:"+id,"Status:"+status);
    this.pmsService.updateEmpStatus(id,status).subscribe((result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        // this.searchSubmit();
        if(this.searchForm.value.keywords!=null||this.searchForm.value.keywords!=undefined)
        {
          console.log("FilterContent");
          this.searchSubmit();
        }
        else
        {
          console.log("FilterList");
          this.getBranchList()
        }
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  ucFirst(string) 
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'branchCode':
          return compare(a.branchCode, b.branchCode, isAsc);

        case 'branchName':
          return compare(a.branchName, b.branchName, isAsc);
        
        case 'mobileNumber':
          return compare(a.mobileNumber, b.mobileNumber, isAsc);

        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
