import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDatepickerInputEvent,
  MatDatepickerIntl,
} from '@angular/material/datepicker';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EMPTY,
  ReplaySubject,
  Subject,
  catchError,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { HeaderService } from 'src/app/providers/header.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-timesheet',
  templateUrl: './manage-timesheet.component.html',
  styleUrls: ['./manage-timesheet.component.scss'],
})
export class ManageTimesheetComponent implements OnInit {
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
  public employeeProjects: any = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  selectedEmployeeSearch: any;
  selectedemployee: any;
  manageGroup: FormGroup;
  success: boolean = false;
  successMessage: string = '';
  approveDisabled: boolean = true;
  denyDisabled: boolean = true;
  showMenu: boolean = true;
  searchButton: boolean = true;
  search: any;
  p: any;
  EmpList: any[] = [];
  User_Type;
  submitted = false;
  selectedemployeeId: any;
  employeeNotFound: boolean = false;
  arrayData: any = [];
  empId: any;
  beforesearchpage: any;
  nodata = true;
  loader = false;
  // selectedemployee: any = undefined;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  today: any;
  employeelist: any;
  projectlist: any;
  projectIdList: any[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  endSelectedFirst: boolean = false;
  // selectedemployeeId: any;
  startDate: any;
  endDate: any;
  projectId: any = undefined;
  disable_project: boolean = true;
  approval_Status = 3;
  organizationId: any;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  employee_required: boolean = false;
  project_required: boolean = false;
  tasktotalTime: any;
  isChecked: any;
  public selected;
  isMasterSel: any;
  checkedCategoryList: any = [];
  checkedList: any = [];
  showEmptyData: boolean = false;
  employeeId: any;
  employee: string;
  employeeNumber: any;
  assignformbyemployee: any;
  // employeeProjects: any;
  selectedEmployeeId: any;
  reportlist: any;
  employeeSelected: boolean = false;
  manageList: any = [];
  tableShow: boolean = false;
  checkEmployeeIds: any[] = [];
  constructor(
    private dialog: MatDialog,
    private gs: ProjectManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe,
    private formbuilder: FormBuilder,
    private _datepickerIntl: MatDatepickerIntl,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
  ) {
    this.manageGroup = this.formbuilder.group({
      projectid: ['', Validators.required],
      employee: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();

    // this.removePlaceholder();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.success = false;
    this.successMessage = '';
  }

  protected setInitialValue() {
    this.employeeProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }
  ngOnInit() {
    this.assignemp.setSuccessUpdateMessage('');
    this.timesheet.setSuccessMessage('');
    this.timesheet.setSuccessUpdateMessage('');
    this.headerservice.setTitle('');
    // this.headerservice.setTitle('Manage Timesheet');
    const data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = data.employee.employeeId;
    this.organizationId = data.organization.organizationId;
    console.log('organzationid' + this.organizationId);

    this.today = new Date();
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');

    // this.gs.getResource().subscribe((data: any) => {
    //   console.log(data);
    //   this.employeelist = data;
    // });

    // this.gs.listProjectList(this.organizationId).subscribe((data: any) => {
    //   console.log('data', data);

    //   console.log('data', data[0].projectId);
    //   this.projectIdList.push(data);
    //   this.projectIdList = data;
    //   this.projectlist = data;

    //   console.log('this.projectlist', this.projectlist[0].projectName);
    // });

    this.gs.getAllProjectNames(this.organizationId).subscribe((data: any) => {
      console.log('data', data);

      console.log('data', data[0].projectId);
      this.projectIdList.push(data);
      this.projectIdList = data;
      this.projectlist = data;

      console.log('this.projectlist', this.projectlist[0].projectName);
    });
    // if(this.projectId){
    //   this.gs.getEmployeeProject(this.projectId).subscribe((data: any) => {
    //     console.log("data", data);
    //     this.employeelist = data;
    //   });
    // }

    this.projectIdFormControl = new FormControl('', Validators.required);

    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    this.gs.getResourceByIds(x.employee.employeeId).subscribe((d: any) => {
      this.User_Type = d?.userType?.userType;
    });
  }
  projectIdFormControl: FormControl; // Assuming it's a FormControl
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   // Check if the clicked element is not the search input
  //   if (!(event.target as HTMLElement).closest('.form-control')) {
  //     this.search = ''; // Clear the search term
  //   }
  // }

  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    // if (event.value) {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.remove('select-placeholder');
    // } else {
    //   const input = document.getElementById('startDate') as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }

    // if (event.value) {
    //   const input = document.getElementById('endDate') as HTMLInputElement;
    //   input.classList.remove('select-placeholder');
    // } else {
    //   const input = document.getElementById('endDate') as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }

    if (this.endDate != undefined) {
      this.enddate_required = false;
      return;
    }

    if (this.endSelectedFirst && this.startDate > this.endDate) {
      // If end date was selected first and start date is greater than end date
      // Set start date to be one day before end date
      this.startDate = new Date(this.endDate);
      this.startDate.setDate(this.endDate.getDate() - 1);
    }

    // if (this.startDate != undefined) {
    //   this.startdate_required = false;
    //   this.enddate_required = true;
    //   return;
    // }

    // if (this.startDate == undefined) {
    //   this.enddate_required = false;
    //   return;
    // }
    // if (this.endDate == undefined) {
    //   this.enddate_required = true;
    //   return;
    // }
  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }
  checkStartDate(): void {
    if (this.startDate && this.endDate) {
      // Convert dates to Date objects if they are not already
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      // Check if the start date is greater than the end date
      if (startDate > endDate) {
        alert('Start date cannot be greater than end date.');

        // Reset both startDate and endDate
        // this.startDate = undefined;
        // this.endDate = undefined;

        this.startDate = '';
        this.endDate = '';
        // this.startDate = undefined;
        console.log('after undefined', this.startDate);

        // Trigger change detection to update the UI
        // this.cdr.detectChanges();

        // Fetch employee time entry limit and update relevant data
      }
    }
  }

  // empSearch(event): void {
  //   this.employeeProjects = [];
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);

  //   this.unsubscribe$.next();
  //   this.unsubscribe$.complete();
  //   this.unsubscribe$ = new Subject<void>();
  //   console.log('checking Project Id', this.projectId);

  //   if (employeenumber && this.projectId > 0) {
  //     if (employeenumber.length >= 2) {
  //       this.gs
  //         .filterEmployeesByProjects(
  //           this.organizationId,
  //           this.projectId,
  //           employeenumber
  //         )
  //         +.pipe(
  //           takeUntil(this.unsubscribe$),
  //           switchMap(() =>
  //             this.gs.filterEmployeesByProjects(
  //               this.organizationId,
  //               this.projectId,
  //               employeenumber
  //             )
  //           )
  //         )
  //         .subscribe((result: any) => {
  //           console.log('result', result);

  //           this.employeeProjects.length = 0;
  //           this.employeeProjects.pop();

  //           console.log('projectId based', this.employeeProjects);

  //           result.forEach((res) => {
  //             console.log('employeeId', res.employee.employeeId);

  //             let employee = {
  //               employee: {
  //                 employeeId: res.employee.employeeId,
  //                 firstName: res.employee.firstName,
  //                 employeeNumber: res.employee.employeeNumber,
  //               },
  //             };

  //             console.log('this.employeeProjects', this.employeeProjects);

  //             this.employeeProjects.push(employee);
  //           });

  //           if (this.selectedemployee.length >= 1) {
  //             this.employeeSelected = true;
  //           }

  //           if (result.errorCode === 204) {
  //             alert('No Employee Found');
  //             this.employeeNotFound = true;
  //             this.employeeProjects.length = 0;
  //             this.employeeProjects.push('No Employee Found');
  //             this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
  //           }
  //         });
  //     }
  //   } else {

  //     this.selectedemployeeId = null;
  //   }
  // }

  empSearch(event, event1: KeyboardEvent): void {
    // if (event.target.value) {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.remove('select-placeholder');
    // } else {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }

    if (event1.key === ' ') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      console.log(event.target.value);
      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log(employeenumber);

      // Cancel the previous API request
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();
      if (employeenumber.length == 0) {
        this.employeeNotFound = false;
        console.log('enterd into the condition!!');
        // this.employeeId = undefined;
        // this.employee = undefined;
      }
      // Employee List Api
      if (employeenumber) {
        if (employeenumber.length >= 2) {
          this.gs
            .filterEmployeesByProjects(
              this.organizationId,
              this.projectId,
              employeenumber
            )
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() =>
                this.gs
                  .filterEmployeesByProjects(
                    this.organizationId,
                    this.projectId,
                    employeenumber
                  )
                  .pipe(
                    catchError((err) => {
                      if (err.error.status === 500) {
                        this.projectId = [];
                        this.employeeNotFound = true;
                      }
                      console.log('err', err);
                      console.error('An error occurred:', err);
                      // Handle the error here, for example, you can show an error message to the user
                      return EMPTY; // Return an empty observable or any other observable you need
                    })
                  )
              )
            )
            .subscribe(
              (result: any) => {
                console.log('result', result);
                if (result.errorCode === 204) {
                  this.employeeProjects = [];
                  this.employeeNotFound = true;
                  this.selectedemployee = undefined;
                  this.selectedemployeeId = undefined;
                }
                if (Array.isArray(result)) {
                  this.employeeProjects = result;
                  this.employeeNotFound = false;
                } else if (typeof result === 'object') {
                  // Transform the object into an array if possible
                  this.employeeProjects = Object.values(result);
                  this.employeeNotFound = false;
                } else {
                  console.error('Unexpected data type:', result);
                  return;
                }
                // if (this.employee.length >= 1) {
                //   this.employeeSelected = true;
                // }
                if (result.errorCode === 204) {
                  this.employeeProjects = [];
                  this.employeeNotFound = true;
                  this.selectedemployee = undefined;
                  this.selectedemployeeId = undefined;
                }

                if (result.errorCode === 204) {
                  // alert('No Employee Found');
                  this.employeeNotFound = true;
                  this.selectedemployee = undefined;
                  this.selectedemployeeId = undefined;
                  // this.employeeProjects.length = 0;
                  // this.employeeProjects.push('No Employee Found');
                  // let control = this.assignformbyemployee?.get(
                  //   'tableRows1'
                  // ) as FormArray;
                  // if (control) {
                  //   control.controls.forEach((element) => {
                  //     element.patchValue({
                  //       employee: null,
                  //     });
                  //   });
                  // }
                }
                // if (result.statusCode) {
                //   console.log(result.statusCode);
                //   this.employeeProjects.length = 0;
                // } else {
                //   this.employeeProjects = result;
                // }
              },
              (err) => {
                if (err.error.status === 500) {
                  this.employeeProjects = [];
                  this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
                // Handle the error here, for example, you can show an error message to the user
              }
            );
        }
      }
    }
  }

  empSelect(emp) {
    console.log('emp', emp);
    this.employeeId = emp.employee.employeeId;
    console.log(this.employeeId);
    this.employee = emp.employee.employeeNumber + '-' + emp.employee.firstName;
    this.employeeNumber = emp.employee.employeeNumber;
    this.selectedemployee = this.employee;
    this.selectedemployeeId = emp.employee.employeeId;
    // this.assignformbyemployee.patchValue({
    //   employeecode: this.employee,
    // });
    //  let  employeecode=this.selectedemployeeId;

    // console.log('this.employee', employeecode);
  }

  handleBackspace(event): void {
    const inputValue = event.target.value;

    if (event.key === 'Backspace' && inputValue.length <= 0) {
      this.selectedemployeeId = undefined;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.employeeNotFound = false; // Set employeeNotFound to false
    }
    if (
      event.key === 'Backspace' &&
      !this.selectedemployee &&
      this.selectedemployeeId.trim().length === 0
    ) {
      this.selectedemployeeId = undefined;

      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.selectedemployeeId = undefined;
    }
  }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.employeeProjects.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
  }

  getCheckedItemList() {
    this.checkedCategoryList = [];
    for (var i = 0; i < this.EmpList.length; i++) {
      console.log(this.EmpList[i].isChecked);
      if (this.EmpList[i].isChecked) {
        console.log('if Checked');

        if (this.EmpList[i].fyistatus == undefined) {
          this.checkedCategoryList.push(this.EmpList[i]);
        }
        console.log('this.checkedCategoryList', this.checkedCategoryList);
      }
    }
  }

  isAllSelected() {
    // Check if all checkboxes in the table rows are checked
    this.isMasterSel = this.EmpList.every((emp) => emp.isChecked);
    this.getCheckedItemList();
  }

  checkUncheckAll() {
    // Update the checked status of all checkboxes in the table rows based on the isMasterSel variable
    for (var i = 0; i < this.EmpList.length; i++) {
      this.EmpList[i].isChecked = this.isMasterSel;
    }
    this.getCheckedItemList();
  }
  Approveall() {
    Swal.fire({
      title: 'Are you sure? Do you want to approve',

      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve!',
    }).then((result) => {
      console.log('result', result);

      if (result.value) {
        console.log(this.checkedCategoryList);
        for (var i = 0; i < this.checkedCategoryList.length; i++) {
          console.log(this.checkedCategoryList[i]);
          let data = this.checkedCategoryList[i];

          data['organization'] = {
            organizationId: this.organizationId,
          };

          // data['approvals'] = {
          //   employeeId:
          //   project:
          //   approverlevel:
          // }

          let count = 0;
          if (data.approvals.approver.approverOne) {
            count = count + 1;
          }
          if (data.approvals.approver.approverTwo) {
            count = count + 1;
          }
          if (data.approvals.approver.approverThree) {
            count = count + 1;
          }
          if (data.approvals.approver.approverFour) {
            count = count + 1;
          }
          if (data.approvals.approver.approverFive) {
            count = count + 1;
          }
          // if (
          //   data.approvals.approver.approverOne == this.empId &&
          //   data.approvals.approverOneStatus == 3
          // ) {
          //   data.approvals.approverOneStatus = 5;
          //   data.approvals.appproverlevel = 1;
          //   console.log(data.approval);
          // }
          // if (
          //   data.approvals.approver.approverTwo == this.empId &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 3
          // ) {
          //   data.approvals.approverTwoStatus = 5;
          //   data.approvals.appproverlevel = 2;
          // }
          // if (
          //   data.approvals.approver.approverThree == this.empId &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 3 &&
          //   data.approvals.appproverlevel == 1
          // ) {
          //   data.approvals.approverThreeStatus = 5;
          //   data.approvals.appproverlevel = 3;
          // }
          // if (
          //   data.approvals.approver.approverFour == this.empId &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 5 &&
          //   data.approvals.approverFourStatus == 3
          // ) {
          //   data.approvals.approverFourStatus = 5;
          //   data.approvals.appproverlevel = 4;
          // }
          // if (
          //   data.approvals.approver.approverFive == this.empId &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 5 &&
          //   data.approvals.approverFourStatus == 5 &&
          //   data.approvals.approverFiveStatus == 3 &&
          //   data.approvals.appproverlevel == 1
          // ) {
          //   data.approvals.approverFiveStatus = 5;
          //   data.approvals.approvalStatus = 5;
          //   data.approvals.appproverlevel = 5;
          // }

          // if (count == 1 && data.approvals.approverOneStatus == 5) {
          //   data.approvals.approvalStatus = 5;
          // }
          // if (
          //   count == 2 &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5
          // ) {
          //   data.approvals.approvalStatus = 5;
          // }
          // if (
          //   count == 3 &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 5 &&
          //   data.approvals.appproverlevel == 1
          // ) {
          //   data.approvals.approvalStatus = 5;
          // }
          // if (
          //   count == 4 &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 5 &&
          //   data.approvals.approverFourStatus == 5 &&
          //   data.approvals.appproverlevel == 1
          // ) {
          //   data.approvals.approvalStatus = 5;
          // }
          // if (
          //   count == 5 &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 5 &&
          //   data.approvals.approverFourStatus == 5 &&
          //   data.approvals.approverFiveStatus == 5 &&
          //   data.approvals.appproverlevel == 1
          // ) {
          //   data.approvals.approvalStatus = 5;
          // }
          // console.log(data);

          if (
            data.approvals.approver.approverOne == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5
          ) {
            data.approvals.approverOneStatus.listTypeValueId = 5;

            data.approvals.approverlevel = 1;
          }
          if (
            data.approvals.approver.approverOne == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 3
          ) {
            data.approvals.approverOneStatus.listTypeValueId = 5;

            data.approvals.approverlevel = 1;
          }

          if (
            data.approvals.approver.approverTwo == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 3
          ) {
            data.approvals.approverTwoStatus.listTypeValueId = 5;

            data.approvals.approverlevel = 2;
          }
          if (
            data.approvals.approver.approverThree == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 3
          ) {
            data.approvals.approverThreeStatus.listTypeValueId = 5;

            data.approvals.approverlevel = 3;
          }
          if (
            data.approvals.approver.approverFour == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 5 &&
            data.approvals.approverFourStatus.listTypeValueId == 3
          ) {
            data.approvals.approverFourStatus.listTypeValueId = 5;

            data.approvals.approverlevel = 4;
          }
          if (
            data.approvals.approver.approverFive == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 5 &&
            data.approvals.approverFourStatus.listTypeValueId == 5 &&
            data.approvals.approverFiveStatus.listTypeValueId == 3
          ) {
            data.approvals.approverlevel = 5;
            data.approvals.approverFiveStatus.listTypeValueId = 5;
            data.approvals.approvalStatus.listTypeValueId = 5;
          }

          if (
            count == 1 &&
            data.approvals.approverOneStatus.listTypeValueId == 5
          ) {
            data.approvals.approvalStatus.listTypeValueId = 5;
          }
          if (
            count == 2 &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5
          ) {
            data.withdrawStatus = 5;
            data.approvals.approvalStatus.listTypeValueId = 5;
          }
          if (
            count == 3 &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 5
          ) {
            data.withdrawStatus = 5;
            data.approvals.approvalStatus.listTypeValueId = 5;
          }
          if (
            count == 4 &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 5 &&
            data.approvals.approverFourStatus.listTypeValueId == 5
          ) {
            data.withdrawStatus = 5;
            data.approvals.approvalStatus.listTypeValueId = 5;
          }
          if (
            count == 5 &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 5 &&
            data.approvals.approverFourStatus.listTypeValueId == 5 &&
            data.approvals.approverFiveStatus.listTypeValueId == 5
          ) {
            data.withdrawStatus = 5;
            data.approvals.approvalStatus.listTypeValueId = 5;
          }

          // if (
          //   count == 5 &&
          //  data.approvals.approverOneStatus.listTypeValueId == 5 &&
          //  data.approvals.approverTwoStatus.listTypeValueId == 5 &&
          //  data.approvals.approverThreeStatus.listTypeValueId == 5 &&
          //  data.approvals.approverFourStatus.listTypeValueId == 5 &&
          //  data.approvals.approverFiveStatus.listTypeValueId == 5
          // ) {
          //  data.approvals.approvalStatus.listTypeValueId = 5;
          //   console.log('approvalstatus5', data.approvals.approvalStatus);
          // }

          data.approvals.employeeId = data.employee.employeeId;
          data.approvals.projectId = data.project.projectId;
          data.projectId = data.project.projectId;
          data.approvalStatus = data.approvals.approvalStatus.listTypeValueId;

          console.log('approval status', data.approvalStatus);

          this.checkedList.push(data);
          console.log(this.checkedList);
        }
        this.approveDisabled = false;
        this.denyDisabled = false;
        this.loader = true;
        this.tableShow = false;
        this.EmpList = [];
        this.gs.updateBulkApprovalStatus(this.checkedList).subscribe((data) => {
          console.log(this.checkedList);
          console.log(data);
          // Swal.fire({
          //   icon: 'success',
          //   text: 'Approved',
          // });
          // this.loader = false;
          // window.location.reload();
          if ((data as any).statusCode == 200) {
            this.successMessage = 'Approved Successfully';
            this.success = true;
            this.loader = false;
            // this.tableShow = true;
            setTimeout(() => {
              this.success = false;
              this.successMessage = '';
            }, 3000);
          } else {
            this.successMessage = '';
            this.success = false;
          }
          this.projectId = '';
          this.selectedemployee = '';
          this.startDate = '';
          this.endDate = '';
        });
      }
    });
  }
  Denyall() {
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let emp = x.employeeId;
    Swal.fire({
      title: 'Are you sure? Do you want to deny',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Deny!',
    }).then((result) => {
      if (result.value) {
        console.log(this.checkedCategoryList);
        for (var i = 0; i < this.checkedCategoryList.length; i++) {
          console.log(this.checkedCategoryList[i]);
          let data = this.checkedCategoryList[i];

          data['organization'] = {
            organizationId: this.organizationId,
          };
          // if (
          //   data.approvals.approver.approverOne == this.empId &&
          //   data.approvals.approverOneStatus == 3
          // ) {
          //   data.approvals.approverOneStatus = 6;
          //   data.approvals.approvalStatus = 6;
          //   data.approvals.appproverlevel = 1;
          // }
          // if (
          //   data.approvals.approver.approverTwo == this.empId &&
          //   data.approvals.approverOneStatus == 5
          // ) {
          //   data.approvals.approverTwoStatus = 6;
          //   data.approvals.approvalStatus = 6;
          //   data.approvals.appproverlevel = 2;
          // }
          // if (
          //   data.approvals.approver.approverThree == this.empId &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5
          // ) {
          //   data.approvals.approverThreeStatus = 6;
          //   data.approvals.approvalStatus = 6;
          //   data.approvals.appproverlevel = 3;
          // }
          // if (
          //   data.approvals.approver.approverFour == this.empId &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 5
          // ) {
          //   data.approvals.approverFourStatus = 6;
          //   data.approvals.approvalStatus = 6;
          //   data.approvals.appproverlevel = 4;
          // }
          // if (
          //   data.approvals.approver.approverFive == this.empId &&
          //   data.approvals.approverOneStatus == 5 &&
          //   data.approvals.approverTwoStatus == 5 &&
          //   data.approvals.approverThreeStatus == 5 &&
          //   data.approvals.approverFourStatus == 5
          // ) {
          //   data.approvals.approverFiveStatus = 6;
          //   data.approvals.approvalStatus = 6;
          //   data.approvals.appproverlevel = 5;
          // }

          if (
            data.approvals.approver.approverOne == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 3
          ) {
            // i.approvals.approver = 6;
            // i.approvals.approver = 6;
            // i.approvals.approver={
            //   approverId: 1
            // } ;
            // "approver": {
            //   "approverId": 172
            // },

            data.approvals.approverOneStatus.listTypeValueId = 6;
            data.approvals.approvalStatus.listTypeValueId = 6;
            data.approvals.approverlevel = 1;
            console.log('i.approvals.approver = 6', data.approvals.approver);
          }
          if (
            data.approvals.approver.approverTwo == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5
          ) {
            data.approvals.approverTwoStatus.listTypeValueId = 6;
            data.approvals.approvalStatus.listTypeValueId = 6;
            data.approvals.approverlevel = 2;
            console.log(
              '  i.approvals.approverTwoStatus.listTypeValueId',
              data.approvals.approverTwoStatus.listTypeValueId
            );
            console.log(
              '   i.approvals.approvalStatus.listTypeValueId',
              data.approvals.approvalStatus.listTypeValueId
            );
            console.log(
              'i.approvals.approverlevel',
              data.approvals.approverlevel
            );
          }
          console.log(
            '  i.approvals.approver.approverThree',
            data.approvals.approver.approverThree,
            this.empId
          );
          console.log(
            '     i.approvals.approverOneStatus.listTypeValueId  5',
            data.approvals.approverOneStatus.listTypeValueId
          );
          console.log(
            '  i.approvals.approverTwoStatus.listTypeValueId 5',
            data.approvals.approverTwoStatus.listTypeValueId
          );

          if (
            data.approvals.approver.approverThree == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5
          ) {
            data.approvals.approverThreeStatus.listTypeValueId = 6;
            data.approvals.approvalStatus.listTypeValueId = 6;
            data.approvals.approverlevel = 3;
          }
          if (
            data.approvals.approver.approverFour == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 5
          ) {
            data.approvals.approverFourStatus.listTypeValueId = 6;
            data.approvals.approvalStatus.listTypeValueId = 6;
            data.approvals.approverlevel = 4;
          }

          if (
            data.approvals.approver.approverFive == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 3 &&
            data.approvals.approverTwoStatus.listTypeValueId == 3 &&
            data.approvals.approverThreeStatus.listTypeValueId == 3 &&
            data.approvals.approverFourStatus.listTypeValueId == 3
          ) {
            data.approvals.approverFiveStatus.listTypeValueId = 6;
            data.approvals.approvalStatus.listTypeValueId = 6;
            data.approvals.approverlevel = 5;
          }

          if (
            data.approvals.approver.approverFive == this.empId &&
            data.approvals.approverOneStatus.listTypeValueId == 5 &&
            data.approvals.approverTwoStatus.listTypeValueId == 5 &&
            data.approvals.approverThreeStatus.listTypeValueId == 5 &&
            data.approvals.approverFourStatus.listTypeValueId == 5
          ) {
            data.approvals.approverFiveStatus.listTypeValueId = 6;
            data.approvals.approvalStatus.listTypeValueId = 6;
            data.approvals.approverlevel = 5;
          }

          data.approvals.employeeId = data.employee.employeeId;
          data.approvals.projectId = data.project.projectId;
          data.projectId = data.project.projectId;
          data.approvalStatus = data.approvals.approvalStatus.listTypeValueId;
          console.log(data);
          this.checkedList.push(data);
          console.log(this.checkedList);
        }
        this.approveDisabled = false;
        this.denyDisabled = false;
        this.loader = true;
        this.tableShow = false;
        this.EmpList = [];
        this.gs.updateBulkApprovalStatus(this.checkedList).subscribe((data) => {
          console.log(this.checkedList);
          console.log(data);
          // Swal.fire({
          //   icon: 'success',
          //   text: 'Denied',
          // });
          // this.loader = false;
          // window.location.reload();

          if ((data as any).statusCode == 200) {
            this.successMessage = 'Denied Successfully';
            this.success = true;
            this.loader = false;
            this.tableShow = false;
            setTimeout(() => {
              this.success = false;
              this.successMessage = '';
            }, 3000);
          } else {
            this.successMessage = '';
            this.success = false;
          }

          this.projectId = '';
          this.selectedemployee = '';
          this.startDate = '';
          this.endDate = '';
        });
      }
    });
  }
  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  getTask() {
    // if (this.projectId) {

    // this.employeeNotFound = false;
    // this.nodata = false;
    // this.disable_project = false;
    // this.project_required = false;
    // this.selectedemployeeId = undefined;

    // this.employeeId = undefined;
    // // this.employeeProjects.length = 0;
    // this.employee = undefined;
    // this.employeeNumber = undefined;
    // this.selectedemployee = undefined;
    // this.selectedemployeeId = undefined;

    this.employeeNotFound = false;
    if (this.projectId) {
      this.project_required = false;
      let matchFound = false;
      this.gs.getEmployeeProject(this.projectId).subscribe(
        (data: any) => {
          console.log('data', data);

          this.employeelist = data;
          //   for (let index = 0; index < this.employeelist.length; index++) {
          //     this.checkEmployeeIds = [];
          //     if (this.employeelist[index]?.employeeId === this.employeeId) {
          //         this.checkEmployeeIds.push(this.employeelist[index]?.employeeId);
          //     }
          //     console.log('currentEmployee', this.checkEmployeeIds);
          //     console.log('array',this.employeelist);
          //     console.log('this.employeelist[index]', this.checkEmployeeIds)
          //     console.log('this.employeeId', this.employeeId);

          //     if ( this.checkEmployeeIds[index] === this.employeeId) {
          //         // if (this.selectedemployeeId === undefined) {
          //         //     this.selectedemployee = this.employee;
          //         // }
          //         matchFound = true;
          //         break; // Exit the loop as we found a match
          //     }

          //     console.log('this.selectedemployee',this.selectedemployee);

          //     if(this.selectedemployee  != undefined){

          //       if(this.checkEmployeeIds[index] != this.employeeId){

          //         if (this.employeeId != undefined && this.selectedemployee != undefined) {

          //           this.selectedemployee = undefined;
          //           this.employeeNotFound = false;
          //           this.employeeProjects.length = 0;
          //           this.employeeId = undefined;
          //           this.employee = undefined;
          //           this.employeeNumber = undefined;
          //           matchFound = false;
          //           break;
          //         }

          //       }
          //     }

          // }

          // this.employeelist.push({data})

          // this.employeeId.map((element , index) => {
          if (this.selectedEmployeeSearch != undefined) {
            let hasemployee = this.employeelist.some(
              (item) => item.employeeId === this.employeeId
            );
            // console.log("hasemployee" + hasemployee);
            // console.log("index" + index);
            if (!hasemployee) {
              this.selectedemployee = undefined;
              this.selectedEmployeeSearch = undefined;

              this.selectedemployee = undefined;
              this.employeeNotFound = false;
              this.employeeProjects.length = 0;
              this.employeeId = undefined;
              this.employee = undefined;
              this.employeeNumber = undefined;
              matchFound = false;
            }
          }
          //  })
        },
        (error) => {
          console.error('Error fetching employee data:', error);
          // Handle the error (e.g., show an error message to the user)
        }
      );
    } else {
      this.employeelist.length = 0;
    }

    // }
  }

  searchTimesheet() {
    console.log(
      'projects',
      this.projectId,
      this.selectedemployee,
      this.startDate,
      this.endDate
    );

    this.isMasterSel = false;
    
    if (!this.selectedEmployeeSearch) {
      this.search = '';
    }
    // this.searchButton = false;
    // this.selectedemployee = this.employee;
    if (!this.selectedEmployeeSearch) {
      this.selectedemployee = undefined;
    }

    this.changefirstpage();
    this.checkedCategoryList = [];
    this.nodata = false;
    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');
    this.EmpList.length = 0;

    if (this.projectId == 0 || this.projectId == undefined) {
      this.employee_required = true;

      this.project_required = true;
      return;
    } else if (this.endDate === undefined && this.startDate !== undefined) {
      // alert("select end date !...")
      this.enddate_required = true;
    } else if (this.startDate > this.endDate) {
      alert('the start date should be greter than end date');
    } else if (this.startDate === undefined && this.endDate !== undefined) {
      // alert("select start date ")
      this.startdate_required = true;
    } else {
      // this.project_required = false;
      console.log('else came out');
    }
    this.employee_required = false;
    if (this.startDate != undefined && this.endDate == undefined) {
      this.startdate_required = false;
      this.enddate_required = true;
      return;
    }

    if (this.startDate == undefined && this.endDate != undefined) {
      this.startdate_required = true;
      this.enddate_required = false;
      return;
    }
    // this.EmpList.length = 0;
    if (this.selectedemployeeId == undefined) {
      if (this.startDate == undefined && this.endDate == undefined) {
        console.log('project only');
        this.loader = true;
        this.searchButton = false;
        this.tableShow = false;
        // if (this.selectedemployeeId ) {
        //   this.selectedemployee = this.employee;
        // }
        this.gs
          .getTaskDetailByProject(this.projectId, this.approval_Status)
          .subscribe(
            (data: any) => {
              console.log('dataaaaaaaaa', data);
              // this.EmpList = data;
              // this.nodata = false;
              this.loader = false;
              this.approvers(data);
              this.tableShow = true;
              // this.nodata = true;
              this.searchButton = true;
              this.employeeNotFound = false;
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              // if (this.EmpList[0].message == 'No Data') {
              //   this.nodata = true;
              //   this.loader = false;
              //   console.log('if entered no dataa!');
              // }
            },
            (error) => {
              // this.EmpList.length = 0;
              // this.nodata = true;
              // console.log("404");
              this.loader = false;
              console.log('error', error);

              console.log(this.loader);
              if (error.status == 404) {
                this.nodata = true;
                this.loader = false;
              }
              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }

              if (error.status === 500) {
                this.nodata = true;
                this.loader = false;
              }
            }
          );
      } else {
        this.searchButton = false;
        if (this.startDate == undefined || this.endDate == undefined) {
          if (this.startDate == undefined) {
            this.startdate_required = true;
          } else if (this.endDate == undefined) {
            this.enddate_required = true;
          }
          return;
        }
        console.log(this.startDate);
        this.enddate_required = false;
        this.startdate_required = false;
        console.log('project and dates');
        this.loader = true;
        this.tableShow = false;
        this.gs
          .taskDetailByEmployees(
            this.projectId,
            this.approval_Status,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              console.log(data);

              if (data?.errorCode === 204 || data?.message === 'No Data') {
                this.nodata = true;
                this.loader = false;
              }
              // this.EmpList.length = 0;
              // this.EmpList.push(data);
              // this.nodata = false;
              this.loader = false;
              // if (this.EmpList.length === 0) {
              //   this.nodata = true;
              //   this.loader = false;
              // }

              // if (this.EmpList[0].message == 'No Data') {
              //   this.nodata = true;
              //   console.log('if entered no dataa!');
              // }
              this.approvers(data);
              this.employeeNotFound = false;
              this.tableShow = true;
              this.searchButton = true;
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
            },
            (error) => {
              // this.EmpList.length = 0;
              if (error.status === 500) {
                this.nodata = true;
                this.loader = false;
              }
              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              console.log('404');
              this.loader = false;
              console.log(this.loader);
              if (error.status == 404) {
                this.nodata = true;
                this.loader = false;
              }
            }
          );
      }
    } else {
      console.log('project .employee');

      this.loader = true;
      if (this.startDate == undefined && this.endDate == undefined) {
        this.searchButton = false;
        this.tableShow = false;
        if (this.selectedemployeeId) {
          this.selectedemployee = this.employee;
        }
        this.gs
          .getManagereportByEmployeeIdAndProjectId(
            this.projectId,
            this.selectedemployeeId,
            this.approval_Status
          )
          .subscribe(
            (data: any) => {
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              console.log('Based on EmployeeId', data);
              // this.EmpList.length = 0;
              // this.EmpList.push(data);
              this.loader = false;
              // if (this.EmpList[0].message == 'No Data') {
              //   this.nodata = true;
              //   console.log('if entered no dataa!');
              // }
              this.approvers(data);
              this.employeeNotFound = false;
              this.tableShow = true;
              this.searchButton = true;
            },
            (error) => {
              this.EmpList.length = 0;
              // this.nodata = true;
              if (error.status === 500) {
                this.nodata = true;
                this.loader = false;
              }
              console.log('404');
              this.loader = false;
              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              console.log(this.loader);
              if (error.status == 404) {
                this.nodata = true;
                this.loader = false;
              }
            }
          );
      } else {
        console.log('all 4');
        this.loader = true;
        this.searchButton = false;
        this.tableShow = false;
        if (this.selectedemployeeId) {
          this.selectedemployee = this.employee;
        }
        this.gs
          .getManagereportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
            this.selectedemployeeId,
            this.projectId,
            this.approval_Status,
            this.startDate,
            this.endDate
          )
          .subscribe(
            (data: any) => {
              this.nodata = false;
              console.log(data);
              // data.length > 0 ? (this.nodata = false) : (this.nodata = true);
              if (data.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              // this.EmpList.length = 0;
              // this.EmpList.push(data);
              this.loader = false;
              // if (this.EmpList[0].message == 'No Data') {
              //   this.nodata = true;
              //   console.log('if entered no dataa!');
              // }
              this.approvers(data);
              this.tableShow = true;
              this.searchButton = true;
              this.employeeNotFound = false;
            },
            (error) => {
              console.log('error', error);
              if (error.status === 500) {
                this.nodata = true;
                this.loader = false;
              }
              this.EmpList.length = 0;
              if (error.errorCode === 204) {
                this.nodata = true;
                this.loader = false;
              }
              console.log('404');
              this.loader = false;
              console.log(this.loader);
              if (error.status == 404) {
                this.nodata = true;
                this.loader = false;
              }
            }
          );
      }
    }
  }

  changepage() {
    if (this.search.length != 0) {
      this.p = 1;
    } else {
      this.p = this.beforesearchpage;
    }
  }

  getTotalEstimatedTime(tasks: any[]): string {
    if (!tasks) {
      return '00:00:00'; // or any default value you prefer
    }

    let totalHours = 0;
    let totalMinutes = 0;

    tasks.forEach((task) => {
      totalHours += task.estimatedHours || 0;
      totalMinutes += task.estimatedMinutes || 0;
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes
      .toString()
      .padStart(2, '0')}:00`;
  }

  changePlaceholderColor(event): void {
    // if (event.target.value) {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.remove('search-menu');
    // } else {
    //   const input = event.target as HTMLInputElement;
    //   input.classList.add('black-placeholder');
    // }
  }

  resetForms() {
    this.employeeProjects = [];
    this.checkedCategoryList = [];
    this.selectedemployeeId = undefined;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.projectId = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.EmpList.length = 0;
    this.showEmptyData = true;
    this.nodata = false;
    this.startdate_required = false;
    this.enddate_required = false;
    this.project_required = false;
    this.isMasterSel = false;
    this.searchButton = true;
    this.tableShow = false;
  }

  changefirstpage() {
    this.p = 1;
  }
  x: boolean;
  sort() {
    this.x = !this.x;
    if (this.x == true) {
      this.EmpList.sort(function (a: any, b: any) {
        return b.empNo - a.empNo;
      });
    } else {
      this.EmpList.sort(function (a: any, b: any) {
        return a.empNo - b.empNo;
      });
    }
  }
  withDrawStatus(i) {
    if (i?.task?.taskStatusName == null) {
      return;
    }
    if (i?.task?.taskStatusName == 'To Do') {
      return '#ffc107';
    }
    if (i?.task?.taskStatusName == 'Completed') {
      return '#28a745';
    }
    if (i?.task?.taskStatusName == 'In Progress') {
      return '#f44336';
    }
  }
  approvalStatus(i) {
    if (i?.approvalStatusName == null) {
      return;
    }
    if (i?.approvalStatusName == 'Pending') {
      return '#ffc107';
    }
    if (i?.approvalStatusName == 'Approved') {
      return '#28a745';
    }
    if (i?.approvalStatusName == 'Denied') {
      return '#f44336';
    }
  }

  Approve(i) {
    let approverlevel;
    Swal.fire({
      title: 'Are you sure? Do you want to approve',

      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve!',
    }).then((result) => {
      console.log(' this.empId', this.empId);

      if (result.value) {
        i['organization'] = {
          organizationId: this.organizationId,
        };

        let count = 0;
        if (i.approvals.approver.approverOne) {
          count = count + 1;
        }
        if (i.approvals.approver.approverTwo) {
          count = count + 1;
        }
        if (i.approvals.approver.approverThree) {
          count = count + 1;
        }
        if (i.approvals.approver.approverFour) {
          count = count + 1;
        }
        if (i.approvals.approver.approverFive) {
          count = count + 1;
        }

        // delete i.approvals.approvalStatus;

        if (
          i.approvals.approver.approverOne == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5
        ) {
          i.approvals.approverOneStatus.listTypeValueId = 5;

          i.approvals.approverlevel = 1;
        }
        if (
          i.approvals.approver.approverOne == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 3
        ) {
          i.approvals.approverOneStatus.listTypeValueId = 5;

          i.approvals.approverlevel = 1;
        }

        if (
          i.approvals.approver.approverTwo == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 3
        ) {
          i.approvals.approverTwoStatus.listTypeValueId = 5;

          i.approvals.approverlevel = 2;
        }
        if (
          i.approvals.approver.approverThree == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 3
        ) {
          i.approvals.approverThreeStatus.listTypeValueId = 5;

          i.approvals.approverlevel = 3;
        }
        if (
          i.approvals.approver.approverFour == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 5 &&
          i.approvals.approverFourStatus.listTypeValueId == 3
        ) {
          i.approvals.approverFourStatus.listTypeValueId = 5;

          i.approvals.approverlevel = 4;
        }
        if (
          i.approvals.approver.approverFive == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 5 &&
          i.approvals.approverFourStatus.listTypeValueId == 5 &&
          i.approvals.approverFiveStatus.listTypeValueId == 3
        ) {
          i.approvals.approverFiveStatus.listTypeValueId = 5;
          i.approvals.approvalStatus.listTypeValueId = 5;
          i.approvals.approverlevel = 5;
        }

        if (count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5) {
          i.approvals.approvalStatus.listTypeValueId = 5;
        }
        if (
          count == 2 &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5
        ) {
          i.withdrawStatus = 5;
          i.approvals.approvalStatus.listTypeValueId = 5;
        }
        if (
          count == 3 &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 5
        ) {
          i.withdrawStatus = 5;
          i.approvals.approvalStatus.listTypeValueId = 5;
        }
        if (
          count == 4 &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 5 &&
          i.approvals.approverFourStatus.listTypeValueId == 5
        ) {
          i.withdrawStatus = 5;
          i.approvals.approvalStatus.listTypeValueId = 5;
        }
        if (
          count == 5 &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 5 &&
          i.approvals.approverFourStatus.listTypeValueId == 5 &&
          i.approvals.approverFiveStatus.listTypeValueId == 5
        ) {
          i.withdrawStatus = 5;
          i.approvals.approvalStatus.listTypeValueId = 5;
        }

        // if (
        //   count == 5 &&
        //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
        //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        //   i.approvals.approverThreeStatus.listTypeValueId == 5 &&
        //   i.approvals.approverFourStatus.listTypeValueId == 5 &&
        //   i.approvals.approverFiveStatus.listTypeValueId == 5
        // ) {
        //   i.approvals.approvalStatus.listTypeValueId = 5;
        //   console.log('approvalstatus5', i.approvals.approvalStatus);
        // }

        i.approvals.employeeId = i.employee.employeeId;
        i.approvals.projectId = i.project.projectId;
        i.projectId = i.project.projectId;
        i.approvalStatus = i.approvals.approvalStatus.listTypeValueId;

        console.log('approval status', i.approvalStatus);

        // i.approvals.approverlevel =   i.approvals.approverlevel;
        // i['approvals'] = {
        //   // employeeId:2,
        //   // project:2,
        //   // approverlevel:2
        // }

        // i.approvals.employeeId = i.employee.employeeId;
        // i.approvals.project = i.project.projectId;
        // i.approvals.approverlevel = approverlevel;

        console.log('i', i);
        this.checkedList.push(i);
        console.log(this.checkedList);
        this.approveDisabled = false;
        this.denyDisabled = false;
        this.loader = true;
        this.tableShow = false;
        this.EmpList = [];
        this.gs.updateBulkApprovalStatus(this.checkedList).subscribe((a) => {
          // Swal.fire({
          //   icon: 'success',
          //   text: 'Approved',
          // });

          if ((a as any).statusCode == 200) {
            this.successMessage = 'Approved Successfully';
            this.success = true;
            this.loader = false;
            setTimeout(() => {
              this.success = false;
              this.successMessage = '';
            }, 3000);
            this.tableShow = false;
          } else {
            this.successMessage = '';
            this.success = false;
          }

          this.projectId = '';
          this.selectedemployee = '';
          this.startDate = '';
          this.endDate = '';

          // window.location.reload();
          //       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // const currentUrl = this.router.url;
          // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          //     this.router.navigateByUrl(currentUrl);
          // });
        });
      }
    });
  }
  Deny(i) {
    console.log('i', i);

    console.log('this.emplist', this.EmpList);

    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let emp = x.employeeId;
    Swal.fire({
      title: 'Are you sure? Do you want to deny',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Deny!',
    }).then((result) => {
      console.log('result', result);

      if (result.value) {
        i['organization'] = {
          organizationId: this.organizationId,
        };

        console.log(
          'i.approvals.approver.approverOne == this.empId',
          i.approvals.approver.approverOne,
          this.empId
        );
        console.log(
          'i.approvals.approverOneStatus.listTypeValueId == 3',
          i.approvals.approverOneStatus.listTypeValueId
        );
        if (
          i.approvals.approver.approverOne == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 3
        ) {
          // i.approvals.approver = 6;
          // i.approvals.approver = 6;
          // i.approvals.approver={
          //   approverId: 1
          // } ;
          // "approver": {
          //   "approverId": 172
          // },

          i.approvals.approverOneStatus.listTypeValueId = 6;
          i.approvals.approvalStatus.listTypeValueId = 6;
          i.approvals.approverlevel = 1;
          console.log('i.approvals.approver = 6', i.approvals.approver);
        }
        if (
          i.approvals.approver.approverTwo == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5
        ) {
          i.approvals.approverTwoStatus.listTypeValueId = 6;
          i.approvals.approvalStatus.listTypeValueId = 6;
          i.approvals.approverlevel = 2;
          console.log(
            '  i.approvals.approverTwoStatus.listTypeValueId',
            i.approvals.approverTwoStatus.listTypeValueId
          );
          console.log(
            '   i.approvals.approvalStatus.listTypeValueId',
            i.approvals.approvalStatus.listTypeValueId
          );
          console.log('i.approvals.approverlevel', i.approvals.approverlevel);
        }
        console.log(
          '  i.approvals.approver.approverThree',
          i.approvals.approver.approverThree,
          this.empId
        );
        console.log(
          '     i.approvals.approverOneStatus.listTypeValueId  5',
          i.approvals.approverOneStatus.listTypeValueId
        );
        console.log(
          '  i.approvals.approverTwoStatus.listTypeValueId 5',
          i.approvals.approverTwoStatus.listTypeValueId
        );

        if (
          i.approvals.approver.approverThree == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5
        ) {
          i.approvals.approverThreeStatus.listTypeValueId = 6;
          i.approvals.approvalStatus.listTypeValueId = 6;
          i.approvals.approverlevel = 3;
        }
        if (
          i.approvals.approver.approverFour == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 5
        ) {
          i.approvals.approverFourStatus.listTypeValueId = 6;
          i.approvals.approvalStatus.listTypeValueId = 6;
          i.approvals.approverlevel = 4;
        }

        if (
          i.approvals.approver.approverFive == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 3 &&
          i.approvals.approverTwoStatus.listTypeValueId == 3 &&
          i.approvals.approverThreeStatus.listTypeValueId == 3 &&
          i.approvals.approverFourStatus.listTypeValueId == 3
        ) {
          i.approvals.approverFiveStatus.listTypeValueId = 6;
          i.approvals.approvalStatus.listTypeValueId = 6;
          i.approvals.approverlevel = 5;
        }

        if (
          i.approvals.approver.approverFive == this.empId &&
          i.approvals.approverOneStatus.listTypeValueId == 5 &&
          i.approvals.approverTwoStatus.listTypeValueId == 5 &&
          i.approvals.approverThreeStatus.listTypeValueId == 5 &&
          i.approvals.approverFourStatus.listTypeValueId == 5
        ) {
          i.approvals.approverFiveStatus.listTypeValueId = 6;
          i.approvals.approvalStatus.listTypeValueId = 6;
          i.approvals.approverlevel = 5;
        }

        i.approvals.employeeId = i.employee.employeeId;
        i.approvals.projectId = i.project.projectId;
        i.projectId = i.project.projectId;
        i.approvalStatus = i.approvals.approvalStatus.listTypeValueId;

        console.log('i', i);

        this.checkedList.push(i);
        console.log(this.checkedList);
        this.approveDisabled = false;
        this.denyDisabled = false;
        this.loader = true;
        this.tableShow = false;
        this.EmpList = [];
        this.gs.updateBulkApprovalStatus(this.checkedList).subscribe((a) => {
          // Swal.fire({
          //   icon: 'success',
          //   text: 'Denied',
          // });
          // this.loader = false;
          // window.location.reload();

          if ((a as any).statusCode == 200) {
            this.successMessage = 'Denied Successfully';
            this.success = true;
            this.loader = false;
            setTimeout(() => {
              this.success = false;
              this.successMessage = '';
            }, 3000);
            this.tableShow = false;
          } else {
            this.successMessage = '';
            this.success = false;
          }

          this.projectId = '';
          this.selectedemployee = '';
          this.startDate = '';
          this.endDate = '';
        });
      }
    });
  }
  //   approvers(y) {

  //     console.log('y',y);

  //     // console.log('empDatannnnn',empData);

  //     this.EmpList.length = 0;
  //     // empData.map((y) => {
  //       // console.log('y',y);
  //       // for (let i = 0; i < empData.length; i++) {
  //         // console.log('y.approval.approver.fyi',y.approvals.approver.approverOne.fyi);
  //         // if (y.approval.approver.fyi == this.empId) {
  //         // this.EmpList.push(y);

  //         // console.log('this.EmpListttttttttttttt',this.EmpList);

  //         // }

  //         // if (
  //         //   y.approvals.approver.approverOne == this.empId &&
  //         //   y.approvals.approver.approverOne.approverOneStatus == 3 &&
  //         //   y.approver.approverOne
  //         // ) {
  //         //   this.EmpList.push(y);
  //         // }
  //         // if (
  //         //   y.approvals.approver.approverOne.approverTwo == this.empId &&
  //         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverTwoStatus == 3
  //         // ) {
  //         //   this.EmpList.push(y);
  //         // }
  //         // if (
  //         //   y.approvals.approver.approverOne.approverThree == this.empId &&
  //         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverThreeStatus == 3
  //         // ) {
  //         //   this.EmpList.push(y);
  //         // }
  //         // if (
  //         //   y.approvals.approver.approverOne.approverFour == this.empId &&
  //         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverThreeStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverFourStatus == 3
  //         // ) {
  //         //   this.EmpList.push(y);
  //         //   console.log(this.EmpList);
  //         //   console.log('approver Four data');
  //         // }
  //         // if (
  //         //   y.approvals.approver.approverOne.approverFive == this.empId &&
  //         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverThreeStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverFourStatus == 5 &&
  //         //   y.approvals.approver.approverOne.approverFiveStatus == 3
  //         // ) {
  //         //   this.EmpList.push(y);
  //         // }

  // // console.log(' y.approvals.approver.approverOne', y.approvals.approver.approverOne,this.empId,  y.approvals.approverOneStatus.listTypeValueId );

  // for (let i = 0; i < y.length; i++) {

  //   console.log('cjeck u',y[i]);

  //         if (
  //           y[i].approvals?.approver?.approverOne == this.empId &&
  //           y[i].approvals?.approverOneStatus?.listTypeValueId == 5
  //         ) {

  //           // y[i].approvals.approverOneStatus.listTypeValueId = 5;

  //           // y[i].approvals.approverlevel = 1;
  //        this.EmpList.pop();

  //           this.EmpList.push(y[i]);
  //         }
  //         if (
  //           y[i]?.approvals?.approver?.approverOne == this.empId &&
  //           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 3
  //         ) {

  //             console.log('y.approvals.approver.approverOne' ,3);
  //             console.log(' y.approvals.approver.approverOne',  y[i].approvals.approver.approverOne,this.empId,   y[i].approvals.approverOneStatus.listTypeValueId );

  //             this.EmpList.pop();

  //           this.EmpList.push(y);
  //           console.log('this.EmpList',y);
  //           console.log('this.EmpList',this.EmpList);

  //           // y[i].approvals.approverOneStatus.listTypeValueId = 5;

  //           // y[i].approvals.approverlevel = 1;
  //           // this.EmpList =  y;
  //         }

  //         if (
  //           y[i]?.approvals?.approver?.approverTwo == this.empId &&
  //           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 3
  //         ) {

  //           this.EmpList.pop();
  //           this.EmpList.push(y[i])
  //           // y[i].approvals.approverTwoStatus.listTypeValueId = 5;

  //           // y[i].approvals.approverlevel = 2;
  //           // this.EmpList.push(y);
  //         }
  //         if (
  //           y[i]?.approvals?.approver?.approverThree == this.empId &&
  //           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 3
  //         ) {

  //           this.EmpList.pop();
  //           this.EmpList.push(y[i]);
  //           // y[i].approvals.approverThreeStatus.listTypeValueId = 5;

  //           // y[i].approvals.approverlevel = 3;
  //           // this.EmpList.push(y);
  //         }
  //         if (
  //           y[i]?.approvals?.approver?.approverFour == this.empId &&
  //           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverFourStatus?.listTypeValueId == 3
  //         ) {
  //           this.EmpList.pop();
  //           this.EmpList.push(y[i]);
  //           // y[i].approvals.approverFourStatus.listTypeValueId = 5;

  //           // y[i].approvals.approverlevel = 4;
  //           // this.EmpList.push(y);
  //         }
  //         if (
  //           y[i]?.approvals?.approver?.approverFive == this.empId &&
  //           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverFourStatus?.listTypeValueId == 5 &&
  //           y[i]?.approvals?.approverFiveStatus?.listTypeValueId == 3
  //         ) {

  //           this.EmpList.pop();
  //           this.EmpList.push(y[i]);
  //           // y[i].approvals.approverFiveStatus.listTypeValueId = 5;
  //           // y[i].approvals.approvalStatus.listTypeValueId = 5;
  //           // y[i].approvals.approverlevel = 5;
  //           // this.EmpList.push(y);
  //         }
  //       }
  //         if (Array.isArray(this.EmpList)) {
  //         this.EmpList = this.EmpList.reduce((accumalator, current) => {
  //           if (!accumalator.some((item) => item === current)) {
  //             accumalator.push(current);
  //           }
  //           return accumalator;
  //         }, []);
  //       }
  //       // }
  //     // });

  //     if (this.EmpList.length == 0) {
  //       this.nodata = true;
  //     }

  //     console.log('timesheet' + this.EmpList.length);
  //   }

  selectProject() {
    this.projectId = undefined;
  }

  approvers(empData) {
    // console.log('empData',empData);
    if (!Array.isArray(empData)) {
      console.error('empData is not an array');
      return;
    }
    this.EmpList.length = 0;
    const uniqueEmpList = new Set();
    const data = empData.filter((y) => {
      console.log('y', y);
      for (let i = 0; i < empData.length; i++) {
        // console.log('y.approval.approver.fyi',y.approval.approver.fyi);
        if (y.approvals?.approver?.fyi == this.empId) {
          this.showMenu = false;
          this.EmpList.push(y);
          this.tableShow = true;

          if (this.EmpList.length == 0) {
            this.nodata = true;
          }
        }

        console.log('checking FYI ID',y?.approvals?.approver?.fyi);
        

        if (
          y?.approvals?.approver?.approverOne == this.empId &&
          y?.approvals?.approverOneStatus?.listTypeValueId == 3
        ) {
          // this.EmpList.pop();
          // this.EmpList.push(...data);
          // uniqueEmpList.add(y);

          console.log('y', y);

          console.log('approval 1 status 3');
          console.log('...uniqueEmpList', ...uniqueEmpList);
          this.EmpList.push(y);
          this.tableShow = true;
          if (this.EmpList.length == 0) {
            this.nodata = true;
          }
        }

        console.log('Approver2Id,EmpId', y?.approvals?.approver?.approverTwo , this.empId);

        if (
          y?.approvals?.approver?.approverTwo == this.empId &&
          y?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverTwoStatus?.listTypeValueId == 3
        ) {
          // this.EmpList.pop();
          // this.EmpList.push(...data);
          // uniqueEmpList.add(y);

          this.EmpList.push(y);
          this.tableShow = true;
          if (this.EmpList.length == 0) {
            this.nodata = true;
          }
        }
        if (
          y?.approvals?.approver?.approverThree == this.empId &&
          y?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverThreeStatus?.listTypeValueId == 3
        ) {
          // this.EmpList.pop();
          // this.EmpList.push(...data);
          // uniqueEmpList.add(y);

          this.EmpList.push(y);
          this.tableShow = true;
          if (this.EmpList.length == 0) {
            this.nodata = true;
          }
        }
        if (
          y?.approvals?.approver?.approverFour == this.empId &&
          y?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverFourStatus?.listTypeValueId == 3
        ) {
          // this.EmpList.pop();
          // this.EmpList.push(...data);
          // uniqueEmpList.add(y);

          this.EmpList.push(y);
          this.tableShow = true;
          if (this.EmpList.length == 0) {
            this.nodata = true;
          }
        }
        if (
          y?.approvals?.approver?.approverFive == this.empId &&
          y?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverFourStatus?.listTypeValueId == 5 &&
          y?.approvals?.approverFiveStatus?.listTypeValueId == 3
        ) {
          // this.EmpList.pop();
          // this.EmpList.push(...data);
          // uniqueEmpList.add(y);

          this.EmpList.push(y);
          this.tableShow = true;
          if (this.EmpList.length == 0) {
            this.nodata = true;
          }
        }
        this.EmpList = this.EmpList.reduce((accumalator, current) => {
          if (!accumalator.some((item) => item === current)) {
            accumalator.push(current);
          }
          return accumalator;
        }, []);
      }
    });

    if (this.EmpList.length == 0) {
      this.nodata = true;
    }

    console.log('timesheet' + this.EmpList.length);
  }

  // approvers(empData) {
  //   this.EmpList.length = 0;
  //   const uniqueEmpList = new Set(); // Using Set to ensure uniqueness

  //   empData.forEach(y => {
  //       if (
  //           (y.approvals.approver.approverOne === this.empId && y.approvals.approverOneStatus.listTypeValueId === 5) ||
  //           (y.approvals.approver.approverOne === this.empId && y.approvals.approverOneStatus.listTypeValueId === 3) ||
  //           (y.approvals.approver.approverTwo === this.empId && y.approvals.approverOneStatus.listTypeValueId === 5 && y.approvals.approverTwoStatus.listTypeValueId === 3) ||
  //           (y.approvals.approver.approverThree === this.empId && y.approvals.approverOneStatus.listTypeValueId === 5 && y.approvals.approverTwoStatus.listTypeValueId === 5 && y.approvals.approverThreeStatus.listTypeValueId === 3) ||
  //           (y.approvals.approver.approverFour === this.empId && y.approvals.approverOneStatus.listTypeValueId === 5 && y.approvals.approverTwoStatus.listTypeValueId === 5 && y.approvals.approverThreeStatus.listTypeValueId === 5 && y.approvals.approverFourStatus.listTypeValueId === 3) ||
  //           (y.approvals.approver.approverFive === this.empId && y.approvals.approverOneStatus.listTypeValueId === 5 && y.approvals.approverTwoStatus.listTypeValueId === 5 && y.approvals.approverThreeStatus.listTypeValueId === 5 && y.approvals.approverFourStatus.listTypeValueId === 5 && y.approvals.approverFiveStatus.listTypeValueId === 3)
  //       ) {
  //           uniqueEmpList.add(y); // Adding to Set for uniqueness
  //       }
  //   });

  //   this.EmpList.push(...uniqueEmpList); // Converting Set back to Array and pushing to EmpList

  //   this.nodata = this.EmpList.length === 0; // Update nodata flag

  //   console.log("timesheet" + this.EmpList.length);
  // }

  description(e) {
    console.log(e.description);
    const dialogRef = this.dialog.open(Descripe, {
      width: '500px',
      data: e,
    });
  }

  sortData(sort: Sort) {
    const data = this.EmpList.slice();
    if (!sort.active || sort.direction === '') {
      this.EmpList = data;
      return;
    }

    this.EmpList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employeeName':
          return this.compare(
            a.employee.firstName,
            b.employee.firstName,
            isAsc
          );
        case 'projectName':
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );
        case 'taskName':
          return this.compare(a.task.taskName, b.task.taskName, isAsc);

        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'workedHours':
          return this.compare(a.workedHours, b.workedHours, isAsc);
        case 'taskStatusName':
          return this.compare(
            a.task.taskStatusName,
            b.task.taskStatusName,
            isAsc
          );
        case 'estimatedHours':
          return this.compare(
            a.task.estimatedHours,
            b.task.estimatedHours,
            isAsc
          );

        case 'taskStatusName':
          return this.compare(
            a.task.taskStatusName,
            b.task.taskStatusName,
            isAsc
          );

        case 'approvalStatus':
          return this.compare(
            a.approvalStatusName,
            b.approvalStatusName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

@Component({
  selector: 'Descripe',
  templateUrl: './Descripe.html',
})
export class Descripe implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<Descripe>,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gs: GlobalserviceService
  ) {}

  ngOnInit() {}
}
