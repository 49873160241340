<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Groups</span>
                </div>
                <div class="sub-header-buttons">
                       <a  (click)="importemployeegroups()" mat-raised-button class="ml-2" title="Import">Import</a>
                    <a routerLink="/home/emp-groups" routerLinkActive="active" color="primary" mat-raised-button title="Add Employee Groups" class="ml-2">
                        Add Employee Groups
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="mb-2">
                    <div class="col-md-12 p-0">
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row mb-3 pl-0">
                                <div class="ol-xl-3 col-lg-3 mb-2">
                                    <!-- [(ngModel)]="tableSearch"  -->
                                    <input formControlName="keywords" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search...">
                                    <small class="text-muted">Note : Group Name</small>
                                </div>

                                <div class="col-xl-3 col-lg-3 mb-2">

                                    <select formControlName="clientId" class="form-select form-select-solid form-select-lg" placeholder="- Select Client - ">
                                        <option  value="null">- Select Client - </option>
                                      <option *ngFor="let row of clientList; let i=index;"  value="{{row.clientId}}">{{row.clientCode}}</option>
                                    </select>

                                
                        
                      
                                </div>

                                <div class="ol-xl-1 col-lg-1 mb-2">
                                    <button type="submit" mat-raised-button color="primary" class="btn btn-primary search-btn" mat-raised-button>Search</button>
                                    
                                </div>
                                <div class="ol-xl-1 col-lg-1 mb-2">
                                    <button  type="reset" mat-raised-button
                                     class="btn-clear  mr-2"
                                    (click)="clearTable()">Clear</button>
                                    </div>
                              
                            </div>
                        </form>
                        <div class="col-md-12 bvcbc">
                            <div class="row"> 
                                <div class="col-md-11 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-1 text-left pl-0">
                                    <select [(ngModel)]="pagePerItem" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <!-- <option value="1500">1500</option> -->
                                </select>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <!--  <div>
                    <app-double-scroll>
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                                <tr>

                                    <td>table cell</td>
                                    <td>table cell</td>
                                    <td>table cell</td>
                                    <td>table cell</td>
                                    <td>table cell</td>
                                    <td>table cell</td>
                                    <td>table cell</td>
                                    <td>table cell</td>
                                    <td>table cell</td>
                                </tr>
                            </tbody>
                        </table>
                    </app-double-scroll>
                </div> -->


                <div class="grid_view" >
                    <div class="row">
                        <div class="col-md-12">
                        <!-- /    <app-double-scroll> -->
                                <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                    <table matSort (matSortChange)="sortData($event)" class="table table-responsive1 table-bordered table-border-new1"  style="border-top-left-radius: 10px; border-top-right-radius: 10px;" >
                                        <thead class="t-head">
                                            <tr>
                                                <!-- <th class="text-center">S.No</th> -->
                                                <th class="text-center">Action</th>              
                                                <th mat-sort-header="employeeNumber">Employee Group Name</th>
                                                <th mat-sort-header="firstName" >Description</th>
                                                <th class="text-center" style="width:10%;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="resultData?.length > 0 ">
                                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p }  let i=index;">
                                                <!-- <td class="text-center">{{i+1}}</td> -->
                                                <td class="text-center">
                                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>

                                                    <mat-menu #menu="matMenu" class="controls-list">
                                                        <button mat-menu-item routerLink="/home/emp-groups/{{row.employeeGroupId}}" routerLinkActive="active"> <!-- (click)="updateform(row)" -->
                                                    <mat-icon>edit</mat-icon>
                                                    <span>Edit</span>
                                                </button>

                                                        <button mat-menu-item (click)="viewemployees(row)" routerLinkActive="active">
                                                    <mat-icon>visibility</mat-icon>
                                                    <span>View Employees</span>
                                                </button>


                                                        <button mat-menu-item *ngIf="row.employeeGroupStatus.listTypeValueId == 1" (click)="updateStatus(row.employeeGroupId,2)">
                                                    <mat-icon>done</mat-icon>
                                                    <span>In-Active</span>
                                                </button>

                                                        <button mat-menu-item *ngIf="row.employeeGroupStatus.listTypeValueId == 2" (click)="updateStatus(row.employeeGroupId,1)">
                                                    <mat-icon>done</mat-icon>
                                                    <span>Active</span>
                                                </button>
                                                    </mat-menu>
                                                </td>
                                             
                                                <td >{{row?.employeeGroupName}}</td>
                                                <td>{{row?.employeeGroupDescription}}</td>
                                                <!-- <td class="text-center">{{employee.status.listTypeValueName}}</td> -->
                                                <td class="active-status text-center">
                                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row?.employeeGroupStatus.listTypeValueId == 1">
                                                    {{row?.employeeGroupStatus.listTypeValueName}}
                                                </span>

                                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row?.employeeGroupStatus.listTypeValueId == 2">
                                                    {{row?.employeeGroupStatus.listTypeValueName}}
                                                </span>
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style="text-align: center;" colspan="12" *ngIf="nodatafound">No Records Found!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                 
                                </div>
                            <!-- </app-double-scroll> -->
                        </div>
                    </div>
                </div>


            
                <div *ngIf="resultData?.length > 0 ">
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <div class="showing-record">
                                <!-- <ng-template pTemplate="paginatorleft" let-state>
                                    Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                                </ng-template> -->
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records</button>
</div>
