import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

@Component({
  selector: 'app-onboardingdetail',
  templateUrl: './onboardingdetail.component.html',
  styleUrls: ['./onboardingdetail.component.scss']
})
export class OnboardingdetailComponent implements OnInit {

  loader:any= true;
  onboardingobj:any;
  onboardingId:any;

  constructor(
    private activatedRoute : ActivatedRoute,
    private onboardingservice:HrmsserviceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log("this.activatedRoute.snapshot.params::",this.activatedRoute.snapshot.params);
    
    console.log("this.activatedRoute.snapshot.params.onboardingId::",this.activatedRoute.snapshot.params.onboardingId);
    
    this.onboardingId = this.activatedRoute.snapshot.params.onboardingId;
    console.log(this.onboardingId);
    this.onboardingservice
    .onboardingbyid(this.activatedRoute.snapshot.params.onboardingId)
    .subscribe((data:any) =>
    {
      this.onboardingobj = data;
      this.loader = false;
      console.log(this.onboardingobj);

    })
  }

  back()
  {
    this.router.navigate(['home/onboardings'])
  }

}
