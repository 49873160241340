import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Console } from 'console';
import { EMPTY, Subject, catchError, takeUntil } from 'rxjs';
// import { MyErrorStateMatcher } from 'src/app/Authenticaton/login/login.component';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { LeaveService } from 'src/app/providers/leave.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-leave-adjustment',
  templateUrl: './leave-adjustment.component.html',
  styleUrls: ['./leave-adjustment.component.scss']
})
export class LeaveAdjustmentComponent implements OnInit {

  isLoading: boolean;
  leaveForm:FormGroup;
  employeeIdList:any = [];
  employeeValue:any;
  leaveTypeList:any = [];
  leaveDays:any;
  length:any;
  empId: any;
  organizationId: any;
  employeeId: any;
  employeeNumber: any;
  employee: any;
  submitted = false;
  sucess_msg: any;
  success = false;

  error = false;
  error_msg: any;
  selectedemployee: any;
  selectedemployeeId: any;
  employeeNotFound: boolean;
  unsubscribe$: any;
  all_null: boolean=false;
  searchButton:boolean=true;
  pasteCount: any;


  constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private leaveservice:LeaveService
    ) { }

  ngOnInit(): void {
    this.length = 4;
    this.isLoading = false;
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;

    // this.gs.getEmpList().subscribe(
    //   (data:any) =>{
    //     this.employeeIdList = data;
    //   }
    // )

    // OverAll Employee Active List in Dropdown
    // this.leaveservice.getEmpList1().subscribe(
    //   (e: any[]) => {
    //     console.log(e);
    //     this.employeeIdList=e;
    //     console.log("Active Employee Status");
    //     console.log(this.employeeIdList);
    //   }
    // )

    // LeaveType
    // this.gs.getLeaveType().subscribe(
    //   (d: any) => {
    //     d.map(
    //       e=>{
    //         if(e.status== 1){
    //           this.leaveTypeList.push(e);
    //         }
    //         console.log(this.leaveTypeList);
    //       }
    //     )

    //   }
    // )

    this.leaveForm = this.fb.group({
      employeeId:['',Validators.required],
      employeeName:[''],
      leaveBalance:[null,Validators.required],
      leaveadjustmenttype:['',Validators.required],
      leaveadjustmentdays:['',Validators.required],
      leaveadjustmentreason:['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]
      ]
    })
  }
  //  keyFunc(event: Event) {
  //   const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
  //   let inputValue = inputElement.value;
  //   this.leaveservice.getEmployeeListKeywords(this.organizationId,inputValue).subscribe(
  //   (resultData: any) =>{
  //     this.employeeIdList= resultData;
  //   })
  // }
  get f(){
    return this.leaveForm.controls;
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    const inputElement = event.target;
    const currentValue = inputElement.value;
  
    // Check if the input character is not a number and not a dot
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      return;
    }
  
    // Allow dot only if there is a numeric expression before it
    if (inputChar === '.') {
      if (currentValue === '' || currentValue.endsWith('.')) {
        event.preventDefault();
        return;
      }
    }
  }

  leave(d){
    this.leaveDays = d;
  }

  empValue(e,id){
    this.employeeValue = e;
    this.employeeId = id;
    let x = this.leaveForm.value;
    x.leaveBalance = ''
    this.leaveservice.getLeaveBalanceById(id).subscribe(
      (data:any) =>{
        this.leaveTypeList = data;
      }
    )
  }

  leaveData(e){
    let x = this.leaveForm.value;
    if(x.leaveadjustmenttype == "add"){
      if(e.target.value >= 22){
        Swal.fire({
          text:'More than 22 days not accepted',
        })
      }
    }

    if(x.leaveadjustmenttype == "detect"){
      if(e.target.value > this.leaveDays){
        Swal.fire({
          text:'Adjustment Days are more than leave balance',
        })
      }
    }

  }
  empSelect(emp){
    console.log(emp);
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
    this.employeeNumber = emp.employeeNumber;
    this.employeeValue = emp.firstName;
    let x = this.leaveForm.value;
    x.leaveBalance = ''
    this.leaveservice.getLeaveBalanceById(this.employeeId).subscribe(
      (data:any) =>{
        if(data.statusCode == 204){
          this.leaveTypeList = [];
        }
        else{
          this.leaveTypeList = data;
        }
      }
    )
    this.leaveForm.patchValue({ 
      employeeId: this.employee 
    });

  }

  onSubmit(){
        this.searchButton=true;

    this.submitted =true;

    if (!this.employee && this.leaveForm.value.employeeId.length !=0 ){
      this.all_null=true;
      
        return;
    }else{
      this.all_null=false;
    }
  
    if (!this.employee || this.employee.length === 0) {
      this.leaveForm.get('employeeId')?.setErrors({ 'required': true });
      setTimeout(() => {
  
        if (this.leaveForm.get('employeeId')?.errors?.required) {
          this.leaveForm.get('employeeId')?.setErrors(null);
        }
      },8000);
      return ;
    }
    console.log(this.leaveForm);
    if(this.leaveForm.invalid){
      this.searchButton=true;

      return
    }
    let x = this.leaveForm.value;


    if(x.leaveadjustmenttype == "add"){
      if(x.leaveadjustmentdays >= 22){
        Swal.fire({
          text:'More than 21 days not accepted'
        })
        return
      }
    }

    if(x.leaveadjustmenttype == "detect"){
      if(x.leaveadjustmentdays > this.leaveDays){
        Swal.fire({
          text:'Adjustment Days are more than leave balance'
        })
        return
      }
    }

    // x['employee'] = {
      x.employeeId= this.employeeId
    // }

    x['leaveBalance'] = {
      'leaveBalanceId' : Number(x.leaveBalance)
    }

    x['leaveadjustmentdays'] = Number(x.leaveadjustmentdays)

    // console.log(JSON.stringify(x))
    this.isLoading = true;
    this.searchButton=false;

    this.leaveForm.patchValue({ 
      employeeId: this.employee 
    });

    this.leaveservice.addAdjustment(x).subscribe(
      (data:any) =>{
        // Swal.fire({
        //   text:'LeaveBalanceAdjustment Created Successfully',
        //   icon:'success'
        // })
      //   this.isLoading = false;
      //   this.router.navigate(['home/leave-adjustment-list'])
      // },
      // (error:any)=>{
      //   console.log(error)
      //   Swal.fire({
      //     text:error.error.message,
      //     icon:'error'
      //   })
      //   this.isLoading = false;
      if (data.statusCode === 200) {
        this.success = true;
        this.sucess_msg = data.description;
        this.searchButton=false;


        setTimeout(() => {
          this.success = false;
          this.leaveForm.reset();
          this.submitted = false; // Reset the form
          this.router.navigate(['home/leave-adjustment-list']);
        }, 3000);
      } else {
        this.error = true;
        console.log("Error Create:", data);
        this.error_msg = data.message ;
        this.searchButton=true;


        setTimeout(() => {
          this.error = false;
        }, 3000);
      }
    },
    error => {
      console.log("Error result:", error);
      this.error = true;
      this.error_msg = error.error.message ;
      this.searchButton=true;


      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    () => {
      this.isLoading = false;
      this.leaveForm.reset(); 
      this.submitted = false;// Reset the form
      this.searchButton=false;

    }
  );
      }
    

  cancel(){
    this.router.navigate(['/home/leave-adjustment-list']);
  }
  keyFunc(event: Event, event1: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
    let inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace

    if (event1.key === ' ') {
        // Handle the space key press
        console.log('Space key pressed');
        // Optionally, prevent the default behavior
        event1.preventDefault();
    } else {
        // Clear the employee list if the input is empty
        if (inputValue === '') {
            this.employeeIdList = [];
            this.employeeNotFound = false;
            this.all_null=false;


            console.log('Input is empty, employee list cleared');
            return;
        }

        // Cancel the previous API request if there's any
        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
        this.unsubscribe$ = new Subject<void>();

        this.leaveservice.getEmployeeListKeywords(this.organizationId, inputValue).pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
                if (err.error.status === 500) {
                    this.employeeIdList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
                return EMPTY; // Return EMPTY to propagate completion downstream
            })
        ).subscribe(
            (resultData: any) => {
                if (resultData.statusCode === 204) {
                    console.log("No employees found (status code 204)");
                    this.employeeIdList = [];
                    this.employeeNotFound = true;
                } else {
                    if (Array.isArray(resultData)) {
                        this.employeeIdList = resultData;
                    } else if (typeof resultData === 'object') {
                        this.employeeIdList = Object.values(resultData);
                    } else {
                        console.error('Unexpected data type:', resultData);
                    }
                    this.employeeNotFound = false;
                }
            },
            (err) => {
                if (err.error.status === 500) {
                    this.employeeIdList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
            }
        );
    }
}

  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.employeeIdList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.employeeIdList = []; // Clear the employee list
    this.employeeNotFound = false; 
    this.all_null=false;

  }
  preventSpaceBeforeInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
        event.preventDefault();
    }
  }
  handleBackspace(event): void {
    if (
      event.key === 'Backspace' &&
      !this.selectedemployee &&
      (this.selectedemployeeId === undefined || this.selectedemployeeId.trim().length === 0)
    ) {
      this.selectedemployeeId = undefined;
      this.employeeId = undefined;
      this.employeeIdList.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.selectedemployeeId = undefined;
    }
  }
  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }

  validateInputs(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }
}

