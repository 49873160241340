<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3> {{cityId ? "Update" : "Add"}} City</h3>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div formGroupName="country" class="form-group col-md-6">
                <label class="col-form-label">Country Name <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <select formControlName="countryId" id="countryId" (change)="countryChange($event)" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg">
                  <option value="">- Select -</option>
                  <option *ngFor="let row of countryData; let i=index;" value="{{row.countryId}}">{{row.countryName}}</option>
                </select>
                <div class="valid-msg" *ngIf="(validate.country.invalid && validate.country.touched) || validate.country.dirty || submitted">
                    <span *ngIf="validate.country.errors?.required" class="text-danger">Please select country.</span>
                </div>
            </div>

            <div formGroupName="state" class="form-group col-md-6">
                <label class="col-form-label">State Name <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <select formControlName="stateId" id="stateId" (change)="stateChange($event)" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg">
                    <option value="">- Select -</option>
                    <option *ngFor="let row of stateList; let i=index;" value="{{row.stateId}}">{{row.stateName}}</option>
                </select>
                <div class="valid-msg" *ngIf="(validate.state.invalid && validate.state.touched) || validate.state.dirty || submitted">
                    <span *ngIf="validate.state.errors?.required" class="text-danger">Please select state.</span>
                </div>
            </div>
        </div>

        <div  class="row">
            <div formGroupName="district" class="form-group col-md-6">
                <label class="col-form-label">District Name <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentCode($event)" -->
                <select formControlName="districtId" id="districtId" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg">
                    <option value="">- Select -</option>
                    <option *ngFor="let row of districtList; let i=index;" value="{{row.districtId}}">{{row.districtName}}</option>
                </select>
                <div class="valid-msg" *ngIf="(validate.district.invalid && validate.district.touched) || validate.district.dirty || submitted">
                    <span *ngIf="validate.district.errors?.required" class="text-danger">Please select district.</span>
                </div>
            </div>
        
            <div class="form-group col-md-6">
                <label class="col-form-label">City Name <span class="star">*</span></label>
                <!-- (keyup)="checkExistDepartmentName($event)" -->
                <input type="text" formControlName="cityName" id="cityName" placeholder="City Name" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg">
                <div class="valid-msg" (keyup)="checkExistCityName($event)" *ngIf="(validate.cityName.invalid && validate.cityName.touched) || validate.cityName.dirty || submitted">
                    <span *ngIf="validate.cityName.errors?.required" class="text-danger">Please enter city name</span>
                    <span *ngIf="validate.cityName.errors?.pattern" class="text-danger">Please enter valid city name</span>
                    <span *ngIf="cityNameExistStatus" class="span-error"> City name already exist!</span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>