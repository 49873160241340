import { Component, OnInit } from '@angular/core';
import { FormGroup ,FormBuilder, Validators,FormArray,ReactiveFormsModule} from '@angular/forms';
import { PmsService } from '../../providers/pms.service';
import { Router,ActivatedRoute} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forEach, index } from 'mathjs';
import { DatePipe } from '@angular/common';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';


@Component({
  selector: 'app-view-appraisal',
  templateUrl: './view-appraisal.component.html',
  styleUrls: ['./view-appraisal.component.scss']
})
export class ViewAppraisalComponent implements OnInit {
  viewform:FormGroup;
  submitted: boolean = false;
  usertData: any;
  orgId: any;
  empId: any;
  empData: any;
  approverData: any;
  kraTemplateData:any;
  empName: any;
  approverName: string;
  localempId:any;
  LastReviewDate:any;
  createDate:any;
  formattedDate:string;
  kraTempLine:any=[];
  currentYear:any;
  periodData:any;
  empLineList:any;
  per:any;
  sucess_msg:any;
  error_msg:any;
  LineDatas:any=[];
  LastRevieDate:any;
  crateDate:any;
  position: any;
  department: any;
  userId: any;
  approveRole: any;
  formattedTodayDate: string;
  formattedLastDate: any;
  Data: any;
  payload: any;
  fileByte: any;
  val: any;
  apprialform: any;
  kraValues: any;
  totalPer: any;
  appList: any;
  minHours: number = 0; 
  minDays:number =0;
  maxDays:number =5;
  // apprisalHeaderId: any;
  appHeaderId: any;
  dowUrl: any;
  success: boolean;
  error: boolean;
  errorMsg: any;
  kraarray: any;
  period: any;
  show: boolean=false;
  manage: boolean=false;
  appheader: any;
  approverOneStatus: any;
  approverlevel: any;
  approvalStatus: any;
  apprisalStatus: any;

  loader:boolean=false;
  invalidInput: boolean = false;

  submitButtonDisabled:boolean=false;
  constructor(
    private pmsService: PmsService,
    private router:Router,
    private dialog:MatDialog,
    private fb:FormBuilder,
    private ActivatedRoute:ActivatedRoute,
    private datepipe: DatePipe,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void 
  {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("master",this.usertData );
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log("this.orgId:",this.orgId);
    this.localempId= this.usertData.employee.employeeId;
    console.log("empId:",this.localempId);
    // this.position="Java Developer";
    // console.log("position:",this.position);
    //  this.position = this.usertData.employee.designation.designationName;
    // console.log("this.orgId:",this.position);
    // this.department ="Java";
    // console.log("dept:",this.department);
    this.department = this.usertData.employee.department.departmentName;
    
    this.userId = this.usertData.userId;
    console.log("Employye ID"+this.userId);

    this.viewform=this.fb.group({
      period:[null,Validators.required],
      employeeName:[null],
      department:[null],
      empId:[null],
      reviewerName:[null],
      positionHold:[null],
      reviewrTitle:[null],
      LastReviewDate:[''],
      createDate:[''],
      // empoverallrating:[''],
      hoursWorked:[null,Validators.required],
      leaveDays:[null,Validators.required],
      empquery:[null],
      manquery:[null],
      // file:[null,Validators.required],
      file:[null],
      emprating:[],
      apprating:[],
      finalrating:[''],
      finalquery:[],
      managementquery:[],
      kra: this.fb.array([
           ]),
    })
    this.appHeaderId = this.ActivatedRoute.snapshot.params.apprisalHeaderId;
    console.log(this.appHeaderId);
    this.loader=true;
     this.pmsService.getApprisalList(this.appHeaderId).subscribe((data: any) => 
    {
      console.log(data);
      this.loader=false;
      console.log(data.length);
      if(data.statusCode==204)
      {
        // this.nodatafound = true;
        console.log("appList Empty");
        this.appList=[];
        
      }
      this.appheader=[];
      this.appList=data;
      if(this.appList.approvals.approver.fyiObject!=undefined)
      {
        if(this.appList.approvals.approver.fyiObject.employeeId==this.localempId)
        {
           console.log("FYI");
           // this.show=true;
           console.log(this.show);
         }
      }
      if(this.appList.approvals.approver.approverOne!=undefined)
      {
        console.log("app1");
        console.log("app1",this.localempId);
        console.log("app1",this.appList.approvals.approver.approverOneObject.employeeId);
        console.log("app1",this.appList.approvals.approverOneStatus.listTypeValueId);
        if(this.appList.approvals.approver.approverOneObject.employeeId==this.localempId&&this.appList.approvals.approverOneStatus.listTypeValueId==3)
        {
          console.log("approver1");
          this.show=true;
          this.manage=false;
          console.log(this.show);
        }
      }
      if(this.appList.approvals.approver.approverTwoObject!=undefined)
      {
        console.log("app2");
        console.log(this.appList.approvals.approver.approverTwoObject.employeeId);
        console.log(this.appList.approvals.approverOneStatus.listTypeValueId);
        console.log(this.appList.approvals.approverTwoStatus.listTypeValueId);
        console.log(this.localempId);
        if(this.appList.approvals.approver.approverTwoObject.employeeId==this.localempId&&this.appList.approvals.approverOneStatus.listTypeValueId==5&&this.appList.approvals.approverTwoStatus.listTypeValueId==3)
      {
        console.log("approver2 or Manager");
        this.manage=true;
        this.show=false;
        console.log(this.manage);  
      }
      }
      if(this.appList.approvals.approver.approverThreeObject!=undefined)
      {
        console.log("app3");
        if(this.appList.approvals.approver.approverThreeObject.employeeId==this.localempId&&this.appList.approvals.approverTwoStatus.listTypeValueId==5&&this.appList.approvals.approverThreeStatus.listTypeValueId==3)
        {
          console.log("approver3 or Manager");
          this.manage=true;
          this.show=false;
          console.log(this.manage);  
        }
      }
      if(this.appList.approvals.approver.approverFourObject!=undefined)
      {
        console.log("app4");
        if(this.appList.approvals.approver.approverFourObject.employeeId==this.localempId&&this.appList.approvals.approverThreeStatus.listTypeValueId==5&&this.appList.approvals.approverFourStatus.listTypeValueId==3)
        {
          console.log("approver4 or Manager");
          this.manage=true;
          this.show=false;
          console.log(this.manage);  
        }
      }
      if(this.appList.approvals.approver.approverFiveObject!=undefined)
      {
        console.log("app5");
        if(this.appList.approvals.approver.approverFiveObject.employeeId==this.localempId&&this.appList.approvals.approverFourStatus.listTypeValueId==5&&this.appList.approvals.approverFiveStatus.listTypeValueId==3)
        {
          console.log("approver5 or Manager");
          this.manage=true;
          this.show=false;
          console.log(this.manage);  
        }
      }
      this.dowUrl=this.appList.timeSheetAttachment.fileDownloadUrl;
      console.log(this.dowUrl)
      this.appheader=data;
      console.log(this.appheader);
      console.log(this.appList.approvals.approver.approverOne);
      console.log(this.localempId);
      const kraLineFormArray = this.viewform.get('kra') as FormArray;
      console.log(kraLineFormArray)
      console.log(this.appList)
      this.pmsService.getPeriodBasedList(this.appList.financialPeriodId).subscribe((Data: any) => 
      {
        this.periodData = Data;
        console.log(this.periodData.financialperiodMonth.substring(0, 3));
        console.log(this.periodData.financialPeriodYear);
        this.period=this.periodData.financialperiodMonth.substring(0, 3)+"-"+this.periodData.financialPeriodYear;
        console.log(this.period);
        this.viewform.patchValue({
          period:this.period
      })
      })
       console.log(this.show);
       console.log(this.manage);
      if(this.show==true)
      {
         console.log("app1 Patch",this.show);
         this.viewform.patchValue({
          empId:this.appList.employeeId,
          employeeName:this.appList.employee.firstName+" "+this.appList.employee.lastName,
          LastReviewDate:this.appList.closedDate,
          createDate:this.appList.todayDate,
          // period:this.period,
          reviewrTitle:this.appList.reviewerTitle,
          reviewerName:this.appList.approvals.approver.approverOneObject.firstName+" "+this.appList.approvals.approver.approverOneObject.lastName,
          emprating:this.appList.employeeReviewRating,
          empquery:this.appList.employeeQuery,
          hoursWorked:this.appList.totalHoursWorking,
          leaveDays:this.appList.leaveDays,
          apprating:this.appList.managementRating,
          finalrating:this.appList.managementTotalRating,
          positionHold: this.usertData.employee.designation.designationName,
          department :this.usertData.employee.department.departmentName,
      })
      }
      if (this.manage==true)
      {
        console.log("manage Patch,",this.manage);
        this.viewform.patchValue({
          empId:this.appList.employeeId,
          employeeName:this.appList.employee.firstName+" "+this.appList.employee.lastName,
          LastReviewDate:this.appList.closedDate,
          createDate:this.appList.todayDate,
          // period:this.period,
          reviewrTitle:this.appList.reviewerTitle,
          reviewerName:this.appList.approvals.approver.approverOneObject.firstName+" "+this.appList.approvals.approver.approverOneObject.lastName,
          emprating:this.appList.employeeReviewRating,
          empquery:this.appList.employeeQuery,
          hoursWorked:this.appList.totalHoursWorking,
          leaveDays:this.appList.leaveDays,
          apprating:this.appList.managementRating,
          finalrating:this.appList.managementTotalRating,
          positionHold: this.usertData.employee.designation.designationName,
          department :this.usertData.employee.department.departmentName,
      })
      }
      console.log(this.appList.apprisalLine.length);
      this.appList.apprisalLine.forEach((element,index) => 
      {
          console.log(element);
          console.log(index);
          this.addRow(element);
         console.log("inside loop:",index);
         console.log(this.show);
         console.log(this.manage);
         if(this.show==true)
         {
           console.log("form aray Patch",this.show);
           kraLineFormArray.at(index).patchValue({
            criteriaName:element.kratemplateLine.criteriaName,
            criteriaDescription:element.criteriaDescription,
            criteriaDetail:element.criteriaDetail,
            weightage:element.weightage,
            // empRating: element.employeeRating,
            empRating: 4,
            // approverRating:['', Validators.required],
            rating: element.rating,
          })
         }
         if(this.manage==true)
         {
          this.loader=true;
          console.log("form aray Patch",this.manage);
          kraLineFormArray.at(index).patchValue({
            criteriaName:element.kratemplateLine.criteriaName,
            criteriaDescription:element.criteriaDescription,
            criteriaDetail:element.criteriaDetail,
            weightage:element.weightage,
            // empRating: element.employeeRating,
            empRating: 4,
            // approverRating:['', Validators.required],
            rating: element.rating,
            approverRating: element.managementRating,
            apprating:element.managementCalculateRating,
            approverComments:element.approverComments
            // apprating :['0'],
            // approverComments:[''],
            // employeeComments:[''],
            // managementRating:[''],
            // managementCalculateRating:[''],
            // kraTemplateHeaderLineId:[data ? data.kraTemplateHeaderLineId : '']
          })
         }
        console.log(this.viewform.value)
        this.loader=false;
    })
  })
  }

  addRow(data?: any) 
  {
    console.log(data);
    const control = this.viewform.get('kra') as FormArray;
    control.push(this.initRows(data)); // Pass data to initRows()
    console.log("control",control);
  }

  initRows(data: any = null)
  {
    console.log(this.show);
    console.log(this.manage);
    if(this.show==true)
    {
      console.log("Approver Vaildation",this.show);
      const group = this.fb.group({
        criteriaName: [data ? data.criteriaName : ''],
        criteriaDescription: ['', Validators.required],
        criteriaDetail: ['', Validators.required],
        weightage: [data ? data.weightage : ''],
        empRating: ['', Validators.required],
        approverRating:['',Validators.required],
        rating: ['',Validators.required],
        apprating :[''],
        approverComments:['',Validators.required],
        // approverComments:[''],
        employeeComments:[''],
        finalrating:[''],
        managementquery:[''],
        kraTemplateHeaderLineId:[data ? data.kraTemplateHeaderLineId : '']
      });
      return group;
    }
    if(this.manage==true)
    {
      console.log("Manager Vaildation",this.manage);
      const group = this.fb.group({
        criteriaName: [data ? data.criteriaName : ''],
        criteriaDescription: [''],
        criteriaDetail: [''],
        weightage: [data ? data.weightage : ''],
        empRating: [''],
        approverRating:[''],
        rating: [''],
        apprating :[''],
        // approverComments:['',Validators.required],
        approverComments:[''],
        employeeComments:[''],
        finalrating:[''],
        // managementquery:['',Validators.required],
        // finalrating:[''],
        managementquery:[''],
        kraTemplateHeaderLineId:[data ? data.kraTemplateHeaderLineId : '']
      });
      return group;
    }
  }

  kraLine() 
  {
    return this.viewform.get('kra') as FormArray;
    // console.log(value);
    // console.log(value.controls);
    //  return this.control;
  }

  // onInputChange(event,index)
  // {
  //   // console.log("OnChange Function", event.target.value+" "+index);
  //   console.log(event,index);
  //   const kraArray = this.apprialform.get('kra') as FormArray;
  //   console.log(kraArray.length);
  //   if (index >= 0 && index < kraArray.length) 
  //   {
  //     const rowData = kraArray.at(index).value;
  //     console.log('Row Data:', rowData.empRating);
  //     // if(rowData.empRating!=0)
  //     // {
  //       this.per=rowData.empRating/100*rowData.weightage;
  //       console.log("percentage:"+this.per.toFixed(2));
  //       this.val=this.per.toFixed(2);
  //       console.log(this.val);
  //       let totalPer=0.00;
  //       kraArray.at(index).patchValue({
  //         rating:this.val,
  //       })
  //       // Calculation
  //       const kraArr= this.apprialform.get('kra') as FormArray;
  //       console.log(kraArr.value);
  //       this.kraValues=kraArr.value;
  //       console.log(this.kraValues);
  //       let total = 0;
  //       for(let i=0;i<this.kraValues.length;i++)
  //       {
  //         console.log(total);
  //         console.log("Rating",this.kraValues.at(i).rating);
  //         let t=this.kraValues.at(i).rating;
  //         console.log(t);
  //         console.log(parseFloat(t));
  //         total=total+parseFloat(t);
  //         console.log(total);
  //         this.totalPer=total;
  //       }
        
  //       this.apprialform.patchValue({
  //         empoverallrating:this.totalPer
  //       })
  
  //     // }
  //     // You can now use the rowData as needed.
  //   } 
  //   else 
  //   {
  //     console.error('Invalid index');
  //   }
  // }

    get validate() 
    { 
      return this.viewform.controls; 
    }
    
    get KraLineFormArray() : FormArray 
    {
      return this.viewform.get('kra') as FormArray;
     }

     downloadfiles(dowUrl)
     {
      console.log("Download File"+dowUrl);
      const a = document.createElement('a')
                  //  const objectUrl: any =  "data:" + filedata.fileType + filedata.fileEncoding + "," + filedata.fileByte;
                  // const objectUrl =  `${base_url}download/${result}`
                  let downloadurl=dowUrl;
                  console.log("downloadurl"+downloadurl);
                  const objectUrl =  downloadurl
                  // const objectUrl =  `http://38.242.135.217:6018/api/enote/pms/download/TSDOC_6.jpg`
                   a.href = objectUrl;
                   a.download = objectUrl;
                   a.click();
                   URL.revokeObjectURL(objectUrl);
                   console.log("Download File:"+a);
     }

     onInputChange(event,index)
  {
    // console.log("OnChange Function", event.target.value+" "+index);
    console.log(event,index);
    const kraArray = this.viewform.get('kra') as FormArray;
    console.log(kraArray.length);
    if (index >= 0 && index < kraArray.length) 
    {
      const rowData = kraArray.at(index).value;
      console.log('Row Data:', rowData.approverRating);
      // if(rowData.empRating!=0)
      // {
        this.per=rowData.approverRating/100*rowData.weightage;
        console.log("percentage:"+this.per.toFixed(2));
        this.val=this.per.toFixed(2);
        console.log(this.val);
        let totalPer=0.00;
        kraArray.at(index).patchValue({
          apprating:this.val,
        })
        // Calculation
        const kraArr= this.viewform.get('kra') as FormArray;
        console.log(kraArr.value);
        this.kraValues=kraArr.value;
        console.log(this.kraValues);
        let total = 0;
        for(let i=0;i<this.kraValues.length;i++)
        {
          console.log(total);
          console.log("Rating",this.kraValues.at(i).apprating);
          let t=this.kraValues.at(i).apprating;
          console.log(t);
          console.log(parseFloat(t));
          total=total+parseFloat(t);
          console.log(total);
          this.totalPer=total.toFixed(2);
          console.log(total);
          this.viewform.patchValue({
            apprating:this.totalPer
          })
        }
        console.log(this.viewform.value);
      // }
      // You can now use the rowData as needed.
    } 
    else 
    {
      console.error('Invalid index');
    }
  }


     submit()
     {
      console.log("Submit",this.viewform.value);
      console.log("appheader",this.appheader);
      console.log("appList",this.appList);
      this.submitted=true;
      if(this.viewform.invalid)
      {
        console.log("required",this.viewform);
        return;
      }
      // let count = 0
      // if(this.appList.approval.approver.approverOne){
      //   count = count+1
      // }
      // if(i.approval.approver.approverTwo){
      //   count = count+1
      // }
      // if(i.approval.approver.approverThree){
      //   count = count+1
      // }
      // if(i.approval.approver.approverFour){
      //   count = count+1
      // }
      // if(i.approval.approver.approverFive){
      //   count = count+1
      // }

      let count = 0;
      if(this.appList.approvals.approver.approverOneObject!=undefined)
      {
        if(this.appList.approvals.approver.approverOneObject)
        {
        count = count+1;
        console.log(count);
        }
      }
      if(this.appList.approvals.approver.approverTwoObject!=undefined)
      {
        if(this.appList.approvals.approver.approverTwoObject)
        {
        count = count+1;
        console.log(count);
        }
      }
      if(this.appList.approvals.approver.approverThreeObject!=undefined)
      {
        if(this.appList.approvals.approver.approverThreeObject)
        {
        count = count+1;
        console.log(count);
        }
      }
      if(this.appList.approvals.approver.approverFourObject!=undefined)
      {
        if(this.appList.approvals.approver.approverFourObject)
        {
        count = count+1;
        console.log(count);
        }
      }
      if(this.appList.approvals.approver.approverFiveObject!=undefined)
      {
        if(this.appList.approvals.approver.approverFiveObject)
        {
        count = count+1;
        console.log(count);
        }
      }

      if(this.appList.approvals.approver.approverOneObject!=undefined)
      {
        console.log("app1");
        console.log("app1",this.appList.approvals.approver.approverOneObject.employeeId);
        console.log("app1",this.appList.approvals.approverOneStatus.listTypeValueId);
        // console.log("app1",this.appList.approvals.approver.approverOneObject.employeeId);
        if(this.appList.approvals.approver.approverOneObject.employeeId== this.localempId && this.appList.approvals.approverOneStatus.listTypeValueId == 3) 
        {
          // this.appList.approvals.approverOneStatus.listTypeValueId = 5;
          // 
          this.appList.approvals['approverOneStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :9
          }
          this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :3
          },
          this.appList.approvals.approver.lastUpdatedBy=this.localempId,
          console.log(this.appList);
          
          // this.appList.approvals['approverOneStatus']=
          // {
          //   listTypeValueId :5
          // }

          this.appList.approvals.approverlevel = 1;
          console.log("approver1"+this.appList.approvals.approverlevel);
          this.approverOneStatus=this.appList.approvals.approverOneStatus.listTypeValueId;
          this.approverlevel=this.appList.approvals.approverlevel;
          console.log(this.appList.approvals.approverOneStatus.listTypeValueId);
        }
      }
      if(this.appList.approvals.approver.approverTwoObject!=undefined)
      {
        console.log("app2");
        console.log("app2",this.appList.approvals.approver.approverTwoObject.employeeId);
        console.log("app2",this.appList.approvals.approverOneStatus.listTypeValueId);
        console.log("app2",this.appList.approvals.approverTwoStatus.listTypeValueId);
        if (this.appList.approvals.approver.approverTwoObject.employeeId== this.localempId && this.appList.approvals.approverOneStatus.listTypeValueId == 5
          && this.appList.approvals.approverTwoStatus.listTypeValueId == 3) 
          {
            // this.appList.approvals.approverTwoStatus.listTypeValueId = 5;
            this.appList.approvals['approverTwoStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :9
          }
          this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :3
          }
          this.appList.approvals.approver.lastUpdatedBy=this.localempId,
          console.log(this.appList);
            this.appList.approvals.approverlevel = 2;
            console.log("approver2"+this.appList.approvals.approverlevel);
            this.approverOneStatus=this.appList.approvals.approverTwoStatus.listTypeValueId;
            this.approverlevel=this.appList.approvals.approverlevel;
        }
      }
      if(this.appList.approvals.approver.approverThreeObject!=undefined)
      {
        console.log("app3");
        if (this.appList.approvals.approver.approverThreeObject.employeeId== this.localempId && this.appList.approvals.approverOneStatus.listTypeValueId == 5
          && this.appList.approvals.approverTwoStatus.listTypeValueId == 5 && this.appList.approvals.approverThreeStatus.listTypeValueId == 3) {
            // this.appList.approvals.approverThreeStatus.listTypeValueId = 5;
            this.appList.approvals['approverThreeStatus']=
            {
              listTypeValueId :5
            }
            this.appList['apprisalStatus']=
          {
            listTypeValueId :9
          }
          this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :3
          }
          this.appList.approvals.approver.lastUpdatedBy=this.localempId,
          console.log(this.appList);
            this.appList.approvals.approverlevel = 3;
            console.log("approver3"+this.appList.approvals.approverlevel);
            this.approverOneStatus=this.appList.approvals.approverThreeStatus.listTypeValueId;
          this.approverlevel=this.appList.approvals.approverlevel;
        }
      }
     if(this.appList.approvals.approver.approverFourObject!=undefined)
     {
      console.log("app4");
      if (this.appList.approvals.approver.approverFourObject.employeeId== this.localempId && this.appList.approvals.approverOneStatus.listTypeValueId == 5
        && this.appList.approvals.approverTwoStatus.listTypeValueId == 5 && this.appList.approvals.approverThreeStatus.listTypeValueId == 5
        && this.appList.approvals.approverFourStatus.listTypeValueId == 3) {
          this.appList.approvals['approverFourStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :9
          }
          this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :3
          }
          this.appList.approvals.approver.lastUpdatedBy=this.localempId,
          console.log(this.appList);
          // this.appList.approvals.approverFourStatus.listTypeValueId = 5/
          this.appList.approvals.approverlevel = 4;
          console.log("approver4"+this.appList.approvals.approverlevel);
          this.approverOneStatus=this.appList.approvals.approverFourStatus.listTypeValueId;
        this.approverlevel=this.appList.approvals.approverlevel;
      }
     }
      
     if(this.appList.approvals.approver.approverFiveObject!=undefined)
     { 
      console.log("app5",this.appList.approvals['approverFiveStatus']);
      if (this.appList.approvals.approver.approverFiveObject.employeeId== this.localempId && this.appList.approvals.approverOneStatus.listTypeValueId == 5
        && this.appList.approvals.approverTwoStatus.listTypeValueId == 5 && this.appList.approvals.approverThreeStatus.listTypeValueId == 5
        && this.appList.approvals.approverFourStatus.listTypeValueId == 5 && this.appList.approvals.approverFiveStatus.listTypeValueId == 3) {
          // this.appList.approvals.approverFiveStatus.listTypeValueId = 5
          this.appList.approvals['approverFiveStatus']=
          {
            listTypeValueId:5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :9
          }
          this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :3
          }
          this.appList.approvals.approver.lastUpdatedBy=this.localempId,
          console.log("hqggqfwwtybgwxbgwugqugqggyge",this.appList);
          // this.appList['apprisalStatus']=
          // {
          //   listTypeValueId :7
          // }
          this.appList.approvals.approvalStatus.listTypeValueId = 5;
          this.appList.approvals.approverlevel = 5;
          console.log("approver5"+ " " +this.appList.approvals.approverlevel);
          this.approverOneStatus=this.appList.approvals.approvalStatus.listTypeValueId;
          this.approverlevel=this.appList.approvals.approverlevel;
      }
     }
     
      console.log("FinalTest",this.appList);
      // console.log(this.appList.approvals.approverTwoStatus.listTypeValueId);
      console.log("count",count);
      let totalapprovercount=count;
      console.log(totalapprovercount);
      console.log(this.appList.approvals.approverOneStatus.listTypeValueId);
      if(count == 1 && this.appList.approvals.approverOneStatus.listTypeValueId == 5){
        // this.appList.approvals.approvalStatus.listTypeValueId = 3;
        // this.appList.apprisalStatus.listTypeValueId = 3;
        this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :7
          }
        this.approvalStatus=this.appList.approvals.approvalStatus.listTypeValueId;
        this.apprisalStatus=this.appList.apprisalStatus.listTypeValueId;
        console.log("approver Staus App1",this.approvalStatus);
        console.log("apprisal Staus App1",this.apprisalStatus);
        if(totalapprovercount==count)
        {
          // i.approvedOn=this.today;
          // console.log(i.approvedOn);
          // i.paymentStatus=
          // {
          //   listTypeValueId:this.paymentData,
          //   listTypeValueName: this.paymentName
          // }
          // console.log(i.paymentStatus.listTypeValueId);
          // console.log(i.paymentStatus.listTypeValueName);
          // console.log(i);
        }
      }
      console.log(this.appList.approvals.approverOneStatus.listTypeValueId);
      console.log(this.appList.approvals.approverTwoStatus.listTypeValueId);
      console.log(this.appList.approvals.approvalStatus.listTypeValueId);
      if(count == 2 && this.appList.approvals.approverOneStatus.listTypeValueId == 5 && this.appList.approvals.approverTwoStatus.listTypeValueId == 5){
        // this.appList.approvals.approvalStatus.listTypeValueId = 3;
        // this.appList.apprisalStatus.listTypeValueId = 3;
        this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :7
          }
        this.approvalStatus=this.appList.approvals.approvalStatus.listTypeValueId;
        this.apprisalStatus=this.appList.apprisalStatus.listTypeValueId;
        console.log("approver Staus App2",this.approvalStatus);
        console.log("apprisal Staus App2",this.apprisalStatus);
        if(totalapprovercount==count){
          // i.approvedOn=this.today;
          // console.log(i.approvedOn);
          // i.paymentStatus=
          // {
          //   listTypeValueId:this.paymentData,
          //   listTypeValueName: this.paymentName
          // }
          // console.log(i.paymentStatus.listTypeValueId);
          // console.log(i.paymentStatus.listTypeValueName);
          // console.log(i);
           
        }
      }
      if(count == 3 && this.appList.approvals.approverOneStatus.listTypeValueId == 5 && this.appList.approvals.approverTwoStatus.listTypeValueId == 5
        && this.appList.approvals.approverThreeStatus.listTypeValueId == 5){
          // this.appList.approvals.approvalStatus.listTypeValueId = 3;
          // this.appList.apprisalStatus.listTypeValueId = 3;
          this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :7
          }
          this.approvalStatus=this.appList.approvals.approvalStatus.listTypeValueId;
          this.apprisalStatus=this.appList.apprisalStatus.listTypeValueId;
          console.log("approver Staus App3",this.approvalStatus);
          console.log("apprisal Staus App3",this.apprisalStatus);
        if(totalapprovercount==count)
        {
          // i.approvedOn=this.today;
          // console.log(i.approvedOn);
          // i.paymentStatus=
          // {
          //   listTypeValueId:this.paymentData,
          //   listTypeValueName: this.paymentName
          // }
          // console.log(i.paymentStatus.listTypeValueId);
          // console.log(i.paymentStatus.listTypeValueName);
          // console.log(i);
        }
      }
      if(count == 4 && this.appList.approvals.approverOneStatus.listTypeValueId == 5 && this.appList.approvals.approverTwoStatus.listTypeValueId == 5
        && this.appList.approval.approverThreeStatus.listTypeValueId == 5 && this.appList.approvals.approverFourStatus.listTypeValueId == 5){
        //   this.appList.approvals.approvalStatus.listTypeValueId = 3;
        // this.appList.apprisalStatus.listTypeValueId = 3;
        this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :7
          }
        this.approvalStatus=this.appList.approvals.approvalStatus.listTypeValueId;
        this.apprisalStatus=this.appList.apprisalStatus.listTypeValueId;
        console.log("approver Staus App4",this.approvalStatus);
        console.log("apprisal Staus App4",this.apprisalStatus);
        if(totalapprovercount==count){
          // i.approvedOn=this.today;
          // console.log(i.approvedOn);
          // i.paymentStatus=
          // {
          //   listTypeValueId:this.paymentData,
          //   listTypeValueName: this.paymentName
          // }
          // console.log(i.paymentStatus.listTypeValueId);
          // console.log(i.paymentStatus.listTypeValueName);
          // console.log(i);
        }
      }
      if(count == 5 && this.appList.approvals.approverOneStatus.listTypeValueId == 5 && this.appList.approvals.approverTwoStatus.listTypeValueId == 5
        && this.appList.approvals.approverThreeStatus.listTypeValueId == 5 && this.appList.approvals.approverFourStatus.listTypeValueId == 5 
        && this.appList.approvals.approverFiveStatus.listTypeValueId == 5){
          // this.appList.approvals.approvalStatus.listTypeValueId = 5;
          // this.appList.apprisalStatus.listTypeValueId = 7;
          this.appList.approvals['approvalStatus']=
          {
            listTypeValueId :5
          }
          this.appList['apprisalStatus']=
          {
            listTypeValueId :7
          }
          this.approvalStatus=this.appList.approvals.approvalStatus.listTypeValueId;
          this.apprisalStatus=this.appList.apprisalStatus.listTypeValueId;
          console.log("approver Staus App5",this.approvalStatus);
          console.log("apprisal Staus App5",this.apprisalStatus);
        if(totalapprovercount==count)
        {
        //   this.appList.approvedOn=this.today;
        // console.log(i.approvedOn);
        // this.appList.paymentStatus=
        //   {
        //     listTypeValueId:this.paymentData,
        //     listTypeValueName: this.paymentName
        //   }
        //   console.log(i.paymentStatus.listTypeValueId);
        //   console.log(i.paymentStatus.listTypeValueName);
        //   console.log(i);
      }
      } 
      console.log("approver Staus",this.approvalStatus);
      console.log("apprisal Staus",this.apprisalStatus);
      this.LastRevieDate = this.datepipe.transform(this.viewform.value.LastReviewDate, 'yyyy-MM-dd');
      console.log(this.LastRevieDate);
      this.crateDate = this.datepipe.transform(this.viewform.value.createDate, 'yyyy-MM-dd');
      console.log(this.crateDate);
      this.LineDatas=[];
      const kraArray = this.viewform.get('kra') as FormArray;
      console.log(kraArray.value);
      this.kraarray=kraArray.value;
      console.log(this.kraarray);
      console.log(this.kraarray.length);
      console.log(this.appheader);
      console.log(this.appheader.apprisalLine);
     this.appheader.apprisalLine.forEach((element,index) => 
      {
      console.log(element.index);
      console.log(element.apprisalLineId);
    // for(let i=0;i<this.kraarray.length;i++)
    // {
      console.log("I",index);
      if(this.show==true)
      {
        const line =
        {
        apprisalLineId: element.apprisalLineId,
        criteriaDescription:element.criteriaDescription,
        criteriaDetail:element.criteriaDetail,
        employeeRating: element.empRating,
        weightage:element. weightage,
        rating: element.rating,
        
        // if(this.show==true)
        // {
        //   approverComments: this.kraarray.at(index).approverComments,
        //   managementRating: this.kraarray.at(index).approverRating,
        //   managementCalculateRating:this.kraarray.at(index).apprating,
        // },
        // if(this.manage==true)
        // {
        //   approverComments: element.approverComments,
        //   managementRating: element.approverRating,
        //   managementCalculateRating:element.apprating,
        // }
        approverComments: this.kraarray.at(index).approverComments,
        managementRating: this.kraarray.at(index).approverRating,
        managementCalculateRating:this.kraarray.at(index).apprating,
        employeeComments: this.kraarray.at(index).employeeComments,
        kratemplateLine: 
        {
        kraTemplateHeaderLineId:element.kratemplateLine.kraTemplateHeaderLineId,
        criteriaName: element.kratemplateLine.criteriaName,
        negativeRatingCheck: element.kratemplateLine.negativeRatingCheck,
        status: 
        {
        listTypeValueId: element.kratemplateLine.status.listTypeValueId,
        // listTypeValueName: element.kratemplateLine.status.listTypeValueName
        },
        present: element.present
        },
        apprisalHeader: 
        {
        apprisalHeaderId: element.apprisalHeader.apprisalHeaderId,
        employeeId: element.apprisalHeader.employeeId,
        status: {
          stTypeValueId: element.kratemplateLine.status.listTypeValueId,
          // listTypeValueName: element.kratemplateLine.status.listTypeValueName
        }
        },
        status: {
        listTypeValueId: element.apprisalHeader.status.listTypeValueId,
        // listTypeValueName: "Active"
        }
        }
        this.Data=line;
        console.log("Line",line);
      }
    
      if(this.manage==true)
      {
        const line =
        {
        apprisalLineId: element.apprisalLineId,
        criteriaDescription:element.criteriaDescription,
        criteriaDetail:element.criteriaDetail,
        employeeRating: element.empRating,
        weightage:element. weightage,
        rating: element.rating,
        
        // if(this.show==true)
        // {
        //   approverComments: this.kraarray.at(index).approverComments,
        //   managementRating: this.kraarray.at(index).approverRating,
        //   managementCalculateRating:this.kraarray.at(index).apprating,
        // },
        // if(this.manage==true)
        // {
          approverComments: element.approverComments,
          managementRating: element.managementRating,
          managementCalculateRating:element.managementCalculateRating,
        // }
        // approverComments: this.kraarray.at(index).approverComments,
        // managementRating: this.kraarray.at(index).approverRating,
        // managementCalculateRating:this.kraarray.at(index).apprating,
        employeeComments: this.kraarray.at(index).employeeComments,
        kratemplateLine: 
        {
        kraTemplateHeaderLineId:element.kratemplateLine.kraTemplateHeaderLineId,
        criteriaName: element.kratemplateLine.criteriaName,
        negativeRatingCheck: element.kratemplateLine.negativeRatingCheck,
        status: 
        {
        listTypeValueId: element.kratemplateLine.status.listTypeValueId,
        // listTypeValueName: element.kratemplateLine.status.listTypeValueName
        },
        present: element.present
        },
        apprisalHeader: 
        {
        apprisalHeaderId: element.apprisalHeader.apprisalHeaderId,
        employeeId: element.apprisalHeader.employeeId,
        status: {
          stTypeValueId: element.kratemplateLine.status.listTypeValueId,
          // listTypeValueName: element.kratemplateLine.status.listTypeValueName
        }
        },
        status: {
        listTypeValueId: element.apprisalHeader.status.listTypeValueId,
        // listTypeValueName: "Active"
        }
        }
        this.Data=line;
        console.log("Line",line);
      }
      
    // }
    console.log("line",this.Data);
    this.LineDatas.push(this.Data);
     });
    console.log(this.LineDatas);
    console.log("AppLIst",this.appList);
    if(this.show==true)
    {
      console.log("this.showPayload"+this.show);
      var updateValues =
      {
      apprisalHeaderId: this.appList.apprisalHeaderId,
      employeeId:this.appList.employeeId,
      financialPeriodId:this.appList.financialPeriodId,
      financialYearId:this.appList.financialYearId,
      reviewerTitle:this.appList.reviewerTitle,
      todayDate:this.appList.todayDate,
      overAllRatting:"",
      managementTotalRating:"",
      totalHoursWorking:this.appList.totalHoursWorking,
      leaveDays:this.appList.leaveDays,
      employeeAcceptanceDate:"",
      employeeQuery:this.appList.employeeQuery,
      employeeReviewRating:this.appList.employeeReviewRating,
      managementRating:this.viewform.value.apprating,
      managementComments:"",
      managementReviseComments:"",
      managementReviseRating:"",
      ratingRequired:"",
      closedDate:this.LastRevieDate,
        status: {
            listTypeValueId: this.appList.status.listTypeValueId,
            // listTypeValueName: "Active"
        },
        organizationId: this.orgId,
        apprisalLine: this.LineDatas,
        approvals: this.appList.approvals,
        // approvalId: this.appheader.approvals.approvalId,
        // approverOneStatus: 
        // {
        // // listTypeValueId:this.appheader.approvals.approverOneStatus,
        // listTypeValueId:5,
        // // listTypeValueName: "Pending"
        // },
        // approverTwoStatus: {
        // listTypeValueId:this.appheader.approvals.approverTwoStatus.listTypeValueId,
        // // listTypeValueName: "Pending"
        // },
        // approvalStatus: 
        // {
        //   listTypeValueId: this.approvalStatus,
        // // listTypeValueName: "Pending"
        // },
        // referredWith: this.appheader.approvals.referredWith,
        // referredWithId: this.appheader.approvals.referredWithId,
        // approver: 
        // {
        // approverId: this.appheader.approvals.approver.approverId,
        // approverOne: this.appheader.approvals.approver.approverOne,
        // approverTwo: this.appheader.approvals.approver.approverTwo,
        // fyi:this.appheader.approvals.approver.fyi,
        // startDate: this.appheader.approvals.approver.startDate,
        // endDate: this.appheader.approvals.approver.endDate,
        // createdBy:this.appheader.approvals.approver.createdBy,
        // // lastUpdatedBy:this.localempId,
        // rulename:this.appheader.approvals.approver.rulename
        // },
        // status: 
        // {
        // listTypeValueId: this.appheader.approvals.status.listTypeValueId,
        // // listTypeValueName: "Active"
        // }
        // },
        // createdBy: this.appheader.createdBy,
        // lastUpdatedBy: this.appheader.lastUpdatedBy,
        // apprisalStatus: 
        // {
        //   listTypeValueId:this.apprisalStatus,
        // listTypeValueName: "Pending"
        // },
        apprisalStatus: this.appList.apprisalStatus,
        approverlevel : this.approverlevel
      }
         console.log("updateValues",updateValues);
         this.submitButtonDisabled= true;

         this.loader=true;
        this.pmsService.updateApprisal(updateValues,this.appHeaderId).subscribe((result: any) =>
        {
          this.loader=false;
          this.submitButtonDisabled= false;
          console.log("submmit",result);
         
          if(result.statusCode == 200)
          {
            this.submitted = false;
            this.success = true;
            this.sucess_msg = result.description;
            setTimeout(() =>
            {
              this.router.navigate(['home/appraisal']);
            },
             redirectMsgTimeOut)
          }
          // if(result.statusCode==409)
          // {
          //     this.error = true;
          //    console.log("Error Create:"+result);
          //   this.error_msg = result.description;
          // }
          else
          {
            this.error = true;
            this.submitButtonDisabled= false;
            console.log("Error Create:"+result);
            this.error_msg = result.description;
            setTimeout(() => 
            {
               this.error = false;
              // this.router.navigate(['/home/kra-template']);
            })
          }
        },
         err =>
        {
          this.error = true;
          this.submitButtonDisabled= false;
          console.log("Error result",err);
          console.log("Error result",err.error.description);
          this.errorMsg =err.error.description;
          console.log( this.errorMsg);
        })
    }
    if(this.manage==true)
    {
      console.log("this.manag Payload"+this.manage);
      console.log("this.manag Payload"+this.viewform.value.finalrating);
      console.log("this.manag Payload"+this.viewform.value.managementquery);
      var updateValues1 =
      {
      apprisalHeaderId: this.appList.apprisalHeaderId,
      employeeId:this.appList.employeeId,
      financialPeriodId:this.appList.financialPeriodId,
      financialYearId:this.appList.financialYearId,
      reviewerTitle:this.appList.reviewerTitle,
      todayDate:this.appList.todayDate,
      overAllRatting:"",
      managementTotalRating:this.viewform.value.finalrating,
      totalHoursWorking:this.appList.totalHoursWorking,
      leaveDays:this.appList.leaveDays,
      employeeAcceptanceDate:"",
      employeeQuery:this.appList.employeeQuery,
      employeeReviewRating:this.appList.employeeReviewRating,
      managementRating:this.viewform.value.apprating,
      managementComments:this.viewform.value.managementquery,
      managementReviseComments:"",
      managementReviseRating:"",
      ratingRequired:"",
      closedDate:this.LastRevieDate,
        status: {
            listTypeValueId: this.appList.status.listTypeValueId,
            // listTypeValueName: "Active"
        },
        organizationId: this.orgId,
        apprisalLine: this.LineDatas,
        approvals: this.appList.approvals,
        // {
        // approvalId: this.appheader.approvals.approvalId,
        // approverOneStatus: 
        // {
        // // listTypeValueId:this.appheader.approvals.approverOneStatus,
        // listTypeValueId:5,
        // // listTypeValueName: "Pending"
        // },
        // approverTwoStatus: {
        // listTypeValueId:this.appheader.approvals.approverTwoStatus.listTypeValueId,
        // // listTypeValueName: "Pending"
        // },
        // approvalStatus: 
        // {
        //   listTypeValueId: this.approvalStatus,
        // // listTypeValueName: "Pending"
        // },
        // referredWith: this.appheader.approvals.referredWith,
        // referredWithId: this.appheader.approvals.referredWithId,
        // approver: 
        // {
        // approverId: this.appheader.approvals.approver.approverId,
        // approverOne: this.appheader.approvals.approver.approverOne,
        // approverTwo: this.appheader.approvals.approver.approverTwo,
        // fyi:this.appheader.approvals.approver.fyi,
        // startDate: this.appheader.approvals.approver.startDate,
        // endDate: this.appheader.approvals.approver.endDate,
        // createdBy:this.appheader.approvals.approver.createdBy,
        // // lastUpdatedBy:this.localempId,
        // rulename:this.appheader.approvals.approver.rulename
        // },
        // status: 
        // {
        // listTypeValueId: this.appheader.approvals.status.listTypeValueId,
        // // listTypeValueName: "Active"
        // }
        // },
        // createdBy: this.appheader.createdBy,
        // lastUpdatedBy: this.appheader.lastUpdatedBy,
        // apprisalStatus: 
        // {
        //   listTypeValueId:this.apprisalStatus,
        // listTypeValueName: "Pending"
        // },
        apprisalStatus: this.appList.apprisalStatus,
        approverlevel : this.approverlevel
      }
         console.log("updateValues",updateValues1);
         this.submitButtonDisabled= true;
         this.loader=true;
        this.pmsService.updateApprisal(updateValues1,this.appHeaderId).subscribe((result: any) =>
        {
          this.submitButtonDisabled= false;
          this.loader=false;
          console.log("submmit",result);
          if(result.statusCode == 200)
          {
            this.submitted = false;
            this.success = true;
            this.sucess_msg = result.description;
            setTimeout(() =>
            {
              this.router.navigate(['home/appraisal']);
            },
             redirectMsgTimeOut)
          }
          // if(result.statusCode==409)
          // {
          //     this.error = true;
          //    console.log("Error Create:"+result);
          //   this.error_msg = result.description;
          // }
          else
          {
            this.error = true;
            this.submitButtonDisabled= false;
            console.log("Error Create:"+result);
            this.error_msg = result.description;
            setTimeout(() => 
            {
               this.error = false;
              // this.router.navigate(['/home/kra-template']);
            })
          }
        },
         err =>
        {
          this.error = true;
          this.submitButtonDisabled= false;
          console.log("Error result",err);
          console.log("Error result",err.error.description);
          this.errorMsg =err.error.description;
          console.log( this.errorMsg);
        })
    }
    
     }

 handleKeyPress(event) {
      if (event.key === ' ' && event.target.selectionStart === 0) {
        event.preventDefault();
      }
    }

    validateInput(event) {
      const input = event.target;
      let value = input.value;
    
      // Trim leading spaces
      value = value.trimStart();
    
      // Replace multiple spaces with a single space
      value = value.replace(/\s+/g, ' ');
    
      // Set the modified value back to the input
      input.value = value;
    
      // Check if the input is invalid (only spaces or empty)
      this.invalidInput = value.trim().length === 0;
    }

 

    validateRating(event: any): void {
      const input = event.target.value;
      const regex = /^([0-4](\.\d{0,2})?|5(\.0{0,2})?)$/;
  
      if (!regex.test(input)) {
        event.target.value = input.slice(0, -1);
      }
    }
    

     
}
