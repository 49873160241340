import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Router,ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { ExcelService} from '../../services/excel.service';
import { ImportShiftEmployeeComponent } from '../import-shift-employee/import-shift-employee.component';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';

@Component({
  selector: 'app-shiftemployeelist',
  templateUrl: './shiftemployeelist.component.html',
  styleUrls: ['./shiftemployeelist.component.scss']
})
export class ShiftemployeelistComponent implements OnInit {

  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any = [];
  selected :true;
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  branchlist: any = [];
  years: number[] = [];
  usertype: any;
  organizationId: any;
  searchForm:FormGroup;
  sortedData: any;
  p:number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  shiftList: any=[];
  dateFormat: any;
  dataExport: any=[];
  exportingdata: any;
  exported: any = {};
  importerror: boolean =  false;
  errormessagejson: any;


  constructor(  private formBuilder: FormBuilder,
    private router : Router,
    private payrollService: PayrollService, private datepipe : DatePipe ,  private dialog:MatDialog,
    private ActivatedRoute:ActivatedRoute, @Inject(AppComponent) public AppData: any ,public ExcelService: ExcelService) { }

  ngOnInit(): void {


    this.searchForm = this.formBuilder.group({
      clientserach: [null], // Initialize with null
      shiftserach: [null], // Initialize with null
      startDateSearch:[null],
      endDateSearch:[null]
    });
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertype.userId;
     this.organizationId = this.usertype.organization.organizationId;
     
    // this.postForm = this.formBuilder.group({
    //   client : [null,[Validators.required]],
    //   calendarName : [null,[Validators.required]],
    //   calendarDescription : [null,[Validators.required]],
    //     startDate : [null,[Validators.required]],
    //     endDate : [null,[Validators.required]],
    //     workDayStart : [null,[Validators.required]],
    //     workingDays : [null,[Validators.required]],
    //     leaveDays :  [null,[Validators.required]],
    //  });

     this.payrollService.getclientlist(this.organizationId).subscribe((resultData:any) =>
     {
       this.clientList=resultData;

       
     });
  }

  getshift(event)
  {
    console.log(event);
    let clientId = this.searchForm.value.clientserach;
    this.payrollService.getshiftbyclient(clientId).subscribe((resultData:any) =>
    {
      this.shiftList=resultData;

      
    });
  }
  
  get serachvalidate() { return this.searchForm.controls; }


  pageChages()
  {
    this.p=1;
  }
  searchSubmit()
  {
    this.resultData = [];
    this.noRecordsFound = false;
    console.log('search submit')
    let shiftserach = this.searchForm.value.shiftserach;
    console.log('shiftserach', shiftserach)
   
    let  startDateSearch = this.searchForm.value.startDateSearch;
    let  endDateSearch = this.searchForm.value.endDateSearch;
    console.log('startDateSearch', startDateSearch);
    console.log('endDateSearch', endDateSearch);

    if((!shiftserach || shiftserach == 'null') && (!startDateSearch || startDateSearch == 'null') &&  (!endDateSearch || endDateSearch == 'null'))
    {
      alert('Please select Shift.');
      return;
    }

    // if (clientserach && clientserach !== 'null' && ((!startDateSearch || startDateSearch === 'null') || (!endDateSearch || endDateSearch === 'null')))
    // {
    //   alert('Please select startDate and endate.');
    //   return;
    // }

    if( (shiftserach && shiftserach != 'null') && (startDateSearch && startDateSearch != 'null')  &&  (endDateSearch && endDateSearch != 'null'))
    {
      this.loader=true;
      this.backdrop=true;
        let startDate = this.datepipe.transform(startDateSearch, 'yyyy-MM-dd');
        let endDate = this.datepipe.transform(endDateSearch, 'yyyy-MM-dd');
      this.payrollService.getemployeeshiftbyClientAndShiftAndDate(shiftserach, this.searchForm.value.clientserach, startDate ,endDate ).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if ((shiftserach && shiftserach != 'null') && (!startDateSearch || startDateSearch == 'null')  &&  (!endDateSearch ||  endDateSearch == 'null'))
    {
      this.loader=true;
      this.backdrop=true;
      this.payrollService.getemployeeshiftbyClientAndShift(shiftserach , this.searchForm.value.clientserach).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }else{
      alert('Please select client and shift.');
      return;
    }
    
  }

  clearTable()
  {
    
    this.resultData = [];
    this.noRecordsFound = false;
    console.log('clear')
    this.searchForm.clearValidators;
    // this.payrollService.get().subscribe((elementslistData:any) =>
    // {
    //   console.log('elementslistData',elementslistData)
    //   this.resultData = elementslistData;
    //   console.log(this.resultData);
    //   if(this.resultData.length == 0)
    //   {this.noRecordsFound = true;
    //     this.nodatafound = true;
    //     console.log("nodata");
    //   }
    //   if(elementslistData.statusCode == 204){
    //     this.noRecordsFound = true;
    //   }
    // },
    // (error) => {
    //   this.nodatafound = true;
    //   console.log("error")
    //   if(error.status == 404){
    //     this.nodatafound = true;
    //     console.log("404")
    //   }
    // }
    // )
  }


  
  updateStatus(id,status)
	{
    this.payrollService.updateEmployeeShiftStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  updateform(editData)
  {
      this.router.navigateByUrl("/home/employee-shift/" + editData.shiftEmployeeAssignId);
}
    importshiftemploye()
    {
      const dialogRef = this.dialog.open(ImportShiftEmployeeComponent, {
        //height: 'auto',width: '600px',
        width: '500px',height:'fit-content',
      });
    
      dialogRef.afterClosed().subscribe(result =>
      {
        //this.departmentList();
    
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        }
        else 
        {
           if(result.length != 0)
           {
    
            this.error = true;
            //this.error_msg = result.message;
            this.importerror = true;
            this.errormessagejson = result;
          //  this.showerrormessage();
            // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
           }
            else
            {
            if(result.statusCode > 400)
            {
              this.error = true;
            
              this.importerror = false;
            }
            }
    
        }
      });
    }

exportToExcel()
  {

    let shift = this.searchForm.value.shiftserach;
    let startDate = this.searchForm.value.startDateSearch;
    let endDate = this.searchForm.value.endDateSearch;
   
      this.dataExport.length = 0;
      this.exportingdata = this.resultData ;

      for(let exportingdata of this.exportingdata)
      {
        let startDate =this.datepipe.transform(exportingdata.startDate, this.dateFormat);
        let endDate =this.datepipe.transform(exportingdata.endDate, this.dateFormat);
       

        this.exported ={
          Client           : exportingdata.client.clientName,
          ShiftCalendar         :exportingdata.shiftCalender.shiftCalendarName,
          ShiftName        : exportingdata.shift.shiftName,
          StartTime          : exportingdata.shift.startTime,
          EndTime          : exportingdata.shift.endTime,
          RosterType       : exportingdata.rosterType.listTypeValueName,
          StartDate          :startDate,
          EndDate                  :endDate,
          EmployeeCode             : exportingdata.employee.employeeNumber,
          EmployeeName           : exportingdata.employee.firstName+" "+exportingdata.employee.lastName,
          MobileNumber           : exportingdata.employee.mobileNumber,
          Email                  : exportingdata.employee.organizationEmail,
          
      
        }

        this.dataExport.push(this.exported);
      }
      this.ExcelService.exportAsExcelFile(this.dataExport,"Employee Shifts");
  }

  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
}
