
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';
import { PayrollService } from 'src/app/providers/payroll.service';
@Component({
  selector: 'app-shiftdetails',
  templateUrl: './shiftdetails.component.html',
  styleUrls: ['./shiftdetails.component.scss']
})
export class ShiftdetailsComponent implements OnInit {
  resultData: any = {};
  loader:any = true;
  constructor(
    private matDialog : MatDialog,
    private payrollService: PayrollService,private router:Router,
    private dialogref : MatDialogRef<ShiftdetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    if(this.data)
    {
      this.payrollService.getShiftId(this.data).subscribe((resultData:any) =>
      {
        this.loader  = false;
        this.resultData=resultData;
      });
    }
  }
  closedialog()
  {
     this.dialogref.close();
  }

}
