

<div class="container">
  <br />
  <!-- <h4 align="center">Assign Employee to this project</h4><br> -->
  <div class="top-content">
    <h4>Approvers</h4>
    <span class="material-icons mat-close" style="color: red" mat-dialog-close
      >cancel</span
    >
  </div>

  <div>
    
    <div  *ngIf="stroreApprovelist?.approver?.approverOneObject">
      <div class="row">
        <div class="col-md-8">
          <h6>{{ stroreApprovelist?.approver?.approverOneObject?.firstName }}</h6>
          <h6 class="email-id-new">
            {{ stroreApprovelist?.approver?.approverOneObject?.organizationEmail }}
          </h6>
        </div>
        <div class="col-md-4">
          <h6
            class="approver-status"
            [style.color]="approvalStatus(stroreApprovelist?.approverOneStatus.listTypeValueName)"
            [style.backgroundColor]="
              approvalBackground(stroreApprovelist?.approverOneStatus.listTypeValueName)
            "
            align="center"
          >
            <!-- {{ data?.approvalStatusName }} -->
            {{stroreApprovelist?.approverOneStatus.listTypeValueName}}
          </h6>
        </div>
      </div>
      <hr />
    </div>
    <div  *ngIf="stroreApprovelist?.approver?.approverTwoObject" >
      <div class="row">
        <div class="col-md-8">
          <h6>{{ stroreApprovelist?.approver?.approverTwoObject?.firstName }}</h6>
          <h6 class="email-id-new">
            {{ stroreApprovelist?.approver?.approverTwoObject?.organizationEmail }}
          </h6>
        </div>
        <div class="col-md-4" >
          <h6
            class="approver-status"
            [style.color]="approvalStatus(stroreApprovelist?.approverTwoStatus.listTypeValueName)"
            [style.backgroundColor]="
              approvalBackground(stroreApprovelist?.approverTwoStatus.listTypeValueName)
            "
            align="center"
          >
            {{stroreApprovelist?.approverTwoStatus.listTypeValueName}}
          </h6>
        </div>
      </div>
      <hr />
    </div>
    <div *ngIf="stroreApprovelist?.approver?.approverThreeObject">
      <div class="row">
        <div class="col-md-8">
          <h6>{{ stroreApprovelist?.approver?.approverThreeObject?.firstName }}</h6>
          <h6 class="email-id-new">
            {{ stroreApprovelist?.approver?.approverThreeObject?.organizationEmail }}
          </h6>
        </div>
        <div class="col-md-4">
          <h6
            class="approver-status"
            [style.color]="
              approvalStatus(stroreApprovelist?.approverThreeStatus.listTypeValueName)
            "
            [style.backgroundColor]="
              approvalBackground(stroreApprovelist?.approverThreeStatus.listTypeValueName)
            "
            align="center"
          >
            {{ stroreApprovelist?.approverThreeStatus.listTypeValueName}}
          </h6>
        </div>
      </div>
      <hr />
    </div>

    <div *ngIf="stroreApprovelist?.approver?.approverFourObject">
      <div class="row">
        <div class="col-md-8">
          <h6>{{ stroreApprovelist?.approver?.approverFourObject?.firstName }}</h6>
          <h6 class="email-id-new">
            {{ stroreApprovelist?.approver?.approverFourObject?.organizationEmail }}
          </h6>
        </div>
        <div class="col-md-4">
          <h6
            class="approver-status"
            [style.color]="approvalStatus(stroreApprovelist?.approverFourStatus?.listTypeValueName)"
            [style.backgroundColor]="
              approvalBackground(stroreApprovelist?.approverFourStatus?.listTypeValueName)
            "
            align="center"
          >
            {{ stroreApprovelist?.approverFourStatus?.listTypeValueName }}
          </h6>
        </div>
      </div>
      <hr />
    </div>

    <div *ngIf="stroreApprovelist?.approver?.approverFiveObject">
      <div class="row">
        <div class="col-md-8">
          <h6>{{ stroreApprovelist?.approver?.approverFiveObject?.firstName }}</h6>
          <h6 class="email-id-new">
            {{ stroreApprovelist?.approver?.approverFiveObject?.organizationEmail }}
          </h6>
        </div>
        <div class="col-md-4">
          <h6
            class="approver-status"
            [style.color]="approvalStatus(stroreApprovelist?.approverFiveStatus?.listTypeValueName)"
            [style.backgroundColor]="
              approvalBackground(stroreApprovelist?.approverFiveStatus?.listTypeValueName)
            "
            align="center"
          >
            {{ stroreApprovelist?.approverFiveStatus?.listTypeValueName }}
          </h6>
        </div>
      </div>
      <hr />
    </div>
    <div class="top-content">
      <h4>FYI</h4>
    </div>
    <!-- <div> -->
      <!-- <div class="row"> -->
        <div class="col-md-6 mt-n3 ml-n3">
          <h6>{{ stroreApprovelist?.approver?.fyiObject?.firstName }}</h6>
          <h6 class="email-id-new">
            {{ stroreApprovelist?.approver?.fyiObject?.organizationEmail }}
          </h6>
        </div>
      <!-- </div> -->
    <!-- </div> -->
  </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
   
  ></div>
</div>

<mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
  <div class="card-body-nodata">
    <div class="alert-nodata alert-primary">
      <h4 align="center" class="h4">Approvers Not Found</h4>
    </div>
  </div>
</mat-card>