<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <!-- <h3 mat-dialog-title>Add Stream</h3> -->
    <h3 class="headerDesign">{{qualificationId ? "Edit" : "Add"}} Stream</h3>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <!-- <div class="form-group col-md-6">
                <label class="form-control-label">Qualification Name <span class="star">*</span></label>
                <input type="text" --(keyup)="checkExistQualificationName($event)" formControlName="qualificationName" id="qualificationName" --[ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-control email-input select-placeholder" value="" placeholder="Qualification Name">
                <div class="valid-msg" --*ngIf="(validate.qualificationName.invalid && validate.qualificationName.touched ) || validate.qualificationName.dirty || submitted">
                    <span --*ngIf="validate.qualificationName.errors?.required" class="text-danger">Please enter qualification name</span>
                    <span --*ngIf="departNameExistStatus" class="span-error"> Qualification name already exist!</span>
                </div>
            </div> -->

            <div class="form-group col-md-12" style="height: 70px !important;">
                <label class="form-control-label">Stream Name <span class="star">*</span></label>
                <input type="text" (keyup)="checkExistQualificationName($event)" formControlName="streamName" id="streamName" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" 
                class="form-control email-input select-placeholder" value="" placeholder="Qualification Name">
                <!-- <div class="valid-msg" *ngIf="(validate.streamName.invalid && validate.streamName.touched ) || validate.streamName.dirty || submitted">
                    <span *ngIf="validate.streamName.errors?.required" class="text-danger">Please Enter Qualification Name</span>
                    <span *ngIf="validate.streamName.errors?.pattern" class="text-danger">Please Enter Valid  Qualification Name</span>
                    <span *ngIf="qualificationNameExistStatus" class="text-danger"> Qualification Name already exist!</span>
                </div> -->
                <span *ngIf="validate.streamName && submitted"  class="text-danger valid-msg" >{{
                    projectCode()
                  }}
                </span>
                <span *ngIf="validate.streamName.errors?.pattern" class="text-danger valid-msg">Please Enter Valid  Stream Name</span>
                <span *ngIf="qualificationNameExistStatus" class="text-danger valid-msg"> Stream Name already exist!</span>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close mat-raised-button class="cancelDesign">Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button [disabled]="btnDisabled" style="" class="searchBtn">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div> -->
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>