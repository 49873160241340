import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { map, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { UserService } from '../providers/user.service';

//import { Stomp } from '@stomp/stompjs';
import Swal from 'sweetalert2';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

//import {base_url,httpOptions, responseMsgTimeOut , redirectMsgTimeOut  } from 'src/app/providers/properties'; from '../providers/properties';
import { NotificationResourceDetailComponent } from '../recruitment/notification-resource-detail/notification-resource-detail.component';
import { httprecruitmentOptions } from '../providers/properties';
import{ GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'app-super-admin-home',
  templateUrl: './super-admin-home.component.html',
  styleUrls: ['./super-admin-home.component.scss']
})
export class SuperAdminHomeComponent implements OnInit 
{
  
  appLogo = GlobalConstants.appLogo;
  appLogoIcon = GlobalConstants.appLogoIcon;

  sideNavContent = "228px";
  expand: Boolean;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  Swal: any;
  isShow :boolean = false;
  active: boolean;
  isShowsettings:boolean = false;
  shrink: Boolean;
  right: Boolean;

  usertype:any;
  employeeName :string;
  stompClient: any;
  notificationlist : any = [];
  notifylist :any;
  id1:any;
  id2:any;
  delete_notify : any = [];
  notificationcount : any = 0;
  isShow1 :boolean = false;

  constructor(
    private router:Router,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private UserService: UserService,
  ) { }

  resultData : any = {};

  headerProfileImage: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit(): void
  {
    this.shrink = false;
    this.expand = true;
    this.right = true;

    if (this.isHandset$)
    {
      this.expand = true
    }

    if (this.isHandset$)
    {
      this.expand = true;
    }
    /* 
    //notification start here
    let x = localStorage.getItem("notifications");

    if(x != null)
    {
      this.notificationlist = JSON.parse(x);
    }

    //console.log("ng inti notification" + JSON.stringify(this.notificationlist));

    if(this.notificationlist != null)
    {
      this.notificationcount = this.notificationlist.length;
    }

    //this.usertype = JSON.parse(localStorage.getItem('user_hrms'));
    //this.employeeName = this.usertype.employeeName;

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let employeeId = this.usertype.userId; 
    // let organizationId = this.usertype.organization.organizationId;
    this.employeeName  = this.usertype.firstName;

    //this.usertype = this.usertype.role.roleName;

    let nofitication = "/notification/";
    let ws = new SockJS(`${rec_base_url}getresourceonboarding` , {headers : {Authorization : 'Basic ZW5vdGU6ZW5vdGUkcmVjdXJpdG1lbnQj' }});

    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect({}, function (frame: any)
    {
        _this.stompClient.subscribe(nofitication, function (sdkEvent: any)
        {
            _this.onMessageReceived(sdkEvent);
        });
        //_this.stompClient.reconnect_delay = 2000;
    }, this.errorCallBack);
    //notification end here

    this.getUserData(); */
  }

  //Get User Data
  isActiveOrganization(): boolean {
    const currentUrl = this.router.url;
    return (
      currentUrl.includes('entity') ||
      currentUrl.includes('add-entity') ||
      currentUrl.includes('edit-entity')

    )
  }
  getUserData()
  {
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let userId = this.usertype.userId;
    let organizationId = this.usertype.org

    if(userId)
    {
      this.UserService.getUserById(userId).subscribe((resultData:any) =>
      {
          this.resultData=resultData;

          if(resultData.userAttachment)
          {
              this.UserService.getuserImage(resultData.userAttachment.fileViewUrl).subscribe( blob => {
                const a = document.createElement('a')
                const objectUrl: any = blob;
                this.headerProfileImage = blob;
              },
              err => {

              }
            );
          }
      });
    }
  }

  errorCallBack(error: string)
  {
    // console.log("errorCallBack -> " + error)
    setTimeout(() => {
    }, 5000);
  }

  onMessageReceived(message: any)
  {
    // console.log("Message Recieved from Server :: " + message.body);
    let x = localStorage.getItem("notifications")
    //console.log(x);

    let y = JSON.parse(x);
    //console.log("notifications");
    //console.log(y);

    if(y !=  null)
    {
      if(y.length != 0)
      {
        //alert("x.lengtj " + y.length)
        this.notificationlist = y;
        //console.log(this.notificationlist);
      }
    }
    this.notifylist = JSON.parse(message.body);
    this.id1 = this.notifylist.resource.resourceId;
    //console.log(this.id1);
    //console.log("this.notificationlist" + JSON.stringify(this.notificationlist));
    let resourceidExist = false;

    if(this.notificationlist.length != 0)
    {
      for(let e of this.notificationlist)
      {
        this.id2 = e.resource.resourceId;
        //console.log(this.id2);
        if(this.id1 == this.id2)
        {
          resourceidExist = true;
        }
      }
    }

    //console.log("resourceidExist" + resourceidExist);
    if(resourceidExist == false)
    {
      this.notificationlist.push(JSON.parse(message.body));
      //console.log(this.notificationlist);
    }
    this.notificationcount  = this.notificationlist.length;
    //console.log("this.notificationcount" + this.notificationcount);
    localStorage.setItem('notifications', JSON.stringify(this.notificationlist));
  }


  delete(i:number)
  {
    this.notificationlist.splice(i,1);
    this.notificationcount  = this.notificationlist.length;
    localStorage.setItem('notifications', JSON.stringify(this.notificationlist));
  }

  deleteAll(all:number)
  {
    this.notificationlist.splice(this.notificationlist);
    this.notificationcount  = this.notificationlist.length;
    localStorage.setItem('notifications', JSON.stringify(this.notificationlist));
  }

  resourceDetails(resource)
  {
    //console.log("resource" +resource);
    var dialogbox = this.dialog.open(NotificationResourceDetailComponent, {
      width: '900px',height:'560px',
      data:resource
    });
    // this.selectedvalue != null
    // this.isDetails = ! this.isDetails;
  }

  MatDrawControl()
  {
    this.expand = !this.expand
    if (this.expand == true)
    {
      this.sideNavContent = "228px"
      //console.log(this.sideNavContent)
    }
    else
    {
      this.sideNavContent = "70px"
      //console.log(this.sideNavContent)
    }
  }

  ngAfterViewInit()
  {
    this.breakpointObserver.observe(['(max-width:800px)']).subscribe((res)=>{
      if(res.matches)
      {
        this.sidenav.mode='over';
        this.sidenav.close();
        //console.log(this.sidenav.mode)
      }
      else
      {
        this.sidenav.mode='side';
        this.sidenav.open();
        //console.log(this.sidenav.mode)
      }
    });
  }

  /* logout()
  {
	  //localStorage.clear();
	  localStorage.removeItem('enoteUserData');
	  this.router.navigate(['login']);
  } */

  logout()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      //text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(10 123 235)',
      cancelButtonColor: 'rgb(233 49 49)',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed)
      {
        localStorage.clear();
        //localStorage.removeItem('enoteUserData');
        this.router.navigate(['login']);


        Swal.fire({
          icon: 'success',
          text: "Done"
        });
        window.location.reload()
      }
    })
  }

  toggle()
  {
    this.isShow = !this.isShow
  }

  show()
  {
    this.active = true
  }

  showNotification(val)
  {
   this.isShow1 = !this.isShow1

  }

}
