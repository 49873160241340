<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class=""><span class="h5 sub-content">Manage Variable Pay</span></div>
                <div class="sub-header-buttons">
                    <!--  <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                    -->
                    <!-- <a routerLink="/home/add-element" routerLinkActive="active" color="primary" mat-raised-button
                        title="Add Element" class="ml-2">
                        Add Element
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>


<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div>
                    <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Search Employee </label>
                                <input formControlName="employeecodeSearch" type="search" autocomplete="off"
                                    class="form-control search-input form-control form-control-lg form-control-solid"
                                    value="" placeholder="Search Employee">
                                <small class="text-muted"  style="
                                color: rgba(0, 0, 0, 0.42) !important;
                                position: relative;
                                left: 6px;
                                font-family: 'Mulish', sans-serif;">Note : Employee Number</small>
                                <!-- <div *ngIf="this.req">
                                    <span style="color:red">*Employee Number required</span>
                                </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Financial Year </label>
                                <!-- <select formControlName="financialyearSearch"
                             <select formControlName="financialyearSearch"
                                    class="form-select form-select-solid form-select-lg select2-new"
                                    (change)="selectFinancialyear($event)">
                                    <option [value]=null>
                                        --
                                        Select
                                        Financialyear
                                        ---
                                    </option>
                                    <option *ngFor="let row of finYearList; let i=index;"
                                        value="{{row.financialYearId}}">
                                        {{row.financialFromMonth}}-{{row.financialFromYear}} To
                                        {{row.financialToMonth}}-{{row.financialToYear}}</option>
                                </select>  -->
                                <mat-select class="mat-select form-control email-input select-placeholder"
                                appearance="fill"formControlName="financialyearSearch"
                                (selectionChange)="selectFilterFinancialyear($event)"
                                placeholder=" Select Financial year">
                                <mat-option [value]=null> Select Financial Year</mat-option>
                                <mat-option *ngFor="let row of finYearList; let i=index;" class="text-color"
                                value="{{row.financialYearId}}">  {{row.financialFromMonth}}-{{row.financialFromYear}} To
                                {{row.financialToMonth}}-{{row.financialToYear}}</mat-option>
                            </mat-select>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Period </label>
                                <!-- <select formControlName="periodSearch"
                                    class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]=null>
                                        --
                                        Select
                                        Period
                                        ---
                                    </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;"
                                        value="{{row.financialPeriodId}}">
                                        {{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>  -->
                                <mat-select class="mat-select form-control email-input select-placeholder"
                                appearance="fill"formControlName="periodSearch"
                                placeholder="  Select Period">
                                <mat-option [value]=null> Select Period</mat-option>
                                <mat-option *ngFor="let row of filterfinPeriodList; let i=index;" class="text-color"
                                value="{{row.financialPeriodId}}">  {{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option>
                            </mat-select>
                            </div>
                            <div class="col-md-3 margin-button" style="margin-bottom: -65px;">
                                 <!-- <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button>  -->
                                    <button type="submit" mat-raised-button
                                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button" [disabled]="isProcessing"
                                >
                                Search
                            </button>
                                    <!-- <button style="margin-left: 10px;" type="reset" mat-raised-button
                                    class="btn-clear search-btn mr-2"
                                     (click)="clearTable()">Clear</button>  -->
                                     <button mat-stroked-button type="reset" class="apply-buttons" (click)="clearTable()" [disabled]="isProcessing"
                                  
                                     style="margin: 8px 0px 0px 5px;background:white;border: 1px solid #ddd;position: relative ;
                                     top: -8%;  font-family: Mulish; color: black; font-size: 16px; font-weight: 610;">Clear</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div>
                     <b class="setDesign">Add Variable Pay</b>
                    <form [formGroup]="variablepayform" (ngSubmit)="calculateVariablePay()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Financial Year<span class="star">
                                        *</span></label>
                                <!-- <select formControlName="financialyear"
                                    class="form-select form-select-solid form-select-lg select2-new"
                                    (change)="selectFinancialyear($event)">
                                    <option [value]="null"> -- Select Financialyear --- </option>
                                    <option *ngFor="let row of finYearList; let i=index;"
                                        value="{{row.financialYearId}}">
                                        {{row.financialFromMonth}}-{{row.financialFromYear}} To
                                        {{row.financialToMonth}}-{{row.financialToYear}}</option>
                                </select> -->
                                <mat-select class="mat-select form-control email-input select-placeholder"
                                appearance="fill" formControlName="financialyear"
                                (selectionChange)="selectFinancialyear($event)"
                                placeholder=" Select Financial year">
                                <mat-option [value]=null> Select Financial Year</mat-option>
                                <mat-option *ngFor="let row of finYearList; let i=index;" class="text-color"
                                value="{{row.financialYearId}}">  {{row.financialFromMonth}}-{{row.financialFromYear}} To
                                {{row.financialToMonth}}-{{row.financialToYear}}</mat-option>
                            </mat-select>

                                <div class="valid-msg"
                                    *ngIf="(validate?.financialyear?.invalid && validate?.financialyear?.touched) || validate?.financialyear?.dirty || submitted">
                                    <span *ngIf="validate?.financialyear.errors?.required" class="text-danger">Please
                                        Select
                                        Financialyear</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="form-control-label">Period<span class="star"> *</span></label>
                                <!-- <select formControlName="period"
                                    class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null"> -- Select Period --- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;"
                                        value="{{row.financialPeriodId}}">
                                        {{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select> -->
                                <mat-select class="mat-select form-control email-input select-placeholder"
                                appearance="fill"formControlName="period"
                                placeholder="  Select Period">
                                <mat-option [value]=null> Select Period</mat-option>
                                <mat-option *ngFor="let row of finPeriodList; let i=index;" class="text-color"
                                value="{{row.financialPeriodId}}">  {{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option>
                            </mat-select>
                                <div class="valid-msg"
                                    *ngIf="(validate?.period?.invalid && validate?.period?.touched) || validate?.period?.dirty || submitted">
                                    <span *ngIf="validate?.period?.errors?.required" class="text-danger">Please
                                        Select Period</span>
                                </div>
                            </div>
                            <!-- <div class="form-group col-md-3">
                                <label class="form-control-label">Employee Code<span class="star">
                                        *</span></label>
                                <input  type="text" placeholder="Search Employee Code" formControlName="employeecode"
                                    [matAutocomplete]="auto" class="designchange  form-control"
                                    (keyup)="empSearch($event)">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option value="All" (click)="empAllSelect()">All</mat-option>
                                    <mat-option *ngFor="let emp of empList" [value]="emp.employeeNumber"
                                        (click)="empSelect(emp)">
                                        {{emp.employeeNumber}} - {{emp.firstName}}
                                    </mat-option>
                                    <mat-option [value]="null" *ngIf="empCode && empList.length === 0" (click)="noEmployeeFound()">No
                                        Employee Found</mat-option>
                                </mat-autocomplete>
                                <mat-icon class="material-symbols-outlined"
                                    style="position:absolute;top:37px;right: 20px;">
                                    search
                                </mat-icon>
                                <div class="valid-msg"
                                    *ngIf="(validate.employeecode.invalid && validate.employeecode.touched) || validate.employeecode.dirty || submitted">
                                    <span *ngIf="validate.employeecode.errors?.required" class="text-danger">Please
                                        Select
                                        Employeecode</span>
                                </div>
                            </div> -->

                            <div class="form-group col-md-3">
                                <label class="form-control-label">Employee Code<span class="star"> *</span></label>
                                <input
                                  type="text"
                                  placeholder="Search Employee Code"
                                  formControlName="employeecode"
                                  [matAutocomplete]="auto"
                                  class="designchange  form-control"
                                  (keyup)="empSearch($event)"
                                  
                                />
                                <mat-autocomplete #auto="matAutocomplete">
                                  <ng-container *ngIf="empList?.length > 0">
                                    <mat-option value="All" (click)="empAllSelect()">All</mat-option>
                                    <mat-option
                                      *ngFor="let emp of empList"
                                      [value]="emp.employeeNumber"
                                      (click)="empSelect(emp)"
                                    >
                                      {{ emp.employeeNumber }} - {{ emp.firstName }}
                                    </mat-option>
                                  </ng-container>
                                  <mat-option [value]="null" *ngIf="empList.length === 0 && empCode" (click)="noEmployeeFound()">
                                    No Employee Found
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-icon
                                  class="material-symbols-outlined"
                                  style="position: absolute; top: 37px; right: 20px"
                                >
                                  search
                                </mat-icon>
                                <div
                                  class="valid-msg"
                                  *ngIf="
                                    (validate?.employeecode?.invalid && validate?.employeecode?.touched) ||
                                    validate?.employeecode?.dirty ||
                                    submitted
                                  "
                                >
                                  <span
                                    *ngIf="validate?.employeecode.errors?.required"
                                    class="text-danger"
                                    >Please Select Employee Code</span
                                  >
                                </div>
                              </div>
                              
                            <!-- <div class="form-group col-md-3 container">
                                <label class="form-control-label"></label>
                                <button type="submit" class="submit action-button btn btn-primary btn-sm">Calculate Variable
                                    Pay</button>
                            </div> -->
                            <div class="col-md-3" style="">
                                <button type="submit" mat-raised-button style="margin-top: 12%;"
                                    class=" searchBtn " [disabled]="isprocessing1 || variablepayform?.invalid
                                    ">Calculate Variable
                                    Pay</button>
                            </div>
                        </div>
                        <!-- <div class="row pb-3">
                            <div class="col-md-12 text-right"> -->
                        <!-- <button  class="submit action-button btn btn-primary btn-sm" [routerLink]="['home/employee-leave-list']"
                                                style="margin-right: 20px;" (click)="close()">Cancel</button> -->
                        <!-- <button type="submit" class="submit action-button btn btn-primary btn-sm">Calculate Variable
                                    Pay</button>
                            </div>
                        </div> -->
                    </form>
                </div>


<!-- 
                <div class="row pb-3" *ngIf="!loader && showTable" >
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem" (change)="pageChages($event)" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div> -->
                <div class="col-md-12 text-right" *ngIf="!loader && showTable" >
                    <div class="row p-0 m-0"    *ngIf="resultData.length > 5 && (resultData | mytask : search).length > 0" >
                        <div class="col-xl-12 col-md-12  "   *ngIf="(resultData | mytask : search).length > 5">
                          <div class="select-pagination">
                            <span>Show : </span>
                            <select style="    border-radius: 6px;"
                              class="select_list new-select_list"
                              [(ngModel)]="mySelect"
                              (change)="changefirstpage()">
                              <option class="form-control" value="5">5</option>
                              <option class="form-control" value="10">10</option>
                              <option class="form-control" value="15">15</option>
                              <option class="form-control" value="20">20</option>
                              <option class="form-control" value="25">25</option>
                            </select>
                          </div>
                        </div>
                      </div>
                </div>
                <div *ngIf="showTable" class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    
                        <b class="setDesign">Manage Variable Pay</b>
                    
                    <div class="">
                    <table *ngIf="!loader" class="table --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">Action</th> -->
                                <th>Financial Year</th>
                                <th>Period</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Rating</th>
                                <th>Percentage</th>
                                <th>Total Variable Pay</th>
                                <th>Calculated Pay</th>
                                <!-- <th class="text-center">Status</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let e of resultData | mytask: search | paginate: { itemsPerPage: pagePerItem, currentPage: p } ">
                                <!-- <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="controls-list"> -->
                                <!-- <button mat-menu-item routerLink="/home/edit-element/{{e.elementId}}"
                                            routerLinkActive="active">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button> -->
                                <!-- <button mat-menu-item routerLinkActive="active" (click)="edit(e.elementId)">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>

                                        <button mat-menu-item *ngIf="e.status.listTypeValueId == 1"
                                            (click)="statuschangeactive(e.elementId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>Inactive</span>
                                        </button>

                                        <button mat-menu-item *ngIf="e.status.listTypeValueId ==2"
                                            (click)="statuschangeinactive(e.elementId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>

                                    </mat-menu>
                                </td> -->
                                <td>{{e.financialYear.financialFromMonth}}-{{e.financialYear.financialFromYear}} To
                                    {{e.financialYear.financialToMonth}}-{{e.financialYear.financialToYear}}</td>
                                <td>{{e.financialPeriod.financialperiodMonth}}-{{e.financialPeriod.financialPeriodYear}}
                                </td>
                                <td>{{e.employee.employeeNumber}}</td>
                                <td>{{e.employee.firstName}} </td>
                                <td>{{e.rating}}</td>
                                <td>{{e.percentage}}</td>
                                <td>{{e.totalVariablePay}}</td>
                                <td>{{e.calculatedPay}}</td>

                                <!-- <td>{{e.categoryType.listTypeValueName}}</td>
                                <td>{{e.elementName}}</td>
                                <td>{{e.orderNumber}}</td>
                                <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block"
                                        *ngIf="e.status.listTypeValueId  == 1">
                                        Active
                                    </span>

                                    <span class="btn btn-outline-danger btn-sm btn-block"
                                        *ngIf="e.status.listTypeValueId  == 2">
                                        In-Active
                                    </span>
                                </td> -->
                            </tr>
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td style="text-align: center; font-size: 21px;font-weight: 770;" colspan="8" *ngIf="noRecordsFound">No Records
                                    Found!</td>
                            </tr>
                        </tbody> -->
                        <tbody style="    border-left: 1px solid #ddd;
                        border-right: 1px solid #ddd;">
                                                        <tr>
                                                            <td   style="
                                                            text-align: center;
                                                            font-size: larger;
                                                            font-weight: bold;
                                                          " colspan="11" 
                                                          *ngIf="noRecordsFound||((resultData | mytask : search)?.length === 0 && search) ||resultData?.length == 0">Manage Variable Pay Records Not Found!</td>
                                                        </tr>
                                                    </tbody>
                        <!-- <div ></div> -->
                    </table>
                </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="showing-record">
                        </div>
                    </div>
                    <!-- <div class="col-md-8 text-right">
                        <pagination-controls style="float:right" (pageChange)="p = $event" *ngIf="!this.noRecordsFound"></pagination-controls>
                    </div> -->
                    <div
                    class="pagination-content"
                    *ngIf="resultData?.length > 5 && (resultData | mytask : search).length > 0"
                    id="page"
                  >
                    <pagination-controls  *ngIf="(resultData | mytask : search).length > 5"
                      style="position: relative; float: inline-end;top: 7px;"
                      (pageChange)="p = $event"
                      align="end"
                    ></pagination-controls>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror" (click)="showerrormessage()"  class="btn btn-danger">View Error Records</button>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>