import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';
import { ViewRoleComponent } from 'src/app/settingsComponents/roleComponent/view-role/view-role.component';
import { ActionitemService } from '../actionitemreport/actionitem.service';
import { AddEmployeeRoleComponent } from '../add-employee-role/add-employee-role.component';

@Component({
  selector: 'app-employee-role',
  templateUrl: './employee-role.component.html',
  styleUrls: ['./employee-role.component.scss']
})
export class EmployeeRoleComponent {
resultData: any=[];
  p:number = 1;
  collection: any[];Sort
  pagePerItem:any=5;
  tableSearch : any;

  errorMsg : any;
  mySelect:any =5;
  tableShow:boolean =  false;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  sortedData: any[];

  menuData : any[];
  usertData: any;
  orgId: any;
search: any;
nodatafound: boolean=false;
  loader: boolean=true;
  clientId:any
  constructor(
      private settingsservice: SettingsService,
      private dialog:MatDialog,
      private actionitem: ActionitemService,

  ) { }

  ngOnInit(): void {
    this.p=1;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.clientId=this.usertData.employee?.client?.clientId
    console.log(this.orgId);
    this.getRoleList();
  }

  addForm() //Add Dialog Box
  {
    this.p=1;
    const dialogRef = this.dialog.open(AddEmployeeRoleComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'auto',
    });
    


    dialogRef.afterClosed().subscribe(result =>
    {
      this.getRoleList();
      console.log("result::",result);
      

      if(result?.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        }
        else if(result?.statusCode ==409)
        {
          this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }
      
      // dialogRef.close()

      // if(result.statusCode == 200)
      // {
      //   this.success = true;
      // 	this.sucess_msg = result.message;
      //   setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      // }
      // else if(result.statusCode > 400)
      // {
      //   this.error = true;
      //   this.error_msg = result.message;
      //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      // }
    });
  }

  updateform(editData)
  {
    var dialogRef = this.dialog.open(AddEmployeeRoleComponent, {
      width: '500px',height:'fit-content',
      data:editData
    });

    dialogRef.afterClosed().subscribe((result:any) =>
    {
      this.getRoleList();

      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }

  updateStatus(roleId,status)
  {
    this.p=1;
    this.settingsservice.updateRoleStatus(roleId,status).subscribe( (result:any) =>
    {
      if(result.statusCode == 200)
      {
        this.loader=true
        this.tableShow=false
        // this.success = true;
        // this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.getRoleList();
      }
      else
      {
        this.loader=false
        this.tableShow=true
        // this.error = true;
        // this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    })
  }

  getRoleList()
  {
    this.p=1
    // this.tableShow =  false;
    this.loader=true

    /* Get Designation Data */
    if(this.orgId ){
      this.settingsservice.getUserRoleList(this.orgId).subscribe((result: any) => {
        this.tableShow =  true;
        this.loader=false

    this.resultData = result;
    this.resultData = Array.isArray(result) ? result: [result];

    console.log("this.resultData",this.resultData);
    
    // this.resultData = result.data || []; 
      if (result.statusCode === 204) {
        this.nodatafound = true;
        this.resultData =[]
      }

      if (  this.resultData.length > 0) {
        this.nodatafound = false;
      }
  if (this.resultData.length === 0) {
    this.nodatafound = true;
  }

  },err =>{
    this.nodatafound = true;

     this.errorMsg = err.error.message;
     console.log(this.errorMsg);
  })
    }
    else{
      if(this.clientId){
        this.settingsservice.getRoleListbyClient(this.clientId).subscribe((result: any) => {
          this.tableShow =  true;
          this.loader=false

      this.resultData = result;
      this.resultData = Array.isArray(result) ? result: [result];
      console.log("this.resultData",this.resultData);
      
      // this.resultData = result.data || []; 

      if (result.statusCode === 204) {
        this.nodatafound = true;
        this.resultData =[]
      }

      if (  this.resultData.length > 0) {
        this.nodatafound = false;
      }
  if (this.resultData.length === 0) {
    this.nodatafound = true;
  }

    },err =>{
      this.nodatafound = true;

       this.errorMsg = err.error.message;
       console.log(this.errorMsg);
    })
      }
    }
    
  }

  ucFirst(string)
  {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  viewForm(roleId) //View Dialog Box
  {
    const dialogRef = this.dialog.open(ViewRoleComponent, {
    width: '500px',height:'auto',
    data:{roleId}
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getRoleList();

      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  /* menuNames(menus)
  {
    if(menus.length >0)
    {
      let menuNames = menus.map(item => {
        return item.menu;
       });


       console.log("AD1:",menus);


       this.menuData =  menus;
    }
  } */
    checkNoData() {
      this.nodatafound = this.resultData.length === 0;
    }
    
  changefirstpage() {
    this.p = 1;
  }

  sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
//     const data = this.resultData;
//     if (!sort.active || sort.direction === '')
//     {
//       this.sortedData = data;
//       return;
//     }

//     this.sortedData = data.sort((a, b) =>
//     {
//       const isAsc = sort.direction === 'asc';

//       switch (sort.active)
//       {
//         case 'roleName':
//           return compare(a.roleName, b.roleName, isAsc);

//         case 'roleDescription':
//           return compare(a.roleDescription, b.roleDescription, isAsc);

//         default:
//           return 0;
//       }
//     });
//   }
// }//Export end

const data = this.resultData;
console.log("data::",data);

if (!sort.active || sort.direction === '') 
{
  this.sortedData = data;
  return;
}
  
      this.sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active)
              {
                case 'roleName':
                  return compare(a.roleName, b.roleName, isAsc);
        
                case 'roleDescription':
                  return compare(a.roleDescription, b.roleDescription, isAsc);
                  case 'status':
                    return compare(a.roleStatus.listTypeValueName, b.roleStatus.listTypeValueName, isAsc);
                default:
                  return 0;
              }
      });
    
    }
}
function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
