<!-- Sub header start here-->
<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Variable Pay Qualifier</span>
                </div>
                <div class="sub-header-buttons"> -->
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <!-- <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a> -->
                    <!-- <a   (click)="addForm()"  title="Add Variable Pay Qualifier" color="primary" mat-raised-button class="ml-2">
                        Add Variable Pay Qualifier
                    </a> -->
                <!-- </div>
            </div>
        </div>
    </div>
</section> -->
<nav class="navbar bg" style="margin-top: 67px;">
    <div class="container-fluid">
        <div class="row w-100">
            <div class="col-sm-4 col-md-3 d-flex align-items-center mb-2 mb-sm-0">
                <h4 class="heading-tag" style="position: relative; left: 5px;">
                    Variable Pay Qualifier
                </h4>
            </div>

            <div class=" col-sm-8 col-md-9 d-flex justify-content-end align-items-center" style="  position: relative;
            top: -4px;
            left: 28px;">
                <!-- <div class="input-group me-3" style="max-width: 400px;">
                    <input type="text" [(ngModel)]="search"
                        autocomplete="off"
                        class="form-control form-control-lg form-control-solid search-input email-input select-placeholder"
                        name="keywords"
                        value=""
                        placeholder="Search...">
                </div> -->

                <div class="d-flex" style="height: 38px;
                    padding-top: 5px !important;margin-top: -4px;">
                    <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                   
                </div>
            </div>
        </div>
    </div>
</nav>

<!-- Sub header end here-->



<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<!-- <div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div> -->


<div class="common-content" style="top: 0px;">
    <div class="card-new" style="    position: relative;
 
    margin: 5px !important;">
        <div class="card">
            <div class="card-body">

                <!--  <div class="alert alert-success alert-dismissible" *ngIf="success">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Success!</strong> Indicates a successful or positive action.
                </div> -->

                <div class="row mb-2">
                    <div class="col-md-8">
                        <div class="row mb-2 pl-0">
                            <div class="col-md-4">
                                <label class="form-control-label">Rating / Percentage<span class="star">*</span></label>

                                <input type="text" [(ngModel)]="search"
                                autocomplete="off"
                                class="form-control form-control-lg form-control-solid search-input email-input select-placeholder"
                                name="keywords"
                                value=""
                                (keypress)="allowOnlyNumbers($event)" 
                                (cut)="disableCopyPaste($event)" 
                                (copy)="disableCopyPaste($event)" 
                                (paste)="disableCopyPaste($event)" 
                                (contextmenu)="disableRightClick($event)"
                                placeholder="Search...">       
                                                 </div>
                            <div class="col-md-6">
                                <!-- <button type="button" mat-raised-button 
                                    class="search-btn mr-2" (click)="getdata()">Search</button>
                                <button style="margin-left: 20px;" type="button" mat-raised-button
                                 class="btn  search-btn mr-2"
                                    (click)="clearTable()">Clear</button> -->
                                    <button   mat-raised-button type="submit" [disabled]="btnDisable" (click)="getdata()"
                                    class="searchBtn  mr-2"  style=" margin-left: 10px;
                                    height: 34px;
                                    position: relative;
                                    top: 28px;"
                                    >Search</button>
                                <button style="margin-left: 20px;top:28px" type="button" mat-raised-button
                                   class="btn-clear mr-2" (click)="clearTable()"[disabled]="btnDisable" 
                                    >Clear</button>
                            </div>
                            <!--  <div class="col-md-3 pl-0">
                                <button class="search-btn" mat-raised-button color="primary">Search</button>
                            </div> -->
                        </div>
                    </div>
                    
               
                <div class="card-body">

                    <!-- <p><b>ADD VARIABLE PAY QUALIFIER</b></p> -->
                    <form [formGroup]="postForm" #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)">
                        <!-- <h3 mat-dialog-title>Add Stream</h3> -->
                        <!-- <h3>{{qualificationId ? "Update" : "Add"}} Variable Pay Qualifier</h3> -->
                            <div class="row">
                                <!-- <div class="form-group col-md-6">
                                    <label class="form-control-label">Qualification Name <span class="star">*</span></label>
                                    <input type="text" --(keyup)="checkExistQualificationName($event)" formControlName="qualificationName" id="qualificationName" --[ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-control email-input select-placeholder" value="" placeholder="Qualification Name">
                                    <div class="valid-msg" --*ngIf="(validate.qualificationName.invalid && validate.qualificationName.touched ) || validate.qualificationName.dirty || submitted">
                                        <span --*ngIf="validate.qualificationName.errors?.required" class="text-danger">Please enter qualification name</span>
                                        <span --*ngIf="departNameExistStatus" class="span-error"> Qualification name already exist!</span>
                                    </div>
                                </div> -->
                    
                                <div class="form-group col-md-4">
                                    <label class="form-control-label">Rating<span class="star">*</span></label>
                                    <input type="text" 
                                    (keyup)="checkExistQualificationName($event)"
                                    (keypress)="allowOnlyNumbers($event)" 

                                     formControlName="rating" 
                                     [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" 
                                     (cut)="disableCopyPaste($event)" 
                                     (copy)="disableCopyPaste($event)" 
                                     (paste)="disableCopyPaste($event)" 
                                     (contextmenu)="disableRightClick($event)"
                                    class="form-control email-input select-placeholder"  placeholder="Enter Rating" maxlength="3">
                                    <!-- <div class="valid-msg" *ngIf="(validate?.rating?.invalid && validate?.rating?.touched ) || validate?.rating?.dirty || submitted">
                                        <span *ngIf="validate?.rating.errors?.required" class="text-danger">Please enter Rating</span>
                                        <span *ngIf="validate?.rating.errors?.pattern" class="text-danger">Please enter valid rating</span>
                                        <span *ngIf="vpqExistStatus" class="span-error">Rating already exist!</span>
                                    </div> -->
                                    <div *ngIf="postForm?.get('rating')?.invalid && (postForm?.get('rating')?.touched || submitted)">
                                        <span *ngIf="postForm?.get('rating')?.errors?.required" class="text-danger">
                                            Please enter Rating
                                        </span>
                                        <span *ngIf="postForm?.get('rating')?.errors?.pattern" class="text-danger">
                                            Please enter a valid rating
                                        </span>
                                        <span *ngIf="vpqExistStatus" class="span-error">
                                            Rating already exists!
                                        </span>
                                    </div>
                                    
                                </div>
                    
                                <div class="form-group col-md-4">
                                    <label class="form-control-label">Percentage<span class="star">*</span></label>
                                    <input type="text" 
                                    (keyup)="checkExistQualificationName($event)"
                                    (keypress)="allowOnlyNumbers($event)" 

                                     formControlName="percentage" name="Enter Percentage" 
                                     [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" 
                                     (cut)="disableCopyPaste($event)" 
                                     (copy)="disableCopyPaste($event)" 
                                     (paste)="disableCopyPaste($event)" 
                                     (contextmenu)="disableRightClick($event)"
                                    class="form-control email-input select-placeholder"  maxlength="3" placeholder="Percentage">
                                    <!-- <div class="valid-msg" *ngIf="(validate?.percentage?.invalid && validate?.percentage?.touched ) || validate?.percentage?.dirty || submitted">
                                        <span *ngIf="validate?.percentage.errors?.required" class="text-danger">Please enter percentage</span>
                                        <span *ngIf="validate?.percentage.errors?.pattern" class="text-danger">Please enter valid percentage</span>
                                    </div> -->
                                    <div *ngIf="postForm?.get('percentage')?.invalid && (postForm?.get('percentage')?.touched || submitted)">
                                        <span *ngIf="postForm?.get('percentage')?.errors?.required" class="text-danger">
                                            Please enter percentage
                                        </span>
                                        <span *ngIf="postForm?.get('percentage')?.errors?.pattern" class="text-danger">
                                            Please enter a valid percentage
                                        </span>
                                        <!-- <span *ngIf="vpqExistStatus" class="span-error">
                                            percentage already exists!
                                        </span> -->
                                    </div>

                                </div>
                                <div class="col-md-4 " style="position: relative;top: 26px;">
                                    <button   mat-raised-button type="submit" [disabled]="btnDisables" 
                                    class="searchBtn  mr-2"  style=" margin-left: 10px;
                                    height: 34px;
                                    position: relative;
                                    top: 0px;"
                                    >Save</button>
                                    <!-- <button type="reset" class="mr-2"  mat-raised-button [disabled]="btnDisables" 
                                    class="btn mr-2" style="margin-right: 20px;" (click)="clearValidation()">Clear</button> -->

                                    <button style="margin-right: 20px;" type="reset" mat-raised-button
                                    class="btn-clear mr-2" (click)="clearValidation()"[disabled]="btnDisables" 
                                     >Clear</button>
                                <!-- <button type="button" mat-button mat-dialog-close>Cancel</button> -->
                                <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
                                <!-- <button type="submit"   class="btn  search-btn" mat-raised-button >Save</button> -->
                               
                            </div>
                    
                                
                            </div>
                      
                    </form>
                </div>
            </div>
            <div class="col-md-12 text-right">
                <div class="row p-0 m-0"    *ngIf="resultData.length > 5 && (resultData | mytask : search).length > 0" >
                    <div class="col-xl-12 col-md-12  "   *ngIf="(resultData | mytask : search).length > 5">
                      <div class="select-pagination">
                        <span>Show : </span>
                        <select style="    border-radius: 6px;"
                          class="select_list new-select_list"
                          [(ngModel)]="mySelect"
                          (change)="changefirstpage()">
                          <option class="form-control" value="5">5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                        </select>
                      </div>
                    </div>
                  </div>
            </div>
                 <div class="--table-responsive" *ngIf="tableShow">
                    <b class="DesigniLabel" style=" font-family: 'Mulish', sans-serif;">  Manage Variable Pay Qualifier</b>
                    <!-- <div class="col-md-12 text-right">
                        <div class="row p-0 m-0"    *ngIf="resultData.length > 5 && (resultData | mytask : search).length > 0" >
                            <div class="col-xl-12 col-md-12  "   *ngIf="(resultData | mytask : search).length > 5">
                              <div class="select-pagination">
                                <span>Show : </span>
                                <select style="    border-radius: 6px;"
                                  class="select_list new-select_list"
                                  [(ngModel)]="mySelect"
                                  (change)="changefirstpage()">
                                  <option class="form-control" value="5">5</option>
                                  <option class="form-control" value="10">10</option>
                                  <option class="form-control" value="15">15</option>
                                  <option class="form-control" value="20">20</option>
                                  <option class="form-control" value="25">25</option>
                                </select>
                              </div>
                            </div>
                          </div>
                    </div> -->
                    <table class="table"  style="border-top-left-radius: 10px; border-top-right-radius: 10px;" *ngIf="tableShow">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center">Action</th>
                                <th>Rating</th>
                                <th>Percentage</th>                              
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody style="border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;">
                           
                            <tr *ngFor="let row of resultData |  mytask: search | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                <!-- <td class="text-center">{{i+1}}</td> -->
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="updateform(row)"  >
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                      
    
                                        <button mat-menu-item *ngIf="row.qualiferStatus.listTypeValueId == 1" (click)="updateStatus(row.qualiferId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span style="color: red;">In-Active</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.qualiferStatus.listTypeValueId == 2" (click)="updateStatus(row.qualiferId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span style="color:green;">Active</span>
                                        </button>
                                    </mat-menu>
                                </td>
    
                               
                                <td>{{row.rating}}</td>
                                <td>{{row.percentage}}</td>                                
                                <!-- capitalizeWordsName -->
                                <td class="active-status text-center">
                                    <span class="btn-outline-success" *ngIf="row.qualiferStatus.listTypeValueId == 1">
                                        {{row.qualiferStatus.listTypeValueName}}
                                    </span>
    
                                    <span class="btn-outline-danger" *ngIf="row.qualiferStatus.listTypeValueId == 2">
                                        {{row.qualiferStatus.listTypeValueName}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody style="    border-left: 1px solid #ddd;
                        border-right: 1px solid #ddd;">
                                                        <tr>
                                                            <td   style="
                                                            text-align: center;
                                                            font-size: larger;
                                                            font-weight: bold;
                                                          " colspan="11" 
                                                          *ngIf="noRecordsFound||((resultData | mytask : search)?.length === 0 && search) ||resultData?.length == 0">Variable Pay Qualifier Records Not Found!</td>
                                                        </tr>
                                                    </tbody>
                                                    <div class="row" *ngIf="resultData.length != 0">
                                                        <div class="col-md-12">
                                                            <!-- <div class="showing-record"> -->
                                                                <!-- <ng-template pTemplate="paginatorleft" let-state>
                                                                    Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                                                                </ng-template> -->
                                                                <!-- {{resultData.length}} rows -->
                                                            <!-- </div> -->
                                                        </div>
                                                        </div>
                    </table>
                    <div
                    class="pagination-content"
                    *ngIf="resultData?.length > 5 && (resultData | mytask : search).length > 0"
                    id="page"
                  >
                    <pagination-controls  *ngIf="(resultData | mytask : search).length > 5"
                      style="position: relative; float: inline-end;top: 7px;"
                      (pageChange)="p = $event"
                      align="end"
                    ></pagination-controls>
                  </div>
                 </div>
             

                <!-- <div class="row" *ngIf="resultData.length != 0">
                    <div class="col-md-4">
                        <div class="showing-record"> -->
                            <!-- <ng-template pTemplate="paginatorleft" let-state>
                                Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                            </ng-template> -->
                            <!-- {{resultData.length}} rows
                        </div>
                    </div> -->
                    <!-- <div class="col-md-8 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
<!-- </div> -->


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>