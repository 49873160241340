import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';

@Component({
  selector: 'app-employee-leave-list',
  templateUrl: './employee-leave-list.component.html',
  styleUrls: ['./employee-leave-list.component.scss']
})
export class EmployeeLeaveListComponent implements OnInit {
  filterForm:FormGroup;
  showtable:boolean=false;
  isProcessing = false;

  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientId:any;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  leaveId: any;
  req: boolean=false;
  startIndex: number;

  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private dialog:MatDialog,
    private router:Router) { }

  ngOnInit(): void {
    this.noRecordsFound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.orgId);

    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null],
      period:[null],
    })
     this.loader=true;

     if(this.orgId){
    this.settingsService.getFinicalyearList( this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1){
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
      this.loader=false;
    });
  }

  if(this.clientId){
    this.settingsService.getClientFinicalyearList(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1){
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
      this.loader=false;
    });
  }

    
  }

  addLeave()
  {
    console.log("create emp  leave");
    this.router.navigateByUrl('/home/add-employee-leave');
  }


  searchSubmit()
  {
    this.showtable=true;
    this.isProcessing=true;

    console.log("submit",this.filterForm.value);
    this.loader=true;
    // this.req=false;
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   // alert('Please enter employee number');
    //   return;
    // }

    

    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear===null|| this.filterForm.value.financialyear=== 'null')&&(this.filterForm.value.period===null||this.filterForm.value.period==='null'))
    {
      console.log("Required Field");
       this.resultData =[];
       this.noRecordsFound = false;
       this.loader = false; // Stop loader after processing
      this.showtable=false;
       alert("Please Enter Employee Number or Financial Year");
      //  return;
      // this.req=true;
      this.isProcessing=false;

      //  this.getorgemployeeleave();
      //  this.loader = false; // Stop loader after processing

    }

    // if(((this.filterForm.value.employeecode!=''||this.filterForm.value.employeecode!=null)&&this.filterForm.value.financialyear==null)||(this.filterForm.value.employeecode==''||this.filterForm.value.employeecode==null)&&this.filterForm.value.financialyear!=null)
    // {
    //   this.req=false;
    // }
    console.log(this.filterForm.value);

    console.log('this.filterForm.value.employeecode',this.filterForm.value.employeecode);
    console.log('this.filterForm.value.financialyear',this.filterForm.value.financialyear);
    console.log('this.filterForm.value.period',this.filterForm.value.period);
    
    if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
    {
      console.log("both");
      this.payrollservice.getemployeeLeave(this.filterForm.value.employeecode,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&(this.filterForm.value.period==null||this.filterForm.value.period=='null'))
    {
      console.log("Finanical year only");
      this.payrollservice.listByFinancialYear(this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
    {
      console.log("year and period");
      this.payrollservice.listByFinancialYearAndFinancialPeriod(this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
   
    else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear==null||this.filterForm.value.financialyear=='null')
    &&(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
      {
      console.log("Employeecode only");

      if(this.orgId){
      this.payrollservice.searchByEmployeeNumberByOrg(this.filterForm.value.employeecode, this.orgId).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;

        this.resultData = resultData;
        this.loader=false;

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    if(this.clientId){
      this.payrollservice.searchByEmployeeNumberByClient(this.filterForm.value.employeecode, this.clientId).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;

        this.resultData = resultData;
        this.loader=false;

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
    } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
    {
      console.log("emp num and period");
      this.payrollservice.listByEmpNumAndFinancialPeriod(this.filterForm.value.employeecode,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&!(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
    {
      console.log("period");
      this.payrollservice.listByFinancialPeriod(this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
    &&!(this.filterForm.value.financialyear==null || this.filterForm.value.financialyear=='null')
    &&(this.filterForm.value.period==null || this.filterForm.value.period=='null'))
    {
      this.showtable=true;

      console.log("emp num and year");
      this.payrollservice.listByEmpNumAndFinancialYear(this.filterForm.value.employeecode,this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        this.loader=false;

        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;

      })
    }
  }
  // pageChages()
  // {
  //   console.log("page changes");
  //   this.p=1;
  // }\
  // onItemsPerPageChange(event: any) {
  //   this.pagePerItem = event.target.value;
  //   this.p = 1; // Reset the pagination to the first page when items per page is changed
  // }

  getorgemployeeleave()
  {
    this.loader = true;
    this.payrollservice.employeeleavelistByOrganization(this.orgId).subscribe((resultData:any) =>
    {
      this.noRecordsFound = false;
      this.resultData = resultData;
      console.log(this.resultData);
      if(this.resultData.length == 0 || this.resultData.length == undefined)
      {
        this.noRecordsFound = true;
      }
      if( resultData.statusCode == 204){
        this.noRecordsFound = true;
      }
      this.loader = false; // Step 3: Hide loader after the API call completes

    })
  }

  clearTable()
  {this.resultData = [];
    console.log('clear')
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
    this.finPeriodList=[];
    this.showtable=false;
  }

  pageChages(event)
  {
    console.log('event.target.value', event.target.value);
    if(event.target.value != null || event.target.value != 0){
      // this.p = event.target.value;
      console.log("page changes");
      this.p=1;
    }
    
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear()
  {
    console.log("Select");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    //  this.chooseFinId=event.target.value;
    this.chooseFinId = this.filterForm.controls['financialyear'].value;
    console.log(this.chooseFinId);
    console.log('this.chooseFinId == null',this.chooseFinId == null)
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else{
      console.log('else');
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
        this.filterForm.patchValue({
          period: null,
        });
      });
    }
  
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.leaveId=row.employeerattingId;
    console.log(this.leaveId);
    this.router.navigate(['/home/edit-employee-leave/', this.leaveId])
  }
}
