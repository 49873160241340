import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mytask'
})
export class MytaskPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(!value)return null;
    if(!args)return value;

    args = args.toLowerCase();

    return value.filter(function(item){
        return JSON.stringify(item).toLowerCase().includes(args);
    });
  }
  // transform(items: any[], searchTerm: string): any[] {
  //   if (!items || !searchTerm) {
  //     return items;
  //   }

  //   searchTerm = searchTerm.toLowerCase();

  //   return items.filter(item => {
  //     // Customize this condition based on your data structure
  //     return item.name.toLowerCase().includes(searchTerm);
  //   });
  // }
}
