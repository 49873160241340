<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="sub-content">View Employee</span>
                </div>
                <div class="sub-header-buttons">
                    <!--  <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                     -->
                    <a routerLink="/home/employee" mat-raised-button title="Back" class="ml-2">
                       Back
                    </a>

                    <a routerLink="/home/edit-employee/{{this.employeeId}}" routerLinkActive="active"  mat-raised-button style="background:#1a83ff;color:#fff" class="ml-2">
                        Edit Employee
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">

                                    <span *ngIf="fileblob">
                                        <img class="profile-image" src="{{fileblob}}" alt="Profile Image">
                                    </span>

                                    <span *ngIf="!fileblob">
                                        <img class="profile-image" src="assets/uploads/load.png" alt="Profile Image">
                                    </span>

                                    <!-- <a class="edit-icon" routerLink="#"><mat-icon class="edit-profile">edit</mat-icon></a>
                                    -->
                                </div>
                            </div>

                            <div class="col-md-10">
                                <div class="profile-name mb-2">
                                    {{resultData.middleName ? resultData.middleName:""}} {{resultData.firstName}} {{resultData.lastName}}
                                </div>
                                <div class="profile-title">
                                    <div class="row">
                                        <span class="profile-icons"><mat-icon class="profile-mat-icon">account_circle</mat-icon>
                                            {{resultData.designation ? resultData.designation.designationName :""}}
                                        </span>
                                        <span class="profile-icons"><mat-icon class="profile-mat-icon">location_on</mat-icon>
                                            {{resultData.address ? resultData.address :""}}
                                        </span>
                                        <span class="profile-icons"><mat-icon class="profile-mat-icon">email</mat-icon>
                                            {{resultData.organizationEmail ? resultData.organizationEmail :""}}
                                        </span>
                                    </div>
                                </div>

                                <!--
                                <div class="profile-progress-bar mt-2">
                                    <div class="row pl-0">
                                        <div class="col-md-6">
                                            <span class="profile-percentage-title">Profile Completion<h5 class="profile-percentage float-right">50%</h5> </span>
                                        -->
                                <!-- <span class="profile-percentage float-right">50%</span> -->
                                <!--
                                            <mat-progress-bar class="progress-bar" mode="determinate" value="50"></mat-progress-bar>
                                        </div>
                                        <div class="col-md-6 text-right">

                                        </div>
                                    </div>
                                </div>   -->


                                <!--  <div class="profile-progress-bar mt-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="row pl-0">
                                                <div class="col-md-6">
                                                    <span>Profile Completion</span>
                                                </div>
                                                <div class="col-md-6 text-right">
                                                    <span>50%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4"></div>
                                    </div>
                                </div> -->


                                <!-- <div class="profile-tabs mt-2">
                                    <div class="row">
                                        <span class="profile-tab" title="My Profile">My Profile</span>
                                        <span class="profile-tab" title="Edit Profile">Edit Profile</span>
                                        <span class="profile-tab" title="Change Password">Change Password</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-new">
        <div class="row">
            <div class="col-md-12">
                <section class="my_prifile">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="profile-header-title">
                                        <mat-icon class="profile-icons">info</mat-icon>
                                        <!-- <img src="assets/uploads/settings/country.png" style="width:30px ;" alt="" class="settings-icon"> -->
                                        Basic Information
                                    </h3>

                                    <!--  <div class="row">
                                        <div class="col-md-5  form-control-label"> <label>Employee Role</label></div>
                                        <div class="col-md-7"><span>{{resultData.role ? resultData.role.roleName :""}}</span></div>
                                    </div> -->

                                    <div class="row mt-2">
                                        <div class="col-md-5  form-control-label"> <label>Employee No</label></div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.employeeNumber ? resultData.employeeNumber :""}}</span></div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-md-5  form-control-label"> <label>Employee First Name</label></div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.firstName ? resultData.firstName :""}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.lastName">
                                        <div class="col-md-5  form-control-label"> <label>Employee Last Name</label></div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.lastName ? resultData.lastName :""}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.middleName">
                                        <div class="col-md-5  form-control-label"> <label>Employee Surname</label></div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.middleName ? resultData.middleName :""}}</span></div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-md-5  form-control-label"><label>Mobile Number</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.mobileNumber ? resultData.mobileNumber :""}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.alternativeMobileNumber">
                                        <div class="col-md-5  form-control-label"> <label>Alternate Mobile Number</label></div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.alternativeMobileNumber}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.organizationEmail">
                                        <div class="col-md-5  form-control-label"><label>Email</label></div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.organizationEmail}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.alternativeEmail">
                                        <div class="col-md-5  form-control-label"><label>Alternate Email</label></div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.alternativeEmail ? resultData.alternativeEmail : ""}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData?.dateOfBirth">
                                        <div class="col-md-5  form-control-label"><label>Date of Birth</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData?.dateOfBirth ? (resultData?.dateOfBirth ) :"-"}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.gender">
                                        <div class="col-md-5  form-control-label"><label>Gender</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.gender.listTypeValueName}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.bloodGroup">
                                        <div class="col-md-5  form-control-label"><label>Blood Group </label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.bloodGroup.listTypeValueName}}</span></div>
                                    </div>



                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-body">
                                    <h3 class="profile-header-title">
                                        <mat-icon class="profile-icons">credit_card</mat-icon>
                                        ID Information
                                    </h3>

                                    <div class="row mt-2" *ngIf="resultData.aadhaarNumber">
                                        <div class="col-md-5  form-control-label"><label>Aadhar No</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.aadhaarNumber}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.panNumber">
                                        <div class="col-md-5  form-control-label"><label>PAN Number</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.panNumber}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.passportNumber">
                                        <div class="col-md-5  form-control-label"><label>Passport No</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.passportNumber}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.passportValidityDate != undefined && resultData.passportValidityDate">
                                        <div class="col-md-5  form-control-label"><label>Passport Validity Date</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.passportValidityDate ? (resultData.passportValidityDate | date: dateFormat) : ""}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.licenceNumber">
                                        <div class="col-md-5  form-control-label"><label>Driving Licence Number</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.licenceNumber}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.licenceValidityDate != undefined && resultData.licenceValidityDate">
                                        <div class="col-md-5  form-control-label"><label>Licence Validity Date</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{ resultData.licenceValidityDate ? (resultData.licenceValidityDate | date: dateFormat) :"" }}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.voterId">
                                        <div class="col-md-5  form-control-label"><label>Voter ID</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.voterId}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.pfNumber">
                                        <div class="col-md-5  form-control-label"><label>PF Number</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.pfNumber}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.esiNumber">
                                        <div class="col-md-5  form-control-label"><label>ESI Number</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.esiNumber}}</span></div>
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-body">
                                    <h3 class="profile-header-title">
                                        <mat-icon class="profile-icons">home</mat-icon>
                                        Bank Details
                                    </h3>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.bankAccountNo">
                                        <div class="col-md-5  form-control-label"><label>Bank A/c No</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.bankAccountNo}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.accountName">
                                        <div class="col-md-5  form-control-label"><label>Bank A/c Holder Name</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.accountName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.bankName">
                                        <div class="col-md-5  form-control-label"><label>Bank Name</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.bankName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.bankBranchName">
                                        <div class="col-md-5  form-control-label"><label>Bank Branch</label></div>
                                       
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-6"><span>{{resultData.employeeInfo.bankBranchName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.ifscCode">
                                        <div class="col-md-5  form-control-label"><label>IFSC Code</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.ifscCode}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.micrCode">
                                        <div class="col-md-5  form-control-label"><label>MICR Code</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.micrCode}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.bankAddress">
                                        <div class="col-md-5  form-control-label"><label>Address</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.bankAddress}}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="profile-header-title">
                                        <mat-icon class="profile-icons">check_circle_outline</mat-icon>
                                        Employment Details
                                    </h3>

                                    <div class="row mt-2" *ngIf="resultData.employmentType != undefined && resultData.employmentType.listTypeValueName">
                                        <div class="col-md-5  form-control-label"><label>Employment Type</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employmentType.listTypeValueName}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.branch != undefined && resultData.branch.branchName">
                                        <div class="col-md-5  form-control-label"><label>Branch</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.branch.branchName}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.designation != undefined && resultData.designation.designationName">
                                        <div class="col-md-5  form-control-label"><label>Designation</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.designation.designationName}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.department != undefined && resultData.department.departmentName">
                                        <div class="col-md-5  form-control-label"><label>Department</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.department.departmentName}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.dateOfJoining != undefined && resultData.dateOfJoining">
                                        <div class="col-md-5  form-control-label"><label>Date of Joining</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.dateOfJoining ? (resultData.dateOfJoining ) :""}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.confirmationDate != undefined && resultData.confirmationDate">
                                        <div class="col-md-5  form-control-label"><label>Confirmation Date</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.confirmationDate ? (resultData.confirmationDate | date: dateFormat) :""}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.yearOfExperiance != undefined && resultData.yearOfExperiance">
                                        <div class="col-md-5  form-control-label"><label>Year of experience</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.yearOfExperiance}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.ratePerDay != undefined && resultData.ratePerDay">
                                        <div class="col-md-5  form-control-label"><label>Rate Per Day</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.ratePerDay}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.ratePerHour != undefined && resultData.ratePerHour">
                                        <div class="col-md-5  form-control-label"><label>Rate Per Hour</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.ratePerHour}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.payFrequency != undefined && resultData.payFrequency.listTypeValueName">
                                        <div class="col-md-5  form-control-label"><label>Pay frequency</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6">{{resultData.payFrequency.listTypeValueName}}</div>
                                    </div>



                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-body">
                                    <h3 class="profile-header-title">
                                        <mat-icon class="profile-icons">location_on</mat-icon>
                                        Address
                                    </h3>

                                    <!-- Current Address start -->
                                    <h4 class="profile-header-title" style="font-weight:550;">Current Address</h4>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.country">
                                        <div class="col-md-5  form-control-label"><label>Country</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.country.countryName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.state">
                                        <div class="col-md-5  form-control-label"><label>State</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.state.stateName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.district">
                                        <div class="col-md-5  form-control-label"><label>District</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.district.districtName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.city">
                                        <div class="col-md-5  form-control-label"><label>City</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.city.cityName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.postalCode != undefined && resultData.postalCode">
                                        <div class="col-md-5  form-control-label"><label>Postal Code</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.postalCode}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.address != undefined && resultData.address">
                                        <div class="col-md-5  form-control-label"><label>Address</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.address}}</span></div>
                                    </div>
                                    <!-- Current Address end -->

                                    <!-- Permenant Address start -->
                                    <!-- <div *ngIf="resultData.employeeInfo.copyCurrentAddress == true"> -->
                                    <h4 class="profile-header-title" style="font-weight:550;">Permenant Address</h4>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo && resultData.employeeInfo.permenantCountry">
                                        <div class="col-md-5  form-control-label"><label>Country</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.permenantCountry.countryName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo && resultData.employeeInfo.permenantState">
                                        <div class="col-md-5  form-control-label"><label>State</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.permenantState.stateName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.permenantDistrict">
                                        <div class="col-md-5  form-control-label"><label>District</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.permenantDistrict.districtName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.permenantCity">
                                        <div class="col-md-5  form-control-label"><label>City</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.permenantCity.cityName}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.permenantPostalCode">
                                        <div class="col-md-5  form-control-label"><label>Postal Code</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.permenantPostalCode}}</span></div>
                                    </div>
                                    <div class="row mt-2" *ngIf="resultData.employeeInfo != undefined && resultData.employeeInfo.permenantAddress">
                                        <div class="col-md-5  form-control-label"><label>Address</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.employeeInfo.permenantAddress}}</span></div>
                                    </div>
                                    <!-- Permenant Address end -->
                                    <!-- </div>   -->
                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-body">
                                    <h3 class="profile-header-title">
                                        <mat-icon class="profile-icons">login</mat-icon>
                                        Login Details
                                    </h3>
                                    <div class="row mt-2" *ngIf="resultData.organizationEmail != undefined">
                                        <div class="col-md-5  form-control-label"><label>Email</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>{{resultData.organizationEmail}}</span></div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-5  form-control-label"><label>Password</label></div>
                                        <div class="col-md-1">:</div>

                                        <div class="col-md-6"><span>***********</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" style="height:3rem;width:3rem;" role="status"></div>
</div>