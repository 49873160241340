import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { PasswordValidators } from 'src/app/password-validators';
import { EmployeeService } from 'src/app/providers/employee.service';

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit {
  submitted: boolean = false;
  entitygroup:FormGroup;
password;
  show=false;
  entityFormValues: any;
  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  entityId: any;
  resultData: any;
  employeeId: any;
isUpdate: boolean=false;
isUpdating: boolean=false;
  constructor(private router:Router,
    private formBuilder:FormBuilder,
    private Employeeservice: EmployeeService,
    private ActivatedRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    // this.password = 'password';

    this.entitygroup=this.formBuilder.group({
      entityName:[ { value: '', disabled: false },[Validators.required,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
    userEmail:[null,[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password : [
        { value: '', disabled: false } ,
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
            requiresDigit: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
            requiresUppercase: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
            requiresLowercase: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
            requiresSpecialChars: true
          })
        ],
        
    ],
    description:[null],
    proxyUrl: [null, [Validators.required, Validators.pattern('https?://(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)')]]
  })

  this.entityId=this.ActivatedRoute.snapshot.params.enitytyId
  console.log("this.entityId::",this.entityId);
  

  if(this.entityId){
    this.isUpdating=true;
    this.Employeeservice.getEntityById(this.entityId).subscribe((response:any)=>{
      this.resultData=response;

      console.log("edit response::",this.resultData);
      if (response.user && Array.isArray(response.user) && response.user.length > 0) {
        this.employeeId = response.user[0].emailId; 
        console.log(this.employeeId);
    }
    console.log(this.employeeId);


      this.entitygroup.patchValue({
        entityName:this.resultData.entityName,
        userEmail:this.employeeId,
        password:'********',
        description:this.resultData.entityDescription,
        proxyUrl:this.resultData.proxyUrl,
      })
      this.entitygroup.get('userEmail')?.disable();  // Disable userEmail
        this.entitygroup.get('password')?.disable();  


    })
  }
  


  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  get validate() 
  { 
    return this.entitygroup ? this.entitygroup.controls : null;
  }
  get requiredValid() {
    return !this.entitygroup?.controls["password"].hasError("required");
  }
  get minLengthValid() {
    return !this.entitygroup?.controls["password"].hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.entitygroup?.controls["password"].hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.entitygroup?.controls["password"].hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.entitygroup?.controls["password"].hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.entitygroup?.controls["password"].hasError("requiresSpecialChars");
  }
  get passwordValid() {
    return this.entitygroup?.controls["password"].errors === null;
  }
  showPassword() {
    if (!this.isUpdating) {
      if (this.password === 'password') {
        this.password = 'text';
        this.show = true;
      } else {
        this.password = 'password';
        this.show = false;
      }
    }
  }
  onSubmit(){
    this.submitted=true;
    if(this.entitygroup.invalid){
      return;
    }
    else{
      console.log("all valid !!!");

      this.entityFormValues=this.entitygroup.value


      if(this.entityId){

        var postValues={
          entityName:this.entityFormValues.entityName,
          entityDescription:this.entityFormValues.description,
          proxyUrl:this.entityFormValues.proxyUrl,
          user:{
            emailId:this.entityFormValues.userEmail,
            password:this.entityFormValues.password
          },
          role:{
            roleId:5

          },
        }
        console.log("post values::",postValues);

        this.Employeeservice.updateEntity(this.entityId,postValues).subscribe((response:any)=>{
          console.log("response::",response);
          if (response.statusCode == 200) {
            this.success = true;
            this.sucess_msg = response.description;
            setTimeout(() => {
              this.router.navigateByUrl("/super-admin-home/entity");
            }, 5000);
          } else {
            this.error = true;
            this.error_msg = response.description;
  
            setTimeout(() => {
              this.router.navigateByUrl("/super-admin-home/entity");
            }, 5000);
          }
        },
        (err:any)=>{
          console.log("err::",err);
          if(err.error.statusCode==409){
            
            this.error = true;
            this.error_msg = err.error.description;
  
            setTimeout(() => {
              this.error = false;
  
              // this.router.navigateByUrl("/super-admin-home/entity");
            }, 5000);
          }
        }

      )

        

      }
      else{
        console.log("creation came here!!");
        

        const payload={
          entityName:this.entityFormValues.entityName,
          proxyUrl:this.entityFormValues.proxyUrl,
          user:{
            emailId:this.entityFormValues.userEmail,
            password:this.entityFormValues.password,
            role:[{
              roleId:5
  
            }],
          },
         
          entityDescription:this.entityFormValues.description
        }
        console.log("payload::",payload);
  
        // return
        this.Employeeservice.createEntity(payload).subscribe((response:any)=>{
          console.log("response",response);
  
          if (response.statusCode == 200) {
            this.success = true;
            this.sucess_msg = response.description;
            setTimeout(() => {
              this.router.navigateByUrl("/super-admin-home/entity");
            }, 5000);
          }
          
          
  
        },
      (err:any)=>{
        console.log("err::",err);
        if(err.error.statusCode==409){
          this.error = true;
          this.error_msg = err.error.description;

          setTimeout(() => {
            this.error = false;

            // this.router.navigateByUrl("/super-admin-home/entity");
          }, 5000);
        }
        
       
      })

      }

     
      
    }
  }
  clear(){
    this.router.navigateByUrl("/super-admin-home/entity");
  }
}
