<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Employee Payroll</span>
                </div>
                <div class="sub-header-buttons">
                    <button mat-raised-button color="primary" class="" (click)="addpayroll()">Run Payroll</button>
                    <a   routerLink="/home/view-wageslip/"   color="primary" mat-raised-button class="ml-2">
                        View Wage Slip
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Client<span class="star"> *</span></label> 
                                <select formControlName="client" (change)="getclientEmployees()"  class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null"> --- Select Client --- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select>    
                                <!-- <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                    <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                     <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  
                                </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                    search
                                </mat-icon> -->
                                <label class="col-form-label">Employee</label>
                                <input type="text"matInput  placeholder="Search Employee"   
                                            formControlName="employeecode"
                                            [matAutocomplete]="auto"  [readonly]="!filterForm.value.client || filterForm.value.client == 'null'"
                                            class="form-control" (keyup)="empSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                            </mat-option> -->
                                        <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employeeNumber}}- {{emp.firstName}}  
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:45px;right: 19px;">
                                        search
                                    </mat-icon>
                                <!-- <div class="valid-msg" *ngIf="submitted">        
                                    <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                                </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Financial year<span class="star"> *</span></label> 
                                <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                    <option [value]=null> -- Select Financialyear --- </option>
                                    <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Financial Period<span class="star"> *</span></label> 
                                <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]=null> -- Select Period --- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button>
                            </div>
                        </div>
                    </form>
                    <div class="row pb-3">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left p1-0">
                                <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                    </div>
                       
                       <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <!-- <p><b>EMPLOYEE RATING</b></p> -->
                        <table class="table table-responsive2 --table-bordered table-border-new" style="width: 135%; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                            <thead class="t-head">
                                <tr>
                                    <th>Action</th>
                                    <th>Financial Year</th>
                                    <th>Financial Month</th>
                                    <th>Emp Code</th>
                                    <th>Emp Name</th>
                                    <th>Desgination</th>
                                    <th>Location</th>
                                    <th>Created Date</th>
                                    <th>CTC  ( {{organizationSettings.country.countrySymbol}} )</th>
                                    <th>Amount  ( {{organizationSettings.country.countrySymbol}} ) </th>
                                    <th>Earning YTD  ( {{organizationSettings.country.countrySymbol}} ) </th>
                                    <th>Deduction YTD ( {{organizationSettings.country.countrySymbol}} )</th>
                                    
                                </tr>
                            </thead>
                            <tbody *ngIf="resultData.length>0">
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item routerLink="/home/view-employee-payroll/{{row.payrollHeaderId}}">
                                                <mat-icon>visibility</mat-icon>
                                                <span>View</span>
                                            </button>
                                            <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==1" (click)="updateStatus(row.clientAssignId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==2" (click)="updateStatus(row.clientAssignId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button> -->
                                        </mat-menu>
                                    </td>
                                    
                                    <td>{{row.finanicalPeriod.finanicalYear.financialFromMonth }} {{row.finanicalPeriod.finanicalYear.financialFromYear}} to {{row.finanicalPeriod.finanicalYear.financialToMonth }} {{ row.finanicalPeriod.finanicalYear.financialToYear}}</td>
                                    <td>{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                    <td>{{row.employee.employeeNumber}}</td>
                                    <td>{{row.employee.firstName}}</td>
                                    <td *ngIf="row.employee.designation !=undefined" >{{row.employee.designation.designationName}}</td>
                                    <td *ngIf="row.employee.designation == undefined" >--</td>
                                    <td>{{row.employeeAnnexure.place}}</td>
                                    <td>{{row.createDateTime | date :dateFormat}}</td>
                                    <td>{{showmontlyctc(row ,row.employeeAnnexure.ctc?.toFixed(2))}}</td>
                                    <td>{{row.totalAmount?.toFixed(2)}}</td>
                                    <td>{{row.totalEarningYtd?.toFixed(2)}}</td>
                                    <td>{{row.totalDedutionYtd?.toFixed(2)}}</td>
                                    <!-- <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId==1">
                                            {{row.status.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId==2">
                                            {{row.status.listTypeValueName}}
                                        </span>
                                    </td> -->
                                </tr>
                                <tr>
                                    <td colspan="8" class="text-right">
                                        <b>Total :</b>
                                    </td>
                                    <td  >
                                       {{this.totalctc?.toFixed(2)}}
                                    </td>
                                    <td >
                                        {{this.totalamount?.toFixed(2)}}
                                     </td>
                                     <td  >
                                        {{this.totalEarningYtd?.toFixed(2)}}
                                     </td>
                                     <td >
                                         {{this.totalDedutionYtd?.toFixed(2)}}
                                      </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
                                </tr> 
                            </tbody> 
                        </table>
                     </div>
                    
                     <div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>