<!-- Sub header start here-->
<!-- <section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span routerLink="/home/branch" class="h5 sub-content">
            Legal Entity</span
          >
        </div>
        <div class="add-business" routerLink="/home/legal-entity">
          <button
            mat-raised-button
            class="m-1 searchBtn"
            style="position: relative; top: -9px"
          >
            Add Legal Entity
          </button>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Legal Entity</span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
        
          <a
            routerLink="/home/legal-entity"
          
            mat-raised-button
            class="ml-2 searchBtn"
          >
          Add Legal Entity
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="main-content" style="padding: 12px; margin-top: -95px">
    <div
      class="container-fluid"
      style="border: 1px solid #ddd; border-radius: 9px"
    >
      <div class="top-content row">
        <div>
          <!-- <h4 class="assign-employee">Assign Employee Task</h4> -->
        </div>
      </div>
      <div class="search-content">
        <form [formGroup]="searchForm">
          <div class="row">
            <div class="col-sm-4">
              <label class="form-control-label">Business Group Name</label>

  <input
                  formControlName="businessgName"
                  type="text"
                 
                  (keydown)="handleBackspace($event)"
                  (input)="validateInputSpace($event)"
                  autocomplete="off"
                  class="form-control email-input select-placeholder"
                  value=""
                  placeholder="Business Group Name"
                />


              <!-- <mat-select
                id="framework"
                formcontrolName="businessgName"
                (selectionChange)="selectionChangeBusinessGroup($event)"
                class="form-control email-input select-placeholder"
                placeholder="Select Business Group Name"
              >
                <mat-option>-Select Business Group Name</mat-option>
                <mat-option
                  *ngFor="let row of businessList"
                  [value]="row.businessGroupId"
                >
                  {{ row.businessGroupName }}
                </mat-option>
              </mat-select> -->

            </div>

            <div class="col-sm-4 apply-margin">
              <label class="form-control-label">Legal Entity Name</label>
              <input
                formControlName="legalentityNames"
                (keydown)="clearTableData($event)"
                (input)="validateInputSpace($event)"
                type="text"
                autocomplete="off"
                class="form-control email-input select-placeholder"
                value=""
                placeholder="Legal Entity Name"
              />
            </div>
            <div class="col-sm-4 mt-4">
              <button
                type="button"
                mat-raised-button
                class="searchBtn1"
                (click)="searchSubmit()"
              >
                Search
              </button>

              <button
                type="button"
                mat-raised-button
                (click)="clearAll()"
                class="btn searchBtn ml-3"
                style="
                  background: none;
                  color: black;
                  border: 1px solid #ddd;
                  font-size: 16px;
                  font-weight: 610;
                "
              >
                Clear
              </button>
            </div>
          </div>
        </form>

        <div class="card-maargin">
          <div class="">
            <div class="row p-0 m-0"   *ngIf="legalList.length > 5 && legalList .length > 0 && tableShow">
              <div class="col-xl-12 col-lg-12" style="margin-bottom:12px;"  *ngIf="legalList.length > 5">
                  <div class="select-pagination" style="float: right;">
                      <span>Show : </span>
                      <select class="select_list new-select_list listDesign" [(ngModel)]="pagePerItem"   [ngModelOptions]="{ standalone: true }" (change)="changefirstpage()">
                          <option class="form-control" value="5"> 5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                      </select>
                  </div>
              </div>  
            </div>
            <div class="table-responsive-new1 table-content ">
              <table class="table" matSort (matSortChange)="sortData($event)" *ngIf="tableShow">
                <thead>
                  <!-- <th
                    mat-sort-header="sno"
                    scope="col"
                    style="text-align: center; border-radius: 15px 0px 0px 0px"
                  >
                    &nbsp;&nbsp;S.No
                  </th> -->
                  <th
                    class="sticky-col-tab"
                    
                    scope="col"
                  >
                    &nbsp;&nbsp;Action
                  </th>
                  <th
                   
                    style="text-align: center"
                    scope="col"
                    mat-sort-header="entityName"
                  >
                    Entity&nbsp;Name
                  </th>

                  <th style="text-align: center"   mat-sort-header="businessName" scope="col">Business&nbsp;Group&nbsp;Name</th>
                  <th style="text-align: center"   mat-sort-header="legalEntityName" scope="col">Legal&nbsp;Entity&nbsp;Name</th>
                  <th style="text-align: center"   mat-sort-header="country" scope="col">Country</th>

                  <th style="text-align: center"   mat-sort-header="legalAddress" scope="col" style="text-align: center">
                    Legal&nbsp;Address
                  </th>
                  <th  style="text-align: center" scope="col"   mat-sort-header="oAddress" style="text-align: center">
                    Operating&nbsp;Address
                  </th>
                  <th style="text-align: center" mat-sort-header="industryType" >Industry&nbsp;Type</th>
                  <th class="text-center" style="border-radius: 0px 15px 0px 0">
                    Status
                  </th>
                </thead>
                <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd"  >
                  <tr
                    *ngFor="
                      let e of legalList
                        | paginate : { itemsPerPage: mySelect, currentPage: p };
                      let i = index
                    "
                  >
                    <!-- <td style="text-align: center" *ngIf="p">
                      {{ (p - 1) * mySelect + (i + 1) }}
                    </td>
                    <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td> -->

                    <td class="text-center" style="margin-left: -24px">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu" class="controls-list">
                        <button
                          mat-menu-item
                          [routerLink]="[
                            '/home/edit-legal-entity',
                            e.legalEntityId
                          ]"
                        >
                          <mat-icon>edit</mat-icon>
                          <span>Edit</span>
                        </button>
                        <button
                          mat-menu-item
                          *ngIf="e.legalEntityStatus == 'Active'"
                          (click)="updateStatus(e.legalEntityId, 'In-active')"
                        >
                          <mat-icon>done</mat-icon>
                          <span style="color: red">Inactive</span>
                        </button>

                        <button
                          mat-menu-item
                          *ngIf="e.legalEntityStatus == 'In-active'"
                          (click)="updateStatus(e.legalEntityId, 'Active')"
                        >
                          <mat-icon>done</mat-icon>
                          <span style="color: #50cd89 !important">Active</span>
                        </button>
                      </mat-menu>
                    </td>
                    <td style="text-align: center">
                      {{ e.businessGroup.entity.entityName }}
                    </td>
                    <td style="min-width: 150px !important">
                      {{ e.businessGroup.businessGroupName }}
                    </td>
                    <td style="text-align: center">
                      {{ e.legalEntityName }}
                    </td>
                    <td style="text-align: center">
                      {{ e.countryOfIncorporation }}
                    </td>
                    <td style="   
                    word-wrap: break-word;
                    white-space: normal;
                    max-width: 280px;">
                      {{ e.legalAddress }}
                    </td>
                    <td style="    text-align: center;
                    word-wrap: break-word;
                    white-space: normal;
                    max-width: 280px;">
                      {{ e.operatingAddress }}
                    </td>
                    <td
                      style="
                        text-align: center;
                        padding-right: 25px;
                        min-width: 150px !important;
                      "
                    >
                      {{ e.businessGroup.industryType.listTypeValueName }}
                    </td>
                    <td class="active-status text-center">
                      <span
                        *ngIf="e.legalEntityStatus == 'Active'"
                        class="btn btn-sm btn-block"
                        style="color: #50cd89 !important"
                      >
                        {{ e.legalEntityStatus }}
                      </span>
                      <span
                        *ngIf="e.legalEntityStatus == 'In-active'"
                        class="btn btn-sm btn-block"
                        style="color: red;width:86px;"
                      >
                        {{ e.legalEntityStatus }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  style="
                    border-left: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                  "
                  *ngIf="tableShow"
                >
                  <tr>
                    <td
                      style="
                        text-align: center;
                        padding: 10px;
                        font-size: 19px;
                        font-weight: 600;
                        font-family: 'Mulish', sans-serif;
                        left: 5;
                        position: relative;
                      "
                      colspan="8"
                      *ngIf="nodatafound || legalList.length==0"
                    >
                      Legal Entity Records Not Found!
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="pagination-content"
                *ngIf="legalList.length > 5 && tableShow"
                id="page"
              >
                <pagination-controls
                  *ngIf="legalList.length > 5"
                  class="text-right"
                  style="float: right"
                  (pageChange)="p = $event; beforesearchpage = $event"
                  align="end"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
        <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
                <div class="card-body-nodata">
                  <div class="alert-nodata alert-primary">
                    <h4 align="center" class="h4">Assign Employee Task Not Found</h4>
                  </div>
                </div>
              </mat-card> -->
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="align-items-cEnter text-cEnter">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 51%;color: #1a83ff"
    role="status"
  ></div>
</div>

<div class="text-cEnter" *ngIf="success">
  <div class="alert alert-resource alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
