<!-- Sub header start here-->
<!-- <section> -->
<!-- <div class="sub-header"> -->
<div class="col-md-12 mb-3">
  <div class="row">
    <div class="">
      <span class="h5 sub-content"></span>
    </div>
    <div class="sub-header-org">
      <mat-button-toggle-group
        name="fontStyle"
        class="table-view-icons active-list"
        aria-label="Font Style"
      >
        <mat-button-toggle
          (click)="tableView(1)"
          [ngClass]="{ 'active-list-btn': pageViews === 1 }"
          title="Grid View"
          class="grid-icon"
        >
          <mat-icon>grid_on</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          (click)="tableView(2)"
          [ngClass]="{ 'active-list-btn': pageViews === 2 }"
          title="List View"
          class="list-icon"
        >
          <mat-icon>list</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          (click)="tableView(3)"
          [ngClass]="{ 'active-list-btn': pageViews === 3 }"
          title="Card View"
          class="view-icon"
        >
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button
        *ngIf="resultData?.length > 0"
        type="button"
        (click)="exportToExcel()"
        mat-raised-button
        class="btn download-button ml-2 btndesign"
      >
        Export
      </button>

      <!--
                      <a (click)="importEmployee()" mat-raised-button class="ml-2" color="primary" title="Import">
                          Import
                      </a> -->

      <!-- <a routerLink="/super-admin-home/add-organization" routerLinkActive="active" color="primary" mat-raised-button title="Add Employee" class="ml-2">
                          Add Organization
                      </a> -->
      <button (click)="Add_client()" mat-raised-button class="ml-2 btndesign">
        Add Organization
      </button>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- </section> -->
<!-- Sub header end here-->

<!-- <div class="common-content"> -->
<!-- <div class="card-new"> -->
<!-- <div class="card"> -->
<div class="card-body">
  <div class="row mb-2">
    <div class="col-md-12">
      <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
        <div class="row mb-3 pl-0">
          <div class="ol-xl-3 col-lg-3 mb-2">
            <label class="form-control-label">Organization Name </label>
            <input
              formControlName="keywords"
              type="text"
              autocomplete="off"
              (input)="validateInput($event)"

              class="form-control email-input select-placeholder"
              value=""
              placeholder="Organization Name"
            />
            <!-- <small class="text-muted smalldesign">Note : Organization Name</small> -->
          </div>
          <div class="ol-xl-3 col-lg-3 mb-2">
            <label class="form-control-label">Business Unit Name </label>
            <input
              formControlName="businessUnitname"
              type="text"
              autocomplete="off"
              (input)="validateInput($event)"
              (keydown)="handleBackspace($event)"
              class="form-control email-input select-placeholder"
              value=""
              placeholder="Business Unit Name"
            />
            <!-- <small class="text-muted smalldesign">Note : Organization Name, Contact Person, Contact Phone Number & Contact Email.</small> -->
          </div>

          <div class="col-xl-3 col-lg-3 mb-2">
            <!-- <span class="text-danger"> *</span> -->
            <label class="form-control-label">Industry Type<span class="text-danger"> *</span> </label>
            <!-- <select formControlName="industryType" class="form-select form-select-solid form-select-lg">
                                        <option value=""> - Select Industry Type - </option>
                                        <option *ngFor="let row of industryTypeLov; let i=index;" value="{{row.listTypeValueId}}">{{row.listTypeValueName}}</option>
                                      </select> -->
            <!-- <select formControlName="industryType" placeholder="- Select Industry Type -" class="form-select form-select-solid form-select-lg select2-new">
                                          <option [value]="null" selected="selected"> - Select Industry Type - </option>
                                          <option *ngFor="let row of industryTypeLov; let i=index;" value="{{row.listTypeValueId}}">{{row.listTypeValueName}}</option>
                                      </select> -->
            <mat-select
              formControlName="industryType"
              placeholder="Select Industry Type"
              class="form-control email-input select-placeholder"
              (selectionChange)="selectionChangeOfIndustryType($event)"
            >
              <mat-option [value]="undefined" selected="selected"
                >-Select Industry Type-</mat-option
              >
              <mat-option
                *ngFor="let row of industryTypeLov; let i = index"
                value="{{ row.listTypeValueId }}"
                >{{ row.listTypeValueName }}
              </mat-option>
              <!-- <mat-option value="Service Based">c</mat-option> -->
            </mat-select>
          </div>

          <!-- <div class="col-xl-1 col-lg-1 mb-2 display-flex">
                                      <button type="reset" mat-raised-button  class="btn " (click)="clearData()">Clear</button>
  
                                      <button type="submit" mat-raised-button  class="btn search-btn btndesign">Search</button>
                                  </div> -->
          <div class="ol-xl-3 col-lg-3 mb-3" style="margin-top: 22px">
             <button
              type="submit"
              mat-raised-button
              class="btndesign searchDesign"
              [disabled]="buttonDisabled"
            >
              Search
            </button>
            <button
              type="reset"
              mat-raised-button
              class="mr-2 clearDesign"
              [disabled]="buttonDisabled"
              (click)="clearData()"
            >
              Clear
            </button>
           
          </div>
        </div>
      </form>
      <!-- <div class="col-md-4 bvcbc">
                              <div class="row">
                                  <div class="col-md-9 text-right">
                                      <p class="show-count">Show</p>
                                  </div>
                                  <div class="col-md-3 text-left pl-0">
                                      <select [(ngModel)]="pagePerItem" class="form-control report-filter">
                                        <option value="5">5</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                  </div>
                              </div>
                          </div> -->
      <div class="float-end">
        <div
          class="row rowDesignshow"
          *ngIf="organizationListData?.length > 5 && tableShow"
        >
          <div
            class="select-pagination"
            style="margin-top: 10px; margin-left: 15px; margin-bottom: 10px"
            *ngIf="organizationListData?.length > 5 && tableShow"
          >
            <span class="showPage showDesign">Show:</span>
            <select
              class="select_list new-select_list listDesign"
              [(ngModel)]="mySelect"
              (change)="changefirstpage()"
            >
              <option class="form-control" value="5">5</option>
              <option class="form-control" value="10">10</option>
              <option class="form-control" value="15">15</option>
              <option class="form-control" value="20">20</option>
              <option class="form-control" value="25">25</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid_view" *ngIf="pageViews == 1">
    <!-- <div class="row"> -->
    <!-- <div class="col-md-12"> -->
    <!-- <div class="table" *ngIf="tableShow">
              <table matSort (matSortChange)="sortData($event)" class="table">
                <thead class="">
                  <tr>
                  
                    <th
                      class="text-center"
                      style="border-radius: 15px 0px 0px 0px"
                    >
                      Action
                    </th>
                    <th mat-sort-header="organizationName" class="center-header" scope="col">
                      Organization Name
                    </th>
                    <th mat-sort-header="industryType" class="center-header" scope="col">
                      Industry Type
                    </th>
                    <th mat-sort-header="address" class="center-header" scope="col">
                      Address
                    </th>
                    <th class="text-center" style="border-radius: 0px 15px 0px 0">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody
                  style="
                    border-left: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                  "
                >
                  <tr
                    *ngFor="
                      let row of organizationListData
                        | paginate : { itemsPerPage: mySelect, currentPage: p }
                        | searchFilter : tableSearch;
                      let i = index
                    "
                  >
               
                    <td class="text-center" style="margin-left: -24px">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
  
                      <mat-menu #menu="matMenu" class="controls-list">
                        <button
                          mat-menu-item
                          routerLink="/home/edit-organization/{{
                            row?.organizationId
                          }}"
                        >
                          <mat-icon>edit</mat-icon>
                          <span>Edit</span>
                        </button>
  
                        <button
                          mat-menu-item
                          routerLink="/home/view-organization/{{
                            row?.organizationId
                          }}"
                          routerLinkActive="active"
                        >
                          <mat-icon>visibility</mat-icon>
                          <span>View</span>
                        </button>
  
                        <button
                          mat-menu-item
                          *ngIf="row?.organizationStatus?.listTypeValueId == 1"
                          (click)="updateStatus(row?.organizationId, 2)"
                        >
                          <mat-icon>done</mat-icon>
                          <span style="color: red">Inactive</span>
                        </button>
  
                        <button
                          mat-menu-item
                          *ngIf="row?.organizationStatus?.listTypeValueId == 2"
                          (click)="updateStatus(row?.organizationId, 1)"
                        >
                          <mat-icon>done</mat-icon>
                          <span style="color: #50cd89 !important">Active</span>
                        </button>
                      </mat-menu>
                    </td>
  
                    <td class="text-center">
                      {{ ucFirst(row?.organizationName) }}
                    </td>
                    <td class="text-center">
                      {{
                        row?.industryType
                          ? row.industryType?.listTypeValueName
                          : ""
                      }}
                    </td>
  
             
                    <td class="text-center">
                      {{ row?.address ? row.address : "-" }}
                    </td>
  
                    <td class="active-status text-center">
                      <span
                        class="btn btn-sm btn-block"
                        style="color: #50cd89 !important"
                        *ngIf="row?.organizationStatus?.listTypeValueId == 1"
                      >
                        {{ row?.organizationStatus?.listTypeValueName }}
                      </span>
  
                      <span
                        class="btn btn-sm btn-block"
                        style="color: red"
                        *ngIf="row?.organizationStatus?.listTypeValueId == 2"
                      >
                        {{ row?.organizationStatus?.listTypeValueName }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  style="
                    border-left: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                  "
                >
                  <tr>
                    <td
                      style="
                        text-align: center;
                        padding: 10px;
                        font-size: 19px;
                        font-weight: 600;
                        font-family: 'Mulish', sans-serif;
                        left: 5;
                        position: relative;
                      "
                      colspan="8"
                      *ngIf="
                        nodata || (organizationListData.length == 0 && tableShow)
                      "
                    >
                      Organization Records Not Found!
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <div
                class="pagination-content"
                *ngIf="organizationListData.length > 5"
                id="page"
              >
                <pagination-controls
                  *ngIf="organizationListData.length > 5"
                  class="text-right;background-color: #fff !important;"
                  style="float: right"
                  (pageChange)="p = $event; beforesearchpage = $event"
                  align="end"
                ></pagination-controls>
              </div>
            </div> -->

    <table
      class="table"
      *ngIf="tableShow"
      matSort
      (matSortChange)="sortData($event)"
    >
      <thead>
        <!-- <th
                  mat-sort-header="sno"
                  scope="col"
                  style="text-align: center"
                >
                  &nbsp;&nbsp;S.No
                </th> -->

        <th class="sticky-col-tab" scope="col">&nbsp;&nbsp;Action</th>
        <th
          mat-sort-header="organizationName"
          style="text-align: center"
          scope="col"
        >
          Organization Name
        </th>

        <th mat-sort-header="Industry" scope="col">Industry&nbsp;Type</th>
        <th mat-sort-header="BusinessUnit" scope="col">
          Business&nbsp;Unit&nbsp;Name
        </th>
        <!-- <th mat-sort-header="address" scope="col">Address</th> -->
        <th mat-sort-header="Status" scope="col">Status</th>
      </thead>
      <tbody
        style="
          border-left: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
        "
      >
        <tr
          *ngFor="
            let row of organizationListData
              | paginate : { itemsPerPage: mySelect, currentPage: p };
            let i = index
          "
        >
          <!-- <td style="text-align: center" *ngIf="p">
                    {{ (p - 1) * mySelect + (i + 1) }}
                  </td>
                  <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td> -->
          <td style="text-align: center">
            <!-- <span class="material-icons" title="edit"    [routerLink]="['/home/business-group', e.businessGroupId]"> edit </span> -->

            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="controls-list">
              <button
                mat-menu-item
                routerLink="/home/edit-organization/{{ row?.organizationId }}"
              >
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button
                mat-menu-item
                routerLink="/home/view-organization/{{ row?.organizationId }}"
                routerLinkActive="active"
              >
                <mat-icon>visibility</mat-icon>
                <span>View</span>
              </button>

              <button
                mat-menu-item
                *ngIf="row?.organizationStatus?.listTypeValueId == 1"
                (click)="updateStatus(row?.organizationId, 2)"
              >
                <mat-icon>done</mat-icon>
                <span style="color: red">Inactive</span>
              </button>

              <button
                mat-menu-item
                *ngIf="row?.organizationStatus?.listTypeValueId == 2"
                (click)="updateStatus(row?.organizationId, 1)"
              >
                <mat-icon>done</mat-icon>
                <span style="color: #50cd89 !important">Active</span>
              </button>
            </mat-menu>
          </td>
          <td style="text-align: center">
            {{ ucFirst(row?.organizationName) }}
          </td>

          <!-- <td
                    style="
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                      min-width: 250px !important;
                    "
                  >
                    {{ e?.businessGroupStatus }}
                  </td> -->

          <td style="min-width: 150px !important">
            {{ row?.industryType ? row?.industryType?.listTypeValueName : "" }}
          </td>

          <td style="min-width: 150px !important">
            {{ row?.businessUnit ? row?.businessUnit?.businessUnitName : "" }}
          </td>

          <!-- <td>
            {{ row?.address ? row.address : "-" }}
          </td> -->
          <td class="active-status text-center">
            <span
              class="btn btn-sm btn-block"
              style="color: #50cd89 !important"
              *ngIf="row?.organizationStatus?.listTypeValueId == 1"
            >
              {{ row?.organizationStatus?.listTypeValueName }}
            </span>

            <span
              class="btn btn-sm btn-block"
              style="color: red"
              *ngIf="row?.organizationStatus?.listTypeValueId == 2"
            >
              {{ row?.organizationStatus?.listTypeValueName }}
            </span>
          </td>
        </tr>
        <tr>
          <td
            style="text-align: center;
            padding: 10px;
            font-size: 19px;
            font-weight: 600;
            font-family: 'Mulish', sans-serif;
            left: 5;
            position: relative;"
            colspan="10"
            *ngIf="nodata || (organizationListData.length == 0 && tableShow)"
          >
            Organization Records Not Found!
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="pagination-content"
      *ngIf="organizationListData.length > 5 && tableShow"
      id="page"
    >
      <pagination-controls
        *ngIf="organizationListData.length > 5 && tableShow"
        class="text-right;background-color: #fff !important;"
        style="float: right"
        (pageChange)="p = $event; beforesearchpage = $event"
        align="end"
      ></pagination-controls>
    </div>
  </div>

  <div class="list_view" *ngIf="pageViews == 2">
    <div *ngIf="organizationListIconData?.length > 0">
      <div class="row mb-3">
        <div
          class="col-md-12 mb-3"
          *ngFor="
            let row of organizationListIconData
              | paginate : { itemsPerPage: mySelectList, currentPage: pList }
             
            let i = index
          "
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <button
                  class="bars"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <!-- <div class="col-md-2 usr-profile-img">
                                                  <img src="{{fileblob}}" alt="Profile Image" class="profile-image text-center">
                                              </div> -->
                <div class="col-md-10 user-basics">
                  <span class="user-name-list">
                    {{ ucFirst(row?.organizationName) }}<br />
                    <p class="user-id-list">
                      {{
                        row?.industryType
                          ? row?.industryType?.listTypeValueName
                          : ""
                      }}
                    </p>
                    <p>
                      {{ row?.businessUnit ? row?.businessUnit?.businessUnitName : "" }}
                    </p>
                  </span>
                  <p class="user-role-list m-0 mb-3">
                    <!--  <mat-icon>work</mat-icon>{{ucFirst(row.role.roleName)}} -->
                  </p>
                  <!-- <span class="text-muted">{{row.gender.listTypeValueName}}</span> -->
                  <!-- <span
                            *ngIf="row.contactPerson"
                            class="list-emp-date pr-2"
                            ><mat-icon>email</mat-icon>
                            {{ row.contactPerson }}</span
                          > -->
                  <!-- <span
                            *ngIf="row.contactEmail"
                            class="list-emp-date pr-2"
                            ><mat-icon>supervised_user_circle</mat-icon>
                            {{ row.contactEmail }}</span
                          >
                          <span
                            *ngIf="row.contactPhoneNumber"
                            class="list-emp-date pr-2"
                            ><mat-icon>phone</mat-icon>
                            {{ row.contactPhoneNumber }}</span
                          > -->
                </div>
              </div>
              <!-- <mat-menu #menu="matMenu" class="controls-list">
                        <button
                          mat-menu-item
                          routerLink="/super-admin-home/edit-organization/{{
                            row.organizationId
                          }}"
                        >
                          <mat-icon>edit</mat-icon>
                          <span>Edit</span>
                        </button>
  
                        <button
                          mat-menu-item
                          routerLink="/super-admin-home/view-organization/{{
                            row.organizationId
                          }}"
                          routerLinkActive="active"
                        >
                          <mat-icon>visibility</mat-icon>
                          <span>View</span>
                        </button>
  
                        <button
                          mat-menu-item
                          *ngIf="row.organizationStatus.listTypeValueId == 1"
                          (click)="updateStatus(row.organizationId, 2)"
                        >
                          <mat-icon>done</mat-icon>
                          <span>Inactive</span>
                        </button>
  
                        <button
                          mat-menu-item
                          *ngIf="row.organizationStatus.listTypeValueId == 2"
                          (click)="updateStatus(row.organizationId, 1)"
                        >
                          <mat-icon>done</mat-icon>
                          <span>Active</span>
                        </button>
                      </mat-menu> -->

              <mat-menu #menu="matMenu" class="controls-list">
                <button
                  mat-menu-item
                  routerLink="/home/edit-organization/{{ row?.organizationId }}"
                >
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button
                  mat-menu-item
                  routerLink="/home/view-organization/{{ row?.organizationId }}"
                  routerLinkActive="active"
                >
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="row?.organizationStatus?.listTypeValueId == 1"
                  (click)="updateStatus(row?.organizationId, 2)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color: red">Inactive</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="row?.organizationStatus?.listTypeValueId == 2"
                  (click)="updateStatus(row?.organizationId, 1)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color: #50cd89 !important;font-size: 18px !important;">Active</span>
                </button>
              </mat-menu>
              <p class="use-active-status">
                <span
                  class="text-success font-weight-bold btn-sm"
                  *ngIf="row.organizationStatus.listTypeValueId == 1"
                >
                  {{ row.organizationStatus.listTypeValueName }}
                </span>

                <span
                  class="text-danger font-weight-bold btn-sm"
                  *ngIf="row.organizationStatus.listTypeValueId == 2"
                >
                  {{ row.organizationStatus.listTypeValueName }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pagination-content"
        *ngIf="organizationListIconData.length > 5 && tableShow"
        id="page"
      >
        <pagination-controls
          *ngIf="organizationListIconData.length > 5 && tableShow"
          class="text-right;background-color: #fff !important;"
          style="float: right"
          (pageChange)="pList = $event; beforeSearchList = $event"
          align="end"
        ></pagination-controls>
      </div>
    </div>
    <!-- <div *ngIf="nodatafound" class="no-data">
                          <img src="assets/uploads/nodata.png"> -->
    <!-- <span class="no-data-found"> No Data Found </span> -->
    <!-- </div> -->
  </div>

  <div class="card_view" *ngIf="pageViews == 3">
    <div *ngIf="organizationViewModuleData?.length > 0">
      <div class="row mb-3">
        <div
          class="col-md-4 mb-3"
          *ngFor="
            let row of organizationViewModuleData
              | paginate : { itemsPerPage: pagePerItemOfGrid, currentPage: pGrid }
              | searchFilter : tableSearch;
            let i = index
          "
        >
          <div class="card org-cards">
            <div class="card-body">
              <div class="row">
                <button
                  class="bars"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <!-- <div class="col-md-2 usr-profile-img">
                                                  <img src="{{fileblob}}" alt="Profile Image" class="profile-image text-center">
                                              </div> -->
                <div class="col-md-10 user-basics">
                  <span class="user-name-list">
                    {{ ucFirst(row?.organizationName) }}
                    <p class="user-id-list">
                      {{
                        row?.industryType
                          ? row?.industryType?.listTypeValueName
                          : ""
                      }}
                    </p>
                    <p>
                      {{ row?.businessUnit ? row?.businessUnit?.businessUnitName : "" }}
                    </p>
                  </span>

                  <p class="user-role-list m-0 mb-3">
                    <!--  <mat-icon>work</mat-icon>{{ucFirst(row.role.roleName)}} -->
                  </p>
                  <!-- <span class="text-muted">{{row.gender.listTypeValueName}}</span> -->
                  <!-- <span
                            *ngIf="row?.contactPerson"
                            class="list-emp-date pr-2"
                            ><mat-icon>supervised_user_circle</mat-icon>
                            {{ row?.contactPerson }}</span
                          > -->
                  <!-- <span
                            *ngIf="row?.contactEmail"
                            class="list-emp-date pr-2"
                            ><mat-icon>email</mat-icon>
                            {{ row?.contactEmail }}</span
                          >
                          <span
                            *ngIf="row.contactPhoneNumber"
                            class="list-emp-date pr-2"
                            ><mat-icon>phone</mat-icon>
                            {{ row.contactPhoneNumber }}</span
                          > -->
                </div>
              </div>
              <!-- <mat-menu #menu="matMenu" class="controls-list">
                        <button
                          mat-menu-item
                          routerLink="/super-admin-home/edit-organization/{{
                            row?.organizationId
                          }}"
                        >
                          <mat-icon>edit</mat-icon>
                          <span>Edit</span>
                        </button>
  
                        <button
                          mat-menu-item
                          routerLink="/super-admin-home/view-organization/{{
                            row?.organizationId
                          }}"
                          routerLinkActive="active"
                        >
                          <mat-icon>visibility</mat-icon>
                          <span>View</span>
                        </button>
  
                        <button
                          mat-menu-item
                          *ngIf="row?.organizationStatus?.listTypeValueId == 1"
                          (click)="updateStatus(row?.organizationId, 2)"
                        >
                          <mat-icon>done</mat-icon>
                          <span>Inactive</span>
                        </button>
  
                        <button
                          mat-menu-item
                          *ngIf="row?.organizationStatus?.listTypeValueId == 2"
                          (click)="updateStatus(row?.organizationId, 1)"
                        >
                          <mat-icon>done</mat-icon>
                          <span>Active</span>
                        </button>
                      </mat-menu> -->

              <mat-menu #menu="matMenu" class="controls-list">
                <button
                  mat-menu-item
                  routerLink="/home/edit-organization/{{ row?.organizationId }}"
                >
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button
                  mat-menu-item
                  routerLink="/home/view-organization/{{ row?.organizationId }}"
                  routerLinkActive="active"
                >
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="row?.organizationStatus?.listTypeValueId == 1"
                  (click)="updateStatus(row?.organizationId, 2)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color: red">Inactive</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="row?.organizationStatus?.listTypeValueId == 2"
                  (click)="updateStatus(row?.organizationId, 1)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color: #50cd89 !important;font-size: 18px !important;">Active</span>
                </button>
              </mat-menu>
              <p class="use-active-status">
                <span
                  class="text-success font-weight-bold btn-sm"
                  *ngIf="row.organizationStatus.listTypeValueId == 1"
                >
                  {{ row?.organizationStatus?.listTypeValueName }}
                </span>

                <span
                  class="text-danger font-weight-bold btn-sm"
                  *ngIf="row.organizationStatus.listTypeValueId == 2"
                >
                  {{ row?.organizationStatus?.listTypeValueName }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
    class="pagination-content"
    *ngIf="organizationViewModuleData.length > 5 && tableShow"
    id="page"
  >
    <pagination-controls
      *ngIf="organizationViewModuleData.length > 5 && tableShow"
      class="text-right;background-color: #fff !important;"
      style="float: right"
      (pageChange)="pGrid = $event; beforeSearchGrid = $event"
      align="end"
    ></pagination-controls>
  </div>
    <!-- <div *ngIf="nodatafound" class="no-data">
                          <img src="assets/uploads/nodata.png"> -->
    <!-- <span class="no-data-found"> No Data Found </span> -->
    <!-- </div> -->
  </div>

  <!-- <div *ngIf="resultData?.length > 0 ">
                      <div class="row mt-3">
                          <div class="col-md-4">
                              <div class="showing-record" *ngIf="resultData?.length > 0 ">
                              </div>
                          </div>
                          <div class="col-md-8 text-right">
                              <pagination-controls (pageChange)="p = $event"></pagination-controls>
                          </div>
                      </div>
                  </div> -->
  <div
    *ngIf="
      resultData?.length > 5 &&
      (resultData | mytask : search)?.length > 0 &&
      tableShow
    "
  >
    <pagination-controls
      (pageChange)="p = $event"
      class="float-right pagination"
      style="float: right"
      *ngIf="(resultData | mytask : search).length > 5"
      align="end"
    ></pagination-controls>
  </div>
</div>
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border table-spinner"
    style="
      height: 3rem;
      width: 3rem;
      color: #009ef7;
      position: absolute;
      z-index: 1;
      top: 390px !important;
      right: 51%;
    "
    role="status"
  ></div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
