
import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, FormGroupName } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LeaveService } from 'src/app/providers/leave.service';
import { LeavemessageService } from 'src/app/leavemessage.service';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() dateInput: EventEmitter<MatDatepickerInputEvent<any>>;

  //
  noOFDays: any;
  WorkingDays: any;
  myFilter: any;
  workDay:any=[];
  EndDate_disable: boolean = true
  EndDate_min: any;
  From_Date: any;
  End_Date: any;
  close_dialogue: boolean;
  withDrawForm: FormGroup;
  // matcher = new MyErrorStateMatcher();
  reportingTo:any
  reportingToSecond:any;
  organizationEmail:any;
  personalEmail:any;
  weekDay:boolean;
  halfDay:boolean;
  validate:boolean;
  notValidate:boolean;
  arrayData:any =[];
  branchId:any
  empId: any;
  // error_msg1: any;
  // success_msg1: any;
  // success1: boolean = false;
  // error1: boolean = false;
  isLoading: Boolean;
  success_msg: any;
  error_msg: any;

  constructor( 
    public dialogRef: MatDialogRef<WithdrawComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private fb: FormBuilder, 
    private gs: GlobalserviceService,
    private leaveservice:LeaveService,
    private leavemessage : LeavemessageService
  ) { }

  ngOnInit() {

    this.close_dialogue = false;
    console.log("this.data",this.data);
    this.From_Date = new Date(this.data.startDate);
    this.End_Date = new Date(this.data.endDate)


    this.withDrawForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],

      withdrawReason: ['', Validators.required],
      withdrawStatus: [3],
      noOfDays:['',Validators.required]
    });
    // working days filteration process
    const myData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = myData.employee.employeeId;
    this.leaveservice.getEmpListById(this.empId).subscribe(
      (x:any)=>{
        this.branchId = x.branch?.branchId;
      }
    )
    if(this.branchId){
      this.leaveservice.getCalByBranch(this.branchId).subscribe(
        (dato: any) => {
          dato.map(
            log => {
              this.leaveservice.getCalendarById(log.calendarId).subscribe(
                (i: any) => {
                  var exception
                  let data
                  i.exceptions.map(
                    b => {
                      if(b.exceptionStatus == 1){
                        if(b.exceptionType != '41'){
                          exception = b.exceptionDate
                          data = new Date(b.exceptionDate)
                          data.setDate(data.getDate());
                          this.arrayData.push(data)
                        }
                      }
                    }
                  )

                  i.holiday.map(a => {
                    if(a.holidayStatus == 1){
                      data = new Date(a.holidayDate)
                      data.setDate(data.getDate());
                      this.arrayData.push(data)
                    }
                  })

                  let b = ["1","2","3","4","5","6","0"]
                  i.calendarWorkingDays.map(dot => {
                    var difference:any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                    this.workDay = difference
                    this.myFilter = (d: Date): boolean => {
                      const day = new Date(d).getDay();
                      const time = new Date(d).getTime()
                      return day !== Number(difference[0]) && day !== Number(difference[1])
                      && day !== Number(difference[2]) && day !== Number(difference[3])
                      && day !== Number(difference[4]) && day !== Number(difference[5])
                      && day !== Number(difference[6])&& !this.arrayData.find(x => new Date(x).getTime() == time)
                    }
                  })
                }
              )
            }
          )
        }
      )
    }

    this.WorkingDays = this.data.employee.noOfWorkingDays;
    let z = JSON.parse(localStorage.getItem('enoteUserData'))
    const empId = z.employee.employeeId;
    const mail = z.organizationEmail
    // this.leaveservice.employee_withdraw_list_action(empId, mail).subscribe(
    //   d => {
    //     console.log(d)
    //   }
    // )
  }
  get f() {
    return this.withDrawForm.controls;
  }

  someMethodName(date: any) {
    this.EndDate_disable = false;
    this.EndDate_min = new Date(date.value);
  }

  nofoDays(i) {
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value)

    let v = new Date(start).toISOString().replace(/T.*$/, '')
    let w = new Date(end).toISOString().replace(/T.*$/, '');

    if(v != w){
      this.weekDay = true;
      this.halfDay = false;
    }
    if(v == w){
      this.halfDay = true;
      this.weekDay = false;
      if(this.data.noOfLeaveDays == 0.5){
       this.validate = true;
       this.notValidate = false;
       this.withDrawForm.patchValue({
        noOfDays:0.5.toString()
       })
      }
      else{
        this.validate = false;
        this.notValidate = true;
      }
    }
    // Copy date objects so don't modify originals
    var s: any = new Date(+start);
    var e: any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12, 0, 0, 0);
    e.setHours(12, 0, 0, 0);

    // Get the difference in whole days
    var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    var days = wholeWeeks * this.WorkingDays + 1;

    // If not even number of weeks, calc remaining weekend days
    let dateArray = new Array()
    let result = new Array()
    while (start <= end) {
      dateArray.push(new Date(start));
      start.setDate(start.getDate() + 1);
    }
    dateArray.map(
      c => {
        if (this.arrayData) {
          this.arrayData.map(
            t => {
              let MyDate = new Date(c)
              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)

              let MyDate1 = new Date(t)
              let MyDateString1 = MyDate1.getFullYear() + '-' + ('0' + (MyDate1.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate1.getDate()).slice(-2)
              if (MyDateString1 == MyDateString) {
                result.push(t)
              }
            }
          )
        }
      }
    )

    // If not even number of weeks, calc remaining weekend days

    s.setDate(s.getDate() + wholeWeeks * 7);
    let dat = new Array()

    dateArray.map(
      e => {
        if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
        && e.getDay() != this.workDay[3]  && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
        && e.getDay() != this.workDay[6]){
          dat.push(e)
        }
        // if (this.WorkingDays == 5){
        //   if (e.getDay() != 0 && e.getDay() != 6) {
        //     dat.push(e)
        //   }
        // }
        // else{
        //   if (e.getDay() != 0 && e.getDay() != 7) {
        //     dat.push(e)
        //   }
        // }

      }
    )

    let uniqueArray = result
      .map(function (date) { return date.getTime() })
      .filter(function (date, i, array) {
        return array.indexOf(date) === i;
      })
      .map(function (time) { return new Date(time); });

      let uniqueArray1 = new Array()

      uniqueArray.map(
        e=>{
          if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
        && e.getDay() != this.workDay[3]  && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
        && e.getDay() != this.workDay[6]){
          uniqueArray1.push(e)
        }
            // if (this.WorkingDays == 5){
            //   if (e.getDay() != 0 && e.getDay() != 6) {
            //     uniqueArray1.push(e)
            //   }
            // }
            // else{
            //   if (e.getDay() != 0 && e.getDay() != 7) {
            //     uniqueArray1.push(e)
            //   }
            // }
        }
      )

      if (uniqueArray1.length > dat.length) {
        days = uniqueArray1.length - dat.length
      }
      if (dat.length > uniqueArray1.length) {
        days = dat.length - uniqueArray1.length
      }

    this.noOFDays = days;
    return days;

  }

  withdraw() {

    let x = this.withDrawForm.value;
    if(x.startDate != x.endDate){
      x['withdrawDays'] = this.noOFDays;
      x['halfday'] = "false"
      this.f.noOfDays.setValue('Leave')

    }
    if (this.withDrawForm.invalid || this.invalidInput) {
      Swal.fire({
        text: 'Please Fill All The Inputs && Withdraw',
        icon: 'info'
      })
      return
    }

    const leave_type = this.data.leaveType;
    const empId = this.data.employeeId;
    // const empName = this.data.employee.employeeName;


    this.close_dialogue = false
    // date time zone error
    let a = this.f.startDate.value;

    if (x) {
      let b = new Date(a);
      b.setMinutes(b.getMinutes() + 480);
      let s = b.toISOString()
      x['startDate'] = s
    }

    let c = this.f.endDate.value;
    if (c) {
      let d = new Date(c);
      d.setMinutes(d.getMinutes() + 480);
      let e = d.toISOString();
      x['endDate'] = e;
    }

    if(x.startDate == x.endDate){
      x['withdrawDays'] = Number(x.noOfDays);
      if(x.withdrawDays == 1){
        x['halfday'] = "false"
      }
      else{
        x['halfday'] = "true"
      }
      if (x.noOfDays == "Leave") {
        this.withDrawForm.controls['noOfDays'].setErrors({ 'required': true });
        return
      }
    }

    // x['withdrawDays'] = this.noOFDays
    x['leaveRequest'] = {
      leaveRequestId: this.data.leaveRequestId,
      // leaveType: this.data.leaveType,
      // applyTo: [this.organizationEmail],
      // cc: [this.personalEmail],
      // leavereportingTo: this.reportingTo,
      // leavereportingToSecond: this.reportingToSecond,
      // employee: {
        employeeId: empId,
        // employeeName: empName
      // }
    }
    // delete x.noOfDays
    // console.log(JSON.stringify(x))
    console.log(x);
    this.isLoading = true;
    this.leaveservice.withdraw(x).subscribe(
      (d: any) => {
        // Swal.fire({
        //   text: 'Applied',
        //   icon: 'success'
        // })
        this.dialogRef.close();

        console.log(d)
        if(d.statusCode===200){
        // this.success1 = true;
        // this.success_msg1 = d.description;
        // this.isLoading = false;
        this.success_msg =d.description ;
        this.leavemessage.setSuccessMessage(
          d.description);

        // console.log("Success Message:", this.success_msg1);

       
        setTimeout(() => {   
            // window.location.reload();
        }, 5000);
      }
      if (d.statusCode === 409) {
        this.dialogRef.close();
        this.error_msg = d.message;
        this.leavemessage.setErrorMessage(
         d.message
        ); // Call method to set success message
      }


      },
      err => {
        // Swal.fire({
        //   text: err.error.message
        // })
        this.dialogRef.close();
        this.error_msg = err.error.message;
        this.leavemessage.setErrorMessage(
          err.error.message
                );

        console.log("error Message:", this.error_msg);

        // this.isLoading = false

  // setTimeout(() => { this.error1 = false; }, 3000); 
      },
      () => {
        this.isLoading = false
      }
    );
  }
  invalidInput: boolean = false;

  validateInput(event: any) {
      const inputElement = event.target;
      let value = inputElement.value;
  
      if (value.startsWith(' ')) {
          value = value.trimStart();
          inputElement.value = value;
      }
  
      this.invalidInput = value.trim().length === 0; // Check if the input consists of only spaces
  }
  

}
