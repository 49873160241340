
<!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
<div >
  <mat-icon style="color: red !important;float: right;" class="material-icons-outlined" (click)="closedialog()">close</mat-icon>          
</div>
<div class="table-responsive">
  <p style="    font-size: 24px;
  font-family: 'Mulish', sans-serif;
  position: relative;
  top: -1px;"><b>Error Records</b></p>
  <ng-container *ngIf="data.at(0).employeeNumber;">
      <table class="table  --table-bordered table-border-new" style="width: 100%;">
          <thead class="t-head">
              <tr>
                  <!-- <th class="text-center">S.No</th> -->
                   <th>Employee Number</th> 
                  <th >Description</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let row of resultData  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                  <!-- <td class="text-center">{{i+1}}</td> -->
                   <td width="30%">{{row.employeeNumber}}</td> 
                  <!-- <td width="30%">
                      <ng-container *ngIf="row.employeeNumber; else clientcode">
                        {{ row.employeeNumber }}
                      </ng-container>
                      <ng-template #clientcode>
                        {{ row.clientCode }} 
                      </ng-template>
                    </td> -->
                  
                  <td width="70%">{{row.description}}</td>      
                  <!-- capitalizeWordsName -->
              </tr>
          </tbody>
          <tbody>
              <tr>
                  <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
              </tr>
          </tbody>
      </table>
  </ng-container>
  <ng-template >
      <table class="table  --table-bordered "style="border-top-left-radius: 10px; border-top-right-radius: 10px;width: 100%;">
          <thead class="t-head">
              <tr>
                  <!-- <th class="text-center">S.No</th> -->
                   <th>Employee Number</th> 
                  <th >Description</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let row of resultData  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: search; let i=index;">
                  <!-- <td class="text-center">{{i+1}}</td> -->
                  <td width="30%">{{row.employeeNumber}}</td>
                  <!-- <td width="30%">
                      <ng-container *ngIf="row.employeeNumber; else clientcode">
                        {{ row.employeeNumber }}
                      </ng-container>
                      <ng-template #clientcode>
                        {{ row.clientCode }} 
                      </ng-template>
                    </td> -->
                  
                  <td width="70%">{{row.description}}</td>      
                  <!-- capitalizeWordsName -->
              </tr>
          </tbody>
          <tbody>
              <tr>
                  <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
              </tr>
          </tbody>
      </table>
  </ng-template>
  <div class="row">
      <div class="col-md-3">
          <div class="showing-record">
              {{resultData.length}} rows
          </div>
      </div>
      <!-- <div class="col-md-9 text-right">
          <pagination-controls (pageChange)="p = $event"  *ngIf="resultData.length > 5"></pagination-controls>
      </div> -->
      <div 
      class="pagination-content "
      *ngIf="resultData?.length > 5 && (resultData | mytask : search).length > 0"
      id="page"
    >
      <pagination-controls  *ngIf="(resultData | mytask : search).length > 5"
          style="position: relative; float: inline-end;top: 7px;"
        (pageChange)="p = $event"
        align="end"
      ></pagination-controls>
    </div>
  </div>
</div>

