

<div class="container">
    <br />
    <!-- <h4 align="center">Assign Employee to this project</h4><br> -->
    <div class="top-content">
      <h4>Approvers</h4>
      <span class="material-icons mat-close" style="color: red" mat-dialog-close
        >cancel</span
      >
    </div>
  
    <div>
      
      <div  *ngIf="storeApproveData?.approver?.approverOneObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ storeApproveData?.approver?.approverOneObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ storeApproveData?.approver?.approverOneObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="approvalStatus(storeApproveData?.approverOneStatus.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(storeApproveData?.approverOneStatus.listTypeValueName)
              "
              align="center"
            >
              <!-- {{ data?.approvalStatusName }} -->
              {{storeApproveData?.approverOneStatus.listTypeValueName}}
            </h6>
          </div>
        </div>
        <hr />
      </div>
      <div  *ngIf="storeApproveData?.approver?.approverTwoObject" >
        <div class="row">
          <div class="col-md-8">
            <h6>{{ storeApproveData?.approver?.approverTwoObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ storeApproveData?.approver?.approverTwoObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4" >
            <h6
              class="approver-status"
              [style.color]="approvalStatus(storeApproveData?.approverTwoStatus.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(storeApproveData?.approverTwoStatus.listTypeValueName)
              "
              align="center"
            >
              {{storeApproveData?.approverTwoStatus.listTypeValueName}}
            </h6>
          </div>
        </div>
        <hr />
      </div>
      <div *ngIf="storeApproveData?.approver?.approverThreeObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ storeApproveData?.approver?.approverThreeObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ storeApproveData?.approver?.approverThreeObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="
                approvalStatus(storeApproveData?.approverThreeStatus.listTypeValueName)
              "
              [style.backgroundColor]="
                approvalBackground(storeApproveData?.approverThreeStatus.listTypeValueName)
              "
              align="center"
            >
              {{ storeApproveData?.approverThreeStatus.listTypeValueName}}
            </h6>
          </div>
        </div>
        <hr />
      </div>
  
      <div *ngIf="storeApproveData?.approver?.approverFourObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ storeApproveData?.approver?.approverFourObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ storeApproveData?.approver?.approverFourObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="approvalStatus(storeApproveData?.approverFourStatus?.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(storeApproveData?.approverFourStatus?.listTypeValueName)
              "
              align="center"
            >
              {{ storeApproveData?.approverFourStatus?.listTypeValueName }}
            </h6>
          </div>
        </div>
        <hr />
      </div>
  
      <div *ngIf="storeApproveData?.approver?.approverFiveObject">
        <div class="row">
          <div class="col-md-8">
            <h6>{{ storeApproveData?.approver?.approverFiveObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ storeApproveData?.approver?.approverFiveObject?.organizationEmail }}
            </h6>
          </div>
          <div class="col-md-4">
            <h6
              class="approver-status"
              [style.color]="approvalStatus(storeApproveData?.approverFiveStatus?.listTypeValueName)"
              [style.backgroundColor]="
                approvalBackground(storeApproveData?.approverFiveStatus?.listTypeValueName)
              "
              align="center"
            >
              {{ storeApproveData?.approverFiveStatus?.listTypeValueName }}
            </h6>
          </div>
        </div>
        <hr />
      </div>
      <div class="top-content">
        <h4>FYI</h4>
      </div>
      <!-- <div> -->
        <!-- <div class="row"> -->
          <div class="col-md-6 mt-n3 ml-n3">
            <h6>{{ storeApproveData?.approver?.fyiObject?.firstName }}</h6>
            <h6 class="email-id-new">
              {{ storeApproveData?.approver?.fyiObject?.organizationEmail }}
            </h6>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
  
  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem"
     
    ></div>
  </div>
  
  <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        <h4 align="center" class="h4">Approvers Not Found</h4>
      </div>
    </div>
  </mat-card>