<!-- <div class="container" style="margin-top: 70px;"> -->
<!-- <mat-card> -->
<!-- <div class="top-content row">
      <div class="">
        <span class="mainname">Assign Employee Project</span>
      </div>
        <div align="end" mat-dialog-actions>
          <button class="btn create-button mb-2" color="primary" type="submit">
            {{this.data.empprojectId ? 'Update' : 'Assign'}}</button>
            <button (click)="importEmployee()" class="btn create-button mb-2" color="primary" type="submit">
            Import</button>
    </div>
  </div> -->
<!-- </mat-card> -->
<nav class="navbar navbar-light bg-light bg" style="margin-top: 68px">
  <h4 class="heading-tag m-2">Assign Employee Project</h4>
  <!-- <button class="btn create-button mb-2" color="primary" type="submit">
    {{this.data.empprojectId ? 'Update' : 'Assign'}}</button> -->
  <button (click)="importEmployee()" class="create-button mb-2 mr-2" type="submit">
    Import
  </button>
  <div class="container-fluid">
    <form class="d-flex"></form>
  </div>
</nav>

<div class="col-md-12">
  <div class="row">
    <div class="card main-card">
      <div class="row">
        <div class="col-md-11">
          <h4 align="center">Assign Employee</h4>
        </div>
      </div>

      <form [formGroup]="employeeForm" (ngSubmit)="save()">
        <div class="row">
          <div class="col-md-12" id="loginform">
            <label class="form-control-label"
              >Project<span class="text-danger">*</span></label
            >
            <mat-select
              formControlName="project"
              placeholder="Select Project "
              class="form-control email-input select-placeholder"
            >
              <mat-option
                *ngFor="let pro of projectList"
                [value]="pro.projectId"
              >
                {{ pro.projectName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                employeeForm.get('project').hasError('required') && submitted
              "
            >
              Project is required
            </mat-error>
          </div>

          <div class="col-md-12" id="loginform">
            <label class="form-control-label"
              >Employee Name<span class="text-danger">*</span></label
            >
            <!-- <mat-select
              formControlName="employee"
              placeholder="Select Employee Name"
              #select multiple
              class="form-control email-input select-placeholder">
        
              <div class="select-all">
                <mat-checkbox
                  [(ngModel)]="allSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
              <mat-option *ngFor="let emp of activeEmployeesData" [value]="emp.employeeId">
                {{emp.firstName}} {{emp.lastName}}
                </mat-option>
              <button
                mat-raised-button
                color="primary"
                style="margin: 0 18px 18px 18px"
                (click)="add()"  class="btn create-button">
                Add
              </button>
              <button mat-raised-button color="warn" 
                (click)="cancel()"
                class="btn cancel-button">
                Cancel
              </button>
            </mat-select> -->

            <!-- <mat-label class="form-label" >Employee <span class="text-danger">*</span></mat-label> -->
            <!-- <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>account_box</mat-icon> -->
            <!-- <mat-select  #select multiple formControlName="employee"    placeholder="Select Employee Name" class="form-control email-input select-placeholder">
              <div class="select-all" style="margin-left: 15px;">
                <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }" (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
              <mat-option style="color: black;" *ngFor="let e of activeEmployeesData" [value]="e.employeeId" (click)="individualCheckboxChanged()">                
                {{e.firstName}} {{e.lastName}}
              </mat-option>
              <button mat-raised-button color="primary" style="margin: 0 18px 18px 18px" (click)="add()" class="btn create-button">Add</button>
              <button mat-raised-button color="warn" (click)="cancel()" style="float: none;"  class="btn cancel-button">Cancel</button>
            </mat-select> -->

            <mat-select
              #select
              multiple
              formControlName="employee"
              placeholder="Select Employee Name"
              class="form-control email-input select-placeholder"
            >
              <div class="select-all" style="margin-left: 15px">
                <mat-checkbox
                  [(ngModel)]="allSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleAllSelection()"
                >
                  Select All
                </mat-checkbox>
              </div>
              <mat-option
                style="color: black"
                *ngFor="let e of activeEmployeesData"
                [value]="e.employeeId"
                (click)="individualCheckboxChanged()"
              >
                {{ e.firstName }} {{ e.lastName }}
              </mat-option>
              <!-- <button
                mat-raised-button
         
                type="button"
            
                (click)="add()"
                class=" create-button"
              >
                Add
              </button>
              <button
                mat-raised-button
                color="warn"
                (click)="cancel()"
                style="float: none"
                class="btn cancel-button"
              >
                Cancel
              </button> -->

              <div class="m-2 d-flex ">
                <div class="mx-2 me-2">
                  <button (click)="add()" class="create-button">Add</button>
                </div>
                <div class="">
                  <button
                    mat-raised-button
                    (click)="cancel()"
                    style="color: var(--Red-Color, #ff5d5d); height: 34px"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </mat-select>

            <!-- <mat-error *ngIf="f.employee.hasError('required')">
              Please select <strong>Employee</strong>
            </mat-error> -->
            <!-- </mat-form-field> -->

            <mat-error
              *ngIf="
                employeeForm.get('employee').hasError('required') && submitted
              "
            >
              Employee Name is required
            </mat-error>
          </div>

          <div class="col-md-12" id="loginform">
            <label class="form-control-label"
              >Role Name<span class="text-danger">*</span></label
            >
            <mat-select
              formControlName="rolename"
              placeholder="Role Name"
              class="form-control email-input select-placeholder"
            >
              <mat-option
                *ngFor="let emps of roleList"
                [value]="emps.organizationId"
              >
                {{ emps.rolename }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                employeeForm.get('rolename').hasError('required') && submitted
              "
              style="margin-left: 3px"
            >
              Role Name is required
            </mat-error>
          </div>
        </div>

        <div align="end" mat-dialog-actions>
          <!-- <button class="btn create-button mb-2" color="primary" type="submit">
                  {{this.data.empprojectId ? 'Update' : 'Assign'}}</button> -->
          <button
            class="btn create-button mb-2"
            color="primary"
            type="submit"
            [disabled]="isButtonDisabled"
            style="position: relative; right: -14px; top: 9px"
          >
            Assign
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="text-center">
    <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong>{{ sucess_msg }}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong>{{ error_msg }}
    <button
      *ngIf="importerror"
      (click)="showerrormessage()"
      class="btn btn-danger"
    >
      View Error Records
    </button>
  </div> -->
<!-- <div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}

<div class="text-center" *ngIf="success" style="margin-top: 70px">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ success_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
View Error Records  </button>
</div> -->
<div class="text-center" *ngIf="success">
  <div
    class="alert alert-success toast-msg"
    role="alert"
    style="margin-top: 67px"
  >
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div
  *ngIf="error"
  class="alert alert-danger alert-dismissible toast-msg"
  style="margin-top: 67px"
>
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
    style="margin-top: 2px"
  >
    View Error Records
  </button>
</div>
