import { Component, Inject, OnInit } from '@angular/core';
import { GlobalserviceService } from '../providers/globalservice.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { expand } from 'rxjs';

@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {
  loandata:any={};
  approvers:any =[];
  fyi:any =[];
  empId:any;
  empNumber:any;
  totalsalary: any;
  advancePaid: any;
  organzationId: any;
  organizationSettings: any;
  tableData: any[] = [];
  installmentCount: number = 0;
  interestPercentage:any;
  totalPayment: any;
  InterestPayable: any;
  emi:any;
  p: number = 1;
  pagePerItem:any=5;
  expand: boolean=false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public dialog: MatDialog,
    public gs: GlobalserviceService,
    private router:Router,
    private datepipe  : DatePipe,
  ) { }

  ngOnInit(): void {
    let localdata = JSON.parse(localStorage.getItem('enoteUserData'));
    // console.log(localdata);
    this.empId = localdata.employee.employeeId;
    this.organzationId = localdata.organization.organizationId;
   
    // console.log(this.empId);

    // console.log("data1",this.data);
    this.gs.getOrganizationdetails(this.organzationId).subscribe(
      (data:any)=>{
        this.organizationSettings=data;
        console.log("this.organizationSettings",this.organizationSettings);
        this.interestPercentage=this.organizationSettings.maxLoanInterest;
      }
    )

    this.gs.getLoanlistByLoanId(this.data).subscribe(
      (loandata:any ) => {
      this.loandata =loandata;
      console.log(this.loandata);
      console.log("approvalStatus",this.loandata.approval.approvalStatus);
      if(loandata.approval != undefined){
        if(loandata.approval.approver.approverOne) {
            this.approvers.push(loandata.approval.approver.approverOneObject.firstName +' '+ loandata.approval.approver.approverOneObject.lastName);
        }
        if(loandata.approval.approver.approverTwo) {
            this.approvers.push(loandata.approval.approver.approverTwoObject.firstName +' '+ loandata.approval.approver.approverTwoObject.lastName);
        }
        if(loandata.approval.approver.approverThree) {
            this.approvers.push(loandata.approval.approver.approverThreeObject.firstName +' '+ loandata.approval.approver.approverThreeObject.lastName);
        }
        if(loandata.approval.approver.approverFour) {
            this.approvers.push(loandata.approval.approver.approverFourObject.firstName +' '+ loandata.approval.approver.approverFourObject.lastName);
        }
        if(loandata.approval.approver.approverFive) {
            this.approvers.push(loandata.approval.approver.approverFiveObject.firstName +' '+ loandata.approval.approver.approverFiveObject.lastName);
        }
        if(loandata.approval.approver.fyi){
          this.fyi.push(loandata.approval.approver.fyiObject.firstName +' '+ loandata.approval.approver.fyiObject.lastName);
        }
        console.log(this.approvers);
        console.log(this.fyi);
      }
    })

  }
  formcancel() {
    this.dialog.closeAll();
  }
  generateTable(event){
    this.expand=!this.expand;
    this.installmentCount = event;
    console.log(this.installmentCount);
    this.tableData = [];
    if (this.installmentCount > 0) {
      const monthlyInterestRate = this.interestPercentage / 12 / 100;
      console.log("monthlyInterestRate",monthlyInterestRate);
      const numerator =  this.loandata.loanRequestedAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, this.installmentCount);
      console.log("numerator",numerator);
      const denominator = Math.pow(1 + monthlyInterestRate, this.installmentCount) - 1;
      console.log("denominator",denominator);
      this.emi = (numerator / denominator).toFixed(2);
      console.log("emi",this.emi);
      this.totalPayment= (this.emi * this.installmentCount).toFixed(2);
      console.log(this.totalPayment);
  
      for(let i = 1; i <= this.installmentCount; i++) {
       let installmentsObject={
          installmentNumber: i, 
          amount: this.emi
        }
        this.tableData.push(installmentsObject);
      }
      console.log(this.tableData);
      this.InterestPayable= (this.totalPayment-this.loandata.loanRequestedAmount).toFixed(2);
      console.log(" this.InterestPayable", this.InterestPayable);
    }
  }
}

