<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class=""><span class="h5 sub-content headingDesing">Positions</span></div>
                <div class="sub-header-buttons">
                  
                    <button *ngIf="positionlistdata?.length > 0 " type="button" (click)="exportToExcel()" mat-raised-button  class=" download-button ml-2 exportDesign" style="    position: relative;
    right: 10px;">
                      Export
                    </button>

                    <a routerLink="/home/add-position" routerLinkActive="active"  mat-raised-button title="Add Position"  class=" download-button addDesign"  *ngIf="isAdmin">
                      Add Position
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <nav class="navbar bg" style="margin-top: 67px">
  <div class="container-fluid">
    <div class="row w-100">
      <div class="col-12 col-md-3">
        <h4 class="heading-tag" style="position: relative; left: 5px">
          Positions
        </h4>
      </div>

      <div class="col-12 col-md-5">
        <div class="input-group">
          <input type="input" class="form-control search-menu search-placeholder" placeholder="Search..."
            [(ngModel)]="search" (keyup)="changePage()" name="searchText" />
        </div>
      </div>

      <div class="col-12 col-md-4 d-flex justify-content-end align-items-center">
        <button *ngIf="positionlistdata?.length > 0 && (positionlistdata | mytask : search).length > 0" type="button" (click)="exportToExcel()" mat-raised-button
          class="download-button ml-2 exportDesign">
          Export
        </button>

        <a routerLink="/home/add-position" routerLinkActive="active" mat-raised-button
          class="download-button addDesign ml-2" *ngIf="isAdmin">
          Add Position
        </a>
      </div>
    </div>
  </div>
</nav> -->
<nav class="navbar bg" style="margin-top: 67px;">
  <div class="container-fluid">
      <div class="row w-100">
          <div class="col-sm-4 col-md-3 d-flex align-items-center mb-2 mb-sm-0">
              <h4 class="heading-tag" style="position: relative; left: 5px;">
               Positions

              </h4>
          </div>

          <div class=" col-sm-8 col-md-9 d-flex justify-content-end align-items-center" style="  position: relative;
          top: -4px;
       ">
              <div class="input-group me-3" style="max-width: 400px;">
                  <input type="text" [(ngModel)]="search"
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid search-input email-input select-placeholder"
                      name="keywords"
                      value=""
                      placeholder="Search...">
              </div>

              <div class="d-flex"  style="height: 38px;
                  padding-top: 5px !important;margin-top: -4px;">
                   <button *ngIf="positionlistdata?.length > 0 && (positionlistdata | mytask : search).length > 0" type="button" (click)="exportToExcel()" mat-raised-button
                    class="download-button ml-2 exportDesign">
                    Export
                  </button>
                  <a routerLink="/home/add-position" routerLinkActive="active" mat-raised-button
                  class="download-button addDesign ml-2" *ngIf="isAdmin">
                  Add Position
                </a>
              </div>
          </div>
      </div>
  </div>
</nav>
<!-- Sub header end here-->

<mat-card style="
    border-radius: 15px;
    border: 2px solid #ddd !important;
    background: #fdfdfd;
    height: auto;
  ">
  <div class="container">
    <div class="row">
      <form [formGroup]="searchForm" (ngSubmit)="submitData()">
        <div class="row mb-3 pl-0">
          <div class="ol-xl-4 col-lg-4 mb-2">
            <!-- [(ngModel)]="tableSearch"  -->
            <label class="form-control-label">Position Name</label>
            <input formControlName="keywords" (keydown)="preventSpaces($event)" (input)="validateInput($event)"
              type="text" autocomplete="off" class="form-control email-input select-placeholder" value=""
              placeholder="Search..." />
            <small class="text-muted" style="  
            color: rgba(0, 0, 0, 0.42) !important;
            position: relative;
            left: 6px;">Note : Position Name</small>
            <!-- <div *ngIf="keywords.dirty">
                        <span *ngIf="keywords.errors?.pattern" class="text-danger">Please Enter Valid Position Name</span>   
                    </div>  -->
          </div>

          <div class="col-xl-4 col-lg-4">
            <!-- <select formControlName="clientId" placeholder="- Select Client -"
                        class="form-select form-select-solid form-select-lg select2-new"  (change)="clientChanges($event)">
                        <option [value]=null selected="selected"> - Select Client - </option>
                        <option class="text-color" [value]=0> In-House </option>
                        <option *ngFor="let e of clientname; let i=index;"
                            value="{{e.clientId}}">{{ e.clientName}}</option>
                    </select> -->
                    <label class="form-control-label"> Client</label>

            <mat-select id="framework" class="form-control email-input select-placeholder no-border-on-focus"
              formControlName="clientId" placeholder="Select Client" (change)="clientChanges($event)">
              <mat-option [value]="null" selected="selected">-Select Client-</mat-option>
              <mat-option [value]="0"> In-House</mat-option>

              <mat-option *ngFor="let e of clientname; let i = index" value="{{ e.clientId }}">
                {{ e.clientName }}
              </mat-option>
            </mat-select>
          </div>

          <!-- <div class="ol-xl-4 col-lg-4 mb-3">
                    <button type="submit" mat-raised-button  color="primary" class=" btn-primary search-btn">Search</button>
                    <button style="margin-left: 10px;" type="reset" mat-raised-button
                    class="btn-clear search-btn mr-2" (click)="clearforms()"
                     >Clear</button>
                </div> -->
          <div class="col-xl-4 col-lg-4 mb-3" style="    margin-top: 21px;">
            <button type="submit" mat-raised-button class="search-btn searchBtn"
              [disabled]="btnDisables" style="border: 0px !important;background:#1a83ff;color:#fff">
              Search
            </button>
            <button style="
                margin-left: 10px;
                background: none;
                color: black;
                border: 1px solid #ddd !important;
              " type="button" mat-raised-button class="btn search-btn mr-2" (click)="clearforms()"
              [disabled]="btnDisables">
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card-content" *ngIf="tableShow">
    <!-- *ngIf="momList.length > 5" -->
    <div class="row p-0 m-0" *ngIf="
        positionlistdata?.length > 5 &&
        (positionlistdata | mytask : search)?.length > 0
      ">
      <div class="col-xl-12 col-lg-12" style="margin-top: -36px"
        *ngIf="(positionlistdata | mytask : search)?.length > 5">
        <div class="select-pagination">
          <span>Show : </span>
          <select class="select_list new-select_list" [(ngModel)]="mySelect" (change)="showChanges()">
            <option class="form-control" value="5">5</option>
            <option class="form-control" value="10">10</option>
            <option class="form-control" value="15">15</option>
            <option class="form-control" value="20">20</option>
            <option class="form-control" value="25">25</option>
          </select>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table matSort (matSortChange)="sortData($event)" class="table table-responsive" style="width: 100%"
        *ngIf="tableShow">
        <thead class="t-head">
          <tr>
            <th class="text-center" style="border-radius: 15px 1px 0px 0">
              Action
            </th>
            <th mat-sort-header="positionName" class="" style="padding: 5px 69px">
              Position Name
            </th>
            <th mat-sort-header="minExperience" class="text-center">
              Min Experience
            </th>
            <th mat-sort-header="maxExperience" class="text-center">
              Max Experience
            </th>
            <th mat-sort-header="clientName">Client Name</th>
            <th mat-sort-header="location" class="">Location</th>
            <th mat-sort-header="ctc" class="text-center">Max CTC</th>
            <th mat-sort-header="jobType">Job Type</th>
            <th>Notice Period</th>
            <th class="text-center" mat-sort-header="positionStatuss">Position Status</th>
            <th class="text-center" style="border-radius: 0px 15px 0px 0px">
              Status
            </th>
          </tr>
        </thead>
        <tbody *ngIf="positionlistdata?.length > 0" style="
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          ">
          <tr *ngFor="
              let e of positionlistdata
                | mytask : search
                | paginate : { itemsPerPage: mySelect, currentPage: p };
              let i = index
            ">
            <td class="text-center">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="controls-list">
                <button mat-menu-item routerLink="/home/edit-position/{{ e.positionId }}" routerLinkActive="active"
                  *ngIf="isAdmin">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button mat-menu-item *ngIf="isAdmin && e.status.listTypeValueId == 1"
                  (click)="statuschangeactive(e.positionId)" style="border: none">
                  <mat-icon>done</mat-icon>
                  <span class="matIcondesign" style="color: red;">Inactive</span>
                </button>

                <button mat-menu-item *ngIf="isAdmin && e.status.listTypeValueId == 2"
                  (click)="statuschangeinactive(e.positionId)" style="border: none">
                  <mat-icon>done</mat-icon>
                  <span class="matIcondesign" style="color: green;">Active</span>
                </button>
                <button mat-menu-item *ngIf="e.positionStatus == true && isAdmin"
                  (click)="statuschangefalse(e.positionId); (falseboolean)" style="border: none">
                  <mat-icon>done</mat-icon>
                  <span class="matIcondesign"  style="color: red;">Close</span>
                </button>

                <button mat-menu-item *ngIf="e.positionStatus == false && isAdmin"
                  (click)="statuschangetrue(e.positionId); (falseboolean)" style="border: none">
                  <mat-icon>done</mat-icon>
                  <span class="matIcondesign"  style="color: green;">Open</span>
                </button>

                <button mat-menu-item routerLink="/home/view-position/{{ e.positionId }}" routerLinkActive="active"
                style=""  *ngIf="isReqruiter">
                  <mat-icon style="    margin-right: 12px!important;
                  vertical-align: middle;
                  margin-top: 0px;
                  position: relative;
                  left: 7px;">remove_red_eye</mat-icon>
                  <span style="    position: relative;
    top: -2px;">View</span>
                </button>
              </mat-menu>
            </td>

            <!-- <ng-template #changestatus>
                        <td class=" table-data text-center text-success" *ngIf="e.positionStatus == true">
                            <ul id="nav">
                                <li class="text-center dropdown-list" >
                                    <a class="text-success dropdown-list-status" *ngIf="e.positionStatus == true">Open</a>
                                    <span>
                                        <mat-icon class="dropdown-list-icon text-success" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                    </span>
                                    <ul class="dropdown-item" (click)="statuschangefalse(e.positionId)">
                                        <li class="material-menu">
                                            <a class="text-danger" style="position: relative; left: 5px;">Close</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </td> -->

            <!-- <td class="table-data text-center text-danger" *ngIf="e.positionStatus == false">
                            <ul id="nav">
                                <li class="text-center dropdown-list" >
                                    <a class="text-danger dropdown-list-status" *ngIf="e.positionStatus == false">Close</a>
                                    <span>
                                        <mat-icon class="dropdown-list-icon " color="#FF0000" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                    </span>
                                    <ul class="dropdown-item" (click)="statuschangetrue(e.positionId)">
                                        <li class="material-menu">
                                            <a class="text-success" style="position: relative; left: 5px;">Open</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                          
                        </td> -->
            <!-- </ng-template> -->
          

            <!-- <ng-container *ngIf="this.usertype=='Recruiter' ; else changestatus">
                        <td class="table-data-recruiter text-center">
                            <a class="text-success dropdown-list-status" style="text-decoration: none;" *ngIf="e.positionStatus == true">Open</a>
                            <a class="text-danger dropdown-list-status" style="text-decoration: none;" *ngIf="e.positionStatus == false">Close</a>
                        </td>
                    </ng-container> -->

            <td>{{ e.positionName ? ucFirst(e.positionName) : "" }}</td>
            <td class="text-center">{{ e.minExperience }} Years</td>
            <td class="text-center">{{ e.maxExperience }} Years</td>
            <!-- <td *ngIf="e.clientId == undefined">In-House</td>
            <td *ngIf="e.clientId != undefined">
              {{ e.clientId.clientName ? ucFirst(e.clientId.clientName) : "" }}
            </td> -->

            <td *ngIf="e.client ==undefined" class="table-data text-center">In-House</td>
            <td *ngIf="e.client !=undefined"class="table-data text-center">{{e.clientId.clientName}}</td>
            <!-- <td *ngIf="e.client !=undefined">{{ e.client ? ucFirst(e.client.clientName) :""}}</td> -->
            <td>{{ e.location ? ucFirst(e.location) : "" }}</td>
            <td class="text-center">{{ e.ctc }}</td>
            <td>{{ e.jobType.listTypeValueName }}</td>
            <td>
              <div style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: flex;
                  flex-direction: column;
                ">
                <span *ngFor="let e of e.noticePeriod">
                  <label class="badge badge-primary mr-2">{{
                    e.listTypeValueName
                  }}</label>
                </span>
              </div>
            </td>
            <td class="active-status text-center">
              <span class="btn-outline-success" *ngIf="e.positionStatus == true">
                <!-- {{e.positionStatus}} -->
                Open
              </span>

              <span class="btn-outline-danger" *ngIf="e.positionStatus == false">
                <!-- {{e.positionStatus}} -->
                Close
              </span>
            </td>
            <td class="active-status text-center">
              <span class="btn-outline-success" *ngIf="e.status.listTypeValueId == 1">
                {{ e.status.listTypeValueName }}
              </span>

              <span class="btn-outline-danger" *ngIf="e.status.listTypeValueId == 2">
                {{ e.status.listTypeValueName }}
              </span>
            </td>
          </tr>
        </tbody>
        <!-- <tbody>
                <tr>
                    <td style="text-align: center;" colspan="11" *ngIf="nodatafound">No Records Found!</td>
                </tr>
            </tbody> -->
        <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd">
          <tr>
            <td style="text-align: center; font-size: larger; font-weight: bold" colspan="11" *ngIf="
                nodatafound ||
                ((positionlistdata | mytask : search)?.length === 0 &&
                  search) ||
                positionlistdata?.length == 0
              ">
              Positions Records Not Found!
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-content" *ngIf="
          positionlistdata?.length > 5 &&
          (positionlistdata | mytask : search).length > 0
        " id="page">
        <pagination-controls *ngIf="(positionlistdata | mytask : search).length > 5"
          style="position: relative; float: inline-end; top: 7px" (pageChange)="p = $event" align="end">
        </pagination-controls>
      </div>
    </div>
  </div>
</mat-card>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div>
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border table-spinner" style="width: 3rem; height: 3rem" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
