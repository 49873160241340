import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap, throwError } from 'rxjs';
import { httpOptions } from './properties';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
 
 
 

 

  headers =  new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
  });
  constructor(private http: HttpClient) { }
  /** Profiles Start here **/
  getEmployeeList(id:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/organization/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  // getSearchEmployeeList(id:any,keywords:any,roleId:any): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.base_url}employee/filter/${roleId}/${keywords}/${id}`,httpOptions).pipe(map(data=>data))
  //   return result;
  // }

  getSearchEmployeeList(id:any,keywords:any,roleId:any,client:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/filter/keyword/${keywords}/role/${roleId}/client/${client}/organization/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getEmployeeListKeywords(keywords:any,id:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/filter/keyword/${keywords}/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getActivesOrganizationRoles(orgId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}role/active_role/organzation/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getActivesClientRoles(clientId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}role/active_role/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
 

  getEmployeeListKeywordsEntity(id:any,keywords:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/filter/keyword/${keywords}/entity/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  ///chekkk

  getEmployeeListRole(id:any,roleId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/filter/role/${roleId}/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getEmployeeRolebyOrganization(keywords:any,roleId:any,orgId:any){
    let result =  this.http.get<any>(`${environment.base_url}employee/filter/keyword/${keywords}/role/${roleId}/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getEmployeeListRoleEntity(id:any,roleId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/filter/role/${roleId}/entity/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  

  getEmployeeListClient(id:any,clientId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee/client/${clientId}/organization/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getEmployeeById(id:any)
  {
    let result = this.http.get(`${environment.base_url}employee/${id}`,httpOptions).pipe(map(data=>data));
    return result;
  }

  updateEmployeeStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.base_url}employee/deactive/${id}/${status}`,null,httpOptions).pipe(map(data=>data));
    return result;
  }

  edituser(id:any,postValues:any)
  {
    let result = this.http.put(`${environment.base_url}employee/profile/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }

  createCheckExistCurrentPassword(postData: any)
  {
    let result = this.http.post(`${environment.base_url}employee/currentpassword`,postData,httpOptions).pipe(map(data => data));
    return result;
  }

  updateChangePassword(postValues:any,id:any)
  {
    let result = this.http.patch(`${environment.base_url}employee/resetpassword/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }
  /** Profiles End here **/


  /** Employee Start here **/
  getActiveRoles(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}role/active_role`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveRolesByOrg() {
    // let result =  this.http.get<any>(`${environment.base_url}role/active_role/organzation/${orgId}`,httpOptions).pipe(map(data=>data))
    // return result;
    let result =  this.http.get<any>(`${environment.base_url}role/superadmin/`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getActivesuperadminRoles(){
    let result =  this.http.get<any>(`${environment.base_url}role/active/superadmin/`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveBranch(orgId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}branch/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getActiveBranchOnly(): Observable<any> {
    let result =  this.http.get<any>(`${environment.base_url}active_branch`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveDesignation(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}designation/active_designation`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveOrgDesignation(orgId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}active_designation/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveClientDesignation(clientId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}active_designation/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveDepartment(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}department/active_department`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveCountry(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}country/active_country`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getOrganizationbyId(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}organization/1`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveState(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}state/active_state`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveDistrict(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}district/active_district`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getActiveCity(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}city/active_city`,httpOptions).pipe(map(data=>data))
    return result;
  }

  createCheckExistEmployeeNumber(postData: any)
  {
    return this.http.post(`${environment.base_url}employee/create/employeeNumber`,postData,httpOptions).pipe(map(data => data));
  }

  createCheckExistEmail(postData: any)
  {
    return this.http.post(`${environment.base_url}employee/create/email`,postData,httpOptions).pipe(map(data => data));
  }

  createCheckExistAlternativeEmail(postData: any)
  {
    return this.http.post(`${environment.base_url}employee/create/alternativeemail`,postData,httpOptions).pipe(map(data => data));
  }
  updateCheckExistAlternativeEmail(postData: any)
  {
    return this.http.post(`${environment.base_url}employee/update/alternativeemail`,postData,httpOptions).pipe(map(data => data));
  }

  createCheckExistMobileNumber(postData: any)
  {
    return this.http.post(`${environment.base_url}employee/create/phonenumber`,postData,httpOptions).pipe(map(data => data));
  }

  createEmployee(postValues: any): Observable<any>
  {
    let result = this.http.post(`${environment.base_url}v2/employee`,postValues,httpOptions).pipe(map(data=>data))
    return result;
  }

  editEmployee(postValues:any,id:any)
  {
    let result = this.http.put(`${environment.base_url}employee/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }

  getemployeeImage(fileViewUrl: any)
  {
    let result = this.http.get(fileViewUrl, { responseType: 'blob',  headers: this.headers } ).pipe(switchMap(response => this.readFile(response)));
    return result;
  }

  getActiveStreams(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}stream/active_stream`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getOrgstram(orgId:any){
    let result =  this.http.get<any>(`${environment.base_url}stream/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getClientstram(clientId:any){
    let result =  this.http.get<any>(`${environment.base_url}stream/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getClientdesignation(clientId:any){
    let result =  this.http.get<any>(`${environment.base_url}designation/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getOrgDesingnation(orgId:any){
    let result =  this.http.get<any>(`${environment.base_url}designation/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getClientdepartment(clientId:any){
    let result =  this.http.get<any>(`${environment.base_url}department/active_department/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getOrgdepartment(orgId:any){
    let result =  this.http.get<any>(`${environment.base_url}department/active_department/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getClientBranch(clientId:any){
    let result =  this.http.get<any>(`${environment.base_url}branch/active/client/${clientId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  getOrgBranch(orgId:any){
    let result =  this.http.get<any>(`${environment.base_url}branch/active/organization/${orgId}`,httpOptions).pipe(map(data=>data))
    return result;
  }
  /** Employee End here **/


  private readFile(blob: Blob): Observable<string>
  {
    return Observable.create(obs =>
      {
      const reader = new FileReader();
      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  uploadFile(postValues:any): Observable<any>
  {
    const ImporthttpOptions = {
        headers: new HttpHeaders({
            'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
        })
    }

    let result = this.http.post(`${environment.base_url}employee/uploadfile`,postValues,ImporthttpOptions).pipe(map(data=>data))
    return result;
  }

  employeeimport(formData: any) {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

  let result = this.http.post(`${environment.base_url}v2/employee/data/import/`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }
  employeeimportV2(formData: any) {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

  let result = this.http.post(`${environment.base_url}v2/employee/data/import/`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }
  employeeimportV2byClient(formData: any) {
    const ImporthttpOptions = {
      headers: new HttpHeaders({
          'Authorization' :'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
      })
  }

  let result = this.http.post(`${environment.base_url}v2/employee/client/data/import/`,formData,ImporthttpOptions).pipe(map(data=>data))
  return result;
  }

  assignEmployeeRole(id:any,postValues:any,)
  {
    let result = this.http.put(`${environment.base_url}employee/role/${id}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }
  
  getIndentityDocumenttypes() {
    let result =  this.http.get<any>(`${environment.base_url}list-type-values/12`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getDocumenttypes() {
    let result =  this.http.get<any>(`${environment.base_url}list-type-values/13`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getemployeeindentityattachments(employeeId: any) {
    let result =  this.http.get<any>(`${environment.base_url}employeeidenityattachment/${employeeId}`,httpOptions).pipe(map(data=>data))
    return result;
  }


  downloademployeeDocuments(filename: any)
  {
    let result = this.http.get(`${environment.base_url}download/${filename}`, { responseType: 'blob',  headers: this.headers } ).pipe(switchMap(response => this.readFile(response)));
    return result;
  }

  uploademployeedocuemnts(filedata: any) {

    
    let result = this.http.post(`${environment.base_url}employeeidenityattachment/multiple`,filedata,httpOptions).pipe(map(data=>data))
    return result;
  }

  getClientById(cliId:any)
  {
    return this.http.get(`${environment.base_url}client/${cliId}`,httpOptions)
    .pipe(map(data=>data));
  }
  // NEW SCREENS API'S ONLY.....................................................................................
  createEntity(data:any) {
    let result = this.http.post(`${environment.base_url}entity`,data,httpOptions)
    return result;
  }
  updateEntity(entityId:any,postValues:any){
    let result = this.http.put(`${environment.base_url}entity/${entityId}`,postValues,httpOptions).pipe(map(data=>data));
    return result;
  }
  getEntityById(entityId:any){
    return this.http.get(`${environment.base_url}entity/${entityId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getEmployeeListKeywordsbyEmployee(keywords:any,clientId:any){
    return this.http.get(`${environment.base_url}employee/filter/keyword/${keywords}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }
  // getEmployeeListbKeywordsbyClient(keywords:any,clientId:any){
  //   return this.http.get(`${environment.base_url}employee/filter/keyword/${keywords}/client/${clientId}`,httpOptions)
  //   .pipe(map(data=>data));
  // }
  getEmployeeListKeywordsbyRole(role:any,clientId){
    return this.http.get(`${environment.base_url}employee/filter/role/${role}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getEmployeeListbKeywordsRolebyClient(keywords:any,roleid:any,clientId:any){
    return this.http.get(`${environment.base_url}employee/filter/keyword/${keywords}/role/${roleid}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getEmployeeRolebyEntity(keywords:any,roleid:any,entityId:any){
    return this.http.get(`${environment.base_url}employee/filter/keyword/${keywords}/role/${roleid}/entity/${entityId}`,httpOptions)
    .pipe(map(data=>data));
  }
  // getAllEmployeeClientList(client: any) {
  //   return this.http.get(`${environment.base_url}employeeclientassign/filter/all/employee/${client}`,httpOptions)
  //   .pipe(map(data=>data));

  // }

  // clientelements(client: any) {
  //   return this.http.get(`${environment.base_url}element-client-assign/client/${client}`,httpOptions)
  //   .pipe(map(data=>data));
  // }


}
