import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/providers/header.service';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

@Component({
  selector: 'app-requirement-detail',
  templateUrl: './requirement-detail.component.html',
  styleUrls: ['./requirement-detail.component.scss']
})
export class RequirementDetailComponent implements OnInit {

  loader:any= true;
  requirementobj:any = {};
  requirementId:any;
  usertype: any;
  userType: any;
  isAdmin: boolean=false;
newFileSelected: any;
  storeAttachment: any;
  orgId:any
  constructor(private requrimentservice :HrmsserviceService,
    private activatedRoute : ActivatedRoute,
    private router: Router,
    private headerservice: HeaderService,

    // @Inject(MAT_DIALOG_DATA) public data: MatDialog
    ) 
     { }

  ngOnInit(): void {
    this.headerservice.setTitle('Requirement ')

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    this.orgId=this.usertype.organization.organizationId;
    console.log(this.usertype);
    // this.positionobj =this.data;
    // console.log(this.data);

    this.userType = [];
    this.usertype.employee.role.forEach(role => {
        this.userType.push(role?.roleName);
    });
    this.isAdmin = this.userType.includes('Admin') ;
    console.log("rolename",this.userType);

    console.log("this.activatedRoute.snapshot.params::",this.activatedRoute.snapshot.params.requirementId);
    

    this.requirementId = this.activatedRoute.snapshot.params.requirementId;

    console.log("this.requirementId::",this.requirementId);
    
    // console.log(this.positionId)
    this.requrimentservice
    .recuirementbyid(this.requirementId,this.orgId)
    .subscribe((data:any) =>
    {
      this.requirementobj = data;
      this.loader = false;
      console.log(this.requirementobj);

      this.storeAttachment=this.requirementobj.jobDescriptionAttachment.sourceFileName;
      console.log(this.storeAttachment);
      

    })
    
  }

  back()
  {
    this.router.navigate(['home/requirement'])
  }


}
