import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organization-compliance-list',
  templateUrl: './organization-compliance-list.component.html',
  styleUrls: ['./organization-compliance-list.component.scss']
})
export class OrganizationComplianceListComponent implements OnInit {
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = false;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=5;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  leaveId: any;
  req: boolean=false;
  startIndex: number;



  constructor(
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
   
    private router:Router) { }

  ngOnInit(): void {
    this.noRecordsFound = false;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);
     this.loader=true;

    if(this.orgId){
      this.payrollservice.getorgcompbyorganziationId(this.orgId).subscribe((data:any) =>
      {
        this.loader=false;

         this.resultData = data;
          if(this.resultData.length == undefined)
          {
             this.nodatafound = true;
          }
      });
    }
    if(this.clientId){
      this.payrollservice.getorgcompbyclientId(this.clientId).subscribe((data:any) =>
      {
        this.loader=false;

         this.resultData = data;
          if(this.resultData.length == undefined)
          {
             this.nodatafound = true;
          }
      });
    }
    
    
  }
  pageChages(event)
  {
    console.log('event.target.value', event.target.value);
    if(event.target.value != null || event.target.value != 0){
      // this.p = event.target.value;
      console.log("page changes");
      this.p=1;
    }
    
  }

  createorgcomplliance()
  {
     this.router.navigateByUrl("/home/organizationcomplaice");
  }


  updateStatus(orgcomplianceId , statusid)
  {

  }
}
