import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignEmpService {

  private successMessageSubject = new BehaviorSubject<string>('');
  private successUpdateMessageSubject =  new BehaviorSubject<string>('');
  private ErrorMessage = new BehaviorSubject<string>('');
  constructor() { }

  setSuccessMessage(message: string, duration: number = 3000) {
    this.successMessageSubject.next(message);
    console.log('Message from service:', message);

    // Automatically clear the message after the specified duration (default: 3000 ms)
    setTimeout(() => {
      this.clearSuccessMessage();
    }, duration);
  }


  getSuccessMessage() {
    return this.successMessageSubject.asObservable();
  }

  clearSuccessMessage() {
    this.successMessageSubject.next('');
  }

  setSuccessUpdateMessage(message: string) {
    this.successUpdateMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getSuccessUpdateMessage() {
    return this.successUpdateMessageSubject.asObservable();
  }

  clearSuccessUpdateMessage() {
    this.successUpdateMessageSubject.next('');
  }


  setErrorMessage(message: string) {
    this.successUpdateMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getErroeMessage() {
    return this.successUpdateMessageSubject.asObservable();
  }

  clearErrorMessage() {
    this.successUpdateMessageSubject.next('');
  }
}
