<div>
    <div class="d-flex">
        <div style="" class="col-9 p-0">
            <h1 style="margin-bottom:15px;color: #181C32;font-weight: 500;">Employee Loan Details</h1>
        </div>
        <div class="col-3 p-0">
            <div class="d-flex float-right" >
                <mat-icon (click)="formcancel()" class="material-symbols-outlined cancel-icon">cancel</mat-icon>  
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Loan Number</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value" *ngIf="loandata.employeeLoanNumber !=undefined">{{loandata.employeeLoanNumber}}</span>
                        <span class="label-value" *ngIf="loandata.employeeLoanNumber == undefined"> -- </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Approver Status&nbsp;</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="float-right text-nowrap label-value" *ngIf="loandata.approval != undefined">{{loandata.approval.approvalStatus.listTypeValueName}}</span>
                        <span *ngIf="loandata.approval== undefined ||loandata.approval== undefined"> -- </span>
                    </p>
                </div>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="col-7">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Employee Number</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">{{loandata.employee.employeeNumber}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Employee Name</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">{{loandata.employee.firstName}} {{loandata.employee.lastName}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Email</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">{{loandata.employee.organizationEmail}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Loan Interest %</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">{{organizationSettings.maxLoanInterest}}%</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Approved Loan Amount</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">{{loandata.loanApprovedAmount.toFixed(2)}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Requested Loan Amount</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">{{loandata.loanRequestedAmount.toFixed(2)}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Installments</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">{{loandata.employeeLoanInstallments.length}}
                            <span class="material-icons-outlined" style="font-size: 16px;position: relative;top: 3px;" (click)="generateTable(loandata.employeeLoanInstallments.length)">{{!expand ? 'expand_more' : 'expand_less' }}</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Loan Type</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value">Loan Type????</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-7">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width">Approvers</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="label-value" style="word-break:unset;" *ngIf="loandata.approval != undefined">{{approvers.join(', ')}}</span>
                        <span style="border:0" *ngIf="loandata.approval == undefined"> -- </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" *ngIf="expand">
        <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12">
            <table class="table table-border-new" >
                <thead class="t-head">
                  <tr>
                    <th class="text-center">Installment Number</th>
                    <th class="text-center">Installment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let d of tableData | paginate: { itemsPerPage: pagePerItem, currentPage: p } ; let i = index ">
                    <!-- <td>{{i + 1 }}</td> -->
                    <td class="text-center table-data" *ngIf="p">{{((p-1)*pagePerItem)+(i+1)}}</td>
                    <td class="text-center table-data" *ngIf="!p">{{i+1}}</td>
                    <td class="text-right" >{{d.amount}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-12 text-right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12">
            <div class="d-flex">
                <div class="label-name">
                    <label class="form-control-label" style="font-weight: 500;">Total Payment <small class="text-nowrap">( including interest )</small></label>
                </div>
                <div>:</div>
                <div>
                    <label class="form-control-label-class" style="font-weight: 500;">{{totalPayment}}</label>
                </div>
            </div>
            <div class="d-flex">
                <div class="label-name">
                    <label class="form-control-label" style="font-weight: 500;">Interest Payable</label>
                </div>
                <div>:</div>
                <div>
                    <label class="form-control-label-class" style="font-weight: 500;">{{InterestPayable}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
