import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
import { AddCountryComponent } from '../add-country/add-country.component';
import { httpOptions,redirectMsgTimeOut,responseMsgTimeOut} from '../../../providers/properties';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit 
{

  public searchFilter: any = '';

  constructor(
    private settingsservice: SettingsService,
    private dialog:MatDialog, 
  ) 
  { }

  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;
  
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  sortedData: any[];

  ngOnInit(): void 
  {
    this.countryList();
  }

  countryList()
  {
    this.settingsservice.getCountryList().subscribe((result: any) => {
      this.resultData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }


  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AddCountryComponent, {
      //height: 'auto',width: '600px',
      width: '700px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result => 
    {
      this.countryList();
     
      if(result.statusCode == 200)
      {
        this.success = true;          
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData)
  {
    var dialogRef = this.dialog.open(AddCountryComponent, { 
      width: '500px',height:'fit-content',
      data:editData
    });   
    
    dialogRef.afterClosed().subscribe(result => 
    {
      this.countryList();
      
      if(result.statusCode == 200)
      {
        this.success = true;          
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }
  
  updateStatus(id,status)
	{
    this.settingsservice.updateCountryStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;          
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.countryList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	} 

  ucFirst(string) 
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'countryName':
          return compare(a.countryName, b.countryName, isAsc);

        case 'countryCode':
          return compare(a.countryCode, b.countryCode, isAsc);
        
        case 'countrySymbol':
          return compare(a.countrySymbol, b.countrySymbol, isAsc);

        case 'currencyCode':
            return compare(a.currencyCode, b.currencyCode, isAsc);
        
        case 'maxPhoneNumberLength':
          return compare(a.maxPhoneNumberLength, b.maxPhoneNumberLength, isAsc);
          
        default:
          return 0;
      }
    });
  }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}