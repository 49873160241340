<form [formGroup]="timesettingsForm" (ngSubmit)="save()">
    <div class="d-flex mb-3" >
        <div class="title-block">
            <div class="title">Enter Time Setting</div>
        </div>
        <div class="concel-block">
            <mat-icon class="material-icons cancel-icon" mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" id="loginform" style="font-family: Mulish;">
            <!-- <mat-form-field class="example-full-width" appearance="outline"> -->
                <mat-label class="form-control-label">Min Time<span class="text-danger"> *</span></mat-label> 
                <!-- <input formControlName="mintime" placeholder="Enter minimum time" maxlength="1" class="form control select-placeholder email-input " (paste)="onPaste($event)"   (keypress)="noNumber($event)" style="background-image: none; outline:none;border-radius: 5px;"> -->
                <input 
                   formControlName="mintime" 
                   placeholder="Enter minimum time" 
                   maxlength="1" 
                   (paste)="onPaste($event)" 
                     (keypress)="noNumber($event)"
                     (keydown)="handleBackspace($event)"
                   class="form-control select-placeholder email-input" 
                   (input)="validateInput($event)" 
                   style="background-image: none; outline: none; border-radius: 5px;">
                <!-- <mat-error *ngIf="(isSubmitted && timesettingsForm.controls['mintime'].invalid) || timesettingsForm.controls['mintime'].dirty || timesettingsForm.controls['mintime'].touched">
                    <small *ngIf="timesettingsForm.controls['mintime'].errors?.required"  id="req" style="color:red;font-size:12px;font-family: 'Mulish', sans-serif;">Min time required</small>
                </mat-error> -->
                <div *ngIf="(timesettingsForm.controls['mintime'].invalid && isSubmitted) || timesettingsForm.controls['mintime'].dirty">
                    <mat-error *ngIf="timesettingsForm.controls['mintime'].errors?.required">Min time required</mat-error>
                </div> 
            <!-- </mat-form-field> -->
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12" id="loginform" style="font-family: Mulish;">
            <!-- <mat-form-field class="example-full-width" appearance="outline"> -->
                <mat-label class="form-control-label">Max Time<span class="text-danger"> *</span></mat-label> 
                <!-- <input formControlName="maxtime" placeholder="Enter maximum time" maxlength="1" class="form control select-placeholder email-input " (paste)="onPaste($event)"   (keypress)="noNumber($event)" style="background-image: none; outline:none;border-radius: 5px;"> -->
                <input 
                   formControlName="maxtime" 
                   placeholder="Enter maximum time" 
                   maxlength="1" 
                   (paste)="onPaste($event)" 
                     (keypress)="noNumber($event)"
                     (keydown)="handleBackspace($event)"
                   class="form-control select-placeholder email-input" 
                   (input)="validateInput1($event)" 
                   style="background-image: none; outline: none; border-radius: 5px;">
                <!-- <mat-error *ngIf="(isSubmitted && timesettingsForm.controls['maxtime'].invalid) || timesettingsForm.controls['maxtime'].dirty || timesettingsForm.controls['maxtime'].touched">
                    <small *ngIf="timesettingsForm.controls['maxtime'].errors?.required"  id="req" style="color:red;font-size:12px;font-family: 'Mulish', sans-serif;">Max time required</small>
                </mat-error> -->
                <div *ngIf="(timesettingsForm.controls['maxtime'].invalid && isSubmitted) || timesettingsForm.controls['maxtime'].dirty">
                    <mat-error *ngIf="timesettingsForm.controls['maxtime'].errors?.required">Max time required</mat-error>
                </div> 
            <!-- </mat-form-field> -->
        </div>
    </div>
    <div class="row float-right mt-2">
        <!-- <div class="col-md-12 pt-1" id="loginform">
            <button mat-stroked-button color="primary" class="form-button" type="submit">Update</button>
        </div> -->
        <div style="margin: 10px 0px;">
            <button mat-stroked-button class="apply-button" color="primary" type="submit" [disabled]="isDisabled">
                Update
            </button>
          </div>
    </div>
</form>



