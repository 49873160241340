<nav class="navbar  bg" style="margin-top: 67px;">
  <a class="navbar-brand navDesign" style="font-size: 22px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;">Leave Balance</a>
 
 
</nav>



<div class="common-content ">
    <div class="card-new colDesign">
      <div class="" style="font-family: Mulish;">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-12">
              <mat-card class="mat-card" style="">
                <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center loader-containe" >
                  <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
          
                  <form (ngSubmit)="onSubmit(leaveTypeForm.value)" [formGroup]="leaveTypeForm">
                    <div class="row">
                      <div class="col-md-12">
                        <!-- <h3 class="title">* Create Leave Balance *</h3> -->
                      </div>
                    </div>
                    <div class="form-group row"> 
                      <div class="col-md-4"> 
                        <label class="form-control-label">Search Employee<span class="star"> *</span></label>
                        <input type="text"  (keyup)="keyFunc($event,$event)" value="" placeholder="Search..."
                        formControlName="employeeId" [matAutocomplete]="temName" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;" autocomplete="off"           (input)="validateInput($event)"
                        (keydown)="validateInput($event)"
                        (keyup)="validateInput($event)"
                        (keydown)="handleBackspace($event)"  (keydown.space)="preventSpaceBeforeInput($event)"   >
                        <!-- <input formControlName="employeeCode"
                        class="form-control form-control-solid form-select-lg"
                        
                        placeholder="Search employee" 
                        [matAutocomplete]="auto"  (keyup)="empSearch($event)"
                        (keydown)="handleBackspace($event)"  (keydown.space)="preventSpaceBeforeInput($event)"> -->
                        <small class="text-muted">Note : Emp No., Employee Name.</small>
                        <!-- <mat-autocomplete #auto="matAutocomplete">  -->
                          <!-- <mat-option value="All" (click)="empAllSelect()">All</mat-option> -->
                          <!-- <mat-option *ngFor="let emp of employeeList" [value]="emp.employeeId"
                              (click)="empSelect(emp)">
                              {{emp.employeeNumber}} - {{emp.firstName}}
                          </mat-option>
                          <mat-option
                              [value]="null"
                              *ngIf="employeeNotFound"
                              (click)="noEmployeeFound()"
                              >No Employee Found</mat-option
                          >
                      </mat-autocomplete>  -->
                         <mat-autocomplete #temName="matAutocomplete">
                            <mat-option *ngFor="let searchData of employeeList" [value]="searchData.employeeId" (click)="empSelect(searchData)">
                                {{searchData.employeeNumber}} - {{searchData.firstName}}
                            </mat-option>
                            <mat-option
                            [value]="null"
                            *ngIf="employeeNotFound"
                            (click)="noEmployeeFound()"
                            >No Employee Found</mat-option
                        >
                        </mat-autocomplete>
                        <!-- <mat-autocomplete class="text-truncate" #auto="matAutocomplete">
                          <mat-option
                            class="text-truncate"
                            *ngFor="let emp of employeeList"
                            [value]="emp?.employee.employeeId"
                            (click)="empSelect(emp)"
                          >
                            {{ emp?.employee?.employeeNumber }} -
                            {{ emp?.employee?.firstName }}
                          </mat-option>
                          <mat-option
                            [value]="null"
                            *ngIf="employeeNotFound"
                            (click)="noEmployeeFound()"
                            >No Employee Found</mat-option
                          >
                        </mat-autocomplete> -->
                        <div *ngIf="(f.employeeId.invalid && submitted) || f.employeeId.dirty">
                            <mat-error *ngIf="f.employeeId.errors?.required"> Please select Employee Number</mat-error>

                        </div>
                         
                      </div>
                      <!-- <div class="col-md-4"> 
                        <label class="col-form-label">Employee Name<span class="star" > *</span></label>
                        <input [(ngModel)]="employeeNumber" formControlName="employeeNumber" readonly 
                        class="form-select form-select-solid form-select-lg"  placeholder="Employee name"/>
                        
                      </div> -->
                      <div class="col-md-4"> 
                        <label class="form-control-label">Leave Type<span class="star"> *</span></label>                                     
                        <mat-select placeholder="Select Leave Type" formControlName="leaveType"
                        class="form control select-placeholder email-input " style="font-family: Mulish ; background-image:none;outline:none;border-radius: 5px;">
                        <!-- <mat-option value="0">-- Select Leave Type -- </mat-option> -->

                           <mat-option *ngFor="let t of type" [value]="t.leaveTypeId">
                                {{t.leaveName}} <em> * </em>bal: {{t.noOfDays}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="(f.leaveType.invalid && submitted) || f.leaveType.dirty">
                            <mat-error *ngIf="f.leaveType.errors?.required"> Please select Leave Type</mat-error>
                        </div> 
                      </div>

                      <div>
                        <mat-error class="error_msg" *ngIf="all_null">Choose valid employee.</mat-error>

                    </div>
                    </div>
                    <div class="form-group">
                      <div class="d-flex justify-content-end">
                        <!-- <button mat-stroked-button class="apply-button" type="submit"  [disabled]="creatingData"  style="font-family: Mulish; top: 39px; height: 38px;">
                          Apply
                        </button> -->
                        <button
          type="submit"
          mat-raised-button
          class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
          [disabled]="!searchButton || leaveTypeForm.invalid"
        >
        Apply
        </button>
                        <button mat-stroked-button type="button" class="apply-button" (click)="Clear()" style="margin: 0px 0px 0px 5px;background: white;border: 1px solid #ddd; font-family: Mulish; color: black;box-shadow: none;font-size:16px;font-weight:600">
                          Clear
                        </button>
                      </div>
                    </div>
                    
                  </form>
              </mat-card>
             
              <br>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
  </div>

  <!-- <div *ngIf="error1" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg1}}
  </div> -->
