import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  content: string;
  title: string;
};

@Component({
  selector: 'app-view-dialog',
  templateUrl: './view-dialog.component.html',
  styleUrls: ['./view-dialog.component.scss']
})
export class ViewDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log('Dialog Data:', this.data);
  }
}
