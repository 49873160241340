import { Component, VERSION } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SuperadminService } from '../app/providers/superadmin.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../app/providers/properties';
import { SettingsService } from '../app/providers/settings.service';
import { AppStateServiceService } from './app-state-service.service';

//import{ GlobalConstants } from '../common/global-constants';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent
{
  globalResultData: any;
  name = 'Angular ' + VERSION.major;
  employeetData:any;
  employeeId:any;
  //organizationId: any;
  dateFormatName : string = "dd-MMM-yyyy";
  dateFormat: string;
  organizationId: string | number;
  userId: string | number;

  constructor(
      private formBuilder: FormBuilder,
      private router : Router,
      private SuperadminService: SuperadminService,
      private ActivatedRoute:ActivatedRoute,
      private SettingsService:SettingsService,
      private appStateService: AppStateServiceService
  ) {
    
    console.log("ACTIVE MODULE CALLING!!");

  //   this.router.navigate(['login']);
    
  //   this.SuperadminService.getActiveModule().subscribe(
  //   (result: any) => {
  //     if (Array.isArray(result)) {
  //       console.log('Active modules received:', result);
  //       result.forEach((element, index) => {
  //         if (element && element.moduleId) {
  //           const elementKey = `module_${element.moduleId}`;
  //           console.log(`Setting item in local storage: ${elementKey}`, element);
  //           localStorage.setItem(elementKey, JSON.stringify(element));
  //         } else {
  //           console.warn(`Element at index ${index} is missing moduleId:`, element);
  //         }
  //       });
  //     } else {
  //       console.error('Expected an array of results, but received:', result);
  //     }
  //   },
  //   (error) => {
  //     console.error('Error fetching active modules:', error);
  //   }
  // );
   }

  ngOnInit(): void
  {
    this.appStateService.logoutObservable$.subscribe(() => {
      this.resetAppState();
    
      // Call the getActiveModule API when the user logs out
      this.SuperadminService.getActiveModule().subscribe(
        (result: any) => {
          if (Array.isArray(result)) {
            console.log('Active modules received:', result);
            result.forEach((element, index) => {
              if (element && element.moduleId) {
                const elementKey = `module_${element.moduleId}`;
                console.log(`Setting item in local storage: ${elementKey}`, element);
                localStorage.setItem(elementKey, JSON.stringify(element));
              } else {
                console.warn(`Element at index ${index} is missing moduleId:`, element);
              }
            });
          } else {
            console.error('Expected an array of results, but received:', result);
          }
        },
        (error) => {
          console.error('Error fetching active modules:', error);
        }
      );
    });
    
    // this.router.navigate(['login']);
    
    this.SuperadminService.getActiveModule().subscribe(
    (result: any) => {
      if (Array.isArray(result)) {
        console.log('Active modules received:', result);
        result.forEach((element, index) => {
          if (element && element.moduleId) {
            const elementKey = `module_${element.moduleId}`;
            console.log(`Setting item in local storage: ${elementKey}`, element);
            localStorage.setItem(elementKey, JSON.stringify(element));
          } else {
            console.warn(`Element at index ${index} is missing moduleId:`, element);
          }
        });
      } else {
        console.error('Expected an array of results, but received:', result);
      }
    },
    (error) => {
      console.error('Error fetching active modules:', error);
    }
  );
  }
  resetAppState() {
    // Add logic to reset or reinitialize your app state
    console.log('AppComponent reinitialized after logout.');
  }
  getOrganizationDateFormat()
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let employeeId = this.employeetData ? this.employeetData.employeeId : 0;
    let OrganizationID = this.employeetData ? this.employeetData?.organization?.organizationId : 0;

    if(OrganizationID)
    {
      localStorage.setItem('OrganizationID',OrganizationID);
      localStorage.setItem('UserID',employeeId);


      
       this.dateFormat = localStorage.getItem('dateFormatName') ? localStorage.getItem('dateFormatName') : this.dateFormatName;
       this.organizationId = localStorage.getItem('OrganizationID') ? localStorage.getItem('OrganizationID') : 0;
       this.userId = localStorage.getItem('UserID') ? localStorage.getItem('UserID') : 0;

       if(this.organizationId){
        console.log("organizaion enterd here!!!");
        
        this.SuperadminService.getOrganizationById(OrganizationID).subscribe((globalResultData:any) =>
          {
            this.globalResultData = globalResultData;
            console.log(" this.globalResultData:", this.globalResultData);
            
            this.dateFormatName = globalResultData.dateFormat ? globalResultData.dateFormat.dateFormatName : "dd-MMM-yyyy";
    
            let dateFormatName = this.dateFormatName;
            localStorage.setItem('dateFormatName',dateFormatName);
    
            // let dateFormat ={
            //   dateFormat:this.dateFormatName
            // }
          });
       }

   
    }
  }

  
  //public static siteTitle: string = "Enote ERP";
  //public static dateFormat: string = this.dateFormatName;
  /* siteTitle = GlobalConstants.siteTitle;
  apiURL = GlobalConstants.apiURL;
  appLogo = GlobalConstants.appLogo;
  appLogoIcon = GlobalConstants.appLogoIcon;
  dateFormat = GlobalConstants.dateFormat;
 */
  /* constructor()
  {
    console.log(GlobalConstants.apiURL);
  }

  ngOnInit()
  {
      console.log(this.siteTitle);
  } */

}
