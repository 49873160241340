import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LeaveService } from 'src/app/providers/leave.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-type-list',
  templateUrl: './type-list.component.html',
  styleUrls: ['./type-list.component.scss']
})
export class 
TypeListComponent implements OnInit {
  list_count = 0;
  noData = false;
  data: any[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns = ['sno', 'leaveName', 'noOfDays', 'status', 'star'];
  // dataSource: any;
  searchModel: string;
  loader = true;
  submitted = false;
  button_label = 'Save';
  label: string = 'create leave type';
  leaveTypeForm: FormGroup;
  id: any;
  daysOptions: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  success: boolean = false;
  success_msg: string;
  error: boolean = false;
  error_msg: any;
processing : boolean=false;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
  pasteCount: any;
  beforesearchpage: number;
  paginators: boolean = true;
  empId: any;
  organizationId: any;

  constructor(
    private gs: GlobalserviceService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private leaveservice: LeaveService,
    private headerservice: HeaderService
  ) { }

  ngOnInit() {

    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;
    console.log(this.organizationId);
    // this.headerservice.setTitle('Leave Type List')
    this.headerservice.setTitle('');

    this.leaveservice.getLeaveType(this.organizationId).subscribe((d: any) => {
      this.loader = false;
      if (d.length == 0) {
        this.noData = true;
      }
      d.forEach(e => {
        if (e.status == 1) {
          e.status = 'Active';
        }
        if (e.status == 2) {
          e.status = 'In-Active';
        }
      });
      this.data = d;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.list_count = this.data.length;
    },
      (error) => {
        this.loader = false;
        this.noData = true;
        console.log("error");
        if (error.statusCode == 204) {
          this.noData = true;
        }
      });

    this.leaveTypeForm = this.fb.group({
      leaveName: ['',[Validators.required, Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],
      noOfDays: ['', [Validators.required,Validators.min(1), Validators.max(30)]],
      createdOn: [''],
      status: ['']
    });

    this.route.paramMap.subscribe(d => {
      this.id = d.get('id');
      if (this.id) {
        this.label = 'update';
        this.button_label = 'update';
      }
    });

    if (this.id) {
      this.leaveservice.getUserTypeById(this.id).subscribe(
        d => {
          this.getEditDataId(d);
        }
      );
    } else {
      this.leaveTypeForm.patchValue({
        status: '1'
      });
    }
  }

  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private getEditDataId(d) {
    console.log("Received data for patching:", d);

    let statusValue: string;
    if (d.status === 'Active') {
      statusValue = '1'; // Assuming '1' corresponds to 'Active' status
    } else if (d.status === 'In-Active') {
      statusValue = '2'; // Assuming '2' corresponds to 'In-Active' status
    }

    this.leaveTypeForm.patchValue({
      leaveTypeId: d.leaveTypeId,
      leaveName: d.leaveName,
      noOfDays: d.noOfDays.toString(),
      status: statusValue // Set the status based on the received data
    });

    console.log("Form after patching:", this.leaveTypeForm.value);
  }

  onSubmit() {
    this.submitted = true;
    this.processing = true;

    if (this.leaveTypeForm.invalid ) {
      
    this.processing = false;

      return;
    }
    
    if (this.id) {
      let x = this.leaveTypeForm.value;
      x['leaveTypeId'] = this.id;
      var date = new Date();
      x['createdOn'] = date;
      let days = Number(x.noOfDays);
      x['noOfDays'] = days;
      x['status'] = Number(x.status);
      x['organizationId']=this.organizationId;
      
      this.leaveservice.updateLeaveType(this.id, x).subscribe(
        (d: any)=> {
          if(d.statusCode == 200)
            {
              this.success = true;
              this.success_msg = d.description;
              this.submitted = false;
              this.id=null;
              this.leaveTypeForm.reset();
              this.fetchdata();
              setTimeout(() =>
              {
                this.success = false;
                this.processing = false;
              },
               3000)
            }
            // if(result.statusCode==409)
            // {
            //     this.error = true;
            //    console.log("Error Create:"+result);
            //   this.error_msg = result.description;
            // }
            else
            {
              this.error = true;
              console.log("Error Create:"+d);
              this.error_msg = d.message;
              this.submitted = false;

              setTimeout(() => 
              {
                 this.error = false;
                   this.processing = false;
                // this.router.navigate(['/home/kra-template']);
              },3000)
            }
          
          // console.log(d,'66666677777788898'
          // )
          // console.log("!111111111","update");

          // this.success = true;
          // this.success_msg = 'update Successfully';
          // this.submitted = false;
          // this.leaveTypeForm.reset();
          

          // this.fetchdata();
          // setTimeout(() => { this.success = false; }, 1000);
          

   
        },
        err => {
          this.error = true;
          this.error_msg = err.error.message;
          this.submitted = false; 

          setTimeout(() => { this.error = false; }, 3000);
          console.error("Error result:", err);

          this.processing = false;

        }
      );
    } else {
      let x = this.leaveTypeForm.value;
      var date = new Date();
      x['createdOn'] = date;
      let days = Number(x.noOfDays);
      x['noOfDays'] = days;
      x['status'] = 1;
      x['organizationId']=this.organizationId;

      this.leaveservice.createLeaveType(x).subscribe(
        d => {
          // Swal.fire({
          //   text: 'Created Successfully',
          //   icon: 'success'
          // });
          console.log("!111111111","create");

          this.success = true;
          this.success_msg = ' LeaveType Created Successfully';
          this.submitted = false;
          this.leaveTypeForm.reset();


          this.fetchdata();
          setTimeout(() => { this.success = false;
            this.processing = false;
           }, 3000);

          // this.router.navigate(['/home/type-list']);
        },
        err => {
            // Swal.fire({
            //   text: err.error.message,
            //   icon: 'error'
            // });

            this.error = true;
            this.error_msg = err.error.message;
            this.submitted = false; // Ensure the form can be resubmitted

            setTimeout(() => { this.error = false; this.processing = false; }, 3000);
            console.log("Error result:", err);

        },
        () => {
          // This callback is called no matter the request was successful or failed
          this.processing = false; // Re-enable the button
      }
      );

    }
  }
  invalidInput: boolean = false;

  // validateInput(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   let value = input.value;
  
  //   // Remove leading spaces
  //   if (value.startsWith(' ')) {
  //     value = value.trimStart();
  //   }
  
  //   // Remove spaces in between
  //   value = value.replace(/\s+/g, '');
  
  //   input.value = value;
  // }
  
  validateInputs(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  get f() {
    return this.leaveTypeForm.controls;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    this.noData = this.list_count == 0;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Clear() {
    this.noData = false;
    // this.loader = true; // Show loader while fetching data
    this.leaveTypeForm.reset();
    this.invalidInput = false; // Reset the invalidInput flag
    this.submitted = false; // Clear the submitted flag
    this.fetchdata(); // Reload data
  }
  

  statuscolor(i: any) {
    if (i == null) {
      return;
    }
    if (i == 'Active') {
      return '#28a745';
    }
    if (i == 'In-Active') {
      return '#f44336';
    }
  }

  edit(i) {
    this.id = i.leaveTypeId; // Set the id of the item being edited
    this.label = 'update';
    this.button_label = 'update';
    this.getEditDataId(i); // Patch the form data
  }


  inactive(i) {
    console.log(i);
    console.log(i.leaveTypeId);
    if (i.status == 'Active') {
      Swal.fire({
        title: 'Are you sure? Do you want to In-Activate',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3f51b5',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'In-Active'
      }).then((result) => {
        if (result.value) {
          this.leaveservice.inactiveLeaveType(i.leaveTypeId, 2).subscribe(d => {
            console.log(d)
            this.success = true;
            this.success_msg = 'Leave Status Updated Sucessfully';
            this.submitted = false;
            // this.leaveTypeForm.reset();
            this.fetchdata();
            setTimeout(() => { this.success = false; }, 3000);

          }); 
        }
      });
    } else if (i.status == 'In-Active') {
      Swal.fire({
        title: 'Are you sure? Do you want to Activate',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3f51b5',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'Active'
      }).then((result) => {
        if (result.value) {
          this.leaveservice.inactiveLeaveType(i.leaveTypeId, 1).subscribe(d => {
            this.success = true;
            this.success_msg = 'Leave Status Updated Sucessfully';
            this.submitted = false;   
            this.fetchdata();
                      setTimeout(() => { this.success = false; }, 3000);

          });
        }
      });
    }
  }

  fetchdata() {
    this.leaveservice.getLeaveType(this.organizationId).subscribe((d: any) => {
      this.loader = false;
      if (d.length == 0) {
        this.noData = true;
      }
      d.forEach(e => {
        if (e.status == 1) {
          e.status = 'Active';
        }
        if (e.status == 2) {
          e.status = 'In-Active';
        }
      });
      this.data = d;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.list_count = this.data.length;
    },
      (error) => {
        this.loader = false;
        this.noData = true;
        console.log("error");
        if (error.statusCode == 204) {
          this.noData = true;
        }
      });
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    const inputElement = event.target;
    const currentValue = inputElement.value;
  
    // Check if the input character is not a number and not a dot
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      return;
    }
  
    // Allow dot only if there is a numeric expression before it
    if (inputChar === '.') {
      if (currentValue === '' || currentValue.endsWith('.')) {
        event.preventDefault();
        return;
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}
