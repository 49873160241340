import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowErrorComponent } from '../show-error/show-error.component';
import { responseMsgTimeOut } from 'src/app/providers/properties';
import { AddTaskImportComponent } from '../add-task-import/add-task-import.component';
import { ImportEmployeeComponent } from '../import-employee/import-employee.component';
import { HeaderService } from 'src/app/providers/header.service';
import { Router } from '@angular/router';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
// import { ImportEmployeeComponent } from 'src/app/project-management/import-employee/import-employee.component';

// import { ImportEmployeeComponent } from '../import-employee/import-employee.component';

@Component({
  selector: 'app-add-multiple-task',
  templateUrl: './add-multiple-task.component.html',
  styleUrls: ['./add-multiple-task.component.scss'],
})
export class AddMultipleTaskComponent implements OnInit {
  @ViewChild('picker1') startDatePicker!: MatDatepicker<any>;
  @ViewChild('picker2') endDatePicker!: MatDatepicker<any>;
  submitClicked: boolean = false;
  Taskform: FormGroup;
  control: FormArray;
  mode: boolean;
  touchedRows: any;
  projectList:any []=[];
  taskList: any;
  min: Date;
  max: Date;
  spaceValidation: boolean = false;
  success = false;
  error: any = false;
  sucess_msg: any;
  errormessagejson: any;
  importerror: boolean;
  startDate_disable: boolean = true;
  endDate_disable: boolean = true;
  minStart: Date;
  projecttaskexist: any = {};
  submitted: boolean = false;
  warningMessage: string = '';
  warning: boolean = false;
  organzationid: any;
  taskarray: any = [];
  istaskexist = { istaskexist: false };
  tasknameexist: boolean = false;
  taskgrouplist: any;
  EmpId: any;
  backdrop: any = false;
  loader: any = false;
  error_msg: any;
  selectedProject: any;
  disableTaskId: boolean = true;
  showDeleteIcon: boolean = false;
  buttonClicked: boolean = false;
  today = new Date();
  endSelectedFirst: boolean;
  constructor(
    private fb: FormBuilder,
    private gs: ProjectManagementService,
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private headerservice: HeaderService,
    private router: Router,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
  ) {

    
  }

  toggleDeleteIconVisibility() {
    this.showDeleteIcon = !this.showDeleteIcon;
  }


  ngOnInit(): void {
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage(
      ''
    );
    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    this.headerservice.setTitle('');
    // this.headerservice.setTitle('Add Task');
    const Data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.EmpId = Data.employee.employeeId;
    this.organzationid = Data.organization.organizationId;

    // this.gs
    //   .listProjectList(this.organzationid)
    //   .subscribe((projectslist: any) => {
    //     this.projectList = projectslist;

    //     console.log('this.projectList', this.projectList);
    //   });

      this.gs
      .getProjectsBasedOnProjectStatus()
      .subscribe((projectslist: any) => {
        this.projectList = projectslist;

        console.log('this.projectList', this.projectList);
      });
    // this.gs.getEmpProjectList(this.EmpId).subscribe((pa: any) => {
    //   this.projectList = pa;
    // });

    this.touchedRows = [];
    this.Taskform = this.fb.group({
      tableRows: this.fb.array([]),
      project: ['', Validators.required],
      taskgroup: ['', Validators.required],
    });
    this.addRow();

    // this.gs.gettaskgroup().subscribe((data: any) => {
    //   this.taskgrouplist = data;
    //   console.log(this.taskgrouplist);
    // });
  }


  // actualDates(event: MatDatepickerInputEvent<Date>) {
  //   const control = this.Taskform.get('tableRows') as FormArray;
  
  //   control.controls.forEach((row) => {
  //     const startDate = row.get('startdate').value;
  //     const endDate = row.get('enddate').value;
  
  //     // Access placeholders relative to the current row
  //     const startPlaceholder = ;
  //     const endPlaceholder = row.get('enddate').parent;
  
  //     if (startDate) {
  //       startPlaceholder.classList.remove('select-placeholder');
  //     } else {
  //       startPlaceholder.classList.add('black-placeholder-date');
  //     }
  
  //     if (endDate) {
  //       endPlaceholder.classList.remove('select-placeholder');
  //     } else {
  //       endPlaceholder.classList.add('black-placeholder-date');
  //     }
  //   });
  // }
  
  
  
  ngAfterOnInit() {
    this.control = this.Taskform.get('tableRows') as FormArray;
  }

  // checkStartDate(): void {
  //   // if (this.startDate && this.endDate && this.startDate > this.endDate) {
  //   //   alert('Start date cannot be greater than end date.');
  //   // }
  // }
  initiateForm(): FormGroup<any> {
    return this.fb.group({
      taskname: ['', [Validators.required]],
      taskdescription: ['', Validators.required],
      startdate: ['', [Validators.required]],
      enddate: ['', [Validators.required]],
      // hours: ['', [Validators.required]],
      // minutes: ['', [Validators.required]],
      isEditable: [true],
    });
  }

  //  TaskNameValidator(control : FormControl) {
  // //   console.log("validot called")
  //    console.log("Task Name : " ,  control.value)
  //    if( this.Taskform.value.project &&  control.value)
  //    {

  //     let control =  this.Taskform.get('tableRows') as FormArray;
  //   control.controls.forEach(element => {
  //          console.log(element);
  //     });

  //     this.gs.getprojecttaskvalidate(control.value, this.Taskform.value.project)
  //     .subscribe(a => {
  //          this.projecttaskexist = a;
  //         //  console.log(this.projecttaskexist);
  //   //        console.log(this.projecttaskexist.isexist);
  //             if(this.projecttaskexist.isexist)
  //            {
  //             let istaskexist =
  //             {istaskexist  : true}
  //             ;

  //             return istaskexist;

  //           }
  //         return null;
  //   })
  //   }
  // }

  validateTaskname(event, rowindex) {
    console.log('taskname' + event.target.value);
    let taskname = event.target.value;
    let control = this.Taskform.get('tableRows') as FormArray;
  }

  TaskNameValidatortest(taskname: any) {
    //   console.log("validot called")
    //    console.log("Task Name : " ,  taskname.value)
    if (this.Taskform.value.project && taskname) {
      this.gs
        .getprojecttaskvalidate(taskname, this.Taskform.value.project)
        .subscribe((a) => {
          this.projecttaskexist = a;
          //  console.log(this.projecttaskexist);
          //        console.log(this.projecttaskexist.isexist);
          if (this.projecttaskexist.isexist) {
            let istaskexist = { istaskexist: true };
            return istaskexist;
          }
          return null;
        });
    }
  }

  //   validateprojecttask(event)
  //   {
  //     console.log(event.target.value);
  // //      let obj = this.TaskNameValidatortest(event.target.value);
  //  //     console.log(obj)
  //         this.gs.getprojecttaskvalidate(event.target.value, this.Taskform.value.project)
  //         .subscribe(a => {
  //               this.projecttaskexist = a;
  //              console.log(this.projecttaskexist);
  //             console.log(this.projecttaskexist.isexist);

  //       })
  //       }
  // date validation
  mintaskendDate(date: any, index: any) {
    this.minStart = new Date(date.value);
    document
      .getElementById('enddtPicker' + index)
      .setAttribute('min', date.target.value);
    this.endDate_disable = false;
  }

  projectData(project) {
    console.log('projecyId', project);

    this.gs.getTaskById(project.projectId).subscribe((x: any) => {
      this.taskList = x;
    });
    this.min = project.projectStartDate.substring(0, 10);
    this.max = project.projectEndDate.substring(0,10);
    this.startDate_disable = false;
    this.disableTaskId = false;
    let control = this.Taskform.get('tableRows') as FormArray;
    control.controls.forEach((element) => {
      element.patchValue({
        startdate: '',
        enddate: '',
      });
    });
    this.gs.gettaskgroup().subscribe((data: any) => {
      this.taskgrouplist = data;
      console.log(this.taskgrouplist);
    });
  }

  addRow() {
    const control = this.Taskform.get('tableRows') as FormArray;
    control.push(this.initiateForm());
  }

  deleteRow(index: number) {
    const control = this.Taskform.get('tableRows') as FormArray;
    control.removeAt(index);
  }

  // editRow(group: FormGroup<any>) {
  //   group.get('isEditable').setValue(true);
  // }

  editRow(group: AbstractControl<any, any>) {
    if (group instanceof FormGroup) {
      group.get('isEditable').setValue(true);
    }
  }
  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.Taskform.value);
  }

  get getFormControls() {
    const control = this.Taskform.get('tableRows') as FormArray;
    return control;
  }

  get tasks() {
    return this.Taskform.get('tasks') as FormArray;
  }

  addTask() {
    const task = this.fb.group({
      taskname: ['', Validators.required],
      taskdescription: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required]
    });

    this.tasks.push(task);
  }

  removeTask(index: number) {
    this.tasks.removeAt(index);
  }

  isFormValid(): boolean {
    if (this.Taskform.valid) {
      // Check if all fields in each row are valid
      for (const control of this.tasks.controls) {
        if (
          control.get('taskname').invalid ||
          control.get('taskdescription').invalid ||
          control.get('startdate').invalid ||
          control.get('enddate').invalid
        ) {
          return false; // Return false if any field in any row is invalid
        }
      }
      return true; // Return true if all fields in all rows are valid
    }
    return false; // Return false if the form itself is invalid
  }



 

  resetForm() {
    this.Taskform.reset();
  }
  submitForm() {
    this.submitClicked =  false;
    this.buttonClicked = false;
    this.submitted = true;
    if (this.Taskform.invalid) {
      this.submitClicked =  false;
      const invalid = [];
      const controls = this.Taskform.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
          invalid.push(name);
        }
      }
      return;
    }

    this.submitClicked =  false;

    // if (this.Taskform.valid) {
    //   const valid = [];
    //   const controls = this.Taskform.controls;
    //   for (const name in controls) {
    //     if (controls[name].valid) {
    //       console.log(name);
    //       valid.push(name);
    //       this.submitted = false;
    //     }
    //   }
    //   return;
    // }

    const fromcontrol = this.Taskform.get('tableRows') as FormArray;

    var valueArr = fromcontrol.controls.map(function (item) {
      return item.value.taskname;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });

    console.log(isDuplicate);

    //    console.log("this.tasknameexist" + this.tasknameexist)

    if (this.Taskform.valid && isDuplicate) {
      Swal.fire({
        icon: 'error',
        text: 'Task Name Exist in Form',
      });
      return;
    }

    const control = this.Taskform.get('tableRows') as FormArray;
    this.touchedRows = control.controls
      .filter((row) => row.touched)
      .map((row) => row.value);
    console.log(this.touchedRows);

    this.touchedRows.forEach((element) => {
      let startdate = this.datepipe.transform(element.startdate, 'YYYY-MM-dd');
      let enddate = this.datepipe.transform(element.enddate, 'YYYY-MM-dd');

      var taskobj = {
        taskName: this.capitalizeWordsName(element.taskname),
        taskStartDate: startdate,
        taskEndDate: enddate,
        taskDescription: this.capitalizeWordsDescription(
          element.taskdescription
        ),
        status: {
          listTypeValueId: 1,
        },
        estimatedHours:7,
        project: {
          projectId: this.Taskform.value.project,
        },
        organizationId: this.organzationid,

        // workhours: element.hours ,
        // workminutes:element.minutes ,
        taskStatus: 31,
        taskGroup: {
          taskGroupId: this.Taskform.value.taskgroup,
        },
      };
      this.taskarray.push(taskobj);
    });

    console.log(this.taskarray);
    this.resetFormData();
      
    this.gs.createMutipletask(this.taskarray).subscribe(
      (a: any) => {
        this.submitted = false;
        console.log('checkingres', a);

        if (a.StatusCode === 200) {
          this.success = true;
          this.sucess_msg = 'Task Added Successfully';
          setTimeout(() => {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            const currentUrl = this.router.url;
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.router.navigateByUrl(currentUrl);
              });
          }, 1000);
        }

        // Swal.fire({
        //   icon: 'success',
        //   text: a.message,
        // });

        setTimeout(() => {
          // window.location.reload();
        }, 6400);
      },
      (err) => {
  
        if (err.error.statusCode == 409 || err.error.errorCode == 409) {
          this.error = true;
          this.error_msg =
            'Task under the  names Performance Task Created Already';
            setTimeout(() => {
              this.error = false;
              this.error_msg = '';
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              const currentUrl = this.router.url;
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.router.navigateByUrl(currentUrl);
                });
            }, 1000);
        }

        // setTimeout(() => {
        //   this.error = true;
        //   this.error_msg = err.error.message;
        // }, 2000);
        // this.error = true;
        // this.error_msg = err.error.message;
        this.taskarray.length = 0;
        //  Swal.fire({
        //    icon: 'error',
        //    text: err.error.message

        //  })
        //  setTimeout(() => {
        //   window.location.reload();
        //  }, 3000);
      }
    );
  }

  resetFormData() {
    this.Taskform.get('project').reset(); // Reset projectname control
    this.Taskform.get('taskgroup').reset(); // Reset taskname control
    // Reset the values of the form controls in each row of the form array
    const control = this.Taskform.get('tableRows') as FormArray;
    control.controls.forEach((row) => {
      row.get('taskname').reset(); // Reset employee control
      row.get('taskdescription').reset(); // Reset startdate control
      row.get('startdate').reset(); // Reset enddate control
      row.get('enddate').reset(); // Reset hours control
      // row.get('minutes').reset(); // Reset minutes control
    });
    this.submitted = false;
  }

  onProjectSelectionChange(projectId: any) {
    console.log('checkingProjectId', projectId);

    if (projectId) {
      this.disableTaskId = false;
    }

    this.selectedProject = projectId;
    // Here you can add any additional logic you need when a project is selected
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get taskgroup() {
    return this.Taskform.get('taskgroup');
  }
  get project() {
    return this.Taskform.get('project');
  }

  capitalizeWordsName(text) {
    return text.replace(/(?:^|\s)\S/g, (res) => {
      return res.toUpperCase();
    });
  }

  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  // importEmployee()
  // {
  //   const dialogRef = this.dialog.open(ImportEmployeeComponent, {

  //     width: '500px',

  //   });

  //   dialogRef.afterClosed().subscribe(result =>
  //   {

  //     if(result.statusCode == 200)
  //     {
  //       this.success = true;
  // 			this.sucess_msg = result.description;
  //       setTimeout(() => {this.success  = false;},responseMsgTimeOut)
  //     }
  //     else
  //     {
  //       if(result.length != 0)
  //      {

  //       this.error = true;

  //       this.importerror = true;
  //       this.errormessagejson = result;

  //      }
  //       else
  //       {
  //       if(result.statusCode > 400)
  //       {
  //         this.error = true;

  //         this.importerror = false;
  //       }
  //       }

  //     }
  //   });
  // }

  // importEmployee() //Add Dialog Box
  // {
  //   const dialogRef = this.dialog.open(AddTaskImportComponent, {
  //     //height: 'auto',width: '600px',
  //     width: '500px',height:'fit-content',
  //   });

  //   dialogRef.afterClosed().subscribe(result =>
  //   {
  //     //this.departmentList();
  //     if(result.statusCode == 200)
  //     {
  //       this.success = true;
  // 			this.sucess_msg = result.description;
  //       setTimeout(() => {this.success  = false;},responseMsgTimeOut)
  //     }
  //     else
  //     {
  //       if(result.length != 0)
  //      {

  //       this.error = true;
  //       //this.error_msg = result.message;
  //       this.importerror = true;
  //       this.errormessagejson = result;
  //       // this.showerrormessage();
  //       // setTimeout(() => {this.error = false}, responseMsgTimeOut)
  //      }
  //       else
  //       {
  //       if(result.statusCode > 400)
  //       {
  //         this.error = true;

  //         this.importerror = false;
  //       }
  //       }

  //     }
  //   });
  // }

  // importEmployee() { //Add Dialog Box
  //   const dialogRef = this.dialog.open(AddTaskImportComponent, {
  //     //height: 'auto',width: '600px',
  //     width: '500px',
  //     height: 'fit-content',
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     //this.departmentList();
  //     if (result.statusCode == 200) {
  //       this.success = true;
  //       this.sucess_msg = result.description;
  //       setTimeout(() => {
  //         this.success = false;
  //       }, responseMsgTimeOut);
  //     } else {
  //       if (result.length != 0) {
  //         this.error = true;
  //         //this.error_msg = result.message;
  //         this.importerror = true;
  //         this.errormessagejson = result;
  //         // this.showerrormessage();
  //         // setTimeout(() => {this.error = false}, responseMsgTimeOut)
  //       } else {
  //         if (result.statusCode > 400) {
  //           this.error = true;

  //           this.importerror = false;
  //         }
  //       }
  //     }
  //   });
  // }

  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }


  
  // Prevent space key from being entered as the first character
  handleKeyPress(event) {
    if (event.key === ' ' && event.target.selectionStart === 0) {
      event.preventDefault();
    }
  }
  

  // updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
  //   const endDate = event.value;
  
  //   const tableRowsArray = this.Taskform.get('tableRows') as FormArray;
  //   const tableRowValues = tableRowsArray.controls.map((row) => {
  //     return {
  //       taskname: row.get('taskname').value,
  //       taskdescription: row.get('taskdescription').value,
  //       startdate: row.get('startdate').value,
  //       enddate: row.get('enddate').value,
  //       // other fields...
  //     };
  //   });
  
  //   // Check if start date is less than end date
  //   const startDateLessThanEndDate = tableRowValues.every(row => row.startdate < endDate);
  
  //   if (!startDateLessThanEndDate) {
  //     // Alert if start date is not less than end date
  //     alert('Start date must be less than end date');
  //     // You can also handle this validation in a different way such as displaying an error message on the UI.
  //   }
  
  //   console.log('tableRowValues', tableRowValues);
  // }
  


  enableButton():boolean{
    this.Taskform.get('tableRows').valid;
   return this.submitted = false;
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    const endDate = event.value;
  
    // Iterate through the FormArray
    const tableRowsArray = this.Taskform.get('tableRows') as FormArray;
    let invalidEndDateFound = false; // Flag to track if any invalid end date is found
  
    tableRowsArray.controls.forEach((row) => {
      const startDate = row.get('startdate').value;
      const rowEndDate = row.get('enddate').value;
  
      // Check if start date is greater than end date for each row
      if (startDate && rowEndDate && startDate > rowEndDate) {
        // Set error state for the row
        row.get('startdate').setErrors({ 'endDateInvalid': true });
        invalidEndDateFound = true; // Set flag if an invalid end date is found
      } else {
        // Reset error if valid
        row.get('startdate').setErrors(null);
      }
    });
  
    // If any invalid end date is found, alert the user
    if (invalidEndDateFound) {
      alert('Start date cannot be greater than end date');
      const control = this.Taskform.get('tableRows') as FormArray;
      control.controls.forEach((row) => {
        // row.get('taskname').reset(); // Reset employee control
        // row.get('taskdescription').reset(); // Reset startdate control
        row.get('startdate').reset(); // Reset enddate control
        row.get('enddate').reset(); // Reset hours control
        // row.get('minutes').reset(); // Reset minutes control
      });
      this.startDatePicker.close();
      this.endDatePicker.close();
    }
  
    // Other code...
  }
  

  checkStartDate(): void {
    const startDate = this.Taskform.get('projectStartDate').value;
    const endDate = this.Taskform.get('projectEndDate').value;
    if (startDate && endDate && startDate > endDate) {
      alert('Start date cannot be greater than end date.');
      const control = this.Taskform.get('tableRows') as FormArray;
      control.controls.forEach((row) => {
        // row.get('taskname').reset(); // Reset employee control
        // row.get('taskdescription').reset(); // Reset startdate control
        row.get('startdate').reset(); // Reset enddate control
        row.get('enddate').reset(); // Reset hours control
        // row.get('minutes').reset(); // Reset minutes control
      });
      this.startDatePicker.close();
      this.endDatePicker.close();
    }
    
  }

  importEmployee() {
    //Add Dialog Box
    const dialogRef = this.dialog.open(AddTaskImportComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      // height: 'fit-content',
      // maxWidth: '90%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //this.departmentList();

      console.log('result ',result);
      
      if (result?.statusCode == 200) {
        this.success = true;
        this.sucess_msg = 'Task Added Successfully';
        setTimeout(() => {
          this.success = false;
        }, 3000) 
      } else {
        if (result?.length != 0 && result != undefined) {
          this.error = true;
          // this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, responseMsgTimeOut)
        } else {
          if (result?.statusCode > 400) {
            this.error = true;

            this.importerror = false;
          }
        }
      }
    });
  }
  showerrormessage() {
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '600px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }
}
