<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Client Tax breakup</span>
                </div>
                <div class="sub-header-buttons">
                    <a  (click)="importEmployee()" mat-raised-button class="ml-2" color="primary" title="Import">
                       <mat-icon class="mat-sidebar-icon">publish</mat-icon> 
                        Import
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3" style="    position: relative;
    top: -22px;">
                                <!-- <label class="col-form-label">Client Name<span class="star"></span></label> -->
                                <!-- <select formControlName="filterorganization" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]="null"> -- Select Organization --- </option>
                                    <option *ngFor="let row of orgList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select> -->
                                <label class="form-control-label">Organization</label>

                                <!-- <mat-select
                                formControlName="filterorganization"
                                placeholder="Select Organization "
                                class="form-control email-input select-placeholder"
                              >
                                <mat-option [value]="undefined" selected="selected"
                                  >-Select Organization Name-</mat-option
                                >
                                <mat-option
                                  *ngFor="let row of orgList; let i = index"
                                  value="{{ row.organizationId }}"
                                  >{{ row.organizationName }}
                                </mat-option>
                              </mat-select> -->
                              <input
                              type="text"
                              placeholder="Organization Name"
                              name="filterorganization"
                              autocomplete="off"
                             readonly
                             [disabled]="true"
                             style="    background: #dddddd87 !important;"
                              [value]="orgName"
                              class="form-control email-input select-placeholder"
                            />
                            <input type="hidden" formControlName="filterorganization" />


                            </div>
                          
                            <div class="col-md-4">
                                <button type="" mat-raised-button 
                                    class="btn  search-btn" [disabled]="btndisables" style="color:#fff;background:#1a83ff">Search</button>
                                <button style="margin-left: 10px;" type="button" mat-raised-button
                                [disabled]="btndisables"      class="btn-clear search-btn mr-2" (click)="clearTable()"
                                    >Clear</button>
                            </div>
                            <!-- <div class="form-group col-md-1" *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" style="position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary" 
                                    class="btn btn-primary search-btn">Export</button>
                            </div> -->
                        </div>
                    </form>
                    <!-- <p style="font-family: 'Mulish', sans-serif;"><b >Manage Client Tax</b></p> -->
                    <div class="col-md-12 sideDesign"><b >Manage Client Tax </b></div>

                        <form [formGroup]="orgtaxbreakup" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Organization Name</label> 
                                    <!-- <select formControlName="organization"   class="form-select form-select-solid form-select-lg select2-new">
                                        <option [ngValue]="null"> --- Select Organization --- </option>
                                        <option *ngFor="let row of orgList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>     -->
                                    <!-- <mat-select
                                    formControlName="organization"
                                    placeholder="Select Organization "
                                    class="form-control email-input select-placeholder"
                                  >
                                    <mat-option [value]="undefined" selected="selected"
                                      >-Select Organization Name-</mat-option
                                    >
                                    <mat-option
                                      *ngFor="let row of orgList; let i = index"
                                      [value]="row.organizationId"
                                      >{{ row.organizationName }}
                                    </mat-option>
                                  </mat-select> -->
                                  <input
                                  type="text"
                                  placeholder="Organization Name"
                                  name="organization"
                                  autocomplete="off"
                                 readonly
                                 [disabled]="true"
                                 style="    background: #dddddd87 !important;"
                                  [value]="orgName"
                                  class="form-control email-input select-placeholder"
                                />
                                    <div class="valid-msg" *ngIf="(validate.organization.invalid && validate.organization.touched) || validate.organization.dirty || submitted">
                                        <span *ngIf="validate.organization.errors?.required" class="text-danger">Please Select Client</span>
                                        <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Salary Start Range<span class="star"> *</span></label>
                                    <input type="text" placeholder="Enter Salary Start Range"  (keypress)="onlyNumber($event)"
                                    formControlName="salaryStartRange"
                                    class="form-control form-control-solid form-control-lg select2-new">
                                    <div class="valid-msg" *ngIf="(validate.salaryStartRange.invalid && validate.salaryStartRange.touched) || validate.salaryStartRange.dirty || submitted">
                                        <span *ngIf="validate.salaryStartRange.errors?.required" class="text-danger">Please Salary Start Range</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Salary End Range<span class="star"> *</span></label>
                                    <input type="text" placeholder="Enter Salary Start Range"  (keypress)="onlyNumber($event)"
                                    formControlName="salaryEndRange"
                                    class="form-control form-control-solid form-control-lg select2-new">
                                    <div class="valid-msg" *ngIf="(validate.salaryEndRange.invalid && validate.salaryEndRange.touched) || validate.salaryEndRange.dirty || submitted">
                                        <span *ngIf="validate.salaryEndRange.errors?.required" class="text-danger">Please Salary End Range</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Tax Amount<span class="star"> *</span></label>
                                    <input type="text" placeholder="Enter Tax Amount"  (keypress)="onlyNumber($event)"
                                               formControlName="taxAmount"
                                               class="form-control form-control-solid form-control-lg select2-new">
                                    <div class="valid-msg" *ngIf="(validate.taxAmount.invalid && validate.taxAmount.touched) || validate.taxAmount.dirty || submitted">
                                        <span *ngIf="validate.taxAmount.errors?.required" class="text-danger">Please Enter Working Days</span>
                                        <span *ngIf="validate.taxAmount.errors?.pattern" class="text-danger">Enter Vaild Working Days </span>
                                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row pb-3">
                                <div class="col-md-12 text-right">
                                        <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="margin-right: 20px;">Clear</button>
                                        <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                            >Save
                                        </button>
                                </div>
                            </div> -->
                            <div class="row pb-3">
                                <div class="col-md-12 text-right">
                                    <button type="submit" mat-raised-button 
                                        class="btn  search-btn" [disabled]="btndisable||orgtaxbreakup.invalid" style="background: #1a83ff;
        color: #ffff;">Save</button>
                                    <button style="margin-left: 10px;" type="button" mat-raised-button
                                       class="btn-clear search-btn mr-2" (click)="clearForm()"
                                        >Clear</button>
                                </div>
                                </div>
                        </form>
                            <!-- <div class="row pb-3" *ngIf="resultData?.length > 0 ">
                                <div class="col-md-11 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-1 text-left pl-0">
                                    <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div> -->
                            <div
                            class="row p-0 m-0"
                            *ngIf="
                              filterData.length > 5 &&
                              
                              tableShow
                            "
                          >
                            <div
                              class="col-xl-12 col-lg-12"
                              style="margin-bottom: 12px"
                              
                            >
                              <div class="select-pagination" style="float: right;    position: relative;
      top: 17px;">
                                <span>Show : </span>
                                <select
                                  class="select_list new-select_list listDesign"
                                  [(ngModel)]="mySelect"
                                  [ngModelOptions]="{ standalone: true }"
                                  (change)="changefirstpage()"
                                >
                                  <option class="form-control" value="5">5</option>
                                  <option class="form-control" value="10">10</option>
                                  <option class="form-control" value="15">15</option>
                                  <option class="form-control" value="20">20</option>
                                  <option class="form-control" value="25">25</option>
                                </select>
                              </div>
                            </div>
                          </div>
                       
                       <div class="table"  *ngIf="tableShow">
                        <!-- <p style="font-family: 'Mulish', sans-serif;"><b>Organization Working Days </b></p> -->
                        <div  class="col-md-12 sideDesign"><b >Organization Tax Breakup </b></div>

                        <table class="table">
                            <thead class="t-head">
                                <tr>
                                    <th style="border-radius: 15px 1px 0px 0;">Action</th>
                                    <!-- <th>Client Code</th> -->
                                    <th>Organization Name</th>
                                    <th>Salary Start Range</th>
                                    <th>Salary End Range</th>
                                    <th>Tax Amount</th>
                                    <th  style="border-radius: 0px 15px 0px 0;">Status</th>
                                </tr>
                            </thead>
                             <tbody>
                                <tr *ngFor="let row of filterData | paginate: { itemsPerPage: mySelect, currentPage: p } let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item (click)="updateform(row)"  >
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                            <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button> -->
                                            <button mat-menu-item *ngIf="row.taxBreakageStatus?.listTypeValueId==1" (click)="updateStatus(row.taxBreakageId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.taxBreakageStatus?.listTypeValueId==2" (click)="updateStatus(row.taxBreakageId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                    <!-- <td>{{row.client.clientCode}}</td> -->
                                    <td>{{row.organization?.organizationName}}</td>
                                    <td>{{row.salaryStartRange}}</td>
                                    <td>{{row.salaryEndRange}}</td>
                                    <td>{{row.taxAmount}}</td>
                                    <!-- <td>{{row.endDate | date :dateFormat}}</td>
                                    <td class="text-center">{{row.ratePerDay}}</td>
                                    <td class="text-center" >{{row.ratePerMonth}}</td> -->
                                    <td class="active-status text-center">
                                        <span class="btn  btn-sm btn-block" style="color:green;" *ngIf="row.taxBreakageStatus?.listTypeValueId==1">
                                            {{row.taxBreakageStatus.listTypeValueName}}
                                        </span>
        
                                        <span class="btn  btn-sm btn-block" style="color:red;" *ngIf="row.taxBreakageStatus?.listTypeValueId==2">
                                            {{row.taxBreakageStatus.listTypeValueName}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody> 
                            <!-- <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="7" *ngIf="noRecordsFound">No Records Found!</td>
                                </tr>
                            </tbody> -->
                            <tbody
                            style="
                              border-left: 1px solid #ddd;
                              border-right: 1px solid #ddd;
                            "
                          >
                            <tr>
                              <td
                                style="
                                  text-align: center;
                                  padding: 10px;
                                  font-size: 19px;
                                  font-weight: 600;
                                  font-family: 'Mulish', sans-serif;
                                  left: 5;
                                  position: relative;
                                "
                                colspan="7"
                                *ngIf="noRecordsFound || (filterData.length == 0 && tableShow)"
                              >
                                Organization Tax Breakup Records Not Found!
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     </div>
                    
                    <!-- <div *ngIf="nodatafound" class="no-data"> -->
                        <!-- <img src="assets/uploads/nodata.png"> -->
                        <!-- <span class="no-data-found"> No Data Found </span> -->
                    <!-- </div> -->
                     <!-- <div *ngIf="resultData?.length > 0 ">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>  -->
                    <div
                    class="pagination-content"
                    *ngIf="filterData.length > 5 && tableShow"
                    id="page"
                  >
                    <pagination-controls
                      *ngIf="filterData.length > 5 && tableShow"
                      class="text-right;background-color: #fff !important;"
                      style="float: right"
                      (pageChange)="p = $event"
                      align="end"
                    ></pagination-controls>
                  </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border table-spinner"
      style="
           height: 3rem;
    width: 3rem;
    color: #009ef7;
    position: absolute;
    z-index: 1;
    top: 442px !important;
    right: 48%;
      "
      role="status"
    ></div>
  </div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>