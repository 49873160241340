<!-- <div class="container"> -->
<!-- <div class="row" style="margin-top: 70px;">
        <div class="col-md-6 resource-title">
            <h2>CATEGORY</h2>
        </div>
        <div class="col-md-4 form-group search-input topnav-right" style="margin-left: 170px;">                                                                     
            <input matInput class="search form-control" type="text" placeholder="Search.." [(ngModel)]="query" (keyup)="onKeyUpEvent($event)" name="search" style="padding-left: 30px;">
            <mat-icon aria-hidden="false" aria-label="Example search icon" class="material-icons-round search-input-icon" style="position: relative; bottom: 26px; padding-left:5px">search</mat-icon>                                                                                                      
        </div>
    </div> -->
<div style="position: relative; height: 64px; visibility: hidden"></div>
<nav class="navbar bg" style=""   id="editForm"
>
  <div class="container-fluid">
    <a
      class="navbar-brand"
      style="
        font-size: 22px;
        font-weight: 800;
        font-family: 'Mulish', sans-serif;
      "
    >
      Category</a
    >
    <form class="d-flex">
      <input
        aria-label="Search"
        class="search-menu searchDesign me-2 no-border-on-focus"
        placeholder="Search..."
        [(ngModel)]="search"
        (keyup)="changepage()"
        name="searchText"
        style="
          /* border-radius: 5px;
                border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
                background: #fff;
                width: 250px;
                height: 45px;
                flex-shrink: 0; */
        "
      />
    </form>
  </div>
</nav>

<!--   
      <div class="card" style="padding: 20px; margin-top: -25px" >
        <form [formGroup]="categoryform" (ngSubmit)="submitData()">
          <div class="row">   
                                      
              <div class="col-md-6" id="loginform">
                  <label class="form-control-label">Category Name<span class="text-danger"> *</span></label>
                  <input type="text" class="form-control email-input select-placeholder" formControlName="expenseCategoryName" placeholder=" Enter Category Name" style="color:black;">
                  <div *ngIf="(expenseCategoryName.invalid && expenseCategoryName.touched) || expenseCategoryName.dirty">
                      <small *ngIf="expenseCategoryName.errors?.required" class="text-danger">Please enter category name</small>
                      <small *ngIf="expenseCategoryName.errors?.pattern" class="text-danger">Please enter valid category name</small>   
                  </div> 
              </div>
          
          
              <div class="col-md-6" id="loginform">
                  <label class="form-control-label">Category Description</label>
                  <textarea type="text" class="form-control email-input" formControlName="description" style="color:black;"></textarea>
              
              </div>  
            </div>
          <div class="row buttons" style="float:right; padding-top: 10px;">
              <div class="col-md-12">
                  <Button type="button" (click)="formcancel()" class="btn cancel-button" style="margin-right: 5px; border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.50));">Cancel</Button>
                  <Button type="submit" [disabled]="categoryform.invalid || btnDisable" class="btn create-button" style="background-color: #1A83FF; color:#FFF; ">{{ data.expenseCategoryId || isEditMode ? "Update" : "Create"}}</Button>
              </div>
          </div>       
        </form>
      </div>   -->
<mat-card
  id="editForm"
  *ngIf="ismatBool"
  style="
    border-radius: 15px;
    border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
    background: #fdfdfd;
    /* top: 64px; */
  "
>
  <form
    class="row g-3"
    *ngIf="isAdmin"
    [formGroup]="categoryform"
    (ngSubmit)="submitData()"
  >
    <div class="col-md-6">
      <!-- <label for="staticEmail2" class="visually-hidden form-control-label"
          >Category Name<span class="text-danger"> *</span></label>
        <input
          type="text"
          class="form-control contrldesign" id="inputPassword2"

          placeholder=" Enter Category Name" formControlName="expenseCategoryName" />
        <mat-error
          *ngIf="
            categoryform.get('expenseCategoryName').hasError('required') && submitted
          "
        style="font-size: 12px;">
        Category name is required
        </mat-error>
        -->
      <label class="form-control-label"
        >Category Name<span class="text-danger"> *</span></label
      >
      <input
        type="text"
        class="form-control email-input select-placeholder"
        formControlName="expenseCategoryName"
        placeholder="Category Name"
      />
      <!-- <div *ngIf="(expenseCategoryName.invalid && expenseCategoryName.touched) || expenseCategoryName.dirty">
            <small *ngIf="expenseCategoryName.errors?.required" class="text-danger">Please enter category name</small>
            <small *ngIf="expenseCategoryName.errors?.pattern" class="text-danger">Please enter valid category name</small>   
        </div>  -->
      <!-- <div *ngIf="expenseCategoryName.invalid || expenseCategoryName.dirty">
             
          <small *ngIf="expenseCategoryName.errors?.pattern" class="text-danger"
            >Please enter valid Category Name</small
          >
        </div> -->
      <!-- <mat-error *ngIf="(categoryform.controls['expenseCategoryName'].errors?.pattern)">
          Please Enter Valid Category Name
      </mat-error>  -->

      <!-- <div
        *ngIf="
      categoryform.get('expenseCategoryName').hasError('required') || submitted"
        class="text-danger"
      >
        <small class="text-danger" *ngIf="expenseCategoryName.errors?.required"> 
          <span>Category Name is required </span>  </small>
        
      </div>
         -->
      <div
        *ngIf="
          categoryform.get('expenseCategoryName').hasError('required') &&
          submitted
        "
        class="text-danger"
      >
        <span class="text-danger">Category Name Is Required </span>
      </div>
      <span
        *ngIf="categoryform.controls['expenseCategoryName'].errors?.pattern"
        class="text-danger"
      >
        Please Enter Valid Category Name
      </span>
      <!-- <div *ngIf="(expenseCategoryName.invalid && expenseCategoryName.touched) || expenseCategoryName.dirty">
            <small *ngIf="expenseCategoryName.errors?.required" class="text-danger">Please enter category name</small>
            <small *ngIf="expenseCategoryName.errors?.pattern" class="text-danger">Please enter valid category name</small>   
        </div>  -->
    </div>
    <div class="col-md-6">
      <label
        for="inputPassword2"
        class="form-control-label"
        formControlName="description"
        style="margin-left: 6px"
        >Category Description</label
      >
      <input
        type="text"
        class="form-control contrldesign"
        id="inputPassword2"
        placeholder="Category Description"
        formControlName="description"
        style="margin-left: 5px"
      />
      <span
        *ngIf="categoryform.controls['description'].errors?.pattern"
        class="text-danger"
      >
        Please Enter Valid Description Name
      </span>
    </div>

    <div class="col-md-3 buttonDesign" style="">
      <button
        mat-raised-button
        type="submit"
        class="searchBtn"
        style="margin-left: 10px; height: 30px; position: relative; top: 0px"
        [disabled]="btnDisable"
      >
        Save
      </button>
      <button
        type="button"
        style="margin-left: 10px"
        mat-raised-button
        class="btn btnRes mr-2"
        (click)="clearForm()"
      >
        Clear
      </button>
    </div>
  </form>

  <!-- ///Listing/// -->

  <div class="card resource-card" style="margin-top: 10px; border: none">
    <div
      class="row"
      *ngIf="
        categorylistdata?.length > 5 &&
        (categorylistdata | mytask : search).length > 0
      "
    >
      <div
        class="col-xl-12 col-lg-12"
        *ngIf="(categorylistdata | mytask : search).length > 5"
      >
        <div
          class="select-pagination"
          style="float: inline-end"
          *ngIf="!nodata"
        >
          <span class="showDesign">Show : </span>
          <select
            class="select_list new-select_list no-border-on-focus"
            [(ngModel)]="mySelect"
            (change)="changefirstpage()"
            style="
              border-radius: 5px;
              border: 1px solid rgb(21 10 10);
              background: #fff;
            "
          >
            <option class="form-control" value="5">5</option>
            <option class="form-control" value="10">10</option>
            <option class="form-control" value="15">15</option>
            <option class="form-control" value="20">20</option>
            <option class="form-control" value="25">25</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="resource-table table-responsive">
          <div >
            <table class="" matSort (matSortChange)="sortData($event)">
              <thead style="background-color: #fff">
                <tr id="trDesign">
                  <th
                    class="text-center"
                    style="border-radius: 15px 1px 0px 0"
                    *ngIf="isAdmin"
                  >
                    Action
                  </th>
                  <th
                    mat-sort-header="expenseCategoryName"
                    class="text-center table-head"
                    style="width: 15%"
                  >
                    Category Name
                  </th>
                  <th
                    mat-sort-header="description"
                    class="text-center table-head"
                    style="width: 65%"
                  >
                    Category Description
                  </th>
                  <th
                    mat-sort-header="listTypeValueName"
                    class="text-center table-head"
                    style="border-radius: 0px 15px 0px 0px"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="categorylistdata?.length > 0"
                style="
                  border-bottom: 1px solid #ddd;
                  border-left: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <tr
                  *ngFor="
                    let e of categorylistdata
                      | mytask : search
                      | paginate : { itemsPerPage: mySelect, currentPage: p }
                  "
                >
                  <td class="table-data text-center" *ngIf="isAdmin">
                    <button
                      class="menu-button"
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon class="menu-icon">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="editCategory(e)">
                        <div>
                          <mat-icon class="material-icons-round edit-icon"
                            >edit</mat-icon
                          >
                          <span class="text">Edit</span>
                        </div>
                      </button>
                      <button
                        mat-icon-button
                        (click)="toggleStatus(e)"
                        [ngClass]="{
                          'text-danger':
                            e.status?.listTypeValueName === 'Active',
                          'text-success':
                            e.status?.listTypeValueName === 'In-Active'
                        }"
                        style="
                          padding-left: 33px;
                          box-sizing: border-box;
                          font-size: 15px;
                        "
                      >
                        {{
                          e.status.listTypeValueName === "Active"
                            ? "In-Active"
                            : "Active"
                        }}
                      </button>
                    </mat-menu>
                  </td>
                  <td class="table-data">{{ e.expenseCategoryName }}</td>
                  <!-- <td class="table-data">{{ e.description }}</td> -->
                  <td class="table-data">
                    {{ e.description ? e.description : "-" }}
                  </td>

                  <!-- <td class="table-data text-center">
                                      <span *ngIf="e.status.listTypeValueName === 'Active'" class="text-success" (click)="statuschangeactive(e.expenseCategoryId)" >{{ e.status.listTypeValueName }}</span>
                                      <span *ngIf="e.status.listTypeValueName === 'In-Active'" class="text-danger" (click)="statuschangeinactive(e.expenseCategoryId)">{{ e.status.listTypeValueName }}</span>
                                  </td> -->
                  <td
                    *ngIf="e.status.listTypeValueName == 'Active'"
                    class="table-data text-center text-success"
                  >
                    <ul id="nav">
                      <li class="text-center dropdown-list">
                        <a
                          class="text-success dropdown-list-status"
                          *ngIf="e.status.listTypeValueName == 'Active'"
                          >{{ e.status.listTypeValueName }}</a
                        >
                        <!-- <span>
                                                <mat-icon class="dropdown-list-icon text-success" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                            </span> -->
                        <ul
                          class="dropdown-item"
                          (click)="statuschangeactive(e.expenseCategoryId)"
                          *ngIf="setInactiveNo"
                        >
                          <li class="material-menu">
                            <a class="text-danger dropdown-item-status-inactive"
                              >In-Active</a
                            >
                            <!-- <mat-icon class="text-danger material-menu-icon" aria-hidden="false">do_disturb</mat-icon> -->
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                  <td
                    *ngIf="e.status.listTypeValueName == 'In-Active'"
                    class="table-data text-center text-danger"
                  >
                    <ul id="nav">
                      <li class="text-center dropdown-list">
                        <a
                          class="text-danger dropdown-list-status"
                          *ngIf="e.status.listTypeValueName == 'In-Active'"
                          >{{ e.status.listTypeValueName }}</a
                        >
                        <!-- <span>
                                              <mat-icon class="dropdown-list-icon text-danger" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                          </span> -->
                        <ul
                          class="dropdown-item"
                          (click)="statuschangeinactive(e.expenseCategoryId)"
                          *ngIf="setActiveNo"
                        >
                          <li class="material-menu">
                            <a class="text-success dropdown-item-status-active"
                              >Active</a
                            >
                            <!-- <mat-icon class="material-menu-icon text-success" aria-hidden="false">verified</mat-icon> -->
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr *ngIf="(categorylistdata | mytask : search).length === 0">
                  <td
                    colspan="9"
                    style="
                      text-align: center;
                      padding: 10px;
                      font-size: 19px;
                      font-weight: 600;
                      font-family: 'Mulish', sans-serif;
                      left: 5;
                      position: relative;
                    "
                  >
                    Search Category Not Found
                  </td>
                </tr>
              </tbody>
              <tbody
                style="
                  border-left: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <tr>
                  <td
                    style="
                      text-align: center;
                      padding: 10px;
                      font-size: 19px;
                      font-weight: 600;
                      font-family: 'Mulish', sans-serif;
                      left: 5;
                      position: relative;border:1px solid #80808057;
                    "
                    colspan="6"
                    *ngIf="nodata || categorylistdata.length == 0"
                  >
                    Category Records Not Found!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div *ngIf="nodata">
                      <div class="no-data card text-center">
                          <div class="card-body">No Data</div> 
                      </div>
                  </div>   -->
          <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
                    <div class="card-body-nodata">
                      <div class="alert-nodata alert-primary">
                        <h4 align="center" class="h4" style="    margin-top: 14px;
                        font-size: 24px;
                        font-family: Roboto, Helvetica Neue, sans-serif;
                        margin-bottom: 11px;
                        ">Category Not Found </h4>
                      </div>
                    </div>
                  </mat-card> -->
          <!-- <div *ngIf="list_loader" class="align-items-center text-center">
                      <div class="spinner-border" 
                      style="width: 3rem; height: 3rem;color: #1a83ff;position: relative;bottom: 0;margin-left: 90%;" role="status"></div>
                  </div>                                  -->
        </div>
      </div>
    </div>
    <div class="row topnav-right pagination-content" *ngIf="categorylistdata">
      <div class="col-md-12">
        <pagination-controls
          class="float-right pagination"
          style="float: right"
          *ngIf="
            (categorylistdata | mytask : search).length > 5 &&
            (categorylistdata | mytask : search).length > 0
          "
          (pageChange)="p = $event; beforepage = $event"
        ></pagination-controls>
      </div>
    </div>
  </div>
</mat-card>

<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="
      height: 3rem;
      width: 3rem;
      color: #009ef7;
      position: absolute;
      z-index: 1;
      top: 37%;
      right: 49%;
    "
    role="status"
  ></div>
</div>
<!-- <div class="text-center" *ngIf="success"> 
          <div class="alert alert-success" role="alert"  style="margin-top: 67px;">
          <strong>Success!</strong><br>{{create_sucess_msg}}
          </div>
          </div>
          <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg" >
          <strong>Error!</strong><br>{{update_error_msg}}
          </div> -->
<div class="text-center" *ngIf="success" id="editForm">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ errorMsg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    something wtts
  </button>
</div>

<!-- </div> -->
