<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class=""><span class="h5 sub-content">Element Percentage</span></div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-8">
                        <div class="row mb-3 pl-0">
                            <div class="col-md-12">
                                <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                                    <div class="row mb-3 pl-0">
                                        <div class="col-md-6">
                                            <label class="form-control-label">Element Name</label>
                                            <mat-select class="mat-select form-control email-input select-placeholder"
                                                appearance="fill" formControlName="elementNameSearch"
                                                (selectionChange)="listTypeValueid($event)"
                                                placeholder="Select Element Name">
                                                <mat-option *ngFor="let e of elementDatalist" class="text-color"
                                                    [value]="e.elementId">{{e.elementName}}</mat-option>
                                            </mat-select>
                                            <span style="
                                            color: rgba(0, 0, 0, 0.42) !important;
                                            position: relative;
                                            left: 6px;
                                            font-family: 'Mulish', sans-serif;">Note : Element Name.</span>
                                        </div>
                                        <div class="col-md-6" style="    margin-top: 25px;">
                                            <!-- <button type="submit" mat-raised-button 
                                                class=" search-btn">Search</button>
                                            <button style="    margin-left: 20px;
                                            height: 33px;
                                            position: relative;
                                            top: 4px;" type="reset" mat-raised-button
                                            mat-raised-button class="btn mr-2 " 
                                                (click)="clearTable()">Clear</button> -->
                                                <button   mat-raised-button type="submit" [disabled]="btnDisable"
                                                class="searchBtn  mr-2"  style=" margin-left: 10px;
                                                height: 34px;
                                                position: relative;
                                                top: 0px;"
                                                >Search</button>
                                            <button style="margin-left: 20px;" type="reset" mat-raised-button
                                               class="btn-clear mr-2" (click)="clearTable()"[disabled]="btnDisable" style="background-color: none !important;"
                                                >Clear</button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </div>
                    <!-- <div class="col-md-4"> -->
                        <!-- <div class="row">
                            <div class="col-md-9 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-3 text-left pl-0">
                                <select [(ngModel)]="pagePerItem" (change)="onItemsPerPageChange($event)" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                        </div> -->
                       
                    <!-- </div> -->
                    <div class="card-body">
                        <form [formGroup]="elementform" (ngSubmit)="submitData()">
        
                            <div class="row">   
                                <div class="form-group col-md-3" >
                                    <label class="form-control-label">Element Type <span class="star">*</span></label>
                                    <mat-select class="mat-select form-control email-input select-placeholder" (selectionChange)="elementType($event)"  appearance="fill" formControlName="categoryType" placeholder="Select Element Type">
                                        <mat-option *ngFor="let e of categoryTypeData" class="text-color" [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
                                    </mat-select>
                                    <div class="valid-msg"
                                        *ngIf="validate.categoryType.touched && validate.categoryType.invalid">
                                        <span *ngIf="validate.categoryType.errors.required"
                                            class="text-danger">Please Select Element Type</span>
                                    </div>
                                </div>  
                                <div class="form-group col-md-3" >
                                    <label class="form-control-label">Element Name <span class="star">*</span></label>
                                    <mat-select class="mat-select form-control email-input select-placeholder" (selectionChange)="listTypeValueidElementName($event)" appearance="fill" formControlName="elementName" placeholder="Select Element Name">
                                        <mat-option *ngFor="let e of elementNameData2" class="text-color" [value]="e.elementId">{{e.elementName}}</mat-option>
                                    </mat-select>
                                    <div class="valid-msg"
                                    *ngIf="validate.elementName.touched && validate.elementName.invalid">
                                    <span *ngIf="validate.elementName.errors.required"
                                        class="text-danger">Please Select Element Name</span>
                                </div>
                                </div>  
                                <div class="form-group col-md-3" *ngIf="showLivingCity">
                                    <label class="form-control-label">Living City </label>
                                    <mat-select class="mat-select form-control email-input select-placeholder" appearance="fill" formControlName="livingCity" placeholder="- Select Type -">
                                        <mat-option *ngFor="let e of livingCityData" class="text-color" [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
                                    </mat-select>
                                </div>  
                                <div class="form-group col-md-3">
                                    <label class="form-control-label">Percentage <span class="star">*</span></label>
                                    <input type="text" class="form-control email-input select-placeholder"  formControlName="percentage"  placeholder="Enter Percentage" appPercentageDirective>  
                                    <div class="valid-msg"
                                        *ngIf="(percentage.invalid && percentage.touched) || percentage.dirty">
                                        
                                        <span *ngIf="percentage.errors?.required" class="text-danger">Please Enter
                                            Percentage</span>
                                        <span *ngIf="percentage.errors?.pattern" class="text-danger">Please Enter Valid
                                            Percentage</span>
                                    </div>
                                </div>  
                                <div class="col-md-3 " style="    position: relative;
    top: 25px;">   
    <!-- <button type="submit" mat-raised-button style="color:#fff;"
                                        class=" searchBtn mr-2 search-btn" [disabled]="elementform.invalid">Save</button>
                                    <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="      /* margin-right: 60px !important; */
                                        height: 33px;
                                        position: relative;
                                        top: 3px;
                                        left: 9px;">Clear</button> -->
                                        <button   mat-raised-button type="submit" [disabled]="elementform.invalid"
                                        class="searchBtn  mr-2"  style=" margin-left: 10px;
                                        height: 34px;
                                        position: relative;
                                        top: 0px;"
                                        >Save</button>
                                          <button type="button" mat-raised-button 
                                              class="btn  btn-clear btnRes  mr-2" (click)="formcancel()" [disabled]="elementform.invalid">Clear</button>
                                     
                                        <!-- <button type="submit" class=" search-btn btn-sm" [disabled]="elementform.invalid">Save</button> -->
                                        <!-- [disabled]="resourceform.invalid || !maxExp" -->
                                </div>
                            </div>
<!--         
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="margin-right: 20px;">Clear</button>
                                    <button type="submit" class="submit action-button btn btn-primary btn-sm" [disabled]="elementform.invalid">Save</button>
                                </div>
                            </div>        -->
                        </form>  
                    </div>
                </div>
                <div class="float-end"  *ngIf="categoryTypeData.length>0 && tableShow" >
                    <div class="row rowDesignshow" *ngIf="categoryTypeData.length>5" >
                      <div class="select-pagination" style=" margin-top: 85px;margin-left: 15px;margin-bottom: 10px;">
                        <span class="showPage showDesign">Show</span>
                        <select
                          class="select_list new-select_list listDesign"
                          [(ngModel)]="mySelect"
                          [ngModelOptions]="{standalone: true}" (change)="changefirstpage()"
                        >
                          <option class="form-control" value="5">5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                        </select>
                      </div>
                    </div>
                  </div>

            

                <div class="table-responsive  " *ngIf="tableShow">
                    <b class="DesigniLabel">MANAGE ELEMENT PERCENTAGE</b>
                    <table class="table --table-bordered table-border-new" >
                        <thead class="t-head">
                            <tr>
                                <th class="text-center" style="border-radius: 15px 0px 0px 0px">Action</th>
                                <th>Category Type</th>
                                <th>Element Name</th>
                                <th>Percentage</th>
                                <th class="text-center" style="border-radius: 0px 15px 0px 0">Status</th>
                            </tr>
                        </thead>
                        <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd" >
                            <tr
                                *ngFor="let e of elementslistData1 | mytask: search | paginate: { itemsPerPage: pagePerItem, currentPage: p } ">
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="edit(e.elementPercentageId)">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>


                                        <button mat-menu-item *ngIf="e.percentageStatus.listTypeValueId == 1"
                                            (click)="statuschangeactive(e.elementPercentageId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span style="color:red">In-active</span>
                                        </button>

                                        <button mat-menu-item *ngIf="e.percentageStatus.listTypeValueId ==2"
                                            (click)="statuschangeinactive(e.elementPercentageId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span style="color:green;">Active</span>
                                        </button>

                                    </mat-menu>
                                </td>

                                <td>{{e.hrmspaySlipElement.categoryType.listTypeValueName}}</td>
                                <td>{{e.hrmspaySlipElement.elementName}}</td>
                                <td>{{e.elementPercentage}}</td>
                                <td class="active-status text-center">
                                    <span class="btn-outline-success"
                                        *ngIf="e.percentageStatus.listTypeValueId  == 1">
                                        Active
                                    </span>

                                    <span class="btn-outline-danger"
                                        *ngIf="e.percentageStatus.listTypeValueId  == 2">
                                        In-Active
                                    </span>
                                </td>
                            
                            </tr>
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="5" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr>
                        </tbody> -->
                        <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd">
                            <tr>
                              <td
                                style="
                                   text-align: center;
                                padding: 10px;
                                font-size: 19px;
                                font-weight: 600;
                                font-family: 'Mulish', sans-serif;
                                left: 5;
                                position: relative;
                               " colspan="5" 
                                
                              
                                *ngIf="
                                  noRecordsFound ||elementslistData1?.length == 0"
                              >
                                Element Percentage Records Not Found!
                              </td>
                            </tr>
                          </tbody>
                        
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="showing-record">
                        </div>
                    </div>
                    <!-- <div class="col-md-8 text-right" *ngIf="!isDataLoading && elementslistData1length > 10">
                        <pagination-controls (pageChange)="p = $event" *ngIf="!this.noRecordsFound"></pagination-controls>
                    </div> -->
                    <div *ngIf="
                   !isDataLoading && elementslistData1.length > 5 && tableShow &&
                    (elementslistData1 | mytask : search).length > 0 
                  "  >
                     <pagination-controls
                    (pageChange)="p = $event" class="float-right pagination" style="float:right"
                    *ngIf="(elementslistData1 | mytask : search).length > 5"
         
                    align="end" 
                  ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>
<div *ngIf="list_loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem;align-items: center;margin-left:950px;color: #1A83FF;"
      role="status"
    ></div>
  </div>