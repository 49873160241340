<nav class="navbar  bg" style="margin-top: 67px;">
    <a class="navbar-brand navDesign" style="font-size: 22px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;">My Leave List</a>
    <div class="d-flex">
   
        <button class="btn" type="submit" (click)="apply_leave()" title="Apply Leave"
         style="    background-color: #1a83ff;
        color: whitesmoke;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 610;
        font-family: 'Mulish', sans-serif;
        position: relative;
        /* float: left; */
        right: 10px;">Apply Leave</button>
      </div>
   
  </nav>


<div class="common-content">
    <div class="card-new" style="font-family: Mulish; ">
        <div class="card colDesign" *ngIf="list_counts">
            <div class="card-body">
                <!-- <ng-container *ngIf="loader; else elseLoading" class="loading">
                    <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
                </ng-container> -->
                <!-- <ng-template #elseLoading> -->
                    <div class="--container-fluid">
                        <div class="row mb-2">
                            <div class="col-md-4 col-4">
                                <select [(ngModel)]="filter_Data" class="form-select form-select-solid form-select-lg select2-new">
                                    <option *ngFor="let item of filterData" [value]="item">{{item}}</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-4">
                                <button (click)="get_user_data(filter_Data)" [disabled]="searching" mat-raised-button  class="btn --btn-primary search-btn" style="font-family: Mulish;">Search</button>
                            </div>
                            <!-- <div class="col-md-4 col-4 d-flex justify-content-end align-items-center">
                                <a (click)="apply_leave()" mat-raised-button color="primary" title="Apply Leave" class="ml-2" style="font-family: Mulish;">Apply Leave</a>
                            </div> -->
                        </div>
                        
                    </div>
                    <div class="card resource-card" style="border: 0px solid rgba(0,0,0,.125);padding: 5px 5px;" *ngIf="list_count > 5 && loader == false">
                        <div class="row rowDesign">
                            <div class="col-xl-12 col-lg-12">
                                <div class="select-pagination">
                                    <span id="showDesign">Show : </span>
                                    <select class="select_list new-select_list" id="listDesign" [(ngModel)]="mySelect" (change)="showChanges()">
                                        <option class="form-control" value="5">5</option>
                                        <option class="form-control" value="10">10</option>
                                        <option class="form-control" value="15">15</option>
                                        <option class="form-control" value="20">20</option>
                                        <option class="form-control" value="25">25</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <ng-container>
                        
                            <div class="--example-container --mat-elevation-z8 --main-block --container-fluid table-responsive">
                                <div *ngIf="!loader">
                                    <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count > 0"
                                        class="table  --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px; margin-top: 15px; margin-bottom:0px;">
                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef class="text-center">&nbsp;S.No&nbsp;</th>
                                            <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="startDate">
                                            <th mat-header-cell *matHeaderCellDef>From&nbsp;Date</th>
                                            <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
                                                {{element.startDate | date}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="endDate">
                                            <th mat-header-cell *matHeaderCellDef>To&nbsp;Date</th>
                                            <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
                                                {{element.endDate | date}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="leaveType">
                                            <th mat-header-cell *matHeaderCellDef>Leave&nbsp;Type</th>
                                            <td mat-cell *matCellDef="let element">{{element.leaveType.leaveName}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="noOfLeaveDays">
                                            <th mat-header-cell *matHeaderCellDef class="text-center">No.of&nbsp;Days</th>
                                            <td mat-cell class="text-center" *matCellDef="let element">{{element.noOfLeaveDays}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="reason">
                                            <th mat-header-cell *matHeaderCellDef class="" style="width:20%;padding-left: 1%;">
                                                Leave&nbsp;Reason</th>
                                            <td mat-cell *matCellDef="let element; let i = index">
                                                <span class="reason-span"
                                                    *ngIf="changeasDenyReason==false">{{element.leaveReason}}</span>
        
                                                <span class="reason-span"
                                                    *ngIf="changeasDenyReason==true && clickedbusinessTravelId != i && (isMenuOpen==true || isMenuOpen==false)">{{element.leaveReason}}</span>
        
                                                <span class="reason-span"
                                                    *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==false">{{element.leaveReason}}</span>
        
                                                <span class="denied-span menu" #menu
                                                    *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">Denied
                                                    Reason</span>
                                                <span class="reason-span-deny menu" #menu
                                                    *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">{{element.leavedenyReason}}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="viewapprovers">
                                            <th></th>
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%">View Approvers</th>
                                            <td *matCellDef="let element" style="text-align:center">
                                                <mat-icon class="view-icon"  style="position: relative;top: 8px;"
                                                (click)="viewapproverstatus(element)">visibility</mat-icon>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="leaveStatus">
                                            <th mat-header-cell *matHeaderCellDef  class="text-center" > Status </th>
                                            <td mat-cell *matCellDef="let element; let i = index" style="white-space: nowrap;"
                                            [style.color]="test(element)">
                                            <ng-container *ngIf="element.leaveStatusName">
                                                {{element.leaveStatusName}}
                                                <span *ngIf="element.leaveStatusName =='Approved'"
                                                    class="material-icons-outlined"
                                                    style="font-size: 16px;transform: translate(0px, 4px);">check_circle</span>
                                                <a *ngIf="element.leaveStatusName =='Denied'"
                                                    class="material-icons-outlined" #toggleButton (click)="denyReason(i)"
                                                    style="font-size: 16px;transform: translate(0px, 4px);">error_outline</a>
                                                <a *ngIf="element.leaveStatusName=='Denied'"
                                                    style="font-size: 20px;">*</a>
                                            </ng-container>
                                        </td>
                                        
                                        </ng-container>
        
                                        <ng-container matColumnDef="withdraw">
                                            <th mat-header-cell *matHeaderCellDef class="text-center">Withdraw&nbsp;Status</th>
                                            <td mat-cell class="text-center" *matCellDef="let element"
                                                [style.color]="withDrawStatus(element)">
                                                <ng-container
                                                    *ngIf="element.leaveWithdraw.withdrawStatus == undefined;else condition">{{"--"}}</ng-container>
                                                <ng-template #condition>{{element.leaveWithdraw.withdrawStatus}}</ng-template>
                                            </td>
                                        </ng-container>
        
                                        <ng-container matColumnDef="star">
                                            <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                                            <td mat-cell *matCellDef="let element" class="text-center">
                                                <button *ngIf="!(element.leaveStatus == 8 || element.leaveStatus == 6)"
                                                    mat-icon-button [matMenuTriggerFor]="menu"
                                                    aria-label="Example icon-button with a menu">
                                                    <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                                                </button>
                                                <mat-icon *ngIf="(element.leaveStatus == 6 || element.leaveStatus == 8)"
                                                    style="font-size: 17px;"> -- </mat-icon>
                                                <mat-menu #menu="matMenu" class="custom-style">
                                                    <ng-container *ngIf="element.leaveWithdraw.withdrawStatus == undefined && element.leaveStatusName != 'Cancelled'
                                            && element.leaveStatusName != 'Denied' && element.leaveStatusName != 'Pending'">
                                                        <a mat-menu-item (click)="openWithdrawDialog(element)">
                                                            <mat-icon class="cancel-trip-icon material-icons-outlined"
                                                                style="color: brown;">compare_arrows</mat-icon>
                                                            <span class="cancel-trip-text">Withdraw</span>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="element.leaveStatusName == 'Pending'">
                                                        <a mat-menu-item (click)="cancel(element)">
                                                            <mat-icon class="cancel-trip-icon material-icons-outlined">clear</mat-icon>
                                                            <span class="cancel-trip-text">Cancel</span>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="element.leaveStatusName == 'Withdrawn'">
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="element.leaveWithdraw.withdrawStatus != undefined && element.leaveWithdraw.withdrawStatus != null">
                                                        <a mat-menu-item (click)="showWithdrawDetails(element)">
                                                            <mat-icon class="cancel-trip-icon material-icons-outlined"
                                                                style="color:royalblue;">details</mat-icon>
                                                            <span class="cancel-trip-text">Show Withdraw Details</span>
                                                        </a>
                                                    </ng-container>
                                                    <a *ngIf="element.leaveWithdraw.leavewithdrawdenyReason != undefined && element.leaveWithdraw.leavewithdrawdenyReason != null"
                                                        mat-menu-item
                                                        (click)="showdenyreason(element.leaveWithdraw.leavewithdrawdenyReason)">
                                                        <mat-icon
                                                            class="cancel-trip-icon material-icons-outlined">do_not_disturb</mat-icon>
                                                        <span class="cancel-trip-text">Deny Reason</span>
                                                    </a>
                                                </mat-menu>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                          
                           

                            <div class="--container" *ngIf="noData && !loader">
                                <div class="alert alert-primary" style="width: 100%;">
                                    No Leave List

                                </div>
                                
                            </div>
                           

                        </div>

                        
                    </ng-container> -->

                    <div class="--container-fluid" style="overflow: auto;">
                        <div *ngIf="!loader" class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px; margin-top: 15px;">
                            <table class="table --table-bordered table-border-new" matSort style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                <thead class="t-head" style="background-color: #f6f9fc;">
                                    <tr>
                                        <th class="text-center">S.No</th>
                                        <th class="text-left">From Date</th>
                                        <th class="text-left">To Date</th>
                                        <th class="text-left">Leave Type</th>
                                        <th class="text-center">No.of Days</th>
                                        <th class="text-left" style="width:20%;">Leave Reason</th>
                                        <th class="text-center">View Approvers</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Withdraw Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="t-body">
                                    <tr *ngFor="let element of data | paginate: { itemsPerPage: mySelect, currentPage: p }; let i = index">
                                        <td class="text-center">{{ (p - 1) * mySelect + i + 1 }}</td>
                                        <td class="text-left">{{element.startDate |  date:'dd/MM/yyyy'}}</td>
                                        <td class="text-left">{{element.endDate |  date:'dd/MM/yyyy'}}</td>
                                        <td class="text-left">{{element.leaveType.leaveName}}</td>
                                        <td class="text-center">{{element.noOfLeaveDays}}</td>
                                        <td class="text-left">
                                            <span class="reason-span" style="white-space: normal;padding:3%" *ngIf="changeasDenyReason == false">{{element.leaveReason}}</span>
                                            <span class="reason-span" style="white-space: normal;padding:3%" *ngIf="changeasDenyReason == true && clickedbusinessTravelId != i && (isMenuOpen == true || isMenuOpen == false)">{{element.leaveReason}}</span>
                                            <span class="reason-span" style="white-space: normal;padding:3%" *ngIf="changeasDenyReason == true && clickedbusinessTravelId == i && isMenuOpen == false">{{element.leaveReason}}</span>
                                            <span class="denied-span menu" #menu *ngIf="changeasDenyReason == true && clickedbusinessTravelId == i && isMenuOpen == true">Denied Reason</span>
                                            <span class="reason-span-deny menu" #menu *ngIf="changeasDenyReason == true && clickedbusinessTravelId == i && isMenuOpen == true">{{element.leavedenyReason}}</span>
                                        </td>
                                        <td class="text-center">
                                            <mat-icon class="view-icon" style="position: relative; top: 8px;" (click)="viewapproverstatus(element)">visibility</mat-icon>
                                        </td>
                                        <td class="text-center" [style.color]="test(element)">
                                            <ng-container *ngIf="element.leaveStatusName">
                                                {{element.leaveStatusName}}
                                                <span *ngIf="element.leaveStatusName == 'Approved'" class="material-icons-outlined" style="font-size: 16px; transform: translate(0px, 4px);">check_circle</span>
                                                <a *ngIf="element.leaveStatusName == 'Denied'" class="material-icons-outlined" #toggleButton (click)="denyReason(i)" style="font-size: 16px; transform: translate(0px, 4px);">error_outline</a>
                                                <a *ngIf="element.leaveStatusName == 'Denied'" style="font-size: 20px;">*</a>
                                            </ng-container>
                                        </td>
                                        <td class="text-center" [style.color]="withDrawStatus(element)">
                                            <ng-container *ngIf="element.leaveWithdraw.withdrawStatus == undefined; else condition">--</ng-container>
                                            <ng-template #condition>{{element.leaveWithdraw.withdrawStatus}}</ng-template>
                                        </td>
                                        <td class="text-center">
                                            <button *ngIf="!(element.leaveStatus == 8 || element.leaveStatus == 6)" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                                            </button>
                                            <mat-icon *ngIf="(element.leaveStatus == 6 || element.leaveStatus == 8)" style="font-size: 17px;"> -- </mat-icon>
                                            <mat-menu #menu="matMenu" class="custom-style">
                                                <ng-container *ngIf="element.leaveWithdraw.withdrawStatus == undefined && element.leaveStatusName != 'Cancelled' && element.leaveStatusName != 'Denied' && element.leaveStatusName != 'Pending'">
                                                    <a mat-menu-item (click)="openWithdrawDialog(element)">
                                                        <mat-icon class="cancel-trip-icon material-icons-outlined" style="color: brown;">compare_arrows</mat-icon>
                                                        <span class="cancel-trip-text">Withdraw</span>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="element.leaveStatusName == 'Pending'">
                                                    <a mat-menu-item (click)="cancel(element)">
                                                        <mat-icon class="cancel-trip-icon material-icons-outlined">clear</mat-icon>
                                                        <span class="cancel-trip-text">Cancel</span>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="element.leaveStatusName == 'Withdrawn'"></ng-container>
                                                <ng-container *ngIf="element.leaveWithdraw.withdrawStatus != undefined && element.leaveWithdraw.withdrawStatus != null">
                                                    <a mat-menu-item (click)="showWithdrawDetails(element)">
                                                        <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:royalblue;">details</mat-icon>
                                                        <span class="cancel-trip-text">Show Withdraw Details</span>
                                                    </a>
                                                </ng-container>
                                                <a *ngIf="element.leaveWithdraw.leavewithdrawdenyReason != undefined && element.leaveWithdraw.leavewithdrawdenyReason != null" mat-menu-item (click)="showdenyreason(element.leaveWithdraw.leavewithdrawdenyReason)">
                                                    <mat-icon class="cancel-trip-icon material-icons-outlined" style="color: red;">do_not_disturb</mat-icon>
                                                    <span class="cancel-trip-text" >Deny Reason</span>
                                                </a>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                        style="
                                        text-align: center;
                                        font-size: larger;
                                        font-weight: bold;"
                                      colspan="10"
                                      *ngIf="noData ">
                                            No Leave list</td>
                                    </tr>

                                    <tr>
                                        <td
                                        style="
                                        text-align: center;
                                        font-size: larger;
                                        font-weight: bold;"
                                      colspan="10"
                                      *ngIf=" errorMessage">
                                      {{ errorMessage }}  </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="list_count > 5  && loader == false">
                            <pagination-controls class="float-right pagination" style="float: right;" (pageChange)="p = $event; beforepage = $event"></pagination-controls>
                        </div>
                        <!-- <div *ngIf="noData">
                            <div class="alert alert-primary" style="width: 100%; text-align: center;">No Leave List</div>
                        </div> -->
                    </div>

                    <!-- <div class="--container">
                        <div *ngIf="noData && errorMessage" class="alert alert-primary" style="width: 100%;">
                            {{ errorMessage }}
                        </div>
                    </div> -->
                    

                     <!-- <div *ngIf="errorMessage " class="alert alert-primary">{{ errorMessage }}</div> -->

                <!-- </ng-template> -->
                <!-- <div [hidden]="loader" >
                    <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]"
                        showFirstLastButtons>
                    </mat-paginator>
                </div> -->

            </div>
        </div>
    </div>
</div>
<div *ngIf="loader" class="d-flex align-items-center justify-content-center" style="margin-top: 15%;" >
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;left: 48%;top: 50%;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{success_msg}}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
  </div>

  

  