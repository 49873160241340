<form [formGroup]="postForm">
  <h3 mat-dialog-title class="headerDesign">Assign Employee Project</h3>
  <!-- <div class="row">
        <div class="form-group col-md-10">
             <label class="col-form-label">Client<span class="star">*</span></label>
             <select formControlName="client" class="form-select form-select-solid form-select-lg select2-new" >
                <option [ngValue]="null"> -- Select Client --- </option>
                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
            </select>
             <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                 <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
             </div>
         </div>
     </div> -->
  <div class="row">
    <div class="col-md-10">
      <label class="file-label">
        <div class="row">
          <div class="col-md-1">
            <!-- <mat-icon class="material-symbols-outlined">
                        attach_file_add
                    </mat-icon> -->
          </div>
          <!-- <div class="col-md-10">
                    <span>{{  fileInputLabel ||  'Attach File' }}</span>
                </div> -->
        </div>

        <input
          type="file"
          formControlName="file"
          placeholder="Enter description"
          [ngClass]="{ 'is-invalid': submitted }"
          class="file-input"
          (change)="onFileSelect($event)"
        />
      </label>
      <!-- <div class="valid-msg" *ngIf="(validate.file.invalid && validate.file.touched) || validate.file.dirty || submitted">
                <span *ngIf="validate.file.errors?.required" class="text-danger">Please select .xls / xlsx file</span>
                <span *ngIf="validate.file.errors?.pattern" class="text-danger">Please enter valid .xls / xlsx file</span>
            </div> -->
      <div *ngIf="(f.file.invalid && submitted) || f.file.dirty">
        <span *ngIf="f.file.errors?.required" class="text-danger"
          >Please select .xls / xlsx file</span
        >
      </div>
      <div *ngIf="file.invalid || file.dirty">
        <span *ngIf="file.errors?.pattern" class="text-danger"
          >Please enter valid .xls / xlsx file</span
        >
      </div>
      <div>
        <span style="margin-bottom: 10px" class="text-muted import-note"
          >Note : Upload format is .xls / xlsx.</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <span class="text-muted">
        *** Please download the sample file and import the data as mentioned
        format. ***
      </span>
      <a
        class="download-sample text-danger"
        href="assets/backend/sample_documents/Project Employee import.xlsx"
        download="Project_Employe_Import.xlsx"
      >
        Download Sample
      </a>
    </div>
  </div>
</form>

<mat-dialog-actions align="end">
  <button
    type="button"
    mat-dialog-close
    color=""
    (click)="onCancel()"
    style="
      margin-right: 6px;
      border: 2px solid blank;
      height: 30px;
      border-radius: 8%;
      /* background: #dddd; */
      font-size: 16px;
      font-weight: 500;
      color: var(--Red-Color, #ff5d5d);
      /* height: 35px; */
      border: 1px solid rgba(117, 109, 109, 0.4392156863) !important;
      box-shadow: none;
      transition: none;
      width: 66px;
      background: none;
      font-family: 'Mulish', sans-serif;

    "
      [disabled]="isbtnDisabled"

  >
    Cancel
  </button>

  <!--<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button>
-->
  <button type="submit" mat-raised-button (click)="onSubmit()" 
  [disabled]="isbtnDisabled || !buttonDisable"
  class="searchBtn"
  >
    Upload
  </button>
</mat-dialog-actions>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem;
    height: 3rem;
    position: absolute;
    z-index: 1;
    top: 37%;
    right: 47%;"
    role="status"
  ></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_Msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>
