import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../../providers/properties';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss']
})
export class AddBranchComponent implements OnInit {
  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any;
  branchId: any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  employeetData:any;
isbtnDisable: boolean=false;
businessId: any;


  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private ActivatedRoute:ActivatedRoute,
    ) { }

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      //mobileNumber : ['' ,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      //email : ['' ,[Validators.required,Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/)]],
      //branchCode : ['',[Validators.required ,  Validators.min(8), Validators.max(10)]],
      branchName : ['',[Validators.required , Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      branchCode : ['',[Validators.required,Validators.pattern(/^[A-Za-z0-9_-]*$/)]],
      branchDescription : ['',[ Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      // phoneNumber : ['',[Validators.required,Validators.pattern(/^[1-9]\d{0,10}$/)]],
      // phoneNumber : new FormControl(this.postForm,[Validators.required,Validators.pattern(/^[1-9]\d{0,10}$/)]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[6-9]\d{9}$/), // Adjust pattern to require exactly 10 digits
        Validators.minLength(10),
        Validators.maxLength(10),
       this.noSpecialCharactersValidator()
      ]),
      // alternativePhoneNumber : [null , [Validators.pattern(/^[1-9]\d{0,10}$/)]],
      alternativePhoneNumber : [null,[Validators.pattern(/^[6-9]\d{9}$/),Validators.minLength(10),this.noSpecialCharactersValidator(),Validators.maxLength(10)]],
      branchAddress : [null,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")],
      // branchEmail : [''],
      // branchEmail: [null, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]
      branchEmail: [null,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      // branchEmail: [null,[Validators.pattern('^[^\\s][a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{1,4}[^\\s]$') ]]
     });
     //console.log(this.ActivatedRoute.snapshot.params.branchId);


     this.branchId = this.ActivatedRoute.snapshot.params.branchId;

     console.log("  this.branchId::",  this.branchId);
     
     this.businessId=this.branchId;

     console.log("this.businessId::",this.businessId);
     

     if(this.branchId)
    {
      this.loader=true;
      this.settingsservice.getBranchById(this.branchId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;
        this.loader=false

        this.postForm.patchValue({
          branchCode: this.resultData.branchCode,
          branchName: this.resultData.branchName,
          branchDescription: this.resultData.branchDescription,
          phoneNumber: this.resultData.phoneNumber,
          alternativePhoneNumber: this.resultData.alternativePhoneNumber,
          branchAddress: this.resultData.branchAddress,
          branchEmail: this.resultData.branchEmail,
        });
      });
    }
  }
  get validate() { return this.postForm.controls; }
  noSpecialCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = /[!@#$%^&*(),.?":{}|<>]/.test(control.value);
      return forbidden ? { specialCharacters: true } : null;
    };
  }
  

  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    // if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
    //   event.preventDefault();
    // }
  
    // // Trim spaces, commas, and backticks at the start
    // while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
    //   value = value.trimStart().replace(/^,|^`/, '');
    //   textarea.value = value;
    // }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }


  // onSubmit()
  // {
  //   // this.isbtnDisable=false;

  //   this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

  //   let organizationId = this.employeetData.organization.organizationId;
  //   console.log('organizationId'+organizationId)

  //   let clientId = this.employeetData.employee.client.clientId;
  //   console.log('cliendId',clientId)
  //   this.submitted = true;

  //   console.log("form group::",this.postForm);
    

  //   if (this.postForm.invalid) {
  //     // this.isbtnDisable=true

  //       return;
  //   }
  //   if (this.postForm.value.alternativePhoneNumber === '') {
  //     this.postForm.value.alternativePhoneNumber = null;
  //   }
  //   if(this.branchId) //Update Branch
  //   {
  //     let postValues = this.postForm.value;

  //     postValues['organization'] = {organizationId: organizationId}
  //     postValues['branchId'] = this.branchId;
  //     postValues['branchStatus'] = this.resultData.branchStatus;
  //     this.isbtnDisable=true;

  //     this.settingsservice.editBranch(this.branchId,postValues).subscribe((result: any) =>
  //     {
  //       this.isbtnDisable=true;

  //       if(result.statusCode == 200)
  //       {
  //         this.success = true;
  //         this.isbtnDisable=true

  //         this.sucess_msg = result.message;
  //         //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
  //         //this.router.navigate(['/home/employee']);

  //         setTimeout(() => {
  //           this.router.navigate(['/home/branch']);
  //         }, 4000)
  //       }
  //       else
  //       {
  //         this.error = true;
  //         this.isbtnDisable=true;

  //         this.error_msg = result.message;
  //         //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

  //         setTimeout(() => {
  //           this.router.navigate(['/home/branch']);
  //         }, 4000)
  //       }

  //       //this.router.navigate([ '/home/branch' ]);
  //     }, err =>{

  //       this.errorMsg = err.error.message;

  //     })
  //   }
  //   else  //Add Branch
  //   {
  //     this.isbtnDisable=true;

  //       let postValues = this.postForm.value;
  //       postValues['organization'] = {organizationId: organizationId}

  //     this. settingsservice.createBranch(postValues).subscribe((result: any) =>
  //     {
  //       this.isbtnDisable=true

  //       if(result.statusCode == 200)
  //       {
  //         this.success = true;
  //         this.isbtnDisable=true

  //         this.sucess_msg = result.message;
  //         //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
  //         //this.router.navigate(['/home/employee']);

  //         setTimeout(() => {
  //           this.router.navigate(['/home/branch']);
  //         }, 4000)
  //       }
  //       else
  //       {
  //         this.error = true;
  //         this.isbtnDisable=true

  //         this.error_msg = result.message;
  //         //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

  //         setTimeout(() => {
  //           this.router.navigate(['/home/branch']);
  //         }, 4000)
  //       }

  //     }, err =>
  //     {
  //       this.isbtnDisable=true

  //         this.errorMsg = err.error.message;

  //     })
  //   }


  // }

  onSubmit() {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization?.organizationId;
    console.log('organizationId:', organizationId);
  
    let clientId = this.employeetData.employee?.client?.clientId;
    console.log('clientId:', clientId);
  
    this.submitted = true;
    console.log("form group::", this.postForm);
  
    if (this.postForm.invalid) {
      return;
    }
  
    if (this.postForm.value.alternativePhoneNumber === '') {
      this.postForm.value.alternativePhoneNumber = null;
    }
  
    // Determine entity data based on availability of organizationId or clientId
    const entityData = organizationId
      ? { organization: { organizationId: organizationId } }
      : { client: { clientId: clientId } };
  
    if (this.branchId) { // Update Branch
      let postValues = this.postForm.value;
  
      postValues = {
        ...postValues,
        ...entityData, // Add organization or client dynamically
        branchId: this.branchId,
        branchStatus: this.resultData.branchStatus
      };
  
      this.isbtnDisable = true;
  
      this.settingsservice.editBranch(this.branchId, postValues).subscribe((result: any) => {
        this.isbtnDisable = true;
  
        if (result.statusCode === 200) {
          this.success = true;
          this.isbtnDisable = true;
  
          this.sucess_msg = result.message;
  
          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, 4000);
        } else {
          this.error = true;
          this.isbtnDisable = true;
  
          this.error_msg = result.message;
  
          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, 4000);
        }
      }, err => {
        this.errorMsg = err.error.message;
      });
  
    } else { // Add Branch
      let postValues = this.postForm.value;
  
      postValues = {
        ...postValues,
        ...entityData // Add organization or client dynamically
      };
  
      this.isbtnDisable = true;
  
      this.settingsservice.createBranch(postValues).subscribe((result: any) => {
        this.isbtnDisable = true;
  
        if (result.statusCode === 200) {
          this.success = true;
          this.isbtnDisable = true;
  
          this.sucess_msg = result.message;
  
          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, 4000);
        } else {
          this.error = true;
          this.isbtnDisable = true;
  
          this.error_msg = result.message;
  
          setTimeout(() => {
            this.router.navigate(['/home/branch']);
          }, 4000);
        }
      }, err => {
        this.isbtnDisable = true;
        this.errorMsg = err.error.message;
      });
    }
  }
  

  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  
  noNumber(event: any) {
    // const inputValue = event.target.value;
  
  
    const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
  }

}
