<div style="margin-top: 70px" class="card card-content">
  <h4 class="heading-tag">Organization / Client</h4>
  <div class="row justify-content-between">
    <div class="col-md-4">
      <div id="loginform">
        <div class="col-md-12 col-xl-6 select">
          <label class="form-control-label"
            >Select&nbsp;Organization&nbsp;/&nbsp;Client</label
          >
          <mat-select
            (selectionChange)="selected($event)"
            class="form-control email-input assign-placeholder"
            placeholder="Select Organization/Client "
          >
            <mat-option
              [value]="undefined"
              selected="selected"
              (click)="clearAllForm()"
              >-Select Organization/Client-</mat-option
            >
            <mat-option value="organization">Organization</mat-option>
            <mat-option value="client">Client</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedType === 'organization'" class="mt-3">
    <app-organization></app-organization>
  </div>

  <!-- <div class="row mb-2">
        <div class="col-md-12">
          <form [formGroup]="searchFormOrg" (ngSubmit)="searchSubmit()">
    
                <div class="sub-header-buttons">
                  <mat-button-toggle-group
                    name="fontStyle"
                    class="table-view-icons active-list"
                    aria-label="Font Style"
                  >
                    <mat-button-toggle
                      (click)="tableView(1)"
                      [ngClass]="{ 'active-list-btn': pageViews === 1 }"
                      title="Grid View"
                      class="grid-icon"
                    >
                      <mat-icon>grid_on</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                      (click)="tableView(2)"
                      [ngClass]="{ 'active-list-btn': pageViews === 2 }"
                      title="List View"
                      class="list-icon"
                    >
                      <mat-icon>list</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                      (click)="tableView(3)"
                      [ngClass]="{ 'active-list-btn': pageViews === 3 }"
                      title="Card View"
                      class="view-icon"
                    >
                      <mat-icon>view_module</mat-icon>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
        
                  <button
                    *ngIf="resultData?.length > 0"
                    type="button"
                    (click)="exportToExcel()"
                    mat-raised-button
                    class="btn download-button ml-2 btndesign"
                  >
                    Export
                  </button>
        
                 
                  <button
                    (click)="Add_org()"
                    mat-raised-button
                    class="ml-2 btndesign"
                  >
                    Add Organization
                  </button>
                  </div>
      
           
            <div class="row mb-3 pl-0">
              <div class="ol-xl-3 col-lg-3 mb-2">
                <label class="form-control-label"
                  >Organization Name<span class="text-danger"> *</span>
                </label>
                <input
                  formControlName="keywords"
                  type="text"
                  autocomplete="off"
                  class="form-control email-input select-placeholder"
                  value=""
                  placeholder="Organization Name"
                />

              </div>
              <div class="ol-xl-3 col-lg-3 mb-2">
                <label class="form-control-label"
                  >Business Unit Name<span class="text-danger"> *</span>
                </label>
                <input
                  formControlName="businessUnitname"
                  type="text"
                  autocomplete="off"
                  class="form-control email-input select-placeholder"
                  value=""
                  placeholder="Business Unit Name"
                />
              </div>

              <div class="col-xl-3 col-lg-3 mb-2">
                <label class="form-control-label"
                  >Industry Type<span class="text-danger"> *</span>
                </label>
             
                <mat-select
                  formControlName="industryType"
                  placeholder="Select Industry Type"
                  class="form-control email-input select-placeholder"
                >
                  <mat-option [value]="undefined" selected="selected"
                    >-Select Industry Type-</mat-option
                  >
                  <mat-option
                    *ngFor="let row of industryTypeLov; let i = index"
                    value="{{ row.listTypeValueId }}"
                    >{{ row.listTypeValueName }}
                  </mat-option>
              
                </mat-select>
              </div>

              
              <div class="ol-xl-3 col-lg-3 mb-3" style="margin-top: 22px">
                <button
                  type="reset"
                  mat-raised-button
                  class="mr-2 clearDesign"
                  (click)="clearData()"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  mat-raised-button
                  class="btndesign searchDesign"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
       
          <div class="float-end" *ngIf="tableShow">
            <div class="row rowDesignshow" *ngIf="resultData?.length > 0">
              <div
                class="select-pagination"
                style="
                  margin-top: 85px;
                  margin-left: 15px;
                  margin-bottom: 10px;
                "
                *ngIf="resultData?.length > 5"
              >
                <span class="showPage showDesign">Show</span>
                <select
                  class="select_list new-select_list listDesign"
                  [(ngModel)]="mySelect"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="changefirstpage()"
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="grid_view" *ngIf="pageViews == 1">

        <div class="table">
          <table
            matSort
            (matSortChange)="sortData($event)"
            *ngIf="tableShow"
            class="table"
          >
            <thead class="t-head">
              <tr id="trDesign">
   
                <th
                  class="text-center"
                  style="border-radius: 15px 0px 0px 0px"
                >
                  Action
                </th>
                <th mat-sort-header="organizationName" class="text-center">
                  Organization Name
                </th>
                <th mat-sort-header="industryType" class="text-center">
                  Industry Type
                </th>
                <th mat-sort-header="contactPerson" class="text-center">
                  Contact Person
                </th>
                <th mat-sort-header="contactPhoneNumber" class="text-center">
                  Contact Phone Number
                </th>
                <th mat-sort-header="contactEmail" class="text-center">
                  Contact Email
                </th>
                <th mat-sort-header="postalCode" class="text-center">
                  Postal Code
                </th>
                <th mat-sort-header="address" class="text-center">Address</th>
                <th class="text-center" style="border-radius: 0px 15px 0px 0">
                  Status
                </th>
              </tr>
            </thead>
            <tbody
              style="
                border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;
              "
            >
              <tr
                *ngFor="
                  let row of resultData
                    | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                    | searchFilter : tableSearch;
                  let i = index
                "
              >
     
                <td class="text-center" style="margin-left: -24px">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" class="controls-list">
                    <button
                      mat-menu-item
                      routerLink="/home/edit-organization/{{
                        row.organizationId
                      }}"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>

                    <button
                      mat-menu-item
                      routerLink="/home/view-organization/{{
                        row.organizationId
                      }}"
                      routerLinkActive="active"
                    >
                      <mat-icon>visibility</mat-icon>
                      <span>View</span>
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="row.organizationStatus.listTypeValueId == 1"
                      (click)="updateStatus(row.organizationId, 2)"
                    >
                      <mat-icon>done</mat-icon>
                      <span style="color: red">Inactive</span>
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="row.organizationStatus.listTypeValueId == 2"
                      (click)="updateStatus(row.organizationId, 1)"
                    >
                      <mat-icon>done</mat-icon>
                      <span style="color: #50cd89 !important">Active</span>
                    </button>
                  </mat-menu>
                </td>

                <td class="text-center">
                  {{ ucFirst(row.organizationName) }}
                </td>
                <td class="text-center">
                  {{
                    row.industryType ? row.industryType.listTypeValueName : ""
                  }}
                </td>

                <td class="text-center">{{ row.contactPerson }}</td>
                <td class="text-center">
                  {{ row.contactPhoneNumber }}
                </td>
                <td class="text-center">{{ row.contactEmail }}</td>
             
                <td class="text-center">
                  {{ row.postalCode ? row.postalCode : "-" }}
                </td>
        
                <td class="text-center">
                  {{ row.address ? row.address : "-" }}
                </td>

                <td class="active-status text-center">
                  <span
                    class="btn btn-sm btn-block"
                    style="color: #50cd89 !important"
                    *ngIf="row.organizationStatus.listTypeValueId == 1"
                  >
                    {{ row.organizationStatus.listTypeValueName }}
                  </span>

                  <span
                    class="btn btn-sm btn-block"
                    style="color: red"
                    *ngIf="row.organizationStatus.listTypeValueId == 2"
                  >
                    {{ row.organizationStatus.listTypeValueName }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody
              style="
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
              "
            >
              <tr>
                <td
                  style="
                    text-align: center;
                    padding: 10px;
                    font-size: 19px;
                    font-weight: 600;
                    font-family: 'Mulish', sans-serif;
                    left: 5;
                    position: relative;
                  "
                  colspan="8"
                  *ngIf="nodatafound || resultData.length == 0"
                >
                  Organization Records Not Found!
                </td>
              </tr>
            </tbody>
          </table>

       
        </div>

      </div>
      <div class="list_view" *ngIf="pageViews == 2">
        <div *ngIf="resultData?.length > 0">
          <div class="row mb-3">
            <div
              class="col-md-12 mb-3"
              *ngFor="
                let row of resultData
                  | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                  | searchFilter : tableSearch;
                let i = index
              "
            >
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <button
                      class="bars"
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
               
                    <div class="col-md-12 user-basics">
                      <span class="user-name-list">
                        {{ ucFirst(row.organizationName) }}<br />
                        <p class="user-id-list">
                          {{
                            row.industryType
                              ? row.industryType.listTypeValueName
                              : ""
                          }}
                        </p>
                      </span>
                      <p class="user-role-list m-0 mb-3">
            
                      </p>
                 
                      <span
                        *ngIf="row.contactPerson"
                        class="list-emp-date pr-2"
                        ><mat-icon>email</mat-icon>
                        {{ row.contactPerson }}</span
                      >
                      <span
                        *ngIf="row.contactEmail"
                        class="list-emp-date pr-2"
                        ><mat-icon>supervised_user_circle</mat-icon>
                        {{ row.contactEmail }}</span
                      >
                      <span
                        *ngIf="row.contactPhoneNumber"
                        class="list-emp-date pr-2"
                        ><mat-icon>phone</mat-icon>
                        {{ row.contactPhoneNumber }}</span
                      >

                      <p class="use-active-status">
                        <span
                          class="text-success font-weight-bold btn-sm"
                          *ngIf="row.organizationStatus.listTypeValueId == 1"
                        >
                          {{ row.organizationStatus.listTypeValueName }}
                        </span>

                        <span
                          class="text-danger font-weight-bold btn-sm"
                          *ngIf="row.organizationStatus.listTypeValueId == 2"
                        >
                          {{ row.organizationStatus.listTypeValueName }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <mat-menu #menu="matMenu" class="controls-list">
                    <button
                      mat-menu-item
                      routerLink="/super-admin-home/edit-organization/{{
                        row.organizationId
                      }}"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>

                    <button
                      mat-menu-item
                      routerLink="/super-admin-home/view-organization/{{
                        row.organizationId
                      }}"
                      routerLinkActive="active"
                    >
                      <mat-icon>visibility</mat-icon>
                      <span>View</span>
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="row.organizationStatus.listTypeValueId == 1"
                      (click)="updateStatus(row.organizationId, 2)"
                    >
                      <mat-icon>done</mat-icon>
                      <span>Inactive</span>
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="row.organizationStatus.listTypeValueId == 2"
                      (click)="updateStatus(row.organizationId, 1)"
                    >
                      <mat-icon>done</mat-icon>
                      <span>Active</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
      <div class="card_view" *ngIf="pageViews == 3">
        <div *ngIf="resultData?.length > 0">
          <div class="row mb-3">
            <div
              class="col-md-4 mb-3"
              *ngFor="
                let row of resultData
                  | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                  | searchFilter : tableSearch;
                let i = index
              "
            >
              <div class="card org-cards">
                <div class="card-body">
                  <div class="row">
                    <button
                      class="bars"
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
               
                    <div class="col-md-12 user-basics">
                      <span class="user-name-list">
                        {{ ucFirst(row.organizationName) }}
                        <p class="user-id-list">
                          {{
                            row.industryType
                              ? row.industryType.listTypeValueName
                              : ""
                          }}
                        </p>
                      </span>

                      <p class="user-role-list m-0 mb-3">
            
                      </p>
                
                      <span
                        *ngIf="row.contactPerson"
                        class="list-emp-date pr-2"
                        ><mat-icon>supervised_user_circle</mat-icon>
                        {{ row.contactPerson }}</span
                      >
                      <span
                        *ngIf="row.contactEmail"
                        class="list-emp-date pr-2"
                        ><mat-icon>email</mat-icon>
                        {{ row.contactEmail }}</span
                      >
                      <span
                        *ngIf="row.contactPhoneNumber"
                        class="list-emp-date pr-2"
                        ><mat-icon>phone</mat-icon>
                        {{ row.contactPhoneNumber }}</span
                      >

                      <p class="use-active-status">
                        <span
                          class="text-success font-weight-bold btn-sm"
                          *ngIf="row.organizationStatus.listTypeValueId == 1"
                        >
                          {{ row.organizationStatus.listTypeValueName }}
                        </span>

                        <span
                          class="text-danger font-weight-bold btn-sm"
                          *ngIf="row.organizationStatus.listTypeValueId == 2"
                        >
                          {{ row.organizationStatus.listTypeValueName }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <mat-menu #menu="matMenu" class="controls-list">
                    <button
                      mat-menu-item
                      routerLink="/super-admin-home/edit-organization/{{
                        row.organizationId
                      }}"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>

                    <button
                      mat-menu-item
                      routerLink="/super-admin-home/view-organization/{{
                        row.organizationId
                      }}"
                      routerLinkActive="active"
                    >
                      <mat-icon>visibility</mat-icon>
                      <span>View</span>
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="row.organizationStatus.listTypeValueId == 1"
                      (click)="updateStatus(row.organizationId, 2)"
                    >
                      <mat-icon>done</mat-icon>
                      <span>Inactive</span>
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="row.organizationStatus.listTypeValueId == 2"
                      (click)="updateStatus(row.organizationId, 1)"
                    >
                      <mat-icon>done</mat-icon>
                      <span>Active</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
      <div
      *ngIf="
        resultData?.length > 5 &&
        (resultData | mytask : search)?.length > 0 &&
        tableShow
      "
    >
      <pagination-controls
        (pageChange)="p = $event"
        class="float-right pagination"
        style="float: right"
        *ngIf="(resultData | mytask : search).length > 5"
        align="end"
      ></pagination-controls>
    </div> -->
  <!-- </div> -->

  <!-- Client Search -->
  <div *ngIf="selectedType === 'client'" class="mt-3">
    <div class="row mb-2">
      <div class="col-md-12">
        <form [formGroup]="clientFormgroup" (ngSubmit)="clientSearch()">
          <div class="sub-header-buttons">
            <button
              (click)="Add_clients()"
              mat-raised-button
              class="ml-2 btndesign"
            >
              Add Client
            </button>
          </div>

          <div class="row mb-3 pl-0">
            <div class="ol-xl-3 col-lg-3 mb-2">
              <label class="form-control-label">Client Name <span class="star">*</span></label>
              <input
                formControlName="clientKeywords"
                type="text"
                
                autocomplete="off"
                class="form-control email-input select-placeholder"
                value=""
                (keyup)="this.showValidation = false;"
                (keydown)="handleBackspace($event)"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"

                placeholder="Client Name"
              />
              <div *ngIf="(clientKeywordset?.touched && clientKeywordset?.invalid) || showValidation" class="text-danger">
                <div *ngIf="clientKeywordset?.errors?.['required']">
                  Client Name is required.
                </div>
                <div *ngIf="clientKeywordset?.errors?.['pattern']">
                  Invalid format for Client Name
                </div>
              </div>
              <!-- <small class="text-muted smalldesign">Note : Organization Name</small> -->
            </div>
            <div class="ol-xl-3 col-lg-3 mb-2">
              <label class="form-control-label">Business Unit Name </label>
              <input
                formControlName="businessUnitnames"
                type="text"
                (keyup)="validateInputSpace($event)"
                (keydown)="handleBackspace($event)"
                (keydown)="validateInputSpace($event)"
                autocomplete="off"
          
                class="form-control email-input select-placeholder"
                value=""
              
                placeholder="Business Unit Name"
              />
              <!-- <div *ngIf="(businessUnitname?.touched && businessUnitname?.invalid) || showValidation" class="text-danger">
                <div *ngIf="businessUnitname?.errors?.['required'] || showValidation">
                  Business Unit Name is required.
                </div>
                <div *ngIf="clientKeywordset?.errors?.['pattern']">
                  Invalid format for Business Unit Name
                </div>
              </div> -->
            </div>

            <!-- <div class="col-xl-3 col-lg-3 mb-2">
                <label class="form-control-label"
                  >Supplier Name<span class="text-danger"> *</span>
                </label>
                <input
                  formControlName="supplierName"
                  type="text"
                  autocomplete="off"
                  class="form-control email-input select-placeholder"
                  value=""
                  placeholder="Supplier"
                />
               
              </div> -->

            <div class="ol-xl-3 col-lg-3 mb-3" style="margin-top: 22px">
              <button
              type="submit"
              mat-raised-button
              [disabled]="btnDisabled"
              class="btndesign searchDesign"
            >
              Search
            </button>
              
              <button
                type="reset"
                mat-raised-button
                [disabled]="btnDisabled"
                class="mr-2 clearDesign"
                (click)="clearData()"
              >
                Clear
              </button>
             
            </div>
          </div>
        </form>

        <div class="float-end" *ngIf="tableShow">
          <div class="row rowDesignshow" *ngIf="clientList?.length > 0">
            <div
              class="select-pagination"
              style="margin-bottom: 10px; position: relative; right: 24px"
              *ngIf="clientList?.length > 5"
            >
              <span class="showPage showDesign shwdesign">Show:</span>
              <select
                class="select_list new-select_list listDesign"
                [(ngModel)]="mySelect"
                (change)="changefirstpage()"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table" style="padding: 17px; margin-top: -27px">
      <table
        matSort
        *ngIf="tableShow"
        class="table"
      >
        <thead class="t-head">
          <tr id="trDesign">
            <!-- <th class="text-center">S.No</th> -->
            <th class="text-center" style="border-radius: 15px 0px 0px 0px">
              Action
            </th>
            <th class="text-center">Client Code</th>
            <th class="text-center">Client Name</th>
            <th class="text-center">Business Unit Name</th>
            <!-- <th mat-sort-header="entityName" class="text-center">
                 Entity Name
              </th> -->

            <th class="text-center">Address</th>
            <th class="text-center" style="border-radius: 0px 15px 0px 0">
              Status
            </th>
          </tr>
        </thead>
        <tbody
          style="
            border-left: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
          "
        >
          <tr
            *ngFor="
              let row of clientList
                | paginate : { itemsPerPage: mySelect, currentPage: p };
              let i = index
            "
          >
            <!-- <td class="text-center">{{i+1}}</td> -->
            <td class="text-center" style="margin-left: -24px">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu" class="controls-list">
                <button
                  mat-menu-item
                  routerLink="/home/edit-clients/{{ row.clientId }}"
                >
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="row.status?.listTypeValueId == 1"
                  (click)="updateStatusClient(row.clientId, 2)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color: red">Inactive</span>
                </button>

                <button
                  mat-menu-item
                  *ngIf="row.status?.listTypeValueId == 2"
                  (click)="updateStatusClient(row.clientId, 1)"
                >
                  <mat-icon>done</mat-icon>
                  <span style="color: #50cd89 !important">Active</span>
                </button>
              </mat-menu>
            </td>

            <td class="text-center">
              {{ row.clientCode }}
            </td>
            <td class="text-center">
                {{
                  row.clientName ? row.clientName : ""
                }}
              </td>
           

            <td class="text-center">
              {{ row.businessUnit?.businessUnitName }}
            </td>
            <!-- <td class="text-center">
                {{ row.businessUnit.entity.entityName ? row.businessUnit.entity.entityName : "-" }}
              </td> -->
            <td class="text-center">
              {{ row.addressLine1 ? row.addressLine1 : "-" }}
            </td>

            <td class="active-status text-center">
              <span
                class="btn btn-sm btn-block"
                style="color: #50cd89 !important;    position: relative;
                  right: 25px;"
                *ngIf="row.status?.listTypeValueId == 1"
              >
                {{ row.status?.listTypeValueName }}
              </span>

              <span
                class="btn btn-sm btn-block"
                style="
                  color: red;
                  position: relative;
                  text-align: justify;
                  right: 11px;
                "
                *ngIf="row.status?.listTypeValueId == 2"
              >
                {{ row.status?.listTypeValueName }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody
          style="border-left: 1px solid #ddd; border-right: 1px solid #ddd"
          *ngIf="tableShow"
        >
          <tr>
            <td
              style="
                text-align: center;
                padding: 10px;
                font-size: 19px;
                font-weight: 600;
                font-family: 'Mulish', sans-serif;
                left: 5;
                position: relative;
              "
              colspan="8"
              *ngIf="nodatafound"
            >
              Client Records Not Found!
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="
          clientList?.length > 5 &&
          (clientList | mytask : search)?.length > 0 &&
          tableShow
        "
      >
        <pagination-controls
          (pageChange)="p = $event"
          class="float-right pagination"
          style="float: right"
          *ngIf="(clientList | mytask : search).length > 5"
          align="end"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border table-spinner"
    role="status"
    style="height: 3rem; width: 3rem; color: #1a83ff"
  ></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div class="text-center" *ngIf="error">
  <div class="alert alert-danger alert-dismissible toast-msg" role="alert">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>
</div>
