import { ANALYZE_FOR_ENTRY_COMPONENTS, Component, OnInit } from '@angular/core';

import { SettingsService } from '../../providers/settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';
import { MustMatch} from '../../password-mustmatch.validator';
import { UserService } from '../../providers/user.service';
import { SuperadminService } from '../../providers/superadmin.service';
import { AdminService } from 'src/app/providers/admin.service';
import { passwordStrengthValidator } from 'src/app/password-validator';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})

export class MyAccountComponent implements OnInit
{
  tabindex : any = 1 ; //Profile Tabs Default My Profile 1
  currentPasswordExistStatus :  any;
  currentPasswordExistMessage :  any;
  fileblob: any;
  constructor(
    private SettingsService: SettingsService,
    private UserService: UserService,
    private SuperadminService: SuperadminService,
    private formBuilder: FormBuilder,
    private router : Router,
    private adminService : AdminService
  ) { }
  usertData : any;
  resultData : any = {};
  idResultData : any = {};

  countryData : any;
  stateData : any;
  districtData : any;
  cityData : any;


  postForm: FormGroup;
  orgpostForm: FormGroup;
  changePasswordSubmitForm: FormGroup;
  submitted: boolean = false;
  change_password_submitted: boolean = false;
  errorMsg : any;
  editResultData:any;

  sucess_msg:any;
  error_msg:any;
  // loader:any= true;
  success:any=false;
  error:any=false;
  backdrop:any= false;


  stateList:any=[];
  districtList:any=[];
  cityList:any=[];


  editCountryId:any;
  editStateId:any;
  editDitrictId:any;
  editCityId:any;

  password;
  show = false;

  newPassword;
  newShow = false;

  confirmNewPassword;
  confirmNewShow = false;


  showPassword = false;
  showConfirmPassword = false;
  showNewPassword = false;

  industryTypeLov : any;
  userType : any;
  activeTimeZoneData :any;
  organizationId :any;

  countrylist:any;
  countryId: any;
  statelist: any;
  stateId: any;
  disrictId: any;
  disrictList: any;
  cityId: any;
  // cityList: any;
  updatePasswordDisable:boolean = true;

  ngOnInit(): void
  {
    this.getLovCountries();

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;

    this.password = 'password';
    this.newPassword = 'password';
    this.confirmNewPassword = 'password';

    if(userId)
    {
      this.UserService.getUserById(userId).subscribe((resultData:any) =>
      {
        console.log("sds",resultData);
        if( resultData.userType && resultData.userType.userType == "Admin" )
        {
       
          this.organizationId = resultData.organization.organizationId;

          this.editCountryId = resultData.organization.country.countryId;
          this.editStateId = resultData.organization.state.stateId;
          this.editDitrictId = resultData.organization.district.districtId;
          this.editCityId = resultData.organization.city.cityId;

          this.getLovStates(this.editCountryId);
          this.getLovDistricts(this.editStateId);
          this.getLovCities(this.editDitrictId);
          // this.loader = false;
        }
        else if( resultData.employee && resultData.employee.employmentType)
        {
          // this.loader = false;
          this.idResultData=resultData;

          this.editCountryId = this.idResultData.userInfo.country.countryId;
          this.editStateId = this.idResultData.userInfo.state.stateId;
          this.editDitrictId = this.idResultData.userInfo.district.districtId;
          this.editCityId = this.idResultData.userInfo.city.cityId;

          this.getLovStates(this.editCountryId);
          this.getLovDistricts(this.editStateId);
          this.getLovCities(this.editDitrictId);
          // this.loader = false;
        }
      });
    }

    this.myProfile();

    //Employee edit Post Form
    this.postForm = this.formBuilder.group({
      firstName : ['',[Validators.required]],
      lastName : [''],
      organizationEmail : ['',[Validators.required]],
      //mobileNumber : ['',[Validators.required, Validators.min(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{12}$")]],
      mobileNumber : ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],

      countryId : ['',[Validators.required]],
      stateId : ['',[Validators.required]],
      districtId : ['',[Validators.required]],
      cityId : ['',[Validators.required]],
      //postalCode : ['',[Validators.required, Validators.pattern("^[0-9.-]+$")]],
      postalCode: ['',[Validators.required,Validators.pattern("^[0-9.-]+$")]],
      address : ['',[Validators.required]],


    });

    //Org Post Form
    this.orgpostForm = this.formBuilder.group({
      organizationName : ['',[Validators.required]],
        industryType : ['',[Validators.required]],
        contactPerson : ['',[Validators.required]],
        //contactCountryCode : ['',[Validators.required]],
        contactPhoneNumber :  ['' ,[Validators.required]],
        contactEmail : ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        postalCode : ['',[Validators.pattern("^[0-9.-]+$")]],
        address : [''],
        fiscalYearStart : [''],
        defaulTimezone : [''],
        //reportingCurrency : [''],
        countryId : ['' ,[Validators.required]],
        stateId : [''],
        districtId : [''],
        cityId : [''],

        /* userTypeId : ['' ,[Validators.required]],
        emailId : ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        password : ['' ,[Validators.required]], */
    });

    //Change Password
    this.changePasswordSubmitForm = this.formBuilder.group({
      password : ['',[Validators.required]],
      new_password : ['',[Validators.required,passwordStrengthValidator()]],
      confirm_new_password : ['',[Validators.required,passwordStrengthValidator()]],
    },
	  {
		  validators :  MustMatch('new_password', 'confirm_new_password')
	  });
    this.editFormPatchValue();
    this.getIndustryTypeLov(7);
    //this.getActiveCountry();
    this.getActiveTimeZone();
  }

  getIndustryTypeLov(listTypeValueId)
  {
    this.SettingsService.getListTypeValues(listTypeValueId).subscribe((resultData:any) =>
    {
      this.industryTypeLov=resultData;
    });
  }

  getActiveTimeZone()
  {
    this.SuperadminService.getActiveTimezone().subscribe((result: any) =>
    {
      this.activeTimeZoneData = result;
    })
  }

  editFormPatchValue()
  {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;

    if(userId)
    {
      this.UserService.getUserById(userId).subscribe((resultData:any) =>
      {
  
        this.resultData=resultData;
        console.log(this.resultData);
        this.countryId = this.resultData.employee.employeeInfo.country.countryId;
        this.stateId = this.resultData.employee.employeeInfo.state.stateId;
        this.disrictId = this.resultData.employee.employeeInfo.district.districtId;
        this.cityId = this.resultData.employee.employeeInfo.city.cityId;

        if( resultData.userType && resultData.userType.userType == "Admin" )
        {

          this.orgpostForm.patchValue({
            organizationName : this.resultData.organization.organizationName,
            //industryType: this.resultData.industryType,
            contactPerson: this.resultData.organization.contactPerson,
            //contactCountryCode: this.resultData.contactCountryCode,
            contactPhoneNumber: this.resultData.organization.contactPhoneNumber,
            contactEmail: this.resultData.organization.contactEmail,
            postalCode: this.resultData.organization.postalCode,
            address: this.resultData.organization.address,
            fiscalYearStart: this.resultData.organization.fiscalYearStart,
            defaulTimezone: this.resultData.organization.defaulTimezone,
            //reportingCurrency: this.resultData.reportingCurrency,
            //countryId: this.resultData.countryId,
            //stateId: this.resultData.stateId,
            //districtId: this.resultData.districtId,
            //cityId: this.resultData.cityId,
          });

          if(this.resultData.organization.industryType)
          {
            this.orgpostForm.patchValue({
              industryType: this.resultData.organization.industryType.listTypeValueId
            });
          }

          if(this.resultData.organization.country)
          {
            this.orgpostForm.patchValue({
              countryId: this.resultData.organization.country.countryName
            });
          }

          if(this.resultData.organization.state)
          {
            this.orgpostForm.patchValue({
              stateId: this.resultData.organization.state.stateName
            });
          }

          if(this.resultData.organization.district)
          {
            this.orgpostForm.patchValue({
              districtId: this.resultData.organization.district.districtName
            });
          }

          if(this.resultData.organization.city)
          {
            this.orgpostForm.patchValue({
              cityId: this.resultData.organization.city.cityName
            });
          }
        }
        else if( resultData.employee && resultData.employee.employmentType)
        {
         this.postForm.patchValue({
            firstName: this.resultData.employee.firstName,
            lastName: this.resultData.employee.lastName,
            organizationEmail: this.resultData.employee.organizationEmail,
            mobileNumber: this.resultData.employee.mobileNumber,
            //countryId: this.resultData.countryId,
           // stateId: this.resultData.stateId,
            //districtId: this.resultData.districtId,
            //cityId: this.resultData.cityId,
            postalCode: this.resultData.employee.postalCode,
            address: this.resultData.employee.address,
          });

          if(this.resultData.employee.employeeInfo)
          {
            this.postForm.patchValue({
              countryId: this.resultData.employee.employeeInfo.country.countryName
            });
          }

          if(this.resultData.employee.employeeInfo)
          {
            this.postForm.patchValue({
              stateId: this.resultData.employee.employeeInfo.state.stateName,
            });
          }

          if(this.resultData.employee.employeeInfo)
          {
            this.postForm.patchValue({
              districtId: this.resultData.employee.employeeInfo.district.districtName,
            });
          }

          if(this.resultData.employee.employeeInfo)
          {
            this.postForm.patchValue({
              cityId: this.resultData.employee.employeeInfo.city.cityName,
            });
          }
        }
      });
    }
  }

  get validate() { return this.postForm.controls; }
  get org_validate() { return this.orgpostForm.controls; }
  get change_password_validate() { return this.changePasswordSubmitForm.controls; }

  orgOnSubmit()
  {
    if(this.organizationId) //Update
    {
      //let postValues = this.postForm.value;

      var postValues = {
        organizationName:this.orgpostForm.value.organizationName,
        industryType:{
          listTypeValueId: this.orgpostForm.value.industryType
        },
        contactPerson:this.orgpostForm.value.contactPerson,
        //contactCountryCode:this.postForm.value.contactCountryCode,
        contactPhoneNumber:this.orgpostForm.value.contactPhoneNumber,
        contactEmail:this.orgpostForm.value.contactEmail,
        address:this.orgpostForm.value.address,
        postalCode:this.orgpostForm.value.postalCode,
        fiscalYearStart:this.orgpostForm.value.fiscalYearStart,
        defaulTimezone:this.orgpostForm.value.defaulTimezone,
        //reportingCurrency:this.postForm.value.reportingCurrency,
      }

      postValues['organizationId'] = this.organizationId;
      postValues['organizationStatus'] = this.resultData.organization.organizationStatus;

      if(this.orgpostForm.value.countryId)
      {
        postValues['country'] = {
          countryId: this.orgpostForm.value.countryId
        };
      }

      if(this.orgpostForm.value.stateId)
      {
        postValues['state'] = {
          stateId: this.orgpostForm.value.stateId
        };
      }

      if(this.orgpostForm.value.districtId)
      {
        postValues['district'] = {
          districtId: this.orgpostForm.value.districtId
        };
      }

      if(this.orgpostForm.value.cityId)
      {
        postValues['city'] = {
          cityId: this.orgpostForm.value.cityId
        };
      }

      this.SuperadminService.editOrganization(this.organizationId,postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
        }
        setTimeout(() => {
          //this.router.navigate(['/home/my-profile']);
          window.location.reload();
        }, redirectMsgTimeOut)

      }, err =>{
        this.errorMsg = err.error.description;
      })
    }
  }

  onSubmit() //form Submit Function
  {
    this.submitted = true;

    if (this.postForm.invalid) {
      /* const controls = this.postForm.controls;
      console.log(controls);
      for (const name in controls) {
          if (controls[name].invalid) {
             console.log(name);
          }
      } */
      return;
    }

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    let employeeId = this.usertData.employee.employeeId;
    console.log("postForm",this.postForm.value);
    //console.log(this.usertData);return

    if(userId && employeeId) //Edit
    {
      var postValues = {
        employeeId       : employeeId,
        firstName    : this.postForm.value.firstName,
        middleName   : this.usertData.employee.middleName,
        employeeNumber   : this.usertData.employee.employeeNumber,
        lastName     : this.postForm.value.lastName,
        address      : this.postForm.value.address,
        postalCode      : this.postForm.value.postalCode,
        mobileNumber     : this.postForm.value.mobileNumber,
        organizationEmail     : this.postForm.value.organizationEmail,
        employeeInfo: {
          employeeInfoId: this.usertData.employee.employeeInfoId,
          country: {
            countryId: this.countryId,
          },
          state: {
            stateId: this.stateId,
          },
          district: {
            districtId: this.disrictId,
          },
          city: {
            cityId: this.cityId,
          }
        },
        /* status: {
         listTypeValueId: this.usertData.userId,
        }, */
       // organization : this.usertData.organization,
      }
      console.log(postValues);
      // return;

      this.UserService.editEmployeeProfile(employeeId,postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          //console.log(result);
          this.success = true;
          this.sucess_msg = result.description;
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
         /*  setTimeout(() => {
            this.router.navigate(['/home/my-profile']);
          }, redirectMsgTimeOut) */

          setTimeout(() => {
            window.location.reload();
          }, redirectMsgTimeOut)

        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }

      })
    }
  }

  myProfile()
  {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;

    if(userId)
    {
      this.UserService.getUserById(userId).subscribe((resultData:any) =>
      {
          this.resultData=resultData;
          console.log(this.resultData);

          if(this.resultData.userInfo)
          {
            if(this.resultData.userInfo)
            {
              this.editCountryId = this.resultData.userInfo.country.countryId;
            }
            if(this.resultData.userInfo)
            {
             this.editStateId = this.resultData.userInfo.state.stateId;
            }
            if(this.resultData.userInfo)
            {
              this.editDitrictId = this.resultData.userInfo.district.districtId;
            }
            if(this.resultData.userInfo)
            {
              this.editCityId = this.resultData.userInfo.city.cityId;
            }
          }
          // console.log("resultData.userAttachment",resultData.employee.employeeAttachment);
          // if(resultData.userAttachment)
           if(resultData.employee?.employeeAttachment)
          {
              this.UserService.getuserImage(resultData.employee.employeeAttachment.fileViewUrl).subscribe( blob => {
                const a = document.createElement('a')
                const objectUrl: any = blob
                this.fileblob = blob;
                console.log("this.fileblob",this.fileblob);
              },
              err => {
                this.fileblob = "assets/uploads/no-image.png"
                console.log("this.fileblob",this.fileblob);
              }
            );
          }else{
            this.fileblob = "assets/uploads/no-image.png"
            console.log("this.fileblob",this.fileblob);
          }
      });
    }
  }

  getLovCountries()
  {
    this.SettingsService.getLovCountries().subscribe((result: any) => {
      this.countryData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

  getLovStates(editCountryId)
  {
   /*  this.SettingsService.getLovStates().subscribe((result: any) =>  */
    this.SettingsService.getCountryAgainstStateList(editCountryId).subscribe((result: any) =>
    {
      this.stateList = result;
    })
  }

  getLovDistricts(editStateId)
  {
    this.SettingsService.getStateAgainstDistictList(editStateId).subscribe((result: any) =>
    {
      this.districtList = result;
    })
  }

  getLovCities(editDitrictId)
  {
    /* this.editCountryId = this.resultData.userInfo.country.countryId;
    this.editStateId = this.resultData.userInfo.state.stateId;
    this.editDitrictId = this.resultData.userInfo.district.districtId;
    this.editCityId = this.resultData.userInfo.city.cityId; */

    this.SettingsService.getDistrictAgainstCityList(editDitrictId).subscribe((result: any) =>
    {
      this.cityList = result;
    })
  }

  //Profile Tabs click
  profileTabs(val)
  {
    this.tabindex = val;
  }

  checkCurrentPassword()
  {
    if(this.changePasswordSubmitForm.value.password.length != 0)
    {
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      let organizationId = this.usertData.organization.organizationId;
      let userId = this.usertData.userId;
      // let postValues = this.changePasswordSubmitForm.value;

      let postValues = {
        password:this.changePasswordSubmitForm.value.password
      }

      postValues['userId'] = userId;
      //postValues['organization'] = {organizationId: 1};
      postValues['organization'] = {organizationId: organizationId};

      this.UserService.createCheckExistCurrentPassword(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.currentPasswordExistStatus = true;
        }
        else if(result.statusCode > 400)
        {
          this.currentPasswordExistStatus = false;
        }

        this.currentPasswordExistMessage = result.message;
      })
    }
    else
    {
      this.currentPasswordExistStatus = false;
    }
  }

  changePasswordSubmit()
  {
    this.change_password_submitted = true;
    this.updatePasswordDisable = false;

    /* if(this.changePasswordSubmitForm.invalid || this.changePasswordSubmitForm.valid || this.currentPasswordExistStatus == false)
    {
      console.log("invalid change password");
      return;
    } */

     if ( this.changePasswordSubmitForm.invalid || this.currentPasswordExistStatus == false)
    {
      console.log("invalid change password");
      return;
    }

    /* if(this.currentPasswordExistStatus == false)
    {
      console.log("invalid change password");
      return;
    } */

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));

    var changePasswordValues = {
      password     : this.changePasswordSubmitForm.value.new_password,
      userId       : this.usertData.userId,
      // organization : this.usertData.organization,
    }
    //console.log(postValues);
    this.UserService.updateChangePassword(changePasswordValues,this.usertData.userId).subscribe((result: any) =>
    {
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.updatePasswordDisable = true;
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        this.updatePasswordDisable = true;
      }
      setTimeout(() => {
        window.location.reload();
      }, redirectMsgTimeOut)

    })
  }

  countryChange(event)
  {
    let countryId = event.target.value;
    if(countryId)
    {
      this.SettingsService.getCountryAgainstStateList(countryId).subscribe((resultData:any) =>
      {
        if(resultData.statusCode)
        {
          this.stateList=[];
        }
        else
        {
          //this.postForm.get('stateId').setValue('');
          this.postForm.get('districtId').setValue('');
          this.postForm.get('cityId').setValue('');

          this.stateList=resultData;
        }
        this.districtList=[];
        this.cityList=[];
      });
    }
    else
    {
      this.stateList=[];
      this.districtList=[];
      this.cityList=[];

      this.postForm.get('stateId').setValue('');
      this.postForm.get('districtId').setValue('');
      this.postForm.get('cityId').setValue('');
    }
  }

  stateChange(event)
  {
    let stateId = event.target.value;
    if(stateId)
    {
      this.SettingsService.getStateAgainstDistictList(stateId).subscribe((resultData:any) =>
      {
        if(resultData.statusCode)
        {
          this.districtList.length = [];
        }
        else
        {
          this.districtList=resultData;
          this.postForm.get('cityId').setValue('');
        }
        this.cityList = [];
      });
    }
    else
    {
      this.districtList = [];
      this.cityList = [];

      this.postForm.get('districtId').setValue('');
      this.postForm.get('cityId').setValue('');
    }
  }

  districtChange(event)
  {
    let districtId = event.target.value;
    if(districtId)
    {
    
      this.SettingsService.getDistrictAgainstCityList(districtId).subscribe((resultData:any) =>
      {
        if(resultData.statusCode)
        {
          this.cityList = [];
        }
        else
        {
          this.cityList=resultData;
          this.postForm.get('cityId').setValue('');
        }
      });
    }
    else
    {
      this.cityList = [];
      this.postForm.get('cityId').setValue('');
    }
  }
// <----------new--------->
  countrySelect(country){
    console.log(country);
    this.countryId=country.countryId;
    this.postForm.patchValue({
       countryId:country.countryName
    });
    console.log("this.postForm",this.postForm);
  }

  countrySearch(event)
  {
    this.adminService.countryAutoCompleteSearch(event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.countrylist = data;
       }
    }
    )
  }

  stateSelect(state)
  {
    this.stateId=state.stateId;
    
    
    this.postForm.patchValue({
      stateId:state.stateName
     });
  }

  stateSearch(event)
  {
    this.adminService.stateAutoCompleteSearch( this.countryId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.statelist = data;
       }
    }
    )
  }

  districtSelect(disrict)
  {
    this.disrictId=disrict.districtId;
    
    
    this.postForm.patchValue({
      districtId:disrict.districtName
     });
  }

  districtSearch(event)
  {
    this.adminService.districtAutoCompleteSearch(this.stateId ,event.target.value).subscribe( (data:any) =>
    {
       if(!data.statusCode)
       {
        this.disrictList = data;
       }
    }
    )
  }

  citySelect(city)
  {
    console.log("city",city);
    this.cityId=city.cityId;
    
    this.postForm.patchValue({
        cityId:city.cityName
    }); 
  }

  citySearch(event)
  {
    console.log("event.target.value",event.target.value);
    this.adminService.cityAutoCompleteSearch(this.disrictId ,event.target.value).subscribe( (data:any) =>
    {
      if(!data.statusCode)
      {
      this.cityList = data;
      }
    }
    )
  }

  toggleShowPassword(val)
  {
    if(val == 1)
    {
      this.showPassword = !this.showPassword;
    }

    if(val == 2)
    {
      this.showNewPassword = !this.showNewPassword;
    }

    if(val == 3)
    {
      this.showConfirmPassword = !this.showConfirmPassword;
    }

  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === 'Space' || event.key === ' ') {
      event.preventDefault();
    }
  }
}
