<!-- <span>
    <h4 class="dynamic-title">Record Timing Settings</h4>
  </span>
  <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Record Timing Settings</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<nav class="navbar  bg" style="margin-top: 67px;">
    <a class="navbar-brand navDesign" style="font-size: 22px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;">Record Timings Settings</a>
    <div class="d-flex">
   
        <button class="btn" type="submit" (click)="apply_setting()" title="Apply Leave"
         style="    background-color: #1a83ff;
        color: whitesmoke;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 610;
        font-family: 'Mulish', sans-serif;
        position: relative;
        /* float: left; */
        right: 10px;">Add Record Time Settings</button>
      </div>
   
  </nav>
<div class="common-content">
    <div class="card-new colDesign" style="font-family: Mulish; ">
        <!-- <div class="card"> -->
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <mat-card style=""  *ngIf="tableShow">
                            <div class="container-fluid" style="margin: 15px 0px;">
                               <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="time-header form-control-label" style="font-family: Mulish;">Time Setting</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="table-responsive-new1">
                                                    <div class="--main-block">
                                                        <table class="table --table-bordered table-border-new">
                                                            <thead class="t-head">
                                                                <th>Minimum&nbsp;Time</th>
                                                                <th>Maximum&nbsp;Time</th>
                                                                <th>Action</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let settings of settingslist  let i=index" >
                                                                    <td *ngIf="settings.minimumWokrHours == 1">{{settings.minimumWokrHours}} Hr</td>
                                                                    <td *ngIf="settings.minimumWokrHours != 1">{{settings.minimumWokrHours}} Hrs</td>
                                                                    <td *ngIf="settings.maximumWokrHours == 1">{{settings.maximumWokrHours}} Hr</td>
                                                                    <td *ngIf="settings.maximumWokrHours != 1">{{settings.maximumWokrHours}} Hrs</td>
                                                                    <td>
                                                                        <mat-icon class="material-icons edit-icon" (click)="edit(settings.settingsId)">mode_edit</mat-icon> 
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                                
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="time-header form-control-label"style="font-family: Mulish;">Permission Setting</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="table-responsive-new1">
                                                    <div class="--main-block">
                                                        <table class="table --table-bordered table-border-new">
                                                            <thead class="t-head">
                                                                <th>Maximum&nbsp;Time</th>
                                                                <th>Action</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let settings of settingslist  let i=index" >
                                                                    <td *ngIf="settings.maximumPermissionHours == 1">{{settings.maximumPermissionHours}} Hr</td>
                                                                    <td *ngIf="settings.maximumPermissionHours != 1">{{settings.maximumPermissionHours}} Hrs</td>
                                                                    <td>
                                                                        <mat-icon class="material-icons edit-icon" (click)="editPermission(settings.settingsId)">mode_edit</mat-icon> 
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>                                
                                               
                                            </div> 
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </mat-card>

                        <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata && settingslist.length ===undefined">
                            <div class="card-body-nodata">
                              <div class="alert-nodata alert-primary">
                                <!-- <h4 align="center" >My Task Not Found</h4> -->
                                <h4 align="center" class="h4" style="    margin-top: 14px;
                                font-size: 24px;
                                font-family: Roboto, Helvetica Neue, sans-serif;
                                margin-bottom: 11px;padding:10px
                                ">Record Time Setting Not Found </h4>
                              </div>
                            </div>
                          </mat-card>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
</div>


<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{success_msg}}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
  </div>