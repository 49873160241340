import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
// import { AddEmployeeComponent } from 'src/app/employeeComponents/add-employee/add-employee.component';
import { ProjectManagementService } from 'src/app/providers/project-management.service';

import { AddEmployeeProjectComponent } from '../add-employee-project/add-employee-project.component';

@Component({
  selector: 'app-emp-list',
  templateUrl:'./emp-list.component.html',
  styleUrls: ['./emp-list.component.scss'],
})
export class EmpListComponent implements OnInit {
  projectList: any[] = [];
  projectData: any = [];
  loader:boolean = true;
  search: any;
  nodata = false;
  disableAssignEmployee:boolean =  true;
  success = false;
  error: any = false;
  sucess_msg: any;
  errormessagejson: any;
  error_msg: any;
  beforesearchpage: any;
  p: number;
  constructor(
    private gs: ProjectManagementService,
    public dialogRef: MatDialogRef<EmpListComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.gs.getprojectByID(this.data.projectId).subscribe(
      (data: any) => {
        console.log('data', data);
        this.projectList = data.employeeProjects;
        this.projectData = data;
        this.loader = false;
        this.disableAssignEmployee = false;
        console.log('projectData',this.projectData);
        
        if (this.projectList.length == 0) {
          this.nodata = true;
          console.log('nodata');
        }
      },
      (error) => {
        this.nodata = true;
        console.log('error');
        if (error.status == 404) {
          this.nodata = true;
          console.log('404');
        }
      }
    );
  }

  addEmployee() {
    const dialogRef = this.dialog.open(AddEmployeeProjectComponent, {
      width: '600px',
      data: this.projectData,

      
    });
    console.log('dataaaa',this.projectData);
    this.dialogRef.close();

    dialogRef.afterClosed().subscribe((result) => {
      const dialogRef = this.dialog.open(EmpListComponent, {
        data: this.data,
        width: '500px',
        height: '500px',
      });
    });
  }
  changepage(){
    // if(this.search.length != 0)
    // {
    //   this.p = 1;
    // }else{
    //   this.p = this.beforesearchpage;
    // }
    console.log(this.projectList.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.projectList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.projectList.length);
      console.log("nodata");
    }
  }

  editEmp(e) {
    e['projectId'] = this.data.projectId;
    const dialogRef = this.dialog.open(AddEmployeeProjectComponent, {
      width: '500px',
      data: e,
    });
    this.dialogRef.close();

    dialogRef.afterClosed().subscribe((result) => {
      const dialogRef = this.dialog.open(EmpListComponent, {
        data: this.data,
        width: '500px',
        height: '500px',
      
      });
    });
  }

  // edit(e){
  //   const dialogRef = this.dialog.open(AddRoleComponent,{
  //     width:"500px",
  //     data:e
  //   })
  //   this.dialogRef.close()

  //   dialogRef.afterClosed().subscribe(result => {
  //     const dialogRef = this.dialog.open(AssignEmpListComponent,{
  //       data:this.data
  //     })
  //   });
  // }

  // addRole(){
  //   const dialogRef = this.dialog.open(AddRoleComponent,{
  //     width:"500px",
  //     data:this.projectData
  //   })
  //   this.dialogRef.close()

  //   dialogRef.afterClosed().subscribe(result => {
  //     const dialogRef = this.dialog.open(AssignEmpListComponent,{
  //       data:this.data
  //     })
  //   });
  // }
}
