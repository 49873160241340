import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TimeSettingsFormComponent} from '../time-settings-form/time-settings-form.component'
import { LeaveService } from 'src/app/providers/leave.service';
import { EmpPermissionSettingComponent } from '../emp-permission-setting/emp-permission-setting.component';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-employee-time-settings',
  templateUrl: './employee-time-settings.component.html',
  styleUrls: ['./employee-time-settings.component.scss']
})
export class EmployeeTimeSettingsComponent implements OnInit {
  loader:boolean=true;

  settingslist : any = [];
tableShow: boolean=false;
  constructor(private leaveservice : LeaveService,
    private httpClient:HttpClient,
    private router:Router,
    private dialog:MatDialog,
    private headerservice: HeaderService
  ) { }

  ngOnInit(): void {
    // this.headerservice.setTitle('Record Timings Settings')
    this.headerservice.setTitle('');

    this.tableShow=false

    this.leaveservice.getsettings().subscribe
    ((data:any)=>{
      this.settingslist = data;
      this.tableShow=true;
      this.loader = false;
    })
  }
  edit(settingsId){
    console.log("create");
    const dialogbox = this.dialog.open(TimeSettingsFormComponent,{
      width:'400px',
      height:'fit-content',
      data : settingsId,
      position: {
        top: '17%' // Adjust the value as needed to move the dialog down
      }
    })

    dialogbox.afterClosed().subscribe(
      (res)=>{
        console.log(res);
         if(res)
         {
           if(res.statusCode == 200)
            this.loader = true;
           {
            setTimeout(() => {
              this.leaveservice.getsettings().subscribe
              ((data:any)=>{
                this.settingslist = data;
                this.loader = false;
              })
            }, 2000)
           }
         }
      }
    );
  }
  editPermission(settingsId){
    console.log("create");
    const dialogRef = this.dialog.open(EmpPermissionSettingComponent,{
      width:'400px',
      height:'fit-content',
      data : settingsId
    })
    dialogRef.afterClosed().subscribe(
      (res)=>{
        console.log(res);
         if(res){
           if(res.statusCode == 200) {
            this.loader = true;
            setTimeout(() => {
              this.leaveservice.getsettings().subscribe
              ((data:any)=>{
                this.settingslist = data;
                this.loader = false;
              })
            }, 2000)
           }
         }
      }
    );
  }
}

