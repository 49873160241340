<div class="container">
    <!-- <div>
      <span class="material-icons" style="transform: translateY(5px)">today</span>
      <span>&nbsp;&nbsp;{{data.date}}</span>
    </div><br>
  
    <div>
      <span class="material-icons" style="transform: translateY(5px)">history</span>
      <span>&nbsp;&nbsp;{{data.workedHours}}</span>
    </div><br> -->
    <div  align="end">
      <!-- <button mat-raised-button type="button" color="warn" > -->
        <span mat-dialog-close class="material-icons" style="color:red ;">cancel</span>
        <!-- <span style="margin-left:5px">Close</span> -->
      <!-- </button> -->
    </div>
    <div>
      <label class="form-control-label  ">Description</label>
      <!-- <input name="" matInput id="" readonly
      placeholder="Description " 
      [(ngModel)]="data.description"
      class="form-control email-input  "> -->

      <textarea name="" 
      matInput id="" 
      [(ngModel)]="data.description" 
      readonly
      placeholder="Enter description"
      class="form-control email-input select-placeholder"></textarea>
    
      </div>
      </div>

    