import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {httpOptions} from './properties';
import {httprecruitmentOptions } from './properties';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentService {

  constructor(private http: HttpClient) { }


  getclientlist(organizationId) {
    return this.http.get(`${environment.base_url}v2/client/active/organizationId/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  hiringfor():Observable<any>
  {
     return this.http.get(`${environment.rec_base_url}list-type-values/6`,httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  jobtypebyid() {
     return this.http.get(`${environment.rec_base_url}list-type-values/7`,httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  resource(orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/resourceinterviewStatus/interviewStatus/Selected/organizationId/${orgId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

 //// Login ///////
  uservalidation(logindata:any):Observable<any>
  {
    return this.http.post(`${environment.rec_base_url}user_validation`,logindata,httprecruitmentOptions)
    .pipe(map(data=>data));
  }


 ///   resource ////
  resourcecreate(resourcecreatedata:any):Observable<any>
  {
    return this.http.post(`${environment.rec_base_url}resource`,resourcecreatedata,httprecruitmentOptions)
    .pipe(map(data=>data)
    );
  }
  resourcelist(organizationId: any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/organizationId/${organizationId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  resourceupdate(resourcedataupdate: any, resourceId: any) {
    return this.http.put(`${environment.rec_base_url}resource/${resourceId}` ,resourcedataupdate,httprecruitmentOptions )
    .pipe(map(data=>data));
  }

  //search position
  position(positionId:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/position/org/${positionId}/${orgId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  //search interviewstatus
  interviewstatus(interviewStatus:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/interviewStatus/org/${interviewStatus}/${orgId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  //search
   searchresource(positionId:any,interviewStatus:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}resource/position/interviewStatus/org/${positionId}/${interviewStatus}/${orgId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  // resourcedetaillist():Observable<any>
  // {
  //   return this.http.get(`${environment.rec_base_url}resource`)
  //   .pipe(map(data=>data));
  // }

  findbyResourceId(resourceId: any) {
    return this.http.get(`${environment.rec_base_url}resource/${resourceId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  resourcestatus(resourceId,status):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}resource/deactive/${resourceId}/${status}`,null,httprecruitmentOptions)
    .pipe(map(data=>data));

  }

//////// onboarding //////
onboardingcreate(resourcedata:any):Observable<any>
  {
    return this.http.post(`${environment.rec_base_url}onboarding`,resourcedata,httprecruitmentOptions)
    .pipe(map(data=>data)
    );
  }

  onboardingupdate(onboardingId,resourcedata:any):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}onboarding/${onboardingId}`,resourcedata,httprecruitmentOptions)
    .pipe(map(data=>data)
    );
  }

  onboardingbyid(onboardingId: any) {
    return this.http.get(`${environment.rec_base_url}onboarding/${onboardingId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  onboardinglist(organizationId: any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}onboarding/organizationId/${organizationId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }


  getSearchOnboardingList(id:any,keywords:any,resourceId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.rec_base_url}onboarding/filter/${resourceId}/${keywords}/${id}`,httprecruitmentOptions).pipe(map(data=>data))
    return result;
  }

  getOnboardingListKeywords(id:any,keywords:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.rec_base_url}onboarding/filter/keyword/${keywords}/${id}`,httprecruitmentOptions).pipe(map(data=>data))
    return result;
  }

  getOnboardingListResource(id:any,resourceId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.rec_base_url}onboarding/filter/resource/${resourceId}/${id}`,httprecruitmentOptions).pipe(map(data=>data))
    return result;
  }


  onboardingstatus(onboardingId,onboardingstatus):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}onboarding/deactive/${onboardingId}/${onboardingstatus}`,null,httprecruitmentOptions)
    .pipe(map(data=>data));

  }

  updateOnboardingStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.rec_base_url}onboarding/deactive/${id}/${status}`,null,httprecruitmentOptions).pipe(map(data=>data));
    return result;
  }

  clientName():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}client/status/active`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  /// Position ////
  positioncreate(positiondata:any):Observable<any>
  {
    return this.http.post(`${environment.rec_base_url}position`,positiondata,httprecruitmentOptions)
    .pipe(map(data=>data)
    );
  }
  // positionlist():Observable<any>
  // {
  //   return this.http.get(`${environment.rec_base_url}position`,httprecruitmentOptions)
  //   .pipe(map(data=>data));
  // }
  positionlist(orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}v2/position/organizationId/${orgId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  positionListFilter(id:any,keywords:any,jobType:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}position/filter/${keywords}/${jobType}/${id}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  positionListFilterKeywords(id:any,keywords:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}position/filter/keyword/${keywords}/${id}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  positionListFilterJobType(id:any,jobType:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}position/filter/jobtype/${jobType}/${id}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }



  positionupdate(positionupdate: any, positionId: any) {
    return this.http.put(`${environment.rec_base_url}position/${positionId}`,positionupdate,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  interviewedstatus():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}list-type-values/5`,httprecruitmentOptions)
    .pipe(map(data=>data));

  }

  positionbyid(positionId:any,orgid:any):Observable<any>
  {
     return this.http.get(`${environment.rec_base_url}position/org/${positionId}/${orgid}`,httprecruitmentOptions)
     .pipe(map(data=>data));

  }

 //create clientname
  client():Observable<any>
  {
    //return this.http.get(`${environment.rec_base_url}client/status/active`,httprecruitmentOptions)
    return this.http.get(`${environment.rec_base_url}client/active_client`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  noticeperiod():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}list-type-values/8`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

  //search client name
  clientname():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}client/status/active`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

    //search position name
    positionname(positionName:any,orgId:any):Observable<any>
    {
      return this.http.get(`${environment.rec_base_url}position/positionName/org/${positionName}/${orgId}`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }
   //search client name
   clientid(clientId:any,orgId:any):Observable<any>
   {
     return this.http.get(`${environment.rec_base_url}position/client/org/${clientId}/${orgId}`,httprecruitmentOptions)
     .pipe(map(data=>data));
   }

  search(positionName:any,clientId:any,orgId:any):Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}position/positionName/clientId/org/${positionName}/${clientId}/${orgId}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  positionstatus(positionId,positionstatus):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}position/deactive/${positionId}/${positionstatus}`,null,httprecruitmentOptions)
    .pipe(map(data=>data));

  }
  positionstatuschange(positionId,positionStatus):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}position/positionstatus/${positionId}/${positionStatus}`,null,httprecruitmentOptions)
    .pipe(map(data=>data));

  }
  //Dashboard
  recruiterdashboard():Observable<any>
  {
     return this.http.get(`${environment.rec_base_url}recruiter-dashboard/1`,httprecruitmentOptions)
     .pipe(map(data=>data));

  }
/*  dashboardRecruitmentAdmin () {
    return this.http.get(`${environment.rec_base_url}dashboard/organization/1`,httprecruitmentOptions)
     .pipe(map(data=>data));
  } */
  admindashboard() {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/1`,httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  admindashbaordreport(fromdate :any , todate :any) {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/${fromdate}/${todate}`,httprecruitmentOptions)
     .pipe(map(data=>data));
  }

  admindashbaordreportbyrecruiter(fromdate: string, todate: string, recruiter: any) {
    return this.http.get(`${environment.rec_base_url}admin-dashboard/resource/${fromdate}/${todate}/${recruiter}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }

     geRecruiter() {
      return this.http.get(`${environment.rec_base_url}employee/user_type/Recruiter`,httprecruitmentOptions)
      .pipe(map(data=>data));
    }

 ///interviewed
 interviewed(interviewd:any):Observable<any>
 {
   return this.http.get(`${environment.rec_base_url}resource/interviewed/${interviewd}`,httprecruitmentOptions)
   .pipe(map(data=>data));
 }


   // Users
 userscreate(usersdata:any):Observable<any>
 {
   return this.http.post(`${environment.rec_base_url}employee`,usersdata,httprecruitmentOptions)
   .pipe(map(data=>data)
   );
 }
 usersupdate(usersdata:any , userId :any):Observable<any>
 {
   return this.http.put(`${environment.rec_base_url}employee/${userId}`,usersdata,httprecruitmentOptions)
   .pipe(map(data=>data)
   );
 }
 userslist():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}employee`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  genderstatus():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}list-type-values/2`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  userstype():Observable<any>
  {
    return this.http.get(`${environment.rec_base_url}user_type`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
  usersbyid(employeeId:any):Observable<any>
  {
     return this.http.get(`${environment.rec_base_url}employee/${employeeId}`,httprecruitmentOptions)
     .pipe(map(data=>data));

  }
  userstatus(employeeId,userstatus):Observable<any>
  {
    return this.http.put(`${environment.rec_base_url}employee/deactive/${employeeId}/${userstatus}`,null,httprecruitmentOptions)
    .pipe(map(data=>data));

  }

  //// client  ////
 clientcreate(clientcreate:any):Observable<any>
  {
    return this.http.post(`${environment.base_url}client`,clientcreate,httpOptions)
    .pipe(map(data=>data)
    );
  }
  clientupdate(clientupdate:any , clientId :any):Observable<any>
  {
    return this.http.put(`${environment.base_url}client/${clientId}`,clientupdate,httpOptions)
    .pipe(map(data=>data)
    );
  }

  clientbyid(clientId):Observable<any>
  {
     return this.http.get(`${environment.base_url}client/${clientId}`,httpOptions)
     .pipe(map(data=>data));
  }
  clientlist():Observable<any>
  {
    return this.http.get(`${environment.base_url}client`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientFilterList(keyword:any,organizationId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}client/keywords/${keyword}/${organizationId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  clientstatus(clientId,status):Observable<any>
  {
    return this.http.put(`${environment.base_url}client/deactive/${clientId}/${status}`,null,httpOptions)
    .pipe(map(data=>data));
  }

////// Reset Password /////
resetpassword(passwordResetdata:any,employeeId:any):Observable<any>
{
  return this.http.patch(`${environment.rec_base_url}employee/resetpassword/${employeeId}`,passwordResetdata,httprecruitmentOptions)
  .pipe(map(data=>data)
  );
}
currentpassword(currentPassword:any):Observable<any>
{
  return this.http.post(`${environment.rec_base_url}employee/currentpassword`,currentPassword,httprecruitmentOptions)
  .pipe(map(data=>data)
  );
}
///forget-password///
forgetpassword(emaildata:any):Observable<any>
{
  return this.http.post(`${environment.rec_base_url}Forgotpassword/OrganizationEmail`,emaildata,httprecruitmentOptions)
  .pipe(map(data=>data)
  );
}
///otp
otp(otpdata:any):Observable<any>
{
  return this.http.post(`${environment.rec_base_url}login/Otpvaildation`,otpdata,httprecruitmentOptions)
  .pipe(map(data=>data)
  );
}

//GetEmployeeByEmail
getemployyebyEmail(organizationEmail: any) {

  return this.http.get(`${environment.rec_base_url}employee/OrganizationEmail/${organizationEmail}`,httprecruitmentOptions)
  .pipe(map(data=>data)
  );
}


////// New Password /////
newpassword(passwordResetdata:any,employeeId:any):Observable<any>
{
  return this.http.patch(`${environment.rec_base_url}employee/resetpassword/${employeeId}`,passwordResetdata,httprecruitmentOptions)
  .pipe(map(data=>data)
  );
}
///
interviewstatusonbarding(interviewStatus:any):Observable<any>
{
  return this.http.get(`${environment.rec_base_url}resource/resourceinterviewStatus/interviewStatus/${interviewStatus}`,httprecruitmentOptions)
  .pipe(map(data=>data));
}
  //////resource/resourceinterviewStatus/interviewStatus/On Hold


  // requrimentbypostionActiveOpen(position:any) {
  //   return this.http.get(`${environment.rec_base_url}requirement/requirementstatus/status/position/1/1/${position}`,httprecruitmentOptions)
  //   .pipe(map(data=>data));
  // }
  
  requrimentbypostionActiveOpen(position:any) {
    return this.http.get(`${environment.rec_base_url}requirement/position/${position}`,httprecruitmentOptions)
    .pipe(map(data=>data));
  }
}



