import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { httpOptions,redirectMsgTimeOut,responseMsgTimeOut}  from '../../../providers/properties';
import { SuperadminService } from '../../../providers/superadmin.service';
import { AddModuleComponent } from '../add-module/add-module.component';
import {Sort} from '@angular/material/sort';
import { ViewModuleComponent } from '../view-module/view-module.component';
@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit
{
  resultData: any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;
  errorMsg : any;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  sortedData: any[];

  constructor(
    private superadminservice: SuperadminService,
    private dialog:MatDialog,
   )
   { }

  ngOnInit(): void {

    this.getModulesList();

  }

  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AddModuleComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getModulesList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  updateform(editData)
  {
    var dialogRef = this.dialog.open(AddModuleComponent, {
      width: '500px',height:'fit-content',
      data:editData
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getModulesList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
      //this.success = true;
    });
  }

  updateStatus(moduleId,status)
	{
    this.superadminservice.updateModuleStatus(moduleId,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.getModulesList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  /* ucFirst(string)
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } */


  viewForm(moduleId) //View Dialog Box
  {
    const dialogRef = this.dialog.open(ViewModuleComponent, {
    width: '500px',height:'auto',
    data:{moduleId}
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      this.getModulesList();

      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '')
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) =>
    {
      const isAsc = sort.direction === 'asc';

      switch (sort.active)
      {
        case 'moduleName':
          return compare(a.moduleName, b.moduleName, isAsc);

        case 'moduleDescription':
          return compare(a.moduleDescription, b.moduleDescription, isAsc);

        default:
          return 0;
      }
    });
  }

  getModulesList()
  {
    /* Get Designation Data */
    this.superadminservice.getModulesList().subscribe((result: any) => {
      this.resultData = result;
    },err =>{
       this.errorMsg = err.error.message;
       console.log(this.errorMsg);
    })
  }


}

function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
