import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Router,ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-shiftcalendar',
  templateUrl: './shiftcalendar.component.html',
  styleUrls: ['./shiftcalendar.component.scss']
})
export class ShiftcalendarComponent implements OnInit {


  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any = [];
  selected :true;
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  branchlist: any = [];
  years: number[] = [];
  usertype: any;
  organizationId: any;
  searchForm:FormGroup;
  sortedData: any;
  p:number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  today: any =  new Date();
  shiftcalendarId: any;
   days = [
    { name: 'Sunday', number: 0 },
    { name: 'Monday', number: 1 },
    { name: 'Tuesday', number: 2 },
    { name: 'Wednesday', number: 3 },
    { name: 'Thursday', number: 4 },
    { name: 'Friday', number: 5 },
    { name: 'Saturday', number: 6 }
  ];
  calendarnameexist: boolean =false;
  dateexist: boolean = false;
  dateexistMsg: string;
  dateFormat: any;
  constructor(  private formBuilder: FormBuilder,
    private router : Router,
    private payrollService: PayrollService, private datepipe : DatePipe ,
    private ActivatedRoute:ActivatedRoute,@Inject(AppComponent) public AppData: any ) { }

  ngOnInit(): void {


    
    this.searchForm = this.formBuilder.group({
      clientserach: [null], // Initialize with null
      startDateSearch:[null],
      endDateSearch:[null]
    });

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertype.userId;
     this.organizationId = this.usertype.organization.organizationId;
     
    this.postForm = this.formBuilder.group({
      client : [null,[Validators.required]],
      calendarName : [null,[Validators.required]],
      calendarDescription : [null,[Validators.required]],
        startDate : [null,[Validators.required]],
        endDate : [null,[Validators.required]],
        workDayStart : [null,[Validators.required]],
        workingDays : [null,[Validators.required]],
        leaveDays :  [null,[Validators.required]],
        default :  [false,[]]
     });

     this.payrollService.getclientlist(this.organizationId).subscribe((resultData:any) =>
     {
       this.clientList= resultData.filter(client =>client.status.listTypeValueId == 1);;

       
     });

     
  }

  get validate() { return this.postForm.controls; }

  get serachvalidate() { return this.searchForm.controls; }

  pageChages()
  {
    this.p=1;
  }

  searchSubmit()
  {
    this.p=1;
    this.resultData = [];
    this.noRecordsFound = false;
    console.log('search submit')
    let clientserach = this.searchForm.value.clientserach;
    console.log('clientserach', clientserach)
   
    let  startDateSearch = this.searchForm.value.startDateSearch;
    let  endDateSearch = this.searchForm.value.endDateSearch;
    console.log('startDateSearch', startDateSearch)

    if((!clientserach || clientserach == 'null') && (!startDateSearch || startDateSearch == 'null') &&  (!endDateSearch || endDateSearch == 'null'))
    {
      alert('Please select client.');
      return;
    }

    if((!clientserach || clientserach == 'null'))
    {
      alert('Please select client.');
      return;
    }

    

     if(((startDateSearch  &&  !endDateSearch ) || (!startDateSearch  &&  endDateSearch ) )&&  (clientserach || clientserach != 'null'))
     {
      alert('Please select startDate and endate.');
      return;
     }
     
      // if ((!startDateSearch || startDateSearch === 'null') && (endDateSearch || endDateSearch != 'null') || (startDateSearch || startDateSearch != 'null') && (!endDateSearch || endDateSearch === 'null'))
      // {
      //   alert('Please select startDate and endate.');
      //   return;
      // }
     
  

    if( (clientserach && clientserach != 'null') && (startDateSearch && startDateSearch != 'null')  &&  (endDateSearch && endDateSearch != 'null'))
    {
      this.loader=true;
      this.backdrop=true;
        let startDate = this.datepipe.transform(startDateSearch, 'yyyy-MM-dd');
        let endDate = this.datepipe.transform(endDateSearch, 'yyyy-MM-dd');
      this.payrollService.getshiftcalendarbyClientAndDate(clientserach,startDate ,endDate ).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if ((clientserach && clientserach != 'null') && (!startDateSearch || startDateSearch == 'null') )
    {
      this.loader=true;
      this.backdrop=true;
      this.payrollService.getshiftcalendarbyClient(clientserach).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
  }
  clearTable()
  {
    
    this.resultData = [];
    this.noRecordsFound = false;
    console.log('clear')
    this.searchForm.clearValidators;
    // this.payrollService.get().subscribe((elementslistData:any) =>
    // {
    //   console.log('elementslistData',elementslistData)
    //   this.resultData = elementslistData;
    //   console.log(this.resultData);
    //   if(this.resultData.length == 0)
    //   {this.noRecordsFound = true;
    //     this.nodatafound = true;
    //     console.log("nodata");
    //   }
    //   if(elementslistData.statusCode == 204){
    //     this.noRecordsFound = true;
    //   }
    // },
    // (error) => {
    //   this.nodatafound = true;
    //   console.log("error")
    //   if(error.status == 404){
    //     this.nodatafound = true;
    //     console.log("404")
    //   }
    // }
    // )
  }

  validatecalendarname(event)
  {
   if(event.target.value.lengh !=0 && this.postForm.value.client)
   {
       if(this.shiftcalendarId){


         let fata = {
          shiftCalendarId: this.shiftcalendarId,
          shiftCalendarName : event.target.value,
          client : {clientId: this.postForm.value.client},             
         }


        this.payrollService.validatecalendarnameupdate(fata).subscribe( (result:any) =>
        {
          if(result.shiftCalendarExist == false)
          {
             this.calendarnameexist = false;
          }
          else
          {
            this.existMsg = "Shift calendar Name Already Exist"
            this.calendarnameexist = true;
          }
        })
       }else{

        
      
        let fata = {
          shiftCalendarName : event.target.value,
          client : {clientId: this.postForm.value.client},             
         }


        this.payrollService.validateshiftcalendarname(fata).subscribe( (result:any) =>
        {
          if(result.shiftCalendarExist == false)
          {
             this.calendarnameexist = false;
          }
          else
          {
            this.existMsg = "Shift calendar name already exist"
            this.calendarnameexist = true;
          }
        })
       }
   }else{
    this.calendarnameexist = false;
   }
  }

  validatedate(event)
  {
    if(event.target.value.lengh !=0 && this.postForm.value.client &&  this.postForm.value.startDate && this.postForm.value.endDate)
   {
       if(this.shiftcalendarId){


         let fata = {
          shiftCalendarId: this.shiftcalendarId,
          shiftCalendarName : event.target.value,
          client : {clientId: this.postForm.value.client},       
          shiftCalendarStartDate: this.datepipe.transform(this.postForm.value.startDate ,'yyyy-MM-dd'),
          shiftCalendarEndDate:this.datepipe.transform(this.postForm.value.endDate,'yyyy-MM-dd')  

         }


        this.payrollService.validatecalendarnameupdate(fata).subscribe( (result:any) =>
        {
          if(result.shiftCalendarExist == false)
          {
             this.calendarnameexist = false;
          }
          else
          {
            this.dateexistMsg = "Shift calendar already exist for the dates"
            this.dateexist = true;
          }
        })
       }else{

        
      
        let fata = {
          shiftCalendarName : event.target.value,
          client : {clientId: this.postForm.value.client},             
          shiftCalendarStartDate: this.datepipe.transform(this.postForm.value.startDate ,'yyyy-MM-dd'),
          shiftCalendarEndDate:this.datepipe.transform(this.postForm.value.endDate,'yyyy-MM-dd')  
         }


        this.payrollService.validateshiftcalendarname(fata).subscribe( (result:any) =>
        {
          if(result.shiftCalendarExist == false)
          {
             this.dateexist = false;
          }
          else
          {
            this.dateexistMsg = "Shift calendar already exist for the dates"
            this.dateexist = true;
          }
        })
       }
   }else{
    this.dateexist = false;
   }
  }

  onlyNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit()
  {
    this.submitted = true;
   const controls = this.postForm.controls;
    console.log("postForm" + this.postForm.invalid)
    console.log("this.calendarnameexist " + this.calendarnameexist );
    console.log("this.dateexist  " + this.dateexist );
    if (this.postForm.invalid || this.calendarnameexist || this.dateexist) {
      console.log("invalid")
       console.log(controls);
        for (const name in controls) {
            if (controls[name].invalid) {
              console.log(name);
            }
        } 
        return;
    }

    if(this.shiftcalendarId) //Update Shiftcalendar
    {
      let postValues = this.postForm.value;
      

      console.log(postValues);

    
       let shiftcalendarData = 
       {
              shiftCalendarId: this.shiftcalendarId,
              shiftCalendarName : this.capitalizeWordsName(postValues.calendarName),
              shiftCalendarDescription :this.capitalizeWordsDescription(postValues.calendarDescription),
              shiftCalendarStartDate: this.datepipe.transform(postValues.startDate ,'yyyy-MM-dd'),
              shiftCalendarEndDate:this.datepipe.transform(postValues.endDate ,'yyyy-MM-dd'),
              shiftCalendarWorkdayStart: postValues.workDayStart,
              shiftCalendarWorkingdays: postValues.workingDays,
              shiftCalendarLeavedays: postValues.leaveDays,
              client: {
                clientId: postValues.client,
              },   
              organization : {organizationId: this.organizationId},          
              shiftCalendarStatus :this.resultData.shiftCalendarStatus,
              shiftCalendadefault : postValues.default
            }

         console.log(shiftcalendarData);

                    
        this. payrollService.updateShiftCalendar(this.shiftcalendarId , shiftcalendarData).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;
            this.postForm.reset();
            this.submitted = false;
            this.searchSubmit();
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            //this.router.navigate(['/home/employee']);
                 this.shiftcalendarId = null;
            setTimeout(() => {
             
            }, redirectMsgTimeOut)
          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }

            }, err =>
            {
                this.errorMsg = err.error.message;
            })

     
    }
    else  //Add Shiftcalendar
    {
        let postValues = this.postForm.value;
      
     
           console.log(postValues);
        
            let shiftcalendarData = 
            {
              
              shiftCalendarName : this.capitalizeWordsName(postValues.calendarName),
              shiftCalendarDescription :this.capitalizeWordsDescription(postValues.calendarDescription),
              shiftCalendarStartDate: this.datepipe.transform(postValues.startDate ,'yyyy-MM-dd'),
              shiftCalendarEndDate:this.datepipe.transform(postValues.endDate ,'yyyy-MM-dd'),
              shiftCalendarWorkdayStart: postValues.workDayStart,
              shiftCalendarWorkingdays: postValues.workingDays,
              shiftCalendarLeavedays: postValues.leaveDays,
              shiftCalendadefault : postValues.default,
              client: {
                clientId: postValues.client,
              },   
              organization : {organizationId: this.organizationId}          
              }

              console.log(shiftcalendarData);

             //  return
      this. payrollService.createShiftCalendar(shiftcalendarData).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);
          this.postForm.reset();     
          this.submitted = false;
          setTimeout(() => {
      //      this.finicalyearList();
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
        //    this.finicalyearList();
          }, redirectMsgTimeOut)
        }

      }, err =>
      {
       
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.message;
            // this.router.navigate(['/home/financialyear']);
          }, redirectMsgTimeOut)
      })
    }


  }

  
  getDayName(daynumber)
  {
    
    const filteredDays = this.days.filter(day => day.number === daynumber);
  return filteredDays.length > 0 ? filteredDays[0].name : undefined;
  

  }

  getcalendarview(rowdata){
      this.router.navigateByUrl('/home/shift-calendar-view/'+rowdata.shiftCalendarId);
  }

  
  updateform(editData)
  {
           this.shiftcalendarId = editData.shiftCalendarId;
    this.payrollService.getshiftcalendarbyId(editData.shiftCalendarId).subscribe((resultData:any) =>
    {
      this.resultData=resultData;
  
          this.postForm.patchValue({
            client :  this.resultData.client.clientId,
            calendarName : this.resultData.shiftCalendarName,
            calendarDescription :this.resultData.shiftCalendarDescription,
              startDate : this.resultData.shiftCalendarStartDate,
              endDate : this.resultData.shiftCalendarEndDate,
              workDayStart : this.resultData.shiftCalendarWorkdayStart,
              workingDays : this.resultData.shiftCalendarWorkingdays,
              leaveDays :  this.resultData.shiftCalendarLeavedays,
              default : this.resultData.shiftCalendadefault 
           });
  });
}

updatedeafult(data)
{
  this.payrollService.updateShiftcalendardefault(data.shiftCalendarId).subscribe( (result:any) =>
  {
    if(result.statusCode == 200)
    {
      this.success = true;
      this.sucess_msg = result.description;
      setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      this.searchSubmit();
     // this.finicalyearList();
    }
    else
    {
      this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
    }
  })
}

  updateStatus(id,status)
	{
    this.payrollService.updateShiftcalendarStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  
  /**** Global Variables *****/
  ucFirst(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
  /**** Global Variables *****/

  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'streamName':
          return compare(a.streamName, b.streamName, isAsc);

        default:
          return 0;
      }
    });
  }

  capitalizeWordsName(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
  
  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  
}
function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}