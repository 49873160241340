<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content headingDesing">
            {{ requirementId ? "Edit" : "Add" }} Requirement
          </span>
        </div>
        <div class="sub-header-buttons">
          <a
            routerLink="/home/requirement"
            mat-raised-button
            title="Back"
            class="ml-2"
          >
            Back
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="resourceform" (ngSubmit)="submitData()">
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Position <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="position"
                placeholder="Select Position"
                (selectionChange)="positiondata($event)"
              >
                <mat-option
                  *ngFor="let position of positionlistdata"
                  class="text-color"
                  [value]="position.positionId"
                  >{{ position.positionName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  position.touched ||
                  position.dirty ||
                  (submmited && resourceform.controls['position'].invalid)
                "
              >
                <span *ngIf="position.errors?.required" class="text-danger"
                  >Please Select Position</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Min Experience <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control form-control-solid mb-3 mb-lg-0 select-placeholder"
                formControlName="minExperience"
                (keyup)="keyUpmaxexp($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                (keypress)="noNumber($event)"

                maxlength="4"
                placeholder="Enter Min Experience"
              />
              <div *ngIf="minExperience.touched || minExperience.dirty">
                <span *ngIf="minExperience.errors?.pattern" class="text-danger"
                  >Please Enter Valid Min Experience</span
                >
              </div>
              <div
                *ngIf="
                  minExperience.touched ||
                  minExperience.dirty ||
                  (submmited && resourceform.controls['minExperience'].invalid)
                "
              >
                <span *ngIf="minExperience.errors?.required" class="text-danger"
                  >Please Enter Min Experience</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Max Experience <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control form-control-solid mb-3 mb-lg-0 select-placeholder"
                formControlName="maxExperience"
                (keypress)="noNumber($event)"
                (keyup)="keyUpmaxexp($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"maxlength="4"
                placeholder="Enter Max Experience"
              />
              <div *ngIf="maxExperience.touched || maxExperience.dirty">
                <span *ngIf="maxExperience.errors?.pattern" class="text-danger"
                  >Please Enter Valid Max Experience</span
                >
              </div>
              <div
                *ngIf="
                  maxExperience.touched ||
                  maxExperience.dirty ||
                  (submmited && resourceform.controls['maxExperience'].invalid)
                "
              >
                <span *ngIf="maxExperience.errors?.required" class="text-danger"
                  >Please Enter Max Experience</span
                >
              </div>
              <span *ngIf="this.maxExp == false" class="text-danger"
                >Maximum Experience Should Be Greater Than Minimum
                Experience</span
              >
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Location <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control form-control-solid mb-3 mb-lg-0 select-placeholder"
                formControlName="location"
                placeholder="Enter Location"
              />
              <div *ngIf="location.touched || location.dirty">
                <span *ngIf="location.errors?.pattern" class="text-danger"
                  >Please Enter Valid Location</span
                >
              </div>
              <div
                *ngIf="
                  location.touched ||
                  location.dirty ||
                  (submmited && resourceform.controls['location'].invalid)
                "
              >
                <span *ngIf="location.errors?.required" class="text-danger"
                  >Please Enter Location</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Max CTC <span class="star">*</span></label
              >
              <input
                type="text"
                class="form-control form-control-solid mb-3 mb-lg-0 select-placeholder"
                (keypress)="noNumber($event)"
                (keyup)="keyUpmaxexp($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                maxlength="7"
                formControlName="ctc"
                placeholder="Enter Max CTC"
              />
              <div *ngIf="ctc.touched || ctc.dirty">
                <span *ngIf="ctc.errors?.pattern" class="text-danger"
                  >Please Enter Valid Max CTC</span
                >
              </div>
              <div
                *ngIf="
                  ctc.touched ||
                  ctc.dirty ||
                  (submmited && resourceform.controls['ctc'].invalid)
                "
              >
                <span *ngIf="ctc.errors?.required" class="text-danger"
                  >Please Enter Max CTC</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Hiring For <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                (selectionChange)="listTypeValueid($event)"
                appearance="fill"
                formControlName="hiring"
                placeholder="Select hiring for"
              >
                <mat-option
                  *ngFor="let e of positionusertype"
                  class="text-color"
                  [value]="e.listTypeValueId"
                  >{{ e.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  hiring.touched ||
                  hiring.dirty ||
                  (submmited && resourceform.controls['hiring'].invalid)
                "
              >
                <span *ngIf="hiring.errors?.required" class="text-danger"
                  >Please Select Hiring For</span
                >
              </div>
            </div>
            <div class="form-group col-md-3" *ngIf="showclientname">
              <label class="form-control-label"
                >Client Name <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="client"
                placeholder="Select client name"
              >
                <mat-option
                  *ngFor="let client of clientlistdata"
                  class="text-color"
                  [value]="client.clientId"
                  >{{ client.clientName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  client.touched ||
                  client.dirty ||
                  (submmited && resourceform.controls['client'].invalid)
                "
              >
                <span *ngIf="client.errors?.required" class="text-danger"
                  >Please Select Client Name</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Job Type <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="jobType"
                placeholder="Select job Type"
              >
                <mat-option
                  *ngFor="let e of jobtype"
                  class="text-color"
                  [value]="e.listTypeValueId"
                  >{{ e.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  jobType.touched ||
                  jobType.dirty ||
                  (submmited && resourceform.controls['jobType'].invalid)
                "
              >
                <span *ngIf="jobType.errors?.required" class="text-danger"
                  >Please Select Job Type</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Notice Period <span class="star">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="noticePeriod"
                placeholder="Select Notice Period"
                [multiple]="true"
                style="    height: 43px !important;
    margin-top: -2px;"
              >
                <mat-option
                  *ngFor="let e of noticeperiod"
                  class="text-color"
                  [value]="e.listTypeValueId"
                  >{{ e.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                class="valid-msg"
                *ngIf="
                  noticePeriod.touched ||
                  noticePeriod.dirty ||
                  (submmited && resourceform.controls['noticePeriod'].invalid)
                "
              >
                <span *ngIf="noticePeriod.errors?.required" class="text-danger"
                  >Please Select Notice Period</span
                >
              </div>
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Expiry Date <span class="star">*</span></label
              >
              <input
                matInput
                [matDatepicker]="picker1"
                [min]="today"
                formControlName="expiryDate"
                class="form-control email-input select-placeholder"
                readonly="readonly"
                placeholder="Choose a date"
                style="height: 43px"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
                style="float: right; position: relative; bottom: 42px"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <div
                class="valid-msg"
                *ngIf="
                  expiryDate.touched ||
                  expiryDate.dirty ||
                  (submmited && resourceform.controls['expiryDate'].invalid)
                "
              >
                <span *ngIf="expiryDate.errors?.required" class="text-danger"
                  >Please Enter Expiry Date</span
                >
              </div>
            </div>

            <!-- <div class="form-group col-md-3">
                        <label class="form-control-label">Job Description Attachment </label>
                        <input type="file" class="form-control  form-control-solid mb-3 mb-lg-0 select-placeholder" (change)="getFileDetails($event)" formControlName="jobDescriptionAttachment" placeholder="" accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple>
                                           
                        <div *ngIf="userdata.jobDescriptionAttachment != undefined"><br>
                            <a href="{{userdata.jobDescriptionAttachment.fileDownloadUrl}}">
                                <mat-icon class=" download-icon">file_download</mat-icon>
                            </a>
                            <label style="margin-top: 15px" >{{userdata.jobDescriptionAttachment.sourceFileName}}</label>
                        </div> 
                       
                    </div> -->

            <div class="form-group col-md-3">
              <!-- <div formArrayName="jobDescriptions"> -->

              <!-- <div *ngFor="let group of getFormControls?.controls; let i = index" [formGroupName]="i"> -->
              <!-- <label class="form-control-label">Job Description Attachment</label> -->
              <!-- <input type="file" class="form-control  form-control-solid mb-3 mb-lg-0 select-placeholder" (change)="getFileDetails($event)" formControlName="jobDescriptionAttachment" placeholder="" accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple> -->

              <!--                         
                        <label class="file-label">
                            <div class="row">
                                <div class="col-md-2">
                                    <mat-icon class="material-symbols-outlined">
                                        attach_file_add
                                       
                                        </mat-icon>
                                </div>
                                <div class="col-md-10" style="margin-left: -6px;" *ngIf="userdata.jobDescriptionAttachment == undefined">
    
                                    <span *ngIf="fileName != undefined">{{fileName}}</span>
                                    <span  *ngIf="fileName == undefined">{{'Attach File'}}</span>
                                </div>
                                <div class="col-md-10" style="margin-left: -6px;" *ngIf="userdata.jobDescriptionAttachment != undefined">
                                    <span *ngIf="userdata.jobDescriptionAttachment.sourceFileName != undefined">{{userdata.jobDescriptionAttachment.sourceFileName}}</span>
                                    <span  *ngIf="userdata.jobDescriptionAttachment.sourceFileName == undefined">{{'Attach File' }}</span>
                                    <div>
                                        <a class="btn btn-primary btn-sm" href="{{userdata.jobDescriptionAttachment.fileDownloadUrl}}">
                                            Download 
                                            </a>
                                           
                                    </div>
                                  
                                </div>
                                </div>
                                <input type="file"                                                        
                                formControlName="jobDescriptionAttachment"
                                placeholder="" accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple
                                class="file-input form-control email-input select-placeholder" (change)="getFileDetails($event)">  
                          </label> -->
              <!-- <label class="file-label form-control-label"
                >Job Description Attachment
                <div class="row" style="width: 135% !important">
                  <div
                    class="col-md-10"
                    style="margin-left: -6px; position: relative; top: 6px"
                  >
                    <input
                      type="file"
                      style="height: 41px"
                      formControlName="jobDescriptionAttachment"
                      placeholder=""
                      accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf"
                      [ngClass]="{ 'is-invalid': submmited }"
                      class="file-input form-control email-input select-placeholder"
                      (change)="getFileDetails($event)"
                    />
                  </div>

                  <div
                    class="col-md-10"
                    style="
                      margin-left: -6px;
                      position: relative;
                      top: 11px;
                      left: 5px;
                    "
                    *ngIf="userdata.jobDescriptionAttachment != undefined"
                  >
                    
                    <div>
                      <a
                        class="btn btn-primary btn-sm"
                        href="{{
                          userdata.jobDescriptionAttachment.fileDownloadUrl
                        }}"
                        download
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </label> -->

              <!-- <label class="file-label form-control-label">
                CV Attachment
                <div class="row" style="width: 135% !important">
                  <div
                    class="col-md-10"
                    style="margin-left: -6px; position: relative; top: 6px"
                  >
                    <input
                      type="file"
                      style="height: 41px"
                      formControlName="jobDescriptionAttachment"
                      placeholder=""
                      accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf"
                      [ngClass]="{ 'is-invalid': submmited }"
                      class="file-input form-control email-input select-placeholder"
                      (change)="getFileDetails($event)"
                    />
                  </div>

                  <div
                    class="col-md-10"
                    style="
                      margin-left: -6px;
                      position: relative;
                      top: 11px;
                      left: 5px;
                    "
                    *ngIf="userdata.jobDescriptionAttachment != undefined"
                  >
                    
                    <div  *ngIf="!newFileSelected">
                      <a
                        class="btn btn-primary btn-sm"
                        href="{{
                          userdata.jobDescriptionAttachment.fileDownloadUrl
                        }}"
                        download
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </label>
              
              <label
                *ngIf="!newFileSelected  && resourceform.get('jobDescriptionAttachment').value"
                class="col-form-label"
                style="font-family: Mulish; margin-left: 1%;"
              >
                Current Attachment: {{ resourceform.get('jobDescriptionAttachment').value }}
              </label> -->

              <label class="file-label form-control-label">
                Job Description Attachment
                <div class="row" style="width: 135% !important">
                  <div class="col-md-10" style="margin-left: -6px; position: relative; top: 6px">
                    <input
                      type="file"
                      style="height: 41px"
                      formControlName="resourcecvattachment"
                      placeholder=""
                      accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf"
                      [ngClass]="{ 'is-invalid': submmited }"class="file-input form-control email-input select-placeholder"
                      (change)="getFileDetails($event)"
                    />
                  </div>
              
                  <div
                    class="col-md-10"
                    style="margin-left: -6px; position: relative; top: 11px; left: 5px;"
                    *ngIf="!newFileSelected && userdata.jobDescriptionAttachment && userdata.jobDescriptionAttachment.jobDescriptionId"
                  >
                    <div>
                      <a
                      class="btn btn-primary btn-sm"
                      href="{{
                        userdata.jobDescriptionAttachment.fileDownloadUrl
                      }}"
                      download
                    >
                      Download File
                    </a>
                    </div>
                  </div>
                </div>
              </label>
              
              <label
                *ngIf="!newFileSelected  && resourceform.get('jobDescriptionAttachment').value"
                class="col-form-label"
                style="    font-family: Mulish;
                margin-left: -1%;
                argin-top: -2px;
                font-family: 'Mulish', sans-serif;
                font-size: 15px;
                font-weight: 660;"
              >
                Current Attachment: {{ resourceform.get('jobDescriptionAttachment').value }}
              </label>

              <!-- </label> -->

              <!-- </div> -->
              <!-- </div> -->
              <!-- </div> -->

              <!-- <div *ngIf="userdata.jobDescriptionAttachment != undefined"><br>
                            <a class="btn btn-primary btn-sm" href="{{userdata.jobDescriptionAttachment.fileDownloadUrl}}">
                            Download 
                            </a>
                        </div>  -->

              <!-- <div class="valid-msg" *ngIf="(jobDescriptionAttachment.invalid && jobDescriptionAttachment.touched) || jobDescriptionAttachment.dirty || submitted">
                            <span *ngIf="jobDescriptionAttachment.errors?.required" class="text-danger">Please enter job description </span>
                        </div>  -->
            </div>

            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Job Description <span class="star">*</span></label
              >
              <textarea
                type="text"
                rows="1"
                placeholder="Job Description"
                style="
                  font-size: 15px;
                  min-height: 43px;
                  border: none;
                  padding: 3px 15px;
                  overflow-y: auto; 
                 
                
                "
                class="form-control email-input select-placeholder"
                formControlName="jobDescription"
              ></textarea>
              <div
                *ngIf="
                  jobDescription.touched ||
                  jobDescription.dirty ||
                  (submmited && resourceform.controls['jobDescription'].invalid)
                "
              >
                <span
                  *ngIf="jobDescription.errors?.required"
                  class="text-danger"
                  >Please Enter Job Description</span
                >
              </div>
              <div *ngIf="jobDescription.touched || jobDescription.dirty">
                <span *ngIf="jobDescription.errors?.pattern" class="text-danger"
                  >Please Enter Valid Job Description</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-5" style="margin-left: 2px">
              <label class="form-control-label"
                >Skills<span class="text-danger">*</span></label
              >
              <mat-chip-list
                #chipList
                aria-label="Fruit selection"
                class="form-control email-input select-placeholder custom-scrollbar"
                style="
      overflow-y: auto;
    width: 120% !important;
    height: fit-content;
                 
                "
              >
                <mat-chip
                  *ngFor="let fruit of skillsarray"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(fruit)"
                >
                  <span class="skill-input">{{ fruit.name }}</span>
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Enter Skills..."
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)"
                  class="chip-input border-none"
                  formControlName="skills"
                />
              </mat-chip-list>
              <div
                *ngIf="
                  (skills.invalid && skills.touched) ||
                  skills.dirty ||
                  submmited
                "
              >
                <span *ngIf="skillsarray.length == 0" class="text-danger"
                  >Please Enter Skills</span
                >
              </div>
              <!-- <div *ngIf="skills.touched ||skills.dirty||( && skillsarray.length==0)">
                            <span *ngIf="skillsarray.length==0" class="text-danger">Please Enter Skills</span>
                        </div> -->

              <!-- <div *ngIf="(skills.touched || skills.dirty || (submmited  && resourceform.controls['skills'].invalid)) && isSkillValid">
                            <span *ngIf="skills.errors?.required" class="text-danger">Please Enter skills</span>
                        </div>
                        
                        <div *ngIf="(skills.touched || skills.dirty) ">
                            <span *ngIf="skills.errors?.pattern" class="text-danger">Please Enter Valid Skills</span>   
                        </div> -->
              <!-- <mat-error *ngIf="resourceform.get('skills') && submmited">
                            {{ projectDescriptionError() }}
                          </mat-error> -->

              <!-- <div *ngIf="(resourceform.get('skills').touched || resourceform.get('skills').dirty || (submmited && resourceform.get('skills').invalid))">
                            <span *ngIf="resourceform.get('skills').hasError('required')" class="text-danger">Please Enter skills</span>
                          </div> -->
              <!-- <div *ngIf="(skills.touched || skills.dirty) ">
                            <span *ngIf="skills.errors?.pattern" class="text-danger">Please Enter Valid Skills</span>   
                        </div> -->

              <!-- <div *ngIf="skills.touched|| skills.dirty|| (submmited && resourceform.controls['skills'].invalid)">
                            <small *ngIf="skills.errors?.required" class="text-danger">Please enter skills</small>
                     </div> -->
            </div>
          </div>
          <!-- <div class="row">
                    <div class="col-md-12 text-right">
                        <a routerLink="/home/requirement" mat-raised-button title="Cancel" class="btn btn-light btn-sm mr-2">
                            Cancel
                        </a>
                        <button type="submit" class="submit action-button btn btn-primary btn-sm" [disabled]="buttonDisabled">Save</button>
                    </div>
                </div>     -->
          <div align="end">
            <button
              mat-raised-button
              type="button"
              class="cancel-button m-1"
              (click)="cancel()"
              [disabled]="buttonDisabled"
              style="
              background: none;
              color: var(--Red-Color, #FF5D5D);
              border: 1px solid #ddd;
              font-size: 16px;
              font-weight: 550;
              margin-left: 5px;
              height: 35px;
            "
            >
              Cancel
            </button>
            <button
              mat-raised-button
              type="submit"
              style="height: 34px"
              class="create-button m-1"
              [disabled]="buttonDisabled"
            >
              Save
            </button>
          </div>
          <!-- </div> -->
          <!-- </div> -->
        </form>
      </div>
    </div>
  </div>
</div>


<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>