import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Calendar } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { PayrollService } from 'src/app/providers/payroll.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-cleint-employee-annexue-list',
  templateUrl: './cleint-employee-annexue-list.component.html',
  styleUrls: ['./cleint-employee-annexue-list.component.scss']
})
export class CleintEmployeeAnnexueListComponent implements OnInit {

  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any = [];
  selected :true;
 
  loader:any= false;
  backdrop:any= false;
  userdata: any;
  organizationId: any;
  searchForm:FormGroup;
  sortedData: any;
  p:number = 1;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  dateFormat: any;
  totalmontlyincome: Number = 0;
  totalannualincome: Number = 0;
  empList: any = [];
  clientList: any= [];
  employee: any;
  employeeId: any;
  employeeNumber: any;
  maxstartdate: Date;
  constructor(  private formBuilder: FormBuilder,
    private router : Router,
    private payrollService: PayrollService, private datepipe : DatePipe ,
    private ActivatedRoute:ActivatedRoute,@Inject(AppComponent) public AppData: any ) { }

  ngOnInit(): void {
      this.noRecordsFound = true;
    this.searchForm = this.formBuilder.group({
      employee: [null], // Initialize with null
      startDateSearch:[null],
      endDateSearch:[null] ,
      client:[null]
    });

    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    // this.dateFormat = this.AppData.dateFormat;
    let userId = this.userdata.userId;
     this.organizationId = this.userdata.organization.organizationId;

       
     this.payrollService.getClientNameList(this.organizationId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log("clientList",this.clientList);
    })  
  }

  getclientEmployees(){
    this.empList.length = 0;
    console.log(this.searchForm.value.client);
    this.searchForm.value.employee = null;
    this.searchForm.patchValue({
      employee:null
    })
    if(this.searchForm.value.client != null && this.searchForm.value.client != "null"){
      this.payrollService.getemployeeclient(this.searchForm.value.client).subscribe(
      (ClientEmployees: any) => {
         if(ClientEmployees.length != undefined)
         {
          this.empList = ClientEmployees;
         }
         
        
      });
    }
  }

  
  empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      // this.payrollService.getEmployeeNameList(employeenumber, this.organizationId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }
      
      // })
      this.payrollService.getClienEmployeeList( this.searchForm.value.employee , this.searchForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode)
        {
          
        }else{
          this.empList = result;
           
        }
     
     })
    }
    
  }
  empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
     this.employeeNumber = emp.employeeNumber;
      this.searchForm.patchValue({
        employee: this.employee 
     });

     this.payrollService.getannexueversionbyemployee(this.employeeId).subscribe((result: any) => {
      if(result.statusCode)
      {
        
      }else{
        var date = new Date(result.effectiveToDate)

        // Add a day
        date.setDate(date.getDate() + 1)
        this.maxstartdate = date ; 
      }
   
   })
  }


  searchSubmit(){
    this.p=1;
    this.resultData = [];
    this.noRecordsFound = false;
    console.log('search submit');
    console.log("this.searchForm..",this.searchForm.value);
    // let employee = this.searchForm.value.employee;
    // console.log('employee', employee);
    let client = this.searchForm.value.client;
    console.log("client", client);
   
    let startDateSearch = this.searchForm.value.startDateSearch;
    let endDateSearch = this.searchForm.value.endDateSearch;
    console.log('startDateSearch', startDateSearch); 
    console.log('endDateSearch', endDateSearch);

    if((this.searchForm.value.employee=== '' || this.searchForm.value.employee === null) && (this.searchForm.value.client=== '' || this.searchForm.value.client === null)){
      alert('Please select employee or client');
      return;
    }
    if((!this.searchForm.value.employee || this.searchForm.value.employee == 'null') && (this.searchForm.value.client=== '' || this.searchForm.value.client === null) &&(!startDateSearch || startDateSearch == 'null') &&  (!endDateSearch || endDateSearch == 'null')){
      alert('Please select employee or client.');
      return;
    }
    if(((startDateSearch  &&  !endDateSearch ) || (!startDateSearch  &&  endDateSearch ) )&&  (this.searchForm.value.employee || this.searchForm.value.employee != 'null')){
      alert('Please select startDate and endate.');
      return;
    }
     
      // if ((!startDateSearch || startDateSearch === 'null') && (endDateSearch || endDateSearch != 'null') || (startDateSearch || startDateSearch != 'null') && (!endDateSearch || endDateSearch === 'null'))
      // {
      //   alert('Please select startDate and endate.');
      //   return;
      // }
      if((client != null || client!= 'null') && (this.searchForm.value.employee=== '' || this.searchForm.value.employee === null) && (startDateSearch == null && endDateSearch == null))
      {
        console.log("only client");
        this.loader=true;
        this.backdrop=true;
        this.payrollService.getemployeeannexureClient(client).subscribe(
        (resultData:any) =>{
          this.loader=false;
          this.backdrop=false;
          this.noRecordsFound = false;
          this.resultData = resultData;
          if(this.resultData.length == 0 || this.resultData.length == undefined){
            this.noRecordsFound = true;
            this.resultData.length = 0;
          }else{
          //  alert("has data")
          this.noRecordsFound = false;
            this.resultData.forEach(element => {
              this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
              this.totalannualincome = this.totalannualincome + element.annualIncome;
            });
          }
          if( resultData.statusCode == 204){
            this.noRecordsFound = true;
            this.totalmontlyincome =  0;
            this.totalannualincome =0;
          }
        })
      }

   else  if((startDateSearch == null && endDateSearch == null) && this.searchForm.value.employee != undefined  &&  this.searchForm.value.employee != null && this.searchForm.value.employee != 'null')
    // if( (employee && employee != 'null') && (startDateSearch && startDateSearch != 'null')&& (endDateSearch && endDateSearch != 'null'))
    {
      console.log("without date");
      this.loader=true;
      this.backdrop=true;
      this.payrollService.getemployeeannexureByEmployee(this.employeeId).subscribe(
      (resultData:any) =>{
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;
        this.resultData = resultData;
        if(this.resultData.length == 0 || this.resultData.length == undefined){
          this.nodatafound = true;
          this.resultData.length = 0;
        }else{
          this.resultData.forEach(element => {
            this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
            this.totalannualincome = this.totalannualincome + element.annualIncome;
          });
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }
      })
    }
    else if ((startDateSearch != null && endDateSearch != null) && (this.employeeId) && (client != null || client!= 'null') ){
      console.log("all");
      let startDate = this.datepipe.transform(startDateSearch, 'yyyy-MM-dd');
      let endDate = this.datepipe.transform(endDateSearch, 'yyyy-MM-dd');
      this.loader=true;
      this.backdrop=true;
      this.payrollService.getemployeeannexureByEmployeeAndFromdateAndTodate(this.employeeId,startDate,endDate).subscribe(
        (resultData:any) =>{
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;
        this.resultData = resultData;
        console.log(" this.resultData", this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined){
          this.nodatafound = true;
          this.resultData.length = 0;
        }else{
          this.resultData.forEach(element => {
            this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
            this.totalannualincome = this.totalannualincome + element.annualIncome;
          });
        }
        if(resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }
      })
    }
    else if ((startDateSearch != null && endDateSearch != null) && (this.searchForm.value.client || this.searchForm.value.client != 'null')){
      console.log("all is client");
      let startDate = this.datepipe.transform(startDateSearch, 'yyyy-MM-dd');
      let endDate = this.datepipe.transform(endDateSearch, 'yyyy-MM-dd');
      this.loader=true;
      this.backdrop=true;
      this.payrollService.getemployeeannexureByclientAndFromdateAndTodate(this.searchForm.value.client,startDate,endDate).subscribe(
        (resultData:any) =>{
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;
        this.resultData = resultData;
        console.log(" this.resultData", this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined){
          this.nodatafound = true;
          this.resultData.length = 0;
        }else{
          this.resultData.forEach(element => {
            this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
            this.totalannualincome = this.totalannualincome + element.annualIncome;
          });
        }
        if(resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }
      })
    }
  }

  get serachvalidate() { return this.searchForm.controls; }

  updateform(row){
    this.router.navigateByUrl(`/home/client-employee-annexue/${row.annexureHeaderId}`)
  }


  updateStatus(annexue ,status)
  {

  }
  pageChages()
{
  this.p=1;
}

clearTable()
{
  
  this.resultData = [];
  this.noRecordsFound = false;
  console.log('clear')
  this.searchForm.clearValidators;
  // this.payrollService.get().subscribe((elementslistData:any) =>
  // {
  //   console.log('elementslistData',elementslistData)
  //   this.resultData = elementslistData;
  //   console.log(this.resultData);
  //   if(this.resultData.length == 0)
  //   {this.noRecordsFound = true;
  //     this.nodatafound = true;
  //     console.log("nodata");
  //   }
  //   if(elementslistData.statusCode == 204){
  //     this.noRecordsFound = true;
  //   }
  // },
  // (error) => {
  //   this.nodatafound = true;
  //   console.log("error")
  //   if(error.status == 404){
  //     this.nodatafound = true;
  //     console.log("404")
  //   }
  // }
  // )
}


}
