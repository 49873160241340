<div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Dashboard</span>
            </div>
            <div class="sub-header-buttons">
                <!-- <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                    Add Employee
                </a> -->
            </div>
        </div>
    </div>
</div>
<div class="common-content">
    <div class="card-new p-0 mb-5">
        <div class="dashboard-cards">
            <div class="row db-card">
                <div class="col-md-4 mb-4">
                    <div class="bg-light-danger left-section p-4 mt-1">
                        <div class="top-row">
                            <mat-icon class="icon-danger">account_balance</mat-icon>
                            <span class="card-name1 text-danger">Total Organizations <h4 class="count-card text-danger">{{resultData.totalOrganization}}</h4></span>
                        </div>
                        <div class="bottom-row text-danger">
                            <div class="row">
                                <div class="col-6">
                                    <span class="card-name">Active : {{resultData.activeOrganization }} </span>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="card-name">Inactive : {{resultData.inActiveOrganization }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Graph and cards End -->

        <!-- Todo Start -->
        <!--<div class="row mt-3">
             <div class="col-md-5">
                <div class="card">
                    <div class="card-body">
                        <div class="todo-list1">
                            <span class="to-do-header">Employee Birthday List</span>
                        </div>
                        <div *ngIf="resultData.upcomingBirthday?.length > 0 ">
                            <div class="todo-list" *ngFor="let row of resultData.upcomingBirthday">
                                <div class="row">

                                    <div class="col-md-12 py-1">
                                        <span class="todo-title"> {{ucFirst(row.firstName)}} {{row.middleName}} {{ucFirst(row.lastName)}} <h6 class="bg-light-danger text-danger">#{{row.employeeNumber}}</h6></span>
                                        <span class="todo-sub-title"><mat-icon>cake</mat-icon> {{row.dateOfBirth | date: 'dd-MMM-yyyy'}}  <h6 class="emp-contact">	<mat-icon> call</mat-icon> {{row.mobileNumber}}</h6></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3" *ngIf="resultData.upcomingBirthday?.length == 0 ">
                            No Data Found.
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-body">
                        <div class="todo-list1">
                            <span class="to-do-header">Employee Work Anniversary List</span>
                        </div>
                        <div *ngIf="resultData.upcomingWorkAnniversery?.length > 0 ">
                            <div class="todo-list" *ngFor="let row of resultData.upcomingWorkAnniversery">
                                <div class="row">
                                    <div class="col-md-12 py-1">
                                        <span class="todo-title"> {{ucFirst(row.firstName)}} {{row.middleName}} {{ucFirst(row.lastName)}} <h6 class="bg-light-danger text-danger"> #{{row.employeeNumber}}</h6></span>
                                        <span class="todo-sub-title"><mat-icon>business_center</mat-icon> {{row.dateOfJoining | date: 'dd-MMM-yyyy'}} <h6 class="emp-contact">	<mat-icon> call</mat-icon> {{row.mobileNumber}}</h6></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3" *ngIf="resultData.upcomingWorkAnniversery?.length == 0 ">
                            No Data Found.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="piechart-bg card">
                    <div class="row p-0 mt-3 mr-3 mb-3">
                        <div class="col-md-8">
                            <h2 class="resource-status">Resources Status</h2>
                        </div>
                        <div class="col-md-4">
                            <h2>Interview Status</h2>
                        </div>

                        <div class="col-md-8">
                            <div class="piechart-card">
                                <div class="card-body piechart-card-body">

                                    <div class="chart">
                                        <canvas *ngIf="this.ProfileShared !=0" baseChart style="color: rgb(7, 7, 7);width: 70%!important;height: 70%!important;" [data]="pieChartData_user" [type]="pieChartType_user" [options]="pieChartOptions_user" [plugins]="pieChartPlugins_user">
                                        </canvas>
                                        <p *ngIf="this.ProfileShared ==0">No Records Found</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div>
                                <div class="table-responsive">
                                    <table class="interviewed-status-table">
                                        <thead>
                                            <tr>
                                                <th class="table-head1">Status</th>
                                                <th class="text-center table-head2">Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="table-data1 text-success">Selected</td>
                                                <td class="text-center table-data2 text-success">{{admindashboard.selected}}</td>
                                            </tr>
                                            <tr>
                                                <td class="table-data1 text-danger">Rejected</td>
                                                <td class="text-center table-data2 text-danger">{{admindashboard.rejected}}</td>
                                            </tr>
                                            <tr>
                                                <td class="table-data1" style="color:#ff9100">On Hold</td>
                                                <td class="text-center table-data2" style="color:#ff9100">{{admindashboard.onHold}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Todo End -->
    </div>

</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>