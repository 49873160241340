import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ExcelService } from '../services/excel.service';

@Component({
  selector: 'app-employee-payroll-list',
  templateUrl: './employee-payroll-list.component.html',
  styleUrls: ['./employee-payroll-list.component.scss']
})
export class EmployeePayrollListComponent implements OnInit {
  isProcessing = false;
  showtable:boolean=false;
  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =true;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=5;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: number;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  employeeAll: boolean;
  firstname: any;
  empnumber: any;
  employeelistsub: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  empCode: boolean;
  inputValue: any;
  selectedemployeeId: any;
  selectedemployee: any;

  
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.orgId);

    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null],
      period:[null],
    })



    if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });
  }

  if(this.clientId){
    this.settingsService.getFinicalyearActiveListClient(this.clientId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });
  }



    if(this.orgId){
    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }

  if(this.clientId){
    this.settingsService.getClientdetails(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }
  }

  
  // empSearch(event)
  // { 
  //   this.empList=[];
  //   this.empCode=false;
  //   console.log(event.target.value);
  //   this.userId=event.target.value;
  //   console.log(this.userId);
  //   // Employee List Api
  //     // this.empList.length == 0;
  //     if(event.target.value.length >= 2)
  //     {
  //       if(this.employeelistsub)
  //       {
  //         this.employeelistsub.unsubscribe();
  //       }
  //       this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
  //         .pipe(
  //           takeUntil(this.unsubscribe$), // Cancel previous request
  //           switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
  //         )
  //         .subscribe((result: any) => {
  //           this.empList.length = 0;
  
  //           if (result.statusCode) {
  //             console.log(result.statusCode);
  //             this.empList.length = 0;
  //           } else {
  //             this.empList = result;
  //           }
  //         });
  //   }
  // }


  empSearch(event){
    console.log(event.target.value)
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement) // Cast the event target as an input element
  this. inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
   console.log(this.inputValue)
    // Clear the employee list and reset flags if input is empty
    if (this.inputValue === '') {
      this.empList = [];
      this.empCode = false;
      console.log('Input is empty, employee list cleared');
      return;
    }
  
    // Check if input length is sufficient for the search
    if (this.inputValue.length >= 2) {
      // Unsubscribe from the previous API request if necessary
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
  
      // Reset and create a new Subject for cancellation
      this.unsubscribe$ = new Subject<void>();


  if(this.orgId){
      this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;

            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }

      if(this.clientId){
        this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true; // Set the flag to indicate an error state
              }
              console.error('An error occurred:', err);
              return EMPTY; // Return EMPTY to propagate completion downstream
            })
          )
          .subscribe(
            (result: any) => {
              if (result.statusCode === 204) {
                console.log('No employees found (status code 204)');
                this.empList = [];
                this.empCode = true; // Indicate no results found
              } else if (result.statusCode) {
                console.log('Other status code:', result.statusCode);
                this.empList = [];
                this.empCode = true;
              } else {
                // Handle the case where result is an array or an object
                this.empList = Array.isArray(result) ? result : Object.values(result);
                this.empCode = false;
  
              }
            },
            (err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true;
              }
              console.error('An error occurred:', err);
            }
          );
        }
    }
  }
   
  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log('empSelect', emp);
    this.employeeAll = false;
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    // this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;
    this.employee=this.empnumber +"-" + this.firstname;
    // if (this.employeeLeaveId) {
      this.filterForm.patchValue({
        employeecode: this.employee
      });
   
  
  }
  

  
  empAllSelect() {
    this.employeeAll = true;
  }

  addpayroll()
  {
   
    this.router.navigateByUrl('/home/employee-payroll');
  }
  searchSubmit()
  {

    if((this.filterForm.value.employeecode !== undefined && this.filterForm.value.employeecode !== null && this.filterForm.value.employeecode !== '') ){
      if((this.employee != this.filterForm.value.employeecode ) || (this.employeeId == null)){
        window.alert('please select valid employee from dropdown.');
        // this.loader=false;
        // this.showtable=false;
        // this.isProcessing=false;
        // this.filterForm.reset();

        return;
      }
    }
    this.showtable=true;
    this.loader=true;

    this.isProcessing=true;
    console.log("submit");
    this.req=false;

  
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   // alert('Please enter employee number');
    //   return;
    // }
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null'))
    {
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = false;
      alert("Please Enter Employee number or Financial Year");
      // this.req=true;
      this.isProcessing=false;
      this.loader=false;
      this.showtable=false;

      // this.getallpayroll();
    }
    // if(((this.filterForm.value.employeecode!=''||this.filterForm.value.employeecode!=null)&&this.filterForm.value.financialyear==null)||(this.filterForm.value.employeecode==''||this.filterForm.value.employeecode==null)&&this.filterForm.value.financialyear!=null)
    // {
    //   this.req=false;
    // }
    console.log(this.filterForm.value);
    if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&this.filterForm.value.financialyear!=null&&this.filterForm.value.period!=null)
    {
      console.log("both");
      this.payrollservice.getpayrollEmployeecodeandperiod(this.empnumber,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.calucatetotal();
        console.log(this.resultData);
        console.log(this.resultData.length);

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }
    else if((this.filterForm.value.employeecode!= '' && this.filterForm.value.employeecode != null && this.filterForm.value.employeecode!='null' ) && (this.filterForm.value.financialyear!=null && this.filterForm.value.financialyear != 'null') && (this.filterForm.value.period==null||this.filterForm.value.period=='null'))
    {
      console.log(this.filterForm.value.employeecode)
      console.log("Finanical year only and employee code");
      this.payrollservice.getpayrollbyfinicalyearEmp(this.empnumber,this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        this.calucatetotal();
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }
    
    else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&this.filterForm.value.financialyear!=null&&this.filterForm.value.period!=null)
    {
      console.log("finatical and period");
      this.payrollservice.getpayrollperiod(this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }
   
    else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear==null||this.filterForm.value.financialyear=='null')&&this.filterForm.value.period==null)
    {
      console.log("Employeecode only");
      this.payrollservice.getpayrollbyEmployeecode(this.empnumber).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;

        this.resultData = resultData;
        console.log(this.resultData);
         this.calucatetotal();
      


        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }
    else if((this.filterForm.value.employeecode === '' || this.filterForm.value.employeecode === null)&&(this.filterForm.value.financialyear !=null && this.filterForm.value.financialyear!='null')&&  (this.filterForm.value.period==null|| this.filterForm.value.period === 'null'))
    {
      console.log("finical year only");
      this.payrollservice.getpayrollbyfinicalyear(this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;

        this.resultData = resultData;
        console.log(this.resultData);
         this.calucatetotal();
      


        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        this.loader=false;
      })
    }
  }

  getallpayroll()
  {
    this.payrollservice.getpayrollbyorg(this.orgId).subscribe((resultData:any) =>
    {
      this.noRecordsFound = false;

      this.resultData = resultData;
      console.log(this.resultData);
       this.calucatetotal();
    


      if(this.resultData.length == 0 || this.resultData.length == undefined)
      {
        this.noRecordsFound = true;
      }
      if( resultData.statusCode == 204){
        this.noRecordsFound = true;
      }
    })
  }

  calucatetotal()
  {
    this.totalctc =  0;
    this.totalamount =  0;
    this.totalEarningYtd =  0;
    this.totalDedutionYtd =  0;
    if(this.resultData.length == undefined){
      this.noRecordsFound = true;
    }
    else{
      this.resultData.forEach(element => {
        console.log("element.employeeAnnexure.ctc" + element.employeeAnnexure.annexureCtc)
         let ctc =this.showmontlyctc(element.employeeAnnexure.annexureCtc);
         console.log("ctc" + ctc)
        this.totalctc += Number(ctc);
         this.totalctc.toFixed(2);
        this.totalamount += element.totalAmount;
        this.totalEarningYtd +=   element.totalEarningYtd;
        this.totalDedutionYtd +=  element.totalDedutionYtd;
      });
    }
  }

  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear()
  {
    console.log("Select");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    this.filterForm.get('financialyear').value;
     this.chooseFinId=this.filterForm.get('financialyear').value;
    console.log(this.chooseFinId);
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
    });
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.ratingId=row.employeerattingId;
    console.log(this.ratingId);
    // this.router.navigateByUrl('/home/edit-employee-rating/',row.employeerattingId);
    this.router.navigate(['/home/view-employee-payroll/', this.ratingId])
  }

  showmontlyctc(ctcamount){

    ctcamount = ctcamount / 12 
   return  ctcamount.toFixed(2);
  }

  resetForms() {
     this.filterForm.reset();
     this.showtable=false;
     this.resultData=[];
     this.p =1;
     this.noRecordsFound = true;
     this.empList=[];
     this.empCode = false; 
     this.finPeriodList=[];

     
     

 }

 noEmployeeFound() {
  this.selectedemployeeId = undefined;
  this.selectedemployeeId = undefined; // Set selectedemployeeId to null
  this.employeeId = undefined;
  this.empList.length = 0;
  this.employee = undefined;
  this.empnumber = undefined;
  this.selectedemployee = undefined;
  this.selectedemployeeId = undefined;
  this.empList = []; // Clear the employee list
  this.empCode = false; 

}
 

}
