import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { responseMsgTimeOut } from 'src/app/providers/properties';
import Swal from 'sweetalert2';
import { AssignEmployeeErrorComponent } from '../assign-employee-error/assign-employee-error.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { AssignEmployeeImportComponentComponent } from '../assign-employee-import-component/assign-employee-import-component.component';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/providers/header.service';
import {
  EMPTY,
  ReplaySubject,
  Subject,
  catchError,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { nonZeroHoursMinutesValidator } from '../non-zero';
import { i, index } from 'mathjs';
import { MatSelect } from '@angular/material/select';
// import { GlobalserviceService } from '../providers/globalservice.service';
// import { MatDialog } from '@angular/material';
// import { responseMsgTimeOut} from '../../app/providers/properties';
// import { ShowErrorEmployeeComponent } from '../show-error-employee/show-error-employee.component';
// import { AssignEmployeeComponent } from '../my-components/approver-list/assign-employee/assign-employee.component';
// import { AssignEmployeeImportComponent } from '../assign-employee-import/assign-employee-import.component';

@Component({
  selector: 'app-assign-employee-task',
  templateUrl: './assign-employee-task.component.html',
  styleUrls: ['./assign-employee-task.component.scss'],
})
export class AssignEmployeeTaskComponent implements OnInit {
  @ViewChild('picker1') startDatePicker!: MatDatepicker<any>;
  @ViewChild('picker2') endDatePicker!: MatDatepicker<any>;
  @ViewChild('picker3') startDatePicker1!: MatDatepicker<any>;
  @ViewChild('picker4') endDatePicker2!: MatDatepicker<any>;
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
  public employeeProjectsTasks: any = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  selectedEmployeeSearch: any;
  // selectedEmployeeSearch = new FormControl('');

  selectedemployee: any;

  @ViewChild('inputElementTask', { static: false })
  inputElementTask!: ElementRef;
  public employeeProjects: any = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelectTask', { static: true }) singleSelectTask: MatSelect;
  protected _onDestroyTask = new Subject<void>();
  selectedEmployeeSearchTask: any = '';
  selectedemployeeTask: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  assignformbyemployee: FormGroup;
  assignformbytask: FormGroup;
  payload: any;
  submit: boolean = false;
  control: FormArray;
  mode: boolean;
  touchedRows: any;
  touchedRows1: any;
  projectList = [];
  taskList: any[] = [];
  taskListEmployee: any;
  min: Date;
  max: Date;
  minEmp: Date;
  maxEmp: Date;
  startDate_disable: boolean = true;
  employeeSelected: boolean = false;
  endDate_disable: boolean = true;
  minStart: Date;
  projectId: any;
  employeeNotFound: boolean = false;
  employeeNotFoundByAssignEmployee: boolean = false;
  selectedProjectId: number;
  taskId: any;
  // employeeProjects: any[] = [];
  // employeeProjectsTasks: any[] = [];
  projectlist: any[] = [];
  selectedvalue: string;
  employeeId: string = '';
  employeeNumber: any;
  employee: any;
  employee_by_task: boolean = false;
  task_by_employee: boolean = false;
  errorMessage: boolean = false;
  submitted2: boolean = false;
  submitted1: boolean = false;
  today: any = new Date();
  employeelastdate: any;
  projectIdByEmployee: number;
  EmpId: any;
  error: any = false;
  submitClicked: boolean = false;
  submitClickedEmployee: boolean = false;
  errormessagejson: any;
  importerror: boolean;
  success = false;
  sucess_msg: any;
  backdrop: any = false;
  isSubmitting: boolean = true;
  isSubmittingAssignByEmployee: boolean = false;
  loader: any = false;
  employees: any[] = [];
  error_msg: any;
  empList: any = [];
  organizationId: number;
  employeeIdTask: any;
  employeeIdToMatch: any = undefined;
  employeeIds: any[] = [];
  payload1: any;
  assignformbyemployeearray: any[] = [];
  submitButtonEnable: boolean = false;
  submitButtonEnableEmployee: boolean = true;
  submitTask: boolean = true;
  submitEmployee: boolean = true;
  // payload1: { task: { taskId: any; }; empolyeeId: string; organizationId: number; assignedhours: number; assignedminutes: number; estimatedHours: number; estimatedMinutes: number; startdate: string; enddate: string; status: { listTypeValueId: number; listTypeValueName: string; }; };
  employeeSelectedFirst: boolean = false;
  employeeNumberWithName: any[] = [];
  disableAssignEmployeeButton: boolean = true;
  employeeIdsChecking: any[] = [];
  checkEmployeeIds: any[] = [];
  UnmatchedEmployeeIds: any[] = [];
  disableIfNoTask: boolean = false;
  assignByEmployeeArray: any[] = [];

  constructor(
    private payrollService: PayrollService,
    private gs: ProjectManagementService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    private assignemp: AssignEmpService
  ) {
    // this.gs.getTaskById(2).subscribe(
    //   (x: any) => {
    //     console.log('x',x);
    //   this.taskList = x;
    //   console.log(this.taskList);
    //   }
    // )
  }

  ngAfterViewInit() {
    this.setInitialValue();
    this.setInitialValueTask();

    // this.removePlaceholder();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroyTask.next();
    this._onDestroyTask.complete();
  }

  protected setInitialValue() {
    this.employeeProjectsTasks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  protected setInitialValueTask() {
    this.employeeProjects
      .pipe(take(1), takeUntil(this._onDestroyTask))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }
  ngOnInit(): void {
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage('');
    this.timesheet.setSuccessUpdateMessage('');
    this.headerservice.setTitle('');
    // this.headerservice.setTitle('Assign Employee Task');
    const Data = JSON.parse(localStorage.getItem('enoteUserData'));
    this.EmpId = Data.employee.employeeId;
    // this.gs.getProjectByOrganization(Data.organization.organizationId).subscribe((projectslist:any ) => {
    //       this.projectList = projectslist;
    // })

    this.organizationId = Data.organization.organizationId;
    console.log('this.organizationId', this.organizationId);

    // this.gs.listProjectList(this.organizationId).subscribe((pa: any) => {
    //   this.projectList = pa;

    //   console.log('projects', this.projectList);
    // });

    this.gs.getProjectsBasedOnProjectStatus().subscribe((pa: any) => {
      this.projectList = pa;

      console.log('projects', this.projectList);
    });
    this.touchedRows = [];
    this.assignformbyemployee = this.fb.group({
      tableRows: this.fb.array([]),
      projectname: ['', Validators.required],
      taskname: ['', Validators.required],
    });

    this.assignformbytask = this.fb.group({
      tableRows1: this.fb.array([]),
      projectname1: ['', Validators.required],
      employee1: ['', Validators.required],
    });
    this.addRow();

    const taskControl = this.assignformbyemployee.get('tableRows') as FormArray;
    console.log('taskControl', taskControl);
    console.log('taskControl length', taskControl.controls.length);
    taskControl.controls.forEach((row1) => {
      console.log('row1', row1);

      this.assignformbyemployee.get('tableRows').valueChanges.subscribe(() => {
        this.checkAllRowsForZeroValues();
      });
    });
    //     const control = this.assignformbyemployee.get('tableRows') as FormArray;
    // console.log('control',control);

    //     control.controls.forEach((row) => {

    //       this.assignformbyemployee.valueChanges.subscribe(() => {
    //         const hours = row.get('hours').value;
    //       const minutes = row.get('minutes').value;
    //       console.log('hours',hours);
    //       console.log('minutes', minutes);
    //         if (parseInt(hours) === 0 && parseInt(minutes) === 0) {
    //           alert("Both hours and minutes cannot be zero");
    //         row.get('hours').reset();
    //         row.get('minutes').reset();
    //         }
    //       });

    //     });

    this.touchedRows1 = [];
    // this.assignformbytask = this.fb.group({
    //   tableRows1: this.fb.array([]),
    //   projectname1: ['', Validators.required],
    //   employee1: ['', Validators.required],
    // });
    this.addRow1();
  }
  hoursValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === '') {
      return null; // Let the required validator handle this case
    }
    if (isNaN(value) || value < 0 || value > 999) {
      return { invalidHours: true };
    }
    return null;
  }
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!', event);
  }
  ngAfterOnInit() {
    this.control = this.assignformbyemployee.get('tableRows') as FormArray;

    console.log('ngAfterOnInit', this.control);

    // this.control2 = this.assignformbytask.get('tableRows2') as FormArray;
  }

  checkStartDate(): void {
    // if (this.startDate && this.endDate && this.startDate > this.endDate) {
    //   alert('Start date cannot be greater than end date.');
    // }
  }

  initiateForm(): FormGroup {
    return this.fb.group(
      {
        employee: ['', Validators.required],
        startdate: ['', [Validators.required]],
        enddate: ['', [Validators.required]],
        hours: ['', [Validators.required, this.hoursValidator]],
        minutes: ['', [Validators.required]],
        isEditable: [true],
      },
      { validators: nonZeroHoursMinutesValidator() }
    );
  }
  initiateForm1(): FormGroup {
    return this.fb.group(
      {
        taskname1: ['', Validators.required],
        startdate1: ['', [Validators.required]],
        enddate1: ['', [Validators.required]],
        hours1: ['', [Validators.required, this.hoursValidator]],
        minutes1: ['', [Validators.required]],
        isEditable: [true],
      },
      { validators: nonZeroHoursMinutesValidator() }
    );
  }

  // date validation
  mintaskendDatenew(date: any, index: any) {
    this.minStart = new Date(date.value);
    console.log(date.target.value);
    document
      .getElementById('TenddtPicker' + index)
      .setAttribute('min', date.target.value);
    this.endDate_disable = false;
  }
  // noNumber(event: any) {
  //   const pattern = /[0-9]|\./;
  //   const inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode !== 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }

  noNumber(event: any) {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    console.log('control1', control);

    const pattern = /[0-9]/; // Removed the `\.` from the pattern
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // date validation
  mintaskendDate(date: any, index: any) {
    console.log('mindate');
    console.log(date.target.value);
    document
      .getElementById('enddtPicker' + index)
      .setAttribute('min', date.target.value);

    this.endDate_disable = false;
  }

  resetFormData() {
    this.submitted1 = false;
    this.employeeNotFound = false;

    // Reset projectname and taskname controls
    this.assignformbyemployee.get('projectname').reset();
    this.assignformbyemployee.get('taskname').reset();

    // Reset the values of the form controls in each row of the form array
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    control.controls.forEach((row) => {
      // Reset employee control and clear patched value
      row.get('employee').reset();
      row.get('employee').patchValue(''); // Clear patched employee value

      // Reset other controls and clear patched values if applicable
      row.get('startdate').reset();
      row.get('enddate').reset();
      row.get('hours').reset();
      row.get('minutes').reset();

      // Mark the controls as pristine and untouched
      row.get('employee').markAsPristine();
      row.get('employee').markAsUntouched();
      row.get('startdate').markAsPristine();
      row.get('startdate').markAsUntouched();
      row.get('enddate').markAsPristine();
      row.get('enddate').markAsUntouched();
      row.get('hours').markAsPristine();
      row.get('hours').markAsUntouched();
      row.get('minutes').markAsPristine();
      row.get('minutes').markAsUntouched();
    });

    // Reset other variables or flags
    this.employeeProjects = [];
    this.isSubmitting = false;

    // Clear input box (employeeInput)
    this.assignformbyemployee.get('employee').reset(); // Assuming 'employee' is the form control for the input
  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }
  handleBackspace(event): void {
    const inputValue = event.target.value;
    console.log('inputValue', inputValue);

    // Check if the key pressed is Backspace or Space and set employeeNotFound accordingly
    if (
      (event.key === 'Backspace' || event.key === ' ') &&
      inputValue.length <= 0
    ) {
      this.employeeNotFoundByAssignEmployee = false;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      // this.selectedemployeeId = undefined;
    } else {
      // this.employeeNotFoundByAssignEmployee = true;
    }
    // Check if the key pressed is Backspace or Space and set employeeNotFound accordingly
    if (
      (event.key === 'Backspace' || event.key === ' ') &&
      inputValue.length <= 1
    ) {
      this.employeeNotFound = false;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      // this.selectedemployeeId = undefined;
    } else {
      // this.employeeNotFound = true;
    }

    // Assuming this.assignformbyemployee is a FormGroup
    const tableRowsArray = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;
    if (!tableRowsArray) {
      console.log('Table Rows FormArray not found');
      return;
    }

    const employeeControl = tableRowsArray.at(0).get('employee'); // Assuming 'employee' control is present in the first row
    if (!employeeControl) {
      console.log('Employee control not found');
      return;
    }

    console.log('Employee control value:', employeeControl.value);

    if (
      (event.key === 'Backspace' || event.key === ' ') &&
      !employeeControl.value.trim()
    ) {
      this.employeeNotFound = false;
    }
  }

  handleBackspace1(event): void {
    const inputValue = event.target.value;
    console.log('inputValue', inputValue);
    this.employeeProjectsTasks = [];
    this.employeeNotFoundByAssignEmployee = false;

    if (inputValue.length === 0) {
      this.employeeProjectsTasks = [];
    }
    // Check if the key pressed is Backspace or Space and set employeeNotFound accordingly
    if (
      (event.key === 'Backspace' || event.key === '') &&
      inputValue.length <= 0
    ) {
      this.employeeNotFoundByAssignEmployee = false;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      // this.selectedemployeeId = undefined;
    } else {
      // this.employeeNotFoundByAssignEmployee = true;
    }

    // Assuming this.assignformbyemployee is a FormGroup
    const tableRowsArray = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;
    if (!tableRowsArray) {
      console.log('Table Rows FormArray not found');
      return;
    }

    const employeeControl = tableRowsArray.at(0).get('employee'); // Assuming 'employee' control is present in the first row
    if (!employeeControl) {
      console.log('Employee control not found');
      return;
    }

    console.log('Employee control value:', employeeControl.value);

    if (
      (event.key === 'Backspace' || event.key === ' ') &&
      (!employeeControl.value || employeeControl.value.trim().length <= 1)
    ) {
      this.employeeNotFound = false;
    }
  }

  resetAssignByEmployee() {
    this.submitted2 = false;
    this.employeeProjectsTasks = [];
    this.isSubmittingAssignByEmployee = false;
    this.employeeNotFoundByAssignEmployee = false;
    this.assignformbytask.get('projectname1').reset(); // Reset projectname control
    this.assignformbytask.get('employee1').reset(); // Reset employee1 control

    // Reset the values of the form controls in each row of the form array
    const control = this.assignformbytask.get('tableRows1') as FormArray;

    control.controls.forEach((row) => {
      row.get('taskname1').reset(); // Reset taskname1 control
      row.get('startdate1').reset(); // Reset startdate1 control
      row.get('enddate1').reset(); // Reset enddate1 control
      row.get('hours1').reset(); // Reset hours1 control
      row.get('minutes1').reset(); // Reset minutes1 control

      // Mark the controls as pristine to clear validation messages
      row.get('taskname1').markAsPristine();
      row.get('startdate1').markAsPristine();
      row.get('enddate1').markAsPristine();
      row.get('hours1').markAsPristine();
      row.get('minutes1').markAsPristine();

      // Mark the controls as untouched to remove any focus styles
      row.get('taskname1').markAsUntouched();
      row.get('startdate1').markAsUntouched();
      row.get('enddate1').markAsUntouched();
      row.get('hours1').markAsUntouched();
      row.get('minutes1').markAsUntouched();
    });
  }

  // empSearch(event) {
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);

  //   this.unsubscribe$.next();
  //   this.unsubscribe$.complete();
  //   this.unsubscribe$ = new Subject<void>();

  //   // Employee List Api
  //   if (employeenumber) {
  //     if(employeenumber.length >= 2)
  //         {

  //     this.gs
  //       .filterEmployeesByProjects(this.organizationId, this.selectedProjectId, employeenumber).pipe(
  //                 takeUntil(this.unsubscribe$), // Cancel previous request
  //                 switchMap(() => this.gs.filterEmployeesByProjects(this.organizationId, this.selectedProjectId, employeenumber))
  //               )
  //       .subscribe((result: any) => {
  //         this.employeeProjects = result;

  //         console.log('this.employeeProjects', this.employeeProjects);
  //         if (result.statusCode) {
  //         } else {
  //           this.empList = result;

  //           console.log('this.employeeProjects', this.employeeProjects);
  //         }
  //       });
  //   }
  // }
  // }

  // empSearch(event, event1: KeyboardEvent): void {
  //   this.employeeNotFound = false;
  //   this.employeeNotFoundByAssignEmployee = false;

  //   if (event1.key === ' ') {
  //     this.employeeNotFound = false;
  //     this.employeeNotFoundByAssignEmployee = false;
  //     console.log('Space key pressed');
  //     event1.preventDefault();
  //   } else {
  //     console.log(event.target.value);
  //     let employeenumber = event.target.value;
  //     console.log(employeenumber);

  //     this.unsubscribe$.next();
  //     this.unsubscribe$.complete();
  //     this.unsubscribe$ = new Subject<void>();

  //     if (employeenumber.length == 0) {
  //       this.employeeNotFound = false;
  //       console.log("entered into the condition!!");
  //     }

  //     if (employeenumber && employeenumber.length >= 2) {
  //       this.gs
  //         .filterEmployeesByProjects(
  //           this.organizationId,
  //           this.selectedProjectId,
  //           employeenumber
  //         )
  //         .pipe(
  //           takeUntil(this.unsubscribe$),
  //           switchMap(() =>
  //             this.gs
  //               .filterEmployeesByProjects(
  //                 this.organizationId,
  //                 this.selectedProjectId,
  //                 employeenumber
  //               )
  //               .pipe(
  //                 catchError((err) => {
  //                   if (err.error.status === 500) {
  //                     this.employeeProjectsTasks = [];
  //                   }
  //                   console.log('err', err);
  //                   console.error('An error occurred:', err);
  //                   return EMPTY;
  //                 })
  //               )
  //           )
  //         )
  //         .subscribe(
  //           (result: any) => {
  //             console.log('result', result);
  //             // this.employeeProjects = [];
  //             // Ensure employeeProjects is an array
  //             if (!Array.isArray(this.employeeProjects)) {
  //               this.employeeProjects = [];
  //             }

  //             // Ensure result is also an array before pushing
  //             if (Array.isArray(result)) {
  //               // this.employeeProjects = [];
  //               this.employeeProjects.push(...result);
  //             } else {

  //               if (result.errorCode == 204) {
  //                 // this.employeeProjects = [];

  //                 // this.employeeProjects.splice(1, this.employeeProjects.length);

  //               }
  //               console.error('Unexpected data type:', result);
  //               this.employeeNotFound = true;
  //               return;
  //             }

  //             console.log('this.employeeProjects', this.employeeProjects);

  //             this.employeeProjectsTasks.length = 0;
  //             // if (result.errorCode === 204) {
  //             //   this.employeeProjectsTasks = [];
  //             //   this.employeeNotFound = true;
  //             // }

  //             if (this.employeeProjects.length > 0) {
  //               this.employeeNotFound = false;
  //             } else {
  //               this.employeeNotFound = true;
  //             }

  //             if (this.employeeProjects.length > 0 &&
  //               this.assignformbyemployee.valid) {
  //               this.submitButtonEnable = true;
  //             } else {
  //               this.submitButtonEnable = false;
  //             }
  //           },
  //           (err) => {
  //             if (err.error.status === 500) {
  //               this.employeeProjectsTasks = [];
  //               this.employeeNotFound = true;
  //             }
  //             console.error('An error occurred:', err);
  //           }
  //         );
  //     }
  //   }
  // }

  empSearch(event, event1: KeyboardEvent): void {
    this.employeeNotFound = false;
    this.employeeNotFoundByAssignEmployee = false;
    if (event1.key === ' ') {
      this.employeeNotFound = false;
      this.employeeNotFoundByAssignEmployee = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      // this.employeeProjects.length = 0;
      this.employeeProjects = [];
      console.log('Space', event.target.value);

      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log('employeenumber', employeenumber);

      // Cancel the previous API request
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();
      if (employeenumber.length == 0) {
        this.employeeNotFound = false;
        console.log('enterd into the condition!!');
        // this.employeeId = undefined;
        // this.employee = undefined;
      }
      // Employee List Api
      if (employeenumber) {
        if (employeenumber.length >= 2) {
          this.gs
            .filterEmployeesByProjects(
              this.organizationId,
              this.selectedProjectId,
              employeenumber
            )
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() =>
                this.gs
                  .filterEmployeesByProjects(
                    this.organizationId,
                    this.selectedProjectId,
                    employeenumber
                  )
                  .pipe(
                    catchError((err) => {
                      if (err.error.status === 500) {
                        this.employeeProjectsTasks = [];
                        // this.employeeNotFound = true;
                      }
                      console.log('err', err);
                      console.error('An error occurred:', err);
                      // Handle the error here, for example, you can show an error message to the user
                      return EMPTY; // Return an empty observable or any other observable you need
                    })
                  )
              )
            )
            .subscribe(
              (result: any) => {
                console.log('result', result);

                console.log('checking empids', this.employeeIds);

                this.employeeProjectsTasks.length = 0;
                if (result.errorCode === 204) {
                  this.employeeProjectsTasks = [];
                  this.employeeNotFound = true;
                }
                if (Array.isArray(result)) {
                  this.employeeProjects.length = 0;
                  this.employeeProjects = result;

                  console.log('checking empids1', this.employeeIds);
                  if (this.employeeProjects.length > 0) {
                    this.employeeNotFound = false;
                  } else {
                    this.employeeNotFound = true;
                  }

                  if (
                    this.employeeProjects.length > 0 &&
                    this.assignformbyemployee.valid
                  ) {
                    this.submitButtonEnable = true;
                  } else {
                    this.submitButtonEnable = false;
                  }
                } else if (typeof result === 'object') {
                  // Transform the object into an array if possible
                  this.employeeProjects = Object.values(result);
                  if (this.employeeProjects.length > 0) {
                    this.employeeNotFound = false;
                  } else {
                    this.employeeNotFound = true;
                  }
                } else {
                  this.employeeProjects.length = 0;
                  this.employeeNotFound = true;
                  console.error('Unexpected data type:', result);
                  return;
                }
                // if (this.employee.length >= 1) {
                //   this.employeeSelected = true;
                // }
                if (result.errorCode === 204) {
                  this.employeeProjects.length = 0;
                  this.employeeNotFound = true;
                  this.employeeProjectsTasks = [];
                  this.submitButtonEnable = false;
                }

                if (result.errorCode === 204) {
                  // alert('No Employee Found');
                  this.employeeNotFound = true;
                  this.employeeProjects.length = 0;
                  this.submitButtonEnable = false;
                  // this.employeeProjects.push('No Employee Found');
                  let control = this.assignformbyemployee?.get(
                    'tableRows1'
                  ) as FormArray;
                  // if (control) {
                  //   control.controls.forEach((element) => {
                  //     element.patchValue({
                  //       employee: null,
                  //     });
                  //   });
                  // }
                }
                // if (result.statusCode) {
                //   console.log(result.statusCode);
                //   this.employeeProjects.length = 0;
                // } else {
                //   this.employeeProjects = result;
                // }
              },
              (err) => {
                if (err.error.status === 500) {
                  this.employeeProjectsTasks = [];
                  this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
                // Handle the error here, for example, you can show an error message to the user
              }
            );
        }
      }
    }
  }
  // removeFromIndex(index: number): void {
  //   console.log('index', index);

  //   // Ensure the index is within the bounds of the array
  //   if (index >= 0 && index < this.employeeProjects.length) {
  //     this.employeeProjects.splice(index, 1);
  //   } else {
  //     console.error('Index out of bounds');
  //   }
  // }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.employeeNotFoundByAssignEmployee = false;
    // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.employeeProjects.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
  }

  empSelect(emp, index) {
    console.log('emp', emp);
    this.employeeIds[index] = emp.employeeId;

    console.log('this.employeeIds', this.employeeIds[index]);
    this.employeeSelectedFirst = true;
    this.employee = ''; // Clear current employee
    this.employeeNumber = emp.employee.employeeNumber; // Assign employee number
    this.employeeNumberWithName[
      index
    ] = `${emp.employee.employeeNumber} - ${emp.employee.firstName}`;

    // Ensure the array is large enough to accommodate the index
    while (this.employeeIds.length <= index) {
      this.employeeIds.push(null); // Fill with null or any placeholder
    }

    // Insert the emp object at the specified index
    this.patchEmployee(index, emp);
    // this.removeFromIndex(index);
    if (this.employeeIds.length > 0) {
      this.submitButtonEnable = true;
      this.submitTask = true;
    }

    if (this.employeeIds.length !== 0) {
      this.employeeNotFound = false;
      this.employeeNotFoundByAssignEmployee = false;
    }
    // this.employeeProjects =[];
    console.log('employeeIds', this.employeeIds);
  }

  // patchEmployee(index: number, emp: any): void {
  //   console.log('this.assignformbytask:', this.assignformbytask);

  //   const tableRowsArray = this.assignformbyemployee.get('tableRows') as FormArray;
  //   console.log('tableRowsArray:', tableRowsArray);

  //   if (!tableRowsArray) {
  //     console.error('tableRowsArray is null. Check form initialization and control name.');
  //     return;
  //   }

  //   if (index >= tableRowsArray.length || index < 0) {
  //     console.error(`Index ${index} is out of bounds for the FormArray.`);
  //     return;
  //   }

  //   const employeeFormGroup = tableRowsArray.at(index) as FormGroup;

  //   if (!employeeFormGroup) {
  //     console.error(`Employee form group at index ${index} is null.`);
  //     return;
  //   }

  //   const combinedValue = `${emp.employee.employeeNumber} - ${emp.employee.firstName}`;
  //   this.employee = combinedValue;
  //   console.log('combinedValue:', combinedValue);
  //   this.selectedemployeeTask = undefined;
  //   this.selectedemployeeTask = this.employeeIds[index];
  //   console.log('this.employeeIds:', this.employeeIds[index]);

  //   employeeFormGroup.patchValue({
  //     employee: this.employeeIds[index]
  //   });

  //   console.log('employeeFormGroup:', employeeFormGroup);
  //   console.log('Length of employeeIds:', this.employeeIds.length);

  //   // if (  this.employeeProjects > 0) {

  //     // this.employeeProjects=[];
  //   // }

  //   // employeeFormGroup.patchValue({
  //   //   employee: this.employeeNumberWithName[index]
  //   // });
  // }

  patchEmployee(index: number, emp: any): void {
    console.log('this.assignformbytask:', this.assignformbytask);

    const tableRowsArray = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;
    console.log('tableRowsArray:', tableRowsArray);

    if (!tableRowsArray) {
      console.error(
        'tableRowsArray is null. Check form initialization and control name.'
      );
      return;
    }

    if (index >= tableRowsArray.length || index < 0) {
      console.error(`Index ${index} is out of bounds for the FormArray.`);
      return;
    }

    const employeeFormGroup = tableRowsArray.at(index) as FormGroup;

    if (!employeeFormGroup) {
      console.error(`Employee form group at index ${index} is null.`);
      return;
    }

    const combinedValue = `${emp.employee.employeeNumber} - ${emp.employee.firstName}`;
    this.employee = combinedValue;
    console.log('combinedValue:', combinedValue);
    this.selectedemployeeTask = undefined;
    this.selectedemployeeTask = this.employeeIds[index];
    console.log('this.employeeIds:', this.employeeIds[index]);

    // Patch the form group with the employee ID
    // employeeFormGroup.patchValue({
    //   employee: this.employeeIds[index]
    // });
    employeeFormGroup.patchValue({
      employee: combinedValue,
    });

    console.log('employeeFormGroup:', employeeFormGroup);
    console.log('Length of employeeIds:', this.employeeIds.length);

    // Clear the employeeProjects array after patching the form
    this.employeeProjects = [];
  }

  clearEmployeeField(index: number): void {
    const tableRowsArray = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;

    if (!tableRowsArray) {
      console.error(
        'tableRowsArray is null. Check form initialization and control name.'
      );
      return;
    }

    const employeeFormGroup = tableRowsArray.at(index) as FormGroup;

    if (!employeeFormGroup) {
      console.error(`Employee form group at index ${index} is null.`);
      return;
    }

    employeeFormGroup.patchValue({
      employee: '',
    });

    // Mark the control as pristine and untouched to reset validation state
    employeeFormGroup.get('employee').markAsPristine();
    employeeFormGroup.get('employee').markAsUntouched();
  }

  //   patchEmployee(index: number, emp: any): void {

  //     console.log('value', emp);

  //     // const tableRowsArray = this.assignformbytask.get('tableRows') as FormArray;
  //     // const employeeFormGroup = tableRowsArray.at(index) as FormGroup;
  //     // employeeFormGroup.patchValue({
  //     //   employee: emp.employee.employeeNumber + '-' + emp.employee.firstName,
  //     // });

  // this.employee = emp.employee.employeeNumber + '-' + emp.employee.firstName;

  //     const tableRowsArray = this.assignformbytask.get('tableRows') as FormArray;
  //     const employeeFormGroup = tableRowsArray.at(index) as FormGroup;
  //     employeeFormGroup.patchValue({
  //       employee:  this.employee,
  //     });

  //   // let control = this.assignformbyemployee.get('tableRows') as FormArray;
  //   //   control.controls.forEach((element) => {
  //   //     element.patchValue({
  //   //       employee: emp.employee.employeeNumber + '-' + emp.employee.firstName,
  //   //     });
  //   //   });

  //   }
  empSearchEmployee(event, event1: KeyboardEvent) {
    this.employeeNotFound = false;
    this.employeeNotFoundByAssignEmployee = false;
    if (event1.key === ' ') {
      this.employeeNotFound = false;
      this.employeeNotFoundByAssignEmployee = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      // event1.preventDefault();
    } else {
      console.log('Space', event.target.value);
      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log(employeenumber);

      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();

      if (employeenumber.length == 0) {
        this.employeeNotFound = false;
        console.log('enterd into the condition!!');
        // this.employeeId = undefined;
        // this.employee = undefined;
        // this.employeeIdTask = undefined;
      }

      if (employeenumber) {
        if (employeenumber.length >= 2) {
          this.gs
            .filterEmployeesByProjects(
              this.organizationId,
              this.projectIdByEmployee,
              employeenumber
            )
            .pipe(
              takeUntil(this.unsubscribe$),
              switchMap(() =>
                this.gs
                  .filterEmployeesByProjects(
                    this.organizationId,
                    this.selectedProjectId,
                    employeenumber
                  )
                  .pipe(
                    catchError((err) => {
                      if (err.error.status === 500) {
                        this.employeeProjectsTasks = [];
                        this.employeeNotFoundByAssignEmployee = true;
                      }
                      console.error(
                        'An error occurred in the HTTP request:',
                        err
                      );
                      // Handle the error here, for example, you can show an error message to the user
                      return EMPTY; // Return an empty observable or any other observable you need
                    })
                  )
              )
            )
            .subscribe(
              (result: any) => {
                console.log('result', result);

                this.employeeProjectsTasks.length = 0;
                if (Array.isArray(result)) {
                  this.employeeProjectsTasks.length = 0;
                  this.employeeProjectsTasks = result;
                  this.assignByEmployeeArray.push(result);
                  this.employeeNotFoundByAssignEmployee = false;

                  if (this.employeeIdTask && this.assignformbytask.valid) {
                    this.submitButtonEnableEmployee = true;
                  } else {
                    this.submitButtonEnableEmployee = false;
                  }

                  if (this.employeeProjectsTasks.length == 0) {
                    this.submitButtonEnableEmployee = false;
                  }
                } else if (typeof result === 'object' && result !== null) {
                  this.employeeProjectsTasks.length = 0;
                  this.employeeProjectsTasks = Object.values(result);
                  this.assignByEmployeeArray = Object.values(result);
                  // if (this.employeeProjectsTasks.length > 0 && this.assignformbytask.valid) {
                  //   this.submitButtonEnableEmployee  =  true;

                  // } else {
                  //   this.submitButtonEnableEmployee = false;

                  // }
                  this.employeeNotFoundByAssignEmployee = false;
                } else {
                  console.error('Unexpected data type:', result);
                  return;
                }
                if (result.errorCode === 204) {
                  this.employeeProjectsTasks = [];
                  this.employeeNotFoundByAssignEmployee = true;
                }

                if (result.errorCode === 204) {
                  this.employeeNotFoundByAssignEmployee = true;
                  this.employeeProjectsTasks.length = 0;
                  this.submitButtonEnableEmployee = false;
                  // this.employeeProjectsTasks.push('No Employee Found');
                  // let control = this.assignformbytask.get(
                  //   'tableRows'
                  // ) as FormArray;
                  // control?.controls?.forEach((element) => {
                  //   element.patchValue({
                  //     employee1: null,
                  //   });
                  // });
                }
              },
              (err) => {
                if (err.error.status === 500) {
                  this.employeeProjectsTasks.length = 0;
                  this.employeeProjectsTasks = [];
                  this.submitButtonEnableEmployee = false;
                  this.employeeNotFoundByAssignEmployee = true;
                }
                console.log('err', err);
              }
            );
        }
      }
    }
  }
  clearEmployeeProjects(): void {
    this.employeeProjects = [];
  }
  empSelectEmployee(emp) {
    console.log('emp', emp);
    // this.employeeSelectedFirst = true;

    // this.employeeIdTask = undefined;
    // this.employee = undefined;
    this.employeeIdToMatch = emp.employee.employeeId;
    this.employeeIdTask = emp.employee.employeeId;

    console.log();

    this.employee = emp.employee.employeeNumber + '-' + emp.employee.firstName;
    this.employeeNumber = emp.employee.employeeNumber;

    console.log('this.employeeIdTask', this.employeeIdTask);

    // this.assignformbyemployee.patchValue({
    // employee: this.employee,
    // });

    // let control = this.assignformbyemployee.get('tableRows') as FormArray;
    // control.controls.forEach((element) => {
    //   element.patchValue({
    //     employee: this.employee,

    //   });
    // })

    this.assignformbytask.patchValue({
      employee1: this.employee,
    });

    console.log('this.employee', this.employee);
  }

  taskData(task) {
    this.disableIfNoTask = true;
    console.log('task', task);
    this.min = null; // or this.min = "";
    this.max = null; // or this.max = "";
    if (task) {
      this.disableIfNoTask = true;
    }
    this.min = task.taskStartDate.substring(0, 10);
    this.max = task.taskEndDate.substring(0, 10);
    // this.min = task.taskStartDate;
    console.log(this.min);
    // this.max = task.taskEndDate;
    console.log(this.max);
    this.startDate_disable = false;
    if (this.task_by_employee) {
      let control = this.assignformbyemployee.get('tableRows') as FormArray;
      control.controls.forEach((element) => {
        element.patchValue({
          startdate: '',
          enddate: '',
        });
      });
    }
  }

  get tableRows(): FormArray {
    return this.assignformbyemployee.get('tableRows') as FormArray;
  }

  taskDataBytask(task, control, index) {
    // this.min = new Date(task.taskStartDate);
    // console.log(this.min);
    // this.max = new Date(task.taskEndDate);
    console.log('task22222', task);

    this.minEmp = task.taskStartDate.substring(0, 10);
    this.maxEmp = task.taskEndDate.substring(0, 10);
    console.log(this.max);
    this.startDate_disable = false;

    console.log(control);
    const control1 = this.assignformbytask.get('tableRows1') as FormArray;
    console.log('control1', control1);

    const datePicker = document.getElementById('dtPicker' + index);
    const endDtPicker = document.getElementById('enddtPicker' + index);

    if (datePicker && endDtPicker) {
      datePicker.setAttribute('min', task.taskStartDate);
      datePicker.setAttribute('max', task.taskEndDate);
      endDtPicker.setAttribute('max', task.taskEndDate);
    } else {
      console.error('Element not found for index: ' + index);
    }
  }

  projectData(project: any) {
    console.log('project', project);

    this.employeeNotFound = false;
    this.disableIfNoTask = false;
    this.min = null; // or this.min = "";
    this.max = null; // or this.max = "";
    this.submitted1 = false;
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    control.controls.forEach((row) => {
      // Reset employee control and clear patched value
      row.get('employee').reset();
      row.get('employee').patchValue(''); // Clear patched employee value

      // Reset other controls and clear patched values if applicable
      row.get('startdate').reset();
      row.get('enddate').reset();
      // row.get('hours').reset();
      // row.get('minutes').reset();

      // Mark the controls as pristine and untouched
      row.get('employee').markAsPristine();
      row.get('employee').markAsUntouched();
      row.get('startdate').markAsPristine();
      row.get('startdate').markAsUntouched();
      row.get('enddate').markAsPristine();
      row.get('enddate').markAsUntouched();
      // row.get('hours').markAsPristine();
      // row.get('hours').markAsUntouched();
      // row.get('minutes').markAsPristine();
      // row.get('minutes').markAsUntouched();
    });
    // this.group.controls.startdate.setErrors(null);
    // this.group.controls.startdate.markAsPristine();
    this.employeeSelectedFirst = false;
    this.employeeNotFoundByAssignEmployee = false;
    this.taskList = [];
    this.assignformbyemployee.controls['taskname'].reset();
    this.taskList.length = 0;
    this.gs.getTaskById(project.projectId).subscribe((x: any) => {
      console.log('x', x);
      this.taskList.length = 0;
      this.taskList = Array.isArray(x) ? x : [x]; // Ensure taskList is an array
      console.log(this.taskList);
      this.assignformbyemployee.controls['taskname'].reset();
    });

    this.gs.getEmployeeProject(project.projectId).subscribe(
      (data: any) => {
        console.log('dataError', data);
        // this.employeelist = data;
        //   if (Array.isArray(data)) {
        //      (data as any).filter((res,index)=>{
        //     console.log('res',res.employee.employeeId
        //     );
        //     // let control1 = this.assignformbyemployee.get('tableRows') as FormArray;
        //     // control1?.controls?.forEach((element, index) => {

        //     //   console.log('element',element);

        //     //   element.patchValue({
        //     //     employee: this.employeeNumberWithName[index],
        //     //   });
        //     // });

        //     // Get the values
        //     // let values = control1.controls.map(control => control.value);

        //     // console.log('values',values);

        //     // console.log('controls',  control1 );

        //     if (res.employee.employeeId === this.employeeIds[index]) {

        //       // console.log('checking both empids', this.employeeId, res.employee.employeeId );
        //       // let control1 = this.assignformbyemployee.get('tableRows') as FormArray;
        //       // control1?.controls?.forEach((element, index) => {

        //       //   console.log('element',element);

        //       //   element.patchValue({
        //       //     employee: this.employeeNumberWithName[index],
        //       //   });
        //       // });
        //     // this.selectedemployee =  this.employee;
        //     }else{
        //       let control1 = this.assignformbyemployee.get('tableRows') as FormArray;
        //       control1?.controls?.forEach((element, index) => {

        //         console.log('element',element);

        //         element.patchValue({
        //           employee: '',
        //         });
        //       });

        //           this.employeeNotFound = false;
        //         this.employeeProjects.length = 0;
        //         this.employeeId = undefined;
        //         this.employee = undefined;
        //         this.employeeNumber = undefined;
        //         // this.selectedemployee = undefined;
        //         // this.selectedemployeeId = undefined;
        //         //this.showErrorMessage = true;
        //     }

        //     console.log('res.employee.employeeId',res.employee.employeeId,this.employeeId);
        //     if (res.employee.employeeId ===  this.employeeIdToMatch) {

        //       // console.log('checking both empids', this.employeeId, res.employee.employeeId );
        //       // let control1 = this.assignformbyemployee.get('tableRows') as FormArray;
        //       // control1?.controls?.forEach((element, index) => {

        //       //   console.log('element',element);

        //       //   element.patchValue({
        //       //     employee: this.employeeNumberWithName[index],
        //       //   });
        //       // });
        //     // this.selectedemployee =  this.employee;
        //     }
        //     else if (res.employee.employeeId !==  this.employeeIdToMatch) {

        //     }{
        //         this.assignformbytask.patchValue({
        //         employee1: undefined
        //         });

        //       this.employeeProjectsTasks.length = 0;
        //           this.employeeNotFound = false;
        //         this.employeeProjects.length = 0;
        //         this.employeeId = undefined;
        //         this.employee = undefined;
        //         this.employeeNumber = undefined;
        //         // this.selectedemployee = undefined;
        //         // this.selectedemployeeId = undefined;
        //         //this.showErrorMessage = true;
        //     }
        //   });
        // }

        // this.employeelist.push({data})

        this.employeeIdsChecking = data;

        let control1 = this.assignformbyemployee.get('tableRows') as FormArray;

        // Iterate over the employeeIdsChecking array

        if (this.employeeIds.length != 0) {
          this.employeeIds.map((element, index) => {
            let hasemployee = this.employeeIdsChecking.some(
              (item) => item.employeeId === element
            );
            console.log('hasemployee' + hasemployee);
            console.log('index' + index);
            if (!hasemployee) {
              let control1 = this.assignformbyemployee.get(
                'tableRows'
              ) as FormArray;
              control1.controls.at(index).patchValue({
                employee: '',
              });
              this.employeeIds.slice(1, index);
              console.log(this.employeeIds);
            }
          });
        }

        if (this.employeeIdToMatch != undefined) {
          let hasAssignemployee = this.employeeIdsChecking.some(
            (item) => item.employeeId === this.employeeIdToMatch
          );
          // console.log("hasemployee" + hasemployee);
          // console.log("index" + index);
          if (!hasAssignemployee) {
            this.assignformbytask.patchValue({
              employee1: undefined,
            });
            this.employeeProjectsTasks = [];

            // this.selectedemployee = undefined;
            // this.employeeNotFound = false;
            // this.employeeProjects.length = 0;
            // this.employeeId = undefined;
            // this.employee = undefined;
            // this.employeeNumber = undefined;
          }
        }

        // this.employeeIdsChecking.some((element, index) => {
        //   console.log(`Index: ${index}, Element: ${element.employeeId}`);
        //   if(element.employeeId !== this.employeeIds[index]){
        //     this.UnmatchedEmployeeIds.push(element.employeeId);
        //   }
        //   if(element.employeeId === this.employeeIds[index]){
        //     this.checkEmployeeIds.push(element.employeeId);
        //   }

        //   console.log('Matched', this.UnmatchedEmployeeIds[index]);
        //   console.log('Unmatched',  this.checkEmployeeIds[index]);
        //   if( this.UnmatchedEmployeeIds[index] !== this.employeeIds[index]){
        //     console.log('Mismatch found at index:', index);
        //     console.log('element.employeeId1:', element.employeeId);
        //     console.log('this.employeeIds[index]1:', this.employeeIds[index]);
        //     this.employeeIdTask = undefined;
        //     let control1 = this.assignformbyemployee.get('tableRows') as FormArray;
        //     control1.controls.forEach((control) => {
        //       control.patchValue({
        //         employee: '',
        //       });
        //     });

        //   }
        //   if (  this.checkEmployeeIds[index] === this.employeeIds[index]) {
        //     console.log('Match found at index:', index);
        //     console.log('this.employeeIds:', this.employeeIds);
        //     console.log('element.employeeId:', element.employeeId);
        //     console.log('this.employeeIds[index]:', this.employeeIds[index]);
        //   }

        // });
      },
      (error) => {
        console.error('Error fetching employee data:', error);
        // Handle the error (e.g., show an error message to the user)
      }
    );
    // this.employeeProjects.length = 0;
    // this.employeeIdTask = undefined;
    // this.employee = undefined;
    // this.employeeIdTask = undefined;
    // this.employee = undefined;
    // this.employeeNumber = undefined;
    // this.employeeProjectsTasks.length = 0;
    // this.employeeProjects = project.employeeProjects;
    // console.log(this.employeeProjects);
    // this.selectedProjectId = undefined;
    // this.employee = undefined;
    this.selectedProjectId = project.projectId;
    // this.projectIdByEmployee = undefined;
    // this.employeeIdTask = undefined;
    this.projectIdByEmployee = project.projectId;

    // this.employee = undefined;
    // this.employee = undefined;
    // this.employeeNumber = undefined;
    this.employeeProjects.length = 0;
    console.log('project,,,,,,', project);

    console.log(project.employeeProjects);

    // this.employees = project.employeeProjects;
    // this.employeeProjects = project.employeeProjects;

    console.log('employeesArray', this.employees);

    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');
    // this.employeeProjects.length = 0;

    // for (let a of this.employees) {
    //   console.log(a.employee);
    //   console.log(a.employee.employeeLastWorkingDate);

    //   if (a.employee.employeeLastWorkingDate != undefined) {
    //     console.log('IF 1');

    //     this.employeelastdate = a.employee.employeeLastWorkingDate;
    //     console.log(this.employeelastdate);

    //     if (this.employeelastdate >= this.today) {
    //       console.log('IF 2');
    //       this.employeeProjects.push(a);
    //       console.log(this.employeeProjects);
    //     }
    //   } else {
    //     this.employeeProjects.push(a);
    //     console.log(this.employeeProjects);
    //   }
    // }
    console.log(this.employeeProjects);

    console.log('project::', project);

    console.log('projectid', project.projectId);

    // let control = this.assignformbyemployee.get('tableRows') as FormArray;
    // control.controls.forEach((element) => {
    //   element.patchValue({
    //     employee: undefined,
    //   });
    // });

    // this.assignformbytask.patchValue({
    //   employee1: undefined,
    // });

    // this.gs.getEmployeesBynumber().subscribe((res:any) =>{

    // });
    if (this.task_by_employee) {
      let control = this.assignformbyemployee.get('tableRows') as FormArray;
      control.controls.forEach((element) => {
        element.patchValue({
          startdate: '',
          enddate: '',
        });
      });
    } else {
      let control = this.assignformbytask.get('tableRows1') as FormArray;
      control.controls.forEach((element) => {
        element.patchValue({
          startdate1: '',
          enddate1: '',
        });
      });
    }
  }

  selected(event: any) {
    this.taskList = [];

    const taskControl1 = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;
    console.log('taskControl', taskControl1);
    console.log('taskControl length', taskControl1.controls.length);
    taskControl1.controls.forEach((row1) => {
      console.log('row1', row1);

      // this.assignformbytask.valueChanges.subscribe(() => {
      //   const hours = row1.get('hours1').value;
      // const minutes = row1.get('minutes1').value;

      // console.log('hours',hours);
      // console.log('minutes', minutes);

      //   if (parseInt(hours) === 0 && parseInt(minutes) === 0) {
      //     alert("Both hours and minutes cannot be zero");
      //   row1.get('hours1').reset();
      //   row1.get('minutes1').reset();
      //   }
      // });

      this.assignformbyemployee.get('tableRows').valueChanges.subscribe(() => {
        this.checkAllRowsForZeroValues();
      });
    });
    const taskControl = this.assignformbytask.get('tableRows1') as FormArray;
    console.log('taskControl', taskControl);
    console.log('taskControl length', taskControl.controls.length);
    taskControl.controls.forEach((row1) => {
      console.log('row1', row1);

      // this.assignformbytask.valueChanges.subscribe(() => {
      //   const hours = row1.get('hours1').value;
      // const minutes = row1.get('minutes1').value;

      // console.log('hours',hours);
      // console.log('minutes', minutes);

      //   if (parseInt(hours) === 0 && parseInt(minutes) === 0) {
      //     alert("Both hours and minutes cannot be zero");
      //   row1.get('hours1').reset();
      //   row1.get('minutes1').reset();
      //   }
      // });

      this.assignformbytask.get('tableRows1').valueChanges.subscribe(() => {
        this.checkAllRowsForZeroValues();
      });
    });

    this.assignformbyemployee.get('projectname').reset(); // Reset projectname control
    this.assignformbyemployee.get('taskname').reset(); // Reset taskname control
    // Reset the values of the form controls in each row of the form array
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    control.controls.forEach((row) => {
      row.get('employee').reset(); // Reset employee control
      row.get('startdate').reset(); // Reset startdate control
      row.get('enddate').reset(); // Reset enddate control
      row.get('hours').reset(); // Reset hours control
      row.get('minutes').reset(); // Reset minutes control

      // Mark the controls as pristine to clear validation messages
      row.get('employee').markAsPristine();
      row.get('startdate').markAsPristine();
      row.get('enddate').markAsPristine();
      row.get('hours').markAsPristine();
      row.get('minutes').markAsPristine();

      // Mark the controls as untouched to remove any focus styles
      row.get('employee').markAsUntouched();
      row.get('startdate').markAsUntouched();
      row.get('enddate').markAsUntouched();
      row.get('hours').markAsUntouched();
      row.get('minutes').markAsUntouched();
    });

    this.assignformbytask.get('projectname1').reset(); // Reset projectname control
    this.assignformbytask.get('employee1').reset(); // Reset employee1 control

    // Reset the values of the form controls in each row of the form array
    const control1 = this.assignformbytask.get('tableRows1') as FormArray;

    control1.controls.forEach((row) => {
      row.get('taskname1').reset(); // Reset taskname1 control
      row.get('startdate1').reset(); // Reset startdate1 control
      row.get('enddate1').reset(); // Reset enddate1 control
      row.get('hours1').reset(); // Reset hours1 control
      row.get('minutes1').reset(); // Reset minutes1 control

      // Mark the controls as pristine to clear validation messages
      row.get('taskname1').markAsPristine();
      row.get('startdate1').markAsPristine();
      row.get('enddate1').markAsPristine();
      row.get('hours1').markAsPristine();
      row.get('minutes1').markAsPristine();

      // Mark the controls as untouched to remove any focus styles
      row.get('taskname1').markAsUntouched();
      row.get('startdate1').markAsUntouched();
      row.get('enddate1').markAsUntouched();
      row.get('hours1').markAsUntouched();
      row.get('minutes1').markAsUntouched();
    });
    this.selectedvalue = event;
    if (this.selectedvalue == 'employee') {
      this.task_by_employee = true;
      this.employee_by_task = false;
    } else if (this.selectedvalue == 'task') {
      this.task_by_employee = false;
      this.employee_by_task = true;
    }
  }

  checkAllRowsForZeroValues() {
    const taskControl = this.assignformbytask.get('tableRows1') as FormArray;
    taskControl.controls.forEach((row, index) => {
      this.checkForZeroValues(row as FormGroup, index);
    });

    const taskControl1 = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;
    taskControl1.controls.forEach((row, index) => {
      this.checkForZeroValues(row as FormGroup, index);
    });
  }

  // Method to check for zero values in a single row
  checkForZeroValues(row: FormGroup, index: number) {
    const hours = row.get('hours1')?.value;
    const minutes = row.get('minutes1')?.value;
    const hours1 = row.get('hours')?.value;
    const minutes1 = row.get('minutes')?.value;
    console.log(`Row ${index + 1} - Hours: ${hours}, Minutes: ${minutes}`);

    if (parseInt(hours) === 0 && parseInt(minutes) === 0) {
      alert(`Both hours and minutes cannot be zero in row ${index + 1}`);
      row.get('hours1')?.reset();
      row.get('minutes1')?.reset();
    }

    if (parseInt(hours1) === 0 && parseInt(minutes1) === 0) {
      alert(`Both hours and minutes cannot be zero in row ${index + 1}`);
      row.get('hours')?.reset();
      row.get('minutes')?.reset();
    }
  }
  addTask() {
    const taskControl = this.assignformbytask.get('tableRows1') as FormArray;
    const newTask = this.initiateForm1();
    taskControl.push(newTask);

    const taskControl1 = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;
    const newTask1 = this.initiateForm();
    taskControl1.push(newTask1);
  }
  getEmployeeProjectsTasksAsArray() {
    return Object.values(this.employeeProjectsTasks);
  }
  addRow() {
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    control.push(this.initiateForm());
    // this.employeeProjects = [];
  }
  addRow1() {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    control.push(this.initiateForm1());
    this.disableAssignEmployeeButton = true;

    this.submitEmployee = true;
    this.submitButtonEnableEmployee = true;

    console.log('control111', control);
  }

  enableButton() {
    // this.disableAssignEmployeeButton = true;
    // this.submitEmployee = true;
    // this.submitButtonEnableEmployee= true;
  }
  deleteRow(index1: number) {
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    control.removeAt(index1);
    this.submitTask = true;
    this.submitButtonEnable = true;
  }

  deleteAllExceptFirstRow() {
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    // Start from the last element and remove each one except the first
    for (let i = control.length - 1; i > 0; i--) {
      control.removeAt(i);
    }
  }
  deleteRow1(index2: number) {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    control.removeAt(index2);
    this.disableAssignEmployeeButton = true;
    this.submitButtonEnableEmployee = true;
    this.submitEmployee = true;
    this.employee = true;
  }

  deleteAllExceptFirstRowform() {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    // Start from the last element and remove each one except the first
    for (let i = control.length - 1; i > 0; i--) {
      control.removeAt(i);
    }
  }

  editRow(group: AbstractControl<any, any>) {
    group.get('isEditable').setValue(true);
  }
  editRow1(group: AbstractControl<any, any>) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }
  doneRow1(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.assignformbyemployee.value);
  }
  saveUserDetails1() {
    console.log(this.assignformbytask.value);
  }

  get getFormControls() {
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    return control;
  }
  get getFormControls1() {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    return control;
  }

  submitForm() {
    let control1 = this.assignformbyemployee.get('tableRows') as FormArray;
    control1?.controls?.forEach((element, index) => {
      const employeeData = this.employeeNumberWithName[index];
      if (employeeData.length > 0) {
        this.submitTask = false;
      }
      element.patchValue({
        employee: this.employeeNumberWithName[index],
      });
    });
    this.assignformbytask.patchValue({
      employee1: this.employee,
    });

    console.log('inside submit');
    this.submitClicked = false;
    const control = this.assignformbyemployee.get('tableRows') as FormArray;

    console.log('control', control);

    if (this.assignformbyemployee.invalid) {
      this.isSubmitting = false;
      console.log('assignformbyemployee.invalid');
      return;
    }

    this.submit = false;

    const valueArr = control.controls.map((item) => item.value.employee);
    const isDuplicate = valueArr.some(
      (item, idx) => valueArr.indexOf(item) !== idx
    );

    console.log('isDuplicate:', isDuplicate);

    if (this.assignformbyemployee.valid && isDuplicate) {
      Swal.fire({
        icon: 'error',
        text: 'Employee Repeats in Form',
      });
      return;
    }

    this.touchedRows = control.controls
      .filter((row) => row.touched)
      .map((row) => row.value);

    console.log('touchedRows:', this.touchedRows);

    // Initialize the array to hold the payloads
    this.assignformbyemployeearray = [];

    if (!this.employeeIds || this.employeeIds.length === 0) {
      console.error('employeeProjects is not defined or is empty');
      return;
    }

    this.touchedRows.forEach((element, index) => {
      let startdate = this.datepipe.transform(element.startdate, 'YYYY-MM-dd');
      let enddate = this.datepipe.transform(element.enddate, 'YYYY-MM-dd');

      let assignedhours = Number(element.hours);
      let assignedMinutes = Number(element.minutes);

      if (assignedMinutes >= 60) {
        assignedhours += Math.floor(assignedMinutes / 60);
        assignedMinutes = assignedMinutes % 60;
      }

      console.log('element:', element);

      // Use each employeeId to create payloads
      const payloads = {
        task: {
          taskId: this.assignformbyemployee.value.taskname,
        },
        empolyeeId: this.employeeIds[index],
        organizationId: this.organizationId,
        assignedhours: assignedhours,
        assignedminutes: assignedMinutes,
        estimatedHours: assignedhours,
        estimatedMinutes: assignedMinutes,
        startdate: startdate,
        enddate: enddate,
        status: {
          listTypeValueId: 1,
          listTypeValueName: 'Active',
        },
        // }));
      };
      console.log('payloads for current element:', payloads);

      // Concatenate the new payloads to the existing array
      this.assignformbyemployeearray =
        this.assignformbyemployeearray.concat(payloads);
    });

    console.log('assignformbyemployeearray:', this.assignformbyemployeearray);

    if (this.assignformbyemployeearray.length === 0) {
      console.error('No payloads to send to API');
      return;
    }

    // this.resetFormData();

    this.gs.assignEmployee(this.assignformbyemployeearray).subscribe(
      (response: any) => {
        if (response.StatusCode === 200) {
          this.success = true;
          this.sucess_msg = 'Task Assigned Successfully';
          this.isSubmitting = false;
          setTimeout(() => {
            this.success = false;
            this.deleteAllExceptFirstRow();
            this.resetAssignByEmployee();
            this.resetFormData();
          }, 3000);

          // setTimeout(() => {
          //   this.success = false;
          // this.sucess_msg = '';
          //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          //   const currentUrl = this.router.url;
          //   this.router
          //     .navigateByUrl('/', { skipLocationChange: true })
          //     .then(() => {
          //       this.router.navigateByUrl(currentUrl);
          //     });
          // }, 1000);
        } else if (response.StatusCode === 409) {
          this.errorMessage = true;
          this.sucess_msg = response.message;
          this.assignformbyemployee.reset();
          this.deleteAllExceptFirstRow();
          this.resetAssignByEmployee();
          this.resetFormData();
          // this.deleteAllExceptFirstRow();
          setTimeout(() => {
            this.assignformbyemployee.reset();
            this.deleteAllExceptFirstRow();
            this.resetAssignByEmployee();
            this.resetFormData();
            this.errorMessage = false;
            this.isSubmitting = false;
            this.assignformbyemployee.reset();
          }, 3000);

          // setTimeout(() => {
          //   this.error = false;
          //   this.error_msg = '';
          //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          //   const currentUrl = this.router.url;
          //   this.router
          //     .navigateByUrl('/', { skipLocationChange: true })
          //     .then(() => {
          //       this.router.navigateByUrl(currentUrl);
          //     });
          // }, 1000);
        }
      },
      (err) => {
        console.log('err');

        if (err.statusCode == 409) {
          // setTimeout(() => {
          this.errorMessage = true;
          this.error_msg = 'Task is Already Assigned for the Employees';

          this.deleteAllExceptFirstRow();
          this.resetAssignByEmployee();
          this.resetFormData();
          // }, 3000);
        }
        // this.deleteAllExceptFirstRow();
        if (err.error.statusCode === 409 || err.error.errorCode === 409) {
          // setTimeout(() => {
          this.errorMessage = true;
          this.error_msg = 'Task is Already Assigned for the Employees';
          // }, 3000);

          setTimeout(() => {
            this.errorMessage = false;
            this.error_msg = '';
            this.deleteAllExceptFirstRow();
            this.resetAssignByEmployee();
            this.resetFormData();
          }, 3000);
        }

        this.assignformbyemployeearray.length = 0;
      }
    );
  }

  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    const endDate = event.value;

    // Iterate through the FormArray
    const tableRowsArray = this.assignformbyemployee.get(
      'tableRows'
    ) as FormArray;
    let invalidEndDateFound = false; // Flag to track if any invalid end date is found

    tableRowsArray.controls.forEach((row) => {
      const startDate = row.get('startdate').value;
      const rowEndDate = row.get('enddate').value;

      // Check if start date is greater than end date for each row
      if (startDate && rowEndDate && startDate > rowEndDate) {
        // Set error state for the row
        row.get('startdate').setErrors({ endDateInvalid: true });
        invalidEndDateFound = true; // Set flag if an invalid end date is found
      } else {
        // Reset error if valid
        row.get('startdate').setErrors(null);
      }
    });

    // If any invalid end date is found, alert the user
    if (invalidEndDateFound) {
      alert('Start date cannot be greater than end date');
      const control = this.assignformbyemployee.get('tableRows') as FormArray;
      control.controls.forEach((row) => {
        // row.get('employee').reset(); // Reset employee control
        row.get('startdate').reset(); // Reset startdate control
        row.get('enddate').reset(); // Reset enddate control
        // row.get('hours').reset(); // Reset hours control
        // row.get('minutes').reset(); // Reset minutes control
      });
      this.startDatePicker.close();
      this.endDatePicker.close();
      this.startDatePicker1.close();
      this.endDatePicker2.close();
    }

    // Other code...
  }

  updateEndDateMinbyEmployee(event: MatDatepickerInputEvent<Date>) {
    const endDate = event.value;

    // Iterate through the FormArray
    const tableRowsArray = this.assignformbytask.get('tableRows1') as FormArray;
    let invalidEndDateFound = false; // Flag to track if any invalid end date is found

    tableRowsArray.controls.forEach((row) => {
      const startDate = row.get('startdate1').value;
      const rowEndDate = row.get('enddate1').value;

      // Check if start date is greater than end date for each row
      if (startDate && rowEndDate && startDate > rowEndDate) {
        // Set error state for the row
        row.get('startdate1').setErrors({ endDateInvalid: true });
        invalidEndDateFound = true; // Set flag if an invalid end date is found
      } else {
        // Reset error if valid
        row.get('startdate1').setErrors(null);
      }
    });

    // If any invalid end date is found, alert the user
    if (invalidEndDateFound) {
      alert('Start date cannot be greater than end date');
      this.startDatePicker1.close();
      this.endDatePicker2.close();
      const control = this.assignformbytask.get('tableRows1') as FormArray;

      control.controls.forEach((row) => {
        // row.get('taskname1').reset(); // Reset taskname1 control
        row.get('startdate1').reset(); // Reset startdate1 control
        row.get('enddate1').reset(); // Reset enddate1 control
        // row.get('hours1').reset(); // Reset hours1 control
        // row.get('minutes1').reset();
      });
    }

    // Other code...
  }

  submitForm1() {
    this.submitButtonEnableEmployee = false;
    this.disableAssignEmployeeButton = false;
    this.submitEmployee = false;
    // this.employeeProjectsTasks.push(this.assignByEmployeeArray);

    //   console.log('employeeProjectsTasks',this.employeeProjectsTasks);

    // if (this.employeeIdTask > 0) {
    this.assignformbytask.patchValue({
      employee1: this.employee,
    });
    // }

    this.submitted2 = true;

    this.submitClickedEmployee = false;
    this.isSubmittingAssignByEmployee = true;
    const control = this.assignformbytask.get('tableRows1') as FormArray;

    console.log('chekcingdata', control);

    if (this.assignformbytask.invalid) {
      this.submitEmployee = false;
      const invalid = [];
      const controls = this.assignformbytask.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
          invalid.push(name);
        }
      }

      return;
    } else {
      this.submitEmployee = true;
    }

    var valueArr = control.controls.map(function (item) {
      return item.value.taskname1;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });

    console.log(isDuplicate);

    //    console.log("this.tasknameexist" + this.tasknameexist)

    if (this.assignformbytask.valid && isDuplicate) {
      Swal.fire({
        icon: 'error',
        text: 'Task Repeats in Form',
      });
      return;
    }

    this.touchedRows1 = control.controls
      .filter((row) => row.touched)
      .map((row) => row.value);
    console.log(this.touchedRows1);
    let assignformbytaskarray = [];
    this.touchedRows1.forEach((element) => {
      let startdate = this.datepipe.transform(element.startdate1, 'YYYY-MM-dd');
      let enddate = this.datepipe.transform(element.enddate1, 'YYYY-MM-dd');

      let assignedhours = Number(element.hours1);
      let assignedMinutes = Number(element.minutes1);

      if (assignedMinutes >= 60) {
        assignedhours += Math.floor(assignedMinutes / 60);
        assignedMinutes = assignedMinutes % 60;
      }

      console.log(' this.employeeIdTask::', this.employeeIdTask);

      this.payload = {
        task: {
          taskId: element.taskname1,
        },
        empolyeeId: this.employeeIdTask,
        assignedhours: assignedhours,
        assignedminutes: assignedMinutes,
        estimatedHours: assignedhours,

        estimatedMinutes: assignedMinutes,
        startdate: startdate,
        enddate: enddate,
        organizationId: this.organizationId,
        status: {
          listTypeValueId: 1,
          listTypeValueName: 'Active',
        },
      };
      assignformbytaskarray.push(this.payload);
    });

    if (assignformbytaskarray.length > 0) {
      this.submitButtonEnableEmployee = true;
      this.submitEmployee = true;
    }
    console.log('assignformbytaskarray:::::', assignformbytaskarray);
    // return
    // this.resetAssignByEmployee();
    this.gs.assignEmployee(assignformbytaskarray).subscribe(
      (a: any) => {
        if (a.StatusCode === 200) {
          this.success = true;
          this.sucess_msg = 'Task Assigned Sucessfully';
          this.isSubmittingAssignByEmployee = false;

          this.deleteAllExceptFirstRowform();

          this.resetAssignByEmployee();
          
          setTimeout(() => {
            this.success = false;
            this.taskList = [];
            this.resetAssignByEmployee();
            this.resetFormData();
            this.deleteAllExceptFirstRowform();
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // const currentUrl = this.router.url;
            // this.router
            //   .navigateByUrl('/', { skipLocationChange: true })
            //   .then(() => {
            //     this.router.navigateByUrl(currentUrl);
            //   });
            // this.Taskform.reset();
          }, 3000);

          setTimeout(() => {
            this.success = false;
            this.sucess_msg = '';
            this.taskList = [];
            this.resetAssignByEmployee();
            this.resetFormData();
            this.deleteAllExceptFirstRowform();
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // const currentUrl = this.router.url;
            // this.router
            //   .navigateByUrl('/', { skipLocationChange: true })
            //   .then(() => {
            //     this.router.navigateByUrl(currentUrl);
            //   });
          }, 1000);
        }

        if (a.statusCode === 409) {
          this.errorMessage = true;
          this.error_msg = 'Task is Already Assigned for the Empolyees';
          this.isSubmittingAssignByEmployee = false;
          this.disableAssignEmployeeButton = true;
          setTimeout(() => {
            this.errorMessage = false;
            this.taskList = [];
            this.resetAssignByEmployee();
            this.resetFormData();
            this.deleteAllExceptFirstRowform();
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // const currentUrl = this.router.url;
            // this.router
            //   .navigateByUrl('/', { skipLocationChange: true })
            //   .then(() => {
            //     this.router.navigateByUrl(currentUrl);
            //   });
            // this.Taskform.reset();
          }, 5000);

          setTimeout(() => {
            this.error = false;
            this.error_msg = '';
            this.errorMessage = false;
            this.taskList = [];
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            const currentUrl = this.router.url;
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.router.navigateByUrl(currentUrl);
              });
          }, 1000);
        }
        // Swal.fire({
        //   icon: 'success',
        //   text: a.message,
        // });
        // window.location.reload();
      },
      (err) => {
        //alert("error")
        // console.log(err.error);

        console.log(err, 'err');

        if (err.error.statusCode === 409 || err.error.errorCode === 409) {
          // setTimeout(() => {
          this.errorMessage = true;
          this.error_msg = 'Task is Already Assigned for the Empolyees';
          this.disableAssignEmployeeButton = true;
          this.taskList = [];
          // }, 5000);
          // this.deleteAllExceptFirstRowform()
          setTimeout(() => {
            this.errorMessage = false;
            this.deleteAllExceptFirstRowform();

            this.resetAssignByEmployee();
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // const currentUrl = this.router.url;
            // this.router
            //   .navigateByUrl('/', { skipLocationChange: true })
            //   .then(() => {
            //     this.router.navigateByUrl(currentUrl);
            //   });
            // this.Taskform.reset();
          }, 5000);
        }
        assignformbytaskarray.length = 0;
        //  Swal.fire({
        //    icon: 'error',
        //    text: err.error.message

        //  })
        //  setTimeout(() => {
        //   window.location.reload();
        //  }, 3000);
      }
    );
  }

  /* validation ++++++++++++++++ */
  get projectname() {
    return this.assignformbyemployee.get('projectname');
  }
  get taskname() {
    return this.assignformbyemployee.get('taskname');
  }
  get projectname1() {
    return this.assignformbytask.get('projectname1');
  }
  get employee1() {
    return this.assignformbytask.get('employee1');
  }

  importEmployee() {
    //Add Dialog Box
    const dialogRef = this.dialog.open(AssignEmployeeImportComponentComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      // height: 'fit-content',
      // maxWidth: '90%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //this.departmentList();
      if (result.statusCode == 200) {
        this.success = true;
        this.sucess_msg = 'Task Assigned Sucessfully ';
        setTimeout(() => {
          this.success = false;
        }, 3000);
      } else {
        if (result.length != 0) {
          this.error = true;
          // this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, responseMsgTimeOut)
        } else {
          if (result.statusCode > 400) {
            this.error = true;

            this.importerror = false;
          }
        }
      }
    });
  }

  get hours1() {
    return this.assignformbytask.get('hours1');
  }
  get hours() {
    return this.assignformbytask.get('hours');
  }

  showerrormessage() {
    const dialogRef = this.dialog.open(AssignEmployeeErrorComponent, {
      //height: 'auto',width: '600px',
      width: '600px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }

  disableCopy(event: ClipboardEvent) {
    event.preventDefault();
  }
}
