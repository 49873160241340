import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsComponent } from '../projects/projects.component';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { NgIfContext } from '@angular/common';

@Component({
  selector: 'app-emp-report',
  templateUrl: './emp-report.component.html',
  styleUrls: ['./emp-report.component.scss']
})
export class EmpReportComponent implements OnInit {
  loader:boolean = true;
  Id:any;
  projectList:any = {};
  projectStage:any = {}
  taskType:any;
  taskList:any = []
  tableList:boolean;
  p:any;
  nodata = false;

  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any = 5;
  search: any;
  beforesearchpage:any;

  tableShow:boolean =  false;
  constructor(private gs:ProjectManagementService,
              private route:ActivatedRoute,
              private dialog:MatDialog,
              private router:Router) { 

                console.log('emp-report loaded');
                
              }

  ngOnInit() {
    this.projectList.length = 0;
    this.route.paramMap.subscribe(
      d =>{
        this.Id = d.get('id');
        this.gs.getprojectByID(this.Id).subscribe(
          (data:any)=>{
            this.projectList = data;
            this.tableShow = true;
            console.log('this.projectList',this.projectList)
            this.projectStage = data.projectStage;
            this.loader = false;
            if (this.projectList.employeeProjects.length === 0) {
              this.nodata = true;
              console.log("nodata")
              this.loader = false;
              this.tableShow = true;
            }
          },
          (error) => {
            this.nodata = true;
            this.loader = false;
            this.tableShow = true;
            console.log("error")
            if(error.status == 404){
              this.nodata = true;
              console.log("404")
            }
          }
        );
      }
    )
  }
  changepage(){
    if(this.search.length != 0)
    {
      this.p = 1;
    }else{
      this.p = this.beforesearchpage;
    }
  }

  changefirstpage()
  {
    this.p = 1;
  }
  open(){
    this.tableList = true;
  }

  report(Id:number,empId:number){

    console.log('Id,empId',Id,empId);
    
    // this.router.navigate([`status/${Id}/${empId}`]);
    this.router.navigate([`/home/projects/status/${Id}/${empId}`]);
  }

  close(){
    this.tableList = false;
  }

  sortData(sort: Sort) {
    const data = this.projectList.employeeProjects.slice();
    if (!sort.active || sort.direction === '') {
      this.projectList['employeeProjects']  = data;
      return;
    }

    this.projectList['employeeProjects'] = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'employeeNumber':
          return this.compare(a.employee.employeeNumber, b.employee.employeeNumber, isAsc);
        case 'employeeName':
          return this.compare(a.employee.firstName, b.employee.firstName, isAsc);
        case 'organizationEmail':
          return this.compare(a.employee.organizationEmail, b.employee.organizationEmail, isAsc);
        case 'rolename':
          return this.compare(a.employeeProjectRole.rolename, b.employeeProjectRole.rolename, isAsc);
        default:
          return 0;
      }
    });
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


}
