import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { ProjectsService } from 'src/app/providers/projects.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-employee-project',
  templateUrl: './add-employee-project.component.html',
  styleUrls: ['./add-employee-project.component.scss'],
})
export class AddEmployeeProjectComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  success = false;
  error: any = false;
  sucess_msg: any;

  errormessagejson: any;
  error_msg: any;
  allSelected = false;
  employeeForm: FormGroup;
  employeeList: any = [];
  projectList: any = [];
  arrayData: any = [];
  ProjectName: any;
  project: any = [];
  roleList: any = [];
  empData: boolean;
  projectId: any;
  submitted = false;
  employeeId: any;
  today: any = new Date();
  employeelastdate: any;
  employeeProjects: any;
  selectedemployeeId: any;
  employeeNumber: any;
  selectedemployee: any;
  employee: string;
  body: any;
  selectedEmployees:any[]=[];
  assignEmployeeButton:boolean= true;
  constructor(
    public dialogRef: MatDialogRef<AddEmployeeProjectComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs: ProjectManagementService,
    private datepipe: DatePipe,
    private timesheet: TimesheetService
  ) {}

  ngOnInit() {
    console.log("data",this.data);
    this.timesheet.setSuccessMessage('');
    this.timesheet.setSuccessUpdateMessage('');
    this.projectId = this.data.projectId;
    this.ProjectName = this.data.projectName;

    this.employeeForm = this.fb.group({
      employee: ['', Validators.required],
      rolename: ['', Validators.required],
    });
console.log(this.empData);
console.log(this.data?.empprojectId);
    if (this.data?.empprojectId) {
        this.loadEmployeeData();
    } else {
      console.log("else");
        this.loadEmployeeList();
    }
}

loadEmployeeData() {
    this.gs.getResourceEmployees().subscribe((x: any) => {
        this.employeeList = x;
        this.gs.getRole().subscribe((x: any) => {
            this.roleList = x;
            this.gs.getEmp_Project(this.data.empprojectId).subscribe(
                (d: any) => {
                    const employeeValue = Array.isArray(d.employeeId) ? d.employeeId : [d.employeeId];
                    this.employeeForm.patchValue({
                        employee: employeeValue,
                        rolename: this.data.employeeProjectRole.employeeProjectRoleId,
                    });
                },
                (error) => {
                    console.error('Error fetching data:', error);
                }
            );
        });
    });
}

loadEmployeeList() {
    this.gs.getResourceEmployees().subscribe((x: any) => {
        this.employeeList = x;
        console.log("this.employeeList",this.employeeList);
    });

    this.gs.getRole().subscribe((x: any) => {
        this.roleList = x;
    });
}


  get f() {
    return this.employeeForm?.controls;
  }

  _Employee() {
    return this.f.employee.hasError('required')
      ? 'Please select Employees'
      : '';
  }

  toggleAllSelection() {
    if (!this.data.empprojectId) {
        if (this.allSelected) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }
}
  employeeSelected(employeeId: number) {
    if (this.data.empprojectId) {
        // If empprojectId is present, ensure only one employee can be selected
        this.employeeForm.patchValue({
            employee: [employeeId]
        });
    }

   
}
  
individualCheckboxChanged() {
  const selectedEmployeeIds = this.employeeForm.get('employee').value;
  if (selectedEmployeeIds.length === this.employeeList.length && selectedEmployeeIds.length !== 0) {
    this.allSelected = true;
    console.log("if entering inside option");
  } else {
    this.allSelected = false;
    console.log("else condition");
  }
}

  add() {
    let x = this.employeeForm.value;
    if (x.employee == '') {
      alert('Please select the options');
    } else {
      this.select.close();
    }
  }

  cancel() {
    this.employeeForm.patchValue({ employee: '' });
    this.select.close();
  }

  save() {
    // this.submitted = true;
    // if (this.employeeForm.invalid) {
    //   return;
    // }
    this.assignEmployeeButton = false;

    let x = this.employeeForm.value;
    console.log("x form values::",x);
    // console.log("x.employeeId",x.employeeId);
    const employeeIds= x.employee[0]; // Accessing the first element of the 'employee' array

console.log("Employee ID:", employeeIds);
console.log("x.rolename",x.rolename);

    
    

    if (this.data.empprojectId) {
      // alert('if');
      // (x['empprojectId'] = this.data.empprojectId),
      //   (x['project'] = {
      //     projectId: this.data.projectId,
      //   });
      // x['employee'] = {
      //   employeeId: x.employee,
      // };
      // x['userRole'] = {
      //   userroleId: x.rolename,
      // };

      // console.log('JSON.stringify(x)', JSON.stringify(x));

      let Payload = {
        project: {
          projectId: this.data?.projectId,
        },
        employeeId: 
        employeeIds,
        status: {
          listTypeValueId: this.data.status.listTypeValueId,
        },
        employeeProjectRole: {
          employeeProjectRoleId:
            x.rolename,
        },
        empprojectId:this.data.empprojectId
      };

      console.log('body', Payload);

      // {"employee":{"employeeId":[8039]},"rolename":1,"empprojectId":15,"project":{"projectId":2},"userRole":{"userroleId":1}}
console.log("this.data.empprojectId",this.data.empprojectId);
// return

      this.gs
        .update_EmpRole(this.data.empprojectId, Payload)
        .subscribe((e: any) => {
          // Swal.fire({
          //   text:e.message,
          //   icon:'success'
          // })
        //   if(e.statusCode==200){
        //     this.success=true
        //     this.sucess_msg=e.message;
        //   setTimeout(() => {
        //     this.success = false;
        //   }, 2000);
        // }
        //   setTimeout(() => {
        //     this.dialog.closeAll();
        //   }, 2000);

        if(e.StatusCode === 200){

          this.submitted =  false;
          setTimeout(() => {
            this.success = true;
            this.sucess_msg = 'Employee Updated Successfully';
          }, 300);
        }
       

        setTimeout(() => {
          this.dialog.closeAll();
        }, 2000);
        },
        (err)=>{
          this.error=true
          this.error_msg=err.error.message
          setTimeout(() => {
            this.error=false
            this.dialog.closeAll();
          }, 2000);
        }
        );
    } else {
      if (this.projectId) {
        if (!x.employee) {
          // If x.employee is null, push the data again
          this.arrayData.push({
            employeeId: null,
            project: {
              projectId: this.projectId,
            },
            status: {
              listTypeValueId: 1,
            },
            employeeProjectRole: {
              employeeProjectRoleId: x.rolename,
            },
          });
        } else {
          x.employee.map((e) => {
            if (e) {
              let data;
              console.log('e', e);
              console.log('this.data.projectId', this.data.projectId);
              this.body = {
                employeeId: e,
                project: {
                  projectId: this.projectId,
                },
                status: {
                  listTypeValueId: 1,
                },
                employeeProjectRole: {
                  employeeProjectRoleId: x.rolename,
                },
              };
              // Check if this.body is not null before pushing
              if (this.body) {
                this.arrayData.push(this.body);
              }

              if ( this.arrayData.length == null) {
                this.body = {
                  employeeId: e,
                  project: {
                    projectId: this.projectId,
                  },
                  status: {
                    listTypeValueId: 1,
                  },
                  employeeProjectRole: {
                    employeeProjectRoleId: x.rolename,
                  },
                };
                this.arrayData.push(this.body);
              }
            }
          });
        }

        console.log('array',this.arrayData);
        
        // Only proceed if arrayData is not empty and doesn't contain null values
        if (this.arrayData.length > 0 && this.arrayData.every(data => data !== null)) {
          console.log(JSON.stringify(this.arrayData));
          this.gs.AssignEmpRole(this.arrayData).subscribe(
            (e: any) => {
              if (e.StatusCode === 200) {
                this.submitted = false;
                setTimeout(() => {
                  this.success = true;
                  this.sucess_msg = 'Employee Assigned Successfully';
                }, 300);
              }
              setTimeout(() => {
                this.dialog.closeAll();
              }, 2000);
            },
            (err) => {
              console.log(err.error);
              this.error = true;
              this.error_msg = 'Project is Already Assigned for the Employees';
              setTimeout(() => {
                this.error = false;
                this.dialog.closeAll();
              }, 2000);
            }
          );
        }
      }
    }
    
    
    
  }

  empSearch(event) {
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if (employeenumber) {
      // this.payrollService.getEmployeeNameList(employeenumber, this.organizationId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }

      // })

      this.gs.getEmployeesBynumber(employeenumber).subscribe((result: any) => {
        this.employeeProjects = result;

        console.log('this.employeeProjects', this.employeeProjects);
        if (result.statusCode) {
        } else {
          this.employeeProjects = result;

          console.log('this.employeeProjects', this.employeeProjects);
        }
      });
    }
  }

  empSelect(emp) {
    console.log('emp', emp);
    this.employeeId = emp.employeeId;
    this.employee = emp.employeeNumber + '-' + emp.firstName;
    this.employeeNumber = emp.employeeNumber;
    this.selectedemployee = this.employee;
    this.selectedemployeeId = this.employeeId;
    // this.assignformbyemployee.patchValue({
    //   employeecode: this.employee,
    // });
    //  let  employeecode=this.selectedemployeeId;

    // console.log('this.employee', employeecode);
  }
}
