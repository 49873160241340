<div class="table" style="padding: 17px; margin-top: -27px">
  <table matSort class="table">
    <thead class="t-head">
      <tr id="trDesign">
        <th class="text-center">SI.NO</th>
        <th class="text-center">Client Code</th>
        <th class="text-center">Client Name</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="
          let item of data
            | paginate : { itemsPerPage: mySelect, currentPage: p };
          let i = index
        "
      >
        <td style="text-align: center" *ngIf="p">
          {{(p - 1) * mySelect + (i + 1) }}
        </td>
        <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
        <td>{{ item.clientCode }}</td>
        <td>{{ item.clientName }}</td>
      </tr>
    </tbody>
  </table>
</div>
