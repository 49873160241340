<mat-sidenav-container class="example-container sidenav-container" autosize>
  <!-- mat side nav start here -->
  <mat-sidenav
    #drawer
    class="example-sidenav sidenav"
    id="sidenavB"
    mmode="side"
    [class.menu-open]="expand"
    [class.menu-close]="!expand"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <ng-container
      *ngIf="expand && hideMenus; else logoElse"
      class="logo-background"
    >
      <!-- matTooltip="JesperApps HCM" -->
      <mat-nav-list
        (mouseenter)="JesperApps = true"
        (mouseleave)="JesperApps = false"
        class="logo logo-background"
      >
        <img
          src="{{ appLogo }}"
          class="brand"
          [class.header-logo2]="!hideMenus"
          [class.img]="!hideMenus"
          [class.header-logo]="hideMenus && hideModules"
          alt=""
          [matTooltip]="JesperApps ? 'JesperApps HCM' : ''"
          (mouseenter)="JesperApps = true"
          (mouseleave)="JesperApps = false"
          (click)="moveToDashboard()"
        />
      </mat-nav-list>
    </ng-container>

    <ng-template #logoElse>
      <mat-nav-list class="logoB" *ngIf="hideModules">
        <img
          src="{{ appLogoIcon }}"
          class="brandB"
          alt=""
          matTooltip="JesperApps HCM"
          (click)="moveModule()"
        />
      </mat-nav-list>
    </ng-template>
    <div class="scrollable-container">
      <mat-nav-list
        class="mat-nav-list list-group p-0 scroll"
        style="height: 87%"
      >
        <div class="d-flex">
          <div
            class="justify-content-center"
            *ngIf="hideModules"
            [class.modules-width]="hideModules"
          >
            <div>
              <div
                *ngFor="let row of results"
                class="modules-name col-md-3 justify-content-center"
              >
                <a
                  (click)="selectModules(row.moduleCode)"
                  style="color: black"
                  class="text-primary1 justify-content-center"
                  *ngIf="row.moduleName != undefined"
                  title="{{ ucFirst(row.moduleName) }}"
                  routerLinkActive="active"
                  [ngClass]="{
                    'selected-module': localStorageMenuId === row.moduleCode
                  }"
                >
                  <!-- <div class="top-row justify-content-center">
                    <mat-icon
                      *ngIf="row.moduleCode == 'CHRMS'"
                      class="icon-primary1"
                      >verified_user</mat-icon
                    >

                    <mat-icon
                      *ngIf="row.moduleCode == 'RQMNT'"
                      class="icon-primary1"
                      >work</mat-icon
                    >
                    <mat-icon
                      *ngIf="row.moduleCode == 'PAYROLL'"
                      class="icon-primary1"
                      >payment</mat-icon
                    >
                    <mat-icon
                      *ngIf="row.moduleCode == 'PMS'"
                      class="icon-primary1"
                      >graphic_eq</mat-icon
                    >
                    <mat-icon
                      *ngIf="row.moduleCode == 'LEAVE'"
                      class="icon-primary1"
                      >calendar_month</mat-icon
                    >
                    <mat-icon
                      *ngIf="row.moduleCode == 'PROJECTMANAGEMENT'"
                      class="icon-primary1"
                      >description</mat-icon
                    >
                    <mat-icon
                      *ngIf="row.moduleCode == 'EXPENSEREIMBURSEMENT'"
                      class="icon-primary1"
                      >attach_money</mat-icon
                    >

                    <span class="card-name1">{{
                      ucFirst(row.moduleName)
                    }}</span>
                  </div> -->

                  <div class="top-row justify-content-center">
                    <!-- <mat-icon *ngIf="row.moduleCode == 'CHRMS' " class="icon-primary1">verified_user</mat-icon> -->
                    <img
                      *ngIf="
                        row.moduleCode === 'CHRMS' &&
                        (isAdmin == true ||
                          isHumanResource == true ||
                          isUserAdmin)
                      "
                      [src]="
                        localStorageMenuId === row.moduleCode
                          ? '../../assets/uploads/Coe HRMS 1.png'
                          : '../../assets/uploads/Coe HRMS 0.png'
                      "
                      alt="CHRMS Icon"
                      class="icon-primary1 hover-image"
                      id="imageToSwap"
                    />
                    <span
                      class="card-name1 mb-4"
                      *ngIf="
                        row.moduleCode === 'CHRMS' &&
                        (isAdmin == true ||
                          isHumanResource == true ||
                          isUserAdmin)
                      "
                      >{{ ucFirst(row.moduleName) }}</span
                    >
                    <img
                      *ngIf="
                        row.moduleCode === 'RQMNT' &&
                        !isUserAdmin &&
                        (isRequirements == true || isHumanResource == true)
                      "
                      [src]="
                        localStorageMenuId === row.moduleCode
                          ? '../../assets/uploads/Recruitment 1.png'
                          : '../../assets/uploads/Recruitment 0.png'
                      "
                      alt="Verified User Icon"
                      class="icon-primary1 hover-image"
                      id="imageToSwap"
                    />
                    <span
                      class="card-name1 mb-4"
                      *ngIf="
                        row.moduleCode === 'RQMNT' &&
                        !isUserAdmin &&
                        (isRequirements == true || isHumanResource == true)
                      "
                      >{{ ucFirst(row.moduleName) }}</span
                    >

                    <img
                      *ngIf="
                        row.moduleCode === 'PAYROLL' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      [src]="
                        localStorageMenuId === row.moduleCode
                          ? '../../assets/uploads/Payroll 1.png'
                          : '../../assets/uploads/Payroll 0.png'
                      "
                      alt="Verified User Icon"
                      class="icon-primary1 hover-image"
                      id="imageToSwap"
                    />
                    <span
                      class="card-name1 mb-4"
                      *ngIf="
                        row.moduleCode === 'PAYROLL' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      >{{ ucFirst(row.moduleName) }}</span
                    >

                    <img
                      *ngIf="
                        row.moduleCode === 'PMS' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      [src]="
                        localStorageMenuId === row.moduleCode
                          ? '../../assets/uploads/PMS 1.png'
                          : '../../assets/uploads/PMS 0.png'
                      "
                      alt="Verified User Icon"
                      class="icon-primary1 hover-image"
                      id="imageToSwap"
                    />
                    <span
                      class="card-name1 mb-4"
                      *ngIf="
                        row.moduleCode === 'PMS' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      >{{ ucFirst(row.moduleName) }}</span
                    >
                    <!-- <mat-icon *ngIf="row.moduleCode == 'LEAVE'" class="icon-primary1">calendar_month</mat-icon> -->
                    <img
                      *ngIf="
                        row.moduleCode === 'LEAVE' &&
                        !isUserAdmin &&
                        (isHumanResource == true || isEmployee == true)
                      "
                      [src]="
                        localStorageMenuId === row.moduleCode
                          ? '../../assets/uploads/Leave 1.png'
                          : '../../assets/uploads/Leave 0.png'
                      "
                      alt="Verified User Icon"
                      class="icon-primary1 hover-image"
                      id="imageToSwap"
                    />
                    <span
                      class="card-name1 mb-4"
                      *ngIf="
                        row.moduleCode === 'LEAVE' &&
                        !isUserAdmin &&
                        (isHumanResource == true || isEmployee == true)
                      "
                      >{{ ucFirst(row.moduleName) }}</span
                    >
                    <!-- <mat-icon *ngIf="row.moduleCode == 'PROJECTMANAGEMENT'" class="icon-primary1">description</mat-icon> -->
                    <img
                      *ngIf="
                        row.moduleCode === 'PROJECTMANAGEMENT' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      [src]="
                        localStorageMenuId === row.moduleCode
                          ? '../../assets/uploads/Project Manaement w.png'
                          : '../../assets/uploads/Project Manaement.png'
                      "
                      alt="Verified User Icon"
                      class="icon-primary1 hover-image"
                      id="imageToSwap"
                    />
                    <span
                      class="card-name1 mb-4"
                      *ngIf="
                        row.moduleCode === 'PROJECTMANAGEMENT' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      >{{ ucFirst(row.moduleName) }}</span
                    >
                    <!-- <mat-icon *ngIf="row.moduleCode == 'EXPENSEREIMBURSEMENT'"
                      class="icon-primary1">attach_money</mat-icon> -->
                    <img
                      *ngIf="
                        row.moduleCode === 'EXPENSEREIMBURSEMENT' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      [src]="
                        localStorageMenuId === row.moduleCode
                          ? '../../assets/uploads/reimbursement w.png'
                          : '../../assets/uploads/reimbursement.png'
                      "
                      alt="Verified User Icon"
                      class="icon-primary1 hover-image"
                      id="imageToSwap"
                    />

                    <span
                      class="card-name1 mb-4"
                      *ngIf="
                        row.moduleCode === 'EXPENSEREIMBURSEMENT' &&
                        !isUserAdmin &&
                        isEmployee == true
                      "
                      >{{ ucFirst(row.moduleName) }}</span
                    >
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="vertical-line" *ngIf="hideMenus"></div>
          <div
            class="col-md-9"
            [class.mat-menus-sideNav]="!hideMenus"
            [class.mat-menus-sideNav1]="hideMenus && hideModules"
            [class.mat-list-base1]="hideMenus"
            [class.mat-list-content3]="!hideMenus"
            [class.no-module-code]="results.length === 0"
            *ngIf="hideMenus && expand"
          >
            <!-- <mat-list-item
              class="mat-list-item"
              routerLink="home"
              routerLinkActive="active"
            >
              <mat-icon class="mat-sidebar-icon">home</mat-icon>
              <a *ngIf="expand" mat-list-item class="sidebar-icons">
                Home
           
              </a>
            </mat-list-item> -->
            <!-- {{first_segment}} -->
            <!-- Core HRMS start here -->
            <div
              *ngIf="localStorageMenuId === 'CHRMS' && checkmoduledata('CHRMS')"
            >
              <mat-list-item
                routerLink="dashboard"
                routerLinkActive="active"
                *ngIf="checkmenudata('Dashboard')"
                (click)="setHeaderTitle2()"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                  <a *ngIf="expand" class="sidebar-icons side-menu">
                    Dashboard
                    <!-- {{localStorageMenuId}} -->
                  </a>
                </div>
              </mat-list-item>

              <!-- <mat-list-item
                routerLink="employee"
                *ngIf="checkmenudata('Employees')"
                routerLinkActive="active"
              >
              <div class=" met-list-menu">
                <mat-icon class="mat-sidebar-icon icon-styles">badge</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons side-menu">
                  Employees
                </a>
                </div>
              </mat-list-item> -->

              <!-- business group -->
              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? ' Business Group' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                *ngIf="checkmenudata('Business Group') && isUserAdmin"
                routerLinkActive="active"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveBusinessGroup()
                }"
                s
                [class.router-link-active]="isActiveBusinessGroup()"
                (click)="navigateToBusinessGroup()"
                [routerLinkActiveOptions]="{ exact: false }"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">business_center</mat-icon>
                  <a
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Business Group
                  </a>
                </div>
              </mat-list-item>
              <!-- business group end here -->

              <!-- legal entity -->
              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? ' Legal Entity' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                routerLinkActive="active"
                *ngIf="checkmenudata('Legal Entity') && isUserAdmin"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActivelegalEntity()
                }"
                [class.router-link-active]="isActivelegalEntity()"
                (click)="navigateToLegalentity()"
                [routerLinkActiveOptions]="{ exact: false }"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">how_to_reg</mat-icon>
                  <a
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Legal Entity
                  </a>
                </div>
              </mat-list-item>
              <!-- end legal entity here -->

              <!-- Business Unit -->
              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? ' Business Unit' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                routerLinkActive="active"
                *ngIf="checkmenudata('Business Unit') && isUserAdmin"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveBusinessUnit()
                }"
                [class.router-link-active]="isActiveBusinessUnit()"
                (click)="navigateToBusinessUnit()"
                [routerLinkActiveOptions]="{ exact: false }"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">domain</mat-icon>
                  <a
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Business Unit
                  </a>
                </div>
              </mat-list-item>
              <!-- end business unit here -->
              <!-- organization will start here -->
              <!-- <mat-list-item
               [matTooltip]="showTimesheetTooltip ? 'Organization / Client' : ''"
               (mouseenter)="showTimesheetTooltip = true"
               (mouseleave)="showTimesheetTooltip = false"
               routerLinkActive="active"
               [ngClass]="{
                'mat-list-item': true,
                active: isActiveOrganization()
              }"
              [class.router-link-active]="isActiveOrganization()"
              (click)="navigateToOrganization()"
              [routerLinkActiveOptions]="{ exact: false }"

             >
               <div class="met-list-menu">
                <mat-icon class="mat-sidebar-icon">account_balance</mat-icon>
                <a
                   (mouseenter)="showTimesheetTooltip = true"
                   (mouseleave)="showTimesheetTooltip = false"
                   *ngIf="expand"
                   class="sidebar-icons side-menu"
                 >
                   Organization / Client
                 </a>
               </div>
             </mat-list-item> -->
              <!-- dummy org -->
              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClickOrganizationClient()"
                  routerLinkActive="active"
                  *ngIf="isAdmin || isUserAdmin"
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon class="mat-sidebar-icon">people</mat-icon>

                    <span *ngIf="expand" class="mat-item side-menu">
                      Organization&nbsp;/&nbsp;Client
                    </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-clientArrow"
                      *ngIf="expand"
                    >
                      {{
                        isShowClientArrow ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>

                <mat-list-item
                  (mouseenter)="clientWorkingDaysTooltips = true"
                  (mouseleave)="clientWorkingDaysTooltips = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteclientWorkingDays()
                  }"
                  *ngIf="
                    isShowClient == true &&
                    checkmenudata('CHRMS Working Days') &&
                    !isUserAdmin &&
                    isAdmin
                  "
                  [class.router-link-active]="isActiveRouteclientWorkingDays()"
                  routerLinkActive="active"
                  (click)="navigateToclientWorkingDays()"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >
                    <span
                      class="mat-item side-menu"
                      [matTooltip]="
                        clientWorkingDaysTooltips ? ' Working Days' : ''
                      "
                      (mouseenter)="clientWorkingDaysTooltips = true"
                      (mouseleave)="clientWorkingDaysTooltips = false"
                      routerLinkActive="active"
                    >
                      Working Days</span
                    >
                  </div>
                </mat-list-item>
                <mat-list-item
                  [matTooltip]="'Organization / Client'"
                  (mouseenter)="showTimesheetTooltip = true"
                  (mouseleave)="showTimesheetTooltip = false"
                  routerLinkActive="active"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveOrg()
                  }"
                  [class.router-link-active]="isActiveOrg()"
                  (click)="navigateToOrg()"
                  [routerLinkActiveOptions]="{ exact: false }"
                  *ngIf="
                    isShowClient == true &&
                    checkmenudata('Organization / Client') &&
                    isUserAdmin
                  "
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >
                    <a
                      (mouseenter)="showTimesheetTooltip = true"
                      (mouseleave)="showTimesheetTooltip = false"
                      *ngIf="expand"
                      class="mat-item side-menu"
                    >
                      Organization / Client
                    </a>
                  </div>
                </mat-list-item>

                <!-- *ngIf="isShowClient && checkmenudata('Client Working Days')" -->

                <!-- *ngIf="
         isShowClient && checkmenudata('Client Element Assignment')
       " -->
                <mat-list-item
                  routerLinkActive="active"
                  [matTooltip]="clientElementsTooltips ? ' Elements' : ''"
                  (mouseenter)="clientElementsTooltips = true"
                  (mouseleave)="clientElementsTooltips = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteclientElements()
                  }"
                  [class.router-link-active]="isActiveRouteclientElements()"
                  (click)="navigateToclientElements()"
                  *ngIf="
                    isShowClient == true &&
                    checkmenudata('CHRMS Elements') &&
                    !isUserAdmin &&
                    !isAdmin &&
                    !clientLogin
                  "
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >
                    <span
                      (click)="toggleProjectsClose()"
                      (click)="toggleLoan()"
                      class="mat-item side-menu"
                      routerLinkActive="active"
                    >
                      Assign Elements</span
                    >
                    <!-- <li class="p-0">
         <a
           (click)="toggleProjectsClose()"
           mat-list-item
           routerLink="manage-client-element-list"
           routerLinkActive="active"
           >Client Elements</a
         >
       </li> -->
                  </div>
                </mat-list-item>

                <!-- *ngIf="
       isShowClient == true &&
       checkmenudata('Employee Client Assignment')
     " -->

                <mat-list-item
                  [matTooltip]="employeeClientAssignment ? 'Tax Breakup' : ''"
                  routerLink="select-tax-breakup"
                  routerLinkActive="active"
                  (mouseenter)="employeeClientAssignment = true"
                  (mouseleave)="employeeClientAssignment = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRoute()
                  }"
                  [class.router-link-active]="isActiveRoute()"
                  *ngIf="
                    isShowClient == true &&
                    checkmenudata('CHRMS Tax Breakup') &&
                    !isUserAdmin &&
                    isAdmin
                  "
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      (click)="toggleProjectsClose()"
                      class="mat-item side-menu"
                      routerLink="client-tax-breakup"
                      routerLinkActive="active"
                    >
                      {{ " Tax Breakup" | slice : 0 : 15
                      }}{{ " Tax Breakup".length > 15 ? "..." : "" }}</span
                    >
                    <!-- <li class="p-0">
<a
(click)="toggleProjectsClose()"
mat-list-item
routerLink="empassign"
routerLinkActive="active"
>Employee Client Assignment</a
>
</li> -->
                  </div>
                </mat-list-item>
                <mat-list-item
                  [matTooltip]="
                    employeeClientAssignment ? 'Add Client Employee' : ''
                  "
                  routerLinkActive="active"
                  (mouseenter)="employeeClientAssignment = true"
                  (mouseleave)="employeeClientAssignment = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveClientEmp()
                  }"
                  [class.router-link-active]="isActiveClientEmp()"
                  *ngIf="
                    isShowClient == true &&
                    checkmenudata('CHRMS Client Employee') &&
                    (isUserAdmin || clientLogin)
                  "
                  (click)="navigateToClientEmp()"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      (click)="toggleProjectsClose()"
                      class="mat-item side-menu"
                      routerLinkActive="active"
                    >
                      {{ "Add Client Employee" | slice : 0 : 15
                      }}{{
                        " Add Client Employee".length > 15 ? "..." : ""
                      }}</span
                    >
                    <!-- <li class="p-0">
           <a
             (click)="toggleProjectsClose()"
             mat-list-item
             routerLink="empassign"
             routerLinkActive="active"
             >Employee Client Assignment</a
           >
         </li> -->
                  </div>
                </mat-list-item>
              </div>

              <!-- dummy end here -->

              <!-- organization end here -->
              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? ' Supplier' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                routerLinkActive="active"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveSupplier()
                }"
                [class.router-link-active]="isActiveSupplier()"
                (click)="navigateToSpplier()"
                [routerLinkActiveOptions]="{ exact: false }"
                *ngIf="
                  checkmenudata('Supplier') &&
                  (isUserAdmin == false || isAdmin == false) &&
                  clientLogin
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">group_add</mat-icon>
                  <a
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Supplier
                  </a>
                </div>
              </mat-list-item>

              <!-- assign client supplier -->
              <mat-list-item
                [matTooltip]="
                  showTimesheetTooltip ? ' Assign Client Supplier' : ''
                "
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                routerLinkActive="active"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveClientSupplier()
                }"
                s
                [class.router-link-active]="isActiveClientSupplier()"
                (click)="navigateToClientSpplier()"
                [routerLinkActiveOptions]="{ exact: false }"
                *ngIf="checkmenudata('Assign Client Supplier') && clientLogin"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">assignment</mat-icon>
                  <a
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Assign Client Supplier
                  </a>
                </div>
              </mat-list-item>
              <!-- assign client supplier end  -->
              <!-- <mat-list-item 
              [matTooltip]="showTimesheetTooltip ? 'Assign Client Supplier' : ''"
              (mouseenter)="showTimesheetTooltip = true"
              (mouseleave)="showTimesheetTooltip = false"
              routerLinkActive="active"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveClientSupplier()
                  }"s
                  [class.router-link-active]="isActiveClientSupplier()"
                  (click)="navigateToClientSpplier()"
                  [routerLinkActiveOptions]="{ exact: false }"
            >
              <div class="met-list-menu">
               <mat-icon class="mat-sidebar-icon">assignment</mat-icon>
                <a
                  (mouseenter)="showTimesheetTooltip = true"
                  (mouseleave)="showTimesheetTooltip = false"
                  *ngIf="expand"
                  class="sidebar-icons side-menu"
                >
                  Assign Client Supplier
                </a>
              </div>
            </mat-list-item> -->
              <!-- assign client supplier end  -->

              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? 'Employee Groups' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveEmployeeGroups()
                }"
                [class.router-link-active]="isActiveEmployeeGroups()"
                (click)="navigateToEmployeeGroups()"
                routerLinkActive="active"
                *ngIf="checkmenudata('Employee Groups')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">groups</mat-icon>
                  <a
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Employee Groups
                  </a>
                </div>
              </mat-list-item>

              <div
                class="menu-sub-menus"
                *ngIf="
                  localStorageMenuId === 'CHRMS' && checkmoduledata('CHRMS')
                "
              >
                <mat-list-item
                  (click)="handleCHRMSClickApprover()"
                  routerLinkActive="active"
                  *ngIf="expand && showApproverMenu"
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                      published_with_changes</mat-icon
                    >

                    <span class="mat-item side-menu"> Approver </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-approver"
                      *ngIf="expand"
                    >
                      {{
                        isShowApproverArrow ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>

                <mat-list-item
                  (mouseenter)="manageApproverToolTip = true"
                  (mouseleave)="manageApproverToolTip = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRoutemanageApprover()
                  }"
                  [class.router-link-active]="isActiveRoutemanageApprover()"
                  (click)="navigateTomanageApprover()"
                  *ngIf="
                    isShowApprover == true && checkmenudata('Manage Approver')
                  "
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      class="mat-item side-menu"
                      [matTooltip]="
                        manageApproverToolTip ? 'Manage Approver' : ''
                      "
                      (mouseenter)="manageApproverToolTip = true"
                      (mouseleave)="manageApproverToolTip = false"
                    >
                      Manage Approver
                    </span>
                  </div>
                </mat-list-item>

                <mat-list-item
                  [matTooltip]="
                    employeeApproverToolTip ? 'Employee Approver' : ''
                  "
                  (mouseenter)="employeeApproverToolTip = true"
                  (mouseleave)="employeeApproverToolTip = false"
                  *ngIf="
                    isShowApprover == true && checkmenudata('Employee Approver')
                  "
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteemployeeApprover()
                  }"
                  [class.router-link-active]="isActiveRouteemployeeApprover()"
                  routerLinkActive="active"
                  (click)="navigateToemployeeApprover()"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      class="mat-item side-menu"
                      (mouseenter)="employeeApproverToolTip = true"
                      (mouseleave)="employeeApproverToolTip = false"
                    >
                      {{ "Employee Approver" | slice : 0 : 13
                      }}{{ "Employee Approver".length > 13 ? "..." : "" }}
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item
                  [matTooltip]="showEmployeesTooltip ? 'Employees' : ''"
                  (mouseenter)="showEmployeesTooltip = true"
                  (mouseleave)="showEmployeesTooltip = false"
                  routerLinkActive="active"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveEmployees()
                  }"
                  [class.router-link-active]="isActiveEmployees()"
                  (click)="navigateToEmployees()"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">people_alt</mat-icon>
                    <a
                      [matTooltip]="showTimesheetTooltip ? 'Employees' : ''"
                      (mouseenter)="showEmployeesTooltip = true"
                      (mouseleave)="showEmployeesTooltip = false"
                      *ngIf="expand"
                      class="sidebar-icons side-menu"
                    >
                      Employees
                    </a>
                  </div>
                </mat-list-item>

                <!-- assign Employess -->

                <mat-list-item
                  [matTooltip]="assignEmployee ? ' Manage Employee Level' : ''"
                  (mouseenter)="assignEmployee = true"
                  (mouseleave)="assignEmployee = false"
                  routerLinkActive="active"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveAssignEmployee()
                  }"
                  [class.router-link-active]="isActiveAssignEmployee()"
                  (click)="navigateToAssignEmployee()"
                  [routerLinkActiveOptions]="{ exact: false }"
                  *ngIf="checkmenudata('Assign Employee') && isUserAdmin"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">badge</mat-icon>
                    <a
                      (mouseenter)="assignEmployee = true"
                      (mouseleave)="assignEmployee = false"
                      *ngIf="expand"
                      class="sidebar-icons side-menu"
                    >
                      Manage Employee Level
                    </a>
                  </div>
                </mat-list-item>

                <mat-list-item
                  [matTooltip]="announcement ? ' Announcement' : ''"
                  (mouseenter)="announcement = true"
                  (mouseleave)="announcement = false"
                  routerLinkActive="active"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveAnnouncement()
                  }"
                  [class.router-link-active]="isActiveAnnouncement()"
                  (click)="navigateToAnnouncement()"
                  [routerLinkActiveOptions]="{ exact: false }"
                  *ngIf="
                    checkmenudata('Manage Announcement') &&
                    !isUserAdmin &&
                    (isAdmin || clientLogin)
                  "
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">announcement</mat-icon>
                    <a
                      (mouseenter)="announcement = true"
                      (mouseleave)="announcement = false"
                      *ngIf="expand"
                      class="sidebar-icons side-menu"
                    >
                      Announcement
                    </a>
                  </div>
                </mat-list-item>
              </div>

              <mat-list-item
                routerLink="users"
                *ngIf="checkmenudata('Users')"
                routerLinkActive="active"
              >
                <mat-icon class="mat-sidebar-icon"
                  >supervised_user_circle</mat-icon
                >
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                  Users
                </a>
              </mat-list-item>
            </div>
            <!-- Core HRMS end here -->
            <!-- <mat-list-item
              routerLink="assign-approver"
              routerLinkActive="active"
            >
              <span
                _ngcontent-fwu-c429=""
                class="material-icons-outlined sidenav-icons"
              >
                published_with_changes
              </span>
              <div *ngIf="expand" mat-list-item class="sidebar-icons"
              (click)="setHeaderTitle(' Assign Approvers')">
                Assign Approvers
              </div>
            </mat-list-item> -->
            <!-- Leave start here -->
            <div
              (mouseenter)="showleaveDashboardTooltip = true"
              (mouseleave)="showleaveDashboardTooltip = false"
              *ngIf="localStorageMenuId == 'LEAVE' && checkmoduledata('LEAVE')"
            >
              <mat-list-item
                routerLink="leave-dashboard"
                [matTooltip]="showleaveDashboardTooltip ? 'Dashboard' : ''"
                (mouseenter)="showleaveDashboardTooltip = true"
                (mouseleave)="showleaveDashboardTooltip = false"
                *ngIf="checkmenudata('Leave HR Dashboard')"
                routerLinkActive="active"
                (click)="
                  toggleProjectsClose();
                  toggleLoan();
                  toggleSalaryAdvance();
                  setHeaderTitle2();
                  toggleApprover();
                  toggleClient();
                  toggleMom()
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                    (click)="setHeaderTitle2()"
                    (mouseenter)="showleaveDashboardTooltip = true"
                    (mouseleave)="showleaveDashboardTooltip = false"
                  >
                    Dashboard
                  </a>
                </div>
              </mat-list-item>
              <mat-list-item
                routerLink="emp-leave-dashboard"
                [matTooltip]="showempleaveDashboardTooltip ? 'Dashboard' : ''"
                (mouseenter)="showempleaveDashboardTooltip = true"
                (mouseleave)="showempleaveDashboardTooltip = false"
                *ngIf="checkmenudata('Leave Employee Dashboard')"
                routerLinkActive="active"
                (click)="
                  toggleProjectsClose();
                  toggleLoan();
                  toggleSalaryAdvance();
                  setHeaderTitle2();
                  toggleApprover();
                  toggleClient();
                  toggleMom()
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                    (click)="setHeaderTitle2()"
                    (mouseenter)="showempleaveDashboardTooltip = true"
                    (mouseleave)="showempleaveDashboardTooltip = false"
                  >
                    Dashboard
                  </a>
                </div>
              </mat-list-item>
              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClicksetups()"
                  routerLinkActive="active"
                  *ngIf="
                    checkmenudata('Leave Balance') ||
                    checkmenudata('Leave Adjustment')
                  "
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon"
                      >add_circle</mat-icon
                    >
                    <span class="mat-item side-menu"> Setups </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-leave"
                      *ngIf="expand"
                      style="margin-left: 87px"
                    >
                      {{
                        SetupsListArrowIcon ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>
                <mat-list-item
                  (mouseenter)="leavebalance = true"
                  (mouseleave)="leavebalance = false"
                  *ngIf="Setups == true && checkmenudata('Leave Balance')"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isleavebalance()
                  }"
                  [class.router-link-active]="isleavebalance()"
                  (click)="navigateToleavebalance()"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >
                    <span
                      class="mat-item side-menu"
                      [matTooltip]="leavebalance ? 'Leave Balance' : ''"
                      (mouseenter)="leavebalance = true"
                      (mouseleave)="leavebalance = false"
                    >
                      Leave Balance
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item
                  (mouseenter)="leaveadjustment = true"
                  (mouseleave)="leaveadjustment = false"
                  *ngIf="Setups == true && checkmenudata('Leave Adjustment')"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isleaveadjustment()
                  }"
                  [class.router-link-active]="isleaveadjustment()"
                  (click)="navigateToleaveadjustment()"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >
                    <span
                      class="mat-item side-menu"
                      [matTooltip]="leaveadjustment ? 'Leave Adjustment' : ''"
                      (mouseenter)="leaveadjustment = true"
                      (mouseleave)="leaveadjustment = false"
                    >
                      Leave Adjustment
                    </span>
                  </div>
                </mat-list-item>
              </div>
              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClickSettings()"
                  routerLinkActive="active"
                  *ngIf="
                    expand &&
                    (checkmenudata('Leave Type List') ||
                      checkmenudata('Record Timings Settings'))
                  "
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon"
                      >settings</mat-icon
                    >
                    <span class="mat-item side-menu">
                      Leave&nbsp;Settings
                    </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-leave"
                      *ngIf="expand"
                      style="margin-left: 37px"
                    >
                      {{
                        SettingsListArrowIcon ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>
                <div>
                  <mat-list-item
                    (mouseenter)="leavetype = true"
                    (mouseleave)="leavetype = false"
                    *ngIf="checkmenudata('Leave Type List') && Settings"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isleavetype()
                    }"
                    [class.router-link-active]="isleavetype()"
                    (click)="navigateToleavetype()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="leavetype ? 'Leave Type List' : ''"
                        (mouseenter)="leavetype = true"
                        (mouseleave)="leavetype = false"
                      >
                        Leave Type List
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="emptimesettings = true"
                    (mouseleave)="emptimesettings = false"
                    *ngIf="checkmenudata('Record Timings Settings') && Settings"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isemptimesettings()
                    }"
                    [class.router-link-active]="isemptimesettings()"
                    (click)="navigateToemptimesettings()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          emptimesettings ? 'Record Timings Settings' : ''
                        "
                        (mouseenter)="emptimesettings = true"
                        (mouseleave)="emptimesettings = false"
                      >
                        Record Timings Settings
                      </span>
                    </div>
                  </mat-list-item>
                </div>
              </div>

              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClickmanage()"
                  routerLinkActive="active"
                  *ngIf="
                    checkmenudata('Manage Leave Request') ||
                    checkmenudata('Manage Permission') ||
                    checkmenudata('Manage Work from Home') ||
                    checkmenudata('Manage Business Travel')
                  "
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <img
                      *ngIf="expand"
                      class="mat-sidebar-icon"
                      src="assets/img/icons/manageemp75.png"
                    />
                    <span class="mat-item side-menu">
                      Manage&nbsp;Request
                    </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-leave"
                      *ngIf="expand"
                      style="margin-left: 20px"
                    >
                      {{
                        ManageListArrowIcon ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>
                <div>
                  <mat-list-item
                    (mouseenter)="leaverequest = true"
                    (mouseleave)="leaverequest = false"
                    *ngIf="Manage && checkmenudata('Manage Permission')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isleaverequest()
                    }"
                    [class.router-link-active]="isleaverequest()"
                    (click)="navigateToleaverequest()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="leaverequest ? 'Leave Request' : ''"
                        (mouseenter)="leaverequest = true"
                        (mouseleave)="leaverequest = false"
                      >
                        Leave Request
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="managepermission = true"
                    (mouseleave)="managepermission = false"
                    *ngIf="Manage && checkmenudata('Manage Work from Home')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: ismanagepermission()
                    }"
                    [class.router-link-active]="ismanagepermission()"
                    (click)="navigateTomanagepermission()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="managepermission ? 'Permission' : ''"
                        (mouseenter)="managepermission = true"
                        (mouseleave)="managepermission = false"
                      >
                        Permission
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="managewfh = true"
                    (mouseleave)="managewfh = false"
                    *ngIf="Manage && checkmenudata('Manage Business Travel')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: ismanagewfh()
                    }"
                    [class.router-link-active]="ismanagewfh()"
                    (click)="navigateTomanagewfh()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="managewfh ? 'Work from Home' : ''"
                        (mouseenter)="managewfh = true"
                        (mouseleave)="managewfh = false"
                      >
                        Work from Home
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="managebusinesstravel = true"
                    (mouseleave)="managebusinesstravel = false"
                    *ngIf="Manage && checkmenudata('Manage Leave Request')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: ismanagebusinesstravel()
                    }"
                    [class.router-link-active]="ismanagebusinesstravel()"
                    (click)="navigateTomanagebusinesstravel()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          managebusinesstravel ? 'Business Travel' : ''
                        "
                        (mouseenter)="managebusinesstravel = true"
                        (mouseleave)="managebusinesstravel = false"
                      >
                        Business Travel
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="managewithdrawalrequest = true"
                    (mouseleave)="managewithdrawalrequest = false"
                    *ngIf="Manage && checkmenudata('Manage Withdrawal Request')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: ismanagewithdrawalrequest()
                    }"
                    [class.router-link-active]="ismanagewithdrawalrequest()"
                    (click)="navigateTomanagewithdrawalrequest()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          managewithdrawalrequest ? 'Withdrawal Request' : ''
                        "
                        (mouseenter)="managewithdrawalrequest = true"
                        (mouseleave)="managewithdrawalrequest = false"
                      >
                        Withdrawal Request
                      </span>
                    </div>
                  </mat-list-item>
                </div>
              </div>
              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClickRequest()"
                  routerLinkActive="active"
                  *ngIf="
                    checkmenudata('My Leave List') ||
                    checkmenudata('My Permission') ||
                    checkmenudata('Work from Home') ||
                    checkmenudata('Business Travel')
                  "
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                      meeting_room</mat-icon
                    >

                    <span class="mat-item side-menu"> Request&nbsp;List </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-leave"
                      *ngIf="expand"
                    >
                      {{
                        requestListArrowIcon ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>

                <div>
                  <mat-list-item
                    (mouseenter)="myLeaveListToolTip = true"
                    (mouseleave)="myLeaveListToolTip = false"
                    *ngIf="requestList && checkmenudata('My Leave List')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRouteLeaveList()
                    }"
                    [class.router-link-active]="isActiveRouteLeaveList()"
                    (click)="navigateToLeaveList()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="myLeaveListToolTip ? 'My Leave List' : ''"
                        (mouseenter)="myLeaveListToolTip = true"
                        (mouseleave)="myLeaveListToolTip = false"
                      >
                        My Leave List
                      </span>
                    </div>
                  </mat-list-item>

                  <mat-list-item
                    (mouseenter)="myPermissionToolTip = true"
                    (mouseleave)="myPermissionToolTip = false"
                    *ngIf="requestList && checkmenudata('My Permission')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: ismypermissionList()
                    }"
                    [class.router-link-active]="ismypermissionList()"
                    (click)="navigateTomypermissionlist()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          myPermissionToolTip ? 'My Permission List' : ''
                        "
                        (mouseenter)="myPermissionToolTip = true"
                        (mouseleave)="myPermissionToolTip = false"
                      >
                        My Permission List
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="mywfhlist = true"
                    (mouseleave)="mywfhlist = false"
                    *ngIf="requestList && checkmenudata('Work from Home')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: ismywfhlist()
                    }"
                    [class.router-link-active]="ismywfhlist()"
                    (click)="navigateTomywfhlist()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="mywfhlist ? ' Work from Home List' : ''"
                        (mouseenter)="mywfhlist = true"
                        (mouseleave)="mywfhlist = false"
                      >
                        Work from Home List
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="BusinessTravelList = true"
                    (mouseleave)="BusinessTravelList = false"
                    *ngIf="requestList && checkmenudata('Business Travel')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isBusinessTravelList()
                    }"
                    [class.router-link-active]="isBusinessTravelList()"
                    (click)="navigateToBusinessTravelList()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          BusinessTravelList ? 'Business Travel List' : ''
                        "
                        (mouseenter)="BusinessTravelList = true"
                        (mouseleave)="BusinessTravelList = false"
                      >
                        Business Travel List
                      </span>
                    </div>
                  </mat-list-item>
                </div>
              </div>
              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClickReport()"
                  routerLinkActive="active"
                  *ngIf="
                    checkmenudata('Leave Report') ||
                    checkmenudata('Permission Report') ||
                    checkmenudata('Work from Home Report') ||
                    checkmenudata('Business Travel Report')
                  "
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon
                      *ngIf="expand"
                      class="material-icons mat-sidebar-icon"
                      >history</mat-icon
                    >
                    <span class="mat-item"> Reports </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-leave"
                      *ngIf="expand"
                      style="margin-left: 81px"
                    >
                      {{
                        reportsListArrowIcon ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>
                <div>
                  <mat-list-item
                    (mouseenter)="LeaveDetail = true"
                    (mouseleave)="LeaveDetail = false"
                    *ngIf="Reports && checkmenudata('Leave Report')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isLeaveDetail()
                    }"
                    [class.router-link-active]="isLeaveDetail()"
                    (click)="navigateToLeaveDetail()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="LeaveDetail ? 'Leave Detail' : ''"
                        (mouseenter)="LeaveDetail = true"
                        (mouseleave)="LeaveDetail = false"
                      >
                        Leave Detail
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="PermissionDetail = true"
                    (mouseleave)="PermissionDetail = false"
                    *ngIf="Reports && checkmenudata('Permission Report')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isPermissionDetail()
                    }"
                    [class.router-link-active]="isPermissionDetail()"
                    (click)="navigateToPermissionDetail()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          PermissionDetail ? 'Permission Detail' : ''
                        "
                        (mouseenter)="PermissionDetail = true"
                        (mouseleave)="PermissionDetail = false"
                      >
                        Permission Detail
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="WorkfromHome = true"
                    (mouseleave)="WorkfromHome = false"
                    *ngIf="Reports && checkmenudata('Work from Home Report')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isWorkfromHome()
                    }"
                    [class.router-link-active]="isWorkfromHome()"
                    (click)="navigateToWorkfromHome()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="WorkfromHome ? 'Work from Home' : ''"
                        (mouseenter)="WorkfromHome = true"
                        (mouseleave)="WorkfromHome = false"
                      >
                        Work from Home
                      </span>
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    (mouseenter)="businesstravel = true"
                    (mouseleave)="businesstravel = false"
                    *ngIf="Reports && checkmenudata('Business Travel Report')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isbusinesstravel()
                    }"
                    [class.router-link-active]="isbusinesstravel()"
                    (click)="navigateTobusinesstravel()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >
                      <span
                        class="mat-item side-menu"
                        [matTooltip]="businesstravel ? 'Business Travel' : ''"
                        (mouseenter)="businesstravel = true"
                        (mouseleave)="businesstravel = false"
                      >
                        Business Travel
                      </span>
                    </div>
                  </mat-list-item>
                </div>
              </div>
              <mat-list-item
                routerLink="leave-calendar"
                routerLinkActive="active"
                *ngIf="checkmenudata('Admin Leave Calendar')"
                [matTooltip]="showleavecalendarTooltip ? 'Calendar' : ''"
                (mouseenter)="showleavecalendarTooltip = true"
                (mouseleave)="showleavecalendarTooltip = false"
                (click)="
                  toggleProjectsClose();
                  toggleLoan();
                  toggleSalaryAdvance();
                  setHeaderTitle2();
                  toggleApprover();
                  toggleClient();
                  toggleMom()
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">date_range</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                    (click)="setHeaderTitle2()"
                    (mouseenter)="showleavecalendarTooltip = true"
                    (mouseleave)="showleavecalendarTooltip = false"
                  >
                    Calendar
                  </a>
                </div>
              </mat-list-item>
              <mat-list-item
                routerLink="employee-leave-calendar"
                routerLinkActive="active"
                *ngIf="checkmenudata('Leave Employee Dashboard')"
                [matTooltip]="showleavecalendarTooltip ? 'Calendar' : ''"
                (mouseenter)="showleavecalendarTooltip = true"
                (mouseleave)="showleavecalendarTooltip = false"
                (click)="
                  toggleProjectsClose();
                  toggleLoan();
                  toggleSalaryAdvance();
                  setHeaderTitle2();
                  toggleApprover();
                  toggleClient();
                  toggleMom()
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">date_range</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                    (click)="setHeaderTitle2()"
                    (mouseenter)="showleavecalendarTooltip = true"
                    (mouseleave)="showleavecalendarTooltip = false"
                  >
                    Calendar
                  </a>
                </div>
              </mat-list-item>
              <!-- <mat-list-item routerLink="employee-leave-calendar" routerLinkActive="active" *ngIf="checkmoduledata('LEAVE')">
                    <mat-icon class="mat-sidebar-icon">date_range</mat-icon>
                    <a *ngIf="expand" mat-list-item class="sidebar-icons">
                      Calendar
                    </a>
                </mat-list-item>  -->
            </div>

            <!-- Leave start here -->
            <!-- <div
              class=""
              *ngIf="localStorageMenuId == 'LEAVE' && checkmoduledata('LEAVE')"
            >
              <mat-list-item
                routerLink="leave-dashboard"
                *ngIf="checkmenudata('Leave HR Dashboard')"
                routerLinkActive="active"
              >
                <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                  Dashboard
                </a>
              </mat-list-item>
              <mat-list-item
                routerLink="emp-leave-dashboard"
                *ngIf="checkmenudata('Leave Employee Dashboard')"
                routerLinkActive="active"
              >
                <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                  Dashboard
                </a>
              </mat-list-item>
              <div class="menu-sub-menus">
                <mat-list-item
                  *ngIf="
                    checkmoduledata('LEAVE') && checkmenudata('Leave Balance')
                  "
                >
                  <mat-icon class="mat-sidebar-icon">add_circle</mat-icon>
                  <a
                    (click)="toggle(5)"
                    mat-list-item
                    class="sidebar-icons"
                    title="Leave"
                  >
                    <span class="mat-item" *ngIf="expand"> Setups </span>
                  </a>
                </mat-list-item>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 5 &&
                    checkmenudata('Leave Balance')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Leave Balance"
                        routerLink="leave-balance"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Leave Balance
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 5 &&
                    checkmenudata('Leave Adjustment')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Leave adjustment"
                        routerLink="leave-adjustment-list"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Leave Adjustment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="menu-sub-menus">
                <mat-list-item
                  *ngIf="
                    checkmoduledata('LEAVE') && checkmenudata('Leave Type List')
                  "
                >
                  <mat-icon class="mat-sidebar-icon">settings</mat-icon>
                  <a
                    (click)="toggle(6)"
                    mat-list-item
                    class="sidebar-icons"
                    title="Leave"
                  >
                    <span class="mat-item" *ngIf="expand">
                      Leave Settings
                    </span>
                  </a>
                </mat-list-item>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 6 &&
                    checkmenudata('Leave Type List')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Type List"
                        routerLink="type-list"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Leave Type List
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 6 &&
                    checkmenudata('Record Timings Settings')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Record Timings Settings"
                        routerLink="emptimesettings-list"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Record Timings Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="menu-sub-menus">
                <mat-list-item
                  *ngIf="
                    checkmoduledata('LEAVE') &&
                    checkmenudata('Manage Leave Request')
                  "
                >
                  <img src="assets/img/icons/manageemp.png" />
                  <a
                    (click)="toggle(1)"
                    mat-list-item
                    class="sidebar-icons"
                    title="Leave"
                  >
                    <span class="mat-item" *ngIf="expand">
                      Manage Request
                    </span>
                  </a>
                </mat-list-item>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 1 &&
                    checkmenudata('Manage Leave Request')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Manage Leave Request"
                        routerLink="manage-leave-request"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Manage Leave Request
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 1 &&
                    checkmenudata('Manage Permission')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Manage Permission"
                        routerLink="manage-permission"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Manage Permission
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 1 &&
                    checkmenudata('Manage Work from Home')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Manage Work From Home"
                        routerLink="manage-wfh"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Manage Work From Home
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 1 &&
                    checkmenudata('Manage Business Travel')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Manage Business Travel"
                        routerLink="manage-business-travel"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Manage Business Travel
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 1 &&
                    checkmenudata('Manage Withdrawal Request')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Manage Withdrawal Request"
                        routerLink="manage-withdrawal-request"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Manage Withdrawal Request
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="menu-sub-menus">
                <mat-list-item
                  *ngIf="
                    checkmoduledata('LEAVE') && checkmenudata('My Leave List')
                  "
                >
                  <mat-icon class="mat-sidebar-icon">perm_identity</mat-icon>
                  <a
                    (click)="toggle(2)"
                    mat-list-item
                    class="sidebar-icons"
                    title="Leave"
                  >
                    <span class="mat-item" *ngIf="expand"> Request List </span>
                  </a>
                </mat-list-item>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 2 &&
                    checkmenudata('My Leave List')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Apply Leave"
                        routerLink="myleave-list"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        My Leave List
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 2 &&
                    checkmenudata('My Permission')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Apply Permission"
                        routerLink="mypermission-list"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        My Permission List
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 2 &&
                    checkmenudata('Work from Home')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Apply Work From Home"
                        routerLink="mywfh-list"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Work from Home List
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 2 &&
                    checkmenudata('Business Travel')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Apply Business Travel"
                        routerLink="mybusinesstravel-list"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Business Travel List
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="menu-sub-menus">
                <mat-list-item
                  *ngIf="
                    checkmoduledata('LEAVE') && checkmenudata('Leave Report')
                  "
                >
                  <mat-icon class="mat-sidebar-icon">history</mat-icon>
                  <a
                    (click)="toggle(3)"
                    mat-list-item
                    class="sidebar-icons"
                    title="Leave"
                  >
                    <span class="mat-item" *ngIf="expand"> Reports </span>
                  </a>
                </mat-list-item>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 3 &&
                    checkmenudata('Leave Report')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Leave Detail"
                        routerLink="leave-Detail"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Leave Detail
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 3 &&
                    checkmenudata('Permission Report')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Permission Detail"
                        routerLink="permission-Detail"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Permission Detail
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 3 &&
                    checkmenudata('Work from Home Report')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Work from Home"
                        routerLink="work-from-home"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Work from Home
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="
                    isShow == true &&
                    isShowValue == 3 &&
                    checkmenudata('Business Travel Report')
                  "
                >
                  <ul class="mb-0 sub-menus sub">
                    <li>
                      <a
                        *ngIf="expand"
                        title="Business Travel"
                        routerLink="business-travel"
                        routerLinkActive="active"
                        class="sidebar-icons"
                      >
                        Business Travel
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <mat-list-item
                routerLink="leave-calendar"
                routerLinkActive="active"
                *ngIf="checkmoduledata('LEAVE')"
              >
                <mat-icon class="mat-sidebar-icon">date_range</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                  Calendar
                </a>
              </mat-list-item>
            </div> -->

            <!-- Leave end here -->

            <!-- Recruitment start here -->
            <div
              *ngIf="localStorageMenuId == 'RQMNT' && checkmoduledata('RQMNT')"
            >
              <!-- <div *ngFor="let user of userType"> -->
              <mat-list-item
                routerLink="recruiter-dashboard"
                routerLinkActive="active"
                *ngIf="checkmenudata('Recruiter Dashboard')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                  <a
                    *ngIf="expand"
                    mat-list-item
                    class="sidebar-icons side-menu"
                    style="
                      position: relative;
                      right: 30px;
                      top: -2px !important;
                    "
                  >
                    Dashboard
                  </a>
                </div>
              </mat-list-item>
              <mat-list-item
                routerLink="admin-dashboard"
                routerLinkActive="active"
                *ngIf="checkmenudata('Recruitment Admin Dashboard')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                  <a
                    *ngIf="expand"
                    mat-list-item
                    class="sidebar-icons side-menu"
                    style="position: relative; right: 30px"
                  >
                    Dashboard
                  </a>
                </div>
              </mat-list-item>
              <!-- </div> -->

              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="recuritmentListOpen()"
                  routerLinkActive="active"
                  *ngIf="expand"
                >
                  <!-- <mat-icon class="mat-sidebar-icon">real_estate_agent</mat-icon> -->
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon class="mat-sidebar-icon">perm_identity</mat-icon>

                    <span class="mat-item side-menu"> Recruitment </span>
                    <span
                      style="margin-left: 53px"
                      class="material-icons-outlined icon arrow-icon"
                      *ngIf="expand"
                    >
                      {{
                        isshowRecruitmentArrow ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>
                <div
                  (mouseenter)="recruitment = true"
                  (mouseleave)="recruitment = false"
                >
                  <mat-list-item
                    (mouseenter)="recruitment = true"
                    (mouseleave)="recruitment = false"
                    *ngIf="recruitmentList"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRouteRecruitmentPosition()
                    }"
                    [class.router-link-active]="
                      isActiveRouteRecruitmentPosition()
                    "
                    (click)="navigateToRecruitmnet()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon class="mat-sidebar-icon dot">&#8226;</mat-icon>

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="recruitment ? 'Positions' : ''"
                        (mouseenter)="recruitment = true"
                        (mouseleave)="recruitment = false"
                      >
                        Positions
                      </span>
                    </div>
                  </mat-list-item>

                  <mat-list-item
                    (mouseenter)="recruitment = true"
                    (mouseleave)="recruitment = false"
                    *ngIf="recruitmentList"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRouteRequirement()
                    }"
                    [class.router-link-active]="isActiveRouteRequirement()"
                    (click)="navigateToRequirement()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon class="mat-sidebar-icon dot">&#8226;</mat-icon>

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="recruitment ? 'Requirement' : ''"
                        (mouseenter)="recruitment = true"
                        (mouseleave)="recruitment = false"
                      >
                        Requirement
                      </span>
                    </div>
                  </mat-list-item>

                  <mat-list-item
                    (mouseenter)="recruitment = true"
                    (mouseleave)="recruitment = false"
                    *ngIf="recruitmentList"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRouteCandidates()
                    }"
                    [class.router-link-active]="isActiveRouteCandidates()"
                    (click)="navigateToCandidates()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon class="mat-sidebar-icon dot">&#8226;</mat-icon>

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="recruitment ? 'Candidates' : ''"
                        (mouseenter)="recruitment = true"
                        (mouseleave)="recruitment = false"
                      >
                        Candidates
                      </span>
                    </div>
                  </mat-list-item>

                  <mat-list-item
                    (mouseenter)="recruitment = true"
                    (mouseleave)="recruitment = false"
                    *ngIf="recruitmentList"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRouteOnboardings()
                    }"
                    [class.router-link-active]="isActiveRouteOnboardings()"
                    (click)="navigateToOnboardings()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon class="mat-sidebar-icon dot">&#8226;</mat-icon>

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="recruitment ? 'Onboardings' : ''"
                        (mouseenter)="recruitment = true"
                        (mouseleave)="recruitment = false"
                      >
                        Onboardings
                      </span>
                    </div>
                  </mat-list-item>

                  <mat-list-item
                    (mouseenter)="projects = true"
                    (mouseleave)="projects = false"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRoute() || isActiveRouteViewReports()
                    }"
                    [class.router-link-active]="
                      isActiveRoute() || isActiveRouteViewReports()
                    "
                    *ngIf="
                      isShowAdvanceProject == true && checkmenudata('Projects')
                    "
                    routerLink="projects"
                    routerLinkActive="active"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="projects ? 'Projects' : ''"
                        (mouseenter)="projects = true"
                        (mouseleave)="projects = false"
                      >
                        Resource
                      </span>
                    </div>
                  </mat-list-item>
                </div>
              </div>
            </div>

            <!-- Recruitment end here -->
            <!-- <mat-list-item routerLink="manage-elements" routerLinkActive="active">
                <mat-icon class="mat-sidebar-icon">credit_card</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                    Payroll
                </a>
            </mat-list-item> -->

            <!-- Project Management start here -->
            <div
              class=""
              (mouseenter)="showDashboardTooltip = true"
              (mouseleave)="showDashboardTooltip = false"
              (click)="checkmenudata('PROJECT MANAGEMENT')"
              *ngIf="
                localStorageMenuId == 'PROJECTMANAGEMENT' &&
                checkmoduledata('PROJECTMANAGEMENT')
              "
            >
              <mat-list-item
                routerLink="project-management"
                [matTooltip]="showDashboardTooltip ? 'Dashboard' : ''"
                (mouseenter)="showDashboardTooltip = true"
                (mouseleave)="showDashboardTooltip = false"
                routerLinkActive="active"
                *ngIf="isAdmin"
                (click)="
                  setHeaderTitle('Dashboard');
                  toggleProjectsClose();
                  toggleLoan();
                  toggleSalaryAdvance();
                  setHeaderTitle2();
                  toggleApprover();
                  toggleClient();
                  toggleMom();
                  this.isShow = false
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                    (click)="setHeaderTitle('Dashboard')"
                    (click)="setHeaderTitle2(); this.isShow = false"
                    (mouseenter)="showDashboardTooltip = true"
                    (mouseleave)="showDashboardTooltip = false"
                  >
                    Dashboard
                  </a>
                </div>
              </mat-list-item>
              <!-- *ngIf="user.role[0].roleName == 'Recruiter'" -->
              <div>
                <!-- *ngIf="checkmenudata('Project Management Employee Dashboard')" -->
                <mat-list-item
                  [matTooltip]="showEmployeeDashboardTooltip ? 'Dashboard' : ''"
                  (mouseenter)="showEmployeeDashboardTooltip = true"
                  (mouseleave)="showEmployeeDashboardTooltip = false"
                  routerLink="time-employee-dashboard"
                  routerLinkActive="active"
                  *ngIf="isEmployee"
                  (click)="
                    toggleProjectsClose();
                    toggleSalaryAdvance();
                    toggleLoan();
                    toggleApprover();
                    toggleClient();
                    toggleMom()
                  "
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                    <a
                      *ngIf="expand"
                      (mouseenter)="showEmployeeDashboardTooltip = true"
                      (mouseleave)="showEmployeeDashboardTooltip = false"
                      class="sidebar-icons side-menu"
                      (click)="setHeaderTitle('Dashboard')"
                      (click)="setHeaderTitle2()"
                    >
                      Dashboard
                    </a>
                  </div>
                </mat-list-item>
              </div>
              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? 'Timesheet' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                routerLink="time-sheet-reports"
                routerLinkActive="active"
                *ngIf="checkmenudata('Timesheet')"
                (click)="toggleProjectsClose()"
                (click)="toggleSalaryAdvance()"
                (click)="toggleLoan()"
                (click)="toggleApprover()"
                (click)="toggleClient()"
                (click)="toggleMom(); this.isShow = false"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">date_range</mat-icon>
                  <a
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    (click)="setHeaderTitle2()"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Timesheet
                  </a>
                </div>
              </mat-list-item>
              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClick()"
                  routerLinkActive="active"
                  *ngIf="
                    (expand && checkmenudata('Projects')) ||
                    checkmenudata('Add Task') ||
                    checkmenudata('Assign Employee Task') ||
                    checkmenudata('Task Group') ||
                    checkmenudata('Assign Employee Project') ||
                    checkmenudata('Project Stage')
                  "
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                      receipt_long</mat-icon
                    >

                    <span *ngIf="expand" class="mat-item side-menu">
                      Projects
                    </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-projects"
                      *ngIf="expand"
                    >
                      {{ isShow ? "expand_more" : "chevron_right" }}</span
                    >
                  </div>
                </mat-list-item>

                <mat-list-item
                  (mouseenter)="projects = true"
                  (mouseleave)="projects = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRoute() || isActiveRouteViewReports()
                  }"
                  [class.router-link-active]="
                    isActiveRoute() || isActiveRouteViewReports()
                  "
                  *ngIf="
                    isShowAdvanceProject == true && checkmenudata('Projects')
                  "
                  routerLink="projects"
                  routerLinkActive="active"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      class="mat-item side-menu"
                      [matTooltip]="projects ? 'Projects' : ''"
                      (mouseenter)="projects = true"
                      (mouseleave)="projects = false"
                    >
                      Projects
                    </span>
                  </div>
                </mat-list-item>

                <!-- <mat-list-item
                (mouseenter)="manageSalaryApprovals = true"
                (mouseleave)="manageSalaryApprovals = false"
                *ngIf="isShowAdvanceProject == true"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRouteSalaryManage()
                }"
                [class.router-link-active]="isActiveRouteSalaryManage()"
                routerLink="salary-manage-approvals"
                class=""
                routerLinkActive="active"
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >&#8226;</mat-icon
                  >

                  <span
                    class="mat-item side-menu text-truncate"
                    [matTooltip]="
                      manageSalaryApprovals ? 'Manage Salary Approvals' : ''
                    "
                    (mouseenter)="manageSalaryApprovals = true"
                    (mouseleave)="manageSalaryApprovals = false"
                  >
                    {{ "Manage Salary Approvals" | slice : 0 : 13
                    }}{{ "Manage Salary Approvals".length > 13 ? "..." : "" }}
                  </span>
                </div>
              </mat-list-item> -->

                <!-- </div> -->

                <!-- <div *ngIf="isShowAdvanceProject == true">
                <ul class="mb-0 sub-menus">
                  <li class="p-0">
                    <a
                      [matTooltip]="addTask ? 'Add Task' : ''"
                      (mouseenter)="addTask = true"
                      (mouseleave)="addTask = false"
                      mat-list-item
                      routerLink="add-multiple-task"
                      routerLinkActive="active"
                      (click)="setHeaderTitle('Add Task')"
                      >Add Task</a
                    >
                  </li>
                </ul>
              </div> -->

                <mat-list-item
                  (mouseenter)="addTask = true"
                  (mouseleave)="addTask = false"
                  *ngIf="
                    isShowAdvanceProject == true && checkmenudata('Add Task')
                  "
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteAddTask()
                  }"
                  [class.router-link-active]="isActiveRouteAddTask()"
                  routerLink="add-multiple-task"
                  routerLinkActive="active"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      class="mat-item side-menu"
                      [matTooltip]="addTask ? 'Add Task' : ''"
                      (mouseenter)="addTask = true"
                      (mouseleave)="addTask = false"
                    >
                      Add Task
                    </span>
                  </div>
                </mat-list-item>

                <!-- <div *ngIf="isShowAdvanceProject == true">
                <ul class="mb-0 sub-menus">
                  <li class="p-0">
                    <a
                      [matTooltip]="
                        projectsAddEmployeeTask ? 'Assign Employee Task' : ''
                      "
                      (mouseenter)="projectsAddEmployeeTask = true"
                      (mouseleave)="projectsAddEmployeeTask = false"
                      mat-list-item
                      routerLink="add-employee-task"
                      routerLinkActive="active"
                      (click)="setHeaderTitle('Assign Employee Task')"
                      >Assign Employee Task</a
                    >
                  </li>
                </ul>
              </div> -->

                <mat-list-item
                  (mouseenter)="projectsAddEmployeeTask = true"
                  (mouseleave)="projectsAddEmployeeTask = false"
                  *ngIf="
                    isShowAdvanceProject == true &&
                    checkmenudata('Assign Employee Task')
                  "
                  class="text-truncate"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteAssignEmployee()
                  }"
                  [class.router-link-active]="isActiveRouteAssignEmployee()"
                  routerLink="add-employee-task"
                  routerLinkActive="active"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      [matTooltip]="
                        projectsAddEmployeeTask ? 'Assign Employee Task' : ''
                      "
                      (mouseenter)="projectsAddEmployeeTask = true"
                      (mouseleave)="projectsAddEmployeeTask = false"
                      class="text-truncate"
                    >
                      {{ " Assign Employee Task" | slice : 0 : 15
                      }}{{ " Assign Employee Task".length > 15 ? "..." : "" }}
                    </span>
                  </div>
                </mat-list-item>

                <mat-list-item
                  (mouseenter)="TaskGroup = true"
                  (mouseleave)="TaskGroup = false"
                  *ngIf="
                    isShowAdvanceProject == true && checkmenudata('Task Group')
                  "
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteTaskGroup()
                  }"
                  [class.router-link-active]="isActiveRouteTaskGroup()"
                  routerLink="task-group"
                  routerLinkActive="active"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      [matTooltip]="TaskGroup ? 'Task Group' : ''"
                      (mouseenter)="TaskGroup = true"
                      (mouseleave)="TaskGroup = false"
                      mat-list-item
                      class="text-truncate"
                    >
                      Task Group
                    </span>
                  </div>
                </mat-list-item>

                <mat-list-item
                  (mouseenter)="assignEmployeeProject = true"
                  (mouseleave)="assignEmployeeProject = false"
                  *ngIf="
                    isShowAdvanceProject == true &&
                    checkmenudata('Assign Employee Project')
                  "
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteAssignEmployeeProject()
                  }"
                  [class.router-link-active]="
                    isActiveRouteAssignEmployeeProject()
                  "
                  routerLink="assign-project"
                  routerLinkActive="active"
                  class=""
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      [matTooltip]="
                        assignEmployeeProject ? 'Assign Employee Project' : ''
                      "
                      (mouseenter)="assignEmployeeProject = true"
                      (mouseleave)="assignEmployeeProject = false"
                      class="text-truncate"
                    >
                      {{ " Assign Employee Project" | slice : 0 : 13
                      }}{{ "Assign Employee Project".length > 13 ? "..." : "" }}
                    </span>
                  </div>
                </mat-list-item>
                <mat-list-item
                  (mouseenter)="projectStage = true"
                  (mouseleave)="projectStage = false"
                  *ngIf="
                    isShowAdvanceProject == true &&
                    checkmenudata('Project Stage')
                  "
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteProjectStage()
                  }"
                  [class.router-link-active]="isActiveRouteProjectStage()"
                  routerLink="project-stage"
                  routerLinkActive="active"
                >
                  <div class="met-list-menu">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                      >&#8226;</mat-icon
                    >

                    <span
                      [matTooltip]="projectStage ? 'Project Stage' : ''"
                      (mouseenter)="projectStage = true"
                      (mouseleave)="projectStage = false"
                    >
                      Project Stage
                    </span>
                  </div>
                </mat-list-item>
              </div>

              <!-- [routerLink]="['assign-approver']"
            [routerLinkActive]="['active']" -->
              <!-- <mat-list-item
              *ngIf="checkmenudata('Assign Approvers')"
              [matTooltip]="assignApprovers ? 'Assign Approvers' : ''"
              (mouseenter)="assignApprovers = true"
              (mouseleave)="assignApprovers = false"
              routerLink="assign-approver"
              routerLinkActive="active"
              [ngClass]="{
                'mat-list-item': true,
                active: isActiveAssignApprovers()
              }"
              [ngClass]="{
                'mat-list-item': true,
                active: isActiveAddAssignApprovers()
              }"
               


              [routerLinkActiveOptions]="{ exact: true }"
              (click)="
                toggleProjectsClose();
                toggleSalaryAdvance();
                toggleSalaryAdvance();
                toggleLoan();
                toggleApprover();
                toggleClient();
                toggleMom();
                handleClickAssignApprover()
              "
            >
              <div class="met-list-menu">
                <mat-icon class="mat-sidebar-icon"
                  >published_with_changes</mat-icon
                >
                <a
                  (mouseenter)="assignApprovers = true"
                  (mouseleave)="assignApprovers = false"
                  *ngIf="expand"
                  class="sidebar-icons side-menu text-truncate"
                  (click)="setHeaderTitle('Assign Approvers')"
                >
                  {{ "Assign Approvers" | slice : 0 : 15
                  }}{{ " Assign Approvers".length > 15 ? "..." : "" }}
                </a>
              </div>
            </mat-list-item> -->

              <mat-list-item
                (mouseenter)="assignApprovers = true"
                (mouseleave)="assignApprovers = false"
                *ngIf="checkmenudata('Assign Approvers')"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveAssignApprovers()
                }"
                [class.router-link-active]="isActiveAssignApprovers()"
                (click)="navigateToAssignApprovers()"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: false }"
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >published_with_changes</mat-icon
                  >

                  <span
                    class="mat-item side-menu"
                    [matTooltip]="assignApprovers ? 'Assign Approvers' : ''"
                    (mouseenter)="assignApprovers = true"
                    (mouseleave)="assignApprovers = false"
                  >
                    {{ "Assign Approvers" | slice : 0 : 15
                    }}{{ " Assign Approvers".length > 15 ? "..." : "" }}
                  </span>
                </div>
              </mat-list-item>

              <mat-list-item
                class=""
                [matTooltip]="manageTimesheet ? 'Manage Timesheet' : ''"
                (mouseenter)="manageTimesheet = true"
                (mouseleave)="manageTimesheet = false"
                routerLink="manage-timesheet"
                routerLinkActive="active"
                *ngIf="checkmenudata('Manage Timesheet')"
                (click)="
                  toggleProjectsClose();
                  toggleSalaryAdvance();
                  toggleLoan();
                  toggleApprover();
                  toggleClient();
                  toggleMom();
                  this.isShow = false
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">date_range</mat-icon>
                  <a
                    (mouseenter)="manageTimesheet = true"
                    (mouseleave)="manageTimesheet = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                  >
                    {{ "   Manage Timesheet" | slice : 0 : 15
                    }}{{ "   Manage Timesheet".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <mat-list-item
                [matTooltip]="assignEmployeeTask ? 'Assign Employee Task' : ''"
                (mouseenter)="assignEmployeeTask = true"
                (mouseleave)="assignEmployeeTask = false"
                routerLink="emp-timesheet"
                (click)="toggleProjectsClose()"
                (click)="toggleSalaryAdvance()"
                (click)="toggleLoan()"
                (click)="toggleApprover()"
                (click)="toggleClient()"
                (click)="toggleMom(); this.isShow = false"
                routerLinkActive="active"
                *ngIf="checkmenudata('Assign Employee Task')"
                matTooltip="Assign Employee Task"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon"
                    >assignment_turned_in</mat-icon
                  >
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    (mouseenter)="assignEmployeeTask = true"
                    (mouseleave)="assignEmployeeTask = false"
                  >
                    {{ "Assign Employee Task" | slice : 0 : 15
                    }}{{ "Assign Employee Task".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <!-- <mat-list-item
              [matTooltip]="dailyTimesheet ? 'Daily Time Sheet' : ''"
              (mouseenter)="dailyTimesheet = true"
              (mouseleave)="dailyTimesheet = false"
              routerLink="daily-timesheet"
              routerLinkActive="active"
              (click)="toggleProjectsClose()"
              (click)="toggleSalaryAdvance()"
              (click)="toggleLoan()"
              (click)="toggleApprover()"
              (click)="toggleClient()"
              (click)="toggleMom()"
            >
              <div class="met-list-menu">
                <mat-icon class="mat-sidebar-icon">timer</mat-icon>
                <a
                  *ngIf="expand"
                  class="sidebar-icons side-menu"
                  (click)="setHeaderTitle('Daily Time Sheet')"
                >
                  Daily Time Sheet
                </a>
              </div>
            </mat-list-item> -->

              <mat-list-item
                [matTooltip]="dailyTimesheet ? 'Daily Time Sheet Report' : ''"
                (mouseenter)="dailyTimesheet = true"
                (mouseleave)="dailyTimesheet = false"
                routerLink="daily-timesheet"
                routerLinkActive="active"
                (click)="toggleProjectsClose()"
                (click)="toggleSalaryAdvance()"
                (click)="toggleLoan()"
                (click)="toggleApprover()"
                (click)="toggleClient()"
                (click)="toggleMom(); this.isShow = false"
                *ngIf="checkmenudata('Daily Time Sheet Report')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">timer</mat-icon>
                  <a
                    (mouseenter)="dailyTimesheet = true"
                    (mouseleave)="dailyTimesheet = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                  >
                    {{ " Daily Time Sheet" | slice : 0 : 15
                    }}{{ " Daily Time Sheet".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <mat-list-item
                [matTooltip]="
                  consolidateTimesheet ? 'Consolidate Timesheet Report' : ''
                "
                (mouseenter)="consolidateTimesheet = true"
                (mouseleave)="consolidateTimesheet = false"
                routerLink="consolidate-timesheet"
                routerLinkActive="active"
                *ngIf="checkmenudata('Consolidate Timesheet Report')"
                matTooltip="Consolidate Timesheet"
                (click)="toggleProjectsClose()"
                (click)="toggleSalaryAdvance()"
                (click)="toggleLoan()"
                (click)="toggleApprover()"
                (click)="toggleClient()"
                (click)="toggleMom(); this.isShow = false"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">group_work</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    (mouseenter)="consolidateTimesheet = true"
                    (mouseleave)="consolidateTimesheet = false"
                  >
                    {{ "Consolidate Timesheet" | slice : 0 : 18
                    }}{{ "Consolidate Timesheet".length > 18 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <mat-list-item
                [matTooltip]="employeeTaskReport ? 'Employee Task Report' : ''"
                (mouseenter)="employeeTaskReport = true"
                (mouseleave)="employeeTaskReport = false"
                routerLink="employee-task-report"
                routerLinkActive="active"
                *ngIf="checkmenudata('Employee Task Report')"
                (click)="toggleProjectsClose()"
                (click)="toggleSalaryAdvance()"
                (click)="toggleLoan()"
                (click)="toggleApprover()"
                (click)="toggleClient()"
                (click)="toggleMom(); this.isShow = false"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">group</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    (mouseenter)="employeeTaskReport = true"
                    (mouseleave)="employeeTaskReport = false"
                  >
                    {{ " Employee Task Report" | slice : 0 : 18
                    }}{{ " Employee Task Report".length > 18 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>
              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClickMOM()"
                  routerLinkActive="active"
                  *ngIf="expand && checkmenudata('MOM Reports')"
                >
                  <!-- <mat-icon class="mat-sidebar-icon">real_estate_agent</mat-icon> -->
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                      meeting_room</mat-icon
                    >
                    <!-- <span
                  _ngcontent-qua-c429=""
                  (click)="toggle12()"
                  class="material-icons-outlined sidenav-icons"
                  >meeting_room</span
                > -->

                    <span class="mat-item side-menu" *ngIf="momlist">
                      MOM
                    </span>
                    <span
                      class="material-icons-outlined icon arrow-icon"
                      *ngIf="expand"
                    >
                      {{ isshowMom ? "expand_more" : "chevron_right" }}</span
                    >
                  </div>
                </mat-list-item>
                <div
                  *ngIf="
                    (isShowmomlist == true && checkmenudata('MOM Reports')) ||
                    checkmenudata('Create MOM') ||
                    checkmenudata('Action Item Report')
                  "
                  (mouseenter)="momReports = true"
                  (mouseleave)="momReports = false"
                >
                  <!-- <ul class="mb-0 sub-menus">
                <li class="p-0">
                  <a
                    mat-list-item
                    routerLink="mom-reports"
                    routerLinkActive="active"
                    (click)="setHeaderTitle('MOM Reports')"
                    >MOM Reports</a
                  >
                </li>
              </ul> -->
                  <mat-list-item
                    (mouseenter)="momReports = true"
                    (mouseleave)="momReports = false"
                    *ngIf="isShowmomlist"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRouteMomReports()
                    }"
                    [class.router-link-active]="isActiveRouteMomReports()"
                    (click)="navigateToMomReports()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="momReports ? 'MOM Reports' : ''"
                        (mouseenter)="momReports = true"
                        (mouseleave)="momReports = false"
                      >
                        MOM Reports
                      </span>
                    </div>
                  </mat-list-item>

                  <mat-list-item
                    (mouseenter)="createMom = true"
                    (mouseleave)="createMom = false"
                    *ngIf="isShowmomlist == true && checkmenudata('Create MOM')"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isMomActiveCreation()
                    }"
                    [class.router-link-active]="isMomActiveCreation()"
                    routerLink="mom-creation"
                    routerLinkActive="active"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="createMom ? 'Create MOM' : ''"
                        (mouseenter)="createMom = true"
                        (mouseleave)="createMom = false"
                      >
                        Create MOM
                      </span>
                    </div>
                  </mat-list-item>
                  <!-- <ul
                class="mb-0 sub-menus"
                [matTooltip]="actionItemReport ? 'Action Item Report' : ''"
                (mouseenter)="actionItemReport = true"
                (mouseleave)="actionItemReport = false"
              >
                <li class="p-0">
                  <a
                    mat-list-item
                    routerLink="action-item-report"
                    (click)="setHeaderTitle('Action Item Report')"
                    routerLinkActive="active"
                    >Action Item Report
                  </a>
                </li>
              </ul> -->
                  <mat-list-item
                    (mouseenter)="actionItemReport = true"
                    (mouseleave)="actionItemReport = false"
                    *ngIf="
                      isShowmomlist == true &&
                      checkmenudata('Action Item Report')
                    "
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isMomActionItemReport()
                    }"
                    [class.router-link-active]="isMomActionItemReport()"
                    routerLink="action-item-report"
                    routerLinkActive="active"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          actionItemReport ? 'Action Item Report' : ''
                        "
                        (mouseenter)="actionItemReport = true"
                        (mouseleave)="actionItemReport = false"
                      >
                        Action Item Report
                      </span>
                    </div>
                  </mat-list-item>
                </div>
              </div>
            </div>
            <!-- Project Management end here -->

            <!-- Payroll Starts Here-->
            <div
              class="menu-sub-menus"
              *ngIf="
                localStorageMenuId == 'PAYROLL' && checkmoduledata('PAYROLL')
              "
            >
              <!-- <mat-list-item
                *ngIf="localStorageMenuId == 'PAYROLL' && checkmoduledata('PAYROLL')"
              >
                <mat-icon class="mat-sidebar-icon">credit_card</mat-icon>
                <a
                  *ngIf="expand"
                  (click)="toggle2()"
                  mat-list-item
                  class="sidebar-icons"
                >
                  <span class="mat-item" *ngIf="expandPayroll"> Payroll </span>
                </a>
              </mat-list-item> -->

              <mat-list-item (click)="toggle2()" routerLinkActive="active">
                <div class="met-list-menu" *ngIf="expand">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                    credit_card</mat-icon
                  >

                  <span *ngIf="expand" class="mat-item side-menu">
                    Payroll
                  </span>
                  <span
                    class="material-icons-outlined icon arrow-icon-projects"
                    *ngIf="expand"
                  >
                    {{
                      isShowPayrollArrow ? "expand_more" : "chevron_right"
                    }}</span
                  >
                </div>
              </mat-list-item>

              <!-- <div
                *ngIf="isShowPayroll == true && checkmenudata('Org Workdays')"
              >
                <ul class="mb-0 sub-menus">
                  <li class="p-0" routerLink="working-days">
                    <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">group_add</span>
                    <a *ngIf="expand" routerLinkActive="active"
                      mat-list-item >Working Days</a>
                  </li>
                </ul>
              </div> -->
              <mat-list-item
                (mouseenter)="workingDaysToolTip = true"
                (mouseleave)="workingDaysToolTip = false"
                routerLink="working-days"
                routerLinkActive="active"
                *ngIf="isShowPayroll == true && checkmenudata('Org Workdays')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">work</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="workingDaysToolTip ? 'Working Days' : ''"
                    (mouseenter)="workingDaysToolTip = true"
                    (mouseleave)="workingDaysToolTip = false"
                  >
                    {{ "Working Days" | slice : 0 : 15
                    }}{{ "Working Days".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>
              <!-- <div
                *ngIf="
                  isShowPayroll == true &&
                  checkmenudata('Employee Recovery Adjustment')
                "
              >
                <ul class="mb-0 sub-menus">
                  <li class="p-0" routerLink="recovery-adjustment-list">
                    <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">badge</span>
                    <a
                     *ngIf="expand"
                      mat-list-item
                      routerLink="recovery-adjustment-list"
                      routerLinkActive="active"
                      >Employee Recovery Adjustment</a
                    >
                  </li>
                </ul>
              </div> -->

              <mat-list-item
                (mouseenter)="employeeRecoveryAdjustmentToolTip = true"
                (mouseleave)="employeeRecoveryAdjustmentToolTip = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActivePayrollEmployeeRecovery()
                }"
                [class.router-link-active]="isActivePayrollEmployeeRecovery()"
                (click)="navigateToEmployeeRecovery()"
                routerLinkActive="active"
                *ngIf="
                  isShowPayroll == true &&
                  checkmenudata('Employee Recovery Adjustment')
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">autorenew</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="
                      employeeRecoveryAdjustmentToolTip
                        ? 'Employee Recovery Adjustment'
                        : ''
                    "
                    (mouseenter)="employeeRecoveryAdjustmentToolTip = true"
                    (mouseleave)="employeeRecoveryAdjustmentToolTip = false"
                  >
                    {{ "Employee Recovery Adjustment" | slice : 0 : 15
                    }}{{
                      "Employee Recovery Adjustment".length > 15 ? "..." : ""
                    }}
                  </a>
                </div>
              </mat-list-item>

              <!-- <div *ngIf="isShowPayroll == true && checkmenudata('Manage Recovery Adjustment')">
                          <ul class="mb-0 sub-menus">
                              <li class="p-0">
                                  <a mat-list-item routerLink="recovery-adjustment-list" routerLinkActive="active">Manage Recovery Adjustment</a>
                              </li>
                          </ul>
                      </div> -->
              <!-- <div *ngIf="isShowPayroll == true && checkmenudata('Employee Revenue Adjustment')">
                          <ul class="mb-0 sub-menus">
                              <li class="p-0" routerLink="manage-employee-revenue-adjustment">
                                <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">people</span>
                               
                                  <a *ngIf="expand" mat-list-item routerLink="manage-employee-revenue-adjustment" routerLinkActive="active">Employee Revenue Adjustment</a>
                              </li>
                          </ul>
                      </div> -->

              <mat-list-item
                (mouseenter)="employeeRevenueAdjustmentToolTip = true"
                (mouseleave)="employeeRevenueAdjustmentToolTip = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRouteEmployeeRevenueAdjustment()
                }"
                [class.router-link-active]="
                  isActiveRouteEmployeeRevenueAdjustment()
                "
                (click)="navigateToEmployeeRevenueAdjustment()"
                routerLinkActive="active"
                *ngIf="
                  isShowPayroll == true &&
                  checkmenudata('Employee Revenue Adjustment')
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">attach_money</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="
                      employeeRevenueAdjustmentToolTip
                        ? 'Employee Revenue Adjustment'
                        : ''
                    "
                    (mouseenter)="employeeRevenueAdjustmentToolTip = true"
                    (mouseleave)="employeeRevenueAdjustmentToolTip = false"
                  >
                    {{ "Employee Revenue Adjustment" | slice : 0 : 15
                    }}{{
                      "Employee Revenue Adjustment".length > 15 ? "..." : ""
                    }}
                  </a>
                </div>
              </mat-list-item>
              <!-- <div *ngIf="isShowPayroll == true && checkmenudata('Run Payroll')" >
                          <ul class="mb-0 sub-menus">
                              <li class="p-0" routerLink="employee-payroll-list">
                                <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">manage_accounts</span>
                                  <a *ngIf="expand" mat-list-item routerLink="employee-payroll-list" routerLinkActive="active">Manage Payroll</a>
                              </li>
                          </ul>
                      </div> -->

              <mat-list-item
                (mouseenter)="managePayrollToolTip = true"
                (mouseleave)="managePayrollToolTip = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRouteManagePayroll()
                }"
                [class.router-link-active]="isActiveRouteManagePayroll()"
                (click)="navigateToManagePayroll()"
                routerLinkActive="active"
                *ngIf="isShowPayroll == true && checkmenudata('Run Payroll')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">account_balance</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="managePayrollToolTip ? 'Manage Payroll' : ''"
                    (mouseenter)="managePayrollToolTip = true"
                    (mouseleave)="managePayrollToolTip = false"
                  >
                    {{ "Manage Payroll" | slice : 0 : 15
                    }}{{ "Manage Payroll".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <!-- <div *ngIf="isShowPayroll == true  && checkmenudata('Manage PaySlip')">
                          <ul class="mb-0 sub-menus">
                              <li class="p-0" routerLink="manage-payslip">
                                <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">manage_history</span>
                                  <a *ngIf="expand" mat-list-item routerLink="manage-payslip" outerLinkActive="active">Manage Pay Slip</a>
                              </li>
                          </ul>
                      </div> -->

              <mat-list-item
                (mouseenter)="managePaySlipToolTip = true"
                (mouseleave)="managePaySlipToolTip = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveManagePaySlip()
                }"
                [class.router-link-active]="isActiveManagePaySlip()"
                (click)="navigateToManagePayslip()"
                routerLinkActive="active"
                *ngIf="isShowPayroll == true && checkmenudata('Manage PaySlip')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">description</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="managePaySlipToolTip ? 'Manage Pay Slip' : ''"
                    (mouseenter)="managePaySlipToolTip = true"
                    (mouseleave)="managePaySlipToolTip = false"
                  >
                    {{ "Manage Pay Slip" | slice : 0 : 15
                    }}{{ "Manage Pay Slip".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <mat-list-item
                (mouseenter)="paySlip = true"
                (mouseleave)="paySlip = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActivePaySlip()
                }"
                (click)="navigateToPayslip()"
                [class.router-link-active]="isActivePaySlip()"
                routerLinkActive="active"
                *ngIf="
                  isShowPayroll == true &&
                  checkmenudata('Employee PaySlip') &&
                  isEmployee
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">receipt_long</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="paySlip ? 'Pay Slip' : ''"
                    (mouseenter)="paySlip = true"
                    (mouseleave)="paySlip = false"
                  >
                    {{ "Pay Slip" | slice : 0 : 15
                    }}{{ "Pay Slip".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>
              <!-- <div *ngIf="isShowPayroll == true">
                          <ul class="mb-0 sub-menus">
                              <li routerLink="employee-annexue-list" routerLinkActive="active">Manage Employee Annexure</li>
                          </ul>
                      </div> 
                      <div *ngIf="isShowPayroll == true">
                          <ul class="mb-0 sub-menus">
                              <li routerLink="shift-calendar" routerLinkActive="active">Manage ShiftCalendar</li>
                          </ul>
                      </div> 
                      <div *ngIf="isShowPayroll == true">
                          <ul class="mb-0 sub-menus">
                              <li routerLink="emp-shift-list" routerLinkActive="active">Manage Employee Shift</li>
                          </ul>
                      </div> -->
              <!-- <div
                *ngIf="isShowPayroll == true && checkmenudata('Employee Annexure')"
              >
                <ul class="mb-0 sub-menus">
                  <li class="p-0" routerLink="employee-annexue-list">
                    <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">manage_accounts</span>
                    <a *ngIf="expand"
                      mat-list-item
                      routerLink="employee-annexue-list"
                      routerLinkActive="active"
                      >Manage Annexure</a
                    >
                  </li>
                </ul>
              </div> -->

              <mat-list-item
                (mouseenter)="manageAnnexure = true"
                (mouseleave)="manageAnnexure = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActivePayrollManageAnnexure()
                }"
                [class.router-link-active]="isActivePayrollManageAnnexure()"
                (click)="navigateToManageAnnexure()"
                routerLinkActive="active"
                *ngIf="
                  isShowPayroll == true && checkmenudata('Employee Annexure')
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon"
                    >insert_drive_file</mat-icon
                  >
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="manageAnnexure ? 'Manage Annexure' : ''"
                    (mouseenter)="manageAnnexure = true"
                    (mouseleave)="manageAnnexure = false"
                  >
                    {{ "Manage Annexure" | slice : 0 : 15
                    }}{{ "Manage Annexure".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>
              <!-- <div *ngIf="isShowPayroll == true && checkmenudata('Manage Elements')">
                <ul class="mb-0 sub-menus">
                  <li class="p-0" routerLink="manage-elements">
                    <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">group_add</span>
                    <a *ngIf="expand"
                      mat-list-item
                      routerLink="manage-elements"
                      routerLinkActive="active"
                      >Manage Elements</a
                    >
                  </li>
                </ul>
              </div> -->

              <mat-list-item
                (mouseenter)="manageElementsToolTip = true"
                (mouseleave)="manageElementsToolTip = false"
                routerLink="select-manage-elements"
                routerLinkActive="active"
                *ngIf="
                  isShowPayroll == true && checkmenudata('Manage Elements')
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">view_list</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="
                      manageElementsToolTip ? 'Manage Elements' : ''
                    "
                    (mouseenter)="manageElementsToolTip = true"
                    (mouseleave)="manageElementsToolTip = false"
                  >
                    {{ "Manage Elements" | slice : 0 : 15
                    }}{{ "Manage Elements".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>
              <!-- <div *ngIf="isShowPayroll == true && checkmenudata('Employee Leave')">
                <ul class="mb-0 sub-menus">
                  <li class="p-0"  routerLink="manage-employee-leave">
                    <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">people_alt</span>
                    <a *ngIf="expand"
                      mat-list-item
                      routerLink="manage-employee-leave"
                      routerLinkActive="active"
                      >Employee Leave</a
                    >
                  </li>
                </ul>
              </div> -->

              <mat-list-item
                (mouseenter)="employeeLeaveToolTip = true"
                (mouseleave)="employeeLeaveToolTip = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveEmployeeLeave()
                }"
                [class.router-link-active]="isActiveEmployeeLeave()"
                (click)="navigateToEmployeeLeave()"
                routerLinkActive="active"
                *ngIf="isShowPayroll == true && checkmenudata('Employee Leave')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">event</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="employeeLeaveToolTip ? 'Employee Leave' : ''"
                    (mouseenter)="employeeLeaveToolTip = true"
                    (mouseleave)="employeeLeaveToolTip = false"
                  >
                    {{ "Employee Leave" | slice : 0 : 15
                    }}{{ "Employee Leave".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>
              <!-- <div *ngIf="isShowPayroll == true && checkmenudata('Employee Rating')">
                <ul class="mb-0 sub-menus">
                  <li class="p-0"  routerLink="employee-rating-list">
                    <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">thumb_up</span>
                    <a *ngIf="expand"
                      mat-list-item
                      routerLink="employee-rating-list"
                      routerLinkActive="active"
                      >Employee Rating</a
                    >
                  </li>
                </ul>
              </div> -->

              <mat-list-item
                (mouseenter)="employeeRating = true"
                (mouseleave)="employeeRating = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveEmployeeRating()
                }"
                [class.router-link-active]="isActiveEmployeeRating()"
                (click)="navigateToEmployeeRating()"
                routerLinkActive="active"
                *ngIf="
                  isShowPayroll == true && checkmenudata('Employee Rating')
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">star</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="employeeRating ? 'Employee Rating' : ''"
                    (mouseenter)="employeeRating = true"
                    (mouseleave)="employeeRating = false"
                  >
                    {{ "Employee Rating" | slice : 0 : 15
                    }}{{ "Employee Rating".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <!-- <div
                *ngIf="isShowPayroll == true && checkmenudata('Manage Variable Pay')"
              >
                <ul class="mb-0 sub-menus">
                  <li class="p-0" routerLink="manage-variablepay">
                    <span routerLinkActive="active" mat-list-item *ngIf="!expand" class="--mat-sidebar-icon material-icons-outlined style-1">attach_money</span>
                    <a *ngIf="expand"
                      mat-list-item
                      routerLink="manage-variablepay"
                      routerLinkActive="active"
                      >Manage Variable Pay</a
                    >
                  </li>
                </ul>
              </div> -->

              <mat-list-item
                (mouseenter)="manageVariablePayToolTip = true"
                (mouseleave)="manageVariablePayToolTip = false"
                routerLink="manage-variablepay"
                routerLinkActive="active"
                *ngIf="
                  isShowPayroll == true && checkmenudata('Manage Variable Pay')
                "
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">tune</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    [matTooltip]="
                      manageVariablePayToolTip ? 'Manage Variable Pay' : ''
                    "
                    (mouseenter)="manageVariablePayToolTip = true"
                    (mouseleave)="manageVariablePayToolTip = false"
                  >
                    {{ "Manage Variable Pay" | slice : 0 : 15
                    }}{{ "Manage Variable Pay".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item>

              <div class="menu-sub-menus">
                <!-- <mat-list-item
              
              >
                <mat-icon class="mat-sidebar-icon">description</mat-icon>
                <a
                  *ngIf="expand"
                  (click)="toggle9()"
                  mat-list-item
                  class="sidebar-icons"
                >
                  <span class="mat-item" *ngIf="reports"> Reports </span>
                </a>
              </mat-list-item> -->

                <!-- //----------------Payroll report -->
                <mat-list-item
                  (click)="toggle9()"
                  routerLinkActive="active"
                  *ngIf="
                    checkmenudata('Consolidated Report') ||
                    checkmenudata('PF Report') ||
                    checkmenudata('TDS Report') ||
                    checkmenudata('Consolidate Payslip Report') ||
                    checkmenudata('ESI Report') ||
                    checkmenudata('NetPay Report')
                  "
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                      description</mat-icon
                    >

                    <span *ngIf="expand" class="mat-item side-menu">
                      Reports
                    </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-projects"
                      style="margin-left: 79px"
                      *ngIf="expand"
                    >
                      {{
                        payrollReports ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>
                <!-- //----------------report -->

                <!-- <div
                  *ngIf="
                    isShowReport == true && checkmenudata('Consolidated Report')
                  "
                >
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="consolidatereport-list"
                        routerLinkActive="active"
                        >Consolidated Report</a
                      >
                    </li>
                  </ul>
                </div> -->

                <mat-list-item
                  (mouseenter)="consolidatedReport = true"
                  (mouseleave)="consolidatedReport = false"
                  routerLink="consolidatereport-list"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveConsolidatedReport()
                  }"
                  [class.router-link-active]="isActiveConsolidatedReport()"
                  (click)="navigateToConsolidatedReports()"
                  routerLinkActive="active"
                  *ngIf="
                    isShowReport == true && checkmenudata('Consolidated Report')
                  "
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">assessment</mat-icon>
                    <a
                      *ngIf="expand"
                      class="sidebar-icons side-menu text-truncate"
                      [matTooltip]="
                        consolidatedReport ? 'Consolidated Report' : ''
                      "
                      (mouseenter)="consolidatedReport = true"
                      (mouseleave)="consolidatedReport = false"
                    >
                      {{ "Consolidated Report" | slice : 0 : 15
                      }}{{ "Consolidated Report".length > 15 ? "..." : "" }}
                    </a>
                  </div>
                </mat-list-item>
                <!-- <div *ngIf="isShowReport == true && checkmenudata('PF Report')">
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="pfreport-list"
                        routerLinkActive="active"
                        >PF Report</a
                      >
                    </li>
                  </ul>
                </div> -->

                <mat-list-item
                  (mouseenter)="pFReport = true"
                  (mouseleave)="pFReport = false"
                  routerLink="pfreport-list"
                  routerLinkActive="active"
                  *ngIf="isShowReport == true && checkmenudata('PF Report')"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon"
                      >account_balance_wallet</mat-icon
                    >
                    <a
                      *ngIf="expand"
                      class="sidebar-icons side-menu text-truncate"
                      [matTooltip]="pFReport ? 'PF Report' : ''"
                      (mouseenter)="pFReport = true"
                      (mouseleave)="pFReport = false"
                    >
                      {{ "PF Report" | slice : 0 : 15
                      }}{{ "PF Report".length > 15 ? "..." : "" }}
                    </a>
                  </div>
                </mat-list-item>
                <!-- <div
                  *ngIf="isShowReport == true && checkmenudata('TDS Report')"
                >
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="tdsreport-list"
                        routerLinkActive="active"
                        >TDS Report</a
                      >
                    </li>
                  </ul>
                </div> -->

                <mat-list-item
                  (mouseenter)="tDSReportToolTip = true"
                  (mouseleave)="tDSReportToolTip = false"
                  routerLink="tdsreport-list"
                  routerLinkActive="active"
                  *ngIf="isShowReport == true && checkmenudata('TDS Report')"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">gavel</mat-icon>
                    <a
                      *ngIf="expand"
                      class="sidebar-icons side-menu text-truncate"
                      [matTooltip]="tDSReportToolTip ? 'TDS Report' : ''"
                      (mouseenter)="tDSReportToolTip = true"
                      (mouseleave)="tDSReportToolTip = false"
                    >
                      {{ "TDS Report" | slice : 0 : 15
                      }}{{ "TDS Report".length > 15 ? "..." : "" }}
                    </a>
                  </div>
                </mat-list-item>
                <!-- <div
                  *ngIf="
                    isShowReport == true &&
                    checkmenudata('Consolidate Payslip Report')
                  "
                >
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="client-consolidatePayslip"
                        routerLinkActive="active"
                        >Consolidate Payslip Report</a
                      >
                    </li>
                  </ul>
                </div> -->

                <mat-list-item
                  (mouseenter)="consolidatePayslipReport = true"
                  (mouseleave)="consolidatePayslipReport = false"
                  routerLink="consolidatePayslip"
                  routerLinkActive="active"
                  *ngIf="
                    isShowReport == true &&
                    checkmenudata('Consolidate Payslip Report')
                  "
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">show_chart</mat-icon>
                    <a
                      *ngIf="expand"
                      class="sidebar-icons side-menu text-truncate"
                      [matTooltip]="
                        consolidatePayslipReport
                          ? 'Consolidate Payslip Report'
                          : ''
                      "
                      (mouseenter)="consolidatePayslipReport = true"
                      (mouseleave)="consolidatePayslipReport = false"
                    >
                      {{ "Consolidate Payslip Report" | slice : 0 : 15
                      }}{{
                        "Consolidate Payslip Report".length > 15 ? "..." : ""
                      }}
                    </a>
                  </div>
                </mat-list-item>

                <mat-list-item
                  (mouseenter)="esiReport = true"
                  (mouseleave)="esiReport = false"
                  routerLink="esireport"
                  routerLinkActive="active"
                  *ngIf="isShowReport == true && checkmenudata('ESI Report')"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">show_chart</mat-icon>
                    <a
                      *ngIf="expand"
                      class="sidebar-icons side-menu text-truncate"
                      [matTooltip]="esiReport ? 'ESI Report' : ''"
                      (mouseenter)="esiReport = true"
                      (mouseleave)="esiReport = false"
                    >
                      {{ "ESI Report" | slice : 0 : 15
                      }}{{ "ESI Report".length > 15 ? "..." : "" }}
                    </a>
                  </div>
                </mat-list-item>

                <mat-list-item
                  (mouseenter)="netpayreport = true"
                  (mouseleave)="netpayreport = false"
                  routerLink="netpayreport"
                  routerLinkActive="active"
                  *ngIf="isShowReport == true && checkmenudata('NetPay Report')"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">show_chart</mat-icon>
                    <a
                      *ngIf="expand"
                      class="sidebar-icons side-menu text-truncate"
                      [matTooltip]="netpayreport ? 'Net Pay Report' : ''"
                      (mouseenter)="netpayreport = true"
                      (mouseleave)="netpayreport = false"
                    >
                      {{ "Net Pay Report" | slice : 0 : 15
                      }}{{ "Net Pay Report".length > 15 ? "..." : "" }}
                    </a>
                  </div>
                </mat-list-item>
                <div *ngIf="isShowReport == true && checkmenudata('OT Report')">
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="client-ot-report"
                        routerLinkActive="active"
                        >OT Report</a
                      >
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="isShowReport == true && checkmenudata('PF Challan')"
                >
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="client-pf-challan"
                        routerLinkActive="active"
                        >PF Challan</a
                      >
                    </li>
                  </ul>
                </div>
                <!-- <div
                  *ngIf="isShowReport == true && checkmenudata('ESI Report')"
                >
                  <ul class="mb-0 sub-menus">
                    
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="esireport"
                        routerLinkActive="active"
                        >ESI Report</a
                      >
                    </li>
                  </ul>
                </div>
                <div
                  *ngIf="isShowReport == true && checkmenudata('NetPay Report')"
                >
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="netpayreport"
                        routerLinkActive="active"
                        >Net Pay Report</a
                      >
                    </li>
                  </ul>
                </div> -->
                <div
                  *ngIf="isShowReport == true && checkmenudata('Wage Register')"
                >
                  <ul class="mb-0 sub-menus">
                    <li class="p-0">
                      <a
                        mat-list-item
                        routerLink="wage-register"
                        routerLinkActive="active"
                        >Wage Register</a
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <div class="menu-sub-menus">
             

                <mat-list-item (click)="togglepayroll()" routerLinkActive="active">
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                      description</mat-icon
                    >

                    <span *ngIf="expand" class="mat-item side-menu">
                      Setup
                    </span>
                    <span
                      class="material-icons-outlined icon arrow-icon-projects" style="margin-left:92px"
                      *ngIf="expand"
                    >
                      {{
                        SetupReports ? "expand_more" : "chevron_right"
                      }}</span
                    >
                  </div>
                </mat-list-item>
              
                <mat-list-item
                  [matTooltip]=" organizationcomplaice? 'Organization Complaince' : ''"
                  (mouseenter)="organizationcomplaice = true"
                  (mouseleave)="organizationcomplaice = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveorganizationcomplaice()
                  }"
                  [class.router-link-active]="isActiveorganizationcomplaice()"
                  (click)="navigateToorganizationcomplaice()"
                  routerLinkActive="active"
                  *ngIf="
                    SetupReports == true && checkmenudata('Organization Complaince')
                  "
                  matTooltip="Assign Employee Task"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon">assessment</mat-icon>
                    <a
                      *ngIf="expand"
                      class="sidebar-icons side-menu text-truncate"
                      (mouseenter)="organizationcomplaice = true"
                      (mouseleave)="organizationcomplaice = false"
                    >
                      {{ "organization Complaice" | slice : 0 : 15
                      }}{{ "organization Complaice".length > 15 ? "..." : "" }}
                    </a>
                  </div>
                </mat-list-item>
               

               
                
               
              </div> -->

              <div class="menu-sub-menus">
                <mat-list-item
                  (click)="handleClickSetup()"
                  routerLinkActive="active"
                  *ngIf="expand && checkmenudata('Organization Complaince')"
                >
                  <div class="met-list-menu" *ngIf="expand">
                    <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                      meeting_room</mat-icon
                    >

                    <span class="mat-item side-menu"> Setup </span>
                    <span
                      class="material-icons-outlined icon arrow-icon"
                      *ngIf="expand"
                    >
                      {{ isSetup ? "expand_more" : "chevron_right" }}</span
                    >
                  </div>
                </mat-list-item>
                <div
                  (mouseenter)="organizationcomplaice = true"
                  (mouseleave)="organizationcomplaice = false"
                >
                  <mat-list-item
                    (mouseenter)="organizationcomplaice = true"
                    (mouseleave)="organizationcomplaice = false"
                    *ngIf="isSetupList"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isActiveRouteSetup()
                    }"
                    [class.router-link-active]="isActiveRouteSetup()"
                    (click)="navigateTosetUps()"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                  >
                    <div class="met-list-menu">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                        >&#8226;</mat-icon
                      >

                      <span
                        class="mat-item side-menu"
                        [matTooltip]="
                          organizationcomplaice ? 'Organization Complaince' : ''
                        "
                        (mouseenter)="organizationcomplaice = true"
                        (mouseleave)="organizationcomplaice = false"
                      >
                        {{ "Organization Complaice" | slice : 0 : 15
                        }}{{
                          "organization Complaice".length > 15 ? "..." : ""
                        }}
                      </span>
                    </div>
                  </mat-list-item>
                </div>
              </div>
            </div>

            <!-- </div> -->
            <!-- pms start here -->
            <div *ngIf="localStorageMenuId == 'PMS' && checkmoduledata('PMS')">
              <mat-list-item
                routerLink="pms-admin-dashboard"
                routerLinkActive="active"
                *ngIf="checkmenudata('PMS Dashboard')"
              >
                <a
                  *ngIf="expand"
                  mat-list-item
                  class="sidebar-icons"
                  title="PMS Dashboard"
                >
                  <mat-icon class="mat-sidebar-icon" style="margin-left: -25px"
                    >dashboard</mat-icon
                  >
                  Dashboard
                </a>
              </mat-list-item>

              <div class="menu-sub-menus">
                <!-- KRA Template start-->
                <!-- <mat-list-item
                  routerLink="kra-template"
                  routerLinkActive="active"
                >
                  
                  <a *ngIf="expand" mat-list-item class="sidebar-icons" title=" KRA Template">
                    <mat-icon class="mat-sidebar-icon" style=" margin-left: -25px;">library_add</mat-icon>
                    KRA Template
                  </a>  
                </mat-list-item> -->

                <!-- <mat-list-item
                
                [matTooltip]="addKra ? 'KRA Template' : ''"
                (mouseenter)="addKra = true"
                (mouseleave)="addKra = false"
                [routerLink]="['kra-template']"
                [routerLinkActive]="['active']"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveKraTemplate()
                }"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveAddKraTemplate()
                }"
                 [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveEditKraTemplate()
                }"
               
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon"
                    >open_in_new</mat-icon
                  >
                  <a
                    (mouseenter)="addKra = true"
                    (mouseleave)="addKra = false"
                    *ngIf="expand"
                    class="sidebar-icons side-menu text-truncate"
                    (click)="setHeaderTitle('KRA Template')"
                  >
                    {{ "KRA Template" | slice : 0 : 15
                    }}{{ "KRA Template".length > 15 ? "..." : "" }}
                  </a>
                </div>
              </mat-list-item> -->

                <mat-list-item
                  (mouseenter)="addKra = true"
                  (mouseleave)="addKra = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteAddkra()
                  }"
                  [class.router-link-active]="isActiveRouteAddkra()"
                  (click)="navigateToaddkra()"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: false }"
                  *ngIf="checkmenudata('KRA Template')"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon dot"
                      >open_in_new</mat-icon
                    >

                    <span
                      class="mat-item side-menu"
                      [matTooltip]="addKra ? ' KRA Template' : ''"
                      (mouseenter)="addKra = true"
                      (mouseleave)="addKra = false"
                    >
                      KRA Template
                    </span>
                  </div>
                </mat-list-item>

                <!-- <mat-list-item
                (mouseenter)="addKra = true"
                (mouseleave)="addKra = false"
          
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRouteAddkra()
                }"
                [class.router-link-active]="isActiveRouteAddkra()"
                (click)="navigateToaddkra()"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: false }"
              >
                <div class="met-list-menu">
                  <mat-icon  class="mat-sidebar-icon dot"
                    >open_in_new</mat-icon
                  >
  
                  <span
                    class="mat-item side-menu"
                    
                    [matTooltip]="addKra ? 'KRA Template' : ''"
                    (mouseenter)="addKra = true"
                    (mouseleave)="addKra = false"
                  >
                  {{ "KRA Template" | slice : 0 : 15
                }}{{ " KRA Template".length > 15 ? "..." : "" }}
                  </span>
                </div>
              </mat-list-item> -->

                <!-- <mat-list-item
              (mouseenter)="assignKra = true"
              (mouseleave)="assignKra = false"
        
              [ngClass]="{
                'mat-list-item': true,
                active: isActiveRouteAssignkra()
              }"
              [class.router-link-active]="isActiveRouteAssignkra()"
              (click)="navigateToassignkra()"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <div class="met-list-menu">
                <mat-icon  class="mat-sidebar-icon dot"
                  >open_in_new</mat-icon
                >

                <span
                  class="mat-item side-menu"
                  
                  [matTooltip]="assignKra ? 'Assign KRA' : ''"
                  (mouseenter)="assignKra = true"
                  (mouseleave)="assignKra = false"
                >
                {{ "Assign KRA" | slice : 0 : 15
              }}{{ " Assign KRA".length > 15 ? "..." : "" }}
                </span>
              </div>
            </mat-list-item> -->

                <mat-list-item
                  (mouseenter)="assignkratemplate = true"
                  (mouseleave)="assignkratemplate = false"
                  [ngClass]="{
                    'mat-list-item': true,
                    active: isActiveRouteAssignkraTemplate()
                  }"
                  [class.router-link-active]="isActiveRouteAssignkraTemplate()"
                  (click)="navigateToAssignkraTemplate()"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: false }"
                  *ngIf="checkmenudata('Assign KRA')"
                >
                  <div class="met-list-menu">
                    <mat-icon class="mat-sidebar-icon dot"
                      >open_in_new</mat-icon
                    >

                    <span
                      class="mat-item side-menu"
                      [matTooltip]="assignkratemplate ? ' Assign KRA' : ''"
                      (mouseenter)="assignkratemplate = true"
                      (mouseleave)="assignkratemplate = false"
                    >
                      Assign KRA
                    </span>
                  </div>
                </mat-list-item>

                <!-- Assign KRA end -->

                <!-- Approvals start-->
                <!-- <div class="menu-sub-menus">
                    <mat-list-item>
                        <mat-icon class="mat-sidebar-icon">thumb_up</mat-icon>
                        <a (click)="toggle(1)" mat-list-item class="sidebar-icons" title="KRA">
                            <span class="mat-item" *ngIf="expand">
                               Approvals
                            </span>
                        </a>
                    </mat-list-item>
                    <div *ngIf="isShow == true && isShowValue == 1">
                        <ul class="mb-0 sub-menus sub">
                            <li>
                                <a *ngIf="expand" routerLink="approver-rules" routerLinkActive="active" title="Approver Rules" class="sidebar-icons">
                                    Approver Rules
                                </a>
                            </li>
                            <li>
                                <a *ngIf="expand" routerLink="employee-approvers" routerLinkActive="active" title="Employee Approvers" class="sidebar-icons">
                                    Employee Approvers
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <!-- Approvals end-->

                <!-- Appraisal start-->
                <div class="menu-sub-menus">
                  <mat-list-item
                    (click)="handleClickAppraisal()"
                    routerLinkActive="active"
                    *ngIf="
                      checkmenudata('Manage Appraisal') ||
                      checkmenudata('Appraisal Reports')
                    "
                  >
                    <div class="met-list-menu" *ngIf="expand">
                      <mat-icon *ngIf="expand" class="mat-sidebar-icon">
                        library_books</mat-icon
                      >

                      <span class="mat-item side-menu"> Appraisal </span>
                      <span
                        class="material-icons-outlined icon arrow-icon"
                        style="margin-left: 65px"
                        *ngIf="expand"
                      >
                        {{
                          appraisalIcon ? "expand_more" : "chevron_right"
                        }}</span
                      >
                    </div>
                  </mat-list-item>
                  <div
                    (mouseenter)="momReports = true"
                    (mouseleave)="momReports = false"
                  >
                    <mat-list-item
                      (mouseenter)="appraisal = true"
                      (mouseleave)="appraisal = false"
                      *ngIf="
                        appraisalList == true &&
                        checkmenudata('Manage Appraisal')
                      "
                      [ngClass]="{
                        'mat-list-item': true,
                        active: isActiveAppraisal()
                      }"
                      [class.router-link-active]="isActiveAppraisal()"
                      (click)="navigateToManageAppraisal()"
                      routerLinkActive="active"
                    >
                      <div class="met-list-menu">
                        <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                          >&#8226;</mat-icon
                        >

                        <span
                          class="mat-item side-menu"
                          [matTooltip]="appraisal ? 'Manage Appraisal' : ''"
                        >
                          Manage Appraisal
                        </span>
                      </div>
                    </mat-list-item>

                    <mat-list-item
                      (mouseenter)="appraisal = true"
                      (mouseleave)="appraisal = false"
                      *ngIf="
                        appraisalList == true &&
                        checkmenudata('Appraisal Reports')
                      "
                      routerLink="reports-appra"
                      routerLinkActive="active"
                    >
                      <div class="met-list-menu">
                        <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                          >&#8226;</mat-icon
                        >

                        <span
                          class="mat-item side-menu"
                          [matTooltip]="appraisal ? 'Appraisal Reports' : ''"
                        >
                          Appraisal Reports
                        </span>
                      </div>
                    </mat-list-item>
                    <!-- <mat-list-item
                      (mouseenter)="appraisal = true"
                      (mouseleave)="appraisal = false"
                      *ngIf="appraisalList == true"
         

                      [ngClass]="{
                        'mat-list-item': true,
                        active: isAddAppraisal()
                      }"
                      [class.router-link-active]="isAddAppraisal()"
                      (click)="navigateToAppraisalReports()"
                      routerLinkActive="active"
                    >
                      <div class="met-list-menu">
                        <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                          >&#8226;</mat-icon
                        >

                        <span
                          class="mat-item side-menu"
                          [matTooltip]="appraisal ? 'Appraisal Reports' : ''"
                        >
                          Appraisal Reports
                        </span>
                      </div>
                    </mat-list-item> -->
                  </div>
                </div>

                <!-- Pms admin-Report -->
                <div class="menu-sub-menus">
                  <!-- <mat-list-item>
                    <mat-icon class="mat-sidebar-icon">library_books</mat-icon>
                    <a
                      (click)="toggle(4)"
                      routerLink="admin-reports"
                      mat-list-item
                      class="sidebar-icons"
                      title="Assignment Reports"
                    >
                      <span class="mat-item" *ngIf="expand">
                        Assignment Reports
                      </span>
                      
                    </a>
                  </mat-list-item> -->

                  <!-- <mat-list-item
                  routerLink="admin-reports"
                  routerLinkActive="active"
                >
                 
                  <a *ngIf="expand" mat-list-item class="sidebar-icons" title=" Assignment Reports">
                    <mat-icon class="mat-sidebar-icon" style=" margin-left: -25px;">library_books</mat-icon>
                    Assignment Reports
                  </a>
                </mat-list-item> -->

                  <mat-list-item
                    [matTooltip]="assignmentReports ? 'Assignment Reports' : ''"
                    (mouseenter)="assignmentReports = true"
                    (mouseleave)="assignmentReports = false"
                    [routerLink]="['admin-reports']"
                    [routerLinkActive]="['active']"
                    [ngClass]="{
                      'mat-list-item': true,
                      active: isadminAppraisal()
                    }"
                    *ngIf="checkmenudata('Assignment Reports')"
                  >
                    <div class="met-list-menu">
                      <mat-icon class="mat-sidebar-icon"
                        >library_books</mat-icon
                      >
                      <a
                        (mouseenter)="assignmentReports = true"
                        (mouseleave)="assignmentReports = false"
                        *ngIf="expand"
                        class="sidebar-icons side-menu text-truncate"
                      >
                        {{ "Assignment Reports" | slice : 0 : 15
                        }}{{ "Assignment Reports".length > 15 ? "..." : "" }}
                      </a>
                    </div>
                  </mat-list-item>
                </div>

                <!-- Approvals end-->

                <!-- Reports start-->
                <!-- <mat-list-item routerLink="users" routerLinkActive="active">
                  
                  <a *ngIf="expand" mat-list-item class="sidebar-icons"  title="Reports">
                    <mat-icon class="mat-sidebar-icon" style=" margin-left: -25px;">ballot</mat-icon>
                    Reports
                  </a>
                </mat-list-item> -->
                <!-- Reports end-->
              </div>
            </div>
            <!-- pms end here -->

            <!-- <expense Statrts here!....> -->

            <div
              *ngIf="
                localStorageMenuId === 'EXPENSEREIMBURSEMENT' &&
                checkmoduledata('EXPENSEREIMBURSEMENT')
              "
            >
              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? 'Category' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                routerLink="category"
                routerLinkActive="active"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">category</mat-icon>
                  <a
                    (click)="setHeaderTitle('Category')"
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    (click)="setHeaderTitle2()"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Category
                  </a>
                </div>
              </mat-list-item>

              <mat-list-item
                [matTooltip]="showTimesheetTooltip ? 'Advance' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                routerLink="advance"
                routerLinkActive="active"
                *ngIf="checkmenudata('Advance')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">wallet</mat-icon>
                  <a
                    (click)="setHeaderTitle('Advance')"
                    (mouseenter)="showTimesheetTooltip = true"
                    (mouseleave)="showTimesheetTooltip = false"
                    (click)="setHeaderTitle2()"
                    *ngIf="expand"
                    class="sidebar-icons side-menu"
                  >
                    Advance
                  </a>
                </div>
              </mat-list-item>
              <mat-list-item
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveExpenseReibursement()
                }"
                [class.router-link-active]="isActiveExpenseReibursement()"
                (click)="navigateExpenseReibursement()"
                routerLinkActive="active"
                [matTooltip]="
                  showTimesheetTooltip ? 'Expense Reimbursement' : ''
                "
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                *ngIf="checkmenudata('Expense Reimbursement')"
              >
                <div class="met-list-menu">
                  <mat-icon class="mat-sidebar-icon">price_check</mat-icon>
                  <a
                    *ngIf="expand"
                    class="sidebar-icons"
                    style="text-wrap: nowrap"
                  >
                    Expense Reimbursement
                  </a>
                </div>
              </mat-list-item>

              <mat-list-item
                routerLink="manage-approvals"
                routerLinkActive="active"
                [matTooltip]="showTimesheetTooltip ? 'Manage Approvals' : ''"
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                *ngIf="checkmenudata('Manage Approvals')"
              >
                <div class="met-list-menu">
                  <mat-icon
                    _ngcontent-owi-c110=""
                    role="img"
                    class="mat-sidebar-icon"
                    aria-hidden="true"
                    data-mat-icon-type="font"
                    >approval</mat-icon
                  >
                  <a *ngIf="expand" class="sidebar-icons side-menu">
                    Manage Approvals
                  </a>
                </div>
              </mat-list-item>

              <mat-list-item
                routerLink="manage-advance-approvals"
                routerLinkActive="active"
                [matTooltip]="
                  showTimesheetTooltip ? 'Manage Advance Approvals' : ''
                "
                (mouseenter)="showTimesheetTooltip = true"
                (mouseleave)="showTimesheetTooltip = false"
                *ngIf="checkmenudata('Manage Advance Approvals')"
              >
                <div class="met-list-menu">
                  <mat-icon
                    _ngcontent-mpb-c110=""
                    role="img"
                    class="mat-sidebar-icon"
                    aria-hidden="true"
                    data-mat-icon-type="font"
                    >published_with_changes</mat-icon
                  >
                  <a
                    *ngIf="expand"
                    style="text-wrap: pretty"
                    class="sidebar-icons side-menu"
                  >
                    Manage Advance Approvals
                  </a>
                </div>
              </mat-list-item>
            </div>

            <div
              class="menu-sub-menus"
              *ngIf="localStorageMenuId === 'CHRMS' && checkmoduledata('CHRMS')"
            >
              <!-- <mat-list-item
                (click)="handleCHRMSClickClient()"
                *ngIf="
                  checkmenudata('Client Working Days') ||
                  checkmenudata('Clients') ||
                  checkmenudata('Client Element Assignment') ||
                  checkmenudata('Employee Client Assignment')
                "
              >
              
              </mat-list-item> -->
              <mat-list-item
                (mouseenter)="clientWorkingDaysTooltips = true"
                (mouseleave)="clientWorkingDaysTooltips = false"
                *ngIf="isShowClient && checkmenudata('Client Working Days')"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRouteclientWorkingDays()
                }"
                [class.router-link-active]="isActiveRouteclientWorkingDays()"
                routerLinkActive="active"
                (click)="navigateToclientWorkingDays()"
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >&#8226;</mat-icon
                  >
                  <span
                    class="mat-item side-menu"
                    [matTooltip]="
                      clientWorkingDaysTooltips ? ' Working Days' : ''
                    "
                    (mouseenter)="clientWorkingDaysTooltips = true"
                    (mouseleave)="clientWorkingDaysTooltips = false"
                    routerLinkActive="active"
                  >
                    Working Days</span
                  >
                </div>
              </mat-list-item>

              <mat-list-item
                *ngIf="
                  isShowClient && checkmenudata('Client Element Assignment')
                "
                routerLinkActive="active"
                [matTooltip]="clientElementsTooltips ? ' Elements' : ''"
                (mouseenter)="clientElementsTooltips = true"
                (mouseleave)="clientElementsTooltips = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRouteclientElements()
                }"
                [class.router-link-active]="isActiveRouteclientElements()"
                (click)="navigateToclientElements()"
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >&#8226;</mat-icon
                  >
                  <span
                    (click)="toggleProjectsClose()"
                    (click)="toggleLoan()"
                    class="mat-item side-menu"
                    routerLinkActive="active"
                  >
                    Elements</span
                  >
                  <!-- <li class="p-0">
                <a
                  (click)="toggleProjectsClose()"
                  mat-list-item
                  routerLink="manage-client-element-list"
                  routerLinkActive="active"
                  >Client Elements</a
                >
              </li> -->
                </div>
              </mat-list-item>
              <!-- <mat-list-item
                [matTooltip]="
                  employeeClientAssignment ? 'Employee Client Assignment' : ''
                "
                routerLink="empassign"
                routerLinkActive="active"
                (mouseenter)="employeeClientAssignment = true"
                (mouseleave)="employeeClientAssignment = false"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRoute()
                }"
                [class.router-link-active]="isActiveRoute()"
                *ngIf="
                  isShowClient == true &&
                  checkmenudata('Employee Client Assignment')
                "
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >&#8226;</mat-icon
                  >

                  <span
                    (click)="toggleProjectsClose()"
                    class="mat-item side-menu"
                    routerLink="empassign"
                    routerLinkActive="active"
                  >
                    {{ "Employee Client Assignment" | slice : 0 : 15
                    }}{{
                      " Employee Client Assignment".length > 15 ? "..." : ""
                    }}</span
                  >
                
                </div>
              </mat-list-item> -->
            </div>

            <div
              class="menu-sub-menus"
              *ngIf="localStorageMenuId == 'CHRMS' && checkmoduledata('CHRMS')"
            >
              <mat-list-item
                *ngIf="
                  checkmenudata('Manage Shift') ||
                  checkmenudata('Manage Shift Calendar') ||
                  checkmenudata('Employee Shift Assignment')
                "
                (click)="toggle5()"
              >
                <div class="met-list-menu">
                  <mat-icon
                    class="mat-sidebar-icon"
                    routerLink="manage-elements"
                  >
                    work_history
                  </mat-icon>
                  <span *ngIf="expand" class="mat-item side-menu">
                    Shift&nbsp;Scheduling
                  </span>
                  <span
                    class="material-icons-outlined icon arrow-icon-shift"
                    *ngIf="expand"
                  >
                    {{
                      shiftSchedulingArrow ? "expand_more" : "chevron_right"
                    }}</span
                  >
                </div>
              </mat-list-item>

              <mat-list-item
                (click)="manageShift()"
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRoute()
                }"
                [class.router-link-active]="isActiveRoute()"
                routerLink="shift"
                routerLinkActive="active"
                *ngIf="isShowShift == true && checkmenudata('Manage Shift')"
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >&#8226;</mat-icon
                  >

                  <span
                    class="mat-item side-menu"
                    [matTooltip]="manageApprover ? 'Manage Shift' : ''"
                    (mouseenter)="manageApprover = true"
                    (mouseleave)="manageApprover = false"
                  >
                    Manage Shift
                  </span>
                </div>
              </mat-list-item>

              <mat-list-item
                routerLink="shift-calendar"
                routerLinkActive="active"
                *ngIf="
                  isShowShift == true && checkmenudata('Manage Shift Calendar')
                "
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >&#8226;</mat-icon
                  >

                  <span
                    class="mat-item side-menu"
                    [matTooltip]="manageApprover ? 'Manage Shift Calendar' : ''"
                    (mouseenter)="manageApprover = true"
                    (mouseleave)="manageApprover = false"
                  >
                    <!-- Manage Shift Calendar -->
                    {{ "  Manage Shift Calendar" | slice : 0 : 15
                    }}{{ "  Manage Shift Calendar".length > 15 ? "..." : "" }}
                  </span>
                </div>
              </mat-list-item>
              <mat-list-item
                [ngClass]="{
                  'mat-list-item': true,
                  active: isActiveRouteEmployeeshift()
                }"
                [class.router-link-active]="isActiveRouteEmployeeshift()"
                routerLinkActive="active"
                (click)="navigateToEmployeeshift()"
                *ngIf="
                  isShowShift == true &&
                  checkmenudata('Employee Shift Assignment')
                "
              >
                <div class="met-list-menu">
                  <mat-icon *ngIf="expand" class="mat-sidebar-icon dot"
                    >&#8226;</mat-icon
                  >

                  <span
                    class="mat-item side-menu"
                    [matTooltip]="
                      manageApprover ? 'Employee Shift Assignment' : ''
                    "
                    (mouseenter)="manageApprover = true"
                    (mouseleave)="manageApprover = false"
                  >
                    <!-- Employee Shift Assignment -->
                    {{ "  Employee Shift Assignment" | slice : 0 : 15
                    }}{{
                      "  Employee Shift Assignment".length > 15 ? "..." : ""
                    }}
                  </span>
                </div>
              </mat-list-item>
            </div>

            <mat-list-item
              class=""
              routerLinkActive="active"
              *ngIf="
                (checkmenudata('Settings') && !isUserAdmin) ||
                isAdmin ||
                clientLogin
              "
              (click)="toggleProjectsClose()"
              (click)="toggleSalaryAdvance()"
              (click)="toggleLoan()"
              (click)="toggleApprover()"
              (click)="toggleClient()"
              (click)="toggleMom(); settingsCloseOtherMenus()"
              (click)="navigateToSettings()"
              [ngClass]="{
                'mat-list-item': true,
                active: isActiveRouteSettings()
              }"
              [class.router-link-active]="isActiveRouteSettings()"
            >
              <div
                class="met-list-menu"
                [matTooltip]="settings ? 'Settings' : ''"
                (mouseenter)="settings = true"
                (mouseleave)="settings = false"
              >
                <mat-icon class="mat-sidebar-icon">settings</mat-icon>
                <a *ngIf="expand" class="sidebar-icons side-menu">Settings</a>
              </div>
            </mat-list-item>

            <!-- not admin menu-->
            <!-- <span *ngIf="usertype.role.roleName !='Admin'"> -->
            <!--  <span>

              <mat-list-item routerLink="recruiter-dashboard" *ngIf="usertype.role.roleName=='Recruiter'" routerLinkActive="active">
                <mat-list-item routerLink="recruiter-dashboard">
                    <mat-icon class="mat-sidebar-icon">home</mat-icon>
                    <a *ngIf="expand" mat-list-item class="sidebar-icons">
                        Dashboard
                    </a>
                </mat-list-item>

                <mat-list-item routerLink="employee" routerLinkActive="active">
                    <mat-icon class="mat-sidebar-icon">badge</mat-icon>
                    <a *ngIf="expand" mat-list-item class="sidebar-icons"> Employees </a>
                </mat-list-item>

                <div class="menu-sub-menus">
                    <mat-list-item>
                        <mat-icon class="mat-sidebar-icon">perm_identity</mat-icon>
                        <a (click)="toggle()" mat-list-item class="sidebar-icons">
                            <span class="mat-item" *ngIf="expand">
                                Recruitment
                            </span>
            <span class="material-icons-outlined icon" *ngIf="expand" class="arrow-icons">
                                {{isShow ? <mat-icon>expand_more</mat-icon> : <mat-icon>expand_less</mat-icon>}}
                                <mat-icon *ngIf="isShow">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="!isShow">keyboard_arrow_right</mat-icon>
                            </span>
            </a>
            </mat-list-item>

            <div *ngIf="isShow == true">
                <ul class="mb-0 sub-menus sub">
                    <li>
                        <mat-icon ss="mat-sidebar-icon">perm_identity</mat-icon>
                        <a *ngIf="expand" routerLink="resource-list" routerLinkActive="active" class="sidebar-icons">
                                            Candidate
                                        </a>
                    </li>

                    <li>
                        <mat-icon ss="mat-sidebar-icon">accessibility_new</mat-icon>
                        <a *ngIf="expand" routerLink="onboardings" routerLinkActive="active" class="sidebar-icons">
                                            Onboarding
                                        </a>
                    </li>

                    <li>
                        <mat-icon ss="mat-sidebar-icon">supervisor_account</mat-icon>
                        <a *ngIf="expand" routerLink="positions" routerLinkActive="active" class="sidebar-icons">
                                            Position
                                        </a>
                    </li>

                    <li>
                        <mat-icon ss="mat-sidebar-icon">supervised_user_circle</mat-icon>
                        <a *ngIf="expand" routerLink="clients" routerLinkActive="active" class="sidebar-icons">
                                            Clients
                                        </a>
                    </li>
                </ul>
            </div>
            </div>

            <mat-list-item routerLink="settings" routerLinkActive="active">
                <mat-icon class="mat-sidebar-icon">settings</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons"> Settings </a>
            </mat-list-item>
            </span> -->
            <!-- not admin menu-->

            <!-- <mat-list-item
              (mouseenter)="signOut = true"
              (mouseleave)="signOut = false"
              (click)="logout()"
              (click)="toggleProjectsClose()"
              (click)="toggleLoan()"
              (click)="toggleSalaryAdvance()"
              (click)="toggleLoan()"
              (click)="toggleApprover()"
              (click)="toggleClient()"
              (click)="toggleMom()"
            >
              <div
                class="met-list-menu"
                [matTooltip]="signOut ? ' Sign Out' : ''"
                (mouseenter)="signOut = true"
                (mouseleave)="signOut = false"
              >
                <mat-icon class="mat-sidebar-icon">logout</mat-icon>
                <a *ngIf="expand" class="sidebar-icons side-menu"> Sign Out</a>
              </div>
            </mat-list-item> -->
          </div>
        </div>

        <!-- <div
          class="ml-3 side-nav-icons"
          [class.side-nav-icons]="
            (showAdditionalIcons && !isSideNavClosed && hideModules) ||
            hideMenus
          "
          (mouseover)="showAdditionalIcons = true"
          (mouseleave)="showAdditionalIcons = false"
        >
          <div class="additional-icons col side-nav">
            <div
              class="ml-n3"
              *ngIf="
                (showAdditionalIcons && !isSideNavClosed) ||
                this.hideModules ||
                hideMenus
              "
            >
              <div class="side-nav-icon" (click)="MatDrawControl()">
                <mat-icon class="mat-icon-color">looks</mat-icon>
              </div>
              <div class="side-nav-icon" (click)="MatDrawControl2()">
                <mat-icon class="mat-icon-color">flare</mat-icon>
              </div>
              <div class="side-nav-icon" (click)="MatDrawControl3()">
                <mat-icon class="mat-icon-color" style="color: #fff"
                  >keyboard_arrow_right</mat-icon
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="!showAdditionalIcons || isSideNavClosed || !isSideNavClosed"
          >
            <mat-icon class="verfied" (click)="MatDrawControl4()"
              >keyboard_arrow_left</mat-icon
            >
          </div>
        </div> -->

        <!-- <div class="ml-3 side-nav-icons" [class.isclosed]="expand"   (mouseover)="showAdditionalIcons = true" (mouseleave)="showAdditionalIcons = false">
          <div class="additional-icons col side-nav" >
            <div class="ml-n3" *ngIf="showAdditionalIcons && !isSideNavClosed || hideModules || hideMenus">
              <div class="side-nav-icon" (click)="MatDrawControl()">
                <mat-icon>home</mat-icon>
              </div>
              <div class="side-nav-icon" (click)="MatDrawControl2()">
                <mat-icon>home</mat-icon>
              </div>
              <div class="side-nav-icon" (click)="MatDrawControl3()">
                <mat-icon>home</mat-icon>
              </div>
            </div>
          </div>
        
          <div *ngIf="!showAdditionalIcons || isSideNavClosed || !isSideNavClosed ">
            <mat-icon class="verfied"  (click)="MatDrawControl4()">verified_user</mat-icon>
          </div>
        </div> -->

        <!-- <div class="ml-3 side-nav-icons" [class.isclosed]="!expand && !showAdditionalIcons && !isSideNavClosed || hideModules || hideMenus" (mouseover)="showAdditionalIcons = true" (mouseleave)="showAdditionalIcons = false">
          <div class="additional-icons col " [class.hover-active]="!isSideNavClosed && expand">
            <div class="ml-n3" *ngIf="showAdditionalIcons && !isSideNavClosed || hideModules || hideMenus">
              <div class="side-nav-icon" (click)="MatDrawControl()">
                <mat-icon>home</mat-icon>
              </div>
              <div class="side-nav-icon" (click)="MatDrawControl2()">
                <mat-icon>home</mat-icon>
              </div>
              <div class="side-nav-icon" (click)="MatDrawControl3()">
                <mat-icon>home</mat-icon>
              </div>
            </div>
          </div>
          
          <div *ngIf="!showAdditionalIcons || isSideNavClosed || !isSideNavClosed || !hideMenus || !hideModules">
            <mat-icon class="verified" (click)="MatDrawControl4()">verified_user</mat-icon>
          </div>
        </div> -->
      </mat-nav-list>
    </div>

    <div
      class="ml-3 side-nav-icons"
      [class.side-nav-icons]="
        (showAdditionalIcons && !isSideNavClosed && hideModules) || hideMenus
      "
      (mouseover)="showAdditionalIcons = true"
      (mouseleave)="showAdditionalIcons = false"
    >
      <div class="additional-icons col side-nav">
        <div
          class=" "
          *ngIf="
            (showAdditionalIcons && !isSideNavClosed) ||
            this.hideModules ||
            hideMenus
          "
        >
          <div class="side-nav-icon mt-3" (click)="MatDrawControl3()">
            <mat-icon class="mat-icon-color ml-1" style="color: #fff"
              >keyboard_arrow_left
            </mat-icon>
          </div>
          <div class="side-nav-icon" (click)="MatDrawControl()">
            <!-- <mat-icon class="mat-icon-color">looks</mat-icon> -->
            <i class="fas fa-angles-left ml-1"></i>
          </div>
          <div class="side-nav-icon mt-2" (click)="MatDrawControl2()">
            <!-- <mat-icon class="mat-icon-color">flare</mat-icon> -->
            <mat-icon class="mat-icon-color ml-1">view_headline</mat-icon>
          </div>
        </div>
      </div>
      <div *ngIf="!showAdditionalIcons || isSideNavClosed || !isSideNavClosed">
        <mat-icon class="verfied" (click)="MatDrawControl4()"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </div>
  </mat-sidenav>

  <!-- mat side nav end here -->

  <!-- mat side nav content start here -->
  <mat-sidenav-content class="app-content">
    <!-- header start here -->
    <mat-toolbar class="navbar container-fluid" color="primary">
      <div>
        <span
          type="button"
          class="text-light menubar"
          mat-button
          (click)="drawer.toggle()"
          *ngIf="drawer.mode === 'over'"
        >
          <mat-icon class="material-icons-round header-menu">menu</mat-icon>
        </span>
      </div>
      <div>
        <span
          type="button"
          class="text-light menubar"
          mat-button
          (click)="MatDrawControl()"
          *ngIf="drawer.mode === 'side'"
        >
          <mat-icon class="material-icons-round header-menu">menu</mat-icon>
        </span>
      </div>
      <!-- <div class="ml-3" (click)="MatDrawControl2()">
        <mat-icon>verified_user</mat-icon>
      </div>
      <div class="ml-3" (click)="MatDrawControl3()">
        <mat-icon>stars</mat-icon>
      </div> -->
      <div class="ml-3">
        <!-- Display the selected menu title here -->
        <div class="menu-title" style="color: #fff">
          {{ selectedModuleName }} {{ selectedMenuTitle }}
        </div>
      </div>
      <!-- <div class="buttons-list">
                <a routerLink="./add-employee" title="Add Employee" class="header-common-buttons btn btn-primary btn-sm">
                  Add Employee
                </a>
                <a routerLink="/home/add-resource" title="Add Resource" class="header-common-buttons btn btn-primary btn-sm">
                  Add Candidate
                </a>
            </div> -->

      <div class="header-buttons">
        <!-- <div ngbDropdown autoClose="true" class="notification-dropdown">
       

          <a
            mat-button
            id="notify"
            mat-icon-button
            [matMenuTriggerFor]="notificationMenu"
            ngbDropdownToggle
            class="notify-icon"
          >
            <span class="notify-count badge badge-info">{{
              notificationcount
            }}</span>
            <mat-icon type="button" class="material-icons-outlined"
              >notifications</mat-icon
            >
          </a>
          <mat-menu #notificationMenu="matMenu" class="notification-list-box">
       
            <div class="" ngbDropdownMenu="notify">
              <a ngbDropdownItem class="notification-list-header">
                <span class="notification-title">Notifications</span>
                <span
                  *ngIf="notificationcount > 0"
                  type="button"
                  class="material-symbols-outlined notification-clear-all deleteall"
                  (click)="deleteAll(notificationlist)"
                >
                  Clear All
                </span>
              </a>
              <a
                *ngIf="notificationcount > 0"
                ngbDropdownItem
                class="matMenu_notify notification-individual"
              >
                <ul
                  *ngFor="let e of notificationlist; let i = index"
                  class="mb-0 notification-lines"
                >
                  <li>
                    <a (click)="resourceDetails(e.resource.resourceId)">
                      <mat-icon
                        class="material-symbols-outlined delete"
                        (click)="delete(i)"
                        >delete</mat-icon
                      >
                      <div class="text-color notify-resource-name">
                        {{ e.resource.resourceName }} -
                        {{ e.resource.resourceNumber }}
                      </div>
                      <div class="sub-text notify-resource-doj">
                        DOJ : {{ e.dateOfJoining.substring(0, 10) }}
                        <span class="notify-resource-position"
                          >Position :
                          {{ e.resource.position.positionName }}</span
                        >
                      </div>
                    </a>
                  </li>
                </ul>
              </a>
              <span
                *ngIf="notificationcount == 0"
                ngbDropdownItem
                class="matMenu_notify notification-individual text-center mt-3 mb-3"
              >
                No Data found.
              </span>
            </div>
          </mat-menu>
        </div> -->

        <span class="pl-3">
          <!-- Menu start here -->
          <a
            *ngIf="headerProfileImage"
            mat-icon-button
            [matMenuTriggerFor]="myProfile"
            aria-label="Example icon-button with a menu"
            title="Profile"
          >
            <img class="header-profile-logo" src="{{ headerProfileImage }}" />
          </a>

          <a
            *ngIf="!headerProfileImage"
            mat-icon-button
            [matMenuTriggerFor]="myProfile"
            aria-label="Example icon-button with a menu"
            title="Profile"
          >
            <img class="header-profile-logo" src="assets/uploads/load.png" />
          </a>

          <mat-menu #myProfile="matMenu" class="profile-list-box">
            <button mat-menu-item class="profile-name-button">
              <img src="{{ headerProfileImage }}" />
              <span *ngIf="resultData.employee"
                >{{
                  resultData.employee.firstName
                    ? resultData.employee.firstName
                    : ""
                }}
                {{
                  resultData.employee.lastName
                    ? resultData.employee.lastName
                    : ""
                }}
              </span>
            </button>

            <button
              mat-menu-item
              routerLink="my-profile"
              routerLinkActive="active"
              class="profile-buttons"
            >
              <div class="pro-buttons-list">
                <mat-icon>account_circle</mat-icon>
                <span>My Profile</span>
              </div>
            </button>

            <button mat-menu-item (click)="logout()" class="profile-buttons">
              <div class="pro-buttons-list">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
              </div>
            </button>
          </mat-menu>
          <!-- Menu end here -->
        </span>
      </div>
      <span class="example-spacer"></span>
    </mat-toolbar>
    <!-- header end here -->
    <router-outlet></router-outlet>

    <!-- Footer start -->
    <!-- <div class="footer">
            <span>
              Copyright © 2022. All rights reserved. Developed and Maintained by <a href="https://www.jesperapps.com/" target="_blank" title="JESPERAPPS">JESPERAPPS</a>
            </span>
        </div> -->
    <!-- Footer end -->
  </mat-sidenav-content>
  <!-- mat side nav content end here -->
</mat-sidenav-container>
