<div class="container-fluid p-0">
    <div class="col-lg-12 d-flex common-section-login">
        <div class="logo-section-New text-center">
            <div class="login-section">
                <div class="row">
                    <div class="col-md-6 p-0 left-image">
                        <img class="super-admin-log" src="assets/uploads/sa-log.jpg">
                    </div>
                    <div class="col-md-6 p-0">
                        <form [formGroup]="superAdminLogin" (ngSubmit)="onSubmit()">
                            <div class="card1 card-new border-0 p-5">
                                <div class="">
                                    <img src="assets/uploads/logo1.png" class="login-logo my-2 img-responsive" alt="" matTooltip="Enote" />
                                </div>
                                <div class="row px-3 pt-4">
                                    <label class="mb-1"><h6 class="mb-0 text-sm login-label">Email  <span class="star">*</span></h6></label>
                                    <input formControlName="email" class="form-control form-control-lg form-control-solid" type="text" placeholder="Enter User Name / Email" [ngClass]="{ 'is-invalid': submitted }">
                                    <div class="valid-msg" *ngIf="(validate.email.invalid && validate.email.touched ) || validate.email.dirty || submitted">
                                        <span *ngIf="validate.email.errors?.required" class="text-danger">Please enter email</span>
                                        <span *ngIf="validate.email.errors?.pattern" class="text-danger">Please enter valid email</span>
                                    </div>
                                </div>
                                <div class="row px-3 mt-3 password-box">
                                    <label class="mb-1"><h6 class="mb-0 text-sm login-label">Password <span class="star">*</span></h6></label>
                                    <!-- <a routerLink="forgot-password" class="forgot-password pl-2 mb-0 text-sm">
                                      Forgot Password?
                                    </a> -->
                                    <input [type]="password" class="form-control form-control-lg form-control-solid" formControlName="password" id="password" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted  }">
                                    <!-- <input type="password" class="form-control form-control-lg form-control-solid" formControlName="password" id="password" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted  }"> -->
                                    <div class="valid-msg" *ngIf="(validate.password.invalid && validate.password.touched) || validate.password.dirty || submitted">
                                        <span *ngIf="validate.password.errors?.required" class="text-danger">Please enter password</span>
                                    </div>
                                    <span class="view-password" (click)="showPassword()">
                                        <i class="fa fa-eye" *ngIf="!show"></i>
                                        <i class="fa fa-eye-slash" *ngIf="show"></i>
                                    </span>
                                </div>

                                <div class="row px-3 mt-4">
                                    <button type="submit" class="btn btn-blue text-center w-100">Sign in</button>
                                </div>
                                <span class="err-msg">{{errorMsg}}</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
