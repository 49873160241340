<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content" style="    font-size: 23px;
                    font-family: 'Mulish', sans-serif;
                    font-weight: 700;">
                       View Position
                    </span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/positions" mat-raised-button  class="ml-2">
                        Back
                    </a>
                    <a routerLink="/home/edit-position/{{positionId}}" routerLinkActive="active" color="primary" mat-raised-button title="Add Position" class="ml-2" *ngIf="userType=='Admin'">
                        Edit Position
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-5"><label class="form-control-label">Position Name</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">{{positionobj?.positionName}}</div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-5"><label class="form-control-label">Experience</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">{{positionobj?.minExperience}} - {{positionobj?.maxExperience}} Years</div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-5"><label class="form-control-label">Location</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">{{positionobj?.location}}</div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-5"><label class="form-control-label">CTC</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">{{positionobj?.ctc}}</div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-5"><label class="form-control-label">Hiring For</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">{{positionobj?.hiring?.listTypeValueName}}</div>
                            </div>
                        </div>

                        <div class="col-md-6" >
                            <div class="row mt-2" *ngIf="positionobj?.client">
                                <div class="col-md-5"><label class="form-control-label">Client Name</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">
                                    {{positionobj?.client?.clientName}}
                                    {{positionobj?.clientName}}
                                </div>
                            </div>

                            <div class="row mt-2" *ngIf="positionobj?.jobType">
                                <div class="col-md-5"><label class="form-control-label">Job Type</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">{{positionobj?.jobType?.listTypeValueName}}</div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-5"><label class="form-control-label">Notice Period</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6">
                                    <span *ngFor="let e of positionobj?.noticePeriod">
                                        <label class="badge badge-primary mr-2"> {{e.listTypeValueName}}</label>
                                    </span>
                                </div>
                            </div>

                            <!-- <div class="row mt-2" >
                                <div class="col-md-5"><label class="form-control-label">Job Description</label></div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-6" *ngIf="positionobj?.jobDescriptionAttachment">
                                    <a class="file-download badge badge-secondary form-control-label" href="{{positionobj?.jobDescriptionAttachment.fileDownloadUrl}}">
                                        <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>Download</a>

                                </div>
                            </div> -->

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
    
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" 
    style="    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 32%;
    right: 48%;" 
    role="status"></div>
</div>
