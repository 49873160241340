
import { Component, Inject, OnInit,ViewChild,ElementRef  } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PmsService } from '../providers/pms.service';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-pms-dashboard-dialog-component',
  templateUrl: './pms-dashboard-dialog-component.component.html',
  styleUrls: ['./pms-dashboard-dialog-component.component.scss']
})
export class PmsDashboardDialogComponentComponent implements OnInit {

  empdata: any;
  resultData: any;
  listData: any;
  listData1: any;
  listData2: any;
  nodata: any;
  loader: boolean = false; 
  // loader:any;
  mainlistdata: any[] = [];
  pendingApiCalled: boolean = false;
  reviewedApiCalled: boolean = false;
  closedApiCalled: boolean =false;
  search: any;
  query: any = '';
  p:number =1;
  mySelect:any =5;
  length:any;
  apprisalStatus: any;
  item: any;
  listName:any;
  userdata: any;
  organizationId: any;
  currentYear:number;
  patchYearId:number;
  monthid:any;
  yearid:any;
  periodChange:any;
  orgName:any;
  orgAddress:any;
  
  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;


  constructor(
    public dialog: MatDialog,
    private pmsService: PmsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {

    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = this.userdata.organization.organizationId;
    this.orgName = this.userdata.organization.organizationName;
    this.orgAddress = this.userdata.organization.address;

    this.currentYear = new Date().getFullYear();
    console.log(this.currentYear);
    this.monthid = this.data.monthid;
    this.yearid = this.data.yearid;

    this.empdata = this.data.empdata;
    this.resultData = this.data.resultData;
    this.listData = this.data.listData;
    this.listData1 = this.data.listData1;
    this.listData2 = this.data.listData2;
    this.periodChange = this.data.periodchange;
    

    console.log("mylist", this.data);
    console.log(this.data.empdata);
    console.log("data",this.data.resultData);
    console.log("pending details", this.listData);
    console.log("Reviewed details", this.listData1);
    console.log("closed details", this.listData2);
    console.log("periodchange",this.periodChange);
    

    this.checkListData(this.listData, "Pending", () => {
      if (!this.pendingApiCalled) {
        this.pendingApiCalled = true;
        this.loader = true;
            if(this.periodChange == undefined){
            this.pmsService.PmsdashboardData2(this.monthid,this.yearid).subscribe((main: any) => {
              this.mainlistdata = main;
              this.listName = "Pending Appraisal Details";
              this.loader = false;
              console.log(this.mainlistdata);
            });
          }
            else if(this.periodChange != undefined){
              this.pmsService.PmsdashboardData2(this.periodChange,this.yearid).subscribe((main: any) => {
                this.mainlistdata = main;
                this.listName = "Pending Appraisal Details";
                this.loader = false;
                console.log(this.mainlistdata);
              });
            }
      }
    });

    this.checkListData(this.listData1, "Reviewed", () => {
      if (!this.reviewedApiCalled) {
        this.reviewedApiCalled = true;
        this.loader = true;
          if(this.periodChange == undefined){
          this.pmsService.PmsdashboardData3(this.monthid,this.yearid).subscribe((main: any) => {
            this.mainlistdata = main;
            this.listName = "Reviewed Appraisal Details";
            this.loader = false;
            console.log(this.mainlistdata);
          });
        }
        else if(this.periodChange != undefined){
          this.pmsService.PmsdashboardData3(this.periodChange,this.yearid).subscribe((main: any) => {
            this.mainlistdata = main;
            this.listName = "Reviewed Appraisal Details";
            this.loader = false;
            console.log(this.mainlistdata);
          });

        }
      }
    });

    this.checkListData(this.listData2, "Closed", () => {
      if (!this.closedApiCalled) {
        this.closedApiCalled = true;
        this.loader = true;
          if(this.periodChange == undefined){
          this.pmsService.PmsdashboardData4(this.monthid,this.yearid).subscribe((main: any) => {
            this.mainlistdata = main;
            this.listName = "Closed Appraisal Details";
            this.loader = false;
            console.log(this.mainlistdata);
          });
        }
        else if(this.periodChange != undefined){
          this.pmsService.PmsdashboardData4(this.periodChange,this.yearid).subscribe((main: any) => {
            this.mainlistdata = main;
            this.listName = "Closed Appraisal Details";
            this.loader = false;
            console.log(this.mainlistdata);
          }); 
        }
      }
    });
  }

  checkListData(listData: any, status: string, apiCall: () => void): void {
    if (Array.isArray(listData)) {
      for (let item of listData) {
        if (item.apprisalStatus && item.apprisalStatus.listTypeValueName === status) {
          console.log(item);
          apiCall();
          break; // Exit the loop once the API is called
        }
      }
    }
  }

  showChanges()
  {
    console.log("show Change");
    this.p=1;
  }

  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML);
    

    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    console.log(pdfTable.innerHTML);
    

    const documentDefinition = {
      content: html,
      pageMargins: [25, 40, 25, 40],
      info: {
        title:  this.listName
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);

        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download(`${this.listName}.pdf`);
  }
}


