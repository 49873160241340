



import { httpOptions} from './properties';
import { ConsolidatePayslipComponent } from '../reports/consolidate-payslip/consolidate-payslip.component';
import { Injectable, Output, EventEmitter } from "@angular/core";
import { globalTimeOut } from "./properties";
import { Observable, Subject } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError, timeout } from "rxjs/operators";
// import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { ErrorObserver } from 'rxjs';
// import { HttpErrorResponse } from '@angular/common/http/src/response';
import Swal from "sweetalert2";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GlobalserviceService {
  organzationid:number;
  getifreportByclient(client: any, fromperiod: any, toperiod: any) {
    throw new Error('Method not implemented.');
  }
 
  
 


  constructor(private http : HttpClient) {  const data = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('localStorage Data', data);
    if (data != undefined) {
      this.organzationid = data.organization.organizationId;
      console.log('organzationid:::' , this.organzationid);
    } }

  //<---------------- manage approver ------------->
  addApprover(data) {
    return this.http.post(`${environment.base_url}approver`,data,httpOptions)
    .pipe(map(res => res));
  }
  getApprovers(organzationid:any) {
    return this.http.get(`${environment.base_url}approvers/organziation/${organzationid}`,httpOptions)
    .pipe(map(res => res));
  }
  getApproversById(approverId) {
    return this.http.get(`${environment.base_url}approvers/${approverId}`,httpOptions)
      .pipe(map(res => res));
  }
  updateApprover(approverId,data) {
    return this.http.put(`${environment.base_url}approvers/${approverId}`, data,httpOptions)
      .pipe(map(res => res));
  }
  getApproverRuleName(rulename: any, approverId: number,organzationid:any) {
    return this.http.get(`${environment.base_url}approvers/approversrulename/${rulename}/${approverId}/${organzationid}`,httpOptions)
    .pipe(map(res => res));
  }
  //<---------------- manage employee Approvers ---------------->
  createEmployeeApprover(employeeapprover :any) {
    return this.http.post(`${environment.base_url}employeeapprovers/multiple`,employeeapprover,httpOptions)
    .pipe(map(res => res));
  }
  updateEmployeeApprover(employeeapproverupdate: any, employeeApproverId: any) {
    return this.http.put(`${environment.base_url}employeeapprovers/${employeeApproverId}`,employeeapproverupdate,httpOptions)
    .pipe(map(res => res));
  }
  // getEmployeeApprovers() {
  //   return this.http.get(`${environment.base_url}employeeapprovers`,httpOptions)
  //   .pipe(map(res => res));
  // }
  getEmployeeApprovers(organizationId: any) {
    return this.http.get(`${environment.base_url}employeeapprovers/org/${organizationId}`,httpOptions)
    .pipe(map(res => res));
  }
  // http://localhost:6040/api/enote/hrms/employeeapprovers/org/2

  getEmployeeApproversvalidate(employeeId: any) {
    return this.http.get(`${environment.base_url}employeeapprovers/validate/${employeeId}`,httpOptions)
    .pipe(map(res => res));
  }
  // activeuserslist(organizationId:any):Observable<any>{
  //   return this.http.get(`${environment.base_url}employee/active_employee/organization/${organizationId}`)
  //   .pipe(map(data=>data));
  // }
  getAllEmployeeByOrganization(organizationId: any) {
    return this.http.get(`${environment.base_url}v22/employee/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }  
  getEmployeeApproversById(employeeapproversId: any) {
    return this.http.get(`${environment.base_url}employeeapprovers/${employeeapproversId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getApproverType(){
    return this.http.get(`${environment.base_url}approvertype`,httpOptions)
    .pipe(map(data=>data));
  }
  //<---------------- manage approval -------------------->
  // manageapprovallist(approvalempId){
  //   return this.http.get(`${environment.base_url}expense-reimbursement/approver/${approvalempId}`,httpOptions)
  //   .pipe(map(data=>data));
  // }
  manageapprovallist(approvalempId){
    return this.http.get(`${environment.base_url}salaryadvance/employeeapprover/${approvalempId}/3`,httpOptions)
    .pipe(map(data=>data));
  }
  manageapprovallistbystatus(approvalempId){
    return this.http.get(`${environment.base_url}expense-reimbursement/approver/pending/${approvalempId}`,httpOptions)
    .pipe(map(data=>data));
  }
  //<---------------- Expense Reimbursement -------------------->
  expenselist(){
    return this.http.get(`${environment.base_url}expense-reimbursement`,httpOptions)
    .pipe(map(data=>data));
  }
  getexpensereburimentbyemployee(employeeId: any) {
    return this.http.get(`${environment.base_url}expense-reimbursement/employee/${employeeId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getexpensereibursementbyemployeeapprover(employeeId: any){
    return this.http.get(`${environment.base_url}expense-reimbursement/employeeapprover/${employeeId}/3`,httpOptions)
  }
  getSalaryAdvanceApprovalsbyemployeeapprover(employeeId: any){
    return this.http.get(`${environment.base_url}salaryadvance/employeeapprover/${employeeId}/3`,httpOptions)
  }
  getSalaryAdvancebyemployeeapprover(employeeapproversId: any) {
    return this.http.get(`${environment.base_url}employeeapprovers/${employeeapproversId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getexpensereburimentbyId(expensereimbursementId: any) {
    return this.http.get(`${environment.base_url}expense-reimbursement/${expensereimbursementId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getListtypeValuebyId(paymentId: any) {
    return this.http.get(`${environment.base_url}list-type-values/${paymentId}`,httpOptions)
    .pipe(map(data=>data));
  }
  SalaryAdvanceStatusApproval(SalaryAdvanceId: any,data:any) {
    return this.http.put(`${environment.base_url}salaryadvance/statusupdate/${SalaryAdvanceId}`,data,httpOptions)
    .pipe(map(data=>data));
  }

  //<---------------- salary Advance  -------------------->
  getlistByFinancialyear(financialYear:any,employee:any){
    return this.http.get(`${environment.base_url}salaryadvance/financial-year/${financialYear}/employee/${employee}`,httpOptions)
    .pipe(map(data=>data));
  }  
  getlistByFinancialyearandperiod(period:any,financialYear:any,employee:any){
    return this.http.get(`${environment.base_url}salaryadvance/financial-period/${period}/financial-Year/${financialYear}/employee/${employee}`,httpOptions)
    .pipe(map(data=>data));
  }
  createSalaryAdvance(createdata:any){
    return this.http.post(`${environment.base_url}salaryadvance`,createdata,httpOptions)
    .pipe(map(data=>data));
  }
  updateSalaryAdvance(salaryadvanceId:any,updatedata:any){
    return this.http.put(`${environment.base_url}salaryadvance/${salaryadvanceId}`,updatedata,httpOptions)
    .pipe(map(data=>data));
  }
  getListBySalaryadvanceId(salaryadvanceId:any){
    return this.http.get(`${environment.base_url}salaryadvance/${salaryadvanceId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getAdvancedetails(financialPeriod:any,employee:any){
    return this.http.get(`${environment.base_url}salaryadvance/financialPeriod/${financialPeriod}/employee/${employee}/organization/1`,httpOptions)
    .pipe(map(data=>data));
  }
  getAdvancedetailsForUpdate(financialPeriod:any,employee:any,salaryadvanceId:any,organizationId:any){
    return this.http.get(`${environment.base_url}salaryadvance/financialPeriod/${financialPeriod}/employee/${employee}/organization/${organizationId}/salaryAdvance/${salaryadvanceId}`,httpOptions)
    .pipe(map(data=>data));
  }
 //<---------------- Employee Loan -------------------->
  createLoan(createdata:any){
    return this.http.post(`${environment.base_url}employee-loan/`,createdata,httpOptions)
    .pipe(map(data=>data));
  }
  updateLoan(loanId:any,updatedata:any){
    return this.http.put(`${environment.base_url}employee-loan/${loanId}`,updatedata,httpOptions)
    .pipe(map(data=>data));
  }
  getLoanlistByemployee(employee:any){
    return this.http.get(`${environment.base_url}employee-loan/employee/${employee}`,httpOptions)
    .pipe(map(data=>data));
  }  
  getLoanlistByLoanId(loanId:any){
    return this.http.get(`${environment.base_url}employee-loan/${loanId}`,httpOptions)
    .pipe(map(data=>data));
  }    
  getLoanEligibilityDetails(employee:any,organizationId:any){
    return this.http.get(`${environment.base_url}employee-loan/employee/${employee}/organization/${organizationId} `,httpOptions)
    .pipe(map(data=>data));
  }  
  //<---------manage Loan Approvals ---------->
  getManageLoanlistByStatus(employeeapprover:any){
    return this.http.get(`${environment.base_url}employee-loan/employeeapprover/${employeeapprover}/3`,httpOptions)
    .pipe(map(data=>data));
  }  
  updateLoanStatus(loanId:any,data:any){
    return this.http.put(`${environment.base_url}employee-loan/statusupdate/${loanId}`,data,httpOptions)
    .pipe(map(data=>data));
  }
  //<---------------Employee -------------->
  getemployee(employee:any){
    return this.http.get(`${environment.base_url}employee/${employee}`,httpOptions)
    .pipe(map(data=>data));
  } 
  //<------------- organaization setting API ----------->
  getOrganizationdetails(organizationId:any){
    return this.http.get(`${environment.base_url}organizationsetting/organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getClientdetails(clientId:any){
    return this.http.get(`${environment.base_url}organizationsetting/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }
  //<--------------- Manage payslip Filters ------------->
  getManagePayslipByEmployeeId(employeeId:any){
    return this.http.get(`${environment.base_url}payslip/employee-number/${employeeId}`,httpOptions)
    .pipe(map(data=>data));
  }   

  getManagePayslipByOrgEmployeeId(employeeId:any, orgId:any){
    return this.http.get(`${environment.base_url}payslip/employee-number/${employeeId}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  } 

  getManagePayslipByClientEmployeeId(employeeId:any, clientId:any){
    return this.http.get(`${environment.base_url}payslip/employee-number/${employeeId}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }






  getManagePayslipByDesignation(designation:any){
    return this.http.get(`${environment.base_url}payslip/designation/${designation}`,httpOptions)
    .pipe(map(data=>data));
  } 
  getManagePayslipByFinancialperiod(Fyear:any,Fperiod:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/financial-period/${Fperiod}`,httpOptions)
    .pipe(map(data=>data));
  }
  getManagePayslipByEmployeeIdAndDesignation(employeeId:any,designation:any){
    return this.http.get(`${environment.base_url}payslip/employee-number/${employeeId}/designation/${designation}`,httpOptions)
    .pipe(map(data=>data));
  } 
  getManagePayslipByEmployeeIdAndFinancialperiod(Fyear:any,Fperiod:any,employeeId:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/financial-period/${Fperiod}/employee-number/${employeeId}`,httpOptions)
    .pipe(map(data=>data));
  }


  getManagePayslipByEmployeeIdAndFinancialperiodOrg(Fyear:any,Fperiod:any,employeeId:any,orgId:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/financial-period/${Fperiod}/employee-number/${employeeId}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }


  getManagePayslipByEmployeeIdAndFinancialperiodClient(Fyear:any,Fperiod:any,employeeId:any,clientId:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/financial-period/${Fperiod}/employee-number/${employeeId}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }













  getManagePayslipByEmployeeIdAndFinancialperiodAndDesignation(Fyear:any,Fperiod:any,employeeId:any,designation:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/financial-period/${Fperiod}/employee-number/${employeeId}/designation/${designation}`,httpOptions)
    .pipe(map(data=>data));
  }
  getManagePayslipByFinancialperiodAndDesignation(Fyear:any,Fperiod:any,designation:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/financial-period/${Fperiod}/designation/${designation}`,httpOptions)
    .pipe(map(data=>data));
  }
  getManagePayslipByFinancialyear(Fyear:any,){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}`,httpOptions)
    .pipe(map(data=>data));
  }
  getManagePayslipByEmployeeIdAndFinancialyear(Fyear:any,employeeId:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/employee-number/${employeeId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getManagePayslipByEmployeeIdAndFinancialyearOrg(Fyear:any,employeeId:any,orgId:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/employee-number/${employeeId}/organization/${orgId}`,httpOptions)
    .pipe(map(data=>data));
  }


  getManagePayslipByEmployeeIdAndFinancialyearClient(Fyear:any,employeeId:any,clientId:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/employee-number/${employeeId}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }




  getManagePayslipByEmployeeIdAndFinancialyearAndDesignation(Fyear:any,designation:any,employeeId:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/employee-number/${employeeId}/designation/${designation}`,httpOptions)
    .pipe(map(data=>data));
  }
  getManagePayslipByFinancialyearAndDesignation(Fyear:any,designation:any){
    return this.http.get(`${environment.base_url}payslip/financial-year/${Fyear}/designation/${designation}`,httpOptions)
    .pipe(map(data=>data));
  }

  // <-------------View manage Payslip ----------------->
  getManagePayslipById(Id:any){
    return this.http.get(`${environment.base_url}payslip/${Id}`,httpOptions)
    .pipe(map(data=>data));
  }
  //<--------------- Consolidate Payslip Report ----------->
  getConsoldatePayslipByemployeeId(employeeId:any){
    return this.http.get(`${environment.base_url}consilidate/payslipreports/employee/${employeeId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getConsoldatePayslipByOrgemployeeId(employeeId:any,organizationId){
    return this.http.get(`${environment.base_url}consilidate/payslipreports/employee/${employeeId}organization/${organizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getConsoldatePayslipByClientemployeeId(employeeId:any,clientId){
    return this.http.get(`${environment.base_url}consilidate/payslipreports/employee/${employeeId}/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }
  getpaySlipElementByOrganaization(organaizationId:any){
    return this.http.get(`${environment.base_url}hrmspaySlipElement/active/active_element/${organaizationId}`,httpOptions)
    .pipe(map(data=>data));
  }

  getpaySlipElementByClient(clientId:any){
    return this.http.get(`${environment.base_url}hrmspaySlipElement/active/active_element/client/${clientId}`,httpOptions)
    .pipe(map(data=>data));
  }


  getConsoldatePayslipByemployeeIdandfinicalyear(employeecode: any, financialyear: any) {
    return this.http.get(`${environment.base_url}consilidate/payslipreports/employee/financialYear/${employeecode}/${financialyear}`,httpOptions)
    .pipe(map(data=>data));
  }

  getConsoldatePayslipByemployeeIdandfinicalyearandpriod(employeecode: any, financialyear: any, fromperiod: any, toperiod: any) {
    return this.http.get(`${environment.base_url}consilidate/payslipreports/employee/year/period/${employeecode}/${financialyear}/${fromperiod}/${toperiod}`,httpOptions)
    .pipe(map(data=>data));
  }

  getConsoldatePayslipByfinicalyear(financialyear: any) {
    return this.http.get(`${environment.base_url}consilidate/payslipreports/financialYear/${financialyear}`,httpOptions)
    .pipe(map(data=>data));
  }

  getConsoldatePayslipByfinicalyearandperiod(financialyear: any, fromperiod: any, toperiod: any) {
    return this.http.get(`${environment.base_url}consilidate/payslipreports/year/period/${financialyear}/${fromperiod}/${toperiod}`,httpOptions)
    .pipe(map(data=>data));
  }

  getConsoldatePayslipByclient(cleint: any, financialyear: any, fromperiod: any, toperiod: any) {
    return this.http.get(`${environment.base_url}consilidate/payslipreports/client/year/period/${cleint}/${financialyear}/${fromperiod}/${toperiod}`,httpOptions)
    .pipe(map(data=>data));
  }


  usersbyid(employeeId:any):Observable<any>{
    return this.http.get(`${environment.base_url}employee/${employeeId}`,httpOptions)
    .pipe(map(data=>data));
  }
  dateformateByOrg(ordId){
    return this.http.get(`${environment.base_url}dateFormat/org/${ordId}`,httpOptions)
    .pipe(map(data=>data));
  }
  createEmployeAssign(payload:any):Observable<any>{
    return this.http.post(`${environment.base_url}addMultipleEmployeeClients`,payload,httpOptions)
    .pipe(map(data=>data));
  }

}
