import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PayrollService } from 'src/app/providers/payroll.service';
import { responseMsgTimeOut}from '../../app/providers/properties'
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { ImportClientComponent} from '../import-client/import-client.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AsyncPipe} from '@angular/common';
import {  MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { EmpassignmentdetailComponent} from '../empassignmentdetail/empassignmentdetail.component'
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { AppComponent } from 'src/app/app.component';
import {ExcelService } from '../services/excel.service'
import { RecruitmentService } from '../providers/recruitment.service';
import { MatSelectChange } from '@angular/material/select';
import { EmployeeService } from '../providers/employee.service';

@Component({
  selector: 'app-select-org-emp-transfer',
  templateUrl: './select-org-emp-transfer.component.html',
  styleUrls: ['./select-org-emp-transfer.component.scss']
})
export class SelectOrgEmpTransferComponent implements OnInit {

  organizationForm:FormGroup;
  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  eventStore:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  organizationId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  storeOrgChanges:any
  clientdata: any = {};
  // pagePerIist:any=10;
  update: boolean = false;
  orgList: any;
  entityId:any;
  mySelect:number=5;
  constructor(private fb:FormBuilder,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private employee_service: EmployeeService,

    @Inject(AppComponent) public AppData: any ,  private recruitmentservice :RecruitmentService) { }
    // private dialogref : MatDialogRef<ClientComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any    

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;
    // this.dateFormat = this.AppData.dateFormat;
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.entityId=this.usertData.entity.entityId
    console.log(this.orgId);
    
    this.filterForm = this.fb.group({
      filterorg:[null,[Validators.required]],
      filterstartdate:[null],
      filterenddate:[null],
    })
    
    this.organizationForm = this.fb.group({
      organization:[null,[Validators.required]], 
      employee:[null,[Validators.required]],
      startdate:[null,[Validators.required]],
      toOrganization:[null,[Validators.required]],
      // ratePerDay :[null ,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
      // ratePerMonth : [null ,equired,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
    })
    
    
    // this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
    //   this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
    //   console.log(this.clientList);
    // })  
    this.payrollservice.getOrganizationNameListBasedEntity( this.entityId).subscribe((result: any) => {
      // this.organizationList =  result.filter(organization =>organization?.status?.listTypeValueId == 1);;
      // console.log(this.organizationList);
      this.orgList = result;
    });  
  }
  changefirstpage(){
this.p=1;
  }
empSearch(event)
{ 
  console.log(event.target.value);
  this.userId=event.target.value;
  console.log(this.userId);
  // Employee List Api
    this.empList.length == 0;
    if(this.orgId!=undefined){
      console.log("only its calling organization!!");
      
      this.payrollservice.getEmployeeNameList(this.userId,this.orgId).subscribe((result: any) => {
        if(result.statusCode)
               {
                this.empList.length = 0;
               }else{
               //  console.log("else")
                this.empList = result;
               }
      })
    }
    else{
      
      if(this.entityId!==undefined){
        console.log("only calling the entity login!!");

        this.employee_service.getEmployeeListKeywordsEntity(this.entityId, this.userId).subscribe((result: any) => {
          if(result.statusCode)
            {
             this.empList.length = 0;
            }else{
            //  console.log("else")
             this.empList = result;
            }
        })
      }
    }
}
selectionChangeIndustryType(data: MatSelectChange) {
  console.log('value', data?.value);
  this.storeOrgChanges = data?.value;
}
getratedetails(ispatch)
{
  this.recruitmentservice.clientbyid(this.organizationForm.value.client).subscribe((result: any) => {
         if(result.statusCode)
           {
          
           }else{
           //  console.log("else")
            this.clientdata = result;
              if(this.clientdata.ratePerDay && this.clientdata.ratePerMonth && !ispatch)
              {
                  this.organizationForm.patchValue({
                    ratePerDay : this.clientdata.ratePerDay,
                    ratePerMonth : this.clientdata.ratePerMonth
                  })
              }
           }
  })
}
empSelect(emp)
{
  console.log(emp);
  this.employeeId=emp.employeeId;
  this.employee=emp.employeeNumber+"-"+emp.firstName;
  console.log(this.employee);
  this.organizationForm.patchValue({
      employee: this.employee
   });
}

importEmployee() //Add Dialog Box
{
  const dialogRef = this.dialog.open(ImportClientComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',
  });

  dialogRef.afterClosed().subscribe(result =>
  {
    //this.departmentList();

    if(result.statusCode == 200)
    {
      this.success = true;
      this.sucess_msg = result.message;
      setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
    }
    else 
    {
       if(result.length != 0)
       {

        this.error = true;
        //this.error_msg = result.message;
        this.importerror = true;
        this.errormessagejson = result;
        // this.showerrormessage();
        // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
       }
        else
        {
        if(result.statusCode > 400)
        {
          this.error = true;
        
          this.importerror = false;
        }
        }

    }
  });
}

showerrormessage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

  searchSubmit()
  {
    this.p=1;
    console.log("Filter Search");
    this.noRecordsFound = false;
    this.resultData = [];
    console.log(this.filterForm.value.filterorg);
    this.organizationId=this.filterForm.value.filterorg;
    console.log(this.organizationId);
    console.log(this.filterForm.value.filterstartdate);
    console.log(this.filterForm.value.filterenddate);
     this.filterstardate= this.datepipe.transform(this.filterForm.value.filterstartdate, 'yyyy-MM-dd');
    console.log("stardate",this.filterstardate);
    this.filterenddate= this.datepipe.transform(this.filterForm.value.filterenddate, 'yyyy-MM-dd');
    console.log("stardate",this.filterenddate);
    if(this.filterForm.invalid)
    {
      console.log("Required Field");
      alert('Please select Organization To Search!');
      return;
    }
    else if (this.filterenddate == null && this.filterstardate != null) {
      alert('End Date is Required!!');
      // this.isbtndisabled = false;
      //       this.projectStatus=undefined
      // this.startDate=undefined
      // this.endDate=undefined
      // this.actionStatus=undefined

      return;
    }
    else if (this.filterstardate == null && this.filterenddate != null) {
      alert('Start Date is Required!!');
     

      return;
    } 
    else{
      console.log("all conditions came out!!");
      
    }
     if(this.filterForm.value.filterclient!=null&&this.filterstardate!=null&&this.filterenddate!=null)
    {
      console.log("both all Filter");
      this.payrollservice.getOrgandDateBasedFilterList(this.organizationId,this.filterstardate).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else 
    {
      this.payrollservice.getOrgFilterList(this.organizationId).subscribe((resultData:any) =>
      {
        console.log("Client Filter");
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
        console.log(this.resultData);

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
  }

  calculatemontly()
  {
    console.log("this.clientdata",this.clientdata)
      if(this.clientdata)
      {
        let montlyamount = this.organizationForm.value.ratePerDay * this.clientdata.workingDays;
        console.log("montlyamount",montlyamount);
        this.organizationForm.patchValue({
          ratePerMonth : montlyamount.toFixed(2)
        })
        console.log(" this.clientForm", this.organizationForm);
      }
   
  }

  calculatdaily(){
    if( this.clientdata)
      {
        let dailyamount = this.organizationForm.value.ratePerMonth / this.clientdata.workingDays;
        this.organizationForm.patchValue({
          ratePerDay: dailyamount.toFixed(2)
        })
      }
   
  }

  clearTable()
    {
      this.resultData = [];
      this.noRecordsFound = false;
      console.log('clear')
      this.filterForm.clearValidators;
    }

    pageChages()
    {
      console.log("page changes");
      this.p=1;
    }
  // onSubmit()
  // {
  //   this.submitted=true;
  //   if (this.clientForm.invalid) {
  //     console.log("invalid")
  //     return;
  //   }
  //   if(this.clientupdateId) //Update Cilent
  //   {
  //     console.log(this.clientupdateId);
  //     let clientValues = this.clientForm.value;
  //     console.log(clientValues);
  //       var updateAssignCreate =
  //       {
  //         clientAssignId:this.clientupdateId,
  //         client : 
  //         {
  //           clientId:clientValues.client
  //         },
  //         status: 
  //         {
  //           listTypeValueId: this.employeeStatus,
  //         },
  //         employee : 
  //         {
  //           employeeId:this.employeeId
  //         },
  //         startDate : this.datepipe.transform(clientValues.startdate ,'yyyy-MM-dd'),
  //         endDate : this.datepipe.transform(clientValues.enddate ,'yyyy-MM-dd'),
  //         ratePerDay : this.clientForm.value.ratePerDay,
  //         ratePerMonth : this.clientForm.value.ratePerMonth
  //       };
  //        console.log(updateAssignCreate);

                    
  //       this. payrollservice.clientAssignmentUpdate(this.clientupdateId , updateAssignCreate).subscribe((result: any) =>
  //       {
  //         if(result.statusCode == 200)
  //         {
  //           this.success = true;
  //           this.sucess_msg = result.description;
  //           this.clientForm.reset();
  //           this.submitted = false;
  //           this.searchSubmit();
  //           setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
  //                this.clientupdateId = null;
  //           setTimeout(() => {
             
  //           }, redirectMsgTimeOut)
  //         }
  //         else
  //         {
  //           this.error = true;
  //           this.error_msg = result.description;
  //           setTimeout(() => {this.error = false}, redirectMsgTimeOut)
  //         }
  //       },
  //        err =>
  //           {
  //               this.errorMsg = err.error.message;
  //           })
  //   }
  //   else  //cretae client Assignment
  //   {
  //     this.stardate= this.datepipe.transform(this.clientForm.value.startdate, 'yyyy-MM-dd');
  //     console.log("stardate",this.stardate);
  //     this.enddate= this.datepipe.transform(this.clientForm.value.enddate, 'yyyy-MM-dd');
  //     console.log("enddate",this.enddate);
  //     var clientAssignCreate =
  //         {
  //           client : 
  //           {
  //             clientId:this.clientForm.value.client
  //           },
  //           employee : 
  //           {
  //             employeeId:this.employeeId
  //           },
  //           startDate : this.stardate,
  //           endDate : this.enddate,
  //           ratePerDay : this.clientForm.value.ratePerDay,
  //           ratePerMonth : this.clientForm.value.ratePerMonth
  //         };
  //         this.payrollservice.clientAssignmrntcreate(clientAssignCreate).subscribe((result: any) =>
  //      {
  //       if(result.statusCode == 200)
  //       {
  //         this.success = true;
  //         this.sucess_msg = result.description;      
  //         setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
  //         this.clientForm.reset();     
  //         this.submitted = false;
  //         // this.searchSubmit();
  //         setTimeout(() => {
  //         }, redirectMsgTimeOut)
  //       }
  //       else
  //       {
  //         this.error = true;
  //         this.error_msg = result.description;
  //         setTimeout(() => {this.error = false}, redirectMsgTimeOut)

  //         setTimeout(() => {
  //         }, redirectMsgTimeOut)
  //       }

  //     }, err =>
  //     {
  //         setTimeout(() => {
  //           this.error = true;
  //           this.errorMsg = err.error.message;
  //         }, redirectMsgTimeOut)
  //     })

  //   }
  //   }

  valiatetoclient(event)
{
    // console.log("this.clientForm.value.client"+ this.organizationForm.value.organization);
    // console.log("event.target.value"+event.target.value);
    console.log("chose:",event.value);
    
    this.eventStore=event.value;
    const selectedFromOrg = this.organizationForm.value.organization;
  
    if( selectedFromOrg===this.eventStore)
    {
      this.organizationForm.patchValue({
        toOrganization: null
     });
     alert("From and to Organization cannot be same!");
    }
}
  onSubmit()
  {
    console.log("form values:",this.organizationForm.value);
    
    this.submitted=true;
    if (this.organizationForm.invalid) {
      console.log("invalid")
      return;
    }
    if(this.clientupdateId) //Update Cilent
    {
      console.log(this.clientupdateId);
      let clientValues = this.organizationForm.value;
      console.log(clientValues);
        var updateAssignCreate =
        {
          clientAssignId:this.clientupdateId,
          organization : 
          {
            clientId:clientValues.organization
          },
          status: 
          {
            listTypeValueId: this.employeeStatus,
          },
          employee : 
          {
            employeeId:this.employeeId
          },
          startDate : this.datepipe.transform(clientValues.startdate ,'yyyy-MM-dd'),
        
        };
           if(clientValues.enddate)
           {
            updateAssignCreate['endDate'] = this.datepipe.transform(clientValues.enddate ,'yyyy-MM-dd')
           }
             console.log(updateAssignCreate);

                    
        this. payrollservice.OrgAssignmentUpdate(this.clientupdateId , updateAssignCreate).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;
            this.organizationForm.reset();
            this.submitted = false;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                 this.clientupdateId = null;
                 this.update = false;
        
          }
          else
          {
            this.error = true;
            this.error_msg = result.description;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        },
         err =>
            {
                this.errorMsg = err.error.message;
            })
    }
    else  //cretae client Assignment
    {
      this.stardate= this.datepipe.transform(this.organizationForm.value.startdate, 'yyyy-MM-dd');
      console.log("stardate",this.stardate);
      this.enddate= this.datepipe.transform(this.organizationForm.value.enddate, 'yyyy-MM-dd');
      console.log("enddate",this.enddate);
      var clientAssignCreate =
          {
            organization : 
            {
              organization:this.organizationForm.value.organization
            },
            toOrganization : 
            {
              toOrganization:this.organizationForm.value.toOrganization
            },
            employee : 
            {
              employeeId:this.employeeId
            },
            startDate : this.stardate,
            // endDate : this.enddate,
            // ratePerDay : this.clientForm.value.ratePerDay,
            // ratePerMonth : this.clientForm.value.ratePerMonth
          };
           console.log("payload",clientAssignCreate);
       //   return;
          this.payrollservice.OrgAssignmrntcreate(clientAssignCreate).subscribe((result: any) =>
       {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          this.organizationForm.reset();     
          this.submitted = false;
          // this.searchSubmit();
          setTimeout(() => {
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
          }, redirectMsgTimeOut)
        }

      }, err =>
      {
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.message;
          }, redirectMsgTimeOut)
      })

    }
    }

  onDateChange(event: any): void {
    let selectedDate = event.value;
    const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
     selectedDate = firstDayOfMonth;
    this.filterForm.setValue ({
      filterstartdate : selectedDate
    })
  }

  onDateChanges(event: any): void {
    let selectedDate = event.value;
    const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
     selectedDate = firstDayOfMonth;
    this.organizationForm.setValue ({
      startdate : selectedDate
    })
  }
  get validate() { return this.organizationForm.controls; }
  
  updateform(editData)
  {
    this.clientupdateId = editData.clientAssignId;
    this.payrollservice.getClientId(this.clientupdateId).subscribe((resultData:any) =>
    {
      this.resultData=resultData;
      console.log(this,resultData);
      this.employeeId=this.resultData.employee.employeeId;
      console.log(this.employeeId);
      this.employeeStatus=this.resultData.status.listTypeValueId;
      console.log(this.employeeStatus);
            this.organizationForm.patchValue({
              organization : this.resultData.client.clientId,
              employee : this.resultData.employee.employeeNumber+" "+this.resultData.employee.firstName+" "+this.resultData.employee.lastName,
              startdate : this.resultData.startDate,
              enddate : this.resultData.endDate,
              ratePerDay : this.resultData.ratePerDay,
              ratePerMonth : this.resultData.ratePerMonth
             });
            this.getratedetails(true);
          })
  }

  viewdata(data)
  {
    console.log(data.clientAssignId);
    var dialogRef = this.dialog.open(EmpassignmentdetailComponent, {
      width: '470px',height:'fit-content',
      data:data.clientAssignId
    });
  }
  updateStatus(id,status)
	{
    this.payrollservice.updateClientStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  exportToExcel()
  { 
      console.log("DATA : ",this.resultData);
      console.log(this.filterForm.value.filterclient);
      console.log(this.filterForm.value.filterstartdate);
      console.log(this.filterForm.value.filterenddate);
     if(this.filterForm.value.filterclient!=null&&this.filterForm.value.filterstartdate!=null&&this.filterForm.value.filterenddate!=null)
      {
        console.log("Both");
        this.dataExport.length = 0;
        this.exportingdata = this.resultData ;
        for(let exportingdata of this.exportingdata)
        {
          this.exported ={
            ClientName  : exportingdata.client.clientName,
            // ClientCode      : exportingdata.client.clientCode,
            EmployeeNo  : exportingdata.employee.employeeNumber,
            EmployeeName  : exportingdata.employee.firstName+" "+exportingdata.employee.lastName,
            StartDate : exportingdata.startDate,
            EndDate : exportingdata.endDate,
            Status: exportingdata.status.listTypeValueName
          }
          this.dataExport.push(this.exported);
        }
        this.excelService.exportAsExcelFile(this.dataExport,"ClientEmployees");
      }
      else if(this.filterForm.value.filterclient!=null&&this.filterForm.value.filterstartdate==null&&this.filterForm.value.filterenddate==null)
      {
        console.log("Client");
        this.dataExport.length = 0;
        this.exportingdata = this.resultData ;
        for(let exportingdata of this.exportingdata)
        {
          this.exported ={
            ClientName  : exportingdata.client.clientName,
            //ClientCode      : exportingdata.client.clientCode,
            EmployeeNo  : exportingdata.employee.employeeNumber,
            EmployeeName  : exportingdata.employee.firstName+" "+exportingdata.employee.lastName,
            StartDate : exportingdata.startDate,
            EndDate : exportingdata.endDate,
            Status: exportingdata.status.listTypeValueName
          }
          this.dataExport.push(this.exported);
        }
        console.log("EX",this.exported);
        this.excelService.exportAsExcelFile(this.dataExport,"ClientEmployees");
    }
  }

}
