Sub header start here
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content"> Manage Employee Annexure</span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
          <a routerLink="/home/" mat-raised-button title="Back" class=""
            >Back</a
          >
          <a
            routerLink="/home/employee-annexue"
            title="Add Employee Annexure"
            mat-raised-button
            class="ml-2 searchBtn"
          >
            Add Employee Annexure
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-12">
            <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
              <div class="row mb-3 pl-0">
                <div class="ol-xl-3 col-lg-3 mb-2">
                  <!-- [(ngModel)]="tableSearch"  -->
                  <label class="form-control-label"
                    >Employee <span class="text-danger"> *</span></label
                  >
                  <input
                    formControlName="employee"
                    type="search"
                    autocomplete="off"
                    class="form control select-placeholder email-input"
                    style="font-family: Mulish; outline: none; border-radius: 5px;"
                    value=""
                    placeholder="Search..."
                  />
                  <small class="text-muted">Note : Emp No.</small>
                </div>
                <div class="col-md-3">
                  <label class="form-control-label">Start Date</label>
                  <input
                    formControlName="startDateSearch"
                    id="startDateSearch"
                    matInput
                    [matDatepicker]="startDateSearch"
                    class="form control select-placeholder email-input"
                    readonly
                    (dateChange)="startDate($event)"
                    placeholder="Select Start Date"
                    style="
                      outline: none;
                      border-radius: 5px;
                      font-family: Mulish;
                    "
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="startDateSearch"
                    style="float: right; position: relative; bottom: 42px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #startDateSearch
                    panelClass="example-month-picker"
                  ></mat-datepicker>

                  <div
                    class="valid-msg"
                    *ngIf="
                      (serachvalidate.startDateSearch.invalid &&
                        serachvalidate.startDateSearch.touched) ||
                      serachvalidate.startDateSearch.dirty
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="serachvalidate.startDateSearch.errors?.required"
                      >Please Start Date</span
                    >
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="form-control-label">End Date</label>
                  <input
                    formControlName="endDateSearch"
                    id="endDateSearch"
                    matInput
                    [matDatepicker]="endDateSearch"
                    class="form control select-placeholder email-input"
                    (dateChange)="EndDate($event)"
                    readonly
                    [min]="min"
                    [min]="searchForm.value.startDateSearch"
                    placeholder="Select End Date"
                    style="
                      outline: none;
                      border-radius: 5px;
                      font-family: Mulish;
                    "
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endDateSearch"
                    style="float: right; position: relative; bottom: 42px"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endDateSearch></mat-datepicker>

                  <div
                    class="valid-msg"
                    *ngIf="
                      (serachvalidate.endDateSearch.invalid &&
                        serachvalidate.endDateSearch.touched) ||
                      serachvalidate.endDateSearch.dirty
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="serachvalidate.endDateSearch.errors?.required"
                      >Please select End Date</span
                    >
                    <mat-error
                      *ngIf="show_error_msg"
                      style="font-family: Mulish"
                    >
                      Start date should not be  greater than End date.
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-3" style="float: right; top: 37px">
                  <!-- <button type="submit" mat-raised-button color="primary"
                                                class="btn btn-primary search-btn">Search</button> -->

                  <button
                    type="submit"
                    mat-raised-button
                    class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                    [disabled]="isProcessing"
                  >
                    Search
                  </button>
                  <!-- <button style="margin-left: 20px;" type="reset" mat-raised-button
                                               class=" btn search-btn mr-2"
                                                (click)="clearTable()">Clear</button> -->

                  <button
                    mat-stroked-button
                    type="reset"
                    class="apply-buttons"
                    (click)="clearTable()"
                    [disabled]="isProcessing"
                    style="
                      margin: 8px 0px 0px 5px;
                      background: white;
                      border: 1px solid #ddd;
                      position: relative;
                      bottom: 22%;
                      font-family: Mulish;
                      color: black;
                      font-size: 16px;
                      font-weight: 610;
                    "
                  >
                    Clear
                  </button>
                  <!-- (click)="clearTable()" -->
                </div>
              </div>
            </form>
            <!--  <div class="col-md-3 pl-0">
                                <button class="search-btn" mat-raised-button color="primary">Search</button>
                            </div> -->
          </div>

          <!-- <div class="col-md-12">
                        <div class="row" *ngIf="!loader && showtable">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left pl-0">
                                <select [(ngModel)]="pagePerItem"  (change)="pageChages()" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                        </div>
                    </div> -->

          <div class="mt-3">
            <div class="text-right" *ngIf="!loader && showtable && resultData.length > 5">
              <div class="select-pagination m-2">
                <span>Show : </span>
                <select
                  class="select_list new-select_list listDesign"
                  [(ngModel)]="pagePerItem"
                  (change)="pageChages()"
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>

          <div *ngIf="showtable && !loader" class="table-responsive">
            <!-- <p><b>SHIFT CALENDAR</b></p> -->
            <table
              class="table --table-bordered table-border-new"
              style="
                width: 110%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              "
            >
              <thead class="t-head">
                <tr>
                  <!-- <th class="text-center">S.No</th> -->
                  <th class="text-center">Action</th>
                  <th>Employee Code</th>
                  <th>Employee Name</th>
                  <th>Version</th>
                  <th>Effective From Date</th>
                  <th>Effective To Date</th>
                  <th>City</th>
                  <th>Created Date</th>
                  <th class="text-center">Montly Income</th>
                  <th class="text-center">Total Compensation Per Annum</th>
                  <!-- <th>Status</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let row of resultData
                      | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                      | searchFilter : tableSearch;
                    let i = index
                  "
                >
                  <!-- <td class="text-center">{{i+1}}</td> -->
                  <td class="text-center">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" class="controls-list">
                      <button mat-menu-item (click)="updateform(row)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>

                      <!-- <button mat-menu-item *ngIf="row.status.listTypeValueId == 1" (click)="updateStatus(row.annexureHeaderId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>In-Active</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 2" (click)="updateStatus(row.annexureHeaderId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button> -->
                    </mat-menu>
                  </td>

                  <td>{{ row.employee.employeeNumber }}</td>
                  <td>{{ row.employee.firstName }}</td>
                  <td>{{ row.version }}</td>
                  <td>{{ row.effectiveFromDate | date : dateFormat }}</td>
                  <td>{{ row.effectiveToDate | date : dateFormat }}</td>
                  <td>{{ row.place }}</td>
                  <td style="white-space: nowrap">
                    {{ row.createDateTime | date : dateFormat }}
                  </td>
                  <td class="text-center">{{ row.monthlyIncome }}</td>
                  <td class="text-center">{{ row.annualIncome }}</td>
                  <!-- capitalizeWordsName -->
                  <!-- <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId == 1">
                                        {{row.status.listTypeValueName}}
                                    </span>
    
                                    <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId == 2">
                                        {{row.status.listTypeValueName}}
                                    </span>
                                </td> -->
                </tr>
                <tr *ngIf="resultData.length != 0">
                  <td colspan="8" class="text-right">
                    <b>Total :</b>
                  </td>
                  <td class="text-center">
                    {{ this.totalmontlyincome }}
                  </td>
                  <td class="text-center">
                    {{ this.totalannualincome }}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    style="
                      text-align: center;
                      font-weight: 770;
                      font-size: 21px;
                    "
                    colspan="12"
                    *ngIf="noRecordsFound"
                  >
                    No Records Found!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" *ngIf="resultData.length > 0 && !loader">
          <div class="col-md-4">
            <div class="showing-record">
              <!-- <ng-template pTemplate="paginatorleft" let-state>
                            Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                        </ng-template> -->
              {{ resultData.length }} rows
            </div>
          </div>
          <div class="col-md-8 text-right" *ngIf="!loader &&  resultData.length > 5">
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div> -->

  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
      role="status"
    ></div>
  </div>
</div>
