<!-- <div class="resource-background"> -->
    <!-- <div class="resource-title">
        <h1>Onboarding</h1>
    </div>   -->
    <section>
        <div class="sub-header">
            <div class="col-md-12 mb-3">
                <div class="row">
                    <div class="">
                        <span class="h5 sub-content" style="    font-size: 23px;
                        font-family: 'Mulish', sans-serif;
                        font-weight: 700;">
                           View Onboarding
                        </span>
                    </div>
                    <div class="sub-header-buttons">
                        <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                        <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                        <a routerLink="/home/onboardings" mat-raised-button title="Back" class="ml-2">
                            Back
                        </a>
                        <!-- <a routerLink="/home/edit-onboarding/{{onboardingId}}"  routerLinkActive="active"  mat-raised-button title="Add Position" class="ml-2" style="    background: #1a83ff !important;color: #fff;">
                            Edit Onboarding
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="card resource-card" style="margin-top:136px;">
        <form>
            <!-- <mat-icon class="material-symbols-outlined back" (click)="back()" style="color:red;">arrow_back</mat-icon> -->
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Resource Name :</label>
                    <label class="form-control-label-class">  {{onboardingobj?.resource?.resourceName}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Hiring for :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.hiring?.listTypeValueName}}</label>
                    <!-- <label class="form-control-label-class" *ngIf="onboardingobj.hiring != undefined"> {{onboardingobj.hiring.listTypeValueName}}</label>
                    <label class="form-control-label-class" *ngIf="onboardingobj.hiring == undefined">     --    </label> -->
                </div>                                                  
              
            </div>  
            <div class="row">  
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Approved CTC :</label>
                    <label class="form-control-label-class">  {{onboardingobj?.approvedCtc}}</label>
                </div>
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Work Location :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.workLocation}}</label>
                </div>
            </div>
            <!-- <div class="row">  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Client Name : </label>
                    <label class="form-control-label-class" *ngIf="onboardingobj.hiring != undefined"> {{onboardingobj.hiring.listTypeValueName}}</label>
                    <label class="form-control-label-class" *ngIf="onboardingobj.hiring == undefined">     --    </label>
                </div>   
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Create Date : </label>
                    <label class="form-control-label-class"> {{onboardingobj.createDateTime}}</label>
                </div>                                                  
            </div>   -->
            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Status :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.offerReleasedDate.substring(0,10)}}</label>
                </div>    
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Offer Accepted Date :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.offerAcceptedDate.substring(0,10)}}</label>
                </div>                                                  
            </div>

            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">waiting Period :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.waitingPeriod}}</label>
                </div>  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Expected Date Of Joining :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.dateOfJoining.substring(0,10)}}</label>
                </div>                                                  
            </div>
            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Status :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.status?.listTypeValueName}}</label>
                </div>  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">CreateDate :</label>
                    <label class="form-control-label-class"> {{onboardingobj?.createDateTime.substring(0,10)}}</label>
                </div>                                                  
            </div>
            <!-- <div class="row">  
                <div class="col-md-6">
                    <label class="form-control-label">Notice Period : </label>
                    <div>
                        <span *ngFor="let e of  onboardingobj.noticePeriod">  
                            <label class="form-control-label-class badge badge-info"> {{e.listTypeValueName}}</label>
                        </span>
                    </div>
                </div>  
                 
                <div class="col-md-6" id="loginform" *ngIf="onboardingobj.jobDescriptionAttachment">
                    <label class="form-control-label">Job Description : </label>
                    <div style="position: relative;bottom: 5px;">
                        <a class="file-download badge badge-secondary form-control-label" href="{{onboardingobj.jobDescriptionAttachment.fileDownloadUrl}}">
                      </a> -->
                            <!-- <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>Download</a> -->
                    <!-- </div>   -->
                <!-- </div>                                                    -->
            <!-- </div> --> 
        </form>  
    </div>
<!-- </div> -->
<div *ngIf="loader" class="align-items-center text-center" >
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
