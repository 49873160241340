<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content" style="    font-size: 23px;
                    font-family: 'Mulish', sans-serif;
                    font-weight: 700;">
                       View Requirement
                    </span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/requirement" mat-raised-button title="Back" class="ml-2">
                        Back
                    </a>
                    <a routerLink="/home/requirement-edit/{{requirementId}}" *ngIf="isAdmin"  routerLinkActive="active"  mat-raised-button  class="ml-2" style="    background: #1a83ff !important;color: #fff;">
                        Edit Requirement
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form>
                    <div class="row">   
                        <div class="col-md-6" id="loginform" >
                            <label class="form-control-label">Position : </label>
                            <label class="form-control-label-class">  {{requirementobj?.position?.positionName}}</label>
                        </div>   
                        <div class="col-md-6" id="loginform" >
                            <label class="form-control-label">Requirement Number : </label>
                            <label class="form-control-label-class">  {{requirementobj?.requirementNo}}</label>
                        </div>                                                  
                      
                    </div>  
                    <div class="row">  
                        <div class="col-md-6" id="loginform">
                            <label class="form-control-label">Experience : </label>
                            <label class="form-control-label-class"> {{requirementobj?.minExperience}} - {{requirementobj?.maxExperience}} Years</label>
                        </div>
                        <div class="col-md-6" id="loginform">
                            <label class="form-control-label">Location : </label>
                            <label class="form-control-label-class"> {{requirementobj?.location}} </label>
                        </div>
                      
                    </div>
                    <div class="row"> 
                        <div class="col-md-6" id="loginform">
                            <label class="form-control-label">CTC : </label>
                            <label class="form-control-label-class"> {{requirementobj?.ctc}}</label>
                        </div>  
                        <div class="col-md-6" id="loginform">
                            <label class="form-control-label">Hiring For : </label>
                            <label class="form-control-label-class"> {{requirementobj?.hiring?.listTypeValueName}}</label>
                        </div>                                                  
                       
                    </div>
                    <div class="row">  
                        <div class="col-md-6" id="loginform">
                            <label class="form-control-label">Client Name : </label>
                            <label class="form-control-label-class" *ngIf="requirementobj.client != undefined"> {{requirementobj?.client?.clientName}}</label>
                            <label class="form-control-label-class" *ngIf="requirementobj.client == undefined">     --    </label>
                        </div>   
                        <div class="col-md-6" id="loginform">
                            <label class="form-control-label">Job Type : </label>
                            <label class="form-control-label-class"> {{requirementobj?.jobType?.listTypeValueName}}</label>
                        </div>                                                  
                   
                    </div>  
                    <div class="row">  
                        <div class="col-md-6">
                            <label class="form-control-label">Notice Period : </label>
                            <div>
                                <span *ngFor="let e of requirementobj.noticePeriod">  
                                    <label class="form-control-label-class badge badge-info"> {{e.listTypeValueName}}</label>
                                </span>
                            </div>
                        </div>  
                         
                        <div class="col-md-6" id="loginform" *ngIf="requirementobj.jobDescriptionAttachment">
                            <label class="form-control-label">Job Description Attachment: </label>
                            <div style="position: relative;bottom: 5px;">
                                <a class="btn-primary create-button btn-sm" href="{{requirementobj.jobDescriptionAttachment.fileDownloadUrl}}">
                                    <!-- <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon> -->
                                    Download File</a>
                            </div>  
                            <label
                           
                            class="col-form-label"
                            style="    font-family: Mulish;
                            margin-left: 0%;
                            margin-top: -10px;
                            font-family: 'Mulish', sans-serif;
                            font-size: 15px;
                            font-weight: 660;"
                          >
                            Current Attachment: {{storeAttachment  }}
                          </label>
<!-- 
                            <div class="row" style="width: 135% !important">
                                <div
                                  class="col-md-10"
                                  style="margin-left: -6px; position: relative; top: 6px"
                                > -->
                                  <!-- Show file input always -->
                                  <!-- <input
                                    type="file"
                                    style="height: 41px"
                                    class="file-input form-control email-input select-placeholder"
                                  /> -->
                                <!-- </div> -->


                            <!-- <div
                            class="col-md-10"
                            style="
                              margin-left: -6px;
                              position: relative;
                              top: 11px;
                              left: 5px;
                            "
                            *ngIf="requirementobj.jobDescriptionAttachment != undefined"
                          > -->
                            <!-- Show attachment details if attachment is present -->
                            <!-- <span *ngIf="userdata.jobDescriptionAttachment.sourceFileName != undefined">{{userdata.jobDescriptionAttachment.sourceFileName}}</span> -->
                            <!-- <span *ngIf="userdata.jobDescriptionAttachment.sourceFileName == undefined">{{'Attach File'}}</span> -->
                            <!-- <div>
                              <a
                                class="btn btn-primary btn-sm"
                                href="{{
                                    requirementobj.jobDescriptionAttachment.fileDownloadUrl
                                }}"
                                download
                              >
                                Download
                              </a>
                            </div> -->
                          <!-- </div> -->


                        <!-- </div>                                                    -->
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12"  id="loginform">
                            <label class="form-control-label">Job Description : </label>
                            <label class="form-control-label-class"> {{requirementobj?.jobDescription}}</label>

                    </div>
                    </div>
                
                </form>
            </div>
        </div>
    </div>
</div>
    
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 38%;
    /* left: 34px; */" role="status"></div>
</div>
