import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl }from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { ShowerrorComponent } from '../../../app/showerror/showerror.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { HeaderService } from 'src/app/providers/header.service';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  categoryform: FormGroup;
  categorycreatedata:any;
  create_sucess_msg:any;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  success:any=false;
  error:any=false;
  usertype:any;
  employeeId:any;
  categoryupdatedata:any;
  categorydata:any;
  organizationId:any;
  status:any;
  listTypeValueId:any;
  createdBy:any;
  btnDisable: boolean=false;
  expenseCategoryId:any;
  isEditMode: boolean = false;
  


/// Listing ///
categorylistdata:any[];
p: number = 1;
public searchFilter: any = '';
query: any = '';
beforepage :any;
mySelect:any =5;
usersId:any;
submitted: Boolean = false;
// list_loader = true;
nodata = false;
importerror: boolean = false;

organization:any;
data: any = {};
categoryData: any;
  sucess_msg: any;
errorMsg: any;
  errormessagejson: any;
search: any;
  beforesearchpage: number;
  storingCategoryId: any;
setInactiveNo: boolean=false;
setActiveNo: boolean=false;
isboolean: boolean=false;
tableShow: boolean=true;
  storeEmpName: any;
  isEmployee: any;
  isAdminReqruiter: any;
  usertData: any='';
  userType: any;
  isAdmin: any;
ismatBool: boolean=false;





constructor(private router:Router,
  private dialog: MatDialog,
  public ActivatedRoute:ActivatedRoute,
  public fb:FormBuilder,
  private elementRef: ElementRef,
  private headerservice: HeaderService,
  private categoryservice:ProjectManagementService){}
  // public dialog: MatDialog,private dialogref : MatDialogRef<CategoryComponent>,
  // @Inject(MAT_DIALOG_DATA) public data: any) { }


ngOnInit(): void {
  // this.headerservice.setTitle('Category');
  this.headerservice.setTitle('');
  ///localhost stroage data
  this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));

    console.log(" this.usertData::", this.usertData);

    this.userType = [];
    this.usertData.employee.role.forEach(role => {
        this.userType.push(role?.roleName);
    });
    this.isAdminReqruiter=this.userType.includes('Admin') 
    this.isAdmin = this.userType.includes('Admin') 
    this.isEmployee=this.userType.includes('Employee') 

    console.log("this.isAdmin ",this.isAdmin );
    console.log( this.isEmployee);
    
    

    console.log(    this.userType);
  
  this.organizationId = this.usertData.organization.organizationId;
  console.log("org Id"+this.organizationId);
  this.employeeId = this.usertData.employeeId;
  console.log("Employee Id"+this.employeeId);

  // this.categoryform = new FormGroup(
  //   {
  //     expenseCategoryName : new FormControl(this.categoryform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
  //     description : new FormControl(this.categoryform),
  //     expenseCategoryId : new FormControl(),
  //     organizationId: new FormControl(),
  //     status:new FormControl(),
  //     lastUpdatedBy: new FormControl(),
  //     createdBy: new FormControl()

  //   });
  
  this.categoryform = new FormGroup(
    {
      // expenseCategoryName : new FormControl(this.categoryform,[Validators.required,Validators.pattern(/^[a-zA-Z0-9!@#$%^&*.?/]+(([. ][a-zA-Z0-9!@#$%^&*.?/ ])?[a-zA-Z0-9!@#$%^&*.?/]*)*$/)]),
      // expenseCategoryName:new FormControl('',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]),
      expenseCategoryName: new FormControl('', [
        Validators.required,
        Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ -/']+$"),
      ]),
      description: new FormControl('', [Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ -/']+$"),
      ]),
      // description : new FormControl(this.categoryform)
    });
  console.log(this.data);
  console.log(this.data.expenseCategoryId);
  

    if(this.data && this.data.expenseCategoryId)
    {
      console.log("CategoryId"+this.data.expenseCategoryId);
      this.categoryservice
      .categorybyid(this.data.expenseCategoryId)
      .subscribe((categorydata:any) =>
      {
        this.categorydata=categorydata;
        console.log(this.categorydata);

        this.categoryform.patchValue({
          expenseCategoryName: this.categorydata.expenseCategoryName,
          description: this.categorydata.description                 
        });
      });
    }
    this.listingTable()

    this.scrollToEditForm();


  ///Listing///
  // let  localdata = JSON.parse(localStorage.getItem('enoteUserData'));
  // this.organizationId = localdata.organization.organizationId;
  // console.log(this.organizationId);

  // this.categoryservice
  // .categorylist(this.organizationId)
  // .subscribe((categorylistdata:any) =>
  // {
    
  //   this.list_loader = false;
  //   this.nodata = false;
  //   this.categorylistdata = categorylistdata;
  //   console.log(this.categorylistdata);
  //   if(categorylistdata.length==0)
  //   {
  //     this.nodata = true;
  //     console.log("nodata");
  //   }
  //   if(categorylistdata.statusCode)
  //   {
  //     this.nodata = true;
  //     console.log("nodata");
  //   }
  // },
  // (error) => {
  //   this.list_loader = false;
  //   this.nodata = true;
  //   console.log("error")
  //   if(error.status == 404){
  //     this.nodata = true;
  //     console.log("404")
  //   }
  // }
  // ) 
}
changepage(){
  console.log(this.categorylistdata.length);
  if(this.search.length!= 0)
  {
     this.p = 1;
     console.log(this.categorylistdata.length);
    // this.taskEmpList.length=0;
    // console.log(this.taskEmpList.length);
    // if(this.taskEmpList.length == 0)
    //     {
    //       this.nodata = true;
    //       console.log("nodata")
    //     }
    // else
    // {
    //   this.p = 1;
      console.log(this.p);
      console.log("success Data");
  }
  else{
     this.p = this.beforesearchpage;
    // this.nodata = true;
    console.log(this.categorylistdata.length);
    console.log("nodata");
  }
}


get f() {
  return this.categoryform.controls;
}
listingTable(){
  this.p=1;
  let  localdata = JSON.parse(localStorage.getItem('enoteUserData'));
  this.organizationId = localdata.organization.organizationId;
  console.log(this.organizationId);

  this.isboolean=false;
  // this.tableShow=false
  this.loader=true
  this.ismatBool=false
  

  this.categoryservice
  .categorylist(this.organizationId)
  .subscribe((categorylistdata:any) =>
  {
    
    // this.list_loader = false;
    this.nodata = false;
    this.loader = false;
    this.isboolean=true;
    // this.tableShow=true
    this.ismatBool=true



    this.backdrop = false;
    this.categorylistdata = categorylistdata;
    console.log(this.categorylistdata);
    if(categorylistdata.length==0)
    {
      this.nodata = true;
      console.log("nodata");
    }
    if(categorylistdata.statusCode)
    {
      this.nodata = true;
      console.log("nodata");
    }
  },
  (error) => {
    // this.list_loader = false;
    this.nodata = true;
    console.log("error")
    if(error.status == 404){
      this.nodata = true;
      console.log("404")
    }
  }
  ) 
}
changefirstpage() {
  this.p = 1;
  }

// Add_client(){
//   const dialogbox = this.dialog.open(CategoryComponent, { 
//     width: '450px',height:'fit-content',
//     data:"" 
//   });

//   dialogbox.afterClosed().subscribe(
//     (res)=>{
//       console.log(res);
//        if(res)
//        {
//          if(res.statusCode == 200)
//          {
//             this.success = true;          
//            this.create_sucess_msg = res.message;

//               setTimeout(() => {
//                 this.success  = false;

//                 this.categoryservice
//                 .categorylist(this.organizationId)
//                 .subscribe((categorylistdata:any) =>
//                 {
//                   this.list_loader = false;
//                   this.categorylistdata = categorylistdata;
//                   console.log(this.categorylistdata);
//                   if(categorylistdata.statusCode)
//                   {
//                     this.nodata = true;
//                     console.log("nodata");
//                   }
//                 },
//                 (error) => {
//                   this.list_loader = false;
//                   this.nodata = true;
//                   console.log("error")
//                   if(error.status == 404){
//                     this.nodata = true;
//                     console.log("404")
//                   }
//                 }
//                 ) 
//             }, 2000)
//          }else{
//             this.error = true;
//              this.update_error_msg = res.message;
//              setTimeout(() => {this.error = false}, 2000)
//          }
 
//        }
//     }
//   );
// }

// updateform(catogory)
// { 
//   console.log(catogory);  
//    const  dialogupdatebox = this.dialog.open(CategoryComponent, { 
//     width: '450px',height:'fit-content',
//     data:catogory
//   });    

//   dialogupdatebox.afterClosed().subscribe(
//     (res)=>{
//       console.log(res);
//        if(res)
//        {
//          if(res.statusCode == 200)
//          {
//             this.success = true;          
//            this.create_sucess_msg = res.description;

//               setTimeout(() => {
//                 this.success  = false;

//                 this.categoryservice
//                 .categorylist(this.organizationId)
//                 .subscribe((categorylistdata:any) =>
//                 {
//                   this.list_loader = false;
//                   this.categorylistdata = categorylistdata;
//                   console.log(this.categorylistdata);
//                   if(categorylistdata.statusCode)
//                   {
//                     this.nodata = true;
//                     console.log("nodata");
//                   }
//                 },
//                 (error) => {
//                   this.list_loader = false;
//                   this.nodata = true;
//                   console.log("error")
//                   if(error.status == 404){
//                     this.nodata = true;
//                     console.log("404")
//                   }
//                 }
//                 ) 
//             }, 2000)
//          }else{
//             this.error = true;
//              this.update_error_msg = res.message;
//              setTimeout(() => {this.error = false}, 2000)
//          }
 
//        }
//     }
//   );
// }

statuschangeactive(expenseCategoryId)
{
  // this.setInactiveNo=false
  console.log("expenseCategoryId"+expenseCategoryId);    
  this.loader =true;
  // this.isboolean=true
  this.tableShow=false

 this.categoryservice
 .categorystatustoDeactive(expenseCategoryId)
 .subscribe( (data:any) =>
 {
    console.log(data);
    this.loader =true;
    // this.isboolean=true
    this.tableShow=false

    this.backdrop = true;
    this.listingTable()
    // window.location.reload();
 })

}

statuschangeinactive(expenseCategoryId)
{
  // this.setActiveNo=false
  console.log("expenseCategoryId"+expenseCategoryId);    
  this.loader =true;
  this.isboolean=true
  this.tableShow=false

  this.categoryservice
  .categorystatustoactive(expenseCategoryId)
  .subscribe( (data:any) =>
  {
    this.loader =true;
    this.backdrop = true;
    this.isboolean=true
    this.tableShow=false


     console.log(data);
     this.listingTable()

    //  window.location.reload(); 
  })
 
}


onKeyUpEvent(event)
{
  if(this.query.length !=0)
  {
    this.p=1;
  }
  else
  {
    this.p = this.beforepage;
  }
}
showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}
sortData(sort: Sort) {
  
  const data = this.categorylistdata.slice();
  if (!sort.active || sort.direction === '') {
    this.categorylistdata = data;
    return;
  }
  console.log("this.categorylistdata::",this.categorylistdata);
  

  this.categorylistdata = data.sort((a, b) => {
    console.log("a:: , b::",a,b);
    
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'expenseCategoryName':
        return this.compare(a.expenseCategoryName, b.expenseCategoryName, isAsc);
      case 'description':
        return this.compare(a.description, b.description, isAsc);
      case 'listTypeValueName':
        return this.compare(a.status.listTypeValueId, b.status.listTypeValueId, isAsc);
      default:
        return 0;
    }
  });
}
scrollToEditForm() {
  const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
  if (editFormElement) {
    editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}

compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
// resetForms(){
//   this.categoryform.length=0
// }

editCategory(category) {

  
  console.log("category.expenseCategoryId",category.expenseCategoryId);

  this.storingCategoryId=category.expenseCategoryId
  console.log(this.storingCategoryId);
  
  this.loader=true
  this.categoryservice.categorybyid(category.expenseCategoryId)
    .subscribe((categoryData: any) => {
      console.log(categoryData);
      this.categoryData=categoryData
      this.loader=false

      
      this.categoryform.patchValue({
        expenseCategoryName: categoryData.expenseCategoryName,
        description: categoryData.description,
        expenseCategoryId: categoryData.expenseCategoryId,
        organizationId: categoryData.organizationId
      });
      if (this.categoryform.valid) {
        const dataUpdate = this.categoryform.value;
        console.log('update data::', dataUpdate);
      }
      this.isEditMode = true;
      this.scrollToEditForm();

    });
}
ngAfterViewInit() {
  // Scroll to the edit form after the view is initialized
  this.scrollToEditForm();
}


submitData(){
  // console.log("this.categoryData.expenseCategoryId",this.categoryData.expenseCategoryId);
  this.search=''
  this.error = false;
  this.success = false;
  this.submitted=true
  event.preventDefault();
  // console.log("this.categoryform.value.expenseCategoryId",this.categoryform.value.expenseCategoryId);
  

  if(this.categoryform.valid)
  {
    // this.submitted=true
    // return;
    this.loader=true;
  
  this.btnDisable=true;
  this.submitted=false

  // this.data  =this.categoryform.value;
  // console.log("this.data::",this.data);
  console.log("this.categoryform.value::",this.categoryform.value);
  console.log(this.storingCategoryId);
  
  
  if( this.storingCategoryId!=undefined)
  {
    console.log(this.storingCategoryId);

    console.log("update entered!!");
    
    var categoryupdate =
    { 
      expenseCategoryId : this.storingCategoryId,
      expenseCategoryName :  this.capitalizeWordsName(this.categoryform.value.expenseCategoryName),
      description :this.categoryform.value.description,
      lastUpdatedBy:this.employeeId,
      status:{
          listTypeValueId:this.categoryData.status.listTypeValueId
        },
      createdBy:this.createdBy,
      organizationId:this.organizationId,
    };          
    console.log(categoryupdate);

    this.backdrop = true;
    // this.loader=true;
    console.log(this.data.expenseCategoryId)

      this.categoryservice
      .categoryupdate(this.storingCategoryId,categoryupdate)
      .subscribe( (data:any) =>
      {
        this.storingCategoryId=undefined
        this.categoryupdatedata= data;
        // console.log(this.categoryupdatedata);
        // this.backdrop = false;
        this.loader = false;
        //  this.dialogref.close(data);
        if(data.statusCode == 200){
          this.success = true;          
          this.btnDisable = false;
          this.sucess_msg = this.categoryupdatedata.description;
           console.log(this.sucess_msg);
             setTimeout(() => {
               this.success=false
               this.categoryform.reset()
   
               // this.backdrop = true;
   
               // window.location.reload();
             }, 4000)
        }else{
          this.btnDisable = false;
             this.error = true;
          this.errorMsg = data.description;
          console.log(this.errorMsg);
          setTimeout(() => {
            this.error = false;
          }, 4000)
        }
      
          this.listingTable()
          this.scrollToEditForm();

        },
        (err) =>
        {
          this.backdrop = false;
          this.loader = false;
         
          this.btnDisable = false;
          // this.dialogref.close(err.error);
          // this.error = true;
          this.errorMsg = err.error.message;
          console.log(this.errorMsg);
          setTimeout(() => {this.error = false}, 4000)
        });

  }
  
  // if( this.categoryform.value.expenseCategoryId)
  // {
  //   var categoryupdate =
  //   { 
  //     expenseCategoryId : this.categoryform.value.expenseCategoryId,
  //     expenseCategoryName :  this.capitalizeWordsName(this.categoryform.value.expenseCategoryName),
  //     description :this.categoryform.value.description,
  //     lastUpdatedBy:this.employeeId,
  //     status:{
  //         listTypeValueId:this.categoryData.status.listTypeValueId
  //       },
  //     createdBy:this.createdBy,
  //     organizationId:this.organizationId,
  //   };          
  //   console.log(categoryupdate);

  //   // this.backdrop = true;
  //   // this.loader=true;
  //   console.log(this.data.expenseCategoryId)

  //     this.categoryservice
  //     .categoryupdate(this.categoryform.value.expenseCategoryId,categoryupdate)
  //     .subscribe( (data:any) =>
  //     {
  //       this.categoryupdatedata= data;
  //       console.log(this.categoryupdatedata);
  //       this.backdrop = false;
  //       this.loader = false;
  //       //  this.dialogref.close(data);
  //       if(data.statusCode==200){
  //         console.log("entered if!...");
  //         this.success = true;          
  //         this.sucess_msg = this.categoryupdatedata.description;
  //          console.log(this.sucess_msg);
  //         setTimeout(() => {
  //           this.success=false
  //         }, 3000)
  //       }
  //         // this.listingTable()

  //       },
  //       (err:any) =>
  //       {
         
  //         console.log("err::",err);
  //         console.log("err.status::",err.status);
  //         console.log("err.error.description::",err.error.description);

  //         // alert(err.error.description)
          
  //         this.error = true;
  //         this.errorMsg = err.error.description;
  //         console.log(this.errorMsg);
  //         setTimeout(() => {this.error = false}, 2000)
  //       });

  // }
  else
  {
    console.log("creattion entered!!");
    
      var categorycreate =
      { 
        // expenseCategoryId : this.categoryform.value.expenseCategoryId,         
        // expenseCategoryName : this.capitalizeWordsName(this.categoryform.value.expenseCategoryName),
        expenseCategoryName:this.categoryform.value.expenseCategoryName,
        description : this.categoryform.value.description,
        // lastUpdatedBy:this.employeeId,
        organizationId:this.organizationId,
        status:{
          listTypeValueId:1
        },
        createdBy: this.employeeId ,
                 
      };
      console.log(categorycreate);
      // this.backdrop = true;/
      // this.loader=true;
      // return
      this.categoryservice
      .categorycreate(categorycreate)
      .subscribe( (data:any) =>
      {
        
          this.categorycreatedata= data;
          console.log(this.categorycreatedata);
          // this.backdrop = false;
         this.loader = false;
          // this.dialogref.close(data);
          // this.success = true;
          // this.sucess_msg = this.categorycreatedata.description;
          // console.log(this.sucess_msg);

          const categoryName = this.categorycreatedata.expenseCategoryName;
          const description = this.categorycreatedata.description;
          this.btnDisable = false;
          // Add the extracted data to categorylistdata array
          // this.categorylistdata.push({ categoryName, description });
          if(data.statusCode==200){
            this.success=true
            this.sucess_msg=data.description
            setTimeout(() => {
              this.success=false
                    this.categoryform.reset()

              // window.location.reload();
            }, 4000)
          }
          this.listingTable()

         
      },
      //   err =>
      //   {
      //     // this.backdrop = false;
      //     // this.loader = false;
      //     // this.btnDisable = false;
      //     // this.dialogref.close(err.error);
      //     this.error = true;
      //     this.update_error_msg = err.error.description;
      //     console.log(this.update_error_msg);
      //     setTimeout(() => {this.error = false}, 2000)
      //   });
      // }
      (err:any)=>{
        this.btnDisable = false;
                console.log("code",err);
                console.log("code",err.status);
                console.log("err.error.description::",err.error.description);
                this.error = true;
                this.errorMsg=err.error.description;
                this.loader = false;
                console.log("this.erroMsg::",this.errorMsg);
                  setTimeout(() => {
                    this.error = false;
                  }, 3000) 
                }
      )
              }
            }
      // this.categoryform.reset()
}


clearForm(){
  this.submitted=false
  // this.search=''
  this.categoryform.reset(); 
  this.storingCategoryId = undefined;
  }

//  formcancel()
//  {
//   // this.categoryform.reset();
//   this.submitted=false;
//   // Reset edit mode to false
//   // this.isEditMode = false;
//   console.log("entered cleare");
//   console.log("this.categoryform",this.categoryform);
  
  
// }

capitalizeWordsName(text){
  if (!text) return text;
  return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
};

capitalizeWordsDescription(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}



//////get values from form //////
get expenseCategoryName()
{
  return this.categoryform.get('expenseCategoryName');  
}
get description()
{
  return this.categoryform.get('description');  
}



toggleStatus(category: any) {
  console.log("category",category);
  
  // category.status.listTypeValueName = category.status.listTypeValueName === 'Active' ? 'In-Active' : 'Active';

  if(category.status.listTypeValueName ==='Active'){
    console.log("if active");
    
    this.statuschangeactive(category.expenseCategoryId)
  }
  else{
    console.log("de active");
    
    this.statuschangeinactive(category.expenseCategoryId)

  }
  
}


}
