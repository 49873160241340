import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { AppComponent } from 'src/app/AppComponent';
import { AppComponent } from '../../app.component';
// import { PayrollService } from 'src/app/providers/payroll.service';
// import { SettingsService } from 'src/app/providers/settings.service';
// import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
// import { ExcelService } from 'src/app/services/excel.service';
// import { ExportExcelService} from 'src/app/services/export-excel.service';

import { PayrollService } from '../../providers/payroll.service';
import { SettingsService } from '../../providers/settings.service';
// import { ShowErrorComponent } from '../../../app/showerror/showerror.component';
import { ExcelService } from '../../services/excel.service';
import { ExportExcelService } from '../../services/export-excel.service';
@Component({
  selector: 'app-client-esi-challan',
  templateUrl: './client-esi-challan.component.html',
  styleUrls: ['./client-esi-challan.component.scss']
})
export class ClientEsiChallanComponent implements OnInit {

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  userData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =true;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: number;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  totalamountbeforededuction: number= 0;
  totalnetpay: number = 0;
  totalDedution: number = 0;
  period: string = "";
  employeeName: any = "";
  firstname: any;
  lastname: any;
  empnumber: any;
  empCode: boolean;
  clientaddressobj: any = {};
  dataForExcel = []; 
  excelData: any;
  headerForExcel = [
    {'Client Code':'' ,'Client Name':'' ,'MONTH':'',  'Emp NO':'','ESI NO':'',  'Name':'',  'DAYW':'',   'DAYPH':'',  'DAYTOT':'',  'ESI Wage':'', 'Total ESI':'',  'Father Name':'',
     'DOJ':'','DOB':'', 'AADHAR':'',   'UAN':'',
   }
  ];


  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any , private ExportExcelService  : ExportExcelService) { }

  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem('enoteUserData') );
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.userData.userId;
    this.orgId = this.userData.organization.organizationId;
    console.log(this.orgId);


    

    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });

    
   
    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null,],
      period:[null,],
      client:[null,],
    })
   

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
    

    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }


  searchSubmit()
  {
    console.log("submit");
    this.req=false;
    this.p = 1;
    console.log(this.filterForm.value);
    console.log("this.filterForm.value.employeecode" + this.filterForm.value.employeecode);
    console.log("this.filterForm.value.financialyear" + this.filterForm.value.financialyear);
    console.log("this.filterForm.value.period" + this.filterForm.value.period);
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null ) && (this.filterForm.value.client=== 'null' || this.filterForm.value.client === null ) &&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period === 'null')){
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = true;
      alert("Please select  Requried fields");
      // this.req=true;
      return
    }
   else if((this.filterForm.value.employeecode) && ( this.filterForm.value.financialyear) && (this.filterForm.value.period ))
    {
          

      console.log("both");
      this.payrollservice.getEsiCallanByEmployeeId(this.employeeId,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        
        //console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
       

        }
        
        if( resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
        }
      })
    }
    
    else if((this.filterForm.value.client) && (this.filterForm.value.financialyear) && (this.filterForm.value.period  ) )
    {
      console.log("finactical and period");
      this.payrollservice.getEsiCallanReportByClientId(this.filterForm.value.client , this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        // this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
       

        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    
    this.clientList.forEach(element => {
      console.log("foreach")
      if(element.clientId == this.filterForm.value.client)
      {
        console.log(element)
         this.clientaddressobj = element;
      }
   });
  }

  clearTable()
  {
    this.resultData.length = 0;
  }

  empSearch(event)
  { 
    this.empList=[];
    this.empCode=false;
    console.log(event.target.value);
    this.userId=event.target.value;
    console.log(this.userId);
    // Employee List Api
      // this.empList.length == 0;
      if(event.target.value.length != 0)
      {
        this.payrollservice.getClienEmployeeList(this.userId,this.filterForm.value.client).subscribe((result: any) => {
          if(result.statusCode==204)
                {
                  console.log('NO Data');
                  this.empList.length = 0;
                  this.empCode=true;
                }
                else
                {
                  this.empList = result;
                  this.empCode=false;
                  console.log(this.empList);
                }
        })
      }else{
        this.filterForm.patchValue({
          employeecode: null
       });
      }
  
  }
  
  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log(emp);
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;

  
   
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;

    console.log(this.employee);
    
      this.filterForm.patchValue({
        employeecode: this.employee
     });
  
    // }
  
  }

  roundofdata()
  {
    this.resultData.map(  (e) => {
      e.payrollLine.map((payelement)=> {
        payelement.amount = Math.round(payelement.amount);
      }) 
   })

  }
  
  pageChages(){
    console.log("page changes");
    this.p=1;
  }
  selectFinancialyear(event){
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.FilterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    
    if(event.target.value == 'null')
    {
      this.filterForm.patchValue({
      
        financialyear:null,
      
      })
    }else{
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
    });
  }
  }
  selectFinancialfromPeriod(event)
  {
    if(event.target.value == 'null')
    {
      this.filterForm.patchValue({
      
        fromperiod:null,
      
      })
  }
}
selectFinancialtoPeriod(event)
{
  if(event.target.value == 'null')
  {
    this.filterForm.patchValue({
    
      toperiod:null,
    
    })
}
}

calucatenetpay(earnings,deductions)
{
   let netpay = earnings - deductions;

  return  netpay?.toFixed(2);
}
 
  public downloadAsExcel(){
      let clientcode = "" ;
    this.dataForExcel.length = 0;
    let totaldaysofwages = 0;
    let totalOtHours = 0;
    this.excelData=this.resultData;
    console.log(this.excelData);
    for(let e of this.excelData){
      let data= {
          clientCode : e.client.clientCode,
          clientName : e.client.clientName,
          period : e.finanicalPeriod.financialperiodMonth + "-"  +e.finanicalPeriod.financialPeriodYear,                    
          employeeNumber :   e.employee.employeeNumber,
          esiNumber :  e.employee.esiNumber ? e.employee.esiNumber : "" ,
          firstName :  e.employee.firstName,
          workingDays :  e.employeeWorkingDays.workingDays,
          leaveDays : e.clientWorkingDays.leaveDays,
          totaldays : e.clientWorkingDays.workingDays + e.clientWorkingDays.leaveDays,
          esiwage :   e.totalEarnings?.toFixed(2),
          employeeEsi :  e.employeeEsi?.toFixed(2),
          lastName:    e.employee.lastName,
          dateOfJoining :   e.employee.dateOfJoining,
          dateOfBirth :    e.employee.dateOfBirth,
          aadhaarNumber :    e.employee.aadhaarNumber ? e.employee.aadhaarNumber : "",
          uanNumber :   e.employee.uanNumber? e.employee.uanNumber : ""
        }
     

       

      //  let netpay = Math.round(e.totalEarnings) -  Math.round(e.totalDeduction) ;
      //   data["Dedecutions"]  =  Math.round(e.totalDeduction) ;

      

     
      console.log("table data",data);
      this.dataForExcel.push(Object.values(data));
      console.log(this.dataForExcel);
    }
   
   

 
   
   
    let reportDatabyrecruiter = {
      title: 'ESI Challan',
       exceldate: "Period : " +  this.period,
      data: this.dataForExcel,
      employeeName :this.employeeName,
      headers: Object.keys(this.headerForExcel[0]),
    }
    console.log(reportDatabyrecruiter);
    this.ExportExcelService.exportExcel(reportDatabyrecruiter);
  }

}
