
<h1 class="title" mat-dialog-title style="color:#4A4A4A;font-size: 20px;">Leave Withdraw</h1>
<div align="end" style="position: relative;right: 10px;bottom: 10px;">
  <mat-icon class="material-icons-outlined icons" mat-button [mat-dialog-close]="true" 
  style="color:red;font-size: 22px;position: absolute;transform: translate(-10px ,-31px);" 
  cdkFocusInitial>highlight_off</mat-icon>
</div>
<mat-dialog-content>
    <form action="" [formGroup]="withDrawForm" (ngSubmit)="withdraw()">
      <div class="row d-flex align-items-center">
        <div class="col-md-6">
            <div class="input-width" id="loginform">
                <label class="form-control-label">Choose FromDate<span class="star"> *</span></label>
                <input [matDatepicker]="picker"
                class="form control select-placeholder email-input " style="outline:none;border-radius: 5px; font-family: Mulish; font-weight: 550;"
                    [matDatepickerFilter]="myFilter"
                    (click)="picker.open()" formControlName="startDate"
                    (dateChange)="someMethodName($event)"
                    [min]="From_Date"
                    [max]="End_Date"
                    placeholder="Choose FromDate" readonly
                    cdkFocusInitial>
                <mat-datepicker-toggle matSuffix [for]="picker" style="float: right; position: relative; bottom: 38px;"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error style="font-family: Mulish;" *ngIf="f.startDate.hasError('required') && (f.startDate.touched || f.startDate.dirty)">
                  FromDate is required.
                </mat-error>
            </div>
        </div>
        <div class="col-md-6" style="padding-left: 10px;">
            <div class="input-width" id="loginform">
                <label class="form-control-label">Choose ToDate<span class="star"> *</span></label>
                <input [matDatepicker]="a" (click)="a.open()"
                class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;font-weight: 550;"
                    [matDatepickerFilter]="myFilter"
                    formControlName="endDate" readonly
                    [min]="EndDate_min"
                    [max]="End_Date"
                    [disabled]="EndDate_disable"
                    (dateInput)="nofoDays($event)"
                    placeholder="Choose ToDate"> 
                <mat-datepicker-toggle matSuffix [for]="a" style="float: right; position: relative; bottom: 38px;"></mat-datepicker-toggle>
                <mat-datepicker #a></mat-datepicker>
                <mat-error style="font-family: Mulish;" *ngIf="f.endDate.hasError('required') && (f.endDate.touched || f.endDate.dirty)">
                  ToDate is required.
                </mat-error>
            </div>
        </div>
    </div>
    
        <ng-container *ngIf="halfDay;else weekDay">
            <div class="input-width" id="loginform">
                <label class="form-control-label">&nbsp; No.of Days<span class="star"> *</span></label>
                <ng-container *ngIf="validate;else notValidate">
                    <input formControlName="noOfDays" matInput readonly class="form control select-placeholder email-input " style="outline:none;border-radius: 5px; font-family: Mulish;font-weight: 300;"/>
                </ng-container>
                <ng-template #notValidate>
                    <select formControlName="noOfDays" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px; font-family: Mulish;font-weight: 300;"
                    placeholder="No.of Days">
                        <option value="0.5">0.5</option>
                        <option value="1">1</option>
                    </select>
                </ng-template>
                <mat-error style="font-family: mulish;" *ngIf="f.noOfDays.hasError('required') && (f.noOfDays.touched || f.noOfDays.dirty)">
                    Number Of Days is required.
                </mat-error>
            </div>
        </ng-container>

        <ng-template #weekDay>
            <div class="input-width" id="loginform">
                <label class="form-control-label">&nbsp; No.of Days<span class="star"> *</span></label>
                <input type="number" [value]="noOFDays" readonly placeholder="No.of Days"
                class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-weight: 300;"/>
            </div>
        </ng-template>

        <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Reason<span class="star"> *</span></label>
            <textarea placeholder="Reason" formControlName="withdrawReason"
            class="form control textarea email-input " style="outline:none;border-radius: 5px;font-family: mulish;" (input)="validateInput($event)"
            ></textarea>
            <mat-error  style="font-family: mulish;" *ngIf="f.withdrawReason.hasError('required') && (f.withdrawReason.touched || f.withdrawReason.dirty)">
                Reason is required.
            </mat-error>
            <div  style="font-family: mulish;" *ngIf="invalidInput && (f.withdrawReason.pristine || !f.withdrawReason.errors?.required)">
                <mat-error>Reason cannot consist of only spaces.</mat-error>
              </div>
        </div>
    </form>
</mat-dialog-content>

<div class="form-group" align="end">
    <div class="d-flex">
        <div style="width: 45%;margin: 10px auto;">
            <!-- <button mat-stroked-button mat-dialog-close class="cancel-button" color="accent" type="button"  >Cancel</button> -->
            <button type="reset"   class="" mat-dialog-close      mat-raised-button   style="
            float: right; 
            
            margin-left: 7px;
              background: none;
           
              border: 1px solid #ddd;
              font-size: 14px;
              font-weight: 300;
              height: 40px !important;
            "  
             style="color:var(--Red-Color, #FF5D5D); height:35px "
            mat-dialog-close>Cancel</button>
        </div>
        <div style="width: 45%;margin: 10px auto;display:flex">
            <button mat-stroked-button class="searchBtn"   type="submit" [mat-dialog-close]="close_dialogue" cdkFocusInitial
            (click)="withdraw()">&nbsp;Withdraw&nbsp;</button>

            
        </div>
    </div>
</div>




  <!-- <div class="text-center" *ngIf="success1">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{success_msg1}}
    </div>
  </div>
  <div *ngIf="error1" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg1}}
  </div> -->




<style>
:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0 12px 0;
}
.form-icon{
  font-size: 18px;
  transform: translateY(0px);
}
:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix input.mat-input-element{
  font-size: 13px;
}
.form-label{
  font-size: 13px;
}
:host ::ng-deep .form-textarea .mat-form-field-wrapper .mat-form-field-flex{
  align-items: start;
}
.form-group{
  margin-bottom: 0px;
}
.apply-button{
  background: #1A83FF;
  border: 1px solid #1A83FF;
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(26, 131, 255, 0.20)!important;
  color: white;
  float: left;
  font-size: 13px;
  font-weight: 550;
  letter-spacing: 0.020em;
}
.cancel-button{
  background: #FFFFFF;
  border: 1px solid #F98080;
  box-shadow: 0px 4px 15px rgba(255, 116, 116, 0.1);
  border-radius: 5px;
  float: right;
  font-size: 13px;
  font-weight: 550;
  color: #FF3434;
}
.cancel-button-icon{
  font-size: 15px;
  position: relative;
  bottom: 1px;
}
.apply-button-icon{
  font-size: 15px;
  position: relative;
  bottom: 1px;
}
button:focus{
  outline: 0px!important;
}
:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color:#5fa8ff7d;
}
</style>


<div *ngIf="isLoading" class="d-flex align-items-center justify-content-center loader-container" style="margin-top: 10%;" >
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

