<div class="container" style="margin-top: 70px;">
    <div class="float-left">
      <!-- <button mat-stroked-button (click)="close()" type="button">
        Close
      </button> -->
      <mat-icon class="material-icons-outlined " style="font-size: 22px;" (click)="close()">arrow_back</mat-icon>
    </div>
    <div class="float-right">
      <button mat-raised-button type="button" (click)="exportPDF(mombyIddata.momName)" class="action btn create-button">
        <mat-icon class="material-symbols-outlined printDesign" style="position:relative; top:2px;font-size:22px">file_download</mat-icon>
      </button>
    </div>
    <div class="float-right">
      <!-- <button mat-raised-button type="button" (click)="print(mombyIddata.momName)" class="action btn create-button">
        <mat-icon class="material-symbols-outlined printDesign" style="position:relative; top:2px;font-size:22px;">print</mat-icon>
      </button> -->
      <button mat-raised-button type="button" (click)="print(mombyIddata.momName)" class="action btn create-button">
        <mat-icon class="material-symbols-outlined printDesign" style="position:relative; top:2px;font-size:22px;">print</mat-icon>
      </button>
    </div>
    <br><br>
    
    <div id="pdfTable"  #pdfTable   style="display: none;" >
      <div>
        <table class="table table-bordered" style="font-size:14px;" >
          <thead>
            <tr>
              <h3 style="text-align: center; 
              "> Minutes Of Meeting</h3>
              <hr>
            </tr>
            <tr style="border:red;">
              <td style="border:0;width:100px;"><b>Meeting Date</b></td>
              <td style="border:0">:</td>
              <td style="border:0;width:202px;">{{mombyIddata.date}}-{{mombyIddata.month}}-{{mombyIddata.year}}</td>
              <td style="border:0;"><b>Client</b></td>
              <td style="border:0">:</td>
              <td style="border:0;width:202px;">{{mombyIddata.client}}</td>
            </tr>
            <tr *ngIf="mombyIddata.project">
           
              <td style="border:0"><b>Project</b></td>
              <td style="border:0">:</td>
              <td style="border:0">{{mombyIddata.project.projectName}}</td>
              <td style="border:0;width:100px;"><b>Place</b></td>
              <td style="border:0">:</td>
              <td style="border:0">{{mombyIddata.meetingPlace}}</td>
            </tr>
            <tr *ngIf="mombyIddata.project==undefined">
              <td style="border:0;width:100px;"><b>Place</b></td>
              <td style="border:0">:</td>
              <td style="border:0"  colspan="4">{{mombyIddata.meetingPlace}}</td>
            </tr>
            <tr>
              <td style="border:0"><b>MOM Name</b></td>
              <td style="border:0">:</td>
              <td style="border:0" colspan="4">{{mombyIddata.momName}}</td>
            </tr>
            <tr style="margin-bottom:15px;">
              <td style="border:0"><b>Attendees</b></td>
              <td style="border:0">:</td>
              <td style="border:0" colspan="4">{{mombyIddata.momAttendees}}</td>
            </tr>
            <tr>
              <td colspan="6" style="border:0;background-color: #dddddd"><b>Points Discussed</b></td>
            </tr>
            <tr *ngFor="let data of mombyIddata.pointsDiscussed" style="margin-bottom:5px;">
              <td style="border:0;" colspan="6">
                <ul style="margin-bottom: 0px;padding-left: 20px;">
                  <li>
                    {{data.pointsDiscussedDetails}}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td colspan="6" style="border:0;background-color: #dddddd"><b>Points Agreed</b></td>
            </tr>
            <tr *ngFor="let data of mombyIddata.pointsAgreed" style="margin-bottom:5px;">
              <td style="border:0" colspan="6">
                <ul style="margin-bottom: 0px;padding-left: 20px;">
                  <li>
                {{data.pointsAgreedDetails}}
                </li>
                </ul>
              </td>
            </tr>
            <tr *ngIf="mombyIddata.actionItem.length !=0">
              <td colspan="6"style="border:0;background-color:#dddddd"><b>Action Items</b></td>
            </tr>
          </thead>
        </table>
        <table style="border: 1px solid #555555;" data-pdfmake="{'heights':10}" *ngIf="mombyIddata.actionItem.length !=0">
          <thead>
            <tr style="font-size:15px;">
              <th style="padding: 10.65px 10.75px;width:7%;text-align: center;">S.No</th>
              <th style="width:50%">Action Items</th>
              <th style="width:23%">Owner</th>
              <th style="width:20%;text-align: center;">Target Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let action of mombyIddata.actionItem;let i=index" style="font-size:14px;">
              <td style="padding: 10.65px 10.75px;text-align: center;">{{i+1}}</td>
              <td>{{action.actionItemName}}</td>
              <td>{{action.actionItemOwner}}</td>
              <td style="text-align: center;">{{action.actionItemTargetDate | date}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> 
       
   
      
    
    
    <div style="font-size:14px;">
      <div style="border:1px solid black;" >
        <div style="border-bottom: 1px solid black;text-align: center;padding:10px;">
            <h5 class="text-center" style="margin: 0px;font-weight:600 ; font-size: 25px;font-family: 'Mulish', sans-serif;">Minutes Of Meeting</h5>
        </div>
        <div style="border-bottom: 1px solid black;padding:10px;font-family: 'Mulish', sans-serif;font-size: 15px;">
          <div class="row">
            <div class="col-md-6">
              <p >
                <span class="" style="display: inline-block;min-width: 100px;"><b>Meeting&nbsp;Date</b></span>
                <span class="" style="margin: 0px 10px 0px 0px;">:</span>
                <span class="">{{mombyIddata.date}}-{{mombyIddata.month}}-{{mombyIddata.year}}</span>
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <span class="" style="display: inline-block;"><b>Client</b></span>
                <span class="" style="margin: 0px 10px 0px 0px;min-width: 100px;">:</span>
                <span class="">{{mombyIddata.client}}</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6" *ngIf="mombyIddata.project">
              <p >
                <span class="" style="display: inline-block;min-width: 100px;"><b>Project</b></span>
                <span class="" style="margin: 0px 10px 0px 0px;">:</span>
                <span class="">{{mombyIddata.project.projectName}}</span>
              </p>
            </div>
            <div class="col-md-6">
              <p >
                <span class="" style="display: inline-block;"><b>Place</b></span>
                <span class="" style="margin: 0px 10px 0px 0px;">:</span>
                <span class="">{{mombyIddata.meetingPlace}}</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p >
                <span class="" style="display: inline-block;min-width: 100px;"><b>MOM&nbsp;Name</b></span>
                <span class="" style="margin: 0px 10px 0px 0px;">:</span>
                <span class="">{{mombyIddata.momName}}</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="d-flex">
                <span class="" style="display: inline-block;min-width: 100px;"><b>Attendees</b></span>
                <span class="" style="margin: 0px 10px 0px 0px;">:</span>
                <span class="">{{mombyIddata.momAttendees}}</span>
              </p>
            </div>
          </div>
        </div>
        <div style="border-bottom: 1px solid black;padding:10.65px 10.75px;font-family: 'Mulish', sans-serif;font-size: 15px;">
            <b>Points Discussed</b>
        </div>
        <div style="border-bottom: 1px solid black;padding:10.65px 10.75px;font-family: 'Mulish', sans-serif;font-size: 14px;">
          <div *ngFor="let data of mombyIddata.pointsDiscussed">
            <ul style="margin-bottom: 5px;padding-left: 20px;">
              <li>
                {{data.pointsDiscussedDetails}}
              </li>
            </ul>
          </div>
        </div>
        <div style="border-bottom: 1px solid black;padding:10.65px 10.75px ;;font-family: 'Mulish', sans-serif;font-size: 15px;">
          <b>Points Agreed</b>
        </div>
        <div style="border-bottom: 1px solid black;padding:10.65px 10.75px ; font-family: 'Mulish', sans-serif;font-size: 14px;">
          <div *ngFor="let data of mombyIddata.pointsAgreed">
            <ul style="margin-bottom: 5px;padding-left: 20px;">
              <li>
                {{data.pointsAgreedDetails}}
              </li>
            </ul>
          </div>
        </div>
        <div style="font-family: 'Mulish', sans-serif;font-size: 15px;color: #525252">
            <b style="    position: relative;
            top: 10px;
            padding: 7px 12px;">Action Items</b>
        </div>
        <table  class="table-responsives" border="1" style="border: 0px solid #525252;" >
          <thead>
            <tr>
              <th class="text-center" style="padding: 10.65px 10.75px;width:5%">S.No</th>
              <th style="padding: 10.65px 10.75px;width:70%" id="sp1">Action&nbsp;Items</th>
              <th style="padding: 10.65px 10.75px;width:16%" id="sp2">Owner&nbsp;&nbsp;</th>
              <th class="text-center" style="padding: 10.65px 2pc 10.75px;width:15%" id="sp3">Target&nbsp;&nbsp;Date&nbsp;</th>
            </tr>
          </thead>
          <tbody style="font-family: 'Mulish', sans-serif;font-size: 14px;">
            <tr *ngFor="let action of mombyIddata.actionItem;let i=index">
              <td class="text-center" style="padding: 10.65px 10.75px;width:5%">{{i+1}}</td>
              <td style="padding: 10.65px 10.75px;width:70%" id="sp1">{{action.actionItemName}}</td>
              <td style="padding: 10.65px 10.75px;width:16%" id="sp2">{{action.actionItemOwner}}</td>
              <td class="text-center" style="padding: 10.65px 10.75px;width:15%" id="sp2">{{action.actionItemTargetDate | date}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
  
  