import { Component, OnInit } from '@angular/core';
import { GlobalserviceService } from '../providers/globalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeSettingsFormComponent } from '../leave/time-settings-form/time-settings-form.component';
import { SettingsService } from '../providers/settings.service';
import { HrmsService } from '../providers/hrms.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-businessgroup',
  templateUrl: './businessgroup.component.html',
  styleUrls: ['./businessgroup.component.scss'],
})
export class BusinessgroupComponent implements OnInit {
  submitted: boolean = false;
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  businessgroup: FormGroup;
  resultData: any;
  branchId: any;

  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;
  employeetData: any;
  isbtnDisable: boolean = false;
  businessId: any;
  listTypeValueId: number = 7;
  industryTypeListing: any[] = [];
  createBusinessGroup: any;
  industryTypeValueID: number;
  businessGroupId: string | null = null;
  storeEntityId:any;
  storeEntityName:any
isbtnDisables: boolean=true;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private gs: GlobalserviceService,
    private ActivatedRoute: ActivatedRoute,
    private settingService: SettingsService,
    private hrmsservice: HrmsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    this.storeEntityName=userdata.entity.entityName;
    console.log("entity Id:",this.storeEntityId);
    console.log("entity name:",this.storeEntityName);
    

    // if (userdata && userdata.entity) {
    //   // Patch the entity name to the form control and disable it
    //   // this.businessgroup.patchValue({
    //   //   entityName: userdata.entity.entityName
    //   // });
    //   this.businessgroup.controls['entityName'].setValue=this.storeEntityName
    // }
    
    this.settingService.getListTypeValues(this.listTypeValueId).subscribe((response: any) => {
      console.log('response', response);
      // this.industryTypeListing.push(response[0]);
      this.industryTypeListing = response;
      console.log('response', this.industryTypeListing);
    })
    this.businessgroup = this.formBuilder.group({
      entityName: [
        '',
        [
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      businessgroupName: [
        '',
        [
          Validators.required,
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      description: [
        null,
        [
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      industryType: ['', [Validators.required]],
    });

    this.route.paramMap.subscribe(params => {
      this.businessGroupId = params.get('id');
      console.log(this.businessGroupId);
    })

    if(this.businessGroupId){

    
      this.loader=true;
      this.isbtnDisable=true;


    this.hrmsservice.getBusinessGroupById(this.businessGroupId).subscribe((response: any) => {
      console.log('response', response);
      this.loader=false;
      this.isbtnDisable=false;

      console.log('industryType', response.industryType.listTypeValueId);
      this.businessgroup.patchValue({
        entityName: response.entity.entityName,
        businessgroupName: response.businessGroupName,
        description: response.businessGroupDescription,
        industryType: Number(response.industryType.listTypeValueId)
      })

      console.log('this.businessgroup', this.businessgroup.value);

    

    })

  }
  else{
       this.businessgroup.patchValue({
        entityName: userdata.entity.entityName
      });
  }



  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  get validate() {
    return this.businessgroup?.controls;
  }
  clearAll() {
    this.router.navigate(['/home/business-list']);
  }

  selectionChangeIndustryType(data: MatSelectChange) {
    console.log('value', data?.value);
    this.industryTypeValueID = data?.value;
  }
  onSubmit() {
    console.log('form values::', this.businessgroup.value);
    console.log('businessgroupName', this.businessgroup.value?.businessgroupName);
    this.submitted = true;
    this.businessgroup.markAllAsTouched();
    this.createBusinessGroup =
    {
      businessGroupName: this.businessgroup.value?.businessgroupName,
      businessGroupDescription: this.businessgroup.value?.description,
      industryType: {
        listTypeValueId: this.industryTypeValueID
      },
      entity: {
        entityId: this.storeEntityId
      }
    }

    console.log('createBusinessGroup', this.createBusinessGroup);
    if (this.businessgroup.invalid) {
      // this.isbtnDisable=true;
      return;
    }


    if (this.businessGroupId) {

      let body = {
        businessGroupName: this.businessgroup.value?.businessgroupName,
        businessGroupDescription: this.businessgroup.value?.description,
        industryType: {
          listTypeValueId: this.businessgroup.value?.industryType
        }
      }
      this.isbtnDisable=true

      this.hrmsservice.updateBusinessGroup(Number(this.businessGroupId), body).subscribe((response:any) => {
        this.isbtnDisable=true

        console.log('response', response);

        if (response.statusCode === 200) {

          this.success = true;
          this.isbtnDisable=true
          this.sucess_msg = response.description;
          setTimeout(() => {
            this.success = false;
            this.isbtnDisable=true

            this.sucess_msg = '';
            this.router.navigate(['/home/business-list']);
          }, 3000)
          

        }



      },((err) => {
        console.log(err);

        if (err.error.statusCode === 409) {

          this.error = true;
          this.isbtnDisable=false;

          this.error_msg = err.error.description;
          setTimeout(() => {
            this.error = false;
            this.error_msg = '';
          }, 3000)
        }}
    )
    )} else {
      this.isbtnDisable=true

      this.hrmsservice.createBusinessGroup(this.createBusinessGroup).subscribe((response: any) => {

        console.log('after creating business group', response);

        if (response.statusCode === 200) {

          this.success = true;
          this.isbtnDisable=true

          this.sucess_msg = response.description;
          setTimeout(() => {
            this.success = false;
            this.sucess_msg = '';
            this.router.navigate(['/home/business-list']);
          }, 3000)
         
        }




      }, ((err) => {
        console.log(err);

        if (err.error.statusCode === 409) {

          this.error = true;
          this.isbtnDisable=false;

          this.error_msg = err.error.description;
          setTimeout(() => {
            this.error = false;
            this.error_msg = '';
          }, 3000)
        }
        // this.error = true;
        // this.error_msg = err.error;
        // setTimeout(()=>{
        //   this.error = false;
        //   this.error_msg = '';
        // },3000)

      }))
    }


  }
}
