<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <div>
      <h4 class="font-weight-bold consolidate-timesheet">Employee Timesheet</h4>
    </div>
    <div>

      <mat-icon class="material-icons-outlined remove" (click)="remove()" style="color: red !important;">
        highlight_off
      </mat-icon>
    </div>
  </div>

  <div class="mt-7" style="margin-top: 10px" *ngIf="showTable">

    <mat-card class="card-content " >
      <div class="card-body">
        <div *ngIf="timesheet.length > 0">
        <div class="row m-2">
      
          <ng-container >
            <div class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9">
              <span class="badge badge-light totaltime"
                >Total Time : {{ totalTime }}</span
              >
            </div>
          </ng-container>
          <ng-template #alignend>
            <div class="col-xl-12 col-lg-12">
              <span class="badge badge-light totaltime"
                >Total Time : {{ tasktotalTime }}</span
              >
            </div>
          </ng-template>
    
  
          <div
            class="col-xl-2 col-lg-3 col-md-3 col-sm-4" align="right"
        
          >
            <div class="select-pagination"         *ngIf="timesheet.length > 5">
              <span>Show : </span>
              <select
                class="select_list new-select_list listDesign"
                [(ngModel)]="mySelect1"
                (change)="changefirstpage1()"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>
      </div>
   
        <div class="table-responsive mt-3 p-3" >
          <table class="table" matSort (matSortChange)="sortData($event)">
            <thead>
              <tr class="tr">
                <th mat-sort-header="sI" scope="col">
                  SI&nbsp;No                </th>
                <th mat-sort-header="employeeName" scope="col">
                  Employee&nbsp;Name
                </th>
                <th mat-sort-header="projectName" scope="col">
                  Project&nbsp;Name
                </th>
                <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
                <th
                  mat-sort-header="date"
                  scope="col"
                  style="text-align: center"
                >
                  Date
                </th>

                <th
                  mat-sort-header="workedHours"
                  scope="col"
                  style="text-align: center"
                >
                  Worked&nbsp;Hours
                </th>
                <th
                  mat-sort-header="taskStatusName"
                  scope="col"
                  style="text-align: center"
                >
                  Task&nbsp;Status
                </th>
                <th
                  mat-sort-header="approvalStatus"
                  scope="col"
                  style="text-align: center"
                >
                  Approval &nbsp; Status
                </th>

                <th style="text-align: center" scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
            
              <tr
                *ngFor="
                  let e of timesheet
                  | customPaginate  : { itemsPerPage: mySelect1, currentPage: p1 };
                  let i = index
                "
              >
              <td style="text-align: center">
                {{ (p1 ? (p1 - 1) * mySelect1 : 0) + i + 1 }}
              </td>
                <td>
                  {{ e.employee.firstName | titlecase }}
                </td>
                <td>{{ e.project?.projectName | titlecase }}</td>
                <td>{{ e.task?.taskName | titlecase }}</td>
                <td style="text-align: center; padding-right: 20px">
                  {{ e.date }}
                </td>

                <td style="text-align: center; padding-right: 30px">
                  {{ e.workedHours }}
                </td>
                <td
                  style="text-align: center; padding-right: 30px"
                  [style.color]="withDrawStatus(e)"
                >
                  {{ e?.task?.taskStatusName }}
                </td>
                <td
                  style="text-align: center; padding-right: 30px"
                  [style.color]="approvalStatus(e)"
                >
                  {{ e?.approvalStatusName }}
                </td>

                <td class="more" align="center">
                  <span
                    class="material-icons cursor"
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    more_vert
                  </span>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="description(e)">
                      <mat-icon class="edit-icon">date_range</mat-icon>
                      <span class="edit-form " style="margin-top: 10px !important">View Description</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>

              <td style="text-align: center; font-size: larger; font-weight: bold;" 
              colspan="11"  *ngIf="nodata  || timesheet.length == 0"  >Employee Task Report Not Found!</td>
            </tbody>
          </table>
          <div         *ngIf="timesheet.length > 5">
          <pagination-controls
          #timesheets
          (pageChange)="p1 = $event"
          class="text-right"
          style="float: right"
  
          align="end"
        ></pagination-controls>
      </div>
        </div>
      
       
      </div>

   
    </mat-card>
   
  </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
