<style>
  .full-width{
    width:100%;
  }
</style>
<div class="container">
  <div class="form-group">
    <form [formGroup]="holidayFrom" (ngSubmit)="onSubmit()">
      <!-- create  -->
      <div class="form-group" *ngIf="!data.id">
       
          <label class="col-form-label">Leave Type<span class="star">*</span></label>
          <select (change)="holiClick($event)" formControlName="leaveType" placeholder="- Select Holiday -" class="form-select form-select-solid form-select-lg select2-new">
              <option [value]="null"> --- Select Holiday --- </option>
              <option value="0" >Holiday</option>
              <option value="1">Exception</option>
          </select>       
          <div class="valid-msg" *ngIf="(f.leaveType.invalid && f.leaveType.touched) || f.leaveType.dirty">
            <span  *ngIf="f.leaveType.hasError('required')" class="text-danger">Please select  event type</span>
            
        </div> 
           
      </div>
      
      <div class="form-group">
        <label class="col-form-label">Holiday Name<span class="star">*</span></label>
  <input type="text" formControlName="holidayName" id="holidayName"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 only_name" placeholder="Holiday Name" autocomplete="off">
  <div class="valid-msg" *ngIf="(f.holidayName.invalid && f.holidayName.touched) || f.holidayName.dirty">
      <span *ngIf="f.holidayName.errors?.required" class="text-danger">Please enter calendar name</span>
      
  </div>
  <!-- <span *ngIf="calendarnameexist" class="text-danger">{{existMsg}}</span> -->
         
     
      </div>

      <div class="form-group" *ngIf="holiday">
        
        <label class="col-form-label">Holiday Type<span class="star">*</span></label>
          <select formControlName="holidaytype" class="form-select form-select-solid form-select-lg select2-new">
            <option [value]="null"> --- Select Holiday Type --- </option>
            <option *ngFor="let type of holidayType" [value]="type.listTypeValueId">
              {{type.listTypeValueName}}
            <option>
          </select>
          <mat-error *ngIf="f.holidaytype.hasError('required')">
            <span>Holiday Type is required</span>
          </mat-error>
         
      </div>

      <div class="form-group" *ngIf="exception">
        <label class="col-form-label">Exception Type<span class="star">*</span></label>
          <select formControlName="holidaytype"  class="form-select form-select-solid form-select-lg select2-new">
            <option [value]="null"> --- Select Exception Type --- </option>
            <option *ngFor="let type of exceptionType" [value]="type.listTypeValueId" >
              {{type.listTypeValueName}}
            </option>
          </select>
          <mat-error *ngIf="f.holidaytype.hasError('required')">
            <span>Exception Type is required</span>
          </mat-error>
     
      </div>
      <div class="form-group">    
        <label class="col-form-label">Holiday Date <span class="star">*</span></label>
        <input formControlName="holidayDate" id="holidayDate" matInput [matDatepicker]="holidayDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" 
            placeholder="Select Holiday Date "  >
        <mat-datepicker-toggle matSuffix [for]="holidayDate" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
        <mat-datepicker #holidayDate   
        panelClass="example-month-picker"></mat-datepicker>
          <mat-error *ngIf=" f.holidayDate.hasError('required')">
            Please select <strong>Holiday Date</strong>
          </mat-error>
    
      </div>

      <div class="form-group" *ngIf="data.id">
        <label class="col-form-label">Holiday Status<span class="star">*</span></label>
          <select formControlName="holidayStatus" class="form-select form-select-solid form-select-lg select2-new">
            <option value="1">
              Active
            </option>
            <option value="2">
              In-Active
            </option>
          </select>
          <mat-error *ngIf="f.holidayStatus.hasError('required')">
            <span>Holiday Status is required</span>
          </mat-error>
      
      </div>

      <div class="form-group">
        <button mat-stroked-button color="accent" style="margin: 0 0 0 5px" type="button"
        mat-dialog-close>
          Cancel
        </button>
        <!-- <button mat-stroked-button color="primary" style="margin: 0 0 0 5px" type="submit">
          <span class="material-icons"> beenhere </span>
          {{data.id ? 'Update' :'Create'}}
        </button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
        <!-- <button mat-stroked-button color="warn" style="margin: 0 0 0 5px" type="button"
         *ngIf="data.id" (click)="delete(data)">
          <span class="material-icons"> delete </span>
          Delete
        </button> -->

      </div>

    </form>
  </div>
</div>
