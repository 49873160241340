import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeavemessageService {
  private successMessageSubject = new BehaviorSubject<string>('');
  private errorMessageSubject = new BehaviorSubject<string>('');

  
  constructor() { }
  setSuccessMessage(message: string) {
    this.successMessageSubject.next(message);
    console.log('Message from service:', message);

    
  }

  getSuccessMessage() {
    return this.successMessageSubject.asObservable();
  }

  setErrorMessage(message: string){
    this.errorMessageSubject.next(message);
    console.log('Message from service:', message);

  }

  getErrorMessage() {
    return this.errorMessageSubject.asObservable();
  }
  
}



