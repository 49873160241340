<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Add Client Employee </span>
        </div>
        <div class="sub-header-buttons">
          <div class="topnav-right">
            <button
              (click)="Add_AssignEmployeclient()"
              mat-button
              class="mat-focus-indicator ml-2 mat-raised-button mat-button-base"
              style="background: #1a83ff; color: #fff"
            >
              Add Client Employee
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content" style="margin-top: 5px !important">
  <div class="card-new " *ngIf="showtable">
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="showupdate == true">
          <div class="container">
            <form [formGroup]="employeeClientForm" (ngSubmit)="submit()"  *ngIf="showForm">
              <!-- Task Type -->
              <div class="row">
                <div class="col-md-3">
                  <div class="input-width" id="loginform">
                    <label class="form-control-label"
                      >Employee<span class="text-danger"> *</span></label
                    >
                    <input
                      formControlName="employee"
                      class="form-control email-input select-placeholder"
                      placeholder="Select employee"
                      [disabled]="empolyeereadonly"
                      [readonly]="readonly"
                      style="background: #817d7d26 !important"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <!-- <mat-option value="All" (click)="empAllSelect()"  [disabled]="empolyeereadonly || disableFeildforAll">All</mat-option> -->
                      <mat-option
                        *ngFor="let emp of empList"
                        [value]="emp.employeeId"
                        (click)="empSelect(emp)"
                      >
                        {{ emp.employeeNumber }}- {{ emp.firstName }}
                      </mat-option>
                      <mat-option
                        [value]="null"
                        *ngIf="employeeNotFound"
                        (click)="noEmployeeFound()"
                        >No Employee Found</mat-option
                      >
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="
                        f.employee.invalid &&
                        submitted1 &&
                        selectedEmployeeData.length == 0
                      "
                      >Employee is required</mat-error
                    ><br />
                    <span *ngIf="empolyeeapproverexist" class="span-error">
                      Approver Already Exist</span
                    >
                  </div>
                </div>

                <div class="col-md-3">
                  <!-- Task Type -->
                  <div class="input-width" id="loginform">
                    <label class="form-control-label"
                      >Client Name<span class="text-danger"> *</span></label
                    >
                    <mat-select
                      formControlName="clientName"
                      placeholder="Select Client Name"
                      class="form-control email-input select-placeholder"
                    >
                    <mat-option [value]="undefined" selected="selected">-Select Client Name-</mat-option>
                    <mat-option
                        *ngFor="let client of clientLists"
                        [value]="client.clientId"
                      >
                        {{ client.clientCode }}-{{ client.clientName }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="f.clientName?.invalid && submitted1"
                      >Client Name is required</mat-error
                    > -->
                    <div
                    class="valid-msg"
                    *ngIf="
                      (f.clientName?.invalid &&
                        f.clientName?.touched) ||
                      f.clientName?.dirty ||
                      submitted
                    "
                  >
                    <span
                      *ngIf="f.clientName?.errors?.required"
                      class="text-danger"
                      >Please Select Client Name</span
                    >
                  </div>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 mx-2">
                  <div
                    class="row buttons mx-2 rowDesign"
                    style="position: relative; top: 28px"
                  >
                    <div class="col-md-4 col-lg-6 col-xl-6">
                      <button
                        (click)="clearForm()"
                        mat-raised-button
                        [disabled]="btnDisable"
                        class="mr-2 btnCancel"
                        style="height: 35px; position: relative; left: 16px"
                      >
                        Clear
                      </button>
                    </div>
                    <div class="col-md-4 col-lg-6 col-xl-6">
                      <button
                        type="submit"
                        class="search-btn mat-raised-button"
                        style="height: 35px"
                        [disabled]="btnDisable"
                        mat-raised-button
                        [ngStyle]="{
                          position: 'relative',
                          left: '38px',
                          height: '35px',
                          top: '0px',
                          color: btnDisable ? 'black' : 'white',
                          'background-color': btnDisable
                            ? '#0000001f'
                            : '#1a83ff'
                        }"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="clientList?.length > 0">
          <div class="col-xl-12 col-lg-12" *ngIf="clientList?.length > 5">
            <div class="select-pagination" style="    float: inline-end;
            top: -9px;
            position: relative;
            right: 8px;">
              <span style="    font-size: 15px;">Show : </span>
              <select style="    border-radius: 5px;"
                class="select_list new-select_list"
                [(ngModel)]="mySelect"
                (change)="changefirstpage()"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>

        <div
          class="table"
         
        >
          <!-- <div > -->
          <table
            class="table"
            matSort
          >
            <thead class="t-head">
              <tr>
                <th class="text-center table-data" style="border-radius: 15px 1px 0px 0">Sl.No</th>
                <th class="text-center table-data">Client&nbsp;Code</th>
                <th class="text-center table-data">Client&nbsp;Name</th>
                <th class="text-center table-data">Employee Code</th>
                <th class="text-center table-data">Employee Name</th>
                <th class="text-center table-data" style="border-radius: 0px 15px 0px 0px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let client of clientList
                    | mytask : search
                    | paginate : { itemsPerPage: mySelect, currentPage: p };
                  let i = index
                "
              >
                <td class="text-center table-data" *ngIf="p">
                  {{ (p - 1) * mySelect + (i + 1) }}
                </td>
                <td class="text-center table-data" *ngIf="!p">{{ i + 1 }}</td>
                <td class="text-center table-data">
                  {{ client.client?.clientCode }}
                </td>
                <td class="text-center table-data">
                  {{ client.client?.clientName }}
                </td>
                <td class="text-center table-data">
                  {{ client.employee?.employeeNumber }}
                </td>
                <td class="text-center table-data">
                  {{ client.employee?.firstName }}
                </td>

                <td class="text-center table-data">
                  <mat-icon
                    class="edit-icon"
                    (click)="updateassignClientEmp(client)"
                    >edit</mat-icon
                  >
                </td>
              </tr>
            </tbody>
            <!-- <tbody>
                              <tr>
                                  <td style="text-align: center;" colspan="10" *ngIf="nodata">No Records Found!</td>
                              </tr>
                          </tbody> -->
            <tbody
              style="border-left: 1px solid #ddd; border-right: 1px solid #ddd"
            >
              <tr>
                <td
                  style="
                    text-align: center;
                    padding: 10px;
                    font-size: 19px;
                    font-weight: 600;
                    font-family: 'Mulish', sans-serif;
                    left: 5;
                    position: relative;
                  "
                  colspan="10"
                  *ngIf="nodata || clientList?.length == 0"
                >
                  Add Client Emplyoee Records Not Found!
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>  -->
        </div>

        <!-- <div class="row topnav-right pagination-content" *ngIf="clientList?.length > 5">        
                  <div class=" text-right">
                      <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                  </div>
              </div>  -->
        <div
          class="pagination-content"
          *ngIf="
            clientList?.length > 5 &&
            (clientList | mytask : search).length > 0 &&
            showtable
          "
          id="page"
        >
          <pagination-controls
            *ngIf="(clientList | mytask : search).length > 5"
            style="position: relative; float: inline-end; top: 7px"
            (pageChange)="p = $event"
            align="end"
          >
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="resultData?.length > 0 ">
  <div class="row">
    <div class="col-md-4">
      <div class="showing-record">
        {{resultData.length}} rows
      </div>
    </div>
    <div class="col-md-8 text-right">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</div> -->
<!-- </div>
</div>
</div>
</div> -->
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border table-spinner"
    style="
      height: 3rem;
      width: 3rem;
      color: #009ef7;
      position: absolute;
      z-index: 1;
      top: 49%;
      right: 48%;
    "
    role="status"
  ></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button *ngIf="importerror" class="btn btn-danger">View Error Records</button>
</div>
