import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AddassignapproverComponent } from '../addassignapprover/addassignapprover.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';

@Component({
  selector: 'app-update-assign',
  templateUrl: './update-assign.component.html',
  styleUrls: ['./update-assign.component.scss']
})
export class UpdateAssignComponent implements OnInit {

  projectApproverForm: FormGroup;
  projectList: any = [];
  ApproverList: any = [];
  empId :any;
  projectreadonly :boolean ;
  projectapproverobject: any = {};
  projectapproverexist: boolean;
  ruleNameexist: boolean;
  organzationId :any;
  submitted1=false;
  eValue: any;
  editData: any;
  constructor(public dialogRef: MatDialogRef<AddassignapproverComponent>,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private gs: ProjectManagementService , private dialog:MatDialog,
    private route:ActivatedRoute ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.eValue =params['projectapproverId'];
      this.editData = JSON.parse(params['approver'])     
       console.log("Value of 'approver' in add-assaignercompon:", this.eValue);
      console.log("this.editData response::",this.editData);
      
  
      // Now you can use 'eValue' as needed in this component
    });
  
    this.route.params.subscribe(params => {
      this.eValue =params['projectapproverId'];
      console.log("Value of 'approver' in add-assaignercompon:", this.eValue);
  
      // Now you can use 'eValue' as needed in this component
    });

    const Data = JSON.parse(localStorage.getItem('enoteUserData'))
    this.organzationId = Data.organization.organizationId;
    console.log("organziationid " + this.organzationId);
   
    this.projectApproverForm = this.fb.group({
      // organization: {
      //   organizationId:  this.organzationId
      // },
      project: ['', Validators.required],
      approver: ['', Validators.required],
      approverType:['',Validators.required]
    })
  console.log("data::",this.data);
  

    console.log("create project apporver id : " + this.eValue);

    
    this.empId = Data.employeeId

    // if(this.eValue){

    if(this.eValue){
      console.log("if entered....!");
             this.projectreadonly = true;
                   this.projectApproverForm.patchValue({
                    approver: this.data.approvers,
                    project : this.data.projectId,
                    approverType:this.editData.approvers.
                    approverTypeName
                    // approver : this.data.project.projectId

          })
     }
    this.gs.getProjectList().subscribe(
      (d: any[]) => {
        console.log("project list::",d);
        console.log("this project list::",this.projectList);
        console.log(this.data.approvers);
        console.log(this.data.projectId);
        
        
        
        
        this.projectList = d.sort((a, b) => a.projectName.localeCompare(b.projectName))
        console.log("this.projectList::",this.projectList);
        
        console.log("if condition checkk::",this.data.projectapproverId);
        
             if(this.eValue){
              console.log("if entered....!");
                     this.projectreadonly = true;
                           this.projectApproverForm.patchValue({
                            approver: this.data.approvers,
                            project : this.data.projectId,
                            approverType:this.editData.approvers.
                    approverTypeId
                            // approver : this.data.project.projectId
        
                  })
             }
      }
    )
    // }
    this.gs.getApprovers().subscribe(
      (d: any[]) => {
        console.log("approver response::",d);
        
        this.ApproverList = d.sort((a, b) => a.rulename.localeCompare(b.rulename))
      }
    )
}

  //control values of form values
  get f() {
    return this.projectApproverForm.controls;
  }
  resetForm() {
    this.projectApproverForm=undefined
   
 }
  Addprojectapprover()
  {
    this.submitted1=true;
        if(this.projectApproverForm.valid && this.projectapproverexist == true)
        {
          return
        }

      // console.log("form sumbitted");
        // return
    let proappform = this.projectApproverForm.value;
    console.log(proappform)

      if(this.data.projectApproverId)
      { 
        
        console.log("update form ")
        console.log(proappform)     
        var projectapproverupdate  = { 
          projectApproverId :this.editData.projectapproverId,
          projectApproverStatus:{
            listTypeValueId:  this.editData.projectApproverStatus},
          projectId: proappform.project,
          approverType: {
            approverTypeId: this.editData.approverType.approverTypeId
          },
          approvers:{
            approverId: proappform.approver

          }
              
            }

            console.log("projectapproverupdate::",projectapproverupdate);


            this.gs.updateProjectApprover(this.eValue,projectapproverupdate).subscribe(a => {
              Swal.fire({
                icon: 'success',
                text: "Project Approver updated Successfully"
              })
              // window.location.reload()
                // this.dialog.closeAll()
                this.router.navigate[('home/assign-approver')]
            } ,
            err =>{
              Swal.fire({
                icon: 'error',
                text: err.error.description
              })
            }
            )
      }else
      {
            var projectapprover  = { 
              
                      projectId: proappform.project, 
                       approvers:{
                      approverId: proappform.approver
                    }	,
                      projectApproverStatus:{
                        listTypeValueId:1,
                        listTypeValueName:"Active"

                      },
                      approverType:{
                        approverTypeId:4
                      },
                           
                  }	
                
                    
                
                console.log("projectapprover payload::",projectapprover);
            this.gs.createProjectApprover(projectapprover).subscribe(a => {
              Swal.fire({
                icon: 'success',
                text: "Project Approver Created Successfully"
              })
              this.router.navigate(['/home/assign-approver']);
              // window.location.reload()
                // this.dialog.closeAll()
            } ,
            err =>{
              Swal.fire({
                icon: 'error',
                text: err.error.message
              })
            }
            )
      }
      
  }

  projectapprovervalidate(){


    console.log("project : " +  this.projectApproverForm.value.project);
  
    if(!this.data.projectApproverId)
    {
        if(this.projectApproverForm.value.project != 0)
        {
           
          this.gs.getprojectApprovers(this.projectApproverForm.value.project).subscribe(a => {
              console.log(a);
              this.projectapproverobject = a;
                if(this.projectapproverobject.isexist)
                {
                    console.log("role name isexist");
                    this.projectapproverexist = true;
                }else{
                  this.projectapproverexist = false;
                }
          })
        }else{
          this.projectapproverexist = false;
        }
    }
   
   }


}
