import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import Swal from 'sweetalert2';
import { ProjectManagementService } from '../../../../app/providers/project-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
// import { redirectMsgTimeOut, responseMsgTimeOut } from '../../../app/providers/properties';
import { ShowErrorComponent } from '../../../../app/project-management/project-management/show-error/show-error.component';
import { MatDialog } from '@angular/material/dialog';
import { NgZone } from '@angular/core';
import { HeaderService } from '../../../../app/providers/header.service';
import { boolean } from 'mathjs';
import { TimesheetService } from '../../../../app/providers/timesheet.service';
import { AssignEmpService } from '../../../../app/providers/assign-emp.service';
import { RecruitmentService } from 'src/app/providers/recruitment.service';

@Component({
  selector: 'app-createmom',
  templateUrl: './createmom.component.html',
  styleUrls: ['./createmom.component.scss']
})
export class CreatemomComponent implements OnInit {
  yourPayloadVariable: any;
  submitted:boolean = false;
  myForm: FormGroup;
  @ViewChild('momNameInput') momNameInput: ElementRef;

  @ViewChild('select') select: MatSelect;
  @Input() payload: any;
  allSelected=false;
  employeeList:any = []
  myProject = [
    {
      id:1,
      value:'Project'
    },
    {
      id:2,
      value:'Non Project'
    }
  ]
  momId:any
  projectList:any = []
  isShow:Boolean = false;
  tomorrow = new Date();
  User_Type: any;
  customerList: any[]=[];
  momdate: any;
  modifiedmomdate: string;
  modifiedTargetDate: string;
  isbuttondisable:boolean=false
con: any;
i: string|number;
  formattedDate: any;
  discussedPoints: void;
  datats: any;
  success:boolean=false;
  sucess_msg:any;
  error:any=false;
  errorMsg: any;
error_msg: any;
importerror: boolean = false;
  errormessagejson: any;
  usertData: any = '';
  userType: any;
  organizationId: any;

  constructor(private gs:ProjectManagementService,
              private fb:FormBuilder,
              private route:ActivatedRoute,
              private router:Router,
              private datepipe : DatePipe,
              private dialog:MatDialog,
              private zone: NgZone,
              private headerservice:HeaderService,
              private timesheet: TimesheetService,
              private assignemp: AssignEmpService,
              private positionservice: RecruitmentService
              ) {
              }
              

  ngOnInit() {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('local storage data::', this.usertData);

    this.userType = [];
    this.usertData.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    this.organizationId = this.usertData.organization.organizationId;


    this.positionservice
    .getclientlist(this.organizationId)
    .subscribe((resultData: any) => {
      console.log(' resultData', resultData);
      // this.clientListData = resultData;
      this.customerList = resultData;
    });
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.assignemp.setSuccessUpdateMessage(
      ''
    );
    this.timesheet.setSuccessUpdateMessage(
      ''
    );
    // this.headerservice.setTitle('Create MOM');
    const myData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("myData::",myData);
    this.gs.getResourceByIds(myData.userId).subscribe(
      (x:any)=>{
        // this.User_Type = x.userType.userType;
        console.log("user Type ID::",this.User_Type)
        
      }
    )
    
    // this.gs.Get_Customer().subscribe((x:any)=>{
    //   this.customerList = x;
    //   console.log("Customer list::",this.customerList)
    // })
    this.route.paramMap.subscribe(
      d=>{
        this.momId = d.get('id')
        if(this.momId){
          this.myForm = this.fb.group({
            // momName : new FormControl(this.myForm,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),

            // momName: ['', [Validators.required]],
            // momName : new FormControl(this.myForm,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
            // momName: new FormControl('', [
            //   Validators.required,
            //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
            // ]),
            momName: new FormControl(this.myForm, [
              Validators.required,
              Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
            ]),
          //   momAttendees:['',Validators.required,
          //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
          // ],
          momAttendees: new FormControl(this.myForm, [
            Validators.required,
            Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
          ]),
            client:['',Validators.required],
            meetingPlace: new FormControl(this.myForm, [
              Validators.required,
              Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
            ]),
            momDate:['',Validators.required],
            projectValue:['',Validators.required],
            project: ['',[ Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
            pointsDiscussed: this.fb.array([
              // Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),

            ]),
            pointsAgreed:this.fb.array([
              // Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),

            ]),
            actionItem:this.fb.array([
              // Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),

            ])
          });
          
        
          this.gs.get_momById(this.momId).subscribe(
            (data:any)=>{
              if(data.project){
                  this.isShow = true
                  this.myForm.patchValue({
                   
                    momName:data.momName,
                    momAttendees:data.momAttendees,
                    momDate:data.momDate,
                    client:data.e.clientId,
                    projectValue:1,
                    project:data.project.projectId,
                    meetingPlace:data.meetingPlace,

                    pointsDiscussed:data.pointsDiscussed.forEach(
                      e=>{
                        this.content().push(
                          this.fb.group({
                            // pointsDiscussedId:e.pointsDiscussedId,
                            pointsDiscussedDetails: e.pointsDiscussedDetails,
                            status:{
                              listTypeValueId:1,
                              listTypeValueName:"Active"                        
                              }

                          })
                        )
                      }
                    ),
                    pointsAgreed:data.pointsAgreed.forEach(
                      e=>{
                        this.agree().push(
                          this.fb.group({
                            // pointsAgreedId:e.pointsAgreedId,
                            pointsAgreedDetails: e.pointsAgreedDetails,
                            status:{
                              listTypeValueId:1,
                              listTypeValueName:"Active"
                            }
                            // status:e.status

                          })
                        )
                      }
                    ),
                    actionItem:data.actionItem.forEach(
                      e=>{
                        this.actionItem().push(
                          this.fb.group({
                            actionItemId:e.actionItemId,
                            actionItemName:e.actionItemName,
                            actionItemTargetDate:e.actionItemTargetDate,
                            actionItemOwner:e.actionItemOwner,
                            actionItemStatus:e.actionItemStatus,
                            status:{
                              listTypeValueId:1,
                              listTypeValueName:"Active"
                            }     
                            // status:e.status
                   
                          })
                        )
                      }
                    )
                  })
              }
              else{
                this.myForm.patchValue({
                  momName:data.momName,
                  momAttendees:data.momAttendees,
                  momDate:data.momDate,
                  client:data.e.clientId,
                  projectValue:2,
                  project:'',
                  meetingPlace:data.meetingPlace,
                  pointsDiscussed:data.pointsDiscussed.forEach(
                    e=>{
                      this.content().push(
                        this.fb.group({
                          pointsDiscussedId:e.pointsDiscussedId,
                          pointsDiscussedDetails: e.pointsDiscussedDetails,
                          // status:e.status
                          status:{
                            listTypeValueId:1,
                            listTypeValueName:"Active"
                          }

                        })
                      )
                    }
                  ),
                  pointsAgreed:data.pointsAgreed.forEach(
                    e=>{
                      this.agree().push(
                        this.fb.group({
                          // pointsAgreedId:e.pointsAgreedId,
                          pointsAgreedDetails: e.pointsAgreedDetails,
                          status:{
                            listTypeValueId:1,
                            listTypeValueName:"Active"
                          }
                          // status:e.status

                        })
                      )
                    }
                  ),
                  actionItem:data.actionItem.forEach(
                    e=>{
                      this.actionItem().push(
                        this.fb.group({
                          actionItemId:e.actionItemId,
                          actionItemName:e.actionItemName,
                          actionItemTargetDate:e.actionItemTargetDate,
                          actionItemOwner:e.actionItemOwner,
                          // actionItemStatus:{
                          //   listTypeValueId:3,
                          //   listTypeValueName:"pending"
                          // },
                          actionItemStatus:e.actionItemStatus,
                          status:{
                            listTypeValueId:1,
                            listTypeValueName:"Active"
                          }
                          // status:e.status

                        })
                      )
                    }
                  )
                
                })
              }
            }
          )
        }
        else{
          this.myForm = this.fb.group({
            // momName:['',Validators.required],
            momName: new FormControl(this.myForm, [
              Validators.required,
              Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]),
            momAttendees: new FormControl(this.myForm, [
              Validators.required,
              Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]),
            client:['',Validators.required],
            meetingPlace: new FormControl(this.myForm, [
              Validators.required,
              Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]),
            momDate:['',Validators.required],
            projectValue:['',Validators.required],
            project: ['',[Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
            pointsDiscussed: this.fb.array([

              this.newContent()
            ]),
            pointsAgreed:this.fb.array([
              // Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),

              this.newAgree()
            ]),
            actionItem:this.fb.array([
              // Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),

              // this.newAction()
            ])
          });
        }
      }
    )

  
    this.gs.getEmpProjectList(myData.organization.organizationId)
    .subscribe((pa:any)=>{
      this.projectList = pa;
      console.log("project_employee list::",this.projectList);

      this.projectList.forEach((project:any)=>{
        // console.log("projectName::",project.projectName);
      })
    });
  }
  // setProjects(x) {
  //   let arr = new FormArray([]);
  //   x.subContent.forEach(y => {
  //     arr.push(
  //       this.fb.group({
  //         subContentId:y.subContentId,
  //         subContentTitle: y.subContentTitle
  //       })
  //     );
  //   });
  //   return arr;
  // }
  
  
  content(): FormArray {
    return this.myForm.get('pointsDiscussed') as FormArray;
  }

  agree(): FormArray {
    return this.myForm.get('pointsAgreed') as FormArray;
  }

  actionItem(): FormArray {
    return this.myForm.get('actionItem') as FormArray;
  }

  newContent(): FormGroup {
    return this.fb.group({
      pointsDiscussedDetails: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      // pointsDiscussedDetails: new FormControl(this.i, [
      //   Validators.required,
      //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      // ]),
    });
  }
  newAgree(): FormGroup {
    return this.fb.group({
      // pointsAgreedDetails: new FormControl(this.myForm, [
      //   Validators.required,
      //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      // ]),
      pointsAgreedDetails: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      
    });
  }
 

  
  
  newAction(): FormGroup {
    return this.fb.group({
      actionItemName: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      // actionItemName: new FormControl(this.i, [
      //   Validators.required,
      //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      // ]),
      actionItemTargetDate:['',Validators.required],
      actionItemOwner: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      actionItemStatus:[''],
      // actionItemStatus: this.fb.group({
      //   listTypeValueId: 3, 
      //   listTypeValueName: 'Pending'  
      // }),
      status:{
        listTypeValueId: 1, 
        listTypeValueName: 'Active'  
      }
    });
  }

  get f(){
    return this.myForm.controls;
  }
  showerrormessage()
  {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  add() {
    this.content().push(this.newContent());
  }

  addAgree(){
    this.agree().push(this.newAgree());
  }
  dateChange(event) {
    console.log(event.value);
    this.momdate =new Date(event.value);

  // Format the date to yyyy/mm/dd
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  this.formattedDate = this.momdate.toLocaleDateString('en-US', options);

  console.log("mom-Date::", this.formattedDate);
    // console.log("mom-Date::",this.momdate);
  }
  onChange(e){
    this.myForm.get('project').reset();

    if (e == 1) {
      this.isShow = true;
      this.myForm.get('project').setValidators([Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]);
    } else {
      this.isShow = false;
      this.myForm.get('project').clearValidators();
    }
    // Trigger validation update after modifying validators
    this.myForm.get('project').updateValueAndValidity();
  }

  get momName() {
    return this.myForm.get('momName');
  }
  get momAttendees() {
    return this.myForm.get('momAttendees');
  }
  get pointsDiscussedDetails() {
    return this.myForm.get('pointsDiscussedDetails');
  } 
  get pointsAgreedDetails() {
    return this.myForm.get('pointsAgreedDetails');
  }
  get actionItemName() {
    return this.myForm.get('actionItemName');
  }
  get actionItemOwner() {
    return this.myForm.get('actionItemOwner');
  }
  get meetingPlace() {
    return this.myForm.get('meetingPlace');
  }
  get project() {
    return this.myForm.get('project');
  }

  remove(index: number) {
    if(index == 0){
      return
    }
    else{
      this.content().removeAt(index);
    }
  }

  addItem() {
    this.actionItem().push(this.newAction());
  }

  removeItem(index: number) {
    // if(index == 0){
    //   return
    // }
    // else{
      this.actionItem().removeAt(index);
    // }
  }

  removeAgree(index: number) {
    if(index == 0){
      return
    }
    else{
      this.agree().removeAt(index);
    }
  }

  // myContent(index: number): FormArray {
  //   return this.content()
  //     .at(index)
  //     .get('subContent') as FormArray;
  // }

  // newSkill(): FormGroup {
  //   return this.fb.group({
  //     subContentTitle: ['',Validators.required],
  //   });
  // }

  keyPress(event: any) {
    // const pattern = /^[a-zA-Z0-9_ .-]*$/;
    // const inputChar = String.fromCharCode(event.charCode);
    // if (event.keyCode !== 8 && !pattern.test(inputChar)) {
    //   event.preventDefault();
    // }
    const pattern = /^[a-zA-Z0-9_.-\s]*$/; // Updated pattern to allow spaces and tabs
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  // addsubContent(index: number) {
  //   this.myContent(index).push(this.newSkill());
  // }

  // removesubContent(index: number, i: number) {
  //   this.myContent(index).removeAt(i);
  // }

  cancel(){
    this.router.navigate(['/home/mom-reports'])
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  // addEmpList(){
  //   let x = this.myForm.value;
  //   if(x.momAttendees == ''){
  //     alert("Please select the options")
  //   }
  //   else{
  //     this.select.close()
  //   }
  // }

  // cancel(){
  //   this.myForm.patchValue({momAttendees:''})
  //   this.select.close()
  // }

  onSubmit() {
    console.log("submt()");
    // this.isbuttondisable=false
    // this.submitted=false;
    this.submitted = true;
    // event.preventDefault();

    console.log(this.myForm); 

    // this.submitted=false;
    if(this.myForm.valid){

      this.submitted=false;
      this.isbuttondisable = true;

      this.modifiedmomdate= this.datepipe.transform(this.myForm.value.momDate, 'YYYY-MM-dd');
    this.myForm.value['momDate'] =this.modifiedmomdate;
      let x = this.myForm.value;
      this.datats=this.myForm.value

      // x.momDate=this.formattedDate
      // console.log("momDate::",x.momDate);
      console.log("this.modifiedmomdate",this.modifiedmomdate);
      this.discussedPoints=x.pointsAgreedDetails
      console.log("this.discussedPoints::",this.discussedPoints);
      
      console.log("Form Values::",x);

      if (x.projectValue ==2) {
        const payload={
          momName:x.momName,                    
          momAttendees:x.momAttendees,
          clientId:x.client,
          meetingPlace:x.meetingPlace,
          momDate:this.modifiedmomdate,
          pointsAgreed: x.pointsAgreed.map(agreed => ({
            pointsAgreedDetails: agreed.pointsAgreedDetails,
            status: {
              listTypeValueId: 1,
              listTypeValueName: "Active",
            }
          })),
          pointsDiscussed: x.pointsDiscussed.map(discussed => ({
            pointsDiscussedDetails: discussed.pointsDiscussedDetails,
            status: {
              listTypeValueId: 1,
              listTypeValueName: "Active",
            }
          })),
          status:{
            listTypeValueId:1
          },
       
          actionItem: x.actionItem.map(item => ({
            actionItemName: item.actionItemName,
            actionItemTargetDate: item.actionItemTargetDate,
            actionItemOwner: item.actionItemOwner,
            actionItemStatus: {
              listTypeValueId: 3,
              listTypeValueName: "pending"
            }
            // actionItemStatus:x.actionItemStatus
          }))
        }
        console.log(payload);
        
        // Make API call for non-project
        // return
        this.gs.Create_mom(payload).subscribe(
          (d: any) => {
            console.log("submit"+d.statusCode);

            console.log("response::", d);
            this.isbuttondisable=true

            if(d.statusCode==200){
              console.log("204 entered here!!");
              
              this.success = true;
              this.sucess_msg=d.description
              this.isbuttondisable=true
              setTimeout(() => {
                this.zone.run(() => {
                  this.isbuttondisable=false

                  this.router.navigate(['/home/mom-reports']);
                });
              }, 3000);
            }
            // this.isbuttondisable=false

          },
        
          (err:any)=>{
  console.log("error project::",this.datats);

            this.error = true;
          console.log("code",err);
          console.log("code",err.status);
          this.errorMsg=err.error.description                

            setTimeout(() => {
              this.error = false;
            }, 5000) 
          }
        );
      } 
      else {
        console.log("submt() --create")
        const payload = {
          momName: x.momName,
          momAttendees: x.momAttendees,
          clientId: x.client,
          meetingPlace: x.meetingPlace,
          momDate: x.momDate,
       
          pointsAgreed: x.pointsAgreed.map(agreed => ({
            pointsAgreedDetails: agreed.pointsAgreedDetails,
            status: {
              listTypeValueId: 1,
              listTypeValueName: "Active",
            }
          })),
         
          pointsDiscussed: x.pointsDiscussed.map(discussed => ({
            pointsDiscussedDetails: discussed.pointsDiscussedDetails,
            status: {
              listTypeValueId: 1,
              listTypeValueName: "Active",
            }
          })),
        
          actionItem: x.actionItem.map(item => ({
            actionItemName: item.actionItemName,
            actionItemTargetDate: item.actionItemTargetDate,
            actionItemOwner: item.actionItemOwner,
            actionItemStatus: {
              listTypeValueId: 3,
              listTypeValueName: "pending"
            }
          })),
          project: {
            projectId: x.project
          },
         
          status: {
            listTypeValueId: 1
          }
        };
         console.log("payload::",payload);
    
        this.gs.Create_mom(payload).subscribe(
          (d: any) => {
            console.log("response::", d);
            this.isbuttondisable=true

            if(d.statusCode==200){
              this.success = true;
              this.sucess_msg=d.description
              this.isbuttondisable=true

              setTimeout(() => {
                this.zone.run(() => {
                  this.isbuttondisable=false

                  this.router.navigate(['/home/mom-reports']);
                });
              }, 3000);
            }
        
            

            // this.router.navigate(['/home/mom-reports']);
          },
          err=>{
            this.error = true;
         
          console.log("code",err);
          console.log("code",err.statusCode);
          this.errorMsg=err.error
.          description
          

            setTimeout(() => {
              this.error = false;
             
              
            }, 5000) 
            console.log("this.errMsg::",this.errorMsg);

          }
        );
      }
    
      // this.myForm.reset();
  
      // const payload={
      //   momName:x.momName,
      //   momAttendees:x.momAttendees,
      //   client:x.client,
      //   meetingPlace:x.meetingPlace,
      //   // momDate:this.formattedDate,
      //   momDate:x.momDate,
      //   project:{
      //     projectId:x.project
      //   },
      //   status:{
      //     listTypeValueId:1
      //   }
      // }


    //   this.myForm.reset()
    //   console.log("payload::",payload);

    //  this.gs.Create_mom(payload).subscribe(
    //       (d:any)=>{
    //         console.log("response::",d);
            
    //         Swal.fire({
    //           text:d.description,
    //           icon:'success'
    //         })
    //        this.router.navigate(['./mom-reports']);
    //       },
    //       err=>{
    //         Swal.fire({
    //           text:err.error.description,
    //           icon:'error'
    //         })
    //       }
    //     )
    // this.isbuttondisable=false

    }
    
    
    // this.myForm.value.actionItem.forEach(
    //   e=>{
    //     this.modifiedTargetDate= this.datepipe.transform(e.actionItemTargetDate, 'YYYY-MM-dd');
    //     e['actionItemTargetDate'] =this.modifiedTargetDate;
    //   }
    // )
    if(this.myForm.invalid){
      console.log("validation not came");
      
      return
    }
    if(this.content().status == 'INVALID'){
        return
    }

    if(this.agree().status == 'INVALID'){
        return
    }
    let x = this.myForm.value;
    console.log("Form Values"+x);

    let text =  x.momAttendees.toString();
    x.momAttendees = text

    if(this.momId){
      let x = this.myForm.value;
      x['momId'] = this.momId;
      if(x.projectValue == 1){
        x['project'] = {
          projectId : Number(x.project)
        }
      }
      if(x.projectValue == 2){
        delete x.project;
      }

      console.log(JSON.stringify(x))
      // return
      this.gs.Update_mom(this.momId,x).subscribe(
        (d:any)=>{
          Swal.fire({
            text:d.description,
            icon:'success'
          })
          this.router.navigate(['./MOM-report'])
        },
        err=>{
          Swal.fire({
            text:err.error.description,
            icon:'error'
          })
        }
      )
    }
    else{

      if(x.projectValue == 1 && x.project == ''){
        this.myForm.controls['project'].setErrors({ 'required': true })
        return
      }
      if(x.projectValue == 1){
        x['project'] = {
          projectId : Number(x.project)
        }
      }
      if(x.projectValue == 2){
        delete x.project;
      }
      if(x.actionItem){
        x.actionItem.map(
          e=>{
            e['actionItemStatus'] = "Pending"
          }
        )
      }
      if(this.myForm.value.actionItem){
        this.myForm.value.actionItem.map(
          e=>{
            if(e.actionItemName == ''){
              this.myForm.removeControl('actionItem');
            }
          }
        )
      }
      // console.log(JSON.stringify(x));
      // console.log(x);
      // console.log(this.myForm.value);
        // this.gs.Create_mom(this.myForm.value).subscribe(
        //   (d:any)=>{
        //     // console.log("submit");
        //     console.log("submit"+d.statusCode);
        //     Swal.fire({
              
        //       text:d.description,
        //       icon:'success'
        //     })
        //     // this.myForm.reset()
        //   //  this.router.navigate(['./mom-report']);
        //   },
        //   err=>{
        //     console.log("error");
        //     Swal.fire({
        //       text:err.error.description,
        //       icon:'error'
        //     })
        //   }
        // )
    }
    // this.myForm.reset()

  }
    }

    

   