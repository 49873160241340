<div class="resource-background">
    <div class="row">
        <div class="col-md-11 resource-title"  >
            <h2 style="white-space: nowrap">Manage Client Employee Assignment Details</h2>
        </div>
        <div class="col-md-1"  >
                <mat-icon style="color: red !important;" class="material-icons-outlined" (click)="closedialog()">close</mat-icon>          
        </div>
    </div>  
    <div class="card resource-card">
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Client Name : </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.client.clientName}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Client Status: </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.status.listTypeValueName}}</label>
                </div>                                                  
            </div>
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Employee Name : </label>
                    <label class="form-control-label-class" style="white-space: nowrap"> &nbsp; {{resultData.employee.firstName}} {{resultData.employee.lastName}}</label>
                </div> 
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Employee Code : </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.employee.employeeNumber}}</label>
                </div>                                                  
            </div>
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Start Date : </label>
                    <label class="form-control-label-class"> &nbsp; {{resultData.startDate | date: 'dd/MM/yyyy'}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">End Date : </label>
                    <label class="form-control-label-class">&nbsp; {{resultData.endDate | date: 'dd/MM/yyyy'}}</label>
                </div>                                                  
            </div>  
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
