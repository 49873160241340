import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';

@Component({
  selector: 'app-client-employee-revenue-adjustment',
  templateUrl: './client-employee-revenue-adjustment.component.html',
  styleUrls: ['./client-employee-revenue-adjustment.component.scss']
})
export class ClientEmployeeRevenueAdjustmentComponent implements OnInit {

  length: number;
  dataArray: any=[];
  financialYear: any;
  period: string;
  recAdjustmentId: any;
  finEmpList: any;
  category: any;
  categoryId: number;
  eleNameList: any;
  categoryList: any;
  categoryTypeData: any;
  sub: boolean = false;
  pageSize: any;
  pageIndex: any;
  startIndex: number;
  users(users: any) {
    throw new Error('Method not implemented.');
  }
  revenueForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any=[];
  chooseFinId:any;
  finPeriodList: any=[];
  empCode: boolean=false;
  empRatings:any=[];
  selectedEmployeeData: any = [];
  financialPeriodList: any = [];
  empratingId: number;
  employeetData: any;
  userdata: any;
  reportData: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  currentPageOffset: number=0;
  data: any[]; // Your complete data array
  pagedData: any[];
  collection: any = []; // Your paginated data array
  // pageIndex = 1; // Default current page index
  // pageSize = 10;

  constructor(private fb:FormBuilder,
    private payrollservice:PayrollService,
    private router:Router,
    private formBuilder: FormBuilder,
    private dialog:MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService
    ) { }

  ngOnInit(): void {
    this.data = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`);
    this.updatePagedData();
    this.recAdjustmentId=this.ActivatedRoute.snapshot.params.revenueAdjustmentId;
    console.log(this.recAdjustmentId);
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.revenueForm = this.fb.group({
      category:[null,[Validators.required]],
      element:[null,[Validators.required]],
      employeecode:[null,[Validators.required]],
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      remarks:[null],
      Revenue: this.fb.array([],[Validators.required]),
      client:[null,[Validators.required]],
    })
    
    this.payrollservice.getCategoryListByName().subscribe((result: any) => {
      this.revenueForm.get('category').patchValue(result[0].listTypeValueId);
      this.categoryTypeData = result;
      this.categoryId=this.categoryTypeData[0].listTypeValueId;
      console.log('result',this.categoryId);
      this.payrollservice.getElementListValues(this.categoryId, this.orgId).subscribe((d: any) => {
        console.log('element', d)
        this.eleNameList = d;
      })
    })

    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if(item.financialYearStatus.listTypeValueId == 1){
          console.log(item.financialYearStatus.listTypeValueId)
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList)
        }
      });
    });

 
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
    // this.settingsService.getElementList(this.orgId).subscribe((Data: any) => {
    //   console.log(Data);
    //   this.finEmpList = Data;
    // });

    if(this.recAdjustmentId){
      console.log("if patch");
      console.log(this.revenueForm);
        this.payrollservice.getEmployeeRevenueAdjustmentById(this.recAdjustmentId).subscribe((userdata:any) =>
        {       
          console.log('userdata', userdata)
          this.userdata=userdata;
          this.revenueForm.patchValue({
            financialyear: this.userdata.financialPeriod.finanicalYear.financialYearId, // Replace with the desired value
            period: this.userdata.financialPeriod.financialPeriodId, // Replace with the desired value
            employeecode:this.userdata.employee.employeeNumber,
            element: this.userdata.hrmspaySlipElement.elementId, // Replace with the desired value
            remarks:this.userdata.reMarks,
            client : this.userdata.client.clientId
          });
          console.log("this.revenueForm",this.revenueForm);
          // Adding a row with leaveDays
          console.log('this.userdata.revenueAdjustmentId', this.userdata.revenueAdjustmentId)
          const updateData= {
            employeeId: this.userdata.employee.employeeId,
            employeeNumber:this.userdata.employee.employeeNumber,
            firstName:this.userdata.employee.firstName,
            lastName:this.userdata.employee.lastName,
            amount: this.userdata.amount,
            revenueAdjusmentId:this.userdata.revenueAdjusmentId,
            client : {

              clientId : this.userdata.client.clientId
            } 
            // employeerattingid:this.userdata.employeerattingId
          }
          console.log("updateData",updateData);
          console.log(updateData.revenueAdjusmentId);
          this.selectedEmployeeData.push(updateData);

          console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
          const rowsArray = this.revenueForm.get('Revenue') as FormArray;
          // console.log(rowsArray);
          this.addRow();
          rowsArray.at(0).patchValue({
            employeeId:this.userdata.employee.employeeId , 
            employeeNumber:this.userdata.employee.employeeNumber,
            firstName: this.userdata.employee.firstName,
            lastName: this.userdata.employee.lastName,
            amount: this.userdata.amount,
            // revenueAdjusmentId:updateData.revenueAdjustmentId
            revenueAdjusmentId:this.userdata.revenueAdjusmentId
            })
          console.log("after"+rowsArray);
          this.employeeId = userdata.employee.employeeId;
          this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
            console.log(resultData);

            this.payrollservice.getEarningsCategoryList().subscribe((result: any) => {
              this.revenueForm.get('category').patchValue(result[0].listTypeValueId);
              this.categoryTypeData = result;
            })

            this.settingsService.getFinancialYearId(this.revenueForm.value.financialyear).subscribe((resultData1: any) => {
              console.log("resultData1", resultData1.finanicalPeriod);
        
              this.finPeriodList = resultData1.finanicalPeriod;
              console.log(this.userdata.financialPeriod.financialPeriodId);
              this.revenueForm.get('period').patchValue(this.userdata.financialPeriod.financialPeriodId); 
              console.log("this.financialPeriodList", this.financialPeriodList);

            });
    
          });
          
        })
      // }
    }
  }

  getclientEmployees()
  {
      this.empList.length = 0;
       console.log(this.revenueForm.value.client)
      if(this.revenueForm.value.client != null && this.revenueForm.value.client != "null")
      {
        this.payrollservice.getAllEmployeeClientList(this.revenueForm.value.client).subscribe((ClientEmployees: any) => {
          if(ClientEmployees.length != undefined)
          {
            // ClientEmployees.forEach(element => {
            //   this.empList.push(element.employee);
            // });
          }
         
        });

     
    }

    
  }
  
 

  onKeyDown(event: KeyboardEvent): void {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default Enter behavior
    }
  }
  get validate() { return this.revenueForm.controls; }

  addRow() 
  {
    const control = this.revenueForm.get('Revenue') as FormArray;
    control.push(this.initRows());
    console.log(control);
  }
  // notZeroValidatorAsync(control: AbstractControl) {
  //   const value = control.value;
  //   return new Promise((resolve) => {
  //     if (value === 0 || value === '0' || value === 0.0 || value === '0.0') {
  //       resolve({ notZero: true }); // Return an object with a custom error key if value is zero
  //     } else {
  //       resolve(null); // Return null if the value is not zero, indicating the control is valid
  //     }
  //   });
  // }

  notZeroValidatorAsync(control: AbstractControl) {
    const value = control.value;
    const regex =/^(?!\.)\d{1,8}(\.\d{1,2})?$|^\d{1,8}$/; 
    return new Promise((resolve) => {
      if (!regex.test(value.toString()) ) {
        console.log("invaild");
        resolve({ notZero: true }); // Return an object with a custom error key if value is zero
      } else {
        console.log("Vaild");
        resolve(null); // Return null if the value is not zero, indicating the control is valid
      }
    });
    // return new Promise((resolve) => {
    //   if (value < 1 ) 
    //   {
    //     resolve({ notZero: true }); // Return an object with a custom error key if value is zero
    //   } 
    //   else 
    //   {
    //     resolve(null); // Return null if the value is not zero, indicating the control is valid
    //   }
    // });
  }
  initRows() {
    return this.fb.group({
      amount: ['', [Validators.required], this.notZeroValidatorAsync],
      employeeId: [''],
      employeeNumber:[''],
      revenueAdjusmentId :[''],
      firstName :[''],
      lastName :['']
    });
  }

  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  onSubmit()
  {
    console.log("submit");
    this.submitted=true;
    const empRevenueAdjustment = this.revenueForm.get('Revenue') as FormArray;
    console.log(empRevenueAdjustment);
    if (this.revenueForm.invalid) {
      console.log("invalid",this.revenueForm);
      if(this.revenueForm.value.employeecode.length!=0&&empRevenueAdjustment.length==0)
      {
        console.log("inside form:"+this.revenueForm.value.employeecode);
        this.revenueForm.patchValue({
          employeecode:null
        });
        this.error = true;
        this.error_msg = "Please Select Employee";
        setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
      }
      else
      {
        console.log("Employee Code Null")
      }
      // console.log("empLeavesControls.length",empRevenueAdjustment.length)
      // if(empRevenueAdjustment.length != 0){
      //   console.log("empLeavesControls.length if",empRevenueAdjustment.length)
      //   console.log('ee',empRevenueAdjustment.controls.some(control => control.value.amount === ''))
      //   if(empRevenueAdjustment.controls.some(control => control.value.amount === '') == true){
      //     // alert('Leave Days Should not be empty')
      //     console.log("true")
      //     this.error = true;
      //     this.error_msg = "Amount Should not be empty";
      //     setTimeout(() => {this.error  = false;},redirectMsgTimeOut) 
      //   }
      // }
      return;
    }

    console.log(this.recAdjustmentId);

    if(this.recAdjustmentId) //Update 
    {
      this.empRatings=[];
      console.log("update", this.recAdjustmentId);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      console.log("employeeLeaveControls", empRevenueAdjustment);
      for (let i = 0; i < empRevenueAdjustment.length; i++) {

        console.log('amount',empRevenueAdjustment.controls[i].value.amount)
        console.log('empRevenueAdjustment.controls.some(control => control.value.amount === 0)',empRevenueAdjustment.controls.some(control => control.value.amount === 0))
        if (empRevenueAdjustment.controls.some(control => control.value.amount === 0)) {
          console.log("zero")
          // Display an error message or handle the empty Leave Days scenario
          alert('Amount Should not be zero')
          
          return;
        }

        if (empRevenueAdjustment.controls.some(control => control.value.amount === '0')) {
          console.log("zero")
          // Display an error message or handle the empty Leave Days scenario
          alert('Amount Should not be zero')
          
          return;
        }
       
        if (empRevenueAdjustment.controls.some(control => control.value.amount === '')) {
          console.log("empty")
          // Display an error message or handle the empty Leave Days scenario
          alert('Amount Should not be empty')
          
          return;
        }

    
        console.log(empRevenueAdjustment.at(0).value.amount);
        console.log("employeeLeaveControls", empRevenueAdjustment.value);
        console.log("revenueAdjustmentId", empRevenueAdjustment.value.at(i).revenueAdjusmentId);
        const employeeId = empRevenueAdjustment.at(i).value.employeeId;
        console.log('employeeId', employeeId);
        console.log("Id", empRevenueAdjustment.at(i).value.revenueAdjusmentId);
        if(empRevenueAdjustment.at(i).value.revenueAdjusmentId==undefined)
        {
          console.log("Id not present", empRevenueAdjustment.at(i).value.revenueAdjusmentId);
          const updateRevAdjustment = 
          {
            amount: empRevenueAdjustment.at(i).value.amount,
            reMarks:this.revenueForm.value.remarks,
            financialPeriod: {
              financialPeriodId: this.revenueForm.value.period
            },
            employee: {
              employeeId: empRevenueAdjustment.at(i).value.employeeId
            },
            hrmspaySlipElement:{
                elementId:this.revenueForm.value.element
            },
            status:{
              listTypeValueId:1
            },
              client: {
                  clientId:this.revenueForm.value.client
              }
        }
          this.empRatings.push(updateRevAdjustment);
        }
        else
        {
          console.log("this.recAdjustmentId",this.recAdjustmentId)
          console.log("id present", empRevenueAdjustment.at(i).value.revenueAdjusmentId);
          const updateRevAdjustment=
          {
            revenueAdjusmentId:this.recAdjustmentId,
            amount: empRevenueAdjustment.at(i).value.amount,
            reMarks:this.revenueForm.value.remarks,
            financialPeriod: {
              financialPeriodId: this.revenueForm.value.period
            },
            employee: {
              employeeId: empRevenueAdjustment.at(i).value.employeeId
            },
            hrmspaySlipElement:{
                elementId:this.revenueForm.value.element
            },
            status:{
              listTypeValueId:1
            },
            client: {
              clientId:this.revenueForm.value.client
          }
          }
          this.empRatings.push(updateRevAdjustment);
        }
      }

      console.log('employeeLeaveArray', this.empRatings);          
        this. payrollservice.updateEmployeeRevenueAdjustment(this.empRatings).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;
            // this.searchSubmit();
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

            this.revenueForm.reset();
            this.submitted = false;
            setTimeout(() => {
              this.router.navigate(["home/client-employee-revenue-adjustment-list"])
            }, redirectMsgTimeOut)
          }
          else
          {
          console.log("err1",result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson',this.errormessagejson);
          this.importerror = true;
          }
        },
         err =>
            {

                console.log("code1",err.statusCode);
                setTimeout(() => {
                  this.error = true;
                  this.errorMsg = err.error.description;
                }, redirectMsgTimeOut) 
              // }
               
            })
    }

    else
    {
       if (empRevenueAdjustment.controls.some(control => control.value.amount === 0)) {
          console.log("zero")
          // Display an error message or handle the empty Leave Days scenario
          alert('Amount Should not be zero')
          return;
        }

        if (empRevenueAdjustment.controls.some(control => control.value.amount === '0')) {
          console.log("zero")
          // Display an error message or handle the empty Leave Days scenario
          alert('Amount Should not be zero')
          
          return;
        }
       
      this.empRatings=[];
      console.log("create");
      console.log(empRevenueAdjustment.value);
      console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      this.reportData=empRevenueAdjustment.value;
      console.log("this.reportDate",this.reportData);
      for (let i = 0; i < this.reportData.length; i++) {
        
        const employeeId = this.reportData.at(i).employeeId;
        console.log('employeeId', employeeId);

       const createadjustment=
        {
          amount: this.reportData.at(i).amount,
          reMarks:this.revenueForm.value.remarks,
          financialPeriod: {
            financialPeriodId: this.revenueForm.value.period
          },
          employee: {
            employeeId: this.reportData.at(i).employeeId
          },
          hrmspaySlipElement:{
              elementId:this.revenueForm.value.element
          },
          client: {
            clientId:this.revenueForm.value.client
        }
        }
        this.empRatings.push(createadjustment);
        console.log(this.empRatings);
      }
          this.payrollservice.createEmployeeRevenueAdjustment(this.empRatings).subscribe((result: any) =>
       {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          this.revenueForm.reset();     
          this.submitted = false;
          // this.searchSubmit();
          setTimeout(() => {
            this.router.navigate(["home/client-employee-revenue-adjustment-list"])
          }, redirectMsgTimeOut)
        }
        else
        {
          console.log("err1",result.statusCode);
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          console.log('this.errormessagejson',this.errormessagejson);
          this.importerror = true;
          // setTimeout(() => {this.error = false},redirectMsgTimeOut)

          // setTimeout(() => {
          // }, redirectMsgTimeOut)
        }

      }, err =>
      {
        console.log("code",err);
        console.log("code",err.statusCode);
        // if(err.error.statusCode == 409)
        // {
        //   console.log("code1",err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut) 
        // }
         
      })
    }

  }
  
  selectFinancialyear(event)
  {
    console.log("Select indidual Employee");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else{
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.revenueForm.patchValue({
        period: null,
      });
    });
  }
  }

  
    empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      

      this.payrollservice.getClienEmployeeList( employeenumber , this.revenueForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode)
        {
          
        }else{
          this.empList =  result;
        }
     
     })

    }
    
  }

  

  empSelect(emp)
   {
    console.log(emp);
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;
      console.log("select",this.selectedEmployeeData);
      let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
      if (!hasemployee) {
        this.selectedEmployeeData.push(emp);
        const rowsArray = this.revenueForm.get('Revenue') as FormArray;
        console.log("control",rowsArray);
        rowsArray.push(this.formBuilder.group({
          employeeId: [this.employeeId], 
          employeeNumber:[this.empnumber],
          firstName:[ this.firstname],
          lastName:[this.lastname],
          amount: ['', [Validators.required], this.notZeroValidatorAsync],
        }));
      }
  }
  get getFormControls() {
    this.dataArray=[];
    const control = this.revenueForm.get('Revenue') as FormArray;
    // console.log(control);
    this.length=control.length;
    if(control != null)
    {
       control.controls.forEach((formGroup: FormGroup) => {
        const data = formGroup.value;
        this.dataArray.push(data);
      });
      // console.log(this.dataArray);
      return control;
    }    
  }
  
  empAllSelect()
 {
  console.log("all Employee");
  if(this.revenueForm.value.employeecode == "All" && this.revenueForm.value.client)
  {
    // console.log("select",this.selectedEmployeeData);
    // this.payrollservice
    // .getAllEmployeeByOrganization(this.orgId).subscribe((result: any) => {
    //   console.log(result);
    //   if(result.length==0)
    //   {
    //     console.log("noData");
    //   }
    //   console.log("All",this.empList);
    //   result.forEach(element => {
    //     let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
    //     if (!hasemployee) {
    //       this.selectedEmployeeData.push(element);
    //       console.log('selectedEmployeeData', this.selectedEmployeeData);
    //       const rowsArray = this.revenueForm.get('Revenue') as FormArray;
    //       rowsArray.push(this.formBuilder.group({
    //         employeeId: [element.employeeId], 
    //         employeeNumber:[element.employeeNumber],
    //         firstName:[element.firstName],
    //         lastName:[element.lastName],
    //         amount: ['', [Validators.required], this.notZeroValidatorAsync],
    //       }));
    //     }
    //   });
    // })


    console.log("else");
    this.selectedEmployeeData.length = 0;
    this.empList.length = 0;
    this.payrollservice.getAllEmployeeClientList(this.revenueForm.value.client).subscribe((ClientEmployees: any) => {
      if(ClientEmployees.length != undefined)
      {
        ClientEmployees.forEach(element => {
          let hasemployee = this.empList.some(e => e.employeeId == element.employeeId);
          if (!hasemployee) {
            this.empList.push(element.employee);
          }
         
        });
        const rowsArray = this.revenueForm.get('Revenue') as FormArray;
        rowsArray.clear();
        this.empList.forEach(element => {
          let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
          if (!hasemployee) {
            this.selectedEmployeeData.push(element);
            console.log('selectedEmployeeData', this.selectedEmployeeData);
           
            
            rowsArray.push(this.formBuilder.group({
              employeeId: [element.employeeId], 
            employeeNumber:[element.employeeNumber],
            firstName:[element.firstName],
            lastName:[element.lastName],
            amount: ['', [Validators.required], this.notZeroValidatorAsync],
            }));
          }
        });
      }
     
    });
  }
}

// onPageChange(event: any): void {
//   this.pageIndex = event.pageIndex;
//   this.pageSize = event.itemsPerPage;
//   console.log(`Page changed. PageIndex: ${this.pageIndex}, PageSize: ${this.pageSize}`);
// }

deleteemployee(index) {
  console.log(index + index);
  if (index != -1) {
    const rowsArray = this.revenueForm.get('Revenue') as FormArray;
    console.log(rowsArray);
    // for (let i = 0; i < rowsArray.length; i++) {
      const emprattingId = rowsArray.at(index).value.employeeId;
      console.log('emprattingId', emprattingId);
      console.log("Id", rowsArray.at(index).value.revenueAdjusmentId);
      if(rowsArray.at(index).value.revenueAdjusmentId==undefined)
      {
        rowsArray.removeAt(index);
        this.selectedEmployeeData.splice(index, 1);
        console.log(this.selectedEmployeeData.length);
      }
   }
}

isRowValid(index: number): boolean {
  const rowsArray = this.revenueForm.get('Revenue') as FormArray;
  // console.log(control);
  const row = rowsArray.at(index) as FormGroup;
  return row.valid;
}
close()
{
  console.log("close");
  this.router.navigateByUrl('/home/client-employee-revenue-adjustment-list');
}
pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

  page(event: any): void 
  {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.pageSize = event.itemsPerPage;
    console.log(`Page changed. PageIndex: ${this.pageIndex}, PageSize: ${this.pageSize}`);
  }

  onPageChange(event: any): void {
    this.p = event;
    this.updatePagedData();
  }

  updatePagedData(): void {
    const startIndex = (this.p - 1) * this.pagePerItem;
    const endIndex = startIndex + this.pagePerItem;
    console.log(startIndex);
    this.startIndex=startIndex;
    console.log(this.startIndex);
    console.log(endIndex);
  }

  get amountFormArray() : FormArray {
    return this.revenueForm.controls["Revenue"] as FormArray
   }

}
