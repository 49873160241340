import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { ProjectManagementService } from '../../../providers/project-management.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ShowErrorComponent } from '../../project-management/show-error/show-error.component';
import { Router } from '@angular/router';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { ActionitemService } from '../actionitemreport/actionitem.service';

@Component({
  selector: 'app-updateaction',
  templateUrl: './updateaction.component.html',
  styleUrls: ['./updateaction.component.scss'],
})
export class UpdateactionComponent {
  momId: any;
  actionForm: FormGroup;
  modifiedTargetDate: any;
  success: boolean = false;
  error: any = false;
  error_msg: any;
  sucess_msg: any;
  submitted:boolean = false;

  importerror: boolean = false;
  errormessagejson: any;
  errorMsg: any;
  actionItemLists: Object;
  firstArray: any;
  storeOrgId: any;
  listvalueItdstore: any;
  momreportUpdatedata: any;
isbtndisabled: boolean=false;
buttonDisabled:boolean = true;

  constructor(
    private gs: ProjectManagementService,
    public dialogRef: MatDialogRef<UpdateactionComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private timesheet: TimesheetService,
    private actionitem:ActionitemService,
    private router: Router,
    private zone: NgZone,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.mom.momId);
    const myData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("wtts this.data check::",this.data);
    
this.storeOrgId=myData.organization.organizationId
this.listvalueItdstore=myData.status.listTypeValueId

    this.actionForm = this.fb.group({
      // actionItemName: ['', Validators.required],
      actionItemName: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],

      actionItemTargetDate: ['', Validators.required],
      // actionItemOwner: ['', Validators.required],
      actionItemOwner: ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],

      actionItemStatus: ['', Validators.required],
      status: [''],
    });
    console.log(this.actionForm);
    this.actionForm.patchValue({
      actionItemName: this.data.actionItemName,
      actionItemTargetDate: this.data.actionItemTargetDate,
      actionItemOwner: this.data.actionItemOwner,
      actionItemStatus: this.data.actionItemStatus.listTypeValueId
      ,
      status: this.data.status,
    });
    console.log(this.actionForm);
  }

  get f() {
    return this.actionForm.controls;
  }

  get actionItemName() {
    return this.actionForm.get('actionItemName');
  }
  get actionItemOwner() {
    return this.actionForm.get('actionItemOwner');
  }


  showerrormessage() {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      height: 'fit-content',
      data: this.errormessagejson,
    });
    this.error = false;
  }

  onSubmit() {
    this.submitted=true
    // this.isbtndisabled=true
    if (this.actionForm.invalid) {
      // this.submitted=false;
      this.isbtndisabled=false;

      return;
    }

    // if (this.actionForm.valid) {
    //   // this.submitted=false;
    //   this.isbtndisabled=false;
   

    //   return;
    // }
    console.log(this.actionForm.value);
    let x = this.actionForm.value;
    console.log(x);
    this.modifiedTargetDate = this.datepipe.transform(
      this.actionForm.value.actionItemTargetDate,
      'YYYY-MM-dd'
    );
let payload={
  actionItemId: this.data.actionItemId,
  actionItemTargetDate:this.modifiedTargetDate,
  actionItemOwner:x.actionItemOwner,
  actionItemName:x.actionItemName,
  mom:{
    momId: this.data.mom.momId
  },
  status:{
    listTypeValueId:1  },
  actionItemStatus:{
    listTypeValueId:x.actionItemStatus
  }
}    
console.log("payload::",payload);


    // x['actionItemTargetDate'] = this.modifiedTargetDate;
    // x['mom'] = {
    //   momId: this.data.mom.momId,
    // };
    // x['actionItemId'] = this.data.actionItemId;
    // x['actionItemStatus'] = {
    //   listTypeValueId:this.data.actionItemStatus}

    // console.log('x::', x);
    // console.log('JSON.stringify(x)::', JSON.stringify(x));
    // return

    this.buttonDisabled = false;

    this.gs.UpdateActionItem(this.data.actionItemId, payload).subscribe(
      (data: any) => {
        
        // this.momreportUpdatedata=data;
        // console.log("this.momreportUpdatedata",this.momreportUpdatedata);
        
        // if (data.statusCode == 200) {
        //   this.success = true;
        //   this.isbtndisabled=true

        //   this.sucess_msg = data.description;
        //     this.timesheet.setAddTaskSuccessMessage('Action Item Report updated Successfully');
        //     this.dialog.closeAll()
        //   // setTimeout(() => {
        //   //   this.success=false       
        //   //   this.isbtndisabled=false
        //   //   this.timesheet.setAddTaskSuccessMessage('Action Item Report updated Successfully');

  
        //     // this.zone.run(() => {
        //     //   this.dialogRef.close(x)

        //     //   this.router.navigate(['/home/action-item-report']);
        //   //   // });
        //     setTimeout(() => {
        //       this.dialogRef.close(x);

        //       this.success=false

        //        this.zone.run(() => {
        //       // this.dialogRef.close(x)

        //       this.router.navigate(['/home/action-item-report']);
        //     });

        //     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //   const currentUrl = this.router.url;
        //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigateByUrl(currentUrl);

        //   });
        //     }, 4000)

              if (data.statusCode == 200) {
                console.log("entered into the condition!!");
                
          this.success = true;
          this.isbtndisabled=true

          this.sucess_msg = data.description;
          this.actionitem.setSuccessUpdateMessage('Action Item Report updated Successfully!');

          this.buttonDisabled = true;
            setTimeout(() => {
                console.log("dialoge box closing ");
      
                    this.isbtndisabled=false;

                    // this.actionitem.setSuccessUpdateMessage('');

      
                    //  this.zone.run(() => {
                    // this.dialogRef.close(x)
      
                    // this.router.navigate(['/home/action-item-report']);
                    this.success=false
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  const currentUrl = this.router.url;
                  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigateByUrl(currentUrl);
                  });
              },5000)
              this.dialogRef.close(x);

            }
            this.isbtndisabled=false;

      },
      (err) => {
        this.error = true;
        console.log('code', err);
        console.log('code', err.statusCode);
        setTimeout(() => {
          this.error = true;
          this.error_msg = err.error.description;
        }, 5000);
      }
    );
  }
}
