import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalserviceService } from '../providers/globalservice.service';
import { AdminService } from '../../app/providers/admin.service';
import { DatePipe } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';
import { PayrollService } from '../providers/payroll.service';

@Component({
  selector: 'app-legalentity',
  templateUrl: './legalentity.component.html',
  styleUrls: ['./legalentity.component.scss'],
})
export class LegalentityComponent implements OnInit {
  submitted: boolean = false;
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  legalEntitygroup: FormGroup;
  resultData: any;
  branchId: any;
  storeEntityListId:any;
  storeEntityListName:any
  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;
  employeetData: any;
  isbtnDisable: boolean = false;
  businessId: any;
  countryData: any=[];
  autoCompleteCountrySearch: any = 0; //Auto Complete Search
  copyCountryName: any;
  copyCountryId: string;
  address_step = false;
  businessList: any[] = [];
  formValues: any;
  isCountryValid:boolean=false;
  autoCompleteStateSearch:any;
  legalentityId: any;
  legaldata: any;
  entityList: any[] = [];
  buttonDisabled: boolean = false;
  entityTypeStringValue: string = '';
  storeEntityId:any;
  entytyData: any;
  autoCompleteCountrySearch1: number;
isbtnDisables: boolean=false;
showRegistrationDate: boolean = false;
submitted1:boolean=false
today: Date = new Date();
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private datepipe: DatePipe,
    private gs: GlobalserviceService,
    private AdminService: AdminService,
    private ActivatedRoute: ActivatedRoute,
    private payrollservice:PayrollService
  ) {
    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);

    this.payrollservice.getBusinessGroup(this.storeEntityId).subscribe((response: any) => {
      console.log("response::", response);

      if (Array.isArray(response)) {
        this.businessList = response;
      } else if (response && typeof response === 'object' && Object.keys(response).length > 0) {
        // If the response is an object, wrap it in an array
        this.businessList = [response];
      } else {
        // Handle cases where the response is null, undefined, or not iterable
        this.businessList = [];
      }
    
    });


    this.AdminService.getEntityList().subscribe((response: any) => {
      console.log("response::", response);

      // Ensure the response is always assigned as an array
      if (Array.isArray(response)) {
        this.entityList = response;
      } else if (response && typeof response === 'object' && Object.keys(response).length > 0) {
        // If the response is an object, wrap it in an array
        this.entityList = [response];
      } else {
        // Handle cases where the response is null, undefined, or not iterable
        this.entityList = [];
      }
    });

  }
  // 

  ngOnInit(): void {
   

    this.legalEntitygroup = this.formBuilder.group({
      businessgroup: ['', Validators.required],
      legalEntityName: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      description: ['', [Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      entityType: ['', [Validators.required]],
      entityTypeId:['', [Validators.required]],
      regNumber: ['', [Validators.minLength(21), Validators.maxLength(21), Validators.pattern('^[A-Za-z]{1}\\d{5}[A-Za-z]{2}\\d{4}[A-Za-z]{3}\\d{6}$')]],
      registrationDate: [{ value: null, disabled: true }, Validators.required], // Initially disabled and not required
      countryName: ['', [Validators.required]],
      // countryId: [''],
      legalAddress: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      operatingAddress: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]]
    });


    // this.legalEntitygroup.get('regNumber').valueChanges.subscribe(value => {
    //   const regNumberControl = this.legalEntitygroup.get('regNumber');
    //   const registrationDateControl = this.legalEntitygroup.get('registrationDate');

    //   if (regNumberControl.valid) {
    //     registrationDateControl.enable(); // Enable the registrationDate if valid
    //     registrationDateControl.setValidators([Validators.required]); // Add required validator
    //   } else {
    //     registrationDateControl.disable(); // Disable it if not valid
    //     registrationDateControl.clearValidators(); // Remove validators
    //   }

    //   // Update the value and validity of registrationDate
    //   registrationDateControl.updateValueAndValidity();
    // });
    const isValidRegNumber = /^[A-Za-z]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}$/.test(this.legaldata?.registrationNumber);

    if (isValidRegNumber) {
      // If valid, enable registrationDate and set it as required
      this.legalEntitygroup.get('registrationDate').enable();
      this.legalEntitygroup.get('registrationDate').setValidators([Validators.required]);
    } else {
      // If invalid, disable registrationDate and remove the required validator
      this.legalEntitygroup.get('registrationDate').disable();
      this.legalEntitygroup.get('registrationDate').clearValidators();
    }
  
    // Update the validity of registrationDate
    this.legalEntitygroup.get('registrationDate').updateValueAndValidity();

   

    this.ActivatedRoute.paramMap.subscribe(params => {


      console.log('params', params);
      this.legalentityId = params.get('legalentityId');
      console.log('this.legalentityId', this.legalentityId);

    })



    if (this.legalentityId) {
      this.loader=true;
      this.isbtnDisables=true;

      this.AdminService.getEditlegalEntity(this.legalentityId).subscribe((response: any) => {
        console.log("response::", response);

        this.legaldata = response;
        this.loader=false; 
             this.isbtnDisables=false;


        console.log("edit ::", this.legaldata);

        const registrationDates = this.legaldata?.registrationDate 
        ? this.legaldata.registrationDate.split('T')[0] 
        : null;
    
      console.log("Patched Registration Date:", registrationDates);
        this.legalEntitygroup.patchValue({
          businessgroup: Number(this.legaldata.businessGroup.businessGroupId),
          legalEntityName: this.legaldata.legalEntityName,
          description: this.legaldata.legalEntityDescription,
          entityType: this.legaldata.entityType,
          entityTypeId: this.legaldata.entityType,

          regNumber: this.legaldata?.registrationNumber,

          registrationDate: registrationDates,
          countryName: this.legaldata.countryOfIncorporation,
          legalAddress: this.legaldata.legalAddress,
          operatingAddress: this.legaldata.operatingAddress,
          legalEntityStatus: this.legaldata.legalEntityStatus
          

        }
        
      ) 
      const registrationDateControl = this.legalEntitygroup.get('registrationDate');
      if (registrationDates) {
        this.showRegistrationDate = true;
        registrationDateControl?.enable({ emitEvent: false });
        registrationDateControl?.setValidators([Validators.required]);
      } else {
        this.showRegistrationDate = false;
        registrationDateControl?.disable({ emitEvent: false });
        registrationDateControl?.clearValidators();
      }

      registrationDateControl?.updateValueAndValidity({ emitEvent: false });



        console.log('businessgroup', this.legalEntitygroup.value.businessgroup);





      })

    }

    this.getBusinessList();
  }

  // onRegNumberInput(event: Event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   const regNumberControl = this.legalEntitygroup.get('regNumber');
  
  //   // Check if the input value matches the expected format
  //   const isValidRegNumber = /^[A-Za-z]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}$/.test(inputElement.value);
    
  //   if (isValidRegNumber) {
  //     this.showRegistrationDate = true;
  //     // If valid, enable registrationDate and set it as required
  //     this.legalEntitygroup.get('registrationDate').enable();
  //     this.legalEntitygroup.get('registrationDate').setValidators([Validators.required]);
  //   } else {
  //     this.showRegistrationDate = false;
  //     // If invalid, disable registrationDate and remove the required validator
  //     this.legalEntitygroup.get('registrationDate').disable();
  //     this.legalEntitygroup.get('registrationDate').clearValidators();
  //   }
  
  //   // Update the validity of registrationDate
  //   this.legalEntitygroup.get('registrationDate').updateValueAndValidity();
  // }
  // onRegNumberInput(event: Event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   const regNumberControl = this.legalEntitygroup.get('regNumber');
  //   const registrationDateControl = this.legalEntitygroup.get('registrationDate');
  
  //   // Ensure you're using the latest input value
  //   const inputValue = inputElement.value;
  
  //   // Check if the input value matches the expected format
  //   const isValidRegNumber = /^[A-Za-z]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}$/.test(inputValue);
  
  //   if (isValidRegNumber) {
  //     this.showRegistrationDate = true;
  
  //     // Enable the registration date control and add the required validator
  //     registrationDateControl?.enable({ emitEvent: false });
  //     registrationDateControl?.setValidators([Validators.required]);
  //   } else {
  //     this.showRegistrationDate = false;
  
  //     // Disable the registration date control and clear validators
  //     registrationDateControl?.disable({ emitEvent: false });
  //     registrationDateControl?.clearValidators();
  //   }
  
  //   // Update the validity of the registration date control
  //   registrationDateControl?.updateValueAndValidity({ emitEvent: false });
  // }
  
  onRegNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const regNumberControl = this.legalEntitygroup.get('regNumber');
    const registrationDateControl = this.legalEntitygroup.get('registrationDate');
  
    // Ensure you're using the latest input value
    const inputValue = inputElement.value;
  
    // Check if the input value matches the expected format
    const isValidRegNumber = /^[A-Za-z]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}$/.test(inputValue);
  
    if (isValidRegNumber) {
      this.showRegistrationDate = true;
  
      // Enable the registration date control and add the required validator
      registrationDateControl?.enable({ emitEvent: false });
      registrationDateControl?.setValidators([Validators.required]);
    } else {
      this.showRegistrationDate = false;
  
      // Disable the registration date control and clear validators
      registrationDateControl?.disable({ emitEvent: false });
      registrationDateControl?.clearValidators();
    }
  
    // Update the validity of the registration date control
    registrationDateControl?.updateValueAndValidity({ emitEvent: false });
  }
  getBusinessList() {
    // this.AdminService.getBranchlists().subscribe((response: any) => {
    //   this.businessList = response;
    //   // this.businessList = Array.isArray(response) ? response : [response];
    // });
  }

  selectionChangeOfBusinessGroup(event: MatSelectChange) {
    console.log('event', event);

  }
  get address1() {
    return this.legalEntitygroup?.controls;
  }
  getFilterData(id, filterName, type) {
    if (type == 1) {
      //Country
      this.autoCompleteCountrySearch = 1;

      if (id) {
        this.legalEntitygroup.patchValue({
          countryName: filterName,
          countryId: id,
        });
      }
    }
  }
  // onRegNumberInput(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   const value = input.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  //   // Update the value in the form control
  //   this.legalEntitygroup.controls.regNumber.setValue(value, {
  //     emitEvent: false, // Prevents triggering valueChanges or statusChanges
  //   });
  // }
  // countryAutoCompleteSearch(event) {
  //   let keywords = event.target.value;

  //   if (keywords === '') {
  //     this.countryData='';
  //     this.countryData.length=0;
  //     this.countryData=[]
  //     console.log('Empty keywords - clearing city name and id');
  //     this.legalEntitygroup.patchValue({
  //       countryId: '',
  //       // districtId: '',
  //     });
  //     this.legalEntitygroup.patchValue({
  //       permenantCountryName: '',
  //       permenantCountryId: '',
  //     });
  //     this.copyCountryName = '';
  //     this.copyCountryId = '';
  //   } else if (
  //     keywords &&
  //     this.copyCountryName &&
  //     keywords !== this.copyCountryName
  //   ) {
  //     // Clear the countryId and reset related fields
  //     this.legalEntitygroup.patchValue({
  //       countryId: '',
  //     });

  //     this.copyCountryName = ''; // Clear the cached country name
  //     this.copyCountryId = ''; // Clear the cached country ID
  //   }

  //   if (keywords) {
  //     this.autoCompleteCountrySearch = 0;

  //     this.AdminService.countryAutoCompleteSearch(keywords).subscribe(
  //       (result: any) => {
  //         this.countryData = result;

  //         this.legalEntitygroup.patchValue({
  //           countryId: '',
  //         });

  //         if (this.countryData.statusCode == 204) {
  //           this.autoCompleteCountrySearch = 0; //No data Found
  //           this.legalEntitygroup.patchValue({
  //             countryId: '',
  //           });
  //           this.legalEntitygroup.controls['countryId'].reset();
  //           this.legalEntitygroup.controls['countryName'].reset();
  //         } else {
  //           this.autoCompleteCountrySearch = 0;
  //         }

  //         if (
  //           this.legalEntitygroup.controls['copyCurrentAddress'].value == true
  //         ) {
  //           this.legalEntitygroup.patchValue({
  //             permenantCountryName: this.copyCountryName,
  //             permenantCountryId: this.copyCountryId,

  //           });
  //         }
  //       }
  //     );
  //   }
  // }
  countryAutoCompleteSearch(event) {
    // let keywords = event.target.value?.trim(); // Trim to remove leading/trailing spaces
    // let keywords = (event.target as HTMLInputElement)?.value?.trim();

    // if (!keywords) {
    //   this.clearCountryField();
    //   return;
    // }

    const textarea = event.target.value;
  console.log("keywords::", textarea);

  // Check if the textarea is empty
  if (textarea.trim() === '') {
    this.autoCompleteCountrySearch = 1; // Hide the list if the input is empty
    this.legalEntitygroup.patchValue({
      countryId: '',
     
    });
    return; // Exit the function early
  }
    // Handle backspace
    // if (event.key === 'Backspace' && !keywords) {
    //   this.countryData = []; // Clear the dropdown list
    //   console.log('Backspace detected, clearing country data');
    //   this.legalEntitygroup.patchValue({
    //     countryName: '',
    //     // permenantCountryName: '',
    //     // permenantCountryId: '',
    //   });
      // this.copyCountryName = '';
      // this.copyCountryId = '';
      // return; // Stop further execution if input is empty
    // }
    this.AdminService.countryAutoCompleteSearch(textarea).subscribe(
      (result: any) => {
        this.countryData = result;
        console.log("this.country data:", this.countryData);
  
        this.legalEntitygroup.patchValue({
          countryId: '',
        });
  
        if (this.countryData.statusCode == 204) {
          this.autoCompleteCountrySearch = 1; // No data Found
          this.legalEntitygroup.patchValue({
            countryId: '',
          });
          // this.legalEntitygroup.controls['countryId'].reset();
          this.legalEntitygroup?.controls['countryName']?.reset();
          this.legalEntitygroup?.controls['countryId']?.reset();

          return;
        } else {
          this.autoCompleteCountrySearch = 0;
        }
      }
    );

  
    // Clear the list and reset fields when keywords are empty
    // if (!keywords) {
    //   this.countryData = []; // Clear the dropdown list
    //   console.log('Empty keywords - clearing country data');
    //   this.legalEntitygroup.patchValue({
    //     countryId: '',
    //     permenantCountryName: '',
    //     permenantCountryId: '',
    //   });
    //   this.copyCountryName = '';
    //   this.copyCountryId = '';
    //   return; // Stop further execution if input is empty
    // }
  
    // // If keywords are provided but don't match the cache, reset related fields
    // if (keywords !== this.copyCountryName) {
    //   this.legalEntitygroup.patchValue({
    //     countryId: '',
    //   });
    //   this.copyCountryName = ''; // Clear the cached country name
    //   this.copyCountryId = ''; // Clear the cached country ID
    // }
  
    // this.autoCompleteCountrySearch = 0;
  
    // // Perform the search only when keywords are present
    // this.AdminService.countryAutoCompleteSearch(keywords).subscribe(
    //   (result: any) => {
    //     this.countryData = result || []; // Ensure list is reset if no data is found
  
    //     if (!this.countryData.length || this.countryData.statusCode == 204) {
    //       this.autoCompleteCountrySearch = 0; // No data found
    //       this.legalEntitygroup.patchValue({
    //         countryId: '',
    //       });
    //       this.legalEntitygroup.controls['countryId'].reset();
    //       this.legalEntitygroup.controls['countryName'].reset();
    //     }
  
    //     // Handle case where 'copyCurrentAddress' is checked
    //     if (this.legalEntitygroup.controls['copyCurrentAddress'].value === true) {
    //       this.legalEntitygroup.patchValue({
    //         permenantCountryName: this.copyCountryName,
    //         permenantCountryId: this.copyCountryId,
    //       });
    //     }
    //   }
    // );
  
  }
  
  
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  getFilterDataEntity(id,filterName) {
    // if (type == 1) {
      //Country/
      console.log("id:",id ,"name:",filterName);
      
      this.autoCompleteCountrySearch1 = 1;

      if (id) {
        this.legalEntitygroup.patchValue({
          entityType: filterName,
          entityTypeId: id,
        });

        // this.legalEntitygroup.patchValue({
        //   stateName: '',
        //   stateId: '',

        //   districtName: '',
        //   districtId: '',

        //   cityName: '',
        //   cityId: '',
        // });
      }
    // }
  }
//   entityTypeSearch(event)
//   {
//     const textarea = event.target.value;
//     console.log("keywords::",textarea);
//     if (textarea) {
//       this.autoCompleteCountrySearch1 = 0;
    
//       this.AdminService.entityTypeSearch(textarea).subscribe(
//         (result: any) => {
//           this.entytyData = result;
//           console.log("this.country data:",this.entytyData);
          
    
//           this.legalEntitygroup.patchValue({
//             entityTypeId: '',
//           });
    
//           if (this.entytyData.statusCode == 204) {
//             this.autoCompleteCountrySearch1 = 1; //No data Found
//             this.legalEntitygroup.patchValue({
//               entityTypeId: '',
//             });
//             // alert("Please select Proper Entity Type in the Option!")
//             this.legalEntitygroup.controls['entityTypeId'].reset()
//             this.legalEntitygroup.controls['entityType'].reset()

// return
//           } else {
//             this.autoCompleteCountrySearch1 = 0;
//           }
//         }
//       );
//     } else {
//       this.autoCompleteCountrySearch1 = 1;
//       this.legalEntitygroup.patchValue({
//         entityTypeId: '',
//       });
//     }
//   }
entityTypeSearch(event) {
  const textarea = event.target.value;
  console.log("keywords::", textarea);

  // Check if the textarea is empty
  if (textarea.trim() === '') {
    this.autoCompleteCountrySearch1 = 1; // Hide the list if the input is empty
    this.legalEntitygroup.patchValue({
      entityTypeId: '',
    });
    return; // Exit the function early
  }

  // If there is text in the textarea, proceed with the search
  this.autoCompleteCountrySearch1 = 0;

  this.AdminService.entityTypeSearch(textarea).subscribe(
    (result: any) => {
      this.entytyData = result;
      console.log("this.country data:", this.entytyData);

      this.legalEntitygroup.patchValue({
        entityTypeId: '',
      });

      if (this.entytyData.statusCode == 204) {
        this.autoCompleteCountrySearch1 = 1; // No data Found
        this.legalEntitygroup.patchValue({
          entityTypeId: '',
        });
        this.legalEntitygroup.controls['entityTypeId'].reset();
        this.legalEntitygroup.controls['entityType'].reset();
        return;
      } else {
        this.autoCompleteCountrySearch1 = 0;
      }
    }
  );
}

  validateInputSpace(event) {
    const textarea = event.target;
    console.log("keywords::",textarea);
    
    let value = textarea?.value;
    console.log("value wt is::",value)


    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0)
    ) {
      console.log("empty space is ther!!");
      
      this.countryData=
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; 
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; 

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }

  }
  validateCountryInput() {
    const enteredValue = this.legalEntitygroup.get('countryName')?.value?.trim();
    const isValid = this.countryData.some(
      (country) => country?.name?.toLowerCase() === enteredValue?.toLowerCase()
    );
  
    if (!isValid) {
      this.clearCountryField();
      this.isCountryValid = false;
    } else {
      this.isCountryValid = true;
    }
  }
  clearCountryField() {
    this.legalEntitygroup.patchValue({
      countryName: '',
      countryId: '',
    });
    this.countryData = [];
  }

  get validate() {
    return this.legalEntitygroup?.controls;
  }
  clearForm() {
    // this.legalEntitygroup.reset();
    this.router.navigate(['/home/legal-list']);

  }
  onSubmit() {

    console.log('legalentityId', this.legalentityId);

    // console.log('regDates::', registrationDate);
    console.log('this.legaldata?.registrationNumber',this.legaldata?.registrationNumber);

    // const isValidRegNumber = /^[A-Za-z]{1}\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}$/.test(this.legaldata?.registrationNumber);
    
    // if (isValidRegNumber) {
    //   // If valid, enable registrationDate and set it as required
    //   this.legalEntitygroup.get('registrationDate').enable();
    //   this.legalEntitygroup.get('registrationDate').setValidators([Validators.required]);
    // } else {
    //   // If invalid, disable registrationDate and remove the required validator
    //   this.legalEntitygroup.get('registrationDate').disable();
    //   this.legalEntitygroup.get('registrationDate').clearValidators();
    // }
  
    // // Update the validity of registrationDate
    // this.legalEntitygroup.get('registrationDate').updateValueAndValidity();
    console.log('form values::', this.legalEntitygroup.value);

    this.submitted = true;
    this.submitted1=true

    if (this.legalEntitygroup.invalid) {
      console.log('form is invalid check!!');

      return;
    }

    

    let registrationDate = this.datepipe.transform(
      this.legalEntitygroup.get('registrationDate').value,
      'yyyy-MM-dd'
    );

    console.log('registrationDate',this.legalEntitygroup.get('registrationDate').value);
    
    this.formValues = this.legalEntitygroup.value;
    let body = {
      legalEntityName: this.formValues?.legalEntityName,
      legalEntityDescription: this.formValues?.description,
      registrationNumber: this.formValues?.regNumber,
      registrationDate: registrationDate,
      countryOfIncorporation: this.formValues?.countryName,
      legalAddress: this.formValues?.legalAddress,
      operatingAddress: this.formValues?.operatingAddress,
      entityType: this.formValues?.entityType,
      businessGroup: {
        businessGroupId: this.formValues?.businessgroup,
      },
      legalEntityStatus: this.legaldata?.legalEntityStatus

    }




    console.log('body', body);

    let updateBody = { "legalEntityName": "NNK Corp", "legalEntityDescription": "Leyland description", "registrationNumber": "123456789", "registrationDate": null, "countryOfIncorporation": "USA", "legalAddress": "123 Legal Street, City, State, USA", "operatingAddress": "456 Operating Road, City, State, USA", "entityType": "private", "businessGroup": { "businessGroupId": 4 } }

    console.log('legalentityId', this.legalentityId);

    if (this.legalentityId) {
      this.isbtnDisables=true;

      this.AdminService.updateLegalEntity(this.legalentityId, body).subscribe((response:any) => {
        // this.buttonDisabled = true;
        this.isbtnDisables=true;

        if (response.statusCode === 200) {
          this.success = true;
          this.sucess_msg = response.description;
          this.isbtnDisables=true;
          setTimeout(() => {
            this.success = false;
            this.sucess_msg = '';
            this.isbtnDisables = false;
            this.router.navigate(['/home/legal-list']);
          }, 3000)
        }
      }, (err) => {
        if (err.error.statusCode === 409) {
          this.error = true;
          this.error_msg = err.error.description;
          this.isbtnDisables = false;
          setTimeout(() => {
            this.error = false;
            this.error_msg = '';
            this.isbtnDisables = false;
          }, 3000);
        }
      })
    } else {

      this.isbtnDisables=true;

      this.AdminService.createLegalEntity(body).subscribe((response:any) => {

        console.log('response', response);
        // this.buttonDisabled = true;
        this.isbtnDisables=true;

        if (response.statusCode === 201) {
          this.success = true;
          this.isbtnDisables=true;
          this.sucess_msg = response.description;
          this.buttonDisabled = false;
          setTimeout(() => {
            this.success = false;
            this.sucess_msg = '';
            this.isbtnDisables = false;
            this.router.navigate(['/home/legal-list']);
          }, 3000)
        }



      }, (err) => {
        if (err.error.statusCode === 409) {
          this.error = true;
          this.isbtnDisables = false;
          this.error_msg = err.error.description;

          setTimeout(() => {
            this.error = false;
            this.error_msg = '';
            this.isbtnDisables = false;
          }, 3000)
        }
      })
    }





  }
  entityTypeSelectionChange(event: MatSelectChange) {
    const selectedId = event.value;
    const selectedEntity = this.entityList.find(ent => ent.listTypeValueId === selectedId);

    if (selectedEntity) {
      this.entityTypeStringValue = selectedEntity.listTypeValueName;
      console.log('Selected Entity ID:', selectedId);
      console.log('Selected Entity Name:', selectedEntity.listTypeValueName);
    } else {
      console.log('No entity found for the selected ID.');
    }
  }
}
