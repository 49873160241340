import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';
import { ShareResourceFeedbackLinkComponent } from './share-resource-feedback-link/share-resource-feedback-link.component';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import *  as htmlToPdfmake from "html-to-pdfmake";
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
@Component({
  selector: 'app-resource-feedback',
  templateUrl: './resource-feedback.component.html',
  styleUrls: ['./resource-feedback.component.scss']
})
export class ResourceFeedbackComponent implements OnInit {

  description: String;
  resourceId: number;
  resFeedback: any;
  ratings: any;
  skills: any;
  recommendations: any;
  resourceObj: any;
  resName: string;
  position: string;
  comments: string;
  isDisabled: boolean = true;
  arr: any;
  resRating: any = [];
  recoName: any;

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;

  constructor(private hrmsService: HrmsserviceService, private fb: FormBuilder, private activatedRoute: ActivatedRoute, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.resourceId = this.activatedRoute.snapshot.params.resourceId;

    this.hrmsService
      .findbyResourceId(this.resourceId)
      .subscribe((resource: any) => {
        this.resourceObj = resource;
        console.log(this.resourceObj);
        this.resName = this.resourceObj.resourceName;
        console.log('this.resName', this.resName)
        this.position = this.resourceObj.position.positionName

      });
    this.hrmsService.getAllRatings()
      .subscribe((ratingList: any) => {
        this.ratings = ratingList.reverse();
        console.log('ratingList', this.ratings);
      });

    this.hrmsService.getAllSkills()
      .subscribe((skillList: any) => {
        this.skills = skillList;
        console.log('skillList', this.skills);
      })

   

    this.hrmsService.getRessourceFeedbackByResourceId(this.resourceId)
      .subscribe((feedback: any) => {
        this.resFeedback = feedback;
        console.log('feedback', this.resFeedback);
        this.resRating = this.resFeedback.resourceRating;
        console.log(' this.resRating', this.resRating);
        this.comments = this.resFeedback.comments;

        const data = this.resFeedback.resourceRating.map(x => x.rating.ratingId)
        console.log('data', data)
        this.arr = data;
        console.log(' this.recommendations', this.recommendations)
        this.hrmsService.getAllRecommendations()
        .subscribe((recList: any) => {
          console.log('recList', recList);
          this.recommendations = recList;
          console.log("this.recommendations",this.recommendations)
          if(this.recommendations != undefined){
            this.recommendations.map((recomendation) => {
              if (recomendation.listTypeValueId == feedback.recomendation.listTypeValueId) {
                console.log('equal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                recomendation.selected = !recomendation.selected;
                this.recoName = recomendation.listTypeValueName;
              } else {
                console.log('notequal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                recomendation.selected = false;
              }
            })
          }
        });
       
       
      })
  }

 
  public downloadAsPDF() {
    // let ratingDataRow = [];
    // this.ratings.forEach((element, i) => {
    //   ratingDataRow.push({
    //     text: element.ratingId + '.' + element.ratingName
    //   });
    // });
    console.log("download");
    var data = document.getElementById('pdfTable');

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML, {tableAutoSize: true,});
    console.log('html', html);

    const documentDefinition = {content: html,
      pageMargins: [40, 40, 40, 60],
      imagesByReference: true,
      info: {
        title: `Candidate Evaluation Form`
      },

      
    };
    console.log(documentDefinition);
    pdfMake.createPdf(documentDefinition).download(`Candidate Evaluation Form.pdf`);
}

  printpdf()
  {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { content: html ,
      pageMargins: [ 40, 40, 40, 60 ],
      info: {
        title:`Candidate Evaluation Form`
        }
    };
    pdfMake.createPdf(documentDefinition).print(); 
  }


  // downloadAsPDF(){
  //   var data = document.getElementById('pdfTable');
  //   const pdfTable = this.pdfTable?.nativeElement;
  //   var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).download(`Candidate Evaluation Form.pdf`); 

  // }
  // printpdf() {
  //   const pdfTable = this.pdfTable?.nativeElement;
  //   var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).print();
  // }
  
  openDialog1(resourceId, feedbackId): void {
    console.log('resid',resourceId)
    console.log('feeid',feedbackId)
    const dialogRef = this.dialog.open(ShareResourceFeedbackLinkComponent, {panelClass: 'feedback-link-panel'});
    dialogRef.componentInstance.resourceId = resourceId;
    dialogRef.componentInstance.feedbackId = feedbackId;
  }

  back()
  {
    this.router.navigate(['home/resource-list'])
  }
}
