<!-- <div class="container" > -->

  <!-- <div> -->

    <nav class="navbar bg" style="margin-top: 68px;">
      <div class="container-fluid">
        <div class="navbar-brand" style="    font-size: 22px;
        font-family: 'Mulish', sans-serif;
        font-weight: 800;
        ">Minutes of Meeting</div>
      </div>
    </nav>
    <mat-card>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="form-group col-md-4 ">
          <label class="form-control-label">MOM Type<span class="text-danger"> *</span></label>
          <mat-select formControlName="projectValue" placeholder="Select MOM Type" class="form-control email-input select-placeholder">
            <mat-option *ngFor="let pro of myProject" (click)="onChange(pro.id)" [value]="pro.id">
              {{pro.value}} 
            </mat-option>
          </mat-select>
          <div *ngIf="(f.projectValue.invalid && submitted) || f.projectValue.dirty">
            <small *ngIf="f.projectValue.errors?.required" class="text-danger">MOM type is required</small>
          </div>
            <!-- <mat-error *ngIf="f.projectValue.hasError('required')">
              MOM Type is required
            </mat-error> -->
        </div>
        <div class="form-group col-md-4 " *ngIf="isShow">
          <label class="form-control-label">Project<span class="text-danger"> *</span></label>
          <mat-select formControlName="project" class="form-control email-input select-placeholder" placeholder="Select project">
            <mat-option *ngFor="let pro of projectList" [value]="pro.projectId">
              {{pro.projectName}}
            </mat-option>
          </mat-select>
          <!-- <div *ngIf="(f.project.invalid && submitted) || f.project.dirty">
            <small *ngIf="f.project.errors?.required" class="text-danger">Project name is required</small>
          </div> -->
          <div
        *ngIf="
          project.invalid  ||
          project.dirty
        ">
      
        <small *ngIf="project.errors?.pattern" class="text-danger"
          >Please enter valid Project </small>
      </div>
      <div
        *ngIf="myForm.get('project').hasError('required') && submitted"  class="text-danger">
  <small class="text-danger">Project is required
  </small>           
  </div>
            <!-- <mat-error *ngIf="f.project.hasError('required')">
              Project Name is required
            </mat-error> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 ">
          <label class=" form-control-label">MOM Name<span class="text-danger"> *</span></label>
          <input formControlName="momName" placeholder="Enter MOM name" 
    
          class="form-control email-input select-placeholder">
          <!-- <div *ngIf="(f.momName.invalid && submitted) || f.momName.dirty">
            <small *ngIf="f.momName.errors?.required" class="text-danger">MOM name is required</small>
            <small *ngIf="f.momName.errors?.whitespace" class="text-danger">MOM name is required</small> -->
            <!-- <small *ngIf="f.momName.errors?.pattern" class="text-danger">Please enter valid category name</small>    -->
          <!-- </div> -->
          <!-- <div *ngIf="(f.momName.invalid && f.momName.touched) || f.momName.dirty">
            <small *ngIf="f.momName.errors?.pattern" class="text-danger">Please enter valid category name</small>   
        </div>  -->
          <!-- <mat-error *ngIf="f.momName.hasError('required')">
            MOM Name is required
          </mat-error> -->
          <div *ngIf="(submitted && momName.invalid) || momName.dirty">
            <small *ngIf="momName.errors?.required" class="text-danger"
              >Mom Name is required</small
            >
          </div>
          <div
          *ngIf="
            momName.invalid || momName.dirty"
>
        <small *ngIf="momName.errors?.pattern" class="text-danger"
        >Please enter valid  MOM Name</small>
    </div>
    <!-- <div
    *ngIf="
      myForm.get('momName').hasError('required') && submitted
    "  class="text-danger">
<small class="text-danger">Mom Name is required
</small>           
</div> -->
<!-- <div *ngIf="submitted && myForm.get('momName').invalid && myForm.get('momName').errors">
  <small class="text-danger" *ngIf="myForm.get('momName').errors.required">MOM name is required</small>
  <small class="text-danger" *ngIf="myForm.get('momName').errors.pattern">Please enter a valid MOM name</small>
</div> -->
        </div>
        <div class="col-md-4 ">
          <label class="form-control-label">MOM Date<span class="text-danger"> *</span></label>
          <input matInput [matDatepicker]="picker1" formControlName="momDate" readonly  placeholder="Select MOM Date" class="form-control email-input select-placeholder"
          [max]="tomorrow" style="height: 43px;" (dateChange)="dateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <div *ngIf="(f.momDate.invalid && submitted) || f.momDate.dirty">
            <small *ngIf="f.momDate.errors?.required" class="text-danger">MOM date is required</small>
          </div>
            <!-- <mat-error *ngIf="f.momDate.hasError('required')">
              MOM Date is required
            </mat-error> -->
        </div>
      </div>
      <div style="position: relative;bottom:15px">
        <div class="row">
          <div class="form-group col-md-4 ">
            <label class="form-control-label">Client Name<span class="text-danger"> *</span></label>
            <!-- <input matInput formControlName="client" (keypress)="keyPress($event)"
            placeholder="Enter Client Name" 
            class="form-control email-input select-placeholder"> -->
            <mat-select 
            formControlName="client" (keypress)="keyPress($event)"
            placeholder="Select Client Name" 
            class="form-control email-input select-placeholder">
              <mat-option *ngFor="let e of customerList" [value]="e.clientId">
                {{ e.clientName}}
              </mat-option>
            </mat-select>
            <div *ngIf="(f.client.invalid && submitted) || f.client.dirty">
              <small *ngIf="f.client.errors?.required" class="text-danger">Client Name is required</small>
            </div>
            
          </div>
          <div class="form-group col-md-4 ">
            <label class="form-control-label">Meeting Place<span class="text-danger"> *</span></label>
            <input matInput formControlName="meetingPlace"
            placeholder="Enter Meeting Place" 
            class="form-control email-input select-placeholder">
            <!-- <div *ngIf="(f.meetingPlace.invalid && submitted) || f.meetingPlace.dirty">
              <small *ngIf="f.meetingPlace.errors?.required" class="text-danger">Meeting place is required</small>
            </div> -->
            <div *ngIf="(submitted && meetingPlace.invalid) || meetingPlace.dirty">
              <small *ngIf="meetingPlace.errors?.required" class="text-danger"
                >Meeting Place is required</small
              >
            </div>
            <div
            *ngIf="
              meetingPlace.invalid || meetingPlace.dirty"
          >
          <small *ngIf="meetingPlace.errors?.pattern" class="text-danger"
          >Please enter valid Meeting Place</small
        >
      </div>
      <!-- <div
      *ngIf="
        myForm.get('meetingPlace').hasError('required') && submitted
      "  class="text-danger">
  <small class="text-danger">Meeting Place is required
  </small>           
  </div> -->
            
          </div>
          <div class="form-group col-md-4 full-wid">
            <label class="form-control-label">Attendees<span class="text-danger"> *</span></label>
            <textarea matInput formControlName="momAttendees"  rows="1" 
            placeholder="Enter Attendees"
            style="font-size: 15px; overflow-x: auto; overflow-y: hidden; min-height: 43px; border: none; padding: 8px;"

            class="form-control email-input select-placeholder"></textarea>
            <!-- <div *ngIf="(f.momAttendees.invalid && submitted) || f.momAttendees.dirty">
              <small *ngIf="f.momAttendees.errors?.required" class="text-danger">Attendees are required</small>
            </div> -->

            <div
            *ngIf="
              momAttendees.invalid || momAttendees.dirty"
          >
          <small *ngIf="momAttendees.errors?.pattern" class="text-danger"
          >Please enter valid Attendees</small
        >
      </div>
     
  <div *ngIf="(submitted && momAttendees.invalid) || momAttendees.dirty">
    <small *ngIf="momAttendees.errors?.required" class="text-danger"
      >Attendees is required</small
    >
  </div>
             
          </div>
        </div>
        <div formArrayName="pointsDiscussed">
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="col-md-6 float-left">
                    <b>The following points were discussed in the meeting.</b>
                  </div>
                  <div class="col-md-6 float-left">
                    <button type="button" class="btn add-buttons" (click)="add()">
                     <mat-icon class="material-symbols-outlined addDesign" style="    /* font-size: 22px; */
                     font-size: 22px;
                     margin-left: 2px;
                     margin-bottom: 0px;
                     margin-top: -3px;;">add</mat-icon>
                     </button>
                   </div>
                </div>
               
              </div>
              <div *ngFor="let con of content().controls; let i=index">
              <div [formGroupName]="i" class="form-group "> 
                  <div  >
                      <label class="form-control-label">Discussed Points<span class="text-danger"> *</span></label>
                      <textarea formControlName="pointsDiscussedDetails"  
                      placeholder="Enter Discussed Points"
                      class="form-control email-input select-placeholder-agreed"></textarea>
                     
                <div *ngIf="con['controls'].pointsDiscussedDetails">
                  <div *ngIf="((submitted && con['controls'].pointsDiscussedDetails.invalid) || con['controls'].pointsDiscussedDetails.dirty) && (con['controls'].pointsDiscussedDetails.errors?.pattern)">
                    <small class="text-danger">Please enter valid Discussed Points Details</small>
                  </div>
                 
                  <div *ngIf="((submitted && con['controls'].pointsDiscussedDetails.invalid) || con['controls'].pointsDiscussedDetails.dirty) ">
                    <small *ngIf="con['controls'].pointsDiscussedDetails.errors?.required" class="text-danger">Discussed Points Details is required</small>
                  </div>
                </div>
                <!-- <div
                *ngIf="
                  myForm.get('pointsDiscussedDetails').hasError('required') && submitted
                "  class="text-danger">
            <small class="text-danger">pointsDiscussedDetails  is required
            </small>           
            </div> -->

                    </div>
                    
                  <div class="aligncenter" >
                    <mat-icon matSuffix (click)="remove(i)" class="material-icons-outlined delete-icon" *ngIf="i >= 1" style=""
                   >
                      delete
                    </mat-icon>
                  </div>
            </div>
          </div>


        </div>
        <div formArrayName="pointsAgreed">
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="col-md-6 float-left">
                    <b>The following points were agreed in the meeting.</b>
                  </div>
                  <div class="col-md-6 float-left">
                   <button type="button"  class="btn add-buttons" (click)="addAgree()">
                     <mat-icon class="material-symbols-outlined addDesign" style="    /* font-size: 22px; */
                     font-size: 22px;
                     margin-left: 2px;
                     margin-bottom: 0px;
                     margin-top: -3px;">add</mat-icon>
                   </button>
                  </div>
                </div>
              </div>
          
              <div *ngFor="let con of agree().controls; let i=index">
              <div [formGroupName]="i" >
                  <div class="form-group full-width">
                      <label class="form-control-label">Agreed points<span class="text-danger"> *</span></label>
                      <textarea formControlName="pointsAgreedDetails" 
                      placeholder="Enter Agreed points" matInput
                      class="form-control email-input select-placeholder-agreed" ></textarea>
                      <div *ngIf="(submitted && con['controls'].pointsAgreedDetails.invalid) || con['controls'].pointsAgreedDetails.dirty">
                        <small *ngIf="con['controls'].pointsAgreedDetails.errors?.required" class="text-danger"> Agreed Points are required</small>
                      </div>
                      <div *ngIf="con.get('pointsAgreedDetails')">
                        <div *ngIf="((submitted && con.get('pointsAgreedDetails').invalid) || con.get('pointsAgreedDetails').dirty) && (con.get('pointsAgreedDetails').errors?.pattern)">
                          <small class="text-danger">Please enter valid Agreed Points </small>
                        </div>
                        <!-- <div *ngIf="con.get('pointsAgreedDetails').errors?.required && submitted" class="text-danger">
                          <small class="text-danger"> Agreed points is required</small>           
                        </div> -->
                      </div>
                      <!-- <div
                      *ngIf="
                        pointsAgreedDetails.invalid || pointsAgreedDetails.dirty"
                    > -->
                    <!-- <small *ngIf="pointsAgreedDetails.errors?.pattern" class="text-danger"
                    >Please enter valid  pointsAgreedDetails</small
                  > -->
                <!-- </div>
                <div
                *ngIf="
                  myForm.get('pointsAgreedDetails').hasError('required') && submitted
                "  class="text-danger">
            <small class="text-danger">pointsAgreedDetails  is required
            </small>           
            </div>
                       -->
                  </div>
                  <div class="aligncenter  text-end">
                    <mat-icon matSuffix (click)="removeAgree(i)" class="material-icons-outlined delete-icon" *ngIf="i >= 1"
                   >delete</mat-icon>
                  </div>
              </div>
            </div>
        </div>
        <!-- <div formArrayName="actionItem">
              <div class="row">
                <div class="col-md-12 ">
                  <div class="col-md-6 float-left"  >
                    <b>The following points were action items in the meeting.</b>
                  </div>
                   <div class="col-md-6 float-left" style="    margin-bottom: 16px;
                   margin-top: -5px;">
                     <button type="button" class="btn add-buttons" (click)="addItem()">
                       <mat-icon class="material-symbols-outlined addDesign" style="    /* font-size: 22px; */
                       font-size: 22px;
                       margin-left: 2px;
                       margin-bottom: 0px;
                       margin-top: -3px;;">add</mat-icon>
                     </button>
                   </div>
                </div>
            
              <div *ngIf="myForm.value.actionItem" style="width: 90%;margin-left: 15px;">
                <div *ngFor="let action of actionItem().controls; let i=index">
                  <div [formGroupName]="i" > -->
                    <!-- <div class="row" >
                      <div>
                          <label class="form-control-label" style="margin-top: 10px;margin-left: 36px;">Action Item Name<span class="text-danger"> *</span></label>
                          <input matInput formControlName="actionItemName"
                          placeholder="Enter Action Item Name" (keypress)="keyPress($event)"
                          class="form-control email-input select-placeholder" style="max-width: 77%;margin-left: 35px;"/>
                         

                          <div *ngIf="((submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty) && (action.get('actionItemName').errors?.pattern)">
                            <small class="text-danger">Please enter a valid Action Item Name</small>
                          </div>
                          <div *ngIf="action.get('actionItemName').errors?.required && submitted" class="text-danger">
                            <small class="text-danger">Action Item Name is required.</small>
                          </div>
                      </div>
                      <div class="col-md-2 full-width">
                          <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                          <input matInput [matDatepicker]="picker" readonly class="form-control email-input" formControlName="actionItemTargetDate"
                          placeholder="Select target date" style="height: 43px;" [min]="momdate">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                            <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target date is required</small>
                          </div> 
                        
                      </div>
                     
                      <div class="col-md-3 full-width">
                          <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                          <input matInput formControlName="actionItemOwner"
                          placeholder="Enter Action Item Owner" (keypress)="keyPress($event)"
                          class="form-control email-input select-placeholder"/>
                         
                          <div *ngIf="((submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty) && (action.get('actionItemOwner').errors?.pattern)">
                            <small class="text-danger">Please enter a valid Action Item Owner</small>
                          </div>
                          <div *ngIf="action.get('actionItemOwner').errors?.required && submitted" class="text-danger">
                            <small class="text-danger">Action Item Owner is required.</small>
                          </div> -->

                          <!-- <div
                          *ngIf="
                            actionItemOwner.invalid || actionItemOwner.dirty"
                        > -->
                        <!-- <small *ngIf="actionItemOwner.errors?.pattern" class="text-danger"
                        >Please enter valid  actionItemOwner</small
                      > -->
                    <!-- </div> -->
                    <!-- <div
                    *ngIf="
                      myForm.get('actionItemOwner').hasError('required') && submitted
                    "  class="text-danger">
                <small class="text-danger">actionItemOwner  is required
                </small>           
                </div> -->



                       
                      <!-- </div>
                      <div class="col-md-1 aligncenter">
                        <mat-icon matSuffix (click)="removeItem(i)" class="material-icons-outlined deleteitem-icon"
                        *ngIf="i >= 1">delete</mat-icon>
                      </div>
                    </div> -->
                    <!-- <div class="row" > -->
                      <!-- <div class="col-md-4 full-width">
                          <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
                          <input matInput formControlName="actionItemName"
                          placeholder="Enter Action Item Name" (keypress)="keyPress($event)"
                          class="form-control email-input select-placeholder"/>
                        
                          <div *ngIf="((submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty) && (action.get('actionItemName').errors?.pattern)">
                            <small class="text-danger">Please enter a valid Action Item Name</small>
                          </div>
                          <div *ngIf="action.get('actionItemName').errors?.required && submitted" class="text-danger">
                            <small class="text-danger">Action Item Name is required.</small>
                          </div>
                       
                      </div>
                      <div class="col-md-3 full-width">
                          <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                          <input matInput [matDatepicker]="picker"readonly formControlName="actionItemTargetDate"
                          placeholder="Select target date" class="form-control email-input select-placeholder"  
                          style="height: 43px;" [min]="momdate">
                          <mat-datepicker-toggle matSuffix [for]="picker" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                            <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target date is required</small>
                          </div> 
                        
                      </div>
                      <div class="col-md-5 full-width M-responsive">
                        <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                        <input matInput formControlName="actionItemOwner"
                        placeholder="Enter Action Item Owner"(keypress)="keyPress($event)"
                        class="form-control email-input select-placeholder"/>
                       
                        <div *ngIf="((submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty) && (action.get('actionItemOwner').errors?.pattern)">
                          <small class="text-danger">Please enter a valid Action Item Owner</small>
                        </div>
                        <div *ngIf="action.get('actionItemOwner').errors?.required && submitted" class="text-danger">
                          <small class="text-danger">Action Item Owner is required.</small>
                        </div>
                       
                        <div class="aligncenter1">
                          <mat-icon matSuffix (click)="removeItem(i)" class="material-icons-outlined deleteitem-icon"
                          *ngIf="i >= 0">delete</mat-icon>
                        </div>
                      </div> -->
                    <!-- </div> -->
                  <!-- </div>
                </div>
                </div>
              </div> -->
              <div formArrayName="actionItem">
                <!-- <mat-card> -->
                  <div class="row">
                    <div class="col-md-12 p-0">
                      <div class="col-md-6 float-left">
                        <b>The following points were action items in the meeting.</b>
                      </div>
                       <div class="col-md-6 float-left">
                        <button type="button" class="btn add-buttons" (click)="addItem()">
                          <mat-icon class="material-symbols-outlined addDesign" style="    /* font-size: 22px; */
                          font-size: 22px;
                          margin-left: 2px;
                          margin-bottom: 0px;
                          margin-top: -3px;;">add</mat-icon>
                        </button>
                       </div>
                    </div>
                  </div>
                  <!-- <div align="end"  *ngIf="myForm.value.actionItem">
                      <button type="button" class="btn add-buttons" (click)="addItem()">Add</button>
                  </div> -->
                  <div *ngIf="myForm.value.actionItem">
                    <div *ngFor="let action of actionItem().controls; let i=index">
                      <div [formGroupName]="i" >
                        <div class="row" *ngIf="momId">
                          <div class="col-md-4 ">
                              <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
                              <input matInput formControlName="actionItemName"
                              placeholder="Enter Action Item Name" (keypress)="keyPress($event)"
                              class="form-control email-input select-placeholder"/>
                              <div *ngIf="action.get('actionItemName')">
                                <div *ngIf="action.get('actionItemName').invalid || action.get('actionItemName').dirty">
                                  <small *ngIf="action.get('actionItemName').errors?.pattern" class="text-danger">
                                    Please enter a valid Action Item Name
                                  </small>
                                  <small *ngIf="action.get('actionItemName').errors?.required" class="text-danger">
                                    Action Item Name is required
                                  </small>
                                </div>
                              </div>
                              <!-- <div *ngIf="((submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty) && (action.get('actionItemName').errors?.pattern)">
                                <small class="text-danger">Please enter a valid Action Item Name</small>
                              </div>
                              <div *ngIf="(submitted && con['controls'].actionItemName.invalid) || con['controls'].actionItemName.dirty">
                                <small *ngIf="con['controls'].actionItemName.errors?.required" class="text-danger"> actionItemNameare required</small>
                              </div> -->
                              <!-- <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty" class="text-danger">
                                <div *ngIf="action.get('actionItemName').errors.required" class="text-danger">
                                <small  class="text-danger">Action Item Name is required.</small>
                                </div>
                              </div> -->
                         <!-- <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty">
                                <small *ngIf="action['controls'].actionItemName.errors?.required" class="text-danger">Action item name is required</small>
                              </div>  -->
                              <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemName').errors?.required">
                                Action Item Name is required
                              </mat-error> -->
                          </div>
                          <div class="col-md-2 ">
                              <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                              <input matInput [matDatepicker]="picker" class="form-control email-input select-placeholder" readonly formControlName="actionItemTargetDate"
                              placeholder="Select Target Date" style="height: 43px;" [min]="momdate">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                              <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                                <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target date is required</small>
                              </div> 
                              <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemTargetDate').errors?.required">
                                Target Date is required
                              </mat-error> -->
                          </div>
                          <!-- <div class="col-md-2 full-width">  
                              <label class="form-control-label">Action Status<span class="text-danger"> *</span></label>
                              <mat-select formControlName="actionItemStatus" readonly placeholder="Action item status">
                                <mat-option value="Pending">Pending</mat-option>
                                <mat-option value="Completed">Completed</mat-option>
                              </mat-select>
                              <div *ngIf="(submitted && action['controls'].actionItemStatus.invalid) || action['controls'].actionItemStatus.dirty">
                                <small *ngIf="action['controls'].actionItemStatus.errors?.required" class="text-danger">Action status is required</small>
                              </div>  -->
                              <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemStatus').errors?.required">
                                Action Status is required
                              </mat-error> -->
                          <!-- </div> -->
                          <div class="col-md-3 ">
                              <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                              <input matInput formControlName="actionItemOwner"
                              placeholder="Enter Action Item Owner" (keypress)="keyPress($event)"
                              class="form-control email-input select-placeholder"/>
                              <!-- <div *ngIf="((submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty) && (action.get('actionItemOwner').errors?.pattern)">
                                <small class="text-danger">Please enter a valid Action Item Owner</small>
                              </div> -->
                              <!-- <div *ngIf="(submitted && actionItemOwner.invalid) || actionItemOwner.dirty">
                                <small *ngIf="actionItemOwner.errors?.required" class="text-danger"
                                  >actionItemOwner is required</small
                                >
                              </div> -->
                              <div *ngIf="action.get('actionItemOwner').errors?.required && submitted" class="text-danger">
                                <small class="text-danger">Action Item Owner is required</small>
                              </div>
                              <!-- <div *ngIf="(submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty">
                                <small *ngIf="action['controls'].actionItemOwner.errors?.required" class="text-danger">Action item owner is required</small>
                              </div>  -->
                              <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemOwner').errors?.required">
                                Action Item Owner is required
                              </mat-error> -->
                          </div>
                          <div class="col-md-1 aligncenter">
                            <mat-icon matSuffix (click)="removeItem(i)" class="material-icons-outlined deleteitem-icon"
                            *ngIf="i >= 1">delete</mat-icon>
                          </div>
                        </div>
                        <div class="row" *ngIf="!momId">
                          <div class="col-md-4 ">
                              <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
                              <input matInput formControlName="actionItemName"
                              placeholder="Enter Action Item Name" (keypress)="keyPress($event)"
                              class="form-control email-input select-placeholder"/>
                             
                              <div *ngIf="action.get('actionItemName')">
                                <div *ngIf="action.get('actionItemName').invalid || action.get('actionItemName').dirty">
                                  <small *ngIf="action.get('actionItemName').errors?.pattern" class="text-danger">
                                    Please enter a valid Action Item Name
                                  </small>
                                
                                </div>
                              </div>
    
                              <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty">
                                <small *ngIf="action['controls'].actionItemName.errors?.required" class="text-danger">Action Item name is required</small>
                              </div> 
                              <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemName').errors?.required">
                                Action Item Name is required
                              </mat-error> -->
                          </div>
                          <div class="col-md-3 ">
                              <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                              <input matInput [matDatepicker]="picker" formControlName="actionItemTargetDate"
                              placeholder="Select Target Date" readonly class="form-control email-input select-placeholder"  
                              style="height: 38px;" [min]="momdate">
                              <mat-datepicker-toggle matSuffix [for]="picker" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                              <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                                <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target date is required</small>
                              </div> 
                              <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemTargetDate').errors?.required">
                                Target Date is required
                              </mat-error> -->
                          </div>
                          <div class="col-md-5  M-responsive">
                            <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                            <input matInput formControlName="actionItemOwner"
                            placeholder="Enter Action Item Owner"(keypress)="keyPress($event)"
                            class="form-control email-input select-placeholder"/>
                            <div *ngIf="action.get('actionItemOwner')">
                              <div *ngIf="action.get('actionItemOwner').invalid || action.get('actionItemOwner').dirty">
                                <small *ngIf="action.get('actionItemOwner').errors?.pattern" class="text-danger">
                                  Please enter a valid Action Item Owner 
                                </small>
                              
                              </div>
                            </div>
  
                            <div *ngIf="(submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty">
                              <small *ngIf="action['controls'].actionItemOwner.errors?.required" class="text-danger">Action Item Owner is required</small>
                            </div> 
                              <!-- <div *ngIf="(submitted && actionItemOwner.invalid) || actionItemOwner.dirty">
                                <small *ngIf="actionItemOwner.errors?.required" class="text-danger"
                                  >actionItemOwner is required</small
                                >
                              </div> -->
                            <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemOwner').errors?.required">
                              Action Item Owner is required
                            </mat-error> -->
                            <div class="aligncenter1">
                              <mat-icon matSuffix (click)="removeItem(i)" class="material-icons-outlined deleteitem-icon"
                              *ngIf="i >= 0">delete</mat-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- </mat-card> -->
            </div>
     
        <div align="end">
          <button mat-raised-button type="button" class="btn cancel-button m-1"
          (click)="cancel()" style="color:var(--Red-Color, #FF5D5D);height: 34px;">Cancel</button>
          <button mat-raised-button type="submit" style="height: 34px;" class="btn create-button m-1" [disabled]="isbuttondisable">
            Save</button>
        </div>
      <!-- </div> -->
      </div>
    </form>
  </mat-card>
  <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{errorMsg}}
    <button *ngIf="importerror" (click)="showerrormessage()"   class="btn btn-danger">view report recoeds
    </button>
</div>
  <!-- </div> -->
  <!-- </div> -->
  
  
