import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeaveService } from 'src/app/providers/leave.service';

@Component({
  selector: 'app-time-settings-form',
  templateUrl: './time-settings-form.component.html',
  styleUrls: ['./time-settings-form.component.scss']
})
export class TimeSettingsFormComponent implements OnInit {
  timesettingsForm:FormGroup;
  isSubmitted: boolean = false;
  settingsdata:any = {};
  isLoading:any = false;
  pasteCount: any;
isDisabled: any;
lastValidValue:any;
  constructor(private fb: FormBuilder,
    private GlobalserviceService : GlobalserviceService,
    private datepipe : DatePipe,
    private router:Router,
    private dialogRef : MatDialogRef<TimeSettingsFormComponent> ,
      @Inject(MAT_DIALOG_DATA) public data: any, 
    private leaveservice:LeaveService
      ) { }

  ngOnInit(): void {

    
    this.timesettingsForm = this.fb.group({
      mintime:['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]] ,
      maxtime:['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]] 
    })

    this.leaveservice.
    getsettingsbyId(this.data)
    .subscribe
    ((data:any)=>
    {
      this.settingsdata = data;
      this.timesettingsForm.patchValue({
        mintime: this.settingsdata.minimumWokrHours,
        maxtime:this.settingsdata.maximumWokrHours,
      })
    }
    )
  }
  handleBackspace(event): void {
    const inputValue = event.target.value;

    if (event.key === 'Backspace' && inputValue.length <= 1) {
    }
  }
    validateInput(event: Event): void {
      const input = event.target as HTMLInputElement;
      const value = input.value;
    
      // Allow empty value (for backspace)
      if (value === '') {
        this.lastValidValue = ''; // Update last valid value to empty
        this.timesettingsForm.controls['mintime'].setValue(''); // Update form control
        return;
      }
    
      // Check if the new value is valid (numeric and of 1 digit)
      if (/^\d{1}$/.test(value)) {
        this.lastValidValue = value; // Update last valid value
      } else {
        // Revert to last valid value if the current input is invalid
        input.value = this.lastValidValue;
        this.timesettingsForm.controls['mintime'].setValue(this.lastValidValue);
      }
    }
 
  validateInput1(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
  
    // Allow empty value (for backspace)
    if (value === '') {
      this.lastValidValue = ''; // Update last valid value to empty
      this.timesettingsForm.controls['maxtime'].setValue(''); // Update form control
      return;
    }
  
    // Check if the new value is valid (numeric and of 1 digit)
    if (/^\d{1}$/.test(value)) {
      this.lastValidValue = value; // Update last valid value
    } else {
      // Revert to last valid value if the current input is invalid
      input.value = this.lastValidValue;
      this.timesettingsForm.controls['maxtime'].setValue(this.lastValidValue);
    }
  }
  // save(){
  //   console.log("Submit");
  //   this.isSubmitted=true;
  //   if(this.timesettingsForm.invalid){
  //     return
  //   }
  //   this.isLoading = true;

  //     var settingsobj = {
      
  //         "settingsId":  this.settingsdata.settingsId,
  //         "minimumWokrHours": this.timesettingsForm.value.mintime,
  //         "maximumWokrHours": this.timesettingsForm.value.maxtime,
  //         "status":  this.settingsdata.status,
  //         "maximumPermissionHours":this.settingsdata.maximumPermissionHours,
  //     }

  //      console.log(settingsobj);


  //      this.leaveservice.
  //      updatesettings(settingsobj)
  //      .subscribe
  //      ((d:any)=>
  //      {
  //       Swal.fire({
  //         text: d.description,
  //         icon: 'success'
  //       })
  //        this.dialogRef.close(d);
  //       setTimeout(() => {
  //         // this.router.navigate(['home/Emptimesettings-list'])
  //        }, 3000);
  //       this.isLoading = false;
  //      },
  //      err => {
  //        Swal.fire({
  //          text: err.error.description,
  //          icon: 'error'
  //        })
  //        this.isLoading = false;
  //      }
  //      )
  // }


  save() {
    console.log("Submit");
    this.isSubmitted = true;
    
    if (this.timesettingsForm.invalid) {
      return;
    }
  
    const minTime = this.timesettingsForm.value.mintime;
    const maxTime = this.timesettingsForm.value.maxtime;
    
    // Validation: Ensure maximum time is greater than minimum time
    // if (minTime >= maxTime) {
    //   Swal.fire({
    //     text: 'Maximum Time should be greater than Minimum Time.',
    //     icon: 'error'
    //   });
    //   return;
    // }
    if (minTime >= maxTime) {
      alert('Maximum Time should be greater than Minimum Time.');
      return;
    }
  
    this.isLoading = true;
    this.isDisabled = true;
  
    const settingsobj = {
      "settingsId": this.settingsdata.settingsId,
      "minimumWokrHours": minTime,
      "maximumWokrHours": maxTime,
      "status": this.settingsdata.status,
      "maximumPermissionHours": this.settingsdata.maximumPermissionHours,
    };
  
    console.log(settingsobj);
  
    this.leaveservice
      .updatesettings(settingsobj)
      .subscribe(
        (d: any) => {
          Swal.fire({
            text: d.description,
            icon: 'success'
          });
          this.dialogRef.close(d);
          setTimeout(() => {
            // this.router.navigate(['home/Emptimesettings-list'])
          }, 3000);
          this.isLoading = false;
        },
        err => {
          Swal.fire({
            text: err.error.description,
            icon: 'error'
          });
          this.isLoading = false;
          this.isDisabled = false; // Re-enable form inputs if an error occurs

        }
      );
  }
  
  noNumber(event: any) {
    const pattern = /[1-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    const inputElement = event.target;
    const currentValue = inputElement.value;
  
    // Check if the input character is not a number and not a dot
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      return;
    }
  
    // Allow dot only if there is a numeric expression before it
    if (inputChar === '.') {
      if (currentValue === '' || currentValue.endsWith('.')) {
        event.preventDefault();
        return;
      }
    }
  }
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }
}

