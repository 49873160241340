import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { TaskCreationComponent } from './task-creation/task-creation.component';
// import { TaskUpdationComponent } from './task-updation/task-updation.component';
import { Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { TaskCreationComponent } from '../task-creation/task-creation.component';
import { TaskUpdationComponent } from '../task-updation/task-updation.component';
import { HeaderService } from 'src/app/providers/header.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { Subscription } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-assign-task',
  templateUrl: './assign-task.component.html',
  styleUrls: ['./assign-task.component.scss'],
})
export class AssignTaskComponent implements OnInit {
  success = false;
  private successMessageSubscription: Subscription;
  error: any = false;
  sucess_msg: any;
  errormessagejson: any;
  error_msg: any;
  taskList: any = [];
  finalTaskList: any = [];
  isData: boolean;
  Id: any;
  myData: any;
  user_type: string;
  search: any;
  projectName: any = {};
  taskEmpList: any[] = [];
  employeeId;
  menuData: any = [];
  // p: any
  p: number = 1;
  taskShow: boolean;
  beforesearchpage: any;
  nodata = false;
  endSelectedFirst: boolean;

  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  enddate_required: any;
  startdate_required: any;
  startDate: any;
  endDate: any;
  selectedetaskGroupId: any;
  today: any;
  taskgrouplist: any;
  taskStatusId: any;
  taskgroup_required: any;
  loader: boolean = true;
  listCount: any;
  // assignListForm:FormGroup;
  // isSubmitted = false;
  errorMsg = false;
  isdsbtnDisable: boolean = false;
  startdate: any;
  maxEndDate: Date;
  successMessage: any;
  tableShow: boolean = false;
  finalTaskListPush: any;
  usertData: any;
  userType: any;
  isHumanResource:any;
  isRequirements: any;
  isAdmin: any;
  isEmployee:any;
  isUserAdmin:boolean = false;
  disableSaveButton:boolean =  true;

  constructor(
    private gs: ProjectManagementService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private headerservice: HeaderService,
    private timesheet: TimesheetService,
    public dialogRef: MatDialogRef<TaskCreationComponent>,

    private fb: FormBuilder
  ) { let x = JSON.parse(localStorage.getItem('enoteUserData'));
    {
      this.User_Type(x);
    }


  }

  ngOnInit() {
    // this.headerservice.setTitle('Add Task')
    this.headerservice.setTitle('');
    this.isData = false;
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.userType = [];
    if(this.usertData?.employee != undefined){
      if (this.usertData.employee.role != undefined) {
        // Populate userType array with roles
        this.usertData?.employee?.role?.forEach((role) => {
          this.userType.push(role?.roleName);
        });
        // Check if the user is an Admin or Recruiter
        this.isAdmin = this.userType.includes('Admin');
        this.isRequirements = this.userType.includes('Recruiter');
        this.isEmployee = this.userType.includes('Employee');
        this.isHumanResource = this.userType.includes('Human Resource');
      }
    }
    else{
      if (this.usertData.role != undefined) {
        this.usertData?.role?.forEach((role) => {
          this.userType.push(role?.roleName);
        });
        this.isUserAdmin = true;
      }
    }
    this.user_type = x?.userType?.userType;
    this.employeeId = x?.employeeId;
    {
      this.routingParams();
    }
    // if(this.user_type == 'HumanResource' || this.user_type == 'Manager') {
    {
      this.getTask(this.Id);
    }
    // }
    // else{
    {
      this.Project(this.Id);
    }
    // }
    // this.today = new Date();
    // this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');

    //Form Group
    // this.assignListForm = this.fb.group({
    //   StartDate:['',Validators.required],
    //   EndDate:['',Validators.required]
    // })
    this.getTasklist();

    this.successMessageSubscription = this.timesheet
      .getAddTaskSuccessMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        if (message) {
          this.success = true;
          this.successMessage = message;
        }

        // Close the success message after 3 seconds (adjust duration as needed)
        setTimeout(() => {
          this.success = false;
          this.successMessage = '';
          // this.closeASuccessMessage();
        }, 3000);
      });
  }

  private User_Type(x) {
    this.user_type = x?.userType?.userType;
  }


  ngOnDestroy() {
    console.log('calling ngOnDestroy');
    this.success = false;
    this.successMessage = '';
    this.timesheet.setAddTaskSuccessMessage('');
    if (this.successMessageSubscription) {
      this.successMessageSubscription.unsubscribe();
    }
  }
  getTasklist() {
    this.gs.gettaskgroup().subscribe((data: any) => {
      // this.loader = false;
      this.taskgrouplist = data;
      this.tableShow = true;
      console.log('this.taskgrouplist', this.taskgrouplist);
    });
  }
  private routingParams() {
    let id: any;
    this.route.paramMap.subscribe((d) => {
      let x = d.get('id');
      this.Id = x;
      id = x;
    });
  }

  private getTask(id) {
    // this.finalTaskList=[]
    //if (this.user_type == 'HumanResource' || this.user_type == 'Manager') {
    this.gs.getTaskById(id).subscribe(
      (d: any) => {
        console.log(d);
        this.finalTaskList = [];
        this.finalTaskList = d;

          this.loader = false;
          this.tableShow =  true;


        // this.loader = false;
        // this.loader=false
        if (this.finalTaskList.message == 'No Data') {
          this.nodata = true;
          // this.loader = false;
          console.log('nodata');
        }
        //     this.finalTaskListPush=this.finalTaskList

        // this.finalTaskListPush.forEach(element => {
        //     var elementobj = {
        //         taskId: element.taskId,
        //         taskGroup: element.taskGroup,
        //         taskName:  element.taskName,
        //         taskStartDate: element.taskStartDate ,
        //         taskEndDate: element.taskEndDate ,
        //         workhours: element.workhours,
        //         workminutes: element.workminutes,
        //         taskStatusName: element.taskStatusName,
        //         // advanceBalance: element.status ,
        //         // advanceGivenBy:  element.advanceGivenBy
        //         project:element.project,
        //         organization:element.organization,
        //         taskStatus:element.taskStatus,
        //         taskCode : element.taskCode
        //     }
        //      this.finalTaskListPush.push(elementobj);

        //  console.log(this.taskList);
        // });
        // this.finalTaskList=this.finalTaskListPush
        // console.log("Data Length:"+this.finalTaskList.length);
        this.listCount = this.finalTaskList.length;
        console.log(this.listCount);

        this.finalTaskList.map((h) => {
          let hour = 0;
          let minute = 0;
          if (h.taskHours != '') {
            hour = h.taskHours
              ?.map((a) => a.extrahours)
              .reduce(function (a, b) {
                return (
                  Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                );
              });
            minute = h.taskHours
              ?.map((a) => a.extraminutes)
              .reduce(function (a, b) {
                return (
                  Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                );
              });
          } else {
            hour = 0;
            minute = 0;
          }

          let data: any;
          let data1: any;

          if (h.workhours) {
            data = Number(h.workhours) + Number(hour);
            data1 = Number(h.workminutes) + Number(minute);
          } else {
            data = 0 + Number(hour);
            data1 = 0 + Number(minute);
          }

          if (data1 >= 60) {
            // Divide minutes by 60 and add result to hours
            data += Math.floor(data1 / 60);
            // Add remainder of totalM / 60 to minutes
            data1 = data1 % 60;
          }
          // data = ('0' + data).slice(-2)
          //data1 = ('0' + data1).slice(-2)

          //  console.log("data" + data);
          //  console.log("data1" + data1);

          h['workhours'] = data;
          h['workminutes'] = data1;
        });
        // console.log(this.finalTaskList.length);

        // console.log("this.taskList.message::",this.taskList.message);
      },
      (error) => {
        this.nodata = true;
        console.log('error');
        if (error.status == 404) {
          this.nodata = true;
          console.log('404');
        }
      }
    );
    // }
    // else {
    //   this.gs.getEmpProjectList(this.employeeId).subscribe(
    //     (d: any) => {
    //       d.map(
    //         e => {
    //           if (e.projectId == this.Id) {
    //             e.employeeProjects.map(
    //               a => {
    //                 if (a.employee.employeeId == this.employeeId) {
    //                   this.menuData = a.userRole.menu
    //                   this.menuData.map(
    //                     b => {
    //                       if (b.menuname == 'Assign Task') {
    //                         this.taskShow = true
    //                       }
    //                     }
    //                   )
    //                 }
    //               }
    //             )
    //           }
    //         }
    //       )
    //     }
    //   )
    //   this.gs.getTaskByEmployee(empId, id).subscribe(
    //     (data: any[]) => {
    //       this.taskEmpList = data.sort((a, b) => a.taskName.localeCompare(b.taskName));
    //       this.taskEmpList.map(
    //         h => {
    //           let hour = 0
    //           let minute = 0
    //           if (h.taskHours != '') {
    //             hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
    //               return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
    //             })
    //             minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
    //               return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
    //             })
    //           }
    //           else {
    //             hour = 0
    //             minute = 0
    //           }

    //           let data: any
    //           let data1: any

    //           if (h.workhours) {
    //             data = Number(h.workhours) + Number(hour)
    //             data1 = Number(h.workminutes) + Number(minute)
    //           }
    //           else {
    //             data = 0 + Number(hour)
    //             data1 = 0 + Number(minute)
    //           }

    //           if (data1 >= 60) {
    //             // Divide minutes by 60 and add result to hours
    //             data += Math.floor(data1 / 60);
    //             // Add remainder of totalM / 60 to minutes
    //             data1 = data1 % 60;
    //           }
    //           data = ('0' + data).slice(-2)
    //           data1 = ('0' + data1).slice(-2)
    //           h['workhours'] = data
    //           h['workminutes'] = data1
    //         }
    //       )
    //     }
    //   )
    // }
  }

  private Project(id) {
    this.gs.getprojectByID(id).subscribe((x: any) => {
      this.projectName = x;
    });
  }

  changepage() {
    console.log(this.finalTaskList.length);
    if (this.search.length != 0) {
      this.p = 1;
      console.log(this.finalTaskList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
      console.log(this.p);
      console.log('success Data');
    } else {
      this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.finalTaskList.length);
      console.log('nodata');
    }
  }

  //methods
  view(i) {
    this.router.navigate(['deliverables/view/']);
  }

  createTask() {
    // const dialogRef = this.dialog.open(TaskCreationComponent, {
    //   width: '750px',
    //   data: this.Id,
    //   disableClose: false
    // });

    // dialogRef.afterOpened().subscribe(() => {
    //   // Add your logic here

    //   this.success=true
    //   console.log('Dialog opened successfully!');
    //   // You can perform any action here to keep the dialog open, if needed
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result == 'cancel') {
    //     return
    //   }
    //   dialogRef.close()

    //   setTimeout(()=>{
    //     // this.dialog.closeAll();
    //     dialogRef.close()

    //   },4500);
    // })
    console.log('assign task came!!!');

    const dialogRef = this.dialog.open(TaskCreationComponent, {
      width: '750px',
      data: this.Id,
      // disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('dialogue entered here!!! ooutside');

      if (result == 'cancel') {
        console.log('dialogue entered here!!!');

        return;
      }
      if (this.success == true) {
        this.success = true;
        this.timesheet.setAddTaskSuccessMessage('Task Added Successfully');
      } else {
        this.success = false;
      }

      // this.sucess_msg = a.message;
      dialogRef.close();
    });
  }

  taskStatus(i) {
    if (i.taskStatusName == null) {
      return;
    }
    if (i.taskStatusName == 'To Do') {
      return '#8313ff';
    }
    if (i.taskStatusName == 'Completed') {
      return '#28a745';
    }
    if (i.taskStatusName == 'In Progress') {
      return '#f44336';
    }
  }

  edit(d) {
    if (d.commonTasks != undefined) {
      d['commontaskId'] = d.commonTasks.commontaskId;
    }

    this.myData = d;
    const dialogRef = this.dialog.open(TaskUpdationComponent, {
      width: '800px',
      maxHeight: '600px',
      data: this.myData,
      // disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'cancel') {
        return;
      }

      console.log('result', result);

      this.gs.updateTask(result, d.taskId).subscribe((d: any) => {
        // this.getTask(this.Id)
        // Swal.fire({
        //   text: d.message,
        //   icon: 'success'
        // })
        // window.location.reload()

        // this.loader=false;
        // this.success = true;
        console.log('d:::', d);

        if (d.StatusCode == 200) {
          console.log('entering into the 200 status!!');
          console.log('d.message', d.message);

          // this.success = true;
          // this.sucess_msg = 'Timesheet Updated Successfully';

          // this.sucess_msg = 'Timesheet Updated Successfully';

          this.success = true;
          this.successMessage = d.message;
          // setTimeout(() => {
          //   window.location.reload();
          // }, 400);
          setTimeout(() => {
            this.success = false;
            // this.successMessage = '';
            // this.closeASuccessMessage();
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            const currentUrl = this.router.url;
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.router.navigateByUrl(currentUrl);
              });
          }, 4000);
        }

        //         setTimeout(() => {
        //           this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // const currentUrl = this.router.url;
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigateByUrl(currentUrl);
        // });

        // }, 1000)

        this.getTasklist();
      });
    });
  }

  delete(e, i) {
    this.gs.deleteTask(e.taskId).subscribe((d: any) => {
      this.finalTaskList.splice(i, 1);
      // Swal.fire({
      //   text: d.message,
      //   icon: 'success'
      // })
      this.success = true;
      this.sucess_msg = d.message;
      setTimeout(() => {
        this.success = false;
        // this.sucess_msg = d.message;
      }, 3000);
    });
  }

  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
    if (this.startDate && this.endDate) {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
      this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');

      console.log('2dates', startDate, endDate);

      if (this.endDate < this.startDate) {
        alert('Start date cannot be greater than end date.');
        this.startDate = '';
        this.endDate = '';
        this.gs.getTaskById(this.Id).subscribe(
          (d: any[]) => {
            this.finalTaskList = [];
            //
            console.log(d);
            this.nodata = false;
            this.finalTaskList = d;
            this.tableShow = true;
            this.loader = false;
            if (this.finalTaskList.message == 'No Data') {
              this.nodata = true;
              this.loader = false;
              console.log('nodata');
            }
            // this.finalTaskList.forEach(element => {
            //     var elementobj = {
            //         taskId: element.taskId,
            //         taskGroup: element.taskGroup,
            //         taskName:  element.taskName,
            //         taskStartDate: element.taskStartDate ,
            //         taskEndDate: element.taskEndDate ,
            //         workhours: element.workhours,
            //         workminutes: element.workminutes,
            //         taskStatusName: element.taskStatusName,
            //         // advanceBalance: element.status ,
            //         // advanceGivenBy:  element.advanceGivenBy
            //         project:element.project,
            //         organization:element.organization,
            //         taskStatus:element.taskStatus,
            //         taskCode : element.taskCode
            //     }
            //      this.finalTaskList.push(elementobj);
            //     //  console.log(this.taskList);
            //     });
            console.log('Data Length:' + this.finalTaskList.length);
            this.listCount = this.finalTaskList.length;
            console.log(this.listCount);

            this.finalTaskList.map((h) => {
              let hour = 0;
              let minute = 0;
              if (h.taskHours != '') {
                hour = h.taskHours
                  ?.map((a) => a.extrahours)
                  .reduce(function (a, b) {
                    return (
                      Number(('0' + a).slice(-2)) +
                      Number(('0' + b).slice(-2))
                    );
                  });
                minute = h.taskHours
                  ?.map((a) => a.extraminutes)
                  .reduce(function (a, b) {
                    return (
                      Number(('0' + a).slice(-2)) +
                      Number(('0' + b).slice(-2))
                    );
                  });
              } else {
                hour = 0;
                minute = 0;
              }

              let data: any;
              let data1: any;

              if (h.workhours) {
                data = Number(h.workhours) + Number(hour);
                data1 = Number(h.workminutes) + Number(minute);
              } else {
                data = 0 + Number(hour);
                data1 = 0 + Number(minute);
              }

              if (data1 >= 60) {
                // Divide minutes by 60 and add result to hours
                data += Math.floor(data1 / 60);
                // Add remainder of totalM / 60 to minutes
                data1 = data1 % 60;
              }
              // data = ('0' + data).slice(-2)
              //data1 = ('0' + data1).slice(-2)

              //  console.log("data" + data);
              //  console.log("data1" + data1);

              h['workhours'] = data;
              h['workminutes'] = data1;
            });
            // console.log(this.finalTaskList.length);

            // console.log("this.taskList.message::",this.taskList.message);
          },
          (error) => {
            this.nodata = true;
            console.log('error');
            if (error.status == 404) {
              this.nodata = true;
              console.log('404');
            }
          }
        );
      } else {
      }
    }

    if (this.startDate === undefined && this.endDate !== undefined) {
      this.startdate_required = true;
      this.enddate_required = false;
      return;
    }

    if (this.startDate !== undefined && this.endDate !== undefined) {
      this.startdate_required = false;
      this.enddate_required = false;
      return;
    }

    if (this.endDate !== undefined) {
      this.enddate_required = false;
      return;
    }
  }


  checkStartDate(): void {}

  changefirstpage() {
    this.p = 1;
  }
  resetForms() {
    // this.changefirstpage()

    // this.selectedetaskGroupId = undefined;
    // this.dateInput.control.setErrors(null);
    // this.timesheetDate = null;
    // this.nodata=false
    // this.startDate = undefined ;
    // this.endDate = undefined;
    // this.taskStatusId =undefined
    // {this.getTask(this.Id) }
    // this.finalTaskList.length=0
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //     const currentUrl = this.router.url;
    //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //         this.router.navigateByUrl(currentUrl);
    //     })
    // this.getTasklist()

    if (
      this.selectedetaskGroupId == undefined &&
      this.taskStatusId == undefined &&
      this.startDate == null &&
      this.endDate == null
    ) {
      this.loader = false;
      console.log('if...came');
    }

    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //     const currentUrl = this.router.url;
    //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //         this.router.navigateByUrl(currentUrl);
    //     });
    else {
      console.log('else...came');

      if (
        this.selectedetaskGroupId !== 0 ||
        this.selectedetaskGroupId !== undefined ||
        this.startDate !== undefined ||
        this.startDate !== null ||
        this.endDate !== undefined ||
        this.endDate !== null ||
        this.taskStatusId !== 0 ||
        this.taskStatusId !== undefined
      ) {
        console.log('first condition');

        // Your code here

        if (
          this.selectedetaskGroupId != 0 ||
          this.startDate != undefined ||
          this.endDate != undefined ||
          this.taskStatusId != 0
        ) {
          console.log('second condition');

          if (
            this.selectedetaskGroupId != undefined ||
            this.startDate != undefined ||
            this.endDate != undefined ||
            this.taskStatusId != undefined
          ) {
            console.log('third condition');

            console.log('Form is valid');
            this.tableShow = false;
            this.selectedetaskGroupId = undefined;
            this.startDate = undefined;
            this.endDate = undefined;
            this.taskStatusId = undefined;
            // this.taskList=undefined
            // this.timesheetreport.length = 0;
            // this.taskList.length=0

            // this.finalTaskList = [];
            this.nodata = false;
            this.loader = true;
            this.gs.getTaskById(this.Id).subscribe(
              (d: any[]) => {
                this.finalTaskList = [];
                //
                console.log(d);
                this.nodata = false;
                this.finalTaskList = d;
                this.tableShow = true;
                this.loader = false;
                if (this.finalTaskList.message == 'No Data') {
                  this.nodata = true;
                  this.loader = false;
                  console.log('nodata');
                }
                // this.finalTaskList.forEach(element => {
                //     var elementobj = {
                //         taskId: element.taskId,
                //         taskGroup: element.taskGroup,
                //         taskName:  element.taskName,
                //         taskStartDate: element.taskStartDate ,
                //         taskEndDate: element.taskEndDate ,
                //         workhours: element.workhours,
                //         workminutes: element.workminutes,
                //         taskStatusName: element.taskStatusName,
                //         // advanceBalance: element.status ,
                //         // advanceGivenBy:  element.advanceGivenBy
                //         project:element.project,
                //         organization:element.organization,
                //         taskStatus:element.taskStatus,
                //         taskCode : element.taskCode
                //     }
                //      this.finalTaskList.push(elementobj);
                //     //  console.log(this.taskList);
                //     });
                console.log('Data Length:' + this.finalTaskList.length);
                this.listCount = this.finalTaskList.length;
                console.log(this.listCount);

                this.finalTaskList.map((h) => {
                  let hour = 0;
                  let minute = 0;
                  if (h.taskHours != '') {
                    hour = h.taskHours
                      ?.map((a) => a.extrahours)
                      .reduce(function (a, b) {
                        return (
                          Number(('0' + a).slice(-2)) +
                          Number(('0' + b).slice(-2))
                        );
                      });
                    minute = h.taskHours
                      ?.map((a) => a.extraminutes)
                      .reduce(function (a, b) {
                        return (
                          Number(('0' + a).slice(-2)) +
                          Number(('0' + b).slice(-2))
                        );
                      });
                  } else {
                    hour = 0;
                    minute = 0;
                  }

                  let data: any;
                  let data1: any;

                  if (h.workhours) {
                    data = Number(h.workhours) + Number(hour);
                    data1 = Number(h.workminutes) + Number(minute);
                  } else {
                    data = 0 + Number(hour);
                    data1 = 0 + Number(minute);
                  }

                  if (data1 >= 60) {
                    // Divide minutes by 60 and add result to hours
                    data += Math.floor(data1 / 60);
                    // Add remainder of totalM / 60 to minutes
                    data1 = data1 % 60;
                  }
                  // data = ('0' + data).slice(-2)
                  //data1 = ('0' + data1).slice(-2)

                  //  console.log("data" + data);
                  //  console.log("data1" + data1);

                  h['workhours'] = data;
                  h['workminutes'] = data1;
                });
                // console.log(this.finalTaskList.length);

                // console.log("this.taskList.message::",this.taskList.message);
              },
              (error) => {
                this.nodata = true;
                console.log('error');
                if (error.status == 404) {
                  this.nodata = true;
                  console.log('404');
                }
              }
            );
            // this.getTaskLists(this.Id);{
          }
        }
      }
    }
  }
  //  @HostListener('document:click', ['$event'])
  //   onClick(event: MouseEvent) {
  //     // Check if the clicked element is not the search input
  //     if (!(event.target as HTMLElement).closest('.form-control')) {
  //       this.search = ''; // Clear the search term
  //     }
  //   }

  searchTask() {
    this.loader = true;
    console.log('this.loader::', this.loader);

    this.search = '';
    this.changefirstpage();
    //  console.log(this.assignListForm.value.StartDate);
    //  console.log(this.assignListForm.value.EndDate);
    this.startDate = this.datepipe.transform(this.startDate, 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'YYYY-MM-dd');
    // console.log("Start Date:"+this.startDate);
    //  console.log("End date:"+this.endDate);
    // this.finalTaskList.length=0;
    // this.finalTaskList.length=0;
    // this.isSubmitted = true;
    // this.taskgroup_required =false;

    console.log('this.startDate',    this.startDate );
    console.log('this.endDate',     this.endDate );
    
    if (
      this.taskStatusId === undefined &&
      this.selectedetaskGroupId === undefined &&
      (this.startDate === undefined || this.startDate === null) &&
      (this.endDate === undefined || this.endDate === null)
    ) {
      alert('Please Choose any one of the elements to Search');
      // { this.getTask(this.Id) }
      this.isdsbtnDisable = false;
      this.loader = false;
      // this.startDate=undefined
      // this.endDate=undefined
      // this.taskStatusId=undefined
      // this.selectedetaskGroupId=undefined
      return;
    } else if (this.startDate > this.endDate) {
      alert('Start date should be greather than end date!!');
      {
        this.getTask(this.Id);
      }
      this.isdsbtnDisable = false;
      this.startDate = undefined;
      this.endDate = undefined;
      this.loader = false;
      //        this.taskStatusId=undefined
      // this.selectedetaskGroupId=undefined

      return;
    }
    //     else if(this.startDate<this.endDate){
    //       alert("Start date should be greather than end date!!")
    //       { this.getTask(this.Id) }
    //       this.isdsbtnDisable=false
    //        this.startDate=undefined
    //        this.endDate=undefined
    // //        this.taskStatusId=undefined
    // // this.selectedetaskGroupId=undefined

    // return
    //     }
    else if (this.startDate !== null && this.endDate == null) {
      alert('End date is required!!');
      {
        this.getTask(this.Id);
      }
      this.isdsbtnDisable = false;
      this.loader = false;
      //       this.startDate=undefined
      // this.endDate=undefined
      // this.taskStatusId=undefined
      // this.selectedetaskGroupId=undefined

      return;
    } else if (this.startDate == null && this.endDate !== null) {
      alert('Start date is required!!');
      {
        this.getTask(this.Id);
      }
      this.isdsbtnDisable = false;
      this.loader = false;
      //       this.startDate=undefined
      //       this.endDate=undefined
      //       this.taskStatusId=undefined
      // this.selectedetaskGroupId=undefined

      return;
    } // else if(this.taskStatusId ===undefined && this.selectedetaskGroupId === undefined &&
    //   this.startDate === undefined && this.endDate === undefined){
    //     alert("Please Choose elements to Search");
    //     { this.getTask(this.Id) }

    //       return
    //   }
    else {
      console.log('no data');
    }
    console.log('taskStatusId::', this.taskStatusId);
    console.log('selectedetaskGroupId::', this.selectedetaskGroupId);
    console.log('start date::', this.startDate);
    console.log('end date::', this.endDate);

    // if(this.startDate == null && this.endDate == null){
    //     this.gs.getTaskByProjectAndTaskgroupAndTaskstatus(this.Id,this.taskStatusId,this.selectedetaskGroupId)
    //     .subscribe((data)=>{
    //       console.log(data);
    //       this.loader = false;
    //       this.nodata = false;
    //       this.finalTaskList = data;
    //       if(this.finalTaskList.length == 0 || this.finalTaskList.errorCode == 204){
    //         this.nodata = true;
    //       }
    //     }
    //     ,(error) => {
    //       // this.nodata = true;
    //       // console.log("Error");
    //       this.finalTaskList.length=0;
    //       this.loader = false;
    //       console.log(this.loader);
    //       if(error.status == 404){
    //         console.log("404");
    //         this.nodata = true;
    //       }
    //     }
    //     );
    //   if(this.selectedetaskGroupId == undefined){
    //     this.gs.getTaskByProjectAndTaskstatus(this.Id,this.taskStatusId)
    //     .subscribe((data)=>{
    //       console.log(data);
    //       this.finalTaskList = data;
    //       this.nodata = false;
    //       if(this.finalTaskList.length == 0 || this.finalTaskList.errorCode == 204){
    //         this.nodata = true;
    //       }
    //     }
    //     ,(error) => {
    //       // this.nodata = true;
    //       // console.log("Error");
    //       this.finalTaskList.length=0;
    //       this.loader = false;
    //       console.log(this.loader);
    //       if(error.status == 404){
    //         console.log("404");
    //         this.nodata = true;
    //       }
    //     }
    //     );
    //   }
    //   else if(this.taskStatusId == undefined){
    //     this.gs.getTaskByProjectAndTaskgroup(this.Id,this.selectedetaskGroupId)
    //     .subscribe((data)=>{
    //       console.log(data);
    //       this.finalTaskList = data;
    //       this.nodata = false;
    //       if(this.finalTaskList.length == 0 || this.finalTaskList.errorCode == 204){
    //         this.nodata = true;
    //       }
    //     }
    //     ,(error) => {
    //       // this.nodata = true;
    //       // console.log("Error");
    //       this.finalTaskList.length=0;
    //       this.loader = false;
    //       console.log(this.loader);
    //       if(error.status == 404){
    //         console.log("404");
    //         this.nodata = true;
    //       }
    //     }
    //     );
    //   }
    // }
    // else{
    //   if(this.startDate == undefined  || this.endDate == undefined ){
    //     if(this.startDate == undefined){
    //       this.startdate_required = true;
    //       return
    //     }
    //     else if(this.endDate == undefined ){
    //       this.enddate_required = true;
    //       return
    //     }
    //     return
    //   }
    //   if(this.selectedetaskGroupId!= undefined && this.taskStatusId != undefined && this.startDate != undefined && this.endDate!= undefined && this.startDate <= this.endDate)
    //   {
    //   console.log("if Condition");
    //   console.log(this.startDate);
    //   this.enddate_required = false;
    //   this.startdate_required = false;
    //   this.gs.getTaskByProjectAndTaskgroupAndTaskstatusAndDate
    //   (this.Id,this.taskStatusId,this.selectedetaskGroupId,this.startDate,this.endDate)
    //   .subscribe((data)=>{
    //     console.log(data);
    //     this.finalTaskList = data;
    //     console.log(this.finalTaskList);
    //     this.nodata = false;
    //     if(this.finalTaskList.length == 0 || this.finalTaskList.errorCode == 204){
    //       this.nodata = true;
    //     }
    //   }
    //   ,(error) => {
    //     // this.nodata = true;
    //     // console.log("Error");
    //     this.finalTaskList.length=0;
    //     console.log(this.finalTaskList);
    //     this.loader = false;
    //     console.log(this.loader);
    //     if(error.status == 404){
    //       console.log("404");
    //       this.nodata = true;
    //     }
    //   }
    //   );
    //   }
    //   else if(this.taskStatusId == undefined && this.selectedetaskGroupId == undefined && this.startDate != undefined && this.endDate!= undefined && this.startDate <= this.endDate){
    //     this.gs.getTaskByProjectAndDate(this.Id,this.startDate,this.endDate)
    //     .subscribe((data)=>{
    //       console.log(data);
    //       this.finalTaskList = data;
    //       this.nodata = false;
    //       if(this.finalTaskList.length == 0 || this.finalTaskList.errorCode == 204){
    //         this.nodata = true;
    //       }
    //     }
    //     ,(error) => {
    //       // this.nodata = true;
    //       // console.log("Error");
    //       this.finalTaskList.length=0;
    //       this.loader = false;
    //       console.log(this.loader);
    //       if(error.status == 404){
    //         console.log("404");
    //         this.nodata = true;
    //       }
    //     }
    //     );
    //   }
    //   else if(this.selectedetaskGroupId == undefined && this.startDate != undefined && this.endDate!= undefined && this.startDate <= this.endDate){
    //     this.gs.getTaskByProjectAndTaskstatusAndDate(this.Id,this.taskStatusId,this.startDate,this.endDate)
    //     .subscribe((data)=>{
    //       console.log(data);
    //       this.finalTaskList = data;
    //       this.nodata = false;
    //       if(this.finalTaskList.length == 0 || this.finalTaskList.errorCode == 204){
    //         this.nodata = true;
    //       }
    //     }
    //     ,(error) => {
    //       // this.nodata = true;
    //       // console.log("Error");
    //       this.finalTaskList.length=0;
    //       this.loader = false;
    //       console.log(this.loader);
    //       if(error.status == 404){
    //         console.log("404");
    //         this.nodata = true;
    //       }
    //     }
    //     );
    //   }
    //   else if(this.taskStatusId == undefined && this.startDate != undefined && this.endDate!= undefined && this.startDate <= this.endDate){
    //     this.gs.getTaskByProjectAndTaskgroupAndDate(this.Id,this.selectedetaskGroupId,this.startDate,this.endDate)
    //     .subscribe((data)=>{
    //       console.log(data);
    //       this.finalTaskList = data;
    //       this.nodata = false;
    //       if(this.finalTaskList.length == 0 || this.finalTaskList.errorCode == 204){
    //         this.nodata = true;
    //       }
    //     }
    //     ,(error) => {
    //       // this.nodata = true;
    //       // console.log("Error");
    //       this.finalTaskList.length=0;
    //       this.loader = false;
    //       console.log(this.loader);
    //       if(error.status == 404){
    //         console.log("404");
    //         this.nodata = true;
    //       }
    //     }
    //    );
    //   }

    if (
      this.selectedetaskGroupId !== undefined &&
      this.taskStatusId == undefined &&
      (this.startDate == null || this.endDate == undefined)
    ) {
      console.log('only task group!!');
      this.isdsbtnDisable = true;
      this.loader = true;
      this.tableShow = false;
      this.gs
        .getTaskByProjectAndTaskgroup(this.Id, this.selectedetaskGroupId)
        .subscribe(
          (data) => {
            console.log(data);
            this.finalTaskList = data;
            this.tableShow = true;
            this.nodata = false;
            this.isdsbtnDisable = true;
            this.loader = false;
            if (
              this.finalTaskList.length == 0 ||
              this.finalTaskList.errorCode == 204
            ) {
              this.nodata = true;
              this.isdsbtnDisable = false;
              // this.loader=false
            }
            this.isdsbtnDisable = false;
          },
          (error) => {
            this.finalTaskList.length = 0;
            this.loader = false;
            console.log(this.loader);
            if (error.status == 404) {
              console.log('404');
              this.nodata = true;
            }
          }
        );
    } else if (
      this.selectedetaskGroupId !== undefined &&
      this.taskStatusId !== undefined &&
      (this.startDate == null || this.endDate == undefined)
    ) {
      console.log('task group and task status!!');
      this.isdsbtnDisable = true;
      this.loader = true;
      this.tableShow = false;
      this.gs
        .getTaskByProjectAndTaskgroupAndTaskstatus(
          this.Id,
          this.taskStatusId,
          this.selectedetaskGroupId
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.loader = false;
            this.nodata = false;
            this.isdsbtnDisable = true;
            this.tableShow = true;
            this.finalTaskList = data;
            if (
              this.finalTaskList.length == 0 ||
              this.finalTaskList.errorCode == 204
            ) {
              this.nodata = true;
              this.isdsbtnDisable = false;
              this.loader = false;
            }
            this.isdsbtnDisable = false;
          },
          (error) => {
            // this.nodata = true;
            // console.log("Error");
            this.finalTaskList.length = 0;
            this.loader = false;
            console.log(this.loader);
            if (error.status == 404) {
              console.log('404');
              this.nodata = true;
            }
          }
        );
    } else if (
      this.selectedetaskGroupId !== undefined &&
      this.taskStatusId == undefined &&
      (this.startDate !== null || this.endDate !== undefined)
    ) {
      console.log('task group and dates!!');
      this.isdsbtnDisable = true;
      this.loader = true;
      this.tableShow = false;
      this.gs
        .getTaskByProjectAndTaskgroupAndDate(
          this.Id,
          this.selectedetaskGroupId,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.finalTaskList = data;
            this.tableShow = true;
            this.nodata = false;
            this.isdsbtnDisable = true;
            this.loader = false;
            if (
              this.finalTaskList.length == 0 ||
              this.finalTaskList.errorCode == 204
            ) {
              this.nodata = true;
              this.isdsbtnDisable = false;
              this.loader = false;
            }
            this.isdsbtnDisable = false;
          },
          (error) => {
            // this.nodata = true;
            // console.log("Error");
            this.finalTaskList.length = 0;
            this.loader = false;
            console.log(this.loader);
            if (error.status == 404) {
              console.log('404');
              this.nodata = true;
            }
          }
        );
    } else if (
      this.selectedetaskGroupId == undefined &&
      this.taskStatusId !== undefined &&
      (this.startDate == null || this.endDate == undefined)
    ) {
      console.log('only task status!!');
      this.isdsbtnDisable = true;

      this.tableShow = false;
      if (this.selectedetaskGroupId == undefined) {
        this.loader = true;
        this.gs
          .getTaskByProjectAndTaskstatus(this.Id, this.taskStatusId)
          .subscribe(
            (data) => {
              console.log(data);
              this.finalTaskList = data;
              this.tableShow = true;
              this.nodata = false;
              this.isdsbtnDisable = true;
              this.loader = false;

              if (
                this.finalTaskList.length == 0 ||
                this.finalTaskList.errorCode == 204
              ) {
                this.nodata = true;
                this.isdsbtnDisable = false;
              }
              this.isdsbtnDisable = false;
            },
            (error) => {
              // this.nodata = true;
              // console.log("Error");
              this.finalTaskList.length = 0;
              this.loader = false;
              console.log(this.loader);
              if (error.status == 404) {
                console.log('404');
                this.nodata = true;
              }
            }
          );
      }
    } else if (
      this.selectedetaskGroupId == undefined &&
      this.taskStatusId !== undefined &&
      (this.startDate !== null || this.endDate !== undefined)
    ) {
      console.log('task status and dates!!');
      this.isdsbtnDisable = true;
      this.loader = true;
      this.tableShow = false;
      this.gs
        .getTaskByProjectAndTaskstatusAndDate(
          this.Id,
          this.taskStatusId,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.finalTaskList = data;
            this.tableShow = true;
            this.nodata = false;
            this.isdsbtnDisable = true;
            this.loader = false;

            if (
              this.finalTaskList.length == 0 ||
              this.finalTaskList.errorCode == 204
            ) {
              this.nodata = true;
              this.isdsbtnDisable = false;
              this.loader = false;
            }
            this.isdsbtnDisable = false;
          },
          (error) => {
            // this.nodata = true;
            // console.log("Error");
            this.finalTaskList.length = 0;
            this.loader = false;
            console.log(this.loader);
            if (error.status == 404) {
              console.log('404');
              this.nodata = true;
            }
          }
        );
    } else if (
      this.selectedetaskGroupId == undefined &&
      this.taskStatusId == undefined &&
      (this.startDate !== null || this.endDate !== undefined)
    ) {
      console.log('only dates!!');
      this.isdsbtnDisable = true;
      this.loader = true;
      this.tableShow = false;
      this.gs
        .getTaskByProjectAndDate(this.Id, this.startDate, this.endDate)
        .subscribe(
          (data) => {
            console.log(data);
            this.finalTaskList = data;
            this.tableShow = true;
            this.nodata = false;
            this.isdsbtnDisable = true;
            this.loader = false;
            if (
              this.finalTaskList.length == 0 ||
              this.finalTaskList.errorCode == 204
            ) {
              this.nodata = true;
              this.isdsbtnDisable = false;
              this.loader = false;
            }
            this.isdsbtnDisable = false;
          },
          (error) => {
            // this.nodata = true;
            // console.log("Error");
            this.finalTaskList.length = 0;
            this.loader = false;
            console.log(this.loader);
            if (error.status == 404) {
              console.log('404');
              this.nodata = true;
            }
          }
        );
    } else if (
      this.selectedetaskGroupId !== undefined &&
      this.taskStatusId !== undefined &&
      (this.startDate !== null || this.endDate !== undefined)
    ) {
      console.log('all!!');
      this.isdsbtnDisable = true;
      this.loader = true;
      this.tableShow = false;
      this.gs
        .getTaskByProjectAndTaskgroupAndTaskstatusAndDate(
          this.Id,
          this.taskStatusId,
          this.selectedetaskGroupId,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.finalTaskList = data;
            this.tableShow = true;
            this.isdsbtnDisable = true;
            this.loader = false;
            console.log(this.finalTaskList);
            this.nodata = false;
            if (
              this.finalTaskList.length == 0 ||
              this.finalTaskList.errorCode == 204
            ) {
              this.nodata = true;
              this.isdsbtnDisable = false;
              this.loader = false;
            }
            this.isdsbtnDisable = false;
          },
          (error) => {
            // this.nodata = true;
            // console.log("Error");
            this.finalTaskList.length = 0;
            console.log(this.finalTaskList);
            this.loader = false;
            console.log(this.loader);
            if (error.status == 404) {
              console.log('404');
              this.nodata = true;
            }
          }
        );
    } else {
      // this.errorMsg=true;
      console.log('all conditions are came out!! ');
    }
  }

  //   if(this.selectedetaskGroupId  && !this.taskStatusId ){
  //     if(!this.startDate  && !this.endDate ){
  //       this.gs.getTaskByProjectandTaskGroup(this.Id ,this.selectedetaskGroupId )
  //       .subscribe((data:any)=>{
  //         console.log(data);
  //       this.finalTaskList =data;
  //       this.finalTaskList.map(
  //           h => {
  //             let hour = 0
  //             let minute = 0
  //             if (h.taskHours != '') {
  //               hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
  //                 return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //               })
  //               minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
  //                 return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //               })
  //             }
  //             else {
  //               hour = 0
  //               minute = 0
  //             }

  //             let data: any
  //             let data1: any

  //             if (h.workhours) {
  //               data = Number(h.workhours) + Number(hour)
  //               data1 = Number(h.workminutes) + Number(minute)
  //             }
  //             else {
  //               data = 0 + Number(hour)
  //               data1 = 0 + Number(minute)
  //             }

  //             if (data1 >= 60) {
  //               // Divide minutes by 60 and add result to hours
  //               data += Math.floor(data1 / 60);
  //               // Add remainder of totalM / 60 to minutes
  //               data1 = data1 % 60;
  //             }
  //            // data = ('0' + data).slice(-2)
  //             //data1 = ('0' + data1).slice(-2)

  //              console.log("data" + data);
  //              console.log("data1" + data1);

  //             h['workhours'] = data
  //             h['workminutes'] = data1
  //           }
  //         )
  //         if(this.taskList.length == 0)
  //         {
  //           this.nodata = true;
  //           console.log("nodata")
  //         }
  //         this.nodata = false;
  //   //      this.tasktotalTime = this.timesheetreport[0].totalTime;
  //       },
  //       (error) => {
  //         this.nodata = true;
  //       });
  //       }
  //     else{
  //       if(! this.startDate ||  !this.endDate ){
  //         if(!this.startDate){
  //           this.startdate_required = true;
  //         }
  //         else if(!this.endDate ){
  //           this.enddate_required = true;
  //         }
  //         return
  //       }
  //       console.log(this.startDate);
  //       this.enddate_required = false;
  //       this.startdate_required = false;
  //       this.gs.getTaskByProjectandTaskGroupdate(this.Id ,this.selectedetaskGroupId  , this.startDate , this.endDate)
  //       .subscribe((data:any)=>{
  //         console.log(data);
  //       this.finalTaskList =data;
  //       this.finalTaskList.map(
  //           h => {
  //             let hour = 0
  //             let minute = 0
  //             if (h.taskHours != '') {
  //               hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
  //                 return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //               })
  //               minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
  //                 return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //               })
  //             }
  //             else {
  //               hour = 0
  //               minute = 0
  //             }

  //             let data: any
  //             let data1: any

  //             if (h.workhours) {
  //               data = Number(h.workhours) + Number(hour)
  //               data1 = Number(h.workminutes) + Number(minute)
  //             }
  //             else {
  //               data = 0 + Number(hour)
  //               data1 = 0 + Number(minute)
  //             }

  //             if (data1 >= 60) {
  //               // Divide minutes by 60 and add result to hours
  //               data += Math.floor(data1 / 60);
  //               // Add remainder of totalM / 60 to minutes
  //               data1 = data1 % 60;
  //             }
  //            // data = ('0' + data).slice(-2)
  //             //data1 = ('0' + data1).slice(-2)

  //              console.log("data" + data);
  //              console.log("data1" + data1);

  //             h['workhours'] = data
  //             h['workminutes'] = data1
  //           }
  //         )
  //         this.nodata = false;
  //      //   this.tasktotalTime = this.timesheetreport[0].totalTime;
  //       },
  //       (error) => {
  //         this.nodata = true;
  //       });
  //     }

  //  }else if(!this.selectedetaskGroupId  && this.taskStatusId ){

  //   if(!this.startDate  && !this.endDate ){
  //     this.gs.getTaskByProjectandTaskGroup(this.Id ,this.selectedetaskGroupId )
  //     .subscribe((data:any)=>{
  //       console.log(data);
  //     this.finalTaskList =data;
  //     this.finalTaskList.map(
  //         h => {
  //           let hour = 0
  //           let minute = 0
  //           if (h.taskHours != '') {
  //             hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
  //               return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //             })
  //             minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
  //               return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //             })
  //           }
  //           else {
  //             hour = 0
  //             minute = 0
  //           }

  //           let data: any
  //           let data1: any

  //           if (h.workhours) {
  //             data = Number(h.workhours) + Number(hour)
  //             data1 = Number(h.workminutes) + Number(minute)
  //           }
  //           else {
  //             data = 0 + Number(hour)
  //             data1 = 0 + Number(minute)
  //           }

  //           if (data1 >= 60) {
  //             // Divide minutes by 60 and add result to hours
  //             data += Math.floor(data1 / 60);
  //             // Add remainder of totalM / 60 to minutes
  //             data1 = data1 % 60;
  //           }
  //          // data = ('0' + data).slice(-2)
  //           //data1 = ('0' + data1).slice(-2)

  //            console.log("data" + data);
  //            console.log("data1" + data1);

  //           h['workhours'] = data
  //           h['workminutes'] = data1
  //         }
  //       )
  //       if(this.taskList.length == 0)
  //       {
  //         this.nodata = true;
  //         console.log("nodata")
  //       }
  //       this.nodata = false;
  // //      this.tasktotalTime = this.timesheetreport[0].totalTime;
  //     },
  //     (error) => {
  //       this.nodata = true;
  //     });
  //     }
  //   else{
  //     if(! this.startDate ||  !this.endDate ){
  //       if(!this.startDate){
  //         this.startdate_required = true;
  //       }
  //       else if(!this.endDate ){
  //         this.enddate_required = true;
  //       }
  //       return
  //     }
  //     console.log(this.startDate);
  //     this.enddate_required = false;
  //     this.startdate_required = false;
  //     this.gs.getTaskByProjectandTaskGroupdate(this.Id ,this.selectedetaskGroupId  , this.startDate , this.endDate)
  //     .subscribe((data:any)=>{
  //       console.log(data);
  //     this.finalTaskList =data;
  //     this.finalTaskList.map(
  //         h => {
  //           let hour = 0
  //           let minute = 0
  //           if (h.taskHours != '') {
  //             hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
  //               return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //             })
  //             minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
  //               return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
  //             })
  //           }
  //           else {
  //             hour = 0
  //             minute = 0
  //           }

  //           let data: any
  //           let data1: any

  //           if (h.workhours) {
  //             data = Number(h.workhours) + Number(hour)
  //             data1 = Number(h.workminutes) + Number(minute)
  //           }
  //           else {
  //             data = 0 + Number(hour)
  //             data1 = 0 + Number(minute)
  //           }

  //           if (data1 >= 60) {
  //             // Divide minutes by 60 and add result to hours
  //             data += Math.floor(data1 / 60);
  //             // Add remainder of totalM / 60 to minutes
  //             data1 = data1 % 60;
  //           }
  //          // data = ('0' + data).slice(-2)
  //           //data1 = ('0' + data1).slice(-2)

  //            console.log("data" + data);
  //            console.log("data1" + data1);

  //           h['workhours'] = data
  //           h['workminutes'] = data1
  //         }
  //       )
  //       this.nodata = false;
  //    //   this.tasktotalTime = this.timesheetreport[0].totalTime;
  //     },
  //     (error) => {
  //       this.nodata = true;
  //     });
  //   }
  //  }

  sortData(sort: Sort) {
    const data = this.finalTaskList.slice();
    if (!sort.active || sort.direction === '') {
      this.finalTaskList['employeeProjects'] = data;
      return;
    }

    this.finalTaskList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'taskGroupName':
          return this.compare(a.taskGroupName, b.taskGroupName, isAsc);
        case 'taskName':
          return this.compare(a.taskName, b.taskName, isAsc);
        case 'taskStartDate':
          return this.compare(a.taskStartDate, b.taskStartDate, isAsc);
        case 'taskEndDate':
          return this.compare(a.taskEndDate, b.taskEndDate, isAsc);
        case 'taskStatusName':
          return this.compare(a.taskStatusName, b.taskStatusName, isAsc);
        case 'workhours':
          return this.compare(a.workhours, b.workhours, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  showChanges() {
    console.log('show Change');
    this.p = 1;
  }
}
