<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Manage Pay Slip</span>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Client<span class="star"> *</span></label> 
                            <select formControlName="client" (change)="getclientEmployees()"  class="form-select form-select-solid form-select-lg select2-new">
                                <option [value]=null> --- Select Client --- </option>
                                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                            </select>   
                        </div>
                        <div class="form-group col-md-3">
                            <!-- <input formControlName="employee" type="search" autocomplete="off" class="form-select form-select-solid form-select-lg select2-new" [value]=null placeholder="Search..."> -->
                            <label class="col-form-label">Employee<span class="star"> *</span></label>
                            <input type="text" matInput  placeholder="Search Employee"   
                                formControlName="employee"
                                [matAutocomplete]="auto"  [readonly]="!filterForm.value.client || filterForm.value.client == 'null'"
                                class="form-control form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-autocomplete #auto="matAutocomplete">
                                <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                    </mat-option> -->
                                <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                {{emp.employeeNumber}}- {{emp.firstName}}  
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                search
                            </mat-icon>
                            <!-- <small class="text-muted">Note : Employee Number</small> -->
                        </div>
                        <div class="form-group col-xl-3 col-lg-3 col-md-4">
                            <div class="input-width" id="loginform">
                                <label class="col-form-label">Designation</label>
                                <select formControlName="designation" class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]=null> -- Select Designation -- </option>
                                    <option *ngFor="let row of activeDesignationData; let i=index;" value="{{row.designationId}}" >{{row.designationName}}</option> 
                                </select>
                                <!-- <mat-error *ngIf="submitted && (filterForm.controls['designation'].errors?.required)">
                                    Financial year is required
                                </mat-error> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xl-3 col-lg-3 col-md-4">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Financial Year</label>
                                <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                    <option value=null> -- Select Financialyear -- </option>
                                    <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                </select>
                                <!-- <mat-error *ngIf="submitted && (filterForm.controls['financialyear'].errors?.required || financialyearError)">
                                    Financial year is required
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="form-group col-xl-3 col-lg-3 col-md-4">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Period</label>
                                <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]=null> -- Select Period -- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>
                                <!-- <mat-error *ngIf="submitted && filterForm.controls['period'].errors?.required">
                                    Period is required
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="form-group col-md-2 col-xl-1">
                            <button type="submit" mat-raised-button color="primary" style="margin-top: 28px;"
                                class="btn btn-primary search-btn">Search</button>
                        </div>
                    </div>
                </form>
                <div class="">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="select-pagination">
                                <span style="font-size: 12px;">Show : </span>
                                <select class="select_list new-select_list" [(ngModel)]="mySelect" (change)="showChanges()">
                                    <option class="form-control" value="5"> 5</option>
                                    <option class="form-control" value="10">10</option>
                                    <option class="form-control" value="15">15</option>
                                    <option class="form-control" value="20">20</option>
                                    <option class="form-control" value="25">25</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <table class="table table-responsive2 --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <th class="text-center">Action</th>
                                <th class="text-center">Financial Year</th>
                                <th class="text-center">Period</th>
                                <th>Employee Name</th>
                                <th class="text-center">Employee Code</th>
                                <th class="text-center">Location</th>
                                <th>Designation</th>
                                <th class="text-center">Created Date</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">CTC ( {{organizationSettings.country.countrySymbol}} )</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">Amount ( {{organizationSettings.country.countrySymbol}} )</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">Earning YTD ( {{organizationSettings.country.countrySymbol}} )</th>
                                <th class="text-right" *ngIf="organizationSettings != undefined">Deduction YTD ( {{organizationSettings.country.countrySymbol}} )</th>
                            </tr>
                        </thead>
                        <tbody class="t-body">
                            <tr *ngFor="let e of ManagePayslipdata | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                <td class="text-center tab-medium-width">
                                    <button class="menu-button"
                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon class="menu-icon">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="viewpayslip(e.payrollHeaderId)" class="mat-dropdowns">
                                            <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                            <span mat-button class="text">View</span>
                                        </button>
                                        <!-- <button mat-menu-item class="mat-dropdowns" (click)="downloadAsPDF(e.payrollHeaderId);">
                                            <mat-icon class="material-icons-outlined">file_download</mat-icon>
                                            <span mat-button class="text">Download PDF</span>
                                        </button> -->
                                    </mat-menu>
                                </td>
                                <td class="text-center tab-full-width">{{e.finanicalPeriod.finanicalYear.financialFromMonth}} - {{e.finanicalPeriod.finanicalYear.financialFromYear}} To {{e.finanicalPeriod.finanicalYear.financialToMonth}}-{{e.finanicalPeriod.finanicalYear.financialToYear}}</td>
                                <td class="text-center tab-medium-width">{{e.finanicalPeriod.financialperiodMonth}} - {{e.finanicalPeriod.financialPeriodYear}}</td>
                                <td class="tab-full-width">{{e.employee.firstName}} </td>
                                <td class="text-center tab-medium-width">{{e.employee.employeeNumber}}</td>
                                <td class="text-center tab-medium-width">{{e.employeeAnnexure.place}}</td>
                                <td class="tab-full-width" *ngIf="e.employee.designation != undefined">{{e.employee.designation.designationName}}</td>
                                <td class="text-center tab-medium-width">{{e.createDateTime | date: dateFormat}}</td>
                                <td class="tab-medium-width text-right">{{e.ctc.toFixed(2)}}</td>
                                <td class="tab-medium-width text-right">{{e.amount.toFixed(2)}}</td>
                                <td class="tab-medium-width text-right">{{e.earningYtd.toFixed(2)}}</td>
                                <td class="tab-medium-width text-right">{{e.deductionYtd.toFixed(2)}}</td>
                            </tr>
                            <tr *ngIf="noRecordsFound == false">
                                <td class="text-right py-2" style="font-size: 13px;" colspan="8"><b>Total&nbsp;&nbsp;&nbsp;</b></td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{CTC.toFixed(2)}}</td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{Amount.toFixed(2)}}</td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{EarningYTD.toFixed(2)}}</td>
                                <td class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{DeductionYTD.toFixed(2)}}</td>
                            </tr>
                        </tbody>
                        <tbody class="t-body">
                            <tr>
                                <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="showing-record">
                            {{ManagePayslipdata.length}} rows
                        </div>
                    </div>
                    <div class="col-md-8 text-right mt-2">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

