import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { PayrollService } from 'src/app/providers/payroll.service';
import { SettingsService } from 'src/app/providers/settings.service';
import { ShowerrorComponent } from 'src/app/showerror/showerror.component';
import { ExcelService } from 'src/app/services/excel.service';
import { ExportExcelService} from 'src/app/services/export-excel.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake  from "html-to-pdfmake";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-ot-reports',
  templateUrl: './ot-reports.component.html',
  styleUrls: ['./ot-reports.component.scss']
})
export class OtReportsComponent implements OnInit {

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  userData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =true;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: number;
  ratingId: any;
  req: boolean=false;
  startIndex: number;
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  organizationSettings: any;
  totalamountbeforededuction: number= 0;
  totalnetpay: number = 0;
  totalDedution: number = 0;
  period: string = "";
  employeeName: any = "";
  firstname: any;
  lastname: any;
  empnumber: any;
  empCode: boolean;
  @ViewChild('pdfTable')pdfTable!: ElementRef;
  clientaddressobj: any = {};
  
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any , private ExportExcelService  : ExportExcelService) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.userData.userId;
    this.orgId = this.userData.organization.organizationId;
    console.log(this.orgId);


    

    this.settingsService.getFinicalyearActiveList(this.orgId ).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      // resultData.forEach((fYear) => {
      //   if (fYear.financialYearStatus.listTypeValueId == 1) {
      //     console.log(fYear);
      //     this.financialYearList = resultData;
      //   }
      // });
    });

    
   
    this.filterForm = this.fb.group({
      employeecode:[null],
      financialyear:[null,],
      period:[null,],
      client:[null,],
    })
   

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
    

    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.organizationSettings = resultData;
      
    });
  }
  addpayroll()
  {
   
    this.router.navigateByUrl('/home/employee-payroll');
  }
   
  searchSubmit()
  {
    console.log("submit");
    this.req=false;
    console.log(this.filterForm.value);
    console.log("this.filterForm.value.employeecode" + this.filterForm.value.employeecode);
    console.log("this.filterForm.value.financialyear" + this.filterForm.value.financialyear);
    console.log("this.filterForm.value.period" + this.filterForm.value.period);
    if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null ) && (this.filterForm.value.client=== 'null' || this.filterForm.value.client === null ) &&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period === 'null')){
      console.log("Required Field");
      this.resultData =[];
      this.noRecordsFound = true;
      alert("Please select  Requried fields");
      // this.req=true;
      return
    }
   else if((this.filterForm.value.employeecode) && ( this.filterForm.value.financialyear) && (this.filterForm.value.period ))
    {
          

      console.log("both");
      this.payrollservice.getOtReportByEmployeeId(this.employeeId,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        
        //console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
       

        }
        
        if( resultData.statusCode == 204)
        {
          this.noRecordsFound = true;
        }
      })
    }
    
    else if((this.filterForm.value.client) && (this.filterForm.value.financialyear) && (this.filterForm.value.period  ) )
    {
      console.log("finactical and period");
      this.payrollservice.getOtReportByClientId(this.filterForm.value.client , this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        this.calucatetotal();
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        else{
          let lastindex = this.resultData.length -1;
          this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear;
       

        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    
    this.clientList.forEach(element => {
      console.log("foreach")
      if(element.clientId == this.filterForm.value.client)
      {
        console.log(element)
         this.clientaddressobj = element;
      }
   });
  }


  
  empSearch(event)
  { 
    this.empList=[];
    this.empCode=false;
    console.log(event.target.value);
    this.userId=event.target.value;
    console.log(this.userId);
    // Employee List Api
      // this.empList.length == 0;
      if(event.target.value.length != 0)
      {
        this.payrollservice.getClienEmployeeList(this.userId,this.filterForm.value.client).subscribe((result: any) => {
          if(result.statusCode==204)
                {
                  console.log('NO Data');
                  this.empList.length = 0;
                  this.empCode=true;
                }
                else
                {
                  this.empList = result;
                  this.empCode=false;
                  console.log(this.empList);
                }
        })
      }else{
        this.filterForm.patchValue({
          employeecode: null
       });
      }
  
  }
  
  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log(emp);
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;

  
   
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;

    console.log(this.employee);
    
      this.filterForm.patchValue({
        employeecode: this.employee
     });
  
    // }
  
  }


  clearTable()
  {
    this.resultData.length = 0;
  }

  calucatetotal()
  {
  
      this.totalamountbeforededuction =  0;
    this.totalnetpay = 0;
    this.totalDedution = 0;
     if(this.resultData.length != undefined )
     {
      this.resultData.forEach(element => {
        // console.log("element.employeeAnnexure.ctc" + element.employeeAnnexure.ctc)
         console.log("this.totalDedution" +this.totalDedution);
        this.totalamountbeforededuction += element.totalAmountbeforededuction;
        this.totalnetpay += element.totalAmount;
        this.totalDedution +=  element.totalDeduction;
    });
     }
   
  }

  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
  selectFinancialyear(event)
  {
      if(event.target.value == 'null')
      {
        this.filterForm.patchValue({
        
          financialyear:null,
        
        })
      }else{
        console.log("Select");
        console.log(event.target.value);
        // console.log(this.filterForm.value.financialyear);
         this.chooseFinId=event.target.value;
        console.log(this.chooseFinId);
        this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
          console.log("PeriodData", resultData)
          this.finPeriodList = resultData.finanicalPeriod;
        });
      }
   
  }

  selectperiod(event)
  {

    if(event.target.value == 'null')
    {
      console.log("null perios")
      this.filterForm.patchValue({
      
        period:null,
      
      })
    }
  }
  updateform(row)
  {
    console.log("edit",row);
    console.log("edit",row.employeerattingId);
    this.ratingId=row.employeerattingId;
    console.log(this.ratingId);
    // this.router.navigateByUrl('/home/edit-employee-rating/',row.employeerattingId);
    this.router.navigate(['/home/view-employee-payroll/', this.ratingId])
  }

  showmontlyctc(ctcamount){

    ctcamount = ctcamount / 12 
   return  ctcamount.toFixed(2);
  }


  printPdf(){
    console.log("PrintPDF");
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { 
      content: html ,
      // image: this.fileblob, // Use the dataURL here
      // width: 200,
      // height: 100,
      pageMargins: [ 40, 40, 40, 40 ],
      info: {
        title:`OT Report`
        },
        pageSize: 'A4',
        pageOrientation: 'landscape',
      };
     pdfMake.createPdf(documentDefinition).open(); 
  }

}
