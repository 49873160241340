import { Component, OnInit } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DenyreasonComponent } from '../denyreason/denyreason.component';
import * as moment from 'moment';
import { LeaveService } from 'src/app/providers/leave.service';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-emp-leave-dashboard',
  templateUrl: './emp-leave-dashboard.component.html',
  styleUrls: ['./emp-leave-dashboard.component.scss']
})
export class EmpLeaveDashboardComponent implements OnInit {
  leaveApproveCount: any = 0;
  leaveDeniedCount: any = 0;
  workApproveCount: any = 0;
  workDeniedCount: any = 0;
  businessApproveCount: any = 0;
  businessDeniedCount: any = 0;
  permissionApproveCount: any = 0;
  permissionDeniedCount: any = 0;
  noOfDays: any = 0;
  remainingLeave: any = 0
  employeeId: any

  totalLeave: any = 0
  User_Name: any;
  Leavecurrentdate: any;
  Holidaycurrentdate: any;
  upComingHolidaydata: any;
  upcomingLeavedata: any;
  listtypevalues: any = []
  holidaytypeId: any;
  totaldays: any[];
  January: any = [];
  February: any = [];
  March: any = [];
  April: any = [];
  May: any = [];
  June: any = [];
  July: any = [];
  August: any = [];
  September: any = [];
  October: any = [];
  November: any = [];
  December: any = [];
  currentyear: number;
  day: any;
  monthyear: string;
  balancePermission: any;
  totaldayscount: number;
  settingslist: any;
  maximumPermissionHours: any;
  userType: any;
  organizationId: any;
  ActiveEmployees: any;
  LeavePendingCount: any;
  LeaveTotalCount: any;
  PermissionPendingCount: any;
  PermissionTotalCount: any;
  WFHLeaveTotalCount: any;
  WFHPendingCount: any;
  User_mail:any;
  leaveRequestPendingdata: any;
  WFHPendingdata: any;
  TotalLeaveAndWfh: any=[];
  currentdateOfmonth: string;
  upcomingsheduleLeave: any;
  Totalupcomingsheduledata: any=[];
  upcomingsheduleWFH: any;
  upcomingshedulePermission: any;
  upcomingsheduleBT: any;
  SortedLeaveAndWfh: any;
  startDate: any;
  dateBasedArray: any=[];
  arr: any=[];
  Day: string;
  headers: string;
  base:any=[];
  finalArr: any=[];
  dateArray: any[];
  TotalLeaveAndWfhlength: any;
  upcomingHolidayLoader:Boolean;
  loader:boolean=true;
  constructor(private gs: GlobalserviceService,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    public leaveservice : LeaveService,
    private headerservice: HeaderService
  ) { }

  ngOnInit(): void {
    this.loader=true;
    this.headerservice.setTitle('- Dashboard');
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(x);
    this.employeeId = x.employee.employeeId;
    this.User_Name = x.employee.firstName +' '+ x.employee.lastName;
    // this.userType = x.userType.userType;
    this.organizationId =x.organization.organizationId;
    this.User_mail = x.emailId;
    this.leaveBalance();
    this.wfh();
    this.bt();
    this.lb();
    this.permission();
    this.upcomingHoliday();
    // this.employeeRequestCount();
    // this.requestList();
    // this.upcomingshedule();
    this.leaveservice.getsettings(this.organizationId).subscribe(
      (data:any)=>
    {
      this.settingslist = data;
      console.log(this.settingslist);
      this.settingslist.forEach(element => {
        this.maximumPermissionHours=element.maximumPermissionHours;
        console.log("maximumPermissionHours",this.maximumPermissionHours);
      });
      this.loader=false;
    })

    let todaydate = new Date();
    this.monthyear = this.datepipe.transform(todaydate, 'yyyy-MM'),
    this.loader=true;

    this.leaveservice.getbalancePermission(this.monthyear,this.employeeId).subscribe(
      (data: any) => {
        console.log(data);
        this.balancePermission = data.availablePermissionBalance;
        console.log(this.balancePermission);
        this.loader=false;
      })
  }

  leaveBalance() {
    this.loader=true;
    this.leaveservice.GetMYleaveBalance(this.employeeId).subscribe(
      (data: any) => {
        console.log(data)
        data.map(
          e => {
            // if(e.leaveType.noOfDays == 21){
            console.log(e)
            this.noOfDays = e.noOfDays;
            this.totalLeave = e.leaveType.noOfDays
            // }
          }
        )
        this.loader=false;
      }
    )
  }
  wfh() {
    const arr = new Array()
    const arr1 = new Array()
    this.loader=true;

    this.leaveservice.get_myWfh(this.employeeId, 1).subscribe(
      (data: any) => {
        data.map(
          e => {
            if (e.workFromHomeStatus == 5) {
              arr.push(e)
              this.workApproveCount = arr.length;
            }
            if (e.workFromHomeStatus == 6) {
              arr1.push(e)
              this.workDeniedCount = arr1.length;
            }
          }
        )
      }
    )
    this.loader=false;
  }
  bt() {
    const arr = new Array()
    const arr1 = new Array()
    this.loader=true;

    this.leaveservice.getMyBusinessTravel_List(this.employeeId, 2).subscribe(
      (data: any) => {
        data.map(
          e => {
            if (e.businessTravelStatus == 5) {
              arr.push(e)
              this.businessApproveCount = arr.length;
            }
            if (e.businessTravelStatus == 6) {
              console.log(e)
              arr1.push(e)
              this.businessDeniedCount = arr1.length;
            }
          }
        )
      }
    )
    this.loader=false;
  }
  lb() {
    const arr = new Array()
    const arr1 = new Array()
    this.loader=true;

    this.leaveservice.getMyleave(this.employeeId).subscribe(
      (data: any) => {
        data.map(
          e => {
            if (e.leaveStatus == 5) {
              arr.push(e)
              this.leaveApproveCount = arr.length;
              console.log(this.leaveApproveCount);
            }
            if (e.leaveStatus == 6) {
              arr1.push(e)
              this.leaveDeniedCount = arr1.length;
              console.log(this.leaveDeniedCount);
            }
            if (e.leaveStatus == 7) {
              if (e.noOfLeaveDays != 0) {
                arr.push(e)
                this.leaveApproveCount = arr.length;
                console.log(this.leaveApproveCount);
              }
            }
          }
        )
      }
    )
    this.loader=false;
  }
  permission() {
    this.loader=true;
    const arr = new Array()
    const arr1 = new Array()
    this.leaveservice.get_permissionList(this.employeeId).subscribe(
      (d: any) => {
        d.map(
          e => {
            if (e.permissionStatus == 5) {
              arr.push(e)
              this.permissionApproveCount = arr.length;
            }
            if (e.permissionStatus == 6) {
              arr1.push(e)
              this.permissionDeniedCount = arr1.length;
            }
          }
        )
        this.loader=false;
        this.upcomingHolidayLoader =true;
      }
    )
  }
  // upcomingshedule(){
  //   let todaydate = new Date();
  //   this.currentdateOfmonth = this.datepipe.transform(todaydate, 'yyyy-MM-dd'),
  //   this.leaveservice.upcomingsheduleleave(this.organizationId,this.currentdateOfmonth).subscribe(
  //     (data: any) => {
  //       this.upcomingsheduleLeave=data;
  //       console.log("upcomingsheduleLeave",this.upcomingsheduleLeave);
  //       let newobject = {};
  //       if(!data.statusCode){
  //         if(this.upcomingsheduleLeave != undefined){
  //           this.upcomingsheduleLeave.map(
  //             (d: any) => {
  //               // console.log(d);
  //               newobject = {
  //                 employee:{
  //                   empName:d.employee.employeeName,
  //                   designationName: d.employee.designationName,
  //                 },
  //                 leaveType: {
  //                   leaveTypeId: d.leaveType.leaveTypeId,
  //                   leaveName:d.leaveType.leaveName,
  //                 },
  //                 noOfLeaveDays: d.noOfLeaveDays,
  //                 startDate:d.startDate,
  //                 endDate:d.endDate
  //               }
  //               this.getStartDay(d.startDate.substring(0,10));
  //               newobject['startDay2']=this.headers;
                
  //               if(d.employee.attachment != undefined){
  //                 newobject['attachment']= {
  //                   fileViewUrl: d.employee.attachment.fileViewUrl
  //                 }
  //               }
  //               // console.log("Leave Req data",newobject);
  //               this.Totalupcomingsheduledata.push(newobject);
  //               console.log("Totalupcomingsheduledata",this.Totalupcomingsheduledata);
  //             }
  //           )
  //         }
  //       }
  //       this.leaveservice.upcomingsheduleWFH(this.organizationId,this.currentdateOfmonth).subscribe(
  //         (data: any) => {
  //           this.upcomingsheduleWFH=data;
  //           console.log("upcomingsheduleWFH",this.upcomingsheduleWFH);
  //           let newobject = {};
  //           if(!data.statusCode){
  //             if(this.upcomingsheduleWFH != undefined){
  //               this.upcomingsheduleWFH.map(
  //                 (d: any) => {
  //                   // console.log(d);
  //                   newobject = {
  //                     employee:{
  //                       empName:d.employee.employeeName,
  //                       designationName: d.employee.designationName,
  //                     },
  //                     leaveType: {
  //                       leaveName: "Work Form Home",
  //                     },
  //                     noOfLeaveDays: d.noOfDays,
  //                     startDate:d.startDate,
  //                     endDate:d.endDate
  //                     // startDate:d.startDate.substring(0,10),
  //                     // endDate:d.endDate.substring(0,10)
  //                   }
  //                   this.getStartDay(d.startDate.substring(0,10));
  //                   newobject['startDay2']=this.headers;
  //                   if(d.employee.attachment != undefined){
  //                     newobject['attachment']= {
  //                       fileViewUrl: d.employee.attachment.fileViewUrl
  //                     }
  //                   }
  //                   // console.log("Leave Req data",newobject);
  //                   this.Totalupcomingsheduledata.push(newobject);
  //                   console.log("Totalupcomingsheduledata",this.Totalupcomingsheduledata);
  //                 }
  //               )
  //             }
  //           }
  //           this.leaveservice.upcomingshedulePermission(this.organizationId,this.currentdateOfmonth).subscribe(
  //             (data: any) => {
  //               this.upcomingshedulePermission=data;
  //               // console.log("upcomingshedulePermission",this.upcomingshedulePermission);
  //               let newobject = {};
  //               if(!data.statusCode){
  //                 if(this.upcomingshedulePermission != undefined){
  //                   this.upcomingshedulePermission.map(
  //                     (d: any) => {
  //                       // console.log(d);
  //                       newobject = {
  //                         employee:{
  //                           empName:d.employee.employeeName,
  //                           designationName: d.employee.designationName,
  //                         },
  //                         leaveType: {
  //                           leaveName: "Permission",
  //                         },
  //                         permissionHours: d.permissionHours.substring(0,2),
  //                         // noOfLeaveDays: d.permissionHours,
  //                         startDate:d.permissionDate
  //                       }
  //                       this.getStartDay(d.permissionDate.substring(0,10));
  //                       newobject['startDay2']=this.headers;
  //                       if(d.employee.attachment != undefined){
  //                         newobject['attachment']= {
  //                           fileViewUrl: d.employee.attachment.fileViewUrl
  //                         }
  //                       }
  //                       if(d.permissionHours == '00:15:00'){
  //                         newobject['noOfLeaveDays'] = '15 mins'
  //                       }    
  //                       if(d.permissionHours == '00:30:00'){
  //                         newobject['noOfLeaveDays'] = '30 mins'
  //                       }
  //                       if(d.permissionHours == '01:00:00'){
  //                         newobject['noOfLeaveDays'] = '1 Hour'
  //                       }
  //                       if(d.permissionHours == '02:00:00'){
  //                         newobject['noOfLeaveDays'] = '2 Hours'
  //                       }
  //                       if(d.permissionHours == '03:00:00'){
  //                         newobject['noOfLeaveDays'] = '3 Hours'
  //                       }
  //                       // console.log("Leave Req data",newobject);
  //                       this.Totalupcomingsheduledata.push(newobject);
  //                       console.log("Totalupcomingsheduledata",this.Totalupcomingsheduledata);
  //                     }
  //                   )
  //                 }
  //               }
  //               this.leaveservice.upcomingsheduleBT(this.organizationId,this.currentdateOfmonth).subscribe(
  //                 (data: any) => {
  //                   this.upcomingsheduleBT=data;
  //                   // console.log("upcomingsheduleBT",this.upcomingsheduleBT);
  //                   let newobject = {};
  //                   if(!data.statusCode){
  //                     if(this.upcomingsheduleBT.statusCode != 204){
  //                       this.upcomingsheduleBT.map(
  //                         (d: any) => {
  //                           // console.log(d);
  //                           newobject = {
  //                             employee:{
  //                               empName:d.employee.employeeName,
  //                               designationName: d.employee.designationName,
  //                             },
  //                             leaveType: {
  //                               leaveName: "Business Travel",
  //                             },
  //                             noOfLeaveDays: d.noOfDays,
  //                             startDate:d.startDate,
  //                             endDate:d.endDate
  //                             // startDate:d.startDate.substring(0,10),
  //                             // endDate:d.endDate.substring(0,10)
  //                           }
  //                           this.getStartDay(d.startDate.substring(0,10));
  //                           newobject['startDay2']=this.headers;

  //                           if(d.employee.attachment != undefined){
  //                             newobject['attachment']= {
  //                               fileViewUrl: d.employee.attachment.fileViewUrl
  //                             }
  //                           }
  //                           console.log("Leave Req data",newobject);
  //                           this.Totalupcomingsheduledata.push(newobject);
  //                           console.log("final Totalupcomingsheduledata",this.Totalupcomingsheduledata);
  //                           this.Totalupcomingsheduledata.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
  //                           console.log("sorted",this.Totalupcomingsheduledata);
  //                           ///header
  //                           let dateArray=[];
  //                           let object={};
  //                           this.Totalupcomingsheduledata.forEach(date => {
  //                                 let status=dateArray.includes(date.startDate.substring(0,10));
  //                                 console.log("status:",status);
  //                                 if(status==false){
  //                                   dateArray.push(date.startDate.substring(0,10));
  //                                 }
  //                                 else{
  //                                   console.log("dont push");
  //                                 }
  //                             })
  //                             console.log("dateArray",dateArray);
  //                             this.dateArray=dateArray;
                              
  //                                this.dateArray.forEach(element => {
                                  
  //                                 this.arr =  this.Totalupcomingsheduledata.filter((listdata) => listdata.startDate.substring(0,10) == element);
  //                                   console.log(this.arr);
  //                                   if(this.arr.length != 0)
  //                                   {
  //                                     this.finalArr.push(this.arr);
  //                                   }
                                 
  //                                 console.log("Final Array",this.finalArr);
  //                                });
  //                         }
  //                       )
  //                     }
  //                   }
  //                 }
  //               )
  //             }
  //           )
  //         }
  //       )
  //     }
  //     )
  // }
  getStartDay(date){
    console.log("-----------Date-------------",date);
      switch (new Date(date).getDay()) {
      case 0:
        this.Day="sunday";
        break;
      case 1:
        this.Day="Monday";
        break;
      case 2:
        this.Day="Tuesday";
        break;
      case 3:
        this.Day="Wednesday";
        break;
      case 4:
        this.Day="Thursday";
        break;
      case 5:
        this.Day="Friday";
        break;
        case 6:
        this.Day="Saturday";
        break;
      }
      this.headers=this.Day;
      console.log(this.headers);
  }
  upcomingHoliday() {
    
    let alldata = [];
    let todaydate = new Date();
    this.Holidaycurrentdate = this.datepipe.transform(todaydate, 'MM-dd-yyyy'),
      console.log(this.Holidaycurrentdate);
      this.currentyear =  (new Date()).getFullYear();
    this.leaveservice.getUpComingHoliday(this.employeeId, this.Holidaycurrentdate,this.organizationId).subscribe(
      (data: any) => {
        this.upComingHolidaydata = data.holiday;
        console.log("holidays", this.upComingHolidaydata);
        let newobject = {};
        if( this.upComingHolidaydata != undefined){
          this.upComingHolidaydata.map(
            (d: any) => {
              console.log(d);
              let holidayYear = new Date(d.holidayDate).getFullYear();
              if (holidayYear === this.currentyear) {
                newobject = {
                  appliedDate: this.datepipe.transform(d.holidayDate, 'dd-MM-yyyy').substring(0, 2),
                  month: d.holidayDate.substring(0, 2),
                  appliedDay: new Date(d.holidayDate).getDay(),
                  description: d.holidayName,
                  holidaytypename: d.holidaytypename,
                  leavestatus: d.holidayStatus
                }
              alldata.push(newobject);
              // console.log(alldata);
              }
            }
          )
        }
        let todaydate = new Date();
        this.Leavecurrentdate = this.datepipe.transform(todaydate, 'yyyy-MM-dd'),
        console.log(this.Leavecurrentdate);
          this.leaveservice.getUpComingLeaves(this.employeeId, this.Leavecurrentdate).subscribe(
            (data: any) => {
              this.upcomingLeavedata = data.leaveRequest;
              console.log("leaves", this.upcomingLeavedata);
              let newobject2 = {};
              if( this.upcomingLeavedata != undefined){
                this.upcomingLeavedata.map(
                  (d: any) => {
                    // console.log(d);
                    let leaveStartYear = new Date(d.startDate).getFullYear();
                    if (leaveStartYear === this.currentyear) {
                      newobject2 = {
                        appliedDate: this.datepipe.transform(d.startDate, 'dd-MM-yyyy').substring(0, 2),
                        month: d.startDate.substring(5, 7),
                        appliedDay: new Date(d.startDate).getDay(),
                        description: d.leaveType.leaveName,
                        leavestatus: d.leaveStatusName
                      }
                      if(d.endDate != d.startDate){
                        console.log(this.datepipe.transform(d.startDate, 'dd-MM-yyyy'));
                        console.log(this.datepipe.transform(d.endDate, 'dd-MM-yyyy'));
                        newobject2['endate']=this.datepipe.transform(d.endDate, 'dd-MM-yyyy').substring(0, 2);
                        // newobject2['appliedEndDay']= new Date(d.startDate).getDay()+'-'+new Date(d.endDate).getDay();
                        newobject2['appliedEndDay']=new Date(d.endDate).getDay();
                      }
                      alldata.push(newobject2);
                    }
                  }
                )
              }
  
              console.log("holidays and Leaves", alldata);
              this.totaldays = alldata;
              this.totaldayscount=this.totaldays.length;
              // console.log(this.totaldayscount);
              // console.log(this.totaldays);
              this.totaldays.map(
                data => {
                  // console.log(this.totaldays);
                  // console.log(data);
                  // console.log(data.month);
                  switch (data.month) {
                    case '01':
                      console.log(data.month);
                      this.January.push(data);
                      console.log("January", this.January);
                      this.January = this.January.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '02':
                      console.log(data.month);
                      this.February.push(data);
                      console.log("February", this.February);
                      this.February = this.February.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '03':
                      console.log(data.month);
                      console.log("index Based Data:", data)
                      this.March.push(data);
                      console.log("March", this.March);
                      this.March = this.March.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '04':
                      console.log(data.month);
                      this.April.push(data);
                      console.log("April", this.April);
                      this.April = this.April.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '05':
                      console.log(data.month);
                      this.May.push(data);
                      console.log(this.May);
                      this.May = this.May.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '06':
                      console.log(data.month);
                      this.June.push(data);
                      console.log(this.June);
                      this.June = this.June.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '07':
                      console.log(data.month);
                      this.July.push(data);
                      console.log(this.July);
                      this.July = this.July.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '08':
                      console.log(data.month);
                      this.August.push(data);
                      console.log(this.August);
                      this.August = this.August.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '09':
                      console.log(data.month);
                      this.September.push(data);
                      console.log(this.September);
                      this.September = this.September.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '10':
                      console.log(data.month);
                      this.October.push(data);
                      console.log(this.October);
                      this.October = this.October.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '11':
                      console.log(data.month);
                      this.November.push(data);
                      console.log(this.November);
                      this.November = this.November.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                    case '12':
                      console.log(data.month);
                      this.December.push(data);
                      console.log(this.December);
                      this.December = this.December.sort(
                        (objA, objB) => Number(objA.appliedDate) - Number(objB.appliedDate),
                      );
                      break;
                  }
                }
              )
              console.log(this.totaldays);
              this.upcomingHolidayLoader =false;
            }
          )
        }
    )
  }
  // employeeRequestCount(){
  //   let todaydate = new Date();
  //   this.monthyear = this.datepipe.transform(todaydate, 'yyyy-MM'),
  //   this.leaveservice.employeeRequestCount(this.organizationId,this.monthyear,this.employeeId).subscribe(
  //     (data: any) => {
  //       console.log(data);
  //       this.ActiveEmployees=data.activeEmployees,
  //       this.LeavePendingCount=data.pendingTotalLeaveRequest,
  //       this.LeaveTotalCount=data.totalLeaveRequest,
  //       this.PermissionPendingCount=data.pendingTotalPermission,
  //       this.PermissionTotalCount=data.totalPermission,
  //       this.WFHPendingCount=data.pendingTotalWorkFromHome,
  //       this.WFHLeaveTotalCount=data.totalWorkFromHome
  //     }
  //   )
  // }
  // requestList(){
  //   this.leaveservice.getleaveReqPending(this.employeeId).subscribe(
  //     (Leave: any) => {
  //       console.log(Leave);
  //       if(Leave.length==0)
  //       {
  //         console.log("NOData");
  //       }
  //       this.leaveRequestPendingdata=Leave;
  //       let newobject = {};
  //       if( this.leaveRequestPendingdata != undefined){
  //         this.leaveRequestPendingdata.map(
  //           (d: any) => {
  //             console.log(d);
  //             newobject = {
  //               leaveRequestId : d.leaveRequestId,
  //               leaveReason: d.leaveReason,
  //               applyTo: [d.applyTo],
  //               cc: [d.cc],
  //               startDate:d.startDate,
  //               endDate:d.endDate,
  //               noOfLeaveDays: d.noOfLeaveDays,
  //               leaveStatus: d.leaveStatus,
  //               leaveStatusName:d.leaveStatusName,
  //               status: 1,
  //               employee:{
  //                 employeeId:d.employee.employeeId,
  //                 empName:d.employee.employeeName,
  //                 designation: d.employee.designation,
  //                 designationName: d.employee.designationName,
  //                 organizationEmail:d.employee.organizationEmail,
  //                 personalEmail:d.employee.personalEmail,
  //               },
  //               leaveType: {
  //                 leaveTypeId: d.leaveType.leaveTypeId,
  //                 leaveName:d.leaveType.leaveName,
  //               },
  //               leaveWithdraw: d.leaveWithdraw,
  //               leavereportingTo: d.leavereportingTo,
  //               leavereportingToSecond: d.leavereportingToSecond,
  //               halfday: d.halfday,
  //             }
  //             if(d.submittedOn !=undefined){
  //               newobject['submittedOn']=d.submittedOn.replace(/\//g, '-')
  //             }
  //             if(d.employee.attachment != undefined){
  //               newobject['attachment']= {
  //                 fileViewUrl: d.employee.attachment.fileViewUrl
  //               }
  //             }
  //             this.TotalLeaveAndWfh.push(newobject);
  //             console.log("---------------------------TotalLeaveAndWfh",this.TotalLeaveAndWfh);
  //             this.TotalLeaveAndWfhlength=this.TotalLeaveAndWfh.length;
  //             console.log(this.TotalLeaveAndWfhlength);

  //             this.SortedLeaveAndWfh=this.TotalLeaveAndWfh.sort(
  //               (objA, objB) => {
  //                 return moment(objA.submittedOn,'DD-MM-YYYY').isAfter(moment(objB.submittedOn,'DD-MM-YYYY')) ? 1 : -1
  //               }
  //             );
  //            console.log("Sorted",this.SortedLeaveAndWfh);
  //             // console.log("Request list length 1",this.TotalLeaveAndWfh.length);
  //           }
  //         )
  //       }
  //       this.WFHApivalue();
  //     },
  //     (err: any)=>{
  //       console.log(err.error);
  //       console.log(err.error.statusCode);
  //       // this.WFHApivalue();
  //     }
  //   )
  // }
  // WFHApivalue(){
  //   this.leaveservice.getWFHRequest(this.employeeId, 3).subscribe(
  //     (wfh: any) => {
  //       console.log(wfh);
  //       this.WFHPendingdata=wfh;
  //       if(wfh.statusCode==204)
  //       {
  //         console.log("NoData");
  //         this.TotalLeaveAndWfh.length == 0;
  //         this.TotalLeaveAndWfhlength =this.TotalLeaveAndWfh.length;
  //       }
  //       let newobject = {};
  //       if( this.WFHPendingdata != undefined){
  //         this.WFHPendingdata.map(
  //           (d: any) => {
  //             console.log(d);
  //             newobject = {
  //               workFromHomeId : d.workFromHomeId,
  //               startDate:d.startDate,
  //               endDate:d.endDate,
  //               noOfDays: d.noOfDays,
  //               reason: d.reason,
  //               applyTo: [d.applyTo],
  //               cc: [d.cc],
  //               workFromHomeStatus: d.workFromHomeStatus,
  //               workFromHomeStatusName:d.workFromHomeStatusName,
  //               status: 1,
  //               employee:{
  //                 employeeId:d.employee.employeeId,
  //                 empName:d.employee.employeeName,
  //                 employeeNumber: d.employee.employeeNumber,
  //                 mobileNumber: d.employee.mobileNumber,
  //                 designation: d.employee.designation,
  //                 designationName: d.employee.designationName,
  //                 organizationEmail:d.employee.organizationEmail,
  //                 personalEmail:d.employee.personalEmail,
  //               },
  //               leaveType: {
  //                 leaveName:"Work From Home",
  //               },
  //               wfhreportingTo: d.wfhreportingTo,
  //               wfhreportingToSecond: d.wfhreportingToSecond,
  //               category: {
  //                 categoryId: d.category?.categoryId
  //               },
  //               workfromhomedenyReason: d.workfromhomedenyReason,
  //               // submittedOn:d.submittedOn
  //             }
  //             if(d.submittedOn !=undefined){
  //               newobject['submittedOn']=this.datepipe.transform(d.submittedOn, 'dd-MM-yyyy')
  //             }
  //             // console.log(d.employee.attachment);
  //             // console.log(d.employee.attachment != undefined);
  //             // console.log( d.employee.attachment.fileViewUrl);
  //             if(d.employee.attachment != undefined){
  //               newobject['attachment']= {
  //                 fileViewUrl: d.employee.attachment.fileViewUrl
  //               }
  //             }
  //             this.TotalLeaveAndWfh.push(newobject);
  //             // console.log("allRequest",this.TotalLeaveAndWfh);
  //             console.log("---------------------------TotalLeaveAndWfh",this.TotalLeaveAndWfh);
  //             this.TotalLeaveAndWfhlength=this.TotalLeaveAndWfh.length;
  //             console.log(this.TotalLeaveAndWfhlength);

  //             this.SortedLeaveAndWfh=this.TotalLeaveAndWfh.sort(
  //               (objA, objB) => {
  //                 return moment(objA.submittedOn,'DD-MM-YYYY').isAfter(moment(objB.submittedOn,'DD-MM-YYYY')) ? 1 : -1
  //               }
  //             );
  //             // console.log("Sorted",this.SortedLeaveAndWfh);
  //             // console.log("Request list length 1",this.TotalLeaveAndWfh.length);
  //           }
  //         )
  //       }
  //     } ,
  //     (err)=>{
  //       console.log(err);
  //       // this.requestList();
  //     }
  //   )
  // }
  // approve(i) {
  //   if(i.leaveRequestId !=undefined){
  //     console.log("if");
  //     if (i.applyTo == 0) {
  //       i['applyTo'] = [];
  //     }
  //     else {
  //       i['applyTo'] = [i.employee.organizationEmail];
  //     }
  //     if (i.cc == 0) {
  //       i['cc'] = [];
  //     }
  //     else {
  //       i['cc'] = [i.employee.personalEmail];
  //     }
  
  //     Swal.fire({
  //       title: 'Are you sure? Do you want to Approve',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3f51b5',
  //       cancelButtonColor: '#f44336',
  //       confirmButtonText: 'Approve'
  //     }).then((result) => {
  //       if (result.value) {
  //         i['leaveStatus'] = 5;
  //         // this.isLoading = true;
  
  //         this.leaveservice.UpdateLeaveStatus(i.leaveRequestId, i).subscribe(d => {
  //           Swal.fire(
  //             'Approved!',
  //           )
  //           // this.isLoading = false
  //         },
  //           err => {
  //             Swal.fire(
  //               'Error',
  //             )
  //             // this.isLoading = false
  //           },
  //           () => {
  //             setTimeout(() => {
  //               window.location.reload();
  //             }, 1000);
  //             // this.isLoading = false
  //           }
  //         );
  
  //       }
  //     })
  //   }
  //   else{
  //     console.log("else");
  //     console.log(i);
  //     if (i.applyTo == 0) {
  //       i['appplyTo'] = [];
  //     }
  //     else {
  //       i['applyTo'] = [i.employee.organizationEmail];
  //     }
  //     i['cc'] = [];
  //     Swal.fire({
  //       title: 'Are you sure? Do you want to Approve',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3f51b5',
  //       cancelButtonColor: '#f44336',
  //       confirmButtonText: 'Approve!'
  //     }).then((result) => {
  //       if (result.value) {
  //         // this.isLoading = true
  //         i['workFromHomeStatus'] = 5
  //         this.leaveservice.Actionwfh(i.workFromHomeId, i).subscribe(d => {
  //           // this.isLoading = false
  //           Swal.fire(
  //             "Done",
  //             'Approved!',
  //           )
  //           window.location.reload()
  //         },
  //           err => {
  //             // this.isLoading = false
  //             Swal.fire(
  //               'error!',
  //               err.error.message
  //             )
  //           }
  //         );
  //       }
  //       //
  //     })
  //   }
  // }
  // denied(i) {
  //   if(i.leaveRequestId !=undefined){
  //     console.log("if");
  //     if (i.applyTo == 0) {
  //       i['appplyTo'] = [];
  //     }
  //     else {
  //       i['applyTo'] = [i.employee.organizationEmail];
  //     }
  //     if (i.cc == 0) {
  //       i['cc'] = [];
  //     }
  //     else {
  //       i['cc'] = [i.employee.personalEmail];
  //     }
  
  //     // leaveStatus
  //     Swal.fire({
  //       title: 'Are you sure? Do you want to Deny?',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3f51b5',
  //       cancelButtonColor: '#f44336',
  //       confirmButtonText: 'Deny'
  //     }).then((result) => {
  //       if (result.value) {
  //         i['leaveStatus'] = 6
  //         if (result.value == true) {
  //           const dialogRef = this.dialog.open(DenyreasonComponent, {
  //             // data: "",
  //             width: '500px',
  //             disableClose: true,
  //           })
  //           dialogRef.afterClosed().subscribe(result => {
  
  //             if (result) {
  
  //               i['leavedenyReason'] = result.denyReason;
  //               // this.isLoading = true
  //               this.leaveservice.UpdateLeaveStatus(i.leaveRequestId, i).subscribe(d => {
  //                 Swal.fire({
  //                   text:'Denied',
  //                   icon:'success'
  //                 })
  //                 // this.isLoading = false
  //                 window.location.reload()
  //               },
  //                 err => {
  //                   Swal.fire({
  //                     text:err.error.message,
  //                     icon:'error'
  //                   })
  //                   // this.isLoading = false
  //                 }
  //               );
  //             }
  //           })
  //         }
  //       }
  //     })
  //   }
  //   else{
  //     console.log("else");
  //     if (i.applyTo == 0) {
  //       i['appplyTo'] = [];
  //     }
  //     else {
  //       i['applyTo'] = [i.employee.organizationEmail];
  //     }
  //       i['cc'] = [];
  
  //     // leaveStatus
  //     Swal.fire({
  //       title: 'Are you sure? Do you want to Deny',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3f51b5',
  //       cancelButtonColor: '#f44336',
  //       confirmButtonText: 'Deny'
  //     }).then((result) => {
  //       if (result.value) {
  
  //         i['workFromHomeStatus'] = 6
  //         if (result.value == true) {
  //           const dialogRef = this.dialog.open(DenyreasonComponent, {
  //             data: "" ,
  //             width:'500px',
  //             disableClose:true,
  //           })
  //           dialogRef.afterClosed().subscribe(result => {
  
  //             if (result) {
  //               // this.isLoading = true
  //               i['workfromhomedenyReason'] = result.denyReason;
  
  //               this.leaveservice.Actionwfh(i.workFromHomeId, i).subscribe(d => {
  //                 // this.isLoading = false;
  //                 Swal.fire({
  //                   text:'Denied',
  //                   icon:'success'
  //                 })
  //                 window.location.reload()
  //               },
  //                 err => {
  //                   // this.isLoading = false;
  //                   Swal.fire({
  //                      text:err.error.message,
  //                      icon:'error'
  //                   })
  //                 }
  //               );
  //             }
  //           });
  //         }
  //       }
  //     })
  //   }
  //   }
  }
