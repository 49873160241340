import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';

@Component({
  selector: 'app-add-state',
  templateUrl: './add-state.component.html',
  styleUrls: ['./add-state.component.scss']
})
export class AddStateComponent implements OnInit {
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  stateId:any;
  employeetData:any;
  stateNameExistStatus:any;

  countryData : any;

   constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddStateComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      country : this.formBuilder.group({
                countryId : ['',[Validators.required]]}),
      stateName : ['' ,[Validators.required,Validators.pattern(/^[A-Za-z0-9_+-]*$/)]],
      //stateCode : ['' ,[Validators.required,Validators.pattern(/^[0-9-]*$/)]],
      stateTin : [''],
      stateCode : ['' ,[Validators.required,Validators.pattern(/^[A-Za-z]+$/)]],
    });

    if(this.edit_data)
    {
      this.stateId = this.edit_data.stateId;
      this.settingsservice.getStateById(this.edit_data.stateId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          stateName: this.resultData.stateName,
          stateCode: this.resultData.stateCode,
          stateTin: this.resultData.stateTin,
          //country: this.resultData.country.countryId
          country:
          {
            countryId:this.resultData.country.countryId
          }
        });
      });
    }

    this.getLovCountries();
  }

  getLovCountries()
  {
    this.settingsservice.getLovCountries().subscribe((result: any) => {
      this.countryData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

  // convenience getter for easy access to form fields
  get validate() { return this.postForm.controls; }

  //Submit Form
  onSubmit() //form Submit Function
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.submitted = true;

    //stop here if form is invalid
    if (this.postForm.invalid || this.stateNameExistStatus == true) {return;}

    if(this.edit_data) //Edit
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['stateId'] = this.edit_data.stateId;
      postValues['stateStatus'] = this.edit_data.stateStatus;

      this.backdrop = true;
      this.loader=true;

      this.settingsservice.editState(this.edit_data.stateId,postValues)
      .subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/department' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    else //Create
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}
      /*if(this.postForm.valid && this.departCodeExistStatus == true || this.departNameExistStatus == true)
      {
        console.log("if condition")
        return
      }*/
     /*  console.log(postValues);
      return */
      this.settingsservice.createState(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
  }

  checkExistStateName(val)

  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */

    if(this.postForm.value.stateName.length != 0)
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      if(this.edit_data)
      {
        postValues['stateId'] = this.edit_data.stateId;

        this.settingsservice.updateCheckExistStateName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.stateNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.stateNameExistStatus = true;
          }
        })
      }
      else
      {
        this.settingsservice.createCheckExistStateName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.stateNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.stateNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.stateNameExistStatus = false;
    }
  }


}
