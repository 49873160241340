<!-- Sub header start here-->
<!-- <section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="group">
          <span class="h5"> Business Group</span>
        </div>
        <div class="add-business" routerLink="/home/business-group">
          <button mat-raised-button class="m-1 searchBtn">
            Add Business Group
          </button>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Business Group</span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
        
          <a
            routerLink="/home/business-group"
          
            mat-raised-button
            class="ml-2 searchBtn"
          >
          Add Business Group
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="main-content">
    <div class="container-fluid">
      <div class="top-content row">
        <div>
          <!-- <h4 class="assign-employee">Assign Employee Task</h4> -->
        </div>
      </div>
      <div class="search-content">
        <form [formGroup]="searchForm">
        <div class="row">
          <div class="col-sm-4 apply-margin">
            <label class="form-control-label"
              >Business Group Name<span class="text-danger">*</span></label
            >

            <input
              type="text"
              autocomplete="off"
              placeholder="Search Business Group Name"
            formControlName="businessGroup"
              class="form-control email-input select-placeholder"
              (keyup)="this.showValidationMessage = false;"
              (keydown)="handleBackspace($event)"
              (input)="validateInput($event)"
            />

            <div *ngIf="(businessGroupValue?.touched && businessGroupValue?.invalid) || showValidationMessage" class="text-danger">
              <!-- <div *ngIf="businessGroupValue?.errors?.['required'] || showValidationMessage">
                Business Group Name is required.
              </div> -->
              <!-- <div *ngIf="businessGroupValue?.errors?.['pattern']">
                Invalid format for Business Group Name.
              </div> -->
            </div>
            
          
          </div>

          <div class="col-sm-4 apply-margin">
            <label class="form-control-label">Industry Type</label>

            <!-- <mat-select
              placeholder="Industry Type"
              [(ngModel)]="industryType"
              class="form-control email-input select-placeholder text-truncate"
              #singleSelect
            >
              <mat-option>
          
                <ngx-mat-select-search placeholderLabel="Search Employee">
                  <span ngxMatSelectNoEntriesFound>
                    No Employee Found
               
                  </span>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option> emp?.employee?.employeeNumber - </mat-option>
            </mat-select> -->

            <mat-select
              formControlName="industryType"
              placeholder="Select Industry Type"
              class="form-control email-input select-placeholder"
              (selectionChange)="selectionChangeIndustryType($event)"
            >
              <mat-option [value]="undefined" selected="selected"
                >-Select Industry Type-</mat-option
              >
              <mat-option
                *ngFor="let row of industryTypeListing; let i = index"
                value="{{ row.listTypeValueId }}"
                >{{ row.listTypeValueName }}
              </mat-option>
              <!-- <mat-option value="Service Based">Service Based</mat-option> -->
            </mat-select>
          </div>
          <div class="col-sm-4 mt-4">
            <button
              type="submit"
              (click)="searchBusinessGroup()"
              mat-raised-button
              class="searchBtn1"
              [disabled]="buttonDisable"
            >
              Search
            </button>

            <button
              type="reset"
              (click)="clearBusinessGroup()"
              mat-raised-button
              [disabled]="buttonDisable"
              class="btn searchBtn ml-3"
              style="
                background: none;
                color: black;
                border: 1px solid #ddd;
                font-size: 16px;
                font-weight: 610;
              "
            >
              Clear
            </button>
          </div>
        </div>
      </form>

        <div class="card-maargin">
          <div class="">
            <div class="row p-0 m-0">
              <div
                class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9 text-right"
              ></div>

              <div
                class="col-xl-2 col-lg-3 col-md-3 col-sm-4 text-right"
                *ngIf="businessGroupList.length > 5"
              >
                <div class="select-pagination">
                  <span>Show : </span>
                  <select
                    class="select_list new-select_list listDesign"
                    [(ngModel)]="mySelect"
                    (change)="changefirstpage()"
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive-new1 table-content card-content" *ngIf="tableShow">
              <table class="table" matSort (matSortChange)="sortData($event)" >
                <thead>
                  <!-- <th
                    mat-sort-header="sno"
                    scope="col"
                    style="text-align: center"
                  >
                    &nbsp;&nbsp;S.No
                  </th> -->

                  <th
                    class="sticky-col-tab"
                    mat-sort-header="action"
                    scope="col"
                  >
                    &nbsp;&nbsp;Action
                  </th>
                  <th
                    mat-sort-header="businessGroup"
                    style="text-align: center"
                    scope="col"
                  >
                    Business&nbsp;Group&nbsp;Name
                  </th>
                  <th mat-sort-header="Description" scope="col">
          
                    Business&nbsp;Description
                  </th>
                  <th mat-sort-header="Industry" scope="col">
                  
                    Industry&nbsp;Type
                  </th>
                  <th mat-sort-header="Entity" scope="col">
                    Entity&nbsp;Name                  </th>
                  <th mat-sort-header="Status" scope="col">
                    Business&nbsp;Group&nbsp;Status&nbsp;

                  </th>
                </thead>
                <tbody  style="    border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;">
                  <tr
                    *ngFor="
                      let e of businessGroupList
                        | paginate : { itemsPerPage: mySelect, currentPage: p };
                      let i = index
                    "
                  >
                    <!-- <td style="text-align: center" *ngIf="p">
                      {{ (p - 1) * mySelect + (i + 1) }}
                    </td>
                    <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td> -->
                    <td style="text-align: center">
                      <!-- <span class="material-icons" title="edit"    [routerLink]="['/home/business-group', e.businessGroupId]"> edit </span> -->

                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu" class="controls-list">
                        <button
                          mat-menu-item
                          [routerLink]="[
                            '/home/business-group',
                            e.businessGroupId
                          ]"
                        >
                          <mat-icon>edit</mat-icon>
                          <span>Edit</span>

                        </button>
                        <button
                        mat-menu-item
                        *ngIf="e.businessGroupStatus == 'Active'"
                        (click)="updateStatus(e.businessGroupId, 'In-active')"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: red">Inactive</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="e.businessGroupStatus == 'In-active'"
                        (click)="updateStatus(e.businessGroupId, 'Active')"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: #50cd89 !important">Active</span>
                      </button>
                        <!-- <button
                        mat-menu-item
                        *ngIf="e.legalEntityStatus == 'Active'"
                        (click)="updateStatus(e.legalEntityId, 'In-active')"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: red">Inactive</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="e.legalEntityStatus == 'In-active'"
                        (click)="updateStatus(e.legalEntityId, 'Active')"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: #50cd89 !important">Active</span>
                      </button> -->
                      </mat-menu>
                    </td>
                    <td style="text-align: center">
                      {{ e?.businessGroupName }}
                    </td>

                    
                    <!-- <td
                      style="
                        white-space: normal;
                        overflow-wrap: break-word;
                        word-break: normal;
                        min-width: 250px !important;
                      "
                    >
                      {{ e?.businessGroupStatus }}
                    </td> -->

                    <td style="min-width: 150px !important">
                      <span>{{ e?.businessGroupDescription }}</span>
                      <span *ngIf="!e?.businessGroupDescription">-</span>
                    </td>

                    <td>
                      {{ e?.industryType?.listTypeValueName }}
                    </td>
                    <td>
                      {{ e?.entity?.entityName }}
                    </td>
                    <td class="active-status text-center">
                      <span
                        *ngIf="e?.businessGroupStatus == 'Active'"
                        class="btn btn-sm btn-block"
                        style="color: #50cd89 !important"
                      >
                        {{ e?.businessGroupStatus }}
                      </span>
                      <span
                        *ngIf="e.businessGroupStatus == 'In-active'"
                        class="btn btn-sm btn-block"
                        style="color: red;width:86px;"
                      >
                        {{ e?.businessGroupStatus }}
                      </span>
                    </td>

                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        font-size: larger;
                        font-weight: bold;
                      "
                      colspan="10"
                      *ngIf="nodata || businessGroupList.length == 0 && tableShow"
                    >
                      Business Group Not Found!
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="pagination-content"
                *ngIf="businessGroupList.length > 5"
                id="page"
              >
                <pagination-controls
                  *ngIf="businessGroupList.length > 5"
                  class="text-right;background-color: #fff !important;"
                  style="float: right"
                  (pageChange)="p = $event; beforesearchpage = $event"
                  align="end"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
        <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
              <div class="card-body-nodata">
                <div class="alert-nodata alert-primary">
                  <h4 align="center" class="h4">Assign Employee Task Not Found</h4>
                </div>
              </div>
            </mat-card> -->
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="align-items-cEnter text-cEnter">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 51%;color: #1a83ff"
    role="status"
  ></div>
</div>

<div class="text-cEnter" *ngIf="success">
  <div class="alert alert-resource alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
