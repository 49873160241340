import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HrmsService } from '../../../providers/hrms.service';
import { AdminService } from '../../../providers/admin.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../../providers/properties';


@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss']
})
export class SuperAdminComponent implements OnInit 
{
  superAdminLogin: FormGroup;
  submitted = false;
  errorMsg : any;
  success:any=false;
  sucess_msg:any;
  password;
  show = false;

  constructor(
    private formBuilder: FormBuilder ,
    private router : Router,
    private hrmsservice: HrmsService,
    private AdminService: AdminService
  ) { }

  ngOnInit(): void 
  {
    this.superAdminLogin = this.formBuilder.group({
      email : ['' ,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password : ['',[Validators.required ]], //,  Validators.min(4), Validators.max(10)
    });
    this.password = 'password';
  }

  get validate() { return this.superAdminLogin.controls; }

  //Submit Form
  onSubmit()
  {
    
    this.router.navigate(['/super-admin-home/dashboard']);

    /* this.submitted = true;
    if (this.superAdminLogin.invalid) 
    {
        return;
    }

    let postValues =  {
        organizationEmail: this.superAdminLogin.value.email,
        password : this.superAdminLogin.value.password,
    }

    this.AdminService.adminLogin(postValues).subscribe((resultData: any) =>
    {
      
      let userData =JSON.stringify(resultData);
      localStorage.setItem('enoteUserData',userData);
      this.success = true;
      this.sucess_msg = resultData.message;
      setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      
      setTimeout(() => {
        this.router.navigate(['/home/dashboard']);
      }, redirectMsgTimeOut)

    },err =>{
      this.errorMsg = err.error.message;
    }) */
  }

  showPassword() 
  {
    if (this.password === 'password') 
    {
      this.password = 'text';
      this.show = true;
    } 
    else 
    {
      this.password = 'password';
      this.show = false;
    }
  }


}
