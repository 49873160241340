<mat-sidenav-container class="example-container sidenav-container" autosize>
    <!-- mat side nav start here -->
    <mat-sidenav #drawer class="example-sidenav sidenav" id="sidenavB" mmode="side" [class.menu-open]="expand" [class.menu-close]="!expand" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">

        <ng-container *ngIf="expand; else logoElse">
            <mat-nav-list class="logo">
                <img src="{{appLogo}}" class="brand" style="left:19px" class="header-logo" alt="" matTooltip="JesperApps HCM" />
            </mat-nav-list>
        </ng-container>

        <ng-template #logoElse>
            <mat-nav-list class="logoB">
                <img src="{{appLogoIcon}}" class="brandB" alt="" matTooltip="Enote">
            </mat-nav-list>
        </ng-template>


        <mat-nav-list class="mat-nav-list list-group p-0">
            <!-- Super Admin Menu Start -->
            <mat-list-item routerLink="dashboard" routerLinkActive="active">
                <mat-icon class="mat-sidebar-icon">dashboard</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                    Dashboard
                </a>
            </mat-list-item>

            <mat-list-item routerLink="entity" routerLinkActive="active"  [ngClass]="{
                'mat-list-item': true,
                active: isActiveOrganization()
              }"
              [class.router-link-active]="isActiveOrganization()">
                <mat-icon class="mat-sidebar-icon">group_add</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                     Entity
                </a>
            </mat-list-item>

            <!-- <mat-list-item routerLink="organization" routerLinkActive="active">
                <mat-icon class="mat-sidebar-icon">account_balance</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons">
                    Organizations
                </a>
            </mat-list-item> -->

            <!-- <div class="menu-sub-menus">
                <mat-list-item>
                    <mat-icon class="mat-sidebar-icon">perm_identity</mat-icon>
                    <a (click)="toggle()" mat-list-item class="sidebar-icons" title="Recruitment">
                        <span class="mat-item" *ngIf="expand">
                            Recruitment
                        </span>
                        <span class="material-icons-outlined icon" *ngIf="expand" class="arrow-icons">
                            <mat-icon *ngIf="isShow">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="!isShow">keyboard_arrow_right</mat-icon>
                        </span>
                    </a>
                </mat-list-item>

                <div *ngIf="isShow == true">
                    <ul class="mb-0 sub-menus sub">
                        <li>
                            <mat-icon ss="mat-sidebar-icon">perm_identity</mat-icon>
                            <a *ngIf="expand" title="Candidate" routerLink="resource-list" routerLinkActive="active" class="sidebar-icons">
                                Candidate
                            </a>
                        </li>

                        <li>
                            <mat-icon ss="mat-sidebar-icon">accessibility_new</mat-icon>
                            <a *ngIf="expand" title="Onboarding" routerLink="onboardings" routerLinkActive="active" class="sidebar-icons">
                                Onboarding
                            </a>
                        </li>

                        <li>
                            <mat-icon ss="mat-sidebar-icon">supervisor_account</mat-icon>
                            <a *ngIf="expand" title="Position" routerLink="positions" routerLinkActive="active" class="sidebar-icons">
                                Position
                            </a>
                        </li>

                        <li>
                            <mat-icon ss="mat-sidebar-icon">supervised_user_circle</mat-icon>
                            <a *ngIf="expand" title="Clients" routerLink="clients" routerLinkActive="active" class="sidebar-icons">
                                Clients
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->

            <mat-list-item routerLink="settings" routerLinkActive="active">
                <mat-icon class="mat-sidebar-icon">settings</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons"> Settings </a>
            </mat-list-item>

            <!-- Super Admin Menu Start -->

            <mat-list-item (click)="logout()">
                <mat-icon class="mat-sidebar-icon">logout</mat-icon>
                <a *ngIf="expand" mat-list-item class="sidebar-icons"> Sign Out</a>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <!-- mat side nav end here -->

    <!-- mat side nav content start here -->
    <mat-sidenav-content class="app-content scroll">
        <!-- header start here -->
        <mat-toolbar class="navbar container-fluid" color="primary">
            <div>
                <span type="button" class="text-light menubar" mat-button (click)="drawer.toggle()" *ngIf="drawer.mode==='over'">
                    <mat-icon class="material-icons-round header-menu">menu</mat-icon>
                </span>
            </div>
            <div>
                <span type="button" class="text-light menubar" mat-button (click)="MatDrawControl()" *ngIf="drawer.mode==='side'">
                  <mat-icon class="material-icons-round header-menu">menu</mat-icon>
              </span>
            </div>


            <div class="header-buttons">
                <!--<div ngbDropdown autoClose="true" class="notification-dropdown">


                    <a mat-button id="notify" mat-icon-button [matMenuTriggerFor]="notificationMenu" ngbDropdownToggle class="notify-icon">
                        <span class="notify-count badge badge-info">{{notificationcount}}</span>
                        <mat-icon type="button" class="material-icons-outlined">notifications</mat-icon>
                    </a>
                    <mat-menu #notificationMenu="matMenu" class="notification-list-box">

                        <div class="" ngbDropdownMenu="notify">
                            <a ngbDropdownItem class="notification-list-header">
                                <span class="notification-title">Notifications</span>
                                <span *ngIf="notificationcount > 0" type="button" class="material-symbols-outlined notification-clear-all deleteall" (click)="deleteAll(notificationlist)">
                                    Clear All
                                </span>

                            </a>
                            <a *ngIf="notificationcount > 0" ngbDropdownItem class="matMenu_notify notification-individual">
                                <ul *ngFor="let e of notificationlist; let i=index" class="mb-0 notification-lines">
                                    <li>
                                        <a (click)="resourceDetails(e.resource.resourceId)">
                                            <mat-icon class="material-symbols-outlined delete" (click)="delete(i)">delete</mat-icon>
                                            <div class="text-color notify-resource-name">{{e.resource.resourceName}} - {{e.resource.resourceNumber}}</div>
                                            <div class="sub-text notify-resource-doj">DOJ : {{e.dateOfJoining.substring(0,10)}} <span class="notify-resource-position">Position : {{e.resource.position.positionName}}</span></div>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                            <span *ngIf="notificationcount == 0" ngbDropdownItem class="matMenu_notify notification-individual text-center mt-3 mb-3">
                               No Data found.
                            </span>
                        </div>
                    </mat-menu>
                </div> -->

                <!--  <a routerLink=" " class="px-2 " title="Message ">
                    <mat-icon> message</mat-icon>
                </a>
                <a routerLink=" " class="px-2 " title="Quick Links ">
                    <mat-icon> local_activity</mat-icon>
                </a> -->
                <span class="pl-3 ">
                  <!-- Menu start here -->
                  <a *ngIf="headerProfileImage" mat-icon-button [matMenuTriggerFor]="myProfile" aria-label="Example icon-button with a menu" title="Profile">
                    <img class="header-profile-logo" src="assets/uploads/use.png" style="width:25px;height:25px;">
                  </a>

                  <a *ngIf="!headerProfileImage" mat-icon-button [matMenuTriggerFor]="myProfile" aria-label="Example icon-button with a menu" title="Profile">
                    <img class="header-profile-logo" src="assets/uploads/use.png" style="width:25px;height:25px;">
                  </a>

                  <mat-menu #myProfile="matMenu" class="profile-list-box">

                    <button mat-menu-item class="profile-name-button">
                        <img  src="assets/uploads/use.png" style="width:25px;height:25px;">
                        <span>
                            <!-- {{this.resultData.firstName}} {{this.resultData.lastName}} -->
                            Super Admin
                        </span>
                </button>

                <button mat-menu-item routerLink="my-profile" routerLinkActive="active" class="profile-buttons">
                        <div class="pro-buttons-list">
                            <mat-icon>account_circle</mat-icon>
                            <span>My Profile</span>
                        </div>
                    </button>

                <!--  <button mat-menu-item routerLink="my-profile" routerLinkActive="active" class="profile-buttons">
                        <div class="pro-buttons-list">
                            <mat-icon>edit</mat-icon>
                            <span>Edit Profile</span>
                            </div>
                        </button> -->

                <!-- <button mat-menu-item>
                            <mat-icon>visibility</mat-icon>
                            <span>Change Password</span>
                            </button> -->

                <!-- <button mat-menu-item class="profile-buttons">
                    <div class="pro-buttons-list">
                        <mat-icon>lock</mat-icon>
                        <span>Lock Screen</span>
                    </div>
                    </button>
                    -->
                <button mat-menu-item (click)="logout()" class="profile-buttons">
                        <div class="pro-buttons-list">
                            <mat-icon>logout</mat-icon>
                            <span>Sign Out</span>
                        </div>
                    </button>
                </mat-menu>
                <!-- Menu end here -->
                </span>
            </div>
            <span class="example-spacer"></span>
        </mat-toolbar>
        <!-- header end here -->
        <router-outlet></router-outlet>

        <!-- Footer start -->
        <!-- <div class="footer">
            <span>
              Copyright © 2022. All rights reserved. Developed and Maintained by <a href="https://www.jesperapps.com/" target="_blank" title="JESPERAPPS">JESPERAPPS</a>
            </span>
        </div> -->
        <!-- Footer end -->
    </mat-sidenav-content>
    <!-- mat side nav content end here -->


</mat-sidenav-container>