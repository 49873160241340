import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-denyreason',
  templateUrl: './denyreason.component.html',
  styleUrls: ['./denyreason.component.scss']
})
export class DenyreasonComponent implements OnInit {

  denyreasonform: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder, 
    private gs: GlobalserviceService, 
    @Inject(MAT_DIALOG_DATA) public data: any[], 
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<DenyreasonComponent>
  ) { }

  ngOnInit(): void {
    this.denyreasonform = this.fb.group({
      denyReason: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
    });
  }

  get f() {
    return this.denyreasonform.controls;
  }

  onSubmit(data) {
    this.submitted=true;
    if (this.denyreasonform.valid || this.invalidInput) {
      console.log(data);
      this.data = data;
      this.dialogRef.close(data);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  
  invalidInput: boolean = false;

  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
}
