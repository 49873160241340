<div class="row">
  <div class="col-md-11">
    <h5 class="mainDesign">Employee Timesheet</h5>
  </div>
  <div class="col-md-1">
    <h3 class=" float-right cancel-icon" mat-dialog-close>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M20.0001 33.505C27.4456 33.505 33.505 27.4456 33.505 20C33.505 12.5545 27.4456 6.49506 20.0001 6.49506C12.5545 6.49506 6.49512 12.5545 6.49512 20C6.49512 27.4456 12.5545 33.505 20.0001 33.505ZM20.0001 8.39605C26.3763 8.39605 31.604 13.5842 31.604 20C31.604 26.4158 26.3763 31.604 20.0001 31.604C13.6238 31.604 8.39611 26.3762 8.39611 20C8.39611 13.6238 13.6238 8.39605 20.0001 8.39605Z" fill="#FF5D5D"/>
        <path d="M14.9309 24.9505C15.1289 25.1485 15.3665 25.2277 15.6042 25.2277C15.8418 25.2277 16.0794 25.1485 16.2774 24.9505L20.0002 21.2277L23.723 24.9505C23.921 25.1485 24.1586 25.2277 24.3962 25.2277C24.6339 25.2277 24.8715 25.1485 25.0695 24.9505C25.4259 24.5941 25.4259 23.9604 25.0695 23.604L21.3467 19.8812L25.0695 16.1584C25.4259 15.802 25.4259 15.1683 25.0695 14.8119C24.7131 14.4554 24.0794 14.4554 23.723 14.8119L20.0002 18.5347L16.2774 14.8119C15.921 14.4554 15.2873 14.4554 14.9309 14.8119C14.5745 15.1683 14.5745 15.802 14.9309 16.1584L18.6537 19.8812L14.9309 23.604C14.5745 23.9604 14.5745 24.5545 14.9309 24.9505Z" fill="#FF5D5D"/>
      </svg>
    </h3>
  </div>
</div>
<div class="row p-0 m-0" *ngIf="tableShow">
    <div class="col-md-10 mt-2" *ngIf="submittedreport.length > 0">
      <span class="badge badge-light totaltime"  style="font-family: 'Mulish', sans-serif;
      ">Total Time : {{tasktotalTime}}</span>
    </div>
    <div class="col-md-2" style="    /* margin-left: 35px; */
    position: relative;
    ">
      <div class="col-xl-12 col-lg-12 p-0" *ngIf="submittedreport?.length > 5 && (submittedreport | mytask : search).length > 0">
          <div class="select-pagination mt-3" *ngIf="(submittedreport | mytask : search).length > 5">
              <span style="font-family: 'Mulish', sans-serif;"> Show : </span>
              <select class="select_list new-select_list newList" [(ngModel)]="mySelectpopup"  (change)="changefirstpage()">
                  <option class="form-control" value="5"> 5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
              </select>
          </div>
      </div>
    </div>
</div>

<div class="table-responsive">
<table class="table" matSort (matSortChange)="sortData($event)" style="margin-top: 10px;
"  *ngIf="tableShow">
    <thead>
      <tr id="trDesign">
        <!-- <th style="text-align: center;" scope="col">Sl.No</th> -->
        <th mat-sort-header="employeeName"  scope="col" style="border-radius: 15px 0px 0px 0px">Employee&nbsp;&nbsp;Name</th>
        <th mat-sort-header="projectName"  scope="col">Project&nbsp;&nbsp;Name</th>
        <th mat-sort-header="taskName"  scope="col">Task&nbsp;&nbsp;Name</th>
        <th mat-sort-header="date" style="text-align: center;" scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
        <th mat-sort-header="workedHours" style="text-align: center;" scope="col">Worked&nbsp;&nbsp;Hours</th>
        <th mat-sort-header="taskStatusName" scope="col">Task&nbsp;&nbsp;Status</th>
        <th scope="col">Approval&nbsp;&nbsp;Status</th>
        <th style="text-align: center;" scope="col" style="border-radius: 0px 15px 0px 0">Action</th>
      </tr>
    </thead>
    <tbody style="     border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
    font-family: 'Mulish', sans-serif;
          /* font-weight: 550; */
    font-style: normal;">
      <tr  *ngFor="let e of submittedreport
      | customPaginate : { itemsPerPage: mySelect, currentPage: p };  ;let i = index">
        <td>{{ e?.employee?.firstName | titlecase }}</td>
        <td>{{ e?.project?.projectName | titlecase }}</td>
        <td>{{ e?.task?.taskName | titlecase }}</td>
        <td>{{ e.date}}</td>
        <td style="text-align: center;" >{{ e.workedHours }}</td>
        <td [style.color]="withDrawStatus(e)" class="status">{{ e.task.taskStatusName }}</td>
        <td [style.color]="approvalStatus(e)"  class="status">{{ e.approvalStatusName }}</td>
        <td class="more" align="center">
          <span
            class="material-icons"
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu">
            more_vert
          </span>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="description(e)">
              <mat-icon class="edit-icon">date_range</mat-icon>
              <span class="edit-form">View Description</span>
            </button>
          </mat-menu>
        </td>
      </tr>

      <!-- <tr >
        <td style="text-align: center; font-size: larger;" 
      colspan="9"  *ngIf="nodata||submittedreport?.length == 0 "> Timesheet Not Found!</td>
      </tr> -->
      <tr *ngIf="nodata || (submittedreport && submittedreport?.length === 0)">
        <td style="text-align: center; font-size: larger;" colspan="9">Timesheet Not Found!</td>
      </tr>
      
    </tbody>
    
</table>
<div>
  <div class="row">
    <div class="col-md-4">
      <div class="showing-record"></div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right"></div>
    </div>
  </div>
</div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="    width: 3rem;
    height: 3rem;
    margin-top: -150px;"
    role="status"
  ></div>
 
</div>
<div
  class="pagination-content"
  *ngIf="submittedreport?.length > 5 && (submittedreport | mytask : search).length > 0 &&  tableShow"
  id="page"
>
  <pagination-controls  *ngIf="(submittedreport | mytask : search).length > 5"
    style="position: relative; float: inline-end;top: 7px;"
    (pageChange)="p = $event"
    align="end"
  ></pagination-controls>
</div>
<!-- <pagination-controls (pageChange)="page = $event ; beforesearchpagepopup  = $event" align="end"></pagination-controls> -->

<!-- <mat-card class="card-content" *ngIf="nodata">
<div class="card-body">
  <div class="alert alert-primary">
    <h4 align="center">Timesheet Not Found</h4>
  </div>
</div>
</mat-card> -->
