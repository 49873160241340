
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport data-color="red"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
      <div class="no-v-scroll">
        <mat-card class="sideNav-card">
          <div class="upcomingleave">
            <h5 class="upcomingleave-title">Upcoming Leave</h5>
            <div style="display: block;height: 385px;overflow: auto;">
              <table>
                <tr *ngFor="let e of totaldays;let i=index">
                  <td style="width:8%">{{i+1}}.</td>
                  <td style="width:70%">{{e.description}}</td>
                  <td style="width:22%">{{e.appliedDate | date:'MMM d'}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="hints">
            <div class="title-row">Hints<span class="text-danger">*</span></div>
            <table>
              <tr>
                <td style="width:10%">
                  <div class="round-shape" style="background: #00BB1E;"></div>
                </td>
                <td style="width:90%">&nbsp;National Holiday</td>
              </tr>
              <tr>
                <td style="width:10%">
                  <div class="round-shape" style="background: #FF9999;"></div>
                </td>
                <td style="width:90%">&nbsp;Personal Leave</td>
              </tr>
              <tr>
                <td style="width:10%">
                  <div class="round-shape" style="background: #40e0d0;"></div> 
                </td>
                <td style="width:90%">&nbsp;Exception</td>
              </tr>
            </table>
          </div>
        </mat-card>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <section>
            <div class="sub-header">
                <div class="col-md-8 mb-1">
                    <div class="row">
                        <div class="col-sm-6">
                            <span class="h5 sub-content">Calendar</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="common-content">
            <mat-card class="large-calendar">
                <full-calendar #calendar 
                    #fullcalendar min="startYear" max="endYear"
                    *ngIf="calendarVisible"
                    [options]="calendarOptions"
                    plugins="calendarOptions.plugins"
                ></full-calendar> 
            </mat-card>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
  

