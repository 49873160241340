<nav class="navbar bg" style="margin-top: 68px" id="editForm">
  <div class="container-fluid mt-n2">
    <h4 class="heading-tag">Task Group</h4>
    <form class="d-flex">
      <input
        class="form-control me-2"
        aria-label="Search"
        type="text"
        class="form-control search-menu1 search-placeholder"
        placeholder="Search..."
        [(ngModel)]="search"
        (keyup)="filterByValue()"
        name="searchText"
      />
    </form>
  </div>
</nav>

<!-- <nav class="navbar bg" style="margin-top: 68px">
  <div class="container-fluid">
    <a class="navbar-brand brandDesign">Task Group</a>
    <div class="col-md-5 col-sm-5">
      <ul class="unstyled inbox-pagination">
        <div class="sidebar-search">
          <div class="input-group">
            <input
            type="input"
              class="form-control search-menu search-placeholder"
              placeholder="Search..."
              [(ngModel)]="search"
              (keyup)="filterByValue()"
              name="searchText"
            />
          </div>
        </div>
      </ul>
    </div>
  </div>
</nav>  -->

<div class="common-content">
  <div class="card-new">
    <div
      class="card"
      style="background: none; background-color: none; border: none"
    >
      <div class="card-body bodyDesgn">
        <!-- <form action="" [formGroup]="taskGroup" (ngSubmit)="onSubmit($event)">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="full-width taskgroup" id="loginform">
                <label class="taskgroupnames" style="font-family: 'Mulish', sans-serif;
                "
                  >Task Group Name<span class="text-danger">*</span></label
                >
                <input
                  formControlName="taskgroupname"
                  placeholder="Enter Form Group Name"
                  class="form-control email-input select-placeholder"
                />
                <mat-error *ngIf="taskGroup.get('taskgroupname').hasError('required')&&submitted ">
                  Task group name is required
                </mat-error>
              </div>
            </div>

            <div class="col-md-5 mdDesign">
              <div class="full-width description">
                <label class="description" style="font-family: 'Mulish', sans-serif; "
                  >Task Group Description<span class="text-danger"
                    >*</span
                  ></label
                >
                <input
                  formControlName="description"
                  placeholder="Enter Description"
                  class="form-control email-input select-placeholder "
                />
                <mat-error *ngIf="taskGroup.get('description').hasError('required') &&submitted">
                  description is required
                </mat-error>
              </div>
            </div>
            <div class="col-md-2">
              <div align="end" mat-dialog-actions >
                <button
                  class="btn create-button "
                  type="submit"
                  style="margin: 15px 0px; margin-right: 61px; margin-top: 35px;" 
                >{{ this.taskId ? "Update" : "Create" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form> -->
        <form
          class="row g-3"
          [formGroup]="taskGroup"
          (ngSubmit)="onSubmit($event)"
        >
          <div class="col-md-4">
            <!-- <label for="staticEmail2" class="visually-hidden"
              >Task Group Name<span class="text-danger"> *</span></label>
            <input
              type="text"
              class="form-control"
              id="inputPassword2"
              placeholder="Task Group Name"
              formControlName="taskgroupname"/>
            <mat-error
              *ngIf="
                taskGroup.get('taskgroupname').hasError('required') && submitted">
              Task group name is required
              <small *ngIf="taskGroup.get('taskgroupname').errors?.pattern" class="text-danger">Please enter a valid project name</small> 
              <small *ngIf="taskGroup.get('taskgroupname').errors?.multipleSpaces" class="text-danger">
                Please do not use multiple consecutive spaces
              </small>
            </mat-error> -->
            <label class="form-control-label"
              >Task Group Name <span class="text-danger"> *</span></label
            >
            <input
              type="text"
              class="form-control email-input select-placeholder"
              id="inputPassword2"
              placeholder="Task Group Name"
              formControlName="taskgroupname"
            />

            <div
              *ngIf="
                taskgroupname.invalid && (taskgroupname.dirty || submitted)
              "
            >
              <small *ngIf="taskgroupname.errors?.pattern" class="text-danger"
                >Please enter valid Task Group Name</small
              >
            </div>
            <div
              *ngIf="
                taskGroup.get('taskgroupname').hasError('required') &&
                (taskGroup.get('taskgroupname').dirty || submitted)
              "
              class="text-danger"
            >
              <small class="text-danger" *ngIf="taskgroupname.errors?.required">
                <span>Task Group Name is required </span>
              </small>
            </div>
            <!-- <div *ngIf="taskGroup.get('taskgroupname').hasError('required') && (taskGroup.get('taskgroupname').dirty   || submitted)">
              <div *ngIf="taskgroupname.errors?.required">
                <small  class="text-danger" *ngIf="showError"
                >Task Group Name is required</small>
              </div>
            </div> -->
          </div>
          <div class="col-md-4">
            <!-- <label for="inputPassword2" class="visually-hidden"
              >Task Group Description<span class="text-danger"> *</span></label
            >
            <input
              type="text"
              class="form-control"
              id="inputPassword2"
              placeholder="Task group description"
              formControlName="description"
            />
            <mat-error
              *ngIf="
                taskGroup.get('description').hasError('required') && submitted
              "
            >
             Task Group description is required
            </mat-error> -->
            <label class="form-control-label"
              >Description <span class="text-danger"> *</span></label
            >
            <input
              type="text"
              class="form-control email-input select-placeholder"
              id="inputPassword2"
              placeholder="Task Group description"
              formControlName="description"
            />
            <!-- <mat-error
            *ngIf="
              expenseCategoryName.get('expenseCategoryName').hasError('required') && submitted
            "
          >
          Category name is required
          </mat-error> -->
            <div *ngIf="description.invalid || description.dirty">
              <small *ngIf="description.errors?.pattern" class="text-danger"
                >Please enter valid Task Group description</small
              >
            </div>
            <div
              *ngIf="
                taskGroup.get('description').hasError('required') &&
                (taskGroup.get('description').dirty || submitted)
              "
              class="text-danger"
            >
              <small class="text-danger" *ngIf="description.errors?.required">
                <span>Task Group Description is required </span>
              </small>
            </div>
          </div>

          <!-- <div class="col-auto">
            <button type="submit" class="btn btn-primary mb-3">Save</button>
            <button type="submit"class="mat-focus-indicator btn search-btn mr-2 mat-raised-button mat-button-base" (click)="formcancel()" >Cancel</button>

          </div> -->
          <!-- <div class="col-md-6 col-lg-4 col-sm-6 mt-3 buttonDesign" >
            <button
              type="submit"
              mat-raised-button [disabled]="isbuttondisabled || !saveButtonDisabled "
              class="searchBtnTime" >
              Save
            </button>
            <button
              style="margin-left: 10px"
              type="reset"
              mat-raised-button

              (click)="clearForm()"
              [disabled]="isbuttondisabled"
              class=" search-btn mr-2" style="      color: black;
              background-color: none;
              border: 1px solid rgba(74, 70, 70, 0.566) !important;
              margin-left: 37px;
              position: relative;
              height: 37px !important;
              right: 20px;
               bottom: 22px;
              "
            >
              Clear
            </button>
          </div> -->

          <div class="col-sm-4">
            <button
            type="submit"
              mat-raised-button
              class="searchBtn1"
              mat-raised-button
              [disabled]="isbuttondisabled || !saveButtonDisabled"
            >
              Save
            </button>

            <button
              type="reset"
              mat-raised-button
              class="btn searchBtn "
              style="
                background: none;
                color: black;
                border: 1px solid #ddd;
                font-size: 16px;
                font-weight: 610;
              "
              (click)="clearForm()"
              [disabled]="isbuttondisabled"
            >
              Clear
            </button>
          </div>
        </form>
        <!-- <div class="card-content" *ngIf="taskgroupList.length > 0">
          <div class="row p-0 m-0" *ngIf="taskgroupList.length > 5">
            <div class="col-md-12 style left">
              <div class="select-pagination">
                <span class="showDesign">Show </span>
                <select
                  class="select_list new-select_list newselect"
                  [(ngModel)]="mySelect"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="changefirstpage()"
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>
        </div> -->

        <!-- *ngIf="taskgroupList.length > 0" -->
        <div class="card-content" *ngIf="tableShow">
          <!-- *ngIf="taskgroupList.length > 5" -->
          <div
            class="row p-0 m-0"
            *ngIf="
              taskgroupList.length > 5 &&
              (taskgroupList | mytask : search).length > 0
            "
          >
            <div
              class="col-md-12"
              *ngIf="(taskgroupList | mytask : search).length > 5"
            >
              <!-- <div class="style-left mt-3"> -->
              <div class="select-pagination">
                <span class="showDesign">Show </span>
                <select
                  class="select_list new-select_list newselect"
                  [(ngModel)]="mySelect"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="changefirstpage()"
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>

        <div class="table-responsive mt-3" *ngIf="tableShow">
          <!-- <p><b>CLIENT WORKING DAYS</b></p> -->
          <!-- *ngIf="taskgroupList.length!=0" -->
          <table
            class="table --table-bordered table-border-new"
            matSort
            (matSortChange)="sortData($event)"
          >
            <thead class="t-head">
              <tr id="trDesign">
                <th style="border-radius: 15px 1px 0px 0">S.No</th>
                <th>Task Group Name</th>
                <th>Task Group Description</th>
                <th>Status</th>
                <th style="border-radius: 0px 15px 0px 0px">Action</th>
              </tr>
            </thead>
            <tbody
              style="
                border-right: 1px solid #ddd;
                border-left: 1px solid #ddd;
                height: 50px;
              "
            >
              <tr
                *ngFor="
                  let taskGroup of datas
                    | mytask : search
                    | paginate : { itemsPerPage: mySelect, currentPage: p };
                  let i = index
                "
              >
                <td style="text-align: center" *ngIf="p">
                  {{ (p - 1) * mySelect + (i + 1) }}
                </td>
                <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
                <!-- <td>{{ i+1 }}</td> -->
                <td>{{ taskGroup.taskGroupName }}</td>
                <td>{{ taskGroup.taskGroupDescription }}</td>
                <td>{{ taskGroup.status.listTypeValueName }}</td>
                <td style="padding-top: 0">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu
                    #menu="matMenu"
                    class="controls-lists"
                    style="
                      padding: 3px 15px 0px 13px !important;
                      margin: 0px !important;
                      line-height: 20px !important;
                      color: #000;
                      height: 35px;
                    "
                  >
                    <button mat-menu-item (click)="update(taskGroup, i)">
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <!-- <button mat-menu-item>
                      <mat-icon>done</mat-icon>
                      <span>Inactive</span>
                    </button>
                    <button mat-menu-item>
                      <mat-icon>done</mat-icon>
                      <span>Active</span>
                    </button> -->
                  </mat-menu>
                </td>
                <!-- <td class="more" *ngIf="taskGroup.timesheet" align="center">
                  <span
                    class="material-icons icon-green reload_green"
                    style="color: green"
                    >check_circle_outline</span
                  >
                </td> -->
              </tr>
              <!-- <tr *ngIf="(taskgroupList | mytask : search).length === 0 && search">
                <td
                  colspan="/* number of columns */"
                  style="
                       text-align: center;
    position: relative;
    left: 352px;
    font-size: 19px;
    font-weight: 600;
                  "
                >
                 Task Group Not Found
                </td>
              </tr> -->

              <td
                style="text-align: center; font-size: larger; font-weight: bold"
                colspan="11"
                *ngIf="
                  ((taskgroupList | mytask : search).length === 0 && search) ||
                  datas.length == 0
                "
              >
                Task Group Not Found!
              </td>
            </tbody>
          </table>
          <!-- <div
            class="controls" 
            *ngIf="
              taskgroupList.length > 5 &&
              (taskgroupList | mytask : search).length > 0
            "
          >
            <pagination-controls
            *ngIf="(taskgroupList | mytask : search).length > 5"
              (pageChange)="p = $event"
              align="end"
              style="    font-family: 'Mulish', sans-serif;
              position: relative;
              float: inline-end;"
            ></pagination-controls>
          </div> -->

          <div
            class="pagination-content"
            *ngIf="
              taskgroupList.length > 5 &&
              (taskgroupList | mytask : search).length > 0
            "
            id="page"
          >
            <pagination-controls
              *ngIf="(taskgroupList | mytask : search).length > 5"
              style="position: relative; float: inline-end"
              (pageChange)="p = $event"
              align="end"
            ></pagination-controls>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-md-4">
              <div class="showing-record"></div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ errorMsg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    view error recodrs
  </button>
</div>
<!-- <div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 2rem; height: 2rem;align-items: center;margin-left:1000px;color: #1A83FF;margin-top:20px;"
    role="status"
  ></div>
</div> -->
