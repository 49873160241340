import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ExpensereiumbursementapproverstatusComponent } from '../../expensereiumbursementapproverstatus/expensereiumbursementapproverstatus.component';
import { ExpenseDetailsFormComponent } from '../../reimbustment/expense-details-form/expense-details-form.component';
import { Router } from '@angular/router';
import { ProjectManagementService } from '../../providers/project-management.service';
import { HeaderService } from '../../providers/header.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
// import { AdvaceApproverComponent } from '../advace-approver/advace-approver.component';
import { ApproverStatusComponent } from '../../leave/approver-status/approver-status.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-advanceapprover',
  templateUrl: './advanceapprover.component.html',
  styleUrls: ['./advanceapprover.component.scss']
})
export class AdvanceapproverComponent implements OnInit {

  approvallist:any[]=[];
  employeedata:any=[];
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  usersId:any;
  paymentData:any;
  paymentName:any;
  employeeId:any;
  loader:any= true;  
  list_loader :boolean= true;
  nodata = false;
  organizationId:any;
  organization:any;
  empId:any;
  approverempId:any;
  success:any=false;
  error:any=false;
  today: any;
  listData:any =[];
search: any;
  beforesearchpage: number;
  storeId: any;
  checkedList: any=[];
  showMenu: boolean=false;
  storeEmpId: any;
  storeGivenTo: any;
  approveDisabled: boolean=true;
setHideaction: boolean=true;
sethidetable: boolean=true;
tableShow: boolean=false;
sucess_msg: any;
error_msg: any;
  // approvallistFiltered: any=[];

  constructor(private router:Router,
    private expenseservice:ProjectManagementService,
    private dialog:MatDialog,
    private headerservice: HeaderService,

    private datepipe:DatePipe) { }
  ngOnInit(): void {
    // this.headerservice.setTitle('Manage Advance Approvals');
    this.headerservice.setTitle('');

    let localdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(localdata);
    this.organizationId = localdata?.organization?.organizationId;
    console.log(this.organizationId);
    this.empId = localdata.employee.employeeId;
    // this.empId=6
    console.log(this.empId)
    let today =  new Date();
    console.log(today);
    this.today =this.datepipe.transform(today, 'yyyy-MM-dd');
  this.list_loader=true
  this.tableShow=false

      let paymentId=6;
    //   this.expenseservice
    //  .getListtypeValuebyId(paymentId) 
    //  .subscribe((paymentdata:any) => {
    //    console.log(paymentdata);
    //   this.listData =paymentdata;
    //   this.tableShow=true;
    //   console.log(this.listData);
    //    this.listData.forEach(element => {
    //      console.log(element);
    //      console.log(element.listTypeValueName);
    //      if(element.listTypeValueName=="Paid")
    //      {
    //        console.log(element.listTypeValueId);
    //        this.paymentData=element.listTypeValueId;
    //        this.paymentName=element.listTypeValueName;
    //        console.log(this.paymentData);
    //        console.log(this.paymentName);
    //      }
    //    });
    //    })
     this.getApprovelist()
   
  }



  getApprovelist(){
    this.list_loader=true
    this.tableShow=false;

      console.log("list called")
    this.expenseservice.getAdvanceList(this.empId).subscribe(
      
      (empData: any) => {
        // this.employeedata =empData;
        console.log(this.employeedata);
        console.log("empData",empData);
        this.tableShow=true;

        this.storeEmpId=this.employeedata?.employee?.employeeId
        console.log( "storeEmpId::",this.storeEmpId);
        this.list_loader=false

        if(this.employeedata.statusCode==204){
          this.nodata=true
          this.list_loader=false
          console.log("entered into the no data");
          
        }
        
      // this.approvallist = empData;
      // let arrayWithoutDuplicates: any[] = [...new Set(empData)];
      this.approvers(empData)
      console.log(this.approvallist);
      // this.storeGivenTo=this.approvallist.advanceGivenTo
      // console.log( "adavance given to:: ",this.storeGivenTo);
      this.approvallist.forEach(item => {
this.storeGivenTo=item.advanceGivenTo
        console.log("this.storeGivenTo",this.storeGivenTo);
    });
    if(this.approvallist.length==0){
      this.nodata=true
      console.log("no data entered");
      
    }
      
      // console.log(this.approvallist.approvals.approvalStatus);
      this.list_loader = false;

        // this.nodata = false;
        // if(empData.length == 0)
        // {
        //   this.list_loader = false;
        //   this.nodata = true;
        //   console.log(this.nodata);
        // }
        if(empData.statusCode == 204){
          this.nodata = true;
          console.log("nodata");
          this.list_loader = false;
        }
        this.employeedata.map(
          y => {
            // console.log(y);
            // console.log(y.approvals);

            // if(y.approvals?.approver?.fyi == this.empId ){
            //   this.nodata = false;
            //   this.setHideaction=false
            //   y['fyistatus'] = true;
            //   this.approvallist.push(y);      
            // }
          //   if (y.fyi && y.approvals.approver.fyi == this.empId) {
          //     this.nodata = false;
          //     y['fyistatus'] = true;
          //     this.approvallist.push(y);      
          // }
          
            // if (y.approvals.approver.approverOne == this.empId && y.approvals.approverOneStatus.listTypeValueId == 3
            //   && y.approvals.approver.approverOne) {
            //   this.nodata = false;
            //   this.approvallist.push(y);     
            // }
          //   if (
          //     y.approvals &&
          //     y.approvals.approver &&
          //     y.approvals.approver.approverOne &&
          //     y.approvals.approver.approverOne == this.empId &&
          //     y.approvals.approverOneStatus &&
          //     y.approvals.approverOneStatus.listTypeValueId == 3
          // ) {
          //     this.nodata = false;
          //     this.approvallist.push(y);     
          // }
          
            // if (y.approvals.approver.approverTwo == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            //   && y.approvals.approverTwoStatus.listTypeValueId == 3) {
            //   this.nodata = false;
            //   this.approvallist.push(y);
            // }
          //   if (
          //     y.approvals &&
          //     y.approvals.approver &&
          //     y.approvals.approver.approverTwo &&
          //     y.approvals.approver.approverTwo == this.empId &&
          //     y.approvals.approverTwoStatus &&
          //     y.approvals.approverTwoStatus.listTypeValueId == 3
          // ) {
          //     this.nodata = false;
          //     this.approvallist.push(y);
          // }
          
            // if (y.approvals.approver.approverThree == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            //   && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 3) {
            //     this.nodata = false;
            //   this.approvallist.push(y);
            //   console.log(this.approvallist);
            // }
          //   if (
          //     y.approvals &&
          //     y.approvals.approver &&
          //     y.approvals.approver.approverThree &&
          //     y.approvals.approver.approverThree == this.empId &&
          //     y.approvals.approverOneStatus &&
          //     y.approvals.approverOneStatus.listTypeValueId == 5 &&
          //     y.approvals.approverTwoStatus &&
          //     y.approvals.approverTwoStatus.listTypeValueId == 5 &&
          //     y.approvals.approverThreeStatus &&
          //     y.approvals.approverThreeStatus.listTypeValueId == 3
          // ) {
          //     this.nodata = false;
          //     this.approvallist.push(y);
          //     console.log(this.approvallist)
          // }
          
            // if (y.approvals.approver.approverFour == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            //   && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
            //   && y.approvals.approverFourStatus.listTypeValueId == 3) {
            //     this.nodata = false;
            //   this.approvallist.push(y);
            // }
          //   if (
          //     y.approvals &&
          //     y.approvals.approver &&
          //     y.approvals.approver.approverFour &&
          //     y.approvals.approver.approverFour == this.empId &&
          //     y.approvals.approverOneStatus &&
          //     y.approvals.approverOneStatus.listTypeValueId == 5 &&
          //     y.approvals.approverTwoStatus &&
          //     y.approvals.approverTwoStatus.listTypeValueId == 5 &&
          //     y.approvals.approverThreeStatus &&
          //     y.approvals.approverThreeStatus.listTypeValueId == 5 &&
          //     y.approvals.approverFourStatus &&
          //     y.approvals.approverFourStatus.listTypeValueId == 3
          // ) {
          //     this.nodata = false;
          //     this.approvallist.push(y);
          // }
          
            // if (y.approvals.approver.approverFive == this.empId && y.approvals.approverOneStatus.listTypeValueId == 5
            //   && y.approvals.approverTwoStatus.listTypeValueId == 5 && y.approvals.approverThreeStatus.listTypeValueId == 5
            //   && y.approvals.approverFourStatus.listTypeValueId == 5 && y.approvals.approverFiveStatus.listTypeValueId == 3) {
            //     this.nodata = false;
            //   this.approvallist.push(y);
            // }

          //   if (
          //     y.approvals &&
          //     y.approvals.approver &&
          //     y.approvals.approver.approverFive &&
          //     y.approvals.approver.approverFive == this.empId &&
          //     y.approvals.approverOneStatus &&
          //     y.approvals.approverOneStatus.listTypeValueId == 5 &&
          //     y.approvals.approverTwoStatus &&
          //     y.approvals.approverTwoStatus.listTypeValueId == 5 &&
          //     y.approvals.approverThreeStatus &&
          //     y.approvals.approverThreeStatus.listTypeValueId == 5 &&
          //     y.approvals.approverFourStatus &&
          //     y.approvals.approverFourStatus.listTypeValueId == 5 &&
          //     y.approvals.approverFiveStatus &&
          //     y.approvals.approverFiveStatus.listTypeValueId == 3
          // ) {
          //     this.nodata = false;
          //     this.approvallist.push(y);
          // }
          
            // this.approvallist = this.approvallist.reduce((accumalator, current) => {
            //   // console.log(accumalator);
            //   // console.log(current);
            //   if (!accumalator.some(item => item === current)) {
            //     accumalator.push(current);
            //   }
            //   return accumalator;
            // }, []);

            if(this.approvallist.length == 0){
              this.nodata = true;
              console.log("NO_DATA");
            }

            console.log("y::",y);
            console.log("this.approvallist::",this.approvallist);
            
            
          }
        )
        // console.log("else");
        // console.log(this.approvallist);
        // this.approvallist.forEach(element => {
        //   console.log("for loop");
        //   var elementobj = {
        //     expenseReimbursementId:element.expenseReimbursementId,
        //     employee:{
        //       employeeName:element.employee.employeeName,
        //       employeeId:element.employee.employeeId,
        //       organizationEmail:element.employee.organizationEmail
        //     },
        //     subTotal:  element.subTotal,
        //     cashAdvance: element.cashAdvance,
        //     totalReimbursement: element.totalReimbursement,
        //     approvals:{
        //       approvalStatus:{
        //         listTypeValueName:element.approvals.approvalStatus.listTypeValueName
        //       }
        //     }    
        //   } 
        //   this.approvallistFiltered.push(elementobj);
        //   console.log(this.approvallistFiltered);
        // });
        this.list_loader = false;

      },
      (error) => {
        this.list_loader = false;
        this.nodata = true;
        console.log("error")
        if(error.status == 404){
          this.nodata = true;
          console.log("404");
        }
      }
    )
  }
  openDialog(expensereburismentId){
    console.log("open")
     this.dialog.open(ExpenseDetailsFormComponent, {
        width: '800px',
        height:'fit-content',
        data:expensereburismentId
    });
  }
  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    approvaldata["type"] = "advance";
    this.dialog.open(ExpensereiumbursementapproverstatusComponent,{
      width: '500px',
      height:'500px',
      data :approvaldata
    })
  }

  changepage(){
    console.log(this.approvallist.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.approvallist.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.approvallist.length);
      console.log("nodata");
    }
  }
  changefirstpage() {
    this.p = 1;
    }

  // approve(i){
  //     console.log(i);
  //     this.list_loader = true;
      
  //     // i['organization']={
  //     //   organizationId :this.organizationId
  //     // }
  //     this.storeId=i.advanceId
  //     // this.expenseservice.getApproverlist(this.storeId).subscribe((data:any)=>{
  //     //   console.log("data",data);

  //     // })
  //     let count = 0
  //     if(i.approvals.approver.approverOne){
  //       count = count+1
  //     }
  //     if(i.approvals.approver.approverTwo){
  //       count = count+1
  //     }
  //     if(i.approvals.approver.approverThree){
  //       count = count+1
  //     }
  //     if(i.approvals.approver.approverFour){
  //       count = count+1
  //     }
  //     if(i.approvals.approver.approverFive){
  //       count = count+1
  //     }
  //     i['status']={
  //       listTypeValueId:1
  //     }
  //      i['employeeId']=this.empId

  //     // i['approverlevel']= 1


     
    
  //     // if (i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3) {
  //     //   i.approvals.approverOneStatus.listTypeValueId = 5
  //     //   i.approvals.approverlevel = 1
  //     //   console.log(i.approvals.approverOneStatus.listTypeValueId);
  //     // }
  //     // if (i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
  //     //   && i.approvals.approverTwoStatus.listTypeValueId == 3) {
  //     //     i.approvals.approverTwoStatus.listTypeValueId = 5
  //     //     i.approvals.approverlevel = 2
  //     // }
  //     // if (i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
  //     //   && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 3) {
  //     //     i.approvals.approverThreeStatus.listTypeValueId = 5
  //     //     i.approvals.approverlevel = 3
  //     // }
  //     // if (i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
  //     //   && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
  //     //   && i.approvals.approverFourStatus.listTypeValueId == 3) {
  //     //     i.approvals.approverFourStatus.listTypeValueId = 5
  //     //     i.approvals.approverlevel = 4
  //     // }
  //     // if (i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5
  //     //   && i.approvals.approverTwoStatus.listTypeValueId == 5 && i.approvals.approverThreeStatus.listTypeValueId == 5
  //     //   && i.approvals.approverFourStatus.listTypeValueId == 5 && i.approvals.approverFiveStatus.listTypeValueId == 3) {
  //     //     i.approvals.approverFiveStatus.listTypeValueId = 5
  //     //     i.approvals.approvalStatus.listTypeValueId = 5
  //     //     i.approvals.approverlevel = 5
  //     // }
  //     if (
  //       i.approvals.approver.approverOne == this.empId &&
  //       i.approvals.approverOneStatus.listTypeValueId == 5
  //     ) {
  //       i.approvals.approverOneStatus.listTypeValueId = 5;
     
  //        i.approvals.approverlevel = 1;
  //     }
  //     if (
  //       i.approvals.approver.approverOne == this.empId &&
  //       i.approvals.approverOneStatus.listTypeValueId == 3
  //     ) {
  //       i.approvals.approverOneStatus.listTypeValueId = 5;
     
  //        i.approvals.approverlevel = 1;
  //     }

   
  //     if (
  //       i.approvals.approver.approverTwo == this.empId &&
  //       i.approvals.approverOneStatus.listTypeValueId == 5 &&
  //       i.approvals.approverTwoStatus.listTypeValueId == 3
  //     ) {
  //       i.approvals.approverTwoStatus.listTypeValueId = 5;
      
  //     i.approvals.approverlevel = 2;
  //     }
  //     if (
  //       i.approvals.approver.approverThree == this.empId &&
  //       i.approvals.approverOneStatus.listTypeValueId == 5 &&
  //       i.approvals.approverTwoStatus.listTypeValueId == 5 &&
  //       i.approvals.approverThreeStatus.listTypeValueId == 3
  //     ) {
  //       i.approvals.approverThreeStatus.listTypeValueId = 5;
    
  //       i.approvals.approverlevel = 3;
  //     }
  //     if (
  //       i.approvals.approver.approverFour == this.empId &&
  //       i.approvals.approverOneStatus.listTypeValueId == 5 &&
  //       i.approvalempId: anys.approverTwoStatus.listTypeValueId == 5 &&
  //       i.approvals.approverThreeStatus.listTypeValueId == 5 &&
  //       i.approvals.approverFourStatus.listTypeValueId == 3
  //     ) {
  //       i.approvals.approverFourStatus.listTypeValueId = 5;
          
  //      i.approvals.approverlevel = 4;
  //     }
  //     if (
  //       i.approvals.approver.approverFive == this.empId &&
  //       i.approvals.approverOneStatus.listTypeValueId == 5 &&
  //       i.approvals.approverTwoStatus.listTypeValueId == 5 &&
  //       i.approvals.approverThreeStatus.listTypeValueId == 5 &&
  //       i.approvals.approverFourStatus.listTypeValueId == 5 &&
  //       i.approvals.approverFiveStatus.listTypeValueId == 3
  //     ) {
  //       i.approvals.approverFiveStatus.listTypeValueId = 5
  //       i.approvals.approvalStatus.listTypeValueId = 5;
  //     i.approvals.approverlevel = 5;

      

  //     }
  //     console.log("count",count);
  //     let totalapprovercount=count;
  //     console.log(totalapprovercount);
        
            
  //           if(count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5){
  //             i.approvals.approvalStatus.listTypeValueId = 5
  //             if(totalapprovercount==count){
  //               i.approvedOn=this.today;
  //               console.log(i.approvedOn);
  //               i.paymentStatus=
  //               {
  //                 listTypeValueId:this.paymentData,
  //                 listTypeValueName: this.paymentName
  //               }
  //               console.log(i.paymentStatus.listTypeValueId);
  //               console.log(i.paymentStatus.listTypeValueName);
  //               console.log(i);
  //             }
  //           }
  //           if(count == 2 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5){
  //             i.approvals.approvalStatus.listTypeValueId = 5
  //             if(totalapprovercount==count){
  //               i.approvedOn=this.today;
  //               console.log(i.approvedOn);
  //               i.paymentStatus=
  //               {
  //                 listTypeValueId:this.paymentData,
  //                 listTypeValueName: this.paymentName
  //               }
  //               console.log(i.paymentStatus.listTypeValueId);
  //               console.log(i.paymentStatus.listTypeValueName);
  //               console.log(i);
                 
  //             }
  //           }
  //           if(count == 3 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
  //             && i.approvals.approverThreeStatus.listTypeValueId == 5){
  //             i.approvals.approvalStatus.listTypeValueId = 5
  //             if(totalapprovercount==count){
  //               i.approvedOn=this.today;
  //               console.log(i.approvedOn);
  //               i.paymentStatus=
  //               {
  //                 listTypeValueId:this.paymentData,
  //                 listTypeValueName: this.paymentName
  //               }
  //               console.log(i.paymentStatus.listTypeValueId);
  //               console.log(i.paymentStatus.listTypeValueName);
  //               console.log(i);
  //             }
  //           }
  //           if(count == 4 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
  //             && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5){
  //             i.approvals.approvalStatus.listTypeValueId = 5
  //             if(totalapprovercount==count){
  //               i.approvedOn=this.today;
  //               console.log(i.approvedOn);
  //               i.paymentStatus=
  //               {
  //                 listTypeValueId:this.paymentData,
  //                 listTypeValueName: this.paymentName
  //               }
  //               console.log(i.paymentStatus.listTypeValueId);
  //               console.log(i.paymentStatus.listTypeValueName);
  //               console.log(i);
  //             }
  //           }
  //           if(count == 5 && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5
  //             && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5 
  //             && i.approvals.approverFiveStatus.listTypeValueId == 5){
  //             i.approvals.approvalStatus.listTypeValueId = 5
  //             if(totalapprovercount==count){
  //             i.approvedOn=this.today;
  //             console.log(i.approvedOn);
  //             // i.paymentStatus=
  //             //   {
  //             //     listTypeValueId:this.paymentData,
  //             //     listTypeValueName: this.paymentName
  //             //   }
  //             //   console.log(i.paymentStatus.listTypeValueId);
  //             //   console.log(i.paymentStatus.listTypeValueName);
  //               console.log(i);

  //           }
  //           } 
  //     console.log(i);  
  //     this.expenseservice
  //   .advanceApproval(i.advanceId,i)
  //   .subscribe((data:any) =>
  //   {
  //     this.list_loader = false;
  //     this.success = true;
  //     setTimeout(() => {
  //     //  return;
  //     this.success = false;

  //        window.location.reload();
  //     }, 2000)
 
  //   },
  //   (error) => {
  //     this.list_loader = false;
  //     this.error = true;
  //     console.log("error")
  //     setTimeout(() => {
  //       this.error = false;
  //     }, 2000)
  //   }
  //   ) 
  // }

  // deny(i)
  // {
  //   console.log(i);
  //     this.list_loader = true;
  //     i['organization']={
  //       organizationId :this.organizationId
  //     }

  //     if(i.approvals.approver.approverOne == this.empId && i.approvals.approverOneStatus.listTypeValueId == 3){
  //       i.approvals.approverOneStatus.listTypeValueId = 6
  //       console.log("approverone status");
  //       console.log(i.approvals.approverOneStatus.listTypeValueId);
  //       i.approvals.approvalStatus.listTypeValueId = 6
  //       i.approvals.approverlevel = 1
  //     }
  //     if(i.approvals.approver.approverTwo == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5){
  //       i.approvals.approverTwoStatus.listTypeValueId = 6
  //       console.log("approverTwoStatus");
  //       console.log(i.approvals.approverTwoStatus.listTypeValueId);
  //       i.approvals.approvalStatus.listTypeValueId = 6
  //       i.approvals.approverlevel = 2
  //     }
  //     if(i.approvals.approver.approverThree == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5){
  //       i.approvals.approverThreeStatus.listTypeValueId = 6
  //       console.log("approverThreeStatus");
  //       console.log(i.approvals.approverThreeStatus.listTypeValueId);
  //       i.approvals.approvalStatus.listTypeValueId = 6
  //       i.approvals.approverlevel = 3
  //     }
  //     if(i.approvals.approver.approverFour == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5 
  //      && i.approvals.approverThreeStatus.listTypeValueId == 5){
  //       i.approvals.approverFourStatus.listTypeValueId = 6
  //       console.log("approverFourStatus");
  //       console.log(i.approvals.approverFourStatus.listTypeValueId);
  //       i.approvals.approvalStatus.listTypeValueId = 6
  //       i.approvals.approverlevel = 4
  //     }
  //     if(i.approvals.approver.approverFive == this.empId && i.approvals.approverOneStatus.listTypeValueId == 5 && i.approvals.approverTwoStatus.listTypeValueId == 5 
  //       && i.approvals.approverThreeStatus.listTypeValueId == 5 && i.approvals.approverFourStatus.listTypeValueId == 5){
  //         i.approvals.approverFiveStatus.listTypeValueId = 6
  //         console.log("approverFiveStatus");
  //         console.log(i.approvals.approverFiveStatus.listTypeValueId);
  //         i.approvals.approvalStatus.listTypeValueId = 6
  //         i.approvals.approverlevel = 5
  //     }
  //     console.log(i);
  //     this.expenseservice
  //   .advanceApproval(i.advanceId,i)
  //   .subscribe((data:any) =>
  //   {
  //     this.list_loader = false;
  //     this.success = true;
      
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 2000)
       
  //   },
  //   (error) => {
  //     this.list_loader = false;
  //     this.error = true;
  //     console.log("error");
  //     setTimeout(() => {
  //       this.error = false;
  //     }, 2000)
  //   }
  //   ) 
  // }
  
  
  // onKeyUpEvent(event)
  // {
  //   if(this.query.length !=0)
  //   {
  //     this.p=1;
  //   }
  //   else
  //   {
  //     this.p = this.beforepage;
  //   }
  // }
//   approve(i){
//     console.log(i);
    
//     let approverlevel;
//     Swal.fire({
//       title: 'Are you sure? Do you want to approve',

//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, Approve!',
//     }).then((result) => {
//       console.log(' this.empId', this.empId);

//       if (result.value) {
//         i['organization'] = {
//           organizationId: this.organizationId,
//         };

//         let count = 0;
//         if (i.approvals.approver.approverOne) {
//           count = count + 1;
//         }
//         if (i.approvals.approver.approverTwo) {
//           count = count + 1;
//         }
//         if (i.approvals.approver.approverThree) {
//           count = count + 1;
//         }
//         if (i.approvals.approver.approverFour) {
//           count = count + 1;
//         }
//         if (i.approvals.approver.approverFive) {
//           count = count + 1;
//         }
//         i['advanceGivenTo']=this.empId

//         //  i['approvals']={
//         // // i['employeeId']=this.empId

//         //   }
//         // }
       
//         i['employeeId']=this.empId

//         // delete i.approvals.approvalStatus;

//         if (
//           i.approvals.approver.approverOne == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5
//         ) {
//           i.approvals.approverOneStatus.listTypeValueId = 5;

//           i.approvals.approverlevel = 1;
//         }
//         if (
//           i.approvals.approver.approverOne == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 3
//         ) {
//           i.approvals.approverOneStatus.listTypeValueId = 5;

//           i.approvals.approverlevel = 1;
//         }

//         if (
//           i.approvals.approver.approverTwo == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 3
//         ) {
//           i.approvals.approverTwoStatus.listTypeValueId = 5;

//           i.approvals.approverlevel = 2;
//         }
//         if (
//           i.approvals.approver.approverThree == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 3
//         ) {
//           i.approvals.approverThreeStatus.listTypeValueId = 5;

//           i.approvals.approverlevel = 3;
//         }
//         if (
//           i.approvals.approver.approverFour == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//           i.approvals.approverFourStatus.listTypeValueId == 3
//         ) {
//           i.approvals.approverFourStatus.listTypeValueId = 5;

//           i.approvals.approverlevel = 4;
//         }
//         if (
//           i.approvals.approver.approverFive == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//           i.approvals.approverFourStatus.listTypeValueId == 5 &&
//           i.approvals.approverFiveStatus.listTypeValueId == 3
//         ) {
//           i.approvals.approverFiveStatus.listTypeValueId = 5;
//           i.approvals.approvalStatus.listTypeValueId = 5;
//           i.approvals.approverlevel = 5;
//         }

//         // if (count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5) {
//         //   i.approvals.approvalStatus.listTypeValueId = 5;

//         //   console.log('approvalstatus1', i.approvals.approvalStatus);

//         // }
//         // if (
//         //   count == 2 &&
//         //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverTwoStatus.listTypeValueId == 5
//         // ) {
//         //   i.approvals.approvalStatus.listTypeValueId = 5;
//         //   console.log('approvalstatus2', i.approvals.approvalStatus);
//         // }
//         // if (
//         //   count == 3 &&
//         //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverThreeStatus.listTypeValueId == 5
//         // ) {
//         //   i.approvals.approvalStatus.listTypeValueId = 5;
//         //   console.log('approvalstatus3', i.approvals.approvalStatus);
//         // }
//         // if (
//         //   count == 4 &&
//         //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverFourStatus.listTypeValueId == 5
//         // ) {
//         //   i.approvals.approvalStatus.listTypeValueId = 5;
//         //   console.log('approvalstatus4', i.approvals.approvalStatus);
//         // }
//         // if (
//         //   count == 5 &&
//         //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverFourStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverFiveStatus.listTypeValueId == 3
//         // ) {
//         //   i.approvals.approverFiveStatus.listTypeValueId == 5
//         //   i.approvals.approvalStatus.listTypeValueId = 5;
//         //   console.log('approvalstatus5', i.approvals.approvalStatus);
//         // }

//         if (count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5) {
//           i.approvals.approvalStatus.listTypeValueId = 5;
//         }
//         if (
//           count == 2 &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5
//         ) {
//           i.withdrawStatus = 5;
//           i.approvals.approvalStatus.listTypeValueId = 5;
//         }
//         if (
//           count == 3 &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 5
//         ) {
//           i.withdrawStatus = 5;
//           i.approvals.approvalStatus.listTypeValueId = 5;
//         }
//         if (
//           count == 4 &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//           i.approvals.approverFourStatus.listTypeValueId == 5
//         ) {
//           i.withdrawStatus = 5;
//           i.approvals.approvalStatus.listTypeValueId = 5;
//         }
//         if (
//           count == 5 &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//           i.approvals.approverFourStatus.listTypeValueId == 5 &&
//           i.approvals.approverFiveStatus.listTypeValueId == 5
//         ) {
//           i.withdrawStatus = 5;
//           i.approvals.approvalStatus.listTypeValueId = 5;
//         }

//         // if (
//         //   count == 5 &&
//         //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverFourStatus.listTypeValueId == 5 &&
//         //   i.approvals.approverFiveStatus.listTypeValueId == 5
//         // ) {
//         //   i.approvals.approvalStatus.listTypeValueId = 5;
//         //   console.log('approvalstatus5', i.approvals.approvalStatus);
//         // }

//         // i.approvals.employee.employeeId = i.employee.employeeId;
//         // i.approvals.projectId = i.project.projectId;
//         // i.projectId = i.project.projectId;
//         i.approvalStatus = i.approvals.approvalStatus.listTypeValueId;

//         console.log('approval status', i.approvalStatus);

//         // i.approvals.approverlevel =   i.approvals.approverlevel;
//         // i['approvals'] = {
//         //   // employeeId:2,
//         //   // project:2,
//         //   // approverlevel:2
//         // }

//         // i.approvals.employeeId = i.employee.employeeId;
//         // i.approvals.project = i.project.projectId;
//         // i.approvals.approverlevel = approverlevel;

//         console.log('i', i);
//         this.checkedList.push(i);
//         console.log(this.checkedList);
//         this.expenseservice
//           .advanceApproval(i.advanceId,i)
//           .subscribe((data:any) =>
//           {
//             this.list_loader = false;
//             this.success = true;
//             setTimeout(() => {
//             //  return;
//             this.success = false;
      
//                window.location.reload();
//             }, 2000)
       
//           },
//           (error) => {
//             this.list_loader = false;
//             this.error = true;
//             console.log("error")
//             setTimeout(() => {
//               this.error = false;
//             }, 2000)
//           }
//           ) 

//         // this.expenseservice.updateBulkApprovalStatus(this.checkedList).subscribe((a) => {
//         //   Swal.fire({
//         //     icon: 'success',
//         //     text: 'Approved',
//         //   });
//         //   // window.location.reload();
//         // });
//       }
//     });
//   }
//   deny(i){
//     console.log('i', i);

//     console.log('this.emplist', this.approvallist);

//     let x = JSON.parse(localStorage.getItem('enoteUserData'));
//     let emp = x.employeeId;
//     Swal.fire({
//       title: 'Are you sure? Do you want to deny',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, Deny!',
//     }).then((result) => {
//       console.log('result', result);

//       if (result.value) {
//         i['organization'] = {
//           organizationId: this.organizationId,
//         };

//         console.log(
//           'i.approvals.approver.approverOne == this.empId',
//           i.approvals.approver.approverOne,
//           this.empId
//         );
//         console.log(
//           'i.approvals.approverOneStatus.listTypeValueId == 3',
//           i.approvals.approverOneStatus.listTypeValueId
//         );
//         if (
//           i.approvals.approver.approverOne == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 3
//         ) {
//           // i.approvals.approver = 6;
//           // i.approvals.approver = 6;
//           // i.approvals.approver={
//           //   approverId: 1
//           // } ;
//           // "approver": {
//           //   "approverId": 172
//           // },

//           i.approvals.approverOneStatus.listTypeValueId = 6;
//           i.approvals.approvalStatus.listTypeValueId = 6;
//           i.approvals.approverlevel = 1;
//           console.log('i.approvals.approver = 6', i.approvals.approver);
//         }
//         if (
//           i.approvals.approver.approverTwo == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5
//         ) {
//           i.approvals.approverTwoStatus.listTypeValueId = 6;
//           i.approvals.approvalStatus.listTypeValueId = 6;
//           i.approvals.approverlevel = 2;
//           console.log(
//             '  i.approvals.approverTwoStatus.listTypeValueId',
//             i.approvals.approverTwoStatus.listTypeValueId
//           );
//           console.log(
//             '   i.approvals.approvalStatus.listTypeValueId',
//             i.approvals.approvalStatus.listTypeValueId
//           );
//           console.log('i.approvals.approverlevel', i.approvals.approverlevel);
//         }
//         console.log(
//           '  i.approvals.approver.approverThree',
//           i.approvals.approver.approverThree,
//           this.empId
//         );
//         console.log(
//           '     i.approvals.approverOneStatus.listTypeValueId  5',
//           i.approvals.approverOneStatus.listTypeValueId
//         );
//         console.log(
//           '  i.approvals.approverTwoStatus.listTypeValueId 5',
//           i.approvals.approverTwoStatus.listTypeValueId
//         );

//         if (
//           i.approvals.approver.approverThree == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5
//         ) {
//           i.approvals.approverThreeStatus.listTypeValueId = 6;
//           i.approvals.approvalStatus.listTypeValueId = 6;
//           i.approvals.approverlevel = 3;
//         }
//         if (
//           i.approvals.approver.approverFour == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 5
//         ) {
//           i.approvals.approverFourStatus.listTypeValueId = 6;
//           i.approvals.approvalStatus.listTypeValueId = 6;
//           i.approvals.approverlevel = 4;
//         }

//         if (
//           i.approvals.approver.approverFive == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 3 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 3 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 3 &&
//           i.approvals.approverFourStatus.listTypeValueId == 3
//         ) {
//           i.approvals.approverFiveStatus.listTypeValueId = 6;
//           i.approvals.approvalStatus.listTypeValueId = 6;
//           i.approvals.approverlevel = 5;
//         }

//         if (
//           i.approvals.approver.approverFive == this.empId &&
//           i.approvals.approverOneStatus.listTypeValueId == 5 &&
//           i.approvals.approverTwoStatus.listTypeValueId == 5 &&
//           i.approvals.approverThreeStatus.listTypeValueId == 5 &&
//           i.approvals.approverFourStatus.listTypeValueId == 5
//         ) {
//           i.approvals.approverFiveStatus.listTypeValueId = 6;
//           i.approvals.approvalStatus.listTypeValueId = 6;
//           i.approvals.approverlevel = 5;
//         }

//         i.approvals.employeeId = i.employee.employeeId;
//         i.approvals.projectId = i.project.projectId;
//         i.projectId = i.project.projectId;
//         i.approvalStatus = i.approvals.approvalStatus.listTypeValueId;

//         console.log('i', i);

//         this.checkedList.push(i);
//         console.log(this.checkedList);
//         this.expenseservice
//           .advanceApproval(i.advanceId,i)
//           .subscribe((data:any) =>
//           {
//             this.list_loader = false;
//             this.success = true;
            
//             setTimeout(() => {
//               window.location.reload();
//             }, 2000)
             
//           },
//           (error) => {
//             this.list_loader = false;
//             this.error = true;
//             console.log("error");
//             setTimeout(() => {
//               this.error = false;
//             }, 2000)
//           }
//           ) 
//         // this.gs.updateBulkApprovalStatus(this.checkedList).subscribe((a) => {
//         //   Swal.fire({
//         //     icon: 'success',
//         //     text: 'Denied',
//         //   });
//         //   // window.location.reload();
//         // });
//       }
//     });
//   }
//   approvers(y) {

//     console.log('y',y);
    
//     // console.log('empDatannnnn',empData);

//     this.approvallist.length = 0;
//     // empData.map((y) => {
//       // console.log('y',y);
//       // for (let i = 0; i < empData.length; i++) {
//         // console.log('y.approval.approver.fyi',y.approvals.approver.approverOne.fyi);
//         // if (y.approval.approver.fyi == this.empId) {
//         // this.approvallist.push(y);

//         // console.log('this.EmpListttttttttttttt',this.approvallist);

//         // }

//         // if (
//         //   y.approvals.approver.approverOne == this.empId &&
//         //   y.approvals.approver.approverOne.approverOneStatus == 3 &&
//         //   y.approver.approverOne
//         // ) {
//         //   this.approvallist.push(y);
//         // }
//         // if (
//         //   y.approvals.approver.approverOne.approverTwo == this.empId &&
//         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverTwoStatus == 3
//         // ) {
//         //   this.approvallist.push(y);
//         // }
//         // if (
//         //   y.approvals.approver.approverOne.approverThree == this.empId &&
//         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverThreeStatus == 3
//         // ) {
//         //   this.approvallist.push(y);
//         // }
//         // if (
//         //   y.approvals.approver.approverOne.approverFour == this.empId &&
//         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverThreeStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverFourStatus == 3
//         // ) {
//         //   this.approvallist.push(y);
//         //   console.log(this.approvallist);
//         //   console.log('approver Four data');
//         // }
//         // if (
//         //   y.approvals.approver.approverOne.approverFive == this.empId &&
//         //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverThreeStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverFourStatus == 5 &&
//         //   y.approvals.approver.approverOne.approverFiveStatus == 3
//         // ) {
//         //   this.approvallist.push(y);
//         // }

// // console.log(' y.approvals.approver.approverOne', y.approvals.approver.approverOne,this.empId,  y.approvals.approverOneStatus.listTypeValueId );


// for (let i = 0; i < y.length; i++) {

//   console.log('cjeck u',y[i]);
  

//         if (
//           y[i].approvals?.approver?.approverOne == this.empId &&
//           y[i].approvals?.approverOneStatus?.listTypeValueId == 5
//         ) {

         
//           // y[i].approvals.approverOneStatus.listTypeValueId = 5;

//           // y[i].approvals.approverlevel = 1;
// this.approvallist.pop();
// this.approvallist.push(y[i]);

//           // this.approvallist.push(y[i]);
//         }
//         if (
//           y[i]?.approvals?.approver?.approverOne == this.empId &&
//           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 3
//         ) {


//             console.log('y.approvals.approver.approverOne' ,3);
//             console.log(' y.approvals.approver.approverOne',  y[i].approvals.approver.approverOne,this.empId,   y[i].approvals.approverOneStatus.listTypeValueId );

//             this.approvallist.pop();
       
//           this.approvallist.push(y[i]);
//           console.log('this.approvallist',this.approvallist);
          
//           // y[i].approvals.approverOneStatus.listTypeValueId = 5;

//           // y[i].approvals.approverlevel = 1;
//           // this.approvallist =  y;
//         }

//         if (
//           y[i]?.approvals?.approver?.approverTwo == this.empId &&
//           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 3
//         ) {

//           this.approvallist.pop();
//           this.approvallist.push(y[i])
//           // y[i].approvals.approverTwoStatus.listTypeValueId = 5;

//           // y[i].approvals.approverlevel = 2;
//           // this.approvallist.push(y);
//         }
//         if (
//           y[i]?.approvals?.approver?.approverThree == this.empId &&
//           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 3
//         ) {

//           this.approvallist.pop();
//           this.approvallist.push(y[i]);
//           // y[i].approvals.approverThreeStatus.listTypeValueId = 5;

//           // y[i].approvals.approverlevel = 3;
//           // this.approvallist.push(y);
//         }
//         if (
//           y[i]?.approvals?.approver?.approverFour == this.empId &&
//           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverFourStatus?.listTypeValueId == 3
//         ) {
//           this.approvallist.pop();
//           this.approvallist.push(y[i]);
//           // y[i].approvals.approverFourStatus.listTypeValueId = 5;

//           // y[i].approvals.approverlevel = 4;
//           // this.approvallist.push(y);
//         }
//         if (
//           y[i]?.approvals?.approver?.approverFive == this.empId &&
//           y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverFourStatus?.listTypeValueId == 5 &&
//           y[i]?.approvals?.approverFiveStatus?.listTypeValueId == 3
//         ) {

//           this.approvallist.pop();
//           this.approvallist.push(y[i]);
//           // y[i].approvals.approverFiveStatus.listTypeValueId = 5;
//           // y[i].approvals.approvalStatus.listTypeValueId = 5;
//           // y[i].approvals.approverlevel = 5;
//           // this.approvallist.push(y);
//         }
//       }
//         if (Array.isArray(this.approvallist)) {
//         this.approvallist = this.approvallist.reduce((accumalator, current) => {
//           if (!accumalator.some((item) => item === current)) {
//             accumalator.push(current);
//           }
//           return accumalator;
//         }, []);
//       }
//       // }
//     // });

//     if (this.approvallist.length == 0) {
//       this.nodata = true;
//     }

//     console.log('timesheet' + this.approvallist.length);
approve(i){
  // this.approvallist.length=0
  this.p=1
  this.search=''
  console.log('ff',i);
  
  let approverlevel;
  Swal.fire({
    title: 'Are you sure? Do you want to approve',

    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Approve!',
  }).then((result) => {
    console.log(' this.empId', this.empId);

    if (result.value) {
      i['organizationId'] =this.organizationId;
      

      let count = 0;
      if (i.approvals.approver.approverOne) {
        count = count + 1;
      }
      if (i.approvals.approver.approverTwo) {
        count = count + 1;
      }
      if (i.approvals.approver.approverThree) {
        count = count + 1;
      }
      if (i.approvals.approver.approverFour) {
        count = count + 1;
      }
      if (i.approvals.approver.approverFive) {
        count = count + 1;
      }

      // i['employeeId']=this.empId

      // delete i.approvals.approvalStatus;

      if (
        i.approvals.approver.approverOne == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5
      ) {
        console.log("first approver");
        
        i.approvals.approverOneStatus.listTypeValueId = 5;

        i.approvals.approverlevel = 1;
      }
      if (
        i.approvals.approver.approverOne == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 3
      ) {
        console.log("2ndappeover");
        
        i.approvals.approverOneStatus.listTypeValueId = 5;

        i.approvals.approverlevel = 1;
      }

      if (
        i.approvals.approver.approverTwo == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 3
      ) {
        console.log("2rd approver");
        
        i.approvals.approverTwoStatus.listTypeValueId = 5;

        i.approvals.approverlevel = 2;
      }
      if (
        i.approvals.approver.approverThree == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 3
      ) {
        console.log("3th approver");
        
        i.approvals.approverThreeStatus.listTypeValueId = 5;

        i.approvals.approverlevel = 3;
      }
      if (
        i.approvals.approver.approverFour == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 5 &&
        i.approvals.approverFourStatus.listTypeValueId == 3
      ) {
console.log("4th approver");

        i.approvals.approverFourStatus.listTypeValueId = 5;

        i.approvals.approverlevel = 4;
      }
      if (
        i.approvals.approver.approverFive == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 5 &&
        i.approvals.approverFourStatus.listTypeValueId == 5 &&
        i.approvals.approverFiveStatus.listTypeValueId == 3
      ) {
        console.log("5th");
        
        i.approvals.approverFiveStatus.listTypeValueId = 5;
        i.approvals.approvalStatus.listTypeValueId = 5;
        i.approvals.approverlevel = 5;
      }

      // if (count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5) {
      //   i.approvals.approvalStatus.listTypeValueId = 5;

      //   console.log('approvalstatus1', i.approvals.approvalStatus);

      // }
      // if (
      //   count == 2 &&
      //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
      //   i.approvals.approverTwoStatus.listTypeValueId == 5
      // ) {
      //   i.approvals.approvalStatus.listTypeValueId = 5;
      //   console.log('approvalstatus2', i.approvals.approvalStatus);
      // }
      // if (
      //   count == 3 &&
      //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
      //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
      //   i.approvals.approverThreeStatus.listTypeValueId == 5
      // ) {
      //   i.approvals.approvalStatus.listTypeValueId = 5;
      //   console.log('approvalstatus3', i.approvals.approvalStatus);
      // }
      // if (
      //   count == 4 &&
      //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
      //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
      //   i.approvals.approverThreeStatus.listTypeValueId == 5 &&
      //   i.approvals.approverFourStatus.listTypeValueId == 5
      // ) {
      //   i.approvals.approvalStatus.listTypeValueId = 5;
      //   console.log('approvalstatus4', i.approvals.approvalStatus);
      // }
      // if (
      //   count == 5 &&
      //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
      //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
      //   i.approvals.approverThreeStatus.listTypeValueId == 5 &&
      //   i.approvals.approverFourStatus.listTypeValueId == 5 &&
      //   i.approvals.approverFiveStatus.listTypeValueId == 3
      // ) {
      //   i.approvals.approverFiveStatus.listTypeValueId == 5
      //   i.approvals.approvalStatus.listTypeValueId = 5;
      //   console.log('approvalstatus5', i.approvals.approvalStatus);
      // }

      if (count == 1 && i.approvals.approverOneStatus.listTypeValueId == 5) {
        i.approvals.approvalStatus.listTypeValueId = 5;
      }
      if (
        count == 2 &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5
      ) {
        i.withdrawStatus = 5;
        i.approvals.approvalStatus.listTypeValueId = 5;
      }
      if (
        count == 3 &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 5
      ) {
        // i.withdrawStatus = 5;
        i.approvals.approvalStatus.listTypeValueId = 5;
      }
      if (
        count == 4 &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 5 &&
        i.approvals.approverFourStatus.listTypeValueId == 5
      ) {
        // i.withdrawStatus = 5;
        i.approvals.approvalStatus.listTypeValueId = 5;
      }
      if (
        count == 5 &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 5 &&
        i.approvals.approverFourStatus.listTypeValueId == 5 &&
        i.approvals.approverFiveStatus.listTypeValueId == 5
      ) {
        // i.withdrawSt**************atus = 5;
        i.approvals.approvalStatus.listTypeValueId = 5;
      }

      // if (
      //   count == 5 &&
      //   i.approvals.approverOneStatus.listTypeValueId == 5 &&
      //   i.approvals.approverTwoStatus.listTypeValueId == 5 &&
      //   i.approvals.approverThreeStatus.listTypeValueId == 5 &&
      //   i.approvals.approverFourStatus.listTypeValueId == 5 &&
      //   i.approvals.approverFiveStatus.listTypeValueId == 5
      // ) {
      //   i.approvals.approvalStatus.listTypeValueId = 5;
      //   console.log('approvalstatus5', i.approvals.approvalStatus);
      // }

      console.log("this.storeGivenTo",this.storeGivenTo);
      i.approvals.employeeId = i.approvals.employeeId;
    
      // i.advanceGivenTo=this.storeEmpId
      i.advanceGivenTo= i.advanceGivenTo;

      // i.approvals.projectId = i.project.projectId;
      // i.projuectId = i.project.projectId;
      i.approvalStatus = i.approvals.approvalStatus.listTypeValueId;

      console.log('approval status', i.approvalStatus);
      console.log( " i.advanceGivenTo::",i.advanceGivenTo);
      

      // i.approvals.approverlevel =   i.approvals.approverlevel;
      // i['approvals'] = {
      //   // employeeId:2,
      //   // project:2,
      //   // approverlevel:2
      // }

      // i.approvals.employeeId = i.employee.employeeId;
      // i.approvals.project = i.project.projectId;
      // i.approvals.approverlevel = approverlevel;

      console.log('i', i);
      this.checkedList.push(i);
      console.log(this.checkedList);
      this.approveDisabled =  false;

      // this.expenseservice.updateBulkApprovalStatus(this.checkedList).subscribe((a) => {
      //   Swal.fire({
      //     icon: 'success',
      //     text: 'Approved',
      //   });
      //   // window.location.reload();
      // });
      // this.expenseservice
      //   .expenseReimbursementApproval(i.expenseReimbursementId,i)
      //   .subscribe((data:any) =>
      //   {
      //     this.list_loader = false;
      //     this.success = true;
      //     setTimeout(() => {
      //     //  return;
      //     this.success = false;
    
      //        window.location.reload();
      //     }, 2000)
     
      //   },
      //   (error) => {
      //     this.list_loader = false;
      //     this.error = true;
      //     console.log("error")
      //     setTimeout(() => {
      //       this.error = false;
      //     }, 2000)
      //   }
      //   ) 
      // return
      this.list_loader = true;
      this.expenseservice
                .advanceApproval(i.advanceId,i)
                .subscribe((data:any) =>
                {
                  console.log("data::",data);
                  
                  this.list_loader = false;
                  this.success = true;
                  this.loader = false;
                  this.sucess_msg=data.message
                  // this.sethidetable=false
                  
             
                  setTimeout(() => {
                    this.success = false;
                    this.sucess_msg=false
                      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(currentUrl);
        this.getApprovelist()
    });
  
                    
}, 5000)
                

                },
                (error) => {
                  this.list_loader = false;
                  this.error = true;
                  this.error_msg=error.message
                  console.log("error");
                  setTimeout(() => {
                    this.error = false;
                    this.error_msg=false
                  }, 2000)
                }
                ) 
    }
  });


}
deny(i){
  this.p=1
  this.search=''
  console.log('i', i);

  console.log('this.approvallist', this.approvallist);

  let x = JSON.parse(localStorage.getItem('enoteUserData'));
  let emp = x.employeeId;
  Swal.fire({
    title: 'Are you sure? Do you want to deny',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Deny!',
  }).then((result) => {
    console.log('result', result);

    if (result.value) {
      i['organizationId'] =  this.organizationId,
      

      console.log(
        'i.approvals.approver.approverOne == this.empId',
        i.approvals.approver.approverOne,
        this.empId
      );
      console.log(
        'i.approvals.approverOneStatus.listTypeValueId == 3',
        i.approvals.approverOneStatus.listTypeValueId
      );
      if (
        i.approvals.approver.approverOne == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 3
      ) {
        // i.approvals.approver = 6;
        // i.approvals.approver = 6;
        // i.approvals.approver={
        //   approverId: 1
        // } ;
        // "approver": {
        //   "approverId": 172
        // },

        i.approvals.approverOneStatus.listTypeValueId = 6;
        i.approvals.approvalStatus.listTypeValueId = 6;
        i.approvals.approverlevel = 1;
        console.log('i.approvals.approver = 6', i.approvals.approver);
      }
      if (
        i.approvals.approver.approverTwo == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5
      ) {
        i.approvals.approverTwoStatus.listTypeValueId = 6;
        i.approvals.approvalStatus.listTypeValueId = 6;
        i.approvals.approverlevel = 2;
        console.log(
          '  i.approvals.approverTwoStatus.listTypeValueId',
          i.approvals.approverTwoStatus.listTypeValueId
        );
        console.log(
          '   i.approvals.approvalStatus.listTypeValueId',
          i.approvals.approvalStatus.listTypeValueId
        );
        console.log('i.approvals.approverlevel', i.approvals.approverlevel);
      }
      console.log(
        '  i.approvals.approver.approverThree',
        i.approvals.approver.approverThree,
        this.empId
      );
      console.log(
        '     i.approvals.approverOneStatus.listTypeValueId  5',
        i.approvals.approverOneStatus.listTypeValueId
      );
      console.log(
        '  i.approvals.approverTwoStatus.listTypeValueId 5',
        i.approvals.approverTwoStatus.listTypeValueId
      );

      if (
        i.approvals.approver.approverThree == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5
      ) {
        i.approvals.approverThreeStatus.listTypeValueId = 6;
        i.approvals.approvalStatus.listTypeValueId = 6;
        i.approvals.approverlevel = 3;
      }
      if (
        i.approvals.approver.approverFour == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 5
      ) {
        i.approvals.approverFourStatus.listTypeValueId = 6;
        i.approvals.approvalStatus.listTypeValueId = 6;
        i.approvals.approverlevel = 4;
      }

      if (
        i.approvals.approver.approverFive == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 3 &&
        i.approvals.approverTwoStatus.listTypeValueId == 3 &&
        i.approvals.approverThreeStatus.listTypeValueId == 3 &&
        i.approvals.approverFourStatus.listTypeValueId == 3
      ) {
        i.approvals.approverFiveStatus.listTypeValueId = 6;
        i.approvals.approvalStatus.listTypeValueId = 6;
        i.approvals.approverlevel = 5;
      }

      if (
        i.approvals.approver.approverFive == this.empId &&
        i.approvals.approverOneStatus.listTypeValueId == 5 &&
        i.approvals.approverTwoStatus.listTypeValueId == 5 &&
        i.approvals.approverThreeStatus.listTypeValueId == 5 &&
        i.approvals.approverFourStatus.listTypeValueId == 5
      ) {
        i.approvals.approverFiveStatus.listTypeValueId = 6;
        i.approvals.approvalStatus.listTypeValueId = 6;
        i.approvals.approverlevel = 5;
      }


      i.approvals.employeeId = this.storeGivenTo;
      // i.approvals.projectId = i.project.projectId;
      // i.projectId = i.project.projectId;
      i.approvalStatus = i.approvals.approvalStatus.listTypeValueId;

      i.advanceGivenTo=this.storeGivenTo
      console.log('i', i);

      this.checkedList.push(i);

      let checkedArray = [i]
      console.log(this.checkedList);
      this.approveDisabled =  false;

      // this.expenseservice.updateBulkApprovalStatus(this.checkedList).subscribe((a) => {
      //   Swal.fire({
      //     icon: 'success',
      //     text: 'Denied',
      //   });
      //   // window.location.reload();
      // });
      this.expenseservice
      .advanceApproval(i.advanceId,i)
      .subscribe((data:any) =>
      {
        this.list_loader = false;
        this.success = true;
        this.loader = false;
        this.sucess_msg=data.message
        // this.sethidetable=false
        
        setTimeout(() => {
          this.success=false
          this.sucess_msg=false
          // window.location.reload();
         
        }, 4000)
        setTimeout(() => {
          this.sucess_msg=false
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
const currentUrl = this.router.url;
this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
this.router.navigateByUrl(currentUrl);
this.getApprovelist()
});

        
}, 5000)
      

         
      },
      (error) => {
        this.list_loader = false;
        this.error = true;
        this.error_msg=error.message
        console.log("error");
        setTimeout(() => {
          this.error = false;
          this.error_msg=false
        }, 2000)
      }
      ) 
    }
  });


}
// approvers(y) {

//   console.log('y',y);
  
//   // console.log('empDatannnnn',empData);

//   this.approvallist.length = 0;
//   // empData.map((y) => {
//     // console.log('y',y);
//     // for (let i = 0; i < empData.length; i++) {
//       // console.log('y.approval.approver.fyi',y.approvals.approver.approverOne.fyi);
//       // if (y.approval.approver.fyi == this.empId) {
//       // this.approvallist.push(y);

//       // console.log('this.EmpListttttttttttttt',this.approvallist);

//       // }

//       // if (
//       //   y.approvals.approver.approverOne == this.empId &&
//       //   y.approvals.approver.approverOne.approverOneStatus == 3 &&
//       //   y.approver.approverOne
//       // ) {
//       //   this.approvallist.push(y);
//       // }
//       // if (
//       //   y.approvals.approver.approverOne.approverTwo == this.empId &&
//       //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverTwoStatus == 3
//       // ) {
//       //   this.approvallist.push(y);
//       // }
//       // if (
//       //   y.approvals.approver.approverOne.approverThree == this.empId &&
//       //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverThreeStatus == 3
//       // ) {
//       //   this.approvallist.push(y);
//       // }
//       // if (
//       //   y.approvals.approver.approverOne.approverFour == this.empId &&
//       //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverThreeStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverFourStatus == 3
//       // ) {
//       //   this.approvallist.push(y);
//       //   console.log(this.approvallist);
//       //   console.log('approver Four data');
//       // }
//       // if (
//       //   y.approvals.approver.approverOne.approverFive == this.empId &&
//       //   y.approvals.approver.approverOne.approverOneStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverTwoStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverThreeStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverFourStatus == 5 &&
//       //   y.approvals.approver.approverOne.approverFiveStatus == 3
//       // ) {
//       //   this.approvallist.push(y);
//       // }

// // console.log(' y.approvals.approver.approverOne', y.approvals.approver.approverOne,this.empId,  y.approvals.approverOneStatus.listTypeValueId );


// for (let i = 0; i < y.length; i++) {

// console.log('cjeck u',y[i]);


//       if (
//         y[i].approvals?.approver?.approverOne == this.empId &&
//         y[i].approvals?.approverOneStatus?.listTypeValueId == 5
//       ) {

       
//         // y[i].approvals.approverOneStatus.listTypeValueId = 5;

//         // y[i].approvals.approverlevel = 1;
// // this.approvallist.pop();
// this.approvallist.pop();
     
// this.approvallist.push(y[i]);
// console.log('pushing array::',this.approvallist.push(y[i])
// );

  
//         // this.approvallist.push(y[i]);
//       }
//       if (
//         y[i]?.approvals?.approver?.approverOne == this.empId &&
//         y[i]?.approvals?.approverOneStatus?.listTypeValueId == 3
//       ) {


//           console.log('y.approvals.approver.approverOne' ,3);
//           console.log(' y.approvals.approver.approverOne',  y[i].approvals.approver.approverOne,this.empId,   y[i].approvals.approverOneStatus.listTypeValueId );

//           this.approvallist.pop();
     
//         this.approvallist.push(y[i]);
//         console.log('this.approvallist',this.approvallist);
//         console.log('pushing array::',this.approvallist.push(y[i]))

        
//         // y[i].approvals.approverOneStatus.listTypeValueId = 5;

//         // y[i].approvals.approverlevel = 1;
//         // this.approvallist =  y;
//       }

//       if (
//         y[i]?.approvals?.approver?.approverTwo == this.empId &&
//         y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 3
//       ) {

//         this.approvallist.pop();
//         this.approvallist.push(y[i])
//         console.log('pushing array::',this.approvallist.push(y[i]))

//         // y[i].approvals.approverTwoStatus.listTypeValueId = 5;

//         // y[i].approvals.approverlevel = 2;
//         // this.approvallist.push(y);
//       }
//       if (
//         y[i]?.approvals?.approver?.approverThree == this.empId &&
//         y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 3
//       ) {

//         this.approvallist.pop();
//         this.approvallist.push(y[i]);
//         console.log('pushing array::',this.approvallist.push(y[i]))

//         // y[i].approvals.approverThreeStatus.listTypeValueId = 5;

//         // y[i].approvals.approverlevel = 3;
//         // this.approvallist.push(y);
//       }
//       if (
//         y[i]?.approvals?.approver?.approverFour == this.empId &&
//         y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverFourStatus?.listTypeValueId == 3
//       ) {
//         this.approvallist.pop();
//         this.approvallist.push(y[i]);
//         console.log('pushing array::',this.approvallist.push(y[i]))

//         // y[i].approvals.approverFourStatus.listTypeValueId = 5;

//         // y[i].approvals.approverlevel = 4;
//         // this.approvallist.push(y);
//       }
//       if (
//         y[i]?.approvals?.approver?.approverFive == this.empId &&
//         y[i]?.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverFourStatus?.listTypeValueId == 5 &&
//         y[i]?.approvals?.approverFiveStatus?.listTypeValueId == 3
//       ) {

//         this.approvallist.pop();
//         this.approvallist.push(y[i]);
//         console.log('pushing array::',this.approvallist.push(y[i]))

//         // y[i].approvals.approverFiveStatus.listTypeValueId = 5;
//         // y[i].approvals.approvalStatus.listTypeValueId = 5;
//         // y[i].approvals.approverlevel = 5;
//         // this.approvallist.push(y);
//       }
//     }
//       if (Array.isArray(this.approvallist)) {
//       this.approvallist = this.approvallist.reduce((accumalator, current) => {
//         if (!accumalator.some((item) => item === current)) {
//           accumalator.push(current);
//         }
//         return accumalator;
//       }, []);
//     }
//     // }
//   // });

//   if (this.approvallist.length == 0) {
//     this.nodata = true;
//   }

//   console.log('timesheet' + this.approvallist.length);
// }
// approvers(empData) {

//   // console.log('empData',empData);
  
//   this.approvallist.length = 0;
//   const uniqueEmpList = new Set();
//  const data= empData.filter((y) => {
//     console.log('y',y);
//     for (let i = 0; i < empData.length; i++) {

//       // console.log('y.approval.approver.fyi',y.approval.approver.fyi);
//       // if (y.approval.approver.fyi == this.empId) {
//       //   this.approvallist.push(y);
//       // }
//       if (y.approvals?.approver?.fyi == this.empId) {
//         this.showMenu = false;
//         this.approvallist.push(y);

//       }
      
//       if (
//         y.approvals?.approver?.approverOne == this.empId &&
//         y.approvals?.approverOneStatus?.listTypeValueId == 3
//       ) {
//         // this.approvallist.pop();
//         // this.approvallist.push(...data);
//         uniqueEmpList.add(y);

//         // console.log('y',y);
        
//         // console.log('approval 1 status 3');
//         // console.log('...uniqueEmpList', ...uniqueEmpList);           
//         this.approvallist.push(y);
        
       
//       }

//       if (
//         y.approvals?.approver?.approverTwo == this.empId &&
//         y.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverTwoStatus?.listTypeValueId == 3
//       ) {
//         // this.approvallist.pop();
//         // this.approvallist.push(...data);
//         uniqueEmpList.add(y);
                  
//         this.approvallist.push(y);
//       }
//       if (
//         y.approvals?.approver?.approverThree == this.empId &&
//         y.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverThreeStatus?.listTypeValueId == 3
//       ) {
//         // this.approvallist.pop();
//         // this.approvallist.push(...data);
//         uniqueEmpList.add(y);
                  
//         this.approvallist.push(y);
//       }
//       if (
//         y.approvals?.approver?.approverFour == this.empId &&
//         y.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverFourStatus?.listTypeValueId == 3
//       ) {
//         // this.approvallist.pop();
//         // this.approvallist.push(...data);
//         uniqueEmpList.add(y);
                  
//         this.approvallist.push(y);
//       }
//       if (
//         y.approvals?.approver?.approverFive == this.empId &&
//         y.approvals?.approverOneStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverTwoStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverThreeStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverFourStatus?.listTypeValueId == 5 &&
//         y.approvals?.approverFiveStatus?.listTypeValueId == 3
//       ) {
//         // this.approvallist.pop();
//         // this.approvallist.push(...data);
//         uniqueEmpList.add(y);
                  
//         this.approvallist.push(y);
//       }
//       this.approvallist = this.approvallist.reduce((accumalator, current) => {
//         if (!accumalator.some((item) => item === current)) {
//           accumalator.push(current);
//         }
//         return accumalator;
//       }, []);
//     }
//   });

//   if (this.approvallist.length == 0) {
//     this.nodata = true;
//   }

//   console.log("timesheet" + this.approvallist.length);

// }

approvers(empData) {

  // console.log('empData',empData);
  if (!Array.isArray(empData)) {
    console.error('empData is not an array');
    return;
  }
  this.approvallist.length = 0;
  // const uniqueEmpList = new Set();
 const data= empData.filter((y) => {
    console.log('y',y);
    for (let i = 0; i < empData.length; i++) {

      // console.log('y.approval.approver.fyi',y.approval.approver.fyi);
      if (y.approvals?.approver?.fyi == this.empId) {
        this.showMenu = false;
        this.approvallist.push(y);

      }
      
      if (
        y.approvals?.approver?.approverOne == this.empId &&
        y.approvals?.approverOneStatus?.listTypeValueId == 3
      ) {
        // this.approvallist.pop();
        // this.approvallist.push(...data);
        // uniqueEmpList.add(y);

        console.log('y',y);
        
      
        // console.log('...uniqueEmpList', ...uniqueEmpList);           
        this.approvallist.push(y);
        console.log('approver1',y);
       
      }

      if (
        y.approvals?.approver?.approverTwo == this.empId &&
        y.approvals?.approverOneStatus?.listTypeValueId == 5 &&
        y.approvals?.approverTwoStatus?.listTypeValueId == 3
      ) {
       
      //  

        this.approvallist.push(y);
        console.log('approverTwo id check', y.approvals.approver.approverTwo, 'empid check',
        this.empId
        );
        
      }
      if (
        y.approvals?.approver.approverThree == this.empId &&
        y.approvals?.approverOneStatus.listTypeValueId == 5 &&
        y.approvals?.approverTwoStatus.listTypeValueId == 5 &&
        y.approvals?.approverThreeStatus.listTypeValueId == 3
      ) {
        // this.approvallist.pop();
        // this.approvallist.push(...data);
        // uniqueEmpList.add(y);
                  
        this.approvallist.push(y);
        console.log('approver3',y);
      }
      if (
        y.approvals?.approver.approverFour == this.empId &&
        y.approvals?.approverOneStatus.listTypeValueId == 5 &&
        y.approvals?.approverTwoStatus.listTypeValueId == 5 &&
        y.approvals?.approverThreeStatus.listTypeValueId == 5 &&
        y.approvals?.approverFourStatus.listTypeValueId == 3
      ) {
        // this.approvallist.pop();
        // this.approvallist.push(...data);
        // uniqueEmpList.add(y);
                  
        this.approvallist.push(y);
        console.log('approver4',y);
      }
      if (
        y.approvals?.approver.approverFive == this.empId &&
        y.approvals?.approverOneStatus.listTypeValueId == 5 &&
        y.approvals?.approverTwoStatus.listTypeValueId == 5 &&
        y.approvals?.approverThreeStatus.listTypeValueId == 5 &&
        y.approvals?.approverFourStatus.listTypeValueId == 5 &&
        y.approvals?.approverFiveStatus.listTypeValueId == 3
      ) {
        // this.approvallist.pop();
        // this.approvallist.push(...data);
        // uniqueEmpList.add(y);
                  
        this.approvallist.push(y);
        console.log('approver4',y);
      }
      this.approvallist = this.approvallist.reduce((accumalator, current) => {
        if (!accumalator.some((item) => item === current)) {
          accumalator.push(current);
        }
        return accumalator;
      }, []);
    }
  });

  if (this.approvallist.length == 0) {
    this.nodata = true;
  }

  console.log("timesheet" + this.approvallist.length);

}
  sortData(sort: Sort) {
    const data = this.approvallist.slice();
    if (!sort.active || sort.direction === '') {
      this.approvallist = data;
      return;
    }

    this.approvallist = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employeeName':
          return this.compare(a.employee.employeeName, b.employee.employeeName, isAsc);
        case 'employeeId':
          return this.compare(a.employee.employeeId, b.employee.employeeId, isAsc);
        case 'organizationEmail':
          return this.compare(a.employee.organizationEmail, b.employee.organizationEmail, isAsc);
        case 'subTotal':
          return this.compare(a.subTotal, b.subTotal, isAsc);
        case 'cashAdvance':
          return this.compare(a.cashAdvance, b.cashAdvance, isAsc);
        case 'totalReimbursement':
          return this.compare(a.totalReimbursement, b.totalReimbursement, isAsc);
        case 'advanceAmount':
          return this.compare(a.advanceAmount, b.advanceAmount, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


}
