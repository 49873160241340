import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';
import { UserService } from '../../../providers/user.service';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  }  from '../../../providers/properties';

@Component({
  selector: 'app-list-type-values',
  templateUrl: './list-type-values.component.html',
  styleUrls: ['./list-type-values.component.scss']
})
export class ListTypeValuesComponent implements OnInit 
{
  public searchFilter: any = '';

  resultData: any;
  listTypeValueId: any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private user_service: UserService,
    private ActivatedRoute:ActivatedRoute,
  ) { }

  ngOnInit(): void 
  {
    this.listTypeValueId = this.ActivatedRoute.snapshot.params.listTypeValueId;
    /* if(this.listTypeId) //View User
    {
      this.settingsservice.getListTypeValues(this.listTypeId).subscribe((resultData:any) =>
      {
          this.resultData=resultData;
      });
    } */

    this.listTypeValueList(this.listTypeValueId);
  }


  listTypeValueList(listTypeValueId)
  {
    this.settingsservice.getListTypeValues(listTypeValueId).subscribe((resultData:any) =>
    {
      this.resultData=resultData;
    });
  }

}
