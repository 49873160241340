<div class="resource-background">
    <div class="resource-title">
        <h1>POSITION</h1>
    </div>  
    <div class="card resource-card">
        <form>
            <div class="row">   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Position Name : </label>
                    <label class="form-control-label-class">  {{positionobj.positionName}}</label>
                </div>   
                <div class="col-md-6" id="loginform" >
                    <label class="form-control-label">Position Number : </label>
                    <label class="form-control-label-class">  {{positionobj.positionNumber}}</label>
                </div>                                                  
              
            </div>  
            <div class="row">  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Experience : </label>
                    <label class="form-control-label-class"> {{positionobj.minExperience}} - {{positionobj.maxExperience}} Years</label>
                </div>
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Location : </label>
                    <label class="form-control-label-class"> {{positionobj.location}} </label>
                </div>
              
            </div>
            <div class="row"> 
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">CTC : </label>
                    <label class="form-control-label-class"> {{positionobj.ctc}}</label>
                </div>  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Hiring For : </label>
                    <label class="form-control-label-class"> {{positionobj.hiring.listTypeValueName}}</label>
                </div>                                                  
               
            </div>
            <div class="row">  
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Client Name : </label>
                    <label class="form-control-label-class" *ngIf="positionobj.client != undefined"> {{positionobj.client.clientName}}</label>
                    <label class="form-control-label-class" *ngIf="positionobj.client == undefined">     --    </label>
                </div>   
                <div class="col-md-6" id="loginform">
                    <label class="form-control-label">Job Type : </label>
                    <label class="form-control-label-class"> {{positionobj.jobType.listTypeValueName}}</label>
                </div>                                                  
           
            </div>  
            <div class="row">  
                <div class="col-md-6">
                    <label class="form-control-label">Notice Period : </label>
                    <div>
                        <span *ngFor="let e of positionobj.noticePeriod">  
                            <label class="form-control-label-class badge badge-info"> {{e.listTypeValueName}}</label>
                        </span>
                    </div>
                </div>  
                 
                <div class="col-md-6" id="loginform" *ngIf="positionobj.jobDescriptionAttachment">
                    <label class="form-control-label">Job Description : </label>
                    <div style="position: relative;bottom: 5px;">
                        <a class="file-download badge badge-secondary form-control-label" href="{{positionobj.jobDescriptionAttachment.fileDownloadUrl}}">
                      <!-- </a> -->
                            <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>Download</a>
                    </div>  
                </div>                                                   
            </div>
        </form>  
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
