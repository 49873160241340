<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>{{timezoneId ? "Edit" : "Add"}} Time Zone</h3>

    <mat-dialog-content class="mat-typography">
       <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Time Zone <span class="star">*</span></label>
                <input type="text" formControlName="timezoneName" id="timezoneName" (keyup)="checkExistTimeZoneName($event)" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="" placeholder="Time Zone">
                <div class="valid-msg" *ngIf="(validate.timezoneName.invalid && validate.timezoneName.touched) || validate.timezoneName.dirty || submitted">
                    <span *ngIf="validate.timezoneName.errors?.required" class="text-danger">Please enter time zone</span>
                    <span *ngIf="dateFormatNameExistStatus" class="span-error">Time zone already exist!</span>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Time Zone Description</label>
                <textarea formControlName="timezoneDescription" id="timezoneDescription" autocomplete="off" class="form-select form-select-solid form-select-lg" placeholder="Time Zone Description"></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>
