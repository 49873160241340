import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import *  as htmlToPdfmake  from "html-to-pdfmake";
import { Router,ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { ProjectManagementService } from 'src/app/providers/project-management.service';

@Component({
  selector: 'app-payment-advice-details',
  templateUrl: './payment-advice-details.component.html',
  styleUrls: ['./payment-advice-details.component.scss']
})
export class PaymentAdviceDetailsComponent implements OnInit {

  paymentdata:any={};
  amountInwords = "";
  loader :any;
  // @ViewChild('paymentsTable')
  // paymentsTable!: ElementRef;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public expenseservice: ProjectManagementService,
    private ActivatedRoute: ActivatedRoute, 
    private router:Router,
    private datepipe  : DatePipe
   ) { }

  ngOnInit(): void {
    console.log("data"+this.data);
     this.loader = true;
    this.expenseservice
    .getexpensereburimentbyId(this.data) 
    .subscribe((paymentData:any) => {
      this.paymentdata =paymentData
      console.log(this.paymentdata);
      // console.log(this.paymentdata.employee.firstName);
      this.loader = false;
      if(this.paymentdata.paymentAdvice)
      {
       this.amountInwords = this.inWords(this.paymentdata.paymentAdvice.paymentAmount)
        console.log(this.amountInwords);
      }
      });
  }

  editpayment(exReimbursementId:any,paymentAdviceId:any)
    {
      this.dialog.closeAll();
      console.log("Edit payment advice",exReimbursementId);
      console.log("Edit payment advice",paymentAdviceId);
      this.router.navigate([`home/payment-advice/${exReimbursementId}/${paymentAdviceId}`]);
    }
   downloadAsPDF()
   {
    //  console.log("download");
    // const payTable = this.paymentsTable.nativeElement;
    // console.log("payTable",payTable);
    // var html = htmlToPdfmake(payTable.innerHTML, {
    //   tableAutoSize:true,
    // });
    // console.log("html",html);
    // const documentDefinition = {
    //    content:html, 
    //    pageMargins: [ 40, 40, 40, 60 ],
    //     imagesByReference:true ,
    //    info: {
    //      title:`Expense Reimbursement ${this.paymentdata.fromDate} - ${this.paymentdata.toDate}`
    //      }
    //     };
    //  console.log(documentDefinition);
    // pdfMake.createPdf(documentDefinition).download(`Expense Reimbursement ${this.paymentdata.fromDate} - ${this.paymentdata.toDate}.pdf`); 
    var data = document.getElementById('pdfTable');
    const pdfTable = this.pdfTable?.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download(`Expense Reimbursement ${this.paymentdata.fromDate} - ${this.paymentdata.toDate}.pdf`);
    
   } 
    printpdf()
    {
    //   console.log("PrintPDF");
    //   const payTable = this.paymentsTable.nativeElement;
    // var html = htmlToPdfmake(payTable.innerHTML,{tableAutoSize:true});
    // const documentDefinition = { content: html ,
    //   pageMargins: [ 40, 40, 40, 60 ],
    //   info: {
    //     title:`Expense Reimbursement ${this.paymentdata.fromDate} - ${this.paymentdata.toDate}`
    //     }
    const pdfTable = this.pdfTable?.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
  const documentDefinition = { content: html };
  pdfMake.createPdf(documentDefinition).print();
    
    };
    // pdfMake.createPdf(documentDefinition).print(); 
    

    formcancel()
    {
      this.dialog.closeAll();
    }

 
 inWords (num) {
  let a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
  let  b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
  
    if ((num = num.toString()).length > 9) 
    {
      return 'overflow';
    }
    let n=[];
     n= ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return ""; 
    }
    var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Only ' : '';
      if(!str.includes("Only"))
      {
        str =  str + 'Only';
      }
          return str;
}



}
