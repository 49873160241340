<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <div class="">
                        <span class="h5 sub-content pr-3">Add Approver Rule</span>
                    </div>
                    <!-- <span routerLink="/home/kra-template" class="h5 sub-content">Add Branch</span> -->
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Rule Name <span class="star">*</span></label>
                            <input type="text" placeholder="Rule Name" formControlName="ruleName" autocomplete="off" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            <div class="valid-msg" *ngIf="(validate.ruleName.invalid && validate.ruleName.touched) || validate.ruleName.dirty || submitted">
                                <span *ngIf="validate.ruleName.errors?.required" class="text-danger">Please Enter Rule Name</span>
                                <span *ngIf="validate.ruleName.errors?.pattern" class="text-danger">Please enter valid rule name</span>
                                <span *ngIf="existMsg" class="text-danger">{{existMsg}}</span>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Rule Description <!-- <span class="star">*</span> --></label>
                            <input type="text" formControlName="ruleDescription" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="Rule Description">
                            <div class="valid-msg" *ngIf="(validate.ruleDescription.invalid && validate.ruleDescription.touched) || validate.ruleDescription.dirty || submitted">
                                <span *ngIf="validate.ruleDescription.errors?.required" class="text-danger">Please enter Branch Name</span>
                                <span *ngIf="validate.ruleDescription.errors?.pattern" class="text-danger">Please enter valid Branch Name</span>
                            </div>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="col-form-label">FYI <!-- <span class="star">*</span> --></label>
                            <select formControlName="fyi" placeholder="- Select -" class="form-select form-select-solid form-select-lg select2-new">
                                <option value="null">- Select -</option> 
                              <!-- <option *ngFor="let position of positionlist" value="{{position.positionId}}">{{position.positionName}} - {{position.positionNumber}}</option>
                               -->
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Start Date <!-- <span class="star">*</span> --></label>
                            <!-- <input type="text" formControlName="startDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="Start Date"> -->
                            <input type="text" formControlName="startDate" id="startDate" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" matInput [matDatepicker]="periodFrom" readonly="readonly" placeholder="Select Start Date">
                            <mat-datepicker-toggle matSuffix [for]="periodFrom" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #periodFrom></mat-datepicker>
                            <div class="valid-msg" *ngIf="(validate.startDate.invalid && validate.startDate.touched) || validate.startDate.dirty || submitted">
                                <span *ngIf="validate.startDate.errors?.required" class="text-danger">Please select start date</span>
                                <!-- <span *ngIf="validate.periodFrom.errors?.pattern" class="text-danger">Please enter valid Mobile Number</span> -->
                            </div>
                            <!-- <div class="valid-msg" *ngIf="(validate.phoneNumber.invalid && validate.phoneNumber.touched) || validate.phoneNumber.dirty || submitted">
                                <span *ngIf="validate.phoneNumber.errors?.required" class="text-danger">Please enter Mobile Number</span>
                                <span *ngIf="validate.phoneNumber.errors?.pattern" class="text-danger">Please enter valid Mobile Number</span>
                            </div> -->
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label">End Date <!-- <span class="star">*</span> --></label>
                            <input type="text"  formControlName="endDate" id="endDate"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 email-input" matInput [matDatepicker]="periodTo" readonly="readonly" placeholder="Select End Date">
                            <mat-datepicker-toggle matSuffix [for]="periodTo" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #periodTo></mat-datepicker>
                            <div class="valid-msg" *ngIf="(validate.endDate.invalid && validate.endDate.touched) || validate.endDate.dirty || submitted">
                                <span *ngIf="validate.endDate.errors?.required" class="text-danger">Please select end date</span>
                                <!-- <span *ngIf="validate.periodFrom.errors?.pattern" class="text-danger">Please enter valid Mobile Number</span> -->
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="form-group col-md-9 mb-3">
                            <label class="col-form-label">
                                <h4 class="criteria-class mb-2">Add several rule approvers and levels.</h4>
                                <h4 class="negative-rating mb-1">The maximum number of approvers is 10.</h4>
                            </label>
                        </div>
                    </div> -->

                    <!-- Add and remove more start -->
                    
                    <div class="row">
                        <div class="col-md-3">Approver <span class="star">*</span></div>
                        <div class="col-md-3">Level <span class="star">*</span></div>
                        <!-- <div class="col-md-2">Negative Rating</div> -->
                        <!-- <div class="col-md-2"></div> -->
                        <div class="mt-2 form-group col-md-12" *ngFor=" let con of add_remove().controls; let i=index" formArrayName="add_remove_more">
                            <div class="row" [formGroupName]="i" *ngIf="i<10">
                                <div class="col-md-3">
                                    <select formControlName="approverId" placeholder="- Select -" class="form-select form-select-solid form-select-lg select2-new">
                                        <option value="null">- Select -</option>
                                       <!-- <option *ngFor="let position of positionlist" value="{{position.positionId}}">{{position.positionName}} - {{position.positionNumber}}</option>
                                        -->
                                    </select>
                                    <div class="valid-msg" *ngIf="(postForm.get('add_remove_more.' + i + '.approverId').invalid && postForm.get('add_remove_more.' + i + '.approverId').touched) || postForm.get('add_remove_more.' + i + '.approverId').dirty || submitted">
                                        <span *ngIf="postForm.get('add_remove_more.' + i + '.approverId').hasError('required')" class="text-danger">Please Enter Approver</span>
                                        <!-- <span *ngIf="validate.criteriaName.errors?.pattern" class="text-danger">Please enter valid criteria name</span> -->
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <select formControlName="approvalLevelId" placeholder="- Select -" class="form-select form-select-solid form-select-lg select2-new">
                                         <option value="null">- Select -</option> 
                                       <!-- <option *ngFor="let position of positionlist" value="{{position.positionId}}">{{position.positionName}} - {{position.positionNumber}}</option>
                                        -->
                                    </select>
                                    <div class="valid-msg" *ngIf="(postForm.get('add_remove_more.' + i + '.approvalLevelId').invalid && postForm.get('add_remove_more.' + i + '.approvalLevelId').touched) || postForm.get('add_remove_more.' + i + '.approvalLevelId').dirty || submitted">
                                        <span *ngIf="postForm.get('add_remove_more.' + i + '.approvalLevelId').hasError('required')" class="text-danger">Please Select Level</span>
                                        <!-- <span *ngIf="validate.criteriaName.errors?.pattern" class="text-danger">Please enter valid criteria name</span> -->
                                    </div>
                                </div>               
                                <!-- add and remove btn -->
                                
                                <div *ngIf="i<1" class="col-md-2">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="addMore()">
                                        Add
                                    </button>
                                </div>

                                <div class="col-md-2 mt-2" *ngIf="i>0">
                                    <mat-icon (click)="removeMore(i)" class="material-icons-outlined">delete</mat-icon>
                                </div>
                                <!-- add and remove btn -->

                            </div>
                        </div>
                        <!-- <div class="form-group  col-md-3">
                        </div> -->
                    </div>
                    <!-- Add and remove more end -->

                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a routerLink="/home/approver-rules" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2">
                                Cancel
                            </a>
                            <button type="submit" mat-raised-button color="primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>