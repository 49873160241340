import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowerrorComponent } from '../../../app/showerror/showerror.component';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import Swal from 'sweetalert2';
// import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { redirectMsgTimeOut,responseMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-manage-work-hours',
  templateUrl: './manage-work-hours.component.html',
  styleUrls: ['./manage-work-hours.component.scss']
})
export class ManageWorkHoursComponent implements OnInit {
  getdata: any;
  maxWorkHours: any;
  organizationEmail: any;
  organzationid: number;
  workhours: any;
taskForm: FormGroup;
  maxWorkHoursData: any;
  isbtnDisable:boolean=false;
success: boolean=false;
sucess_msg:any;
error:any=false;
error_msg:any;
importerror: boolean = false;
errormessagejson: any;
  errorMsg: any;
  submitted:boolean=false


  constructor(private gs:ProjectManagementService,
    private route: ActivatedRoute,
    private dialog:MatDialog,
    private router: Router) { 
      const data = JSON.parse(localStorage.getItem('enoteUserData'))
      console.log(data);
      // this.organizationEmail = data.organizationEmail;
      this.organzationid = data.organization.organizationId;

      console.log("organzationid" ,this.organzationid);
      // console.log("organization mail::",this.organizationEmail);

     
    }

    getData(){
      this.gs.Getmaxworkhours().subscribe(
        (response:any)=>{
          console.log("response::",response);


          this.getdata=response;
          this.taskForm.patchValue({
            maxWorkHours: this.getdata.maxWorkHours,
          })
          this.maxWorkHoursData = this.getdata.maxWorkHours;
          console.log("Max Hours wtts::",this.maxWorkHoursData);
        }
      )
    }

  ngOnInit(): void {
    this.taskForm = new FormGroup(
      {
        maxWorkHours : new FormControl('',[Validators.required,Validators.pattern(/^[0-9]*$/)])
      })
   this.getData()
  }
  get f() {
    return this.taskForm.controls;
  }
  WorkHours(e){
    this.workhours = e.target.value;
  }
  showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}


  onSubmit() {
    this.submitted=true;

    const orgId=this.organzationid

    if (this.taskForm.invalid) {
      return
    }

    var maxWorkHours =
    { 
      // maxWorkHoursId: 1,
      maxWorkHours : this.taskForm.value.maxWorkHours, 
      // maxWorkHoursStatus : this.getdata.maxWorkHoursStatus,
      maxWorkHoursStatus:{
        listTypeValueId:1,
        listTypeValueName:"Active"
      },
      // organization:{
        organizationId: orgId
    // }
  }
  console.log("payload:",maxWorkHours);
  
    // console.log("wtts after update maxworkhours::",maxWorkHours);
    // console.log("organization id::",orgId);

    // this.isbtnDisable=true;
    
    // this.gs.updatemaxworkhours(maxWorkHours,orgId).subscribe(
    //   (data:any) =>{
    //     console.log(data);
       
    //     if(data.statusCode==200){   
    //       this.success=true
    //       this.sucess_msg=data. message   
    //       this.isbtnDisable=true;

    //       setTimeout(()=>{
    //         this.success = false;
    //       },5000)
    //     }

    //     console.log("organization id::",this.organzationid);

    //   //  window.location.reload()
    //   },
    //   err=>{
    //     this.error = true;
    //     this.isbtnDisable=false;

    //   //  Swal.fire({
    //   //    text:err.error,
    //     //  icon:'error'
    //   //  })
    //   console.log("code",err);
    //   console.log("code",err.statusCode);
    //     setTimeout(() => {
    //       this.error = false;
    //     }, 5000) 
    //   }
    // )
    this.isbtnDisable=true;
    this.gs.createMaxHours(maxWorkHours).subscribe((data:any)=>{
      console.log(data);
      this.submitted=false;
      if(data.statusCode==200){   
        this.success=true
        this.submitted=false;
        this.sucess_msg=data. message   
        this.isbtnDisable=true;

        setTimeout(()=>{
          this.success = false;
          this.submitted=false;
          this.isbtnDisable=false;

        },5000)
      }

      console.log("organization id::",this.organzationid);

    //  window.location.reload()
    },
    err=>{
      this.error = true;
      this.isbtnDisable=false;

    //  Swal.fire({
    //    text:err.error,
      //  icon:'error'
    //  })
    console.log("code",err);
    console.log("code",err.statusCode);
      setTimeout(() => {
        this.error = false;
      }, 5000) 
    }
  )
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    const inputElement = event.target;
    const currentValue = inputElement.value;
  
    // Check if the input character is not a number and not a dot
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      return;
    }
  
    // Allow dot only if there is a numeric expression before it
    if (inputChar === '.') {
      if (currentValue === '' || currentValue.endsWith('.')) {
        event.preventDefault();
        return;
      }
    }
  }
  
}

