<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Manage Loan Approvals</span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <!-- <div class="card resource-card"> -->
                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="select-pagination">
                                <span style="font-size: 12px;">Show : </span>
                                <select class="select_list new-select_list" [(ngModel)]="mySelect">
                                    <option class="form-control" value="5"> 5</option>
                                    <option class="form-control" value="10">10</option>
                                    <option class="form-control" value="15">15</option>
                                    <option class="form-control" value="20">20</option>
                                    <option class="form-control" value="25">25</option>
                                </select>
                            </div>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <div>
                                    <table class="table --table-bordered table-border-new" matSort (matSortChange)="sortData($event)">
                                        <thead class="t-head" style="background-color: #f6f9fc;">
                                            <tr>
                                                <th class="text-center">Action</th>
                                                <th mat-sort-header="employeeId" class="text-center">Employee Code</th>
                                                <th mat-sort-header="employeeName">Employee&nbsp;Name</th>                                            																			
                                                <th mat-sort-header="designation">Designation</th>
                                                <th mat-sort-header="RequestedLoanAmount" class="text-center">Requested Loan Amount</th>
                                                <th mat-sort-header="Installments" class="text-center">Installments</th>
                                                <th mat-sort-header="interest" class="text-center">Loan interest %</th>
                                                <th mat-sort-header="listTypeValueName" class="text-center">Approval&nbsp;Status</th>
                                            </tr>
                                        </thead>
                                        <tbody class="t-body" *ngIf="approvallist?.length > 0 ">
                                            <tr *ngFor="let e of approvallist | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                                <td class="text-center" *ngIf="e.fyistatus == undefined">
                                                    <button class="menu-button"
                                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                        <mat-icon class="menu-icon">more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item (click)="openDialog(e.employeeLoanId)">
                                                            <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                                            <span mat-button class="text">View</span>
                                                        </button>
                                                        <button mat-menu-item  (click)="approve(e)">
                                                            <mat-icon class="material-symbols-outlined viewicon text-success">verified</mat-icon>
                                                            <span class="text text-success">Approve</span>
                                                        </button>
                                                        <button mat-menu-item (click)="deny(e)">
                                                            <mat-icon class="material-symbols-outlined viewicon text-danger">block</mat-icon>
                                                            <span class="text text-danger">Deny</span>
                                                        </button>
                                                    </mat-menu>
                                                </td>
                                                <td class="text-center" *ngIf="e.fyistatus == true">
                                                    <button class="menu-button"
                                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                        <mat-icon class="menu-icon">more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item (click)="openDialog(e.employeeLoanId)">
                                                            <mat-icon class="material-symbols-outlined view-icon">visibility</mat-icon>
                                                            <span mat-button class="text">View</span>
                                                        </button>
                                                        <button mat-menu-item (click)="viewapproverstatus(e)">
                                                            <mat-icon class="view-icon">visibility</mat-icon>
                                                            <span mat-button class="text">View Approvers</span>
                                                        </button>
                                                    </mat-menu>
                                                </td> 
                                                <td class="text-center">{{e.employee.employeeNumber}}</td> 
                                                <td class="">{{e.employee.firstName}} {{e.employee.lastName}}</td>
                                                <td class="">{{e.employee.designation.designationName}}</td>
                                                <td class="text-right">{{e.loanRequestedAmount.toFixed(2)}}</td>
                                                <td class="text-center">{{e.employeeLoanInstallments.length}}</td> 
                                                <td class="text-center">{{organizationSettings.maxLoanInterest}}%</td> 
                                                <td class="text-center">
                                                    <div *ngIf="e.approval != undefined">
                                                        <span class="text-success" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Approved'">
                                                            {{e.approval.approvalStatus.listTypeValueName}}
                                                        </span>
                                                        <span class="text-warning" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Pending'">
                                                            {{e.approval.approvalStatus.listTypeValueName}}
                                                        </span>
                                                        <span class="text-danger" *ngIf="e.approval.approvalStatus.listTypeValueName == 'Denied'">
                                                            {{e.approval.approvalStatus.listTypeValueName}}
                                                        </span>
                                                    </div>
                                                </td>         
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style="text-align: center;" colspan="8" *ngIf="nodata">No matching records found!</td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div> 
                                <!-- <div *ngIf="nodata">
                                    <div class="no-data card text-center">
                                        <div class="card-body">No Data</div> 
                                    </div>
                                </div>   -->
                                <div *ngIf="list_loader" class="align-items-center text-center">
                                    <div class="spinner-border" 
                                    style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;bottom: 0;" role="status"></div>
                                </div>                                 
                            </div>
                        </div>
                    </div>
                    <div class="row topnav-right pagination-content">        
                        <div class="col-md-12">
                            <pagination-controls class="float-right pagination" style="float: right;"
                            (pageChange)="p = $event; beforepage = $event"></pagination-controls>  
                        </div> 
                    </div> 
                <!-- </div>  -->
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success"> 
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>Approver Status Updated Successfully
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>Unable to Update Approver Status
</div>



