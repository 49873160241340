<div
  style="margin-top: 80px; margin-left: 10px; margin-right: 10px"
  class="card card-content"
  [ngClass]="{'min-height': selectedType === 'organization' || selectedType === 'client'}"
>
  <h4 class="heading-tag">Assign Elements</h4>
  <div class="row justify-content-between">
    <div class="col-md-4">
      <div id="loginform">
        <div class="col-md-12 col-xl-6 select">
          <label class="form-control-label">
            Select&nbsp;Organization&nbsp;/&nbsp;Client
          </label>
          <!-- <mat-select
            (selectionChange)="selected($event)"
            [(value)]="selectedType"
            [disabled]="organizationLogin || clientLogin"
            class="form-control email-input assign-placeholder"
            placeholder="Select Organization/Client"
          >
            <mat-option
              *ngIf="!organizationLogin && !clientLogin"
              [value]="undefined">
              -Select Organization/Client-
            </mat-option>
            <mat-option [value]="'organization'"> Organization </mat-option>
            <mat-option [value]="'client'" > Client </mat-option>
          </mat-select> -->
          <input
          type="text"
          placeholder="Organization/Client Name"
          formControlName="filterorg"
          autocomplete="off"
          [readonly]="true"
          [value]="selectedType === 'organization' ? 'Organization' : selectedType === 'client' ? 'Client' : 'No Selection'"
          style="background: #dddddd87 !important;width: 263px !important;"
          class="form-control email-input select-placeholder"
        />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedType === 'organization'" class="mt-3">
    <app-organization-elements></app-organization-elements>
  </div>

  <div *ngIf="selectedType === 'client'" class="mt-3 client">
    <app-client-element-list></app-client-element-list>
  </div>
</div>
