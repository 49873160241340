<!-- <div  class="container">
    <div class="row" style="margin-top: 70px;">
        <div class="col-md-6 resource-title">
            <h2>EXPENSE REIMBURSEMENT</h2>
        </div>
                                 
        <div class="col-md-3 form-group search-input topnav-right" style="margin-left: 100px;">                                                                     
            <input matInput class="search form-control" type="text" placeholder="Search.." [(ngModel)]="query" (keyup)="onKeyUpEvent($event)" name="search" style="padding-left: 30px;">
            <mat-icon aria-hidden="false" aria-label="Example search icon" class="material-icons-round search-input-icon" style="position: relative; bottom: 26px; padding-left:5px">search</mat-icon>                                                                                                      
        </div>
    </div> -->
    <!-- <nav class="navbar bg" style="margin-top: 68px">
        <div class="container-fluid">
          <a class="navbar-brand">Expense Reiumbursement</a>
          <form class="d-flex">
            <input
              class="form-control me-2"
              aria-label="Search"
              type="text"
              class="form-control search-menu"
              placeholder="Search..."
              [(ngModel)]="query" (keyup)="onKeyUpEvent($event)" 
              name="searchText"
              style="
                border-radius: 5px;
                border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
                background: #fff;
                width: 250px;
                height: 45px;
                flex-shrink: 0;
              "
            />
          </form>
        </div>
      </nav> -->
      <nav class="navbar  bg" style="margin-top: 67px;">
        <a class="navbar-brand navDesign" style="font-size: 22px;
        font-weight: 800;
        font-family: 'Mulish', sans-serif;">Expense Reimbursement</a>
        <div class="d-flex">
        <input
              class="form-control me-2 no-border-on-focus"
             
              placeholder="Search..."
              [(ngModel)]="search" (keyup)="changepage()" 
              name="searchText"
              aria-label="Search"
              style="
                width: 250px;
                height: 38px;
                flex-shrink: 0;
                border-radius: 5px;
                background: #fff;position: relative;right: 20px;
              "
            />
            <button class="btn" type="submit" (click)="Add_expense()" style="    background-color: #1a83ff;
            color: whitesmoke;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 610;
            font-family: 'Mulish', sans-serif;
            position: relative;
            /* float: left; */
            right: 10px;">Create Expense</button>
          </div>
       
      </nav>
      
<!-- <mat-card > -->

    <div  class="card resource-card" style="border: 0px solid rgba(0,0,0,.125);padding: 30px;margin-top: 5px;" *ngIf="tableShow" >
        <div class="row rowDesign" *ngIf="expenseReimbursementList?.length > 0">
            <div class="col-xl-6 col-lg-6" style="margin-top:10px" *ngIf="expenseReimbursementList?.length > 5">
                <div class="select-pagination">
                    <span id="showDesign">Show : </span>
                    <select class="select_list new-select_list " id="listDesign" [(ngModel)]="mySelect" 
                    style="width: 51px;
                    border-radius: 5px;
                    position: relative;
                    /* float: right; */
                    left: 73px;
                    border: 1px solid rgb(38 34 34 / 50%);" (change)="showChanges()"
                    >
                        <option class="form-control" value="5"> 5</option>
                        <option class="form-control" value="10">10</option>
                        <option class="form-control" value="15">15</option>
                        <option class="form-control" value="20">20</option>
                        <option class="form-control" value="25">25</option>
                    </select>
                </div>
            </div>  
            <!-- <div class="col-xl-6 col-lg-6" style="margin-bottom: 10px;">
                <div class="topnav-right add-button">
                    <button (click)="Add_expense()" mat-button class="btn add-resource">Add Expense</button>  
                </div> 
            </div>   -->
        </div>
        
        <div class="row">
            <div class="col-md-12 colDesign">
                <div   class="resource-table table-responsive" >
                    <div  >
                        <table class="interviewed-status-table" matSort (matSortChange)="sortData($event)" style="width:100% ;" >
                            <thead >
                                <tr  id="trDesign">
                                    <th mat-sort-header="fromDate" class="table-head text-center" style="border-radius: 15px 0px 0px 0px;">&nbsp;&nbsp;&nbsp;From&nbsp;Date&nbsp;&nbsp;&nbsp;</th>                                            																			
                                    <th mat-sort-header="toDate" class="table-head text-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th mat-sort-header="subTotal" class="table-head text-center">Subtotal</th>
                                    <th mat-sort-header="cashAdvance" class="table-head text-center">Advance</th>
                                    <!-- <th mat-sort-header="finalbalance" class="table-head text-center"> Advance Balance</th> -->
                                    <th mat-sort-header="totalReimbursement" class="table-head text-center">Total Reimbursement</th>
                                    <th class="table-head text-center">View Approvers</th>
                                    <th class="table-head text-center">Approval&nbsp;&nbsp;Status</th>
                                    <!-- <th class="table-head text-center">STATUS</th>-->
                                    <th class="table-head text-center" style="border-radius: 0px 15px 0px 0">Action</th>
                                </tr>
                            </thead>
                            <tbody style="border-bottom: 1px solid #ddd;
                            border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;" *ngIf="expenseReimbursementList?.length > 0 ">
                                <tr *ngFor="let expenseReimbursement of expenseReimbursementListFiltered | mytask: search | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                    <td class="table-data text-center">{{expenseReimbursement.fromDate}}</td>
                                    <td class="table-data text-center">{{expenseReimbursement.toDate}}</td> 
                                    <td class="table-data text-center">{{expenseReimbursement.subTotal}}</td>
                                    <td class="table-data text-center">{{expenseReimbursement.cashAdvance}}</td> 
                                    <!-- <td *ngIf="expenseReimbursement.totalReimbursement == 0" class="table-data text-center">{{expenseReimbursement.advanceBalance}}</td> 
                                    <td *ngIf="expenseReimbursement.totalReimbursement  > 0" class="table-data text-center">0.00</td>  -->
                                    <td class="table-data text-center">{{expenseReimbursement.totalReimbursement}}</td>
                                    <td class="table-data text-center" style="padding-top: 14px;">
                                        <mat-icon class="view-icon" *ngIf="expenseReimbursement?.approvalStatus != undefined"
                                        (click)="viewapproverstatus(expenseReimbursement)">visibility</mat-icon>
                                        <span *ngIf="expenseReimbursement?.approvalStatus == undefined">  --  </span>
                                    </td>
                                    <!-- <td class="table-data" >{{expenseReimbursement.approver.employeeName}}</td>  -->
                                    <!-- <td class="table-data text-center text-success" *ngIf="expenseReimbursement.approval == undefined">Saved as Draft</td> -->

                                    <td class="table-data text-center">
                                        <div *ngIf="expenseReimbursement?.approvalStatus !== undefined">
                                            <!-- <span>pending</span> -->
                                            <span class="text-success" *ngIf="expenseReimbursement?.approvalStatus?.listTypeValueName == 'Approved'">
                                                {{expenseReimbursement?.approvalStatus?.listTypeValueName}}
                                            </span>
                                            <span class="text-warning" *ngIf="expenseReimbursement?.approvalStatus?.listTypeValueName == 'Pending'">
                                                {{expenseReimbursement?.approvalStatus?.listTypeValueName}}
                                            </span>
                                            <span class="text-danger" *ngIf="expenseReimbursement?.approvalStatus?.listTypeValueName == 'Denied'">
                                                {{expenseReimbursement?.approvalStatus?.listTypeValueName}}
                                            </span>
                                        </div>
                                        <div class="text-success" *ngIf="expenseReimbursement?.approvalStatus == undefined">Saved as Draft</div>
                                    </td>
                                    <!-- <td *ngIf="expenseReimbursement.status.listTypeValueName =='Active'" class="table-data text-center text-success">
                                        <ul id="nav">
                                            <li class="text-center dropdown-list" style=" border: 1px solid green;">
                                                <a class="text-success dropdown-list-status" *ngIf="expenseReimbursement.status.listTypeValueName =='Active'">{{expenseReimbursement.status.listTypeValueName}}</a>
                                                <span>
                                                    <mat-icon class="dropdown-list-icon text-success" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                                </span>
                                                <ul  class="dropdown-item" (click)="statuschangeactive(expenseReimbursement.status)">
                                                    <li class="material-menu">
                                                        <a class="text-danger dropdown-item-status-inactive">In-Active</a>
                                                        <mat-icon class="text-danger material-menu-icon" aria-hidden="false">do_disturb</mat-icon>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                    <td *ngIf="expenseReimbursement.status.listTypeValueName =='In-Active'" class=" table-data text-center text-danger">
                                        <ul id="nav">
                                            <li class="text-center dropdown-list" style=" border: 1px solid red;">
                                                <a class="text-danger dropdown-list-status" *ngIf="expenseReimbursement.status.listTypeValueName =='In-Active'">{{expenseReimbursement.status.listTypeValueName}}</a>
                                                <span>
                                                    <mat-icon class="dropdown-list-icon text-danger" aria-hidden="false" aria-label="Example arrow_drop_down">arrow_drop_down</mat-icon>
                                                </span>
                                                <ul class="dropdown-item" (click)="statuschangeinactive(expenseReimbursement.status)">
                                                    <li class="material-menu">
                                                        <a class="text-success dropdown-item-status-active">Active</a>
                                                        <mat-icon class="material-menu-icon text-success" aria-hidden="false">verified</mat-icon>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>-->
                                    <td class="table-data text-center" style="margin-top: -11px;">
                                        <button class="menu-button"
                                            mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon class="menu-icon">more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <!-- <button mat-menu-item (click)="openDialog(expenseReimbursement.expenseReimbursementId)">
                                                <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                                <span mat-button class="text">View</span>
                                            </button> -->
                                            <button mat-menu-item (click)="openDialog(expenseReimbursement.expenseReimbursementId)">
                                                <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                                <span mat-button class="text">View</span>
                                            </button>
                                            <!-- <button *ngIf="expenseReimbursement.approval != undefined" mat-menu-item>
                                                <div *ngIf="expenseReimbursement.draft == true" 
                                                (click)="updateexpensereburisment(expenseReimbursement.expenseReimbursementId)">
                                                    <mat-icon class="material-icons-round edit-icon">edit</mat-icon>
                                                    <span class="text">Edit</span>
                                                </div>
                                            </button> -->
                                            <button [disabled]="expenseReimbursement.approvalStatus" mat-menu-item>
                                                <div (click)="updateexpensereburisment(expenseReimbursement.expenseReimbursementId)">
                                                    <mat-icon class="material-icons-round edit-icon" >edit</mat-icon>
                                                    <span class="text">Edit</span>
                                                </div>
                                            </button>
                                            <!-- <ng-container *ngIf="expenseReimbursement?.approvalStatus?.listTypeValueName ==='Approved' || expenseReimbursement.approvalStatus==undefined; else head"> -->
                                                <!-- <button mat-menu-item *ngIf="(expenseReimbursement?.approvalStatus?.listTypeValueName ==='Approved' )  &&
                                                 expenseReimbursement.paymentAdvice==undefined "> -->
                                                 <button mat-menu-item *ngIf="(expenseReimbursement?.approvalStatus?.listTypeValueName === 'Approved') 
                                                 && expenseReimbursement.paymentAdvice == undefined 
                                                 && expenseReimbursement.totalReimbursement !== 0">
                                                    <div (click)="addexpensereburisment(expenseReimbursement.expenseReimbursementId)">
                                                        <mat-icon class="material-icons-outlined viewicon">add</mat-icon>
                                                        <span class="text">Payment</span>
                                                    </div>
                                                </button>
                                            <!-- </ng-container> -->
                                            <!-- <ng-template #head > -->
                                                <button mat-menu-item *ngIf="expenseReimbursement?.paymentAdvice!=undefined">
                                                   <div (click)="updatePaymentAdvice(expenseReimbursement.expenseReimbursementId)" >
                                                       <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                                       <span class="text">Payment</span>
                                                   </div>
                                                </button>
                                            <!-- </ng-template> -->
                                            <!-- <ng-container *ngIf="expenseReimbursement?.paymentAdvice==undefined ">
                                                <button mat-menu-item [disabled]="expenseReimbursement?.approvals?.approvalStatus?.listTypeValueName === 'Approved'">
                                                    
                                                    <div (click)="addexpensereburisment(expenseReimbursement.expenseReimbursementId)">
                                                        <mat-icon class="material-icons-outlined viewicon">add</mat-icon>
                                                        <span class="text">Payment</span>
                                                    </div>
                                                </button>
                                            </ng-container> -->
                                            <!-- <ng-container *ngIf="expenseReimbursement.paymentAdvice == undefined && expenseReimbursement.approvalStatus == undefined">
                                                <button mat-menu-item [disabled]="expenseReimbursement.approvalStatus?.listTypeValueName === 'Approved'">
                                                    <div (click)="addexpensereburisment(expenseReimbursement.expenseReimbursementId)">
                                                        <mat-icon class="material-icons-outlined viewicon">add</mat-icon>
                                                        <span class="text">Payment</span>
                                                    </div>
                                                </button>
                                            </ng-container> -->
                                            
                                             <!-- <ng-template *ngIf="expenseReimbursement?.paymentAdvice !== undefined">
                                                 <button mat-menu-item *ngIf="paymentStatus && expenseReimbursement?.approvals?.approvalStatus?.listTypeValueId === 5 " >
                                                    <div (click)="updatePaymentAdvice(expenseReimbursement.expenseReimbursementId)">
                                                        <mat-icon class="material-symbols-outlined viewicon"  >visibility</mat-icon>
                                                        <span class="text">Payment</span>
                                                    </div>
                                                 </button>
                                             </ng-template> -->
                                        
                                            
                                             <!-- <ng-container >
                                                <ng-template *ngIf="paymentStatus && expenseReimbursement?.approvals?.approvalStatus?.listTypeValueId === 5">
                                                    <button mat-menu-item (click)="updatePaymentAdvice(expenseReimbursement.expenseReimbursementId)">
                                                        <div>
                                                            <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                                            <span class="text">Payment</span>
                                                        </div>
                                                    </button>
                                                </ng-template>
                                            </ng-container> -->
<!--                                             
                                             <ng-container *ngIf="expenseReimbursement?.paymentAdvice === undefined && expenseReimbursement?.approvals?.approvalStatus !==undefined">
                           <ng-template *ngIf="expenseReimbursement?.approvals?.approvalStatus?.listTypeValueName === 'Approved'" >
                             <button mat-menu-item [disabled]="expenseReimbursement?.approvals?.approvalStatus?.listTypeValueName === 'Approved'"
                                     (click)="addexpensereburisment(expenseReimbursement.expenseReimbursementId)">
                                 <mat-icon class="material-icons-outlined viewicon">add</mat-icon>
                                 <span class="text">Payment</span>
                             </button>
                            </ng-template> 
                         </ng-container> -->
                         <!-- <p>Approval Status: {{ expenseReimbursement.approvalStatus | json }}</p> -->

                         <!-- <ng-template #head>
                             <button mat-menu-item *ngIf="paymentStatus"
                                     (click)="updatePaymentAdvice(expenseReimbursement.expenseReimbursementId)">
                                 <mat-icon class="material-symbols-outlined viewicon">visibility</mat-icon>
                                 <span class="text">Payment</span>
                             </button>

                         </ng-template> -->
                         
                                            
                                        </mat-menu>
                                    </td>
                                </tr>
                                </tbody>
                               
                                <!-- <tr *ngIf="(expenseReimbursementListFiltered | mytask : search).length === 0">
                                    <td colspan="9" class="searchnodataDesign" style="padding: 11px 12px 14px 12px;
                                    font-size: 19px;
                                    font-weight: 600;
                                    font-family: 'Mulish', sans-serif;
                                    text-align: center;"> Search Expense Reimbursement  Not found</td>
                                  </tr> -->

                                  <tbody style="border-left: 1px solid #ddd; border-right: 1px solid #ddd" *ngIf="tableShow &&   (noData==true ||
                                          expenseReimbursementListFiltered.length == 0)">
                                    <tr>
                                      <td
                                        style="
                                          width: 100%;
                                          position: relative;

                                          text-align: center;
                        padding: 12px;
                        font-size: 19px;
                        font-weight: 600;
                        font-family: 'Mulish', sans-serif;
                        position: relative;
                                        "
                                        colspan="9"
                                       
                                      >
                                      Expense Reimbursement Records Not Found!
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tr *ngIf="(expenseReimbursementListFiltered | mytask : search).length === 0 && search" style="border: 1px solid #ddd;">
                                    <td style="   width: 100%;
                                    text-align: center;
                                    font-size: 19px;
                                    font-weight: 600;
                                    position: relative;
                                    padding:12px;" colspan="9" >Search Expense Reimbursement Records Not Found!</td>
            
                                  </tr>
                              
                            <!-- </tbody> -->
                          
                        </table> 
                    </div> 
                    <!-- <div *ngIf="nodata">
                        <div class="no-data card text-center">
                            <div class="card-body">No Data</div> 
                        </div>
                    </div>   -->
                    <!-- <mat-card class="card-content-nodata " >
                        <div class="card-body-nodata">
                          <div class="alert-nodata alert-primary">
                            <h4 align="center" class="h4" style="  
                            font-size: 24px;
                            font-family: Roboto, Helvetica Neue, sans-serif;
                           
                            ">Expense Reiumbursement Not found </h4>
                          </div>
                        </div>
                      </mat-card>
                                                -->
                                               
                </div>
            </div>
        </div>
      
        <!-- <div class="row topnav-right pagination-content" style="padding-top:25px">         -->
            <div class="col-md-12" style="margin-top: 10px;">
                <pagination-controls class="float-right pagination" 
                style="float: right;" *ngIf="expenseReimbursementListFiltered?.length > 5 &&  (expenseReimbursementListFiltered | mytask : search).length > 0"
                 (pageChange)="p = $event"></pagination-controls>  
            </div> 
        </div> 
    <!-- </div>  -->
<!-- </mat-card> -->
<div *ngIf="list_loader" class="align-items-center text-center">
    <div class="spinner-border" 
    style=" width: 3rem;
    height: 3rem;
    color: #1a83ff;
    position: relative;
    bottom: 0;
    align-items: center;
    margin-left: 90%;
    top: 175px;" role="status"></div>
</div>    
<!-- </div> -->
<!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="noData==true">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        <h4 align="center" class="h4" style="    margin-top: 14px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 11px;
        ">Expense Reiumbursement Not found</h4>
      </div>
    </div>
  </mat-card> -->