import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-manage-elements',
  templateUrl: './select-manage-elements.component.html',
  styleUrls: ['./select-manage-elements.component.scss']
})
export class SelectManageElementsComponent implements OnInit {

  selectedType: string = '';
  usertData: any;
  clientLogin: boolean = false;
  organizationLogin: boolean = false;
  entityAdminLogin: boolean = false;
  storeClientObj:any
  constructor() { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData') || '{}');
    console.log('this.usertData', this.usertData);
    this.storeClientObj=this.usertData?.employee?.client

    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;
      this.selectedType = 'organization'; // Auto-patch value
      console.log('this.organizationLogin', this.organizationLogin);
    } else if ( this.storeClientObj) {
      this.clientLogin = true;
      this.selectedType = 'client'; // Auto-patch value
      console.log('this.clientLogin', this.clientLogin);
    } else {
      this.entityAdminLogin = true;
      this.selectedType = ''; // No auto-patch
    }
  }

  clearAllForm() {
    this.selectedType = '';


  }

  selected(event: any) {
    this.selectedType = event.value;


  }

}
