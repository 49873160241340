<section>
    <div class="sub-header">
        <!-- <div class="col-md-12 mb-3">
            <div class="col-md-12">
                <div class="col-md-6">
                    <span class="h5 sub-content">Employee Working Days</span>
                </div>
                <div class="sub-header-buttons col-md-2">
                    <a  (click)="importEmployee()" mat-raised-button class="ml-2" color="primary" title="Import">
                        Import
                    </a>
                </div>
                <div class="sub-header-buttons">
                    <button mat-raised-button color="primary" class="" (click)="addWorkingdays()">Add Employee Working Days</button>
                </div>
            </div>
        </div> -->
        <div class="col-md-12  mb-3">
            <div class="row">
              <!-- Left column -->
              <div class="col-md-6">
                <span class="h5 sub-content">Employee Working Days</span>
              </div>
          
              <!-- Right column -->
              <div class="col-md-6 d-flex justify-content-end">
                <div class="sub-header-buttons">
                  <a (click)="importEmployee()" mat-raised-button class="ml-2 mr-2" color="primary" title="Import">
                    Import
                  </a>
                  <button mat-raised-button color="primary" class="" (click)="addWorkingdays()">Add Employee Working Days</button>
                </div>
              </div>
            </div>
        </div>
          
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Client</label> 
                                <select formControlName="client" (change)="getclientEmployees()"  class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null"> --- Select Client --- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select>    
                                <!-- <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                    <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                     <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  
                                </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                    search
                                </mat-icon> -->
                                <label class="col-form-label">Employee<span class="star">*</span></label>
                                <input type="text"matInput  placeholder="Search Employee"   
                                            formControlName="employeecode"
                                            [matAutocomplete]="auto"  [readonly]="!filterForm.value.client || filterForm.value.client == 'null'"
                                             class="example-input form-control" (keyup)="empSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                            </mat-option> -->
                                        <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employeeNumber}}- {{emp.firstName}}  
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:51px;right: 19px;">
                                        search
                                    </mat-icon>
                                <!-- <div class="valid-msg" *ngIf="submitted">        
                                    <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                                </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Financial year</label> 
                                <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                    <option [value]="null"> -- Select Financial Year --- </option>
                                    <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Financial Period</label> 
                                <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]="null"> -- Select Period --- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>
                            </div>
                        
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button>
                                    <button style="margin-left: 10px;" type="reset" mat-raised-button
                                     class="btn search-btn mr-2"
                                    (click)="clearTable()">Clear</button>
                            </div>
                        </div>
                    </form>
                    <div class="row pb-3">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left pl-0">
                                <select [(ngModel)]="pagePerItem"  (change)="pageChages($event)" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                    </div>
                       
                       <div class="table-responsive">
                        <p><b>EMPLOYEE WORKINGDAYS</b></p>
                        <table class="table  --table-bordered table-border-new" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                            <thead class="t-head">
                                <tr>
                                    <th>Action</th>
                                    <th >Financial Year</th>
                                    <th>Period</th>
                                    <th>Employee Code</th>
                                    <th>Employee Name</th>
                                    <th>Working Days</th>
                                    <th>OT Hours</th>
                                    <th>Arrear Days</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="resultData.length>0">
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item routerLink="/home/employeeworkdays/{{row.employeeWorkingDaysId}}">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                            <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button> -->
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==1" (click)="updateStatus(row.employeeWorkingDaysId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==2" (click)="updateStatus(row.employeeWorkingDaysId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button> 
                                        </mat-menu>
                                    </td>
                                    <td>{{row.financialPeriod.finanicalYear.financialFromMonth}}-{{row.financialPeriod.finanicalYear.financialFromYear}} To {{row.financialPeriod.finanicalYear.financialToMonth}}-{{row.financialPeriod.finanicalYear.financialToYear}}</td> 
                                    <td>{{row.financialPeriod.financialperiodMonth}}-{{row.financialPeriod.financialPeriodYear}}</td>
                                    <td>{{row.employee.employeeNumber}}</td>
                                    <td>{{row.employee.firstName}}</td>
                                    <td style="text-align: center;">{{row.workingDays}}</td>
                                    <td style="text-align: center;" >{{row.otHours}}</td>
                                    <td style="text-align: center;" >{{row.arrearDays}}</td>
                                    <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId==1">
                                            {{row.status.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId==2">
                                            {{row.status.listTypeValueName}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="7" *ngIf="noRecordsFound">No Records Found!</td>
                                </tr> 
                            </tbody> 
                        </table>
                     </div>
                    
                     <div>
                        <div class="row">
                            <div class="col-md-4" *ngIf="!this.noRecordsFound">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event" *ngIf="!this.noRecordsFound"></pagination-controls>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>