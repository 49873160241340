import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, Validators, FormBuilder } from '@angular/forms';
import { RecruitmentService } from '../providers/recruitment.service';
import { PayrollService } from 'src/app/providers/payroll.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { SettingsService } from 'src/app/providers/settings.service';
import { responseMsgTimeOut } from '../providers/properties'
import { Router, ActivatedRoute } from '@angular/router';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowErrorOfWorkingdaysComponent } from '../show-error-of-workingdays/show-error-of-workingdays.component';
import { DatePipe } from '@angular/common';
import { SelectionChange } from '@angular/cdk/collections';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-client-workings',
  templateUrl: './client-workings.component.html',
  styleUrls: ['./client-workings.component.scss']
})
export class ClientWorkingsComponent implements OnInit {

  loader: any = false;
  isProcessing:boolean= false;
  isprocessing1:boolean= false;
  clientworkingdaysForm: FormGroup;
  filterForm: FormGroup;
  clientdata: any = {};
  usertData: any;
  dateFormat: any;
  orgId: number;
  noRecordsFound: boolean = false;
  clientList: any;
  error: any = false;
  resultData: any = [];
  submitted: boolean = false;
  errormessagejson: any;
  success = false;
  beforesearchpage:any
  sucess_msg: any;
  error_msg: any;
  p: number = 1;
  tableSearch: any;
  errorMsg: any;
  finYearList: any = [];
  pagePerItem: any = 5;
  chooseFinId: any;
  finPeriodList: any = [];
  importerror: boolean = false;
  orgWorkingDaysId: number;
  filterData: any = [];
  chooseFinancialyearId: any;
  financialPeriodList: any[];
  perId: any;
  staus: any;
  showtable: boolean = false;
  clientWorkingDaysId: any;
  entityId:number;
  clientId:number;
  clientLogin: boolean = false;
  organizationLogin: boolean = false;
  entityAdminLogin: boolean = false;
  employeetData: any;
  clientNames:any;
  setDisable:boolean=true;
  financialYearId:any;
  paginationSet:boolean=false;
  constructor(
    private recruitmentservice: RecruitmentService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private settingsService: SettingsService,
    private payrollservice: PayrollService,
    private datepipe: DatePipe,
    private ActivatedRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log('this.usertData', this.usertData);
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log('this.clientId',this.clientId);
    this.clientNames=this.usertData?.employee?.client?.clientName;
    
    this.entityId = this.usertData.entity.entityId;
    console.log(this.orgId);

    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;

      console.log('this.organizationLogin', this.organizationLogin);
    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;
     console.log('this.clientLogin', this.clientLogin);
     this.clientId = this.usertData?.employee?.client?.clientId;
     console.log('this.clientId',this.clientId);
    } else {
      this.entityAdminLogin = true;

    }

    this.filterForm = this.fb.group({
      // filterclient: [null],
      filterclient: [{ value: null, disabled: false }], // Initially enabled

      filterfinancialyear: [null],
      filterperiod: [null],
    })

    this.clientworkingdaysForm = this.fb.group({
      clientName: [null, [Validators.required]],
      financialyear: [null, [Validators.required]],
      period: [null, [Validators.required]],
      // workingdays:[null,[Validators.required,Validators.pattern(/^(0\.5|[1-9]\d{0,1}(\.\d)?)$/)]]
      workingdays: [null, [Validators.required, Validators.pattern(/^(0\.5|[1-9](\.0|\.5)?|[1-2][0-9](\.0|\.5)?|30(\.0)?)$/
      )]],
      paymentdate: [null]

    })


    this.payrollservice.getClientNameListBasedEntity(this.entityId).subscribe((result: any) => {
      this.clientList = result.filter(client => client?.status?.listTypeValueId == 1);
      this.clientList = Array.isArray(result) ? result: [result];
      console.log(this.clientList);
    })

    this.settingsService.getClientFinancialYear(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
      this.finYearList = Array.isArray(resultData) ? resultData: [resultData];

    });

    if(this.clientLogin == true){
      this.filterForm.get('filterclient')?.patchValue(this.clientNames);
      this.filterForm.get('filterclient')?.disable();
    }
    if(this.clientLogin == true){
      this.clientworkingdaysForm.get('clientName')?.patchValue(this.clientNames);
      this.clientworkingdaysForm.get('clientName')?.disable();
    }

    this.ActivatedRoute.paramMap.subscribe(params => {


      console.log('params', params);
      this.financialYearId = params.get('financialYearId');
      console.log('this.legalentityId', this.financialYearId);

    })

  }

  clientNameChange(event: MatSelectChange) {
    console.log('event', event.value);

  }
  resetForm() {
    console.log("reset came!!!")
    const currentClientName =this.clientNames; 
    console.log("currentClientName",currentClientName);
    
  
    this.clientworkingdaysForm.patchValue({
      financialyear: null,
      clientName:currentClientName,
      period:null,
      workingdays:null
    });
    this.clientworkingdaysForm.get('filterclient')?.disable();

  }

  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  blockDrop(event: DragEvent) {
    event.preventDefault();
  }

  notZeroValidatorAsync(control: AbstractControl) {
    const value = control.value;
    const regex = /^(?:\d\.\d{1,2}|\d{1})$/;
    return new Promise((resolve) => {
      if (!regex.test(value.toString())) {
        console.log("Not Vaild");
        resolve({ notZero: true }); // Return an object with a custom error key if value is zero
      } else {
        console.log("Vaild");
        resolve(null); // Return null if the value is not zero, indicating the control is valid
      }
    });
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getorworkingdayslist() {
    this.loader = true;
    this.payrollservice.getorgWorkingDaysbyorganList(this.orgId).subscribe((filterData: any) => {
      this.noRecordsFound = false;
      this.filterData = filterData;
      console.log(this.filterData);
      if (this.filterData.length == 0 || this.filterData.length == undefined) {
        this.noRecordsFound = true;
      }
      if (this.filterData.statusCode == 204) {
        this.noRecordsFound = true;
      }
      this.loader = false;
    })
  }

  exportToExcel() {
    console.log("Export");
  }

  searchSubmit()
  {
    // this.filterData=[];
    this.p=1
    console.log("Submit",this.filterForm.value);
    const currentClientName = this.clientNames;

    this.filterForm.patchValue({
      
      filterclient: currentClientName,
      
    });
    if (!this.filterForm.value.filterperiod) {
      this.filterForm.patchValue({ filterperiod: null });
    }
    console.log("period::",this.filterForm.value.filterperiod)

    if(this.clientId===null&&this.filterForm.value.filterfinancialyear===null&&this.filterForm.value.filterperiod===null)
    {
      alert("Please select any of the fields.");
      this.filterData=[];
              this.showtable=false;
                      this.noRecordsFound = false;
                      this.loader=false;


      // this.noRecordsFound = true;
      return;
    }

    if(this.clientId!=null&&this.filterForm.value.filterfinancialyear!=null&&this.filterForm.value.filterperiod==null)
      {
        alert("Please select period as well");
        this.filterData=[];
        this.noRecordsFound = false;
        this.showtable=false;
        this.loader=false;

        return;
      }

    if(this.clientId!=null&&this.filterForm.value.filterfinancialyear===null&&this.filterForm.value.filterperiod===null)
    {
      console.log("ClientID");
      this.isProcessing = true; 
      this.loader =  true;
      this.paginationSet=false;    
        this.payrollservice.getClientList(this.clientId).subscribe((filterData:any) =>
      {
        this.noRecordsFound = false;
        this.filterData = filterData;
        this.paginationSet=true;    
        this.isProcessing = false; 

        this.showtable = true;
        this.loader =  false;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    // else if(this.clientId===null&&this.filterForm.value.filterfinancialyear!=null&&this.filterForm.value.filterperiod===null)
    // {
    //   console.log("Finalcial Year only");
    //   this.loader =  true;
    //   this.payrollservice.getclientworkingdaysYearList(this.filterForm.value.filterfinancialyear,this.clientId).subscribe((filterData:any) =>
    //   {
    //     this.noRecordsFound = false;
    //     this.filterData = filterData;
    //     this.showtable = true;
    //     this.loader =  false;
    //     console.log(this.filterData);
    //     if(this.filterData.length == 0 || this.filterData.length == undefined)
    //     {
    //       this.noRecordsFound = true;
    //     }
    //     if(this.filterData.statusCode == 204){
    //       this.noRecordsFound = true;
    //     }
    //   })
    // }
    else if(this.clientId===null&&this.filterForm.value.filterfinancialyear!=null&&this.filterForm.value.filterperiod!=null)
    {
      console.log("Finalcial and Period");
      this.isProcessing = true; 
      this.loader =  true;
      this.paginationSet=false;
            this.payrollservice.getFinanicalandPeriodList(this.filterForm.value.filterperiod,this.clientId).subscribe((filterData:any) =>
      {
        this.noRecordsFound = false;
        this.filterData = filterData;
        this.showtable = true;
        this.isProcessing = false; 

        this.paginationSet=true;
        this.loader =  false;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.filterclient !== "null" && this.filterForm.value.filterclient !== null) &&
    (this.filterForm.value.filterfinancialyear !== "null" && this.filterForm.value.filterfinancialyear !== null) &&
    (this.filterForm.value.filterperiod !== "null" && this.filterForm.value.filterperiod !== null)
    )
    {
      console.log("Both");
      this.isProcessing = true; 
      this.loader =  true;
      this.paginationSet=false;
            this.payrollservice.getClientandFinperiodList(this.clientId,this.filterForm.value.filterfinancialyear,this.filterForm.value.filterperiod,this.clientId).subscribe((filterData:any) =>
      {
        this.noRecordsFound = false;
        this.filterData = filterData;
        this.showtable = true;
        this.loader =  false;
        this.isProcessing = false; 

        this.paginationSet=true;


        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
  }

  selectFilterFinancialyear() {
    // console.log("Select");
    // // console.log(event.target.value);
    // // console.log(this.filterForm.value.financialyear);
    // this.filterForm.get('filterfinancialyear').value;
    // this.chooseFinancialyearId = this.filterForm.get('filterfinancialyear').value;

    // console.log(this.chooseFinancialyearId);
    // if (this.chooseFinancialyearId == null || this.chooseFinancialyearId == 'null') {
    //   console.log('if');
    //   this.financialPeriodList = [];
    // } else {
    //   this.settingsService.getFinancialYearId(this.chooseFinancialyearId).subscribe((resultData: any) => {
    //     console.log("PeriodData", resultData)
    //     this.financialPeriodList = resultData.finanicalPeriod;
    //     this.financialPeriodList = Array.isArray(resultData) ? resultData: [resultData];
    //     this.filterForm.patchValue({
    //       filterperiod: null,
    //     });
    //   })
    // }
    console.log("Select indidual Employee");
    // console.log(event.target.value);
    this.filterForm.get('filterfinancialyear').value;
    // console.log(this.filterForm.value.financialyear);
    this.chooseFinId = this.filterForm.get('filterfinancialyear').value;
    console.log(this.chooseFinId);
    if (this.chooseFinId == null || this.chooseFinId == 'null') {
      console.log('if');
      this.financialPeriodList = [];
    }
    else {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.financialPeriodList = resultData.finanicalPeriod;
        console.log("finPeriodList", this.financialPeriodList)
     
      })
    }
  }
  onSubmit() {
    // this.submitted = true;
    this.isprocessing1 = true;
    if (this.clientworkingdaysForm.invalid) {
      console.log("form invaild");
      this.isprocessing1 = false;
      return;
    }
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    let clientId=this.employeetData?.employee?.client?.clientId
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;
    let clientDetailStore=clientId?{clientId:clientId}:null;
    console.log("submit", this.clientworkingdaysForm.value);
    console.log(this.clientWorkingDaysId);
    if (this.clientWorkingDaysId) {
      console.log("Update" + this.clientWorkingDaysId);
      const updateWorkingDays =
      {
        clientWorkingDaysId: this.clientWorkingDaysId,
        workingDays: this.clientworkingdaysForm.value.workingdays,
        // client:
        // {
        //   clientId: this.clientworkingdaysForm.value.clientName
        // },
        financialPeriod:
        {
          financialPeriodId: this.clientworkingdaysForm.value.period
        },
        // organization:
        // {
        //   organizationId: this.orgId
        // },
        client:clientDetailStore,
        status: {
          listTypeValueId: this.staus
        },
        // leaveDays : this.clientworkingdaysForm.value.leavedays,
        // datePaid: this.datepipe.transform(this.clientworkingdaysForm.value.paymentdate, "yyyy-MM-dd")
      }
      console.log(updateWorkingDays);
      this.payrollservice.updateClientWorkingDays(this.clientWorkingDaysId, updateWorkingDays).subscribe((result: any) => {
        if (Array.isArray(result)) {
          result.forEach((descript: any) => {
            if (descript.statusCode === 409) {
              this.error = true;
              this.error_msg = descript.description;
              this.loader = false;
              this.isprocessing1 = false;
              setTimeout(() => {
                this.error = false;
              }, 5000);
            }
          });
        } else {
          // Handle result as an object
          if (result?.statusCode === 409) {
            this.error = true;
            this.error_msg = result.description;
            this.loader = false;
            this.isprocessing1 = false;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          }
        }
        const currentClientName = this.clientNames;


        this.clientworkingdaysForm.reset();
  
        // Reapply values for specific fields (e.g., organizationName)
        this.clientworkingdaysForm.patchValue({
          clientName: currentClientName,
        });

       
      
        
        if (result.statusCode === 200) {
          this.success = true;
          this.clientWorkingDaysId = 0;
          this.loader=false;
          this.sucess_msg = result.description;

          // if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
          //  ((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
          // {
          //   console.log("Filter Call Method",this.filterForm.value);
          //   this.searchSubmit();
          // }
          this.clientworkingdaysForm.controls['period']?.patchValue('');
          this.clientworkingdaysForm.get('financialyear')?.patchValue('')
          this.clientworkingdaysForm.get('workingdays')?.patchValue('')
          // if ((this.filterForm.value.filterclient == null) && (this.filterForm.value.filterfinancialyear == null) && (this.filterForm.value.filterperiod == null)) {
          //   console.log("Without", this.filterForm.value);
          //   // this.searchSubmit();
          // }
          // else {
          //   console.log("Filter Call Method", this.filterForm.value);
          //   this.searchSubmit();
          // }
          // if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
          // ((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
          // {
          //   this.searchSubmit();
          // }
          if((this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!=null))
            {
              console.log("Without",this.filterForm.value);
              this.searchSubmit();
            }
            else{
              console.log("Filter Call Method",this.filterForm.value);
              // this.searchSubmit();
            }
          if((this.filterForm.value.filterclient==null || this.filterForm.value.filterclient== 'null' )){
            console.log("Without",this.filterForm.value);

          }
          setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
          // this.clientupdateId = null;
          // this.clientworkingdaysForm.reset();
          this.submitted = false;
          // setTimeout(() => {
          //   this.router.navigate(["home/employee-rating-list"])
          // }, redirectMsgTimeOut)
        }
        // else if(result.statusCode == 409)
        // {
        //   console.log("Already Exist");
        // this.importerror = true;
        // // this.error = true;
        // this.error_msg = result.description;
        // this.errormessagejson = result;
        // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
        else {
          console.log("err1", result.statusCode);
          this.error = true;
          this.importerror = true;
          this.errormessagejson = result;
          // console.log('this.errormessagejson',this.errormessagejson);
          //  this.error_msg = result.description;
          //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }
        this.loader=false;
        this.isprocessing1 = false; // Disable the button
      },
        err => {
          console.log("code1", err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut)
          this.loader=false;
          this.isprocessing1 = false; // Disable the button

        })
    }
    else {
      console.log("create");
      const createWorkingDays =
      {
        workingDays: this.clientworkingdaysForm.value.workingdays,
        // client:
        // {
        //   clientId: this.clientworkingdaysForm.value.clientName
        // },
        financialPeriod:
        {
          financialPeriodId: this.clientworkingdaysForm.value.period
        },
        // organization:
        // {
        //   organizationId: this.orgId
        // },
        client:clientDetailStore,

        // leaveDays : this.clientworkingdaysForm.value.leavedays,
        // datePaid: this.datepipe.transform(this.clientworkingdaysForm.value.paymentdate, "yyyy-MM-dd")

      }
      console.log(createWorkingDays);
      this.payrollservice.createClientWorkingDays(createWorkingDays).subscribe((result: any) => {
        console.log("Create" , result);
        console.log("Create",result);

        if (Array.isArray(result)) {
          result.forEach((descript: any) => {
            if (descript.statusCode === 409) {
              this.error = true;
              this.error_msg = descript.description;
              this.loader = false;
              this.isprocessing1 = false;
              setTimeout(() => {
                this.error = false;
              }, 5000);
            }
          });
        } else {
          // Handle result as an object
          if (result?.statusCode === 409) {
            this.error = true;
            this.error_msg = result.description;
            this.loader = false;
            this.isprocessing1 = false;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          }
        }
        const currentClientName = this.clientNames;
  
        //  this.orgWorkingDaysForm.patchValue({
                
        //   financialyear: null,
        //   organizationName:currentClientName,
        //   period:null,
        //   workingdays:null              
        // });
        // this.orgWorkingDaysForm.markAsUntouched();
        this.clientworkingdaysForm.reset();
  
        // Reapply values for specific fields (e.g., organizationName)
        this.clientworkingdaysForm.patchValue({
          clientName: currentClientName,
        });
      
  
       if(result.statusCode ===200)
       {
         this.success = true;
         this.loader=false;
         this.sucess_msg = result.description; 
         
        // Update validity and mark as pristine to clear validation messages
        this.clientworkingdaysForm.updateValueAndValidity();
        this.clientworkingdaysForm.markAsPristine();
        this.clientworkingdaysForm.markAsUntouched();
        //  if((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)||(this.filterForm.value.filterfinancialyear=="null"||(this.filterForm.value.filterfinancialyear==null))||((this.filterForm.value.filterfinancialyear=="null")||(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null))||
        //  ((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null)))
        //  {
        //    console.log("Filter Call Method",this.filterForm.value);
        //   //  this.searchSubmit();
        //  }
        // if((this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null))
        // {
        //   console.log("Without",this.filterForm.value);
        //   // this.searchSubmit();
        // }

        // else if((this.filterForm.value.filterclient!==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null)){
        //   console.log("client onlt Method",this.filterForm.value);
        //   this.searchSubmit();
        // }
        // else if((this.filterForm.value.filterclient!==null)&&(this.filterForm.value.filterfinancialyear!==null)&&(this.filterForm.value.filterperiod!==null)){
        //   console.log("all fields are filled" );
          
        // }
        // if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
        // ((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)&&(this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
        // {
        //   this.searchSubmit();
        // }
        if(this.filterForm.value.filterfinancialyear!=null && this.filterForm.value.filterperiod !=null){
          this.searchSubmit();

 }
        if((this.filterForm.value.filterclient==null || this.filterForm.value.filterclient== 'null' )){
          console.log("Without",this.filterForm.value);

        }
         setTimeout(() => {this.success  = false;
          this.submitted=false;
        
          
  
          
         },redirectMsgTimeOut)
        //  this.orgWorkingDaysForm.reset(); 
        }
        else
        {
          console.log("err1",result.statusCode);
         //  console.log("err1",result.clientCode);
          this.error = true;
           this.importerror = true;
          this.errormessagejson = result;
         //  console.log('this.errormessagejson',this.errormessagejson);
         //  this.error_msg = result.description;
         //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }
        this.loader=false;
        this.isprocessing1 = false; // Disable the button
   
      }, 
      err =>
      {
        console.log("code",err);
        console.log("code",err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut) ;
          this.loader=false;
          this.isprocessing1 = false; // Disable the button
   
      })
         
       }
       

  }

  updateform(row)
   {
    // this.clearForm();
    console.log("clear Form",row);
    this.clientWorkingDaysId=row.clientWorkingDaysId;
    console.log(this.clientWorkingDaysId);
    this.payrollservice.getClientworkingDaysList(this.clientWorkingDaysId,this.clientId).subscribe((resultData:any) =>
    {
      this.loader=true;
      console.log("Api1",resultData);
      this.resultData=resultData;
      this.staus=resultData.status.listTypeValueId;
      console.log(this.staus);
      console.log(this.resultData);
      console.log(this.clientworkingdaysForm);

             console.log("this.clientworkingdaysForm 1", this.clientworkingdaysForm.value);
             this.settingsService.getFinancialYearId(this.resultData.financialPeriod.finanicalYear.financialYearId).subscribe(
            (resultData1: any) => {
                    this.loader=false;

              console.log("resultData1", resultData1.finanicalPeriod);
              this.finPeriodList = resultData1.finanicalPeriod;
              console.log("resultData2", this.finPeriodList);
              this.perId=this.resultData.financialPeriod.financialPeriodId;
              console.log("perId", this.perId);
              // this.clientworkingdaysForm.get('period').patchValue(this.resultData.financialPeriod.financialPeriodId); 
              console.log("patchvalue");
              setTimeout(() => {
                this.clientworkingdaysForm.patchValue({
                  clientName : this.clientNames,
                  financialyear : this.resultData.financialPeriod.finanicalYear.financialYearId,
                  period : this.resultData.financialPeriod.financialPeriodId, 
                  workingdays : this.resultData.workingDays,
                  // leavedays : this.resultData.leaveDays,
                  paymentdate : this.resultData.datePaid
                });
              },50);
              console.log("this.clientworkingdaysForm 2", this.clientworkingdaysForm.value);
            });
    })
   }

  orgWorkingDaysIdage() {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      width: '500px', height: 'fit-content', data: this.errormessagejson
    });
    this.error = false;
  }

  selectFinancialyear() {
    console.log("Select indidual Employee");
    // console.log(event.target.value);
    this.clientworkingdaysForm.get('financialyear').value;
    // console.log(this.filterForm.value.financialyear);
    this.chooseFinId = this.clientworkingdaysForm.get('financialyear').value;
    console.log(this.chooseFinId);
    if (this.chooseFinId == null || this.chooseFinId == 'null') {
      console.log('if');
      this.finPeriodList = [];
    }
    else {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
        console.log("finPeriodList", this.finPeriodList)
        this.clientworkingdaysForm.patchValue({
          period: null,
        });
      })
      console.log("Inside selectFinancialyear func", this.clientworkingdaysForm);
    }
  }

  clearTable() {
    // this.isProcessing=true;
    // this.resultData = [];
    // this.noRecordsFound = false;
    // this.filterData = [];
    // this.noRecordsFound = true;
    console.log('clear');
    const currentClientName = this.clientNames;

    // Reset the entire form while preserving "filterclient"
    this.filterForm.patchValue({
      filterfinancialyear: null, 
      filterclient: currentClientName,
      filterperiod:null
    });
  
  
    // Re-disable the Client Name field
    this.filterForm.get('filterclient')?.disable();
  
    // Clear other variables
    this.finPeriodList = [];
    this.financialPeriodList = [];
    this.showtable = false;
  
    this.setDisable = true;
  }

  // clearForm() {
  //   this.clientworkingdaysForm.reset(); // This resets all form controls to their initial values
  // }

  pageChages() {
    console.log("page changes");
    this.p = 1;
  }

  get validate() {
    return this.clientworkingdaysForm.controls;
  }

  showerrormessage() {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorOfWorkingdaysComponent, {
      //height: 'auto',width: '600px',
      width: '500px', height: 'fit-content', data: this.errormessagejson
    });
    this.error = false;
  }
  updateStatus(id,status)
	{
    this.payrollservice.deactiveWorkingDays(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}


}
