<div style="margin-top: 80px" class="container">
  <div class="container-list" style="color: #696969">
    <div class="float-left">
      <h6 class="report">
        {{ projectList.projectName }}
      </h6>
      <div class="list-inline-item">
        <button
          mat-icon-button
          color="warn"
          aria-label="Example icon button with a home icon"
          routerLink="/home/projects"
        >
          <span class="material-icons" style="color: #696969">
            arrow_back
          </span>
        </button>
      </div>
    </div>
    <div class="float-right">
      <h6 class="report">
        {{ projectList.projectTypeName }} Project <br /><br />
        {{ projectList.projectStartDate | date }} to
        {{ projectList.projectEndDate | date }}
      </h6>
    </div>
  </div>
  <div>
    <!-- <ng-container *ngIf="projectList.employeeProjects != '';else elseTemplate"> -->
    <!-- <mat-card class="col-md-11">
  
       <div class="float-left" style="color: white;">
         <h4>Project List</h4>
       </div>
       <div class="float-right">
         <div class="row">
           <div class="col-sm-12">
           </div>
         </div>
       </div>
     </mat-card> -->
    <!-- *ngIf="projectList?.employeeProjects?.length > 0" -->
    <div class="col-md-12 card" style="background-color: none"  *ngIf="tableShow">
      <div class="table-responsive" style="height: 100%">
        <!-- *ngIf="projectList.employeeProjects.length > 5" -->
        <div class="row p-0 m-0">
          <div class="col-xl-12 col-lg-12">
            <div class="select-pagination"  *ngIf="projectList.length > 5">
              <span>Show : </span>
              <select
                class="select_list new-select_list"
                [(ngModel)]="mySelect"
                (change)="changefirstpage()"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>
        <table
          style="margin-top: 10px; width: 100%"
          class="table table-hover"
          matSort
          (matSortChange)="sortData($event)"
        >
          <thead>
            <tr class="tr" id="trDesign">
              <th class="text-center">S.No</th>
              <th mat-sort-header="employeeNumber" class="text-center">
                Employee&nbsp;No
              </th>
              <th mat-sort-header="employeeName" class="text-center">
                Employee&nbsp;Name
              </th>
              <th mat-sort-header="organizationEmail">
                Organization&nbsp;Email
              </th>
              <th mat-sort-header="rolename">Employee&nbsp;Role</th>
              <th>Employee&nbsp;TimeLogs</th>
            </tr>
          </thead>
          <tbody
            style="
              border-bottom: 1px solid #ddd;
              border-left: 1px solid #ddd;
              border-right: 1px solid #ddd;
              height: 50px;
            "
          >
            <tr
              *ngFor="
                let task of projectList.employeeProjects
                  | paginate : { itemsPerPage: mySelect, currentPage: p };
                let i = index
              "
            >
              <td style="text-align: center" *ngIf="p">
                {{ (p - 1) * mySelect + (i + 1) }}
              </td>
              <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
              <td class="text-center" style="padding-right: 50px">
                {{ task.employee.employeeNumber }}
              </td>
              <td>{{ task.employee.firstName }}{{ task.employee.lastName }}</td>
              <td>{{ task.employee.organizationEmail }}</td>
              <td>{{ task?.employeeProjectRole?.rolename }}</td>
              <!-- (click)="report(this.Id,task.employee.employeeId)" -->
              <!-- routerLink="/home/projects/status/{{ this.Id }}/{{
                task.employee.employeeId
              }}" -->
              <td align="center">
                <span
                  class="material-icons view-icon"
                  (click)="report(this.Id, task.employee.employeeId)"
                  >remove_red_eye</span
                >
              </td>
            </tr>

            <tr>
              <td
                style="text-align: center; font-size: larger; font-weight: bold"
                colspan="6"
                *ngIf="nodata || projectList.length == 0"
              >
                Reports Not Found!
              </td>
            </tr>
          </tbody>
        </table>

        <pagination-controls
        *ngIf="projectList.length > 5"
          class="text-right"
          style="float: right"
          (pageChange)="p = $event; beforesearchpage = $event"
          align="end"
        ></pagination-controls>
      </div>
    </div>
  </div>
  <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
        <div class="card-body-nodata">
          <div class="alert-nodata alert-primary">
            <h4 align="center" class="h4">Reports Not Found</h4>
          </div>
        </div>
      </mat-card> -->
  <!-- </ng-container> -->
  <!-- <ng-template #elseTemplate>
  <mat-card class="col-md-12 card" *ngIf="nodata">
    <div class="container">
      <div class="alert alert-primary" align="center">
        <h4>No Resource Found</h4>
      </div>
    </div>
  </mat-card> -->
  <!-- </ng-template> -->
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="width: 3rem; height: 3rem"></div>
</div>
