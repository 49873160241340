<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span routerLink="/home/branch" class="h5 sub-content">
            {{ businessId ? "Edit" : "Add" }} Branch</span
          >
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-md-3" style="height: 70px !important;">
              <label class="form-control-label"
                >Branch Code<span class="star">*</span></label
              >
              <input
                type="text"
                placeholder="Branch Code"
                formControlName="branchCode"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                autocomplete="off"
                class="form-control email-input select-placeholder"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate.branchCode.invalid &&
                    validate.branchCode.touched) ||
                  validate.branchCode.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.branchCode.errors?.required"
                  class="text-danger"
                  >Please Enter Branch Code</span
                >
                <span
                  *ngIf="validate.branchCode.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Branch Code</span
                >
                <span *ngIf="existMsg" class="text-danger">{{ existMsg }}</span>
              </div>
            </div>
            <div class="form-group col-md-3" style="height: 70px !important;">
              <label class="form-control-label"
                >Branch Name <span class="star">*</span></label
              >
              <input
                type="text"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                formControlName="branchName"
                class="form-control email-input select-placeholder"
                autocomplete="off"
                placeholder="Branch name"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate.branchName.invalid &&
                    validate.branchName.touched) ||
                  validate.branchName.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.branchName.errors?.required"
                  class="text-danger"
                  >Please Enter Branch Name</span
                >
                <span
                  *ngIf="validate.branchName.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Branch Name</span
                >
              </div>
            </div>

            <div class="form-group col-md-3" style="height: 70px !important;">
              <label class="form-control-label"
                >Mobile Number <span class="star">*</span></label
              >
              <input
                type="tel"
                formControlName="phoneNumber"
                maxlength="10"
                minlength="10"
                (keypress)="noNumber($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                id="mobile_number"
                class="form-control email-input select-placeholder"
                autocomplete="off"
                placeholder="Mobile Number"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate.phoneNumber.invalid &&
                    validate.phoneNumber.touched) ||
                  validate.phoneNumber.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.phoneNumber.errors?.required"
                  class="text-danger"
                  >Please Enter Mobile Number</span
                >
                <span
                  *ngIf="validate.phoneNumber.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Mobile Number</span
                >
                <br />
                <!-- <span
                  *ngIf="
                    validate.phoneNumber.errors?.minlength ||
                    validate.phoneNumber.errors?.maxlength
                  "
                  class="text-danger"
                  >Mobile Number must be exactly 10 digits</span
                > -->
              </div>
            </div>

            <div class="form-group col-md-3" style="height: 70px !important;">
              <label class="form-control-label">Alternate Mobile Number </label>
              <input
                type="tel"
                formControlName="alternativePhoneNumber"
                minlength="10"
                maxlength="10"
                (keypress)="noNumber($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                id="mobile_number"
                class="form-control email-input select-placeholder"
                autocomplete="off"
                placeholder="Alternate Mobile Number"
              />
              <span
                *ngIf="validate.alternativePhoneNumber.errors?.pattern"
                class="text-danger"
                >Please Enter Valid Alternate Mobile Number</span
              >
            </div>

            <div class="form-group col-md-3" style="height: 70px !important;">
              <label class="form-control-label">E Mail </label>
              <input
                type="email"
                formControlName="branchEmail"
                id="email"

                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                class="form-control email-input select-placeholder only_name"
                placeholder="Email"
                autocomplete="off"
              />
              <!-- <span
                class="text-danger"
                *ngIf="validate.branchEmail.errors?.pattern"
                >Please enter valid Email</span
              > -->
              <div
                class="valid-msg"
                *ngIf="
                  (validate.branchEmail.invalid &&
                    validate.branchEmail.touched) ||
                  validate.branchEmail.dirty
                "
              >
                <span
                  *ngIf="validate.branchEmail.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Email</span
                >
              </div>
              <!-- <div
            class="valid-msg"
            *ngIf="
              (postForm.get('branchEmail').invalid && postForm.get('branchEmail').touched) ||
              postForm.get('branchEmail').dirty
            ">
            <span
              *ngIf="postForm.get('branchEmail').errors?.pattern"
              class="text-danger"
              >Please Enter Valid Email</span
            >
          </div> -->
            </div>

            <div class="form-group col-md-3" style="height: 70px !important;">
              <label class="form-control-label">Description </label>
              <input
                type="text"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                formControlName="branchDescription"
                id="email"
                class="form-control email-input select-placeholder only_name"
                placeholder="Description"
                autocomplete="off"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate.branchDescription.invalid &&
                    validate.branchDescription.touched) ||
                  validate.branchDescription.dirty
                "
              >
                <span
                  *ngIf="validate.branchDescription.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Description</span
                >
              </div>
            </div>
            <div class="form-group col-md-3" style="height: 70px !important">
              <label class="form-control-label">Address </label>
              <input
                type="text"
                formControlName="branchAddress"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                id="email"
                class="form-control email-input select-placeholder only_name"
                placeholder="Address"
                autocomplete="off"
              />
              <div
                class="valid-msg"
                *ngIf="
                  (validate.branchAddress.invalid &&
                    validate.branchAddress.touched) ||
                  validate.branchAddress.dirty
                "
              >
                <span
                  *ngIf="validate.branchAddress.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Address</span
                >
              </div>
            </div>

            <!-- <div class="form-group col-md-3">
                            <label class="form-control-label">Country </label>
                            <select formControlName="country_id" id="country_id" class="form-select form-select-solid form-select-lg fw-bold">
                              <option >Select</option>
                              <option >India</option>
                              <option >Dubai</option>
                            </select>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="form-control-label">State </label>
                            <select formControlName="state_id" id="state_id" class="form-select form-select-solid form-select-lg fw-bold">
                              <option >Select</option>
                              <option >Tamilnadu</option>
                              <option >Andhra</option>
                            </select>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="form-control-label">District </label>
                            <select formControlName="district_id" id="district_id" class="form-select form-select-solid form-select-lg fw-bold">
                              <option >Select</option>
                              <option >Krishnagiri</option>
                              <option >Salem</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label">City</label>
                            <select formControlName="city_id" id="city_id" class="form-select form-select-solid form-select-lg fw-bold">
                              <option >Select</option>
                              <option >Krishnagiri</option>
                              <option >Hosur</option>
                            </select>
                        </div> -->
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <a
                routerLink="/home/branch"
                routerLinkActive="active"
                mat-raised-button
                style="
                     margin-left: 10px;
    background: none;
    color: var(--Red-Color, #ff5d5d);
    border: 1px solid #ddd !important;
    height: 36px;
    position: relative;
    top: -2px;
    font-weight: 550;
    padding-top: 10px;
                "
                class="btn search-btn mr-2"
                [disabled]="isbtnDisable"
              >
                Cancel
              </a>
              <button
                type="submit"
                mat-raised-button
                class="n m-1 searchBtn"
                [disabled]="isbtnDisable"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="align-items-cEnter text-cEnter">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 51%"
    role="status"
  ></div>
</div>

<div class="text-cEnter" *ngIf="success">
  <div class="alert alert-resource alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
