import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import {  httpleaveOptions, httpOptions, httpOptionsHrms } from './properties';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {
  yearname = new Date().getFullYear();
  checkPermissionExists: any;

  constructor(private http : HttpClient) { }

  //permission
  get_permissionList(empId:any){
    return this.http.get(`${environment.leave_base_url}permissions/empolyee/${empId}`,httpleaveOptions)
    .pipe(map(res => res));
  }
  Permission_Status(permissionId,data:any){
    return this.http.put(`${environment.leave_base_url}permissions_status/${permissionId}`,data,httpleaveOptions)
    .pipe(map(res => res));
  }
  applyPermission(data:any){
    return this.http.post(`${environment.leave_base_url}permissions`,data,httpleaveOptions)
    .pipe(map(res => res));
  }

  ///leave
  applyLeave(data:any){
    return this.http.post(`${environment.leave_base_url}leave_request`,data,httpleaveOptions)
    .pipe(map(res => res));
  }
  // getMyleave(id:any) {
  //   return this.http.get(`${environment.leave_base_url}leave_request_of_employee/${id}`,httpleaveOptions);
  // }performance issue

  getMyleave(id:any) {
    return this.http.get(`${environment.leave_base_url}v2/leave_request_of_employee/${id}`,httpleaveOptions);
  }
  approvalsById(referredwith:any,id:any){
    return this.http.get(`${environment.base_url}approvalss/${referredwith}/${id}`,httpOptionsHrms)
  }
  deleteLeave(leaveId, data){
    return this.http.put(`${environment.leave_base_url}leave_request_status/${leaveId}`, data,httpleaveOptions);
  }
  UpdateLeaveStatus(leaveId, data){
    return this.http.put(`${environment.leave_base_url}leave_request_status/${leaveId}`, data,httpleaveOptions);
  }
  getLeaveBalanceById(employeeId) {
    return this.http.get(`${environment.leave_base_url}leave_balance_of_employee_by_year/${employeeId}/${this.yearname}`,httpleaveOptions)
  }

  // withdraw
  withdraw(data): Observable<any>  {
    return this.http.post(`${environment.leave_base_url}leave_withdraw`, data,httpleaveOptions)
  }

  //WFH
  addWfm(Data) {
    return this.http.post(`${environment.leave_base_url}wfh`, Data,httpleaveOptions);
  }
  // get_myWfh(empid, categoryId) {
  //   return this.http.get(`${environment.leave_base_url}wfh/${empid}/${categoryId}`,httpleaveOptions)
  // }performance issue

  get_myWfh(empid, categoryId) {
      return this.http.get(`${environment.leave_base_url}v2/wfh/${empid}/${categoryId}`,httpleaveOptions)
    }
  Actionwfh(id, data) {
    return this.http.put(`${environment.leave_base_url}wfh_status/${id}`, data,httpleaveOptions);
  }
  //business travel
  getMyBusinessTravel_List(empId, categoryId) {
    return this.http.get(`${environment.leave_base_url}business_travel/${empId}/${categoryId}`,httpleaveOptions)
  }
  AcctionBusiness(id, data) {
    return this.http.put(`${environment.leave_base_url}business_travel_status/${id}`, data,httpleaveOptions);
  }
   // get travel mode
  getTravel_Mode() {
    return this.http.get(`${environment.leave_base_url}travelmode`,httpleaveOptions);
  }
  Apply_Business_Travel_Request(data) {
    return this.http.post(`${environment.leave_base_url}business_travel`, data,httpleaveOptions)
  }

  //dashboard
  getsettings(orgId) {
    return this.http.get(`${environment.leave_base_url}settings/organization/${orgId}`,httpleaveOptions).pipe(map(data => data));
  }
  getbalancePermission(currentdate:any,employeeId:any){
    return this.http.get(`${environment.leave_base_url}dashboard/premissiondate/employee/${currentdate}/${employeeId}`,httpleaveOptions)
    .pipe(map(data => data));
  }
  GetMYleaveBalance(id) {
    return this.http.get<any>(`${environment.leave_base_url}leave_balance_of_employee_by_year/${id}/${this.yearname}`,httpleaveOptions)
  }
  upcomingsheduleleave(employeeId:any,currentdate:any){
    // return this.http.get(`${environment.leave_base_url}leave_request/approved/organization/${orgId}/${currentdate}/5`,httpleaveOptions)
    return this.http.get(`${environment.leave_base_url}leave_request/approved/employee/${employeeId}/${currentdate}/5`,httpleaveOptions)
    .pipe(map(data => data));
  }
  upcomingsheduleWFH(employeeId:any,currentdate:any){
    return this.http.get(`${environment.leave_base_url}wfh/employee/approved/${employeeId}/${currentdate}/5`,httpleaveOptions)
    .pipe(map(data => data));
  }
  upcomingshedulePermission(employeeId:any,currentdate:any){
    return this.http.get(`${environment.leave_base_url}permissions/approved/employee/${employeeId}/${currentdate}/5`,httpleaveOptions)
    .pipe(map(data => data));
  }  
  upcomingsheduleBT(employeeId:any,currentdate:any){
    return this.http.get(`${environment.leave_base_url}business_travel/approved/employee/${employeeId}/${currentdate}/5`,httpleaveOptions)
    .pipe(map(data => data));
  }
  getUpComingHoliday(employeeId:any,currentdate:any,orgId:any){
    return this.http.get(`${environment.leave_base_url}dashboard/holiday/employee/${employeeId}/${currentdate}/${orgId}`,httpleaveOptions)
    .pipe(map(data => data));
  }
  getUpComingLeaves(employeeId:any,currentdate:any){
    return this.http.get(`${environment.leave_base_url}dashboard/leave_request_of_employee/employee/${employeeId}/${currentdate}/5`,httpleaveOptions)
    .pipe(map(data => data));
  }
  employeeRequestCount(orgId:any,currentdate:any,approverid:any){
    return this.http.get(`${environment.leave_base_url}admindashboard/organization/leave_request/permission/workfromhome/${orgId}/${currentdate}/${approverid}`,httpleaveOptions)
      // http://192.168.18.45:6019/api/enote/leave/dashboard/leaveBalance/8010/10/2023
    .pipe(map(data => data));
  }
  // getWFHRequest(reportingTo, status) {
  //   return this.http.get(`${environment.leave_base_url}wfh/reportingto/${reportingTo}/${status}`,httpleaveOptions)
  // }performance issue

  getWFHRequest(reportingTo, status) {
      return this.http.get(`${environment.leave_base_url}v2/wfh/reportingto/${reportingTo}/${status}`,httpleaveOptions)
    }

  // manage leave request
  // getleaveReqPending(mail) {
  //   return this.http.get(`${environment.leave_base_url}leave_request/pending/${mail}`,httpleaveOptions);
  // }performance issue

  getleaveReqPending(mail) {
    return this.http.get(`${environment.leave_base_url}v2/leave_request/pending/${mail}`,httpleaveOptions);
  }
  // getemp_withdraw_list(mail) {
  //   return this.http.get(`${environment.leave_base_url}leave_withdraw/pending/${mail}`,httpleaveOptions)
  // }performance issue

  getemp_withdraw_list(mail) {
    return this.http.get(`${environment.leave_base_url}v2/leave_withdraw/pending/${mail}`,httpleaveOptions)
  }
  withdrawAction(id, data) {
    return this.http.put(`${environment.leave_base_url}leave_withdraw_status/${id}`, data,httpleaveOptions)
  }

  // leave balance
  getLeaveType(id) {
    return this.http.get(`${environment.leave_base_url}leave_type/organizationId/${id}`,httpleaveOptions)
  }
  getEmpList1() {
    return this.http.get(`${environment.leave_base_url}employee/activestatus`,httpleaveOptions)
  }
  getEmpListById(id): Observable<any> {
    return this.http.get(`${environment.base_url}employee/${id}`,httpOptions)
  }
  getApproverById(id): Observable<any> {
    return this.http.get(`${environment.base_url}approvers/${id}`,httpOptions)
  }
  get_leave_date(id) {
    return this.http.get(`${environment.leave_base_url}leave_request_dates/${id}`,httpleaveOptions)
  }
  leaveBalance(data) {
    return this.http.post(`${environment.leave_base_url}leave_balance`, data,httpleaveOptions)
  }
  getEmployeeListKeywords(id:any,keywords:any): Observable<any>{
    let result =  this.http.get(`${environment.base_url}employee/filter/keyword/${keywords}/${id}`,httpOptions)
    .pipe(map(data=>data))
    return result;
  }
  getAdjustment(id) {
    return this.http.get(`${environment.leave_base_url}leave_adjustment/organization/${id}`,httpleaveOptions);
  }
  leaveadjustmentByEmp(employeeId: any) {
    return this.http.get(`${environment.leave_base_url}leave_adjustment/employee/${employeeId}`,httpleaveOptions);
  }
  leaveadjustmentByLeavetype(leavetypeId: any) {
    return this.http.get(`${environment.leave_base_url}leave_adjustment/leavetype/${leavetypeId}`,httpleaveOptions);
  }
  leaveadjustmentByEmpAndLeavetype(leavetypeId: any, employeeId: any) {
    return this.http.get(`${environment.leave_base_url}leave_adjustment/leavetype/employee/${leavetypeId}/${employeeId}`,httpleaveOptions);
  }
  addAdjustment(Data) {
    return this.http.post(`${environment.leave_base_url}leave_adjustment`, Data,httpleaveOptions);
  }
  inactiveLeaveType(leaveTypeId: number, statusId: number) {
    return this.http.put(`${environment.leave_base_url}leavetype/deactive/${leaveTypeId}/${statusId}`, null,httpleaveOptions);
  }
  getUserTypeById(id) {
    return this.http.get(`${environment.leave_base_url}leave_type/${id}`,httpleaveOptions)
  }
  updateLeaveType(id, data){
    return this.http.put(`${environment.leave_base_url}leave_type/${id}`,data,httpleaveOptions)
  }
  createLeaveType(data){
    return this.http.post(`${environment.leave_base_url}leave_type`,data,httpleaveOptions)
  }
  getsettingsbyId(data: any){
    return this.http.get(`${environment.leave_base_url}settings/${data}`,httpleaveOptions).
    pipe(map(data => data));
  }
  updatesettings(settingsobj: any){
    return this.http.put(`${environment.leave_base_url}settings/${settingsobj.settingsId}`, settingsobj,httpleaveOptions).
    pipe(map(data => data));
  }
  createsettings(settingsobj: any){
    return this.http.post(`${environment.leave_base_url}v1/settings`, settingsobj,httpleaveOptions).
    pipe(map(data => data));
  }

  createPermissionsettings(settingsobj: any){
    return this.http.post(`${environment.leave_base_url}settings`, settingsobj,httpleaveOptions).
    pipe(map(data => data));
  }
  // getPermission_Manage(id){
  //   return this.http.get(`${environment.leave_base_url}permissions/pending/${id}`,httpleaveOptions)
  // }performance issue

 getPermission_Manage(id){
    return this.http.get(`${environment.leave_base_url}v2/permissions/pending/${id}`,httpleaveOptions)
  }

  deleteLeaveType(id){
    return this.http.delete(`${environment.leave_base_url}leave_type/${id}`,httpleaveOptions)
  }
  // getEmployeeBusinessTravelRequest_EmailID(reportingto, businesssTravelStatus){
  //   return this.http.get(`${environment.leave_base_url}business_travel/reportingto/${reportingto}/${businesssTravelStatus}`,httpleaveOptions)
  // }performance issue

  getEmployeeBusinessTravelRequest_EmailID(reportingto, businesssTravelStatus){
      return this.http.get(`${environment.leave_base_url}v2/business_travel/reportingto/${reportingto}/${businesssTravelStatus}`,httpleaveOptions)
    }
  getCalByBranch(branchId) {
    return this.http.get(`${environment.leave_base_url}calendar/branch/${branchId}`,httpleaveOptions);
  }
  getCalendarById(calendarId) {
    return this.http.get(`${environment.leave_base_url}calendar/${calendarId}`,httpleaveOptions);
  } 
  getCalByBranchAndyearname(branchId:any,year:any){
    return this.http.get(`${environment.leave_base_url}calendar/branch/${branchId}/${year}`,httpleaveOptions);
  }

//
  getCalendar() {
    return this.http.get(`${environment.leave_base_url}calendar`,httpleaveOptions);
  } 
  getCalendarById_Active(calendarId) {
    return this.http.get(`${environment.leave_base_url}calendar/active_calendar/${calendarId}`,httpleaveOptions);
  }
  getHoliday() {
    return this.http.get(`${environment.leave_base_url}holiday`,httpleaveOptions);
  }
  getHolidayById(holidayId) {
    return this.http.get(`${environment.leave_base_url}holiday/${holidayId}`,httpleaveOptions);
  }
  getExceptionById(exceptionId) {
    return this.http.get(`${environment.leave_base_url}exceptions/${exceptionId}`,httpleaveOptions);
  }
  getHolidayByCalId(calendarId) {
    return this.http.get(`${environment.leave_base_url}holiday/calendar/${calendarId}`,httpleaveOptions);
  }
  getExceptionByCalId(calendarId) {
    return this.http.get(`${environment.leave_base_url}holiday/calendar/${calendarId}`,httpleaveOptions);
  }
  updateCalendar(calendarId, Form) {
    return this.http.put(`${environment.leave_base_url}calendar/${calendarId}`, Form,httpleaveOptions)
  }
  deactiveCalendar(calendarId, Form) {
    return this.http.put(`${environment.leave_base_url}calendar/deactivate/${calendarId}`, Form,httpleaveOptions)
  }
  getListTypeById(listTypeId) {
    return this.http.get(`${environment.leave_base_url}list-type-values/${listTypeId}`,httpleaveOptions)
  }
  //Working Days
  createWoringDays(Form) {
    return this.http.post(`${environment.leave_base_url}calendarworkingdays`, Form,httpleaveOptions)
  }
  // calenderOfYear(currentyear: any) {
  //   return this.http.get(`${environment.leave_base_url}calendar/year/${currentyear}`,httpleaveOptions);
  // }
  calenderOfYear(currentyear: any,id) {
    return this.http.get(`${environment.leave_base_url}v2/calendar/year/${currentyear}/${id}`,httpleaveOptions);
  }
  createMultipleHoliday(Data) {
    return this.http.post(`${environment.leave_base_url}multiple/holidays`, Data,httpleaveOptions);
  }
  createMultipleException(Data) {
    return this.http.post(`${environment.leave_base_url}multiple/exceptions`, Data,httpleaveOptions);
  }

  //Woring Days Update
  updateWoringDays(workDayId, Form) {
    return this.http.put(`${environment.leave_base_url}calendarworkingdays/${workDayId}`, Form,httpleaveOptions)
  }
  getOrganization() {
    return this.http.get(`${environment.base_url}organization`,httpOptions);
  }
  getYear() {
    return this.http.get(`${environment.leave_base_url}year`,httpleaveOptions);
  }
  getBranch() {
    return this.http.get(`${environment.leave_base_url}branch`,httpleaveOptions);
  }
  createCalendar(Form) {
    return this.http.post(`${environment.leave_base_url}v2/calendar`, Form,httpleaveOptions)
  }
  deleteHoliday(holidayId: number) {
    return this.http.delete<any>(`${environment.leave_base_url}holiday/${holidayId}`,httpleaveOptions);
  }
  UpdateHoliday(holidayId, data) {
    return this.http.put(`${environment.leave_base_url}holiday/${holidayId}`, data,httpleaveOptions)
  }
  createHoliday(Data: FormData) {
    return this.http.post(`${environment.leave_base_url}holiday`, Data,httpleaveOptions);
  }
  createException(Data: FormData) {
    return this.http.post(`${environment.leave_base_url}exceptions`, Data,httpleaveOptions);
  }
  UpdateException(exceptionId, data) {
    return this.http.put(`${environment.leave_base_url}exceptions/${exceptionId}`, data,httpleaveOptions)
  }
  leavereportByempname(employeeId: number) {
    return this.http.get(`${environment.leave_base_url}v2/leave_request_of_employee/${employeeId}`,httpleaveOptions)
  }

  withdrawreportByempname(employeeId: number) {
    return this.http.get(`${environment.leave_base_url}leave_withdraw/employee/${employeeId}`,httpleaveOptions)
  }
  getExceptionlist(employeeId:any,currentdate:any,branchId:any){
    return this.http.get(`${environment.leave_base_url}dashboard/exceptions/employee/${employeeId}/${currentdate}/${branchId}`,httpleaveOptions)
    .pipe(map(data => data));
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
  }
  //reports
  getEmpLEaveList(mail) {
    return this.http.get(`${environment.leave_base_url}leave_request/all/${mail}`,httpleaveOptions)
  }
  // leavereportfilter(startdate: any, enddate: any, orgId) {
  //   return this.http.get(`${environment.leave_base_url}leave_request/organization/${startdate}/${enddate}/${orgId}`,httpleaveOptions)
  // }performance issue

   leavereportfilter(startdate: any, enddate: any, orgId) {
    return this.http.get(`${environment.leave_base_url}v2/leave_request/organization/${startdate}/${enddate}/${orgId}`,httpleaveOptions)
  }
  withdrawreportfilter(startdate: any, enddate: any, orgId) {
    return this.http.get(`${environment.leave_base_url}leave_withdraw/organization/${startdate}/${enddate}/${orgId}`,httpleaveOptions)
  }
  getLeaveListByOrg(orgId) {
    return this.http.get(`${environment.leave_base_url}leave_request/organization/${orgId}`,httpleaveOptions)
  }
  // leavereportBystartdateenddateempname(startdate: any, enddate: any, employeename: any) {
  //   return this.http.get(`${environment.leave_base_url}leave_request/employee/${startdate}/${enddate}/${employeename}`,httpleaveOptions)
  // }performance issue

  leavereportBystartdateenddateempname(startdate: any, enddate: any, employeename: any) {
    return this.http.get(`${environment.leave_base_url}v2/leave_request/employee/${startdate}/${enddate}/${employeename}`,httpleaveOptions)
  }

  withdrawreportBystartdateenddateempname(startdate: any, enddate: any, employeename: any) {
    return this.http.get(`${environment.leave_base_url}leave_withdraw/employee/${startdate}/${enddate}/${employeename}`,httpleaveOptions)
  }
  getAll_withdrawList(mail) {
    return this.http.get(`${environment.leave_base_url}leave_withdraw/all/${mail}`,httpleaveOptions)
  }
  getBusinesstravelList(orgId) {
    return this.http.get(`${environment.leave_base_url}business_travel/organization/${orgId}`,httpleaveOptions)
  }
  getEmployee_based_on_reportingTo(mail) {
    return this.http.get(`${environment.leave_base_url}employee/all/${mail}`,httpleaveOptions)
  }
  // businessreportfilter(startdate, enddate, orgId) {
  //   return this.http.get(`${environment.leave_base_url}business_travel/organization/${startdate}/${enddate}/${orgId}`,httpleaveOptions)
  // }PERFORMANCE ISSUE

  businessreportfilter(startdate, enddate, orgId) {
    return this.http.get(`${environment.leave_base_url}v2/business_travel/organization/${startdate}/${enddate}/${orgId}`,httpleaveOptions)
  }
  // businesslistBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
  //   return this.http.get(`${environment.leave_base_url}business_travel/employee/${startdate}/${enddate}/${employeeId}`,httpleaveOptions)
  // }PERFORMANCE ISSUE API

  businesslistBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
    return this.http.get(`${environment.leave_base_url}v2/business_travel/employee/${startdate}/${enddate}/${employeeId}`,httpleaveOptions)
  }
  businesslistByempId(employeeId: number) {
    return this.http.get(`${environment.leave_base_url}business_travel/${employeeId}/2`,httpleaveOptions)
  }
  getPermissions(orgId) {
    return this.http.get(`${environment.leave_base_url}permissions/organization/${orgId}`,httpleaveOptions)
  }
  // filterPermissionList(stDate, edDate, orgId) {
  //   return this.http.get(`${environment.leave_base_url}permissions/organization/${stDate}/${edDate}/${orgId}`,httpleaveOptions)
  // }performance issue

  filterPermissionList(stDate, edDate, orgId) {
    return this.http.get(`${environment.leave_base_url}v2/permissions/organization/${stDate}/${edDate}/${orgId}`,httpleaveOptions)
  }
  // permissionlistBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
  //   return this.http.get(`${environment.leave_base_url}permissions/employee/${startdate}/${enddate}/${employeeId}`,httpleaveOptions)
  // }performance issue

  permissionlistBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
    return this.http.get(`${environment.leave_base_url}v2/permissions/employee/${startdate}/${enddate}/${employeeId}`,httpleaveOptions)
  }
  permissionlistByempId(employeeId: number) {
    return this.http.get(`${environment.leave_base_url}permissions/empolyee/${employeeId}`,httpleaveOptions)
  }
  getWFHlistByOrg(orgId) {
    return this.http.get(`${environment.leave_base_url}wfh/organization/${orgId}`,httpleaveOptions)
  }
  // getWFHfilters(startdate: any, enddate: any, orgId) {
  //   return this.http.get(`${environment.leave_base_url}wfh/organization/${startdate}/${enddate}/${orgId}`,httpleaveOptions)
  // }performance issue

  getWFHfilters(startdate: any, enddate: any, orgId) {
    return this.http.get(`${environment.leave_base_url}v2/wfh/organization/${startdate}/${enddate}/${orgId}`,httpleaveOptions)
  }
  // WFHBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
  //   return this.http.get(`${environment.leave_base_url}wfh/employee/${startdate}/${enddate}/${employeeId}`,httpleaveOptions)
  // }performance issue

   WFHBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
    return this.http.get(`${environment.leave_base_url}v2/wfh/employee/${startdate}/${enddate}/${employeeId}`,httpleaveOptions)
  }
  WFHByEmplId(employeeId: number) {
    return this.http.get(`${environment.leave_base_url}wfh/${employeeId}/1`,httpleaveOptions)
  }

}
