<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content headingDesing">
                       {{onboardingId ? "Edit" : "Add"}} Onboarding
                    </span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/onboardings" mat-raised-button title="Back" class="ml-2">
                        Back
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="onboaringform" (ngSubmit)="submitData()">
                    <div class="row">   
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Resource <span class="star">*</span></label>
                            <mat-select class="form-control email-input select-placeholder" [ngClass]="{ 'is-invalid': submitted }" appearance="fill" formControlName="resource"   (selectionChange)="resourcechange($event)" placeholder="Select Resource" [disabled] = "resourcereadonly">
                                <mat-option *ngFor="let resource of data"  class="text-color" [value]="resource.resourceId">{{resource.resourceName}} - {{resource.resourceNumber}}</mat-option>
                                <!-- <mat-option class="text-color" value="option2">2</mat-option> -->
                            </mat-select>
                            <div class="valid-msg" *ngIf="(resource.invalid && resource.touched) || resource.dirty || submitted">
                                <span class="text-danger" *ngIf="resource.errors?.required">Please select Resource</span>
                            </div> 
                        </div>
                
                        <div class="form-group col-md-3 cursorDesign">
                            <label class="form-control-label">Hiring For</label>
                            <mat-select class="form-control email-input select-placeholder selectDesign"  (selectionChange)="listTypeValueid($event)" [disabled] = true appearance="fill" formControlName="hiring" placeholder="Select Hiring for">
                                <mat-option *ngFor="let e of onboardingusertype" class="text-color" [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
                            </mat-select>
                           
                            <div class="valid-msg" *ngIf="(hiring.invalid && hiring.touched) || hiring.dirty || submitted">
                                <span class="text-danger" *ngIf="hiring.errors?.required">Please select Hiring for</span>
                            </div> 
                        </div>
                    
                        <div class="form-group col-md-3">
                            <label class="form-control-label" >Approved CTC <span class="star">*</span></label>
                            <input type="text" class="form-control email-input select-placeholder mb-3 mb-lg-0" 
                            (keypress)=" noNumber($event)"
                            (paste)="blockPaste($event)"
                            (drop)="blockDrop($event)"
                             maxlength="7"
                             [ngClass]="{ 'is-invalid': submitted }" 
                             formControlName="approvedCtc" placeholder="Enter Approved CTC">
                            <div class="valid-msg" *ngIf="(approvedCtc.invalid && approvedCtc.touched) || approvedCtc.dirty || submitted">
                                <span class="text-danger" *ngIf="approvedCtc.errors?.required" >Please enter Approved CTC</span>
                                <span class="text-danger" *ngIf="approvedCtc.errors?.pattern">Please enter valid Approved CTC</span>   
                            </div> 
                        </div>
                
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Work Location <span class="star">*</span></label>
                            <input type="text" class="form-control email-input select-placeholder mb-3 mb-lg-0"
                             [ngClass]="{ 'is-invalid': submitted }" 
                             formControlName="workLocation" placeholder="Enter Work Location">
                            <div class="valid-msg" *ngIf="(workLocation.invalid && workLocation.touched) || workLocation.dirty || submitted">
                                <span class="text-danger" *ngIf="workLocation.errors?.required">Please enter Work location</span>
                                <span class="text-danger" *ngIf="workLocation.errors?.pattern">Please enter valid Work location</span>   
                            </div> 
                        </div>  
                    </div>

                    <div class="row">                                            
                        <div class="form-group col-md-3" *ngIf="showclient">
                            <label class="form-control-label">Client Name</label>
                            <mat-select class="form-control email-input select-placeholder" [ngClass]="{ 'is-invalid': submitted }" 
                            appearance="fill" formControlName="clientName" placeholder="Select client name">
                                <!-- [disabled] = true -->
                                <mat-option *ngFor="let e of clientname1" class="text-color" [value]="e.clientId" >{{e.clientName}}</mat-option>
                            </mat-select>
                            <div class="valid-msg" *ngIf="(clientName.invalid && clientName.touched) || clientName.dirty || submitted">
                                <span class="text-danger" *ngIf="clientName.errors?.required">Please select client name</span>
                            </div> 
                        </div>  
                
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Offer Released Date <span class="star">*</span></label>
                            <input matInput [matDatepicker]="picker1" 
                            (dateChange)="checkdif();updateEndDateMin($event) ;"  
                           
                             formControlName="offerReleasedDate" class="form-control email-input select-placeholder mb-3 mb-lg-0" readonly="readonly" placeholder="Choose Offer Released Date">
                            <mat-datepicker-toggle matSuffix  [for]="picker1" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <div class="valid-msg" *ngIf="(offerReleasedDate.invalid && offerReleasedDate.touched) || offerReleasedDate.dirty || submitted">
                                <span class="text-danger" *ngIf="offerReleasedDate.errors?.required" class="text-danger">Please enter offer released date</span>   
                            </div> 
                        </div> 
                                                         
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Offer Accepted Date <span class="star">*</span></label>
                            <input matInput [matDatepicker]="picker2" [min]="onboaringform.value.offerReleasedDate" [max]="today"
                           
                             (dateChange)="checkdif();updateEndDateMin($event)" 
                             formControlName="offerAcceptedDate" 
                             class="form-control email-input select-placeholder mb-3 mb-lg-0" 
                             readonly="readonly" placeholder="Choose Offer Accepted Date ">
                            <mat-datepicker-toggle matSuffix [for]="picker2" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                            <div class="valid-msg" *ngIf="(offerAcceptedDate.invalid && offerAcceptedDate.touched) || offerAcceptedDate.dirty || submitted">
                                <span class="text-danger" *ngIf="offerAcceptedDate.errors?.required">Please enter offer accepted date</span>    
                            </div> 
                        </div> 
            
                        <div class="form-group col-md-3" >
                            <label class="form-control-label">Waiting Period <span class="star">*</span></label>
                            <input type="text" 
                          
                            class="form-control email-input select-placeholder mb-3 mb-lg-0" formControlName="waitingPeriod" placeholder="Enter Waiting Period " readonly>
                            <div class="valid-msg" *ngIf="(waitingPeriod.invalid && waitingPeriod.touched) || waitingPeriod.dirty || submitted">
                                <span class="text-danger" *ngIf="waitingPeriod.errors?.required" class="text-danger">Please enter waiting period</span>
                                <span class="text-danger" *ngIf="waitingPeriod.errors?.pattern" class="text-danger">Please enter valid waiting period</span>   
                            </div> 
                        </div>       
                    </div>  

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Expected Date Of Joining <span class="star">*</span></label>
                            <input matInput [matDatepicker]="picker3"  
                               [min]="onboaringform.value.offerAcceptedDate" [max]="today"
                                [ngClass]="{ 'is-invalid': submitted }" 
                                formControlName="dateOfJoining" class="form-control email-input select-placeholder mb-3 mb-lg-0"
                                 readonly="readonly"
                                 style="padding:12px;"
                                 (dateChange)="checkdif();updateEndDateMin($event)" 
                                  placeholder="Choose Expected Date Of Joining">
                            <mat-datepicker-toggle matSuffix [for]="picker3" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                            <div class="valid-msg" *ngIf="(dateOfJoining.invalid && dateOfJoining.touched) || dateOfJoining.dirty || submitted">
                                <span class="text-danger" *ngIf="dateOfJoining.errors?.required" class="text-danger">Please enter date of joining</span>   
                            </div> 
                        </div>
                    
                        <div class="form-group col-md-3">
                            <label class="form-control-label">Email Confirmation <span class="star">*</span></label>
                            <mat-select class="form-control email-input select-placeholder" [ngClass]="{ 'is-invalid': submitted }" appearance="fill" formControlName="emailConfirmation" placeholder="Select Email Confirmation">
                                <mat-option class="text-color" value=true>Yes</mat-option>
                                <mat-option class="text-color" value=false>No</mat-option>
                            </mat-select>
                            <div class="valid-msg" *ngIf="(emailConfirmation.invalid && emailConfirmation.touched) || emailConfirmation.dirty || submitted">
                                <span class="text-danger" *ngIf="emailConfirmation.errors?.required" class="text-danger">Please select Email Confirmation</span>
                            </div> 
                        </div> 
                    </div> 

                    <!-- <div class="row buttons">
                        <div class="col-md-12">
                            <a routerLink="/home/onboardings" routerLinkActive="active" mat-raised-button title="Cancel" class="btn btn-light btn-sm mr-2">
                                Cancel
                            </a>
                            <button type="submit" class="submit action-button btn btn-primary btn-sm" [disabled]="buttonDisabled">Save</button>
                        </div>
                    </div>        -->
                    <div align="end">
                        <button mat-raised-button type="button" class="cancel-button m-1"
                        routerLink="/home/onboardings"[disabled]="buttonDisabled"
                        style="
                        background: none;
                        color: var(--Red-Color, #FF5D5D);
                        border: 1px solid #ddd;
                        font-size: 16px;
                        font-weight: 550;
                        margin-left: 5px;
                        height: 35px;
                      "
                         >Cancel</button>
                        <button mat-raised-button type="submit" style="height: 34px;" class="create-button m-1" [disabled]="buttonDisabled">
                          Save</button>
                      </div>
                </form>  
            </div>  
        </div>  
    </div>  
</div>  
    
<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success"> 
    <div class="alert  alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert  alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{update_data_error_msg}}
</div>



