<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content pr-3"
                    style="    position: relative;
                    top: 6px;
                    color: #181c32;
                    font-weight: 800;
                    font-size: 22px;
                    font-family: 'Mulish', sans-serif;
                    right: 15px;">KRA Template</span> 
                </div>
                <div class="sub-header-buttons">
                    
                    <a routerLink="/home/add-kra-template" routerLinkActive="active"  mat-raised-button title="Add KRA Template" class="ml-2"
                    style="position: relative; left:22px; color:#fff; background-color: #4870ff;" >
                      Add KRA
                    </a>
      
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->
<div class="common-content" style="overflow: scroll;">
    <div class="card-new">
        <div class="card" style="font-family: Mulish;">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <!-- <div class="row mb-3 pl-0">
                            <div class="col-md-4">
                                <input type="search" [(ngModel)]="tableSearch" autocomplete="off" class="form-control form-control-lg form-control-solid search-input" name="keywords" value="" placeholder="Search...">
                            </div>
                        </div> -->
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row mb-3 pl-0">
                                <div class="col-md-4 col-sm-4 col-xs-4 col-4" style="margin-top: 3px;">
                                    <input matInput formControlName="keywords" type="text" [matAutocomplete]="auto" class="form-control email-input select-placeholder "  placeholder="Search..."  (input)="validateInput($event)"
                                    >
                                    <small class="text-muted">Note : Template Code, Template Name.</small>
                                </div>
                                <div class="col-xl-4 col-lg-4   ">
                                    <button type="submit" mat-raised-button 
                                    class=" btn btn-primary searchBtn searchDesign "   style=" top: 3px; " [disabled]="buttonsDisabled"  >Search</button>
                                    <!-- <button  type="button" mat-raised-button
                                    class="clear" style="margin-left: 20px; position: absolute; "
                                     (click)="clearTable()" [disabled]="loading || !searchForm.valid">Clear</button>     
                                     
                                     -->
                                     <button
        type="button"
        mat-raised-button
        class=""
        (click)="clearTable()"
        [disabled]="buttonsDisabled"
        style="
          
          color: white;
          position:absolute;
          margin-left: 10px;
          background: none;
          color: black;
          border: 1px solid #ddd;
          font-size: 16px;
          font-weight: 610;
          height: 35px;
          top:3px;
          width:0px;
          padding-left: 10px !important;
          
        "
      >
        Clear
      </button>
                                </div>
                                <!-- <div class="col-xl-1 col-lg-1 mb-2">
                                    <button  type="reset" mat-raised-button
                                    class="btn search-btn"
                                     (click)="clearTable()">Clear</button>
                                </div> -->
                            </div>
                        </form>
                    </div>
                    
                </div>

                <div class="row" >
                    <!-- <div class="col-md-6 offset-6" style="margin-bottom: 10px;">
                        <div class="row" style="margin-left: 100px;">
                            <div class="col-md-2 col-sm-2 col-2 colxs-2 offset-7" style="padding:0px">
                                <p class="show-count" style="white-space: nowrap;text-align: right;float:right;">Show:</p>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3 col-3" style="width: 10px;padding:0px">
                                <select [(ngModel)]="mySelect" class="form-select form-select-solid form-select-lg report-filter" style="width:67px;">
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                  <option value="25">25</option>
                                  
                              </select>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-xl-12 col-lg-12" style="margin-bottom: 10px;"
                    *ngIf="!error && !nodatafound && kralistData?.length > 0 && showTable">
                        <div class="select-pagination" *ngIf="kralistData.length >= 5">
                            <span>Show : </span>
                            <select class="select_list new-select_list"  style="cursor: pointer;" [(ngModel)]="mySelect" (change)="onItemsPerPageChange()" >
                                <option class="form-control" value="5"> 5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                        <!-- <div class="row">
                            <div class="col-md-2 col-sm-2 col-2 colxs-2 ">
                                <p class="show-count" style="white-space: nowrap; ">Show:</p>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3 col-3">
                                <select [(ngModel)]="mySelect" class="form-select form-select-solid form-select-lg report-filter">
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                  <option value="25">25</option>
                                  <option value="1500">1500</option>
                              </select>
                            </div>
                        </div> -->
                    
                <div class="table-responsive" *ngIf="showTable" >
                    <table  matSort (matSortChange)="sortData($event)"  class="table --table-responsive  table-border-new" style="border-radius: 10px;">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center " style="border:15px 1px 0px 0;">Action</th>
                                <th  class="text-center ">Template Code</th>
                                <th  class="text-center " >Template Name</th>
                                <th class="text-center"   style="border:0px 15px 0px 0px;">Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="kralistData?.length > 0"  style="    border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            border-bottom: 1px solid #ddd;">
                            <tr *ngFor="let row of kralistData | searchFilter: query | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                
                                <!-- <td class="text-center">{{i+1}}</td> -->
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                      <mat-icon>more_vert</mat-icon>
                                  </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item routerLink="/home/edit-kra-template/{{row.kraTemplateHeaderId}}" routerLinkActive="active">
                                          <mat-icon>edit</mat-icon>
                                          <span>Edit</span>
                                        </button>
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 1"
                                         (click)="updateStatus(row.kraTemplateHeaderId,2)">
                                          <mat-icon>done</mat-icon>
                                          <span>Inactive</span>
                                        </button>
                                        <button mat-menu-item *ngIf="row.status.listTypeValueId == 2" 
                                        (click)="updateStatus(row.kraTemplateHeaderId,1)">
                                          <mat-icon>done</mat-icon>
                                          <span>Active</span>
                                        </button>
                                    </mat-menu>
                                </td>
                                <td class="text-center">{{row.templateCode}}</td>
                                <td class="text-center ">{{ucFirst(row.templateName)}}</td>
                                <td class="active-status text-center">
                                    <span class="btn btn-outline-success btn-sm btn-block" 
                                    *ngIf="row.status.listTypeValueId == 1">
                                      {{row.status.listTypeValueName}}
                                  </span>
    
                                    <span class="btn btn-outline-danger btn-sm btn-block"
                                     *ngIf="row.status.listTypeValueId == 2">
                                      {{row.status.listTypeValueName}}
                                  </span>
                                </td>
                            </tr>
                            
                        </tbody>
                        <tbody *ngIf="nodatafound">
                            <tr>
                                <td colspan="4" class="text-center" style="font-size: 22px; font-family: 'Mulish', sans-serif; font-weight: 800;">No Matching KRA Template Found!</td>
                            </tr>
                        </tbody>
                       
                    </table>

                   
                </div>
              
                
                <!-- <div *ngIf="kralistData?.length > 0 "> -->
                    <!-- <div class="row" style="margin-top: 15px;"> -->
                        <!-- <div class="col-md-8">
                            <div class="showing-record">
                                {{kralistData.length}} rows
                            </div>
                        </div> -->
                         <!-- <div class="col-md-4 offset-8 ">
                            <pagination-controls  (pageChange)="p = $event; beforepage = $event"></pagination-controls>
                        </div>
                    </div> -->
                <!-- </div> -->
                <!-- <div class="col-md-5" style="float: inline-end; padding-left: 65px;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div> -->

                <!-- <div class="col-md-6 " style="float: right; ">
                    <pagination-controls (pageChange)="p = $event" ></pagination-controls>
                </div> -->

                <div class="col-md-12 text-right" *ngIf="!error && !nodatafound && kralistData?.length > 5 && showTable ">
                    
                        <pagination-controls (pageChange)="p = $event" style="float:right;"></pagination-controls >
                    </div>

            </div>
        </div>
    </div>
</div>
 
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>

<!-- <div
     style="text-align: center; font-size: 22px;
    font-family: 'Mulish', sans-serif;
    font-weight: 800; " colspan="8" *ngIf="nodatafound ">No Matching Records Found!
</div> -->

<!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodatafound" style="    margin-top: 250px;">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        
        <h4 align="center" class="h4" style="    margin-top: 14px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 11px;
        ">KRA Template Not Found </h4>
      </div>
    </div>
     </mat-card> -->


<div *ngIf="loader" class="align-items-center text-center">
                    
    <div class="spinner-border" 
    style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;bottom: 0px; " role="status"></div>
</div> 
  