<section>
  <div class="sub-header-element">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <!-- Client Element -->
          <span class="h5"></span>
        </div>
        <div class="">
          <button
            mat-raised-button

            class="searchBtn2"
            (click)="assignclientelements()"
          >
            Add Assign Element
          </button>
          <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
          <div class="row" style="    margin-top: -27px;
    margin-left: -4px;">
            <div class="form-group col-md-3">
              <label
                class="form-control-label"
                style="font-family: 'Mulish', sans-serif"
                >Client Name<span class="star">*</span></label
              >
              <!-- <mat-select
                formControlName="client"
                placeholder="Select Client"
                class=" form-control email-input assign-placeholder"
              > -->
                <!-- <mat-option [value]="null"> Select Client</mat-option>
                <mat-option
                  *ngFor="let row of clientList; let i = index"
                  value="{{ row?.clientId }}"
                >
                  {{ row?.clientName }}
                </mat-option>
              </mat-select> -->
              <input
              type="text"
             
              placeholder="client Name"
              formControlName="client"
              autocomplete="off"
             readonly
             [disabled]="true"
             style="    background: #dddddd87 !important;"
              [value]="clientNames"
              class="form-control email-input select-placeholder"
            />
              <div
                class="valid-msg"
                *ngIf="
                  (validate.client.invalid && validate.client.touched) ||
                  validate.client.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="validate.client.errors?.required"
                  class="text-danger"
                  >Please select client</span
                >
                <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
              </div>
            </div>

            <div class="col-md-4" style="float: right; top: 24px">
              <button
                type="submit"
                mat-raised-button
                class="btn btn-primary searchBtn"
              >
                Search
              </button>
              <button
                style="margin-left: 10px; height: 35px"
                type="button"
                mat-raised-button
                class="btn-clear search-btn mr-2"
                (click)="clearTable()"
              >
                Clear
              </button>
            </div>
          </div>
        </form>
        <!-- <div class="row pb-3">
          <div class="col-md-11 text-right">
            <p class="show-count">Show</p>
          </div>
          <div class="col-md-1 text-left pl-0">
            <select
              [(ngModel)]="pagePerItem"
              (change)="pageChages($event)"
              class="form-control report-filter"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div> -->

        <div *ngIf="tableShow"
          class="table"
          
        >
        <div class="row">
        <!-- <div class="col-md-6">
          <p style="font-family: 'Mulish', sans-serif;margin-top: 10px;">
            <b class="">Elements</b>
          </p>
        </div> -->
        <div class="col-md-6 DesigniLabel1">
          <b>Elements</b>
        </div>
          <div class="col-md-6">
            <div class="text-right" *ngIf="resultData.length > 5">
              <div class="select-pagination m-2">
                <span>Show : </span>
                <select
                  class="select_list new-select_list listDesign"
                  [(ngModel)]="pagePerItem"
                  (click)="pageChages()"
                >
                  <!-- <option class="form-control" value="5">5</option>  -->
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                </select>
              </div>
            </div>
          </div>
        </div>
          <table *ngIf="tableShow"
            class="table" style=margin-top:21px;
            
          >
            <thead class="t-head">
              <tr>
                <th style="border-radius: 15px 1px 0px 0;border-top: 0px;">Action</th>
                <th>Client Name</th>
                <th>Client Code</th>
                <th>PF Salary Limit</th>
                <th>Employee PF</th>
                <th>Employer PF</th>
                <th>ESI Salary Limit</th>
                <th>Employee ESI</th>
                <th  style="border-radius: 0px 15px 0px 0;border-top:0px;">Employer ESI</th>
              </tr>
            </thead>
            <tbody *ngIf="resultData.length > 0"  style="
              border-right: 1px solid #ddd;
              border-left: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
              /* border: none; */
            ">
              <tr
                *ngFor="
                  let row of resultData
                    | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                    | searchFilter : tableSearch;
                  let i = index
                "
              >
                <td class="">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" class="controls-list">
                    <button
                      mat-menu-item
                      routerLink="/home/clientElementassign/{{
                        row.elementClientHeaderId
                      }}"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==1" (click)="updateStatus(row.clientAssignId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==2" (click)="updateStatus(row.clientAssignId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button> -->
                  </mat-menu>
                </td>
                <td>{{ row.client.clientName }}</td>
                <td>{{ row.client.clientCode }}</td>
                <td style="text-align: center">{{ row.pfMaxSalaryAmount }}</td>
                <td style="text-align: center">{{ row.employeePfAmount }}</td>
                <td style="text-align: center">{{ row.employeePfAmount }}</td>
                <td style="text-align: center">{{ row.esiMaxSalaryAmount }}</td>
                <td style="text-align: center">{{ row.employeeEsiAmount }}</td>
                <td style="text-align: center">{{ row.employerEsiAmount }}</td>
                <!-- <td style="text-align: center;">{{row.workingDays}}</td>
                                    <td style="text-align: center;" >{{row.otHours}}</td> -->
                <!-- <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId==1">
                                            {{row.status.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId==2">
                                            {{row.status.listTypeValueName}}
                                        </span>
                                    </td> -->
              </tr>
            </tbody>
            <!-- <tbody>
              <tr>
                <td
                  style="text-align: center"
                  colspan="9"
                  *ngIf="noRecordsFound"
                >
                  No Records Found!
                </td>
              </tr>
            </tbody> -->
            <tbody
            style="border-right: 1px solid #ddd; border-left: 1px solid #ddd"
          >
            <tr>
              <td
                style="
                  text-align: center;
                  font-size: larger;
                  font-weight: bold;
                "
                colspan="8"
                *ngIf="noRecordsFound"
              >
               Assign Elements Records Not Found!
              </td>
            </tr>
          </tbody>
          </table>
        </div>

        <div>
          <div class="row m-1" *ngIf="resultData.length > 5">
            <div class="col-md-9" *ngIf="!this.noRecordsFound">
              <div class="showing-record">{{ resultData.length }} rows</div>
            </div>
            <div class="col-md-3 text-right">
              <pagination-controls
                (pageChange)="p = $event"
                *ngIf="!this.noRecordsFound"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
      role="status"
    ></div>
  </div>