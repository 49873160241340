<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content herderDesign">Financial Calendar</span>
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                    <a routerLink="/home/settings" mat-raised-button title="Back" class="">Back</a>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->



<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">

                <!--  <div class="alert alert-success alert-dismissible" *ngIf="success">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Success!</strong> Indicates a successful or positive action.
                </div> -->

                <div class="row">
       
                    <div class="col-md-8">
                        <div class="row pl-0">
                            <!-- <div class="col-md-4">
                                <input type="search" [(ngModel)]="tableSearch" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" name="keywords" value="" placeholder="Search...">
                            </div> -->
                            <div class="col-md-12">
                                <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                                    <div class="row pl-0">
                                        <div class="col-md-4 form-group">
                                            <!-- <select class="form-select form-select-solid form-select-lg"
                                                appearance="fill" formControlName="branchSearch" >
                                                
                                                <option [value]="null" >- Select Business Unit -</option>
                                                <option *ngFor="let e of branchlistSearch" class="text-color"
                                                    [value]="e.branchId">{{e.branchName}}</option>
                                            </select> -->
                                            <label class="form-control-label"> Branch Name</label>

                                            <mat-select formControlName="branchSearch" placeholder="Select Branch Name" appearance="fill"  class="form-control email-input select-placeholder">
                                                <mat-option [value]="null" >-Select Branch Name-</mat-option>

                                                <mat-option *ngFor="let e of branchlistSearch"  [value]="e.branchId">
                                                  {{e.branchName}} 
                                                </mat-option>
                                              </mat-select>

                                            <!-- (selectionChange)="listTypeValueid($event)" -->
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <label class="form-control-label">Financial Calendar Name</label>

                                            <input type="text" formControlName="calendarNameSearch" id="calendarName" class="form-control email-input select-placeholder" placeholder=" Financial Calendar Name" autocomplete="off">
                                            <!-- <div class="valid-msg" *ngIf="(validate.yearto.invalid && validate.yearto.touched) || validate.yearto.dirty || submitted">
                                               
                                                
                                            </div> -->
                                        </div>
                                        <div class="col-md-4 form-group" style="    margin-top: 23px;">
                                            <button type="submit" mat-raised-button 
                                                class="btn  search-btn " [disabled]="isbtnDisable">Search</button>
                                            <button style="margin-left: 20px;" type="reset" mat-raised-button
                                                class="btn cancelBtn mr-2"
                                                (click)="clearTable()" [disabled]="isbtnDisable">Clear
                                            </button>
                                              
                                        </div>
                                    </div>
                                </form>
                            <!--  <div class="col-md-3 pl-0">
                                <button class="search-btn" mat-raised-button color="primary">Search</button>
                            </div> -->
                        </div>
                    </div>
                    </div>
                    <div class="col-md-4">
                       
                        <div class="row p-0 m-0" *ngIf="resultData.length > 5 && (resultData | mytask : search).length > 0" >

                        <div class="col-xl-12 col-md-12  "   *ngIf="(resultData | mytask : search).length > 5">
                            <div class="select-pagination">
                              <span>Show : </span>
                              <select style="    border-radius: 6px;"
                                class="select_list new-select_list"
                                [(ngModel)]="mySelect"
                                (change)="changefirstpage()">
                                <option class="form-control" value="5">5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                              </select>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="card-body"> -->
                    <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="form-control-label"> Branch Name<span class="star">*</span></label>
                                <!-- <select formControlName="branch" placeholder="- Select >Bussines Unit -" class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null" selected="selected"> --- Select Bussines Unit --- </option>
                                    <option *ngFor="let row of branchlist; let i=index;" value="{{row.branchId}}">{{row.branchName}}</option>
                                </select> -->
                                <mat-select formControlName="branch" placeholder="Select  Branch Name"  class="form-control email-input select-placeholder">
                                    <mat-option [value]="null" selected="selected" >-Select  Branch Name-</mat-option>
                                    <mat-option *ngFor="let row of branchlist; let i=index;" value="{{row.branchId}}">{{row.branchName}} 
                                    </mat-option>
                                  </mat-select>
                                <div class="valid-msg" *ngIf="(validate?.branch?.invalid && validate?.branch?.touched) || validate?.branch?.dirty || submitted">
                                    <span *ngIf="validate?.branch.errors?.required" class="text-danger">Please enter Branch Name</span>
                                    <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span> -->
                                   
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label class="form-control-label">Financial Calendar Name<span class="star">*</span></label>
                                <input type="text" formControlName="calendarName" id="calendarName"
                                 (keyup)="validatecalendarname($event)" 
                                 class="form-control email-input select-placeholder"
                                  placeholder=" Financial Calendar Name" autocomplete="off">
                                <div class="valid-msg" *ngIf="(validate.calendarName.invalid && validate.calendarName.touched) || validate.calendarName.dirty || submitted">
                                    <span *ngIf="validate.calendarName.errors?.required" class="text-danger">Please Enter Financial Calendar Name</span>
                                    <span *ngIf="validate.calendarName.errors?.pattern" class="text-danger">Please Enter Valid Financial Calendar Name</span>
                                </div>
                                <span *ngIf="calendarnameexist" class="text-danger">{{existMsg}}</span>
                            </div>
                            <div class="form-group col-md-3" style="height: 80px;">
                                <label class="form-control-label">Financial Calendar From <span class="star">*</span></label>
                                <input formControlName="yearfrom" matInput [matDatepicker]="yearfrom" readonly  class="form-control email-input select-placeholder" 
                                    [max]="today" placeholder="Financial Calendar From">
                                <mat-datepicker-toggle matSuffix [for]="yearfrom" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                <mat-datepicker #yearfrom   startView="multi-year"
                                (dateChange)="onDateChange($event)"
                                panelClass="example-month-picker"></mat-datepicker>

                                <div class="valid-msg" *ngIf="(validate.yearfrom.invalid && validate.yearfrom.touched) || validate.yearfrom.dirty || submitted">
                                    <span class="text-danger" *ngIf="validate.yearfrom.errors?.required">Please select Financial Calendar From</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3" style="height: 80px;">
                                <label class="form-control-label">Financial Calendar To<span class="star">*</span></label>
                                <input formControlName="yearto" id="yearto"  matInput [matDatepicker]="yearto" readonly class="form-control email-input select-placeholder"
                                    [max]="today" [min]="postForm.value.yearfrom" placeholder="Financial Calendar To">
                                <mat-datepicker-toggle matSuffix [for]="yearto" style="float: right;position: relative;bottom: 38px;"></mat-datepicker-toggle>
                                <mat-datepicker #yearto></mat-datepicker>

                                <div class="valid-msg" *ngIf="(validate.yearto.invalid && validate.yearto.touched) || validate.yearto.dirty || submitted">
                                    <span class="text-danger" *ngIf="validate.yearto.errors?.required">Please select financial calendar to</span>
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label class="form-control-label">Description</label>
                                <textarea   formControlName="description" 
                                id="description" 
                                class="form-control email-input select-placeholder"
                                 placeholder="Description"
                                 style="font-size: 15px; overflow-x: auto; overflow-y: hidden; min-height: 43px; border: none; padding: 8px;"
                                 ></textarea>
                                <div class="valid-msg" *ngIf="(validate.description.invalid && validate.description.touched) || validate.description.dirty || submitted">
                                    <span *ngIf="validate.description.errors?.pattern" class="text-danger">Please Enter Valid Description</span>
                                    <!-- <span *ngIf="validate.monthfrom.errors?.pattern" class="text-danger">Please enter valid Branch Name</span> -->
                                </div>
                            </div>
                            <div class="col-md-4 saveclearDesign">
                                <button type="reset"   mat-raised-button class="mr-2"
                                style="    margin-right: 20px;
    height: 32px;"      class="btn cancelBtn  mr-2" [disabled]="isbtnDisable">Clear</button>
                                <!-- <a routerLink="/home/financialyear" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2">
                                    Cancel
                                </a> -->
                                <button type="submit" mat-raised-button  class="btn  search-btn "  [disabled]="isbtnDisable" style="    position: relative;
    top: 0px;">Save</button>
                            </div>
                        </div>
                        <div class="row">
                             <div class="col-md-8">
                                <span style="float:left;color:red">
                                    <b>Note : </b> After submission, months will be created for the selected financial year
                                </span>
                             </div>
                           
                        </div>
                    </form>
                <!-- </div> -->
                 <div class="table-responsive">
                    <table class="table " style="border-top-left-radius: 10px; border-top-right-radius: 10px;margin-top: 8px;" *ngIf="tableShow">
                        <thead class="t-head">
                            <tr>
                                <!-- <th class="text-center">S.No</th> -->
                                <th class="text-center">Action</th>
                                <th>Branch Name</th>
                                <th>Financial Calendar Name</th>
                                <th>Financial From Month / Year</th>
                                <th>Financial To Month / Year</th>
                                <th>Description</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody  style="    border-left: 1px solid #ddd;
                    border-right: 1px solid #ddd;">
                            <tr *ngFor="let row of resultData | mytask : search
  | paginate : { itemsPerPage: mySelect, currentPage: p }; let i=index;">
                                <!-- <td class="text-center">{{i+1}}</td> -->
                                <td class="text-center">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
    
                                    <mat-menu #menu="matMenu" class="controls-list">
                                        <button mat-menu-item (click)="updateform(row)"  >
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item (click)="viewdata(row)"  >
                                            <mat-icon>remove_red_eye</mat-icon>
                                            <span>View</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.financialYearStatus.listTypeValueId == 1" (click)="updateStatus(row.financialYearId,2)">
                                            <mat-icon>done</mat-icon>
                                            <span>Inactive</span>
                                        </button>
    
                                        <button mat-menu-item *ngIf="row.financialYearStatus.listTypeValueId == 2" (click)="updateStatus(row.financialYearId,1)">
                                            <mat-icon>done</mat-icon>
                                            <span>Active</span>
                                        </button>
                                    </mat-menu>
                                </td>
    
                                <td>{{row.branch.branchName}}</td>
                                <td>{{row.financialYearName}}</td>
                                <td>{{row.financialFromMonth}} - {{row.financialFromYear}}</td>
                                <td>{{row.financialToMonth}} -{{row.financialToYear}}</td>
                                <td>{{ row.financialDescription ? row.financialDescription : '-' }}</td>
                                <!-- capitalizeWordsName -->
                                <td class="active-status text-center">
                                    <span class=" btn-outline-success " *ngIf="row.financialYearStatus.listTypeValueId == 1">
                                        {{row.financialYearStatus.listTypeValueName}}
                                    </span>
    
                                    <span class=" btn-outline-danger " *ngIf="row.financialYearStatus.listTypeValueId == 2">
                                        {{row.financialYearStatus.listTypeValueName}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td style="text-align: center;" colspan="7" *ngIf="noRecordsFound">No Records Found!</td>
                            </tr>
                        </tbody> -->
                        <tbody style="    border-left: 1px solid #ddd;
                        border-right: 1px solid #ddd;">
                                                        <tr>
                                                            <td   style="
                                                            text-align: center;
                                                            font-size: larger;
                                                            font-weight: bold;
                                                          " colspan="11" 
                                                          *ngIf="noRecordsFound">Financial Calender Records Not Found!</td>
                                                        </tr>
                                                    </tbody>
                                                    
                                                      <!-- <div class="row" *ngIf="tableShow">
                                                        <div class="col-md-12"*ngIf="resultData.length > 0">
                                                            <div class="showing-record"  >
                                                               
                                                                {{resultData.length}} rows
                                                            </div>
                                                        </div>
                                                       
                                                        </div> -->
                    </table>
                    <div 
                class="pagination-content "
                *ngIf="resultData?.length > 5 && (resultData | mytask : search).length > 0 && tableShow"
                id="page"
              >
                <pagination-controls  *ngIf="(resultData | mytask : search).length > 5"
                    style="position: relative; float: inline-end;top: 7px;"
                  (pageChange)="p = $event"
                  align="end"
                ></pagination-controls>
              </div>
                 </div>
             

                <!-- <div class="row"  *ngIf="resultData.length != 0">
                    <div class="col-md-4">
                        <div class="showing-record"> -->
                            <!-- <ng-template pTemplate="paginatorleft" let-state>
                                Showing {{(state.page  * state.rows) + 1}} to {{state.rows * (state.page + 1)}} of {{resultData.length}} entries
                            </ng-template> -->
                            <!-- {{resultData.length}} rows
                        </div>
                    </div>
                    <div class="col-md-8 text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 66% !important;
    right: 48% !important;" role="status"></div>
  </div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>