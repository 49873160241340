<div class="container-fluid p-0">
    <div class="col-lg-12 d-flex common-section-login">
        <!-- <div class="col-lg-7 login-left-section">
         <img src="assets/uploads/login-bg.png" class="login-image image">
      </div> -->
        <div class="logo-section-New text-center">

            <div class="login-section">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="card border-0 p-5">
                        <div class="">
                            <img src="assets/uploads/HCM_Logo_witho.png" class="login-logo my-2 img-responsive" alt="" matTooltip="JesperApps HCM" />
                            <!-- <div class="facebook text-center mr-3"><div class="fa fa-facebook"></div></div>
                        <div class="twitter text-center mr-3"><div class="fa fa-twitter"></div></div>
                        <div class="linkedin text-center mr-3"><div class="fa fa-linkedin"></div></div> -->
                        </div>
                        <div class="row">
                            <!-- <h6 class="access-account px-3">New to eNote?
                              <a routerLink="register" class="ml-auto mb-0 text-sm new-account">
                                  <span>Create an account</span>
                              </a>
                          </h6> -->
                            <!-- <div class="line"></div>
                        <small class="or text-center">Or</small>
                        <div class="line"></div> -->
                        </div>
                        <div class="row px-3 pt-4">
                            <label class="mb-1"><h6 class="mb-0 text-sm login-label">Email  <span class="star">*</span></h6></label>
                            <input formControlName="email" class="form-control form-control-lg form-control-solid" type="text" placeholder="Enter User Name / Email" [ngClass]="{ 'is-invalid': submitted }">
                            <div class="valid-msg" *ngIf="(validate.email.invalid && validate.email.touched ) || validate.email.dirty || submitted">
                                <span *ngIf="validate.email.errors?.required" class="text-danger">Please enter email</span>
                                <span *ngIf="validate.email.errors?.pattern" class="text-danger">Please enter valid email</span>
                            </div>
                            <!-- <div *ngIf="email.errors?.['required']">First Name is required</div> -->
                            <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                          </div> -->
                        </div>
                        <div class="row px-3 mt-3 password-box">
                            <label class="mb-1"><h6 class="mb-0 text-sm login-label">Password <span class="star">*</span></h6></label>
                            <!-- <a routerLink="forgot-password" class="forgot-password pl-2 mb-0 text-sm">
                              Forgot Password?
                          </a> -->
                            <input [type]="password" class="form-control form-control-lg form-control-solid" formControlName="password" id="password" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted  }">
                            <!-- <input type="password" class="form-control form-control-lg form-control-solid" formControlName="password" id="password" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted  }"> -->
                            <span class="view-password" (click)="showPassword()">
                              <i class="fa fa-eye" *ngIf="!show"></i>
                              <i class="fa fa-eye-slash" *ngIf="show"></i>
                            </span>
                            <div class="valid-msg" *ngIf="(validate.password.invalid && validate.password.touched) || validate.password.dirty || submitted">
                                <span *ngIf="validate.password.errors?.required" class="text-danger">Please enter password</span>
                            </div>

                            <!-- <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                          </div> -->
                            <span>
                          <!-- <a class="view-password" href="javascript:void(0);">
                            <i class="view_icon fa fa-eye-slash"></i>
                          </a> -->
                        </span>
                        </div>


                        <!-- <div class="row px-3 mb-4 mt-2">
                          <div class="custom-control custom-checkbox custom-control-inline">
                              <input id="chk1" type="checkbox" name="chk" class="custom-control-input">
                              <label for="chk1" class="custom-control-label text-sm">Remember me</label>
                          </div>
                      </div> -->
                        <div class="row px-3 mt-4">
                            <button type="submit" class="btn btn-blue text-center w-100">Sign in</button>
                        </div>
                        <span class="err-msg">{{errorMsg}}</span>
                        <!--  <div class="or-tag">
                          <mat-divider class="my-1"></mat-divider>
                          <span class="divider-text">Or sign in with</span>
                      </div> -->

                        <!-- <div class="row mb-4 px-3">
                        <small class="font-weight-bold">Don't have an account? <a class="text-danger ">Register</a></small>
                    </div> -->
                        <!-- <div class="social-logins col-ms-12 py-2">
                          <a class="google-login" routerLink="google-login" target="_blank">
                              <img src="assets/uploads/google.png" height="16px" width="16px"> Google
                          </a>
                          <a class="facebook-login ml-3" routerLink="google-login" target="_blank">
                              <img src="assets/uploads/facebook.png" height="20px" width="20px"> Facebook
                          </a>
                      </div>
                      <div class="copyrights">
                          <span class=""> Developed and Maintained by <a href="https://www.jesperapps.com/" target="_blank" title="Jesperapps">JESPERAPPS</a> </span>
                      </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Footer Start here -->
<!-- <div class="bg-blue p-2">
  <div class="">
      <small class="copyright">
      Copyright &copy; 2022. All rights reserved.
    </small>
      <div class="social-contact ">
          Developed and Maintained by <a href="https://www.jesperapps.com/" target="_blank" title="Jesperapps">Jesperapps</a>
      </div>
  </div>
</div> -->