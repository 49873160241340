<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Wage Register</span>
                </div>
                <div class="sub-header-buttons">
                    <a title="Download PDF"  class="btn text-danger">                
                            <i class="fa fa-file-pdf-o" (click)="printPdf()"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Client<span class="star"> *</span></label> 
                                <select formControlName="client" (change)="getclientEmployees()"  class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null"> --- Select Client --- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select>    
                                <!-- <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                    <span *ngIf="validate.client.errors?.required" class="text-danger">Please select client</span>
                                     <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  
                                </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                                <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                                    search
                                </mat-icon> -->
                                <label class="col-form-label">Employee</label>
                                <input type="text"matInput  placeholder="Search Employee"   
                                            formControlName="employeecode"
                                            [readonly]="!filterForm.value.client || filterForm.value.client == 'null'"
                                            class="form-control" (keyup)="empSearch($event)">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                            </mat-option> -->
                                        <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                        {{emp.employeeNumber}}- {{emp.firstName}}  
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="material-symbols-outlined" style="position:absolute;top:46px;right: 19px;">
                                        search
                                    </mat-icon>
                                <!-- <div class="valid-msg" *ngIf="submitted">        
                                    <span *ngIf="validate.employee.errors?.required" class="text-danger">Please select employee</span> 
                                </div> -->
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Financial year<span class="star"> *</span></label> 
                                <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                    <option [value]=null> -- Select Financialyear --- </option>
                                    <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="col-form-label">Financial Period<span class="star"> *</span></label> 
                                <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]=null> -- Select Period --- </option>
                                    <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search</button>
                            </div>
                        </div>
                    </form>
                    <div class="row pb-3">
                            <div class="col-md-11 text-right">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left p1-0">
                                <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                    </div>
                       
                       <div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <!-- <p><b>EMPLOYEE RATING</b></p> -->
                        <table class="table table-responsive2 --table-bordered table-border-new" style="width: 135%; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                            <thead class="t-head">
                                <tr>
                                    <th>SI No</th>
                                    <th>Reg SN  Number <br> ID No</th>
                                    <th>Name of the Worker</th>
                                    <th>Days Worked</th>
                                    <th>Basic & DA  HRA </th>
                                    <th [innerHTML]=" this.variablesHeader"></th>
                                    <th>OT Wages <br> LW/EL/NFH OTH</th>
                                    <th>Gross Wages</th>
                                    <th>PF <br>  ESI <br> LWF </th>
                                    <th>ADV PAID</th>
                                    <th>AdRePend <br> AdvReco  <br> AdvPenReco</th>
                                    <th>DednImp <br> DednPen  <br> Dedn Md</th>
                                    <th>PendRec <br> othDedn  <br> P Tax</th>
                                    <th>Total Dedn</th>
                                    <th>Net Wages</th>
                                    <th>Date Paid</th>
                                    <th>Rate</th>
                                    <th>Sign ,Ac / No</th>
                                    <!-- <th>Remarks</th> -->
                                </tr>
                            </thead>
                            <tbody *ngIf="resultData.length>0">
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                              
                                    <td>{{i+1}}</td>
                                    <td>{{row.employee.employeeNumber}} <br> PF No :  {{row.employee.pfNumber}}  <br> ESI No :  {{row.employee.esiNumber}} </td>          
                                    <td>{{row.employee.firstName}} <br> {{row.employee.uanNumber}}</td>
                                    <td>{{row.employeeWorkingDays ? row.employeeWorkingDays.workingDays : 0}}</td>
                                    <td  [innerHTML]="row.basichra"> </td>
                                    <td  [innerHTML]="row.variablesamount"> </td>
                                     <td  [innerHTML]="row.otlw" ></td>
                                   <td>{{row.totalAmount?.toFixed(2)}}</td>
                                   <td [innerHTML]="row.pfesi"  ></td>
                                   <td>0</td>
                                   <td>Nil <br>Nil <br> Nil </td>
                                   <td>Nil <br>Nil <br> Nil </td>
                                   <td>Nil <br>Nil <br> {{row.ptax}} </td>
                                   <td> {{row.totaldeductions}} </td>
                                   <td> {{(row.totalAmount - row.totaldeductions).toFixed(2)}}</td>
                                   <td> {{ this.datepaid}}</td>
                                   <td> </td>
                                   <td> <br>   {{row.employee.employeeInfo.bankAccountNo ?row.employee.employeeInfo.bankAccountNo : "" }} <br> {{row.employee.employeeInfo.bankName ?row.employee.employeeInfo.bankName : "" }} </td>
                                   <!-- <td> </td> -->
                                </tr>
                              
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="19" *ngIf="noRecordsFound">No Records Found!</td>
                                </tr> 
                            </tbody> 
                        </table>
                     </div>
                    
                     <div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>


<div  id="pdfTable" #pdfTable style="display: none;">   
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table items table-bordered table-condensed table-hover" style="margin: 0;width: 100%;" data-pdfmake="{'widths': ['*','*','*','*','*','*']}">
                                <tr>
                                    <td style="text-align: center;border:1px solid #ccc;padding: 0;" colspan="6">
                                        <div style="font-size:13px;font-weight:400;margin:7px 0px 7px 0px;;line-height:1;text-align: center;">Form No 15 </div>
                                        <div style="font-size:13px;font-weight:400;margin:7px 0px 7px 0px;;line-height:1;text-align: center;">(Prescribed Under Rules 87 and 88  of the Tamilnadu Contract Factories,Rules 1950) </div>
                                        <div  style="font-size:15px;font-weight:100;margin:7px 0px 7px 0px;line-height:1;text-align: center;"><b> Register of Leave with Wages</b></div>
                                        <div  style="font-size:15px;font-weight:100;margin:7px 0px 7px 0px;line-height:1;text-align: center;"><b>Part 2</b></div>
                                        <div *ngIf="resultData!=undefined" style="font-size:18px;font-weight:700;margin:7px 0px 7px 0px;"><b>Register of  wages for the  Month of {{period}}</b></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="" colspan="4" style="border:1px solid #ccc;margin:0px 0px 10px 0px;">
                                        <table data-pdfmake="{'widths': ['*','*','*']}" style="border:0px;width: 100%;">
                                            <tr>
                                                <td style="border:0px;border-bottom:0px;">Name & Address of the factory   :</td>
                                                <td colspan="2" style="border:0px;border-bottom:0px;">{{clientaddressobj ? clientaddressobj.clientCode :  ""}}
                                                    <p class="header" style="font-size:20px;font-weight:700;margin: 0px 0px 0px 0px ;text-align: left;"><b>{{userData.organization.organizationName}}</b></p>
                                                    <span class="header" style="font-size:13px;font-weight:400;margin: 0px 0px 0px 0px ;line-height:1;text-align: left;">{{userData.organization.address}}, {{userData.organization.city.cityName}}, {{userData.organization.state.stateName}}-{{userData.organization.postalCode}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border:0px; border-bottom:0px;"> Name & Address of the Occupier/Pay Master   :</td>
                                                <td colspan="2" style="border:0px;border-bottom:0px;">                                                    
                                                    <p class="header" *ngIf="clientaddressobj != undefined" style="font-size:20px;font-weight:700;margin: 0px 0px 0px 0px ;line-height:1;text-align: left;"><b> M/S {{clientaddressobj.clientName}}</b></p>
                                                    <span class="header" *ngIf="clientaddressobj != undefined" style="font-size:13px;font-weight:400;margin: 0px 0px 0px 0px ;line-height:1;text-align: left;">{{clientaddressobj.addressLine1}} {{clientaddressobj.addressLine2}}, {{clientaddressobj.city ? clientaddressobj.city.cityName : ""}}, {{clientaddressobj.state ? clientaddressobj.state.stateName : ""}}-{{clientaddressobj.postalCode ? clientaddressobj.postalCode: ""}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border:0px;border-bottom:0px;"> Name of the Managet / InCharge   :  <br>
                                                    Registration No. :</td>
                                                <td colspan="2" style="border:0px;border-bottom:0px;">                                                    
                                                    
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="" colspan="2" style="border:1px solid #ccc;margin:0px 0px 10px 0px;">
                                        <table data-pdfmake="{'widths': ['*','*','*','*']}">
                                            <tr>
                                                <td colspan="4" style="border:0px;border-bottom:0px;">
                                                    Total Number of Persons Employed
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Men</td>
                                                <td>Women</td>
                                                <td>Male Adolescent</td>
                                                <td>Female Adolescent</td>
                                            </tr>
                                            <tr>
                                                <td><br></td>
                                                <td><br></td>
                                                <td><br></td>
                                                <td><br></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" style="text-align: center;border:1px solid #ccc;padding: 0;" >
                                        Wage Period from to (Montly) :  From {{salarystartdate}}  to {{salaryenddate}}
                                    </td>
                                </tr>
                            </table>
                            <table class="table  --table-bordered table-border-new" style="width: 100%;font-size: 12px" data-pdfmake="{'widths': ['auto','*','*','auto','auto','auto','auto','auto','auto','auto', 'auto','auto','auto','auto','auto',60,'auto','auto','auto']}">
                                <thead class="t-head">
                                    <tr>
                                        <th>SI No</th>
                                        <th>Reg SN  Number <br> ID No</th>
                                        <th>Name of the Worker</th>
                                        <th>Days Worked</th>
                                        <th>Basic & DA  HRA </th>
                                        <th [innerHTML]=" this.variablesHeader"></th>
                                        <th>OT Wages <br> LW/EL/NFH OTH</th>
                                        <th>Gross Wages</th>
                                        <th>PF <br>  ESI <br> LWF </th>
                                        <th>ADV PAID</th>
                                        <th>AdRePend <br> AdvReco  <br> AdvPenReco</th>
                                        <th>DednImp <br> DednPen  <br> Dedn Md</th>
                                        <th>PendRec <br> othDedn  <br> P Tax</th>
                                        <th>Total Dedn</th>
                                        <th>Net Wages</th>
                                        <th>Date Paid</th>
                                        <th>Rate</th>
                                        <th>Sign ,Ac / No</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr *ngFor="let row of resultData  let i=index;">
                                  
                                        <td>{{i+1}}</td>
                                        <td>{{row.employee.employeeNumber}} <br> PF No :  {{row.employee.pfNumber}}  <br> ESI No :  {{row.employee.esiNumber}} </td>          
                                        <td>{{row.employee.firstName}} <br> {{row.employee.uanNumber}}</td>
                                        <td>{{row.employeeWorkingDays ? row.employeeWorkingDays.workingDays : 0}}</td>
                                        <td  [innerHTML]="row.basichra"> </td>
                                        <td  [innerHTML]="row.variablesamount"> </td>
                                         <td  [innerHTML]="row.otlw" ></td>
                                       <td>{{row.totalAmount?.toFixed(2)}}</td>
                                       <td [innerHTML]="row.pfesi"  ></td>
                                       <td>0</td>
                                       <td>Nil <br>Nil <br> Nil </td>
                                       <td>Nil <br>Nil <br> Nil </td>
                                       <td>Nil <br>Nil <br> {{row.ptax}} </td>
                                       <td> {{row.totaldeductions}} </td>
                                       <td> {{(row.totalAmount - row.totaldeductions).toFixed(2)}}</td>
                                       <td>{{ this.datepaid}} </td>
                                       <td> </td>
                                       <td> <br>   {{row.employee.employeeInfo.bankAccountNo ?row.employee.employeeInfo.bankAccountNo : "" }} <br> {{row.employee.employeeInfo.bankName ?row.employee.employeeInfo.bankName : "" }} </td>
                                       <td> </td>
                                    </tr>
                                  
                                </tbody>
                               
                            </table>
                        </div>    
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>



