
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
    >
      <div class="no-v-scroll">
        <div class="no-v-scroll" style="margin-top: 30%;">
          <mat-card class="sideNav-card">
            <div class="upcomingleave">
              <h5 class="upcomingleave-title" style="font-weight: 600;font-size: large;">Upcoming Leave</h5>
              <div class="upcomingleave-content" style="padding-top: 10px;">
                <table>
                  <tr *ngFor="let e of totaldays; let i = index">
                    <td class="index" style="width: 10%;">{{i + 1}}.</td>
                    <td class="description" style="width: 70%;">{{e.description}}</td>
                    <td class="appliedDate" style="width: 30%;">{{e.appliedDate | date: 'MMM d'}}</td>
                  </tr>
                  <tbody *ngIf="noData">
                    <tr>
                        <td class="text-center" colspan="5"  style="display: flex; justify-content: center; align-items: center; height: 500px;">
                            <span style=" font-family: 'Mulish', sans-serif;
                            font-size: 21px; 
                            font-weight: 770;
                            color: black;">No Upcoming Leave</span>
                        </td>
                    </tr>
                     
                </tbody>
                <div *ngIf="loader2" class="align-items-center text-center">
                  <div class="spinner-border" style="width: 2rem;height: 2rem;top: 45%;right: 44%;" role="status"></div>
              </div>
                </table>
              </div>
            </div>
            <div class="hints">
              <div class="title-row">Hints<span class="text-danger">*</span></div>
              <table>
                <tr>
                  <td class="icon">
                    <div class="round-shape national-holiday"></div>
                  </td>
                  <td class="description">&nbsp;National Holiday</td>
                </tr>
                <tr>
                  <td class="icon">
                    <div class="round-shape personal-leave"></div>
                  </td>
                  <td class="description">&nbsp;Personal Leave</td>
                </tr>
                <tr>
                  <td class="icon">
                    <div class="round-shape exception"></div>
                  </td>
                  <td class="description">&nbsp;Exception</td>
                </tr>
              </table>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <section>
        <div class="sub-header" style="padding: 9px;">
            <div class="col-md-8 mb-1">
                <div class="row">
                    <div class="col-sm-6">
                      <span class="h5 sub-content" style="font-size:x-large;top:8px">Calendar</span>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <div *ngIf="!loader">
      <div class="common-content">
        <mat-card class="large-calendar">
          <full-calendar
            #calendar
            #fullcalendar
            min="startYear"
            max="endYear"
            *ngIf="calendarVisible"
            [options]="calendarOptions"
            plugins="calendarOptions.plugins"
          ></full-calendar>
        </mat-card>
      </div>
    </div>
     
      <div *ngIf="loader" class="spinner-container">
        <div class="spinner-border" role="status"></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  