import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';
@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss']
})
export class AddDepartmentComponent implements OnInit
{
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  employeetData:any;

  departCodeExistStatus:any;
  departNameExistStatus:any;
departmentId: any;
isbtnDisable: boolean=false;
  clientId: any;

  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddDepartmentComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    /* Add Validation Department Data */
    this.postForm = this.formBuilder.group({
      departmentCode : ['' ,[Validators.required,Validators.pattern(/^[A-Za-z0-9_-]*$/)]],
      departmentName : ['',[Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      departmentDescription : [null,Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")],
    });
    this.departmentId=this.edit_data?.departmentId;
    console.log("this.departmentId::",this.departmentId);

    this.clientId = this.employeetData?.employee?.client?.clientId
    console.log("clientId'::",this.clientId);

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)

    if(this.edit_data)
    {
      this.loader=true
      this.settingsservice.getDepartmentById(this.edit_data.departmentId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;
        this.loader=false

        this.postForm.patchValue({
          departmentCode: this.resultData.departmentCode,
          departmentName: this.resultData.departmentName,
          departmentDescription: this.resultData.departmentDescription,
        });
      });
    }
  }

  // convenience getter for easy access to form fields
  get validate() { return this.postForm.controls; }

  onSubmit() //form Submit Function
  {

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)

    let clientId = this.employeetData?.employee?.client?.clientId
    console.log('clientId'+clientId);

    this.submitted = true;

    //stop here if form is invalid
    //if (this.postForm.invalid) {return;}
    if (this.postForm.invalid || this.departCodeExistStatus == true || this.departNameExistStatus == true) {return;}


   
    
    if(this.edit_data) //Edit
    {

      if(organizationId){
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['departmentId'] = this.edit_data.departmentId;
      postValues['departmentStatus'] = this.edit_data.departmentStatus;

      this.backdrop = true;
      this.loader=true;
      this.isbtnDisable=true

      this.settingsservice.editDepartment(this.edit_data.departmentId,postValues)
      .subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/department' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.isbtnDisable=true;

        // this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        // this.isbtnDisable=false;

        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
      if(clientId){
      let postValues = this.postForm.value;

      postValues['client'] = {clientId: this.clientId}
      postValues['departmentId'] = this.edit_data.departmentId;
      postValues['departmentStatus'] = this.edit_data.departmentStatus;

      this.backdrop = true;
      this.loader=true;
      this.isbtnDisable=true

      this.settingsservice.editDepartment(this.edit_data.departmentId,postValues)
      .subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/department' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.isbtnDisable=true;

        // this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        // this.isbtnDisable=false;

        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }

    }
    else //Create
    {
      if(organizationId){
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      // if(this.postForm.valid && this.departCodeExistStatus == true || this.departNameExistStatus == true)
      // {
      //   console.log("if condition");
      //   return
      // }
      this.isbtnDisable=true;


      this.settingsservice.createDepartment(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        // this.success = true;
        this.isbtnDisable=true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        // this.isbtnDisable=false;

        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }

    if(clientId){
      let postValues = this.postForm.value;
      postValues['client'] = {clientId: this.clientId}

      // if(this.postForm.valid && this.departCodeExistStatus == true || this.departNameExistStatus == true)
      // {
      //   console.log("if condition");
      //   return
      // }
      this.isbtnDisable=true;


      this.settingsservice.createDepartment(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        // this.success = true;
        this.isbtnDisable=true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        // this.isbtnDisable=false;

        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    }
  }
  /* onReset()
  {
      this.submitted = false;
      this.postForm.reset();
  } */

      projectCode() {
        return this.validate.departmentCode.hasError('required')
          ? 'Please Enter Department Code'
          : '';
         
      }

  checkExistDepartmentCode(val)
  {    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

  let organizationId = this.employeetData.organization.organizationId;
  console.log('organizationId'+organizationId)
    /* let editData = 0;
      if(this.edit_data)
      {
        editData = this.edit_data;
      }
    */

    if(this.postForm.value.departmentCode.length != 0)
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}

      this.settingsservice.createCheckExistDepartmentCode(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.departCodeExistStatus = false;
        }
        else if( result.statusCode > 400)
        {
          this.departCodeExistStatus = true;
        }
      })
    }
    else
    {
      this.departCodeExistStatus = false;
    }
  }

  checkExistDepartmentName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    if(this.edit_data)
    {
      editData = this.edit_data;
    }

    if(this.postForm.value.departmentName.length != 0)
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      this.settingsservice.createCheckExistDepartmentName(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.departNameExistStatus = false;
        }
        else if(result.statusCode > 400)
        {
          this.departNameExistStatus = true;
        }
      })
    }
    else
    {
      this.departNameExistStatus = false;
    }
  }
}
