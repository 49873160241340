import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import {
  httpOptions,
  responseMsgTimeOut,
  redirectMsgTimeOut,
} from '../../providers/properties';
import { DatePipe, JsonPipe } from '@angular/common';
import { AdminService } from '../../providers/admin.service';
import { PayrollService } from 'src/app/providers/payroll.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatclientcomponentsComponent } from '../matclientcomponents/matclientcomponents.component';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { invalid } from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
})
export class AddEmployeeComponent implements OnInit {
  @ViewChild('picker1') startDatePicker!: MatDatepicker<any>;
  @ViewChild('picker') endDatePicker!: MatDatepicker<any>;
  // today: Date = new Date();
  minLastWorkingDate: Date | null = null; // Minimum date for "Last Working Date"

  basicInformationDetails!: FormGroup;
  employmentDetails!: FormGroup;
  idDetails!: FormGroup;
  addressDetails!: FormGroup;
  bankDetails!: FormGroup;
  loginDetails!: FormGroup;
  mobilenumberlength: any = false;
  basic_step = false;
  employment_step = false;
  id_step = false;
  address_step = false;
  bank_step = false;
  login_step = false;
  mobileVali = false;
  deletedrows: any = [];
  step = 1;
  setDatebool: boolean = false;
  setAdharbool: boolean = false;
  submitted: boolean = false;
  errorMsg: any;
  resultData: any;

  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;

  tabindex: any = 1; //Employee Profile Tabs Default My Profile 1
  empList: any = [];
  activeRolesData: any;
  activeStreamData: any;
  genderLov: any;
  bloodGroupLov: any;
  employementTypeLov: any;
  activeBranchData: any;
  activeDesignationData: any;
  activeDepartmentData: any;
  clientelements: any;
  maxpfamount: any;
  max: any;
  employeePfAmount: any;
  employerPfAmount: any;
  maxesiamount: any;
  employeeEsiAmount: any;
  employerEsiAmount: any;
  elementlist: any = [];

  activeCountryData: any;

  stateList: any;
  districtList: any;
  cityList: any;

  perstateList: any;
  perdistrictList: any;
  percityList: any;

  martialStatusLov: any;
  payFrequencyStatusLov: any;
  insertEmployeeId: any;

  autoCompleteCountrySearch: any = 0; //Auto Complete Search
  countryData: any;

  autoCompleteStateSearch: any = 0; //Auto Complete Search
  stateData: any;

  autoCompleteDistrictSearch: any = 0; //Auto Complete Search
  districtData: any;

  autoCompleteCitySearch: any = 0; //Auto Complete Search
  cityData: any;

  permenantautoCompleteCountrySearch: any = 0; //Auto Complete Search
  permenantcountryData: any;

  permenantautoCompleteStateSearch: any = 0; //Auto Complete Search
  permenantstateData: any;

  permenantautoCompleteDistrictSearch: any = 0; //Auto Complete Search
  permenantdistrictData: any;

  permenantautoCompleteCitySearch: any = 0; //Auto Complete Search
  permenantcityData: any;

  /*
  dbCountryName:any;
  dbCountryId :0;

  dbStateName:any;
  dbStateId:0;

  dbDistrictName :any;
  dbDistrictId :0;

  dbCityName :any;
  dbCityId :0; */

  today: any = new Date();

  employeeNumberExistStatus: any;
  emailExistStatus: any;
  alternateemailExistStatus: any;
  mobileNumberExistStatus: any;
  alternateMobileNumberExistStatus: any;
  organizationId: any;
  isMasterSel: boolean;
  filename: any;
  fileByte: any;
  fileSize: any;
  fileType: any;
  clientList: any;
  employeetData: any;
  employeeId: any;
  employeeInfoId: any;
  employeeStatus: any;
  profileImage: any;
  fileblob: any;
  userdata: any;
  fileId: any;
  cliId: any;
  OrganizationDetail: any;
  maxPhoneNumberLength: any;
  EmployeeID: any;
  inputValue: string = '';
  editCountryId: any;
  editStateId: any;
  editDitrictId: any;
  editCityId: any;
  identityDocumentTypes: any;
  documentypes: any;
  employeeIndetityAttachment: any = [];
  identitydocuments: any = [];
  touchedRows: any = [];
  attachmentypesexit: boolean;
  lastName: string = '';
  dob: any;
  typedValue1: string = '';
  finalPassword: string = '';
  formattedDate: string;
  ClientName: any;
  minHours: number = 0;
  minDays: number = 0;
  // isSelectDisabled = true;
  basicInfoDisabled: boolean = true;
  employeeDetailsDisabled: boolean = true;
  iDInformation: boolean = true;
  addBankDetails: boolean = true;
  addressDisabled: boolean = true;
  show_error_msg: boolean = false;
  startdate: any;
  storeConfirmDate: any;
  confirmationDates: any;
  setValid: boolean = false;

  invalidInput: boolean = false;
  setDocument: boolean = false;
  setAdharbools: boolean = false;
  setbankbool: boolean = false;
  copyCountryId: any;
  copyCountryName: any;

  copyStateId: any;
  copyStateName: any;

  copyDistrictId: any;
  copyDistrictName: any;
  copyCity: void;
  copCityName: any;
  copyCityId: any;

  passwordVisible: boolean = false;
  BusinessUnit: any;
  BusinessName: Object;
  entityId: any;
  clientId: any;

  constructor(
    private Employeeservice: EmployeeService,
    private settingsservice: SettingsService,
    private payrollService: PayrollService,
    private formBuilder: FormBuilder,
    private router: Router,
    private datepipe: DatePipe,
    private ActivatedRoute: ActivatedRoute,
    private AdminService: AdminService,
    private route: ActivatedRoute /*private dialogref : MatDialogRef<AddDesignationComponent>, // @Inject(MAT_DIALOG_DATA) public clientData: any, // public dialogRef: MatDialogRef<MatclientcomponentsComponent>
    @Inject(MAT_DIALOG_DATA) public edit_data: any */
  ) {
    this.isMasterSel = false;
  }

  ngOnInit(): void {
    // this.route.queryParams.subscribe(params => {
    //   const ClientID = params['client'];
    //   // Now you can access the data as key1Data and key2Data
    //   console.log("ClientID",ClientID);
    //   this.cliId=ClientID;
    //   console.log("cliId"+this.cliId);
    // });
    this.employeeId = this.ActivatedRoute.snapshot.params.employeeId;
    console.log(' this.employeeId' + this.employeeId);
    //  console.log("Client Data",this.clientData);
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId = this.userdata.organization.organizationId;
    this.entityId = this.userdata.entity.entityId;
    this.clientId = this.userdata?.employee?.client?.clientId;
    console.log(this.entityId);

    this.Employeeservice.getOrganizationbyId().subscribe((result: any) => {
      this.OrganizationDetail = result;

      if (this.OrganizationDetail.country) {
        this.maxPhoneNumberLength =
          this.OrganizationDetail.country.maxPhoneNumberLength;
      }
    });

    this.Employeeservice.getIndentityDocumenttypes().subscribe(
      (result: any) => {
        this.identityDocumentTypes = result;
      }
    );

    this.Employeeservice.getDocumenttypes().subscribe((result: any) => {
      this.documentypes = result;
    });


if(this.organizationId){
    this.payrollService
      .getClientNameList(this.organizationId)
      .subscribe((result: any) => {
        this.clientList = result.filter(
          (client) => client.status.listTypeValueId == 1
        );
        console.log(this.clientList);
      });
    }


if(this.clientId){
      this.payrollService
      .getClientNameListbyClient(this.clientId)
      .subscribe((result: any) => {
        this.clientList = result.filter(
          (client) => client.status.listTypeValueId == 1
        );
        console.log(this.clientList);
      });
    }

    //Basic Form Validation
    if (!this.employeeId) {
      //Create
      this.basicInformationDetails = this.formBuilder.group({
        /*  roleId : [null,[Validators.required]], */
        businessgroup: [null, [Validators.required]],
        employeeNumber: [
          null,
          [Validators.required, Validators.pattern('^[0-9]+$')],
        ],
        firstName: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$'),
          ],
        ],
        // lastName : [null,[Validators.required]],
        lastName: [
          null,
          [Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$')],
        ],
        // client : [null,[Validators.required]],
        client: [null],
        //mobileNumber : [null,[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(this.maxPhoneNumberLength),Validators.maxLength(this.maxPhoneNumberLength)]],
        // mobileNumber: [
        //   null,
        //   [Validators.required, Validators.pattern('^[0-9]+$')],
        // ],
        mobileNumber: [
          null,
          [
            Validators.pattern(/^[6-9]\d{9}$/), // Adjust pattern to require exactly 10 digits
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        organizationEmail: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        dateOfBirth: [null, [Validators.required]],
        gender: [null, [Validators.required]],
        martialStatus: [null],
        middleName: [
          null,
          [Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$')],
        ],
        bloodGroup: [null],
        streamId: [null],
        qualification: [
          null,
          [Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$')],
        ],
        // alternativeMobileNumber: [
        //   null,
        //   [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
        // ],
        alternativeMobileNumber: [
          null,
          [
            Validators.pattern(/^[6-9]\d{9}$/), // Adjust pattern to require exactly 10 digits
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        profile_image: [null],
        alternativeEmail: [
          null,
          [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
        ],
        // inputField: ['', [Validators.minLength(3), Validators.maxLength(10)]]
        password: [null, [Validators.required, Validators.maxLength(20)]],
        // password: [null, Validators.required,.maxLength(20)],
      });
      this.getEmployeeIndentityattachments(this.employeeId);
    } //Update
    else {
      this.basicInformationDetails = this.formBuilder.group({
        /* roleId : [null,[Validators.required]], */
        businessgroup: [null, [Validators.required]],

        employeeNumber: [
          null,
          [Validators.required, Validators.pattern('^[0-9]+$')],
        ],
        firstName: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$'),
          ],
        ],
        // lastName : [null,[Validators.required]],
        lastName: [
          null,
          [Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$')],
        ],
        // client : [null,[Validators.required]],
        client: [null],
        //mobileNumber : [null,[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(this.maxPhoneNumberLength),Validators.maxLength(this.maxPhoneNumberLength)]],
        // mobileNumber: [
        //   null,
        //   [Validators.required, Validators.pattern('^[0-9]+$')],
        // ],
        mobileNumber: [
          null,
          [
            Validators.pattern(/^[6-9]\d{9}$/), // Adjust pattern to require exactly 10 digits
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        organizationEmail: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        dateOfBirth: [null, [Validators.required]],
        gender: [null, [Validators.required]],
        martialStatus: [null],
        middleName: [
          null,
          [Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$')],
        ],
        bloodGroup: [null],
        streamId: [null],
        qualification: [
          null,
          [Validators.pattern('^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$')],
        ],
        alternativeMobileNumber: [
          null,
          [
            Validators.pattern(/^[6-9]\d{9}$/), // Adjust pattern to require exactly 10 digits
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        profile_image: [null],
        alternativeEmail: [
          null,
          [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
        ],
        //password: [null, Validators.required],
      });
    }

    /*  if(!this.userId)
    {
      this.basicInformationDetails = this.formBuilder.group({

      });
    } */

    this.employmentDetails = this.formBuilder.group(
      {
        employmentType: ['', [Validators.required]],
        designationId: ['', [Validators.required]],
        departmentId: ['', Validators.required],
        dateOfJoining: [null, Validators.required],
        yearOfExperiance: [null, [Validators.pattern('^[0-9.-]+$')]],
        ratePerDay: [null, [Validators.pattern('^[0-9.-]+$')]],
        ratePerHour: [null, [Validators.pattern('^[0-9.-]+$')]],
        payFrequency: [''],
        branchId: [''],
        lastWorkingDate: [null],
        confirmationDate: [null],
      },
      { validator: this.dateComparisonValidator }
    );

    this.idDetails = this.formBuilder.group({
      // aadhaarNumber: [
      //   null,
      //   [
      //     Validators.required,
      //     Validators.pattern('^[0-9.-]+$'),
      //     this.aadhaarNumberValidator(),
      //   ],
      // ],
      aadhaarNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ],
      ],
      // panNumber: [null, [Validators.pattern('^[A-Z0-9]+$')]],
      panNumber: [null, [Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')]],
      //  passportNumber: [null, [Validators.pattern('^[A-Z0-9]+$')]],
      passportNumber: [null, [Validators.pattern('^[A-Z][0-9]{7}$')]],
      // licenceNumber: [null, [Validators.pattern('^[A-Z0-9]+$')]],
      licenceNumber: [null, [Validators.pattern('^[A-Z]{2}\\d{13}$')]],
      // voterId: [null, [Validators.pattern('^[A-Z0-9]+$')]],
      voterId: [null, [Validators.pattern('^[A-Z]{3}[0-9]{7}$')]],
      pfNumber: [null, [Validators.pattern('^[A-Z0-9]+$')]],
      esiNumber: [null, [Validators.pattern('^[A-Z0-9]+$')]],
      // uanNumber: [null, [Validators.pattern('^[A-Z0-9]+$')]],
      uanNumber: [null, [Validators.pattern('^[0-9]{12}$')]],

      passportValidityDate: [null],
      licenceValidityDate: [null],
      tableRows: this.formBuilder.array([]),
    });

    this.addressDetails = this.formBuilder.group({
      /*  countryId: [null, Validators.required],
      stateId: [null, Validators.required],
      districtId: [null, Validators.required],
      cityId: [null], */
      countryName: [null, [Validators.required]],
      countryId: [null, [Validators.required]],

      stateName: [null, [Validators.required]],
      stateId: [null, [Validators.required]],

      districtName: [null, [Validators.required]],
      districtId: [null, [Validators.required]],

      cityName: [null, [Validators.required]],
      cityId: [null, [Validators.required]],

      // postalCode: [
      //   null,
      //   [Validators.required, Validators.pattern('^[0-9.-]+$')],
      // ],
      postalCode: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]{6}$'),
      ]),

      address: [null, Validators.required],
      copyCurrentAddress: [null],

      permenantCountryName: [null],
      permenantCountryId: [null],

      permenantStateName: [null],
      permenantStateId: [null],

      permenantDistrictName: [null],
      permenantDistrictId: [null],

      permenantCityName: [null],
      permenantCityId: [null],

      permenantPostalCode: [null, [Validators.pattern('^[0-9.-]+$')]],
      permenantAddress: [null],
    });

    this.bankDetails = this.formBuilder.group({
      //bankAccountNo: [null, Validators.required],
      //bankAccountNo: [null,[Validators.required,Validators.pattern("^[0-9]+$")]],
      // bankAccountNo : [null,[Validators.required,Validators.pattern("^[0-9.-]+$")]],
      // bankAccountNo: [null, [Validators.required,Validators.pattern('^[0-9.-]+$')]],
      // bankAccountNo: [null, [
      //   Validators.required,
      //   Validators.pattern('^[0-9]{20}$') // This pattern ensures exactly 20 digits
      // ]],
      bankAccountNo: [
        null,
        [
          Validators.required,
          Validators.pattern('^[0-9]{11,16}$'), // This pattern allows between 11 and 16 digits
        ],
      ],

      // bankAccountNo: new FormControl(null, [
      //   Validators.required,
      //   Validators.pattern('^[0-9]{20}$')
      // ]),

      // bankAccountHolderName: [
      //   null,
      //   [
      //     Validators.required,
      //     Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      //   ],
      // ],
      //, Validators.pattern("^[A-Za-z_-]+$")
      // bankName: [
      //   null,
      //   [
      //     Validators.required,
      //     Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      //   ],
      // ], //, Validators.pattern("^[A-Za-z_-]+$")
      bankAccountHolderName: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([ ][a-zA-Z])?[a-zA-Z]*)*$/),
        ],
      ],
      bankName: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
        ],
      ],
      bankBranchName: [
        null,
        Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      ],
      // ifscCode: [
      //   null,
      //   [Validators.required, Validators.pattern('^[A-Z0-9.-]+$')],
      // ],
      ifscCode: [
        null,
        [Validators.required, Validators.pattern('^[A-Z]{4}[0-9]{7}$')],
      ],

      //[null,[Validators.required, Validators.pattern("^[A-Z0-9]+$")]],
      micrCode: [null, [Validators.pattern('^[0-9]+$')]],
      bankAddress: [null],
    });

    /* this.loginDetails = this.formBuilder.group({
      password: [null, Validators.required]
    }); */

    //this.checkMobileNumberLength();

    /* this.getActiveRoles(); */
    this.getActiveStreams();
    this.getGenderLov(2);
    this.getBloodGroupLov(3);
    this.getEmployementTypeLov(4);
    this.getMartialStatusLov(6);
    this.getpayFrequencyStatusLov(5);

    this.getActiveBranch();
    this.getActiveDesignation();
    this.getActiveDepartment();
    this.getActiveCountry();
    this.getBusinessGroupData();
    // this.patchPermenantAddress()

    // this.today.setYear(this.today.getFullYear() - 18);
    this.today = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    this.confirmationDates =
      this.employmentDetails.get('confirmationDate').value;

    // mat dialog
    console.log(this.basicInformationDetails.value.password);
    if (this.employeeId) {
      console.log(
        'country name::',
        this.addressDetails.controls['countryName'].value
      );

      this.loader = true;
      this.Employeeservice.getEmployeeById(this.employeeId).subscribe(
        (resultData: any) => {
          //console.log(resultData);
          this.loader = false;
          this.resultData = resultData;
          console.log('resultData:', this.resultData);
          if (this.resultData.employeeInfo) {
            if (this.resultData.employeeInfo.country) {
              this.editCountryId =
                this.resultData.employeeInfo.country.countryId;
            }

            if (this.resultData.employeeInfo.state) {
              this.editStateId = this.resultData.employeeInfo.state.stateId;
            }

            if (this.resultData.employeeInfo.district) {
              this.editDitrictId =
                this.resultData.employeeInfo.district.districtId;
            }

            if (this.resultData.employeeInfo.city) {
              this.editCityId = this.resultData.employeeInfo.city.cityId;
            }
          }

          if (this.resultData.employeeInfo) {
            this.employeeInfoId = this.resultData.employeeInfo.employeeInfoId;
          }

          this.employeeStatus = this.resultData.status.listTypeValueId;

          /*  if(this.resultData.userAttachment.fileViewUrl != undefined)
        {
          this.profileImage = this.resultData.userAttachment.fileViewUrl;
        } */

          if (this.resultData.employeeAttachment) {
            this.fileId = this.resultData.employeeAttachment.fileId;
          }

          if (
            resultData.employeeAttachment &&
            this.resultData.employeeAttachment.fileViewUrl != undefined
          ) {
            this.Employeeservice.getemployeeImage(
              this.resultData.employeeAttachment.fileViewUrl
            ).subscribe(
              (blob) => {
                const a = document.createElement('a');
                const objectUrl: any = blob;
                this.fileblob = blob;
              },
              (err) => {}
            );
          } else {
            this.fileblob = 'assets/uploads/no-image.png';
          }
          if (this.resultData.client != undefined) {
            this.ClientName = this.resultData.client.clientName;
            this.cliId = this.resultData.client.clientId;
            console.log(
              'Update clientName:' + this.ClientName + 'ClientID' + this.cliId
            );
          }

          this.basicInformationDetails.patchValue({
            /* roleId: this.resultData.role.roleId, */
            employeeNumber: this.resultData.employeeNumber,
            firstName: this.resultData.firstName,
            lastName: this.resultData.lastName,
            mobileNumber: this.resultData.mobileNumber,
            organizationEmail: this.resultData.organizationEmail,
            dateOfBirth: this.resultData.dateOfBirth,
            // client : this.resultData.client.clientId,
            middleName: this.resultData.middleName,
            qualification: this.resultData.qualification,
            alternativeMobileNumber: this.resultData.alternativeMobileNumber,
            profile_image: this.resultData.profile_image,
            alternativeEmail: this.resultData.alternativeEmail,
            password: this.resultData.password,
          });
          console.log(this.basicInformationDetails.value);
          console.log(this.resultData.businessgroup.businessGroupId);
          if (this.resultData.businessgroup) {
            console.log(this.resultData.businessgroup);
            this.basicInformationDetails.patchValue({
              businessgroup: this.resultData.businessgroup.businessGroupId,
            });
          }
          if (this.resultData.gender) {
            this.basicInformationDetails.patchValue({
              gender: this.resultData.gender.listTypeValueId,
            });
          }

          if (this.resultData.bloodGroup) {
            this.basicInformationDetails.patchValue({
              bloodGroup: this.resultData.bloodGroup.listTypeValueId,
            });
          }

          if (this.resultData.stream) {
            this.basicInformationDetails.patchValue({
              streamId: this.resultData.stream.streamId,
            });
          }

          if (this.resultData.martialStatus) {
            this.basicInformationDetails.patchValue({
              martialStatus: this.resultData.martialStatus.listTypeValueId,
            });
          }

          this.employmentDetails.patchValue({
            dateOfJoining: this.resultData.dateOfJoining,
            confirmationDate: this.resultData.confirmationDate,
            yearOfExperiance: this.resultData.yearOfExperiance,
            ratePerDay: this.resultData.ratePerDay,
            ratePerHour: this.resultData.ratePerHour,
            lastWorkingDate: this.resultData.lastWorkingDate,
          });

          if (this.resultData.employmentType) {
            this.employmentDetails.patchValue({
              employmentType: this.resultData.employmentType.listTypeValueId,
            });
          }

          if (this.resultData.branch) {
            this.employmentDetails.patchValue({
              branchId: this.resultData.branch.branchId,
            });
          }

          if (this.resultData.designation) {
            this.employmentDetails.patchValue({
              designationId: this.resultData.designation.designationId,
            });
          }

          if (this.resultData.department) {
            this.employmentDetails.patchValue({
              departmentId: this.resultData.department.departmentId,
            });
          }

          if (this.resultData.payFrequency) {
            this.employmentDetails.patchValue({
              payFrequency: this.resultData.payFrequency.listTypeValueId,
            });
          }

          this.idDetails.patchValue({
            aadhaarNumber: this.resultData.aadhaarNumber,
            panNumber: this.resultData.panNumber,
            passportNumber: this.resultData.passportNumber,
            licenceNumber: this.resultData.licenceNumber,
            voterId: this.resultData.voterId,
            pfNumber: this.resultData.pfNumber,
            esiNumber: this.resultData.esiNumber,
            uanNumber: this.resultData.uanNumber,
          });

          if (this.resultData.passportValidityDate) {
            this.idDetails.patchValue({
              passportValidityDate: this.resultData.passportValidityDate,
            });
          }

          if (this.resultData.licenceValidityDate) {
            this.idDetails.patchValue({
              licenceValidityDate: this.resultData.licenceValidityDate,
            });
          }

          // this.addressDetails.patchValue({
          //   //countryId: this.resultData.countryId,
          //   //stateId: this.resultData.stateId,
          //   //districtId: this.resultData.districtId,
          //   //cityId: this.resultData.cityId,
          //   postalCode: this.resultData.postalCode,
          //   address: this.resultData.address,
          //   /* copyCurrentAddress: this.resultData.copyCurrentAddress,
          // permenantCountryId: this.resultData.permenantCountryId,
          // permenantStateId: this.resultData.permenantStateId,
          // permenantDistrictId: this.resultData.permenantDistrictId,
          // permenantCityId: this.resultData.permenantCityId, */
          //   // permenantPostalCode: this.resultData.userInfo.permenantPostalCode,
          //   //permenantAddress: this.resultData.userInfo.permenantAddress,
          // });
          console.log(
            'postal code:',
            this.resultData.postalCode,
            'address::',
            this.resultData.address
          );
          if (this.resultData?.postalCode && this.resultData?.address) {
            this.addressDetails.patchValue({
              postalCode: this.resultData.postalCode,
              address: this.resultData.address,
            });
          }

          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              permenantPostalCode:
                this.resultData.employeeInfo.permenantPostalCode,
            });
          }
          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              permenantCountryName:
                this.resultData?.employeeInfo?.permenantCountry?.countryName,
              permenantCountryId:
                this.resultData?.employeeInfo?.permenantCountry?.countryId,
            });
          }
          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              permenantStateName:
                this.resultData.employeeInfo?.permenantState?.stateName,
              permenantStateId:
                this.resultData?.employeeInfo?.permenantState?.stateId,
            });
          }
          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              permenantDistrictName:
                this.resultData.employeeInfo?.permenantDistrict?.districtName,
              permenantDistrictId:
                this.resultData?.employeeInfo?.permenantDistrict?.districtId,
            });
          }
          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              permenantCityName:
                this.resultData.employeeInfo?.permenantCity?.cityName,
              permenantCityId:
                this.resultData?.employeeInfo?.permenantCity?.cityId,
            });
          }
          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              permenantAddress: this.resultData.employeeInfo.permenantAddress,
            });
          }

          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              countryName: this.resultData?.employeeInfo?.country?.countryName,
              countryId: this.resultData?.employeeInfo?.country?.countryId,
            });
          }

          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              stateName: this.resultData?.employeeInfo?.state?.stateName,
              stateId: this.resultData?.employeeInfo?.state?.stateId,
            });
          }

          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              districtName:
                this.resultData?.employeeInfo?.district?.districtName,
              districtId: this.resultData?.employeeInfo?.district?.districtId,
            });
          }

          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              cityName: this.resultData?.employeeInfo?.city?.cityName,
              cityId: this.resultData?.employeeInfo?.city?.cityId,
            });
          }

          /* if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            countryId: this.resultData.employeeInfo.country.countryId
          });
        }

        if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            stateId: this.resultData.employeeInfo.state.stateId,
          });
        }

        if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            districtId: this.resultData.employeeInfo.district.districtId,
          });
        }

        if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            cityId: this.resultData.employeeInfo.city.cityId,
          });
        } */

          /* if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            //countryId: this.resultData.employeeInfo.country.countryId
            permenantCountryId: this.resultData.employeeInfo.permenantCountry.countryId
          });
        }

        if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            permenantStateId: this.resultData.employeeInfo.permenantState.stateId
          });
        }

        if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            permenantDistrictId: this.resultData.employeeInfo.permenantDistrict.districtId,
          });
        }

        if(this.resultData.employeeInfo)
        {
          this.addressDetails.patchValue({
            permenantCityId: this.resultData.employeeInfo.permenantCity.cityId,
          });
        } */

          if (this.resultData.employeeInfo) {
            this.addressDetails.patchValue({
              copyCurrentAddress:
                this.resultData.employeeInfo.copyCurrentAddress,
            });
          }

          /*  this.bankDetails.patchValue({
          bankAccountNo: this.resultData.userInfo.bankAccountNo,
          bankAccountHolderName: this.resultData.userInfo.accountName,
          bankName: this.resultData.userInfo.bankName,
          bankBranchName: this.resultData.userInfo.bankBranchName,
          ifscCode: this.resultData.userInfo.ifscCode,
          micrCode: this.resultData.userInfo.micrCode,
          bankAddress: this.resultData.userInfo.bankAddress
        }); */

          if (this.resultData.employeeInfo) {
            this.bankDetails.patchValue({
              bankAccountNo: this.resultData.employeeInfo.bankAccountNo,
            });
          }

          if (this.resultData.employeeInfo) {
            this.bankDetails.patchValue({
              bankAccountHolderName: this.resultData.employeeInfo.accountName,
            });
          }

          if (this.resultData.employeeInfo) {
            this.bankDetails.patchValue({
              bankName: this.resultData.employeeInfo.bankName,
            });
          }

          if (this.resultData.employeeInfo) {
            this.bankDetails.patchValue({
              bankBranchName: this.resultData.employeeInfo.bankBranchName,
            });
          }

          if (this.resultData.employeeInfo) {
            this.bankDetails.patchValue({
              ifscCode: this.resultData.employeeInfo.ifscCode,
            });
          }
          if (this.resultData.employeeInfo) {
            this.bankDetails.patchValue({
              micrCode: this.resultData.employeeInfo.micrCode,
            });
          }

          if (this.resultData.employeeInfo) {
            this.bankDetails.patchValue({
              bankAddress: this.resultData.employeeInfo.bankAddress,
            });
          }

          /* this.loginDetails.patchValue({
          password: this.resultData.password
        }); */
        }
      );
      console.log(
        'country name::',
        this.addressDetails.controls['countryName'].value
      );
    } else {
      console.log('create Method Client Patch', this.cliId);
      // this.Employeeservice.getClientById(this.cliId).subscribe(
      //   (resultData: any) => {
      //     console.log(resultData);
      //     // this.resultData=resultData;
      //     console.log(resultData.clientName);
      //     this.ClientName = resultData.clientName;
      //     console.log(this.ClientName);
      //     // this.basicInformationDetails.patchValue({
      //     //   client : resultData.clientName
      //     // });
      //   }
      // );
    }
    console.log(
      'country name::',
      this.addressDetails.controls['countryName'].value
    );
  }

  checkStartDate(): void {
    const startDate = this.employmentDetails.get('confirmationDate')?.value;
    const endDate = this.employmentDetails.get('dateOfJoining')?.value;

    if (!startDate || !endDate) {
      return; // Exit if either date is not set
    }

    const startDate1 = new Date(startDate);
    const endDate1 = new Date(endDate);

    if (startDate1 > endDate1) {
      // alert('Start date cannot be greater than end date.');
      alert(
        'Joining Confirmation Date  Should not be greater than Date of Joining'
      );

      this.employmentDetails.get('confirmationDate')?.patchValue(null);
      this.employmentDetails.get('dateOfJoining')?.patchValue(null);

      // Clear validators and update value and validity
      this.employmentDetails.get('confirmationDate')?.clearValidators();
      this.employmentDetails.get('dateOfJoining')?.clearValidators();
      this.employmentDetails.get('confirmationDate')?.updateValueAndValidity();
      this.employmentDetails.get('dateOfJoining')?.updateValueAndValidity();

      this.startDatePicker.close();
      this.endDatePicker.close();
    }
  }
  onConfirmationDateChange(event: any, datePicker: any): void {
    const confirmationDate = new Date(event.value);
    const dateOfJoining = new Date(
      this.employmentDetails.get('dateOfJoining')?.value
    );

    // Perform validations
    if (dateOfJoining) {
      this.validateDates(confirmationDate, dateOfJoining, false, datePicker);
    }
  }

  onDateOfJoiningChange(event: any, datePicker: any): void {
    const dateOfJoining = new Date(event.value);
    const dateOfBirth = new Date(
      this.basicInformationDetails.get('dateOfBirth')?.value
    );
    const confirmationDate = new Date(
      this.employmentDetails.get('confirmationDate')?.value
    );

    // Perform validations
    if (dateOfBirth) {
      this.validateDates(dateOfJoining, dateOfBirth, true, datePicker);
    }
    if (confirmationDate) {
      this.validateDates(dateOfJoining, confirmationDate, true, datePicker);
    }
  }

  onLastWorkingDateChange(event: any, datePicker: any) {
    const joiningDate = this.employmentDetails.get('dateOfJoining')?.value;
    const lastWorkingDate = event.value;

    if (
      lastWorkingDate &&
      joiningDate &&
      new Date(lastWorkingDate) < new Date(joiningDate)
    ) {
      alert('Last Working Date cannot be earlier than Date of Joining!');
      this.employmentDetails.get('lastWorkingDate')?.reset();
      datePicker.close();
    }
  }

  validateDates(
    changedDate: Date,
    comparisonDate: Date,
    isDateOfJoining: boolean,
    datePicker: any
  ): void {
    // Normalize to midnight
    const changed = changedDate.setHours(0, 0, 0, 0);
    const comparison = comparisonDate.setHours(0, 0, 0, 0);

    // Check if Date of Joining equals Date of Birth (for Date of Joining only)
    if (isDateOfJoining && changed === comparison) {
      alert('Date of Joining cannot be the same as Date of Birth.');
      this.employmentDetails.get('dateOfJoining')?.patchValue(null);
      datePicker.close();
      return;
    }

    // Check if employee is at least 18 years old (for Date of Joining only)
    if (isDateOfJoining) {
      const ageInMilliseconds = changed - comparison; // Difference in milliseconds
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Convert to years
      if (ageInYears < 18) {
        alert('Employee must be at least 18 years old at the Date of Joining.');
        this.employmentDetails.get('dateOfJoining')?.patchValue(null);
        datePicker.close();
        return;
      }
    }

    // Validate Confirmation Date relative to Date of Joining
    if (!isDateOfJoining && changed > comparison) {
      alert(
        'Joining Confirmation Date should not be greater than Date of Joining.'
      );
      this.employmentDetails.get('confirmationDate')?.patchValue(null);
      datePicker.close();
      return;
    }

    // Validate Date of Joining relative to Confirmation Date
    if (isDateOfJoining && comparison && changed < comparison) {
      alert(
        'Date of Joining should not be earlier than Joining Confirmation Date.'
      );
      this.employmentDetails.get('dateOfJoining')?.patchValue(null);
      datePicker.close();
    }
  }

  aadhaarNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value && control.value.length === 12;
      return isValid ? null : { invalidAadhaarNumber: true };
    };
  }
  ConfirmationDate(e) {
    let min = new Date(e.value);
    console.log('this.min', min);

    let w = this.datepipe.transform(
      this.employment.dateOfJoining.value,
      'YYYY-MM-dd'
    );
    let v = this.datepipe.transform(min, 'YYYY-MM-dd');
    if (w != null) {
      if (w < v) {
        this.show_error_msg = true;
        console.log('if');
      } else {
        this.show_error_msg = false;
        console.log('else');
      }
    }
  }
  DateofJoining(e) {
    this.max = new Date(e.value);

    let w = this.datepipe.transform(this.max, 'YYYY-MM-dd');
    let v = this.datepipe.transform(
      this.employment.confirmationDate.value,
      'YYYY-MM-dd'
    );
    if (w != null) {
      if (w < v) {
        this.show_error_msg = true;
        console.log('if');
      } else {
        this.show_error_msg = false;
        console.log('else');
      }
    }
  }

  
  getEmployeeIndentityattachments(employeeId) {
    this.Employeeservice.getemployeeindentityattachments(employeeId).subscribe(
      (result: any) => {
        console.log('result', result);

        if (result.statusCode) {
          this.employeeIndetityAttachment.length = 0;
        } else {
          this.employeeIndetityAttachment = result;
          const control = this.idDetails.get('tableRows') as FormArray;
          console.log(control);
          this.employeeIndetityAttachment.forEach((element, index) => {
            console.log('element', element);
            this.addRow(element.fileId);
            control.at(index).patchValue({
              fileId: element.fileId,
              filedata: element.fileId,
              identiyDocumentType: element.fileAttachmentType.listTypeValueId,
              documentType: element.documentType.listTypeValueId,
              description: element.documentDescription,
              fileName: element.fileName,
              fileType: element.fileType,
            });
          });
        }
      }
    );
  }

  addRow(fileId) {
    const control = this.idDetails.get('tableRows') as FormArray;
    control.push(this.initiateForm(fileId));
  }

  deleteRow(index: number, group) {
    const control = this.idDetails.get('tableRows') as FormArray;
    console.log(group);
    console.log('group.value.fileId' + group.value.fileId);
    if (group.value.fileId) {
      this.deletedrows.push(group.value);
    }
    control.removeAt(index);
  }

  downloadfiles(fileid) {
    let result;
    this.employeeIndetityAttachment.forEach((element) => {
      if (element.fileId == fileid) {
        result = element.fileName;
      }
    });
    const a = document.createElement('a');
    //  const objectUrl: any =  "data:" + filedata.fileType + filedata.fileEncoding + "," + filedata.fileByte;
    const objectUrl = `${environment.base_url}download/${result}`;
    a.href = objectUrl;
    a.download = result;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  getIdentityFileDetails(event, index, group) {
    const files = event.target.files;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filename = file.name;
      this.fileSize = file.size;
      this.fileType = file.type;

      let reader = new FileReader();

      reader.onload = () => {
        let x: any = reader.result;
        let y: any = x.split(',');
        this.fileByte = y[1];

        // let filedata = {
        //    fileId : fileId,
        //   fileName : file.name,
        //   fileSize : file.size,
        //   fileType : file.type,
        //   fileByte : this.fileByte
        //  }
        //  this.identitydocuments.push(filedata);
        console.log(file);
        console.log(this.fileByte);
        const control = this.idDetails.get('tableRows') as FormArray;
        console.log(index);
        this.setDocument = false;

        group.patchValue({
          fileBytes: this.fileByte,
          fileName: file.name,
          fileType: file.type,
        });
      };

      reader.readAsDataURL(file);
    }
  }

  editRow(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  initiateForm(fileId): FormGroup {
    if (fileId) {
      return this.formBuilder.group({
        fileId: [null],
        identiyDocumentType: [null, Validators.required],
        filedata: [null, Validators.required],
        fileBytes: [null],
        fileName: [null],
        fileType: [null],
        documentType: [null, [Validators.required]],
        description: [null, [Validators.required]],
        isEditable: [true],
      });
    } else {
      console.log('fileId', fileId);
      return this.formBuilder.group({
        fileId: [null],
        fileName: [null],
        fileType: [null],
        fileBytes: [null],
        identiyDocumentType: [null, Validators.required],
        filedata: [null, Validators.required],
        documentType: [null, [Validators.required]],
        description: [null, [Validators.required]],
        isEditable: [true],
      });
    }
  }

  get getFormControls() {
    //alert("getformcontrols")
    const control = this.idDetails.get('tableRows') as FormArray;
    if (control != null) {
      return control;
    }
  }

  /* getActiveRoles()
  {
    this.Employeeservice.getActiveRoles().subscribe((result: any) => {
      this.activeRolesData = result;
    })
  } */

  getActiveStreams() {
    if (this.organizationId) {
      this.Employeeservice.getOrgstram(this.organizationId).subscribe(
        (result: any) => {
          this.activeStreamData = result;
        }
      );
    } else if (this.clientId) {
      this.Employeeservice.getClientstram(this.clientId).subscribe(
        (result: any) => {
          this.activeStreamData = result;
        }
      );
    } else {
      console.log('entity');
      this.Employeeservice.getActiveStreams().subscribe((result: any) => {
        this.activeStreamData = result;
      });
    }
  }

  getGenderLov(listTypeValueId) {
    this.settingsservice
      .getListTypeValues(listTypeValueId)
      .subscribe((resultData: any) => {
        this.genderLov = resultData;
      });
  }

  getBloodGroupLov(listTypeValueId) {
    this.settingsservice
      .getListTypeValues(listTypeValueId)
      .subscribe((resultData: any) => {
        this.bloodGroupLov = resultData;
      });
  }

  getMartialStatusLov(listTypeValueId) {
    this.settingsservice
      .getListTypeValues(listTypeValueId)
      .subscribe((resultData: any) => {
        this.martialStatusLov = resultData;
      });
  }

  getpayFrequencyStatusLov(listTypeValueId) {
    this.settingsservice
      .getListTypeValues(listTypeValueId)
      .subscribe((resultData: any) => {
        this.payFrequencyStatusLov = resultData;
        this.payFrequencyStatusLov = Array.isArray(resultData)
          ? resultData
          : [resultData];
      });
  }

  getEmployementTypeLov(listTypeValueId) {
    this.settingsservice
      .getListTypeValues(listTypeValueId)
      .subscribe((resultData: any) => {
        this.employementTypeLov = resultData;
        this.employementTypeLov = Array.isArray(resultData)
          ? resultData
          : [resultData];
      });
  }

  getActiveBranch() {
    if (this.organizationId) {
      this.Employeeservice.getOrgBranch(this.organizationId).subscribe(
        (result: any) => {
          this.activeBranchData = result;
        }
      );
    } else if (this.clientId) {
      this.Employeeservice.getClientBranch(this.clientId).subscribe(
        (result: any) => {
          this.activeBranchData = result;
        }
      );
    } else {
      console.log('entity');
      this.Employeeservice.getActiveBranchOnly().subscribe((result: any) => {
        this.activeBranchData = result;
      });
    }
  }

  /*  getOrganizationbyId()
  {
    this.UserService.getOrganizationbyId().subscribe((result: any) => {
      this.OrganizationDetail = result;

     this.maxPhoneNumberLength = this.OrganizationDetail.country.maxPhoneNumberLength;
      console.log("ORG DETAILS No : ",this.maxPhoneNumberLength);
    })
  } */

  getActiveDesignation() {
    if (this.organizationId) {
      this.Employeeservice.getActiveOrgDesignation(this.organizationId).subscribe(
        (result: any) => {
          this.activeDesignationData = result;
          console.log("activeDesignationData:organization",this.activeDesignationData)

          this.activeDesignationData = Array.isArray(result)
            ? result
            : [result];
        }
      );
    } else if (this.clientId) {
      this.Employeeservice.getClientdesignation(this.clientId).subscribe(
        (result: any) => {
          this.activeDesignationData = result;
          console.log("activeDesignationData:client",this.activeDesignationData)

          this.activeDesignationData = Array.isArray(result)
            ? result
            : [result];
        }
      );
    } else {
      console.log('entity');
      this.Employeeservice.getActiveDesignation().subscribe((result: any) => {
        this.activeDesignationData = result;
        console.log("activeDesignationData:enity",this.activeDesignationData)
        this.activeDesignationData = Array.isArray(result) ? result : [result];
      });
    }
  }

  getActiveDepartment() {
    if (this.organizationId) {
      this.Employeeservice.getOrgdepartment(this.organizationId).subscribe(
        (result: any) => {
          this.activeDepartmentData = result;
          this.activeDepartmentData = Array.isArray(result) ? result : [result];
        }
      );
    } else if (this.clientId) {
      this.Employeeservice.getClientdepartment(this.clientId).subscribe(
        (result: any) => {
          this.activeDepartmentData = result;
          this.activeDepartmentData = Array.isArray(result) ? result : [result];
        }
      );
    } else {
      console.log('entity');
      this.Employeeservice.getActiveDepartment().subscribe((result: any) => {
        this.activeDepartmentData = result;
        this.activeDepartmentData = Array.isArray(result) ? result : [result];
      });
    }
  }

  getActiveCountry() {
    this.Employeeservice.getActiveCountry().subscribe((result: any) => {
      this.activeCountryData = result;
    });
  }

  getActiveState(countryId) {
    /*  this.UserService.getActiveState().subscribe((result: any) =>  */
    this.settingsservice
      .getCountryAgainstStateList(countryId)
      .subscribe((result: any) => {
        this.stateList = result;
        this.perstateList = result;
      });
  }

  getActiveDistrict(stateId) {
    /* this.UserService.getActiveDistrict(stateId).subscribe((result: any) => { */
    this.settingsservice
      .getStateAgainstDistictList(stateId)
      .subscribe((result: any) => {
        this.districtList = result;
        this.perdistrictList = result;
      });
  }

  getActiveCity(districtId) {
    /* this.UserService.getActiveCity(districtId).subscribe((result: any) => { */
    this.settingsservice
      .getDistrictAgainstCityList(districtId)
      .subscribe((result: any) => {
        this.cityList = result;
        this.percityList = result;
      });
  }

  adddocument() {
    this.setDocument = true; // Set the flag to true as user attempted to add a document

    if (
      this.idDetails.controls.tableRows.value.length ==
      this.identityDocumentTypes.length
    ) {
      this.attachmentypesexit = true;
      this.setDocument = false;
    } else {
      this.attachmentypesexit = false;
      this.setDocument = true;

      let fileid;
      this.addRow(fileid); // Call your method to add a new row for documents
    }
  }

  get basic() {
    return this.basicInformationDetails.controls;
  }
  get employment() {
    return this.employmentDetails.controls;
  }
  get id() {
    return this.idDetails.controls;
  }
  get address1() {
    return this.addressDetails.controls;
  }
  get bank() {
    return this.bankDetails.controls;
  }
  /*   get login() { return this.loginDetails.controls; } */

  //Steps
  /* next()
  {
    if(this.step==1) //Basic Information
    {
      this.basic_step = true;

      if (this.basicInformationDetails.invalid || this.employeeNumberExistStatus == true || this.emailExistStatus == true || this.mobileNumberExistStatus == true || this.alternateMobileNumberExistStatus == true )
      {
        return
      }
      this.step++; return;
    }

    if(this.step==2) //Employment Details
    {
      this.employment_step = true;
      if (this.employmentDetails.invalid) { return }
      this.step++;return;
    }

    if(this.step==3) //ID Information
    {
      this.id_step = true;
      if (this.idDetails.invalid) { return }
      this.step++;return;
    }

    if(this.step==4) //Address
    {
      this.address_step = true;
      if (this.addressDetails.invalid) { return }
      this.step++;return;
    }

    if(this.step==5 && !this.userId) //Bank Details
    {
      this.bank_step = true;
      if (this.bankDetails.invalid) { return }
      this.step++;return;
    }

    //if(this.step==6)
    //{
        //this.login_step = true;
        //if (this.loginDetails.invalid) { return }
           // this.step++;
    //}
  } */

  /* previous()
  {
    this.step--

    if(this.step==1){
      this.basic_step = false;
    }

    if(this.step==2){
      this.employment_step = false;
    }

    if(this.step==3){
      this.id_step = false;
    }

    if(this.step==4){
      this.address_step = false;
    }

    if(this.step==5){
      this.bank_step = false;
    }
  } */
  /* checkMobileNumberLength()
 {
   let mobileNumber = this.basicInformationDetails.value.mobileNumber.length;
    if(mobileNumber > 0 || mobileNumber != this.maxPhoneNumberLength)
    {
      alert(mobileNumber);
     this.mobileVali = true;
     console.log("MV",this.mobileVali);
    }
    else
    {
      return;
    }
  } */
  selectFun() {
    this.setValid = true;
  }
  basicSubmit() {
    console.log('submit');
    this.basic_step = true;
    this.submitted = true;
    // this.setValid=true;

    if (
      this.basicInformationDetails.invalid ||
      this.employeeNumberExistStatus == true ||
      this.emailExistStatus == true ||
      this.mobileNumberExistStatus == true ||
      this.alternateMobileNumberExistStatus == true ||
      this.mobileVali != true
    ) {
      this.basicInfoDisabled = true;
      //  this.setValid=false;
    }

    /* if (this.basicInformationDetails.invalid || this.employeeNumberExistStatus == true || this.emailExistStatus == true || this.mobileNumberExistStatus == true || this.alternateMobileNumberExistStatus == true || this.mobileVali != true)
    {
      return
    } */

    /* if (this.basicInformationDetails.invalid || this.employeeNumberExistStatus == true || this.emailExistStatus == true || this.mobileNumberExistStatus == true || this.alternateMobileNumberExistStatus == true || this.mobileVali != true)
    { */

    let organizationEmail =
      this.basicInformationDetails.value.organizationEmail;
    let alternativeEmail = this.basicInformationDetails.value.alternativeEmail;
    console.log(this.basicInformationDetails);
    if (
      this.basicInformationDetails.invalid ||
      this.employeeNumberExistStatus == true ||
      this.emailExistStatus == true ||
      this.mobileNumberExistStatus == true ||
      this.alternateMobileNumberExistStatus == true 
   
    ) {
      const controls = this.basicInformationDetails.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          // invalid.push(name);
          console.log(name);
        }
      }
      console.log('return');
      return;
    } else if (
      alternativeEmail != null &&
      organizationEmail == alternativeEmail
    ) {
      alert('Email and alternate email should not equal!');
      return;
    } else {
      console.log('fun');
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
      let employeeId = this.employeetData.employeeId;

      let organizationId = this.employeetData.organization.organizationId;
      let clientId = this.employeetData?.employee?.client?.clientId;
      let dateOfBirth = this.datepipe.transform(
        this.basicInformationDetails.value.dateOfBirth,
        'yyyy-MM-dd'
      );

      let organizationIstore = organizationId
        ? { organizationId: organizationId }
        : null;
      let clientDetailStore = clientId ? { clientId: clientId } : null;

      var postValues = {
        firstName: this.basicInformationDetails.value.firstName,
        middleName: this.basicInformationDetails.value.middleName,
        lastName: this.basicInformationDetails.value.lastName,
        // client:this.basicInformationDetails.value.client,
        // client:
        // {
        //   // clientId:this.basicInformationDetails.value.client
        //   clientId:this.cliId
        // },
        businessgroup: {
          businessGroupId: this.basicInformationDetails.value.businessgroup,
        },
        entity: { entityId: this.entityId },
        employeeNumber: this.basicInformationDetails.value.employeeNumber,
        mobileNumber: this.basicInformationDetails.value.mobileNumber,
        dateOfBirth: dateOfBirth,
        gender: {
          listTypeValueId: this.basicInformationDetails.value.gender,
        },
        organizationEmail: this.basicInformationDetails.value.organizationEmail,
        alternativeEmail: this.basicInformationDetails.value.alternativeEmail,
        firstLogin: true,

        /*  role:{
          roleId: this.basicInformationDetails.value.roleId
        }, */

        // organization: {
        //   organizationId: organizationId,
        // },
        organization: organizationIstore,
        client: clientDetailStore,
      };

      if (this.basicInformationDetails.value.password) {
        console.log(this.finalPassword);
        // postValues['password'] = this.basicInformationDetails.value.password
        // postValues['password'] = this.basicInformationDetails.value.firstName+'@'+dateOfBirth
        postValues['password'] = this.finalPassword;
      }

      if (this.basicInformationDetails.value.bloodGroup) {
        postValues['bloodGroup'] = {
          listTypeValueId: this.basicInformationDetails.value.bloodGroup,
        };
      }

      if (this.basicInformationDetails.value.martialStatus) {
        postValues['martialStatus'] = {
          listTypeValueId: this.basicInformationDetails.value.martialStatus,
        };
      }

      if (this.basicInformationDetails.value.qualification) {
        postValues['qualification'] =
          this.basicInformationDetails.value.qualification;
      }

      if (this.basicInformationDetails.value.alternativeMobileNumber) {
        postValues['alternativeMobileNumber'] =
          this.basicInformationDetails.value.alternativeMobileNumber;
      }

      if (this.basicInformationDetails.value.streamId) {
        postValues['stream'] = {
          streamId: this.basicInformationDetails.value.streamId,
        };
      }

      if (this.filename) {
        let employeeAttachment = {
          fileId: this.fileId,
          fileName: this.filename,
          fileType: this.fileType,
          fileSize: this.fileSize,
          fileBytes: this.fileByte,
        };
        postValues['employeeAttachment'] = employeeAttachment;
      }

      if (this.employeeId) {
        //Update Employee // Step - 5 Bank Details
        this.basicInfoDisabled = false;
        this.Employeeservice.getEmployeeById(this.employeeId).subscribe(
          (resultData: any) => {
            (resultData['firstName'] =
              this.basicInformationDetails.value.firstName),
              (resultData['middleName'] =
                this.basicInformationDetails.value.middleName),
              (resultData['lastName'] =
                this.basicInformationDetails.value.lastName),
              // client:this.basicInformationDetails.value.client,
              // client:
              // {
              //   // clientId:this.basicInformationDetails.value.client
              //   clientId:this.cliId
              // },
              (resultData['employeeNumber'] =
                this.basicInformationDetails.value.employeeNumber);
            resultData['mobileNumber'] =
              this.basicInformationDetails.value.mobileNumber;
            (resultData['dateOfBirth'] = dateOfBirth),
              (resultData['gender'] = {
                listTypeValueId: this.basicInformationDetails.value.gender,
              }),
              (resultData['businessgroup'] = {
                businessGroupId:
                  this.basicInformationDetails.value.businessgroup,
              }),
              (resultData['organizationEmail'] =
                this.basicInformationDetails.value.organizationEmail);
            resultData['alternativeEmail'] =
              this.basicInformationDetails.value.alternativeEmail;

            resultData['employeeId'] = this.employeeId;

            resultData['status'] = {
              listTypeValueId: this.employeeStatus,
            };

            if (this.basicInformationDetails.value.bloodGroup) {
              resultData['bloodGroup'] = {
                listTypeValueId: this.basicInformationDetails.value.bloodGroup,
              };
            }

            if (this.basicInformationDetails.value.martialStatus) {
              resultData['martialStatus'] = {
                listTypeValueId:
                  this.basicInformationDetails.value.martialStatus,
              };
            }

            if (this.basicInformationDetails.value.qualification) {
              resultData['qualification'] =
                this.basicInformationDetails.value.qualification;
            }

            if (this.basicInformationDetails.value.alternativeMobileNumber) {
              resultData['alternativeMobileNumber'] =
                this.basicInformationDetails.value.alternativeMobileNumber;
            }

            if (this.basicInformationDetails.value.streamId) {
              resultData['stream'] = {
                streamId: this.basicInformationDetails.value.streamId,
              };
            }

            if (this.filename) {
              let employeeAttachment = {
                fileId: this.fileId,
                fileName: this.filename,
                fileType: this.fileType,
                fileSize: this.fileSize,
                fileBytes: this.fileByte,
              };
              resultData['employeeAttachment'] = employeeAttachment;
            }
            console.log('payload::', postValues);

            console.log(resultData);
            //  return;
            // this.basicInfoDisabled = false;
            this.Employeeservice.editEmployee(
              resultData,
              this.employeeId
            ).subscribe((result: any) => {
              console.log('checkingStatus', result);

              if (result.statusCode == 200) {
                this.success = true;
                this.sucess_msg = result.message;
                // this.basicInformationDetails.reset();
                this.basicInfoDisabled = true;
                //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                //this.router.navigate(['/home/employee']);

                setTimeout(() => {
                  this.success = false;

                  this.tabindex = 2;
                  // this.basicInformationDetails.reset();
                  this.basicInfoDisabled = true;
                }, redirectMsgTimeOut);
              } else {
                this.error = true;
                this.error_msg = result.message;
                setTimeout(() => {
                  this.error = false;
                }, redirectMsgTimeOut);
                this.basicInfoDisabled = true;
              }
            });
          },
          (err) => {
            if (err.error.statusCode >= 400) {
              this.error = true;
              this.error_msg = err.erro.description;
            }
          }
        );
      } else {
        if (this.insertEmployeeId) {
          //Update
          this.basicInfoDisabled = false;
          this.Employeeservice.editEmployee(
            postValues,
            this.insertEmployeeId
          ).subscribe(
            (result: any) => {
              if (result.statusCode == 200) {
                this.success = true;
                this.sucess_msg = result.message;
                //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                //this.router.navigate(['/home/employee']);
                // this.basicInformationDetails.reset();
                this.basicInfoDisabled = true;
                setTimeout(() => {
                  this.success = false;
                  this.tabindex = 2;
                }, redirectMsgTimeOut);
                this.basicInfoDisabled = true;
              } else {
                this.error = true;
                this.error_msg = result.message;
                setTimeout(() => {
                  this.error = false;
                }, redirectMsgTimeOut);
                this.basicInfoDisabled = true;
              }
            },
            (err) => {
              if (err.error.statusCode >= 400) {
                this.error = true;
                this.error_msg = err.erro.description;
              }
            }
          );
        } else {
          //Create start here
          console.log('Create', postValues);
          this.basicInfoDisabled = false;
          // return
          this.Employeeservice.createEmployee(postValues).subscribe(
            (result: any) => {
              this.insertEmployeeId = result.employeeId;
              if (result.statusCode == 200) {
                this.success = true;
                this.sucess_msg = result.description;
                // this.basicInformationDetails.reset();
                this.basicInfoDisabled = true;
                setTimeout(() => {
                  this.success = false;
                  this.basicInfoDisabled = false;
                }, redirectMsgTimeOut);

                setTimeout(() => {
                  this.tabindex = 2;
                }, redirectMsgTimeOut);
                this.basicInfoDisabled = true;

              } 
              else if(result.statusCode == 204){
                this.error = true;
                this.error_msg=result.message
                this.basicInfoDisabled = true;
                setTimeout(() => {
                  // this.tabindex = 2;
                  this.basicInfoDisabled = false;
                  this.error = false;
                }, redirectMsgTimeOut);

              }
              else {
                this.error = true;
                this.error_msg = result.description;
                setTimeout(() => {
                  this.error = false;
                }, redirectMsgTimeOut);
                this.basicInfoDisabled = false;
              }
            },
            (err) => {
              if (err.error.statusCode >= 400) {
                this.error = true;
                this.basicInfoDisabled = false;

                this.error_msg = err.erro.description;
              }
            }
          ); //Create end here
        }
      }
    }
  }

  get gender() {
    return this.basicInformationDetails.get('gender');
  }
  // get businessgroups() {
  //   return this.basicInformationDetails.get('businessgroup');
  // }

  employmentSubmit() {
    console.log('button submitted');
    this.employment_step = true;
    this.setDatebool = true;
    // this.submitted=true;
    console.log('form group value::', this.employmentDetails);
    console.log('forvalue::', this.employmentDetails.value);

    const dateOfJoining = this.employmentDetails.value.dateOfJoining
      ? this.datepipe.transform(
          new Date(this.employmentDetails.value.dateOfJoining),
          'yyyy-MM-dd'
        )
      : null;

    const confirmationDate = this.employmentDetails.value.confirmationDate
      ? this.datepipe.transform(
          new Date(this.employmentDetails.value.confirmationDate),
          'yyyy-MM-dd'
        )
      : null;

    const lastWorkingDate = this.employmentDetails.value.lastWorkingDate
      ? this.datepipe.transform(
          new Date(this.employmentDetails.value.lastWorkingDate),
          'yyyy-MM-dd'
        )
      : null;

    if (this.employmentDetails.invalid) {
      console.log('invalied came ');
      // const controls = this.employmentDetails.controls;
      // for (const name in controls) {
      //   if (controls[name].invalid) {
      //     // invalid.push(name);
      //     console.log(name);
      //   }
      // }
      // this.employeeDetailsDisabled = true;
      // this.employment_step = false;

      return;
    }
    console.log('forvalue::', this.employmentDetails.value);

    console.log('Transformed dates:', {
      dateOfJoining,
      confirmationDate,
      lastWorkingDate,
    });
    this.EmployeeID = this.employeeId || this.insertEmployeeId;
    console.log(this.EmployeeID);
    //  else {
    // this.employment_step=true;
    // this.employment_step = false;

    // let dateOfJoining = this.datepipe.transform(
    //   this.employmentDetails.value.dateOfJoining,
    //   'yyyy-MM-dd'
    // );
    // let confirmationDate = this.datepipe.transform(
    //   this.employmentDetails.value.confirmationDate,
    //   'yyyy-MM-dd'
    // );
    // let lastWorkingDate = this.datepipe.transform(
    //   this.employmentDetails.value.lastWorkingDate,
    //   'yyyy-MM-dd'
    // );

    // Set employee ID

    // if (this.employeeId) {
    //   this.EmployeeID = this.employeeId;
    // } else if (this.insertEmployeeId) {
    //   this.EmployeeID = this.insertEmployeeId;
    // }
    this.employeeDetailsDisabled = false;
    this.Employeeservice.getEmployeeById(this.EmployeeID).subscribe(
      (resultData: any) => {
        console.log('resultData:::', resultData);
        resultData['designation'] = {
          designationId: this.employmentDetails.value.designationId,
        };
        resultData['dateOfJoining'] = dateOfJoining;

        resultData['confirmationDate'] = confirmationDate;
        resultData['lastWorkingDate'] = lastWorkingDate;

        resultData['department'] = {
          departmentId: this.employmentDetails.value.departmentId,
        };
        resultData['firstLogin'] = true;

        resultData['employmentType'] = {
          listTypeValueId: this.employmentDetails.value.employmentType,
        };

        resultData['yearOfExperiance'] =
          this.employmentDetails.value.yearOfExperiance;
        resultData['ratePerHour'] = this.employmentDetails.value.ratePerHour;
        resultData['ratePerDay'] = this.employmentDetails.value.ratePerDay;

        if (this.employmentDetails.value.branchId) {
          resultData['branch'] = {
            branchId: this.employmentDetails.value.branchId,
          };
        }

        if (this.employmentDetails.value.payFrequency) {
          resultData['payFrequency'] = {
            listTypeValueId: this.employmentDetails.value.payFrequency,
          };
        }
        console.log('update payload::', resultData);

        this.Employeeservice.editEmployee(
          resultData,
          this.EmployeeID
        ).subscribe(
          (result: any) => {
            if (result.statusCode == 200) {
              this.success = true;
              this.sucess_msg = result.message;
              this.employeeDetailsDisabled = true;

              //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
              //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
              //this.router.navigate(['/home/employee']);

              setTimeout(() => {
                this.success = false;
                this.tabindex = 3;
                this.employeeDetailsDisabled = true;
              }, redirectMsgTimeOut);
            } else {
              this.error = true;
              this.error_msg = result.message;
              setTimeout(() => {
                this.error = false;
              }, redirectMsgTimeOut);
              this.employeeDetailsDisabled = false;
            }
          },
          (err) => {
            if (err.error.statusCode >= 400) {
              this.error = true;
              this.employeeDetailsDisabled = false;

              this.error_msg = err.erro.description;
            }
          }
        );
        this.employeeDetailsDisabled = false;
      }
    );

    /*   this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      let userId = this.usertData.userId;
      let organizationId = this.usertData.organization.organizationId; */

    /*  var postValues = {
        designation:{
          designationId:  this.employmentDetails.value.designationId
        },
        dateOfJoining:dateOfJoining,
        joiningConfirmationDate : joiningConfirmationDate,
        lastWorkingDate : lastWorkingDate,
        firstLogin:true,
        department:{
          departmentId: this.employmentDetails.value.departmentId
        },
        employmentType:{
          listTypeValueId:this.employmentDetails.value.employmentType
        },
        yearOfExperiance:this.employmentDetails.value.yearOfExperiance,
        ratePerHour:this.employmentDetails.value.ratePerHour,
        ratePerDay:this.employmentDetails.value.ratePerDay,
        organization:{
          organizationId: organizationId
        }
      } */

    /* if(this.userId) //Update Employee // Step - 5 Bank Details
      {
        postValues['userId'] = this.userId;

        postValues['status'] = {
          listTypeValueId: this.employeeStatus
        };

        var userInfo = {};
        userInfo["userInfoId"] = this.userInfoId;


      }
      else
      {
        //Create start here
        this.UserService.createUser(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

            setTimeout(() => {
              this.tabindex = 3;
            }, redirectMsgTimeOut)
          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        }) //Create end here

      } */
    // }
  }

  idSubmit() {
    this.id_step = true;
    this.setAdharbool = true;
    this.setDocument = true;
    // this.setAdharbools=false;

    // this.setDocument = false;

    if (this.idDetails.invalid) {
      const controls = this.idDetails.controls;
      console.log(this.idDetails.controls);
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
          this.setDocument = true;
        }
      }
      return;
    } else {
      //Post Values start
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
      let employeeId = this.employeetData.employeeId;
      let organizationId = this.employeetData.organization.organizationId;
      let passportValidityDate = this.datepipe.transform(
        this.idDetails.value.passportValidityDate,
        'yyyy-MM-dd'
      );
      let licenceValidityDate = this.datepipe.transform(
        this.idDetails.value.licenceValidityDate,
        'yyyy-MM-dd'
      );

      if (this.employeeId) {
        this.EmployeeID = this.employeeId;
      } else if (this.insertEmployeeId) {
        this.EmployeeID = this.insertEmployeeId;
      }

      const control = this.idDetails.get('tableRows') as FormArray;
      this.touchedRows = control.controls
        .filter((row) => row)
        .map((row) => row.value);
      console.log(this.touchedRows);
      const formData = new FormData();
      let documentarray = [];

      this.touchedRows.forEach(async (element, index) => {
        let fileobj;
        if (element.filedata != undefined) {
          fileobj = {
            fileId: element.fileId == null ? 0 : element.fileId,
            fileName: element.fileName,
            fileBytes: element.fileBytes,
            fileType: element.fileType,
            employee: {
              employeeId: this.EmployeeID,
            },
            fileAttachmentType: {
              listTypeValueId: element.identiyDocumentType,
            },
            documentType: {
              listTypeValueId: element.documentType,
            },
            documentDescription: element.description,
          };
        } else {
          // this.setDocument = true;

          fileobj = {
            fileId: element.fileId == null ? 0 : element.fileId,
            fileName: element.fileName,
            //  fileBytes : element.filedata,
            fileType: element.fileType,
            employee: {
              employeeId: this.EmployeeID,
            },
            fileAttachmentType: {
              listTypeValueId: element.identiyDocumentType,
            },
            documentType: {
              listTypeValueId: element.documentType,
            },
            documentDescription: element.description,
          };
        }

        documentarray.push(fileobj);
      });

      this.deletedrows.forEach((deletedelement) => {
        let filedeteobj = {
          fileId: deletedelement.fileId == null ? 0 : deletedelement.fileId,
          fileName: deletedelement.fileName,
          //  fileBytes : element.filedata,
          fileType: deletedelement.fileType,
          employee: {
            employeeId: this.EmployeeID,
          },
          fileAttachmentType: {
            listTypeValueId: deletedelement.identiyDocumentType,
          },
          documentType: {
            listTypeValueId: deletedelement.documentType,
          },
          documentDescription: deletedelement.description,
          status: {
            listTypeValueId: 4,
          },
        };

        documentarray.push(filedeteobj);
      });

      console.log('identitydocuments', this.identitydocuments);

      console.log(documentarray);

      //return
      this.Employeeservice.uploademployeedocuemnts(documentarray).subscribe(
        (result: any) => {
          if (result.statusCode == 200) {
            // this.success = true;
            // this.sucess_msg = result.message;
            documentarray.length = 0;
            this.deletedrows.length = 0;
            const control = this.idDetails.get('tableRows') as FormArray;
            this.clearFormArray(control);
            this.iDInformation = true;
          } else {
            // this.error = true;
            // this.error_msg = result.message;
            // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        }
      );
      this.iDInformation = false;
      this.Employeeservice.getEmployeeById(this.EmployeeID).subscribe(
        (resultData: any) => {
          resultData['aadhaarNumber'] = this.idDetails.value.aadhaarNumber;
          resultData['panNumber'] = this.idDetails.value.panNumber;
          resultData['licenceNumber'] = this.idDetails.value.licenceNumber;
          resultData['passportNumber'] = this.idDetails.value.passportNumber;
          resultData['passportValidityDate'] = passportValidityDate;
          resultData['licenceValidityDate'] = licenceValidityDate;
          resultData['voterId'] = this.idDetails.value.voterId;
          console.log(
            'this.idDetails.value.pfNumber',
            this.idDetails.value.pfNumber
          );
          if (
            this.idDetails.value.pfNumber == '' ||
            this.idDetails.value.pfNumber == null ||
            this.idDetails.value.pfNumber == undefined
          ) {
            console.log('this.idDetails.value.pfNumber null');
            resultData['pfNumber'] = null;
          } else {
            resultData['pfNumber'] = this.idDetails.value.pfNumber;
          }
          if (
            this.idDetails.value.esiNumber == '' ||
            this.idDetails.value.esiNumber == null ||
            this.idDetails.value.esiNumber == undefined
          ) {
            console.log('this.idDetails.value.esiNumber null');
            resultData['esiNumber'] = null;
          } else {
            resultData['esiNumber'] = this.idDetails.value.esiNumber;
          }
          if (
            this.idDetails.value.uanNumber == '' ||
            this.idDetails.value.uanNumber == null ||
            this.idDetails.value.uanNumber == undefined
          ) {
            console.log('this.idDetails.value.esiNumber null');
            resultData['uanNumber'] = null;
          } else {
            resultData['uanNumber'] = this.idDetails.value.uanNumber;
          }

          console.log('resultData', resultData);
          this.Employeeservice.editEmployee(
            resultData,
            this.EmployeeID
          ).subscribe((result: any) => {
            if (result.statusCode == 200) {
              this.success = true;
              this.sucess_msg = result.message;
              //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
              //this.router.navigate(['/home/employee']);

              setTimeout(() => {
                this.success = false;
                this.tabindex = 4;
                this.iDInformation = true;
              }, redirectMsgTimeOut);
            } else {
              this.error = true;
              this.error_msg = result.message;
              setTimeout(() => {
                this.error = false;
              }, redirectMsgTimeOut);
              this.iDInformation = true;
            }
          });
        }
      );

      /*  var postValues = {
        aadhaarNumber:this.idDetails.value.aadhaarNumber,
        panNumber:this.idDetails.value.panNumber,
        licenceNumber:this.idDetails.value.licenceNumber,
        passportNumber:this.idDetails.value.passportNumber,

        passportExpiryDate : passportExpiryDate,
        drivingLicenceExpiryDate : drivingLicenceExpiryDate,

        voterId:this.idDetails.value.voterId,
        pfNumber:this.idDetails.value.pfNumber,
        esiNumber:this.idDetails.value.esiNumber,
        organization:{
          organizationId: organizationId
        }
      } */

      /*  if(this.userId)
      {
        postValues['userId'] = this.userId;

        postValues['status'] = {
          listTypeValueId: this.employeeStatus
        };

        var userInfo = {};
        userInfo["userInfoId"] = this.userInfoId;

        this.UserService.editEmployee(postValues,this.userId).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            //this.router.navigate(['/home/employee']);

            setTimeout(() => {
              this.tabindex = 4;
            }, redirectMsgTimeOut)

          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        })
      }
      else
      {
        //Create start here
        this.UserService.createUser(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

            setTimeout(() => {
              this.tabindex = 4;
            }, redirectMsgTimeOut)
          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        }) //Create end here
      } */
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
  get aadhaarNumber() {
    return this.idDetails.get('aadhaarNumber');
  }
  addressSubmit() {
    this.address_step = true;

    if (this.addressDetails.invalid) {
      return;
    } else {
      //Post Values start
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
      let employeeId = this.employeetData.employeeId;
      let organizationId = this.employeetData.organization.organizationId;

      if (this.employeeId) {
        this.EmployeeID = this.employeeId;
      } else if (this.insertEmployeeId) {
        this.EmployeeID = this.insertEmployeeId;
      }
      this.addressDisabled = false;
      this.Employeeservice.getEmployeeById(this.EmployeeID).subscribe(
        (resultData: any) => {
          resultData['address'] = this.addressDetails.value.address;
          resultData['postalCode'] = this.addressDetails.value.postalCode;

          let employeeInfo = {
            district: {
              districtId: this.addressDetails.value.districtId,
            },
            country: {
              countryId: this.addressDetails.value.countryId,
            },
            state: {
              stateId: this.addressDetails.value.stateId,
            },
            permenantAddress: this.addressDetails.value.permenantAddress,
            permenantPostalCode: this.addressDetails.value.permenantPostalCode,
            copyCurrentAddress: this.addressDetails.value.copyCurrentAddress,
          };

          if (this.addressDetails.value.countryId) {
            employeeInfo['country'] = {
              countryId: this.addressDetails.value.countryId,
            };
          }

          if (this.addressDetails.value.stateId) {
            employeeInfo['state'] = {
              stateId: this.addressDetails.value.stateId,
            };
          }

          if (this.addressDetails.value.districtId) {
            employeeInfo['district'] = {
              districtId: this.addressDetails.value.districtId,
            };
          }

          if (this.addressDetails.value.cityId) {
            employeeInfo['city'] = {
              cityId: this.addressDetails.value.cityId,
            };
          }

          if (this.addressDetails.value.permenantCountryId) {
            employeeInfo['permenantCountry'] = {
              countryId: this.addressDetails.value.permenantCountryId,
            };
          }

          if (this.addressDetails.value.permenantStateId) {
            employeeInfo['permenantState'] = {
              stateId: this.addressDetails.value.permenantStateId,
            };
          }

          if (this.addressDetails.value.permenantDistrictId) {
            employeeInfo['permenantDistrict'] = {
              districtId: this.addressDetails.value.permenantDistrictId,
            };
          }

          if (this.addressDetails.value.permenantCityId) {
            employeeInfo['permenantCity'] = {
              cityId: this.addressDetails.value.permenantCityId,
            };
          }

          if (resultData.employeeInfo != undefined) {
            let employeeInfoId = resultData.employeeInfo.employeeInfoId;

            if (employeeInfoId) {
              employeeInfo['employeeInfoId'] = employeeInfoId;
            }
          }

          resultData['employeeInfo'] = employeeInfo;
          console.log('address payload::', resultData);
          // return;

          this.addressDisabled = false;
          this.Employeeservice.editEmployee(
            resultData,
            this.EmployeeID
          ).subscribe((result: any) => {
            //userInfo["userInfoId"] = this.userInfoId;

            if (result.statusCode == 200) {
              this.success = true;
              this.sucess_msg = result.message;
              //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
              //this.router.navigate(['/home/employee']);

              setTimeout(() => {
                this.success = false;
                this.tabindex = 5;
              }, redirectMsgTimeOut);
              this.addressDisabled = true;
            } else {
              this.error = true;
              this.error_msg = result.message;
              setTimeout(() => {
                this.error = false;
              }, redirectMsgTimeOut);
              this.addressDisabled = true;
            }
          });
        }
      );

      /* var postValues = {
        address:this.addressDetails.value.address,
        postalCode:this.addressDetails.value.postalCode,
        organization:{
          organizationId: organizationId
        }
      } */

      /*  let userInfo =
      {
        district : {
          districtId:this.addressDetails.value.districtId,
        },
        country : {
          countryId: this.addressDetails.value.countryId,
        },
        state : {
          stateId: this.addressDetails.value.stateId,
        },
        permenantAddress:this.addressDetails.value.permenantAddress,
        permenantpostalCode:this.addressDetails.value.permenantpostalCode,
        copyCurrentAddress:this.addressDetails.value.copyCurrentAddress
      };

      if(this.addressDetails.value.cityId)
      {
        userInfo["city"] =  {
          cityId:this.addressDetails.value.cityId,
        }
      }

      if(this.addressDetails.value.permenantCountryId)
      {
        userInfo["permenantCountry"] =  {
          countryId: this.addressDetails.value.permenantCountryId,
        }
      }

      if(this.addressDetails.value.permenantStateId)
      {
        userInfo["permenantState"] =  {
          stateId: this.addressDetails.value.permenantStateId,
        }
      }

      if(this.addressDetails.value.permenantDistrictId)
      {
        userInfo["permenantDistrict"] =  {
          districtId:this.addressDetails.value.permenantDistrictId,
        }
      }

      if(this.addressDetails.value.permenantCityId)
      {
        userInfo["permenantCity"] =  {
          cityId:this.addressDetails.value.permenantCityId,
        }
      } */

      /* if(this.userId)
      {
        postValues['userId'] = this.userId;


        postValues['status'] = {
          listTypeValueId: this.employeeStatus
        };

        //var userInfo = {};
        userInfo["userInfoId"] = this.userInfoId;

        this.UserService.editEmployee(postValues,this.userId).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            //this.router.navigate(['/home/employee']);

            setTimeout(() => {
              this.tabindex = 5;
            }, redirectMsgTimeOut)

          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        })
      }
      else
      {
        //Create start here
        this.UserService.createUser(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

            setTimeout(() => {
              this.tabindex = 5;
            }, redirectMsgTimeOut)
          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        }) //Create end here
      } */
    }
  }

  getclientEmployees() {
    this.empList.length = 0;
    console.log(this.basicInformationDetails.value.client);
    if (
      this.basicInformationDetails.value.client != null &&
      this.basicInformationDetails.value.client != 'null'
    ) {
      this.payrollService
        .getAllEmployeeClientList(this.basicInformationDetails.value.client)
        .subscribe((ClientEmployees: any) => {
          if (ClientEmployees.length != undefined) {
            // ClientEmployees.forEach(element => {
            //   this.empList.push(element.employee);
            // });
          }
        });

      this.payrollService
        .clientelements(this.basicInformationDetails.value.client)
        .subscribe((result: any) => {
          if (result.statusCode) {
            this.elementlist.length = 0;
          } else {
            console.log('else');
            this.clientelements = result;
            this.maxpfamount = this.clientelements[0].pfMaxSalaryAmount;
            this.employeePfAmount = this.clientelements[0].employeePfAmount;
            this.employerPfAmount = this.clientelements[0].employerPfAmount;

            this.maxesiamount = this.clientelements[0].esiMaxSalaryAmount;
            this.employeeEsiAmount = this.clientelements[0].employeeEsiAmount;
            this.employerEsiAmount = this.clientelements[0].employerEsiAmount;

            //     result.forEach(cleintelemets => {
            //       cleintelemets.lines.forEach(lines => {
            //           this.elementlist.push(lines.element);
            //       });

            //     });

            // this.filterele =  this.elementlist.filter(ele=> ele.elementName.includes( "Basic" ) ||  ele.elementName.includes( "HRA" ) );
            // this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
            //        console.log(this.filterele);

            // const control = this.postForm.get('tableRows') as FormArray;

            // console.log("this.filterele",this.filterele);
            // this.filterele.forEach((element ,index) => {
            //          this.addRow();
            //           if( element.elementName != "HRA")
            //           {
            //             control.at(index).patchValue({
            //               hrElementPercentagelist : element.hrElementPercentage,
            //               hrElementPercentage :  element.percentage ? element.percentage.elementPercentageId : 0,
            //               element : element.elementId,
            //               elementName : element.elementName
            //              })
            //           }else{
            //             control.at(index).patchValue({
            //               hrElementPercentagelist : element.hrElementPercentage,
            //               element : element.elementId,
            //               elementName : element.elementName
            //              })
            //           }
            //     });
            //  console.log(control);
          }
        });
    }
  }

  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  bankSubmit() {
    this.bank_step = true;
    this.setbankbool = true;

    if (this.bankDetails.invalid) {
      // this.bank_step=false
      return;
    } else {
      //Post Values start
      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
      let employeeId = this.employeetData.employeeId;
      let organizationId = this.employeetData.organization.organizationId;

      if (this.employeeId) {
        this.EmployeeID = this.employeeId;
      } else if (this.insertEmployeeId) {
        this.EmployeeID = this.insertEmployeeId;
      }
      this.addBankDetails = false;
      this.Employeeservice.getEmployeeById(this.EmployeeID).subscribe(
        (resultData: any) => {
          let employeeInfo = {
            bankAccountNo: this.bankDetails.value.bankAccountNo,
            bankName: this.bankDetails.value.bankName,
            ifscCode: this.bankDetails.value.ifscCode,
            bankBranchName: this.bankDetails.value.bankBranchName,
            bankAddress: this.bankDetails.value.bankAddress,
            accountName: this.bankDetails.value.bankAccountHolderName,
            micrCode: this.bankDetails.value.micrCode,
          };

          if (resultData.employeeInfo != undefined) {
            let employeeInfoId = resultData.employeeInfo.employeeInfoId;

            if (employeeInfoId) {
              resultData.employeeInfo['employeeInfoIdId'] = employeeInfoId;
            }
          } else {
            resultData['employeeInfo'] = {};
          }

          /* bankAccountNo:this.bankDetails.value.bankAccountNo,
          bankName:this.bankDetails.value.bankName,
          ifscCode:this.bankDetails.value.ifscCode,
          bankBranchName:this.bankDetails.value.bankBranchName,
          bankAddress: this.bankDetails.value.bankAddress,
          accountName: this.bankDetails.value.bankAccountHolderName,
          micrCode: this.bankDetails.value.micrCode */
          console.log(employeeInfo);
          resultData.employeeInfo['bankAccountNo'] =
            employeeInfo?.bankAccountNo;
          resultData.employeeInfo['bankName'] = employeeInfo?.bankName;
          resultData.employeeInfo['ifscCode'] = employeeInfo?.ifscCode;
          resultData.employeeInfo['bankBranchName'] =
            employeeInfo?.bankBranchName;
          resultData.employeeInfo['bankAddress'] = employeeInfo?.bankAddress;
          resultData.employeeInfo['accountName'] = employeeInfo?.accountName;
          resultData.employeeInfo['micrCode'] = employeeInfo?.micrCode;

          //resultData["userInfo"] = userInfo

          //console.log(resultData);return;
          this.addBankDetails = false;
          this.Employeeservice.editEmployee(
            resultData,
            this.EmployeeID
          ).subscribe((result: any) => {
            if (result.statusCode == 200) {
              this.success = true;
              this.addBankDetails = true;

              this.sucess_msg = result.message;
              setTimeout(() => {
                this.success = false;
              }, redirectMsgTimeOut);
              //this.router.navigate(['/home/employee']);

              setTimeout(() => {
                this.router.navigate(['/home/employee']);
              }, redirectMsgTimeOut);
              this.addBankDetails = true;
            } else {
              this.error = true;
              this.error_msg = result.message;
              setTimeout(() => {
                this.error = false;
              }, redirectMsgTimeOut);
              this.addBankDetails = true;
            }
          });
        }
      );

      /*  let userInfo =
      {
        bankAccountNo:this.bankDetails.value.bankAccountNo,
        bankName:this.bankDetails.value.bankName,
        ifscCode:this.bankDetails.value.ifscCode,
        bankBranchName:this.bankDetails.value.bankBranchName,
        bankAddress: this.bankDetails.value.bankAddress,
        accountName: this.bankDetails.value.bankAccountHolderName,
        micrCode: this.bankDetails.value.micrCode
      }; */

      /*  var postValues = {};

      postValues["userInfo"] = userInfo;

      if(this.userId)
      {
        postValues['userId'] = this.userId;

        postValues['status'] = {
          listTypeValueId: this.employeeStatus
        };

        //var userInfo = {};
        userInfo["userInfoId"] = this.userInfoId;

        this.UserService.editEmployee(postValues,this.userId).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            //this.router.navigate(['/home/employee']);

            setTimeout(() => {
              this.router.navigate(['/home/employee']);
            }, redirectMsgTimeOut)
          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        })
      }
      else
      {
        //Create start here
        this.UserService.createUser(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

            if(this.userId)
            {
              setTimeout(() => {
                this.router.navigate(['/home/employee']);
              }, redirectMsgTimeOut)
            }
            else
            {
              this.tabindex = 6;
            }
          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          }
        }) //Create end here
      } */
    }
  }

  /*  loginSubmit()
  {
    this.login_step = true;

    if (this.loginDetails.invalid)
    {
      return;
    }
    else
    {
      //Post Values start
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      let userId = this.usertData.userId;
      let organizationId = this.usertData.organization.organizationId;

      var postValues = {
        password:this.loginDetails.value.password,
        firstLogin:true,
        organization:{
          organizationId: organizationId
        }
      }

      //Create start here
      this.UserService.createUser(postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

          setTimeout(() => {
            this.router.navigate(['/home/employee']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        }
      }) //Create end here
    }
  } */

  //Login Submit
  submit() {
    //if(this.step==6 || this.step==5)
    if (this.step == 5) {
      /*  this.login_step = true;
      if (this.loginDetails.invalid && !this.userId && this.step==6)
      {
        return
      }
      else if (this.bankDetails.invalid && this.userId && this.step==5)*/
      if (this.bankDetails.invalid && this.employeeId && this.step == 5) {
        const controls = this.bankDetails.controls;
        /* console.log(controls);
        for (const name in controls) {
            if (controls[name].invalid) {
              console.log(name);
            }
        } */
        return;
      } else {
        //Post Values start
        this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
        let employeeId = this.employeetData.employeeId;
        let organizationId = this.employeetData.organization.organizationId;

        let dateOfBirth = this.datepipe.transform(
          this.basicInformationDetails.value.dateOfBirth,
          'yyyy-MM-dd'
        );
        let dateOfJoining = this.datepipe.transform(
          this.employmentDetails.value.dateOfJoining,
          'yyyy-MM-dd'
        );

        let confirmationDate = this.datepipe.transform(
          this.employmentDetails.value.confirmationDate,
          'yyyy-MM-dd'
        );
        let lastWorkingDate = this.datepipe.transform(
          this.employmentDetails.value.lastWorkingDate,
          'yyyy-MM-dd'
        );

        let passportValidityDate = this.datepipe.transform(
          this.idDetails.value.passportValidityDate,
          'yyyy-MM-dd'
        );
        let licenceValidityDate = this.datepipe.transform(
          this.idDetails.value.licenceValidityDate,
          'yyyy-MM-dd'
        );

        var postValues = {
          firstName: this.basicInformationDetails.value.firstName,
          middleName: this.basicInformationDetails.value.middleName,
          lastName: this.basicInformationDetails.value.lastName,
          // client:this.basicInformationDetails.value.client,
          client: {
            // clientId:this.basicInformationDetails.value.client
            clientId: this.cliId,
          },
          employeeNumber: this.basicInformationDetails.value.employeeNumber,
          address: this.addressDetails.value.address,
          mobileNumber: this.basicInformationDetails.value.mobileNumber,
          dateOfBirth: dateOfBirth,
          designation: {
            designationId: this.employmentDetails.value.designationId,
          },
          gender: {
            listTypeValueId: this.basicInformationDetails.value.gender,
          },
          businessgroup: {
            businessGroupId: this.basicInformationDetails.value.businessgroup,
          },
          dateOfJoining: dateOfJoining,
          confirmationDate: confirmationDate,
          lastWorkingDate: lastWorkingDate,
          organizationEmail:
            this.basicInformationDetails.value.organizationEmail,
          alternativeEmail: this.basicInformationDetails.value.alternativeEmail,
          password: this.basicInformationDetails.value.password,
          firstLogin: true,
          aadhaarNumber: this.idDetails.value.aadhaarNumber,
          panNumber: this.idDetails.value.panNumber,
          licenceNumber: this.idDetails.value.licenceNumber,
          /*  role:{
            roleId: this.basicInformationDetails.value.roleId
          }, */
          department: {
            departmentId: this.employmentDetails.value.departmentId,
          },
          employmentType: {
            listTypeValueId: this.employmentDetails.value.employmentType,
          },
          passportNumber: this.idDetails.value.passportNumber,

          passportValidityDate: passportValidityDate,
          licenceValidityDate: licenceValidityDate,

          voterId: this.idDetails.value.voterId,
          postalCode: this.addressDetails.value.postalCode,
          yearOfExperiance: this.employmentDetails.value.yearOfExperiance,
          pfNumber: this.idDetails.value.pfNumber,
          esiNumber: this.idDetails.value.esiNumber,
          uanNumber: this.idDetails.value.uanNumber,
          //annualCtc:0, //Annual CTC
          ratePerHour: this.employmentDetails.value.ratePerHour,
          ratePerDay: this.employmentDetails.value.ratePerDay,
          organization: {
            organizationId: organizationId,
          },
        };

        let employeeInfo = {
          /* district : {
            districtId:this.addressDetails.value.districtId,
          },
          country : {
            countryId: this.addressDetails.value.countryId,
          },
          state : {
            stateId: this.addressDetails.value.stateId,
          }, */

          bankAccountNo: this.bankDetails.value.bankAccountNo,
          bankName: this.bankDetails.value.bankName,
          ifscCode: this.bankDetails.value.ifscCode,
          bankBranchName: this.bankDetails.value.bankBranchName,
          permenantAddress: this.addressDetails.value.permenantAddress,
          permenantPostalCode: this.addressDetails.value.permenantPostalCode,
          copyCurrentAddress: this.addressDetails.value.copyCurrentAddress,
          bankAddress: this.bankDetails.value.bankAddress,
          accountName: this.bankDetails.value.bankAccountHolderName,
          micrCode: this.bankDetails.value.micrCode,
        };

        if (this.addressDetails.value.countryId) {
          employeeInfo['country'] = {
            countryId: this.addressDetails.value.countryId,
          };
        }

        if (this.addressDetails.value.stateId) {
          employeeInfo['state'] = {
            stateId: this.addressDetails.value.stateId,
          };
        }

        if (this.addressDetails.value.districtId) {
          employeeInfo['district'] = {
            districtId: this.addressDetails.value.districtId,
          };
        }

        if (this.addressDetails.value.cityId) {
          employeeInfo['city'] = {
            cityId: this.addressDetails.value.cityId,
          };
        }

        /*  else
        {
          userInfo["city"] =  {
            cityId:0,
          }
        } */

        if (this.addressDetails.value.permenantCountryId) {
          employeeInfo['permenantCountry'] = {
            countryId: this.addressDetails.value.permenantCountryId,
          };
        }

        if (this.addressDetails.value.permenantStateId) {
          employeeInfo['permenantState'] = {
            stateId: this.addressDetails.value.permenantStateId,
          };
        }

        if (this.addressDetails.value.permenantDistrictId) {
          employeeInfo['permenantDistrict'] = {
            districtId: this.addressDetails.value.permenantDistrictId,
          };
        }

        if (this.addressDetails.value.permenantCityId) {
          employeeInfo['permenantCity'] = {
            cityId: this.addressDetails.value.permenantCityId,
          };
        }

        postValues['employeeInfo'] = employeeInfo;

        if (this.filename) {
          let employeeAttachment = {
            fileId: this.fileId,
            fileName: this.filename,
            fileType: this.fileType,
            fileSize: this.fileSize,
            fileBytes: this.fileByte,
          };

          postValues['employeeAttachment'] = employeeAttachment;
        }

        if (this.basicInformationDetails.value.bloodGroup) {
          postValues['bloodGroup'] = {
            listTypeValueId: this.basicInformationDetails.value.bloodGroup,
          };
        }

        if (this.basicInformationDetails.value.martialStatus) {
          postValues['martialStatus'] = {
            listTypeValueId: this.basicInformationDetails.value.martialStatus,
          };
        }

        if (this.basicInformationDetails.value.qualification) {
          postValues['qualification'] =
            this.basicInformationDetails.value.qualification;
        }

        if (this.basicInformationDetails.value.alternativeMobileNumber) {
          postValues['alternativeMobileNumber'] =
            this.basicInformationDetails.value.alternativeMobileNumber;
        }

        if (this.basicInformationDetails.value.streamId) {
          postValues['stream'] = {
            streamId: this.basicInformationDetails.value.streamId,
          };
        }

        if (this.employmentDetails.value.branchId) {
          postValues['branch'] = {
            branchId: this.employmentDetails.value.branchId,
          };
        }

        if (this.employmentDetails.value.payFrequency) {
          postValues['payFrequency'] = {
            listTypeValueId: this.employmentDetails.value.payFrequency,
          };
        }
        //Post Values end

        if (this.employeeId && this.step == 5) {
          //Update Employee // Step - 5 Bank Details
          postValues['employeeId'] = this.employeeId;

          postValues['status'] = {
            listTypeValueId: this.employeeStatus,
          };

          employeeInfo['employeeInfoId'] = this.employeeInfoId;
          // console.log("PPV : ",postValues);return;
          this.Employeeservice.editEmployee(
            postValues,
            this.employeeId
          ).subscribe((result: any) => {
            if (result.statusCode == 200) {
              this.success = true;
              this.sucess_msg = result.message;
              setTimeout(() => {
                this.success = false;
              }, redirectMsgTimeOut);
              //this.router.navigate(['/home/employee']);

              setTimeout(() => {
                this.router.navigate(['/home/employee']);
              }, redirectMsgTimeOut);
            } else {
              this.error = true;
              this.error_msg = result.message;
              setTimeout(() => {
                this.error = false;
              }, redirectMsgTimeOut);
            }
          });
        } //Create Employee
        else {
          this.Employeeservice.createEmployee(postValues).subscribe(
            (result: any) => {
              if (result.statusCode == 200) {
                this.success = true;
                this.sucess_msg = result.message;
                setTimeout(() => {
                  this.success = false;
                }, redirectMsgTimeOut);

                setTimeout(() => {
                  this.router.navigate(['/home/employee']);
                }, redirectMsgTimeOut);
              } else {
                this.error = true;
                this.error_msg = result.message;
                setTimeout(() => {
                  this.error = false;
                }, redirectMsgTimeOut);
              }
            }
          ); //Create end here
        }
      }
    }
  }

  //Employee Profile Tabs click
  employeeDetails(val) {
    // this.bank_step=false
    // this.setbankbool=false;
    this.employment_step = false;

    if (this.employmentDetails.invalid) {
      console.log("return")
      return;
    }
    console.log('Previous tab' + val);
    this.tabindex = val;
    console.log('tab' + this.tabindex + 'EMPID' + this.employeeId);
    if (val == 3) {
      console.log('Val' + val);
      let employeeId;
      if (this.employeeId) {
        employeeId = this.employeeId;
      } else if (this.insertEmployeeId) {
        employeeId = this.insertEmployeeId;
      }
      const control = this.idDetails.get('tableRows') as FormArray;
      this.clearFormArray(control);
      this.getEmployeeIndentityattachments(employeeId);
    }

    if (val == 4 && this.employeeId) {
      console.log('Last tab' + val);
      //alert(this.editCountryId+"=="+this.editStateId+"=="+this.editDitrictId);
      this.getActiveState(this.editCountryId);
      this.getActiveDistrict(this.editStateId);
      this.getActiveCity(this.editDitrictId);
      this.Employeeservice.getEmployeeById(this.employeeId).subscribe(
        (resultData: any) => {
          this.resultData = resultData;
          console.log('this.resultData :::', this.resultData);
          if (this.resultData.employeeInfo) {
            console.log('here entered!!!');

            this.addressDetails.patchValue({
              countryName:
                this.resultData.employeeInfo.country?.countryName || '',
              countryId: this.resultData.employeeInfo.country?.countryId || '',
              stateName: this.resultData.employeeInfo.state?.stateName || '',
              stateId: this.resultData.employeeInfo.state?.stateId || '',
              districtName:
                this.resultData.employeeInfo.district?.districtName || '',
              districtId:
                this.resultData.employeeInfo.district?.districtId || '',
              cityName: this.resultData.employeeInfo.city?.cityName || '',
              cityId: this.resultData.employeeInfo.city?.cityId || '',
              postalCode: this.resultData?.postalCode,
              address: this.resultData?.address,
              permenantCountryId:
                this.resultData.employeeInfo.permenantCountry.countryId || '',
              permenantCountryName:
                this.resultData?.employeeInfo?.permenantCountry?.countryName ||
                '',
              permenantStateName:
                this.resultData?.employeeInfo?.permenantState?.stateName || '',
              permenantStateId:
                this.resultData?.employeeInfo?.permenantState?.stateId || '',
              permenantDistrictId:
                this.resultData?.employeeInfo?.permenantDistrict?.districtId ||
                '',
              permenantDistrictName:
                this.resultData?.employeeInfo?.permenantDistrict
                  ?.districtName || '',
              permenantCityId:
                this.resultData?.employeeInfo?.permenantCity?.cityId || '',
              permenantCityName:
                this.resultData?.employeeInfo?.permenantCity?.cityName || '',
            });
            // this.addressDetails.patchValue({
            // countryName: this.resultData.employeeInfo.country.countryName,
            //   countryId: this.resultData.employeeInfo.country.countryId,
            // });

            // this.addressDetails.patchValue({
            //   stateName: this.resultData.employeeInfo.state.stateName,
            //   stateId: this.resultData.employeeInfo.state.stateId,
            // });

            // this.addressDetails.patchValue({
            //   districtName: this.resultData.employeeInfo.district.districtName,
            //   districtId: this.resultData.employeeInfo.district.districtId,
            // });

            // this.addressDetails.patchValue({
            //   cityName: this.resultData.employeeInfo.city.cityName,
            //   cityId: this.resultData.employeeInfo.city.cityId,
            // });

            // if (this.resultData.employeeInfo.permenantCountry) {
            //   this.addressDetails.patchValue({
            //     permenantCountryId:
            //       this.resultData.employeeInfo.permenantCountry.countryId,
            //     permenantCountryName:
            //       this.resultData.employeeInfo.permenantCountry.countryName,
            //   });
            // }

            // if (this.resultData.employeeInfo.permenantState) {
            //   this.addressDetails.patchValue({
            //     permenantStateId:
            //       this.resultData.employeeInfo.permenantState.stateId,
            //     permenantStateName:
            //       this.resultData.employeeInfo.permenantState.stateName,
            //   });
            // }

            // if (this.resultData.employeeInfo.permenantDistrict) {
            //   this.addressDetails.patchValue({
            //     permenantDistrictId:
            //       this.resultData.employeeInfo.permenantDistrict.districtId,
            //     permenantDistrictName:
            //       this.resultData.employeeInfo.permenantDistrict.districtName,
            //   });
            // }

            // if (this.resultData.employeeInfo.permenantCity) {
            //   this.addressDetails.patchValue({
            //     permenantCityId:
            //       this.resultData.employeeInfo.permenantCity.cityId,
            //     permenantCityName:
            //       this.resultData.employeeInfo.permenantCity.cityName,
            //   });
            // }
          }
        }
      );
    }
  }
  // dateComparisonValidator(control: AbstractControl): ValidationErrors | null {
  //   const confirmationDates = control.get('confirmationDate')?.value;
  //   // this.storeConfirmDate=confirmationDate
  //   const dateOfJoining = control.get('dateOfJoining')?.value;
  //   if (
  //     confirmationDates &&
  //     dateOfJoining &&
  //     new Date(dateOfJoining) < new Date(confirmationDates)
  //   ) {
  //     return { dateComparison: true };
  //   }
  //   return null;
  // }
  dateComparisonValidator(control: AbstractControl): ValidationErrors | null {
    const dateOfJoining = control.get('dateOfJoining')?.value;
    const confirmationDate = control.get('confirmationDate')?.value;
    const lastWorkingDate = control.get('lastWorkingDate')?.value;

    // Check if Date of Joining is before Confirmation Date
    if (
      confirmationDate &&
      dateOfJoining &&
      new Date(dateOfJoining) > new Date(confirmationDate)
    ) {
      return {
        dateComparison: true,
        message: 'Date of Joining cannot be after Confirmation Date.',
      };
    }

    // Check if Last Working Date is after Date of Joining
    if (
      lastWorkingDate &&
      dateOfJoining &&
      new Date(lastWorkingDate) < new Date(dateOfJoining)
    ) {
      return {
        dateComparison: true,
        message: 'Last Working Date cannot be before Date of Joining.',
      };
    }

    // All validations passed
    return null;
  }

  // updateEndDateMin(event: MatDatepickerInputEvent<Date>) {
  //   const selectedJoiningDate = event.value; // Selected "Date of Joining"

  //   if (selectedJoiningDate) {
  //     // Set the minimum date for "Last Working Date"
  //     this.minLastWorkingDate = new Date(selectedJoiningDate);
  //     this.employmentDetails.get('lastWorkingDate')?.setValidators([
  //       Validators.required,
  //       Validators.min(this.minLastWorkingDate.getTime()),
  //     ]);
  //     this.employmentDetails.get('lastWorkingDate')?.updateValueAndValidity();
  //   }
  // }
  checkExistEmployeeNo(val) {
    if (this.basicInformationDetails.value.employeeNumber.length != 0) {
      //let postValues = this.basicInformationDetails.value;
      this.organizationId = this.organizationId;
      var employeeNumberValue = {
        employeeNumber: this.basicInformationDetails.value.employeeNumber,
        organization: {
          organizationId: this.organizationId,
        },
      };

      this.Employeeservice.createCheckExistEmployeeNumber(
        employeeNumberValue
      ).subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.employeeNumberExistStatus = false;
        } else if (result.statusCode > 400) {
          this.employeeNumberExistStatus = true;
        }
      });
    } else {
      this.employeeNumberExistStatus = false;
    }
  }

  checkExistEmail(val) {
    if (this.basicInformationDetails.value.organizationEmail.length != 0) {
      var postValue = {
        organizationEmail: this.basicInformationDetails.value.organizationEmail,
        organization: {
          organizationId: this.organizationId,
        },
      };

      this.Employeeservice.createCheckExistEmail(postValue).subscribe(
        (result: any) => {
          if (result.statusCode == 200) {
            this.emailExistStatus = false;
          } else if (result.statusCode > 400) {
            this.emailExistStatus = true;
          }
        }
      );
    } else {
      this.emailExistStatus = false;
    }
  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === 'Space' || event.key === ' ') {
      event.preventDefault();
    }
  }

  alternateCheckExistEmail(val) {
    if (this.basicInformationDetails.value.alternativeEmail.length != 0) {
      if (this.employeeId) {
        //Edit
        var postValue = {
          employeeId: this.employeeId,
          alternativeEmail: this.basicInformationDetails.value.alternativeEmail,
        };

        this.Employeeservice.updateCheckExistAlternativeEmail(
          postValue
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.alternateemailExistStatus = false;
          } else if (result.statusCode > 400) {
            this.alternateemailExistStatus = true;
          }
        });
      } //Create
      else {
        var postValue1 = {
          alternativeEmail: this.basicInformationDetails.value.alternativeEmail,
        };

        this.Employeeservice.createCheckExistAlternativeEmail(
          postValue1
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.alternateemailExistStatus = false;
          } else if (result.statusCode > 400) {
            this.alternateemailExistStatus = true;
          }
        });
      }
    } else {
      this.alternateemailExistStatus = false;
    }
  }

  /* alternateCheckExistEmail(val)
  {
    if(this.basicInformationDetails.value.alternativeEmail.length != 0)
    {
      var postValue = {
        alternativeEmail     : this.basicInformationDetails.value.alternativeEmail
      }

      this.UserService.updateCheckExistAlternativeEmail(postValue).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {event
          this.alternateemailExistStatus = false;
        }
        else if( result.statusCode > 400)
        {
          this.alternateemailExistStatus = true;
        }
      })
    }
    else
    {
      this.alternateemailExistStatus = false;
    }
  } */
  onPanNumberInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    // Update the value in the form control
    this.idDetails.controls.panNumber.setValue(value, {
      emitEvent: false, // Prevents triggering valueChanges or statusChanges
    });
  }
  onPassportNumberChange(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.toUpperCase().trim();

    // Optional: Limit the length of the value if needed
    if (value.length > 10) {
      value = value.substring(0, 10);
    }

    this.idDetails.controls.passportNumber.setValue(value, {
      emitEvent: false,
    });
  }
  convertToUppercase() {
    const licenceNumberControl = this.idDetails.get('licenceNumber');
    const value = licenceNumberControl?.value;
    if (value) {
      const newValue = value.replace(/\s+/g, '').toUpperCase();
      licenceNumberControl?.setValue(newValue, { emitEvent: false });
    }
  }
  convertToUpperCase(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.toUpperCase();
    value = value.replace(/\s+/g, '');
    input.value = value;
    this.idDetails.get('voterId')?.setValue(value, { emitEvent: false });
  }

  validateInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
    this.idDetails.get('uanNumber')?.setValue(inputElement.value);
  }

  // validateInputSpace(event) {
  //   // const selectedDate = event.value; // This gives you the selected date object
  //   // if (selectedDate) {
  //   //   const year = selectedDate.getFullYear(); // Extract the year
  //   //   console.log('Selected Year:', year);

  //   //   this.finalPassword =
  //   //     this.finalPassword +
  //   //     '@' + // Add the @ symbol
  //   //     year;

  //   //   this.basicInformationDetails.patchValue({
  //   //     password: this.finalPassword,
  //   //   });
  //   //   console.log('this.finalPassword', this.finalPassword);
  //   //   console.log(
  //   //     'formvalue',
  //   //     this.basicInformationDetails.get('password').value
  //   //   );
  //   // }
  //   // // this.finalPassword = '';

  //   // const textarea = event.target;
  //   // let value = textarea.value;

  //   // console.log("value:",value)

  //   // // Allow space only if it's not the first character and the previous character is not a space
  //   // if (
  //   //   event.key === ' ' &&
  //   //   (textarea.selectionStart === 0 ||
  //   //     value[textarea.selectionStart - 1] === ' ')
  //   // ) {
  //   //   event.preventDefault();
  //   //   return;
  //   // }
  //   // value = value?.replace(/\s{2,}/g, ' ');
  //   // textarea.value = value;

  //   // // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
  //   // const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
  //   // const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

  //   // if (datePattern1.test(value) || datePattern2.test(value)) {
  //   //   textarea.value = value
  //   //     .replace(datePattern1, '')
  //   //     .replace(datePattern2, '');
  //   // }
  //   if (event.value) {
  //     const selectedDate = event.value; // Selected date object
  //     const year = selectedDate.getFullYear(); // Extract year
  //     console.log('Selected Year:', year);
  
  //     this.finalPassword =
  //       this.finalPassword +
  //       '@' + // Add the @ symbol
  //       year;
  
  //     this.basicInformationDetails.patchValue({
  //       password: this.finalPassword,
  //     });
  //     console.log('this.finalPassword', this.finalPassword);
  //     console.log(
  //       'formvalue',
  //       this.basicInformationDetails.get('password').value
  //     );
  //     return; // Exit function to avoid further processing for dateChange
  //   }
  
  //   // For keyup/keydown events
  //   const textarea = event.target as HTMLInputElement;
  //   if (!textarea) {
  //     console.error('Textarea is not defined');
  //     return;
  //   }
  
  //   let value = textarea.value || '';
  
  //   // Allow space only if it's not the first character and not preceded by a space
  //   if (
  //     event.key === ' ' &&
  //     (textarea.selectionStart === 0 ||
  //       value[textarea.selectionStart - 1] === ' ')
  //   ) {
  //     event.preventDefault();
  //     return;
  //   }
  
  //   // Replace consecutive spaces with a single space
  //   value = value.replace(/\s{2,}/g, ' ');
  //   textarea.value = value;
  
  //   // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
  //   const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
  //   const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
  //   if (datePattern1.test(value) || datePattern2.test(value)) {
  //     textarea.value = value
  //       .replace(datePattern1, '')
  //       .replace(datePattern2, '');
  //   }
  
  //   console.log('Processed value:', textarea.value);
  // }

  validateInputSpace(event) {
    if (event.value) {
      const selectedDate = event.value; // Selected date object
      const year = selectedDate.getFullYear(); // Extract year
      console.log('Selected Year:', year);
  
      // Replace the existing year if it exists, otherwise append it
      const yearPattern = /@\d{4}$/; // Pattern to match a year at the end of the string
      if (this.finalPassword.match(yearPattern)) {
        this.finalPassword = this.finalPassword.replace(yearPattern, `@${year}`);
      } else {
        this.finalPassword += `@${year}`;
      }
  
      this.basicInformationDetails.patchValue({
        password: this.finalPassword,
      });
      console.log('this.finalPassword', this.finalPassword);
      console.log(
        'formvalue',
        this.basicInformationDetails.get('password').value
      );
      return; // Exit function to avoid further processing for dateChange
    }
  
    // For keyup/keydown events
    const textarea = event.target as HTMLInputElement;
    if (!textarea) {
      console.error('Textarea is not defined');
      return;
    }
  
    let value = textarea.value || '';
  
    // Allow space only if it's not the first character and not preceded by a space
    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }
  
    // Replace consecutive spaces with a single space
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  
    console.log('Processed value:', textarea.value);
  }
  
  toUpperCase(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
    this.bankDetails
      .get('ifscCode')
      ?.setValue(input.value, { emitEvent: false });
  }
  preventCopyPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  preventNumberInput(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  }
  preventInvalidInput(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    const charStr = String.fromCharCode(charCode);

    // Regular expression to allow only letters and spaces
    if (!/^[a-zA-Z\s]*$/.test(charStr)) {
      event.preventDefault();
    }
  }

  checkExistMobileNumber(val) {
    if (this.basicInformationDetails.value.mobileNumber.length > 0) {
      let mobileNumberLength =
        this.basicInformationDetails.value.mobileNumber.length;

      if (mobileNumberLength != this.maxPhoneNumberLength) {
        this.mobileVali = true;
        //console.log("State : ",this.mobileVali);
      } else {
        this.mobileVali = false;
        var postValue = {
          mobileNumber: this.basicInformationDetails.value.mobileNumber,
        };
        /* console.log("CHECK CONTROL : ",this.basicInformationDetails.value.mobileNumber); */
        this.Employeeservice.createCheckExistMobileNumber(postValue).subscribe(
          (result: any) => {
            if (result.statusCode == 200) {
              this.mobileNumberExistStatus = false;
            } else if (result.statusCode > 400) {
              this.mobileNumberExistStatus = true;
            }
          }
        );
      }
    } else {
      this.mobileVali = false;
      this.mobileNumberExistStatus = false;
    }
  }

  checkExistAlternateMobileNumber(val) {
    if (
      this.basicInformationDetails.value.alternativeMobileNumber.length != 0
    ) {
      var postValue = {
        alternativeMobileNumber:
          this.basicInformationDetails.value.alternativeMobileNumber,
      };

      this.Employeeservice.createCheckExistMobileNumber(postValue).subscribe(
        (result: any) => {
          if (result.statusCode == 200) {
            this.alternateMobileNumberExistStatus = false;
          } else if (result.statusCode > 400) {
            this.alternateMobileNumberExistStatus = true;
          }
        }
      );
    } else {
      this.alternateMobileNumberExistStatus = false;
    }
  }

  /* checkUncheckAll() //Check & Uncheck Copy Current Address
  {
    let copyCurrentAddress = this.addressDetails.value.copyCurrentAddress;
    let currentCountryId = this.addressDetails.value.countryId;
    let currentStateId = this.addressDetails.value.stateId;
    let currentDistrictId = this.addressDetails.value.districtId;
    let currentCityId = this.addressDetails.value.cityId;
    let currentPostalCode = this.addressDetails.value.postalCode;
    let currentAddress = this.addressDetails.value.address;

    if(copyCurrentAddress == false) //Check
    {
      if(currentCountryId)
      {
        this.addressDetails.patchValue({
          permenantCountryId : currentCountryId
        });
      }

      if(currentStateId)
      {
        this.addressDetails.patchValue({
          permenantStateId : currentStateId
        });
      }

      if(currentDistrictId)
      {
        this.addressDetails.patchValue({
          permenantDistrictId : currentDistrictId
        });
      }

      if(currentCityId)
      {
        this.addressDetails.patchValue({
          permenantCityId : currentCityId
        });
      }
      else
      {
        this.addressDetails.get('permenantCityId').setValue(null);
      }

      this.addressDetails.patchValue({
        permenantPostalCode: currentPostalCode,
        permenantAddress: currentAddress,
      });
    }
    else if(copyCurrentAddress == true) //Uncheck
    {
      if(currentCountryId)
      {
        this.addressDetails.patchValue({
          permenantCountryId : null
        });
      }

      if(currentStateId)
      {
        this.addressDetails.patchValue({
          permenantStateId : null
        });
      }

      if(currentDistrictId)
      {
        this.addressDetails.patchValue({
          permenantDistrictId : null
        });
      }

      if(currentCityId)
      {
        this.addressDetails.patchValue({
          permenantCityId : null
        });
      }

      this.addressDetails.patchValue({
        permenantPostalCode: "",
        permenantAddress: "",
      });
    }
  } */
  patchPermenantAddress() {
    let currentCountryId = this.addressDetails.value.countryId;
    let currentCountryName = this.addressDetails.value.countryName;
    let currentStateId = this.addressDetails.value.stateId;
    let currentStateName = this.addressDetails.value.stateName;
    let currentDistrictId = this.addressDetails.value.districtId;
    let currentDistrictName = this.addressDetails.value.districtName;
    let currentCityId = this.addressDetails.value.cityId;
    let currentCityName = this.addressDetails.value.cityName;
    let currentPostalCode = this.addressDetails.value.postalCode;
    let currentAddress = this.addressDetails.value.address;

    if (currentCountryId) {
      this.addressDetails.patchValue({
        permenantCountryId: currentCountryId,
        permenantCountryName: currentCountryName,
      });
    }

    if (currentStateId) {
      this.addressDetails.patchValue({
        permenantStateId: currentStateId,
        permenantStateName: currentStateName,
      });
    }

    if (currentDistrictId) {
      this.addressDetails.patchValue({
        permenantDistrictId: currentDistrictId,
        permenantDistrictName: currentDistrictName,
      });
    }

    if (currentCityId) {
      this.addressDetails.patchValue({
        permenantCityId: currentCityId,
        permenantCityName: currentCityName,
      });
    }

    this.addressDetails.patchValue({
      permenantPostalCode: currentPostalCode,
      permenantAddress: currentAddress,
    });
  }
  checkUncheckAll() {
    //Check & Uncheck Copy Current Address
    //     let copyCurrentAddress = this.addressDetails.value.copyCurrentAddress;
    //     let currentCountryId = this.addressDetails.value.countryId;
    //     let currentCountryName = this.addressDetails.value.countryName;
    //     let currentStateId = this.addressDetails.value.stateId;
    //     let currentStateName = this.addressDetails.value.stateName;
    //     let currentDistrictId = this.addressDetails.value.districtId;
    //     let currentDistrictName = this.addressDetails.value.districtName;
    //     let currentCityId = this.addressDetails.value.cityId;
    //     let currentCityName = this.addressDetails.value.cityName;
    //     let currentPostalCode = this.addressDetails.value.postalCode;
    //     let currentAddress = this.addressDetails.value.address;

    //     console.log('copyAddress', copyCurrentAddress);
    //     console.log('countryName and ID',
    //       this.addressDetails.controls['countryId'].value);

    // // if (this.addressDetails.controls['countryId'].value) {
    // //   copyCurrentAddress = true;
    // // }

    //     if (copyCurrentAddress == false  || copyCurrentAddress == null ) {
    //       //Check
    //       if (currentCountryId) {
    //         this.addressDetails.patchValue({
    //           permenantCountryId: currentCountryId,
    //           permenantCountryName: currentCountryName,
    //         });
    //       }

    //       if (currentStateId) {
    //         this.addressDetails.patchValue({
    //           permenantStateId: currentStateId,
    //           permenantStateName: currentStateName,
    //         });
    //       }

    //       if (currentDistrictId) {
    //         this.addressDetails.patchValue({
    //           permenantDistrictId: currentDistrictId,
    //           permenantDistrictName: currentDistrictName,
    //         });
    //       }

    //       if (currentCityId) {
    //         this.addressDetails.patchValue({
    //           permenantCityId: currentCityId,
    //           permenantCityName: currentCityName,
    //         });
    //       } else {
    //         this.addressDetails.get('permenantCityId').setValue(null);
    //       }

    //       this.addressDetails.patchValue({
    //         permenantPostalCode: currentPostalCode,
    //         permenantAddress: currentAddress,
    //       });
    //     } else if (
    //       copyCurrentAddress == true ||
    //       copyCurrentAddress == '' ||
    //       currentCountryId == ''
    //     ) {
    //       //Uncheck
    //       if (currentCountryId) {
    //         this.addressDetails.patchValue({
    //           permenantCountryId: null,
    //           permenantCountryName: null,
    //         });

    //         this.addressDetails.controls['permenantCountryId'].setValue('');
    //         this.addressDetails.controls['permenantCountryName'].setValue('');
    //       }

    //       if (currentStateId) {
    //         this.addressDetails.patchValue({
    //           permenantStateId: null,
    //           permenantStateName: null,
    //         });
    //       }

    //       if (currentDistrictId) {
    //         this.addressDetails.patchValue({
    //           permenantDistrictId: null,
    //           permenantDistrictName: null,
    //         });
    //       }

    //       if (currentCityId) {
    //         this.addressDetails.patchValue({
    //           permenantCityId: null,
    //           permenantCityName: null,
    //         });
    //       }

    //       this.addressDetails.patchValue({
    //         permenantPostalCode: '',
    //         permenantAddress: '',
    //       });
    //     }

    let copyCurrentAddress = this.addressDetails.value.copyCurrentAddress;

    if (!copyCurrentAddress) {
      // Copy current address details to permanent address
      this.addressDetails.patchValue({
        permenantCountryId: this.addressDetails.value.countryId,
        permenantCountryName: this.addressDetails.value.countryName,
        permenantStateId: this.addressDetails.value.stateId,
        permenantStateName: this.addressDetails.value.stateName,
        permenantDistrictId: this.addressDetails.value.districtId,
        permenantDistrictName: this.addressDetails.value.districtName,
        permenantCityId: this.addressDetails.value.cityId,
        permenantCityName: this.addressDetails.value.cityName,
        permenantPostalCode: this.addressDetails.value.postalCode,
        permenantAddress: this.addressDetails.value.address,
      });
    } else {
      // Clear permanent address details
      this.addressDetails.patchValue({
        permenantCountryId: null,
        permenantCountryName: null,
        permenantStateId: null,
        permenantStateName: null,
        permenantDistrictId: null,
        permenantDistrictName: null,
        permenantCityId: null,
        permenantCityName: null,
        permenantPostalCode: '',
        permenantAddress: '',
      });
    }
  }

  getFileDetails(event) {
    const files = event.target.files;
    const fileInput = event.target as HTMLInputElement;
    const filePath = fileInput.value;
    const allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload a image file .');
      fileInput.value = '';
      event.preventDefault();
    } else {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.filename = file.name;
        this.fileSize = file.size;
        this.fileType = file.type;

        let reader = new FileReader();

        reader.onload = () => {
          let x: any = reader.result;
          let y: any = x.split(',');
          this.fileByte = y[1];
          /* group.patchValue({
          fileSource : file,
          fileBytes : sourceByteArray
        }) */
        };

        reader.readAsDataURL(file);
      }
    }
  }

  countryChange(event) {
    let countryId = event.target.value;
    if (countryId) {
      this.getActiveState(countryId);
      this.settingsservice
        .getCountryAgainstStateList(countryId)
        .subscribe((resultData: any) => {
          if (resultData.statusCode) {
            this.stateList = [];
          } else {
            this.addressDetails.get('stateId').setValue(null);
            this.addressDetails.get('districtId').setValue(null);
            this.addressDetails.get('cityId').setValue(null);
            this.stateList = resultData;
          }
          this.districtList = [];
          this.cityList = [];
        });
    } else {
      this.stateList = [];
      this.districtList = [];
      this.cityList = [];
      this.addressDetails.get('stateId').setValue(null);
      this.addressDetails.get('districtId').setValue(null);
      this.addressDetails.get('cityId').setValue(null);
      //console.log(this.address1);
    }
  }

  stateChange(event) {
    let stateId = event.target.value;
    if (stateId) {
      this.getActiveDistrict(stateId);

      this.settingsservice
        .getStateAgainstDistictList(stateId)
        .subscribe((resultData: any) => {
          if (resultData.statusCode) {
            this.districtList = [];
          } else {
            this.districtList = resultData;
            this.addressDetails.get('districtId').setValue(null);
            this.addressDetails.get('cityId').setValue(null);
          }

          this.cityList = [];
        });
    } else {
      this.districtList = [];
      this.cityList = [];

      this.addressDetails.get('districtId').setValue(null);
      this.addressDetails.get('cityId').setValue(null);
    }
  }

  districtChange(event) {
    let districtId = event.target.value;
    if (districtId) {
      this.getActiveCity(districtId);
      this.settingsservice
        .getDistrictAgainstCityList(districtId)
        .subscribe((resultData: any) => {
          if (resultData.statusCode) {
            this.cityList = [];
          } else {
            this.cityList = resultData;
            this.addressDetails.get('cityId').setValue(null);
          }
        });
    } else {
      this.cityList = [];
      this.addressDetails.get('cityId').setValue(null);
    }
  }

  permenantCountryChange(event) {
    let countryId = event.target.value;
    if (countryId) {
      this.settingsservice
        .getCountryAgainstStateList(countryId)
        .subscribe((resultData: any) => {
          if (resultData.statusCode) {
            this.perstateList = [];
          } else {
            this.addressDetails.get('permenantDistrictId').setValue(null);
            this.addressDetails.get('permenantCityId').setValue(null);
            this.perstateList = resultData;
          }
          this.perdistrictList = [];
          this.percityList = [];
        });
    } else {
      this.perstateList = [];
      this.perdistrictList = [];
      this.percityList = [];

      this.addressDetails.get('permenantStateId').setValue(null);
      this.addressDetails.get('permenantDistrictId').setValue(null);
      this.addressDetails.get('permenantCityId').setValue(null);
    }
  }

  permenantStateChange(event) {
    let stateId = event.target.value;
    if (stateId) {
      this.settingsservice
        .getStateAgainstDistictList(stateId)
        .subscribe((resultData: any) => {
          if (resultData.statusCode) {
            this.perdistrictList.length = [];
          } else {
            this.perdistrictList = resultData;
            this.addressDetails.get('permenantCityId').setValue(null);
          }
        });
    } else {
      this.perdistrictList = [];
      this.percityList = [];

      this.addressDetails.get('permenantDistrictId').setValue(null);
      this.addressDetails.get('permenantCityId').setValue(null);

      /* this.addressDetails.get('districtId').setValue(null);
      this.addressDetails.get('cityId').setValue(null); */
    }
  }
  onPostalCodeInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');

    // Trigger validation manually (optional)
    const postalCodeControl = this.addressDetails.get('postalCode');
    if (postalCodeControl) {
      postalCodeControl.updateValueAndValidity(); // Correctly updating the control's validity
    }
  }

  onBankAcctNoInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');

    // Trigger validation manually (optional)
    const postalCodeControl = this.addressDetails.get('bankAccountNo');
    if (postalCodeControl) {
      postalCodeControl.updateValueAndValidity(); // Correctly updating the control's validity
    }
  }

  permenantDistrictChange(event) {
    let districtId = event.target.value;
    if (districtId) {
      this.settingsservice
        .getDistrictAgainstCityList(districtId)
        .subscribe((resultData: any) => {
          if (resultData.statusCode) {
            this.percityList.length = [];
          } else {
            this.percityList = resultData;
            this.addressDetails.get('cityId').setValue(null);
          }
        });
    } else {
      //this.perdistrictList = [];
      this.percityList = [];
      this.addressDetails.get('cityId').setValue(null);
    }
  }

  /** Auto Complete Search **/
  countryAutoCompleteSearch(event) {
    let keywords = event.target.value;

    if (keywords === '') {
      console.log('Empty keywords - clearing city name and id');
      this.addressDetails.patchValue({
        countryId: '',
        // districtId: '',
      });
      this.addressDetails.patchValue({
        permenantCountryName: '',
        permenantCountryId: '',
      });
      this.copyCountryName = '';
      this.copyCountryId = '';
    } else if (
      keywords &&
      this.copyCountryName &&
      keywords !== this.copyCountryName
    ) {
      // Clear the countryId and reset related fields
      this.addressDetails.patchValue({
        countryId: '',
      });

      this.copyCountryName = ''; // Clear the cached country name
      this.copyCountryId = ''; // Clear the cached country ID
    }

    if (keywords) {
      this.autoCompleteCountrySearch = 0;

      this.AdminService.countryAutoCompleteSearch(keywords).subscribe(
        (result: any) => {
          this.countryData = result;

          this.addressDetails.patchValue({
            countryId: '',
          });

          if (this.countryData.statusCode == 204) {
            this.autoCompleteCountrySearch = 0; //No data Found
            this.addressDetails.patchValue({
              countryId: '',
            });
          } else {
            this.autoCompleteCountrySearch = 0;
          }

          if (
            this.addressDetails.controls['copyCurrentAddress'].value == true
          ) {
            this.addressDetails.patchValue({
              permenantCountryName: this.copyCountryName,
              permenantCountryId: this.copyCountryId,
            });
          }
        }
      );
    } else {
      this.autoCompleteCountrySearch = 1;
      this.addressDetails.patchValue({
        countryId: '',

        stateName: '',
        stateId: '',

        districtName: '',
        districtId: '',

        cityName: '',
        cityId: '',
        postalCode: '',
      });
      this.addressDetails.patchValue({
        permenantDistrictName: '',
        permenantDistrictId: '',
        permenantCountryName: '',
        permenantCountryId: '',
        permenantStateName: '',
        permenantStateId: '',
        permenantCityName: '',
        permenantCityId: '',
      });
    }
  }

  stateAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let countryId = this.addressDetails.value.countryId;

    if (!countryId) {
      alert('Please! First select country.');
      this.addressDetails.patchValue({
        stateName: '',
        stateId: '',
      });

      return;
    }

    if (keywords === '') {
      console.log('Empty keywords - clearing city name and id');
      this.addressDetails.patchValue({
        stateName: '',
        stateId: '',
      });
      this.addressDetails.patchValue({
        permenantStateName: '',
        permenantStateId: '',
      });
      this.copyStateName = '';
      this.copyStateId = '';
    } else if (
      keywords &&
      this.copyStateName &&
      keywords !== this.copyStateName
    ) {
      // Clear the countryId and reset related fields
      this.addressDetails.patchValue({
        stateName: '',
        stateId: '',
      });

      this.copyStateName = '';
      this.copyStateId = '';
    }

    if (keywords) {
      this.autoCompleteStateSearch = 0;

      this.AdminService.stateAutoCompleteSearch(countryId, keywords).subscribe(
        (result: any) => {
          this.stateData = result;

          if (this.stateData.statusCode == 204) {
            this.autoCompleteStateSearch = 1; //No data Found
            this.addressDetails.patchValue({
              //employeeName : firstName+" "+lastName,
              stateId: '',
            });
          } else {
            this.autoCompleteStateSearch = 0;
          }

          if (
            this.addressDetails.controls['copyCurrentAddress'].value == true
          ) {
            this.addressDetails.patchValue({
              permenantStateName: this.copyStateName,
              permenantStateId: this.copyStateId,
            });
          }
        }
      );
    } else {
      this.autoCompleteStateSearch = 1;
      this.addressDetails.patchValue({
        stateId: '',

        districtName: '',
        districtId: '',

        cityName: '',
        cityId: '',
      });
    }
  }

  districtAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let stateId = this.addressDetails.value.stateId;

    if (!stateId) {
      alert('Please! First select state.');
      this.addressDetails.patchValue({
        districtName: '',
        districtId: '',
        cityName: '',
        cityId: '',
      });
      return;
    }

    if (keywords === '') {
      console.log('Empty keywords - clearing city name and id');
      this.addressDetails.patchValue({
        districtName: '',
        districtId: '',
      });
      this.addressDetails.patchValue({
        permenantDistrictName: '',
        permenantDistrictId: '',
      });
      this.copyDistrictName = '';
      this.copyDistrictId = '';
    } else if (
      keywords &&
      this.copyDistrictName &&
      keywords !== this.copyDistrictName
    ) {
      this.addressDetails.patchValue({
        districtName: '',
        districtId: '',
      });

      this.copyDistrictName = '';
      this.copyDistrictId = '';
    }

    if (keywords) {
      this.autoCompleteDistrictSearch = 0;

      this.AdminService.districtAutoCompleteSearch(stateId, keywords).subscribe(
        (result: any) => {
          this.districtData = result;

          if (this.districtData.statusCode == 204) {
            this.autoCompleteDistrictSearch = 1; //No data Found
            this.addressDetails.patchValue({
              districtId: '',
            });
          } else {
            this.autoCompleteDistrictSearch = 0;
          }

          if (
            this.addressDetails.controls['copyCurrentAddress'].value == true
          ) {
            this.addressDetails.patchValue({
              permenantDistrictName: this.copyDistrictName,
              permenantDistrictId: this.copyDistrictId,
            });
          }
        }
      );
    } else {
      this.autoCompleteDistrictSearch = 1;
      this.addressDetails.patchValue({
        districtId: '',

        // cityName: '',
        // cityId: '',
      });
    }
  }

  cityAutoCompleteSearch(event) {
    let keywords = event.target.value;

    let cityId = this.addressDetails.value.cityId;

    let districtId = this.addressDetails.value.districtId;
    console.log(
      'this.addressDetails.value.districtId',
      this.addressDetails.value.districtId
    );

    console.log('keywords', event.target.value);

    console.log(this.copCityName + '' + this.copyCityId);
    console.log('keywords::', keywords);

    if (!districtId) {
      alert('Please! First select district.');
      this.addressDetails.get('cityName')?.setValue('');
      this.addressDetails.get('cityId')?.setValue('');
      this.cityData?.patchValue({
        cityName: '',
        cityId: '',
      });
      return;
    }
    if (keywords === '') {
      console.log('Empty keywords - clearing city name and id');
      this.addressDetails.patchValue({
        cityName: '',
        cityId: '',
      });
      this.addressDetails.patchValue({
        permenantCityName: '',
        permenantCityId: '',
      });
      this.copCityName = '';
      this.copyCityId = '';
    } else if (keywords && this.copCityName && keywords !== this.copCityName) {
      console.log('if condition here!!!');

      this.addressDetails.patchValue({
        cityName: '',
        cityId: '',
      });
      this.addressDetails.patchValue({
        permenantCityName: '',
        permenantCityId: '',
      });

      this.copCityName = '';
      this.copyCityId = '';
    }

    // this.copyCityId = '';
    console.log(this.copCityName + '' + this.copyCityId);
    console.log('keywords::', keywords);

    if (keywords) {
      this.autoCompleteCitySearch = 0;

      this.AdminService.cityAutoCompleteSearch(districtId, keywords).subscribe(
        (result: any) => {
          this.cityData = result;

          if (this.cityData.statusCode == 204) {
            this.autoCompleteCitySearch = 1; //No data Found
            this.addressDetails.patchValue({
              cityId: '',
              // cityName: ''
            });
          } else {
            this.autoCompleteCitySearch = 0;
          }
          if (
            this.addressDetails.controls['copyCurrentAddress'].value == true
          ) {
            this.addressDetails.patchValue({
              permenantCityName: this.copCityName,
              permenantCityId: this.copyCityId,
            });
          }
        }
      );
    } else {
      this.autoCompleteCitySearch = 1;
      this.addressDetails.patchValue({
        cityName: '',
        cityId: '',
      });
    }
  }

  getFilterData(id, filterName, type) {
    if (type == 1) {
      //Country
      this.autoCompleteCountrySearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          countryName: filterName,
          countryId: id,
        });

        if (this.addressDetails.controls['copyCurrentAddress'].value == true) {
          // this.addressDetails.patchValue({
          //   permenantCountryName: filterName,
          //   permenantCountryId: id,
          // });
        }

        this.copyCountryId = filterName;
        (this.copyCountryName = id),
          this.addressDetails.patchValue({
            stateName: '',
            stateId: '',

            districtName: '',
            districtId: '',

            cityName: '',
            cityId: '',
          });

        if (this.addressDetails.controls['copyCurrentAddress'].value == true) {
          // this.addressDetails.patchValue({
          //   permenantCountryName: filterName,
          //   permenantCountryId: id,
          // });
        }

        // if (
        //   this.addressDetails.controls['copyCurrentAddress'].value == false
        // ) {
        // this.addressDetails.patchValue({
        //   permenantCountryName: this.copyCountryName,
        //   // permenantCountryId: this.copyCountryId,
        // });
        // }
      }
    } else if (type == 2) {
      //State
      this.autoCompleteStateSearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          stateName: filterName,
          stateId: id,
        });
        if (this.addressDetails.controls['copyCurrentAddress'].value == true) {
          this.addressDetails.patchValue({
            permenantStateName: filterName,
            permenantStateId: id,
          });
        }

        this.copyStateId = filterName;
        this.copyStateName = id;

        this.addressDetails.patchValue({
          districtName: '',
          districtId: '',

          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 3) {
      //district
      this.autoCompleteDistrictSearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          districtName: filterName,
          districtId: id,
        });
        if (this.addressDetails.controls['copyCurrentAddress'].value == true) {
          this.addressDetails.patchValue({
            permenantDistrictName: filterName,
            permenantDistrictId: id,
          });
        }

        this.copyDistrictId = filterName;
        this.copyDistrictName = id;
        this.addressDetails.patchValue({
          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 4) {
      //city
      this.autoCompleteCitySearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          cityName: filterName,
          cityId: id,
        });
        if (this.addressDetails.controls['copyCurrentAddress'].value == true) {
          this.addressDetails.patchValue({
            permenantCityName: filterName,
            permenantCityId: id,
          });
        }
        this.copCityName = filterName;
        this.copyCityId = id;
      }
    }
  }

  //Permenant SearchOption

  /** Auto Complete Search **/
  permenantcountryAutoCompleteSearch(event) {
    let keywords = event.target.value;

    if (keywords) {
      this.permenantautoCompleteCountrySearch = 0;

      this.AdminService.countryAutoCompleteSearch(keywords).subscribe(
        (result: any) => {
          this.permenantcountryData = result;

          this.addressDetails.patchValue({
            permenantCountryId: '',
          });

          if (this.permenantcountryData.statusCode == 204) {
            this.permenantautoCompleteCountrySearch = 1; //No data Found
            this.addressDetails.patchValue({
              permenantCountryId: '',
            });
          } else {
            this.permenantautoCompleteCountrySearch = 0;
          }
        }
      );
    } else {
      this.permenantautoCompleteCountrySearch = 1;
      this.addressDetails.patchValue({
        permenantCountryId: '',

        permenantStateName: '',
        permenantStateId: '',

        permenantDistrictName: '',
        permenantDistrictId: '',

        permenantCityName: '',
        permenantCityId: '',
      });
    }
  }

  permenantstateAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let permenantCountryId = this.addressDetails.value.permenantCountryId;

    if (!permenantCountryId) {
      alert('Please! First select country.');
      this.addressDetails.patchValue({
        permenantStateName: '',
        permenantStateId: '',
      });
      return;
    }

    if (keywords) {
      this.permenantautoCompleteStateSearch = 0;

      this.AdminService.stateAutoCompleteSearch(
        permenantCountryId,
        keywords
      ).subscribe((result: any) => {
        this.permenantstateData = result;

        if (this.permenantstateData.statusCode == 204) {
          this.permenantautoCompleteStateSearch = 1; //No data Found
          this.addressDetails.patchValue({
            //employeeName : firstName+" "+lastName,
            permenantStateId: '',
          });
        } else {
          this.permenantautoCompleteStateSearch = 0;
        }
      });
    } else {
      this.permenantautoCompleteStateSearch = 1;
      this.addressDetails.patchValue({
        permenantStateId: '',

        permenantDistrictName: '',
        permenantDistrictId: '',

        permenantCityName: '',
        permenantCityId: '',
      });
    }
  }

  permenantdistrictAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let permenantStateId = this.addressDetails.value.permenantStateId;

    if (!permenantStateId) {
      alert('Please! First select state.');
      this.addressDetails.patchValue({
        permenantDistrictName: '',
        permenantDistrictId: '',
      });
      return;
    }

    if (keywords) {
      this.permenantautoCompleteDistrictSearch = 0;

      this.AdminService.districtAutoCompleteSearch(
        permenantStateId,
        keywords
      ).subscribe((result: any) => {
        this.permenantdistrictData = result;

        if (this.permenantdistrictData.statusCode == 204) {
          this.permenantautoCompleteDistrictSearch = 1; //No data Found
          this.addressDetails.patchValue({
            permenantDistrictId: '',
          });
        } else {
          this.permenantautoCompleteDistrictSearch = 0;
        }
      });
    } else {
      this.permenantautoCompleteDistrictSearch = 1;
      this.addressDetails.patchValue({
        permenantDistrictId: '',
        permenantCityName: '',
        permenantCityId: '',
      });
    }
  }

  permenantcityAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let permenantDistrictId = this.addressDetails.value.permenantDistrictId;

    if (!permenantDistrictId) {
      alert('Please! First select district.');
      this.permenantcityData.patchValue({
        permenantCityName: '',
        permenantCityId: '',
      });
      return;
    }

    if (keywords) {
      this.permenantautoCompleteCitySearch = 0;

      this.AdminService.cityAutoCompleteSearch(
        permenantDistrictId,
        keywords
      ).subscribe((result: any) => {
        this.permenantcityData = result;

        if (this.permenantcityData.statusCode == 204) {
          this.permenantautoCompleteCitySearch = 1; //No data Found
          this.addressDetails.patchValue({
            permenantCityId: '',
          });
        } else {
          this.permenantautoCompleteCitySearch = 0;
        }
      });
    } else {
      this.permenantautoCompleteCitySearch = 1;
      this.addressDetails.patchValue({
        permenantCityId: '',
      });
    }
  }

  permenantgetFilterData(id, filterName, type) {
    //alert(id+"=="+filterName+"=="+type);

    if (type == 1) {
      //Country
      this.permenantautoCompleteCountrySearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          permenantCountryName: filterName,
          permenantCountryId: id,
        });

        /* this.addressDetails.patchValue({
          permenantStateName : "",
          permenantStateId : "",

          permenantDistrictName : "",
          permenantDistrictId : "",

          permenantCityName : "",
          permenantCityId : ""
        }); */
      }
    } else if (type == 2) {
      //State
      this.permenantautoCompleteStateSearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          permenantStateName: filterName,
          permenantStateId: id,
        });

        this.addressDetails.patchValue({
          permenantDistrictName: '',
          permenantDistrictId: '',

          permenantCityName: '',
          permenantCityId: '',
        });
      }
    } else if (type == 3) {
      //district
      this.permenantautoCompleteDistrictSearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          permenantDistrictName: filterName,
          permenantDistrictId: id,
        });

        this.addressDetails.patchValue({
          permenantCityName: '',
          permenantCityId: '',
        });
      }
    } else if (type == 4) {
      //city
      this.permenantautoCompleteCitySearch = 1;

      if (id) {
        this.addressDetails.patchValue({
          permenantCityName: filterName,
          permenantCityId: id,
        });
      }
    }
  }

  postalCode() {
    this.addressDetails.controls['postalCode'].value;

    if (this.addressDetails.controls['copyCurrentAddress'].value == true) {
      this.addressDetails.patchValue({
        permenantPostalCode: this.addressDetails.controls['postalCode'].value,
      });
    }
  }

  copyAddress() {
    this.addressDetails.controls['address'].value;

    if (this.addressDetails.controls['copyCurrentAddress'].value == true) {
      this.addressDetails.patchValue({
        permenantAddress: this.addressDetails.controls['address'].value,
      });
    }
  }

  empDob(event: any) {
    this.dob = event.target.value;
    console.log('DOB', this.dob);
    if (this.dob.length != 0) {
      console.log('Length', this.dob.length);
      this.password();
    }
  }
  empFirstName(event: any) {
    // console.log(event.target.value.charAt(0).toUpperCase())
    this.lastName = event.target.value;
    console.log('Firstname', this.lastName);
    console.log('Firstname', this.lastName.length);
    if (this.lastName.length != 0) {
      console.log('Length', this.lastName.length);
      this.password();
    } else {
      console.log('Patch', this.lastName.length);
      this.basicInformationDetails.patchValue({
        password: null,
      });
    }
  }

  capitalizeFirstLetter(value: string) {
    if (value) {
      this.inputValue = value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      this.inputValue = '';
    }
  }

  // password() {
  //   // console.log("Length",this.dob.length);
  //   console.log('Length', this.dob);
  //   if (this.dob == undefined || this.dob == null) {
  //     this.formattedDate = this.datepipe.transform(this.dob, 'ddMMyy');
  //     console.log('Formatted Date:', this.formattedDate);
  //     this.finalPassword = this.lastName;
  //     console.log('pass' + this.finalPassword);
  //     // this.finalPassword =
  //     //   this.finalPassword.charAt(0).toUpperCase() +
  //     //   this.finalPassword.slice(1);

  //     this.finalPassword =
  //       this.finalPassword.charAt(0).toUpperCase() + // Capitalize the first letter
  //       this.finalPassword.charAt(1).toLowerCase() + // Lowercase the second letter
  //       this.finalPassword.slice(2);
  //     console.log('this.finalPassword', this.finalPassword);
  //   } else {
  //     this.formattedDate = this.datepipe.transform(this.dob, 'ddMMyy');
  //     console.log('First Name:', this.formattedDate);
  //     this.finalPassword = this.lastName + '@' + this.formattedDate;
  //     console.log('pass' + this.finalPassword);
  //     this.finalPassword =
  //       this.finalPassword.charAt(0).toUpperCase() +
  //       this.finalPassword.slice(1);
  //     console.log(this.finalPassword);
  //   }
  //   this.basicInformationDetails.patchValue({
  //     password: this.finalPassword,
  //   });
  //   console.log(this.basicInformationDetails.value.password);
  // }


  password() {
    console.log('DOB:', this.dob);
    let existingYear = '';
  
    // Check if finalPassword contains a year and extract it
    const yearPattern = /@\d{4}$/; // Regex to match a year at the end
    if (yearPattern.test(this.finalPassword)) {
      existingYear = yearPattern.exec(this.finalPassword)?.[0] || ''; // Extract the year part
    }
  
    if (!this.dob) {
      // DOB is undefined or null
      this.formattedDate = this.datepipe.transform(this.dob, 'ddMMyy') || '';
      console.log('Formatted Date:', this.formattedDate);
  
      // Update only the name part of the password, keeping the year intact
      const updatedName =
        this.lastName.charAt(0).toUpperCase() + // Capitalize the first letter
        this.lastName.charAt(1).toLowerCase() + // Lowercase the second letter
        this.lastName.slice(2);
  
      this.finalPassword = `${updatedName}${existingYear}`;
      console.log('Updated finalPassword:', this.finalPassword);
    } else {
      // DOB is defined
      this.formattedDate = this.datepipe.transform(this.dob, 'ddMMyy') || '';
      console.log('Formatted Date:', this.formattedDate);
  
      const updatedName = this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1);
      this.finalPassword = `${updatedName}@${this.formattedDate}`;
      console.log('Updated finalPassword:', this.finalPassword);
    }
  
    // Patch the updated password back to the form
    this.basicInformationDetails.patchValue({
      password: this.finalPassword,
    });
  
    console.log('Patched Password:', this.basicInformationDetails.value.password);
  }
  



  noNumber(event: any) {
    console.log('noNumber');
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  handleKeyPress(event) {
    if (event.key === ' ' && event.target.selectionStart === 0) {
      event.preventDefault();
    }
  }

  filterAndValidateInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart;

    // Allow alphanumeric, hyphens, underscores, and spaces, but remove leading spaces
    const filteredValue = inputElement.value
      .replace(/^\s+/g, '')
      .replace(/[^a-zA-Z0-9-_ ]/g, '')
      .replace(/\s{2,}/g, ' ');

    if (filteredValue !== inputElement.value) {
      inputElement.value = filteredValue;
      this.basicInformationDetails.controls['organizationEmail'].setValue(
        filteredValue
      );

      // Adjust cursor position to account for removed characters
      if (cursorPosition !== null) {
        const newCursorPosition = Math.min(
          cursorPosition,
          filteredValue.length
        );
        setTimeout(() => {
          inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
      }
    }

    this.validateInput(event); // Call your validation function here
  }

  validaterInput(event: any) {
    const inputElement = event.target;
    let value = inputElement.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      inputElement.value = value;
    }

    this.invalidInput = value.trim().length === 0; // Check if the input consists of only spaces
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  getBusinessGroupData() {
    this.payrollService.getBusinessGroup(this.entityId).subscribe((data) => {
      console.log('Business Unit Data:', data);
      // this.BusinessUnit=data;
      this.BusinessUnit = Array.isArray(data) ? data : [data];
      console.log(this.BusinessUnit);
      console.log(this.BusinessUnit.businessGroupId);
    });
  }
}
