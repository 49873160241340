import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-viewclient',
  templateUrl: './viewclient.component.html',
  styleUrls: ['./viewclient.component.scss']
})
export class ViewclientComponent implements OnInit {
  mySelect: any=5;
  p:number=1;
  constructor(
    public dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    console.log("data:",this.data);
    
  }

}
