import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-variable-pay-add',
  templateUrl: './variable-pay-add.component.html',
  styleUrls: ['./variable-pay-add.component.scss']
})
export class VariablePayAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
