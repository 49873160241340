import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { LeaveService } from 'src/app/providers/leave.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-apply-permission',
  templateUrl: './apply-permission.component.html',
  styleUrls: ['./apply-permission.component.scss']
})
export class ApplyPermissionComponent implements OnInit {

  isLoading: boolean;
  permissionForm: FormGroup;
  empId: any;
  firstApprover = new FormControl()
  secondApprover = new FormControl()
  employee_Data
  myFilter: any;
  secondError: boolean;
  arrayData: any = []
  workingDay: any = []
  totalDay: any = []
  branchId: any
  workDay: any = []
  From_Date: any;
  End_Date: any;
  currentyear: number;
  submitted = false;
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  searchButton:boolean=true;


  constructor(private fb: FormBuilder,
    private gs: LeaveService,
    private router: Router,
    private datepipe: DatePipe,
    private location: Location,) { }

  ngOnInit(): void {
    // this.isLoading = false
    let localData = JSON.parse(localStorage.getItem("enoteUserData"));
    this.empId = localData.employee.employeeId;
  

    this.permissionForm = this.fb.group({
      employeeName: [''],
      organizationEmail: [''],
      permissionDate: ['', Validators.required],
      permissionHours: [null, Validators.required],
      permissionReason: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]]
    })

    this.permissionForm.patchValue({
      // employeeName: localData.employee.firstName + ' ' + localData.employee.lastName,
      employeeName:localData.employee.firstName + (localData.employee.lastName ? ' ' + localData.employee.lastName : ''),

      organizationEmail: localData.emailId
    });

    // get date
    const sample_date_data: any[] = []
    this.gs.get_leave_date(this.empId).subscribe((a: any) => {
      a.forEach(element => {
        let x = element.split("-");
        sample_date_data.push(new Date(`'${x[0]}/${x[1]}/${x[2]}'`).getTime())
      });
      let x = sample_date_data;
    })

    this.gs.getEmpListById(this.empId).subscribe(
      (x: any) => {
        this.branchId = x.branch.branchId;

    if (this.branchId) {
      const currentDate = new Date();
      this.currentyear = currentDate.getFullYear();
      // this.gs.getCalByBranch(this.branchId).subscribe(
      this.gs.getCalByBranchAndyearname(this.branchId, this.currentyear).subscribe(
        (dato: any) => {
          dato.map(
            log => {
              this.gs.getCalendarById(log.calendarId).subscribe(
                (i: any) => {
                  var exception
                  let data
                  i.exceptions.map(
                    b => {
                      if (b.exceptionStatus == 1) {
                        if (b.exceptionType != '31') {
                          exception = b.exceptionDate
                          data = new Date(b.exceptionDate)
                          data.setDate(data.getDate());
                          this.arrayData.push(data)
                        }
                        if (b.exceptionType == '31') {
                          exception = b.exceptionDate
                          data = new Date(b.exceptionDate)
                          data.setDate(data.getDate());
                          this.workingDay.push(data)
                        }
                      }

                    }
                  )

                  i.holiday.map(a => {
                    if (a.holidayStatus == 1) {
                      data = new Date(a.holidayDate)
                      data.setDate(data.getDate());
                      this.arrayData.push(data);
                    }
                  })

                  let b = ["1", "2", "3", "4", "5", "6", "0"]
                  i.calendarWorkingDays.map(dot => {
                    const workingDays = b.filter((dayCode) => {
                      const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
                      return dayNumber < dot.workingdays;
                    })
                      .map((dayCode) => dayCode);
                    dot['workingDays'] = workingDays;
                    console.log("dot.workingdays", dot.workingDays);

                    var difference: any = b.filter(x => dot.workingDays.indexOf(x) === -1);
                    this.workDay = difference
                    this.myFilter = (d: Date): boolean => {
                      const day = new Date(d).getDay();
                      const time = new Date(d).getTime();
                      if (day !== Number(difference[0]) && day !== Number(difference[1]) &&
                        day !== Number(difference[2]) && day !== Number(difference[3]) &&
                        day !== Number(difference[4]) && day !== Number(difference[5]) &&
                        day !== Number(difference[6])) {

                        this.totalDay.push(new Date(d))
                        this.workingDay.map(
                          e => {
                            this.totalDay.push(new Date(e))
                          }
                        )
                      }

                      return this.totalDay.find(x => new Date(x).getTime() == time) &&
                        !this.arrayData.find(x => new Date(x).getTime() == time)
                      // return day !== Number(difference[0]) && day !== Number(difference[1])
                      // && day !== Number(difference[2]) && day !== Number(difference[3])
                      // && day !== Number(difference[4]) && day !== Number(difference[5])
                      // && day !== Number(difference[6])&& !this.arrayData.find(x => new Date(x).getTime() == time)
                    }
                  })
                }
              )
            }
          )

        }
      )
    }
      }
    )

  }

  Cancel() {
    this.location.back();
  }

  get f() {
    return this.permissionForm.controls;
  }

  employeeName() {
    return this.f.employeeName.hasError('required') ? 'Please Enter EmployeeName' : ''

  }

  organizationEmail() {
    return this.f.organizationEmail.hasError('required') ? 'Please Enter Organization Email' : ''
  }

  date() {
    return this.f.permissionDate.hasError('required') ? 'Please Select Date' : ''
  }

  hour() {
    return this.f.permissionHours.hasError('required') ? 'Please Select Hour' : ''
  }

  reason() {
    return this.f.permissionReason.hasError('required') ? 'Please Enter Reason' : ''
  }

  cancel() {
    this.router.navigate(['./permission-list'])
  }

  onSubmit() {
    this.searchButton=true;

    this.submitted = true;
    if (this.permissionForm.invalid || this.invalidInput) {
      this.searchButton=true;

      return
    }


    let x = this.permissionForm.value;
    x['permissionStatus'] = 3;
    x['status'] = 1;

    x['employeeId'] = this.empId
    if (x.permissionHours == "15") {
      x['permissionHours'] = `${'00:15:00'}`
    }
    if (x.permissionHours == "30") {
      x['permissionHours'] = `${'00:30:00'}`
    }
    if (x.permissionHours == "1") {
      x['permissionHours'] = `${'01:00:00'}`
    }
    if (x.permissionHours == "2") {
      x['permissionHours'] = `${'02:00:00'}`
    }
    if (x.permissionHours == "3") {
      x['permissionHours'] = `${'03:00:00'}`
    }
    console.log(x.permissionHours);
    x['submittedOn'] = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    let c = new Date(this.f.permissionDate.value)
    let d = new Date(c);
    d.setMinutes(d.getMinutes() + 480);
    let e = d.toISOString();
    x['permissionDate'] = this.datepipe.transform(e, 'yyyy-MM-dd');
    // console.log(JSON.stringify(x))
    // return
    // console.log(this.permissionForm.permissionHours);
    Swal.fire({
      title: 'Are you sure? Do you want to Apply Permission!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Apply'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.searchButton=false;

        this.gs.applyPermission(x).subscribe(
          (result: any) => {
            console.log((result as any).message);
            this.success = true;
            this.sucess_msg = (result as any).message;
            // this.sucess_msg = result.message;
            this.isLoading = false;
            this.searchButton=false;

            // this.searchSubmit();
            setTimeout(() => {
              this.success = false;
            }, 3000)
            setTimeout(() => {
              this.router.navigate(['/home/mypermission-list']);
              this.isLoading = false;
            }, 3000)
          },
           err => {
            this.error = true;
                  this.error_msg = err.error.message;
                  this.isLoading = false
                  this.searchButton=true;


            setTimeout(() => {
                // Swal.fire({
                //     text: err.error.message,
                //     icon: 'error'
                // });
                this.error = false;
            }, 3000); 
          },
          
          () => {
            this.isLoading = false; 
            this.searchButton=false;

          } 

        )
      }
    })

  }
  invalidInput: boolean = false;

  validateInput(event: any) {
    const inputElement = event.target;
    let value = inputElement.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      inputElement.value = value;
    }

    this.invalidInput = value.trim().length === 0; // Check if the input consists of only spaces
  }



}

