import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { fe_url } from 'src/app/providers/properties';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-share-resource-feedback-link',
  templateUrl: './share-resource-feedback-link.component.html',
  styleUrls: ['./share-resource-feedback-link.component.scss']
})
export class ShareResourceFeedbackLinkComponent implements OnInit {


  resourceId:any;
  feedbackId:any;
  link:any;
  

  constructor(private clipboard: Clipboard,
    private snackBar:MatSnackBar,

     private http: HttpClient) { }

  ngOnInit(): void {
    
    // this.link = this.http.get(`${api_url}home/resourcefeedback/${this.resourceId}`)

    // if(this.feedbackId == undefined){
    //   this.link = `${fe_url}share-resource-feedback/${this.resourceId}`;
    //   console.log(this.link)
    // }else{

    console.log("this.resourceId::",this.resourceId);
    
      this.link = `${environment.fe_url}share-feedback-form/${this.resourceId}/${this.feedbackId}`;
      console.log(this.link)
    // }
  

  
  }
  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    
    const config: MatSnackBarConfig = {
      duration:1000, // Duration in milliseconds
      verticalPosition: 'top', // Position the snack bar at the top
      horizontalPosition: 'center', // Optionally, position it in the center horizontally
      panelClass: ['center-snackbar'] // Add a custom class
    };
  
    this.snackBar.open('Link copied!', 'Close', config);
  }


}
