<div class="container">
    <div class="top-content">        
        <h1>Approvers</h1>
        <span class="material-icons c" style="color:#181c32b0 !important;"  mat-dialog-close>cancel</span>
    </div>
    <div>
        <div>
            <div class="row">
                <div class="col-md-8">
                    <h4>{{ data.approval.approver.approverOneObject.firstName}} {{ data.approval.approver.approverOneObject.lastName}}</h4>
                    <h6 class="email-id-new">{{data.approval.approver.approverOneObject.organizationEmail}}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approval.approverOneStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approval.approverOneStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approval.approverOneStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approval.approverOneStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approval.approverOneStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approval.approverOneStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div *ngIf="data.approval.approver.approverTwo">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approval.approver.approverTwoObject.firstName}} {{ data.approval.approver.approverTwoObject.lastName}}</h4>
                    <h6 class="email-id-new">{{data.approval.approver.approverTwoObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approval.approverTwoStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approval.approverTwoStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approval.approverTwoStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approval.approverTwoStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approval.approverTwoStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approval.approverTwoStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div *ngIf="data.approval.approver.approverThree">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approval.approver.approverThreeObject.firstName}} {{ data.approval.approver.approverThreeObject.lastName}}</h4>
                    <h6 class="email-id-new">{{data.approval.approver.approverThreeObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approval.approverThreeStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approval.approverThreeStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approval.approverThreeStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approval.approverThreeStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approval.approverThreeStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approval.approverThreeStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div  *ngIf="data.approval.approver.approverFour">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approval.approver.approverFourObject.firstName}} {{ data.approval.approver.approverFourObject.lastName}}</h4>
                    <h6 class="email-id-new">{{data.approval.approver.approverFourObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approval.approverFourStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approval.approverFourStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approval.approverFourStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approval.approverFourStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approval.approverFourStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approval.approverFourStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div  *ngIf="data.approval.approver.approverFive">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{data.approval.approver.approverFiveObject.firstName}} {{ data.approval.approver.approverFiveObject.lastName}}</h4>
                    <h6 class="email-id-new">{{data.approval.approver.approverFiveObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approval.approverFiveStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approval.approverFiveStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approval.approverFiveStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approval.approverFiveStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approval.approverFiveStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approval.approverFiveStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
        <div class="top-content">
            <h1>FYI</h1>
        </div>
        <div>
            <div class="row" >
                <div class="col-md-6">
                    <h4>{{data.approval.approver.fyiObject.firstName}} {{data.approval.approver.fyiObject.lastName}}</h4>
                    <h6 class="email-id-new">{{data.approval.approver.fyiObject.organizationEmail }}</h6>
                </div>
           </div>
        </div> 
    </div>
</div>