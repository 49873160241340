import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';

@Component({
  selector: 'app-add-qualification',
  templateUrl: './add-qualification.component.html',
  styleUrls: ['./add-qualification.component.scss']
})
export class AddQualificationComponent implements OnInit {

  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg: any;
  resultData: any;

  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;

  employeetData: any;
  qualificationNameExistStatus: any;

  qualificationId: any;
  btnDisabled: boolean = false;
  clientId: number;
  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,
    private dialogref: MatDialogRef<AddQualificationComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void {
    //Post Form
    this.postForm = this.formBuilder.group({
      streamName: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],

      // streamName : ['',[Validators.required,[Validators.required,Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]]],
    });

    if (this.edit_data) {
      this.qualificationId = this.edit_data.streamId;

      this.settingsservice.getQuaificationById(this.edit_data.streamId).subscribe((resultData: any) => {
        this.resultData = resultData;

        this.postForm.patchValue({
          streamName: this.resultData.streamName,
        });
      });
    }
  }

  // convenience getter for easy access to form fields
  get validate() { return this.postForm.controls; }

  //Form Submit Create / Edit
  onSubmit() //form Submit Function
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.clientId = this.employeetData?.employee?.client?.clientId;
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId' + organizationId)
    this.submitted = true;

    //stop here if form is invalid
    if (this.postForm.invalid || this.qualificationNameExistStatus == true) { return; }

    if (this.edit_data) //Edit
    {
      if (organizationId) {
        let postValues = this.postForm.value;
        let id = this.edit_data.streamId;

        postValues['organization'] = { organizationId: organizationId }
        postValues['streamId'] = id;
        postValues['streamStatus'] = this.edit_data.streamStatus;

        this.backdrop = true;
        this.loader = true;
        this.btnDisabled = true

        this.settingsservice.editQualification(id, postValues).subscribe((user: any) => {
          //this.router.navigate([ '/home/department' ]);
          //window.location.reload();

          this.backdrop = false;
          this.loader = false;
          this.btnDisabled = true

          // this.success = true;

          this.dialogref.close(user);
        }, err => {
          this.backdrop = false;
          this.loader = false;
          this.dialogref.close(err.error);
        })
      }

      if (this.clientId) {
        let postValues = this.postForm.value;
        let id = this.edit_data.streamId;

        postValues['client'] = {clientId:this.clientId};
        postValues['streamId'] = id;
        postValues['streamStatus'] = this.edit_data.streamStatus;
        this.backdrop = true;
        this.loader = true;
        this.btnDisabled = true;

        console.log('postValues',postValues);





          

        this.settingsservice.editQualification(id, postValues).subscribe((user: any) => {
          //this.router.navigate([ '/home/department' ]);
          //window.location.reload();

          this.backdrop = false;
          this.loader = false;
          this.btnDisabled = true

          // this.success = true;

          this.dialogref.close(user);
        }, err => {
          this.backdrop = false;
          this.loader = false;
          this.dialogref.close(err.error);
        })
      }
    }
    else //Create
    {

      if (organizationId) {
        let postValues = this.postForm.value;
        postValues['organization'] = { organizationId: organizationId }

        /*  if(this.postForm.valid &&  this.qualificationNameExistStatus == true)
         {
           console.log("if condition")
           return
         } */

        this.btnDisabled = true;


        this.settingsservice.createQualification(postValues).subscribe((user: any) => {
          this.backdrop = false;
          this.loader = false;
          // this.success = true;
          this.btnDisabled = true

          this.dialogref.close(user);
        }, err => {
          this.backdrop = false;
          this.loader = false;
          this.dialogref.close(err.error);
        })
      }

      if (this.clientId) {
        let postValues = this.postForm.value;
        postValues['client'] = { clientId: this.clientId };
        postValues['organization'] = null;

        console.log('postValues', postValues);

        this.settingsservice.createQualification(postValues).subscribe((user: any) => {
          this.backdrop = false;
          this.loader = false;
          // this.success = true;
          this.btnDisabled = true

          this.dialogref.close(user);
        }, err => {
          this.backdrop = false;
          this.loader = false;
          this.dialogref.close(err.error);
        })
      }

    }
  }

  checkExistQualificationName(val) {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId' + organizationId)
    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */

    if (this.postForm.value.streamName.length != 0) {
      let postValues = this.postForm.value;
      postValues['organization'] = { organizationId: organizationId }

      if (this.edit_data) {
        postValues['streamId'] = this.edit_data.streamId;

        this.settingsservice.updateCheckExistQualificationName(postValues).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.qualificationNameExistStatus = false;
          }
          else if (result.statusCode > 400) {
            this.qualificationNameExistStatus = true;
          }
        })
      }
      else {
        this.settingsservice.createCheckExistQualificationName(postValues).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.qualificationNameExistStatus = false;
          }
          else if (result.statusCode > 400) {
            this.qualificationNameExistStatus = true;
          }
        })
      }
    }
    else {
      this.qualificationNameExistStatus = false;
    }
  }
  projectCode() {
    return this.validate.streamName.hasError('required')
      ? 'Please Enter Stream Name'
      : '';

  }

}
