import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-addclientlist',
  templateUrl: './addclientlist.component.html',
  styleUrls: ['./addclientlist.component.scss']
})
export class AddclientlistComponent implements OnInit {

  customerForm:FormGroup;
  customerNameobject: any = {};
  customerNameexist: boolean;
  userdata :any;
  submitted=false;

  constructor(
    public dialogRef: MatDialogRef<AddclientlistComponent>,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs:ProjectManagementService ) { }

  ngOnInit() {
   this.userdata =  JSON.parse(localStorage.getItem('UserData'));

    this.customerForm = this.fb.group({
      customername:['',Validators.required],
      customeraddress:['',Validators.required]
    })
    console.log(this.data)
    if(this.data.customerId){
      this.customerForm.patchValue({
        customername:this.data.customername,
        customeraddress:this.data.customeraddress
      })
    }
  }

  get f(){
    return this.customerForm.controls;
  }

  _CustomerName(){
    return this.f.customername.hasError('required') ? 'Customer Name is required' :''
  }

  _CustomerAddress()
  {
    return this.f.customeraddress.hasError('required') ? 'Customer Address is required' :''
  }

  save(){
    this.submitted = true;
    if(this.customerForm.invalid){
      return
    }
  
     if(this.customerForm.valid  && this.customerNameexist == true){
      return
     }

         // console.log("form sumbitted");
          

    let x = this.customerForm.value;

    x['organization'] = {
      "organizationId" : this.userdata.organization.organizationId
    }

    x['customername'] =this.capitalizeWordsName(this.customerForm.value.customername);

    if(this.data.customerId){
      x['customerId'] = this.data.customerId
      this.gs.updateCustomer(this.data.customerId,x).subscribe(
        (data:any)=>{
          Swal.fire({
            text:"Customer Updated Successfully",
            icon:'success'
          })
          this.dialog.closeAll()
          window.location.reload()
        }
      )
    }
    else{
      this.gs.Create_Customer(x).subscribe(
        (data:any)=>{
          Swal.fire({
            text:"Customer Created Successfully",
            icon:'success'
          })
          this.dialog.closeAll()
          window.location.reload()
        }
      )
    }

  }


   customernamevalidate(){


    console.log("customername " +  this.customerForm.value.customername);
    let customerId = 0;
    if(this.data.customerId)
    {
      
      customerId = this.data.customerId;
    }
     if(this.customerForm.value.customername.length != 0 && this.customerForm.value.customeraddress.length != 0)
     {
          console.log("projectstageId : " +  customerId);

            var data  = {
                
              "customerId" : customerId ,
              "customername": this.customerForm.value.customername,
              "customeraddress": this.customerForm.value.customeraddress,
              "organization": {
                "organizationId": this.userdata.organization.organizationId           
                }  
            }

       this.gs.getCustomerName(data).subscribe(a => {
            console.log(a);
           this.customerNameobject = a;
              if(this.customerNameobject.isexist)
              {
                 console.log("role name isexist");
                 this.customerNameexist = true;
              }else{
               this.customerNameexist = false;
              }
       })
     }else{
       this.customerNameexist = false;
     }
   }

   capitalizeWordsName(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };

}
