import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { LeaveService } from 'src/app/providers/leave.service';
import { MatOption } from '@angular/material/core';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { AddHolidayComponent } from '../add-holiday/add-holiday.component';

@Component({
  selector: 'app-exceptionholidayleave',
  templateUrl: './exceptionholidayleave.component.html',
  styleUrls: ['./exceptionholidayleave.component.scss']
})
export class ExceptionholidayleaveComponent implements OnInit {

  exceptionForm: FormGroup;
  exceptionType: any = []
  dateDisable: boolean;
  btnDisable:boolean =false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private leaveservice: LeaveService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddHolidayComponent>
  ) { }

  ngOnInit() {
    this.exceptionForm = this.fb.group({
      exceptionName: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      exceptionDate: ['', Validators.required],
      exceptionType: ['', Validators.required],
      calender: ['', Validators.required],
      exceptionStatus: ['', Validators.required]
    })

    this.leaveservice.getListTypeById(6).subscribe(
      (d: any) => {
        this.exceptionType = d;
      }
    )

    if (this.data.id) {
      this.dateDisable = true
      this.leaveservice.getExceptionById(this.data.id).subscribe(
        (d: any) => {
          this.exceptionForm.patchValue({
            exceptionStatus: d.exceptionStatus.toString()
          })
        }
      )
      this.exceptionForm.patchValue({
        exceptionId: this.data.id,
        exceptionName: this.data.title,
        exceptionDate: this.data.start,
        exceptionType: Number(this.data.holidayType),
        calender: {
          calendarId: Number(this.data.groupId)
        }
      })
    }
  }

  get f() {
    return this.exceptionForm.controls
  }
  onSubmit() {
    if (this.exceptionForm.invalid) {
      return
    }
    this.btnDisable =true;
    let x = this.exceptionForm.value
    console.log(x.exceptionDate,"11111111111")
    
    var date = new Date(x.exceptionDate);
    var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
    x['exceptionDate'] = newdate;
    x['exceptionStatus'] = Number(x.exceptionStatus)

    if (this.data.id) {
      console.log(x)
      x['exceptionId'] = this.data.id
      this.leaveservice.UpdateException(this.data.id, x).subscribe(
        (data: any) => {
          this.btnDisable =false;
          Swal.fire({
            text: data.description,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000 



          })
          this.dialogRef.close(x)
          setTimeout(() => {
            // window.location.reload()
          }, 2000)

        },
        err => {
          this.btnDisable =false;
          Swal.fire({
            text: err.err.message,
            icon: 'error'
          })
          this.dialogRef.close()
          setTimeout(() => {
            // window.location.reload()
          }, 2000)
        }
      )
    }
  }

}
