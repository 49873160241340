import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { EmpTimelogsComponent } from '../emp-timelogs/emp-timelogs.component';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ProjectManagementService } from 'src/app/providers/project-management.service';

@Component({
  selector: 'app-view-approvers',
  templateUrl: './view-approvers.component.html',
  styleUrls: ['./view-approvers.component.scss'],
})
export class ViewApproversComponent implements OnInit {
  loader: boolean = true;

  nodata: boolean = false;
  approversData: any;
  constructor(
    public dialogRef: MatDialogRef<EmpTimelogsComponent>,
    private router: Router,
    private gs: ProjectManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  wholeData: any;
  ngOnInit(): void {
    console.log('this.data.taskDetailId', this.data.taskDetailId);

    this.gs.getApprovals(this.data.taskDetailId).subscribe(
      (pa: any) => {
        this.approversData = pa;
        this.loader = false;
  
        if (pa.errorCode == 204) {
          this.nodata = true;
        }
        console.log('this.approversData', this.approversData);
      },
      (err) => {
        console.log('err',err);
        
        if (err.errorCode == 204) {
          this.nodata = true;
        }
      }
    );

    console.log('viewApprovers', this.approversData);

    console.log(
      'this.data',
      this.data?.approvals?.approverFourStatus?.listTypeValueName
    );

    this.wholeData = this.data;
    // console.log(
    //   'description',
    //   this.wholeData.approvals.approver.approverTwoObject.firstName
    // );
    console.log('approverOneStatusName', this.wholeData.approvalStatusName);
  }

  approvalStatus(approvalStatusName) {
    if (approvalStatusName == null) {
      return;
    }
    if (approvalStatusName == 'Pending') {
      return '#a68b00';
    }
    if (approvalStatusName == 'Approved') {
      return '#388e3c';
    }
    if (approvalStatusName == 'Denied') {
      return '#c62828';
    }
  }

  approvalBackground(approvalStatusName) {
    if (approvalStatusName == null) {
      return;
    }
    if (approvalStatusName == 'Pending') {
      return '#fff0c2';
    }
    if (approvalStatusName == 'Approved') {
      return '#c8e6c9';
    }
    if (approvalStatusName == 'Denied') {
      return '#ffcdd2';
    }
  }
}
