
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { AppComponent } from 'src/app/app.component';
import { SettingsService } from 'src/app/providers/settings.service';
import { catchError, EMPTY, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import * as pdfMake from "pdfmake/build/pdfmake";
import { DatePipe } from '@angular/common';
import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ActivatedRoute, Router } from '@angular/router';
import { Stream } from 'stream';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { EmployeeComponent } from 'src/app/employeeComponents/employee/employee.component';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import *  as htmlToPdfmake  from "html-to-pdfmake";
// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// import domtoimage from 'dom-to-image';
// import  jsPDF from 'jspdf';
// import { DatePipe } from '@angular/common';
// import { map } from 'rxjs-compat/operator/map';
// import { NgxPrinterService} from 'ngx-printer';
@Component({
  selector: 'app-pf-reports',
  templateUrl: './pf-reports.component.html',
  styleUrls: ['./pf-reports.component.scss']
})
export class PfReportsComponent implements OnInit {
  isProcessing = false;

  pfForm: FormGroup;
  today: any = new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  usertData: any;
  orgId: number;
  clientList: any;
  empList: any = [];
  empNum: number;
  nodatafound: boolean = true;
  update_error_msg: any;
  loader: any = false;
  backdrop: any = false;
  resultData: any = [];
  noRecordsFound: boolean = false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate: any;
  filterenddate: any;
  clientId: any;
  pagePerItem: any = 10;
  p: number = 1;
  tableSearch: any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg: any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported: any;
  exportingdata: any;
  beforepage: any;
  dateFormat: any;
  finYearList: any = [];
  finPeriodList: any = [];
  chooseFinId: any;
  ratingId: any;
  req: boolean = false;
  finEmpList: any;
  eleNameList: any;
  categoryId: number;
  categoryTypeData: any;
  categoryName: any;
  employeePFval: number = 0;
  totalPF: number = 0;
  employeerPF: number = 0;
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  excelData: any;
  dataForExcel = [];
  headerForExcel = [
    { 'Employee Code': '', 'UAN Number': '', 'Employee Name': '', 'Worked Days': '', 'Earned Wages': '', 'Period': '', 'Employee PF': '' }
  ];
  orgdata: any;
  base64data: string | ArrayBuffer | null = null;
  payrollHeaderId: any;
  fileblob: string;
  period: string = "";
  employeeName: any = "";
  fromPer: any;
  toPer: any;
  payrolldata: any = [];
  tPeriod: any;
  fPeriod: any;
  show: any = false;
  orgworkingdays: any;
  orgworkingdaysList: any;
  employeeAll: boolean;
  firstname: any;
  empnumber: any;
  employeelistsub: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  empCode: boolean =false;
  selectedemployee: any;
  selectedemployeeId: any;
  inputValue: any;
  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private payrollservice: PayrollService,
    private datepipe: DatePipe,
    public activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    public gs: GlobalserviceService,
    public ExportExcelService: ExportExcelService,
    @Inject(AppComponent) public AppData: any,
    private superAminservice: SuperadminService,
    private EmployeeService: EmployeeService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.usertData.employee?.client?.clientId;
    console.log(this.clientId);

    this.pfForm = this.fb.group({
      employeecode: [null, [Validators.required]],
      financialyear: [null,],
      fromperiod: [null,],
      // toperiod:[null,]
    })


if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }

  if(this.clientId){
    this.settingsService.getFinicalyearActiveListbyClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }


    // this.superAminservice.getOrganizationById(this.orgId ).subscribe((resultData:any) =>
    // {
    //   this.loader=false;
    //   this.backdrop=false;

    //   this.orgdata=resultData;

    //   console.log("ORG DETAIL : ",this.orgdata);

    //   if(resultData.organizationAttachment)
    //   {
    //     this.EmployeeService.getemployeeImage(resultData.organizationAttachment.fileViewUrl).subscribe(
    //       blob => {
    //         const a = document.createElement('a')
    //         const objectUrl: any = blob
    //         this.fileblob = blob;

    //       },
    //       err => 
    //       {
    //         this.fileblob= "assets/uploads/no-image.png"
    //       }
    //     );
    //   }
    //   else
    //   {
    //     this.fileblob= "assets/uploads/no-image.png"
    //   }
    // });


  if(this.orgId){
    this.superAminservice.getOrganizationById(this.orgId).subscribe(
      (resultData: any) => {
        this.resultData = resultData;
        console.log(this.resultData);
        if (this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl != undefined) {
          console.log(this.resultData.organizationAttachment.fileViewUrl);
          this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
            (blob) => {
              const a = document.createElement('a')
              const objectUrl: any = blob
              this.fileblob = blob;
              this.http.get(this.fileblob, { responseType: 'blob' })
                .subscribe(res => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    this.base64data = reader.result;
                    // console.log(this.base64data);
                  }
                  reader.readAsDataURL(res);
                  // console.log(res);
                });
            },
            err => {
              this.fileblob = "assets/uploads/no-image.png"
              console.log("else", this.fileblob);
              this.http.get(this.fileblob, { responseType: 'blob' })
                .subscribe(res => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    this.base64data = reader.result;
                    // console.log(this.base64data);
                  }
                  reader.readAsDataURL(res);
                  // console.log(res);
                });
            }
          );
        }
        else {
          this.fileblob = "assets/uploads/no-image.png";
          console.log("else", this.fileblob);
          this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;
                // console.log(this.base64data);
              }
              reader.readAsDataURL(res);
              // console.log(res);
            });
        }
      }
    )
  }
  if(this.clientId){
    this.superAminservice.getClientById(this.clientId).subscribe(
      (resultData: any) => {
        this.resultData = resultData;
        console.log(this.resultData);
        if (this.resultData.organizationAttachment && this.resultData.organizationAttachment.fileViewUrl != undefined) {
          console.log(this.resultData.organizationAttachment.fileViewUrl);
          this.EmployeeService.getemployeeImage(this.resultData.organizationAttachment.fileViewUrl).subscribe(
            (blob) => {
              const a = document.createElement('a')
              const objectUrl: any = blob
              this.fileblob = blob;
              this.http.get(this.fileblob, { responseType: 'blob' })
                .subscribe(res => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    this.base64data = reader.result;
                    // console.log(this.base64data);
                  }
                  reader.readAsDataURL(res);
                  // console.log(res);
                });
            },
            err => {
              this.fileblob = "assets/uploads/no-image.png"
              console.log("else", this.fileblob);
              this.http.get(this.fileblob, { responseType: 'blob' })
                .subscribe(res => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    this.base64data = reader.result;
                    // console.log(this.base64data);
                  }
                  reader.readAsDataURL(res);
                  // console.log(res);
                });
            }
          );
        }
        else {
          this.fileblob = "assets/uploads/no-image.png";
          console.log("else", this.fileblob);
          this.http.get(this.fileblob, { responseType: 'blob' })
            .subscribe(res => {
              const reader = new FileReader();
              reader.onloadend = () => {
                this.base64data = reader.result;
                // console.log(this.base64data);
              }
              reader.readAsDataURL(res);
              // console.log(res);
            });
        }
      }
    )
  }
  }


  // empSearch(event) {
  //   this.empList = [];
  //   this.empCode = false;
  //   console.log(event.target.value);
  //   this.userId = event.target.value;
  //   console.log(this.userId);
  //   // Employee List Api
  //   // this.empList.length == 0;
  //   if (event.target.value.length >= 2) {
  //     if (this.employeelistsub) {
  //       this.employeelistsub.unsubscribe();
  //     }
  //     this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId, this.orgId)
  //       .pipe(
  //         takeUntil(this.unsubscribe$), // Cancel previous request
  //         switchMap(() => this.payrollservice.getEmployeeNameList(this.userId, this.orgId))
  //       )
  //       .subscribe((result: any) => {
  //         this.empList.length = 0;

  //         if (result.statusCode) {
  //           console.log(result.statusCode);
  //           this.empList.length = 0;
  //         } else {
  //           this.empList = result;
  //         }
  //       });
  //   }
  // }


  empSearch(event){
    console.log(event.target.value)
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement) // Cast the event target as an input element
  this. inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
   console.log(this.inputValue)
    // Clear the employee list and reset flags if input is empty
    if (this.inputValue === '') {
      this.empList = [];
      this.empCode = false;
      console.log('Input is empty, employee list cleared');
      return;
    }
  
    // Check if input length is sufficient for the search
    if (this.inputValue.length >= 2) {
      // Unsubscribe from the previous API request if necessary
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
  
      // Reset and create a new Subject for cancellation
      this.unsubscribe$ = new Subject<void>();
  
      if(this.orgId){
      this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }

      if(this.clientId){
        this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true; // Set the flag to indicate an error state
              }
              console.error('An error occurred:', err);
              return EMPTY; // Return EMPTY to propagate completion downstream
            })
          )
          .subscribe(
            (result: any) => {
              if (result.statusCode === 204) {
                console.log('No employees found (status code 204)');
                this.empList = [];
                this.empCode = true; // Indicate no results found
              } else if (result.statusCode) {
                console.log('Other status code:', result.statusCode);
                this.empList = [];
                this.empCode = true;
              } else {
                // Handle the case where result is an array or an object
                this.empList = Array.isArray(result) ? result : Object.values(result);
                this.empCode = false;
              }
            },
            (err) => {
              if (err.error.status === 500) {
                this.empList = [];
                this.empCode = true;
              }
              console.error('An error occurred:', err);
            }
          );
        }
    }
  }

  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log('empSelect', emp);
    this.employeeAll = false;
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname = emp.firstName;
    // this.lastname=emp.lastName;
    this.empnumber = emp.employeeNumber;
   this.employee= this.empnumber + "-" + this.firstname
    // if (this.employeeLeaveId) {
    this.pfForm.patchValue({
      employeecode: this.empnumber + "-" + this.firstname
    });


  }


  // clearTable()
  // {
  //   this.resultData.length = 0;
  // }

  searchSubmit() {


    if((this.pfForm.value.employeecode !== undefined && this.pfForm.value.employeecode !== null && this.pfForm.value.employeecode !== '') ){
      if((this.employee != this.pfForm.value.employeecode ) || (this.employeeId == null)){
        window.alert('please select valid employee from dropdown.');
        return;
      }
    }
    this.isProcessing = true;
    this.loader = true;
    this.noRecordsFound =false;

    console.log("Filter", this.pfForm.value);
    if ((this.pfForm.value.employeecode === '' || this.pfForm.value.employeecode === null) && (this.pfForm.value.financialyear === null || this.pfForm.value.financialyear === 'null') && (this.pfForm.value.fromperiod === null || this.pfForm.value.fromperiod === 'null')) {
      console.log("Required Field");
      this.resultData = [];
      this.noRecordsFound = false;
      alert("Please Enter Employee Number");
      this.isProcessing = false;
      this.loader = false
      return;
    }
    // if((this.pfForm.value.employeecode!=''|| this.pfForm.value.employeecode!=null)&&(this.pfForm.value.financialyear===null|| this.pfForm.value.financialyear==='null')&&(this.pfForm.value.fromperiod!=null|| this.pfForm.value.fromperiod!='null')
    // ||(this.pfForm.value.employeecode===''|| this.pfForm.value.employeecode===null)&&(this.pfForm.value.financialyear===null|| this.pfForm.value.financialyear==='null')&&(this.pfForm.value.fromperiod!=null|| this.pfForm.value.fromperiod!='null')
    // )
    // {
    //   console.log("Required financialyear");
    //   alert("Please Enter financialyear");
    // }
    if ((this.pfForm.value.financialyear === null || this.pfForm.value.financialyear === 'null') && (this.pfForm.value.fromperiod === null || this.pfForm.value.fromperiod === 'null')) {
      console.log("Required Field");
      this.resultData = [];
      this.noRecordsFound = false;
      alert("Please Enter Requried Fields");
      this.isProcessing = false;
      this.loader = false

      return;
    }

    if ((this.pfForm.value.fromperiod === null || this.pfForm.value.fromperiod === 'null')) {
      console.log("Required Field");
      this.resultData = [];
      this.noRecordsFound = false;
      alert("Please Enter Requried Fields");
      this.isProcessing = false;
      this.loader = false

      return;
    }
    console.log(this.pfForm.value);
    if ((this.pfForm.value.employeecode != '' || this.pfForm.value.employeecode != null) && (this.pfForm.value.financialyear === null || this.pfForm.value.financialyear === 'null') && (this.pfForm.value.fromperiod === null || this.pfForm.value.fromperiod === 'null')) {
      console.log("EMPLOYEE");
      this.payrollservice.getemployeeList(this.employeeId).subscribe((resultData: any) => {
        this.noRecordsFound = false;
        this.resultData = resultData;
        //  if( this.resultData.length>0)
        //  {

        //  let lastindex = this.resultData.length -1;
        //  this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
        //  + " to "  + this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
        //  }
        console.log(this.resultData);
        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.noRecordsFound = true;
        }
        else {
          this.calculate(this.resultData);
          let lastindex = this.resultData.length - 1;
          this.period = this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
          // + " to "  + this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
          this.employeeName = "Employee : " + " " + this.pfForm.value.employeecode + " " + this.resultData[0].employee.firstName + ' ' + this.resultData[0].employee.lastName
        }
        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        this.isProcessing = false;
        this.loader = false;
      })
    }

    else if ((this.pfForm.value.employeecode === '' || this.pfForm.value.employeecode === null) && (this.pfForm.value.financialyear != null || this.pfForm.value.financialyear != 'null') && (this.pfForm.value.fromperiod === null || this.pfForm.value.fromperiod === 'null')) {
      console.log("YEAR");
      this.payrollservice.getyearList(this.pfForm.value.financialyear).subscribe((resultData: any) => {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if(this.resultData.length>0)
        // {
        // this.period = this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
        // + " to "  + this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
        // }
        // console.log(this.resultData);
        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.noRecordsFound = true;
        }
        else {
          this.calculate(this.resultData);
          this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + "-" + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear

          this.employeeName = "";
          console.log(this.employeeName);
        }
        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        this.isProcessing = false;
        this.loader = false;
      })
    }

    else if ((this.pfForm.value.employeecode != '' || this.pfForm.value.employeecode != null) && (this.pfForm.value.financialyear != null || this.pfForm.value.financialyear != 'null') && (this.pfForm.value.fromperiod === null || this.pfForm.value.fromperiod === 'null')) {
      console.log("EMPLOYEE AND YEAR");
      this.payrollservice.geEmpandYear(this.pfForm.value.employeecode, this.pfForm.value.financialyear).subscribe((resultData: any) => {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if(this.resultData.length>0)
        // {
        // /
        // console.log(this.resultData);
        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.noRecordsFound = true;
        }
        else {
          this.calculate(this.resultData);
          let lastindex = this.resultData.length - 1;
          this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + "-" + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear

          this.employeeName = "";
          this.employeeName = "Employee : " + " " + this.pfForm.value.employeecode
        }
        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        this.isProcessing = false;
        this.loader = false;
      })
    }

    else if ((this.pfForm.value.employeecode === '' || this.pfForm.value.employeecode === null) && (this.pfForm.value.financialyear != null || this.pfForm.value.financialyear != 'null') && (this.pfForm.value.fromperiod != null || this.pfForm.value.fromperiod != 'null')) {
      console.log("YEAR AND PERIOD");
      this.payrollservice.geYearandPeriod(this.pfForm.value.financialyear, this.pfForm.value.fromperiod).subscribe((resultData: any) => {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {
        // }
        // console.log(this.resultData);
        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.noRecordsFound = true;
        }
        else {
          this.calculate(this.resultData);
          console.log(this.finPeriodList);
          this.fromPer = this.pfForm.value.fromperiod;
          this.fromPeriodFunction(this.fromPer);
          this.toPer = this.pfForm.value.toperiod;
          this.toPeriodFunction(this.toPer);
          this.period = this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
          // + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
          this.employeeName = "";
        }
        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        this.isProcessing = false;
        this.loader = false;
        this.payrollservice.getorgFinanicalandPeriodList(this.pfForm.value.fromperiod, this.orgId).subscribe((filterData: any) => {
          // this.noRecordsFound = false;
          console.log(filterData);
          this.orgworkingdaysList = filterData;
          console.log(this.orgworkingdaysList);

        console.log(this.orgworkingdaysList.length);
        console.log(this.resultData.length);

          // if ((this.orgworkingdaysList.length === 0 || this.orgworkingdaysList.length === undefined) &&
          //   (this.resultData.length === 0 || this.resultData.length === undefined)) {
          //   console.log("if checking")
          //   this.noRecordsFound = true;
          //   // Your code here
          // }

           if (this.orgworkingdaysList.length != undefined) {
            console.log(this.orgworkingdaysList)
            this.orgworkingdays = this.orgworkingdaysList[0].workingDays;
            // if(resultData.length === undefined){
            //   this.noRecordsFound = true;

            // }
          }
          console.log(this.orgworkingdays);
          this.isProcessing = false;
          this.loader = false;
        })
      })
    }



    else if ((this.pfForm.value.employeecode != '' || this.pfForm.value.employeecode != null) && (this.pfForm.value.financialyear != null || this.pfForm.value.financialyear != 'null') && (this.pfForm.value.fromperiod != null || this.pfForm.value.fromperiod != 'null')) {
      console.log("BOTH");
      this.payrollservice.getpfListbyemployeeandperiod(this.employeeId, this.pfForm.value.financialyear, this.pfForm.value.fromperiod).subscribe((resultData: any) => {
        this.noRecordsFound = false;
        this.resultData = resultData;
        // if( this.resultData.length>0)
        // {

        // }
        // console.log(this.resultData);
        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.noRecordsFound = true;
        }
        else {
          this.calculate(this.resultData);
          console.log(this.finPeriodList);
          this.fromPer = this.pfForm.value.fromperiod;
          this.fromPeriodFunction(this.fromPer);
          this.toPer = this.pfForm.value.toperiod;
          this.toPeriodFunction(this.toPer);
          this.period = this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
          // + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
          this.employeeName = "Employee : " + " " + this.pfForm.value.employeecode
        }
        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        this.isProcessing = false;
        this.loader = false;


        if(this.orgId){
        this.payrollservice.getorgFinanicalandPeriodList(this.pfForm.value.fromperiod, this.orgId).subscribe((filterData: any) => {
          // this.noRecordsFound = false;
          this.orgworkingdaysList = filterData;
          // if ((this.orgworkingdaysList.length === 0 || this.orgworkingdaysList === undefined) &&
          //   (this.resultData.length === 0 || this.resultData === undefined)) {
          //   this.noRecordsFound = true;
          //   // Your code here
          // }

           if (this.orgworkingdaysList.length != undefined) {
            this.orgworkingdays = this.orgworkingdaysList[0].workingDays;
          }
          console.log(this.orgworkingdays);
          this.isProcessing = false;
          this.loader = false;
        })
      }
      if(this.clientId){
        this.payrollservice.getClientFinanicalandPeriodList(this.pfForm.value.fromperiod, this.clientId).subscribe((filterData: any) => {
          // this.noRecordsFound = false;
          this.orgworkingdaysList = filterData;
          // if ((this.orgworkingdaysList.length === 0 || this.orgworkingdaysList === undefined) &&
          //   (this.resultData.length === 0 || this.resultData === undefined)) {
          //   this.noRecordsFound = true;
          //   // Your code here
          // }

           if (this.orgworkingdaysList.length != undefined) {
            this.orgworkingdays = this.orgworkingdaysList[0].workingDays;
          }
          console.log(this.orgworkingdays);
          this.isProcessing = false;
          this.loader = false;
        })
      }
      })
    }

  }
  periodid(fromPer: any) {
    throw new Error('Method not implemented.');
  }
  fromPeriodFunction(fromPer: any) {
    console.log(fromPer);
    this.fPeriod = [];
    this.finPeriodList.forEach(element => {
      console.log(element);
      if (element.financialPeriodId == fromPer) {
        this.fPeriod = element;
        console.log(this.fPeriod);
      }
    });
  }
  toPeriodFunction(toPer: any) {
    console.log(toPer);
    this.tPeriod = [];
    this.finPeriodList.forEach(element => {
      console.log(element);
      if (element.financialPeriodId == toPer) {
        this.tPeriod = element;
        console.log(this.tPeriod);
      }
    });
  }
  // Calculate Function
  calculate(resultData: any) {
    this.employeePFval = 0;
    this.employeerPF = 0;
    this.totalPF = 0;
    console.log("resultData", resultData);
    resultData.forEach(element => {
      console.log(element);
      if (element.employeePF != 0) {
        console.log(this.employeePFval);
        this.employeePFval = this.employeePFval + element.employeePF;
        console.log(this.employeePFval);
      }
      if (element.employeerPF != 0) {
        this.employeerPF = this.employeerPF + element.employeerPF;
        console.log(this.employeerPF);
      }
      if (element.totalPF != 0) {
        this.totalPF = this.totalPF + element.totalPF;
        console.log(this.totalPF);
      }

    });
  }
  pageChages() {
    console.log("page changes");
    this.p = 1;
  }

  selectFinancialyear() {
    console.log("Select");
    // console.log(event.target.value);
    this.pfForm.get('financialyear').value;
    // console.log(this.FilterForm.value.financialyear);
    this.chooseFinId = this.pfForm.get('financialyear').value;

    console.log(this.chooseFinId);
    if (this.chooseFinId == null || this.chooseFinId == 'null') {
      console.log('if');
      this.finPeriodList = [];
    } else {
      this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
        console.log("PeriodData", resultData)
        this.finPeriodList = resultData.finanicalPeriod;
        this.pfForm.patchValue({
          fromperiod: null,
          toperiod: null
        });
      });
    }
    // this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
    //   console.log("PeriodData", resultData)
    //   this.finPeriodList = resultData.finanicalPeriod;
    // });
  }


  public downloadAsExcel() {
    // this.excelData=this.resultData;
    // console.log(this.excelData);
    this.dataForExcel.length = 0;
    for (let e of this.resultData) {
      let data = {
        employeeNumber: e.employee.employeeNumber,
        uanNumber: e.employee.uanNumber,
        firstName: e.employee.firstName + ' ' + (e.employee.lastName ? e.employee.lastName : ""),
        workingdays: this.getempworkingdays(e),
        ctc: e.ctc,
        financialperiodMonth: e.finanicalPeriod.financialperiodMonth + ' ' + e.finanicalPeriod.financialPeriodYear,
        employeePF: e.employeePF?.toFixed(2),
      }
      this.dataForExcel.push(Object.values(data))
      console.log(this.dataForExcel);
    }
    let data = {
      employeeNumber: "",
      uanNumber: "",
      firstName: "",
      workingDays: "",
      ctc: "",
      financialperiodMonth: "Total",
      employeePF: this.employeePFval,
      // totalPF:this.totalPF
    }
    this.dataForExcel.push(Object.values(data))
    let reportDatabyrecruiter = {
      title: 'PF Report',
      exceldate: "Period : " + this.period,
      data: this.dataForExcel,
      employeeName: this.employeeName,
      headers: Object.keys(this.headerForExcel[0])
    }
    console.log(reportDatabyrecruiter);
    this.ExportExcelService.exportExcel(reportDatabyrecruiter);
  }

  // public downloadAsPDF()
  //  {
  //   console.log("download");
  //   const pdfTable = this.pdfTable.nativeElement;

  //   console.log(pdfTable);


  //   var html = htmlToPdfmake(pdfTable.innerHTML, {
  //     tableAutoSize:true,
  //   });

  //   const documentDefinition = {
  //      content: html , 
  //      pageMargins: [ 40, 40, 40, 150],
  //       imagesByReference:true ,
  //      info: {
  //        title:`Pay Roll`
  //        },


  //       pageSize: 'A4',
  //       };
  //    console.log(documentDefinition);
  //   pdfMake.createPdf(documentDefinition).download(`Pay Roll.pdf`); 

  // }

  clearTable() {
    this.resultData = [];
    console.log('clear')
    this.pfForm.clearValidators;
    this.noRecordsFound = false;
    this.finPeriodList = [];
    this.empList =[];
    this.empCode=false;
  }
  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    const documentDefinition = {
      content: html,
      pageMargins: [40, 40, 40, 40],
      info: {
        title: `PF Report`
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);

        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download("PF Report");
  }

  // printPdf(){
  //   console.log("PrintPDF");
  //   const pdfTable = this.pdfTable.nativeElement;
  //   var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
  //   this.gs.getManagePayslipById(this.payrollHeaderId).subscribe(
  //     (data:any)=>{
  //       console.log(data);
  //       this.payrolldata=data;
  //       const formattedDate = this.datepipe.transform(this.payrolldata.createDateTime, this.dateFormat);
  //       const documentDefinition = { 
  //       content: html ,
  //       // image: this.fileblob, // Use the dataURL here
  //       // width: 200,
  //       // height: 100,
  //       pageMargins: [ 40, 40, 40, 40 ],
  //       info: {
  //         title:`Report ${formattedDate}`
  //         },
  //         pageSize: 'A4',
  //         pageOrientation: 'landscape',
  //       };
  //      pdfMake.createPdf(documentDefinition).open(); 
  //     })
  // }


  getempworkingdays(element) {
    if (this.orgworkingdays && element.employeeLeave) {
      return (this.orgworkingdays - element.employeeLeave.leaveDays);
    } else {

      return "--"
    }
  }

  Roundof(amount) {
    return Math.round(amount);
  }

  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 

  }
}
