import { Component, Inject, OnInit,ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImportEmployeeComponent } from '../../app/project-management/project-management/import-employee/import-employee.component';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';


@Component({
  selector: 'app-employeeworkingdaysimport',
  templateUrl: './employeeworkingdaysimport.component.html',
  styleUrls: ['./employeeworkingdaysimport.component.scss']
})
export class EmployeeworkingdaysimportComponent implements OnInit {
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  fileInputLabel: string;
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeefile :any;
  employeetData:any;
  errorrecords: any;
  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private payrollservice: PayrollService,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<ImportEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      file : ['',[Validators.required]]
    });
  }

  get validate() { return this.postForm.controls; }

  onSubmit()
  {
    /* if (!this.postForm.get('myfile').value)
    {
      alert('Please fill valid details!');
      return false;
    } */

    this.submitted = true;
    if (this.postForm.invalid) {return;}

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;

    const formData = new FormData();
    formData.append("file", this.postForm.get('file').value);

    formData.append('organizationId', organizationId);

    this.payrollservice.importEmployeeworkingdays(formData).subscribe((result: any) =>
    {
      if(result.statusCode == 200)
      {
        //this.loader = true;
        // this.success = true;
        // this.sucess_msg = result.description;

       
      
        // setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        // this.backdrop = false;
        // this.loader = false;
        // this.success = true;
        this.dialogref.close(result);

      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        this.errorrecords = result;
        this.dialogref.close(result);
      }
    }, error => {
      console.log(error);
    }); //Create end here

  }

  onFileSelect(event)
  {
    //let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0)
    {
      const file = event.target.files[0];
      //this.fileToUpload = event.item(0);
      // console.log(file);

      /*   if (!_.includes(af, file.type)) {
        alert('Only EXCEL Docs Allowed!');
      } else { */
        this.fileInputLabel = file.name;
        this.postForm.get('file').setValue(file);
      ///}
    }
  }

}
