<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Employee Rating</span>
        </div>
        <div class="sub-header-buttons">
          <a
            (click)="importEmployeeRating()"
            mat-raised-button
            class="ml-2 mr-2 searchBtn2"
            title="Import"
          >
            Import
          </a>
          <button mat-raised-button class="searchBtn2" (click)="addRating()">
            Add Employee Rating
          </button>
          <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
          <div class="row">
            <div class="form-group col-md-3">
              <label class="form-control-label">Search Employee </label>
              <input
                formControlName="employeecode"
                type="search"
                autocomplete="off"
                class="form-control email-input select-placeholder"
                value=""
                placeholder="Search..." (input)="validateInputs($event)"
                (keydown)="validateInputs($event)" (keyup)="validateInputs($event)"
              />
              <small class="text-muted">Note : Employee Number</small>
              <!-- <div *ngIf="this.req">
                                     <span style="color:red">*Employee Number required</span>
                                 </div> -->
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">Financial Year </label>
              <mat-select
                formControlName="financialyear"
                class="form-control email-input select-placeholder"
                (selectionChange)="selectFinancialyear()"
                placeholder="Select Financial Year"
              >
                <mat-option [value]="null">-- Select Financial Year ---</mat-option>
                <mat-option
                  *ngFor="let row of finYearList; let i = index"
                  value="{{ row.financialYearId }}"
                >
                  {{ row.financialFromMonth }}-{{ row.financialFromYear }} To
                  {{ row.financialToMonth }}-{{ row.financialToYear }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label">Period </label>
              <mat-select
                formControlName="period"
                class="form-control email-input select-placeholder"
                placeholder="Select Period"
              >
                <mat-option [value]="null">-- Select Period ---</mat-option>
                <mat-option
                  *ngFor="let row of finPeriodList; let i = index"
                  value="{{ row.financialPeriodId }}"
                >
                  {{ row.financialperiodMonth }}-{{ row.financialPeriodYear }}
                </mat-option>
              </mat-select>
            </div>
            <div class="col-md-3 button-margin" >
              <!-- <button type="submit" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn">Search
                                </button> -->
              <button
                type="submit"
                mat-raised-button
                class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
                [disabled]="isProcessing"
              >
                Search
              </button>
              <!-- <button style="margin-left: 10px;" type="reset" mat-raised-button
                                   class="btn mr-2 search-btn"
                                    (click)="clearTable()">Clear
                                </button> -->

              <button
                mat-stroked-button
                type="reset"
                class="apply-buttons"
                (click)="clearTable()"
                [disabled]="isProcessing"
                style="
                  margin: 8px 0px 0px 5px;
                  background: white;
                  border: 1px solid #ddd;
                  position: relative;
                  top: -30%;
                  font-family: Mulish;
                  color: black;
                  font-size: 16px;
                  font-weight: 610;
                "
              >
                Clear
              </button>
            </div>
          </div>
        </form>
        <div
          class="row pb-3"
          *ngIf="!loader && showtable && resultData.length > 0"
        >
          <!-- <div class="col-md-11 text-right"  *ngIf="resultData.length>5">
                                <p class="show-count">Show</p>
                            </div>
                            <div class="col-md-1 text-left pl-0" *ngIf="resultData.length>5">
                                <select [(ngModel)]="pagePerItem"  (change)="onItemsPerPageChange($event)" class="form-control report-filter">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div> -->

          <div class="text-right" *ngIf="resultData.length > 5">
            <div class="select-pagination m-2">
              <span>Show : </span>
              <select
                class="select_list new-select_list listDesign"
                [(ngModel)]="pagePerItem"
                (change)="onItemsPerPageChange($event)"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>

        <div *ngIf="showtable && !loader" class="table-responsive">
          <!-- <p><b>EMPLOYEE RATING</b></p> -->
          <table
            *ngIf="!loader"
            class="table --table-bordered table-border-new"
            style="border-top-left-radius: 10px; border-top-right-radius: 10px"
          >
            <thead class="t-head">
              <tr>
                <th>Action</th>
                <th>Financial Year</th>
                <th>Period</th>
                <th>Employee Code</th>
                <th>Employee Name</th>
                <th>Rating</th>
              </tr>
            </thead>
            <tbody *ngIf="resultData.length > 0">
              <tr
                *ngFor="
                  let row of resultData
                    | paginate : { itemsPerPage: pagePerItem, currentPage: p }
                    | searchFilter : tableSearch;
                  let i = index
                "
              >
                <td class="">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" class="--controls-list --customize">
                    <button
                      mat-menu-item
                      routerLink="/home/edit-employee-rating/{{
                        row.employeerattingId
                      }}"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==1" (click)="updateStatus(row.clientAssignId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==2" (click)="updateStatus(row.clientAssignId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button> -->
                  </mat-menu>
                </td>
                <td>
                  {{ row.financialPeriod.finanicalYear.financialFromMonth }}-{{
                    row.financialPeriod.finanicalYear.financialFromYear
                  }}
                  To {{ row.financialPeriod.finanicalYear.financialToMonth }}-{{
                    row.financialPeriod.finanicalYear.financialToYear
                  }}
                </td>
                <td>
                  {{ row.financialPeriod.financialperiodMonth }}-{{
                    row.financialPeriod.financialPeriodYear
                  }}
                </td>
                <td>{{ row.employee.employeeNumber }}</td>
                <td>{{ row.employee.firstName }}</td>
                <td>{{ row.rating }}</td>
                <!-- <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId==1">
                                            {{row.status.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId==2">
                                            {{row.status.listTypeValueName}}
                                        </span>
                                    </td> -->
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td
                  style="text-align: center; font-size: 21px; font-weight: 770"
                  colspan="6"
                  *ngIf="noRecordsFound"
                >
                  No Records Found!
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="resultData.length > 0 && !loader">
          <div class="row">
            <div class="col-md-4">
              <div class="showing-record">{{ resultData.length }} rows</div>
            </div>
            <div class="col-md-8" *ngIf="!loader && resultData.length > 5">
              <pagination-controls
                style="float: right"
                (pageChange)="p = $event"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; top: 53%; right: 47.8%"
    role="status"
  ></div>
</div>
