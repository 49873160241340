<!-- <nav class="navbar navbar-light bg-light bg" style="margin-top: 68px">
  <div class="container-fluid">
    <a class="navbar-brand">Manage Timesheet</a>
    <div class="d-flex">
      <input
        class="form-control m-2"
        aria-label="Search"
        type="text"
        class="form-control search-menu"
        placeholder="Search..."
        [(ngModel)]="search"
        (keyup)="changepage()"
        name="searchText"
        style="
          border-radius: 5px;
          border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
          background: #fff;
          width: 250px;
          height: 45px;
          flex-shrink: 0;
        "
      />
   
    </div>
  </div>
</nav> -->

<mat-card class="header-card" style="margin-top: 66px !important">
  <div class="top-content row col-sm-12  mt-2 ">
    <!-- <div
      class="col-md-5 col-sm-12"
      *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'"
    >
      <h4 class="timesheet">Manage Timesheet</h4>
    </div> -->
    <div class="col-md-5 col-sm-12 mt-1">
      <h4 class="heading-tag">Manage Timesheet</h4>
    </div>

    <div class="col-md-5 col-sm-5 mt-1">
      <ul class="unstyled inbox-pagination">
        <div class="sidebar-search">
          <div class="input-group">
            <input
              type="input"
              class="form-control search-menu search-placeholder"
              placeholder="Search..."
              [(ngModel)]="search"
              (keyup)="changepage(); changePlaceholderColor($event)"
              name="searchText"
            />
            <!-- <div class="material-icons-outlined search-icon">search</div> -->
          </div>
        </div>
      </ul>
    </div>
    <!-- <div class="col-md-2 col-sm-2 col2-res button-top">
      <button
        type="button"
        mat-raised-button
        class="btn float-right res-timesheet"
        (click)="updateTime()"
      >
        <span>Add TimeSheet</span>
      </button>
    </div> -->
  </div>
</mat-card>
<mat-card>
  <div class="search-content m-3">
    <div class="row">
      <div class="col-sm-3">
        <label class="form-control-label"
          >Project<span class="text-danger"> *</span></label
        >
        <mat-select
          [(ngModel)]="projectId"
          (selectionChange)="getTask()"
          (click)="search = ''"
          class="form-control email-input select-placeholder"
          placeholder="Select Project"
        >
          <mat-option (click)="selectProject()">-Select Project-</mat-option>
          <mat-option *ngFor="let pro of projectIdList" [value]="pro.projectId">
            {{ pro.projectName }}
          </mat-option>
        </mat-select>
        <span class="error_msg" *ngIf="project_required"
          >Please&nbsp;select&nbsp;Project</span
        >
      </div>
      <!-- <div class="col-sm-3">

      <label class="form-control-label">Employee</label>
      <mat-select
        id="framework"
        class="form-control email-input select-placeholder"
        [(ngModel)]="selectedemployeeId"
        placeholder="Select Employee"
      >
        <mat-option>-Select Employee-</mat-option>
        <mat-option
          *ngFor="let name of employeelist"
          [value]="name?.employee?.employeeId"
        >
          {{ name?.employee?.employeeName }}
        </mat-option>
      </mat-select>
  
    </div> -->

      <div class="col-sm-3 mt-sm-3">
        <label class="form-control-label">Search Employee</label>

        <!-- <input
          type="text"
          (click)="this.search = ''"
          placeholder="Search Employee"
          (input)="validateInput($event)"
          (keydown)="validateInput($event)"
          (keyup)="validateInput($event)"
          [(ngModel)]="selectedemployee"
          [matAutocomplete]="auto"
          [disabled]="!projectId"
          (click)="search = ''"
          class="form-control email-input select-placeholder text-truncate"
          (keyup)="empSearch($event, $event); this.search = ''"
          (keydown)="handleBackspace($event)"
        /> -->

        <!-- [readonly]="
          !assignformbyemployee.value.employee ||
          assignformbyemployee.value.employee == 'null'
        " -->
        <!-- <mat-autocomplete class="text-truncate" #auto="matAutocomplete">
          <mat-option
            class="text-truncate"
            *ngFor="let emp of employeeProjects"
            [value]="emp?.employee.employeeId"
            (click)="empSelect(emp)"
          >
            {{ emp?.employee?.employeeNumber }} -
            {{ emp?.employee?.firstName }}
          </mat-option>
          <mat-option
            [value]="null"
            *ngIf="employeeNotFound"
            (click)="noEmployeeFound()"
            >No Employee Found</mat-option
          >
        </mat-autocomplete> -->

        <mat-select
        [(ngModel)]="selectedEmployeeSearch"
          placeholder="Search Employee"
          class="form-control email-input select-placeholder text-truncate"
          [disabled]="!projectId"

          #singleSelect
        >
          <mat-option
    
          >
          <!-- [formControl]="selectedemployee" -->
            <ngx-mat-select-search
            [disabled]="!projectId"
              (keyup)="empSearch($event, $event)"
      
              [(ngModel)]="selectedemployee"
              placeholderLabel="Search Employee"
           
         
             
            >
              <span  ngxMatSelectNoEntriesFound>
                No Employee Found
                <!-- <button mat-button color="primary">
                Add <mat-icon>add</mat-icon>
              </button> -->
              </span>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let emp of employeeProjects"
            [value]="emp?.employee.employeeId"
            (click)="empSelect(emp)"
          >
            {{ emp?.employee?.employeeNumber }} -
            {{ emp?.employee?.firstName }}
          </mat-option>
        </mat-select>
      </div>

      <div class="col-md-3 col-lg-3 col-xl-2 mt-sm-3 col-xl-2">
        <label class="form-control-label">Start Date</label>

        <div style="position: relative">
          <input
            placeholder="Start Date"
            readonly
            [matDatepicker]="picker1"
            class="form-control email-input select-placeholder text-truncate"
            style="
              height: 38px;
              padding: 2px;
              margin-left: -2px;
              background: #fff;
            "
            [(ngModel)]="startDate"
            type="text"
            [max]="today"
            (dateChange)="updateEndDateMin($event); checkStartDate()"
            (click)="this.search = ''"
            id="startDate"
          />
          <mat-datepicker-toggle
            matSuffix
            (click)="this.search = ''"
            [for]="picker1"
            style="
              float: right;
              position: relative;
              bottom: 41px;
              margin-right: -3px;
            "
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <span
            class="error_msg"
            *ngIf="startdate_required"
            style="position: absolute; top: 100%; left: 0; width: 100%"
            >Please&nbsp;select&nbsp;start&nbsp;date</span
          >
        </div>
      </div>
      <!-- </div> -->
      <div class="col-md-3 col-lg-3 col-xl-2 col-xl-2 mt-md-0 mt-n4">
        <label class="form-control-label">End Date</label>
        <div style="position: relative">
          <input
            placeholder="End Date"
            readonly
            [matDatepicker]="picker2"
            class="form-control email-input select-placeholder text-truncate;"
            style="
              height: 38px;
              padding: 2px;
              margin-left: -2px;
              background: #fff;
            "
            [(ngModel)]="endDate"
            type="text"
            [min]="startDate"
            [max]="today"
            (dateChange)="
              this.search = ''; checkStartDate(); updateEndDateMin($event)
            "
            (click)="this.search = ''; endSelectedFirst = true"
            id="endDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
            (click)="this.search = ''"
            style="
              float: right;
              position: relative;
              bottom: 42px;
              margin-right: -3px;
            "
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <span
            class="error_msg"
            *ngIf="enddate_required"
            style="position: absolute; top: 100%; left: 0; width: 100%"
            >Please&nbsp;select&nbsp;end&nbsp;date</span
          >
        </div>
      </div>
      <div class="col-md-6 col-lg-2 col-xl-2 button-align mt-xl-n3">
        <button
          type="button"
          mat-raised-button
          class="searchBtn mat-raised-button mat-button-base mr-2 custom-button"
          (click)="searchTimesheet()"
          [disabled]="!searchButton"
        >
          Search
        </button>
        <!-- <button
        type="button"
        class="btn mat-raised-button mat-button-base"
        (click)="searchTimesheet(); this.search = ''"
      >
        Search
      </button> -->
        <button
          type="button"
          mat-raised-button
          class="btn searchBtn"
          (click)="resetForms()"
          [disabled]="!searchButton"
          style="
            background: none;
            color: black;
            border: 1px solid #ddd;
            font-size: 16px;
            font-weight: 610;
          "
        >
          Clear
        </button>
        <button
          [disabled]="!approveDisabled"
          mat-button
          class="searchMenu ml-2"
          [matMenuTriggerFor]="menu"
          *ngIf="checkedCategoryList.length != 0"
        >
          <mat-icon
            style="
              vertical-align: middle;
              color: #ffffff !important;
              position: relative;
              top: 3px;
              margin-left: -12px;
            "
            >menu</mat-icon
          >
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="Approveall()"
            [disabled]="!approveDisabled"
          >
          <mat-icon class="edit-icon approve">check_circle</mat-icon>
            <span class="edit-form ">Approve</span>
          </button>
          <button mat-menu-item (click)="Denyall()" [disabled]="!denyDisabled">
            <mat-icon class="edit-icon deny">delete_forever</mat-icon>
            <span class="edit-form ">Deny</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <!-- *ngIf="EmpList.length > 0 && !nodata" -->
  <div class="card-content" *ngIf="tableShow">
    <!-- *ngIf="EmpList.length > 5" -->

    <div class="select-pagination "   *ngIf="EmpList.length > 5 && (EmpList | mytask : search).length > 5">
      <span>Show : </span>
      <select
        class="select_list new-select_list listDesign"
        [(ngModel)]="mySelect"
        (change)="changefirstpage()"
      >
        <option class="form-control" value="5">5</option>
        <option class="form-control" value="10">10</option>
        <option class="form-control" value="15">15</option>
        <option class="form-control" value="20">20</option>
        <option class="form-control" value="25">25</option>
      </select>
    </div>
    <div class="card-body table-responsive">
      <!-- <div
        class="row m-0"
        *ngIf="EmpList.length > 5 && (EmpList | mytask : search).length > 0"
      > -->
        <!-- <div class="col-md-10"></div> -->
        <!-- <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 text-right" *ngIf="(EmpList | mytask : search).length > 5"> -->
     
        <!-- </div> -->
      <!-- </div> -->
        <table class="table" matSort (matSortChange)="sortData($event)">
          <thead>
            <tr>
              <th class="text-center">
                <input
                  type="checkbox"
                  [(ngModel)]="isMasterSel"
                  name="list_name"
                  value="h1"
                  [disabled]="
                    EmpList.length === 0 ||
                    (EmpList | mytask : search).length === 0 ||
                    !showMenu
                  "
                  (change)="checkUncheckAll()"
                  style="height: 12px; width: 12px; font-weight: 600"
                />
                All
              </th>
              <th mat-sort-header="employeeName" scope="col">
                Employee&nbsp;Name
              </th>
              <th mat-sort-header="projectName" scope="col">
                Project&nbsp;Name
              </th>
              <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
              <th mat-sort-header="date" scope="col" style="text-align: center">
                &nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;
              </th>
              <th
                mat-sort-header="workedHours"
                scope="col"
                style="text-align: center"
              >
                Worked&nbsp;Hours
              </th>
              <th
                mat-sort-header="estimatedHours"
                scope="col"
                style="text-align: center"
              >
                Estimated&nbsp;Hours
              </th>
              <th
                mat-sort-header="taskStatusName"
                scope="col"
                style="text-align: center"
              >
                Task&nbsp;Status
              </th>
              <th
                mat-sort-header="approvalStatus"
                scope="col"
                style="text-align: center"
              >
                Approval&nbsp;Status
              </th>
              <th scope="col" style="text-align: center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let e of EmpList
                  | mytask : search
                  | paginate : { itemsPerPage: mySelect, currentPage: p };
                let i = index
              "
            >
              <!-- *ngIf="e.approvals.approver.fyistatus == true" -->
              <td
                *ngIf="e?.approvals?.approver?.fyistatus == true"
                class="text-center"
              >
                --
              </td>
              <!-- *ngIf="e.approvals.approver.fyistatus == undefined" -->
              <td
                class="text-center"
                *ngIf="e?.approvals?.approver?.fyistatus == undefined"
              >
                <input
                  [disabled]="!showMenu"
                  type="checkbox"
                  value="{{ e.taskDetailId }}"
                  [(ngModel)]="e.isChecked"
                  (change)="isAllSelected()"
                  style="height: 12px; width: 12px; margin: 0px 15px"
                  [checked]="selected === e.checked"
                />
              </td>
              <!-- <td style="text-align: center;" *ngIf="p"  >{{((p-1)*10)+(i+1)}}</td>
            <td  style="text-align: center;" *ngIf="!p">{{i+1}}</td> -->
              <td>
                {{ e?.employee?.firstName | titlecase }}
              </td>
              <td
                style="
                  white-space: normal;
                  overflow-wrap: break-word;
                  word-break: normal;
                  min-width: 150px !important;
                "
              >
                {{ e?.project?.projectName | titlecase }}
              </td>
              <td
                style="
                  white-space: normal;
                  overflow-wrap: break-word;
                  word-break: normal;
                  min-width: 250px !important;
                "
              >
                {{ e?.task?.taskName | titlecase }}
              </td>
              <td style="min-width: 150px !important">{{ e?.date }}</td>
              <td style="text-align: center; padding-right: 30px">
                {{ e?.workedHours }}
              </td>
              <!-- <td class="text-center">
              <ng-container *ngIf="!e?.task?.estimatedHours; else showTaskCode">{{
                "-"
              }}</ng-container>
              <ng-template >{{
                e?.task?.estimatedHours
              }}</ng-template>
            </td> -->
              <td class="text-center">
                {{ getTotalEstimatedTime(e.task.empolyeeTask) }}
              </td>
              <td
                [style.color]="withDrawStatus(e)"
                class="status"
                style="padding-right: 30px"
              >
                {{ e?.task?.taskStatusName }}
              </td>
              <td [style.color]="approvalStatus(e)" class="status">
                {{ e?.approvalStatusName }}
              </td>
              <td class="more" align="center">
                <span
                  class="material-icons"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  more_vert
                </span>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="description(e)">
                    <mat-icon class="edit-icon">date_range</mat-icon>
                    <span class="edit-form">View Description</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="Approve(e)"
                    *ngIf="e?.approvals?.approver?.fyi != empId"
                    [disabled]="!approveDisabled"
                  >
                    <!-- <mat-icon class="edit-icon">create</mat-icon> -->
                    <mat-icon class="edit-icon approve">check_circle</mat-icon>
                    <span class="edit-form ">Approve </span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="Deny(e)"
                    [disabled]="!denyDisabled"
                    *ngIf="e?.approvals?.approver?.fyi != empId"
                  >
                    <mat-icon class="edit-icon deny">delete_forever</mat-icon>
                    <span class="edit-form">Deny</span>
                  </button>
                </mat-menu>
              </td>
              <td class="more" align="center" *ngIf="e?.fyistatus == true">
                <span
                  class="material-icons"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  more_vert
                </span>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="description(e)">
                    <mat-icon class="edit-icon">date_range</mat-icon>
                    <span class="edit-form">View Description</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
            <!-- <tr *ngIf="(EmpList | mytask : search).length === 0">
            <td colspan="/* number of columns */" class="searchnodataDesign">
              No search data found
            </td>
          </tr> -->
            <td
              style="text-align: center; font-size: larger; font-weight: bold"
              colspan="10"
              *ngIf="nodata || (EmpList | mytask : search).length === 0"
            >
              Manage Timesheet Not Found!
            </td>
          </tbody>
        </table>
     
      <!-- <div
        *ngIf="(EmpList | mytask : search).length === 0"
        class="no-data text-center"
      >
        <span class=""  colspan="/* number of columns */"
        style="
          text-align: center;
          position: relative;
        
          font-size: 19px;
          font-weight: 410;
        ">Manage Timesheet Not Found </span>
      </div> -->
      <!-- <div
        class="pagination-content"
        *ngIf="EmpList.length > 5 && (EmpList | mytask : search).length > 0"
        id="page"
      >
       
        <pagination-controls
          class="text-right"
          style="float: right"
          (pageChange)="p = $event; beforesearchpage = $event"
          align="end"
        ></pagination-controls>
      </div> -->
      <div
        class="pagination-content"
        *ngIf="EmpList.length > 5 && (EmpList | mytask : search).length > 0"
        id="page"
      >
        <pagination-controls
          *ngIf="(EmpList | mytask : search).length > 5"
          style="position: relative; float: inline-end"
          (pageChange)="p = $event"
          align="end"
        ></pagination-controls>
      </div>
      <!-- </ng-container> -->
    </div>
  </div>
  <!-- 
  <div class="card-content" *ngIf="nodata">
    <div class="card-body">
      <div class="alert alert-primary">
        <h4
          align="center"
          style="
            font-family: Mulish, sans-serif;
            color: #424242;
            font-size: 20px;
          "
        >
          Manage Timesheet Not Found
        </h4>
      </div>
    </div>
  </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        <h4 align="center" class="h4">Manage Timesheet Report Not Found</h4>
      </div>
    </div>
  </mat-card> -->
  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
  </div>
</mat-card>

<div class="text-center successUpdate" *ngIf="success" #successMessageElement>
  <div class="alert alert-success toast-msg successUpdate" role="alert">
    <strong>{{ successMessage }}</strong
    ><br />
  </div>
</div>
