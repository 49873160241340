<!-- <div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Dashboard</span>
            </div>
            <div class="sub-header-buttons">
                 <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                    Add Employee
                </a> -->
<!-- </div>
        </div>
    </div>
</div> -->


<body class="admin-dashboard">
    <!-- <div class="dashboard-title">
        <h2>DASHBOARD</h2>
    </div> -->
    <div class="card">
        <div class="col-md-12">
            <div class="recruitercard-123">
                <form [formGroup]="recruiterSearchform" (ngSubmit)="submitData()">
                    <div class="row">
                        <div class="col-md-12 px-4">
                            <h1 style="    margin-top: 20px;
                            font-family: 'Mulish', sans-serif;
                            font-weight: 770;">Summary</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row px-2 ">


                                <div class="col-xl-3 col-lg-3 " >
                                    <!-- <label class="form-control-label">From Date</label> -->
                                    <input matInput [matDatepicker]="picker1" [max]="startmaxdate"
                                        formControlName="fromdate" (dateChange)="startdate()" readonly="readonly"
                                        class="form-control email-input select-placeholder no-border-on-focus" placeholder="Select From Date"
                                        style="width: 100%; height: 40px;">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"
                                        style="float: right;position: relative;bottom:40px;"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </div>
                                <div class="col-xl-3 col-lg-3">
                                    <!-- <label class="form-control-label">To Date</label> -->
                                    <input matInput [matDatepicker]="picker2" [min]="endmindate"
                                        formControlName="todate" class="form-control email-input select-placeholder no-border-on-focus" readonly="readonly"
                                        placeholder="Select To Date" style="width: 100%; height: 40px;">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"
                                        style="float: right;position: relative;bottom: 40px;"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </div>
                                <div class="col-xl-3 col-lg-3">
                                    <!-- <label class="form-control-label">Recruiter</label> -->
                                    <!-- <mat-select formControlName="recruiter"
                                        class="form-control email-input select-placeholder no-border-on-focus" appearance="fill"
                                        placeholder="Select Recruiter" style="width: 100; height: 40px;">
                                        <mat-option class="text-color" [value]=""> -- Select --</mat-option>
                                        <mat-option class="text-color" *ngFor="let recruiter of recruiterlist"
                                            [value]="recruiter.employeeId">{{recruiter.firstName}}
                                            {{recruiter.lastName}}</mat-option>

                                    </mat-select> -->
                                    <mat-select
                                    id="framework" style="height: 38px;"
                                    class="form-control email-input select-placeholder no-border-on-focus"
                                    formControlName="recruiter" 
                                    appearance="fill"
                                    placeholder="Select Recruiter" 
                                     
                                  >
                                    <mat-option [value]="">-Select-</mat-option>
                                    <!-- <mat-option [value]=0> In-House</mat-option> -->
    
                                    
                                    <mat-option
                                    *ngFor="let recruiter of recruiterlist"
                                    [value]="recruiter?.user?.userId">
                                    {{recruiter.firstName}}
                                    {{recruiter.lastName}}           
                                  </mat-option>
                                  </mat-select>
                                </div>
                                <div class="col-xl-3 col-lg-3">
                                    <!-- <button type="submit" class="btn search-btn searchBtn"
                                        style="background-color:#1A83FF; padding: 10px;" [disabled]="isbtnDisable">
                                        <span style="font-size: 15px; color: white; margin-left: 3px;">Search</span>
                                    </button>
                                    <button type="button" class="btn  search-btn" [disabled]="isbtnDisable"  mat-raised-button 
                                        style="margin-left: 5px; padding: 10px;    background: none;
                                        color: black;
                                        border: 1px solid #ddd !important;" (click)="clearfilter()">
                                         Clear
                                    </button>
                                    <button type="button" (click)="exportToExcel()" class="btn btn-primary export-btn"
                                        style="background-color: #0EAB00; margin-left: 5px;">
                                        <mat-icon class="material-icons-outlined export-icon">file_download</mat-icon>
                                    </button> -->
                                    <button type="submit" mat-raised-button  class=" search-btn searchBtn"
                                    [disabled]="isbtnDisable" style="border: 0px !important;background:#1a83ff;">
                                    Search
                                  </button>
                                  <button style="
                                      margin-left: 10px;
                                      background: none;
                                      color: black;
                                      border: 1px solid #ddd !important;
                                    " type="button" mat-raised-button class="btn search-btn mr-2" (click)="clearfilter()"
                                    [disabled]="isbtnDisable">
                                    Clear
                                  </button>
                                  <button type="button" (click)="exportToExcel()" class="btn btn-primary export-btn"
                                  style=" margin-left: 5px;">
                                  <mat-icon class="material-icons-outlined export-icon">file_download</mat-icon>
                              </button> 



                                </div>
                                <div  *ngIf="loader"  class="align-items-center text-center">
                                    <div class="spinner-border table-spinner" role="status" style="height: 3rem;
                                    width: 3rem;
                                    color:#1a83ff;

                                    top: -2%;
                                    left: -3% !important;"></div>
                                </div>


                                <!-- <div class="col-xl-1 col-lg-1">
                                    <button type="button" class="btn btn-primary search-btn" style="background-color: #FF5057;" (click)="clearfilter()">Clear</button>
                             </div> 
                            <div class="col-xl-1 col-lg-1">
                                <button type="button" (click)="exportToExcel()" class="btn btn-primary export-btn" style="background-color: #0EAB00;">
                                    <mat-icon class="material-icons-outlined export-icon">file_download</mat-icon>
                                </button>
                            </div>  -->
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-12" *ngIf="hideAdminDashboardDesign">
            <div class="dashboard-cards">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="card status-card">
                            <div class="card-body status-card-body">
                                <div class="row" (click)="resourceList(admindashboard.profilesource)">
                                    <div class="col">
                                        <h2 class="card-category pb-0">Profile Shared</h2>
                                        <h2 class="card-title pb-0">{{admindashboard.profilesource}}</h2>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon-card" style="background-color: #73C854">
                                            <img src="assets/uploads/Profile Shared.png" width="20px" height="20px ">
                                            <!-- <mat-icon  aria-hidden="false" aria-label="Example group" class="material-icons-round admin-dashboard-icons">source</mat-icon> -->
                                            <!-- <mat-icon routerLink="/home/resource-list" aria-hidden="false" aria-label="Example group" class="material-icons-round admin-dashboard-icons">source</mat-icon> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="card status-card">
                            <div class="card-body status-card-body">
                                <div class="row" (click)="interviewedList(admindashboard.interviewed)">
                                    <div class="col">
                                        <h2 class="card-category pb-0">Interviewed</h2>
                                        <h2 class="card-title pb-0">{{admindashboard.interviewed}}</h2>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon-card" style="background-color: #9747FF">
                                            <img src="assets/uploads/Interview.png" width="20px" height="20px ">

                                            <!-- <mat-icon   aria-hidden="false" aria-label="Example groups" class="material-icons-round admin-dashboard-icons">check_circle_outline</mat-icon> -->
                                            <!-- <mat-icon  routerLink="/home/resource-list/true" aria-hidden="false" aria-label="Example groups" class="material-icons-round admin-dashboard-icons">check_circle_outline</mat-icon> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="card status-card">
                            <div class="card-body status-card-body">
                                <div class="row" (click)="notInterviewedList(admindashboard.notInterviewed)">
                                    <div class="col">
                                        <h2 class="card-category pb-0">Not Interviewed</h2>
                                        <h2 class="card-title pb-0">{{admindashboard.notInterviewed}}</h2>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon-card" style="background-color:#FF5D5D">
                                            <img src="assets/uploads/Non Interview.png" width="20px" height="20px ">

                                            <!-- <mat-icon  aria-hidden="false" aria-label="Example volunteer_activism" class="material-icons-round admin-dashboard-icons">highlight_off</mat-icon> -->
                                            <!-- <mat-icon routerLink="/home/resource-list/false" aria-hidden="false" aria-label="Example volunteer_activism" class="material-icons-round admin-dashboard-icons">highlight_off</mat-icon> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-3 col-lg-6">
                         <div class="card status-card">                        
                             <div class="card-body status-card-body">
                                 <div class="row">
                                     <div class="col">
                                         <p class="card-category mr-2 pb-0">On Hold</p>
                                         <h2 class="card-title pb-0">34</h2>
                                     </div>
                                     <div class="col-auto"> 
                                         <div class="icon-card bg-warning">
                                             <mat-icon aria-hidden="false" aria-label="Example volunteer_activism" class="material-icons-round admin-dashboard-icons">do_disturb</mat-icon>
                                         </div>   
                                     </div>
                                 </div>
                             </div>
                         </div>                                   
                     </div> -->
                </div>
            </div>
        </div>
    </div>



</body>

<!-- <div *ngIf="loader" class="align-items-center text-center" style="position: relative;z-index: 99;height: 0;">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div> -->
<div class="piechart-bg" *ngIf="hideAdminDashboardDesign">
    <div class="row m-0 p-0">
        <!-------------- pie chart --------------------------------->

        <div class="col-xl-8">
            <div class="card piechart-card">
                <div class="card-body piechart-card-body">
                    <div class="header">
                        <h2 class="category" style="    /* margin-top: 20px; */
                        font-family: 'Mulish', sans-serif;
                        font-weight: 770;
                        font-size: 24px;">Resources Status</h2>
                    </div>
                    <div class="chart">
                        <canvas *ngIf="this.ProfileShared !=0" baseChart
                            style="color: white;width: 70%!important;height: 70%!important;" [data]="pieChartData_user"
                            [type]="pieChartType_user" [options]="pieChartOptions_user"
                            [plugins]="pieChartPlugins_user">
                        </canvas>
                        <p *ngIf="this.ProfileShared ==0" style="    font-size: 21px;
                        font-family: 'Mulish', sans-serif;
                        font-weight: 770;
                        position: relative;
                        color: black;
                        left: 28%;"> Resources Status Not Found</p>
                    </div>
                </div>
            </div>
        </div>
        <!-------------- Interviewed Status table --------------------------------->

        <div class="col-xl-4">
            <div class="card table-card">
                <div class="card-title table-title border-0 m-0">
                    <h2 style="      /* margin-top: 20px; */
                    font-family: 'Mulish', sans-serif;
                    font-weight: 770;
                    font-size: 24px;
                    color: black;
                    font-size: 24px;">Interview Status</h2>
                    <!-- <h2 style="font-family: 'Mulish', sans-serif;
                    font-weight: 770;
                    font-size: 24px;
                    color: black;" >
           {{ titleName }}
         </h2> -->
         
                </div>
                <div class="table-responsive">
                    <table class="interviewed-status-table">
                        <thead style="background-color: #f6f9fc;">
                            <tr>
                                <th class="table-head1">Status</th>
                                <th class="text-center table-head2">Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="selected(selectedStatus,admindashboard.selected)">
                                <td class="table-data1 text-success">Selected</td>
                                <td class="text-center table-data2 text-success">{{admindashboard.selected}}</td>
                            </tr>
                            <tr (click)="rejected(rejectedStatus,admindashboard.rejected)">
                                <td class="table-data1 text-danger">Rejected</td>
                                <td class="text-center table-data2 text-danger">{{admindashboard.rejected}}</td>
                            </tr>
                            <tr (click)="onHold(onHoldStatus,admindashboard.onHold)">
                                <td class="table-data1" style="color:#ff9100">On Hold</td>
                                <td class="text-center table-data2" style="color:#ff9100">{{admindashboard.onHold}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
    style="height: 527vh!important;"></div>


