import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';

@Component({
  selector: 'app-add-district',
  templateUrl: './add-district.component.html',
  styleUrls: ['./add-district.component.scss']
})
export class AddDistrictComponent implements OnInit {

  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  countryData:any;
  stateData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  employeetData:any;

  districtNameExistStatus:any;

  stateList : any;
  districtId:any;

  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddDistrictComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      country : this.formBuilder.group({
            countryId : ['',[Validators.required]]}),
      state : this.formBuilder.group({
            stateId : ['',[Validators.required]]}),
      districtName : ['' ,[Validators.required,Validators.pattern(/^[A-Za-z0-9_+-]*$/)]],
    });

    if(this.edit_data)
    {
      this.districtId = this.edit_data.districtId;
      this.settingsservice.getDistrictById(this.edit_data.districtId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          districtName: this.resultData.districtName,
          country: { countryId:this.resultData.country.countryId },
          state: { stateId:this.resultData.state.stateId }
        });
      });
    }
    this.getLovCountries();
    this.getLovStates();
  }

  getLovCountries()
  {console.log(this.countryData);
    this.settingsservice.getLovCountries().subscribe((result: any) => {
      this.countryData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

  getLovStates()
  {
    this.settingsservice.getLovStates().subscribe((result: any) => {
      this.stateList = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

  // convenience getter for easy access to form fields
  get validate() { return this.postForm.controls; }

  //Submit Form
  onSubmit() //form Submit Function
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.submitted = true;

    //stop here if form is invalid
    if (this.postForm.invalid || this.districtNameExistStatus == true) {return;}

    if(this.edit_data) //Edit
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['districtId'] = this.edit_data.districtId;
      postValues['districtStatus'] = this.edit_data.districtStatus;

      this.backdrop = true;
      this.loader=true;

      this.settingsservice.editDistrict(this.edit_data.districtId,postValues)
      .subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/department' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
    else //Create
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      /*if(this.postForm.valid && this.departCodeExistStatus == true || this.departNameExistStatus == true)
      {
        console.log("if condition")
        return
      }*/

      this.settingsservice.createDistrict(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
  }

  checkExistDistrictName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */

    if(this.postForm.value.districtName.length != 0)
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      if(this.edit_data)
      {
        postValues['districtId'] = this.edit_data.districtId;

        this.settingsservice.updateCheckExistDistrictName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.districtNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.districtNameExistStatus = true;
          }
        })
      }
      else
      {
        this.settingsservice.createCheckExistDistrictName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.districtNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.districtNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.districtNameExistStatus = false;
    }
  }

  countryChange(event)
  {
    let countryId = event.target.value;
    if(countryId)
    {
      this.settingsservice.getCountryAgainstStateList(countryId).subscribe((resultData:any) =>
      {
        if(resultData.statusCode)
        {
          this.stateList=[];
        }
        else
        {
          this.stateList=resultData;
        }
      });
    }
    else
    {
      this.stateList=[];
    }
  }

}
