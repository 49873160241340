import { AbstractControl, ValidatorFn } from '@angular/forms';

// Custom validator to prevent leading zeros (e.g., '0', '0123')
export function noLeadingZeroValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    // Check if the value is a single zero or starts with zero followed by other digits
    if (value && (value === '0' || /^0\d+/.test(value))) {
      return { notZero: true }; // Error key to trigger the validation
    }
    return null;
  };
}
