<!-- <section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Client Tax breakup</span>
                </div>
                <div class="sub-header-buttons">
                    <a  (click)="importEmployee()" mat-raised-button class="ml-2" color="primary" title="Import">
                       <mat-icon class="mat-sidebar-icon">publish</mat-icon> 
                        Import
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                        <div class="row">
                            <div class="form-group col-md-3" style="    position: relative;
    top: -22px;">
                                <!-- <label class="col-form-label">Client Name<span class="star"></span></label> -->
                                <!-- <select formControlName="filterclient" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]="null"> -- Select Client --- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select> -->
                                <label class="form-control-label">Client </label>

                                <!-- <mat-select
                                formControlName="filterclient"
                                placeholder="Select Client "
                                class="form-control email-input select-placeholder"
                              >
                                <mat-option [value]="undefined" selected="selected"
                                  >-Select Client -</mat-option
                                >
                                <mat-option
                                  *ngFor="let row of clientList; let i = index"
                                  value="{{ row.clientId }}"
                                  >{{ row.clientName }}
                                </mat-option>
                              </mat-select> -->
                              <input
                              type="text"
                             
                              placeholder="client Name"
                              formControlName="filterclient"
                              autocomplete="off"
                             readonly
                             [disabled]="true"
                             style="    background: #dddddd87 !important;"
                              [value]="clientNames"
                              class="form-control email-input select-placeholder"
                            />
                            </div>
                          
                            <div class="col-md-4">
                                <button type="" style="background: #1a83ff;color:#fff"  mat-raised-button  [disabled]="btnDisablesearch"
                                    class="btn  search-btn">Search</button>
                                <button style="margin-left: 10px;" type="button" mat-raised-button
                                    class="btn-clear search-btn mr-2" (click)="clearTable()"
                                    >Clear</button>
                            </div>
                            <!-- <div class="form-group col-md-1" *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" style="position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary" 
                                    class="btn btn-primary search-btn">Export</button>
                            </div> -->
                        </div>
                    </form>
                    <div  class="col-md-12 sideDesign"><b >Manage Client Tax</b></div>
                        <form [formGroup]="clienttaxbreakup" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Client Name<span class="star">*</span></label> 
                                    <!-- <select formControlName="client"   class="form-select form-select-solid form-select-lg select2-new">
                                        <option [ngValue]="null"> --- Select Client --- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>     -->

                                    <!-- <mat-select
                                    formControlName="client"
                                    placeholder="Select Client "
                                    class="form-control email-input select-placeholder"
                                  >
                                    <mat-option [value]="undefined" selected="selected"
                                      >-Select Client -</mat-option
                                    >
                                    <mat-option
                                      *ngFor="let row of clientList; let i = index"
                                      [value]="row.clientId"
                                      >{{ row.clientName }}
                                    </mat-option>
                                  </mat-select> -->
                                  <input
                                  type="text"
                                 
                                  placeholder="client Name"
                                  formControlName="client"
                                  autocomplete="off"
                                 readonly
                                 [disabled]="true"
                                 style="    background: #dddddd87 !important;"
                                  [value]="clientNames"
                                  class="form-control email-input select-placeholder"
                                />

                                    <!-- <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                        <span *ngIf="validate.client.errors?.required" class="text-danger">Please Select Client</span> -->
                                        <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    <!-- </div> -->
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Salary Start Range<span class="star"> *</span></label>
                                    <input type="text" placeholder="Enter Salary Start Range"  (keypress)="onlyNumber($event)"
                                    formControlName="salaryStartRange"
                                    class="form-control form-control-solid form-control-lg select2-new">
                                    <div class="valid-msg" *ngIf="(validate.salaryStartRange.invalid && validate.salaryStartRange.touched) || validate.salaryStartRange.dirty || submitted">
                                        <span *ngIf="validate.salaryStartRange.errors?.required" class="text-danger">Please Salary Start Range</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Salary End Range<span class="star"> *</span></label>
                                    <input type="text" placeholder="Enter Salary Start Range"  (keypress)="onlyNumber($event)"
                                    formControlName="salaryEndRange"
                                    class="form-control form-control-solid form-control-lg select2-new">
                                    <div class="valid-msg" *ngIf="(validate.salaryEndRange.invalid && validate.salaryEndRange.touched) || validate.salaryEndRange.dirty || submitted">
                                        <span *ngIf="validate.salaryEndRange.errors?.required" class="text-danger">Please Salary End Range</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label" style="font-family: 'Mulish', sans-serif;">Tax Amount<span class="star"> *</span></label>
                                    <input type="text" placeholder="Enter Tax Amount"  (keypress)="onlyNumber($event)"
                                               formControlName="taxAmount"
                                               class="form-control form-control-solid form-control-lg select2-new">
                                    <div class="valid-msg" *ngIf="(validate.taxAmount.invalid && validate.taxAmount.touched) || validate.taxAmount.dirty || submitted">
                                        <span *ngIf="validate.taxAmount.errors?.required" class="text-danger">Please Enter Working Days</span>
                                        <span *ngIf="validate.taxAmount.errors?.pattern" class="text-danger">Enter Vaild Working Days </span>
                                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row pb-3">
                                <div class="col-md-12 text-right">
                                        <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="margin-right: 20px;">Clear</button>
                                        <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                            >Save
                                        </button>
                                </div>
                            </div> -->
                            <div class="row pb-3">
                            <div class="col-md-12 text-right">
                                <button type="submit" mat-raised-button [disabled]="btndisable  || clienttaxbreakup.invalid"
                                    class="btn  search-btn" style="    background: #1a83ff;
    color: #ffff;">Save</button>
                                <button style="margin-left: 10px;" type="button" mat-raised-button
                                    class="btn-clear search-btn mr-2" (click)="clearForm()"
                                      >Clear</button>
                            </div>
                            </div>
                        </form>
                            <!-- <div class="row pb-3" *ngIf="resultData?.length > 0 ">
                                <div class="col-md-11 text-right">
                                    <p class="show-count">Show</p>
                                </div>
                                <div class="col-md-1 text-left pl-0">
                                    <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        -->
                        <!-- <div class="row p-0 m-0">
                            <div
                              class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9 text-right"
                            ></div>
              
                            <div
                              class="col-xl-2 col-lg-3 col-md-3 col-sm-4 text-right"
                              *ngIf="resultData.length > 5"
                            >
                              <div class="select-pagination">
                                <span>Show : </span>
                                <select
                                  class="select_list new-select_list listDesign"
                                  [(ngModel)]="mySelect"
                                  (change)="changefirstpage()"
                                >
                                  <option class="form-control" value="5">5</option>
                                  <option class="form-control" value="10">10</option>
                                  <option class="form-control" value="15">15</option>
                                  <option class="form-control" value="20">20</option>
                                  <option class="form-control" value="25">25</option>
                                </select>
                              </div>
                            </div>
                          </div> -->
                          <div
                          class="row p-0 m-0"
                          *ngIf="
                            filterData.length > 5 &&
                            
                            tableShow
                          "
                        >
                          <div
                            class="col-xl-12 col-lg-12"
                            style="margin-bottom: 12px"
                            
                          >
                            <div class="select-pagination" style="float: right;    position: relative;
    top: 17px;">
                              <span>Show : </span>
                              <select
                                class="select_list new-select_list listDesign"
                                [(ngModel)]="pagePerItem"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="changefirstpage()"
                              >
                                <option class="form-control" value="5">5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                              </select>
                            </div>
                          </div>
                        </div>
                       <div class="table"  *ngIf="tableShow">
                        <!-- <p style="font-family: 'Mulish', sans-serif;"><b>CLIENT WORKING DAYS</b></p> -->
                        <div  class="col-md-12 sideDesign"><b >Client Tax Breakup</b></div>

                        <table class="table">
                            <thead class="t-head">
                                <tr>
                                    <th style="border-radius: 15px 1px 0px 0;border-top: 0px;">Action</th>
                                    <th>Client Code</th>
                                    <th>Client Name</th>
                                    <th>Salary Start Range</th>
                                    <th>Salary End Range</th>
                                    <th>Tax Amount</th>
                                    <th style="border-radius: 0px 15px 0px 0;border-top: 0px;">Status</th>
                                </tr>
                            </thead>
                             <tbody  style="    border-left: 1px solid #ddd;
                             border-bottom: 1px solid #ddd;
                             border-right: 1px solid #ddd;">
                                <tr *ngFor="let row of filterData | paginate: { itemsPerPage: pagePerItem, currentPage: p } let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item (click)="updateform(row)"  >
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                            <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button> -->
                                            <button mat-menu-item *ngIf="row.taxBreakageStatus.listTypeValueId==1" (click)="updateStatus(row.taxBreakageId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.taxBreakageStatus.listTypeValueId==2" (click)="updateStatus(row.taxBreakageId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                    <td>{{row.client.clientCode}}</td>
                                    <td>{{row.client.clientName}}</td>
                                    <td>{{row.salaryStartRange}}</td>
                                    <td>{{row.salaryEndRange}}</td>
                                    <td>{{row.taxAmount}}</td>
                                    <!-- <td>{{row.endDate | date :dateFormat}}</td>
                                    <td class="text-center">{{row.ratePerDay}}</td>
                                    <td class="text-center" >{{row.ratePerMonth}}</td> -->
                                    <td class="active-status text-center">
                                        <span class="btn  btn-sm btn-block" *ngIf="row.taxBreakageStatus.listTypeValueId==1 " style="color:green;">
                                            {{row.taxBreakageStatus.listTypeValueName}}
                                        </span>
        
                                        <span class="btn  btn-sm btn-block" *ngIf="row.taxBreakageStatus.listTypeValueId==2" style="color:red;">
                                            {{row.taxBreakageStatus.listTypeValueName}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody> 
                            <!-- <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="7" *ngIf="noRecordsFound">No Records Found!</td>
                                </tr>
                            </tbody> -->
                            <tbody
                            style="
                              border-left: 1px solid #ddd;
                              border-right: 1px solid #ddd;
                            "
                          >
                            <tr>
                              <td
                                style="
                                  text-align: center;
                                  padding: 10px;
                                  font-size: 19px;
                                  font-weight: 600;
                                  font-family: 'Mulish', sans-serif;
                                  left: 5;
                                  position: relative;
                                "
                                colspan="7"
                                *ngIf="noRecordsFound || filterData.length==0"
                              >
                                 Client Tax Breakup Records Not Found!
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                        class="pagination-content"
                        *ngIf="filterData.length >5"
                        id="page"
                      >
                        <pagination-controls
                          *ngIf="filterData.length >5"
                          class="text-right"
                          style="float: right"
                          (pageChange)="p = $event; beforesearchpage = $event"
                          align="end"
                        ></pagination-controls>
                      </div>
                     </div>
                    
                    <!-- <div *ngIf="nodatafound" class="no-data"> -->
                        <!-- <img src="assets/uploads/nodata.png"> -->
                        <!-- <span class="no-data-found"> No Data Found </span> -->
                    <!-- </div> -->
                     <!-- <div *ngIf="resultData?.length > 0 ">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>  -->
                    
            </div><div *ngIf="loader" class="align-items-cEnter text-cEnter">
                <div
                  class="spinner-border"
                  style="    height: 3rem;
                  width: 3rem;
                  color: #009ef7;
                  position: absolute;
                  z-index: 1;
                  top: 250px !important;
                  right: 48%;"
                  role="status"
                ></div>
              </div>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>