import { Component, Inject, OnInit } from '@angular/core';
import { PayrollService } from '../providers/payroll.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router ,ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from '../showerror/showerror.component';

@Component({
  selector: 'app-employee-groups',
  templateUrl: './employee-groups.component.html',
  styleUrls: ['./employee-groups.component.scss']
})
export class EmployeeGroupsComponent implements OnInit {
  usertData: any;
  orgId: any;
  clientList: any;
  postForm: FormGroup;
  employeegroupId: any;
  sucess_msg: any;
  success: boolean;
  error: boolean;
  error_msg: any;
  employeegroupnameexist: boolean = false;
  existMsg: string;
  p:number = 1;
  nodatafound: boolean = false;
  pagePerItem:any=10;
  submitted: boolean;
  selectedEmployeeData: any = [];

  dateFormat: any;
  empList: any = [];
  errormessagejson: any;
  errorMsg: any;
  importerror: boolean;
  employeegroupdata: any;

  constructor( private payrollservice: PayrollService,
    private formBuilder: FormBuilder,
    private router : Router, @Inject(AppComponent) public AppData: any ,private dialog:MatDialog, private ActivatedRoute:ActivatedRoute) { }

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.dateFormat = localStorage.getItem('dateFormatName');
    console.log(this.orgId);
    
    
    this.employeegroupId = this.ActivatedRoute.snapshot.params.employeeGroupId;

    this.postForm = this.formBuilder.group({
      assignmentType: [null],
      client : [null,[Validators.required]],
      groupName : [null,[Validators.required]],
      groupDescription : [null],
       
     });

       if(this.employeegroupId)
       {
        this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
            this.payrollservice.getEmployeegroupsById(this.employeegroupId).subscribe((resultData: any) =>
            {
                this.employeegroupdata = resultData;
                 this.postForm.patchValue({
                  client : this.employeegroupdata.client.clientId,
                  groupName : this.employeegroupdata.employeeGroupName,
                  groupDescription :  this.employeegroupdata.employeeGroupDescription
                 })

                 this.empList = this.employeegroupdata.employees;

                 this.empList.map( (e) => {
                  e["checked"] = true;
                 })
                 this.getemployees();
            })
            this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
            console.log(this.clientList);
          })    
       }else{
            this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
              this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
              console.log(this.clientList);
            })    
       }
  }
 
  get validate() { return this.postForm.controls; }


  pageChages()
{
  this.p=1;
}

// changeassingment()
// {
//   if(this.postForm.value.assignmentType == 0)
//    {
//     this.selectedEmployeeData.length = 0;
//      this.s
//    }else{

//    }
// }

getemployees()
{
       
  //  if(this.postForm.value.assignmentType == 1)
  //  {
        
    this.payrollservice.getAllEmployeeClientList(this.postForm.value.client).subscribe((result: any) => {
      if(result.statusCode)
      {
         this.nodatafound = true;
         this.empList = [];
      }else{
        this.nodatafound = false;
          result;
          result.forEach(element => {
           let  hasemployee =   this.empList.some(e=> e.employeeId == element.employee.employeeId);
           if(!hasemployee)
           {
             element.employee["checked"] = false;
             this.empList.push(element.employee);
           }
          });
    
      }
   
   })

  //  }
 

}

selectemployee(employee , event)
{

  console.log(event.target.checked);
   return
  let  hasemployee =   this.empList.some(e=> e.employeeId == employee.employeeId);
  if(!hasemployee)
  {
    this.selectedEmployeeData.push(employee);
  }
}



validateemployeegroupname(event)
  {
   if(event.target.value.length !=0 && this.postForm.value.client)
   {
        
    let fata ;
       if(this.employeegroupId){

        
              fata = {
              employeeGroupId: this.employeegroupId,
              employeeGroupName : event.target.value,
              client : {clientId: this.postForm.value.client},             
            }
       }else{
            fata = {
              employeeGroupName : event.target.value,
              client : {clientId: this.postForm.value.client},             
            }
       }

       this.payrollservice.validateemployeegroupname(fata).subscribe((result:any) =>
       {
          
         if(result.employeeGroupNameexist == false)
         {
            this.employeegroupnameexist = false;
         }
         else
         {
           this.existMsg = "Group name already exist"
           this.employeegroupnameexist = true;
         }
       })
   }else{
    this.employeegroupnameexist = false;
   }
  }

  clearemployeelist()
  {
    this.selectedEmployeeData =  [];
  }
  
  onSubmit()
  {
    //alert("form submitted");
    this.selectedEmployeeData = [];
    this.submitted = true;
   const controls = this.postForm.controls;
   console.log( this.empList)

   this.selectedEmployeeData = this.empList.filter(item => item.checked == true);
    console.log(this.selectedEmployeeData)
  
  console.log("postForm" + this.postForm.invalid)
  
     if(this.employeegroupId){
      if (this.postForm.invalid  || this.selectedEmployeeData.length == 0  || this.employeegroupnameexist) {
        console.log("invalid")
          console.log(controls);
          for (const name in controls) {
              if (controls[name].invalid) {
                console.log(name);
              }
          } 
          return;
      }
     }else{
          if (this.postForm.invalid || this.selectedEmployeeData.length == 0 || this.employeegroupnameexist) {
            console.log("invalid")
              console.log(controls);
              for (const name in controls) {
                  if (controls[name].invalid) {
                    console.log(name);
                  }
              } 
              return;
          }
     }

 

    if(this.employeegroupId) //Update Shiftcalendar
    {
      
      let postValues = this.postForm.value;
      //  let  employeeGroupDataList = [];
     
           console.log(postValues);
          
            let employeeGroupData = 
            {
              employeeGroupId : this.employeegroupId,
              employeeGroupName :  postValues.groupName,
              employeeGroupDescription:postValues.groupDescription,
                client : {
                  clientId: postValues.client
                  },
                  
              employees :  this.selectedEmployeeData 
            };
          
        
          

             //return
      this. payrollservice.employeegroupUpdate(this.employeegroupId,employeeGroupData).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);
          this.postForm.reset();     
          this.submitted = false;
          setTimeout(() => {
            this.router.navigate(['/home/employee-groups-list']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
           setTimeout(() => {this.error = false}, redirectMsgTimeOut)

        //   setTimeout(() => {
        // //    this.finicalyearList();
        //   }, redirectMsgTimeOut)
        }

      }, err =>
      {
       
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.message;
            // this.router.navigate(['/home/financialyear']);
          }, redirectMsgTimeOut)
      })
    }
    else  //Add employeegroup
    {
        let postValues = this.postForm.value;
       
     
           console.log(postValues);
           let employeeGroupData = 
            {
              employeeGroupName :  postValues.groupName,
              employeeGroupDescription:postValues.groupDescription,
                client : {
                  clientId: postValues.client
                  },
                  
              employees :  this.selectedEmployeeData 
            };
           

             console.log(employeeGroupData);
          
            
      this. payrollservice.employeegroupCreate(employeeGroupData).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;      
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);
          this.postForm.reset();     
          this.submitted = false;
          setTimeout(() => {
            this.router.navigate(['/home/employee-groups-list']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.description;
          this.errormessagejson = result;
          this.importerror = true;
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)

        //   setTimeout(() => {
        // //    this.finicalyearList();
        //   }, redirectMsgTimeOut)
        }

      }, err =>
      {
       
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.message;
            // this.router.navigate(['/home/financialyear']);
          }, redirectMsgTimeOut)
      })
    }


  }

  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
}
