import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TimeSettingsFormComponent} from '../time-settings-form/time-settings-form.component'
import { LeaveService } from 'src/app/providers/leave.service';
import { EmpPermissionSettingComponent } from '../emp-permission-setting/emp-permission-setting.component';
import { HeaderService } from 'src/app/providers/header.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AddrecordsettingComponent } from 'src/app/addrecordsetting/addrecordsetting.component';
import { Subscription } from 'rxjs';
import { LeavemessageService } from 'src/app/leavemessage.service';

@Component({
  selector: 'app-employee-time-settings',
  templateUrl: './employee-time-settings.component.html',
  styleUrls: ['./employee-time-settings.component.scss']
})
export class EmployeeTimeSettingsComponent implements OnInit {
  loader:boolean=true;
  usertData: any;
  organizationId:any;
  settingslist : any = [];
tableShow: boolean=false;
 selectedSetting: string = ''; // Holds the current radio button selection
  minTime: string = '';
  selectedOption: string = '';
  maxTime: string = '';
  pasteCount: any;
  isSubmitted: boolean = false;

  timesettingsForm:FormGroup;
  permissionsettingsForm:FormGroup;
  lastValidValue: string;
isDisabled: any;
isLoading:any = false;
  orgId: any;
  nodata:boolean=false;
success: any;
success_msg: any;
error_msg: any;
error: any;
  private successMessageSubscription: Subscription;
  private errorMessageSubscription: Subscription;
  constructor(private leaveservice : LeaveService,
    private fb: FormBuilder,
    private httpClient:HttpClient,
    private router:Router,
    private dialog:MatDialog,
    private headerservice: HeaderService,
        private leavemessage :LeavemessageService
    
  ) { }
  ngOnDestroy() {
    console.log('calling ngOnDestroy');
   
    this.success = false;
    this.success_msg = '';
    this.error=false;
    this.error_msg=''
    this.leavemessage.setErrorMessage('');
    this.leavemessage.setSuccessMessage('');
    if (this.successMessageSubscription) {
      this.successMessageSubscription.unsubscribe();
    }
    if (this.errorMessageSubscription) {
      this.errorMessageSubscription.unsubscribe();
    }
 
  }
  ngOnInit(): void {
    // this.headerservice.setTitle('Record Timings Settings')
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    console.log(userId);
    this.organizationId = this.usertData.organization.organizationId;
    console.log(this.organizationId);
    this.headerservice.setTitle('');

    this.tableShow=false
    this.successMessageSubscription=this.leavemessage

    .getSuccessMessage().subscribe((message)=>{
      if (message) {
        this.success = true;
        this.success_msg = message;
        this.loader=false;
        this.leaveservice.getsettings(this.organizationId).subscribe
        ((data:any)=>{
          this.settingslist = data;
          console.log(this.settingslist.length);
          this.tableShow=true;
          this.loader = false;
         console.log(this.settingslist)
         if(this.settingslist.statusCode==204){
          console.log("204")
          this.nodata=true;
         }
          if( this.settingslist.length === undefined){
            console.log("undefined")
            this.tableShow=false;
    
          }
        })

      // Close the success message after 3 seconds (adjust duration as needed)
      setTimeout(() => {
        this.success = false;
        this.success_msg = '';
        this.closeSuccessMessage();
      }, 5000);
      }
    });

    this.errorMessageSubscription=this.leavemessage
    .getErrorMessage()
    .subscribe((message)=>{
      if (message) {
        this.error = true;
        this.error_msg= message;
        this.loader=false;
      

      setTimeout(() => {
        this.error = false;
        this.error_msg = '';
        this.closeErrorMessage();
      }, 5000);
    }
    })

    this.leaveservice.getsettings(this.organizationId).subscribe
    ((data:any)=>{
      this.settingslist = data;
      console.log(this.settingslist.length);
      this.tableShow=true;
      this.loader = false;
     console.log(this.settingslist)
     if(this.settingslist.statusCode==204){
      console.log("204")
      this.nodata=true;
     }
      if( this.settingslist.length === undefined){
        console.log("undefined")
        this.tableShow=false;

      }
    })
   
      let localData = JSON.parse(localStorage.getItem("enoteUserData"));
            
              this.orgId = localData.organization.organizationId;
            
              this.timesettingsForm = this.fb.group({
                mintime: ['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]],
                maxtime: ['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]],
              });
            
              this.permissionsettingsForm = this.fb.group({
                maxtimes: ['', [Validators.required, Validators.pattern(/^\d{0,1}$/)]]
              });
  }
  edit(settingsId){
    console.log("create");
    const dialogbox = this.dialog.open(TimeSettingsFormComponent,{
      width:'400px',
      height:'fit-content',
      data : settingsId,
      position: {
        top: '17%' // Adjust the value as needed to move the dialog down
      }
    })

    dialogbox.afterClosed().subscribe(
      (res)=>{
        console.log(res);
         if(res)
         {
           if(res.statusCode == 200)
            this.loader = true;
           {
            setTimeout(() => {
              this.leaveservice.getsettings(this.organizationId).subscribe
              ((data:any)=>{
                this.settingslist = data;
                this.loader = false;
              })
            }, 2000)
           }
         }
      }
    );
  }
  editPermission(settingsId){
    console.log("create");
    const dialogRef = this.dialog.open(EmpPermissionSettingComponent,{
      width:'400px',
      height:'fit-content',
      data : settingsId
    })
    dialogRef.afterClosed().subscribe(
      (res)=>{
        console.log(res);
         if(res){
           if(res.statusCode == 200) {
            this.loader = true;
            setTimeout(() => {
              this.leaveservice.getsettings(this.organizationId).subscribe
              ((data:any)=>{
                this.settingslist = data;
                this.loader = false;
              })
            }, 2000)
           }
         }
      }
    );
  }
  apply_setting(){
    // this.router.navigateByUrl("/home/record-setting");
    console.log("create");
    const dialogRef = this.dialog.open(AddrecordsettingComponent,{
      width:'550px',
      // height:'fit-content',
    })
    // dialogRef.afterClosed().subscribe(
    //   (res)=>{
    //     console.log(res);
    //      if(res){
    //        if(res.statusCode == 200) {
    //         this.loader = true;
    //         setTimeout(() => {
    //           this.leaveservice.getsettings(this.organizationId).subscribe
    //           ((data:any)=>{
    //             this.settingslist = data;
    //             this.loader = false;
    //           })
    //         }, 2000)
    //        }
    //      }
    //   }
    // );
  }
  
  closeSuccessMessage() {
    this.success = false;
    this.success_msg = '';
  }
  closeErrorMessage(){
    this.error = false;
    this.error_msg = '';
  }
 
    

   
}

