import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { AppComponent } from 'src/app/app.component';
import {ExcelService } from '../services/excel.service';
import { SettingsService } from 'src/app/providers/settings.service';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import { responseMsgTimeOut }from '../../app/providers/properties'
import { ImportEmployeerecoveryadjustmentComponent } from '../import-employeerecoveryadjustment/import-employeerecoveryadjustment.component';

@Component({
  selector: 'app-manage-recoveryadjustment',
  templateUrl: './manage-recoveryadjustment.component.html',
  styleUrls: ['./manage-recoveryadjustment.component.scss']
})
export class ManageRecoveryadjustmentComponent implements OnInit {
  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  // clientList:any;
  clientList: any= [];
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: any;
  ratingId: any;
  req: boolean=false;
  finEmpList: any;
  eleNameList: any;
  categoryId: number;
  categoryTypeData: any;
  categoryName: any;
  employeeNumber: any;
  maxstartdate: Date;
  elementlist:any = [];
  clientelements: any;
  maxpfamount: any;
  max: any;
  employeePfAmount: any;
  employerPfAmount: any;
  maxesiamount: any;
  employeeEsiAmount: any;
  employerEsiAmount: any;
  
  constructor(private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    public excelService:ExcelService,
    private dialog:MatDialog,
    private router:Router,
    @Inject(AppComponent) public AppData: any) { }

  ngOnInit(): void {
    this.noRecordsFound = true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
   
    this.filterForm = this.fb.group({
      // employeecode:[null],
      client:[null],
      employee:[null],
      financialyear:[null],
      period:[null],
      element:[null]
    })

    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });

    this.payrollservice.getCategoryList().subscribe((result: any) => {
      // this.filterForm.get('category').patchValue(result[0].listTypeValueId);
      this.categoryTypeData = result;
      this.categoryId=this.categoryTypeData[0].listTypeValueId;
      this.categoryName=this.categoryTypeData[0].listTypeValueName;
      console.log('result',this.categoryId);
      this.payrollservice.getElementListValues(this.categoryId, this.orgId).subscribe((d: any) => {
        console.log('element', d)
        this.eleNameList = d;
      })
    })
    // this.categoryId=43;
    // this.payrollservice.getElementListValues(this.categoryId).subscribe((d: any) => {
    //   console.log('element', d)
    //   this.eleNameList = d;
    // })

    this.settingsService.getElementList(this.orgId).subscribe((Data: any) => {
      console.log(Data);
      this.finEmpList = Data;
    });

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
  }

  addRecovery()
  {
    console.log("add recovery");
    this.router.navigateByUrl('/home/client-recovery-adjustment');
  }
  
  get serachvalidate() { return this.filterForm.controls; }
  clearTable()
  {
    this.resultData = [];
    console.log('clear')
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
  }
  searchSubmit()
  {
    console.log("Filter",this.filterForm.value);
    // if(this.filterForm.invalid)
    // {
    //   console.log("Required Field");
    //   console.log(this.filterForm);
    //   return;
    // }
    if((this.filterForm.value.client=== '' || this.filterForm.value.client ===null)&&(this.filterForm.value.employee=== '' || this.filterForm.value.employee ===null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null')&&(this.filterForm.value.element===null|| this.filterForm.value.element ==='null'))
    {
       console.log("required Fields");
       this.resultData =[];
       this.noRecordsFound = true;
       alert("Please Select Client");
       return;
    }

    if((this.filterForm.value.client!= '' || this.filterForm.value.client != null)&&(this.filterForm.value.employee===''|| this.filterForm.value.employee=== null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null')&&(this.filterForm.value.element===null|| this.filterForm.value.element ==='null'))
    {
        alert("Please Select Employee");
        return;
    }
     if((this.filterForm.value.client==='' || this.filterForm.value.client===null||this.filterForm.value.client==='null')&&(this.filterForm.value.employee===''|| this.filterForm.value.employee===null)&&(this.filterForm.value.financialyear!=null|| this.filterForm.value.financialyear!='null'|| this.filterForm.value.financialyear!='')&&(this.filterForm.value.period===null|| this.filterForm.value.period==='null')&&(this.filterForm.value.element===null|| this.filterForm.value.element==='null'))
    { 
      console.log("year only");
      this.payrollservice.getYearList(this.filterForm.value.financialyear).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.client===''||this.filterForm.value.client===null||this.filterForm.value.client==='null')&&(this.filterForm.value.employee===''|| this.filterForm.value.employee===null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null')&&(this.filterForm.value.element!=null|| this.filterForm.value.element!='null'||this.filterForm.value.element!=''))
    {
      console.log("element");
      this.payrollservice.getElementList(this.filterForm.value.element).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    if((this.filterForm.value.client!=''|| this.filterForm.value.client!=null||this.filterForm.value.client!='null')&&(this.filterForm.value.employee!='' || this.filterForm.value.employee!=null)&&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear==='null')&&(this.filterForm.value.period===null|| this.filterForm.value.period==='null')&&(this.filterForm.value.element===null|| this.filterForm.value.element==='null'))
    { 
      console.log("Client and Emp"); 
      this.payrollservice.getClientandEmpList(this.employeeId,this.filterForm.value.client).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.client===''|| this.filterForm.value.client===null||this.filterForm.value.client==='null')&&(this.filterForm.value.employee===''||this.filterForm.value.employee===null)&&(this.filterForm.value.financialyear!=null|| this.filterForm.value.financialyear!='null')&&(this.filterForm.value.period!=null|| this.filterForm.value.period!='null')&&(this.filterForm.value.element===null|| this.filterForm.value.element==='null'))
    { 
      console.log("year and period");
      this.payrollservice.getYearandPeriodList(this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }
    else if((this.filterForm.value.client!='' || this.filterForm.value.client!=null||this.filterForm.value.client!='null')&&(this.filterForm.value.employee!='' || this.filterForm.value.employee!=null)&&(this.filterForm.value.financialyear!=null || this.filterForm.value.financialyear!='null')&&(this.filterForm.value.period!=null|| this.filterForm.value.period !='null')&&(this.filterForm.value.element!=null|| this.filterForm.value.element !='null'))
    { 
      console.log("Both");
      this.payrollservice.getAdjustmentList(this.filterForm.value.client,this.employeeId,this.filterForm.value.financialyear,this.filterForm.value.period,this.filterForm.value.element).subscribe((resultData:any) =>
      {
        this.noRecordsFound = false;
        this.resultData = resultData;
        console.log(this.resultData);
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
        }
      })
    }

  }
  
  // {
  //   console.log("page changes",event);
  // }
  onItemsPerPageChange(event: any) {
    this.pagePerItem = event.target.value;
    this.p = 1; // Reset the pagination to the first page when items per page is changed
  }
  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

  selectFinancialyear(event)
  {
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    if(this.chooseFinId == null || this.chooseFinId == 'null'){
      console.log('if');
      this.finPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        period: null,
      });
    });
  }
  }

//  getclientEmployees()
//   {
//       this.empList.length = 0;
//        console.log(this.filterForm.value.client)
//       if(this.filterForm.value.client != null && this.filterForm.value.client != "null")
//       {
//         // this.payrollService.getAllEmployeeClientList(this.searchForm.value.client).subscribe((ClientEmployees: any) => {
//         //   if(ClientEmployees.length != undefined)
//         //   {
//         //     ClientEmployees.forEach(element => {
//         //       this.empList.push(element.employee);
//         //     });
//         //   }
         
//         // });
//     }
//   }

   getclientEmployees()
  {
      this.empList.length = 0;
       console.log(this.filterForm.value.client);
      if(this.filterForm.value.client != null && this.filterForm.value.client != "null")
      {
         this.filterForm.patchValue({
          employee:null
         });

        this.payrollservice.getAllEmployeeClientList(this.filterForm.value.client).subscribe((ClientEmployees: any) => {
          if(ClientEmployees.length != undefined)
          {
            // ClientEmployees.forEach(element => {
            //   this.empList.push(element.employee);
            // });
            this.empList = ClientEmployees;
          }
         
        });

        this.payrollservice.clientelements(this.filterForm.value.client).subscribe((result: any) => {
          if(result.statusCode)
          {
           this.elementlist.length = 0;
          }else{
            console.log("else")
             this.clientelements = result;
               this.maxpfamount = this.clientelements[0].pfMaxSalaryAmount;
               this.employeePfAmount = this.clientelements[0].employeePfAmount;
               this.employerPfAmount = this.clientelements[0].employerPfAmount;

               this.maxesiamount = this.clientelements[0].esiMaxSalaryAmount;
               this.employeeEsiAmount = this.clientelements[0].employeeEsiAmount;
               this.employerEsiAmount = this.clientelements[0].employerEsiAmount;
          //     result.forEach(cleintelemets => {
          //       cleintelemets.lines.forEach(lines => {
          //           this.elementlist.push(lines.element);
          //       });

          //     });
        
          // this.filterele =  this.elementlist.filter(ele=> ele.elementName.includes( "Basic" ) ||  ele.elementName.includes( "HRA" ) );
          // this.filterele.sort((one, two) => (one.elementName > two.elementName ? 1 : -1));
          //        console.log(this.filterele);
  
          // const control = this.postForm.get('tableRows') as FormArray;
                 
          // console.log("this.filterele",this.filterele);
          // this.filterele.forEach((element ,index) => {
          //          this.addRow();
          //           if( element.elementName != "HRA")
          //           {
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,
          //               hrElementPercentage :  element.percentage ? element.percentage.elementPercentageId : 0,
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }else{
          //             control.at(index).patchValue({
          //               hrElementPercentagelist : element.hrElementPercentage,                  
          //               element : element.elementId,
          //               elementName : element.elementName
          //              })
          //           }
          //     });
        //  console.log(control); 
          }
       })
      }
      else
      {
        console.log("client Empty");
        this.empList =[];
        this.filterForm.patchValue({
          employee:null
         });
         console.log("empty patch emp"+this.filterForm);
      }
  }

  empSearch(event)
  { 
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if(employeenumber)
    {
      // this.payrollService.getEmployeeNameList(employeenumber, this.organizationId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }
      
      // })
      this.payrollservice.getClienEmployeeList( this.filterForm.value.employee , this.filterForm.value.client).subscribe((result: any) => {
        this.empList.length = 0;
        if(result.statusCode)
        {
          
        }else{
          this.empList =   result;
          // result.forEach(element => {
          //   if(!this.empList.some(e=>e.employeeId == element.employee.employeeId))
          //   {
          //     this.empList.push(element.employee);
          //   }
           
          // });
        }
     
     })
    }
    
  }

  empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
     this.employeeNumber = emp.employeeNumber;
      this.filterForm.patchValue({
        employee: this.employee 
     });

     this.payrollservice.getannexueversionbyemployee(this.employeeId).subscribe((result: any) => {
      if(result.statusCode)
      {
        
      }else{
        var date = new Date(result.effectiveToDate)

        // Add a day
        date.setDate(date.getDate() + 1)
        this.maxstartdate = date ; 
      }
   
   })
  }
  
  importEmployee()
  {
    console.log("import");
    console.log("import Employee");
    const dialogRef = this.dialog.open(ImportEmployeerecoveryadjustmentComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });
  
    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else 
      {
         if(result.length != 0)
         {
          console.log("Import Error");
          this.error = true;
          //this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         }
          else
          {
            if(result.statusCode > 400)
            {
              this.error = true;
              this.importerror = false;
            }
         }
      }
    });
  }
  
 
}
