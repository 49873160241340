import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-employee-transfer',
  templateUrl: './select-employee-transfer.component.html',
  styleUrls: ['./select-employee-transfer.component.scss']
})
export class SelectEmployeeTransferComponent implements OnInit {

  selectedType: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  clearAllForm() {
    this.selectedType = '';


  }

  selected(event: any) {
    this.selectedType = event.value;


  }

}
