<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title class="headerDesign" >  {{ designationId ? "Edit" : "Add" }} Designation</h3>

    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="form-group col-md-12" style="height: 70px !important;">
                <label class="form-control-label">Designation Name <span class="star">*</span></label>
                <input type="text" (keyup)="checkExistDesignationName($event)" formControlName="designationName" id="designationName" [ngClass]="{ 'is-invalid': submitted }"
                 class="form-control email-input select-placeholder" value="" placeholder="Designation Name"
                    autocomplete="off">
                <!-- <div class="valid-msg" *ngIf="(validate.designationName.invalid && validate.designationName.touched) || validate.designationName.dirty || submitted">
                    <span *ngIf="validate.designationName.errors?.required" class="text-danger">Please Enter Designation Name</span>
                    <span *ngIf="validate.designationName.errors?.pattern" class="text-danger">Please Enter Valid  Designation Name</span>
                    <span *ngIf="designationNameExistStatus" class="text-danger"> Designation Name already exist!</span>
                </div> -->
                <span *ngIf="validate.designationName && submitted"  class="text-danger valid-msg" >{{
                    projectCode()
                  }}
                </span>
                <span *ngIf="validate.designationName.errors?.pattern" class="text-danger valid-msg">Please Enter Valid  Designation Name</span>
                <span *ngIf="designationNameExistStatus" class="text-danger valid-msg"> Designation Name already exist!</span>
            </div>
        </div>

        <div style="height: 70px !important;">
            <div class="row">
                <div class="form-group col-md-12">
                    <label class="form-control-label">Designation Description</label>
                    <input type="text" formControlName="designationDescription" id="designationDescription" autocomplete="off" class="form-control email-input select-placeholder" value="" placeholder="Designation Description">
                    <div class="valid-msg" *ngIf="(validate.designationDescription.invalid && validate.designationDescription.touched) || validate.designationDescription.dirty">
                        <span *ngIf="validate.designationDescription.errors?.pattern" class="text-danger">Please Enter Valid  Designation Description</span>
                    </div>
                    <!-- <span *ngIf="validate.designationDescription && submitted"  class="text-danger valid-msg" >{{
                        descripErrror()
                      }}
                    </span> -->
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" 
        [disabled]="isbtnDisable"
        mat-button mat-dialog-close mat-raised-button 
        style="
                  margin-left: 10px;
                  background: none;
                  color: var(--Red-Color, #ff5d5d);
                  border: 1px solid #ddd !important;
                  height: 32px;
                  position: relative;
                  top: 2px;
                  font-weight: 550;
                "
                class="btn search-btn mr-2"  >Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <!-- <button 
        type="submit"
                mat-raised-button
                class="searchBtn"   
                 [disabled]="isbtnDisable"
        >Save</button> -->
        <button type="submit" mat-raised-button [disabled]="isbtnDisable" style="" class="searchBtn">Save</button>

    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="        width: 3rem;
    height: 3rem;
    position: absolute;
    top: 47%;
    right: 48%;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>
