<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Client Working Days</span>
                </div>
                <div class="sub-header-buttons">
                    <a  (click)="importEmployee()" mat-raised-button class="ml-2" color="primary" title="Import">
                        <!-- <mat-icon class="mat-sidebar-icon">publish</mat-icon> -->
                        Import
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                         <div class="col-md-6 DesigniLabel2">
            <b>Search Working Days</b>
          </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <!-- <label class="col-form-label">Client Name<span class="star"></span></label> -->
                                <select formControlName="filterclient" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]="null"> -Select Client- </option>
                                    <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <select formControlName="filterfinancialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFilterFinancialyear($event)" >
                                    <option [value]=null> -Select Financial Year- </option>
                                    <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <select formControlName="filterperiod" class="form-select form-select-solid form-select-lg select2-new" >
                                    <option [value]=null> -Select Period- </option>
                                    <option *ngFor="let row of financialPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <button type="" mat-raised-button color="primary"
                                    class="btn btn-primary search-btn" [disabled]="isProcessing">Search</button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                    class="btn-clear search-btn mr-2" (click)="clearTable()"
                                    [disabled]="isProcessing"   >Clear</button>
                            </div>
                            <!-- <div class="form-group col-md-1" *ngIf="resultData?.length > 0 " type="button" (click)="exportToExcel()" style="position: relative; top: 37px;">
                                <button type="submit" mat-raised-button color="primary" 
                                    class="btn btn-primary search-btn">Export</button>
                            </div> -->
                        </div>
                    </form>
                    <br>
                        <form [formGroup]="clientworkingdaysForm" (ngSubmit)="onSubmit()">
                            <div class="col-md-12 DesigniLabel">
                                <b>  {{ financialYearId ? "Edit" : "Add" }} Working Days</b>
                              </div>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Client Name<span class="star">*</span></label> 
                                    <select formControlName="client"   class="form-select form-select-solid form-select-lg select2-new">
                                        <option [ngValue]="null"> -Select Client- </option>
                                        <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                                    </select>    
                                    <div class="valid-msg" *ngIf="(validate.client.invalid && validate.client.touched) || validate.client.dirty || submitted">
                                        <span *ngIf="validate.client.errors?.required" class="text-danger">Please Select Client</span>
                                        <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Financial Year<span class="star"> *</span></label>
                                    <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                        <option [ngValue]="null"> -Select Financial Year- </option>
                                        <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option> 
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.financialyear.invalid && validate.financialyear.touched) || validate.financialyear.dirty || submitted">
                                        <span *ngIf="validate.financialyear.errors?.required" class="text-danger">Please Select Financial Year</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Period<span class="star"> *</span></label>
                                    <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new">
                                        <option [ngValue]="null"> -Select Period - </option>
                                        <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option>
                                    </select>
                                    <div class="valid-msg" *ngIf="(validate.period.invalid && validate.period.touched) || validate.period.dirty || submitted">
                                        <span *ngIf="validate.period.errors?.required" class="text-danger">Please Select Period</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label">Working Days<span class="star"> *</span></label>
                                    <input type="text" placeholder="Enter Working Days"  (keypress)="onlyNumber($event)"
                                               formControlName="workingdays"
                                               class="form-select form-select-solid form-select-lg select2-new">
                                    <div class="valid-msg" *ngIf="(validate.workingdays.invalid && validate.workingdays.touched) || validate.workingdays.dirty || submitted">
                                        <span *ngIf="validate.workingdays.errors?.required" class="text-danger">Please Enter Working Days</span>
                                        <span *ngIf="validate.workingdays.errors?.pattern" class="text-danger">Enter Vaild Working Days </span>
                                        <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-md-12 text-right">
                                        <button type="reset" class="submit action-button btn mr-2 btn-sm" mat-raised-button
                                        style="margin-right: 20px;">Clear</button>
                                        <button type="submit" class="submit action-button btn btn-primary btn-sm"
                                            [disabled]="clientworkingdaysForm.invalid">Save
                                        </button>
                                </div>
                            </div>
                        </form>
                        <div *ngIf="showtable && !loader" class="table" style="margin-top: 60px;">

                        <div class="row">
                            <!-- <div class="col-md-6">
                              <p><b>WORKING DAYS</b></p>
                            </div> -->
                            <div class="col-md-6 DesigniLabel1">
                              <b>Client Working Days</b>
                            </div>
                            <div class="col-md-6">
                              <div class="text-right" *ngIf="filterData?.length > 5">
                                <div class="select-pagination m-2" style=   " position: relative;
                                top: 0px;">
                                  <span>Show : </span>
                                  <select
                                    class="select_list new-select_list listDesign"
                                    [(ngModel)]="pagePerItem"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="pageChages()"
                                  >
                                    <option class="form-control" value="5">5</option>
                                    <option class="form-control" value="10">10</option>
                                    <option class="form-control" value="15">15</option>
                                    <option class="form-control" value="20">20</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                       
                       <div class="table"   >
                        <!-- <p><b>CLIENT WORKING DAYS</b></p> -->
                        <!-- <div class="col-md-6 DesigniLabel1">
                            <b>Client Working Days</b>
                          </div> -->
                        <table class="table">
                            <thead class="t-head">
                                <tr>
                                    <th  style="border-radius: 15px 1px 0px 0;border-top: 0px;">Action</th>
                                    <th>Client Name</th>
                                    <th>Financial Year</th>
                                    <th>Period</th>
                                    <th>Working Days</th>
                                    <th style="border-radius: 0px 15px 0px 0;border-top:0px;">Status</th>
                                </tr>
                            </thead>
                             <tbody>
                                <tr *ngFor="let row of filterData | paginate: { itemsPerPage: pagePerItem, currentPage: p }; let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item (click)="updateform(row)"  >
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                            <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button> -->
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==1" (click)="updateStatus(row.clientWorkingDaysId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.status.listTypeValueId==2" (click)="updateStatus(row.clientWorkingDaysId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                    <td>{{row.client.clientName}}</td>
                                    <td>{{row.financialPeriod.finanicalYear.financialFromMonth}}-{{row.financialPeriod.finanicalYear.financialFromYear}} To {{row.financialPeriod.finanicalYear.financialToMonth}}-{{row.financialPeriod.finanicalYear.financialToYear}}</td>
                                    <td>{{row.financialPeriod.financialperiodMonth}}-{{row.financialPeriod.financialPeriodYear}}</td>
                                    <td>{{row.workingDays}}</td>
                                    <!-- <td>{{row.endDate | date :dateFormat}}</td>
                                    <td class="text-center">{{row.ratePerDay}}</td>
                                    <td class="text-center" >{{row.ratePerMonth}}</td> -->
                                    <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.status.listTypeValueId==1">
                                            {{row.status.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.status.listTypeValueId==2">
                                            {{row.status.listTypeValueName}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody> 
                            <!-- <tbody>
                                <tr>
                                    <td style="text-align: center;" colspan="7" *ngIf="noRecordsFound">No Records Found!</td>
                                </tr>
                            </tbody> -->
                            <tbody style=" border-right: 1px solid #ddd;
                            border-left: 1px solid #ddd;">
                              <tr>
                                <td style="
                                text-align: center;
                                font-size: larger;
                                font-weight: bold;
                              " colspan="7" *ngIf="noRecordsFound">
                                  Working Days Records Not Found!
                                </td>
                              </tr>
                            </tbody>
                        </table>
                     </div>
                     </div>
                    
                    <!-- <div *ngIf="nodatafound" class="no-data"> -->
                        <!-- <img src="assets/uploads/nodata.png"> -->
                        <!-- <span class="no-data-found"> No Data Found </span> -->
                    <!-- </div> -->
                     <!-- <div *ngIf="resultData?.length > 0 ">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>  -->
                    <div class="pagination-content" *ngIf="filterData.length > 5 && filterData .length > 0 && paginationSet"
                        id="page">
                        <pagination-controls *ngIf="filterData.length > 5" style="position: relative; float: inline-end"
                          (pageChange)="p = $event" align="end"></pagination-controls>
                      </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem; top: 80%; right: 47.8%" role="status"></div>
  </div>