import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refreshcomponent',
  templateUrl: './refreshcomponent.component.html',
  styleUrls: ['./refreshcomponent.component.scss']
})
export class RefreshcomponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    console.log("refresh component!!!");
    
  }

}
