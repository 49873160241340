import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ExcelService } from '../../services/excel.service';
import { Sort } from '@angular/material/sort';
import { MatSelectChange } from '@angular/material/select';
import { HeaderService } from 'src/app/providers/header.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';

@Component({
  selector: 'app-position-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['./position-list.component.scss'],
})
export class PositionListComponent implements OnInit {
  positionlistdata: any[];
  combinedClients: any[];
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  positionId: any;
  listTypeValueid: any;
  client: any;
  clientname: any;
  clientid: any;
  positionname: any;
  positionSearchform: FormGroup;
  loader: any = false;
  backdrop: any = false;
  showclear: any = false;
  usertype: any;
  userType: any;
  list_loader = true;
  sortedData: any[];
  //usertData = false;

  usertData: any = '';
  searchForm!: FormGroup;
  nodatafound: boolean = true;
  jobtype: any;
  clientName: any;
  clientName1: any;
  clientDetails: string;
  organizationId: any;
  btnDisable: boolean;
  btnsDisable: boolean;
  btnDisables: boolean = false;
  search: any;
  tableShow: boolean = false;
  falseboolean: boolean = false;
  poshValidation: boolean = true;
  isReqruiter: boolean = false;
  beforesearchpage: number;
  noDataFound: boolean = false;
  postionStatus: any;
  noticePeriodGet:any;
  positionStatusInExcel:any;
  StatusInExcel:any;
  orgId: any;

  constructor(
    private router: Router,
    private RecruitmentService: RecruitmentService,
    private formBuilder: FormBuilder,
    public ExcelService: ExcelService,
    private headerservice: HeaderService,
    private positionservice: RecruitmentService,
    private SuperadminService:SuperadminService
  ) {}

  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  PositionName: any;
  MinExperience: any;
  MaxExperience: any;
  Location: any;
  dataExport: any = [];
  exported: any;
  exportingdata: any;
  tableSearch: any;
  clientListData: any = [];
  isAdmin: boolean = false;

  storeEntityId:any
  ngOnInit(): void
  {
    // this.headerservice.setTitle('Positions')
    this.headerservice.setTitle('');

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('local storage data::', this.usertData);
    this.orgId=this.usertData.organization.organizationId;
    this.storeEntityId=this.usertData.entity.entityId;

    this.userType = [];
    this.usertData.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    // if (this.usertData && this.usertData.employee && Array.isArray(this.usertData.employee.role)) {
    //   this.usertData.employee.role.forEach((role) => {
    //     this.userType.push(role?.roleName);
    //   });
    // } else if (this.usertData && this.usertData.role.roleName) {
    //   // Assuming the role name is directly available when there's no employee object
    //   this.userType.push(this.usertData.role.roleName);
    // }
    
    // this.isAdmin = this.userType.includes('Admin');
    this.isAdmin=(this.userType.includes('Admin') && this.userType.includes('Employee') && this.userType.includes('Human Resource')) || 
    (this.userType.includes('Employee') && this.userType.includes('Human Resource')) ||
    (this.userType.includes('Admin') && this.userType.includes('Human Resource'));
    this.isReqruiter = this.userType.includes('Recruiter');
    console.log('this.isAdmin', this.isAdmin);
    console.log(this.isReqruiter);

    console.log('rolename', this.userType);

    let userId = this.usertData.userId;
    this.organizationId = this.usertData.organization.organizationId;
    this.positionservice
      .getclientlist(this.organizationId)
      .subscribe((resultData: any) => {
        console.log(' resultData', resultData);
        this.clientListData = resultData;
      });
    /* this.positionSearchform = new FormGroup(
      {
        positionName : new FormControl(this.positionSearchform,[Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
        clientId : new FormControl(this.positionSearchform)
      }
    ); */

    /* this.usertype = JSON.parse(localStorage.getItem('user_hrms'));
    this.userType = this.usertype.userType.userType;
    */

    /*  this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let userId = this.usertype.userId;
    let organizationId = this.usertype.organization.organizationId;
    this.usertype = this.usertype.role.roleName;

    this.getPositionList();*/
    // this.getClientname();

    this.getFilterFormData();
    this.RecruitmentService.jobtypebyid().subscribe((data: any) => {
      this.jobtype = data;
    });
    this.getAllClientList()
  }
  getAllClientList(){
    // this.loader=true;
    // this.tableShow=false
    this.SuperadminService.getAllClientlist(this.storeEntityId).subscribe((resultData:any)=>{

      console.log(' client list', resultData);
          resultData.forEach((item: any) => {
            if (item.status.listTypeValueId == 1) {
              let storeStatusListValueNames=item.status.listTypeValueName
              console.log("storeStatusListValueId:",storeStatusListValueNames);
              this.clientname=resultData;
              // this.clientname?.push(storeStatusListValueId);
              console.log('active client list::', this.clientname);
              // this.patchresource();
            }
  
    })
  })
}
clearall(event:any){
  // this.getAllbusinessUnitList()
  this.nodatafound = false;

  const inputValue=event.target.value;
  this.tableShow=false;

  
  }
  getFilterFormData() {
    /* this.searchForm = new FormGroup(
      {
        keywords : new FormControl(this.searchForm),
        jobType  : new FormControl(this.searchForm)
      }
    ); */

    this.searchForm = this.formBuilder.group({
      // keywords: [
      //   '',
      //   Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      // ],
      keywords: [
        '',
        [
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      // jobType: [''],
      // jobType: [null],
      clientId: [null],
    });
  }

  // getClientname() {
  //   this.positionservice
  //     .getclientlist(this.organizationId)
  //     .subscribe((data: any) => {
  //       this.clientname = data;
  //       console.log('this.clientname', this.clientname);
  //     });
  // }

  // searchSubmit()
  // {
  //   this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
  //   let userId = this.usertData.userId;
  //   let organizationId = this.usertData.organization.organizationId;

  //   let keywords = this.searchForm.value.keywords;
  //   let jobType = this.searchForm.value.jobType;

  //   if( !keywords && (!jobType || jobType == 'null'))
  //   {
  //     alert('Please search atleast one item');
  //     return;
  //   }

  //   if( keywords && (jobType && jobType != 'null' ))
  //   {
  //     this.loader=true;
  //     this.backdrop=true;

  //     this.RecruitmentService.positionListFilter(organizationId, keywords, jobType).subscribe((positionlistdata:any) =>
  //     {
  //       this.loader = false;
  //       this.backdrop = false;
  //       this.nodatafound = false;

  //       this.positionlistdata = positionlistdata;
  //       console.log("this.positionlistdata", this.positionlistdata)
  //       if(this.positionlistdata.length == 0 || this.positionlistdata.length == undefined)
  //       {
  //         this.nodatafound = true;
  //       }
  //       this.combinedClients = this.getCombinedClients();
  //       for (var j = 0; j < this.combinedClients.length; j++) {
  //         this.clientName1 = this.combinedClients[j].clientName;
  //         console.log('this.clientName1',this.clientName1)
  //         for (var i = 0; i < this.positionlistdata.length; i++) {
  //           console.log("i", i, "j", j);

  //           if(i==j){
  //             console.log("ij", i,j);
  //             this.positionlistdata[i].clientName = this.clientName1;
  //           }

  //          }
  //          }
  //       console.log('combinedClients',this.combinedClients)
  //       console.log("this.positionlistdata2", this.positionlistdata)
  //     })
  //   }
  //   else if( keywords && (!jobType || jobType == 'null') )
  //   {
  //     this.loader=true;
  //     this.backdrop=true;

  //     this.RecruitmentService.positionListFilterKeywords(organizationId, keywords).subscribe((positionlistdata:any) =>
  //     {
  //       this.loader = false;
  //       this.backdrop = false;
  //       this.nodatafound = false;

  //       this.positionlistdata = positionlistdata;
  //       console.log("this.positionlistdata1", this.positionlistdata)

  //       if(this.positionlistdata.length == 0 || this.positionlistdata.length == undefined)
  //       {
  //         this.nodatafound = true;
  //       }

  //       this.combinedClients = this.getCombinedClients();
  //       for (var j = 0; j < this.combinedClients.length; j++) {
  //         this.clientName1 = this.combinedClients[j].clientName;
  //         console.log('this.clientName1',this.clientName1)
  //         for (var i = 0; i < this.positionlistdata.length; i++) {
  //           console.log("i", i, "j", j);

  //           if(i==j){
  //             console.log("ij", i,j);
  //             this.positionlistdata[i].clientName = this.clientName1;
  //           }

  //          }
  //          }
  //       console.log('combinedClients',this.combinedClients)
  //       console.log("this.positionlistdata2", this.positionlistdata)
  //     })
  //   }
  //   else if( !keywords && (jobType && jobType != 'null') )
  //   {
  //     this.loader=true;
  //     this.backdrop=true;

  //     this.RecruitmentService.positionListFilterJobType(organizationId, jobType).subscribe((positionlistdata:any) =>
  //     {
  //       this.loader = false;
  //       this.backdrop = false;
  //       this.nodatafound = false;

  //       this.positionlistdata = positionlistdata;

  //       if(this.positionlistdata.length == 0 || this.positionlistdata.length == undefined)
  //       {
  //         this.nodatafound = true;
  //       }
  //       this.combinedClients = this.getCombinedClients();
  //       for (var j = 0; j < this.combinedClients.length; j++) {
  //         this.clientName1 = this.combinedClients[j].clientName;
  //         console.log('this.clientName1',this.clientName1)
  //         for (var i = 0; i < this.positionlistdata.length; i++) {
  //           console.log("i", i, "j", j);

  //           if(i==j){
  //             console.log("ij", i,j);
  //             this.positionlistdata[i].clientName = this.clientName1;
  //           }

  //          }
  //          }
  //       console.log('combinedClients',this.combinedClients)
  //       console.log("this.positionlistdata2", this.positionlistdata)
  //     })
  //   }

  // }

  getCombinedClients() {
    return this.positionlistdata.map((item) => {
      const client = this.clientListData.find(
        (aItem) => aItem.clientId === item.client
      );
      return { clientName: client ? client.clientName : 'Unknown' };
    });
  }
  singleTabValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const tabsCount = (value.match(/\t/g) || []).length;

      // If there are more than one tab, return an error
      return tabsCount > 1 ? { tooManyTabs: true } : null;
    };
  }
  get keywords() {
    return this.searchForm.get('keywords');
  }
  exportToExcel() {
    let keywords = this.searchForm.value.keywords;
    let jobType = this.searchForm.value.jobType;

    if (keywords && jobType) {
      this.dataExport.length = 0;
      this.exportingdata = this.positionlistdata;

      

      console.log(" this.exportingdata::", this.exportingdata);
      

      // for (let exportingdata of this.exportingdata) {
      //           console.log("exportingdata::",exportingdata);

      //   console.log('exportingdata.clientId', exportingdata.clientId);
      //   if (exportingdata.clientId != undefined) {
      //     this.clientName = exportingdata.clientId.clientName;
      //   } else if (exportingdata.client == undefined) {
      //     this.clientName = 'In-House';
      //   }

      //   console.log("exportingdata::",exportingdata);
        

      //   this.exported = {
      //     PositionName: exportingdata.positionName,
      //     MinExperience: exportingdata.minExperience,
      //     MaxExperience: exportingdata.maxExperience,
      //     clientName: this.clientName,
      //     Location: exportingdata.location,
      //     MaxCTC: exportingdata.ctc,
      //     JobType: exportingdata.jobType.listTypeValueName,
      //     NoticePeriod: this.noticePeriodGet,

      //   };
      //   this.dataExport.push(this.exported);
      // }

      
for (let exportingdata of this.exportingdata) {
  console.log('exportingdata.clientId', exportingdata.clientId);

  if (exportingdata.clientId != undefined) {
    this.clientName = exportingdata.clientId.clientName;
  } else {
    this.clientName = 'In-House';
  }

  // Extract noticePeriod values for the current exportingdata
  const noticePeriodGet = exportingdata.noticePeriod.map(notice => notice.listTypeValueName);
if (exportingdata.positionStatus == true) {
  this.positionStatusInExcel = 'Open';
}else if(exportingdata.positionStatus == false){
  this.positionStatusInExcel = 'Close';
}

if (exportingdata.status.listTypeValueId == 1) {
  this.StatusInExcel = 'Active';
}else if(exportingdata.status.listTypeValueId == 2){
  this.StatusInExcel = 'Active';
}
  this.exported = {
    PositionName: exportingdata.positionName,
    MinExperience: exportingdata.minExperience,
    MaxExperience: exportingdata.maxExperience,
    ClientName: this.clientName,
    Location: exportingdata.location,
    MaxCTC: exportingdata.ctc,
    JobType: exportingdata.jobType.listTypeValueName,
    NoticePeriod: noticePeriodGet.join(','),
    PositionStatus:this.positionStatusInExcel,
    Status:this.StatusInExcel

  };

  this.dataExport.push(this.exported);
}
      this.ExcelService.exportAsExcelFile(this.dataExport, 'Export');
    } else {
      this.dataExport.length = 0;
      this.exportingdata = this.positionlistdata;
      // for (let exportingdata of this.exportingdata) {
      //   console.log('exportingdata.clientId', exportingdata.clientId);
      //   //let dob =this.datepipe.transform(exportingdata.dateOfBirth, 'dd-MMM-yyyy')
      //   if (exportingdata.clientId != undefined) {
      //     this.clientName = exportingdata.clientId.clientName;
      //   } else if (exportingdata.clientId == undefined) {
      //     this.clientName = 'In-House';
      //   }

      //   this.exported = {
      //     PositionName: exportingdata.positionName,
      //     MinExperience: exportingdata.minExperience,
      //     MaxExperience: exportingdata.maxExperience,
      //     ClientName: this.clientName,
      //     Location: exportingdata.location,
      //     MaxCTC: exportingdata.ctc,
      //     JobType: exportingdata.jobType.listTypeValueName,
      //     // NoticePeriod: this.noticePeriodGet.join(','),
      //     NoticePeriod: this.noticePeriodGet, 
        

         
      //   };
      //   this.dataExport.push(this.exported);
      // }

      this.dataExport = [];

for (let exportingdata of this.exportingdata) {
  console.log('exportingdata.clientId', exportingdata.clientId);

  if (exportingdata.clientId != undefined) {
    this.clientName = exportingdata.clientId.clientName;
  } else {
    this.clientName = 'In-House';
  }

  // Extract noticePeriod values for the current exportingdata
  const noticePeriodGet = exportingdata.noticePeriod.map(notice => notice.listTypeValueName);
if (exportingdata.positionStatus == true) {
  this.positionStatusInExcel = 'Open';
}else if(exportingdata.positionStatus == false){
  this.positionStatusInExcel = 'Close';
}

if (exportingdata.status.listTypeValueId == 1) {
  this.StatusInExcel = 'Active';
}else if(exportingdata.status.listTypeValueId == 2){
  this.StatusInExcel = 'Active';
}
  this.exported = {
    PositionName: exportingdata.positionName,
    MinExperience: exportingdata.minExperience,
    MaxExperience: exportingdata.maxExperience,
    ClientName: this.clientName,
    Location: exportingdata.location,
    MaxCTC: exportingdata.ctc,
    JobType: exportingdata.jobType.listTypeValueName,
    NoticePeriod: noticePeriodGet.join(','),
    PositionStatus:this.positionStatusInExcel,
    Status:this.StatusInExcel

  };

  this.dataExport.push(this.exported);
}

      this.ExcelService.exportAsExcelFile(this.dataExport, 'Export');
    }
  }

  getPositionList() {
    let organizationId = this.usertData.organization.organizationId;
    let keywords = this.searchForm.value.keywords;
    let jobType = this.searchForm.value.jobType;

    if (keywords && jobType && jobType != 'null') {
      this.loader = true;
      this.backdrop = true;

      this.RecruitmentService.positionListFilter(
        organizationId,
        keywords,
        jobType
      ).subscribe((positionlistdata: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;

        this.positionlistdata = positionlistdata;

        if (
          this.positionlistdata.length == 0 ||
          this.positionlistdata.length == undefined
        ) {
          this.nodatafound = true;
        }
      });
    } else if (keywords && (!jobType || jobType == 'null')) {
      this.loader = true;
      this.backdrop = true;

      this.RecruitmentService.positionListFilterKeywords(
        organizationId,
        keywords
      ).subscribe((positionlistdata: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;

        this.positionlistdata = positionlistdata;

        if (
          this.positionlistdata.length == 0 ||
          this.positionlistdata.length == undefined
        ) {
          this.nodatafound = true;
        }
      });
    } else if (!keywords && jobType && jobType != 'null') {
      this.loader = true;
      this.backdrop = true;

      this.RecruitmentService.positionListFilterJobType(
        organizationId,
        jobType
      ).subscribe((positionlistdata: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;

        this.positionlistdata = positionlistdata;

        if (
          this.positionlistdata.length == 0 ||
          this.positionlistdata.length == undefined
        ) {
          this.nodatafound = true;
        }
      });
    } else {
      this.loader = true;
      this.backdrop = true;

      this.RecruitmentService.positionlist(this.orgId).subscribe(
        (positionlistdata: any) => {
          this.loader = false;
          this.backdrop = false;

          this.positionlistdata = positionlistdata;
          
         
          

          if (this.positionlistdata.length == 0) {
            this.nodatafound = true;
          }
        },
        (error) => {
          this.list_loader = false;
          this.nodatafound = true;

          if (error.status == 404) {
            this.nodatafound = true;
          }
        }
      );
    }
  }

  //Status Active
  statuschangeactive(positionId) {
    this.RecruitmentService.positionstatus(positionId, 'In-Active').subscribe(
      (result: any) => {
        console.log(result);
        // if(result.statusCode == 200)
        // {
        //   this.success = true;
        // 	this.sucess_msg = result.description;
        //   setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.loader = true;

        this.submitData();
        // }
        // else
        // {
        //   this.error = true;
        //   this.error_msg = result.description;
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
      }
    );
  }

  //Status In-Active
  statuschangeinactive(positionId) {
    this.RecruitmentService.positionstatus(positionId, 'Active').subscribe(
      (data: any) => {
        this.loader = true;

        this.submitData();
      }
    );
  }

  statuschangetrue(positionId) {
    this.RecruitmentService.positionstatuschange(positionId, true).subscribe(
      (data: any) => {
        // window.location.reload();

        this.submitData();
      }
    );
  }

  statuschangefalse(positionId) {
    this.RecruitmentService.positionstatuschange(positionId, false).subscribe(
      (data: any) => {
        // window.location.reload();

        this.submitData();
      }
    );
  }

  Add_resource() {
    this.router.navigate(['/home/position']);
  }
  // updateform(position)
  // {
  //   // this.router.navigate(['home/position'/{{}}]);
  // }

  onKeyUpEvent(event) {
    if (this.query.length != 0) {
      console.log(this.query.length);
      this.p = 1;
    } else {
      this.p = this.beforepage;
    }
  }
  getpostionname() {}

  getclientname() {}

  // clientChanges(event:MatSelectChange)
  // {
  //   let client = {
  //     value: event.value,
  //     text: event.source.triggerValue
  //   };
  //   console.log(client);
  //   // console.log(client.text);
  //   this.clientDetails=client.text;
  //   console.log(this.clientDetails);
  // }

  clientChanges(event: Event) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    const text = target.options[target.selectedIndex].text;

    let client = {
      value: value,
      text: text,
    };

    console.log(client);
    this.clientDetails = client.text;
    console.log(this.clientDetails);
  }

  clearfilter() {
    this.positionSearchform.patchValue({
      positionName: '',
      clientId: null,
    });
    this.RecruitmentService.positionlist(this.orgId).subscribe(
      (positionlistdata: any) => {
        this.positionlistdata = positionlistdata;
        console.log(this.positionlistdata[0].noticePeriod
        );
      }
    );
  }
  // validateInput(event) {
  //   const textarea = event.target;
  //   let value = textarea.value;

  //   // Trim leading and trailing spaces
  //   value = value.trim();

  //   // Replace multiple spaces with a single space
  //   value = value.replace(/\s+/g, ' ');

  //   // Set the modified value back to the input field
  //   textarea.value = value;
  // }

  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }
  preventSpaces(event) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }
  // projectDescriptionError() {
  //   return this.searchForm.keywords.hasError('required')
  //     ? 'Please Enter Project Detail Description*'
  //     : '';
  // }

  submitData() {
    this.search = '';
    this.p = 1;
    if (this.searchForm.invalid) {
    }

    // if(this.searchForm.value.keywords.length ==0  &&  (this.searchForm.value.clientId ===  null || this.searchForm.value.clientId ===  'null') )
    // {
    //   this.loader = true;
    //   this.RecruitmentService
    //   .positionlist()
    //   .subscribe((positionlistdata:any) =>
    //   {
    //     this.positionlistdata = positionlistdata;
    //     console.log(this.positionlistdata);
    //     this.loader = false;
    //     if(this.positionlistdata.length == 0)
    //       {
    //         this.nodatafound = true;
    //       }else{
    //         this.nodatafound = false;
    //       }
    //   }
    //   )

    // }
    this.btnDisables = false;
    this.positionlistdata = [];

    let positionName = this.searchForm.value.keywords;
    let clientId = this.searchForm.value.clientId;
    console.log(positionName);
    console.log(clientId);

    if (
      (positionName == 0 || positionName == null) &&
      (clientId == null || clientId == undefined)
    ) {
      alert('Please Choose any Field to Search!!');
      this.btnDisables = false;
      this.nodatafound = true;
      return;
    }
    this.poshValidation = true;

    if (
      (positionName !== 0 || positionName !== null) &&
      (clientId == null || clientId == undefined)
    ) {
      this.btnDisables = true;
      this.loader = true;
      // this.nodatafound=true
      this.tableShow = false;
      // this.positionlistdata=[]

      console.log('keyword is calling!!!only');
      this.RecruitmentService.positionname(positionName, this.orgId).subscribe(
        (positionlistdata: any) => {
          this.nodatafound = false;
          this.backdrop = false;
          this.positionlistdata = positionlistdata;
          this.btnDisables = true;
          this.tableShow = true;
          this.loader = false;

          console.log(this.positionlistdata);

          if (this.positionlistdata.length == 0) {
            this.nodatafound = true;
            this.btnDisables = false;
            this.tableShow = true;
          }
          this.btnDisables = false;
        }
      );
    } else if (
      (positionName == 0 || positionName == null) &&
      (clientId !== null || clientId !== undefined)
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;
      // this.nodatafound=true

      // this.positionlistdata=[]

      console.log('only client calling!!');
      this.RecruitmentService.clientid(clientId, this.orgId).subscribe(
        (positionlistdata: any) => {
          //  this.loader=false;
          //  this.backdrop=false;
          //  positionlistdata.forEach(element => {
          //   element.noticePeriod.sort((a, b) => a.listTypeValueId - b.listTypeValueId);
          // });
          //  this.positionlistdata = positionlistdata;
          //  this.tableShow=true
          //  this.btnDisables=true
          this.nodatafound = false;
          this.backdrop = false;
          this.positionlistdata = positionlistdata;
          this.btnDisables = true;
          this.loader = false;
          this.tableShow = true;

          console.log('noticePeriod',this.positionlistdata[0]?.noticePeriod
          );
          const index = 0; // replace with the desired index

          const exportingdata = this.positionlistdata[index];
          
          // this.noticePeriodGet = exportingdata.noticePeriod.map(notice => notice.listTypeValueName);

          console.log('noticePeriod2',this.noticePeriodGet);
          

          if (this.positionlistdata.length == 0) {
            this.nodatafound = true;
            this.tableShow = true;
            //  this.tableShow=true;

            this.btnDisables = false;
          }
          this.btnDisables = false;
        }
      );
    } else if (
      (positionName !== 0 || positionName !== null) &&
      (clientId !== null || clientId !== undefined)
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;
      // this.nodatafound=true
      // this.positionlistdata=[]

      console.log('all!!');

      this.RecruitmentService.search(positionName, clientId, this.orgId).subscribe(
        (positionlistdata: any) => {
          this.loader = false;
          this.backdrop = false;
          this.positionlistdata = positionlistdata;
          this.tableShow = true;
          this.btnDisables = true;
          this.tableShow = true;
          this.nodatafound = false;

          console.log(this.positionlistdata);

          if (this.positionlistdata.length == 0) {
            this.nodatafound = true;
            this.tableShow = true;
            this.btnDisables = false;
            this.tableShow = true;
          }
          this.btnDisables = false;
        }
      );
    } else {
      console.log('all conditioncame out!!!');
    }

    // if (this.searchForm.value.keywords.length !=0  && (this.searchForm.value.clientId === null || this.searchForm.value.clientId === 'null'))
    // {
    //   this.loader=true;
    //   this.backdrop=true;
    //   this.nodatafound = false;
    //   this.tableShow=false
    //   this.btnDisables=true;

    //     //positionname
    //  this.RecruitmentService
    //  .positionname(positionName)
    //   .subscribe((positionlistdata:any) =>
    //  {
    //   this.loader=false;
    //   this.backdrop=false;
    //   this.nodatafound=false
    //   this.positionlistdata = positionlistdata;
    //   this.tableShow=true
    //   this.btnDisables=true;

    //   console.log(this.positionlistdata);

    //   if(this.positionlistdata.length == 0)
    //   {
    //     this.nodatafound = true;
    //     this.tableShow=true
    //     this.btnDisables=false;

    //   }
    //   this.btnDisables=false;

    //  }
    //  )
    // }

    //  else if (this.searchForm.value.keywords.length ==0  && (this.searchForm.value.clientId != null && this.searchForm.value.clientId != 'null'))
    //  {
    //    this.loader=true;
    //    this.backdrop=true;
    //    this.nodatafound = false;
    //    this.tableShow=false
    //    this.btnDisables=true
    //      // clientid
    //   this.RecruitmentService
    //   .clientid(clientId)
    //    .subscribe((positionlistdata:any) =>
    //   {
    //    this.loader=false;
    //    this.backdrop=false;
    //    positionlistdata.forEach(element => {
    //     element.noticePeriod.sort((a, b) => a.listTypeValueId - b.listTypeValueId);
    //   });
    //    this.positionlistdata = positionlistdata;
    //    this.tableShow=true
    //    this.btnDisables=true

    //    console.log(this.positionlistdata);

    //    if(this.positionlistdata.length == 0)
    //    {
    //      this.nodatafound = true;
    //      this.tableShow=true
    //      this.btnDisables=false

    //    }
    //    this.btnDisables=false;

    //   }
    //   )
    // }
    // else if(this.searchForm.value.keywords.length !=0  &&  (this.searchForm.value.clientId!=null || this.searchForm.value.clientId!=null) )
    // {
    //      this.loader=true;
    //      this.backdrop=true;
    //      this.tableShow=false
    //      this.btnDisables=true

    //      this.nodatafound = false;
    //      this.RecruitmentService
    //      .search(positionName,clientId)
    //       .subscribe((positionlistdata:any) =>
    //      {
    //        this.loader=false;
    //        this.backdrop=false;
    //        this.positionlistdata = positionlistdata;
    //        this.tableShow=true
    //        this.btnDisables=true

    //        console.log(this.positionlistdata);

    //    if(this.positionlistdata.length == 0)
    //    {
    //      this.nodatafound = true;
    //      this.tableShow=true
    //      this.btnDisables=false

    //    }
    //    this.btnDisables=false;

    //      }
    //      )
    //  }
  }
  get positionName() {
    return this.positionSearchform.get('positionName');
  }
  get clientId() {
    return this.positionSearchform.get('clientId');
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  changePage() {
    console.log(this.positionlistdata.length);
    if (this.search.length != 0) {
      this.p = 1;
      console.log(this.positionlistdata.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
      console.log(this.p);
      console.log('success Data');
    } else {
      this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.positionlistdata.length);
      console.log('nodata');
    }
  }

  sortData(sort: Sort) {
    //const data = this.resultData.slice();
    // const data = this.positionlistdata;
    // if (!sort.active || sort.direction === '')
    // {
    //   this.sortedData = data;
    //   return;
    // }

    // this.sortedData = data.sort((a, b) =>
    // {
    //   const isAsc = sort.direction === 'asc';

    //   switch (sort.active)
    //   {
    //     case 'positionName':
    //       return compare(a.positionName, b.positionName, isAsc);

    //     case 'minExperience':
    //       return compare(a.minExperience, b.minExperience, isAsc);

    //     case 'maxExperience':
    //         return compare(a.maxExperience, b.maxExperience, isAsc);

    //     case 'clientName':
    //       return compare(a.clientName, b.clientName, isAsc);

    //     case 'location':
    //       return compare(a.location, b.location, isAsc);

    //     case 'ctc':
    //       return compare(a.ctc, b.ctc, isAsc);

    //     case 'jobType':
    //       return compare(a.jobType, b.jobType, isAsc);

    //     default:
    //       return 0;
    //   }
    // });
    this.p=1;
    console.log('sort::', sort);

    console.log('this.positionlistdata::', this.positionlistdata);

    const data = this.positionlistdata.slice();
    console.log('check this codition::', !sort.active || sort.direction === '');

    if (!sort.active || sort.direction === '') {
      this.positionlistdata = data;
      // this.checkNoData();
      return;
    }

    this.positionlistdata = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'positionName':
          return compare(a.positionName, b.positionName, isAsc);

        case 'minExperience':
          return compare(a.minExperience, b.minExperience, isAsc);

        case 'maxExperience':
          return compare(a.maxExperience, b.maxExperience, isAsc);

        case 'clientName':
          return compare(a.clientName, b.clientName, isAsc);

        case 'location':
          return compare(a.location, b.location, isAsc);

        case 'ctc':
          return compare(a.ctc, b.ctc, isAsc);

        case 'jobType':
          return compare(a.jobType, b.jobType, isAsc);
          
        case 'positionStatuss':
          return compare(a.positionStatus, b.positionStatus, isAsc);
          case 'Status':
            return compare(a.status.listTypeValueName, b.status.listTypeValueName, isAsc);
  
        default:
          return 0;
      }
    });
    // this.checkNoData();
  }
  checkNoData() {
    this.noDataFound = this.positionlistdata.length === 0;
    this.nodatafound = true;

  }
  showChanges() {
    console.log('show Change');
    this.p = 1;
  }

  clearforms() {
    // this.advanceType=undefined
this.tableShow=false;
    this.positionlistdata = [];
    this.searchForm?.reset();
    this.nodatafound = true;
    this.btnDisable = false;
    this.btnsDisable = false;

    this.loader = false;
  }
} //Export end

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
