<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
  <h3 mat-dialog-title class="headerDesign">
    {{ roleId ? "Edit" : "Add" }} User Roles
  </h3>

  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="form-group col-md-12">
        <div style="height: 70px !important;">
          <label class="form-control-label"
            >Role Name <span class="star">*</span></label
          >
          <!-- <input
            type="text"
            (keyup)="checkExistRoleName($event)"
            formControlName="roleName"
            id="roleName"
            class="form-control email-input select-placeholder"
            value=""
            placeholder="Role Name"
            autocomplete="off"
          /> -->
            <mat-select
                    formControlName="roleName"
                    placeholder="Role Name"
                    class="form-control email-input select-placeholder no-border-on-focus"
                  >
                    <mat-option value="null" selected (click)="setRoleValues()"
                      >-Select Role-</mat-option
                    >
                    <mat-option
                      *ngFor="let row of activeRolesData; let i = index"
                      [value]="row.roleName"
                    >
                      {{ row.roleName }}
                    </mat-option>
                  </mat-select>
          <!-- <div class="valid-msg" *ngIf="validate.roleName.invalid  || validate.roleName.dirty || submitted">
                    <span *ngIf="validate.roleName.errors?.required" class="text-danger">Please Enter Role Name</span>
                    <span *ngIf="validate.roleName.errors?.pattern" class="text-danger">Please Enter valid  Role Name</span>
                    <span *ngIf="roleNameExistStatus" class="span-error"> Role Name already exist!</span>
                </div> -->
          <span
            *ngIf="validate.roleName && submitted"
            class="text-danger valid-msg"
            >{{ projectCode() }}
          </span>
          <span
            *ngIf="validate.roleName.errors?.pattern"
            class="text-danger valid-msg"
            >Please Enter valid Role Name</span
          >

          <span *ngIf="roleNameExistStatus" class="span-error">
            Role Name already exist</span
          >
        </div>
      </div>
    </div>

    <!-- <div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label class="form-control-label">Role Description</label>
                    <input type="text" formControlName="roleDescription" id="roleDescription" autocomplete="off" class="form-control email-input select-placeholder" value="" placeholder="Description">
                    <div class="valid-msg" *ngIf="(validate.roleDescription.invalid && validate.roleDescription.touched) || validate.roleDescription.dirty || submitted">
                        <span *ngIf="validate.roleDescription.errors?.pattern" class="text-danger">Please Enter Valid  Role Description</span>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="row">
      <div class="form-group col-md-12">
        <div style="height: 70px !important;">
          <label class="form-control-label"
            >Menus <span class="star">*</span></label
          >
          <mat-select
            formControlName="menuId"
            placeholder="Select Menu"
            #select
            multiple
            class="form-control email-input select-placeholder"
          >
            <div class="select-all">
              <mat-checkbox
                class="check-all select-all-btns"
                [(ngModel)]="allSelected"
                [ngModelOptions]="{ standalone: true }"
                (change)="toggleAllSelection()"
              >
                Select All
              </mat-checkbox>
            </div>

            <mat-option
              *ngFor="let row of activeMenusData"
              [value]="row.menuId"
              (click)="individualCheckboxChanged()"
            >
              {{ row.menuname }}
            </mat-option>

            <button
              mat-raised-button
             
              style="margin: 0 18px 18px 18px"
              (click)="add()"
              class="btn create-button"
            >
              Add
            </button>

            <button
              mat-raised-button
              color="danger"
              (click)="cancel()"
              class="btn cancel-button"
              style="color: var(--Red-Color, #ff5d5d); font-weight: 550"
            >
              Cancel
            </button>
          </mat-select>

          <div
            *ngIf="validate.menuId.hasError('required') && submitted"
          class="text-danger valid-msg"
          >
            Please Select Menus
          </div>

          <!-- <mat-select formControlName="roleId" class="form-select form-select-solid form-select-lg" appearance="fill" placeholder="- Select -">
                <mat-option value="">- Select -</mat-option>
                <mat-option *ngFor="let row of activeRolesData" [value]="row.roleId">{{row.roleName}}</mat-option>
            </mat-select> -->
          <!-- <div class="valid-msg" *ngIf="(validate.departmentCode.invalid && validate.departmentCode.touched) || validate.departmentCode.dirty || submitted">
                <span *ngIf="validate.departmentCode.errors?.required" class="text-danger">Please enter department code</span>
                <span *ngIf="validate.departmentCode.errors?.pattern" class="text-danger">Please enter valid department code</span>
                <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
            </div> -->
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      type="button"
      mat-button
      mat-dialog-close
      mat-raised-button
      class="cancelDesign"
      style="
        background: none;
        color: var(--Red-Color, #ff5d5d);
        border: 1px solid #ddd;
        font-size: 16px;
        font-weight: 550;
        margin-left: 5px;
        height: 35px;
      "
      [disabled]="isbtnDisable"
    >
      Cancel
    </button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
    <button
      type="submit"
      class="searchBtn  m-1"
      mat-raised-button
      [disabled]="isbtnDisable"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>

<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem; position: absolute; top: 47%; right: 48%"
    role="status"
  ></div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
