import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Calendar } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { PayrollService } from 'src/app/providers/payroll.service';
import { AppComponent } from '../app.component';



@Component({
  selector: 'app-employee-annexure-list',
  templateUrl: './employee-annexure-list.component.html',
  styleUrls: ['./employee-annexure-list.component.scss']
})
export class EmployeeAnnexureListComponent implements OnInit {
  isProcessing = false;
  showtable:boolean=false;
  loader:any= false;
  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any = [];
  selected :true;
 
  backdrop:any= false;
  userdata: any;
  organizationId: any;
  clientId:any;
  searchForm:FormGroup;
  sortedData: any;
  p:number = 1;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=5;
  dateFormat: any;
  totalmontlyincome: Number = 0;
  totalannualincome: Number = 0;
  min: string;
  show_error_msg: boolean=false;
  startdate_required: boolean;
  enddate_required: boolean;
  max: string;
  constructor(  private formBuilder: FormBuilder,
    private router : Router,
    private payrollService: PayrollService, private datepipe : DatePipe ,
    private ActivatedRoute:ActivatedRoute,@Inject(AppComponent) public AppData: any ) { }

  ngOnInit(): void {

      this.noRecordsFound = true;
    this.searchForm = this.formBuilder.group({
      employee: [null,Validators.required], // Initialize with null
      startDateSearch:[null],
      endDateSearch:[null]
    });

    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    console.log("date format;", this.dateFormat);
    // this.dateFormat = this.AppData.dateFormat;
    let userId = this.userdata.userId;
     this.organizationId = this.userdata.organization.organizationId;
     this.clientId = this.userdata?.employee?.client?.clientId;
  }

  

  searchSubmit()
  {
    this.totalannualincome=0;
  this.totalmontlyincome=0;
    if (this.serachvalidate.startDateSearch.value>this.serachvalidate.endDateSearch.value) {
      // this.show_error_msg=true;
      console.log("date In Vaild");
      return

    }
    // this.show_error_msg = false;

    this.showtable=true;
    this.loader=true;
    this.isProcessing = true;  // Disable the button


    this.p=1;
    this.resultData = [];
    this.noRecordsFound = false;
    console.log('search submit')
    let employee = this.searchForm.value.employee;
    console.log('employee', employee)
   
    let  startDateSearch = this.searchForm.value.startDateSearch;
    let  endDateSearch = this.searchForm.value.endDateSearch;
    console.log('startDateSearch', startDateSearch); console.log('endDateSearch', endDateSearch)

    if((!employee || employee == 'null') && (!startDateSearch || startDateSearch == 'null') &&  (!endDateSearch || endDateSearch == 'null'))
    {
      alert('Please select employee or date.');
      // return;
        // this.getannexuelist();
        this.isProcessing = false; // Enable the button
       this.loader=false;
       this.showtable=false;
    }
    else if(this.searchForm.invalid){
      console.log("Choose valid fields");

      alert("Please Enter Requried Fields");
      this.loader = false;
      this.p=1;
      this.resultData = [];
      this.noRecordsFound = false;
      this.showtable = false;
      this.isProcessing = false;
      return
    }

    // if ((this.searchForm.value.employee === null || this.searchForm.value.employee === 'null') && (this.searchForm.value.startDateSearch === null || this.searchForm.value.startDateSearch === 'null') && (this.searchForm.value.endDateSearch === null || this.searchForm.value.endDateSearch === 'null')) {
    //   console.log("Required Field");
    //   this.resultData = [];
    //   this.noRecordsFound = false;
    //   alert("Please Enter Requried Fields");
    //   this.isProcessing = false;
    //   this.loader = false

    //   return;
    // }
 
  

     if(((startDateSearch  &&  !endDateSearch ) || (!startDateSearch  &&  endDateSearch ) )&&  (employee || employee != 'null'))
     {
      alert('Please select startDate and endate.');
      return;
     }
     
      // if ((!startDateSearch || startDateSearch === 'null') && (endDateSearch || endDateSearch != 'null') || (startDateSearch || startDateSearch != 'null') && (!endDateSearch || endDateSearch === 'null'))
      // {
      //   alert('Please select startDate and endate.');
      //   return;
      // }
     
  

    if( (employee && employee != 'null') && (startDateSearch && startDateSearch != 'null')  &&  (endDateSearch && endDateSearch != 'null'))
    {
      // this.loader=true;
      this.backdrop=true;
        let startDate = this.datepipe.transform(startDateSearch, 'yyyy-MM-dd');
        let endDate = this.datepipe.transform(endDateSearch, 'yyyy-MM-dd');

       if(this.organizationId){
      this.payrollService.getannexuebyEmployeeAndDate(employee,startDate ,endDate ,this.organizationId ).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.isProcessing = false; // Enable the button

        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
    
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
          this.resultData.length = 0;
        }else{
          this.resultData.forEach(element => {
            this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
            this.totalannualincome = this.totalannualincome + element.annualIncome;
        });
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }
      })
    }
    if(this.clientId){
      this.payrollService.getannexuebyEmployeeAndDateClient(employee,startDate ,endDate ,this.clientId ).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.isProcessing = false; // Enable the button

        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;
    
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
          this.resultData.length = 0;
        }else{
          this.resultData.forEach(element => {
            this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
            this.totalannualincome = this.totalannualincome + element.annualIncome;
        });
        }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }
      })
    }
    }
    else if (employee  && !startDateSearch   &&!endDateSearch  )
    {
      this.backdrop=true;
      if(this.organizationId){
      this.payrollService.getannexuebyEmployee(employee, this.organizationId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.isProcessing = false; // Enable the button

        this.loader=false;
                this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        

          if(this.resultData.length == 0 || this.resultData.length == undefined)
          {
            this.nodatafound = true;
            this.resultData.length = 0;
          }else{
            this.resultData.forEach(element => {
              this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
              this.totalannualincome = this.totalannualincome + element.annualIncome;
          });
          }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }
      })
    }

    if(this.clientId){
      this.payrollService.getannexuebyClientEmployee(employee, this.clientId).subscribe((resultData:any) =>
      {
        this.showtable=true;
        this.isProcessing = false; // Enable the button

        this.loader=false;
                this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        

          if(this.resultData.length == 0 || this.resultData.length == undefined)
          {
            this.nodatafound = true;
            this.resultData.length = 0;
          }else{
            this.resultData.forEach(element => {
              this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
              this.totalannualincome = this.totalannualincome + element.annualIncome;
          });
          }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }
      })
    }
    }
  }

  get serachvalidate() { return this.searchForm.controls; }

  updateform(row)
  {
     this.router.navigateByUrl(`/home/employee-annexue/${row.annexureHeaderId}`)
  }


  updateStatus(annexue ,status)
  {

  }
  pageChages()
{
  this.p=1;
}

getannexuelist()
{
  this.loader=true;
      this.backdrop=true;
      this.payrollService.getannexuebyorg(this.organizationId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        

          if(this.resultData.length == 0 || this.resultData.length == undefined)
          {
            this.nodatafound = true;
            this.resultData.length = 0;
          }else{
            this.resultData.forEach(element => {
              this.totalmontlyincome =  this.totalmontlyincome + element.monthlyIncome;
              this.totalannualincome = this.totalannualincome + element.annualIncome;
          });
          }
        if( resultData.statusCode == 204){
          this.noRecordsFound = true;
          this.totalmontlyincome =  0;
          this.totalannualincome =0;
        }

      });
}

// someMethodName(date: any) {
//   this.min = new Date(date.value)
//   // this.endDate_disable = false;

//   let w = this.datepipe.transform(this.serachvalidate.endDateSearch.value, 'YYYY-MM-dd');
//   let v = this.datepipe.transform(this.min, 'YYYY-MM-dd');
//   if(w != null){
//     if(w < v){
//       this.show_error_msg = true;
//     }
//   }
// }

startDate(event){
  this.startdate_required=false;
  console.log("start Date:"+event.value);
  this.show_error_msg = false;
  this.min = this.datepipe.transform(event.value, 'yyyy-MM-dd');
  if(this.max != null){
    if(this.max < this.min){
      console.log(this.max);
      this.show_error_msg = true;
    }
  }
}
EndDate(event){
  this.enddate_required=false;
  this.show_error_msg = false;
  console.log("start Date:"+event.value);
  this.max = this.datepipe.transform(event.value, 'yyyy-MM-dd');
}

clearTable()
{
  this.totalannualincome=0;
  this.totalmontlyincome=0;
  
  this.resultData = [];
  this.noRecordsFound = false;
  console.log('clear')
  this.searchForm.clearValidators;
  this.showtable=false;
  // this.isProcessing=false;

  // this.payrollService.get().subscribe((elementslistData:any) =>
  // {
  //   console.log('elementslistData',elementslistData)
  //   this.resultData = elementslistData;
  //   console.log(this.resultData);
  //   if(this.resultData.length == 0)
  //   {this.noRecordsFound = true;
  //     this.nodatafound = true;
  //     console.log("nodata");
  //   }
  //   if(elementslistData.statusCode == 204){
  //     this.noRecordsFound = true;
  //   }
  // },
  // (error) => {
  //   this.nodatafound = true;
  //   console.log("error")
  //   if(error.status == 404){
  //     this.nodatafound = true;
  //     console.log("404")
  //   }
  // }
  // )
}


}
