import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecruitmentService } from '../providers/recruitment.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { Router } from '@angular/router';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';

@Component({
  selector: 'app-client-tax-breakage',
  templateUrl: './client-tax-breakage.component.html',
  styleUrls: ['./client-tax-breakage.component.scss']
})
export class ClientTaxBreakageComponent implements OnInit {
  
  clienttaxbreakup:FormGroup;
  filterForm:FormGroup;
  clientdata: any={};
  usertData:any;
  dateFormat: any;
  orgId:number;
  noRecordsFound: boolean =false;
  clientList:any;
  beforesearchpage:number;
  error:any=false;
  resultData : any = [];
  submitted: boolean = false;
  errormessagejson: any;
  success = false;
  sucess_msg:any;
  error_msg:any;
  clientNames:any
  p: number = 1;
  tableSearch : any;
  errorMsg : any;
  finYearList: any=[];
  pagePerItem:any=5;
  chooseFinId:any;
  finPeriodList: any=[];
  importerror: boolean = false;
  taxbreakageId:number;
  filterData: any=[];
  chooseFinancialyearId: any;
  financialPeriodList: any[];
  perId: any;
  staus: any;
  btnDisablesearch:boolean=false;
  entityId:any;
  mySelect:number=5
btndisable: any;
  loader: boolean=false;
  clientId:any;
  tableShow:boolean=false
  clientLogin:boolean=false;
  organizationLogin:boolean=false;
  entityAdminLogin:boolean=false;
  constructor( private recruitmentservice :RecruitmentService,
    private fb:FormBuilder,
    private dialog:MatDialog,
    private router:Router,
    private settingsService: SettingsService,
    private payrollservice:PayrollService) { }

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.entityId = this.usertData?.entity?.entityId;

    this.noRecordsFound = true;
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    // this.clientNames=this.usertData?.employee?.client?.clientName;
    console.log("this.clientNames",this.clientNames);
    
    console.log(this.orgId);
    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;
      console.log('this.organizationLogin', this.organizationLogin);
    } else if (this.usertData?.employee?.client) {
      this.clientLogin = true;
     this.clientId = this.usertData?.employee?.client?.clientId;
     this.clientNames=this.usertData?.employee?.client?.clientName;


    } else {
      this.entityAdminLogin = true;

    }
    this.clienttaxbreakup= this.fb.group({
      client:[null],
      salaryStartRange:[null,[Validators.required]],
      salaryEndRange:[null,[Validators.required]],
      taxAmount:[null,[Validators.required]]
    
    })
    this.filterForm = this.fb.group({
      filterclient:[null],
   
    })

    if(this.clientLogin == true){
      this.filterForm.get('filterclient')?.patchValue(this.clientNames);
      this.filterForm.get('filterclient')?.disable();
    }
      // console.log('Filterorg Disabled:', this.filterForm);/
      if(this.clientLogin == true){

      this.clienttaxbreakup.get('client')?.patchValue(this.clientNames);
      this.clienttaxbreakup.get('client')?.disable();
      // console.log('Filterorg Disabled:', this.filterForm);

    }
   
    
    this.payrollservice.getClientNameListBasedEntity(this.entityId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client?.status?.listTypeValueId == 1);;
      console.log(this.clientList);
    })   

    

  }
  updateform(row)
  {
   // this.clearForm();
   console.log("clear Form",row);
   this.taxbreakageId=row.taxBreakageId;
   console.log(this.taxbreakageId);
   this.loader=true;
   this.payrollservice.gettaxbreagebyId(this.taxbreakageId).subscribe((resultData:any) =>
   {
     console.log("Api1",resultData);
     this.resultData=resultData;
     this.loader=false
     this.staus=resultData.taxBreakageStatus.listTypeValueId;
     console.log(this.staus);
     console.log(this.resultData);
     console.log(this.clienttaxbreakup);

            console.log("this.clienttaxbreakup 1", this.clienttaxbreakup.value);
            this.clienttaxbreakup.patchValue({
             client : this.clientNames,
             salaryStartRange:this.resultData.salaryStartRange,
             salaryEndRange:this.resultData.salaryEndRange,
             taxAmount:this.resultData.taxAmount
           });
   })
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  changefirstpage() {
    this.p = 1;
  }
  searchSubmit()
  {
    this.filterData=[];
    console.log("Submit",this.filterForm.value);
    if((this.filterForm.value.filterclient==="null"||this.filterForm.value.filterclient===null))
    {
      alert("Please Select Client Name");
      this.filterData=[];
      this.noRecordsFound = true;
      return;
    }

    if(this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)
    {
      console.log("ClientID");
      this.loader=true;
      this.tableShow=false;
      this.btnDisablesearch=true
      this.payrollservice.gettaxbreakupbyclient(this.clientId).subscribe((filterData:any) =>
      {
        this.tableShow=true;
        this.loader=false;
        this.btnDisablesearch=false

        this.noRecordsFound = false;
        this.filterData = filterData;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;
          this.tableShow=true

        }
      })
    }
    
  }
 
   selectFilterFinancialyear(event)
  {
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinancialyearId=event.target.value;
    console.log(this.chooseFinancialyearId);
    if(this.chooseFinancialyearId == null || this.chooseFinancialyearId == 'null'){
      console.log('if');
      this.financialPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinancialyearId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.financialPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        filterperiod: null,
      });
    })
  }
  }
//    onSubmit()
//    {
//     // this.submitted=true;
//     this.btndisable=true
//     if(this.clienttaxbreakup.invalid)
//     {
//       this.btndisable=false
//       console.log("form invaild");
//       return;
//     }
//     console.log("submit",this.clienttaxbreakup.value);
//     console.log(this.taxbreakageId);
//     if(this.taxbreakageId)
//     {
//       console.log("Update",this.taxbreakageId);
//       let  updateclienttaxbreakage= this.clienttaxbreakup.value;
//       updateclienttaxbreakage['taxBreakageId'] = this.taxbreakageId;
//       updateclienttaxbreakage['client'] = { clientId:this.clientId } ;

//       updateclienttaxbreakage['taxBreakageStatus'] = { listTypeValueId:this.staus } ;
     
//       console.log("update payload:",updateclienttaxbreakage);
//     // return;
//     // this.btndisable=true

//       this. payrollservice.updateClientTaxBreakage(this.taxbreakageId,updateclienttaxbreakage).subscribe((result: any) =>
//         {
       
//           // this.btndisable=true;
//           const currentClientName = this.clientNames;


//           this.clienttaxbreakup.reset();
    
//           // Reapply values for specific fields (e.g., organizationName)
//           this.clienttaxbreakup.patchValue({
//             client: currentClientName,
//           });

//           if(result.statusCode == 200)
//           {
//             this.success = true;
//             // this.taxbreakageId=0;
//             // this.btndisable=true
//             // this.submitted=false;
//             // this.clienttaxbreakup.reset()

//             console.log("this.taxbreakageId"+this.taxbreakageId);
//             this.sucess_msg = result.description;
//             this.clienttaxbreakup.updateValueAndValidity();
//             this.clienttaxbreakup.markAsPristine();
//             this.clienttaxbreakup.markAsUntouched();
//             // this.clienttaxbreakup.controls['salaryStartRange']?.patchValue('');
//             // this.clienttaxbreakup.get('salaryEndRange')?.patchValue('')
//             // this.clienttaxbreakup.get('taxAmount')?.patchValue('')
           
//             if(this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)
//             {
//               console.log("Filter Call Method",this.filterForm.value);
//               this.searchSubmit();
//             }
//             if((this.filterForm.value.filterclient==null || this.filterForm.value.filterclient== 'null' )){
//               console.log("Without",this.filterForm.value);
      
//             }
//             // if((this.filterForm.value.filterclient==null || this.filterForm.value.filterclient== 'null' ))
//             // {
//             //   console.log("Without",this.filterForm.value);
//             //   // this.searchSubmit();
//             // }
//             // else{
//             //   console.log("Filter Call Method",this.filterForm.value);
//             //   this.searchSubmit();
//             // }
//             setTimeout(() => {this.success  = false;this.submitted=false;
//               this.btndisable=false
//             },5000)
//             // this.searchSubmit();
//             // this.clienttaxbreakup.reset()
//             // this.clientupdateId = null;
//             // this.clienttaxbreakup.reset();
            
//             // setTimeout(() => {
//             //   this.router.navigate(["home/employee-rating-list"])
//             // }, redirectMsgTimeOut)
//           }
//           // else if(result.statusCode == 409)
//           // {
//           //   console.log("Already Exist");
//           // this.importerror = true;
//           // // this.error = true;
//           // this.error_msg = result.description;
//           // this.errormessagejson = result;
//           // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
//           // }
//           else
//           {
//           console.log("err1",result.statusCode);
//           this.error = true;
//          // this.importerror = true;
//         //  this.errormessagejson = result;
//           // console.log('this.errormessagejson',this.errormessagejson);
//             this.error_msg = result.description;
//           setTimeout(() => {this.error = false}, 5000)
//           }
//         },
//          err =>
//             {
//                 console.log("code1",err.statusCode);
//                 setTimeout(() => {
//                   this.error = true;
//                   this.errorMsg = err.error.description;
//                 }, 2000) 
//             })
//     }
//     else
//     {
//       // this.btndisable=true

//       console.log("create");


//       let  createtaxbreakage= this.clienttaxbreakup.value;
      
      
//       // createtaxbreakage['client'] =
//       //   {
//       //       clientId:this.clientId
//       //   };

//         const payload={
//        client:{
//         clientId:this.clientId
//        },
//           salaryStartRange:createtaxbreakage.salaryStartRange,
//           salaryEndRange:createtaxbreakage.salaryEndRange,
//           taxAmount:createtaxbreakage.taxAmount
  
//         }
       
    
//     console.log("payload::",payload);
//   //  return
//     this.payrollservice.createClientTaxBreakup(payload).subscribe((result: any) =>
//     {
      
//       console.log("Create",result);
//       const currentClientName = this.clientNames;


//       this.clienttaxbreakup.reset();

//       // Reapply values for specific fields (e.g., organizationName)
//       this.clienttaxbreakup.patchValue({
//         client: currentClientName,
//       });
//      if(result.statusCode == 200)
//      {
//       this.btndisable=true

//        this.success = true;
//        this.submitted=false;
// // this.clienttaxbreakup.reset()
//        this.sucess_msg = result.description; 
//        this.clienttaxbreakup.controls['salaryStartRange']?.patchValue('');
//       this.clienttaxbreakup.get('salaryEndRange')?.patchValue('')
//       this.clienttaxbreakup.get('taxAmount')?.patchValue('')
//       //  if((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)||(this.filterForm.value.filterfinancialyear=="null"||(this.filterForm.value.filterfinancialyear==null))||((this.filterForm.value.filterfinancialyear=="null")||(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null))||
//       //  ((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null)))
//       //  {
//       //    console.log("Filter Call Method",this.filterForm.value);
//       //   //  this.searchSubmit();
//       //  }
//       if((this.filterForm.value.filterclient==null))
//       {
//         console.log("Without",this.filterForm.value);
//         // this.searchSubmit();
//       }
//       else{
//         console.log("Filter Call Method",this.filterForm.value);
//         this.searchSubmit();
//       }
//        setTimeout(() => {this.success  = false;      this.btndisable=false
//        },5000)
//       //  this.clienttaxbreakup.reset();     
//        this.submitted = false;
//      }
//     //  else if(result.statusCode == 409)
//     //       {
//     //         console.log("Already Exist");
//     //         // this.error = true;
//     //       this.importerror = true;
//     //       this.error_msg = result.description;
//     //    this.errormessagejson = result;
//     //    setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         
//     //       }
//      else
//      {
//        console.log("err1",result.statusCode);
//       //  console.log("err1",result.clientCode);
//        this.error = true;
//        this.btndisable=false

//       //  this.importerror = true;
//    //    this.errormessagejson = result;
//       //  console.log('this.errormessagejson',this.errormessagejson);
//       this.error_msg = result.description;
//        setTimeout(() => {this.error = false;this.btndisable=false}, 5000)
//      }
//    }, 
//    err =>
//    {
//      console.log("code",err);
//      console.log("code",err.statusCode);
//        setTimeout(() => {
//          this.error = true;
//          this.errorMsg = err.error.description;
//        }, 5000) 
//    })
      
//     }
    
//    }

//    updateform(row)
//    {
//     // this.clearForm();
//     console.log("clear Form",row);
//     this.taxbreakageId=row.taxBreakageId;
//     console.log(this.taxbreakageId);
//     this.loader=true;
//     this.payrollservice.gettaxbreagebyId(this.taxbreakageId).subscribe((resultData:any) =>
//     {
//       console.log("Api1",resultData);
//       this.resultData=resultData;
//       this.loader=false
//       this.staus=resultData.taxBreakageStatus.listTypeValueId;
//       console.log(this.staus);
//       console.log(this.resultData);
//       console.log(this.clienttaxbreakup);

//              console.log("this.clienttaxbreakup 1", this.clienttaxbreakup.value);
//              this.clienttaxbreakup.patchValue({
//               client : this.clientNames,
//               salaryStartRange:this.resultData.salaryStartRange,
//               salaryEndRange:this.resultData.salaryEndRange,
//               taxAmount:this.resultData.taxAmount
//             });
//     })
//    }
onSubmit() {
  this.btndisable = true;

  // Validate form
  if (this.clienttaxbreakup.invalid) {
      this.btndisable = false;
      console.log("Form invalid");
      return;
  }

  console.log("Submit:", this.clienttaxbreakup.value);

  if (this.taxbreakageId) {
      // Update flow
      console.log("Update:", this.taxbreakageId);

      const updatePayload = {
          ...this.clienttaxbreakup.value,
          taxBreakageId: this.taxbreakageId,
          client: { clientId: this.clientId },
          taxBreakageStatus: { listTypeValueId: this.staus },
      };

      console.log("Update payload:", updatePayload);

      this.payrollservice.updateClientTaxBreakage(this.taxbreakageId, updatePayload).subscribe(
          (result: any) => this.handleResponse(result, "update"),
          (err) => this.handleError(err)
      );
  } else {
      // Create flow
      console.log("Create");

      const createPayload = {
          client: { clientId: this.clientId },
          salaryStartRange: this.clienttaxbreakup.value.salaryStartRange,
          salaryEndRange: this.clienttaxbreakup.value.salaryEndRange,
          taxAmount: this.clienttaxbreakup.value.taxAmount,
      };

      console.log("Create payload:", createPayload);

      this.payrollservice.createClientTaxBreakup(createPayload).subscribe(
          (result: any) => this.handleResponse(result, "create"),
          (err) => this.handleError(err)
      );
  }
}

// Common Response Handler
handleResponse(result: any, action: "create" | "update") {
  const isSuccess = result.statusCode === 200;
  console.log(`${action === "create" ? "Create" : "Update"} Response:`, result);

  if (isSuccess) {
      this.success = true;
      this.sucess_msg = result.description;
      this.clienttaxbreakup.reset();
      this.taxbreakageId = null; // Clear the ID after successful operation
      this.btndisable = false;

      if (this.filterForm.value.filterclient != null) {
          this.searchSubmit();
      }

      setTimeout(() => {
          this.success = false;
      }, 5000);
  } else {
      this.error = true;
      this.error_msg = result.description;
      setTimeout(() => {
          this.error = false;
          this.btndisable = false;
      }, 5000);
  }
}

// Common Error Handler
handleError(err: any) {
  console.error("Error:", err);
  this.error = true;
  this.error_msg = err.error?.description || "An error occurred";
  setTimeout(() => {
      this.error = false;
      this.btndisable = false;
  }, 5000);
}

clientWorkingDaysIdage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

selectFinancialyear(event)
{
  console.log("Select indidual Employee");
  console.log(event.target.value);
  // console.log(this.filterForm.value.financialyear);
   this.chooseFinId=event.target.value;
  console.log(this.chooseFinId);
  if(this.chooseFinId == null || this.chooseFinId == 'null'){
    console.log('if');
    this.finPeriodList = [];
  }
  else{
  this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => 
  {
    console.log("PeriodData", resultData)
    this.finPeriodList = resultData.finanicalPeriod;
    console.log("finPeriodList",  this.finPeriodList)
    this.clienttaxbreakup.patchValue({
      period: null,
    });
  })
  console.log("Inside selectFinancialyear func",this.clienttaxbreakup);
}
}

clearTable()
{
  // this.resultData = [];
  // this.noRecordsFound = false;
  this.filterData=[];
  this.submitted=false;
  this.tableShow=false
  this.noRecordsFound = true;
  console.log('clear')
  this.filterForm.clearValidators;
  const currentClientName =this.clientNames

  this.filterForm.patchValue({
    filterclient: currentClientName, // Preserve the current client name
            // Reset other fields
  });
  this.filterForm.get('filterclient')?.disable();
}

clearForm() 
{
  this.submitted=false;
  this.clienttaxbreakup.clearValidators;
  const currentClientName =this.clientNames

  this.clienttaxbreakup.patchValue({
    client: currentClientName, // Preserve the current client name
    salaryStartRange:null,
      salaryEndRange:null,
      taxAmount:null
  
  });
  this.clienttaxbreakup.get('client')?.disable();
  // this.clienttaxbreakup.reset(); // This resets all form controls to their initial values
}

pageChages()
{
    console.log("page changes");
    this.p=1;
}
get validate() 
{ 
  return this.clienttaxbreakup?.controls; 
}
  
showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

 updateStatus(id,status)
	{
    this.payrollservice.deactiveWorkingDays(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},5000)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.description;
          setTimeout(() => {this.error = false}, 5000)
      }
		})
	}
}
