import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmpTimesheetRoutingModule } from './emp-timesheet-routing.module';
import { EmpTimesheetComponent } from './emp-timesheet.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { AssignEmpTaskComponent } from './assign-emp-task/assign-emp-task.component';
import { EmpTimesheetPipe } from './emp-timesheet.pipe';
import { MaterialDesign } from 'src/app/material/material.module';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    EmpTimesheetComponent,
    AssignEmpTaskComponent,
    EmpTimesheetPipe
  ],
  imports: [
    CommonModule,
    EmpTimesheetRoutingModule,
    MaterialDesign,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxMatSelectSearchModule
  ],
  providers:[    { provide: MAT_DATE_FORMATS, useValue: 'en-GB'},
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, // Replace 'en-US' with your desired locale

  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, ]
})
export class EmpTimesheetModule { }
