import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { MatDialog } from '@angular/material/dialog';
import { ImportEmployeerevenueadjustmentComponent } from '../import-employeerevenueadjustment/import-employeerevenueadjustment.component';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { ShowerrorComponent } from '../showerror/showerror.component';

@Component({
  selector: 'app-client-employee-revenue-adjustment-list',
  templateUrl: './client-employee-revenue-adjustment-list.component.html',
  styleUrls: ['./client-employee-revenue-adjustment-list.component.scss'],
})
export class ClientEmployeeRevenueAdjustmentListComponent implements OnInit {
  filterForm: FormGroup;
  finYearList: any = [];
  categoryNameList: any = [];
  finPeriodList: any = [];
  nodatafound: boolean = true;
  update_error_msg: any;
  error_msg: any;
  loader: any = false;
  backdrop: any = false;
  resultData: any = [];
  noRecordsFound: boolean = false;
  pagePerItem: any = 10;
  p: number = 1;
  tableSearch: any;
  chooseFinId: any;
  list_loader = true;
  employeetData: any;
  categoryTypeData: any;
  categoryId: any;
  categoryName: any;
  usertData: any;
  dateFormat: string;
  orgId: any;
  employeeId: any;
  empList: any = [];
  employeeNumber: any;
  maxstartdate: Date;
  employee: any;
  clientList: any = [];
  success: boolean;
  sucess_msg: any;
  error: boolean;
  importerror: boolean;
  errormessagejson: any;

  constructor(
    private settingsService: SettingsService,
    private payrollservice: PayrollService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.noRecordsFound = true;
    this.settingsService.getFinicalyearList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      resultData.forEach((item: any) => {
        // console.log(item.financialYearStatus.listTypeValueId)
        // console.log(item.financialYearStatus.listTypeValueId == 1)
        if (item.financialYearStatus.listTypeValueId == 1) {
          console.log(item.financialYearStatus.listTypeValueId);
          this.finYearList.push(item);
          console.log(' this.finYearList', this.finYearList);
        }
      });
    });

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;

    // this.settingsService.getCategoryNameList(organizationId).subscribe((resultData: any) => {
    //   console.log(resultData);
    //   this.categoryNameList = resultData;
    // });

    this.payrollservice.getEarningsCategoryList().subscribe((result: any) => {
      // this.filterForm.get('category').patchValue(result[0].listTypeValueId);
      this.categoryTypeData = result;
      this.categoryId = this.categoryTypeData[0].listTypeValueId;
      this.categoryName = this.categoryTypeData[0].listTypeValueName;
      console.log('result', this.categoryId);
      this.payrollservice
        .getElementListValues(this.categoryId, this.orgId)
        .subscribe((d: any) => {
          console.log('element', d);
          this.categoryNameList = d;
        });
    });

    this.filterForm = this.formBuilder.group({
      employeecode: [null],
      financialyear: [null],
      period: [null],
      element: [null],
      client: [null],
      // employee: [null, []],
      // remarks: [null, []],
      // Rows: this.formBuilder.array([
      // ]),
    });

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
  }
  // getclientEmployees()
  // {
  //   this.payrollservice
  //     .getClientNameList(this.orgId)
  //     .subscribe((result: any) => {
  //       this.clientList = result.filter(
  //         (client) => client.status.listTypeValueId == 1
  //       );
  //       console.log(this.clientList);
  //     });
  // }

  getclientEmployees() {
    this.empList.length = 0;
    console.log(this.filterForm.value.client);
    this.filterForm.value.employeecode = null;
    this.filterForm.patchValue({
      employeecode: null,
    });
    if (
      this.filterForm.value.client != null &&
      this.filterForm.value.client != 'null'
    ) {
      this.payrollservice
        .getemployeeclient(this.filterForm.value.client)
        .subscribe((ClientEmployees: any) => {
          this.empList = ClientEmployees;
        });
    }
  }

  empSearch(event) {
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    // Employee List Api
    if (employeenumber) {
      // this.payrollService.getEmployeeNameList(employeenumber, this.organizationId).subscribe((result: any) => {
      //    if(result.statusCode)
      //    {
      //     this.empList.length = 0;
      //    }else{
      //      console.log("else")
      //     this.empList = result.filter(emp=> emp.status.listTypeValueId == 1);
      //    }

      // })
      this.payrollservice
        .getClienEmployeeList(employeenumber, this.filterForm.value.client)
        .subscribe((result: any) => {
          this.empList.length = 0;
          if (result.statusCode) {
          } else {
            this.empList = result;
          }
        });
    }
  }

  importrevenueadjustment() {
    console.log('import Employee');
    const dialogRef = this.dialog.open(
      ImportEmployeerevenueadjustmentComponent,
      {
        //height: 'auto',width: '600px',
        width: '500px',
        height: 'fit-content',
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      //this.departmentList();
      if (result.statusCode == 200) {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {
          this.success = false;
        }, responseMsgTimeOut);
      } else {
        if (result.length != 0) {
          console.log('Import Error');
          this.error = true;
          //this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, responseMsgTimeOut)
        } else {
          if (result.statusCode > 400) {
            this.error = true;
            this.importerror = false;
          }
        }
      }
    });
  }

  empSelect(emp) {
    // console.log(emp);
    this.employeeId = emp.employeeId;
    this.employee = emp.employeeNumber + '-' + emp.firstName;
    this.employeeNumber = emp.employeeNumber;
    this.filterForm.patchValue({
      employeecode: this.employee,
    });

    console.log(this.employee);
  }
  
  showerrormessage() {
    const dialogRef = this.dialog.open(ShowerrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      height: 'fit-content',
      data: this.errormessagejson
    });
    this.error = false;
  }

  pageChages(event) {
    console.log('event.target.value', event.target.value);
    if (event.target.value != null || event.target.value != 0) {
      // this.p = event.target.value;
      console.log('page changes');
      this.p = 1;
    }
  }
  selectFinancialyear(event) {
    console.log('Select');
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    this.chooseFinId = event.target.value;
    console.log(this.chooseFinId);
    if (this.chooseFinId == null || this.chooseFinId == 'null') {
      console.log('if');
      this.finPeriodList = [];
    } else {
      this.settingsService
        .getFinancialYearId(this.chooseFinId)
        .subscribe((resultData: any) => {
          console.log('PeriodData', resultData);
          this.finPeriodList = resultData.finanicalPeriod;
          this.filterForm.patchValue({
            period: null,
          });
        });
    }
  }
  clearTable() {
    this.resultData = [];
    console.log('clear');
    this.filterForm.clearValidators;
    this.noRecordsFound = true;
  }

  searchSubmit() {
    console.log(this.filterForm.value);
    if (
      this.filterForm.value.employeecode == null ||
      this.filterForm.value.employeecode == ''
    ) {
      if (
        this.filterForm.value.client == null ||
        this.filterForm.value.client == '' ||
        this.filterForm.value.client == 'null'
      ) {
        alert('Please select client and employee');
        return;
      } else {
        alert('Please select employee');
        return;
      }
    }
    if (
      this.filterForm.value.element == null ||
      this.filterForm.value.element == 'null'
    ) {
      if (
        this.filterForm.value.period == null ||
        this.filterForm.value.period == 'null'
      ) {
        if (
          this.filterForm.value.financialyear == null ||
          this.filterForm.value.financialyear == 'null'
        ) {
          console.log('1');
          this.payrollservice
            .getemployeeRevenueByClientandemployee(
              this.employeeId,
              this.filterForm.value.client
            )
            .subscribe(
              (resultData1: any) => {
                this.noRecordsFound = false;
                this.resultData = resultData1;
                console.log('this.resultData', this.resultData);
                if (this.resultData.statusCode == 204) {
                  this.noRecordsFound = true;
                  console.log('nodata');
                }
              },
              (error) => {
                this.list_loader = false;
                this.noRecordsFound = true;
                console.log('error');
                if (error.status == 404) {
                  this.noRecordsFound = true;
                  console.log('404');
                }
              }
            );
        } else {
          console.log('5');
          this.payrollservice
            .getemployeeRevenueByClientandemployeeFinancialyear(
              this.employeeId,
              this.filterForm.value.client,
              this.filterForm.value.financialyear
            )
            .subscribe(
              (resultData1: any) => {
                this.noRecordsFound = false;
                this.resultData = resultData1;
                console.log('this.resultData', this.resultData);
                if (this.resultData.statusCode == 204) {
                  this.noRecordsFound = true;
                  console.log('nodata');
                }
              },
              (error) => {
                this.list_loader = false;
                this.noRecordsFound = true;
                console.log('error');
                if (error.status == 404) {
                  this.noRecordsFound = true;
                  console.log('404');
                }
              }
            );
        }
      } else {
        console.log('6');
        this.payrollservice
          .getemployeeRevenueByClientandemployeePeriod(
            this.employeeId,
            this.filterForm.value.client,
            this.filterForm.value.period
          )
          .subscribe(
            (resultData1: any) => {
              this.noRecordsFound = false;
              this.resultData = resultData1;
              console.log('this.resultData', this.resultData);
              if (this.resultData.statusCode == 204) {
                this.noRecordsFound = true;
                console.log('nodata');
              }
            },
            (error) => {
              this.list_loader = false;
              this.noRecordsFound = true;
              console.log('error');
              if (error.status == 404) {
                this.noRecordsFound = true;
                console.log('404');
              }
            }
          );
      }
    } else {
      if (
        this.filterForm.value.period == null ||
        this.filterForm.value.period == 'null'
      ) {
        if (
          this.filterForm.value.financialyear == null ||
          this.filterForm.value.financialyear == 'null'
        ) {
          console.log('2');
          this.payrollservice
            .getemployeeRevenueByClientandemployeeAndpayslipelement(
              this.employeeId,
              this.filterForm.value.client,
              this.filterForm.value.element
            )
            .subscribe(
              (resultData1: any) => {
                this.noRecordsFound = false;
                this.resultData = resultData1;
                console.log('this.resultData', this.resultData);
                if (this.resultData.statusCode == 204) {
                  this.noRecordsFound = true;
                  console.log('nodata');
                }
              },
              (error) => {
                this.list_loader = false;
                this.noRecordsFound = true;
                console.log('error');
                if (error.status == 404) {
                  this.noRecordsFound = true;
                  console.log('404');
                }
              }
            );
        } else {
          console.log('3');
          this.payrollservice
            .getemployeeRevenueByClientandemployeeAndpayslipelementFinancialyear(
              this.employeeId,
              this.filterForm.value.client,
              this.filterForm.value.element,
              this.filterForm.value.financialyear
            )
            .subscribe(
              (resultData1: any) => {
                this.noRecordsFound = false;
                this.resultData = resultData1;
                console.log('this.resultData', this.resultData);
                if (this.resultData.statusCode == 204) {
                  this.noRecordsFound = true;
                  console.log('nodata');
                }
              },
              (error) => {
                this.list_loader = false;
                this.noRecordsFound = true;
                console.log('error');
                if (error.status == 404) {
                  this.noRecordsFound = true;
                  console.log('404');
                }
              }
            );
        }
      } else {
        console.log('4');
        this.payrollservice
          .getemployeeRevenueByClientandemployeeAndpayslipelementPeriod(
            this.employeeId,
            this.filterForm.value.client,
            this.filterForm.value.element,
            this.filterForm.value.period
          )
          .subscribe(
            (resultData1: any) => {
              this.noRecordsFound = false;
              this.resultData = resultData1;
              console.log('this.resultData', this.resultData);
              if (this.resultData.statusCode == 204) {
                this.noRecordsFound = true;
                console.log('nodata');
              }
            },
            (error) => {
              this.list_loader = false;
              this.noRecordsFound = true;
              console.log('error');
              if (error.status == 404) {
                this.noRecordsFound = true;
                console.log('404');
              }
            }
          );
      }
    }
  }

  // searchSubmit()
  // {
  //   console.log("Filter",this.filterForm.value);
  //   if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode ===null)
  //   &&(this.filterForm.value.financialyear===null || this.filterForm.value.financialyear ==='null')
  //   &&(this.filterForm.value.period===null|| this.filterForm.value.period ==='null')
  //   &&(this.filterForm.value.element===null|| this.filterForm.value.element ==='null'))
  //   {
  //      console.log("required Fields");
  //      this.resultData =[];
  //      this.noRecordsFound = true;
  //      alert("Please Enter Employee Number");
  //      return;
  //   }
  //   console.log("emp code",this.filterForm.value.employeecode);
  //   console.log("year",this.filterForm.value.financialyear);
  //   console.log("period",this.filterForm.value.period);
  //   console.log("element",this.filterForm.value.element);

  //   if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&(this.filterForm.value.financialyear=== null || this.filterForm.value.financialyear === 'null')
  //   &&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')
  //   &&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
  //   {
  //     console.log("emp num");
  //     this.payrollservice.searchRevenueAdjustmentByEmployeeandclient(this.employeeId ,this.filterForm.value.client ).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //     if(this.resultData.statusCode == 204)
  //     {
  //       this.noRecordsFound = true;
  //       console.log("nodata");
  //     }
  //   },
  //   (error) => {

  //     this.list_loader = false;
  //     this.noRecordsFound = true;
  //     console.log("error")
  //     if(error.status == 404){
  //       this.noRecordsFound = true;
  //       console.log("404")
  //     }
  //   }
  //   )
  //   }
  //   else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')
  //   &&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
  //   {
  //     console.log("year only");
  //     // this.noRecordsFound = false;
  //   this.payrollservice.listRevenueAdjustmentByFinancialYear(this.filterForm.value.financialyear).subscribe((resultData1: any) => {
  //     this.noRecordsFound = false;
  //     this.resultData = resultData1;
  //     console.log("this.resultData",this.resultData);
  //     if(this.resultData.statusCode == 204)
  //     {
  //       this.noRecordsFound = true;
  //       console.log("nodata");
  //     }
  //   },
  //   (error) => {
  //     this.list_loader = false;
  //     this.noRecordsFound = true;
  //     console.log("error")
  //     if(error.status == 404){
  //       this.noRecordsFound = true;
  //       console.log("404")
  //     }
  //   }
  //   )
  //   }
  //   else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&(this.filterForm.value.financialyear=== null || this.filterForm.value.financialyear === 'null')
  //   &&(this.filterForm.value.period=== null|| this.filterForm.value.period === 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("element");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.searchRevenueAdjustmentByElementCategoryId(this.filterForm.value.element).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //     if(this.resultData.statusCode == 204)
  //     {
  //       this.noRecordsFound = true;
  //       console.log("nodata");
  //     }
  //   },
  //   (error) => {
  //     this.list_loader = false;
  //     this.noRecordsFound = true;
  //     console.log("error")
  //     if(error.status == 404){
  //       this.noRecordsFound = true;
  //       console.log("404")
  //     }
  //   }
  //   )
  //   }
  //   else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&(this.filterForm.value.element=== null|| this.filterForm.value.element === 'null'))
  //   {
  //     console.log("year and period");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByFinancialYearAndFinancialPeriod(this.filterForm.value.financialyear, this.filterForm.value.period).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }
  //   else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("all");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndElementCategoryAndFinancialYearAndFinancialPeriod(this.filterForm.value.employeecode,this.filterForm.value.element, this.filterForm.value.financialyear, this.filterForm.value.period).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }  else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("period");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByFinancialPeriod(this.filterForm.value.period).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("emp code and fin year");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndFinancialYear(this.filterForm.value.employeecode, this.filterForm.value.financialyear).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("emp code and fin year and period");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndFinancialYearAndFinancialPeriod(this.filterForm.value.employeecode, this.filterForm.value.financialyear,this.filterForm.value.period).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("period and element");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByFinancialPeriodAndElement(this.filterForm.value.period,this.filterForm.value.element).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("fin year and period and element");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByFinancialYearAndFinancialPeriodAndElement(this.filterForm.value.financialyear,this.filterForm.value.period, this.filterForm.value.element).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("fin year and element");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByFinancialYearAndElement(this.filterForm.value.financialyear,this.filterForm.value.element).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }else if((this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log(" element based");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByElement(this.filterForm.value.element).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("emp num and element");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByEmployeeCodeAndElement(this.filterForm.value.employeecode,this.filterForm.value.element, ).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&!(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("period andemp num and element");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByPeriodAndEmployeeCodeAndElement(this.filterForm.value.period,this.filterForm.value.employeecode,this.filterForm.value.element, ).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   } else if(!(this.filterForm.value.employeecode=== '' || this.filterForm.value.employeecode === null)
  //   &&!(this.filterForm.value.financialyear== null || this.filterForm.value.financialyear == 'null')
  //   &&(this.filterForm.value.period== null|| this.filterForm.value.period == 'null')
  //   &&!(this.filterForm.value.element== null|| this.filterForm.value.element == 'null'))
  //   {
  //     console.log("year andemp num and element");
  //     // this.noRecordsFound = false;
  //     this.payrollservice.listRevenueAdjustmentByYearAndEmployeeCodeAndElement(this.filterForm.value.financialyear,this.filterForm.value.employeecode,this.filterForm.value.element, ).subscribe((resultData1: any) => {
  //       this.noRecordsFound = false;
  //       this.resultData = resultData1;
  //       console.log("this.resultData",this.resultData);
  //       if(this.resultData.statusCode == 204)
  //       {
  //         this.noRecordsFound = true;
  //         console.log("nodata");
  //       }
  //     },
  //     (error) => {
  //       this.list_loader = false;
  //       this.noRecordsFound = true;
  //       console.log("error")
  //       if(error.status == 404){
  //         this.noRecordsFound = true;
  //         console.log("404")
  //       }
  //     }
  //     )
  //   }
  // }
}
