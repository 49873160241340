import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SuperadminService } from '../../../providers/superadmin.service';
import {
 
  httpOptions,
  responseMsgTimeOut,
  redirectMsgTimeOut,
} from '../../../providers/properties';
import { SettingsService } from '../../../providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import { AdminService } from '../../../providers/admin.service';
import { PasswordValidators } from '../../../password-validators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
//import * as $ from 'jquery';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.scss'],
})
export class AddOrganizationComponent implements OnInit {
  submitted: boolean = false;
  submitted1: boolean = false;
  heading: string = 'Organization';  // Default heading
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  postForm: FormGroup;
  resultData: any;
  organizationId: any;
  selectedOption: string = 'organization'; // Default value

  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;

  activeCountryData: any;
  activeTimeZoneData: any;

  stateList: any;
  districtList: any;
  cityList: any;

  industryTypeLov: any;
  userType: any;

  editData: any;
  organizationNameExistStatus: any;
  contactNumberExistStatus: any;
  contactEmailExistStatus: any;
  password;
  show = false;
  dateFormatId: any;

  filename: any;
  fileByte: any;
  fileSize: any;
  fileType: any;
  profileImage: any;
  fileblob: any;
  fileId: any;
  allSelected: any;
  autoCompleteCountrySearch: any = 0; //Auto Complete Search
  countryData: any;

  autoCompleteStateSearch: any = 0; //Auto Complete Search
  stateData: any;

  autoCompleteDistrictSearch: any = 0; //Auto Complete Search
  districtData: any;

  autoCompleteCitySearch: any = 0; //Auto Complete Search
  cityData: any;
  @ViewChild('select') select: MatSelect;
  roleresultData: any;
  arrayData: any = [];
  activeBusinessUnitList: any[] = [];
  storeEntityId: any;
  fileName: any;
  myFile: any;
  extension: any;
  mydata: any;
  storefileName: any;
  storeFilebyte: any;
  storeFlieSize: any;
  storeFileType: any;
  storeOrgStatus: any;
  organizationAttachment: { fileName: any; fileType: any; fileSize: any; fileByte: any; };
  fileChoosen: boolean[]=[];
  storeFileByte: string;
isbtndisable: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private SuperadminService: SuperadminService,
    private ActivatedRoute: ActivatedRoute,
    private SettingsService: SettingsService,
    private Employeeservice: EmployeeService,
    private AdminService: AdminService,
    private superadminservice: SuperadminService
  ) { }

  ngOnInit(): void {
    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);
    this.superadminservice.listOfBusinessUnit(this.storeEntityId).subscribe((response) => {
      console.log('listOfBusinessUnit', response);
      this.activeBusinessUnitList = response;
    });
    //console.log("LOV", this.activeCountryData);

    /* $('.select2Select').on('select2:select', function (e)
    {
      console.log("Hi");
    }); */

    // this.password = 'password';

    this.organizationId = this.ActivatedRoute.snapshot.params.organizationId;

    this.superadminservice.getUserRoleList().subscribe(
      (result: any) => {
        this.roleresultData = result;
      },
      (err) => {
        this.errorMsg = err.error.message;
        console.log(this.errorMsg);
      }
    );

    // if(this.organizationId) //Edit
    // {
    //   this.postForm = this.formBuilder.group({
    //     organizationName : ['',[Validators.required]],
    //     industryType : ['',[Validators.required,Validators.pattern(/^[0-9]*$/)]],
    //     contactPerson : ['',[Validators.required]],
    //     //contactCountryCode : ['',[Validators.required]],
    //     contactPhoneNumber :  ['',[Validators.required, Validators.pattern("^[0-9]+$")]],
    //     contactEmail : ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    //     postalCode : ['',[Validators.pattern("^[0-9.-]+$")]],
    //     address : [''],
    //     fiscalYearStart : [''],
    //     timezoneId :['',[Validators.pattern(/^[0-9]*$/)]],
    //     //reportingCurrency : [''],

    //     userTypeId : [''],
    //     emailId : [''],
    //     password : [''],

    //     profile_image : [''],

    //     countryName : ['' ,[Validators.required]],
    //     countryId : ['' ,[Validators.required]],

    //     stateName : [''],
    //     stateId : [''],

    //     districtName : [''],
    //     districtId : [''],

    //     cityName : [''],
    //     cityId : [''],
    //   });
    // }
    // else //Add
    // {
    //   this.postForm = this.formBuilder.group({
    //     organizationName : ['',[Validators.required]],
    //     industryType : ['',[Validators.required,Validators.pattern(/^[0-9]*$/)]],
    //     contactPerson : ['',[Validators.required]],
    //     //contactCountryCode : ['',[Validators.required]],
    //     contactPhoneNumber :  ['',[Validators.required, Validators.pattern("^[0-9]+$")]],
    //     contactEmail : ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    //     postalCode : ['',[Validators.pattern("^[0-9.-]+$")]],
    //     address : [''],
    //     fiscalYearStart : [''],
    //     timezoneId :['',[Validators.pattern(/^[0-9]*$/)]],
    //     //reportingCurrency : [''],

    //     roleId : ['',[Validators.required]],
    //     userTypeId : ['' ,[Validators.required]],
    //     emailId : ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],

    //     password : [
    //                 '' ,
    //                 [
    //                   Validators.required,
    //                   Validators.minLength(8),
    //                   PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
    //                     requiresDigit: true
    //                   }),
    //                   PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
    //                     requiresUppercase: true
    //                   }),
    //                   PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
    //                     requiresLowercase: true
    //                   }),
    //                   PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
    //                     requiresSpecialChars: true
    //                   })
    //                 ],

    //             ],

    //     profile_image : [''],

    //     countryName : ['' ,[Validators.required]],
    //     countryId : ['' ,[Validators.required]],

    //     stateName : [''],
    //     stateId : [''],

    //     districtName : [''],
    //     districtId : [''],

    //     cityName : [''],
    //     cityId : [''],

    //     // client form

    //   });
    // }

    if (this.organizationId) {
      this.loader=true;
      this.isbtndisable=true;
      this.SuperadminService.getOrganizationById(this.organizationId).subscribe(
        (resultData: any) => {
          this.editData = resultData;
          this.loader=false;
          this.isbtndisable=false;

          console.log("this.edit data::", this.editData);

          this.resultData = resultData;

          console.log("this.resultData:", this.resultData);

          this.storefileName=this.resultData?.organizationAttachment?.fileName;
          this.storeFilebyte=this.resultData?.organizationAttachment?.fileByte;
          this.storeFlieSize=this.resultData?.organizationAttachment?.fileSize;
          this.storeFileType=this.resultData?.organizationAttachment?.fileType;

          this.storeOrgStatus=this.resultData.organizationStatus.listTypeValueId;


          console.log( "this.storeOrgStatus:", this.storeOrgStatus);
          console.log(this.storeFileType,"",this.storeFilebyte,"",this.storeFlieSize,"",this.storefileName);
          


          this.dateFormatId = resultData.dateFormat
            ? resultData.dateFormat.dateFormatId
            : '';
          this.getActiveState(resultData.country.countryId);

          if (resultData.state) {
            this.getActiveDistrict(resultData.state.stateId);
          }

          if (resultData.district) {
            this.getActiveCity(resultData.district.districtId);
          }

          if (this.resultData.organizationAttachment) {
            this.fileId = this.resultData.organizationAttachment.fileId;
          }

          if (
            this.resultData.organizationAttachment &&
            this.resultData.organizationAttachment.fileViewUrl != undefined
          ) {
            this.Employeeservice.getemployeeImage(
              this.resultData.organizationAttachment.fileViewUrl
            ).subscribe(
              (blob) => {
                // console.log("blod....::",blob);
                
                const a = document.createElement('a');
                const objectUrl: any = blob;
                this.fileblob = blob;
                console.log("this.fileblob::",this.fileblob);
                   this.postForm.patchValue({
            profile_image:this.resultData?.organizationAttachment?.fileName
          })
                
              },
              (err) => {
                this.fileblob = 'assets/uploads/no-image.png';
              }
            );
          } else {
            this.fileblob = 'assets/uploads/no-image.png';
          }

          this.resultData = resultData;
          console.log("edit response::",this.resultData);
       
          
          this.postForm.patchValue({


            organizationName: this.resultData.organizationName,
            industryType: this.resultData.industryType.listTypeValueId,
            postalCode: this.resultData.postalCode,
            address: this.resultData.address,
            // fiscalYearStart: this.resultData.fiscalYearStart,
            defaulTimezone: this.resultData.defaulTimezone,
            //reportingCurrency: this.resultData.reportingCurrency,
            countryId: this.resultData.country.countryId,
            stateId: this.resultData.state.stateId,
            districtId: this.resultData.district.districtId,
            cityId: this.resultData.city.cityId,
              countryName: this.resultData.country.countryName,
              stateName: this.resultData.state.stateName,
              cityName: this.resultData.city.cityName,
              districtName: this.resultData.district.districtName,
                            businessUnitorg: this.resultData.businessUnit.businessUnitId,


          });
          // this.postForm.patchValue({
          //   profile_image:this.resultData?.organizationAttachment?.fileName,
          // })
        
         

          // console.log("attachement value::",this.postForm.get('profile_image').value);
          

          if(this.postForm.value.countryName&&this.postForm.value.countryId){
            this.postForm.get('countryName')?.markAsUntouched();
            this.postForm.get('countryName')?.setErrors(null);
          
            this.postForm.get('countryId')?.markAsUntouched();
            this.postForm.get('countryId')?.setErrors(null);
          }

          

          // if (this.resultData.organizationAttachment) {
          //   this.postForm.patchValue({
          //     profile_image: this.resultData.organizationAttachment.fileId,
          //   });
          // }
          // if (this.resultData.industryType) {
          //   this.postForm.patchValue({
          //     industryType: this.resultData.industryType.listTypeValueId,
          //   });
          // }
          // if (this.resultData.businessUnit) {
          //   this.postForm.patchValue({
          //     businessUnitorg: this.resultData.businessUnit.businessUnitId,
          //   });
          // }

          // if (this.resultData.defaulTimezone) {
          //   this.postForm.patchValue({
          //     timezoneId: this.resultData.defaulTimezone.timezoneId,
          //   });
          // }

          // if (this.resultData.country) {
          //   this.postForm.patchValue({
          //     countryName: this.resultData.country.countryName,
          //     countryId: this.resultData.country.countryId,
          //   });
          // }

          // if (this.resultData.state) {
          //   this.postForm.patchValue({
          //     stateName: this.resultData.state.stateName,
          //     stateId: this.resultData.state.stateId,
          //   });
          // }

          // if (this.resultData.district) {
          //   this.postForm.patchValue({
          //     districtName: this.resultData.district.districtName,
          //     districtId: this.resultData.district.districtId,
          //   });
          // }

          // if (this.resultData.city) {
          //   this.postForm.patchValue({
          //     cityName: this.resultData.city.cityName,
          //     cityId: this.resultData.city.cityId,
          //   });
          // }
        }
      );
    }

    this.getIndustryTypeLov(7);
    this.getActiveCountry();
    this.getUserType();
    this.getActiveTimeZone();
    this.addOrganizationControls();

    // Listen to selected option change
    this.onOptionChange({ target: { value: this.selectedOption } });



  }
  validateInputSpace(event) {
    const textarea = event.target;
    console.log("keywords::",textarea);
    
    let value = textarea?.value;
    console.log("value wt is::",value)


    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0)
    ) {
      console.log("empty space is ther!!");
      
      this.countryData=
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; 
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; 

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }

  }
  addOrganizationControls() {
    if (this.organizationId) {
      //Edit
      this.postForm = this.formBuilder.group({
        businessUnitorg: ['', [Validators.required]],
        organizationName: ['', [Validators.required]],
        industryType: [
          '',
          [Validators.required, Validators.pattern(/^[0-9]*$/)],
        ],
        // contactPerson: ['', [Validators.required]],
        //contactCountryCode : ['',[Validators.required]],
        // contactPhoneNumber: [
        //   '',
        //   [Validators.required, Validators.pattern('^[0-9]+$')],
        // ],
        // contactEmail: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        //   ],
        // ],
        // postalCode: ['', [Validators.pattern('^[0-9.-]+$')]],
        // address: [''],
        // fiscalYearStart: [''],
        // timezoneId: ['', [Validators.pattern(/^[0-9]*$/)]],
        //reportingCurrency : [''],
        timezoneId: [null],
        userTypeId: [''],
        emailId: [''],
        password: [''],

        profile_image: ['',[Validators.required]],

        countryName: ['',[Validators.required]],
        countryId:['',[Validators.required]],

        stateName: ['',[Validators.required]],
        stateId: ['',[Validators.required]],

        districtName: ['',[Validators.required]],
        districtId: ['',[Validators.required]],

        cityName: ['',[Validators.required]],
        cityId: ['',[Validators.required]],
      });
    } //Add
    else {
      this.postForm = this.formBuilder.group({
        businessUnitorg: ['', [Validators.required]],
        organizationName: ['', [Validators.required]],
        industryType: [
          '',
          [Validators.required, Validators.pattern(/^[0-9]*$/)],
        ],
        // contactPerson: ['', [Validators.required]],
        //contactCountryCode : ['',[Validators.required]],
        // contactPhoneNumber: [
        //   '',
        //   [Validators.required, Validators.pattern('^[0-9]+$')],
        // ],
        // contactEmail: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        //   ],
        // ],
        // postalCode: ['', [Validators.pattern('^[0-9.-]+$')]],
        // address: [''],
        // fiscalYearStart: [''],
        timezoneId: [null],
        //reportingCurrency : [''],

        // roleId: ['', [Validators.required]],
        // userTypeId: ['', [Validators.required]],
        // emailId: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        //   ],
        // ],

        // password: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.minLength(8),
        //     PasswordValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
        //       requiresDigit: true,
        //     }),
        //     PasswordValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
        //       requiresUppercase: true,
        //     }),
        //     PasswordValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
        //       requiresLowercase: true,
        //     }),
        //     PasswordValidators.patternValidator(
        //       new RegExp('(?=.*[$@^!%*?&])'),
        //       {
        //         requiresSpecialChars: true,
        //       }
        //     ),
        //   ],
        // ],

        profile_image: ['',[Validators.required]],

        countryName: ['', [Validators.required]],
        countryId:['',[Validators.required]],

        stateName: ['',[Validators.required]],
        stateId:['',[Validators.required]],

        districtName:['',[Validators.required]],
        districtId: ['',[Validators.required]],

        cityName:['',[Validators.required]],
        cityId: ['',[Validators.required]],


      });
    }
  }
  addClientControls() {
    this.postForm = this.formBuilder.group({
      businessUnits: ['', [Validators.required]],
      clientCode: ['', [Validators.required]],
      clientName: ['', [Validators.required]],
      supplierName: ['', [Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      description: [''],
      postalCode: ['', [Validators.pattern('^[0-9.-]+$')]],

      countryName: ['', [Validators.required]],
      countryId: ['', [Validators.required]],

      stateName: [''],
      stateId: [''],

      districtName: [''],
      districtId: [''],

      cityName: [''],
      cityId: [''],
    });
  }

  getIndustryTypeLov(listTypeValueId) {
    this.SettingsService.getListTypeValues(listTypeValueId).subscribe(
      (resultData: any) => {
        this.industryTypeLov = resultData;
      }
    );
  }
  setSupplier() {
    this.postForm.controls['supplierName'].setValue(null);

  }
  setTimeZone() {
    this.postForm.controls['timezoneId'].setValue(null);

  }
  setIndustry() {
    this.postForm.controls['industryType'].setValue(null);

  }
  business() {
    this.postForm.controls['businessUnits'].setValue(null);

  }
  businessorgset() {
    this.postForm.controls['businessUnitorg'].setValue(null);

  }
  onOptionChange(event: any) {
    this.selectedOption = event.target.value;
    this.heading = event.target.value === 'client' ? 'Client' : 'Organization';
    if (this.selectedOption === 'organization') {
      console.log('org cameee!!');

      this.addOrganizationControls();
    } else if (this.selectedOption === 'client') {
      console.log('client came!!');

      this.addClientControls();
    }
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  get validate() {
    return this.postForm.controls;
  }

  get passwordValid() {
    return this.postForm.controls['password'].errors === null;
  }

  get requiredValid() {
    return !this.postForm.controls['password'].hasError('required');
  }

  get minLengthValid() {
    return !this.postForm.controls['password'].hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.postForm.controls['password'].hasError('requiresDigit');
  }

  get requiresUppercaseValid() {
    return !this.postForm.controls['password'].hasError('requiresUppercase');
  }

  get requiresLowercaseValid() {
    return !this.postForm.controls['password'].hasError('requiresLowercase');
  }

  get requiresSpecialCharsValid() {
    return !this.postForm.controls['password'].hasError('requiresSpecialChars');
  }

  // validateInputSpace(event) {
  //   const textarea = event.target;
  //   let value = textarea.value;

  //   if (
  //     event.key === ' ' &&
  //     (textarea.selectionStart === 0 ||
  //       value[textarea.selectionStart - 1] === ' ')
  //   ) {
  //     event.preventDefault();
  //     return;
  //   }

  //   value = value.replace(/\s{2,}/g, ' ');
  //   textarea.value = value;

  //   const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
  //   const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

  //   if (datePattern1.test(value) || datePattern2.test(value)) {
  //     textarea.value = value
  //       .replace(datePattern1, '')
  //       .replace(datePattern2, '');
  //   }
  // }
  onSubmit() {
    console.log("form values::",this.postForm);
    
  console.log('this.resultData',this.resultData);
  

    this.submitted = true;
    console.log(this.postForm.controls['profile_image'].value);

    if(this.postForm.controls['profile_image'].value){
      this.postForm.get('profile_image')?.markAsUntouched();
      this.postForm.get('profile_image')?.setErrors(null);
    
    
    }
    console.log(this.postForm.controls['countryId'].value ,"",console.log(this.postForm.controls['countryName'].value)
  );


    if (
      this.postForm.invalid ||
      this.organizationNameExistStatus == true ||
      this.contactNumberExistStatus == true ||
      this.contactEmailExistStatus == true
    ) {

      console.log('formInvalid');

      return;
    }

    

    console.log('form ::', this.postForm.value);

    console.log('org ::', this.postForm.value);

    console.log('organization form here!!!');

    if (this.organizationId) {
     
      // var postValues = {
      //   organizationName: this.postForm.value.organizationName,
      //   industryType: {
      //     listTypeValueId: this.postForm.value.industryType,
      //   },
      //   contactPerson: this.postForm.value.contactPerson,
    
      //   contactPhoneNumber: this.postForm.value.contactPhoneNumber,
      //   contactEmail: this.postForm.value.contactEmail,
      //   address: this.postForm.value.address,
      //   postalCode: this.postForm.value.postalCode,
      //   fiscalYearStart: this.postForm.value.fiscalYearStart,

  
      // };
      const defaulTimezone = this.postForm.value.timezoneId 
      ? { timezoneId: this.postForm.value.timezoneId } 
      : null;

      var postValues:any = {
        
        organizationName: this.postForm.value.organizationName,
        industryType: {
          listTypeValueId: this.postForm.value.industryType
        },

        businessUnit: {
          businessUnitId: this.postForm.value.businessUnitorg
        },
      //   organizationAttachment: {
      //     fileName: this.mydata?.fileName,
      //       fileType: this.mydata?.fileType,
      //       fileBytes: this.mydata?.fileByte,
      //       fileSize: this.mydata?.fileSize,

      // },
      // defaulTimezone: defaulTimezone,
      status:{listTypeValueId:1

      }

    }

      postValues['organizationId'] = this.organizationId;
      postValues['organizationStatus'] ={
        listTypeValueId: this.storeOrgStatus};

      /* postValues['dateFormat'] = {
        dateFormatId : this.dateFormatId,
      }; */

      /*  if(this.resultData.defaulTimezone)
        {
          this.postForm.patchValue({
            timezoneId: this.resultData.defaulTimezone.timezoneId
          });
        }
     */

      // if (this.postForm.value.timezoneId) {
      //   postValues['defaulTimezone'] = {
      //     timezoneId: this.postForm.value.timezoneId,
      //   };
      // }

      if (this.postForm?.value?.countryId) {
        // Ensure the country property is initialized
        postValues.country = {
          countryId: this.postForm.value.countryId
        };
      }

      if (this.postForm.value.stateId) {
        postValues['state'] = {
          stateId: this.postForm.value.stateId,
        };
      }

      if (this.postForm.value.districtId) {
        postValues['district'] = {
          districtId: this.postForm.value.districtId,
        };
      }

      if (this.postForm.value.cityId) {
        postValues['city'] = {
          cityId: this.postForm.value.cityId,
        };
      }
      if (this.storefileName && this.storeFileType && this.storeFlieSize && this.storeFileByte) {
        // Add new file details to the payload, remove old file details
        postValues['organizationAttachment'] = {
          fileName: this.storefileName,
          fileType: this.storeFileType,
          fileSize: this.storeFlieSize,
          fileBytes: this.storeFileByte,
          fileId:this.resultData?.organizationAttachment?.fileId
        };
      } else {
        // Use existing file data if no new file is selected
        postValues['organizationAttachment'] = this.resultData.organizationAttachment || null;
      }
      
        
      
      

      //console.log(postValues); return;
  

      // let body = {
      //   organizationName: this.postForm.value.organizationName,
      //   industryType: {
      //     listTypeValueId: this.postForm.value.industryType
      //   },
      //   // contactPerson: this.postForm.value.contactPerson,
      //   // contactPhoneNumber: this.postForm.value.contactPhoneNumber,
      //   // contactEmail: this.postForm.value.contactEmail,
      //   address: this.postForm.value.address,
      //   postalCode: this.postForm.value.postalCode,
      //   fiscalYearStart: this.postForm.value.fiscalYearStart,
      //   defaulTimezone: defaulTimezone,
      //   country: {
      //     countryId: this.postForm.value.countryId
      //   },
      //   state: {
      //     stateId: this.postForm.value.stateId
      //   },
      //   district: {
      //     districtId: this.postForm.value.districtId
      //   },
      //   city: {
      //     cityId: this.postForm.value.cityId
      //   },
      //   businessUnit: {
      //     businessUnitId: this.postForm.value.businessUnitorg
      //   },
        // organizationAttachment: {
        //   fileName: this.mydata?.fileName,
        //   fileType: this.mydata?.fileType,
        //   fileBytes: this.mydata?.fileByte,
        //   fileSize: this.mydata?.fileSize
        // }
    
      // }
      
      console.log("payload::",postValues);

      this.isbtndisable=true
      


// return
      this.SuperadminService.editOrganization(
        this.organizationId,
        postValues
      ).subscribe(
        (result: any) => {
          this.isbtndisable=true
          if(this.postForm.value.countryName&&this.postForm.value.countryId){
            this.postForm.get('countryName')?.markAsUntouched();
            this.postForm.get('countryName')?.setErrors(null);
          
            this.postForm.get('countryId')?.markAsUntouched();
            this.postForm.get('countryId')?.setErrors(null);
          }
          if (result.statusCode == 200) {
            this.success = true;
            this.isbtndisable=true

            this.sucess_msg = result.description;
            setTimeout(() => {
              this.router.navigate(['/home/organization']);
            }, redirectMsgTimeOut);
          } else {
            this.error = true;
            this.error_msg = result.description;

            setTimeout(() => {
              this.router.navigate(['/home/organization']);
            }, redirectMsgTimeOut);
          }
        },
        (err) => {
          this.errorMsg = err.error.description;
        }
      );
    } //Add Organization
    else {
      // var postValues = {
      //   organizationName: this.postForm.value.organizationName,
      //   industryType: {
      //     listTypeValueId: this.postForm.value.industryType,
      //   },
      //   contactPerson: this.postForm.value.contactPerson,
      //   //contactCountryCode:this.postForm.value.contactCountryCode,
      //   contactPhoneNumber: this.postForm.value.contactPhoneNumber,
      //   contactEmail: this.postForm.value.contactEmail,
      //   address: this.postForm.value.address,
      //   postalCode: this.postForm.value.postalCode,
      //   fiscalYearStart: this.postForm.value.fiscalYearStart,
      //   //defaulTimezone:this.postForm.value.defaulTimezone,
      //   //reportingCurrency:this.postForm.value.reportingCurrency,
      // };

      // if (this.postForm.value.timezoneId) {
      //   postValues['defaulTimezone'] = {
      //     timezoneId: this.postForm.value.timezoneId,
      //   };
      // }

      // if (this.postForm.value.countryId) {
      //   postValues['country'] = {
      //     countryId: this.postForm.value.countryId,
      //   };
      // }

      // if (this.postForm.value.stateId) {
      //   postValues['state'] = {
      //     stateId: this.postForm.value.stateId,
      //   };
      // }

      // if (this.postForm.value.districtId) {
      //   postValues['district'] = {
      //     districtId: this.postForm.value.districtId,
      //   };
      // }

      // if (this.postForm.value.cityId) {
      //   postValues['city'] = {
      //     cityId: this.postForm.value.cityId,
      //   };
      // }
      this.isbtndisable=true

      if (this.postForm.value.emailId) {
        let x = this.postForm.value;
        x.roleId.map((e) => {
          let data;
          if (e) {
            data = {
              roleId: e,
            };
          }
          this.arrayData.push(data);
        });

        postValues['user'] = {
          emailId: this.postForm.value.emailId,
          password: this.postForm.value.password,
          userType: {
            userTypeId: this.postForm.value.userTypeId,
          },
          role: this.arrayData,
        };
      }

      // if (this.filename) {
      //   let organizationAttachment = {
      //     fileId: this.fileId,
      //     fileName: this.filename,
      //     fileType: this.fileType,
      //     fileSize: this.fileSize,
      //     fileBytes: this.fileByte,
      //   };
      //   postValues['organizationAttachment'] = organizationAttachment;
      // }

// if (this.filename && this.fileId && this.fileType && this.fileSize && this.fileByte) {
//   postValues.organizationAttachment = {
//     fileId: this.fileId,
//     fileName: this.filename,
//     fileType: this.fileType,
//     fileSize: this.fileSize,
//     fileBytes: this.fileByte
//   };
//           postValues['organizationAttachment'] = this.postForm.value.profile_image;

// }

      // console.log(postValues);
      // const defaulTimezone = this.postForm.value.timezoneId 
      // ? { timezoneId: this.postForm.value.timezoneId } 
      // : null;
    

      let body = {
        organizationName: this.postForm.value.organizationName,
        industryType: {
          listTypeValueId: this.postForm.value.industryType
        },
        // contactPerson: this.postForm.value.contactPerson,
        // contactPhoneNumber: this.postForm.value.contactPhoneNumber,
        // contactEmail: this.postForm.value.contactEmail,
        address: this.postForm.value.address,
        postalCode: this.postForm.value.postalCode,
        fiscalYearStart: this.postForm.value.fiscalYearStart,
        // defaulTimezone: defaulTimezone,
        country: {
          countryId: this.postForm.value.countryId
        },
        state: {
          stateId: this.postForm.value.stateId
        },
        district: {
          districtId: this.postForm.value.districtId
        },
        city: {
          cityId: this.postForm.value.cityId
        },
        businessUnit: {
          businessUnitId: this.postForm.value.businessUnitorg
        },
        organizationAttachment: {
          fileName: this.mydata?.fileName,
          fileType: this.mydata?.fileType,
          fileBytes: this.mydata?.fileByte,
          fileSize: this.mydata?.fileSize
        }
      }
      console.log("payload::",body);
      
      
      // return
      this.SuperadminService.addOrganization(body).subscribe(
        (result: any) => {
          console.log('result', result);
          this.isbtndisable=true

          if (result.statusCode == 200) {
            this.success = true;
            this.isbtndisable=true;
            this.sucess_msg = result.description;

            setTimeout(() => {
              this.router.navigate(['/home/organization']);
            }, redirectMsgTimeOut);
          } else {
            this.error = true;
            this.error_msg = result.message;

            setTimeout(() => {
              this.router.navigate(['/home/organization']);
            }, redirectMsgTimeOut);
          }
        },
        (err) => {
          this.errorMsg = err.error.message;
        }
      );
    }
  }
  submitClient() {

    this.submitted1 = true;
    console.log('client form::', this.postForm.value);
    if (this.postForm.invalid) {
      return;
    }
  }
  getUserType() {
    this.SuperadminService.getUserType().subscribe((result: any) => {
      this.userType = result;
    });
  }

  getActiveTimeZone() {
    this.SuperadminService.getActiveTimezone().subscribe((result: any) => {
      this.activeTimeZoneData = result;
    });
  }

  getActiveCountry() {
    this.Employeeservice.getActiveCountry().subscribe((result: any) => {
      this.activeCountryData = result;
    });
  }

  getActiveState(countryId) {
    this.SettingsService.getCountryAgainstStateList(countryId).subscribe(
      (result: any) => {
        this.stateList = result;
      }
    );
  }

  getActiveDistrict(stateId) {
    this.SettingsService.getStateAgainstDistictList(stateId).subscribe(
      (result: any) => {
        this.districtList = result;
      }
    );
  }

  getActiveCity(districtId) {
    this.SettingsService.getDistrictAgainstCityList(districtId).subscribe(
      (result: any) => {
        this.cityList = result;
      }
    );
  }

  countryChange(event) {
    let countryId = event.target.value;

    if (countryId) {
      this.getActiveState(countryId);

      this.SettingsService.getCountryAgainstStateList(countryId).subscribe(
        (resultData: any) => {
          if (resultData.statusCode) {
            this.stateList = [];
          } else {
            this.postForm.get('stateId').setValue('');
            this.postForm.get('districtId').setValue('');
            this.postForm.get('cityId').setValue('');
            this.stateList = resultData;
          }
          this.districtList = [];
          this.cityList = [];
        }
      );
    } else {
      this.stateList = [];
      this.districtList = [];
      this.cityList = [];

      this.postForm.get('stateId').setValue('');
      this.postForm.get('districtId').setValue('');
      this.postForm.get('cityId').setValue('');

      //console.log(this.address1);
    }
  }

  stateChange(event) {
    let stateId = event.target.value;
    if (stateId) {
      this.getActiveDistrict(stateId);

      this.SettingsService.getStateAgainstDistictList(stateId).subscribe(
        (resultData: any) => {
          if (resultData.statusCode) {
            this.districtList = [];
          } else {
            this.districtList = resultData;
            this.postForm.get('districtId').setValue('');
            this.postForm.get('cityId').setValue('');
          }

          this.cityList = [];
        }
      );
    } else {
      this.districtList = [];
      this.cityList = [];

      this.postForm.get('districtId').setValue('');
      this.postForm.get('cityId').setValue('');
    }
  }

  districtChange(event) {
    let districtId = event.target.value;
    if (districtId) {
      this.getActiveCity(districtId);
      this.SettingsService.getDistrictAgainstCityList(districtId).subscribe(
        (resultData: any) => {
          if (resultData.statusCode) {
            this.cityList = [];
          } else {
            this.cityList = resultData;
            this.postForm.get('cityId').setValue('');
          }
        }
      );
    } else {
      this.cityList = [];
      this.postForm.get('cityId').setValue('');
    }
  }

  checkOrganizationName(val) {
    let editData = 0;

    if (this.postForm.value.organizationName.length != 0) {
      if (this.editData) {
        var postValues = {
          organizationName: this.postForm.value.organizationName,
          //contactPhoneNumber:this.postForm.value.contactPhoneNumber,
          //contactEmail:this.postForm.value.contactEmail,
        };

        this.SuperadminService.createCheckExistOrganizationName(
          postValues
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.organizationNameExistStatus = false;
          } else if (result.statusCode > 400) {
            this.organizationNameExistStatus = true;
          }
        });
      } else {
        var postValues = {
          organizationName: this.postForm.value.organizationName,
          //contactPhoneNumber:this.postForm.value.contactPhoneNumber,
          //contactEmail:this.postForm.value.contactEmail,
        };

        this.SuperadminService.createCheckExistOrganizationName(
          postValues
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.organizationNameExistStatus = false;
          } else if (result.statusCode > 400) {
            this.organizationNameExistStatus = true;
          }
        });
      }
    } else {
      this.organizationNameExistStatus = false;
    }
  }

  checkContactNumber(val) {
    let editData = 0;

    if (this.postForm.value.contactPhoneNumber.length != 0) {
      if (this.editData) {
        var postValues = {
          //organizationName:this.postForm.value.organizationName,
          contactPhoneNumber: this.postForm.value.contactPhoneNumber,
          //contactEmail:this.postForm.value.contactEmail,
        };

        this.SuperadminService.createCheckExistContactNumber(
          postValues
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.contactNumberExistStatus = false;
          } else if (result.statusCode > 400) {
            this.contactNumberExistStatus = true;
          }
        });
      } else {
        var postValues = {
          //organizationName:this.postForm.value.organizationName,
          contactPhoneNumber: this.postForm.value.contactPhoneNumber,
          //contactEmail:this.postForm.value.contactEmail,
        };

        this.SuperadminService.createCheckExistContactNumber(
          postValues
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.contactNumberExistStatus = false;
          } else if (result.statusCode > 400) {
            this.contactNumberExistStatus = true;
          }
        });
      }
    } else {
      this.contactNumberExistStatus = false;
    }
  }

  checkContactEmail(val) {
    let editData = 0;

    if (this.postForm.value.contactEmail.length != 0) {
      if (this.editData) {
        var postValues = {
          //organizationName:this.postForm.value.organizationName,
          //contactPhoneNumber:this.postForm.value.contactPhoneNumber,
          contactEmail: this.postForm.value.contactEmail,
        };

        this.SuperadminService.createCheckExistContactEmail(
          postValues
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.contactEmailExistStatus = false;
          } else if (result.statusCode > 400) {
            this.contactEmailExistStatus = true;
          }
        });
      } else {
        var postValues = {
          //organizationName:this.postForm.value.organizationName,
          //contactPhoneNumber:this.postForm.value.contactPhoneNumber,
          contactEmail: this.postForm.value.contactEmail,
        };

        this.SuperadminService.createCheckExistContactEmail(
          postValues
        ).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.contactEmailExistStatus = false;
          } else if (result.statusCode > 400) {
            this.contactEmailExistStatus = true;
          }
        });
      }
    } else {
      this.contactEmailExistStatus = false;
    }
  }

  showPassword() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

//   getFileDetails(event) {
//     const files = event.target.files;
//     console.log("files:",files);
    

//     // if (event.target.files.length > 0) {
//     //   const file = event.target.files[0];
//     //   this.filename = file.name;
//     //   this.fileSize = file.size;
//     //   this.fileType = file.type;
//     if (files) {
//       for (let i = 0; i < files.length; i++) {
  
//         if (files.length != 0) {
//           // this.isShow = true;
//           // this.newFileSelected=true
  
//         }
//         const image = {
//           fileName: '',
//           fileType: '',
//           fileSize: '',
//           fileByte: '',
//           fileUrlName: '',
//           size: '',
//           filePath: '',
//           url: '',
//         };
  
//         const file = files[i];
//         const fileType = file.type;
//         const fileName = file.name;
  
//         // Check if the file is an image
//         // if (fileType.startsWith('image/')) {
//         //   alert('Image files are not allowed.');
//         //   this.newFileSelected=true
//         //   return; // Skip processing this file
//         // }
  
//         this.fileName = fileName;
//         console.log('this.fileName', this.fileName);
//         console.log(image);
//         this.myFile?.push(file);
//         image.fileName = fileName;
//         console.log('sourceFileName' + image.fileName);
//         image.fileType = fileType;
//         console.log('sourceFileType' + image.fileType);
//         image.fileSize = file.size;
//         console.log('sourceFileSize' + image.fileSize);
//         image.size = image.size;
//         console.log('size' + image.size);
//         var totalBytes = file.size;
//         console.log('totalBytes' + totalBytes);
  
//         // if (totalBytes < 1000000) {
//         //   image.size = Math.floor(totalBytes / 1000) + 'KB';
//         // } else {
//         //   image.size = Math.floor(totalBytes / 1000000) + 'MB';
//         // }
//         var ext = image.fileName.substring(
//           image.fileName.lastIndexOf('.') + 1
//         );
//         console.log('image.sourceFileType' + image.fileType);
  
//         // if (
//         //   image.fileType.length == 0 &&
//         //   (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc')
//         // ) {
//         //   image.fileType =
//         //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
//         // }

//       let reader = new FileReader();
//       reader.onload = () => {
//         // this.fileblob = reader.result as string; // Store Base64 data
      
//         // let x: any = reader.result;
//         // let y: any = x.split(',');
//         // this.fileByte = y[1];
//         /* group.patchValue({
//           fileSource : file,
//           fileBytes : sourceByteArray
//         }) */
       
//           image.filePath = reader.result + '';
//           this.extension = image.fileName.substring(
//             image.fileName.lastIndexOf('.') + 1
//           );
//           var ext = image.fileName.substring(
//             image.fileName.lastIndexOf('.') + 1
//           );
  
//           let x: any = reader.result;
//           let y: any = x.split(',');
//           image.fileByte =y[1];
//           // this.mydata.push(image)
//           this.mydata = image;
//           console.log("mydata",this.mydata);
//       };

//       reader.readAsDataURL(file);
//     }
//   }
// }

getFileDetails(event) {
  const files = event.target.files;
  if (files && files.length > 0) {
    // Clear existing file details when a new file is selected
    this.fileId = null; // Clear the existing file ID
    this.fileblob = null; // Clear existing file blob if applicable

    const file = files[0]; // Assuming single file selection for simplicity
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result as string;
      const fileByte = base64Data.split(',')[1]; // Extract the base64 content

      // Store new file details in component properties
      this.storefileName = file.name;
      this.storeFileType = file.type;
      this.storeFlieSize = file.size;
      this.storeFileByte = fileByte; // Store the file byte data

      this.mydata = {
        fileName: this.storefileName,
        fileType: this.storeFileType,
        fileSize: this.storeFlieSize,
        fileByte: this.storeFileByte
      };

      console.log('New file details:', this.mydata);

      // Optionally update the form to show that a new file is selected
      this.postForm.patchValue({
        profile_image: this.storefileName
      });
    };

    reader.readAsDataURL(file); // Read file as Base64
  }
}


  /** Auto Complete Search **/
  countryAutoCompleteSearch(event) {
    let keywords = event.target.value;
    const textarea = event.target.value;
  console.log("keywords::", textarea);

  // Check if the textarea is empty
  if (textarea.trim() === '') {
    this.autoCompleteCountrySearch = 1; // Hide the list if the input is empty
    this.postForm.patchValue({
      countryId: '',
      stateName: '',
        stateId: '',

        districtName: '',
        districtId: '',

        cityName: '',
        cityId: '',
    });
    return; // Exit the function early
  }

    // if (keywords) {
    //   this.autoCompleteCountrySearch = 0;

    //   this.AdminService.countryAutoCompleteSearch(keywords).subscribe(
    //     (result: any) => {
    //       this.countryData = result;

    //       this.postForm.patchValue({
    //         countryId: '',
    //       });

    //       if (this.countryData.statusCode == 204) {
    //         this.autoCompleteCountrySearch = 1; //No data Found
    //         this.postForm.patchValue({
    //           countryId: '',
    //         });
    //       } else {
    //         this.autoCompleteCountrySearch = 0;
    //       }
    //     }
    //   );
    // } else {
    //   this.autoCompleteCountrySearch = 1;
    //   this.postForm.patchValue({
    //     countryId: '',

    //     stateName: '',
    //     stateId: '',

    //     districtName: '',
    //     districtId: '',

    //     cityName: '',
    //     cityId: '',
    //   });
    // }
    this.AdminService.countryAutoCompleteSearch(textarea).subscribe(
      (result: any) => {
        this.countryData = result;
        console.log("this.country data:", this.countryData);
  
        this.postForm.patchValue({
          countryId: '',
        });
  
        if (this.countryData.statusCode == 204) {
          this.autoCompleteCountrySearch = 1; // No data Found
          this.postForm.patchValue({
            countryId: '',
          });
          this.postForm.controls['countryId'].reset();
          this.postForm.controls['countryName'].reset();
          return;
        } else {
          this.autoCompleteCountrySearch = 0;
        }
      }
    );
  }

  stateAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let countryId = this.postForm.value.countryId;

    if (!countryId) {
      alert('Please! First select country.');
      this.postForm.patchValue({
        stateName: '',
        stateId: '',
      });
      return;
    }

    if (keywords) {
      this.autoCompleteStateSearch = 0;

      this.AdminService.stateAutoCompleteSearch(countryId, keywords).subscribe(
        (result: any) => {
          this.stateData = result;

          if (this.stateData.statusCode == 204) {
            this.autoCompleteStateSearch = 1; //No data Found
            this.postForm.patchValue({
              //employeeName : firstName+" "+lastName,
              stateId: '',
            });
          } else {
            this.autoCompleteStateSearch = 0;
          }
        }
      );
    } else {
      this.autoCompleteStateSearch = 1;
      this.postForm.patchValue({
        stateId: '',

        districtName: '',
        districtId: '',

        cityName: '',
        cityId: '',
      });
    }
  }

  districtAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let stateId = this.postForm.value.stateId;

    if (!stateId) {
      alert('Please! First select state.');
      this.postForm.patchValue({
        districtName: '',
        districtId: '',
      });
      return;
    }

    if (keywords) {
      this.autoCompleteDistrictSearch = 0;

      this.AdminService.districtAutoCompleteSearch(stateId, keywords).subscribe(
        (result: any) => {
          this.districtData = result;

          if (this.districtData.statusCode == 204) {
            this.autoCompleteDistrictSearch = 1; //No data Found
            this.postForm.patchValue({
              districtId: '',
            });
          } else {
            this.autoCompleteDistrictSearch = 0;
          }
        }
      );
    } else {
      this.autoCompleteDistrictSearch = 1;
      this.postForm.patchValue({
        districtId: '',

        cityName: '',
        cityId: '',
      });
    }
  }

  cityAutoCompleteSearch(event) {
    let keywords = event.target.value;
    let districtId = this.postForm.value.districtId;

    if (!districtId) {
      alert('Please! First select district.');
      this.postForm.patchValue({
        cityName: '',
        cityId: '',
      });
      return;
    }

    if (keywords) {
      this.autoCompleteCitySearch = 0;

      this.AdminService.cityAutoCompleteSearch(districtId, keywords).subscribe(
        (result: any) => {
          this.cityData = result;

          if (this.cityData.statusCode == 204) {
            this.autoCompleteCitySearch = 1; //No data Found
            this.postForm.patchValue({
              cityId: '',
            });
          } else {
            this.autoCompleteCitySearch = 0;
          }
        }
      );
    } else {
      this.autoCompleteCitySearch = 1;
      this.postForm.patchValue({
        cityId: '',
      });
    }
  }

  getFilterData(id, filterName, type) {
    if (type == 1) {
      //Country
      this.autoCompleteCountrySearch = 1;

      if (id) {
        this.postForm.patchValue({
          countryName: filterName,
          countryId: id,
        });

        this.postForm.patchValue({
          stateName: '',
          stateId: '',

          districtName: '',
          districtId: '',

          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 2) {
      //State
      this.autoCompleteStateSearch = 1;

      if (id) {
        this.postForm.patchValue({
          stateName: filterName,
          stateId: id,
        });

        this.postForm.patchValue({
          districtName: '',
          districtId: '',

          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 3) {
      //district
      this.autoCompleteDistrictSearch = 1;

      if (id) {
        this.postForm.patchValue({
          districtName: filterName,
          districtId: id,
        });

        this.postForm.patchValue({
          cityName: '',
          cityId: '',
        });
      }
    } else if (type == 4) {
      //city
      this.autoCompleteCitySearch = 1;

      if (id) {
        this.postForm.patchValue({
          cityName: filterName,
          cityId: id,
        });
      }
    }
  }
  /** Auto Complete Search end **/
}
