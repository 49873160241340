import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators,FormControl} from '@angular/forms';
import { Inject,ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../app/providers/settings.service';
import { EmployeeService } from 'src/app/providers/employee.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../app/providers/properties';
import { PayrollService } from '../providers/payroll.service';
import { ShowerrorComponent } from '../showerror/showerror.component';

@Component({
  selector: 'app-import-employeeworkingdays',
  templateUrl: './import-employeeworkingdays.component.html',
  styleUrls: ['./import-employeeworkingdays.component.scss']
})
export class ImportEmployeeworkingdaysComponent implements OnInit {
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  importForm:FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  fileInputLabel: string;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeefile :any;
  employeetData:any;
  usertData: any;
  orgId: any;
  clientList: any = [];
  errorrecords: any;
  showtable: boolean = false;

  p:number = 1;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  constructor(
    private settingsservice: SettingsService,
    private payrollservice: PayrollService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<ImportEmployeeworkingdaysComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })    

    this.importForm = this.formBuilder.group({
       importType :  [null,[Validators.required]],
      file : ['',[Validators.required]]
    });
  }
  get validate() 
  { 
    return this.importForm.controls; 
  }
  onSubmit()
  {
    console.log("submit");
    this.submitted = true;
    if (this.importForm.invalid) {return;}

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;

    const formData = new FormData();
    formData.append("file", this.importForm.get('file').value);

    formData.append('organizationId', this.orgId);

     if(this.importForm.value.importType == 0)
     {
      this.payrollservice.importEmployeeworkingdays(formData).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          //this.loader = true;
          console.log("success");
          this.success = true;
          this.sucess_msg = result.description;
  
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/onboardings']);
  
          /* setTimeout(() => {
            this.router.navigate(['/home/employee']);
            //this.loader = false;
          }, redirectMsgTimeOut)
         */
       //   window.location.reload();
          //this.uploadFileInput.nativeElement.value = "";
         // this.fileInputLabel = undefined;
  
           this.success = true;
          this.sucess_msg = result.message; 
         
          this.dialogref.close(result);
  
        }
        else
        {
          console.log("Error");
          this.error = true;
          this.error_msg = result.description;
          this.errorrecords = result;
          this.dialogref.close(result);
          //setTimeout(() => {this.error = false}, redirectMsgTimeOut)
       //   window.location.reload();
        }
      }, error => {
        console.log(error+"Error1");
      });
     }else{
          this.payrollservice.importEmployeeworkingdayswithelements(formData).subscribe((result: any) =>
          {
            if(result.statusCode == 200)
            {
              //this.loader = true;
              console.log("success");
              this.success = true;
              this.sucess_msg = result.description;
      
              //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
              //this.router.navigate(['/home/onboardings']);
      
              /* setTimeout(() => {
                this.router.navigate(['/home/employee']);
                //this.loader = false;
              }, redirectMsgTimeOut)
            */
          //   window.location.reload();
              //this.uploadFileInput.nativeElement.value = "";
            // this.fileInputLabel = undefined;
      
              this.success = true;
              this.sucess_msg = result.message; 
            
              this.dialogref.close(result);
      
            }
            else
            {
              console.log("Error");
              this.error = true;
              this.error_msg = result.description;
              this.errorrecords = result;
              this.dialogref.close(result);
              //setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          //   window.location.reload();
            }
          }, error => {
            console.log(error+"Error1");
          });
     }

   
  }

  showerrormessage()
  {
    this.showtable = true;
  }

  onFileSelect(event)
  {
    // alert("function called");
    //let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0)
    {
      const file = event.target.files[0];
      //this.fileToUpload = event.item(0);
      // console.log(file);

      /*   if (!_.includes(af, file.type)) {
        alert('Only EXCEL Docs Allowed!');
      } else { */
      // this.postForm.patchValue({
      //    file : file.name
      // })
        this.fileInputLabel = file.name;
        console.log("this.fileInputLabel" +this.fileInputLabel);
        this.importForm.get('file').setValue(file);
      ///}
    }
  }
  closedialog()
  {
    this.dialogref.close();
  }

}
