import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProjectManagementService } from '../providers/project-management.service';
import {ActivatedRoute,Router} from '@angular/router';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  myData:any = []
  userName
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  mombyIddata:any= {};
  beforesearchpage: number;
  p: number;
  mombyIddatas: any;

  constructor(
              // public dialogRef: MatDialogRef<PreviewFileComponent>,
              // private dialog:MatDialog,
              // @Inject(MAT_DIALOG_DATA) public data: any,
              public gs:ProjectManagementService,
              public ActivatedRoute:ActivatedRoute,
              public Router:Router
              ) { }

  ngOnInit(): void {
    this.mombyIddata.actionItem=[];
    this.mombyIddata.pointsDiscussed=[];
    console.log(this.ActivatedRoute.snapshot.params.id);
    this.gs.get_momById(this.ActivatedRoute.snapshot.params.id).subscribe(
      (a:any)=>{

      var days = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August','September','October','November','December'];
      var dato = new Date(a.momDate);
      var dayName = days[dato.getMonth()];
      a['date'] = dato.getDate()
      a['year'] = dato.getFullYear()
      a['month'] = dayName.slice(0, 3)

      // if(a.actionItem){
      //   a.actionItem.map(
      //     e=>{
      //       if(e.actionItemName == ''){
      //         delete a.actionItem;
      //       }
      //     }
      //   )
      // }
      //this.myData = this.data;
      //console.log(this.myData);

      this.mombyIddata = a;
      this.mombyIddatas=a.meetingPlace

      console.log("this.mombyIddata",this.mombyIddata);
      console.log("this.mombyIddatas",this.mombyIddatas);
      
    });
  }
  onPageChange(event: Event) {
    console.log(event);
    
    const pageNumber = (event as any).yourProperty;
    
    this.p = pageNumber;
    this.beforesearchpage = pageNumber;
  }
  exportPDF(fileName){
    var data = document.getElementById('pdfTable');
    const pdfTable = this.pdfTable?.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download("Minutes_of_Meeting.pdf"); 

  }
  // print(fileName){
  //   const pdfTable = this.pdfTable?.nativeElement;
  //   var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).print(); 
  // }
  print(fileName) {
  const pdfTable = this.pdfTable?.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
  const documentDefinition = { content: html };
  pdfMake.createPdf(documentDefinition).print();
}

  close(){
    this.Router.navigate(['home/mom-reports'])
  }
}

function html2canvas(data: HTMLElement) {
}
