



<div class="row" style="font-family: Mulish;">

    <div class="col-md-7   mt-5" style="max-height: 1000px; overflow-y: scroll; overflow-x: hidden;">
        <div *ngIf="!showFeedSection">

            <div class="card new_class1" style="margin-left: 15px; margin-top: 30px;padding: 18px;">
                <form [formGroup]="FeedForm">
                    <h3 style="font-family: Mulish; font-weight: bolder;">create a feed!</h3>

                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-2 col-xs-2 col-xl-1" style="padding: 2px;">
                            <img src="../../../assets/uploads/Prof Pic.png" alt="" width="50px" height="50px ">

                        </div>
                        <div class="col-md-10  col-sm-10 col-10 col-xs-10 col-xl-11">
                            <!-- <input type="text" class="form-control" placeholder="enter your feeds!"
                            style="border-radius: 20px; margin-top: 5px;"> -->
                            <!-- <textarea type="text" 
                            rows="1"
                            class="form-control form-control-solid form-control-lg"  style="border-radius: 20px; margin-top: 7px;"
                            ></textarea> -->
                            <!-- app.component.html -->

                            <!-- <ckeditor [(ngModel)]="editorContent"  [editor]="Editor"  (change)="onEditorChange($event)" formControlName="content">
                        </ckeditor> -->

                        <quill-editor   (onEditorCreated)="onEditorCreated($event)" formControlName="content">
                      <div quill-editor-toolbar>
                        <span class="ql-formats">
                          <button type="button" class="ql-bold"></button>
                          <button type="button" class="ql-italic"></button>
                          <button type="button" class="ql-underline"></button>
                          <button type="button" class="ql-strike"></button>
                        </span>
                        <span class="ql-formats">
                          <button type="button" class="ql-code-block"></button>
                        </span>
                        <span class="ql-formats">
                          <button type="button" class="ql-header" value="1"></button>
                          <button type="button" class="ql-header" value="2"></button>
                        </span>
                        <span class="ql-formats">
                          <button type="button" class="ql-list" value="ordered"></button>
                          <button type="button" class="ql-list" value="bullet"></button>
                        </span>
                        <span class="ql-formats">
                          <button type="button" class="ql-script" value="sub"></button>
                          <button type="button" class="ql-script" value="super"></button>
                        </span>
                        <span class="ql-formats">
                          <button type="button" class="ql-indent" value="-1"></button>
                          <button type="button" class="ql-indent" value="+1"></button>
                        </span>
                        <span class="ql-formats">
                          <button type="button" class="ql-direction" value="rtl"></button>
                        </span>
                      <span  class="ql-formats">
                        <select class="ql-size">
                          <option value="small"></option>
                          <option selected></option>
                          <option value="large"></option>
                          <option value="huge"></option>
                        </select>
                      </span>
                      <span  class="ql-formats">
                        <select class="ql-header">
                          <option value="1"></option>
                          <option value="2"></option>
                          <option value="3"></option>
                          <option value="4"></option>
                          <option value="5"></option>
                          <option value="6"></option>
                          <option selected></option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-color" [title]="'Char color'">
                        </select>
                        <select class="ql-background" [title]="'Bg color'">
                        </select>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-font">
                            <option value="serif" selected>Serif</option>
                            <option value="Arial">Arial</option>
                            <option value="Courier New">Courier</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Tahoma">Tahoma</option>
                            <option value="Times New Roman">Times New Roman</option>
                            <option value="Trebuchet MS">Trebuchet MS</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Comic Sans MS">Comic Sans MS</option>
                            <option value="Calibri">Calibri</option>
                            <option value="Roboto">Roboto</option>
                            <option value="Noto Sans">Noto Sans</option>
                            <option value="Helvetica">Helvetica</option>
                        </select>
                        </span>
                        <span class="ql-formats">
                          <select class="ql-align">
                            <option selected></option>
                            <option value="center"></option>
                            <option value="right"></option>
                          
                          </select>
                          </span>
                          <span class="ql-formats">
                            <button type="button" class="ql-clean" ></button>
                          </span>
                          <span class="ql-formats">
                            <button class="ql-link"></button>
                            <button class="ql-image"></button>
                          </span>
                        <span class="ql-formats">
                          <!-- <div class="image-upload">
                            <button (click)="fileInput.click()"  type="button" >
                              <mat-icon   style="font-size: 18px;
                              font-weight: 500; ">attach_file</mat-icon>
                              <input  #fileInput  type="file" id="input-file-now" style="display:none;"  (change)="fileChange($event)" class="file_upload"
                              accept=".xls,.xlsx,.png,.jpg,.gif,.jpeg,.docx,.doc,.docm,.dot,.dotm,
                                      .dotx,.rtf,.xlsx,.xlsm,.xls,.xml,.zip,.rar,.pdf,.pptx" multiple>
                            </button>
                          </div> -->
                        </span>
                      </div>
                        </quill-editor>


                        </div>
                    </div>


                    <div class="row mt-3">
                        <div class="col-md-12 col-12 d-flex justify-content-end">
                            <!-- <button type="submit" class="btn btn-primary search-btn mb-3 mb-lg-0 mr-lg-3"
                            style="background-color:white; padding: 12px; display: flex; justify-content: center; align-items: center;border: 1px solid #ddd ;">
                            <i class="fa fa-paperclip" aria-hidden="true" style="color: black;"></i>
                            <small style="font-size: 13px; color: black; margin-left: 5px;font-weight:bolder;" >Add
                                File</small>
                        </button> -->
                            <label class="file-label custom-border">
                                <div class="row mt-2">
                                    <div class="col-md-2">
                                        <mat-icon class="material-symbols-outlined">
                                            attach_file_add

                                        </mat-icon>
                                    </div>
                                    <div class="col-md-10">
                                        <span class="file-name">{{ fileInputLabel || 'Attach File' }}</span>
                                    </div>
                                </div>


                                <input type="file" formControlName="feedAttachment" placeholder="Enter description"
                                    class="file-input" (change)="onFileSelect($event)">
                            </label>

                            <button type="submit" (click)="onSubmit()"
                                class="btn btn-primary search-btn mb-3 mb-lg-0 mr-lg-3"
                                style="background-color:#1A83FF; padding: 12px; display: flex; justify-content: center; align-items: center;border: 0px solid black ;">
                                <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                                <small style="font-size: 13px; color: white; margin-left: 5px;"> Post</small>
                            </button>

                        </div>
                    </div>


                </form>

            </div>

            <div class="col-md-12 p-0" *ngIf="!loader">
                <div class="card mt-4 new_class1 " style="margin-left: 15px; margin-top: 30px;padding: 18px;">
                    <div *ngIf="posts?.length > 0">

                        <div *ngFor="let post of posts; let i = index">
                            <div class="row">
                                <div class="col-xl-1 col-sm-2 col-2 col-xs-2 col-md-2 ">
                                    <img src="../../../assets/uploads/Image-47-10.png" alt="" width="50px"
                                        height="50px ">

                                </div>
                                <div class="col-xl-8 col-sm-7 col-7 col-md-7 ">
                                    <p style="margin-top: 5px; font-weight: bolder; margin-left: 10px;">
                                        {{post?.employee?.firstName}}
                                        <br><span><small>
                                                {{post?.employee?.designation?.designationName}}</small></span>
                                    </p>
                                </div>
                                <div class="col-xl-3 col-sm-3 col-3 col-md-3 " style="margin-top: 5px;">
                                    <small style="float: right;white-space: nowrap;">{{post?.date }} <br><span><small
                                                style="float:right">{{post?.time|timeconverter }}</small></span></small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <img src="../../../assets/uploads/Image-47-10.png" alt="" width="100%" height="100%"> -->
                                    <p [innerHTML]="post?.content"></p>
                                    <a *ngIf="post?.feedAttachment?.fileViewUrl"
                                        [href]="post.feedAttachment.fileViewUrl" style="font-weight: 600;">View File</a>
                                    <!-- <button type="button" class="btn btn-primary" (click)="downloadfiles(dowUrl)"
                                style="padding: 5px; margin-left: 1%; ">
                                <div class="button-content">
                                  <i class="fa fa-download" style="color:#fff;"></i>
                                  <span class="button-text ml-2">Download</span>
                                </div>
                              </button> -->
                                </div>

                            </div>

                            <div class=" col-md-12">
                                <div class="row mt-4">
                                    <div class="col-xl-4 col-4 col-md-4" style="white-space: nowrap;">
                                        <div (click)="onSubmit1(post)">
                                            <i class="fa fa-thumbs-o-up"  
                                                
                                            [ngClass]="post.liked ? 'fa-thumbs-up liked-icon' : 'fa-thumbs-o-up'"
                                            style="margin-left: 30px;" aria-hidden="true"></i>
                                            <p style="display:inline; margin-left: 5px;">Like</p>
                                        </div>

                                    </div>
                                    <div class="col-xl-4 col-4 col-md-4" style="white-space: nowrap;"
                                        (click)="toggleComment( post.feedId,i)">
                                        <i class="fa fa-comment-o" style="margin-left: 30px;" aria-hidden="true"></i>
                                        <p style="display:inline; margin-left: 5px;">Comment</p>

                                    </div>


                                    <!-- <div class="col-xl-4 col-4 col-md-4" style="white-space: nowrap;">
                                        <i class="fa fa-share-alt" style="margin-left: 30px;" aria-hidden="true"></i>
                                        <p style="display:inline; margin-left: 5px;">Share</p>
                                    </div> -->
                                </div>
                                <div class="row" *ngIf="showComment[post.feedId]">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-2 col-xs-2 col-xl-1"
                                            style="padding: 2px; top: 10px;">
                                            <img src="../../../assets/uploads/Prof Pic.png" alt="" width="40px"
                                                height="40px ">

                                        </div>
                                        <div class="col-md-10  col-sm-10 col-10 col-xs-10 col-xl-11">

                                            <div class="textarea-container"
                                                style="position: relative; display: inline-block;">
                                                <textarea type="text" rows="1"
                                                    class="form-control form-control-solid form-control-lg"
                                                    placeholder="share ur comments" [(ngModel)]="content"></textarea>

                                                <!-- <i class="fa fa-paperclip" aria-hidden="true" 
                                               style="position: absolute; top: 55%; right: 80px; transform: translateY(-50%); cursor: pointer;" ></i> -->
                                                <!-- <i class="fa fa-smile-o" 
                                               style="position: absolute; top: 55%; right: 45px; background-color: #EBEBEB; border-radius: 50%; transform: translateY(-50%); padding: 8px; cursor: pointer;"></i> -->
                                                <i class='attachment' (click)="toggled = !toggled"
                                                    [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'"
                                                    (emojiPickerSelect)="handleSelection($event)"
                                                    style="position: absolute; top: 55%; right: 45px; background-color: #EBEBEB; border-radius: 50%; transform: translateY(-50%); padding: 8px; cursor: pointer;">😄</i>
                                                <img src="../../assets/uploads/icons8-send-30.png" width="30px"
                                                    height="30px"
                                                    style="position: absolute; top: 55%; right: 9px; background-color: #1A83FF; padding: 7px; border-radius: 12px; transform: translateY(-50%); cursor: pointer;"
                                                    alt="" (click)="onSubmit2(post)">
                                            </div>





                                        </div>
                                    </div>

                                    <div *ngIf="com && com.length > 0 && !nodatafound">
                                        <div *ngFor="let coms of com">
                                            <div class="row">
                                                <div class="card mt-2" style="width: 100%; background-color: #F6FAFF;">
                                                    <div class="row no-gutters align-items-center">
                                                        <div
                                                            class="col-xl-1 col-sm-2 col-2 col-xs-2 col-md-2 text-center">
                                                            <img src="../../../assets/uploads/Image-47-10.png" alt=""
                                                                width="30px" height="30px">
                                                        </div>
                                                        <div class="col-xl-8 col-sm-7 col-7 col-md-7">
                                                            <p
                                                                style="margin-top: 5px; font-weight: bolder; margin-left: 10px;">
                                                                {{coms?.employee?.firstName}}<br>
                                                                <span><small>{{coms?.employee?.designation?.designationName}}</small></span>
                                                            </p>
                                                        </div>
                                                        <div class="col-xl-3 col-sm-3 col-3 col-md-3">
                                                            <small
                                                                style="float: right;white-space: nowrap; margin-top: -20px;margin-right:10px">{{coms.time |timeconverter}}
                                                                </small>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <p>{{coms?.content}}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>

                    <div style="text-align: center; font-size: larger; font-weight: bold;" colspan="8"
                        *ngIf="nodatafounds">No data Found!</div>


                </div>

            </div>
        </div>



        <div class="row">
            <div *ngIf="showFeedSection">
                <div class="card "
                    style="position: relative; font-family: Mulish; margin-left: 15px; margin-top: 30px;">
                    <i class="fa fa-arrow-left" (click)="hideFeed()" aria-hidden="true"
                        style="position: absolute; top: 15px; left: 10px; background-color: #F7F7F7; border-radius: 20px; padding: 9px;"></i>
                    <img src="{{rows?.announcementAttachment?.fileViewUrl}}" alt="">
                    <h2
                        style="color: #567AFA; font-size: x-large; font-family: Mulish; padding: 20px; margin-top: 25px;">
                        {{rows?.announcementTitle }}
                    </h2>

                    <p style="margin-left: 3%; margin-top: -1%; margin-right: 3%;"
                        [innerHTML]="rows?.announcementContent"></p>



                </div>

            </div>


        </div>

    </div>

    <div class="col-md-5 mt-5 ">
        <div class="card new_class jan"  *ngIf="!loader"
            style="margin-right: 15px; margin-top: 30px;border-top-left-radius: 10px;border-top-right-radius: 10px; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px; ">
            <div class="col-md-12"
                style="background-color:#426AF8 ;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                <h3 style="color: white;margin:0px;padding:7px 0px
                ">Announcement</h3>

            </div>

            <div style="max-height: 800px; overflow-y: scroll; overflow-x: hidden;">
                <div *ngIf="employees?.length > 0">

                    <div class="row mt-3" *ngFor="let employee of employees">
                        <div class="col-md-12">
                            <ng-template #announcementTemplate let-message="message" let-date="date"
                                let-message1="message1" let-time="time">
                                <div class="row">
                                    <div class="col-md-2 col-xl-1 col-sm-2 col-2">
                                        <i class="fa fa-circle mr-2"
                                        [ngClass]="{'blue-icon': selectedAnnouncementId === employee.announcementId, 'grey-icon': selectedAnnouncementId !== employee.announcementId}"
                                        style="margin-left: 15px;"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div class="col-xl-7 col-md-6 col-sm-6 col-6">
                                        <p style="display: inline; font-weight: bolder;">{{ message }}</p>
                                    </div>
                                    <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                                        <small style="float: right; margin-right: 10px; white-space: nowrap;">{{ date
                                            }}</small>
                                    </div>
                                </div>
                                <div class="row custom-row-height">
                                    <!-- <div class="col-md-8 col-9 col-sm-9">
                                    <small style="display: block; margin-left: 32px;white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;" [innerHTML]="message1"></small>
                                   
                                </div> -->

                                    <div class="col-md-8 col-9 col-sm-9">

                                        <small style="display: block; margin-left: 32px;white-space: nowrap;
                                                                    overflow: hidden;
                                                                    text-overflow: ellipsis; "
                                            [innerHTML]="message1"></small>
                                    </div>

                                    <div class="col-md-4 col-sm-3 col-3" style="float: right;">
                                        <small style="float: right; margin-right: 10px;">{{ time|timeconverter
                                            }}</small>
                                    </div>

                                </div>
                            </ng-template>

                            <div
                                (click)="showFeed(employee.announcementId,employee.announcementTitle,employee.date,employee.announcementContent,employee.time)">
                                <ng-container
                                    *ngTemplateOutlet="announcementTemplate; context: { message: employee.announcementTitle,date: employee.date,message1:employee.announcementContent,time: employee.time}"></ng-container>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
                <div style="text-align: center; font-size: larger; font-weight: bold;" colspan="8" *ngIf="nodatafound1">
                    No data Found!</div>





            </div>


        </div>
        <!-- <div class="card new_class"
            style="margin-right: 15px; margin-top: 30px;border-top-left-radius: 10px;border-top-right-radius: 10px; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div class="col-md-12"
                style="background-color:#426AF8  ;border-top-left-radius: 10px;border-top-right-radius: 10px;">
                <h3 style="color: white;margin:0px;padding:7px 0px">Message <span style="float: right;"><i
                            class="fa fa-pencil-square-o" aria-hidden="true"></i></span></h3>

            </div>
            <div class="col-md-12 col-12 mt-3">
                <input class="form-control search-input form-control-lg form-control-solid" type="text"
                    placeholder="Search.." name="search">
                <i class="fa fa-search"
                    style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%);"></i>

            </div>
            <div style="max-height: 390px; overflow-y: scroll; overflow-x: hidden;">
                <div class="row" style="padding: 20px;" (click)="viewchatbox(element)">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Prof Pic.png" alt="" width="50px" height="50px"
                            style="padding:6px;">
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor
                            <br><span><small>Hi Vincent quick question can i
                                    help u?</small></span>
                        </p>
                    </div>
                    <div class="col-xl-4 col-md-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small
                                    style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-10.png" alt="" width="50px" height="50px"
                            style="padding:6px;">
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor
                            <br><span><small>Hi Vincent quick question can i
                                    help u?</small></span>
                        </p>
                    </div>
                    <div class="col-md-4 col-xl-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small
                                    style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-2.png" alt="" width="50px" height="50px"
                            style="padding:6px;">
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor
                            <br><span><small>Hi Vincent quick question can i
                                    help u?</small></span>
                        </p>
                    </div>
                    <div class="col-md-4 col-xl-4 col-4" style="margin-top: 5px;">
                        <small style="float: right;white-space: nowrap;">Dec 18, 2023 <br><span><small
                                    style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-3.png" alt="" width="50px" height="50px"
                            style="padding:6px;">
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor
                            <br><span><small>Hi Vincent quick question can i
                                    help u?</small></span>
                        </p>
                    </div>
                    <div class="col-md-4 col-xl-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small
                                    style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">
                <div class="row" style="padding: 20px;">
                    <div class="col-xl-1 col-md-2  col-1 pl-0">
                        <img src="../../../assets/uploads/Image-47-9.png" alt="" width="50px" height="50px"
                            style="padding:6px;">
                    </div>
                    <div class="col-xl-7 col-md-6 col-7">
                        <p style="margin-top: 5px;font-weight: bolder; margin-left: 5px ;">Nandini Manohor
                            <br><span><small>Hi Vincent quick question can i
                                    help u?</small></span>
                        </p>
                    </div>
                    <div class="col-xl-4 col-md-4 col-4" style="margin-top: 5px;">
                        <small style="float: right; white-space: nowrap;">Dec 18, 2023 <br><span><small
                                    style="float:right">3m
                                    ago</small></span></small>
                    </div>
                </div>
                <hr style="margin-top: -25px;">

            </div>


        </div> -->
    </div>
    <div *ngIf="loader" class="d-flex align-items-center justify-content-center" style="margin-top: 25%;">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>