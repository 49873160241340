<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">OT Report</span>
                </div>
                <div class="sub-header-buttons">
                    <a title="Download PDF" target="_blank" class="btn text-danger">
                     
                            <i class="fa fa-file-pdf-o" (click)="printPdf()"></i>
                      
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Client<span class="star"> *</span></label> 
                            <select formControlName="client"   class="form-select form-select-solid form-select-lg select2-new">
                                <option [value]=null> --- Select Client --- </option>
                                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                            </select>   
                        </div>
                        <div class="form-group col-md-3">
                            <!-- <input formControlName="employee" type="search" autocomplete="off" class="form-select form-select-solid form-select-lg select2-new" [value]=null placeholder="Search..."> -->
                            <label class="col-form-label">Employee</label>
                            <input type="text" matInput  placeholder="Search Employee"   
                                formControlName="employeecode"
                                [matAutocomplete]="auto"  [readonly]="!filterForm.value.client || filterForm.value.client == 'null'"
                                class="example-input form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-autocomplete #auto="matAutocomplete">
                                <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                    </mat-option> -->
                                <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                {{emp.employeeNumber}}- {{emp.firstName}}  
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                search
                            </mat-icon>
                            <!-- <small class="text-muted">Note : Employee Number</small> -->
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Financial Year</label> 
                            <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                <option [value]=null> -- Select Financialyear --- </option>
                                <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option>  -->
                            </select>
                        </div>
                        <div class="form-group col-md-3 ">
                            <label class="col-form-label">Financial Period</label> 
                            <select formControlName="period" class="form-select form-select-solid form-select-lg select2-new"  >
                                <option [value]=null> -- Select Financial Period --- </option>
                                <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option> 
                            </select>
                        </div>
                       
                    </div>
                    <div class="row">
                   
                        <div class="col-md-3" >
                            <button type="submit" mat-raised-button color="primary"
                                class="btn btn-primary search-btn">Search</button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                class="btn mr-2 search-btn" (click)="clearTable()"
                                >Clear</button>
                        </div>
                    </div>
                </form>
                <div class="row pb-3">
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table  --table-bordered table-border-new">
                        <thead class="t-head">
                            <tr >
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>OT Hours</th>
                                <th>OT Rate</th>
                                <th>Amount</th>
                                <th>Esi</th>
                                <th>Period</th>                     
                                <th>Net Amount</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData.length>0">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td>{{row.employee.employeeNumber}}</td>
                                <td>{{row.employee.firstName}}</td>
                                <td>{{row.otHours}}</td>
                                <td>{{row.client.otWagesPerHour}}</td>
                                <td>{{row.otRate?.toFixed(2)}}</td>
                                <td>{{row.employeeEsi?.toFixed(2)}}</td>
                                <td style="white-space: nowrap;">{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>                    
                                <td>{{(row.otRate -row.employeeEsi )?.toFixed(2)}}</td>
                              
                            </tr>
                           
                        </tbody>
                    </table>
                    <!-- <div> -->
                        <div *ngIf="noRecordsFound">
                            <p style="text-align: center;" [attr.colspan]="resultData.length">No Records Found!</p>
                        </div> 
                    <!-- </div> -->
                 </div>
                
                <div *ngIf="noRecordsFound == false">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>



<div  id="pdfTable" #pdfTable style="display: none;">   
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table items table-bordered table-condensed table-hover" style="margin: 0;width: 100%;" data-pdfmake="{'widths':['*','*'],'heights':10}">
                                <tr>
                                    <td style="text-align: center;border:1px solid #ccc;padding: 0;" colspan="2">
                                        <div style="font-size:13px;font-weight:400;margin:7px 0px 7px 0px;;line-height:1;text-align: center;">Form No 10 </div>
                                        <div style="font-size:13px;font-weight:400;margin:7px 0px 7px 0px;;line-height:1;text-align: center;">(Under Rule 78  of the Tamilnadu Contract Factories,Rules 1950) </div>
                                        <div *ngIf="resultData!=undefined" style="font-size:18px;font-weight:700;margin:7px 0px 7px 0px;"><b>OT Register for the Month of {{period}}</b></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="" colspan="1" style="width:50%;border:1px solid #ccc;margin:0px 0px 10px 0px;">
                                        <!-- <img [src]="base64data" style="height:65px;width : 190px;"> -->
                                        <p class="header" style="font-size:20px;font-weight:700;margin: 0px 0px 0px 0px ;line-height:0.5;text-align: left;"><b>{{userData.organization.organizationName}}</b></p>
                                        <span class="header" style="font-size:13px;font-weight:400;margin: 0px 0px 0px 0px ;line-height:1;text-align: left;">{{userData.organization.address}}, {{userData.organization.city.cityName}}, {{userData.organization.state.stateName}}-{{userData.organization.postalCode}}</span>
                                    </td>
                                    <td class="" colspan="1" style="width:50%;border:1px solid #ccc;margin:0px 0px 10px 0px;">
                                        <p class="header" *ngIf="clientaddressobj != undefined" style="font-size:20px;font-weight:700;margin: 0px 0px 0px 0px ;line-height:0.5;text-align: left;"><b>{{clientaddressobj.clientName}}</b></p>
                                        <span class="header" *ngIf="clientaddressobj != undefined" style="font-size:13px;font-weight:400;margin: 0px 0px 0px 0px ;line-height:1;text-align: left;">{{clientaddressobj.addressLine1}} {{clientaddressobj.addressLine2}}, {{clientaddressobj.city ? clientaddressobj.city.cityName : ""}}, {{clientaddressobj.state ? clientaddressobj.state.stateName : ""}}-{{clientaddressobj.postalCode ? clientaddressobj.postalCode: ""}}</span>
                                    </td>
                                </tr>
                            </table>
                            
                   
                            <table class="table items table-bordered table-condensed table-hover" style="width: 100%;" data-pdfmake="{'widths':[50,50,'*',50,50,55,50,80,120],'heights':5}">
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >SN</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >EMP NO</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >EMPLOYEE NAME</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >OT HRS</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >OT RATE</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >AMOUNT</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >ESI</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >NET AMT</th>
                                <th  style="border:1px solid #ccc;" class="" colspan="1"  >SIGNATURE</th>

                                <tbody *ngIf="resultData.length>0">
                                    <tr *ngFor="let row of resultData  let i=index">
                                        <td style="border:1px solid #ccc;text-align: center;line-height: 1;padding-top:2px;padding-bottom:2px;">{{i+1}}</td>
                                        <td style="border:1px solid #ccc;" >{{row.employee.employeeNumber}}</td>
                                        <td style="border:1px solid #ccc;">{{row.employee.firstName}}</td>
                                        <td  style="border:1px solid #ccc;" >{{row.otHours}}</td>
                                        <td style="border:1px solid #ccc;" >{{row.client.otWagesPerHour}}</td>
                                        <td  style="border:1px solid #ccc;" >{{row.otRate?.toFixed(2)}}</td>
                                        <td  style="border:1px solid #ccc;" >{{row.employeeEsi?.toFixed(2)}}</td>
                                        <td  style="border:1px solid #ccc;" >{{(row.otRate -row.employeeEsi )?.toFixed(2)}}</td>

                                        <td style="border:1px solid #ccc;line-height: 2;"><br><span>{{row.employee.employeeInfo.bankAccountNo }}</span></td>                    
                                        <!-- <td>{{row.employee.employeeInfo.}}</td> -->
                                      
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>    
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>