<div class="container">
  <div class="top-content">
    <!-- <button  (click)="addEmployee()"
      class="btn "
     >Assign Employee</button> -->
    <button  mat-raised-button class="assign-btn" type="submit" (click)="addEmployee()" >
      Assign Employee
    </button>
    <span class="material-icons c" style="color: red" mat-dialog-close
      >cancel</span
    >
  </div>
  <br />

  <div>
    <ul class="">
      <div class="">
        <div class="">
          <!-- <input
            type="text"
            class="form-control "
            placeholder="Search..."
            [(ngModel)]="search"
            (keyup)="changepage()"
            name="searchText"
          /> -->

          <input
            type="text"
          
            placeholder="Search Employee"
            [(ngModel)]="search"
            (keyup)="changepage()"
            class="form-control email-input select-placeholder text-truncate"
        
          />
          <!-- <div class="material-icons-outlined search-icon">search</div> -->
        </div>
      </div>
    </ul>
  </div>

  <div class="container-fluid" *ngIf="projectList.length > 0">
    <div *ngFor="let e of projectList | project : search; let i = index">
      <div class="top-content">
        <img src="../../../assets/img/ava6.png" />
        <div style="font-size: 15px">
          <h6 class="employee-task-report-name">{{ e?.employee?.firstName }}</h6>
          <span class="employee-task-report">{{
            e?.employeeProjectRole?.rolename
          }}</span>
        </div>
        <div class="c">
          <span
            class="material-icons"
            style="font-size: 18px"
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            more_vert
          </span>

          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editEmp(e)">
              <mat-icon class="edit-icon">edit</mat-icon>
              <span class="edit-form">Edit</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <hr />
    </div>

 
  </div>
  <!-- <div *ngIf="nodata">
    <div class="alert alert-primary">
      <h4 align="center">Employees Not Found</h4>
    </div>
  </div> -->
  <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodata || (projectList | project : search).length === 0 && search">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary">
        <h4 align="center" class="h4">Employees Not Found</h4>
      </div>
    </div>
  </mat-card>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
   
  ></div>
</div>