
<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title class="addTask"> Employee Revenue Adjustment </h3>
 
   
    <div class="row">
       <div class="col-md-10">
        <label class="file-label">
            <div class="row">
                <div class="col-md-2">
                    <mat-icon class="material-symbols-outlined">
                        attach_file_add
                       
                        </mat-icon>
                </div>
                <div class="col-md-10">
                    <span>{{  fileInputLabel ||  'Attach File' }}</span>
                </div>
            </div>
         
             
            <input type="file"                                                        
            formControlName="file"
            placeholder="Enter description"  [ngClass]="{ 'is-invalid': submitted }"
            class="file-input"  (change)="onFileSelect($event)">
          </label>
            <div class="valid-msg" *ngIf="(validate?.file?.invalid && validate?.file?.touched) || validate?.file?.dirty || submitted">
                <span *ngIf="validate.file.errors?.required" class="text-danger">Please select .xls / xlsx file</span>
                <span *ngIf="validate.file.errors?.pattern" class="text-danger">Please enter valid .xls / xlsx file</span>
            </div>
           <div>
            <span style=" margin-bottom: 10px;" class="text-muted import-note ">Note : Upload format is .xls / xlsx.</span>
           </div>
		</div>
        
    </div>

    <div class="row">
        <div class="col-md-12">
            <span class="text-muted">
                *** Please download the sample file and import the data as mentioned format. ***
            </span>  
            <a class="download-sample text-danger" href="../../assets/backend/sample_documents/Employee Revenue Adjustment.xlsx" download="Employee Revenue Adjustment.xlsx">
                Download Sample
            </a> 
        </div>
    </div>

    <mat-dialog-actions align="end">
        <!-- <button type="button" mat-button mat-dialog-close 
        style="color:var(--Red-Color, #FF5D5D);">Cancel</button> -->

        <button
        mat-stroked-button
        type="button"
        mat-dialog-close
        style="color:var(--Red-Color, #FF5D5D);"
        [disabled]="btnDisable"
      >
        Cancel
      </button>
 
     <button type="submit" class="searchBtn"  [disabled]="btnDisable" mat-raised-button >Upload</button>
    </mat-dialog-actions>
</form>




<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <!-- <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button> -->
</div>


