import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-validatepayroll',
  templateUrl: './validatepayroll.component.html',
  styleUrls: ['./validatepayroll.component.scss']
})
export class ValidatepayrollComponent implements OnInit{
  validReport:any=[];
  mySelect:number=5;
  p:number=1;
  loader:boolean=false;
  search:any;
  mySelectpopup:any =5;
  nodata:boolean=false;
  tableShow:boolean=false;
  ngOnInit():void{

  }

  sortData(event:any){

  }
  changefirstpage(){
    this.p=1;
  }

}
