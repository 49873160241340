import { Component, OnInit,Inject } from '@angular/core';
import { SettingsService } from '../../../providers/settings.service';
import { RoleComponent } from '../role/role.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-view-role',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.scss']
})
export class ViewRoleComponent implements OnInit {

  resultData: any;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  errorMsg:any;

  constructor(
    private settingsservice: SettingsService,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void
  {
    console.log("data : ",this.data);
  }
}
