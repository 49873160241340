import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  usertData : any;
  userType : any;
  clientIdstore:boolean=false;
  organizationIdStore:any;
  setOrgLogin:boolean=false;
  setClientLogin:boolean=false;
  constructor( private headerservice:HeaderService) { }
  
  ngOnInit(): void 
  {

    this.headerservice.setTitle('');
    // this.headerservice.setTitle('Settings');
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    let employeeId = this.usertData.employee ? this.usertData.employee.employeeId :"";

    this.organizationIdStore=this.usertData?.organization?.organizationId;
    this.clientIdstore=this.usertData?.employee?.client?.clientId;
    console.log("this.organizationIdStore::",this.organizationIdStore);
    

    if(this.organizationIdStore!==undefined){
      this.setOrgLogin=true;
      console.log("this.setOrgLogin",this.setOrgLogin);
      
    }
    // else if(this.clientIdstore){
    //   this.setClientLogin=
    // }

   // this.userType = this.usertData.userType.userType ? this.usertData.userType.userType :"";
   // alert(employeeId+"="+userId);
  }

}
