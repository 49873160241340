<section *ngIf="data == undefined">
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content"> Assign Client Supplier</span>
        </div>
        <div class="sub-header-buttons">
          <a routerLink="/home/ClientSup-list" mat-raised-button title="Back" class="">Back</a>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <div class="col-md-12 p-0" *ngIf="data == undefined">
            <form [formGroup]="clientassignform">
              <!-- Task Type -->
              <div class="row">
                <div class="col-md-3">
                  <div class="input-width" id="loginform">
                    <label class="form-control-label">Client<span class="text-danger"> *</span></label>
                    <input formControlName="client" class="form-control email-input select-placeholder"
                      placeholder="Select Client" [disabled]="empolyeereadonly || disableFeildforAll "
                      [readOnly]="readonlyDisable" [matAutocomplete]="auto" (click)="clearEmpArray()"
                      (keyup)="empSearch($event)">
                    <small class="text-muted">Note : Client Code, Client Name</small>
                    <mat-autocomplete #auto="matAutocomplete">
                      <!-- <mat-option value="All" (click)="empAllSelect()"  [disabled]="empolyeereadonly || disableFeildforAll">All</mat-option> -->
                      <mat-option *ngFor="let client of clientList" [value]="client.clientId"
                        (click)="empSelect(client)">
                        {{client.clientCode}}- {{client.clientName}}
                      </mat-option>
                      <mat-option [value]="null" *ngIf="employeeNotFound" (click)="noEmployeeFound()">No Client Found
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="f.client?.invalid && submitted1 && selectedEmployeeData.length == 0">Client is
                      required</mat-error><br>
                    <span *ngIf="empolyeeapproverexist" class="span-error"> Client Already Exist</span>
                  </div>
                </div>

                <div class="col-md-3">
                  <!-- Task Type -->
                  <div class="input-width" id="loginform">
                    <label class="form-control-label">Supplier <span class="text-danger"> *</span></label>
                    <mat-select formControlName="supplier" placeholder="Select Supplier"
                      class="form-control email-input select-placeholder">
                      <mat-option *ngFor="let supplier of supplierList" [value]="supplier.supplierId">
                        {{supplier.supplierName}}
                      </mat-option>
                      <!-- <mat-option value="supplier 1">supplier 1</mat-option> -->
                    </mat-select>
                    <mat-error *ngIf="f.supplier.invalid && submitted1">Supplier Type is required</mat-error>
                  </div>
                </div>
              </div>
              <div class="table-responsive" *ngIf="selectedEmployeeData.length != 0 || approverId != null"
                style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                <table class="table  --table-bordered table-border-new" style="    width: 100%;
                              border-top-left-radius: 10px;
                              border-top-right-radius: 10px;
                              margin-top: 12px;">
                  <thead class="t-head">
                    <tr>
                      <th class="text-center">Action</th>
                      <th>Client&nbsp;Code</th>
                      <th>Client&nbsp;Name</th>
                      <!-- <th>Designation</th>
                                          <th>DOJ</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let row of selectedEmployeeData | paginate: { itemsPerPage: pagePerItem, currentPage: p }; let i=index;">
                      <td>
                        <div class="text-center" (click)="deleteemployee(i)">
                          <mat-icon style="    vertical-align: middle;
  color: red;">delete</mat-icon>
                        </div>
                      </td>
                      <td>{{row.clientCode}}</td>
                      <td>{{row.clientName}} </td>
                      <!-- <td *ngIf="row.designation !=undefined">{{row.designation.designationName}}</td> -->
                      <!-- <td *ngIf="row.designation == undefined">--</td> -->
                      <!-- <td>{{row.dateOfJoining | date : 'dd-MMM-yyyy'}}</td> -->
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row buttons">
                                  <div class="col-md-12 text-right">
                                      <button type="reset" [disabled]="btnDisable" mat-raised-button (click)="clearemployeelist()" style="margin-right: 20px;"
                                      >Clear</button>                              
                                      <button type="submit" mat-raised-button color="primary" [disabled]="btnDisable">Save</button>
                                  </div>
                              </div> -->
                <div class="row buttons">
                  <div class="col-md-12 text-right">
                    <a
                    mat-raised-button
                    style="
                         margin-left: 10px;
        background: none;
        color: var(--Red-Color, #ff5d5d);
        border: 1px solid #ddd !important;
        height: 36px;
        position: relative;
        top: -2px;
        font-weight: 550;
        padding-top: 10px;
                    "
                    class="btn search-btn mr-2"
                    [disabled]="btnDisable"
                    (click)="clearForm()"
                  >
                    Cancel
                  </a>
                  <button
                    type="submit"
                    mat-raised-button
                    class="n m-1 searchBtn"
                    [disabled]="btnDisable"
                    (click)="submit()"
                  >
                    Save
                  </button>
                    <!-- <div class="col-md-4 col-lg-6 col-xl-6"> -->
                    <!-- <button type="reset" mat-raised-button class="mr-2 btnCancel " (click)="clearemployeelist()" style="height: 35px;
                                          position: relative;
                                          right: 16px;">Clear</button> -->
                    <!-- </div> -->
                    <!-- <div class="col-md-4 col-lg-6 col-xl-6"> -->
                    <!-- <button type="button" mat-raised-button style="background: #1a83ff;
                                          color: #fff;
                                          position: relative;
                                          height: 33px;" (click)="submit()" [disabled]="btnDisable">Save</button> -->
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br>{{success_msg}}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br>{{error_msg}}
</div>
