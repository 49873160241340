import { Component, Input, OnInit, ViewChild,Inject } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { AdminService } from 'src/app/providers/admin.service';
import { UserService } from 'src/app/providers/user.service';
// import { base_url} from '../../providers/properties';
import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { AppComponent } from '../../app.component';
import { HeaderService } from 'src/app/providers/header.service';
import { PayrollService } from 'src/app/providers/payroll.service';

@Component({
  selector: 'app-payrolldashboard',
  templateUrl: './payrolldashboard.component.html',
  styleUrls: ['./payrolldashboard.component.scss']
})
export class PayrolldashboardComponent implements OnInit {
//dateFormat = GlobalConstants.dateFormat;
dateFormat : any;
organizationId: any;

resultData: any = {};
resultDatarec: any;

errorMsg: any;
admindashboard: any;
ProfileShared: number;
Interviewed: any;
Notinterviewed: number;
recruiterSearchform: FormGroup;
@Input() requiredError: boolean;
reportfromdate:any;
reporttodate:any;
startmaxdate :Date =  new Date();
endmindate;
recruiterlist:any;
usertData:any;
loader:any= false;
backdrop:any= false;
dataForExcel = [];
true:any;
interviewStatuslist:any;
headerForExcel = [
  {'Profile Shared':'', 'Interviewed':'', 'Not Interviewed':'', 'Selected':'', 'Rejected':'', 'On Hold':'', 'Open Position':''}
];
exportingdata:any;
selectedrecruiter:any;
employeeName:any;
recruitmentservice: any;
Adminservice: any;

upcomingBirthday : any = {};
upcomingWorkAnniversery : any = {};

fileblob: any;
fileblob1: any;

success:any=false;
error:any=false;
sucess_msg:any;
error_msg:any;
localStorageMenuId:any;
userdata: any;
clientCount: any;
branchcount: any;
 monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  payrollamount: any = {};
    
constructor(
  private dashboardservice: RecruitmentService,
  private adminservice: AdminService,
  private datepipe : DatePipe ,
  private payrollservice :PayrollService,
  private activatedRoute : ActivatedRoute,
  @Inject(AppComponent) public AppData: any,
  private headerservice: HeaderService
) { } 

  ngOnInit(): void {

    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    this.organizationId =this.userdata.organization.organizationId;

    this.adminservice.dashboardData(this.organizationId).subscribe((result: any) =>
      {
        this.resultData = result;
      },err =>{
        this.errorMsg = err.error.message;
      })
 
      const currentDate = new Date();

      // Calculate previous month's date
      let  previousMonth = currentDate.getMonth() - 1;
      let year = currentDate.getFullYear();

      if (previousMonth === -1) {
        // Adjust for January
        previousMonth = 11; // December
    }
    
       // Get previous month's name
       const previousMonthName = this.monthNames[previousMonth];

        console.log(previousMonthName+"-"+year);
      
      // this.payrollservice.payrolldashboard(this.organizationId , "February" , String(year)).subscribe((result: any) =>
      //   {
      //     this.payrollamount = result;
      //   },err =>{
      //     this.errorMsg = err.error.message;
      //   })
  }

}
