import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import  {  NgxEmojiPickerModule  }  from  'ngx-emoji-picker';
import { QuillModule } from 'ngx-quill';
import { BrowserModule } from '@angular/platform-browser';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
/*App Component start here */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './adminComponents/login/login.component';
import { ForgetPasswordComponent } from './adminComponents/forget-password/forget-password.component';
import { DashboardComponent } from './adminComponents/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { FormGroup, FormsModule } from '@angular/forms';
import { ExcelService } from './services/excel.service';
/* App Component end here */
import { DoubleScrollComponent } from './double-scroll/double-scroll.component';
/*Material API Links start */
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EmployeeComponent } from './employeeComponents/employee/employee.component';
import { SettingsComponent } from './settingsComponents/settings/settings.component';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AddEmployeeComponent } from './employeeComponents/add-employee/add-employee.component';

import { MatStepperModule } from '@angular/material/stepper';
import { MyAccountComponent } from './adminComponents/my-account/my-account.component';
import { MatSelectModule } from '@angular/material/select';

import { HttpClientModule } from '@angular/common/http';
import { HrmsService } from './providers/hrms.service';
import { PmsService } from './providers/pms.service';
//import { RecruitmentService } from './providers/recruitment.service';
import { MatTimepickerModule } from 'mat-timepicker';
import { ViewEmployeeComponent } from './employeeComponents/view-employee/view-employee.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { DepartmentComponent } from './settingsComponents/departmentComponent/department/department.component';
import { BranchComponent } from './settingsComponents/branchComponents/branch/branch.component';
import { AddBranchComponent } from './settingsComponents/branchComponents/add-branch/add-branch.component';
import { AddDepartmentComponent } from './settingsComponents/departmentComponent/add-department/add-department.component';
import { SettingsService } from './providers/settings.service';
import { SuperadminService } from './providers/superadmin.service';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { SearchFilterPipe } from './search-filter.pipe';
import { QualificationComponent } from './settingsComponents/qualificationComponent/qualification/qualification.component';
import { AddQualificationComponent } from './settingsComponents/qualificationComponent/add-qualification/add-qualification.component';
import { DesignationComponent } from './settingsComponents/designationComponent/designation/designation.component';
import { AddDesignationComponent } from './settingsComponents/designationComponent/add-designation/add-designation.component';
import { CountryComponent } from './settingsComponents/locationsComponent/country/country.component';
import { AddCountryComponent } from './settingsComponents/locationsComponent/add-country/add-country.component';
import { StateComponent } from './settingsComponents/locationsComponent/state/state.component';
import { AddStateComponent } from './settingsComponents/locationsComponent/add-state/add-state.component';
import { DistrictComponent } from './settingsComponents/locationsComponent/district/district.component';
import { AddDistrictComponent } from './settingsComponents/locationsComponent/add-district/add-district.component';
import { CityComponent } from './settingsComponents/locationsComponent/city/city.component';
import { AddCityComponent } from './settingsComponents/locationsComponent/add-city/add-city.component';
import { RoleComponent } from './settingsComponents/roleComponent/role/role.component';
import { AddRoleComponent } from './settingsComponents/roleComponent/add-role/add-role.component';
import { UserService } from './providers/user.service';
import { EmployeeService } from './providers/employee.service';
import { AdminService } from './providers/admin.service';
import { ListTypesComponent } from './settingsComponents/listTypeComponent/list-types/list-types.component';
import { ListTypeValuesComponent } from './settingsComponents/listTypeComponent/list-type-values/list-type-values.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { PositionComponent } from './recruitment/position/position.component';
import { ClientComponent } from './recruitment/client/client.component';
import { RecruitmentService } from './providers/recruitment.service';
import { GlobalserviceService } from './providers/globalservice.service';
import { ResourceComponent } from './recruitment/resource/resource.component';
import { PositionDetailComponent } from './recruitment/position-detail/position-detail.component';
///import { AdminDashboardComponent } from './recruitment/admin-dashboard/admin-dashboard.component';
import { NotificationResourceDetailComponent } from './recruitment/notification-resource-detail/notification-resource-detail.component';
import { RecruiterDashboardComponent } from './recruitment/recruiter-dashboard/recruiter-dashboard.component';
import { NgChartsModule } from 'ng2-charts';
import { MatInputModule } from '@angular/material/input';
import { ResourceDetailNewComponent } from './recruitment/resource-detail-new/resource-detail-new.component';
import { PositionDetailNewComponent } from './recruitment/position-detail-new/position-detail-new.component';
import { SearchOptionPipe } from './search-option.pipe';

import { MatSortModule } from '@angular/material/sort';
import { SuperAdminComponent } from './superadminComponents/loginComponent/super-admin/super-admin.component';
import { ModulesComponent } from './superadminComponents/moduleComponent/modules/modules.component';

import { AddModuleComponent } from './superadminComponents/moduleComponent/add-module/add-module.component';
import { SuperAdminDashboardComponent } from './superadminComponents/super-admin-dashboard/super-admin-dashboard.component';
import { SuperAdminHomeComponent } from './super-admin-home/super-admin-home.component';
import { OrganizationComponent } from './superadminComponents/organizationComponent/organization/organization.component';
import { AddOrganizationComponent } from './superadminComponents/organizationComponent/add-organization/add-organization.component';
import { ViewOrganizationComponent } from './superadminComponents/organizationComponent/view-organization/view-organization.component';
import { SuperAdminSettingsComponent } from './superadminComponents/superadminsettingsComponents/super-admin-settings/super-admin-settings.component';
import { MenusComponent } from './superadminComponents/menuComponent/menus/menus.component';
import { AddMenuComponent } from './superadminComponents/menuComponent/add-menu/add-menu.component';
import { AddDateFormatComponent } from './superadminComponents/dateFormatComponents/add-date-format/add-date-format.component';
import { DateFormatsComponent } from './superadminComponents/dateFormatComponents/date-formats/date-formats.component';
import { AdminMenusHomeComponent } from './adminComponents/admin-menus-home/admin-menus-home.component';
import { EmployeeRolesComponent } from './employeeRoleComponent/employee-roles/employee-roles.component';
import { AddEmployeeRolesComponent } from './employeeRoleComponent/add-employee-roles/add-employee-roles.component';
import { ApplicationSettingsComponent } from './settingsComponents/application-settings/application-settings.component';

import { UserComponent } from './userComponents/user/user.component';
import { AddUserComponent } from './userComponents/add-user/add-user.component';
import { TimeZonesComponent } from './superadminComponents/timeZoneComponents/time-zones/time-zones.component';
import { AddTimeZoneComponent } from './superadminComponents/timeZoneComponents/add-time-zone/add-time-zone.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ViewRoleComponent } from './settingsComponents/roleComponent/view-role/view-role.component';
import { ViewModuleComponent } from './superadminComponents/moduleComponent/view-module/view-module.component';
import { ClientListComponent } from './recruitment/client-list/client-list.component';
import { PositionListComponent } from './recruitment/position-list/position-list.component';
import { ResourceListComponent } from './recruitment/resource-list/resource-list.component';
import { OnboardingListComponent } from './recruitment/onboarding-list/onboarding-list.component';
import { OnboardingComponent } from './recruitment/onboarding/onboarding.component';
/*Material API Links end */
import { PmsAdminDashboardComponent } from './pms/pms-admin-dashboard/pms-admin-dashboard.component';
import { KraComponent } from './pms/kraComponent/kra/kra.component';
import { AddKraComponent } from './pms/kraComponent/add-kra/add-kra.component';
import { ApproverRulesComponent } from './pms/approvals/approverRulesComponent/approver-rules/approver-rules.component';
import { AddApproverRulesComponent } from './pms/approvals/approverRulesComponent/add-approver-rules/add-approver-rules.component';
import { EmployeeApporversComponent } from './pms/approvals/employeeApproverComponent/employee-apporvers/employee-apporvers.component';
import { AddEmployeeApproversComponent } from './pms/approvals/employeeApproverComponent/add-employee-approvers/add-employee-approvers.component';
import { AssignKraComponent } from './pms/assignKraComponent/assign-kra/assign-kra.component';
import { AddAssignKraComponent } from './pms/assignKraComponent/add-assign-kra/add-assign-kra.component';
import { AppraisalComponent } from './pms/appraisal/appraisal.component';
import { AddAppraisalComponent } from './pms/add-appraisal/add-appraisal.component';
import { FinanicalyearComponent } from './settingsComponents/finanicalyear/finanicalyear.component';
import { FinanicalyearlistComponent } from './settingsComponents/finanicalyearlist/finanicalyearlist.component';
import { FinanicalyeardetailComponent } from './settingsComponents/finanicalyeardetail/finanicalyeardetail.component';
import { ManageElementsListComponent } from './payroll/manage-elements-list/manage-elements-list.component';
import { AddElementsComponent } from './payroll/add-elements/add-elements.component';
import { VariablepayqualiferComponent } from './settingsComponents/variablepayqualifer/variablepayqualifer.component';
import { VariablepayqualiferlistComponent } from './settingsComponents/variablepayqualiferlist/variablepayqualiferlist.component';
import { ListElementPercentageComponent } from './settingsComponents/element-percentage-component/list-element-percentage/list-element-percentage.component';
import { AddElementPercentageComponent } from './settingsComponents/element-percentage-component/add-element-percentage/add-element-percentage.component';
import { CompanySettingsComponent } from './settingsComponents/company-settings/company-settings.component';
import { EmpassignmentComponent } from './empassignment/empassignment.component';
import { ImportClientComponent } from './import-client/import-client.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { ShiftcalendarComponent } from './shift/shiftcalendar/shiftcalendar.component';
import {
  AddHoliday,
  ExceptionHoliday,
  ShifthoildaysExceptionsComponent,
} from './shift/shiftcalendar/shifthoildays-exceptions/shifthoildays-exceptions.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ShiftemployeelistComponent } from './shift/shiftemployeelist/shiftemployeelist.component';
import { ShiftemployeeComponent } from './shift/shiftemployee/shiftemployee.component';
import { TimeconverterPipe } from './timeconverter.pipe';
import { EmpassignmentdetailComponent } from './empassignmentdetail/empassignmentdetail.component';
import { ShiftComponent } from './shift/shift.component';
import { ShowerrorComponent } from './showerror/showerror.component';
import { ShiftdetailsComponent } from './shiftdetails/shiftdetails.component';
import { EmployeeLeaveAddComponent } from './employee-leave/employee-leave-add/employee-leave-add.component';
import { EmployeeLeaveListComponent } from './employee-leave/employee-leave-list/employee-leave-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
//import {MatAutocompleteModule} from '@angular/material/autocomplete';
//import { SelectAutocompleteModule } from 'mat-select-autocomplete';
/* App Component import start here */

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ImportShiftEmployeeComponent } from './shift/import-shift-employee/import-shift-employee.component';
import { EmployeeGroupsComponent } from './employee-groups/employee-groups.component';
import { EmployeeGroupsListComponent } from './employee-groups-list/employee-groups-list.component';
import { ImportEmployeegroupComponent } from './import-employeegroup/import-employeegroup.component';
import { ViewemployeegroupemployeesComponent } from './viewemployeegroupemployees/viewemployeegroupemployees.component';
import { EmployeeRatinglistComponent } from './employee-ratinglist/employee-ratinglist.component';
import { EmployeeRatingComponent } from './employee-rating/employee-rating.component';
import { ManageApproverComponent } from './approvers/manage-approver/manage-approver.component';
import { SalaryManageApprovalsComponent } from './approvers/salary-manage-approvals/salary-manage-approvals.component';
import { ManageEmployeeApproverComponent } from './approvers/manage-employee-approver/manage-employee-approver.component';
import { ManageApproverListComponent } from './approvers/manage-approver-list/manage-approver-list.component';
import { ManageEmployeeApproverListComponent } from './approvers/manage-employee-approver-list/manage-employee-approver-list.component';
import { RecoveryAdjustmentComponent } from './recovery-adjustment/recovery-adjustment.component';
import { RecoveryAdjustmentListComponent } from './recovery-adjustment-list/recovery-adjustment-list.component';
import { EmpRevenueAdjustmentAddComponent } from './emp-revenue-adjustment/emp-revenue-adjustment-add/emp-revenue-adjustment-add.component';
import { EmpRevenueAdjustmentListComponent } from './emp-revenue-adjustment/emp-revenue-adjustment-list/emp-revenue-adjustment-list.component';
import { RefreshcomponentComponent } from './refreshcomponent/refreshcomponent.component';
import { EmployeeAnnexureComponent } from './employee-annexure/employee-annexure.component';
import { EmployeeAnnexureListComponent } from './employee-annexure-list/employee-annexure-list.component';
import { VariablePayListComponent } from './variable-pay/variable-pay-list/variable-pay-list.component';
import { VariablePayAddComponent } from './variable-pay/variable-pay-add/variable-pay-add.component';
import { EmployeePayrollListComponent } from './employee-payroll-list/employee-payroll-list.component';
import { EmployeePayrollComponent } from './employee-payroll/employee-payroll.component';
import { EmployeePayrollDetailComponent } from './employee-payroll-detail/employee-payroll-detail.component';
import { TdsReportsComponent } from './reports/tds-reports/tds-reports.component';
import { PfReportsComponent } from './reports/pf-reports/pf-reports.component';
import { ConsolidatereportComponent } from './reports/consolidatereport/consolidatereport.component';

import { PercentageDirective } from './percentage.directive';
import { RequestSalaryAdvanceComponent } from './request-salary-advance/request-salary-advance.component';
import { SalaryAdvanceListComponent } from './salary-advance-list/salary-advance-list.component';
import { ViewManageApproversComponent } from './approvers/view-manage-approvers/view-manage-approvers.component';
import { SalaryAdvanceDetailsComponent } from './salary-advance-details/salary-advance-details.component';
import { LoanComponent } from './loan/loan.component';
import { LoanListComponent } from './loan-list/loan-list.component';
import { ExistLoanDetailsComponent } from './exist-loan-details/exist-loan-details.component';
import { AdvanceApproverStatusComponent } from './advance-approver-status/advance-approver-status.component';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { LoanManageApprovalsComponent } from './loan-manage-approvals/loan-manage-approvals.component';
import { ManagePayslipComponent } from './payroll/manage-payslip/manage-payslip.component';
import { ViewPayslipComponent } from './payroll/view-payslip/view-payslip.component';
import { ConsolidatePayslipComponent } from './reports/consolidate-payslip/consolidate-payslip.component';
import { ClientElementAssignmentComponent } from './client-elements/client-element-assignment/client-element-assignment.component';
import { ClientWorkingdaysComponent } from './client-workingdays/client-workingdays.component';
import { ImportClientworkingdaysComponent } from './import-clientworkingdays/import-clientworkingdays.component';
import { EmployeeworkingdaysComponent } from './employeeworkingdays/employeeworkingdays.component';
import { EmployeewokingdayslistComponent } from './employeewokingdayslist/employeewokingdayslist.component';
import { EmployeeworkingdaysimportComponent } from './employeeworkingdaysimport/employeeworkingdaysimport.component';
import { ImportEmployeeworkingdaysComponent } from './import-employeeworkingdays/import-employeeworkingdays.component';
import { ClientElementListComponent } from './client-elements/client-element-list/client-element-list.component';
import { ClientEmployeeAnnexueComponent } from './client-employee-annexue/client-employee-annexue.component';
import { CleintEmployeeAnnexueListComponent } from './cleint-employee-annexue-list/cleint-employee-annexue-list.component';
import { UserroleComponent } from './superadminComponents/userrole/userrole.component';
import { UserrolelistComponent } from './superadminComponents/userrolelist/userrolelist.component';
import { ClientRunPayrollComponent } from './client-run-payroll/client-run-payroll.component';
import { ClientemployeePayrollListComponent } from './clientemployee-payroll-list/clientemployee-payroll-list.component';
import { ImportEmployeerecoveryadjustmentComponent } from './import-employeerecoveryadjustment/import-employeerecoveryadjustment.component';
import { ImportEmployeerevenueadjustmentComponent } from './import-employeerevenueadjustment/import-employeerevenueadjustment.component';
import { ManageRecoveryadjustmentComponent } from './manage-recoveryadjustment/manage-recoveryadjustment.component';
import { CreateManageRecoveryadjustmentComponent } from './create-manage-recoveryadjustment/create-manage-recoveryadjustment.component';
import { ClientEmployeeRevenueAdjustmentComponent } from './client-employee-revenue-adjustment/client-employee-revenue-adjustment.component';
import { ClientEmployeeRevenueAdjustmentListComponent } from './client-employee-revenue-adjustment-list/client-employee-revenue-adjustment-list.component';
import { ClientemployeePaylispListComponent } from './clientemployee-paylisp-list/clientemployee-paylisp-list.component';
import { MatclientcomponentsComponent } from './employeeComponents/matclientcomponents/matclientcomponents.component';
import { ClientTaxBreakageComponent } from './client-tax-breakage/client-tax-breakage.component';
import { ClientTaxBreakageListComponent } from './client-tax-breakage-list/client-tax-breakage-list.component';
import { AppraisalReportsComponent } from './pms/appraisal-reports/appraisal-reports.component';
import { AdminReportsComponent } from './pms/admin-reports/admin-reports.component';
import { PreviewPayslipComponent } from './view-payslip/preview-payslip.component';
import { ClientemployeeConslidatepayslipComponent } from './reports/clientemployee-conslidatepayslip/clientemployee-conslidatepayslip.component';
import { MyleavelistComponent } from './leave/myleavelist/myleavelist.component';
import { RequirementListComponent } from './recruitment/requirement/requirement-list/requirement-list/requirement-list.component';
import { RequirementAddComponent } from './recruitment/requirement/requirement-add/requirement-add/requirement-add.component';
import { MatChipsModule } from '@angular/material/chips';
import { RequirementDetailComponent } from './recruitment/requirement/requirement-detail/requirement-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatRadioModule } from '@angular/material/radio';
import { AdminDashboardNewComponent } from './recruitment/admin-dashboard-new/admin-dashboard-new.component';
import { DashboardDialogboxComponent } from './recruitment/dashboard-dialogbox/dashboard-dialogbox.component';
import { SelectedComponent } from './recruitment/selected/selected.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FeedbackFormComponent } from './recruitment/resource-feedback/feedback-form/feedback-form.component';
import { ShareFeedbackFormLinkComponent } from './recruitment/resource-feedback/share-feedback-form-link/share-feedback-form-link.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareFeedbackFormComponent } from './recruitment/resource-feedback/share-feedback-form/share-feedback-form.component';
import { ResourceFeedbackComponent } from './recruitment/resource-feedback/resource-feedback.component';
import { ShareResourceFeedbackLinkComponent } from './recruitment/resource-feedback/share-resource-feedback-link/share-resource-feedback-link.component';
import { ViewAppraisalComponent } from './pms/view-appraisal/view-appraisal.component';
import { ShowapproverlistComponent } from './pms/showapproverlist/showapproverlist.component';
import { ApprisalviewComponent } from './pms/apprisalview/apprisalview.component';
import { OtReportsComponent } from './reports/ot-reports/ot-reports.component';
import { LeaveDashboardComponent } from './leave/leave-dashboard/leave-dashboard.component';
import { ApplyLeaveComponent } from './leave/apply-leave/apply-leave.component';
import { ApplyPermissionComponent } from './leave/apply-permission/apply-permission.component';
import { ApplyWorkFromHomeComponent } from './leave/apply-work-from-home/apply-work-from-home.component';
import { ApplyBusinessTravelComponent } from './leave/apply-business-travel/apply-business-travel.component';
import { MypermissionListComponent } from './leave/mypermission-list/mypermission-list.component';
import { MyWFHListComponent } from './leave/my-wfh-list/my-wfh-list.component';
import { MybusinessTravelListComponent } from './leave/mybusiness-travel-list/mybusiness-travel-list.component';
import { WithdrawListComponent } from './leave/withdraw-list/withdraw-list.component';
import { DenyreasonComponent } from './leave/denyreason/denyreason.component';
import { ManageLeaveComponent } from './leave/manage-leave/manage-leave.component';
import { LeaveTypeListComponent } from './leave/leave-type-list/leave-type-list.component';
import { LeaveAdjustmentListComponent } from './leave/leave-adjustment-list/leave-adjustment-list.component';
import { LeaveAdjustmentComponent } from './leave/leave-adjustment/leave-adjustment.component';
import { TypeListComponent } from './leave/type-list/type-list.component';
import { TypeAddComponent } from './leave/type-add/type-add.component';
import { EmployeeTimeSettingsComponent } from './leave/employee-time-settings/employee-time-settings.component';
import { TimeSettingsFormComponent } from './leave/time-settings-form/time-settings-form.component';
import { EmpPermissionSettingComponent } from './leave/emp-permission-setting/emp-permission-setting.component';
import { WithdrawDetailsComponent } from './leave/myleavelist/withdraw-details/withdraw-details.component';
import { WithdrawComponent } from './leave/myleavelist/withdraw/withdraw.component';
import { DatadialogexampledialogComponent } from './leave/manage-leave/datadialogexampledialog/datadialogexampledialog.component';
import { ManagePermissionComponent } from './leave/manage-permission/manage-permission.component';
import { ManageWorkfromhomeComponent } from './leave/manage-workfromhome/manage-workfromhome.component';
import { ManageBusinesstravelComponent } from './leave/manage-businesstravel/manage-businesstravel.component';
import { ManageWithdrawalComponent } from './leave/manage-withdrawal/manage-withdrawal.component';
import { LocationComponent } from './leave/mybusiness-travel-list/location/location.component';
import { ApproverStatusComponent } from './leave/approver-status/approver-status.component';
import { LeaveCalendarComponent } from './leave/leave-calendar/leave-calendar.component';
import { AddHolidayComponent } from './leave/leave-calendar/add-holiday/add-holiday.component';
import { ExceptionholidayleaveComponent } from './leave/leave-calendar/exceptionholidayleave/exceptionholidayleave.component';
import { MycalendarComponent } from './leave/leave-calendar/mycalendar/mycalendar.component';
import { UploadfileComponent } from './leave/leave-calendar/uploadfile/uploadfile.component';
import { LeaveReportComponent } from './leave/leave-report/leave-report.component';
import { WithdrawReportComponent } from './leave/withdraw-report/withdraw-report.component';
import { PermissionReportComponent } from './leave/permission-report/permission-report.component';
import { BusinesstravelReportComponent } from './leave/businesstravel-report/businesstravel-report.component';
import { WorkfromhomeReportComponent } from './leave/workfromhome-report/workfromhome-report.component';
import { EmpLeaveDashboardComponent } from './leave/emp-leave-dashboard/emp-leave-dashboard.component';
import { OrganizationComplianceComponent } from './organization-compliance/organization-compliance.component';
import { OrganizationComplianceListComponent } from './organization-compliance-list/organization-compliance-list.component';
import { EmployeeratingimportComponent } from './employeeratingimport/employeeratingimport.component';
import { OrganizationworkingdaysComponent } from './organizationworkingdays/organizationworkingdays.component';
import { EmployeePayslipComponent } from './payroll/employee-payslip/employee-payslip.component';
import { ProjectManagementAdminComponent } from '../app/project-management/project-management/project-management-admin/project-management-admin.component';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ProjectManagementService } from './providers/project-management.service';
import { TimeEmployeeDashboardComponent } from '../app/project-management/project-management/time-employee-dashboard/time-employee-dashboard.component';
import { TimeSheetComponent } from '../app/project-management/project-management/time-sheet/time-sheet.component';
import { ViewApproversComponent } from '../app/project-management/project-management/view-approvers/view-approvers.component';
// import { AddTimelogsComponent } from './project-management/add-timelogs/add-timelogs.component';
import { TimesheetreportsComponent } from '../app/project-management/project-management/timesheetreports/timesheetreports.component';
import { ViewdescripeComponent } from '../app/project-management/project-management/viewdescripe/viewdescripe.component';
import { TimelogPipe } from '../app/project-management/project-management/timelog.pipe';
import { ProjectsComponent } from '../app/project-management/project-management/projects/projects.component';
import { AddProjectComponent } from '../app/project-management/project-management/add-project/add-project.component';
// import { EmpListComponent } from '../app/project-management/project-management/emp-list/emp-list.component';
import { EmpTimelogsComponent } from '../app/project-management/project-management/emp-timelogs/emp-timelogs.component';
import { ExpenseApprovalComponent } from './expense-approval/expense-approval.component';
import { ClientEsiChallanComponent } from './reports/client-esi-challan/client-esi-challan.component';
import { ClientPfChallanComponent } from './reports/client-pf-challan/client-pf-challan.component';
import { ClientNetpayChallanComponent } from './reports/client-netpay-challan/client-netpay-challan.component';
import { EmployeeLeaveDashboardComponent } from './leave/employee-leave-dashboard/employee-leave-dashboard.component';
import { WageRegisterComponent } from './wage-register/wage-register.component';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { PayrolldataService } from './services/payrolldata.service';
import { ClientIfReportsComponent } from './reports/client-if-reports/client-if-reports.component';
import { AssaignProjectComponent } from '../app/project-management/project-management/assaign-project/assaign-project.component';
import { AssaignemployeeerrorComponent } from '../app/project-management/project-management/assaignemployeeerror/assaignemployeeerror.component';
import { TaskgroupComponent } from '../app/project-management/project-management/taskgroup/taskgroup.component';
// import { AddclientlistComponent } from './project-management/addclientlist/addclientlist.component';
// import { ClientListsProjectComponent } from './project-management/client-lists-project/client-lists-project.component';
import { AddclientlistComponent } from '../app/project-management/project-management/addclientlist/addclientlist.component';
import { ProjectstageComponent } from '../app/project-management/project-management/projectstage/projectstage.component';
import { ManageWorkHoursComponent } from './settingsComponents/manage-work-hours/manage-work-hours.component';
import { DailytimesheetComponent } from '../app/project-management/project-management/dailytimesheet/dailytimesheet.component';
import { TimesheetReportPipe } from '../app/project-management/project-management/dailytimesheet/timesheet-report.pipe';
import { ViewtimesheetreportComponent } from '../app/project-management/project-management/viewtimesheetreport/viewtimesheetreport.component';
import { ViewdescriptionComponent } from '../app/project-management/project-management/viewdescription/viewdescription.component';
import { TimesheetPipe } from '../app/project-management/project-management/viewtimesheetreport/timesheet.pipe';
import { MomreportsComponent } from '../app/project-management/project-management/momreports/momreports.component';
import { ActionitemreportComponent } from '../app/project-management/project-management/actionitemreport/actionitemreport.component';
import { CreatemomComponent } from '../app/project-management/project-management/createmom/createmom.component';
import { UpdatemomComponent } from '../app/project-management/project-management/updatemom/updatemom.component';
import { CustompipePipe } from './custompipe.pipe';
import { ActionitemPipe } from './actionitem.pipe';
import { UpdateactionComponent } from '../app/project-management/project-management/updateaction/updateaction.component';
import { ManageApprovalsComponent } from './reimbustment/manage-approvals/manage-approvals.component';
import { AdvanceapproverComponent } from './reimbustment/advanceapprover/advanceapprover.component';
import { ExpensereiumbursementapproverstatusComponent } from './expensereiumbursementapproverstatus/expensereiumbursementapproverstatus.component';
import { OrganizationclientlistingComponent } from './organizationclientlisting/organizationclientlisting.component';
import { ClientcreationComponent } from './clientcreation/clientcreation.component';

const circleProgressConfig = {
  radius: 60,
  outerStrokeWidth: 10,
  innerStrokeWidth: 5,
  showBackground: false,
  startFromZero: false,
};
import { AddTimelogsComponent } from '../app/project-management/project-management/emp-timelogs/add-timelogs/add-timelogs.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { AddMultipleTaskComponent } from '../app/project-management/project-management/add-multiple-task/add-multiple-task.component';
import { ShowErrorComponent } from '../app/project-management/project-management/show-error/show-error.component';
import { AddTaskImportComponent } from '../app/project-management/project-management/add-task-import/add-task-import.component';
import { AssignEmployeeTaskComponent } from '../app/project-management/project-management/assign-employee-task/assign-employee-task.component';
import { AssignEmployeeImportComponentComponent } from '../app/project-management/project-management/assign-employee-import-component/assign-employee-import-component.component';
import { AssignEmployeeErrorComponent } from '../app/project-management/project-management/assign-employee-error/assign-employee-error.component';
import { ImportEmployeeComponent } from '../app/project-management/project-management/import-employee/import-employee.component';
// import { AssignEmpTaskComponent } from './project-management/assign-emp-task/assign-emp-task.component';
// import { EmpTimesheetPipe } from './project-management/assign-emp-task/emp-timesheet.pipe';
// import { AssignEmpTaskEditComponent } from './project-management/assign-emp-task/assign-emp-task-edit/assign-emp-task-edit.component';
// import { AssignEmpTaskModule } from './project-management/add-task-import/assign-emp-task.module';
import {
  Descripe,
  ManageTimesheetComponent,
} from '../app/project-management/project-management/manage-timesheet/manage-timesheet.component';
import { TimesheetPipePipe } from '../app/project-management/project-management/manage-timesheet/timesheet-pipe.pipe';
import { PreviewComponent } from './preview/preview.component';
import { EmployeeProjectImportComponentComponent } from './employee-project-import-component/employee-project-import-component.component';
import { ConsolidateTimesheetComponent } from '../app/project-management/project-management/consolidate-timesheet/consolidate-timesheet.component';
import { EmployeeTaskReportComponent } from '../app/project-management/project-management/employee-task-report/employee-task-report.component';
import { EmpTimesheetModule } from '../app/project-management/project-management/emp-timesheet/emp-timesheet.module';
import { EmpTimesheetRoutingModule } from '../app/project-management/project-management/emp-timesheet/emp-timesheet-routing.module';
import { EmployeeTimesheetViewdescriptionComponent } from '../app/project-management/project-management/employee-timesheet-viewdescription/employee-timesheet-viewdescription.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { AssignapproverComponent } from '../app/project-management/project-management/assignapprover/assignapprover.component';
import { AddassignapproverComponent } from '../app/project-management/project-management/addassignapprover/addassignapprover.component';
import { ProjectapproverPipe } from '../app/project-management/project-management/assignapprover/projectapprover.pipe';
import { MytaskComponent } from '../app/project-management/project-management/mytask/mytask.component';
import { UpdateAssignComponent } from '../app/project-management/project-management/update-assign/update-assign.component';
// import { ProjectsPipe } from './projects.pipe';
import { MytaskPipe } from '../app/project-management/project-management/mytask/mytask.pipe';
import { AssignProjectErrorComponent } from '../app/project-management/project-management/assign-project-error/assign-project-error.component';

import { AddEmployeeProjectComponent } from '../app/project-management/project-management/add-employee-project/add-employee-project.component';
import { AppraisalviewdialogComponent } from './pms/appraisalviewdialog/appraisalviewdialog.component';

import { ReportshomeComponent } from './reports/reportshome/reportshome.component';
import { EsireportComponent } from './reports/esireport/esireport.component';
import { HdfcbankreportComponent } from './reports/hdfcbankreport/hdfcbankreport.component';

import { EmpReportComponent } from '../app/project-management/project-management/emp-report-module/emp-report.component';
import { ViewStatusComponent } from '../app/project-management/project-management/view-status/view-status.component';
import { AssignTaskComponent } from '../app/project-management/project-management/assign-task/assign-task.component';
import { TaskCreationComponent } from '../app/project-management/project-management/task-creation/task-creation.component';
import { TaskUpdationComponent } from '../app/project-management/project-management/task-updation/task-updation.component';
import { TaskPipe } from '../app/project-management/project-management/assign-task/task.pipe';
import { ExperexpenseReimbursementCreateComponent } from './reimbustment/experexpense-reimbursement-create/experexpense-reimbursement-create.component';
import { ExpenseReimbursementComponent } from './reimbustment/expense-reimbursement/expense-reimbursement.component';
import { ExpenseDetailsFormComponent } from './reimbustment/expense-details-form/expense-details-form.component';
import { PaymentAdviceDetailsComponent } from './reimbustment/payment-advice-details/payment-advice-details.component';
import { PaymentAdviceComponent } from './reimbustment/payment-advice/payment-advice.component';
import { CategoryComponent } from './reimbustment/category/category.component';
import { AdvanceComponent } from './reimbustment/advance/advance.component';
// import { ProjectPipe } from '../app/project-management/project-management/project.pipe';
import { PayrolldashboardComponent } from './payroll/payrolldashboard/payrolldashboard.component';
import { AmountFormatPipe } from './amount-format.pipe';
import { ChatboxmsgComponent } from './chatboxmsg/chatboxmsg.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PayrollService } from './providers/payroll.service';
import { ExpenseAdvanceapproverstatusComponent } from './advanceapproverstatus/expenseadvanceapproverstatus.component';
import { ProjectPipe } from './project-management/project-management/project.pipe';
import { LeavemanagementstatusComponent } from './leavemanagementstatus/leavemanagementstatus.component';
import { PositionDetailsComponent } from './recruitment/position-details/position-details.component';
import { OnboardingdetailComponent } from './recruitment/onboardingdetail/onboardingdetail.component';
import { ShareresoursefeedbackComponent } from './recruitment/shareresoursefeedback/shareresoursefeedback.component';
import { PaginationControlComponent } from './pagination-control/pagination-control.component';
import { WrapTextDirective } from './providers/wrap-text.directive';
import { PmsDashboardDialogComponentComponent } from './pms-dashboard-dialog-component/pms-dashboard-dialog-component.component';
import { ShowLeave } from './leave/withdraw-list/withdraw-list.component';
import { CustomPaginatePipe } from './project-management/project-management/custom-paginate.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeCalendarComponent } from './employee-calendar/employee-calendar.component';
import { ShowErrorOfWorkingdaysComponent } from './show-error-of-workingdays/show-error-of-workingdays.component';
import { EmployeeErrorComponent } from './employee-error/employee-error.component';
import { BusinessgroupComponent } from './businessgroup/businessgroup.component';
import { LegalentityComponent } from './legalentity/legalentity.component';
import { CorebusinessunitComponent } from './corebusinessunit/corebusinessunit.component';
import { EntityComponent } from './superadminComponents/entity/entity.component';
import { AddsupplierComponent } from './superadminComponents/addsupplier/addsupplier.component';
import { BusinessGroupListingComponent } from './business-group-listing/business-group-listing.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { LegalListComponent } from './legal-list/legal-list.component';
// import { LegalEntityListComponent } from './legal-entity-list/legal-entity-list.component';
import { ListentityComponent } from './listentity/listentity.component';
import { AddbusinessunitComponent } from './addbusinessunit/addbusinessunit.component';
import { AssignEmployeeComponent } from './assign-employee/assign-employee.component';
import { AssignEmployeeListComponent } from './assign-employee-list/assign-employee-list.component';
import { AssignclientsupplierComponent } from './assignclientsupplier/assignclientsupplier.component';
import { SelectWorkingDaysComponent } from './select-working-days/select-working-days.component';
import { OrgWorkingDaysComponent } from './org-working-days/org-working-days.component';
import { ClientWorkingsComponent } from './client-workings/client-workings.component';
import { SelectElementsComponent } from './select-elements/select-elements.component';
import { OrganizationElementsComponent } from './organization-elements/organization-elements.component';
import { OrganizationElementAssignmentComponent } from './organization-element-assignment/organization-element-assignment.component';
import { AssignclientListComponent } from './assignclient-list/assignclient-list.component';

import { ManageannouncementComponent } from './manageannouncement/manageannouncement.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { NewsandfeedComponent } from './newsandfeed/newsandfeed.component';
import { DeleteClientSupplierComponent } from './delete-client-supplier/delete-client-supplier.component';
import { SelectTaxBreakupComponent } from './select-tax-breakup/select-tax-breakup.component';
import { OrgTaxBreakupComponent } from './org-tax-breakup/org-tax-breakup.component';
import { SelectEmployeeTransferComponent } from './select-employee-transfer/select-employee-transfer.component';
import { SelectOrgEmpTransferComponent } from './select-org-emp-transfer/select-org-emp-transfer.component';
import { SelectManageElementsComponent } from './select-manage-elements/select-manage-elements.component';
import { OrgnizationManageElementsComponent } from './orgnization-manage-elements/orgnization-manage-elements.component';
import { AssignclientemployeelistComponent } from './assignclientemployeelist/assignclientemployeelist.component';
import { EmployeeRoleComponent } from './project-management/project-management/employee-role/employee-role.component';
import { AddEmployeeRoleComponent } from './project-management/project-management/add-employee-role/add-employee-role.component';
import { ValidatepayrollComponent } from './validatepayroll/validatepayroll.component';
import { AddrecordsettingComponent } from './addrecordsetting/addrecordsetting.component';
import { ImportEmployeeAnnexureComponent } from './import-employee-annexure/import-employee-annexure.component';
import { ShowerrormessageComponent } from './showerrormessage/showerrormessage.component';
import { ViewDialogComponent } from './pms/view-dialog/view-dialog.component';
// import { NgxSliderModule } from 'ngx-slider-v2';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CustompipePipe,
    MytaskComponent,
    ForgetPasswordComponent,
    DashboardComponent,
    EmployeeComponent,
    ShowLeave,
    SettingsComponent,
    AddEmployeeComponent,
    MyAccountComponent,
    HomeComponent,
    AssignclientsupplierComponent,
    // ManageResourseComponent,
    ViewEmployeeComponent,
    BranchComponent,
    DepartmentComponent,
    AddBranchComponent,
    AddDepartmentComponent,
    PositionDetailsComponent,
    SearchFilterPipe,
    QualificationComponent,
    AddQualificationComponent,
    CountryComponent,
    AddCountryComponent,
    DesignationComponent,
    AddDesignationComponent,
    CountryComponent,
    StateComponent,
    AddStateComponent,
    DistrictComponent,
    AddDistrictComponent,
    CityComponent,
    AddCityComponent,
    RoleComponent,
    AddRoleComponent,
    ListTypesComponent,
    ListTypeValuesComponent,
    PositionComponent,
    PositionListComponent,
    ClientComponent,
    ClientListComponent,
    ResourceListComponent,
    ResourceComponent,
    OnboardingListComponent,
    OnboardingComponent,
    AssignclientsupplierComponent,
    //AdminDashboardComponent,
    PositionDetailComponent,
    NotificationResourceDetailComponent,
    RecruiterDashboardComponent,
    ResourceDetailNewComponent,
    PositionDetailNewComponent,
    SearchOptionPipe,
    ImportEmployeeComponent,
    SuperAdminComponent,
    ModulesComponent,
    AddModuleComponent,
    SuperAdminDashboardComponent,
    SuperAdminHomeComponent,
    OrganizationComponent,
    AddOrganizationComponent,
    ViewOrganizationComponent,
    SuperAdminSettingsComponent,
    MenusComponent,
    AddMenuComponent,
    DateFormatsComponent,
    AddDateFormatComponent,
    AdminMenusHomeComponent,
    PreviewPayslipComponent,
    EmployeeRolesComponent,
    AddEmployeeRolesComponent,
    ApplicationSettingsComponent,
    UserComponent,
    AddUserComponent,
    TimeZonesComponent,
    AddTimeZoneComponent,
    ViewRoleComponent,
    DoubleScrollComponent,
    ViewModuleComponent,
    PmsAdminDashboardComponent,
    KraComponent,
    AddKraComponent,
    ApproverRulesComponent,
    AddApproverRulesComponent,
    EmployeeApporversComponent,
    AddEmployeeApproversComponent,
    AssignKraComponent,
    OrganizationclientlistingComponent,
    AddAssignKraComponent,
    ExpenseApprovalComponent,
    AddAppraisalComponent,
    OnboardingListComponent,
    OnboardingComponent,
    FinanicalyearComponent,
    FinanicalyearlistComponent,
    FinanicalyeardetailComponent,
    ManageElementsListComponent,
    AddElementsComponent,
    VariablepayqualiferComponent,
    VariablepayqualiferlistComponent,
    ListElementPercentageComponent,
    AddElementPercentageComponent,
    CompanySettingsComponent,
    EmpassignmentComponent,
    ImportClientComponent,
    ShiftcalendarComponent,
    ShifthoildaysExceptionsComponent,
    EmpassignmentdetailComponent,
    ShiftComponent,
    AddHoliday,
    ExceptionHoliday,
    ShiftemployeelistComponent,
    ShiftemployeeComponent,
    TimeconverterPipe,
    EmpassignmentdetailComponent,
    ShowerrorComponent,
    ShiftdetailsComponent,
    ImportShiftEmployeeComponent,
    EmployeeGroupsComponent,
    EmployeeGroupsListComponent,
    ImportEmployeegroupComponent,
    ViewemployeegroupemployeesComponent,
    EmployeeLeaveAddComponent,
    EmployeeLeaveListComponent,
    ImportShiftEmployeeComponent,
    EmployeeRatinglistComponent,
    EmployeeRatingComponent,
    ManageApproverComponent,
    SalaryManageApprovalsComponent,
    ManageEmployeeApproverComponent,
    ManageApproverListComponent,
    ManageEmployeeApproverListComponent,
    PercentageDirective,
    RequestSalaryAdvanceComponent,
    SalaryAdvanceListComponent,
    ViewManageApproversComponent,
    SalaryAdvanceDetailsComponent,
    LoanComponent,
    LoanListComponent,
    ExistLoanDetailsComponent,
    AdvanceApproverStatusComponent,
    LoanDetailsComponent,
    LoanManageApprovalsComponent,
    ManagePayslipComponent,
    ViewPayslipComponent,
    RecoveryAdjustmentComponent,
    RecoveryAdjustmentListComponent,
    EmpRevenueAdjustmentAddComponent,
    EmpRevenueAdjustmentListComponent,
    RefreshcomponentComponent,
    EmployeeAnnexureComponent,
    EmployeeAnnexureListComponent,
    VariablePayListComponent,
    VariablePayAddComponent,
    EmployeePayrollListComponent,
    EmployeePayrollComponent,
    EmployeePayrollDetailComponent,
    TdsReportsComponent,
    PfReportsComponent,
    ConsolidatePayslipComponent,
    ConsolidatereportComponent,
    ClientWorkingdaysComponent,
    ImportClientworkingdaysComponent,
    ClientElementAssignmentComponent,
    EmployeeworkingdaysComponent,
    EmployeewokingdayslistComponent,
    EmployeeworkingdaysimportComponent,
    ImportEmployeeworkingdaysComponent,
    ClientElementListComponent,
    ClientEmployeeAnnexueComponent,
    CleintEmployeeAnnexueListComponent,
    UserroleComponent,
    UserrolelistComponent,
    ProjectPipe,
    ClientEmployeeAnnexueComponent,
    ClientRunPayrollComponent,
    ClientemployeePayrollListComponent,
    ImportEmployeerecoveryadjustmentComponent,
    ImportEmployeerevenueadjustmentComponent,
    ManageRecoveryadjustmentComponent,
    CreateManageRecoveryadjustmentComponent,
    ClientEmployeeRevenueAdjustmentComponent,
    ClientEmployeeRevenueAdjustmentListComponent,
    ClientemployeePaylispListComponent,
    MatclientcomponentsComponent,
    ClientTaxBreakageComponent,
    ClientTaxBreakageListComponent,
    AppraisalReportsComponent,
    AdminReportsComponent,
    ClientemployeeConslidatepayslipComponent,
    MyleavelistComponent,
    RequirementListComponent,
    RequirementAddComponent,
    RequirementDetailComponent,
    AdminDashboardNewComponent,
    DashboardDialogboxComponent,
    SelectedComponent,
    FeedbackFormComponent,
    ShareFeedbackFormLinkComponent,
    ShareFeedbackFormComponent,
    ResourceFeedbackComponent,
    ShareResourceFeedbackLinkComponent,
    ViewAppraisalComponent,
    ShowapproverlistComponent,
    ApprisalviewComponent,
    OtReportsComponent,
    AppraisalComponent,
    ClientcreationComponent,

          MyleavelistComponent,
          LeaveDashboardComponent,
          ApplyLeaveComponent,
          ApplyPermissionComponent,
          ApplyWorkFromHomeComponent,
          ApplyBusinessTravelComponent,
          MypermissionListComponent,
          MyWFHListComponent,
          MybusinessTravelListComponent,
          WithdrawListComponent,
          DenyreasonComponent,
          ManageLeaveComponent,
          LeaveTypeListComponent,
          LeaveAdjustmentListComponent,
          LeaveAdjustmentComponent,
          TypeListComponent,
          TypeAddComponent,
          EmployeeTimeSettingsComponent,
          TimeSettingsFormComponent,
          EmpPermissionSettingComponent,
          WithdrawDetailsComponent,
          WithdrawComponent,
          DatadialogexampledialogComponent,
          ManagePermissionComponent,
          ManageWorkfromhomeComponent,
          ManageBusinesstravelComponent,
          ManageWithdrawalComponent,
          LocationComponent,
          ApproverStatusComponent,
          LeaveCalendarComponent,
          AddHolidayComponent,
          ExceptionholidayleaveComponent,
          MycalendarComponent,
          UploadfileComponent,
          LeaveReportComponent,
          WithdrawReportComponent,
          PermissionReportComponent,
          BusinesstravelReportComponent,
          WorkfromhomeReportComponent,
          EmpLeaveDashboardComponent,
          OrganizationComplianceComponent,
          OrganizationComplianceListComponent,
          EmployeeratingimportComponent,
          OrganizationworkingdaysComponent,
          EmployeePayslipComponent,
          ProjectManagementAdminComponent,
          TimeEmployeeDashboardComponent,
          TimeSheetComponent,
          ViewApproversComponent,
          AddTimelogsComponent,
          TimesheetreportsComponent,
          ViewdescripeComponent,
          TimelogPipe,
           ProjectsComponent,
          AddProjectComponent,
          // EmpListComponent,
         EmpTimelogsComponent,
     
          EmployeeLeaveDashboardComponent,
          WageRegisterComponent,
          ViewPdfComponent,
          ClientEsiChallanComponent,
          ClientPfChallanComponent,
          ClientNetpayChallanComponent,
          EmployeeLeaveDashboardComponent,
          ClientIfReportsComponent,
          AssaignProjectComponent,
          AssaignemployeeerrorComponent,
          TaskgroupComponent,
          AddclientlistComponent,
          // ClientListsProjectComponent,
          ProjectstageComponent,
          ManageWorkHoursComponent,
          DailytimesheetComponent,
          TimesheetReportPipe,
          ViewtimesheetreportComponent,
          ViewdescriptionComponent,
          TimesheetPipe,
          ActionitemreportComponent,
          MomreportsComponent,
          // MomcreationComponent,
          CreatemomComponent,
          UpdatemomComponent,
          ActionitemPipe,
          UpdateactionComponent,
          AddMultipleTaskComponent,
                      ShowErrorComponent,
                      AddTaskImportComponent,
                      AssignEmployeeTaskComponent,
                      AssignEmployeeImportComponentComponent,
                      AssignEmployeeErrorComponent,
                      ManageTimesheetComponent,
                      Descripe,
                      TimesheetPipePipe,
                      PreviewComponent,
                      EmployeeProjectImportComponentComponent,        
                      ConsolidateTimesheetComponent,
                      ViewdescripeComponent,
                      EmployeeTaskReportComponent,
                      EmployeeTimesheetViewdescriptionComponent,
                      AddEmployeeComponent,
                      AddEmployeeProjectComponent,
                      AppraisalviewdialogComponent,
                      AssignapproverComponent,
                      AddassignapproverComponent,
                      ProjectapproverPipe,
                      // MytaskComponent,
                      UpdateAssignComponent,
                      // ProjectsPipe
                      MytaskPipe,
                      AssignProjectErrorComponent,
                      AddEmployeeComponent,
                      AddEmployeeProjectComponent,
                      ViewStatusComponent,
                      AssignTaskComponent,
                      TaskCreationComponent,
                      TaskUpdationComponent,
                      TaskPipe,
                      ExperexpenseReimbursementCreateComponent,
                      ExpenseReimbursementComponent,
                      ExpenseDetailsFormComponent,
                      PaymentAdviceDetailsComponent,
                      PaymentAdviceComponent,
                      CategoryComponent,
                      AdvanceComponent,
                      // EmpReportComponent,
                     // AssignEmpTaskComponent,
                      //EmpTimesheetPipe,
                     // AssignEmpTaskEditComponent,
                       ReportshomeComponent,
                        EsireportComponent,
                        HdfcbankreportComponent,
                        PayrolldashboardComponent,
                        AmountFormatPipe,
                        ChatboxmsgComponent,
                        ExpenseAdvanceapproverstatusComponent,
                        ManageApprovalsComponent,
                        AdvanceapproverComponent,
                        ExpensereiumbursementapproverstatusComponent,
                        ViewdescripeComponent,
                        LeavemanagementstatusComponent,

                        OnboardingdetailComponent,
                        ShareresoursefeedbackComponent,
                        PaginationControlComponent,
                        WrapTextDirective,
                        PmsDashboardDialogComponentComponent,
                        ShowLeave,
                        CustomPaginatePipe,
                        EmployeeCalendarComponent,
                        ShowErrorOfWorkingdaysComponent,
                        EmployeeErrorComponent,
                        BusinessgroupComponent,
                        LegalentityComponent,
                        CorebusinessunitComponent,
                        EntityComponent,
                        AddsupplierComponent,
                        BusinessGroupListingComponent,
                        SupplierListComponent,
                        LegalListComponent,
                    
                        ListentityComponent,
                        AddbusinessunitComponent,
                        AssignEmployeeComponent,
                        AssignEmployeeListComponent,
                        SelectWorkingDaysComponent,
                        OrgWorkingDaysComponent,
                        ClientWorkingsComponent,
                        SelectElementsComponent,
                        OrganizationElementsComponent,
                        OrganizationElementAssignmentComponent,
                        AssignclientListComponent,
                        ManageannouncementComponent,
                        AnnouncementComponent,
                        NewsandfeedComponent,
                        DeleteClientSupplierComponent,
                        SelectTaxBreakupComponent,
                        OrgTaxBreakupComponent,
                        SelectEmployeeTransferComponent,
                        SelectOrgEmpTransferComponent,
                        SelectManageElementsComponent,
                        OrgnizationManageElementsComponent,
                        AssignclientemployeelistComponent,
                        EmployeeRoleComponent,
                        AddEmployeeRoleComponent,
                        ValidatepayrollComponent,
                        AddrecordsettingComponent,
                        ImportEmployeeAnnexureComponent,
                        ShowerrormessageComponent,
                        ViewDialogComponent,
          
  ],
  imports: [
    NgxEmojiPickerModule,
    QuillModule.forRoot(),
    BrowserModule,
    CKEditorModule,
    FullCalendarModule,
    AppRoutingModule,
    FormsModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonToggleModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatSelectModule,
    HttpClientModule,
    MatProgressBarModule,
    MatSliderModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTimepickerModule,
    NgChartsModule,
    MatInputModule,
    MatTooltipModule,
    CommonModule,
    MatSortModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    NgbModule,
    MatRadioModule,
    ShareButtonsModule,
    ShareIconsModule,
    ShareModule,
    NgApexchartsModule,
    MatInputModule,
    MatFormFieldModule,
    EmpTimesheetModule,
    EmpTimesheetRoutingModule,
    MatDialogModule,
    NgxMatSelectSearchModule,

    NgCircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 5,
      showBackground: false,
      startFromZero: false,
    }),
    

  ],

  exports: [RouterModule, NgApexchartsModule, EmpTimesheetRoutingModule],
  providers: [
    HrmsService,
    SettingsService,
    MatSnackBar,
    RecruitmentService,
    GlobalserviceService,
    UserService,
    EmployeeService,
    AdminService,
    ExcelService,
    SuperadminService,
    ProjectManagementService,
    DatePipe,
    PmsService,
    CustomPaginatePipe,
    MatDatepickerModule,
    PayrolldataService,
    PayrollService,
    // ProjectPipe,
    // EmpTimesheetPipe,
    // { provide : LocationStrategy , useClass: HashLocationStrategy },
    // { provide: MAT_DATE_FORMATS, useValue: 'en-US'},
    // { provide: MAT_DATE_LOCALE, useValue: 'en-US' }, // Replace 'en-US' with your desired locale

    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_FORMATS, useValue: 'en-GB' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, // Replace 'en-US' with your desired locale

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  bootstrap: [AppComponent],
})
/* App Component import end here */
export class AppModule {}
