import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/providers/header.service';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

@Component({
  selector: 'app-requirement-list',
  templateUrl: './requirement-list.component.html',
  styleUrls: ['./requirement-list.component.scss'],
})
export class RequirementListComponent implements OnInit {
  positionlistdata: any;
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  positionId: any;
  loader = false;
  listTypeValueid: any;
  client: any;
  clientname: any = [];
  clientid: any;
  positionname: any;
  positionSearchform: FormGroup;
  backdrop: any = false;
  showclear: any = false;
  usertype: any;
  userType: any;
  list_loader = true;
  nodata = false;
  positionactivelist: any;
  dataForExcel = [];
  posData: any;
  clientData: any;
  recuirementData: any;
  overAllResourceData: any[];
  tableSearch: any;
  headerForExcel = [
    {
      'Req_Number': '',
      'Position Name': '',
      'Min Experience': '',
      'Max Experience': '',
      'Client Name': '',
      'Max CTC': '',
      'Requirements Status': '',
      Status: '',
    },
  ];
  excelStatus: string;
  excelClient: string;
  excelPosition: string;
  reqLength: any;
  nodatafound: boolean;
  btnDisable: boolean;
  btnsDisable: boolean;
  search: any;
  setActiveNo: boolean = false;
  setInactiveNo: boolean = false;
  btnDisables: boolean = false;
  closeOption: boolean = false;
  openOption: boolean = false;
  noDataFound: boolean = false;
  tableShow: boolean = false;
  isAdmin: boolean = false;
  isRequirement: boolean = false;
  beforesearchpage: number;
  orgId: any;
  storeEntityId:any;
  constructor(
    private router: Router,
    private resourceservice: HrmsserviceService,
    private positionservice: HrmsserviceService,
    public ExportExcelService: ExportExcelService,
    private recService: RecruitmentService,
    private headerservice: HeaderService,
    private SuperadminService:SuperadminService
  ) {}

  ngOnInit(): void {
    // this.headerservice.setTitle('Requirement ');
    this.headerservice.setTitle('');


    this.nodata = true;
    let userStatus = JSON.parse(localStorage.getItem('enoteUserData'));
    this.orgId = userStatus.organization.organizationId;
   this.storeEntityId=userStatus.entity.entityId;
    console.log(localStorage.getItem(localStorage.reqActivePage));
    if (
      localStorage.reqActivePage != null ||
      localStorage.reqActivePage != undefined
    ) {
      // console.log("nav:"+localStorage.reqActivePage);
      this.p = localStorage.reqActivePage;
      //  this.page=JSON.parse(d);
      //  console.log("answer:"+localStorage.reqActivePage);
    } else {
      console.log('empty storage');
      this.p = 1;
    }

    this.positionSearchform = new FormGroup({
      positionName: new FormControl(this.positionSearchform, [
        Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
      ]),
      clientId: new FormControl(this.positionSearchform),
      requirementnumber: new FormControl(this.positionSearchform),
    });

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.usertype.organization.organizationId;
    console.log('local storage::', this.usertype);

    this.userType = [];
    // this.usertype.employee.role.forEach((role) => {
    //   this.userType.push(role?.roleName);
    // });
    if (this.usertype && this.usertype.employee && Array.isArray(this.usertype.employee.role)) {
      this.usertype.employee.role.forEach((role) => {
        this.userType.push(role?.roleName);
      });
    } else if (this.usertype && this.usertype.roleName) {
      // Assuming the role name is directly available when there's no employee object
      this.userType.push(this.usertype.roleName);
    }
    // this.isAdmin = this.userType.includes('Admin');
    this.isAdmin=(this.userType.includes('Admin') && this.userType.includes('Employee') && this.userType.includes('Human Resource')) || 
    (this.userType.includes('Employee') && this.userType.includes('Human Resource')) ||
    (this.userType.includes('Admin') && this.userType.includes('Human Resource'));
    this.isRequirement = this.userType.includes('Recruiter');

    console.log('rolename', this.userType);


    //  //positionname
    //  this.resourceservice
    //  .positionname()
    //   .subscribe((data:any) =>
    //  {
    //    this.positionName = data;
    //    console.log(this.positionName);
    //  }
    //  )
    // //clientname
    // this.resourceservice
    // .clientname()
    //  .subscribe((data:any) =>
    // {
    //   this.clientname = data;
    //   console.log(this.clientname);
    // }
    // )

    // this.recService
    //   .getclientlist(organizationId)
    //   .subscribe((resultData: any) => {
    //     console.log(' resultData', resultData);
    //     resultData.forEach((item: any) => {
    //       if (item.status.listTypeValueId == 1) {
    //         console.log(item.status.listTypeValueId);
    //         this.clientname.push(item);
    //         console.log(' this.clientlistdata', this.clientname);
    //       }
    //     });
    //   });

    // position active list
    this.positionservice.positionactivelist(this.orgId).subscribe((position: any) => {
      this.positionactivelist = position;
      //  this.patchresource();
      console.log(this.positionactivelist);
    });

    // this.resourceservice
    // . requirementlist()
    // .subscribe((positionlistdata:any) =>
    // {
    //   this.list_loader = false;
    //   this.positionlistdata = positionlistdata;
    //   console.log(this.positionlistdata);
    //   this.reqLength=this.positionlistdata.length;
    //   console.log(this.reqLength);
    //   // this.positionlistdata.forEach(element => {
    //   //   console.log(this.positionlistdata);
    //   //   console.log(element);
    //   // });

    //   if(this.positionlistdata.length == 0)
    //   {
    //     this.nodata = true;
    //     console.log("nodata");
    //   }
    // },
    // (error) => {
    //   this.list_loader = false;
    //   this.nodata = true;
    //   console.log("error")
    //   if(error.status == 204){
    //     this.nodata = true;
    //     console.log("204")
    //   }
    // }
    // )
    this.getAllClientList();
  }
  getAllClientList(){
    // this.loader=true;
    // this.tableShow=false
    this.SuperadminService.getAllClientlist(this.storeEntityId).subscribe((resultData:any)=>{

      console.log(' client list', resultData);
          resultData.forEach((item: any) => {
            if (item.status.listTypeValueId == 1) {
              let storeStatusListValueNames=item.status.listTypeValueName
              console.log("storeStatusListValueId:",storeStatusListValueNames);
              this.clientname=resultData;
              // this.clientname?.push(storeStatusListValueId);
              console.log('active client list::', this.clientname);
              // this.patchresource();
            }
  
    })
  })
}
  // Navigate Create page
  create() {
    console.log('Create Page');
    this.router.navigate(['/home/recuirement']);
  }
  viweDetail(e) {
    console.log('e::', e);

    this.router.navigate([`/home/requirement-details/${e}`]);
  }
  changepage() {
    console.log(this.positionlistdata.length);
    if (this.search.length != 0) {
      this.p = 1;
      console.log(this.positionlistdata.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
      console.log(this.p);
      console.log('success Data');
    } else {
      this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.positionlistdata.length);
      console.log('nodata');
    }
  }
  statuschangeactive(requirementId) {
    console.log('positrequirementIdonId' + requirementId);
    this.resourceservice
      .requirementstatus(requirementId, 'In-Active')
      .subscribe((data: any) => {
        // console.log("positionId");
        // window.location.reload();
        // this.loader = true;
        // this.tableShow=false
        // this.backdrop = true;
        this.nodata=false

        this.submitData();
      });
  }
  statuschangeinactive(requirementId) {
    // console.log("requirementId"+requirementId);
    this.resourceservice
      .requirementstatus(requirementId, 'Active')
      .subscribe((data: any) => {
        this.nodata=false
        // this.loader = true;
        // this.backdrop = true;
        // this.tableShow=false

        // console.log("requirementId");
        // window.location.reload();
        this.submitData();
      });
  }
  statuschangetrue(requirementId) {
    // console.log("requirementId"+requirementId);
    this.resourceservice
      .requirementstatuschange(requirementId, true)
      .subscribe((data: any) => {
        this.nodata = false;
        console.log('requirementId');
        //  window.location.reload();
        this.submitData();
      });
  }
  statuschangefalse(requirementId) {
    // console.log("requirementIdonId"+requirementId);
    this.resourceservice
      .requirementstatuschange(requirementId, false)
      .subscribe((data: any) => {
        this.nodata = false;
        console.log('requirementId');
        //  window.location.reload();
        this.submitData();
      });
  }

  Add_resource() {
    this.router.navigate(['/home/requirement']);
  }
  // updateform(position)
  // {
  //   // this.router.navigate(['home/position'/{{}}]);
  // }

  onKeyUpEvent(event) {
    if (this.query.length != 0) {
      console.log(this.query.length);
      this.p = 1;
    } else {
      this.p = this.beforepage;
    }
  }
  getrequirementno() {
    //  console.log("Requirement Id");
  }
  // getclientname(event:MatSelectChange)
  // {
  //   console.log(event);
  //   let status = {
  //     value: event.value,
  //     text: event.source.triggerValue
  //   };
  //   console.log(status);
  //   console.log(status.text);
  //   this.excelClient=status.text;
  //   console.log(this.excelClient);
  // }
  getclientname(event: any) {
    console.log(event);
    let status = {
      value: event?.target?.value,
      text: event?.target?.options[event.target.selectedIndex].text,
    };
    console.log(status);
    console.log(status.text);
    this.excelClient = status.text;
    console.log(this.excelClient);
  }
  getpostionname(event: MatSelectChange) {
    console.log(event);
    let status = {
      value: event?.value,
      text: event.source.triggerValue,
    };
    console.log(status);
    console.log(status.text);
    this.excelPosition = status.text;
    console.log(this.excelPosition);
  }
  clearfilter() {
    this.p = 1;
    this.nodata = false;
    console.log('Clear');
    this.positionSearchform.patchValue({
      positionName: null,
      clientId: null,
      requirementnumber: '',
    });

    this.resourceservice
      .requirementlist()
      .subscribe((positionlistdata: any) => {
        this.positionlistdata = positionlistdata;
        console.log('table listing data::', this.positionlistdata);

        this.reqLength = this.positionlistdata.length;
        console.log(this.reqLength);
        if (this.positionlistdata.length == 0) {
          this.nodata = true;
          console.log(this.positionlistdata.length);
        }
        console.log(this.positionlistdata);
      });
  }
  submitData() {
    // if(this.positionSearchform.invalid)
    // {
    //   alert('Please select from position and client and recuirement');
    //   return;
    // }
    // console.log(this.loader);
    // this.loader=true;
    // console.log("loader1:"+this.loader);
    this.search = '';
    this.p = 1;
    this.btnDisables = false;
    //  this.nodata=true;
    let posId = this.positionSearchform.value.positionName;
    let cliId = this.positionSearchform.value.clientId;
    let recuirementId = this.positionSearchform.value.requirementnumber;
    console.log(posId);
    console.log(recuirementId);
    console.log(cliId);
    if (
      (posId == null || posId == 'null') &&
      (recuirementId == null ||
        recuirementId == 'null' ||
        recuirementId == '') &&
      (cliId == null || cliId == 'null')
    ) {
      alert('Please Choose Any Field to Search!!');
      this.btnDisables = false;

      this.nodata = true;
      return;
    }
    this.positionlistdata = [];

    if (
      (posId !== null || posId !== 'null') &&
      (recuirementId == null ||
        recuirementId == 'null' ||
        recuirementId == '') &&
      (cliId == null || cliId == 'null')
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;

      console.log('Posistion calling!!');
      this.resourceservice.positionList(posId,this.orgId).subscribe(
        (positionlistdata: any) => {
          this.nodata = false;
          this.backdrop = false;
          this.positionlistdata = positionlistdata;
          this.btnDisables = true;
          this.loader = false;
          this.tableShow = true;

          console.log(this.positionlistdata);
          //  this.reqLength=this.positionlistdata?.length;
          console.log(this.reqLength);
          if (this.positionlistdata.length == 0) {
            this.nodata = true;
            this.btnDisables = false;
            this.loader = false;
            this.tableShow = true;

            console.log('nodata');
          }
          this.btnDisables = false;
        },
        (error) => {
          this.loader = false;
          this.nodata = true;
          this.btnDisables = false;
          this.tableShow = true;

          console.log('No Data');
        }
      );
    } else if (
      (posId == null || posId == 'null') &&
      (recuirementId !== null ||
        recuirementId !== 'null' ||
        recuirementId !== '') &&
      (cliId == null || cliId == 'null')
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;

      console.log('Requirtment Id!!');

      this.resourceservice.recuriementList(recuirementId,this.orgId).subscribe(
        (positionlistdata: any) => {
          this.nodata = false;
          this.backdrop = false;

          this.positionlistdata = positionlistdata;
          this.btnDisables = true;
          this.loader = false;
          this.tableShow = true;

          console.log(this.positionlistdata);
          this.reqLength = this.positionlistdata.length;
          console.log(this.reqLength);
          if (this.positionlistdata.length == 0) {
            this.nodata = true;
            this.loader = false;
            this.tableShow = true;

            this.btnDisables = false;

            console.log('nodata');
          }
          this.btnDisables = false;
        },
        (error) => {
          this.loader = false;
          this.nodata = true;
          this.btnDisables = false;
          this.tableShow = true;

          console.log('No Data');
        }
      );
    } else if (
      (posId == null || posId == 'null') &&
      (recuirementId == null ||
        recuirementId == 'null' ||
        recuirementId == '') &&
      (cliId !== null || cliId !== 'null')
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;

      console.log('Client !!');
      this.resourceservice.clientList(cliId,this.orgId).subscribe(
        (positionlistdata: any) => {
          this.backdrop = false;

          this.nodata = false;
          this.positionlistdata = positionlistdata;
          this.btnDisables = true;
          this.loader = false;
          this.tableShow = true;

          console.log(this.positionlistdata);
          // this.reqLength=this.positionlistdata?.length;
          console.log(this.reqLength);
          if (this.positionlistdata.length == 0) {
            this.btnDisables = false;
            this.loader = false;
            this.tableShow = true;

            this.nodata = true;
            console.log('nodata');
          }
          this.btnDisables = false;
        },
        (error) => {
          this.loader = false;
          this.nodata = true;
          this.tableShow = true;

          this.btnDisables = false;

          console.log('No Data');
        }
      );
    } else if (
      (posId !== null || posId !== 'null') &&
      (recuirementId !== null ||
        recuirementId !== 'null' ||
        recuirementId !== '') &&
      (cliId == null || cliId == 'null')
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;

      console.log('position and requiremnt!!');
      this.resourceservice
        .positionandrecuriementList(recuirementId, posId,this.orgId)
        .subscribe(
          (positionlistdata: any) => {
            this.nodata = false;

            this.backdrop = false;
            this.positionlistdata = positionlistdata;
            this.btnDisables = true;
            this.loader = false;
            this.tableShow = true;

            console.log(this.positionlistdata);
            // this.reqLength=this.positionlistdata?.length;
            console.log(this.reqLength);
            if (this.positionlistdata.length == 0) {
              this.btnDisables = false;
              this.nodata = true;
              this.loader = false;
              this.tableShow = true;

              console.log('nodata');
            }
            this.btnDisables = false;
          },
          (error) => {
            this.loader = false;
            this.nodata = true;
            this.tableShow = true;

            this.btnDisables = false;

            console.log('No Data');
          }
        );
    } else if (
      (posId !== null || posId !== 'null') &&
      (recuirementId == null ||
        recuirementId == 'null' ||
        recuirementId == '') &&
      (cliId !== null || cliId !== 'null')
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;

      console.log('position and Client!!');
      this.resourceservice.positionandclientList(posId, cliId,this.orgId).subscribe(
        (positionlistdata: any) => {
          this.nodata = false;
          this.backdrop = false;

          this.positionlistdata = positionlistdata;
          this.btnDisables = true;
          this.loader = false;
          this.tableShow = true;

          console.log(this.positionlistdata);
          // this.reqLength=this.positionlistdata?.length;
          console.log(this.reqLength);
          if (this.positionlistdata.length == 0) {
            this.btnDisables = false;
            this.nodata = true;
            this.loader = false;
            this.tableShow = true;

            console.log('nodata');
          }
          this.btnDisables = false;
        },
        (error) => {
          this.loader = false;
          this.nodata = true;
          this.tableShow = true;

          this.btnDisables = false;

          console.log('No Data');
        }
      );
    } else if (
      (posId == null || posId == 'null') &&
      (recuirementId !== null ||
        recuirementId !== 'null' ||
        recuirementId !== '') &&
      (cliId !== null || cliId !== 'null')
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;

      console.log('requirment and client!!');

      this.resourceservice
        .recuriementandclientList(recuirementId, cliId,this.orgId)
        .subscribe(
          (positionlistdata: any) => {
            this.backdrop = false;

            this.nodata = false;
            this.positionlistdata = positionlistdata;
            this.btnDisables = true;
            this.loader = false;
            this.tableShow = true;

            console.log(this.positionlistdata);

            // this.reqLength=this.positionlistdata.length;
            // console.log(this.reqLength);
            if (this.positionlistdata.statusCode == 200) {
              this.nodata = true;
              this.loader = false;
              this.tableShow = true;

              this.btnDisables = false;
            }

            if (this.positionlistdata.length == 0) {
              this.btnDisables = false;

              this.nodata = true;
              this.tableShow = true;

              console.log('nodata');
            }
            this.btnDisables = false;
          },
          (error) => {
            this.loader = false;
            this.nodata = true;
            this.btnDisables = false;
            this.tableShow = true;

            console.log('No Data');
          }
        );
    } else if (
      (posId !== null || posId !== 'null') &&
      (recuirementId !== null ||
        recuirementId !== 'null' ||
        recuirementId !== '') &&
      (cliId !== null || cliId !== 'null')
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.tableShow = false;

      console.log('posistion and requirment and client !!');
      this.resourceservice
        .recuirementoverallfilterList(recuirementId, posId, cliId,this.orgId)
        .subscribe(
          (positionlistdata: any) => {
            this.backdrop = false;
            this.nodata = false;

            this.positionlistdata = positionlistdata;
            this.btnDisables = true;
            this.loader = false;
            this.tableShow = true;

            // console.log(this.positionlistdata);
            // this.reqLength=this.positionlistdata?.length;
            console.log(this.reqLength);
            if (this.positionlistdata.length == 0) {
              this.btnDisables = false;
              this.nodata = true;
              this.loader = false;
              this.tableShow = true;

              console.log('nodata');
            }
            this.btnDisables = false;
          },
          (error) => {
            this.loader = false;
            this.nodata = true;
            this.btnDisables = false;
            this.tableShow = true;

            console.log('No Data');
          }
        );
    } else {
      console.log('all condition came out!!');
    }

    // console.log(recuirementId.length);
    //  if((posId==null || posId=='null')&&(recuirementId===null || recuirementId==='null')&&(cliId==null || cliId=='null'))
    // {
    //   console.log("enterd!!!");

    //   // alert('Please select from position and client and recuirement.');
    //   //   return;
    //   this.resourceservice
    //   . requirementlist()
    //   .subscribe((positionlistdata:any) =>
    //   {
    //     this.positionlistdata = positionlistdata;
    //     this.reqLength=this.positionlistdata.length;
    //     console.log(this.reqLength);
    //     if(this.positionlistdata.length == 0)
    //     {
    //       this.nodata = true;
    //       console.log(this.positionlistdata.length);
    //     }
    //     console.log(this.positionlistdata);
    //   }
    //   )
    // }

    // if ((recuirementId!=null || recuirementId!='null') &&(posId===null || posId==='null')&&(cliId===null || cliId==='null'))
    // {
    //  console.log("RecuirementId list");
    //  // this.positionlistdata.length=0;
    //   this.loader=true;
    //   this.backdrop=true;
    //     // clientid
    //  this.resourceservice
    //   .recuriementList(recuirementId)
    //   .subscribe((positionlistdata:any) =>
    //  {
    //   this.loader=false;
    //   this.backdrop=false;
    //   this.positionlistdata = positionlistdata;
    //   console.log(this.positionlistdata);
    //   this.reqLength=this.positionlistdata.length;
    //   console.log(this.reqLength);
    //   if(this.positionlistdata.length == 0)
    //   {
    //     this.nodata = true;
    //     console.log("nodata");
    //   }
    //  }
    //   )
    // }
    // else if((recuirementId != null || recuirementId !='null') &&cliId!=null && (posId ===null || posId ==='null'))
    // // else if((recuirementId.length!=0||recuirementId!=null||recuirementId!=undefined)&&(cliId!=null)&&(posId==null))
    // {
    //   console.log("recuirement and client list");
    //   // this.positionlistdata.length=0;
    //   this.loader=true;
    //   this.backdrop=true;
    //     //positionname
    //  this.resourceservice
    //  .recuriementandclientList(recuirementId,cliId)
    //   .subscribe((positionlistdata:any) =>
    //  {
    //   this.loader=false;
    //   this.backdrop=false;
    //   this.nodata=false
    //   this.positionlistdata = positionlistdata;
    //   console.log(this.positionlistdata);

    //   // this.reqLength=this.positionlistdata.length;
    //   // console.log(this.reqLength);

    //   if(this.positionlistdata.length == 0)
    //     {
    //       this.nodata = true;
    //       console.log("nodata");
    //     }
    //  }
    //  )
    // }
    // else if (cliId!=null&&(recuirementId===null || recuirementId==='null')&&(posId===null || posId==='null'))
    // // else
    //  {
    //   console.log("Client id list");
    //   // this.positionlistdata.length=0;
    //   this.loader=true;
    //   this.backdrop=true;
    //     // clientid
    //     this.resourceservice
    //     .clientList(cliId)
    //     .subscribe((positionlistdata:any) =>
    //    {
    //      this.loader=false;
    //     this.backdrop=false;
    //     this.positionlistdata = positionlistdata;
    //     console.log(this.positionlistdata);
    //     // this.reqLength=this.positionlistdata?.length;
    //     console.log(this.reqLength);
    //     if(this.positionlistdata.length == 0)
    //     {
    //       this.nodata = true;
    //       console.log("nodata");
    //     }
    //    }
    //     )
    //  }
    // else if((recuirementId!=null || recuirementId!='null') && posId !=null && (cliId===null || cliId==='null'))
    // // else if((recuirementId.length!=0||recuirementId!=null||recuirementId!=undefined)&&(cliId!=null)&&(posId==null))
    // {
    //   console.log("Position and Recuriement list");
    //   // this.positionlistdata.length=0;
    //   this.loader=true;
    //   this.backdrop=true;
    //     //positionname
    //  this.resourceservice
    //  .positionandrecuriementList(recuirementId,posId)
    // //  .recuriementandclientList(recuirementId,cliId)
    //   .subscribe((positionlistdata:any) =>
    //  {
    //   this.loader=false;
    //   this.backdrop=false;
    //   this.positionlistdata = positionlistdata;
    //   console.log(this.positionlistdata);
    //   // this.reqLength=this.positionlistdata?.length;
    //   console.log(this.reqLength);
    //   if(this.positionlistdata.length == 0)
    //   {
    //     this.nodata = true;
    //     console.log("nodata");
    //   }
    //  }
    //  )
    // }
    // else if (posId!=null && (recuirementId==null || recuirementId=='null') && (cliId===null ||cliId==='null'))
    //  {
    //   console.log("Position id list");
    //   // this.positionlistdata.length=0;
    //    this.loader=true;
    //    this.backdrop=true;
    //      // clientid
    //   this.resourceservice
    //   .positionList(posId)
    //    .subscribe((positionlistdata:any) =>
    //   {
    //     console.log("Position id list");
    //    this.loader=false;
    //    this.backdrop=false;
    //    this.positionlistdata = positionlistdata;
    //    console.log(this.positionlistdata);
    //   //  this.reqLength=this.positionlistdata?.length;
    //    console.log(this.reqLength);
    //    if(this.positionlistdata.length == 0)
    //    {
    //      this.nodata = true;
    //      console.log("nodata");
    //    }
    //   }
    //   )
    // }
    //  else if(posId!=null&&recuirementId?.length!=0&&cliId!=null)
    //  {
    //       console.log("position and client and recuirement  list");
    //       this.positionlistdata.length=0;
    //       this.loader=true;
    //       this.backdrop=true;
    //       this.resourceservice
    //       .recuirementoverallfilterList(recuirementId,posId,cliId)
    //        .subscribe((positionlistdata:any) =>
    //       {
    //         this.loader=false;
    //         this.backdrop=false;
    //         this.positionlistdata = positionlistdata;
    //         // console.log(this.positionlistdata);
    //         // this.reqLength=this.positionlistdata?.length;
    //          console.log(this.reqLength);
    //         if(this.positionlistdata.length == 0)
    //         {
    //           this.nodata = true;
    //           console.log("nodata");
    //         }
    //       }
    //       )
    //   }

    //   else if(posId !=null&&cliId!=null && recuirementId.length==0)
    //   // else if((recuirementId.length!=0||recuirementId!=null||recuirementId!=undefined)&&(cliId!=null)&&(posId==null))
    //   {
    //     console.log("Position and client list");
    //     // this.positionlistdata.length=0;
    //     this.loader=true;
    //     this.backdrop=true;
    //       //positionname
    //    this.resourceservice
    //    .positionandclientList(posId,cliId)
    //   //  .recuriementandclientList(recuirementId,cliId)
    //     .subscribe((positionlistdata:any) =>
    //    {
    //     this.loader=false;
    //     this.backdrop=false;
    //     this.positionlistdata = positionlistdata;
    //     console.log(this.positionlistdata);
    //     // this.reqLength=this.positionlistdata?.length;
    //     console.log(this.reqLength);
    //     if(this.positionlistdata.length == 0)
    //     {
    //       this.nodata = true;
    //       console.log("nodata");
    //     }
    //    }
    //    )
    //   }
  }

  //  export function
  exportToExcel() {
    console.log('Export');
    this.posData = this.positionSearchform.value.positionName;
    this.clientData = this.positionSearchform.value.clientId;
    this.recuirementData = this.positionSearchform.value.requirementnumber;
    console.log(this.posData);
    console.log(this.clientData);
    console.log(this.recuirementData);
    this.dataForExcel.length = 0;
    console.log(this.dataForExcel.length);
    if (this.positionlistdata.length != 0) {
      console.log('Export filter');
      if (
        this.posData != null &&
        this.recuirementData?.length != 0 &&
        this.clientData != null
      ) {
        console.log('position and client and recuirement Date list');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          console.log('e', e);

          console.log('e.client::', e.client);

          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            // clientName:e.client!=undefined?e.client.clientName:'In-House',
            clientName:
              e.client !== undefined && e.client.clientName !== undefined
                ? e.client.clientName
                : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log('this.dataForExcel::', this.dataForExcel);
        }
        this.dataForExcel.map((res) => {
          console.log('check res', res);

          // res.forEach((d) => {
          //   console.log('check res2',d);

          // });
          console.log('recruitment::', res[0]);
          console.log('posistion::', res[1]);
          console.log('client::', res[4]);
          this.excelPosition = res[1];
          this.excelClient = res[4];
          this.recuirementData = res[0];

          console.log(this.excelPosition);
          console.log(this.excelClient);
        });

        let reportDatabyrecruiter = {
          title: 'Requirement List',
          //  exceldate: 'Position:'+this.excelPosition +"  "+ 'Requirement:'+this. recuirementData+"  "+ 'Client:'+this.excelClient,
          exceldate: `Position: ${this.excelPosition}  Requirement: ${this.recuirementData}  Client: ${this.excelClient}`,

          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else if (
        this.posData != null &&
        this.clientData != null &&
        this.recuirementData.length == 0
      ) {
        // else if((recuirementId.length!=0||recuirementId!=null||recuirementId!=undefined)&&(cliId!=null)&&(posId==null))
        console.log('Position and client list');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            clientName:
              e.client != undefined ? e.client.clientName : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log(this.dataForExcel);
        }

        this.dataForExcel.map((res) => {
          console.log('check res', res);

          // res.forEach((d) => {
          //   console.log('check res2',d);

          // });
          console.log('recruitment::', res[0]);
          console.log('posistion::', res[1]);
          console.log('client::', res[4]);
          this.excelPosition = res[1];
          this.excelClient = res[4];
          this.recuirementData = res[0];

          console.log(this.excelPosition);
          console.log(this.excelClient);
        });
        let reportDatabyrecruiter = {
          title: 'Requirement List',
          //  exceldate: 'Position:'+this.excelPosition +"  " + 'Client:'+this.excelClient,
          exceldate: `Position: ${this.excelPosition} Client: ${this.excelClient}`,

          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else if (
        this.recuirementData?.length != 0 &&
        this.posData != null &&
        this.clientData == null
      ) {
        // else if((recuirementId.length!=0||recuirementId!=null||recuirementId!=undefined)&&(cliId!=null)&&(posId==null))
        console.log('Position and Recuriement list');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            clientName:
              e.client != undefined ? e.client.clientName : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log(this.dataForExcel);
        }
        this.dataForExcel.map((res) => {
          console.log('check res', res);

          // res.forEach((d) => {
          //   console.log('check res2',d);

          // });
          console.log('recruitment::', res[0]);
          console.log('posistion::', res[1]);
          console.log('client::', res[4]);
          this.excelPosition = res[1];
          this.excelClient = res[4];
          this.recuirementData = res[0];

          console.log(this.excelPosition);
          console.log(this.excelClient);
        });
        let reportDatabyrecruiter = {
          title: 'Requirement List',
          //  exceldate: 'Position:'+this.excelPosition +"  "+ 'Requirement:'+this. recuirementData,
          exceldate: `Position: ${this.excelPosition} Requirement: ${this.recuirementData} `,

          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else if (
        this.recuirementData?.length != 0 &&
        this.clientData != null &&
        this.posData == null
      ) {
        // else if((recuirementId.length!=0||recuirementId!=null||recuirementId!=undefined)&&(cliId!=null)&&(posId==null))
        console.log('recuirement and client list');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            clientName:
              e.client != undefined ? e.client.clientName : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log(this.dataForExcel);
        }
        this.dataForExcel.map((res) => {
          console.log('check res', res);

          // res.forEach((d) => {
          //   console.log('check res2',d);

          // });
          console.log('recruitment::', res[0]);
          console.log('posistion::', res[1]);
          console.log('client::', res[4]);
          this.excelPosition = res[1];
          this.excelClient = res[4];
          this.recuirementData = res[0];

          console.log(this.excelPosition);
          console.log(this.excelClient);
        });
        let reportDatabyrecruiter = {
          title: 'Requirement List',
          //  exceldate:  'Requirement:'+this. recuirementData+"  "+ 'Client:'+this.excelClient,

          exceldate: `  Client: ${this.excelClient}`,
          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else if (
        this.posData != null &&
        this.recuirementData?.length == 0 &&
        this.clientData == null
      ) {
        console.log('Position id list');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            clientName:
              e.client != undefined ? e.client.clientName : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log(this.dataForExcel);
        }
        this.dataForExcel.map((res) => {
          console.log('check res', res);

          // res.forEach((d) => {
          //   console.log('check res2',d);

          // });
          console.log('recruitment::', res[0]);
          console.log('posistion::', res[1]);
          console.log('client::', res[4]);
          this.excelPosition = res[1];
          this.excelClient = res[4];
          this.recuirementData = res[0];

          console.log(this.excelPosition);
          console.log(this.excelClient);
        });
        let reportDatabyrecruiter = {
          title: 'Requirement List',
          //  exceldate: 'Position:'+this.excelPosition,
          exceldate: `Position: ${this.excelPosition}  `,

          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else if (
        this.recuirementData?.length != 0 &&
        this.posData == null &&
        this.clientData == null
      ) {
        console.log('RecuirementId list');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            clientName:
              e.client != undefined ? e.client.clientName : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log(this.dataForExcel);
        }
        this.dataForExcel.map((res) => {
          console.log('check res', res);

          // res.forEach((d) => {
          //   console.log('check res2',d);

          // });
          console.log('recruitment::', res[0]);
          console.log('posistion::', res[1]);
          console.log('client::', res[4]);
          this.excelPosition = res[1];
          this.excelClient = res[4];
          this.recuirementData = res[0];

          console.log(this.excelPosition);
          console.log(this.excelClient);
        });
        let reportDatabyrecruiter = {
          title: 'Requirement List',
          // exceldate: 'Requirement:'+this. recuirementData,
          exceldate: `  Requirement: ${this.recuirementData}  `,

          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else if (
        this.clientData != null &&
        this.recuirementData?.length == 0 &&
        this.posData == null
      ) {
        // else
        console.log('Client id list');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            clientName:
              e.client != undefined ? e.client.clientName : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log(this.dataForExcel);
        }
        this.dataForExcel.map((res) => {
          console.log('check res', res);

          // res.forEach((d) => {
          //   console.log('check res2',d);

          // });
          console.log('recruitment::', res[0]);
          console.log('posistion::', res[1]);
          console.log('client::', res[4]);
          this.excelPosition = res[1];
          this.excelClient = res[4];
          this.recuirementData = res[0];

          console.log(this.excelPosition);
          console.log(this.excelClient);
        });
        this.dataForExcel.map((res) => {
          console.log('check res', res);
        });
        let reportDatabyrecruiter = {
          title: 'Requirement List',
          //  exceldate: 'Client:'+this.excelClient,
          exceldate: `  Client: ${this.excelClient}`,

          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else {
        console.log('default one');
        this.overAllResourceData = this.positionlistdata;
        console.log(this.overAllResourceData);
        for (let e of this.positionlistdata) {
          let data = {
            resourceNumber: e.requirementNo,
            PositionName: e.position.positionName,
            minExperience: e.minExperience,
            maxExperience: e.maxExperience,
            clientName:
              e.client != undefined ? e.client.clientName : 'In-House',
            maxCTC: e.ctc,
            // requirementStatus: e.requirementStatus,
            requirementStatus: e.requirementStatus ? 'Open' : 'Close',

            status: e.status.listTypeValueName,
          };
          this.dataForExcel.push(Object.values(data));
          console.log(this.dataForExcel);
        }
        let reportDatabyrecruiter = {
          title: 'Requirement List',
          //  exceldate: 'Position:'+this.excelPosition +"\n"+  'Requirement:'+this. recuirementData+"\n"+ 'Client:'+this.excelClient,
          data: this.dataForExcel,
          // employeeName :this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      }
      //
    }
  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }
  handleBackspace(event): void {
    const inputValue = event.target.value;

    if (event.key === 'Backspace' && inputValue.length <= 1) {
    }
  }

  toggleStatus(position: any) {
    console.log('position::', position);

    // category.status.listTypeValueName = category.status.listTypeValueName === 'Active' ? 'In-Active' : 'Active';

    if (position.status.listTypeValueName === 'Active') {
      console.log('if active');

      this.statuschangeactive(position.requirementId);
    } else {
      console.log('de active');

      this.statuschangeinactive(position.requirementId);
    }
  }
  toggleStatusopenclose(position: any) {
    console.log('position::', position);

    // category.status.listTypeValueName = category.status.listTypeValueName === 'Active' ? 'In-Active' : 'Active';

    if (position.requirementStatus === 'true') {
      console.log('if active');

      this.statuschangetrue(position.requirementId);
    } else {
      console.log('de active');

      this.statuschangefalse(position.requirementId);
    }
  }
  get positionName() {
    return this.positionSearchform.get('positionId');
  }
  get clientId() {
    return this.positionSearchform.get('clientId');
  }
  get getrequirementnumber() {
    return this.positionSearchform.get('requirementnumber');
  }

  handlePageChange(event) {
    this.p = event;
    localStorage.reqActivePage = JSON.stringify(this.p);
  }

  showChanges() {
    console.log('show Change');
    this.p = 1;
  }

  sortData(sort: Sort) {
    this.p = 1;

    console.log('sort::', sort);

    console.log('this.positionlistdata::', this.positionlistdata);

    const data = this.positionlistdata.slice();
    console.log('check this codition::', !sort.active || sort.direction === '');

    if (!sort.active || sort.direction === '') {
      this.positionlistdata = data;
      // this.checkNoData();
      return;
    }

    this.positionlistdata = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ReName':
          return this.compare(a.ReName, b.ReName, isAsc);
        case 'positionName':
          return this.compare(a.positionName, b.positionName, isAsc);
        case 'minExperience':
          return this.compare(a.minExperience, b.minExperience, isAsc);
        case 'maxExperience':
          return this.compare(a.maxExperience, b.maxExperience, isAsc);
        case 'clientName':
          return this.compare(a.clientName, b.clientName, isAsc);
        case 'ctc':
          return this.compare(a.ctc, b.ctc, isAsc);
        default:
          return 0;
      }
    });
    this.checkNoData(); 
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  checkNoData() {
    this.noDataFound = this.positionlistdata.length === 0;
  }
  clearforms() {
    // this.advanceType=undefined

    // this.positionlistdata.length = [];
    if (this.positionlistdata) {
      this.positionlistdata.length = 0; // or this.positionlistdata = [];
  } else {
      this.positionlistdata = []; // Initialize if it was undefined
  }
    this.positionSearchform.reset();
    // this.nodatafound=true
    // this.btnDisable=false
    // this.btnsDisable=false
    // this.loader=false
    this.btnDisables = false;
    this.nodata = true;
    this.tableShow=false
  }
}
