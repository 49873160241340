<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Organization Compliance</span>
                </div>
                <div class="sub-header-buttons">
                    <button mat-raised-button  class="searchBtn" (click)="createorgcomplliance()">Create Organization Compliance</button>
                    <!-- <a   routerLink="/home/addfinancialyear"   title="Add Financial Year" color="primary" mat-raised-button class="ml-2">
                        Add Financial Calendar
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                    <!-- <div class="row pb-3" *ngIf="!loader && resultData.length>5">
                        <div class="col-md-11 text-right">
                            <p class="show-count">Show</p>
                        </div>
                        <div class="col-md-1 text-left pl-0">
                            <select [(ngModel)]="pagePerItem" (change)="pageChages($event)" class="form-control report-filter">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="text-right" *ngIf="resultData?.length > 5 && !loader">
                        <div class="select-pagination m-2">
                          <span>Show : </span>
                          <select
                            class="select_list new-select_list listDesign"
                            [(ngModel)]="pagePerItem"
                            (change)="pageChages($event)"
                          >
                            <!-- <option class="form-control" value="5">5</option> -->
                            <option class="form-control" value="5">5</option>
                            <option class="form-control" value="10">10</option>
                            <option class="form-control" value="15">15</option>
                            <option class="form-control" value="20">20</option>
                            <option class="form-control" value="25">25</option>
            
                          </select>
                        </div>
                      </div>
                       
                    <div *ngIf="!loader"  class="table-responsive">
                        <!-- <p><b>EMPLOYEE WORKINGDAYS</b></p> -->
                        <table style="border-radius: 10px;" class="table  --table-bordered table-border-new">
                            <thead class="t-head">
                                <tr>
                                    <th>Action</th>
                                    <th>PF Salary Limit</th>
                                    <th>Employee PF</th>
                                    <th>Employer PF</th>
                                    <th>ESI Salary Limit</th>
                                    <th>Employee ESI</th>
                                    <th>Employer ESI</th>
                                    <!-- <th>Status</th> -->
                                </tr>
                            </thead>
                            <tbody *ngIf="resultData.length>0">
                                <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                    <td class="">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
        
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button mat-menu-item routerLink="/home/organizationcomplaice/{{row.organizationCompalianceId}}">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                             <!-- <button mat-menu-item (click)="viewdata(row)"  >
                                                <mat-icon>remove_red_eye</mat-icon>
                                                <span>View</span>
                                            </button> -->
        
                                            <!-- <button mat-menu-item *ngIf="row.organizationCompalianceStatus.listTypeValueId==1" (click)="updateStatus(row.organizationCompalianceId,2)">
                                                <mat-icon>done</mat-icon>
                                                <span>Inactive</span>
                                            </button>
        
                                            <button mat-menu-item *ngIf="row.organizationCompalianceStatus.listTypeValueId==2" (click)="updateStatus(row.organizationCompalianceId,1)">
                                                <mat-icon>done</mat-icon>
                                                <span>Active</span>
                                            </button>  -->
                                        </mat-menu>
                                    </td>
                                   
                                    <td style="text-align: center;">{{row.pfMaxSalaryAmount}}</td>
                                    <td style="text-align: center;">{{row.employeePfAmount}}</td>
                                    <td style="text-align: center;">{{row.employeePfAmount}}</td>
                                    <td style="text-align: center;" >{{row.esiMaxSalaryAmount}}</td>
                                    <td style="text-align: center;">{{row.employeeEsiAmount}}</td>
                                    <td style="text-align: center;">{{row.employerEsiAmount}}</td>
                                    <!-- <td style="text-align: center;">{{row.workingDays}}</td>
                                    <td style="text-align: center;" >{{row.otHours}}</td> -->
                                    <!-- <td class="active-status text-center">
                                        <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.organizationCompalianceStatus.listTypeValueId==1">
                                            {{row.organizationCompalianceStatus.listTypeValueName}}
                                        </span>
        
                                        <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.organizationCompalianceStatus.listTypeValueId==2">
                                            {{row.organizationCompalianceStatus.listTypeValueName}}
                                        </span>
                                    </td> -->
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td style="text-align: center;font-size: 21px;  font-weight: 770;" colspan="9" *ngIf="nodatafound">No Records Found!</td>
                                </tr> 
                            </tbody> 
                        </table>
                    </div>
                    
                     <div>
                        <div class="row">
                            <div class="col-md-4" *ngIf="!this.noRecordsFound && !loader">
                                <div class="showing-record">
                                    {{resultData.length}} rows
                                </div>
                            </div>
                            <div class="col-md-8 text-right" *ngIf="!loader && resultData.length>5">
                                <pagination-controls style="float:right" (pageChange)="p = $event" *ngIf="!this.noRecordsFound"></pagination-controls>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <!-- <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button> -->
</div>


<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>