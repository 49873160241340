import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';

@Component({
  selector: 'app-resource-detail-list',
  templateUrl: './resource-detail-list.component.html',
  styleUrls: ['./resource-detail-list.component.scss']
})
export class ResourceDetailListComponent implements OnInit {

  resourcedetaillistdata:any[];
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  usertype :any;
  userType:any;

  constructor(private router:Router,private resourceservice:RecruitmentService) { }

  ngOnInit(): void 
  {
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertype.userId;
    let organizationId = this.usertype.organization.organizationId;
    this.usertype = this.usertype.role.roleName;  

    this.resourceservice.resourcelist(organizationId).subscribe((resourcedetaillistdata:any) =>
    {
      this.resourcedetaillistdata = resourcedetaillistdata;
      console.log(this.resourcedetaillistdata);
    }
    )
  }

  Add_resource(){
    this.router.navigate(['home/resourcedetail']);
  }


  onKeyUpEvent(event)
  {
    if(this.query.length !=0)
    {
      console.log(this.query.length);
      this.p=1;
      console.log(this.p);
    }
    else
    {
      this.p= this.beforepage;
      console.log(this.p);
      console.log(this.beforepage);
    }
  }
}
