import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { DialogData } from '../employee/employee-list/employee-list.component';
import { DenyreasonComponent } from '../denyreason/denyreason.component';
import { LeaveService } from 'src/app/providers/leave.service';
import { DialogData } from '../myleavelist/myleavelist.component';
import { T } from '@angular/cdk/keycodes';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-manage-withdrawal',
  templateUrl: './manage-withdrawal.component.html',
  styleUrls: ['./manage-withdrawal.component.scss']
})
export class ManageWithdrawalComponent implements OnInit {

  list_count = 0
  data: any[] = []
  isLoading:Boolean;
  leaveData=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber','employeeName', 'startDate', "endDate",'reason','noOfLeaveDays', 'star',];
  // dataSource: any;
  personalEmail
  //  dataSource = ELEMENT_DATA;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  mail: any;
  empId: any;
  organizationId: any;
  approvallist:any=[];
  success: boolean = false;
  error: boolean = false;
  success_msg: string;
  error_msg: any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
list_counts=false;
  beforesearchpage: number;
  paginators: boolean = true;

  constructor(private gs: GlobalserviceService, 
    private router: Router, 
    public dialog: MatDialog,
    public leaveservice:LeaveService,
    private headerservice : HeaderService
  ) {
  }
  ngOnInit() 
  {
    // this.headerservice.setTitle('Withdrawal Request')
    this.headerservice.setTitle('');

    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let mail = x.organizationEmail;
    this.mail=mail;
    console.log(this.mail);
    this.empId = x.employee.employeeId;
    this.organizationId  = x.organization.organizationId;
    // this.leaveservice.getemp_withdraw_list(this.empId).subscribe((d: any) => {
    //   this.loader=false;
    //   if(d.length==0) {
    //       this.leaveData=true;
    //       console.log("NoData");
    //     }
    //   this.data = d;
    //   console.log(this.data);
    //   this.data.map(
    //     y => {
    //      console.log()
    //       if(y.leaveWithdraw.approvals.approver.fyi == this.empId ){
    //         this.leaveData = false;
    //         y['fyistatus'] = true;
    //         this.approvallist.push(y);   
    //            console.log("fyi");
    //       }
    //       console.log(y.leaveWithdraw.approvals.approver.approverOne ,this.empId,"0000000000000000000000")
    //       if (y.leaveWithdraw.approvals.approver.approverOne == this.empId  &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 3
    //         &&  y.leaveWithdraw.approvals.approver.approverOne) {
    //        console.log(y,"11111111111111111111")
    //         this.leaveData = false;
    //         this.approvallist.push(y);  
    //         console.log("approverOne");   
    //       }
    //       if (y.leaveWithdraw.approvals.approver.approverTwo == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
    //         &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 3) {
    //         this.leaveData = false;
    //         this.approvallist.push(y);
    //         console.log("approverTwo");  
    //       }
    //       if (y.leaveWithdraw.approvals.approver.approverThree == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
    //         &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 3) {
    //           this.leaveData = false;
    //         this.approvallist.push(y);
    //         console.log(this.approvallist);  
    //         console.log("approverThree");  
    //       }
    //       if (y.leaveWithdraw.approvals.approver.approverFour == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
    //         &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5
    //         &&  y.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 3) {
    //           this.leaveData = false;
    //         this.approvallist.push(y);
    //         console.log("approverFour");  
    //       }
    //       if ( y.leaveWithdraw.approvals.approver.approverFive == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
    //         &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5
    //         &&  y.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverFiveStatus.listTypeValueId == 3) {
    //           this.leaveData = false;
    //         this.approvallist.push(y);
    //         console.log("approverFive");  
    //       }
    //       this.approvallist = this.approvallist.reduce((accumalator, current) => {
    //         console.log(accumalator);
    //         console.log(current,'kkkkkkkkkkkkkkkkkkkkkk');
    //         if (!accumalator.some(item => item === current)) {
    //           console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj",current)
    //           accumalator.push(current);
    //         }
    //         return accumalator;
    //       }, []);
    //       console.log("this.approvallist",this.approvallist);  

    //       if(this.approvallist.length == 0){
    //         this.leaveData = true;
    //         console.log("NO_DATA");
    //       }
    //     }
    //   )

    //   this.dataSource = new MatTableDataSource<any>(this.approvallist);
    //   this.dataSource.filterPredicate = (data, filter) => {
    //     console.log(data,"wwwwwwwwwwwwwww")
    //     const dataStr = data.employee.employeeNumber+data.employee.firstName+data.employee.lastName + data.leaveWithdraw.startDate + data.leaveWithdraw.endDate
    //     +data.leaveWithdraw.withdrawDays+data.leaveWithdraw.withdrawReason;
    //     return dataStr.trim().toLowerCase().indexOf(filter) != -1;
    //   }
    //   this.dataSource.paginator = this.paginator;
    //   this.list_count = this.data.length
    // },
    // (error) =>{
    //   console.log("withdraw list error");
    //   if(error.error.statusCode==204){
    //     this.loader=false;
    //     this.leaveData=true;
    //     console.log("204");
    //   }
    // }
    // )
    this.fetchwithdrawn();
  }
 fetchwithdrawn(){
  this.loader=true;
  this.leaveservice.getemp_withdraw_list(this.empId).subscribe((d: any) => {
    this.loader=false;
    this.list_counts=true;
    this.approvallist=[];
    if(d.length==0) {
        this.leaveData=true;
        console.log("NoData");
      }
    this.data = d;
    console.log(this.data);
    this.data.map(
      y => {
       console.log()
        if(y.leaveWithdraw.approvals.approver.fyi == this.empId ){
          this.leaveData = false;
          y['fyistatus'] = true;
          this.approvallist.push(y);   
             console.log("fyi");
        }
        console.log(y.leaveWithdraw.approvals.approver.approverOne ,this.empId,"0000000000000000000000")
        if (y.leaveWithdraw.approvals.approver.approverOne == this.empId  &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 3
          &&  y.leaveWithdraw.approvals.approver.approverOne) {
         console.log(y,"11111111111111111111")
          this.leaveData = false;
          this.approvallist.push(y);  
          console.log("approverOne");   
        }
        if (y.leaveWithdraw.approvals.approver.approverTwo == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 3) {
          this.leaveData = false;
          this.approvallist.push(y);
          console.log("approverTwo");  
        }
        if (y.leaveWithdraw.approvals.approver.approverThree == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 3) {
            this.leaveData = false;
          this.approvallist.push(y);
          console.log(this.approvallist);  
          console.log("approverThree");  
        }
        if (y.leaveWithdraw.approvals.approver.approverFour == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5
          &&  y.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 3) {
            this.leaveData = false;
          this.approvallist.push(y);
          console.log("approverFour");  
        }
        if ( y.leaveWithdraw.approvals.approver.approverFive == this.empId &&  y.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          &&  y.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5
          &&  y.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 5 &&  y.leaveWithdraw.approvals.approverFiveStatus.listTypeValueId == 3) {
            this.leaveData = false;
          this.approvallist.push(y);
          console.log("approverFive");  
        }
        this.approvallist = this.approvallist.reduce((accumalator, current) => {
          console.log(accumalator);
          console.log(current,'kkkkkkkkkkkkkkkkkkkkkk');
          if (!accumalator.some(item => item === current)) {
            console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj",current)
            accumalator.push(current);
          }
          return accumalator;
        }, []);
        console.log("this.approvallist",this.approvallist);  

        if(this.approvallist.length == 0){
          this.leaveData = true;
          console.log("NO_DATA");
        }
      }
    )

    this.dataSource = new MatTableDataSource<any>(this.approvallist);
    this.dataSource.filterPredicate = (data, filter) => {
      console.log(data,"wwwwwwwwwwwwwww")
      const dataStr = data.employee.employeeNumber+data.employee.firstName+data.employee.lastName + data.leaveWithdraw.startDate + data.leaveWithdraw.endDate
      +data.leaveWithdraw.withdrawDays+data.leaveWithdraw.withdrawReason;
      return dataStr.trim().toLowerCase().indexOf(filter) != -1;
    }
    this.dataSource.paginator = this.paginator;
    this.list_count = this.approvallist.length
  },
  (error) =>{
    console.log("withdraw list error");
    if(error.error.statusCode==204){
      this.loader=false;
      this.leaveData=true;
      console.log("204");
    }
  }
  )
 }

 showChanges() {
  // Handle items per page change from select box
  this.pagePerItem = this.mySelect;
  this.p=1;
  console.log((this.p));
  console.log(this.mySelect );
  this.updateTableData();

}
onPageChange(event: any) {
  this.p = event; // Update current page on page change
  this.updateTableData();
}

updateTableData() {
  const start = (this.p - 1) * this.mySelect;
  const end = start + this.mySelect;
  this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.leaveData = true;
    }
    else{
      this.leaveData = false;
    }
  if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  Accept(i) 
  {
    console.log(i);
    // this.loader = true;
   
    Swal.fire({
      title: 'Are you sure? Do you want to Accept',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {

      if (result.value) {
        i['organization']={
          organizationId :this.organizationId
        }
        let count = 0
        if(i.leaveWithdraw.approvals.approver.approverOne){
          count = count+1
        }
        if(i.leaveWithdraw.approvals.approver.approverTwo){
          count = count+1
        }
        if(i.leaveWithdraw.approvals.approver.approverThree){
          count = count+1
        }
        if(i.leaveWithdraw.approvals.approver.approverFour){
          count = count+1
        }
        if(i.leaveWithdraw.approvals.approver.approverFive){
          count = count+1
        }
        console.log(i)
        console.log(i.leaveWithdraw.approvals.approver.approverOne == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 3,"111111111");
        console.log(i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 3,this.empId ,i.leaveWithdraw.approvals.approver.approverOne)
        if (i.leaveWithdraw.approvals.approver.approverOne == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 3) {
          console.log("{{{{{{{{{{{{{{{{{ The approverlevel 1111111111}}}}}}}}}}}}}}}}")

          i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId = 5
          i.leaveWithdraw.approvals.approverlevel = 1
          i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
          
          console.log(i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId);
          console.log(  i.withdrawStatus)
        }
    
        if (i.leaveWithdraw.approvals.approver.approverTwo == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 222222}}}}}}}}}}}}}}}}")

            i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId = 5
            i.leaveWithdraw.approvals.approverlevel = 2
            i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
            console.log(i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId);
        }
        if (i.leaveWithdraw.approvals.approver.approverThree == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 3}}}}}}}}}}}}}}}}")

            i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId = 5
            i.leaveWithdraw.approvals.approverlevel = 3
            i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
        }
        if (i.leaveWithdraw.approvals.approver.approverFour == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 4}}}}}}}}}}}}}}}}")

            i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId = 5
            i.leaveWithdraw.approvals.approverlevel = 4
            i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
        }
        if (i.leaveWithdraw.approvals.approver.approverFive == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverFiveStatus.listTypeValueId == 3) {
            console.log("{{{{{{{{{{{{{{{{{ The approverlevel 5}}}}}}}}}}}}}}}}")

            i.leaveWithdraw.approvals.approverFiveStatus.listTypeValueId = 5
            i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 5
            i.leaveWithdraw.approvals.approverlevel = 5
            i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
            i.withdrawStatus = 5
        }
    
        if(count == 1 && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5){
          console.log("{{{{{{{{{{{{{{{{{ The count 11111111111111111}}}}}}}}}}}}}}}}")

          i.withdrawStatus = 5
          i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 2 && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5){
                    console.log("{{{{{{{{{{{{{{{{{ The count 222222222222222222222222}}}}}}}}}}}}}}}}")

          i.withdrawStatus = 5
          i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 3 && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5){
            console.log("{{{{{{{{{{{{{{{{{ The count 333333333333333333}}}}}}}}}}}}}}}}")

            i.withdrawStatus = 5
            i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 4 && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 5){
            console.log("{{{{{{{{{{{{{{{{{ The count 4444444444444444444444}}}}}}}}}}}}}}}}")

            i.withdrawStatus = 5
            i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 5
        }
        if(count == 5 && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5
          && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverFiveStatus.listTypeValueId == 5){
            console.log("{{{{{{{{{{{{{{{{{ The count 555555555555555555}}}}}}}}}}}}}}}}")

            i.withdrawStatus = 5
            i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 5
        }
        console.log(i)
        let x = i.leaveWithdraw
        let withdrawStatus;
        let approvalsCount = 0;

if (i.leaveWithdraw.approvals?.approverOneStatus?.listTypeValueId === 5) approvalsCount++;
if (i.leaveWithdraw.approvals?.approverTwoStatus?.listTypeValueId === 5) approvalsCount++;
if (i.leaveWithdraw.approvals?.approverThreeStatus?.listTypeValueId === 5) approvalsCount++;
if (i.leaveWithdraw.approvals?.approverFourStatus?.listTypeValueId === 5) approvalsCount++;
if (i.leaveWithdraw.approvals?.approverFiveStatus?.listTypeValueId === 5) approvalsCount++;

if (approvalsCount === 1) {
  console.log("Exactly one approver has approved. Setting withdraw status to 7.");
  withdrawStatus = 7;
}else if(approvalsCount === 2){
  withdrawStatus = 7;

}else if(approvalsCount === 3){
  withdrawStatus = 7;
}else if(approvalsCount === 4){
  withdrawStatus = 7;
}else if (approvalsCount === 5) {
  console.log("All five approvers have approved. Setting withdraw status to 7.");
  withdrawStatus = 7;
} else {
  withdrawStatus = i.leaveWithdraw.withdrawStatus;
  console.log("Withdraw status:", withdrawStatus);
}

        // if (i.leaveWithdraw.approvals?.approverFiveStatus?.listTypeValueId === 5) {
        //   console.log("checking withdraw status")
        //   withdrawStatus = 7;
        // } else {
        //   withdrawStatus = i.leaveWithdraw.withdrawStatus;
        //   console.log("withdraw status",withdrawStatus)
        // }
        let data = {
          leaveWithdrawId: x.leaveWithdrawId,
          startDate: i.leaveWithdraw.startDate,
          endDate: i.leaveWithdraw.endDate,
          noOfDays:i.noOfLeaveDays,
          withdrawDays: i.leaveWithdraw.withdrawDays,
          halfday: i.halfday,
          withdrawStatus: withdrawStatus,
          approvals:i.leaveWithdraw.approvals,
          status: 1,
          withdrawReason: x.withdrawReason,
          employeeId: i.employee.employeeId,
          leaveRequest: {
            leaveRequestId: i.leaveRequestId,
            startDate: i.startDate,
            endDate: i.endDate,
            leaveType: i.leaveType,
            halfday: i.halfday,
            employeeId: i.employee.employeeId,
          }
        }
        console.log("data checking for withdrawals",data);
        data['employeeId']	=	i.employee.employeeId;
        // return
        this.loader = true
        this.leaveservice.withdrawAction(x.leaveWithdrawId, data).subscribe(
          d => {
            // Swal.fire({
            //   text: 'Accepted',
            //   icon: 'success'
            // });
            this.success = true;
            this.success_msg = 'Withdrawal Request Approved Successfully';
            this.loader = false;
            setTimeout(() => { this.success = false;
              // this.list_count=0;
              // this.approvallist=[];
              this.fetchwithdrawn();
            }, 3000);
          

            // window.location.reload();
     
          },
          err => {
            // Swal.fire({
            //   text: err.error.message
            // })
            // this.isLoading = false
            this.error = true;
            this.error_msg = err.error.message;
            this.loader = false;
            setTimeout(() => { this.error = false; }, 3000);
          }
        )
      }else{
        this.loader = false;

      }
      //
    })

  }
  reject(i) {
    console.log(i);
    // this.loader = true;
  
    console.log(i);
    Swal.fire({
      title: 'Are you sure? Do you want to Reject',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {

        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              i['organization']={
                organizationId :this.organizationId
              }
          
              if(i.leaveWithdraw.approvals.approver.approverOne == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 3){
                i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId = 6
                console.log("approverone status");
                console.log(i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId);
                i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 6
                i.leaveWithdraw.approvals.approverlevel = 1
                i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
              }
              if(i.leaveWithdraw.approvals.approver.approverTwo == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5){
                i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId = 6
                console.log("approverTwoStatus");
                console.log(i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId);
                i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 6
                i.leaveWithdraw.approvals.approverlevel = 2
                i.leaveWithdraw.approvals['employeeId'] =i.employee.employeeId;
              }
              if(i.leaveWithdraw.approvals.approver.approverThree == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5){
                i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId = 6
                console.log("approverThreeStatus");
                console.log(i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId);
                i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 6
                i.leaveWithdraw.approvals.approverlevel = 3
                i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
              }
              if(i.leaveWithdraw.approvals.approver.approverFour == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 
               && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5){
                i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId = 6
                console.log("approverFourStatus");
                console.log(i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId);
                i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 6
                i.leaveWithdraw.approvals.approverlevel = 4
                i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
              }
              if(i.leaveWithdraw.approvals.approver.approverFive == this.empId && i.leaveWithdraw.approvals.approverOneStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverTwoStatus.listTypeValueId == 5 
                && i.leaveWithdraw.approvals.approverThreeStatus.listTypeValueId == 5 && i.leaveWithdraw.approvals.approverFourStatus.listTypeValueId == 5){
                  i.leaveWithdraw.approvals.approverFiveStatus.listTypeValueId = 6
                  console.log("approverFiveStatus");
                  console.log(i.leaveWithdraw.approvals.approverFiveStatus.listTypeValueId);
                  i.leaveWithdraw.approvals.approvalStatus.listTypeValueId = 6
                  i.leaveWithdraw.approvals.approverlevel = 5
                  i.leaveWithdraw.approvals['employeeId'] = i.employee.employeeId;
                  // i.approvals['employeeId'] = i.employee.employeeId;
          
              }
              let x = i.leaveWithdraw
              let data = {
                leaveWithdrawId: x.leaveWithdrawId,
                startDate: i.leaveWithdraw.startDate,
                endDate: i.leaveWithdraw.endDate,
                noOfDays:i.noOfLeaveDays,
                withdrawDays: i.leaveWithdraw.withdrawDays,
                halfday: i.halfday,
                withdrawStatus:6,
                approvals:i.leaveWithdraw.approvals,
                withdrawReason: x.withdrawReason,
                status: 1,
                leavewithdrawdenyReason: result.denyReason,
                employeeId: i.employee.employeeId,
                leaveRequest: {
                  leaveRequestId: i.leaveRequestId,
                  startDate: i.startDate,
                  endDate: i.endDate,
                  leaveType: i.leaveType,
                  halfday: i.halfday,
                  employeeId: i.employee.employeeId,
                }
              }
              console.log(data);
              // return
              this.loader = true

              this.leaveservice.withdrawAction(x.leaveWithdrawId, data).subscribe(
                d => {
                  // Swal.fire({
                  //   text: 'Denied',
                  //   icon: 'success'
                  // });
                  this.success = true;
                  this.success_msg = 'Withdrawal Request Denied Successfully';
                  this.loader = false
                  setTimeout(() => { this.success = false;
              //       this.list_count=0;
              // this.approvallist=[];
              this.fetchwithdrawn();
                   }, 3000);
                 
                  // window.location.reload();
                  // this.isLoading = false
                  // this.afterRefresh();
                },
                err => {
                  // Swal.fire({
                  //   text: err.error.message,
                  //   icon:'error'
                  // })
                  // this.isLoading = false
                  this.error = true;
                  this.error_msg = err.error.message;
                  this.loader = false;
                  setTimeout(() => { this.error = false; }, 3000);  
                },
                // () => {
                //   setTimeout(() => {
                //     // window.location.reload()
                //   }, 1000);
                //   this.isLoading = false
                // }
              )
            }else{
              this.loader = false;
  
            }
          });
        }
      }
      //
    })
  }

  afterRefresh()
  {
    console.log("Referesh");
    this.leaveservice.getemp_withdraw_list(this.empId).subscribe((d: any) => {
      this.loader=false;
      if(d.length==0)
        {
          this.leaveData=true;
          console.log("NoData");
        }
      this.data = d;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.leaveWithdraw.startDate + data.leaveWithdraw.endDate
        +data.leaveWithdraw.withdrawDays;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length
    },
    (error) =>
    {
      console.log("withdraw list error");
      if(error.error.statusCode==204)
      {
        this.list_count=0;
        this.loader=false;
        this.leaveData=true;
        console.log("204");
      }
    }
    )
  }
  // dialog
  openDialog(i) {
    console.log(i)
    this.dialog.open(Reason, {
      data: i.leaveWithdraw.withdrawReason
    });
  }
  show_leave(i) {
    console.log(i)
    this.dialog.open(ShowLeave, {
      data: i
    });
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

@Component({
  selector: 'reason',
  templateUrl: 'reason.html',
})
export class Reason {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<Reason>) { }

  ngOnInit() {

  }
  closeLeaveDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'leavedenyreason',
  templateUrl: 'leavedenyreason.html',
})
export class leavedenyreason {
  Data: any = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<leavedenyreason>) { }

  ngOnInit() {
    this.Data = this.data;
    console.log(this.Data);
  }
  closeDenyDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'showleave',
  templateUrl: 'showleave.html',
})
export class ShowLeave implements OnInit {
  Data: any = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<ShowLeave>) { }

  ngOnInit() {
    this.Data = this.data;
    console.log(this.Data);
    if (this.Data.leaveWithdraw.withdrawStatus == 3) {
      this.Data.leaveWithdraw.withdrawStatus = 'Pending'
    }
  }
  test(i) {
    if (i.leaveWithdraw.withdrawStatus == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Approved') {
      return '#28a745'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Denied') {
      return '#f44336'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Withdrawn') {
      return '#20B2AA'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Cancelled') {
      return '#e40000'
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
 
}


