import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,AbstractControl, Validators, FormBuilder }from '@angular/forms';
import { RecruitmentService } from '../providers/recruitment.service';
import { PayrollService } from 'src/app/providers/payroll.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { SettingsService } from 'src/app/providers/settings.service';
import { responseMsgTimeOut}from '../providers/properties'
import { Router,ActivatedRoute} from '@angular/router';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { ShowErrorOfWorkingdaysComponent } from '../show-error-of-workingdays/show-error-of-workingdays.component';

@Component({
  selector: 'app-organizationworkingdays',
  templateUrl: './organizationworkingdays.component.html',
  styleUrls: ['./organizationworkingdays.component.scss']
})
export class OrganizationworkingdaysComponent implements OnInit {
  loader:any= false;
  isProcessing = false;
  isprocessing1=false;
  orgWorkingDaysForm:FormGroup;
  filterForm:FormGroup;
  clientdata: any={};
  beforesearchpage:any
  usertData:any;
  dateFormat: any;
  orgId:any;
  clientId:any;
  noRecordsFound: boolean =false;
  clientList:any;
  error:any=false;
  resultData : any = [];
  submitted: boolean = false;
  errormessagejson: any;
  success = false;
  sucess_msg:any;
  error_msg:any;
  p: number = 1;
  tableSearch : any;
  errorMsg : any;
  finYearList: any=[];
  pagePerItem:any=5;
  chooseFinId:any;
  finPeriodList: any=[];
  importerror: boolean = false;
  orgWorkingDaysId:number;
  filterData: any=[];
  chooseFinancialyearId: any;
  financialPeriodList: any[];
  perId: any;
  staus: any;
  showtable:boolean=false;

  constructor(
    private recruitmentservice :RecruitmentService,
    private fb:FormBuilder,
    private dialog:MatDialog,
    private router:Router,
    private settingsService: SettingsService,
    private payrollservice:PayrollService
  ) { }

  ngOnInit(): void 
  {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.noRecordsFound = true;
    let userId = this.usertData.userId;
    this.orgId = this.usertData?.organization?.organizationId;
    console.log(this.orgId);

    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);
    
    this.filterForm = this.fb.group({
      filterfinancialyear:[null],
      filterperiod:[null],
    })
    
    this.orgWorkingDaysForm = this.fb.group({
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      // workingdays:[null,[Validators.required,Validators.pattern(/^(0\.5|[1-9]\d{0,1}(\.\d)?)$/)]]
      workingdays:[null,[Validators.required,Validators.pattern(/^(0\.5|[1-9](\.0|\.5)?|[1-2][0-9](\.0|\.5)?|30(\.0)?)$/
)]]

    })
    
    
    // this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
    //   this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
    //   console.log(this.clientList);
    // })    
    
    if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }


  if(this.clientId){
    this.settingsService.getFinicalyearActiveListClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }

  }

  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  blockDrop(event: DragEvent) {
    event.preventDefault();
  }

  notZeroValidatorAsync(control: AbstractControl) 
  {
    const value = control.value;
    const regex =/^(?:\d\.\d{1,2}|\d{1})$/; 
    return new Promise((resolve) => {
      if (!regex.test(value.toString()) ) {
        console.log("Not Vaild");
        resolve({ notZero: true }); // Return an object with a custom error key if value is zero
      } else {
        console.log("Vaild");
        resolve(null); // Return null if the value is not zero, indicating the control is valid
      }
    });
  }
  onlyNumber(event) {
    console.log(event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  getorworkingdayslist()
  {
    this.loader=true;
    this.payrollservice.getorgWorkingDaysbyorganList(this.orgId).subscribe((filterData:any) =>
    {
      this.noRecordsFound = false;
      this.filterData = filterData;
      console.log(this.filterData);
      if(this.filterData.length == 0 || this.filterData.length == undefined)
      {
        this.noRecordsFound = true;
      }
      if(this.filterData.statusCode == 204){
        this.noRecordsFound = true;
      }
      this.loader=false;
    })
  }
 
  exportToExcel()
  {
    console.log("Export");
  }
  
  searchSubmit()
  {
    this.filterData=[];
    this.showtable=true;
    this.loader=true;
    this.isProcessing = true; 
    console.log("Submit",this.filterForm.value);
    if((this.filterForm.value.filterfinancialyear==="null"||this.filterForm.value.filterfinancialyear===null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null))
    {
     alert("Please Select Finanical year");
      this.filterData=[];
      this.noRecordsFound = false;
      // this.getorworkingdayslist();
      this.loader=false;
      this.isProcessing = false; 
      this.showtable=false;
     // return;
    }

    if((this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod==="null"||this.filterForm.value.filterperiod===null))
    {
      console.log("Finalcial Year only");


      if(this.orgId){
      this.payrollservice.getworkingdaysYearList(this.filterForm.value.filterfinancialyear,this.orgId).subscribe((filterData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.filterData = filterData;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;

        }
        this.loader=false;
        this.isProcessing = false; // Disable the button

      })
    }
    if(this.clientId){
      this.payrollservice.getClientworkingdaysYearList(this.filterForm.value.filterfinancialyear,this.clientId).subscribe((filterData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.filterData = filterData;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;

        }
        this.loader=false;
        this.isProcessing = false; // Disable the button

      })
    }
    }
    else if((this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))
    {
      console.log("Finalcial and Period");
      if(this.orgId){
      this.payrollservice.getorgFinanicalandPeriodList(this.filterForm.value.filterperiod,this.orgId).subscribe((filterData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;
        this.filterData = filterData;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;

        }
        this.loader=false;
        this.isProcessing = false; // Disable the button

      })
    }

    if(this.clientId){
      this.payrollservice.getClientFinanicalandPeriodList(this.filterForm.value.filterperiod,this.clientId).subscribe((filterData:any) =>
      {
        this.showtable=true;

        this.noRecordsFound = false;  
        this.filterData = filterData;
        console.log(this.filterData);
        if(this.filterData.length == 0 || this.filterData.length == undefined)
        {
          this.noRecordsFound = true;
        }
        if(this.filterData.statusCode == 204){
          this.noRecordsFound = true;

        }
        this.loader=false;
        this.isProcessing = false; // Disable the button

      })
    }
    }
   
  }
 
   selectFilterFinancialyear()
  {
    console.log("Select");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    this.filterForm.get('filterfinancialyear').value;
     this.chooseFinancialyearId= this.filterForm.get('filterfinancialyear').value;

    console.log(this.chooseFinancialyearId);
    if(this.chooseFinancialyearId == null || this.chooseFinancialyearId == 'null'){
      console.log('if');
      this.financialPeriodList = [];
    }else
    {
    this.settingsService.getFinancialYearId(this.chooseFinancialyearId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.financialPeriodList = resultData.finanicalPeriod;
      this.filterForm.patchValue({
        filterperiod: null,
      });
    })
  }
  }
   onSubmit()
   {
    this.isprocessing1 = true; // Disable the button

    this.submitted=true;
    if(this.orgWorkingDaysForm.invalid)
    {
      this.isprocessing1 = false; // Enable the button if the form is invalid

      console.log("form invaild");
      return;
    }
    console.log("submit",this.orgWorkingDaysForm.value);
    console.log(this.orgWorkingDaysId);
    this.loader=true;

    // if(this.orgWorkingDaysId)
    // {
    //   console.log("Update"+this.orgWorkingDaysId);
    //   const updateWorkingDays= 
    //   {
    //     orgWorkingDaysId:this.orgWorkingDaysId,
    //     workingDays:this.orgWorkingDaysForm.value.workingdays,
    //     financialPeriod:
    //     {
    //     financialPeriodId:this.orgWorkingDaysForm.value.period
    //     },
    //     organization:
    //     {
    //      organizationId:this.orgId
    //     },
    //    status:{
    //       listTypeValueId:this.staus
    //     }
    //   }
    //   console.log(updateWorkingDays);
    //   this. payrollservice.updateorgWorkingDays(this.orgWorkingDaysId,updateWorkingDays).subscribe((result: any) =>
    //     {
    //       if(result.statusCode == 200)
    //       {
    //         this.success = true;
    //         this.orgWorkingDaysId=0;
    //         console.log("this.orgWorkingDaysId"+this.orgWorkingDaysId);
    //         this.sucess_msg = result.description;
          

    //         // if((this.filterForm.value.filterclient!="null"||this.filterForm.value.filterclient!=null)||(this.filterForm.value.filterfinancialyear!="null"||(this.filterForm.value.filterfinancialyear!=null))||((this.filterForm.value.filterfinancialyear!="null")||(this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null))||
    //         //  ((this.filterForm.value.filterfinancialyear!="null"||this.filterForm.value.filterfinancialyear!=null)&&(this.filterForm.value.filterperiod!="null"||this.filterForm.value.filterperiod!=null)))
    //         // {
    //         //   console.log("Filter Call Method",this.filterForm.value);
    //         //   this.searchSubmit();
    //         // }
    //         if((this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null))
    //         {
    //           console.log("Without",this.filterForm.value);
    //           this.searchSubmit();
    //         }
    //         else{
    //           console.log("Filter Call Method",this.filterForm.value);
    //           this.searchSubmit();
    //         }
    //         setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
    //         // this.clientupdateId = null;
    //         this.orgWorkingDaysForm.reset();
    //         this.submitted = false;
    //         this.showtable=false;
    //         // setTimeout(() => {
    //         //   this.router.navigate(["home/employee-rating-list"])
    //         // }, redirectMsgTimeOut)
    //       }
    //       // else if(result.statusCode == 409)
    //       // {
    //       //   console.log("Already Exist");
    //       // this.importerror = true;
    //       // // this.error = true;
    //       // this.error_msg = result.description;
    //       // this.errormessagejson = result;
    //       // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
    //       // }
    //       else
    //       {
    //       console.log("err1",result.statusCode);
    //       this.error = true;
    //       this.importerror = true;
    //       this.errormessagejson = result;
    //       // console.log('this.errormessagejson',this.errormessagejson);
    //       //  this.error_msg = result.description;
    //       //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
    //       }
    //       this.loader=false;
    //       this.isprocessing1 = false; // Disable the button

    //     },
    //      err =>
    //         {
    //             console.log("code1",err.statusCode);
    //             setTimeout(() => {
    //               this.error = true;
    //               this.errorMsg = err.error.description;
    //             }, redirectMsgTimeOut);
    //             this.loader=false;
    //             this.isprocessing1 = false; // Disable the button

    //         })
    // }
    if (this.orgWorkingDaysId) {
      console.log("Update" + this.orgWorkingDaysId);
    
      let updateWorkingDays: any;
    
      if (this.orgId) {
        updateWorkingDays = {
          orgWorkingDaysId: this.orgWorkingDaysId,
          workingDays: this.orgWorkingDaysForm.value.workingdays,
          financialPeriod: {
            financialPeriodId: this.orgWorkingDaysForm.value.period
          },
          organization: {
            organizationId: this.orgId
          },
          status: {
            listTypeValueId: this.staus
          }
        };
      } else if (this.clientId) {
        updateWorkingDays = {
          orgWorkingDaysId: this.orgWorkingDaysId,
          workingDays: this.orgWorkingDaysForm.value.workingdays,
          financialPeriod: {
            financialPeriodId: this.orgWorkingDaysForm.value.period
          },
          client: {
            clientId: this.clientId
          },
          status: {
            listTypeValueId: this.staus
          }
        };
      }
    
      console.log(updateWorkingDays);
    
      this.payrollservice.updateorgWorkingDays(this.orgWorkingDaysId, updateWorkingDays).subscribe(
        (result: any) => {
          if (result.statusCode == 200) {
            this.success = true;
            this.orgWorkingDaysId = 0;
            console.log("this.orgWorkingDaysId" + this.orgWorkingDaysId);
            this.sucess_msg = result.description;
    
            if (
              this.filterForm.value.filterclient == null &&
              this.filterForm.value.filterfinancialyear == null &&
              this.filterForm.value.filterperiod == null
            ) {
              console.log("Without", this.filterForm.value);
              this.searchSubmit();
            } else {
              console.log("Filter Call Method", this.filterForm.value);
              this.searchSubmit();
            }
    
            setTimeout(() => {
              this.success = false;
            }, redirectMsgTimeOut);
    
            this.orgWorkingDaysForm.reset();
            this.submitted = false;
            this.showtable = false;
          } else {
            console.log("err1", result.statusCode);
            this.error = true;
            this.importerror = true;
            this.errormessagejson = result;
          }
          this.loader = false;
          this.isprocessing1 = false;
        },
        (err) => {
          console.log("code1", err.statusCode);
          setTimeout(() => {
            this.error = true;
            this.errorMsg = err.error.description;
          }, redirectMsgTimeOut);
          this.loader = false;
          this.isprocessing1 = false;
        }
      );
    }
    
    else
    {
      console.log("create");

      let createWorkingDays: any;

    //   const createWorkingDays= 
    //   {
    //     workingDays:this.orgWorkingDaysForm.value.workingdays,
    //     financialPeriod:
    //     {
    //         financialPeriodId:this.orgWorkingDaysForm.value.period
    //     },
    //     organization:
    //     {
    //      organizationId:this.orgId
    //     },
    // }

    if (this.orgId) {
      createWorkingDays = {
        workingDays: this.orgWorkingDaysForm.value.workingdays,
        financialPeriod: {
          financialPeriodId: this.orgWorkingDaysForm.value.period
        },
        organization: {
          organizationId: this.orgId
        }
      };
    } else if (this.clientId) {
      createWorkingDays = {
        workingDays: this.orgWorkingDaysForm.value.workingdays,
        financialPeriod: {
          financialPeriodId: this.orgWorkingDaysForm.value.period
        },
        client: {
          clientId: this.clientId
        }
      };
    }
    console.log(createWorkingDays);
    this.payrollservice.createorgWorkingDays(createWorkingDays).subscribe((result: any) =>
    {
      console.log("Create"+result);
     if(result.statusCode == 200)
     {
       this.success = true;
       this.sucess_msg = result.description; 
      //  if((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)||(this.filterForm.value.filterfinancialyear=="null"||(this.filterForm.value.filterfinancialyear==null))||((this.filterForm.value.filterfinancialyear=="null")||(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null))||
      //  ((this.filterForm.value.filterclient=="null"||this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear=="null"||this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod=="null"||this.filterForm.value.filterperiod==null)))
      //  {
      //    console.log("Filter Call Method",this.filterForm.value);
      //   //  this.searchSubmit();
      //  }
      if((this.filterForm.value.filterclient==null)&&(this.filterForm.value.filterfinancialyear==null)&&(this.filterForm.value.filterperiod==null))
      {
        console.log("Without",this.filterForm.value);
        // this.searchSubmit();
      }
      else{
        console.log("Filter Call Method",this.filterForm.value);
        this.searchSubmit();
      }
       setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
       this.orgWorkingDaysForm.reset();     
       this.submitted = false;
     }
    //  else if(result.statusCode == 409)
    //       {
    //         console.log("Already Exist");
    //         // this.error = true;
    //       this.importerror = true;
    //       this.error_msg = result.description;
    //    this.errormessagejson = result;
    //    setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         
    //       }
     else
     {
       console.log("err1",result.statusCode);
      //  console.log("err1",result.clientCode);
       this.error = true;
        this.importerror = true;
       this.errormessagejson = result;
      //  console.log('this.errormessagejson',this.errormessagejson);
      //  this.error_msg = result.description;
      //  setTimeout(() => {this.error = false}, redirectMsgTimeOut)
     }
     this.loader=false;
     this.isprocessing1 = false; // Disable the button

   }, 
   err =>
   {
     console.log("code",err);
     console.log("code",err.statusCode);
       setTimeout(() => {
         this.error = true;
         this.errorMsg = err.error.description;
       }, redirectMsgTimeOut) ;
       this.loader=false;
       this.isprocessing1 = false; // Disable the button

   })
      
    }
    
   }

   updateform(row)
   {
    // this.clearForm();
    console.log("clear Form",row);
    this.orgWorkingDaysId=row.orgWorkingDaysId;
    console.log(this.orgWorkingDaysId);
    this.payrollservice.getorgWorkingDaysList(this.orgWorkingDaysId).subscribe((resultData:any) =>
    {
      console.log("Api1",resultData);
      this.resultData=resultData;
      this.staus=resultData.status.listTypeValueId;
      console.log(this.staus);
      console.log(this.resultData);
      console.log(this.orgWorkingDaysForm);

             console.log("this.orgWorkingDaysForm 1", this.orgWorkingDaysForm.value);
             this.settingsService.getFinancialYearId(this.resultData.financialPeriod.finanicalYear.financialYearId).subscribe(
            (resultData1: any) => {
              console.log("resultData1", resultData1.finanicalPeriod);
              this.finPeriodList = resultData1.finanicalPeriod;
              console.log("resultData2", this.finPeriodList);
              this.perId=this.resultData.financialPeriod.financialPeriodId;
              console.log("perId", this.perId);
              // this.orgWorkingDaysForm.get('period').patchValue(this.resultData.financialPeriod.financialPeriodId); 
              console.log("patchvalue");
              setTimeout(() => {
                this.orgWorkingDaysForm.patchValue({
                  financialyear : this.resultData.financialPeriod.finanicalYear.financialYearId,
                  period : this.resultData.financialPeriod.financialPeriodId, 
                  workingdays : this.resultData.workingDays
                });
              },50);
              console.log("this.orgWorkingDaysForm 2", this.orgWorkingDaysForm.value);
            });
    })
   }

orgWorkingDaysIdage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

selectFinancialyear()
{
  console.log("Select indidual Employee");
  // console.log(event.target.value);
  this.orgWorkingDaysForm.get('financialyear').value;
  // console.log(this.filterForm.value.financialyear);
   this.chooseFinId=this.orgWorkingDaysForm.get('financialyear').value;
  console.log(this.chooseFinId);
  if(this.chooseFinId == null || this.chooseFinId == 'null'){
    console.log('if');
    this.finPeriodList = [];
  }
  else{
  this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => 
  {
    console.log("PeriodData", resultData)
    this.finPeriodList = resultData.finanicalPeriod;
    console.log("finPeriodList",  this.finPeriodList)
    this.orgWorkingDaysForm.patchValue({
      period: null,
    });
  })
  console.log("Inside selectFinancialyear func",this.orgWorkingDaysForm);
}
}

clearTable()
{
  // this.isProcessing=true;
  // this.resultData = [];
  // this.noRecordsFound = false;
  this.filterData=[];
  this.noRecordsFound = true;
  console.log('clear')
  // this.isProcessing=false;
this.showtable=false;
  this.filterForm.clearValidators;
  this.financialPeriodList=[];
}

clearForm() 
{
  this.orgWorkingDaysForm.reset(); // This resets all form controls to their initial values
}

pageChages()
{
    console.log("page changes");
    this.p=1;
}

get validate() 
{ 
  return this.orgWorkingDaysForm.controls; 
}
  
showerrormessage()
{
  console.log(this.errormessagejson);
  const dialogRef = this.dialog.open(ShowErrorOfWorkingdaysComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

 updateStatus(id,status)
	{
    console.log(id,status);
    this.payrollservice.deactiveWorkingDays(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.searchSubmit();
       // this.finicalyearList();
      }
      else
      {
        this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

}
