import { Component, Inject, OnInit } from '@angular/core';
import { PayrollService } from '../providers/payroll.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router ,ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { catchError, EMPTY, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { DatePipe } from '@angular/common';
import { SettingsService } from '../providers/settings.service';
@Component({
  selector: 'app-employee-payroll',
  templateUrl: './employee-payroll.component.html',
  styleUrls: ['./employee-payroll.component.scss']
})
export class EmployeePayrollComponent implements OnInit {
  isprocessing1=false;

  length: number;
  dataArray: any=[];
  financialYear: any;
  period: string;
  startIndex: number;
  employeelistsub: any;
 
  payrollForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: any;
  employee: any;
  employeeId: any;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=5;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any=[];
  chooseFinId:number;
  finPeriodList: any=[];
  empCode: boolean=false;
  empRatings:any=[];
  selectedEmployeeData: any = [];
  financialPeriodList: any = [];
  empratingId: number;
  employeetData: any;
  userdata: any;
  reportData: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  data: any[];
  // pagePerIist:any=10;
  private unsubscribe$: Subject<void> = new Subject<void>();
  allemployee: boolean = false;
  employementTypeLov: any;
  selectedId: boolean;
  inputValue: any;
  selectedemployeeId: any;
  selectedemployee: any;


  constructor(private fb:FormBuilder,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    private router:Router,
    private formBuilder: FormBuilder,
    private dialog:MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    @Inject(AppComponent) public AppData: any) { }
  

  ngOnInit(): void {
    this.getEmployementTypeLov(4);

    this.data = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`);
    this.updatePagedData();
    this.payrollForm = this.fb.group({
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      employeecode:[null,[Validators.required]],
      employmentType:['']
   
      // employeeId:['']
    })


    // this.payrollForm.get('employeecode').valueChanges.subscribe((value) => {
    //   console.log("employeecode");
    //   if (value && value.trim() !== '') {
    //     this.payrollForm.get('employmentType').disable();
    //   } else {
    //     this.payrollForm.get('employmentType').enable();
    //   }
    //   this.checkFormValidity();

    // });

    // this.payrollForm.get('employmentType').valueChanges.subscribe((value) => {
    //   console.log("employmentType");
    //   if (value) {
    //     this.payrollForm.get('employeecode').disable();
    //   } else {
    //     this.payrollForm.get('employeecode').enable();
    //   }
    //   this.checkFormValidity();

    // });
  
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.clientId = this.usertData?.employee?.client?.clientId;
    console.log(this.clientId);


if(this.orgId){
    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }

  if(this.clientId){
    this.settingsService.getFinicalyearActiveListClient(this.clientId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
  }

      
  }

  getEmployementTypeLov(listTypeValueId) {
    this.settingsService
      .getListTypeValues(listTypeValueId)
      .subscribe((resultData: any) => {
        this.employementTypeLov = resultData;
      });
  }

  // patchresource()
  // {
  //   this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
  //   let organizationId = this.employeetData.organization.organizationId;
  //   console.log('organizationId'+organizationId)
  //   console.log('patch',this.empratingId);
  //   if(this.empratingId)
  //   {
  //     this.payrollservice.getEmployeeRatingId(this.empratingId).subscribe((userdata:any) =>
  //     { 
  //       console.log('Ratingdata', userdata)
  //       this.userdata=userdata;
  //        console.log('this.userdata', this.userdata);
  //        this.financialYear=userdata.financialPeriod.finanicalYear.financialFromMonth+"-"+userdata.financialPeriod.finanicalYear.financialFromYear+" To "+
  //        userdata.financialPeriod.finanicalYear.financialToMonth+"-"+userdata.financialPeriod.finanicalYear.financialToYear;
  //       console.log(this.financialYear) ;
  //       this.period=userdata.financialPeriod.financialperiodMonth+"-"+userdata.financialPeriod.financialPeriodYear;
  //       console.log(this.period) ;
  //       this.payrollForm.patchValue({
  //         financialYear: this.financialYear, 
  //         period: this.period, 
  //       });

  //       var financialYear = this.userdata.financialPeriod.finanicalYear.financialFromMonth + "-" +
  //       this.userdata.financialPeriod.finanicalYear.financialFromYear + " to " + 
  //       this.userdata.financialPeriod.finanicalYear.financialToMonth + "-" +
  //       this.userdata.financialPeriod.finanicalYear.financialToYear;

  //      var financialPeriod = this.userdata.financialPeriod.financialperiodMonth + "-" +
  //       this.userdata.financialPeriod.financialPeriodYear;
  //       console.log("financialYear", this.payrollForm.get('financialYear'));
  //       console.log("financialPeriod", this.payrollForm.get('period'));
  //       this.payrollForm.get('financialYear').patchValue(this.userdata.financialPeriod.finanicalYear.financialYearId);
  //       this.payrollForm.get('period').patchValue(this.userdata.financialPeriod.financialPeriodId);  
  //       console.log("financialYear", this.payrollForm.get('financialYear'));
  //       console.log("financialPeriod", this.payrollForm.get('period'));
  //       // Adding a row with leaveDays
  //       this.selectedEmployeeData.push(this.userdata);

  //       console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
  //       const rowsArray = this.payrollForm.get('Ratings') as FormArray;
  //       rowsArray.push(this.formBuilder.group({
  //         employeeId: userdata.employee.employeeNumber,
  //         emprating: userdata.emprating, 
  //       }));
  //       this.employeeId = userdata.employee.employeeId;
  //     })
  //   }
  // }

  get validate() { return this.payrollForm.controls; }

 

  onSubmit()
  {

    if (this.payrollForm.value.employeecode !== undefined 
      && this.payrollForm.value.employeecode !== null 
      && this.payrollForm.value.employeecode !== '' 
      && this.payrollForm.value.employeecode !== 'All') {
    
    if ((this.employee != this.payrollForm.value.employeecode) || (this.employeeId == null)) {
      window.alert('Please select a valid employee from the dropdown.');
      return;
    }
  }

  // if((this.payrollForm.value.employeecode !== undefined && this.payrollForm.value.employeecode !== null && this.payrollForm.value.employeecode !== '') ){
  //   if((this.employee != this.payrollForm.value.employeecode ) || (this.employeeId == null)){
  //     window.alert('please select valid employee from dropdown.');
  //     return;
  //   }
  // }
   
    this.isprocessing1 = true; // Disable the button
    this.loader = true;

    console.log("submit");
    console.log("this.selectedEmployeeData", this.selectedEmployeeData);
    this.submitted=true;
    let valid = true;
          if(this.allemployee == false && this.selectedEmployeeData.length == 0)
          {
        
            valid = false;
          }
          console.log(valid);
    if (this.payrollForm.invalid  ||  !valid) {
      this.isprocessing1 = false;
      this.loader = false; 

      console.log("invalid")
      return;
    }
   
       if(this.payrollForm.value.employeecode == 'All'  ) 
       { console.log("All--");

            this.reportData=this.payrollForm.value;
            console.log("this.reportDate",this.reportData);
          
              
              const employeepayroll = {
                finanicalPeriod: 
                        { 
                          financialPeriodId: this.payrollForm.value.period
                        }
               
            };
                console.log(employeepayroll);
                  
                this.payrollservice.runpayrollbyemployeeAll(employeepayroll,this.orgId).subscribe((result: any) =>
            {
              if(result.statusCode == 200)
              {
                this.success = true;
                this.sucess_msg = result.description;      
                setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                this.payrollForm.reset();     
                this.submitted = false;
                // this.searchSubmit();
                setTimeout(() => {
                  this.router.navigate(["home/employee-payroll-list"])
                }, redirectMsgTimeOut)
              }
              else
              {
                console.log("err1",result.statusCode);
                this.error = true;
                this.error_msg = result.description;
                this.errormessagejson = result;
                console.log('this.errormessagejson',this.errormessagejson);
                this.importerror = true;
                setTimeout(() => {this.error = false}, 3000)
      
                // setTimeout(() => {
                // }, redirectMsgTimeOut)
              }
              this.isprocessing1=false;
              this.loader = false; // Hide loader

            }, err =>
            {
              this.error = true;
                  this.errorMsg = err.error.description;
              console.log("code",err);
              console.log("code",err.statusCode);
              this.loader = false;
              // if(err.error.statusCode == 409)
              // {
              //   console.log("code1",err.statusCode);
                setTimeout(() => {
               
                  this.error = false;
                  // this.errorMsg = err.error.description;
                }, 3000);
                this.isprocessing1=false; 
                this.loader = false; // Hide loader

              // }
              
            })
       }else if(this.selectedId){

         console.log("employeement type--");

            this.reportData=this.payrollForm.value;
            console.log("this.reportDate",this.reportData);
          
              
              const employeepayroll = {
                finanicalPeriod: 
                        { 
                          financialPeriodId: this.payrollForm.value.period
                        }
               
            };
                console.log(employeepayroll);


                if(this.orgId){
                this.payrollservice.runpayrollbyemployeeType(this.orgId,this.selectedId,employeepayroll).subscribe((result: any) =>
            {
              if(result.statusCode == 200)
              {
                this.success = true;
                this.sucess_msg = result.description;      
                setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                this.payrollForm.reset();     
                this.submitted = false;
                // this.searchSubmit();
                setTimeout(() => {
                  this.router.navigate(["home/employee-payroll-list"])
                }, redirectMsgTimeOut)
              }
              else
              {
                console.log("err1",result.statusCode);
                this.error = true;
                this.error_msg = result.description;
                this.errormessagejson = result;
                console.log('this.errormessagejson',this.errormessagejson);
                this.importerror = true;
                setTimeout(() => {this.error = false}, 3000)
      
                // setTimeout(() => {
                // }, redirectMsgTimeOut)
              }
              this.isprocessing1=false;
              this.loader = false; // Hide loader

            }, err =>
            {
              this.error = true;
                  this.errorMsg = err.error.description;
              console.log("code",err);
              console.log("code",err.statusCode);
              this.loader = false;
              // if(err.error.statusCode == 409)
              // {
              //   console.log("code1",err.statusCode);
                setTimeout(() => {
               
                  this.error = false;
                  // this.errorMsg = err.error.description;
                }, 3000);
                this.isprocessing1=false; 
                this.loader = false; // Hide loader

              // }
              
            })
          }

          if(this.clientId){
            this.payrollservice.runpayrollbyemployeeTypeClient(this.clientId,this.selectedId,employeepayroll).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;      
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            this.payrollForm.reset();     
            this.submitted = false;
            // this.searchSubmit();
            setTimeout(() => {
              this.router.navigate(["home/employee-payroll-list"])
            }, redirectMsgTimeOut)
          }
          else
          {
            console.log("err1",result.statusCode);
            this.error = true;
            this.error_msg = result.description;
            this.errormessagejson = result;
            console.log('this.errormessagejson',this.errormessagejson);
            this.importerror = true;
            setTimeout(() => {this.error = false}, 3000)
  
            // setTimeout(() => {
            // }, redirectMsgTimeOut)
          }
          this.isprocessing1=false;
          this.loader = false; // Hide loader

        }, err =>
        {
          this.error = true;
              this.errorMsg = err.error.description;
          console.log("code",err);
          console.log("code",err.statusCode);
          this.loader = false;
          // if(err.error.statusCode == 409)
          // {
          //   console.log("code1",err.statusCode);
            setTimeout(() => {
           
              this.error = false;
              // this.errorMsg = err.error.description;
            }, 3000);
            this.isprocessing1=false; 
            this.loader = false; // Hide loader

          // }
          
        })
      }
       }else{

     

        console.log("one--");  
        this.reportData=this.payrollForm.value;
        console.log("this.reportDate",this.reportData);
       
        if (!this.employeeId || this.employeeId.length === 0) {
          console.log("Employee ID is empty. API call will not be made.");
          this.loader=false;
          this.error = true;
          // this.error_msg = "No employee selected.";
          setTimeout(() => { this.error = false; }, 3000);
          return;
      }
          
          const employeepayroll = {
            finanicalPeriod: 
                     { 
                      financialPeriodId: this.payrollForm.value.period
                     },
            employee: 
            { 
              employeeId: this.employeeId
            }
        };
            console.log(employeepayroll);
               
            this.payrollservice.runpayrollbyemployee(employeepayroll).subscribe((result: any) =>
         {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;      
            setTimeout(() => {this.success  = false;},3000)
            this.payrollForm.reset();     
            this.submitted = false;
            // this.searchSubmit();
            setTimeout(() => {
              this.router.navigate(["home/employee-payroll-list"])
            }, redirectMsgTimeOut)
          }
          else
          {
            console.log("err1",result.statusCode);
            this.error = true;
            this.error_msg = result.description;
            this.errormessagejson = result;
            console.log('this.errormessagejson',this.errormessagejson);
            this.importerror = true;
            setTimeout(() => {this.error = false}, 3000)
            // setTimeout(() => {
            // }, redirectMsgTimeOut)
          }
          this.isprocessing1=false;
          this.loader = false; // Hide loader

  
        }, err =>
        {
          this.error = true;
              this.errorMsg = err.error.description;
          console.log("code",err);
          console.log("code",err.statusCode);
          this.loader = false;
          // if(err.error.statusCode == 409)
          // {
          //   console.log("code1",err.statusCode);
            setTimeout(() => {
              this.error = false;
              // this.errorMsg = err.error.description;
            }, 3000) ;
            this.isprocessing1=false;
            this.loader = false; // Hide loader

          // }
           
        })
      
          
       }

   
     
    
     
    
  }


  
  selectFinancialyear()
  {
    console.log("Select indidual Employee");
    // console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
    this.payrollForm.get('financialyear').value
     this.chooseFinId= this.payrollForm.get('financialyear').value

    console.log(this.chooseFinId);
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
    });
  }

//   empSearch(event)
// { 
//   this.empList=[];
//   this.empCode=false;
//   console.log(event.target.value);
//   this.userId=event.target.value;
//   console.log(this.userId);


//   if (!this.userId || this.userId === "") {
//     this.selectedEmployeeData=[];
//     console.log("User ID is invalid, skipping API call.");
//     this.loader = false;  
//     return;  
//   }
//   // Employee List Api
//     // this.empList.length == 0;
// 	  if(event.target.value.length >= 2)
//     {
//       if(this.employeelistsub)
//       {
//         this.employeelistsub.unsubscribe();
//       }
//       this.employeelistsub = this.payrollservice.getEmployeeNameList(this.userId,this.orgId)
//         .pipe(
//           takeUntil(this.unsubscribe$), // Cancel previous request
//           switchMap(() => this.payrollservice.getEmployeeNameList(this.userId,this.orgId))
//         )
//         .subscribe((result: any) => {
//           this.empList.length = 0;

//           if (result.statusCode) {
//             console.log(result.statusCode);
//             this.empList.length = 0;
//           } else {
//             this.empList = result;
//           }
//         });
// 	}
// }


empSearch(event){
  console.log(event.target.value)
  const inputElement = event.target as HTMLInputElement;
  console.log(inputElement) // Cast the event target as an input element
this. inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace
 console.log(this.inputValue)
       console.log("employeecode");
      if (event.target.value && event.target.value.trim() !== '') {
        this.payrollForm.get('employmentType').disable();
      } else {
        this.payrollForm.get('employmentType').enable();
      }
      this.checkFormValidity();

  // Clear the employee list and reset flags if input is empty
  if (!this.inputValue || this.inputValue === '') {
    this.empList = [];
    this.empCode = false;
    this.selectedEmployeeData=[];

    console.log('Input is empty, employee list cleared');
    return;
  }

  if (!this.inputValue || this.inputValue === "") {
        this.selectedEmployeeData=[];
        console.log("User ID is invalid, skipping API call.");
        this.loader = false;  
        return;  
      }

  // Check if input length is sufficient for the search
  if (this.inputValue.length >= 2) {
    // Unsubscribe from the previous API request if necessary
    if (this.employeelistsub) {
      this.employeelistsub.unsubscribe();
    }

    // Reset and create a new Subject for cancellation
    this.unsubscribe$ = new Subject<void>();

    if(this.orgId){
    this.employeelistsub = this.payrollservice.getEmployeeNameList(this.inputValue,this.orgId)
      .pipe(
        takeUntil(this.unsubscribe$), // Cancel previous request
        catchError((err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true; // Set the flag to indicate an error state
          }
          console.error('An error occurred:', err);
          return EMPTY; // Return EMPTY to propagate completion downstream
        })
      )
      .subscribe(
        (result: any) => {
          if (result.statusCode === 204) {
            console.log('No employees found (status code 204)');
            this.empList = [];
            this.empCode = true; // Indicate no results found
          } else if (result.statusCode) {
            console.log('Other status code:', result.statusCode);
            this.empList = [];
            this.empCode = true;
          } else {
            // Handle the case where result is an array or an object
            this.empList = Array.isArray(result) ? result : Object.values(result);
            this.empCode = false;

          }
        },
        (err) => {
          if (err.error.status === 500) {
            this.empList = [];
            this.empCode = true;
          }
          console.error('An error occurred:', err);
        }
      );
    }

    if(this.clientId){
      this.employeelistsub = this.payrollservice.getClientEmployeeNameList(this.inputValue,this.clientId)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          catchError((err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true; // Set the flag to indicate an error state
            }
            console.error('An error occurred:', err);
            return EMPTY; // Return EMPTY to propagate completion downstream
          })
        )
        .subscribe(
          (result: any) => {
            if (result.statusCode === 204) {
              console.log('No employees found (status code 204)');
              this.empList = [];
              this.empCode = true; // Indicate no results found
            } else if (result.statusCode) {
              console.log('Other status code:', result.statusCode);
              this.empList = [];
              this.empCode = true;
            } else {
              // Handle the case where result is an array or an object
              this.empList = Array.isArray(result) ? result : Object.values(result);
              this.empCode = false;
  
            }
          },
          (err) => {
            if (err.error.status === 500) {
              this.empList = [];
              this.empCode = true;
            }
            console.error('An error occurred:', err);
          }
        );
      }
  }
}


empSelect(emp) {
  // this.selectedEmployeeData=[];
  console.log('empSelect', emp);
  // this.empList=[];
  this.employeeId = emp.employeeId;
  this.firstname=emp.firstName;
  this.lastname=emp.lastName;
  this.empnumber=emp.employeeNumber;
  this.employee=emp.employeeNumber

  // if (this.employeeLeaveId) {
  //   this.payrollForm.patchValue({
  //     employee: this.employee
  //   });
  // } 
  // else {

    this.payrollForm.patchValue({
      employeecode: this.employee
    });
    console.log("select",this.selectedEmployeeData);
    let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
    if (!hasemployee) {
      
      this.selectedEmployeeData.push(emp);
     
    }

  // }

}
// empSelect(emp)
// {
//   console.log(emp);
//   this.employeeId=emp.employeeId;
//   this.employee=emp.employeeId;
//   console.log(this.employee);
//   this.payrollForm.patchValue({
//     employeecode: this.employee
//    });
// }
close()
{
  console.log("close");
  this.router.navigateByUrl('/home/employee-payroll-list');
}
pageChages()
  {
    console.log("page changes");
    this.p=1;
  }

empAllSelect()
{
  console.log('empAllSelect')
  // this.empList=[];
  // this.selectedEmployeeData=[];
  
  this.loader=true;
  if(this.payrollForm.value.employeecode == "All")
  {
      this.allemployee = true;
    console.log("select",this.selectedEmployeeData);

    if(this.orgId){
    this.payrollservice
    .getAllEmployeeByOrganization(this.orgId).subscribe((result: any) => {
      console.log(result);
      if(result.length==0)
      {
        console.log("noData");
        // this.empList.length = 0;
      }
      // this.empList=result;
      console.log("All",this.empList);
      result.forEach(element => {
        let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
        if (!hasemployee) {
          this.selectedEmployeeData.push(element);
          console.log('selectedEmployeeData', this.selectedEmployeeData);
         
        }
      });
      this.loader=false;
    })
  }

  if(this.clientId){
    this.payrollservice
    .getAllEmployeeByClient(this.clientId).subscribe((result: any) => {
      console.log(result);
      if(result.length==0)
      {
        console.log("noData");
        // this.empList.length = 0;
      }
      // this.empList=result;
      console.log("All",this.empList);
      result.forEach(element => {
        let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
        if (!hasemployee) {
          this.selectedEmployeeData.push(element);
          console.log('selectedEmployeeData', this.selectedEmployeeData);
         
        }
      });
      this.loader=false;
    })
  }

  }
}



showerrormessage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

// deleteemployee(index) 
//   {
//   console.log(index);
//   console.log(this.dataArray);
//   if (index != -1) {
//     this.dataArray.splice(index, 1);
//     console.log(this.dataArray);
//   }
//   }
  deleteemployee(index) {
    console.log(index + index);
    if (index != -1) {
     
       
          this.selectedEmployeeData.splice(index, 1);
          console.log(this.selectedEmployeeData.length);
        
     }
  }
  onPageChange(event: any): void {
    this.p = event;
    this.updatePagedData();
  }

  updatePagedData(): void {
    const startIndex = (this.p - 1) * this.pagePerItem;
    const endIndex = startIndex + this.pagePerItem;
    console.log(startIndex);
    this.startIndex=startIndex;
    console.log(this.startIndex);
    console.log(endIndex);
  }


  onEmploymentTypeChange(event){
    this.selectedId = event.value;
    console.log("employmentType");
    if (this.selectedId) {
      this.payrollForm.get('employeecode').disable();
    } else {
      this.payrollForm.get('employeecode').enable();
    }
    this.checkFormValidity();
console.log(this.selectedId);
if (!this.selectedId) {
  this.selectedEmployeeData=[];
  this.loader = false;  
  return;  
}
this.loader=true;

if(this.orgId){
this.payrollservice.getEmployementType(this.orgId,this.selectedId).subscribe((type:any)=>{
  console.log(type);
  this.loader=false;
  this.selectedEmployeeData=type;

  
})
}

if(this.clientId){
  this.payrollservice.getClientEmployementType(this.clientId,this.selectedId).subscribe((type:any)=>{
    console.log(type);
    this.loader=false;
    this.selectedEmployeeData=type;
  
    
  })
  }


  }
 

  checkFormValidity() {
    console.log("checkFormValidity");
    const employeecode = this.payrollForm.get('employeecode').value;
    const employmentType = this.payrollForm.get('employmentType').value;
  
    if (!employeecode && !employmentType) {
      this.isprocessing1 = true;
    } else {
      this.isprocessing1 = false;
    }
  }


  noEmployeeFound() {
    this.selectedemployeeId = undefined;
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.empnumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.empCode = false; 
  
  }
  preventSpaceBeforeInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
        event.preventDefault();
      }
    }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }
validateInputs(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value;

  // Remove leading spaces
  if (value.startsWith(' ')) {
    value = value.trimStart();
  }

  // Remove spaces in between
  value = value.replace(/\s+/g, '');

  input.value = value;
}
}
