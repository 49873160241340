<style>
  .input-width {
    width: 100%;
  }
</style>

<div class="row">
  <div class="col-sm-6">
    <h1 mat-dialog-title>{{ data.project.projectName }}</h1>
  </div>
  <!-- <div class="col-sm-5 float-right" align="end" >
     <button mat-stroked-button type="button" color="primary" 
     class="btn create-button" (click)="addTaskHours()" >Add Hours</button>
  </div> -->
  <div align="end" class="col-sm-6">
    <!-- <span class="material-icons canceldesign " style="color:red;" (click)="onClick()">cancel</span> -->
    <mat-icon
    class="material-icons"
    style="color: red !important; "
    mat-dialog-close
    color="custom-red"
    >clear</mat-icon
  >
  </div>
</div>

<form [formGroup]="taskForm">
  <div class="row">
    <div class="col-md-12">
      <div id="loginform">
          <label class="form-control-label">Select Task Group<span class="text-center text-danger">*</span></label>
          <mat-select
              class="form-control email-input select-placeholder"
              formControlName="taskGroup"
              placeholder="Select task group">
              <mat-option *ngFor="let taskgroup of taskgrouplist" [value]="taskgroup.taskGroupId">{{taskgroup.taskGroupName}}
              </mat-option>
          </mat-select>
          <div
          *ngIf="taskForm?.get('taskGroup').hasError('required') && submitted"  class="text-danger">
        <small class="text-danger">Task Group is required
        </small>           
        </div>
          <!-- <mat-error *ngIf="f.taskGroup && submitted">{{TaskGroup()}}</mat-error> -->
      </div>
    </div>
  </div>
  <div class="row">
    <ng-container *ngIf="this.commonTaskId else textfield">
      <div class="col-sm-12">
        <div class="input-width" id="loginform">
          <label class="form-control-label">
            <!-- <mat-icon matPrefix>layers</mat-icon>  -->
            Task Name<span class="text-danger">*</span>
          </label>
          <mat-select placeholder="Select TaskName" 
            formControlName="taskName"
            class="form-control email-input select-placeholder">
            <mat-option *ngFor="let task of taskList" 
              [value]="task.commontaskName" 
              (click)="change(task.commontaskId)">
              {{task.commontaskName}}
            </mat-option>
          </mat-select>
          <div
          *ngIf="
            taskName?.invalid  ||
            taskName?.dirty
          ">
        
          <small *ngIf="taskName.errors?.pattern" class="text-danger"
            >Please enter valid Task Name </small>
        </div>
        <!-- <div
          *ngIf="taskForm.get('taskName').hasError('required') && submitted"  class="text-danger">
    <small class="text-danger">Task Name is required
    </small>           
    </div> -->
    <div *ngIf="(submitted && taskName.invalid) || taskName.dirty">
      <small *ngIf="taskName.errors?.required" class="text-danger"
        >Task Name is required</small
      >
    </div>
          <!-- <mat-error *ngIf="f.taskName && submitted">{{taskName()}}</mat-error> -->
        </div>
      </div>
    </ng-container>
    <ng-template #textfield>
      <div class="full-width col-sm-12"  id="loginform">
        <label class="form-control-label">Task Name<span class="text-danger">*</span></label>
        <input matInput 
          type="text" 
          formControlName="taskName" 
          placeholder="Enter TaskName" 
          class="form-control email-input select-placeholder"/>
        <!-- <mat-error *ngIf="f.taskName && submitted">
          {{taskName()}}
        </mat-error> -->
        <div
        *ngIf="
          taskName?.invalid  ||
          taskName?.dirty
        ">
      
        <small *ngIf="taskName.errors?.pattern" class="text-danger"
          >Please enter valid Task Name </small>
      </div>
      <!-- <div
        *ngIf="taskForm.get('taskName').hasError('required') && submitted"  class="text-danger">
  <small class="text-danger">Task Name is required
  </small>           
  </div> -->
  <div *ngIf="(submitted && taskName.invalid) || taskName.dirty">
    <small *ngIf="taskName?.errors?.required" class="text-danger"
      >Task Name is required</small
    >
  </div>
      </div>
    </ng-template>
  </div>

  <div class="input-width" id="loginform_Comments">
    <!-- <label class="form-control-label">Task Detail Description<span class="text-danger">*</span></label>
    <textarea name="" 
      matInput id="" 
      formControlName="taskDescription"
      placeholder="Enter Task Detail Description"
      class="form-control email-input select-placeholder"  style=""></textarea> -->
        <!-- <label class="form-control-label">Task Detail Description<span class="text-danger">*</span></label>
          <textarea 
          style="font-size: 15px; overflow-x: auto; overflow-y: hidden; min-height: 43px; border: none; padding: 8px;"
          matInput
          class="form-control email-input select-placeholder" 
          formControlName="taskDescription"
          placeholder="Task Detail Description"></textarea> -->
          <label class="form-control-label">Task Detail Description<span class="text-danger">*</span></label>
          <textarea 
       matInput
          class="form-control email-input select-placeholder" 
         
          formControlName="taskDescription" rows="1" 
          style="font-size: 15px; overflow-x: auto; overflow-y: hidden; min-height: 43px; border: none; padding: 8px;"
          placeholder="Task Detail Description"></textarea>
    <!-- <mat-error *ngIf="f.taskDescription && submitted">{{taskDescription()}}</mat-error> -->
    <div
    *ngIf="
      taskDescription?.invalid  ||
      taskDescription?.dirty
    ">
  
    <small *ngIf="taskDescription?.errors?.pattern" class="text-danger"
      >Please enter valid Task Description </small>
  </div>
  <!-- <div
  *ngIf="taskForm.get('taskDescription').hasError('required') && submitted"  class="text-danger">
<small class="text-danger">Task Description is required
</small>           
</div> -->
<div *ngIf="(submitted && taskDescription.invalid) || taskDescription?.dirty">
  <small *ngIf="taskDescription?.errors?.required" class="text-danger"
    >Task Description is required</small>
</div>
  </div>

  <!-- Task Start Date -->
  <div class="row mt-3">
    <div class="col-sm-6 col-6 " id="loginform">
      <label class="form-control-label">
        Start Date<span class="text-danger">*</span>
      </label>
      <input matInput 
        [matDatepicker]="picker"  readonly
        (click)="picker.open()"
        [min]="this.min"
        [max]="this.max"
        placeholder="Select Start Date" 
        formControlName="taskStartDate"
        (dateChange)="someMethodName($event);  
    "
        class="form-control email-input select-placeholder">
      <mat-datepicker-toggle matSuffix [for]="picker"
      style="float: right;position: relative;bottom: 42px;">
      </mat-datepicker-toggle>
      <mat-datepicker #picker ></mat-datepicker>
     
      <!-- <mat-error *ngIf="f.taskStartDate && submitted">{{taskStartDate()}}</mat-error> -->
      <div *ngIf="taskForm?.controls.taskStartDate.errors?.required && (taskForm.controls.taskStartDate.touched || submitted)" class="text-danger">
        Start Date is Required
      </div>
    </div>
    

    <!-- Task End date -->
    <div class="col-sm-6 col-6 " id="loginform">
      <label class="form-control-label">
        End Date<span class="text-danger">*</span>
      </label>
      <input matInput 
        [matDatepicker]="p" 
        (click)="p.open()" 
        placeholder="Select End Date"
        formControlName="taskEndDate"  readonly
        [min]="taskForm?.controls['taskStartDate'].value"
        [max]="this.max"
        [disabled]="endDate_disable"
        class="form-control email-input select-placeholder">
      <mat-datepicker-toggle matSuffix [for]="p"
      style="float: right;position: relative;bottom: 42px;">
      </mat-datepicker-toggle>
      <mat-datepicker #p ></mat-datepicker>
      <!-- <mat-error *ngIf="f.taskEndDate && submitted">{{taskEndDate}}</mat-error> -->
      <div *ngIf="taskForm?.controls.taskEndDate.errors?.required && (taskForm.controls.taskEndDate.touched || submitted)" class="text-danger">
        End Date is Required
      </div>
    </div>
    
  </div>

  <div class="row">
    <div class="col-6 col-sm-6"  id="loginform">
      <label class="form-control-label">Enter Hours<span class="text-danger">*</span></label>
      <input matInput 
        type="text" 
        formControlName="hours" 
        maxlength="3" 
        (keypress)="noNumber($event)"
        placeholder="Enter Hours" 
        readonly
        class="form-control email-input select-placeholder"/>
      <mat-error *ngIf="f.hours && submitted">
        {{hours()}}
      </mat-error>
    </div>

    <div class="col-6 col-sm-6"  id="loginform">
      <label class="form-control-label">Enter Minutes<span class="text-danger">*</span></label>
      <input matInput type="text" 
        formControlName="minutes" 
        maxlength="2" 
        readonly
        placeholder="Enter Minutes"
        (keypress)="noNumber($event)" 
        class="form-control email-input select-placeholder"/>
      <mat-error *ngIf="f.minutes && submitted">
        {{minutes()}}
      </mat-error>
    </div>
  </div>

  <div formArrayName="taskHours">
    <div *ngFor="let task of totalHours.controls; let i = index" [formGroupName]="i"
      style="padding:20px;margin-top:10px">
      <div class="row">
        <div class="col-sm-6"><b>Working Hours : {{i+1}}</b></div>
        <div class="col-sm-6" align="end">
          <button mat-stroked-button type="button" color="warn" class="btn cancel-button"
          [disabled]="totalHours.controls[i].get('taskhoursId')"
          (click)="deleteTaskHours(i)">Delete</button>
        </div>
      </div>
      <!-- [readonly]="totalHours.controls[i].get('taskhoursId')" -->
      <div class="row">
        <div class="full-width col-sm-6"  id="loginform">
          <label class="form-control-label">Enter Extra Hours<span class="text-danger">*</span></label>
          <input matInput 
            type="text" 
            formControlName="extrahours" 
            maxlength="2" 
            (keypress)="noNumber($event)"
            placeholder="Enter Hours" 
    
            class="form-control email-input select-placeholder"/>
          <mat-error *ngIf="totalHours.controls[i].get('extrahours').errors?.required && submitted">
            Extra Hours is required
          </mat-error>
        </div>
        <!-- [readonly]="totalHours.controls[i].get('taskhoursId')" -->
        <div class="full-width col-sm-6"  id="loginform">
          <label class="form-control-label">Enter Extra Minutes<span class="text-danger">*</span></label>
          <input matInput 
            type="text" 
            formControlName="extraminutes"
            maxlength="2" 
            placeholder="Enter Minutes" 
        
            (keypress)="noNumber($event)" 
            class="form-control email-input select-placeholder"/>
          <mat-error *ngIf="totalHours.controls[i].get('extraminutes').errors?.required && submitted">
            Extra Minutes is required
          </mat-error>
        </div>
      </div>
      <!-- [readonly]="totalHours.controls[i].get('taskhoursId')" -->
      <div class="input-width"  id="loginform">
        <label class="form-control-label">Reason<span class="text-danger">*</span></label>
        <textarea name="" 
          matInput id="" 
          formControlName="reason"
     
          placeholder="Enter Reason"
          class="form-control email-input select-placeholder"></textarea>
        <mat-error *ngIf="totalHours.controls[i].get('reason').errors?.required && submitted">
          Reason is required
        </mat-error>
      </div>
    </div>
  </div>



  <mat-dialog-actions align="end">
    <button    class="searchBtnTime" style="margin-bottom: 15px;

" type="submit"  mat-raised-button [disabled]="taskForm.invalid || !disableButton"  (click)="onSubmit()">
      Save
    </button>
  </mat-dialog-actions>
</form>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong><br>{{sucess_msg}}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br>{{errorMsg}}
  <button *ngIf="importerror" (click)="showerrormessage()"   class="btn btn-danger">view report recoeds
  </button>
</div>


<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border" style="width: 3rem; height: 3rem"></div>
</div>
