<div class="row" style="display: flex; flex-wrap: inherit">
  <div class="col-md-11">
    <h4 class="heading">{{ data.projectName }}</h4>
  </div>
  <div align="end" class="col-md-1">
    <span
      class="material-icons"
      style="color: red; right: 15px; position: relative"
      mat-dialog-close
      >cancel</span
    >
  </div>
</div>
<form [formGroup]="employeeForm" (ngSubmit)="save()">
  <div mat-dialog-content>
    <div class="full-width"  id="loginform">
      <label class="form-control-label">Employee Name</label>
      <mat-select
      [disabled]="data.empprojectId"
        formControlName="employee"
        placeholder="Select Employee Name"
        #select
        multiple
        class="form-control email-input select-placeholder"
      >
        <div class="select-all">
          <mat-checkbox
            [(ngModel)]="allSelected"
            [ngModelOptions]="{ standalone: true }"
            (change)="toggleAllSelection()"
            [disabled]="data.empprojectId"
            >Select All</mat-checkbox
          >
        </div>
        <mat-option
          *ngFor="let emp of employeeList"
          (click)="individualCheckboxChanged()"
          [value]="emp.employeeId"
          (click)="employeeSelected(emp.employeeId)"
        >
          {{ emp.firstName }}
        </mat-option>
        <button
          mat-raised-button
          color="primary"
          style="margin: 0 18px 18px 18px"
          (click)="add()"
          class="btn"
        >
          Add
        </button>
        <button
          mat-raised-button
      
          (click)="cancel()"
          style="color:var(--Red-Color, #FF5D5D);height: 34px;"
      
        >
          Cancel
        </button>

       
      </mat-select>
      <mat-error *ngIf="f.employee.hasError('required') && submitted">
        Please select Employee Name
      </mat-error>
    </div>

    <div class="full-width" *ngIf="empData" id="loginform">
      <label class="form-control-label">Select Employee</label>
      <mat-select
      formControlName="employee"
      placeholder="Select Employee Name"
      #select
      [multiple]="!data.empprojectId"
      class="form-control email-input select-placeholder"
  >
      <div *ngIf="!data.empprojectId" class="select-all">
          <mat-checkbox
              [(ngModel)]="allSelected"
              [ngModelOptions]="{ standalone: true }"
              (change)="toggleAllSelection()"
          >Select All</mat-checkbox>
      </div>
      <mat-option
          *ngFor="let emp of employeeList"
          [value]="emp.employeeId"
          (click)="employeeSelected(emp.employeeId)"
      >
          {{ emp.firstName }}
      </mat-option>
  </mat-select>
  <button
      mat-raised-button
      color="primary"
      style="margin: 0 18px 18px 18px"
      (click)="add()"
      class="btn"
  >
      Add
  </button>
  <button
      mat-raised-button
      (click)="cancel()"
      style="color: var(--Red-Color, #ff5d5d); height: 34px"
  >
      Cancel
  </button>
  
      <mat-error *ngIf="f.employee.hasError('required') && submitted">
        Please select Employee Name
      </mat-error>
    </div>

    <div class="full-width" id="loginform">
      <label class="form-control-label">Role Name</label>
      <mat-select
        formControlName="rolename"
        placeholder="Role Name"
        class="form-control email-input select-placeholder"
      >
        <mat-option
          *ngFor="let emp of roleList"
          [value]="emp.employeeProjectRoleId"
        >
          {{ emp.rolename }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.rolename.hasError('required') && submitted">
        Please select Role Name
      </mat-error>
    </div>
  </div>

  <!-- [disabled]="submitted && !employeeForm.valid" -->
  <div align="end" >
    <button
      class="searchBtn mb-2"
      mat-raised-button
   
      type="submit"
      [disabled]=" employeeForm.invalid || !assignEmployeeButton"
   
    >
      <!-- {{ this.data.empprojectId ? "Update" : "Assign" }} -->
      Save
    </button>
  </div>
</form>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <!-- <button
      *ngIf="importerror"
      (click)="showerrormessage()"
      class="btn btn-danger"
    >
      View Error Records
    </button> -->
</div>
