import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from '../providers/payroll.service';
import { Router  , ActivatedRoute} from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-organization-compliance',
  templateUrl: './organization-compliance.component.html',
  styleUrls: ['./organization-compliance.component.scss']
})
export class OrganizationComplianceComponent implements OnInit {
  isprocessing1=false;
  loader:any= false;

  orgcomplianceForm:FormGroup;
  clientList: any;
  usertData: any;
  orgId: any;
  clientId:any;
  submitted: boolean = false;
  showctcerror: boolean;
  deletedrows: any = [];
  elementlist:any = [];
  filterele: any;
  clientElementId: any;
  livingcity: any;
  empannexue: any = {};
  employeeId: any;
  toppingList: string[] ;
  selectedOption: string | null = null;
  refreancelements: any = [];
  touchedRows: any;
  success: boolean;
  sucess_msg: any;
  error: boolean;
  error_msg: any;
  organizationcomplaice: any;
  orgcomplianceid: any;
  resultData: any;
  nodatafound: boolean;

  constructor(private fb:FormBuilder,
    public payrollservice:PayrollService , private router : Router, private activeRoute:ActivatedRoute) { }

    ngOnInit(): void {
      this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
      this.orgId = this.usertData.organization.organizationId;
      this.clientId = this.usertData?.employee?.client?.clientId;


      console.log(this.activeRoute.snapshot.params.organizationcomplaiceId);
      this.orgcomplianceid = this.activeRoute.snapshot.params.organizationcomplaiceId

      
      this.orgcomplianceForm = this.fb.group({
        PfMaxSalaryAmount:[null,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
        EmployeePF:[null,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
        EmployerPF:[null,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
        ESIMaxSalaryAmount:[null,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
        EmployeeESI:[null,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
        EmployerESI:[null,[Validators.required,Validators.pattern(/^(?:\d{1,8}(?:\.\d{1,2})?|\d{1,5})$/)]],
        EmployeeESIDeduct:[null,[Validators.required]],
        EmployerESIDeduct:[null,[Validators.required]],
       
      })
  

       if( this.orgcomplianceid ){
        this.payrollservice.getorgcompbyId(this.orgcomplianceid).subscribe((data:any) =>
        {
           this.resultData = data;
           
              this.orgcomplianceForm.patchValue({
                PfMaxSalaryAmount:this.resultData.pfMaxSalaryAmount,
                    EmployeePF:this.resultData.employeePfAmount,
                    EmployerPF : this.resultData.employerPfAmount,
                    ESIMaxSalaryAmount:this.resultData.esiMaxSalaryAmount,
                    EmployeeESI:this.resultData.employeeEsiAmount,
                    EmployerESI:this.resultData.employerEsiAmount,
                    EmployeeESIDeduct : this.resultData.employeeEsiDeduct,
                    EmployerESIDeduct : this.resultData.employerEsiDeduct,
              })

            
        });
       }
  

    }


    onSubmit()
    {
      this.isprocessing1=true;
      this.loader=true;
      this.submitted = true;
      if (this.orgcomplianceForm.invalid) {
        this.isprocessing1=false;
        console.log("invalid");
        this.loader=false;
        return;
      }
  
      //  if(!this.orgcomplianceid)
      //  {
      //         let organizationcomplaice = {
      //           organization: {
      //             organizationId: this.orgId
      //           },
      //           pfMaxSalaryAmount: this.orgcomplianceForm.value.PfMaxSalaryAmount,
      //           employeePfAmount: this.orgcomplianceForm.value.EmployeePF,
      //           employerPfAmount: this.orgcomplianceForm.value.EmployerPF,
      //           esiMaxSalaryAmount: this.orgcomplianceForm.value.ESIMaxSalaryAmount,
      //           employeeEsiAmount: this.orgcomplianceForm.value.EmployeeESI,
      //           employerEsiAmount: this.orgcomplianceForm.value.EmployerESI,
      //           employerEsiDeduct : this.orgcomplianceForm.value.EmployerESIDeduct,
      //           employeeEsiDeduct : this.orgcomplianceForm.value.EmployeeESIDeduct
      //   }
      //   console.log(organizationcomplaice);
      //   //  return
      //   this.payrollservice.createOrganizationcomplaice(organizationcomplaice).subscribe(
      //     (result: any) => {
      //       this.loader=false;
      //       if (result.statusCode == 200) {
      //         this.success = true;
      //         this.sucess_msg = result.description;
      //         // this.searchSubmit();
      //         setTimeout(() => { this.success = false; },redirectMsgTimeOut)
      //         // this.clientupdateId = null;
            
      //         this.submitted = false;
      //         setTimeout(() => {
      //           this.router.navigate(["home/organizationcomplaice-list"])
      //         }, redirectMsgTimeOut)
      //       }
      //       else {
      //         // this.error = true;
      //         // this.error_msg = result.description;
      //         // this.errormessagejson = result;
      //         // setTimeout(() => {this.error = false},redirectMsgTimeOut)
      //         console.log("err1", result.statusCode);
      //         this.error = true;
      //         this.error_msg = result.description;
            
      //       }
      //       this.isprocessing1=false;
      //     },
      //       err => {
      //         // console.log("code",err);
      //         // console.log("code",err.statusCode);
      //         // if(err.error.statusCode == 409)
      //         // {
      //         console.log("code1", err.statusCode);
      //         setTimeout(() => {
      //           this.error = true;
      //           this.error_msg = err.error.description;
      //         }, redirectMsgTimeOut);
      //         this.isprocessing1=false;

      //         // }
  
      //       })
          
      //  }else{
      //               let organizationcomplaice = {
      //                 organizationCompalianceId : this.orgcomplianceid,
      //                 organization: {
      //                   organizationId: this.orgId
      //                 },
      //                 pfMaxSalaryAmount: this.orgcomplianceForm.value.PfMaxSalaryAmount,
      //                 employeePfAmount: this.orgcomplianceForm.value.EmployeePF,
      //                 employerPfAmount: this.orgcomplianceForm.value.EmployerPF,
      //                 esiMaxSalaryAmount: this.orgcomplianceForm.value.ESIMaxSalaryAmount,
      //                 employeeEsiAmount: this.orgcomplianceForm.value.EmployeeESI,
      //                 employerEsiAmount: this.orgcomplianceForm.value.EmployerESI,
      //                 employerEsiDeduct : this.orgcomplianceForm.value.EmployerESIDeduct,
      //                 employeeEsiDeduct : this.orgcomplianceForm.value.EmployeeESIDeduct
      //         }
  
                     
      //            console.log(organizationcomplaice);
      //         // return
      //         this.payrollservice.updateOrganizationcomplaice(organizationcomplaice , this.orgcomplianceid).subscribe(
      //           (result: any) => {
      //             this.loader=false;
      //             if (result.statusCode == 200) {
      //               this.success = true;
      //               this.sucess_msg = result.description;
      //               // this.searchSubmit();
      //               setTimeout(() => { this.success = false; },redirectMsgTimeOut)
      //               // this.clientupdateId = null;
                  
      //               this.submitted = false;
      //               setTimeout(() => {
      //                 this.router.navigate(["home/organizationcomplaice-list"])
      //               }, redirectMsgTimeOut)
      //             }
      //             else {
      //               // this.error = true;
      //               // this.error_msg = result.description;
      //               // this.errormessagejson = result;
      //               // setTimeout(() => {this.error = false},redirectMsgTimeOut)
      //               console.log("err1", result.statusCode);
      //               this.error = true;
      //               this.error_msg = result.description;
                  
      //             }
      //             this.isprocessing1=false;

      //           },
      //             err => {
      //               // console.log("code",err);
      //               // console.log("code",err.statusCode);
      //               // if(err.error.statusCode == 409)
      //               // {
      //               console.log("code1", err.statusCode);
      //               setTimeout(() => {
      //                 this.error = true;
      //                 this.error_msg = err.error.description;
      //               }, redirectMsgTimeOut);
      //               this.isprocessing1=false;

      //               // }
  
      //             })
      //  }
      
      if (!this.orgcomplianceid) {
        
        let organizationcomplaice = {
          ...(this.orgId ? { organization: { organizationId: this.orgId } } : {}),
          ...(this.clientId ? { client: { clientId: this.clientId } } : {}),
          pfMaxSalaryAmount: this.orgcomplianceForm.value.PfMaxSalaryAmount,
          employeePfAmount: this.orgcomplianceForm.value.EmployeePF,
          employerPfAmount: this.orgcomplianceForm.value.EmployerPF,
          esiMaxSalaryAmount: this.orgcomplianceForm.value.ESIMaxSalaryAmount,
          employeeEsiAmount: this.orgcomplianceForm.value.EmployeeESI,
          employerEsiAmount: this.orgcomplianceForm.value.EmployerESI,
          employerEsiDeduct: this.orgcomplianceForm.value.EmployerESIDeduct,
          employeeEsiDeduct: this.orgcomplianceForm.value.EmployeeESIDeduct
        };
      
        console.log(organizationcomplaice);
      
        this.payrollservice.createOrganizationcomplaice(organizationcomplaice).subscribe(
          (result: any) => {
            this.loader = false;
            if (result.statusCode === 200) {
              this.success = true;
              this.sucess_msg = result.description;
              setTimeout(() => {
                this.success = false;
              }, redirectMsgTimeOut);
      
              this.submitted = false;
              setTimeout(() => {
                this.router.navigate(["home/organizationcomplaice-list"]);
              }, redirectMsgTimeOut);
            } else {
              console.log("err1", result.statusCode);
              this.error = true;
              this.error_msg = result.description;
            }
            this.isprocessing1 = false;
          },
          err => {
            console.log("code1", err.statusCode);
            setTimeout(() => {
              this.error = true;
              this.error_msg = err.error.description;
            }, redirectMsgTimeOut);
            this.isprocessing1 = false;
          }
        );
      } else {
        
        let organizationcomplaice = {
          organizationCompalianceId: this.orgcomplianceid,
          ...(this.orgId ? { organization: { organizationId: this.orgId } } : {}),
          ...(this.clientId ? { client: { clientId: this.clientId } } : {}),
          pfMaxSalaryAmount: this.orgcomplianceForm.value.PfMaxSalaryAmount,
          employeePfAmount: this.orgcomplianceForm.value.EmployeePF,
          employerPfAmount: this.orgcomplianceForm.value.EmployerPF,
          esiMaxSalaryAmount: this.orgcomplianceForm.value.ESIMaxSalaryAmount,
          employeeEsiAmount: this.orgcomplianceForm.value.EmployeeESI,
          employerEsiAmount: this.orgcomplianceForm.value.EmployerESI,
          employerEsiDeduct: this.orgcomplianceForm.value.EmployerESIDeduct,
          employeeEsiDeduct: this.orgcomplianceForm.value.EmployeeESIDeduct
        };
      
        console.log(organizationcomplaice);
      
        this.payrollservice.updateOrganizationcomplaice(organizationcomplaice, this.orgcomplianceid).subscribe(
          (result: any) => {
            this.loader = false;
            if (result.statusCode === 200) {
              this.success = true;
              this.sucess_msg = result.description;
              setTimeout(() => {
                this.success = false;
              }, redirectMsgTimeOut);
      
              this.submitted = false;
              setTimeout(() => {
                this.router.navigate(["home/organizationcomplaice-list"]);
              }, redirectMsgTimeOut);
            } else {
              console.log("err1", result.statusCode);
              this.error = true;
              this.error_msg = result.description;
            }
            this.isprocessing1 = false;
          },
          err => {
            console.log("code1", err.statusCode);
            setTimeout(() => {
              this.error = true;
              this.error_msg = err.error.description;
            }, redirectMsgTimeOut);
            this.isprocessing1 = false;
          }
        );
      }
      
        
    }

    onlyNumber(event) {
      console.log(event);
      console.log("only number" + event.charCode);
      const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
    blockPaste(event: ClipboardEvent) {
      event.preventDefault();
    }
  
    blockDrop(event: DragEvent) {
      event.preventDefault();
    }

    get validate() { return this.orgcomplianceForm.controls; }
}
