<section>
    <div class="sub-header">
      <div class="col-md-12 mb-3">
        <div class="row">
          <div class="">
            <span class="h5 sub-content">{{ supplierID ? "Edit" : "Add" }}  Supplier </span>
          </div>
          <div class="sub-header-buttons">
            <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="common-content">
    <div class="card-new">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="suppliergroup" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Supplier Name <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  placeholder="Supplier Name"
                  formControlName="supplierName"
                  autocomplete="off"
                  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.supplierName.invalid &&
                      validate.supplierName.touched) ||
                    validate.supplierName.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.supplierName.errors?.required"
                    class="text-danger"
                    >Please enter Supplier name</span
                  >
                  <span
                    *ngIf="validate.supplierName.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Supplier name</span
                  >
                 
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Supplier Code <span class="star">*</span></label
                >
                <input
                  type="text"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  placeholder="Supplier Code"
                  formControlName="supplierCode"
                  autocomplete="off"
                  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.supplierCode.invalid &&
                      validate.supplierCode.touched) ||
                    validate.supplierCode.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.supplierCode.errors?.required"
                    class="text-danger"
                    >Please enter Supplier Code</span
                  >
                  <span
                    *ngIf="validate.supplierCode.errors?.pattern"
                    class="text-danger"
                    >Please enter valid Supplier Code</span
                  >
                 
                </div>
              </div>
             
              <div class="form-group col-md-3">
                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                          <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                              search
                          </mat-icon> -->
                <label class="form-control-label"
                  >Country<span class="star"> *</span></label
                >
                <input
                  type="text"
                  placeholder=" Search Country"
                  formControlName="country"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  [matAutocomplete]="autocountry"
                  class="form-control email-input select-placeholder"
                  (keyup)="countrySearch($event)"
                />
                <mat-autocomplete #autocountry="matAutocomplete">
                  <mat-option
                    *ngFor="let country of countrylist"
                    [value]="country.countryId"
                    (click)="countrySelect(country)"
                  >
                    {{ country.countryName }}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon
                  class="material-symbols-outlined"
                  style="position: absolute; top: 39px; right: 20px"
                >
                  search
                </mat-icon>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.country.invalid && validate.country.touched) ||
                    validate.country.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.country.errors?.required"
                    class="text-danger"
                    >Please select country</span
                  >
                  <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                </div>
              </div>
              <div class="form-group col-md-3">
                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                          <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                              search
                          </mat-icon> -->
                <label class="form-control-label"
                  >State</label
                >
                <input
                  type="text"
                  placeholder=" Search State"
                  formControlName="state"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  [matAutocomplete]="stateauto"
                  class="form-control email-input select-placeholder"
                  (keyup)="stateSearch($event)"
                />
                <mat-autocomplete #stateauto="matAutocomplete">
                  <mat-option
                    *ngFor="let state of statelist"
                    [value]="state.stateId"
                    (click)="stateSelect(state)"
                  >
                    {{ state.stateName }}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon
                  class="material-symbols-outlined"
                  style="position: absolute; top: 39px; right: 20px"
                >
                  search
                </mat-icon>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.state.invalid && validate.state.touched) ||
                    validate.state.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.state.errors?.required"
                    class="text-danger"
                    >Please select state</span
                  >
                  <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                </div>
              </div>
              <div class="form-group col-md-3">
                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                          <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                              search
                          </mat-icon> -->
                <label class="form-control-label"
                  >District</label
                >
                <input
                  type="text"
                  placeholder=" Search District"
                  formControlName="district"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  [matAutocomplete]="districtauto"
                  class="form-control email-input select-placeholder"
                  (keyup)="districtSearch($event)"
                />
                <mat-autocomplete #districtauto="matAutocomplete">
                  <mat-option
                    *ngFor="let district of disrictList"
                    [value]="district.districtId"
                    (click)="districtSelect(district)"
                  >
                    {{ district.districtName }}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon
                  class="material-symbols-outlined"
                  style="position: absolute; top: 39px; right: 20px"
                >
                  search
                </mat-icon>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.district.invalid &&
                      validate.district.touched) ||
                    validate.district.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.district.errors?.required"
                    class="text-danger"
                    >Please select district</span
                  >
                  <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                </div>
              </div>
              <div class="form-group col-md-3">
                <!-- <input type="text" formControlName="employee" id="searchInput" list="empList" class="form-select form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                          <mat-icon class="material-symbols-outlined" style="position:absolute;top:14px;right: 19px;">
                              search
                          </mat-icon> -->
                <label class="form-control-label"
                  >City</label
                >
                <input
                  type="text"
                  placeholder=" Search City"
                  formControlName="city"
                  [matAutocomplete]="cityauto"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  class="form-control email-input select-placeholder"
                  (keyup)="citySearch($event)"
                />
                <mat-autocomplete #cityauto="matAutocomplete">
                  <mat-option
                    *ngFor="let city of cityList"
                    [value]="city.cityId"
                    (click)="citySelect(city)"
           
                  >
                    {{ city.cityName }}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon
                  class="material-symbols-outlined"
                  style="position: absolute; top: 39px; right: 20px"
                >
                  search
                </mat-icon>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.city.invalid && validate.city.touched) ||
                    validate.city.dirty ||
                    submitted
                  "
                >
                  <span
                    *ngIf="validate.city.errors?.required"
                    class="text-danger"
                    >Please select city</span
                  >
                  <!-- <span *ngIf="validate.branch.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
                </div>
              </div>
              <div class="form-group col-md-3">
                <label class="form-control-label"
                  >Postal Code</label
                >
                <input
                  type="text"
                  formControlName="postalCode"
                  maxlength="6"
                  (keyup)="validateInputSpace($event)"
                  (keypress)="noNumber($event)"
                  (keydown)="validateInputSpace($event)"
                  (paste)="blockPaste($event)"
                  (drop)="blockDrop($event)"
                  id="permenantPostalCode"
                  [ngClass]="{
                    'is-invalid': validate && validate.postalCode.errors
                  }"
                  autocomplete="off"
                  class="form-control email-input select-placeholder"
                  value="null"
                  placeholder="Postal Code"
                />
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.postalCode.invalid &&
                      validate.postalCode.touched) ||
                    validate.postalCode.dirty ||
                    submitted
                  "
                >
                  <span
                    class="text-danger"
                    *ngIf="validate.postalCode.errors?.required"
                    >Please enter  postal code</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="validate.postalCode.errors?.pattern"
                    >Please enter  valid postal code</span
                  >
                </div>
              </div>
             <div class="form-group col-md-3">
              <label class="form-control-label"
                >AddressLine 1<span class="star"> *</span></label
              >
              <textarea
                formControlName="addressLine1"
                id="addressLine1"
                rows="3"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                class="form-control email-input select-placeholder"
                placeholder="Enter Address"
                autocomplete="off"
              ></textarea>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.addressLine1.invalid &&
                    validate.addressLine1.touched) ||
                  validate.addressLine1.dirty||submitted
                "
              >
                <span
                  *ngIf="validate.addressLine1.errors?.required"
                  class="text-danger"
                  >Please enter AddressLine 1</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="form-control-label"
                >Address Line 2</label
              >
              <textarea
                formControlName="addressLine2"
                id="addressLine2"
                (keyup)="validateInputSpace($event)"
                (keydown)="validateInputSpace($event)"
                rows="3"
                class="form-control email-input select-placeholder"
                placeholder="Enter Address"
                autocomplete="off"
              ></textarea>
              <div
                class="valid-msg"
                *ngIf="
                  (validate.addressLine2.invalid &&
                    validate.addressLine2.touched) ||
                  validate.addressLine2.dirty
                "
              >
                <span
                  *ngIf="validate.addressLine2.errors?.required"
                  class="text-danger"
                  >Please enter AddressLine 2</span
                >
              </div>
            </div>
            <div class="form-group col-md-3">
                <label class="form-control-label">Description</label>
                <textarea
                  formControlName="description"
                  (keyup)="validateInputSpace($event)"
                  (keydown)="validateInputSpace($event)"
                  id="description"
                  rows="3"
                  class="form-control email-input select-placeholder"
                  placeholder="Enter Description"
                  autocomplete="off"
                ></textarea>
                <div
                  class="valid-msg"
                  *ngIf="
                    (validate.description.invalid &&
                      validate.description.touched) ||
                    validate.description.dirty
                  "
                >
                  <span
                    *ngIf="validate.description.errors?.pattern"
                    class="text-danger"
                    >Please enter Description</span
                  >
                </div>
              </div>
            <!-- </div> -->
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                  <button routerLinkActive="active" mat-raised-button style="color:red" class="mr-2" (click)="clearForm()" [disabled]="btnDisabled">
                      Cancel
                  </button>
                  <button type="submit" mat-raised-button class="btndesign" [disabled]="btnDisabled">Save</button>
              </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loader" class="align-items-cEnter text-cEnter">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; top: 51%"
      role="status"
    ></div>
  </div>
  
  <div class="text-cEnter" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_msg }}
  </div>