import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PayrollService } from 'src/app/providers/payroll.service';
import { ActivatedRoute, Router } from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-add-element-percentage',
  templateUrl: './add-element-percentage.component.html',
  styleUrls: ['./add-element-percentage.component.scss']
})
export class AddElementPercentageComponent implements OnInit {
  elementform: FormGroup;
  sucess_msg: any;
  error_msg: any;
  error: any = false;
  success: any = false;
  loader: any = false;
  backdrop: any = false;
  elementPercentageId: any;
  submitted: boolean = false;
  categoryTypeData: any;
  livingCityData: any;
  elementNameData: any;
  usertype: any;
  update_data_error_msg: any;
  userdata: any = [];
  showCategoryType: boolean = false;

  showLivingCity: boolean = false;
  categoryId: any;
  eleName: any;
  employeetData: any;
  organizationId: any;

  constructor(public dialog: MatDialog,
    private router: Router,
    private payrollService: PayrollService,
    private ActivatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.organizationId = organizationId;
    this.listCategoryType();
    this.patchresource();
    this.listLivingCity();

    if (this.ActivatedRoute.snapshot.params.elementPercentageId) {
      console.log('elementId present')

      this.elementform = new FormGroup(
        {
          categoryType: new FormControl(this.elementform, [Validators.required]),
          elementName: new FormControl(this.elementform, [Validators.required]),
          percentage: new FormControl(this.elementform, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
          livingCity: new FormControl(this.elementform),
        }
      );

    }
    else {
      console.log('elementId not present')
      this.elementform = new FormGroup(
        {
          categoryType: new FormControl(this.elementform, [Validators.required]),
          elementName: new FormControl(this.elementform, [Validators.required]),
          percentage: new FormControl(this.elementform, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
          livingCity: new FormControl(this.elementform),
        }
      );
    }
  }


  patchresource() {
    console.log('patch')
    if (this.ActivatedRoute.snapshot.params.elementPercentageId) {
      this.elementPercentageId = this.ActivatedRoute.snapshot.params.elementPercentageId;

      this.payrollService.elementpercentagebyid(this.ActivatedRoute.snapshot.params.elementPercentageId).subscribe((userdata: any) => {
        console.log('userdata', userdata)
        this.userdata = userdata;
        console.log('this.userdata', this.userdata)

        this.elementform.patchValue({

          elementPercentageId: this.userdata.elementPercentageId,
          percentage: this.userdata.elementPercentage,
          elementName:this.userdata.hrmspaySlipElement.elementId
        });
        console.log(" this.elementform", this.elementform);

        this.elementform.get('categoryType').patchValue(this.userdata.hrmspaySlipElement.categoryType.listTypeValueId);
        this.elementform.get('elementName').patchValue(this.userdata.hrmspaySlipElement.elementId);

        console.log("this.userdata.livingCityType ",this.userdata.livingCityType);
       if(this.userdata.livingCityType != undefined){
        this.elementform.get('livingCity').patchValue(this.userdata.livingCityType.listTypeValueId);
       }

       this.payrollService.listByCategoryId(this.elementform.value.categoryType, this.organizationId).subscribe((result: any) => {
        this.elementNameData = result;
        console.log(' this.elementNameData', this.elementNameData);
        // for(let key in this.elementNameData){
        //   if(key == )
        // }
        var eleName = this.elementform.value.elementName;
        this.payrollService.elementbyid(eleName).subscribe((userdata: any) => {
          if (userdata.elementName.toLowerCase() === "hra".toLowerCase()) {
            console.log(" if hra")
            this.showLivingCity = true;
            this.listLivingCity();
          } else {
            this.showLivingCity = false;
          }
        })
       

     
       
      })
    
        
        if (this.elementform.invalid) {
          const controls = this.elementform.controls;
          for (const name in controls) {
            if (controls[name].invalid) {
              console.log(name)
            }
          }
        }
      })
    }
  }

  listTypeValueid(event) {
    console.log('event', event.value)
    this.listElementByCategoryId(event);
  }

  listTypeValueidElementName(event) {
    console.log("element name dropdown", event.value)
   this.payrollService.elementbyid(event.value).subscribe((userdata: any) => {
    console.log('userdata.elementName', userdata.elementName)
    this.eleName = userdata.elementName;
    console.log(" userdata.elementName", userdata.elementName)
    if (this.eleName.toLowerCase() === "hra".toLowerCase()) {
      console.log(" if hra")
      this.showLivingCity = true;
      this.listLivingCity();
    } else {
      
      this.showLivingCity = false;
    }
  })
   
  }

  listTypeValueidLivingCity(event){

  }

  submitData() {
    console.log('submit')
    this.submitted = true;

    this.error = false;
    this.success = false;

    // this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    // console.log('userdata', this.usertype)
    // let employeeId = this.usertype.userId;
    // let organizationId = this.usertype.organization.organizationId;

    if (this.elementPercentageId) {
      var elementupdate;
      if(this.elementform.value.livingCity != null){
        elementupdate =
        {
          elementPercentageId: this.elementPercentageId,
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: { listTypeValueId: this.elementform.value.livingCity },
          percentageStatus: this.userdata.percentageStatus
        };
      }else{
        elementupdate =
        {
          elementPercentageId: this.elementPercentageId,
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: null,
          percentageStatus: this.userdata.percentageStatus,
        };
      }
     
      console.log('elementupdate', elementupdate)
      this.payrollService.elementpercantageupdate(elementupdate, this.elementPercentageId).subscribe((data: any) => {
        if (data.statusCode == 200) {
          this.success = true;
          this.sucess_msg = data.description;

          setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
          this.router.navigate(['/home/element-percentage']);
        }
        else {
          this.error = true;
          this.error_msg = data.description;
          setTimeout(() => { this.error = false }, redirectMsgTimeOut)
        }
      },
        (err) => {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
          this.update_data_error_msg = err.error.message;
          setTimeout(() => { this.error = false }, 3000)
        })
    }
    else //Create
    {
      var elementdata;

      console.log('this.elementform.value.livingCity', this.elementform.value.livingCity)
      if(this.elementform.value.livingCity != null){
        console.log('if', this.elementform.value.livingCity)
        elementdata =
        {
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: { listTypeValueId: this.elementform.value.livingCity },
        };
      }else{
        console.log('if', this.elementform.value.livingCity)
        elementdata =
        {
          elementPercentage: this.elementform.value.percentage,
          hrmspaySlipElement: { elementId: this.elementform.value.elementName },
          livingCityType: null,
        };
      }
     

      console.log('elementdata', elementdata)
      this.backdrop = true;
      this.loader = true;
      this.payrollService.elementpercantagecreate(elementdata).subscribe((data: any) => {
        if (data.statusCode == 200) {
          console.log('data.statusCode if success', data.statusCode)
          this.success = true;
          this.sucess_msg = data.description;
          setTimeout(() => { this.success = false; }, redirectMsgTimeOut)
          this.router.navigate(['/home/element-percentage']);
        }
        else {
          console.log('data.statusCode if failure', data.statusCode)
          this.error = true;
          this.error_msg = data.description;
          console.log(this.error_msg)
          setTimeout(() => { this.error = false }, redirectMsgTimeOut)
        }
      },
        (err) => {
          this.backdrop = false;
          this.loader = false;
          this.error = true;
          this.error_msg = err.error.message;

          setTimeout(() => { this.error = false }, 3000)
        });
    }
  }

  formcancel() {
    this.router.navigate(['home/element-percentage']);
  }
  listCategoryType() {
    this.payrollService.listCategoryType(this.organizationId).subscribe((result: any) => {
      this.categoryTypeData = result;
    })
  }
  listElementByCategoryId(event) {
    this.payrollService.listByCategoryId(event.value, this.organizationId).subscribe((result: any) => {
      this.elementNameData = result;
      console.log(' this.elementNameData', this.elementNameData)
    })
  }
  listLivingCity() {

    this.payrollService.listLivingCity().subscribe((result: any) => {
      this.livingCityData = result;
      console.log('this.livingCityData', this.livingCityData)
    })
  }
  get elementName() {
    return this.elementform.get('elementName');
  }

  get categoryType() {
    return this.elementform.get('categoryType');
  }


  get orderNumber() {
    return this.elementform.get('orderNumber');
  }
  get livingCity() {
    return this.elementform.get('livingCity');
  }
  get percentage() {
    return this.elementform.get('percentage');
  }
}
