import { X } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Subject, catchError, switchMap, takeUntil, throwError } from 'rxjs';
// import { MyErrorStateMatcher } from 'src/app/Authenticaton/login/login.component';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { HeaderService } from 'src/app/providers/header.service';
import { LeaveService } from 'src/app/providers/leave.service';
import Swal from 'sweetalert2';
export class LeaveType {
  leaveTypeId: any;
  leaveName: string;
  noOfDays: any;
  createdOn: any;
  status: any;
}

@Component({
  selector: 'app-leave-type-list',
  templateUrl: './leave-type-list.component.html',
  styleUrls: ['./leave-type-list.component.scss']
})
export class LeaveTypeListComponent implements OnInit {
  searchButton:boolean=true;


  isLoading: boolean = true;
  leaveTypeForm: FormGroup;
  endDate_disable: boolean;
  // matcher = new MyErrorStateMatcher();
  myFilter: any;
  noOFDays: any = 0;
  min: any;
  WorkingDays: any;
  empId: any;
  type: LeaveType[] = [];
  employeeList: any[] = [];
  leaveTypeId: any;
  employeeNumber: any;
  organizationId: any;
  employeeId: any;
  employee: string;
  submitted = false;
  loader: any;
  noData: boolean;
  fetchdata: any;
  unsubscribe$: any;
  selectedemployeeId: any;
  selectedemployee: any;
  employeeNotFound: boolean;
  errorMsg: string;
  successMsg: any;
  success = false;
  sucess_msg: any;
  error_msg: any;
  error = false;
  error_msg1: any;
  error1 = false;
  all_null: boolean=false;

  constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public leaveservice: LeaveService,
    private headerservice: HeaderService) { }

  ngOnInit(): void {
    // this.headerservice.setTitle('Leave Balance')

    
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    this.empId = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;
    this.headerservice.setTitle('')
    this.isLoading = false;
    this.endDate_disable = true;
    this.leaveservice.getLeaveType(this.organizationId).subscribe(
      (d: any) => {
        d.map(
          e => {
            if (e.status == 1) {
              this.type.push(e);
            }
          }
        )
      }
    )

    // OverAll Employee Active List in Dropdown
    // this.leaveservice.getEmpList1().subscribe(
    //   (e: any[]) => {
    //     this.employeeList=e;
    //     console.log("Employee List"+this.employeeList);
    //   }
    // )


    this.leaveTypeForm = this.fb.group({
      status: [''],
      employeeId: ['', Validators.required],

      employeeNumber: [''],
      leaveType: ['', Validators.required]
    })
    this.leaveservice.getEmpListById(this.empId).subscribe(
      (d: any) => {
        //working days
        this.WorkingDays = d.noOfWorkingDays;
        // get date
        const sample_date_data: any[] = [];
        this.leaveservice.get_leave_date(this.empId).subscribe((a: any) => {
          a.forEach(element => {
            let x = element.split("-");
            sample_date_data.push(new Date(`'${x[0]}/${x[1]}/${x[2]}'`).getTime())
          });
          let x = sample_date_data;
        })
        if (this.WorkingDays == 5) {
          this.myFilter = (a: Date): boolean => {
            const day = a.getDay();
            const time = a.getTime();
            // Prevent Saturday and Sunday from being selected.
            return day !== 0 && day !== 6 && !sample_date_data.find(x => x == time);
          }
        } else {
          this.myFilter = (a: Date): boolean => {
            const day = a.getDay();
            const time = a.getTime()
            // Prevent Saturday and Sunday from being selected.
            return day !== 0 && !sample_date_data.find(x => x == time);
          }
        }
      }
    );
  }

  Clear() {
    this.employeeList = [];
    this.employeeNotFound = false;

    this.noData = false;
    this.loader = true; 
    this.all_null=false;
    // Show loader while fetching data
    this.leaveTypeForm.reset(); // Reset the form
    this.submitted = false; // Clear the submitted flag
    this.fetchdata(); // Reload data
    this.employeeNumber = null;
    this.employeeId = null;

    this.employee= undefined;

  this.leaveTypeForm.patchValue({ 
    employeeId: undefined
  });

  }

  keyFunc(event: Event, event1: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
    let inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace

    if (event1.key === ' ') {
        // Handle the space key press
        console.log('Space key pressed');
        // Optionally, prevent the default behavior
        event1.preventDefault();
    } else {
        // Clear the employee list if the input is empty
        if (inputValue === '') {
            this.employeeList = [];
            this.employeeNotFound = false;
            this.all_null=false;


            console.log('Input is empty, employee list cleared');
            return;
        }

        // Cancel the previous API request if there's any
        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
        this.unsubscribe$ = new Subject<void>();

        this.leaveservice.getEmployeeListKeywords(this.organizationId, inputValue).pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
                return EMPTY; // Return EMPTY to propagate completion downstream
            })
        ).subscribe(
            (resultData: any) => {
                if (resultData.statusCode === 204) {
                    console.log("No employees found (status code 204)");
                    this.employeeList = [];
                    this.employeeNotFound = true;
                } else {
                    if (Array.isArray(resultData)) {
                        this.employeeList = resultData;
                    } else if (typeof resultData === 'object') {
                        this.employeeList = Object.values(resultData);
                    } else {
                        console.error('Unexpected data type:', resultData);
                    }
                    this.employeeNotFound = false;
                }
            },
            (err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
            }
        );
    }
}

  
  
  
  // keyFunc(event: Event) {
  //   const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
  //   let inputValue = inputElement.value;
  //   this.leaveservice.getEmployeeListKeywords(this.organizationId, inputValue).subscribe(
  //     (resultData: any) => {
  //       this.employeeList = resultData;
  //     })
  // }

  get f() {
    return this.leaveTypeForm.controls;
  }

  nofoDays(i) {
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value);
    // Copy date objects so don't modify originals
    var s: any = new Date(+start);
    var e: any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12, 0, 0, 0);
    e.setHours(12, 0, 0, 0);

    // Get the difference in whole days
    var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    var days = wholeWeeks * this.WorkingDays + 1;

    // If not even number of weeks, calc remaining weekend days
    if (totalDays % 7) {
      s.setDate(s.getDate() + wholeWeeks * 7);
      if (this.WorkingDays == 5) {
        while (s < e) {
          s.setDate(s.getDate() + 1);

          // If day isn't a Sunday or Saturday, add to business days
          if (s.getDay() != 0 && s.getDay() != 6) {
            ++days;
          }
        }
      }
      else {
        while (s < e) {
          s.setDate(s.getDate() + 1);

          // If day isn't a Sunday or Saturday, add to business days
          if (s.getDay() != 0 && s.getDay() != 7) {
            ++days;
          }
        }
      }
    }
    this.noOFDays = days;
    return days;
  }
  // date validation
  someMethodName(date: any) {
    this.min = new Date(date.value);
    this.endDate_disable = false
  }

  empSelect(emp) {
    console.log(emp);
    this.employeeId = emp.employeeId;
    this.employee = emp.employeeNumber + "-" + emp.firstName ;
    this.employeeNumber = emp.firstName;
    this.leaveTypeForm.patchValue({
      employeeId: this.employee
    });
  }

  // onSubmit(data) {
  //   this.submitted = true;
  //   if (this.leaveTypeForm.invalid) {
  //     return
  //   }
  //   this.creatingData = true;

  //   let x = this.leaveTypeForm.value;
  //   console.log("x", x);
  //   x.employeeId = this.employeeId;

  //   x['leaveType'] = {
  //     leaveTypeId: x.leaveType
  //   }

  //   x['status'] = 1

  //   this.isLoading = true;
  //   this.leaveservice.leaveBalance(x).subscribe(
  //     (d: any) => {
  //       if (d.statusCode == 200) {
  //         this.success = true;
  //         this.sucess_msg = d.description;
  //         this.creatingData = false;

  //         console.log("1111111", d.description, this.sucess_msg)
  //         setTimeout(() => {
  //           this.success = false;
  //           this.Clear(); // Clear form data

  //         }, 1000);
  //       } else {
  //         this.error = true;
  //         console.log("Error Create:", d);
  //         this.error_msg = d.message;
  //         this.creatingData = false;

  //         setTimeout(() => {
  //           this.error = false;
  //         }, 1000);
  //       }
  //     }, err => {
  //       console.log("Error result");
  //       this.errorMsg = err.error.description;
  //       this.creatingData = false;

  //       setTimeout(() => {
  //         this.error = false;
  //       }, 1000);
  //     });
  //   //         this.success = true;
  //   //         this.sucess_msg = d.description;
  //   //         this.error = false;
  //   //         this.error_msg = ''; // Clear any previous error messages
  //   //         this.isLoading = false;
  //   //         this.creatingData = false;

  //   //       setTimeout(() => {

  //   //         this.sucess_msg = '';
  //   //     }, 3000);
  //   // },
  //   //       err => {
  //   //         this.error = true;
  //   //         this.error_msg = err.error.message;
  //   //         this.success = false;
  //   //         this.sucess_msg = ''; // Clear any previous success messages
  //   //         this.isLoading = false;
  //   //         this.creatingData = false;

  //   //       setTimeout(() => {

  //   //         this.error_msg = '';
  //   //     }, 3000);
  //   // },
  //   //       () => {
  //   //         this.isLoading = false;
  //   //       this.creatingData = false;
  //   //       this.Clear();
  //   //       }
  //   //     )
  // }
  onSubmit(data) {
    this.submitted = true;
    this.searchButton=true;


if (!this.employee && this.leaveTypeForm.value.employeeId.length !=0 ){
  this.all_null=true;
  
    return;
}else{
  this.all_null=false;
}


    if (this.leaveTypeForm.invalid ) {
      this.searchButton=false;
      return;
    }
  
    let x = this.leaveTypeForm.value;
    console.log("x", x);
    x.employeeId = this.employeeId;

    x['leaveType'] = {
      leaveTypeId: x.leaveType
    }

    x['status'] = 1
    this.isLoading = true;
    this.searchButton=false;



    this.leaveTypeForm.patchValue({
      employeeId: this.employee
    });
    this.all_null=false;

  
    this.leaveservice.leaveBalance(x).subscribe(
      (d: any) => {
        if (d.statusCode === 200) {
          this.success = true;
          this.sucess_msg = d.description;

  
          setTimeout(() => {
            this.success = false;
           
            this.leaveTypeForm.reset();
            this.submitted = false;
            this.employeeList=[]; // Reset the form
            this.employeeId=undefined;
            this.employee=undefined;
            this.employeeNotFound=false;
            this.searchButton = true; // Enable button after success

          }, 3000);
        } else {
          this.error = true;
          console.log("Error Create:", d);
          this.error_msg = d.message ;

  
          setTimeout(() => {
            this.error = false;
            this.searchButton = true; // Enable button after error


          }, 3000);
        }
      },
      error => {
        console.log("Error result:", error);
        this.error = true;
        this.error_msg = error.error.message ;

        setTimeout(() => {
          this.error = false;
          this.searchButton = true; // Enable button after error


        }, 3000);
      },
      () => {
        this.isLoading = false;
        this.searchButton=false;

        // this.creatingData = false;
        // this.leaveTypeForm.reset(); 
        // this.submitted = false;// Reset the form
      }
    );
  }
  
  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }
    noEmployeeFound() {
      this.selectedemployeeId = undefined;
      this.selectedemployeeId = undefined; // Set selectedemployeeId to null
      this.employeeId = undefined;
      this.employeeList.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.selectedemployeeId = undefined;
      this.employeeList = []; // Clear the employee list
      this.employeeNotFound = false; 
      this.all_null=false;

    }
    preventSpaceBeforeInput(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
          event.preventDefault();
      }
    }
    handleBackspace(event): void {
      if (
        event.key === 'Backspace' &&
        !this.selectedemployee &&
        (this.selectedemployeeId === undefined || this.selectedemployeeId.trim().length === 0)
      ) {
        this.selectedemployeeId = undefined;
        this.employeeId = undefined;
        this.employeeList.length = 0;
        this.employee = undefined;
        this.employeeNumber = undefined;
        this.selectedemployee = undefined;
        this.selectedemployeeId = undefined;
      }
    }
  //   empSearch(event: any): void {
  //     if (event.target.value) {
  //       const input = event.target as HTMLInputElement;
  //       input.classList.remove('select-placeholder');
  //     } else {
  //       const input = event.target as HTMLInputElement;
  //       input.classList.add('black-placeholder');
  //     }
  //     console.log(event.target.value);
  //     const employeeNumber = event.target.value;
  //     console.log(employeeNumber);

  //     if (this.unsubscribe$) {
  //       this.unsubscribe$.next(); // Complete previous subscription if it exists
  //       this.unsubscribe$.complete();
  //     }
  //     this.unsubscribe$ = new Subject<void>();

  //     if (employeeNumber && employeeNumber.length >= 2) {
  //       this.leaveservice.getEmployeeListKeywords(this.organizationId,employeeNumber)
  //         .pipe(
  //           takeUntil(this.unsubscribe$),
  //           switchMap(() =>
  //             this.leaveservice.getEmployeeListKeywords(this.organizationId,employeeNumber).pipe(
  //               catchError((err) => {
  //                 if (err.error.status === 500) {
  //                   this.employeeList = [];
  //                   this.employeeNotFound = true;
  //                 }
  //                 console.log('err', err);
  //                 console.error('An error occurred:', err);
  //                 return EMPTY;
  //               })
  //             )
  //           )
  //         )
  //         .subscribe(
  //           (result: any) => {
  //             console.log('result', result);
  //             if (result.statusCode==204) {
  //               console.log("if entered");

  //               this.employeeList = [];
  //               this.employeeNotFound = true;
  //             } else {
  //               if (Array.isArray(result)) {
  //                 // this.empList=[]
  //                 this.employeeList = result;
  //               } else if (typeof result === 'object') {
  //                 this.employeeList = Object.values(result);
  //               } else {
  //                 console.error('Unexpected data type:', result);
  //               }
  //               this.employeeNotFound = false;
  //             }
  //           },
  //           (err) => {
  //             if (err.error.status === 500) {
  //               this.employeeList = [];
  //               this.employeeNotFound = true;
  //             }
  //             console.error('An error occurred:', err);
  //           }
  //         );
  //     } else {
  //       // Handle condition when employee number is not valid
  //       this.employeeList = [];
  //       this.employeeNotFound = false; // Depending on your use case, this might need to be true
  //     }
  //   }
  //   empSelect(emp) {
  //     console.log('emp',emp);
  //     this.employeeId= emp.employeeId;
  //     this.employee = emp.employeeNumber + '-' + emp.employee.firstName;
  //     // this.employeeCode = this.employeeStoring;
  //     console.log(this.employee);
  //     // console.log(this.employeeStoring);
  //     this.employeeNumber = emp.employee.employeeNumber;
  //     this.selectedemployee = this.employee;
  //     this.selectedemployeeId=this.employee;
  //     console.log(this.selectedemployeeId)
  //     this.leaveTypeForm.patchValue({
  //       employeeCode: this.employee
  // });
  // console.log("employee Id after selecting::",this.employeeId);

  //     console.log('this.employee::',this.selectedemployeeId);
  //     console.log("wtts id tell me?::",this.employeeId);
  //     // this.getTask()
  // }
}
