import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectManagementService } from '../providers/project-management.service';

@Component({
  selector: 'app-expensereiumbursementapproverstatus',
  templateUrl: './expensereiumbursementapproverstatus.component.html',
  styleUrls: ['./expensereiumbursementapproverstatus.component.scss']
})
export class ExpensereiumbursementapproverstatusComponent implements OnInit {
  storeApproveData: any;
  storeFirstname: any;
  loader: boolean;
  nodata: boolean=false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any = {}, 
    private advanceservice :ProjectManagementService,


  ) { }

  ngOnInit(): void {
    console.log(this.data);
    // console.log(this.data.approval.approver.fyiObject.employeeName);
       this.loader = true;
     if(this.data.type === "advance")
      {
        this.advanceservice.approvalsList(this.data.advanceId).subscribe((data:any)=>{
          console.log(data);
          this.loader = false;
          this.storeApproveData=data
          this.storeFirstname=data.approver.approverOneObject.firstName
          console.log(" this.storeFirstname::", this.storeFirstname);
          console.log(this.storeApproveData);
          
          
          // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);
    
        },)
      }else{
        this.advanceservice.expenseApprovalList(this.data.expenseReimbursementId).subscribe((data:any)=>{
          console.log(data);
          this.storeApproveData=data
          this.storeFirstname=data.approver.approverOneObject.firstName
          console.log(" this.storeFirstname::", this.storeFirstname);
          console.log(this.storeApproveData);
          this.loader = false;
          
          // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);
    
        },)
      }
  
  }

  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }

  approvalBackground(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#fff0c2'
    }
    if (approvalStatusName == 'Approved') {
      return '#c8e6c9'
    }
    if (approvalStatusName == 'Denied') {
      return '#ffcdd2'
    }
  }
  
  }


