import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';
import { FinanicalyeardetailComponent } from '../finanicalyeardetail/finanicalyeardetail.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

import { DatePipe } from '@angular/common';
import { PayrollService } from 'src/app/providers/payroll.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-finanicalyearlist',
  templateUrl: './finanicalyearlist.component.html',
  styleUrls: ['./finanicalyearlist.component.scss']
})


export class FinanicalyearlistComponent implements OnInit {

  public searchFilter: any = '';
  usertype: any;
  organizationId: any;
  clientId:any;
  branchlist: any = [];
  branchlistClient:any = [];
  years: number[] = [];
  yearinvalid: boolean = false;
  financialyearId: any;
  today: any = new Date().getFullYear() + 5;
  calendarnameexist: boolean;
  noRecordsFound: boolean = false;
  formsubmitted: boolean = false;
  tableShow: boolean = false;
  isbtnDisable: boolean = false;
  mySelect: any = 5;
  search: any;

  constructor(
    private settingsservice: SettingsService, private router: Router,
    private dialog: MatDialog, private formBuilder: FormBuilder, private datepipe: DatePipe,
    private payrollService: PayrollService
  ) { }



  submitted: boolean = false;
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  postForm: FormGroup;
  resultData: any = [];
  p: number = 1;
  collection: any[];
  pagePerItem: any = 10;
  tableSearch: any;
  months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  sortedData: any[];
  searchForm: FormGroup;
  branchlistSearch: any;
  branchlistSearchClient:any;
  loader: any = false;
  backdrop: any = false;
  nodatafound: boolean = true;

  //Documnt ready function
  ngOnInit(): void {
    this.noRecordsFound = true;
    this.searchForm = this.formBuilder.group({
      branchSearch: [null, Validators.required], // Initialize with null
      calendarNameSearch: [null, Validators.required]
    });
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let userId = this.usertype.userId;
    this.organizationId = this.usertype.organization.organizationId;
    // console.log("ORGID::",this.organizationId);
    this.clientId = this.usertype?.employee?.client?.clientId;
    // console.log("ID::",this.clientId);

    this.postForm = this.formBuilder.group({
      branch: [null, [Validators.required]],
      yearfrom: [null, [Validators.required]],
      yearto: [null, [Validators.required]],
      // calendarName : ['',[Validators.required]],
      calendarName: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],

      // description: [null,],
      description : [null, [ Validators.pattern("^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$")]],

    });

    this.postForm.patchValue({
      branch: null,
      yearfrom: null,
      yearto: null,
      monthfrom: null,
      monthto: null,

    });


    const currentYear = new Date().getFullYear() - 1;
    const futureYears = 5; // Number of future years to include

    for (let i = 0; i <= futureYears; i++) {
      this.years.push(currentYear + i);
    }



    //console.log(this.ActivatedRoute.snapshot.params.branchId);
    // this.settingsservice.getBranchList(this.organizationId).subscribe((resultData: any) => {
    //   this.branchlist = resultData;
    //   this.branchlistSearch = resultData;

    // });

    if (this.organizationId) {
      console.log("Calling getBranchList with organizationId:", this.organizationId);
      this.settingsservice.getBranchList(this.organizationId).subscribe((resultData: any) => {
        this.branchlist = resultData;
        this.branchlistSearch = resultData;
      });
    } else if (this.clientId) {
      console.log("Calling getBranchListByClient with clientId:", this.clientId);
      this.settingsservice.getBranchListByClient(this.clientId).subscribe((resultData: any) => {
        this.branchlist = resultData;
        this.branchlistSearch = resultData;
        this.branchlist = Array.isArray(resultData) ? resultData: [resultData];
        this.branchlistSearch = Array.isArray(resultData) ? resultData: [resultData];

      });
    } else {
      console.warn("No valid organizationId or clientId found.");
    }




    // this.finicalyearList();
  }

  get branchSearch() {
    return this.searchForm.get('branchNameSearch');
  }
  get calendarNameSearch() {
    return this.searchForm.get('calendarNameSearch');
  }

  date = new FormControl(moment());

  // chosenYearHandler(normalizedYear: Moment) {
  //   const ctrlValue = this.date.value;
  //   ctrlValue.year(normalizedYear.year());
  //   this.date.setValue(ctrlValue);
  // }

  // chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  //   const ctrlValue = this.date.value;
  //   ctrlValue.month(normalizedMonth.month());
  //   this.date.setValue(ctrlValue);
  //   datepicker.close();
  // }

  get validate() { return this.postForm?.controls; }

  validateyear() {
    console.log("function called")
    //  if( this.postForm.value.yearfrom && this.postForm.value.yearto)
    //  {
    //      if(this.postForm.value.yearto <  this.postForm.value.yearfrom)
    //      {
    //          console.log("to year invalid")
    //          this.yearinvalid = true;
    //      }else{
    //            this.yearinvalid = false;
    //      }
    //  }
  }


  finicalyearList() {
    this.settingsservice.getFinicalyearList(this.organizationId).subscribe((result: any) => {
      this.resultData = result;
    }, err => {
      //this.errorMsg = err.error.message;
    })
  }

  onDateChange(event: any): void {
    let selectedDate = event.value;
    const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    // const lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

    // Set the default date to the first day of the month
    selectedDate = firstDayOfMonth;

    // Set the default date to the last day of the month
    // selectedDate = lastDayOfMonth;

    // Format the default date to display in the input
    this.postForm.setValue({
      yearfrom: selectedDate
    })
  }
  clear() {
    this.postForm.reset();
    return;
  }
  addForm() //Add Dialog Box
  {
    // const dialogRef = this.dialog.open(AddQualificationComponent, {
    //   //height: 'auto',width: '600px',
    //   width: '500px',height:'fit-content',
    // });

    // dialogRef.afterClosed().subscribe(result =>
    // {
    //   this.qualificationList();

    //   if(result.statusCode == 200)
    //   {
    //     this.success = true;
    // 		this.sucess_msg = result.message;
    //     setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
    //   }
    //   else if(result.statusCode > 400)
    //   {
    //     this.error = true;
    //     this.error_msg = result.message;
    //     setTimeout(() => {this.error = false}, redirectMsgTimeOut)
    //   }
    // });
  }

  pageChages() {
    this.p = 1;
  }

  searchSubmit() {
    this.resultData = [];
    this.noRecordsFound = false;
    this.tableShow = false;
    this.isbtnDisable = false
    this.loader = false;
    console.log('search submit')
    let branchSearch = this.searchForm.value.branchSearch;
    console.log('branchSearch', branchSearch)

    let calendarNameSearch = this.searchForm.value.calendarNameSearch;
    console.log('calendarNameSearch', calendarNameSearch)

    if ((!branchSearch || branchSearch == 'null') && (!calendarNameSearch || calendarNameSearch == 'null') && this.formsubmitted == false) {
      this.noRecordsFound = true;
      alert('Please select Branch Name or Calendar Name to Search!');

      return;
    }

    if ((branchSearch && branchSearch != 'null') && (calendarNameSearch && calendarNameSearch != 'null')) {
      this.loader = true;
      this.backdrop = true;
      this.isbtnDisable = true;
      this.payrollService.searchByBranchAndCalendarName(branchSearch, calendarNameSearch).subscribe((resultData: any) => {

        this.resultData = resultData;
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.tableShow = true;
        this.isbtnDisable = false;



        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
          // this.tableShow=true
          // this.isbtnDisable=false;
          this.loader = false;

        }
        this.isbtnDisable = false;
        this.nodatafound = false;


        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        this.isbtnDisable = false;


      }
      )
    }
    else if ((branchSearch && branchSearch != 'null') && (!calendarNameSearch || calendarNameSearch == 'null')) {
      this.loader = true;
      this.backdrop = true;
      this.isbtnDisable = true;

      this.payrollService.searchByBranch(branchSearch).subscribe((resultData: any) => {


        this.resultData = resultData;
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.tableShow = true
        this.isbtnDisable = false;


        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
          // this.tableShow=true;
          // this.isbtnDisable=false;
          this.loader = false;


        }
        this.isbtnDisable = false;

        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        // this.isbtnDisable=false;

      })
    }
    else if ((!branchSearch || branchSearch == 'null') && (calendarNameSearch && calendarNameSearch != 'null')) {
      this.loader = true;
      this.backdrop = true;
      this.isbtnDisable = true;
      this.payrollService.searchByCalendarName(calendarNameSearch).subscribe((resultData: any) => {


        this.resultData = resultData;
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;
        this.tableShow = true
        this.isbtnDisable = false;


        if (this.resultData.length == 0 || this.resultData.length == undefined) {
          this.nodatafound = true;
          // this.isbtnDisable=false;
          this.tableShow = true;
          this.loader = false
        }
        this.isbtnDisable = false;

        if (resultData.statusCode == 204) {
          this.noRecordsFound = true;
        }
        // this.isbtnDisable=false;

      })
    }

    this.formsubmitted = false;
  }
  changefirstpage() {
    this.p = 1;
  }
  clearTable() {
    this.resultData = [];
    this.noRecordsFound = true;
    console.log('clear')
    this.searchForm.clearValidators;
    // this.settingsservice.getFinicalyearList().subscribe((elementslistData:any) =>
    // {
    //   console.log('elementslistData',elementslistData)
    //   this.resultData = elementslistData;
    //   console.log(this.resultData);
    //   if(this.resultData.length == 0)
    //   {this.noRecordsFound = true;
    //     this.nodatafound = true;
    //     console.log("nodata");
    //   }
    //   if(elementslistData.statusCode == 204){
    //     this.noRecordsFound = true;
    //   }
    // },
    // (error) => {
    //   this.nodatafound = true;
    //   console.log("error")
    //   if(error.status == 404){
    //     this.nodatafound = true;
    //     console.log("404")
    //   }
    // }
    // )
  }

  updateform(editData) {
    this.financialyearId = editData.financialYearId;
    this.settingsservice.getFinancialYearId(editData.financialYearId).subscribe((resultData: any) => {

      console.log('resultData', resultData);

      this.resultData = resultData;
      let lastday = this.getLastDayOfMonth(this.resultData.financialToMonth, this.resultData.financialToYear);
      let calendarfrom = new Date(`01-${this.resultData.financialFromMonth}-${this.resultData.financialFromYear}`)
      // console.log("calendarfrom" + calendarfrom);
      // console.log("lastday" + lastday);
      let calendarto = new Date(`${lastday}-${this.resultData.financialToMonth}-${this.resultData.financialToYear}`)
      // console.log("calendarto" + calendarto);
      this.postForm.patchValue({
        branch: this.resultData.branch.branchId,
        yearfrom: calendarfrom,
        yearto: calendarto,
        description: this.resultData.financialDescription,
        calendarName: this.resultData.financialYearName
      });
    });

  }

  updateStatus(id, status) {
    this.settingsservice.updateFinancialYearStatus(id, status).subscribe((result: any) => {
      if (result.statusCode == 200) {
        // this.success = true;
        this.loader = true;
        this.tableShow = true
        // this.sucess_msg = result.message;
        setTimeout(() => {
          // this.success  = false;
          this.tableShow = true;
          this.loader = false

        }, redirectMsgTimeOut)
        this.searchSubmit();
        // this.finicalyearList();
      }
      else {
        // this.error = true;
        this.loader = false
        // this.error_msg = result.message;
        setTimeout(() => {
          // this.error = false
        }, redirectMsgTimeOut)
      }
    })
  }

  validatecalendarname(event) {
    if (event.target.value.lengh != 0) {
      if (this.financialyearId) {


        let fata = {
          financialYearId: this.financialyearId,
          financialYearName: event.target.value,
          organization: { organizationId: this.organizationId },
        }


        this.settingsservice.validatecalendarnameupdate(fata).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.calendarnameexist = false;

          }
          else {
            this.existMsg = "Finical calendar Name Already Exist"
            this.calendarnameexist = true;
          }
        })
      } else {


        let fata = {
          financialYearName: event.target.value,
          organization: { organizationId: this.organizationId },
        }

        this.settingsservice.validatecalendarname(fata).subscribe((result: any) => {
          if (result.statusCode == 200) {
            this.calendarnameexist = false;
          }
          else {
            this.existMsg = "Finical calendar Name Already Exist"
            this.calendarnameexist = true;
          }
        })
      }
    } else {
      this.calendarnameexist = false;
    }
  }

  // onSubmit() {
  //   this.submitted = true;
  //   this.isbtnDisable = false;


  //   if (this.postForm.invalid || this.calendarnameexist) {
  //     console.log("invalid")
  //     return;
  //   }

  //   if (this.financialyearId) //Update Finacialyear
  //   {
  //     let postValues = this.postForm.value;


  //     console.log(postValues);

  //     let caledaryearfrom = this.datepipe.transform(postValues.yearfrom, 'MMMM yyyy').split(" ");
  //     console.log(caledaryearfrom + "caledaryearfrom");
  //     let caledaryearto = this.datepipe.transform(postValues.yearto, 'MMMM yyyy').split(" ");
  //     console.log(caledaryearto + "caledaryearto");
  //     let finanicalyeardata =
  //     {
  //       financialYearId: this.financialyearId,
  //       financialFromMonth: caledaryearfrom[0],
  //       financialFromYear: caledaryearfrom[1],
  //       financialToMonth: caledaryearto[0],
  //       financialToYear: caledaryearto[1],
  //       financialDescription: postValues.description,
  //       financialYearName: postValues.calendarName,
  //       branch: {
  //         branchId: postValues.branch,
  //       },
  //       organization: { organizationId: this.organizationId },
  //       financialYearStatus: this.resultData.financialYearStatus
  //     }

  //     console.log(finanicalyeardata);
  //     this.settingsservice.updateFinancialYear(this.financialyearId, finanicalyeardata).subscribe((result: any) => {
  //       this.formsubmitted = true;
  //       if (result.statusCode == 200) {
  //         this.success = true;
  //         this.isbtnDisable = true;

  //         this.sucess_msg = result.message;
  //         this.postForm.reset();
  //         this.submitted = false;
  //         setTimeout(() => { this.success = false; }, 4000)
  //         //this.router.navigate(['/home/employee']);
  //         this.financialyearId = null;
  //         setTimeout(() => {
  //           this.nodatafound = true;
  //           this.searchSubmit();
  //         }, 4000)

  //       }

  //       else {
  //         this.error = true;
  //         this.error_msg = result.message;
  //         this.isbtnDisable = true;

  //         setTimeout(() => { this.error = false }, 4000)
  //       }
  //       this.isbtnDisable = false;


  //     }, err => {
  //       this.errorMsg = err.error.message;

  //     })


  //   }
  //   else  //Add Finacialyear
  //   {
  //     let postValues = this.postForm.value;

  //     let caledaryearfrom = this.datepipe.transform(postValues.yearfrom, 'MMMM yyyy').split(" ");
  //     console.log(caledaryearfrom + "caledaryearfrom");
  //     let caledaryearto = this.datepipe.transform(postValues.yearto, 'MMMM yyyy').split(" ");
  //     console.log(caledaryearto + "caledaryearto");
  //     console.log(postValues);

  //     let finanicalyeardata =
  //     {

  //       financialFromMonth: caledaryearfrom[0],
  //       financialFromYear: caledaryearfrom[1],
  //       financialToMonth: caledaryearto[0],
  //       financialToYear: caledaryearto[1],
  //       financialDescription: postValues.description,
  //       financialYearName: postValues.calendarName,
  //       branch: {
  //         branchId: postValues.branch,
  //       },
  //       organization: { organizationId: this.organizationId }
  //     }

  //     console.log(finanicalyeardata);

  //     //  return
  //     this.settingsservice.createFinancialYear(finanicalyeardata).subscribe((result: any) => {
  //       this.formsubmitted = true;
  //       if (result.statusCode == 200) {
  //         this.success = true;
  //         this.isbtnDisable = true;

  //         this.sucess_msg = result.message;
  //         setTimeout(() => {
  //           this.success = false;
  //           this.postForm.reset();
  //           this.submitted = false;
  //           this.searchSubmit();
  //           // this.finicalyearList()
  //         }, 4000)
  //         //this.router.navigate(['/home/employee']);
  //         // this.postForm.reset();
  //         // this.submitted = false;
  //         // this.searchSubmit();
  //         //     setTimeout(() => {
  //         // //      this.finicalyearList();
  //         //     }, 4000)

  //       }

  //       else {
  //         this.error = true;
  //         this.error_msg = result.message;
  //         this.isbtnDisable = true;

  //         setTimeout(() => { this.error = false }, 4000)

  //         setTimeout(() => {
  //           //    this.finicalyearList();
  //         }, 4000)
  //       }
  //       this.isbtnDisable = false;


  //     }, err => {

  //       setTimeout(() => {
  //         this.error = true;
  //         this.errorMsg = err.error.message;
  //         this.isbtnDisable = true;

  //         // this.router.navigate(['/home/financialyear']);
  //       }, 4000)
  //     })
  //   }


  // }

  onSubmit() {
    this.submitted = true;
    // this.isbtnDisable = false;
  
    if (this.postForm.invalid || this.calendarnameexist) {
      console.log("invalid");
      return;
    }
  
    // Determine if organizationId or clientId should be included in the payload
    const entityData = this.organizationId
      ? { organization: { organizationId: this.organizationId } }
      : { client: { clientId: this.clientId } };
  
    if (this.financialyearId) { // Update Financial Year
      let postValues = this.postForm.value;
  
      let caledaryearfrom = this.datepipe.transform(postValues.yearfrom, 'MMMM yyyy').split(" ");
      console.log(caledaryearfrom + "caledaryearfrom");
      let caledaryearto = this.datepipe.transform(postValues.yearto, 'MMMM yyyy').split(" ");
      console.log(caledaryearto + "caledaryearto");
  
      let financialYearData = {
        financialYearId: this.financialyearId,
        financialFromMonth: caledaryearfrom[0],
        financialFromYear: caledaryearfrom[1],
        financialToMonth: caledaryearto[0],
        financialToYear: caledaryearto[1],
        financialDescription: postValues.description,
        financialYearName: postValues.calendarName,
        branch: {
          branchId: postValues.branch,
        },
        ...entityData, // Dynamically add organization or client
        financialYearStatus: this.resultData.financialYearStatus
      };
  
      console.log(financialYearData);
      this.isbtnDisable=true
      this.settingsservice.updateFinancialYear(this.financialyearId, financialYearData).subscribe((result: any) => {
        this.formsubmitted = true;
        if (result.statusCode == 200) {
          this.success = true;
          this.isbtnDisable = true;
  
          this.sucess_msg = result.message;
          this.postForm.reset();
          this.submitted = false;
          setTimeout(() => { this.success = false; }, 4000);
          this.financialyearId = null;
          setTimeout(() => {
            this.nodatafound = true;
            this.searchSubmit();
          }, 4000);
        } else {
          this.error = true;
          this.isbtnDisable=false

          this.error_msg = result.message;
          this.isbtnDisable = true;
  
          setTimeout(() => { this.error = false; }, 4000);
        }
        this.isbtnDisable = false;
      }, err => {
        this.errorMsg = err.error.message;
      });
  
    } else { // Add Financial Year
      let postValues = this.postForm.value;
  
      let caledaryearfrom = this.datepipe.transform(postValues.yearfrom, 'MMMM yyyy').split(" ");
      console.log(caledaryearfrom + "caledaryearfrom");
      let caledaryearto = this.datepipe.transform(postValues.yearto, 'MMMM yyyy').split(" ");
      console.log(caledaryearto + "caledaryearto");
  
      let financialYearData = {
        financialFromMonth: caledaryearfrom[0],
        financialFromYear: caledaryearfrom[1],
        financialToMonth: caledaryearto[0],
        financialToYear: caledaryearto[1],
        financialDescription: postValues.description,
        financialYearName: postValues.calendarName,
        branch: {
          branchId: postValues.branch,
        },
        ...entityData // Dynamically add organization or client
      };
  
      console.log(financialYearData);
      this.isbtnDisable=true

      this.settingsservice.createFinancialYear(financialYearData).subscribe((result: any) => {
        this.formsubmitted = true;
        if (result.statusCode == 200) {
          this.success = true;
          this.isbtnDisable = true;
  
          this.sucess_msg = result.message;
          setTimeout(() => {
            this.success = false;
            this.postForm.reset();
            this.submitted = false;
            this.searchSubmit();
          }, 4000);
        } else {
          this.error = true;
          this.error_msg = result.message;
          this.isbtnDisable = true;
          this.isbtnDisable=false

  
          setTimeout(() => { this.error = false; }, 4000);
        }
        this.isbtnDisable = false;
      }, err => {
        setTimeout(() => {
          this.error = true;
          this.errorMsg = err.error.message;
          this.isbtnDisable = false;
        }, 4000);
      });
    }
  }
  

  viewdata(data) {
    var dialogRef = this.dialog.open(FinanicalyeardetailComponent, {
      width: '900px', height: '580px',
      data: data.financialYearId
    });
  }

  /**** Global Variables *****/
  ucFirst(text) {
    return text.replace(/(?:^|\s)\S/g, (res) => { return res.toUpperCase(); })
  };
  /**** Global Variables *****/

  sortData(sort: Sort) {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'streamName':
          return compare(a.streamName, b.streamName, isAsc);

        default:
          return 0;
      }
    });
  }

  getLastDayOfMonth(monthName, year) {
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
    const lastDay = new Date(year, monthIndex + 1, 0).getDate();
    return lastDay;
  }

}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

