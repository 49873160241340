import { Component, Input, OnInit, ViewChild } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecruitmentService } from 'src/app/providers/recruitment.service';
import { AdminService } from 'src/app/providers/admin.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { UserService } from 'src/app/providers/user.service';
// import { base_url} from '../../providers/properties';

@Component({
  selector: 'app-super-admin-dashboard',
  templateUrl: './super-admin-dashboard.component.html',
  styleUrls: ['./super-admin-dashboard.component.scss']
})
export class SuperAdminDashboardComponent implements OnInit
{
  resultData: any = {};
  resultDatarec: any;
  errorMsg: any;
  admindashboard: any;
  ProfileShared: number;
  Interviewed: any;
  Notinterviewed: number;
  recruiterSearchform: FormGroup;
  @Input() requiredError: boolean;
  reportfromdate:any;
  reporttodate:any;
  startmaxdate :Date =  new Date();
  endmindate;
  recruiterlist:any;
  loader:any= false;
  backdrop:any= false;
  dataForExcel = [];
  true:any;
  interviewStatuslist:any;
  usertData:any;
  headerForExcel = [
    {'Profile Shared':'', 'Interviewed':'', 'Not Interviewed':'', 'Selected':'', 'Rejected':'', 'On Hold':'', 'Open Position':''}
  ];
  exportingdata:any;
  selectedrecruiter:any;
  employeeName:any;
  recruitmentservice: any;
  Adminservice: any;
  upcomingBirthday : any = {};
  upcomingWorkAnniversery : any = {};

  fileblob: any;
  fileblob1: any;

  success:any=false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  results: any;

  constructor(
    private dashboardservice: RecruitmentService,
    private adminservice: AdminService,
    private superadminservice: SuperadminService,
    private UserService: UserService,
    private datepipe : DatePipe ,
    private activatedRoute : ActivatedRoute
  ) { }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    ///////// pie Chart //////////
    public pieChartOptions_user: ChartConfiguration['options'] = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'top',
        labels: {
          color: 'black'
        }
        },
        datalabels: {
          color:'white',
          formatter: (value, ctx) => {
            if (ctx.chart.data.labels) {
              return ctx.chart.data.labels[ctx.dataIndex];
            }
          },
          font: {
           // weight: 'bold',
            size: 13,
          },
        },
      }
    };
    public pieChartData_user: ChartData<'pie', number[], string | string[]> = {
      labels:[['Profile Shared'],['Interviewed'],['Not interviewed']],
      datasets: [ {
        data: [300, 300, 400],
        backgroundColor: ['#f67c94', '#f67f63', '#5e77a1'],
        hoverBackgroundColor:['#f67c94','#f67f63','#5e77a1'],
        hoverBorderColor:['#f67c94','#f67f63','#5e77a1'],


      } ],
    };
    public pieChartType_user: ChartType = 'pie';
    public pieChartPlugins_user = [ DatalabelsPlugin ];



  ngOnInit(): void
  {
    this.getModulesList();
    this.dashboardDataNew();
    this.recruiterSearchform = new FormGroup(
      {
        fromdate : new FormControl(this.recruiterSearchform,[Validators.required]),
        todate : new FormControl(this.recruiterSearchform,[Validators.required]),
        recruiter : new FormControl(this.recruiterSearchform)
      }
    );
    this.admindashboard = {
      "rejected": 0,
      "notInterviewed":0,
      "selected": 0,
      "onHold": 0,
      "profilesource": 0,
      "interviewed" : 0
    }

    this.dashboardservice.admindashboard().subscribe((admindashboard:any) =>
    {
        this.admindashboard = admindashboard;
        console.log(" this.admindashboard " + JSON.stringify( this.admindashboard));
        console.log(" this.admindashboard.openPositio" + this.admindashboard.openPosition);

        this.ProfileShared  = this.admindashboard.profilesource;
        this.Interviewed = this.admindashboard.interviewed;
        this.Notinterviewed = this.admindashboard.notInterviewed;
        this.pieChartData_user = {
          labels:[['Profile Shared'],['Interviewed'],['Not interviewed']],
          datasets: [ {
            data: [this.ProfileShared, this.Interviewed, this.Notinterviewed],
            backgroundColor: ['#f67c94', '#f67f63', '#5e77a1'],
            hoverBackgroundColor:['#f67c94','#f67f63','#5e77a1'],
            hoverBorderColor:['#f67c94','#f67f63','#5e77a1'],
          } ],
        };
    })
  }

  dashboardDataNew()
  {
    this.superadminservice.dashboardData().subscribe((result: any) =>
    {
      this.resultData = result;

    },err =>{
      this.errorMsg = err.error.message;
    })
  }

  ucFirst(string)
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getModulesList()
  {
    /* Get Designation Data */
    this.superadminservice.getModulesList().subscribe((result: any) => {
      this.results = result;
      console.log("RD",this.results);
    },err =>{
       this.errorMsg = err.error.message;
       console.log(this.errorMsg);
    })
  }


/*  dashboardRecruitmentAdmin()
  {
      this.recruitmentservice.dashboardRecruitmentAdmin().subscribe((result: any) => {
      this.resultDatarec = result;
      console.log(this.resultDatarec);
    },err =>{
      this.errorMsg = err.error.message;
    })
  } */
}
