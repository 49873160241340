<form [formGroup]="clientInfo">
    <h2>Create Employee</h2>
    <div class="row">
        <div class="form-group col-md-10">
            <label class="col-form-label">Client Name<span class="star">*</span></label> 
            <select formControlName="client"  class="form-select form-select-solid form-select-lg select2-new">
                <option [value]="null"> --- Select Client --- </option>
                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
            </select>    
            <!-- <div class="valid-msg" *ngIf="(basic.client.invalid)"> -->
                <!-- <span *ngIf="basic.client.errors?.required" class="text-danger">Please select client</span> -->
                <!-- <span *ngIf="validate.client.errors?.pattern" class="text-danger">Please enter valid Branch Code</span>  -->
            <!-- </div> -->
            <!-- <div *ngIf="clientInfo.get('client').invalid || clientInfo.get('client')==null">
                <div class="text-danger">Please select client.</div>
              </div> -->
              <!-- <div *ngIf="clientInfo.get('client').invalid && (clientInfo.get('client').dirty || clientInfo.get('client').touched)">
                <div class="text-danger">Please select a client.</div>
              </div> -->
             <div *ngIf="clientInfo.get('client').invalid && (clientInfo.get('client').dirty || clientInfo.get('client').touched)">
                <div class="text-danger">Please select a client.</div>
              </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-right">
            <!-- <a routerLink="/home/employee" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2">
                Cancel
            </a> -->
            <!--<button type="button" (click)="next()" mat-raised-button color="primary">Next</button> -->
            <!-- <input (click)="next()" type="button" name="next" class="next action-button btn btn-primary btn-sm" value="Save & Next"> -->
            <input (click)="basicSubmit()" this.clientInfotype="submit" name="submit" class="submit action-button btn btn-primary btn-sm col-md-3" value="Next"/>
        </div>
    </div>
</form>