import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, FormGroupName } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { leavedenyreason } from '../withdraw-list/withdraw-list.component';
import { LeaveService } from 'src/app/providers/leave.service';
import { WithdrawComponent} from './withdraw/withdraw.component';
import { WithdrawDetailsComponent} from './withdraw-details/withdraw-details.component';
import { ApproverStatusComponent } from '../approver-status/approver-status.component';
import { HeaderService } from 'src/app/providers/header.service';
import { Subscription } from 'rxjs';
import { LeavemessageService } from 'src/app/leavemessage.service';
import { LeavemanagementstatusComponent } from 'src/app/leavemanagementstatus/leavemanagementstatus.component';

export interface DialogData {
  leaveType: any;
  leaveRequestId: any;
  applyTo: any;
  cc: any
}

@Component({
  selector: 'app-myleavelist',
  templateUrl: './myleavelist.component.html',
  styleUrls: ['./myleavelist.component.scss']
})
export class MyleavelistComponent implements OnInit {
  
  data: any[] = [];
  filter_Data = "All"
  isLoading:Boolean;
  noData=false;
  filterData = [
    "All",
    "Approved",
    "Pending",
    "Denied",
    "Cancelled",
    "Withdrawn"
  ]
  list_count = 0;
  organizationEmail:any;
  personalEmail:any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    [
      'sno',
      'startDate',
      'endDate',
      'leaveType',
      'noOfLeaveDays',
      'reason',
      "viewapprovers",
      'leaveStatus',
      'withdraw',
      'star'];
  // dataSource: any;
  loader=true;
  changeasDenyReason: boolean=false;
  isMenuOpen: boolean = false;
  clickedbusinessTravelId: any;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  //  dataSource = ELEMENT_DATA;
  lastclickedIndex:any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
searchClicked: any;
  errorMessage: string;
  successMessage:string;
  success_msg: string;
  error_msg: any;
  success: boolean = false;
  error: boolean = false;
  private successMessageSubscription: Subscription;
  private errorMessageSubscription: Subscription;
  searching: boolean;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
  list_counts=false;

  constructor(
    private gs: GlobalserviceService, 
    private router: Router, 
    public dialog: MatDialog,
    private renderer: Renderer2,
    private leaveservice :LeaveService,
    private headerservice: HeaderService,
    private leavemessage :LeavemessageService
    ) {
  }
  ngOnDestroy() {
    console.log('calling ngOnDestroy');
   
    this.success = false;
    this.success_msg = '';
    this.leavemessage.setSuccessMessage('');
    if (this.successMessageSubscription) {
      this.successMessageSubscription.unsubscribe();
    }
    if (this.errorMessageSubscription) {
      this.errorMessageSubscription.unsubscribe();
    }
 
  }
  ngOnInit() {
    // this.headerservice.setTitle('My Leave List')
    this.headerservice.setTitle('');
    this.successMessageSubscription=this.leavemessage
    .getSuccessMessage().subscribe((message)=>{
      if (message) {
        this.success = true;
        this.success_msg = message;
        this.loader=false;
        this.fetchleave();
      

      // Close the success message after 3 seconds (adjust duration as needed)
      setTimeout(() => {
        this.success = false;
        this.success_msg = '';
        this.closeSuccessMessage();
      }, 5000);
      }
    });

    this.errorMessageSubscription=this.leavemessage
    .getErrorMessage()
    .subscribe((message)=>{
      if (message) {
        this.error = true;
        this.error_msg= message;
        this.loader=false;
      

      setTimeout(() => {
        this.error = false;
        this.error_msg = '';
        this.closeErrorMessage();
      }, 5000);
    }
    })

    console.log("Sucess");
    // this.get_user_data("All");

    // let x = JSON.parse(localStorage.getItem('enoteUserData'));
    // let id = x.employee.employeeId;
    // console.log("Loader");
    // this.loader=true;
    // this.leaveservice.getMyleave(id).subscribe(
    //   (s: any) => {
    //     this.loader=false;
    //     console.log("Not Loader");
    //     if(s.length==0)
    //   {
    //     this.noData=true;
    //     console.log("NoData");
    //   }
    //   if(s.statusCode==204)
    //   {
    //      this.noData=true;
    //      console.log("204");
    //   }
    //     this.data = s;
    //     console.log("Data");
    //     s.forEach(e =>{
    //       console.log('tttttttttttttttt',e.leaveWithdraw.withdrawStatus)
    //       if(e.leaveWithdraw.withdrawStatus == 3){
    //         e.leaveWithdraw.withdrawStatus = "Pending"
    //       }
    //       if(e.leaveWithdraw.withdrawStatus == 4){
    //         e.leaveWithdraw.withdrawStatus = "Deleted"
    //       }
    //       if(e.leaveWithdraw.withdrawStatus == 5){
    //         e.leaveWithdraw.withdrawStatus = "Approved"
    //       }
    //       if(e.leaveWithdraw.withdrawStatus == 6){
    //         e.leaveWithdraw.withdrawStatus = "Denied"
    //       }
    //       if(e.leaveWithdraw.withdrawStatus == 7){
    //         e.leaveWithdraw.withdrawStatus = "Withdrawn"
    //       }
    //       if(e.leaveWithdraw.withdrawStatus == 8){
    //         e.leaveWithdraw.withdrawStatus = "Cancelled"
    //       }
    //     })
    //     this.dataSource = new MatTableDataSource<any>(this.data);
    //     console.log(this.dataSource);
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    //     this.list_count = this.data.length;
    //     console.log(this.list_count);

    //   },
    //   (error) =>
    //   {
    //     this.noData= true;
    //     this.loader=false;
    //     console.log("error");
    //     if(error.statusCode==204)
    //     {
    //        this.noData=true;
    //        console.log("204");
    //     }
    //    console.log("noData" + this.noData);
    //   }
    // )
    this.fetchleave();

  }
  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

   fetchleave(){
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    let id = x.employee.employeeId;
    console.log("Loader");
    this.loader=true;
    this.leaveservice.getMyleave(id).subscribe(
      (s: any) => {
        this.loader=false;
        this.list_counts=true;
        console.log("Not Loader");
        if(s.length==0)
      {
        this.noData=true;
        console.log("NoData");
      }
      if(s.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
        this.data = s;
        console.log("Data",this.data);
        s.forEach(e =>{
          console.log('tttttttttttttttt',e.leaveWithdraw.withdrawStatus)
          if(e.leaveWithdraw.withdrawStatus == 3){
            e.leaveWithdraw.withdrawStatus = "Pending"
          }
          if(e.leaveWithdraw.withdrawStatus == 4){
            e.leaveWithdraw.withdrawStatus = "Deleted"
          }
          if(e.leaveWithdraw.withdrawStatus == 5){
            e.leaveWithdraw.withdrawStatus = "Approved"
          }
          if(e.leaveWithdraw.withdrawStatus == 6){
            e.leaveWithdraw.withdrawStatus = "Denied"
          }
          if(e.leaveWithdraw.withdrawStatus == 7){
            e.leaveWithdraw.withdrawStatus = "Withdrawn"
          }
          if(e.leaveWithdraw.withdrawStatus == 8){
            e.leaveWithdraw.withdrawStatus = "Cancelled"
          }
        })
        this.dataSource = new MatTableDataSource<any>(this.data);
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.list_count = this.data.length;
        console.log(this.list_count);

      },
      (error) =>
      {
        this.noData= true;
        this.loader=false;
        console.log("error");
        if(error.statusCode==204)
        {
           this.noData=true;
           console.log("204");
        }
       console.log("noData" + this.noData);
      }
    )
   }
  apply_leave(){
    this.router.navigateByUrl("/home/apply-leave");
  }

  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(LeavemanagementstatusComponent,{
      width: '500px',
      // minHeight: '500px',
      height:'fit-content',
      data: {
        referredwith: "LEAVEREQUEST",
        id: approvaldata.leaveRequestId
      }
            })
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataSource.filteredData.length;
    if(this.list_count ==0){
      this.noData = true;
    }
    else{
      this.noData = false;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deny(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    if(this.isMenuOpen==false){
      console.log("if");
      console.log(this.isMenuOpen);
      this.denyReason(e);
    }
    else{
      console.log("else");
      this.isMenuOpen=false;
      this.denyReason(e);
    }
  }
  denyReason(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if(this.isMenuOpen==true){
      this.changeasDenyReason=true;
      console.log(this.changeasDenyReason);
    }
    else{
      this.changeasDenyReason=false;
      console.log(this.changeasDenyReason);
    }
  }

  test(i) {
    if (i.leaveStatusName) {
      // console.log("11111111111111111111111");

        switch (i.leaveStatusName) {
            case 'Pending':
                return '#8313ff';
            case 'Approved':
                return '#28a745';
            case 'Denied':
                return '#f44336';
            case 'Withdrawn':
                return '#20B2AA';
            case 'Cancelled':
                return '#e40000';
            default:
                return ''; // Default case if none of the above match
        }
    } else {
        return ''; // Default return value if any of the properties are undefined
    }
}

  withDrawStatus(i) {
    if (i.leaveWithdraw == null) {
      return
    }
    if (i.leaveWithdraw.withdrawStatus == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Approved') {
      return '#28a745'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Denied') {
      return '#f44336'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Withdrawn') {
      return '#20B2AA'
    }    
    if (i.leaveWithdraw.withdrawStatus == 'Cancelled') {
      return '#e40000'
    }
  }
  showWithdrawDetails(i) {
    console.log(i)
    let x = i;
    this.dialog.open(WithdrawDetailsComponent, {
      // width: '100%',
      data: x,
      width:'450px'
    });
  }

  openWithdrawDialog(i) {
    console.log(i)
    let x = i;
    this.dialog.open(WithdrawComponent, {
      data: x,
        width: '650px'
    });
  }

  cancel(e){
    console.log(e,"identification")
    Swal.fire({
      title: 'Are you sure? Do you want to Cancel Leave Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor:'#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader = true;

        this.leaveservice.approvalsById("LEAVEREQUEST",e.leaveRequestId).subscribe((data:any)=>{
          console.log(e);
          
          this.loader = false;
          
          // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);
          e['leaveStatus'] = 8;
          e['approverlevel'] = 1;
            e['approvals'] = data;
        
          e.approvals.approvalStatus.listTypeValueId = 8 ;
          e.approvals.approvalStatus.listTypeValueName = "Cancelled";
          e['employeeId'] = e.employee.employeeId;
          this.loader = true;

          this.leaveservice.UpdateLeaveStatus(e.leaveRequestId, e).subscribe(
            d => {
              // Swal.fire({
              //   text: 'Cancelled',
              //   icon: 'success'
              // })
              this.success = true;
              this.success_msg = 'My Leave List Cancelled Successfully';
              this.loader = false
              setTimeout(() => { this.success = false; }, 3000);
              this.fetchleave();
            },
            err => {
              // Swal.fire({
              //   text: err.error.message,
              //   icon: 'error'
              // })
              this.error = true;
              this.error_msg = err.error.message;
              this.loader = false
  
              setTimeout(() => { this.error = false; }, 3000);        
              },
              () => {
                this.loader = false
              }
          )
        },)
    //     e['leaveStatus'] = 8;
    // e['approverlevel'] = 1;
    // e.approvals.approvalStatus.listTypeValueId = 8 ;
    // e.approvals.approvalStatus.listTypeValueName = "Cancelled";
    // e.['employeeId'] = e.employee.employeeId;
    
      }
    })
  }

  reason(i) {
    console.log(i)
    this.dialog.open(Reason, {
      width: '300px',
      data: i.leaveReason
    });
  }

  showdenyreason(i){
    console.log(i)
    let x = i;
    this.dialog.open(leavedenyreason, {
      width: '300px',     
       data: x
    });
  } 

  // get_user_data(filter) {
  //   let x = JSON.parse(localStorage.getItem('enoteUserData'));
  //   let id = x.employee.employeeId;
  //   this.leaveservice.getMyleave(id).subscribe(
  //     (s: any) => {
  //       console.log(s)

  //       if (filter != "All") {
  //         this.data = s.filter(a => {
  //           return a.leaveStatusName == filter;
  //         });
  //         if (this.data.length < 1) {
  //           Swal.fire(
  //             `No ${filter} List Found`
  //           )
  //           let x = JSON.parse(localStorage.getItem('enoteUserData'));
  //           let id = x.employee.employeeId;
  //           this.leaveservice.getMyleave(id).subscribe(
  //             (s: any) => {
  //               s.forEach(e =>{

  //                 console.log(e.leaveWithdraw.withdrawStatus)
  //                 if(e.leaveWithdraw.withdrawStatus == 3){
  //                   e.leaveWithdraw.withdrawStatus = "Pending"
  //                 }
  //                 if(e.leaveWithdraw.withdrawStatus == 4){
  //                   e.leaveWithdraw.withdrawStatus = "Deleted"
  //                 }
  //                 if(e.leaveWithdraw.withdrawStatus == 5){
  //                   console.log("11111111111111111111111111111111111")

  //                   e.leaveWithdraw.withdrawStatus = "Approved"
  //                 }
  //                 if(e.leaveWithdraw.withdrawStatus == 6){
  //                   e.leaveWithdraw.withdrawStatus = "Denied"
  //                 }
  //                 if(e.leaveWithdraw.withdrawStatus == 7){
  //                   e.leaveWithdraw.withdrawStatus = "Withdrawn"
  //                 }
  //                 if(e.leaveWithdraw.withdrawStatus == 8){
  //                   e.leaveWithdraw.withdrawStatus = "Cancelled"
  //                 }
  //               })
  //               this.data = s
  //               this.dataSource = new MatTableDataSource<any>(this.data);
  //               this.dataSource.paginator = this.paginator;
  //               this.dataSource.sort = this.sort;
  //               this.list_count = this.data.length;
  //             }
  //           )
  //         }
  //       } else {
  //         let x = JSON.parse(localStorage.getItem('enoteUserData'));
  //         let id = x.employee.employeeId
  //         this.leaveservice.getMyleave(id).subscribe(
  //           (s: any) => {
  //             s.forEach(e =>{

  //               if(e.leaveWithdraw.withdrawStatus == 3){
  //                 e.leaveWithdraw.withdrawStatus = "Pending"
  //               }
  //               if(e.leaveWithdraw.withdrawStatus == 4){
  //                 e.leaveWithdraw.withdrawStatus = "Deleted"
  //               }
  //               if(e.leaveWithdraw.withdrawStatus == 5){
  //                 console.log("11111111111111111111111111111111111")

  //                 e.leaveWithdraw.withdrawStatus = "Approved"
  //               }
  //               if(e.leaveWithdraw.withdrawStatus == 6){
  //                 e.leaveWithdraw.withdrawStatus = "Denied"
  //               }
  //               if(e.leaveWithdraw.withdrawStatus == 7){
  //                 e.leaveWithdraw.withdrawStatus = "Withdrawn"
  //               }
  //               if(e.leaveWithdraw.withdrawStatus == 8){
  //                 e.leaveWithdraw.withdrawStatus = "Cancelled"
  //               }
  //             })
  //             this.data = s
  //             this.dataSource = new MatTableDataSource<any>(this.data);
  //             this.dataSource.paginator = this.paginator;
  //             this.dataSource.sort = this.sort;
  //             this.list_count = this.data.length;
  //           }
  //         )
  //       }
  //       s.forEach(e =>{
  //         console.log(e.leaveWithdraw.withdrawStatus)
  //         if(e.leaveWithdraw.withdrawStatus == 3){
  //           e.leaveWithdraw.withdrawStatus = "Pending"
  //         }
  //         if(e.leaveWithdraw.withdrawStatus == 4){
  //           e.leaveWithdraw.withdrawStatus = "Deleted"
  //         }
  //         if(e.leaveWithdraw.withdrawStatus == 5){
  //                           console.log("11111111111111111111111111111111111")

  //           e.leaveWithdraw.withdrawStatus = "Approved"
  //         }
  //         if(e.leaveWithdraw.withdrawStatus == 6){
  //           e.leaveWithdraw.withdrawStatus = "Denied"
  //         }
  //         if(e.leaveWithdraw.withdrawStatus == 7){
  //           e.leaveWithdraw.withdrawStatus = "Withdrawn"
  //         }
  //         if(e.leaveWithdraw.withdrawStatus == 8){
  //           e.leaveWithdraw.withdrawStatus = "Cancelled"
  //         }
  //       })
  //       this.dataSource = new MatTableDataSource<any>(this.data);
  //       this.dataSource.paginator = this.paginator;
  //       this.dataSource.sort = this.sort;
  //       this.list_count = this.data.length;
  //     }
  //   )
  // }
  get_user_data(filter) {
    this.searching = true; 
    this.loader = true;
    this.noData = false;
    this.data = [];
    // this.dataSource = [];
    // this.dataSource = new MatTableDataSource<any>([]);
    // this.list_count = 0;
    // this.errorMessage = '';
    let userData = JSON.parse(localStorage.getItem('enoteUserData'));
    let id = userData.employee.employeeId;
  
    this.leaveservice.getMyleave(id).subscribe(
      (d: any) => {
        
        console.log(d); // Log the received data
        if(d.statusCode == 204){
          this.loader= false;
          this.noData = true;
        //  return
        }

        let data = [];
        data = d;
        console.log(data);
        if(d.statusCode != 204){

          if (filter && filter !== "All") {
          
            this.data = data.filter(item => item.leaveStatusName === filter);
            
            if (this.data.length === 0 ) {

              this.errorMessage = `No ${filter} List Found`; // Set the error message
            } else {
              this.errorMessage = ''; // Clear the error message if data is found
            }
          } else {
            this.data = data;
            this.errorMessage = ''; // Clear the error message when showing all data
          }
    
          // Transform leaveWithdraw status codes to readable strings
          this.data.forEach(item => {
            switch (item.leaveWithdraw.withdrawStatus) {
              case 3:
                item.leaveWithdraw.withdrawStatus = "Pending";
                break;
              case 4:
                item.leaveWithdraw.withdrawStatus = "Deleted";
                break;
              case 5:
                item.leaveWithdraw.withdrawStatus = "Approved";
                break;
              case 6:
                item.leaveWithdraw.withdrawStatus = "Denied";
                break;
              case 7:
                item.leaveWithdraw.withdrawStatus = "Withdrawn";
                break;
              case 8:
                item.leaveWithdraw.withdrawStatus = "Cancelled";
                break;
            }
          });
              
        }
        // Assign data to MatTableDataSource and update pagination and sorting
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
  
        // Update list count
        this.list_count = this.data.length;
      // this.data= [];
      
          this.loader = false;
          this.searching = false; // Re-enable the button
          console.log("this.searching",this.searching);
      },
      error => {
        console.error('Error fetching data:', error);
        this.loader = false; 
        this.searching = false; // Re-enable the button 
        // this.errorMessage = 'Error fetching data'; // Set a generic error message

        // Handle error appropriately, e.g., set error message
      }
    );
  }
  closeSuccessMessage() {
    this.success = false;
    this.success_msg = '';
  }
  closeErrorMessage(){
    this.error = false;
    this.error_msg = '';
  }
}

