<div style="margin-top: 70px" class="card card-content">
  <div>
    <h4 class="heading-tag">Add Task</h4>
  </div>
  <form [formGroup]="Taskform">
    <div class="row">
      <div class="col-md-3">
        <div id="loginform">
          <label class="form-control-label"
            >Select project<span class="text-danger">*</span></label
          >
          <mat-select
            class="form-control email-input select-placeholder"
            formControlName="project"
            placeholder="Select project"
            (click)="onProjectSelectionChange($event)"
            (change)="onProjectSelectionChange($event)"
          >
            <mat-option
              *ngFor="let project of projectList"
              [value]="project?.projectId"
              (click)="projectData(project)"
            >
              {{ project?.projectName }}
            </mat-option>
          </mat-select>
          <div *ngIf="submitted && project.invalid">
            <small *ngIf="project.errors?.required" class="text-danger"
              >Please select project</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div id="loginform">
          <label class="form-control-label"
            >Select Task Group<span class="text-danger">*</span></label
          >
          <mat-select
            class="form-control email-input select-placeholder"
            formControlName="taskgroup"
            placeholder="Select task group"
          >
            <mat-option
              *ngFor="let taskgroup of taskgrouplist"
              [value]="taskgroup?.taskGroupId"
              >{{ taskgroup?.taskGroupName }}
            </mat-option>
          </mat-select>
          <div *ngIf="submitted && taskgroup.invalid">
            <small *ngIf="taskgroup.errors?.required" class="text-danger"
              >Please select task group</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3 add-row">
        <button
          mat-raised-button
          type="submit"
          (click)="addRow()"
          class="searchBtn btn-res mr-3 "
        >
          Add Row
        </button>
      </div>

      <div class="col-md-3 text-right import-btn">
        <button
          mat-raised-button
          type="submit"
          class="searchBtn btn-res"
          (click)="importEmployee()"
        >
          Import
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-border table-responsive">
        <thead class="text-nowrap">
          <th class="">
            Task Name<span class="text-danger">*</span
            ><span class="resizer"></span>
          </th>
          <th>
            Task Description<span class="text-danger">*</span
            ><span class="resizer"></span>
          </th>
          <th class="ml-3">
            Start Date<span class="text-danger">*</span
            ><span class="resizer1"></span>
          </th>
          <th class="" style="margin-left: -23px !important">
            End Date<span class="text-danger">*</span
            ><span class="resizer1"></span>
          </th>

          <th>Action</th>
        </thead>
        <tbody>
          <ng-container  class="col-8"
            formArrayName="tableRows"
            *ngFor="let group of getFormControls.controls; let i = index"
          >
            <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
              <td>
                <div>
                  <input
                    type="text"
                    formControlName="taskname"
                    placeholder="Enter task"
                    maxlength="150"
                    (keyup)="validateTaskname($event, i)"
                    (input)="validateInput($event)"
                    (keydown)="handleKeyPress($event)"
                    class="form-control email-input select-placeholder"
                  />
                  <div
                    *ngIf="
                      (submitted && group['controls'].taskname.invalid) ||
                      group['controls'].taskname.dirty ||
                      spaceValidation
                    "
                  >
                    <small
                      *ngIf="
                        group['controls'].taskname.errors?.required ||
                        spaceValidation
                      "
                      class="text-danger"
                      >Please enter task name</small
                    >
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <textarea
                    type="text"
                    rows="1"
                    formControlName="taskdescription"
                    placeholder="Enter description"
                    maxlength="250"
                    (input)="validateInput($event)"
                    (keydown)="handleKeyPress($event)"
                    class="form-control email-input select-placeholder textarea"
                  ></textarea>
                  <div
                    *ngIf="
                      (submitted &&
                        group['controls'].taskdescription.invalid) ||
                      group['controls'].taskdescription.dirty ||
                      spaceValidation
                    "
                  >
                    <small
                      *ngIf="
                        group['controls'].taskdescription.errors?.required ||
                        spaceValidation
                      "
                      class="text-danger"
                      >Please enter task description</small
                    >
                  </div>
                </div>
              </td>
              <td class="">
                <div class=" ">
                  <input
                    placeholder="Start Date"
                    matInput
                    readonly
                 
                    [disabled]="!Taskform.controls['project'].value"
                    [matDatepicker]="picker1"
                    class="form-control email-input date-input select-placeholder"
                    formControlName="startdate"
                    style="height: 38px; padding: 2px; margin-left: -2px;"
                    type="text"
                    [min]="this.min"
                    [max]="this.max"
                    (dateChange)="updateEndDateMin($event)"
                    id="startDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                          
                    [disabled]="!Taskform.controls['project'].value"
                    style="

                      float: right;
                      position: relative;

                      margin-top: 3px !important;     margin-right: 10px;
                    "
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>

                  <div
                    class="validation"
                    *ngIf="
                      (submitted && group['controls'].startdate.invalid) ||
                      group['controls'].startdate.dirty
                    "
                  >
                    <small
                      *ngIf="group['controls'].startdate.errors?.required"
                      class="text-danger"
                      >Please select start date</small
                    >
                  </div>
                </div>
              </td>

              <td class="">
                <div class="">
                  <!-- <input
                    formControlName="enddate"
                    placeholder="Enter end date"
                    [readonly]="endDate_disable"
                    [disabled]="endDate_disable"
                    [attr.id]="'enddtPicker' + i"
                    type="date"
                    class="form-control email-input select-placeholder dateclass placeholderclass"
                  /> -->

                  <input
                    placeholder="End Date"
                    matInput
                    readonly
                    [matDatepicker]="picker2"
                    class="form-control email-input select-placeholder text-truncate date-input p-0"
                    style="height: 38px; padding: 2px; margin-left: -2px"
                    formControlName="enddate"
                    type="text"
                    [disabled]="!group.get('startdate').value"
                    [min]="this.min"
                    [max]="this.max"
                    id="endDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                    class="date-picker"
                    style="float: right; position: relative; margin-top: 3px !important;     margin-right: 10px;"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
                <div
                  class="validation"
                  *ngIf="
                    (submitted && group['controls'].enddate.invalid) ||
                    group['controls'].enddate.dirty
                  "
                >
                  <small
                    *ngIf="group['controls'].enddate.errors?.required"
                    class="text-danger"
                    >Please select end date</small
                  >
                </div>
              </td>

              <td class="p-0" style="text-align: center;">
                <ng-container *ngIf="i !== 0">
                  <mat-icon class="delete" style="color: red !important;" (click)="deleteRow(i)"
                    >delete</mat-icon
                  >
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="!group.get('isEditable').value">
              <td>
                {{ group.get("taskname").value }}
              </td>
              <td>
                {{ group.get("taskdescription").value }}
              </td>
              <td>
                {{ group.get("startdate").value }}
              </td>
              <td>
                {{ group.get("enddate").value }}
              </td>
              <td>
                {{ group.get("hours").value }}
              </td>
              <td>
                {{ group.get("minutes").value }}
              </td>
              <td>
                <mat-icon class="edit" (click)="editRow(group)">edit</mat-icon>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="action-container">
      <!-- [disabled]="!isFormValid()" -->
      <!-- <button
        mat-raised-button
        class="btn create-button"
        style="float: right; color: white; margin-top: 15px; margin-left: 7px"
        type="submit"
        (click)="resetFormData()"
      >
        Clear
      </button> -->
      <button
        type="button"
        mat-raised-button
        class=""
        (click)="resetFormData()"
        style="
          float: right;
          color: white;
          margin-top: 15px;
          margin-left: 7px;
          background: none;
          color: black;
          border: 1px solid #ddd;
          font-size: 16px;
          font-weight: 610;
          height: 35px;
        "
      >
        Clear
      </button>
      <!-- [disabled]="Taskform.invalid || Taskform.get('tableRows').invalid || submitted" -->

    
      <button
        mat-raised-button
        class="searchBtn"
        style="float: right; color: white; margin-top: 15px"
        type="submit"
        (click)="submitForm()"
        [disabled]="Taskform.invalid "
      >
        Submit
      </button>
    </div>
  </form>
</div>

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
  style="height: 527vh !important"
></div>
<div *ngIf="loader" class="align-items-center text-center">
  <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>

<div class="text-center successUpdate" *ngIf="warning">
  <div class="alert alert-warning toast-msg successUpdate" role="alert">
    <strong>{{ warningMessage }}</strong
    ><br />
  </div>
</div>
