<h3 mat-dialog-title class="headerDesign">
    View Role</h3>
<mat-dialog-content class="mat-typography">
    <span class="role-name form-control-label">
        <span style="font-weight:600">  Role Name : </span>

          {{data.roleId.roleName}}
        </span>
    <span class="role-name form-control-label" *ngIf="data.roleId.roleDescription !=undefined">
         <span style="font-weight:600"> Role Description :</span>
           {{data.roleId.roleDescription}}
        </span>
    <span class="role-name " *ngIf="data.roleId.roleStatus !=undefined">
        <span style="font-weight:600;font-size:16px;">   Role Status :</span>

         {{data.roleId.roleStatus.listTypeValueName}}
        </span>
    <div class="role-name " *ngIf="data.roleId.menu!=undefined">
        <span class="role-name form-control-label">

         Assigned Menus :
        </span>
        <span class="role-menu-names form-control-label" *ngFor="let menus of data.roleId.menu">
         {{menus.menuname}}
      </span>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- <button type="button" mat-button mat-dialog-close class="closeDesign"> Close</button> -->
    <button mat-raised-button  mat-dialog-close color="danger"  class="btn cancel-button" style="color: red;" >
        Close
    </button>
</mat-dialog-actions>