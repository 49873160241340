<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">IF Report</span>
                </div>
                <div class="sub-header-buttons">
                    <a title="Download PDF" target="_blank" class="btn text-danger">
                     
                        <i class="fa fa-file-pdf-o" (click)="printPdf()"></i>
                  
                </a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Client<span class="star"> *</span></label> 
                            <select formControlName="client"   class="form-select form-select-solid form-select-lg select2-new" (change)="getclientdetails($event)">
                                <option [value]=null> --- Select Client --- </option>
                                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                            </select>   
                        </div>
                   
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Financial Year<span class="star"> *</span></label> 
                            <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                <option [value]=null> -- Select Financialyear --- </option>
                                <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option>  -->
                            </select>
                        </div>
                        <div class="form-group col-md-3 ">
                            <label class="col-form-label">From Period<span class="star"> *</span></label> 
                            <select formControlName="fromperiod" class="form-select form-select-solid form-select-lg select2-new"  (change)="selectFinancialfromPeriod($event)"  >
                                <option [value]=null> -- Select From Period --- </option>
                                <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option> 
                            </select>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3 ">
                            <label class="col-form-label">To Period<span class="star"> *</span></label> 
                            <select formControlName="toperiod" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialtoPeriod($event)">
                                <option [value]=null> -- Select To Period --- </option>
                                <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option> 
                            </select>
                        </div>
                        <div class="col-md-3" style="float: right;top: 37px;">
                            <button type="submit" mat-raised-button color="primary"
                                class="btn btn-primary search-btn">Search</button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                class="btn mr-2 search-btn" (click)="clearTable()"
                                >Clear</button>
                        </div>
                    </div>
                </form>
                <div class="row pb-3">
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table  --table-bordered table-border-new">
                        <thead class="t-head">
                            <tr >
                                <th>Period</th>
                                <th>No of Employees</th>
                                <th>Duty Days</th>
                                <th>OT Hours</th>
                                <th>Gross Wages</th>
                                <th>ESI Wages</th>
                                <th>PF</th>
                                <th>ESI</th>                       
                            </tr>
                        </thead>
                        
                        <tbody *ngIf="resultData.length>0">
                            <h2 style="margin: 0 0 16px 16px;">Catagory : Male</h2>
                            <tr *ngFor="let row of categoryMale | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td>{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                <td>{{row.totalEmployees}}</td>
                                <td>{{row.employeeWorkingDays}}</td>
                                <td>{{row.otHours}}</td>
                                <td>{{row.grossWages?.toFixed(2)}}</td>
                                <td>{{row.esiWages?.toFixed(2)}}</td>
                                <td>{{row.employeePf?.toFixed(2)}}</td>
                                <td>{{row.employeeEsi?.toFixed(2)}}</td>
                                <!-- <td>{{calucatenetpay(row.totalEarnings, row.totalDeduction)}}</td> -->
                            </tr>
                           <tr>
                            <td><b>Cat Total</b></td>
                            <td>{{totalmaleemployees}}</td>
                            <td>{{totalmaledutydatys}}</td>
                            <td>{{totalmaleothours}}</td>
                            <td>{{totalmalegrosswages?.toFixed(2)}}</td>
                            <td>{{totalmaleesiWages?.toFixed(2)}}</td>
                            <td>{{totalmaleemployeepf?.toFixed(2)}}</td>
                            <td>{{totalmaleemployeeesi?.toFixed(2)}}</td>
                           </tr>
                        </tbody>
                        <tbody *ngIf="resultData.length>0">
                            <h2 style="margin: 0 0 16px 16px;">Catagory : Female</h2>
                            <tr *ngFor="let row of categoryFemale | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td>{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                <td>{{row.totalEmployees}}</td>
                                <td>{{row.employeeWorkingDays}}</td>
                                <td>{{row.otHours}}</td>
                                <td>{{row.grossWages?.toFixed(2)}}</td>
                                <td>{{row.esiWages?.toFixed(2)}}</td>
                                <td>{{row.employeePf?.toFixed(2)}}</td>
                                <td>{{row.employeeEsi?.toFixed(2)}}</td>
                                <!-- <td>{{calucatenetpay(r   <td>{{row.employeeEsi?.toFixed(2)}}</td>ow.totalEarnings, row.totalDeduction)}}</td> -->
                            </tr>
                            <tr>
                                <td><b>Cat Total</b></td>
                                <td>{{totalfemaleemployees}}</td>
                                <td>{{totalfemaledutydatys}}</td>
                                <td>{{totalfemaleothours}}</td>
                                <td>{{totalfemalegrosswages?.toFixed(2)}}</td>
                                <td>{{totalfemaleesiWages?.toFixed(2)}}</td>
                                <td>{{totalfemaleemployeepf?.toFixed(2)}}</td>
                                <td>{{totalfemaleemployeeesi?.toFixed(2)}}</td>
                               </tr>
                        </tbody>
                        <tbody *ngIf="resultData.length>0">
                           <tr></tr>
                            <tr style="margin-top: 10px;">
                                <td><b>Total</b></td>
                                <td>{{totalmaleemployees + totalfemaleemployees}}</td>
                                <td>{{totalmaledutydatys + totalfemaledutydatys}}</td>
                                <td>{{totalmaleothours + totalfemaleothours}}</td>
                                <td>{{(totalmalegrosswages + totalfemalegrosswages)?.toFixed(2)}}</td>
                                <td>{{(totalmaleesiWages + totalfemaleesiWages)?.toFixed(2)}}</td>
                                <td>{{(totalmaleemployeepf + totalfemaleemployeepf)?.toFixed(2)}}</td>
                                <td>{{(totalmaleemployeeesi + totalfemaleemployeeesi)?.toFixed(2)}}</td>
                               </tr>
                        </tbody>
                    </table>
                    <!-- <div> -->
                        <div *ngIf="noRecordsFound">
                            <p style="text-align: center;" [attr.colspan]="paySlipElementlength">No Records Found!</p>
                        </div> 
                    <!-- </div> -->
                 </div>
                
                <div *ngIf="noRecordsFound == false">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div  id="pdfTable" #pdfTable style="display: none;">   
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table items table-bordered table-condensed table-hover" style="margin: 0;width: 100%;" data-pdfmake="{'widths':['*','*'],'heights':10}">
                                <tr>
                                    <td style="border:0px solid #ccc;padding: 0;" colspan="2">
                                      
                                        <div  style="font-size:15px;font-weight:100;margin:7px 0px 7px 0px;line-height:1;">{{clientName}}</div>
                                        <div class="row" >
                                            <div style="font-size:15px;font-weight:100;margin:7px 0px 7px 0px;line-height:1;" class="col-md-6">
                                                Company: {{clientCode}}
                                            </div>
                                            <div   style="font-size:15px;font-weight:100;margin:7px 0px 7px 0px;line-height:1;text-align: right;"  class="col-md-6">
                                                {{today | date : 'dd-MM-yyyy' }}
                                            </div>
                                        </div>
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: center;border:0px solid #ccc;padding: 0;" colspan="2">
                                        <div *ngIf="resultData!=undefined" style="font-size:18px;font-weight:700;margin:7px 0px 7px 0px;"><b>IF REPORT OF THE YEAR {{period}}</b></div>
                                    </td>
                                </tr>
                            </table>
                            
                   
                            <table  data-pdfmake="{'widths':[120,'*','*','*','*','*','*','*']}" class="table items table-bordered table-condensed table-hover" style="width: 100%;" >
                                <th style="text-align: center;line-height: 2;">Period</th>
                                <th style="text-align: center;">No of Employees</th>
                                <th style="text-align: center;">Duty Days</th>
                                <th style="text-align: center;">OT Hours</th>
                                <th style="text-align: center;">Gross Wages</th>
                                <th style="text-align: center;">ESI Wages</th>
                                <th style="text-align: center;" >PF</th>
                                <th style="text-align: center;">ESI</th>                       

                                <tbody *ngIf="resultData.length>0">
                                    <tr>   </tr>
                                    <td colspan="8"> <!-- Spanning across all columns -->
                                        <P style="margin: 10px 0 10px 10px;">Catagory : MALE</P>
                                      </td>
                                    <tr *ngFor="let row of categoryMale | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                        <td style="text-align: center;" >{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                        <td style="text-align: center;" >{{row.totalEmployees}}</td>
                                        <td style="text-align: center;" >{{row.employeeWorkingDays}}</td>
                                        <td style="text-align: center;" >{{row.otHours}}</td>
                                        <td style="text-align: center;" >{{row.grossWages?.toFixed(2)}}</td>
                                        <td style="text-align: center;" >{{row.esiWages?.toFixed(2)}}</td>
                                        <td style="text-align: center;">{{row.employeePf?.toFixed(2)}}</td>
                                        <td style="text-align: center;">{{row.employeeEsi?.toFixed(2)}}</td>
                                        <!-- <td>{{calucatenetpay(row.totalEarnings, row.totalDeduction)}}</td> -->
                                    </tr>
                                   <tr>
                                    <td style="text-align: center; "  ><b>Cat Total</b></td>
                                    <td style="text-align: center;">{{totalmaleemployees}}</td>
                                    <td style="text-align: center;">{{totalmaledutydatys}}</td>
                                    <td style="text-align: center;">{{totalmaleothours}}</td>
                                    <td style="text-align: center;">{{totalmalegrosswages?.toFixed(2)}}</td>
                                    <td style="text-align: center;">{{totalmaleesiWages?.toFixed(2)}}</td>
                                    <td style="text-align: center;">{{totalmaleemployeepf?.toFixed(2)}}</td>
                                    <td style="text-align: center;">{{totalmaleemployeeesi?.toFixed(2)}}</td>
                                   </tr>
                                </tbody>
                                <tbody *ngIf="resultData.length>0">
                                    <td colspan="8"> <!-- Spanning across all columns -->
                                        <P style="margin: 10px 0 10px 10px;">Catagory : FEMALE</P>
                                      </td>
                                    <!-- <h2 style="margin: 10px 0 10px 10px;">Catagory : FEMALE</h2> -->
                                    <tr *ngFor="let row of categoryFemale | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                        <td style="text-align: center; " >{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                        <td style="text-align: center; " >{{row.totalEmployees}}</td>
                                        <td style="text-align: center; " >{{row.employeeWorkingDays}}</td>
                                        <td style="text-align: center; " >{{row.otHours}}</td>
                                        <td style="text-align: center; " >{{row.grossWages?.toFixed(2)}}</td>
                                        <td style="text-align: center; " >{{row.esiWages?.toFixed(2)}}</td>
                                        <td style="text-align: center; " >{{row.employeePf?.toFixed(2)}}</td>
                                        <td style="text-align: center; " >{{row.employeeEsi?.toFixed(2)}}</td>
                                        <!-- <td>{{calucatenetpay(r   <td>{{row.employeeEsi?.toFixed(2)}}</td>ow.totalEarnings, row.totalDeduction)}}</td> -->
                                    </tr>
                                    <tr>
                                        <td style="text-align: center; " ><b>Cat Total</b></td>
                                        <td style="text-align: center; " >{{totalfemaleemployees}}</td>
                                        <td style="text-align: center; " >{{totalfemaledutydatys}}</td>
                                        <td style="text-align: center; " >{{totalfemaleothours}}</td>
                                        <td style="text-align: center; " >{{totalfemalegrosswages?.toFixed(2)}}</td>
                                        <td style="text-align: center; " >{{totalfemaleesiWages?.toFixed(2)}}</td>
                                        <td style="text-align: center; " >{{totalfemaleemployeepf?.toFixed(2)}}</td>
                                        <td style="text-align: center; " >{{totalfemaleemployeeesi?.toFixed(2)}}</td>
                                       </tr>
                                </tbody>
                            <table style="margin-top:40px;" data-pdfmake="{'widths':[120,'*','*','*','*','*','*','*']}">
                                    <tbody style="margin-top: 40px;"  *ngIf="resultData.length>0">
                                 
                                        <tr style="margin-top: 10px;">
                                            <td style="text-align: center; " ><b>Total</b></td>
                                            <td style="text-align: center; " >{{totalmaleemployees + totalfemaleemployees}}</td>
                                            <td style="text-align: center; " >{{totalmaledutydatys + totalfemaledutydatys}}</td>
                                            <td style="text-align: center; " >{{totalmaleothours + totalfemaleothours}}</td>
                                            <td style="text-align: center; " >{{(totalmalegrosswages + totalfemalegrosswages)?.toFixed(2)}}</td>
                                            <td style="text-align: center; " >{{(totalmaleesiWages + totalfemaleesiWages)?.toFixed(2)}}</td>
                                            <td style="text-align: center; " >{{(totalmaleemployeepf + totalfemaleemployeepf)?.toFixed(2)}}</td>
                                            <td style="text-align: center; "  >{{(totalmaleemployeeesi + totalfemaleemployeeesi)?.toFixed(2)}}</td>
                                           </tr>
                                    </tbody>
                               
                            </table>
                            </table>
                        </div>    
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>