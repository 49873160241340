import { Component, Inject, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { endWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-selected',
  templateUrl: './selected.component.html',
  styleUrls: ['./selected.component.scss']
})
export class SelectedComponent implements OnInit {
  animal: string;
  name: string;
  resourcelistdata:any[];
  p:number =1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  usertype :any;
  userType:any;
  // resourceSearchform:FormGroup;
  positionname:any;
  positionId:any;
  positionlist:any;
  interviewStatuslist:any;
  loader= true;
  backdrop:any= false;
  resorcelistdata:any;
  interviewstatus:any;
  interviewStatusinterviewed:any;
  interviewed:any;
  // data:any;
  false:any;
  true:any;
  Interviewed:any;
  Notinterviewed:any;
  resource:any;
  list_loader = true;
  nodata = false;
  fromdate: string;
  todate: string;
  recruiter: string;
  endDate: string;
  startDate: string;
  length: any;
  user: any;
  orgId: any;
search: any;
titleName: string;

@ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
  orgAddress: any;
  orgName: any;

  constructor(private router:Router,
    private resourceservice:HrmsserviceService,
    private activatedRoute : ActivatedRoute, 
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datepipe : DatePipe

    // @Inject(MAT_DIALOG_DATA) public rejectedtypeId: any,
    // @Inject(MAT_DIALOG_DATA) public onholdtypeId: any,
    ) {}

  ngOnInit(): void {

    console.log("this.data::",this.data);
    
    this.titleName = this.data.titleName || 'Default Title';

    console.log("this.titleName::", this.titleName);
    
      // this.loader=true;
      // this.backdrop=true;
        // clientid
    //  var interviewStatus=28;

    this.user = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("local data::",this.user);

    this.userType = [];
    this.user.employee.role.forEach(role => {
        this.userType.push(role?.roleName);
    });
    
    console.log("rolename",this.userType);
    
    
    this.orgId = this.user.organization.organizationId;
    console.log(this.data);
    this.orgAddress = this.user.organization.address;
    this.orgName = this.user.organization.organizationName;
    console.log(this.data.createdBy);
    console.log(this.data.endDate);
    console.log(this.data.startDate);
    console.log(this.data.interviewstatus);
    this.fromdate = this.datepipe.transform(this.data.startDate, 'yyyy-MM-dd')
      // console.log("fromdate" + this.fromdate);
      this.todate =this.datepipe.transform(this.data.endDate, 'yyyy-MM-dd')
      // console.log("rodate" + this.todate);
      this.recruiter =this.data.createdBy;
      // console.log("reportrecruiter" + this.recruiter);
    //  let fdate = this.fromdate+ " " + "00:00:00";
    //  let tdate   =  this.todate + " " + "23:59:00";
    //  console.log("fromdate" + fdate);
    //  console.log("todate" + tdate);
    
    let start = this.fromdate+ " " + "00:00:00";
    let end   =  this.todate + " " + "23:59:00";
    console.log(start);
    console.log(end);
    this.startDate=start;
    this.endDate=end;
    console.log(this.startDate);
    console.log(this.endDate);
    // let start = this.data.startDate.substring(1, 4);
    // console.log(start);
    // let end = this.data.endDate.substring(1, 4);
    // console.log(end);
    if(this.userType.includes("Admin")){
      if(((this.data.startDate !=  null) &&(this.data.endDate!=  null)&&(this.data.createdBy !=  null)&&(this.data.interviewstatus!=null)))
      {
       console.log("From date and Todate and Recuriter");
       // this.loader=true;
        this.resourceservice
       //  .resourcrreportstartdateandrecruiter(this.startDate, this.endDate,this.data.interviewstatus,this.data.createdBy)
        .resourcrreportstartdateandrecruiterorg(this.startDate, this.endDate,this.data.interviewstatus,this.data.createdBy, this.orgId)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
         //  this.loader=false;
         // this.list_loader = false;
         // this.backdrop=false;
         this.loader= false;
         console.log("loader2",this.loader);
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //    this.nodata = true;
       //   console.log("error");
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
      }
      else if(( this.data.startDate!=  null)&& (this.data.endDate!=  null)&&(this.data.interviewstatus !=  null)&&(this.data.createdBy==null))
       {
         console.log("From date and Todate and Status only");
         // this.loader=true;
        this.resourceservice
       //  .resourcrreportdate(this.startDate, this.endDate,this.data.interviewstatus)
       .resourcrreportdatebyorg(this.startDate, this.endDate,this.data.interviewstatus, this.orgId)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
          this.loader=false;
          console.log("loader2",this.loader);
         // this.list_loader = false;
         // this.backdrop=false;
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //   // this.nodata = true;
       //   console.log("error")
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
       }
       else if((this.data.createdBy!=null)&&(this.data.interviewstatus !=  null)&&(this.data.startDate == null)&& (this.data.endDate== null))
       { 
         // this.loader=true;
       console.log("rejected",this.data);
        this.resourceservice
        .resourcrreportecruiterorg(this.data.createdBy,this.data.interviewstatus, this.orgId)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
          this.loader=false;
          console.log("loader2",this.loader);
         // this.list_loader = false;
         // this.backdrop=false;
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //   // this.nodata = true;
       //   console.log("error")
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
       }
       else 
       { 
         // this.loader=true;
        console.log(this.loader);
       console.log("Both Null overall Api");
       console.log("rejected",this.data);
      //  return;
        this.resourceservice
       //  .interviewstatus(this.data.interviewstatus)
       .resourcebyinterviewstatusandorganization(this.data.interviewstatus, this.orgId)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
         //  this.loader=false;
         // this.list_loader = false;
         // this.backdrop=false;
          this.loader= false;
         console.log("loader2:",this.loader);
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //   // this.nodata = true;
       //   console.log("error");
       //   console.log(error.statusCode);
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
       }
    }else{
      if(((this.data.startDate !=  null) &&(this.data.endDate!=  null)&&(this.data.createdBy !=  null)&&(this.data.interviewstatus!=null)))
      {
       console.log("From date and Todate and Recuriter");
       // this.loader=true;
        this.resourceservice
       //  .resourcrreportstartdateandrecruiter(this.startDate, this.endDate,this.data.interviewstatus,this.data.createdBy)
        .resourcrreportstartdateandrecruiter(this.startDate, this.endDate,this.data.interviewstatus,this.data.createdBy)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
         //  this.loader=false;
         // this.list_loader = false;
         // this.backdrop=false;
         this.loader= false;
         console.log("loader2",this.loader);
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //    this.nodata = true;
       //   console.log("error");
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
      }
      else if(( this.data.startDate!=  null)&& (this.data.endDate!=  null)&&(this.data.interviewstatus !=  null)&&(this.data.createdBy==null))
       {
         console.log("From date and Todate and Status only");
         // this.loader=true;
        this.resourceservice
       //  .resourcrreportdate(this.startDate, this.endDate,this.data.interviewstatus)
       .resourcrreportdate(this.startDate, this.endDate,this.data.interviewstatus)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
          this.loader=false;
          console.log("loader2",this.loader);
         // this.list_loader = false;
         // this.backdrop=false;
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //   // this.nodata = true;
       //   console.log("error")
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
       }
       else if((this.data.createdBy!=null)&&(this.data.interviewstatus !=  null)&&(this.data.startDate == null)&& (this.data.endDate== null))
       { 
         // this.loader=true;
       console.log("rejected",this.data);
        this.resourceservice
        .resourcrreportecruiter(this.data.createdBy,this.data.interviewstatus)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
          this.loader=false;
          console.log("loader2",this.loader);
         // this.list_loader = false;
         // this.backdrop=false;
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //   // this.nodata = true;
       //   console.log("error")
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
       }
       else 
       { 
         // this.loader=true;
        console.log(this.loader);
       console.log("Both Null overall Api");
       console.log("rejected",this.data);
       // return;
        this.resourceservice
       //  .interviewstatus(this.data.interviewstatus)
       .interviewstatus(this.data.interviewstatus)
        .subscribe((resourcelistdata:any) =>
        {
         console.log("loader",this.loader);
         //  this.loader=false;
         // this.list_loader = false;
         // this.backdrop=false;
          this.loader= false;
         console.log("loader2:",this.loader);
         this.resourcelistdata = resourcelistdata;
         this.length=this.resourcelistdata.length;
         console.log(this.length);
         console.log(this.resourcelistdata);
        }
       //  (error) => {
       //   // this.loader= false;
       //   // this.nodata = true;
       //   console.log("error");
       //   console.log(error.statusCode);
       //   if(error.statusCode == 204){
       //     this.nodata = true;
       //     console.log("404")
       //   }
       // }
        ) 
       }
    }
   

  //   if(this.data==30)
  //   {
  //    console.log("onHold",this.data);
  //    this.resourceservice
  //    .interviewstatus(this.data)
  //    .subscribe((resourcelistdata:any) =>
  //    {
  //     console.log("loader");
  //     this.loader=false;
  //     // this.list_loader = false;
  //     // this.backdrop=false;
  //     this.resourcelistdata = resourcelistdata;
  //     console.log(this.resourcelistdata);
  //    },
  //    (error) => {
  //     this.loader= false;
  //     this.nodata = true;
  //     console.log("error")
  //     if(error.status == 404){
  //       this.nodata = true;
  //       console.log("404")
  //     }
  //   }
  //    ) 
  //  }
}
  
showChanges()
{
  console.log("show Change");
  this.p=1;
}

printPdf() {
  const pdfTable = this.pdfTable.nativeElement;
  console.log(pdfTable.innerHTML);
  

  var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
  console.log(pdfTable.innerHTML);
  

  const documentDefinition = {
    content: html,
    pageMargins: [25, 40, 25, 40],
    info: {
      title: this.titleName
    },
    background: function (currentPage, pageCount) {
      console.log('currentPage:', currentPage);
      console.log('pageCount:', pageCount);

      return {
        text: 'Page ' + currentPage.toString(),
        alignment: 'center',
        margin: [0, 8],
        fontSize: 10,
      };
    },
    pageSize: 'A4',
    pageOrientation: 'landscape'
  };
  console.log(pdfTable);
  pdfMake.createPdf(documentDefinition).download(`${this.titleName}.pdf`);
}
}
