import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  redirectMsgTimeOut,
  responseMsgTimeOut,
} from 'src/app/providers/properties';
import { ExcelService } from '../../services/excel.service';
import { Sort } from '@angular/material/sort';
//import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { AppComponent } from '../../app.component';
import { ShareFeedbackFormLinkComponent } from '../resource-feedback/share-feedback-form-link/share-feedback-form-link.component';
import { HrmsService } from 'src/app/providers/hrms.service';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';
import { MatSelectChange } from '@angular/material/select';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent implements OnInit {
  //dateFormat = GlobalConstants.dateFormat;
  selectedRequirement: number | null = null;
  resourcelistdata: any[];
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  usertype: any;
  CandidateName: any;
  CandidateNumber: any;
  CandidateInterview: any;
  CandidateReleventExperience: any;
  CandidateTotalExperience: any;
  CandidateinterviewDate: any;
  CandidateCurrentCtc: any;
  CandidateExpectedCtc: any;
  CandidateNoticePeriod: any;
  CandidateInterviewStatus: any;
  userType: any;
  searchForm: FormGroup;
  positionname: any;
  positionId: any;
  positionlist: any;
  interviewStatuslist: any;
  loader: any = false;
  backdrop: any = false;
  resorcelistdata: any;
  interviewstatus: any;
  interviewStatusinterviewed: any;
  CandidatePosition: any;
  interviewed: any;
  data: any;
  exportLists: any;
  exported: any;
  false: any;
  true: any;
  Interviewed: any;
  Notinterviewed: any;
  resource: any;
  list_loader = true;
  nodata = false;
  pagePerItem: any = 10;
  tableSearch: any;
  error_msg: any;
  error: boolean;
  success: boolean;
  exportingdata: any;
  dataForExcel = [];
  dataExport = [];
  nodatafound: boolean = true;
  sucess_msg: any;
  resultData: any = {};

  sortedData: any[];
  userdata: any;
  excelposition: any;
  excelReq: any;
  resLength: number;
  search: any;
  btnDisables: boolean = false;
  tableShow: boolean = false;
  isAdmin: boolean = false;
  isRecruiter: boolean = false;
  beforesearchpage: number;
  orgId: any;

  constructor(
    private hrmsService: HrmsserviceService,
    private router: Router,
    private headerservice: HeaderService,
    private resourceservice: RecruitmentService,
    private activatedRoute: ActivatedRoute,
    public ExcelService: ExcelService,
    public formBuilder: FormBuilder,
    @Inject(AppComponent) public AppData: any,
    public dialog: MatDialog
  ) {}

  dateFormat: any;
  organizationId: any;
  requrimentlist: any;

  selectedPosition: number | null = null;

  ngOnInit(): void 
  {
    // this.headerservice.setTitle('Candidates')
    this.headerservice.setTitle('');

    this.nodata = true;
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(this.userdata);
    this.orgId=this.userdata.organization.organizationId
    console.log(this.userdata.role);
    this.userType = [];
    this.userdata.employee.role.forEach((role) => {
      this.userType.push(role?.roleName);
    });
    this.isAdmin = this.userType.includes('Admin');
    this.isRecruiter = this.userType.includes('Recruiter');

    console.log('rolename', this.userType);
    this.organizationId = this.userdata.organization.organizationId;
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.searchForm = new FormGroup({
      position: new FormControl(this.searchForm),
      interviewStatus: new FormControl(this.searchForm),
      requirement: new FormControl(this.searchForm),
    });
    this.getPositionList();
    this.getInterviewStatus();
    this.getFilterFormData();
  }

  getFilterFormData() {
    this.searchForm = this.formBuilder.group({
      position: [null],
      interviewStatus: [null],
      requirement: [null],
    });
  }

  getPositionList() {
    this.resourceservice.positionlist(this.orgId).subscribe((positions: any) => {
      this.list_loader = false;
      this.positionlist = positions;
      console.log('this.positionlist::', this.positionlist);

      if (this.positionlist.length == 0) {
        this.nodata = true;
      }
    });
  }
  positionselected(event: any) {
    console.log(event);
    console.log(this.selectedPosition);
    let positionData = {
      value: this.selectedPosition,
      text: this.getPositionName(this.selectedPosition),
    };
    console.log(positionData);
    console.log(positionData.text);
    this.excelposition = positionData.text;

    if (this.selectedPosition == null) {
      this.requrimentlist;
    } else {
      this.hrmsService
        .requrimentbypostion(this.selectedPosition)
        .subscribe((requriments: any) => {
          this.requrimentlist = requriments;
          console.log(this.requrimentlist);
          // console.log(this.requrimentlist.position.positionName);
          // this.excelposition=this.requrimentlist.position.positionName;
        });
    }
  }

  getPositionName(positionId: number): string {
    const selectedPosition = this.positionlist.find(
      (position) => position.positionId === positionId
    );
    return selectedPosition
      ? `${selectedPosition.positionName} - ${selectedPosition.positionNumber}`
      : '';
  }
  requirement1(event) {
    let selectedData = {
      value: this.selectedRequirement,
      text: this.getRequirementNo(this.selectedRequirement),
    };
    console.log(selectedData);
    console.log(selectedData.text);
    this.excelReq = selectedData.text;
  }
  getRequirementNo(requirementId: number): string {
    const selectedRequirement = this.requrimentlist.find(
      (req) => req.requirementId === requirementId
    );
    return selectedRequirement ? selectedRequirement.requirementNo : '';
  }
  getInterviewStatus() {
    this.resourceservice
      .interviewedstatus()
      .subscribe((interviewStatus: any) => {
        this.interviewStatuslist = interviewStatus;
      });
  }

  searchSubmit() {
    this.search = '';
    this.p = 1;
    // this.page=1;
    this.nodata = false;
    // if((this.searchForm.value.position === null || this.searchForm.value.position === 'null') &&
    // (this.searchForm.value.interviewStatus === null || this.searchForm.value.interviewStatus === 'null') &&
    // (this.searchForm.value.requirement === null || this.searchForm.value.requirement === 'null') )
    // {
    //   // alert('Please select from position & interviewStatus.');
    //   // this.nodata = true;
    //   // return;

    //   this.hrmsService
    //   .resourceByStatusAndOrganization(1, this.organizationId)
    //   .subscribe((resourcelistdata:any) =>
    //   {
    //    this.loader=false;
    //    // this.backdrop=false;
    //    this.resourcelistdata = resourcelistdata;
    //    this.resLength=this.resourcelistdata.length;
    //    console.log(this.resLength);
    //    if(this.resourcelistdata.length == 0)
    //    {
    //      this.nodata = true;
    //      console.log(this.resourcelistdata.length);
    //    }
    //    console.log(this.resourcelistdata);
    //   }
    //   )

    // }
    let position = this.searchForm.value.position;
    let interviewStatus = this.searchForm.value.interviewStatus;
    let requriment = this.searchForm.value.requirement;
    console.log(position);
    console.log(interviewStatus);
    console.log(requriment);

    if (
      (position == null || position == undefined) &&
      interviewStatus == null &&
      (requriment == null || requriment == undefined)
    ) {
      alert('Please Choose Any Position or Interview Status to Search');
      this.nodata = true;
      this.btnDisables = false;

      return;
    }

    if (
      position != undefined &&
      interviewStatus == null &&
      requriment == undefined
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.nodata = true;
      this.tableShow = false;

      this.resourcelistdata = [];
      console.log('only posistion calling!!');
      this.resourceservice.position(position).subscribe(
        (resourcelistdata: any) => {
          this.nodata = false;
          this.backdrop = false;
          this.tableShow = true;

          this.resourcelistdata = resourcelistdata;
          this.loader = false;
          this.btnDisables = true;
          // this.resLength=this.resourcelistdata.length;
          // console.log(this.resLength);
          if (this.resourcelistdata.length == 0) {
            this.nodata = true;
            this.btnDisables = false;
            this.tableShow = true;

            this.loader = false;
            console.log(this.resourcelistdata.length);
          }
          console.log(this.resourcelistdata);
          this.btnDisables = false;
        },
        (err) => {
          this.loader = false;
          this.btnDisables = false;
          this.nodata = true;
          this.tableShow = true;

          console.log('error');
        }
      );
    } else if (
      position == undefined &&
      interviewStatus !== null &&
      requriment == undefined
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.nodata = true;
      this.tableShow = false;

      this.resourcelistdata = [];
      console.log('only interview!!');
      this.resourceservice.interviewstatus(interviewStatus).subscribe(
        (resourcelistdata: any) => {
          this.nodata = false;
          this.backdrop = false;
          this.resourcelistdata = resourcelistdata;
          this.loader = false;
          this.btnDisables = true;
          this.tableShow = true;

          //  this.resLength=this.resourcelistdata.length;
          //  console.log(this.resLength);
          if (this.resourcelistdata.length == 0) {
            this.nodata = true;
            this.btnDisables = false;
            this.loader = false;
            this.tableShow = true;

            console.log(this.resourcelistdata.length);
          }
          console.log(this.resourcelistdata);
          this.btnDisables = false;
        },

        (err) => {
          this.loader = false;
          this.btnDisables = false;
          this.nodata = true;
          this.tableShow = true;

          console.log('error');
        }
      );
    } else if (
      position == undefined &&
      interviewStatus == null &&
      requriment != undefined
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.nodata = true;
      this.tableShow = false;

      this.resourcelistdata = [];
      console.log('only requirement');
      this.hrmsService.resourcebyrequriment(requriment).subscribe(
        (resourcelistdata: any) => {
          this.nodata = false;
          this.backdrop = false;
          this.resourcelistdata = resourcelistdata;
          this.loader = false;
          this.tableShow = true;

          this.btnDisables = true;
          // this.resLength=this.resourcelistdata.length;
          // console.log(this.resLength);
          if (this.resourcelistdata.length == 0) {
            this.nodata = true;
            this.btnDisables = false;
            this.loader = false;
            this.tableShow = true;

            console.log(this.resourcelistdata.length);
          }
          console.log(this.resourcelistdata);
          this.btnDisables = false;
        },
        (err) => {
          this.loader = false;
          this.btnDisables = false;
          this.nodata = true;
          this.tableShow = true;

          console.log('error');
        }
      );
    } else if (
      position !== undefined &&
      requriment !== undefined &&
      interviewStatus == undefined
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.nodata = true;
      this.tableShow = false;

      this.resourcelistdata = [];
      console.log('Position and requirement!!!');
      this.hrmsService.positionandrecuritment(position, requriment).subscribe(
        (resourcelistdata: any) => {
          this.nodata = false;
          this.backdrop = false;
          this.resourcelistdata = resourcelistdata;
          this.loader = false;
          this.btnDisables = true;
          this.tableShow = true;

          //  this.resLength=this.resourcelistdata.length;
          //  console.log(this.resLength);
          if (this.resourcelistdata.length == 0) {
            this.nodata = true;
            this.btnDisables = false;
            this.loader = false;
            this.tableShow = true;

            console.log(this.resourcelistdata.length);
          }
          console.log(this.resourcelistdata);
          this.btnDisables = false;
        },
        (err) => {
          this.loader = false;
          this.btnDisables = false;
          this.tableShow = true;

          this.nodata = true;
          console.log('error');
        }
      );
    } else if (
      position !== undefined &&
      requriment == undefined &&
      interviewStatus !== undefined
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.nodata = true;
      this.tableShow = false;

      this.resourcelistdata = [];
      console.log('position and interview status!!!');
      this.resourceservice.searchresource(position, interviewStatus).subscribe(
        (resourcelistdata: any) => {
          this.nodata = false;
          this.backdrop = false;
          this.resourcelistdata = resourcelistdata;
          this.loader = false;
          this.btnDisables = true;
          this.tableShow = true;

          // this.resLength=this.resourcelistdata.length;
          // console.log(this.resLength);
          if (this.resourcelistdata.length == 0) {
            // console.log(this.resourcelistdata.length);
            this.nodata = true;
            this.btnDisables = false;
            this.tableShow = true;

            this.loader = false;
          }
          console.log(this.resourcelistdata);
          this.btnDisables = false;
        },
        (err) => {
          this.loader = false;
          this.btnDisables = false;
          this.tableShow = true;

          this.nodata = true;
          console.log('error');
        }
      );
    } else if (
      position == undefined &&
      requriment !== undefined &&
      interviewStatus !== undefined
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.nodata = true;
      this.tableShow = false;

      this.resourcelistdata = [];
      console.log('Requirement and interview status!!!');
      this.hrmsService
        .requrimentandinterviewstatus(requriment, interviewStatus)
        .subscribe(
          (resourcelistdata: any) => {
            this.nodata = false;
            this.backdrop = false;
            this.resourcelistdata = resourcelistdata;
            this.loader = false;
            this.tableShow = true;

            this.btnDisables = true;
            // this.resLength=this.resourcelistdata.length;
            // console.log(this.resLength);
            if (this.resourcelistdata.length == 0) {
              this.nodata = true;
              this.btnDisables = false;
              this.loader = false;
              this.tableShow = true;

              console.log(this.resourcelistdata.length);
            }
            console.log(this.resourcelistdata);
            this.btnDisables = false;
          },
          (err) => {
            this.loader = false;
            this.btnDisables = false;
            this.nodata = true;
            this.tableShow = true;

            console.log('error');
          }
        );
    } else if (
      position !== undefined &&
      requriment !== undefined &&
      interviewStatus !== undefined
    ) {
      this.btnDisables = true;
      this.loader = true;
      this.nodata = true;
      this.tableShow = false;

      this.resourcelistdata = [];
      console.log('All fields !!!');
      this.hrmsService
        .overallFilter(position, requriment, interviewStatus)
        .subscribe(
          (resourcelistdata: any) => {
            this.nodata = false;
            this.backdrop = false;
            this.resourcelistdata = resourcelistdata;
            this.loader = false;
            this.tableShow = true;

            this.btnDisables = true;
            // this.resLength=this.resourcelistdata.length;
            // console.log(this.resLength);
            if (this.resourcelistdata.length == 0) {
              this.nodata = true;
              this.btnDisables = false;
              this.tableShow = true;

              this.loader = false;
              console.log(this.resourcelistdata.length);
            }
            console.log(this.resourcelistdata);
            this.btnDisables = false;
          },
          (err) => {
            this.loader = false;
            this.btnDisables = false;
            this.nodata = true;
            console.log('error');
            this.tableShow = true;
          }
        );
    }

    //    if ((this.searchForm.value.position === null || this.searchForm.value.position === 'null') &&
    //    this.searchForm.value.interviewStatus != null  &&
    //    (this.searchForm.value.requirement === null || this.searchForm.value.requirement === 'null'))
    //    {
    //     console.log("Interview Status Only");
    //      this.loader=true;
    //      this.btnDisables=true;
    //     this.resourceservice.interviewstatus(interviewStatus).subscribe((resourcelistdata:any) =>
    //     {
    //     //  this.backdrop=false;
    //     this.nodata=false

    //      this.resourcelistdata = resourcelistdata;
    //      this.loader=false;
    //      this.btnDisables=true;

    //     //  this.resLength=this.resourcelistdata.length;
    //     //  console.log(this.resLength);
    //      if(this.resourcelistdata.length == 0)
    //       {
    //           this.nodata = true;
    //           this.btnDisables=false;
    //           this.loader=this.true
    //           console.log(this.resourcelistdata.length);
    //       }
    //      console.log(this.resourcelistdata);
    //     },
    //     // },
    //     (err) =>
    //       {
    //         this.loader=false
    //         this.btnDisables=false
    //         this.nodata = true;
    //         console.log("error");
    //       }
    //     )
    //   }
    //   else if ((this.searchForm.value.position === null || this.searchForm.value.position === 'null') &&
    //     (this.searchForm.value.interviewStatus === null || this.searchForm.value.interviewStatus === 'null')  &&
    //     this.searchForm.value.requirement != null)
    //   {
    //     console.log("Recruirement Only");
    //     this.loader=true;
    //     this.btnDisables=true;

    //     // this.resourcelistdata.length=0;
    //     // this.loader=true;
    //     // this.nodatafound = false;
    //     // this.backdrop=true;
    //       // clientid
    //    this.hrmsService.resourcebyrequriment(requriment).subscribe((resourcelistdata:any) =>
    //    {
    //     // this.backdrop=false;
    //     this.resourcelistdata = resourcelistdata;
    //     this.loader=false;
    //     this.nodata=false
    //     this.btnDisables=true;
    //     // this.resLength=this.resourcelistdata.length;
    //     // console.log(this.resLength);
    //     if(this.resourcelistdata.length == 0)
    //     {
    //       this.nodata = true;
    //       this.btnDisables=false;
    //       this.loader=this.true
    //       console.log(this.resourcelistdata.length);
    //     }
    //     console.log(this.resourcelistdata);
    //    },
    //    (err) =>
    //     {
    //       this.loader=false
    //       this.btnDisables=false
    //       this.nodata = true;
    //       console.log("error");
    //     }

    //    )
    //  }
    //  else if (this.searchForm.value.position != null &&
    //   (this.searchForm.value.interviewStatus === null || this.searchForm.value.interviewStatus === 'null')  &&
    //   (this.searchForm.value.requirement === null || this.searchForm.value.requirement === 'null'))
    //   {
    //     console.log("Position Only");
    //     // this.resourcelistdata.length=0;
    //     this.loader=true;
    //     this.btnDisables=true;
    //     // this.backdrop=true;
    //       //positionname
    //    this.resourceservice.position(position).subscribe((resourcelistdata:any) =>
    //    {
    //     // this.loader=false;
    //     // this.backdrop=false;
    //     this.resourcelistdata = resourcelistdata;
    //        this.loader=false;
    //     this.nodata=false
    //     this.btnDisables=true;

    //     // this.resLength=this.resourcelistdata.length;
    //     // console.log(this.resLength);
    //     if(this.resourcelistdata.length == 0)
    //     {
    //       this.nodata = true;
    //       this.btnDisables=false;
    //       this.loader=true
    //         console.log(this.resourcelistdata.length);
    //     }
    //     console.log(this.resourcelistdata);
    //    },
    //    (err) =>
    //     {
    //       this.loader=false
    //       this.btnDisables=false
    //       this.nodata = true;
    //       console.log("error");
    //     }
    //    )
    //   }
    //   else if ((this.searchForm.value.position === null || this.searchForm.value.position === 'null') &&
    //     this.searchForm.value.interviewStatus != null  &&
    //     this.searchForm.value.requirement != null)
    //   {
    //    console.log("Recuritement and interview Status");
    //    // this.resourcelistdata.length=0;
    //    this.loader=true;
    //     this.btnDisables=true;
    //    //  this.backdrop=true;
    //       // clientid
    //    this.hrmsService.requrimentandinterviewstatus(requriment, interviewStatus).subscribe((resourcelistdata:any) =>
    //    {
    //     this.loader=false;
    //    //  this.backdrop=false;
    //     this.resourcelistdata = resourcelistdata;
    //     this.loader=false;
    //     this.nodata=false
    //     this.btnDisables=true;
    //     // this.resLength=this.resourcelistdata.length;
    //     // console.log(this.resLength);
    //     if(this.resourcelistdata.length == 0)
    //     {
    //       this.nodata = true;
    //       this.btnDisables=false;
    //       this.loader=true
    //               console.log(this.resourcelistdata.length);
    //     }
    //     console.log(this.resourcelistdata);
    //    },
    //    (err) =>
    //     {
    //       this.loader=false
    //       this.btnDisables=false
    //       this.nodata = true;
    //       console.log("error");
    //     }
    //    )
    //  }
    //  else if(this.searchForm.value.position != null &&
    //   this.searchForm.value.interviewStatus != null &&
    //   (this.searchForm.value.requirement === null || this.searchForm.value.requirement === 'null'))
    //  {
    //   console.log("position And Interview Status");
    //   // this.resourcelistdata.length=0;
    //   this.loader=true;
    //   this.btnDisables=true;
    //       // this.backdrop=true;
    //       this.resourceservice.searchresource(position,interviewStatus).subscribe((resourcelistdata:any) =>
    //       {
    //         // this.loader=false;
    //         // this.backdrop=false;
    //         this.resourcelistdata = resourcelistdata;
    //         this.loader=false;
    //         this.nodata=false
    //         this.btnDisables=true;
    //         // this.resLength=this.resourcelistdata.length;
    //         // console.log(this.resLength);
    //         if(this.resourcelistdata.length == 0)
    //           {
    //             // console.log(this.resourcelistdata.length);
    //             this.nodata = true;
    //             this.btnDisables=false;
    //             this.loader=true
    //           }
    //         console.log(this.resourcelistdata);
    //       },
    //       (err) =>
    //         {
    //           this.loader=false
    //           this.btnDisables=false
    //           this.nodata = true;
    //           console.log("error");
    //         }
    //       )
    //   }

    //   else if(this.searchForm.value.position != null &&
    //     this.searchForm.value.requirement != null &&
    //     (this.searchForm.value.interviewStatus === null || this.searchForm.value.interviewStatus === 'null'))
    //   {
    //    console.log("Position And Recuritment");
    //    // this.resourcelistdata.length=0;
    //    this.loader=true;
    //    this.btnDisables=true;
    //        // this.backdrop=true;
    //        this.hrmsService.positionandrecuritment(position,requriment).subscribe((resourcelistdata:any) =>
    //        {
    //          this.loader=false;
    //          // this.backdrop=false;
    //          this.resourcelistdata = resourcelistdata;
    //          this.loader=false;
    //          this.nodata=false
    //          this.btnDisables=true;
    //         //  this.resLength=this.resourcelistdata.length;
    //         //  console.log(this.resLength);
    //          if(this.resourcelistdata.length == 0)
    //            {
    //             this.nodata = true;
    //             this.btnDisables=false;
    //             this.loader=true
    //                            console.log(this.resourcelistdata.length);
    //            }
    //          console.log(this.resourcelistdata);
    //        },
    //        (err) =>
    //         {
    //           this.loader=false
    //           this.btnDisables=false
    //           this.nodata = true;
    //           console.log("error");
    //         }
    //        )
    //    }

    //    else if(this.searchForm.value.position != null && this.searchForm.value.interviewStatus != null && this.searchForm.value.requirement != null)
    //    {
    //         console.log("Both all");
    //         // this.resourcelistdata.length=0;
    //         this.loader=true;
    //         this.btnDisables=true;
    //         // this.backdrop=true;
    //         this.hrmsService.overallFilter(position,requriment,interviewStatus).subscribe((resourcelistdata:any) =>
    //         {
    //           // this.loader=false;
    //           // this.backdrop=false;
    //           this.resourcelistdata = resourcelistdata;
    //           this.loader=false;
    //           this.nodata=false
    //           this.btnDisables=true;
    //           // this.resLength=this.resourcelistdata.length;
    //           // console.log(this.resLength);
    //           if(this.resourcelistdata.length == 0)
    //             {
    //               this.nodata = true;
    //               this.btnDisables=false;
    //               this.loader=true
    //                               console.log(this.resourcelistdata.length);
    //             }
    //           console.log(this.resourcelistdata);
    //         },
    //         (err) =>
    //           {
    //             this.loader=false
    //             this.btnDisables=false
    //             this.nodata = true;
    //             console.log("error");
    //           }
    //         )
    //     }
  }
  // searchSubmit()
  // {
  //   let position = this.searchForm.value.position;
  //   let interviewStatus = this.searchForm.value.interviewStatus;

  //   if((!position || position == 'null') && (!interviewStatus || interviewStatus == 'null') )
  //   {
  //     alert('Please select from position or interviewStatus.');
  //     return;
  //   }

  //   if( (position && position != 'null') && (interviewStatus && interviewStatus != 'null') )
  //   {
  //     this.loader=true;
  //     this.backdrop=true;
  //     this.resourceservice.searchresource(position,interviewStatus).subscribe((resultData:any) =>
  //     {
  //       this.loader=false;
  //       this.backdrop=false;
  //       this.nodatafound = false;

  //       this.resultData = resultData;

  //       if(this.resultData.length == 0 || this.resultData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //       }
  //     })
  //   }
  //   else if ((position && position != 'null') && (!interviewStatus || interviewStatus == 'null') )
  //   {
  //     this.loader=true;
  //     this.backdrop=true;
  //     this.resourceservice.position(position).subscribe((resultData:any) =>
  //     {
  //       this.loader=false;
  //       this.backdrop=false;
  //       this.nodatafound = false;

  //       this.resultData = resultData;
  //       console.log(' this.resultData',  this.resultData)

  //       if(this.resultData.length == 0 || this.resultData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //       }
  //     })
  //   }
  //   else if ((!position || position == 'null') && (interviewStatus && interviewStatus != 'null') )
  //   {
  //     this.loader=true;
  //     this.backdrop=true;
  //     this.resourceservice.interviewstatus(interviewStatus).subscribe((resultData:any) =>
  //     {
  //       this.loader=false;
  //       this.backdrop=false;
  //       this.nodatafound = false;

  //       this.resultData = resultData;

  //       if(this.resultData.length == 0 || this.resultData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //       }
  //     })
  //   }
  // }

  candidateList() {
    let position = this.searchForm.value.position;
    let interviewStatus = this.searchForm.value.interviewStatus;

    if (
      position &&
      position != 'null' &&
      interviewStatus &&
      interviewStatus != 'null'
    ) {
      this.loader = true;
      this.backdrop = true;
      this.resourceservice
        .searchresource(position, interviewStatus)
        .subscribe((resultData: any) => {
          this.loader = false;
          this.backdrop = false;
          this.nodatafound = false;

          this.resultData = resultData;

          if (
            this.resultData.length == 0 ||
            this.resultData.length == undefined
          ) {
            this.nodatafound = true;
          }
        });
    } else if (
      position &&
      position != 'null' &&
      (!interviewStatus || interviewStatus == 'null')
    ) {
      this.loader = true;
      this.backdrop = true;
      this.resourceservice.position(position).subscribe((resultData: any) => {
        this.loader = false;
        this.backdrop = false;
        this.nodatafound = false;

        this.resultData = resultData;

        if (
          this.resultData.length == 0 ||
          this.resultData.length == undefined
        ) {
          this.nodatafound = true;
        }
      });
    } else if (
      (!position || position == 'null') &&
      interviewStatus &&
      interviewStatus != 'null'
    ) {
      this.loader = true;
      this.backdrop = true;
      this.resourceservice
        .interviewstatus(interviewStatus)
        .subscribe((resultData: any) => {
          this.loader = false;
          this.backdrop = false;
          this.nodatafound = false;

          this.resultData = resultData;

          if (
            this.resultData.length == 0 ||
            this.resultData.length == undefined
          ) {
            this.nodatafound = true;
          }
        });
    }
  }

  statuschangeactive(resourceId, status) {
    this.resourceservice
      .resourcestatus(resourceId, status)
      .subscribe((result: any) => {
        console.log(result);
        // if(result.statusCode == 200)
        // {
        //   this.success = true;
        // 	this.sucess_msg = result.description;
        //   setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        // this.candidateList();
        this.searchSubmit();
        // }
        // else
        // {
        //   this.error = true;
        //   this.error_msg = result.description;
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
      });
  }

  statuschangeinactive(resourceId, status) {
    this.resourceservice
      .resourcestatus(resourceId, status)
      .subscribe((result: any) => {
        // if(result.statusCode == 200)
        // {
        //   this.success = true;
        // 	this.sucess_msg = result.description;
        //   setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        //   // this.candidateList();
        this.searchSubmit();
        // }
        // else
        // {
        //   this.error = true;
        //   this.error_msg = result.description;
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
      });
  }

  onKeyUpEvent(event) {
    if (this.query.length != 0) {
      console.log(this.query.length);
      this.p = 1;
      console.log(this.p);
    } else {
      this.p = this.beforepage;
      console.log(this.p);
      console.log(this.beforepage);
    }
  }

  clearfilter() {
    this.searchForm.patchValue({
      position: '',
      interviewStatus: null,
      requirement: null,
    });

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertype.userId;
    let organizationId = this.usertype.organization.organizationId;
    this.usertype = this.usertype.role.roleName;

    this.resourceservice
      .resourcelist(organizationId)
      .subscribe((resorcelistdata: any) => {
        this.resourcelistdata = resorcelistdata;
        console.log(this.resourcelistdata);
      });
  }

  Add_resource() {
    this.router.navigateByUrl('home/resource');
  }

  exportToExcel() {
    console.log(
      'this.searchForm.value.position::',
      this.searchForm.value.position
    );
    console.log(
      'this.searchForm.value.interviewStatus ::',
      this.searchForm.value.interviewStatus
    );

    if (
      this.searchForm.value.position != null &&
      this.searchForm.value.interviewStatus != null
    ) {
      this.dataExport.length = 0;
      this.exportingdata = this.resourcelistdata || [];
      let storeExportingdata = Array.isArray(this.exportingdata)
        ? this.exportingdata
        : [];
      console.log('storeExportingdata::', storeExportingdata);
      console.log(' this.exportingdata', this.exportingdata);

      for (let exportingdata of storeExportingdata) {
        this.exported = {
          CandidateName: exportingdata.resourceName,
          RequirementNumber:exportingdata.requirement.requirementNo || '-',
          CandidateNumber: exportingdata.resourceNumber,
         Position: exportingdata.position.positionName,
       InterviewDate: exportingdata.interviewDate.slice(0, 10),
          TotalExperience: exportingdata.totalExperience,
         ReleventExperience: exportingdata.relevantExperience,
          Interviwed: exportingdata.interviewed ? 'Yes' : 'No',
          CurrentCtc: exportingdata.currentCtc,
        ExpectedCtc: exportingdata.expectedCtc,
         NoticePeriod: exportingdata.noticePeriod.listTypeValueName,
          InterviewStatus:
            exportingdata.interviewStatus.listTypeValueName,
            Status:exportingdata.status.listTypeValueName

        };
        this.dataExport.push(this.exported);
      }
      this.ExcelService.exportAsExcelFile(this.dataExport, 'Export');
    } else {
      this.dataExport.length = 0;
      this.exportingdata = this.resourcelistdata || [];
      let storeExportingdata = Array.isArray(this.exportingdata)
        ? this.exportingdata
        : [];
      console.log('storeExportingdata::', storeExportingdata);
      console.log(' this.exportingdata', this.exportingdata);

      for (let exportingdata of this.exportingdata) {
        this.exported = {
          CandidateName: exportingdata?.resourceName,
          CandidateNumber: exportingdata?.resourceNumber,
          RequirementNumber:exportingdata?.requirement?.requirementNo || '-',
          Position: exportingdata?.position?.positionName,
          InterviewDate: exportingdata?.interviewDate.slice(0, 10),
          TotalExperience: exportingdata?.totalExperience,
          ReleventExperience: exportingdata?.relevantExperience,
        Interviwed: exportingdata.interviewed ? 'Yes' : 'No',
          CurrentCtc: exportingdata?.currentCtc,
          ExpectedCtc: exportingdata?.expectedCtc,
          NoticePeriod: exportingdata?.noticePeriod?.listTypeValueName,
          // CandidateInterviewStatus:
          //   exportingdata?.interviewStatus?.listTypeValueName,
          InterviewStatus: exportingdata?.interviewStatus?.listTypeValueName || '-',
            Status:exportingdata?.status?.listTypeValueName
        };
        this.dataExport.push(this.exported);
      }
      console.log('EX', this.exported);
      this.ExcelService.exportAsExcelFile(this.dataExport, 'Export');
    }
  }

  get position() {
    return this.searchForm.get('position');
  }
  get interviewStatus() {
    return this.searchForm.get('interviewStatus');
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  changepage() {
    console.log(this.resourcelistdata.length);
    if (this.search.length != 0) {
      this.p = 1;
      console.log(this.resourcelistdata.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
      console.log(this.p);
      console.log('success Data');
    } else {
      this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.resourcelistdata.length);
      console.log('nodata');
    }
  }
  showChanges() {
    console.log('show Change');
    this.p = 1;
  }
  sortData(event: Sort) {
    // const data = this.resourcelistdata?.slice();
    // // const data = this.resultData;
    // if (!sort.active || sort.direction === '')
    // {
    //   this.resourcelistdata = data;
    //   return;
    // }

    // this.resourcelistdata = data.sort((a, b) =>
    // {
    //   const isAsc = sort.direction === 'asc';

    //   switch (sort.active)
    //   {
    //     case 'candiateName':
    //       return compare(a.resourceName, b.resourceName, isAsc);

    //     case 'candiateNumber':
    //       return compare(a.resourceNumber, b.resourceNumber, isAsc);
    //       case 'requirement':
    //         return compare(a.requirement?.requirementNo, b.requirement?.requirementNo, isAsc);

    //     case 'positionName':
    //         return compare(a.position.positionName, b.position.positionName, isAsc);

    //     case 'interviewDate':
    //       return compare(a.interviewDate, b.interviewDate, isAsc);

    //     case 'totalExperience':
    //       return compare(a.totalExperience, b.totalExperience, isAsc);

    //     case 'relevantExperience':
    //       return compare(a.relevantExperience, b.relevantExperience, isAsc);

    //     case 'noticePeriod':
    //       return compare(a.noticePeriod.listTypeValueName, b.noticePeriod.listTypeValueName, isAsc);

    //     case 'currentCtc':
    //       return compare(a.currentCtc, b.currentCtc, isAsc);

    //     case 'expectedCtc':
    //       return compare(a.expectedCtc, b.expectedCtc, isAsc);

    //     case 'interviewed':
    //       return compare(a.interviewed, b.interviewed, isAsc);

    //     case 'interviewStatus':
    //       return compare(a.interviewStatus.listTypeValueName, b.interviewStatus.listTypeValueName, isAsc);

    //     default:
    //       return 0;
    //   }
    // });

    const sort = event as unknown as Sort;
    const data = this.resourcelistdata.slice();
    if (!sort.active || sort.direction === '') {
      this.resultData = data;
      return;
    }

    this.resourcelistdata = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'candiateName':
          return compare(a.resourceName, b.resourceName, isAsc);

        case 'candiateNumber':
          return compare(a.resourceNumber, b.resourceNumber, isAsc);
        case 'requirement':
          return compare(
            a.requirement?.requirementNo,
            b.requirement?.requirementNo,
            isAsc
          );

        case 'positionName':
          return compare(
            a.position.positionName,
            b.position.positionName,
            isAsc
          );

        case 'interviewDate':
          return compare(a.interviewDate, b.interviewDate, isAsc);

        case 'totalExperience':
          return compare(a.totalExperience, b.totalExperience, isAsc);

        case 'relevantExperience':
          return compare(a.relevantExperience, b.relevantExperience, isAsc);

        case 'noticePeriod':
          return compare(
            a.noticePeriod.listTypeValueName,
            b.noticePeriod.listTypeValueName,
            isAsc
          );

        case 'currentCtc':
          return compare(a.currentCtc, b.currentCtc, isAsc);

        case 'expectedCtc':
          return compare(a.expectedCtc, b.expectedCtc, isAsc);

        case 'interviewed':
          return compare(a.interviewed, b.interviewed, isAsc);

        case 'interviewStatus':
          return compare(
            a.interviewStatus.listTypeValueName,
            b.interviewStatus.listTypeValueName,
            isAsc
          );

        default:
          return 0;
      }
    });
  }
  get requirement() {
    return this.searchForm.get('requirement');
  }

  clearforms() {
    // this.advanceType=undefined

    this.resourcelistdata = [];
    this.searchForm.reset();
    // this.nodatafound=true
    // this.btnDisable=false
    // this.btnsDisable=false
    // this.loader=false
    this.btnDisables = false;
    this.nodata = true;
    this.tableShow=false
    this.requrimentlist=[]
  }

  openDialog(resourceId): void {
    console.log('resid', resourceId);
    const dialogRef = this.dialog.open(ShareFeedbackFormLinkComponent, {});
    dialogRef.componentInstance.resourceId = resourceId;
  }

  openDialog1(resourceId, feedbackId): void {
    console.log('resid', resourceId);
    console.log('feeid', feedbackId);
    const dialogRef = this.dialog.open(ShareFeedbackFormLinkComponent, {
      panelClass: 'feedback-link-panel',
    });
    dialogRef.componentInstance.resourceId = resourceId;
    dialogRef.componentInstance.feedbackId = feedbackId;
  }
} //Export end

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
