<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <!-- <h3 mat-dialog-title>Add Stream</h3> -->
    <h3>{{qualificationId ? "Update" : "Add"}} Variable Pay Qualifier</h3>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <!-- <div class="form-group col-md-6">
                <label class="col-form-label">Qualification Name <span class="star">*</span></label>
                <input type="text" --(keyup)="checkExistQualificationName($event)" formControlName="qualificationName" id="qualificationName" --[ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="" placeholder="Qualification Name">
                <div class="valid-msg" --*ngIf="(validate.qualificationName.invalid && validate.qualificationName.touched ) || validate.qualificationName.dirty || submitted">
                    <span --*ngIf="validate.qualificationName.errors?.required" class="text-danger">Please enter qualification name</span>
                    <span --*ngIf="departNameExistStatus" class="span-error"> Qualification name already exist!</span>
                </div>
            </div> -->

            <div class="form-group col-md-12">
                <label class="col-form-label">Rating<span class="star">*</span></label>
                <input type="text" (keyup)="checkExistQualificationName($event)" formControlName="rating" id="rating" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" 
                class="form-select form-select-solid form-select-lg" value="" placeholder="Rating" maxlength="4">
                <div class="valid-msg" *ngIf="(validate.rating.invalid && validate.rating.touched ) || validate.rating.dirty || submitted">
                    <span *ngIf="validate.rating.errors?.required" class="text-danger">Please enter Rating</span>
                    <span *ngIf="validate.rating.errors?.pattern" class="text-danger">Please enter valid rating</span>
                    <span *ngIf="qualificationNameExistStatus" class="span-error">Rating already exist!</span>
                </div>
            </div>

            <div class="form-group col-md-12">
                <label class="col-form-label">Percentage<span class="star">*</span></label>
                <input type="text" (keyup)="checkExistQualificationName($event)" formControlName="percentage" id="percentage" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" 
                class="form-select form-select-solid form-select-lg" value="" maxlength="3" placeholder="Percentage">
                <div class="valid-msg" *ngIf="(validate.percentage.invalid && validate.percentage.touched ) || validate.percentage.dirty || submitted">
                    <span *ngIf="validate.percentage.errors?.required" class="text-danger">Please enter percentage</span>
                    <span *ngIf="validate.percentage.errors?.pattern" class="text-danger">Please enter valid percentage</span>
                    <!-- <span *ngIf="qualificationNameExistStatus" class="span-error"> Rating name already exist!</span> -->
                </div>
            </div>

            
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>