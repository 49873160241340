
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <div class="">
                        <span class="h5 sub-content pr-3"  style="    position: relative;
                        top: 6px;
                        color: #181c32;
                        font-weight: 800;
                        font-size: 22px;
                        font-family: 'Mulish', sans-serif;
                        right: 15px;">View Appraisal</span>
                    </div>
                    <!-- <span routerLink="/home/kra-template" class="h5 sub-content">Add Branch</span> -->
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
              <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
                <div class="sub-header-buttons" > 
                    <a style="background-color: #365eef; color:#fff" mat-raised-button  class="ml-2" (click)="openDialog()" *ngIf="appList?.apprisalStatus?.listTypeValueId ===7" >
                      SUBMIT ACCEPTANCE
                    </a> 
                 </div> 
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<form [formGroup]="viewform">
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <div class="row">
                <div class="col-md-3 float-left mb-3 px-0">
                    <label class="col-form-label"  style="font-weight: 500; margin-left: 12px;">Period To:<span class="star"></span></label>
                    <input type="text" formControlName="period" readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" style="margin-left:12px">
                </div>
                <div class=" col-md-9" >
                    <button  type="reset" mat-raised-button  
                    class="btn search-btn" title="Back" style="font-family: Mulish; float:inline-end "
                     (click)="back()">Back</button>
                </div>
            </div>
                <table class="table items  appraisa-form table-bordered table-condensed table-hover product_table">
                    <thead class="appraisalfontbold">
                        <tr>
                            <td class="text-center" style="height:70px; font-size:larger;" colspan="12">
                                APPRAISAL 
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width" colspan="1">
                                EMPLOYEE NAME
                            </td>
                            <td class="tab-medium-width" colspan=""  >
                                <input type="text" formControlName="employeeName" readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select EmployeeName</span>
                                </div>
                            </td>
                            <td class="tab-medium-width" colspan="" >
                                DEPARTMENT
                            </td>
                            <td class="tab-medium-width" colspan="3" >
                                <input type="text" formControlName="department"  readonly=""  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select Department</span>
                                </div>
                            </td> 
                        </tr>
                        <tr>
                            <td class="tab-medium-width" colspan="">
                                EMPLOYEE ID
                            </td>
                            <td class="tab-medium-width" >
                                <input type="text" formControlName="empId"  readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select EmployeeId</span>
                                </div>
                            </td>

                            <td class="tab-medium-width" colspan="">
                                REVIEWER NAME
                            </td>
                            <td class="tab-medium-width" colspan="4">
                                <input type="text" formControlName="reviewerName" readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select ReviewerName</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width" colspan="">
                                POSITION HELD
                            </td>
                            <td class="tab-medium-width" colspan="">
                                <input type="text" formControlName="positionHold" readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select Position Hold</span>
                                </div>
                            </td>

                            <td class="tab-medium-width" colspan="">
                                REVIEWER TITLE
                            </td>
                            <td class="tab-medium-width" colspan="4">
                                <input type="text" formControlName="reviewerTitle" readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select Reviewer Title</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width" colspan="">
                                LAST REVIEW DATE
                            </td>
                            <td class="tab-medium-width" colspan="">
                                <input type="text" formControlName="LastReviewDate" readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select Last Review Date</span>
                                </div>
                            </td>

                            <td class="tab-medium-width" colspan="">
                                CREATED DATE
                            </td>
                            <td class="tab-medium-width" colspan="4">
                                <input type="text" formControlName="createDate" readonly="" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off" placeholder="">
                                <div class="valid-msg">
                                    <span class="text-danger d-none">Please select Created Date</span>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    </table>

                        <table class="table items table-responsive appraisa-form table-bordered table-condensed table-hover product_table">
                        <thead class="appraisalfontbold">
                        <tr>
                            <td class="text-center" style="height:60px; font-size:larger;" colspan="12">
                                CHARACTERISTICS
                            </td>
                        </tr>
                        <tr>
                            <th class="text-left" style="padding-bottom:25px;" rowspan="2">KRA</th>
                            <th class="text-center"  style="padding-bottom:25px;" rowspan="2">Description</th>
                            <th class="text-center" style="padding-bottom:25px;" rowspan="2">Detail</th>
                            <th class="text-center" style="padding-bottom:25px;" rowspan="2">Weightage(%)</th>
                            <th class="text-center"  style="padding-bottom:25px;"colspan="2" style="background: #d2d2e5;">Employee Rating</th>
                            <th class="text-center"  style="padding-bottom:25px;"colspan="2" style="background:#FFD580;">Approver Rating</th>
                            <th class="text-center"  style="padding-bottom:25px;"rowspan="2">Approver Comments</th>
                            <th class="text-center"   style="padding-bottom:25px;"rowspan="2">Employee Comments</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="background: #d2d2e5;">Rating(Out Of 5)</th>
                            <th class="text-center" style="background: #d2d2e5;">Rating</th>
                            <th class="text-center" style="background: #FFD580;">Rating(Out Of 5)</th>
                            <th class="text-center" style="background: #FFD580;">Rating</th>
                        </tr>
                        <!-- <tr>
                            <th class="text-center" style="background: #d2d2e5;">Rating(Out Of 5)</th>
                            <th class="text-center" style="background: #d2d2e5;">Rating</th>
                        </tr> -->
                    </thead>
                    <tbody >
                        <ng-container *ngFor="let kraline of kraLine().controls; let i = index" formArrayName="kra">
                            <tr [formGroupName]="i">
                                        <td class="tab-maxfull-width">
                                            {{ kraline.get('criteriaName').value }}
                                        </td>
                                         <td class="tab-maxfull-width">
                                             <textarea class="form-control" readonly="" formControlName="criteriaDescription" (click)="openDescription(i)"  required=""  rows="2" ></textarea>
                                         </td>
             
                                         <td class="tab-maxfull-width">
                                             <textarea class="form-control"  readonly="" formControlName="criteriaDetail" (click)="openDetail(i)"  required=""  rows="2"></textarea>
                                         </td>
                                         <td class="tab-medium-width"> 
                                             <input type="number" readonly="" formControlName="weightage"  class="form-control text-center" value="{{kraline.get('weightage').value}}">
                                         </td>
                                         <td class="tab-medium-width" style="background: #d2d2e5;">
                                            <select formControlName="empRating"    class="form-control -searchDropdown"  [attr.disabled]="'disabled'">
                                                <option value="">- Rating -</option>
                                                <option value="5">5 - Excellent</option>
                                                <option value="4">4 - Very Good</option>
                                                <option value="3">3 - Good</option>
                                                <option value="2">2 - Weak</option>
                                                <option value="1">1 - Unacceptable</option>
                                              </select>
                                         </td>
                                         <td class="tab-medium-width" style="background: #d2d2e5;">
                                             <input type="number" formControlName="rating" readonly  class="form-control text-center" [attr.disabled]="'disabled'">
                                         </td>
                                        <td class="tab-medium-width" style="background: #FFD580;">
                                                <select  formControlName="approverRating"    class="form-control -searchDropdown" [attr.disabled]="'disabled'">
                                                   <option value="">- Rating -</option>
                                                   <option value="5">5 - Excellent</option>
                                                   <option value="4">4 - Very Good</option>
                                                   <option value="3">3 - Good</option>
                                                   <option value="2">2 - Weak</option>
                                                   <option value="1">1 - Unacceptable</option>
                                               </select>
                                        </td>
                                        <td class="tab-medium-width" style="background: #FFD580;">
                                                    <input type="number" formControlName="apprating"  class="form-control text-center" [attr.disabled]="'disabled'">
                                        </td>
                                        <td class="tab-maxfull-width">
                                                <textarea class="form-control"  formControlName="approverComments"  readonly></textarea>
                                        </td>
                                          
                                        <!-- <td class="tab-maxfull-width">
                                            <textarea class="form-control"  formControlName="approverComments" ></textarea>
                                            <mat-error  *ngIf="submitted || KraLineFormArray.controls[i].get('approverComments')?.touched && KraLineFormArray.controls[i].get('approverComments')?.invalid">
                                                <span
                                                    *ngIf="KraLineFormArray.controls[i].get('approverComments')?.hasError('required')">Please Enter Approver Comments.
                                                </span>
                                            </mat-error>
                                        </td> -->
                                        <!-- <td class="tab-maxfull-width">
                                            <textarea class="form-control"  formControlName="approverComments" ></textarea>
                                            <mat-error  *ngIf="submitted || KraLineFormArray.controls[i].get('approverComments')?.touched && KraLineFormArray.controls[i].get('approverComments')?.invalid">
                                                <span
                                                    *ngIf="KraLineFormArray.controls[i].get('approverComments')?.hasError('required')">Please Enter Approver Comments.
                                                </span>
                                            </mat-error>
                                        </td> -->

                                        <td class="tab-maxfull-width">
                                            <textarea    class="form-control"  formControlName="employeeComments"  [readonly]="appList.apprisalStatus.listTypeValueId !==7" required (keydown)="handleKeyPress($event)" (input)="validateInput($event)" ></textarea>
                                            <!-- <div *ngIf="viewform.get('employeeComments').invalid && viewform.get('employeeComments').touched" class="text-danger">
                                                Employee Comments is required
                                              </div> -->
                                              <!-- <mat-error  *ngIf="submitted || KraLineFormArray.controls[i].get('employeeComments')?.touched && KraLineFormArray.controls[i].get('employeeComments')?.invalid">
                                                <span
                                                    *ngIf="KraLineFormArray.controls[i].get('employeeComments')?.hasError('required')">Please Enter Employee Comments.
                                                </span>
                                               </mat-error> -->
                                               <mat-error *ngIf="appList.apprisalStatus.listTypeValueId === 7 && (submitted || KraLineFormArray.controls[i].get('employeeComments')?.touched && KraLineFormArray.controls[i].get('employeeComments')?.invalid)">
                                                <span *ngIf="KraLineFormArray.controls[i].get('employeeComments')?.hasError('required')">Please Enter Employee Comments.</span>
                                            </mat-error>
                                        </td>
                                </tr>
                        </ng-container>
                    </tbody>
                </table>
                <table class="table items table-responsive appraisa-form table-bordered table-condensed table-hover">
                    <tbody>
                        <!-- OVER ALL RATING -->
                        <tr >
                            <td class="tab-maxfull-width appraisalfontbold" colspan="">
                                EMPLOYEE RATING
                            </td>
                            <td class="tab-maxfull-width text-center">
                                <input type="text"  formControlName="emprating"  readonly class="form-control text-center" value="">
                            </td>
                        </tr>
                        <tr >
                                <td class="tab-maxfull-width appraisalfontbold" colspan="">
                                    APPROVER RATING
                                </td>
                                <td class="tab-maxfull-width text-center">
                                    <input type="text"  formControlName="apprating"  readonly  class="form-control text-center" value="">
                                </td>
                        </tr>
                        <tr >
                            <td class="tab-maxfull-width appraisalfontbold" colspan="">
                                FINAL RATING
                            </td>
                                <td class="tab-maxfull-width text-center">
                                    <input type="text" formControlName="finalrating" readonly  class="form-control text-center" value="">
                                </td>
                        </tr>
                        <tr >
                            <td class="tab-medium-width">Comment / Query:</td>
                            <!-- <ng-container *ngIf="manage; else notManagecommant"> -->
                                <td class="tab-maxfull-width text-center">
                                    <textarea name="managementquery"  formControlName="managementquery" readonly class="form-control" rows="2"></textarea>
                                </td>
                            <!-- /</ng-container> -->
                            <!-- <ng-template  #notManagecommant>
                                <td class="tab-maxfull-width text-center">
                                    <textarea name="managementquery"  formControlName="managementquery" readonly  class="form-control" rows="2"></textarea>
                                </td>
                            </ng-template> -->
                        </tr>
                        <tr>
                            <td class="tab-medium-width appraisalfontbold">
                                Total Hours Worked <span class="text-danger">*</span>
                            </td>
                            <td class="tab-medium-width" colspan="">
                                <input type="number" formControlName="hoursWorked" readonly=""  name="total_hours_worked"  id="total_hours_worked" class="form-control text-center" value="" [min]="minHours">
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width appraisalfontbold" colspan="">
                                Leave Days <span class="text-danger">*</span>
                            </td>
                            <td class="tab-medium-width">
                                <input type="number"  formControlName="leaveDays" readonly=""  id="leave_days" name="leave_days" class="form-control text-center" value="" [min]="minDays">
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width">Employee Comment / Query:</td>
                            <td class="tab-medium-width text-center" colspan="">
                                <textarea name="employee_query"  formControlName="empquery"  readonly=""  id="employee_query" class="form-control" rows="2"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td class="tab-medium-width">Upload Timesheet Document <span class="text-danger">*</span></td>
                            <td class="tab-medium-width text-center" colspan="">
                                <!-- <input type="file" name="attachment" id="attachment"  class="form-control"> -->
                                <!-- <button type="button" class="btn btn-primary">
                                    <mat-icon class="material-symbols-outlined downlaodicon">
                                        download
                                    </mat-icon>
                                    Download
                                </button> -->
                                <!-- <button type="button" class="btn btn-primary">
                                    <mat-icon class="material-symbols-outlined downlaodicon">download</mat-icon>
                                    <span class="button-text">Download</span>
                                </button> -->
                                <button type="button" class="btn btn-primary" (click) ="downloadfiles(dowUrl)">
                                    <div class="button-content">
                                      <!-- <mat-icon class="material-symbols-outlined downlaodicon">download</mat-icon> -->
                                      <i class="fa fa-download" style="color:#fff;"></i>
                                      <span class="button-text ml-2">Download</span>
                                    </div>
                                  </button>
                                  
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- <div class="row">
                    <div class="col-md-12 text-right" *ngIf="this.show||this.manage">
                        <a routerLink="/home/appraisal" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2">
                            Cancel
                        </a>
                        <button type="submit" mat-raised-button color="primary" (click)="submit()">Save</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border"
        style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;bottom: 0;"
        role="status"></div>
</div>
</form>

