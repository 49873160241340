import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ViewManageApproversComponent } from '../approvers/view-manage-approvers/view-manage-approvers.component';
import { LoanDetailsComponent } from '../loan-details/loan-details.component';
import { AdvanceApproverStatusComponent } from '../advance-approver-status/advance-approver-status.component';

@Component({
  selector: 'app-loan-manage-approvals',
  templateUrl: './loan-manage-approvals.component.html',
  styleUrls: ['./loan-manage-approvals.component.scss']
})
export class LoanManageApprovalsComponent implements OnInit {
  approvallist:any=[];
  employeedata:any=[];
  p: number = 1;
  public searchFilter: any = '';
  beforepage :any;
  mySelect:any =5;
  paymentData:any;
  paymentName:any;
  employeeId:any;
  loader:any= false;  
  list_loader = true;
  nodata = false;
  organizationId:any;
  organization:any;
  empId:any;
  approverempId:any;
  success:any=false;
  error:any=false;
  today: any;
  listData:any =[];
  organizationSettings: any;

  constructor(private router:Router,
    private gs:GlobalserviceService,
    private dialog:MatDialog,
    private datepipe:DatePipe) { }

  ngOnInit(): void {
    let localdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(localdata);
    this.organizationId = localdata.organization.organizationId;
    console.log(this.organizationId);
    this.empId = localdata.employee.employeeId;
    console.log(this.empId);
    let today =  new Date();
    console.log(today);
    this.today =this.datepipe.transform(today, 'yyyy-MM-dd');
    this.gs.getOrganizationdetails(this.organizationId).subscribe(
      (data:any)=>{
        this.organizationSettings=data;
        console.log("this.organizationSettings",this.organizationSettings);
      }
    )

    this.gs.getManageLoanlistByStatus(this.empId).subscribe(
      (empData: any) => {
        this.employeedata = empData;
        console.log(this.employeedata);
        this.nodata = false;
        this.list_loader = false;
        if(empData.length == 0)
        {
          this.list_loader = false;
          this.nodata = true;
          console.log(this.nodata);
        }
        if(empData.statusCode == 204){
          this.nodata = true;
          console.log("nodata");
          this.list_loader = false;
        }
        this.employeedata.map(
          y => {
            if(y.approval.approver.fyi == this.empId ){
              this.nodata = false;
              y['fyistatus'] = true;
              this.approvallist.push(y);    
              console.log("approver fyi list",this.approvallist);  
            }
            if(y.approval.approver.approverOne == this.empId && y.approval.approverOneStatus.listTypeValueId == 3
              && y.approval.approver.approverOne) {
              this.nodata = false;
              this.approvallist.push(y); 
              console.log("approver 1 list",this.approvallist);
            }
            if (y.approval.approver.approverTwo == this.empId && y.approval.approverOneStatus.listTypeValueId == 5
              && y.approval.approverTwoStatus.listTypeValueId == 3) {
              this.nodata = false;
              this.approvallist.push(y);
              console.log("approver 2 list",this.approvallist);
            }
            if (y.approval.approver.approverThree == this.empId && y.approval.approverOneStatus.listTypeValueId == 5
              && y.approval.approverTwoStatus.listTypeValueId == 5 && y.approval.approverThreeStatus.listTypeValueId == 3) {
                this.nodata = false;
              this.approvallist.push(y);
              console.log(this.approvallist);
              console.log("approver 3 list",this.approvallist);
            }
            if (y.approval.approver.approverFour == this.empId && y.approval.approverOneStatus.listTypeValueId == 5
              && y.approval.approverTwoStatus.listTypeValueId == 5 && y.approval.approverThreeStatus.listTypeValueId == 5
              && y.approval.approverFourStatus.listTypeValueId == 3) {
                this.nodata = false;
              this.approvallist.push(y);
              console.log("approver 4 list",this.approvallist);
            }
            if (y.approval.approver.approverFive == this.empId && y.approval.approverOneStatus.listTypeValueId == 5
              && y.approval.approverTwoStatus.listTypeValueId == 5 && y.approval.approverThreeStatus.listTypeValueId == 5
              && y.approval.approverFourStatus.listTypeValueId == 5 && y.approval.approverFiveStatus.listTypeValueId == 3) {
                this.nodata = false;
              this.approvallist.push(y);
              console.log("approver 5 list",this.approvallist);
            }
            this.approvallist = this.approvallist.reduce((accumalator, current) => {
              // console.log(accumalator);
              // console.log(current);
              if (!accumalator.some(item => item === current)) {
                accumalator.push(current);
              }
              return accumalator;
            }, []);

            if(this.approvallist.length == 0){
              this.nodata = true;
              console.log("NO_DATA");
            }
          }
        )
      },
      (error) => {
        this.list_loader = false;
        this.nodata = true;
        console.log("error")
        if(error.status == 404){
          this.nodata = true;
          console.log("404");
        }
      }
    )
  }
  

  openDialog(SalaryAdvanceId){
    console.log("open")
     this.dialog.open(LoanDetailsComponent, {
        width: '800px',
        height:'fit-content',
        data:SalaryAdvanceId
    });
  }
  viewapproverstatus(approvaldata:any){
    console.log(approvaldata);
    this.dialog.open(AdvanceApproverStatusComponent,{
      width: '500px',
      height:'fit-content',
      data :approvaldata
    })
  }

  approve(i){
      console.log(i);
      this.list_loader = true;
      
      i['organization']={
        organizationId :this.organizationId
      }
      let count = 0
      if(i.approval.approver.approverOne){
        count = count+1
      }
      if(i.approval.approver.approverTwo){
        count = count+1
      }
      if(i.approval.approver.approverThree){
        count = count+1
      }
      if(i.approval.approver.approverFour){
        count = count+1
      }
      if(i.approval.approver.approverFive){
        count = count+1
      }
    
      if (i.approval.approver.approverOne == this.empId && i.approval.approverOneStatus.listTypeValueId == 3) {
        i.approval.approverOneStatus.listTypeValueId = 5
        i.approval.approverlevel = 1
        console.log(i.approval.approverOneStatus.listTypeValueId);
      }
      if (i.approval.approver.approverTwo == this.empId && i.approval.approverOneStatus.listTypeValueId == 5
        && i.approval.approverTwoStatus.listTypeValueId == 3) {
          i.approval.approverTwoStatus.listTypeValueId = 5
          i.approval.approverlevel = 2
      }
      if (i.approval.approver.approverThree == this.empId && i.approval.approverOneStatus.listTypeValueId == 5
        && i.approval.approverTwoStatus.listTypeValueId == 5 && i.approval.approverThreeStatus.listTypeValueId == 3) {
          i.approval.approverThreeStatus.listTypeValueId = 5
          i.approval.approverlevel = 3
      }
      if (i.approval.approver.approverFour == this.empId && i.approval.approverOneStatus.listTypeValueId == 5
        && i.approval.approverTwoStatus.listTypeValueId == 5 && i.approval.approverThreeStatus.listTypeValueId == 5
        && i.approval.approverFourStatus.listTypeValueId == 3) {
          i.approval.approverFourStatus.listTypeValueId = 5
          i.approval.approverlevel = 4
      }
      if (i.approval.approver.approverFive == this.empId && i.approval.approverOneStatus.listTypeValueId == 5
        && i.approval.approverTwoStatus.listTypeValueId == 5 && i.approval.approverThreeStatus.listTypeValueId == 5
        && i.approval.approverFourStatus.listTypeValueId == 5 && i.approval.approverFiveStatus.listTypeValueId == 3) {
          i.approval.approverFiveStatus.listTypeValueId = 5
          i.approval.approvalStatus.listTypeValueId = 5
          i.approval.approverlevel = 5
      }

      console.log("count",count);
      if(count == 1 && i.approval.approverOneStatus.listTypeValueId == 5){
        i.approval.approvalStatus.listTypeValueId = 5
      }
      if(count == 2 && i.approval.approverOneStatus.listTypeValueId == 5 && i.approval.approverTwoStatus.listTypeValueId == 5){
        i.approval.approvalStatus.listTypeValueId = 5
      }
      if(count == 3 && i.approval.approverOneStatus.listTypeValueId == 5 && i.approval.approverTwoStatus.listTypeValueId == 5
        && i.approval.approverThreeStatus.listTypeValueId == 5){
        i.approval.approvalStatus.listTypeValueId = 5
      }
      if(count == 4 && i.approval.approverOneStatus.listTypeValueId == 5 && i.approval.approverTwoStatus.listTypeValueId == 5
        && i.approval.approverThreeStatus.listTypeValueId == 5 && i.approval.approverFourStatus.listTypeValueId == 5){
        i.approval.approvalStatus.listTypeValueId = 5
      }
      if(count == 5 && i.approval.approverOneStatus.listTypeValueId == 5 && i.approval.approverTwoStatus.listTypeValueId == 5
        && i.approval.approverThreeStatus.listTypeValueId == 5 && i.approval.approverFourStatus.listTypeValueId == 5 
        && i.approval.approverFiveStatus.listTypeValueId == 5){
        i.approval.approvalStatus.listTypeValueId = 5
      } 
      console.log(i);  
      this.gs
    .updateLoanStatus(i.employeeLoanId,i).subscribe(
      (data:any) => {
      this.list_loader = false;
      this.success = true;
      setTimeout(() => {
      //  return;
       window.location.reload();
      this.success = false;
      }, 2000)
 
    },
    (error) => {
      this.list_loader = false;
      this.error = true;
      console.log("error");
      setTimeout(() => {
        this.error = false;
      }, 2000)
    }
    ) 
  }

  deny(i)
  {
    console.log(i);
      this.list_loader = true;
      i['organization']={
        organizationId :this.organizationId
      }

      if(i.approval.approver.approverOne == this.empId && i.approval.approverOneStatus.listTypeValueId == 3){
        i.approval.approverOneStatus.listTypeValueId = 6
        console.log("approverone status");
        console.log(i.approval.approverOneStatus.listTypeValueId);
        i.approval.approvalStatus.listTypeValueId = 6
        i.approval.approverlevel = 1
      }
      if(i.approval.approver.approverTwo == this.empId && i.approval.approverOneStatus.listTypeValueId == 5){
        i.approval.approverTwoStatus.listTypeValueId = 6
        console.log("approverTwoStatus");
        console.log(i.approval.approverTwoStatus.listTypeValueId);
        i.approval.approvalStatus.listTypeValueId = 6
        i.approval.approverlevel = 2
      }
      if(i.approval.approver.approverThree == this.empId && i.approval.approverOneStatus.listTypeValueId == 5 && i.approval.approverTwoStatus.listTypeValueId == 5){
        i.approval.approverThreeStatus.listTypeValueId = 6
        console.log("approverThreeStatus");
        console.log(i.approval.approverThreeStatus.listTypeValueId);
        i.approval.approvalStatus.listTypeValueId = 6
        i.approval.approverlevel = 3
      }
      if(i.approval.approver.approverFour == this.empId && i.approval.approverOneStatus.listTypeValueId == 5 && i.approval.approverTwoStatus.listTypeValueId == 5 
       && i.approval.approverThreeStatus.listTypeValueId == 5){
        i.approval.approverFourStatus.listTypeValueId = 6
        console.log("approverFourStatus");
        console.log(i.approval.approverFourStatus.listTypeValueId);
        i.approval.approvalStatus.listTypeValueId = 6
        i.approval.approverlevel = 4
      }
      if(i.approval.approver.approverFive == this.empId && i.approval.approverOneStatus.listTypeValueId == 5 && i.approval.approverTwoStatus.listTypeValueId == 5 
        && i.approval.approverThreeStatus.listTypeValueId == 5 && i.approval.approverFourStatus.listTypeValueId == 5){
          i.approval.approverFiveStatus.listTypeValueId = 6
          console.log("approverFiveStatus");
          console.log(i.approval.approverFiveStatus.listTypeValueId);
          i.approval.approvalStatus.listTypeValueId = 6
          i.approval.approverlevel = 5
      }
      console.log(i);
      this.gs
    .updateLoanStatus(i.employeeLoanId,i)
    .subscribe((data:any) =>
    {
      this.list_loader = false;
      this.success = true;
      
      setTimeout(() => {
        window.location.reload();
        this.success = false;
      }, 2000)
       
    },
    (error) => {
      this.list_loader = false;
      this.error = true;
      console.log("error");
      setTimeout(() => {
        this.error = false;
      }, 2000)
    }
    ) 
  }
  
  sortData(sort: Sort) {
    const data = this.approvallist.slice();
    if (!sort.active || sort.direction === '') {
      this.approvallist = data;
      return;
    }

    this.approvallist = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employeeName':
          return this.compare(a.employee.employeeName, b.employee.employeeName, isAsc);
        case 'employeeId':
          return this.compare(a.employee.employeeId, b.employee.employeeId, isAsc);
        case 'organizationEmail':
          return this.compare(a.employee.organizationEmail, b.employee.organizationEmail, isAsc);
        case 'subTotal':
          return this.compare(a.subTotal, b.subTotal, isAsc);
        case 'cashAdvance':
          return this.compare(a.cashAdvance, b.cashAdvance, isAsc);
        case 'totalReimbursement':
          return this.compare(a.totalReimbursement, b.totalReimbursement, isAsc);
        case 'listTypeValueName':
          return this.compare(a.approval.approvalStatus.listTypeValueName, b.approval.approvalStatus.listTypeValueName, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}


