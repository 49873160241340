import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function nonZeroHoursMinutesValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hours = control.get('hours')?.value;
    const minutes = control.get('minutes')?.value;

    if (hours === 0 || minutes === 0) {
        alert('Both Hours and minutes should not be zero')
      return { 'nonZeroHoursMinutes': true };
    }

    return null;
  };
}


