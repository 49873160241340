<div
  style="margin-top: 80px; margin-left: 10px; margin-right: 10px"
  class="card card-content"
  [ngClass]="{'min-height': selectedType === 'organization' || selectedType === 'client'}"
>
  <h4 class="heading-tag">Manage Client Employee Transfer</h4>
  <div class="row justify-content-between">
    <div class="col-md-4">
      <div id="loginform">
        <div class="col-md-12 col-xl-6 select">
          <label class="form-control-label">
            Select&nbsp;Organization&nbsp;/&nbsp;Client
          </label>
          <mat-select
            (selectionChange)="selected($event)"
            class="form-control email-input assign-placeholder"
            placeholder="Select Organization/Client"
          >
            <mat-option
              [value]="undefined"
              selected="selected"
              (click)="clearAllForm()"
            >
              -Select Organization/Client-
            </mat-option>
            <mat-option value="organization">Organization</mat-option>
            <mat-option value="client">Client</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedType === 'organization'" class="mt-3">

    <app-select-org-emp-transfer></app-select-org-emp-transfer>

  </div>

  <div *ngIf="selectedType === 'client'" class="mt-3 client">

    <app-empassignment></app-empassignment>
  </div>
</div>
