import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { SuperadminService } from '../providers/superadmin.service';
import { EmployeeService } from '../providers/employee.service';
import {ActivatedRoute } from "@angular/router"
import { PayrollService } from '../providers/payroll.service'; 
import { SettingsService } from '../providers/settings.service';
import { ReturnStatement } from '@angular/compiler';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import *  as htmlToPdfmake  from "html-to-pdfmake";
import { statSync } from 'fs';
import { index } from 'mathjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-employee-payroll-detail',
  templateUrl: './employee-payroll-detail.component.html',
  styleUrls: ['./employee-payroll-detail.component.scss']
})
export class EmployeePayrollDetailComponent implements OnInit {

@ViewChild('pdfTable')
pdfTable!: ElementRef;

  usertData: any;
  dateFormat: any;
  orgId: any;
  clientId:any;
  loaders:boolean=true;
  backdrop: boolean;
  resultData: any = {};
  fileblob: string;
  payrollId: any;
  orgdata: any = {};
  organizationSettings: any;
  earningdata: any = [];
  deductionsdata: any= [];
  totalctc : number = 0;
  totalamount :number = 0;
  totalEarningYtd :number = 0;
  totalDedutionYtd :number = 0;
  totaldetectionsctc : number = 0;
  totaldetectionsamount :number = 0;
  elementlist: any;
  orgcompliancedata: any = [];

  constructor( @Inject(AppComponent) public AppData: any , private superAminservice : SuperadminService , 
   private EmployeeService : EmployeeService ,  private ActivatedRoute:ActivatedRoute , private payrollService : PayrollService ,
   private settingsService : SettingsService,
   private datepipe  : DatePipe) { }

  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.clientId = this.usertData?.employee?.client?.clientId;
   // console.log(this.orgId);
    this.payrollId =  this.ActivatedRoute.snapshot.params.payrollheaderId;
    this.loaders=true;


    if(this.orgId){
    this.settingsService.getOrganizationdetails(this.orgId).subscribe((resultData: any) => {
     // console.log(resultData);
      this.organizationSettings = resultData;
      // this.loaders=false;
});
    }
    
    if(this.clientId){
      this.settingsService.getClientdetails(this.clientId).subscribe((resultData: any) => {
       // console.log(resultData);
        this.organizationSettings = resultData;
        // this.loaders=false;
  });
      }

if(this.orgId){
  this.payrollService.getorgcompactivebyorganziationId(this.orgId).subscribe((data: any) => {
      // this.loaders=false;
      if (data.length != undefined) {
        this.orgcompliancedata = data;
      }
      
    this.payrollService.getpayrollbyId(this.payrollId ).subscribe((resultData:any) =>
    {
      // this.loaders=false;
      this.backdrop=false;

      this.resultData=resultData;
       this.earningdata =   this.resultData.payrollLine.filter(payrollline=> payrollline.hrmspaySlipElement.categoryType.listTypeValueId == 42);
       this.deductionsdata =   this.resultData.payrollLine.filter(payrollline=> payrollline.hrmspaySlipElement.categoryType.listTypeValueId == 43);
     // console.log("ORG DETAIL : ",this.resultData);
     console.log("deductionsdata",this.deductionsdata);
        let  esiindex;
      this.deductionsdata.forEach((element , index) => {
        if(this.orgcompliancedata.length !=0   && element.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYEE ESI") )
        {        
             console.log( "employeeEsiDeduct" + this.orgcompliancedata[0].employeeEsiDeduct) ;
             if(!this.orgcompliancedata[0].employeeEsiDeduct){
              esiindex = index;
             }
        }
      });
      console.log("esiindex" +esiindex) ;
                  
                if(esiindex != -1)
                {
                  console.log("splice") ;
                  this.deductionsdata.splice(esiindex , 1);
                }
        
                console.log(this.deductionsdata) ;

                console.log("deductionsdata",this.deductionsdata);
                // return
 
      this.payrollService.getelements(this.orgId).subscribe((result: any) => {
        if(result.statusCode)
        {
         this.elementlist.length = 0;
        }else{
         console.log("else")
         this.loaders=false;
         this.elementlist = result;
         console.log("this.elementlist",this.elementlist);
           this.elementlist.forEach(element => {
              if(element.categoryType.listTypeValueId == 42)
              {
                if(!this.earningdata.some(e => e.hrmspaySlipElement.elementId == element.elementId))
                {
                       let data = {
                        ytdDouble: 0,
                        amountDouble: 0,
                        hrmspaySlipElement: element,
                        ctcReferenceDouble : 0
                       }

                       this.earningdata.push(data);
                }
              }
              else if(element.categoryType.listTypeValueId == 43)
              {
                 console.log("",element);
                 console.log("deductionsdata",this.deductionsdata);
                 console.log(!element.elementName.toUpperCase().includes("EMPLOYER"));
                 console.log(this.deductionsdata.some(e => e.hrmspaySlipElement.elementId), "element.elementId",element.elementId)
                 console.log("elementId exit",this.deductionsdata.some(e => e.hrmspaySlipElement.elementId == element.elementId));
                //  return
               
                if(!this.deductionsdata.some(e => e.hrmspaySlipElement.elementId == element.elementId ))
                {
                  console.log("push elemt");
                      if(!element.elementName.toUpperCase().includes("EMPLOYER"))
                      {
                       console.log("element name : " + element.elementName.toUpperCase());
                        let data = {
                          ytdDouble: 0,
                          amountDouble: 0,
                          hrmspaySlipElement: element,
                          ctcReferenceDouble : 0
                         }
  
                         this.deductionsdata.push(data);
                      }
                       
                }
                console.log("this.deductionsdata", this.deductionsdata);
              }
           });
        
        }
     
     })

      this.calucatectc();
    
    });
  });
  console.log("this.deductionsdata", this.deductionsdata);
}


if(this.clientId){
  this.payrollService.getorgcompactivebyClientId(this.clientId).subscribe((data: any) => {
      // this.loaders=false;
      if (data.length != undefined) {
        this.orgcompliancedata = data;
      }
      
    this.payrollService.getpayrollbyId(this.payrollId ).subscribe((resultData:any) =>
    {
      // this.loaders=false;
      this.backdrop=false;

      this.resultData=resultData;
       this.earningdata =   this.resultData.payrollLine.filter(payrollline=> payrollline.hrmspaySlipElement.categoryType.listTypeValueId == 42);
       this.deductionsdata =   this.resultData.payrollLine.filter(payrollline=> payrollline.hrmspaySlipElement.categoryType.listTypeValueId == 43);
     // console.log("ORG DETAIL : ",this.resultData);
        let  esiindex;
      this.deductionsdata.forEach((element , index) => {
        if(this.orgcompliancedata.length !=0   && element.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYEE ESI") )
        {        
             console.log( "employeeEsiDeduct" + this.orgcompliancedata[0].employeeEsiDeduct) ;
             if(!this.orgcompliancedata[0].employeeEsiDeduct){
              esiindex = index;
             }
        }
      });
      console.log("esiindex" +esiindex) ;
                  
                if(esiindex != -1)
                {
                  console.log("splice") ;
                  this.deductionsdata.splice(esiindex , 1);
                }
        
                console.log(this.deductionsdata) ;


 
      this.payrollService.getelementsByClient(this.clientId).subscribe((result: any) => {
        if(result.statusCode)
        {
         this.elementlist.length = 0;
        }else{
         // console.log("else")
         this.loaders=false;
         this.elementlist = result;
           this.elementlist.forEach(element => {
              if(element.categoryType.listTypeValueId == 42)
              {
                if(!this.earningdata.some(e => e.hrmspaySlipElement.elementId == element.elementId))
                {
                       let data = {
                        ytdDouble: 0,
                        amountDouble: 0,
                        hrmspaySlipElement: element,
                        ctcReferenceDouble : 0
                       }

                       this.earningdata.push(data);
                }
              }
              else if(element.categoryType.listTypeValueId == 43)
              {
             
                 // console.log(! element.elementName.toUpperCase().includes("EMPLOYER"));
               
                if(!this.deductionsdata.some(e => e.hrmspaySlipElement.elementId == element.elementId ))
                {
                      if(!element.elementName.toUpperCase().includes("EMPLOYER"))
                      {
                       // console.log("element name : " + element.elementName.toUpperCase());
                        let data = {
                          ytdDouble: 0,
                          amountDouble: 0,
                          hrmspaySlipElement: element,
                          ctcReferenceDouble : 0
                         }
  
                         this.deductionsdata.push(data);
                      }
                       
                }
              }
           });
        
        }
     
     })

      this.calucatectc();
    
    });
  });
}


     
if(this.orgId){
    this.superAminservice.getOrganizationById(this.orgId ).subscribe((resultData:any) =>
    {
      this.backdrop=false;

      this.orgdata=resultData;
      // this.loaders=false;

     // console.log("ORG DETAIL : ",this.orgdata);

      if(resultData.organizationAttachment)
      {
        this.EmployeeService.getemployeeImage(resultData.organizationAttachment.fileViewUrl).subscribe(
          blob => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;

          },
          err => 
          {
            this.fileblob= "assets/uploads/no-image.png"
          }
        );
      }
      else
      {
        this.fileblob= "assets/uploads/no-image.png"
      }
    });
  }
  if(this.clientId){
    this.superAminservice.getClientById(this.clientId ).subscribe((resultData:any) =>
    {
      this.backdrop=false;

      this.orgdata=resultData;
      // this.loaders=false;

     // console.log("ORG DETAIL : ",this.orgdata);

      if(resultData.organizationAttachment)
      {
        this.EmployeeService.getemployeeImage(resultData.organizationAttachment.fileViewUrl).subscribe(
          blob => {
            const a = document.createElement('a')
            const objectUrl: any = blob
            this.fileblob = blob;

          },
          err => 
          {
            this.fileblob= "assets/uploads/no-image.png"
          }
        );
      }
      else
      {
        this.fileblob= "assets/uploads/no-image.png"
      }
    });
  }
  }



  calucatectc()
  {
    
    this.earningdata.forEach(element => {
      this.totalctc += element.ctcReferenceDouble; 
  });
  this.deductionsdata.forEach(element => {
   
     if(!element.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYER"))
     {

      this.totaldetectionsctc += element.ctcReferenceDouble; 
     // console.log(" element.amount" +  element.amountDouble);
          this.totaldetectionsamount += element.amountDouble;
     }
 
   });
  }

  calucatetotalamount(totalAmount ,totaldetectionsamount)
  {
    return Math.round(totalAmount -  totaldetectionsamount)  ;
  }

  inWords (num) {
    let a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
    let  b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
    
      if ((num = num.toString()).length > 9) 
      {
        return 'overflow';
      }
      let n=[];
       n= ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) {
        return ""; 
      }
      var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Only ' : '';
        if(!str.includes("Only"))
        {
          str =  str + 'Only';
        }
            return str;
  }

  public downloadAsPDF() {
   // console.log("download");
    const pdfTable = this.pdfTable.nativeElement;

   // console.log(pdfTable);


    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize:true,
    });
    
    const documentDefinition = {
       content: html , 
       pageMargins: [ 40, 40, 40, 150],
        imagesByReference:true ,
       info: {
         title:`Pay Roll`
         },

         footer: function (currentPage, pageCount) {
          return {
            table: {
              body: [
                [ 
                  { 
                    text: 'Employee Signature', alignment: 'left', fontSize: 10,
                    margin: [40, 40, 0, 0]
                    // pageMargins: [50, 0, 0, 0]
                  },
                  { 
                    text: 'Date', alignment: 'right', fontSize: 10,
                    margin: [350, 40, 0, 0]
                    // pageMargins: [0, 0, 100, 0]
                  },
                ],
                [
                  // 'Approver Signature',
                  // { 
                  //   text: 'Date', alignment: 'right', fontSize: 9,
                  //   pageMargins: [700, 0, 300, 0]
                  // },

                  { 
                    text: 'Approver Signature', alignment: 'left', fontSize: 10,
                    margin: [40, 40, 0, 0]
                    // pageMargins: [50, 0, 0, 0]
                  },
                  { 
                    text: 'Date', alignment: 'right', fontSize: 10,
                    margin: [350, 40, 0, 0]
                    // pageMargins: [500, 0, 100, 0]
                  },
                ],
              ]
            },
            layout: {
              defaultBorder: false,
            },
            // layout: 'noBorders'
          };
        },
        pageSize: 'A4',
        };
    // console.log(documentDefinition);
    pdfMake.createPdf(documentDefinition).download(`Pay Roll.pdf`); 
     
  }


  printpdf()
  {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { content: html ,
      pageMargins: [ 40, 40, 40, 40 ],
      info: {
        title:`Pay Roll`
        },
     
        pageSize: 'A4',
        pageOrientation : 'landscape'
    };

   // console.log(documentDefinition);
    pdfMake.createPdf(documentDefinition).open(); 
  }


  // printpdf(){
  //     console.log("printpdf");
  //     const pdfTable = this.pdfTable.nativeElement;
  //     var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
  //     this.payrollService.getpayrollbyId(this.payrollId).subscribe(
  //       (data:any)=>{
  //         console.log(data);
  //         this.resultData=data;
  //         const formattedDate = this.datepipe.transform(this.resultData.createDateTime, this.dateFormat);
  //         const documentDefinition = { 
  //         content: html ,
  //         // image: this.fileblob, // Use the dataURL here
  //         // width: 200,
  //         // height: 100,
  //         pageMargins: [ 40, 40, 40, 35 ],
  //         info: {
  //           title:`Report ${formattedDate}`
  //           },
  //           pageSize: 'A4',
  //           pageOrientation: 'landscape',
            
  //         };
  //        pdfMake.createPdf(documentDefinition).open(); 
  //       })
  //   }


  

  Roundof(amount){
    return Math.round(amount);
  }

}
