
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { AppComponent } from 'src/app/app.component';
import { SettingsService } from 'src/app/providers/settings.service';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
// import * as pdfMake from "pdfmake/build/pdfmake";
import { DatePipe } from '@angular/common';
import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Stream } from 'stream';
import { ExportExcelService} from 'src/app/services/export-excel.service';
import { SuperadminService } from 'src/app/providers/superadmin.service';
import { EmployeeComponent } from 'src/app/employeeComponents/employee/employee.component';
import { EmployeeService } from 'src/app/providers/employee.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-clientemployee-conslidatepayslip',
  templateUrl: './clientemployee-conslidatepayslip.component.html',
  styleUrls: ['./clientemployee-conslidatepayslip.component.scss']
})
export class ClientemployeeConslidatepayslipComponent implements OnInit {

  filterForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =true;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any =[];
  finPeriodList: any=[];
  chooseFinId: number;
  ratingId: any;
  req: boolean=false;
  finEmpList: any;
  eleNameList: any;
  categoryId: number;
  categoryTypeData: any;
  categoryName: any;
  employeePFval: number=0;
  totalPF: number=0;
  employeerPF: number=0;
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  excelData: any;
  dataForExcel = []; 
  headerForExcel = [
    {'Client Code':'' ,'Client Name':'' , 'Client Address':'' , 'Employee Code':'','Employee Name':'', 'Father Name':'', 'Account Name':'','Account Number':'',
     'IFSC Code':'','Bank Name':'','Branch Name':'','Address':'', 'Date of Birth':'','Date of Joining':'', 'PF Number':'',  'ESI Number':'',  'UAN Number':'',  'Period':'','Client Working Days':'','Employee Working Days':'','OT Hours':'',
   }
  ];
  orgdata: any;
  fileblob: string;
  period: string ="";
  paySlipElement: any;
  Amount =0;
  employeeName: string;
  fromPeriod: any;
  toperiod: any;
  fPeriod: any;
  tPeriod: any;
  paySlipElementlength: number ;
  selectedEmployeeData: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  empCode: boolean;
  totalDeduction: number;
  totalNetpay: number;
  
  constructor(
    private fb:FormBuilder,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    private dialog:MatDialog,
    private router:Router,
    public ExportExcelService: ExportExcelService,
    @Inject(AppComponent) public AppData: any ,
    private superAminservice : SuperadminService ,  
    private EmployeeService : EmployeeService,
    private gs :GlobalserviceService
  ) { }
  ngOnInit(): void {
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
   
    this.filterForm = this.fb.group({
      employeecode:[null,[Validators.required]],
      financialyear:[null,],
      fromperiod:[null,],
      toperiod:[null,],
      client:[null,],
    })
    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe(
      (resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });

    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
    

    
    this.gs.getpaySlipElementByOrganaization(this.orgId).subscribe(
      (data:any)=>{
        this.paySlipElement = data;
        this.paySlipElementlength =  this.paySlipElement.length + 9;
        console.log("table headers count",this.paySlipElementlength)
        console.log("paySlipElement",this.paySlipElement);
        const customOrder = ['Basic DA','HRA'];
        this.paySlipElement.sort((a, b) => {
          const aIndex = customOrder.indexOf(a.elementName);
          const bIndex = customOrder.indexOf(b.elementName);
          if (aIndex === -1 && bIndex === -1) {
            return 0; // Both elements are not in customOrder, maintain their relative order
          } else if (aIndex === -1) {
            return 1; // Only 'a' is not in customOrder, move it to the end
          } else if (bIndex === -1) {
            return -1; // Only 'b' is not in customOrder, move it to the end
          }
          return aIndex - bIndex; // Compare their positions in customOrder
        });


        console.log(this.paySlipElement);
        this.paySlipElement.map(  (e) => {
              e['amount'] =0;
        })
      }
    )
  }

  clearTable()
  {
    this.resultData.length = 0;
  }

  searchSubmit(){
    console.log("Filter",this.filterForm.value);


      if(this.filterForm.value.client == null && this.filterForm.value.employeecode == null && this.filterForm.value.financialyear===null && this.filterForm.value.fromperiod===null && this.filterForm.value.toperiod===null){

           alert("Please select requried fields")
         return;
       }

       if( (this.filterForm.value.fromperiod !== null && this.filterForm.value.toperiod===null )|| (this.filterForm.value.fromperiod === null && this.filterForm.value.toperiod !==null ) ){
        alert("Please select from and to period")
      return;
    }


     this.loader = true;
      if(this.filterForm.value.employeecode && this.filterForm.value.financialyear===null && this.filterForm.value.fromperiod===null && this.filterForm.value.toperiod===null)
      {
        this.gs.getConsoldatePayslipByemployeeId(this.employeeId).subscribe(
          (data:any)=>{
            console.log(data);
            this.resultData = data;
            this.loader = false;
            if(this.resultData.length !=undefined){
              console.log("if");
              this.noRecordsFound = false;
              this.calculate();
              let lastindex = this.resultData.length -1;
              this.period = this.resultData[lastindex].finanicalPeriod.financialperiodMonth + " " + this.resultData[lastindex].finanicalPeriod.financialPeriodYear
              + " to "  + this.resultData[0].finanicalPeriod.financialperiodMonth + " " + this.resultData[0].finanicalPeriod.financialPeriodYear
              this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode  +" " + this.resultData[0].employee.firstName
            }
            else{
              this.noRecordsFound = true;
            }
          }
        )
      }else if(this.filterForm.value.employeecode && this.filterForm.value.financialyear!== null && this.filterForm.value.fromperiod===null && this.filterForm.value.toperiod===null)
      {
        this.gs.getConsoldatePayslipByemployeeIdandfinicalyear(this.employeeId ,this.filterForm.value.financialyear).subscribe(
          (data:any)=>{
            console.log(data);
            this.resultData = data;
            this.loader = false;
            if(this.resultData.length == 0 || this.resultData.length == undefined || this.resultData.statusCode == 204 )
            {
              this.noRecordsFound = true;
            }else{
              this.noRecordsFound = false;
              this.calculate();
              let lastindex = this.resultData.length -1;
              this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear
              + " to "  + this.resultData[0].finanicalPeriod.finanicalYear.financialToMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialToYear
    
               this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode
            }
          }
        )
      }else if(this.filterForm.value.employeecode && this.filterForm.value.financialyear!== null && this.filterForm.value.fromperiod !==null && this.filterForm.value.toperiod !==null)
      {
        this.gs.getConsoldatePayslipByemployeeIdandfinicalyearandpriod(this.employeeId ,this.filterForm.value.financialyear,this.filterForm.value.fromperiod ,this.filterForm.value.toperiod ).subscribe(
          (data:any)=>{
            console.log(data);
            this.resultData = data;
            this.loader = false;
            if(this.resultData.length !=undefined)
            {
              this.calculate();
              this.noRecordsFound = false;
              this.fromPeriod=this.filterForm.value.fromperiod;
              this.fromPeriodFunction(this.fromPeriod);
              this.toperiod=this.filterForm.value.toperiod;
              this.toPeriodFunction(this.toperiod);
              this.period = this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
              + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear

              this.employeeName =  "Employee : " +" "+ this.filterForm.value.employeecode
            }
            else{
              this.noRecordsFound = true;
            }
          

          }
          
        )
      }
      else if(!this.filterForm.value.employeecode && this.filterForm.value.financialyear!== null && this.filterForm.value.fromperiod ===null && this.filterForm.value.toperiod ===null)
      {
        this.gs.getConsoldatePayslipByfinicalyear(this.filterForm.value.financialyear).subscribe(
          (data:any)=>{
            console.log(data);
            this.resultData = data;
            this.loader = false;
            if(this.resultData.length !=undefined || this.resultData.statusCode == 204 )
            {
              this.calculate();
              this.noRecordsFound = false;
              this.period = this.resultData[0].finanicalPeriod.finanicalYear.financialFromMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialFromYear
              + " to "  + this.resultData[0].finanicalPeriod.finanicalYear.financialToMonth + " " + this.resultData[0].finanicalPeriod.finanicalYear.financialToYear
              this.employeeName = "";
            }
            else{
              this.noRecordsFound = true;
            }
          }
        )
      } else if(!this.filterForm.value.employeecode && this.filterForm.value.client == null   && this.filterForm.value.financialyear!== null && this.filterForm.value.fromperiod !=null && this.filterForm.value.toperiod !=null)
      {
        this.gs.getConsoldatePayslipByfinicalyearandperiod(this.filterForm.value.financialyear , this.filterForm.value.fromperiod , this.filterForm.value.toperiod).subscribe(
          (data:any)=>{
            console.log(data);
            this.resultData = data;
            this.loader = false;
              
            if(this.resultData.length !=undefined || this.resultData.statusCode == 204 )
            {
              this.calculate();
              this.noRecordsFound = false;
              this.fromPeriod=this.filterForm.value.fromperiod;
              this.fromPeriodFunction(this.fromPeriod);
              this.toperiod=this.filterForm.value.toperiod;
              this.toPeriodFunction(this.toperiod);
              this.period = this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
              + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
            }
            else{
              this.noRecordsFound = true;
            }
          }
        )
      }else if(this.filterForm.value.employeecode == null && this.filterForm.value.client != null  && this.filterForm.value.financialyear!== null && this.filterForm.value.fromperiod !=null && this.filterForm.value.toperiod !=null)
      {
        this.gs.getConsoldatePayslipByclient( this.filterForm.value.client, this.filterForm.value.financialyear , this.filterForm.value.fromperiod , this.filterForm.value.toperiod).subscribe(
          (data:any)=>{
            console.log(data);
            this.resultData = data;
           // this.roundofdata();
           this.loader = false;
            if(this.resultData.length !=undefined || this.resultData.statusCode == 204 )
            {
              this.calculate();
              this.noRecordsFound = false;
              this.fromPeriod=this.filterForm.value.fromperiod;
              this.fromPeriodFunction(this.fromPeriod);
              this.toperiod=this.filterForm.value.toperiod;
              this.toPeriodFunction(this.toperiod);
              this.period = this.fPeriod.financialperiodMonth + "-" + this.fPeriod.financialPeriodYear
              + " to "  + this.tPeriod.financialperiodMonth  + "-" + this.tPeriod.financialPeriodYear
              this.employeeName =  "Client : " +" "+ this.resultData[0].client.clientCode
            }
            else{
              this.noRecordsFound = true;
            }
          }
        )
      }
  }
  fromPeriodFunction(fromPer: any) {
    console.log(fromPer);
    this.fPeriod=[];
    this.finPeriodList.forEach(element => {
      console.log(element);
      if(element.financialPeriodId==fromPer)
      {
       this.fPeriod=element;
       console.log(this.fPeriod);
      }
   });
  }
  toPeriodFunction(toPer: any) {
    console.log(toPer);
    this.tPeriod=[];
    this.finPeriodList.forEach(element => {
      console.log(element);
      if(element.financialPeriodId==toPer)
      {
        this.tPeriod=element;
        console.log(this.tPeriod);
      }
   });
  }
  calculate() {

    this.paySlipElement.forEach(e =>{
      e['amount'] =0;
    });
    this.totalDeduction = 0;
    this.totalNetpay = 0;
    this.resultData.forEach(element => {
      this.paySlipElement.forEach(e =>{
        element.payrollLine.forEach(payrollLine => {
            if(payrollLine.hrmspaySlipElement.elementId == e.elementId){
                let Amount  =  e.amount+= payrollLine.amount;
                console.log( e.elementName  + Amount);
              e['amount'] =Amount;
            }
        })
      
      });
      this.totalDeduction +=element.totalDeduction;
      this.totalNetpay +=   element.totalEarnings - element.totalDeduction;

      // this.totalDeduction += Math.round(element.totalDeduction);
      // this.totalNetpay +=   Math.round(element.totalEarnings) -Math.round(element.totalDeduction);
    })

    // this.paySlipElement.push({amount : totalDeduction});
    // this.paySlipElement.push({amount : totalNetpay})
    // console.log(this.paySlipElement);
  }

  empSearch(event)
  { 
    this.empList=[];
    this.empCode=false;
    console.log(event.target.value);
    this.userId=event.target.value;
    console.log(this.userId);
    // Employee List Api
      // this.empList.length == 0;
      if(event.target.value.length != 0)
      {
        this.payrollservice.getClienEmployeeList(this.userId,this.filterForm.value.client).subscribe((result: any) => {
          if(result.statusCode==204)
                {
                  console.log('NO Data');
                  this.empList.length = 0;
                  this.empCode=true;
                }
                else
                {
                  this.empList = result;
                  this.empCode=false;
                  console.log(this.empList);
                }
        })
      }else{
        this.filterForm.patchValue({
          employeecode: null
       });
      }
  
  }
  
  empSelect(emp) {
    // this.selectedEmployeeData=[];
    console.log(emp);
    // this.empList=[];
    this.employeeId = emp.employeeId;
    this.firstname=emp.firstName;
    this.lastname=emp.lastName;
    this.empnumber=emp.employeeNumber;

  
   
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;

    console.log(this.employee);
    
      this.filterForm.patchValue({
        employeecode: this.employee
     });
  
    // }
  
  }

  roundofdata()
  {
    this.resultData.map(  (e) => {
      e.payrollLine.map((payelement)=> {
        payelement.amount = Math.round(payelement.amount);
      }) 
   })

  }
  
  pageChages(){
    console.log("page changes");
    this.p=1;
  }
  selectFinancialyear(event){
    console.log("Select");
    console.log(event.target.value);
    // console.log(this.FilterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    
    if(event.target.value == 'null')
    {
      this.filterForm.patchValue({
      
        financialyear:null,
      
      })
    }else{
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
    });
  }
  }
  selectFinancialfromPeriod(event)
  {
    if(event.target.value == 'null')
    {
      this.filterForm.patchValue({
      
        fromperiod:null,
      
      })
  }
}
selectFinancialtoPeriod(event)
{
  if(event.target.value == 'null')
  {
    this.filterForm.patchValue({
    
      toperiod:null,
    
    })
}
}

calucatenetpay(earnings,deductions)
{
   let netpay = earnings - deductions;

  return  netpay?.toFixed(2);
}
 
  public downloadAsExcel(){
      let clientcode = "" ;
    this.dataForExcel.length = 0;
    let totaldaysofwages = 0;
    let totalOtHours = 0;
    this.excelData=this.resultData;
    console.log(this.excelData);
    for(let e of this.excelData){
      let data= {
          clientCode : e.client.clientCode,
          clientName : e.client.clientName,
          clientAddress : e.client.addressLine1 + " "+ e.client.addressLine2 +" "+ e.client.city.cityName + "-" + e.client.postalCode,
          employeeNumber:e.employee.employeeNumber,
          employeeName:e.employee.firstName,
          fatherName : e.employee.lastName,
          accountName:e.employee.employeeInfo.accountName,
          accountNumber:e.employee.employeeInfo.bankAccountNo,
          IfscCode:e.employee.employeeInfo.ifscCode,
          BankName:e.employee.employeeInfo.bankName,
          BranchName:e.employee.employeeInfo.bankBranchName,
          Address:e.employeeAnnexure.place,
          dateofBirth : this.datepipe.transform(e.employee.dateOfBirth, 'dd-MM-yyyy'), 
          dateOfJoining : this.datepipe.transform(e.employee.dateOfJoining, 'dd-MM-yyyy'),  
          pfNumber : e.employee.pfNumber,
          esiNumber : e.employee.esiNumber,
          uanNumber : e.employee.uanNumber,
          Period:e.finanicalPeriod.financialperiodMonth+'- '+e.finanicalPeriod.financialPeriodYear,     
          clientWorkingDays : e.clientWorkingDays.workingDays,
          employeeWorkingDays : e.employeeWorkingDays ? e.employeeWorkingDays.workingDays : 0,
          otHours : e.employeeWorkingDays ? e.employeeWorkingDays.otHours : 0
        }
        totaldaysofwages += e.employeeWorkingDays ? e.employeeWorkingDays.workingDays : 0,
        totalOtHours += e.employeeWorkingDays ? e.employeeWorkingDays.otHours : 0

         console.log("exceldata")
        this.paySlipElement.forEach(paySlipElement =>{
          e.payrollLine.forEach(payrollLine => {
            if(payrollLine.hrmspaySlipElement.elementId == paySlipElement.elementId){
              data[paySlipElement.elementName] =payrollLine.amount?.toFixed(2);
            }
          })
        })

      //  let netpay = Math.round(e.totalEarnings) -  Math.round(e.totalDeduction) ;
      //   data["Dedecutions"]  =  Math.round(e.totalDeduction) ;

        let netpay =e.totalEarnings -  e.totalDeduction ;
        data["Dedecutions"]  = e.totalDeduction ;

        data['Net Pay'] = netpay;
      console.log("table data",data);
      this.dataForExcel.push(Object.values(data));
      console.log(this.dataForExcel);
    }
    let data= {
      clientCode : '',
      clientName :'',
      clientAddress :'',
      employeeNumber:"",
      employeeName:"",
      fatherName : "",
      accountName:"",
      accountNumber:"",
      IfscCode:"",
      BankName:"",
      BranchName:"",
      Address:"",
      dateofBirth :"",
      dateOfJoining :"",
      pfNumber :"",
      esiNumber :"",
      uanNumber :"",
      Period:"",
      clientWorkingDays : "Total",
      employeeWorkingDays : totaldaysofwages,
      otHours : totalOtHours
    }
    this.paySlipElement.forEach(element1 =>{
      this.headerForExcel[0][element1.elementName]='';
      data[element1.elementName] =  element1.amount?.toFixed(2);
      console.log("total data",data);
    })

    
    this.headerForExcel[0]["Deductions"]='';
    this.headerForExcel[0]["Net Pay"]='';
   
    data["Dedecutions"]  =  this.totalDeduction;
    data['Net Pay'] = this.totalNetpay;
    this.dataForExcel.push(Object.values(data))
    let reportDatabyrecruiter = {
      title: 'Consolidate Payslip Report',
       exceldate: "Period : " +  this.period,
      data: this.dataForExcel,
      employeeName :this.employeeName,
      headers: Object.keys(this.headerForExcel[0])
    }
    console.log(reportDatabyrecruiter);
    this.ExportExcelService.exportExcel(reportDatabyrecruiter);
  }


}
