<div style="margin-top: 50px">
  <body class="overflow: auto; background-color:#fafbfb!important;">
    <div class="main-content">
      <div class="container-fluid">
        <mat-card class="chart-card">
          <h4 class="text-left heading">Summary</h4>
          <div class="row">
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
              <mat-card class="card-content">
                <div class="row">
                  <div class="d-flex align-items-center">
                    <span class="material-icons insert-drive-icon mr-2 ml-2"
                      >insert_drive_file</span
                    >
                    <div class="project-count mt-n1">Projects</div>
                  </div>
                </div>
                <div class="col-6 text-right">
                  <div class="d-flex align-items-center justify-content-end">
                    <div class="project-count mt-project-count">
                      {{ projectCount }}
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
              <mat-card class="card-content">
                <div class="row">
                  <div class="d-flex align-items-center">
                    <span class="material-icons profile-icon mr-2 ml-2"
                      >person</span
                    >
                    <div class="project-count mt-n1">Clients</div>
                  </div>
                </div>
                <div class="col-6 text-right">
                  <div class="d-flex align-items-center justify-content-end">
                    <div class="project-count mt-project-count">
                      {{ clientCount }}
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
              <mat-card class="card-content">
                <div class="row">
                  <div class="d-flex align-items-center">
                    <span class="material-icons tick-icon mr-2 ml-2"
                      >check_box</span
                    >
                    <div class="project-count mt-n1">Tasks</div>
                  </div>
                </div>
                <div class="col-6 text-right">
                  <div class="d-flex align-items-center justify-content-end">
                    <div class="project-count mt-project-count">
                      {{ taskCount }}
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
              <mat-card class="card-content">
                <div class="row">
                  <div class="d-flex align-items-center">
                    <span class="material-icons people-alt mr-2 ml-2"
                      >people_alt</span
                    >
                    <div class="project-count mt-n1">Employees</div>
                  </div>
                </div>
                <div class="col-6 text-right ml-0 ml-sm-m2">
                  <div class="d-flex align-items-center justify-content-end">
                    <div class="project-count mt-project-count">
                      {{ employeeCount }}
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </mat-card>
        <div class="row">
          <div class="col-md-12">
            <mat-card class="chart-card">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                      <mat-select
                          class="form-control email-input select-placeholder "
                          placeholder="Select Project"
                          (selectionChange)="getproject($event)"
                      >
                          <mat-option [value]="0">--Select Project--</mat-option>
                          <mat-option *ngFor="let project of projectList" [value]="project.projectId">
                              {{ project.projectName }}
                          </mat-option>
                      </mat-select>
                  </div>
              
                  <div class="col-9 d-flex justify-content-end open-close-btn">
                      <div class="me-2">
                          <button   class="open-button" (click)="projectStatusOpen()">
                              Open
                          </button>
                      </div>
              
                      <div>
                          <button class="close-button" type="button" (click)="projectStatusClose()">
                              Close
                          </button>
                      </div>
                  </div>
              </div>
              </div>
              <div
                *ngIf="projectBudgetHourslist?.length != 0"
                style="top: -30px; right: 3px"
              >
                <apx-chart
                  class="apx-chart"
                  style="top: -30px; right: 3px"
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="chartOptions.plotOptions"
                  [yaxis]="chartOptions.yaxis"
                  [legend]="chartOptions.legend"
                  [fill]="chartOptions.fill"
                  [stroke]="chartOptions.stroke"
                  [tooltip]="chartOptions.tooltip"
                  [xaxis]="chartOptions.xaxis"
                ></apx-chart>
              </div>
              <div class="card-body" *ngIf="nodatachart">
                <div class="alert alert-primary">
                  <h4 class="mt-3 no-budget-hours" align="center ">
                    No Budget Hours Found
                  </h4>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-card class="card-content">
              <div
                class="table-responsive"
                *ngIf="notsubmittedtaskreport.length > 0"
              >
                <div class="row p-0 m-0">
                  <div class="col-xl-12 col-lg-12">
                    <div
                      class="select-pagination"
                      *ngIf="notsubmittedtaskreport.length > 5"
                    >
                      <span>Show : </span>
                      <select
                        class="select_list new-select_list listDesign"
                        [(ngModel)]="mySelect"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="changefirstpage()"
                      >
                        <option class="form-control" value="5">5</option>
                        <option class="form-control" value="10">10</option>
                        <option class="form-control" value="15">15</option>
                        <option class="form-control" value="20">20</option>
                        <option class="form-control" value="25">25</option>
                      </select>
                    </div>
                  </div>
                </div>
                

                <div class="table-responsive-new1">
                  <table
                    matSort
                    (matSortChange)="sortData($event)"
                    class="table trDesign tablebordered"
                  >
                    <thead>
                      <tr class="tr">
                        <th style="text-align: center">Sl.No</th>
                        <th>Employee&nbsp;&nbsp;Name</th>
                        <th>Employee&nbsp;&nbsp;Email</th>
                        <th style="text-align: center">
                          Time&nbsp;Sheet&nbsp;Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let e of notsubmittedtaskreport
                            | paginate
                              : { itemsPerPage: mySelect, currentPage: p };
                          let i = index
                        "
                      >
                        <td style="text-align: center" *ngIf="p">
                          {{ (p - 1) * mySelect + (i + 1) }}
                        </td>
                        <td style="text-align: center" *ngIf="!p">
                          {{ i + 1 }}
                        </td>
                        <td>{{ e.firstName }}</td>
                        <td>{{ e.organizationEmail }}</td>
                        <!-- <td class="more">
                        <span class="material-icons-outlined icon-red">
                          cancel
                        </span>
                      </td> -->

                        <td class="more" *ngIf="e.timesheet" align="center">
                          <span class="material-icons icon-green">
                            check_circle_outline
                          </span>
                        </td>
                        <td class="more" align="center" *ngIf="!e.timesheet">
                          <span class="material-icons-outlined icon-red">
                            cancel
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <pagination-controls
              (pageChange)="p = $event"
              *ngIf="notsubmittedtaskreport.length > 5"
              align="end"
            ></pagination-controls> -->

                  <pagination-controls
                    class="text-right"
                    style="float: right"
                    (pageChange)="p = $event; beforesearchpage = $event"
                    align="end"
                  ></pagination-controls>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </body>
  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
  </div>
</div>
