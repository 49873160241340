import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PayrolldataService {

 payrolldata :any = [];

  constructor() { }


   setpayrolldata(payrolllist:any)
   {
      this.payrolldata = payrolllist;
   }

}
