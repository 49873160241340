import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeaveService } from 'src/app/providers/leave.service';
import { subscribe } from 'diagnostics_channel';
import { ProjectManagementService } from 'src/app/providers/project-management.service';

@Component({
  selector: 'app-approver-status',
  templateUrl: './approver-status.component.html',
  styleUrls: ['./approver-status.component.scss']
})
export class ApproverStatusComponent implements OnInit {
  loader=true;
  datanew:any;
  constructor(
    private leaveservice :LeaveService,

    @Inject(MAT_DIALOG_DATA) public data: any, 
    
  ) { }
  
  ngOnInit(): void {
   
    this.loader = true;
      this.leaveservice.getApproverById(this.data.approvals.approver.approverId).subscribe(
        (response) => {
          this.datanew = response
          console.log(  this.datanew,"222222222222222");
          console.log(response);
          this.loader = false;
        },
        (error) => {
          console.error('Error fetching approver:', error);
          this.loader = false;
        }
      );
  
    
    console.log("1111111111111111111111111111111111111111111",this.datanew);
    console.log("this.data1111111111111111111111111111",this.data);
    console.log("this.data1111111111111111111111111111",this.data.approvals.approver.approverId);
    // console.log("this.data.approver.fyiObject.firstName",this.data.approver.fyiObject.firstName);
  }
  

}
