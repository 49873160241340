<style>
    .full-width{
      width:100%;
    }
   
  </style>
  <div class="container">
    <div class=" --form-group">
      <form [formGroup]="holidayFrom" (ngSubmit)="onSubmit()">
        <div class=" --form-group" *ngIf="!data.id">
           <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Leave Type<span class="star"> *</span></label>
            <mat-select formControlName="leaveType" 
            class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
            placeholder="Select Leave Type" (change)="onLeaveTypeChange($event)">
              <mat-option value="0" (click)="holiClick(0)">Holiday</mat-option>
              <mat-option value="1" (click)="holiClick(1)">Exception</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="f.leaveType.hasError('required') && (f.leaveType.touched || f.leaveType.dirty)">
              Leave Type is required
            </mat-error> -->
            <mat-error *ngIf="f.leaveType.hasError('required') && submitted">
              Leave Type is required
            </mat-error>
            
          </div>
        </div>
        <div class=" --form-group">
           <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Holiday Name<span class="star"> *</span></label>
            <input formControlName="holidayName" 
            class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
            placeholder="Enter Holiday Name"/>
            <!-- <mat-error *ngIf="f.holidayName.hasError('required') && (f.holidayName.touched || f.holidayName.dirty)">
              Please enter <strong>holidayName</strong>
            </mat-error> -->
            <mat-error *ngIf="f.holidayName.hasError('required') && submitted">
              Please enter holidayName
            </mat-error>
            <mat-error *ngIf="f.holidayName.errors?.pattern">
              Please enter a valid Holiday Name
          </mat-error> 
          </div>
        </div>
  
        <div class=" --form-group" *ngIf="holiday">
           <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Holiday Type<span class="star"> *</span></label>
            <mat-select formControlName="holidaytype" placeholder="Select Holiday Type" 
            class="form control select-placeholder email-input " style="outline:none; border-radius:5px;font-family: Mulish;"
            >
              <mat-option [value]="null">Select Holiday Type</mat-option>
              <mat-option *ngFor="let type of holidayType" [value]="type.listTypeValueId">
                {{type.listTypeValueName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.holidaytype.hasError('required') && submitted">
              <span>Holiday Type is required</span>
            </mat-error>
          </div>
        </div>

        <div class="form-group" *ngIf="exception">
          <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Exception Type<span class="star">*</span></label>
            <mat-select formControlName="holidaytype" placeholder="Select Exception Type" 
            class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
            >
              <mat-option [value]="null">Select Exception Type</mat-option>
              <mat-option *ngFor="let type of exceptionType" [value]="type.listTypeValueId" >
                {{type.listTypeValueName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.holidaytype.hasError('required') && submitted">
              <span>Exception Type is required</span>
            </mat-error>
          </div>
        </div>
<!--   
        <div class=" --form-group" *ngIf="exception">
           <div class="input-width" id="loginform">
            <label class="col-form-label">Holiday Type<span class="star"> *</span></label>
            <select formControlName="holidaytype" placeholder="Select Holiday Type" class="form-select form-select-solid form-select-lg select2-new">
              <option [value]="null"> ---Select Holiday Type --- </option>
              <option *ngFor="let type of exceptionType" [value]="type.listTypeValueId">
                {{type.listTypeValueName}}
              </option>
            </select>
            <mat-error *ngIf="f.holidaytype.hasError('required') && submitted">
              <span>Holiday Type is required</span>
            </mat-error>
          </div>
        </div> -->
        <div class=" --form-group">
           <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Date<span class="star"> *</span></label>
            <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            style="float: right;position: relative;top: 38px;"></mat-datepicker-toggle>
            <input
              placeholder="Select Date"
              (click)="picker.open()"
              formControlName="holidayDate"
              [disabled] = "dateDisable"
              [matDatepickerFilter]="myFilter"
              [matDatepicker]="picker"
              placeholder="select start date"       
class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
/>
            <mat-datepicker #picker touchUi></mat-datepicker>
            <!-- <mat-error *ngIf=" f.holidayDate.hasError('required') && (f.holidayDate.touched || f.holidayDate.dirty)">
              Please select <strong>Holiday Date</strong>
            </mat-error> -->
            <mat-error *ngIf=" f.holidayDate.hasError('required') && submitted">
              Please select Holiday Date
            </mat-error>
          </div>
        </div>
  
        <div class=" --form-group" *ngIf="data.id">
           <div class="input-width" id="loginform">
            <label class="form-control-label mt-3">Holiday Status<span class="star"> *</span></label>
            <mat-select formControlName="holidayStatus" 
            class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"            
            placeholder="Select Holiday Status">
              <mat-option value="1">Active</mat-option>
              <mat-option value="2">InActive</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="f.holidayStatus.hasError('required')&& (f.holidayStatus.touched || f.holidayStatus.dirty)">
              <span>Holiday Status is required</span>
            </mat-error> -->
            <mat-error *ngIf="f.holidayStatus.hasError('required')&& submitted">
              <span>Holiday Status is required</span>
            </mat-error>
          </div>
        </div>
  
        <div class=" --form-group float-right">
          <button mat-raised-button style="margin: 10px 0 0 5px;color:#ff0000a8" 
          type="button"
          class="btn btnRes mr-2"
          mat-dialog-close>
            Cancel
          </button>
          <button mat-raised-button color="primary" 
          style="margin: 10px 0 0 5px" type="submit"
          [disabled]="btnDisable"
          >
            {{data.id ? 'Update' :'Create'}}
          </button>
        </div>
      </form>
    </div>
  </div>
  
