//import 'chart.js/src/chart.js';
import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecruitmentService } from '../../providers/recruitment.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportExcelService } from '../../services/export-excel.service';
//import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { AppComponent } from '../../app.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardDialogboxComponent } from '../dashboard-dialogbox/dashboard-dialogbox.component';
import { SelectedComponent } from '../selected/selected.component';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';
import { HeaderService } from 'src/app/providers/header.service';

@Component({
  selector: 'app-admin-dashboard-new',
  templateUrl: './admin-dashboard-new.component.html',
  styleUrls: ['./admin-dashboard-new.component.scss'],
})
export class AdminDashboardNewComponent implements OnInit {
  admindashboard: any;
  ProfileShared: number;
  Interviewed: any;
  Notinterviewed: number;
  recruiterSearchform: FormGroup;
  @Input() requiredError: boolean;
  reportfromdate: any;
  reporttodate: any;
  reportrecruiter: any;
  startmaxdate: Date = new Date();
  endmindate;
  recruiterlist: any;
  loader: any = false;
  backdrop: any = false;
  dataForExcel = [];
  true: any;
  beforepage: any;
  interviewStatuslist: any;
  selectedStatus = 28;
  rejectedStatus = 29;
  onHoldStatus = 30;
  headerForExcel = [
    {
      'Profile Shared': '',
      Interviewed: '',
      'Not Interviewed': '',
      Selected: '',
      Rejected: '',
      'On Hold': '',
      'Open Position': '',
    },
  ];
  exportingdata: any;
  query: any = '';
  p: number = 1;
  selectedrecruiter: any;
  employeeName: any;
  passData: {};
  sDate: string;
  tDate: string;
  userStatus: any;
  orgId: any;
  isbtnDisable: any;
  hideAdminDashboardDesign: boolean = false;
  titleName: string = 'Interview Status';
  creadbyId:any;
  constructor(
    private dashboardservice: HrmsserviceService,
    private datepipe: DatePipe,
    public ExportExcelService: ExportExcelService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private headerservice: HeaderService
  ) {}

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  ///////// pie Chart //////////
  public pieChartOptions_user: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: 'black',
        },
      },
      datalabels: {
        color: 'white',
        formatter: (value, ctx) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
        font: {
          // weight: 'bold',
          size: 13,
        },
      },
    },
  };
  public pieChartData_user: ChartData<'pie', number[], string | string[]> = {
    labels: [['Profile Shared'], ['Interviewed'], ['Not interviewed']],
    datasets: [
      {
        data: [300, 300, 400],
        backgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
        hoverBackgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
        hoverBorderColor: ['#74C458', '#9B5EEB', '#FF6666'],
      },
    ],
  };
  public pieChartType_user: ChartType = 'pie';
  public pieChartPlugins_user = [DatalabelsPlugin];

  ngOnInit(): void {
    this.headerservice.setTitle('- Dashboard');
    this.userStatus = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(this.userStatus);
    
    this.orgId = this.userStatus.organization.organizationId;
    this.creadbyId=this.userStatus.createdBy;
    console.log("this.creadbyId",this.creadbyId);
    
    console.log(this.userStatus.status.listTypeValueName);
    this.recruiterSearchform = new FormGroup({
      fromdate: new FormControl(this.recruiterSearchform, [
        Validators.required,
      ]),
      todate: new FormControl(this.recruiterSearchform, [Validators.required]),
      recruiter: new FormControl(this.recruiterSearchform),
    });

    this.dashboardservice.geRecruiter(this.orgId).subscribe((recruiterlist: any) => {
      this.recruiterlist = recruiterlist;
      console.log(this.recruiterlist);
      //  console.log(this.recruiterlist[0].employeeId);
    });

    this.admindashboard = {
      rejected: 0,
      notInterviewed: 0,
      selected: 0,
      onHold: 0,
      profilesource: 0,
      interviewed: 0,
    };

    this.loader = true;
    this.dashboardservice
      .admindashboardByOrganization(this.orgId)
      .subscribe((admindashboard: any) => {
        this.loader = false;
        this.admindashboard = admindashboard;
        console.log(
          ' this.admindashboard ' + JSON.stringify(this.admindashboard)
        );
        console.log(
          ' this.admindashboard.openPositio' + this.admindashboard.openPosition
        );

        this.ProfileShared = this.admindashboard.profilesource;
        this.Interviewed = this.admindashboard.interviewed;
        this.Notinterviewed = this.admindashboard.notInterviewed;

        this.pieChartData_user = {
          labels: [['Profile Shared'], ['Interviewed'], ['Not interviewed']],
          datasets: [
            {
              data: [this.ProfileShared, this.Interviewed, this.Notinterviewed],
              backgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
              hoverBackgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
              hoverBorderColor: ['#74C458', '#9B5EEB', '#FF6666'],
            },
          ],
        };
        this.hideAdminDashboardDesign = true;
      });
  }

  ngOnDestroy() {
    this.headerservice.setTitle('');
  }
  startdate() {
    if (this.recruiterSearchform.value.fromdate != null) {
      this.endmindate = this.recruiterSearchform.value.fromdate;
    }
  }
  clearfilter() {
    console.log('Clear Data');
    this.recruiterSearchform.patchValue({
      fromdate: null,
      todate: null,
      // recruiter : ""
      recruiter: null,
    });
    console.log('Clear From:' + this.recruiterSearchform.value.fromdate);
    console.log('Clear To:' + this.recruiterSearchform.value.todate);
    console.log('Clear Recuriter:' + this.recruiterSearchform.value.recruiter);
    console.log(this.reportfromdate);
    console.log(this.reporttodate);
    console.log(this.reportrecruiter);
    this.dashboardservice.geRecruiter(this.orgId).subscribe((recruiterlist: any) => {
      this.recruiterlist = recruiterlist;
      console.log(this.recruiterlist);
      //  console.log(this.recruiterlist[0].employeeId);
    });
 
    // this.loader=true;
    // if (
    //   this.recruiter == undefined &&
    //   this.fromdate == null &&
    //   this.todate == null
    // ) {
    //   this.loader = false;
    //   console.log('if...came');
    // }

    // // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // //     const currentUrl = this.router.url;
    // //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    // //         this.router.navigateByUrl(currentUrl);
    // //     });
    // else {
    //   console.log('else...came');

    //   if (
    //     this.recruiter! == undefined &&
    //     this.fromdate !== null &&
    //     this.todate! == null
    //   ) {
    //     console.log('first condition');

    //     // Your code here

    //     if (
    //       this.recruiter! == undefined &&
    //       this.fromdate! == null &&
    //       this.todate! == null
    //     ) {
    //       console.log('second condition');

    //       if (
    //         this.recruiter !== undefined &&
    //         this.fromdate !== null &&
    //         this.todate !== null
    //       ) {
    //         console.log('third condition');

    //         console.log('Form is valid');
    //         this.recruiter == undefined &&
    //         this.fromdate == null &&
    //         this.todate == null
    //         // this.taskList=undefined
    //         // this.timesheetreport.length = 0;
    //         // this.taskList.length=0

    //         // this.finalTaskList = [];
    //         // this.nodata = false;
    //         this.loader = true;
    //         this.dashboardservice.admindashboard().subscribe((admindashboard:any) =>
    //           {
    //               this.loader=false;
    //               this.admindashboard = admindashboard;
    //               console.log(" this.admindashboard " + JSON.stringify( this.admindashboard));
    //               console.log(" this.admindashboard.openPosition" + this.admindashboard.openPosition);
    //               this.ProfileShared  = this.admindashboard.profilesource;
    //               this.Interviewed = this.admindashboard.interviewed;
    //               this.Notinterviewed = this.admindashboard.notInterviewed;
    //               this.pieChartData_user = {
    //                 labels:[['Profile Shared'],['Interviewed'],['Not interviewed']],
    //                 datasets: [ {
    //                   data: [this.ProfileShared, this.Interviewed, this.Notinterviewed],
    //                   backgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
    //                   hoverBackgroundColor:['#74C458','#9B5EEB','#FF6666'],
    //                   hoverBorderColor:['#74C458','#9B5EEB','#FF6666'],
    //                 } ],
    //               };
    //           })

    //       }
    //     }
    //   }
console.log("this.admindashboard.profilesource::",this.admindashboard.profilesource);
console.log("this.admindashboard.interviewed::",this.admindashboard.interviewed);
console.log("this.admindashboard.notInterviewed::",this.admindashboard.notInterviewed);



    // if (
    //   this.admindashboard.profilesource!==0 &&
    //   this.admindashboard.interviewed !==0&&
    //   this.admindashboard.notInterviewed!==0
    // ) {

      // this.admindashboard = {
      //   rejected: 0,
      //   notInterviewed: 0,
      //   selected: 0,
      //   onHold: 0,
      //   profilesource: 0,
      //   interviewed: 0,
      // };
      this.dashboardservice
        .admindashboard()
        .subscribe((admindashboard: any) => {
          this.loader = false;
          this.admindashboard = admindashboard;
          console.log(
            ' this.admindashboard ' + JSON.stringify(this.admindashboard)
          );
          console.log(
            ' this.admindashboard.openPosition' +
              this.admindashboard.openPosition
          );
          this.ProfileShared = this.admindashboard.profilesource;
          this.Interviewed = this.admindashboard.interviewed;
          this.Notinterviewed = this.admindashboard.notInterviewed;
          this.pieChartData_user = {
            labels: [['Profile Shared'], ['Interviewed'], ['Not interviewed']],
            datasets: [
              {
                data: [
                  this.ProfileShared,
                  this.Interviewed,
                  this.Notinterviewed,
                ],
                backgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBackgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBorderColor: ['#74C458', '#9B5EEB', '#FF6666'],
              },
            ],
          };
        });
    // }
  }

  // this.resourceList(this.ProfileShared);
  // console.log("done");
  //  window.location.reload();
  // }

  onKeyUpEvent(event) {
    if (this.query.length != 0) {
      console.log(this.query.length);
      this.p = 1;
      console.log(this.p);
    } else {
      this.p = this.beforepage;
      console.log(this.p);
      console.log(this.beforepage);
    }
  }
  submitData() {
    // if(this.recruiterSearchform.invalid)
    // {
    //   // console.log("NULL From date and Todate and Recuriter");
    //   alert('Please select from date & to date.');
    //   return;
    // }
    console.log("from date::",    this.recruiterSearchform.value.fromdate );
    console.log("to date::",this.recruiterSearchform.value.todate  );
    console.log("recruiter::", this.recruiterSearchform.value.recruiter);
    
    
    
    if (
      this.recruiterSearchform.value.fromdate == null &&
      this.recruiterSearchform.value.todate == null &&
      this.recruiterSearchform.value.recruiter == null
    ) {
      // console.log('NULL From date and Todate and Recuriter');
      alert('Please select Any Field to Search');
      return;
    }
    else if( this.recruiterSearchform.value.fromdate !== null &&
      this.recruiterSearchform.value.todate == null ){
        alert('Please select To date ');
        return
      }
      else if( this.recruiterSearchform.value.fromdate == null &&
        this.recruiterSearchform.value.todate !== null 
       ){
          alert('Please select From date');
          return
        }

    else  if (this.recruiterSearchform.value.fromdate >this.recruiterSearchform.value.todate) {
        console.log('to date is less then Start date');
        alert('to date is less then From date');
        return;
        
      }
      else{
        console.log("all conditions came out !!");
        
      }

    console.log(this.recruiterSearchform);
    this.reportfromdate = this.datepipe.transform(
      this.recruiterSearchform.value.fromdate,
      'yyyy-MM-dd'
    );
    // console.log('fromdate' + this.reportfromdate);
    this.reporttodate = this.datepipe.transform(
      this.recruiterSearchform.value.todate,
      'yyyy-MM-dd'
    );
    // console.log('rodate' + this.reporttodate);
    this.reportrecruiter = this.recruiterSearchform.value.recruiter;
    // console.log('reportrecruiter' + this.reportrecruiter);
    console.log('reportrecruiter', this.recruiterSearchform.value.recruiter);
    let fromdate = this.reportfromdate + ' ' + '00:00:00';
    let todate = this.reporttodate + ' ' + '23:59:00';
    console.log('fromdate' ,fromdate);
    console.log('todate' , todate);
    this.sDate = fromdate;
    this.tDate = todate;

   
  
     
   

    if (
      (this.recruiterSearchform.value.fromdate != null &&
        this.recruiterSearchform.value.todate == null) ||
      (this.recruiterSearchform.value.fromdate == null &&
        this.recruiterSearchform.value.todate != null)
    ) {
      console.log('From date and Todate alert');
      alert('Please select  To Date.');
      return;
    } else if (
      (this.recruiterSearchform.value.recruiter != null ||
        this.recruiterSearchform.value.recruiter != undefined) &&
      this.reportfromdate != null &&
      this.reporttodate != null
    ) {
      console.log('From date and Todate and Recuriter');
      this.loader = true;
      // this.backdrop=true;
      this.isbtnDisable = true;
      this.dashboardservice
        // .admindashbaordreportbyrecruiter(fromdate , todate , this.recruiterSearchform.value.recruiter)
        .admindashbaordreportbyrecruiterorg(
          fromdate,
          todate,
          this.recruiterSearchform.value.recruiter,
          this.orgId
        )
        .subscribe((data: any) => {
          this.loader = false;

          this.backdrop = false;
          this.admindashboard = data;
          this.isbtnDisable = true;

          console.log(this.admindashboard);
          this.ProfileShared = this.admindashboard.profilesource;
          this.Interviewed = this.admindashboard.interviewed;
          this.Notinterviewed = this.admindashboard.notInterviewed;
          this.pieChartData_user = {
            labels: [['Profile Shared'], ['Interviewed'], ['Not interviewed']],
            datasets: [
              {
                data: [
                  this.ProfileShared,
                  this.Interviewed,
                  this.Notinterviewed,
                ],
                backgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBackgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBorderColor: ['#74C458', '#9B5EEB', '#FF6666'],
              },
            ],
          };
          this.isbtnDisable = false;
        });
    } else if (this.reportfromdate != null && this.reporttodate != null) {
      console.log('From date and Todate');
      this.loader = true;
      this.isbtnDisable = true;

      // this.backdrop=true;
      this.dashboardservice
        // .admindashbaordreport(fromdate , todate)
        .admindashbaordreportbyOrg(fromdate, todate, this.orgId)
        .subscribe((data: any) => {
          this.loader = false;
          this.backdrop = false;
          this.admindashboard = data;
          this.isbtnDisable = true;

          console.log(this.admindashboard);
          this.ProfileShared = this.admindashboard.profilesource;
          this.Interviewed = this.admindashboard.interviewed;
          this.Notinterviewed = this.admindashboard.notInterviewed;
          this.pieChartData_user = {
            labels: [['Profile Shared'], ['Interviewed'], ['Not interviewed']],
            datasets: [
              {
                data: [
                  this.ProfileShared,
                  this.Interviewed,
                  this.Notinterviewed,
                ],
                backgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBackgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBorderColor: ['#74C458', '#9B5EEB', '#FF6666'],
              },
            ],
          };
          this.isbtnDisable = false;
        });
    } else {
      console.log('Recuriter Only');
      this.loader = true;
      // this.backdrop=true;
      this.isbtnDisable = true;

      console.log(this.reportrecruiter);
      this.dashboardservice
        .admindashbaordrecuriterreportorg(this.reportrecruiter, this.orgId)
        .subscribe((data: any) => {
          this.loader = false;
          this.backdrop = false;
          this.admindashboard = data;
          this.isbtnDisable = true;

          console.log(this.admindashboard);
          this.ProfileShared = this.admindashboard.profilesource;
          this.Interviewed = this.admindashboard.interviewed;
          this.Notinterviewed = this.admindashboard.notInterviewed;
          this.pieChartData_user = {
            labels: [['Profile Shared'], ['Interviewed'], ['Not interviewed']],
            datasets: [
              {
                data: [
                  this.ProfileShared,
                  this.Interviewed,
                  this.Notinterviewed,
                ],
                backgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBackgroundColor: ['#74C458', '#9B5EEB', '#FF6666'],
                hoverBorderColor: ['#74C458', '#9B5EEB', '#FF6666'],
              },
            ],
          };
          this.isbtnDisable = false;
        });
    }
  }
  //// exportToExcel //////
  exportToExcel() {
    this.reportfromdate = this.datepipe.transform(
      this.recruiterSearchform.value.fromdate,
      'dd-MM-yyyy'
    );
    this.reporttodate = this.datepipe.transform(
      this.recruiterSearchform.value.todate,
      'dd-MM-yyyy'
    );
    this.dataForExcel.length = 0;
    console.log(this.dataForExcel.length);
    if (
      this.recruiterSearchform.value.recruiter != null ||
      this.recruiterSearchform.value.recruiter != undefined
    ) {
      console.log('IF');
      for (let recruiterlist of this.recruiterlist) {
        console.log(recruiterlist);
        console.log(recruiterlist.employeeId);
        if (
          recruiterlist.employeeId == this.recruiterSearchform.value.recruiter
        ) {
          var recruiter = ' Recruiter : ';
          this.employeeName = recruiter + recruiterlist.employeeName;
          console.log(this.employeeName);
        }
      }

      this.exportingdata = this.admindashboard;
      console.log(this.exportingdata);

      this.exportingdata = {
        ProfileShared: this.exportingdata.profilesource,
        interviewed: this.exportingdata.interviewed,
        notInterviewed: this.exportingdata.notInterviewed,
        selected: this.exportingdata.selected,
        rejected: this.exportingdata.rejected,
        onHold: this.exportingdata.onHold,
        openPosition: this.exportingdata.openPosition,
      };
      this.dataForExcel.push(Object.values(this.exportingdata));
      console.log(this.dataForExcel);
      if (this.reportfromdate != null && this.reporttodate != null) {
        let reportDatabyrecruiter = {
          title: 'Resource Status Report',
          exceldate: this.reportfromdate + ' - ' + this.reporttodate,
          data: this.dataForExcel,
          employeeName: this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      } else {
        let reportDatabyrecruiter = {
          title: 'Resource Status Report',
          // exceldate:  this.reportfromdate +' - '+ this.reporttodate,
          data: this.dataForExcel,
          employeeName: this.employeeName,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportDatabyrecruiter);
        this.ExportExcelService.exportExcel(reportDatabyrecruiter);
      }
    } else {
      console.log('else');
      this.exportingdata = this.admindashboard;
      console.log(this.exportingdata);
      this.exportingdata = {
        ProfileShared: this.exportingdata.profilesource,
        interviewed: this.exportingdata.interviewed,
        notInterviewed: this.exportingdata.notInterviewed,
        selected: this.exportingdata.selected,
        rejected: this.exportingdata.rejected,
        onHold: this.exportingdata.onHold,
        openPosition: this.exportingdata.openPosition,
      };
      this.dataForExcel.push(Object.values(this.exportingdata));
      console.log(this.dataForExcel);

      if (this.reportfromdate != null && this.reporttodate != null) {
        let reportData = {
          title: 'Resource Status Report',
          exceldate: this.reportfromdate + ' - ' + this.reporttodate,
          data: this.dataForExcel,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportData);
        this.ExportExcelService.exportExcel(reportData);
      } else {
        let reportData = {
          title: 'Resource Status Report',
          // exceldate:  this.reportfromdate +' - '+ this.reporttodate,
          data: this.dataForExcel,
          headers: Object.keys(this.headerForExcel[0]),
        };
        console.log(reportData);
        this.ExportExcelService.exportExcel(reportData);
      }
    }
  }
  
  selected(id, statuscount) {
    // console.log("Selected");
    // console.log("Id:"+id);
    // console.log("Id:"+ this.reportfromdate);
    // console.log("Id:"+ this.reporttodate);
    if (statuscount != 0) {
      this.titleName = 'Selected';  // Set the title name

      this.passData = {
        // startDate: this.reportfromdate,
        // endDate: this.reporttodate,
        // createdBy: this.reportrecruiter,
        startDate: this.recruiterSearchform.value.fromdate,
        endDate: this.recruiterSearchform.value.todate,
        createdBy: this.recruiterSearchform.value.recruiter,
        interviewstatus: id,
        titleName: 'Selected'
      };
      console.log(this.passData);
      this.dialog.open(SelectedComponent, {
        width: '83%',
        height: '74%',
        data: this.passData,
      });
    }
  }
  rejected(id1, statuscount) {
    if (statuscount != 0) {
      this.titleName = 'Rejected';  // Set the title name

      this.passData = {
        // startDate: this.reportfromdate,
        // endDate: this.reporttodate,
        // createdBy: this.reportrecruiter,
        startDate: this.recruiterSearchform.value.fromdate,
        endDate: this.recruiterSearchform.value.todate,
        createdBy: this.recruiterSearchform.value.recruiter,
        interviewstatus: id1,
        titleName: 'Rejected'

      };
      console.log(this.passData);
      this.dialog.open(SelectedComponent, {
        width: '70%',
        height: '70%',
        data: this.passData,
      });
    }
  }

  onHold(id3, statuscount) {
    if (statuscount != 0) {
      this.titleName = 'On-Hold';  // Set the title name

      this.passData = {
        // startDate: this.reportfromdate,
        // endDate: this.reporttodate,
        // createdBy: this.reportrecruiter,
        startDate: this.recruiterSearchform.value.fromdate,
        endDate: this.recruiterSearchform.value.todate,
        createdBy: this.recruiterSearchform.value.recruiter,
        interviewstatus: id3,
        titleName: 'On-Hold'

      };
      console.log(this.passData);
      this.dialog.open(SelectedComponent, {
        width: '70%',
        height: '70%',
        data: this.passData,
      });
    }
  }

  resourceList(profilesource) {
    console.log('profilesource' + profilesource);
    if (profilesource != 0) {
      console.log('profilesource1' + profilesource);
      let resdata = {
        status: 1,
        // startDate: this.reportfromdate,
        // endDate: this.reporttodate,
        // createdBy: this.reportrecruiter,
        startDate: this.recruiterSearchform.value.fromdate,
        endDate: this.recruiterSearchform.value.todate,
        createdBy: this.creadbyId,
        titleName: 'Profile Shared'

        // interviewed:true
      };
      console.log(resdata);
      this.dialog.open(DashboardDialogboxComponent, {
        width: '70%',
        height: '70%',
        data: resdata,
      });
    }
    // else
    // {
    //   console.log("Interviewed1"+Interviewed);
    //   let resdata={
    //     // status: 1
    //      interviewed:true
    //    }
    //    console.log(resdata);
    //     this.dialog.open(DashboardDialogboxComponent, {
    //       width:'70%',
    //       height:'70%',
    //       data:resdata
    //     });
    // }
  }
  interviewedList(interviewed) {
    console.log('Interviewed1' + interviewed);
    if (interviewed != 0) {
      console.log('profilesource1' + interviewed);
      let resdata = {
        // status: 1
        interviewed: true,
        //  startDate: this.reportfromdate,
        //  endDate: this.reporttodate,
        //  createdBy: this.reportrecruiter
        startDate: this.recruiterSearchform.value.fromdate,
        endDate: this.recruiterSearchform.value.todate,
        createdBy: this.creadbyId,
        titleName: 'Interviewed '

      };
      console.log(resdata);
      this.dialog.open(DashboardDialogboxComponent, {
        width: '70%',
        height: '70%',
        data: resdata,
      });
    }
  }

  notInterviewedList(notinterviewed) {
    console.log('Not Interviewed1' + notinterviewed);
    if (notinterviewed != 0) {
      console.log('profilesource1' + notinterviewed);
      let resdata = {
        // status: 1
        interviewed: false,
        //  startDate: this.reportfromdate,
        //  endDate: this.reporttodate,
        //  createdBy: this.reportrecruiter
        startDate: this.recruiterSearchform.value.fromdate,
        endDate: this.recruiterSearchform.value.todate,
        createdBy: this.creadbyId,
        titleName: 'Not Interviewed '

      };
      console.log(resdata);
      this.dialog.open(DashboardDialogboxComponent, {
        width: '70%',
        height: '70%',
        data: resdata,
      });
    }
  }

  // status(interviewed)
  // {
  //    console.log("interviewed"+interviewed);
  //    if(interviewed != 0)
  //   {
  //     // let data={
  //     //   status: this.userStatus.status.listTypeValueId,
  //     //  }
  //     //  console.log(data);
  //       this.dialog.open(DashboardDialogboxComponent, {
  //         width:'70%',
  //         height:'70%',
  //         // data:data
  //       });
  //   }
  // }

  get fromdate() {
    return this.recruiterSearchform.get('fromdate');
  }
  get todate() {
    return this.recruiterSearchform.get('todate');
  }
  get recruiter() {
    return this.recruiterSearchform.get('recruiter');
  }
}
