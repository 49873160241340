import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';
// import { ExceptionHoliday } from '../dashboard/dashboard.component';
import { Location } from '@angular/common';
import { LeaveService } from 'src/app/providers/leave.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-apply-leave',
  templateUrl: './apply-leave.component.html',
  styleUrls: ['./apply-leave.component.scss']
})
export class ApplyLeaveComponent implements OnInit {
  fyi = new FormControl('')
  endDate_disable: boolean;
  matcher = new MyErrorStateMatcher();
  isLoading: Boolean;
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() dateInput: EventEmitter<MatDatepickerInputEvent<any>>;
  myFilter: any;
  type: any;
  private id: number;
  username: any;
  min: any;
  noOFDays: any = 0;

  applytoList: any[] = [];
  ccList: any[] = [];
  halfDay: boolean;
  weekDay: boolean;

  empname: string;
  email: any;
  applyTo: any;
  applyTo_id: any;
  applyToSecond_id: any;
  leaveForm: FormGroup;
  private empid;
  private leaveTypeId;
  submitted = false;

  managermail: any = [];
  WorkingDays: any;
  userrole: any;
  workArray:any =[]

  applyToControl = new FormControl('', Validators.required);
  ccToControl = new FormControl('');

  arr: any[] = [];
  testdata: any;
  startdate: any;
  enddate: any;
  public sampleData2: Array<any>;
  public sampleData1: Array<any>;
  stringArr: Array<any> = [];
  json1: any;
  startMin: any;
  successMessage: any;
  secondApprover: any;
  firstApproverEmail: any;
  secondApproverEmail: any;
  organizationEmail: any;
  // personalEmail: any;
  arrayData: any = []
  workingDay:any = []
  totalDay:any = []
  branchId: any
  workDay:any = []
  exceptionDay:any =[]
  From_Date:any;
  End_Date:any;
  today:any;
  show_error_msg: boolean=false;
  currentyear:any;
loader: true;
  success_msg: string;
  error_msg: any;
  success: boolean = false;
  error: boolean = false;
  processing:boolean=true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private gs: GlobalserviceService,
    private datepipe:DatePipe,
    private cd: ChangeDetectorRef,
    private location: Location,
    private leaveservice:LeaveService ) {
  }
  ngOnInit() {
    this.endDate_disable = true;
    this.isLoading = false;
    let x = JSON.parse(localStorage.getItem('enoteUserData'));

    this.empname = x.employee.firstName +' '+ x.employee.lastName;
    this.email = x.employee.organizationEmail;

    console.log('email',this.email);
    
    this.userrole = x.userType;
    let contact = x.contact;
    this.empid = x.employee.employeeId;


    this.leaveForm = this.fb.group({
      employeeId: this.fb.group( [this.empid]), 
      employeeName: ['', Validators.required],
      email: ['', [Validators.required]],
      leaveType: [null, Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      leaveReason: ['', [Validators.required, Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$")]],
      noOfDays: [null, Validators.required]
    });
 
    let today=new Date();
    console.log("today:"+today);
    this.today=today;

    this.min = new Date(this.f.startDate.value);
    console.log(this.min);

    this.leaveForm.patchValue({
      employeeName: this.empname,
      email: this.email,
      contact: contact
    })
    this.leaveservice.getEmpListById(this.empid).subscribe(
      (x:any)=>{
        console.log("x",x);
        this.branchId = x.branch?.branchId;
        console.log("this.branchId",this.branchId);

    this.leaveservice.getLeaveBalanceById(this.empid).subscribe(
      (d: any[]) => {
        this.type = d;
        console.log("this.type",this.type);

            // get date
            const sample_date_data: any[] = []
            this.leaveservice.get_leave_date(this.empid).subscribe((a: any) => { 
              console.log("LEave Date Api");
              console.log(a);
              a.forEach(element => {
                let x = element.split("-");
                sample_date_data.push(new Date(`'${x[0]}/${x[1]}/${x[2]}'`).getTime())
              });
              console.log(sample_date_data);
              let x = sample_date_data;
            })

            if(this.branchId){
              console.log(this.branchId);
              console.log("Bracnch Id");
              const currentDate = new Date();
              this.currentyear = currentDate.getFullYear();
              // this.leaveservice.getCalByBranch(this.branchId).subscribe(
                this.leaveservice.getCalByBranchAndyearname(this.branchId,this.currentyear).subscribe(
                (dato: any) => { 
                  dato.map(
                    log => {
                      console.log(log);
                      console.log(log.calendarId);
                      console.log(log.year.yearId);
                      console.log(log.year.yearName);
                        this.leaveservice.getCalendarById(log.calendarId).subscribe(
                        (i: any) => { 
                          console.log("calendarId");
                          console.log(i);
                          var exception
                          let data
                          i.exceptions.map(
                            b => {
                              if(b.exceptionStatus == 1){
                                if(b.exceptionTypeName != 'Working day'){
                                  console.log("not Working day");
                                  exception = b.exceptionDate
                                  data = new Date(b.exceptionDate)
                                  console.log(data);
                                  data.setDate(data.getDate());
                                  this.arrayData.push(data)
                                  // console.log( this.arrayData);
                                }
                                 console.log(this.arrayData);
                                if(b.exceptionTypeName == 'Working day'){
                                  console.log("Working day");
                                  exception = b.exceptionDate
                                  data = new Date(b.exceptionDate)
                                  console.log(data);
                                  data.setDate(data.getDate());
                                  this.workingDay.push(data)
                                }
                                console.log(this.workingDay);
                              }
                              console.log(b);
                            }  
                          )

                          i.holiday.map(a => {
                            console.log("Holiday");
                            if(a.holidayStatus == 1){
                              data = new Date(a.holidayDate)
                              console.log(data);
                              data.setDate(data.getDate());
                              this.arrayData.push(data)
                            }
                            console.log("Hoildays List Disable");
                            console.log(this.arrayData);
                          })
                          
                          // i.exceptions.map(b=> {
                          //   console.log("Working days in Api");
                          //   if(b.exceptionStatus == 1){
                          //     data = new Date(b.exceptionDate)
                          //     console.log(data);
                          //     data.setDate(data.getDate());
                          //     this.workingDay.push(data)
                          //   }
                          //   console.log("Workingdays List Disable");
                          //   console.log(this.workingDay);
                          // })



                          let b = ["1","2","3","4","5","6","0"]
                          i.calendarWorkingDays.map(dot => {

                            console.log("Calendar Working Days");
                            console.log("dot",dot);
                            console.log("calendarWorkdayStart",dot.calendarWorkdayStart);
                            console.log("workingdays",dot.workingdays);
                            const workingDays = b.filter((dayCode) => {
                              const dayNumber = (parseInt(dayCode) + 7 - dot.calendarWorkdayStart) % 7;
                              return dayNumber < dot.workingdays;
                            })
                            .map((dayCode) => dayCode);
                            // dot['calendarworkingdays'] = workingDays;
                            dot['workingDays'] = workingDays;
                            console.log("dot.workingdays", dot.workingDays);

                            var difference:any = b.filter(x => dot.workingDays.indexOf(x) === -1);
                            console.log(difference);
                            this.workDay = difference;
                            console.log(this.workDay);
                            console.log(this.myFilter);
                            this.myFilter = (d: Date): boolean => {
                              console.log(d);
                              const day = new Date(d).getDay();
                              console.log(day);
                              const time = new Date(d).getTime();
                              console.log(time);
                              if(day !== Number(difference[0]) && day !== Number(difference[1]) &&
                                 day !== Number(difference[2]) && day !== Number(difference[3]) &&
                                 day !== Number(difference[4]) && day !== Number(difference[5]) &&
                                 day !== Number(difference[6])){
                                  this.totalDay.push(new Date(d))
                                  this.workingDay.map(
                                    e=>{
                                      this.totalDay.push(new Date(e));
                                    }
                                  )
                                  console.log("Working Days");
                              }
                              console.log("Hoildays and Workingdays Disable Days in Calendar");
                              return this.totalDay.find(x=> new Date(x).getTime() == time) &&
                                     !this.arrayData.find(x=> new Date(x).getTime() == time)
                            }
                            console.log(this.myFilter);
                            console.log("Working Day List Disable");
                            console.log(this.totalDay);
                          })
                          console.log(i.calendarWorkingDays);
                        }
                      )
                    }
                  )
                }
              )
            }
          }
        );
      }
    )
    // employee list for fiteration process
    // this.leaveservice.getEmpList().subscribe(
    //   (d: any) => {
    //     this.ccList = d;
    //     this.applytoList = d

    //     // default cc value
    //     if (userType != "HumanResource") {
    //       let a = d.filter(e => {
    //         return e.userType == "HumanResource"
    //       })
    //       d.forEach(element => {
    //         this.ccToControl.setValue([element.organizationEmail])
    //       });
    //     }
    //   }
    // )
    // ngoninit
  }
  Cancel(){
    this.location.back();
  }
  get f() {
    return this.leaveForm.controls;
  }
  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  cancelForm() {
    this.router.navigate(['./Home']);
  }
  resetForm() {
    this.leaveForm.reset();
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // date validation
  someMethodName(date: any) {
    this.min = new Date(date.value)
    this.endDate_disable = false;

    let w = this.datepipe.transform(this.f.endDate.value, 'YYYY-MM-dd');
    let v = this.datepipe.transform(this.min, 'YYYY-MM-dd');
    if(w != null){
      if(w < v){
        this.show_error_msg = true;
      }
    }
  }


  
  nofoDays(i) {

    if (this.f.startDate.value>this.f.endDate.value) {
      console.log("date In Vaild");
      return
    }
    this.show_error_msg = false;

    console.log("No of Days Count");
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value);
    console.log(start);
    console.log(end);
    // this.From_Date=start;
    // this.End_Date=end;
    let v = this.datepipe.transform(start, 'YYYY-MM-dd');
    
    let w = this.datepipe.transform(end, 'YYYY-MM-dd');

    // let v = new Date(start).toISOString().replace(/T.*$/, '');
    // // tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
    // let w = new Date(end).toISOString().replace(/T.*$/, '');
    console.log(v);
    console.log(w);
     this.From_Date=v;
     this.End_Date=w;
   
    if (v != w) {
      this.weekDay = true;
      this.halfDay = false;
    }
    if (v == w) {
      this.halfDay = true;
      this.weekDay = false;
    }
    // Copy date objects so don't modify originals
    var s: any = new Date(+start);
    var e: any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(0, 0, 0, 0);
    e.setHours(0, 0, 0, 0);

    // Get the difference in whole days
    if(e!=0&&s!=0)
    {
      console.log("e to s");
      var totalDays = Math.round((e - s) / 8.64e7);
      console.log(totalDays);
    }
    else{
      console.log("s to e");
      var totalDays = Math.round((s - e) / 8.64e7);
      console.log(totalDays);
    }
    // var totalDays = Math.round((e - s) / 8.64e7);
    var totalDays = Math.round((e - s) / 8.64e7);
       // console.log("totalDays : "+totalDays);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;
  //  console.log("wholeWeeks : "+wholeWeeks);
    // Estimate business days as number of whole weeks * 5
    var days = 0
    days = wholeWeeks * this.WorkingDays + 1;

    // console.log(days)
    let dateArrays = new Array();

 

    let result = new Array()
    while (start <= end) {
      dateArrays.push(new Date(start));
      start.setDate(start.getDate() + 1);
    }
   //  console.log("dateArrays length" + dateArrays.length);
    dateArrays.map(
      c => {
        if (this.arrayData) {
          this.arrayData.map(
            t => {
              let MyDate = new Date(c)
              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)

              let MyDate1 = new Date(t)
              let MyDateString1 = MyDate1.getFullYear() + '-' + ('0' + (MyDate1.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate1.getDate()).slice(-2)
              if (MyDateString1 == MyDateString) {
                result.push(t)
              }
            }
          )
        }
      }
    )

    // If not even number of weeks, calc remaining weekend days
    s.setDate(s.getDate() + wholeWeeks * 7);

    let dat = new Array();

    dateArrays.map(
      e => {
        if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
        && e.getDay() != this.workDay[3] && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
        && e.getDay() != this.workDay[6]){
          dat.push(e)
        }

        // console.log("dat" + dat.length);
        // console.log("this.workingDay" + this.workingDay.length);
        // console.log("this.workingDay" + this.workingDay);
        // if(this.workingDay != ''){
        //   this.workingDay.map(
        //     e=>{
        //       dat.push(e)
        //       dat = dat.filter((test, index, array) =>
        //       index === array.findIndex((findTest) =>
        //          findTest === test
        //       )
        //    );
        //     }
        //   )
        // }


        
        // if (this.WorkingDays == 5){
        //   if (e.getDay() != 0 && e.getDay() != 6) {
        //     dat.push(e)
        //   }
        // }
        // else{
        //   if (e.getDay() != 0 && e.getDay() != 7) {
        //     dat.push(e)
        //   }
        // }
      }
    )
 //   console.log(result);
    let uniqueArray = result
      .map(function (date) { return date.getTime() })
      .filter(function (date, i, array) {
        return array.indexOf(date) === i;
      })
      .map(function (time) { return new Date(time); });

    let uniqueArray1 = new Array()

    uniqueArray.map(
      e=>{
        if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
        && e.getDay() != this.workDay[3]  && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
        && e.getDay() != this.workDay[6]){
          uniqueArray1.push(e)
        }
      }
    )

   // console.log("uniqueArray1.length"+ uniqueArray1.length);
   // console.log("dat.length" + dat.length);
    if (uniqueArray1.length > dat.length) {
      days = uniqueArray1.length - dat.length
    }
    if (dat.length > uniqueArray1.length) {
      days = dat.length - uniqueArray1.length
    }
    //    console.log("days" + days);
    this.noOFDays = days;
    console.log("days::::",days);
    console.log("this.noOfdays:::",this.noOFDays);
    
    
    this.leaveForm.controls['noOfDays'].setValue(this.noOFDays); // Update form control
    this.leaveForm.controls['noOfDays'].markAsDirty(); // Mark it as dirty
    this.leaveForm.controls['noOfDays'].updateValueAndValidity(); // Re-evaluate validation
    return days;
  }
 

  onSubmit(data) {
    console.log("data",data);
    
    this.processing=true;
    console.log("onSubmit", this.leaveForm.value);
    let x = this.leaveForm.value;
    this.submitted =true;
    let V = new Date(x.startDate).toISOString().replace(/T.*$/, '')
    let W = new Date(x.endDate).toISOString().replace(/T.*$/, '')
    if (V != W) {
      x['noOfLeaveDays'] = this.noOFDays;
      x['halfday'] = "false"
      this.f.noOfDays.setValue('Leave')
    }
    console.log("test");
    if (V == W) {
      x['noOfLeaveDays'] = Number(x.noOfDays);
      if (x.noOfLeaveDays == 1) {
        x['halfday'] = "false"
      }
      else {
        x['halfday'] = "true"
      }
      if (x.noOfDays == "Leave") {
        this.leaveForm.controls['noOfDays'].setErrors({ 'required': true });

        return
      }
    }
    console.log("test");

    // console.log("this.invalidInput" + this.invalidInput);
    // if (this.leaveForm.invalid||this.invalidInput) {
    //   for (const name in  this.leaveForm.controls) {
    //     if ( this.leaveForm.controls[name].invalid) {
    //       console.log("name : " + name);
    //     }
    // }
    
    //   return
    // }

    if (this.leaveForm.invalid || this.invalidInput) {
      this.processing = true; // Re-enable the button if the form is invalid
      return;
    }
  
    console.log("test");
    // if (this.applyToControl.invalid) {
    //   return
    // }
    console.log("test");
    // start date
    let a = this.f.startDate.value;
    if (x) {
      let b = new Date(a);
      b.setMinutes(b.getMinutes() + 480);
      let s = b.toISOString()
      x['startDate'] = s
    }
    console.log("test");
    // endDate
    let c = this.f.endDate.value;
    if (c) {
      let d = new Date(c);
      d.setMinutes(d.getMinutes() + 480);
      let e = d.toISOString();
      x['endDate'] = e;
    }
    console.log("test");
    // apply leave
    let _localStorageData = JSON.parse(localStorage.getItem("enoteUserData"))

    // let y: any = _localStorageData.reportingToSecond
    // x['leavereportingTo'] = this.firstApproverEmail;
    // if (this.fyi.value == '') {
    //   x['leavereportingToSecond'] = null;
    // } else {
    //   x['leavereportingToSecond'] = this.secondApproverEmail;
    // }
    x['leaveType'] = {
      leaveTypeId: parseInt(this.f.leaveType.value)
    }

    // x['applyTo'] = [this.organizationEmail];
    // x['cc'] = []

    let submit = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    x['submittedOn'] = submit;
    x['leaveStatus'] = 3;
    x['status'] = 1;
    x.employeeId=this.empid;

    console.log(JSON.stringify(x))

    Swal.fire({
      title: 'Are you sure? Do you want to Apply Leave!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Apply'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.processing = false;

        this.leaveservice.applyLeave(x).subscribe(
          d => {
            console.log((d as any).message);
            // console.log(d.);

            // Swal.fire({
            //   text: 'Applied',
            //   icon: 'success'
            // }
            // );

            if((d as any).statusCode == 200){
              this.success = true;
              this.success_msg =(d as any).message;
              // this.error =  true;
              // this.error_msg =(d as any).message;
            }

            if((d as any).statusCode == 204){
              // this.success = true;
              // this.success_msg =(d as any).message;
              this.error =  true;
              this.error_msg = (d as any).message;
            }
          
            this.isLoading = false;
            this.processing = false;

            setTimeout(() => { this.success = false;   
              this.router.navigate(['/home/myleave-list']);
          }, 3000);

          },
          err => {
            // Swal.fire(
            //   {
            //     text: err.error.message,
            //     icon: 'error'
            //   }
            // )
            this.error = true;
                  this.error_msg = err.error.message;
                  this.isLoading = false
                  this.processing = true;


            setTimeout(() => { this.error = false; }, 3000);   

          },
          () => {
            this.isLoading = false
            this.processing = false;

          }
        );
      } 
    })
  }
  invalidInput: boolean = false;

  validateInput(event: any) {
      const inputElement = event.target;
      let value = inputElement.value;
  
      if (value.startsWith(' ')) {
          value = value.trimStart();
          inputElement.value = value;
      }
  
      this.invalidInput = value.trim().length === 0; // Check if the input consists of only spaces
  }
}


