import { Component, OnInit,Inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { PmsService } from '../../providers/pms.service';
import { UserService } from '../../providers/user.service';
import { ShowapproverlistComponent }from '../showapproverlist/showapproverlist.component'
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
// import { ShowapproverlistComponent } '../showapproverlist/showapproverlist.component';
import { L } from '@angular/cdk/keycodes';
import { StatusServiceService } from 'src/app/status-service.service';
import { EMPTY, Subject, catchError, switchMap, takeUntil, throwError } from 'rxjs';
import { HeaderService } from 'src/app/providers/header.service';
// import { truncateSync } from 'fs';
// import { log } from 'console';
@Component({
  selector: 'app-appraisal',
  templateUrl: './appraisal.component.html',
  styleUrls: ['./appraisal.component.scss']
})
export class AppraisalComponent implements OnInit {
  loader: boolean=false;
  backdrop: boolean;
  nodatafound: boolean=true;
  pageViews: any;
  sortedData: any;
  dateFormat : any;
  tableSearch : any;
  searchForm!:FormGroup;
  resultData: any = {};
  pagePerItem:any=5;
  error:any=false;
  p:number = 1;
  mySelect:any =5;
  beforepage :any;
  empolyeereadonly :boolean = true;
  sucess_msg:any;
  employeetData: any = '';
  autoCompleteSearch: number;
  error_msg:any;
  query: any = '';
  success = false;
  usertData: any;
  orgId: any;
  apprisalData: any;
  status: any;
  userTypeId: any;
  employeeData: any;
  statusList: any;
  localempId: any;
  empId: any;
  apprisal: any;
  manageList: any=[];
  Data: any=[];
  submitted: any=false;
  show: boolean=false;
  employee: string;
  empList: any[]=[];
  employeeId: any;
  reviewStatus: any;
  employeeNotFound: boolean;


  unsubscribe$: any;
  employeeCode: string;
  employeeNumber: number;
  selectedemployeeId: any;
  employeeIds: any;
  employeeCodes: string;
  selectedemployee: any;
  employeeSelected: boolean;

  showTable:boolean=false;
  // clearButtonEnabled: boolean = false;
  clearButtonDisabled: boolean = false;

  apiOneCompleted:boolean = false;
  apiTwoCompleted:boolean = false;
  hasData:boolean=false;

  submitButtonDisabled:boolean = false;
  isFyi: boolean = false;



  // id:number=1;

  


  

  constructor(
    private router:Router,
    private pmsService:PmsService,
    private dialog:MatDialog,
    private fb:FormBuilder,
    private userService:UserService,
    private headerservice: HeaderService,

    
    @Inject(AppComponent) public AppData: any,
  ) { }

  

  ngOnInit(): void {
    this.loader=true;
    this.dateFormat = localStorage.getItem('dateFormatName');
    this.nodatafound =false;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(this.usertData);
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    // this.userTypeId = this.usertData.userType.userTypeId;
    // console.log(this.userTypeId);
    // this.headerservice.setTitle('Manage Appraisal')
    this.headerservice.setTitle('');
    this.empId= this.usertData.employee.employeeId;
    console.log("empId:",this.empId);
    this.searchForm=this.fb.group({
      keywords:[null],
      status:["",Validators.required]
    })
    
    // this.status=this.searchForm.value.status;
    
    // this.getFilterFormData(this.orgId,this.status);
    
    this.getFilterFormData();
    let id=1;
    this.pmsService.getStatus(id).subscribe(
      (statusData: any) => {
        console.log("StatusList", statusData);
        this.statusList = statusData;
        // this.financialyearError=false;
        this.searchForm.patchValue(
          {
            status:3
          }
        )
        console.log(this.searchForm.value.status);
      }
      
    );
  }
  
  viewApprover(row)
  {
    console.log("apprisalHeaderId:",row.apprisalHeaderId);
      const dialogRef = this.dialog.open(ShowapproverlistComponent, {
        width: '500px',
        height:'500px',
        data : row
      })
      console.log("apprisalHeaderId:", row.apprisalHeaderId);
  }

 


  // getFilterFormData() {
  //   let status=this.searchForm.value.status;
  //    console.log(status);
  //   console.log("Fetching appraisal data for employee", this.empId);
  //   this.nodatafound = false;
  //   this.manageList = [];
  //   this.loader = true;
  //   this.showTable=false;
    
    
  //   // Employee Based API
  //   this.pmsService.getApprisalEmpCode(this.empId).subscribe((result: any) => {
  //     console.log("List Api data", this.empId);
  //     if (result.statusCode == 204) {
  //       console.log("nodata");
  //       this.manageList = [];
  //       this.showTable=true;
        
       

  //     } else {
  //       this.Data = result;
  //       console.log(this.Data);
  //       this.Data.forEach(ele => {
  //         console.log(ele);
  //         console.log(ele.apprisalStatus.listTypeValueId);
  //         if (ele.apprisalStatus.listTypeValueId == 3) {
  //           this.manageList.push(ele);
  //         }
  //       });
  //     }
  //     console.log("own Appraisal1", this.manageList);
  
  //     // Check the conditions for each approval stage
  //     this.manageList.forEach(element => {
  //       console.log("Elements", element);
  //       const approvals = element.approvals?.approver;
  
  //       if (approvals?.fyiObject.employeeId == this.empId) {
  //         console.log("FYI:", approvals.fyiObject?.employeeId);
  //       }
  //       if (approvals?.approverOneObject?.employeeId == this.empId && element.approvals.approverOneStatus.listTypeValueId == 3) {
  //         console.log("ApproverOne:", approvals.approverOneObject?.employeeId);
  //       }
  //       if (approvals?.approverTwoObject?.employeeId == this.empId && element.approvals.approverOneStatus.listTypeValueId == 5 && element.approvals.approverTwoStatus.listTypeValueId == 3) {
  //         console.log("ApproverTwo:", approvals.approverTwoObject?.employeeId);
  //       }
  //       if (approvals?.approverThreeObject?.employeeId == this.empId && element.approvals.approverTwoStatus.listTypeValueId == 5 && element.approvals.approverThreeStatus.listTypeValueId == 3) {
  //         console.log("ApproverThree:", approvals.approverThreeObject?.employeeId);
  //       }
  //       if (approvals?.approverFourObject?.employeeId == this.empId && element.approvals.approverThreeStatus.listTypeValueId == 5 && element.approvals.approverFourStatus.listTypeValueId == 3) {
  //         console.log("ApproverFour:", approvals.approverFourObject?.employeeId);
  //       }
  //       if (approvals?.approverFiveObject?.employeeId == this.empId && element.approvals.approverFourStatus.listTypeValueId == 5 && element.approvals.approverFiveStatus.listTypeValueId == 3) {
  //         console.log("ApproverFive:", approvals.approverFiveObject?.employeeId);
  //       }
  //       if (element.employeeId == this.empId) {
  //         console.log("Individual employee based:", element.employeeId);
  //       }
  //     });
  
  //     console.log("manageList Length", this.manageList.length);
  //     if (this.manageList.length == 0) {
  //       this.nodatafound = true;
  //       this.manageList = [];
        
  //       console.log("NO_DATA");
  //     }
  //     this.loader = false;
  //     this.showTable=true;
  //   });


  //   this.pmsService.getApprisalEmpCodeNew(this.empId).subscribe((filterData: any) => {
  //     this.loader=false;
  //     this.showTable=true;
  //     if (filterData.statusCode == 204) {
  //         console.log("nodata");
  //         this.manageList = [];
  //         this.nodatafound = true;
  //         // this.loader=true;
  //     } else {
  //       // this.loader = false;
  //       this.submitted = false;
  //       this.nodatafound = false;
  //       this.apprisalData = filterData;
        
  //       console.log("apprisalData", this.apprisalData);
  //       this.apprisalData.forEach(element => {
  //         console.log(element,"t1t1t1t1");
  //           console.log("element.employee:", element.employee.employeeId,
  //                           "approvals:", element.approvals,
  //                           "approvers", element.approvals.approver);
  //           console.log("emp", this.empId);
  //           console.log("own Apprsaial2", this.manageList);
            
  //           if (element.approvals && element.approvals.approver) {
  //               console.log("Approval and Approver exist for element:", element);
    
  //               console.log("FYI Object employeeId:", element.approvals.approver.fyiObject.employeeId);
  //               console.log("Approver ID:", element.approvals.approver.approverId);
  //               console.log(element.approvals.approver.fyiObject);
    
  //               if (element.approvals.approver.fyiObject && element.approvals.approver.fyiObject.employeeId == this.empId) {
  //                   this.show = true;
  //                   console.log("FYI:", element.approvals.approver.fyiObject.employeeId);
  //                   this.manageList.push(element);
  //               } else if (element.approvals.approver.approverOneObject &&
  //                          element.approvals.approver.approverOneObject.employeeId == this.empId &&
  //                          element.approvals.approverOneStatus.listTypeValueId == 3 &&
  //                          element.approvals.approver.approverOneObject.employeeId) {
  //                   this.show = true;
  //                   console.log("ApproverOne:", element.approvals.approver.approverOneObject.employeeId);
  //                   this.manageList.push(element);

  //               } else if (element.approvals.approver.approverTwoObject.employeeId == this.empId 
  //                         && element.approvals.approverOneStatus.listTypeValueId == 5 && 
  //                         element.approvals.approverTwoStatus.listTypeValueId == 3 && 
  //                         element.approvals.approver.approverTwoObject.employeeId) {
  //                   this.show = true;
  //                   console.log("ApproverTwo:", element.approvals.approver.approverTwoObject.employeeId);
  //                   this.manageList.push(element);

  //               // } else if (element.approvals.approver.approverTwoObject.employeeId == this.empId 
  //               //   && element.approvals.approverOneStatus.listTypeValueId == 5 && 
  //               //   element.approvals.approverTwoStatus.listTypeValueId == 5 && 
  //               //   element.approvals.approverThreeStatus.listTypeValueId == 3 && 
  //               //   element.approvals.approver.approverTwoObject.employeeId) {
  //               // this.show = true;
  //               // console.log("ApproverTwo:", element.approvals.approver.approverTwoObject.employeeId);
  //               // this.manageList.push(element);


  //               }else if (element.approvals.approver.approverThreeObject.employeeId == this.empId 
  //                         && element.approvals.approverOneStatus.listTypeValueId == 5 && 
  //                         element.approvals.approverTwoStatus.listTypeValueId == 5 && 
  //                         element.approvals.approverThreeStatus.listTypeValueId == 3 && 
  //                         element.approvals.approver.approverThreeObject.employeeId) {
  //                       this.show = true;
  //                       console.log("ApproverThree:", element.approvals.approver.approverThreeObject.employeeId);
  //                       this.manageList.push(element);

  //               }else if (element.approvals.approver.approverFourObject.employeeId == this.empId 
  //                 && element.approvals.approverOneStatus.listTypeValueId == 5 && 
  //                 element.approvals.approverTwoStatus.listTypeValueId == 5 && 
  //                 element.approvals.approverThreeStatus.listTypeValueId == 5 && 
  //                 element.approvals.approverFourStatus.listTypeValueId == 3 && 
  //                 element.approvals.approver.approverFourObject.employeeId) {
  //               this.show = true;
  //               console.log("ApproverFour:", element.approvals.approver.approverFourObject.employeeId);
  //               this.manageList.push(element);

  //               }else if (element.approvals.approver.approverFiveObject.employeeId == this.empId 
  //                 && element.approvals.approverOneStatus.listTypeValueId == 5 && 
  //                 element.approvals.approverTwoStatus.listTypeValueId == 5 && 
  //                 element.approvals.approverThreeStatus.listTypeValueId == 5 && 
  //                 element.approvals.approverFourStatus.listTypeValueId == 5 &&
  //                  element.approvals.approverFiveStatus.listTypeValueId == 3 && 
  //                  element.approvals.approver.approverFiveObject.employeeId) {
  //               this.show = true;
  //               console.log("ApproverFive:", element.approvals.approver.approverFiveObject.employeeId);
  //               this.manageList.push(element);
  //               }


                
    
  //               // Similarly, check and handle other properties...
    
  //               if (element.employeeId == this.empId) {
  //                   console.log("Individual employee Based:", element.employeeId);
  //                   this.manageList.push(element);
  //               }
  //           } else {
  //               console.log("Approvals or Approvers not found for element:", element);
  //           }
  //           console.log("manageList:", this.manageList);
  //       });
  //      }
  //      this.loader = false; 
  //      this.showTable=true;
  // });

    


    

    
  // }

  




  
  
  


  // empSearch(event: any): void {
  //   if (event.target.value) {
  //     const input = event.target as HTMLInputElement;
  //     input.classList.remove('select-placeholder');
  //   } else {
  //     const input = event.target as HTMLInputElement;
  //     input.classList.add('black-placeholder');
  //   }
  //   console.log(event.target.value);
  //   const employeeNumber = event.target.value;
  //   console.log(employeeNumber);
  
  //   if (this.unsubscribe$) {
  //     this.unsubscribe$.next(); // Complete previous subscription if it exists
  //     this.unsubscribe$.complete();
  //   }
  //   this.unsubscribe$ = new Subject<void>();
  
  //   if (employeeNumber && employeeNumber.length >= 2) {
  //     this.userService
  //       .employeeSearch(this.orgId, employeeNumber)
  //       .pipe(
  //         takeUntil(this.unsubscribe$),
  //         switchMap(() =>
  //           this.userService.employeeSearch(this.orgId, employeeNumber).pipe(
  //             catchError((err) => {
  //               if (err.error.status === 500) {
  //                 this.empList = [];
  //                 this.employeeNotFound = true;
  //               }
  //               console.log('err', err);
  //               console.error('An error occurred:', err);
  //               return EMPTY;
  //             })
  //           )
  //         )
  //       )
  //       .subscribe(
  //         (result: any) => {
  //           console.log('result', result);
  //           if (result.statusCode==204) {
  //             console.log("if entered");
              
  //             this.empList = [];
  //             this.employeeNotFound = true;
  //           } else {
  //             if (Array.isArray(result)) {
  //               // this.empList=[]
  //               this.empList = result;
  //             } else if (typeof result === 'object') {
  //               this.empList = Object.values(result);
  //             } else {
  //               console.error('Unexpected data type:', result);
  //             }
  //             this.employeeNotFound = false;
  //           }
  //         },
  //         (err) => {
  //           if (err.error.status === 500) {
  //             this.empList = [];
  //             this.employeeNotFound = true;
  //           }
  //           console.error('An error occurred:', err);
  //         }
  //       );
  //   } else {
  //     // Handle condition when employee number is not valid
  //     this.empList = [];
  //     this.employeeNotFound = false; // Depending on your use case, this might need to be true
  //   }
  // }


  // getFilterFormData() {
  //   let status = this.searchForm.value.status;
  //   console.log(status);
  //   console.log("Fetching appraisal data for employee", this.empId);
  //   this.nodatafound = false;
  //   this.manageList = [];
  //   this.loader = true;
  //   this.showTable = false;
  
  //   const handleApiResponse = (result) => {
  //     console.log("List Api data", this.empId);
  //     if (result.statusCode == 204) {
  //       console.log("nodata");
  //       return false;
  //     } else {
  //       this.Data = result;
  //       console.log(this.Data);
  //       this.Data.forEach(ele => {
  //         console.log(ele);
  //         console.log(ele.apprisalStatus.listTypeValueId);
  //         if (ele.apprisalStatus.listTypeValueId == 3) {
  //           this.manageList.push(ele);
  //         }
  //       });
  
  //       // Check the conditions for each approval stage
  //       this.manageList.forEach(element => {
  //         console.log("Elements", element);
  //         const approvals = element.approvals?.approver;
  
  //         if (approvals?.fyiObject?.employeeId == this.empId) {
  //           console.log("FYI:", approvals.fyiObject?.employeeId);
  //         }
  //         if (approvals?.approverOneObject?.employeeId == this.empId && element.approvals.approverOneStatus.listTypeValueId == 3) {
  //           console.log("ApproverOne:", approvals.approverOneObject?.employeeId);
  //         }
  //         if (approvals?.approverTwoObject?.employeeId == this.empId && element.approvals.approverOneStatus.listTypeValueId == 5 && element.approvals.approverTwoStatus.listTypeValueId == 3) {
  //           console.log("ApproverTwo:", approvals.approverTwoObject?.employeeId);
  //         }
  //         if (approvals?.approverThreeObject?.employeeId == this.empId && element.approvals.approverTwoStatus.listTypeValueId == 5 && element.approvals.approverThreeStatus.listTypeValueId == 3) {
  //           console.log("ApproverThree:", approvals.approverThreeObject?.employeeId);
  //         }
  //         if (approvals?.approverFourObject?.employeeId == this.empId && element.approvals.approverThreeStatus.listTypeValueId == 5 && element.approvals.approverFourStatus.listTypeValueId == 3) {
  //           console.log("ApproverFour:", approvals.approverFourObject?.employeeId);
  //         }
  //         if (approvals?.approverFiveObject?.employeeId == this.empId && element.approvals.approverFourStatus.listTypeValueId == 5 && element.approvals.approverFiveStatus.listTypeValueId == 3) {
  //           console.log("ApproverFive:", approvals.approverFiveObject?.employeeId);
  //         }
  //         if (element.employeeId == this.empId) {
  //           console.log("Individual employee based:", element.employeeId);
  //         }
  //       });
  
  //       console.log("manageList Length", this.manageList.length);
  //       return true;
  //     }
  //   };
  
  //   let apiOneCompleted = false;
  //   let apiTwoCompleted = false;
  //   let hasData = false;
  
  //   const checkForNoData = () => {
  //     if (apiOneCompleted && apiTwoCompleted) {
  //       if (!hasData) {
  //         this.nodatafound = true;
  //         console.log("NO_DATA");
  //       }
  //       this.loader = false;
  //       this.showTable = true;
  //     }
  //   };
  
  //   // Employee Based API
  //   this.pmsService.getApprisalEmpCode(this.empId).subscribe((result: any) => {
  //     apiOneCompleted = true;
  //     if (handleApiResponse(result)) {
  //       hasData = true;
  //     }
  //     checkForNoData();
  //   });
  
  //   this.pmsService.getApprisalEmpCodeNew(this.empId).subscribe((result: any) => {
  //     apiTwoCompleted = true;
  //     if (handleApiResponse(result)) {
  //       hasData = true;
  //     }
  //     checkForNoData();
  //   });
  // }
  



  

  empSearch(event, event1: KeyboardEvent): void {
    if (event1.key === ' ') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');
      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      if (this.empList?.length > 0) {
        this.nodatafound = false;
      }
  
      this.empList = [];
      console.log(event.target.value);
      let employeeNumber = event.target.value;
      employeeNumber = employeeNumber.replace(/-.*/, '');
      console.log(employeeNumber);
  
      // Cancel the previous API request
      if (this.unsubscribe$) {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
      this.unsubscribe$ = new Subject<void>();
  
      if (
        this.employeeId === 0 ||
        this.employeeId === undefined ||
        this.employeeId === null
      ) {
        console.log('employee key', employeeNumber);
  
        if (employeeNumber && employeeNumber.length >= 2) {
          this.userService
            .employeeSearch(this.orgId, employeeNumber)
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() => this.userService.employeeSearch(this.orgId, employeeNumber)),
              catchError((err) => {
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.empList = [];
                  this.employeeNotFound = true;
                }
                console.error('Error fetching employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error fetching employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);
  
              if (result && result.statusCode === 204) {
                this.employeeNotFound = true;
              }
              this.empList.length = 0;
  
              if (result && Array.isArray(result)) {
                result.forEach((element) => {
                  let employee = {
                    employee: {
                      employeeId: element.employeeId,
                      firstName: element.firstName,
                      employeeNumber: element.employeeNumber,
                    },
                  };
  
                  if (Object.keys(employee).length > 0) {
                    this.employeeNotFound = false;
                  } else {
                    this.employeeNotFound = true;
                  }
                  this.empList.push(employee);
                  console.log(this.empList);
                });
  
                if (this.selectedemployeeId?.length >= 1) {
                  this.employeeSelected = true;
                }
              }
  
              if (result && result.errorCode === 204) {
                // alert('No Employee Found');
                this.employeeNotFound = true;
                this.empList.length = 0;
                this.empList.push('No Employee Found');
                this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
              }
            });
        }
      } else {
        // Set selectedemployeeId to null if the input is empty
        this.selectedemployeeId = null;
      }
    }
  }

  // empSelect(emp)
  // {
  //   console.log(emp);
  //   console.log(this.employee);
  //   this.employeeId=emp.employeeId;
  //   console.log(this.employeeId);
  //   this.employee=emp.employeeNumber+"-"+emp.firstName;
  //   // if(this.approverId){
  //     this.searchForm.patchValue({
  //       keywords: this.employee 
  //    });
   
  // }


  empSelect(emp) {
    console.log('emp',emp);
    this.employeeId = emp.employee.employeeId;
    this.employeeCodes = emp.employee.employeeNumber + '-' + emp.employee.firstName;
    // this.employeeCode = this.employeeStoring;
    console.log(this.employeeCodes);
    // console.log(this.employeeStoring);
    this.employeeNumber = emp.employee.employeeNumber;
    // this.selectedemployee = this.employee;
    this.selectedemployeeId=this.employee;

    this.searchForm.patchValue({
      keywords: this.employeeCodes
    });
console.log("employee Id after selecting::",this.employeeId);

    console.log('this.employee::',this.selectedemployeeId);
    console.log("wtts id tell me?::",this.employeeId);
    // this.getTask()
}

// handleBackspace(event): void {
//   if (
//     event.key === 'Backspace' &&
//     !this.selectedemployee &&
//     (this.selectedemployeeId === undefined || this.selectedemployeeId === null || this.selectedemployeeId.trim().length === 0)
//   ) {
//     this.selectedemployeeId = undefined;
//     this.employeeId = undefined;
//     this.empList.length = 0;
//     this.employee = undefined;
//     this.employeeNumber = undefined;
//     this.selectedemployee = undefined;
//     this.selectedemployeeId = undefined;
//     this.employeeNotFound = false;
    
//   }
// }




handleBackspace(event): void {
  if (
    event.key === 'Backspace' &&
    !this.selectedemployee &&
    (this.selectedemployeeId === undefined || this.selectedemployeeId === null || this.selectedemployeeId.trim().length === 0)
  ) {
    this.selectedemployeeId = undefined;
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.employeeNotFound = false;
  }
}

noEmployeeFound() {
  this.selectedemployeeId = undefined;
  this.selectedemployeeId = undefined; // Set selectedemployeeId to null
  this.employeeId = undefined;
  this.empList.length = 0;
  this.employee = undefined;
  this.employeeNumber = undefined;
  this.selectedemployee = undefined;
  this.selectedemployeeId = undefined;
  this.employeeNotFound = false;
}



  get validate() 
  { 
    return this.searchForm.controls; 
  }



 



    validateInput(event: Event) {
      const input = event.target as HTMLInputElement;
      let value = input.value;
    
      // Remove leading spaces
      if (value.startsWith(' ')) {
        value = value.trimStart();
      }
    
      // Remove spaces in between
      value = value.replace(/\s+/g, '');
    
      input.value = value;
    }



  

  clearTable()
  
  {
    console.log(this.statusList);
    console.log(this.statusList[0].listTypeValueId);

    // this.searchForm.get('status').setValue(this.statusList[2].listTypeValueId);
    setTimeout(()=>{
      console.log("gfgdg")
      this.searchForm.patchValue(
        {
          status: this.statusList[0].listTypeValueId
        }
      )

  },200)
    
    console.log(this.searchForm.value);
    this.apprisalData=[]; 
   
    console.log('clear');
    this.searchForm.clearValidators;
    this.nodatafound = true;
    this.employeeNotFound = false;
    // this.clearButtonEnabled = false;
    this.getFilterFormData();
    this.searchForm.reset();
     this.employeeId=undefined;
     this.selectedemployeeId =undefined;
    this.empList=[];
   
  }
  
  getActiveRoles()
  {
   /*  this.employee_service.getActiveRoles().subscribe((result: any) => {
      this.activeRolesData = result;
    }) */
  }
  tableView(val) //Grid, List & Card View
  {
    this.pageViews = val;
  }
  ucFirst(string)
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '')
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) =>
    {
      const isAsc = sort.direction === 'asc';

      switch (sort.active)
      {
        case 'roleName':
          return compare(a.roleName, b.roleName, isAsc);

        case 'employeeNumber':
          return compare(a.employeeNumber, b.employeeNumber, isAsc);

        case 'firstName':
          return compare(a.firstName, b.firstName, isAsc);

        case 'mobileNumber':
          return compare(a.mobileNumber, b.mobileNumber, isAsc);

        case 'organizationEmail':
          return compare(a.organizationEmail, b.organizationEmail, isAsc);

        case 'dateOfBirth':
          return compare(a.dateOfBirth, b.dateOfBirth, isAsc);

        case 'createDateTime':
            return compare(a.createDateTime, b.createDateTime, isAsc);

        default:
          return 0;
      }
    });

  
  }
  onItemsPerPageChange(){
    this.p = 1;
  }

  // preventSpaceBeforeInput(event: Event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
  //       event.preventDefault();
  //   }
  // }
  preventSpaceBeforeInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
        event.preventDefault();
      }
    }


  searchSubmit()

  {
    this.p=1;
     console.log("Submit");
     let key=this.searchForm.value.keywords;
     console.log(key);
     let status=this.searchForm.value.status;
     console.log(status);



     this.submitted=true;
     this.loader = true;
     this.showTable=false;
     this.submitButtonDisabled= true;
     this.clearButtonDisabled=true;

    //  if(!key){
    //   this.searchForm.controls['keywords'].reset();
    //   this.employeeId=undefined;
    //   alert('Please Search valid Employee');
      
    //   console.log("type")
    //   this.getFilterFormData();
      
    // }


    //  if(key==undefined) 
    //  {
    //   console.log("Empty");
    //   alert("please enter keyword");
    //   this.loader = false; 
    //  }

    console.log(this.searchForm.value.keywords);
    console.log(status);
    console.log(this.employeeId);
    
    if(this.employeeId === undefined && this.searchForm.value.keywords != null ) {

      console.log('dead');
      alert("please enter valid Employee");
    //  this.searchForm.get('keywords').setValue('');
     this.searchForm.controls['keywords'].reset();
    //  this.employeeId=undefined;
      this.getFilterFormData();

    }

     
    //  if(this.searchForm.invalid)
    //  {
    //   console.log("invaild");
    //   return;
    //  }
     if((key!=null||key!=undefined)&&(status==null||status==undefined))
      
      
      
     {
      console.log("empCode only",status);
      console.log("orgId:",this.orgId);
      this.manageList=[];
      this.showTable=false;
     
      

     this.pmsService.getApprisalEmpCode(this.empId).subscribe((filterData: any) => 
      
     {
      this.submitButtonDisabled= false;
      this.clearButtonDisabled=false;
      this.loader=false;
      this.showTable=true;
      if(filterData.statusCode==204 )
       {
         console.log("nodata");
         this.manageList=[];
         this.nodatafound=true;
        //  this.loader=false;
       }
      //  if(filterData.length == 0||filterData.length == undefined)
      //  {
      //    this.manageList=[];
      //    this.nodatafound = true;
      //  }
      else
      {
      //  this.loader=false;
      this.submitButtonDisabled= false;
      this.clearButtonDisabled=false;
       this.submitted=false;
       this.nodatafound = false;
       this.apprisalData = filterData;
       console.log(this.apprisalData);
       this.apprisalData.forEach(element => 
      {
        console.log("element.employee:",element.employee.employeeId);
        console.log("own Apprsaial2",this.manageList);
        console.log("element",element);
        if(element.approvals.approver.fyiObject!=undefined)
        {
          console.log("FYi:",element.approvals.approver.fyiObject);
          if(element.approvals.approver.fyiObject.employeeId==this.empId)
          {
            this.show=true;
            console.log("FYi:",element.approvals.approver.fyiObject.employeeId);
             this.manageList.push(element);
          }
        }
        if(element.approvals.approver.approverOneObject!=undefined)
        {
          console.log("Appr 1 Object",element.approvals.approver.approverOneObject);
          if(element.approvals.approver.approverOneObject.employeeId==this.empId&&element.approvals.approverOneStatus.listTypeValueId == 3
            && element.approvals.approver.approverOneObject.employeeId)
          {
            this.show=true;
             console.log("ApproverOne:",element.approvals.approver.approverOneObject.employeeId);
             this.manageList.push(element);
          }
        }
        if(element.approvals.approver.approverTwoObject!=undefined)
        {
         console.log("Appr 2 Object",element.approvals.approver.approverTwoObject);
           if(element.approvals.approver.approverTwoObject==this.empId&&element.approvals.approverOneStatus.listTypeValueId == 5&&element.approvals.approverTwoObject.listTypeValueId == 3
            && element.approvals.approver.approverTwoObject.employeeId)
          {
            this.show=true;
             console.log("ApproverTwo:",element.approvals.approver.approverTwoObject.employeeId);
             this.manageList.push(element);
          }
        }
        if(element.approvals.approver.approverThreeObject!=undefined)
        {
          console.log("Appr 3 Object",element.approvals.approver.approverThreeObject);
           if(element.approvals.approver.approverThreeObject.employeeId==this.empId&&element.approvals.approverTwoObject.listTypeValueId == 5 &&element.approvals.approverThreeObject.listTypeValueId == 3
            && element.approvals.approver.approverThreeObject.employeeId)
          {
             this.show=true;
             console.log("ApproverThree:",element.approver.approverThreeObject);
             this.manageList.push(element);
          }
        }
        if(element.approvals.approver.approverFourObject!=undefined)
        {
          console.log("Appr 5 Object",element.approvals.approver.approverFourObject);
          if(element.approvals.approver.approverFourObject.employeeId==this.empId&&element.approvals.approverThreeObject.listTypeValueId == 5 &&element.approvals.approverFourObject.listTypeValueId == 3
            && element.approvals.approver.approverFourObject.employeeId)
          {
             this.show=true;
             console.log("ApproverFour:",element.approver.approverFourObject);
             this.manageList.push(element);
          }
        }
        if(element.approvals.approver.approverFiveObject!=undefined)
        {
          console.log("Appr 5 Object",element.approvals.approver.approverFiveObject);
          if(element.approvals.approver.approverFiveObject.employeeId==this.empId&&element.approvals.approverFourObject.listTypeValueId == 5 &&element.approvals.approverFiveObject.listTypeValueId == 3
            && element.approvals.approver.approverFiveObject.employeeId)
          {
             this.show=true;
             console.log("ApproverFive:",element.approver.approverFiveObject);
             this.manageList.push(element);
          }
        }
        if(element.employeeId==this.empId)
          {
            console.log("Indidual employee Based:",element.employeeId);
             this.manageList.push(element);
          }
          console.log("manageList",this.manageList);
        });
       }
       this.loader = false;
       this.showTable=true;
     })
     }
    

     else if((status!=null||status!=undefined)&&(key==null||key==undefined))
     {
      console.log("status Only");
      console.log("orgId:",this.orgId);
      this.manageList=[];

    
    this.pmsService.getApprisalStatus(status, this.orgId,this.empId).subscribe((filterData: any) => {
      this.submitButtonDisabled= false;
      this.clearButtonDisabled=false;
      this.loader=false;
      this.showTable=true;
      if (filterData.statusCode == 204) {
          console.log("nodata");
          this.manageList = [];
          this.nodatafound = true;
          // this.loader=true;
      } else {
        // this.loader = false;
        this.submitButtonDisabled= false;
        this.clearButtonDisabled=false;
        this.submitted = false;
        this.nodatafound = false;
        this.apprisalData = filterData;
        
        console.log("apprisalData", this.apprisalData);
        this.apprisalData.forEach(element => {
          console.log(element,"t1t1t1t1");
            console.log("element.employee:", element.employee.employeeId,
                            "approvals:", element.approvals,
                            "approvers", element.approvals.approver);
            console.log("emp", this.empId);
            console.log("own Apprsaial2", this.manageList);
            
            if (element.approvals && element.approvals.approver) {
                console.log("Approval and Approver exist for element:", element);
    
                console.log("FYI Object employeeId:", element.approvals.approver.fyiObject.employeeId);
                console.log("Approver ID:", element.approvals.approver.approverId);
                console.log(element.approvals.approver.fyiObject);
                console.log(this.empId);
    
                if (element.approvals.approver.fyiObject && element.approvals.approver.fyiObject.employeeId == this.empId) {
                    this.show = true;
                    console.log("FYI:", element.approvals.approver.fyiObject.employeeId);
                    this.manageList.push(element);

                } else if (element.approvals.approver.approverOneObject &&
                           element.approvals.approver.approverOneObject.employeeId == this.empId &&
                           element.approvals.approverOneStatus.listTypeValueId == 3 &&
                           element.approvals.approver.approverOneObject.employeeId) {
                    this.show = true;
                    console.log("ApproverOne:", element.approvals.approver.approverOneObject.employeeId);
                    this.manageList.push(element);

                } else if (element.approvals.approver.approverTwoObject.employeeId == this.empId 
                          && element.approvals.approverOneStatus.listTypeValueId == 5 && 
                          element.approvals.approverTwoStatus.listTypeValueId == 3 && 
                          element.approvals.approver.approverTwoObject.employeeId) {
                    this.show = true;
                    console.log("ApproverTwo:", element.approvals.approver.approverTwoObject.employeeId);
                    this.manageList.push(element);

                // } else if (element.approvals.approver.approverTwoObject.employeeId == this.empId 
                //   && element.approvals.approverOneStatus.listTypeValueId == 5 && 
                //   element.approvals.approverTwoStatus.listTypeValueId == 5 && 
                //   element.approvals.approverThreeStatus.listTypeValueId == 3 && 
                //   element.approvals.approver.approverTwoObject.employeeId) {
                // this.show = true;
                // console.log("ApproverTwo:", element.approvals.approver.approverTwoObject.employeeId);
                // this.manageList.push(element);


                }else if (element.approvals.approver.approverThreeObject.employeeId == this.empId 
                          && element.approvals.approverOneStatus.listTypeValueId == 5 && 
                          element.approvals.approverTwoStatus.listTypeValueId == 5 && 
                          element.approvals.approverThreeStatus.listTypeValueId == 3 && 
                          element.approvals.approver.approverThreeObject.employeeId) {
                        this.show = true;
                        console.log("ApproverThree:", element.approvals.approver.approverThreeObject.employeeId);
                        this.manageList.push(element);

                }else if (element.approvals.approver.approverFourObject.employeeId == this.empId 
                  && element.approvals.approverOneStatus.listTypeValueId == 5 && 
                  element.approvals.approverTwoStatus.listTypeValueId == 5 && 
                  element.approvals.approverThreeStatus.listTypeValueId == 5 && 
                  element.approvals.approverFourStatus.listTypeValueId == 3 && 
                  element.approvals.approver.approverFourObject.employeeId) {
                this.show = true;
                console.log("ApproverFour:", element.approvals.approver.approverFourObject.employeeId);
                this.manageList.push(element);

                }else if (element.approvals.approver.approverFiveObject.employeeId == this.empId 
                  && element.approvals.approverOneStatus.listTypeValueId == 5 && 
                  element.approvals.approverTwoStatus.listTypeValueId == 5 && 
                  element.approvals.approverThreeStatus.listTypeValueId == 5 && 
                  element.approvals.approverFourStatus.listTypeValueId == 5 &&
                   element.approvals.approverFiveStatus.listTypeValueId == 3 && 
                   element.approvals.approver.approverFiveObject.employeeId) {
                this.show = true;
                console.log("ApproverFive:", element.approvals.approver.approverFiveObject.employeeId);
                this.manageList.push(element);
                }

              //   if (element.employeeId != this.empId) {
              //     this.nodatafound=true;
                  
              // }


                
    
                // Similarly, check and handle other properties...
    
                else if (element.employeeId == this.empId) {
                    console.log("Individual employee Based:", element.employeeId);
                    this.manageList.push(element);
                    // this.nodatafound=false
                }
                // else{
                //   console.log("fgdsdjh");
                //   this.nodatafound=true;
                // }
            } 
            else {
                console.log("Approvals or Approvers not found for element:", element);
            }
            // if(this.manageList.length === 0){
            //       console.log("fgdsdjh");
            //       this.nodatafound=true;
            //     }
            console.log("manageList:", this.manageList);
        });
         if(this.manageList.length!=0)
             {
              console.log("DATA manageList:", this.manageList);
              this.nodatafound=false;
             }
             else
             {
              console.log(" EMpty manageList:", this.manageList);
              this.nodatafound=true;
             }
        }
       this.loader = false; 
       this.showTable=true;
  });
  
     }


     else if((this.employeeId!=null || this.employeeId!=undefined) || (status!=null||status!=undefined) && this.searchForm.value.keywords != null) 

      this.searchForm.patchValue({
        keywords: this.employeeCodes
      });
      
      
      
     {
      console.log("status and emp Only");
      console.log("orgId:",this.orgId);
      this.manageList=[];
      
     this.pmsService.getApprisalEmpCodeandStatus(this.employeeId,status).subscribe((filterData: any) => 
     {
      
      this.submitButtonDisabled= false;
      this.clearButtonDisabled=false;
      this.loader=false;
      this.showTable=true;
      if(filterData.statusCode==204)
      {
        console.log("nodata");
        this.manageList=[];
        this.nodatafound=true;
        // this.loader=true;
      }
      else
      {
      // if(filterData.length == 0 || filterData.length == undefined)
      // {
      //  this.manageList=[];
      //   this.nodatafound = true;
      // }
      //  this.loader=false;
      this.submitButtonDisabled= false;
      this.clearButtonDisabled=false;
       this.submitted=false;
       this.nodatafound = false;
       this.apprisalData = filterData;
       console.log(this.apprisalData);
       this.apprisalData.forEach(element => 
        {
          console.log("element.employee:",element.employee.employeeId);
          console.log("emp",this.empId);
          console.log("own Apprsaial2",this.manageList);
          console.log(element.approvals.approver.approverThreeObject);

          if(element.approvals.approver.fyiObject!=undefined)
          {
            if(element.approvals.approver.fyiObject.employeeId==this.empId)
            {
              this.show=true;
              console.log("FYi:",element.approvals.approver.fyiObject.employeeId);
               this.manageList.push(element);
            }
          }
          if(element.approvals.approver.approverOneObject!=undefined)
          {
            if(element.approvals.approver.approverOneObject.employeeId==this.empId 
              && element.approvals.approverOneStatus.listTypeValueId == 3
              && element.approvals.approver.approverOneObject.employeeId)
            {
              this.show=true;
               console.log("ApproverOne:",element.approvals.approver.approverOneObject.employeeId);
               this.manageList.push(element);
            }
          }
          if(element.approvals.approver.approverTwoObject!=undefined)
          {
            if(element.approvals.approver.approverTwoObject.employeeId==this.empId 
              && element.approvals.approverOneStatus.listTypeValueId == 5
              &&element.approvals.approverTwoStatus.listTypeValueId == 3
              && element.approvals.approver.approverTwoObject.employeeId)
            {
              this.show=true;
               console.log("ApproverTwo:",element.approvals.approver.approverTwoObject.employeeId);
               this.manageList.push(element);
            }
          }
          if(element.approvals.approver.approverThreeObject!=undefined)
          {
             if(element.approvals.approver.approverThreeObject.employeeId==this.empId
              && element.approvals.approverOneStatus.listTypeValueId == 5
              &&element.approvals.approverTwoStatus.listTypeValueId == 5
               &&element.approvals.approverThreeStatus.listTypeValueId == 3
              && element.approvals.approver.approverThreeObject.employeeId)
            {
               this.show=true;
               console.log("ApproverThree:",element.approver.approverThreeObject);
               this.manageList.push(element);
            }
          }
          if(element.approvals.approver.approverFourObject!=undefined)
          {
            if(element.approvals.approver.approverFourObject.employeeId==this.empId
              && element.approvals.approverOneStatus.listTypeValueId == 5
              &&element.approvals.approverTwoStatus.listTypeValueId == 5
              &&element.approvals.approverThreeStatus.listTypeValueId == 5
               &&element.approvals.approverFourStatus.listTypeValueId == 3
              && element.approvals.approver.approverFourObject.employeeId)
            {
               this.show=true;
               console.log("ApproverFour:",element.approver.approverFourObject);
               this.manageList.push(element);
            }
          }
          if(element.approvals.approver.approverFiveObject!=undefined)
          {
            if(element.approvals.approver.approverFiveObject.employeeId==this.empId
              && element.approvals.approverOneStatus.listTypeValueId == 5
              &&element.approvals.approverTwoStatus.listTypeValueId == 5
              &&element.approvals.approverThreeStatus.listTypeValueId == 5
              &&element.approvals.approverFourStatus.listTypeValueId == 5 
              &&element.approvals.approverFiveStatus.listTypeValueId == 3
              && element.approvals.approver.approverFiveObject.employeeId)
            {
               this.show=true;
               console.log("ApproverFive:",element.approver.approverFiveObject);
               this.manageList.push(element);
            }
          }
           if(element.employeeId==this.empId)
            {
              console.log("Indidual employee Based:",element.employeeId);
               this.manageList.push(element);
            }

            
            console.log("manageList",this.manageList);
        });

        if (this.manageList.length != 0) {
          console.log("DATA manageList:", this.manageList);
          this.nodatafound = false;
        } else {
          console.log("Empty manageList:", this.manageList);
          this.nodatafound = true;
        }
      }
      this.loader = false;
      this.showTable=true;
     });

     }

    
     }




  //    getFilterFormData() {
  //     this.p=1;
  //     let status = this.searchForm.value.status;
  //     console.log(status);
  //     console.log("Fetching appraisal data for employee", this.empId);
  //     this.nodatafound = false;
  //     this.manageList = [];
  //     this.loader = true;
  //     this.showTable = false;

  //     this.submitButtonDisabled = true;
  //     this.clearButtonDisabled=true;
  
  //     let apiResponses = 0;
  //     let dataFound = false;
  
  //     const handleApiResponse = () => {
  //         apiResponses++;
  //         if (apiResponses === 2) {
  //             this.loader = false;
  //             this.showTable = true;
  //              this.submitButtonDisabled = false;
  //              this.clearButtonDisabled=false;
  //             if (!dataFound) {
  //                 this.nodatafound = true;
  //                 this.manageList = [];
  //                 console.log("NO_DATA");
  //             }
  //         }
  //     };
  
  //     // Employee Based API
  //     this.pmsService.getApprisalEmpCode(this.empId).subscribe((result: any) => {
  //         console.log("List Api data", this.empId);
  //         if (result.statusCode !== 204) {
  //             this.Data = result;
  //             console.log(this.Data);
  //             this.Data.forEach(ele => {
  //                 console.log(ele);
  //                 console.log(ele.apprisalStatus.listTypeValueId);
  //                 if (ele.apprisalStatus.listTypeValueId == 3) {
  //                     this.manageList.push(ele);
  //                     dataFound = true;
  //                 }
  //             });
  //         }
  //         console.log("own Appraisal1", this.manageList);
    
  //         this.manageList.forEach(element => {
  //             console.log("Elements", element);
  //             const approvals = element.approvals?.approver;
  
  //             if (approvals?.fyiObject.employeeId == this.empId) {
  //                 console.log("FYI:", approvals.fyiObject?.employeeId);
  //             }
  //             if (approvals?.approverOneObject?.employeeId == this.empId && element.approvals.approverOneStatus.listTypeValueId == 3) {
  //                 console.log("ApproverOne:", approvals.approverOneObject?.employeeId);
  //             }
  //             if (approvals?.approverTwoObject?.employeeId == this.empId && element.approvals.approverOneStatus.listTypeValueId == 5 && element.approvals.approverTwoStatus.listTypeValueId == 3) {
  //                 console.log("ApproverTwo:", approvals.approverTwoObject?.employeeId);
  //             }
  //             if (approvals?.approverThreeObject?.employeeId == this.empId && element.approvals.approverTwoStatus.listTypeValueId == 5 && element.approvals.approverThreeStatus.listTypeValueId == 3) {
  //                 console.log("ApproverThree:", approvals.approverThreeObject?.employeeId);
  //             }
  //             if (approvals?.approverFourObject?.employeeId == this.empId && element.approvals.approverThreeStatus.listTypeValueId == 5 && element.approvals.approverFourStatus.listTypeValueId == 3) {
  //                 console.log("ApproverFour:", approvals.approverFourObject?.employeeId);
  //             }
  //             if (approvals?.approverFiveObject?.employeeId == this.empId && element.approvals.approverFourStatus.listTypeValueId == 5 && element.approvals.approverFiveStatus.listTypeValueId == 3) {
  //                 console.log("ApproverFive:", approvals.approverFiveObject?.employeeId);
  //             }
  //             if (element.employeeId == this.empId) {
  //                 console.log("Individual employee based:", element.employeeId);
  //             }
  //         });
  
  //         console.log("manageList Length", this.manageList.length);
  //         handleApiResponse();
  //     });
  
  //     this.pmsService.getApprisalEmpCodeNew(this.empId).subscribe((filterData: any) => {
  //         if (filterData.statusCode !== 204) {
  //             this.submitted = false;
  //             this.nodatafound = false;
  //             this.apprisalData = filterData;
  
  //             console.log("apprisalData", this.apprisalData);
  //             this.apprisalData.forEach(element => {
  //                 console.log(element, "t1t1t1t1");
  //                 console.log("element.employee:", element.employee.employeeId,
  //                     "approvals:", element.approvals,
  //                     "approvers", element.approvals.approver);
  //                 console.log("emp", this.empId);
  //                 console.log("own Apprsaial2", this.manageList);
  
  //                 if (element.approvals && element.approvals.approver) {
  //                     console.log("Approval and Approver exist for element:", element);
  
  //                     console.log("FYI Object employeeId:", element.approvals.approver.fyiObject.employeeId);
  //                     console.log("Approver ID:", element.approvals.approver.approverId);
  //                     console.log(element.approvals.approver.fyiObject);
  
  //                     if (element.approvals.approver.fyiObject && element.approvals.approver.fyiObject.employeeId == this.empId) {
  //                         this.show = true;
  //                         console.log("FYI:", element.approvals.approver.fyiObject.employeeId);
  //                         this.manageList.push(element);
  //                         dataFound = true;
  //                     } else if (element.approvals.approver.approverOneObject &&
  //                         element.approvals.approver.approverOneObject.employeeId == this.empId &&
  //                         element.approvals.approverOneStatus.listTypeValueId == 3 &&
  //                         element.approvals.approver.approverOneObject.employeeId) {
  //                         this.show = true;
  //                         console.log("ApproverOne:", element.approvals.approver.approverOneObject.employeeId);
  //                         this.manageList.push(element);
  //                         dataFound = true;
  //                     } else if (element.approvals.approver.approverTwoObject.employeeId == this.empId
  //                         && element.approvals.approverOneStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverTwoStatus.listTypeValueId == 3 &&
  //                         element.approvals.approver.approverTwoObject.employeeId) {
  //                         this.show = true;
  //                         console.log("ApproverTwo:", element.approvals.approver.approverTwoObject.employeeId);
  //                         this.manageList.push(element);
  //                         dataFound = true;
  //                     } else if (element.approvals.approver.approverThreeObject.employeeId == this.empId
  //                         && element.approvals.approverOneStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverTwoStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverThreeStatus.listTypeValueId == 3 &&
  //                         element.approvals.approver.approverThreeObject.employeeId) {
  //                         this.show = true;
  //                         console.log("ApproverThree:", element.approvals.approver.approverThreeObject.employeeId);
  //                         this.manageList.push(element);
  //                         dataFound = true;
  //                     } else if (element.approvals.approver.approverFourObject.employeeId == this.empId
  //                         && element.approvals.approverOneStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverTwoStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverThreeStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverFourStatus.listTypeValueId == 3 &&
  //                         element.approvals.approver.approverFourObject.employeeId) {
  //                         this.show = true;
  //                         console.log("ApproverFour:", element.approvals.approver.approverFourObject.employeeId);
  //                         this.manageList.push(element);
  //                         dataFound = true;
  //                     } else if (element.approvals.approver.approverFiveObject.employeeId == this.empId
  //                         && element.approvals.approverOneStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverTwoStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverThreeStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverFourStatus.listTypeValueId == 5 &&
  //                         element.approvals.approverFiveStatus.listTypeValueId == 3 &&
  //                         element.approvals.approver.approverFiveObject.employeeId) {
  //                         this.show = true;
  //                         console.log("ApproverFive:", element.approvals.approver.approverFiveObject.employeeId);
  //                         this.manageList.push(element);
  //                         dataFound = true;
  //                     }
  
  //                    else if (element.employeeId == this.empId) {
  //                         console.log("Individual employee Based:", element.employeeId);
  //                         // this.manageList.push(element);
  //                         dataFound = true;
  //                     }
  //                 } else {
  //                     console.log("Approvals or Approvers not found for element:", element);
  //                 }
  //                 console.log("manageList:", this.manageList);
  //             });
  //               if (this.manageList.length != 0) {
  //                 console.log("DATA manageList:", this.manageList);
  //                 this.nodatafound = false;
  //               } else {
  //                 console.log("Empty manageList:", this.manageList);
  //                 this.nodatafound = true;
  //               }
  //         }
  //         handleApiResponse();
  //     });
  // }

  getFilterFormData() {
    this.p = 1;
    let status = this.searchForm.value.status;
    console.log(status);
    console.log("Fetching appraisal data for employee", this.empId);
    this.nodatafound = false;
    this.manageList = [];
    this.loader = true;
    this.showTable = false;

    this.submitButtonDisabled = true;
    this.clearButtonDisabled = true;

    let apiResponses = 0;
    let dataFound = false;

    const handleApiResponse = () => {
        apiResponses++;
        if (apiResponses === 1) { // Change 2 to 1 since only one API call now
            this.loader = false;
            this.showTable = true;
            this.submitButtonDisabled = false;
            this.clearButtonDisabled = false;
            if (!dataFound) {
                this.nodatafound = true;
                this.manageList = [];
                console.log("NO_DATA");
            }
        }
    };

    this.pmsService.getApprisalEmpCodeNew(this.empId).subscribe((filterData: any) => {
        if (filterData.statusCode !== 204) {
            this.submitted = false;
            this.nodatafound = false;
            this.apprisalData = filterData;
            this.isFyi = false;

            console.log("apprisalData", this.apprisalData);
            this.apprisalData.forEach(element => {
                console.log(element, "t1t1t1t1");
                console.log("element.employee:", element.employee.employeeId,
                    "approvals:", element.approvals,
                    "approvers", element.approvals.approver);
                console.log("emp", this.empId);
                console.log("own Apprsaial2", this.manageList);

                if (element.approvals && element.approvals.approver) {
                    console.log("Approval and Approver exist for element:", element);

                    console.log("FYI Object employeeId:", element.approvals.approver.fyiObject.employeeId);
                    console.log("Approver ID:", element.approvals.approver.approverId);
                    console.log(element.approvals.approver.fyiObject);

                    if (element.approvals.approver.fyiObject && element.approvals.approver.fyiObject.employeeId == this.empId) {
                        this.show = true;
                        this.isFyi = true;
                        console.log("FYI:", element.approvals.approver.fyiObject.employeeId);
                        this.manageList.push(element);

                        dataFound = true;
                    } else if (element.approvals.approver.approverOneObject &&
                        element.approvals.approver.approverOneObject.employeeId == this.empId &&
                        element.approvals.approverOneStatus.listTypeValueId == 3 &&
                        element.approvals.approver.approverOneObject.employeeId) {
                        this.show = true;
                        console.log("ApproverOne:", element.approvals.approver.approverOneObject.employeeId);
                        
                        
                        this.manageList.push(element);
                        console.log(this.manageList);
                        dataFound = true;
                    } else if (element.approvals.approver.approverTwoObject.employeeId == this.empId
                        && element.approvals.approverOneStatus.listTypeValueId == 5 &&
                        element.approvals.approverTwoStatus.listTypeValueId == 3 &&
                        element.approvals.approver.approverTwoObject.employeeId) {
                        this.show = true;
                        console.log("ApproverTwo:", element.approvals.approver.approverTwoObject.employeeId);
                        this.manageList.push(element);
                        dataFound = true;
                    } else if (element.approvals.approver.approverThreeObject.employeeId == this.empId
                        && element.approvals.approverOneStatus.listTypeValueId == 5 &&
                        element.approvals.approverTwoStatus.listTypeValueId == 5 &&
                        element.approvals.approverThreeStatus.listTypeValueId == 3 &&
                        element.approvals.approver.approverThreeObject.employeeId) {
                        this.show = true;
                        console.log("ApproverThree:", element.approvals.approver.approverThreeObject.employeeId);
                        this.manageList.push(element);
                        dataFound = true;
                    } else if (element.approvals.approver.approverFourObject.employeeId == this.empId
                        && element.approvals.approverOneStatus.listTypeValueId == 5 &&
                        element.approvals.approverTwoStatus.listTypeValueId == 5 &&
                        element.approvals.approverThreeStatus.listTypeValueId == 5 &&
                        element.approvals.approverFourStatus.listTypeValueId == 3 &&
                        element.approvals.approver.approverFourObject.employeeId) {
                        this.show = true;
                        console.log("ApproverFour:", element.approvals.approver.approverFourObject.employeeId);
                        this.manageList.push(element);
                        dataFound = true;
                    } else if (element.approvals.approver.approverFiveObject.employeeId == this.empId
                        && element.approvals.approverOneStatus.listTypeValueId == 5 &&
                        element.approvals.approverTwoStatus.listTypeValueId == 5 &&
                        element.approvals.approverThreeStatus.listTypeValueId == 5 &&
                        element.approvals.approverFourStatus.listTypeValueId == 5 &&
                        element.approvals.approverFiveStatus.listTypeValueId == 3 &&
                        element.approvals.approver.approverFiveObject.employeeId) {
                        this.show = true;
                        console.log("ApproverFive:", element.approvals.approver.approverFiveObject.employeeId);
                        this.manageList.push(element);
                        dataFound = true;
                    }

                    else if (element.employeeId == this.empId) {
                        console.log("Individual employee Based:", element.employeeId);

                        this.manageList.push(element);
                        console.log("check",this.manageList)
                        dataFound = true;
                    }
                } else {
                    console.log("Approvals or Approvers not found for element:", element);
                }
                console.log("manageList:", this.manageList);
            });
            if (this.manageList.length != 0) {
              console.log("DATA manageList:", this.manageList);
              this.nodatafound = false;
            } else {
              console.log("Empty manageList:", this.manageList);
              this.nodatafound = true;
            }
        }
        handleApiResponse();
    });
}

  
}

function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
