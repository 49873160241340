<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Consolidate Payslip Report</span>
                </div>
                <div class="sub-header-buttons">
                    <button  class="but ml-2 mb-2" type="button">
                        <mat-icon class="material-symbols-outlined "  style=" color: white !important;"  (click)="downloadAsExcel();">file_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Client<span class="star"> *</span></label> 
                            <select formControlName="client"   class="form-select form-select-solid form-select-lg select2-new">
                                <option [value]=null> --- Select Client --- </option>
                                <option *ngFor="let row of clientList; let i=index;" value="{{row.clientId}}">{{row.clientCode}}</option> 
                            </select>   
                        </div>
                        <div class="form-group col-md-3">
                            <!-- <input formControlName="employee" type="search" autocomplete="off" class="form-select form-select-solid form-select-lg select2-new" [value]=null placeholder="Search..."> -->
                            <label class="col-form-label">Employee</label>
                            <input type="text" matInput  placeholder="Search Employee"   
                                formControlName="employeecode"
                                [matAutocomplete]="auto"  [readonly]="!filterForm.value.client || filterForm.value.client == 'null'"
                                class="example-input form-select-solid form-select-lg select2-new" (keyup)="empSearch($event)">
                            <mat-autocomplete #auto="matAutocomplete">
                                <!-- <mat-option *ngIf="!shiftemployeeId" value="All" (click)="empAllSelect()">All
                                    </mat-option> -->
                                <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                {{emp.employeeNumber}}- {{emp.firstName}}  
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon class="material-symbols-outlined" style="position:absolute;top:48px;right: 19px;">
                                search
                            </mat-icon>
                            <!-- <small class="text-muted">Note : Employee Number</small> -->
                        </div>
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Financial Year</label> 
                            <select formControlName="financialyear" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialyear($event)" >
                                <option [value]=null> -- Select Financialyear --- </option>
                                <option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</option>  -->
                            </select>
                        </div>
                        <div class="form-group col-md-3 ">
                            <label class="col-form-label">From Period</label> 
                            <select formControlName="fromperiod" class="form-select form-select-solid form-select-lg select2-new"  (change)="selectFinancialfromPeriod($event)"  >
                                <option [value]=null> -- Select From Period --- </option>
                                <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option> 
                            </select>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3 ">
                            <label class="col-form-label">To Period</label> 
                            <select formControlName="toperiod" class="form-select form-select-solid form-select-lg select2-new" (change)="selectFinancialtoPeriod($event)">
                                <option [value]=null> -- Select To Period --- </option>
                                <option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</option> 
                            </select>
                        </div>
                        <div class="col-md-3" style="float: right;top: 37px;">
                            <button type="submit" mat-raised-button color="primary"
                                class="btn btn-primary search-btn">Search</button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                class="btn mr-2 search-btn" (click)="clearTable()"
                                >Clear</button>
                        </div>
                    </div>
                </form>
                <div class="row pb-3">
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table  --table-bordered table-border-new">
                        <thead class="t-head">
                            <tr >
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>IFSC Code</th>
                                <th>Bank Name</th>
                                <th>Branch Name</th>
                                <th>Address</th>
                                <th>Period</th>
                                <th *ngFor="let elements of paySlipElement">{{elements.elementName}}</th>                      
                                <th>Total Deductions</th>
                                <th>Net Pay</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="resultData.length>0">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <td>{{row.employee.employeeNumber}}</td>
                                <td>{{row.employee.firstName}}</td>
                                <td>{{row.employee.employeeInfo.accountName}}</td>
                                <td>{{row.employee.employeeInfo.bankAccountNo}}</td>
                                <td>{{row.employee.employeeInfo.ifscCode}}</td>
                                <td>{{row.employee.employeeInfo.bankName}}</td>
                                <td>{{row.employee.employeeInfo.bankBranchName}}</td>
                                <td>{{row.employeeAnnexure.place}}</td>
                                <td style="white-space: nowrap;">{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                <td *ngFor="let elements of paySlipElement" >
                                    <div *ngFor="let payrollLine of row.payrollLine">
                                        <div *ngIf="payrollLine.hrmspaySlipElement.elementId === elements.elementId" >
                                            {{payrollLine.amount?.toFixed(2)}}
                                        </div>
                                    </div> 
                                </td>
                            
                                <td>{{row.totalDeduction?.toFixed(2)}}</td>
                                <td>{{calucatenetpay(row.totalEarnings, row.totalDeduction)}}</td>
                            </tr>
                            <tr *ngIf="noRecordsFound == false" >
                                <td class="text-right" style="font-size: 13px;" colspan="9"><b>Total&nbsp;&nbsp;&nbsp;</b></td>
                                <td *ngFor="let e of paySlipElement" class="text-center" style="font-size: 13px;text-align: right" colspan="1">{{e.amount?.toFixed(2)}}</td>
                                <td  class="text-center" style="font-size: 13px;text-align: center" colspan="1">{{this.totalDeduction?.toFixed(2)}}</td>
                                <td  class="text-center" style="font-size: 13px;text-align: center" colspan="1">{{this.totalNetpay?.toFixed(2)}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div> -->
                        <div *ngIf="noRecordsFound">
                            <p style="text-align: center;" [attr.colspan]="paySlipElementlength">No Records Found!</p>
                        </div> 
                    <!-- </div> -->
                 </div>
                
                <div *ngIf="noRecordsFound == false">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>