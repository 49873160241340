<div>
    <div class="d-flex">
        <div style="" class="col-9 p-0">
            <h1 style="margin-bottom:15px;color: #181C32;font-weight: 500;">Salary Advance Details</h1>
        </div>
        <div class="col-3 p-0">
            <div class="d-flex float-right" >
                <mat-icon (click)="formcancel()" class="material-symbols-outlined cancel-icon">cancel</mat-icon>  
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width" style="display: inline-block;min-width: 125px;">Salary Advance Id</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;word-break: break-all;" *ngIf="advancedata.salaryAdvanceNumber !=undefined">{{advancedata.salaryAdvanceNumber}}</span>
                        <span style="border:0;word-break: break-all;" *ngIf="advancedata.salaryAdvanceNumber == undefined"> -- </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 135px;" class="font-weight label-color right-label-width">Approver Status&nbsp;</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span class="float-right text-nowrap" *ngIf="advancedata.approval != undefined">{{advancedata.approval.approvalStatus.listTypeValueName}}</span>
                        <span *ngIf="advancedata.approval== undefined ||advancedata.approval== undefined"> -- </span>
                    </p>
                </div>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 125px;" class="font-weight label-color right-label-width">Employee Number</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;word-break: break-all;">{{advancedata.employee.employeeNumber}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color right-label-width" style="display: inline-block;min-width: 135px;">Employee Name</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;word-break: break-all;">{{advancedata.employee.firstName}} {{advancedata.employee.lastName}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 125px;" class="font-weight label-color right-label-width">Email</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;word-break: break-all;">{{advancedata.employee.organizationEmail}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 135px;" class="font-weight label-color right-label-width">Total Salary</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;word-break: break-all;">{{totalsalary}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 125px;" class="font-weight label-color right-label-width">Advance Paid</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;word-break: break-all;">{{advancePaid}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 135px;" class="font-weight label-color right-label-width">Requested Amount</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;word-break: break-all;">{{advancedata.requestedAdvanceAmount}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p class="d-flex m-0">
                <span style="display: inline-block;min-width: 125px;" class="font-weight label-color right-label-width">Approvers</span>
                <span style="margin: 0px 10px 0px 0px;">:</span>
                <span style="border:0;word-break: break-all;" *ngIf="advancedata.approval != undefined">{{approvers}}</span>
                <span style="border:0" *ngIf="advancedata.approval == undefined"> -- </span>
            </p>
        </div>
    </div>
        <!-- <div class="col-3"> -->
            <!-- <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span class="font-weight label-color" colspan="3" style="text-align: center;border:0;margin:0px 0px 0px 40px;">Expense Period</span>
                    </p>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 110px;" class="label-color text-nowrap">From</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;" class="text-nowrap">{{advancedata.fromDate}}</span>
                    </p>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 110px;" class="label-color text-nowrap">To</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;" class="text-nowrap">{{advancedata.toDate}}</span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 110px;" class="font-weight label-color">Job Location</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;">Hosur</span>
                    </p>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 110px;" class="font-weight label-color">Submitted Date</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;" class="text-nowrap">{{advancedata.submittedOn}}</span>
                        <span style="border:0" *ngIf="advancedata.submittedOn == null"> -- </span>
                    </p>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-12">
                    <p class="d-flex m-0">
                        <span style="display: inline-block;min-width: 110px;" class="font-weight label-color">Approval Date</span>
                        <span style="margin: 0px 10px 0px 0px;">:</span>
                        <span style="border:0;" class="text-nowrap" *ngIf="advancedata.approvedOn != undefined">{{advancedata.approvedOn}}</span>
                        <span style="border:0" *ngIf="advancedata.approvedOn == undefined"> -- </span>
                    </p>
                </div>
            </div> -->
        <!-- </div> -->
</div>