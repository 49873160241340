import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { UserService } from '../../providers/user.service';
// import { base_url,httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';
import { AppComponent } from '../../app.component';
import { redirectMsgTimeOut } from 'src/app/providers/properties';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit
{
  autoCompleteSearch : any = 0 ; //Auto Complete Search

  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any;
  employeeId:any;
  userId: any;
  activeEmployeesData: any;
  emailExistStatus: any;
  employeeExistStatus: any;
  sucess_msg:any;
  error_msg:any;
  employeetData: any = '';
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  employeeData:any;

  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private userservice: UserService,
    private ActivatedRoute:ActivatedRoute,
    @Inject(AppComponent) public AppData: any,
  ) { }



  dateFormat : any;
  organizationId : any;


  ngOnInit(): void
  {
    this.dateFormat = localStorage.getItem('dateFormatName');

    this.userId = this.ActivatedRoute.snapshot.params.userId;

    if(this.userId)
    {
      this.postForm = this.formBuilder.group({
        employeeName : [''],
        emailId : ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        employeeId: [''],
      });
    }
    else
    {
      this.postForm = this.formBuilder.group({
        employeeName : [''],
        emailId : ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        password: ['', Validators.required],
        employeeId: [''],
      });
    }

    if(this.userId)
    {
      this.userservice.getUserById(this.userId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          employeeName: this.resultData.employee.firstName,
          emailId: this.resultData.emailId,
          employeeId: this.resultData.employee.employeeId,
          password: this.resultData.password,
        });

      });
    }
    this.getActiveEmployees();
  }
  get validate() { return this.postForm.controls; }

  
  getActiveEmployees() {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("this.employeetData",this.employeetData);
    let organizationId = this.employeetData.organization.organizationId;
    this.organizationId = this.employeetData.organization.organizationId
    //let organizationId = organizationId;

    this.userservice.getActiveEmployees(organizationId).subscribe((result: any) => {
      this.activeEmployeesData = result;
    })
  }

  onSubmit()
  {
    this.submitted = true;

    let organizationId = this.organizationId;

    if(this.postForm.invalid || this.emailExistStatus == true || this.employeeExistStatus == true)
    {
      return;
    }

    if(this.userId) //Update Branch
    {
      var postValues = {
        emailId:this.postForm.value.emailId,
        password:this.postForm.value.password,
        employee:{
          employeeId: this.postForm.value.employeeId
        },
        organization:{
          organizationId: organizationId
        },
      }
      postValues['userId'] = this.resultData.userId;
      postValues['status'] = this.resultData.status;

      this.userservice.editUser(this.userId,postValues).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.description;
          setTimeout(() => {
            this.router.navigate(['/home/users']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {
            this.router.navigate(['/home/users']);
          }, redirectMsgTimeOut)
        }
      }, err =>{

        this.errorMsg = err.error.message;

      })
    }
    else  //Add User
    {
      //let postValues = this.postForm.value;

      if (this.postForm.invalid)
      {
        return;
      }
      let postValues = {
        emailId:this.postForm.value.emailId,
        password:this.postForm.value.password,
       
        organization:{
          organizationId: organizationId
        },
      };
        
       if(this.postForm.value.employeeId)
       {
          postValues['employee'] ={
            employeeId: this.postForm.value.employeeId
          };
       }

        console.log(postValues);
     //  return
      this.userservice.createUser(postValues).subscribe((result: any) =>
      {

        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          setTimeout(() => {
            this.router.navigate(['/home/users']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          setTimeout(() => {
            this.router.navigate(['/home/users']);
          }, redirectMsgTimeOut)
        }
      }, err =>
      {
          this.errorMsg = err.error.message;

      })
    }
  }

  CheckExistemailId(val)
  {
    if(this.postForm.value.emailId.length != 0)
    {
     var postValues = {
        emailId     : this.postForm.value.emailId,
        userId     : this.userId
      }

      if(this.userId)
      {
        this.userservice.updateCheckExistemailId(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.emailExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.emailExistStatus = true;
          }
        })

      }
      else
      {
        this.userservice.createCheckExistemailId(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.emailExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.emailExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.emailExistStatus = false;
    }
  }

  CheckExistemployeeId(val)
  {
    if(this.postForm.value.employeeId.length != 0)
    {
     var postValues = {
        employee:{
          employeeId: this.postForm.value.employeeId
        },
        userId     : this.userId
      }

      if(this.userId)
      {
        this.userservice.updateCheckExistemployeeId(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.employeeExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.employeeExistStatus = true;
          }
        })

      }
      else
      {
        this.userservice.createCheckExistemployeeId(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.employeeExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.employeeExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.employeeExistStatus = false;
    }
  }

  /** Auto Complete Search **/
  getEmployeeList(event)
  {
    let keywords  = event.target.value;
    //let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;

    if(keywords)
    {
      this.autoCompleteSearch = 0;

      let organizationId = this.organizationId;

      this.userservice.employeeSearch(organizationId,keywords).subscribe((result: any) =>
      {
        this.employeeData = result;

        if(this.employeeData.statusCode == 204)
        {
          this.autoCompleteSearch = 1; //No data Found
          this.postForm.patchValue({
            //employeeName : firstName+" "+lastName,
            employeeId : ""
          });
        }
        else
        {
          this.autoCompleteSearch = 0;
        }
      })
    }
    else
    {
      this.autoCompleteSearch = 1;
      this.postForm.patchValue({
        //employeeName : firstName+" "+lastName,
        employeeId : ""
      });
    }
  }

  getFilterData(id,firstName,lastName,employeeNumber)
  {
    this.autoCompleteSearch = 1;

    if(id)
    {
      this.postForm.patchValue({
        employeeName : firstName+" "+lastName,
        employeeId : id
      });
    }
  }
}
