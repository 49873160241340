<nav class="navbar bg" style="margin-top: 66px !important">
  <div class="container-fluid mt-n2" style="height: 53px; background: #ffffff">
    <a class="heading-tag">Project Stage</a>
    <form class="d-flex">
      <input
        class="form-control search-menu1 search-placeholder me-2"
        type="search"
        aria-label="Search"
        type="text"
        placeholder="Search..."
        [(ngModel)]="search"
        name="searchText"
        (keyup)="filterByValue()"
      />
    </form>
  </div>
</nav>
<!-- <nav class="navbar navbar-light bg-light bg" style="margin-top: 68px;" id="editForm">
    <div class="m-2">
      <h4 class="heading-tag" >Project Stage</h4>
      <form class="d-flex">
        <input class="form-control me-2 col-md-5 col-sm-5"  aria-label="Search"
        type="text"
        class="form-control search-menu search-placeholder"
        placeholder="Search..."
        [(ngModel)]="search"
        (keyup)="filterByValue()"
        name="searchText" 
       >
       
      </form>
    </div>
  </nav> -->

<div class="common-content">
  <div class="card-new">
    <div class="card" style="background: none; border: none">
      <div
        class="card-body"
        style="
          border-radius: 15px;
          border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
          background: #fdfdfd;
          margin-top: -106px;
        "
      >
        <form
          action=""
          [formGroup]="projectStage"
          (ngSubmit)="onSubmit($event)"
        >
          <div class="container">
            <div class="row">
              <div class="col-md-6" style="align-items: center">
                <div class="full-width" id="loginform">
                  <!-- <label class="projectstages" id="loginformdesign"
                style=" font-family: 'Mulish', sans-serif;margin-left: 22px;
                "> ProjectStage <span class="text-danger">*</span></label
                > -->
                  <!-- <input
                  formControlName="projectstage"
                  placeholder="Enter ProjectStage "
                  class="form-control email-input select-placeholder"
                />
                <mat-error *ngIf="projectStage.get('projectstage').hasError('required')&&submitted ">
                  ProjectStage is required
                </mat-error> -->

                  <!-- <label class="form-control-label">Category Name<span class="text-danger"> *</span></label> -->
                  <label
                    class="projectstages"
                    id="loginformdesign"
                    style="
                      font-family: 'Mulish', sans-serif;
                      margin-left: 13px !important;
                      color: black;
                      font-weight: 550;
                      font-size: 16px;
                      margin-bottom: 13px;
                    "
                  >
                    Project Stage <span class="text-danger">*</span></label
                  >
                  <input
                    formControlName="projectstage"
                    placeholder="Enter Project Stage "
                    class="form-control email-input select-placeholder"
                  />
                  <!-- <div *ngIf="projectStage.get('projectstage').hasError('required')&&submitted " style="margin-left: 20px;">
                <small *ngIf="(projectstage.invalid && projectstage.touched) || projectstage.dirty">
                </small>
                    <small *ngIf="projectstage.errors?.required" class="text-danger">Please enter project stage name</small>
                    <small *ngIf="projectstage.errors?.pattern" class="text-danger">Please enter valid project stage name</small>   
                </div>  -->
                  <div *ngIf="projectstage.invalid || projectstage.dirty">
                    <small
                      *ngIf="projectstage.errors?.pattern"
                      class="text-danger textErrorDesign"
                      >Please enter valid Project Stage
                    </small>
                  </div>
                  <div
                    *ngIf="
                      projectStage.get('projectstage').hasError('required') &&
                      (projectStage.get('projectstage').dirty || submitted)
                    "
                    class="text-danger"
                  >
                    <small
                      class="text-danger"
                      *ngIf="projectstage.errors?.required"
                    >
                      <span style="margin-left: 25px">
                        Project Stage is required
                      </span>
                    </small>
                  </div>
                </div>
                <div
                  *ngIf="projectstageNameexist"
                  class="span-error textErrorDesign"
                >
                  Project Stage Name Already Exist
                </div>
              </div>
              <!-- <div class="col-md-6"> -->
              <!-- <div align="end" mat-dialog-actions>
                <button
                  class="btn create-button savebtn"
                  type="submit"
                >Save
                </button>
                <button
                style="margin-left: 10px"
                type="reset"
                (click)="clearForm()"
                class="btn  mr-2" style="background-color: none !important;
                border: 1px solid rgba(74, 70, 70, 0.566) !important;
                margin-left: 7px;
                background-color: whitesmoke;"
              >
                Clear
              </button>
              </div> -->
              <div class="col-md-6 buttonDesign" style="display: contents">
                <button
                  type="submit"
                  [disabled]="isbuttondisabled"
                  mat-raised-button
                  class="btn search-btn"
                  style="background: #1a83ff; color: #fff"
                >
                  Save
                </button>
                <button
                  style="margin-left: 10px"
                  type="reset"
                  mat-raised-button
                  [disabled]="isbuttondisabled"
                  (click)="clearForm()"
                  class="btn search-btn mr-2"
                  style="
                    color: black;
                    background-color: none;
                    border: 1px solid rgba(74, 70, 70, 0.566) !important;
                    background: none;
                    margin-left: 16px;
                  "
                >
                  Clear
                </button>
              </div>
              <!-- </div> -->
              <!-- <div class="float-end"  *ngIf="prjstStageList.length > 0">
              <div class="row roeDesinpage" *ngIf="prjstStageList.length > 5">
                <div class="select-pagination" style=" margin-top: 85px;margin-left: 15px;margin-bottom: 10px;">
                  <span class="showPage">Show    </span>
                  <select
                    class="select_list new-select_list listDesign"
                    [(ngModel)]="mySelect"
                    [ngModelOptions]="{standalone: true}"
                    (change)="changefirstpage()"
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div> -->

              <!-- *ngIf="prjstStageList.length > 0" -->
              <!-- <div
                class="card-content"
                *ngIf="
                  prjstStageList.length > 5 &&
                  (prjstStageList | mytask : search).length > 0
                "
              >
                <div
                  class="row p-0 m-0"
                  *ngIf="
                    prjstStageList.length > 5 &&
                    (prjstStageList | mytask : search).length > 0
                  "
                >
                  <div
                    class="col-md-12"
                    style="margin-bottom: 5px; position: relative; left: 91%"
                    *ngIf="(prjstStageList | mytask : search).length > 5"
                  >
                    <div class="style-left">
                      <div class="select-pagination">
                        <span class="showDesign">Show </span>
                        <select
                          class="select_list new-select_list newselect"
                          [(ngModel)]="mySelect"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="changefirstpage()"
                        >
                          <option class="form-control" value="5">5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </form>

        <div class="table-responsive" *ngIf="tableShow">
          <!-- *ngIf="prjstStageList.length!=0"  -->
          <div class="row p-0 m-0 justify-content-end">
            <div
              class="col-xl-2 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-end align-items-end"
            >
              <div
                class="select-pagination"
                *ngIf="(prjstStageList | mytask : search).length > 5"
              >
                <span>Show : </span>
                <select
                  class="select_list new-select_list"
                  [(ngModel)]="mySelect"
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>
          <table
            class="table --table-bordered table-border-new mt-2"
            matSort
            (matSortChange)="sortData($event)"
          >
            <thead class="t-head">
              <tr id="trDesign">
                <th
                  style="
                    border-radius: 15px 0px 0px 0px;
                    border-top: 0;
                    border-bottom: 0;
                  "
                >
                  S.No
                </th>
                <th>Stage Name</th>
                <th>Status</th>
                <th
                  style="
                    border-radius: 0px 15px 0px 0;
                    border-top: 0;
                    border-bottom: 0;
                  "
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              style="
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                height: 50px;
              "
            >
              <tr
                *ngFor="
                  let prjtstage of datas
                    | mytask : search
                    | paginate : { itemsPerPage: mySelect, currentPage: p };
                  let i = index
                "
              >
                <td style="text-align: center; padding: 16px" *ngIf="p">
                  {{ (p - 1) * mySelect + (i + 1) }}
                </td>
                <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>

                <td style="padding: 16px">{{ prjtstage.projectstagename }}</td>
                <td style="padding: 18px">
                  {{ prjtstage.status.listTypeValueName }}
                </td>
                <td>
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="controls-list">
                    <button mat-menu-item (click)="update(prjtstage, i)">
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>
              <!-- <tr *ngIf="(prjstStageList | mytask : search).length === 0 && search">
              <td colspan="/* number of columns */" style="
                  text-align: center;
    position: relative;
    font-size: 19px;
    left: 243px;
    font-weight: 600;"> Project Stage Not Found</td>
            </tr> -->
              <td
                style="text-align: center; font-size: larger; font-weight: bold"
                colspan="11"
                *ngIf="
                  ((prjstStageList | mytask : search).length === 0 && search) ||
                  datas.length == 0 || nodata
                "
              >
                Project Stage Not Found!
              </td>
            </tbody>
          </table>

          <!-- <div class="controls">
        <pagination-controls
        (pageChange)="p = $event; beforesearchpage = $event"
        *ngIf="(prjstStageList | mytask : search).length > 5"
        align="end"
        style="font-family: 'Mulish', sans-serif;">
      </pagination-controls>
         </div>   -->
        </div>
        <!-- <div
    class="controls"
    *ngIf="
      prjstStageList.length > 5 &&
      (prjstStageList | mytask : search).length > 0
    "
  >
    <pagination-controls
      (pageChange)="p = $event"
      align="end"
      style="    font-family: 'Mulish', sans-serif;
      position: relative;
      float: inline-end;"
    ></pagination-controls>
  </div> -->
        <div
          class="pagination-content"
          *ngIf="
            prjstStageList.length > 5 &&
            (prjstStageList | mytask : search).length > 0
          "
          id="page"
        >
          <pagination-controls
            *ngIf="(prjstStageList | mytask : search).length > 5"
            style="position: relative; float: inline-end"
            (pageChange)="p = $event"
            align="end"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="width: 3rem; height: 3rem; margin-top: 40px"
      role="status"
    ></div>
  </div>
  <div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>
  <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br />{{ error_Msg }}
    <button
      *ngIf="importerror"
      (click)="showerrormessage()"
      class="btn btn-danger"
    >
      View Error Records
    </button>
  </div>
</div>
