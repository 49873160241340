/* import { Component, OnInit } from '@angular/core';
import { PmsService } from '../../../providers/pms.service';
//import { AddBranchComponent } from '../add-branch/add-branch.component';
import { base_url,pms_base_url,httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../../providers/properties';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import {Sort} from '@angular/material/sort';
 */

import { Component, OnInit,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { RecruitmentService } from '../../../providers/recruitment.service';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../../../providers/properties';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { ExcelService} from '../../../services/excel.service';
import {Sort} from '@angular/material/sort';
import { PmsService } from '../../../providers/pms.service';
import { AppComponent } from '../../../app.component';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from 'src/app/providers/header.service';


@Component({
  selector: 'app-kra',
  templateUrl: './kra.component.html',
  styleUrls: ['./kra.component.scss']
})

export class KraComponent implements OnInit 
{
  [x: string]: any;

  filterContent:any;
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  kralistData:any=[];
  list_loader = true;
  nodata = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  exportingdata:any;
  exported:any;
  loader:any= false;
  backdrop:any= false;

  searchForm!:FormGroup;
  sortedData: any[];

  resultData : any;
  pagePerItem:any=5;
  tableSearch : any;
  nodatafound: boolean = false;
  showPagination: boolean = true;

  showTable:boolean=false;
  clearButtonEnabled: boolean = false;

  keywordsControl: FormControl;
  loading: boolean = false;

  buttonsDisabled: boolean = false;

 

  

  constructor(private router:Router,
  private pmsService: PmsService,
  private dialog:MatDialog,
  public ExcelService:ExcelService,
  private fb:FormBuilder,
  private route: ActivatedRoute,
  private headerservice: HeaderService,
  @Inject(AppComponent) public AppData: any,
  ) { }

  ngOnInit(): void 
  {
    

    // this.nodatafound = true;
    // this.loader=true;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    this.searchForm=this.fb.group({
      keywords:[null]
    })
    // this.getFilterFormData(this.orgId);

    // this.headerservice.setTitle('KRA Template');
    this.headerservice.setTitle('');

    this.searchForm = this.fb.group({
      keywords: ['', [Validators.required, this.noSpecialCharsValidator]]
    });

    
  }

  // isFormValid() {
  //   return this.searchForm.get('keywords').value !== null;
  // }



 
 
 

  getFilterFormData(orgId)
  {
    console.log("List Api data"+orgId);
    this.loader=true;
    this.showTable=false;
    this.buttonsDisabled = true;
    this.pmsService.getKRATemplateList(this.orgId).subscribe((data:any) =>
      {
      console.log("data",data);
      this.kralistData=data;
      
      console.log(this.kralistData);
      this.loader=false;
      this.showTable=true;
      this.nodatafound=false;
      this.showPagination = this.kralistData.length < 1;
      if(data.statusCode==204)
      {
        console.log("nodata");
        this.nodatafound=true;
      }
      this.buttonsDisabled = false;
      // if(this.kralistData.length==0)
      // {
      //   this.nodatafound=true;
      // }
      })
  }

  // searchSubmit()
  // {
  //   this.kralistData=[];
  //   let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
  //   console.log(keywords);
  //   this.filterContent=keywords;
  //   console.log(this.filterContent);
  //   if(keywords == null)
  //   {
  //     alert('Please Search Keywords');
  //     this.getFilterFormData(this.orgId)
  //     return;
  //   }
    
    
  //     console.log("orgId:",this.orgId);
  //     this.loader=true;
  //     this.showTable=false;

  //     this.searchForm.get('keywords').disable();
      
  //     this.pmsService.getAssignKRATemplateOrgAndKeywordList(this.orgId,keywords).subscribe((filterData: any) => 
  //     {
  //       this.loader=false;
  //       this.showTable=true;
  //       this.nodatafound = false;
  //       this.kralistData = filterData;
  //       console.log(this.kralistData);
  //       if(this.kralistData.statusCode==204)
  //       {
  //         console.log("nodata");
  //         this.nodatafound=true;
  //         this.loader=false;
  //         this.showTable=true;
  //       }
  //       if(this.kralistData.length == 0 || this.kralistData.length == undefined)
  //       {
  //         this.nodatafound = true;
  //         this.loader=false;
  //         this.showTable=true;
  //       }

  //       this.clearButtonEnabled = true; 
  //       this.p = 1;
  //       this.searchForm.get('keywords').enable();
        
        
  //     })
    
   
  // }

  searchSubmit() {

    
  
    this.kralistData = [];
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
    console.log(keywords);
    this.filterContent = keywords;
    console.log(this.filterContent);
  
    if (keywords == null) {
      // alert('Please Search Keywords');
      this.getFilterFormData(this.orgId);
      return;
    }
  
    console.log("orgId:", this.orgId);
    this.loader = true;
    this.showTable = false;
  
    // Disable the search and clear buttons
    this.loading = true;
    this.buttonsDisabled = true;
  
    this.pmsService.getAssignKRATemplateOrgAndKeywordList(this.orgId, keywords).subscribe((filterData: any) => {
      this.loader = false;
      this.showTable = true;
      this.nodatafound = false;
      this.kralistData = filterData;
      console.log(this.kralistData);
  
      if (this.kralistData.statusCode == 204) {
        console.log("nodata");
        this.nodatafound = true;
        this.loader = false;
        this.showTable = true;
      }
      if (this.kralistData.length == 0 || this.kralistData.length == undefined) {
        this.nodatafound = true;
        this.loader = false;
        this.showTable = true;
      }
  
      // this.clearButtonEnabled = true;
      this.p = 1;
  
      
      this.loading = false;
      this.buttonsDisabled = false;
    }, (error) => {
     
      this.loading = false;
      this.buttonsDisabled = false;
    });
  }
  
  


  
  isInteger(value) {
    return typeof value === 'number' && Number.isInteger(value);
  }

  getKRATemplateList()
  {
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;
    
    if(keywords)
    {
      // this.loader=true;
      this.backdrop=true;
     
      this.PmsService.getKRATemplateList(this.organizationId,keywords).subscribe((resultData: any) => 
      {
        // this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
  }

  // updateStatus(id,status)
	// {
  //   console.log("Template Id:"+id,"Status:"+status);
  //   this.loader=true;
  //   this.pmsService.updateKRATemplateStatus(id,status).subscribe((result:any) =>
	// 	{
  //     if(result.statusCode == 200)
  //     { 
  //       this.success = true;
  //       this.loader=false;
	// 			this.sucess_msg = result.message;
       
  //       setTimeout(() => {this.success  = false;},3000)
        
  //       this.getFilterFormData(this.orgId);
  //       // this.searchSubmit();
  //       // if(this.searchForm.value.keywords!=null||this.searchForm.value.keywords!=undefined)
  //       // {
  //       //   console.log("FilterContent");
  //       //   this.searchSubmit();
  //       // }
  //       // else
  //       // {
  //       //   console.log("FilterList");
  //       //   this.getFilterFormData(this.orgId)
  //       // }
        
  //     }
  //     else
  //     {
  //       this.error = true;
  //       this.loader=false;
  //       this.error_msg = result.message;
  //       setTimeout(() => {this.error = false}, 3000)
  //     }
	// 	})

	// }


  updateStatus(id,status)
	{
    this.pmsService.updateKRATemplateStatus(id,status).subscribe( (result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.loader=true
        this.showTable=false;
        // this.success = true;
				// this.sucess_msg = result.message;
        setTimeout(() => {
          // this.success  = false;
          this.loader=false
          this.showTable=true
        },3000)

        this.getFilterFormData(this.orgId);
      }
      else
      {
        // this.error = true;
        // this.error_msg = result.message;
        setTimeout(() => {this.error = false}, 3000)
      }
		})
	}

  

  
  ucFirst(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return ''; // or handle the case appropriately
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  


  clearTable()
  {
    this.kralistData=[]; 
    // this.nodatafound = false;
    console.log('clear');
    this.searchForm.clearValidators;
    this.nodatafound = false;
    // this.getFilterFormData(this.orgId);
   
    this.clearButtonEnabled = false;
    this.searchForm.reset();
    this.kralistData.length=0;
    this.showTable=false;

  }

  sortData(sort:Sort) 
  {
    // const data = this.resultData.slice();
    const data = this.kralistData;
    console.log("Data",data);
    // const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) 
      {
        case 'templateCode':
          return compare(a.templateCode, b.templateCode, isAsc);
        case 'templateName':
          return compare(a.templateName, b.templateName, isAsc);
        // case 'mobileNumber':
        //   return compare(a.mobileNumber, b.mobileNumber, isAsc);
        default:
          return 0;
      }
    });
  }

  onItemsPerPageChange() {
    this.p = 1; // Reset pagination to first page when items per page changes
  }

  // validateInput(event) {
  //   const input = event.target;
  //   let value = input.value;

  //   if (value.startsWith(' ')) {
  //     value = value.trimStart();
  //     input.value = value;
  //   }
  // }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Allow only alphanumeric characters, hyphens, and underscores
    const regex = /^[a-zA-Z0-9-_]*$/;
    if (!regex.test(value)) {
      value = value.replace(/[^a-zA-Z0-9-_]/g, '');
    }

    input.value = value;
    this.searchForm.get('keywords').setValue(value);
  }

  noSpecialCharsValidator(control: FormControl) {
    const regex = /^[a-zA-Z0-9-_]*$/;
    const valid = regex.test(control.value);
    return valid ? null : { invalidCharacters: true };
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
