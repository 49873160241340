import { Component, Inject, OnInit } from '@angular/core';
import { PayrollService } from '../providers/payroll.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router ,ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { MatDialog } from '@angular/material/dialog';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { SettingsService } from '../providers/settings.service';

@Component({
  selector: 'app-client-run-payroll',
  templateUrl: './client-run-payroll.component.html',
  styleUrls: ['./client-run-payroll.component.scss']
})
export class ClientRunPayrollComponent implements OnInit {

  length: number;
  dataArray: any=[];
  financialYear: any;
  period: string;
  startIndex: number;
  allemployees: boolean = false;
  users(users: any) {
    throw new Error('Method not implemented.');
  }
  payrollForm:FormGroup;
  today: any =  new Date().getFullYear() + 5;
  submitted: boolean = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  usertData:any;
  orgId:number;
  clientList:any;
  empList:any=[];
  empNum:number;
  nodatafound: boolean = true;
  update_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  resultData : any = [];
  noRecordsFound: boolean =false;
  filteredEmp: Observable<string[]>;
  control: any;
  userId: number;
  employee: any;
  employeeId: number;
  stardate: any;
  enddate: any;
  filterstardate:any;
  filterenddate:any;
  clientId:any;
  pagePerItem:any=10;
  p: number = 1;
  tableSearch : any;
  public searchFilter: any = '';
  clientupdateId: any;
  errorMsg : any;
  employeeStatus: any;
  errormessagejson: any;
  importerror: boolean = false;
  dataExport = [];
  exported:any;
  exportingdata:any;
  beforepage :any;
  dateFormat: any;
  finYearList: any=[];
  chooseFinId:number;
  finPeriodList: any=[];
  empCode: boolean=false;
  empRatings:any=[];
  selectedEmployeeData: any = [];
  financialPeriodList: any = [];
  empratingId: number;
  employeetData: any;
  userdata: any;
  reportData: any;
  firstname: any;
  lastname: any;
  empnumber: any;
  data: any[];
  // pagePerIist:any=10;


  constructor(private fb:FormBuilder,
    private payrollservice:PayrollService,
    private datepipe:DatePipe,
    private router:Router,
    private formBuilder: FormBuilder,
    private dialog:MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    @Inject(AppComponent) public AppData: any) { }
  

  ngOnInit(): void {
    this.data = Array.from({ length: 100 }).map((_, index) => `Item ${index + 1}`);
    this.updatePagedData();
    this.payrollForm = this.fb.group({
      financialyear:[null,[Validators.required]],
      period:[null,[Validators.required]],
      employeecode:[null],
      client:[null,[Validators.required]],
   
      // employeeId:['']
    })
  
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.dateFormat = localStorage.getItem('dateFormatName');
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);

    this.settingsService.getFinicalyearActiveList(this.orgId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
      console.log(this.clientList);
    })  
      
  }
  
  
  getclientEmployees()
  {
      this.empList.length = 0;
       console.log(this.payrollForm.value.client)
      if(this.payrollForm.value.client != null && this.payrollForm.value.client != "null")
      {
        // this.payrollservice.getemployeeclient(this.payrollForm.value.client).subscribe((ClientEmployees: any) => {
        //   if(ClientEmployees.length != undefined)
        //   {
        //     this.empList = ClientEmployees;
        //   }
         
        // });
    }
  }


  // patchresource()
  // {
  //   this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
  //   let organizationId = this.employeetData.organization.organizationId;
  //   console.log('organizationId'+organizationId)
  //   console.log('patch',this.empratingId);
  //   if(this.empratingId)
  //   {
  //     this.payrollservice.getEmployeeRatingId(this.empratingId).subscribe((userdata:any) =>
  //     { 
  //       console.log('Ratingdata', userdata)
  //       this.userdata=userdata;
  //        console.log('this.userdata', this.userdata);
  //        this.financialYear=userdata.financialPeriod.finanicalYear.financialFromMonth+"-"+userdata.financialPeriod.finanicalYear.financialFromYear+" To "+
  //        userdata.financialPeriod.finanicalYear.financialToMonth+"-"+userdata.financialPeriod.finanicalYear.financialToYear;
  //       console.log(this.financialYear) ;
  //       this.period=userdata.financialPeriod.financialperiodMonth+"-"+userdata.financialPeriod.financialPeriodYear;
  //       console.log(this.period) ;
  //       this.payrollForm.patchValue({
  //         financialYear: this.financialYear, 
  //         period: this.period, 
  //       });

  //       var financialYear = this.userdata.financialPeriod.finanicalYear.financialFromMonth + "-" +
  //       this.userdata.financialPeriod.finanicalYear.financialFromYear + " to " + 
  //       this.userdata.financialPeriod.finanicalYear.financialToMonth + "-" +
  //       this.userdata.financialPeriod.finanicalYear.financialToYear;

  //      var financialPeriod = this.userdata.financialPeriod.financialperiodMonth + "-" +
  //       this.userdata.financialPeriod.financialPeriodYear;
  //       console.log("financialYear", this.payrollForm.get('financialYear'));
  //       console.log("financialPeriod", this.payrollForm.get('period'));
  //       this.payrollForm.get('financialYear').patchValue(this.userdata.financialPeriod.finanicalYear.financialYearId);
  //       this.payrollForm.get('period').patchValue(this.userdata.financialPeriod.financialPeriodId);  
  //       console.log("financialYear", this.payrollForm.get('financialYear'));
  //       console.log("financialPeriod", this.payrollForm.get('period'));
  //       // Adding a row with leaveDays
  //       this.selectedEmployeeData.push(this.userdata);

  //       console.log("this.selectedEmployeeData5", this.selectedEmployeeData);
  //       const rowsArray = this.payrollForm.get('Ratings') as FormArray;
  //       rowsArray.push(this.formBuilder.group({
  //         employeeId: userdata.employee.employeeNumber,
  //         emprating: userdata.emprating, 
  //       }));
  //       this.employeeId = userdata.employee.employeeId;
  //     })
  //   }
  // }

  get validate() { return this.payrollForm.controls; }

 

  onSubmit()
  {
    console.log("submit");
    console.log("this.selectedEmployeeData", this.selectedEmployeeData);
    this.submitted=true;
    if (this.payrollForm.invalid  || this.selectedEmployeeData.length == 0) {
      console.log("invalid")
      return;
    }
      this.backdrop  =true;
       this.loader = true;
      
       if(this.selectedEmployeeData.length > 1 && this.allemployees) // ALL Block
       {
            this.reportData=this.payrollForm.value;
            console.log("this.reportDate",this.reportData);
          
              
              const employeepayroll = {
                finanicalPeriod: 
                        { 
                          financialPeriodId: this.payrollForm.value.period
                        },
                        client: {
                          clientId: this.payrollForm.value.client
                      }
               
            };
                console.log(employeepayroll);
                  
                this.payrollservice.runpayrollbyclientemployeeAll(employeepayroll,this.payrollForm.value.client).subscribe((result: any) =>
            {
              if(result.statusCode == 200)
              {
                this.success = true;
                this.sucess_msg = result.description;      
                setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
                this.payrollForm.reset();     
                this.submitted = false;
                this.loader = false;
                this.backdrop  =false;
                // this.searchSubmit();
                setTimeout(() => {
                  this.router.navigate(["home/client-employee-payroll-list"])
                }, redirectMsgTimeOut)
              }
              else
              {
                console.log("err1",result.statusCode);
                this.error = true;
                this.error_msg = result.description;
                this.errormessagejson = result;
                console.log('this.errormessagejson',this.errormessagejson);
                this.importerror = true;
                this.loader = false;
                this.backdrop  =false;
                setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      
                // setTimeout(() => {
                // }, redirectMsgTimeOut)
              }
      
            }, err =>
            {
              console.log("code",err);
              console.log("code",err.statusCode);
              // if(err.error.statusCode == 409)
              // {
              //   console.log("code1",err.statusCode);
              this.loader = false;
              this.backdrop  =false;
                setTimeout(() => {
                  this.error = true;
                  this.errorMsg = err.error.description;
                }, redirectMsgTimeOut) 
              // }
              
            })
       }else if (this.selectedEmployeeData.length > 1 && !this.allemployees){
               console.log("not all emps")
        this.reportData=this.payrollForm.value;
        console.log("this.reportDate",this.reportData);
       
          
          const employeepayroll = {
            finanicalPeriod: 
                     { 
                      financialPeriodId: this.payrollForm.value.period
                     },
            employees:  this.selectedEmployeeData,
            
            client: {
              clientId: this.payrollForm.value.client
          }
        };
            console.log(employeepayroll);

           //   return
               
            this.payrollservice.runpayrollbyemployeeswages(employeepayroll).subscribe((result: any) =>
         {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;      
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            this.payrollForm.reset();     
            this.submitted = false;
            // this.searchSubmit();
            setTimeout(() => {
              this.router.navigate(["home/client-employee-payroll-list"])
            }, redirectMsgTimeOut)
          }
          else
          {
            console.log("err1",result.statusCode);
            this.error = true;
            this.error_msg = result.description;
            this.errormessagejson = result;
            console.log('this.errormessagejson',this.errormessagejson);
            this.importerror = true;
            this.loader = false;
            this.backdrop  =false;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
  
            // setTimeout(() => {
            // }, redirectMsgTimeOut)
          }
  
        }, err =>
        {
          console.log("code",err);
          console.log("code",err.statusCode);
          this.loader = false;
          this.backdrop  =false;
          // if(err.error.statusCode == 409)
          // {
          //   console.log("code1",err.statusCode);
            setTimeout(() => {
              this.error = true;
              this.errorMsg = err.error.description;
            }, redirectMsgTimeOut) 
          // }
           
        })
      
          
       }
       
       
       
       else{
              
        this.reportData=this.payrollForm.value;
        console.log("this.reportDate",this.reportData);
       
          
          const employeepayroll = {
            finanicalPeriod: 
                     { 
                      financialPeriodId: this.payrollForm.value.period
                     },
            employee: 
            { 
              employeeId: this.employeeId
            },
            client: {
              clientId: this.payrollForm.value.client
          }
        };
            console.log(employeepayroll);
               
            this.payrollservice.runpayrollbyemployeewages(employeepayroll).subscribe((result: any) =>
         {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.description;      
            setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            this.payrollForm.reset();     
            this.submitted = false;
            // this.searchSubmit();
            setTimeout(() => {
              this.router.navigate(["home/client-employee-payroll-list"])
            }, redirectMsgTimeOut)
          }
          else
          {
            console.log("err1",result.statusCode);
            this.error = true;
            this.error_msg = result.description;
            this.errormessagejson = result;
            console.log('this.errormessagejson',this.errormessagejson);
            this.importerror = true;
            this.loader = false;
            this.backdrop  =false;
            setTimeout(() => {this.error = false}, redirectMsgTimeOut)
  
            // setTimeout(() => {
            // }, redirectMsgTimeOut)
          }
  
        }, err =>
        {
          console.log("code",err);
          console.log("code",err.statusCode);
          this.loader = false;
          this.backdrop  =false;
          // if(err.error.statusCode == 409)
          // {
          //   console.log("code1",err.statusCode);
            setTimeout(() => {
              this.error = true;
              this.errorMsg = err.error.description;
            }, redirectMsgTimeOut) 
          // }
           
        })
      
          
       }

   
     
    
     
    
  }


  
  selectFinancialyear(event)
  {
    console.log("Select indidual Employee");
    console.log(event.target.value);
    // console.log(this.filterForm.value.financialyear);
     this.chooseFinId=event.target.value;
    console.log(this.chooseFinId);
    this.settingsService.getFinancialYearId(this.chooseFinId).subscribe((resultData: any) => {
      console.log("PeriodData", resultData)
      this.finPeriodList = resultData.finanicalPeriod;
    });
  }

  empSearch(event)
{ 
  this.empList=[];
  this.empCode=false;
  console.log(event.target.value);
  this.userId=event.target.value;
  console.log(this.userId);
  // Employee List Api
    // this.empList.length == 0;
  this.payrollservice.getClienEmployeeList(this.userId,this.payrollForm.value.client).subscribe((result: any) => {
    if(result.statusCode==204)
          {
            console.log('NO Data');
            this.empList.length = 0;
            this.empCode=true;
          }
          else
          {
            this.empList = result;
            this.empCode=false;
            console.log(this.empList);
          }
  })
}
empSelect(emp) {
  // this.selectedEmployeeData=[];
  console.log(emp);
  // this.empList=[];
  this.employeeId = emp.employeeId;
  this.firstname=emp.firstName;
  this.lastname=emp.lastName;
  this.empnumber=emp.employeeNumber;
  // if (this.employeeLeaveId) {
  //   this.payrollForm.patchValue({
  //     employee: this.employee
  //   });
  // } 
  // else {

    this.payrollForm.patchValue({
      employeecode: null
    });
    console.log("select",this.selectedEmployeeData);
    let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == emp.employeeId);
    if (!hasemployee) {
      
      this.selectedEmployeeData.push(emp);
     
    }

  // }

}
// empSelect(emp)
// {
//   console.log(emp);
//   this.employeeId=emp.employeeId;
//   this.employee=emp.employeeId;
//   console.log(this.employee);
//   this.payrollForm.patchValue({
//     employeecode: this.employee
//    });
// }
close()
{
  console.log("close");
  this.router.navigateByUrl('/home/client-employee-payroll-list');
}
pageChages()
  {
    console.log("page changes");
    this.p=1;
  }

empAllSelect()
{
  // this.empList=[];
  // this.selectedEmployeeData=[];
 
  if(this.payrollForm.value.employeecode == "All")
  {
     this.allemployees= true;
    console.log("select",this.selectedEmployeeData);
    this.payrollservice
    .getemployeeclient(this.payrollForm.value.client).subscribe((result: any) => {
      console.log(result);
      if(result.length==0)
      {
        console.log("noData");
        // this.empList.length = 0;
      }
      // this.empList=result;
      console.log("All",this.empList);
      result.forEach(element => {
        let hasemployee = this.selectedEmployeeData.some(e => e.employeeId == element.employeeId);
        if (!hasemployee) {
          this.selectedEmployeeData.push(element);
          console.log('selectedEmployeeData', this.selectedEmployeeData);
         
        }
      });
    })

  }
}



showerrormessage()
{
  const dialogRef = this.dialog.open(ShowerrorComponent, {
    //height: 'auto',width: '600px',
    width: '500px',height:'fit-content',data: this.errormessagejson
  });
  this.error = false;
}

// deleteemployee(index) 
//   {
//   console.log(index);
//   console.log(this.dataArray);
//   if (index != -1) {
//     this.dataArray.splice(index, 1);
//     console.log(this.dataArray);
//   }
//   }
  deleteemployee(index) {
    console.log(index + index);
    if (index != -1) {
     
       
          this.selectedEmployeeData.splice(index, 1);
          console.log(this.selectedEmployeeData.length);
        
     }
  }
  onPageChange(event: any): void {
    this.p = event;
    this.updatePagedData();
  }

  updatePagedData(): void {
    const startIndex = (this.p - 1) * this.pagePerItem;
    const endIndex = startIndex + this.pagePerItem;
    console.log(startIndex);
    this.startIndex=startIndex;
    console.log(this.startIndex);
    console.log(endIndex);
  }


}
