<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Consolidate Payslip Report</span>
                </div>
                <!-- <div class="sub-header-buttons">
                    <button  class="but ml-2 mb-2" type="button">
                        <mat-icon class="material-symbols-outlined"  (click)="downloadAsExcel();">file_download</mat-icon>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm" (ngSubmit)="searchSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="form-control-label"  style="font-family: Mulish; font-size:16px;">Search Employee</label>
                            <input formControlName="employeecode" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search...">
                                <small class="text-muted">Note : Employee Number</small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="form-control-label"  style="font-family: Mulish; font-size:16px;">Financialyear</label>
                            <mat-select formControlName="financialyear" placeholder="Select Financialyear " class="form-select email-input select-placeholder form-select-solid form-select-lg select2-new" (selectionChange)="selectFinancialyear()"
                            style=" background-image: none;" >
                                <mat-option [value]=null> -Select Financialyear- </mat-option>
                                <mat-option *ngFor="let row of finYearList; let i=index;" value="{{row.financialYearId}}" >{{row.financialFromMonth}}-{{row.financialFromYear}} To {{row.financialToMonth}}-{{row.financialToYear}}</mat-option> 
                            </mat-select>
                        </div>
                        <div class="form-group col-md-3 pl-1">
                            <label class="form-control-label"  style="font-family: Mulish; font-size:16px;">From Period</label>
                            <mat-select formControlName="fromperiod" placeholder="Select From Period " class="form-select email-input select-placeholder form-select-solid form-select-lg select2-new"  (selectionChange)="selectFinancialfromPeriod()"  
                            style=" background-image: none;">
                                <mat-option [value]=null> -Select From Period- </mat-option>
                                <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option> 
                            </mat-select>
                        </div>
                        <div class="form-group col-md-3 pl-1">
                            <label class="form-control-label" style="font-family: Mulish; font-size:16px;">To Period</label>
                            <mat-select formControlName="toperiod" placeholder="Select To Period" class="form-select email-input select-placeholder form-select-solid form-select-lg select2-new" (selectionChange)="selectFinancialtoPeriod()"
                            style=" background-image: none;">
                                <mat-option [value]=null> -Select To Period - </mat-option>
                                <mat-option *ngFor="let row of finPeriodList; let i=index;" value="{{row.financialPeriodId}}">{{row.financialperiodMonth}}-{{row.financialPeriodYear}}</mat-option> 
                            </mat-select>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <button type="submit" mat-raised-button  [disabled]="isProcessing"
                                class="searchBtn">Search</button>
                                <button style="margin-left: 10px;" type="reset" mat-raised-button
                                class="btn mr-2 search-btn" (click)="clearTable()"
                                >Clear</button>
                        </div>
                        <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                            <button  class="but mb-2" type="button" style="color: white;"   (click)="downloadAsExcel();">
                                <mat-icon class="material-symbols-outlined">file_download</mat-icon> Download Excel
                            </button>
                          
                        </div>
                        <!-- <div class="form-group col-md-2 pl-1" *ngIf="resultData.length>0">
                            <button  class="but ml-2 mb-2" type="button"  style="color: white;"  (click)="printPdf();" >
                                <mat-icon class="material-symbols-outlined" >print</mat-icon> Download PDF
                            </button>
                        </div> -->
                    </div>
                </form>
                <!-- <div class="row pb-3">
                    <div class="col-md-11 text-right">
                        <p class="show-count">Show</p>
                    </div>
                    <div class="col-md-1 text-left pl-0">
                        <select [(ngModel)]="pagePerItem"  (click)="pageChages()" class="form-control report-filter">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div> -->
                <div class="float-end" *ngIf="tableShow">
                    <div class="row rowDesignshow" *ngIf="resultData?.length > 0">
                      <div
                        class="select-pagination"
                        style="
                        
                          margin-left: 15px;
                          margin-bottom: 10px;
                        "
                        *ngIf="resultData?.length > 5"
                      >
                        <span class="showPage showDesign" style="    position: relative;
          right: 5px;">Show</span>
                        <select
                          class="select_list new-select_list listDesign"
                          [(ngModel)]="mySelect"
                      
                          (change)="changefirstpage()"
                        >
                          <option class="form-control" value="5">5</option>
                          <option class="form-control" value="10">10</option>
                          <option class="form-control" value="15">15</option>
                          <option class="form-control" value="20">20</option>
                          <option class="form-control" value="25">25</option>
                        </select>
                      </div>
                    </div>
                  </div>
                <div class="table-responsive" *ngIf="tableShow">
                    <table class="table  --table-bordered table-border-new" style="    margin-top: 15px;">
                        <thead class="t-head">
                            <tr >
                                <th   style="border-radius: 15px 0px 0px 0px">Employee Code</th>
                                <th>Employee Name</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>IFSC Code</th>
                                <th>Bank Name</th>
                                <th>Branch Name</th>
                                <th>Address</th>
                                <th>Period</th>
                                <th *ngFor="let elements of paySlipElement">{{elements.elementName}}</th>
                            </tr>
                        </thead>
                        <tbody  style="
                        border-left: 1px solid #ddd;
                      
                        border-right: 1px solid #ddd;
                      ">
                            <tr *ngFor="let row of resultData | paginate: { itemsPerPage: mySelect, currentPage: p } let i=index;">
                                <td>{{row.employee.employeeNumber}}</td>
                                <td>{{row.employee.firstName}}</td>
                                <td>{{row.employee.employeeInfo.accountName}}</td>
                                <td>{{row.employee.employeeInfo.bankAccountNo}}</td>
                                <td>{{row.employee.employeeInfo.ifscCode}}</td>
                                <td>{{row.employee.employeeInfo.bankName}}</td>
                                <td>{{row.employee.employeeInfo.bankBranchName}}</td>
                                <td>{{row.employeeAnnexure.place}}</td>
                                <td style="white-space: nowrap;">{{row.finanicalPeriod.financialperiodMonth }} - {{ row.finanicalPeriod.financialPeriodYear}}</td>
                                <td  *ngFor="let elements of paySlipElement" >
                                    <div *ngFor="let payrollLine of row.payrollLine">
                                        <div *ngIf="payrollLine.hrmspaySlipElement.elementId === elements.elementId" >
                                            {{payrollLine.amountDouble?.toFixed(2)}}
                                        </div>
                                    </div> 
                                </td>
                            </tr>
                            <tr *ngIf="noRecordsFound == false" >
                                <td class="text-right" style="font-size: 13px;" colspan="9"><b>Total&nbsp;&nbsp;&nbsp;</b></td>
                                <td *ngFor="let e of paySlipElement" class="text-right" style="font-size: 13px;text-align: right" colspan="1">{{e.amount?.toFixed(2)}}</td>
                            </tr>
                        </tbody>
                        <tbody
                        style="
                          border-left: 1px solid #ddd;
                          border-right: 1px solid #ddd;
                        "
                        *ngIf="tableShow"
                      >
                        <tr>
                          <td
                            style="
                              text-align: center;
                              padding: 10px;
                              font-size: 19px;
                              font-weight: 600;
                              font-family: 'Mulish', sans-serif;
                              left: 5;
                              position: relative;
                            "
                            colspan="11"
                            *ngIf="noRecordsFound || resultData?.length == 0"
                          >
                           Consolidate Payslip Reports Records Not Found!
                          </td>
                        </tr>
                      </tbody>
                    </table>
               
                       
                
                 </div>
                 <!-- <div *ngIf="noRecordsFound">
                    <p style="text-align: center;" [attr.colspan]="paySlipElementlength">No Records Found!</p>
                </div> 
                <div *ngIf="noRecordsFound == false">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="showing-record">
                                {{resultData.length}} rows
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>


<!-- <mat-card style=margin-top:27% class="card-content-nodata mat-card-nodata" *ngIf="noRecordsFound&&tableShow">
    <div class="card-body-nodata">
      <div class="alert-nodata --alert-primary">
        <h4 align="center" class="--h4" style="padding: 10px;    margin-top: 3px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 0px;
        ">Consolidate Payslip Report Records Not Found </h4>
      </div>
    </div>
  </mat-card> -->


  <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;top: 53%;right: 47.8%;" role="status"></div>
  </div>