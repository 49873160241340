import { Component, OnInit, Inject } from '@angular/core';
/* import { Component, OnInit,Inject} from '@angular/core'; */
import { RecruitmentService } from '../../providers/recruitment.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
//import{ GlobalConstants } from '../../../common/global-constants'; //Global Variables
import { AppComponent } from '../../app.component';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';
// import { rec_base_url } from 'src/app/providers/properties';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resource-detail-new',
  templateUrl: './resource-detail-new.component.html',
  styleUrls: ['./resource-detail-new.component.scss'],
})
export class ResourceDetailNewComponent implements OnInit {
  resourceId: any;
  loader: any = true;
  resourceobj: any;
  downloadbaseurl = environment.rec_base_url;

  feedbackId: any;
newFileSelected: any;
  cvFileName: any;
  constructor(
    private hrmsService: HrmsserviceService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  feedbackObj: any;
  ngOnInit(): void {
    this.resourceId = this.activatedRoute.snapshot.params.resourceId;
    this.hrmsService
      .findbyResourceId(this.activatedRoute.snapshot.params.resourceId)
      .subscribe((resource: any) => {
        this.resourceobj = resource;
        this.loader = false;
        console.log(resource);

        this.cvFileName=resource.resourcecvattachment?.cvFileName;
        console.log("this.cvFileName::",this.cvFileName);
        

      });
    this.hrmsService
      .getRessourceFeedbackByResourceId(this.resourceId)
      .subscribe((feedback: any) => {
        this.feedbackObj = feedback;
        console.log('this.feedbackObj', this.feedbackObj);
        if (this.feedbackObj.statusCode == 200) {
          console.log('if no feedbackId');
        } else {
          console.log('else feedbackId', this.feedbackObj.feedBackId);
          this.feedbackId = this.feedbackObj.feedBackId;
        }
      });
  }

  back() {
    this.router.navigate(['home/resource-list']);
  }
}
