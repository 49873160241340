import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-salary-advance',
  templateUrl: './request-salary-advance.component.html',
  styleUrls: ['./request-salary-advance.component.scss']
})
export class RequestSalaryAdvanceComponent implements OnInit {
  SalaryAdvanceForm:FormGroup;
  finYearList: any =[];
  finPeriodList: any=[];
  submitted: boolean=false;
  organzationId: any;
  userdata: any;
  totalSalary: number;
  advancePaid: number;
  showError:boolean=false;
  success: boolean=false;
  sucessmessage: any;
  salaryAdvanceId: Number;
  financialPeriodList: any=[];
  advanceDetails: any;
  advanceEligible: any;
  annexureHeaderId: any;
  salaryAdvanceObject: any;
  Disable_submitToApprover:boolean=false;
  Disable_submitAsDraft:boolean=false;
  show_draft:boolean=true;
  show_submit:boolean=false;
  constructor(
    public router:Router,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    public fb :FormBuilder,
    private gs : GlobalserviceService,
    public activeRouter : ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(this.userdata);
    this.organzationId = this.userdata.organization.organizationId;
    console.log("organziationid " + this.organzationId);

    this.salaryAdvanceId = this.activeRouter.snapshot.params.id;

    this.settingsService.getFinicalyearList( this.organzationId).subscribe((resultData: any) => {
      console.log(resultData);
      this.finYearList = resultData;
    });
    console.log("SalaryadvanceDetails",this.advanceDetails);

    this.SalaryAdvanceForm=this.fb.group({
      financialyear:[null,Validators.required],
      period:[null,Validators.required],
      advancePaid:['',Validators.required],
      advanceEligible:['',Validators.required],
      advanceamount:['',Validators.required]
    });
    if(this.salaryAdvanceId){
      this.gs.getListBySalaryadvanceId(this.salaryAdvanceId).subscribe(
        (data:any)=>{
          console.log(data);
          this.salaryAdvanceObject=data;
          this.annexureHeaderId = data.annexureId.annexureHeaderId;

          if(data.draft == false){
            this.show_draft = false;
            this.show_submit = true;
          }
          else{
            this.show_draft = true;
            this.show_submit = true;
          }
          this.gs.getAdvancedetailsForUpdate(data.financialPeriod.financialPeriodId,this.userdata.employee.employeeId,this.salaryAdvanceObject.salaryAdvanceId,this.organzationId).subscribe(
          (dataIn:any)=>{
            console.log("data",dataIn);
            this.SalaryAdvanceForm.patchValue({
              financialyear : data.financialPeriod.finanicalYear.financialYearId,
              period : data.financialPeriod.financialPeriodId,
              advancePaid : dataIn.advanceAmountPaid,
              advanceEligible : dataIn.advanceEligibleAmount,
              advanceamount : data.requestedAdvanceAmount
            })
            this.totalSalary = dataIn.totalSalary;
            this.settingsService.getFinancialYearId(this.SalaryAdvanceForm.value.financialyear).subscribe(
              (resultData: any) => {
                this.finPeriodList = resultData.finanicalPeriod;
              }
            );
            console.log(this.SalaryAdvanceForm.value);
          })
        }
      )
    }
  }

  checkAdvanceEligibility(event: any) {
    console.log('Key up event occurred:', event.target.value);
    console.log("advanceEligible ",this.SalaryAdvanceForm.value.advanceEligible);
    if (event.target.value > this.SalaryAdvanceForm.value.advanceEligible) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }
  submitToApprover() {
    console.log("submit");
    this.submitted=true;
    console.log(this.SalaryAdvanceForm.invalid);
    console.log(this.SalaryAdvanceForm.value);
    if(this.SalaryAdvanceForm.invalid){
      return
    }
    if(this.showError == true){
      return
    }
    this.Disable_submitToApprover=true;
    if(this.salaryAdvanceId){
      let updateAdvance={
        salaryAdvanceId : this.salaryAdvanceObject.salaryAdvanceId,
        annexureId: {
          annexureHeaderId:this.annexureHeaderId
        },
        employee:{
          employeeId:this.userdata.employee.employeeId
        },
        requestedAdvanceAmount:this.SalaryAdvanceForm.value.advanceamount,
        financialPeriod: {
          financialPeriodId: this.SalaryAdvanceForm.value.period
        },
        advancePaid:this.SalaryAdvanceForm.value.advancePaid,
        AdvanceEligibility:this.SalaryAdvanceForm.value.advanceEligible,
        status:{
          listTypeValueId:this.salaryAdvanceObject.status.listTypeValueId
        },
        approvalStatus:{
          listTypeValueId:this.salaryAdvanceObject.approvalStatus.listTypeValueId
        },
        ['draft'] : false, 
      }
      console.log("updateAdvance",updateAdvance);
      this.gs.updateSalaryAdvance(this.salaryAdvanceId,updateAdvance).subscribe(
        ((data:any)=>{
            console.log(data);
            this.sucessmessage =  data.description;
            this.success=true;
            setTimeout(() => {
              this.router.navigate(["home/SalaryAdvance-list"]);
            }, 2000);
        })
      )
    }
    else{
      let CreateAdvance={
        annexureId: {
          annexureHeaderId:this.advanceDetails.annexureId.annexureHeaderId
        },
        employee:{
          employeeId:this.userdata.employee.employeeId
        },
        requestedAdvanceAmount:this.SalaryAdvanceForm.value.advanceamount,
        financialPeriod: {
          financialPeriodId: this.SalaryAdvanceForm.value.period
        },
        advancePaid:this.SalaryAdvanceForm.value.advancePaid,
        AdvanceEligibility:this.SalaryAdvanceForm.value.advanceEligible,
        ['draft'] : false, 
      }
      console.log("CreateAdvance",CreateAdvance);
      this.gs.createSalaryAdvance(CreateAdvance).subscribe(
        ((data:any)=>{
            console.log(data);
            this.sucessmessage =  data.description;
            this.success=true;
            setTimeout(() => {
              this.router.navigate(["home/SalaryAdvance-list"]);
            }, 2000);
        })
      )
    }
  }
  submitAsDraft(){
    console.log("submit as draft");
    this.submitted=true;
    console.log(this.SalaryAdvanceForm.invalid);
    console.log(this.SalaryAdvanceForm.value);
    if(this.SalaryAdvanceForm.invalid){
      return
    }
    if(this.showError == true){
      return
    }
    this.Disable_submitAsDraft=true;
    if(this.salaryAdvanceId){
      let updateAdvance={
        salaryAdvanceId : this.salaryAdvanceObject.salaryAdvanceId,
        annexureId: {
          annexureHeaderId:this.annexureHeaderId
        },
        employee:{
          employeeId:this.userdata.employee.employeeId
        },
        requestedAdvanceAmount:this.SalaryAdvanceForm.value.advanceamount,
        financialPeriod: {
          financialPeriodId: this.SalaryAdvanceForm.value.period
        },
        advancePaid:this.SalaryAdvanceForm.value.advancePaid,
        AdvanceEligibility:this.SalaryAdvanceForm.value.advanceEligible,
        status:{
          listTypeValueId:this.salaryAdvanceObject.status.listTypeValueId
        },
        approvalStatus:{
          listTypeValueId:this.salaryAdvanceObject.approvalStatus.listTypeValueId
        },
        ['draft'] : true, 
      }
      console.log("updateAdvance",updateAdvance);
      this.gs.updateSalaryAdvance(this.salaryAdvanceId,updateAdvance).subscribe(
        ((data:any)=>{
            console.log(data);
            this.sucessmessage =  data.description;
            this.success=true;
            setTimeout(() => {
              this.router.navigate(["home/SalaryAdvance-list"]);
            }, 2000);
        })
      )
    }
    else{
      let CreateAdvance={
        annexureId: {
          annexureHeaderId:this.advanceDetails.annexureId.annexureHeaderId
        },
        employee:{
          employeeId:this.userdata.employee.employeeId
        },
        requestedAdvanceAmount:this.SalaryAdvanceForm.value.advanceamount,
        financialPeriod: {
          financialPeriodId: this.SalaryAdvanceForm.value.period
        },
        advancePaid:this.SalaryAdvanceForm.value.advancePaid,
        AdvanceEligibility:this.SalaryAdvanceForm.value.advanceEligible,
        ['draft'] : true, 
      }
      console.log("CreateAdvance",CreateAdvance);
      this.gs.createSalaryAdvance(CreateAdvance).subscribe(
        ((data:any)=>{
            console.log(data);
            this.sucessmessage =  data.description;
            this.success=true;
            setTimeout(() => {
              this.router.navigate(["home/SalaryAdvance-list"]);
            }, 2000);
        })
      )
    }
  }
  cancel(){
    this.router.navigate(['home/SalaryAdvance-list']);
  }
  selectFinancialyear(event){
    console.log(event.target.value);
    this.settingsService.getFinancialYearId(event.target.value).subscribe(
      (resultData: any) => {
        console.log("PeriodData", resultData);
        this.finPeriodList = resultData.finanicalPeriod;
      }
    );
  }
  selectPeriod(event){
    console.log(event.target.value);
    this.gs.getAdvancedetails(event.target.value,this.userdata.employee.employeeId).subscribe(
      (data:any)=>{
        this.advanceDetails = data;
        console.log( this.advanceDetails);
        if(this.advanceDetails.salaryAdvanceRequestExist == true){
          Swal.fire({
            text: 'Salary Advance already requested for the selected period!',
            icon: 'error'
          })
          return
        }
        this.totalSalary =this.advanceDetails.totalSalary;
        this.advancePaid = this.advanceDetails.advanceAmountPaid;
        this.advanceEligible = this.advanceDetails.advanceEligibleAmount;
        console.log(this.totalSalary-this.advancePaid);
        this.SalaryAdvanceForm.patchValue({
          advancePaid : this.advancePaid,
          advanceEligible : this.advanceEligible-this.advancePaid,
        })
        console.log(this.SalaryAdvanceForm.value);
      }
    )
  }

}
