import { Component, Inject, OnInit } from '@angular/core';
import { ManageApproverListComponent } from '../manage-approver-list/manage-approver-list.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-manage-approvers',
  templateUrl: './view-manage-approvers.component.html',
  styleUrls: ['./view-manage-approvers.component.scss']
})
export class ViewManageApproversComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ManageApproverListComponent>,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any, 

    ) { }

  ngOnInit(): void {
    console.log(this.data);
  
      //  approvalStatus(approvalStatusName){
      //   if (approvalStatusName == null) {
      //     return
      //   }
      //   if (approvalStatusName == 'Pending') {
      //     return '#a68b00'
      //   }
      //   if (approvalStatusName == 'Approved') {
      //     return '#388e3c'
      //   }
      //   if (approvalStatusName == 'Denied') {
      //     return '#c62828'
      //   }
      // }
    
      // approvalBackground(approvalStatusName){
      //   if (approvalStatusName == null) {
      //     return
      //   }
      //   if (approvalStatusName == 'Pending') {
      //     return '#fff0c2'
      //   }
      //   if (approvalStatusName == 'Approved') {
      //     return '#c8e6c9'
      //   }
      //   if (approvalStatusName == 'Denied') {
      //     return '#ffcdd2'
      //   }
      // }
  

  }
  updateapprover(approverId:any){
    console.log(approverId);
    this.router.navigate([`home/manage-approver/${approverId}`]); 
  }

}
