import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuperadminService } from '../../../providers/superadmin.service';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss']
})
export class AddMenuComponent implements OnInit
{
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  menuNameExistStatus :any;
  updatemenuNameExistStatus :any;
  modulesLov :any;
  menuId :any;

  constructor(
    private superadminservice: SuperadminService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      //moduleId : ['',[Validators.required]],
      menuname : ['',[Validators.required]],
      menuDescription : [''],
    });

    if(this.edit_data)
    {
      this.menuId = this.edit_data.menuId;

      this.superadminservice.getMenuById(this.edit_data.menuId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          menuname: this.resultData.menuname,
          menuDescription: this.resultData.menuDescription,
          //moduleId: this.resultData.module.moduleId
         /*  module:{
            moduleId: this.resultData.module.moduleId
          }, */
        });
      });
    }

    this.getActiveModule();
  }


  get validate() { return this.postForm.controls; }

  onSubmit() //form Submit Function
  {
    this.submitted = true;

    if(this.edit_data) //Edit
    {

      if (this.postForm.invalid || this.menuNameExistStatus == true)
      {
        return;
      }

      //let postValues = this.postForm.value;

      var postValues = {
        menuname:this.postForm.value.menuname,
        menuDescription:this.postForm.value.menuDescription,
        /* module:{
          moduleId: this.postForm.value.moduleId
        }, */
      }

      postValues['menuId'] = this.edit_data.menuId;
      postValues['menustatus'] = this.edit_data.menustatus;

      this.backdrop = true;
      this.loader=true;

      /* if(this.postForm.valid && this.updateroleNameExistStatus == true)
      {
         return
      } */

      this.superadminservice.editMenu(this.edit_data.menuId,postValues).subscribe((resultData: any) =>
      {
        //this.router.navigate([ '/home/designation' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;


        this.dialogref.close(resultData);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    else //Create
    {
      if (this.postForm.invalid || this.menuNameExistStatus == true)
      {
        return;
      }

      var postValues = {
        menuname:this.postForm.value.menuname,
        menuDescription:this.postForm.value.menuDescription,
       /*  module:{
          moduleId: this.postForm.value.moduleId
        }, */
      }

      this.superadminservice.createMenu(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>
      {
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
  }

  checkExistMenuName(val)
  {

    let editData = 0;
    /* if(this.edit_data)
    {
      editData = this.edit_data;
    } */
    if(this.postForm.value.menuname.length != 0)
    {
      if(this.edit_data)
      {
        var postValues = {
          menuname:this.postForm.value.menuname,
          menuDescription:this.postForm.value.menuDescription,
          menustatus:{
            listTypeValueId: this.edit_data.menustatus
          },
          /* module:{
            moduleId: this.postForm.value.moduleId
          }, */
        }

        postValues['menuId'] = this.edit_data.menuId;
        postValues['menustatus'] = this.edit_data.menustatus;

        this.superadminservice.updateCheckExistMenuName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.menuNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.menuNameExistStatus = true;
          }
        })
      }
      else
      {
        this.superadminservice.createCheckExistMenuName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.menuNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.menuNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.menuNameExistStatus = false;
    }
  }

  getActiveModule()
  {
    this.superadminservice.getActiveModule().subscribe((resultData:any) =>
    {
      this.modulesLov=resultData;
    });
  }
}
