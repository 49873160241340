<mat-card-title>
    <!-- {{"Description"}} -->
  </mat-card-title>
  <mat-dialog-content class="mat-typography">
    <div class="input-width mb-2">
      <label style="font-weight: 550 !important;font-size: 17px !important;"  class="">Description</label>
      <textarea name="" 
        matInput id="" 
        [(ngModel)]="data.description" 
        readonly
        placeholder="Enter description"
        class="form-control email-input select-placeholder"></textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button 
      [mat-dialog-close]="true" 

      class="searchBtn mb-2">OK</button>
  </mat-dialog-actions>
  <style>
    .consolidate-timesheet{
  font-family: 'Mulish', sans-serif;

  font-size: 14px;
}


.create-button {
 

  color: #fff;
  padding: 7px 12px 7px 12px;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.075px;
  height: 35px;
}


.searchBtn {
  background: #1a83ff;
  border-radius: 5px;
  color: #fff;
  padding: 7px 12px 7px 12px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  border: none;
  line-height: normal;
  letter-spacing: 0.075px;
  height: 35px;
  font-family: "Mulish", sans-serif;
  transition: transform 0.5s; /* Add transition for smooth animation */
}
.btn {
  background: #1a83ff;
  color: #fff;
  padding: 7px 12px 7px 12px;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.075px;
  height: 35px;
}

.alert-primary {
  color: #4870ff !important;
  background-color: #fff !important;
  border-color: #4870ff !important;
  margin-bottom: 0px !important;
  margin-top: auto;
  width: auto;
}
    .input-width{
      width: 100%;
    }
    #loginform{
    margin-bottom: 20px;
    max-height: 75px;
  }
  #loginform_Comments{
    margin-bottom: 20px;
  }
  .form-control-label {
    font-size: 15px;
    font-weight: 550;
    color: #747f99;
    margin-bottom: 10px;
  }
  .form-control{
    line-height: 2.0;
    padding:7px 15px 7px 15px;
  }
  .form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer;
    padding-left: 12px;
    padding-bottom: 6px;
  }

  .file-download{
    text-decoration: none;
    font-size: 13px;
  }
  .download-icon{
    position: relative;
    top: 5px;
    right: 5px;
    font-size: 20px;
  }
  .badge{
    color: black;
    font-weight: 500;
    padding: 0px 10px 5px 10px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.5);
  }
  /* .badge:hover{
    color: #212529;
    background-color: #d2e3ee;
    border-radius: 20px;
  } */
  /* .select-placeholder
  {
    width: 100% !important;
    height: 46px;
    padding:7px 12px 7px 12px;
    font-size: 16px;
  } */

  .select-placeholder {
    width: 100% !important;
    /* height: 38px; */
    padding: 7px 12px 7px 12px;
    font-size: 16px;
    line-height: 29px;
    color: black !important;
   border: 2px solid #dee2e6 !important;
}
  input::placeholder {
    color: black !important;
    opacity: 1;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
  }
  .mat-calendar-body-cell-content{
    background: #FFCC00 !important;
  }
  .mat-select-value{
    color: rgba(0,0,0,0);
    text-decoration: none !important;
  }
  .mat-select-value-text {
    color: #8898aa!important;
  }
  :host::ng-deep .mat-select-placeholder {
    color: #e0dcdc!important;
    opacity: 1;
  }
  :host::ng-deep .mat-tab-body-content {
    height: 100%;
    overflow: hidden !important;
  }
  :host::ng-deep .mat-tab-label-container{
    z-index: 0!important;
  }
  :host::ng-deep .mat-tab-body-wrapper {
    z-index: 0!important;
  }
  .text-color span{
    color: #8898aa!important;
  }
  .buttons{
    float: right;
  }
  .create-button{
    background: #4870ff!important;
    border-radius: 7px;
    color:#f8f9fa;
  }
  .btn{
    /* padding: 7px 12px 7px 12px; */
    border: 0px;
    border-radius: 7px;
    font-weight: 550;
    color:#f8f9fa;
  }
  /* .btn:hover{
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    font-weight: 550;
  } */
  .span-error{
    color: red;
    font-size: 75%;
  }
  .no-v-scroll{
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
  
  textarea::-webkit-input-placeholder {
    color: #e0dcdc;
  }
  
  textarea:-moz-placeholder { 
    color: #e0dcdc;  
  }
  
  textarea::-moz-placeholder { 
    color: #e0dcdc;  
  }
  
  textarea:-ms-input-placeholder {
    color: #e0dcdc;  
  }
  
  textarea::placeholder {
    color: #e0dcdc;  
  }
  .icon-size{
    font-size: 17px;
    top: 3px;
    position: relative;
  }

  .select-placeholder {
    width: 100% !important;
    /* height: 38px; */
    min-height: 38px;
    padding: 7px 12px 7px 12px;
    font-size: 16px;
    line-height: 29px;
    color: black !important; /* Light black color with alpha value */
    border: 2px solid #dee2e6 !important;
}


/* styles.css or your component's CSS file */
::ng-deep .mat-select-placeholder {
  color: black; /* Apply black color to placeholder text */
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-underline {
  background-color: black; /* Apply black color to underline when focused */
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-underline::after {
  border-color: black !important; /* Apply black color to underline */
}

.mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-top: 5px solid black; /* Change border-top color here */
  margin: 0 4px;
  color: #0a0a0a !important;
}
.form-control-label {
  font-size: 16px;
  font-weight: 550;

  color: black !important;
  margin-bottom: 3px;
}


textarea::placeholder {
  color: black !important;
}

.select-placeholder {
  width: 100% !important;
    /* height: 43px; */
    padding: 7px 12px 7px 12px;
    font-size: 16px;
    line-height: 29px;
    color: black !important; /* Light black color with alpha value */
    border: 2px solid #dee2e6 !important;
}

  mat-error{
    color: #dc3545!important;
    font-size: 13px!important;
  }
  </style>
  