import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PayrollService } from 'src/app/providers/payroll.service';
import { ActivatedRoute, Router } from '@angular/router';
import { responseMsgTimeOut , redirectMsgTimeOut  } from 'src/app/providers/properties';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {

  companySettingsForm:FormGroup;
  countryData:any;
  countrySingleData:any;

  submitted: boolean = false;
  
  countryInfo:any;
  employeetData:any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  update_data_error_msg:any;
  loader:any= false;
  backdrop:any= false;
  isFieldDisabled: boolean = true;

  countryId:any;
isbtnDisabled: boolean=false;

  constructor(  private payrollService:PayrollService,   
     private router : Router,
    private ActivatedRoute:ActivatedRoute,) { }

  ngOnInit(): void {

    this.countryInfo = this.listCountry();
    this.listCountry();
    this.patch();

    this.companySettingsForm = new FormGroup(
      {
        country: new FormControl(this.companySettingsForm,[Validators.required]), 
        countryCode : new FormControl(this.companySettingsForm),
        currencySymbol : new FormControl(this.companySettingsForm), 
        currencyCode : new FormControl(this.companySettingsForm), 
        decimalDigitValue: new FormControl(this.companySettingsForm,[Validators.required,Validators.pattern('^[0-5]$')]), 
        maxSalaryAdvanceLimit: new FormControl(this.companySettingsForm,[Validators.required]),
        maxLoanLimit: new FormControl(this.companySettingsForm,[Validators.required])
      }
    );
  }


  validateNumber(event: KeyboardEvent): void {
    const allowedNumbers = ['0', '1', '2', '3', '4', '5'];
    if (!allowedNumbers.includes(event.key)) {
      event.preventDefault();
    }
  }
  patch(){

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    // this.loader=true
    this.isbtnDisabled=true
    this.payrollService.companySettingsbyOrganizationId(organizationId).subscribe((result: any) => {
      
      console.log('result',result);
      this.countrySingleData = result;

      console.log('countrySingleData',this.countrySingleData);
      
      this.loader=false;
      this.isbtnDisabled=false;

      console.log(' this.countrySingleData',  this.countrySingleData)

      this.companySettingsForm.patchValue({
        // country : this.countrySingleData.country.countryName,
        countryCode : this.countrySingleData?.country?.countryCode,
        currencySymbol: this.countrySingleData?.country?.countrySymbol,
        currencyCode: this.countrySingleData?.country?.currencyCode,
        decimalDigitValue: this.countrySingleData?.decimalDigitValue,
        maxSalaryAdvanceLimit: this.countrySingleData?.maxSalaryAdvanceLimit,
        maxLoanLimit: this.countrySingleData?.maxLoanLimit,
      });
      console.log('this.companySettingsForm', this.companySettingsForm);

      this.companySettingsForm.get('country').patchValue(this.countrySingleData?.country?.countryId); 
    })
  }

  submitData(){

    console.log('submit')
    this.submitted = true;
    // this.isbtnDisabled=true;

    if(this.companySettingsForm.invalid){
          // this.isbtnDisabled=false;

      return;
    }

    this.error = false;
    this.success = false;

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.payrollService.companySettingsbyOrganizationId(organizationId).subscribe((result: any) => {
     
      var settingsupdate;
      console.log('this.countryId', this.countryId)
          settingsupdate =
            {
              organizationSettingId : result.organizationSettingId,
              country:{countryId:this.companySettingsForm.value.country},
              organization:{organizationId:organizationId},
              decimalDigitValue: this.companySettingsForm.value.decimalDigitValue,  
              maxSalaryAdvanceLimit: this.companySettingsForm.value.maxSalaryAdvanceLimit,  
              maxLoanLimit: this.companySettingsForm.value.maxLoanLimit,  
            };
            console.log('settingsupdate', settingsupdate)
            
            this.isbtnDisabled=true;

          this.payrollService.updateOrganizationSettings(settingsupdate,result.organizationSettingId).subscribe( (data:any) =>
          {
            this.isbtnDisabled=true
            if(data.statusCode == 200)
            {
              this.isbtnDisabled=true

              this.payrollService.companySettingsbyOrganizationId(organizationId).subscribe((result: any) => {
                this.countrySingleData = result;
                console.log(' this.countrySingleData',  this.countrySingleData)

                this.companySettingsForm.patchValue({
                  // country : this.countrySingleData.country.countryName,
                  countryCode : this.countrySingleData.country.countryCode,
                  currencySymbol: this.countrySingleData.country.countrySymbol,
                  currencyCode: this.countrySingleData.country.currencyCode,
                  decimalDigitValue: this.countrySingleData.decimalDigitValue,
                  maxSalaryAdvanceLimit: this.countrySingleData.maxSalaryAdvanceLimit,
                  maxLoanLimit: this.countrySingleData.maxLoanLimit,
                 
                });
                console.log('this.companySettingsForm', this.companySettingsForm);
          
                this.companySettingsForm.get('country').patchValue(this.countrySingleData.country.countryId); 
            })
      
              this.success = true;
              this.sucess_msg = data.description;
              console.log('this.sucess_msg', this.sucess_msg)
              
              setTimeout(() => {this.success  = false;
                // this.isFieldDisabled=false;
              this.router.navigate(['/home/settings']);
              },redirectMsgTimeOut)
            }
            else
            {
              this.error = true;
              this.isbtnDisabled=false

              this.error_msg = data.description;
              setTimeout(() => {this.error = false}, redirectMsgTimeOut)
            }
          },
          (err) =>
          {
            this.backdrop = false;
            this.loader = false;
            this.error = true;     
            this.update_data_error_msg = err.error.message;
            setTimeout(() => {this.error = false}, 3000)
          })
    })
  
  }

  listTypeValueid(event)
  {
    this.countryId = event.value;
    console.log('listtypevalue id', event.value)
    //   this.payrollService.companySettingsbyOrganizationId(organizationId).subscribe((result: any) => {
            //     this.countrySingleData = result;
            //     console.log(' this.countrySingleData',  this.countrySingleData)

            //     this.companySettingsForm.patchValue({
            //       // country : this.countrySingleData.country.countryName,
            //       countryCode : this.countrySingleData.country.countryCode,
            //       currencySymbol: this.countrySingleData.country.countrySymbol,
            //       currencyCode: this.countrySingleData.country.currencyCode,
            //       decimalDigitValue: this.countrySingleData.decimalDigitValue
                 
            //     });
            //     console.log('this.companySettingsForm', this.companySettingsForm);
          
            //     this.companySettingsForm.get('country').patchValue(this.countrySingleData.country.countryId); 
            // })
    this.listByCountryId(event.value)
    
  }
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
  
  blockDrop(event: DragEvent) {
    event.preventDefault();
  }
  
  noNumber(event: any) {
    // const inputValue = event.target.value;
  
  
    const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
  }

  listByCountryId(countryId)
  {
    console.log("list by cid", countryId)
    this.payrollService.listByCountryId(countryId).subscribe((result: any) => {
      this.countrySingleData = result;

                  this.companySettingsForm.patchValue({
                  // country : this.countrySingleData.country.countryName,
                  countryCode : this.countrySingleData.countryCode,
                  currencySymbol: this.countrySingleData.countrySymbol,
                  currencyCode: this.countrySingleData.currencyCode,
                  // decimalDigitValue: this.countrySingleData.decimalDigitValue
                 
                });
                console.log('this.companySettingsForm', this.companySettingsForm);
          
                // this.companySettingsForm.get('country').patchValue(this.countrySingleData.country.countryId); 
    })

   
    
  }

  listCountry()
  {
    this.payrollService.listCountry().subscribe((result: any) => {
      this.countryData = result;
    })
  }

  get country()
  {
    return this.companySettingsForm.get('country');
  }

  get currencySymbol()
  {
    return this.companySettingsForm.get('currencySymbol');
  } 
   get currencyCode()
  {
    return this.companySettingsForm.get('currencyCode');
  }
  get countryCode()
  {
    return this.companySettingsForm.get('countryCode');
  }
  get decimalDigitValue()
  {
    return this.companySettingsForm.get('decimalDigitValue');
  }
  get maxSalaryAdvanceLimit()
  {
    return this.companySettingsForm.get('maxSalaryAdvanceLimit');
  }  
  get maxLoanLimit()
  {
    return this.companySettingsForm.get('maxLoanLimit');
  }
}
