import { HttpClient } from '@angular/common/http';
import { Component, OnInit,Inject} from '@angular/core';
// import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { AppraisalComponent } '../appraisal/appraisal.component';
import { PmsService } from 'src/app/providers/pms.service';

@Component({
  selector: 'app-showapproverlist',
  templateUrl: './showapproverlist.component.html',
  styleUrls: ['./showapproverlist.component.scss']
})
export class ShowapproverlistComponent implements OnInit {
  app: any;
  appNew: any;
nodata: any;
loader:boolean=false;
  

  
  constructor(
    // public dialogRef: MatDialogRef<AppraisalComponent>,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private fb: FormBuilder,
    private dialog:MatDialog,
    private http: HttpClient,
    private pmsService:PmsService,

  ) { }

  ngOnInit(): void 
  {
    console.log("Data",this.data);
    // console.log(this.data.approvals);
    this.app=this.data;

    console.log(this.app);
    console.log(this.app.apprisalHeaderId);
    this.loader=true;
    // console.log(this.app.approvals.approver.approverOneObject);
    // console.log(this.app.approvals.approver.approverTwoObject);
    // console.log(this.app.approvals.approverOneStatus.listTypeValueName);
    // console.log(this.app.approvals.approverTwoStatus.listTypeValueName);

    this.pmsService.viewApprovalsDialog(this.app.apprisalHeaderId).subscribe((responseData) => {
      console.log("response data::",responseData);
      
      this.appNew = responseData;
      this.loader=false;
      // console.log("og",this.appNew);
      console.log(this.appNew.approver.approverOneObject);
      
      // console.log("wrqtywrty",this.appNew.approver.approverOneObject);
    });
    
  }
  
  approvalStatus(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#a68b00'
    }
    if (approvalStatusName == 'Approved') {
      return '#388e3c'
    }
    if (approvalStatusName == 'Denied') {
      return '#c62828'
    }
  }

  approvalBackground(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#fff0c2'
    }
    if (approvalStatusName == 'Approved') {
      return '#c8e6c9'
    }
    if (approvalStatusName == 'Denied') {
      return '#ffcdd2'
    }
  }
}
