import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { add } from 'timelite/time';
import { ShowErrorComponent } from '../show-error/show-error.component';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-task-updation',
  templateUrl: './task-updation.component.html',
  styleUrls: ['./task-updation.component.scss']
})
export class TaskUpdationComponent implements OnInit {
  @ViewChild('picker') startDatePicker!: MatDatepicker<any>;
  @ViewChild('p') endDatePicker!: MatDatepicker<any>;
  @ViewChild('allSelected') private allSelected: MatOption;
  taskForm:FormGroup;
  hoursData;
  loader:boolean = true;
  minutesData;
  endDate_disable:any;
  disableButton:boolean =  true;
  min:any;
  taskList:any;
  arrayData:any[] = []
  employeeList:any [] = []
  commonTaskId:any;
  submitted=false;
  taskgrouplist:any;
  storeorganizationId: any;
  success:boolean=false;
  sucess_msg:any;
  error:any=false;
  errorMsg: any;
error_msg: any;
importerror: boolean = false;
  errormessagejson: any;
  max: any;

  constructor( public dialogRef: MatDialogRef<TaskUpdationComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               private gs:ProjectManagementService,
               private fb:FormBuilder,
               private datepipe: DatePipe,

               private timesheet: TimesheetService,
               private dialog:MatDialog,
              ) { }

  ngOnInit(): void {


    console.log('data',this.data);

    console.log("start date::",this.data.project.projectStartDate);
    console.log("end date::",this.data.project.projectEndDate);
    
    
    this.min = this.data.project.projectStartDate.substring(0, 10);
    this.max =this.data.project.projectEndDate.substring(0,10);
    console.log(this.min);
    console.log(this.max);
    
    
    // this.startDate_disable = false;
    // this.disableTaskId = false;
    let control = this.taskForm?.get('tableRows') as FormArray;
    control?.controls?.forEach((element) => {
      element.patchValue({
        taskStartDate: '',
        taskEndDate: '',
      });
    });
    
    let x = JSON.parse(localStorage.getItem('enoteUserData'))

    console.log("x::",x);
    this.storeorganizationId=x.organization.organizationId
    console.log(" this.storeorganizationId", this.storeorganizationId);
    
    

    
    let a = this.data.workingHours;
    this.gs.getCommonTaskName().subscribe(
      (data)=>{
        this.taskList = data;
      }
    )

    this.gs.getprojectByID(this.data.project.projectId).subscribe(
      (x:any) =>{
        this.employeeList = x.employee
      }
    )

    // var b = a.split(':');
     this.taskForm = this.fb.group({
      hours:[''],
      // taskName:['',Validators.required],
      taskName: new FormControl(this.taskForm, [
        Validators.required,
        Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"),
      ]),
      // taskDescription:['',Validators.required],
      taskDescription: new FormControl(this.taskForm, [
        Validators.required,
        Validators.pattern("^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"),
      ]),
      minutes:[''],
      taskStartDate:['',Validators.required],
      taskEndDate:['',Validators.required],
      taskHours: this.fb.array([
      ]),
      taskGroup:['',Validators.required]
     })
     if(this.data.taskId){
        if(this.data.commonTasks != undefined)
        {
          this.commonTaskId = this.data.commonTasks.commontaskId;
        }

     }

     this.gs.gettaskgroup()
     .subscribe((data:any) =>{
        this.taskgrouplist = data;
        console.log(this.taskgrouplist);


     this.gs.getTask(this.data.taskId).subscribe(
       (data:any)=>{
         console.log(data)
         if(data.taskGroup != undefined){
          this.taskForm.patchValue({
            taskGroup :data.taskGroup.taskGroupId
         })
        }
         this.taskForm.patchValue({
          taskName:data.taskName,
          hours:data.workhours,
          taskDescription:data.taskDescription,
          minutes:data.workminutes,
          taskStartDate:data.taskStartDate,
          taskEndDate:data.taskEndDate,
         })
        
         data.taskHours.forEach((x) => {
          this.totalHours.push(this.fb.group(x))
        }); 
        this.loader = false;
        this.disableButton= true;
       }
     )
    })
  
  }

  get f(){
    return this.taskForm?.controls;
  }

  getHoursData(x){

  }

  get totalHours(): FormArray {
    return this.taskForm?.get('taskHours') as FormArray;
  }

  taskHours(){
    return this.fb.group({
      extrahours:['',Validators.required],
      extraminutes: ['',Validators.required],
      reason: ['',Validators.required],
      task:{
        taskId:this.data.taskId
      }
    })
  }
  
  addTaskHours() {
		let fg = this.taskHours();
		this.totalHours.push(fg);
	}

  deleteTaskHours(idx: number) {
		this.totalHours.removeAt(idx);
	}

  minutes() {
    return this.f.minutes
      .hasError('required')
      ?
      'Minutes is required' : ''
  }

  hours() {
    return this.f.hours
      .hasError('required')
      ?
      'Hour is required' : ''
  }

  // taskName() {
  //   return this.f.taskName
  //     .hasError('required')
  //     ?
  //     'TaskName is required' : ''
  // }
  // taskDescription(){
  //   return this.f.taskDescription
  //   .hasError('required')
  //   ?
  //   'Task Detail Description is required' : ''
  // }

  // get f() { return this.taskForm.controls; }

  get taskStartDate() {
    return this.taskForm.get('taskStartDate');

    // return this.f.taskStartDate.hasError('required') ? 'Please Select Start Date' : '';
  }

  get taskEndDate() {
    return this.taskForm.get('taskEndDate');

    // return this.f.taskEndDate.hasError('required') ? 'Please Select End Date' : '';
  }
 
  EmployeeName() {
    return this.f.employee.hasError('required') ? 'Please Select Employee Name*' :
      ''
  }

  // TaskGroup() {
  //   return this.f.taskGroup.hasError('required') ? 'Please Select Task Group' :
  //     ''
  // }
  get taskDescription() {
    return this.taskForm?.get('taskDescription');
  }
  get TaskGroup() {
    return this.taskForm?.get('TaskGroup');
  }
  get taskName() {
    return this.taskForm?.get('taskName');
  }

  tosslePerOne(all){
    if (this.allSelected.selected) {
     this.allSelected.deselect();
     return false;
 }
   if(this.taskForm.controls.employee.value.length==this.employeeList.length)
     this.allSelected.select();
 }
   toggleAllSelection() {
     if (this.allSelected.selected) {
       this.taskForm.controls.employee
         .patchValue([...this.employeeList.map(item => item.employeeId), 0]);
     } else {
       this.taskForm.controls.employee.patchValue([]);
     }
   }

  status(){
    return this.f.taskStatus
    .hasError('required')
    ?
    'Task Status is required' : ''
  }

  showerrormessage()
  {
    console.log(this.errormessagejson);
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

    // keys
    noNumber(event: any) {
      const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }


    // date validation
    someMethodName(date:any) {
      this.submitted = true;
      if (this.taskForm.invalid) {
        return; 
      }
      // this.min = new Date(date.value)
      // this.endDate_disable = false
      const startDate = this.taskForm.get('taskStartDate').value;
      const endDate = this.taskForm.get('taskEndDate').value;
      
      
    
      
      const startdatestore = this.taskForm.controls['taskStartDate'].value;
      const enddatestore = this.taskForm.controls['taskEndDate'].value;
      if (startdatestore && enddatestore) {
       
      
        // Ensure dates are valid before creating Date objects
        let startDate: Date | string = startdatestore ? new Date(startdatestore) : new Date();
        let endDate: Date | string = enddatestore ? new Date(enddatestore) : new Date();
      
        // Transform the dates
        startDate = this.datepipe.transform(startdatestore, 'yyyy-MM-dd');
        endDate = this.datepipe.transform(enddatestore, 'yyyy-MM-dd');
      
        console.log('2dates', startDate, endDate);
      
        // Convert transformed dates back to Date objects for comparison
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
      
        if (startDateObj > endDateObj) {
          alert('Start date cannot be greater than end date.');
          this.taskForm.patchValue({
            taskStartDate: null,
            taskEndDate: null,
          });
          if (this.taskForm.controls.taskStartDate.errors?.required && !this.taskForm.controls.taskStartDate.touched) {
            this.startDatePicker.close()
         this.endDatePicker.close()
            return; // If not touched and empty, do not show error
          }
      
          if (this.taskForm.controls.taskEndDate.errors?.required && !this.taskForm.controls.taskEndDate.touched) {
            this.startDatePicker.close()
            this.endDatePicker.close()
            return; // If not touched and empty, do not show error
          }
      
          if (this.taskForm.invalid) {
            this.startDatePicker.close()
         this.endDatePicker.close()
            return; // If form is invalid, don't proceed further
          }
          console.log("controls::",this.taskForm);

          // this.submitted=false
    
         this.startDatePicker.close()
         this.endDatePicker.close()
        }
        } else {
          // Additional logic if needed
        }
      }
      
      
    // }
    // checkStartDate(): void {
      // const startDate = this.taskForm.get('taskStartDate').value;
      // const endDate = this.taskForm.get('taskEndDate').value;
  
      // if (startDate && endDate && startDate > endDate) {
      //   alert('Start date cannot be greater than end date.');
  
      //   this.taskForm.patchValue({
      //     taskStartDate: null,
      //     taskEndDate: null,
      //   });
  
      //   this.startDatePicker.close();
      //   this.endDatePicker.close();
      // }
  
      // }
    

  onClick(){
    this.dialogRef.close('cancel');
  }

  change(id){
    this.commonTaskId = id
  }
  onSubmit(){
    console.log("controls::",this.taskForm.value);
    this.disableButton =  false;
    this.submitted=true;
    if(this.taskForm.invalid){
      return;
    }
    let z = this.taskForm.value;
    console.log(z)


    if(z.taskStartDate){
      //start date
      let a = z.taskStartDate;
      let i = new Date(a);
      i.setMinutes(i.getMinutes() + 480);
      let j = i.toISOString()
      z['taskStartDate'] = j
    }
    if(z.taskEndDate){
    //end date
    let b = z.taskEndDate;
    let y = new Date(b);
    y.setMinutes(y.getMinutes() + 480);
    let t = y.toISOString()
    z['taskEndDate'] = t
    }

    if(z.hours == 0 && z.minutes == 0){
      alert('Task Hours Should be greater than 0')
      return
    }

    let hour = 0
    let minute = 0
    if (z.taskHours != '') {

      hour = z.taskHours.map(a => a.extrahours).reduce(function (a, b) {
        return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
      })
      minute = z.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
        return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
      })
      if (minute >= 60) {
        // Divide minutes by 60 and add result to hours
        hour += Math.floor(minute / 60);
        // Add remainder of totalM / 60 to minutes
        minute = minute % 60;
      }
      let d: any = `${('0' + z.hours).slice(-2)}:${('0' + z.minutes).slice(-2)}`
      let e: any = `${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}`
      let j = add([d,e])
      if(j[0] > 99){
        alert("Working Hours Should be less than 100")
        return
      }
    }

    // let x = {
    //   taskId:this.data.taskId,
    //   organization: this.data.organization,
    //   project: {
    //     projectId: this.data.project.projectId
    //   },
    //   taskStartDate:z.taskStartDate,
    //   taskEndDate:z.taskEndDate,
    //   taskName: this.capitalizeWordsName(z.taskName),
    //   taskDescription: this.capitalizeWordsDescription(z.taskDescription),
    //   workhours: z.hours,
    //   workminutes: z.minutes,
    //   taskDetail: [],
    //   taskStatus:this.data.taskStatus,
    //   taskHours:z.taskHours,
    //   taskGroup :{
    //     taskGroupId:z.taskGroup
    //   }

    // }
    let x = {
      taskId: this.data.taskId,
      taskName: this.capitalizeWordsName(z.taskName),
      taskStartDate: z.taskStartDate,
      taskEndDate: z.taskEndDate,
      taskDescription: this.capitalizeWordsDescription(z.taskDescription),
      // allotedWorkingHours: 8,
      // totalWorkingHours: '00:01:00:00',
      // taskProgress: 0,
      status: {
        listTypeValueId: this.data?.project.status?.listTypeValueId,
      },
      // status:{
      //   listTypeValueId:1
      //   },
        // status:this.data.status.listTypeValueId
      
          
      taskGroup: {
        taskGroupId: z.taskGroup,
      },
      project: {
        projectId: this.data.project.projectId,
      },
      organizationId:this.storeorganizationId,
      taskStatus: {
        listTypeValueId: this.data.taskStatus.listTypeValueId,
      },
      // commonTasks: {
      //   commontaskId: 1,
      // },
      workhours: z.hours,
      workminutes: z.minutes,
      // empolyeeId: 8010,
      // taskCompletionPercentage: 0,
    };

     if(this.data.commonTasks != undefined)
     {
      x['commonTasks'] =  {
        commontaskId: this.commonTaskId
        }
     }
   
    // console.log(x)
    // return
    this.dialogRef.close(x);
  }

  capitalizeWordsName(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
  
  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

}
