import { Component, Inject, OnInit, ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Validators } from '@angular/forms';
import { DatePipe} from '@angular/common';
import { LeaveService } from 'src/app/providers/leave.service';
import { HeaderService } from 'src/app/providers/header.service';
import { EMPTY, Subject, catchError, takeUntil } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';

import * as htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-workfromhome-report',
  templateUrl: './workfromhome-report.component.html',
  styleUrls: ['./workfromhome-report.component.scss']
})
export class WorkfromhomeReportComponent implements OnInit {
  @ViewChild('picker1') startDatePicker!: MatDatepicker<any>;
  @ViewChild('p1') endDatePicker!: MatDatepicker<any>;
  @ViewChild('picker') startDatePicker1!: MatDatepicker<any>;
  @ViewChild('p') endDatePicker2!: MatDatepicker<any>;
  list_count = 0; 
  noData=false;
  data: any[] = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // displayedColumns =
  //   ['employeeNumber', 'employeeName', 'organizationEmail', 'contact', 'startDate', 'endDate', 'noOfLeaveDays', 'leaveStatus'];
    displayedColumns =
    ['employeeNumber','employeeName', 'organizationEmail','startDate', 'endDate','noOfLeaveDays','leaveStatus'];
  dataS: any;
  loader=true;
  leaveReport:FormGroup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  startdate: any;
  enddate: string; 
  submitted: boolean = false;
  min: string;
  show_EMsg:boolean = false;
  startdate_required:boolean = false;
  enddate_required:boolean = false;
  max: string;
  employeelist: any;
  employeeId: any;
  orgId:any;
  all_null:boolean = false;
  organizationId: any;
  orgAddress:any;
  organizationName:any;
  employeeNumber: any;
  empid: any;
  employee: string;
  employeeNotFound: boolean;
  employeeList: any[];
  unsubscribe$: any;
  selectedemployeeId: any;
  selectedemployee: any;
  isProcessing = false;
  storeemployee: any;
  storeStartDate: any;
  storeEndtDate: any;
  dataSource = new MatTableDataSource<any>();
  pagedData = new MatTableDataSource<any>();
  p: number = 1; // Current page number
  pagePerItem: number = 5; // Items per page
  mySelect: number = 5; // Default selected items per page from select box
  beforepage:any;
searchs: any;
showTable:boolean=false;
paginators: boolean = true;
  beforesearchpage: number;

  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
  x:any;

  constructor(private gs: GlobalserviceService, 
    private router: Router,
    private fb: FormBuilder,
    public datepipe:DatePipe,
    public leaveservice:LeaveService,
  private headerservice: HeaderService) {
  }
  ngOnInit() 
  {
    // this.headerservice.setTitle('Work from Home')
    this.headerservice.setTitle('');

    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(x);
    let mail = x.emailId;
    this.organizationId = x.organization.organizationId;
    this.empid = x.employee.employeeId;


    // this.gs.activeemployeelist().subscribe(
    //   (d:any)=>{
    //     console.log(d);
    //     this.employeelist = d;
    //   })

    this.leaveReport = this.fb.group({
      startdate:[''],
      enddate:[''],
      employeeId:[''],
    });
     
     console.log(x.organization.organizationId);
     this.orgId=x.organization.organizationId;
     console.log(this.orgId);

     this.orgAddress = x.organization.address;
      console.log(this.orgAddress);

      this.organizationName = x.organization.organizationName;
      console.log(this.organizationName);

     this.loader=false;
    // this.gs.getEmpLEaveList(mail).subscribe(
      // this.leaveservice.getWFHlistByOrg(this.orgId).subscribe(
      // (d: any) => {
      //   this.loader=false;
      //   if(d.length==0)
      //   {
      //     this.noData =true;
      //     console.log("NoData");
      //   }

      //   this.data = d
      //   this.dataS = new MatTableDataSource<any>(this.data);
      //   console.log(this.dataS);
      //   this.dataS.paginator = this.paginator;
      //   this.dataS.filterPredicate = (data, filter) => {
      //     const dataStr = data.employee.firstName+data.employee.lastName + data.employee.organizationEmail
      //     + data.employee.employeeNumber + data.employee.mobileNumber
      //     +data.startDate + data.endDate + data.noOfDays + data.workFromHomeStatusName;
      //     return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      //   }
      //   this.list_count = this.data.length;
      // },
      // (error) =>
      // {
      //   this.loader=false;
      //   this.noData= true;
      //   console.log("error");
      //   if(error.statusCode==204)
      //   {
      //      this.noData=true;
      //      console.log("204");
      //   }
      // }
      // )
    // this.leaveservice.getEmpLEaveList(this.empid).subscribe(d => { })
  }
  showChanges() {
    // Handle items per page change from select box
    this.pagePerItem = this.mySelect;
    this.p=1;
    console.log((this.p));
    console.log(this.mySelect );
    this.updateTableData();
 
  }
  onPageChange(event: any) {
    this.p = event; // Update current page on page change
    this.updateTableData();
  }

  updateTableData() {
    const start = (this.p - 1) * this.mySelect;
    const end = start + this.mySelect;
    this.dataSource = new MatTableDataSource<any>(this.data.slice(start, end));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  startDate(event){
    this.startdate_required=false;
    console.log("start Date:"+event.value);
    this.show_EMsg = false;
    this.min = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    if(this.max != null){
      if(this.max < this.min){
        this.show_EMsg = true;
      }
    }
  }
  EndDate(event){
    this.enddate_required=false;
    this.show_EMsg = false;
    console.log("start Date:"+event.value);
    this.max = this.datepipe.transform(event.value, 'yyyy-MM-dd');
  }
  isselected(event){
    // console.log(event);
    // this.isDisabled = true;
    // this.submitted=false;
  }
  empSelect(emp){
    console.log(emp);
    this.employeeId=emp.employeeId;
    this.employee=emp.employeeNumber+"-"+emp.firstName;
    this.employeeNumber = emp.employeeNumber;
      this.leaveReport.patchValue({ 
        employeeId: this.employee 
      });
  }

  search(){
    this.storeemployee=this.leaveReport.controls['employeeId'].value
    this.storeStartDate=this.leaveReport.controls['startdate'].value
    this.storeEndtDate=this.leaveReport.controls['enddate'].value
    if((this.storeemployee ==undefined || this.storeemployee =='') &&(this.storeEndtDate ==undefined || this.storeEndtDate =='')  &&(this.storeStartDate ==undefined || this.storeStartDate =='')  ){
      alert("Please Choose Any Field To Search")
      return;
    }
    this.submitted = true;
    this.changefirstpage();

    console.log(this.leaveReport.value);
    if(this.leaveReport.invalid){
      return;
    }
    this.startdate = this.datepipe.transform(this.leaveReport.value.startdate,'yyyy-MM-dd');
    console.log(this.startdate);
    this.enddate = this.datepipe.transform(this.leaveReport.value.enddate,'yyyy-MM-dd');
    console.log(this.enddate);
    // this.employeeId = this.leaveReport.value.employeeId;
    console.log(this.employeeId);

    // if(this.startdate == null && this.enddate == null){
    //   if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
    //     this.all_null=true;
    //     setTimeout(() => {
    //       this.all_null = false;
    //     }, 2000)
    //     return
    //   }
    // }

    if (!this.employee && (this.leaveReport.value.employeeId !== undefined && this.leaveReport.value.employeeId !== null)) {
      if (this.leaveReport.value.employeeId.length !== 0) {
          this.all_null = true;
          return;
      } else {
          this.all_null = false;
      }
  }
    if(this.startdate!=null && this.enddate == null){
      this.enddate_required = true;
      return
    }
    else if(this.enddate !=null && this.startdate == null){
      this.startdate_required = true;
      return
    }
    else{
      this.enddate_required=false;
      this.startdate_required=false;
    }
    this.loader=true;
    this.isProcessing = true; // Enable buttons
   this.data=[];
    if(this.startdate != null && this.enddate != null){
      console.log(this.employeeId=='');
      if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
        this.leaveservice.getWFHfilters(this.startdate,this.enddate,this.orgId).subscribe(
          (d:any) => {
            console.log(d);
            this.loader=false;
            this.showTable=true;

            this.noData =false;
            if(d.length==0)
            {
              this.noData = true;
              console.log("NoData");
            }
            if(d.statusCode == 204){
              this.noData = true;
              console.log("NoData");
            }else{

              this.data = d
              this.dataS = new MatTableDataSource<any>(d);
              this.dataS.paginator = this.paginator;
              // this.dataS.filterPredicate = (data, filter) => {
              //   const dataStr = data.employee.employeeName + data.employee.organizationEmail
              //   + data.employee.employeeNumber + data.employee.mobileNumber
              //   +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
              //   return dataStr.trim().toLowerCase().indexOf(filter) != -1;
              // }
              this.dataS.filterPredicate = (data, filter) => {
                const formatDate = (dateStr) => {
                  if (!dateStr) return '';
                  const date = new Date(dateStr);
                  if (isNaN(date.getTime())) return dateStr; 
                  return date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
                };
              
                const formattedStartDate = formatDate(data.startDate);
                const formattedEndDate = formatDate(data.endDate);
              
                const dataStr = (
                  data.employee.firstName + ' ' +
                  data.employee.lastName + ' ' +
                  data.employee.organizationEmail + ' ' +
                  data.employee.employeeNumber + ' ' +
                  formattedStartDate + ' ' +
                  formattedEndDate + ' ' +
                  data.noOfDays + ' ' +
                  data.workFromHomeStatusName
                ).toLowerCase().trim();
              
                const filterStr = filter.toLowerCase().trim();
              
                const normalizedFilterStr = filterStr.replace(/\s+/g, ' ');
              
                return dataStr.indexOf(normalizedFilterStr) !== -1;
              }; 
            }
            
            this.list_count = this.data.length;
            this.isProcessing = false; // Enable buttons

          },
          (error) =>
          {
            this.loader=false;
            this.noData= true;
            console.log("error");
            if(error.statusCode==204)
            {
               this.noData=true;
               console.log("204");
            }
            this.isProcessing = false; // Enable buttons

          }
          )
      }
      else{
        this.leaveReport.patchValue({ 
          employeeId: this.employee 
        });
        this.employeeNotFound = false;
        this.all_null = false;


        this.leaveservice.WFHBystartdateenddateempId(this.startdate,this.enddate,this.employeeId).subscribe(
          (d:any) => {
            console.log(d);
            this.loader=false;
            this.showTable=true;

            this.noData =false;
            if(d.length==0)
            {
              this.noData = true;
              console.log("NoData");
            }
            if(d.statusCode == 204){
              this.noData = true;
              console.log("NoData");
            }else{
              this.data = d
            this.dataS = new MatTableDataSource<any>(d);
            this.dataS.paginator = this.paginator;
            this.dataS.filterPredicate = (data, filter) => {
              const formatDate = (dateStr) => {
                if (!dateStr) return '';
                const date = new Date(dateStr);
                if (isNaN(date.getTime())) return dateStr; 
                return date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
              };
            
              const formattedStartDate = formatDate(data.startDate);
              const formattedEndDate = formatDate(data.endDate);
            
              const dataStr = (
                data.employee.firstName + ' ' +
                data.employee.lastName + ' ' +
                data.employee.organizationEmail + ' ' +
                data.employee.employeeNumber + ' ' +
                formattedStartDate + ' ' +
                formattedEndDate + ' ' +
                data.noOfDays + ' ' +
                data.workFromHomeStatusName
              ).toLowerCase().trim();
            
              const filterStr = filter.toLowerCase().trim();
            
              const normalizedFilterStr = filterStr.replace(/\s+/g, ' ');
            
              return dataStr.indexOf(normalizedFilterStr) !== -1;
            };
            }
            
            this.list_count = this.data.length;
            this.isProcessing = false; // Enable buttons

          },
          (error) =>
          {
            this.loader=false;
            this.noData= true;
            console.log("error");
            if(error.statusCode==204)
            {
               this.noData=true;
               console.log("204");
            }
            this.isProcessing = false; // Enable buttons

          }
          )
      }
    }
    else {
      this.leaveReport.patchValue({ 
        employeeId: this.employee 
      });
      this.employeeNotFound = false;
      this.all_null = false;


      this.leaveservice.WFHByEmplId(this.employeeId).subscribe(
        (d:any) => {
          console.log(d);
          this.loader=false;
          this.showTable=true;

          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }else{
            this.data = d
            this.dataS = new MatTableDataSource<any>(d);
            this.dataS.paginator = this.paginator;
            this.dataS.filterPredicate = (data, filter) => {
              const formatDate = (dateStr) => {
                if (!dateStr) return '';
                const date = new Date(dateStr);
                if (isNaN(date.getTime())) return dateStr; 
                return date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
              };
            
              const formattedStartDate = formatDate(data.startDate);
              const formattedEndDate = formatDate(data.endDate);
            
              const dataStr = (
                data.employee.firstName + ' ' +
                data.employee.lastName + ' ' +
                data.employee.organizationEmail + ' ' +
                data.employee.employeeNumber + ' ' +
                formattedStartDate + ' ' +
                formattedEndDate + ' ' +
                data.noOfDays + ' ' +
                data.workFromHomeStatusName
              ).toLowerCase().trim();
            
              const filterStr = filter.toLowerCase().trim();
            
              const normalizedFilterStr = filterStr.replace(/\s+/g, ' ');
            
              return dataStr.indexOf(normalizedFilterStr) !== -1;
            };
          }
         
          this.list_count = this.data.length;
          this.isProcessing = false; // Enable buttons

        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
            this.noData=true;
            console.log("204");
          }
          this.isProcessing = false; // Enable buttons

        }
        )
    }

  }
  Clear(){
    this.data=[];
    this.isProcessing=true;
    this.showTable=false;

    this.employeeList = [];
    this.employeeNotFound = false;
    this.noData=false;
    this.enddate_required = false;
    this.show_EMsg = false;
    this.startdate_required = false;
    this.all_null=false;
    this.leaveReport.reset();
    this.submitted = false;
    this.employeeId = null;
    this.employeeNumber = null;
    this.min = null;
    this.max = null;
    this.list_count=0;
    this.isProcessing=false;
    this.employee= undefined;
    this.leaveReport.patchValue({ 
      employeeId: undefined
    });
    // this.loader=true;
    // this.leaveservice.getWFHlistByOrg(this.orgId).subscribe(
    //   (d: any) => {
    //     this.loader=false;
    //     if(d.length==0)
    //     {
    //       this.noData =true;
    //       console.log("NoData");
    //     }

    //     this.data = d
    //     this.dataS = new MatTableDataSource<any>(d);
    //     console.log(this.dataS);
    //     this.dataS.paginator = this.paginator;
    //     this.dataS.filterPredicate = (data, filter) => {
    //       const dataStr = data.employee.employeeName + data.employee.organizationEmail
    //       + data.employee.employeeNumber + data.employee.mobileNumber
    //       +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
    //       return dataStr.trim().toLowerCase().indexOf(filter) != -1;
    //     }
    //     this.list_count = this.data.length;
    //     this.isProcessing=false;

    //   },
    //   (error) =>
    //   {
    //     this.loader=false;
    //     this.noData= true;
    //     console.log("error");
    //     if(error.statusCode==204)
    //     {
    //        this.noData=true;
    //        console.log("204");
    //     }
    //     this.isProcessing=false;

    //   }
    //   )
  }
  // keyFunc(event: Event) {
  //   const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
  //   let inputValue = inputElement.value;
  //   this.leaveservice.getEmployeeListKeywords(this.organizationId,inputValue).subscribe(
  //   (resultData: any) =>{
  //     this.employeelist= resultData;
  //   })
  // }

  keyFunc(event: Event, event1: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement; // Cast the event target as an input element
    let inputValue = inputElement.value.trim(); // Trim any leading or trailing whitespace

    if (event1.key === ' ') {
        // Handle the space key press
        console.log('Space key pressed');
        // Optionally, prevent the default behavior
        event1.preventDefault();
    } else {
        // Clear the employee list if the input is empty
        if (inputValue === '') {
            this.employeeList = [];
            this.employeeNotFound = false;
            this.all_null = false;


            console.log('Input is empty, employee list cleared');
            return;
        }

        // Cancel the previous API request if there's any
        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
        this.unsubscribe$ = new Subject<void>();

        this.leaveservice.getEmployeeListKeywords(this.organizationId, inputValue).pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            catchError((err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
                return EMPTY; // Return EMPTY to propagate completion downstream
            })
        ).subscribe(
            (resultData: any) => {
                if (resultData.statusCode === 204) {
                    console.log("No employees found (status code 204)");
                    this.employeeList = [];
                    this.employeeNotFound = true;
                } else {
                    if (Array.isArray(resultData)) {
                        this.employeeList = resultData;
                    } else if (typeof resultData === 'object') {
                        this.employeeList = Object.values(resultData);
                    } else {
                        console.error('Unexpected data type:', resultData);
                    }
                    this.employeeNotFound = false;
                }
            },
            (err) => {
                if (err.error.status === 500) {
                    this.employeeList = [];
                    this.employeeNotFound = true;
                }
                console.error('An error occurred:', err);
            }
        );
    }
}


  noEmployeeFound() {
      this.selectedemployeeId = undefined;
      this.selectedemployeeId = undefined; // Set selectedemployeeId to null
      this.employeeId = undefined;
      this.employeeList.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.selectedemployeeId = undefined;
      this.employeeList = []; // Clear the employee list
      this.employeeNotFound = false; 
      this.all_null = false;

    }
    preventSpaceBeforeInput(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
          event.preventDefault();
      }
    }
    handleBackspace(event): void {
      if (
        event.key === 'Backspace' &&
        !this.selectedemployee &&
        (this.selectedemployeeId === undefined || this.selectedemployeeId.trim().length === 0)
      ) {
        this.selectedemployeeId = undefined;
        this.employeeId = undefined;
        this.employeeList.length = 0;
        this.employee = undefined;
        this.employeeNumber = undefined;
        this.selectedemployee = undefined;
        this.selectedemployeeId = undefined;
      }
    }
  empValue(e){
    this.employeeNumber = e;
   }
  // applyFilter(event: Event) {

  //   const filterValue = (event.target as HTMLInputElement).value;
  //   console.log(filterValue);
  //   console.log("Search");
  //   if(!isNaN(Date.parse(filterValue)))
  //   {
  //      console.log("date");
  //      const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
  //      console.log(searchDate);
  //      this.dataS.filter = searchDate .trim().toLowerCase();
  //      this.list_count = this.dataS.filteredData.length;
  //      if(this.list_count ==0){
  //        this.noData = true;
  //      }
  //      else{
  //        this.noData = false;
  //      }
  //      if (this.dataS.paginator) {
  //       this.dataS.paginator.firstPage();
  //     }
  //   }
  //   else
  //   {
  //     console.log("Number");
  //     this.dataS.filter = filterValue .trim().toLowerCase();
  //     this.list_count = this.dataS.filteredData.length;
  //     if(this.list_count ==0){
  //       this.noData = true;
  //     }
  //     else{
  //       this.noData = false;
  //     }
  //     // this.dataSource.filter = filterValue .trim().toLowerCase();
  //     if (this.dataS.paginator) {
  //       this.dataS.paginator.firstPage();
  //     }
  //   }
  // }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataS.filter = filterValue.trim().toLowerCase();
    this.list_count = this.dataS.filteredData.length;
    if(this.list_count ==0){
      this.noData = true;
    }
    else{
      this.noData = false;
    }
    if (this.dataS.paginator) {
      this.dataS.paginator.firstPage();
    }
  }

  test(i) {
    if (i.workFromHomeStatusName == null) {
      return
    }
    if (i.workFromHomeStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.workFromHomeStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.workFromHomeStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.workFromHomeStatusName == 'Withdrawn') {
      return '#20B2AA'
    }    
    if (i.workFromHomeStatusName == 'Cancelled') {
      return '#e40000'
    }
  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (value.startsWith(' ')) {
      value = value.trimStart();
      textarea.value = value;
    }
  }
  validateInputs(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
  
    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }
  
    // Remove spaces in between
    value = value.replace(/\s+/g, '');
  
    input.value = value;
  }

  checkStartDate(): void {
    const startDate = this.leaveReport.get('startdate').value;
    const endDate = this.leaveReport.get('enddate').value;
    let startDate1 = this.datepipe.transform(startDate, 'YYYY-MM-dd');
    let endDate1 = this.datepipe.transform(endDate, 'YYYY-MM-dd');
    if (startDate && endDate && startDate1 > endDate1) {
      alert('Start date cannot be greater than end date.');

      this.leaveReport.patchValue({
        startdate: null,
        enddate: null,
      });

      this.startDatePicker.close();
      this.endDatePicker.close();
    }

  
  }
  changepage() {
    if (this.searchs.length !== 0) {
      this.p = 1;
      this.paginators = false;
    } else {
      this.p = this.beforesearchpage;
      this.paginators = true;
    }
  }
  changefirstpage() {
    this.p = 1;
  }

  printPdf() {
    const pdfTable = this.pdfTable.nativeElement;
    console.log(pdfTable.innerHTML);
    
  
    var html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    console.log(pdfTable.innerHTML);
    
  
    const documentDefinition = {
      content: html,
      pageMargins: [25, 40, 25, 40],
      info: {
        title: `Work From Home Detail Report`
      },
      background: function (currentPage, pageCount) {
        console.log('currentPage:', currentPage);
        console.log('pageCount:', pageCount);
  
        return {
          text: 'Page ' + currentPage.toString(),
          alignment: 'center',
          margin: [0, 8],
          fontSize: 10,
        };
      },
      pageSize: 'A4',
      pageOrientation: 'landscape'
    };
    console.log(pdfTable);
    pdfMake.createPdf(documentDefinition).download("Work From Home Detail Report");
  }
}

