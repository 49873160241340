import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecruitmentService } from '../../providers/recruitment.service';
import { ClientComponent} from '../client/client.component';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../../providers/properties';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { ExcelService} from '../../services/excel.service';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  [x: string]: any;

  clientlistdata:any[];
  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  usersId:any;
  employeeId:any;

  list_loader = true;
  nodata = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  exportingdata:any;
  exported:any;
  ClientName:any;
  Location:any;
  Description:any;
  dataExport = [];
  loader:any= false;
  backdrop:any= false;
  noRecordsFound :true;
  searchForm!:FormGroup;
  sortedData: any[];
  searchClient:boolean = true;
  constructor(private router:Router,
  private recruitmentservice:RecruitmentService,
  private dialog:MatDialog,
  public ExcelService:ExcelService) { }

  resultData : any;
  pagePerItem:any=5;
  tableSearch : any;
  nodatafound: boolean = true;
  organizationId;
  showtable :any = false;

  ngOnInit(): void
  {
    //this.clientList();
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    this.userId = this.usertData.userId;
     this.organizationId = this.usertData.organization.organizationId;
    this.getFilterFormData();
  }

  getFilterFormData()
  {
    this.searchForm = new FormGroup(
      {
        keywords : new FormControl(this.searchForm)
      }
    );
  }
  changefirstpage() {
    this.p = 1;
  }


  searchSubmit()
  {
    let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;

    if(keywords == null)
    {
      alert('Please search Client Name or Client Code');
      return;
    }

    if(keywords)
    {
      this.loader=true;
      this.backdrop=true;
      this.showtable=false;

      this.recruitmentservice.getClientFilterList(keywords , this.organizationId).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;
        this.showtable = true;
        this.resultData = resultData;
        this.searchClient = true;
        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
  }


  // EXPORT START
  exportToExcel()
  {
    console.log("DATA : ",this.resultData);
   if(this.searchForm.value.keywords !=  null)
    {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData ;

      for(let exportingdata of this.exportingdata)
      {
        this.exported ={
          ClientName       : exportingdata.clientName,
          Location     : exportingdata.location,
          Description  : exportingdata.clientDescription,
        }
        this.dataExport.push(this.exported);
      }
      this.ExcelService.exportAsExcelFile(this.dataExport,"Export");
    }
    else
    {
      this.dataExport.length = 0;
      this.exportingdata = this.resultData ;
      for(let exportingdata of this.exportingdata)
      {
        this.exported ={
          ClientName       : exportingdata.clientName,
          Location     : exportingdata.location,
          Description  : exportingdata.clientDescription,
        }
        this.dataExport.push(this.exported);
      }
      console.log("EX",this.exported);
      this.ExcelService.exportAsExcelFile(this.dataExport,"Export");
    }
  }
  // EXPORT END

  clientList()
  {
    let keywords = this.searchForm.value.keywords;

    if(keywords)
    {
      this.loader=true;
      this.backdrop=true;
      // this.showtable=true

      this.recruitmentservice.getClientFilterList(keywords, this.organizationId).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.showtable=true
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
    else
    {
      this.loader = true;
      this.backdrop = true;
      // this.showtable=true

      this.recruitmentservice.clientlist().subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;
        this.showtable=true

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
  }

  Add_client()
  {
    // var dialogbox = this.dialog.open(ClientComponent, {
    //   width: '500px',height:'fit-content',
    //   //width: '500px',height:'515px',
    //   //data:""
    // });
  this.router.navigateByUrl("/home/add-client");
    // dialogbox.afterClosed().subscribe(result =>
    // {
    //   //this.clientList();

    //   /* if(result.statusCode == 200)
    //   {
    //     this.success = true;
    //     this.sucess_msg = result.message;
    //     setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
    //   }
    //   else if(result.statusCode > 400)
    //   {
    //     this.error = true;
    //     this.error_msg = result.message;
    //     setTimeout(() => {this.error = false}, redirectMsgTimeOut)
    //   } */

    //   if(result.statusCode == 200)
    //   {
    //     this.success = true;
    //     this.sucess_msg = result.message;
    //     setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
    //   }
    //   else if(result.statusCode > 400)
    //   {
    //     this.error = true;
    //     this.error_msg = result.message;
    //     setTimeout(() => {this.error = false}, redirectMsgTimeOut)
    //   }
    // });

  }

  updateform(client)
  {

    //  var dialogupdatebox = this.dialog.open(ClientComponent, {
    //   width: '500px',height:'fit-content',
    //   data:client
    // });
    this.router.navigateByUrl(`/home/edit-client/${client.clientId}`);
      // dialogupdatebox.afterClosed().subscribe(result =>
      // {
      //   this.clientList();

      //   /* if(result.statusCode == 200)
      //   {
      //     this.success = true;
      //     this.sucess_msg = result.message;
      //     setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      //   }
      //   else if(result.statusCode > 400)
      //   {
      //     this.error = true;
      //     this.error_msg = result.message;
      //     setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      //   } */

      //   if(result.statusCode == 200)
      //   {
      //     this.success = true;
      //     this.sucess_msg = result.message;
      //     setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      //   }
      //   else if(result.statusCode > 400)
      //   {
      //     this.error = true;
      //     this.error_msg = result.message;
      //     setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      //   }


      //   //this.success = true;
      // });

  }

  statuschangeactive(clientId,status)
  {
    this.recruitmentservice.clientstatus(clientId,status).subscribe( (data:any) =>
    {
      if(data.statusCode == 200)
      {
        this.showtable=false

        // this.success = true;
        // this.sucess_msg = data.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.clientList();
        //this.getFilterFormData();
      }
      else
      {
        // this.error = true;
        // this.error_msg = data.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }

   })
  }
  statuschangeinactive(clientId,status)
  {

   this.recruitmentservice.clientstatus(clientId,status).subscribe( (data:any) =>
   {
      if(data.statusCode == 200)
      {
        this.showtable=false

        // this.success = true;
        // this.sucess_msg = data.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        this.clientList();
      }
      else
      {
        // this.error = true;
        // this.error_msg = data.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }

    /* window.location.reload();
    console.log("clientId"); */
   })
  }


  onKeyUpEvent(event)
  {
    if(this.query.length !=0)
    {
      // console.log(this.query.length);
      this.p=1;
      // console.log(this.p);
    }
    else
    {
      this.p = this.beforepage;
      // console.log(this.p);
      // console.log(this.beforepage);
    }
  }

  ucFirst(string) 
  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'clientName':
          return compare(a.clientName, b.clientName, isAsc);

        case 'location':
          return compare(a.location, b.location, isAsc);
        
        case 'clientDescription':
          return compare(a.clientDescription, b.clientDescription, isAsc);

        default:
          return 0;
      }
    });
  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Allow only alphanumeric characters, hyphens, and underscores
    const regex = /^[a-zA-Z0-9-_]*$/;
    if (!regex.test(value)) {
      value = value.replace(/[^a-zA-Z0-9-_]/g, '');
    }

    input.value = value;
    this.searchForm.get('keywords').setValue(value);
  }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

