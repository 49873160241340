import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

@Component({
  selector: 'app-share-feedback-form',
  templateUrl: './share-feedback-form.component.html',
  styleUrls: ['./share-feedback-form.component.scss']
})
export class ShareFeedbackFormComponent implements OnInit {

  result: any[];
  bootDisable: boolean=false;
setDisabled: boolean=false;
showradioerrors: boolean=false;
btnDisable: boolean=false;
btnDisables: any;

  constructor(private router: Router, private hrmsService: HrmsserviceService, private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,  private elementRef: ElementRef,
  ) { }

  resFeedbackCreateData: any;
  resourceId: any;
  loader: any = true;
  resourceObj: any;
  ratings: any = [];
  skills: any = [];
  recommendations: any = [];
  feedbackFormWithArray: FormGroup;
  feedbackId: any;
  recId: number;
  feedbackData: any;
  recCheckbox: any;
  rec: any;
  resRatingData: any;
  ratingsArray = [];
  skillsData: any;
  resName: string;
  position: string;
  update_data_error_msg: any;
  create_sucess_msg: any;
  backdrop: any = false;
  success: any = false;
  error: any = false;
  resRatingId: any;
  submitted: boolean = false;
  showerror: boolean = false;
  showradioerror: boolean = false;
  fId: any;
  routeFeedbackId:any;
  checkBox:boolean =  false;

  ngOnInit(): void {

    // this.feedbackFormWithArray =  new FormGroup({
    //   applicantId: new FormControl(),
    //   comments: new FormControl(this.feedbackFormWithArray,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
    //   skills: this.fb.array(
    //     this.skills.map((t) => {
    //       this.fb.control(t);
    //     })
    //   ),
    // });
    this.routeFeedbackId = this.activatedRoute.snapshot.params.feedbackId;

    console.log('this.routeFeedbackId ', this.routeFeedbackId )
    this.feedbackFormWithArray = this.fb.group({
      applicantId: [''],
      comments: [''],
      // comments:  new FormControl(this.feedbackFormWithArray,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      recommendation: ['', [Validators.required]],
      skills: this.fb.array(
        this.skills.map((t) => {
          this.fb.control(t, { validators: [Validators.requiredTrue] });
        })
      ),
    });
    this.resourceId = this.activatedRoute.snapshot.params.resourceId;
    console.log(" this.resourceId ",  this.resourceId )
    this.hrmsService
      .findbyResourceId(this.resourceId)
      .subscribe((resource: any) => {
        this.resourceObj = resource;
        console.log(this.resourceObj);
        this.resName = this.resourceObj.resourceName;
        console.log('this.resName', this.resName)
        this.position = this.resourceObj.position.positionName
        this.loader = false;
        if(this.resourceObj.resourceFeedBack != undefined){
          this.fId =  this.resourceObj.resourceFeedBack.feedBackId;
          console.log("this.fid", this.fId)
        }
      });
  
    if (this.activatedRoute.snapshot.params.feedbackId) {
      this.feedbackId = this.activatedRoute.snapshot.params.feedbackId;
      this.hrmsService.getAllRatings()
      .subscribe((ratingList: any) => {
        this.ratings = ratingList.reverse();
        console.log('ratingList', this.ratings);
        this.hrmsService.getAllSkills()
        .subscribe((skillList: any) => {
          this.skills = skillList;
          console.log('skillList', this.skills);
          this.feedbackFormWithArray = this.fb.group({
            applicantId: [''],
            comments: [''],
            recommendation: ['', [Validators.required]],
            skills: this.fb.array(
              this.skills.map((t) => {
                this.fb.control(t, { validators: [Validators.requiredTrue] });
              })
            ),
          });

          this.hrmsService.getAllRecommendations()
          .subscribe((recList: any) => {
            console.log('recList', recList);
            this.recommendations = recList;
            this.hrmsService.getResourceFeedbackByFeedbackId(this.activatedRoute.snapshot.params.feedbackId)
            .subscribe((feedback: any) => {
              this.feedbackData = feedback;
              this.btnDisable=false
              console.log('feedbackdata',this.feedbackData);
              this.recommendations.map((recomendation) => {
                if (recomendation.listTypeValueId == feedback.recomendation.listTypeValueId) {
                  console.log('equal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                  recomendation.selected = !recomendation.selected;
                  this.feedbackFormWithArray.patchValue({
                    recommendation:feedback.recomendation.listTypeValueId,
                  });
                  console.log( this.feedbackFormWithArray);
                } else {
                  console.log('notequal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                  recomendation.selected = false;
                }
              })
              const data = this.feedbackData.resourceRating.map(x => x.rating.ratingId)
              console.log('data', data)
              var arr = data;
              this.feedbackFormWithArray.controls.skills.patchValue(arr)
              this.feedbackFormWithArray.patchValue({
                comments: this.feedbackData.comments,
              });
              console.log("this.btndisable",this.btnDisable);
              console.log("this.checkbox::",this.checkBox);
              console.log("feedbackFormWithArray::",this.feedbackFormWithArray);
              // if( this.feedbackFormWithArray.valid){
              //   this.btnDisable=true;
              //   this.checkBox=true
              //   return
              // }
              
              
              
            }
            )
          });
        });
      });
    } else {
      this.hrmsService.getAllRatings()
        .subscribe((ratingList: any) => {
          this.ratings = ratingList.reverse();
          console.log('ratingList', this.ratings);
        });

      // this.hrmsService.getAllSkills()
      //   .subscribe((skillList: any) => {
      //     this.skills = skillList;
      //     console.log('skillList', this.skills);
      //     this.feedbackFormWithArray = this.fb.group({
      //       applicantId: [''],
      //       comments: [''],
      //       recommendation: ['', [Validators.required]],
      //       skills: this.fb.array(
      //         this.skills.map((t) => {
      //           this.fb.control(t, { validators: [Validators.requiredTrue] });
      //         })
      //       ),
      //     });
      //   });
      this.hrmsService.getAllSkills().subscribe((skillList: any) => {
        this.skills = skillList;
        console.log('skillList', this.skills);
      
        this.feedbackFormWithArray = this.fb.group({
          applicantId: [''],
          comments: [''],
          recommendation: ['', [Validators.required]],
          skills: this.fb.array(
            this.skills.map(() => this.fb.control(null, Validators.required)) // Corrected this line
          ),
        });
      });

      this.hrmsService.getAllRecommendations()
        .subscribe((recList: any) => {
          console.log('recList', recList);
          this.recommendations = recList;
        });
    }
    this.scrollToEditForm()
  }

  formcancel() {
    // this.router.navigate(['home/resource-list']);
  }

  isAllSelected(recomendation) {
    // this.checkBox = false;

    console.log("isAllSelected");
    this.recommendations.forEach(val => {
      if (val.listTypeValueId == recomendation.listTypeValueId) {
        val.selected = !val.selected;
        if (val.selected == true) {
          this.showerror = false;
          this.checkBox = true;
          this.btnDisable=false;
        } else {
          this.showerror = true;
          this.checkBox = false;
          this.btnDisable=true;

        }
        this.recId = recomendation.listTypeValueId;
        console.log('this.feedbackFormWithArray.value.recommendation', this.feedbackFormWithArray.value.recommendation)
        console.log('this.recId', this.recId)
        console.log('selected', val.selected)
        console.log("this.brndisable::",this.btnDisable);
        
      } else {
        console.log('unselected', val.selected)
        val.selected = false;
        // this.recId = 0;
      }
    });
    // this.showradioerror = false;

  }
  ngAfterViewInit() {
    // Scroll to the edit form after the view is initialized
    this.scrollToEditForm();
  }
  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
  }

  submitData() {
    console.log(this.feedbackFormWithArray);
    console.log(this.feedbackFormWithArray.value);
    console.log('this.feedbackFormWithArray.value.recommendation', this.feedbackFormWithArray.value.recommendation)
    this.submitted = true;
    this.btnDisable=true;
    // this.showradioerror = true;
    if (this.feedbackFormWithArray.value.recommendation == false) {
      this.showerror = true;
      this.btnDisable=false;

      return
    }
    // if (this.feedbackFormWithArray.get('skills').invalid) {
    //   this.showradioerror = true;
    //   this.btnDisable = false;
    //   return;
    // } 
    // else{
    //   this.showradioerror = false;
    // }

   
   
    if(this.feedbackFormWithArray.invalid){
      this.btnDisable=false;
      // this.showradioerror = true;


      return
    }
 
   

    if (this.feedbackId) {
      this.showradioerror = true;

      const resultArray = [];
      for (let i = 0; i < this.skills.length; i++) {
        if (this.feedbackFormWithArray.value.skills[i] == null) {
          this.showradioerror = true;
          return
        } else {
          this.showradioerror = false;
        }
        this.feedbackData.resourceRating.forEach((element, j) => {
          if (i == j) {
            console.log('resourceRatingId', element.resourceRatingId);
            this.resRatingId = element.resourceRatingId;
          }

        });
        console.log('resultArray1', resultArray);
        resultArray.push({
          resourceRatingId: this.resRatingId,
          skill: {
            skillId: this.skills[i].skillId,
          },
          rating: {
            ratingId: this.feedbackFormWithArray.value['skills'][i],
          },
          status: 1
        });
      }
      var feedbackUpdateData = {
        feedbackId: this.feedbackId,
        resource: {
          resourceId: this.resourceId
        },
        // recomendation: {
        //   listTypeValueId: this.feedbackFormWithArray.value.recommendation
        // },
        selected: true,
        comments: this.feedbackFormWithArray.value.comments,
        resourceRating: resultArray,
        status: {
          listTypeValueId: 1
        }
      }
      if (this.feedbackFormWithArray.value.recommendation == true ||
        this.feedbackFormWithArray.value.recommendation == false) {
        feedbackUpdateData['recomendation'] = {
          listTypeValueId: this.recId
        }
      } else {
        feedbackUpdateData['recomendation'] = {
          listTypeValueId: this.feedbackFormWithArray.value.recommendation
        }
      }
      this.btnDisable=true;

      this.hrmsService.updateResourceFeedback(this.feedbackId, feedbackUpdateData)
        .subscribe(
          (data: any) => {
            this.resFeedbackCreateData = data;
            console.log('sub update:', data)
            this.backdrop = false;
            this.loader = false;
            this.success = true;
            // this.bootDisable=true;
            this.btnDisable=true;


            this.create_sucess_msg = this.resFeedbackCreateData.description;
            console.log("update" + this.create_sucess_msg);
            setTimeout(() => {
              this.success=false;
              // this.bootDisable=true;
              this.btnDisable=true;


              // this.router.navigate(['login']);
            }, 3000)
            this.btnDisable=false;

            this.scrollToEditForm()
          },
          (err) => {
            console.log("err");
            this.backdrop = false;
            this.loader = false;
            this.error = true;
            this.update_data_error_msg = err.error.message;
            setTimeout(() => { this.error = false }, 3000)
          }
        )
    } else {
      this.showradioerror = true;

      const resultArray = [];
      for (let i = 0; i < this.skills.length; i++) {
        if (this.feedbackFormWithArray.value.skills[i] == null) {
          this.showradioerror = true;
          return
        } else {
          this.showradioerror = false;
          // return
        }
        resultArray.push({

          skill: {
            skillId: this.skills[i].skillId,
          },
          rating: {
            ratingId: this.feedbackFormWithArray.value['skills'][i],
          },
          status: 1
        });
      }
      console.log('resultArray', resultArray);
      let createfeedback = {
        resource: {
          resourceId: this.resourceId
        },
        recomendation: {
          listTypeValueId: this.recId
        },
        selected: true,
        comments: this.feedbackFormWithArray.value.comments,
        resourceRating: resultArray
      }
      this.btnDisable=true;

      console.log(createfeedback);
      this.hrmsService.createResourceFeedback(createfeedback)
        .subscribe((data: any) => {
          console.log('subs:', data)
          this.resFeedbackCreateData = data;
          console.log(this.resFeedbackCreateData);
          this.backdrop = false;
          this.loader = false;
          this.success = true;
          // this.bootDisable=true;
          this.btnDisable=true;


          this.create_sucess_msg = this.resFeedbackCreateData.description;
          console.log('create_sucess_msg', this.create_sucess_msg);
          setTimeout(() => {
            this.success=false;
            // this.bootDisable=true;
            this.btnDisable=true;

            // this.router.navigate(['login']);
          }, 3000)
          this.scrollToEditForm()

        },
          (err) => {
            this.backdrop = false;
            this.loader = false;
            this.error = true;
            this.update_data_error_msg = err.error.message;
            console.log('update_data_error_msg', this.update_data_error_msg);
            setTimeout(() => { this.error = false }, 3000)
          })
    }
  }

  get comments() {
    return this.feedbackFormWithArray.get('comments');
  }
  get recommendation() {
    return this.feedbackFormWithArray.get('recommendation');
  }

}
