import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { httpOptions } from './properties';
import { re } from 'mathjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
    })
  }
  admindashboard() {
    throw new Error('Method not implemented.');
  }
  geRecruiter() {
    throw new Error('Method not implemented.');
  }
  subscribe(arg0: (recruiterlist: any) => void) {
    throw new Error('Method not implemented.');
  }
  dashboardRecruitmentAdmin() {
    throw new Error('Method not implemented.');
  }
  constructor(private http: HttpClient) {}

  adminLogin(postValues):Observable<any> {
    let result = this.http
      .post(`${environment.base_url}user_Login`, postValues, httpOptions)
      .pipe(map((data) => data));
    return result;
  }

  dashboardData(id: any): Observable<any> {
    let result = this.http
      .get<any>(`${environment.base_url}dashboard/organization/${id}`, httpOptions)
      .pipe(map((data) => data));
    return result;
  }

  countryAutoCompleteSearch(keywords: any): Observable<any> {
    let result = this.http
      .get<any>(`${environment.base_url}country/countryname/${keywords}`, httpOptions)
      .pipe(map((data) => data));
    return result;
  }

  stateAutoCompleteSearch(countryId: any, keywords: any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}state/countryId/${countryId}/${keywords}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }

  districtAutoCompleteSearch(stateId: any, keywords: any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}district/stateId/${stateId}/${keywords}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }

  cityAutoCompleteSearch(districtId: any, keywords: any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}city/districtId/${districtId}/${keywords}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  

  getBranchlists(): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}active/businessGroup`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  // getBranchlistset(entityIds:any): Observable<any> {
  //   let result = this.http
  //     .get<any>(
  //       `${environment.base_url}businessGroup/entity/${entityIds}`,
  //       httpOptions
  //     )
  //     .pipe(map((data) => data));
  //   return result;
  // }

  getBranchlistset(entityIds:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}active/legalEntity/entity/${entityIds}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  
  // http://localhost:6015/api/enote/hrms/active/legalEntity/entity/1

  getEntityList():Observable<any>{
    let result = this.http.get<any>(`${environment.base_url}list-type-values/20`,httpOptions).pipe(map((data)=>data));
    return result;
  }

  // http://localhost:6015/api/enote/hrms/list-type-values/20
  // https://enote.jespersoft.com:6015/api/enote/hrms/entity
  getlegalEntityList(entity:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}legalEntity/entity/${entity}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  updateLegalEntityStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.base_url}legalEntityId/deactive/${id}/${status}`,null,httpOptions).pipe(map(data=>data));
    return result;
  }
  getlegalEntityListKeyword(keywords:any,entityId:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}legalEntity/legalEntityName/${keywords}/entity/${entityId}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  getBusinessGroupListKeyword(keywords:any,entity:any): Observable<any> {
    let result = this.http
      .get<any>(

        `${environment.base_url}legalEntity/businessGroupName/${keywords}/entity/${entity}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  getBusinessGroupLegalEntityList(legalkeywords:any,businessKeywords:any,entityId:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}legalEntity/legalEntityName/${legalkeywords}/businessGroupName/${businessKeywords}/entity/${entityId}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  getEditlegalEntity(id:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}legalEntity/${id}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }

 
  // createLegalEntity(body: any): Observable<any> {
  //   return this.http.get<any>(`${environment.base_url}legalEntity`, { ...httpOptions, params: body }).pipe(
  //     map(response => response) 
  //   );
  // }

  createLegalEntity(body: any): Observable<any> {
    return this.http.post<any>(`${environment.base_url}legalEntity`, body,httpOptions).pipe(
      map(response => response) // Extracts the response data
    );
  }


  updateLegalEntity(legalEntityId:number,body:any):Observable<any>{
    return this.http.put<any>(`${environment.base_url}legalEntity/${legalEntityId}`,body,httpOptions).pipe(map(response=>response));
  }

  // https://enote.jespersoft.com:6015/api/enote/hrms/entity/1
  updateBusinessStatus(id:any,status:any): Observable<any> {
    let result = this.http.put(`${environment.base_url}businessGroupId/deactive/${id}/${status}`,null,httpOptions).pipe(map(data=>data));
    return result;
  }
  getSupplierList(keywords:any,entityId:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}supplier/supplierName/${keywords}/entity/${entityId}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  getAllSupplier(entityId:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}supplier/entity/${entityId}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
  }
  updateSupplierStatus(id:any,status:any): Observable<any> {
    let result = this.http.put(`${environment.base_url}supplierId/deactive/${id}/${status}`,null,httpOptions).pipe(map(data=>data));
    return result;
  }

  addSupplier(payload):Observable<any>{
    let result = this.http.post(`${environment.base_url}supplier`,payload,httpOptions).pipe(map(data=>data));
    return result;
  }
  getSupplierEdit(id:any): Observable<any> {
    let result = this.http
      .get<any>(
        `${environment.base_url}supplier/${id}`,
        httpOptions
      )
      .pipe(map((data) => data));
    return result;
}
updateSupplier(id:any,postvalues:any): Observable<any> {
  let result = this.http.put(`${environment.base_url}supplier/${id}`,postvalues,httpOptions).pipe(map(data=>data));
  return result;
}
getAllActiveLegalEntitylist(entityId:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}activeOnly/legalEntity/entity/${entityId}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}

createBusinessUnit(payload):Observable<any>{
  let result = this.http.post(`${environment.base_url}businessUnit`,payload,httpOptions).pipe(map(data=>data));
  return result;
}
getBusinessUnitById(id:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}businessUnit/${id}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}
updateBusinessUnit(id:any,postvalues:any):Observable<any>{
  let result = this.http.put(`${environment.base_url}businessUnit/${id}`,postvalues,httpOptions).pipe(map(data=>data));
  return result;
}
getBusinessOnly(keywods:any,entityid:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}businessUnit/businessUnitName/${keywods}/entity/${entityid}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}
getLegalOnly(keywods:any,entityid:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}businessUnit/legalEntityName/${keywods}/entity/${entityid}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}
getbusinessLegalList(keywods:any,keyowordsoflegal:any,entityId:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}businessUnit/businessUnitName/${keywods}/legalEntityName/${keyowordsoflegal}/entity/${entityId}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}
getAllEntityBusinessUnit(entityId:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}active/businessUnit/entity/${entityId}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}
getAllEntitySupplier(entityId:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}supplier/entity/${entityId}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}
createClient(payload):Observable<any>{
  let result = this.http.post(`${environment.base_url}client`,payload,httpOptions).pipe(map(data=>data));
  return result;
}
getClientbyId(clientId:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}client/${clientId}`,
    httpOptions
  )
  .pipe(map((data) => data));
return result;
}
updateClient(clientId:any,payload:any):Observable<any>{
  let result=this.http.put(`${environment.base_url}client/${clientId}`,payload,httpOptions).pipe(map(data=>data));
  return result;
}
entityTypeSearch(keywords:any){
  let result = this.http
  .get<any>(
    `${environment.base_url}entityType/entityTypeName/${keywords}/listType/20`,
    httpOptions
  ).pipe(map((data) => data));
return result;
}
}
