import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { httpOptions } from './properties';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class SuperadminService {

  constructor(private http: HttpClient) { }

  createModule(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}module`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  editModule(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}module/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  createCheckExistModuleName(postData: any) {
    return this.http.post(`${environment.base_url}module/create/moduleName`, postData, httpOptions).pipe(map(data => data));
  }

  updateCheckExistModuleName(postData: any) {
    return this.http.post(`${environment.base_url}module/update/moduleName`, postData, httpOptions).pipe(map(data => data));
  }

  createCheckExistModuleCode(postData: any) {
    return this.http.post(`${environment.base_url}module/create/modulecode`, postData, httpOptions).pipe(map(data => data));
  }

  updateCheckExistModuleCode(postData: any) {
    return this.http.post(`${environment.base_url}module/update/modulecode`, postData, httpOptions).pipe(map(data => data));
  }

  getModuleById(id: any) {
    let result = this.http.get(`${environment.base_url}module/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  updateModuleStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}module/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  getModulesList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}module`, httpOptions).pipe(map(data => data))
    return result;
  }

  getActiveModule(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}module/active_module`, httpOptions).pipe(map(data => data))
    return result;
  }

  dashboardData(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}superAdminDashboard`, httpOptions).pipe(map(data => data))
    return result;
  }

  /* Organization Module Start */
  getOrganizationList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}organization`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateOrganizationStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}organization/organizationstatus/${id}/${status}`, null, httpOptions);
    return result;
  }
  // http://localhost:6015/api/enote/hrms/organization/organizationstatus/11/2
  getOrganizationById(id: any) {
    let result = this.http.get(`${environment.base_url}organization/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  getClientById(id: number) {
    let result = this.http.get(`${environment.base_url}client/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  // http://localhost:6015/api/enote/hrms/client/46

  // addOrganization(item): Observable<any>
  // {
  //   let result = this.http.post(`${environment.base_url}organization`,item,httpOptions).pipe(map(data=>data))
  //   return result;
  // }

  addOrganization(item): Observable<any> {
    let result = this.http.post(`${environment.base_url}newmodule/organization`, item, httpOptions).pipe(map(data => data))
    return result;
  }


  listOfBusinessUnit(entityId: any): Observable<any> {
    let result = this.http.get(`${environment.base_url}active/businessUnit/entity/${entityId}`, httpOptions)
    return result;
  }


  editOrganization(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}newModule/organization/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }



  // getSearchOrganizationList(keywords: any, industryType: any): Observable<any> {
  //   let result = this.http.get<any>(`${environment.base_url}organization/filter/keyword/industrytype/${keywords}/${industryType}`, httpOptions).pipe(map(data => data))
  //   return result;
  // }

  getSearchOrganizationList(keywords: any, industryType: any,entityId:any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}organizationName/${keywords}/IndustryType/${industryType}/entity/${entityId}`, httpOptions).pipe(map(data => data))
    return result;
  }
  // http://localhost:6015/api/enote/hrms/organizationName/ Environmental NGO/IndustryType/27

  getOrganizationListKeywords(keywords: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}organization/filter/keyword/${keywords}`, httpOptions).pipe(map(data => data))
    return result;
  }

  getOrganizationListIndustryType(industryType: any, entityId: any): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}organization/filter/industrytype/${industryType}/entity/${entityId}`, httpOptions).pipe(map(data => data))
    return result;
  }

  createCheckExistOrganizationName(postData: any) {
    return this.http.post(`${environment.base_url}organization/create/organizationName`, postData, httpOptions).pipe(map(data => data));
  }

  createCheckExistContactNumber(postData: any) {
    return this.http.post(`${environment.base_url}organization/create/contactNumber`, postData, httpOptions).pipe(map(data => data));
  }

  createCheckExistContactEmail(postData: any) {
    return this.http.post(`${environment.base_url}organization/create/contactEmail`, postData, httpOptions).pipe(map(data => data));
  }
  /* Organization Module End */

  /* Menus start here */
  getMenusList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}menu`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateMenuStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}menu/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  createMenu(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}menu`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  editMenu(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}menu/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  createCheckExistMenuName(postData: any) {
    return this.http.post(`${environment.base_url}menu/create/menuName`, postData, httpOptions).pipe(map(data => data));
  }

  updateCheckExistMenuName(postData: any) {
    return this.http.post(`${environment.base_url}menu/update/menuName`, postData, httpOptions).pipe(map(data => data));
  }

  getMenuById(id: any) {
    let result = this.http.get(`${environment.base_url}menu/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  /* Menus end here */

  /* Date Format start here */
  getDateFormatList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}dateFormat`, httpOptions).pipe(map(data => data))
    return result;
  }

  createDateFormat(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}dateFormat`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  editDateFormat(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}dateFormat/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  createCheckExistDateFormatName(postData: any) {
    return this.http.post(`${environment.base_url}dateFormat/create/dateFormatName`, postData, httpOptions).pipe(map(data => data));
  }

  updateCheckExistDateFormat(postData: any) {
    return this.http.post(`${environment.base_url}dateFormat/update/dateFormatName`, postData, httpOptions).pipe(map(data => data));
  }

  updateDateFormatStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}dateFormat/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  getDateformatById(id: any) {
    let result = this.http.get(`${environment.base_url}dateFormat/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }
  /* Date Format end here */

  getUserType(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}user_type`, httpOptions).pipe(map(data => data))
    return result;
  }

  /* TimeZone start here */
  getTimeZoneList(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}timezone`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateTimeZoneStatus(id, status): Observable<any> {
    let result = this.http.put(`${environment.base_url}timezone/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  createTimeZone(postValues): Observable<any> {
    let result = this.http.post(`${environment.base_url}timezone`, postValues, httpOptions).pipe(map(data => data))
    return result;
  }

  editTimeZone(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}timezone/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  getTimezoneById(id: any) {
    let result = this.http.get(`${environment.base_url}timezone/${id}`, httpOptions).pipe(map(data => data));
    return result;
  }

  createCheckExistTimezoneName(postData: any) {
    return this.http.post(`${environment.base_url}timezone/create/timezonename`, postData, httpOptions).pipe(map(data => data));
  }

  updateCheckExistTimezoneName(postData: any) {
    return this.http.post(`${environment.base_url}timezone/update/timezonename`, postData, httpOptions).pipe(map(data => data));
  }

  getActiveTimezone(): Observable<any> {
    let result = this.http.get<any>(`${environment.base_url}timezone/active_timezone`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateOrganizationSettings(id: any, postValues: any) {
    let result = this.http.put(`${environment.base_url}organization/dateformat/${id}`, postValues, httpOptions).pipe(map(data => data));
    return result;
  }

  getUserRoleList() {
    let result = this.http.get<any>(`${environment.base_url}role/superadmin/`, httpOptions).pipe(map(data => data))
    return result;
  }

  // entity search api's...................................

  getSearchEntityNames(keywords: any) {
    let result = this.http.get<any>(`${environment.base_url}entity/entityName/${keywords}`, httpOptions).pipe(map(data => data))
    return result;
  }

  updateEntityStatus(id: any, status: any) {
    let result = this.http.put(`${environment.base_url}entity/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }

  entityAll() {
    let result = this.http.get<any>(`${environment.base_url}entity`, httpOptions).pipe(map(data => data))
    return result;
  }

  getAllbusinessUnit(entity: any) {
    let result = this.http.get<any>(`${environment.base_url}businessUnit/entity/${entity}`, httpOptions).pipe(map(data => data))
    return result;
  }
  updateBusinessStatus(id: any, status: any) {
    let result = this.http.put(`${environment.base_url}businessUnit/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }


  listOragnizationBasedOnEntity(entityId: any) {
    let result = this.http.get(`${environment.base_url}organization/entity/${entityId}`, httpOptions).pipe(map(data => data));
    return result;
  }

  keywordsSearchOfOrganizationName(keywords: any, entityId: any) {
    let result = this.http.get(`${environment.base_url}organizationName/${keywords}/entity/${entityId}`, httpOptions).pipe(map(data => data));
    return result;
  }

  listOragnizationByOrganizationId(orgId: number) {
    let result = this.http.get(`${environment.base_url}organization/organizationId/2`)
  }

  // http://localhost:6015/api/enote/hrms/organization/organizationId/2

  // https://enote.jespersoft.com:6015/api/enote/hrms/organizationName/t
  getAllClientlist(entityId: any) {
    let result = this.http.get<any>(`${environment.base_url}client/entity/${entityId}`, httpOptions).pipe(map(data => data))
    return result;
  }
  // getAllClientlist() {
  //   let result = this.http.get<any>(`${environment.base_url}client`, httpOptions).pipe(map(data => data))
  //   return result;
  // }
  updateClientStatus(id: any, status: any) {
    let result = this.http.put(`${environment.base_url}client/deactive/${id}/${status}`, null, httpOptions).pipe(map(data => data));
    return result;
  }
  getClientDataonly(keywords: any,entityId:any): Observable<any> {
    let result = this.http.get(`${environment.base_url}client/clientName/${keywords}/entity/${entityId}`, httpOptions)
    return result;
  }

  getSeachClientData(keywords: any, entityId: any): Observable<any> {
    let result = this.http.get(`${environment.base_url}client/filter/keyword/${keywords}/entity/${entityId}`, httpOptions)
    return result;
  }
  getBusinessUnitClientOnlyy(keywords: any, busniesskeyword: any, entityId: any): Observable<any> {
    let result = this.http.get(`${environment.base_url}client/clientName/${keywords}/businessUnitName/${busniesskeyword}/entity/${entityId}`, httpOptions)
    return result;
  }
  getBusinessUnitOnlyy(busniesskeyword: any, entityId: any): Observable<any> {
    let result = this.http.get(`${environment.base_url}businessUnit/${busniesskeyword}/entity/${entityId}`, httpOptions)
    return result;
  }

  // http://192.168.0.194:6015/api/enote/hrms/organization/entity/1

  searchBasedOnBusinessUnitNameAndIndutryType(businessUnitName: string, industryType: number, entityId: any) {
    let result = this.http.get(`${environment.base_url}organization/businessUnitName/${businessUnitName}/industryType/${industryType}/entity/${entityId}`, httpOptions)
    return result;

  }


  searchBasedOnOrganizationBusinessUnitIndustryType(organizationName: string, BusinessUnitName: string, industryType: number, entityId: any) {

    let result = this.http.get(`${environment.base_url}organization/organizationName/${organizationName}/businessUnitName/${BusinessUnitName}/industryType/${industryType}/entity/${entityId}
`, httpOptions);
    return result;
  }
  getActiveSupplierEntity(entityId: any): Observable<any> {
    let result = this.http.get(`${environment.base_url}supplier/active/entity/${entityId}`, httpOptions)
    return result;
  }
  createClientSupplier(postData: any) {
    return this.http.post(`${environment.base_url}clientsupplier/multiple`, postData, httpOptions).pipe(map(data => data));
  }
  getAllClientSupplierList(entityId: any): Observable<any> {
    let result = this.http.get(`${environment.base_url}clientsuppliers/entity/${entityId}`, httpOptions)
    return result;
  }
  deleteClientSup(clientid: any, supplierId: any) {
    let result = this.http.delete(`${environment.base_url}client-supplier/clientId/${clientid}/supplierId/${supplierId}`, httpOptions).pipe(map(data => data))
    return result;

  }
  BothClientSupplier(clientkeywords: any, supplierKeywords: any, entityid: any) {
    let result = this.http.delete(`${environment.base_url}filter/clientName/${clientkeywords}/supplierName/${supplierKeywords}/entity/${entityid}`, httpOptions).pipe(map(data => data))
    return result;

  }
  onlyClientAssign(clientkeywords: any, entityid: any) {
    let result = this.http.delete(`${environment.base_url}filter/clientName/${clientkeywords}/entity/${entityid}`, httpOptions).pipe(map(data => data))
    return result;

  }
  onlySuppAssign(supplierKeywords: any, entityid: any) {
    let result = this.http.delete(`${environment.base_url}filter/supplierName/${supplierKeywords}/entity/${entityid}`, httpOptions).pipe(map(data => data))
    return result;

  }
  getAllClientActiveEntity( entityid: any){
    let result = this.http.get(`${environment.base_url}client/active/entity/${entityid}`, httpOptions)
    return result;
  }
  getsupClientAssignGet(assignsupclientId: any){
    let result = this.http.get(`${environment.base_url}clientsupplier/${assignsupclientId}`, httpOptions)
    return result;
  }
  updateclientSupp(id:any,data:any){
    let result=this.http.put(`${environment.base_url}clientSupplier/${id}`,data,httpOptions)
    return result;
  }

  // https://enote.jespersoft.com:6015/api/enote/hrms/organization/organizationName/Jesperapps Software Services/businessUnitName/TEst/industryType/41

}
