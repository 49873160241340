<div >
    <mat-icon style="color: red !important;float: right;" class="material-icons-outlined" (click)="closedialog()">close</mat-icon>          
</div>
<div class="table-responsive">
    <p><b>Employees</b></p>
    <table class="table  --table-bordered table-border-new" style="width: 100%;">
        <thead class="t-head">
            <tr>
               
                <th>Emp Code</th>
                <th>Emp Name</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Desgination</th>
                <th>DOJ</th>
                <th>Gender</th>
        
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of resultData.employees | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
              
          

                <td>{{row.employeeNumber}}</td>                                      
                <td>{{row.firstName}}  </td>
                <td>{{row.mobileNumber}}</td>
                <td>{{row.organizationEmail}}</td>
                <td *ngIf="row.designation !=undefined" >{{row.designation.designationName}}</td>
                <td *ngIf="row.designation == undefined" >--</td>
                <td>{{row.dateOfJoining | date : dateFormat}}</td>
                <td>{{row.gender.listTypeValueName}}</td>
                        
                        
                <!-- capitalizeWordsName -->
               
            </tr>
        </tbody>
        <tbody>
            <tr>
                <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
            </tr>
        </tbody>
    </table>
</div>