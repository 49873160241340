<!-- <nav class="navbar bg" style="margin-top: 68px">
  <a class="navbar-brand">Add Task</a>
  <div class="d-flex">
    <input
      class="form-control me-2"
      type="search"
      placeholder="Search..."
      [(ngModel)]="search"
      (keyup)="changepage()"
      aria-label="Search"
      style="
        width: 250px;
        height: 45px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
        background: #fff;
        position: relative;
        left: -19px;
      "
    />

    <button
      class="btn"
      type="submit"
      (click)="createTask()"
      style="
        background-color: #009ef7;
        color: whitesmoke;
        top: 5px;
        position: relative;
      "
    >
      Add Task
    </button>
  </div>
</nav> -->

<!-- <nav class="navbar bg" style="margin-top: 67px">
  <div class="top-content row col-sm-12">
    <div class="col-md-5 col-sm-12">
      <h4 class="timesheet" style="    position: relative;
      top: 9px;
      font-size: 22px;
      font-family: 'Mulish', sans-serif;
      font-weight: 800;
      left: -19px;">Add Task</h4>
    </div>

    <div class="col-md-5 col-sm-5">
      <ul class="unstyled inbox-pagination">
        <div class="sidebar-search">
          <div class="input-group">
            <input
              type="text"
              class="form-control search-menu search-placeholder"
              placeholder="Search..."
              [(ngModel)]="search"
              (keyup)="changepage()"
              name="searchText"
            />
          </div>
        </div>
      </ul>
    </div>
    <div class="col-md-2 col-sm-2 col2-res button-top">
      <button
        type="button"
        mat-raised-button
        class="btn float-right res-timesheet"
        (click)="createTask()"
      >
        <span> Add Task</span>
      </button>
    </div>
  </div>
</nav> -->

<nav class="navbar bg" style="margin-top: 67px">
  <div class="container-fluid">
    <div class="row w-100">
      <div class="col-12 col-md-4">
        <h4 class="heading-tag" style="position: relative; left: 5px">
          Add Task
        </h4>
      </div>

      <div class="col-12 col-md-4">
        <div class="input-group">
          <input type="input" class="form-control search-menu search-placeholder" placeholder="Search..."
            [(ngModel)]="search" (keyup)="changepage()" name="searchText" />
        </div>
      </div>

      <div class="col-12 col-md-4 isBTN ">
        <button
  type="button"
  (click)="createTask()"
  mat-raised-button
  class="searchBtn1"
>
  <span> Add Task</span>
</button>
      </div>
    </div>
  </div>
</nav>

<mat-card>
  <div class="search-content">
    <!-- <form  [formGroup]="assignListForm"> -->
    <!-- <span class="text-danger"> *</span> -->
    <div class="row">
      <div class="col-md-3 ">
        <label class="form-control-label">Task Group</label>
        <mat-select
          id="framework"
          (selectionChange)="search = ''"
          class="form-control email-input select-placeholder"
          [(ngModel)]="selectedetaskGroupId"
          placeholder="Select Task Group"
        >
          <mat-option>-Select Task Group-</mat-option>
          <mat-option
            *ngFor="let taskgroup of taskgrouplist"
            [value]="taskgroup.taskGroupId"
          >
            {{ taskgroup.taskGroupName }}
          </mat-option>
        </mat-select>
        <!-- <span class="error_msg" *ngIf="taskgroup_required">Please select task group</span>  -->
        <!-- <span class="text-danger"> *</span> -->
      </div>
      <div class="col-md-3 min-height">
        <label class="form-control-label">Task Status</label>
        <mat-select
        (selectionChange)="search = ''"
          id="framework"
          class="form-control email-input select-placeholder"
          [(ngModel)]="taskStatusId"
          placeholder="Select Task Status"
        >
          <mat-option>-Select Task Status-</mat-option>
          <mat-option [value]="31">To Do</mat-option>
          <mat-option [value]="29">In Progress</mat-option>
          <mat-option [value]="30">Completed</mat-option>
          <mat-option [value]="3">Pending</mat-option>
        </mat-select>
      </div>
      <div
      class="col-xl-2 col-lg-3 col-md-3 col-sm-12  my-xl-0 my-md-0 my-lg-0"
    >
      <div style="position: relative">
        <label class="form-control-label" style="margin-left: 0px"
          >Start Date</label
        >
        <input
          placeholder="Start Date"
          matInput
          readonly
          [matDatepicker]="picker1"
          class="form-control email-input select-placeholder text-truncate"
          style="height: 38px; padding: 1px; margin-left: -2px"
          [(ngModel)]="startDate"
          type="text"
          [max]="today"
          (dateChange)="checkStartDate(); updateEndDateMin($event)"
          id="startDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
          style="
            float: right;
            position: relative;
            bottom: 41px;
            margin-right: 2px;
          "
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <span
          class="error_msg"
          *ngIf="startdate_required"
          style="position: absolute; top: 100%; left: 0; width: 100%"
          >Please&nbsp;select&nbsp;start&nbsp;date</span
        >
      </div>
    </div>

    <div
      class="col-xl-2 col-lg-3 col-md-3 col-sm-12 mt-sm-n7 mt-0 py-0 apply-margin  my-xl-0 my-md-0 my-lg-0"
    >
      <div style="position: relative">
        <label class="form-control-label" style="margin-left: 0px"
          >End Date</label
        >
        <input
          placeholder="End Date"
          matInput
          readonly
          [matDatepicker]="picker2"
          class="form-control email-input select-placeholder text-truncate"
          style="height: 38px; padding: 1px; margin-left: -2px"
          [(ngModel)]="endDate"
          type="text"
          [min]="startDate"
          (dateChange)="
            dateChange($event); checkStartDate(); updateEndDateMin($event)
          "
          id="endDate"
        />

        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
          style="
            float: right;
            position: relative;
            bottom: 41px;
            margin-right: 2px;
          "
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <span
          class="error_msg"
          *ngIf="enddate_required"
          style="position: absolute; top: 100%; left: 0; width: 100%"
          >Please&nbsp;select&nbsp;end&nbsp;date</span
        >
      </div>
    </div>
      <!-- <div class="col-sm-2 mt-xl-n3" style="align-items: center;display: inline-flex;">
            <button type="button" class="btn searchBtn" (click)="searchTask()">Search</button>
          </div> -->
      <div class="col-md-6 col-lg-4 col-xl-2 col-sm-6 mt-3 button-align " style="margin-top:69px !important;">
        <!-- <button
          type="button"
          mat-raised-button
          color="primary"
          class="btn btn-primary search-btn"
          (click)="searchTask()"
        >
          Search
        </button> -->

        <button
          type="button"
          mat-raised-button
          class="btn res-timesheet"
          (click)="searchTask()" [disabled]="isdsbtnDisable"
        >
          <span>Search</span>
        </button>
        <button
          type="button"
          class="btn searchBtn  ml-2 mx-2 mt-2 mt-xl-0"
          (click)="resetForms()"
          [disabled]="isdsbtnDisable"
          style="
            background: none;
            color: black;
            border: 1px solid rgba(74, 70, 70, 0.566) !important;
            font-size: 16px;
            font-weight: 610;
            margin-left: 5px;
          "
        >
          Clear
        </button>
      </div>
    </div>
    <!-- </form> -->
  </div>

  <div>
    <!-- *ngIf="finalTaskList.length > 0" -->
    <div class="card-content" *ngIf="tableShow">
      <div class="card-body table-responsive">
        <!-- <ng-container *ngIf="taskList.length > 0;else elseProject"> -->
          <!-- *ngIf="finalTaskList.length > 5" -->
          <div class="row p-0 m-0 justify-content-end " *ngIf="finalTaskList.length > 5 && (finalTaskList | mytask : search).length > 0">
            <div
              class="col-xl-2 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-end align-items-end"
              style="margin-top: -15px;"
              *ngIf="(finalTaskList | mytask : search).length > 5"
            >
              <div class="select-pagination">
                <span>Show : </span>
                <select
                  class="select_list new-select_list"
                  [(ngModel)]="mySelect"
                  (change)="showChanges()"
                >
                  <option class="form-control" value="5">5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
                </select>
              </div>
            </div>
          </div>
          
        <table class="table" matSort (matSortChange)="sortData($event)" >
          <thead>
            <tr class="tr">
              <th style="text-align: center" scope="col">Sl.No</th>
              <th mat-sort-header="taskGroupName" scope="col">
                Task&nbsp;Group
              </th>
              <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
              <th scope="col">Task&nbsp;Code</th>
              <th
                mat-sort-header="taskStartDate"
                style="text-align: center"
                scope="col"
              >
                Start&nbsp;Date
              </th>
              <th
                mat-sort-header="taskEndDate"
                style="text-align: center"
                scope="col"
              >
                &nbsp;End&nbsp;Date&nbsp;
              </th>
              <th
                mat-sort-header="workhours"
                style="text-align: center"
                scope="col"
              >
                Total&nbsp;Hours
              </th>
              <th
                mat-sort-header="taskStatusName"
                style="text-align: center"
                scope="col"
              >
                Task&nbsp;Status
              </th>
              <th style="text-align: center" scope="col"    *ngIf="  this.isAdmin  ||
              this.isHumanResource ">Action</th>
            </tr>
          </thead>
          <tbody
            style="
              border-bottom: 1px solid #ddd;
              border-left: 1px solid #ddd;
              border-right: 1px solid #ddd;
            "
          >
            <tr
              *ngFor="
                let e of finalTaskList
                  | mytask : search
                  | paginate : { itemsPerPage: mySelect, currentPage: p };
                let i = index
              "
            >
              <td style="text-align: center" *ngIf="p">
                {{ (p - 1) * mySelect + (i + 1) }}
              </td>
              <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
              <td *ngIf="e.taskGroup != undefined">
                {{ e.taskGroup.taskGroupName }}
              </td>
              <td *ngIf="e.taskGroup == undefined">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--
              </td>
              <td   style="
              white-space: normal;
              overflow-wrap: break-word;
              word-break: normal;
              min-width: 250px !important;
            ">{{ e.taskName }}</td>

              <!-- <td>{{e.taskCode}}</td> -->
              <td class="text-center">
                <ng-container *ngIf="!e.taskCode; else showTaskCode">{{
                  "-"
                }}</ng-container>
                <ng-template #showTaskCode>{{ e.taskCode }}</ng-template>
              </td>

              <td style="text-align: center; padding-right: 25px">
                {{ e.taskStartDate }}
              </td>
              <td style="text-align: center; padding-right: 25px">
                {{ e.taskEndDate }}
              </td>
              <!-- <td style="text-align: center">
                {{ e.workhours?.toString().padStart(2, '0') }}:{{ e.workminutes?.toString().padStart(2, '0') }}:00

              </td> -->
              <td style="text-align: center">
                {{ (e.workhours !== undefined ? e.workhours?.toString().padStart(2, '0') : '00') }}:{{ (e.workminutes !== undefined ? e.workminutes?.toString().padStart(2, '0') : '00') }}:00
              </td>
              
              <!-- <td style="text-align: center">
                {% if e.workhours is defined and e.workminutes is defined %}
                  {% set hours = e.workhours | default(0) | string | pad(2, '0') %}
                  {% set minutes = e.workminutes | default(0) | string | pad(2, '0') %}
                  {{ e.workhours }}:{{ e.workminutes }}:00
                {% else %}
                  00:00:00
                {% endif %}
              </td> -->
              
              
              <td
                style="text-align: center; padding-right: 25px"
                [style.color]="taskStatus(e)"
              >
                {{ e.taskStatusName }}
              </td>
              <!-- *ngIf="
              user_type == 'HumanResource' ||
              user_type == 'Manager' ||
              user_type == 'TeamLeader' ||
              user_type == 'Admin'
            " -->
              <td
              *ngIf="  this.isAdmin  ||
              this.isHumanResource "
         
                class="more"
                align="center"
                style="text-align: center; padding-right: 25px"
              >
                <span
                  class="material-icons"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  more_vert
                </span>
                <mat-menu #menu="matMenu">
                  <button
                  
                    mat-menu-item
                    class="mat-menu-item"
                    (click)="edit(e)"
                    style="
                      padding-top: 12px !important;
                      padding-left: 21px !important;
                    "
                  >
                    <mat-icon class="edit-icon">create</mat-icon>
                    <span class="edit-form">Edit</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
            <!-- <tr *ngIf="(finalTaskList | mytask : search).length === 0">
              <td
                colspan="/* number of columns */"
                style="    text-align: center;
                position: relative;
                left: 425px;
                font-size: 19px;
                font-weight: 600;"
              >
                  Add Task Not Found
              </td>
            </tr> -->
            <tr >
              <td style="text-align: center; font-size: larger; font-weight: bold;" 
            colspan="9"  *ngIf="nodata || (finalTaskList | mytask : search).length === 0 && search || finalTaskList.length == 0 " >  Add Task Not Found!</td>
            </tr>
          </tbody>
        </table>
        <!-- <div
          class="col-md-12 text-right"
          *ngIf="
            finalTaskList.length > 5 &&
            (finalTaskList | mytask : search).length > 0
          "
          style="padding-right: 0px"
        >
          <pagination-controls style="    position: relative;
          float: inline-end;"
            (pageChange)="p = $event"
            align="end"
          ></pagination-controls>
        </div> -->
        <div
      class="pagination-content"
      *ngIf="finalTaskList.length > 5 && (finalTaskList | mytask : search).length > 0"
      id="page"
    >
      <pagination-controls  *ngIf="(finalTaskList | mytask : search).length > 5"
        style="position: relative; float: inline-end"
        (pageChange)="p = $event"
        align="end"
      ></pagination-controls>
    </div>
        <!-- <pagination-controls (pageChange)="p = $event; beforesearchpage  = $event"
            *ngIf="finalTaskList.length > 5" align="end"></pagination-controls> -->
        <!-- </ng-container> -->
      </div>
    </div>
    <!-- <div class="card-content" *ngIf="nodata">
      <div class="card-body">
        <div class="alert alert-primary">
          <h4
            align="center"
            style="
                 font-size: 27px;
    font-weight: 400;
    font-family: 'Mulish', sans-serif;
    margin-top: 5px;
            "
          >
            Add Task Not Found
          </h4>
        </div>
      </div>
    </div> -->
    <!-- <mat-card class="card-content-nodata mat-card-nodata"    *ngIf="nodata">
      <div class="card-body-nodata">
        <div class="alert-nodata alert-primary">
          <h4 align="center" class="h4"> Add Task Not Found</h4>
        </div>
      </div>
    </mat-card> -->
  </div>
</mat-card>

<!-- 
      <div *ngIf="user_type != 'HumanResource' && user_type != 'Manager' ">
        <mat-card class="card-content">
          <div class="card-body table-responsive">
             <ng-container *ngIf="taskEmpList.length > 0;else elseProject">
              <table class="table table-bordered">
                <thead>
                  <tr class="tr">
                    <th style="text-align: center;" scope="col">Sl.No
                    </th>
                    <th scope="col">Task&nbsp;&nbsp;Name</th>
                    <th scope="col">Start&nbsp;&nbsp;Date</th>
                    <th scope="col">End&nbsp;&nbsp;Date</th>
                    <th  style="text-align: center;" scope="col">Total&nbsp;&nbsp;Hours</th>
                    <th  style="text-align: center;" scope="col">Task&nbsp;&nbsp;Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let e of taskEmpList | task:search |paginate:{itemsPerPage:4,currentPage:p};  let i = index">
                    <td *ngIf="p" style="text-align: center;" >{{((p-1)*4)+(i+1)}}</td>
                    <td  style="text-align: center;"  *ngIf="!p">{{i+1}}</td>
                    <td>{{e.taskName}}</td>
                    <td>{{e.taskStartDate | date}}</td>
                    <td>{{e.taskEndDate | date}}</td>
                    <td  style="text-align: center;" >{{e.workhours}}:{{e.workminutes}}</td>
                    <td  style="text-align: center;" [style.color]="taskStatus(e)">{{e.taskStatusName}}</td>
                  </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event ; changepage = $event"
            *ngIf="taskEmpList.length > 4" align="end"></pagination-controls>
             </ng-container>
  
              <ng-template #elseProject>
                <div class="alert alert-primary">
                  <h3 align="center">Task Not Found</h3>
                </div>
              </ng-template>
          </div>
          </mat-card>
      </div> -->
<!-- </div> -->
<!-- </div> -->


<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ successMessage }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <!-- <button
      *ngIf="importerror"
      (click)="showerrormessage()"
      class="btn btn-danger"
    >
      View Error Records
    </button> -->
</div>
<div
  *ngIf="loader "
  class="align-items-center text-center"
>
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
