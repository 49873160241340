<!-- <div class="sub-header">
    <div class="col-md-12 mb-3">
        <div class="row">
            <div class="">
                <span class="h5 sub-content">Modules</span>
            </div>
            <div class="sub-header-buttons">
                <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
              <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
              <a routerLink="/home/add-employee" mat-raised-button title="Add Employee" class="ml-2">
                  Add Employee
              </a> 
            </div>
        </div>
    </div>
</div>-->
<div class="common-content">
    <div class="home-cards">
        <div class=" db-card">
            <h1>Payroll</h1>
            <ul *ngFor="let row of results"  >
                    <li>
                        <a  *ngIf="checkmenudata(row.reportName)" (click)="selectReports(row.routerlink)" class="text-primary1" title="{{row.reportName}}" routerLinkActive="active">   {{row.reportName}} </a>
                    </li>
            </ul>
        </div>
    </div>

</div>

<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div> --> 