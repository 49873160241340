<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>{{menuId ? "Edit" : "Add"}} Menu</h3>

    <mat-dialog-content class="mat-typography">
        <!--  <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Module Name <span class="star">*</span></label>
                <select formControlName="moduleId" id="moduleId" class="form-select form-select-solid form-select-lg">
                    <option value="">- Select -</option>
                    <option *ngFor="let row of modulesLov; let i=index;" value="{{row.moduleId}}">{{row.moduleName}}</option>
                </select>
                <div class="valid-msg" *ngIf="(validate.moduleId.invalid && validate.moduleId.touched) || validate.moduleId.dirty || submitted">
                    <span *ngIf="validate.moduleId.errors?.required" class="text-danger">Please select module</span>
                   //Old- hidden  <span *ngIf="moduleNameExistStatus" class="span-error"> Menu name already exist!</span>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Menu Name <span class="star">*</span></label>
                <input type="text" formControlName="menuname" (keyup)="checkExistMenuName($event)" id="menuname" [ngClass]="{ 'is-invalid': submitted }" autocomplete="off" class="form-select form-select-solid form-select-lg" value="" placeholder="Menu Name">
                <div class="valid-msg" *ngIf="(validate.menuname.invalid && validate.menuname.touched) || validate.menuname.dirty || submitted">
                    <span *ngIf="validate.menuname.errors?.required" class="text-danger">Please enter menu name</span>
                    <span *ngIf="menuNameExistStatus" class="span-error">Menu name already exist!</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Menu Description</label>
                <textarea formControlName="menuDescription" id="menuDescription" autocomplete="off" class="form-select form-select-solid form-select-lg" placeholder="Menu Description"></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>
