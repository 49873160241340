import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { redirectMsgTimeOut, responseMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';
import { VariablepayqualiferComponent } from '../variablepayqualifer/variablepayqualifer.component';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-variablepayqualiferlist',
  templateUrl: './variablepayqualiferlist.component.html',
  styleUrls: ['./variablepayqualiferlist.component.scss']
})
export class VariablepayqualiferlistComponent implements OnInit {

  public searchFilter: any = '';
  edit_data: any;
  loader: boolean;
  vpqExistStatus: any = false;
  noRecordsFound:boolean=true;
search: any;
mySelect: any=5;
tableShow: boolean=false;
btnDisable: boolean=false;
btnDisables: boolean=false;
employeetData:any
  constructor(
    private settingsservice: SettingsService,
    private router:Router,
    private dialog:MatDialog, 
    private formBuilder: FormBuilder,
   
  )
  { }
 

  resultData : any = [];
  p:number = 1;
  collection: any[];
  pagePerItem:any=5;
  tableSearch : any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  sortedData: any[];

  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;



  backdrop:any= false;

  clientId:any
  qualificationNameExistStatus:any;

  qualificationId:any;
  usertype: any;
  organizationId: any;
  qualiferId: any;

  //Documnt ready function
  ngOnInit(): void
  {
   // this.variablepayqualiferList();
   this.noRecordsFound = true;
    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let userId = this.usertype.userId;
     this.organizationId = this.usertype.organization?.organizationId;
     this.clientId=this.usertype.employee?.client?.clientId
     
     
    //Post Form
    this.postForm=this.formBuilder.group({
      rating: [
        '',
        [Validators.required, Validators.pattern(/^(?:[0-4](?:\.\d+)?|5(?:\.0+)?)$/)]],
      percentage: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
    });
  
   
  }

  variablepayqualiferList()
  {
    console.log("this.search::",this.search);
    this.btnDisable=false
    
    if (this.search==undefined && (this.search == null || this.search == 'null')) {
      this.noRecordsFound = true;
      this.loader = false;
      alert("Please Select Element Name");
     return
      //  this.elementpercentageList();
    }
    else{
      this.btnDisable=true
      this.tableShow=false
    this.settingsservice.getvariablepayqualiferList( this.organizationId).subscribe((result: any) => {
      console.log("result from variable pay!!!");
      
       this.loader = false;
       this.tableShow=true;
       this.btnDisable=false
      if(result.statusCode != undefined)
      {
         if(result.statusCode == 204)
         {
          this.noRecordsFound = true;
          this.tableShow=true
         }
      }else{
        this.resultData = result;
        this.noRecordsFound = false;
      }
    
    },err =>{
      //this.errorMsg = err.error.message;
    })

    }
    
  }

  disableCopyPaste(event: ClipboardEvent): void {
    event.preventDefault();  // This disables cut, copy, and paste
}

disableRightClick(event: MouseEvent): void {
    event.preventDefault();  // This disables right-click (context menu)
}

allowOnlyNumbers(event: KeyboardEvent): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  
  // Allow backspace, tab, and delete keys
  if (charCode === 8 || charCode === 9 || charCode === 46) {
      return true;
  }
  
  // Allow numbers (0-9) and the dot (for decimal numbers)
  if ((charCode >= 48 && charCode <= 57) || charCode === 46) {
      return true;
  }
  
  // Prevent anything else (like alphabets and special characters)
  return false;
}

  changefirstpage() {
    this.p = 1;
  }
  clearValidation(){

this.postForm.reset();
this.submitted=false;
  }
  // convenience getter for easy access to form fields
  get validate() { return this.postForm?.controls }

  //Form Submit Create / Edit
  onSubmit(formDirective: FormGroupDirective) //form Submit Function
  {
    console.log("formvalues::",this.postForm.value);
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    let clientId=this.employeetData?.employee?.client?.clientId
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;
    let clientDetailStore=clientId?{clientId:clientId}:null;

    this.submitted = true;

    

    //stop here if form is invalid
    if (this.postForm.invalid || this.vpqExistStatus == true) {
        this.loader =false;
      return;}

    if(this.edit_data) //Edit
    {
      if(organizationId){
        let postValues = this.postForm.value;
        let id = this.edit_data.qualiferId;
  
        postValues['organization'] = organizationIstore  
        postValues['qualiferId'] = id;
        postValues['qualiferStatus'] = this.edit_data.qualiferStatus;
  
        this.backdrop = true;
        this.loader=true;
        this.btnDisables=true
  
        console.log("payload::",postValues);
        
  
        this.settingsservice.updatevarpayqualifer(id,postValues).subscribe((result: any) =>
        {
          // this.variablepayqualiferList();
          // this.getdata();
          this.loader=false
          this.btnDisables=true
  
        if(result.statusCode == 200)
        {
          this.success = true;
          this.loader=false;
          this.btnDisables=true
  
          this.sucess_msg = result.message;
          formDirective.resetForm();
          this.postForm.reset();
          // this.getdata()
          this.tableShow=false;
          this.submitted = false;
          this.edit_data = null;
          setTimeout(() => {this.success  = false;
            this.btnDisables=false
  
          },4000)
        }
        else if(result.statusCode > 400)
        {
          this.error = true;
          this.btnDisables=false
  
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, 4000)
        }
        
  
          
        }, err =>{
          this.error = true;
                  this.btnDisables=false
  
          this.error_msg = err.error.message;
          setTimeout(() => {this.error = false}, 4000)
          this.backdrop = false;
          this.loader = false;
         
        })

      }
      else{
        console.log("client Edit calling!!");
        
        let postValues = this.postForm.value;
        let id = this.edit_data.qualiferId;
  
        postValues['client'] = clientDetailStore
  
        postValues['qualiferId'] = id;
        postValues['qualiferStatus'] = this.edit_data.qualiferStatus;
  
        this.backdrop = true;
        this.loader=true;
        this.btnDisables=true
  
        console.log("payload::",postValues);
        
  
        this.settingsservice.updatevarpayqualifer(id,postValues).subscribe((result: any) =>
        {
          // this.variablepayqualiferList();
          // this.getdata();
          this.loader=false
          this.btnDisables=true
  
        if(result.statusCode == 200)
        {
          this.success = true;
          this.loader=false;
          this.btnDisables=true
  
          this.sucess_msg = result.message;
          formDirective.resetForm();
          this.postForm.reset();
          // this.getdata()
          this.tableShow=false;

          this.submitted = false;
          this.edit_data = null;
          setTimeout(() => {this.success  = false;
            this.btnDisables=false
  
          },4000)
        }
        else if(result.statusCode > 400)
        {
          this.error = true;
          this.btnDisables=false
  
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, 4000)
        }
        
  
          
        }, err =>{
          this.error = true;
                  this.btnDisables=false
  
          this.error_msg = err.error.message;
          setTimeout(() => {this.error = false}, 4000)
          this.backdrop = false;
          this.loader = false;
         
        })
      }
     
    }
    else //Create
    {
      if(organizationId){
        console.log("organization creation calling!");
        
        let postValues = this.postForm.value;
        postValues['organization'] = organizationIstore
       /*  if(this.postForm.valid &&  this.qualificationNameExistStatus == true)
        {
          console.log("if condition")
          return
        } */
          console.log("payload::",postValues);
  
          // return
       this.btnDisables=true
  
        this.settingsservice.createvarpayqualifer(postValues).subscribe((result: any) =>
        {
  
          // this.variablepayqualiferList();
          this.btnDisables=true
  
        if(result.statusCode == 200)
        {
          this.btnDisables=true
          this.btnDisables=false
  
          this.success = true;
          this.sucess_msg = result.message;
          this.submitted = false;
          this.postForm.reset();
          // this.getdata()
  
          setTimeout(() => {this.success  = false;this.btnDisables=false
            // this.getdata()
            this.tableShow=false;

  
          },4000)
        }
        else if(result.statusCode > 400)
        {
          this.btnDisables=false
  
          this.error = true;
          this.btnDisables=false
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, 4000)
        }
        
        // this.getdata();
  
  
        
        }, err =>{
          this.error = true;
          this.error_msg = err.error.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          this.backdrop = false;
          this.loader = false;
        
        })
      }
      else{
        console.log("client creation calling!!");
        
        let postValues = this.postForm.value;
        postValues['client'] = clientDetailStore
       /*  if(this.postForm.valid &&  this.qualificationNameExistStatus == true)
        {
          console.log("if condition")
          return
        } */
          console.log("payload::",postValues);
  
          // return
       this.btnDisables=true
  
        this.settingsservice.createvarpayqualifer(postValues).subscribe((result: any) =>
        {
  
          // this.variablepayqualiferList();
          this.btnDisables=true
  
        if(result.statusCode == 200)
        {
          this.btnDisables=true
          this.btnDisables=false
  
          this.success = true;
          this.sucess_msg = result.message;
          this.submitted = false;
          this.postForm.reset();
          // this.getdata()
  
          setTimeout(() => {this.success  = false;this.btnDisables=false
            // this.getdata()
            this.tableShow=false;

  
          },4000)
        }
        else if(result.statusCode > 400)
        {
          this.btnDisables=false
  
          this.error = true;
          this.btnDisables=false
          this.error_msg = result.message;
          setTimeout(() => {this.error = false}, 4000)
        }
        
        // this.getdata();
  
  
        
        }, err =>{
          this.error = true;
          this.error_msg = err.error.message;
          setTimeout(() => {this.error = false}, redirectMsgTimeOut)
          this.backdrop = false;
          this.loader = false;
        
        })
      }
     
    }
  }

  clearTable() {
    this.noRecordsFound = true;
    console.log('clear')
     this.resultData.length = 0;
     this.tableSearch = null;
     this.noRecordsFound  =true;
     this.tableShow=false;
     this.search=''
    // this.searchForm.clearValidators;
      //this.variablepayqualiferList();
  }
  getdata()
  {
      console.log("search::",this.search);
      // console.log("this.tableSearch==null ",this.tableSearch==null);
      
      
      if(!this.search || this.search.trim() === "")
      {
        console.log("if tablesearch came!!!!");
        this.loader=false;
        this.tableShow=false;

        alert("Please type to Search Either Rating or Percentage!")
        return
      }
      // else if(this.search==null){
      //   this.tableShow=false;
      // }
      else
      {
        this.loader = true;
        this.tableShow=false
        this.btnDisable=true

        if(this.organizationId){
          this.settingsservice.getvariblepayqualiferratinglike(this.search.trim() ,  this.organizationId).subscribe((result: any) => {
            console.log("result::",result);
            this.loader=false
            this.tableShow=true;
            this.btnDisable=false;
  
            // this.variablepayqualiferList();
  
            
            if(result.statusCode != undefined) 
            {     
               if(result.statusCode == 204)
               {
                this.noRecordsFound = true;
                this.btnDisable=false;
  
                this.resultData.length = 0;
               }
            }else{
  
              this.noRecordsFound = false;
              this.resultData = result;
            }
            this.loader = false;
  
          }
          ,err =>{
            console.log("error came!!!");
            
            //this.errorMsg = err.error.message;
          })
  
        }
        else{
          this.settingsservice.getvariblepayqualiferratinglikebyClient(this.search.trim(),this.clientId).subscribe((result: any) => {
            console.log("result::",result);
            this.loader=false
            this.tableShow=true;
            this.btnDisable=false;
  
            // this.variablepayqualiferList();
  
            
            if(result.statusCode != undefined) 
            {     
               if(result.statusCode == 204)
               {
                this.noRecordsFound = true;
                this.btnDisable=false;
  
                this.resultData.length = 0;
               }
            }else{
  
              this.noRecordsFound = false;
              this.resultData = result;
            }
            this.loader = false;
  
          }
          ,err =>{
            console.log("error came!!!");
            
            //this.errorMsg = err.error.message;
          })
  
        }

        
      }
      // }
      //  else{
      //   console.log("else came !!!!");
        
      //    this.variablepayqualiferList();
      // }
  //  
  }
  
  checkExistQualificationName(val)
  {
    // if(val.target.value)
    // {
    //   if(this.edit_data)
    //   {
    //     this.vpqExistStatus =  this.resultData.some(e=> e.rating == val.target.value && e.qualiferId != this.edit_data.qualiferId);
    //     console.log("this.vpqExistStatus "+this.vpqExistStatus );
    //   }else{
    //     this.vpqExistStatus =  this.resultData.some(e=> e.rating == val.target.value);
    //     console.log("this.vpqExistStatus "+this.vpqExistStatus );
    //   }
    // }else{
    //   this.vpqExistStatus =false;
    // }
    // console.log("val" +val );
   
 
  }

  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(VariablepayqualiferComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });

    dialogRef.afterClosed().subscribe(result =>
    {
    //  this.variablepayqualiferList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
    });
  }

  clear(){
    this.postForm.reset();
    return;
  }

  updateform(editData)
  {
    this.edit_data = editData;
    this.qualiferId = this.edit_data.qualiferId;
    this.loader=true
      
    this.settingsservice.getvariblepayqualiferById(this.qualiferId).subscribe((resultData:any) =>
    {
      console.log("edit response::",resultData);
      this.loader=false

      
      this.resultData=resultData;

      this.postForm.patchValue({
        rating: this.resultData.rating,
        percentage: this.resultData.percentage,
      });
    });

   
  }

  pageChages()
  {
    console.log("page changes");
    this.p=1;
  }


  updateStatus(id,status)
	{
    this.loader=true
    this.settingsservice.updatequlafierStatus(id,status).subscribe( (result:any) =>
		{
      this.loader=false

      if(result.statusCode == 200)
      {
        this.success = true;

				this.sucess_msg = result.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
         this.getdata();
     //   this.variablepayqualiferList();
      }
      else
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}

  /**** Global Variables *****/
  ucFirst(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
  /**** Global Variables *****/

  sortData(sort: Sort) 
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') 
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => 
    {
      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) 
      {
        case 'streamName':
          return compare(a.streamName, b.streamName, isAsc);

        default:
          return 0;
      }
    });
  }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) 
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


