<!-- Sub header start here-->
<!-- <section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Business Unit</span>
        </div>
      </div>
      <button
        (click)="Add_businessunit()"
        mat-raised-button
        class="ml-2 btndesign"
      >
        Add Business Unit
      </button>
    </div>
  </div>
</section> -->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content">Business Unit</span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
        
          <a
          (click)="Add_businessunit()"
          
            mat-raised-button
            class="ml-2 searchBtn"
          >
          Add Business Unit
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-md-12">
            <form [formGroup]="searchForm" >
              <div class="row mb-3 pl-0">
                <div class="form-group col-md-3">
                  <label class="form-control-label">Business Unit Name</label>
                  <input
                    formControlName="businessunitName"
                    type="text"
                    autocomplete="off"
                    (keydown)="clearall($event)"
                    (input)="validateInput($event)"
                    class="form-control email-input select-placeholder"
                    value=""
                    placeholder="Business Unit Name"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label class="form-control-label">Legal Entity Name </label>
                  <input
                    formControlName="legalEntityname"
                    type="text"
                    autocomplete="off"
                    (keydown)="clearall($event)"
                    (input)="validateInput($event)"
                    class="form-control email-input select-placeholder"
                    value=""
                    placeholder="Legal Entity Name"
                  />
                </div>
                <div class="form-group col-md-3" style="top: 23px">
                 
                  <button type="submit" mat-raised-button class="searchDesign" 
                  [disabled]="btnDisabled"
                  (click)="searchSubmit()">
                    Search
                  </button>
                   <button
                    type="reset"
                    mat-raised-button
                    [disabled]="btnDisabled"
                    class="mr-2 clearDesign"
                    (click)="clearData()"
                  >
                    Clear
                  </button>
                  
                </div>
              </div>
            </form>
            <div class="float-end" *ngIf="tableShow">
              <div class="row rowDesignshow" *ngIf="resultData?.length > 0">
                <div
                  class="select-pagination"
                  style="
                  
                    margin-left: 15px;
                    margin-bottom: 10px;
                  "
                  *ngIf="resultData?.length > 5"
                >
                  <span class="showPage showDesign" style="    position: relative;
    right: 5px;">Show</span>
                  <select
                    class="select_list new-select_list listDesign"
                    [(ngModel)]="mySelect"
                
                    (change)="changefirstpage()"
                  >
                    <option class="form-control" value="5">5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid_view"  *ngIf="tableShow">
          <!-- <div class="row"> -->
          <!-- <div class="col-md-12"> -->
          <div class="table">
            <table matSort  class="table">
              <thead class="t-head">
                <tr id="trDesign">
                  <!-- <th class="text-center">S.No</th> -->
                  <th
                    class="text-center"
                    style="border-radius: 15px 0px 0px 0px"
                  >
                    Action
                  </th>
                  <th class="text-center">Business Unit Name</th>
                  <th class="text-center">Legal Entity Name</th>
                  <th class="text-center">Business Start Date</th>
                  <th class="text-center">Location</th>

                  <th class="text-center" style="border-radius: 0px 15px 0px 0">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody
                style="
                  border-left: 1px solid #ddd;
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <tr
                  *ngFor="
                    let row of resultData
                      | paginate : { itemsPerPage: mySelect, currentPage: p }
              
                    let i = index
                  "
                >
                  <!-- <td class="text-center">{{i+1}}</td> -->
                  <td class="text-center" style="margin-left: -24px">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" class="controls-list">
                      <button
                        mat-menu-item
                        routerLink="/home/edit-business-unit/{{
                          row.businessUnitId
                        }}"
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="row.businessUnitStatus== 'Active'"
                        (click)="updateStatus(row.businessUnitId, 'In-active')"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: red">Inactive</span>
                      </button>

                      <button
                        mat-menu-item
                        *ngIf="row.businessUnitStatus == 'In-active'"
                        (click)="updateStatus(row.businessUnitId, 'Active')"
                      >
                        <mat-icon>done</mat-icon>
                        <span style="color: #50cd89 !important">Active</span>
                      </button>
                    </mat-menu>
                  </td>

                  <td class="text-center">{{row.businessUnitName}}</td>

                  <td class="text-center">{{row.legalEntity.legalEntityName}}</td>
                  <td class="text-center">{{ row.businessStartDate | date:'yyyy-MM-dd' }}</td>
                  <td class="text-center">{{row.location}}</td>

                  <td class="active-status text-center">
                    <span
                      class="btn btn-sm btn-block"
                      style="color: #50cd89 !important"
                      *ngIf="row.businessUnitStatus == 'Active'"
                    >
                      {{ row.businessUnitStatus }}
                    </span>

                    <span
                      class="btn btn-sm btn-block"
                      style="color: red"
                      *ngIf="row.businessUnitStatus == 'In-active'"
                    >
                      {{ row.businessUnitStatus }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody
                style="
                  border-left: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
                *ngIf="tableShow"
              >
                <tr>
                  <td
                    style="
                      text-align: center;
                      padding: 10px;
                      font-size: 19px;
                      font-weight: 600;
                      font-family: 'Mulish', sans-serif;
                      left: 5;
                      position: relative;
                    "
                    colspan="8"
                    *ngIf="nodatafound || resultData?.length == 0"
                  >
                    Business Unit Records Not Found!
                  </td>
                </tr>
              </tbody>
            </table>
           

            <!-- <div *ngIf="nodatafound" class="no-data"> -->
            <!-- <div *ngIf="resultData?.length == 0" class="no-data"> -->
            <!-- <img src="assets/uploads/nodata.png"> -->
            <!-- <span class="no-data-found"> No Data Found </span> -->
            <!-- </div> -->
          </div>
          <div
          *ngIf="
            resultData?.length > 5 &&
            (resultData | mytask:search)?.length > 0 &&
            tableShow
          "
        >
          <pagination-controls
            (pageChange)="p = $event"
            class="float-right pagination"
            style="float: right"
            *ngIf="(resultData | mytask:search).length > 5"
            align="end"
          ></pagination-controls>
        </div>
          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border table-spinner"
    style="
      height: 3rem;
      width: 3rem;
      color: #1a83ff;
      position: absolute;
      z-index: 1;
      top: 49%;
      right: 48%;
    "
    role="status"
  ></div>
</div>
<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
