import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { redirectMsgTimeOut } from 'src/app/providers/properties';
import { SettingsService } from 'src/app/providers/settings.service';

@Component({
  selector: 'app-finanicalyear',
  templateUrl: './finanicalyear.component.html',
  styleUrls: ['./finanicalyear.component.scss']
})
export class FinanicalyearComponent implements OnInit {

  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  postForm: FormGroup;
  resultData: any;
  financialyearId: any;
  selected :true;
  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  branchlist: any = [];
  years: number[] = [];
  months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  usertype: any;
  organizationId: any;
  yearinvalid: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private ActivatedRoute:ActivatedRoute,
    ) { }

  ngOnInit(): void
  {

    this.usertype = JSON.parse(localStorage.getItem('enoteUserData'));

    let userId = this.usertype.userId;
     this.organizationId = this.usertype.organization.organizationId;
     
    this.postForm = this.formBuilder.group({
      branch : ['',[Validators.required]],
      yearfrom : ['',[Validators.required]],
      yearto : ['',[Validators.required]],
        monthfrom : ['',[Validators.required]],
        monthto : ['',[Validators.required]],
        description : ['',[Validators.required]],
     });

     this.postForm.patchValue({
      branch: null,
      yearfrom :null,
     yearto :null,
    monthfrom : null,
    monthto : null,
    
    });

  
    const currentYear = new Date().getFullYear()-1;
    const futureYears = 5; // Number of future years to include

    for (let i = 0; i <= futureYears; i++) {
      this.years.push(currentYear + i);
    }
  


     //console.log(this.ActivatedRoute.snapshot.params.branchId);
     this.settingsservice.getBranchList(this.organizationId).subscribe((resultData:any) =>
     {
       this.branchlist=resultData;

       
     });

     this.financialyearId = this.ActivatedRoute.snapshot.params.financialyear;

     if(this.financialyearId)
    {
      this.settingsservice.getFinancialYearId(this.financialyearId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          branch: this.resultData.branch.branchId,
          yearfrom :this.resultData.financialFromYear,
         yearto :this.resultData.financialToYear,
        monthfrom : this.resultData.financialFromMonth,
        monthto : this.resultData.financialToMonth,
        description : this.resultData.financialDescription,
        });
      });
    }
  }
  get validate() { return this.postForm.controls; }

  validateyear()
  {
    console.log("function called")
     if( this.postForm.value.yearfrom && this.postForm.value.yearto)
     {
         if(this.postForm.value.yearto <  this.postForm.value.yearfrom)
         {
             console.log("to year invalid")
             this.yearinvalid = true;
         }else{
               this.yearinvalid = false;
         }
     }
  }



  onSubmit()
  {
    this.submitted = true;

    if (this.postForm.invalid || this.yearinvalid) {
      console.log("invalid")
        return;
    }

    if(this.financialyearId) //Update Finacialyear
    {
      let postValues = this.postForm.value;
      

      console.log(postValues);

       let finanicalyeardata = 
       {
        financialYearId : this.resultData.financialYearId,
         financialFromMonth: postValues.monthfrom,
         financialFromYear: postValues.yearfrom,
         financialToMonth: postValues.monthto,
         financialToYear: postValues.yearto,
         financialDescription: postValues.description,
         branch: {
         branchId: postValues.branch,
         },   
         organization : {organizationId: this.organizationId}        ,
         financialYearStatus :this.resultData.financialYearStatus
         }

         console.log(finanicalyeardata);
        this. settingsservice.updateFinancialYear(this.financialyearId , finanicalyeardata).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.success = true;
            this.sucess_msg = result.message;
            //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
            //this.router.navigate(['/home/employee']);

            setTimeout(() => {
              this.router.navigate(['/home/financialyear']);
            }, redirectMsgTimeOut)
          }
          else
          {
            this.error = true;
            this.error_msg = result.message;
            //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

            setTimeout(() => {
              this.router.navigate(['/home/financialyear']);
            }, redirectMsgTimeOut)
          }

 }, err =>
 {
     this.errorMsg = err.error.message;

 })

     
    }
    else  //Add Finacialyear
    {
        let postValues = this.postForm.value;
      

           console.log(postValues);

            let finanicalyeardata = 
            {
              
              financialFromMonth: postValues.monthfrom,
              financialFromYear: postValues.yearfrom,
              financialToMonth: postValues.monthto,
              financialToYear: postValues.yearto,
              financialDescription: postValues.description,
              branch: {
              branchId: postValues.branch,
              },   
              organization : {organizationId: this.organizationId}          
              }

              console.log(finanicalyeardata);
      this. settingsservice.createFinancialYear(finanicalyeardata).subscribe((result: any) =>
      {
        if(result.statusCode == 200)
        {
          this.success = true;
          this.sucess_msg = result.message;
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);

          setTimeout(() => {
            this.router.navigate(['/home/financialyear']);
          }, redirectMsgTimeOut)
        }
        else
        {
          this.error = true;
          this.error_msg = result.message;
          //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
            this.router.navigate(['/home/financialyear']);
          }, redirectMsgTimeOut)
        }

      }, err =>
      {
          this.errorMsg = err.error.message;

      })
    }


  }

  


}
