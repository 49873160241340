<div
  style="position: relative; height: 64px; visibility: hidden"
></div>
<mat-card id="exepensecard"
  style="
       margin-top: 20px;
    border-radius: 15px;
    border: 1px solid var(--Card-Border-Color, rgba(194, 194, 194, 0.5));
    background: #fdfdfd;
    padding: 16px;
  "
>
  <div class="row" >
    <div class="col-md-6 resource-title">
      <h2 class="mainDesign">{{ data ? "Update" : "Create" }} Expense</h2>
    </div>
  </div>
  <div class="card card-content" >
    <form [formGroup]="expenseform" style="    position: relative;
    left: -19px;">
      <div class="row" style="margin-top: -50px; padding: 19px 12px 19px 29px">
        <div class="col-md-6">
          <div class="d-flex">
            <div class="label-name">
              <label class="form-control-label">Employee Name</label>
            </div>
            <div>:</div>
            <div>
              <label class="form-control-label-class">{{
                employeedata?.employee?.firstName
              }}</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex">
            <div class="label-name">
              <label class="form-control-label">Employee Code</label>
            </div>
            <div>:</div>
            <div>
              <label class="form-control-label-class">{{
                employeedata?.employee?.employeeNumber
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-12"
          style="padding: 14px 12px 10px; margin-left: 29px; margin-top: -27px"
        >
          <div class="d-flex">
            <div class="label-name">
              <label class="form-control-label">Email</label>
            </div>
            <div>:</div>
            <div>
              <label class="form-control-label-class">{{
                employeedata?.employee?.organizationEmail
              }}</label>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6">
                    <div class="d-flex">
                        <div>
                            <label class="form-control-label">Approver Name</label>
                        </div>&nbsp;&nbsp;
                        <div>:</div>&nbsp;&nbsp;
                        <div>
                            <label class="form-control-label-class">{{employeedata.approver.employeeName}}</label>
                        </div>
                    </div>
                </div> -->
      </div>
      <div
        class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
        style="margin-left: 15px"
      >
        <div class="col">
          <label class="form-control-label"
            >From Date <span class="text-danger">*</span></label
          >
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="startdate"
            placeholder="Enter From Date"
            readonly
            (dateChange)="setendate($event)"
            class="form-control email-input select-placeholder no-border-on-focus"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            class="targetClass"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <div *ngIf="submitted && startdate.invalid">
            <small *ngIf="startdate.errors?.required" class="text-danger"
              >Please Select From Date</small
            >
          </div>
        </div>
        <div class="col">
          <label class="form-control-label"
            >To Date <span class="text-danger">*</span></label
          >
          <input
            matInput
            [matDatepicker]="picker1"
            formControlName="enddate"
            readonly
            placeholder="Enter To Date"
            [min]="min"
            (dateChange)="setmaxendate($event)"
            class="form-control email-input select-placeholder no-border-on-focus"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
            class="targetClass"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <div *ngIf="submitted && enddate.invalid">
            <small *ngIf="enddate.errors?.required" class="text-danger"
              >Please Select To Date</small
            >
            <small
              *ngIf="expenseform.controls.enddate.errors?.pattern"
              class="text-danger"
              >Valid To Date</small
            >
          </div>
          <small *ngIf="isenddate_Valid" class="text-danger"
            >Please Select Valid To Date</small
          >
        </div>
        <!-- <div class="col-md-3">
                    <label class="form-control-label">Reimbursement type <span class="text-danger">*</span></label>
                    <mat-select
                        class="form-control email-input select-placeholder"
                        formControlName="expenseType"
                        placeholder="Select Element"
                        >
                        <mat-option class="text-color " [value]="undefined" selected>- Select Element -
                        </mat-option>
                        <mat-option ></mat-option>
                    </mat-select>
                    <div *ngIf="(submitted && expenseType.invalid)">
                        <small *ngIf="expenseType.errors?.required" class="text-danger">Please select expense type</small>  
                    </div> 
                </div> -->
        <!-- <div class="col-md-3" *ngIf="showprojectname">
                    <label class="form-control-label">Project Name <span class="text-danger">*</span></label>
                    <input type="text" 
                        class="form-control email-input select-placeholder"
                        formControlName="projectName"
                        placeholder="Enter project name">
                        <div *ngIf="(submitted && projectName.invalid) || projectName.dirty">
                            <small *ngIf="projectName.errors?.required" class="text-danger">Please enter project name</small>  
                            <small *ngIf="expenseform.controls.projectName.errors?.pattern" class="text-danger">Please enter valid project name</small> 
                        </div> 
                </div> -->
        <div class="col">
          <label class="form-control-label"
            >Expense Type <span class="text-danger">*</span></label
          >
          <mat-select
            style="padding: 3px 7px 6px 7px"
            class="form-control email-input select-placeholder no-border-on-focus"
            formControlName="expenseType"
            placeholder="Select Expense Type"
            (selectionChange)="changeexpensetype($event)"
          >
            <mat-option
              *ngFor="let expensetype of expensetypelist"
              [value]="expensetype.listTypeValueId"
              >{{ expensetype.listTypeValueName }}</mat-option
            >
          </mat-select>
          <div *ngIf="submitted && expenseType.invalid">
            <small *ngIf="expenseType.errors?.required" class="text-danger"
              >Please Select Expense Type</small
            >
          </div>
        </div>
        <div class="col" *ngIf="showprojectname">
          <label class="form-control-label"
            >Project Name <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control email-input select-placeholder no-border-on-focus"
            formControlName="projectName"
            placeholder="Enter Project Name"
          />
          <div *ngIf="(submitted && projectName.invalid) || projectName.dirty">
            <small *ngIf="projectName.errors?.required" class="text-danger"
              >Please Enter Project Name</small
            >
            <small
              *ngIf="expenseform.controls.projectName.errors?.pattern"
              class="text-danger"
              >Please Enter Valid Project Name</small
            >
          </div>
        </div>

        <!-- <div class="col-md-6"> -->
        <!-- <button mat-raised-button type="submit" 
                        (click)="addRow()"
                        class="btn create-button btn-res">Add Row</button> -->
        <!-- </div> -->
        <div class="col">
          <label class="form-control-label"
            >Reimbursement Type <span class="text-danger">*</span></label
          >
          <mat-select
          class="form-control email-input select-placeholder no-border-on-focus"
          style="padding: 3px 7px 6px 7px"
          (selectionChange)="listTypeValueid($event)"
          appearance="fill"
          formControlName="reimbursementType"
          placeholder="Select Element"
          [disabled]="setHide"
          [ngClass]="{'hide-dropdown-icon': isEditing}"
          >
            <mat-option class="text-color" (click)="setElement()" [value]="null"   
              >- Select Element - 
            </mat-option>
            <mat-option
              *ngFor="let e of advanceTypeData"
              class="text-color"
              [value]="e?.listTypeValueId"
              >{{ e?.listTypeValueName }}</mat-option
            >
          </mat-select>
          <div
            *ngIf="
              (submitted && reimbursementType.invalid) ||
              reimbursementType?.dirty
            "
          >
            <small
              *ngIf="reimbursementType.errors?.required"
              class="text-danger"
              >Please Select Reimbursement Type</small
            >
          </div>
        </div>
      </div>
      <!-- <div class="col-md-2" style=" color:#525252;font-family: 'Mulish', sans-serif;
        margin-left: 22px;">
            <label class="col-form-label">Reimbiursement Type </label>
            <mat-select class="mat-select form-control email-input select-placeholder"
                (change)="listTypeValueid($event)" appearance="fill"
                formControlName="reimbursementType" placeholder="Select Element">
                <mat-option class="text-color " [value]="undefined" selected>- Select Element -
                </mat-option>
                <mat-option *ngFor="let e of advanceTypeData" class="text-color"
                    [value]="e.listTypeValueId">{{e.listTypeValueName}}</mat-option>
            </mat-select>
            
        </div> -->

      <div class="row pt-3" style="    margin-top: -6px;
    margin-left: 12px;">
        <!-- <div class="col-md-3">
                        <label class="form-control-label">Business Purpose <span class="text-danger">*</span></label>
                        <input type="text" 
                            class="form-control email-input select-placeholder"
                            formControlName="businessPurpose"
                            placeholder="Enter Business Purpose">
                            <div *ngIf="(submitted && businessPurpose.invalid)">
                                 <small *ngIf="businessPurpose.errors?.required" class="text-danger">Please enter business purpose</small>
                            </div> 
                    </div> -->
        <div class="col-md-6 example-chip-list" appearance="fill">
          <label class="form-control-label" style="position: relative; top: 4px"
            >Business Purpose <span class="text-danger">*</span></label
          >
          <!-- <mat-chip-list #chipList aria-label="Fruit selection" class=" form-control select-placeholder email-input custom-chip-list" placeholder="Business Purpose"
                         style="min-height: 79px; margin: 7px;
                         /* width: 43px; */
                         overflow-y: scroll;overflow-x: hidden;
                         /* padding: 0; */">
                          <mat-chip *ngFor="let fruit of skillsarray" [selectable]="selectable"
                                   [removable]="removable" 
                                   (removed)="remove(fruit)">
                            {{fruit.name}}
                            <mat-icon matChipRemove *ngIf="removable" style="color: red !important
                            ;" >cancel</mat-icon>
                          </mat-chip>
                          <input 
                                 [matChipInputFor]="chipList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 [matChipInputAddOnBlur]="addOnBlur"
                                 (matChipInputTokenEnd)="add($event)"
                                 class="chip-input"
                                 formControlName="businessPurpose">              
                        </mat-chip-list> -->

          <mat-chip-list
            #chipList
            aria-label="Fruit selection"
            class="form-control email-input select-placeholder custom-scrollbar"
            style="height: fit-content; position: relative; top: 8px"
          >
            <mat-chip
              *ngFor="let fruit of skillsarray"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(fruit)"
              style="height: auto"
            >
              <span class="skill-input">{{ fruit.name }}</span>
              <mat-icon matChipRemove *ngIf="removable" style="color: red"
                >cancel</mat-icon
              >
            </mat-chip>
            <input
              placeholder="Business Purpose"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
              class="chip-input border-none"
              formControlName="businessPurpose"
              (input)="checkLength1()"
            />
          </mat-chip-list>
          <div *ngIf="businessPurpose?.touched || businessPurpose?.dirty || (submitted && skillsarray.length == 0)">
            <small *ngIf="skillsarray.length == 0" class="text-danger" style="position: relative; top: 7px;">
              Please Enter Business Purpose
            </small>
            <small *ngIf="businessPurpose?.errors?.['wordCountError'] &&submitted" class="text-danger">
              Please enter between 0 and 99 words.
            </small>
            <!-- <small *ngIf="descri.errors?.pattern" class="text-danger"
            >Please Enter Valid  Business Purpose</small> -->

          </div>
          <!-- <div *ngIf="businessPurpose?.errors?.['spaceError']">
            <small  class="text-danger" style=" position: relative;
                        top: 7px;">
              Please Enter Valid Business Purpose
            </small>
            </div> -->

          <!-- <div
    *ngIf="
       submitted && skillsarray.length == 0
    "
  >
    <small *ngIf="businessPurpose.hasError('wordCountError') && skillsarray.length == 0" class="text-danger">
      Please enter between 0 and 99 words.
    </small>
  </div> -->
  <!-- <div *ngIf="getTotalWordCount() > maxWords">
    <small class="text-danger">
      Please enter between 0 and 99 words.
    </small>
  </div> -->
          <!-- <div *ngIf="skills.touched|| skills.dirty|| (submmited && resourceform.controls['skills'].invalid)">
                            <small *ngIf="skills.errors?.required" class="text-danger">Please enter skills</small>
                     </div> -->
        </div>
        <div class="col-md-6" style="margin-top: 7px">
          <label class="form-control-label">Description</label>
          <textarea
            type="text"
            class="form-control email-input select-placeholder no-border-on-focus"
            formControlName="descri"
            (keydown)="noTab($event)"
            (input)="checkLength()"
            placeholder="Description"
            style="
              font-size: 15px;
              max-width: 87%;
              overflow-x: hidden;
              overflow-y: auto;
              min-height: 45px;
              border: none;
              border: 2px solid #dee2e6;
              padding: 3px 7px 6px 7px;
            "
          ></textarea>
          <div *ngIf="submitted && descri.invalid">
            <!-- <small *ngIf="descri.errors?.required" class="text-danger"
              >Please Enter Description</small
            > -->
            <small *ngIf="descri.errors?.pattern" class="text-danger"
            >Please Enter Valid Description</small
          >
          <small *ngIf="descri.errors?.maxlength" class="text-danger">
            Description must not exceed 99 characters
          </small>
          </div>
        </div>
      </div>
      <div class="" style="max-height: 228px">
        <table class="table table-border mb-0" style="    margin-left: 11px;">
          <thead class="text-nowrap" style="/*display: revert;*/">
            <th style="/*width:13%;*/ color: #000" class="tbl-th label-desgin">
              Date<span class="text-danger">*</span>
            </th>
            <th style="/*width:23%;*/" class="tbl-th">
              Description<span class="text-danger">*</span>
            </th>
            <th
              style="/*width:13%;text-align: left*/"
              class="tbl-th"
              style="width: 500px"
            >
              Category<span class="text-danger">*</span>
            </th>
            <th style="/*width:17%;text-align: left;*/" class="tbl-th">
              Amount<span class="text-danger">*</span>
            </th>
            <th style="/*width:27%;text-align: left;*/" class="tbl-th-att">
              Attachment
            </th>
            <th style="/*width:7%;text-align: center;*/" class="tbl-th-action">
              Action
            </th>
          </thead>
          <tbody class="text-nowrap" style="/*display: revert;*/">
            <ng-container
              formArrayName="tableRows"
              *ngFor="let group of reminder()?.controls; let i = index"
            >
              <tr
                *ngIf="group?.get('isEditable')?.value"
                [formGroupName]="i"
                style="vertical-align: middle"
              >
                <td style="/*width: 13%;*/" class="tbl-data">
                  <div class="datepicker-class">
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                      class="targetClass1"
                    >
                    </mat-datepicker-toggle>
                    <input
                      matInput
                      [matDatepicker]="picker2"
                      formControlName="date"
                      readonly
                      [min]="min"
                      [max]="max"

                      placeholder="Enter date"
                      [disabled]="startDate_disable"
                      class="form-control email-input select-placeholder no-border-on-focus select-placeholder1"
                    />
                    <mat-datepicker #picker2></mat-datepicker>
                    <div
                      *ngIf="
                        (submitted && group['controls'].date.invalid) ||
                        group['controls'].date.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].date.errors?.required"
                        class="text-danger"
                        >Please Select Date</small
                      >
                    </div>
                    <small *ngIf="isdate_Valid" class="text-danger"
                      >Please Select Valid Date</small
                    >
                  </div>
                </td>
                <td style="/*width: 23%;*/" class="tbl-data">
                  <div>
                    <input
                      type="text"
                      rows="1"
                      formControlName="description"
                      placeholder="Enter description"
                      maxlength="250"
                      class="form-control email-input select-placeholder no-border-on-focus"
                      (keydown)="noTab($event)"
                    />
                    <!-- </textarea> -->
                    <div
                      *ngIf="
                        (submitted && group['controls'].description.invalid) ||
                        group['controls'].description.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].description.errors?.required"
                        class="text-danger"
                        >Please Enter Description</small
                      >
                      <small
                        *ngIf="group['controls'].description.errors?.pattern"
                        class="text-danger" style="  
                        position: relative;
                        top: 7px;"
                        >Please Enter Valid Description</small
                      >
                    </div>
                  </div>
                </td>
                <!-- <td style="width: 11%;">
                                <div>
                                    <mat-select
                                        class="form-control email-input select-placeholder"
                                        formControlName="expenseType"
                                        placeholder="Select Type">
                                        <mat-option *ngFor="let expensetype of expensetypelist" [value]="expensetype.listTypeValueId">{{expensetype.listTypeValueName}}</mat-option>
                                    </mat-select>
                                    <div *ngIf="(submitted && group['controls'].category.invalid) || group['controls'].category.dirty">
                                        <small *ngIf="group['controls'].category.errors?.required" class="text-danger">Please select expenseType</small>  
                                    </div> 
                                </div>
                            </td> -->
                <td style="/*width: 13%;*/" class="tbl-data-cate">
                  <div>
                    <mat-select
                      class="form-control email-input select-placeholder no-border-on-focus selectDesign"
                      formControlName="category"
                      placeholder="Select Category"
                      style="width: 184px; padding: 3px 7px 6px 7px"
                    >
                      <mat-option
                        *ngFor="let e of categorylist"
                        [value]="e.expenseCategoryId"
                        >{{ e.expenseCategoryName }}</mat-option
                      >
                    </mat-select>
                    <div
                      *ngIf="
                        (submitted && group['controls'].category.invalid) ||
                        group['controls'].category.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].category.errors?.required"
                        class="text-danger"
                        >Please Select Category</small
                      >
                    </div>
                  </div>
                </td>
                <td
                  style="/*text-align: center; width: 17%;*/"
                  class="tbl-data"
                >
                  <div>
                    <input
                      type="text"
                      class="form-control email-input select-placeholder no-border-on-focus"
                      formControlName="amount"
                      maxlength="6"
                      placeholder="Enter Amount"
                      (input)="calucateamount(i)"
                      (keydown)="noNumber($event)"
                      (paste)="noPaste($event)"
                      style="text-align: right; padding: 3px 20px 6px 7px"
                    />
                    <div
                      *ngIf="
                        (submitted && group['controls'].amount.invalid) ||
                        group['controls'].amount.dirty
                      "
                    >
                      <small
                        *ngIf="group['controls'].amount.errors?.required"
                        class="text-danger"
                        >Please Enter Amount</small
                      >
                      <small
                        *ngIf="group['controls'].amount.errors?.pattern"
                        class="text-danger"
                        >Please Enter Valid Amount</small
                      >
                    </div>
                  </div>
                </td>
                <td
                  style="/*text-align: center;width:27%*/"
                  class="tbl-data-att"
                >
                  <div>
                    <input
                      type="file"
                      (change)="getFileDetails($event, i, group)"
                      class="form-control email-input fileDesign no-border-on-focus"
                      style="line-height: 1.7; font-size: 15px; padding: 3px"
                      formControlName="expenseAttachment"
                      accept=".png,.jpg,.jpeg,.pdf"
                    />

                    <div
                      *ngIf="
                        (submitted &&
                          group['controls'].expenseAttachment.invalid) ||
                        group['controls'].expenseAttachment.dirty
                      "
                    >
                      <small
                        *ngIf="
                          group['controls'].expenseAttachment.errors?.required
                        "
                        class="text-danger"
                        >Please upload expense attachment</small
                      >
                    </div>
                    <div style="display: flex; flex-direction: row">
                      <a
                        *ngIf="
                          group['controls'].expenseId.value != '' &&
                          !fileChoosen[i] &&
                          group.get('expenseAttachment').value != ''
                        "
                        [href]="
                          group.get('expenseAttachment').value.fileDownloadUrl
                        "
                        download
                        style="color:black;"
                      >
                        Download
                      </a>
                      <div
                        *ngIf="
                          group['controls'].expenseId.value != '' &&
                          !fileChoosen[i] &&
                          group.get('expenseAttachment').value != ''
                        "
                        style="
                          font-weight: 500;
                          color: rgb(98 98 98);
                          margin-left: 50px;
                        "
                      >
                        {{ group.get("expenseAttachment").value.fileName }}
                      </div>
                      <!-- Display the file name here -->
                    </div>
                  </div>
                </td>
                <td
                  style="/*text-align: center;width: 7%;padding-left: 16px;*/"
                  class="tbl-data-action"
                >
                  <span>
                    <mat-icon
                      class="material-icons-outlined addicon"
                      (click)="addRow()"
                      *ngIf=" i < 1"
                      style="color:black"
                      >add</mat-icon
                    >
                    <mat-icon
                      class="material-icons-outlined delete"
                      (click)="deleteRow(i, group)"
                      *ngIf="i > 0"
                      style="color: rgba(236, 19, 19, 0.81) !important"
                      >delete</mat-icon
                    >
                  </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="action-container">
        <div class="mt-2">
          <div class="row d-flex justify-content-end">
            <div class="col-md-4">
              <div class="d-flex">
                <div style="width: 65%; text-align: right">
                  <label class="form-control-label">Sub Total</label>
                </div>
                <!-- <span style="width:100px"></span> -->
                <div style="width: 5%; text-align: right">:</div>
                <div style="width: 25%; text-align: right">
                  <label
                    class="form-control-label-class"
                    style="text-align: right"
                    >{{ subTotal + ".00" }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-end">
            <div class="col-md-4">
              <div class="d-flex">
                <div style="width: 65%; text-align: right">
                  <label class="form-control-label">Cash Advance</label>
                </div>
                <!-- <span style="width:67px"></span> -->
                <div style="width: 5%; text-align: right">:</div>
                <div style="width: 25%; text-align: right">
                  <label
                    class="form-control-label-class"
                    style="text-align: right"
                    >{{ cashAdvance + ".00" }}</label
                  >
                  <!-- <label
                  class="form-control-label-class"
                  style="text-align: right"
                  >{{TotaladvanceAmount + ".00" }}</label
                > -->
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-end">
            <div class="col-md-4">
              <div class="d-flex">
                <div style="width: 65%; text-align: right">
                  <label class="form-control-label">Advance Balance</label>
                </div>
                <!-- <span style="width:48px"></span> -->
                <div style="width: 5%; text-align: right">:</div>
                <div style="width: 25%; text-align: right">
                  <label
                    *ngIf="totalRebusriment == 0"
                    class="form-control-label-class"
                    style="text-align: right"
                    >
                {{advancebalance + ".00"}}
                    <!-- {{ advancebalance - subTotal + ".00" }} -->
                      <!-- {{advancebalance}}--{{subTotal}} -->
                    </label
                  >
                  <label
                    *ngIf="totalRebusriment > 0"
                    class="form-control-label-class"
                    style="text-align: right"
                    >0.00</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-end">
            <div class="col-md-4">
              <div class="d-flex">
                <div style="width: 65%; text-align: right">
                  <label class="form-control-label">Total Reimbursement</label>
                </div>
                <!-- <span style="width:22px"></span> -->
                <div style="width: 5%; text-align: right">:</div>
                <div style="width: 25%; text-align: right">
                  <label
                    class="form-control-label-class"
                    style="text-align: right"
                    >{{ totalRebusriment + ".00" }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex" style="float: right">
            <div class="" *ngIf="draft_button">
              <button
                mat-raised-button
                class="btn create-button"
                type="submit"
                (click)="saveasdraft()"
                [disabled]="btnDisable"
              >
                {{ data ? "Update as Draft" : "Save as Draft" }}
              </button>
            </div>
            <div class="" *ngIf="submit_button">
              <button
                mat-raised-button
                class="btn create-button"
                type="submit"
                (click)="submitForm()"
                [disabled]="Approval_btnDisable"
              >
                {{ data ? "Update to Approval" : "Submit to Approval" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-card>

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
></div>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem;    color: #009ef7;
    position: absolute;
    z-index: 1;
    top: 65%;
    right: 48%;"
    role="status"
  ></div>
</div>
<div class="text-center" *ngIf="success" id="exepensecard">
  <div class="alert alert-success" role="alert" style="margin-top: -281px">
    <strong>Success!</strong><br />{{ create_sucess_msg }}
  </div>
</div>
<div
  *ngIf="error"
  class="alert alert-danger alert-dismissible"
  style="margin-top: -281px"
>
  <strong>Error!</strong><br />{{ update_error_msg }}
</div>
