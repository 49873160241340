import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import {  httpOptions } from './properties';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HrmsService {

  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ZW5vdGU6ZW5vdGUkaHJtcyM='
    })
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  //Login
  adminLogin(item): Observable<any> {
    return this.http.post(`${environment.base_url}user_Login`, item, this.httpOptions).pipe(map(data => data))
  }

  // Get single student data by ID
  /* getItem(id): Observable<any> {
    return this.http
      .get<any>(this.base_path + '/' + id)
      .pipe(
        // catchError(this.handleError)
      )
  } */

  // Get students data
  getList(): Observable<any> {
    return this.http
      .get<any>(`${environment.base_url}employee`, this.httpOptions)
      .pipe(map(data => data))
  }

  // Update item by id
  /*  updateItem(id, item): Observable<any> {
     return this.http
       .put<any>(this.base_path + '/' + id, JSON.stringify(item), this.httpOptions)
       .pipe(
         // catchError(this.handleError)
       )
   } */

  // Delete item by id
  /* deleteItem(id) {
    return this.http
      .delete<any>(this.base_path + '/' + id, this.httpOptions)
      .pipe(
      //  catchError(this.handleError)
      )
  } */


  createBusinessGroup(body: any): Observable<any> {
    let result = this.http.post(`${environment.base_url}businessGroup`, body, httpOptions);
    return result;
  }


  // getAllBusinessGroup() {
  //   let result = this.http.get(`${environment.base_url}businessGroup`, httpOptions);
  //   return result;
  // }
  getAllBusinessGroup(entityId:any) {
    let result = this.http.get(`${environment.base_url}businessGroup/entity/${entityId}`, httpOptions);
    return result;
  }

  keywordSearchOfBusinessGroup(keyword: any,entityId:any) {
    let result = this.http.get(`${environment.base_url}businessGroup/businessGroupName/${keyword}/entity/${entityId}`, httpOptions);
    return result;
  }

  getBusinessGroupById(businessGroupId: any) {
    let result = this.http.get(`${environment.base_url}businessGroup/${businessGroupId}`, httpOptions);
    return result;
  }


  updateBusinessGroup(businessGroupId:number,body:any) {
    let result = this.http.put(`${environment.base_url}businessGroup/${businessGroupId}`,body, httpOptions);
    return result;
  }

  searchBasedOnBusinessGroupandIndustryType(businessGroupName:any,industryTypeId:number,entityId:any){
    let result =  this.http.get(`${environment.base_url}businessGroupName/${businessGroupName}/industryType/${industryTypeId}/entity/${entityId}`,httpOptions);
    return result;
  }

  


}
