<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <div class="">
                        <span class="h5 sub-content pr-3">Add Employee Approvers</span>
                    </div>
                    <!-- <span routerLink="/home/kra-template" class="h5 sub-content">Add Branch</span> -->
                </div>
                <div class="sub-header-buttons">
                    <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Approver Rule <span class="star">*</span></label>
                            <select formControlName="approverHeaderId" placeholder="- Select -" class="form-select form-select-solid form-select-lg select2-new">
                                <option value="null">- Select -</option>
                               <!-- <option *ngFor="let position of positionlist" value="{{position.positionId}}">{{position.positionName}} - {{position.positionNumber}}</option>
                                -->
                            </select>
                            <div class="valid-msg" *ngIf="(validate.approverHeaderId.invalid && validate.approverHeaderId.touched) || validate.approverHeaderId.dirty || submitted">
                                <span *ngIf="validate.approverHeaderId.errors?.required" class="text-danger">Please Select Approver Rule</span>
                                <!-- <span *ngIf="validate.approverHeaderId.errors?.pattern" class="text-danger">Please enter valid rule name</span> -->
                                <!-- <span *ngIf="existMsg" class="text-danger">{{existMsg}}</span> -->
                            </div>
                        </div>
                  

                    <!-- <div class="row"> -->
                        <div class="form-group col-md-3">
                            <label class="col-form-label">Employee Code <span class="star">*</span></label>
                            <select formControlName="empId" placeholder="- Select -" class="form-select form-select-solid form-select-lg select2-new">
                                <option value="null">- Select -</option>
                               <!-- <option *ngFor="let position of positionlist" value="{{position.positionId}}">{{position.positionName}} - {{position.positionNumber}}</option>
                                -->
                            </select>
                            <div class="valid-msg" *ngIf="(validate.empId.invalid && validate.empId.touched) || validate.empId.dirty || submitted">
                                <span *ngIf="validate.empId.errors?.required" class="text-danger">Please Select Employee Code</span>
                                <!-- <span *ngIf="validate.empId.errors?.pattern" class="text-danger">Please enter valid rule name</span>
                                <span *ngIf="existMsg" class="text-danger">{{existMsg}}</span> -->
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->

                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a routerLink="/home/employee-approvers" routerLinkActive="active" mat-raised-button title="Cancel" class="mr-2">
                                Cancel
                            </a>
                            <button type="submit" mat-raised-button color="primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>