<!-- Sub header start here-->
<section>
  <div class="sub-header">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="">
          <span class="h5 sub-content headingDesing">
            {{ resourceId ? "Edit" : "Add" }} Candidates
          </span>
        </div>
        <div class="sub-header-buttons">
          <!--<a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                    <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a> -->
          <a
            routerLink="/home/resource-list"
            mat-raised-button
            title="Back"
            class="ml-2"
          >
            Back
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="common-content">
  <div class="card-new">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="resourceform" (ngSubmit)="submitData()">
          <div class="row">
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Resource Name<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control email-input select-placeholder"
                formControlName="name"
                placeholder="Enter Resource Name"
              />
              <div
                *ngIf="
                  (name?.invalid && name?.touched) || name?.dirty || submitted
                "
              >
                <span *ngIf="name?.errors?.required" class="text-danger"
                  >Please Enter Resource Name</span
                >
                <span *ngIf="name?.errors?.pattern" class="text-danger"
                  >Please Enter Valid Resource Name</span
                >
              </div>
            </div>
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Position<span class="text-danger">*</span></label
              >
              <mat-select
                (selectionChange)="selected($event)"
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="position"
                placeholder="Select Position"
                [disabled]="isUpdating"
              >
                <mat-option
                  *ngFor="let position of positionlist"
                  [value]="position.positionId"
                >
                  <span
                    >{{ position.positionName }} - {{ position.positionNumber }}
                  </span>
                </mat-option>
              </mat-select>
              <mat-icon
                *ngIf="isDetails"
                (click)="positionDetails()"
                class="material-icons-outlined visible-icon"
                >visibility</mat-icon
              >
              <div
                *ngIf="
                  (position?.invalid && position?.touched) ||
                  position?.dirty ||
                  submitted
                "
              >
                <span *ngIf="position.errors?.required" class="text-danger"
                  >Please Select Position</span
                >
              </div>
            </div>

            <div class="col-md-4" id="loginform" *ngIf="isShow">
              <label class="form-control-label">Requirement</label>
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="requirement"
                placeholder="Select Requirement"
                [disabled]="isUpdating"
              >
                <mat-option
                  *ngFor="let requriment of requrimentlist"
                  class="text-color"
                  [value]="requriment.requirementId"
                  >{{ requriment.requirementNo }}</mat-option
                >
              </mat-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Interview date<span class="text-danger">*</span></label
              >
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="interview_date"
                class="form-control email-input"
                readonly="readonly"
                placeholder="Choose a date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                style="float: right; position: relative; bottom: 42px"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <div
                *ngIf="
                  (interview_date?.invalid && interview_date?.touched) ||
                  interview_date?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="interview_date.errors?.required"
                  class="text-danger"
                  >Please Enter Interview Date</span
                >
              </div>
            </div>
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Total Experience<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control email-input"
                formControlName="total_experience"
                maxlength="4"
                (keypress)="noNumber($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                (keyup)="keyUpmaxexp($event)"
                placeholder="Enter Total Experience"
              />
              <div
                *ngIf="
                  (total_experience?.invalid && total_experience?.touched) ||
                  total_experience?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="total_experience.errors?.required"
                  class="text-danger"
                  >Please Enter Total Experience</span
                >
                <span
                  *ngIf="total_experience.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Total Experience</span
                >
              </div>
            </div>
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Relevent Experience<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control email-input"
                formControlName="relevent_experience"
                maxlength="4"
                (keypress)="noNumber($event)"
                (paste)="blockPaste($event)"
                (keyup)="keyUpmaxexp($event)"
                (drop)="blockDrop($event)"
                placeholder="Enter Relevent Experience"
              />
              <div
                *ngIf="
                  (relevent_experience?.invalid &&
                    relevent_experience?.touched) ||
                  relevent_experience?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="relevent_experience.errors?.required"
                  class="text-danger"
                  >Please Enter Relevent Experience</span
                >
                <span
                  *ngIf="relevent_experience.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Relevent Experience</span
                >
                <span *ngIf="this.maxExp == false" class="text-danger"
                  >Relevent Experience Shouldn't Be Greater Than Total Experience</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Notice Period<span class="text-danger">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="notice_period"
                placeholder="Choose Notice Period"
              >
                <mat-option
                  *ngFor="let e of noticeperiod"
                  class="text-color"
                  [value]="e.listTypeValueId"
                  >{{ e.listTypeValueName }}</mat-option
                >
              </mat-select>
              <div
                *ngIf="
                  (notice_period?.invalid && notice_period?.touched) ||
                  notice_period?.dirty ||
                  submitted
                "
              >
                <span *ngIf="notice_period.errors?.required" class="text-danger"
                  >Please Enter Notice Period</span
                >
                <span *ngIf="notice_period.errors?.pattern" class="text-danger"
                  >Please Enter Valid Notice Period</span
                >
              </div>
            </div>
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Current CTC<span class="text-danger">*</span></label
              >
              <input
                type="text"
                (keypress)="noNumber($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                maxlength="8"
                class="form-control email-input"
                formControlName="current_CTC"
                placeholder="Enter Current CTC"
              />
              <div
                *ngIf="
                  (current_CTC?.invalid && current_CTC?.touched) ||
                  current_CTC?.dirty ||
                  submitted
                "
              >
                <span *ngIf="current_CTC.errors?.required" class="text-danger"
                  >Please Enter Current CTC</span
                >
                <span *ngIf="current_CTC.errors?.pattern" class="text-danger"
                  >Please Enter Valid Current CTC</span
                >
              </div>
            </div>
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Expected CTC<span class="text-danger">*</span></label
              >
              <input
                type="text"
                (keypress)="noNumber($event)"
                (paste)="blockPaste($event)"
                (drop)="blockDrop($event)"
                maxlength="8"
                class="form-control email-input"
                formControlName="expected_CTC"
                placeholder="Enter Expected CTC"
              />
              <div
                *ngIf="
                  (expected_CTC?.invalid && expected_CTC?.touched) ||
                  expected_CTC?.dirty ||
                  submitted
                "
              >
                <span *ngIf="expected_CTC.errors?.required" class="text-danger"
                  >Please Enter Expected CTC</span
                >
                <span *ngIf="expected_CTC.errors?.pattern" class="text-danger"
                  >Please Enter Valid Expected CTC</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Job Location<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control email-input"
                formControlName="job_location"
                placeholder="Enter Job Location"
                (keyup)="jobLKeyUp($event)"
              />
              <div
                *ngIf="
                  (job_location?.invalid && job_location?.touched) ||
                  job_location?.dirty ||
                  submitted
                "
              >
                <span *ngIf="job_location.errors?.required" class="text-danger"
                  >Please Enter Job Location</span
                >
                <span *ngIf="job_location.errors?.pattern" class="text-danger"
                  >Please Enter Valid Job Location</span
                >
              </div>
            </div>

            <div class="col-md-4" id="loginform">
              <label class="form-control-label"
                >Current Location<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control email-input"
                formControlName="Current_location"
                placeholder="Enter Job Location"
                (keyup)="CurrentLKeyUp($event)"
              />
              <div
                *ngIf="
                  (Current_location?.invalid && Current_location?.touched) ||
                  Current_location?.dirty ||
                  submitted
                "
              >
                <span
                  *ngIf="Current_location.errors?.required"
                  class="text-danger"
                  >Please Enter Current Location</span
                >
                <span
                  *ngIf="Current_location.errors?.pattern"
                  class="text-danger"
                  >Please Enter Valid Current Location</span
                >
              </div>
            </div>

            <div class="col-md-4" id="loginform" *ngIf="relocatestatus">
              <label class="form-control-label"
                >Ready to Relocate<span class="text-danger">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="relocate"
                placeholder="Select relocate status"
              >
                <mat-option class="text-color" value="true">Yes</mat-option>
                <mat-option class="text-color" value="false">No</mat-option>
              </mat-select>
              <div
                *ngIf="
                  (relocate?.invalid && relocate?.touched) ||
                  relocate?.dirty ||
                  submitted
                "
              >
                <span *ngIf="relocate.errors?.required" class="text-danger"
                  >Please Select Relocate</span
                >
              </div>
            </div>

            <div class="col-md-8">
              <div class="row">
                <div
                  class="col-md-12"
                  *ngFor="let con of reminder()?.controls; let i = index"
                  formArrayName="resource_availability"
                >
                  <div class="row" [formGroupName]="i" *ngIf="i < 5">
                    <div class="col-md-5" id="loginform">
                      <label class="form-control-label"
                        >Resource Availability Date<span class="text-danger"
                          >*</span
                        ></label>
                      <input
                        matInput
                        [matDatepicker]="picker1"
                        formControlName="date_picker1"
                        class="form-control email-input"
                        readonly="readonly"
                        placeholder="Choose a date"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                        style="float: right; position: relative; bottom: 42px"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                      <div
                        *ngIf="
                          con['controls'].date_picker1?.touched ||
                          (submitted &&
                            con['controls'].date_picker1?.invalid) ||
                          con['controls'].date_picker1?.dirty
                        "
                      >
                        <span
                          *ngIf="con['controls'].date_picker1.errors?.required"
                          class="text-danger"
                          >Choose Resource Availability date </span
                        >
                      </div>
                    </div>
                    <div class="col-md-5" id="loginform">
                      <div appearance="fill">
                        <label
                          class="form-control-label"
                          i18n="Timepicker 1 Title"
                          >Resource Availability Time<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <input
                          matTimepicker
                          #t1="matTimepicker"
                          i18n-okLabel="Timepicker 1 Ok Label"
                          okLabel="Ok"
                          id="okDesign"
                          id="cancelDesign"
                          class="form-control email-input"
                          i18n-cancelLabel="Timepicker 1 Cancel Label"
                          cancelLabel="Cancel"
                          formControlName="time1"
                          id="timepicker-example-1"
                          mode="12h"
                          placeholder="Please select time..."
                          name="time-1"
                          (timeChange)="timeSetter()"
                        />
                      </div>
                      <div
                        *ngIf="
                          con['controls'].time1?.touched ||
                          (submitted && con['controls'].time1?.invalid) ||
                          con['controls'].time1?.dirty
                        "
                      >
                        <span
                          *ngIf="con['controls'].time1.errors?.required"
                          class="text-danger"
                          >Choose Resource Availability Time</span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-1" id="loginform">
                      <!-- <button
                        mat-stroked-button
                        color="primary"
                        type="button"
                        class="btn add-button btn-primary plus-btn"
                        *ngIf="i < 1"
                        (click)="add()"
                      >
                        +
                      </button> -->
                      <button
                        type="button"
                        class="btn add-button btn-primary plus-btn"
                        *ngIf="!isEditing && i < 1"
                        (click)="add()"
                      >
                        <mat-icon
                          class="material-symbols-outlined addDesign"
                          style="
                            /* font-size: 22px; */
                            font-size: 22px;
                            margin-left: 1px;
                            margin-bottom: -5px;
                            margin-top: -3px;
                            width: 30px !important;
                          "
                          >add</mat-icon
                        >
                      </button>
                    </div>
                    <div class="form-group col-sm-1 delete-icon">
                      <mat-icon
                        (click)="removeReminder(i)"
                        class="material-icons-outlined"
                        *ngIf="i > 0"
                        style="
                          font-size: 20px;
                          margin-left: -33px;
                          margin-top: 29px;
                          color: red;
                        "
                        >delete</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" id="loginform_Comments">
              <label class="form-control-label">Comments</label>
              <textarea
                type="text"
                rows="2"
                class="form-control email-input"
                formControlName="comments"
                placeholder="Enter Comments"
              ></textarea>
              <!-- <div *ngIf="(comments?.invalid && comments?.touched) || comments?.dirty">
                                <span *ngIf="comments.errors?.required" class="text-danger">Please enter comments</span>
                                <span *ngIf="comments.errors?.pattern" class="text-danger">Please enter valid comments</span>
                            </div>  -->
            </div>
          </div>
          <div class="row" *ngIf="resourceId">
            <div class="col-md-6" id="loginform">
              <label class="form-control-label"
                >Interviewed<span class="text-danger">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                (selectionChange)="interviewedchange($event)"
                formControlName="interviewed"
                placeholder="Select Interviewed"
              >
                <mat-option class="text-color" value="true">Yes</mat-option>
                <mat-option class="text-color" value="false">No</mat-option>
              </mat-select>
              <div
                *ngIf="
                  (interviewed?.invalid && interviewed?.touched) ||
                  interviewed?.dirty
                "
              >
                <span *ngIf="interviewed.errors?.required" class="text-danger"
                  >Please Select Interviewed</span
                >
              </div>
            </div>
            <div class="col-md-6" id="loginform" *ngIf="selectedstatus">
              <label class="form-control-label"
                >Interview Status<span class="text-danger">*</span></label
              >
              <mat-select
                class="form-control email-input select-placeholder"
                appearance="fill"
                formControlName="interviewStatus"
                (selectionChange)="interviewstatusChange($event)"
                placeholder="Select Interview Status"
              >
                <mat-option
                  *ngFor="let InterviewStatus of interviewStatuslist"
                  [value]="InterviewStatus.listTypeValueId"
                  ><span>{{
                    InterviewStatus.listTypeValueName
                  }}</span></mat-option
                >
              </mat-select>
              <div
                *ngIf="
                  (interviewStatus?.invalid && interviewStatus?.touched) ||
                  interviewStatus?.dirty
                "
              >
                <span
                  *ngIf="interviewStatus.errors?.required"
                  class="text-danger"
                  >Please Select Interview Status</span
                >
                <!-- <span *ngIf="interviewStatus.errors?.pattern" class="text-danger">Please select valid interview Status</span>    -->
              </div>
            </div>
          </div>
          <!-- <div class="row">   -->
          <!-- <div class="col-md-6"  *ngIf ="resourceselected" id="loginform">
                            <label class="form-control-label">Expected Date Of Joining<span class="text-danger">*</span></label>
                            <input matInput [matDatepicker]="picker3" formControlName="dateOfJoining" [min]="resourceform.value.interview_date" class="form-control email-input" readonly="readonly" placeholder="Choose a date">
                            <mat-datepicker-toggle matSuffix [for]="picker3" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                            <div *ngIf="(dateOfJoining?.invalid && dateOfJoining?.touched) || dateOfJoining?.dirty">
                                <span *ngIf="dateOfJoining.errors?.required" class="text-danger">Please enter expected joining date</span>   
                            </div> 
                        </div> -->

          <!-- </div>  -->
          <div class="row">
            <div
              class="form-group col-md-3"
              style="display: flex; flex-direction: column"
            >
              <!-- <label class="col-form-label">CV Attachment</label> -->
              <!-- <input type="file" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" (change)="getFileDetails($event)" formControlName="jobDescriptionAttachment" placeholder="" accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple> -->

              <!-- <label class="file-label">
                                <div class="row">
                                    <div class="col-md-2">
                                        <mat-icon class="material-symbols-outlined">
                                            attach_file_add
                                           
                                            </mat-icon>
                                    </div>
                                    <div class="col-md-10" style="margin-left: -6px;" *ngIf="resourceobj.resourcecvattachment == undefined">
        
                                        <span *ngIf="fileName != undefined">{{fileName}}</span>
                                        <span  *ngIf="fileName == undefined">{{'Attach File' }}</span>
                                    </div>
                                    <div class="col-md-10" style="margin-left: -6px;" *ngIf="resourceobj.resourcecvattachment != undefined">
                                        <span *ngIf="resourceobj.resourcecvattachment.sourceFileName != undefined">{{resourceobj.resourcecvattachment.sourceFileName}}</span>
                                        <span  *ngIf="resourceobj.resourcecvattachment.sourceFileName == undefined">{{'Attach File' }}</span>
                                        <div class="download-btn">
                                            <a class="btn btn-primary btn-sm" href="{{downloadbaseurl+'download_CV/'+resourceobj.resourcecvattachment.resourceCvId}}">
                                                Download 
                                                </a>
                                        </div>
                                      
                                    </div>
                                    </div>
                                    <input type="file"                                                        
                                    formControlName="resourcecvattachment"
                                    placeholder="" accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple
                                    class="file-input" (change)="getFileDetails($event)">  
                              </label> -->
                              <label class="file-label form-control-label">
                                CV Attachment
                                <div class="row" style="width: 135% !important">
                                  <div class="col-md-10" style="margin-left: -6px; position: relative; top: 6px">
                                    <input
                                      type="file"
                                      style="height: 41px"
                                      formControlName="resourcecvattachment1"
                                      placeholder=""
                                      accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf"
                                      [ngClass]="{ 'is-invalid': submitted }"
                                      class="file-input form-control email-input select-placeholder"
                                      (change)="getFileDetails($event)"
                                    />
                                  </div>
                              
                                  <div
                                    class="col-md-10"
                                    style="margin-left: -6px; position: relative; top: 11px; left: 5px;"
                                    *ngIf="!newFileSelected && resourceobj.resourcecvattachment && resourceobj.resourcecvattachment.resourceCvId"
                                  >
                                    <!-- <div> -->
                                      <a
                                        class="btn-primary create-button btn-sm"
                                        [href]="downloadbaseurl + 'download_CV/' + resourceobj.resourcecvattachment.resourceCvId"
                                        download
                                      >
                                        Download File
                                      </a>
                                    <!-- </div> -->
                                  </div>
                                </div>
                              </label>
                              
                              <label
                                *ngIf="!newFileSelected  && resourceform.get('resourcecvattachment').value"
                                class="col-form-label"
                                style="font-family: Mulish; margin-left: 1%;"
                              >
                                CV Attachment: {{ resourceform.get('resourcecvattachment').value }}
                              </label>
                            
                              </div>
            <!-- <div class="col-md-6" id="loginform">
                            <label class="form-control-label">CV Attachment</label>
                            <input type="file" (change)="getFileDetails($event)" class="form-control email-input" formControlName="resourcecvattachment" placeholder=""          
                            accept=".docx,.doc,.docm,.dot,.dotm,.dotx,.rtf,.xml,.pdf" multiple>
                            <div *ngIf="(resourcecvattachment?.invalid && resourcecvattachment?.touched) || resourcecvattachment?.dirty">
                                <span *ngIf="resourcecvattachment.errors?.required" class="text-danger">Please enter  resource cv attachment</span>
                                                           </div>          
                        </div> -->
          </div>

          <!-- <div class="row" *ngIf="resourceobj.resourcecvattachment != undefined">
                        <div class="col-md-6" id="loginform" style="position: relative;bottom: 5px;">
                            <a class="file-download badge badge-secondary" href="{{resourceobj.resourcecvattachment.fileDownloadUrl}}">
                                <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>Download</a>
                        </div>  
                    </div>  -->
          <!-- <div class="row buttons">
                        <div class="col-md-12">
                            <Button type="submit" class=" btn-primary create-button" [disabled]="buttonDisabled">{{resourceId ? 'Update' : 'Create'}}</Button>
                            <Button type="cancel" class=" cancel-button"(click)="formcancel()">Cancel</Button>
                        </div>
                    </div>        -->
          <div align="end">
            <button
              mat-raised-button
              type="button"
              [disabled]="buttonDisabled"

              class="cancel-button m-1"
              (click)="formcancel()"
              style="
              background: none;
              color: var(--Red-Color, #FF5D5D);
              border: 1px solid #ddd;
              font-size: 16px;
              font-weight: 550;
              margin-left: 5px;
              height: 35px;
            "
            >
              Cancel
            </button>
            <button
              mat-raised-button
              type="submit"
              style="height: 34px"
              class="create-button m-1"
              [disabled]="buttonDisabled"
            >
            Save
              <!-- {{ resourceId ? "Update" : "Create" }} -->
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;    color: #009ef7;
    position: absolute;
    z-index: 1;
    top: 53%;
    right: 48%;" role="status"></div>
</div>
<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div> -->

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
</div>
