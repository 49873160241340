import { Component, OnInit } from '@angular/core';
import { GlobalserviceService } from '../providers/globalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { HrmsService } from '../providers/hrms.service';
import { catchError, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { em } from '@fullcalendar/core/internal-common';
import { SettingsService } from '../providers/settings.service';
import { MatSelectChange } from '@angular/material/select';
import { AdminService } from '../providers/admin.service';

@Component({
  selector: 'app-business-group-listing',
  templateUrl: './business-group-listing.component.html',
  styleUrls: ['./business-group-listing.component.scss']
})
export class BusinessGroupListingComponent implements OnInit {

  submitted: boolean = false;
  statusCode: any;
  errorMsg: any;
  existMsg: any;
  businessgroup: FormGroup;
  resultData: any;
  branchId: any;

  sucess_msg: any;
  error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;
  employeetData: any;
  isbtnDisable: boolean = false;
  businessId: any;
  tableShow: boolean = false;
  businessGroupList: any[] = [];
  beforepage: any;
  mySelect: any = 5;
  beforesearchpage: any;
  p: any;
  nodata: boolean = false;

  //List type value id should be static 
  listTypeValueId: number = 7;
  private unsubscribe$: Subject<void> = new Subject<void>();
  businessGroupName: any[] = [];
  businessGroup: any ;
  industryType: any ;
  businessGroupNotFound: boolean = false;
  industryTypeListing: any[] = [];
  industryTypeValueID: number | undefined;
  searchForm: FormGroup;
  businessValue: number ;
  industryValue: number ;
  showValidationMessage: boolean = false; 
  buttonDisable:boolean =  false;
  storeEntityId: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private gs: GlobalserviceService,
    private ActivatedRoute: ActivatedRoute,
    private hrmsservice: HrmsService,
    private AdminService:AdminService,
    private settingService: SettingsService
  ) { }

  ngOnInit(): void {

    let userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log("user data:",userdata);
    this.storeEntityId=userdata.entity.entityId;
    console.log("entity Id:",this.storeEntityId);

 
    
    this.searchForm = this.formBuilder.group({
     
      businessGroup: [
        null,
        [
          Validators.required,
          Validators.pattern(
            "^(?![ ])(?!.* {2,})[a-zA-Z0-9_ !@#$%^&*()-.,/';:]+$"
          ),
        ],
      ],
      
     
      industryType: [null],
    });
    // this.businessgroupList()
    this.getIndustrtype()
    // this.searchForm.controls['businessGroup'].setValue(undefined);
    // this.searchForm.controls['industryType'].setValue(undefined);

  }

  get businessGroupValue() {
    return this.searchForm.get('businessGroup');
  }
  getIndustrtype(){
    this.nodata=false;
    this.loader=true;
    this.settingService.getListTypeValues(this.listTypeValueId).subscribe((response: any) => {
      console.log('response', response);
      // this.industryTypeListing.push(response[0]);
      this.industryTypeListing = response;
      this.loader = false;
      console.log('response', this.industryTypeListing);
    })
  }
  businessgroupList(){
    this.tableShow=false;
    this.hrmsservice.getAllBusinessGroup(this.storeEntityId).subscribe((response: any) => {
      console.log('response', response);
      this.loader =  false;
      this.tableShow=true;
      
      this.businessGroupList = response;
      if(response.statusCode==204){
        this.nodata=true;
        this.tableShow=true;
        this.businessGroupList=[];

      }
      this.nodata=false;
      // this.businessGroupList.push(response);

    })
  }
  updateStatus(id,status)
	{
    this.AdminService.updateBusinessStatus(id,status).subscribe((result:any) =>
		{
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        const updatedRow = this.businessGroupList?.find((row) => row.businessGroupId === id);
        if (updatedRow) {
          updatedRow.businessGroupStatus = status; // Update the status in the current data
        }
        setTimeout(() => {this.success  = false;},5000)
      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, 5000)
      }
		})
	}
  changefirstpage() {
    this.p = 1;
  }
  sortData(sort: Sort) {
    const data = this.businessGroupList.slice();
    if (!sort.active || sort.direction === '') {
      this.businessGroupList = data;
      return;
    }

    this.businessGroupList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'businessGroup':
          return this.compare(
            a.businessGroupName,
            b.businessGroupName,
            isAsc
          );
        case 'Description':
          return this.compare(
            a.businessGroupDescription,
            b.businessGroupDescription,
            isAsc
          );
        case 'Industry':
          return this.compare(a.industryType.listTypeValueName, b.industryType.listTypeValueName, isAsc);
        case 'Entity':
          return this.compare(
            a.entity.entityName,
            b.entity.entityName,
            isAsc
          );
        case 'Status':
          return this.compare(a.businessGroupStatus, b.businessGroupStatus, isAsc);
       
        
        // return this.compare(a.assignedhours, b.assignedhours, isAsc);
        default:
          return 0;
      }
    });
  }

  clearBusinessGroup() {
    this.businessGroup = undefined;
    this.industryType = undefined;
    this.industryTypeValueID = undefined;
    this.showValidationMessage = false;


    // this.searchForm.controls['businessGroup'].setValue(undefined);
    // this.searchForm.controls['industryType'].setValue(undefined);
    

    
    this.businessValue=this.searchForm.value.businessGroup;
    this.industryValue=this.searchForm.value.industryType;

    console.log('this.businessValue', this.businessValue);
    console.log('this.industryValue',  this.industryValue);
    this.nodata = false;
    this.tableShow=false

    // this.hrmsservice.getAllBusinessGroup(this.storeEntityId).subscribe((response: any) => {
    //   console.log('response', response);
    //   this.loader =  false;
    //   this.businessGroupList = response;
    //   this.nodata = false;
    //   this.tableShow = true;
    //   // this.businessGroupList.push(response);

    // })
    // if (  this.businessValue  != undefined &&   this.industryValue != undefined) {
    //   this.businessGroupList = [];
    //   this.loader = true;
    //   this.tableShow = false;
    //   this.hrmsservice.getAllBusinessGroup(this.storeEntityId).subscribe((response: any) => {
    //     console.log('response', response);
    //     this.loader =  false;
    //     this.businessGroupList = response;
    //     this.nodata = false;
    //     this.tableShow = true;
    //     // this.businessGroupList.push(response);
  
    //   })
    // }

    // if (  this.businessValue  &&   this.industryValue == null &&  this.businessGroupList.length == 0) {
    //   this.businessGroupList = [];
    //   this.loader = true;
    //   this.tableShow = false;
    //   this.hrmsservice.getAllBusinessGroup(this.storeEntityId).subscribe((response: any) => {
    //     console.log('response', response);
    //     this.loader =  false;
    //     this.businessGroupList = response;
    //     this.nodata = false;
    //     this.tableShow = true;
    //     // this.businessGroupList.push(response);
  
    //   })
    // }
 
  }

  selectionChangeIndustryType(data: MatSelectChange) {
    console.log('value', data?.value);
    this.industryTypeValueID = data?.value;
  }

  searchBusinessGroup() {

    let businessValue = this.searchForm.controls['businessGroup'].value?.trim();
  let industryValue = this.searchForm.controls['industryType'].value;

  console.log("businessValue::", businessValue);
  console.log("industryValue::", industryValue);

  if ((!businessValue || businessValue === "") && (!industryValue || industryValue === undefined)) {
    // this.showValidationMessage = true;
    alert("Please Type any Keywords in Business Group Name to Search!!")
    this.buttonDisable = false;
    console.log("Both fields are empty or null");
    return;
  }

  if ((!businessValue || businessValue === "") && industryValue) {
    // this.showValidationMessage = false;
    alert("Please Type Keywords Along With Business Group Name!");
    return;
  }

  // Check if both values are provided
  if (businessValue && industryValue) {
    this.showValidationMessage = false;
    // alert("Both values provided!");

    console.log("Both values provided!!");
    
       this.tableShow=false;
        this.loader = true;
        this.businessGroupList = [];
        this.buttonDisable = true;
        this.hrmsservice.searchBasedOnBusinessGroupandIndustryType(businessValue, industryValue,this.storeEntityId).subscribe((response: any) => {
     
          this.loader = false;
          this.businessGroupList = response;
          this.tableShow=true;
          this.buttonDisable = false;

          if (response.statusCode === 204) {
            this.businessGroupList = [];
            this.loader =  false;
            this.nodata = true;
            this.buttonDisable = false;
          }
          this.nodata = false;

          console.log('response', response);
  
        },((err) => {
          this.error = true;
          this.loader =  false;
          this.error_msg = err.description;
          this.buttonDisable = false;
          setTimeout(() => {this.error = false}, 3000)
          console.log('errrrr', err);
  
        }));

    return;
  }

  // Check if only businessValue is provided
  if (businessValue && (!industryValue || industryValue === undefined)) {
    this.showValidationMessage = false;
    // alert("Only business value provided!");
    console.log("Only business value provided");
    
      this.buttonDisable = true;
        // this.businessGroupList = [];
        this.tableShow=false;
        this.loader = true;
        this.hrmsservice.keywordSearchOfBusinessGroup(businessValue,this.storeEntityId).subscribe((response: any) => {
          this.loader =  false;
          this.businessGroupList = [];
          this.businessGroupList = response;
          this.nodata = false;
          this.tableShow=true;
          this.buttonDisable = false;
          if (response.statusCode === 204) {
            this.businessGroupList = [];
            this.nodata = true;
            this.loader =  false;
            this.tableShow=true;
            this.buttonDisable = false;
          }else{
            this.nodata = false;
            this.loader =  false;
          }
          console.log('response', response);
  
        }, ((err) => {
          this.error = true;
          this.loader =  false;
          this.error_msg = err.description;
          this.buttonDisable = false;
          setTimeout(() => {this.error = false}, 3000)
          console.log('errrrr', err);
  
        }))
    return;
  }
    // else{
    

      // if (this.businessValue!==null&& this.industryValue==undefined) {
      //   this.buttonDisable = true;
      //   // this.businessGroupList = [];
      //   this.tableShow=false;
      //   this.loader = true;
      //   this.hrmsservice.keywordSearchOfBusinessGroup(this.businessValue,this.storeEntityId).subscribe((response: any) => {
      //     this.loader =  false;
      //     this.businessGroupList = [];
      //     this.businessGroupList = response;
      //     this.nodata = false;
      //     this.tableShow=true;
      //     this.buttonDisable = false;
      //     if (response.statusCode === 204) {
      //       this.businessGroupList = [];
      //       this.nodata = true;
      //       this.loader =  false;
      //       this.tableShow=true;
      //       this.buttonDisable = false;
      //     }else{
      //       this.nodata = false;
      //       this.loader =  false;
      //     }
      //     console.log('response', response);
  
      //   }, ((err) => {
      //     this.error = true;
      //     this.loader =  false;
      //     this.error_msg = err.description;
      //     this.buttonDisable = false;
      //     setTimeout(() => {this.error = false}, 3000)
      //     console.log('errrrr', err);
  
      //   }))
      // }
  
      // if (this.businessValue!==null && (this.industryValue!==' '||this.industryValue!==null||this.industryValue.length!=0)) {
      //   this.tableShow=false;
      //   this.loader = true;
      //   this.businessGroupList = [];
      //   this.buttonDisable = true;
      //   this.hrmsservice.searchBasedOnBusinessGroupandIndustryType(this.businessValue, this.industryValue,this.storeEntityId).subscribe((response: any) => {
     
      //     this.loader = false;
      //     this.businessGroupList = response;
      //     this.tableShow=true;
      //     this.buttonDisable = false;

      //     if (response.statusCode === 204) {
      //       this.businessGroupList = [];
      //       this.loader =  false;
      //       this.nodata = true;
      //       this.buttonDisable = false;
      //     }
      //     this.nodata = false;

      //     console.log('response', response);
  
      //   },((err) => {
      //     this.error = true;
      //     this.loader =  false;
      //     this.error_msg = err.description;
      //     this.buttonDisable = false;
      //     setTimeout(() => {this.error = false}, 3000)
      //     console.log('errrrr', err);
  
      //   }));
      // }
    // }
    
    

  


  }
  // searchBusinessName(event1, event: KeyboardEvent) {
  //   if (event.key === ' ') {
  //     // this.employeeNotFound = false;
  //     // Handle the space key press
  //     console.log('Space key pressed');

  //     // Optionally, prevent the default behavior
  //     event.preventDefault();
  //   } else {
  //     // if (this.timesheetreport.length > 0) {
  //     //   this.nodata = false;
  //     // }

  //     // this.employeeProjects = [];
  //     // console.log(event.target.value);
  //     let employeenumber = event1.target.value;
  //     employeenumber = employeenumber.replace(/-.*/, '');
  //     // console.log(employeenumber);

  //     // Cancel the previous API request
  //     this.unsubscribe$.next();
  //     this.unsubscribe$.complete();
  //     this.unsubscribe$ = new Subject<void>();

  //     if (employeenumber.length == 0) {
  //       // this.employeeNotFound = false;
  //       console.log('enterd into the condition!!');
  //       // this.employeeId = undefined;
  //       // this.employee = undefined;
  //     }
  //     // console.log('checking Project Id', this.projectId);

  //     // if (
  //     //   this.projectId === 0 ||
  //     //   this.projectId === undefined ||
  //     //   this.projectId === null
  //     // ) {
  //       console.log('employee key', employeenumber);
  //       if (employeenumber.length >= 2) {
  //         this.hrmsservice
  //           .keywordSearchOfBusinessGroup(employeenumber)
  //           .pipe(
  //             takeUntil(this.unsubscribe$), 
  //             switchMap(() => this.hrmsservice.keywordSearchOfBusinessGroup(employeenumber)),
  //             catchError((err) => {
  //               if (err.error.status === 500) {
  //                 // this.projectId = [];
  //                 // this.employeeNotFound = true;
  //               }
  //               console.error('Error fetching employees:', err);
  //               // Handle the error here, for example, showing an alert
  //               // alert('Error fetching employees. Please try again later.');
  //               return throwError(err); // Rethrow the error to propagate it downstream
  //             })
  //           )
  //           .subscribe((result: any) => {
  //             console.log('result', result);
  //             this.businessGroupName = [];
  //             this.businessGroupName =  result;
  //             if (result.statusCode === 204) {
  //               this.businessGroupNotFound = true
  //             }
  //             // this.employeeProjects.length = 0;

  //             if (result.length > 0) {
  //               (result as any)?.forEach((element) => {
  //                 let employee = {
  //                   employee: {
  //                     employeeId: element.employeeId,
  //                     firstName: element.firstName,
  //                     employeeNumber: element.employeeNumber,
  //                   },
  //                 };
  //                 this.businessGroupNotFound = false;
  //                 if (Object.keys(employee).length > 0) {
  //                   // this.employeeNotFound = false;
  //                 } else {
  //                   // this.employeeNotFound = true;
  //                 }
  //                 // this.employeeProjects.push(employee);
  //                 // console.log('this.employeeProjects', this.employeeProjects);
  //               });
  //             }

  //             // if (this.selectedemployee.length >= 1) {
  //             //   this.employeeSelected = true;
  //             // }

  //             if (result.errorCode === 204) {
  //               // alert('No Employee Found');
  //               // this.selectedemployee = undefined;
  //               // this.selectedemployeeId = undefined;
  //               // this.employeeNotFound = true;
  //               // this.employeeProjects.length = 0;
  //               // this.employeeProjects.push('No Employee Found');
  //               // this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
  //             }
  //           });
  //       // }
  //     }


  //   }
  // }



  noEmployeeFound() {
    this.businessGroupNotFound = false;
  }
  businessNameSelect(emp) {

    this.businessGroup = emp.businessGroupName;
    // this.projectList = []; // Clear projectList
    // this.employeeProjects = []; // Clear employeeProjects
    // this.employeeId = ''; // Reset employeeId
    // this.employeeData = ''; // Reset employeeData
    // this.employeeNumber = ''; // Reset employeeNumber
    // this.taskList = [];
    // console.log('emp', emp);
    // this.employeeId = emp.employee.employeeId;
    // this.employeeData =
    //   emp.employee.employeeNumber + '-' + emp.employee.firstName;
    // this.employeeNumber = emp.employee.employeeNumber;

    // console.log('this.employeeId', this.employeeId);



    // Patch the form with employee data
    // this.taskForm.patchValue({
    //   employee: this.employeeData,
    // });

    // console.log('patching', this.taskForm.controls['employee'].value);


  }
  validateInput(event) {
    const textarea = event.target;
    let value = textarea.value;
  
    // Allow space only if it's not the first character and the previous character is not a space
    if (event.key === ' ' && (textarea.selectionStart === 0 || value[textarea.selectionStart - 1] === ' ')) {
      event.preventDefault();
      return;
    }
  
    // Prevent Enter key, period, comma, and backticks
    if (event.key === 'Enter' || event.key === '.' || event.key === ',' || event.key === '`') {
console.log("her validate came !!");

      event.preventDefault();
    }
  
    // Trim spaces, commas, and backticks at the start
    while (value.startsWith(' ') || value.startsWith(',') || value.startsWith('`')) {
      value = value.trimStart().replace(/^,|^`/, '');
      textarea.value = value;
    }
  
    // Remove extra spaces between words
    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;
  
    // Prevent date patterns (e.g., 2023-07-02, 02/07/2023, etc.)
    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY
  
    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value.replace(datePattern1, '').replace(datePattern2, '');
    }
  }
  handleBackspace(event:any) {
    // if (event.key === ' ') {
    //   event.preventDefault();
    // }

    // Get the key that was pressed
  console.log("Key pressed:", event.key);

  // Get the current value in the input field
  const inputValue = event.target.value;
  console.log("Current input value:", inputValue);
  console.log("Current input value:", inputValue.length);
      // this.showValidationMessage = true;


  if ( inputValue.length ===0) {
    this.tableShow=false
    console.log("if entered!!!");
    // this.showValidationMessage = true;

    // this.businessGroupList = [];
    // this.loader = true;
    // this.tableShow = false;
    // this.showValidationMessage = false;
    // this.hrmsservice.getAllBusinessGroup(this.storeEntityId).subscribe((response: any) => {
    //   console.log('response', response);
    //   this.loader =  false;
    //   this.businessGroupList = response;
    //   this.nodata = false;
    //   this.tableShow = true;
    //   // this.businessGroupList.push(response);

    // })
  }
  // Your logic here (like calling a service or performing other actions)
  this.nodata = false;

    
  }
  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  validateInputSpace(event) {
    const textarea = event.target;
    let value = textarea.value;

    if (
      event.key === ' ' &&
      (textarea.selectionStart === 0 ||
        value[textarea.selectionStart - 1] === ' ')
    ) {
      event.preventDefault();
      return;
    }

    value = value.replace(/\s{2,}/g, ' ');
    textarea.value = value;

    const datePattern1 = /\b\d{4}[-/]\d{2}[-/]\d{2}\b/; // YYYY-MM-DD or YYYY/MM/DD
    const datePattern2 = /\b\d{2}[-/]\d{2}[-/]\d{4}\b/; // DD-MM-YYYY or DD/MM/YYYY

    if (datePattern1.test(value) || datePattern2.test(value)) {
      textarea.value = value
        .replace(datePattern1, '')
        .replace(datePattern2, '');
    }
  }
  get validate() {
    return this.businessgroup.controls;
  }
  clearAll() {
    this.businessgroup.reset();
  }
  onSubmit() {
    console.log('form values::', this.businessgroup.value);

    this.submitted = true;

    if (this.businessgroup.invalid) {
      // this.isbtnDisable=true;
      return;
    }
  }

}
