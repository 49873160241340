<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">View Organization</span>
                </div>
                <div class="sub-header-buttons">
                    <!--  <a routerLink="employee" mat-raised-button title="Export" class="">Export</a>
                  <a routerLink="employee" mat-raised-button class="ml-2" title="Import">Import</a>
                   -->
                    <a  routerLink="/home/organization" mat-raised-button class="ml-2">
                        Back
                    </a>

                    <a routerLink="/home/edit-organization/{{organizationId}}" routerLinkActive="active"  mat-raised-button  class="ml-2 desinbtn">
                      Edit Organization
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->

<div class="common-content">
    <div class="card-new">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">

                                    <span *ngIf="fileblob">
                                        <img class="profile-image" src="{{fileblob}}" style="width:150px;height:75px;" alt="Profile Image">
                                    </span>

                                    <span *ngIf="!fileblob">
                                        <img class="profile-image" src="assets/uploads/load.png" alt="Profile Image">
                                    </span>

                                    <!-- <a class="edit-icon" routerLink="#"><mat-icon class="edit-profile">edit</mat-icon></a>
                                    -->
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="profile-name mb-2">
                                    {{resultData.organizationName}}
                                </div>

                                <div class="profile-title">
                                    <div class="row">
                                        <span *ngIf="resultData.industryType" class="profile-icons"><mat-icon class="profile-mat-icon">work</mat-icon>
                                          {{resultData.industryType ? resultData.industryType.listTypeValueName :""}}
                                      </span>
                                        <span *ngIf="resultData.contactCountryCode" class="profile-icons"><mat-icon class="profile-mat-icon">call</mat-icon>
                                          {{resultData.contactCountryCode ? resultData.contactCountryCode :""}}
                                          {{resultData.contactPhoneNumber ? resultData.contactPhoneNumber :""}}
                                      </span>
                                        <span *ngIf="resultData.contactEmail" class="profile-icons"><mat-icon class="profile-mat-icon">email</mat-icon>
                                          {{resultData.contactEmail ? resultData.contactEmail :""}}
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-new">
        <div class="row">
            <div class="col-md-12">
                <section class="my_prifile">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3 class="profile-header-title align-top">
                                        <mat-icon class="profile-icons">info</mat-icon>
                                        Organization Information
                                    </h3>

                                    <div class="row" >
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">Organization Name :</label></div>
                                        <div class="col-md-7"><span>{{resultData.organizationName ? resultData?.organizationName:"-"}}</span></div>
                                    </div>

                                    <div class="row mt-2" *ngIf="resultData.businessUnit">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">Business Unit:</label></div>
                                        <div class="col-md-7"><span>{{resultData.businessUnit?.businessUnitName ? resultData.businessUnit?.businessUnitName :"-"}}</span></div>
                                    </div>

                                    <!-- <div class="row mt-2">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">Postal Code :</label></div>
                                        <div class="col-md-7"><span>{{resultData.postalCode ? resultData.postalCode :"-"}}</span></div>
                                    </div> -->

                                    <div class="row mt-2" *ngIf="resultData.industryType">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">Industry Type : </label></div>
                                        <div class="col-md-7"><span>{{resultData.industryType.listTypeValueName ? resultData.industryType.listTypeValueName  :"-"}}</span></div>
                                    </div>

                                    <!-- <div class="row mt-2" *ngIf="resultData.defaulTimezone">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">Default Time Zone :</label></div>
                                        <div class="col-md-7"><span>{{resultData.defaulTimezone ? resultData.defaulTimezone.timezoneName :"-"}}</span></div>
                                    </div> -->

                                    <!-- <div class="row mt-2">
                                        <div class="col-md-5 d-flex align-items-center"><label>Currency</label></div>
                                        <div class="col-md-7"><span>{{resultData.reportingCurrency ? resultData.reportingCurrency :""}}</span></div>
                                    </div> -->
                                </div>

                                <div class="col-md-6">
                                    <h3 class="profile-header-title ">
                                        <mat-icon class="profile-icons">info</mat-icon>
                                        Address Information
                                    </h3>

                                    <div class="row" *ngIf="resultData?.country">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">Country Name:</label></div>
                                        <div class="col-md-7"><span>
                                            {{resultData?.country?.countryName ? resultData.country.countryName:"-"}}
                                            </span></div>
                                    </div>
                                    
                                    <!--  <div class="row">
                                        <div class="col-md-5 d-flex align-items-center"> <label>Country Code</label></div>
                                        <div class="col-md-7"><span>{{resultData.country.countryCode ? resultData.country.countryCode:""}}</span></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 d-flex align-items-center"> <label>Mobile Number Length</label></div>
                                        <div class="col-md-7"><span>{{resultData.country.maxPhoneNumberLength ? resultData.country.maxPhoneNumberLength:""}}</span></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 d-flex align-items-center"> <label>Country Symbol</label></div>
                                        <div class="col-md-7"><span>{{resultData.country.countrySymbol ? resultData.country.countrySymbol:""}}</span></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 d-flex align-items-center"> <label>Currency Code</label></div>
                                        <div class="col-md-7"><span>{{resultData.country.currencyCode ? resultData.country.currencyCode:""}}</span></div>
                                    </div> -->

                                    <div class="row" *ngIf="resultData.state">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">State:</label></div>
                                        <div class="col-md-7"><span>{{resultData.state.stateName ? resultData.state.stateName:"-"}}</span></div>
                                    </div>

                                    <div class="row" *ngIf="resultData.district">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">District:</label></div>
                                        <div class="col-md-7"><span>{{resultData.district.districtName ? resultData.district.districtName:"-"}}</span></div>
                                    </div> 

                                    <div class="row" *ngIf="resultData.city">
                                        <div class="col-md-5 d-flex align-items-center"> <label class="form-label-design">City:</label></div>
                                        <div class="col-md-7"><span>{{resultData.city.cityName ? resultData.city.cityName:"-"}}</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<!--
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div> -->