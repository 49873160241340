import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  private afterSubmitTimesheet =  new BehaviorSubject<string>('');
  private successMessageSubject = new BehaviorSubject<string>('');
  private successUpdateMessageSubject =  new BehaviorSubject<string>('');
  private warningMessageSubject =  new BehaviorSubject<string>('');
  private warningUpdateMessageSubject =  new BehaviorSubject<string>('');
  private successAddTaskMessageSubject = new BehaviorSubject<string>('');
 
  private successAddTaskUpdateMessageSubject = new BehaviorSubject<string>('');
 
  constructor() { }

  setSuccessMessage(message: string) {
    this.successMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  setAfterSubmitTimesheet(message:string) {

    this.afterSubmitTimesheet.next(message);
  }

  getSuccessMessage() {
    return this.successMessageSubject.asObservable();
  }

  getAfterSubmitTimesheet(){
    return this.afterSubmitTimesheet.asObservable();
  }
  clearSuccessMessage() {
    this.successMessageSubject.next('');
  }

  setSuccessUpdateMessage(message: string) {
    this.successUpdateMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getSuccessUpdateMessage() {
    return this.successUpdateMessageSubject.asObservable();
  }

  clearSuccessUpdateMessage() {
    this.successUpdateMessageSubject.next('');
  }


  setWarningMessage(message: string) {
    this.warningMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getWarningSucessMessage() {
    return this.warningMessageSubject.asObservable();
  }

  clearWarningSuccessMessage() {
    this.warningMessageSubject.next('');
  }


  setWarningUpdateMessage(message: string) {
    this.warningUpdateMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getWarningUpdateMessage() {
    return this.warningUpdateMessageSubject.asObservable();
  }

  clearWarningUpdateMessage() {
    this.warningUpdateMessageSubject.next('');
  }


  
  setAddTaskSuccessMessage(message: string) {
    this.successAddTaskMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getAddTaskSuccessMessage() {
    return this.successAddTaskMessageSubject.asObservable();
  }

  clearAddTaskSuccessMessage() {
    this.successAddTaskMessageSubject.next('');
  }

  setAddTaskSuccessUpdateMessage(message: string) {
    this.successAddTaskUpdateMessageSubject.next(message);
    console.log('Message from service:', message);
  }

  getAddTaskSuccessUpdateMessage() {
    return this.successAddTaskUpdateMessageSubject.asObservable();
  }

  clearAddTaskSuccessUpdateMessage() {
    this.successAddTaskUpdateMessageSubject.next('');
  }

}
