<div class="container">
    <div class="top-content">        
        <h1>Approvers</h1>
        <span class="material-icons c" style="color:red;"  mat-dialog-close>cancel</span>
    </div>
    <div *ngIf="!loader">
        <div>
            <div class="row">
                <div class="col-md-8">
                    <h4>{{datanew?.approverOneObject?.firstName}}</h4>
                    <h6 class="email-id-new">{{datanew.approverOneObject.organizationEmail}}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approvals.approverOneStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approvals.approverOneStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approvals.approverOneStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approvals.approverOneStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approvals.approverOneStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approvals.approverOneStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
         <div *ngIf="data.approvals.approver.approverTwo">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{datanew.approverTwoObject.firstName}}</h4>
                    <h6 class="email-id-new">{{datanew.approverTwoObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approvals.approverTwoStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approvals.approverTwoStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approvals.approverTwoStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approvals.approverTwoStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approvals.approverTwoStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approvals.approverTwoStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
         <div *ngIf="data.approvals.approver.approverThree">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{datanew.approverThreeObject.firstName}}</h4>
                    <h6 class="email-id-new">{{datanew.approverThreeObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approvals.approverThreeStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approvals.approverThreeStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approvals.approverThreeStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approvals.approverThreeStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approvals.approverThreeStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approvals.approverThreeStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
         <div  *ngIf="data.approvals.approver.approverFour">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{datanew.approverFourObject.firstName}}</h4>
                    <h6 class="email-id-new">{{datanew.approverFourObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approvals.approverFourStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approvals.approverFourStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approvals.approverFourStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approvals.approverFourStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approvals.approverFourStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approvals.approverFourStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
         <div  *ngIf="data.approvals.approver.approverFive">
            <div class="row" >
                <div class="col-md-8">
                    <h4>{{datanew.approverFiveObject.firstName}}</h4>
                    <h6 class="email-id-new">{{datanew.approverFiveObject.organizationEmail }}</h6>
                </div>
                <div class="col-md-4">               
                    <h6 class="approver-status text-warning" 
                    *ngIf="data.approvals.approverFiveStatus.listTypeValueName == 'Pending'" align="center"> 
                    {{data.approvals.approverFiveStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-success" 
                    *ngIf="data.approvals.approverFiveStatus.listTypeValueName == 'Approved'" align="center"> 
                    {{data.approvals.approverFiveStatus.listTypeValueName}}</h6>
                    <h6 class="approver-status text-danger" 
                    *ngIf="data.approvals.approverFiveStatus.listTypeValueName == 'Denied'" align="center"> 
                    {{data.approvals.approverFiveStatus.listTypeValueName}}</h6>
                </div>  
            </div>
            <hr/>
        </div>
         <div class="top-content">
            <h1>FYI</h1>
        </div> 
        <div>
            <div class="row" >
                <div class="col-md-6">
                    <h4>{{datanew.fyiObject.firstName}}</h4>
                    <h6 class="email-id-new">{{datanew.fyiObject.organizationEmail }}</h6>
                </div>
           </div>
        </div> 
    </div> 
    <div *ngIf="loader" class="d-flex align-items-center justify-content-center" style="margin-top: 10%;" >
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div> 