<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">View Payslip</span>
                </div>
                <div class="sub-header-buttons" style="right: 0px;">
                    <a title="View PDF" target="_blank" class="btn text-danger">
                        <!-- <mat-icon (click)="printPdf()">file_download</mat-icon> -->
                        <!-- <a title="Save as PDF" class="btn btn-danger"  (click)="printpdf()"> -->
                            <i class="fa fa-file-pdf-o" (click)="printPdf()" style="font-size: 22px;"></i>
                        <!-- </a>  -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<div  id="pdfTable" #pdfTable>   
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table items table-bordered table-condensed table-hover" style="margin: 0;width: 100%;" data-pdfmake="{'widths':['*','*','*'],'heights':10}">
                                <tr>
                                    <td class="text-center" colspan="1" style="width:40%;border:1px solid #ccc;margin:10px 0px 10px 0px; text-align: center">
                                        <img [src]="base64data" style="height:65px;width : 190px;">
                                    </td>
                                    <td class="text-center" colspan="2" style="width:60%;border:1px solid #ccc;margin:10px 0px 10px 0px;">
                                        <p class="header" style="font-size:20px;font-weight:700;margin: 0px 0px 0px 0px ;line-height:0.5;text-align: center;"><b>{{userdata.organization.organizationName}}</b></p>
                                        <span class="header" style="font-size:13px;font-weight:400;margin: 0px 0px 0px 0px ;line-height:0.5;text-align: center;">{{userdata.organization.address}}, {{userdata.organization.city.cityName}}, {{userdata.organization.state.stateName}}-{{userdata.organization.postalCode}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: center;border:1px solid #ccc;padding: 0;" colspan="3">
                                        <div  *ngIf="payrolldata!=undefined" style="font-size:18px;font-weight:700;margin:7px 0px 7px 0px;"><b>Payslip for the Month of {{payrolldata.finanicalPeriod?.financialperiodMonth}} {{payrolldata.finanicalPeriod?.financialPeriodYear}}</b></div>
                                    </td>
                                </tr>
                            </table>
                            <table class="table items table-bordered table-condensed table-hover" style="width: 100%;" data-pdfmake="{'widths':['*','*','*','*'],'heights':10}">
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Employee No</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.employeeNumber}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Employee Name</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.firstName}} {{payrolldata.employee?.lastName}}</td>
                                </tr>
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Department</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.department?.departmentName}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Designation</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.designation?.designationName}}</td>
                                </tr>
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Date of Birth</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.dateOfBirth}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Bank Acc No</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.employeeInfo ? payrolldata.employee.employeeInfo.bankAccountNo  : "" }}</td>
                                </tr>
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Date of Joining</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.dateOfJoining}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Aadhaar Number</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.aadhaarNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>No.of Working Days</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"> {{payrolldata.organizationworkDays ? payrolldata.organizationworkDays.workingDays  : ""}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>PAN No</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee?.panNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Leave Days</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.leaveDays}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Location</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employeeAnnexure?.place}}</td>
                                </tr>
                            </table>
                            <table class="table" data-pdfmake="{'widths':['*','*']}">
                                <tr style="vertical-align: top">
                                    <td style="border:1px solid #ccc;">
                                        <table class="table items table-bordered table-condensed table-hover" style="margin: 0;" data-pdfmake="{'widths':['*','*','*','*']}">
                                            <thead class="table_heading">
                                                <tr>
                                                    <th class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">Earnings</th>
                                                    <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">CTC Reference ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                    <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">Amount ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                    <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">YTD ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table_heading">
                                                <tr *ngFor="let e of Earnings">
                                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>{{e.hrmspaySlipElement.elementName}}</b></td>
                                                    <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.ctcReferenceDouble.toFixed(2)}}</td>
                                                    <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.amountDouble.toFixed(2)}}</td>
                                                    <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.ytdDouble.toFixed(2)}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Total </b></td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{EarningsCTCtotal.toFixed(2)}}</td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{EarningsAmounttotal.toFixed(2)}}</td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{EarningsYTDTotal.toFixed(2)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style="border:1px solid #ccc;">
                                        <table class="table items table-bordered table-condensed table-hover m-0" data-pdfmake="{'widths':['*','*','*','*']}">
                                            <thead class="table_heading">
                                                <tr>
                                                    <th class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">Deductions</th>
                                                    <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">CTC Reference ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                    <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">Amount ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                    <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">YTD ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table_heading">
                                                <ng-container  *ngFor="let e of Deductions" >
                                                   
                                                <tr *ngIf="e.hrmspaySlipElement.elementName != 'Employeer PF'" >
                                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>{{e.hrmspaySlipElement.elementName}}</b></td>
                                                    <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.ctcReferenceDouble.toFixed(2)}}</td>
                                                    <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.amountDouble.toFixed(2)}}</td>
                                                    <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.ytdDouble.toFixed(2)}}</td>
                                                </tr>
                                               
                                                </ng-container>
                                                <tr>
                                                    <td class="text-left" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Total </b></td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{DeductionsCTCtotal.toFixed(2)}}</td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{DeductionsAmounttotal.toFixed(2)}}</td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{DeductionsYTDTotal.toFixed(2)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table items table-bordered table-condensed table-hover m-0" style="width: 100%;" data-pdfmake="{'widths':['*','*','*']}">
                                        <thead class="table_heading">
                                            <tr>
                                                <td class style="height:30px;" style="border:1px solid #ccc;font-size: 13px;" colspan="3"><b>Summary of pay </b></td>
                                            </tr>
                                            <tr>
                                                <th class="tab-medium-width1 text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1">Total Earnings ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1">Total Deductions ( {{organizationSettings.country?.countrySymbol}} )</th>
                                                <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1">Net Pay ( {{organizationSettings.country?.countrySymbol}} )</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table_heading">
                                            <tr>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1"><b>{{EarningsAmounttotal.toFixed(2)}}</b></td>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1"><b>{{DeductionsAmounttotal.toFixed(2)}}</b></td>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1"><b>{{Roundof(EarningsAmounttotal- DeductionsAmounttotal).toFixed(2)}}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span style="padding:10px 0px;float:left;width:100%;font-size: 13px;">
                                        <b>Amount In Words : </b>{{NetPayInWords}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="benefits"></ul>
                                    <p class="text-muted" style="font-size: 13px;">*For any clarification please raise your request.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div  id="pdfTable" #pdfTable>   
    <div class="common-content">
        <div class="card-new">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table items table-bordered table-condensed table-hover" style="margin: 0;width: 100%;" data-pdfmake="{'widths':['*','*'],'heights':10}">
                                <tr>
                                    <td style="text-align: center;border:1px solid #ccc;padding: 0;" colspan="2">
                                        <div *ngIf="payrolldata!=undefined" style="font-size:18px;font-weight:700;margin:7px 0px 7px 0px;"><b>Payslip for the Month of {{payrolldata.finanicalPeriod.financialperiodMonth}} {{payrolldata.finanicalPeriod.financialPeriodYear}}</b></div>
                                        <div style="font-size:13px;font-weight:400;margin:7px 0px 7px 0px;;line-height:1;text-align: center;">Under Rule 78 (7) (b) of the Tamilnadu Contract Labour Rules,1975 </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="" colspan="1" style="width:50%;border:1px solid #ccc;margin:0px 0px 10px 0px;">
                                 
                                        <p class="header" style="font-size:20px;font-weight:700;margin: 0px 0px 0px 0px ;line-height:0.5;text-align: left;"><b>{{userdata.organization.organizationName}}</b></p>
                                        <span class="header" style="font-size:13px;font-weight:400;margin: 0px 0px 0px 0px ;line-height:1;text-align: left;">{{userdata.organization.address}}, {{userdata.organization.city.cityName}}, {{userdata.organization.state.stateName}}-{{userdata.organization.postalCode}}</span>
                                    </td>
                                    <td class="" colspan="1" style="width:50%;border:1px solid #ccc;margin:0px 0px 10px 0px;">
                                        <p class="header" *ngIf="payrolldata.client != undefined" style="font-size:20px;font-weight:700;margin: 0px 0px 0px 0px ;line-height:0.5;text-align: left;"><b>{{payrolldata.client.clientName}}</b></p>
                                        <span class="header" *ngIf="payrolldata.client != undefined" style="font-size:13px;font-weight:400;margin: 0px 0px 0px 0px ;line-height:1;text-align: left;">{{payrolldata.client.addressLine1}} {{payrolldata.client.addressLine2}}, {{payrolldata.client.city.cityName}}, {{payrolldata.client.state.stateName}}-{{payrolldata.client.postalCode}}</span>
                                    </td>
                                </tr>
                            </table>
                            <table class="table items table-bordered table-condensed table-hover" style="width: 100%;" data-pdfmake="{'widths':['*','*','*','*'],'heights':10}">
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Employee No</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.employeeNumber}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Employee Name</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.firstName}}</td>
                                </tr>
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Father/Hus Name</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"> {{payrolldata.employee.lastName}}</td>
                                     <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Department</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.department.departmentName}}</td> 
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Date of Birth</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.dateOfBirth | date :dateFormat}}</td>
                                </tr>
                            
                                <tr>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Aadhaar Number</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.aadhaarNumber}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>PAN No</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.panNumber}}</td>
                                </tr>
                             
                                <tr>
                                   
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>UAN No</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.uanNumber}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>PF No</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employee.pfNumber}}</td>
                                </tr>
                                
                                <tr>
                                 
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Total Paid Days</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employeeWorkingDays.workingDays}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>PF wages</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.client.elementClientHeader[0].pfMaxSalaryAmount.toFixed(2)}}</td>
                                </tr>
                                <tr>
                                  
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Arrear Days</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employeeWorkingDays.arrearDays}}</td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>OT Hours</b></td>
                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">{{payrolldata.employeeWorkingDays.otHours}}</td>
                                </tr>
                            </table>
                            <table class="table" data-pdfmake="{'widths':['*','*']}">
                                <tr style="vertical-align: top">
                                    <td style="border:1px solid #ccc;">
                                        <table class="table items table-bordered table-condensed table-hover" style="margin: 0;" data-pdfmake="{'widths':['*','*']}">
                                            <thead class="table_heading">
                                                <tr>
                                                    <th class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">Earnings</th>
                                                    <th class="" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">Amount ( {{organizationSettings.country.countrySymbol}} )</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table_heading">
                                                <tr *ngFor="let e of Earnings">
                                                    <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>{{e.hrmspaySlipElement.elementName}}</b></td>
                                                    <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.amount.toFixed(2)}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Total </b></td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{EarningsAmounttotal.toFixed(2)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style="border:1px solid #ccc;vertical-align: top !important;">
                                        <table class="table items table-bordered table-condensed table-hover m-0" data-pdfmake="{'widths':['*','*']}">
                                            <thead class="table_heading">
                                                <tr>
                                                    <th class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1">Deductions</th>
                                                    <th class="" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">Amount ( {{organizationSettings.country.countrySymbol}} )</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table_heading">
                                                <ng-container *ngFor="let e of Deductions">
                                                    <tr  >
                                                        <td class="tab-medium-width" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>{{e.hrmspaySlipElement.elementName}}</b></td>
                                                        <td class="tab-medium-width text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{e.amount.toFixed(2)}}</td>
                                                    </tr>
                                                    
                                                </ng-container>
                                                <tr>
                                                    <td class="text-left" style="border:1px solid #ccc;font-size: 13px;" colspan="1"><b>Total </b></td>
                                                    <td class="text-right" style="border:1px solid #ccc;font-size: 13px;text-align: right" colspan="1">{{DeductionsCTCtotal.toFixed(2)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table items table-bordered table-condensed table-hover m-0" style="width: 100%;" data-pdfmake="{'widths':['*','*','*']}">
                                        <thead class="table_heading">
                                            <tr>
                                                <td class style="height:30px;" style="border:1px solid #ccc;font-size: 13px;" colspan="3"><b>Summary of pay </b></td>
                                            </tr>
                                            <tr>
                                                <th class="tab-medium-width1 text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1">Total Earnings ( {{organizationSettings.country.countrySymbol}} )</th>
                                                <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1">Total Deductions ( {{organizationSettings.country.countrySymbol}} )</th>
                                                <th class="text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1">Net Pay ( {{organizationSettings.country.countrySymbol}} )</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table_heading">
                                            <tr>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1"><b>{{EarningsAmounttotal.toFixed(2)}}</b></td>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1"><b>{{DeductionsAmounttotal.toFixed(2)}}</b></td>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;" colspan="1"><b>{{(EarningsAmounttotal- DeductionsAmounttotal).toFixed(2)}}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span style="padding:0px 0px;float:left;width:100%;font-size: 13px;margin:10px 0px 0px 0px;">
                                        <b>Amount In Words : </b>{{NetPayInWords}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table items table-bordered table-condensed table-hover m-0" style="width: 100%;" data-pdfmake="{'widths':['*','*']}">
                                        <tbody class="table_heading">
                                            <tr>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;margin: 35px 0 0 0;height: 0px" colspan="1"><div>
                                                
                                                    <b>Signature of the Employee</b>
                                                </div></td>
                                                <td class="tab-medium-width text-center" style="border:1px solid #ccc;font-size: 13px;text-align: center;margin: 0px 0 0 0;padding-top: 0px;height: 50px" colspan="1"><div>
                                                    <p>Digitally genreated no signature needed</p>
                                                </div><b>Signature of the Employer</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="benefits"></ul>
                                    <p class="text-muted" style="font-size: 13px;">*For any clarification please raise your request.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

