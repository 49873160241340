import { Component, OnInit } from '@angular/core';
import { PayrollService } from '../providers/payroll.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../providers/properties';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ImportEmployeegroupComponent } from '../import-employeegroup/import-employeegroup.component';
import { ShowerrorComponent } from '../showerror/showerror.component';
import { ViewemployeegroupemployeesComponent } from '../viewemployeegroupemployees/viewemployeegroupemployees.component';

@Component({
  selector: 'app-employee-groups-list',
  templateUrl: './employee-groups-list.component.html',
  styleUrls: ['./employee-groups-list.component.scss']
})
export class EmployeeGroupsListComponent implements OnInit {

  usertData: any;
  orgId: any;
  clientList: any;
  searchForm!:FormGroup;
  resultData: any = {};
  p:number = 1;
  nodatafound: boolean = true;
  pagePerItem:any=10;
  sortedData: any;
  loader: boolean;
  backdrop: boolean;
  organizationId: any;
  sucess_msg: any;
  success: boolean;
  error: boolean;
  error_msg: any;
  importerror: boolean;
  errormessagejson: any;

  constructor( private payrollservice: PayrollService,
    private formBuilder: FormBuilder, private dialog : MatDialog,
    private router : Router,) { }

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
      this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);
      console.log(this.clientList);
    })    
    
    this.searchForm = this.formBuilder.group({
      keywords: [''],
      clientId: [null],
    });

  }
 
  importemployeegroups()
   {
    const dialogRef = this.dialog.open(ImportEmployeegroupComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
    });
  
    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
  
      if(result.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
      }
      else 
      {
         if(result.length != 0)
         {
  
          this.error = true;
          //this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
        //  this.showerrormessage();
          // setTimeout(() => {this.error = false}, redirectMsgTimeOut)
         }
          else
          {
          if(result.statusCode > 400)
          {
            this.error = true;
          
            this.importerror = false;
          }
          }
  
      }
    });
  
    }

    showerrormessage()
    {
      const dialogRef = this.dialog.open(ShowerrorComponent, {
        //height: 'auto',width: '600px',
        width: '500px',height:'fit-content',data: this.errormessagejson
      });
      this.error = false;
    }

  searchSubmit()
  {
    let organizationId = this.organizationId;

    let keywords = this.searchForm.value.keywords;

    let clientId = this.searchForm.value.clientId;

    if( !keywords && (!clientId || clientId == 'null'))
    {
      alert('Please search atleast one item');
      return;
    }

    if( keywords && (clientId && clientId != 'null') )
    {
      this.loader=true;
      this.backdrop=true;

      this.payrollservice.getEmployeegroups(keywords, clientId).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
    else if( keywords && (!clientId || clientId == 'null') )
    {
      this.loader=true;
      this.backdrop=true;

      this.payrollservice.getEmployeegroupsName(keywords).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
    else if( !keywords && (clientId && clientId != 'null') )
    {
      this.loader=true;
      this.backdrop=true;

      this.payrollservice.getEmployeeGroupsByclient(clientId).subscribe((resultData: any) =>
      {
        this.loader=false;
        this.backdrop=false;
        this.nodatafound = false;

        this.resultData = resultData;

        if(this.resultData.length == 0 || this.resultData.length == undefined)
        {
          this.nodatafound = true;
        }
      })
    }
  }

  viewemployees(empgrpid)
  {
    const dialogRef = this.dialog.open(ViewemployeegroupemployeesComponent, {
      //height: 'auto',width: '600px',
      width: '950px',height:'fit-content',data: empgrpid
    });
  }
  clearTable()
  {
    
    this.resultData = [];
    this.nodatafound = false;
    console.log('clear')
    this.searchForm.clearValidators;
    // this.payrollService.get().subscribe((elementslistData:any) =>
    // {
    //   console.log('elementslistData',elementslistData)
    //   this.resultData = elementslistData;
    //   console.log(this.resultData);
    //   if(this.resultData.length == 0)
    //   {this.noRecordsFound = true;
    //     this.nodatafound = true;
    //     console.log("nodata");
    //   }
    //   if(elementslistData.statusCode == 204){
    //     this.noRecordsFound = true;
    //   }
    // },
    // (error) => {
    //   this.nodatafound = true;
    //   console.log("error")
    //   if(error.status == 404){
    //     this.nodatafound = true;
    //     console.log("404")
    //   }
    // }
    // )
  }

  
  updateStatus(id,status)
	{
    this.payrollservice.updateEmployeeGroupsStatus(id,status).subscribe((result:any) =>
		{
      console.log(result);
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        this.searchSubmit();
      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
		})
	}
 sortData(sort: Sort)
  {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '')
    {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) =>
    {
      const isAsc = sort.direction === 'asc';

      switch (sort.active)
      {
        case 'roleName':
          return compare(a.roleName, b.roleName, isAsc);

        case 'employeeNumber':
          return compare(a.employeeNumber, b.employeeNumber, isAsc);

        case 'firstName':
          return compare(a.firstName, b.firstName, isAsc);

        case 'mobileNumber':
          return compare(a.mobileNumber, b.mobileNumber, isAsc);

        case 'organizationEmail':
          return compare(a.organizationEmail, b.organizationEmail, isAsc);

        case 'dateOfBirth':
          return compare(a.dateOfBirth, b.dateOfBirth, isAsc);

        case 'createDateTime':
            return compare(a.createDateTime, b.createDateTime, isAsc);

        default:
          return 0;
      }
    });
  }

}
function compare(a: number | string, b: number | string, isAsc: boolean)
{
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
