import { Component, Inject, OnInit } from '@angular/core';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImportEmployeerevenueadjustmentComponent } from '../import-employeerevenueadjustment/import-employeerevenueadjustment.component';

@Component({
  selector: 'app-import-employee-annexure',
  templateUrl: './import-employee-annexure.component.html',
  styleUrls: ['./import-employee-annexure.component.scss']
})
export class ImportEmployeeAnnexureComponent {
  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;
  fileInputLabel: string;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  employeefile :any;
  employeetData:any;
  usertData: any;
  orgId: any;
  clientList: any = [];
  errorrecords: any;
  showtable: boolean = false;

  p:number = 1;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  employeegroupnameexist: boolean;
  existMsg: string;
  submitbuttondisable: boolean = false;


  constructor(
    private settingsservice: SettingsService,
    private payrollservice: PayrollService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<ImportEmployeerevenueadjustmentComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  ngOnInit(): void {

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log(this.orgId);
    // this.payrollservice.getClientNameList(this.orgId).subscribe((result: any) => {
    //   this.clientList =  result.filter(client =>client.status.listTypeValueId == 1);;
    //   console.log(this.clientList);
    // })    

    this.postForm = this.formBuilder.group({
      file : ['',[Validators.required]],
    });
  }
  get validate() { return this.postForm.controls; }




  onSubmit()
  {
    this.submitted = true;
    if (this.postForm.invalid) {return;}
    this.submitbuttondisable = true;
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let employeeId = this.employeetData.employeeId;
    let organizationId = this.employeetData.organization.organizationId;
    this.loader = true;
    const formData = new FormData();
    formData.append("file", this.postForm.get('file').value);
    formData.append("organizationId", organizationId);
    console.log("formData",formData);
   
    return
    this.payrollservice.importemployeeannexue(formData).subscribe((result: any) =>
    {
      if(result.statusCode == 200)
      {
        this.loader = false;
        this.success = true;
        this.sucess_msg = result.description; 
        this.submitbuttondisable = false;
        this.dialogref.close(result);

      }
      else
      {
        this.error = true;
        this.error_msg = result.description;
        this.errorrecords = result;
        this.submitbuttondisable = false;
        this.dialogref.close(result);
  
      }
    }, error => {
      console.log(error);
    }); //Create end here
  }


  

  onFileSelect(event)
  {
   // alert("function called");
    //let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0)
    {
      const file = event.target.files[0];
      //this.fileToUpload = event.item(0);
      // console.log(file);

      /*   if (!_.includes(af, file.type)) {
        alert('Only EXCEL Docs Allowed!');
      } else { */
      // this.postForm.patchValue({
      //    file : file.name
      // })
        this.fileInputLabel = file.name;
        console.log("this.fileInputLabel" +this.fileInputLabel);
        this.postForm.get('file').setValue(file);
      ///}
    }
  }

 


  closedialog(){
    this.dialogref.close();
  }
}
