import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { httppmsOptions,httpOptions, httpOptionsTimeEntry, httpOptionsHrms} from './properties';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PmsService
{
  constructor(private http: HttpClient) { }

  /** KRA Template start here **/
  createKRATemplate(postValues): Observable<any>
  {
    let result = this.http.post(`${environment.pms_base_url}kratemplateheader`,postValues,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  editKRATemplate(id:any,postValues:any)
  {
    let result = this.http.put(`${environment.pms_base_url}kratemplateheader/${id}`,postValues,httppmsOptions).pipe(map(data=>data));
    return result;
  }

  createCheckExistTemplateName(postData: any)
  {
    return this.http.post(`${environment.pms_base_url}kratemplateheader/create/templateName`,postData,httppmsOptions).pipe(map(data => data));
  }

  updateCheckExistTemplateName(postData: any)
  {
    return this.http.post(`${environment.pms_base_url}kratemplateheader/update/templateName`,postData,httppmsOptions).pipe(map(data => data));
  }

  createCheckExistTemplateCode(postData: any)
  {
    return this.http.post(`${environment.pms_base_url}kratemplateheader/create/templateCode`,postData,httppmsOptions).pipe(map(data => data));
  }

  updateCheckExistTemplateCode(postData: any)
  {
    return this.http.post(`${environment.pms_base_url}kratemplateheader/update/templateCode`,postData,httppmsOptions).pipe(map(data => data));
  }

  getKRATemplateById(id:any)
  {
    let result = this.http.get(`${environment.pms_base_url}kratemplateheader/${id}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }

  updateKRATemplateStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.pms_base_url}kratemplateheader/deactive/${id}/${status}`,null,httppmsOptions).pipe(map(data=>data));
    return result;
  }

createemployeekratemplate(postValues): Observable<any>
{
  let result = this.http.post(`${environment.pms_base_url}employeekratemplate `,postValues,httppmsOptions).pipe(map(data=>data))
  return result;
}


  /* getModulesList(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}kratemplateheader`,httppmsOptions).pipe(map(data=>data))
    //let result =  this.http.get<any>(`${environment.pms_base_url}kratemplateheader/search/keyword/${id}/${keyword}`,httppmsOptions).pipe(map(data=>data))
    return result;
  } */

  editemployeekratemplate(postValues): Observable<any>
  {
    let result = this.http.post(`${environment.pms_base_url}employeekratemplate`,postValues,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getAssignkraById(id:any)
  {
    let result = this.http.get(`${environment.pms_base_url}employeekratemplate/${id}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }

  editAssignkra(id:any,postValues:any)
  {
    let result = this.http.put(`${environment.pms_base_url}employeekratemplate/${id}`,postValues,httppmsOptions).pipe(map(data=>data));
    return result;
  }

  getModulesList(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}kratemplateheader`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  /* getAssignkraList(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}employeekratemplate`,httppmsOptions).pipe(map(data=>data))
    return result;
  } */
  // getAssignkraList(organizationId: any):Observable<any>
  // {
  //   return this.http.get(`${environment.pms_base_url}employeekratemplate/organization/${organizationId}`,httppmsOptions)
  //   .pipe(map(data=>data));
  // }
  //PERFORMANCE API//
  getAssignkraList(organizationId: any):Observable<any>
  {
    return this.http.get(`${environment.pms_base_url}kra-template/${organizationId}`,httppmsOptions)
    .pipe(map(data=>data));
  }

  updateAssignkraStatus(id,status):Observable<any>
  {  
    let result = this.http.put(`${environment.pms_base_url}employeekratemplate/deactive/${id}/${status}`,null,httppmsOptions).pipe(map(data=>data));
    return result;
  }
  getKRATemplateIdList(id:number)
  {
    let result = this.http.get(`${environment.pms_base_url}kratemplateheader/${id}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }
  getKRAEmpCodeList(empCode:number,temName:any)
  {
    let result = this.http.get(`${environment.pms_base_url}employeekratemplate/search/employee/templateCode/${empCode}/${temName}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }
  // Filter in asignKRA Template

  // getKRATemplateTextList(empId:number,temName:any)
  // {
  //   let result = this.http.get(`${environment.pms_base_url}employeekratemplate/search/employee/templateName/${empId}/${temName}`,httppmsOptions).pipe(map(data=>data));
  //   return result;
  // }

  //PERFORMANCE API//
  getKRATemplateTextList(empId:number,temName:any)
  {
    let result = this.http.get(`${environment.pms_base_url}kra-template/employee/templateName/${empId}/${temName}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }

  // getAssignKRAEmpCodeList(empCode:number)
  // {
  //   let result = this.http.get(`${environment.pms_base_url}employeekratemplate/employee/${empCode}`,httppmsOptions).pipe(map(data=>data));
  //   return result;
  // }

   //PERFORMANCE API//
  getAssignKRAEmpCodeList(empCode:number)
  {
    let result = this.http.get(`${environment.pms_base_url}kra-template/employeeId/${empCode}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }
  

  
  // getAssignKRATemplateNameList(temName:any)
  // {
  //   let result = this.http.get(`${environment.pms_base_url}employeekratemplate/kraTemplateHeader/${temName}`,httppmsOptions).pipe(map(data=>data));
  //   return result;
  // }
  //PERFORMANCE API//
  getAssignKRATemplateNameList(temName:any)
  {
    let result = this.http.get(`${environment.pms_base_url}kra-template/templateName/${temName}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }

  getAssignKRATemplateOrgAndKeywordList(orgId,keyword)
  {
    let result = this.http.get(`${environment.pms_base_url}kratemplateheader/search/keyword/${orgId}/${keyword}`,httppmsOptions).pipe(map(data=>data));
    return result;
  }
  
  getKRATemplateList(ordId:number)
  {
     return this.http.get(`${environment.pms_base_url}kratemplateheader/organization/${ordId}`,httppmsOptions);
  }

  checkTemplateCode(temcode:any)
  {
     return this.http.post(`${environment.pms_base_url}kratemplateheader/create/templateCode`,temcode,httppmsOptions);
  }

  checkTemplateCodeUpdate(temcode:any)
  {
     return this.http.post(`${environment.pms_base_url}kratemplateheader/update/templateCode`,temcode,httppmsOptions);
  }



  checktemplateName(temname:any)
  {
     return this.http.post(`${environment.pms_base_url}kratemplateheader/create/templateName`,temname,httppmsOptions);
  }

  
  checktemplateUpdateName(temname:any)
  {
     return this.http.post(`${environment.pms_base_url}kratemplateheader/update/templateName`,temname,httppmsOptions);
  }


  employeeTemplateSearch(id:any,keyword:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}kratemplateheader/search/keyword/${id}/${keyword}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getApproverBranchList(orgId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}branch/organization/${orgId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  updateBranchStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.pms_base_url}branch/deactivate/${id}/${status}`,null,httppmsOptions).pipe(map(data=>data));
    return result;
  }
  getEmployeeApproverList(orgId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}branch/organization/${orgId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }
  getEmpApproverFiilterList(orgId,keywords): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}branch/organization/${orgId}/${keywords}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }
  updateEmpStatus(id,status):Observable<any>
  {
    let result = this.http.put(`${environment.pms_base_url}branch/deactivate/${id}/${status}`,null,httppmsOptions).pipe(map(data=>data));
    return result;
  }
// Manage Appraisal Filter
//  getApprisalEmpCode(empId:any): Observable<any>
//   {
//     let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/employee/${empId}`,httppmsOptions).pipe(map(data=>data))
//     return result;
//   }

//PERFORMANCE API//
getApprisalEmpCode(empId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/employeeId/${empId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // getApprisalEmpCodeNew(empId:any): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/pending/${empId}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }
   //PERFORMANCE API//
   getApprisalEmpCodeNew(empId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/pending/${empId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // getApprisalEmpCodeandStatus(keywords:any,status:any): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/employee/appriasalstatus/${keywords}/${status}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }
  //PERFORMANCE API//
   getApprisalEmpCodeandStatus(keywords:any,status:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/employeeId/apprisalStatus/${keywords}/${status}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  

  // getApprisalStatus(status:any,orgId:any): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/appriasalstatus/${status}/${orgId}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }

//  //PERFORMANCE API//
  // getApprisalStatus(status:any,orgId:any): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/apprisalStatus/OrganizationId/${status}/${orgId}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }

  getApprisalStatus(status:any,orgId:any,empId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/apprisalStatus/organizationId/employeeId/${status}/${orgId}/${empId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }


  // Admin Reports
  // getReportList(empId,month,financialyearId): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/assign/employee/${empId}/${month}/${financialyearId}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }
  getReportList(empId,month,financialyearId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/employeeId/financialYear/financialPeriod/${empId}/${month}/${financialyearId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getReportListEmp(empId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/assign/employee/${empId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  //   getReportListEmpAndYear(empId,financialyearId): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/assign/employee/${empId}/${financialyearId}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }
  getReportListEmpAndYear(empId,financialyearId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/employeeId/financialYear/${empId}/${financialyearId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // getReportListYearAndMonth(financialyearId,month): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/assign/${financialyearId}/${month}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }
  getReportListYearAndMonth(financialyearId,month): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/financialYear/financialPeriod/${financialyearId}/${month}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // getReportListYear(financialyearId): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/assigns/${financialyearId}`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }
  getReportListYear(financialyearId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/financialYear/${financialyearId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getReportEmpCodeList(empCode): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}branch/organization/${empCode}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }
  getReportMonthandYearList(month,year): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}branch/organization/${month}/${year}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // Apprisal Reports
  createApprisal(postValues): Observable<any>
{
  let result = this.http.post(`${environment.pms_base_url}apprisalheader/head`,postValues,httppmsOptions).pipe(map(data=>data))
  return result;
}

// update Apprasial
updateApprisal(updateValues,appHeaderId): Observable<any>
{
  let result = this.http.put(`${environment.pms_base_url}apprisalheader/${appHeaderId}`,updateValues,httppmsOptions).pipe(map(data=>data))
  return result;
}

// ApproverList
getApproverList(empId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employeeapprovers_by_empolyee/approverType/${empId}/PMS`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getemployeekratemplateList(empId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}employeekratemplate/currentperiod/employee/${empId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getWorkedHours(empId,yearid,monthid): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/employee/${empId}/financialYear/${yearid}/financialPeriod/${monthid}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getLeavedays(empId,yearid,monthid): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}employee-leave/leave-count/employee/financialPeriod/financialYear/${empId}/${monthid}/${yearid}`,httpOptionsHrms).pipe(map(data=>data))
    return result;
  }

 

  getPeriodList(orgId,year): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}finanicalperiod/organzation/finanicalPeriodyear/${orgId}/${year}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getApprisalList(id): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/${id}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

 
  getStatus(id): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalStatus/list-type-values/${id}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // Apprisal Reports
  getAppReportList(financialyear: any) {
    let result =  this.http.get<any>(`${environment.pms_base_url}finanicalyear/${financialyear}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getAppReportPeriodList(financialyear: any,fromperiod:any,toperiod:any) {
    let result =  this.http.get<any>(`${environment.pms_base_url}finanicalyear/${financialyear}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // Period ID Based List
  getPeriodBasedList(id): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}finanicalPeriod/${id}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  getManageapprisalApprisalList(empId:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/pending/employee/${empId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getAppsialReportPeriodList(fromperiod:any,toperiod:any): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalheader/financialPeriodId/${fromperiod}/${toperiod}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  getAppsialReportPeriodListYear(financialYearId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}apprisalHeader/apprisalReports/financialYear/${financialYearId}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }
  //createViewAppraisalDialog//

  createViewAppraisalDialog(payload: any)
  {
    return this.http.post(`${environment.pms_base_url}employeeacceptance`,payload,httppmsOptions).pipe(map(data => data));
  }

//NEW API FOR APPROVALS//

viewApprovalsDialog(appHeaderId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}approvalss/PMS/${appHeaderId}`,httpOptions).pipe(map(data=>data))
    return result;
  }


 

  PmsdashboardData(): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}admin-dashboard`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  PmsdashboardData1(organizationId): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.base_url}v2/employee/organization/active/${organizationId}`,httpOptions).pipe(map(data=>data))
    return result;
  }

  PmsdashboardData2(monthid,yearid): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatusName/financialPeriod/financialYear/Pending/${monthid}/${yearid}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  PmsdashboardData3(monthid,yearid): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatusName/financialPeriod/financialYear/Reviewed/${monthid}/${yearid}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  PmsdashboardData4(monthid,yearid): Observable<any>
  {
    let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatusName/financialPeriod/financialYear/Closed/${monthid}/${yearid}`,httppmsOptions).pipe(map(data=>data))
    return result;
  }

  // PmsdashboardData2(): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatus/Pending`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }

  // PmsdashboardData3(): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatus/Reviewed`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }

  // PmsdashboardData4(): Observable<any>
  // {
  //   let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatus/Closed`,httppmsOptions).pipe(map(data=>data))
  //   return result;
  // }
                                                    


 //dashboard monthwise status based count

 PmsdashboardDataPendingCount(monthid,yearid): Observable<any>
 {
   let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatusName/financialPeriod/Year/count/Pending/${monthid}/${yearid}`,httppmsOptions).pipe(map(data=>data))
   return result;
 }

 PmsdashboardDataReviewedCount(monthid,yearid): Observable<any>
 {
   let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatusName/financialPeriod/Year/count/Reviewed/${monthid}/${yearid}`,httppmsOptions).pipe(map(data=>data))
   return result;
 }

 PmsdashboardDataClosedCount(monthid,yearid): Observable<any>
 {
   let result =  this.http.get<any>(`${environment.pms_base_url}v2/apprisalheader/apprisalstatusName/financialPeriod/Year/count/Closed/${monthid}/${yearid}`,httppmsOptions).pipe(map(data=>data))
   return result;
 }






}
