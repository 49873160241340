import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../providers/settings.service';
import { PayrollService } from '../providers/payroll.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import Swal from 'sweetalert2';
import { ExistLoanDetailsComponent } from '../exist-loan-details/exist-loan-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent implements OnInit {
  loanForm:FormGroup;
  submitted: boolean=false;
  organzationId: any;
  userdata: any;
  Disable_submitToApprover:boolean=false;
  Disable_submitAsDraft:boolean=false;
  show_draft:boolean=true;
  show_submit:boolean=true;
  success: boolean=false;
  sucessmessage: any;
  tableData: any[] = [];
  installmentCount: number = 0;
  interestPercentage:any;
  emi:any;
  loanId: any;
  employeedata: any;
  loanEligible: any;
  organizationSettings: any;
  p: number = 1;
  pagePerItem:any=5;
  totalPayment: any;
  InterestPayable: any;
  loanObject: any;
  showError:boolean=false;
  constructor(
    public router:Router,
    private settingsService: SettingsService,
    private payrollservice:PayrollService,
    public fb :FormBuilder,
    private gs : GlobalserviceService,
    public activeRouter : ActivatedRoute,
    public dialog :MatDialog
  ) { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log(this.userdata);
    this.organzationId = this.userdata.organization.organizationId;
    console.log("organziationid " + this.organzationId);

    this.loanId = this.activeRouter.snapshot.params.id;
    console.log(this.loanId);

    this.loanForm=this.fb.group({
      existingLoanAmount:['',Validators.required],
      loanAmountEligible:['',Validators.required],
      loanType:[null,''],
      loanAmount:['',Validators.required],
      installments:['',Validators.required]
    });

    this.gs.getemployee(this.userdata.employee.employeeId).subscribe(
      (data:any)=>{
          this.employeedata=data;
          console.log("this.employeedata",this.employeedata);
      }
    )
    this.gs.getLoanEligibilityDetails(this.userdata.employee.employeeId,this.organzationId).subscribe(
      (data:any)=>{
        this.loanEligible=data;
        console.log("this.loanEligible",this.loanEligible);
        this.loanForm.patchValue({
          existingLoanAmount:this.loanEligible.loanApprovedAmount,
          loanAmountEligible:this.loanEligible.loaneligibleAmount
        })
        if(this.loanEligible.loanRequested == true){
          Swal.fire({
            text: 'Loan requested already!',
            icon: 'error'
          })
          return
        }
        console.log(this.loanForm.value);
      }
    )
    this.gs.getOrganizationdetails(this.organzationId).subscribe(
      (data:any)=>{
        this.organizationSettings=data;
        console.log("this.organizationSettings",this.organizationSettings);
        this.interestPercentage=this.organizationSettings.maxLoanInterest;
      }
    )
    if(this.loanId){
      this.gs.getLoanlistByLoanId(this.loanId).subscribe(
        (data:any)=>{
          this.loanObject=data;
          console.log(this.loanObject);
          this.loanForm.patchValue({
            // existingLoanAmount:this.loanEligible.loanApprovedAmount,
            // loanAmountEligible:this.loanEligible.loaneligibleAmount,
            // loanType:,
            loanAmount:this.loanObject.loanRequestedAmount,
            installments:this.loanObject.employeeLoanInstallments.length
          })
          let event ={
            target :{
              value :this.loanObject.employeeLoanInstallments.length
            }
          }
          console.log("event",event);
          this.generateTable(event);
        }
      )
    }
  }
  submitAsDraft(){
    console.log("submit as draft");
    this.submitted=true;
    console.log(this.loanForm.invalid);
    console.log(this.loanForm.value);
    if(this.loanForm.invalid){
      return
    }
    if(this.showError == true){
      return
    }
    if(this.loanEligible.loanRequested == true){
      Swal.fire({
        text: 'Loan requested already!',
        icon: 'error'
      })
      return
    }
    this.Disable_submitAsDraft=true;
    if(this.loanId){
      let updateloan={
        employeeLoanId: this.loanObject.employeeLoanId,
        employeeLoanNumber : this.loanObject.employeeLoanNumber,
        loanRequestedAmount: this.loanForm.value.loanAmount,
        loanApprovedAmount: this.loanForm.value.existingLoanAmount,
        employeeLoanStatus: {
          listTypeValueId: this.loanObject.employeeLoanStatus.listTypeValueId,
          listTypeValueName: this.loanObject.employeeLoanStatus.listTypeValueName
        },
        employee:{
          employeeId : this.userdata.employee.employeeId
        },
        ['draft'] : true, 
        employeeLoanInstallments : this.tableData,
      }
      console.log("updateloan",updateloan);
      this.gs.updateLoan(this.loanId,updateloan).subscribe(
        ((data:any)=>{
            console.log(data);
            this.sucessmessage =  data.description;
            this.success=true;
            setTimeout(() => {
              this.router.navigate(["home/loan-list"]);
            }, 2000);
        })
      )
    }
    else{
      let createloan={
        loanRequestedAmount: this.loanForm.value.loanAmount,
        employee:{
          employeeId : this.userdata.employee.employeeId
        },
        employeeLoanInstallments : this.tableData,
        ['draft'] : true, 
      }
      console.log("createloan",createloan);
      this.gs.createLoan(createloan).subscribe(
        ((data:any)=>{
            console.log(data);
            this.sucessmessage =  data.description;
            this.success=true;
            setTimeout(() => {
              this.router.navigate(["home/loan-list"]);
            }, 2000);
        })
      )
    }
  }
  submitToApprover() {
    console.log("submit");
    this.submitted=true;
    console.log(this.loanForm.invalid);
    console.log(this.loanForm.value);
    if(this.loanForm.invalid){
      return
    }
    if(this.showError == true){
      return
    }
    if(this.loanEligible.loanRequested == true){
      Swal.fire({
        text: 'Loan requested already!',
        icon: 'error'
      })
      return
    }
    this.Disable_submitToApprover=true;
    if(this.loanId){
      let updateloan={
        employeeLoanId: this.loanObject.employeeLoanId,
        employeeLoanNumber : this.loanObject.employeeLoanNumber,
        loanRequestedAmount: this.loanForm.value.loanAmount,
        loanApprovedAmount: this.loanForm.value.existingLoanAmount,
        employeeLoanStatus: {
          listTypeValueId: this.loanObject.employeeLoanStatus.listTypeValueId,
          listTypeValueName: this.loanObject.employeeLoanStatus.listTypeValueName
        },
        employee:{
          employeeId : this.userdata.employee.employeeId
        },
        ['draft'] : false, 
        employeeLoanInstallments : this.tableData,
      }
      console.log("updateloan",updateloan);
      this.gs.updateLoan(this.loanId,updateloan).subscribe(
        ((data:any)=>{
            console.log(data);
            if(data.statusCode >=400){
              Swal.fire({
                text: data.description,
                icon: 'error',
                confirmButtonText:'OK'
              })
            }
            else{
              this.sucessmessage =  data.description;
              this.success=true;
              setTimeout(() => {
                this.router.navigate(["home/loan-list"]);
              }, 2000);
            }
        })
      )
    }
    else{
      let createloan={
        loanRequestedAmount: this.loanForm.value.loanAmount,
        employee:{
          employeeId : this.userdata.employee.employeeId
        },
        employeeLoanInstallments : this.tableData,
        ['draft'] : false, 
      }
      console.log("createloan",createloan);
      return
      this.gs.createLoan(createloan).subscribe(
        ((data:any)=>{
            console.log(data);
            this.sucessmessage =  data.description;
            this.success=true;
            setTimeout(() => {
              this.router.navigate(["home/loan-list"]);
            }, 2000);
        })
      )
    }
  }
  cancel(){
    this.router.navigate(['home/loan-list']);
  }
  generateTable(event){
    this.installmentCount = event.target.value;
    this.tableData = [];
    // if (!this.loanForm.value.loanAmount || !this.interestPercentage || !this.installmentCount) {
    //   alert('Please fill all fields before generating the table.');
    //   return;
    // }
    if (this.installmentCount > 0) {
      const monthlyInterestRate = this.interestPercentage / 12 / 100;
      console.log("monthlyInterestRate",monthlyInterestRate);
      const numerator =  this.loanForm.value.loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, this.installmentCount);
      console.log("numerator",numerator);
      const denominator = Math.pow(1 + monthlyInterestRate, this.installmentCount) - 1;
      console.log("denominator",denominator);
      this.emi = (numerator / denominator).toFixed(2);
      console.log("emi",this.emi);
      this.totalPayment= (this.emi * this.installmentCount).toFixed(2);
      console.log(this.totalPayment);
  
      for(let i = 1; i <= this.installmentCount; i++) {
       let installmentsObject={
          installmentNumber: i, 
          loanInstallmentAmount: this.emi
        }
        this.tableData.push(installmentsObject);
      }
      console.log(this.tableData);
      this.InterestPayable= (this.totalPayment-this.loanForm.value.loanAmount).toFixed(2);
      console.log(" this.InterestPayable", this.InterestPayable);
    }
  }
  checkLoanEligibility(event: any) {
    console.log('Key up event occurred:', event.target.value);
    console.log("advanceEligible ",this.loanForm.value.loanAmountEligible);
    this.loanForm.patchValue({ installments: '' });
    if (event.target.value > this.loanForm.value.loanAmountEligible) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }
  togglePasswordVisibility() {
    this.dialog.open(ExistLoanDetailsComponent,{
      width: '100%',
      height:'90%',
    })
  }

}
