<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-content">Request Loan
                        <!-- {{employeeLeaveId ? "Edit" : "Request"}} Salary Advance -->
                     </span>
                </div>
                <div class="sub-header-buttons">
                    <a routerLink="/home/loan-list" mat-raised-button title="Back" class="">Back</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="common-content">
    <div class="card-new">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="loanForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Employee Number</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class">{{userdata.employee.employeeNumber}}</label>
                                </div>
                            </div>           
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Employee Name</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class">{{userdata.employee.firstName}} {{userdata.employee.lastName}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Total Salary</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label *ngIf="loanEligible" class="form-control-label-class">{{loanEligible.totalSalary}}</label>
                                </div>
                            </div>           
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Designation</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label *ngIf="employeedata" class="form-control-label-class">{{employeedata.designation.designationName}}</label>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Interest Percentage </label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label *ngIf="organizationSettings != undefined" class="form-control-label-class">{{organizationSettings.maxLoanInterest}} %</label>
                                </div>
                            </div>           
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label">Processing Fee</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class">Processing Fee ?</label>
                                </div>
                            </div>
                        </div>
                    </div>              
                    <div class="row">
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                            <label class="col-control-label">Existing Loan Amount<span class="text-danger"> *</span></label>
                            <input type="text" matInput formControlName="existingLoanAmount" 
                                class="form-control search-input form-control form-control-lg form-control-solid input-field" readonly>
                                <!-- <mat-icon class="view-icon material-icons-outlined" (click)="togglePasswordVisibility()">visibility</mat-icon> -->
                                <a class="view-icon material-icons" (click)="togglePasswordVisibility()">visibility</a>
                                <mat-error *ngIf="submitted && loanForm.controls['existingLoanAmount'].errors?.required">
                                    Existing Loan Amount is required
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                            <label class="col-control-label">Loan Amount Eligible<span class="text-danger"> *</span></label>
                            <input type="text" matInput formControlName="loanAmountEligible" 
                                class="form-control search-input form-control form-control-lg form-control-solid" readonly>
                                <mat-error *ngIf="submitted && loanForm.controls['loanAmountEligible'].errors?.required">
                                    Loan Amount Eligible is required
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Loan Type<span class="text-danger"> *</span></label>
                                <select formControlName="loanType" class="form-select form-select-solid form-select-lg select2-new">
                                    <option [value]="null"> -- Select Loan Type --- </option>
                                    <option [value]=1>Type 1 </option>
                                    <option [value]=2>Type 2 </option>
                                </select>
                                <mat-error *ngIf="submitted && loanForm.controls['loanType'].errors?.required">
                                    Loan Type is required
                                </mat-error>
                            </div>
                        </div>
                    </div>   
                    <div class="row">
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Loan Amount<span class="text-danger"> *</span></label>
                                <input type="text" matInput formControlName="loanAmount" (keyup)="checkLoanEligibility($event)"
                                placeholder="Enter Loan Amount"
                                class="form-control search-input form-control form-control-lg form-control-solid">
                                <mat-error *ngIf="submitted && loanForm.controls['loanAmount'].errors?.required">
                                    Loan amount is required
                                </mat-error>
                                <div *ngIf="showError" style="color: red;font-size: 11px;">
                                    Advance Amount cannot be greater than Advance Eligible.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="input-width" id="loginform">
                                <label class="col-control-label">Installments<span class="text-danger"> *</span></label>
                                <input type="text" matInput formControlName="installments" (keyup)="generateTable($event)"
                                placeholder="Enter Installments"
                                class="form-control search-input form-control form-control-lg form-control-solid ">
                                <mat-error *ngIf="submitted && loanForm.controls['installments'].errors?.required">
                                    Installments is required
                                </mat-error>
                            </div>
                        </div>
                    </div>   
                    <div class="row" *ngIf="tableData.length > 0">
                        <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12">
                            <table class="table table-border-new" >
                                <thead class="t-head">
                                  <tr>
                                    <th class="text-center">Installment Number</th>
                                    <th class="text-center">Installment Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let d of tableData | paginate: { itemsPerPage: pagePerItem, currentPage: p } ; let i = index ">
                                    <!-- <td>{{i + 1 }}</td> -->
                                    <td class="text-center table-data" *ngIf="p">{{((p-1)*pagePerItem)+(i+1)}}</td>
                                    <td class="text-center table-data" *ngIf="!p">{{i+1}}</td>
                                    <td class="text-right" >{{d.loanInstallmentAmount}}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="row">
                                <div class="col-md-12 text-right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12">
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label" style="font-weight: 500;">Total Payment <small class="text-nowrap">( including interest )</small></label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class" style="font-weight: 500;">{{totalPayment}}</label>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="label-name">
                                    <label class="form-control-label" style="font-weight: 500;">Interest Payable</label>
                                </div>
                                <div>:</div>
                                <div>
                                    <label class="form-control-label-class" style="font-weight: 500;">{{InterestPayable}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row buttons">
                        <div class="col-md-12 text-right">
                            <button type="reset" (click)="cancel()"
                                class="mat-focus-indicator ml-2 mat-raised-button mat-button-base">Cancel</button> 
                            <button type="button" (click)="submitAsDraft()" [disabled]="Disable_submitAsDraft" *ngIf="show_draft"
                                class="mat-focus-indicator ml-2 mat-raised-button mat-button-base mat-primary">Save as Draft</button>                             
                            <button type="button" (click)="submitToApprover()" [disabled]="Disable_submitToApprover" *ngIf="show_submit"
                                class="mat-focus-indicator ml-2 mat-raised-button mat-button-base mat-primary">Submit to Approval</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="text-center" *ngIf="success"> 
    <div class="alert alert-resource alert-success" role="alert">
        <strong>Success!</strong><br>{{sucessmessage}}
    </div>
</div>
<!-- <div *ngIf="error" class="alert alert-resource alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{update_data_error_msg}}
</div> -->
