import { Component, ElementRef,OnInit } from '@angular/core';
import { PayrollService } from 'src/app/providers/payroll.service';
import { redirectMsgTimeOut, responseMsgTimeOut } from '../../providers/properties';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-manage-elements-list',
  templateUrl: './manage-elements-list.component.html',
  styleUrls: ['./manage-elements-list.component.scss']
})
export class ManageElementsListComponent implements OnInit {
  showtable:boolean=false;
  isProcessing = false;
  isprocessing1=false;

  searchForm:FormGroup;
  list_loader = true;
  elementslistData:any[];
  elementslistData1:any[];
  nodata = false;
  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  p: number = 1;
  query: any = '';
  beforepage :any;
  mySelect:any =5;
  pagePerItem:any=5;
  categoryTypeData :any;
  listTypeValueId:any;
  employeetData : any;
  isDataLoading: boolean = true;
  elementform:FormGroup;
  loader:any= false;
  backdrop:any= false;
  elementId:number;
  submitted: boolean = false;
  usertype:any;
  update_data_error_msg:any;
  userdata:any=[];
  showCategoryType: boolean = false;
  categoryTypeSearchData:any;
  noRecordsFound: boolean =false;

  elementslistData1length = 0;
  length: any
  formsubmitted: boolean = false;
  organizationId: any;
  pasteCount: any;
  entityId:number;
  clientList:any[]=[];
  clientLogin: boolean = false;
  organizationLogin: boolean = false;
  entityAdminLogin: boolean = false;
  usertData: any;
  clientId: any;
  clientName:any
  constructor(private payrollservice:PayrollService,
    private dialog:MatDialog,
    private payrollService:PayrollService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private ActivatedRoute:ActivatedRoute,) { 
    }

  ngOnInit(): void {
  this.onItemsPerPageChange;
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.organizationId = organizationId;
    this.entityId = this.employeetData.entity.entityId;
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));


    if (this.usertData?.organization?.organizationId) {
      this.organizationLogin = true;
    }
     else if (this.usertData?.employee?.client) {
      this.clientLogin = true;
      console.log('this.clientLogin', this.clientLogin);
      this.clientId = this.usertData?.employee?.client?.clientId;
      this.clientName=this.usertData?.employee?.client?.clientName;
      console.log("this.this.clientName::",this.clientName);
      
    } else {
      this.entityAdminLogin = true;
    }
    this.listCategoryType();
    this.patchresource();

    this.noRecordsFound = true;

    if(this.elementId)
    { 
      console.log('elementId present')
      
        this.elementform = new FormGroup(
        {

          // client: new FormControl(null),
          // categoryType: new FormControl(this.elementform,[Validators.required]), 
          // elementName : new FormControl(this.elementform,[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]),
          // // orderNumber : new FormControl(this.elementform,[Validators.pattern(/^[0-9]*$/),Validators.minLength(1), Validators.maxLength(2)]), 
          // orderNumber : new FormControl(this.elementform,[Validators.required,Validators.minLength(1), Validators.maxLength(2)]), 
          // elementDescription : new FormControl(this.elementform), 
          // elementCode : new FormControl(this.elementform,[Validators.required,Validators.minLength(2), Validators.maxLength(10)])
          client: new FormControl(null), // No initial value and no validators
          categoryType: new FormControl(null, [Validators.required]),
          elementName: new FormControl(null, [
            Validators.required,
            Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
          ]),
          orderNumber: new FormControl(null, [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(2),
          ]),
          elementDescription: new FormControl(null), // No validators
          elementCode: new FormControl(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10),
          ]),
        }
      );
   
    }
    else
    {   console.log('elementId not present')
        this.elementform = new FormGroup(
        {
          // client: new FormControl(null),
          // categoryType: new FormControl(this.elementform,[Validators.required]), 
          // elementName : new FormControl(this.elementform,[Validators.required,Validators.pattern(/^[a-zA-Z&_]+(([. ][a-zA-Z&_ ])?[a-zA-Z&_]*(&[a-zA-Z&_]*)?)*$/)]),
          // // orderNumber : new FormControl(this.elementform,[Validators.pattern(/^[0-9]*$/),Validators.minLength(1), Validators.maxLength(2)]), 
          // orderNumber : new FormControl(this.elementform,[Validators.required,Validators.minLength(1), Validators.maxLength(2)]), 
          // elementDescription : new FormControl(this.elementform), 
          // // elementCode : new FormControl(this.elementform,[Validators.required])
          // elementCode : new FormControl(this.elementform,[Validators.required,Validators.minLength(2), Validators.maxLength(10)])
          client: new FormControl(null), // No initial value and no validators
          categoryType: new FormControl(null, [Validators.required]),
          elementName: new FormControl(null, [
            Validators.required,
            Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/),
          ]),
          orderNumber: new FormControl(null, [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(2),
          ]),
          elementDescription: new FormControl(null), // No validators
          elementCode: new FormControl(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10),
          ]),
        }
      );
    } 

    this.searchForm = this.formBuilder.group({
      filterclient: [null],
      categoryTypeSearch: [null, Validators.required] // Initialize with null
    });

    if(this.clientLogin == true){
      console.log("client login is logged in!!!");
      
      this.searchForm.get('filterclient')?.patchValue(this.clientName);
      this.searchForm.get('filterclient')?.disable();
 
      
    }
    if(this.clientLogin == true){
      this.elementform.get('client')?.patchValue(this.clientName);
      
     this.elementform.get('client')?.disable();
    }
    this.listCategoryType();
    this.payrollservice.getClientNameListBasedEntity(this.entityId).subscribe((result: any) => {
      this.clientList = result.filter(client => client?.status?.listTypeValueId == 1);;
      console.log(this.clientList);
    })
    // if(this.clientLogin == true){
    //   this.elementform.get('client')?.patchValue((this.clientId));
    //   this.elementform.get('client')?.disable();
    //   this.searchForm.get('filterclient')?.patchValue((this.clientId));
    //   this.searchForm.get('filterclient')?.disable();
    // }
    
    
  }

  // clear(){
  //   this.elementform.reset();
   
  //   return;
  // }
  clearForm(){
    console.log("reset came!!!")
    const currentClientName =this.clientName; 
    console.log("currentClientName",currentClientName);
    
  
    this.elementform.patchValue({
      elementCode  : null,
      categoryType:null,

      client:currentClientName,
      elementDescription :null,
      orderNumber:null,
      elementName:null
    });
    this.elementform.get('client')?.disable();
  }

  clearTable()
  {
  //   const currentClientName = this.searchForm.get('filterclient')?.value || this.clientName;
  //     console.log("currentClientName",currentClientName);
      

  // // Reset the form but exclude filterclient from being modified
  // this.searchForm.patchValue({
  //   filterclient: currentClientName, // Retain the client field
  //   categoryTypeSearch: null // Reset other fields as needed
  // });

  // this.searchForm.get('filterclient')?.disable(); // Keep the field disabled if necessary

  // // Clear other variables and states
  // this.elementslistData1 = [];
  // this.noRecordsFound = false;
  // console.log('clear');
  // this.noRecordsFound = true;
  // this.showtable = false;
  // this.p = 1;

  this.elementslistData1 = [];
  this.submitted = false;
  this.showtable = false;
  this.noRecordsFound = true;

  console.log('clear');
  
  // Store the current client name before resetting the form
  const currentClientName = this.clientName;
  console.log("currentClientName:", currentClientName);

  // Reset the form but retain the client name
  this.searchForm?.patchValue({
    filterclient: currentClientName, // Retain client name
    categoryTypeSearch: null // Reset other fields
  });

  // Ensure filterclient field remains disabled
  this.searchForm?.get('filterclient')?.disable();
  }

  onlyNumber(event) {
    console.log("only number"+event);
    console.log("only number" + event.charCode);
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) 
    {
      event.preventDefault();
    }
  }

  onlyLetters(event: KeyboardEvent) {
    console.log("Only text:"+event);
    console.log("only letters: " + event.key);
    const pattern = /[a-zA-Z]/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }
  
  
  elementList()
  {
    this.loader=true;
    this.isDataLoading = true;
    console.log('list')
    this.noRecordsFound = false;
    this.payrollservice.elementList(this.organizationId).subscribe((elementslistData:any) =>
    {
      this.loader=false;

      this.isDataLoading = false;
      console.log('elementslistData',elementslistData)
      this.list_loader = false;
      this.elementslistData1 = elementslistData;
      console.log(this.elementslistData1);
      this.elementslistData1length = this.elementslistData1.length;
      if(this.elementslistData1.length == 0)
      {
        this.noRecordsFound = true;
        console.log("nodata");
      }else{
        this.noRecordsFound = false;
      }

      if(elementslistData.statusCode == 204){
        this.noRecordsFound = true;
        console.log("nodata");
      }{
        this.noRecordsFound = false;
      }
    },
    (error) => {
      this.loader=false;

      this.list_loader = false;
      this.noRecordsFound = true;
      console.log("error")
      if(error.status == 404){
        this.noRecordsFound = true;
        console.log("404")
      }
    }
    )
  }
  

  listTypeValueid()
  {
    this.searchForm.get('categoryTypeSearch').value;
    console.log('event', this.searchForm.get('categoryTypeSearch').value);
    this.listTypeValueId = this.searchForm.get('categoryTypeSearch').value;
    console.log('this.listTypeValueId', this.listTypeValueId)
    if( this.listTypeValueId  == 'null')
    {
      this.searchForm.patchValue({

        categoryTypeSearch : null
      })
    }
    
  }

  listTypeValueid1()
  {
    this.elementform.get('categoryType').value;
    console.log('event', this.searchForm.get('categoryType').value);
    this.listTypeValueId = this.searchForm.get('categoryType').value;
    console.log('this.listTypeValueId', this.listTypeValueId)
    if( this.listTypeValueId  == 'null')
    {
      this.searchForm.patchValue({

        categoryType : null
      })
    }
    
  }
  onItemsPerPageChange(event: any) {
    this.pagePerItem = event.target.value;
    this.p = 1; // Reset the pagination to the first page when items per page is changed
  }
  searchSubmit()
  {
    this.onItemsPerPageChange;
    this.showtable=true;
    this.isProcessing=true;

    this.elementslistData1 = [];
    this.noRecordsFound = false;
    this.loader=true;
    console.log(this.searchForm.value)
    console.log( "formsubmitted" + this.formsubmitted);
    console.log("this.clientId::",this.clientId);
    
    if(this.searchForm.value.categoryTypeSearch == null   && this.formsubmitted == false){
      console.log("this.searchForm.value.categoryTypeSearch",this.searchForm.value.categoryTypeSearch)
      // this.elementList();
     alert("Please Select Element Type!");
    this.isProcessing=false;
      this.noRecordsFound = false;
      this.loader=false;
      this.showtable=false;
   
    }else if(this.searchForm.value.categoryTypeSearch != null){
      console.log("this.searchForm.value.categoryTypeSearch",this.searchForm.value.categoryTypeSearch)
      const categoryType = this.searchForm.get('categoryTypeSearch').value;
      this.payrollservice.listByClientCategoryType(this.searchForm.value.categoryTypeSearch, this.clientId ).subscribe( (data:any) =>{
        this.showtable=true;

        console.log('data',data);
        this.elementslistData1 = data;
        this.elementslistData1length = this.elementslistData1.length;
        this.loader=false;
        if( data.statusCode == 204){
          this.noRecordsFound = true;
        }
        this.isProcessing=false;
        console.log('this.elementslistData1',this.elementslistData1);
       
      }, (error) => {
        console.log('error')
        this.loader=false;
        this.isProcessing=false;
      });
    }else if(this.searchForm.value.categoryTypeSearch == null && this.formsubmitted == true){
      console.log('both true')
      this.noRecordsFound = true;
      this.loader=false;
      this.isProcessing=false;

    }
    this.formsubmitted = false;
  }

  callTable(){
    this.payrollservice.listByClientCategoryType(this.elementform.value.categoryType, this.clientId ).subscribe( (data:any) =>{
      this.showtable=true;

      console.log('data',data);
      this.elementslistData1 = data;
      this.elementslistData1length = this.elementslistData1.length;
      this.loader=false;
      if( data.statusCode == 204){
        this.noRecordsFound = true;
      }
      this.isProcessing=false;
      console.log('this.elementslistData1',this.elementslistData1);
     
    }, (error) => {
      console.log('error')
      this.loader=false;
      this.isProcessing=false;
    });
  }

  statuschangeactive(elementId,status)
  {
    console.log('this.listTypeValueId',this.listTypeValueId);
    console.log("elementId : "+elementId);
    console.log("status"+status);

   this.payrollservice.elementstatus(elementId,status).subscribe( (data:any) =>
   {
      if(data.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = data.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        if(this.listTypeValueId == null || this.listTypeValueId == 'null' || this.listTypeValueId == 0 || this.listTypeValueId == undefined){
           this.elementList();
         }else{
          this.listByCategoryId();
         }
      }
      else
      {
        this.error = true;
        this.error_msg = data.message;

        setTimeout(()=>{
          this.error = false;
          this.error_msg = '';
        },3000);
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }

   })
  }

listByCategoryId(){
  this.payrollservice.listByClientCategoryType(this.searchForm.value.categoryTypeSearch, this.searchForm.value.filterclient ).subscribe( (data:any) =>{
    console.log('data',data);
    this.elementslistData1 = data;
    this.elementslistData1length = this.elementslistData1.length;
    if( data.statusCode == 204){
      this.noRecordsFound = true;
    }
    console.log('this.elementslistData1',this.elementslistData1);
   
  }, (error) => {
    console.log('error')
  });
}

  listCategoryType()
  {

    this.payrollService.listCategoryType(this.organizationId).subscribe((result: any) => {
      this.categoryTypeData = result;
      this.categoryTypeSearchData = result;
    })
  }
  statuschangeinactive(elementId,status)
  {
    console.log('this.listTypeValueId',this.listTypeValueId);

   this.payrollservice.elementstatus(elementId,status).subscribe( (data:any) =>
   {
      if(data.statusCode == 200)
      {
        this.success = true;
        this.sucess_msg = data.message;
        setTimeout(() => {this.success  = false;},redirectMsgTimeOut)

        if(this.listTypeValueId == null || this.listTypeValueId == 'null' || this.listTypeValueId == 0 || this.listTypeValueId == undefined){
          this.elementList();
        }else{
         this.listByCategoryId();
        }
      
      }
      else
      {
        this.error = true;
        this.error_msg = data.message;
        setTimeout(() => {this.error = false}, redirectMsgTimeOut)
      }
   })
  }

  onKeyUpEvent(event)
  {
    if(this.query.length !=0)
    {
      this.p=1;
    }
    else
    {
      this.p = this.beforepage;
    }
  }

  get categoryTypeSearch()
  {
    return this.searchForm.get('categoryTypeSearch');
  }
  edit(elementId){
    console.log('elementId', elementId)
    this.elementId = elementId;
    
    this.patchresource();
    this.scrollToEditForm();
  }

  
  patchresource()
  { 
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    console.log('patch')
    if(this.elementId)
    {      
      this.payrollService.elementbyid(this.elementId).subscribe((userdata:any) =>
      {        console.log('userdata', userdata)
        this.userdata=userdata;
         console.log('this.userdata', this.userdata)
        
        this.elementform.patchValue({

          elementId : this.userdata.elementId,
          elementName: this.userdata.elementName,
          elementDescription: this.userdata.elementDescription,
          orderNumber: this.userdata.orderNumber,
          calculationFormula: "",
          elementCode: this.userdata.elementCode,
          categoryType:{listTypeValueId:this.userdata.categoryType},
          // client:this.userdata.client?.clientId,
          client:this.clientName
         
        });
        this.elementform.get('categoryType').patchValue(this.userdata.categoryType.listTypeValueId); 
        if(this.elementform.invalid)
        {
          const controls = this.elementform.controls;
          for (const name in controls) 
          {
            if (controls[name].invalid) {
              console.log(name)
            }
          }
        }
      })
    }
  }

  submitData()
  {
    this.p=1;
    // this.isprocessing1 = true; // Disable the button

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let clientId=this.employeetData?.employee?.client?.clientId
    let organizationIstore = organizationId ? { organizationId: organizationId } : null;
    let clientDetailStore=clientId?{clientId:clientId}:null;
    console.log('patch')
    this.noRecordsFound = false;
    console.log('submit')
    this.submitted = true;
    this.error = false;
    this.success = false;
    console.log(this.elementId);

    if(this.elementId) 
    { 
      console.log("Update");

      if(organizationId){

      }
      var elementupdate;
      elementupdate=
        {
          elementId : this.elementId,
          elementName: this.elementform.value.elementName,
          elementDescription: this.elementform.value.elementDescription,
          orderNumber: this.elementform.value.orderNumber,
          calculationFormula: "",
          elementCode: this.elementform.value.elementCode,
          categoryType:{listTypeValueId:this.elementform.value.categoryType},
          client:clientDetailStore,
          status:this.userdata.status,
        };
        console.log('elementupdate', elementupdate)
        if(this.elementform.value.orderNumber == null){
          console.log('this.elementform.value.orderNumber'+this.elementform.value.orderNumber)
          elementupdate.orderNumber = 0;
        }
        this.loader =true;
        // return
      this.payrollService.elementupdate(elementupdate,this.elementId).subscribe( (data:any) =>
      {
        if (data?.statusCode === 409) {
          console.log("409 entered!!")
          this.error = true;
          this.error_msg = data.description;
          this.loader = false;
          this.isprocessing1 = false;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }

        const currentClientName = this.clientName;

        
        // this.elementform.reset();
  
        this.elementform.patchValue({
          client: currentClientName,
          // elementCode: null,
          // categoryType:null,
          // elementName:null,
          // orderNumber:null,
          // elementDescription:null
        });
        if(data.statusCode == 200)
        {
          this.loader =false;
          this.elementform.get('elementCode')?.reset();
          this.elementform.get('categoryType')?.reset();
          this.elementform.get('elementName')?.reset();
          this.elementform.get('orderNumber')?.reset();
          this.elementform.get('elementDescription')?.reset();
          // this.callTable();
          // this.elementform.reset();
          this.formsubmitted = true;
          this.elementId=0;
          //this.elementList();
          //  this.searchSubmit();
          this.success = true;
          this.sucess_msg = data.description;
          // if((this.searchForm.value.filterclient!="null"||this.searchForm.value.filterclient!=null)||(this.searchForm.value.filterfinancialyear!="null"||(this.searchForm.value.filterfinancialyear!=null))||((this.searchForm.value.filterfinancialyear!="null")||(this.searchForm.value.filterfinancialyear!=null)&&(this.searchForm.value.filterperiod!="null"||this.searchForm.value.filterperiod!=null))||
          // ((this.searchForm.value.filterclient!="null"||this.searchForm.value.filterclient!=null)&&(this.searchForm.value.filterfinancialyear!="null"||this.searchForm.value.filterfinancialyear!=null)&&(this.searchForm.value.filterperiod!="null"||this.searchForm.value.filterperiod!=null)))
          // {
          //   this.searchSubmit();
          // }
          if(this.searchForm.value.categoryTypeSearch!=null){
            this.searchSubmit();
              console.log("search update")
          }
          if((this.searchForm.value.filterclient==null || this.searchForm.value.filterclient== 'null' )){
            console.log("Without",this.searchForm.value);
    
          }
          
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        }
        // else
        // {
        //   this.loader =false;
        //   this.noRecordsFound = true;
        //   this.error = true;
        //   this.error_msg = data.description;
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
        this.isprocessing1=false;
      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;     
        this.update_data_error_msg = err.error.message;
        setTimeout(() => {this.error = false}, 3000);
        this.isprocessing1=false;

      })
    }
    else //Create
    {
      console.log("Create");
      var elementdata;

      elementdata =
        { 
          elementName: this.elementform.value.elementName,
          elementDescription: this.elementform.value.elementDescription,
          orderNumber: this.elementform.value.orderNumber,
          calculationFormula: "",
          elementCode: this.elementform.value.elementCode,
          categoryType:{listTypeValueId:this.elementform.value.categoryType},
          // client:{clientId:this.elementform.value.client},
          client:clientDetailStore,

          status:{listTypeValueId:1},
        };
        console.log('elementdata', elementdata)
        if(this.elementform.value.orderNumber == null){
          console.log('this.elementform.value.orderNumber'+this.elementform.value.orderNumber)
          elementdata.orderNumber = 0;
        }
      this.backdrop=true;
      this.loader=true;
      // return
      this.payrollService.elementcreate(elementdata).subscribe( (data:any) =>
      {

        if (data?.statusCode === 409) {
          this.error = true;
          this.error_msg = data.description;
          this.loader = false;
          this.isprocessing1 = false;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
        const currentClientName = this.clientName;

        
        // this.elementform.reset();
  
        this.elementform.patchValue({
          client: currentClientName,
          // elementCode: null,
          // categoryType:null,
          // elementName:null,
          // orderNumber:null,
          // elementDescription:null
                });

        if(data.statusCode == 200)
        {  console.log('this.elementform reset', this.elementform)
          this.elementform.get('elementCode')?.reset();
          this.elementform.get('categoryType')?.reset();
          this.elementform.get('elementName')?.reset();
          this.elementform.get('orderNumber')?.reset();
          this.elementform.get('elementDescription')?.reset();
          this.loader=false;
          // this.callTable();
          // this.elementform.reset();
             this.formsubmitted = true;
       //   this.elementList();
           
            this.success = true;
          this.sucess_msg = data.description;


          // if((this.searchForm.value.filterclient!="null"||this.searchForm.value.filterclient!=null)||(this.searchForm.value.filterfinancialyear!="null"||(this.searchForm.value.filterfinancialyear!=null))||((this.searchForm.value.filterfinancialyear!="null")||(this.searchForm.value.filterfinancialyear!=null)&&(this.searchForm.value.filterperiod!="null"||this.searchForm.value.filterperiod!=null))||
          // ((this.searchForm.value.filterclient!="null"||this.searchForm.value.filterclient!=null)&&(this.searchForm.value.filterfinancialyear!="null"||this.searchForm.value.filterfinancialyear!=null)&&(this.searchForm.value.filterperiod!="null"||this.searchForm.value.filterperiod!=null)))
          // {
          //   this.searchSubmit();
          // }
          if(this.searchForm.value.categoryTypeSearch!=null){
            this.searchSubmit();
              console.log("search update")
          }
          if((this.searchForm.value.filterclient==null || this.searchForm.value.filterclient== 'null' )){
            console.log("Without",this.searchForm.value);
    
          }
            // this.searchSubmit();
          setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
        }
        // else
        // {   this.noRecordsFound = true; 
        //   console.log('data.statusCode if failure', data.statusCode)
        //   this.loader=false;

        //   this.error = true;
        //   this.error_msg = data.description;
        //   console.log(this.error_msg)
        //   setTimeout(() => {this.error = false}, redirectMsgTimeOut)
        // }
        this.isprocessing1=false;

      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;
        this.error_msg = err.error.message;
        
        setTimeout(() => {this.error = false}, 3000);
        this.isprocessing1=false;

      });
    }
  }

  get elementCode()
  {
    return this.elementform?.get('elementCode');
  }

  get elementName()
  {
    return this.elementform?.get('elementName');
  }

  get categoryType()
  {
    return this.elementform?.get('categoryType');
  }

  
  get orderNumber()
  {
    return this.elementform?.get('orderNumber');
  }

  scrollToEditForm() {
    const editFormElement = this.elementRef.nativeElement.querySelector('#editForm');
    if (editFormElement) {
      editFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    console.log('Pasting is not allowed!');
    this.pasteCount--;
  }
}
