<div class="resource-background">
    <div class="row">
        <div class="col-md-6 resource-title">
            <h2>RESOURCE DETAIL</h2>
        </div>
        <div class="col-md-6 form-group search-input topnav-right">
            <!-- <input matInput class="search form-control" type="text" placeholder="Search.." [(ngModel)]="query" (keyup)="onKeyUpEvent($event)" name="search">
            <mat-icon aria-hidden="false" aria-label="Example search icon" class="material-icons-round search-input-icon">search</mat-icon> -->
        </div>
    </div>
    <div class="card resource-card">
        <div class="row">
            <div class="col-md-12">
                <div class="topnav-right add-button">
                    <button (click)="Add_resource()" mat-button class="btn add-resource">Add Resource</button>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="topnav-right select-pagination">
                <span>Show : </span>
                <select class="select_list">
                    <option class="form-control" value="5"> 5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="resourcedetail-table table-responsive">
                    <ng-container *ngIf="resourcedetaillistdata?.length > 0 ; else nodata">
                        <table class="interviewed-status-table">
                            <thead style="background-color: #f6f9fc;">
                                <tr>
                                    <th class="table-head">Name</th>
                                    <th class="table-head">Position</th>
                                    <th class="table-head date-width">Interview date</th>
                                    <th class="table-head">Total Experience</th>
                                    <th class="table-head">Relevent Experience</th>
                                    <th class="table-head date-width">Notice Period</th>
                                    <th class="text-center table-head">Current CTC</th>
                                    <th class="text-center table-head">Expected CTC</th>
                                    <!-- <th class="table-head date-width">Job Location</th>	 -->
                                    <!-- <th class="table-head">Resource Availability</th>	 -->
                                    <th class="table-head">Interviewed</th>
                                    <th class="table-head">Interview Status</th>
                                    <th class="table-head">Status</th>
                                    <th class="table-head text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let e of resourcedetaillistdata | searchFilter: query | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                    <!-- <tr *ngFor="let e of resourcelistdata"> -->
                                    <td class="table-data">{{e.resourceName}}</td>
                                    <td class="table-data">{{e.position.positionName}}</td>
                                    <td class="table-data">{{e.interviewDate.slice(0,10)}}</td>
                                    <td class="table-data text-center">{{e.totalExperience}}</td>
                                    <td class="table-data text-center">{{e.relevantExperience}}</td>
                                    <td class="table-data text-center">{{e.noticePeriod}}</td>
                                    <td class="table-data text-center">{{e.currentCtc}}</td>
                                    <td class="table-data text-center">{{e.expectedCtc}}</td>
                                    <!-- <td class="table-data">{{e.jobLocation}}</td>
                                    <td class="table-data text-center">{{e.resourceAvailability}}</td> -->
                                    <td class="table-data text-center">{{e.interviewed ? 'Yes' : 'No' }}</td>
                                    <td *ngIf="e.interviewStatus ==undefined" class="table-data text-center"></td>
                                    <td *ngIf="e.interviewStatus !=undefined" class="table-data text-center">{{e.interviewStatus.listTypeValueName}}</td>
                                    <td *ngIf="e.status.listTypeValueName =='Active'" class="table-data text-center text-success">{{e.status.listTypeValueName}}</td>
                                    <td *ngIf="e.status.listTypeValueName =='InActive'" class="table-data text-center text-danger">{{e.status.listTypeValueName}}</td>
                                    <td class="table-data text-center">
                                        <span>
                                          Edit
                                         <!--  <mat-icon routerLink="/home/resourcedetail"  aria-hidden="false" aria-label="Example edit icon" class="material-icons-round edit-icon">edit</mat-icon></span> -->
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <ng-template #nodata>
        <div class="col-md-12 card text-center">
            <div class="card-body">No Data</div>
        </div>
    </ng-template>
    <div class="row topnav-right pagination-content">
        <div class="col-md-12">
            <!-- <pagination-controls class="float-right pagination" (pageChange)="p = $event; beforepage = $event"></pagination-controls> -->
        </div>
    </div>
</div>
