import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PmsService } from '../../../providers/pms.service';
import { UserService } from '../../../providers/user.service';
import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';
import { httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../../providers/properties';
import { DatePipe, JsonPipe } from '@angular/common';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject, catchError, switchMap, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'app-add-assign-kra',
  templateUrl: './add-assign-kra.component.html',
  styleUrls: ['./add-assign-kra.component.scss']
})

export class AddAssignKraComponent implements OnInit
{

  submitted: boolean = false;
  statusCode : any;
  errorMsg : any;
  existMsg : any;
  employeeId:any;
  postForm: FormGroup;
  resultData: any;
  employeeKraId: any;
  organizationId : any;
  empolyeereadonly :boolean = true;
  sucess_msg:any;
  error_msg:any;
  employeetData: any = '';
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  autoCompleteSearch: number;
  employeeData: any;
  activekraData: any;
  today: any =  new Date();
  minPeriodToDate: Date;
  dateFormat : any;
  usertData:any;
  userId: any;
  empList: any;
  employee: string;
  employeeNotFound: boolean; 
  employeeNumber: number;
  selectedemployee: any="";
  selectedemployeeId: any; 
  employeeSelected: boolean;
  submitButtonDisable :boolean = false;
  unsubscribe$: any;
  empName:any;

  editemployee:any;
  editemployeeid:any;

  @ViewChild('periodFrom') startDatePicker!: MatDatepicker<any>;
  @ViewChild('periodFrom') endDatePicker!: MatDatepicker<any>;
  employeeNumbertyped: any;


  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private pmsservice: PmsService,
    private settingsservice: SettingsService,
    private userservice: UserService,
    private ActivatedRoute:ActivatedRoute,
    private datepipe : DatePipe ,
  ){ }

  ngOnInit(): void
  {
    this.getActivekra();
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.organizationId = this.usertData.organization.organizationId;
    console.log(this.organizationId);
    this.userId = this.usertData.userId;
    console.log("Employye ID"+this.userId);
    // this.today = new Date();
    this.today.setYear(this.today.getFullYear() - 18)
    this.today =this.datepipe.transform(this.today, 'yyyy-MM-dd')
    //let periodFrom = this.datepipe.transform(this.basicInformationDetails.value.dateOfBirth, 'yyyy-MM-dd');

    this.postForm = this.formBuilder.group({
      employeeName : ['',[Validators.required]],
      employeeId: ['',[Validators.required]],
      kraTemplateHeaderId : ['',[Validators.required]],
      periodFrom : ['',[Validators.required]],
      periodTo : ['',[Validators.required]],
    });

    this.postForm.get('periodFrom').valueChanges.subscribe((selectedDate: Date) => {
      this.minPeriodToDate = selectedDate;
    console.log(":::::");
  
      // Reset periodTo if it is before the new minPeriodToDate
      if (this.postForm.get('periodTo').value < this.minPeriodToDate) {
        this.postForm.get('periodTo').setValue(null);
        

      }
    });

    // this.postForm.get('periodTo').valueChanges.subscribe((selectedDate: Date) => {
    //   // Validate if periodTo is less than or equal to periodFrom
    //   if (this.postForm.get('periodFrom').value && new Date(selectedDate) <= new Date(this.postForm.get('periodFrom').value)) {
    //     alert('Period To must be greater than Period From.');
    //     this.postForm.patchValue({
    //       periodFrom: '',
    //       periodTo: ''
    //     });
    //   }
    // });

    

   

    

    
    


    this.employeeKraId = this.ActivatedRoute.snapshot.params.employeeKraId;
    
   if(this.employeeKraId)
    {
      this.loader = true;
      this.pmsservice.getAssignkraById(this.employeeKraId).subscribe((resultData:any) =>
      {
        
        this.resultData=resultData;
          console.log("resultData",this.resultData);
        console.log("employeeName"+this.resultData.employee.firstName+" "+this.resultData.employee.lastName+"-"+this.resultData.employee.employeeId)
        let periodFrom = this.datepipe.transform(this.resultData.periodFrom, 'yyyy-MM-dd');
        let periodTo = this.datepipe.transform(this.resultData.periodTo, 'yyyy-MM-dd');

        // this.employeeId=this.resultData.employee.employeeId;
        this.editemployee=this.resultData.employee.firstName+" "+this.resultData.employee.lastName+"-"+this.resultData.employee.employeeId;
        this.editemployeeid=this.resultData.employee.employeeId;

        
        this.postForm.patchValue({
          
          employeeKraId: this.resultData.employeeKraId,
          employeeId: this.resultData.employee.employeeId,
          employeeName: this.resultData.employee.firstName+" "+this.resultData.employee.lastName+"-"+this.resultData.employee.employeeId,
          // employeeName: this.resultData.employeeName,
          periodFrom: periodFrom,
          periodTo: periodTo,
          kraTemplateHeaderId:this.resultData.kraTemplateHeader.kraTemplateHeaderId
        });
        console.log("Patch: ",this.postForm);
        this.loader = false; 
        
        /* if(this.resultData.kraTemplateHeader)
        {
          this.postForm.patchValue({
            kraTemplateHeader:
            {
              kraTemplateHeader : this.resultData.kraTemplateHeader.kraTemplateHeaderId
            }
          });
        } */
      });
    }

   
  }

  


  


  // empSearch(event)
  // { 
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);
  //   this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
  //     let organizationId = this.employeetData.organization.organizationId;
  //   // Employee List Api
  //   if(employeenumber){
  //     this.userservice.employeeSearch(organizationId,employeenumber).subscribe((result: any) => {
  //        if(result.statusCode) {
  //         this.empList.length = 0;
  //        }
  //        else{
  //         console.log("else");
  //         this.empList = result;
  //         console.log("this.empList",this.empList);
  //        }
  //     })
  //   }
  // }

  empSearch(event, event1: KeyboardEvent): void {
    this.employeeNumbertyped = event.target.value;
    console.log("emptyped",this.employeeNumbertyped);
    if (event1.key === ' ') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');
      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      if (this.empList?.length > 0) {
        // this.nodatafound = false;
      }
  
      this.empList = [];
      console.log(event.target.value);
      let employeeNumber = event.target.value;
      employeeNumber = employeeNumber.replace(/-.*/, '');
      console.log(employeeNumber);
      this.organizationId = this.usertData.organization.organizationId;
    console.log(this.organizationId);
  
      // Cancel the previous API request
      if (this.unsubscribe$) {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
      this.unsubscribe$ = new Subject<void>();
  
      if (
        this.employeeId === 0 ||
        this.employeeId === undefined ||
        this.employeeId === null
      ) {
        console.log('employee key', employeeNumber);
  
        if (employeeNumber && employeeNumber.length >= 2) {
          this.userservice
            .employeeSearch(this.organizationId, employeeNumber)
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() => this.userservice.employeeSearch(this.organizationId, employeeNumber)),
              catchError((err) => {
                if (err.error.status === 500) {
                  // this.projectId = [];
                  this.empList = [];
                  this.employeeNotFound = true;
                }
                console.error('Error fetching employees:', err);
                // Handle the error here, for example, showing an alert
                // alert('Error fetching employees. Please try again later.');
                return throwError(err); // Rethrow the error to propagate it downstream
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);
  
              if (result && result.statusCode === 204) {
                this.employeeNotFound = true;
              }
              this.empList.length = 0;
  
              if (result && Array.isArray(result)) {
                result.forEach((element) => {
                  let employee = {
                    employee: {
                      employeeId: element.employeeId,
                      firstName: element.firstName,
                      employeeNumber: element.employeeNumber,
                    },
                  };
  
                  if (Object.keys(employee).length > 0) {
                    this.employeeNotFound = false;
                  } else {
                    this.employeeNotFound = true;
                  }
                  this.empList.push(employee);
                  console.log(this.empList);
                });
  
                if (this.selectedemployeeId?.length >= 1) {
                  this.employeeSelected = true;
                }
              }
  
              if (result && result.errorCode === 204) {
                // alert('No Employee Found');
                this.employeeNotFound = true;
                this.empList.length = 0;
                this.empList.push('No Employee Found');
                this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
              }
            });
        }
      } else {
        // Set selectedemployeeId to null if the input is empty
        this.selectedemployeeId = null;
      }
    }
  } 

  empSelect(emp)
  {
    console.log(emp);
    console.log(this.employee);
    this.employeeId=emp.employee.employeeId;
    console.log(this.employeeId);
    this.employee=emp.employee.employeeNumber+"-"+emp.employee.firstName;
    console.log(this.employee);
    // if(this.approverId){
      this.postForm.patchValue({
        // employeeName: this.employee 
        employeeName: this.employee,
        employeeId: this.employeeId
     });
    // }else{
    
    //   this.searchForm.patchValue({
    //     employeeCode: null
    //    });
    // console.log("this.selectedEmployeeData", this.selectedEmployeeData);
    
    //     let  hasemployee =   this.selectedEmployeeData.some(e=> e.employeeId == emp.employeeId);
    //       if(!hasemployee)
    //       {
    //         this.selectedEmployeeData.push(emp);
    //         console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
    //       }
    //       console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
    // }
  }

  noEmployeeFound() {
    
    this.selectedemployeeId = undefined; // Set selectedemployeeId to null
    this.employeeId = undefined;
    this.empList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
    this.empList = []; // Clear the employee list
    this.employeeNotFound = false; // Keep track of no employee found state
  }



  get validate() { return this.postForm.controls; }


  onSubmit()
  {
    this.submitted = true;
    console.log("Submit");
    // if(this.employeeKraId) //Update
    // {
    //   this.postForm.patchValue({
    //     employeeId: this.resultData.employee.employeeId,
    //     employeeName: this.resultData.employee.employeeNumber
    //   });
    // }
    console.log("emptyped",this.employeeNumbertyped);
    console.log("employeenum",this.employeeNumber);

    if(!this.employeeKraId && !this.employeeId && this.employeeNumbertyped != undefined ){
      this.postForm.controls['employeeName'].reset();
      
      this.employeeId=undefined;
      
      alert('Please Enter valid Employee');
      
      console.log("type");
      return;
      
    }

    if(this.employeeId){

    this.postForm.patchValue({
      employeeName:this.employee,
    })
  }else

  if(this.editemployeeid){
    this.postForm.patchValue({
      employeeName:this.editemployee,
    })

  }

  

    

    
    if(this.postForm.invalid) 
    {
      console.log("InVaild",this.postForm);
      console.log("InVaild",this.postForm.value);
      return;
    }

   
    const { periodFrom, periodTo } = this.postForm.value;
    const fromDate = new Date(periodFrom);
  const toDate = new Date(periodTo);

  



  if (toDate <= fromDate) {
    alert('Period To must be greater than Period From.');
    this.postForm.patchValue({
      periodFrom: '',
      periodTo: ''
    });
    return;
  }

 


  this.submitButtonDisable= true;

    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let organizationId = this.usertData.organization.organizationId;

  


    if(this.employeeKraId) //Update
    {
      console.log("updateId"+this.employeeKraId);
      console.log("from"+this.postForm.value.periodFrom);
      console.log("to"+this.postForm.value.periodTo);

      var postValues1 = {
        employeeKraId:this.postForm.value.employeeKraId,
        periodFrom:new Date(this.postForm.value.periodFrom),
        periodTo:new Date(this.postForm.value.periodTo),
        employeeId:this.postForm.value.employeeId,
        employeeName: this.postForm.value.employeeName,
        organizationId:organizationId,
        kraTemplateHeader:{
          kraTemplateHeaderId: this.postForm.value.kraTemplateHeaderId
        },
        status:{
          listTypeValueId: this.resultData.status.listTypeValueId
        },
        createdBy: this.userId,
        lastUpdatedBy:this.userId
      }
      postValues1.periodFrom.setDate(postValues1.periodFrom.getDate() + 1);
      postValues1.periodTo.setDate(postValues1.periodTo.getDate() + 1);

      this.loader=true;

      this.pmsservice.editAssignkra(this.employeeKraId,postValues1).subscribe((result: any) =>
      {
        this.submitButtonDisable= false;
        if(result.statusCode == 200)
        {
          this.success = true;
         this.loader=false;
          this.submitted = false;
          this.sucess_msg = result.description;
          setTimeout(() => {
            this.router.navigate(['/home/assign-kra-template']);
          }, 3000)
        }
        else
        {
          this.error = true;
          this.submitButtonDisable= false;
          this.error_msg = result.description;
          setTimeout(() => {
            this.router.navigate(['/home/assign-kra-template']);
          }, 3000)
        }
      }, err =>{

        this.error = true;
          this.submitButtonDisable= false;
        this.errorMsg = err.error.description;

      })
    }
    else  //Add
    {
      var postValues = {
        employeeName: this.postForm.value.employeeName,
        employeeId  : this.postForm.value.employeeId,
        periodFrom  : new Date(this.postForm.value.periodFrom),
        periodTo    : new Date(this.postForm.value.periodTo),
        kraTemplateHeader :
        {
          kraTemplateHeaderId :this.postForm.value.kraTemplateHeaderId
        } ,
        createdBy: this.userId
      }

      postValues.periodFrom.setDate(postValues.periodFrom.getDate() + 1);
      postValues.periodTo.setDate(postValues.periodTo.getDate() + 1);

      postValues['organizationId'] = organizationId
      console.log("PV : ",postValues);

      this.loader=true;
      this. pmsservice.createemployeekratemplate(postValues).subscribe((result: any) =>
      {
        this.submitButtonDisable= false;
        if(result.statusCode == 200)
        {
          this.success = true;
         this.loader=false;
          this.submitted = false;
          this.sucess_msg = result.description;
          //setTimeout(() => {this.success  = false;},redirectMsgTimeOut)
          //this.router.navigate(['/home/employee']);

          setTimeout(() => {
            this.router.navigate(['/home/assign-kra-template']);
          }, 3000)
        }
        else
        {
          this.error = true;
          this.submitButtonDisable= false;
          this.error_msg = result.description;
          //setTimeout(() => {this.error = false}, redirectMsgTimeOut)

          setTimeout(() => {
            this.router.navigate(['/home/assign-kra-template']);
          }, 3000)
        }

      }, err =>
      {
        this.error = true;
          this.submitButtonDisable= false;
          this.errorMsg = err.error.description;
      })
    }
  }


  // empSearch(event){ 
  //   console.log(event.target.value);
  //   let employeenumber = event.target.value;
  //   console.log(employeenumber);
  //   // Employee List Api
  //   if(employeenumber){
  //     this.payrollService.getEmployeeNameList(employeenumber, this.organzationId).subscribe((result: any) => {
  //        if(result.statusCode) {
  //         this.empList.length = 0;
  //        }
  //        else{
  //         console.log("else");
  //         this.empList = result;
  //         console.log("this.empList",this.empList);
  //        }
  //     })
  //   }
  // }

  getActivekra()
  {
    this.pmsservice.getModulesList().subscribe((result: any) => {
      this.activekraData = result;
    })
    console.log("Active KRA : ", this.activekraData);
  }

  getEmployeeList(event)
  {
    let keywords  = event.target.value;
    //let keywords = this.searchForm.value.keywords ? this.searchForm.value.keywords : null;

    if(keywords)
    {
      this.autoCompleteSearch = 0;

      this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
      let organizationId = this.employeetData.organization.organizationId;

      this.userservice.employeeSearch(organizationId,keywords).subscribe((result: any) =>
      {
        this.employeeData = result;

        if(this.employeeData.statusCode == 204)
        {
          this.autoCompleteSearch = 1; //No data Found
          this.postForm.patchValue({
            //employeeName : firstName+" "+lastName,
            employeeId : ""
          });
        }
        else
        {
          this.autoCompleteSearch = 0;
        }
      })
    }
    else
    {
      this.autoCompleteSearch = 1;
      this.postForm.patchValue({
        //employeeName : firstName+" "+lastName,
        employeeId : ""
      });
    }
  }

  getFilterData(id,firstName,lastName,employeeNumber)
  {
    this.autoCompleteSearch = 1;

    if(id)
    {
      this.postForm.patchValue({
        employeeName : firstName,
        employeeId : id
      });
    }
  }

 


  
handleKeyPress(event) {
  if (event.key === ' ' && event.target.selectionStart === 0) {
    event.preventDefault();
  }
}


handleBackspace(event): void {
  if (
    event.key === 'Backspace' &&
    !this.selectedemployee &&
    (this.selectedemployeeId === undefined || this.selectedemployeeId === null || this.selectedemployeeId.trim().length === 0)
  ) {
    this.selectedemployeeId = undefined;
    this.employeeId = undefined;
    // this.empList.length = 0;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
   

  }
}

preventSpaceBeforeInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  if (event instanceof KeyboardEvent && event.keyCode === 32 && !inputElement.value.trim()) {
      event.preventDefault();
    }
  }

validateInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value;

  // Remove leading spaces
  if (value.startsWith(' ')) {
    value = value.trimStart();
  }

  // Remove spaces in between
  value = value.replace(/\s+/g, '');

  input.value = value;
  }

checkStartDate(): void {
  const startDate = this.postForm.get('periodFrom').value;
  const endDate = this.postForm.get('periodTo').value;
  let startDate1 = this.datepipe.transform(startDate, 'YYYY-MM-dd');
  let endDate1 = this.datepipe.transform(endDate, 'YYYY-MM-dd');
  if (startDate && endDate && startDate1 > endDate1) {
    alert('Start date cannot be greater than end date.');

    this.postForm.patchValue({
      periodFrom: "",
      periodTo: "",
    });
    this.startDatePicker.close();
  }
}




  
}
