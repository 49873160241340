import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { Sort } from '@angular/material/sort';
import { ProjectManagementService } from 'src/app/providers/project-management.service';
import { ReplaySubject, Subject, Subscription, of, throwError } from 'rxjs';
import { AssignEmpService } from 'src/app/providers/assign-emp.service';
import { HeaderService } from 'src/app/providers/header.service';
import { TimesheetService } from 'src/app/providers/timesheet.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-emp-timesheet',
  templateUrl: './emp-timesheet.component.html',
  styleUrls: ['./emp-timesheet.component.scss'],
})
export class EmpTimesheetComponent implements OnInit {

  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
  public employeeProjects: any = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  selectedEmployeeSearch:any;
  selectedemployee:any;
  @ViewChild('successMessageElement') successMessageElement: any;
  successMessage: string = '';
  searchButtonDisable: boolean = true;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private successMessageSubscription: Subscription;
  private successUpdateMessageSubscription: Subscription;
  private errorSubscription: Subscription;
  successUpdate: Boolean;
  successUpdateMessage: string;
  employeeNotFound: boolean = false;
  success: boolean = false;
  taskList: any = [];
  user_type: string;
  search: any;
  p: any;
  employeeId: any = undefined;
  beforesearchpage: any;
  nodata = false;
  loader = true;
  paginator: boolean = true;
  public searchFilter: any = '';
  query: any = '';
  beforepage: any;
  mySelect: any = 5;
  selectedProjectId: number;
  projectId: number = undefined;
  projectList: any = [];
  min: any;
  max: any;
  taskId: number = 0;
  timesheetreport: any = [];
  isproject: boolean;
  timesheetDate: any;
  // employeeProjects: any[] = [];
  selectedemployeeId: any;
  disable_employee: boolean = true;
  project_required: boolean = false;
  EmpId: any;
  employee: string = undefined;
  employeeNumber: any;
  assignformbyemployee: any;
  employeeSelected: boolean = false;
  // selectedemployee: any;
  organizationId: any;
  error: boolean = false;
  errorMessage: string = '';
  tableShow: boolean = false;
  checkEmployeeIds: any[] = [];
  employeelist: any[] = [];
  constructor(
    private gs: ProjectManagementService,
    private router: Router,
    public dialog: MatDialog,
    private assignemp: AssignEmpService,
    private headerservice: HeaderService,
    private route: ActivatedRoute,
    private timesheet: TimesheetService
  ) {}


  ngAfterViewInit() {
    this.setInitialValue();
    

    // this.removePlaceholder();
  
  }


  protected setInitialValue() {
    this.employeeProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  ngOnInit() {
    this.headerservice.setTitle('');
    this.timesheet.setSuccessMessage('');
    this.timesheet.setSuccessUpdateMessage('');
    // this.headerservice.setTitle('Assign Employee Task');
    this.successMessageSubscription = this.assignemp
      .getSuccessMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        if (message) {
          this.success = true;
          this.successMessage = message;
        } else {
          this.success = false;
          this.successMessage = '';
        }

        // Close the success message after 3 seconds (adjust duration as needed)
        window.setTimeout(() => {
          this.success = false;
          this.closeSuccessMessage();
        }, 3500);

        console.log('Success message:', message);

        // const timer$ = timer(300).pipe(take(1));
        // timer$.subscribe(() => {
        //   this.closeSuccessMessage();
        // });
      });

    this.successUpdateMessageSubscription = this.assignemp
      .getSuccessUpdateMessage()
      .subscribe((message) => {
        if (message) {
          this.showUpdateSuccessMessage(message);
        }
      });

    window.setTimeout(() => {
      this.successUpdate = false;
      this.closeUpdateSuccessMessage();
    }, 3500);

    this.errorSubscription = this.assignemp
      .getErroeMessage()
      .subscribe((message) => {
        // setTimeout(() => {
        if (message) {
          this.error = true;
          this.errorMessage = message;
        }

        // Close the success message after 3 seconds (adjust duration as needed)
        window.setTimeout(() => {
          this.error = false;
          this.closeErrorMessage();
        }, 3500);

        console.log('Success message:', message);

        // const timer$ = timer(300).pipe(take(1));
        // timer$.subscribe(() => {
        //   this.closeSuccessMessage();
        // });
      });

    this.loader = true;
    console.log('LOader' + this.loader);
    let x = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('x', x);

    this.EmpId = x.employee.employeeId;
    this.organizationId = x.organization.organizationId;
    this.gs.getResourceByIds(this.EmpId).subscribe((d: any) => {
      this.user_type = d?.userType?.userType;
      // this.gs.getProjectList().subscribe(
      //   (pa:any)=>{

      //     this.projectList = pa;
      //     console.log(this.projectList);
      //   }
      // )
    });
    this.gs.getProjectsBasedOnProjectStatus().subscribe((pa: any) => {
      this.projectList.length = 0;
      this.projectList = Array.isArray(pa) ? pa : [pa];
      console.log('this.projectList', this.projectList);
    });
    this.employeeId = x.employeeId;
    {
      this.getTask();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    // if (this.successMessageSubscription) {
    console.log('calling ngdestroy');

    this.success = false;
    this.successMessage = '';
    this.successUpdateMessage = '';
    this.successUpdate = false;
    this.successMessageSubscription.unsubscribe();
    // }
    // if (this.successUpdateMessageSubscription) {
    this.successUpdateMessageSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();

    // }
  }

  selectProject() {
    this.projectId = undefined;
    this.employeeProjects = [];
    // this.taskList = [];
    this.selectedemployeeId = undefined;
    this.employee = undefined;
    this.employeeNumber = undefined;
    this.selectedemployee = undefined;
    this.selectedemployeeId = undefined;
  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }

  getTask() {
    this.gs.getemployeetask(this.EmpId).subscribe(
      (d: any) => {
        this.loader = false;
        this.taskList = [];
        this.taskList = d;
        this.tableShow = true;
        if (d.statusCode === 204) {
          this.nodata = true;
        }  

      },
      (error) => {
        this.nodata = true;
        this.loader = false;
        console.log('error');
        if (error.status == 404) {
          this.nodata = true;
          console.log('404');
        }
      }
    );
  }

  closeSuccessMessage() {
    this.success = false;
    this.successMessage = '';
  }
  showUpdateSuccessMessage(message: string) {
    this.successUpdate = true;
    this.successUpdateMessage = message;

    setTimeout(() => {
      this.closeUpdateSuccessMessage();
    }, 3500);
  }

  closeUpdateSuccessMessage() {
    this.successUpdate = false;
    this.successUpdateMessage = '';
  }

  closeErrorMessage() {
    this.error = false;
    this.errorMessage = '';
  }

  delete(e, i) {
    console.log(e);
    this.gs.deleteCommonTask(e.commontaskId).subscribe((d: any) => {
      this.taskList.splice(i, 1);
      Swal.fire({
        text: d.message,
        icon: 'success',
      });
      window.location.reload();
    });
  }

  resetForms() {
    console.log('checking projectId', this.projectId, this.selectedemployeeId);

    console.log('checking form data', this.selectedemployeeId, this.projectId);
    this.project_required = false;
    if (this.selectedemployee != undefined || this.projectId != 0) {
      if (
        this.selectedemployee != undefined ||
        this.selectedemployee != null ||
        this.projectId != undefined
      ) {
        this.employeeProjects = [];
        // this.taskList = [];
        this.selectedemployeeId = undefined;
        this.employee = undefined;
        this.employeeNumber = undefined;
        this.selectedemployee = undefined;
        this.selectedemployeeId = undefined;
        this.projectId = undefined;

        this.nodata = false;

        this.project_required = false;
        this.loader = true;
        this.tableShow = false;
        this.gs.getProjectsBasedOnProjectStatus().subscribe((pa: any) => {
          // this.projectList.length = 0;

          this.projectList = pa;
          // this.projectList = Array.isArray(pa) ? pa : [pa];
          this.searchButtonDisable = true;
          console.log('this.projectList', this.projectList);
        });
        // this.employeeId = x.employeeId;
        {
          this.getTask();
          this.changefirstpage();
        }
      }
    }
  }

  empSearch(event, event1: KeyboardEvent): void {
    if (event1.key === ' ') {
      this.employeeNotFound = false;
      // Handle the space key press
      console.log('Space key pressed');

      // Optionally, prevent the default behavior
      event1.preventDefault();
    } else {
      this.employeeNotFound = false;
      this.employeeProjects = [];
      console.log(event.target.value);
      let employeenumber = event.target.value;
      employeenumber = employeenumber.replace(/-.*/, '');
      console.log(employeenumber);

      // Cancel the previous API request
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$ = new Subject<void>();

      if (employeenumber.length == 0) {
        this.employeeNotFound = false;
        console.log('enterd into the condition!!');
        this.employeeId = undefined;
        this.employee = undefined;
      }
      // Employee List Api
      if (employeenumber) {
        if (employeenumber.length >= 2) {
          this.gs
            .filterEmployeesByProjects(
              this.organizationId,
              this.selectedProjectId,
              employeenumber
            )
            .pipe(
              takeUntil(this.unsubscribe$), // Cancel previous request
              switchMap(() =>
                this.gs
                  .filterEmployeesByProjects(
                    this.organizationId,
                    this.selectedProjectId,
                    employeenumber
                  )
                  .pipe(
                    catchError((err: any) => {
                      console.error('Error occurred:', err);

                      if (err.errorCode === 204 || err.status === 500) {
                        this.employeeNotFound = true;
                        this.employeeProjects = []; // Reset employeeProjects
                      }
                      if (err.errorCode === 204 || err.error.status === 500) {
                        this.employeeNotFound = true;
                        this.employeeProjects = []; // Reset employeeProjects
                      }
                      return of(err); // Return observable with error to continue the stream
                    })
                  )
              ),
              catchError((err: any) => {
                console.error('Second error catch block:', err);

                if (err?.status == 500) {
                  this.employeeNotFound = true;
                }
                // Handle additional error scenarios here
                return throwError(err); // Rethrow the error to propagate it further
              })
            )
            .subscribe((result: any) => {
              console.log('result', result);

              if (result?.status == 500) {
                this.employeeNotFound = true;
              }
              if (result.length > 0) {
                // this.employeeNotFound = false;
                this.employeeProjects = result;
              }
              if (
                !result ||
                result?.errorCode === 204 ||
                result?.error?.status === 500
              ) {
                // No result or error
                this.selectedemployee = undefined;
                this.selectedemployeeId =  undefined;
                this.employeeNotFound = true;
                this.employeeProjects = []; // Reset employeeProjects
                return;
              }

              this.employeeNotFound = false;
              this.employeeProjects = result; // Assign result to employeeProjects
              // this.employeeProjects.push(result); // Check if this line is necessary
              // if (this.selectedemployee.length >= 1) {
              //   this.employeeSelected = true;
              // }
              if (this.selectedemployee != '') {
                this.employeeNotFound = false;
              }
            });
        }
      } else {
        // Set selectedemployeeId to null if the input is empty
        // this.selectedemployeeId = null;
      }
    }
  }
  // Adjust empSelect() function to handle "No Employee Found" selection
  empSelect(emp) {
    // Continue with the rest of your logic for employee selection
    console.log('emp', emp);
    // this.employeeId = '';
    // this.employeeProjects.pop();
    // this.selectedemployee = '';
    // this.selectedemployeeId = null; // Reset selectedemployeeId to null
    // this.employee = '';
    // this.employeeNumber = '';
    this.selectedemployee =
      emp.employee.employeeNumber + '-' + emp.employee.firstName;
    this.employeeId = emp.employee.employeeId;
    console.log('checkingempId', this.employeeId);
    this.employee = emp.employee.employeeNumber + '-' + emp.employee.firstName;
    this.employeeNumber = emp?.employee?.employeeNumber;
    this.selectedemployeeId = this.employeeId;
  }

  handleBackspace(event): void {
    const inputValue = event.target.value;

    if (event.key === 'Backspace' && inputValue.length <= 0) {
      this.selectedemployeeId = undefined;
      this.employeeId = undefined;
      this.employeeProjects.length = 0;
      this.employee = undefined;
      this.employeeNumber = undefined;
      this.selectedemployee = undefined;
      this.employeeNotFound = false; // Set employeeNotFound to false
    }

    if (
      event.key === 'Backspace' &&
      !this.selectedemployee &&
      this.selectedemployeeId.trim().length === 0
    ) {
      this.employeeNotFound = true;
    }
  }

  getproject() {
    // this.employeeProjects = [];
    // this.disable_employee = false;
    // this.project_required = false;
    // console.log('project id ' + this.projectId);
    // if (this.projectId == 0) {
    //   this.employeeProjects.length = 0;
    // }

    this.employeeNotFound = false;
    if (this.projectId == undefined) {
      this.employeeProjects.length = 0;
    } else {
      this.gs.getEmployeeProject(this.projectId).subscribe((data: any) => {
        console.log(data);
        this.employeelist.length = 0;
        this.employeelist = data;

        console.log('empId', this.employeeId);

        if (this.selectedEmployeeSearch != undefined) {
          let hasemployee = this.employeelist.some(
            (item) => item.employeeId === this.employeeId
          );
          // console.log("hasemployee" + hasemployee);
          // console.log("index" + index);
          if (!hasemployee) {
            this.selectedemployee = undefined;
            this.selectedEmployeeSearch = undefined;
            this.selectedemployee = undefined;
            this.employeeNotFound = false;
            this.employeeProjects.length = 0;
            this.employeeId = undefined;
            this.employee = undefined;
            this.employeeNumber = undefined;
          }
        }
        // this.employeeProjects = data;
        //         if (Array.isArray(data)) {
        //           (data as any).filter((res)=>{
        //          console.log('res',res.employee.employeeId
        //          );

        //          if (res.employee.employeeId ===   this.employeeId) {

        //            console.log('checking both empids', this.employeeId, res.employee.employeeId );

        //          this.selectedemployee =  this.employee;
        //          }else{

        //                this.employeeNotFound = false;
        //              this.employeeProjects.length = 0;
        //              this.employeeId = undefined;
        //              this.employee = undefined;
        //              this.employeeNumber = undefined;
        //              this.selectedemployee = undefined;
        //              this.selectedemployeeId = undefined;
        //  //this.showErrorMessage = true;
        //          }
        //        });
        //      }
        let matchFound = false;
        // for (let index = 0; index < this.employeelist.length; index++) {
        //   this.checkEmployeeIds = [];
        //   if (this.employeelist[index]?.employeeId === this.employeeId) {
        //       this.checkEmployeeIds.push(this.employeelist[index]?.employeeId);
        //   }
        //   console.log('currentEmployee', this.checkEmployeeIds);
        //   console.log('array',this.employeelist);
        //   console.log('this.employeelist[index]', this.checkEmployeeIds)
        //   console.log('this.employeeId', this.employeeId);

        //   if ( this.checkEmployeeIds[index] === this.employeeId) {
        //       // if (this.selectedemployeeId === undefined) {
        //       //     this.selectedemployee = this.employee;
        //       // }
        //       matchFound = true;
        //       break; // Exit the loop as we found a match
        //   }

        //   console.log('this.selectedemployee',this.selectedemployee);

        //   if(this.selectedemployee  != undefined){

        //     if(this.checkEmployeeIds[index] != this.employeeId){

        //       if (this.employeeId != undefined && this.selectedemployee != undefined) {

        //         this.selectedemployee = undefined;
        //         this.employeeNotFound = false;
        //         this.employeeProjects.length = 0;
        //         this.employeeId = undefined;
        //         this.employee = undefined;
        //         this.employeeNumber = undefined;
        //         matchFound = false;
        //         break;
        //       }

        //     }
        //   }

        // }

        this.project_required = false;
      });
    }
  }
  projectData(pro) {
    // this.employeeNotFound = false;
    // this.selectedemployee = null;
    // this.employeeId = null;
    this.project_required = false;
    this.selectedProjectId = pro.projectId;
    // this.employeeProjects = pro.employeeProjects;
    console.log(this.employeeProjects);

  }
  searchTimesheet() {
    // this.loader = true;

    console.log('projectId', this.projectId);

    if (!this.selectedEmployeeSearch) {
      this.selectedemployee = '';
    }

    this.changefirstpage();
    if (this.projectId == undefined) {
      this.project_required = true;
      return;
    }
    // this.taskList.length = 0;
    this.project_required = false;

    if (
      (this.projectId !== undefined && this.selectedemployeeId == 0) ||
      this.selectedemployeeId == undefined
    ) {
      if (this.projectId !== undefined) {
        console.log('pro Id only');
        this.loader = true;
        this.selectedemployee = null;
        this.employee = null;
        this.selectedemployeeId = null;
        this.searchButtonDisable = false;
        this.tableShow = false;
        this.gs.getEmpTimeLogbyProjectId(this.projectId).subscribe(
          (data: any) => {
            console.log('data', data);
            this.taskList = [];
            this.taskList = data;
            this.tableShow = true;
            this.loader = false;
            this.employeeNotFound = false;
            this.nodata = false;
            this.searchButtonDisable = true;
            if ((data as any).statusCode === 204) {
              this.nodata = true;
              this.loader = false;
            }
          },
          (error) => {
            this.nodata = true;
            this.loader = false;
          }
        );
      }
      this.selectedemployee = null;
      this.employee = null;
      this.selectedemployeeId = null;
    } else {
      this.loader = true;
      console.log('this.selectedemployeeId', this.selectedemployeeId);
      // if (this.selectedemployeeId) {
      //   this.selectedemployee = this.employee;
      // }

      this.employeeNotFound = false;
      this.searchButtonDisable = false;
      this.tableShow = false;
      this.employeeNotFound = false;
      this.gs
        .getEmpTimeLogbyEmployeeIdandProjectId(
          this.selectedemployeeId,
          this.projectId
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            this.employeeNotFound = false;
            this.taskList = [];
            this.taskList = data;
            this.tableShow = true;
            this.searchButtonDisable = true;
            this.employeeNotFound = false;
            this.loader = false;
            this.nodata = false;
            if ((data as any).errorCode === 204) {
              this.nodata = true;
              this.loader = false;
            }
          },
          (error) => {
            this.nodata = true;
            this.loader = false;
          }
        );
    }
  }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.selectedemployeeId = null; // Set selectedemployeeId to null
  }
  changepage() {
    if (this.search.length != 0) {
      this.paginator = false;
      // this.taskList = [];
      this.p = 1;
    } else {
      this.p = this.beforesearchpage;
      this.paginator = true;
    }

    this.changefirstpage();
    // if (this.search.length === 0) {

    //   this.paginator = false;
    //   this.taskList = [];
    // }
  }
  changefirstpage() {
    this.p = 1;
  }

  sortData(sort: Sort) {
    const data = this.taskList.slice();
    if (!sort.active || sort.direction === '') {
      this.taskList = data;
      return;
    }

    this.taskList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'projectCode':
          return this.compare(
            a.task.project.projectCode,
            b.task.project.projectCode,
            isAsc
          );
        case 'projectName':
          return this.compare(
            a.task.project.projectName,
            b.task.project.projectName,
            isAsc
          );
        case 'taskName':
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case 'employeeName':
          return this.compare(
            a.employee.firstName,
            b.employee.firstName,
            isAsc
          );
        case 'startdate':
          return this.compare(a.startdate, b.startdate, isAsc);
        case 'enddate':
          return this.compare(a.enddate, b.enddate, isAsc);
        case 'approvalStatusName':
          return this.compare(a.taskName, b.taskName, isAsc);
        case 'taskCode':
          return this.compare(a.task.taskCode, b.task.taskCode, isAsc);
        case 'assignedhours':
          return (
            this.compare(a?.assignedhours, b?.assignedhours, isAsc) ||
            this.compare(a?.assignedhours, b?.assignedhours, isAsc) ||
            this.compare(
              a.task.project.projectCode,
              b.task.project.projectCode,
              isAsc
            )
          );
        // return this.compare(a.assignedhours, b.assignedhours, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
