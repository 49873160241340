<!-- Sub header start here-->
<section>
    <div class="sub-header">
        <div class="col-md-12 mb-3">
            <div class="row">
                <div class="">
                    <span class="h5 sub-contentpr-3" style="    position: relative;
                    top: 6px;
                    color: #181c32;
                    font-weight: 800;
                    font-size: 22px;
                    font-family: 'Mulish', sans-serif;
                    right: 15px;">Manage Appraisal</span>
                </div>
                <!-- <div class="sub-header-buttons"  *ngIf="userTypeId!=1">
                    <button *ngIf="resultData?.length > 0 " type="button" mat-raised-button color="primary" class="btn download-button ml-2">
                    Export
                  </button>
                    <a routerLink="/home/add-appraisal" routerLinkActive="active" color="primary" mat-raised-button title="Add Employee" class="ml-2">
                      Add Appraisal
                    </a>
                </div> -->
                <div class="sub-header-buttons">
                    <!-- <button *ngIf="resultData?.length > 0 " type="button" mat-raised-button color="primary" class="btn download-button ml-2">
                      Export
                    </button> -->
                    <a routerLink="/home/add-appraisal" routerLinkActive="active"  mat-raised-button title="Add Appraisal" class="ml-2"
                    style="position:relative; left:22px; color:#fff; background-color: #4870ff;">
                      Add Appraisal
                    </a>
                </div>  
            </div>
        </div>
    </div>
</section>
<!-- Sub header end here-->
<div class="common-content">
    <div class="card-new">
        <div class="card" style="font-family: Mulish;">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <form [formGroup]="searchForm" (ngSubmit)="searchSubmit()">
                            <div class="row mb-3 pl-0">
                                <!-- <div class="col-xl-4 col-lg-4 mb-2">
                                    <input type="text" (keyup)="getEmpCodeList($event)"   matInput  formControlName="keywords" [matAutocomplete]="empCode" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" autocomplete="off">
                                    <mat-autocomplete #empCode="matAutocomplete">
                                        <mat-option *ngFor="let searchData of employeeData" [value]="searchData.employeeId">
                                            {{searchData.employeeNumber}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <small class="text-muted">Note : Employee code</small>
                                </div> -->
                                <div class="col-xl-4 col-lg-4 mb-2" style="margin-top:2px;" *ngIf="userType?.includes('Admin') || userType?.includes('Human Resource') || userType?.includes('Manager')">
                                    <label class="col-control-label" style="white-space: nowrap; font-weight: 600;">Employee</label>
                                    <!-- <input formControlName="keywords" 
                                        class="form-control form-control-solid form-select-lg"
                                        placeholder="Search employee"
                                        [disabled]="empolyeereadonly"
                                        [matAutocomplete]="auto"  
                                        
                                        (keyup)="empSearch($event,$event)"
                                        (keydown)="handleBackspace($event)"
                                        (keydown)="preventSpaceBeforeInput($event)">
                                        <mat-autocomplete #auto="matAutocomplete"> -->
                                            <input 
                                            type="text"
                            matInput
                            (input)="validateInput($event)"
                            (keydown)="validateInput($event)"
                            (keyup)="validateInput($event)"
                            placeholder="Search Employee"
                            formControlName="keywords"
                            [matAutocomplete]="auto"
                            class="form-control email-input select-placeholder"
                            (keyup)="empSearch($event,$event)"
                            (keydown)="handleBackspace($event)"
                            (keydown)="preventSpaceBeforeInput($event)"
                           ><mat-autocomplete #auto="matAutocomplete">
                                            <!-- <mat-option value="All" (click)="empAllSelect()">All</mat-option> -->
                                            <mat-option *ngFor="let emp of empList" [value]="emp.employeeId" (click)="empSelect(emp)">
                                                {{emp.employee.employeeNumber}} - {{emp.employee.firstName}}  
                                            </mat-option>
                                            <mat-option
                                            [value]="null"
                                            *ngIf="employeeNotFound"
                                            (click)="noEmployeeFound()"
                                            >No Employee Found</mat-option>
                                            
                                            
                                        </mat-autocomplete>
                                    <mat-error *ngIf="validate.keywords.invalid && submitted">Employee is required</mat-error><br>
                                    <!-- <span *ngIf="empolyeeapproverexist" class="span-error"> Approver Already Exist</span> -->
                                </div>
                                <div class="col-xl-4 col-lg-4 mb-2">
                                    <label class="col-control-label" style="white-space: nowrap; font-weight: 600;">Appraisal Status</label>
                                    <mat-select formControlName="status" placeholder="- Select -" class="form-control email-input select-placeholder" >
                                        <mat-option value="null" disabled>-select-</mat-option>
                                         <!-- <option value="1">Pending</option> -->
                                        <!-- <option value="2">- Inprogress -</option> -->
                                       <mat-option *ngFor="let status of statusList" [value]="status.listTypeValueId" >{{status.listTypeValueName}}</mat-option>
                                    </mat-select>
                                    <!-- <input formControlName="keywords" type="search" autocomplete="off" class="form-control search-input form-control form-control-lg form-control-solid" value="" placeholder="Search...">
                                    <small class="text-muted">Note : Employee code</small> -->
                                </div>
                                <div class="col-xl-4 col-lg-4 mb-4 col-md-4 col-xs-4 col-sm-4 col-4 " style="display: flex; margin-top: 28px;">
                                    <button type="submit" mat-raised-button  class=" btn btn-primary searchBtn searchDesign "
                                    [disabled]="submitButtonDisabled"
                                    [color]="submitButtonDisabled? 'warn' : 'primary'"
                                    [ngClass]="{'disabled-button': submitButtonDisabled}">Search</button>

                                    <button  type="button" mat-raised-button 
                                    class="" style="font-family: Mulish; margin-left: 5px;color: white;  background: none;
                                    color: black;
                                    border: 1px solid #ddd;
                                    font-size: 16px;
                                    font-weight: 610;
                                    height: 35px; width:0px;"
                                     (click)="clearTable()" [disabled]="clearButtonDisabled">Clear</button>
                                </div>
                                <!-- <div class="col-xl-1 col-lg-1 mb-2  ">
                                    <button  type="reset" mat-raised-button
                                    class="btn search-btn" style="font-family: mulish;"
                                     (click)="clearTable()">Clear</button>
                                </div> -->
                            </div>
                        </form>
                    </div>
                    <!-- <div class="row" style="margin-bottom: 10px;">
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-2 col-sm-2 col-2 colxs-2 text-right">
                                    <p class="show-count" style="white-space: nowrap; ">Show:</p>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3 col-3 text-left pl-0">
                                    <select [(ngModel)]="mySelect" class="form-select form-select-solid form-select-lg report-filter">
                                      <option value="5">5</option>
                                      <option value="10">10</option>
                                      <option value="15">15</option>
                                      <option value="20">20</option>
                                      <option value="25">25</option>
                                      <option value="1500">1500</option>
                                  </select>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="row" >
                    <!-- <div class="col-md-6 offset-6" style="margin-bottom: 10px;">
                        <div class="row" style="margin-left: 100px;">
                            <div class="col-md-2 col-sm-2 col-2 colxs-2 offset-7" style="padding:0px">
                                <p class="show-count" style="white-space: nowrap;text-align: right;float:right;">Show:</p>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3 col-3" style="width: 10px;padding:0px">
                                <select [(ngModel)]="mySelect" class="form-select form-select-solid form-select-lg report-filter" style="width:67px;">
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                  <option value="25">25</option>
                                  
                              </select>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-xl-12 col-lg-12" style="margin-bottom: 10px;"
                        *ngIf="!error && !nodatafound && manageList?.length > 0 && showTable">
                        <div class="select-pagination" *ngIf="manageList.length >= 5">
                            <span>Show : </span>
                            <select class="select_list new-select_list" [(ngModel)]="mySelect" (change)="onItemsPerPageChange()" style="cursor: pointer;">
                                <option class="form-control" value="5"> 5</option>
                                <option class="form-control" value="10">10</option>
                                <option class="form-control" value="15">15</option>
                                <option class="form-control" value="20">20</option>
                                <option class="form-control" value="25">25</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div class="grid_view"> -->
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12"> -->
                            <div *ngIf="showTable" class="table-responsive ">
                                <table matSort (matSortChange)="sortData($event)" class="table --table-bordered table-border-new"
                                >
                                    <thead class="t-head">
                                        <tr>
                                            <!-- <th class="text-center">S.No</th> -->
                                            <th class="text-center">Action</th>
                                            <!-- <th mat-sort-header="roleName" style="width:15%;">Employee Role</th> -->
                                            <th mat-sort-header="employeeNumber" class="text-center" style="padding-left: 20px;">Emp Code</th>
                                            <th mat-sort-header="firstName"  class="text-center">Name</th>
                                            <!-- <th mat-sort-header="mobileNumber" style="width:15%;" class="text-center">Reviewer Name</th> -->
                                            <th mat-sort-header="organizationEmail">Employee Rating</th>
                                            <th mat-sort-header="approverrating">Approver Rating</th>
                                            <th mat-sort-header="finalrating">Final Rating</th>
                                            <th mat-sort-header="submitteddate">Submitted Date</th>
                                            <th mat-sort-header="reviewstatus">Review Status</th>
                                        </tr>
                                    </thead>
                                    <tbody  *ngIf="manageList?.length > 0"   style="    border-left: 1px solid #ddd;
                                        border-right: 1px solid #ddd;
                                        border-bottom: 1px solid #ddd;">
                                        <tr *ngFor="let row of manageList | searchFilter: query | paginate: { itemsPerPage: mySelect, currentPage: p } ">
                                            <!-- <td class="text-center">{{i+1}}</td> -->
                                            <td class="text-center">
                                                <button mat-icon-button [matMenuTriggerFor]="optlist" aria-label="Example icon-button with a menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #optlist="matMenu" class="controls-list">
                                                    <!-- <button mat-menu-item routerLink="/home/edit-employee/{{row.employeeId}}" routerLinkActive="active">
                                                        <mat-icon>visibility</mat-icon>
                                                        <span>View Details</span>
                                                    </button> -->
                                                    <button mat-menu-item  routerLink="/home/appraisalview/{{row.apprisalHeaderId}}" routerLinkActive="active"> 
                                                        <mat-icon>visibility</mat-icon>
                                                        <span>View Details</span>
                                                    </button>
                                                    <button mat-menu-item  routerLinkActive="active" (click)="viewApprover(row)">
                                                        <mat-icon>visibility</mat-icon>
                                                        <span>View Approvers</span>
                                                    </button>
                                                    <!-- <div *ngIf="(row.approvals.approver.approverOneObject.employeeId==empId)||(row.approvals.approver.approverTwoObject.employeeId==empId&&row.approvals.approverOneStatus.listTypeValueId==5)"> -->
                                                    <div *ngIf="show && !isFyi && (row.apprisalStatus.listTypeValueId == 3 || row.apprisalStatus.listTypeValueId == 9)">
                                                        <button mat-menu-item  routerLink="/home/view-appraisal/{{row.apprisalHeaderId}}" routerLinkActive="active">
                                                            <mat-icon>open_in_new</mat-icon>
                                                            <span>Open</span>
                                                        </button>
                                                    </div>
                                                    
                                                    <!-- <button mat-menu-item routerLink="/home/employee-roles/{{row.employeeId}}" routerLinkActive="active">
                                                      <mat-icon>supervised_user_circle</mat-icon>
                                                      <span>Roles</span>
                                                    </button> -->
                                                </mat-menu>
                                            </td>
                                            <!--  <td>{{ucFirst(row.role.roleName)}}</td> -->
                                            <td class="text-center">{{row.employee.employeeNumber}}</td>
                                            <td class="text-center">{{row.employee.firstName}}  {{row.employee.lastName}}</td>
                                            <!-- <td class="text-center">{{row.approvals.approver.approverOneObject.firstName}}  {{row.approvals.approver.approverOneObject.lastName}}</td> -->
                                            <td class="text-center">
                                                {{row.employeeReviewRating}}
                                            </td>
                                            <!-- <td class="text-center">{{row.employeeReviewRating}}
                                            <ng-container *ngIf="row.employeeReviewRating==undefined; else empRatting">
                                                {{row.employeeReviewRating}}
                                            </ng-container>
                                            <ng-template #empRatting>
                                                0
                                            </ng-template>
                                            </td> -->
                                            <td class="text-center">
                                                <ng-container *ngIf="row.managementRating; else approverRating">
                                                    {{row.managementRating}}
                                                </ng-container>
                                                <ng-template #approverRating>
                                                    -
                                                </ng-template>
                                            </td>
                                            <td class="text-center">
                                                <ng-container *ngIf="row.managementTotalRating; else management">
                                                    {{row.managementTotalRating}}
                                                </ng-container>
                                                <ng-template #management>
                                                    -
                                                </ng-template>
                                            </td>
                                            <td>{{row.todayDate}}</td>
                                            <td class="active-status text-center">
                                              <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.apprisalStatus.listTypeValueId == 3">
                                                    {{row.apprisalStatus.listTypeValueName}}
                                              </span>
                                              <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row.apprisalStatus.listTypeValueId == 7">
                                                    {{row.apprisalStatus.listTypeValueName}}
                                              </span>
                                              <!-- <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.apprisalStatus.listTypeValueId == 7">
                                                {{row.apprisalStatus.listTypeValueName}}
                                              </span> -->
                                              <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.apprisalStatus.listTypeValueId == 9">
                                                    {{row.apprisalStatus.listTypeValueName}}
                                              </span>

                                              <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row.apprisalStatus.listTypeValueId == 8">
                                                {{row.apprisalStatus.listTypeValueName}}
                                          </span>
                                            </td>
                                            <!-- <td>{{row.apprisalStatus.listTypeValueName}}</td> -->
                                            <!-- <td class="active-status text-center">
                                                <span class="btn btn-outline-success btn-sm btn-block" *ngIf="row?.status.listTypeValueId == 1">
                                                    {{row?.status.listTypeValueName}}
                                                </span>
                                                <span class="btn btn-outline-danger btn-sm btn-block" *ngIf="row?.status.listTypeValueId == 2">
                                                  {{row?.status.listTypeValueName}}
                                                </span>
                                            </td> -->
                                        </tr>
                                        <!-- <tr *ng="resultData.length > 0">
                                            <td> No data found.</td>
                                        </tr> -->
                                    </tbody>
                                    <tbody>
                                        <!-- <tr>
                                            <td style="text-align: center;" colspan="9" *ngIf="nodatafound">No Matching Records Found!</td>
                                        </tr>  -->

                                        <!-- <tr>
                                            <td style="font-size: 22px;
                                            font-family: 'Mulish', sans-serif;
                                            font-weight: 800;
                                            " colspan="8" *ngIf="nodatafound">No
                                                Records Found!</td>
                                        </tr> -->
                                    </tbody>
                                    <tbody *ngIf="nodatafound">
                                        <tr>
                                            <td colspan="8" class="text-center" style="font-size: 22px; font-family: 'Mulish', sans-serif; font-weight: 800; padding-left:80px">No Matching Appraisal  Found!</td>
                                        </tr>
                                    </tbody>
                                
                                </table>

                            </div>
                           
                            <!-- <div *ngIf="nodatafound" class="no-data">
                                <img src="assets/uploads/nodata.png"> -->
                                <!-- <span class="no-data-found"> No Data Found </span> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    <!-- </div> -->
                <!-- </div> -->


                <!-- <div class="list_view" *ngIf="pageViews == 2">
                    <div *ngIf="resultData?.length > 0 ">
                        <div class="row mb-3">
                            <div class="col-md-12 mb-3" *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <button class="bars" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                          <mat-icon>more_vert</mat-icon>
                                      </button>
                                            <div class="col-md-12 user-basics">
                                                <span class="user-name-list mb-2">
                                                  {{ucFirst(row.firstName)}} {{ucFirst(row.lastName)}}
                                                  <p class="user-id-list"> ( # {{row.employeeNumber}})</p>
                                              </span>
                                                <span class="list-emp-date pr-2"><mat-icon>calendar_month</mat-icon> {{row.dateOfBirth | date: 'dd-MMM-yyyy'}}</span>
                                                <span class="list-emp-date pr-2"><mat-icon>work_outline</mat-icon> {{row.dateOfJoining | date: 'dd-MMM-yyyy'}}</span>
                                                <span class="list-emp-date pr-2"><mat-icon>phone</mat-icon> {{row.mobileNumber}}</span>
                                                <p class="use-active-status">
                                                    <span class="text-success font-weight-bold btn-sm" *ngIf="row.status.listTypeValueId == 1">
                                                      {{row.status.listTypeValueName}}
                                                  </span>

                                                    <span class="text-danger font-weight-bold btn-sm" *ngIf="row.status.listTypeValueId == 2">
                                                      {{row.status.listTypeValueName}}
                                                  </span>
                                                </p>
                                            </div>
                                        </div>
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button routerLink="/home/edit-employee/{{row.employeeId}}" routerLinkActive="active" mat-menu-item>
                                              <mat-icon>edit</mat-icon>
                                              <span>Edit</span>
                                          </button>

                                            <button mat-menu-item routerLink="/home/view-employee/{{row.employeeId}}" routerLinkActive="active">
                                              <mat-icon>visibility</mat-icon>
                                              <span>View</span>
                                          </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="nodatafound" class="no-data">
                        <img src="assets/uploads/nodata.png">
                    </div>
                </div> -->

                <!-- <div class="card_view" *ngIf="pageViews == 3">
                    <div *ngIf="resultData?.length > 0 ">
                        <div class="row mb-3">
                            <div class="col-md-4 mb-3" *ngFor="let row of resultData | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <button class="bars" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                          <mat-icon>more_vert</mat-icon>
                                          </button>
                                        </div>
                                        <div class="row1">
                                            <div class="user-basics-card-new">
                                                <span class="user-name-list">
                                                  {{ucFirst(row.firstName)}} {{ucFirst(row.lastName)}}
                                                  <p class="user-id-list"> ( # {{row.employeeNumber}})</p>
                                              </span>
                                                <span class="card-date pt-3 pr-2"><mat-icon>calendar_month</mat-icon> {{row.dateOfBirth | date: 'dd-MMM-yyyy'}}</span>
                                                <span *ngIf="row.dateOfJoining !=undefined" class="card-date pr-2"><mat-icon>work_outline</mat-icon> {{row.dateOfJoining | date: 'dd-MMM-yyyy'}}</span>

                                                <span class="card-date pr-2"><mat-icon>phone</mat-icon> {{row.mobileNumber}}</span>
                                                <p class="use-active-status">
                                                    <span class="text-success font-weight-bold btn-sm" *ngIf="row.status.listTypeValueId == 1">
                                              {{row.status.listTypeValueName}}
                                              </span>

                                                    <span class="text-danger font-weight-bold btn-sm" *ngIf="row.status.listTypeValueId == 2">
                                              {{row.status.listTypeValueName}}
                                              </span>
                                                </p>
                                            </div>
                                        </div>
                                        <mat-menu #menu="matMenu" class="controls-list">
                                            <button routerLink="/home/edit-employee/{{row.employeeId}}" routerLinkActive="active" mat-menu-item>
                                              <mat-icon>edit</mat-icon>
                                              <span>Edit</span>
                                          </button>

                                            <button mat-menu-item routerLink="/home/view-employee/{{row.employeeId}}" routerLinkActive="active">
                                              <mat-icon>visibility</mat-icon>
                                              <span>View</span>
                                          </button>

                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="nodatafound" class="no-data">
                        <img src="assets/uploads/nodata.png">
                    </div>
                </div> -->

                <!-- <div *ngIf="manageList?.length>0 ">
                    <div class="row mt-3">
                        <div class="col-md-8">
                            <div class="showing-record">
                                {{manageList.length}} rows
                            </div>
                        </div>
                        <div class="col-md-4 text-right">
                            <pagination-controls (pageChange)="p = $event;beforepage = $event"></pagination-controls>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-md-8 " style="float: right; padding-left: 230px;">
                    <pagination-controls (pageChange)="p = $event" ></pagination-controls>
                </div> -->

                <div class="col-md-12 text-right" *ngIf="!error && !nodatafound && manageList?.length > 5  && showTable">
                    
                    <pagination-controls (pageChange)="p = $event" style="float:right; margin-top:10px;"></pagination-controls>
                </div>

            </div>
        </div>
    </div>
</div>


<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<!-- <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div> -->





<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
</div>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" 
    style="width: 3rem; height: 3rem;color: #009ef7;position: absolute;bottom: 0;" role="status"></div>
</div> 


<!-- <mat-card class="card-content-nodata mat-card-nodata" *ngIf="nodatafound" style="    margin-top: 250px;">
    <div class="card-body-nodata">
      <div class="alert-nodata alert-primary" style="margin-top: 12px;">
        
        <h4 align="center" class="h4" style="    margin-top: 14px;
        font-size: 24px;
        font-family: Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 15px;
        "> Records Not Found </h4>
      </div>
    </div>
     </mat-card> -->