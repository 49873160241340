import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
import { AddDesignationComponent } from '../add-designation/add-designation.component';
import { httpOptions, redirectMsgTimeOut, responseMsgTimeOut } from '../../../providers/properties';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit {
  organizationId: any;
  employeetData: any;
  search: any;
  tableShow: boolean = false;
  mySelect: any = 5;
  nodatafound: boolean = false;
  loader: boolean = true;
  isbtnDisable: boolean = false;
  clientId:number;

  constructor(
    private settingsservice: SettingsService,
    private dialog: MatDialog,
  ) { }


  resultData: any;
  p: number = 1;
  collection: any[];
  pagePerItem: any = 10;
  tableSearch: any;
  errorMsg: any;

  success = false;
  error: any = false;
  sucess_msg: any;
  error_msg: any;
  sortedData: any[];

  ngOnInit(): void {

    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));
    console.log('this.employeetData',this.employeetData);
    this.clientId = this.employeetData?.employee?.client?.clientId;
    let organizationId = this.employeetData?.organization?.organizationId;
    console.log('organizationId' + organizationId)
    this.organizationId = organizationId;
    this.getDesignationList();
  }

  addForm() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AddDesignationComponent, {
      //height: 'auto',width: '600px',
      width: '500px', height: 'fit-content',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDesignationList();

      if (result.statusCode == 200) {
        this.success = true;
        this.isbtnDisable = true

        this.sucess_msg = result.message;
        setTimeout(() => {
          this.success = false;
          this.isbtnDisable = false

        }, 4000)
      }
      else if (result.statusCode > 400) {
        this.error = true;
        this.isbtnDisable = true;

        this.error_msg = result.message;
        setTimeout(() => {
          this.error = false;
          this.isbtnDisable = false

        }, 4000)
      }
    });
    this.isbtnDisable = false;

  }

  updateform(editData) {
    var dialogRef = this.dialog.open(AddDesignationComponent, {
      width: '500px', height: 'fit-content',
      data: editData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDesignationList();

      if (result.statusCode == 200) {
        this.success = true;
        this.isbtnDisable = true

        this.sucess_msg = result.message;
        setTimeout(() => {
          this.success = false;
          this.isbtnDisable = false

        }, 4000)
      }
      else if (result.statusCode > 400) {
        this.error = true;
        this.isbtnDisable = true

        this.error_msg = result.message;
        setTimeout(() => { this.error = false }, 4000)
      }
      //this.success = true;
    });
  }
  updateStatus(designationId, status) {
    this.settingsservice.updateDeignationStatus(designationId, status).subscribe((result: any) => {
      if (result.statusCode == 200) {
        this.loader = true
        this.tableShow = false;
        // this.success = true;
        // this.sucess_msg = result.message;
        setTimeout(() => {
          // this.success  = false;
          this.loader = false
          this.tableShow = true
        }, redirectMsgTimeOut)

        this.getDesignationList();
      }
      else {
        // this.error = true;
        // this.error_msg = result.message;
        setTimeout(() => { this.error = false }, redirectMsgTimeOut)
      }
    })
  }
  changefirstpage() {
    this.p = 1;
  }
  getDesignationList()
   {
    this.p = 1
    this.tableShow = false;
    this.loader = true
    /* Get Designation Data */

    if(this.organizationId){
      this.settingsservice.getDesignationList(this.organizationId).subscribe((result: any) => {

        this.tableShow = true;
        this.loader = false
        this.resultData = result;
        // this.resultData = result.data || []; 
        if (result.statusCode == 404) {
          this.nodatafound = true;
        } else {
          this.nodatafound = false;
        }
        if (this.resultData.length === 0) {
          this.nodatafound = true;
        }
      }, err => {
        this.nodatafound = true;
  
        // this.errorMsg = err.error.message;
      })
    }

    if(this.clientId){
      this.settingsservice.getDesignationListbyClientId(this.clientId).subscribe((result: any) => {

        this.tableShow = true;
        this.loader = false
        this.resultData = result;
        // this.resultData = result.data || []; 
        if (result.statusCode == 404) {
          this.nodatafound = true;
        } else {
          this.nodatafound = false;
        }
        if (this.resultData.length === 0) {
          this.nodatafound = true;
        }
      }, err => {
        this.nodatafound = true;
  
        // this.errorMsg = err.error.message;
      })
    }
  
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  sortData(sort: Sort) {
    //const data = this.resultData.slice();
    const data = this.resultData;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'designationName':
          return compare(a.designationName, b.designationName, isAsc);

        case 'designationDescription':
          return compare(a.designationDescription, b.designationDescription, isAsc);

        default:
          return 0;
      }
    });
  }
}//Export end

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
