import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../../../providers/settings.service';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.scss']
})
export class AddCountryComponent implements OnInit {

  postForm: FormGroup;
  submitted: boolean = false;
  errorMsg : any;
  resultData:any;

  sucess_msg:any;
  error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;

  countryNameExistStatus:any;
  countryCodeExistStatus:any;
  employeetData:any;
  countryId : any;

  constructor(
    private settingsservice: SettingsService,
    private formBuilder: FormBuilder,
    private router : Router,
    private matDialog : MatDialog,
    private dialogref : MatDialogRef<AddCountryComponent>,
    @Inject(MAT_DIALOG_DATA) public edit_data: any
  ) { }

  //Document ready function
  ngOnInit(): void
  {
    this.postForm = this.formBuilder.group({
      countryName : ['',[Validators.required]],
      countryCode : ['' ,[Validators.required,Validators.pattern(/^[0-9_+-]*$/)]],
      countrySymbol : ['' ,[Validators.required]],
      currencyCode : ['' ,[Validators.required,Validators.pattern(/^[A-Za-z-]*$/)]],
      maxPhoneNumberLength : [''],
    });

    if(this.edit_data)
    {
      this.countryId = this.edit_data.countryId;
      this.settingsservice.getCountryById(this.edit_data.countryId).subscribe((resultData:any) =>
      {
        this.resultData=resultData;

        this.postForm.patchValue({
          countryName: this.resultData.countryName,
          countryCode: this.resultData.countryCode,
          countrySymbol: this.resultData.countrySymbol,
          currencyCode: this.resultData.currencyCode,
          maxPhoneNumberLength: this.resultData.maxPhoneNumberLength,
        });
      });
    }
  }

  // convenience getter for easy access to form fields
  get validate() { return this.postForm.controls; }


  //Submit Form
  onSubmit() //form Submit Function
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    this.submitted = true;

    //stop here if form is invalid
    if (this.postForm.invalid || this.countryNameExistStatus == true ||  this.countryCodeExistStatus == true) {return;}

    if(this.edit_data) //Edit
    {
      let postValues = this.postForm.value;

      postValues['organization'] = {organizationId: organizationId}
      postValues['countryId'] = this.edit_data.countryId;
      postValues['countryStatus'] = this.edit_data.countryStatus;

      this.backdrop = true;
      this.loader=true;

      this.settingsservice.editCountry(this.edit_data.countryId,postValues)
      .subscribe((user: any) =>
      {
        //this.router.navigate([ '/home/department' ]);
        //window.location.reload();

        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
        // this.error = true;
        // this.update_error_msg = err.error.message;
        // console.log(this.update_error_msg);
        //setTimeout(() => {this.error = false}, 2000)
      })
    }
    else //Create
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      /* if(this.postForm.valid && this.departCodeExistStatus == true || this.departNameExistStatus == true)
      {
        console.log("if condition")
        return
      } */
      /* if(this.postForm.valid && this.countryNameExistStatus == true)
      {
         return
      } */

      this.settingsservice.createCountry(postValues).subscribe((user: any) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.success = true;

        this.dialogref.close(user);
      }, err =>{
        this.backdrop = false;
        this.loader = false;
        this.dialogref.close(err.error);
      })
    }
  }

  checkExistCountryName(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
   /*  if(this.edit_data)
    {
      editData = this.edit_data;
    } */
    if(this.postForm.value.countryName.length != 0)
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      if(this.edit_data)
      {
        postValues['countryId'] = this.edit_data.countryId;

        this.settingsservice.updateCheckExistCountryName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.countryNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.countryNameExistStatus = true;
          }
        })
      }
      else
      {
        this.settingsservice.createCheckExistCountryName(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.countryNameExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.countryNameExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.countryNameExistStatus = false;
    }
  }

//Country Code Exist Check

  checkExistCountryCode(val)
  {
    this.employeetData = JSON.parse(localStorage.getItem('enoteUserData'));

    let organizationId = this.employeetData.organization.organizationId;
    console.log('organizationId'+organizationId)
    let editData = 0;
   /*  if(this.edit_data)
    {
      editData = this.edit_data;
    } */
    if(this.postForm.value.countryCode.length != 0)
    {
      let postValues = this.postForm.value;
      postValues['organization'] = {organizationId: organizationId}

      if(this.edit_data)
      {
        postValues['countryId'] = this.edit_data.countryId;

        this.settingsservice.updateCheckExistCountryCode(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.countryCodeExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.countryCodeExistStatus = true;
          }
        })
      }
      else
      {
        this.settingsservice.createCheckExistCountryCode(postValues).subscribe((result: any) =>
        {
          if(result.statusCode == 200)
          {
            this.countryCodeExistStatus = false;
          }
          else if(result.statusCode > 400)
          {
            this.countryCodeExistStatus = true;
          }
        })
      }
    }
    else
    {
      this.countryCodeExistStatus = false;
    }
  }

}
