<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>{{moduleId ? "Edit" : "Add"}} Module</h3>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="form-group col-md-6">
                <label class="col-form-label">Module Code <span class="star">*</span></label>
                <input type="text" (keyup)="checkExistModuleCode($event)" formControlName="moduleCode" id="moduleCode" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg" value="" placeholder="Module Code" autocomplete="off">
                <div class="valid-msg" *ngIf="(validate.moduleCode.invalid && validate.moduleCode.touched) || validate.moduleCode.dirty || submitted">
                    <span *ngIf="validate.moduleCode.errors?.required" class="text-danger">Please enter Module Code</span>
                    <span *ngIf="moduleCodeExistStatus" class="span-error"> Module code already exist!</span>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label class="col-form-label">Module Name <span class="star">*</span></label>
                <input type="text" (keyup)="checkExistModuleName($event)" formControlName="moduleName" id="moduleName" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg" value="" placeholder="Module Name" autocomplete="off">
                <div class="valid-msg" *ngIf="(validate.moduleName.invalid && validate.moduleName.touched) || validate.moduleName.dirty || submitted">
                    <span *ngIf="validate.moduleName.errors?.required" class="text-danger">Please enter Module Name</span>
                    <span *ngIf="moduleNameExistStatus" class="span-error"> Module name already exist!</span>
                </div>
            </div>
        </div>

        <div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label class="col-form-label">Module Description</label>
                    <input type="text" formControlName="moduleDescription" id="moduleDescription" autocomplete="off" class="form-select form-select-solid form-select-lg" value="" placeholder="Module Description">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Menus <span class="star">*</span></label>
                <mat-select formControlName="menuId" placeholder="Select Menu" #select multiple class="form-control email-input select-placeholder">
                    <div class="select-all">
                        <mat-checkbox class="check-all select-all-btns" [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }" (change)="toggleAllSelection()">
                            Select All
                        </mat-checkbox>
                    </div>

                    <mat-option *ngFor="let row of activeMenusData" [value]="row.menuId">
                        {{row.menuname}}
                    </mat-option>

                    <button mat-raised-button color="primary" style="margin: 0 18px 18px 18px" (click)="add()" class="btn create-button">
                  Add
              </button>

                    <button mat-raised-button color="danger" (click)="cancel()" class="btn cancel-button">
                  Cancel
              </button>
                </mat-select>

                <mat-error *ngIf="validate.menuId.hasError('required') && submitted">
                    Please select Menus
                </mat-error>

                <!-- <mat-select formControlName="roleId" class="form-select form-select-solid form-select-lg" appearance="fill" placeholder="- Select -">
              <mat-option value="">- Select -</mat-option>
              <mat-option *ngFor="let row of activeRolesData" [value]="row.roleId">{{row.roleName}}</mat-option>
          </mat-select> -->
                <!-- <div class="valid-msg" *ngIf="(validate.departmentCode.invalid && validate.departmentCode.touched) || validate.departmentCode.dirty || submitted">
              <span *ngIf="validate.departmentCode.errors?.required" class="text-danger">Please enter department code</span>
              <span *ngIf="validate.departmentCode.errors?.pattern" class="text-danger">Please enter valid department code</span>
              <span *ngIf="departCodeExistStatus" class="span-error"> Department code already exist!</span>
          </div> -->
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
        <button type="submit" mat-raised-button color="primary">Save</button>
    </mat-dialog-actions>
</form>


<!-- <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div> -->
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{error_msg}}
</div>
