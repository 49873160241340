import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appWrapText]'
})
export class WrapTextDirective implements OnInit {

  @Input() maxLength: number = 12; // Default maximum length

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.wrapText();
  }

  private wrapText() {
    const text = this.el.nativeElement.textContent;

    if (text.length > this.maxLength) {
      this.renderer.setStyle(this.el.nativeElement, 'white-space', 'normal');
    }
  }
}
