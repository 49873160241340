import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { HrmsserviceService } from 'src/app/services/hrmsservice.service';

@Component({
  selector: 'app-shareresoursefeedback',
  templateUrl: './shareresoursefeedback.component.html',
  styleUrls: ['./shareresoursefeedback.component.scss']
})
export class ShareresoursefeedbackComponent implements OnInit {

  description: String;
  resourceId: number;
  resFeedback: any;
  ratings: any;
  skills: any;
  recommendations: any;
  resourceObj: any;
  resName: string;
  position: string;
  comments: string;
  isDisabled: boolean = true;
  arr: any;
  resRating: any = [];
  recoName: any;

  constructor(private hrmsService: HrmsserviceService, 
    private fb: FormBuilder, 
    private activatedRoute: ActivatedRoute,
     public dialog: MatDialog) { }

  ngOnInit(): void {

    this.resourceId = this.activatedRoute.snapshot.params.resourceId;

    this.hrmsService
      .findbyResourceId(this.resourceId)
      .subscribe((resource: any) => {
        this.resourceObj = resource;
        console.log(this.resourceObj);
        this.resName = this.resourceObj.resourceName;
        console.log('this.resName', this.resName)
        this.position = this.resourceObj.position.positionName

      });
    this.hrmsService.getAllRatings()
      .subscribe((ratingList: any) => {
        this.ratings = ratingList.reverse();
        console.log('ratingList', this.ratings);
      });

    this.hrmsService.getAllSkills()
      .subscribe((skillList: any) => {
        this.skills = skillList;
        console.log('skillList', this.skills);
      })

   

    this.hrmsService.getRessourceFeedbackByResourceId(this.resourceId)
      .subscribe((feedback: any) => {
        this.resFeedback = feedback;
        console.log('feedback', this.resFeedback);
        this.resRating = this.resFeedback.resourceRating;
        console.log(' this.resRating', this.resRating);
        this.comments = this.resFeedback.comments;

        const data = this.resFeedback.resourceRating.map(x => x.rating.ratingId)
        console.log('data', data)
        this.arr = data;
        console.log(' this.recommendations', this.recommendations)
        this.hrmsService.getAllRecommendations()
        .subscribe((recList: any) => {
          console.log('recList', recList);
          this.recommendations = recList;
          console.log("this.recommendations",this.recommendations)
          if(this.recommendations != undefined){
            this.recommendations.map((recomendation) => {
              if (recomendation.listTypeValueId == feedback.recomendation.listTypeValueId) {
                console.log('equal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                recomendation.selected = !recomendation.selected;
                this.recoName = recomendation.listTypeValueName;
              } else {
                console.log('notequal', recomendation.listTypeValueId, feedback.recomendation.listTypeValueId)
                recomendation.selected = false;
              }
            })
          }
        });
       
       
      })
  }

  }


