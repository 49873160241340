import { Component,Inject, OnInit, ViewChild,ElementRef,AfterViewInit } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake  from "html-to-pdfmake";
import { DatePipe } from '@angular/common';
import { el } from '@fullcalendar/core/internal-common';
import { PayrolldataService } from '../services/payrolldata.service';
import { PayrollService } from '../providers/payroll.service';
import {SuperadminService } from '../providers/superadmin.service'
import { RecruitmentService } from '../providers/recruitment.service';


@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {

  @ViewChild('viewPdf')viewPdf: ElementRef;
  payrolldata: any =[];
    Earnings: any = [];
    Deductions: any= [];
    organzationId: any;
    userdata: any;
    orgdata: any;
    clientdata: any;
  // @ViewChild('myElement') myElement: ElementRef;
  
  constructor(
    private datepipe  : DatePipe ,  private payrolldataService :  PayrolldataService  , 
    private payrollservice : PayrollService , private SuperadminService: SuperadminService,private recruitmentservice :RecruitmentService
  ) { }

  ngOnInit(): void {

     this.userdata = JSON.parse(localStorage.getItem('enoteUserData'));
    // console.log(this.userdata);

      this.payrolldata = this.payrolldataService.payrolldata;
       console.log("payrolldata" + this.payrolldata.length)
     
       this.organzationId= this.userdata.organization.organizationId;
         this.SuperadminService.getOrganizationById(this.organzationId).subscribe(
                      (resultData:any) =>{
                        this.orgdata = resultData;
                      }
                    )

                    this.recruitmentservice.clientbyid(this.payrolldata[0].client.clientId).subscribe( (resultData:any) =>{
                        this.clientdata = resultData;
                      }
                    )

             this.payrolldata.map(element => {
                element["totalearnings"] = this.getearningtotal(element);
                element["totaldeductions"] = this.getdeductionstotal(element);
                element["netpay"] = this.getnetpay(element.totalearnings , element.totaldeductions);
                element["earnings"] = this.getEarning(element);
                element["deductions"] = this.getDeductions(element);
            
             });
    
  }

  getEarning(payroll)
  {
    let Earnings = [];
       payroll.payrollLine.forEach(payrollLine => {
        if(payrollLine.hrmspaySlipElement.categoryType.listTypeValueId == 42){
            Earnings.push(payrollLine);
        
          console.log("EarningsArray",this.Earnings);
        }
    //     else{
    //       if(!payrollLine.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYER"))
    //       {
    //         this.Deductions.push(payrollLine);
           
    //     }

       
    // //      console.log("DeductionsArray",this.Deductions);
    //     }
      });

        return Earnings;
  }

  getDeductions(payroll)
  {
    let deductions = [];
       payroll.payrollLine.forEach(payrollLine => {
      
          if(payrollLine.hrmspaySlipElement.categoryType.listTypeValueId == 43 && !payrollLine.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYER"))
          {
                deductions.push(payrollLine);
           
        }
            console.log("DeductionsArray",deductions);
      
      });

        return deductions;
  }


  getdeductionstotal(payroll)
  {
      let dedutionsAmounttotal = 0;
    payroll.payrollLine.forEach(payrollLine => {
      
        if(payrollLine.hrmspaySlipElement.categoryType.listTypeValueId == 43 && !payrollLine.hrmspaySlipElement.elementName.toUpperCase().includes("EMPLOYER"))
        {
            dedutionsAmounttotal += payrollLine.amount;
         
        }
          
    
    });

        return dedutionsAmounttotal?.toFixed(2);
  }



  getearningtotal(payroll)
  {
     let earningsAmounttotal = 0;
    payroll.payrollLine.forEach(payrollLine => {
        if(payrollLine.hrmspaySlipElement.categoryType.listTypeValueId == 42){
            earningsAmounttotal += payrollLine.amount;
        }
      });

        return earningsAmounttotal?.toFixed(2);
  }

  getnetpay(earning , deductions)
{

    let netpay = earning - deductions;
   return netpay;
}

  printPdf()
  {
   //  alert("funtion called");
    if(this.viewPdf)
    {
      console.log("printPDf",this.viewPdf);
      const viewPDF = this.viewPdf.nativeElement;
      var html = htmlToPdfmake(viewPDF.innerHTML,{tableAutoSize:true});
      // this.gs.getManagePayslipById(this.payrollHeaderId).subscribe(
      //   (data:any)=>
      //   {
      //  //   console.log(data);
      //     this.payrolldata=data;
          // const formattedDate = this.datepipe.transform("text");
          const documentDefinition = { 
          content: html ,
          pageMargins: [ 10, 10, 30, 35 ],
          // info: {
          //   title:`Report ${formattedDate}`
          //   },
          title : "wage slip",
            pageSize: 'A4',
            pageOrientation: 'landscape',
          };
         pdfMake.createPdf(documentDefinition).download("wage slip.Pdf"); 
      //   })
    }
    else{
         console.log("No Data");
    }
    
  }

  back() {
    history.back()
    }
}
