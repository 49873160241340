<!-- <form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>Import Employees</h3>
    
    <div class="row">
    
        <div class="col-md-10">
            <label class="file-label">
                <div class="row">
                    <div class="col-md-2">
                        <mat-icon class="material-symbols-outlined">
                            attach_file_add
                           
                            </mat-icon>
                    </div>
                    <div class="col-md-10">
                        <span>{{  fileInputLabel ||  'Attach File' }}</span>
                    </div>
                </div>
             
                 
                <input type="file"                                                        
                formControlName="file"
                placeholder="Enter description"  [ngClass]="{ 'is-invalid': submitted }"
                class="file-input"  (change)="onFileSelect($event)">
              </label>
                <div class="valid-msg" *ngIf="(validate.file.invalid && validate.file.touched) || validate.file.dirty || submitted">
                    <span *ngIf="validate.file.errors?.required" class="text-danger">Please select .xls / xlsx file</span>
                    <span *ngIf="validate.file.errors?.pattern" class="text-danger">Please enter valid .xls / xlsx file</span>
                </div>
               <div>
                <span style=" margin-bottom: 10px;" class="text-muted import-note ">Note : Upload format is .xls / xlsx.</span>
               </div>
            </div>
            
    </div>

    <div class="row">
        <div class="col-md-12">
            <span class="text-muted">
                *** Please download the sample file and import the data as mentioned format. ***
            </span>  
            <a class="download-sample text-danger" href="assets/backend/sample_documents/employee_import.xlsx" download="employee_import.xlsx">
                Download Sample
            </a> 
        </div>
    </div>

    <mat-dialog-actions align="end">
        <button type="button" mat-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-raised-button color="primary">Upload</button>
    </mat-dialog-actions>
</form>

<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>

<div class="text-center" *ngIf="error">
    <div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
        <strong>Error!</strong><br>{{error_msg}}
    </div>
</div> -->

<form [formGroup]="postForm">
  <h3 mat-dialog-title class="assign-task">Assign Employee Task</h3>

  <div class="row">
    <!-- <div class="form-group col-md-10">
            <label class="col-form-label">Upload File <span class="star">*</span></label>
            <input type="file" formControlName="file" id="file" (change)="onFileSelect($event)" [ngClass]="{ 'is-invalid': submitted }" class="form-select form-select-solid form-select-lg" accept=".xls,">
            <div class="valid-msg" *ngIf="(validate.file.invalid && validate.file.touched) || validate.file.dirty || submitted">
                <span *ngIf="validate.file.errors?.required" class="text-danger">Please select .xls / xlsx file</span>
                <span *ngIf="validate.file.errors?.pattern" class="text-danger">Please enter valid .xls / xlsx file</span>
            </div>
            <span class="text-muted import-note">Note : Upload format is .xls / xlsx.</span>
		</div> -->
    <div class="col-md-10">
      <label class="file-label">
        <div class="row">
          <!-- <div class="col-md-2">
                        <mat-icon class="material-symbols-outlined">
                            attach_file
                           
                            </mat-icon>
                    </div>
                    <div class="col-md-10">
                        <span>{{  fileInputLabel ||  'Attach File' }}</span>
                    </div> -->
        </div>

        <input
          UploadFileInput
          type="file"
          accept=".xls,.xlsx"
          formControlName="file"
          placeholder="Enter description"
          [ngClass]="{ 'is-invalid': submitted }"
          class="file-input"
          (change)="onFileSelect($event)"
        />
      </label>
      <!-- <div class="valid-msg" *ngIf="(validate.file.invalid && validate.file.touched) || validate.file.dirty || submitted">
                    <span *ngIf="validate.file.errors?.required" class="text-danger">Please select .xls / xlsx file</span>
                    <span *ngIf="validate.file.errors?.pattern" class="text-danger">Please enter valid .xls / xlsx file</span>
                </div> -->
      <div *ngIf="(f.file.invalid && submitted) || f.file.dirty">
        <span *ngIf="f.file.errors?.required" class="text-danger"
          >Please select .xls / xlsx file</span
        >
      </div>
      <div *ngIf="file.invalid || file.dirty">
        <span *ngIf="file.errors?.pattern" class="text-danger"
          >Please enter valid .xls / xlsx file</span
        >
      </div>
      <div>
        <span style="margin-bottom: 10px" class="text-muted import-note"
          >Note : Upload format is .xls / xlsx.</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <span class="text-muted">
        *** Please download the sample file and import the data as mentioned
        format. ***
      </span>
      <a
        class="download-sample text-danger"
        href="../../../assets/backend/sample_documents/AssignEmpTaskImport (1).xlsx"
        download="employee_import.xlsx"
      >
        Download Sample
      </a>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button
      type="button"
      style="
        float: right;

        margin-left: 7px;
        background: none;

        border: 1px solid #ddd;
        font-size: 14px;
        font-weight: 300;
        height: 35px;
      "
      style="color: var(--Red-Color, #ff5d5d); height: 35px"
      mat-raised-button
      mat-dialog-close
      mat-raised-button
      (click)="onCancel()"
    >
      Cancel
    </button>
    <!--<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button>
    -->
    <!-- <button type="submit" mat-raised-button color="primary">Upload</button> -->

    <button
      mat-raised-button
      type="submit"
      (click)="onSubmit()"
      class="searchBtn"
      [disabled]="!disableButton"
    >
      Upload
    </button>
  </mat-dialog-actions>

  <!-- <div *ngIf="loader" class="align-items-center text-center">
    <div
      class="spinner-border"
      style="
        width: 3rem;
        height: 3rem;
        position: absolute;
        z-index: 1;
        top: -1%;
        right: 49%;
      "
      role="status"
    ></div>
  </div> -->

  <div class="text-center" *ngIf="success; else errorBlock">
    <div class="alert alert-resource alert-success toast-msg" role="alert">
      <strong>Success!</strong><br />{{ sucess_msg }}
    </div>
  </div>

  <ng-template #errorBlock>
    <div class="text-center" *ngIf="error">
      <div class="alert alert-danger alert-dismissible toast-msg">
        <strong>Error!</strong><br />{{ error_msg }}
      </div>
    </div>
  </ng-template>
</form>

<div *ngIf="loader"  class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem;
    height: 3rem;
    position: absolute;
    z-index: 1;
    top: 37%;
    right: 47%;"
    role="status"
  ></div>
</div>