import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-tax-breakage-list',
  templateUrl: './client-tax-breakage-list.component.html',
  styleUrls: ['./client-tax-breakage-list.component.scss']
})
export class ClientTaxBreakageListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
