  import { Component, OnInit } from '@angular/core';
import { FormGroup ,FormBuilder, Validators,FormArray} from '@angular/forms';
import { PmsService } from '../../providers/pms.service';
import { Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forEach } from 'mathjs';
import { DatePipe } from '@angular/common';
import {httpOptions,responseMsgTimeOut,redirectMsgTimeOut} from '../../providers/properties';
// import { log } from 'console';


// import {PmsService } from '../../providers/pms.service'
@Component({
  selector: 'app-add-appraisal',
  templateUrl: './add-appraisal.component.html',
  styleUrls: ['./add-appraisal.component.scss']
})
export class AddAppraisalComponent implements OnInit {
  [x: string]: any;
  apprialform:FormGroup;
  submitted: boolean = false;
  usertData: any;
  orgId: any;
  empId: any;
  empData: any;
  approverData: any;
  kraTemplateData:any;
  empName: any;
  approverName: string;
  localempId:any;
  LastReviewDate:any;
  createDate:any;
  formattedDate:string;
  kraTempLine:any=[];
  currentYear:number;
  periodData:any[] = [];
  empLineList:any;
  per:any;
  sucess_msg:any;
  error_msg:any;
  LineDatas:any=[];
  LastRevieDate:any;
  crateDate:any;
  period:any;
  minHours: number = 0; 
  minDays:number =0;
  currentMonth:string;
  // selectvalues:any
  currentMonth1:string;
  currentYear1:number;
  selectedYears:any;
  selectedPeriodIds:any;
 
  finalMatchedPeriodId:any;

  patchPeriodId:any;
  patchYearId:any;

  submitButtonDisabled:boolean=false;
  loader:boolean=false;

  
  
 
  

  constructor(private router:Router,
    private pmsService: PmsService,
    private dialog:MatDialog,
    private fb:FormBuilder,
    private datepipe: DatePipe
    ) { }

  ngOnInit(): void 
  {
    
    this.usertData = JSON.parse(localStorage.getItem('enoteUserData'));
    let userId = this.usertData.userId;
    this.orgId = this.usertData.organization.organizationId;
    console.log("this.orgId:",this.orgId);
    this.localempId= this.usertData.employee.employeeId;
    console.log("empId:",this.localempId);
    // this.position="Java Developer";
    // console.log("position:",this.position);
     this.position = this.usertData?.employee?.designation?.designationName;
    console.log("this.orgId:",this.position);
    // this.department ="Java";
    // console.log("dept:",this.department);
    this.department = this.usertData?.employee?.department?.departmentName;
    console.log("this.orgId:",this.department);
    // this.userId = this.usertData.userId;
    // console.log("Employye ID"+this.userId);
   

    
    // const today = new Date();
    // this.formattedDate = this.datePipe.transform(today, 'yyyy-MM-dd');
    // console.log(this.formattedDate);
    // this.today.setYear(this.today.getFullYear() - 18)
    // this.today =this.datepipe.transform(this.today, 'yyyy-MM-dd')
    // console.log("today"+this.today);
    // let review="31-10-2023";
    // let craDate="31-10-2023";
    // this.LastReviewDate = this.datepipe.transform(review, 'yyyy-MM-dd');
    // this.createDate= this.datepipe.transform(craDate, 'yyyy-MM-dd');
    // console.log("LastReviewDate:"+this.LastReviewDate);
    // console.log("createDate:"+this.createDate);
    this.apprialform=this.fb.group({
      period:['',Validators.required],
      employeeName:[null],
      department:[null],
      empId:[null],
      reviewerName:[null],
      positionHold:[null],
      reviewrTitle:[null],
      LastReviewDate:[''],
      createDate:[''],
      empoverallrating:[''],
      hoursWorked:[null,Validators.required],
      // hoursWorked: ['', [Validators.required, Validators.maxLength(3)]],
     
      leaveDays:[null,Validators.required],
      query:[null],
      file:[null,Validators.required],
      
      kra: this.fb.array([
        // this.fb.group({
        // criteriaName: [''],
        // criteriaDescription: ['',Validators.required], // You can add your initial value here
        // criteriaDetails: ['',Validators.required], // You can add your initial value here
        // weightage: [''], // You can add your initial value here
        // empRating: ['',Validators.required], // You can add your initial value here
        // rating: [''], // You can add your initial value here
        // })
      ]),
      // empRating:[null,Validators.required],
      // empRating:[null,Validators.required]
      
      
    })

    

    
    

    const currentDate = new Date();
    console.log(currentDate);
    // const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    // console.log(currentMonth);
    // const currentYear = currentDate.getFullYear();
    // console.log(currentYear);

    // const currentDate: Date = new Date();
    // console.log(currentDate);

    const currentMonth: string = currentDate.toLocaleString('default', { month: 'long' });
    console.log(currentMonth);
    this.currentMonth1 = currentMonth;
    console.log(this.currentMonth1);

    const currentYear: number = currentDate.getFullYear();
    console.log(currentYear);
    this.currentYear1 = currentYear;
    console.log(this.currentYear1);

    // const defaultPeriodValue = `${this.currentMonth1}-${this.currentYear1}`;
    // this.apprialform.get('period').patchValue(defaultPeriodValue);
    

    
    // let formattedTodayDate = this.pipe.transform(currentDate, 'yyyy-MM-dd');
    this.formattedTodayDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    console.log(this.formattedTodayDate);
    this.formattedLastDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    console.log(this.formattedLastDate);
    
    this.apprialform.patchValue({
      createDate:this.formattedTodayDate,
      LastReviewDate:this.formattedLastDate,
      // period:this.currentMonth1 +"-"+  this.currentYear1
    })
    console.log(this.apprialform.value);

    this.currentYear = new Date().getFullYear();
    console.log(this.currentYear);

    // this.pmsService.getPeriodList(this.orgId,this.currentYear).subscribe((Data: any) => 
    // {
    //   this.periodData = Data;
    //   this.approverData.forEach(ele => {
    //     console.log("approver:",ele);
    //     this.approverName=ele.approvers.approverOneObject.firstName+" "+ele.approvers.approverOneObject.lastName;
    //     console.log(this.approverName);
    //     this.approveRole=ele.approvers.rulename;
    //     console.log(this.approveRole);
    //     this.apprialform.patchValue({
    //       reviewerName:this.approverName,
    //       reviewrTitle:this.approveRole
    //     })
    //   });
      
    //   if(this.Data.length == 0 || this.Data.length == undefined)
    //   {
    //     // this.nodatafound = true;
    //     this.periodData=[];
    //   }
    // })

    this.pmsService.getAssignKRAEmpCodeList(this.localempId).subscribe((resultData: any) => {
      
      if (Array.isArray(resultData) && resultData.length !== undefined) {
          this.empData = resultData;
          this.empData.forEach(element => {
              console.log("Element:", element);
              this.empName = element.employee.firstName + " " + element.employee.lastName;
              console.log(this.empName);
              this.empId = element.employee.employeeId;
              console.log(this.empId);
              this.apprialform.patchValue({
                  empId: this.empId,
                  employeeName: this.empName,
                  positionHold: this.position,
                  LastReviewDate:element.lastClosedDate
                  // createDate:this.createDate,
              });
          });
      } else {
          console.log("empData is not an array or its length is undefined");
         
      }
  
      if (!Array.isArray(this.empData) || this.empData.length === undefined) {
          this.empData = [];
      }
      
      if (this.empData.length === 0) {
          // this.nodatafound = true;
          this.empData = [];
      }
  });
    

  //   this.pmsService.getPeriodList(this.orgId, this.currentYear).subscribe((Data: any) => {
  //     this.periodData = Data; 
  //     console.log(this.periodData)
      
     
  //     console.log(this.apprialform.value);
  //     if (this.approverData) {
  //         this.approverData.forEach(ele => {
  //             console.log("approver:",ele);
  //             this.approverName=ele.approvers.approverOneObject.firstName+" "+ele.approvers.approverOneObject.lastName;
  //             console.log(this.approverName);
  //             this.approveRole=ele.approvers.rulename;
  //             console.log(this.approveRole);
  //             this.apprialform.patchValue({
  //                 reviewerName:this.approverName,
  //                 reviewrTitle:this.approveRole,
  //               })
  //         });
  //         console.log(this.apprialform.value);
  //     } else {
  //         console.log("Approver data is null or undefined");
  //     }
      
  //     if(Data.length == 0 || Data.length == undefined) {
  //         // this.nodatafound = true;
  //         this.periodData=[];
  //     }
  // })

  this.pmsService.getPeriodList(this.orgId, this.currentYear).subscribe((Data: any) => {
    this.periodData = Data; 
    console.log(this.periodData);

    
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    console.log(currentYear,"currentYear"); 
    console.log(currentMonth,"currentMonth");

    
    this.periodData.forEach(period => {
      console.log("Financial Period Month:", period.financialperiodMonth);
      console.log("Financial Period Year:", period.financialPeriodYear);
      console.log("month id:", period.financialPeriodId);
      console.log("year id:", period.finanicalYear.financialYearId);
    });

    
    const finalIds = this.periodData.filter(period => {
      console.log("Comparing financial period:", period.financialperiodMonth, period.financialPeriodYear);
      console.log("With current month and year:", currentMonth, currentYear);
    
      return period.financialperiodMonth === currentMonth || period.financialPeriodYear === currentYear;
      
  });
  
  console.log("finalIds Periods:", finalIds);
  if (finalIds.length > 0) {
    
    finalIds.forEach(matchingPeriod => {
        
        console.log("finalIds:", matchingPeriod.finanicalYear.financialYearId,matchingPeriod.financialPeriodId  );
  
        this.patchYearId = matchingPeriod.finanicalYear.financialYearId;
        this.patchPeriodId = matchingPeriod.financialPeriodId;
        console.log(this.patchYearId);
        console.log(this.patchPeriodId);
        

        this.apprialform.patchValue({
          period: this.patchPeriodId
        });
        this.apprialform.get("period").value;
        console.log( this.apprialform.get("period").value);
        console.log("dgdfa",this.patchPeriodId);


        this.pmsService.getLeavedays(this.localempId,this.patchYearId,this.patchPeriodId).subscribe((Data: any) => {
           this.leavedays=Data;
           console.log(this.leavedays);
           this.apprialform.patchValue({
            leaveDays:this.leavedays.leave_count
        });
        })

        this.pmsService.getWorkedHours(this.localempId,this.patchYearId,this.patchPeriodId).subscribe((Data: any) => {
          this.workedHours=Data;
          console.log(this.workedHours);
          this.apprialform.patchValue({
           hoursWorked:this.workedHours.TotalWorkedHours
       });
       })

        
        
        
       
    });
  } else {
      console.log("No matching period");
      
  }

    
  if (this.approverData) {
        this.approverData.forEach(ele => {
            console.log("approver:", ele);
            this.approverName = ele.approvers.approverOneObject.firstName + " " + ele.approvers.approverOneObject.lastName;
            console.log(this.approverName);
            this.approveRole = ele.approvers.rulename;
            console.log(this.approveRole);
            this.apprialform.patchValue({
                reviewerName: this.approverName,
                reviewrTitle: this.approveRole,
            });
        });
        console.log(this.apprialform.value);
    } else {
        console.log("Approver data is null or undefined");
    }

    if (Data.length == 0 || Data.length == undefined) {
        // this.nodatafound = true;
        this.periodData = [];
    }
});


  

  
  
  

    // this.pmsService.getAssignKRAEmpCodeList(this.localempId).subscribe((resultData: any) => 
    // {
    //   this.empData = resultData;
    //   console.log(this.empData);
    //   this.empData.forEach(element => {
    //   console.log("Element:",element);
    //   this.empName=element.employee.firstName+" "+element.employee.lastName;
    //   console.log(this.empName);
    //   this.empId=element.employee.employeeId;
    //   console.log(this.empId);
    //   this.apprialform.patchValue({
    //     empId:this.empId,
    //     employeeName:this.empName,
    //     positionHold:this.position,
    //     // LastReviewDate:this.LastReviewDate,
    //     // createDate:this.createDate,
    //   })
    //   });
    //   console.log("empData",this.apprialform.value);
      
    //   if(this.empData.length == 0 || this.empData.length == undefined)
    //   {
    //     // this.nodatafound = true;
    //     this.empData=[];
    //   }
    // })

    this.pmsService.getAssignKRAEmpCodeList(this.localempId).subscribe((resultData: any) => 
      {
        console.log("Raw resultData:", resultData);  // Debugging log to check the response
      
        this.empData = resultData;
        console.log("Processed empData:", this.empData);  // Debugging log to check the processed data
      
        // Check if empData is actually an array
        if (Array.isArray(this.empData)) {
          this.empData.forEach(element => {
            console.log("Element:", element);
            this.empName = element.employee.firstName + " " + element.employee.lastName;
            console.log(this.empName);
            this.empId = element.employee.employeeId;
            console.log(this.empId);
            this.apprialform.patchValue({
              empId: this.empId,
              employeeName: this.empName,
              positionHold: this.position,
              LastReviewDate:element.lastClosedDate
              // createDate: this.createDate,
            });
          });
          console.log("empData", this.apprialform.value);
        } else {
          console.error("empData is not an array:", this.empData);  // Error log if empData is not an array
          this.empData = [];
        }
      
        if (this.empData.length == 0 || this.empData.length == undefined) {
          // this.nodatafound = true;
          this.empData = [];
        }
      });
      

  
  

    this.pmsService.getApproverList(this.localempId).subscribe((resultData: any) => 
    {
      this.approverData = resultData;
      console.log(this.approverData);
      this.approverData.forEach(ele => {
        console.log("approver:",ele);
        this.approverName=ele.approvers.approverOneObject.firstName+" "+ele.approvers.approverOneObject.lastName;
        console.log(this.approverName);
        this.approveRole=ele.approvers.rulename;
        console.log(this.approveRole);
        this.apprialform.patchValue({
          reviewerName:this.approverName,
          reviewrTitle:this.approveRole
        })
      });
      
      if(this.approverData.length == 0 || this.approverData.length == undefined)
      {
        // this.nodatafound = true;
        this.approverData=[];
      }
    })

    

    this.pmsService.getApproverList(this.localempId).subscribe((resultData: any) => {
      this.approverData = resultData;
      console.log(this.approverData,"approverdata");
      if (this.approverData) {
          this.approverData.forEach(ele => {
              console.log("approver:",ele);
              this.approverName = ele.approvers.approverOneObject.firstName + " " + ele.approvers.approverOneObject.lastName;
              console.log(this.approverName);
              this.approveRole = ele.approvers.rulename;
              console.log(this.approveRole);
              this.apprialform.patchValue({
                  reviewerName: this.approverName,
                  reviewrTitle: this.approveRole,
                  
                  
              })
              console.log(this.apprialform.value);
          });
      } else {
          console.log("Approver data is null or undefined");
          
      }
      
      if (this.approverData && this.approverData.length == 0 || !this.approverData) {
          // this.nodatafound = true;
          this.approverData = [];
      }
  })

//   this.pmsService.getWorkedhours(this.localempId).subscribe((resultData: any) => {
//     this.workedHourData = resultData;
//     console.log(this.workedHourData,"workedhours");
//     if (this.workedHourData) {
//         this.workedHourData.forEach(ele => {
//             console.log("worked hours:",ele);
//             this.workedHours = ele.totalTime;
//             console.log(this.workedHours);
            
//             this.apprialform.patchValue({
//                 hoursWorked: this.workedHours,
                
                
                
//             })
//             console.log(this.apprialform.value);
//         });
//     } else {
//         console.log("Worked hours is null or undefined");
        
//     }
    
//     if (this.workedHourData && this.workedHourData.length == 0 || !this.workedHourData) {
//         // this.nodatafound = true;
//         this.workedHourData = [];
//     }
// })



  

    // this.pmsService.getemployeekratemplateList(this.localempId).subscribe((templatrData: any) => 
    // {
    //   this.kraTemplateData = templatrData;
    //   this.kraTemplateData.forEach(element => {
    //   console.log("Element:",element.kraTemplateHeader.kratemplateLine);
    //   element.kraTemplateHeader.kratemplateLine.forEach(ele => 
    //     {
    //     console.log("Element:",ele);
    //     this.empLineList=ele;
    //     console.log(this.empLineList);
    //     this.addRow(ele);
    //     // let kraobj=
    //     // {
    //     //   kraTemplateHeaderLineId: ele.kraTemplateHeaderLineId,
    //     //   criteriaName:ele.criteriaName,
    //     //   weightage:ele.weightage,
    //     //   negativeRatingCheck:ele.negativeRatingCheck,
    //     //   present: ele.present
    //     // }
    //     // console.log(kraobj);
    //     // this.kraTempLine.push(kraobj);
    //     // console.log("kraTempLine",this.kraTempLine);
    //   });

    //   this.apprialform.patchValue({
    //     empId:this.empId,
    //     employeeName:this.empName,
    //     positionHold:this.position,
    //     // LastReviewDate:this.LastReviewDate,
    //     // createDate:this.createDate,
    //   })
    //   });
    //   console.log("empData",this.apprialform.value);
      
    //   if(this.empData.length == 0 || this.empData.length == undefined)
    //   {
    //     // this.nodatafound = true;
    //     this.kraTemplateData=[];
    //   }
    // })

  //   this.pmsService.getWorkedhours(this.localempId).subscribe((resultData: any) => {
  //     this.workedHourData = resultData;
  //     console.log(this.workedHourData, "workedhours");
  
  //     if (this.workedHourData && this.workedHourData.length > 0) {
  //         const firstElement = this.workedHourData[0];
  //         console.log("worked hours:", firstElement);
  
  //         const totalTime = firstElement.totalTime; 
  //         console.log("Total time string:", totalTime);
  
  //         // Validate and patch the value in HH:mm:ss format
  //         const isValidTime = /^\d{2}:\d{2}:\d{2}$/.test(totalTime);
  //         if (isValidTime) {
  //             this.workedHours = totalTime;
  //             this.apprialform.patchValue({
  //                 hoursWorked: this.workedHours,
  //             });
  //             console.log(this.apprialform.value);
  //         } else {
  //             console.error("Invalid time format. Expected HH:mm:ss");
  //         }
  //     } else {
  //         console.log("Worked hours is null, undefined, or empty");
  //         this.workedHourData = [];
  //     }
  // });
   


  
    this.pmsService.getemployeekratemplateList(this.localempId).subscribe((templateData: any) => {
      if (Array.isArray(templateData)) {
          this.kraTemplateData = templateData;
          console.log(this.kraTemplateData);
          this.kraTemplateData.forEach(element => {
              console.log("Element:", element.kraTemplateHeader.kratemplateLine);
              element.kraTemplateHeader.kratemplateLine.forEach(ele => {
                  console.log("Element:", ele);
                  this.empLineList = ele;
                  console.log(this.empLineList);
                  this.addRow(ele);
              });
          });
      } else {
          console.log("kraTemplateData is not an array");
          
      }
  
      if (!Array.isArray(this.kraTemplateData) || this.kraTemplateData.length === 0) {
          this.kraTemplateData = [];
      }
  });
  

  console.log(this.apprialform.value);
    this.apprialform.patchValue({
      // period:this.apprialform.value.period,
      // employeeName:this.empName,
      employeeName:this.empName,
      department:this.department,
      empId:this.empId,
      // reviewerName:this.approverName,
      reviewerName:this.approverName,
      reviewrTitle:this.approveRole,
      positionHold:this.position,
      // reviewrTitle:this.approverData.approvers.approverOneObject.firstName+" "+this.approverData.approvers.approverOneObject.lastName,
      // LastReviewDate:"2023-01-24",
      // LastReviewDate:this.formattedLastDate
      // createDate:"2023-01-24",
      // empRating:this.apprialform.value.empRating,
      // hoursWorked:this.workedHours,
      // leaveDays:this.apprialform.value.leaveDays,
      // query:this.apprialform.value.query,
      // timesheet:this.apprialform.value.timesheet,


      
     

      
      
    })

    

   }
  
 
 

  // setDefaultPeriod() {
  //   const currentDate = new Date();
  //   const currentMonth = this.datePipe.transform(currentDate, 'MM');
  //   const currentYear = this.datePipe.transform(currentDate, 'yyyy');

  //   // Find the index of the periodData array where financialPeriodMonth and financialPeriodYear match current month and year
  //   const defaultPeriodIndex = this.periodData.findIndex(year => year.financialPeriodMonth === currentMonth && year.financialPeriodYear === currentYear);

  //   if (defaultPeriodIndex !== -1) {
  //     // Set the default value of the period form control
  //     this.periodForm.patchValue({
  //       period: this.currentMonth1-this.currentYear1
  //     });
  //   }
  // }


  // addRow()
  // {
  //   const control = this.apprialform.get('kra') as FormArray;
  //   control.push(this.initRows());
  //   console.log(control);
  // }

  // initRows() 
  // {
  //   this.fb.group({
  //       criteriaName: [''],
  //       criteriaDescription: ['',Validators.required], // You can add your initial value here
  //       criteriaDetails: ['',Validators.required], // You can add your initial value here
  //       weightage: [''], // You can add your initial value here
  //       empRating: ['',Validators.required], // You can add your initial value here
  //       rating: [''], // You can add your initial value here
  //       })
  // }
  
  // kraLine(){
  //   const val=this.apprialform.get('kra') as FormArray;
  //   console.log(val);
  //   console.log(val.controls);
  //   return val;
  // }

  // onFileSelected(event: any) {
  //   console.log("file Read",event);
  //   const file: File = event.target.files[0];
  //   console.log("file",file);
  //   if (file) {
  //     const fileName = file.name;
  //     const fileType = file.type;
  //     const fileSize = file.size;
      
  //     // Use the obtained file details in your payload
  //     this.payload = {
  //       fileName: fileName,
  //       fileType: fileType,
  //       fileSize: fileSize,
  //       fileBytes:null
  //     };
      
  //     console.log(this.payload); 
  //   }
  //     let reader = new FileReader();
  //     reader.onload = () => {
  //        let x: any = reader.result;
  //       let y: any = x.split(',')
  //      let  sourceByteArray = y[1];
  //     //  console.log("sourceByteArray",sourceByteArray);
  //      this.payload.fileBytes=sourceByteArray;
  //     //  console.log(this.payload);
  //     };
  //     reader.readAsDataURL(file);
  //     console.log(this.fileByte)
  //   }


  onFileSelected(event: any) {
    console.log("file Read", event);
    const file: File = event.target.files[0];
    console.log("file", file);

    const allowedTypes = ['application/pdf', 
                          'application/msword', 
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          'application/vnd.ms-excel'
    ];

    if (file) {
        const fileType = file.type;

        if (!allowedTypes.includes(fileType)) {
            alert('Invalid file type.');
            event.target.value = ''; // Clear the file input

            this.apprialform.get('file').setErrors({ required: true });
            this.apprialform.get('file').markAsTouched();
            return;
        }

        const fileName = file.name;
        const fileSize = file.size;

        // Use the obtained file details in your payload
        this.payload = {
            fileName: fileName,
            fileType: fileType,
            fileSize: fileSize,
            fileBytes: null
        };

        console.log(this.payload);

        let reader = new FileReader();
        reader.onload = () => {
            let x: any = reader.result;
            let y: any = x.split(',');
            let sourceByteArray = y[1];
            this.payload.fileBytes = sourceByteArray;
            console.log(this.payload);
        };
        reader.readAsDataURL(file);
    }
}

  
  
  
 
  addRow(data?: any) 
  {
    console.log(data);
    const control = this.apprialform.get('kra') as FormArray;
    control.push(this.initRows(data)); // Pass data to initRows()
    console.log("control",control);
  }

  initRows(data: any = null) {
    return this.fb.group({
      criteriaName: [data ? data.criteriaName : ''],
      criteriaDescription: ['', Validators.required],
      criteriaDetail: ['', Validators.required],
      weightage: [data ? data.weightage : ''],
      empRating: ['', Validators.required],
      rating: ['0'],
      approverComments:[''],
      managementRating:[''],
      managementCalculateRating:[''],
      employeeComments:[''],
      kraTemplateHeaderLineId:[data ? data.kraTemplateHeaderLineId : '']
    });
  }

  kraLine() {
    return this.apprialform.get('kra') as FormArray;
    // console.log(value);
    // console.log(value.controls);
    //  return this.control;
  }

  onInputChange(event,index)
  {
    // console.log("OnChange Function", event.target.value+" "+index);
    console.log(event,index);
    const kraArray = this.apprialform.get('kra') as FormArray;
    console.log(kraArray.length);
    if (index >= 0 && index < kraArray.length) 
    {
      const rowData = kraArray.at(index).value;
      console.log('Row Data:', rowData.empRating);
      // if(rowData.empRating!=0)
      // {
        this.per=rowData.empRating/100*rowData.weightage;
        console.log("percentage:"+this.per.toFixed(2));
        this.val=this.per.toFixed(2);
        console.log(this.val);
        let totalPer=0.00;
        kraArray.at(index).patchValue({
          rating:this.val,
        })
        // Calculation
        const kraArr= this.apprialform.get('kra') as FormArray;
        console.log(kraArr.value);
        this.kraValues=kraArr.value;
        console.log(this.kraValues);
        let total = 0;
        for(let i=0;i<this.kraValues.length;i++)
        {
          console.log(total);
          console.log("Rating",this.kraValues.at(i).rating);
          let t=this.kraValues.at(i).rating;
          console.log(t);
          console.log(parseFloat(t));
          total=total+parseFloat(t);
          console.log(total);
          this.totalPer=total.toFixed(2);
        }
        
        this.apprialform.patchValue({
          empoverallrating:this.totalPer
        })
  
      // }
      // You can now use the rowData as needed.
    } 
    else 
    {
      console.error('Invalid index');
    }
  }

  
  searchSubmit()
  {

    // Extract the single period value from this.apprialform.value.period
    const singlePeriodValue = this.apprialform.value.period;
    // const singlePeriodValueNumber = Number(singlePeriodValue.trim());
    const singlePeriodValueString = String(singlePeriodValue).trim(); // Convert to string and trim
    const singlePeriodValueNumber = Number(singlePeriodValueString);
    console.log("singlePeriodValue:", singlePeriodValue);
    console.log("1111111:", this.periodData);

    // Find the matching period for the single period value
    const matchingPeriods = this.periodData.filter(period => period.financialPeriodId === singlePeriodValueNumber);
    console.log("matchingPeriods:", matchingPeriods);

if (matchingPeriods.length > 0) {
  // If matching periods are found, extract the financialYearId of each matching period
  matchingPeriods.forEach(matchingPeriod => {
      console.log("Matching financialPeriodId:", singlePeriodValue);
      console.log("Corresponding financialYearId:", matchingPeriod.finanicalYear.financialYearId);

      this.finalMatchedPeriodId=matchingPeriod.finanicalYear.financialYearId;
      
     
  });
} else {
    console.log("No matching period found for:", singlePeriodValue);
    // Handle the case when no matching period is found for the single period value
}

console.log(this.finalMatchedPeriodId,"[][][][]");





    console.log("search",this.apprialform);
    console.log("search",this.apprialform.value);
    console.log("periodid",this.apprialform.value.period,this.patchPeriodId);
    console.log("search",this.apprialform.value.kra);
    this.submitted = true;

    this.LastRevieDate = this.datepipe.transform(this.apprialform.value.LastReviewDate, 'yyyy-MM-dd');
    console.log(this.LastRevieDate);
    this.crateDate = this.datepipe.transform(this.apprialform.value.createDate, 'yyyy-MM-dd');
    console.log(this.crateDate);
    if(this.apprialform.invalid)
    {
      console.log("required",this.apprialform);
      return;
    }

    this.submitButtonDisabled= true;

   this.LineDatas=[];
    const kraArray = this.apprialform.get('kra') as FormArray;
    console.log(kraArray.value);
    this.kraarray=kraArray.value;
    console.log(this.kraarray);
    for(let i=0;i<this.kraarray.length;i++)
    {
      /// const line=
      // {
      // criteriaName:this.kraarray.at(i).criteriaName,
      // criteriaDescription: this.kraarray.at(i).criteriaDescription,
      // criteriaDetails:this.kraarray.at(i).criteriaDetails,
      // weightage: this.kraarray.at(i).weightage,
      // empRating: this.kraarray.at(i).empRating,
      // rating: this.kraarray.at(i).rating,
      // }

      const line =
      {
        criteriaDescription:this.kraarray.at(i).criteriaDescription,
        criteriaDetail:this.kraarray.at(i).criteriaDetail,
        employeeRating:this.kraarray.at(i).empRating,
        weightage:this.kraarray.at(i).weightage,
        rating:this.kraarray.at(i).rating,
        approverComments:"",
        managementRating:"",
        managementCalculateRating:"",
        employeeComments:"",
        kratemplateLine:
        {
        kraTemplateHeaderLineId:this.kraarray.at(i).kraTemplateHeaderLineId
        }
      }
        console.log("line"+line);
      this.LineDatas.push(line);
    }
    console.log(this.LineDatas);
    console.log(this.apprialform.value,"jhsghsgjhsgjhg",this.apprialform.value.period);
    console.log(this.apprialform.value);

    const value = this.apprialform.value.period;
    // const separatedValues = value.split('-');
    const separatedValues = (value && typeof value === 'string') ? value.split('-') : [];

    console.log(separatedValues); 

    const selectvalues= this.apprialform.value.period;
    console.log(selectvalues);

    // this.selectedYears = selectvalues.split('-')[1]; 
    // this.selectedPeriodIds = selectvalues.split('-')[0]; 

    if (selectvalues && typeof selectvalues === 'string') {
      this.selectedYears = selectvalues.split('-')[1];
      this.selectedPeriodIds = selectvalues.split('-')[0];
  } else {
      // Handle the case where selectvalues is not a string
      console.error('selectvalues is not a string:', selectvalues);
      this.selectedYears = null; // or some default value
      this.selectedPeriodIds = null; // or some default value
  }

    console.log("selectedPeriodId::",this.selectedPeriodIds);
    console.log("selectedYear::",this.selectedYears);

  

  
   


  var postValues={
    
    employeeId:this.apprialform.value.empId,
   
    financialYearId:this.finalMatchedPeriodId || this.patchYearId,
    financialPeriodId:this.apprialform.value.period || this.patchPeriodId,
     reviewerTitle:this.apprialform.value.reviewrTitle,
    todayDate:this.crateDate,
    overAllRatting:"",
    managementTotalRating:"",
    totalHoursWorking:this.apprialform.value.hoursWorked,
    leaveDays:this.apprialform.value.leaveDays,
    employeeAcceptanceDate:"",
    employeeQuery:this.apprialform.value.query,
    employeeReviewRating:this.apprialform.value.empoverallrating,
    managementRating:"",
    managementComments:"",
    managementReviseComments:"",
    managementReviseRating:"",
    ratingRequired:"",
    closedDate:this.LastRevieDate,
    organizationId:this.orgId,
    selectPeriod:this.crateDate,
    apprisalLine: this.LineDatas,
    timeSheetAttachment:this.payload
    // {
    //   fileName: "timesheet.xlsx",
    //   fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   fileSize: 3434,
    //   fileBytes: "foile"
    // }
  }
    
    // {
    //   var postValues = {
    // employeeId: this.apprialform.value.empId,
    // createdBy:this.userId,
    // reviewerTitle: this.apprialform.value.reviewrTitle,
    // todayDate:this.apprialform.value.createDate,
    // overAllRatting: "defaultTemplate",
    // managementTotalRating: "4",
    // totalHoursWorking:this.apprialform.value.hoursWorked,
    // leaveDays:this.apprialform.value.leaveDays,
    // employeeAcceptanceDate: "2022-11-21",
    // employeeQuery:this.apprialform.value.query,
    // employeeReviewRating:this.apprialform.value.empoverallrating,
    // managementRating: "3",
    // managementComments: "productivity",
    // managementReviseComments: "delay",
    // managementReviseRating: "good",
    // ratingRequired: "",
    // closedDate: this.apprialform.value.LastReviewDate,
    // organizationId: this.orgId,
    // selectPeriod:this.apprialform.value.period,
    // apprisalLine: [
    //     {
    //         criteriaDescription: "kraTemplateLine",
    //         employeeRating: "64",
    //         weightage: "45",
    //         rating: "56",
    //         approverComments: "good",
    //         managementRating: "4", 
    //         managementCalculateRating: "4",
    //         employeeComments: "delay productivity",
    //         kratemplateLine: {
    //             kraTemplateHeaderLineId: 2005
    //         }
    //     },
    //     {
    //         criteriaDescription: "kraTemplateLine",
    //         employeeRating: "64",
    //         weightage: "45",
    //         rating: "56",
    //         approverComments: "work is good",
    //         managementRating: "4",
    //         managementCalculateRating: "4",
    //         employeeComments: "need productivity",
    //         kratemplateLine: {
    //             kraTemplateHeaderLineId: 2005
    //         }
    //     }
    // ]
    //   }
    
     console.log("postValues",postValues);
     console.log(this.financialYearId);
     console.log(this.financialPeriodId);
     
     
    //  console.log(JSON.stringify(postValues, null, 2));

      this.loader=true;
      this.pmsService.createApprisal(postValues).subscribe((result: any) =>
      {
        this.submitButtonDisabled= false;
        this.loader=false;
        console.log(result);
        if(result.statusCode == 200)
        {
          this.submitted = false;
          
          this.success = true;
          this.sucess_msg = result.description;
          setTimeout(() =>
          {
            this.router.navigate(['home/appraisal']);
          },
           3000)
        }
        // if(result.statusCode==409)
        // {
        //     this.error = true;
        //    console.log("Error Create:"+result);
        //   this.error_msg = result.description;
        // }
        else
        {
          this.error = true;
          this.loader = false;
          this.submitButtonDisabled= false;
          console.log("Error Create:"+result);
          this.error_msg = result.message;
          setTimeout(() => 
          {
             this.error = false;
            // this.router.navigate(['/home/kra-template']);
          },3000)
        }
      },
       err =>
      {
        this.error = true;
        this.loader = false;
        this.submitButtonDisabled= false;
        console.log("Error result",err);
         console.log("Error result",err.error.description);
        this.error_msg =err.error.description;
        setTimeout(() => {
          this.error = false;
        }, 3000)
        console.log( this.error_msg);
      })
    // }

  }

  back(){
    // this.router.navigate(['home/appraisal']);
    console.log('back')
    window.history.back();

    
  }




  get validate() { return this.apprialform.controls; }


  get KraLineFormArray() : FormArray {
    return this.apprialform.get('kra') as FormArray;
   }


   validateHours(event: any) {
    const value = event.target.value;
    if (value.length > 3) {
        event.target.value = value.slice(0, 3);
    }
}

validateLeave(event: any) {
  const value = event.target.value;
  if (value.length > 2) {
      event.target.value = value.slice(0, 2);
  }
}

// validateInput(event) {
//   const input = event.target;
//   let value = input.value;

//   if (value.startsWith(' ')) {
//     value = value.trimStart();
//     input.value = value;
//   }

//   this.invalidInput = value.trim().length === 0;
// }

validateInput(event) {
  const input = event.target;
  let value = input.value;

  // Trim leading spaces
  value = value.trimStart();

  // Replace multiple spaces with a single space
  value = value.replace(/\s+/g, ' ');

  // Set the modified value back to the input
  input.value = value;

  // Check if the input is invalid (only spaces or empty)
  this.invalidInput = value.trim().length === 0;
}

handleKeyPress(event) {
  if (event.key === ' ' && event.target.selectionStart === 0) {
    event.preventDefault();
  }
}


preventPaste(event: ClipboardEvent): void {
  event.preventDefault();
}

// onPeriodChange(event: Event): void {
//   const selectedValue = (event.target as HTMLSelectElement).value;
//   console.log('Selected value via change event:', selectedValue);

//   this.periodData.forEach(period => {
//     console.log("Financial Period Month:", period.financialperiodMonth);
//     console.log("Financial Period Year:", period.financialPeriodYear);
//     console.log("month id:", period.financialPeriodId);
//     console.log("year id:", period.finanicalYear.financialYearId);

//     this.monthid=period.financialPeriodId;
//     this.yearid=period.finanicalYear.financialYearId
//   });

  


//   this.pmsService.getLeavedays(this.localempId,this.yearid,this.monthid).subscribe((Data: any) => {
//     this.leavedays=Data;
//     console.log(this.leavedays);
//     this.apprialform.patchValue({
//      leaveDays:this.leavedays.leave_count
//  });
//  })
  
// }


// onPeriodChange(event: Event): void {
//   const selectedValue = (event.target as HTMLSelectElement).value;
//   console.log('Selected value via change event:', selectedValue);

//   // Split the selected value to extract the financialPeriodId and financialYearId
//   const [selectedPeriodId, selectedYearId] = selectedValue.split('-').map(value => value.trim());

//   // Find the selected period object in periodData
//   const selectedPeriod = this.periodData.find(period => 
//     period.financialPeriodId.toString() === selectedPeriodId &&
//     period.finanicalYear.financialYearId.toString() === selectedYearId
//   );

//   if (selectedPeriod) {
//     // Assign the correct IDs
//     this.monthid = selectedPeriod.financialPeriodId;
//     this.yearid = selectedPeriod.finanicalYear.financialYearId;

//     console.log('Selected Period:', selectedPeriod);

//     // Call the service with the selected values
//     this.pmsService.getLeavedays(this.localempId, this.yearid, this.monthid).subscribe((Data: any) => {
//       this.leavedays = Data;
//       console.log('Leave Days:', this.leavedays);

//       // Update the form
//       this.apprialform.patchValue({
//         leaveDays: this.leavedays.leave_count
//       });
//     });
//   } else {
//     console.error('Selected period not found in periodData.');
//   }
// }

onPeriodChange(event: Event): void {
  const selectedValue = (event.target as HTMLSelectElement).value;
  console.log('Selected value via change event:', selectedValue);

  // Find the selected period based on the financialPeriodId
  const selectedPeriod = this.periodData.find(period => 
    period.financialPeriodId.toString() === selectedValue
  );

  if (selectedPeriod) {
    // Assign the correct IDs
    this.monthid = selectedPeriod.financialPeriodId;
    this.yearid = selectedPeriod.finanicalYear.financialYearId;

    console.log('Selected Period:', selectedPeriod);

    // Call the service with the selected values
    this.pmsService.getLeavedays(this.localempId, this.yearid, this.monthid).subscribe((Data: any) => {
      this.leavedays = Data;
      console.log('Leave Days:', this.leavedays);

      // Update the form
      this.apprialform.patchValue({
        leaveDays: this.leavedays.leave_count
      });
    });

    this.pmsService.getWorkedHours(this.localempId, this.yearid, this.monthid).subscribe((Data: any) => {
      this.workedHours = Data;
      console.log('worked hours:', this.workedHours);

      // Update the form
      this.apprialform.patchValue({
        hoursWorked:this.workedHours.TotalWorkedHours
      });
    });
  } else {
    console.error('Selected period not found in periodData.');
  }
}




}
