import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { subscribe } from 'diagnostics_channel';
import { ProjectManagementService } from 'src/app/providers/project-management.service';


@Component({
  selector: 'app-/expenseadvanceapproverstatus',
  templateUrl: './expenseadvanceapproverstatus.component.html',
  styleUrls: ['./expenseadvanceapproverstatus.component.scss']
})
export class ExpenseAdvanceapproverstatusComponent implements OnInit {

  storeFirstname: void;
  storeApproveData: any;
  nodata: boolean=false;
   loader :any =true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private advanceservice :ProjectManagementService,

  ) { }

  ngOnInit(): void {
    console.log("this.data",this.data);
    // console.log(this.data.paymentAdvice.paymentAdviceId);
console.log(this.data.advanceId);
// console.log(this.data.approver.approverOneObject.firstName);
    this.advanceservice.approvalsList(this.data.advanceId).subscribe((data:any)=>{
      console.log(data);
      this.storeApproveData=data
      this.storeFirstname=data.approver?.approverOneObject?.firstName
      console.log(" this.storeFirstname::", this.storeFirstname);
      console.log(this.storeApproveData);
      this.loader = false;

      if(this.storeApproveData.message=='No Data'){
        this.nodata=true;
      }
      // console.log("this.data.approvals.approver.fyiObject.firstName",this.data.approvals.approver.fyiObject.firstName);

    },)
  }

  
  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }

  approvalBackground(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#fff0c2'
    }
    if (approvalStatusName == 'Approved') {
      return '#c8e6c9'
    }
    if (approvalStatusName == 'Denied') {
      return '#ffcdd2'
    }
  }

}
