import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../providers/settings.service';
//import { AddDepartmentComponent } from '../add-department/add-department.component';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  }  from '../../../providers/properties';

@Component({
  selector: 'app-list-types',
  templateUrl: './list-types.component.html',
  styleUrls: ['./list-types.component.scss']
})
export class ListTypesComponent implements OnInit 
{

  public searchFilter: any = '';

  constructor(
    private settingsservice: SettingsService,
    private dialog:MatDialog,
  )
  { }

  resultData : any;
  p:number = 1;
  collection: any[];
  pagePerItem:any=10;
  tableSearch : any;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;

  ngOnInit(): void 
  {
    this.listTypeList();
  }

  listTypeList()
  {
    this.settingsservice.getListTypeList().subscribe((result: any) => {
      this.resultData = result;
    },err =>{
      //this.errorMsg = err.error.message;
    })
  }

}
