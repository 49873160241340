<style>
  .full-width {
    width: 100%;
  }
</style>
<div class="container">
  <div class="d-flex">
    <h2 style="width: 100%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" align="left">{{data.calendarId ? 'Update' :'Create'}} Calendar</h2>
    <mat-icon class="material-icons float-right"  style="color: red !important;"
      mat-dialog-close 
    >clear</mat-icon>
  </div>

  <form [formGroup]="calendarForm" (ngSubmit)="onSubmit()">
    <div class="--form-group">
      <div class="input-width" id="loginform">
        <label class="form-control-label ">Calendar Name<span class="star"> *</span></label>
        <input formControlName="calendarName"class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"

        placeholder="Enter Calendar Name">
        <!-- <mat-error *ngIf="f.calendarName.hasError('required') && (f.calendarName.touched || f.calendarName.dirty)">
          <span>Calendar Name is required</span>
        </mat-error> -->
        <mat-error *ngIf="f.calendarName.hasError('required') && submitted">
          <span>Calendar Name is required</span>
        </mat-error>
        <mat-error *ngIf="(f.calendarName.errors?.pattern )">
          Please enter a valid Calendar Name
      </mat-error> 
      </div>
    </div>

    <!-- <div class="--form-group">
      <div class="input-width" id="loginform">
        <label class="col-form-label">Organization Name<span class="star"> *</span></label>
        <select formControlName="organization" (change)="change($event)"
         class="form-select form-select-solid form-select-lg select2-new">
          <option [value]=null> --- Select Organization Name --- </option>
          <option *ngFor="let org of organization" [value]="org.organizationId" >
            {{org.organizationName}}
          </option>
        </select>
        <mat-error *ngIf="f.organization.hasError('required') && submitted">
          <span>Organization is required</span>
        </mat-error>
      </div>
    </div> -->

    <div class="--form-group">
      <div class="input-width" id="loginform">
        <label class="form-control-label mt-3">Branch Name<span class="star"> *</span></label>
        <mat-select formControlName="branch" placeholder="Select Branch Name" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
        >
          <mat-option [value]="null" selected> Select Branch Name </mat-option>
          <mat-option *ngFor="let org of branchList" [value]="org.branchId">
            {{org.branchName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.branch.hasError('required') && submitted">
          <span>Branch is required</span>
        </mat-error>
      </div>
    </div>

    <div class="--form-group">
      <div class="input-width" id="loginform">
        <label class="form-control-label mt-3">Year<span class="star"> *</span></label>
        <mat-select formControlName="year" placeholder="Select Year" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
        >
          <mat-option [value]="null"> Select Year </mat-option>
          <mat-option *ngFor="let y of year" [value]="y.yearId">
            {{y.yearName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.year.hasError('required') && submitted">
          <span>Year is required</span>
        </mat-error>
      </div>
    </div>

    <div class="--form-group" *ngIf="data.calendarStatus == 2">
      <div class="input-width" id="loginform">
        <label class="form-control-label mt-3">Calendar Status<span class="star"> *</span></label>
        <mat-select formControlName="calendarStatus" class="form control select-placeholder email-input " style="outline:none;border-radius: 5px;font-family: Mulish;"
        >
          <mat-option value="1">Active</mat-option>
          <mat-option value="2">InActive</mat-option>
        </mat-select>
        <mat-error
          *ngIf="f.calendarStatus.hasError('required') && submitted">
          <span>Calendar Status is required</span>
        </mat-error>
      </div>
    </div>

    <div class="--form-group" align="right">
      <button mat-raised-button  
      style="width: 20%; margin: 15px 0 0 8px; color:#ff0000a8" 
      type="button"
      class="btn btnRes mr-2"
      mat-dialog-close>Cancel
      </button>
      <button mat-raised-button color="primary" 
      style="margin: 15px 0 0 8px" 
      type="submit" 
      class="searchBtn"
      [disabled]="btnDisable"
      >
        {{data.calendarId ? 'Update' :'Create'}}
      </button>
    </div>
  </form>
</div>