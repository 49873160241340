import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../providers/settings.service';
import { EmployeeService } from '../../providers/employee.service';

import { AddEmployeeRolesComponent } from '../add-employee-roles/add-employee-roles.component';
import { MatDialog } from '@angular/material/dialog';
import {httpOptions, responseMsgTimeOut , redirectMsgTimeOut  } from '../../providers/properties';
import { UserService } from 'src/app/providers/user.service';

@Component({
  selector: 'app-employee-roles',
  templateUrl: './employee-roles.component.html',
  styleUrls: ['./employee-roles.component.scss']
})
export class EmployeeRolesComponent implements OnInit 
{
  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;

  resultData: any = {};
  employeeId: any;
  fileblob: any;

  loader:any= false;
  backdrop:any= false;

  success = false;
  error:any=false;
  sucess_msg:any;
  error_msg:any;
  tableShow:boolean=false
  empId :any;
  userId: any;
  userresultData: any;
  noRecordsFound:boolean=false

  constructor(
    private formBuilder: FormBuilder,
    private router : Router,
    private settingsservice: SettingsService,
    private employee_service: EmployeeService,
    private ActivatedRoute:ActivatedRoute,
    private dialog:MatDialog, private  userservice : UserService
  ) { }

  ngOnInit(): void
  {
    this.employeeId = this.ActivatedRoute.snapshot.params.employeeId;
    this.userId = this.ActivatedRoute.snapshot.params.userId;
    
      this.getemployeeuserdetails();
  }

  getemployeeuserdetails()
  {
    if(this.employeeId) //View User
    {
      this.loader=true;
      this.backdrop=true;

      this.employee_service.getEmployeeById(this.employeeId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;

        this.resultData=resultData;

        if(resultData.employeeAttachment)
        {
          this.employee_service.getemployeeImage(resultData.employeeAttachment.fileViewUrl)
          .subscribe(
            blob => {
              const a = document.createElement('a')
              const objectUrl: any = blob
              this.fileblob = blob;

            },
            err => {
                this.fileblob= "assets/uploads/no-image.png"
            }
          );
        }
        else
        {
          this.fileblob= "assets/uploads/no-image.png"
        }
      });
    }

      if(this.userId)
      {
        this.loader=true;
      this.backdrop=true;

      this.userservice.getUserById(this.userId).subscribe((resultData:any) =>
      {
        this.loader=false;
        this.backdrop=false;

        this.userresultData=resultData;

     
       
      });
    }
  }

  addForm(employeeId) //Add Dialog Box
  {
    this.empId = employeeId;
    
    const dialogRef = this.dialog.open(AddEmployeeRolesComponent, {
      //height: 'auto',width: '600px',
      width: '500px',height:'fit-content',
      data :{employeeId:this.empId , userId : this.userId}
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      console.log(result)
      //this.departmentList();

      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;  this.getemployeeuserdetails(); },5000)
      }
      else if(result.statusCode > 400)
      {
        this.error = true;
        this.error_msg = result.message;
        setTimeout(() => {this.error = false }, 5000)
      }
    });
  }
}
